
import React, { useState } from 'react';
import FlightSectors from '../../../bookingFlows/flight/common/FlightSectors';
/**
 * @description:This function will Include the Origns and destinations
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 31-08-2020
 */
function FlightInfoDetails(props) {
    const [response, setResponse] = useState(props.response);

    return (
        <>
            <>
                {response.tt !== 3 &&
                    <>
                        {(response.origns[0].srcorigns[0].ref == 1 || response.onhold_sty) &&
                            <FlightSectors revieWFlt={props.response} bktype={props.bktype} origin={[response.origns[0].srcorigns[0], 'Review']} mapping={response.mappings} tripType={response.tt} />
                        }
                        {response.origns[0].destorigins !== undefined && response.origns[0].destorigins[0] !== undefined && response.origns[0].destorigins[0].ref == 2 &&
                            <FlightSectors revieWFlt={props.response} bktype={props.bktype} origin={[response.origns[0].destorigins[0], 'Review']} mapping={response.mappings} tripType={response.tt} />
                        }
                    </>
                }
                {response.tt === 3 &&
                    <>
                        {response.origns[0].srcorigns &&
                            <FlightSectors revieWFlt={props.response} bktype={props.bktype} originsList={[response.origns[0], 'Review']} mapping={response.mappings} tripType={response.tt} />
                        }
                    </>
                }
            </>
        </>
    )
}

export default FlightInfoDetails;
