import React, { useEffect, useState, useRef, useReducer } from "react";
import { Alert, Col, Form ,Modal, Button, Table, Container,Row} from "react-bootstrap";
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import ShowHide from '../../../common/showHide/ShowHide';
import Attimage from '../../../../assets/images/GDEidd.jpg';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import VisaBookingService from "../../../../services/bookingService/visa/VisaBookingService";
import { cities, countries } from "../../../adminstration/utils";

let bookingStatus = require('../../../masterData/BookingStatus..json');

const initialState= {
  pax_id:"",fn:"", ln:"", application_id:"", city:"", cur_adrs:"", dob:new Date(), em:"", gen:"", lead:"", na:"", pas_exp:new Date(), pas_num:"", phc:"",
  phn:"", plbl:"", rn:"", sno:"", state:"", sur_nm:"", ty:"", tl:"", docs_info:[], genderList:[], notiMessageShow: false, notiMessage: '', notiVarient: '',
  pax_status_str:"", countriesMenu:[], cityMenu:[]
}

const reducer = (state, action) => {
  switch (action.type) {

    case 'paxdata':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function VisaSummaryEdit(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [startDate, setStartDate] = useState(new Date());
  const [bookingList, setBookingList]=  useState([])
  const [bookid, setBookid]=  useState([])
  // const [pax_info, setPax_info]=  useState([])


  const Validation = () =>{
  if ((props.paxData?.fn!=undefined&& props.paxData?.fn!="") && state.fn==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter first name for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
}
else if ((props.paxData?.ln!=undefined&& props.paxData?.ln!="") && state.ln==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter last name for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.application_id!=undefined&& props.paxData?.application_id!="") && state.application_id==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter application id for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.cur_adrs!=undefined&& props.paxData?.cur_adrs!="") && state.cur_adrs==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter adress for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.em!=undefined&& props.paxData?.em!="") && state.em==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter email for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.gen!=undefined&& props.paxData?.gen!="") && state.gen==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter gender for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.na!=undefined&& props.paxData?.na!="") && state.na==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter nationality for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.pas_num!=undefined&& props.paxData?.pas_num!="") && state.pas_num==""){
  debugger
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter passport number for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.phn!=undefined&& props.paxData?.phn!="") && state.phn==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter phone number for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
else if ((props.paxData?.state!=undefined&& props.paxData?.state!="") && state.state==""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Please enter state for ${state.plbl} ${state.sno}`})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
  return true;
  }
  useEffect(()=>{
    const gList =[{ "value": "", "label": "Select" },{ "value": "Male", "label": "Male" },{ "value": "Female", "label": "Female" },{ "value": "Other", "label": "Other" } ]
    const genderList=gList.map((title)=>
      <option value={title.value}>{title.label}</option>
    )
    dispatch({type:"genderList", payload:genderList})
    countries().then((res) => {
      for(let countries of res){
        if(countries.label ==props.paxData.na){
          state.na=countries.label
        dispatch({ type: 'countriesMenu', 
        payload: <option key={countries.value} value={countries.value}>{countries.label}</option>
        })                
          cities(countries.value).then((response) => {
          //  for (let cities of response){ 
            dispatch({ type: "cityMenu", 
            payload: response })
        // }
      })
       }
     }
  })
    // setPax_info(props.paxData)
    dispatch({type:"paxdata", payload:props.paxData})
    if (props.paxData.dob!=null && props.paxData.dob!==undefined && props.paxData.dob!=""){ 
    let newDOB=new Date(props.paxData.dob)
    dispatch({type:"dob", payload:newDOB})
    }
    if (props.paxData.pas_exp!=null && props.paxData.pas_exp!==undefined && props.paxData.pas_exp!=""){ 
    let newExp=new Date(props.paxData.pas_exp)
    dispatch({type:"pas_exp", payload:newExp})
    }
    // if(enbleTravelrs && props.bkngdetails!==undefined){
      let bookingList= [{value:"Pending", label:"Pending"},{value:"In Progress", label:"In Progress"},{value:"Issued", label:"Issued"},{value:"Rejected", label:"Rejected"}]
      setBookingList(bookingList)
      for(let val of bookingList){
          if((val.label.toUpperCase())==(props.paxData.pax_status_str.toUpperCase())){
            dispatch({type:"pax_status_str", payload:val.label})
            setBookid([{ label: val.label, value: val.value }])
          } else {
            dispatch({type:"pax_status_str", payload:props.paxData.pax_status_str})
            setBookid([{ label: props.paxData.pax_status_str, value: props.paxData.pax_status_str }])
          }
      }
      // }
  },[])

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handlecancel =()=>{
    window.location= '/ezytrip/dashboard/visa/summary/'+props.bkng_det.booking_id;
  }
  const handleUpdate =()=>{
    if (Validation()){
    const req ={
      region_type:props.bkng_det.region_type,
      booking_status_str:props.bkng_det.booking_status_str,
      booking_id:props.bkng_det.booking_id,
      upby:props.uid,
      pax_info:state
    }
    VisaBookingService.updateSummary(req).then((res)=>{
      if (res.data.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Updated successfully`})
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
        window.location= '/ezytrip/dashboard/visa/summary/'+props.bkng_det.booking_id;
        return false;
      } else{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Not updated`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }
    })
    }
  }

    return (
        <>
      
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
            <ShowHide visible={true} icon="xxx" title={`${state.plbl} ${state.sno}. ${state.fn} ${state.ln}`}>
           
           <div className="d-flex summerAppEdtop">
           <span className="summApliedit d-flex"><span>Application#:</span> {state.application_id}</span>
            { <Col xs="3" className="d-flex summeryStatuse pr-0"><span className="summVisaSta">Visa Status: </span> <Form.Group className="selectplugin editOne m-0 pr-0" as={Col} controlId="firstName">
                 <Select
                   options={bookingList}
                   searchable={false}
                   values={[{value:state.pax_status_str, label:state.pax_status_str}]}
                   onChange={(value) => {dispatch({type:"pax_status_str", payload:value[0].label})}}
                 />
                </Form.Group> </Col>}
               
                </div>
            <hr/>


            <div className="bookingListinfoinner summEdit">
            <Row className="pb-2">
                       
             {props.paxData.fn && <Form.Group as={Col} xs={4} className="selectpluginn">
             <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" autoComplete="off"
                 value={state.fn} 
                  onChange={(e)=>{dispatch({type:"fn", payload:e.target.value})}}/>
               
            </Form.Group>}

                      
            {props.paxData.ln && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Last Name : </Form.Label>
            <Form.Control type="text" placeholder="First Name" autoComplete="off"
                 value={state.ln} 
                  onChange={(e)=>{dispatch({type:"ln", payload:e.target.value})}}/>
         
             </Form.Group>}

            {props.paxData.application_id && 
            <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Application # :</Form.Label><Form.Control type="text" placeholder="First Name" autoComplete="off"
                 value={state.application_id} 
                  onChange={(e)=>{dispatch({type:"application_id", payload:e.target.value})}}/>
            </Form.Group>}
              </Row>
              <Row className="pb-2">

              {props.paxData.dob && <Form.Group as={Col} xs={4} className="selectpluginn datePicker">
                <Form.Label>Date of Birth :</Form.Label>
                <DatePicker selected={state.dob} onChange={(date) =>{dispatch({type:"dob", payload:date})}} placeholderText="DD/MM/YYYY" />
                <span className="calendar ezyIcon icon-newcalendar"></span>
            </Form.Group>}

              
            {props.paxData.gen && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Gender :</Form.Label> <Form.Control as="select"  value={state.gen}
                onChange={(e)=>{dispatch({type:"gen", payload:e.target.value})}}>
                {state.genderList}
                </Form.Control>
            </Form.Group>}

            {props.paxData.phn && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Mob. Number :</Form.Label>
            <Form.Control type="text" placeholder="mobile no" autoComplete="off"
                 value={state.phn} 
                  onChange={(e)=>{dispatch({type:"phn", payload:e.target.value})}}/>
            </Form.Group>}
             </Row>
              <Row className="pb-2">
              {props.paxData.em && <Form.Group as={Col} xs={4} className="selectpluginn">
                <Form.Label>Email Id :</Form.Label>
              <Form.Control type="text" placeholder="email" autoComplete="off"
                 value={state.em} 
                  onChange={(e)=>{dispatch({type:"em", payload:e.target.value})}}/>
            </Form.Group>}

            {props.paxData.na && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Nationality :</Form.Label>
              <Form.Control as="select"  value={state.na}
                onChange={(e)=>{dispatch({type:"na", payload:e.target.value})}}>
                {state.countriesMenu}
              </Form.Control>
            </Form.Group>}
            {props.paxData.city &&
              <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>City :</Form.Label>
                 <Typeahead
              allowNew
              id="label"
              newSelectionPrefix="Add a new item: "
              options={state.cityMenu}
              value={state.city}
              placeholder="city"
              onChange={(e)=>{dispatch({type:"city", payload: e[0]?e[0].label:""})}}
            />
            </Form.Group>}
             
              </Row>
              <Row className="pb-2">
              {props.paxData.state && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>State :</Form.Label>
            <Form.Control type="text" placeholder="state" autoComplete="off"
                 value={state.state} 
                  onChange={(e)=>{dispatch({type:"state", payload:e.target.value})}}/>
            </Form.Group>}

            {props.paxData.pas_num && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Passport # :</Form.Label>
            <Form.Control type="text" placeholder="passport number" autoComplete="off"
                 value={state.pas_num} 
                  onChange={(e)=>{dispatch({type:"pas_num", payload:e.target.value})}}/>
            </Form.Group>}
            {props.paxData.pas_exp && <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Passport Expiry :</Form.Label>
            <DatePicker selected={state.pas_exp} onChange={(date) =>{dispatch({type:"pas_exp", payload:date})}} placeholderText="DD/MM/YYYY" />
                <span className="calendar ezyIcon icon-newcalendar"></span>
            </Form.Group>}
      
            
              </Row>
              {props.paxData.cur_adrs && <Row className="pb-2">
              <Form.Group as={Col} xs={4} className="selectpluginn">
            <Form.Label>Address :</Form.Label>
            <Form.Control type="text" placeholder="address" autoComplete="off"
                 value={state.cur_adrs} 
                  onChange={(e)=>{dispatch({type:"cur_adrs", payload:e.target.value})}}/>
            </Form.Group>
            </Row>}
            </div>
            <hr/>
              {state.docs_info>0 && 
            <div className="attSection summEdit">
              <h5>Attachments :</h5>
              <h5>Passport :</h5>
              <Row className="pb-0">
             <Form.Group as={Col} xs={3} >
        <Form.Label>Passport Front</Form.Label>
        <div class="uploadButton">
        <span className="addlogo ezyIcon icon-attach-ico"
            > Passport-Font.JPEG <span className="browseE"></span></span>

        </div>

        <span className="fileFormat">File Format should be JPEG, PNG, PDF. File Size 2MB</span>
        </Form.Group>


        <Form.Group as={Col} xs={3} >
        <Form.Label>Passport Back</Form.Label>
        <div class="uploadButton">
        <span className="addlogo ezyIcon icon-attach-ico"
            > Passport-Font.JPEG <span className="browseE"></span></span>

        </div>

        <span className="fileFormat">File Format should be JPEG, PNG, PDF. File Size 2MB</span>
        </Form.Group>
   
            </Row>

            <hr/>
            <h5>Visa Copy :</h5>
             <Row className="pb-0">
             
        <Form.Group as={Col} xs={3} >
        <Form.Label>Passport Back</Form.Label>
        <div class="uploadButton">
        <span className="addlogo ezyIcon icon-attach-ico"
            > Visa-copy.JPEG <span className="browseE"></span></span>

        </div>

        <span className="fileFormat">File Format should be JPEG, PNG, PDF. File Size 2MB</span>
        </Form.Group>
               
            </Row>
            <hr/>
            </div>}
            <div className="canSavebt">
            <Button variant="outline-secondary mr-3" onClick={handlecancel}>Cancel</Button>
          <Button variant="outline-primary" onClick={handleUpdate}>Update</Button>
          </div>
            </ShowHide>
        </>
     );




    }
    
    export default VisaSummaryEdit;