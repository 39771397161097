
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import PickList from '../../../common/pickList/PickList';
import Utilities from '../../../commonUtils/Utilities';
import ApplicableMarkup from '../../common/ApplicableMarkup';
import {deledeFMap,fetch_company_list, getOfficeIdListS,delPaxServiceFee, editServiceFee, getCompaniesList, getCompanyCatLsit, getCompanyList, getCompanyTAList, getSTACompanyList, retriveFMDetails, updateFMRequest,loadBranchesList } from './operations';
import RegionType from '../../common/RegionType';
import SalesChannel from "../../common/SalesChannel";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * Initial State Declaration
 */
const initialState = {
  cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "TA", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [], branches: [], branchMenu: [], catMenu: [],
  categories: "", enbleType: true, showCat: true, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,sales_chanel:"",officeIds:[],
  enabeleEdit: false, fmId: "", notiMessage: "", notiMessageShow: false, notiVarient: "", userType: "", staCompanies: [], ta_comp: [], selBranches: [], reset: false, hideDelete: false,region_type:"",RegionSelected:false,branchAdded:false,region_type_edit:"",
  isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,ofcID:"",supplierError:false,enableOfcId:true
};

let serviceFeeArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "TA", aptype: "Branch", compType: true, don: "", dtype: "", type: "", rbd: []
        , enbleType: true, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
        enabeleEdit: false, fmId: "", staCompanies: [], ta_comp: [], selBranches: [], hideDelete: false, reset: true,region_type:"",RegionSelected:false,officeIds:[],
        isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};


/**
 * @author: Lakshmi
 * @description: This function will create the air markup defination and some other details.
 * @date:14-09-2020
 * @function : convertEditRoutes
 */
function AirMarkupFareMapForm(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [classState, setRbdClass] = useState({ class: [] });
  const [companydata, setCompanyPick] = useState();
  const [context, setContext] = useContext(Context);
  const [fares, setShowFareList] = useState({ faresList: [] });
  const [value, setValue] = useState([]);
  const [perTicket, setPerTicket] = useState({ perTickts: [] })
  const [perBooking, setPerBooking] = useState({ perBookings: [] })
  const [applicable, setApplicableType] = useState();
  const [enApplicable, setEnApplicable] = useState(true);
  const [companyType, setCompanyType] = useState(true);
  const [paxData, setPaxData] = useState()
  const [data, setData] = useState([])
  const [disableButton, setDisableButton] = useState(false);
  const suppliers = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6}, {label:"Riya Travel", value: 7}];

  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);
  useEffect(() => {
    if(state.enableDevices && state.ofcID){
      const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}

        fetch_company_list(payload).then(response => {

          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "value": val.fk_company_id,
                "label": val.company_name,
                "sales_channel": val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
    }
  }, [!state.enableDevices,state.ofcID]);
  useEffect(() => {
    if(props.edit_ofcid){
    dispatch({ type: 'ofcs', payload: props.edit_ofcid })
  }
  if(props.edit_supplier){
    dispatch({ type: 'supplier', payload: props.edit_supplier })
  }
  }, [props.edit_ofcid,props.edit_supplier])
  useEffect(() => {
    if (state.sales_chanel!== "" && state.region_type !== "") {
       const payload = {"sales_channel": state.sales_chanel, "region_type": state.region_type,"userid": context.logindata.uid,}
       getOfficeIdListS(payload).then(response => {
          if (response.data.suc) {
            if (response.data.res && response.data.res.length>0) {
              let authData = []
              let officeIds = response.data.res.filter((val)=>{
                return val.office_id_val != null;
              })
              for (let val of officeIds){
                let auth = {
                  "value": val.id,
                  "label": val.office_id_label,
                  "ofc_id": val.office_id_val
                }
                authData.push(auth)
              }
              dispatch({ type: "ofcs", payload: [] });
              dispatch({ type: 'officeIds', payload: response.data.res })
              dispatch({ type: 'enableOfcs', payload: true })
            }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
       }
  }, [state.sales_chanel,state.region_type]);
  useEffect(() => {

    if(state.ofcID ){
      AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}).then(response => {

        if (response.data.suc){
          let authData = []
          let BRANCHES = response.data.res.filter((val)=>{
                  return val.fk_branch_id != null;
                })
                for (let val of BRANCHES){
                  let auth = {
                    "value": val.fk_branch_id
                    ,
                    "label": val.office_name
                  }
                  authData.push(auth)
                }
          let branchesList = authData.map((branch) => (
            <option value={branch.value}>{branch.label}</option>
          ));
          state.branchMenu = branchesList;
          dispatch({ type: "branchMenu", payload: branchesList });
          dispatch({ type: "branches", payload: branchesList });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in Loadi branches based on Region function---" + error
        );
      });
    }
  }, [state.ofcID])
  useEffect(() => {
    if(!state.enableDevices){
     setCompanyPick([])
    }
  }, [!state.enableDevices]);

  useEffect(() => {
    if (props.reset) {
      setShowFareList({ faresList: [] })
      setCompanyPick()
      setValue([])
      setPerTicket({ perTickts: [] })
      setApplicableType()
      setCompanyType(true)
      setEnApplicable(true)
      setPaxData()
      setData([])
      serviceFeeArray = []
      dispatch({ type: 'clear', payload: true })
    }
  }, [props.reset])

  useEffect(() => {
    let fareList = [];
    if (props.fareData && props.fareData !== undefined) {
      for (let value of props.fareData) {
        // dispatch({ type: 'region_type', payload: value.region_type })
        let loadVarience = {
          "label" : value.region_type,
          "value" : value.region_type
        }
        // handleRegionType()
        var name = "";
        if (value.branch_name !== null && value.branch_name !== "") {
          name = value.branch_name;
        } else if (value.category_name !== null) {
          name = value.category_name;
        }
        const fares = {
          fmid: value.pk_fare_map_id,
          applyon: value.applon,
          borcname: name,
          company: (value.company_name) ? value.company_name : value.com_type,
          sup: value.supplier_name + "[" + value.fk_office_id + "]"
        }

        serviceFeeArray.push({ name: (value.company_name) ? value.company_name : value.com_type })
        fareList.push(fares);
      }
      setShowFareList({ faresList: fareList })
      dispatch({ type: 'enabeleEdit', payload: true })
      if(context.logindata!==undefined && context.logindata.utype==="att_user"){
        if(context.logindata.is_admin){//ATT Admin
        AttCommonSerivce.loadBranches({"region_type":props.edit_region}).then(response => {
            if (response.data.suc){
              let branchesList = response.data.res.map((branch) => (
                <option value={branch.value}>{branch.label}</option>
              ));
              }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
          });
        }else{
          AttCommonSerivce.loadBranches({"region_type":props.edit_region,"uid":context.logindata.uid}).then(response => {
            if (response.data.suc){
              let branchesList = response.data.res.map((branch) => (
                <option value={branch.value}>{branch.label}</option>
              ));
                          }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
          });
        }
        }
        }
  }, [props.fareData]);


  useEffect(() => {
    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      if (context.logindata.utype !== "ta_user" && context.logindata.utype !== "sta_user") {
        const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid, "fetch": "mapping", is_admin:context.logindata.is_admin}
       } else {
        //Load all companies based on TA
        const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
        //Load all companies based Login type as TA
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }, [context.logindata]);

  const pickldata = (data) => {
    dispatch({ type: 'data', payload: data })
  }
  const compdata = (data) => {
    dispatch({ type: 'compData', payload: data })
  }

  const handleCategoryChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    getCompanyCatLsit(value).then(response => {
      if (response.data.suc) {
        setCompanyPick(response.data.res)
      } else {
        //   //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
  }

  const handleBranchChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    let selbr = state.branches.filter((val)=>val.props.value == e.target.value)
    dispatch({ type: 'data', payload: selbr })
    if (state.ctyp === "STA") {
      getCompanyTAList(value, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    } else {
      const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel,"branch_id":e.target.value}

      fetch_company_list(payload).then(response => {

        if (response.data.suc) {
          let authData = []
          for (let val of response.data.res){
            let auth = {
              "value": val.fk_company_id,
              "label": val.company_name,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

  const handleCompTypeChange = (e) => {
    if (e.target.id === "att") {
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      dispatch({ type: 'ofcs', payload: [] })
      setCompanyType(true);
    } else if (e.target.id === "ta") {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(false);
      setEnApplicable(true);
    } else {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(false);
      setEnApplicable(false);
    }
  }

  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateFareMap = () => {
    dispatch({ type: 'supplierError', payload: false })
    if (perBooking.perBookings.length === 0 && perTicket.perTickts.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please define Applicable On*' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
     }else if (state.supplier && state.supplier.length === 0) {
      dispatch({ type: 'supplierError', payload: true })
     }
       if (state.userType === "att_user") {
      if (state.aptype === "Branch" && state.ctyp === "ATT") {
        if (state.data.length === 0 && !state.enableDevices) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else {
        if((!state.ofcID || state.ofcID.length === 0) && state.enableOfcId==true) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select OFC ID' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (state.ctyp === "TA" && state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }

        if (state.ctyp === "STA" && state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }
      if (state.aptype === "Branch") {
        var exist = false;
        if (state.ctyp === "ATT") {
          if (state.data.length !== 0) {
            for (let val of state.data) {
              const payload = {
                "bid": val.value
              }
              serviceFeeArray.push(payload);
            }
            let data = []
            if(serviceFeeArray.length > 1){
              data = Utilities.getUniqData(serviceFeeArray);
            }
            if (serviceFeeArray.length !== data.length) {
              serviceFeeArray = data;
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Branch is already exist' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
              return false;
            }
          }
        } else if (state.ctyp === "TA") {
          for (let val of state.compData) {
            const payload = {
              "name": val.label
            }
            serviceFeeArray.push(payload);
          }
          let data = Utilities.getUniqData(serviceFeeArray);
          if ((serviceFeeArray.length !== data.length) && state.enableOfcId) {
            serviceFeeArray = data;
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Company is already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            let correction = serviceFeeArray[serviceFeeArray.length - 1].name
            serviceFeeArray = serviceFeeArray.filter((s, idx) => (s.name !== correction))
            return false;
          }
        }
        if (state.ctyp === "STA") {
          for (let val of state.compData) {
            const payload = {
              "name": val.label
            }
            serviceFeeArray.push(payload);
          }
          let data = []
            if(serviceFeeArray.length > 1){
              data = Utilities.getUniqData(serviceFeeArray);
            }
          if (serviceFeeArray.length !== data.length) {
            serviceFeeArray = data;
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            let correction = serviceFeeArray[serviceFeeArray.length - 1].name
            serviceFeeArray = uniqueJson(serviceFeeArray)
            return false;
          }
        }
      }
    }
    return true;
  }

  // ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      if (context.logindata.utype === "att_user" && state.aptype === "Branch") {
        if(!state.enableDevices){
          if(state.data.length > 0) {
            branches.push({ "id": state.data[0]?.props !== undefined ? state.data[0]?.props.value : state.data[0]?.value, "name": state.data[0]?.props !== undefined ? state.data[0]?.props.children : state.data[0]?.label }); }
          }
        if (state.compData) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices? state.data[0]?.props !== undefined ? state.data[0]?.props.children : state.data[0]?.label :"N/A"
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              dispatch({ type: 'compData', payload: [] })
              setData([])
              dispatch({ type: 'staCompanies', payload: [] })
              setCompanyPick([])
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if (context.logindata.utype === "att_user" && (state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || state.aptype === "Category") {
        let fList = [];
        var branchcatName = "";
        if (state.branches && state.bid !== 0 && state.aptype === "Branch") {
          state.branches.map((obj) => {
            if (Number(obj.value) === Number(state.bid)) {
              branchcatName = obj.label;
              branches.push({ "id": obj.value, "name": obj.label });
              category = [];
            }
          });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Category") {
          state.categories.map((obj) => {
            if (Number(obj.id) === Number(state.cat)) {
              branchcatName = obj.name;
              category.push({ "id": obj.id, "name": obj.name });
              branches = [];
            }
          });
        }
        if (state.compData) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname: branchcatName
              }

              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              dispatch({ type: 'compData', payload: [] })
              setData([])
              dispatch({ type: 'staCompanies', payload: [] })
              setCompanyPick([])
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if (context.logindata.utype === "att_user") {
        let fList = [];

        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              applyon: applicable,
              borcname: branchcatName
            }
            companys.push({ "id": obj.value, "name": obj.label });

            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      let paxData = [];
      if (applicable === "Per Booking") {
        paxData = perBooking.perBookings;
      } else {
        paxData = perTicket.perTickts;
      }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (context.logindata.utype === "ta_user") {
        if (state.ctyp === "TA") {
          const fare = {
            applyon: applicable,
            company: context.logindata.cnm,
            compType: "TA"
          }
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          fares.faresList = fares.faresList.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === value.place && t.name === value.name
              )
          );
          setShowFareList({ faresList: fares.faresList });
        } else {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                compType: "STA"
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      }
      if (context.logindata.utype === "sta_user") {
        const fare = {
          applyon: applicable,
          company: context.logindata.cnm,
          compType: "STA"
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      if (context.logindata.utype === "att_user") {
        if (!state.enabeleEdit) {
          createFareMapRequest(paxData, companyType, branches, companys, category);
        } else {
          createFMUpdatedPayload(paxData, companyType, branches, companys, category);
        }
      } else if (context.logindata.utype === "ta_user") {
        if (!state.enabeleEdit) {
          createFareMapTARequest(paxData, companys);
        } else {
          createFMUpdatedTAPayload(paxData, companys);
        }

      } else {
        if (!state.enabeleEdit) {
          createFareMapSTARequest(paxData, companys);
        } else {
          createFMUpdatedSTAPayload(paxData, companys);
        }
      }
    }
    dispatch({ type: 'branchAdded', payload: true })
  }
  const createFareMapTARequest = (paxData, companies) => {
    const payload = {
      "applon": applicable,
      "applist": uniqueJson(paxData),
      "class": classState.class,
      "ctyp": state.ctyp,
      "ltype": context.logindata.utype,
      "bid": [],
      "cat": [],
      "aptype": "",
      "cmpid": companies,
      "ofc": [],
      "taCmp": state.ta_comp,
      "utype": context.logindata.utype
    }
    props.sendFareData(payload);
  }
  const createFareMapRequest = (paxData, companyType, branches, companies, categories) => {
    const payload = {
      "applon": applicable,
      "applist": uniqueJson(paxData),
      "ctyp": companyType,
      "bid": branches,
      "cat": categories,
      "aptype": state.aptype,
      "cmpid": companies,
      "taCmp": state.ta_comp,
      "utype": companyType == "STA" ? "ta_user" : "att_user"
      // "ofc":state.ofcs
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedTAPayload = (paxData, companies) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "sid": props.markupId,
        "utype": "ta_user",
        "applon": applicable,
        "class": classState.class,
        "ltype": context.logindata.utype,
        "ctyp": state.ctyp,
        "aptype": "",
        "applist": uniqueJson(paxData),
        "bid": [],
        "cat": [],
        "cmpid": companies,
        "ofc": [],
        "taCmp": state.ta_comp,
        "region_type":context.logindata.region_type,
        "sales_channel":context.logindata.channel,
        'office_id':"All",
      }
    }
    createFMPayload(payload);
  }
  const createFMUpdatedPayload = (paxData, companyType, branches, companies, categories) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "sid": props.markupId,
        "applon": applicable,
        "class": classState.class,
        "ctyp": companyType,
        "ltype": context.logindata.utype,
        "aptype": state.aptype,
        "applist": uniqueJson(paxData),
        "bid": branches,
        "cat": categories,
        "cmpid": companies,
        "taCmp": state.ta_comp,
        "utype": "att_user",
        "supp": state.supplier.length > 0 ? state.supplier[0].value : null,
        "region_type":props.edit_region,
        "sales_channel":state.sales_chanel,
        'office_id':state.ofcID,
      }
    }
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    if (paxData && paxData.length > 0) {
      updateFMRequest(requestId, payload).then(response => {
        let fareList = [];
        if (response.data.suc) {
          dispatch({ type: 'hideDelete', payload: false })
          setShowFareList({ faresList: [] })
          editServiceFee(props.markupId).then(response => {
            if (response.data.suc) {
              var obj = response.data.res;
              var srvcmap = obj.srvcmap
              if (srvcmap && srvcmap.length > 0) {
                for (let value of srvcmap) {
                  var name = "";
                  if (value.branch_name !== null && value.branch_name !== "") {
                    name = value.branch_name;
                  } else if (value.category_name !== null) {
                    name = value.category_name;
                  }
                  const fares = {
                    fmid: value.pk_fare_map_id,
                    applyon: value.applon,
                    borcname: name,
                    company: (value.company_name) ? value.company_name : value.com_type,
                    sup: value.supplier_name + "[" + value.fk_office_id + "]"
                  }

                  fareList.push(fares);
                }
                dispatch({ type: 'enabeleEdit', payload: true })
                setShowFareList({ faresList: uniqueJson(fareList) })
              }
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in editServiceFee function---" + error
            );
          });
          dispatch({ type: 'notiMessage', payload: "ServiceFee updated successfully" })
          dispatch({ type: 'notiVarient', payload: "success" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
          dispatch({ type: 'reset', payload: true })
          setCompanyPick()
          setValue([])
          setPerTicket({ perTickts: [] })
          setPerBooking({ perBookings: [] })
          setApplicableType()
          setCompanyType(true)
          setEnApplicable(true)
          setPaxData()
          setData([])
          dispatch({ type: 'clear', payload: true })
        } else {
          dispatch({ type: 'notiMessage', payload: "ServiceFee Not updated.." })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
    } else {
      dispatch({ type: 'notiMessage', payload: "Please Select Applicable Data" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }
  }
  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
    return myUniqueArr;
  }
  const createFMPayload = (payload) => {
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    updateFMRequest(requestId, payload).then(response => {
      let fareList = [];
      if (response.data.suc) {
        for (let value of response.data.res.data) {
          var name = "";
          if (value.branch_name !== null && value.branch_name !== "") {
            name = value.branch_name;
          } else if (value.category_name !== null) {
            name = value.category_name;
          }
          const fares = {
            fmid: value.pk_service_fee_id,
            applyon: value.applicable_on,
            borcname: name,
            company: (value.company_name) ? value.company_name : value.com_type
          }


          fareList.push(fares);
        }
        setShowFareList({ faresList: fareList })
        dispatch({ type: 'notiMessage', payload: "Service Profile updated successfully" })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      } else {
        dispatch({ type: 'notiMessage', payload: "Service Profile Not updated.." })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  const createFareMapSTARequest = (paxData, companies) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "class": classState.class,
      "ctyp": "STA",
      "bid": barr,
      "cat": [],
      "aptype": "Branch",
      "cmpid": companies,
      "ofc": [],
      "taCmp": state.ta_comp,
      "utype": context.logindata.utype
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedSTAPayload = (paxData, companies) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mid": props.markupId,
        "utype": "sta_user",
        "applon": applicable,
        "class": classState.class,
        "ctyp": "STA",
        "aptype": "Branch",
        "applist": paxData,
        "bid": barr,
        "cat": [],
        "cmpid": companies,
        "ofc": [],
        "taCmp": state.ta_comp
      }
    }
    createFMPayload(payload)
  }
  const paxMarkup = (data) => {
    dispatch({ type: 'reset', payload: false })
    setApplicableType(data[0]);
    if (data[0] !== undefined && data[0] === "Per Booking") {
      perBooking.perBookings.push(data[1]);
      setPerBooking({ perBookings: perBooking.perBookings });
    } else {
      perTicket.perTickts.push(data[1]);
      setPerTicket({ perTickts: perTicket.perTickts });
    }
  }
  const setCompType = (resp) => {
    if (resp.ctyp === "ATT") {
      setCompanyType(true)
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      if (resp.bid && resp.bid !== undefined) {
        let compas = [];
        let data = [];
        for (let val of resp.bid) {
          const comp = {
            "value": val.id,
            "label": val.name
          }
          data.push(comp);
          compas.push(val.id);
        }
        setData(data);
        dispatch({ type: 'data', payload: resp.bid })
      }
    } else if (resp.ctyp === "TA") {
      dispatch({ type: 'bid', payload: resp.bid[0]?.id })
      setCompanyType(false)
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'enableOfcs', payload: true })
      if (resp.cmpid && resp.cmpid.length !== 0) {
        let compas = [];
        let data = [];
        for (let val of resp.cmpid) {
          const comp = {
            "value": val.id,
            "label": val.name
          }
          data.push(comp);
          compas.push(val.id);
        }
        setData(data);
        dispatch({ type: 'compData', payload: data })
      }
      if(state.sales_chanel === "B2C") {
        setCompanyPick([])
        let pyload
        if(state.supplier[0].value == 0) {
          pyload = {"office_id":state.ofcs[0].value,"userid": context.logindata.uid,"region_type":props.edit_region,"sales_channel":state.sales_chanel}
        } else {
          pyload = {userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: props.edit_region,sales_channel:state.sales_chanel}
        }
        fetch_company_list(pyload).then(response => {
          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "value": val.fk_company_id,
                "label": val.company_name,
                "sales_channel": val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      } else {
        getCompanyList(resp.bid[0].id).then(response => {
          if (response.data.suc) {
            let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });
    } else {
      dispatch({ type: 'bid', payload: resp.bid[0].id })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(true);
      setEnApplicable(false)
      setData([]);
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.id, "name": obj.name });
        }
      });
      dispatch({ type: 'ta_comp', payload: resp.taCmp })
      getCompanyTAList(resp.bid[0].id, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      let value = resp.taCmp[0].value
      getSTACompanyList(resp.taCmp[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }

  const updateRegion=(region)=>{
    dispatch({ type: "region_type", payload: region });
    props.getRegionType(region);

  }


  const handleRegionType = (e) => {
    if(e.length!==0 && !state.enableDevices){
    let branchesList = e.map((branch) => (
      <option value={branch.value}>{branch.label}</option>
    ));
    dispatch({ type: "RegionSelected", payload: true });
  }else{
    dispatch({type:"branchMenu", payload:[]})
    dispatch({type:"bid", payload:0})
  }
  };
  const loadCompanyData = (companies) => {
    let compas = [];
    let data = [];
    for (let val of companies) {
      const comp = {
        "value": val.id,
        "label": val.name
      }
      data.push(comp);
      compas.push(val.id);
    }
    setData(data);
    dispatch({ type: 'compData', payload: data })
  }

  const setAppType = (appType) => {
    if (appType === "Branch") {
      setEnApplicable(true);
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyPick("");
    } else {
      setEnApplicable(false);
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
    }
  }

  const editFareMap = (obj) => () => {
    retriveFMDetails(obj.fmid).then(response => {
      if (response.data.suc) {
        serviceFeeArray = serviceFeeArray.filter((d, id) => d.name !== obj.company)
        dispatch({ type: 'hideDelete', payload: true })
        dispatch({ type: 'reset', payload: false })
        dispatch({ type: 'enableUpdate', payload: true })
        dispatch({ type: 'fmId', payload: obj.fmid })
        var resp = response.data.res
        setPaxData(resp)
        if (resp.ctyp) {
          if (state.userType === "att_user") {
            setCompType(resp);
          }
          if(resp.bid !== undefined && resp.bid.length!==0){
            dispatch({ type: 'data', payload: [{value:resp.bid[0].id,label:resp.bid[0].name}] })
          }
          if (state.userType === "ta_user" && resp.cmpid && resp.cmpid.length !== 0) {
            if (resp.ctyp === "TA") {
              dispatch({ type: 'ctyp', payload: "TA" })
              setCompanyType(true)
            } else {
              loadCompanyData(resp.cmpid);
              dispatch({ type: 'ctyp', payload: "STA" })
              setCompanyType(false)
              const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
              if (companydata && companydata.length === 0) {
                getCompaniesList(pyload).then(response => {
                  if (response.data.suc) {
                    setCompanyPick(response.data.res)
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCompanyList function---" + error
                  );
                });
              }
            }
          }
        }
        if (resp.aptype) {
          setAppType(resp.aptype);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  const deleteFareMap = (obj, idx) => () => {
    if (obj !== undefined && obj.fmid !== undefined) {
      confirmBranchDlg(obj, 'Applicable Type', function () {
        deledeFMap(obj.fmid).then(response => {
          if (response.data.suc) {
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.fmid !== obj.fmid) });
            let companyList = serviceFeeArray;
            serviceFeeArray = companyList.filter((item, i) => item.name !== obj.company);
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      let companyList = serviceFeeArray;
      serviceFeeArray = companyList.filter((item, i) => item.name !== obj.company);
    }
  }


  const deletePaxMarakup = (id) => {
    dispatch({ type: 'reset', payload: false })
    delPaxServiceFee(id).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deletePaxMarakup function---" + error
      );
    });
  }

  const handleTypeChange = (e) => {
    if (e.target.id === "ta") {
      dispatch({ type: 'ctyp', payload: "TA" })
      setCompanyType(true);
      setCompanyPick([])
    } else {
      dispatch({ type: 'ctyp', payload: "STA" })
      setCompanyType(false);
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      if (companydata && companydata.length === 0) {
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }
  const updatePaxData = (data) => {
    dispatch({ type: 'reset', payload: false })

    if (data[0] !== undefined && data[0] === "Per Booking") {
      setPerBooking({ perBookings: data[1] });
    } else {
      setPerTicket({ perTickts: data[1] });
    }
  }

  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "ofcs", payload: [] });
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    setCompanyPick([])
    props.sendChannel(salesChanel)
  }

  const handleStaChange = (e) => {
    dispatch({ type: 'staCompanies', payload: [] })
    setData([])
    if (e && e.length > 0) {
      dispatch({ type: 'ta_comp', payload: e });
      getSTACompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  
  const updateSupplier=(sup)=>{
    if(sup!==undefined && sup.length!==0){
      props.sendSup(sup);
      if(sup[0].label==="Amadeus"){
        dispatch({type:"enableOfcId", payload:true})
      }else{
        dispatch({type:"enableOfcId", payload:false})
      }
      dispatch({type:"branchMenu", payload:[]})
      dispatch({type:"supplier", payload:sup})
      dispatch({type:"bid", payload:0})
      setCompanyPick([])
      if(sup[0].label!=="Amadeus"){
        if(state.sales_chanel==="B2B"){
        if(context.logindata.utype == "att_user") {
       if(context.logindata.is_admin){
        loadBranchesList({"region_type": state.region_type }).then(response => {
          if (response.data.suc) {
            let branchesList = response.data.res.map((branch) =>
              <option value={branch.value}>{branch.label}</option>
            );
            dispatch({ type: 'branchMenu', payload: branchesList })
            dispatch({ type: 'branches', payload: branchesList })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
      }else{
        loadBranchesList({"region_type": state.region_type,"uid": context.logindata.uid}).then(response => {
          if (response.data.suc) {
            let branchesList = response.data.res.map((branch) =>
              <option value={branch.value}>{branch.label}</option>
            );
            dispatch({ type: 'branchMenu', payload: branchesList })
            dispatch({ type: 'branches', payload: branchesList })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
      }
      }
      }else{
        setCompanyPick([])
        let pyload = {userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type,sales_channel:state.sales_chanel}
        fetch_company_list(pyload).then(response => {
          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "value": val.fk_company_id,
                "label": val.company_name,
                "sales_channel":val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
      }
    }
  }

  const updateOfcId=(ofc)=>{
    dispatch({type:"ofcs", payload:ofc})
    if(ofc.length!==0){
     if(state.sales_chanel!==""){
        if(state.sales_chanel==="B2C"){
          let payload="";
          if(context.logindata.is_admin){
            payload={ofc_id:ofc[0].value}
          }else{
            payload={ofc_id:ofc[0].value,uid:context.logindata.uid}
          }
          AttCommonSerivce.att_companies(payload).then(response => {
            if (response.data.suc && response.data.res.status === 200) {
              let authData = []
              for (let val of response.data.res.data){
                let auth = {
                  "value": val.value,
                  "label": val.label,
                  "sales_channel": val.sales_channel
                }
                authData.push(auth)
              }
              let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
              setCompanyPick(cmpanis)
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getBranchData function---" + error
            );
          });
        }else{
          let payload="";
          if(context.logindata.is_admin){
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_chanel:state.sales_chanel}
          }else{
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_chanel:state.sales_chanel,uid:context.logindata.uid}
          }
        }
     }else if(state.sales_chanel===""){

     }else if(state.region_type===""){
     }
    }
    if(ofc.length>0){
      dispatch({ type: "ofcID", payload: ofc[0].value });
    }
    props.sendOfcId(ofc)
  }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }

  return (
    (
      <>
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>

        <h4 className="subTitle plusHr mt-0">Profiles Service Fee</h4>
        {state.userType === "att_user" &&
          <Form.Row>
          <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={props.edit_salesChanel}/>
          <RegionType
            sendResponse={(e) => handleRegionType(e)}
            regionType={updateRegion}
            regionUpdate={props.edit_region?props.edit_region:""}
            flowType={"Service"}
          />
          <Form.Group as={Col} xs={2} className="selectplugin" controlId="supplier">
          <Select
           disabled={props.edit_supplier.length!=0?true:false}
            options={suppliers}
            values={state.supplier}
            onChange={updateSupplier}
          />
            {state.supplierError && (
              <p className="vError">Please Select Supplier</p>
            )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
         </Form.Group>
          {state.enableOfcId &&
          <Form.Group
            as={Col}
            xs={2}
            className="selectplugin"
            controlId="supplier"
           >
          <Select
            disabled= {props.edit_ofcid?true:false}
            options={state.officeIds}
            values={state.ofcs}
            onChange={updateOfcId}
          />
          <Form.Label>
            Office Id <sup>*</sup>
          </Form.Label>
        </Form.Group>
           }
           </Form.Row>
           }
        <ApplicableMarkup
          configType={props.configType}
          sendPaxData={paxMarkup}
          sendAppMarkupId={deletePaxMarakup}
          reset={state.reset}
          editPaxData={paxData}
          updateData={updatePaxData}
          disabledBtn={props.disabledBtn}
          sendChannel={updateSalesChanel}
          sendOfcId={updateOfcId}
          edit_salesChanel={state.edit_salesChanel}
          edit_ofcid={state.edit_ofcid}
        />
        <hr />
        <Form.Row>
          {state.userType === "att_user" && (
            <Form.Group as={Col}  lg={3} md={6} controlId="classes" className="aptype inputCheck">
              <Form.Label>Applicable Type</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  label="Travel Agent"
                  value={state.ctyp}
                  onChange={handleCompTypeChange}
                  checked={enApplicable}
                  custom
                  name="ctype"
                  id="ta"
                />
                <Form.Check
                  type="radio"
                  label="Sub Travel Agent"
                  value={state.ctyp}
                  onChange={handleCompTypeChange}
                  checked={!enApplicable}
                  custom
                  name="ctype"
                  id="sta"
                />
              </div>
            </Form.Group>
          )}

          {state.userType === "ta_user" && (
            <Form.Group as={Col} controlId="ctyp" className="inputCheck">
              <div className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  label={context.logindata.cnm}
                  value={state.ctyp}
                  onChange={handleTypeChange}
                  checked={companyType}
                  custom
                  name="ctype"
                  id="ta"
                />
                <Form.Check
                  type="radio"
                  label="STA"
                  value={state.ctyp}
                  onChange={handleTypeChange}
                  checked={!companyType}
                  custom
                  name="ctype"
                  id="sta"
                />
              </div>
              <Form.Label>Company Type</Form.Label>
            </Form.Group>
          )}

          {state.userType === "sta_user" && (
            <Form.Group as={Col} controlId="ctyp" className="inputCheck">
              <div className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  label={context.logindata.cnm}
                  value={state.ctyp}
                  checked={companyType}
                  custom
                  name="ctype"
                  className="ml-0"
                  id="sta"
                />
              </div>
              <Form.Label>Company Type</Form.Label>
            </Form.Group>
          )}

        <br/>
            <Form.Group as={Col}  lg={6} md={6} className="inputCheck">


          {state.enableDevices &&
          <>
           <Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
  <div className="d-flex align-items-center">

          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
            </div>
            </>
          }

        </Form.Group>
        </Form.Row>
        <Form.Row>
          {state.enbleType &&
            state.compType &&
            state.userType === "att_user" &&
            state.ctyp == "ATT" && (
              <Form.Group as={Col} className="picklist" xs={7}>
                {state.branches && (
                  <PickList
                    leftPaneLabel="Available Branch Names"
                    rightPaneLabel="Selected Branch Names"
                    selData={data}
                    dataList={state.branches}
                    sendData={pickldata}
                  />
                )}
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>
            )}

          { !state.enableDevices &&state.userType === "att_user" && state.branchMenu.length!==0 && (
            <Form.Group as={Col} xs={3} controlId="bid">
              <Form.Control
                as="select"
                value={state.bid}
                onChange={handleBranchChange}
              >
                <option value="">Select</option>
                {state.branchMenu}
              </Form.Control>
              <Form.Label>
                Branch<sup>*</sup>
              </Form.Label>
            </Form.Group>
          )}

          {!state.showCat && state.ctyp == "STA" && companydata && companydata.length!==0 &&  (
            <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
              <Select
                options={companydata}
                id="class"
                isSearchable={false}
                disabled={state.disabled}
                values={state.ta_comp}
                onChange={(value) => handleStaChange(value)}
              />
              <Form.Label>Ta Company</Form.Label>
            </Form.Group>
          )}
          {companydata &&
            companydata.length !== 0 &&
            state.userType === "att_user" &&
            state.ctyp === "TA" && (
              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList
                  leftPaneLabel="Available Company Names"
                  rightPaneLabel="Selected Company Names"
                  type={state.compType}
                  selData={data}
                  dataList={companydata}
                  sendData={compdata}
                />
                <Form.Label>
                  Company Name<sup>*</sup>
                </Form.Label>
              </Form.Group>
            )}

          {state.staCompanies &&
            state.staCompanies.length !== 0 &&
            state.userType === "att_user" &&
            state.ctyp === "STA" && (
              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList
                  leftPaneLabel="Available Company Names"
                  rightPaneLabel="Selected Company Names"
                  type={state.compType}
                  selData={data}
                  dataList={state.staCompanies}
                  sendData={compdata}
                />
                <Form.Label>
                  STA Company Name<sup>*</sup>
                </Form.Label>
              </Form.Group>
            )}
          {!companyType &&
            companydata &&
            companydata.length !== 0 &&
            state.userType === "ta_user" && (
              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList
                  leftPaneLabel="Available Company Names"
                  rightPaneLabel="Selected Company Names"
                  type={state.compType}
                  selData={data}
                  dataList={companydata}
                  sendData={compdata}
                />
                <Form.Label>
                  Company Name<sup>*</sup>
                </Form.Label>
              </Form.Group>
            )}
          <Form.Group as={Col} xs={2}>
            {!state.enableUpdate && !disableButton && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={showMarkupFares}
              >
                {" "}
                Add
              </Button>
            )}
            {disableButton && (
              <Button size="xs" variant="outline-primary" isDisabled={true}>
                {" "}
                Add
              </Button>
            )}
          </Form.Group>
        </Form.Row>
        {state.enableUpdate && (
          <div className="text-right buttonGrop mt-4">
            <Button
              size="xs"
              onClick={showMarkupFares}
              variant="outline-primary"
              type="submit"
            >
              Update Applicable
            </Button>
          </div>
        )}
        {fares.faresList.length !== 0 && (
          <div className="tableLayout">
            <Table>
              <thead>
                <tr>
                  <th>Applicable On</th>
                  {state.userType === "att_user" && <th>Branch</th>}
                  <th>Company Name</th>
                  <th className="text-right" width="8%">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {fares.faresList.map((fare, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <span>{fare.applyon}</span>
                      </td>
                      {state.userType === "att_user" && (
                        <td>
                          <span>{fare.borcname!==""?fare.borcname:"N/A"}</span>
                        </td>
                      )}
                      <td>
                        <span>{fare.company}</span>
                      </td>
                      <td className="text-right">
                        <Button
                          size="xs"
                          variant="link"
                          onClick={editFareMap(fare)}
                          className="ezyIcon icon-edit"
                          type="submit"
                        ></Button>
                        {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_D') && !state.hideDelete && fares.faresList && fares.faresList.length > 1 && (
                          <Button
                            size="xs"
                            variant="link"
                            onClick={deleteFareMap(fare, index)}
                            className="ezyIcon icon-delete"
                            type="submit"
                          ></Button>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </>
    )
  );
}
export default AirMarkupFareMapForm