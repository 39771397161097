import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import InnerLoader from '../../../common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import HotelServiceConfigMenu from '../../../common/subMenu/HotelServiceConfigMenu';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { editCrossSel, retrieveCrossSell } from "./operations";
import {editPromoCode,retrievePromo } from "../promoCode/operations";
import CreateCrossSell from "./index";


export const PromoCodeContext = React.createContext();


/**
 * Initial State Declaration
 */
const initialState = {
  promoCodeList: [], updatePromoCode: false, createCorssSel: false, showCreateBtn: true, cname: "", travelType: "", userType: "", userId: "",
  branchId: "", catId: "", branchList: [], catMenu: [], agencyEmail: "", travelData: [], applTypData: [], aplType: "", nameError: false, res: []
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editCompany':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will Load the all companies from service
 * @author: Lakshmi
 * @param {*}
 * @function CompanyManagement
 */
function CrossSellMain() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, setData] = useState()
  const [context, setContext] = useContext(Context);
  const [editData, setEditData] = useState();
  const [enableView, setEnableView] = useState(false)
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enabelType, setEnableType] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  //This fnction will call when component will load
  useEffect(() => {
    setIsLoadingMore(true)
    if (context.logindata !== "" && context.logindata.roles !== undefined) {
      const pyload = { "type": context.logindata.utype, "userid": context.logindata.uid }
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      retrieveCrossSell(pyload).then((resp) => {
        console.log("SEARCH RESP " + JSON.stringify(resp));
        if (resp.data.suc) {
          loadResponse(resp.data.res);
        } else {
          //Results ot found
        }
      })
    }
  }, [context.logindata]);

  
  const loadResponse = (response) => {
    let data = [];
    var enableEdit = false;
    if(context.logindata!==""){
      if(context.logindata.roles.includes('E_H_C_C')) {
        enableEdit = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem("USER_AUTH_DATA"));
      if(userData.roles.includes('E_H_C_C')) {
        enableEdit = true;
      }
    }
    for (let val of response) {

      data.push({ crosel_nm: val.crosel_nm, region_type:val.region_type, sales_channel: val.sales_channel, cnt_nm: val.cnt_nm, city_nm: val.city_nm,status:val.st==1?"Active": "In-Active", options: <> <span className="tbAction"><a className="ezyIcon icon-view" onClick={editPromoBtn(val, "view")}></a>{ enableEdit && <a className="ezyicon icon-edit" onClick={editPromoBtn(val, "edit")}></a>}</span></> })
    }
    //setIsLoadingMore(false);
    dispatch({ type: 'promoCodeList', payload: data })
    dispatch({ type: 'isLoadingMore', payload: false })
    
    if (response.length !== 0) {
      dispatch({ type: "res", payload: data });
    }
  }

  const columns = [
  {
    dataField: 'sales_channel',
    text: 'Sales Channel',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'crosel_nm',
    text: 'Cross Sell Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cnt_nm',
    text: 'Country',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'city_nm',
    text: 'City',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];



  const editPromoBtn = (obj, type) => () => {
    // setState({ editPromo: true })
    console.log(obj)
    editCrossSel(obj).then(response => {
      if (response.data.suc) {
        response.data.res.office_label = obj.office_label
        setData(response)
        dispatch({ type: 'updatePromoCode', payload: true })
        dispatch({ type: 'createCorssSel', payload: false })
        dispatch({ type: 'showCreateBtn', payload: false })
        setEditData(response)
        if (type === "view") {
          setEnableView(true)
        } else {
          setEnableView(false)
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editMarkup function---" + error
      );
    });
  }

  const resetSearch = () => {
    dispatch({ type: 'applTypData', payload: [] })
    dispatch({ type: 'travelData', payload: [] })
    dispatch({ type: 'cname', payload: "" })
    setFromDate("")
    setToDate("")
  }
  const handleSearch = () => {
    if ( state.cname !== "") {
      let valid = false;
      if (state.cname) {
        if (characterValidation(state.cname)) {
          dispatch({ type: "nameError", payload: false })
          valid = true
        } else {
          dispatch({ type: "nameError", payload: true })
        }
      } else {
        valid = true
      }
      if (valid) {
        const payload = {
          "type": state.userType,
          "userid": state.userId,
          "cname": state.cname,
          // "applicable": state.ap,
          // "fmdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
          // "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
        }
        console.log("SEARCH " + JSON.stringify(payload));
        retrievePromo(payload).then((resp) => {
          console.log("SEARCH RESP " + JSON.stringify(resp));
          if (resp.data.suc) {
            loadResponse(resp.data.res);
          } else {
            //Results ot found
          }
        })
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select at least one search criteria' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == 'cname') {
      dispatch({ type: "nameError", payload: false })
    }
    if (id == 'pcode') {
      dispatch({ type: "nameError", payload: false })
    }
    dispatch({ type: id, payload: value });
  }

  const createCompBtnClick = (e) => {
    dispatch({ type: 'createCorssSel', payload: true })
    dispatch({ type: 'showCreateBtn', payload: false })
  }

 

  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Hoel Services' />
      <Breadcrumb activePage="Cross Sell" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <HotelServiceConfigMenu active="Cross Sell" />
          {state.showCreateBtn &&
            <div className="contentArea cmnSearch">
              {state.showCreateBtn && context.logindata.roles !== undefined && context.logindata.roles.includes('C_H_C') && (
                <div className="buttonGrop pb-0">
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={createCompBtnClick}
                    loading={state.loadBranch}
                    className="addPlus ezyIcon icon-plus"
                    type="submit"
                  >  Create Cross Sell</Button>
                </div>
              )}
              {/* <div className="text-right pb-0 buttonGrop  creat_absolute">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  type="submit"
                  onClick={resetSearch}
                > Reset</Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={handleSearch}
                > Search</Button>
              </div> */}

            </div>
          }

          {state.createCorssSel && (
            <CreateCrossSell />
          )}
          {state.updatePromoCode && (
            <PromoCodeContext.Provider value={data}>
              <CreateCrossSell sendEditData={editData} disableBtn={enableView} resdata={state.res} />
            </PromoCodeContext.Provider>
          )}

        </div>
        {state.showCreateBtn ? (
          <div className="cardMainlayout tableRes p-0">
            {state.isLoadingMore ? <InnerLoader /> :
              (<>
                {state.promoCodeList.length !== 0 &&
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    classes="markup filterTbl"
                    data={state.promoCodeList}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    filter={filterFactory()}
                    pagination={state.promoCodeList.length >10 && state.promoCodeList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                    state.promoCodeList.length >25 && state.promoCodeList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                    state.promoCodeList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                  />
                  || state.promoCodeList.length === 0 &&
                  <NoRecord />
                }
              </>
              )}
          </div>
        ) : ('')}
      </div>
      <Footer />

    </>
  )
}
export default CrossSellMain