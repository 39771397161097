
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table, Modal } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import RegionType from '../../common/RegionType';
import SalesChannel from "../../common/SalesChannel";
import PickList from '../../../common/pickList/PickList';
import ShowHide from '../../../common/showHide/ShowHide';
import log from "loglevel";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import {companyList,getCompanyList,getCompaniesList,getOfcIdList,deleteOfficeidChildData,saveOfficeidConfigData,updateOffcieIdConfigDetails,editOfficeId,deleteOfficeidMap} from './operations';

let airlineMaster = require('../../../masterData/AirlinesMasterData.json');

/**
 * Initial State Declaration
 */
const initialState = {
  ofc_id:0,comp_id:0,branchmasterList: [], selsource: '', officeidData: [], airlineType: [], companyList: [], selbranch: [], selcompany: [], selgds: [],
  companyDisplay_data: [], is_branch_data: '', is_routeDetails: '', rr_json: '', selofficeid: [], selAirlineType: [], selAirlineName: [],
  offcie_id_display_data: [], is_Office_data: '', officeidDataList: [], data: '', notiMessageShow: '', notiMessage: '', notiVarient: '',compType:true,
  editOfficeIdData: [], isLoadingMore: '', enableView: '', officeIdName: '', capAmount: '', isDefault: true, editOfficeId: false, selectedValue: '',comp_map:[],comapny_temp_List:[],showdelete_popup:false,comp_map_id:null,delete_type:"",disCap:false,disDefcheck:false,
  isActive: true, agy: [], rts_edit_data: [], disableButton: false, rts: [], sel_off_id: '', airlinesList: [], nameError: false, amtError: false, airNError: false,branchList:[],bid:null,region_type:"",region_type_edit:"",branch_name:"",ofc_mp:[],final_ofc_data:[],
  airTError: false, offError: false, bnError: false, pfError: false, gdsinv: [], gdsinvError: false, branch: [], profile: [],isItineraryLoading:false,edit_salesChanel:"",sales_chanel:"",enableDevices:false,isDeviceAll:true,is_b2cweb:false,is_b2cmapp:false,is_b2cmweb:false, importFlow:true, importFlowCheck:false
};



const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function OfficeId(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [companydata, setCompanyPick] = useState([]);
  const [data, setData] = useState([])
  const [regionType, setRegionType] = useState("")

  /**
   * GDS Invinotry static data
   */
  const suppliers = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6}, {label:"Riya Travel", value: 7}];

  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })

  }, []);

  useEffect(() => {
    if(props.sendEditData!==undefined && props.sendEditData!==""){
      editOfficeId(props.sendEditData.id).then((res) => {
        if(res.data.suc){
        dispatch({ type: 'ofc_id', payload: props.sendEditData.id })
        dispatch({ type: 'officeIdName', payload: res.data.res.onm })
        dispatch({ type: 'sales_chanel', payload: res.data.res.cha })
        dispatch({ type: 'edit_salesChanel', payload: res.data.res.cha })
        dispatch({ type: 'region_type_edit', payload: res.data.res.reg })
        dispatch({ type: 'isActive', payload: res.data.res.act })
        setRegionType(res.data.res.reg)
        if(props.sendEditData.type==="Edit"){
          dispatch({ type: 'enabeleEdit', payload: true })
        }else{
          dispatch({ type: 'enableView', payload: true })
        }
        for(let val of res.data.res.com_map){
          var app="";
              if(val.is_b2cmapp && val.is_b2cmweb && val.is_b2cweb){
                app="All";
              }
            if(app===""){
              if(val.is_b2cmapp){
                app="B2C APP";
              }
              if(val.is_b2cweb){
                if(app!==""){
                  app=app+"-B2C Web";
                }else{
                  app="B2C Web";
                }
              }
              if(val.is_b2cmweb){
                if(app!==""){
                  app=app+"-M Web";
                }else{
                  app="M Web";
                }
              }
        }
        val.applbl_type=app;
        }
        dispatch({ type: 'final_ofc_data', payload: res.data.res.com_map })
        //Calling srvices
        if(res.data.res.cha==="B2B"){
          let req="";
          if(context.logindata.is_admin){
            req={"region_type":res.data.res.reg}
          }else{
            req={"region_type":res.data.res.reg,"uid":context.logindata.uid}
          }
          AttCommonSerivce.loadBranches(req).then(response => {
            if (response.data.suc){
                dispatch({ type: "branchList", payload: response.data.res });
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
          });
        }else{
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: res.data.res.reg}
      getCompaniesList(pyload).then(response => {
        let authData = []
        if (response.data.suc) {
          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: cmpanis });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
        }


        }
     });
    }else{
      dispatch({ type: "companyDisplay_data", payload: [] });
      dispatch({ type: "offcie_id_display_data", payload: [] });
      dispatch({ type: "final_ofc_data", payload: [] });
      dispatch({ type: "comp_map", payload: [] });
      dispatch({ type: "ofc_mp", payload: [] });
    }
  }, [props.sendEditData]);

  /**
     * @description: Load the Office id masterdata
     * @date:06-08-2020
     * @author:Rambabu
     */
  useEffect(() => {
    dispatch({ type: 'isLoadingMore', payload: true })
    getOfcIdList().then((res) => {
       let list=[];
       if(res.data.suc){
        for(let val of res.data.res){
          const req={
          value:val.id,
          label:val.office_id
          }
          list.push(req)
       }
      dispatch({ type: 'officeidData', payload: list })
       }
    });

  }, []);

  useEffect(() => {
    if (props.disabledBtn) {
      dispatch({ type: 'disableButton', payload: true })
    }
  }, [props.disabledBtn]);

  /**
   * @description: When we select GDS Drop down below event will call
   * @date:06-08-2020
   * @author:Rambabu
   * @param {*} e
   */
  const gdsInvEvent = (e) => {
    dispatch({ type: 'selgds', payload: e })
  }


  /**
   * @description:Handle change event for all Input text field
   * @author:Rambabu
   * @date:07-08-2020
   * @param {*} e
   */
  const handleChange = (e) => {

    const { id, value } = e.target;

    //Office id name
    if (id === 'officeIdName') {

      dispatch({ type: 'officeIdName', payload: value });
      dispatch({ type: 'nameError', payload: false })

    }
    //is active
    else if (id === 'isActive') {
      if(state.isActive){
        dispatch({ type: 'isActive', payload: false });
      }else{
        dispatch({ type: 'isActive', payload: true });
      }
    } //isDefault
    else if (id === 'isdefault') {
      if(state.isDefault){
        dispatch({ type: 'isDefault', payload: false });
      }else{
        dispatch({ type: 'isDefault', payload: true });
      }

    }//import flow
    else if (id === 'importflow') {
      if(state.importFlow){
        dispatch({ type: 'importFlow', payload: false });
      }else{
        dispatch({ type: 'importFlow', payload: true });
      }

    }//cap Amount
    else if (id === 'capAmount') {
      dispatch({ type: 'capAmount', payload: value });
      dispatch({ type: 'amtError', payload: false })

    }


  }

  /**
   * @description: When we click profile ADD button below event will call
   * @date:16-03-2023
   * @author:Laskhmi
   */
  const branchandcompanyAddBtnClick = (e) => {
    e.preventDefault(e);
    dispatch({ type: 'gdsinvError', payload: false})
    dispatch({ type: 'bnError', payload: false})
    dispatch({ type: 'pfError', payload: false})
    if(validateOFC()){
    if(((state.sales_chanel==="B2B" &&  state.bid!==null) || state.sales_chanel==="B2C")  && state.selgds.length!==0 && isArrayNotEmpty(state.selcompany)){
        let company_id = [];
        let comps = [];
        dispatch({ type: 'compType', payload: false })
        //branch is mandatory company selection is not mandatory(selected company will be added)
        if (isArrayNotEmpty(state.selcompany)){
          for (let c in state.selcompany) {
            let company_d = state.selcompany[c];
            comps=state.companyDisplay_data.filter(obj => Number(obj.company_id)===Number(company_d.value));
           if(comps.length===0){
            var app=state.isDeviceAll?"All":"";
            if(app===""){
              if(state.is_b2cmapp){
                app="B2C APP";
              }
              if(state.is_b2cweb){
                if(app!==""){
                  app=app+"-B2C Web";
                }else{
                  app="B2C Web";
                }
              }
              if(state.is_b2cmweb){
                if(app!==""){
                  app=app+"-M Web";
                }else{
                  app="M Web";
                }
              }
            }
            const display_data = {
              company_id: company_d.value,
              company_name: company_d.label,
              branch_name: state.branch_name,
              branch_id: state.bid,
              sup:state.selgds[0].label,
              applbl_type:app
            }
            company_id.push(company_d.value);
            state.companyDisplay_data.push(display_data);
          }else{
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Company already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            break;
          }
          }
          if(company_id.length!==0){
          let req={
              "bid": state.bid,
              "sup": state.selgds[0].label,
              "cid": company_id,
              "is_b2cweb": state.isDeviceAll?true:state.is_b2cweb,
              "is_b2cmweb": state.isDeviceAll?true:state.is_b2cmweb,
              "is_b2cmapp": state.isDeviceAll?true:state.is_b2cmapp
          }
          state.comp_map.push(req);
        }
            setCompanyPick([])
            if(!state.enabeleEdit){
              dispatch({ type: 'selgds', payload: [] })
              dispatch({ type: 'selbranch', payload: [] })
              setData([])
            }
            dispatch({ type: 'edit_salesChanel', payload: state.sales_chanel })
            dispatch({ type: 'region_type_edit', payload: regionType })
            dispatch({ type: 'compType', payload: true })
            setCompanyPick(state.comapny_temp_List)
            dispatch({ type: 'selcompany', payload: [] })
        }
  }else {
    if(state.selgds.length===0){
      dispatch({ type: 'gdsinvError', payload: true})
    }else if(state.sales_chanel==="B2B" && state.bid === null){
      dispatch({ type: 'bnError', payload: true })
    }else if(state.selcompany.length===0){
      dispatch({ type: 'pfError', payload: true })
    }
    }
  }
  }

  /**
   * @description: When we click profile ADD button below event will call
   * @date:16-03-2023
   * @author:Laskhmi
   */
  const createOFCIdsList = (e) => {
           e.preventDefault(e);
           dispatch({ type: 'offError', payload: false })
          if(state.selofficeid.length!==0){
            if(state.offcie_id_display_data.length < 3){
            let list = state.offcie_id_display_data.filter(obj => obj.ofc_id_label===state.selofficeid[0].label);
           if(list.length===0){
            const request={
              ofc_id_label:state.selofficeid[0].label,
              cap:state.capAmount!==""?state.capAmount:"N/A",
              isdef:state.isDefault && !state.disDefcheck?"YES":"NO",
              is_import:state.importFlow && !state.importFlowCheck?"YES":"NO",
              pk_id:null
             }
             state.offcie_id_display_data.push(request);
             const request1={
              ofc_id:state.selofficeid[0].value,
              cap:state.capAmount!==""?state.capAmount:"N/A",
              // isdef:state.isDefault,
              // is_import:state.importFlow,
              isdef:state.isDefault && !state.disDefcheck? true : false,
              is_import:state.importFlow && !state.importFlowCheck? true : false,
             }
             state.ofc_mp.push(request1);
            if(state.capAmount!=="" && state.capAmount!==undefined){
              dispatch({ type: 'disCap', payload: true })
            }
            if(state.isDefault){
              dispatch({ type: 'disDefcheck', payload: true })
            }
            if(state.importFlow){
              dispatch({ type: 'importFlowCheck', payload: true })
            }
            if(state.enabeleEdit && state.selgds.length!==0){
              let req={
                "bid": state.bid,
                "sup": state.selgds[0].label,
                "cid": [state.comp_id],
                "is_b2cweb": state.isDeviceAll?true:state.is_b2cweb,
                "is_b2cmweb": state.isDeviceAll?true:state.is_b2cmweb,
                "is_b2cmapp": state.isDeviceAll?true:state.is_b2cmapp
            }
            state.comp_map.push(req);
            }
            dispatch({ type: 'selcompany', payload: [] })
            dispatch({ type: 'selofficeid', payload: [] })
            dispatch({ type: 'capAmount', payload: "" })
           }else{
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Office Id already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
           }
          }else{
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Allow only Max 3 Office Ids' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          }
          }else{
            dispatch({ type: 'offError', payload: true })
          }          
  }
      



  /**
   * @description: When we select Office id dropdown below event will call
   * @date:07-08-2020
   * @author:Rambabu
   * @param {*} e
   */
  const officeidEvent = (e) => {
    dispatch({ type: 'selofficeid', payload: e })
    dispatch({ type: 'offError', payload: false })
  }


  /**
   * @description: When we select Airline type dropdown below event will call
   * @date:07-08-2020
   * @author:Rambabu
   * @param {*} e
   */
  const airlineTypeEvent = (e) => {
    dispatch({ type: 'selAirlineType', payload: e })
    dispatch({ type: 'airTError', payload: false })
  }
  /**
   * @description: When we select Airline name dropdown below event will call
   * @date:07-08-2020
   * @author:Rambabu
   * @param {*} e
   */

  const airlinenameEvent = (data) => {
    dispatch({ type: 'selAirlineName', payload: data })
    dispatch({ type: 'airNError', payload: false })
  }

  /**
   * @description: When we click profile ADD button this event will call
   * @date:06-08-2020
   * @author:Rambabu
   */
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const handleRegionType = (e) => {
    dispatch({ type: "branchList", payload: e });
    dispatch({type:"RegionSelected", payload: true })
    dispatch({ type: 'selbranch', payload: [] });
    dispatch({ type: 'selTa', payload: [] });
  }

  const updateRegion=(region)=>{
    dispatch({ type: "region_type", payload: region });
    setRegionType(region)
    setCompanyPick([]);
    dispatch({ type: "selbranch", payload: [] });
    dispatch({ type: "branchList", payload: [] });
    setData([])
    if(state.enableDevices && state.sales_chanel==="B2C"){
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: region}
      getCompaniesList(pyload).then(response => {
        let authData = []
        if (response.data.suc) {
          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel": val.sales_channel,
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: cmpanis });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });

    }
  }
  /**
   * @description:Find duplicate Officeid json objects
   * @author:Rambabu
   * @date:21-08-2020
   * @param {*} sorted_arr
   */
  function duplicateOffcieid(array) {
    const unique = new Set();
    const duplicate = array.some(element => (unique.size === unique.add(element.artype).size));
    return duplicate;
  }

  function getUniquebasedontwotags(arr, comp, compp) {

    // store the comparison  values in array
    const unique = arr.map(e => (e[comp] && e[compp]))

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  /**
  * @description:Find duplicate json objects
  * @author:Rambabu
  * @date:21-08-2020
  * @param {*} sorted_arr
  */
  function unique(array) {

    const unique = new Set();
    const duplicate = array.some(element => unique.size === unique.add(element.company_id).size);
    return duplicate;
  }

  /**
   * @description:Removed duplicates in json objects in json array based on All value comparison
   * @author:Rambabu
   * @date:21-08-2020
   * @param {*} jsonarray
   */
  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

    return myUniqueArr;
  }

  /**
   * @description:Find duplicate Officeid json objects based on office_id
   * @author:Rambabu
   * @date:27-08-2020
   * @param {*} sorted_arr
   */
  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  const updateDropdowns=()=>{
    if(state.companyDisplay_data.length===0){
      dispatch({ type: 'edit_salesChanel', payload: "" })
      dispatch({ type: 'region_type_edit', payload: "" })
    }
  }


  const handleRemovecompanyEvent = (data, index) => {
      let list = state.companyDisplay_data.filter((rt, sidx) => index !== sidx);
      dispatch({ type: 'companyDisplay_data', payload: list })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Record deleted successfully..' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      hidemessage()
      //updateDropdowns();
  };


  const handleRemoveofficeIdEvent = (data, index) => {
    if(data.pk_id !== null && data.pk_id !== undefined && data.pk_id !== ''){
        dispatch({ type: 'showdelete_popup', payload: true })
        dispatch({ type: 'comp_map_id', payload: data.pk_id })
        dispatch({ type: 'delete_type', payload: "officeid" })
    }else{
      let data = state.offcie_id_display_data.filter((rt, sidx) => index !== sidx);
      dispatch({ type: 'offcie_id_display_data', payload: data })

      // if(state.offcie_id_display_data.find(item => item.cap >= 0)) {
      //  dispatch({ type: 'disCap', payload: false })
      // }      

      if(data.find(item => item.cap > 0)) {
        dispatch({ type: 'disCap', payload: true })
      } else if(data.find(item => item.cap === "N/A")) {
        dispatch({ type: 'disCap', payload: false })
      }

      if(data.length == 1) {
        if(data.find(item => item.isdef === "NO")) {
          dispatch({ type: 'disDefcheck', payload: false })
        } else if(data.find(item => item.isdef === "YES")) {
          dispatch({ type: 'disDefcheck', payload: true })
        }
      
        if(data.find(item => item.is_import === "NO")) {
          dispatch({ type: 'importFlowCheck', payload: false })
        } else if(data.find(item => item.is_import === "YES")) {
          dispatch({ type: 'importFlowCheck', payload: true })
        }
      }

      if(data.length > 1) {
        let ofcIdIsdefList = data.filter(item => item.isdef === "YES")
        if(ofcIdIsdefList.length == 1) {
          dispatch({ type: 'disDefcheck', payload: true })
        } else {
          dispatch({ type: 'disDefcheck', payload: false })
        }

        let ofcIdIsimportList = data.filter(item => item.is_import === "YES")
        if(ofcIdIsimportList.length == 1) {
          dispatch({ type: 'importFlowCheck', payload: true })
        } else {
          dispatch({ type: 'importFlowCheck', payload: false })
        }
      }

      // if(state.offcie_id_display_data.find(item => item.is_import == "NO" || false)) { 
      //   dispatch({ type: 'importFlowCheck', payload: false })
      // } else if(state.offcie_id_display_data.find(item => item.is_import == "YES" || true)) {
      //   dispatch({ type: 'importFlowCheck', payload: true })
      // }

      // if(state.offcie_id_display_data.find(item => item.isdef == "NO" || false)) {
      //   dispatch({ type: 'disDefcheck', payload: false })
      // } else if(state.offcie_id_display_data.find(item => item.is_import == "YES" || true)) {
      //   dispatch({ type: 'disDefcheck', payload: true })
      // }
      
      if(data.length===0){
        dispatch({ type: 'disCap', payload: false })
        dispatch({ type: 'disDefcheck', payload: false })
        dispatch({ type: 'importFlowCheck', payload: false })
      }
      let data1 = state.ofc_mp.filter((rt, sidx) => index !== sidx);
      dispatch({ type: 'ofc_mp', payload: data1 })

      // console.log("SDebug isDefault", state.isDefault)

      // for(let val of data1) {
      //   if(val.cap >= 0) {
      //     dispatch({ type: 'disCap', payload: false })
      //   }
      //   if(val.cap === "N/A") {
      //     dispatch({ type: "capAmount", payload: ""})
      //     dispatch({ type: 'disCap', payload: true })          
      //     if(state.capAmount!=="" && state.capAmount!==undefined){
      //       dispatch({ type: 'disCap', payload: true })
      //     }
      //   }
      //   if(val.isdef === false) {
      //     dispatch({ type: "isDefault", payload: false})
      //     dispatch({ type: 'disDefcheck', payload: false })
      //     if(state.isDefault){
      //         dispatch({ type: 'disDefcheck', payload: true })
      //     } else {
      //       dispatch({ type: 'disDefcheck', payload: false })
      //     }
      //     console.log("SDebug isDefault", state.isDefault)
      //   }
      //   if(val.isdef === true) {
      //     dispatch({ type: "isDefault", payload: true})
      //     dispatch({ type: 'disDefcheck', payload: true })
      //   }
      //   if(val.is_import === false) {
      //    dispatch({ type: 'importFlowCheck', payload: true })
      //    dispatch({ type: 'importFlowCheck', payload: false })
      //   }
      //   if(val.is_import === true) {
      //    dispatch({ type: 'importFlowCheck', payload: false })
      //    dispatch({ type: 'importFlowCheck', payload: true })
      //   }
      // }

      
      
      // if(state.isDefault){
      //   dispatch({ type: 'disDefcheck', payload: false })
      // }
      
      // if(state.importFlow){
      //   dispatch({ type: 'importFlowCheck', payload: false })
      // }
      
      // if(state.capAmount!=="" && state.capAmount!==undefined){
      //   dispatch({ type: 'disCap', payload: false })
      // }
      
      // if(state.ofc_mp.find(item => item.cap >= 0)) {
      //   dispatch({ type: 'disCap', payload: false })
      //  }
 
      //  if(state.ofc_mp.find(item => item.is_import == false)) { 
      //    dispatch({ type: 'importFlowCheck', payload: false })
      //  } 
 
      //  if(state.ofc_mp.find(item => item.isdef == false)) {
      //    dispatch({ type: 'disDefcheck', payload: false })
      //  } 


      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Record deleted successfully..' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      hidemessage()
    }
    };


  const editOfcIdMap=(obj, index)=>{
           if(obj.id!==undefined){
            let data = state.final_ofc_data.filter((ofc, sidx) => obj.id === ofc.id);
            var app=""
            if(data[0].is_b2cmapp && data[0].is_b2cweb && data[0].is_b2cmweb){
              app="All";
            }
            if(app===""){
              if(data[0].is_b2cmapp){
                app="B2C APP";
              }
              if(data[0].is_b2cweb){
                if(app!==""){
                  app=app+"-B2C Web";
                }else{
                  app="B2C Web";
                }
              }
              if(data[0].is_b2cmweb){
                if(app!==""){
                  app=app+"-M Web";
                }else{
                  app="M Web";
                }
              }
            }
            const display_data = {
              company_id:data[0].cid,
              company_name: data[0].cnm,
              branch_name: data[0].bnm,
              branch_id: data[0].bid,
              sup:data[0].sup,
              applbl_type:app,
              id:obj.id
            }
            dispatch({ type: 'companyDisplay_data', payload: [display_data] })
            for(let val of data[0].ofc_mp){
              val.isdef=(val.isdef == "YES" || val.isdef === true)?"YES":"NO";
              val.is_import=(val.is_import == "YES" || val.is_import === true)?"YES":"NO";
            }
            dispatch({ type: 'bid', payload: data[0].bid })
            dispatch({ type: 'selbranch', payload: [{value:data[0].bid,label:data[0].bnm}] })
            dispatch({ type: 'selgds', payload: [{value:data[0].sup,label:data[0].sup}] })
            dispatch({ type: 'offcie_id_display_data', payload: data[0].ofc_mp })

            if(data[0].ofc_mp.find(item => item.cap > 0)) {
              dispatch({ type: 'disCap', payload: true })
            } else if(data[0].ofc_mp.find(item => item.cap === 0)) {
              dispatch({ type: 'disCap', payload: false })
            } else if(data[0].ofc_mp.find(item => item.cap === "N/A")) {
              dispatch({ type: 'disCap', payload: false })
            }

            if(data[0].ofc_mp.length == 1) {
              if(data[0].ofc_mp.find(item => item.isdef === "NO")) {
                dispatch({ type: 'disDefcheck', payload: false })
              } else if(data[0].ofc_mp.find(item => item.isdef === "YES")) {
                dispatch({ type: 'disDefcheck', payload: true })
              }
            
              if(data[0].ofc_mp.find(item => item.is_import === "NO")) {
                dispatch({ type: 'importFlowCheck', payload: false })
              } else if(data[0].ofc_mp.find(item => item.is_import === "YES")) {
                dispatch({ type: 'importFlowCheck', payload: true })
              }
            }

            if(data[0].ofc_mp.length > 1) {
              let ofcIdIsdefList = data[0].ofc_mp.filter(item => item.isdef === "YES")
              if(ofcIdIsdefList.length == 1) {
                dispatch({ type: 'disDefcheck', payload: true })
              } else {
                dispatch({ type: 'disDefcheck', payload: true })
              }

              let ofcIdIsimportList = data[0].ofc_mp.filter(item => item.is_import === "YES")
              if(ofcIdIsimportList.length == 1) {
                dispatch({ type: 'importFlowCheck', payload: true })
              } else {
                dispatch({ type: 'importFlowCheck', payload: false })
              }
            }


            setData([{value:data[0].cid,label:data[0].cnm}])
            dispatch({ type: 'selcompany', payload: [{value:data[0].cid,label:data[0].cnm}] })
            dispatch({ type: 'comp_id', payload: obj.cid })
          }else{
              const display_data = {
                company_id:obj.cid,
                company_name: obj.cnm,
                branch_name: obj.bnm,
                branch_id: obj.bid,
                sup:obj.sup,
                applbl_type:obj.applbl_type,
                id:undefined
              }
              dispatch({ type: 'companyDisplay_data', payload: [display_data] })
              dispatch({ type: 'offcie_id_display_data', payload: obj.ofc_map })
          }
      }
      //Deleting main OFC ID MAP
      const deleteOfcId=(data)=>{
        if(data.id!==undefined){
          dispatch({ type: 'showdelete_popup', payload: true })
          dispatch({ type: 'comp_map_id', payload: data.id })
          dispatch({ type: 'delete_type', payload: "company" })
        }else{
          let list = state.final_ofc_data.filter((ofc, sidx) => Number(data.cid) !== Number(ofc.cid));
          dispatch({ type: 'final_ofc_data', payload: list })
          for(let val of state.comp_map){
            let list1 = val.cid.filter((ofc, sidx) => Number(data.cid) !== Number(ofc));
            val.cid=list1;
          }
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Selected Record deleted successfully..' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          hidemessage()
        }
      }

      const deleteOfcIdMap=()=>{
        deleteOfficeidChildData({"type":state.delete_type,"id":state.comp_map_id}).then(response => {
          if (response.data.suc) {
            if(state.delete_type==="company"){
              let data = state.final_ofc_data.filter((ofc, sidx) =>state.comp_map_id !== ofc.id);
              dispatch({ type: 'final_ofc_data', payload: data })

              if(data.find(item => item.cap > 0)) {
                dispatch({ type: 'disCap', payload: true })
              } else if(data.find(item => item.cap === 0)) {
                dispatch({ type: 'disCap', payload: false })
              } else if(data.find(item => item.cap === "N/A")) {
                dispatch({ type: 'disCap', payload: false })
              }
  
              if(data.length == 1) {
                if(data.find(item => item.isdef === "NO")) {
                  dispatch({ type: 'disDefcheck', payload: false })
                } else if(data.find(item => item.isdef === "YES")) {
                  dispatch({ type: 'disDefcheck', payload: true })
                }
              
                if(data.find(item => item.is_import === "NO")) {
                  dispatch({ type: 'importFlowCheck', payload: false })
                } else if(data.find(item => item.is_import === "YES")) {
                  dispatch({ type: 'importFlowCheck', payload: true })
                }
              }
  
              if(data.length > 1) {
                let ofcIdIsdefList = data.filter(item => item.isdef === "YES")
                if(ofcIdIsdefList.length == 1) {
                  dispatch({ type: 'disDefcheck', payload: true })
                } else {
                  dispatch({ type: 'disDefcheck', payload: false })
                }
  
                let ofcIdIsimportList = data.filter(item => item.is_import === "YES")
                if(ofcIdIsimportList.length == 1) {
                  dispatch({ type: 'importFlowCheck', payload: true })
                } else {
                  dispatch({ type: 'importFlowCheck', payload: false })
                }
              }


            }else{
              let list = state.offcie_id_display_data.filter((rt, sidx) => state.comp_map_id !== rt.pk_id);
              for(let val of state.final_ofc_data){
                 if(val.cid===state.comp_id){
                  val.ofc_mp=list;
                  break;
                 }
              }
              dispatch({ type: 'offcie_id_display_data', payload: list })

              if(list.find(item => item.cap > 0)) {
                dispatch({ type: 'disCap', payload: true })
              } else if(list.find(item => item.cap === 0)) {
                dispatch({ type: 'disCap', payload: false })
              } else if(list.find(item => item.cap === "N/A")) {
                dispatch({ type: 'disCap', payload: false })
              }
  
              if(list.length == 1) {
                if(list.find(item => item.isdef === "NO")) {
                  dispatch({ type: 'disDefcheck', payload: false })
                } else if(list.find(item => item.isdef === "YES")) {
                  dispatch({ type: 'disDefcheck', payload: true })
                }
              
                if(list.find(item => item.is_import === "NO")) {
                  dispatch({ type: 'importFlowCheck', payload: false })
                } else if(list.find(item => item.is_import === "YES")) {
                  dispatch({ type: 'importFlowCheck', payload: true })
                }
              }
  
              if(list.length > 1) {
                let ofcIdIsdefList = list.filter(item => item.isdef === "YES")
                if(ofcIdIsdefList.length == 1) {
                  dispatch({ type: 'disDefcheck', payload: true })
                } else {
                  dispatch({ type: 'disDefcheck', payload: false })
                }
  
                let ofcIdIsimportList = list.filter(item => item.is_import === "YES")
                if(ofcIdIsimportList.length == 1) {
                  dispatch({ type: 'importFlowCheck', payload: true })
                } else {
                  dispatch({ type: 'importFlowCheck', payload: false })
                }
              }
            }
            dispatch({ type: 'showdelete_popup', payload: false })
            dispatch({ type: 'delete_type', payload: "" })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Selected Record deleted successfully..' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            hidemessage()
          }
        }).catch(function (error){
          console.log(
            "Exception occured in deleteOfcIdMap function---" + error
          );
        });
      }


  /**
     * @description: Cancel the entered Office id configuration
     * @date:07-08-2020
     * @author:Rambabu
     * @param {*} e
     */
  const handleCancel = (e) => {
    window.location = "/ezytrip/administration/officeid"
  }

  const handleClose=()=>{
    dispatch({ type: 'comp_map_id', payload: null })
    dispatch({ type: 'showdelete_popup', payload: false })
  }

  /**
  * @description: Save Office id configuration
  * @date:07-08-2020
  * @author:Rambabu
  * @param {*} e
  */
  const handleSaveOfficeidBtn = (e) => {
    dispatch({ type: 'nameError', payload: false })
    e.preventDefault();
    if (isNotNull(state.officeIdName) && state.final_ofc_data.length!==0){
        /* below is the construct main json for saving */
        dispatch({ type: "isItineraryLoading", payload: true });
        const main_json = {
          onm: state.officeIdName,
          reg: regionType,
          cha: state.sales_chanel,
          act: state.isActive,
          com_map:[{...state.comp_map[0]}],
          ofc_map:state.ofc_mp,
        }
        if(!state.enabeleEdit){
          main_json.crby=context.logindata.uid;
        }else{
          main_json.id=state.ofc_id;
          main_json.upby=context.logindata.uid;
        }
        const payload = {
          ofcreq: main_json
        }
        if(!state.enabeleEdit){
          saveOfficeidConfigData(payload).then(response => {
            if(response!==""){
            if (response.data.suc) {
              resetValues();
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Offcie Id saved successfully' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              window.location = "/ezytrip/administration/officeid"
            } else {
              if (isNotNull(response.data.res.message)) {
                dispatch({ type: "isItineraryLoading", payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: response.data.res.message })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
              } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Offcie Id Not saved' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
              }
            }
          }else{
                dispatch({ type: "isItineraryLoading", payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Office id already created for this company' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
          }
          }).catch(function (error) {
            console.error(
              "Exception occured in Save office id  function---" + error
            );
          });
        }else{
          updateOffcieIdConfigDetails(payload).then(response => {
            if (response.data.suc) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Offcie id updated successfully' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              hidemessage()
              window.location = "/ezytrip/administration/officeid"
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Offcie Id Not updated successfull' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
            }
          }).catch(function (error) {
            console.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        }

    } else if(state.officeIdName===""){
      dispatch({ type: 'nameError', payload: true })
    }else{
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please add atleast one configuration and proceed' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }

  }

  /**
   * @description: Reset All Values
   * @date:12-08-2020
   * @author:Rambabu
   */
  function resetValues() {
    dispatch({ type: 'officeIdName', payload: '' })
    dispatch({ type: 'capAmount', payload: '' })
    dispatch({ type: 'selgds', payload: [] })
    dispatch({ type: 'selbranch', payload: [] })
    dispatch({ type: 'companyList', payload: [] })
    dispatch({ type: 'selcompany', payload: [] })
    dispatch({ type: 'companyDisplay_data', payload: [] })
    dispatch({ type: 'is_branch_data', payload: false })
    dispatch({ type: 'is_routeDetails', payload: false })
    dispatch({ type: 'selofficeid', payload: [] })
    dispatch({ type: 'selAirlineName', payload: [] })
    dispatch({ type: 'selAirlineType', payload: [] })
    dispatch({ type: 'offcie_id_display_data', payload: [] })
    dispatch({ type: 'is_Office_data', payload: false })
  }

  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
      dispatch({ type: "branchList", payload: [] });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    //dispatch({ type: 'officeIds', payload: [] })
    setCompanyPick([])
  }

  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'is_b2cweb', payload: false })
        dispatch({ type: 'is_b2cmapp', payload: false })
        dispatch({ type: 'is_b2cmweb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'is_b2cweb', payload: false })
      }else{
        dispatch({ type: 'is_b2cweb', payload: true })
        if(state.is_b2cmapp && state.is_b2cmweb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'is_b2cmapp', payload: false })
      }else{
        dispatch({ type: 'is_b2cmapp', payload: true })
        if(state.is_b2cweb && state.is_b2cmweb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'is_b2cmweb', payload: false })
      }else{
        dispatch({ type: 'is_b2cmweb', payload: true })
        if(state.is_b2cweb && state.is_b2cmapp){
          updateStateVal();
        }
      }
    }
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'is_b2cweb', payload: false })
    dispatch({ type: 'is_b2cmapp', payload: false })
    dispatch({ type: 'is_b2cmweb', payload: false })
  }
  const handleBranchChange = (e) => {
    if(e!==undefined && e.length!==0){
    setCompanyPick([]);
    dispatch({ type: 'bid', payload: e[0].value })
    dispatch({ type: 'branch_name', payload: e[0].label })
      getCompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: cmpanis });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
  }
  const compdata = (data) => {
    dispatch({ type: 'selcompany', payload: data })
  }


  const validateOFC=()=>{
    if(state.sales_chanel===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Sales Channel' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(regionType===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Region' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    return true;
  }

  const creteOfcIdMap=()=>{
     if(state.companyDisplay_data.length!==0){
       for(let c of state.companyDisplay_data){
        const req={
          "cid":c.company_id,
          "bid":c.branch_id,
          "bnm":c.branch_name,
          "cnm":c.company_name,
          "sup":c.sup,
          "applbl_type" :c.applbl_type,
          "ofc_mp" :state.offcie_id_display_data
        }
        let compList=state.final_ofc_data.filter(comp=>Number(comp.cid)===Number(c.company_id));
        if(compList.length===0){
          state.final_ofc_data.push(req);
        }
       }
       dispatch({ type: 'companyDisplay_data', payload: [] })
       dispatch({ type: 'offcie_id_display_data', payload: [] })
     }else{
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter required fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
     }
  }

  return (
        <>
            <div className="cardMainlayout pt-0">
              <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
                  {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                            <div class="loaderPage">
                              <div class="loaderIcon"></div>
                            </div>
                          </div>
                          ) : ""}
                                <div className="contentArea">
                                {/* New Screen Start */}
                  <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="officeIdName">
                    <Form.Control type="text" placeholder="officeIdName"
                     value={state.officeIdName} onChange={handleChange}
                     disabled={state.enableView || state.enabeleEdit}/>
                    <Form.Label>Office Id Name<sup>*</sup></Form.Label>
                    {state.nameError && (
                      <p className="vError">Please Enter Office Id Name</p>
                    )}
                  </Form.Group>
                   {/* Sales Channel */}

                   <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={state.edit_salesChanel}/>

                  {/* Region Type */}
                     <RegionType
                      sendResponse={(e)=>handleRegionType(e)}
                      regionUpdate={state.region_type_edit}
                      disabled = {state.enabeleEdit}
                      regionError = {state.regionError}
                      regionType={updateRegion}
                      callBranch={state.enableDevices}
                    />
              </Form.Row>
             <div className="cardMainlayout">
              {/* GDS/Inventory  */}
              <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedGDs">
                    <Select
                      options={suppliers}
                      disabled={state.enableView || state.enabeleEdit}
                      values={state.selgds}
                      onChange={(value) => (dispatch({ type: 'gdsinv', payload: value }), dispatch({ type: 'gdsinvError', payload: false }), gdsInvEvent(value))}
                    />
                    {state.gdsinvError && (
                      <p className="vError">Please select Supplier</p>
                    )}
                    <Form.Label>Supplier<sup>*</sup></Form.Label>
                  </Form.Group>
                {state.sales_chanel==="B2B" && state.branchList.length!==0 && context.logindata.utype !== "ta_user"&& context.logindata.utype !== "sta_user" && !state.enableDevices &&
                <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedGDs">
                 <Select
                 options={state.branchList}
                 values={state.selbranch}
                 onChange={handleBranchChange}
                 disabled={state.enableView || state.enabeleEdit}
               />
               {state.bnError && (
                <p className="vError">Please Select Branch</p>
                )}
               <Form.Label>Branch<sup>*</sup></Form.Label>
             </Form.Group>
                 }
          <Form.Group as={Col} className="inputCheck officeIdd pt-0">
          {state.enableDevices &&
          <>
          <Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
             <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="mr-3"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="Desktop Web"
              custom
              className="mr-3"
              checked = {state.is_b2cweb}
              value={state.is_b2cweb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="M App"
              custom
              className="ml-3"
              checked={state.is_b2cmapp}
              value={state.is_b2cmapp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.is_b2cmweb}
              value={state.is_b2cmweb}
              onChange={handleDeviceTypeChange}
            />
           </div>
            </>
          }

        </Form.Group>
   </Form.Row>
    <Form.Row>
        <Form.Group as={Col} className="picklist" xs={7}>
        <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata!==undefined && companydata} sendData={compdata} />
        <Form.Label>Company Name<sup>*</sup></Form.Label>
        {state.pfError && (
        <p className="vError">Please Select Company</p>
          )}
        </Form.Group>

        <Form.Group>
          {!state.enabeleEdit &&
          <Button
          size="xs"
          variant="outline-primary"
          onClick={branchandcompanyAddBtnClick}
          > Add</Button>
          }
      </Form.Group>
    </Form.Row>
    {state.companyDisplay_data.length!==0 &&
<Form.Row>
          <Form.Group as={Col} xs={6}>
            <div className="selectedRout tableLayout">
              <Table>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Supplier</th>
                    {state.sales_chanel==="B2B" &&
                    <th>Branch</th>
                    }
                    {state.sales_chanel==="B2C" &&
                    <th>Applicable Type</th>
                    }
                    {!state.enabeleEdit && !state.enableView &&
                      <th>Action</th>
                    }
                  </tr>
                </thead>
                <tbody>
                {state.companyDisplay_data.length!==0 && state.companyDisplay_data.map((company, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                <td>
                                    <span>{company.company_name}</span>
                                  </td>
                                  <td>
                                    <span>{company.sup}</span>
                                  </td>
                                  {state.sales_chanel==="B2B" &&
                                  <td>
                                    <span>{company.branch_name}</span>
                                  </td>
                                  }
                                  {state.sales_chanel==="B2C" &&
                                  <td>
                                    <span>{company.applbl_type}</span>
                                  </td>
                                  }
                                  {company.id===undefined &&
                                    <td>
                                    <Button
                                      size="xs"
                                      onClick={() => handleRemovecompanyEvent(company, index)}
                                     variant="link" className="ezyIcon icon-delete">
                                    </Button>
                                  </td>
                                  }
                                </tr>
                              </React.Fragment>
                            ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>

         </Form.Row>
     }
    {/* Office Id */}
    <Form.Row>
    <Form.Group as={Col} xs={3} controlId="capAmount">
                    <Form.Control type="text" placeholder="Commission Name" value={state.capAmount}
                    onChange={handleChange}
                    disabled={state.enableView || state.disCap} />
                    <Form.Label>Cap Amount</Form.Label>
                    {state.amtError && (
                      <p className="vError">Please Enter Valid Amount</p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                    <Select
                      options={state.officeidData}
                      disabled={state.enableView}
                      values={state.selofficeid}
                      onChange={(value) => (console.log('officeId selected value----' + value), officeidEvent(value))}
                    />
                    <Form.Label>Office Id <sup>*</sup></Form.Label>
                    {state.offError && (
                      <p className="vError">Please Enter Office ID</p>
                    )}
                  </Form.Group>

                  {/* Check box*/}
                  <Form.Group controlId="isdefault" className="d-flex align-items-center mr-2">
                    <Form.Check type="checkbox" id="isdefault" label="Default Office ID"
                    disabled={state.enableView || state.disDefcheck}
                    defaultChecked value={state.isDefault} onChange={handleChange}
                    custom className="ml-0" />
                  </Form.Group>

                  <Form.Group controlId="importflow" className="d-flex align-items-center">
                    <Form.Check type="checkbox" id="importflow" label="Import Booking"
                    disabled={state.enableView || state.importFlowCheck}
                    defaultChecked value={state.importFlow} onChange={handleChange}
                    custom className="ml-0" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Button
                      size="xs"
                      variant="outline-primary"
                      isDisabled={state.enableView}
                      onClick={createOFCIdsList}
                    > Add</Button>
                  </Form.Group>
                </Form.Row>
          {state.offcie_id_display_data.length!==0 &&
          <Form.Row>
          <Form.Group as={Col} xs={6}>
            <div className="selectedRout tableLayout">
              <Table>
                <thead>
                  <tr>
                    <th>Office Id</th>
                    <th>Cap Amount</th>
                    <th>Default OID</th>
                    <th>Import Flow</th>
                    {((!state.enabeleEdit) || (state.enabeleEdit && state.offcie_id_display_data.length > 1)) &&
                     <th>Action</th>
                    }
                    
                  </tr>
                </thead>
                <tbody>
                {state.offcie_id_display_data.map((ofc, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                <td>
                                    <span>{ofc.ofc_id_label}</span>
                                  </td>
                                  <td>
                                    <span>{ofc.cap}</span>
                                  </td>
                                  <td>
                                    <span>{ofc.isdef}</span>
                                  </td>
                                  <td>
                                    <span>{ofc.is_import}</span>
                                  </td>
                                  <td>
                                    {((!state.enabeleEdit) || (state.enabeleEdit && state.offcie_id_display_data.length > 1)) &&
                                           <Button
                                           size="xs"
                                           onClick={() => handleRemoveofficeIdEvent(ofc, index)}
                                          variant="link" className="ezyIcon icon-delete">
                                         </Button>
                                    }

                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>
         </Form.Row>
            }
              </div>
              </div>
              {!state.enableView &&
                <Form.Row>
              <Form.Group as={Col} xs={7}>
                </Form.Group>
                <Form.Group as={Col} xs={5}>
              <div className='mt-2 mb-2 addConf'>
                  <Button
                    size="xs"
                    variant="outline-primary"
                    onClick={creteOfcIdMap}
                  > Add Config</Button>
                  </div>
                </Form.Group>
              </Form.Row>
              }

              <div>
              {state.final_ofc_data.length!==0 &&
                  <Form.Row>
                    <Form.Group as={Col} xs={12}>
                      <div className="selectedRout tableLayout">
                        <Table>
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Supplier</th>
                              <th>Applicable Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.final_ofc_data.map((comp, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <span>{comp.cnm}</span>
                                  </td>
                                  <td>
                                    <span>{comp.sup}</span>
                                  </td>
                                  <td>
                                    <span>{comp.applbl_type}</span>
                                  </td>
                                  <td>
                                    {state.enabeleEdit && comp.id!==undefined &&
                                      <Button
                                      size="xs"
                                      onClick={() => editOfcIdMap(comp, index)}
                                     variant="link" className="ezyIcon icon-edit">
                                    </Button>
                                    }
                                    <Button
                                      size="xs"
                                      isDisabled={state.enableView}
                                      onClick={() => deleteOfcId(comp, index)}
                                     variant="link" className="ezyIcon icon-delete">
                                    </Button>
                                  </td>
                                 </tr>
                                 <tr>
                                  <td colSpan={4}>

                                    <div className='cardMainlayoutt p-0'>
                                    <ShowHide visible={false}>
                                    <div className='cardMainlayout tableLayout p-0'>
                                    <Table>
                                <thead>
                                  <tr>
                                    <th>Office Id</th>
                                    <th>Cap Amount</th>
                                    <th>Is Default</th>
                                    <th>Import Flow</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {comp.ofc_mp.map((ofc, ind) => (
                                        <React.Fragment key={ind}>
                                          <tr>
                                        <td>
                                          <span>{ofc.ofc_id_label}</span>
                                        </td>
                                        <td>
                                          <span>{ofc.cap}</span>
                                        </td>
                                        <td>
                                          <span>{ofc.isdef}</span>
                                        </td>
                                        <td>
                                          <span>{ofc.is_import}</span>
                                        </td>
                                        </tr>
                                        </React.Fragment>
                                    ))}
                                  </tbody>
                                    </Table>
                                </div>
                                </ShowHide>
                                    </div>
                                  </td>
                                 </tr>

                                  </React.Fragment>
                                    ))}

                                </tbody>
                              </Table>

                      </div>
                    </Form.Group>
                  </Form.Row>
                }
              </div>

              <div className="switchPlus buttonGrop pb-0">
                <Form.Group as={Col} className="topSwitch">
                  <Form.Check
                    type="switch"
                    id="isActive"
                    label="Status"
                    checked={state.isActive}
                    value={state.isActive} onChange={handleChange}
                  /></Form.Group>
                  <Button size="xs"
                    variant="outline-dark"
                    type="submit"
                    onClick={handleCancel}
                  > Back</Button>
                {!state.enableView && !state.enabeleEdit && context.logindata.roles !== undefined && context.logindata.roles.includes('D_C_C') &&
                  <Button
                    size="xs"
                    onClick={handleSaveOfficeidBtn}
                    variant="outline-primary"
                    type="submit"
                  >Save</Button>
                }
                {state.enabeleEdit &&
                  <Button
                    size="xs"
                    onClick={handleSaveOfficeidBtn}
                    variant="outline-primary"
                    type="submit"
                  >Update</Button>
                }
              </div>
            </div>

    <Modal
        show={state.showdelete_popup}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="custom-ui">
              <span className="confirmD"></span>
              <h3>Are you sure?</h3>
              <p className="t-color2 mb-1">You want to delete the selected Record ?</p>
          </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              variant="secondary"
              onClick={handleClose}
            >No</Button>
            <Button
              size="xs"
              onClick={deleteOfcIdMap}
              variant="primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>
        </>
  )
}
export default OfficeId