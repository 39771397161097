import HotelConfigurationService from '../../../../services/administration/HotelConfigurationService';
import HotelCrossSellService from '../../../../services/administration/HotelCrossSellService';
// import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for promo
 * @author: arshad
 * @param {*}
 * @function Branch
 * @date : 18-11-23
 */


// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const creation = async (obj) => {
    let finalRes = [];
    await HotelCrossSellService.saveCrossSell(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// retrieve promo
export const retrieveCrossSell= async (obj) => {
    let ofcList = [];
    await HotelCrossSellService.getCorssSellList(obj).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const update = async (obj) => {
    let finalRes = [];
    await HotelCrossSellService.updateCrossSell(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const editCrossSel= async (id) => {
    let finalRes = [];
    await HotelCrossSellService.editCrossSell(id.id).then(response => {
        
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================

export const deletePromoFMap = async (id) => {
    let ofcList = [];
        await HotelCrossSellService.deletePromoFMap(id).then(response => {
            ofcList = response;
        });
    return Promise.resolve(ofcList);
}

