
import React, { useState,useContext } from 'react';
import Tabs from '../../common/tabs/Tabs';
import FlightSearch from './FlightSearch';
import OperationSearch from '../OperationSearch';
import { Context } from "../../../../App";

function Flight(props) {
    const [state, setState] = useState({})
    const [context, setContext] = useContext(Context);

    const [flightSearch, setFlightSearch] = useState({
        active: 'FlightTab'
    });

    const sendPnr = (data) => {
        props.sendPnrStats(data);

    }
    const newBooking = () => {
        props.newBooking(true)
    }

    const content = {
        FlightTab: <FlightSearch />,
        OperationsTab: <OperationSearch sendPnrStats={sendPnr} />
    };

    const flight_content = {
        FlightTab: <FlightSearch />
    }
    return (
        <>
            <div className={"tabLink flighttab"}>
                {
                    context && context.logindata&&context.logindata.roles&&context.logindata.roles.split(',').includes("I_M_P_N_R")?
                    <Tabs active={flightSearch.active} onChange={active => setFlightSearch({ active })}>
                    <span className="flightSelect" key="FlightTab" onClick={newBooking} >New Bookings</span>
                    <span className="flightSelect mangeBooking" key="OperationsTab">Manage Booking</span>
                    </Tabs>:
                <Tabs active={flightSearch.active} onChange={active => setFlightSearch({ active })}>
                <span className="flightSelect" key="FlightTab" onClick={newBooking} >New Bookings</span>
            </Tabs>
                }
                <div className="tabContent">
                    {content[flightSearch.active]}
                </div>
            </div>
        </>
    )
}


export default Flight;
