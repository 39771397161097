import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import GSTConfigurationService from "../../../../services/administration/GSTConfigurationService";
/**
 * @description: CRUD operations for Gst Configuration
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function office_ID_Master
 * @date : 10-03-2023
 */

export const getGstInfo = async (obj) => {
  let oidMaster = [];
  await GSTConfigurationService.retriveGST(obj).then((response) => {
    if (response.data.suc) {
      oidMaster = response.data.res;
    }
  });
  return Promise.resolve(oidMaster);
};
// ========================================================================
//  GST Create Operation
//  This will create GST and save the details
// ========================================================================
export const saveGstInfo = async (obj) => {
  let finalRes = {};
  await GSTConfigurationService.saveGST(obj).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

export const updateGSTInfo = async (obj) => {
  let finalRes = {};
  await GSTConfigurationService.updateGST(obj).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

export const deleteGSTInfo = async (id) => {
  let finalRes = {};
  await GSTConfigurationService.deleteGST(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

// fetching companies from an API
export const getCompanyListGst = async (code) => {
  let ofcList = [];
  await AttCommonSerivce.getCompanyList(code).then((response) => {
    ofcList = response;
  });
  return Promise.resolve(ofcList);
};

export const getCompaniesListGst = async (obj) => {
  let companies = [];
  await AttCommonSerivce.att_companies(obj).then((response) => {
    companies = response;
  });
  return Promise.resolve(companies);
};
