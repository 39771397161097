
import React, { useState, useEffect } from 'react';
import { Alert, Col, Form, Row, } from "react-bootstrap";
import Select from 'react-select';

/**
 * @description:This function will creaet all the Add AddMeal details
 * @author: Pravas
 * @param {*}
 * @function AddMeal details
 * @date : 30-06-2021
 */

function AddMeal(props) {
    const [mealDefault, setMealDefault] = useState([]);

       useEffect(() => {
        if(props.mealList!==undefined){
          if(props.mealList.includes("Select")){
            setMealDefault([])
          }else{
              if(props.mealList.length!==0 && props.mealList[0]!==undefined){
                setMealDefault({"value":props.mealList[0].value,"label":props.mealList[0].label})
                props.sendMealprice(props.mealList[0])
              }
          }
        }
        }, []);

    const setMealPrice=(obj)=>{
     props.sendMealprice(obj)
     if(!props.mealList.includes("Select")){
        setMealDefault({"value":obj.value,"label":obj.label})
     }
    }

    return (
        <>
            <div className="extraListLayout">
                <Row>
                    <Col xs={12} className="d-flex">
                        <div className="extraIcon ezyIcon icon-meal1">
                        </div>
                        <Col xs={11}>
                            <Form.Row>
                                <Form.Group as={Col} xs="12" className="selectplugin" controlId="cn">

                                     <Select
                                        options={props.mealList}
                                        isDisabled={props.disable}
                                        value={[mealDefault]}
                                        id="class"
                                        values={[]}
                                        onChange={(value) => (setMealPrice(value))}
                                        className='react-select-container-extra addMeals'
                                        classNamePrefix="react-select-extra"
                                        getOptionValue={(option) => option.code}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#F0F0F0',
                                              primary: '#475F7B',
                                            },
                                          })}
                                    />
                                    <Form.Label>Select Meal</Form.Label>
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default AddMeal;
