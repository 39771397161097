import axios from 'axios'

const B2B_URL = process.env.REACT_APP_B2B_URL
const API_URL = process.env.REACT_APP_B2B_URL
const HOTEL_SEARCH_URL = process.env.REACT_APP_SEARCH_URL
const HOTEL_BOOK_URL = process.env.REACT_APP_HOTEL_BOOK_URL
// const API_URL = "http://localhost:3005"
/**
 * @author: Lakshmi
 * @description: Integrated all Common services
 * @param {*} Service 
 */
class AttCommonSerivce {
    async submitRegForm(data) {
        return await axios.post(`${B2B_URL}/att/cms/registerform`,data); 
    }
    async getCLBforATT(id) {
        return await axios.post(`${API_URL}/att/credit/balance`,id)
    }
    async getCountryList() {
        return await axios.get(`${API_URL}/att/common/country`);
    }

    async getCityList(id) {
        return await axios.get(`${API_URL}/att/common/city/${id}`);
    }

    async getCompanyList(id) {
        return await axios.get(`${API_URL}/att/common/companies?commercialofcid=${id}`)
    }

    async getCompanyTAList(id,type) {
        return await axios.get(`${API_URL}/att/common/companies?commercialofcid=${id}&companyType=${type}`)
    }
    async getReportingUserList(id) {
        return await axios.get(`${API_URL}/att/common/reportingusers/${id}`)
    } 

    async getRolesList(data) {
        return await axios.post(`${API_URL}/att/role/fetch_user_roles`,data)
    }
    async getCRMRolesList(data) {
        return await axios.post(`${API_URL}/att/role/fetch_crm_roles`,data)
    }
    async fetch_company_list(data) {
        return await axios.post(`${API_URL}/att/common/fetch_company_list`,data)
    }
    async getBranchData() {
        return await axios.get(`${API_URL}/att/common/branch`)
    }

    async getuserBranchData(data) {
        return await axios.get(`${API_URL}/att/common/userbranch/${data.userId}`)
    }
    /**
     * @author: Rambabu
     * @description: Fetch resepective office id branch data
     * @date:16-09-2020 
     */
    async getOfficeidBranchData(id) {
        return await axios.get(`${API_URL}/att/common/getBranchDetails/${id}`)
    }

    async getCategoryList(data) {
        return await axios.post(`${API_URL}/att/common/fetch_categories`,data)
    }

    async getAirportData(code) {
        return await axios.get(`${API_URL}/att/common/airports/${code}`)
    }

    async getSuppOfcIds() {
        return await axios.get(`${API_URL}/att/common/officeids`)
    }

    async getOfficeIdListS(obj) {
        return await axios.post(`${API_URL}/att/common/fetch_officeids`, obj);
    }

    async getBranchesWithOfficeId(obj){
        return await axios.post(`${API_URL}/att/common/fetch_branch_list`, obj);
    }
    async getCompanyCatLsit(id) {
        return await axios.get(`${API_URL}/att/common/companies?categoryid=${id}`)
    }

    /**
     * @description: Load TA or STA all compnies
     * @date:22-09-2020
     * @author:Rambabu
     * @param {*} type
     * 
     */
    async getCompanyLsit(type) {
        console.log('company_type is---'+type);
        return await axios.get(`${API_URL}/att/common/companies?companyType=${type}`)
    }

    /**
     * @description: Load STA companies based on TA company id
     * @date:22-09-2020
     * @author:Rambabu
     * @param {*} id
     * 
     */
    async getSTACompanyList(id) {
        console.log('company_type is---'+id);
        return await axios.get(`${API_URL}/att/common/companies?companyid=${id}`)
    }

    /**
     * @description: Load branch currency and branch id resepective compnayid
     * @date:11-09-2020
     * @author:Rambabu
     * 
     */
    async getCompanyBranchData(id) {
        console.log('company_type is---'+id);
        return await axios.get(`${API_URL}/att/common/companies?company_id=${id}`)
    }

    /**
     * @description: Load the Office id masterdata
     * @date:06-08-2020
     * @author:Rambabu
     */
    async getOfficeIdList() {
        return await axios.get(`${API_URL}/att/common/fetchofficeids`);
    }

    /**
     * @author: Rambabu
     * @description: Fetch office id  data from config table
     * @date:16-09-2020 
     */
    async getOfficeIdConfigList(obj) {
        return await axios.post(`${API_URL}/att/common/getofficeids`, obj);
        
    }

     /**
     * @description: Load all TAS
     * @date:08-09-2020
     * @author:Lakshmi
     */
    async getAllTaList() {
        return await axios.get(`${API_URL}/att/common/companies?companyType=TA`)
    }
   /**
     * @description: Load ALL STAs based on TA
     * @date:08-09-2020
     * @author:Lakshmi
     */
    async getAllStaList(id) {
        return await axios.get(`${API_URL}/att/common/companies?companyid=${id}`)
    }
    
    async getCompanyCRMUSER(id) {
        return await axios.get(`${API_URL}/att/common/companies?region_type=${id}&sales_channel=B2C`)
    }
    /**
     * @description: Get updated CL Based on company
     * @date:10-09-2020
     * @author:Lakshmi
     */
    async getUpdateCreditLimit(obj) {
        return await axios.post(`${API_URL}/att/credit/balance`,obj)
    }

    /**
     * @description: Deduct CL Based on company
     * @date:10-09-2020
     * @author:Lakshmi
     */
    async deductCreditLimit(obj) {
        return await axios.post(`${API_URL}/att/credit/updatebalance`,obj)
    }

    /**
     * @description: Get Profiles List
     * @date:11-09-2020
     * @author:Lakshmi
     */
    async getProfilesData(utype,id) {
            return await axios.get(`${API_URL}/att/booking/profile?butype=${utype}&companyid=${id}`)
        }
        
    

    /**
     * @description: Get Travellr data based on Profile Id
     * @date:11-09-2020
     * @author:Lakshmi
     */
    async getTravellersData(id,type) {
        return await axios.get(`${API_URL}/att/booking/profile?proid=${id}&type=${type}`)
    }


    /**
     * @description: Get Companies based on branch id
     * @date:15-10-2020
     * @param {*} id 
     * @author:Rambabu
     */    
    async fetchcompany(id) {
        return await axios.get(`${API_URL}/att/common/fetchcompanydetails/${id}`)
    }

    async getBrnachesList(obj) {
        return await axios.get(`${API_URL}/att/common/branch`,obj)
    }

    async getBranches(obj) {
        console.log("REQ :"+JSON.stringify(obj));
        return await axios.post(`${API_URL}/att/common/fetch_branches`,obj)
    }

    async getCompaniesList(obj) {
        return await axios.post(`${API_URL}/att/common/fetch_companies`,obj)
    }
    

    async fetchFunctionalities(id) {
        return await axios.get(`${API_URL}/att/common/fetch_functionalites/${id}`)
    }

    async getCompanyRoles(id) {
        return await axios.get(`${API_URL}/att/common/companyRoles/${id}`)
    }
    
    /**
     * @description : fetch country codes by passing city codes 
     * @author: Azamuddin
     * @date : 28-11-2020
     * -----code : [sa,da]
     */
    async fetch_country_codes(code) {
        {
            return await axios.post(`${API_URL}/att/common/fetch_country_codes`,code)

        }
    }

    async att_companies(data) {
        return await axios.post(`${API_URL}/att/common/att_companies`,data)
    }
    
    async companypredticiveTAList(data) {
        return await axios.post(`${API_URL}/att/common/fetchCompPredictive`,data)
    }
    async getCustId(id) {
        return axios.get(`${API_URL}/att/common/fetchCompId/${id}`)
    }

    async fetch_offpay_role(id){
        return await axios.get(`${API_URL}/att/common/fetch_offpay_role/${id}`)
    }

    async fetchHotelCountries() {
        return await axios.get(`${HOTEL_SEARCH_URL}/countries`)
    }

    async fetchHotelCities(ccode,type) {
        return await axios.get(`${HOTEL_SEARCH_URL}/city_search?countryCode=${ccode}&cityName=${type}`)
    }

    async fetchHotels(ccode) {
        return await axios.get(`${HOTEL_SEARCH_URL}/properties?cityId=${ccode}&skip=0&limit=7000`)
    }

    async validateBookingSummary(data) {
        return await axios.post(`${API_URL}/att/common/validateBookingSummary`,data)
    }

    async changePWD(data) {
        return await axios.post(`${API_URL}/att/user/change-password`,data)
    }

    async getNotifiMasters() {
        return await axios.get(`${API_URL}/att/notification/getconfig`)
    }

    async updateNotiMaster(data) {
        return await axios.post(`${API_URL}/att/notification/updateconfig`,data)
    }

    async loadInsSummary(id) {
        return await axios.get(`${API_URL}/att/insurance/insurance-retrieve/${id}`)
    }

    async loadBranches(data) {
          return await axios.post(`${API_URL}/att/common/fetchBranches`,data)
    }
    async getOfficeID(obj) {
        return await axios.post(`${API_URL}/att/officeid/fetch-max-hits`, obj)
    }
    async saveMaxHits(data) {
        return await axios.post(`${API_URL}/att/officeid/save-max-hits`,data)  
      }
    async updateMaxHits(data){
        return await axios.put(`${API_URL}/att/officeid/update-max-hits`,data)  
    }
    async deleteMaxHits(id) {
        return await axios.delete(`${API_URL}/att/officeid/delete-max-hits/${id}`)
    }
    async loadOfficeIds(data) {
          return await axios.post(`${API_URL}/att/common/fetchBranches`,data)
    }
    
    async getCountryMasterData(data) {
        return await axios.post(`${API_URL}/att/common/region_config/retrieve`,data); 
    }

    async loadOfficeIdList(data) {
        return await axios.post(`${API_URL}/att/officeid/home/fetch`,data)
    }

    async getFetchGSTDetails(obj){
        return await axios.post(`${API_URL}/att/gst/fetch`,obj)
    }

    async getTdsDetails(obj){
        return await axios.post(`${API_URL}/att/common/getTdsInfo`,obj)
    }
    async searchService(obj) {
        return await axios.post(`${API_URL}/att/revenue/fetchSurcharge`,obj)
    }
        
    async createRevenue(obj) {
        return await axios.post(`${API_URL}/att/revenue/create`,obj)
    }
        
    async updateRevenue(data){
        return await axios.put(`${API_URL}/att/revenue/update/${data.id}`,data)  
    }
        
    async getRevenueData() {
        return await axios.get(`${API_URL}/att/revenue/retrieve`)
    }
        
    async editRevenue(id) {
        return await axios.get(`${API_URL}/att/revenue/edit/${id}`);
    }
    async deleteRevenue(id) {
        return await axios.delete(`${API_URL}/att/revenue/delete/${id}`)
    }
    async deleteRoutes(id) {
        return await axios.delete(`${API_URL}/att/revenue/deleteRoutes/${id}`)
    }
    async crmFunctionalities(id)
    {
        return await axios.get(`${API_URL}/att/common/fetch_crm_functionalites/${id}`)
    }
}

export default new AttCommonSerivce()
