import React, { Component, useState, useEffect, useReducer } from 'react';
// class ShowHideAccordion extends Component {

  const initialState = {
    visible:false,
  };


  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state
        };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

  function ShowHideAccordion(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const divClass = state.visible ? props.className : 'penal' +  ' hide'
    const accordion = () =>{
      dispatch({ type: 'visible', payload: props.visible || false })
    }


   const handleClick = (e)=> {
    dispatch({ type: 'visible', payload: !state.visible })
    	// this.setState({
      //   visible:!this.state.visible
      // });
    }

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       visible: this.props.visible || false,
    //     };
    //     this.handleClick = this.handleClick.bind(this)
    // }
    // handleClick(e) {
    // 	this.setState({
    //     visible:!this.state.visible
    //   });
    // }
        // return() {
        //   const{
        //     className = 'penal',
        //   } = this.props
        //   const divClass = this.state.visible ? className : className +  ' hide'
        //     return <>
        //         <div className="showHideLayout">
        //         <h5 onClick={this.handleClick}  className={"show-tg-line "+(this.state.visible ?  'up' : 'down')}><span><span className="panelTitle"> {this.props.title}</span> <Icon className="arrow" size={16} icon={this.props.icon} /></span> <div className="d-flex"><span className="subtitl">{this.props.cur} {this.props.subtitle}</span></div></h5>
        //         {/* <ReactCSSTransitionGroup transitionName="example"> */}
        //           <div className={divClass}> {this.props.children}</div>
        //         {/* </ReactCSSTransitionGroup> */}

        //       </div>

        //     </>
        // }

        return(
          <>
          <div className="showHideLayout">
                 <h5 onClick={handleClick}  className={"show-tg-line "+(state.visible ?  'up' : 'down')}><span  className="panelTitle"><span title={(props.title)}> {props.title}</span> <span className={"ezyIcon icon-"+props.icon}></span></span> <div className="d-flex"><span className="subtitl">{props.cur} {props.subtitle}</span></div></h5>
                {/* <ReactCSSTransitionGroup transitionName="example"> */}
                  <div className={divClass}> {props.children}</div>
                {/* </ReactCSSTransitionGroup> */}

            </div>

          </>
        )
    }


export default ShowHideAccordion;
