import AirConfigurationService from '../../../services/administration/AirConfigurationService';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function Branch
 * @date : 06-10-20
 */


// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = [];
    await AirConfigurationService.saveMarkupDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes = [];
    await AirConfigurationService.updateMarkupDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const editMarkup = async (id) => {
    let finalRes = [];

    await AirConfigurationService.editMarkup(id).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const branchDataList = async () => {
    let branchesList = [];
    await AttCommonSerivce.getBranchData().then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Category Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const categoryDataList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getCategoryList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}
export const loadBranchesList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const fetchBranches = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.loadBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getAirportData = async (code) => {
    let airportList = [];
    await AttCommonSerivce.getAirportData(code).then(response => {
        airportList = response;
    });
    return Promise.resolve(airportList);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const ofcDataList = async () => {
    let ofcList = [];
    await AttCommonSerivce.getSuppOfcIds().then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const getOfficeIdListS = async (payload) => {
    let ofcList = [];
    await AttCommonSerivce.getOfficeIdListS(payload).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyCatLsit = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyCatLsit(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const fetch_company_list = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.fetch_company_list(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deleteAppcableDate = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deleteAppcableDate(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deleteRoute = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deleteRoute(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================
export const retriveFMDetails = async (configType, id) => {
    let ofcList = [];
    if (configType == "Markup") {
        await AirConfigurationService.retriveFMDetails(id).then(response => {
            ofcList = response;
        });
    } else {
        await AirConfigurationService.retriveSFDetails(id).then(response => {
            ofcList = response;
        });
    }
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================

export const deledeFMap = async (configType, id) => {
    let ofcList = [];
    if (configType == "Markup") {
        await AirConfigurationService.deleteFareMap(id).then(response => {
            ofcList = response;
        });
    } else {
        await AirConfigurationService.deleteServiceMap(id).then(response => {
            ofcList = response;
        });
    }
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const delPaxMarakup = async (configType, id) => {
    let ofcList = [];
    if (configType == "Markup") {
        await AirConfigurationService.deletePaxMarakup(id).then(response => {
            ofcList = response;
        });
    } else {
        await AirConfigurationService.deletePaxServiceFee(id).then(response => {
            ofcList = response;
        });
    }
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const delClass = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deleteClass(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const updateFMRequest = async (configType, id, data) => {
    let ofcList = [];
    if (configType == "Markup") {
        await AirConfigurationService.updateFareMap(id, data).then(response => {
            ofcList = response;
        });
    } else {
        await AirConfigurationService.updateSrvcMap(id, data).then(response => {
            ofcList = response;
        });
    }
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const retrieveMarkups = async (obj) => {
    let ofcList = [];
    await AirConfigurationService.getMarkupList(obj).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const getuserBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompaniesList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const searchMarkups = async (obj) => {
    let branchesList = [];
    await AirConfigurationService.getSearchMarkups(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const getCompanyTAList = async (obj, type) => {
    let branchesList = [];
    await AttCommonSerivce.getCompanyTAList(obj, type).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const getSTACompanyList = async (id) => {
    let branchesList = [];
    await AttCommonSerivce.getSTACompanyList(id).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const getNotificationMasterData = async () => {
    let mastersList = [];
    await AttCommonSerivce.getNotifiMasters().then(response => {
        mastersList = response;
    });
    return Promise.resolve(mastersList);
}