import React, { Component, useState, useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";



function HotelServiceConfigMenuFun(props) {
  const { state } = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  /* const redirect = (event) => {
     event.preventDefault();
     if (event.target.id === "branch") {
       history.push("/ezytrip/administration/branch");
     }else if(event.target.id === "category"){
       history.push("/ezytrip/administration/category");
     }else if(event.target.id === "markup"){
       history.push("/ezytrip/administration/markup");
     }else if(event.target.id === "user"){
       history.push("/ezytrip/administration/user");
     }else if(event.target.id === "role"){
       history.push("/ezytrip/administration/role");
     }

   }*/
  //const [mainNav, setmainNav,] = useState(false);

  return (

    <div className="submenu">
      <ul>
        {context.logindata.roles && context.logindata.roles.includes('H_M') &&
          <li className={state.active === "Hotel Markup" ? 'active' : ''}>
            <Link
              to="/ezytrip/administration/hotel/markup"
              title="Markup"
            >
              Markup
              </Link>
          </li>
        }
        {context.logindata.roles && context.logindata.roles.includes('H_S') &&
          <li className={state.active === "Service Fee" ? 'active' : ''}>
            <Link
              to="/ezytrip/administration/hotel/serviceFee"
              title="Service Fee"
            >
              Service Fee
            </Link>
          </li>
        }
        {context.logindata.roles && context.logindata.utype === "att_user" &&
          <li className={state.active === "Hold Bookings" ? 'active' : ''}>
            <Link
              to="/ezytrip/administration/hotelServices/maxOnhold"
              title="Hold Bookings"
            >
              Hold Bookings
            </Link>
          </li>
        }

     {context.logindata.roles && context.logindata.utype === "att_user" &&
          <li className={state.active === "Commission" ? 'active' : ''}>
            <Link
              to="/ezytrip/administration/hotelServices/commdisc"
              title="Commission"
            >
              Commission Discount
            </Link>
          </li>
        }
        {/* {context.logindata.utype == "att_user" &&
               <li  className={state.active==="PromoCode" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/promocode"
              title="Promo Code"
            >
             Promo Code
            </Link>
              </li>} */}

        {/* <li  className={state.active==="Hotel Deals" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/dealCode"
              title="Deals"
            >
              Deals
            </Link>
              </li>
             <li  className={state.active==="Commission" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/commission"
              title="Commission"
            >
             Commission
            </Link>
              </li>
              <li  className={state.active==="Office Id" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/officeid"
              title="Office Id"
            >
              Office ID
            </Link>
              </li> */}
{context.logindata.roles && context.logindata.roles.split(",").includes('H_P_C') &&
               <li  className={state.active==="PromoCode" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/promocode"
              title="Promo Code"
            >
             Promo Code
            </Link>
              </li>}
         {context.logindata.roles && context.logindata.roles.split(",").includes('H_C_S') &&
              <li  className={state.active==="Cross Sell" ? 'active':''}>
              <Link
              to="/ezytrip/administration/hotel/crossell"
              title="Cross Sell"
            >
             Cross Sell
            </Link>
              </li>}

      </ul>
    </div>

  );
};



class HotelServiceConfigMenu extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      active: props.active

    };
  }

  render() {
    return <HotelServiceConfigMenuFun state={this.state} />
  }
}

export default HotelServiceConfigMenu;

