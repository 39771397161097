import React from 'react';
import { Col, Table } from "react-bootstrap";
import Utilities from '../../commonUtils/Utilities';
import FormateCurrency from '../../commonUtils/FormateCurrency';

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
const calculateRefundSup = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.refunSupPrice, "Add");
    }, 0);

    return total;
}

const calculateRefundCL = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.refundToCl, "Add");
    }, 0);

    return total;
}



function RefundSummary(props) {

    return (
        <>
            <Col>
                <div className="resultSection boxShadow bg-white recordInfo pb-0">
                    <h5 className="title m-0 ezyIcon icon-document" >
                        {props.flowType} Summary - {props.type}
                    </h5>


<div className='bookingListinfo'>
                    <Table className="m-0">
                        <thead>
                            <tr>
                                <th>Passenger Type</th>
                                <th>Sub Total x PAX</th>
                                <th>{props.flow} from Supplier</th>
                                {props.type === "ATT" &&
                                    <th>{props.flow} to TA</th>
                                }
                                {props.type === "TA" && props.butype==="ta_user" &&
                                    <th>{props.flow} to Customer</th>
                                }
                                {props.type === "TA" && props.butype==="sta_user" &&
                                    <th>{props.flow} to STA</th>
                                }
                                {props.type === "STA" &&
                                    <th>{props.flow} to Customer</th>
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {props.priceData && props.priceData.map((pr, idx) => (
                                <tr>
                                    <td>
                                        {pr.ptype === "ADT" &&
                                            <span>Adult</span>
                                        }
                                        {pr.ptype === "CHD" &&
                                            <span>Child</span>
                                        }
                                        {pr.ptype === "INF" &&
                                            <span>Infant</span>
                                        }
                                    </td>
                                    <td>
                                        <span>{pr.subtotal} x {pr.count}</span>
                                    </td>
                                    <td>
                                        <span>{<FormateCurrency value={pr.refunSupPrice} />}</span>
                                    </td>
                                    <td>
                                        <span>{<FormateCurrency value={pr.refundToCl} />}</span>
                                    </td>
                                </tr>
                            ))}
                            <tr className="totalAmount">
                                <td colSpan="2" className="text-right"><strong>Total :</strong></td>
                                <td>{calculateRefundSup(props.priceData)} </td>
                                <td>{calculateRefundCL(props.priceData)}</td>

                            </tr>
                        </tbody>

                    </Table>
                    </div>
                </div>
            </Col>

        </>
    )
}
export default RefundSummary