import React, { Component } from "react";
// import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import slide1 from "../../../assets/images/slidernew.jpg";
import slide2 from "../../../assets/images/flightone.jpg";
import slide3 from "../../../assets/images/hotelone.jpg";
import slide4 from "../../../assets/images/insurancethree.jpg";
import { Fade } from 'react-slideshow-image';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// const slideImages = [
//     '../../../assets/images/newBnr.jpg',
//     '../../../assets/images/bannerTop.jpg',
// ];

// const DashBordSlider = () => {
//     return (
//       <div className="dashbordSlider">
//         <Slide easing="ease">
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slide1})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slide2})`}}>
//             </div>
//           </div>
//         </Slide>
//       </div>
//     )
// };

// export default DashBordSlider;
export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
     <div className="dashbordSlider">
      <div className="slide-container">
      <Slider {...settings}>
      <div>
      <img src={slide4} />
      </div>
      <div>
      <img src={slide3} />
      </div>
      <div>
      <img src={slide2} />
      </div>
      </Slider>
      </div>
      </div>


    );
  }
}

