
import React, { useState, useEffect, useRef, useReducer, useContext, useCallback } from 'react';
import { Form, Button, Col, Row, OverlayTrigger, Tooltip, Modal, Alert } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import 'react-input-range/lib/css/index.css';
import Sticky from '../../../common/sticky/Sticky'
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import ChangeHotelSearch from '../common/reviewpage/ChangeHotelSearch';
import MapView from '../common/reviewpage/MapView';
import Amenities from '../common/reviewpage/Amenities';
import Policies from '../common/reviewpage/Policies';
import log from "loglevel";
import { Context } from "../../../../../App";
import { getHotelReviewContent, getHotelRooms, hotelPriceCheck } from '../../../bookingFlows/hotel/pages/operations';
import ImageSlider from '../common/reviewpage/ImageSlider';
import { Slide } from 'react-slideshow-image';
import { Scrollbars } from 'react-custom-scrollbars';
import Utilities from '../../../commonUtils/Utilities';
import RoomOccupancy from './RoomOccupancy';
import RoomHeader from './RoomHeader';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import Linkimage from '../../../../assets/images/link.gif'
import GuestReviews from '../../hotel/common/result/GuestReviews';
import { isNotNull, isArrayNotEmpty } from '../../../commonUtils/validators';
//import Linkimage from '../../../../assets/images/link.gif'
// import ReturnHeaderLoader from './ReturnHeaderLoader'
export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const HTL_CTID = "ctid";
export const SEL_PID_REQ = "pidRequest";
export const USER_AUTH_DATA = "userAuthData";
export const AUTH_DATA = "authData";
export const IS_PACKAGE = "isPack";

const _ = require("lodash");
let non_room_only = ["2194", "2208", "2205", "2104", "2193", "1073742786", "2103", "2197", "2111", "2206", "1073742618", "1073742857", "1073744459", "1073743287", "2199", "1073742552", "2108"];

const initialState = {
  hotelContentResp: "", loading: false, hotelRoomResp: "", showPop: false, ocpsList: [], finalRoomsList: [], showRoom: false, notiMessage: '', notiMessageShow: false, notiVarient: '', selectedRooms: [], selRoomsSize: 0, roomInfo: "", hid: "", availContnt: false, availRooms: false, boardBasisList: [], onflyMkp: 0, roomOneOcpList: [], roomTwoOcpList: [], roomThreeOcpList: [], roomForOcpList: [], roomFiveOcpList: [], roomSixOcpList: [], roomSevenOcpList: [], roomEightOcpList: [], showRoomChoice: false, precepernight: false, boardBasisOne: [], boardBasisTwo: [], boardBasisThree: [], boardBasisFour: [], boardBasisFive: [], boardBasisSix: [], boardBasisSeven: [], boardBasisEight: [], bbupdated: false, noofNights: false, cin: "", cout: "", chdage: "", pid: "", updateResults: true, priceNote: "", serviceFee: [], showOcpPop: false, cityId: 0, roomObj: "", roomImages: [], showPop: false,
  guestReviews: [], reviewObj: "", expRating: 0, usrRating: 0, reviewShow: false, respAvail: false,
  roomOneOcpList_data: [], roomTwoOcpList_data: [], roomThreeOcpList_data: [], roomForOcpList_data: [], roomFiveOcpList_data: [], roomSixOcpList_data: [], roomSevenOcpList_data: [], roomEightOcpList_data: [], showClear: false, isRefundable: false, isNonRefundable: false,selectedBoardbasis:'',enableRooms:false,isExpedia:false,sel_rm_combination:"",temp_request:""
};

let finalRoomsArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will load the selected Hotel details
 * @author: Lakshmi
 * @param {*}
 * @function Confirm details
 * @date : 10-02-2021
 */
function HotelReview(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [showMoreRooms, setShowMoreRooms] = useState(false)
  const [chRoom, setChRoom] = useState(true)
  const [overview, setOverview] = useState()
  const [amenities, setAmenities] = useState()
  const [policies, setPolicies] = useState()
  const [htlData, setHotlData] = useState()
  const history = useHistory();
  const findRoom = useRef(null)
  const chRoomSec = useRef(null)
  const overviewSec = useRef(null)
  const amenitiesSec = useRef(null)
  const policiesSec = useRef(null)
  const executeScrolltop = () => findRoom.current.scrollIntoView()
  const executeScroll = () => chRoomSec.current.scrollIntoView()
  const executeScroll1 = () => overviewSec.current.scrollIntoView()
  const executeScroll2 = () => amenitiesSec.current.scrollIntoView()
  const executeScroll3 = () => policiesSec.current.scrollIntoView()
  const [show, setShow] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [srequest, setRequest] = useState(props.location);
  const [searchRequest, setSearchRequest] = useState();
  const [roomOneOcpListshow, setRoomOneOcpList] = useState(false)
  const [roomOtwocpListshow, setRoomtwoOcpList] = useState(false)
  const [roomthreeOcpListshow, setRoomthreeOcpList] = useState(false)
  const [roomfourOcpListshow, setRoomfourOcpList] = useState(false)
  const [roomfiveOcpListshow, setRoomfiveOcpList] = useState(false)
  const [roomsixOcpListshow, setRoomsixOcpList] = useState(false)
  const [roomsvnOcpListshow, setRoomsvnOcpList] = useState(false)
  const [roomeightOcpListshow, setRoomeightOcpList] = useState(false)
  const [roomeListshow, setRoomeListshow] = useState(false)
  const [showFcList, setShowFcList] = useState(false);
  const [refund, setRefund] = useState(false);
  const [nonRefund, setNonRefund] = useState(false);
  const handleClose = () => setShow(false);
  const [isLoading, setIsloading] = useState(false);
  const [tdsPercentage, setTdsPercentage] = useState(5);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [loader, setloader] = useState(false)
  useEffect(() => {
    //Call Hotel details
    if (window.htl !== undefined ) {
      var htldta = JSON.parse(window.htl)
      setHotlData(htldta);
      setRequest(htldta);
      window.sessionStorage.setItem(SEL_PID_REQ, window.htl);
      window.sessionStorage.setItem(IS_PACKAGE, window.isPackage);
      console.log("called",window.isPackage);
      if (htldta.cont !== undefined && htldta.cont._id !== undefined && htldta.cont._id !== "") {
        callHotelDetails(htldta,window.isPackage);
        dispatch({ type: 'pid', payload: htldta.pid })
        dispatch({ type: 'serviceFee', payload: htldta.servicefee })
      }
    } else {
      var htldta = JSON.parse(window.sessionStorage.getItem(SEL_PID_REQ));
      var isPack = window.sessionStorage.getItem(IS_PACKAGE);
      setHotlData(htldta);
      setRequest(htldta)
      if (htldta.cont !== undefined && htldta.cont._id !== undefined && htldta.cont._id !== "") {
        callHotelDetails(htldta,isPack);
        dispatch({ type: 'pid', payload: htldta.pid })
        dispatch({ type: 'serviceFee', payload: htldta.servicefee })
      }
    }
    if (window.cmpid !== undefined ) {
      window.sessionStorage.setItem("CMPID", window.cmpid);
    }
    var userData = localStorage.getItem(USER_AUTH_DATA);
    window.sessionStorage.setItem(AUTH_DATA, userData);
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (props.location !== undefined && props.location.state !== undefined && props.location.state.req !== undefined && props.location.state.req.ismodify) {
      console.log("Called here",props.location.state.req.package)
      var htldta = JSON.parse(window.sessionStorage.getItem(SEL_PID_REQ));
      callHotelPidSearch(state.hotelContentResp, props.location.state.req, props.location.state.req.package,htldta);
    }
  }, [props.location])

  const openReviewPopup = (obj) => () => {
    dispatch({ type: 'expRating', payload: state.hotelContentResp.expediareview })
    dispatch({ type: 'usrRating', payload: state.hotelContentResp.urat })
    dispatch({ type: 'reviewObj', payload: state.hotelContentResp.guestreview })
    dispatch({ type: 'reviewShow', payload: true })

  }

  const chooseRoom = () => {
    let request = JSON.parse(localStorage.getItem(HTL_REQUEST));
    request.package ===1?request.package=0:request.package=1
    localStorage.setItem(HTL_REQUEST,JSON.stringify(request))
var w = window.open("/ezytrip/bookings/hotel/review");
    w.htl = JSON.stringify(htlData);
    w.cmpid = context.logindata.cid;
    w.isPackage = JSON.parse(window.sessionStorage.getItem(IS_PACKAGE))?false:true;
   }


  //Creating Total Occupancies List
  const createOccupancyList = (resp, contRooms, requst) => {
    let ocpsList1 = []; let ocpsList2 = []; let ocpsList3 = []; let ocpsList4 = []; let ocpsList5 = []; let ocpsList6 = []; let ocpsList7 = []; let ocpsList8 = [];
    let boardbsis = []
    let selRoomList = [];
    let count = 1;
    if (requst !== undefined && requst.roomsInfo !== undefined) {
      for (let room of requst.roomsInfo) {
        var ocp = "";
        var age = "";
        if (room.chdArray.length !== 0) {
          for (let val of room.chdArray) {
            if (age === "") {
              age = val.age;
            } else {
              age = age + "," + val.age;
            }
          }
          ocp = room.adt + "-" + age;
        } else {
          ocp = room.adt;
        }
        let rooms = resp.rooms !== undefined && resp.rooms.filter((ob) => ob.ocp.includes(ocp) && ob.rmno == room.rmno);
        for (let val of rooms) {
          let rmImg = contRooms[val.rmid]
          for (let price of val.rmpr) {
            let imge = "";
            if (rmImg !== undefined && rmImg.images !== undefined && rmImg.images.length !== 0) {
              imge = rmImg.images[0].links["1000Px"];
            }
            if(price.refundable == true){
              dispatch({type: "isRefundable", payload: true})
            }
            if(price.refundable == false){
              dispatch({type: "isNonRefundable", payload: true})
            }
            //Cal GST
            var htldta = JSON.parse(window.sessionStorage.getItem(SEL_PID_REQ));
            if(requst.region_type!==undefined && requst.region_type==="India"){
              calculateGST(price,htldta);
            }else{
              price.pr[0].gst_total=0;
            }
            price.pr[0].tds_val=0;
            //Cal TDS
            if(requst.region_type!==undefined && requst.region_type==="India"){
              if(price.pr[0].market_fee!==undefined && price.pr[0].market_fee!==0){
                calculateTds(price,htldta);
              }else{
                price.pr[0].tds_val=0;
              }
            }else{
              price.pr[0].tds_val=0;
            }
            const payload = {
              "count": count,
              "rmindex": room.rmno,
              "rmimg": (imge !== undefined && imge.href !== undefined) ? imge.href : "",
              "imgecnt": (rmImg !== undefined && rmImg.images !== undefined && rmImg.images.length !== 0) ? rmImg.images.length : 0,
              "adt": room.adt,
              "chd": room.chd,
              "chdage": age !== undefined && age !== "" && room.chd !== undefined ? "(Age: " + age + " Yrs)" : "1 ",
              "rmid": val.rmid,
              "rnm": val.rnm,
              "hs": val.hs,
              "ra": val.ra,
              "ocp": val.ocp,
              "note":val.note,
              "sup":val.hs,
              "hkey":val.HKey!==undefined?val.HKey:"",
              "index":val.index!==undefined?val.index:"",
              "combination":val.combination!==undefined?val.combination:"",
              "fixedOption":val.fixedOption!==undefined?val.fixedOption:"",
              "rmpr": [
                price
              ]
            }
            if (room.rmno === 1) {
              payload.selected1 = false
              ocpsList1.push(payload);
            } else if (room.rmno === 2) {
              payload.selected2 = false
              ocpsList2.push(payload);
            } else if (room.rmno === 3) {
              payload.selected3 = false
              ocpsList3.push(payload);
            } else if (room.rmno === 4) {
              payload.selected4 = false
              ocpsList4.push(payload);
            } else if (room.rmno === 5) {
              payload.selected5 = false
              ocpsList5.push(payload);
            } else if (room.rmno === 6) {
              payload.selected6 = false
              ocpsList6.push(payload);
            } else if (room.rmno === 7) {
              payload.selected7 = false
              ocpsList7.push(payload);
            } else if (room.rmno === 8) {
              payload.selected8 = false
              ocpsList8.push(payload);
            }
            let brdbase=[];
            if (price.ra !== undefined && price.ra.length !== 0) {
              for (let taxIndex = 0; taxIndex < price.ra.length; taxIndex++) {
                var mak = price.ra[taxIndex];
                if (mak.id !== "2109" && mak.id !== "2195" && mak.id !== "2192" && mak.id !== "2191" ) {
                  brdbase.push(mak.name);
                  boardbsis.push(mak.name);
                }
              }
            }
            if (!isArrayNotEmpty(brdbase)) {
              brdbase.push("Room Only");
              boardbsis.push("Room Only");
            }
            count = count + 1;
          }
        }
        const payload = {
          "rmindex": room.rmno,
          "rst": false,
          "rooms": []
        }
        selRoomList.push(payload);
      }
      dispatch({ type: 'updateResults', payload: false })
    }
    dispatch({ type: 'roomOneOcpList', payload: sortPrice(ocpsList1) })
    dispatch({ type: "boardBasisOne", payload: ocpsList1 });
    dispatch({ type: 'roomTwoOcpList', payload: sortPrice(ocpsList2) })
    dispatch({ type: "boardBasisTwo", payload: ocpsList2 });
    dispatch({ type: 'roomThreeOcpList', payload: sortPrice(ocpsList3) })
    dispatch({ type: "boardBasisThree", payload: ocpsList3 });
    dispatch({ type: 'roomForOcpList', payload: sortPrice(ocpsList4) })
    dispatch({ type: "boardBasisFour", payload: ocpsList4 });
    dispatch({ type: 'roomFiveOcpList', payload: sortPrice(ocpsList5) })
    dispatch({ type: "boardBasisFive", payload: ocpsList5 });
    dispatch({ type: 'roomSixOcpList', payload: sortPrice(ocpsList6) })
    dispatch({ type: 'roomSevenOcpList', payload: sortPrice(ocpsList7) })
    dispatch({ type: 'roomEightOcpList', payload: sortPrice(ocpsList8) })
    dispatch({ type: 'selectedRooms', payload: selRoomList })
    dispatch({ type: 'selRoomsSize', payload: selRoomList.length })
    var uniqueArray = Array.from(new Set(boardbsis));
    let data = Utilities.getUniqData(boardbsis);
    dispatch({ type: 'boardBasisList', payload: data })
    dispatch({ type: 'showRoomChoice', payload: false })
    dispatch({ type: 'updateResults', payload: true })
    //All OCP List data backup
    dispatch({ type: 'roomOneOcpList_data', payload: sortPrice(ocpsList1) })
    dispatch({ type: 'roomTwoOcpList_data', payload: sortPrice(ocpsList2) })
    dispatch({ type: 'roomThreeOcpList_data', payload: sortPrice(ocpsList3) })
    dispatch({ type: 'roomForOcpList_data', payload: sortPrice(ocpsList4) })
    dispatch({ type: 'roomFiveOcpList_data', payload: sortPrice(ocpsList5) })
    dispatch({ type: 'roomSixOcpList_data', payload: sortPrice(ocpsList6) })
    dispatch({ type: 'roomSevenOcpList_data', payload: sortPrice(ocpsList7) })
    dispatch({ type: 'roomEightOcpList_data', payload: sortPrice(ocpsList8) })

  }

  const calculateGST=(price,htldta)=>{
      let total=0;
      if(htldta.type!=="" && htldta.type!==undefined){
      let gst={
            "type": htldta.type,
            "sgst": htldta.sgst!==undefined?htldta.sgst:0,
            "igst": htldta.igst!==undefined?htldta.igst:0,
            "cgst": htldta.cgst!==undefined?htldta.cgst:0,
         }
      gst = Utilities.applyGST(price.servicefee,gst,price.pr[0].tax,null,"Hotel");
      if(gst.gst_invoice.length!==0){
        for(let val of gst.gst_invoice){
          if(gst.type==="same"){
          if(total===0){
            total=Number(val.cgst_val)+Number(val.sgst_val);
          }else{
            total=total+Number(val.cgst_val)+Number(val.sgst_val);
          }
         }else{
          if(total===0){
            total=Number(val.igst_val);
          }else{
            total=total+Number(val.igst_val);
          }
         }
      }
    }
    price.pr[0].gst=gst;
    price.pr[0].gst_total=total;
  }else{
    price.pr[0].gst_total=0;
  }
  }

  const calculateTds=(price,htldta)=>{
    price.pr[0].tds_per=htldta.tds_per;
    price.pr[0].tds_val = Utilities.applyTDS(htldta.tds_per,price.pr[0].com_disc);
  }

  const sortPrice = (list) => {
    let totalList = [];
    totalList = list.sort(function (a, b) {
      return (a.rmpr[0].pr[0].tf - b.rmpr[0].pr[0].tf)
    });
    return totalList;
  }


  const showselectRoom = (e) => {
    executeScrolltop()
  }
  const showMapSec = (e) => {
    executeScroll1()
  }
  const cRoomContent = (e) => {
    setChRoom(true);
    setOverview(false)
    setAmenities(false)
    setPolicies(false)
    executeScroll()
  }
  const overviewContent = (e) => {
    setChRoom(false);
    setOverview(true)
    setAmenities(false)
    setPolicies(false)
    executeScroll1()
  }
  const amenitiesContent = (e) => {
    setChRoom(false);
    setOverview(false)
    setAmenities(true)
    setPolicies(false)
    executeScroll2()
  }
  const policiesContent = (e) => {
    setChRoom(false);
    setOverview(false)
    setAmenities(false)
    setPolicies(true)
    executeScroll3()
  }
  const addMoreRooms = (e) => {
    setShowMoreRooms(true)
  }


  const showMoreFlightsColView = (event) => {
    if (event.target.parentNode.classList.contains("moreHtlOpen")) {
      event.target.parentNode.classList.remove("moreHtlOpen");
      event.target.innerHTML = "+ More options";
    } else {
      event.target.parentNode.classList.add("moreHtlOpen");
      event.target.innerHTML = "- Less options";
    }
  }

  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callHotelDetails = (htldta,isPack) => {

    try {
      setIsLoadingSpinner(true)
      getHotelReviewContent(htldta.expediapid,htldta.otilapid).then((response) => {

        console.log("Content Response...." + JSON.stringify(response));
        if (response.status === "success") {
          dispatch({ type: 'hotelContentResp', payload: response.data })
          callHotelPidSearch(response.data,null,isPack,htldta);
        } else {
          dispatch({ type: 'showPop', payload: true });
          dispatch({ type: 'availContnt', payload: true });
          dispatch({ type: 'availRooms', payload: false });
        }
      }).catch(error => {
        // Handle any errors
        // ...
      })
      .finally(() => {
        setIsLoadingSpinner(false);
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }


  const handleCLClose = () => {
    dispatch({ type: 'showPop', payload: false });
    dispatch({ type: 'availContnt', payload: true });
    return window.location = "/ezytrip/bookings/hotel/results"
  }



  const convertAdts = (roomsInfo) => {
    var adts = "";
    for (let val of roomsInfo) {
      if (adts === "") {
        adts = val.adt;
      } else {
        adts = adts + "," + val.adt;
      }
    }
    return adts;
  }
  //Converting Child Ages
  const convertChildAge = (roomsInfo) => {
    var ages = "";
    for (let val of roomsInfo) {
      if (val.chdArray.length !== 0) {
        if (ages === "") {
          if (val.chdArray.length === 1) {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, '')
          } else {
            ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, '')
          }
        } else {
          if (val.chdArray.length === 1) {
            ages = ages + "|" + val.chdArray.reduce((acc, curr) => `${acc}${curr.age}`, '')
          } else {
            ages = ages + "|" + val.chdArray.reduce((acc, curr) => `${acc}${curr.age},`, '')
          }
        }
      } else {
        if (ages !== "") {
          ages = ages + "|" + "0";
        } else {
          ages = "0";
        }

      }
    }
    return ages;
  }


  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callHotelPidSearch = (obj, srequest, isPack,data) => {
    dispatch({ type: 'respAvail', payload: false })
    try {
      let request = "";
      if (srequest === null) {
        request = JSON.parse(localStorage.getItem(HTL_REQUEST));
        setSearchRequest(localStorage.getItem(HTL_REQUEST));
      } else {
        request = srequest
        localStorage.setItem(HTL_REQUEST,JSON.stringify(srequest))
        setSearchRequest(JSON.stringify(srequest));
      }
      dispatch({ type: 'noofNights', payload: request.nights });
      dispatch({ type: 'cin', payload: request.cin });
      dispatch({ type: 'cout', payload: request.cout });
      let chd = request.roomsInfo !== undefined && request.roomsInfo.filter((ob) => ob.chd !== undefined && ob.chd);
      var ages = convertChildAge(request.roomsInfo);
      var adts = convertAdts(request.roomsInfo);
      var randomStr = Math.random().toString(36).slice(2);
      let isPackAvail=isPack?1:0;
      var requrl = "cin=" + request.cin + "&cout=" + request.cout + "&rms=" + request.roomsInfo.length + "&adt=" + adts + "&chd=" + chd.length + "&rty=" + "prid" + "&echo=" + randomStr + "&cid=" + request.cid + "&cha=" + "B2B" + "&cur=" + request.cur + "&bid=" + request.bid + "&prtyp=" + "hotelOnely" + "&country_code=" + "AE" + "&chdages=" + ages + "&package=" + isPackAvail + "&butype=" + request.butype + "&_id=" + obj._id + "&region_type=" + request.region_type + "&otilacityid=" + data.otilacityid + "&otilapid=" + data.otilapid+"&expediacityid="+data.expediacityid+"&expediapid="+data.expediapid
      console.log("Hotel request :" + JSON.stringify(requrl));
      dispatch({ type: 'chdage', payload: ages });
      setloader(true)
      getHotelRooms(requrl).then((response) => {

        console.log("Pid Search Response.... " + JSON.stringify(response));
        dispatch({ type: 'hid', payload: response.data.hid });
        dispatch({ type: 'priceNote', payload: response.data.note });
        dispatch({ type: 'selectedRooms', payload: [] })
        dispatch({ type: 'respAvail', payload: true })
        let onFly = localStorage.getItem(HTL_MARKUP);
        if(isPack===1){
          sessionStorage.setItem(IS_PACKAGE,true)
        }else if(isPack===0){
          sessionStorage.setItem(IS_PACKAGE,false)
        }
        // sessionStorage.setItem(IS_PACKAGE,isPack==="true"||isPack===1?true:false)
        // console.log("ddddddddddddddddd",isPack==="true"||isPack===1, isPack)
        let onFlyMarkup = onFly / request.roomsInfo.length;
        dispatch({ type: 'onflyMkp', payload: onFlyMarkup });
        dispatch({ type: 'cityId', payload: localStorage.getItem(HTL_CTID) });
        dispatch({ type: 'temp_request', payload: request });
        if(response.data.isExpedia){
          dispatch({ type: 'enableRooms', payload: true });
        }
        dispatch({ type: 'isExpedia', payload: response.data.isExpedia });
        localStorage.removeItem(HTL_CTID);
        if (response.suc) {
          createOccupancyList(response.data, obj.roomData, request);
        } else {
          dispatch({ type: 'showPop', payload: true });
          dispatch({ type: 'availRooms', payload: true });
          dispatch({ type: 'availContnt', payload: false });
        }

      }).catch(error => {
        console.log(error)
        // ...
      })
      .finally(() => {
        setloader(false)
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }

  //Creating the Price request
  const checkExpRoomPrice = useCallback((data) => {
    try {
      if (data !== undefined) {
        setIsloading(true);
        // let reqData=data;
        // let req=data.rmpr;
        // delete data.rmpr;
        // let rmpr=[];
        // rmpr.push(req);
        // data.rmpr=rmpr;
        let request = createPriceRequest(data);
        console.log("PRICE CHECK REQ:" + JSON.stringify(data))
        hotelPriceCheck(request).then((response) => {
          console.log("PRICE CHECK RESP:" + JSON.stringify(response))
          let price = 0;
          if (response.data !== undefined && response.data.status === "success") {
            for (let val of state.selectedRooms) {
              if (val.rmindex === data.rmindex) {
                val.rst = true;
                data.rmpr[0].itiurl = response.data.rooms[0].itiurl
                val.rooms = data;
                val.sup=data.sup;
              }else{
                if(val.sup!==undefined && val.sup!==data.sup){
                  val.rst = false;
                  val.rooms = [];
                }
              }
            }
            dispatch({ type: 'showRoomChoice', payload: true })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Selected Room added Sucessfully...' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            setIsloading(false);
            hidemessage();
          } else {
            dispatch({ type: 'updateResults', payload: false })
            if (data.rmindex === 1) {
              state.roomOneOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomOneOcpList.length);
            } else if (data.rmindex === 2) {
              state.roomTwoOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomTwoOcpList.length);
            } else if (data.rmindex === 3) {
              state.roomThreeOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomThreeOcpList.length);
            } else if (data.rmindex === 4) {
              state.roomForOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomForOcpList.length);
            } else if (data.rmindex === 5) {
              state.roomFiveOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomFiveOcpList.length);
            } else if (data.rmindex === 6) {
              state.roomSixOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomSixOcpList.length);
            } else if (data.rmindex === 7) {
              state.roomSevenOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomSevenOcpList.length);
            } else if (data.rmindex === 8) {
              state.roomEightOcpList.splice(data, 1);
              data.selected1 = false;
              showPopuopOcp(state.roomEightOcpList.length);
            }
            dispatch({ type: 'updateResults', payload: true })
            setIsloading(false);
          }
        });

      }
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  })

  const showPopuopOcp = (size) => {
    if (size === 0) {
      dispatch({ type: 'showPop', payload: true })
      dispatch({ type: 'showOcpPop', payload: true })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room no more available. Please try another room' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

  const checkSupplier=(roomsList,sup)=>{
    let list = roomsList.filter((ob) => ob.hs===sup);
    return list;
  }

  const checkHotelKey=(roomsList,hkey)=>{
    let list = roomsList.filter((ob) => ob.hkey===hkey);
    return list;
  }

  const checkOtillaRoomCombination=(data)=>{
   //Checking Supplier
   if(data.rmindex===1){
    dispatch({ type: 'sel_rm_combination', payload: "" });
    dispatch({ type: 'enableRooms', payload: false });
   let listTwo =checkSupplier(state.roomTwoOcpList,data.hs);
   if(listTwo.length===0){
    listTwo=checkSupplier(state.roomTwoOcpList_data,data.hs);
   }
   let listThree=[];
   if(state.roomThreeOcpList.length!==0){
    listThree =checkSupplier(state.roomThreeOcpList,data.hs);
    if(listThree.length===0){
      listThree=checkSupplier(state.roomThreeOcpList_data,data.hs)
     }
   }
   let listFour=[];
   if(state.roomForOcpList.length!==0){
    listFour =checkSupplier(state.roomForOcpList,data.hs);
    if(listFour.length===0){
      listFour=checkSupplier(state.roomForOcpList_data,data.hs);
     }
   }
   let listFive=[];
   if(state.roomFiveOcpList.length!==0){
    listFive = checkSupplier(state.roomFiveOcpList,data.hs);
    if(listFive.length===0){
      listFive=checkSupplier(state.roomFiveOcpList_data,data.hs);
     }
   }
   let listSix=[];
   if(state.roomSixOcpList.length!==0){
    listSix = checkSupplier(state.roomSixOcpList,data.hs);
    if(listSix.length===0){
      listSix=checkSupplier(state.roomSixOcpList_data,data.hs);
     }
   }
   let listSeven=[];
   if(state.roomSevenOcpList.length!==0){
    listSeven =checkSupplier(state.roomSevenOcpList,data.hs);
    if(listSeven.length===0){
      listSeven=checkSupplier(state.roomSevenOcpList_data,data.hs);
     }
   }
   let listEight=[];
   if(state.roomEightOcpList.length!==0){
    listEight = checkSupplier(state.roomEightOcpList,data.hs);
    if(listEight.length===0){
      listEight=checkSupplier(state.roomEightOcpList_data,data.hs);
     }
   }
   if(listTwo.length!==0 && listTwo[0].hs===0){
      for(let rm of listTwo){
         if(rm.selected2){
          rm.selected2=false;
          break;
         }
      }
      dispatch({ type: 'roomTwoOcpList', payload: listTwo });
      if(listThree.length!==0 && listThree[0].hs===0){
        for(let rm of listThree){
           if(rm.selected3){
            rm.selected3=false;
            break;
           }
           }
      dispatch({ type: 'roomThreeOcpList', payload: listThree });
    }
    if(listFour.length!==0){
      for(let rm of listFour){
        if(rm.selected4){
         rm.selected4=false;
         break;
        }
        }
      dispatch({ type: 'roomForOcpList', payload: listFour });
    }
    if(listFive.length!==0){
      for(let rm of listFive){
        if(rm.selected5){
         rm.selected5=false;
         break;
        }
        }
      dispatch({ type: 'roomFiveOcpList', payload: listFive });
    }
    if(listSix.length!==0){
      for(let rm of listSix){
        if(rm.selected6){
         rm.selected6=false;
         break;
        }
        }
      dispatch({ type: 'roomSixOcpList', payload: listSix });
    }
    if(listSeven.length!==0){
      for(let rm of listSeven){
        if(rm.selected7){
         rm.selected7=false;
         break;
        }
        }
      dispatch({ type: 'roomSevenOcpList', payload: listSeven });
    }
    if(listEight.length!==0){
      for(let rm of listEight){
        if(rm.selected8){
         rm.selected8=false;
         break;
        }
        }
      dispatch({ type: 'roomEightOcpList', payload: listEight });
    }
    checkExpRoomPrice(data);
    dispatch({ type: 'enableRooms', payload: true });
   }else{
    //Checking Supplier
    let listTwoTemp=checkHotelKey(listTwo,data.hkey);
    let listThreTemp=checkHotelKey(listThree,data.hkey);
    let listFourTemp=checkHotelKey(listFour,data.hkey);
    let listFiveTemp=checkHotelKey(listFive,data.hkey);
    let listSixTemp=checkHotelKey(listSix,data.hkey);
    let listSevenTemp=checkHotelKey(listSeven,data.hkey);
    let listEightTemp=checkHotelKey(listEight,data.hkey);
    dispatch({ type: 'enableRooms', payload: true });
    if(listTwoTemp.length!==0){
      dispatch({ type: 'roomTwoOcpList', payload: listTwoTemp });
    }
    if(listThreTemp.length!==0){
      dispatch({ type: 'roomThreeOcpList', payload: listThreTemp });
    }
    if(listFourTemp.length!==0){
      dispatch({ type: 'roomForOcpList', payload: listFourTemp });
    }
    if(listFiveTemp.length!==0){
      dispatch({ type: 'roomFiveOcpList', payload: listFiveTemp });
    }
    if(listSixTemp.length!==0){
      dispatch({ type: 'roomSixOcpList', payload: listSixTemp });
    }
    if(listSevenTemp.length!==0){
      dispatch({ type: 'roomSevenOcpList', payload: listSevenTemp });
    }
    if(listEightTemp.length!==0){
      dispatch({ type: 'roomEightOcpList', payload: listEightTemp });
    }
    if(data.fixedOption){
      dispatch({ type: 'sel_rm_combination', payload: data.index.toString() });
    }
    for (let val of state.selectedRooms) {
      if (val.rmindex === data.rmindex){
        val.rst = true;
        val.rooms = data;
        val.sup=data.sup;
      }else{
        if(val.sup!==undefined && val.sup!==data.sup){
          val.rst = false;
          val.rooms = [];
        }
      }
    }
      dispatch({ type: 'showRoomChoice', payload: true })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room added Sucessfully...' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      setIsloading(false);
      hidemessage();
   }
  }else{
    if(data.fixedOption!==undefined && data.fixedOption){
      var combination="";
      let selAry=[];
      if(state.sel_rm_combination!==undefined && state.sel_rm_combination.includes(",")){
        selAry=state.sel_rm_combination.split(",")
      }
      if(state.temp_request.roomsInfo.length===selAry.length){
        if(selAry[data.rmindex -1]){
           selAry[data.rmindex -1] = data.index.toString();
        }
      //selAry[selAry.length-1] = data.index.toString();
      combination=selAry.sort(function(a, b){return a - b});
      combination=combination.join(",")
      }else{
      combination=state.sel_rm_combination+","+data.index.toString();
      }
    dispatch({ type: 'sel_rm_combination', payload: combination });
    const myArray = combination.split(",");
    if(state.temp_request.roomsInfo.length===myArray.length){
     var finalCombination= myArray.sort(function(a, b){return a - b});
     finalCombination=finalCombination.join(",");
     if(data.combination.includes(finalCombination.toString())){
      console.log("ROOM COMBINATION IS MATCHED1",data.combination)
      console.log("ROOM COMBINATION IS MATCHED2",finalCombination)
      for (let val of state.selectedRooms){
        if (val.rmindex === data.rmindex){
          val.rst = true;
          val.rooms = data;
          val.sup=data.sup;
        }
      }
      dispatch({ type: 'showRoomChoice', payload: true })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room added Sucessfully...' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      setIsloading(false);
      hidemessage();
     }else{
      for (let val of state.selectedRooms){
        if (val.rmindex === data.rmindex){
          val.rst = false;
        }
      }
      dispatch({ type: 'showRoomChoice', payload: true })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room combination is Not avaialable.Choose diffrent Room' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      setIsloading(false);
      hidemessage();
     }
    }else{
      for (let val of state.selectedRooms) {
        if (val.rmindex === data.rmindex){
          val.rst = true;
          val.rooms = data;
          val.sup=data.sup;
        }
      }
      dispatch({ type: 'showRoomChoice', payload: true })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room added Sucessfully...' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      setIsloading(false);
      hidemessage();
    }
  }else{
    if(data.hs===0){
      checkExpRoomPrice(data);
    }else{
      for (let val of state.selectedRooms) {
        if (val.rmindex === data.rmindex){
          val.rst = true;
          val.rooms = data;
          val.sup=data.sup;
        }
      }
      dispatch({ type: 'showRoomChoice', payload: true })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Selected Room added Sucessfully...' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      setIsloading(false);
      hidemessage();
    }
  }
  }
  }

  const checkRoomPrice = useCallback((data) => {
    if(state.isExpedia){
      checkExpRoomPrice(data);
      setRoomOneOcpList(false)
      setRoomtwoOcpList(false)
      setRoomthreeOcpList(false)
      setRoomfourOcpList(false)
      setRoomfiveOcpList(false)
      setRoomsixOcpList(false)
      setRoomsvnOcpList(false)
      setRoomeightOcpList(false)
     }else{
      checkOtillaRoomCombination(data);
      setRoomOneOcpList(false)
      setRoomtwoOcpList(false)
      setRoomthreeOcpList(false)
      setRoomfourOcpList(false)
      setRoomfiveOcpList(false)
      setRoomsixOcpList(false)
      setRoomsvnOcpList(false)
      setRoomeightOcpList(false)
     }
  })


  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  //Creating the Price Request
  const createPriceRequest = (selRoom) => {
    try {
      let request = createRequest();
      request.rooms = [selRoom]
      return request;
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }
  //Creating Final request
  const createRequest = () => {
    let data="";
    var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
    if(userData.cid===0){
      data=JSON.parse(window.sessionStorage.getItem(AUTH_DATA));
      localStorage.setItem(USER_AUTH_DATA, JSON.stringify(data));
      setContext({ logindata: data })
    }
    const payload = {
      cur: context.logindata.cur,
      cid: (context.logindata.cid !== 0) ? context.logindata.cid :data.cid,
      cname: context.logindata.cnm!==""?context.logindata.cnm:data.cnm,
      crby: context.logindata.uid!==null?context.logindata.uid:data.uid,
      bid: context.logindata.bid!==null?context.logindata.bid:data.bid,
      butype: context.logindata.butype!==""?context.logindata.butype:data.butype,
      utype: context.logindata.utype!==""?context.logindata.utype:data.utype,
      ofcId: "DOHQR2718",
      bktype: "DIRECT",
      cuscode: context.logindata.cuscode!==""?context.logindata.cuscode:data.cuscode,
      ctid: state.cityId,
      bkby: context.logindata.nm!==""?context.logindata.nm:data.nm,
      onflymarkup: (localStorage.getItem(HTL_MARKUP) !== null) ? parseFloat(localStorage.getItem(HTL_MARKUP)) : 0,
      region_type : context.logindata.region_type!==undefined?context.logindata.region_type : searchRequest.region_type,
      req_cur : context.logindata.cur!==undefined?context.logindata.cur:searchRequest.cur,
      htldtls: {
        cin: state.cin + " " + (state.hotelContentResp.cin!==undefined && state.hotelContentResp.cin.beginTime!==undefined?state.hotelContentResp.cin.beginTime:""),
        cout: state.cout + " " + (state.hotelContentResp.cout!==undefined && state.hotelContentResp.cout.time!==undefined?state.hotelContentResp.cout.time:""),
        htnm: state.hotelContentResp.htnm,
        htimg: state.hotelContentResp.hImages[0],
        pid: state.hotelContentResp.pid,
        add: state.hotelContentResp.add,
        area: state.hotelContentResp.area,
        city: state.hotelContentResp.city,
        country: state.hotelContentResp.country,
        landmark: state.hotelContentResp.landmark,
        rating: state.hotelContentResp.ratings,
        category: state.hotelContentResp.category,
        remarks: state.hotelContentResp.checkin,
        phone_no: state.hotelContentResp.phone_no,
        email: state.hotelContentResp.email,
        location: {
          latitude: state.hotelContentResp.location.lat,
          longitude: state.hotelContentResp.location.long
        }
      }
    }
    window.sessionStorage.removeItem(AUTH_DATA);
    return payload;
  }

  const finalSelRooms = () => {
    try {
      let roomsList = [];
      let request = createRequest();
      for (let val of state.selectedRooms) {
        roomsList.push(val.rooms);
      }
      let req=JSON.parse(searchRequest);
      request.rooms = roomsList;
      request.hid = state.hid;
      request.onFlyMarkup = parseFloat(localStorage.getItem(HTL_MARKUP));
      request.chdage = state.chdage;
      request.priceNote = state.priceNote;
      request.servicefee = state.serviceFee;
      request.nonights = state.noofNights;
      request.hs=roomsList[0].hs;
      request.hKey=roomsList[0].hkey;
      request.cityId=req.htlData[0].otilacityid;
      console.log("Final Selected Rooms List :" + JSON.stringify(request));
      localStorage.removeItem(HTL_MARKUP);
      window.sessionStorage.removeItem(IS_PACKAGE);

      history.push({
        pathname: '/ezytrip/bookings/hotel/guestDetails',
        state: {
          req: request
        }
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }


  const showRoomInfo = (roomObj) => {
    let rmImg = "";
    let roomimge = [];
    if (roomObj !== undefined) {
      for (var key in state.hotelContentResp.roomData) {
        rmImg = state.hotelContentResp.roomData[roomObj.rmid];
        if (rmImg !== undefined && rmImg.images !== undefined && rmImg.images.length !== 0) {
          for (let val of rmImg.images) {
            let imge = val.links["1000Px"];
            if (imge !== undefined && imge.href !== undefined) {
              roomimge.push(imge.href);
            }
          }
          dispatch({ type: 'roomInfo', payload: rmImg })
        }

      }
      dispatch({ type: 'roomObj', payload: roomObj })
      dispatch({ type: 'roomImages', payload: roomimge })
      setShow(true)
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (state.precepernight) {
      dispatch({ type: 'precepernight', payload: false })
    } else {
      dispatch({ type: 'precepernight', payload: true })
    }
    dispatch({ type: id, payload: value });
  }
  //Applay Board basis
  const applyBoardBasis_old = (bbname) => () => {
    console.log('state.roomOneOcpList------------' + JSON.stringify(state.roomThreeOcpList));
    let ocpList1 = state.boardBasisOne;
    if (ocpList1 !== undefined) {
      let matched = false;
      for (let val of ocpList1) {
        for (let rmprice of val.rmpr) {
          let list = rmprice.ra.filter((ob) => ob.name.includes(bbname.name));
          if (list !== undefined && list.length !== 0) {
            if (!bbname.sel) {
              rmprice.ra = list;
              matched = true;
              bbname.sel = true;
              dispatch({ type: 'roomOneOcpList', payload: [val] })
              break;
            } else {
              bbname.sel = false;
              dispatch({ type: 'roomOneOcpList', payload: ocpList1 })
              break;
            }
          }
          // }else{
          //   alert("2");
          //   bbname.sel=false;
          //   dispatch({ type: 'roomOneOcpList', payload: state.boardBasisOne })
          // }
        }
      }
    }
    let ocpList2 = state.boardBasisTwo;
    if (ocpList2 !== undefined) {
      for (let val of ocpList2) {
        for (let rmprice of val.rmpr) {
          let list = rmprice.ra.filter((ob) => ob.name.includes(bbname.name));
          if (list !== undefined && list.length !== 0)
            dispatch({ type: 'roomTwoOcpList', payload: [val] })
          break;
        }
      }
    }
    let ocpList3 = state.boardBasisThree;
    if (ocpList3 !== undefined) {
      for (let val of ocpList3) {
        for (let rmprice of val.rmpr) {
          let list = rmprice.ra.filter((ob) => ob.name.includes(bbname.name));
          if (list !== undefined && list.length !== 0) {
            dispatch({ type: 'roomThreeOcpList', payload: [val] })
            break;
          }
        }
      }
    }
    dispatch({ type: 'bbupdated', payload: true })
  }


  //Applay Board basis
  const applyBoardBasis = (bbname) => () => {
    if (bbname == "Refundable" || bbname == "Non-Refundable"){
      dispatch({type:"selectedBoardbasis",payload:''})
    }
    else{
      setRefund(false)
      setNonRefund(false)
    }
    dispatch({ type: "showClear", payload: true })
    if (bbname == "Refundable") {
      setRefund(true)
      setNonRefund(false)
    }
    if (bbname == "Non-Refundable") {
      setRefund(false)
      setNonRefund(true)
    }
    //Room 1
    let room1data = []
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      dispatch({type:"selectedBoardbasis",payload:bbname})

      room1data = state.roomOneOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {

            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room1data = state.roomOneOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room1data = state.roomOneOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }

    //Other than Room only
    else {
      dispatch({type:"selectedBoardbasis",payload:bbname})
      room1data = state.roomOneOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })

    }
    dispatch({ type: 'roomOneOcpList', payload: room1data });


    //Room 2
    let room2data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room2data = state.roomTwoOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room2data = state.roomTwoOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room2data = state.roomTwoOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //Other than Room only
    else {
      room2data = state.roomTwoOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })

    }
    dispatch({ type: 'roomTwoOcpList', payload: room2data });


    //Room 3
    let room3data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room3data = state.roomThreeOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room3data = state.roomThreeOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room3data = state.roomThreeOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //Other than Room only
    else {
      room3data = state.roomThreeOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })
    }
    dispatch({ type: 'roomThreeOcpList', payload: room3data });

    //Room 4
    let room4data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room4data = state.roomForOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room4data = state.roomForOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room4data = state.roomForOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //If other than Room only
    else {
      room4data = state.roomForOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })
    }
    dispatch({ type: 'roomForOcpList', payload: room4data });


    //Room 5
    let room5data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room5data = state.roomFiveOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })

    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room5data = state.roomFiveOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room5data = state.roomFiveOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //If other than Room only
    else {
      room5data = state.roomFiveOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })
    }
    dispatch({ type: 'roomFiveOcpList', payload: room5data });

    //Room 6
    let room6data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room6data = state.roomSixOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room6data = state.roomSixOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room6data = state.roomSixOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //If Other than Room only
    else {
      room6data = state.roomSixOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })

    }
    dispatch({ type: 'roomSixOcpList', payload: room6data });

    //Room 7
    let room7data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room7data = state.roomSevenOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })
    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room7data = state.roomSevenOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room7data = state.roomSevenOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //If Other than Room only
    else {
      room7data = state.roomSevenOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })

    }
    dispatch({ type: 'roomSevenOcpList', payload: room7data });

    //Room 8
    let room8data = [];
    //If Room only
    if (bbname.toUpperCase() == 'ROOM ONLY') {
      room8data = state.roomEightOcpList_data.filter(room => {
        if (!isArrayNotEmpty(room.rmpr[0].ra)) {
          return room;
        } else {
          let raids = _.uniq(_.map(room.rmpr[0].ra, 'id'));
          const ram_data = raids.filter(element => non_room_only.includes(element));
          if (!isArrayNotEmpty(ram_data)) {
            return room;
          }
        }
      })

    }
    // refundable
    else if (bbname.toUpperCase() == 'REFUNDABLE') {
      room8data = state.roomEightOcpList_data.filter(room => {

        if (room.rmpr[0].refundable === true) {
          return room;
        }
      })
    }
    //non-refundable
    else if (bbname.toUpperCase() == 'NON-REFUNDABLE') {
      room8data = state.roomEightOcpList_data.filter(room => {
        if (room.rmpr[0].refundable === false) {
          return room;
        }
      })
    }
    //If other than room only
    else {
      room8data = state.roomEightOcpList_data.filter(room => {
        let data = room.rmpr[0].ra.filter(obj => obj.name.toUpperCase().includes(bbname.toUpperCase()));
        if (isArrayNotEmpty(data)) {
          return room;
        }
      })
    }
    dispatch({ type: 'roomEightOcpList', payload: room8data });
  }

  //Clear Board basis filter
  const clearBoardBasis = () => {
    setRefund(false)
    setNonRefund(false)
    dispatch({type:"selectedBoardbasis",payload:""})
    dispatch({ type: "showClear", payload: false })

    dispatch({ type: "showClear", payload: false })
    //Room 1
    dispatch({ type: 'roomOneOcpList', payload: state.roomOneOcpList_data });

    //Room 2
    dispatch({ type: 'roomTwoOcpList', payload: state.roomTwoOcpList_data });

    //Room 3
    dispatch({ type: 'roomThreeOcpList', payload: state.roomThreeOcpList_data });

    //Room 4
    dispatch({ type: 'roomForOcpList', payload: state.roomForOcpList_data });

    //Room 5
    dispatch({ type: 'roomFiveOcpList', payload: state.roomFiveOcpList_data });

    //Room 6
    dispatch({ type: 'roomSixOcpList', payload: state.roomSixOcpList_data });

    //Room 7
    dispatch({ type: 'roomSevenOcpList', payload: state.roomSevenOcpList_data });

    //Room 8
    dispatch({ type: 'roomEightOcpList', payload: state.roomEightOcpList_data });
  }


  const updateBoardbasis = (ocpList, bbname) => {
    for (let val of ocpList) {
      for (let rmprice of val.rmpr) {
        rmprice.ra = rmprice.ra.filter((ob) => ob.name.includes(bbname));
      }
    }
  }

  const enableBook = () => {
    let enable = true;
    for (let val of state.selectedRooms) {
      if (!val.rst) {
        enable = false;
      }
    }
    return enable;
  }
  //Cal price
  const calculatePrice = () => {
    let price = 0
    let onfly = 0;
    let roomList = state.selectedRooms.filter((ob) => ob.rst);
    if (roomList !== undefined && roomList.length !== 0) {
      onfly = state.onflyMkp * roomList.length;
      for (let val of roomList) {
        if (price === 0) {
          let service = Utilities.calHotelServiceFee(val.rooms.rmpr[0].servicefee, context.logindata.butype, "All");
          price = Utilities.addPrice(val.rooms.rmpr[0].pr[0].tf - val.rooms.rmpr[0].pr[0].com_disc, service, "Add");
          price = Number(price) + Number(val.rooms.rmpr[0].pr[0].gst_total) + Number(val.rooms.rmpr[0].pr[0].tds_val)
        } else {
          let service = Utilities.calHotelServiceFee(val.rooms.rmpr[0].servicefee, context.logindata.butype, "All");
          let tprice = Utilities.addPrice(val.rooms.rmpr[0].pr[0].tf - val.rooms.rmpr[0].pr[0].com_disc, service, "Add");
          price = Utilities.addPrice(Number(price) + Number(val.rooms.rmpr[0].pr[0].gst_total) + Number(val.rooms.rmpr[0].pr[0].tds_val), tprice, "Add");
        }
      }
    }
    return Utilities.addPrice(price, onfly, "Add");
  }

  const handleClosePop = () => {
    dispatch({ type: 'showPop', payload: false });
  }

  const handleClosePopup = () => {
    dispatch({ type: 'reviewShow', payload: false })
  }

  return (

    <>
      <Header />
      {searchRequest !== undefined &&
        <div className="msearch">
          <ChangeHotelSearch request={JSON.parse(searchRequest)} length={"10"} searchEnable={false} flow={"Review"} respAvail={state.respAvail} />
        </div>
      }
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
     {isLoadingSpinner ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}


      <section className="hotelContainer topSec">
        {state.hotelContentResp !== undefined &&
          <div className="container resultLayout mb-4">
            <div className="reviewTopSec">
              <div className="reviewInfo">
                <div className="hotelTitel"><h3>{state.hotelContentResp.htnm}</h3>
                  <div className="extDetails">

                    <span className="stars">
                      {Utilities.getHotelStartRating(state.hotelContentResp.rat).map((star, idx) => (
                        <React.Fragment key={idx}>
                          <span className="ezyIcon icon-star"></span>
                        </React.Fragment>
                      ))}
                    </span>

                    <span className="pType">{state.hotelContentResp.cat}</span>
                  </div>
                </div>
                <p>{state.hotelContentResp.add}, {state.hotelContentResp.city}, {state.hotelContentResp.country}  <Link onClick={showMapSec}>View on Map</Link></p>

                {/* <div className="rating">{Utilities.getUserStatus(state.hotelContentResp.urat)}</div> */}
              </div>
              <div className="selectHtlPrice">
                <p onClick={openReviewPopup()} className="xpRating">
                  {state.hotelContentResp.urat !== "" &&
                    <>
                      <span className="rating5" > <em>{state.hotelContentResp.urat + "/5"}</em> {state.hotelContentResp.urat !== undefined && Utilities.getUserStatus(state.hotelContentResp.urat)}</span>
                    </>
                  }
                  {state.hotelContentResp.urat !== "" &&
                    <>
                      <span className="icons xpIcon"></span>
                      <span>( {state.hotelContentResp.expediareview} Reviews )</span>
                    </>
                  }
                </p>

                <Button
                  size="sm"
                  className="choose"
                  onClick={showselectRoom}
                  variant="outline-secondary">
                  CHOOSE ROOM
          </Button>
              </div>
            </div>

            <div className="hoteGgallery">
              {state.hotelContentResp.hImages !== undefined &&
                <ImageSlider img={state.hotelContentResp.hImages} />
              }
            </div>
            <div className="secNavPart" >
              <ul className="secNav">
                <li><a href={void (0)} className={chRoom ? 'active' : ''} onClick={cRoomContent}>Choose Rooms</a></li>
                <li><a href={void (0)} className={overview ? 'active' : ''} onClick={overviewContent}>Overview</a></li>
                <li><a href={void (0)} className={amenities ? 'active' : ''} onClick={amenitiesContent}>Services & Amenities</a></li>
                <li><a href={void (0)} className={policies ? 'active' : ''} onClick={policiesContent}>Policies</a></li>
              </ul>
              {state.hotelContentResp !== undefined && state.hotelContentResp.cin !== undefined && state.hotelContentResp.cout !== undefined &&
                <div className="chkinInfo"><strong>Check-In : <span>{state.hotelContentResp.cin.beginTime}</span></strong><strong>Check-Out : <span>{state.hotelContentResp.cout.time}</span></strong></div>
              }
            </div>

          </div>
        }
      </section>
      <div className="container hotelContainer mb-4" ref={findRoom}>
      <div className='d-flex chooseRoomview'>
        <h4 className="title mt-4 mb-1">Room Choices {isLoading && <div class="spinner-border text-success spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>}</h4>

        {
          JSON.parse(localStorage.getItem(HTL_REQUEST)) &&JSON.parse(localStorage.getItem(HTL_REQUEST)).package!==undefined && JSON.parse(localStorage.getItem(HTL_REQUEST)).package===1
        &&         <span className='LinkImage'><Link onClick={chooseRoom}><span><img src={Linkimage} /></span><span className='textImage'>View Package Rates</span></Link></span>
          }
         {
         JSON.parse(localStorage.getItem(HTL_REQUEST)) && JSON.parse(localStorage.getItem(HTL_REQUEST)).package!==undefined && JSON.parse(localStorage.getItem(HTL_REQUEST)).package===0  && 
         htlData?.supplier!=1 && 
           <span className='LinkImage'><Link onClick={chooseRoom}><span><img src={Linkimage} /></span><span className='textImage'>View Standalone Rates</span></Link></span>
         }
         </div>
        <Sticky>{state.showRoomChoice &&
          <div className="selectedHotelinfo">

            <>
              <Row>
                <Col lg={10} md={12}>
                  <Row>
                  <Col xs={4} className="addedSec"><h6 className="ezyIcon icon-confirmedOne"> Your Selection</h6></Col>
                        <Col xs={2} className="addedSec"><h6 className="ezyIcon icon-bedroom"> Bed Type</h6></Col>
                        <Col xs={2} className="addedSec"> <h6 className="ezyIcon icon-occupancy"> Occupancy</h6></Col>
                        <Col xs={2} className="addedSec"> <h6 className="ezyIcon icon-Fullboard"> Board Basis</h6></Col>
                        <Col xs={2} className="addedSec"> <h6>Price Per Room</h6></Col>

                  </Row>
                  <div className="chooseInfoLayout">
                    {state.selectedRooms.map((room, indx) => (
                      <Row className="chooseInfo">
                        <Col xs={4} className="dtls roomTypeinfo">
                          <span>Room {room.rmindex} : {room.rst ? <span className="roomcont" title={room.rooms !== undefined && room.rooms.rnm}> {room.rooms !== undefined && room.rooms.rnm}</span> : <span className="notSelect"><span> Not Selected</span></span>}</span>
                        </Col>
                        <Col className="dtls roomTypeinfo" xs={2}>
                          <span title={room.rooms !== undefined && room.rooms.rmpr !== undefined && room.rooms.rmpr[0].bed}>{room.rst ? <span>{room.rooms !== undefined && room.rooms.rmpr !== undefined && room.rooms.rmpr[0].bed}</span> : <span className="notSelect"><span>---</span></span>}</span>
                        </Col>
                        <Col xs={2} className="dtls roomTypeinfo">
                          {console.log("age", room.rooms)}
                          <span>{room.rst ? <span title={room.rooms !== undefined && room.rooms.chd > 0 ? room.rooms.adt + " Adult(s) " + room.rooms.chd + " Child(s)" + room.rooms.chdage : room.rooms.adt + " Adult(s) "}>{room.rooms !== undefined && room.rooms.chd > 0 ? room.rooms.adt + " Adult(s) " + room.rooms.chd + " Child(s)" + room.rooms.chdage : room.rooms.adt + " Adult(s) "}</span> : <span className="notSelect"><span>---</span></span>}</span>
                        </Col>
                        {console.log(room.rooms)}
                        <Col xs={2} className="dtls roomsType">
                          <span>{room.rst ? <span>{room.rooms !== undefined && room.rooms.rmpr[0] !== undefined && room.rooms.rmpr[0].ra[0] !== undefined ? Utilities.getBoardBasisHotel(room.rooms.rmpr[0].ra) : "Room Only"}</span> : <span className="notSelect"><span>---</span></span>}</span>
                        </Col>
                        <Col className="dtls">
                          <span>{room.rst ? <span>{context.logindata.cur} <FormateCurrency value={room.rooms !== undefined && room.rooms.rmpr !== undefined && Number(room.rooms.rmpr[0].pr[0].tf - room.rooms.rmpr[0].pr[0].com_disc) + Number(state.onflyMkp) + Number(room.rooms.rmpr[0].pr[0].gst_total) + Number(room.rooms.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(room.rooms.rmpr[0].servicefee, context.logindata.butype, "All"))} /> </span> : <span className="notSelect"><span>---</span></span>}</span>
                        </Col>

                      </Row>
                    )).slice(0, roomeListshow ? state.selectedRooms.length : '3')}
                    {state.selectedRooms.length >= 4 ?
                      <Button className="showmorelink" variant="link" onClick={() => setRoomeListshow(!roomeListshow)}>
                        {roomeListshow ? "- Show less" : "+ Show All"}
                      </Button> : ''}
                  </div>
                </Col>

                <Col lg={2} md={12} className="priceperRoom">
                  {state.selRoomsSize &&
                    <div className="dtls pricePart">
                      <h6>Total Price</h6>
                      {/* <div className="deals">
              <span>Hot Deal</span>
              <p className="blockPrice"><strong className="offers"><em>QAR</em>695.00</strong></p>
             </div> */}
                      {/* <div className="pkgRate">Package Rates</div> */}
                      <div className="blockPrice"><strong><em>{context.logindata.cur}</em> {<FormateCurrency value={calculatePrice()} />}</strong></div>
                      {state.noofNights !== undefined &&
                        <span className="blockInfo">Price for all for {state.noofNights} nights</span>
                      }
                      {enableBook() === true &&
                        <Button
                          size="xs"
                          className="bookNow"
                          onClick={finalSelRooms}
                          variant="primary">
                          BOOK NOW
              </Button>
                      }
                    </div>
                  }

                </Col>
              </Row>



            </>

          </div>}
        </Sticky>



        <div className="roomFilter">

          <div className="roomsWith">
            {state.boardBasisList.length !== 0 && <span>Show only rooms with :</span>}
            {state.boardBasisList.length !== 0 && state.boardBasisList.map((bb, indx) => (
              <Button
                size="sm"
                onClick={applyBoardBasis(bb)}
                className={"d-flex align-items-center justify-content-center " + ((bb.sel || state.selectedBoardbasis == bb) ? "active" : "")}
                variant="outline-primary">
                {bb}
              </Button>
            )).slice(0, showFcList ? props.amenities.length : '5')}
            {state.isRefundable == true && <Button
              size="sm"
              onClick={applyBoardBasis("Refundable")}
              className={"d-flex align-items-center justify-content-center "
                + (refund ? "active" : "")
              }
              variant="outline-primary">
              {"Refundable"}
            </Button>
            }
            {state.isNonRefundable == true && <Button
              size="sm"
              onClick={applyBoardBasis("Non-Refundable")}
              className={"d-flex align-items-center justify-content-center "
                + (nonRefund ? "active" : "")
              }
              variant="outline-primary">
              {"Non-Refundable"}
            </Button>
            }
            {isArrayNotEmpty(state.boardBasisList) && state.showClear && (
              <Button
                size="sm"
                className="fliterClear ezyIcon icon-close"
                onClick={clearBoardBasis}
                variant="outline-primary"> Clear
              </Button>
            )}
          </div>

          <div className="staytype">
            <Form.Group >
              <Form.Check type="radio" value={state.precepernight} onChange={handleChange} label="Total for Stay" id="att" custom name="classes" defaultChecked />
              <Form.Check type="radio" value={state.precepernight} onChange={handleChange} label="Avg price per night" id="ta" custom name="classes" className="ml-3" />
            </Form.Group>
          </div>

        </div>
        {loader ? ( <div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div> ) : ( <>
          {state.updateResults &&


<div ref={chRoomSec}>

  {state.roomOneOcpList.length !== 0 &&
    <>
      <div className="cardMainlayout selectedRooms" >
        {state.roomOneOcpList.map((ocp, index) => (
          <React.Fragment key={index}>
            <>
              {index === 0 && <RoomHeader rmno={1} ocp={ocp} />}
              <RoomOccupancy rmocp1={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList1={state.roomOneOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
            </>
          </React.Fragment>
        )).slice(0, roomOneOcpListshow ? state.roomOneOcpList.length : '2')}

        {state.roomOneOcpList.length >= 3 ?
          <Button className="showmorelink" variant="link" onClick={() => setRoomOneOcpList(!roomOneOcpListshow)}>
            {roomOneOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
          </Button> : ''}
          <span className='btnUheight'>&nbsp;</span>
      </div>

    </>

  }
  {!state.enableRooms && !state.isExpedia && state.roomTwoOcpList.length!==0 && <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={2} ocp={state.roomTwoOcpList[0]} /></div>}
  {state.enableRooms && state.roomTwoOcpList.length !== 0 &&
    <>   <div className="cardMainlayout selectedRooms" >
      {state.roomTwoOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={2} ocp={ocp} />}
            <RoomOccupancy rmocp2={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList2={state.roomTwoOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>
      )).slice(0, roomOtwocpListshow ? state.roomTwoOcpList.length : '2')}

      {state.roomTwoOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomtwoOcpList(!roomOtwocpListshow)}>
          {roomOtwocpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''}  <span className='btnUheight'>&nbsp;</span></div>

    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomThreeOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={3} ocp={state.roomThreeOcpList[0]} /></div>}
  {state.enableRooms && state.roomThreeOcpList.length !== 0 &&
    <><div className="cardMainlayout selectedRooms" >
      {state.roomThreeOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={3} ocp={ocp} />}
            <RoomOccupancy rmocp3={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList3={state.roomThreeOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>
      )).slice(0, roomthreeOcpListshow ? state.roomThreeOcpList.length : '2')}

      {state.roomThreeOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomthreeOcpList(!roomthreeOcpListshow)}>
          {roomthreeOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''}  <span className='btnUheight'>&nbsp;</span></div>
    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomForOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={4} ocp={state.roomForOcpList[0]} /></div>}
  {state.enableRooms && state.roomForOcpList.length !== 0 &&
    <><div className="cardMainlayout selectedRooms" >
      {state.roomForOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={4} ocp={ocp} />}
            <RoomOccupancy rmocp4={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList4={state.roomForOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />

          </>
        </React.Fragment>
      )).slice(0, roomfourOcpListshow ? state.roomForOcpList.length : '2')}

      {state.roomForOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomfourOcpList(!roomfourOcpListshow)}>
          {roomfourOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''}  <span className='btnUheight'>&nbsp;</span></div>
    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomFiveOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={5} ocp={state.roomFiveOcpList[0]} /></div>}
  {state.enableRooms && state.roomFiveOcpList.length !== 0 &&
    <><div className="cardMainlayout selectedRooms" >
      {state.roomFiveOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={5} ocp={ocp} />}
            <RoomOccupancy rmocp5={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList5={state.roomFiveOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>
      )).slice(0, roomfiveOcpListshow ? state.roomFiveOcpList.length : '2')}

      {state.roomFiveOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomfiveOcpList(!roomfiveOcpListshow)}>
          {roomfiveOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''}  <span className='btnUheight'>&nbsp;</span></div>
    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomSixOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={5} ocp={state.roomSixOcpList[0]} /></div>}
  {state.enableRooms && state.roomSixOcpList.length !== 0 &&
    <><div className="cardMainlayout selectedRooms" >
      {state.roomSixOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={6} ocp={ocp} />}
            <RoomOccupancy rmocp6={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList6={state.roomSixOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>

      )).slice(0, roomsixOcpListshow ? state.roomSixOcpList.length : '2')}

      {state.roomSixOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomsixOcpList(!roomsixOcpListshow)}>
          {roomsixOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''} <span className='btnUheight'>&nbsp;</span></div>
    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomSevenOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={7} ocp={state.roomSevenOcpList[0]} /></div>}
  {state.enableRooms && state.roomSevenOcpList.length !== 0 &&
    <> <div className="cardMainlayout selectedRooms" >
      {state.roomSevenOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={7} ocp={ocp} />}
            <RoomOccupancy rmocp7={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList7={state.roomSevenOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>
      )).slice(0, roomsvnOcpListshow ? state.roomSevenOcpList.length : '2')}

      {state.roomSevenOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomsvnOcpList(!roomsvnOcpListshow)}>
          {roomsvnOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''}  <span className='btnUheight'>&nbsp;</span></div>
    </>
  }

{!state.enableRooms && !state.isExpedia && state.roomEightOcpList.length!==0 &&  <div className="cardMainlayout selectedRooms" ><RoomHeader rmno={8} ocp={state.roomEightOcpList[0]} /></div>}
  {state.enableRooms && state.roomEightOcpList.length !== 0 &&
    <> <div className="cardMainlayout selectedRooms" >
      {state.roomEightOcpList.map((ocp, index) => (
        <React.Fragment key={index}>
          <>
            {index === 0 && <RoomHeader rmno={8} ocp={ocp} />}
            <RoomOccupancy rmocp8={ocp} rmpr={ocp.rmpr[0].pr[0]} cur={context.logindata.cur} idx={index + 1} sendSelRoom={checkRoomPrice} sendRoomInfo={showRoomInfo} ocpnsList8={state.roomEightOcpList} nights={state.noofNights} pricePerNight={state.precepernight} onflyMkp={state.onflyMkp} butype={context.logindata.butype} />
          </>
        </React.Fragment>
      )).slice(0, roomeightOcpListshow ? state.roomEightOcpList.length : '2')}

      {state.roomEightOcpList.length >= 2 ?
        <Button className="showmorelink" variant="link" onClick={() => setRoomeightOcpList(!roomeightOcpListshow)}>
          {roomeightOcpListshow ? <span className="uparw"> Show Less <span className="ezyIcon icon-arrowDown"></span></span> : <span>Show More <span className="ezyIcon icon-arrowDown"></span> </span>}
        </Button> : ''} <span className='btnUheight'>&nbsp;</span></div>
    </>
  }
</div>
}
        </> )}



        <div className="cardMainlayout map" ref={overviewSec} >
          {state.hotelContentResp !== undefined &&
            <MapView attractions={state.hotelContentResp.attractions} location={state.hotelContentResp.location} hname={state.hotelContentResp.htnm} description={state.hotelContentResp.descriptions} />
          }

        </div>
        {/* <div className="cardMainlayout map" ref={overviewSec}>
          <h4 className="title border-bottom pb-2">Overview</h4>
          {state.hotelContentResp !== undefined &&
            <Overview desc={state.hotelContentResp.descriptions} />
          }
        </div> */}

        <div className="cardMainlayout amenities" ref={amenitiesSec}>
          <h4 className="title">Services & Amenities</h4>
          {state.hotelContentResp !== undefined && state.hotelContentResp.amenities !== undefined &&
            <Amenities amenities={state.hotelContentResp.amenities} />
          }
        </div>
        <div className="cardMainlayout policies" ref={policiesSec}>
          <h4 className="title">Hotel Policies</h4>
          {state.hotelContentResp.cin !== undefined && state.hotelContentResp.cout && state.hotelContentResp.policies !== undefined &&
            <Policies cin={state.hotelContentResp.cin} cout={state.hotelContentResp.cout} payments={state.hotelContentResp.onsitePayments !== null && state.hotelContentResp.onsitePayments} policy={state.hotelContentResp.policies} canpolicy={state.hotelContentResp.checkin} />
          }
        </div>
      </div>


      <Modal
        show={state.showPop}
        onHide={handleCLClose}
        className="importPNRMdl requiredFare  "
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0">
          <div className="popUplayout center">
            <div className="icons warning"></div>
            {state.availContnt &&
              <div>
                <strong>Sorry we are not able to Load Hotel Details</strong>
                <p>Please go to result page and select the hotel again</p>
              </div>
            }
            {state.availRooms &&
              <div className="text-center">
                <strong>No Rooms found for the selected Hotel. Please check another Hotel</strong>
              </div>
            }

            {state.showOcpPop &&
              <div>
                <strong>Rooms are not avaialable for the selected Occupancy </strong>
              </div>
            }

          </div>
        </Modal.Header>
      </Modal>


      <Modal
        show={state.showRoom}
        onHide={handleClose}
        className="importPNRMdl "
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            {state.showRoom &&
              <div className="text-center">
                <strong>Selected Room no more avaialablre.</strong>
                <p>Please check another room</p>
              </div>
            }
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button
            size="xs"
            variant="primary"
            className="mr-3"
            onClick={handleClose}
          >Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} size="lg" dialogClassName="modal-90w" className="hotelSlider" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Room Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.roomInfo !== undefined &&
            <Row>
              <Col xs={7} className="slider">
                <Slide>

                  {state.roomImages !== undefined && state.roomImages.map((image, index) => (
                    <div className="each-fade">
                      <img src={image} />
                    </div>
                  ))}

                </Slide>
              </Col>
              <Col xs={5}>
                <div className="hotelselectedRoomInfo mb-2">
                  <strong>{state.roomInfo.name}</strong>
                  <p><span>Room Occupancy - </span> {state.roomObj.adt} Adult(s), {state.roomObj.chd} Child(s)</p>
                  <p className="ezyIcon icon-bed"> {state.roomObj.rmpr !== undefined && state.roomObj.rmpr[0] !== undefined && state.roomObj.rmpr[0].bed}</p>
                  <p className="ezyIcon icon-nounroom"> <span>Room Size </span> {state.roomInfo.area !== undefined && state.roomInfo.area.squareFeet !== undefined && state.roomInfo.area.squareFeet} sq feet</p>
                  <div className="emnt"></div>

                </div>
                <Row className="emntList">
                  {state.roomInfo.amenities !== undefined &&
                    <Scrollbars style={{ height: 390 }}>
                      <Col xs={12}>
                        <strong className="listTitl">Most popular facilities</strong>
                        <ul>
                          {Object.keys(state.roomInfo.amenities).map(key => (
                            <li value={key.name}><span className="ezyIcon icon-Confirmed"  title={state.roomInfo.amenities[key].name}>{state.roomInfo.amenities[key].name}</span></li>
                          ))}
                        </ul>
                      </Col>
                    </Scrollbars>
                  }

                </Row>
              </Col>
            </Row>
          }
        </Modal.Body>

      </Modal>

      <Modal
        show={state.reviewShow}
        size="lg"
        onHide={handleClosePopup}
        className="importPNRMdl xpreview"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <h5>Guest Reviews<Button variant="outline-dark" className="Confirmed ezyIcon icon-close" onClick={handleClosePopup}>
          </Button></h5>
          <div className="guestReviews">
            {state.reviewObj !== undefined &&
              <GuestReviews reviewObj={state.reviewObj} reviewCount={state.expRating} urat={state.usrRating} pid={state.pid} page={"Review"} />
            }
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  )
}


export default HotelReview;
