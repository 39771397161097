import SupplierConfiguration from '../../../../services/administration/SupplierConfiguration';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */

export const getSuppliersData = async (obj) => {
    let holdBookigsList = [];
    await SupplierConfiguration.retrieveSupplier(obj).then(response => {
        if (response.data.suc) {
            holdBookigsList = response.data.res;
        }
    });
    return Promise.resolve(holdBookigsList);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    await SupplierConfiguration.saveSupplier(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes = {};
    await SupplierConfiguration.updateSupConfig(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

