import React, { useState, useReducer } from 'react';
import RoomPrice from '../../pages/RoomPrice.js';
import Utilities from '../../../../commonUtils/Utilities';
/**
 * @description:Showing the Booking
 * @author: Pravas
 * @param {*}
 * @function Gust details
 * @date : 24-01-2021
 */

/**
* Initial State Declaration
*/

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================


function BookingSummary(props) {
    const [state, dispatch] = useReducer();
    const [request, setRequest] = useState(props.request);

    return (
        <>
        {request!==undefined && request.htldtls!==undefined &&
            <div className="costBox bookingSummary">
                <div className="hotelSummary">
                    {request.htldtls.htimg?<div className="img"> <img
                        src={request.htldtls.htimg}
                        className="htlImg"
                        title="Hotel"
                        alt="Hotel"
                    /></div>:
                    <div className='htlImagee icons'>
                        </div>}
                    <div className="sumryInfo"> <p>{request.htldtls.htnm}</p>
                        <div className="extDetails">
                        <span className="stars">
                        {Utilities.getHotelStartRating(request.htldtls.rating).map((star, idx) => (
                        <React.Fragment key={idx}>
                         <span className="ezyIcon icon-star"></span>
                        </React.Fragment>
                        ))}
                        </span>
                            <p className="propertyAdress ezyIcon icon-map"><span>{request.htldtls.add}, {request.htldtls.city}, {request.htldtls.country}</span></p>
                        </div>

                    </div>
                </div>

                <div className="dateinfo">
                    <div className="hBkng from">
                        <strong>Check-In</strong>
                        <span>{request.htldtls.cin}</span>
                        <span className="timeIcon ezyIcon icon-time"></span>
                    </div>
                    <div className="hBkng to">
                        <strong>Check-Out</strong>
                        <span>{request.htldtls.cout}</span>
                    </div>
                </div>


                <div className="gustSUmmary">
                    <RoomPrice roomsData={request} />
                </div>


            </div>
           }
        </>

    )
}
export default BookingSummary