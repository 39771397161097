import React from 'react';

function ShowFlightInfo(props) {

    return (
        <>
            <div className="definedServices">
            {props.response!==undefined &&
             <ul>
            {props.response.flt_markups!==undefined && props.response.flt_markups.length!==0 &&
            <li>
             <strong>  Markup Name: </strong>
            {props.response.flt_markups.map((mark, index) => (
                <span>{mark.name}{props.response.flt_markups[index + 1] ? "," : ""} </span>
            ))}

          </li>
            }

            {props.response.flt_service!==undefined && props.response.flt_service.length!==0 &&
            <li>
             <strong> Service Fee: </strong>
            {props.response.flt_service.map((service, index) => (
                <span>{service.name}{props.response.flt_service[index + 1] ? "," : ""}</span>
            ))}
           </li>
            }

            {props.response.deal_codes!==undefined && props.response.deal_codes.length!==0 &&
            <li>
            <strong> Deal Code: </strong>
            {props.response.deal_codes.map((deal, index) => (
                <span>{deal.name}{props.response.deal_codes[index + 1] ? "," : ""}</span>
            ))}
           </li>
            }
            </ul>
           }
         </div>

            {(props.response===undefined || props.response!==undefined && props.response.flt_markups!==undefined && props.response.flt_service!==undefined && props.response.deal_codes!==undefined && props.response.flt_markups.length===0 && props.response.flt_service.length===0 && props.response.deal_codes.length===0) &&
           <div className="definedServices">
           <span> No Data</span>
           </div>
           }


        </>
    )
}


export default ShowFlightInfo
