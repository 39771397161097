import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button'

/**
 * @description:This function will work for Payment Failed pop Up.
 * @author: Pravas
 * @param {*}
 * @function PaymentFailed
 * @date : 01-09-2020
 */
function PaymentFailed() {

  return (
    <>
          <div className="layover layout">
            <div className="popUpBox paymentFailed">
              <div className="icons failed"></div>
              <strong>Payment has failed</strong>
              <p>Sorry. We are unable to process this transaction,<br/>
              Please try again with another card or a different payment method.</p>
          <div className="buttons mt-2">
                          <Button
                          size="md"
                          className="return mr-2"
                          variant="outline-secondary"
                        >Return to home</Button>
                        <Button
                          size="md"
                          className="refresh"
                          variant="outline-secondary"
                        >Try Again</Button>
          </div>

            </div>
          </div>

    </>
  )
}
export default PaymentFailed