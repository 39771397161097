/**
 * @description : Dash Board Details Will diplay in this page
 * @author :Praavs Kumar
 * @date : 04-09-2020
 */
 import React, { useContext, useReducer, useState, useEffect } from 'react';
 import { Alert, Col, Row } from "react-bootstrap";
 import { Bar } from 'react-chartjs-2';


 /**
  * Initial State Declaration
  */
 const initialState = {

 }
 // Reducer function for useReact Hook which will update the state
 const reducer = (state, action) => {
   switch (action.type) {
     case 'clear':
       return {
         ...state, categoryName: ''
       };
     default:
       return { ...state, [action.type]: action.payload };
   }
 };
 //=================
 // Function Implementaton which will recevie props
 //=================
 function ConfirmedSales(props) {
   const [state, dispatch] = useReducer(reducer, initialState);

   const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov','Dec'],
    datasets: [
      {
        label: 'Flight (95)',
        data: [12, 19, 3, 5, 2, 3, 20, 24, 40, 50, 27, 56],
        backgroundColor: 'rgb(0 54 122)',
      },
      {
        label: 'Hotel (33)',
        data: [2, 3, 20, 5, 1, 4,7,21,45,22,1,23],
        backgroundColor: 'rgb(242 111 34)',
      },

    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  }

   return (
     <>
       <h3>Sales Summary</h3>
       <Row>
         <Col xs={8}>
         <Bar data={data} options={options} />
         </Col>
         <Col xs={4}>
           <Row>
             <Col xs="12"></Col>
             <Col xs="12"></Col>
           </Row>
         </Col>
       </Row>
     </>
   )

 }
 export default ConfirmedSales