
import React, { useState,useEffect } from 'react';
import { Col, Row, Modal } from "react-bootstrap";
import ItemsCarousel from 'react-items-carousel';
import {getAllPackages} from '../../dashBoard/operations';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackageDetails from '../../dashBoard/PackageDetails';

function TopSellingPackages(props) {
    const [state, setState] = useState({})
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [response, setResponse] = useState([]);
    const  [showPackage , setShowPackage] = useState(false)
    const  [packageObj,setPackageObj] =useState("")

    const chevronWidth = 40;

    const trendinsettings = {
      dots: true,
      infinite: response.length > 3,
      speed: 500,
      slidesToShow: 3,
      autoplay: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    useEffect(() => {
      getAllPackages().then(response => {
        console.log('Package response', response)
        if (response.suc &&  response.res) {
          let resp=[];
          for(let val of response.res){
            if(val.cha_b2b && val.image!=="" && val.image!==null && val._id!==null){
              resp.push(val)
            }
          }
          setResponse(resp)
        }
      }).catch(function (error) {
        console.log('Package response error ', error)
      });
    }, [])

    const openPackagePopup=(obj)=>{
      setShowPackage(true);
      setPackageObj(obj)
    }

    return (
        <>

{response.length!==0 &&
<section className="trendingPackages innerTrending dashBordLayout pb-5" id="trendingPackage">
        <div className="container">

          <div className="trendingItemscont">
        <h2 className="titleLine"><span>Riyadh Season</span></h2>

         <span className="semiTitle">Riyadh Season has become one of Saudi’s favourite festivals since its successful launch in 2019. This year it returns bigger than ever with a total of 7,500 days of activities and 14 unmatched zones. Last year, we asked you to imagine and this year we’re asking you to IMAGINE MORE and to travel beyond your imagination; because the exciting surprises, events and activities we have in store for you will make this season a citywide extravaganza curated for all age groups
</span>
         </div>

         {response.length!==0 &&
       <div className="sliderItem">

        {/* <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<button><img src={Rightimage} /></button>}
        rightChevron={<button><img src={Leftimage} /></button>}
        outsideChevron
        chevronWidth={chevronWidth}
      >
         {response.map((pack, sidx) => (
          <React.Fragment>
                  <div className="slideImg">
                    <div className="newpackageDetails">
                    <span className="placeName">{pack.package_name_en}<br/> <span className="duration">{pack.noofDays}NIGHTS / {pack.noofDays+1}DAYS</span></span>
                     <span className="price"><em>QAR</em> {pack.amt} </span>
                     </div>
                    <img
                      src={pack.image}
                      title=""
                      alt=""
                    />
              </div>
         </React.Fragment>
        ))}
      </ItemsCarousel> */}
{response.length!==0 && (
<Slider {...trendinsettings}>
         {response.map((pack, sidx) => (
             <React.Fragment>
                  <div className="slideImg">
                    <img
                      src={pack.image}
                      title=""
                      alt=""
                      onClick={()=>openPackagePopup(pack)}
                    />
              </div>

              <div className="newpackageDetails d-flex justify-content-left">
                    <span  className="placeName" title={pack.package_name_en}>{pack.package_name_en}</span>
                     {/* <span className="price"><span>QAR</span> {pack.amt} </span> */}
                     </div>
              </React.Fragment>
        ))}
      </Slider>
        )}

</div>
   }
         </div>
        </section>
}
            {/* <div className="bestSellingPKG">
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col>
                                <h2>
                                    Trending Packages
                                </h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Col>
                        </Row>
                    </Col>

        <Col xs={6}>
        <div className="sliderItem">
        <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<button>{'<'}</button>}
        rightChevron={<button>{'>'}</button>}
        outsideChevron
        chevronWidth={chevronWidth}
      >
         {response.length!==0 && response.map((pack, sidx) => (
           <>
           {pack.active==="true" && pack.image!==undefined && pack.image!=="" && pack.cha_b2b &&
                  <div className="slideImg">
                    <span>{pack.package_name_en} {pack.noofDays}NIGHTS / {pack.noofDays+1}DAYS</span>
                     <span className="price"><em>QAR</em> {pack.amt} </span>
                    <img
                      src={pack.image}
                      title=""
                      alt=""
                    />
              </div>
         }
         </>
        ))}
      </ItemsCarousel>
</div>

                    </Col>

                </Row>
            </div> */}

<Modal
        show={showPackage}
        onHide={()=>setShowPackage(false)}
        className="importPNRMdl pacakgeDetailspop"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <PackageDetails selpackage={packageObj} />
          </div>
        </Modal.Header>
      </Modal>
        </>


    )
}


export default TopSellingPackages;
