import React, { useState, useReducer, useEffect, useContext } from "react";
import { Alert, Col, Form } from "react-bootstrap";
import { Context } from "../../../../../App";
import Footer from "../../../../components/common/Footer";
import InnerLoader from "../../../../components/common/loader/InnerLoader";
import MainMenu from "../../../common/MainMenu";
import OrganizationMenu from "../../../common/subMenu/OrganizationMenu";
import RegionType from "../../common/RegionType";
import Button from "../../../../components/common/buttons/Button";
import { deleteTdsInfo, getTdsInfo, saveTdsInfo } from "./operations";
import { updateTDSData } from "./operations";
import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../common/popUps/NoRecord";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
import Breadcrumb from "../../../common/breadcrumb/Breadcrumb";
export const USER_AUTH_DATA = "userAuthData";

/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function TDS Configuration
 */

const initialState = {
  isLoadingMore: false,
  isLoadingMore: "",
  region_type: "",
  region_type_edit: "",
  RegionSelected: false,
  fl: true,
  hotl: false,
  ins: false,
  productType: "flight",
  pTFlight: true,
  pTHotel: false,
  pTInsurance: false,
  pTFlightEnable: true,
  pTHotelEnable: true,
  pTInsuranceEnable: true,
  enbaleUpdate: false,
  res: [],
  id: [],
  notiMessageShow: "",
  flights: true,
  hotels: false,
  insurance: false,
  flightsEnable: true,
  hotelsEnable: true,
  insuranceEnable: true,
  productType: "flight",
  plbDiscount: "",
  dealDiscount: "",
  commisionDiscount: "",
  branchList: false,
  disabled: "",
  showSave: true,
  selcompany: [],
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        isLoadingMore: false,
        region_type: "",
        region_type_edit: "",
        RegionSelected: false,
        id: 0,
        fl: true,
        hotl: false,
        ins: false,
        pTFlight: false,
        pTHotel: false,
        pTInsurance: false,
        enbaleUpdate: false,
        res: [],
        notiMessageShow: false,
        flights: false,
        hotels: false,
        insurance: false,
        productType: "flight",
        plbDiscount: "",
        dealDiscount: "",
        commisionDiscount: "",
        disabled: "",
      };
    case "editBranch":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function TDSConfig() {
  const [getProductType, setGetProductType] = useState("fl");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch({ type: "isLoadingMore", payload: false });
    dispatch({ type: "enbaleUpdate", payload: false });
    getTdsInfo({"is_admin":context.logindata.is_admin, "region_type":context.logindata.region_type}).then((response) => {
      if (response.data.suc) {
        loadResponse(response.data.res);
        // dispatch({ type: "res", payload: response.data });
      }
    });
  },[context.logindata.region_type]);

  
  // useEffect(() => {
  //   if(context.logindata!==undefined && state.region_type){
  //   let is_admin=context.logindata.utype==="att_user"?1:0
  //    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
  //     AttCommonSerivce.att_companies(pyload).then(response => {
  //       if (response.data.suc && response.data.res.status === 200) {
  //         dispatch({ type: "productType", payload: response.data.res.data });

  //       }
  //     })
  //   }
  // }, [context.logindata,state.region_type]);

  const handleRegionType = () => {
    // debugger;
    dispatch({ type: "branchList", payload: false });
    dispatch({ type: "RegionSelected", payload: true });
  };

  const updateRegion = (region) => {
    dispatch({ type: "region_type", payload: region });
  };

  const gstHandler = (getProductType) => {
    setGetProductType(getProductType);
  };

  const handleProductChange = (event) => {
    if (event.target.id === "flight") {
      gstHandler("fl");
      dispatch({ type: "productType", payload: event.target.value });
      dispatch({ type: "flights", payload: true });
      dispatch({ type: "hotels", payload: false });
      dispatch({ type: "insurance", payload: false });
    } else if (event.target.id === "hotel") {
      gstHandler("ho");
      dispatch({ type: "productType", payload: "hotel" });
      dispatch({ type: "flights", payload: false });
      dispatch({ type: "insurance", payload: false });
      dispatch({ type: "hotels", payload: true });
      dispatch({type:"plbDiscount", payload:""})
      dispatch({type:"dealDiscount", payload:""})
    } else if (event.target.id === "insurance") {
      gstHandler("ins");
      dispatch({ type: "productType", payload: "insurance" });
      dispatch({ type: "flights", payload: false });
      dispatch({ type: "hotels", payload: false });
      dispatch({ type: "insurance", payload: true });
      dispatch({type:"plbDiscount", payload:""})
      dispatch({type:"dealDiscount", payload:""})
    }
  };

  const productTypeHandler = (getProductType) => {
    setGetProductType(getProductType);
  };
  const handleProductTypeChange = (event) => {
    if (event.target.id === "flight") {
      productTypeHandler("fl");
      dispatch({ type: "getproductType", payload: event.target.value });
      dispatch({ type: "flight", payload: true });
      dispatch({ type: "hotel", payload: false });
      dispatch({ type: "insurance", payload: false });
    } else if (event.target.id === "hotel") {
      productTypeHandler("ho");
      dispatch({ type: "getproductType", payload: event.target.value });
      dispatch({ type: "flight", payload: false });
      dispatch({ type: "hotel", payload: true });
      dispatch({ type: "insurance", payload: false });
    } else if (event.target.id === "insurance") {
      productTypeHandler("ins");
      dispatch({ type: "getproductType", payload: event.target.value });
      dispatch({ type: "flight", payload: false });
      dispatch({ type: "hotel", payload: false });
      dispatch({ type: "Insurance", payload: true });
    }
  };

  const handleCancel = () => {
    window.location = "/ezytrip/administration/tds";
  };
  const resetValues = () => {
    dispatch({ type: "plbDiscount", payload: "" });
    dispatch({ type: "dealDiscount", payload: "" });
    dispatch({ type: "commisionDiscount", payload: "" });
    dispatch({ type: "region_type", payload: [] });
    window.location = "/ezytrip/administration/tds";
  };

  const columns = [
    {
      dataField: "region",
      text: "Region",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "product_type",
      text: "Product Type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "plbDis",
      text: "On PLB (%)",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "dealDis",
      text: "On Deal (%)",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "commissionDis",
      text: "On Commission (%)",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "options",
      text: "Action",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const loadResponse = (response) => {
    let tableData = [];
    // var enableWEdit = false;
    var enableWDel = false;
    var enableWView = false;
    if(context.logindata!==""){
      // if(context.logindata.roles.includes('E_T_D_S ')) {
      //   enableWEdit = true;
      // }
      if(context.logindata.roles.includes('D_T_D_S')) {
        enableWDel = true;
      }
      if(context.logindata.roles.includes('V_T_D_S')) {
        enableWView = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      // if(userData.roles.includes('E_T_D_S ')) {
      //   enableWEdit = true;
      // }
      if(userData.roles.includes('D_T_D_S')) {
        enableWDel = true;
      }
      if(userData.roles.includes('V_T_D_S')) {
        enableWView = true;
      }
    }    
    for (let val of response) {
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.region){
      tableData.push({
        region: val.region,
        product_type: val.product,
        plbDis: val.plb,
        dealDis: val.deal,
        commissionDis: val.comm,
        options: (
          <>
            <span className="tbAction">
              {enableWView &&
                <a
                  className="ezyIcon icon-view"
                  onClick={editTdsBtnClick(val, "view")}
                ></a>
              }
              {
                <a
                  className="ezyIcon icon-edit"
                  onClick={editTdsBtnClick(val, "Edit")}
                ></a>
              }
              {enableWDel &&
                <a
                  className="ezyIcon icon-delete"
                  onClick={editTdsBtnClick(val, "delete")}
                ></a>
              }
            </span>
          </>
        ),
      });
    }
      if (response.length !== 0) {
        dispatch({ type: "res", payload: tableData });
      }
      dispatch({ type: "isLoadingMore", payload: false });
    }
  };

  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  }

  const tdsValidation = () => {
    if (state.region_type == "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Please Select Region" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.productType == "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Please Select Product Type" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (
      state.plbDiscount === "" &&
      state.dealDiscount === "" &&
      state.commisionDiscount === "" &&
      getProductType === "fl"
    ) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Select atleast one discount" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ho") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Commission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ho") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Commission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ins") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Comission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    return true;
  };

  const addTdsInfo = () => {
    // debugger;
    // tdsValidation();
    if (tdsValidation()) {
      let suc = true;
      for (let val of state.res) {
        if (val.region.toLowerCase() == state.region_type.toLowerCase()) {
          if (
            val.region.toLowerCase() == state.region_type.toLowerCase() &&
            val.product_type.toLowerCase() == state.productType.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if (suc) {
        let tdsData = {
          region: context.logindata.utype === "att_user"
          ? state.region_type
          : context.logindata.region_type,
          product: state.productType,
          plb: state.plbDiscount,
          deal: state.dealDiscount,
          comm: state.commisionDiscount,
          crby: context.logindata.uid,
          // crby: 1,

          
          // region: state.region_type,
          // product: state.productType,
          // plb: state.plbDiscount,
          // deal: state.dealDiscount,
          // comm: state.commisionDiscount,
          // crby: context.logindata.uid,
          // // crby: 1,
        };
        saveTdsInfo(tdsData).then((response) => {
          // if (response) {
          //   window.location.reload();
          // }
          if (response.suc) {
            resetValues();
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "TDS Config saved successfully",
            });
            dispatch({ type: "notiVarient", payload: "success" });
            window.location = "/ezytrip/administration/tds";
          }
        });
      } else {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Region with Product type already exist",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
        // window.location = "/ezytrip/administration/tds";
        return false;
      }
    }
  };

  

  const validation = () => {
    if (
      state.plbDiscount === "" &&
      state.dealDiscount === "" &&
      state.commisionDiscount === "" &&
      getProductType === "fl"
    ) {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Select atleast one discount" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ho") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Commission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ho") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Commission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (state.commisionDiscount === "" && getProductType === "ins") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Comission Discount is required",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    return true;
  };

  const updateTdsData = () => {
    if (validation()) {
      dispatch({ type: "isItineraryLoading", payload: true });
      let obj = {
        id: state.id,
        plb: state.plbDiscount,
        deal: state.dealDiscount,
        comm: state.commisionDiscount,
        upby: context.logindata.uid,
      };
      updateTDSData(obj).then((response) => {
        if (response.suc) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Updated TDS successfully",
          });
          dispatch({ type: "notiVarient", payload: "success" });
          hidemessage();
          return (window.location = window.location.pathname);
        }
        dispatch({ type: "isItineraryLoading", payload: false });
      });
    }

    // debugger;
    // dispatch({ type: "isItineraryLoading", payload: true });
    // console.log("SDebug > state values", state);
    // let obj = {
    //   id: state.id,
    //   plb: state.plbDiscount,
    //   deal: state.dealDiscount,
    //   comm: state.commisionDiscount,
    //   upby: context.logindata.uid,
    // };

    // updateTDSData(obj).then((response) => {
    //   if (response) {
    //     window.location.reload();
    //   }
    // });
  };

  const editTdsBtnClick = (obj, type) => () => {
    // debugger;

    if (type == "Edit") {
      dispatch({ type: "enableView", payload: true });
      dispatch({ type: "id", payload: obj.id });
      dispatch({ type: "region_type_edit", payload: obj.region });
      dispatch({ type: "productType", payload: obj.product });
      // dispatch({ type: "disabled", payload: obj.product_type });
      if (obj.product === "flight") {
        dispatch({ type: "flights", payload: true });
        dispatch({ type: "hotels", payload: false });
        dispatch({ type: "insurance", payload: false });
        dispatch({ type: "flightsEnable", payload: true });
        dispatch({ type: "hotelsEnable", payload: false });
        dispatch({ type: "insuranceEnable", payload: false });
        dispatch({ type: "productType", payload: obj.product });
        dispatch({ type: "plbDiscount", payload: obj.plb });
        dispatch({ type: "dealDiscount", payload: obj.deal });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        setGetProductType("fl");
      } else if (obj.product === "hotel") {
        dispatch({ type: "hotels", payload: true });
        dispatch({ type: "flights", payload: false });
        dispatch({ type: "insurance", payload: false });
        dispatch({ type: "flightsEnable", payload: false });
        dispatch({ type: "hotelsEnable", payload: true });
        dispatch({ type: "insuranceEnable", payload: false });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        dispatch({ type: "productType", payload: obj.product });
        setGetProductType("ho");
      } else if (obj.product === "insurance") {
        dispatch({ type: "hotels", payload: false });
        dispatch({ type: "flights", payload: false });
        dispatch({ type: "insurance", payload: true });
        dispatch({ type: "flightsEnable", payload: false });
        dispatch({ type: "hotelsEnable", payload: false });
        dispatch({ type: "insuranceEnable", payload: true });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        dispatch({ type: "productType", payload: obj.product });
        setGetProductType("ho");
      }
      dispatch({ type: "enabeleEdit", payload: true });
      dispatch({ type: "enbaleUpdate", payload: true });
      dispatch({ type: "disabled", payload: false });
    }

    if (type === "view") {
      dispatch({ type: "enableView", payload: true });
      dispatch({ type: "id", payload: obj.id });
      dispatch({ type: "region_type_edit", payload: obj.region });
      dispatch({ type: "productType", payload: obj.product });
      dispatch({ type: "enbaleUpdate", payload: false });
      dispatch({ type: "showSave", payload: false });
      dispatch({ type: "id", payload: obj.id });
      dispatch({ type: "region_type_edit", payload: obj.region });
      dispatch({ type: "productType", payload: obj.product });
      dispatch({ type: "disabled", payload: true });
      // dispatch({ type: "disabled", payload: obj.product_type });
      if (obj.product === "flight") {
        dispatch({ type: "flights", payload: true });
        dispatch({ type: "hotels", payload: false });
        dispatch({ type: "insurance", payload: false });
        dispatch({ type: "flightsEnable", payload: true });
        dispatch({ type: "hotelsEnable", payload: false });
        dispatch({ type: "insuranceEnable", payload: false });
        dispatch({ type: "productType", payload: obj.product });
        dispatch({ type: "plbDiscount", payload: obj.plb });
        dispatch({ type: "dealDiscount", payload: obj.deal });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        setGetProductType("fl");
      } else if (obj.product === "hotel") {
        dispatch({ type: "hotels", payload: true });
        dispatch({ type: "flights", payload: false });
        dispatch({ type: "insurance", payload: false });
        dispatch({ type: "flightsEnable", payload: false });
        dispatch({ type: "hotelsEnable", payload: true });
        dispatch({ type: "insuranceEnable", payload: false });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        dispatch({ type: "productType", payload: obj.product });
        setGetProductType("ho");
      } else if (obj.product === "insurance") {
        dispatch({ type: "hotels", payload: false });
        dispatch({ type: "flights", payload: false });
        dispatch({ type: "insurance", payload: true });
        dispatch({ type: "flightsEnable", payload: false });
        dispatch({ type: "hotelsEnable", payload: false });
        dispatch({ type: "insuranceEnable", payload: true });
        dispatch({ type: "commisionDiscount", payload: obj.comm });
        dispatch({ type: "productType", payload: obj.product });
        setGetProductType("ho");
      }
      dispatch({ type: "enabeleEdit", payload: false });
      dispatch({ type: "enbaleUpdate", payload: false });
    }
    if (type === "delete") {
      confirmDlg(obj);
    }
  };
  const confirmDlg = (obj, cb) => {
    confirmAlert({
      customUI: () => {
        return (
          <>
            <div className="custom-ui">
              <span className="confirmD"></span>
              <h3>Are you sure?</h3>
              <p className="t-color2 mb-1">You want to delete the TDS?</p>
            </div>
            <div className="buttonGrop text-center border-0 pb-0">
              <button
                className="btn btn-xs btn-secondary pl-4 pr-4"
                onClick={() => {
                  handleDelete(obj);
                }}
              >
                {" "}
                Yes{" "}
              </button>
              <button
                className="btn btn-primary btn-xs pl-4 pr-4"
                onClick={handleCancel}
              >
                No{" "}
              </button>
            </div>
          </>
        );
      },
    });
  };

  const handleDelete = (obj) => {
    deleteTdsInfo(obj.id).then((response) => {
      if (response) {
        window.location.reload();
      }
    });
  };

  return (
    <>
      {state.notiMessageShow && (
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
      )}
      {state.isItineraryLoading ? (
        <div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <MainMenu active="Organization/CM" />
      <Breadcrumb activePage="TDS Configuration" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <OrganizationMenu active="TDS Config" />
          <br></br>
          <Form.Row>
            {context.logindata.utype !== "ta_user" && (
              <RegionType
              sendResponse={(e) => handleRegionType(e)}
              regionUpdate={state.region_type_edit}
              disabled={state.enabeleEdit}
              regionType={updateRegion}
              regionError={state.regionError}
            />
            )}
          </Form.Row>

          <Form.Row>
            <Form.Group
              as={Col}
              xs={6}
              controlId="productType"
              className="inputCheck"
            >
              <Form.Label className="mr-2">
                Product Type <sup>*</sup>
              </Form.Label>

              <div className="d-flex align-items-center">
                {state.flightsEnable && (
                  <Form.Check
                    type="radio"
                    label="Flights"
                    id="flight"
                    checked={state.flights}
                    value={state.productType}
                    disabled={state.disabled}
                    onChange={handleProductChange}
                    custom
                    name="classes"
                  />
                )}
                {state.hotelsEnable && (
                  <Form.Check
                    type="radio"
                    label="Hotels"
                    id="hotel"
                    checked={state.hotels}
                    value={state.productType}
                    disabled={state.disabled}
                    onChange={handleProductChange}
                    custom
                    name="classes"
                    className="ml-3"
                  />
                )}
                {state.insuranceEnable && (
                  <Form.Check
                    type="radio"
                    label="Insurance"
                    id="insurance"
                    checked={state.insurance}
                    value={state.productType}
                    disabled={state.disabled}
                    onChange={handleProductChange}
                    custom
                    name="classes"
                    className="ml-3"
                  />
                )}
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Label className="mr-2">Applicable On</Form.Label>

          <Form.Row>
            {getProductType === "fl" && (
              <>
                <Form.Group as={Col} xs={3} controlId="plbDiscount">
                  <Form.Label className="mr-2">
                    PLB Discount (%) <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    disabled={state.disabled}
                    onChange={(e) => {
                      dispatch({
                        type: "plbDiscount",
                        payload: e.target.value.toString(),
                      });
                    }}
                    value={state.plbDiscount}
                  ></Form.Control>
                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="dealDiscount">
                  <Form.Label className="mr-2">
                    Deal Discount (%) <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    disabled={state.disabled}
                    onChange={(e) => {
                      dispatch({
                        type: "dealDiscount",
                        payload: e.target.value.toString(),
                      });
                    }}
                    value={state.dealDiscount}
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="commisionDiscount">
                  <Form.Label className="mr-2">
                    Commision Discount (%) <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    disabled={state.disabled}
                    onChange={(e) => {
                      dispatch({
                        type: "commisionDiscount",
                        payload: e.target.value.toString(),
                      });
                    }}
                    value={state.commisionDiscount}
                  ></Form.Control>
                </Form.Group>
              </>
            )}
            {getProductType === "ho" && (
              <Form.Group as={Col} xs={3} controlId="isgt">
                <Form.Label className="mr-2">
                  Commision Discount (%) <sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  disabled={state.disabled}
                  onChange={(e) => {
                    dispatch({
                      type: "commisionDiscount",
                      payload: e.target.value.toString(),
                    });
                  }}
                  value={state.commisionDiscount}
                ></Form.Control>
              </Form.Group>
            )}
            {getProductType === "ins" && (
              <Form.Group as={Col} xs={3} controlId="isgt">
                <Form.Label className="mr-2">
                  Commision Discount (%) <sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  disabled={state.disabled}
                  onChange={(e) => {
                    dispatch({
                      type: "commisionDiscount",
                      payload: e.target.value.toString(),
                    });
                  }}
                  value={state.commisionDiscount}
                ></Form.Control>
              </Form.Group>
            )}
          </Form.Row>

          <div className="text-right pb-0 buttonGrop creat_absolute">
            {state.enbaleUpdate && (
              <Button
                size="xs"
                variant="outline-secondary"
                onClick={handleCancel}
                type="submit"
              >
                {" "}
                Back
              </Button>
            )}
            {context.logindata.roles !== undefined && !state.enbaleUpdate && (
              <Button
                size="xs"
                variant="outline-secondary"
                onClick={resetValues}
                type="submit"
                // isDisabled={state.enableView}
              >
                Reset
              </Button>
            )}
            {!state.enbaleUpdate && state.showSave && context.logindata.roles !== undefined && context.logindata.roles.includes('C_T_D_S') && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={addTdsInfo}
                type="submit"
              >
                Save
              </Button>
            )}
            {state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('U_T_D_S') && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={updateTdsData}
                // isDisabled={state.enableView}
                type="submit"
              >
                Update
              </Button>
            )}
          </div>
        </div>

        <div className="cardMainlayout tableRes p-0">
          {state.isLoadingMore ? (
            <InnerLoader />
          ) : (
            <>
              {(state.res.length !== 0 && (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={
                    state.res.length > 10 && state.res.length <= 25
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                          ],
                        })
                      : state.res.length > 25 && state.res.length <= 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                          ],
                        })
                      : state.res.length > 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                            { text: "50", value: 50 },
                          ],
                        })
                      : ""
                  }
                />
              )) ||
                (state.res.length === 0 && <NoRecord />)}
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default TDSConfig;
