import TDSConfigurationService from "../../../../services/administration/TDSConfigurationService";
/**
 * @description: CRUD operations for TDS Configuration
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function TDS Configuration
 * @date : 30-03-2023
 */

export const getTdsInfo = async (obj) => {
  // debugger;
  let finalRes = {};
  await TDSConfigurationService.getTdsInfo(obj).then((response) => {
    // if (response.data.suc) {
    finalRes = response;
    // }
  });
  return Promise.resolve(finalRes);
};

export const saveTdsInfo = async (obj) => {
  // debugger;
  let finalRes = {};
  await TDSConfigurationService.saveTdsInfo(obj).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

export const updateTDSData = async (obj) => {
  let finalRes = {};
  await TDSConfigurationService.updateTDS(obj).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};

export const deleteTdsInfo = async (id) => {
  let finalRes = {};
  await TDSConfigurationService.deleteTds(id).then((response) => {
    finalRes = response.data;
  });
  return Promise.resolve(finalRes);
};
