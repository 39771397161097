
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Col, Form,Alert  } from 'react-bootstrap';
import AddRemainder from './AddRemainder';
import moment from "moment";
import Moment from 'react-moment';
import DateUtils from '../../commonUtils/DateUtils';
function ReminderCall(props) {
    const [state, setState] = useState({})
    const [addriminder, setAddriminder,] = useState(false);

    const openRiminder = () => {
        if (addriminder) {
          setAddriminder(false)
        } else {
          setAddriminder(true)
        }
      }
      const closeRiminder = () =>{
        setAddriminder(false)
      }

    return (
        <>
            <div className="reminderCall">
                <Scrollbars style={{ height: 440 }}>
                    <ul className="p-4 m-0 pr-4 reminCall">
                        {props.remaiList!==undefined && props.remaiList.map((item,idx) => (
                             <React.Fragment key={idx}>
                            {item.remai_status==="Open" &&
                            <li  className="reminderList ezyIcon active" >
                                {/* {item.priority} */}
                            <div className="callerDetails d-flex">
                                <p className="flex-column"><span title="Call with Peter Anderson">{item.remai_name}</span>
                                 <em className="ezyIcon icon-newcalendar">
                            {(DateUtils.prettyDate(new Date(), 'DD-MM-YYYY') == DateUtils.prettyDate(item.date_time, 'DD-MM-YYYY')) ? "Today" : (DateUtils.prettyDate(DateUtils.addDatePlusOne(new Date(),'DD-MM-YYYY'), 'DD-MM-YYYY' ) == DateUtils.prettyDate(item.date_time, 'DD-MM-YYYY')) ? "Tomorrow" : (DateUtils.prettyDate(DateUtils.addDateMinusOne(new Date(),'DD-MM-YYYY'), 'DD-MM-YYYY' ) == DateUtils.prettyDate(item.date_time, 'DD-MM-YYYY')) ? "Yesterday" : (DateUtils.prettyDate(item.date_time, 'DD-MM-YYYY'))
                           }</em>
                                 </p>
                            </div>
                            <div className="time d-flex">
                                <p className="ezyIcon icon-time">
                        {DateUtils.prettyDate(item.date_time,'hh:mm A')}
                        </p>

                            </div>
                        </li>
                         }
                        </React.Fragment>
                        )).slice(0,  props.remaiList.length='7')}
                        <li className="addRemin"> <div className="dashBordReminder">
        <Button  size="md" className="viewallqu" onClick={openRiminder}  variant="primary">+ Add Reminder</Button>
      </div></li>
                    </ul>
                </Scrollbars>

                {addriminder &&
                  <AddRemainder closeRemainder={closeRiminder}/>
                 }
            </div>
        </>
    )
}


export default ReminderCall;
