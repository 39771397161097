import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";

//This function will show the Air markups List and Add Markup
function InsPolicyDetails(props) {
  const [state, setState] = useState({
    loading: false
  })

  return (
    <>
        <div className="resultSection boxShadow bg-white summary mb-4 newlayoutDesign">
          <Row className="pb-2 ">
                <Col>
                <span>No. Of pax:</span> {props.policy.adt+" Adults"} {props.policy.chd!==0 && props.policy.chd+" Children"}
                </Col>
                <Col>
                <span>Email Id: </span> {props.paxInfo.em}
                </Col>
                <Col>
                <span>Phone#: </span>{props.paxInfo.phc} {props.paxInfo.phn}
                </Col>
                </Row>
                <Row className="pb-2 ">
                <Col>
                <span>Policy Number:</span> <span className='bookNum'>{props.policy.policy_number}</span>
                </Col>
                <Col>
                <span>Departure date: </span> {props.policy.dep_date}
                </Col>
                <Col>
                <span>Return date: </span>  {props.policy.retu_date}
                </Col>
                </Row>
                <Row className="pb-2 ">
                <Col>
                <span>Coverage Starts:</span> {props.policy.cov_stdate}
                </Col>
                <Col>
                <span>Coverage Ends: </span> {props.policy.retu_date}
                </Col>
                <Col>
                <span>Coverage Duration: </span> <span> {props.policy.trvl_dur} Days</span>
                </Col>
                </Row>
                <Row className="pb-2 ">
                <Col>
                <span>Travel Duration: </span> <span> {props.policy.trvl_dur} Days</span>
                </Col>
                </Row>
        </div>
    </>
  )
}
export default InsPolicyDetails