
/**
 * @description: CRUD operations for Deal code
 * @author: Rambabu
 * @param {*}
 * @function Commission
 * @date : 15-10-2020
 */


import OfficieIdConfig from '../../../../services/administration/OfficieIdConfig';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: Fetch Branch details
 * @author: Rambabu
 * @param {*}
 * @function getBranchData
 * @date : 15-10-2020
 */
// ========================================================================
// search markup
//  This function retrives the selected values while clicking on Edit
// ========================================================================
export const searchService = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.searchService(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// Create revenue  
export const createRevenue = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.createRevenue(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const updateRevenue = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.updateRevenue(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// Retreive revenue 
export const getRevenueData = async () => {
    let holdBookigsList = [];
    await AttCommonSerivce.getRevenueData().then(response => {
        if (response.data.suc) {
            holdBookigsList = response.data.res;
        }
    });
    return Promise.resolve(holdBookigsList);
}
// Edit Revenue call
export const editRevenue = async (countryId) => {
    let citiesList = [];
    await AttCommonSerivce.editRevenue(countryId).then(response => {
        if (response.data.suc) {
            citiesList=response.data.res
        }
    });
    return Promise.resolve(citiesList);
};

// delete records
export const deleteRevenue = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.deleteRevenue(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// delete airports 
export const deleteRoutes = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.deleteRoutes(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
export const fetch_company_list = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.fetch_company_list(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
export const getOfcIdList = async (obj) => {
    let companies = [];
    await OfficieIdConfig.getOIDMasterData(obj).then(response => {
        companies = response;
    });
    return Promise.resolve(companies);
}

