import React, { useEffect, useReducer, useState,useRef,createRef } from "react";
import { Button, Form } from "react-bootstrap";
import InputRange from "react-input-range";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import AirlinesMemo from "../../../../common/airlines";
import AirportMemo from "../../../../common/airlines/airportNames";
import DateUtils from "../../../../commonUtils/DateUtils";
import FBFResultsPage from "../flightByFlight/FBFResultsPage";
import FormateCurrency from "../../../../commonUtils/FormateCurrency";
import Utilities from "../../../../commonUtils/Utilities";
import Sticky from '../../../../common/sticky/Sticky';
import ShowHide from "../../../../common/showHide/ShowHide";

//Initial State
const initialState = {
  response: {},
  finalResponse: {},
  temp: {},
  price: { min: 0, max: 0 },
  onwardDepTime: { min: 0, max: 24 },
  onwardArrivalTime: { min: 0, max: 24 },
  returnDeptTime: { min: 0, max: 24 },
  returnArrivalTime: { min: 0, max: 24 },
  stopsList: [],
  layoversList: "",
  airlines: [],
  onwardDep: true,
  onwardArrrival: false,
  returnDep: true,
  returnArrrival: false,
  airports: [],
  mapping: [],
  airlinesList: [],
  topAirLinesList:[],
  airportsArr: [],
  resType: "",
  searchResponse: "",
  refundable: false,
  priceFilterChanged: "",
  supplirsList: [],
  isLcc: false,
  isFsc: false,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "min":
      return { ...state, price: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//Main Function
function Filter(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showNearbyAirports, setShowNearbyAirports] = useState(false);
  const [showNearbyArrAirports, setShowNearbyArrAirports] = useState(false);
  const [showAirlines, setShowAirlines] = useState(false);
  const [isLcc, setIsLcc] = useState(false);
  const [isFsc, setIsFsc] = useState(true);

  const myRefs = useRef([]);
  useEffect(() => {
    const searcRs = props.searchRes;
    const initResp = props.initResp;
    dispatch({ type: "temp", payload: Object.assign({}, props.initResp) });
    dispatch({ type: "mapping", payload: props.mapping });
    dispatch({ type: "response", payload: searcRs });
    dispatch({ type: "resType", payload: searcRs.sty });
    dispatch({ type: "finalResponse", payload: Object.assign({}, initResp) });
    if (Object.keys(searcRs.filter).length !== 0) {
      dispatch({
        type: "price",
        payload: {
          min: searcRs.filter.minprc.toFixed(2),
          max: searcRs.filter.maxprc
            ? searcRs.filter.maxprc.toFixed(2)
            : searcRs.filter.minprc.toFixed(2),
        },
      });
    }
    dispatch({ type: "priceFilterChanged", payload: "" });
    dispatch({ type: "onwardDepTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "onwardArrivalTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "returnDeptTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "returnArrivalTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "stopsList", payload: [] });
    dispatch({ type: "layoversList", payload: "" });
    dispatch({ type: "airlines", payload: [] });
    dispatch({ type: "airports", payload: [] });
    dispatch({ type: "airlinesList", payload: [] });
    dispatch({type:"topAirLinesList", payload: []});
    dispatch({ type: "supplirsList", payload: [] });
    dispatch({ type: "refundable", payload: false });
  }, []);
  if(state&&state.response&&state.response.filter&&state.response.filter.airl){
  myRefs.current = state&&state.response&&state.response.filter&&state.response.filter.airl.map(
    (element, i) =>  createRef());
  }

   // Price Filter Logic
  const onPriceChange = (value) => {
    dispatch({ type: "price", payload: value });
    let res = state.finalResponse.origns.filter((flight) => {
      return flight.pr.tf >= value.min && flight.pr.tf <= value.max;
    });
    state.response.origns = res;
    props.handelChange(state.response, "");
  };

  // Stops Filter Logic
  const onStopsChange = (e) => {
    if (e.target.checked) {
      state.stopsList.push(parseInt(e.target.value));
    } else {
      for (var i = 0; i < state.stopsList.length; i++) {
        if (state.stopsList[i] === parseInt(e.target.value)) {
          state.stopsList.splice(i, 1);
          i--;
        }
      }
    }


    let res = [];
    if (state.stopsList.length > 0) {
      state.finalResponse.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((src) => {
          return state.stopsList.includes(parseInt(src.stop));
        });
        if (srcFiltered.length > 0) {
          if (state.response.tt === 2) {
            let desFiltered = flights.destorigins.filter((des) => {
              return state.stopsList.includes(parseInt(des.stop));
            });

            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = srcFiltered;
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (state.response.tt === 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);

            if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
      state.response.origns = res;
    } else {
      state.response.origns = state.finalResponse.origns;
    }

    // state.response.origns = res;
    props.handelChange(state.response, "");
  };

  // Airlines Filter
  const airlinesFilter = (e) => {
    let res = [];
    if (e.target.checked) {
      state.airlines.push(e.target.value);
    } else {
      for (var i = 0; i < state.airlines.length; i++) {
        if (state.airlines[i] === e.target.value) {
          state.airlines.splice(i, 1);
          i--;
        }
      }
    }
    if (state.airlines.length > 0) {
      state.finalResponse.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return flt.seg && state.airlines.includes(flt.seg[0].ac);
        });
        if (srcFiltered.length > 0) {
          if (state.response.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              return flt.seg && state.airlines.includes(flt.seg[0].ac);
            });

            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (state.response.tt === 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);

            if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
      state.response.origns = res;
    } else {
      state.response.origns = state.finalResponse.origns;
    }

    props.handelChange(state.response, "");
  };

  const airportFilter = (e) => {
    let cloneRes = Object.assign({}, state.finalResponse);
    let res = [];
    if (e.target.checked) {
      state.airports.push(e.target.value);
    } else {
      for (var i = 0; i < state.airports.length; i++) {
        if (state.airports[i] === e.target.value) {
          state.airports.splice(i, 1);
          i--;
        }
      }
    }
    if (state.airports.length > 0) {
      state.finalResponse.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return (
            flt.seg &&
            (state.airports.includes(flt.seg[0].da) ||
              state.airports.includes(flt.seg[flt.seg.length - 1].ar))
          );
        });

        if (srcFiltered.length > 0) {
          if (state.response.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airports.includes(flt.seg[0].da) ||
                  state.airports.includes(flt.seg[flt.seg.length - 1].ar))
              );
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (state.response.tt === 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);

            if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
    } else {
      state.response.origns = state.finalResponse.origns;
    }
    props.handelChange(state.response, "");
    state.finalResponse = {};
    state.finalResponse = cloneRes;
  };

  const openOnwardDEP = () => {
    dispatch({ type: "onwardDep", payload: true });
    dispatch({ type: "onwardArrrival", payload: false });
  };
  const openOnwardARI = () => {
    dispatch({ type: "onwardDep", payload: false });
    dispatch({ type: "onwardArrrival", payload: true });
  };

  const openReturndDEP = () => {
    dispatch({ type: "returnDep", payload: true });
    dispatch({ type: "returnArrrival", payload: false });
  };
  const openReturnARI = () => {
    dispatch({ type: "returnDep", payload: false });
    dispatch({ type: "returnArrrival", payload: true });
  };
  const filterApplyPrice = (name, e) => {
    dispatch({ type: "price", payload: e });
  };
  /*    const filterApplyPrice = (name, e) => {

    } */
  //Calculation the DISC
  const calcuateServiceFee = (serviceFeeList) => {
    var userType = props.srequest.butype === "ta_user" ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(
        serviceFeeList,
        userType,
        props.srequest
      );
      if (servicefee === "NaN") {
        return 0.0;
      } else {
        return servicefee;
      }
    } else {
      return 0.0;
    }
  };
  const layOverTimeCal = (one, two) => {
    // let totTime = Math.abs(new Date(one) - new Date(two));
    var from = moment(new Date(one));
    var to = moment(new Date(two));
    //     var m = moment(new Date(totTime));
    // var minutes = (m.hour()*60) + m.minute();
    return to.diff(from, "minutes");
  };

  //Calculation the DISC
  const calculateDisc = (taxes) => {
    if (taxes !== undefined) {
      for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
        var mak = taxes[taxIndex];
        if (
          mak !== undefined &&
          mak.dealmkp !== undefined &&
          mak.dealmkp !== 0
        ) {
          return mak.dealmkp;
        } else {
          return 0.0;
        }
      }
    } else {
      return 0.0;
    }
  };
  useEffect(() => {
    if(props.topFilter){
      filterApply("airline",props.topFilter)
    }
  },[props.topFilter])

  const filterApply = async (name, e) => {
    sessionStorage.setItem("MODIFY_SEARCH","no")
    props.loaderflight(true);
    let cloneRes;
    if (state.response.tt == 3) {
      let res = JSON.parse(localStorage.getItem("MULTI"));
      cloneRes = props.initResp && Object.assign({}, props.initResp);
    } else {
      let res = JSON.parse(localStorage.getItem("RESPONSE"));
      cloneRes = props.initResp && Object.assign({}, props.initResp);
    }
    if (name === "airline") {
      if (e && e.target && e.target.checked) {
        state.airlinesList.push(e.target.value);
        // if(!state.topAirLinesList.includes(e.target.value)){
        //   state.topAirLinesList.push(e.target.value)
        // }
      } else if(e && e.target && !e.target.checked){
        for (var i = 0; i < state.airlinesList.length; i++) {
          if (state.airlinesList[i] === e.target.value) {
            state.airlinesList.splice(i, 1);
            i--;
          }
        }
        // for (var i = 0; i < state.topAirLinesList.length; i++) {
        //   if (state.topAirLinesList[i] === e.target.value) {
        //     state.topAirLinesList.splice(i, 1);
        //     i--;
        //   }
        // }

      }else{ 
        state.airlinesList.length = 0;
        airlinesFiltersClear()
        state.airlinesList.push(e);
        if(!state.topAirLinesList.includes(e)){
          state.topAirLinesList.push(e)
        }
        if(document.getElementById(e)){
          document.getElementById(e).checked=! document.getElementById(e).checked

        } 
      }
    }
    if (name === "nearByAirport") {
      if (e.target.checked) {
        state.airports.push(e.target.value);
      } else {
        for (var i = 0; i < state.airports.length; i++) {
          if (state.airports[i] === e.target.value) {
            state.airports.splice(i, 1);
            i--;
          }
        }
      }
    }
    if (name === "nearByAirportArr") {
      if (e.target.checked) {
        state.airportsArr.push(e.target.value);
      } else {
        for (var i = 0; i < state.airportsArr.length; i++) {
          if (state.airportsArr[i] === e.target.value) {
            state.airportsArr.splice(i, 1);
            i--;
          }
        }
      }
    }

    //check for the refundable
    if (name === "refundable") {
      if (e.target.checked) {
        state.refundable = true;
      } else {
        state.refundable = false;
      }
    }
    //price range
    if (name == "price") {
      dispatch({ type: "price", payload: e });
      state.priceFilterChanged = e;
    }

    if (name == "stops") {
      if (e.target.checked) {
        state.stopsList.push(parseInt(e.target.value));
      } else {
        for (var i = 0; i < state.stopsList.length; i++) {
          if (state.stopsList[i] === parseInt(e.target.value)) {
            state.stopsList.splice(i, 1);
            i--;
          }
        }
      }
    }
    if (name == "layover") {
      state.layoversList = parseInt(e.target.value);
    }

    //if onward Departure is changed
    if (name == "onwardDep") {
      state.onwardDepTime = e.value;
    }

    // if onward Arrival is changed
    if (name == "onwardArr") {
      state.onwardArrivalTime = e.value;
    }

    // if return Departure is changed
    if (name == "returnDep") {
      state.returnDeptTime = e.value;
    }

    // if return Arrival is changed
    if (name == "returnArr") {
      state.returnArrivalTime = e.value;
    }

    //suppliers Filter
    if (name == "supp") {
      if (e.target.checked) {
        state.supplirsList.push(e.target.value);
      } else {
        const index = state.supplirsList.indexOf(e.target.value);
        state.supplirsList.splice(index, 1);
      }
    }

    if (state.airlinesList.length > 0 ||state.topAirLinesList.length>0) {
      // let sourceList = [];
      // let destinationList = [];
      // let originsList = [];
      let newList =
        cloneRes.origns &&
        cloneRes.origns.filter((flt) => {
          let list = (flt.airlinesList || flt.airlineList).split("_");
          let filteredArray = list.filter((value) =>
            state.airlinesList.length>0?state.airlinesList.includes(value):state.topAirLinesList.includes(value)
          );
          
          return state.airlinesList.length>0?state.airlinesList.includes(filteredArray.join("")):state.topAirLinesList.includes(filteredArray.join(""));
        });
      cloneRes.origns = [];
      cloneRes.origns = newList;
      // if (state.airlinesList.length > 0) {
      //     cloneRes.origns.forEach(flights => {
      //         sourceList = flights.srcorigns.filter(flt => { return (flt.seg && state.airlinesList.includes(flt.seg[0].ma)) });
      //         if (state.response.tt == 2) {
      //             destinationList = flights.destorigins.filter(flt => { return (flt.seg && state.airlinesList.includes(flt.seg[0].ma)) })
      //             if (sourceList.length > 0 && destinationList.length > 0) {
      //                 flights.srcorigns = [];
      //                 flights.srcorigns = sourceList;
      //                 flights.destorigins = [];
      //                 flights.destorigins = destinationList;
      //                 originsList.push(flights);
      //             }
      //         }
      //         else if (state.response.tt == 3) {
      //             const refOne = sourceList.filter((jour) => jour.ref === 1);
      //             const refTwo = sourceList.filter((jour) => jour.ref === 2);
      //             const refThree = sourceList.filter((jour) => jour.ref === 3);
      //             const refFour = sourceList.filter((jour) => jour.ref === 4);
      //             const refFive = sourceList.filter((jour) => jour.ref === 5);
      //             const refSix = sourceList.filter((jour) => jour.ref === 6);

      //             if (cloneRes.sec.length === 2 && refOne.length > 0 && refTwo.length > 0 && refThree.length === 0 && refFour.length === 0 && refFive.length === 0 && refSix.length === 0) {
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }

      //             if (cloneRes.sec.length === 3 && refOne.length > 0 && refTwo.length > 0 && refThree.length > 0 && refFour.length === 0 && refFive.length === 0 && refSix.length === 0) {
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }

      //             if (cloneRes.sec.length === 4 && refOne.length > 0 && refTwo.length > 0 && refThree.length > 0 && refFour.length > 0 && refFive.length === 0 && refSix.length === 0) {
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }

      //             if (cloneRes.sec.length === 5 && refOne.length > 0 && refTwo.length > 0 && refThree.length > 0  && refFour.length > 0 && refFive.length > 0 && refSix.length === 0) {
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }

      //             if (cloneRes.sec.length === 6 && refOne.length > 0 && refTwo.length > 0 && refThree.length > 0&& refFour.length > 0 && refFive.length > 0 && refSix.length > 0) {
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }
      //         }
      //         else {
      //             if (sourceList.length > 0) {
      //                 flights.srcorigns = [];
      //                 flights.srcorigns = sourceList;
      //                 originsList.push(flights);
      //             }

      //         }

      //     })
      //     cloneRes.origns = [];
      //     cloneRes.origns = originsList;
      // }
    }
    if (state.airports.length > 0) {
      let sourceList = [];
      let destinationList = [];
      let originsList = [];
      if (state.airports.length > 0) {
        cloneRes.origns.forEach((flights) => {
          sourceList = flights.srcorigns.filter((flt) => {
            return (
              flt.seg &&
              (state.airports.includes(flt.seg[0].da) ||
                state.airports.includes(flt.seg[flt.seg.length - 1].ar))
            );
          });
          if (state.response.tt == 2) {
            destinationList = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airports.includes(flt.seg[0].da) ||
                  state.airports.includes(flt.seg[flt.seg.length - 1].ar))
              );
            });
            if (sourceList.length > 0 && destinationList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              flights.destorigins = [];
              flights.destorigins = destinationList;
              originsList.push(flights);
            }
          } else if (state.response.tt == 3) {
            const refOne = sourceList.filter((jour) => jour.ref === 1);
            const refTwo = sourceList.filter((jour) => jour.ref === 2);
            const refThree = sourceList.filter((jour) => jour.ref === 3);
            const refFour = sourceList.filter((jour) => jour.ref === 4);
            const refFive = sourceList.filter((jour) => jour.ref === 5);
            const refSix = sourceList.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          } else {
            if (sourceList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          }
        });
        cloneRes.origns = [];
        cloneRes.origns = originsList;
      }
    }
    if (state.airportsArr.length > 0) {
      let sourceList = [];
      let destinationList = [];
      let originsList = [];
      if (state.airportsArr.length > 0) {
        cloneRes.origns.forEach((flights) => {
          sourceList = flights.srcorigns.filter((flt) => {
            return (
              flt.seg &&
              (state.airportsArr.includes(flt.seg[0].ar) ||
                state.airportsArr.includes(flt.seg[flt.seg.length - 1].ar))
            );
          });
          if (state.response.tt == 2) {
            destinationList = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airports.includes(flt.seg[0].ar) ||
                  state.airports.includes(flt.seg[flt.seg.length - 1].da))
              );
            });
            if (sourceList.length > 0 && destinationList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              flights.destorigins = [];
              flights.destorigins = destinationList;
              originsList.push(flights);
            }
          } else if (state.response.tt == 3) {
            const refOne = sourceList.filter((jour) => jour.ref === 1);
            const refTwo = sourceList.filter((jour) => jour.ref === 2);
            const refThree = sourceList.filter((jour) => jour.ref === 3);
            const refFour = sourceList.filter((jour) => jour.ref === 4);
            const refFive = sourceList.filter((jour) => jour.ref === 5);
            const refSix = sourceList.filter((jour) => jour.ref === 6);

            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          } else {
            if (sourceList.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = sourceList;
              originsList.push(flights);
            }
          }
        });
        cloneRes.origns = [];
        cloneRes.origns = originsList;
      }
    }

    //if clicked on refundable check box
    if (state.refundable) {
      let refundsList = [];

      cloneRes.origns.forEach((flights) => {
        const refundsSRC = flights.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (state.response.tt == 2 && refundsSRC.length > 0) {
          const refundsARR = flights.destorigins.filter((flt) => {
            return flt.re === "Refundable Before Departure";
          });
          if (refundsSRC.length > 0 && refundsARR.length > 0) {
            // flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            // flights.destorigins = [];
            flights.destorigins = refundsARR;
            refundsList.push(flights);
          }
        }
        if (state.response.tt == 3) {
          const refOne = refundsSRC.filter((jour) => jour.ref === 1);
          const refTwo = refundsSRC.filter((jour) => jour.ref === 2);
          const refThree = refundsSRC.filter((jour) => jour.ref === 3);
          const refFour = refundsSRC.filter((jour) => jour.ref === 4);
          const refFive = refundsSRC.filter((jour) => jour.ref === 5);
          const refSix = refundsSRC.filter((jour) => jour.ref === 6);

          if (
            cloneRes.sec.length === 2 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length === 0 &&
            refFour.length === 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 3 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length === 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
          if (
            cloneRes.sec.length === 4 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length === 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 5 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length > 0 &&
            refSix.length === 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }

          if (
            cloneRes.sec.length === 6 &&
            refOne.length > 0 &&
            refTwo.length > 0 &&
            refThree.length > 0 &&
            refFour.length > 0 &&
            refFive.length > 0 &&
            refSix.length > 0
          ) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        } else {
          if (refundsSRC.length > 0) {
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        }
      });
      //if refundable is available then assign the filtered response to cloneRes
      if (refundsList.length > 0) {
        cloneRes.origns = refundsList;
      } else {
        cloneRes.origns = refundsList;
        cloneRes.pr = {};
      }
      // let unique_city = [new Set(cloneRes.origns)];
      let unique_city = [];
      cloneRes.origns.forEach((c) => {
        if (!unique_city.includes(c)) {
          unique_city.push(c);
        }
      });
      cloneRes.origns = unique_city;
    }

    // if layovers are selected in filters destorigins
    if (state.layoversList && state.layoversList !== "") {
      let res = [];
      let result = [];
      if (cloneRes && cloneRes.origns.length>0 && cloneRes.origns[0].destorigins) {
        if (state.layoversList === 2000) {
          cloneRes.origns.forEach((flights) => {
            let srcFiltered = flights.srcorigns.filter((flt) => {
              return (
                "1440" <
                layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
              );
            });
            if (srcFiltered) {
              let desFiltered =
                flights.destorigins &&
                flights.destorigins.filter((flt) => {
                  return (
                    "1440" <
                    layOverTimeCal(
                      flt.seg[0].ad,
                      flt.seg[flt.seg.length - 1].dd
                    )
                  );
                });
              if (
                srcFiltered.length > 0 &&
                desFiltered &&
                desFiltered.length > 0
              ) {
                flights.srcorigns = srcFiltered;
                flights.destorigins = desFiltered;
                res.push(flights);
              }
            }
          });
        } else {
          cloneRes.origns.forEach((flights) => {
            let srcFiltered = flights.srcorigns.filter((flt) => {
              return (
                state.layoversList >
                layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
              );
            });
            if (srcFiltered.length > 0) {
              let desFiltered =
                flights.destorigins &&
                flights.destorigins.filter((flt) => {
                  return (
                    state.layoversList >
                    layOverTimeCal(
                      flt.seg[0].ad,
                      flt.seg[flt.seg.length - 1].dd
                    )
                  );
                });
              if (srcFiltered.length > 0) {
                if (
                  srcFiltered.length > 0 &&
                  desFiltered &&
                  desFiltered.length > 0
                ) {
                  flights.srcorigns = srcFiltered;
                  flights.destorigins = desFiltered;
                  res.push(flights);
                }
              }
            }
          });
        }
      } else {
        if (state.layoversList === 2000) {
          cloneRes.origns.forEach((flights) => {
            let srcFiltered = flights.srcorigns.filter((flt) => {
              return (
                "1440" <
                layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
              );
            });
            if (srcFiltered) {
              let desFiltered =
                flights.destorigins &&
                flights.destorigins.filter((flt) => {
                  return (
                    "1440" <
                    layOverTimeCal(
                      flt.seg[0].ad,
                      flt.seg[flt.seg.length - 1].dd
                    )
                  );
                });
              if (srcFiltered.length > 0) {
                if (
                  srcFiltered.length > 0 &&
                  desFiltered &&
                  desFiltered.length > 0
                ) {
                  flights.srcorigns = srcFiltered;
                  flights.destorigins = desFiltered;
                  res.push(flights);
                } else {
                  flights.srcorigns = srcFiltered;
                  res.push(flights);
                }
              }
            }
          });
        } else {
          cloneRes.origns.forEach((flights) => {
            let srcFiltered = flights.srcorigns.filter((flt) => {
              return (
                state.layoversList >
                layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
              );
            });
            if (srcFiltered.length > 0) {
              let desFiltered =
                flights.destorigins &&
                flights.destorigins.filter((flt) => {
                  return (
                    state.layoversList >
                    layOverTimeCal(
                      flt.seg[0].ad,
                      flt.seg[flt.seg.length - 1].dd
                    )
                  );
                });
              if (srcFiltered.length > 0) {
                if (
                  srcFiltered.length > 0 &&
                  desFiltered &&
                  desFiltered.length > 0
                ) {
                  flights.srcorigns = srcFiltered;
                  flights.destorigins = desFiltered;
                  res.push(flights);
                } else {
                  flights.srcorigns = srcFiltered;
                  res.push(flights);
                }
              }
            }
          });
        }
      }
      cloneRes.origns = res;
    }
    if (state.stopsList.length > 0) {
      let res = [];
      cloneRes.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((src) => {
          return state.stopsList.includes(parseInt(src.stop));
        });
        if (srcFiltered.length > 0) {
          if (state.response.tt == 2) {
            let desFiltered = flights.destorigins.filter((des) => {
              return state.stopsList.includes(parseInt(des.stop));
            });

            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = srcFiltered;
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (state.response.tt == 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);
            const refFour = srcFiltered.filter((jour) => jour.ref === 4);
            const refFive = srcFiltered.filter((jour) => jour.ref === 5);
            const refSix = srcFiltered.filter((jour) => jour.ref === 6);
            if (
              cloneRes.sec.length === 2 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length === 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              // flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }

            if (
              cloneRes.sec.length === 3 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length === 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
            if (
              cloneRes.sec.length === 4 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length === 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }

            if (
              cloneRes.sec.length === 5 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length === 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }

            if (
              cloneRes.sec.length === 6 &&
              refOne.length > 0 &&
              refTwo.length > 0 &&
              refThree.length > 0 &&
              refFour.length > 0 &&
              refFive.length > 0 &&
              refSix.length > 0
            ) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
      cloneRes.origns = res;
    }
    //onward departurn filter
    if (state.onwardDep) {
      if (state.onwardDepTime) {
        let respd = [];
        cloneRes.origns.forEach((flights) => {
          let remainsRef = flights.srcorigns.filter((flt) => {
            let depDate = DateUtils.convertToHours(flt.seg[0].dd);
            return (
              depDate >= state.onwardDepTime.min &&
              depDate <= state.onwardDepTime.max
            );
          });

          /*  const lists = remainsRef.filter(refs => (refs.ref === 1)); */
          if (remainsRef.length > 0) {
            if (state.response.tt == 3) {
              const refOne = remainsRef.filter((jour) => jour.ref === 1);
              const refTwo = remainsRef.filter((jour) => jour.ref === 2);
              const refThree = remainsRef.filter((jour) => jour.ref === 3);
              const refFour = remainsRef.filter((jour) => jour.ref === 4);
              const refFive = remainsRef.filter((jour) => jour.ref === 5);
              const refSix = remainsRef.filter((jour) => jour.ref === 6);

              if (
                cloneRes.sec.length === 2 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length === 0 &&
                refFour.length === 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                respd.push(flights);
              }

              if (
                cloneRes.sec.length === 3 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length === 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                respd.push(flights);
              }
              if (
                cloneRes.sec.length === 4 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                respd.push(flights);
              }

              if (
                cloneRes.sec.length === 5 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length > 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                respd.push(flights);
              }

              if (
                cloneRes.sec.length === 6 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length > 0 &&
                refSix.length > 0
              ) {
                flights.srcorigns = remainsRef;
                respd.push(flights);
              }
            } else {
              flights.srcorigns = [];
              flights.srcorigns = remainsRef;
              respd.push(flights);
            }
          }
        });
        if(respd.length > 0) {
          cloneRes.origns = respd;
        }
      }
    }
    //onward arrival
    if (state.onwardArrrival) {
      if (state.onwardArrivalTime) {
        let resoa = [];
        cloneRes.origns.forEach((flights) => {
          let remainsRef = flights.srcorigns.filter((flt) => {
            let arrDate = DateUtils.convertToHours(
              flt.seg[flt.seg.length - 1].ad
            );
            return (
              arrDate >= state.onwardArrivalTime.min &&
              arrDate <= state.onwardArrivalTime.max
            );
          });
          if (remainsRef.length > 0) {
            if (state.response.tt == 3) {
              const refOne = remainsRef.filter((jour) => jour.ref === 1);
              const refTwo = remainsRef.filter((jour) => jour.ref === 2);
              const refThree = remainsRef.filter((jour) => jour.ref === 3);
              const refFour = remainsRef.filter((jour) => jour.ref === 4);
              const refFive = remainsRef.filter((jour) => jour.ref === 5);
              const refSix = remainsRef.filter((jour) => jour.ref === 6);

              if (
                cloneRes.sec.length === 2 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length === 0 &&
                refFour.length === 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                resoa.push(flights);
              }
              if (
                cloneRes.sec.length === 3 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length === 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                resoa.push(flights);
              }
              if (
                cloneRes.sec.length === 4 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length === 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                resoa.push(flights);
              }

              if (
                cloneRes.sec.length === 5 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length > 0 &&
                refSix.length === 0
              ) {
                flights.srcorigns = remainsRef;
                resoa.push(flights);
              }

              if (
                cloneRes.sec.length === 6 &&
                refOne.length > 0 &&
                refTwo.length > 0 &&
                refThree.length > 0 &&
                refFour.length > 0 &&
                refFive.length > 0 &&
                refSix.length > 0
              ) {
                flights.srcorigns = remainsRef;
                resoa.push(flights);
              }
            } else {
              flights.srcorigns = [];
              flights.srcorigns = remainsRef;
              resoa.push(flights);
            }
          }
        });
        if(resoa.length > 0) {
          cloneRes.origns = resoa;
        }
      }
    }
    //return departure
    if (state.returnDep && state.response.tt === "2") {
      if (state.returnDeptTime) {
        let resdt = [];
        cloneRes.origns.forEach((flights) => {
          let remainsRef = flights.destorigins.filter((flt) => {
            let depDate = DateUtils.convertToHours(flt.seg[0].dd);
            return (
              depDate >= state.returnDeptTime.min &&
              depDate <= state.returnDeptTime.max
            );
          });
          if (remainsRef.length > 0) {
            flights.destorigins = [];
            flights.destorigins = remainsRef;
            resdt.push(flights);
          }
        });
        cloneRes.origns = resdt;
      }
    }
    //return arrival
    if (state.returnArrrival && state.response.tt === "2") {
      if (state.returnArrivalTime) {
        let res = [];
        cloneRes.origns.forEach((flights) => {
          let remainsRef = flights.destorigins.filter((flt) => {
            let arrDate = DateUtils.convertToHours(
              flt.seg[flt.seg.length - 1].ad
            );
            return (
              arrDate >= state.returnArrivalTime.min &&
              arrDate <= state.returnArrivalTime.max
            );
          });
          if (remainsRef.length > 0) {
            flights.destorigins = [];
            flights.destorigins = remainsRef;
            res.push(flights);
          }
        });
        cloneRes.origns = res;
      }
    }
    //once price filter departed or changed filter across the flow
    if (
      (state.priceFilterChanged &&
        state.priceFilterChanged.min &&
        state.priceFilterChanged.max) ||
      (state.price.min && state.price.max)
    ) {
      let priceRange = {};
      if (state.priceFilterChanged.min && state.priceFilterChanged.max) {
        priceRange = state.priceFilterChanged;
      } else {
        priceRange = state.price;
      }
      let res = cloneRes.origns.filter((flights) => {
        let price = flights.pr.tf;
        if (flights.pr.tax) {
          price =
            Number(flights.pr.tf) +
            Number(calcuateServiceFee(flights.servicefee)) +
            Number(calculateDisc(flights.pr.tax));
        } else if (props.srequest.flowType !== "REISSUE") {
          price = Utilities.addPrice(
            flights.pr.tf + calcuateServiceFee(flights.servicefee),
            calculateDisc(flights.pr.tax),
            "Sub"
          );
        } else if (props.srequest.flowType == "REISSUE") {
          price = Utilities.addPrice(
            flights.pr.gtd,
            calcuateServiceFee(flights.servicefee),
            "Add"
          );
        }
        return (
          Number(price) >= Number(priceRange.min) &&
          Number(price) <= Number(priceRange.max)
        );
      });
      cloneRes.origns = res;
    }
    //Supplier Filter
    let sourceList = [];
    let destinationList = [];
    let originsList = [];
    if (state.supplirsList.length > 0) {
      cloneRes.origns.forEach((flights) => {
        sourceList = flights.srcorigns.filter((flt) => {});

        sourceList = flights.srcorigns.filter((flt) => {
          if (
            state.supplirsList.includes("LCC") &&
            state.supplirsList.includes("Full Service")
          ) {
            return true;
          } else if (state.supplirsList.includes("LCC")) {
            return flt.sup !== "0";
          } else if (state.supplirsList.includes("Full Service")) {
            return flt.sup === "0";
          }
        });
        if (state.response.tt === "2" && flights.destorigins !== undefined) {
          destinationList = flights.destorigins.filter((flt) => {
            if (
              state.supplirsList.includes("LCC") &&
              state.supplirsList.includes("Full Service")
            ) {
              return flt.sup == 0 || flt.sup != 0;
            } else if (state.supplirsList.includes("LCC")) {
              return flt.sup != 0;
            } else if (state.supplirsList.includes("Full Service")) {
              return flt.sup == 0;
            }
          });
          if (sourceList.length > 0 && destinationList.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = sourceList;
            flights.destorigins = [];
            flights.destorigins = destinationList;
            originsList.push(flights);
          }
        } else {
          if (sourceList.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = sourceList;
            originsList.push(flights);
          }
        }
      });
      cloneRes.origns = [];
      cloneRes.origns = originsList;
    }
    props.handelChange(cloneRes, "filter");
    // if(name ==="clearLayover"){
    //     cloneRes = Object.assign({}, state.temp);
    //     let a = props.initResp.origns
    //     cloneRes.origns.push = a
    //     props.handelChange(cloneRes, 'filters')
    //     }
  };

  // Onward Departure Time Filter
  const onChangeOnwardDepTime = (data) => {
    dispatch({ type: "onwardDepTime", payload: data.value });
    let res = [];
    state.finalResponse.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let depDate = DateUtils.convertToHours(flt.seg[0].dd);
        return depDate >= data.value.min && depDate <= data.value.max;
      });

      /*  const lists = remainsRef.filter(refs => (refs.ref === 1)); */
      if (remainsRef.length > 0) {
        if (state.response.tt == 3) {
          const refOne = remainsRef.filter((jour) => jour.ref === 1);
          const refTwo = remainsRef.filter((jour) => jour.ref === 2);
          const refThree = remainsRef.filter((jour) => jour.ref === 3);

          if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
            flights.srcorigns = remainsRef;
            res.push(flights);
          }
        } else {
          flights.srcorigns = [];
          flights.srcorigns = remainsRef;
          res.push(flights);
        }
      }
    });
    state.response.origns = res;
    props.handelChange(state.response, "");
  };

  const onChangeOnwardArrTime = (data) => {
    dispatch({ type: "onwardArrivalTime", payload: data.value });
    let res = [];
    state.finalResponse.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let arrDate = DateUtils.convertToHours(flt.seg[flt.seg.length - 1].ad);
        return arrDate >= data.value.min && arrDate <= data.value.max;
      });

      if (remainsRef.length > 0) {
        if (state.response.tt === 3) {
          const refOne = remainsRef.filter((jour) => jour.ref === 1);
          const refTwo = remainsRef.filter((jour) => jour.ref === 2);
          const refThree = remainsRef.filter((jour) => jour.ref === 3);

          if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
            flights.srcorigns = remainsRef;
            res.push(flights);
          }
        } else {
          flights.srcorigns = [];
          flights.srcorigns = remainsRef;
          res.push(flights);
        }
      }
    });
    state.response.origns = res;
    props.handelChange(state.response, "");
  };

  const onChangeReturnDepTime = (data) => {
    dispatch({ type: "returnDeptTime", payload: data.value });
    let res = [];
    state.finalResponse.origns.forEach((flights) => {
      let remainsRef = flights.destorigins.filter((flt) => {
        let depDate = DateUtils.convertToHours(flt.seg[0].dd);
        return depDate >= data.value.min && depDate <= data.value.max;
      });
      if (remainsRef.length > 0) {
        flights.destorigins = [];
        flights.destorigins = remainsRef;
        res.push(flights);
      }
    });
    state.response.origns = res;
    props.handelChange(state.response, "");
  };

  const onChangeReturnArrTime = (data) => {
    dispatch({ type: "returnArrivalTime", payload: data.value });
    let res = [];
    state.finalResponse.origns.forEach((flights) => {
      let remainsRef = flights.destorigins.filter((flt) => {
        let arrDate = DateUtils.convertToHours(flt.seg[flt.seg.length - 1].ad);
        return arrDate >= data.value.min && arrDate <= data.value.max;
      });
      if (remainsRef.length > 0) {
        flights.destorigins = [];
        flights.destorigins = remainsRef;
        res.push(flights);
      }
    });
    state.response.origns = res;
    props.handelChange(state.response, "");
  };

  const refundable = (e) => {
    let cloneRes = Object.assign({}, state.finalResponse);
    if (e.target.checked) {
      let refundsList = [];

      state.finalResponse.origns.forEach((flights) => {
        const refundsSRC = flights.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (state.finalResponse.tt === 2 && refundsSRC.length > 0) {
          const refundsARR = flights.destorigins.filter((flt) => {
            return flt.re === "Refundable Before Departure";
          });
          if (refundsSRC.length > 0 && refundsARR.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            flights.destorigins = [];
            flights.destorigins = refundsARR;
            refundsList.push(flights);
          }
        }
        if (state.response.tt === 3) {
          const refOne = refundsSRC.filter((jour) => jour.ref === 1);
          const refTwo = refundsSRC.filter((jour) => jour.ref === 2);
          const refThree = refundsSRC.filter((jour) => jour.ref === 3);

          if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        } else {
          if (refundsSRC.length > 0) {
            flights.srcorigns = refundsSRC;
            refundsList.push(flights);
          }
        }
      });
      if (refundsList.length > 0) state.response.origns = refundsList;
      else {
        state.response.origns = refundsList;
        state.response.pr = {};
      }
    } else {
      state.response.origns = state.finalResponse.origns;
      state.response.pr = state.finalResponse.pr;
    }

    props.handelChange(state.response, "");
    state.finalResponse = {};
    state.finalResponse = cloneRes;
  };

  const nonRefundable = () => {
    let refundsList = [];
    state.finalResponse.origns.forEach((flights) => {
      const refundsSRC = flights.srcorigns.filter((flt) => {
        return flt.re !== "Refundable Before Departure";
      });
      if (state.finalResponse.tt === 2 && refundsSRC.length > 0) {
        const refundsARR = flights.destorigins.filter((flt) => {
          return flt.re !== "Refundable Before Departure";
        });
        if (refundsSRC.length > 0 && refundsARR.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = refundsSRC;
          flights.destorigins = [];
          flights.destorigins = refundsARR;
          refundsList.push(flights);
        }
      } else {
        flights.srcorigns = [];
        flights.srcorigns = refundsSRC;
        refundsList.push(flights);
      }
    });

    state.response.origns = refundsList;
    props.handelChange(state.response, "");
  };
  //=====================================
  // This function will check the Class type
  //=====================================

  const flightByFlight = (e) => {
    if (props.srequest !== undefined) {
      history.push({
        pathname: "/ezytrip/bookings/flight/flightbyflight",
        state: {
          req: props.srequest,
        },
      });
    }
  };

  const allFiltersClear = () => {
    sessionStorage.setItem("ISRESET",true)
    props.allClear()
    props.loaderflight(true);
    dispatch({
      type: "price",
      payload: {
        min: state.finalResponse.filter.minprc,
        max: state.finalResponse.filter.maxprc,
      },
    });
    state.price = {min: state.finalResponse.filter.minprc,max: state.finalResponse.filter.maxprc}
    dispatch({ type: "priceFilterChanged", payload: "" });
    state.priceFilterChanged = ""
    dispatch({ type: "onwardDepTime", payload: { min: 0, max: 24 } });
    state.onwardDepTime = { min: 0, max: 24 } 
    dispatch({ type: "onwardArrivalTime", payload: { min: 0, max: 24 } });
    state.onwardArrivalTime = { min: 0, max: 24 } 
    dispatch({ type: "returnDeptTime", payload: { min: 0, max: 24 } });
    state.returnDeptTime = { min: 0, max: 24 } 
    dispatch({ type: "returnArrivalTime", payload: { min: 0, max: 24 } });
    state.returnArrivalTime = { min: 0, max: 24 } 
    dispatch({ type: "stopsList", payload: [] });
    state.stopsList = []
    // state.stopsList=[]
    dispatch({ type: "layoversList", payload: "" });
    state.layoversList = "";
    dispatch({ type: "airlines", payload: [] });
    state.airlines=[]
    dispatch({ type: "airports", payload: [] });
    state.airports=[]
    dispatch({ type: "airportsArr", payload: [] });
    state.airportsArr=[]
    dispatch({ type: "airlinesList", payload: [] });
    state.airlinesList=[]
    dispatch({ type: "topAirLinesList", payload: [] });
    state.topAirLinesList=[]
    dispatch({ type: "refundable", payload: false });
    state.refundable=false
    dispatch({ type: "supplirsList", payload: [] });
    state.supplirsList=[]
    //Stops clear
    var stopChecks = document.getElementsByName("stops");
    for (var i = 0, n = stopChecks.length; i < n; i++) {
      stopChecks[i].checked = false;
    }
    // Layovers clear
    var layoverChecks = document.getElementsByName("layover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });
    //refunds clear
    var refundChecks = document.getElementsByName("refund");
    for (var i = 0, n = refundChecks.length; i < n; i++) {
      refundChecks[i].checked = false;
    }
    //airlines clear
    var airlineChecks = document.getElementsByName("airlines");
    for (var i = 0, n = airlineChecks.length; i < n; i++) {
      airlineChecks[i].checked = false;
    }
    //near by clears
    var nearbyChecks = document.getElementsByName("nearby");
    for (var i = 0, n = nearbyChecks.length; i < n; i++) {
      nearbyChecks[i].checked = false;
    }
    props.handelChange(state.finalResponse, "clear");

    var checkboxes = document.getElementsByName("nearbyarr");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }

    //Supplier clear
    var supplierChecks = document.getElementsByName("suppliers");
    for (var i = 0, n = supplierChecks.length; i < n; i++) {
      supplierChecks[i].checked = false;
    }
    filterApply("", "");
  };
  const priceFilterClear = () => {
    state.price = {
      min: state.finalResponse.filter.minprc,
      max: state.finalResponse.filter.maxprc,
    };
    state.priceFilterChanged = "";
    filterApply("", "");
  };
  const stopsFiltersClear = () => {
    state.stopsList = [];
    //Stops clear
    var stopChecks = document.getElementsByName("stops");
    for (var i = 0, n = stopChecks.length; i < n; i++) {
      stopChecks[i].checked = false;
    }
    filterApply("", "");
    filterApply("clearLayover", "");
  };
  const layoversFiltersClear = () => {
    dispatch({ type: "layoversList", payload: "" });
    state.layoversList = "";

    //Stops clear
    var layoverChecks = document.getElementsByName("layover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });

    filterApply("", "");
    filterApply("clearLayover", "");
  };

  const owTimingsFiltersClear = () => {
    state.onwardDepTime = { min: 0, max: 24 };
    state.onwardArrivalTime = { min: 0, max: 24 };
    filterApply("", "");
  };
  const rtTimingsFiltersClear = () => {
    state.returnDeptTime = { min: 0, max: 24 };
    state.returnArrivalTime = { min: 0, max: 24 };
    filterApply("", "");
  };
  const refundFiltersClear = () => {
    state.refundable = false;
    var checkboxes = document.getElementsByName("refund");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");
  };
  const airlinesFiltersClear = () => {
    // for (let i = 0; i < state.airlinesList.length; i++) {
    //   for(let j=0;j<state.topAirLinesList.length;j++) {
    //     if (state.airlinesList[i] === state.topAirLinesList[j]) {
    //       state.topAirLinesList.splice(i, 1);
    //       i--;
    //     }
    //   }
    // }
    state.airlines = [];
    state.airlinesList = [];
    var checkboxes = document.getElementsByName("airlines");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");
  };
  const nearbyFiltersClear = () => {
    state.airports = [];
    var checkboxes = document.getElementsByName("nearby");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");
  };
  const nearbyArrFiltersClear = () => {
    state.airportsArr = [];
    var checkboxes = document.getElementsByName("nearbyarr");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");
  };

  const supplierFiltersClear = () => {
    state.supplirsList = [];
    var checkboxes = document.getElementsByName("suppliers");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");
  };

  /*  const nonRefundable = () => {
         let refundsList = []
         state.finalResponse.origns.forEach(origin => {
             const refunds = origin.orign.filter(jur => { return ((jur.re && jur.re === 'no') || jur.pr != undefined) })
             if (state.finalResponse.tt === 2 && refunds.length > 0) {
                 const onwards = refunds.filter(jur => (jur.ref && jur.ref === 1))
                 const arrivals = refunds.filter(jur => (jur.ref && jur.ref === 2))
                 if (onwards.length > 0 && arrivals.length > 0) {
                     origin.orign = [];
                     origin.orign = refunds;
                     refundsList.push(origin)
                 }
             }
             else {
                 origin.orign = [];
                 origin.orign = refunds;
                 refundsList.push(origin);
             }

         })

         state.response.origns = refundsList;
         props.handelChange(state.response,'')
     } */

  //assigning of bar change value to respective data
  const dummyCall = (name, e) => {
    if (name == "onwardDep") {
      dispatch({ type: "onwardDepTime", payload: e.value });
    }

    // if onward Arrival is changed
    if (name == "onwardArr") {
      dispatch({ type: "onwardArrivalTime", payload: e.value });
    }

    // if return Departure is changed
    if (name == "returnDep") {
      dispatch({ type: "returnDeptTime", payload: e.value });
    }

    // if return Arrival is changed
    if (name == "returnArr") {
      dispatch({ type: "returnArrivalTime", payload: e.value });
    }
  };
  return (
    <Sticky>
      <div className="fltSec">
        {state.searchResponse !== undefined && state.searchResponse !== "" && (
          <FBFResultsPage fbfRespons={state.searchResponse} />
        )}
        {Number(props.triptype) === 2 && (
          <Button
            size="lg"
            block
            variant="primary"
            className="fbyf flexiLigtoption"
            onClick={flightByFlight}
          >
            <span className="ezyIcon icon-ticket mr-3"></span> Flexi Flight Option
          </Button>
        )}

        <div className="filter">
          <div className="title d-flex justify-content-between">
            <h5>Result Filter</h5>
            <Link
              onClick={allFiltersClear}
            >Clear All</Link>
          </div>
          <div className="filterSec price">
            <div className="filterLabel d-flex justify-content-between">
              <p>Price</p>
              <Link onClick={priceFilterClear}>Clear</Link>
            </div>
            <div className="ranges">
              <InputRange
                formatLabel={(value) => `${state.response.cur} ${value}`}
                allowSameValues={true}
                maxValue={state.response.filter && state.response.filter.maxprc}
                minValue={state.response.filter && state.response.filter.minprc}
                value={state.price}
                onChangeComplete={(value) => filterApply("price", value)}
                onChange={(value) => filterApplyPrice("price", value)}
              />
            </div>
          </div>
          <div className="filterSec stops">
            <div className="filterLabel d-flex justify-content-between">
              <p>Stops</p>
              <Link onClick={stopsFiltersClear}>Clear</Link>
            </div>
            <div className="stops">
              <ul style={{"flexDirection":"column"}}>
                {state.response.filter &&
                  state.response.filter.stps !== undefined &&
                  state.response.filter.stps.map((stp, index) => (
                    <>
                      {index <= 2 && (
                        <li className="d-flex justify-content-between">
                        <Form.Check
                          name="stops"
                          type="checkbox"
                          id={
                            stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                          }
                          label={
                              stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                            }
                          title={
                            stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                          }
                          value={stp.count}
                        onChange={(e) => filterApply("stops", e)}
                          custom
                        />
                        <div class="priceLabel">
                             <span>{state.response.cur + " "}</span>
                             {<FormateCurrency value={stp.prc} />}
                           </div>
                      </li>
                        // <li>
                        //   <label class="container">{
                        //       stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                        //     }
                        //     <input type="checkbox" checked="checked"/>
                        //     <span class="checkmark"></span>
                        //   </label>
                        //   {/* <Form.Check
                        //     name="stops"
                        //     type="checkbox"
                        //     id={
                        //       stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                        //     }
                        //     // onChange={onStopsChange}
                        //     onChange={(e) => filterApply("stops", e)}
                        //     // className="stopsBlock"
                        //     value={stp.count}
                        //     label={
                        //       stp.count === 0 ? "Non Stop" : stp.count + " Stop"
                        //     }
                        //   /> */}
                        //   <div class="priceLabel">
                        //     <span>{state.response.cur + " "}</span>
                        //     {<FormateCurrency value={stp.prc} />}
                        //   </div>
                        // </li>
                      )}
                    </>
                  ))}
              </ul>
            </div>
          </div>
          <div className="filterSec layOver">
            <div className="filterLabel d-flex justify-content-between">
              <p>Layover</p>
              <Link onClick={layoversFiltersClear}>Clear</Link>
            </div>
            <div>
              <ul className="m-0 p-0">
                {/* 6hours/12hours/24 hours and more then 24 hours */}

                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="layover"
                    type="radio"
                    id="6hours"
                    label={<span>6 hours</span>}
                    title="6hours"
                    value="360"
                    custom
                    onChange={(e) => filterApply("layover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="layover"
                    type="radio"
                    id="12hours"
                    label={<span>12 hours</span>}
                    title="12hours"
                    value="720"
                    custom
                    onChange={(e) => filterApply("layover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="layover"
                    type="radio"
                    id="24hours"
                    label={<span>24 hours</span>}
                    title="24hours"
                    value="1440"
                    custom
                    onChange={(e) => filterApply("layover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="layover"
                    type="radio"
                    id="moreThan24"
                    label={<span>More than 24 hours</span>}
                    title="moreThan24"
                    value="2000"
                    custom
                    onChange={(e) => filterApply("layover", e)}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="filterSec times">
            <div className="filterLabel d-flex justify-content-between">
              <p>Onward Timings</p>
              <Link onClick={owTimingsFiltersClear}>Clear</Link>
            </div>
            <ul>
              <li className={state.onwardDep ? "active" : ""}>
                <Link onClick={openOnwardDEP}>Departure</Link>
              </li>
              <li className={state.onwardArrrival ? "active" : ""}>
                <Link onClick={openOnwardARI}>Arrival</Link>
              </li>
            </ul>

            {state.onwardDep && (
              <div className="ranges">
                <InputRange
                  formatLabel={(value) => ` ${value} hrs`}
                  maxValue={24}
                  minValue={0}
                  value={state.onwardDepTime}
                  // onChange={value => onChangeOnwardDepTime({ value })}
                  onChange={(value) => dummyCall("onwardDep", { value })}
                  onChangeComplete={(value) =>
                    filterApply("onwardDep", { value })
                  }
                />
              </div>
            )}
            {state.onwardArrrival && (
              <div className="ranges">
                <InputRange
                  formatLabel={(value) => ` ${value} hrs`}
                  maxValue={24}
                  minValue={0}
                  value={state.onwardArrivalTime}
                  onChange={(value) => dummyCall("onwardArr", { value })}
                  // onChange={value => onChangeOnwardArrTime({ value })}
                  onChangeComplete={(value) =>
                    filterApply("onwardArr", { value })
                  }
                />
              </div>
            )}
          </div>
          {state.response.tt === "2" && (
            <div className="filterSec times return">
              <div className="filterLabel d-flex justify-content-between">
                <p>Return Timings</p>
                <Link onClick={rtTimingsFiltersClear}>Clear</Link>
              </div>
              <ul>
                <li className={state.returnDep ? "active" : ""}>
                  <Link onClick={openReturndDEP}>Departure</Link>
                </li>
                <li className={state.returnArrrival ? "active" : ""}>
                  <Link onClick={openReturnARI}>Arrival</Link>
                </li>
              </ul>

              {state.returnDep && (
                <div className="ranges">
                  <InputRange
                    formatLabel={(value) => ` ${value} hrs`}
                    maxValue={24}
                    minValue={0}
                    value={state.returnDeptTime}
                    onChange={(value) => dummyCall("returnDep", { value })}
                    // onChange={value => onChangeReturnDepTime({ value })}
                    onChangeComplete={(value) =>
                      filterApply("returnDep", { value })
                    }
                  />
                </div>
              )}
              {state.returnArrrival && (
                <div className="ranges">
                  <InputRange
                    formatLabel={(value) => ` ${value} hrs`}
                    maxValue={24}
                    minValue={0}
                    value={state.returnArrivalTime}
                    onChange={(value) => dummyCall("returnArr", { value })}
                    // onChange={value => onChangeReturnArrTime({ value })}
                    onChangeComplete={(value) =>
                      filterApply("returnArr", { value })
                    }
                  />
                </div>
              )}
            </div>
          )}
          <div className="filterSec farePolicy">
            <div className="filterLabel d-flex justify-content-between">
              <p>Fare Policy</p>
              <Link onClick={refundFiltersClear}>Clear</Link>
            </div>
            <ul>
              <li>
                <Form.Check
                  name="refund"
                  type="checkbox"
                  id="refund"
                  label="Penalty Applies"
                  // onChange={refundable}
                  onChange={(e) => filterApply("refundable", e)}
                  custom
                />
              </li>
              {/*  <li>
                            <Form.Check
                                type="checkbox"
                                id="noneRefund"
                                label="Non-Refundable"
                                onChange={nonRefundable}
                            />
                        </li> */}
            </ul>
          </div>
          {state.response.filter &&
            state.response.filter.airl &&
            state.response.filter.airl.length > 0 && (
              <div className="filterSec airlines">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Airlines</p>
                  <Link onClick={airlinesFiltersClear}>Clear</Link>
                </div>
                <ul className="m-0 p-0">
                  {state.response.filter &&
                    state.response.filter.airl
                      .map((airline, index) => (
                        <li className="d-flex justify-content-between">
                          <Form.Check
                            name="airlines"
                            type="checkbox"
                            id={airline.airline}
                            ref={myRefs.current[index]}
                            label={
                              <span>
                                <AirlinesMemo code={airline.airline} />
                              </span>
                            }
                            title={airline.airline}
                            value={airline.airline}
                            onChange={(e) => filterApply("airline", e)}
                            custom
                          />
                          <span className="priceLbel">
                            <em>{state.finalResponse.cur} </em>
                            {
                              <FormateCurrency
                                value={Utilities.addPrice(
                                  airline.prc,
                                  0,
                                  "Add"
                                )}
                              />
                            }
                          </span>
                        </li>
                      ))
                      .slice(
                        0,
                        showAirlines ? state.response.filter.airl.length : "5"
                      )}
                </ul>

                {state.response.filter.airl.length >= 6 ? (
                  <Button
                    className="showmorelink"
                    variant="link"
                    onClick={() => setShowAirlines(!showAirlines)}
                  >
                    {showAirlines ? "- Show less" : "+ Show All"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}

          {state.response.filter &&
            state.response.filter.suplrs &&
            state.response.filter.suplrs.length > 0 && (
              <div className="filterSec airlines">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Carrier Type</p>
                  <Link onClick={supplierFiltersClear}>Clear</Link>
                </div>
                <ul className="m-0 p-0">
                  {state.response.filter &&
                    state.response.filter.suplrs.map((sup, index) => (
                      <li className="d-flex justify-content-between">
                        <Form.Check
                          name="suppliers"
                          type="checkbox"
                          id={index + 1}
                          label={sup.sup_name}
                          value={sup.sup_name}
                          onChange={(e) => filterApply("supp", e)}
                          custom
                        />
                      </li>
                    ))}
                </ul>
              </div>
            )}
          {state.response.filter &&
            state.response.filter.nerby &&
            state.response.filter.nerby.length > 0 && (
              <div className="filterSec nearbyAirports">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Nearby Airports Departure</p>
                  <Link onClick={nearbyFiltersClear}>Clear</Link>
                </div>
                <ul className="mb-0">
                  {state.response.filter &&
                    state.response.filter.nerby
                      .map((airport, index) => (
                        <li>
                          <Form.Check
                            name="nearby"
                            type="checkbox"
                            id={airport}
                            label={
                              <AirportMemo
                                code={airport}
                                mapping={state.mapping}
                                type={"filters"}
                              />
                            }
                            value={airport}
                            onChange={(e) => filterApply("nearByAirport", e)}
                            custom
                          />
                        </li>
                      ))
                      .slice(
                        0,
                        showNearbyAirports
                          ? state.response.filter.nerby.length
                          : "5"
                      )}
                </ul>
                {state.response.filter.nerby.length >= 6 ? (
                  <Button
                    className="showmorelink"
                    variant="link"
                    onClick={() => setShowNearbyAirports(!showNearbyAirports)}
                  >
                    {showNearbyAirports ? "- Show less" : "+ Show All"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}
          {state.response.filter &&
            state.response.filter.nerbyarr &&
            state.response.filter.nerbyarr.length > 0 && (
              <div className="filterSec nearbyAirports">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Nearby Airports Arrival</p>
                  <Link onClick={nearbyArrFiltersClear}>Clear</Link>
                </div>
                <ul className="mb-0">
                  {state.response.filter &&
                    state.response.filter.nerbyarr
                      .map((airport, index) => (
                        <li>
                          <Form.Check
                            name="nearbyarr"
                            type="checkbox"
                            id={airport}
                            label={
                              <AirportMemo
                                code={airport}
                                mapping={state.mapping}
                                type={"filters"}
                              />
                            }
                            value={airport}
                            onChange={(e) => filterApply("nearByAirportArr", e)}
                            custom
                          />
                        </li>
                      ))
                      .slice(
                        0,
                        showNearbyArrAirports
                          ? state.response.filter.nerbyarr.length
                          : "5"
                      )}
                </ul>
                {state.response.filter.nerbyarr.length >= 6 ? (
                  <Button
                    className="showmorelink"
                    variant="link"
                    onClick={() =>
                      setShowNearbyArrAirports(!showNearbyArrAirports)
                    }
                  >
                    {showNearbyArrAirports ? "- Show less" : "+ Show All"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}
        </div>

        <div className="showInmobile">
          <div className="filter">
            <ShowHide icon="" title="Filter">
              <div className="title d-flex justify-content-between">
                <h5>Result Filter</h5>
                <Link
                  className="ezyIcon icon-reset"
                  onClick={allFiltersClear}
                ></Link>
              </div>
              <div className="filterSec price">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Price</p>
                  <Link onClick={priceFilterClear}>Clear</Link>
                </div>
                <div className="ranges">
                  <InputRange
                    formatLabel={(value) => `${state.response.cur} ${value}`}
                    allowSameValues={true}
                    maxValue={
                      state.response.filter && state.response.filter.maxprc
                    }
                    minValue={
                      state.response.filter && state.response.filter.minprc
                    }
                    value={state.price}
                    onChangeComplete={(value) => filterApply("price", value)}
                    onChange={(value) => filterApplyPrice("price", value)}
                  />
                </div>
              </div>
              <div className="filterSec stops">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Stops</p>
                  <Link onClick={stopsFiltersClear}>Clear</Link>
                </div>
                <div className="stops">
                  <ul>
                    {state.response.filter &&
                      state.response.filter.stps !== undefined &&
                      state.response.filter.stps.map((stp, index) => (
                        <>
                          {index <= 2 && (
                            <li>
                              <Form.Check
                                name="stops"
                                type="checkbox"
                                id={
                                  stp.count === 0
                                    ? "Non Stop"
                                    : stp.count + " Stop"
                                }
                                // onChange={onStopsChange}
                                onChange={(e) => filterApply("stops", e)}
                                className="stopsBlock"
                                value={stp.count}
                                label={
                                  stp.count === 0
                                    ? "Non Stop"
                                    : stp.count + " Stop"
                                }
                              />
                              <div class="priceLabel">
                                <span>{state.response.cur + " "}</span>
                                {<FormateCurrency value={stp.prc} />}
                              </div>
                            </li>
                          )}
                        </>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="filterSec airlines">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Layover</p>
                  <Link onClick={airlinesFiltersClear}>Clear</Link>
                </div>
                <div>
                  <ul className="m-0 p-0">
                    {/* 6hours/12hours/24 hours and more then 24 hours */}

                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="layover"
                        type="checkbox"
                        id="6hours"
                        label={<span>6 hours</span>}
                        title="6hours"
                        value="6hours"
                        custom
                        // onChange={(e) => filterApply('airline', e)}
                      />
                    </li>
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="layover"
                        type="checkbox"
                        id="12hours"
                        label={<span>12 hours</span>}
                        title="12hours"
                        value="12hours"
                        custom
                        // onChange={(e) => filterApply('airline', e)}
                      />
                    </li>
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="layover"
                        type="checkbox"
                        id="24hours"
                        label={<span>24 hours</span>}
                        title="24hours"
                        value="24hours"
                        custom
                        // onChange={(e) => filterApply('airline', e)}
                      />
                    </li>
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="layover"
                        type="checkbox"
                        id="moreThan24"
                        label={<span>More than 24 hours</span>}
                        title="moreThan24"
                        value="moreThan24"
                        custom
                        // onChange={(e) => filterApply('airline', e)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filterSec times">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Onward Timings</p>
                  <Link onClick={owTimingsFiltersClear}>Clear</Link>
                </div>
                <ul>
                  <li className={state.onwardDep ? "active" : ""}>
                    <Link onClick={openOnwardDEP}>Departure</Link>
                  </li>
                  <li className={state.onwardArrrival ? "active" : ""}>
                    <Link onClick={openOnwardARI}>Arrival</Link>
                  </li>
                </ul>

                {state.onwardDep && (
                  <div className="ranges">
                    <InputRange
                      formatLabel={(value) => ` ${value} hrs`}
                      maxValue={24}
                      minValue={0}
                      value={state.onwardDepTime}
                      // onChange={value => onChangeOnwardDepTime({ value })}
                      onChange={(value) => dummyCall("onwardDep", { value })}
                      onChangeComplete={(value) =>
                        filterApply("onwardDep", { value })
                      }
                    />
                  </div>
                )}
                {state.onwardArrrival && (
                  <div className="ranges">
                    <InputRange
                      formatLabel={(value) => ` ${value} hrs`}
                      maxValue={24}
                      minValue={0}
                      value={state.onwardArrivalTime}
                      onChange={(value) => dummyCall("onwardArr", { value })}
                      // onChange={value => onChangeOnwardArrTime({ value })}
                      onChangeComplete={(value) =>
                        filterApply("onwardArr", { value })
                      }
                    />
                  </div>
                )}
              </div>
              {state.response.tt === "2" && (
                <div className="filterSec times return">
                  <div className="filterLabel d-flex justify-content-between">
                    <p>Return Timings</p>
                    <Link onClick={rtTimingsFiltersClear}>Clear</Link>
                  </div>
                  <ul>
                    <li className={state.returnDep ? "active" : ""}>
                      <Link onClick={openReturndDEP}>Departure</Link>
                    </li>
                    <li className={state.returnArrrival ? "active" : ""}>
                      <Link onClick={openReturnARI}>Arrival</Link>
                    </li>
                  </ul>

                  {state.returnDep && (
                    <div className="ranges">
                      <InputRange
                        formatLabel={(value) => ` ${value} hrs`}
                        maxValue={24}
                        minValue={0}
                        value={state.returnDeptTime}
                        onChange={(value) => dummyCall("returnDep", { value })}
                        // onChange={value => onChangeReturnDepTime({ value })}
                        onChangeComplete={(value) =>
                          filterApply("returnDep", { value })
                        }
                      />
                    </div>
                  )}
                  {state.returnArrrival && (
                    <div className="ranges">
                      <InputRange
                        formatLabel={(value) => ` ${value} hrs`}
                        maxValue={24}
                        minValue={0}
                        value={state.returnArrivalTime}
                        onChange={(value) => dummyCall("returnArr", { value })}
                        // onChange={value => onChangeReturnArrTime({ value })}
                        onChangeComplete={(value) =>
                          filterApply("returnArr", { value })
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="filterSec farePolicy">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Fare Policy</p>
                  <Link onClick={refundFiltersClear}>Clear</Link>
                </div>
                <ul>
                  <li>
                    <Form.Check
                      name="refund"
                      type="checkbox"
                      id="refund"
                      label="Penalty Applies"
                      // onChange={refundable}
                      onChange={(e) => filterApply("refundable", e)}
                      custom
                    />
                  </li>
                  {/*  <li>
                            <Form.Check
                                type="checkbox"
                                id="noneRefund"
                                label="Non-Refundable"
                                onChange={nonRefundable}
                            />
                        </li> */}
                </ul>
              </div>
              {state.response.filter &&
                state.response.filter.airl &&
                state.response.filter.airl.length > 0 && (
                  <div className="filterSec airlines">
                    <div className="filterLabel d-flex justify-content-between">
                      <p>Airlines</p>
                      <Link onClick={airlinesFiltersClear}>Clear</Link>
                    </div>
                    <ul className="m-0 p-0">
                      {state.response.filter &&
                        state.response.filter.airl
                          .map((airline, index) => (
                            <li className="d-flex justify-content-between">
                              <Form.Check
                                name="airlines"
                                type="checkbox"
                                id={airline.airline}
                                label={
                                  <span>
                                    <AirlinesMemo code={airline.airline} />
                                  </span>
                                }
                                title={airline.airline}
                                value={airline.airline}
                                onChange={(e) => filterApply("airline", e)}
                                custom
                              />
                              <span className="priceLbel">
                                <em>{state.finalResponse.cur} </em>
                                {
                                  <FormateCurrency
                                    value={Utilities.addPrice(
                                      airline.prc,
                                      0,
                                      "Add"
                                    )}
                                  />
                                }
                              </span>
                            </li>
                          ))
                          .slice(
                            0,
                            showAirlines
                              ? state.response.filter.airl.length
                              : "5"
                          )}
                    </ul>

                    {state.response.filter.airl.length >= 6 ? (
                      <Button
                        className="showmorelink"
                        variant="link"
                        onClick={() => setShowAirlines(!showAirlines)}
                      >
                        {showAirlines ? "- Show less" : "+ Show All"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}

              {state.response.filter &&
                state.response.filter.suplrs &&
                state.response.filter.suplrs.length > 0 && (
                  <div className="filterSec airlines">
                    <div className="filterLabel d-flex justify-content-between">
                      <p>Carrier Type</p>
                      <Link onClick={supplierFiltersClear}>Clear</Link>
                    </div>
                    <ul className="m-0 p-0">
                      {state.response.filter &&
                        state.response.filter.suplrs.map((sup, index) => (
                          <li className="d-flex justify-content-between">
                            <Form.Check
                              name="suppliers"
                              type="checkbox"
                              id={index + 1}
                              label={sup.sup_name}
                              value={sup.sup_id}
                              onChange={(e) => filterApply("supp", e)}
                              custom
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              {state.response.filter &&
                state.response.filter.nerby &&
                state.response.filter.nerby.length > 0 && (
                  <div className="filterSec nearbyAirports">
                    <div className="filterLabel d-flex justify-content-between">
                      <p>Nearby Airports Departure</p>
                      <Link onClick={nearbyFiltersClear}>Clear</Link>
                    </div>
                    <ul className="mb-0">
                      {state.response.filter &&
                        state.response.filter.nerby
                          .map((airport, index) => (
                            <li>
                              <Form.Check
                                name="nearby"
                                type="checkbox"
                                id={airport}
                                label={
                                  <AirportMemo
                                    code={airport}
                                    mapping={state.mapping}
                                    type={"filters"}
                                  />
                                }
                                value={airport}
                                onChange={(e) =>
                                  filterApply("nearByAirport", e)
                                }
                                custom
                              />
                            </li>
                          ))
                          .slice(
                            0,
                            showNearbyAirports
                              ? state.response.filter.nerby.length
                              : "5"
                          )}
                    </ul>
                    {state.response.filter.nerby.length >= 6 ? (
                      <Button
                        className="showmorelink"
                        variant="link"
                        onClick={() =>
                          setShowNearbyAirports(!showNearbyAirports)
                        }
                      >
                        {showNearbyAirports ? "- Show less" : "+ Show All"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              {state.response.filter &&
                state.response.filter.nerbyarr &&
                state.response.filter.nerbyarr.length > 0 && (
                  <div className="filterSec nearbyAirports">
                    <div className="filterLabel d-flex justify-content-between">
                      <p>Nearby Airports Arrival</p>
                      <Link onClick={nearbyArrFiltersClear}>Clear</Link>
                    </div>
                    <ul className="mb-0">
                      {state.response.filter &&
                        state.response.filter.nerbyarr
                          .map((airport, index) => (
                            <li>
                              <Form.Check
                                name="nearbyarr"
                                type="checkbox"
                                id={airport}
                                label={
                                  <AirportMemo
                                    code={airport}
                                    mapping={state.mapping}
                                    type={"filters"}
                                  />
                                }
                                value={airport}
                                onChange={(e) =>
                                  filterApply("nearByAirportArr", e)
                                }
                                custom
                              />
                            </li>
                          ))
                          .slice(
                            0,
                            showNearbyArrAirports
                              ? state.response.filter.nerbyarr.length
                              : "5"
                          )}
                    </ul>
                    {state.response.filter.nerbyarr.length >= 6 ? (
                      <Button
                        className="showmorelink"
                        variant="link"
                        onClick={() =>
                          setShowNearbyArrAirports(!showNearbyArrAirports)
                        }
                      >
                        {showNearbyArrAirports ? "- Show less" : "+ Show All"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
            </ShowHide>
          </div>
        </div>
      </div>
    </Sticky>
  );
}

export default Filter;
