import React, { useState, useEffect } from 'react';
import HotelSearchPart from '../../../../common/hotel/HotelSearchPart';
import LoadingBar from 'react-top-loading-bar';
import Sticky from '../../../../common/sticky/Sticky'
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function ChangeHotelSearch(props) {
  const [state, setState] = useState({})
  const [modifySearch, setModifySearch] = useState(false);
  const [request, setRequest] = useState(props.request);
  const [htlslist, setHotelslist] = useState(props.hotels);

  const openSearch = () => {
    if (modifySearch) {
      setModifySearch(false)
    } else {
      setModifySearch(true)
    }
  }

  useEffect(() => {
    if(props.enable){
      setModifySearch(false)
    }
    },[props.enable])
  useEffect(() => {
    if(props.request){
      setRequest(props.request)
    }
    },[props.request])



  return (

    <>
    <Sticky>
   <section className="modifySearch hotelContainer mb-0">
        <div className="searchInfo">
          <div className="container ">
            <div className="modifySearchInfo">
              {props.flow==="Results" &&
              <div className="resultCount">
              Showing <strong>{props.hotels!==undefined &&  props.hotels} properties <span>in </span><span className="hotelSercname" title={request.htlData[0]!==undefined && request.htlData[0].text!==undefined && request.htlData[0].text}>{request.htlData[0]!==undefined && request.htlData[0].text!==undefined && request.htlData[0].text}</span></strong>
              </div>
              }
              <div className="dateTIme"><span>Check-In : </span> {request!=undefined && request.cinui!==null && request.cinui} -  <span>Check-Out : </span>{request!=undefined && request.coutui!==null && request.coutui}</div>
              <div class="paxInfo"> <span>Room : {request && request.roomsInfo!==null && request.roomsInfo!==undefined && request.roomsInfo.length}</span> <span>Guests : {request && request.guestcnt!=undefined && request.guestcnt!=null && request.guestcnt}</span></div>
            </div>
            <span onClick={openSearch} className={"changeSearch" + (modifySearch ? ' active' : '')}>Change Search <span className="ezyIcon icon-arrowDown"/></span>
            </div>
        </div>
        {modifySearch &&
          <div className="container searchTab mudifySearch pt-4 pb-2">
           <HotelSearchPart srequest={request} flow={props.flow} respAvail={props.respAvail} cleardb={true}/>
          </div>
        }
      </section>
      <LoadingBar
       color='#02367B'
       progress={100}
       className='proGressbar'
       containerClassName='proGresbartop'
              />
      </Sticky>
    </>
  )
}


export default ChangeHotelSearch;
