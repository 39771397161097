
import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';



//Main Function
function FilterLoader(props) {
    const [state, setState] = useState({})

    const [price, setPrice] = useState({
        value: { min: 0, max: 1110 },
    });

    const [time, setTime] = useState({
        value: { min: 0, max: 24 },
    });

    return (

        <>
            {props.triptype === 2 &&
                <Button
                    size="lg" block
                    variant="primary"
                    className="fbyf flexiLigtoption"

                >
                    <span className="ezyIcon icon-ticket mr-3"></span> Flexi Flight Option
                </Button>
            }
            <div className="filter ldr">

                <div className="title d-flex justify-content-between">
                    <h5>Result Filter</h5>

                    <Link className="ezyIcon icon-reset" to="#"></Link>
                </div>

                <div className="filterSec price">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Price</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <div className="ranges price">
                        <InputRange
                            formatLabel={value => `QAR ${value}`}
                            maxValue={1000}
                            minValue={0}
                            value={price.value}
                            onChange={value => setPrice({ value })} />
                    </div>
                </div>

                <div className="filterSec stops">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Stops</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <div class="showFilters loaderFlex">
                        <div class="stopsLoader"></div>
                        <div class="stopsLoader"></div>
                        <div class="stopsLoader"></div>

                    </div>
                </div>



                <div className="filterSec times">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Onward Timings</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <ul>
                        <li className="active"><Link to="#">Departure</Link></li>
                        <li className=""><Link to="">Arrival</Link></li>
                    </ul>

                    <div className="ranges">
                        <InputRange
                            maxValue={24}
                            minValue={0}
                            value={time.value}
                            onChange={value => setTime({ value })} />

                    </div>
                </div>

                <div className="filterSec times return">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Return Timings</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <ul>
                        <li className="active"><Link to="">Departure</Link></li>
                        <li className=""><Link to="#">Arrival</Link></li>
                    </ul>

                    <div className="ranges">
                        <InputRange
                            maxValue={24}
                            minValue={0}
                            value={time.value}
                            onChange={value => setTime({ value })} />

                    </div>

                </div>

                <div className="filterSec farePolicy">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Fare Policy</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <ul class="loadList fulWid">
                        <li class="airLgolist2"></li>
                        <li class="airLgolist2"></li>
                        <li class="airLgolist2"></li>

                    </ul>

                </div>

                <div className="filterSec airlines">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Airlines</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <ul class="loadList fulWid">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>

                </div>

                <div className="filterSec nearbyAirports">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Nearby Airports</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <ul class="loadList fulWid">
                        <li class="airLgolist2"></li>
                        <li class="airLgolist2"></li>
                        <li class="airLgolist2"></li>

                    </ul>

                </div>




            </div>



        </>
    )
}


export default FilterLoader;
