
import React from 'react';
import { Col, Form } from "react-bootstrap";
import Button from '../../../common/buttons/Button';
function ReissueRefund(props) {
    const { state } = props || {}




    return (
        <>
            <div className="flightSearchFrom ">
                <Form.Row>
                    <Form.Group as={Col} className="mb-0" controlId="userRole">

                        <Form.Control type="text" placeholder="To Country" autoComplete="off" />
                        <Form.Label>Ticket Number</Form.Label>

                    </Form.Group>
                    <Form.Group as={Col} className="mb-0" controlId="userRole">

                        <Form.Control type="text" placeholder="To Country" autoComplete="off" />
                        <Form.Label>PNR Number</Form.Label>

                    </Form.Group>
                    <Form.Group as={Col} className="mb-0" controlId="userRole">

                        <Form.Control type="text" placeholder="To Country" autoComplete="off" />
                        <Form.Label>Last Name</Form.Label>

                    </Form.Group>
                    <Form.Group as={Col} className="clickMe mb-0" controlId="userRole">

                        <Button
                            size="md"
                            className="importButton text-center"
                            variant="primary" block>
                            Submit</Button>

                    </Form.Group>
                </Form.Row>
            </div>
        </>
    )
}


export default ReissueRefund;
