
import React, { Component, useState , useReducer , useEffect,useContext} from 'react';
import { Form, Button, Col, Row , Alert } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import { Context } from "../../../../../../App";
// import ReturnHeaderLoader from './ReturnHeaderLoader'

const initialState = {
    notiMessage:"", notiMessageShow:false, notiVarient:"danger",markp:0
  };
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
            ...state,      };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

//Main Function
function ApplayMarkup(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [markup, setMarkup] = useState()
  const [context, setContext] = useContext(Context);


  const applayMark=()=>()=>{
    if(markup===undefined || markup===""){
        dispatch({ type: "notiMessage", payload: "Please enter Markup." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
    }else{
      if(Math.sign(markup)===1){
        props.sendMarkup(markup);
        dispatch({type:"markp",payload:markup})
      }else{
        dispatch({ type: "notiMessage", payload: "Enter proper value." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
      }
        
    }
}

const clearMarkup = () => () => {
  if(Math.sign(markup)===1){
    dispatch({type:"markp",payload:0})
    setMarkup("");
  props.sendMarkup("0");
}
}

const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

const handleChange = (e) => {
    const { id, value } = e.target;
    if(value!==undefined){
        setMarkup(value);
    }
};

  useEffect(() => {
  if(props.markVal!==0){
    setMarkup(props.markVal);
    dispatch({type:'markp',payload:props.markVal ? Number(props.markVal) : 0})
  }
  }, [props.markVal])

  return (
    <>
       <div>
       {state.notiMessageShow &&
         <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
         }
      </div>
     <Form.Row className="m-0">
      <Form.Group controlId="markup">
          <Form.Control type="text" value={markup} placeholder="Add MarkUp" onChange={handleChange}/>
        </Form.Group>
      </Form.Row>
      {state.markp !== 0 ? (
        <Button
        size="sm"
        variant="primary"
        type="submit"
        onClick={clearMarkup()}
    > Clear</Button>
      ) :
       (<Button
        disabled = {!(context.logindata.roles && context.logindata.roles.split(",").includes('O_N_F_M_E'))}
        size="sm"
        variant="primary"
        type="submit"
        onClick={applayMark()}
    > Apply</Button>)}
      {/* <Button
        size="sm"
        variant="primary"
        type="submit"
        onClick={applayMark()}
    > Apply</Button> */}

    </>
  )
}


export default ApplayMarkup;
