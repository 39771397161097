import React, { useState, useEffect, useReducer } from 'react';
import GoogleMapReact from 'google-map-react';


 /**
 * @description:This function will the Room Occupancy
 * @author: Lakshmi
 * @param {*}
 * @function Confirm details
 * @date : 13-02-2021
 */
function HotelDetailMap(props) {
    const [resp, setResp] = useState(props.locations);
    const [zoom, setZoom] = useState(11);
    const [key, setKey] = useState("AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY");

     const defaultProps = {
        center: {
          lat: props.locations!==undefined && props.locations.lat?props.locations.lat:undefined,
          lng: props.locations!==undefined && props.locations.long?props.locations.long:undefined
        },
      };

      const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
        position: { lat: props.locations!==undefined && props.locations.lat, lng: props.locations!==undefined && props.locations.long },
        map,
        title: props.hname!==undefined?props.hname:""
        });
        return marker;
       };


    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '40vh', width: '100%' }}>
        {props.locations!==undefined &&
        <GoogleMapReact
          bootstrapURLKeys={{ key: key}}
          defaultCenter={defaultProps.center}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
        </GoogleMapReact>
         }
      </div>
    );
}

export default HotelDetailMap;
