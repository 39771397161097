import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import DateUtils from '../../../commonUtils/DateUtils';
import { characterValidation } from '../../../commonUtils/FormValidations';
import AirlineCommission from './index';
import { getComissionDetails, searchCommission } from "./operations";

export const CommissionContext = React.createContext();


/**
 * Initial State Declaration
 */
const initialState = {
  comission_List: [], updateCommission: false, createCommission: false, showCreateBtn: true, cname: "", travelType: "", userType: "", userId: "",
  branchId: "", catId: "", branchList: [], catMenu: [], agencyEmail: "", travelData: [], applTypData: [], aplType: "", nameError: false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editCompany':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will Load the all companies from service
 * @author: Lakshmi
 * @param {*}
 * @function CompanyManagement
 */
function CommissionMain() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, setData] = useState()
  const [context, setContext] = useContext(Context);
  const [enableView, setEnableView] = useState(false)
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enabelType, setEnableType] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  //This fnction will call when component will load
  useEffect(() => {
    setIsLoadingMore(true)
    if (context.logindata !== "" && context.logindata.roles !== undefined) {
      const pyload = { "type": context.logindata.utype, "userid": context.logindata.uid }
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      searchCommission(pyload).then((res) => {
        if (res && res.data.suc) {
          loadResponse(res.data.res);
        } else {
          dispatch({ type: 'isLoadingMore', payload: false })
        }
      });
    }
  }, [context.logindata]);

  const travelType = [{ label: "All", value: "0" }, { label: "One way", value: "1" }, { label: "Round Trip", value: "2" }, { label: "Multi City", value: "3" }]
  const applicabelType = [{ label: "Booking", value: "Booking" }, { label: "Travel", value: "Travel" }]

  const loadResponse = (response) => {
    let data = [];
    var enableEdit = false;
    if (context.logindata.roles.includes('C_M_U')) {
      enableEdit = true;
    }
    for (let val of response) {

      data.push({ cname: val.cname, aircode: val.aircode, ofcid: "QAR12345", tcomsion: val.amount, fromd: val.fdate, tod: val.tdate, options: <> <span className="tbAction"><a className="ezyIcon icon-view" onClick={editcommissionBtnClick(val, "view")}></a>{enableEdit && <a className="ezyicon icon-edit" onClick={editcommissionBtnClick(val, "edit")}></a>}</span></> })
    }
    //setIsLoadingMore(false);
    dispatch({ type: 'comission_List', payload: data })
    dispatch({ type: 'isLoadingMore', payload: false })
  }

  const columns = [{
    dataField: 'cname',
    text: 'Commision Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'aircode',
    text: 'Airline Code',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ofcid',
    text: 'Offfice Id',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'tcomsion',
    text: 'Total Commission',
    sort: true,
    filter: textFilter()
  },

  {
    dataField: 'fromd',
    text: 'From Date',
    sort: true,
    filter: ""
  },
  {
    dataField: 'tod',
    text: 'To Date',
    sort: true,
    filter: ""
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];



  const editcommissionBtnClick = (obj, type) => () => {
    getComissionDetails(obj.commission_id).then(response => {
      if (response && response !== undefined) {
        setData(response)
        dispatch({ type: 'updateCommission', payload: true })
        dispatch({ type: 'createCommission', payload: false })
        dispatch({ type: 'showCreateBtn', payload: false })
        if (type === "view") {
          setEnableView(true);
        } else {
          setEnableView(false);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getBrnchData function---" + error
      );
    });
  }

  const resetSearch = () => {
    dispatch({ type: 'applTypData', payload: [] })
    dispatch({ type: 'travelData', payload: [] })
    dispatch({ type: 'cname', payload: "" })
    setFromDate("")
    setToDate("")
  }
  const handleSearch = () => {
    if (state.applTypData.length > 0 || state.travelData.length > 0 || state.cname !== "" || fromDate !== "" || toDate !== "") {
      let valid = false;
      if (state.cname) {
        if (characterValidation(state.cname)) {
          dispatch({ type: "nameError", payload: false })
          valid = true
        } else {
          dispatch({ type: "nameError", payload: true })
        }
      } else {
        valid = true
      }
      if (valid) {
        const payload = {
          "type": state.userType,
          "userid": state.userId,
          "cname": state.cname,
          "ttype": state.travelType,
          "applicable": state.ap,
          "fmdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
          "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
        }
        searchCommission(payload).then((resp) => {
          if (resp.data.suc) {
            loadResponse(resp.data.res);
          } else {
            //Results ot found
          }
        })
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select at least one search criteria' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == 'cname') {
      dispatch({ type: "nameError", payload: false })
    }
    dispatch({ type: id, payload: value });
  }

  const agentTypes = [{ label: "Travel Agent", value: "TA" },
  { label: "Sub Travel Agent", value: "STA" }]

  const createCompBtnClick = (e) => {
    dispatch({ type: 'createCommission', payload: true })
    dispatch({ type: 'showCreateBtn', payload: false })
  }

  const setTravelType = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'travelType', payload: obj[0].value })
      dispatch({ type: 'travelData', payload: obj })
    }
  }

  // Adding Class and rbds
  const setAplType = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'aplType', payload: obj[0].value })
      setEnableType(true);
      dispatch({ type: 'applTypData', payload: obj })
    }
  }

  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Commission" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Commission" />
          {state.showCreateBtn &&
            <div className="contentArea cmnSearch">
              <Form.Row>
                <Form.Group as={Col} xs={3} controlId="cname">
                  <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.cname} onChange={handleChange} />
                  <Form.Label>Commission Name</Form.Label>
                  {state.nameError && (
                    <p className="vError">Please Enter Valid Name</p>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
                  <Select
                    options={travelType}
                    searchable={false}
                    values={state.travelData}
                    onChange={(value) => (setTravelType(value))}
                  />
                  <Form.Label>Travel Type</Form.Label>
                </Form.Group>

                <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
                  <Select
                    options={applicabelType}
                    searchable={false}
                    values={state.applTypData}
                    onChange={(value) => (setAplType(value))}
                  />
                  <Form.Label>Applicable Type</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={3}>
                  <Form.Row>
                    {enabelType &&
                      <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                        <DatePicker
                          selected={fromDate}
                          onChange={date => setFromDate(date)}
                          fromDate={fromDate}
                          toDate={toDate}
                          minDate={new Date()}
                        />
                         <span className="calendar ezyIcon icon-newcalendar"></span>
                        <Form.Label>Start Date</Form.Label>
                      </Form.Group>
                    }
                    {enabelType &&
                      <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                        <DatePicker
                          selected={toDate}
                          onChange={date => setToDate(date) + 1}
                          fromDate={fromDate}
                          toDate={toDate}
                          minDate={fromDate}
                          className="datefield"
                        />
                         <span className="calendar ezyIcon icon-newcalendar"></span>
                        <Form.Label>End Date</Form.Label>
                      </Form.Group>
                    }
                  </Form.Row>
                </Form.Group>
              </Form.Row>
              {state.showCreateBtn && context.logindata.roles !== undefined && context.logindata.roles.includes('C_C_C') && (
                <div className="buttonGrop pb-0">
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={createCompBtnClick}
                    loading={state.loadBranch}
                    className="addPlus ezyIcon icon-plus"
                    type="submit"
                  >  Create Commission</Button>
                </div>
              )}
              <div className="text-right pb-0 buttonGrop  creat_absolute">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  type="submit"
                  onClick={resetSearch}
                > Reset</Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={handleSearch}
                > Search</Button>
              </div>

            </div>
          }

          {state.createCommission && (
            <AirlineCommission />
          )}
          {state.updateCommission && (
            <CommissionContext.Provider value={data}>
              <AirlineCommission enbleUpdate={enableView} />
            </CommissionContext.Provider>
          )}

        </div>
        {state.showCreateBtn ? (
          <div className="cardMainlayout tableRes p-0">
            {state.isLoadingMore ? <InnerLoader /> :
              (<>
                {state.comission_List.length !== 0 &&
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    classes="markup filterTbl"
                    data={state.comission_List}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    filter={filterFactory()}
                    pagination={state.comission_List.length >10 && state.comission_List.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                    state.comission_List.length >25 && state.comission_List.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                    state.comission_List.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                  />
                  || state.comission_List.length === 0 &&
                  <NoRecord />
                }
              </>
              )}
          </div>
        ) : ('')}
      </div>
      <Footer />

    </>
  )
}
export default CommissionMain