import React, {useState } from 'react';
import InsurnceBookingPdf from './InsurnceBookingPdf';
import InsurancePolicyPdf from './InsurancePolicyPdf';
import InsuracnePlanPdf from './InsuracnePlanPdf';
import InsuranceFarePdf from './InsuranceFarePdf';
import InsurancePaxPdf from './InsurancePaxPdf';

const InsuranceMainPdf = React.forwardRef((props, ref) => {
  const [cur, setCur] = useState("QAR")

  return (
    <div className="bg-gray-200 p-6" title={"INSURANCE" + props.type} ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
            <h2 className="mb-4">
            INSURANCE {props.type}
                </h2>
                </div>
               {props.response !== undefined &&
               <InsurnceBookingPdf response={props.response} company_logo={props.compLogo} />
               }
               <div className="pdfflightDetails">
               {props.response !== undefined &&
               <InsurancePolicyPdf response={props.response} />
               }
              </div>
              <div className="pdfflightDetails">
               {props.response !== undefined && props.response.plan_details!==undefined &&
               <InsuracnePlanPdf response={props.response.plan_details} />
               }
               </div>
               {props.type!==undefined && props.type==="INVOICE" &&
               <div className="pdfflightDetails">
               {props.response !== undefined && props.response.plan_details!==undefined &&
               <InsuranceFarePdf response={props.response.plan_details} />
               }
                </div>
               }
               {props.type!==undefined && props.type==="ITINERARY" &&
               <div className="pdfflightDetails">
               {props.response !== undefined && props.response.pax_details!==undefined &&
               <InsurancePaxPdf paxList={props.response.pax_details} />
               }
                </div>
               }
          </div>
        </div>
        </div>
        <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>
      </div>
  );
});

export default InsuranceMainPdf;
