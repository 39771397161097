import UserRoleManagementService from '../../../../services/administration/UserRoleManagementService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function User
 * @date : 27-10-20
 */


// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = [];
    await UserRoleManagementService.saveUserDetails(obj).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
// export const creation = async (obj) => {
//     let finalRes=[];
//     await UserRoleManagementService.saveUserDetails(obj).then(response => {
//         finalRes = response.data;
//     });
//     return Promise.resolve(finalRes);
// }

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes = [];
    await UserRoleManagementService.updateUserDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranches = async () => {
    let branchesList = [];
    await AttCommonSerivce.getBranchData().then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}


export const loadBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.loadBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const fetchCrmFunctionalities = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.crmFunctionalities(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const reportingList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getReportingUserList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getUserData = async (code) => {
    let ofcList = [];
    await UserRoleManagementService.getUserData(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

/**
 * @description : fetch users from DB w.r.t userId
 */

export const getUserSearch = async (code) => {
    let ofcList = [];
    await UserRoleManagementService.getUserSearch(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}



