
import React, { useEffect, useState, useReducer } from 'react';
import { Col, Row } from "react-bootstrap";
import AirportMemo from '../../../../common/airlines/airportNames';
import DateUtils from '../../../../commonUtils/DateUtils';

//Initial State
const initialState = {
    headerInfos: [], secType: '', mapping: []
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {

        case 'test':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//Main function For Result Header
function ResultSectionHeader(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [displayObj, setDisplayObj] = useState({ fd: "", da: "", aa: "" });

    useEffect(() => {
        dispatch({ type: 'headerInfos', payload: props.sectors });
        dispatch({ type: 'secType', payload: props.secType });
        dispatch({ type: 'mapping', payload: props.mapping });
        if (props.secType === "onward") {
            if(props.sectors.length > 0) {
            setDisplayObj({ fd: props.sectors[0].dd, da: props.sectors[0].src, aa: props.sectors[0].des })
            }
        }
        else if (props.secType === "arrival") {
            if(props.sectors.length > 0) {
            setDisplayObj({ fd: props.sectors[0].ad, da: props.sectors[0].des, aa: props.sectors[0].src })
            }
        }

    }, [props.secType]);

    return (
        <>
            {displayObj.da && <div className="rs-headerBlock mt-3">


                <Row className="align-items-center rs-row">
                    <Col xs={8} className="rs-rTypeDate">
                        {state.secType === "onward" ? <span className="departure ezyIcon icon-flight"> {props.secTypeIndex!==undefined?props.secTypeIndex:'Departure'} </span> : <span className="departure arrival ezyIcon icon-flight"> Arrival</span>}
                        <span className="listDdate">{DateUtils.convertToLetterDate(displayObj.fd)}</span>
                        <span className="columnDdate">{DateUtils.convertToLetterDate(displayObj.fd)}</span>

                    </Col>
                    <Col xs={4} className="rs-FromTo">
                        <Row className="align-items-center justify-content-end">
                            <Col xs={5} className="d-flex flex-column p-0 mobileModev">
                              <AirportMemo field = "ResultSectionHeader" code={displayObj.da} mapping={state.mapping} />
                            </Col>
                            <Col xs={1} className="iconFlight"><span className="listFlt ezyIcon icon-flight"></span><span className="culmnArw ezyIcon icon-oneside"></span></Col>
                            <Col xs={5} className="d-flex flex-column p-0 mobileModev">
                                <AirportMemo field = "ResultSectionHeader" code={displayObj.aa} mapping={state.mapping} />
                            </Col>
                        </Row>
                    </Col>
                </Row>


                {/* <Row className="align-items-center rs-row">
                    <Col xs={4} className="rs-rTypeDate">
                        {state.secType === "onward" ? <span className="departure ezyIcon icon-flight"> {props.secTypeIndex!==undefined?props.secTypeIndex:'Departure'} </span> : <span className="departure arrival ezyIcon icon-flight"> Arrival</span>}
                        <span className="listDdate">{DateUtils.convertToLetterDate(displayObj.fd)}</span>
                        <span className="columnDdate">{DateUtils.convertToLetterDate(displayObj.fd)}</span>

                    </Col>
                    <Col xs={8} className="rs-FromTo">
                        <Row className="align-items-center">
                            <Col xs={5} className="d-flex flex-column p-0">
                              <AirportMemo code={displayObj.da} mapping={state.mapping} />
                            </Col>
                            <Col xs={2} className="iconFlight"><span className="listFlt ezyIcon icon-flight"></span><span className="culmnArw ezyIcon icon-oneside"></span></Col>
                            <Col xs={5} className="d-flex flex-column p-0">
                                <AirportMemo code={displayObj.aa} mapping={state.mapping} />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
            </div>
            }
        </>
    )
}


export default ResultSectionHeader;
