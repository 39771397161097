import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Modal, Row } from "react-bootstrap";
import ShowFareOption from './ShowFareOption'

/**
 * @description:This function will call the Mini fare rules
 * @author: Pravas
 * @param {*}
 * @function TravellerFusionFare details
 * @date : 28-06-2021
 */

/**
* Initial State Declaration
*/
const initialState = { response: "" };

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function TravelFusionFareRules(props) {
    return (
        <>
            {props.flights!==undefined && props.secType!==undefined &&
            <div className="travelFusionresponceList">
                {props.reviewFlight===undefined && props.flights[0]!==undefined && props.flights[0].fareop!==undefined &&
                <div className="fusionlistGrid">
                     {props.secType==="onward" && props.flights[0].fareop.map((fare, index) => (
                         <>
                         {fare.selected &&
                        <div className="selectfareTf">
                           <ShowFareOption fare={fare} flights={props.flights[0]} serviceFee={fare.servicefee} srequest={props.srequest} reviewFlt={props.reviewFlight} regionType={props.regionType}/>
                       </div>
                         }
                    </>
                   ))}
                </div>
                 }
                 {props.reviewFlight===undefined && props.secType==="arrival" && props.flights[1]!==undefined && props.flights[1].fareop!==undefined &&
                <div className="fusionlistGrid">
                     {props.flights[1].fareop.map((fare, index) => (
                         <>
                         {fare.selected &&
                        <Row>
                        <Col xs={12}>
                        <ShowFareOption fare={fare} flights={props.flights[1]} serviceFee={fare.servicefee} srequest={props.srequest} reviewFlt={props.reviewFlight} regionType={props.regionType}/>
                        </Col>
                    </Row>
                         }
                    </>
                   ))}
                </div>
                 }
                 </div>
                }
                {props.selFlt!==undefined && props.selFlt===1 && props.reviewFlight!==undefined && props.reviewFlight.origns[0]!==undefined && props.reviewFlight.origns[0].srcorigns[0]!==undefined &&
                 <div className="travelFusionresponceList">
                <div className="fusionlistGrid">
                <>
                <Row>
                <Col xs={6}>
                <ShowFareOption fare={props.reviewFlight.origns[0].srcorigns[0]} flights={props.reviewFlight.origns[0].srcorigns[0]} serviceFee={props.reviewFlight.origns[0].srcorigns[0].serviceFee} srequest={props.reviewFlight} reviewFlt={props.reviewFlight} regionType={props.regionType} />
                </Col>
                </Row>
                </>
                </div>
                </div>
                 }

              {props.selFlt!==undefined && props.selFlt===2 &&  props.reviewFlight!==undefined && props.reviewFlight.origns[0]!==undefined && props.reviewFlight.origns[0].destorigins[0]!==undefined &&
                 <div className="travelFusionresponceList">
                <div className="fusionlistGrid">
                <>
                <Row>
                <Col xs={6}>
                <ShowFareOption fare={props.reviewFlight.origns[0].destorigins[0]} flights={props.reviewFlight.origns[0].destorigins[0]} serviceFee={props.reviewFlight.origns[0].destorigins[0].serviceFee} srequest={props.reviewFlight} reviewFlt={props.reviewFlight}/>
                </Col>
                </Row>
                </>
                </div>
                </div>
                 }

                {props.secType==="onward" && props.sty==="split" &&  props.splitFlights!==undefined && props.splitFlights[0]!==undefined && props.splitFlights[0].srcorigns[0]!==undefined &&
                 <div className="travelFusionresponceList">
                <div className="fusionlistGrid">
                <>
                <Row>
                <Col xs={12}>
                <ShowFareOption fare={props.splitFlights[0]} flights={props.splitFlights[0].srcorigns[0]} serviceFee={props.splitFlights[0].servicefee} srequest={props.srequest} reviewFlt={props.reviewFlight} regionType={props.regionType}/>
                </Col>
                </Row>
                </>
                </div>
                </div>
                 }

               {props.secType==="arrival" && props.sty==="split" &&  props.splitFlights!==undefined && props.splitFlights[1]!==undefined && props.splitFlights[1].srcorigns[0]!==undefined &&
                 <div className="travelFusionresponceList">
                <div className="fusionlistGrid">
                <>
                <Row>
                <Col xs={12}>
                <ShowFareOption fare={props.splitFlights[1]} flights={props.splitFlights[1].srcorigns[0]} serviceFee={props.splitFlights[1].servicefee} srequest={props.srequest} reviewFlt={props.reviewFlight} regionType={props.regionType}/>
                </Col>
                </Row>
                </>
                </div>
                </div>
                 }
        </>
    )
}
export default TravelFusionFareRules