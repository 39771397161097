import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import DateUtils from "../../commonUtils/DateUtils";

//This function will show the Air markups List and Add Markup
function PassangerDetails(props) {
  const [state, setState] = useState({
    loading: false
  })

  return (
    <>
        <div className="headerlist">
                     <Row>
                         <Col>Traveller</Col>
                         <Col>Pax Type</Col>
                         <Col>Gender</Col>
                         <Col>Date of Birth</Col>
                         <Col>Passport Number</Col>
                         <Col>Country of Residence</Col>
                         {/* <Col>Covid Extension</Col>
                         <Col>Winter Sport Extension </Col> */}
                     </Row>
                 </div>
                    <div className="passengerList">
                    {props.paxList && props.paxList.map((pax,idx)=>(
                             <div className="list">

                                     <Row>
                                     <Col>{pax.pax_name}</Col>
                                     <Col>{pax.pax_type}</Col>
                                     <Col>{pax.gender}</Col>
                                     <Col>{DateUtils.convertToDateHifen(pax.dob)}</Col>
                                     <Col>{pax.doc_id}</Col>
                                     <Col>{pax.isc}</Col>
                                     {/* <Col>{pax.covid_ext?"YES":"NO"}</Col>
                                     <Col>{pax.wintor_ext?"YES":"NO"} </Col> */}
                                     </Row>

                             </div>
                                ))
                              }
                     </div>
    </>
  )
}
export default PassangerDetails