
import React, { useState, useEffect}from 'react';

/**
 * @description:This Login pageLayout
 * @author: Pravas
 * @param {*}
 * @function Package Info
 * @date : 21-09-2020
 */

function PackageInfo(props) {
  //const [state, setState] = useState({})

  return (
    <>
            <div className="costBox bookingSummary">
             {props.overView!==undefined && props.overView!=="" && props.isOver &&
        <div dangerouslySetInnerHTML={{__html: props.overView}} ></div>

            //  <span>{props.overView}</span>

             }
            </div>
            <div className="costBox bookingSummary">
             {props.incluExclusion!==undefined && props.incluExclusion!=="" && props.isOnclu &&
              <div dangerouslySetInnerHTML={{__html: props.incluExclusion}} ></div>
              // <span>{props.incluExclusion}</span>
             }
            </div>
            <div className="costBox bookingSummary">
             {props.cancellation!==undefined && props.cancellation!=="" && props.isCancel &&
              <div dangerouslySetInnerHTML={{__html: props.cancellation}} ></div>
              // <span>{props.cancellation}</span>
             }
            </div>
            <div className="costBox bookingSummary">
             {props.terms_en!==undefined && props.terms_en!=="" && props.isTerms &&
              <div dangerouslySetInnerHTML={{__html: props.terms_en}} ></div>
              // <span>{props.cancellation}</span>
             }
            </div>
    </>
  )
}
export default PackageInfo