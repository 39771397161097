import React, { Component, useState, useEffect } from 'react';
import { Button, Spinner} from "react-bootstrap";
/**
 * @description:This function will add Button Component .
 * @author: Pravas
 * @param {*}
 * @function Button
 * @date : 01-09-2020
 */

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function InnerLoader(props) {
  //console.log(props);
  const { state } = props || {}
  const [className] = useState(props.className);

  return (
    <div  className={`layoverLoader ${className}`}><div className="pageInner">
    <div className="loaderIcon"></div>
  </div></div>


  );
}

export default InnerLoader;