
/**
 * @description: CRUD operations for Comission
 * @author: Rambabu
 * @param {*}
 * @function Commission
 * @date : 13-09-2020
 */

import AirLineCommissionService from '../../../../services/administration/AirLineCommissionService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';


/**
 * @description: Fetch Countries for airport selection
 * @author: Rambabu
 * @param {*}
 * @function countries
 * @date : 16-09-2020
 */

export const countries = async (obj) => {
    let country_res = '';
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            country_res = response.data.res;
        }
    })
    return Promise.resolve(country_res);
}


/**
 * @description: Fetch airportlist for airport selection
 * @author: Rambabu
 * @param {*}
 * @function airportlist
 * @date : 16-09-2020
 */
export const airportlist = async (e) => {
    let airport_res = '';
    await AttCommonSerivce.getAirportData(e[0].code).then(response => {
        if (response.data.suc) {
            airport_res = response.data.res;
        }
    })
    return Promise.resolve(airport_res);
}


/**
 * @description: Fetch getbranchList
 * @author: Rambabu
 * @param {*}
 * @function getbranchList
 * @date : 16-09-2020
 */
export const getbranchList = async (id) => {
    let branch_res = '';
    await AttCommonSerivce.getOfficeidBranchData(id).then(response => {
        if (response.data.suc) {
            branch_res = response.data.res;
        }
    })
    return Promise.resolve(branch_res);
}


/**
 * @description: Fetch getOfficeIdList
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdList
 * @date : 16-09-2020
 */
export const getOfficeIdList = async (obj) => {
    let officeid_res = '';
    await AttCommonSerivce.getOfficeIdConfigList(obj).then(response => {
        if (response.data.suc) {
            officeid_res = response.data.res;
        }
    })
    return Promise.resolve(officeid_res);
}


/**
 * @description: Creation Comission
 * @author: Rambabu
 * @param {*}
 * @function creation
 * @date : 15-09-2020
 */
export const creation = async (obj) => {
    let finalRes = '';
    await AirLineCommissionService.saveCommission(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Fetch Comission List
 * @author: Rambabu
 * @param {*}
 * @function getComissionList
 * @date : 15-09-2020
 */
export const getComissionList = async () => {
    let commissionList = [];
    await AirLineCommissionService.getCommissionData().then(response => {
        console.log("Load all commission list response : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            commissionList = response.data.res;
        }
    });
    return Promise.resolve(commissionList);
}


/**
 * @description: Fetch Comission List
 * @author: Rambabu
 * @param {*}
 * @function getComissionList
 * @date : 21-09-2020
 */
export const getComissionDetails = async (id) => {
    let commissionDetails = [];
    await AirLineCommissionService.getComissionDetails(id).then(response => {
        console.log("Load Resepective Commission data : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            commissionDetails = response.data.res;
        }
    });
    return Promise.resolve(commissionDetails);
}

/**
 * @description: Delete Applicable Data
 * @author: Rambabu
 * @param {*}
 * @function getComissionList
 * @date : 22-09-2020
 */
export const deleteAppliacableData = async (id) => {
    let deleteres = [];
    await AirLineCommissionService.deleteapplicableChildData(id).then(response => {
        console.log("Delete applicable Data is--- : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            deleteres = response.data.res;
        }
    });
    return Promise.resolve(deleteres);
}

/**
 * @description: Delete Routes Data
 * @author: Rambabu
 * @param {*}
 * @function deleteRoutesData
 * @date : 22-09-2020
 */
export const deleteRoutesData = async (obj) => {
    let deleteroutesres = [];
    await AirLineCommissionService.deleteCommissionRoutesData(obj).then(response => {
        console.log("Delete routes Data is--- : " + JSON.stringify(response));
        if (response.data.suc) {
            deleteroutesres = response.data.res;
        }
    });
    return Promise.resolve(deleteroutesres);
}


/**
 * @description: Delete Class(RBD) Data
 * @author: Rambabu
 * @param {*}
 * @function deleteClassData
 * @date : 22-09-2020
 */
export const deleteClassData = async (id) => {
    let deleteclasssres = [];
    await AirLineCommissionService.deleteCommissionClassChildData(id).then(response => {
        console.log("Delete routes Data is--- : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            deleteclasssres = response.data.res;
        }
    });
    return Promise.resolve(deleteclasssres);
}

/**
 * @description: Delete Office id Data
 * @author: Rambabu
 * @param {*}
 * @function deleteCommissionOfficeidData
 * @date : 22-09-2020
 */
export const deleteCommissionOfficeidData = async (id) => {
    let deleteOffres = [];
    await AirLineCommissionService.deleteCommissionOfficeidChildData(id).then(response => {
        console.log("Delete routes Data is--- : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            deleteOffres = response.data.res;
        }
    });
    return Promise.resolve(deleteOffres);
}


/**
 * @description: Update Comission
 * @author: Rambabu
 * @param {*}
 * @function update
 * @date : 24-09-2020
 */
export const update = async (obj) => {
    let updateRes = '';
    await AirLineCommissionService.updateCommissionData(obj).then(response => {
        updateRes = response.data;
    });
    return Promise.resolve(updateRes);
}
/**
 * @description: Update Comission
 * @author: Rambabu
 * @param {*}
 * @function update
 * @date : 24-09-2020
 */
export const searchCommission = async (obj) => {
    let updateRes = '';
    await AirLineCommissionService.searchCommission(obj).then(response => {
        updateRes = response;
    });
    return Promise.resolve(updateRes);
}







