
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import AirportMemo from '../../common/airlines/airportNames';
import DateUtils from '../../commonUtils/DateUtils';
import ImportPriceDetails from './ImportPriceDetails';
import AirlinesMemo from '../../common/airlines';
import {isNotNull } from '../../commonUtils/validators';
import LayoverDetails from '../../bookingFlows/flight/common/LayoverDetails';
import FlightSegmentImportDetails from '../../bookingFlows/flight/common/FlightSegmentImportDetails';
const _ = require('lodash');
/**
 * @description:This function will load the Import PNR details
 * @author: Lakshmi
 * @param {*}
 * @function DashBoardPNRImport
 * @date : 17-11-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  all: false, selSegment: false, notiMessage: '', notiMessageShow: false, notiVarient: '', priceResp: "", showSegments: [], adt: 0, chd: 0, inf: 0,grandTotal: 0,finalisd_import_seg:[]
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ShowInsertPnrData(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selSegments, setSelSegments] = useState({ segments: [] })
  const [response, setResponse] = useState(props.pnrResponse.res)
  const [mappings, setMappings] = useState(props.pnrResponse.res.mappings)


  useEffect(() => {
    //******if import booking then handle accordingly *********/
        let initData = [] //segment details
        if(props.pnrResponse.res.origns) {
            initData = props.pnrResponse.res.origns[0].srcorigns[0].seg;
        }
        let count = 1 // count of seg order for further use
        if(initData.length!==0){
          for(let val of initData) { //iteration of seg details
            if(val) {
            val.trip_type = 1
            val.seg_order = count
            count = count + 1
            }
        }
        }
        let  new_seg_details =  constructSegmentForImport(initData) //handle layover time or new departure
        if(new_seg_details !== null && new_seg_details.length > 0) {
            initData = new_seg_details //set new modified seg details
        }

       let arr = _.groupBy(initData,"trip_type" )
       const propertyNames = Object.values(arr);

       dispatch({type:"finalisd_import_seg",payload:propertyNames})

 },[props.pnrResponse.res])


 const constructSegmentForImport = (data) => {
  let seg_data = data //segment details

  // scenarios->

  if(seg_data.length > 0 && seg_data.length === 2) {
      //check if its like one way with connectivity
    let duration =  DateUtils.msToTime(Math.abs(new Date(seg_data[1].dd) - new Date(seg_data[0].ad))) //layover time
    let dayExceedTag = false
    if(duration.includes("day") || duration.includes("days") ) {
      dayExceedTag = true
    }
  //   if(dayExceedTag) {
      if(seg_data[0].ar == seg_data[1].da) { //checking arrival of first segment and departure of second segment
          if(seg_data[0].da == seg_data[1].ar) { //checking departure of first segment and arrival of first segment
              seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
              seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //no changes
          } else {
              if(dayExceedTag) {
                  seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                  seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //changes
              } else {
                  return null //no changes
              }
          }
      } else {
          if(seg_data[0].da == seg_data[1].ar) {
              seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
              seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //changes
          } else {
              return null //no changes
          }
      }

  } else {
      let count = 1 //intial count of trip type
      let segment_number = 1
      //iterate each of the seg to handle same condition as above
      for(let indexOf in seg_data) {
          let current_seg = seg_data[indexOf] //iterative current segment
          let next_seg = null
          if(seg_data[Number(indexOf) + 1]) {
          next_seg = seg_data[Number(indexOf) + 1] //next segment
          }
          //****condition to check whether next segment is available or not *****/
          if(next_seg) {
              //if current segment arrival === next segment destination
              // if(current_seg.ar == next_seg.da) {
                  let duration =  DateUtils.msToTime(Math.abs(new Date(next_seg.dd) - new Date(current_seg.ad))) //layover time
                  let dayExceedTag = false
                    if(duration.includes("day") || duration.includes("days") || duration.includes("d")) {
                       dayExceedTag = true
                      }
                      //this means its more than or equal to 24 hrs
                      if(dayExceedTag) {
                          count = count + 1
                          segment_number = 1
                      }
                      let next_seg_count = count
                      next_seg.trip_type = next_seg_count //assiginng new trip type(AKA segment header count) to show as new departure
                      if(dayExceedTag) {
                      next_seg.seg_order = 1 // assigning inital seg order
                      } else {
                          segment_number = segment_number + 1
                          next_seg.seg_order = segment_number
                      }
              }
          // }
          }
            return seg_data //changes depends on layover time
        }
      }

 function getAirportdata(code, val) {
    if (isNotNull(props.airport_data) && isNotNull(code)) {
        for (let a in props.airport_data) {
            let air_data = props.airport_data[a];
            if (isNotNull(air_data.airportCode)) {
                if (air_data.airportCode === code && val === 'c') {
                    return air_data.cityName;
                } else if (air_data.airportCode === code && val === 'a') {
                    return air_data.airportName;
                }
            }
        }
    }
}

const handlePax = (data) => (e) => {
  const { id, value } = e.target
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
         if(pax.sno===data.sno){
           if(!pax.selected){
            pax.selected=true;
           }else{
            pax.selected=false;
           }
         }
      }
 }
 dispatch({ type: id, payload: value });
}

const handlePaxChange = (data,isInf) => (e) => {
  const { id, value } = e.target
  dispatch({ type: id, payload: value });
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
          if(isInf){
            if(pax.sno===data.sno){
              pax.inf.tkt=value;
           }
          }else{
            if(pax.sno===data.sno){
              pax.tkt=value;
           }
          }

      }
 }
 dispatch({ type: id, payload: value });
 props.sendPaxInfo(response.psngrs);
}

  return (
    <>
      <div className="confirmation mt-3">
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <div className="cardMainlayout bg-white mb-4">
          <h5 className="title d-flex justify-content-between">Flight Details</h5>
          <div className="selectedRout resultSection tableLayout">
            <Table>
              {state.finalisd_import_seg.length===0 &&
                <thead>
                <tr>
                  <th>Flight No.</th>
                  <th>Departure Date/Time</th>
                  <th>Arrival Date/Time</th>
                  <th>Airline PNR</th>
                </tr>
              </thead>
              }
              <tbody>
                {state.finalisd_import_seg.length===0 && response.origns[0].srcorigns[0].seg.map((sege, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr>
                        <td>
                          <div className="d-flex">
                            <img
                            src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + sege.ma + '.png'}
                            className="logo"
                            alt="AirLine"
                                     />
                          <div className="ml-2"><strong><AirlinesMemo code={sege.ma} /></strong>
                          <span>{sege.ac} {sege.fn}</span>
                          <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                       </div></div> </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                            <span>{getAirportdata(sege.da, 'c')} ({sege.da}),</span>
                                         <span>{getAirportdata(sege.da, 'a')}</span>
                                         {sege.terminal &&
                                         <small className="trm">{"Terminal - " + sege.terminal.dep}</small>
                                         }
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                            <span>{getAirportdata(sege.ar, 'c')} ({sege.ar}),</span>
                                         <span>{getAirportdata(sege.ar, 'a')}</span>
                                         {sege.terminal &&
                                         <small className="trm">{"Terminal - " + sege.terminal.arr}</small>
                                         }
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                          <div className="oprtBy"><span>{sege.airpnr}</span></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="p-0 border-0">
                        {response.origns[0].srcorigns[0].bag!==undefined && response.origns[0].srcorigns[0].bag.length!==0 &&
                                <>
                                {response.origns[0].srcorigns[0].bag.map((bag, idx) => (
                                <React.Fragment key={idx}>
                                {bag.tatoo===sege.tatoo &&
                                <div className="sectBaggageinfo ezyIcon icon-baggage m-0"> Baggage Info : Cabin 7 kgs, Check-in {bag.qun}  {bag.wt} per Adult & Child </div>
                                }
                                </React.Fragment>
                                ))}
                                </>
                            }
                            </td>
                            </tr>
                            <tr>
                            {response.origns[0].srcorigns[0].seg.length > 1 &&
                             (isNotNull(response.origns[0].srcorigns[0].seg[(index + 1)]))
                             && <LayoverDetails layoverdata={[response.origns[0].srcorigns[0].seg[index].ad, response.origns[0].srcorigns[0].seg[(index + 1)].dd, sege.ar, mappings]} />}
                            </tr>
                    </>
                  </React.Fragment>
                ))
                }

                {state.finalisd_import_seg.length > 0 && state.finalisd_import_seg.map((sec, idx) => (
                         <>
                             <div className="rs-headerBlock mt-3">
                                 <Row className="align-items-center rs-row">
                                     <Col xs={3} className="rs-rTypeDate">
                                         <>
                                             <span className="departure ezyIcon icon-flight">
                                          Departure </span><span>{DateUtils.prettyDate(sec[0].dd, "ddd, DD MMM YYYY")}</span>
                                         </>
                                     </Col>
                                     <Col xs={9} className="rs-FromTo">
                                         <Row className="align-items-center">
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[0].da} mapping={mappings} />
                                             </Col>
                                             <Col xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[sec.length - 1].ar} mapping={mappings} />
                                             </Col>
                                         </Row>
                                         <span>
                                         </span>
                                     </Col>
                                 </Row>
                             </div>
                             <FlightSegmentImportDetails  origindata={[{"seg":sec}, 'Confirm']} cl={response.origns[0].srcorigns[0].cl} pnr={response.pnr} bgd={response.origns[0].srcorigns[0].bag} mapping={mappings} confirmation={true}/>
                         </>
                     ))}
              </tbody>
            </Table>
          </div>
          <h5 className="title mt-4">Passenger Details</h5>
          <div className="selectedRout tableLayout">
            <Table>
              <thead>
                <tr>
                  <th>Traveller</th>
                  <th>Pax Type</th>
                  <th>Ticket Numbers</th>
                </tr>
              </thead>
              <tbody>
              {response.psngrs.map((pasenger, idx) => (
                <>
                <tr>
                  <td>{pasenger.tl.toUpperCase()} {pasenger.fn.toUpperCase() + " " + pasenger.ln.toUpperCase()} ({pasenger.plbl.toUpperCase()}) {pasenger.lead?"Lead":""}</td>
                  <td>{pasenger.plbl}</td>
                  <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.selected} onChange={handlePax(pasenger)} custom id={pasenger.tkt} />
                   {!pasenger.selected &&
                   <span>{pasenger.tkt} </span>
                   }
                   {pasenger.selected &&
                  <Form.Row>
                        <Form.Group xs={12} as={Col} controlId="tkt" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter Ticket number" value={pasenger.tkt} onChange={handlePaxChange(pasenger,false)} />
                      </Form.Group>
                      </Form.Row>
                    }
                    </div>
                   </td>
                  </tr>

                  {pasenger.inf !== "" &&
                  <tr>
                    <td width="332">{pasenger.inf.tl.toUpperCase()} {pasenger.inf.fn + " " + pasenger.inf.ln} ({pasenger.inf.plbl.toUpperCase()})</td>
                    <td>Infant</td>

                    <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.selected} onChange={handlePax(pasenger)} custom id={pasenger.inf.tkt} />
                   {!pasenger.selected &&
                   <span>{pasenger.inf.tkt} </span>
                   }
                   {pasenger.selected &&
                  <Form.Row>
                        <Form.Group xs={6} as={Col} controlId="tkt" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter Ticket number" value={pasenger.inf.tkt} onChange={handlePaxChange(pasenger,true)} />
                      </Form.Group>
                      </Form.Row>
                    }
                                    </div>
                   </td>

                  </tr>
                  }
               </>
            ))}
              </tbody>
              </Table>

            </div>

        <h5 className="title mt-4">Fare Details({props.userCurrency})</h5>
           {response!==undefined &&
            <ImportPriceDetails faredet={response} />
           }
        </div>
      </div>
    </>
  )
}
export default ShowInsertPnrData