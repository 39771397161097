import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import log from "loglevel";
import Select from 'react-select';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import { Context } from "../../../../App";
import { isNotNull } from '../../commonUtils/validators';
import Indiaflag from '../../../../../src/client/assets/images/indiaflag.png'
import { getRecentData } from '../../dashBoard/operations';
export const USER_AUTH_DATA = "userAuthData";
export const ATT_BRANCHES = "attBranches";
export const ATT_COMPANIES = "attCompanys";
export const INTIAL_USER_AUTH_DATA = "authData";
export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
export const SUMMARY_COMP_REGION = "SUMMARY_COMP_REGION";
export const COMP_ID = "COMP_ID"
export const CRE_BAL = "CRE_BAL"
export const BRANCH_CUR = "BRANCH_CUR";
export const COUNTRY_DATA = "COUNTRY_DATA";
/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Heder Info component
 * @date : 23-10-2020
 */

/**
* Initial State Declaration
*/
const initialState = {
  branches: [], companies: [], cid: 0, bid: 0, selBranch: [], selCompany: [], disableBranch: false, disableCompany: false, setCredit: [], cid: 0, summaryCompDetails: [], allCompanies: [], propscid: 0, compid: 0, companyData: [],selRegion:[]
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [],
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HeaderInfo(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [enabelCompany, setEnableCompany] = useState(false)
  const [context, setContext] = useContext(Context);
  const [path,setPath] = useState()
  const [regionTypes, setRegionTypes] = useState([])

  useEffect(() => {
    if (props.contextData !== "" && state.companies.length===0) {
      if ((context.logindata.utype === "att_user" || context.logindata.utype === "ta_user")){
        if(context.logindata.utype === "att_user"){
        if(context.logindata.region_list!==undefined && context.logindata.region_list.length!==0){
          setRegionTypes(context.logindata.region_list)
          setEnableCompany(true)
          if(context.logindata.region_type!==undefined){
            dispatch({ type: "selRegion", payload: [{value:context.logindata.region_type,label:context.logindata.region_type}]});
          }
        }else{
          let data =JSON.parse(localStorage.getItem(COUNTRY_DATA))
          if(data!==null){
            setRegionTypes(data)
            setEnableCompany(true)
            dispatch({ type: "selRegion", payload: [{value:data[0].label,label:data[0].label}]});
          }
        }
        }
        var compData = localStorage.getItem(SUMMARY_COMP_ID)
        var region_summary = window.sessionStorage.getItem(SUMMARY_COMP_REGION)
        console.log('compData:::',compData)
        let url_array = window.location
        if(url_array.pathname.includes("ezytrip/bookings/hotel") || url_array.pathname.includes("ezytrip/bookings/flight") || url_array.pathname.includes("ezytrip/bookings/insurance")) {
          var headerCompData = sessionStorage.getItem("CMPID")
          }
          else{
        var headerCompData = localStorage.getItem(COMP_ID)
        }
        if(context.logindata.utype === "ta_user" && state.setCredit.length===0 && (headerCompData == undefined || JSON.parse(headerCompData) === 0)) {
          let pyload = { "pid": 0, "comid": context.logindata.cid , "cuscode":context.logindata.cuscode}
          console.log("payload::::",pyload)
          AttCommonSerivce.getCLBforATT(pyload).then(response => {
            console.log('responsive for intil  flow', response)
            if (response.data.suc) {
              let array = []
              if (response.data.res.data) {
                let val = response.data.res.data
                let obj = {
                  "value": val.cbal,
                  "cur": val.cur ? val.cur : "QAR"
                }
                array.push(obj)
                dispatch({ type: 'setCredit', payload: array })
                props.creditData(array)
              }
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getCredutbalance function---" + error
            );
          })
        let url_array = window.location
          if(url_array.pathname.includes("ezytrip/bookings/flight")) {
            dispatch({type:"disableCompany",payload:true})
          }
        }
        dispatch({ type: 'userId', payload: props.contextData.uid, is_admin:props.contextData.is_admin })
        console.log('urllll::::', JSON.parse(JSON.stringify(url_array)))
        // const pyload={"type":context.logindata.utype,"userId":context.logindata.uid,fetch:'mapping'}
        let pyload = { userId: props.contextData.uid, pcid: props.contextData.pcid, utype: props.contextData.utype,region_type:region_summary!==null?region_summary:context.logindata.region_type}
        if(state.companyData.length===0){
        AttCommonSerivce.att_companies(pyload).then(response => {
          //console.log('response::::companies', JSON.stringify(response))
          if (response.data.suc && response.data.res.status === 200) {
            setEnableCompany(true);
            let authData = []
            let selectData = []
            dispatch({ type: 'companyData', payload: response.data.res.data })
            dispatch({ type: 'allCompanies', payload: response.data.res.data })
            for (let val of response.data.res.data) {
              let auth = {
                "value": val.value,
                "label": val.label
              }
              authData.push(auth)
              if(val.value!==290){
                let select = {
                  "label": val.label + "    " + val.type,
                  "value": val.value,
                  "cuscode":val.cuscode,
                  "bid":val.bid,
                  "type":val.type
                }
                selectData.push(select)
              }

            }
            dispatch({ type: 'companies', payload: selectData })
            var userData = localStorage.getItem(USER_AUTH_DATA);
            var resObj = JSON.parse(userData);
            var userinitData = localStorage.getItem(INTIAL_USER_AUTH_DATA);
            var resultObj = JSON.parse(userinitData);
            if (headerCompData && JSON.parse(headerCompData) !== 0 && state.compid === 0 && url_array.pathname !== "/ezytrip/dashboard") {
              dispatch({type:'disableCompany',payload: true})
              let objArr = []
              let cnm = ''
              let type = ''
              let butype = ''
              let pcid = 0
              let cuscode = ''
              let bid = 0
              for (let val of response.data.res.data) {
                if (JSON.parse(headerCompData) === val.value) {
                  cnm = val.label
                  type = val.type
                  pcid = val.pid
                  cuscode = val.cuscode
                  bid = val.bid
                  let obj = {
                    "label": val.label + "    " + val.type,
                    "value": val.value
                  }
                  objArr.push(obj)
                }
              }
              dispatch({ type: 'selCompany', payload: objArr })
              dispatch({ type: "selRegion", payload: [{value:context.logindata.region_type,label:context.logindata.region_type}]});
              if (objArr.length !== 0 && state.cid === 0) {
                resObj.cnm = cnm
                resObj.cid = objArr[0].value
                if (type == 'STA') {
                  butype = "sta_user"
                } else {
                  butype = "ta_user"
                }
                dispatch({ type: 'compid', payload: objArr[0].value })
                let pid = ''
                let arr = []
                if ( response.data.res.data.length > 0) {
                  for (let val of  response.data.res.data) {
                    if (val.value === objArr[0].value) {
                      pid = val.pid
                    }
                  }
                }
                resObj.butype = butype;
                resObj.pcid = pcid
                resObj.bid = bid
                let pyload = { "pid": (butype == "ta_user" ? 0 : pid), "comid": objArr[0].value,"cuscode": cuscode}
                console.log('pyload:::',pyload)
                AttCommonSerivce.getCLBforATT(pyload).then(response => {
                  console.log('responsive for flow', response)
                  if (response.data.suc) {

                    if (response.data.res.data) {
                      let val = response.data.res.data
                      let obj = {
                        "value": val.cbal,
                        "cur": val.cur
                      }
                      arr.push(obj)
                      dispatch({ type: 'setCredit', payload: arr })
                      props.creditData(arr)
                    }
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCredutbalance function---" + error
                  );
                })
                AttCommonSerivce.fetch_offpay_role(objArr[0].value).then(response => {
                  console.log('responsive for fetchData', response)
                  if (response.data.suc){
                    let offPay = false
                    if(response.data.res.length > 0) {
                      let responseOf = response.data.res[0]
                      if(responseOf.offpay > 0) {
                        offPay = true
                      } else {
                        offPay = false
                      }
                    }
                    resObj.offlinePay = offPay
                    console.log("local udate context:::",resObj)
                    updateContext(resObj);
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in roles(offlinepayment) function---" + error
                  );
                })
                updateContext(resObj);
              }
            } else if (compData && JSON.parse(compData) !== 0 && state.propscid === 0 && url_array.pathname !== "/ezytrip/dashboard") {
              let company = []
              let cnm = ''
              let type = ''
              let butype = ''
              let pcid = 0
              let cuscode = ""
              let bid = ""
              let logo=""
              if(compData == context.logindata.cid) {
                console.log('im here:::::::')
                dispatch({type:'disableCompany',payload: true})
              }
              for (let val of response.data.res.data) {
                if (JSON.parse(compData) === val.value) {
                  cnm = val.label
                  type = val.type
                  pcid = val.pid
                  cuscode = val.cuscode
                  logo = val.comp_logo_url
                  bid = val.bid
                  let obj = {
                    "label": val.label + "    " + val.type,
                    "value": val.value,
                  }
                  company.push(obj)
                }
              }
              console.log('company selected::::',company)
              dispatch({ type: 'summaryCompDetails', payload: company })
              if (company.length !== 0 && state.cid === 0) {
                resObj.cnm = cnm
                resObj.cid = company[0].value
                if (type == 'STA') {
                  butype = "sta_user"
                } else {
                  butype = "ta_user"
                }
                dispatch({ type: 'propscid', payload: company[0].value })
                resObj.butype = butype;
                resObj.pcid = pcid
                resObj.bid = bid
                let pid = ''
                let arr = []
                console.log('compData',state.companyData)
                if (state.companyData.length > 0) {
                  for (let val of state.companyData) {
                    if (val.value === company[0].value) {
                      pid = val.pid
                    }
                  }
                }
                resObj.cuscode = cuscode
                let pyload = { "pid": (butype == "ta_user" ? 0 : pid), "comid": company[0].value ,"cuscode": cuscode}
                console.log('fetch for pyload',pyload)
                AttCommonSerivce.getCLBforATT(pyload).then(response => {
                  console.log('responsive', response)
                  if (response.data.suc) {

                    if (response.data.res.data) {
                      let val = response.data.res.data
                      let obj = {
                        "value": val.cbal,
                        "cur": val.cur
                      }
                      arr.push(obj)
                      dispatch({ type: 'setCredit', payload: arr })
                      props.creditData(arr)
                    }
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCredutbalance function---" + error
                  );
                })
                AttCommonSerivce.fetch_offpay_role(company[0].value).then(response => {
                  console.log('responsive for fetchData', response)
                  if (response.data.suc){
                    let offPay = false
                    if(response.data.res.length > 0) {
                      let responseOf = response.data.res[0]
                      if(responseOf.offpay > 0) {
                        offPay = true
                      } else {
                        offPay = false
                      }
                    }
                    resObj.offlinePay = offPay
                    updateContext(resObj);
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in roles(offlinepayment) function---" + error
                  );
                })
                updateContext(resObj);
              }
            }
            console.log('exceptionnn',state.cid,resObj.cid,state.propscid,url_array.pathname)
            if(url_array.pathname === "/ezytrip/dashboard/bookingList") {
              localStorage.setItem("COMP_ID", 0)
              dispatch({ type: 'summaryCompDetails', payload: [] })
            }


            //default set for local storage if clicked on dashboard
            if (state.cid === 0  && context.logindata.utype === "att_user" && (url_array.pathname === "/ezytrip/dashboard" || url_array.pathname.includes("ezytrip/administration"))) {
              console.log('diff set here')
              resObj.cid = 0;
              resObj.cnm = ""
              resObj.butype = "att_user";
              resObj.offlinePay = false
              resObj.pcid = 0
              localStorage.setItem("SUMMARY_COMP_ID", 0)
              localStorage.setItem("COMP_ID", 0)
              dispatch({ type: 'selCompany', payload: [] })
              dispatch({type:'disableCompany',payload: false})
              props.creditData([])
              updateContext(resObj);
            } else if (state.cid === 0 && resObj.cid !== resultObj.cid && context.logindata.utype === "ta_user" && (url_array.pathname === "/ezytrip/dashboard" || url_array.pathname.includes("ezytrip/administration"))) {
              localStorage.setItem("SUMMARY_COMP_ID", 0)
              localStorage.setItem("COMP_ID", 0)
              dispatch({ type: 'selCompany', payload: [] })
              dispatch({type:'disableCompany',payload: false})
              props.creditData([])
              updateContext(resultObj)
            }
            if (context.logindata.utype === "att_user") {
              localStorage.setItem(ATT_COMPANIES, JSON.stringify(authData));
            }

          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranches function---" + error
          );
        });
      }
      }
    }
  }, [props.contextData]);

  useEffect(() => {
    let url_array = window.location
    console.log('urllll::::header', JSON.parse(JSON.stringify(url_array.pathname)))
    setPath(url_array.pathname)
    // if (context.logindata.utype === "att_user") {
    //   if (context.logindata.cid === 0) {
    //     document.querySelector('.domin.company').classList.add('dominFocus');
    //     return false;
    //   }
    // }
  },[window.location])


  const updateContext = (resObj) => {
    localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
    if (resObj) {
      setContext({ logindata: resObj });
    }
  }

  // ========================================================================
  //  Updating Local storage based on selected company
  // ========================================================================
 const selectCompany = (obj) => {
    let type = ''
    let cnm = ''
    let cuscode = ''
    let pid = 0
    let bid = 0
    let comp_logo = ''
    let arr = []
    var userData = localStorage.getItem(USER_AUTH_DATA);
    var resObj = JSON.parse(userData);
    AttCommonSerivce.getCustId(obj.value).then(response => {
      console.log('responsive of custID', response)
      if (response.data.suc) {

        if (response.data.res && response.data.res.length > 0) {
          cuscode = response.data.res[0].back_ofc_id
          comp_logo = response.data.res[0].comp_logo_url

          resObj.cuscode = cuscode
          resObj.comp_logo_url = comp_logo;
          resObj.butype=obj.type==="TA"?"ta_user":"sta_user";
          updateContext(resObj);
        }
      }
      let pyload = { "pid": (type == "ta_user" ? 0 : pid), "comid": obj.value , "cuscode":obj.cuscode }
      AttCommonSerivce.getCLBforATT(pyload).then(resp => {
        console.log('responsive for CL', resp)
        if (resp.data.suc){
          if (resp.data.res.data) {
            let val = resp.data.res.data
            let obj = {
              "value": val.cbal,
              "cur": val.cur
            }
            arr.push(obj)
            dispatch({ type: 'setCredit', payload: arr })
            props.creditData(arr)
          }
        }else{
          let obj = {
            "value": 0,
            "cur": "QAR"
          }
          arr.push(obj)
          props.creditData(arr)
        }


    AttCommonSerivce.fetch_offpay_role(obj.value).then(rspe => {
      console.log('responsive for fetchData', rspe)
      if (rspe.data.suc){
        let offPay = false
        if(rspe.data.res.length > 0) {
          let responseOf = rspe.data.res[0]
          if(responseOf.offpay > 0) {
            offPay = true
          } else {
            offPay = false
          }
        }
        resObj.offlinePay = offPay
        updateContext(resObj);
          let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
          let rq_json = {
            id: obj.value,
            utype: localstorage.utype,
            uid: localstorage.uid,
            landing: true,
            is_admin:localstorage.is_admin,
            region_type:localstorage.region_type
          }
          getRecentData(rq_json).then(response => {   
            if (response) {
              props.bookingListRes(response)
            }})
      }
        console.log('selected company', obj)
        localStorage.setItem("SUMMARY_COMP_ID", 0)
        dispatch({ type: 'selCompany', payload: [obj] })
        dispatch({ type: "selRegion", payload: [{value:context.logindata.region_type,label:context.logindata.region_type}]});
        localStorage.setItem("COMP_ID", obj.value)
        document.querySelector('.domin').classList.remove('dominFocus');
        // var userData = localStorage.getItem(USER_AUTH_DATA);
        // var resObj = JSON.parse(userData);
        resObj.cid = obj.value;
        resObj.bid = obj.bid;
        resObj.butype=obj.type==="TA"?"ta_user":"sta_user";
        dispatch({ type: 'cid', payload: obj.value })
        for (let val of state.allCompanies) {
          if (val.value === obj.value) {
            cnm = val.label;
          }
        }
        resObj.cnm = cnm
        resObj.cuscode = obj.cuscode;
        console.log('selected com', obj)
        // retrieve credit for the company
        if (state.companyData.length > 0) {
          for (let val of state.companyData) {
            if (val.value === obj.value) {
              pid =  (type == "ta_user" ? obj.value : val.pid)
            }
          }
        }
        resObj.pcid = pid
        updateContext(resObj);
    }).catch(function (error) {
      log.error(
        "Exception occured in roles(offlinepayment) function---" + error
      );
    })
      }).catch(function (error) {
        log.error(
          "Exception occured in getCredutbalance function---" + error
        );
      })
    }).catch(function (error) {
      log.error(
        "Exception occured in getCredutbalance function---" + error
      );
    })
    //updateContext(resObj);
  }

  const handleRegionChange = (e) => {
      dispatch({ type: 'selCompany', payload: [] })
      dispatch({ type: "selRegion", payload: [e] });
      var userData = localStorage.getItem(USER_AUTH_DATA);
      var resObj = JSON.parse(userData);
      resObj.region_type=e.label;
      resObj.cid=0;
      resObj.office_id="DOHQR28IH";
      resObj.cur=e.cur;
      resObj.bcur=e.cur;
      updateContext(resObj)
      let pyload = {userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype,region_type:e.label}
      AttCommonSerivce.att_companies(pyload).then(response => {
        dispatch({ type: 'companies', payload: []})
        if (response.data.suc && response.data.res.status === 200) {
          setEnableCompany(true);

          let selectData = []
          for (let val of response.data.res.data) {
            if(val.value!==290){
              let select = {
                "label": val.label + "    " + val.type,
                "value": val.value,
                "cuscode":val.cuscode,
                "bid":val.bid,
                "type":val.type
              }
              selectData.push(select)
            }

          }
          dispatch({ type: 'companies', payload: selectData })
        }}).catch(function (error) {
          log.error(
            "Exception occured in getBranches function---" + error
          );
        });
}

  return (
    <>
    {path && !path.includes("/administration") &&
    <div className="domin mr-3">
      <Form.Row>
      {regionTypes.length!==0 && context.logindata.utype === "att_user" && context.logindata.bothRegions!==undefined && context.logindata.bothRegions && state.propscid === 0 &&
      <Form.Group className="selectplugin regionCountry mr-2" controlId="regions" >
      <Select
        options={regionTypes}
        className='react-select-container branchType countryType'
        classNamePrefix="react-select"
        isSearchable={false}
        value={state.selRegion}
        isDisabled={state.disableCompany || state.summaryCompDetails.length !== 0}
        placeholder = " "
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F0F0F0',
            primary: '#00367A',
          },
        })}
        onChange={(value) => (handleRegionChange(value))}
      />
    </Form.Group>

       }
        {enabelCompany && state.summaryCompDetails.length === 0 && state.propscid === 0 &&
          <Form.Group className="selectplugin" controlId="userRole" >
            <Select
              options={state.companies}
              className='react-select-container branchType brnchSelect'
              classNamePrefix="react-select"
              isSearchable={true}
              value={state.selCompany}
              isDisabled={state.disableCompany}
              placeholder = "Select Agent"
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F0F0F0',
                  primary: '#00367A',
                },
              })}
              onChange={(value) => (selectCompany(value))}
            />
          </Form.Group>
        }
        {enabelCompany && state.summaryCompDetails.length !== 0 && state.propscid !== 0 &&
          <Form.Group className="selectplugin" as={Col} xs={2} controlId="userRole" >
            <Select
              options={state.summaryCompDetails}
              className='react-select-container branchType'
              classNamePrefix="react-select"
              isSearchable={true}
              value={state.summaryCompDetails}
              isDisabled={true}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F0F0F0',
                  primary: '#00367A',
                },
              })}
              onChange={(value) => (selectCompany(value))}
            />
          </Form.Group>
        }
      </Form.Row>
    </div>
      }
      </>
  );
}


export default HeaderInfo;