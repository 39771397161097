import CreditManagementServices from '../../../../services/administration/CreditManagementServices';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { isNotNull } from '../../../commonUtils/validators';
/**
 * @description: CRUD operations for Credit limit
 * @author: Rambabu
 * @param {*}
 * @function:Create
 * @date : 08-09-2020
 */



export const creation = async (obj) => {
    let finalRes = {};
    let company_id = '';
    if (isNotNull(obj.companyType) && obj.companyType === 'STA') {
        company_id = obj.company_sta_id;
    }
    const save_obj = {
        company_type: obj.companyType,
        comp_id: company_id.value,
        com_name: company_id.label,
        credit_limit_amt: obj.creditLimit,
        is_alert: obj.is_alert,
        low_credit_amt: obj.low_credit_limt,
        cur: 'QAR',
        bid: obj.bid,
        bname: obj.bname,
        crby: obj.crby,
        ledger: {
            "remarks": obj.remarks,
            "amt": obj.creditLimit,
            "t_type": "Credit",
            "p_type": "credit",
            "ref_no": ""
        }
    }
    await CreditManagementServices.save(save_obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: CRUD operations for Credit limit
 * @author: Rambabu
 * @param {*}
 * @function:Update
 * @date : 08-09-2020
 */
export const update = async (obj) => {

    let finalRes = {};
    const update_obj = {

        "credit_id": obj.id,
        "remarks": obj.remarks,
        "amt": obj.amount,
        "t_type": obj.trans_type,
        "p_type": obj.pType,
        "ref_no": obj.refData,
        "low_credit_amt": obj.low_credit_limt,
        "is_alert": obj.is_alert,
        "crby": obj.crby

    }
    await CreditManagementServices.updateCreditlimit(update_obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}



/**
 * @description: Fetch TA company list
 * @author: Rambabu
 * @param {*}
 * @function:companyList
 * @date : 08-09-2020
 */
export const companyList = async (obj) => {
    let companyList = [];
    await AttCommonSerivce.getCompanyLsit(obj.company_type).then(response => {
        if (response.data.suc) {
            companyList = response.data.res;
        }
    });
    return Promise.resolve(companyList);
}


/**
 * @description: Fetch STA company list
 * @author: Rambabu
 * @param {*}
 * @function:companySTAList
 * @date : 08-09-2020
 */
export const companySTAList = async (obj) => {
    let companyList = [];
    await AttCommonSerivce.getSTACompanyList(obj.company_id).then(response => {
        if (response.data.suc) {
            companyList = response.data.res;
        }
    });
    return Promise.resolve(companyList);
}

export const companypredticiveTAList = async (obj) => {
    let companyList = [];
    await AttCommonSerivce.companypredticiveTAList(obj).then(response => {
        if (response.data.suc) {
            companyList = response.data.res;
        }
    });
    return Promise.resolve(companyList);
}
/**
 * @description: Fetch Branch data resepective  company id
 * @author: Rambabu
 * @param {*}
 * @function:companybranchData
 * @date : 11-09-2020
 */
export const companybranchData = async (obj) => {
    let branchData = [];
    await AttCommonSerivce.getCompanyBranchData(obj.company_id).then(response => {
        if (isNotNull(response.data)) {
            branchData = response.data.res;
        }
    });
    return Promise.resolve(branchData);
}


/**
 * @description: Fetch company credit limit data
 * @author: Rambabu
 * @param {*}
 * @function:companycreditlimtData
 * @date : 08-09-2020
 */
export const companycreditlimtData = async (obj) => {
    let companyList = [];
    await CreditManagementServices.getCompanyCreditLimtData(obj.company_id).then(response => {
        if (response.data.suc) {
            companyList = response.data.res;
        }
    });
    return Promise.resolve(companyList);
}

/**
 * @description: Fetch company credit limit data
 * @author: Rambabu
 * @param {*}
 * @function:companycreditlimtData
 * @date : 08-09-2020
 */
export const getCompaniesList = async (obj) => {
    let companyList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
        companyList = response;
    });
    return Promise.resolve(companyList);
}





