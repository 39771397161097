
import React, { useState } from 'react';
import { Col, Row, Table } from "react-bootstrap";
import FormateCurrency from '../../../commonUtils/FormateCurrency';

function PaymentDetails(props) {
    const [state, setState] = useState({})
    const [flightDetails, setFlightDetails] = useState(false);




    return (
        <>
                <Row>
                    <Col className="farepaymentInfo">
                        <div className='table-responsive'>

                        <Table>
                            <tr>
                                <th>Payment Type</th>
                                <th>Card Surcharge</th>
                                <th>Amount</th>
                                <th>Status</th>
                                {props.payments.length!==0 && props.payments[0].form_of_pay!=="" &&
                                <>
                                <th>Mode of Payment</th>
                                <th>Ref Number</th>
                                <th>Remarks</th>
                                </>
                                }

                            </tr>
                            {props.payments && props.payments.map((pay, idx) => (
                                <>
                                {pay.status!=="Pending" &&
                                <tr>
                                        <td>
                                        <span>{pay.form_of_pay!=="" && pay.form_of_pay!==undefined && pay.form_of_pay.includes("Offline")?"Offline Payment":pay.payment_type}</span>                                        </td>
                                        <td>{pay.c_sur_charge} </td>
                                        <td>{props.cur} {<FormateCurrency value={pay.purchase_amount} />}</td>
                                        {props.bktype!=="34" &&
                                        <td className={pay.status === "Confirmed" ? "status paxstatus confirm" : "status paxstatus "}>{pay.status} </td>
                                        }
                                        {pay.form_of_pay!=="" &&
                                        <>
                                        <td>{pay.paymode} </td>

                                        <td>{pay.payref} </td>

                                        <td>{pay.remarks} </td>
                                        </>
                                        }
                                    </tr>
                                }

                                </>
                            ))}
                        </Table>
                        </div>
                    </Col>
                </Row>
        </>
    )
}


export default PaymentDetails;
