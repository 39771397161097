import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import DateUtils from '../../../commonUtils/DateUtils';
import { getCompanyTAList, getBranchList, getCompaniesList, getDsrReportData } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../../common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../../common/ReportMenu';
import HotelreportMenu from '../../../common/subMenu/HotelreportMenu';
import CoomonExportExcel from '../../../common/CoomonExportExcel';
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import { contextType } from "react-currency-format";

/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
    branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "", aplType: "", applToData: [], companyData: [], branchData: [], applTypData: [], travelData: [], totals: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",
            };
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air dsrdata List and Add Markup
function DSRReports(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [enabelATT, setEnableATT] = useState(false);
    const [enabelTA, setEnableTA] = useState(false);
    const [enabelType, setEnableType] = useState(false);
    const [isLoadingMore, setIsLoadingMore,] = useState(false);
    const [dsrdata, setDsrData] = useState({ dsrReportList: [] });
    const [dsrExceldata, setDsrExcelData] = useState({ dsrReportExcelList: [] });

    const applicabelTo = [{ label: "TA", value: 'TA' }, { label: "STA", value: 'STA' }]
    useEffect(() => {

        if (context.logindata !== undefined) {
            dispatch({ type: 'userType', payload: context.logindata.utype })
            if (context.logindata.utype === "sta_user") {
                dispatch({ type: 'cid', payload: context.logindata.cid })
                dispatch({ type: 'aplTo', payload: "STA" })
            } else if (context.logindata.utype === "ta_user") {
                dispatch({ type: 'cid', payload: context.logindata.cid })
                setEnableTA(true)
            }
            dispatch({ type: 'userId', payload: context.logindata.uid })
            const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
            //Load all companies based Login type as TA
            getCompaniesList(pyload).then(response => {
                if (response.data.suc) {
                    dispatch({ type: 'companies', payload: response.data.res })
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in getCompanyList function---" + error
                );
            });
        }
        //loadResponse();
    }, [context.logindata]);
    useEffect(()=>{
        if(dsrdata.dsrReportList.length !== 0){
    dsrExceldata.dsrReportExcelList = dsrdata.dsrReportList
    let restotal = state.totals
    let total = {
      region_type: restotal.region_type,
      currency: restotal.region_type=="Qatar"?"QAR":"INR",
      star_rate: "Grand Total",
      pub_fare: restotal.tot_pub_fare.toFixed(2),
      mkt_fare: restotal.tot_mkt_fare.toFixed(2),
      tax: restotal.tot_tax.toFixed(2),
      svc_fee : restotal.tot_svc_fee.toFixed(2),
      cost: restotal.tot_cost.toFixed(2),
      markup: restotal.tot_markup.toFixed(2),
      disc : restotal.tot_disc.toFixed(2),
      cccharge: restotal.tot_cccharge.toFixed(2),
      cgst: restotal.tot_cgst,
      sgst: restotal.tot_sgst,
      igst: restotal.tot_igst,
      tds: restotal.tot_tds,
      selprice : restotal.tot_selprice !== null && restotal.tot_selprice.toFixed(2),
      tot_sup_disc : Number(restotal.tot_sup_disc).toFixed(2),
      profit: restotal.tot_profit !== null && restotal.tot_profit.toFixed(2)
    }
     let excelData = dsrExceldata.dsrReportExcelList.concat(total)
    setDsrExcelData({dsrReportExcelList: excelData})
      }
      }, [dsrdata.dsrReportList] )
    function hidemessage() {
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
    }
    const validateSearch = () => {
        if (fromDate === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
        } else if (toDate === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
        } else if (state.aplTo !== "" && state.bid === "" && state.userType !== "sta_user") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
        }
        else if (state.aplTo !== "" && state.bid !== "" && state.cid == "" && state.userType == "att_user") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
        }
        return true;
    }

    //Handling the search creterai based pn the selection and dates
    const handleSearch = () => {
        if (validateSearch()) {
            setDsrData({ dsrReportList: []})
            setDsrExcelData({dsrReportExcelList: []})
            dispatch({ type: 'totals', payload: "" })
            if (state.userType === "att_user" && state.aplTo === "") {
                setIsLoadingMore(true)
                const req = {
                    "region_type":context.logindata.region_type,
                    "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
                    "branch_name": state.branchData.length!==0?state.branchData[0].label:"",
                    "vtype": "MTA",
                    "uid": state.userId,
                    "cid": "",
                    "utype": "att_user",
                    "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
                    "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
                }
                // console.log("Search request : " + JSON.stringify(req));
                getDsrReportData(req).then(response => {
                    if (response.suc && response.res.data.length !== 0) {
                        dispatch({ type: 'totals', payload: response.res.totals })
                        let data = [];
                        for(let val of response.res.data){
                            val.region_type = val.region_type
                            val.currency = val.region_type=="Qatar"?"QAR":"INR"
                            val.pub_fare = val.pub_fare.toFixed(2)
                            val.mkt_fare = val.mkt_fare.toFixed(2)
                            val.tax = val.tax.toFixed(2)
                            val.svc_fee = val.svc_fee.toFixed(2)
                            val.cost = val.cost.toFixed(2)
                            val.markup = val.markup.toFixed(2)
                            val.disc = val.disc.toFixed(2)
                            val.cccharge = val.cccharge.toFixed(2)
                            val.cgst = context.logindata.region_type=="India" && val.cgst.toFixed(2)
                            val.sgst = context.logindata.region_type=="India" && val.sgst.toFixed(2)
                            val.igst = context.logindata.region_type=="India" && val.igst.toFixed(2)
                            val.tds  = context.logindata.region_type=="India" && val.tds.toFixed(2)
                            val.selprice = val.selprice !== null ?  val.selprice.toFixed(2) : null
                            if((state.userType === "att_user"&&state.aplTo=="TA")|| state.userType === "ta_user"){
                                val.sup_disc = Number(val.sup_disc).toFixed(2)
                            }
                            val.profit = val.profit !== null ? val.profit.toFixed(2) : null
                            val.doc_dateExcel = val.doc_date
                            val.doc_date = <span className="dsrdate" title={val.doc_date}>{val.doc_date}</span>
     }                       
                        setDsrData({ dsrReportList: response.res.data })

                        setIsLoadingMore(false)
                    }else{
                        setIsLoadingMore(false)
                        setDsrData({ dsrReportList: [] })
                    }
                }).catch(function (error) {
                    setIsLoadingMore(false)
                    log.error(
                        "Exception occured in getCompanyList function---" + error
                    );
                });
            }
            else {
                if (state.cid !== 0 && state.cid !== "") {
                    setIsLoadingMore(true)
                    var userType = (state.userType === "ta_user") ? "TA" : "STA";
                    const request = {
                        "region_type":context.logindata.region_type,
                        "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
                        "branch_name": state.branchData.length!==0?state.branchData[0].label:"",
                        "vtype": (state.aplTo !== "" && state.aplTo !== undefined) ? state.aplTo : userType,
                        "uid": state.userId,
                        "utype": state.userType,
                        "cid": (state.cid !== 0) ? state.cid : "",
                        "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
                        "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
                    }
                    getDsrReportData(request).then(response => {
                        if (response.suc && response.res.data.length !== 0) {
                            dispatch({ type: 'totals', payload: response.res.totals })
                            let data = [];
                            for(let val of response.res.data){
                                val.region_type = val.region_type
                                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                                val.pub_fare = val.pub_fare?val.pub_fare.toFixed(2):null
                                val.mkt_fare = val.mkt_fare?val.mkt_fare.toFixed(2):null
                                val.tax = val.tax?val.tax.toFixed(2):null
                                val.svc_fee = val.svc_fee?val.svc_fee.toFixed(2):null
                                val.cost = val.cost?val.cost.toFixed(2):null
                                val.markup = val.markup?val.markup.toFixed(2):null
                                val.disc = val.disc?val.disc.toFixed(2):null
                                val.cccharge = val.cccharge?val.cccharge.toFixed(2):null
                                val.cgst = context.logindata.region_type=="India" && val.cgst
                                val.sgst = context.logindata.region_type=="India" && val.sgst
                                val.igst = context.logindata.region_type=="India" && val.igst
                                val.tds = context.logindata.region_type=="India" && val.tds
                                val.selprice = val.selprice !== null ? val.selprice.toFixed(2): null
                                if((state.userType === "att_user"&&state.aplTo=="TA")|| state.userType === "ta_user"){
                                    val.sup_disc = Number(val.sup_disc).toFixed(2)
                                }
                                val.profit = val.profit !== null ? val.profit.toFixed(2) : null
                                val.doc_dateExcel = val.doc_date
                                val.doc_date = <span className="dsrdate" title={val.doc_date}>{val.doc_date}</span>
                            }
                            setDsrData({ dsrReportList: response.res.data })
                            setIsLoadingMore(false)
                        }else{
                            setIsLoadingMore(false)
                            setDsrData({ dsrReportList: [] })
                        }
                    }).catch(function (error) {
                        setIsLoadingMore(false)
                        log.error(
                            "Exception occured in getCompanyList function---" + error
                        );
                    });
                }
            }
        }
    }



    const setCompany = (obj) => {
        if (obj[0] !== undefined) {
            dispatch({ type: 'cid', payload: obj[0].value })
            dispatch({ type: 'companyData', payload: obj })
        }
    }


    const resetSearch = () => {
        setEnableType(false);
        setEnableATT(false)
        setEnableTA(false)
        setFromDate("")
        setToDate("")
        dispatch({ type: 'aplTo', payload: "" })
        dispatch({ type: 'bid', payload: "" })
        dispatch({ type: 'cid', payload: "" })
        dispatch({ type: 'applTypData', payload: [] })
        dispatch({ type: 'companyData', payload: [] })
        dispatch({ type: 'branchData', payload: [] })
        dispatch({ type: 'applToData', payload: [] })
    }


    const setAplTo = (obj) => {
        if (obj[0] !== undefined && obj[0].value !== undefined) {
            dispatch({ type: 'aplTo', payload: obj[0].value })
        }
        dispatch({ type: 'applToData', payload: obj })
        dispatch({ type: 'cid', payload: 0 })
        if (obj[0] !== undefined && obj[0].value === "TA") {
            setEnableATT(true);
            setEnableTA(false)
            // const pyload = { "type": state.userType, "userId": state.userId }
            //Load all branches based on for ATT
            if(context.logindata.is_admin){//ATT Admin
                AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type}).then(response => {
                    if (response.data.suc){
                      dispatch({ type: 'branches', payload: response.data.res })
                    }
                  }).catch(function (error) {
                    log.error(
                      "Exception occured in Loadi branches based on Region function---" + error
                    );
                  });
            }else{
                AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type, "uid":context.logindata.uid}).then(response => {
                    if (response.data.suc){
                      dispatch({ type: 'branches', payload: response.data.res })
                    }
                  }).catch(function (error) {
                    log.error(
                      "Exception occured in Loadi branches based on Region function---" + error
                    );
                  });
            }
            

        } else if (obj[0] !== undefined && obj[0].value === "STA") {
            // if (state.userType === "att_admin") {
                setEnableATT(true);
                setEnableTA(false)
            // const pyload = { "type": state.userType, "userId": state.userId }
        if(context.logindata.is_admin){//ATT Admin
        AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type}).then(response => {
          if (response.data.suc){
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in Loadi branches based on Region function---" + error
          );
        });
        }else{
            AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type, "uid":context.logindata.uid}).then(response => {
                if (response.data.suc){
                  dispatch({ type: 'branches', payload: response.data.res })
                }
              }).catch(function (error) {
                log.error(
                  "Exception occured in Loadi branches based on Region function---" + error
                );
              });
        }
            // }
        } else {
            setEnableATT(false);
            setEnableTA(false)
        }
    }

    const selectBranch = (obj) => {
        var branchId = obj[0].value;
        dispatch({ type: 'bid', payload: branchId })
        dispatch({ type: 'branchData', payload: obj })
        if (enabelATT) {
            getCompanyTAList(branchId, state.aplTo).then(response => {
                if (response.data.suc) {
                    setEnableTA(true)
                    dispatch({ type: 'companies', payload: response.data.res })
                    dispatch({ type: 'aplTo', payload: "TA" })
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in getCompanyList function---" + error
                );
            });
            // } else {
            //   setEnableTA(false)
            //   console.log("Branch  : ");
            // }
        } else {
            setEnableTA(false);
        }
    }
    if (state.userType == "att_user") {
        var columns = [
            {
                dataField: 'region_type',
                text: 'Region Type',
                sort: false,
                filter: ""
            },
            {
                dataField: 'Branch',
                text: 'Branch',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custnm',
                text: 'Company',
                sort: false,
                filter: ""
            },
            {
                dataField: 'currency',
                text: 'Currency',
                sort: false,
                filter: ""
            },
            {
                dataField: 'doc_date',
                text: 'Document Date',
                sort: false,
                filter: ""
            },
            {
                dataField: 'hotel_nm',
                text: 'Hotel Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'supplier_no',
                text: 'Supplier Conf #',
                sort: false,
                filter: ""
            },
            {
                dataField: 'hcno',
                text: 'Hotel Conf #',
                sort: false,
                filter: ""
            },
            {
                dataField: 'c_center',
                text: 'Cost center',
                sort: false,
                filter: ""
            },
            {
                dataField: 'bkr_no',
                text: 'BKR No.',
                sort: false,
                filter: ""
            },
            {
                dataField: 'dcmntno',
                text: 'Document No.',
                sort: false,
                filter: ""
            },
            {
                dataField: 'supplier',
                text: 'Supplier',
                sort: false,
                filter: ""
            },
            {
                dataField: 'saleschnl',
                text: 'Sales Channel',
                sort: false,
                filter: ""
            },
            {
                dataField: 'servicetype',
                text: 'Type of Services',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custcode',
                text: 'Customer Code',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custnm',
                text: 'Customer Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custgrp',
                text: 'Customer Group',
                sort: false,
                filter: ""
            },
            {
                dataField: 'agent_name',
                text: 'Agent Name',
                sort: false,
                filter: ""
            },
            // {
            //     dataField: 'ticketno',
            //     text: 'Ticket Number',
            //     sort: false,
            //     filter: ""
            // },
            {
                dataField: 'pass_name',
                text: 'Passenger Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'ptype',
                text: 'Pax Type',
                sort: false,
                filter: ""
            },
            {
                dataField: 'nopax',
                text: 'No of PAX',
                sort: false,
                filter: ""
            },
            {
                dataField: 'ticketst',
                text: 'Status',
                sort: false,
                filter: ""
            },
            {
                dataField: 'destination',
                text: 'Destination',
                sort: false,
                filter: ""
            },
            {
                dataField: 'country',
                text: 'Country',
                sort: false,
                filter: ""
            },
            {
                dataField: 'non',
                text: 'No. Of Nights',
                sort: false,
                filter: ""
            },
            {
                dataField: 'star_rate',
                text: 'Star Rating',
                sort: false,
                filter: ""
            },
            {
                dataField: 'pub_fare',
                text: 'Published Fare',
                sort: false,
                filter: ""
            },
            {
                dataField: 'mkt_fare',
                text: 'Market Fare(Base Fare)',
                sort: false,
                filter: ""
            },
            {
                dataField: 'tax',
                text: 'Tax',
                sort: false,
                filter: ""
            },
            {
                dataField: 'svc_fee',
                text: 'Service Fee',
                sort: false,
                filter: ""
            },
            {
                dataField: 'cost',
                text: 'Cost',
                sort: false,
                filter: ""
            },
            {
                dataField: 'markup',
                text: 'Mark Up',
                sort: false,
                filter: ""
            },
            {
                dataField: 'disc',
                text: 'Discount Given',
                sort: false,
                filter: ""
            },
            {
                dataField: 'cccharge',
                text: 'CC Charge',
                sort: false,
                filter: ""
            },
        ];
        if (context.logindata.region_type=="India"){
         var columnData = [
                {
                    dataField: 'cgst',
                    text: 'CGST',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'sgst',
                    text: 'SGST',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'igst',
                    text: 'IGST',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'tds',
                    text: 'TDS',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'selprice',
                    text: 'Selling Price',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'profit',
                    text: 'Profit',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'sp_req',
                    text: 'Special Request',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'agent_name',
                    text: 'Staff Name',
                    sort: false,
                    filter: ""
                }
            ];
            if(context.logindata.region_type=="India" && state.aplTo==="TA"){
                 columnData = [
                    {
                        dataField: 'cgst',
                        text: 'CGST',
                        sort: false,
                        filter: ""
                    },
                    {
                        dataField: 'sgst',
                        text: 'SGST',
                        sort: false,
                        filter: ""
                    },
                    {
                        dataField: 'igst',
                        text: 'IGST',
                        sort: false,
                        filter: ""
                    },
                 //    {
                 //        dataField: 'tds',
                 //        text: 'TDS',
                 //        sort: false,
                 //        filter: ""
                 //    },
                    {
                        dataField: 'selprice',
                        text: 'Selling Price',
                        sort: false,
                        filter: ""
                    },
                    {
                        dataField: 'profit',
                        text: 'Profit',
                        sort: false,
                        filter: ""
                    },
                    {
                        dataField: 'sp_req',
                        text: 'Special Request',
                        sort: false,
                        filter: ""
                    },
                    {
                        dataField: 'agent_name',
                        text: 'Staff Name',
                        sort: false,
                        filter: ""
                    }
                ]
            }
            columns.push(...columnData)
        } else {
            var columnData = [
                {
                    dataField: 'selprice',
                    text: 'Selling Price',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'profit',
                    text: 'Profit',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'sp_req',
                    text: 'Special Request',
                    sort: false,
                    filter: ""
                },
                {
                    dataField: 'agent_name',
                    text: 'Staff Name',
                    sort: false,
                    filter: ""
                }
            ]
            columns.push(...columnData)
        }
    }
    else {
        var columns = [
            {
                dataField: 'region_type',
                text: "Region Type",
                sort: false,
                filter: ""
            },
            {
                dataField: 'Branch',
                text: 'Branch',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custnm',
                text: 'Company',
                sort: false,
                filter: ""
            },
            {
                dataField: 'currency',
                text: 'Currency',
                sort: false,
                filter: ""
            },
            {
                dataField: 'doc_date',
                text: 'Document Date gg',
                sort: false,
                filter: ""
            },
            {
                dataField: 'hotel_nm',
                text: 'Hotel Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'supplier_no',
                text: 'Supplier Conf #',
                sort: false,
                filter: ""
            },
            {
                dataField: 'hcno',
                text: 'Hotel Conf #',
                sort: false,
                filter: ""
            },
            {
                dataField: 'c_center',
                text: 'Cost center',
                sort: false,
                filter: ""
            },
            {
                dataField: 'bkr_no',
                text: 'BKR No.',
                sort: false,
                filter: ""
            },
            {
                dataField: 'dcmntno',
                text: 'Document No.',
                sort: false,
                filter: ""
            },
            // {
            //     dataField: 'supplier',
            //     text: 'Supplier',
            //     sort: false,
            //     filter: ""
            // },
            {
                dataField: 'saleschnl',
                text: 'Sales Channel',
                sort: false,
                filter: ""
            },
            {
                dataField: 'servicetype',
                text: 'Type of Services',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custcode',
                text: 'Customer Code',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custnm',
                text: 'Customer Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'custgrp',
                text: 'Customer Group',
                sort: false,
                filter: ""
            },
            {
                dataField: 'agent_name',
                text: 'Agent Name',
                sort: false,
                filter: ""
            },
            // {
            //     dataField: 'ticketno',
            //     text: 'Ticket Number',
            //     sort: false,
            //     filter: ""
            // },
            {
                dataField: 'pass_name',
                text: 'Passenger Name',
                sort: false,
                filter: ""
            },
            {
                dataField: 'ptype',
                text: 'Pax Type',
                sort: false,
                filter: ""
            },
            {
                dataField: 'nopax',
                text: 'No of PAX',
                sort: false,
                filter: ""
            },
            {
                dataField: 'ticketst',
                text: 'Status',
                sort: false,
                filter: ""
            },
            {
                dataField: 'destination',
                text: 'Destination',
                sort: false,
                filter: ""
            },
            {
                dataField: 'country',
                text: 'Country',
                sort: false,
                filter: ""
            },
            {
                dataField: 'non',
                text: 'No. Of Nights',
                sort: false,
                filter: ""
            },
            {
                dataField: 'star_rate',
                text: 'Star Rating',
                sort: false,
                filter: ""
            },
            {
                dataField: 'pub_fare',
                text: 'Published Fare',
                sort: false,
                filter: ""
            },
            {
                dataField: 'mkt_fare',
                text: 'Market Fare(Base Fare)',
                sort: false,
                filter: ""
            },
            {
                dataField: 'tax',
                text: 'Tax',
                sort: false,
                filter: ""
            },
            {
                dataField: 'svc_fee',
                text: 'Service Fee',
                sort: false,
                filter: ""
            },
            {
                dataField: 'cost',
                text: 'Cost',
                sort: false,
                filter: ""
            },
            {
                dataField: 'markup',
                text: 'Mark Up',
                sort: false,
                filter: ""
            },
            {
                dataField: 'disc',
                text: 'Discount Given',
                sort: false,
                filter: ""
            },
            {
                dataField: 'cccharge',
                text: 'CC Charge',
                sort: false,
                filter: ""
            },
        ];
        if (context.logindata.region_type=="India"){
            var columnData = [
                   {
                       dataField: 'cgst',
                       text: 'CGST',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'sgst',
                       text: 'SGST',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'igst',
                       text: 'IGST',
                       sort: false,
                       filter: ""
                   },
                //    {
                //        dataField: 'tds',
                //        text: 'TDS',
                //        sort: false,
                //        filter: ""
                //    },
                   {
                       dataField: 'selprice',
                       text: 'Selling Price',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'profit',
                       text: 'Profit',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'sp_req',
                       text: 'Special Request',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'agent_name',
                       text: 'Staff Name',
                       sort: false,
                       filter: ""
                   }
               ]
               columns.push(...columnData)
           } else {
               var columnData = [
                   {
                       dataField: 'selprice',
                       text: 'Selling Price',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'profit',
                       text: 'Profit',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'sp_req',
                       text: 'Special Request',
                       sort: false,
                       filter: ""
                   },
                   {
                       dataField: 'agent_name',
                       text: 'Staff Name',
                       sort: false,
                       filter: ""
                   }
               ]
               columns.push(...columnData)
           }
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];
    if (state.userType == "att_user") {
        var csvHeaders = [
            { label: 'Region Type', key: 'region_type' },
            { label: 'Branch', key: 'Branch' },
            { label: 'Company', key: 'custnm' },
            { label: 'Currency', key: 'currency' },
            { label: 'Document Date', key: 'doc_dateExcel' },
            { label: 'Hotel Name', key: 'hotel_nm' },
            { label: 'Supplier Conf #', key: 'supplier_no' },
            { label: 'Hotel Conf #', key: 'hcno' },
            { label: 'Cost center', key: 'c_center' },
            { label: 'BKR No.', key: 'bkr_no' },
            { label: 'Document No', key: 'dcmntno' },
            { label: 'Supplier', key: 'supplier' },
            { label: 'Sales Channel', key: 'saleschnl' },
            { label: 'Type of Service', key: 'servicetype' },
            { label: 'Customer Code', key: 'custcode' },
            { label: 'Customer Name', key: 'custnm' },
            { label: 'Customer Group', key: 'custgrp' },
            { label: 'Agent Name', key: 'agent_name' },
            { label: 'Ticket Number', key: 'ticketno' },
            { label: 'Passenger Name', key: 'pass_name' },
            { label: 'Pax Type', key: 'ptype' },
            { label: 'No of Pax', key: 'nopax' },
            { label: 'Ticket Status', key: 'ticketst' },
            { label: 'Destination', key: 'destination' },
            { label: 'Country', key: 'country' },
            { label: 'No. Of Nights', key: 'non' },
            { label: 'Star Rating', key: 'star_rate' },
            { label: 'Published Fare', key: 'pub_fare' },
            { label: 'Market Fare(Base Fare)', key: 'mkt_fare' },
            { label: 'Tax', key: 'tax' },
            { label: 'Service Fee', key: 'svc_fee' },
            { label: 'Cost', key: 'cost' },
            { label: 'Markup', key: 'markup' },
            { label: 'Discount Given', key: 'disc' },
            { label: 'CC Charge', key: 'cccharge' },
        ]
        if(context.logindata.region_type=="India"){
        var exportData = [
            { label: 'CGST', key: 'cgst' },
            { label: 'SGST', key: 'sgst' },
            { label: 'IGST', key: 'igst' },
            { label: 'TDS', key: 'tds' },
            { label: 'Selling Price', key: 'selprice' },
            { label: 'Profit', key: 'profit' },
            { label: 'Special Request', key: 'sp_req' },
            { label: 'Staff Name', key: 'agent_name' }]
            csvHeaders.push(...exportData)
        }else{
        var exportData= [
            { label: 'Selling Price', key: 'selprice' },
            { label: 'Profit', key: 'profit' },
            { label: 'Special Request', key: 'sp_req' },
            { label: 'Staff Name', key: 'agent_name' }   
            ]
            csvHeaders.push(...exportData)
        }
    }
    else {
        var csvHeaders = [
            { label: 'Region Type', key: 'region_type' },
            { label: 'Branch', key: 'Branch' },
            { label: 'Company', key: 'custnm' },
            { label: 'Currency', key: 'currency' },
            { label: 'Document Date', key: 'doc_dateExcel' },
            { label: 'Hotel Name', key: 'hotel_nm' },
            { label: 'Supplier Conf #', key: 'supplier_no' },
            { label: 'Hotel Conf #', key: 'hcno' },
            { label: 'Cost center', key: 'c_center' },
            { label: 'BKR No.', key: 'bkr_no' },
            { label: 'Document No', key: 'dcmntno' },
            // { label: 'Supplier', key: 'supplier' },
            { label: 'Sales Channel', key: 'saleschnl' },
            { label: 'Type of Service', key: 'servicetype' },
            { label: 'Customer Code', key: 'custcode' },
            { label: 'Customer Name', key: 'custnm' },
            { label: 'Customer Group', key: 'custgrp' },
            { label: 'Agent Name', key: 'agent_name' },
            { label: 'Ticket Number', key: 'ticketno' },
            { label: 'Passenger Name', key: 'pass_name' },
            { label: 'Pax Type', key: 'ptype' },
            { label: 'No of Pax', key: 'nopax' },
            { label: 'Ticket Status', key: 'ticketst' },
            { label: 'Destination', key: 'destination' },
            { label: 'Country', key: 'country' },
            { label: 'No. Of Nights', key: 'non' },
            { label: 'Star Rating', key: 'star_rate' },
            { label: 'Published Fare', key: 'pub_fare' },
            { label: 'Market Fare(Base Fare)', key: 'mkt_fare' },
            { label: 'Tax', key: 'tax' },
            { label: 'Service Fee', key: 'svc_fee' },
            { label: 'Cost', key: 'cost' },
            { label: 'Markup', key: 'markup' },
            { label: 'Discount Given', key: 'disc' },
            { label: 'CC Charge', key: 'cccharge' },
        ];
        if(context.logindata.region_type=="India"){
            var exportData = [
                { label: 'CGST', key: 'cgst' },
                { label: 'SGST', key: 'sgst' },
                { label: 'IGST', key: 'igst' },
                { label: 'TDS', key: 'tds' },
                { label: 'Selling Price', key: 'selprice' },
                { label: 'Profit', key: 'profit' },
                { label: 'Staff Name', key: 'agent_name' }]
                csvHeaders.push(...exportData)
            }else{
            var exportData= [
                { label: 'Selling Price', key: 'selprice' },
                { label: 'Profit', key: 'profit' },
                { label: 'Staff Name', key: 'agent_name' }   
                ]
                csvHeaders.push(...exportData)
            }
    }
    const paxDate = (dt) => {
        // if (toDate){
          let oneYearDate= new Date(toDate);
             oneYearDate.setFullYear(oneYearDate.getFullYear() -1);
          let today= new Date()
          let test= (today > oneYearDate ? oneYearDate : today) 
         return test
        // }else {
        // return new Date(dt.setFullYear(dt.getFullYear()));
        // }
    }
      const returnDate = ()=>{ 
        // if(fromDate){
        //   let beforecurrentDate = paxDate(new Date()).setDate(new Date().getDate() - 30)
        //   let fromdateadded = paxDate(fromDate).setDate(fromDate.getDate())
        //   if(fromdateadded < beforecurrentDate ){
          let oneYearDate= new Date(fromDate);
            oneYearDate.setFullYear(oneYearDate.getFullYear() + 1);
          let today= new Date()
          let test= (today > oneYearDate ? oneYearDate : today) 
              return test
        //   }
        //  else{
        //    return new Date()
        //  }
        // }
        // else{
        //   return new Date()
        // }
      } 
    return (

        <>
            <ReportMenu active='Hotel Services' />
            <Breadcrumb activePage="DSR Report" />
            <div className="container">
                <div className="cardMainlayout pt-0">
                    <HotelreportMenu active="Hotel DSR" />
                    <div>
                        <br />
                        {state.notiMessageShow &&
                            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                                dismissible> {state.notiMessage}</Alert>
                        }
                        <Form.Row>
                            {state.userType === "att_user" &&
                                <>
                                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                                        <Select
                                            options={applicabelTo}
                                            searchable={false}
                                            values={state.applToData}
                                            onChange={(value) => (setAplTo(value))}
                                        />
                                        <Form.Label>Applicable To</Form.Label>
                                    </Form.Group>
                                    {enabelATT &&
                                        <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                                            <Select
                                                options={state.branches}
                                                searchable={false}
                                                values={state.branchData}
                                                onChange={(value) => (selectBranch(value))}
                                            />
                                            <Form.Label>Branch<sup>*</sup></Form.Label>
                                        </Form.Group>
                                    }
                                    {enabelTA && ((state.userType === "att_user"&&state.aplTo=="TA") || state.userType === "att_user") &&
                                        <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                                            <Select
                                                options={state.companies}
                                                searchable={false}
                                                values={state.companyData}
                                                onChange={(value) => (setCompany(value))}
                                            />
                                            <Form.Label>Company<sup>*</sup></Form.Label>
                                        </Form.Group>
                                    }
                                </>
                            }
                            {state.userType === "ta_user" && state.companies.length !== 0 &&

                                <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                                    <Select
                                        options={state.companies}
                                        searchable={false}
                                        values={state.companyData}
                                        onChange={(value) => (setCompany(value))}
                                    />
                                    <Form.Label>Company</Form.Label>
                                </Form.Group>

                            }

                            <Form.Group as={Col} xs={3}>
                                <Form.Row>
                                    <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={date => setFromDate(date)}
                                            maxDate={ toDate ? toDate : new Date()}
                                            minDate={toDate && paxDate(toDate).setDate(toDate.getDate()) }
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            
                                        />
                                        <span className="calendar ezyIcon icon-newcalendar"></span>

                                        <Form.Label>From Date<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} className="selectplugin datepicright datePicker mb-0" controlId="toDate">
                                        <DatePicker
                                            selected={toDate}
                                            onChange={date => setToDate(date) + 1}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            minDate={fromDate && fromDate}                 
                                            maxDate= {returnDate()}

                                            className="datefield"
                                        />
                                       <span className="calendar ezyIcon icon-newcalendar"></span>
                                        <Form.Label>To Date<sup>*</sup></Form.Label>
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div className="text-right pb-0 buttonGrop  creat_absolute">
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            type="submit"
                            onClick={resetSearch}
                        > Reset</Button>
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={handleSearch}
                        > Search</Button>
                    </div>





                </div>
                {dsrExceldata.dsrReportExcelList!==undefined && dsrExceldata.dsrReportExcelList.length !== 0 &&
                    <div className="d-flex justify-content-end"><CoomonExportExcel data={dsrExceldata.dsrReportExcelList} headers={csvHeaders} show={true} name={"DSR_Report.csv"} /></div>
                }
                <div className="cardMainlayout p-0">
                    {isLoadingMore ? <InnerLoader /> : (<>
                        {dsrdata.dsrReportList.length !== 0 &&
                            <div className="tablescroll">
                                <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    classes="dsrTable filterTbl"
                                    data={dsrdata.dsrReportList}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    filter={filterFactory()}
                                    pagination={dsrdata.dsrReportList.length > 10 && dsrdata.dsrReportList.length <= 25 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }] }) :
                                        dsrdata.dsrReportList.length > 25 && dsrdata.dsrReportList.length <= 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }] }) :
                                            dsrdata.dsrReportList.length > 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }, { text: '50', value: 50 }] }) : ''}
                                />
                                {state.totals !== "" && <div className="cardMainlayout finalTotal"><div  className="react-bootstrap-table">
                                <table className="table table-bordered markupp filterTbl">
                                <thead>
                    <tr>
                  <th>&nbsp;</th>
                  <th>Published Fare</th>
                  <th>Market Fare(Base Fare)</th>
                  <th>Tax</th>
                  <th>Service Fee</th>
                  <th>Cost</th>
                  <th>Mark Up</th>
                  <th>Discount Given</th>
                  <th>CC Charge</th>
                  {context.logindata.region_type=="India" &&
                  <th>CGST</th>}
                  {context.logindata.region_type=="India" &&
                  <th>SGST</th>}
                  {context.logindata.region_type=="India" &&
                  <th>IGST</th>}
                  {context.logindata.region_type=="India" && state.userType !== "ta_user" && state.aplTo !=="TA" &&
                  <th>TDS</th>}
                  <th>Selling Price</th>
                  {((state.userType === "att_user" && state.aplTo=="TA") || state.userType === "ta_user")&&
                  <th>Supplier Discount</th>}
                  <th>Profit</th>

              </tr>
              </thead>


            <tr>
              <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_pub_fare.toFixed(2)}</td>
              <td>{state.totals.tot_mkt_fare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_svc_fee.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_cccharge.toFixed(2)}</td>
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_cgst}</td>}
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_sgst}</td>}
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_igst}</td>}
              {context.logindata.region_type=="India" && state.userType !== "ta_user" && state.aplTo !=="TA" &&
              <td>{state.totals.tot_tds}</td>}
              <td>{state.totals.tot_selprice !== null && state.totals.tot_selprice.toFixed(2)}</td>
              {((state.userType==="att_user"&&state.aplTo=="TA")|| state.userType === "ta_user" )&&
               <td>{Number(state.totals.tot_sup_disc).toFixed(2)}</td>}
              <td>{state.totals.tot_profit !== null && state.totals.tot_profit.toFixed(2)}</td>
            </tr>
            </table>
          </div></div>}
                            </div>
                            || dsrdata.dsrReportList.length === 0
                            && <NoRecord title="Please select applicable fields to populate the records" />}</>)}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default DSRReports