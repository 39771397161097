
import React, { useState, useEffect,useRef,useReducer, useContext} from 'react';
import { Button, OverlayTrigger, Tooltip, Modal,Form,Alert,Row, Col,ProgressBar } from "react-bootstrap";
import {Link, useHistory } from 'react-router-dom';
import useOutsideClick from '../../../../common/OutsideClick';
import { Context } from "../../../../../../App";
import 'react-input-range/lib/css/index.css';
import { searchRequest } from '../../../../bookingFlows/hotel/pages/operations';
import log from "loglevel";
import Utilities from '../../../../commonUtils/Utilities';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';
import Linkimage from '../../../../../assets/images/link.gif'
import {isNotNull} from '../../../../commonUtils/validators';
import ApplayMarkup from './ApplayMarkup';
import GuestReviews from './GuestReviews';
export const HTL_REQUEST = "htlRequest"
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const HTL_CTID = "ctid";

const initialState = {
    notiMessage:"", notiMessageShow:false, notiVarient:"danger",markp:0,showPop:false,isLoading:false,addMrkup:false,checkAvail:false,markupApplied:false,guestReviews:[],reviewObj:"",expRating:0,usrRating:0,response:""
  };
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
            ...state,      };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };


/**
 * @description:This function will load the selected Hotel results
 * @author: Lakshmi
 * @param {*}
 * @function Confirm details
 * @date : 10-02-2021
 */
function Hotel(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    //const [response, setResponse] = useState();
    const [htlContent, setHtlContent] = useState(props.contentResp);
    const [context, setContext] = useContext(Context);
    const [searchReq, setSearchReq] = useState(props.request);
    const [butype, setButype] = useState("");
    const ref = useRef();
    useEffect(() => {
        if(searchReq!==undefined && !props.mapView){
            setButype(searchReq.butype);
            callSearchReq(searchReq);
        }else{
            state.response=props.searchResp
            dispatch({type: "checkAvail",payload: true})
        }
    }, [])

    useEffect(function() {
        if (props.appliedMarkupValue !==0 && props.appliedMarkupValue!== undefined){
            dispatch({type:'markp', payload: props.appliedMarkupValue})
            dispatch({type:'markupApplied', payload: true})
        }

    },[props.appliedMarkupValue])


    //Set the filter response
    useEffect(() => {
        if (isNotNull(props.filterResponse) && props.filterResponse.prpts.length!==0) {
            //setResponse(props.filterResponse);
            dispatch({type: "response",payload: props.filterResponse})
           // window.scrollTo(0, 0)
        }
    }, [props.filterResponse])



    //Converting Child Ages
    const convertChildAge=(roomsInfo)=>{
        var ages="";
        for(let val of roomsInfo){
            if(val.chdArray.length!==0){
            if(ages===""){
            if(val.chdArray.length===1){
                ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age}` ,'')
            }else{
                ages = val.chdArray.reduce((acc, curr) => `${acc}${curr.age},` ,'')
            }
            }else{
            if(val.chdArray.length===1){
                ages=ages+"|"+val.chdArray.reduce((acc, curr) => `${acc}${curr.age}` ,'')
            }else{
                ages=ages+"|"+val.chdArray.reduce((acc, curr) => `${acc}${curr.age},` ,'')
            }
            }
            }else{
                if(ages!==""){
                    ages=ages+"|"+"0";
                }else{
                    ages="0";
                }

            }
            }
            return ages;
    }

    const convertAdts=(roomsInfo)=>{
        var adts="";
        for(let val of roomsInfo){
            if(adts===""){
                adts=val.adt;
            }else{
                adts=adts+","+val.adt;
            }
        }
        return adts;
    }

    //=====================================
    // Calling the Search request.state.req
    //=====================================
    const callSearchReq = (request) => {
        try {
            localStorage.removeItem(HTL_MARKUP);
            localStorage.removeItem(HTL_CHD_AGES);
            localStorage.removeItem(HTL_REQUEST);
            let chdArray = request.roomsInfo.filter((obj) => obj.chd!==undefined && obj.chd);
            var count = 0;
            for(let val of chdArray){
                if(count===0){
                count=val.chd;
                }else{
                count=count+val.chd;
                }
            }
            var ages="";
            var adts="";
            ages=convertChildAge(request.roomsInfo);
            adts=convertAdts(request.roomsInfo);
            var randomStr = Math.random().toString(36).slice(2);
            var requrl="";
            var type="";
            if(request.htlData[0].type==="city"){
                type="cityId";
            }else if(request.htlData[0].type==="landmark"){
                type="landmarkId";
            }else if(request.htlData[0].type==="area"){
                type="areaId";
            }
            if(request.htlData[0].type!=="property"){
            requrl = "cin=" + request.cin + "&cout=" + request.cout + "&rms=" + request.roomsInfo.length + "&adt=" + adts + "&chd="+ count + "&echo=" + randomStr + "&cid=" + request.cid + "&cha=" + "B2B" + "&cur=" + request.cur + "&bid=" + request.bid + "&rty=" + type + "&prtyp=" + "hotelOnely" + "&country_code=" + "AE" + "&ctid=" + request.htlData[0]._id + "&chdages="+ages + "&package="+request.package +"&butype=" + request.butype + "&region_type=" + request.region_type + "&otilacityid="+request.htlData[0].otillaCityId+"&expediacityid="+request.htlData[0].expediaCityId;
            }else{
            requrl = "cin=" + request.cin + "&cout=" + request.cout + "&rms=" + request.roomsInfo.length + "&adt=" + adts + "&chd="+ count + "&echo=" + randomStr + "&cid=" + request.cid + "&cha=" + "B2B" + "&cur=" + request.cur + "&bid=" + request.bid + "&rty=" + "prid" + "&prtyp=" + "hotelOnely" + "&country_code=" + "AE" + "&ctid=" + request.htlData[0]._id + "&chdages="+ages + "&package="+request.package + "&prsearch=" + request.htlData[0].externalId +"&butype=" + request.butype + "&region_type=" + request.region_type + "&otilacityid=" + request.htlData[0].otilaCityId + "&otilaprsearch=" + request.htlData[0].otilapid+"&expediaprsearch="+request.htlData[0].expediapid+"&expediacityid="+request.htlData[0].expediaCityId;
            }
            searchRequest(requrl).then((response) => {
                if (response.data.suc){
                    state.response=response.data.data;
                    localStorage.setItem(HTL_CHD_AGES,ages)
                    props.hotelresponse(response.data.data);
                    dispatch({type: "checkAvail",payload: true})
                } else {
                    props.norRsponse();
                }
            });
        } catch (e) {
            log.error('Exception occured in handleChange function---' + e);
        }
    }

    const chooseRoom = (htlId,isPackage,sup, enblestandalone) => () => {
        let selHtl=[]
        if(sup===0){
            selHtl= state.response.prpts.filter(obj => Number(obj.pid) === Number(htlId));
        }else{
            selHtl= state.response.prpts.filter(obj => obj.pid === htlId);
        }
        selHtl[0].type=state.response.type!==undefined?state.response.type:"";
        selHtl[0].sgst=state.response.sgst!==undefined?state.response.sgst:0;
        selHtl[0].cgst=state.response.cgst!==undefined?state.response.cgst:0;
        selHtl[0].igst=state.response.igst!==undefined?state.response.igst:0;
        selHtl[0].tds_per=state.response.tds_per!==undefined?state.response.tds_per:0;
         let htl = JSON.stringify(selHtl[0]);
         //selHtl.request=searchReq;
         if (enblestandalone){
            searchReq.package=0
         }
         if (enblestandalone==undefined){
            searchReq.package=1
         }
        localStorage.setItem(HTL_REQUEST,JSON.stringify(searchReq))
        localStorage.setItem(HTL_MARKUP,state.markp)
        localStorage.setItem(HTL_CTID,state.response.ctid)
        localStorage.setItem("HTL_OTILACITYID",selHtl[0].otilacityid)
        let comid = sessionStorage.getItem("CMPID")
        var w = window.open("/ezytrip/bookings/hotel/review");
        w.htl = htl;
        w.cmpid = comid;
        w.isPackage = isPackage;
    }

    useOutsideClick(ref, () => {
        if (state.addMrkup){
            dispatch({type: "addMrkup",payload: false})
        }

      });
    //Show Hotel Image
    const showHtlImage = (image) => {
        if(image!==undefined){
        let imgs=image?.links.length>0?image.links["350Px"]:null
        if(imgs?.href!==undefined){
            return imgs.href;
        } else{
            return null;
        }
    } else{
        return null;
     }
    }

    const handleCLClose = () => {
       return window.location="/ezytrip/dashboard"
    }




    const openMarkUp = () => {
        if (state.addMrkup) {
            dispatch({type: "addMrkup",payload: false})
        } else {
            dispatch({type: "addMrkup",payload: true})
        }
      }

      const openReviewPopup=(obj)=>()=>{
        dispatch({ type: 'reviewObj', payload: obj })
        dispatch({ type: 'expRating', payload: obj.cont.expediareview})
        dispatch({ type: 'usrRating', payload: obj.cont.urat })
        dispatch({ type: 'reviewShow', payload: true })
      }

      const handleClose = () => {
        dispatch({ type: 'reviewShow', payload: false })
      }
      const updateMarkup = (data) => {
        dispatch({ type: 'markp', payload: data })
        props.markupValue(data)
        dispatch({type: "markupApplied",payload: true})
      }

    return (
        <>
        <div className="hotelResult">
        {state.notiMessageShow &&
         <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
         }
         {state.checkAvail
         && context.logindata.roles && context.logindata.roles.split(",").includes('O_N_F_M')
          &&
        <div className={"mrkup " + (state.addMrkup ? 'open':'') } ref={ref} >
        <span onClick={openMarkUp}><span className="ezyIcon markup icon-arrowDown"></span></span>
        {
        <div className={"admarkup " + (state.addMrkup ? 'active':'') }>
        {/* {state.markp+"h"+state.addMrkup+"A"+props.appliedMarkupValue} */}
        <ApplayMarkup sendMarkup={updateMarkup} markVal={state.markp}/>
            </div>
            }
        </div>
         }

                {!state.checkAvail  && htlContent && htlContent.list.length > 0 && htlContent.list.map((htl, index) => (
                    <React.Fragment key={index}>
                        <>
                            {htl !== null && htl!==undefined &&
                                <div className="result listView  boxShadow d-flex mb-4 ">
                                    <div className="htlImage icons">
                                        {htl!==undefined && htl.image!==undefined &&
                                            <img
                                            src={showHtlImage(htl.image[0])}
                                            className="htlImg"
                                            title={htl.category.name}
                                            alt="Hotel"
                                        />
                                        }
                                        {htl.category.name !== "Hotel" ? <sapn class="typeHotel">{htl.category.name} </sapn> : ""}
                                    </div>
                                    <div className="hotelInfo">
                                        <div className="propertyName"> <p>{htl.name}</p>
                                            <div className="extDetails">
                                                {htl.ratings !== undefined && htl.ratings !== 0 &&
                                                    <>
                                                        <span className="stars">
                                                            {Utilities.getHotelStartRating(htl.ratings).map((star, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <span className="ezyIcon icon-star"></span>
                                                                </React.Fragment>
                                                            ))}
                                                        </span>
                                                    </>
                                                }
                                                  <div className="toolTipInfo hService">
                                                <div className="serviceType">
                                                <span className="rcmd"><span className="ezyIcon icon-recommended"></span></span>
                                                </div>
                                                <div className="toolTipCUstoom">
                                                    <span>Recommended</span>
                                                </div>
                                            </div>



                                            </div>
                                        </div>
                                        <p className="propertyAdress"><span className="ezyIcon icon-map"></span>{htl.address.line1}, {htl.address.city}, {htl.address.countryCode}</p>
                                        <p className="propertyDistance"><span className="ezyIcon icon-direcion"></span>{htl.attractions.nearby}</p>
                                        <div className="extraDetails">

                                        </div>
                                        <div className="services">
                                        <div className="toolTipInfo hService">
                                                <div className="serviceType ezyIcon icon-wifi">
                                                </div>
                                                <div className="toolTipCUstoom">
                                                    <span>wifi</span>
                                                </div>
                                            </div>

                                              <div className="toolTipInfo hService">
                                              <div className="serviceType ezyIcon icon-Swimming-Pool">
                                              </div>
                                              <div className="toolTipCUstoom">
                                                  <span>Pool</span>
                                              </div>
                                          </div>

                                             <div className="toolTipInfo hService">
                                             <div className="serviceType ezyIcon icon-gym">
                                             </div>
                                             <div className="toolTipCUstoom">
                                                 <span>GYM</span>
                                             </div>
                                         </div>

                                               <div className="toolTipInfo hService">
                                               <div className="serviceType ezyIcon icon-bar">
                                               </div>
                                               <div className="toolTipCUstoom">
                                                   <span>Bar</span>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                    <div className="priceInfo">
                                    <div className="review">
                                    <div className="stopsLoader4"></div>
                                    </div>
                                    <div className="price">
                                    <div className="stopsLoader4"></div>
                                    {/* <p className="nightPriceinfo">Price for all for 2 nights</p> */}
                                                <Button
                                                    size="xs"
                                                    className="choose mt-2"
                                                    variant="primary">
                                                    CHOOSE ROOM
                                                    <span className="ezyIcon icon-rightArrow"></span>
                                                </Button>
                                    </div>
                                    </div>
                                </div>
                            }
                        </>
                    </React.Fragment>
                ))}

                  {state.checkAvail && state.response!=="" && state.response.prpts && state.response.prpts.length > 0 && state.response.prpts.map((htl, index) => (
                    <React.Fragment key={index}>
                        <>
                            {htl !== null &&
                                <div className="result listView  boxShadow d-flex mb-4 ">
                                    <div className="htlImage icons">
                                        {htl.cont!==undefined && htl.cont.image!==undefined && htl.cont.image!==null &&
                                            <img
                                            src={htl.cont.image}
                                            //className="htlImg"
                                            title={htl.cont.catnm}
                                            alt="Hotel"
                                            onClick={searchReq.package!==undefined && searchReq.package===1?chooseRoom(htl.pid,true,htl.hs):chooseRoom(htl.pid,false,htl.hs)}
                                        />
                                        }
                                        {htl.cont.catnm!==undefined && htl.cont.catnm!=="" &&
                                        <sapn class="typeHotel">{htl.cont.catnm} </sapn>
                                        }
                                    </div>
                                    <div className="hotelInfo">
                                        <div className="propertyName"> <Link onClick={searchReq.package!==undefined && searchReq.package===1?chooseRoom(htl.pid,true,htl.hs):chooseRoom(htl.pid,false,htl.hs)}>{htl.cont.name}</Link>
                                            <div className="extDetails">
                                                {htl.cont.ratings !== undefined && htl.cont.ratings !== 0 &&
                                                    <>
                                                        <span className="stars">
                                                        <span className={"star star"+Math.ceil(htl.cont.ratings)}></span>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {htl.cont!==undefined && htl.cont.city!==undefined && <p className="propertyAdress"><span className="ezyIcon map icon-map"></span>{htl.cont.line1}, {htl.cont.city}, {htl.cont.countryCode}</p>}
                                        <div className="review">
                                        {htl.cont!==undefined && htl.cont.urat!==undefined && htl.cont.urat !=="" && htl.cont.expediareview!==0 &&
                                                <p onClick={openReviewPopup(htl)} className="xpRating">
                                                {htl.cont.urat !=="" &&
                                                 <>
                                                 <span className="rating5"><em>{htl.cont.urat + "/5"}</em> {htl.usrst}</span>
                                                 </>
                                                }
                                                {htl.cont.expediareview!==0 &&
                                                <>
                                                 <span className="icons xpIcon"></span>
                                                 <span><span>{htl.cont.expediareview} Reviews</span></span>
                                                 </>
                                                }
                                                </p>}
                                           </div>

                                        {htl.cont.nearby!==undefined && <p className="propertyDistance"><span className="ezyIcon diration icon-direcion"></span>{htl.cont.nearby}</p>}
                                        <div className="extraDetails">
                                        {htl.brdbs!==undefined &&
                                        <>
                                          <span className="brdType">{htl.brdbs.toString()}</span>
                                        </>
                                        }
                                        </div>
                                        <Row className="servicelist">
                                        <Col xs={7}  className="services">
                                         {htl.cont.ha!==undefined && htl.cont.ha.length!==0 &&
                                          <>
                                          {htl.cont.ha.includes("WIFI") &&
                                             <div className="toolTipInfo hService">
                                                <div className="serviceType">
                                                    <span className="ezyIcon wifi icon-wifi"></span>
                                                </div>
                                                <div className="toolTipCUstoom">
                                                    <span>Wifi</span>
                                                </div>
                                            </div>
                                           }
                                           {htl.cont.ha.includes("POOL") &&
                                              <div className="toolTipInfo hService">
                                              <div className="serviceType">
                                                  <span className="ezyIcon wifi icon-Swimming-Pool"></span>
                                              </div>
                                              <div className="toolTipCUstoom">
                                                  <span>Pool</span>
                                              </div>
                                          </div>
                                           }
                                           {htl.cont.ha.includes("GYM") &&
                                             <div className="toolTipInfo hService">
                                             <div className="serviceType">
                                                 <span className="ezyIcon wifi icon-gym"></span>
                                             </div>
                                             <div className="toolTipCUstoom">
                                                 <span>GYM</span>
                                             </div>
                                         </div>
                                           }
                                           {htl.cont.ha.includes("BAR") &&
                                               <div className="toolTipInfo hService">
                                               <div className="serviceType">
                                                   <span className="ezyIcon wifi icon-bar"></span>
                                               </div>
                                               <div className="toolTipCUstoom">
                                                   <span>Bar</span>
                                               </div>
                                           </div>
                                           }
                                          </>
                                         }
                                         </Col>
                                           {htl.refundable && context.logindata.roles.includes('O_P_B') && htl.hs===0 &&
                                        <Col xs={5} className="onholdHotel" >
                                        <strong className="brdType"><span className="ezyIcon wifi icon-time"></span>Hold Booking</strong>
                                        </Col>
                                        }
                                        </Row>
                                    </div>
                                    <div className="priceInfo">
                                          {/* Total price */}
                                            <div className="price">
                                                    {/* Hot deal price */}
                                           <div className="dealparts">

                                            {htl.package!==undefined && htl.package &&
                                            <div className="pkgRate"><span className="ezyIcon package icon-Package"></span> <span>Package Rate</span></div>
                                            }
                                            {htl.ezy_deal!==undefined && htl.ezy_deal &&
                                            <div className="deals ezy">Eztrip Deal </div>
                                            }
                                            {htl.disc!=="0" && htl.disc!==0 &&
                                            <div className="deals">
                                                Hot Deal
                                            </div>
                                            }
                                            </div>
                                            <div className="priceSec">
                                            {htl.disc!=="0" && htl.disc!==0 && <div>
                                                {!state.markupApplied &&
                                               <p className="blockPrice"> <strong className="offers"><em>{state.response.cur}</em>{<FormateCurrency value={parseFloat(htl.rdwtf)} />} </strong></p>
                                                }
                                                {state.markupApplied && state.markp!==0 &&
                                               <p className="blockPrice"><strong className="offers"><em>{state.response.cur}</em>{<FormateCurrency value={parseFloat(htl.rdwtf) +  parseFloat(state.markp)} />}</strong></p>
                                                }</div>}
                                                <div>
                                                   {!state.markupApplied &&
                                                     <p className="blockPrice"><strong><em>{state.response.cur}</em>{<FormateCurrency value={parseFloat(htl.rtf) - parseFloat(htl.com_disc)} />}</strong></p>
                                                    }
                                                    {state.markupApplied && state.markp!==0 &&
                                                     <p className="blockPrice"><strong><em>{state.response.cur}</em>{<FormateCurrency value={parseFloat(htl.rtf) +  parseFloat(state.markp) - parseFloat(htl.com_disc)} />}</strong></p>
                                                      }</div>
                                                    {context.logindata.roles.includes('E_H_C' ) &&
                                                      <span className="toolTipInfo pricebrk">
                                                  <span className="ezyIcon info icon-info ml-1"></span>
                                                  <div className="toolTipCUstoom fairbrkUp hotelFairbrkup">
                                                     <div className="toolTipCont">
                                                       <div className="list"><span>Total Price</span>
                                                       <span>{context.logindata.cur} {<FormateCurrency value={parseFloat(htl.rtf) + parseFloat(state.markp)} />} </span>
                                                       </div>
                                                       <div className="list"><span>Discount</span> <span>{context.logindata.cur} {htl.com_disc}</span></div>
                                                       <div className="list sumTotall"><span>Total Amount</span>
                                                       <span>{context.logindata.cur} {<FormateCurrency value={parseFloat(htl.rtf) + parseFloat(state.markp) - parseFloat(htl.com_disc)} />} </span>
                                                       </div>
                                                       <div className="list"><span>Commission</span> <span>{state.response.cur} {htl.market_fee}</span></div>
                                                      </div>
                                                 </div>
                                                 </span>
                                                    }
                                                  </div>
                                                <p className="nightPriceinfo">Total price for all {searchReq.nights} nights</p>
                                                <Button
                                                    size="xs"
                                                    onClick={searchReq.package!==undefined && searchReq.package===1?chooseRoom(htl.pid,true, undefined, true):chooseRoom(htl.pid,false,htl.hs)}
                                                    className="choose"
                                                    variant="primary">
                                                    CHOOSE ROOM
                                                    <span className="ezyIcon icon-rightArrow ml-1"></span>
                                                </Button>
                                               <div className={htl.available_rooms < 9  ? "chooseBtn withroom": "chooseBtn"}>
                                                {htl.available_rooms > 0 &&  htl.available_rooms !==undefined && htl.available_rooms < 9  &&
                                             <div className="roomleft">
                                                 <span className="ezyIcon icon-Door"></span>

                                             {htl.available_rooms + " Rooms Left"}
                                             </div>
                                             }
                                                <br/>
                                                {searchReq.package!==undefined && searchReq.package===0 &&
                                                <span className='LinkImage'><Link onClick={chooseRoom(htl.pid,true,htl.hs)}><span><img src={Linkimage} /></span><span className='textImage'>View Package Rates</span></Link></span>
                                                }
                                                {searchReq.package!==undefined && searchReq.package===1 && htl.supplier!=1 &&
                                                <span className='LinkImage'><Link onClick={chooseRoom(htl.pid,false,htl.hs)}><span><img src={Linkimage} /></span><span className='textImage'>View Standalone Rates</span></Link></span>
                                                }
                                               </div>
                                            </div>



                                    </div>

                                </div>
                            }
                        </>
                    </React.Fragment>
                ))}
            </div>

        <Modal
        show={state.reviewShow}
        size="lg"
        onHide={handleClose}
        className="importPNRMdl xpreview"
        backdrop="static"
        keyboard={false}>
            <Modal.Header closeButton>
          <Modal.Title>Guest Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h5> <Button variant="outline-dark" onClick={handleClose}>
          <span className="ezyIcon icon-close"></span>
          </Button></h5> */}
          <div className="guestReviews">
        {state.reviewObj!==undefined &&
         <GuestReviews  reviewObj={state.reviewObj} reviewCount={state.expRating} urat={state.usrRating} page={"Results"}/>
        }
         </div>
        </Modal.Body>
      </Modal>
        </>
    )
}


export default Hotel;
