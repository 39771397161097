import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Alert, Col, Form } from "react-bootstrap";
import Select from "react-dropdown-select";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../../App";
import {
  categoryDataList,
  cities,
  countries,
  currencies,
  getBranchList,
  getCompanyTAList,
  getCustId,
  rolesList,
  stdCodes,
} from "../../../../components/adminstration/utils";
import Button from "../../../common/buttons/Button";
import PickList from "../../../common/pickList/PickList";
import {
  characterNumberValidation,
  characterValidation,
  companyCharacterValidation,
  emailValidation,
  mobileNumberValidation,
} from "../../../commonUtils/FormValidations";
import ContactInfo from "../../commonContactInfo/index";
import { CompanyContext } from "./CompanyMain";
import { saveCompany, updateCompany } from "./operations";
import FileUpload from "../../../commonUtils/FileUpload";
import Tabs from "../../../common/tabs/Tabs";
import ShowFlightInfo from "./ShowFlightInfo";
import ShowHotelInfo from "./ShowHotelInfo";
import SalesChannel from "../../common/SalesChannel";
import { isNotNull } from "../../../commonUtils/validators";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
export const COUNTRY_DATA="COUNTRY_DATA";

/**
 * Initial State Declaration
 */
const initialState = {
  isItineraryLoading:false,
  id: undefined,
  enblCompny:true,
  cname: "",
  cpid: 0,
  bkoid: "",
  ctyp: "",
  ccur: "",
  oid: 0,
  ctid: "",
  ucad: true,
  act: false,
  uem: "",
  ufn: "",
  uln: "",
  uph: "",
  uphc: "93",
  logo: "",
  usrc: true,
  cadd: "",
  cnt: 0,
  cty: 0,
  ptaName: "",
  updateBtn: false,
  currencyMenu: [],
  enableParentTa: [],
  stdCodeMenu: [],
  contacts: [],
  branchMenu: [],
  catMenu: [],
  countryMenu: [],
  cityMenu: [],
  bcurency: "",
  branchList: [],
  enableRole: false,
  selBranch: [],
  companies: [],
  enableCompany: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  enableUpdate: false,
  seleAgent: [],
  selCompany: [],
  selCurrency: [],
  selCategory: [],
  selPhone: [],
  selCountry: [],
  selCity: [],
  selectedRoles: [],
  compType: false,
  BranchSel: false,
  compCur: false,
  catError: false,
  clientError: false,
  payError: false,
  busError: false,
  roleError: false,
  supError: false,
  htlSup: false,
  selAgent: [],
  groups: [],
  payment: [],
  business: [],
  selCategory: [],
  cntError: false,
  ctyError: false,
  ccError: false,
  bkFalse: false,
  comNameFalse: false,
  uemFalse: false,
  fnFalse: false,
  lnFalse: false,
  uphFalse: false,
  contactValidation: {},
  saveContacts: false,
  bkoidLength: false,
  logo_url: "",
  selProfiles: [],
  enableB2C: false,
  companyUrl: "",
  profileType: "",
  cprefix: "",
  fltSuppliers: [
    { value: 0, label: "Amadeus"},
    { value: 1, label: "Travel Fusion" },
    { value: 2, label: "Air Arabia" },
    { value: 3, label: "Jazeera" },
    { value: 4, label: "Indigo" },
    { value: 5, label: "Flydubai" },
    { value: 6, label: "Salam Air"},
    { value: 7, label: "Riya Travel"}
  ],
  htlsuppliers: [
    { value: 0, label: "Expedia" },
    { value: 1, label: "Otilla" }
  ],
  fltSupNames: [],
  htl_supNames: [],
  enableFlight: false,
  enableHotel: false,
  region_type:"",
  selRegion:[],
  edit_salesChanel:""
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        id: undefined,
        cname: "",
        cpid: 0,
        bkoid: "",
        ctyp: "",
        ccur: "",
        oid: 0,
        ctid: 0,
        ucad: true,
        act: false,
        uem: "",
        ufn: "",
        uln: "",
        uph: "",
        uphc: "93",
        logo: "",
        usrc: true,
        cadd: "",
        cnt: 0,
        cty: 0,
        ptaName: "",
        updateBtn: false,
        enableParentTa: [],
        contacts: [],
        branchMenu: [],
        catMenu: [],
        cityMenu: [],
        enableRole: false,
        enableCompany: false,
        notiMessage: "",
        notiMessageShow: false,
        notiVarient: "",
        enableUpdate: false,
        seleAgent: [],
        selCompany: [],
        selCurrency: [],
        selCategory: [],
        selPhone: [],
        selCity: [],
        groups: [],
        compType: false,
        BranchSel: false,
        compCur: false,
        catError: false,
        clientError: false,
        payError: false,
        busError: false,
        roleError: false,
        selAgent: [],
        groups: [],
        payment: [],
        business: [],
        selCategory: [],
        cntError: false,
        ctyError: false,
        ccError: false,
        bkFalse: false,
        comNameFalse: false,
        bkoidLength: false,
        uemFalse: false,
        fnFalse: false,
        lnFalse: false,
        uphFalse: false,
        contactValidation: {},
        saveContacts: false,
        selCountry: [],
        selBranch: [],
        enableFlight: false,
        enableHotel: false,
        supError: false,
        htlSup: false,
        selProfiles: [],
        enableB2C: false,
        companyUrl: "",
        profileType: "",
        cprefix: "",
        region_type:"",
        edit_salesChanel:"",
        fltSuppliers: [
          { value: 0, label: "Amadeus" },
          { value: 1, label: "Travel Fusion" },
          { value: 2, label: "Air Arabia" },
          { value: 3, label: "Jazeera" },
          { value: 4, label: "Indigo" },
          { value: 5, label: "Flydubai" },
          { value: 6, label: "Salam Air"}, 
          { value: 7, label: "Riya Travel"}
        ],
      };
    case "editCompany":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will creaet the Company or Agnencies with seletion of Company type
 * @author: Lakshmi
 * @param {*}
 * @function Company Creation
 */
function CompanyCreation(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dataState, setData] = useState({ data: [] });
  const [role, setRoleData] = useState({ roleData: [] });
  const [actState, setActive] = useState({ act: true });
  const [usrState, setUsrActive] = useState({ usrc: false });
  const [uscState, setUseActive] = useState({ ucad: true });
  const [value, setValue] = useState([]);
  const [roles, setRoles] = useState([]);
  const [context, setContext] = useContext(Context);
  const comapnyObj = useContext(CompanyContext);
  const [disableBtn, setDisableBtn] = useState(false);
  const [validated, setValidated] = useState(false);
  const [resetChild, setresetChild] = useState(false);
  const [definedServices, setDefinedServices] = useState("");
  const [regionTypes, setRegionTypes] = useState([]);
  const stdCodesMaster = require("../../../masterData/CountrySTDCodes.json");

  const groups = [
    { label: "ASU", value: "ASU" },
    { label: "Retail", value: "Retail" },
  ];
  const payment = [
    { label: "All", value: "all" },
    { label: "Manual", value: "manual" },
    { label: "Online", value: "online" },
  ];
  const business = [
    { label: "All", value: "all" },
    { label: "Flight", value: "flight" },
    { label: "Hotel", value: "hotel" },
  ];
  
  useEffect(() => {
    if (state.contacts !== undefined && state.contacts.length > 0) {
      setresetChild(false);
    }
  });
  const getSupplier = (supId) => {
    for (let sup of state.fltSuppliers) {
      if (sup.value === supId) {
        return sup.label;
      }
    }
  };
  const gethtlSupplier = (supId) => {
    for (let sup of state.htlsuppliers) {
      if (Number(sup.value) === supId) {
        return sup.label;
      }
    }
  };
  useEffect(() => {
    if (comapnyObj !== undefined && comapnyObj.data!==undefined) {
      setDefinedServices(comapnyObj.data.res);
      //Edit Suppliers
      if (comapnyObj.data.res.flt_sup.length !== 0) {
        let fltsups = [];
        for (let flt of comapnyObj.data.res.flt_sup) {
          let supname = getSupplier(flt.sup_id);
          if (supname !== undefined) {
            fltsups.push({ value: Number(flt.sup_id), label: supname });
          }
        }
        dispatch({ type: "fltSupNames", payload: fltsups });
        if (fltsups.length !== 0) {
          dispatch({ type: "enableFlight", payload: true });
        }
      }
      if (comapnyObj.data.res.htl_sup.length !== 0) {
        let htlsups = [];
        for (let htl of comapnyObj.data.res.htl_sup) {
          let htlsupname = gethtlSupplier(htl.sup_id);
          if (htlsupname !== undefined) {
            htlsups.push({ value: Number(htl.sup_id), label: htlsupname });
          }
        }
        dispatch({ type: "htl_supNames", payload: htlsups });
        dispatch({ type: "enableHotel", payload: true });
      }
      if(comapnyObj.data.res.region_type!==undefined){
        dispatch({ type: 'selRegion', payload:  [{"value":comapnyObj.data.res.region_type,"label":comapnyObj.data.res.region_type}]})
        dispatch({ type: "region_type", payload: comapnyObj.data.res.region_type });
      }
    }
    
    if (context.logindata.utype === "ta_user") {
      dispatch({ type: "ptaName", payload: context.logindata.cnm });
      dispatch({ type: "ccur", payload: context.logindata.cur });
      dispatch({ type: "ctyp", payload: "STA" });
      dispatch({ type: "bcurency", payload: context.logindata.bcur });
      dispatch({ type: 'selCurrency', payload:  [{"value":context.logindata.bcur,"label":context.logindata.bcur}]})
      dispatch({ type: "enableParentTa", payload: true });
      dispatch({ type: "bkoid", payload: context.logindata.cuscode });
    }
    if (comapnyObj!==undefined && comapnyObj.data !== undefined && comapnyObj.data.res.bid !== undefined) {
    if (comapnyObj.data.res.ctyp === "STA") {
      dispatch({ type: "enblCompny", payload: false });
      dispatch({ type: "bkoid", payload: comapnyObj.data.res.bkoid });
      getCompanyTAList(comapnyObj.data.res.bid, "TA")
        .then((response) => {
          if (response.data.suc) {
            response.data.res.map((company) => {
              if (company.value === comapnyObj.data.res.cpid) {
                let obj = {
                  label: company.label,
                  value: comapnyObj.data.res.cpid,
                };
                dispatch({ type: "selCompany", payload: [obj] });
              }
              let companyList = response.data.res.filter((obj)=>obj.sales_channel===obj.protype)
              dispatch({
                type: "companies",
                payload: companyList,
              });
              dispatch({ type: "enableCompany", payload: true });
            });
          }
        })
        .catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
    }
  }
  if (comapnyObj!==undefined && comapnyObj.data !== undefined && comapnyObj.data.res !== undefined && comapnyObj.data.res.region_type!==undefined) {
    let request="";
    if(context.logindata.is_admin){
      request = {
        "region_type": comapnyObj.data.res.region_type,
      };
    }else{
      request = {
        "region_type": comapnyObj.data.res.region_type,
        uid: context.logindata.uid
      };
    }
    AttCommonSerivce.loadBranches(request).then((response) => {
      if (response.data.suc) {
        dispatch({ type: "branchList", payload: response.data.res });
        if (
          comapnyObj.data !== undefined &&
          comapnyObj.data.res.bid !== undefined
        ) {
          response.data.res.map((branch) => {
            if (branch.value === Number(comapnyObj.data.res.bid)) {
              dispatch({ type: "bcurency", payload: branch.currency });
              dispatch({ type: 'selCurrency', payload:  [{"value":branch.currency,"label":branch.currency}]})
              dispatch({
                type: "selBranch",
                payload: [
                  { value: comapnyObj.data.res.bid, label: branch.label },
                ],
              });
            }
          });
        }
      }
    })
    .catch(function (error) {
      log.error("Exception occured in getBranchData function---" + error);
    });
  }
  
      const pyload = {
        type: context.logindata.utype,
        userId: context.logindata.uid,
        fetch: "mapping"
      };
    categoryDataList(pyload)
      .then((response) => {
        if (response.data.suc) {
          dispatch({ type: "catMenu", payload: response.data.res });
          //For Edit Cate
          if (
            comapnyObj.data !== undefined &&
            comapnyObj.data.res.ctid !== undefined
          ) {
            response.data.res.map((cat) => {
              if (cat.value === comapnyObj.data.res.ctid) {
                dispatch({ type: "selCategory", payload: [cat] });
              }
            });
          }
        }
      })
      .catch(function (error) {
        log.error("Exception occured in getCategoryData function---" + error);
      });
    countries()
      .then((response) => {
        if (response) {
          dispatch({ type: "countryMenu", payload: response });
          //For Edit Country
          if (
            comapnyObj.data !== undefined &&
            comapnyObj.data.res.ctid !== undefined
          ) {
            response.map((con) => {
              if (con.value === comapnyObj.data.res.cnt) {
                dispatch({ type: "selCountry", payload: [con] });
              }
            });
          }
        }
      })
      .catch(function (error) {
        log.error("Exception occured in getCountryList function---" + error);
      });

    var payload = "";
    if (context.logindata.utype === "ta_user") {
      payload = {
        utype: context.logindata.utype,
        user_id: context.logindata.uid,
        appto: "STA",
        fetch: "company",
        cid: context.logindata.cid,
        is_admin: context.logindata.is_admin,
      };

      rolesList(payload)
        .then((response) => {
          let roles = [];
          if (response.data.suc) {
            for (let val of response.data.res.data) {
              const payload = { value: val.rid, label: val.rn };
              roles.push(payload);
            }
            setRoles(roles);
            dispatch({ type: "enableRole", payload: true });
            dispatch({ type: "selectedRoles", payload: roles });
          }

          //For EDIT
          if (comapnyObj !== undefined && comapnyObj.data.res !== undefined) {
            let finalRoles = [];
            roles.forEach((b1) =>
              comapnyObj.data.res.crls.forEach((b2) => {
                if (Number(b1.value) === b2) {
                  finalRoles.push(b1);
                }
              })
            );
            setRoleData({ roleData: finalRoles });
          }
        })
        .catch(function (error) {
          log.error(
            "Exception occured in getReportingUserList function---" + error
          );
        });
    } else {
      payload = {
        utype: context.logindata.utype,
        user_id: context.logindata.uid,
        appto: "TA",
        fetch: "company",
      };
    }
    dispatch({ type: "currencyMenu", payload: currencies() });
    dispatch({ type: "stdCodeMenu", payload: stdCodes() });
    if(context.logindata.utype==="att_user"){
      let data = JSON.parse(localStorage.getItem(COUNTRY_DATA));
        // let finalArray = [];
        // data.forEach((element) => {
        //   finalArray.push({"label":element.con_name,"value":element.con_id})
        // });
        setRegionTypes(data)
    }

  }, []);

  //Edit company
  useEffect(() => {
    if (comapnyObj !== undefined && comapnyObj.data !== undefined) {
      if (context.logindata.utype == "att_user") {
        let payload = {
          utype: context.logindata.utype,
          user_id: context.logindata.uid,
          appto: "STA",
          fetch: "company",
          cid: context.logindata.cid,
          is_admin: context.logindata.is_admin,
        };

        rolesList(payload)
          .then((response) => {
            let roles = [];
            if (response.data.suc) {
              for (let val of response.data.res.data) {
                const payload = { value: val.rid, label: val.rn };
                roles.push(payload);
              }
              setRoles(roles);
              dispatch({ type: "enableRole", payload: true });
              dispatch({ type: "selectedRoles", payload: roles });
            }

            //For EDIT
            if (comapnyObj !== undefined && comapnyObj.data.res !== undefined) {
              let finalRoles = [];
              roles.forEach((b1) =>
                comapnyObj.data.res.crls.forEach((b2) => {
                  if (Number(b1.value) === b2) {
                    finalRoles.push(b1);
                  }
                })
              );
              setRoleData({ roleData: finalRoles });
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in getReportingUserList function---" + error
            );
          });
      }
      var obj = comapnyObj.data.res;
      if (obj.client_group) {
        for (let group of groups) {
          if (group.value == obj.client_group) {
            let pyload = {
              label: group.label,
              value: obj.client_group,
            };

            dispatch({ type: "groups", payload: [pyload] });
          }
        }
      }
      if (obj.business_nature) {
        for (let group of business) {
          if (group.value == obj.business_nature) {
            let pyload = {
              label: group.label,
              value: obj.business_nature,
            };

            dispatch({ type: "business", payload: [pyload] });
          }
        }
      }
      if (obj.payment_form) {
        for (let group of payment) {
          if (group.value == obj.payment_form) {
            let pyload = {
              label: group.label,
              value: obj.payment_form,
            };

            dispatch({ type: "payment", payload: [pyload] });
          }
        }
      }
      Object.assign(state.contacts, obj.ccinfo);
      dispatch({ type: "editCompany", payload: obj });
      dispatch({ type: "logo_url", payload: obj.comp_logo_url });
      dispatch({ type: "contacts", payload: obj.ccinfo });
      dispatch({ type: "enableUpdate", payload: true });
      dispatch({ type: "companyUrl", payload: obj.compurl });
      dispatch({ type: "cprefix", payload: obj.compprefix });
      // currencies().map((cur) => {
      //   if (cur.value === obj.ccur) {
      //     dispatch({ type: "selCurrency", payload: [cur] });
      //   }
      // });
      stdCodesMaster.map((code) => {
        if (code.code === obj.uphc) {
          let obj = {
            label: code.name,
            value: code.name,
          };
          dispatch({ type: "selPhone", payload: [obj] });
        }
      });
      if (obj.act === 1) {
        setActive({ act: true });
      } else {
        setActive({ act: false });
      }
      if (obj.usrc === 1) {
        setUsrActive({ usrc: true });
      } else {
        setUsrActive({ usrc: false });
      }
      if (obj.ucad === 1) {
        setUseActive({ ucad: true });
      } else {
        setUseActive({ ucad: false });
      }
      if (obj.ctyp === "TA") {
        dispatch({
          type: "selAgent",
          payload: [{ label: "Travel Agent", value: "TA" }],
        });
      } else {
        dispatch({
          type: "selAgent",
          payload: [{ label: "Sub Travel Agent", value: "STA" }],
        });
      }
      if (obj.protype === "B2C") {
        dispatch({ type: "enableB2C", payload: true });
        dispatch({ type: "edit_salesChanel", payload: "B2C" });
        dispatch({
          type: "selProfiles",
          payload: [{ label: "B2C", value: "B2C" }],
        });
      } else {
        dispatch({ type: "edit_salesChanel", payload: "B2B" });
        dispatch({
          type: "selProfiles",
          payload: [{ label: "B2B", value: "B2B" }],
        });
      }
      cities(comapnyObj.data.res.cnt)
        .then((response) => {
          if (response) {
            response.map((city) => {
              if (city.value === comapnyObj.data.res.cty) {
                dispatch({ type: "selCity", payload: [city] });
              }
            });
            dispatch({ type: "cityMenu", payload: response });
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCityList function---" + error);
        });

      if (props.enbleUpdate) {
        setDisableBtn(true);
      }
      window.scrollTo(0, 0);
    }
  }, [context.logindata && comapnyObj && comapnyObj.data !== undefined]);

  useEffect(() => {
    if (props.create) {
      dispatch({ type: "contacts", payload: [] });
    }
  }, [props.create]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    if (e.target.id === "act") {
      if (actState.act) {
        setActive({ act: false });
      } else {
        setActive({ act: true });
      }
    }
    if (e.target.id === "usrc") {
      if (usrState.usrc) {
        setUsrActive({ usrc: false });
      } else {
        setUsrActive({ usrc: true });
      }
    }
    if (e.target.id === "ucad") {
      if (uscState.ucad) {
        setUseActive({ ucad: false });
      } else {
        setUseActive({ ucad: true });
      }
    }
    if (e.target.id === "bkoid") {
      if (value.length > 5) {
        dispatch({ type: "bkoidLength", payload: true });
      } else {
        dispatch({ type: "bkoidLength", payload: false });
      }
    }
  };

  const handleRegionChange = (e) => {
    dispatch({ type: "selBranch", payload: [] });
    if(state.enblCompny){
      dispatch({ type: "selCompany", payload: [] });}
    dispatch({ type: "bcurency", payload: [] });
    dispatch({ type: "selCurrency", payload: [] });
    if (e.length > 0) {
      dispatch({ type: "selRegion", payload: e });
      dispatch({ type: "region_type", payload: e[0].label });
      if(context.logindata.is_admin){
      const pyload = {
        type: context.logindata.utype,
        userId: context.logindata.uid,
        fetch: "mapping",
        "region_type": e[0].label,
      };
      AttCommonSerivce.loadBranches(pyload).then(response => {
        if (response.data.suc){
            dispatch({ type: "branchList", payload: response.data.res });
            if (comapnyObj.data !== undefined && comapnyObj.data.res.bid !== undefined) {
              response.data.res.map((branch) => {
                if (branch.value === Number(comapnyObj.data.res.bid)) {
                  dispatch({ type: "bcurency", payload: branch.currency });
                  dispatch({ type: 'selCurrency', payload:  [{"value":branch.currency,"label":branch.currency}]})
                  dispatch({
                    type: "selBranch",
                    payload: [
                      { value: comapnyObj.data.res.bid, label: branch.label },
                    ],
                  });
                }
              });
            }
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getBranchData function---" + error);
        });
      }else{
        AttCommonSerivce.loadBranches({"region_type":e[0].label,"uid":context.logindata.uid}).then(response => {
          if (response.data.suc){
              dispatch({ type: "branchList", payload: response.data.res });
              if (comapnyObj.data !== undefined && comapnyObj.data.res.bid !== undefined) {
                response.data.res.map((branch) => {
                  if (branch.value === Number(comapnyObj.data.res.bid)) {
                    dispatch({ type: "bcurency", payload: branch.currency });
                    dispatch({ type: 'selCurrency', payload:  [{"value":branch.currency,"label":branch.currency}]})
                    dispatch({
                      type: "selBranch",
                      payload: [
                        { value: comapnyObj.data.res.bid, label: branch.label },
                      ],
                    });
                  }
                });
              }
            }
          })
          .catch(function (error) {
            log.error("Exception occured in getBranchData function---" + error);
          });
      }
      };
    }
    
    //Updating the branch currency
    const handleBranchChange = (data) => {
      if(state.enblCompny){
        dispatch({ type: "selCompany", payload: [] });}
    if (data && data.length !== 0) {
      if (state.ctyp) {
        if(state.enblCompny){
        dispatch({ type: "selCompany", payload: [] });}
        if (state.ctyp === "STA") {
          setRoleData({ roleData: [] });
          setRoles([]);
        }
        dispatch({ type: "BranchSel", payload: false });
        dispatch({ type: "selBranch", payload: data });
        dispatch({ type: "bid", payload: data[0].value });
        if (state.enableParentTa) {
          getCompanyTAList(data[0].value, "TA")
            .then((response) => {
              // getCompanyList(data[0].value).then(response => {
              if (response.data.suc) {
                let companyList = response.data.res.filter((obj)=>obj.sales_channel===state.profileType)
                dispatch({ type: "companies", payload: companyList });
                dispatch({ type: "enableCompany", payload: true });
              } else {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: "Selected Branch dont have any company.",
                });
                dispatch({ type: "notiVarient", payload: "danger" });
              }
            })
            .catch(function (error) {
              log.error(
                "Exception occured in getCompanyList function---" + error
              );
            });
        }

        if (state.branchList) {
          state.branchList.map((obj) => {
            if (obj.value === Number(data[0].value)) {
              dispatch({ type: "bcurency", payload: obj.currency });
              dispatch({ type: 'selCurrency', payload:  [{"value":obj.currency,"label":obj.currency}]})
            }
          });
        }
      } else {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Please Select Company Type",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        dispatch({ type: "selBranch", payload: [] });
      }
    }
  };

  //Updating the branch currency
  const handleCompanyChange = (data) => {
    if (data.length > 0) {
      dispatch({ type: "selCompany", payload: data });
      dispatch({ type: "cpid", payload: data[0].value });
      const pyload = {
        utype: context.logindata.utype,
        user_id: context.logindata.uid,
        appto: state.ctyp,
        cid: data[0].value,
        fetch: "company",
        is_admin: context.logindata.is_admin,
      };
      rolesList(pyload)
        .then((response) => {
          let rdata = [];
          if (response.data.suc && response.data.res.data !== undefined) {
            for (let val of response.data.res.data) {
              const payload = { value: val.rid, label: val.rn };
              rdata.push(payload);
            }
            setRoles(rdata);
            //For EDIT
            if (
              comapnyObj !== undefined &&
              comapnyObj.data.res !== undefined &&
              role.roleData.length === 0
            ) {
              let finalRoles = [];
              rdata.forEach((b1) =>
                comapnyObj.data.res.crls.forEach((b2) => {
                  if (Number(b1.value) === b2) {
                    finalRoles.push(b1);
                  }
                })
              );
              setRoleData({ roleData: finalRoles });
            }
            dispatch({ type: "enableRole", payload: true });
            dispatch({ type: "selectedRoles", payload: rdata });
          } else {
            let roles = [];
            setRoles(roles);
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Roles are not available",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCompanyList function---" + error);
        });
      //Get Categories Based on Company
      const cat = {
        type: context.logindata.utype,
        userId: context.logindata.uid,
        cid: data[0].value,
      };
      categoryDataList(cat)
        .then((response) => {
          if (response.data.suc) {
            dispatch({ type: "catMenu", payload: response.data.res });
            //For Edit Cate
            if (
              comapnyObj.data !== undefined &&
              comapnyObj.data.res.ctid !== undefined
            ) {
              response.data.res.map((cat) => {
                if (cat.value === comapnyObj.data.res.ctid) {
                  dispatch({ type: "selCategory", payload: [cat] });
                }
              });
            }
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCategoryData function---" + error);
        });

      //get bofcId for STA companies of there TA
      if (state.ctyp == "STA") {
        dispatch({ type: "bkoid", payload: "" });
        getCustId(data[0].value)
          .then((response) => {
            if (response.data.suc && response.data.res.length > 0) {
              dispatch({
                type: "bkoid",
                payload: response.data.res[0].back_ofc_id,
              });
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in getCategoryData function---" + error
            );
          });
      }
    }
  };

  const createRequest = () => {
    let selRoles;
    if (role.roleData) {
      selRoles = role.roleData.map((obj) => {
        if (obj.value) return obj.value;
      });
    }
    var pCompanyId = state.cpid;
    var branchId = state.bid;
    if (context.logindata.utype === "ta_user") {
      pCompanyId = context.logindata.cid;
      branchId = context.logindata.bid;
    }
    let finalSup = [];
    if (state.fltSupNames.length !== 0) {
      state.fltSupNames.map((obj) => {
        finalSup.push({ sup_id: obj.value, sup_name: obj.label });
      });
    }
    let finalHtlSup = [];
    if (state.htl_supNames.length !== 0) {
      state.htl_supNames.map((obj) => {
        finalHtlSup.push({ sup_id: obj.value, sup_name: obj.label });
      });
    }
    let currency="";
    if(state.profileType==="B2B"){
      currency=state.bcurency;
    }else{
      if(context.logindata.utype==="att_user"){
        if(state.region_type==="Qatar"){
          currency="QAR";
        }else{
          currency="INR";
        }
      }else{
        currency=context.logindata.cur;
      }
      
    }
    const payload = {
      creq: {
        cname: state.cname,
        ctyp: state.ctyp,
        bkoid: state.bkoid,
        ccur: currency,
        bid: branchId,
        ctid: state.ctid ? state.ctid : null,
        act: actState.act,
        usrc: usrState.usrc,
        cpid: pCompanyId,
        cadd: state.cadd,
        cnt: state.cnt,
        cty: state.cty,
        ucad: uscState.ucad,
        uem: state.uem,
        ufn: state.ufn,
        uln: state.uln,
        uphc: state.uphc,
        uph: state.uph,
        comp_logo_url: state.logo_url,
        ccinfo: state.contacts,
        crls: selRoles,
        flt_sup: finalSup,
        htl_sup: finalHtlSup,
        payment:
          state.payment && state.payment.length > 0
            ? state.payment[0].value
            : null,
        groups:
          state.groups && state.groups.length > 0
            ? state.groups[0].value
            : null,
        business:
          state.business && state.business.length > 0
            ? state.business[0].value
            : null,
        protype: state.profileType,
        compurl: state.companyUrl,
        compprefix: state.cprefix,
        region_type : context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type
      },
    };
    return payload;
  };

  const valid = () => {
    //valid all bootstraps inputbox here
    // emailValidation,mobileNumberValidation,characterValidation,characterNumberValidation

    let bkFalse = true;
    let comNameFalse = true;
    let uemFalse = true;
    let fnFalse = true;
    let lnFalse = true;
    let uphFalse = true;
    //type validation of
    if (isNotNull(state.bkoid)) {
      if (characterNumberValidation(state.bkoid)) {
        if (state.bkoid.length > 5) {
          dispatch({ type: "bkoidLength", payload: true });
        } else {
          bkFalse = false;
          dispatch({ type: "bkoidLength", payload: false });
        }
        dispatch({ type: "bkFalse", payload: false });
      } else {
        dispatch({ type: "bkFalse", payload: true });
      }
    }
    //type validation of company name
    if (state.cname !== "") {
      if (companyCharacterValidation(state.cname)) {
        dispatch({ type: "comNameFalse", payload: false });
        comNameFalse = false;
      } else {
        dispatch({ type: "comNameFalse", payload: true });
      }
    }
    //validations of user email
    if (state.uem !== "") {
      if (emailValidation(state.uem)) {
        dispatch({ type: "uemFalse", payload: false });
        uemFalse = false;
      } else {
        dispatch({ type: "uemFalse", payload: true });
      }
    }
    //validation of first name
    if (state.ufn !== "") {
      if (characterValidation(state.ufn)) {
        fnFalse = false;
        dispatch({ type: "fnFalse", payload: false });
      } else {
        dispatch({ type: "fnFalse", payload: true });
      }
    }
    //validations of last name
    if (state.uln !== "") {
      if (characterValidation(state.uln)) {
        lnFalse = false;
        dispatch({ type: "lnFalse", payload: false });
      } else {
        dispatch({ type: "lnFalse", payload: true });
      }
    }
    //validations of phone number
    if (state.uph !== "") {
      if (mobileNumberValidation(state.uph)) {
        uphFalse = false;
        dispatch({ type: "uphFalse", payload: false });
      } else {
        dispatch({ type: "uphFalse", payload: true });
      }
    }

    //check if all are validated or not
    if (
      !bkFalse &&
      !comNameFalse &&
      !uemFalse &&
      !fnFalse &&
      !lnFalse &&
      !uphFalse
    ) {
      return true;
    } else {
      return false;
    }
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  }
  const handleCompanyCreation = (event) => {
    const form = document.getElementById("formId");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    // e.preventDefault();
    // compType: false,BranchSel: false,compCur: false,catError: false,clientError: false,payError: false,busError: false,roleError: false
    if (context.logindata.utype == "ta_user") {
      dispatch({ type: "ctyp", payload: sType[0].value });
      dispatch({ type: "selAgent", payload: sType });
    } else {
      if (state.selAgent && state.selAgent.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "compType", payload: true });
      }
      if (state.profileType==="B2B" && state.selBranch && state.selBranch.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "BranchSel", payload: true });
      }
    }

    if (state.selCurrency && state.selCurrency.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "compCur", payload: true });
    }
    if (state.ctyp == "TA") {
      if (state.selCategory && state.selCategory.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "catError", payload: true });
      }
    }
    if (state.groups && state.groups.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "clientError", payload: true });
    }
    if (state.payment && state.payment.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "payError", payload: true });
    }
    if (state.business && state.business.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "busError", payload: true });
    }
    if (state.fltSupNames && state.fltSupNames.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "supError", payload: true });
    }
    if (state.htl_supNames && state.htlSup.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "htlSup", payload: true });
    }
    if (role.roleData && role.roleData.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "roleError", payload: true });
    }

    if (uscState.ucad && state.selCountry.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "cntError", payload: true });
    }

    if (uscState.ucad && state.selCity.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "ctyError", payload: true });
    }

    if (state.selPhone.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "ccError", payload: true });
    }
    let topvalidation = false; //validation is diff for ta and att user
    let contactsValidations = checkContactsValidations(state.contacts);
    if (context.logindata.utype == "att_user") {
      if (state.ctyp == "TA") {
        if (
          state.cname !== "" &&
          role.roleData.length > 0 &&
          state.business.length > 0 &&
          state.payment.length > 0 &&
          state.groups.length > 0 &&
          state.selCurrency.length > 0 &&
          state.selBranch.length > 0 &&
          state.selAgent.length > 0 &&
          state.selCategory.length > 0 &&
          contactsValidations &&
          state.bkoid !== "" &&
          state.uem !== "" &&
          state.ufn !== "" &&
          state.uph !== "" &&
          state.selPhone.length > 0 &&
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ) {
          if (uscState.ucad) {
            if (
              state.selCountry.length > 0 &&
              state.selCity.length > 0 &&
              state.cadd
            ) {
              topvalidation = true;
            }
          } else {
            topvalidation = true;
          }
        }
      } else {
        if (
          state.cname !== "" &&
          role.roleData.length > 0 &&
          state.business.length > 0 &&
          state.payment.length > 0 &&
          state.groups.length > 0 &&
          state.selCurrency.length > 0 &&
          state.selBranch.length > 0 &&
          state.selAgent.length > 0 &&
          contactsValidations &&
          state.bkoid !== "" &&
          state.uem !== "" &&
          state.ufn !== "" &&
          state.uph !== "" &&
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ) {
          if (uscState.ucad) {
            if (
              state.selCountry.length > 0 &&
              state.selCity.length > 0 &&
              state.cadd
            ) {
              topvalidation = true;
            }
          } else {
            topvalidation = true;
          }
        }
      }
    } else {
      if (
        state.cname !== "" &&
        role.roleData.length > 0 &&
        state.business.length > 0 &&
        state.payment.length > 0 &&
        state.groups.length > 0 &&
        state.selCurrency.length > 0 &&
        contactsValidations &&
        state.bkoid !== "" &&
        state.uem !== "" &&
        state.ufn !== "" &&
        state.uph !== "" &&
        state.selCountry.length > 0 &&
        state.selCity.length > 0 &&
        state.cadd
      ) {
        if (uscState.ucad) {
          if (
            state.selCountry.length > 0 &&
            state.selCity.length > 0 &&
            state.cadd
          ) {
            topvalidation = true;
          }
        } else {
          topvalidation = true;
        }
      }
    }
    let validationb2c = false
    if (
      state.cname !== "" &&
      role.roleData.length > 0 &&
      contactsValidations &&
      state.bkoid !== "" &&
      state.uem !== "" &&
      state.ufn !== "" &&
      state.uph !== "" &&
      state.selCountry.length > 0 &&
      state.selCity.length > 0 &&
      state.cadd
    ) {
      if (uscState.ucad) {
        if (
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ){
          validationb2c = true
          }
        }
    }
    if (!state.enableB2C?topvalidation:validationb2c) {
      let bootstrapValidation = valid();
      if (bootstrapValidation) {
        let suc=true
        for (let val of props.res){
          if((val.cname).toLowerCase()==(state.cname).toLowerCase()){
          suc=false
        }
      }
        if(suc){
        var companyPayload = createRequest();
        companyPayload.creq.crby = context.logindata.uid;
        dispatch({type:"isItineraryLoading", payload:true})
        saveCompany(companyPayload)
          .then((response) => {
            if (response.data.suc) {
              setData({ data: response });
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Company saved successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              hidemessage();
              return (window.location = "/ezytrip/administration/company");
            } else if (
              response.data.err &&
              response.data.err.includes("Duplicate Email")
            ) {
              dispatch({type:"isItineraryLoading", payload:false})
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Email Already Exists",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            } else {
              dispatch({type:"isItineraryLoading", payload:false})
              // setData({ data: response })
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({ type: "notiMessage", payload: "Company Not saved" });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        } else{
          dispatch({type:"isItineraryLoading", payload:false})
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({type: "notiMessage",payload: "Company Already Exists"});
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
        }
      }
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Mandatory Fields",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const handleCompanyUpdate = (event) => {
    event.preventDefault();
    const form = document.getElementById("formId");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    // e.preventDefault();
    // compType: false,BranchSel: false,compCur: false,catError: false,clientError: false,payError: false,busError: false,roleError: false
    if (context.logindata.utype == "ta_user") {
      dispatch({ type: "ctyp", payload: sType[0].value });
      dispatch({ type: "selAgent", payload: sType });
    } else {
      if (state.selAgent && state.selAgent.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "compType", payload: true });
      }
      if (state.profileType==="B2B" && state.selBranch && state.selBranch.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "BranchSel", payload: true });
      }
    }

    if (state.selCurrency && state.selCurrency.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "compCur", payload: true });
    }
    if (state.ctyp == "TA") {
      if (state.selCategory && state.selCategory.length === 0) {
        event.preventDefault();
        event.stopPropagation();
        dispatch({ type: "catError", payload: true });
      }
    }
    if (state.groups && state.groups.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "clientError", payload: true });
    }
    if (state.payment && state.payment.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "payError", payload: true });
    }
    if (state.business && state.business.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "busError", payload: true });
    }
    if (role.roleData && role.roleData.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "roleError", payload: true });
    }

    if (uscState.ucad && state.selCountry.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "cntError", payload: true });
    }

    if (uscState.ucad && state.selCity.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "ctyError", payload: true });
    }

    if (state.selPhone.length === 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch({ type: "ccError", payload: true });
    }
    let topvalidation = false; //validation is diff for ta and att user
    let contactsValidations = checkContactsValidations(state.contacts);
    if (context.logindata.utype == "att_user") {
      if (state.ctyp == "TA") {
        if (
          state.cname !== "" &&
          role.roleData.length > 0 &&
          state.business.length > 0 &&
          state.payment.length > 0 &&
          state.groups.length > 0 &&
          state.selCurrency.length > 0 &&
          state.selBranch.length > 0 &&
          state.selAgent.length > 0 &&
          state.selCategory.length > 0 &&
          contactsValidations &&
          state.bkoid !== "" &&
          state.uem !== "" &&
          state.ufn !== "" &&
          state.uph !== "" &&
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ) {
          if (uscState.ucad) {
            if (
              state.selCountry.length > 0 &&
              state.selCity.length > 0 &&
              state.cadd
            ) {
              topvalidation = true;
            }
          } else {
            topvalidation = true;
          }
        }
      } else {
        if (
          state.cname !== "" &&
          role.roleData.length > 0 &&
          state.business.length > 0 &&
          state.payment.length > 0 &&
          state.groups.length > 0 &&
          state.selCurrency.length > 0 &&
          state.selBranch.length > 0 &&
          state.selAgent.length > 0 &&
          contactsValidations &&
          state.bkoid !== "" &&
          state.uem !== "" &&
          state.ufn !== "" &&
          state.uph !== "" &&
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ) {
          if (uscState.ucad) {
            if (
              state.selCountry.length > 0 &&
              state.selCity.length > 0 &&
              state.cadd
            ) {
              topvalidation = true;
            }
          } else {
            topvalidation = true;
          }
        }
      }
    } else {
      if (
        state.cname !== "" &&
        role.roleData.length > 0 &&
        state.business.length > 0 &&
        state.payment.length > 0 &&
        state.groups.length > 0 &&
        state.selCurrency.length > 0 &&
        contactsValidations &&
        state.bkoid !== "" &&
        state.uem !== "" &&
        state.ufn !== "" &&
        state.uph !== "" &&
        state.selCountry.length > 0 &&
        state.selCity.length > 0 &&
        state.cadd
      ) {
        if (uscState.ucad) {
          if (
            state.selCountry.length > 0 &&
            state.selCity.length > 0 &&
            state.cadd
          ) {
            topvalidation = true;
          }
        } else {
          topvalidation = true;
        }
      }
    }
    let validationb2c = false
    if (
      state.cname !== "" &&
      role.roleData.length > 0 &&
      contactsValidations &&
      state.bkoid !== "" &&
      state.uem !== "" &&
      state.ufn !== "" &&
      state.uph !== "" &&
      state.selCountry.length > 0 &&
      state.selCity.length > 0 &&
      state.cadd
    ) {
      if (uscState.ucad) {
        if (
          state.selCountry.length > 0 &&
          state.selCity.length > 0 &&
          state.cadd
        ){
          validationb2c = true
          }
        }
    }
    if (!state.enableB2C?topvalidation:validationb2c) {
      let bootstrapValidation = valid();
      if (bootstrapValidation) {
        var companyPayload = createRequest();
        companyPayload.creq.id = state.id;
        companyPayload.creq.upby = context.logindata.uid;
          dispatch({type:"isItineraryLoading", payload:true})
        updateCompany(companyPayload)
          .then((response) => {
            if (response.data.suc) {
              setData({ data: response });
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Company Updated successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              hidemessage();
              return (window.location = "/ezytrip/administration/company");
            } else {
              dispatch({type:"isItineraryLoading", payload:false})
              setData({ data: response });
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({ type: "notiMessage", payload: "Company Not Updated" });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
      }
    } else {
      dispatch({type:"isItineraryLoading", payload:false})
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter Mandatory Fields",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const handleCountryChange = (e) => {
    dispatch({ type: "selCity", payload: [] });
    if (e.length > 0) {
      dispatch({ type: "selCountry", payload: e });
      dispatch({ type: "cntError", payload: false });
      cities(e[0].value).then((res) => {
        dispatch({ type: "cnt", payload: e[0].value });
        let data = getUnique(res, "label");
        dispatch({ type: "cityMenu", payload: data });
      });
    }
  };

  const pickldata = (data) => {
    setRoleData({ roleData: data });
  };

  const contactData = (data) => {
    dispatch({ type: "contacts", payload: data });
  };

  const profileTypes = [
    { label: "B2B", value: "B2B" },
    { label: "B2C", value: "B2C" },
  ];

  const handleCancel = (e) => {
    return (window.location = "/ezytrip/administration/company");
  };

  const handleProfileType = (schanel) => {
    if (schanel) {
      if(state.ctyp !== "STA"){
      dispatch({
        type: "selAgent",
        payload: [{ label: "Travel Agent", value: "TA" }],
      });
    }
      dispatch({ type: "profileType", payload: schanel });
      if (schanel === "B2C") {
        dispatch({ type: "enableB2C", payload: true });
      } else {
        dispatch({ type: "enableB2C", payload: false });
      }
      dispatch({ type: "selProfiles", payload: [{value:schanel,label:schanel}] });
    }
  };

  const agentTypes = [
    { label: "Travel Agent", value: "TA" },
    { label: "Sub Travel Agent", value: "STA" },
  ];

  const sType = [{ label: "Sub Travel Agent", value: "STA" }];
  

  const handleCompanyType = (data) => {
    if (data && data.length !== 0) {
      setRoles([]);
      setRoleData({ roleData: [] });
      dispatch({ type: "compType", payload: false });
      dispatch({ type: "selBranch", payload: [] });
      dispatch({ type: "ctyp", payload: data[0].value });
      dispatch({ type: "selAgent", payload: data });
      if(state.enblCompny){
        // dispatch({ type: "bkoid", payload: "" });
        dispatch({ type: "selCompany", payload: [] });
      }
      dispatch({ type: "selCurrency", payload: [] });
      dispatch({ type: "selCategory", payload: [] });
      if (data[0].value === "STA") {
        dispatch({ type: "enableParentTa", payload: true });
        dispatch({ type: "bkoid", payload: "" });
        var payload = "";
        if (context.logindata.utype === "ta_user") {
          payload = {
            utype: context.logindata.utype,
            user_id: context.logindata.uid,
            appto: "STA",
            fetch: "company",
            cid: context.logindata.cid,
            is_admin: context.logindata.is_admin,
          };

          rolesList(payload)
            .then((response) => {
              let roles = [];
              if (response.data.suc) {
                for (let val of response.data.res.data) {
                  const payload = { value: val.rid, label: val.rn };
                  roles.push(payload);
                }
                setRoles(roles);
                dispatch({ type: "enableRole", payload: true });
                dispatch({ type: "selectedRoles", payload: roles });
              }

              //For EDIT
              if (
                comapnyObj !== undefined &&
                comapnyObj.data.res !== undefined
              ) {
                let finalRoles = [];
                roles.forEach((b1) =>
                  comapnyObj.data.res.crls.forEach((b2) => {
                    if (Number(b1.value) === b2) {
                      finalRoles.push(b1);
                    }
                  })
                );
                setRoleData({ roleData: finalRoles });
              }
            })
            .catch(function (error) {
              log.error(
                "Exception occured in getReportingUserList function---" + error
              );
            });
        }
      } else {
        dispatch({ type: "enableParentTa", payload: false });
        dispatch({ type: "enableCompany", payload: false });
        var payload = {
          utype: context.logindata.utype,
          user_id: context.logindata.uid,
          appto: "TA",
          cid: null,
          fetch: "company",
          is_admin: context.logindata.is_admin,
        };

        rolesList(payload)
          .then((response) => {
            let roles = [];
            if (response.data.suc) {
              for (let val of response.data.res.data) {
                const payload = { value: val.rid, label: val.rn };
                roles.push(payload);
              }
              setRoles(roles);
              dispatch({ type: "enableRole", payload: true });
              dispatch({ type: "selectedRoles", payload: roles });
            }

            //For EDIT
            if (comapnyObj !== undefined && comapnyObj.data.res !== undefined) {
              let finalRoles = [];
              roles.forEach((b1) =>
                comapnyObj.data.res.crls.forEach((b2) => {
                  if (Number(b1.value) === b2) {
                    finalRoles.push(b1);
                  }
                })
              );
              setRoleData({ roleData: finalRoles });
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in getReportingUserList function---" + error
            );
          });
        setRoles(state.selectedRoles);
      }
    }
  };
  const companyCurrency = (data) => {
    if (data && data.length > 0) {
      dispatch({ type: "ccur", payload: data[0].value });
      dispatch({ type: "selCurrency", payload: data });
      dispatch({ type: "compCur", payload: false });
    }
  };

  const handleCity = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "cty", payload: obj[0].value });
      dispatch({ type: "ctyError", payload: false });
      dispatch({ type: "selCity", payload: obj });
    }
  };

  const handleCouCode = (obj) => {
    if (obj.length > 0) {
      if (obj[0].value !== "Select") {
        let bhpcSplit = obj[0].value.split("(");
        let bhpc = bhpcSplit[1].split(")");
        dispatch({ type: "uphc", payload: bhpc[0] });
        dispatch({ type: "ccError", payload: false });
        dispatch({ type: "selPhone", payload: obj });
      }
    }
  };

  /**
   * @description: to remove all multiples in array of objects
   * @author : Azamuddin
   * @date : 03-11-2020
   */

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  //contacts child component Validations
  const checkContactsValidations = (data) => {
    let email = false;
    let nm = false;
    let no = false;
    let cph = false;
    let emailEmpty = false;
    let nameEmpty = false;
    let noEmpty = false;
    let design = false;
    for (let each of data) {
      if (each.cem == "") {
        emailEmpty = true;
      }
      if (each.cn == "") {
        nameEmpty = true;
      }
      if (each.cph == "") {
        noEmpty = true;
      }
      if (each.cdesig == "") {
        design = true;
      }
      if (each.mcode == "") {
        cph = true;
      }
      if (data.length > 0) {
        if (emailValidation(each.cem) == false) {
          email = true;
        }
        if (characterValidation(each.cn) == false) {
          nm = true;
        }
        if (mobileNumberValidation(each.cph) == false) {
          no = true;
        }
      }
    }
    if (data.length === 0) {
      cph = true;
    }
    //send prop for child data
    let obj = {
      email: email,
      nm: nm,
      no: no,
      cph: cph,
    };
    dispatch({ type: "contactValidation", payload: obj });
    if (
      !cph &&
      !email &&
      !no &&
      !nm &&
      !emailEmpty &&
      !nameEmpty &&
      !noEmpty &&
      !design
    ) {
      dispatch({ type: "saveContacts", payload: true });
      return true;
    } else {
      return false;
    }
  };
  //on click of reset
  const handleReset = (event) => {
    dispatch({ type: "clear", payload: true });
    dispatch({ type: "selRegion", payload: [] });
    dispatch({ type: "selProfiles", payload: [] });
    setValidated(false);
    setresetChild(true);
  };

  const updateFileName = (url) => {
    dispatch({ type: "logo_url", payload: url });
  };

  const content = {
    flight: (
      <ShowFlightInfo
        response={
          state.enableUpdate && definedServices !== "" && definedServices
        }
      />
    ),
    hotel: (
      <ShowHotelInfo
        response={
          state.enableUpdate && definedServices !== "" && definedServices
        }
      />
    ),
  };
  const [tab, setTab] = useState({
    active: "flight",
  });

  const setBussinesType = (obj) => {
    dispatch({ type: "business", payload: obj });
    if (obj.length!=0){
      if (obj[0].value === "all") {
      dispatch({ type: "enableFlight", payload: true });
      dispatch({ type: "enableHotel", payload: true });
    } else if (obj[0].value === "flight") {
      dispatch({ type: "enableFlight", payload: true });
      dispatch({ type: "enableHotel", payload: false });
    } else if (obj[0].value === "hotel") {
      dispatch({ type: "enableFlight", payload: false });
      dispatch({ type: "enableHotel", payload: true });
    }
   }
  };
  const categoryChange = (value) => {
    if (value.length > 0) {
      dispatch({ type: "ctid", payload: value[0].value });
      dispatch({ type: "catError", payload: false });
      dispatch({ type: "selCategory", payload: value });
    } else {
      dispatch({ type: "catError", payload: true });
      dispatch({ type: "ctid", payload: 0 });
      dispatch({ type: "selCategory", payload: [] });
    }
  };

  return (
    <>
      <div>
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
        {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
        <Form noValidate validated={validated} id="formId">
          <Form.Row>
            <Form.Group as={Col} xs={3} controlId="bkoid">
              <Form.Control
                required
                type="text"
                maxLength="5"
                disabled={state.enableUpdate || state.ctyp == "STA"}
                placeholder="Enter Back Office Id"
                value={state.bkoid}
                onChange={handleChange}
              />
              <Form.Label>
                Back Office <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Back Office.
              </Form.Control.Feedback>
              {state.bkoidLength && (
                <p className="vError">
                  Back Office Id Length Should not be Greater Than 5
                </p>
              )}
              {state.bkFalse && (
                <p className="vError">Please Enter Valid Bank Office</p>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="cname">
              <Form.Control
                required
                type="text"
                disabled={state.enableUpdate}
                placeholder="Enter Company Name"
                value={state.cname}
                onChange={handleChange}
              />
              <Form.Label>
                Company Name <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Company Name.
              </Form.Control.Feedback>
              {state.comNameFalse && (
                <p className="vError">
                  Please Enter Combination of Alphabets and Numbers
                </p>
              )}
            </Form.Group>
            {context.logindata.utype !== "ta_user" && (
              <SalesChannel sendSalesChanel={handleProfileType} edit_sales={state.edit_salesChanel}/>
              // <Form.Group
              //   as={Col}
              //   xs={3}
              //   className={"selectplugin " + (state.compType && "error")}
              //   controlId="ctyp"
              // >
              //   <Select
              //     options={profileTypes}
              //     disabled={state.enableUpdate}
              //     searchable={false}
              //     values={state.selProfiles}
              //     onChange={(value) => handleProfileType(value)}
              //     //disabled={state.enableUpdate}
              //   />
              //   <Form.Label>
              //     Sales Channel <sup>*</sup>
              //   </Form.Label>
              //   {state.compType && (
              //     <p className="vError">Please Select a Profile Type</p>
              //   )}
              // </Form.Group>
            )}
            {context.logindata.utype !== "ta_user" && (
              <Form.Group
                as={Col}
                xs={3}
                className={"selectplugin " + (state.compType && "error")}
                controlId="ctyp"
              >
                <Select
                  options={agentTypes}
                  searchable={false}
                  values={state.selAgent}
                  onChange={(value) => handleCompanyType(value)}
                  disabled={state.enableUpdate||state.enableB2C}
                />
                <Form.Label>
                  Company Type <sup>*</sup>
                </Form.Label>
                {state.compType && (
                  <p className="vError">Please Select a Company Type</p>
                )}
              </Form.Group>
            )}
            
              {context.logindata.utype == "att_user" && <Form.Group
                as={Col}
                xs={3}
                className={"selectplugin " + (state.BranchSel && "error")}
                controlId="region_type"
              >
                <Select
                  options={regionTypes}
                  searchable={false}
                  values={state.selRegion}
                  disabled={state.enableUpdate}
                  onChange={(value) => handleRegionChange(value)}
                />
                <Form.Label>
                  Region<sup>*</sup>
                </Form.Label>
                {state.BranchSel && (
                  <p className="vError">Please Select a Region</p>
                )}
              </Form.Group>}

            {!state.enableB2C && (!state.enableParentTa || context.logindata.utype === "att_user") &&
              <Form.Group
                as={Col}
                xs={3}
                className={"selectplugin " + (state.BranchSel && "error")}
                controlId="bid"
              >
                <Select
                  options={state.branchList}
                  searchable={true}
                  values={state.selBranch}
                  disabled={state.enableUpdate}
                  onChange={(value) => handleBranchChange(value)}
                />
                <Form.Label>
                  Branch<sup>*</sup>
                </Form.Label>
                {state.BranchSel && (
                  <p className="vError">Please Select a Branch</p>
                )}
              </Form.Group>
             }
            {!state.enableB2C && state.ctyp !== "STA" && (
              <Form.Group as={Col} xs={3} controlId="bCur">
                <Form.Control
                  type="text"
                  disabled="true"
                  value={state.bcurency}
                  onChange={handleChange}
                />
                <Form.Label>Branch Currency</Form.Label>
              </Form.Group>
            )}

            {state.enableCompany && (
              <Form.Group
                className="selectplugin"
                as={Col}
                xs={3}
                controlId="userRole"
              >
                <Select
                  options={state.companies}
                  searchable={true}
                  values={state.selCompany}
                  onChange={(value) => handleCompanyChange(value)}
                />
                <Form.Label>Company</Form.Label>
              </Form.Group>
            )}
            {!state.enableB2C &&
            <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.compCur && "error")}
              controlId="ccur"
            >
              <Select
                options={state.currencyMenu}
                searchable={true}
                disabled={true}
                values={state.selCurrency}
                //onChange={(value) => companyCurrency(value)}
              />
              <Form.Label>
                Company Currency <sup>*</sup>
              </Form.Label>

              {state.compCur && (
                <p className="vError">Please Select a Company Currency</p>
              )}
            </Form.Group>
            }
            {!state.enableB2C && state.ctyp == "TA" && (
              <Form.Group
                as={Col}
                xs={3}
                className={"selectplugin " + (state.catError && "error")}
                controlId="ctid"
              >
                <Select
                  options={state.catMenu}
                  searchable={false}
                  values={state.selCategory}
                  onChange={categoryChange}
                />
                <Form.Label>
                  Category <sup>*</sup>
                </Form.Label>
                {state.catError && (
                  <p className="vError">Please Select a Category</p>
                )}
              </Form.Group>
            )}

            {!state.enableB2C &&
              <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.clientError && "error")}
              controlId="ctid"
            >
              <Select
                options={groups}
                searchable={false}
                values={state.groups}
                onChange={(value) => (
                  dispatch({ type: "groups", payload: value }),
                  dispatch({ type: "clientError", payload: false })
                )}
              />
              <Form.Label>
                Client Group <sup>*</sup>
              </Form.Label>
              {state.clientError && (
                <p className="vError">Please Select Client Group</p>
              )}
            </Form.Group>
            }
            {!state.enableB2C &&
            <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.busError && "error")}
              controlId="ctid"
            >
              <Select
                options={business}
                searchable={false}
                values={state.business}
                onChange={(value) => setBussinesType(value)}
                // onChange={(value) => (
                //   dispatch({ type: "business", payload: value }),
                //   dispatch({ type: "busError", payload: false })
                // )}
              />
              <Form.Label>
                Nature Of Business <sup>*</sup>
              </Form.Label>
              {state.busError && (
                <p className="vError">Please Select a Nature Of Business</p>
              )}
            </Form.Group>
            }
            {state.enableFlight && (
              <Form.Group
                as={Col}
                xs={3}
                className="selectplugin"
                controlId="selectedValue"
              >
                <Select
                  multi
                  options={state.fltSuppliers}
                  values={state.fltSupNames}
                  onChange={(value) =>
                    dispatch({ type: "fltSupNames", payload: value })
                  }
                />
                <Form.Label>Flight Supplier</Form.Label>
              </Form.Group>
            )}
            {state.enableHotel && (
              <Form.Group
                as={Col}
                xs={3}
                className="selectplugin"
                controlId="selectedValue"
              >
                <Select
                  multi
                  options={state.htlsuppliers}
                  values={state.htl_supNames}
                  onChange={(value) =>
                    dispatch({ type: "htl_supNames", payload: value })
                  }
                />
                <Form.Label>Hotel Supplier</Form.Label>
              </Form.Group>
            )}

            {!state.enableB2C &&
            <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.payError && "error")}
              controlId="ctid"
            >
              <Select
                options={payment}
                searchable={false}
                values={state.payment}
                onChange={(value) => (
                  dispatch({ type: "payment", payload: value }),
                  dispatch({ type: "payError", payload: false })
                )}
              />
              <Form.Label>
                Form of Payment <sup>*</sup>
              </Form.Label>
              {state.payError && (
                <p className="vError">Please Select Form of Payment</p>
              )}
            </Form.Group>
            }
          </Form.Row>

          <Form.Row>
            {state.enableRole && (
              <Form.Group
                as={Col}
                className={"selectplugin " + (state.roleError && "error")}
                xs={6}
              >
                <PickList
                  leftPaneLabel="Available Roles"
                  rightPaneLabel="Selected Roles"
                  type={"Company"}
                  selData={role.roleData}
                  dataList={roles}
                  sendData={pickldata}
                />
                <Form.Label>
                  Roles <sup>*</sup>
                </Form.Label>
                {state.roleError && (
                  <p className="vError">Please Select Roles</p>
                )}
              </Form.Group>
            )}
          </Form.Row>
          <ContactInfo
            sendData={contactData}
            contactsList={state.contacts}
            validation={validated}
            resetData={resetChild}
            checkValidations={state.contactValidation}
            type="company"
          />

          <h4 className="mt-3 mb-4 subTitle plusHr d-flex justify-content-between">
            Agency Admin
          </h4>
          <Form.Row>
            <Form.Group as={Col} xs={3} className="mb-3" controlId="usrc">
              <Form.Check
                type="checkbox"
                label="Don't send Credentials on this Email address"
                custom
                checked={usrState.usrc}
                value={usrState.usrc}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {state.enableB2C && (
              <Form.Group as={Col} xs={3} controlId="companyUrl">
                <Form.Control
                  required
                  type="text"
                  //disabled={state.enableUpdate}
                  placeholder="Enter Company URL"
                  value={state.companyUrl}
                  onChange={handleChange}
                />
                <Form.Label>
                  Company URL <sup>*</sup>
                </Form.Label>
              </Form.Group>
            )}
            {state.enableB2C && (
              <Form.Group as={Col} xs={3} controlId="cprefix">
                <Form.Control
                  required
                  type="text"
                  //disabled={true}
                  placeholder="Enter Prefix"
                  onChange={handleChange}
                  value={state.cprefix}
                />
                <Form.Label>
                  Company Prefix <sup>*</sup>
                </Form.Label>
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={3} controlId="uem">
              <Form.Control
                required
                type="text"
                disabled={state.enableUpdate}
                placeholder="Enter Email"
                value={state.uem}
                onChange={handleChange}
              />
              <Form.Label>
                Email <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Email.
              </Form.Control.Feedback>
              {state.uemFalse && (
                <p className="vError">Please Enter Valid Email</p>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="ufn">
              <Form.Control
                required
                type="text"
                disabled={state.enableUpdate}
                placeholder="Enter First Name"
                value={state.ufn}
                onChange={handleChange}
              />
              <Form.Label>
                First name <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide First Name.
              </Form.Control.Feedback>
              {/* {state.fnFalse && (
                <p className="vError">Please Enter Valid First Name</p>
              )} */}
            </Form.Group>
            <Form.Group as={Col} xs={2} controlId="uln">
              <Form.Control
                required
                type="text"
                disabled={state.enableUpdate}
                placeholder="Enter Last Name"
                value={state.uln}
                onChange={handleChange}
              />
              <Form.Label>
                Last Name <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Last Name.
              </Form.Control.Feedback>
              {state.lnFalse && (
                <p className="vError">Please Enter Valid Last Name</p>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              xs={2}
              className={"selectplugin cCode" + (state.ccError && "error")}
              controlId="uphc"
            >
              <Select
                options={state.stdCodeMenu}
                // searchable={true}
                values={state.selPhone}
                disabled={state.enableUpdate}
                onChange={(value) => handleCouCode(value)}
              />
              <Form.Label>
                Country Code <sup>*</sup>
              </Form.Label>
              {state.ccError && (
                <p className="vError">Please Select a Country Code</p>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={2} controlId="uph">
              <Form.Control
                required
                type="text"
                disabled={state.enableUpdate}
                placeholder="Enter Contact Nmber"
                value={state.uph}
                onChange={handleChange}
              />
              <Form.Label>
                Contact Number<sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Contact Number.
              </Form.Control.Feedback>
              {state.uphFalse && (
                <p className="vError">Please Select a Valid Contact Number</p>
              )}
            </Form.Group>
            <Col className="mb-4">
              <Alert variant="info" className="fieldNote">
                {" "}
                Note : This Email id is considered as User name and Password
                will be send through email id
              </Alert>
            </Col>
          </Form.Row>

          <h4 className="mt-0 subTitle plusHr d-flex justify-content-between">
            Address & Communication Details
          </h4>

          <Form.Row>
            <Form.Group as={Col} className="mb-3" controlId="ucad">
              <Form.Check
                type="checkbox"
                label="Always use Agency address for communication"
                custom
                checked={uscState.ucad}
                value={uscState.ucad}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={3} controlId="cadd">
              <Form.Control
                required
                type="text"
                placeholder="Enter Address"
                value={state.cadd}
                onChange={handleChange}
              />
              <Form.Label>
                Address <sup>*</sup>
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide Address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.cntError && "error")}
              controlId="cnt"
            >
              <Select
                options={state.countryMenu}
                searchable={true}
                values={state.selCountry}
                onChange={(value) => handleCountryChange(value)}
              />

              <Form.Label>
                Country <sup>*</sup>
              </Form.Label>
              {state.cntError && uscState.ucad && (
                <p className="vError">Please Select a Country</p>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              xs={3}
              className={"selectplugin " + (state.ctyError && "error")}
              controlId="cty"
            >
              <Select
                options={state.cityMenu}
                searchable={true}
                values={state.selCity}
                onChange={(value) => handleCity(value)}
              />
              <Form.Label>
                City<sup>*</sup>
              </Form.Label>
              {state.ctyError && uscState.ucad && (
                <p className="vError">Please Select a City</p>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
        <FileUpload
          reportUrls={state.logo_url}
          type={"Company"}
          sendFileUrl={updateFileName}
        />
        {state.enableUpdate && definedServices !== "" && (
          <>
            <h4 className="mt-0 subTitle plusHr d-flex justify-content-between">
              Defined Services
            </h4>
            <div className="tabLink tablinkwithArrow">
              <Tabs
                active={tab.active}
                onChange={(active) => setTab({ active })}
              >
                <span key="flight">Flight</span>
                <span key="hotel">Hotel</span>
              </Tabs>
              <div className="tabContent">{content[tab.active]}</div>
            </div>
          </>
        )}

        <div className="switchPlus buttonGrop pb-0">
          <Form.Group as={Col} className="topSwitch" controlId="ctyp">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              checked={actState.act}
              value={actState.act}
              onChange={handleChange}
            />
          </Form.Group>
          {context.logindata.roles !== undefined &&
            context.logindata.roles.includes("C_C_C") && (
              <Button
                size="xs"
                variant="outline-secondary"
                onClick={handleCancel}
                type="submit"
              >
                {" "}
                Back
              </Button>
            )}
          {!state.enableUpdate && (
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={handleReset}
              type="submit"
            >
              {" "}
              Reset
            </Button>
          )}
          {!state.enableUpdate && (
            <Button
              size="xs"
              variant="outline-primary"
              onClick={handleCompanyCreation}
              loading={state.loadBranch}
              type="submit"
            >
              {" "}
              Add Company
            </Button>
          )}
          {state.enableUpdate && !props.enbleUpdate && (
            <Button
              size="xs"
              variant="primary"
              onClick={handleCompanyUpdate}
              loading={state.loadBranch}
              type="submit"
              isDisabled={disableBtn}
            >
              {" "}
              Update
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
export default CompanyCreation;
