import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
// const API_URL = "http://localhost:3005/"
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations service
 * @param {*} Service 
 */
class AirConfigurationService {
    
    async getMarkupList() {
        return await axios.get(`${API_URL}/att/markup/retrieve`);
    }

    async saveMarkupDetails(markpData) {
        return await axios.post(`${API_URL}/att/markup/create`,markpData)
    }

    async updateMarkupDetails(markpData) {
        return await axios.put(`${API_URL}/att/markup/update/${markpData.mrkreq.id}`,markpData)
    }

    async editMarkup(id) {
        return await axios.get(`${API_URL}/att/markup/edit/${id}`)
    }

    async deleteAppcableDate(id) {
        return await axios.delete(`${API_URL}/att/markup/deleteApplicable/${id}`)
    }

    async deleteRoute(data) {
        return await axios({ method: "DELETE", url: `${API_URL}/att/markup/deleteRoutes`, data: data });
    }

    async retriveFMDetails(id) {
        return await axios.get(`${API_URL}/att/markup/fm/retrieve/${id}`)
    }

    async retriveSFDetails(id) {
        return await axios.get(`${API_URL}/att/servicefee/edit_fare_map/${id}`)
    }

    async deleteFareMap(id) {
        return await axios.delete(`${API_URL}/att/markup/fm/delete/${id}`)
    }

    async deleteServiceMap(id) {
        return await axios.delete(`${API_URL}/att/servicefee/delete_fare_map/${id}`)
    }


    async deletePaxMarakup(id) {
        return await axios.delete(`${API_URL}/att/markup/fm/deletePax/${id}`)
    }

    async deleteClass(id){
        return await axios.delete(`${API_URL}/att/markup/fm/deleteClass/${id}`)
    }

    async updateFareMap(id,data){
        return await axios.put(`${API_URL}/att/markup/fm/update/${id}`, data)
    }

    async updateSrvcMap(id,updata) {
        console.log('updata for FM',updata)
        return await axios.put(`${API_URL}/att/servicefee/update_fare_map/${id}`, updata)
    }

    async deletePaxServiceFee(id) {
        let data = {}
        return await axios.delete(`${API_URL}/att/servicefee/delete_pax_map/${id}`, data)
    }

    async getSearchMarkups(data){
        return await axios.post(`${API_URL}/att/markup/get_markups`, data)
    }

    async getpromo(obj) {
        return await axios.post(`${API_URL}/att/promocode/retrieve`, obj);
    }

    async savePromoDetails(promoData) {
        return await axios.post(`${API_URL}/att/promocode/create`,promoData)
    }

    async updatePromoDetails(promoData) {
        return await axios.put(`${API_URL}/att/promocode/update/${promoData.id}`,promoData)
    }

    async editPromo(id) {
        return await axios.get(`${API_URL}/att/promocode/edit/${id}`)
    }
    async deletePromoRoute(data) {
        return await axios({ method: "DELETE", url: `${API_URL}/att/promocode/deleteRoutes`, data: data });
    }
    async deletePromoAplDates(id) {
        return await axios.delete(`${API_URL}/att/promocode/deleteApplicable/${id}`)
    }
    async deletePromoFMap(id) {
        return await axios.delete(`${API_URL}/att/promocode/fm/delete/${id}`)
    }
    async deletePromoClass(id){
        return await axios.delete(`${API_URL}/att/promocode/fm/deleteClass/${id}`)
    }
    
}
export default new AirConfigurationService()