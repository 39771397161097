import React, { Component, useState,useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";



function AirServiceConfigMenuFun(props) {
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
   /* const redirect = (event) => {
      event.preventDefault();
      if (event.target.id === "branch") {
        history.push("/ezytrip/administration/branch");
      }else if(event.target.id === "category"){
        history.push("/ezytrip/administration/category");
      }else if(event.target.id === "markup"){
        history.push("/ezytrip/administration/markup");
      }else if(event.target.id === "user"){
        history.push("/ezytrip/administration/user");
      }else if(event.target.id === "role"){
        history.push("/ezytrip/administration/role");
      }

    }*/
  //const [mainNav, setmainNav,] = useState(false);

return (

        <div className="submenu">
            <ul>
            {context.logindata.roles && context.logindata.roles.split(",").includes('M_U')  && <li  className={state.active==="Air Markup Configuration" ? 'active':''}>
              <Link
                to="/ezytrip/administration/markup"
                title="Markup"
              >
                Markup
              </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('S_F')  && <li  className={state.active==="Service Fee" ? 'active':''}>
              <Link
              to="/ezytrip/administration/serviceFee"
              title="Service Fee"
            >
              Service Fee
            </Link>
              </li>}
              {context.logindata.utype == "att_user" && context.logindata.roles && context.logindata.roles.split(",").includes('D_C')  && <li  className={state.active==="Deals" ? 'active':''}>
              <Link
              to="/ezytrip/administration/dealCode"
              title="Deals"
            >
              Deals
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('C_M')  && <li  className={state.active==="Commission" ? 'active':''}>
              <Link
              to="/ezytrip/administration/commission"
              title="Commission"
            >
             Commission
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('O_I')  && context.logindata.utype == "att_user" && <li  className={state.active==="Office Id" ? 'active':''}>
              <Link
              to="/ezytrip/administration/officeid"
              title="Office Id"
            >
              Office ID
            </Link>
              </li>}


       {context.logindata.utype == "att_user" && (
        <li className={state.active === "Extra Services" ? 'active' : ''}>
          <Link
            to="/ezytrip/administration/extraservices"
            title="Extra Services">
            Extra Services
          </Link>
        </li>)
      }
      {context.logindata.utype == "att_user" && (
        <li className={state.active === "Notification" ? 'active' : ''}>
          <Link
            to="/ezytrip/administration/notification"
            title="Notification">
            Notification
          </Link>
        </li>
      )
}
{context.logindata.utype == "att_user" &&
<li  className={state.active==="Hold Airline" ? 'active':''}>
              <Link
              to="/ezytrip/administration/holdairline"
              title="Hold Airline"
            >
              Hold Airline
            </Link>
              </li>}

        {context.logindata.roles && context.logindata.roles.split(",").includes('A_C_O') &&
            <li  className={state.active==="Airport" ? 'active':''}>
              <Link
              to="/ezytrip/administration/airport"
              title="Airport Master"
            >
             Airport Master
            </Link>
              </li>}

              {context.logindata.roles && context.logindata.roles.split(",").includes('R_V_M') &&
            <li  className={state.active==="Revenue Management" ? 'active':''}>
              <Link
              to="/ezytrip/administration/revenue"
              title="Revenue Management"
            >
             Revenue Management
            </Link>
              </li>
              }
            {/* {context.logindata.utype == "att_user" &&
              <li  className={state.active==="PromoCode" ? 'active':''}>
              <Link
              to="/ezytrip/administration/promocode"
              title="Promo Code"
            >
             Promo Code
            </Link>
              </li>
            } */}
             {context.logindata.roles && context.logindata.roles.split(",").includes('F_P_C') &&
              <li  className={state.active==="PromoCode" ? 'active':''}>
              <Link
              to="/ezytrip/administration/promocode"
              title="Promo Code"
            >
             Promo Code
            </Link>
              </li>
            }
            </ul>
          </div>

  );
};



class AirServiceConfigMenu extends Component {
  constructor(props) {
  super(props);
  console.log(props)
  this.state = {
         active:props.active

      };
  }

  render() {
  return <AirServiceConfigMenuFun state={this.state} />
}
}

export default AirServiceConfigMenu;

