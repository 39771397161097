
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
/**
 * @description: Ins  summary page operations
 * @author: Lakshmi
 * @param {*}
 * @function Insurance flow
 * @date : 20-12-2021
 */
 export const loadInsuranceSummary = async(bookingId) => {
    let response = '';
    await AttCommonSerivce.loadInsSummary(bookingId).then(resp => {
        if(resp) {
            response = resp
        }
    })
    return Promise.resolve(response)
}

