
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Alert, Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';
import { savePaymentInfo} from './operations';
import Utilities from '../../../commonUtils/Utilities';
import Processing from '../../../common/processingPopUp/Processing'
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import { Scrollbars } from 'react-custom-scrollbars';
import Paymentprocessingimg from '../../../../assets/images/Payment-Processing.gif'

let payment=false;
class PayVisa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsgs: [],
      errorExists: false,
      isLoading: false,
      pleasewait: false,
      surchrge: 100,
      bookSuc: false,
      terms: false,
      notiMessage: '',
      notiMessageShow: false,
      notiVarient: '',
      showTerms: false,
      pnr: "",
      clBal: 0,
      enablePayPopup:false,
      showCl: false, clCurrentBal: 0, payAmount: 0, overcl: false, showOverCl: false, showHoldPop: false, holdResp: "", showFailed: false
    };
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    if (this.state.terms) {
      this.setState({
        terms: false
      });
    } else {
      this.setState({
        terms: true
      });
    }
  }

 
  confirmBooking=(e)=>{
    e.preventDefault();
     if(this.state.terms){
     this.setState({
       isLoading : true,
       pleasewait:true
     })
   console.log("Confirm Booking Start+++++"+this.props.bdata.bookingId)
   let request={
      "booking_id":this.props.bdata.bookingId,
      "region_type":this.props.bdata.region_type,
      "pay": [{
        "ptype": "Credit Limit",
        "amt": this.props.bdata.price,
        "ccode": this.props.bdata.cur,
        "crbal": this.props.bdata.creditBal - this.props.bdata.price,
        "psta": "Confirmed",
        "formofpay": this.props.bdata.enableOvrwriteCL? "Override CL" : ""
      }]
   }
   savePaymentInfo(request).then((resp) => {
    console.log("Insurance Response is  : "+JSON.stringify(resp));
    if(resp.suc){
     this.props.sendResponse(resp);
    }else{
      this.setState({
        showFailed: true,
        pleasewait:false,
        isLoading:false
      });
    }

 });
   }else{
     this.setState({
       notiMessageShow : true,
       notiMessage:"Please accept terms and conditions and proceed",
       notiVarient:'danger'
     })
     //this.hidemessage()
     //setTimeout(this.setState.bind(this, {position:1}), 5000);
 }
 }


  showTermsConditions = () => {
    this.setState({
      showTerms: true
    })
  }

  handleClose = () => {
    this.setState({
      showTerms: false
    })
  }
  handleCLClose = () => {
    this.setState({
      showCl: false
    })
  }

  handleOverClose = () => {
    this.setState({
      showOverCl: false
    })
  }

  handleChangeOver = (e) => {
    const { id, value } = e.target;
    if (this.state.overcl) {
      this.setState({
        overcl: false,
        showOverCl: false,
      });
    } else {
      this.setState({
        overcl: true,
        showOverCl: true
      });
    }
  }
  confirmChangeOver = (e) => {
    const { id, value } = e.target;
    this.setState({
      overcl: true,
      showOverCl: false
    });
  }
  //Back To results page
  backToResults = (e) => {
    this.props.back()
  }

  backToHome=()=>{
    return window.location="/ezytrip/dashboard";
  }

  render() {
    return (
      <>
        <Alert className="notification" variant={this.state.notiVarient} show={this.state.notiMessageShow}
          onClose={() => this.setState({
            notiMessageShow: false
          })}
          dismissible> {this.state.notiMessage}</Alert>
          {payment ? <Processing/> : "" }

        <div key="Payment">
        {this.props.bdata!==undefined && Math.sign(this.props.bdata.creditBal)!==1 && this.props.bdata.utype !== "att_user" &&
          <Alert variant="info">
         <span>You don't have enough Balance to do the booking. Please contact Ezytrip Support Team for any queries!</span>
         </Alert>
         }
          {this.props.bdata!==undefined && this.props.bdata.enableOvrwriteCL && (this.props.bdata.utype === "att_user" || this.props.bdata.utype === "ta_user") && this.props.bdata.overwriteRole &&
            <div>
              <div className="overwrite">

                <div className="creditchk">
                  <span>The amount going to be debited from your credit balance is :</span><span><strong> {this.props.bdata.cur} <FormateCurrency value={this.props.bdata.price} /> (Purchase Amount) </strong></span>
                </div>
                <div className="creditchk">
                  <Form.Check
                    type="checkbox"
                    id="ov"
                    label="Override Credit Balance"
                    checked={this.state.overcl}
                    value={this.state.overcl}
                    onChange={this.handleChangeOver}
                    custom
                  />
                </div>

                <div className="creditchk note">
                  <span>Note : Your updated credit balance will be : <strong> {this.props.bdata.cur}<FormateCurrency value={Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub")} /></strong></span>
                </div>
              </div>
              <br />
              {this.state.overcl &&
                <>
                  <Row className="htlConfirm">
                    <Col xs="9">
                      <div className="d-flex mt-2 tc"><Form.Check
                        type="checkbox"
                        id="cb"
                        label=""
                        value={this.state.terms}
                        onChange={this.handleChange}
                        custom
                      /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>
                    </Col>
                    <Col xs="3" className="d-flex justify-content-end align-items-center">
                      <div className="d-flex justify-content-end">
                        <Button variant="success" size="lg"
                          onClick={this.confirmBooking}
                          className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
                          disabled={this.state.isLoading && this.state.pleasewait ? true : false} >
                          {this.state.isLoading ? "Processing..." : "Confirm"}
                        </Button>
                      </div>
                      {this.state.pleasewait ? <Processing /> : ""}
                    </Col>
                  </Row>
                </>
              }
            </div>
          }
        </div>

        {this.props.bdata!==undefined && this.props.bdata.isclavail && this.props.bdata.creditBal !== 0 &&
          <div className="conffirm crdt">
            <div className="totalPay mb-2">
              <span className="mr-1">The amount going to be debited from your credit balance is  : </span> <strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price, 0, "Add")} /> (Purchase Amount)</strong></div>
            <div className="totalPay">
              <span className="mr-1">The remaining credit will be  : </span><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub")} /></strong></div>
            <Row className="htlConfirm">
              <Col xs="9">
                <div className="d-flex mt-2 tc"><Form.Check
                  type="checkbox"
                  id="cb"
                  label=""
                  value={this.state.terms}
                  onChange={this.handleChange}
                  custom
                /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>

              </Col>
              <Col xs="3" className="d-flex justify-content-end align-items-center">
                <div className="d-flex justify-content-end">
                  <Button variant="success" size="lg"
                    onClick={this.confirmBooking}
                    className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
                    disabled={this.state.isLoading && this.state.pleasewait ? true : false} >
                    {this.state.isLoading ? "Processing..." : "Confirm"}
                  </Button>
                </div>
                {this.state.pleasewait ? <Processing /> : ""}
              </Col>
            </Row>
          </div>

        }
        <Modal
          show={this.state.showTerms}
          onHide={this.handleClose}
          className="importPNRMdl"
          backdrop="static"
          size="lg" dialogClassName="modal-90w"
          keyboard={false}>
          <Modal.Body>
            <h5>Agent terms of use</h5>
            <Scrollbars style={{ height: 500 }}>
              <div className="dstn">
                <p>These Agent Terms of Use (these Terms) govern the terms upon which you market Hotel Information and undertake Bookings for End Customers through the Approved Platforms.</p>
              </div>
            </Scrollbars>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.handleClose}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showOverCl}
          onHide={this.handleChangeOver}
          className="importPNRMdl creditPop"
          backdrop="static"
          keyboard={false}>
          <Modal.Body className="text-center clpopup">

            {/* <div className="popIcon" >
              <span className="icons clIcon"></span>
            </div> */}

<div className="backGroundgif">
<span className='backGroundbot'></span>
<span className='aarabQar'>QAR</span>
<img src={Paymentprocessingimg} />
            </div>
            <h4>Credit balance alert!</h4>
            <div className="dstn">
              <p>{this.props.bdata!==undefined && this.props.bdata.cname} Doesn't have sufficient credit balance</p>

              <p>Would like to continue with the Override credit balance?</p>
            </div>
            <div className="buttonGrop pb-0 border-0">
              <Button size="xs" variant="secondary" onClick={this.handleChangeOver}>
                Cancel
          </Button>
              <Button variant="primary" size="xs" onClick={this.confirmChangeOver}>
                Yes
          </Button>
            </div>
          </Modal.Body>

        </Modal>

        <Modal
          show={this.state.showFailed}
          onHide={this.backToResults}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}>
          <Modal.Body className="text-center clpopup">

            <div className="popIcon" >
              <span className="icons clIcon"></span>
            </div>
            <h4>Selected Plan is not avaialble.Please choose another Plan</h4>

          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="outline-dark" onClick={this.backToResults}>
              Back to Results
          </Button>
          </Modal.Footer>
        </Modal>


      </>
    );
  }
}

export default withRouter(PayVisa);
