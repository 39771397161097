
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../../App";
import Tabs from '../../../../components/common/tabs/Tabs';
import ConfirmPayment from '../../../paymentUnique/index';
import HoldPayment from './HoldPayment';
import OfflinePay from './OfflinePay';
export const PAY_DONE = "payDone";
/**
 * @description:This function will fire the Book request and Process the Payment
 * @author: Lakshmi
 * @param {*}
 * @function Payment details
 * @date : 03-09-2020
 */

function Payment(props) {
    const [state, setState] = useState({})
    const [enableConfirm, setEnableConfirm] = useState(true)
    const [context, setContext] = useContext(Context);
    const [payment, setPayment] = useState({
        active: 'confirm'
    });
    window.sessionStorage.removeItem(PAY_DONE)
    
    const sendBook = (data) => {
        props.sendToBook(data);
      }

    useEffect(() => {
        if (props.bookingType==="DIRECT" && props.bookingdata.utype === "sta_user") {
            if (props.showNote) {
                setPayment({ active: "hold" })
                setEnableConfirm(false);
            }
        }
    }, [props.showNote]);


    useEffect(() => {
        if (props.bookingdata.isSSR) {
                setPayment({ active: "hold" })
                setEnableConfirm(false);
        }
       }, [props.bookingdata.isSSR]);


    const content = {
        confirm: <>{enableConfirm && <ConfirmPayment sendBook={sendBook} bdata={props.bookingdata} showNote={props.showOverCL} />}</>,
        hold: <>{props.bookingdata.onhold == "yes" && props.bookingType !== "ONHOLD" && props.bookingType !== "ONHOLD REBOOK" && props.bookingType !== "IMPORT" && props.bookingdata.ttl  && <HoldPayment bdata={props.bookingdata} sendBook={sendBook} product={"Flight"} />}</>,
        offline: <>{props.bookingdata.enableOffline && props.bookingdata.utype === "att_user" && context.logindata.roles.includes('O_P_E') && <OfflinePay bdata={props.bookingdata} product={"Flight"} />}</>
    };

    return (
        <>
            <div className="paymentSec boxLayout" ref={props.refProp}>
                <h5 className="panelTitle ezyIcon icon-payment mb-4"> Payment Details</h5>
                <div className="tabLink d-flex">
                    <Tabs active={payment.active} onChange={active => setPayment({ active })}>
                        {enableConfirm && <span key="confirm" className="ezyIcon icon-confirm"> CONFIRM</span>}
                        {props.bookingdata.onhold == "yes" && props.bookingType !== "ONHOLD" && props.bookingType !== "ONHOLD REBOOK" && props.bookingType !== "IMPORT" && props.bookingdata.ttl && <span key="hold" className="ezyIcon icon-hold"> HOLD</span>}
                        {props.bookingdata.enableOffline && props.bookingdata.utype === "att_user" && context.logindata.roles.includes('O_P_E') && <span key="offline" className="ezyIcon icon-offline"> OFFLINE PAY</span>}
                    </Tabs>
                    <div className="tabContent">
                        {content[payment.active]}
                    </div>
                </div>
            </div>
        </>
    )
}


export default Payment;
