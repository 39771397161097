import Picklist from 'c2-react-picklist';
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { emailValidation, mobileNumberValidation,characternameValidation } from '../../../commonUtils/FormValidations';
import { isNotNull } from '../../../commonUtils/validators';
import { UserContext } from '../../masterServices/userManagement/UserManagement';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { getSTACompanyList, rolesList,CRMrolesList } from '../../utils/index';
import { creation,getCompanyList, reportingList, update,loadBranchList ,fetchCrmFunctionalities} from './operations';
let stdCodesMaster = require('../../../masterData/CountrySTDCodes.json');
export const COUNTRY_DATA = "COUNTRY_DATA";


/**
 * @description:This function will creaet user details and this will provide the ADD,EDITfunctionalities
 * @author: Lakshmi
 * @param {*}
 * @function User
 */

/**
* Initial State Declaration
*/
const initialState = {
  uid: 0, utyp: "", uem: "", ufn: "", uln: "", uph: "", uphc: [], cmpid: [], act: true, bid: [], rpuid: [], ctype: "TA", enableBtn: false, branchData: [], stdCodeMenu: [], reportUsers: [], companyList: [], tacompanyList: [], branchMenu: [], roles: [], branchList: [], urmap: [], ubmap: [], rpunm: null, updatable: false,
  fnError: false, lnError: false, phError: false, mailError: false,rpuError:false, disabled: false, notiMessageShow: false,region_selected:[],edit_user_response: null,isItineraryLoading:false,edit_salesChanel:"",salesChannel:"",showBranch: true, enableAlert:true, cname:""
};

const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};
function CreateUser(props) {
  const userObj = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dataState, setData] = useState({ data: [] });
  const [actState, setActive] = useState({ act: true });
  const [attDropDown, setAttDropDown] = useState(false);
  const [taDropDown, settaDropDown] = useState(false);
  const [crmStaff, setCRMStaff] = useState(false);
  const [uname, setUserName] = useState();
  const [value, setValue] = useState([]);
  const [values, setValues] = useState([]);
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [context, setContext] = useContext(Context);

  const [region,setRegion] = useState([])
  useEffect(() => {
    if(context.logindata!==undefined && state.salesChannel === "B2C" && state.region_type){
     if(context.logindata.utype==="att_user"){
      setAttDropDown(true)
     }else{
      setAttDropDown(true)
     }
    let is_admin=context.logindata.utype==="att_user"?1:0
     let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
      AttCommonSerivce.att_companies(pyload).then(response => {
        if (response.data.suc && response.data.res.status === 200) {
          dispatch({ type: "taMenu", payload: response.data.res.data });

        }
      })
    }
  }, [context.logindata,state.salesChannel,state.region_type]);
  useEffect(() => {
    if(userObj && userObj.type && userObj.type==="crm_user"){
      AttCommonSerivce.getCompanyCRMUSER(userObj.region_type).then(response => {
        if (response.data.suc && response.data.status === 200) {
          dispatch({ type: "companyList", payload: response.data.res });
        }
      })
    }
    const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: "CRM", cid: null,is_admin: context.logindata.is_admin }
    rolesList(role).then(response => {
      let roles = [];
      if (response.data.suc) {
        //  setRoles(response.data.res)
        for (let val of response.data.res.data) {
          const payload = { "value": val.rid, "label": val.rn }
          roles.push(payload);
        }
        dispatch({ type: "roles", payload: roles });
        //For Edit Role
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });
     
let cmpidArray = userObj && userObj.cmpid && userObj.cmpid.split(',').map(Number);
let cnameArray = userObj && userObj.cname && userObj.cname.split(',');

// Create an array of objects with the specified structure
let cmpd = cmpidArray && cmpidArray.map((value, index) => ({
  value,
  label: cnameArray[index]
}));

dispatch({type:'cmpid',payload:cmpd})
let structuredArray = userObj && userObj.urmap && userObj.urmap.map(value => ({
  value,
  label: userObj.role.split(', ')[userObj.urmap.indexOf(value)]
}));
pickldata(structuredArray)
setValue(structuredArray)
// context.logindata.utype="crm_user"

// role

}, [userObj && userObj.type && userObj.type==="crm_user"])
  useEffect(() => {
    if (state.salesChannel === "B2C") {
      dispatch({ type: "showBranch", payload: false });
      dispatch({ type: "selBranch", payload: [] });

    } else {
      dispatch({ type: "showBranch", payload: true });
    }
  }, [state.salesChannel]);
  const handleChange = (e) => {
    const { id, value } = e.target
    if (e.target.id === "act") {
      if (state.act) {
        setActive({ act: false })
        dispatch({ type: 'act', payload: false })
      } else {
        setActive({ act: true })
        dispatch({ type: 'act', payload: true })
      }
    } else {
    dispatch({ type: id, payload: value });
    }
    if(e.target.id == "ufn") {
      dispatch({type:"fnError",payload:false})
    }
    if(e.target.id == "uln") {
      dispatch({type:"lnError",payload:false})
    }
    if(e.target.id == "uem") {
      dispatch({type:"mailError",payload:false})
    }
    if(e.target.id == "uph") {
      dispatch({type:"phError",payload:false})
    }
  }
  const handleProfileType = (schanel) => {
    
    
    if (schanel) {
      dispatch({ type: "salesChannel", payload: schanel });
      if (schanel === "B2C") {
        dispatch({ type: "enableB2C", payload: true });
      } else {
        dispatch({ type: "enableB2C", payload: false });
      }
    }
  }

  const crmStaffChange= (e) => {
    setAttDropDown(false)
    settaDropDown(false)
    setCRMStaff(true)
    dispatch({ type: "utyp", payload: "crm_user" })
    dispatch({type:"region_selected",payload:[]})

    const role = { "utype": "crm_user", "user_id": context.logindata.uid, appto: "CRM", cid: null,is_admin: context.logindata.is_admin }
    rolesList(role).then(response => {
      let roles = [];
      if (response.data.suc) {
        //  setRoles(response.data.res)
        for (let val of response.data.res.data) {
          const payload = { "value": val.rid, "label": val.rn }
          roles.push(payload);
        }
        dispatch({ type: "roles", payload: roles });
        //For Edit Role
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });

  }

  /**
 * @description: while the radio button is ATT while att_user login
 * @author: Azamuddin
 * @date: 10-11-2020
 */
  const attStaff = (e) => {
    setAttDropDown(true)
    settaDropDown(false)
    setCRMStaff(false)
    dispatch({ type: "utyp", payload: "att_user" })
    dispatch({type:"region_selected",payload:[]})
    //getting roles when radio selected
    const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: "ATT", cid: null,is_admin: context.logindata.is_admin }
    rolesList(role).then(response => {
      let roles = [];
      if (response.data.suc) {
        //  setRoles(response.data.res)
        for (let val of response.data.res.data) {
          const payload = { "value": val.rid, "label": val.rn }
          roles.push(payload);
        }
        dispatch({ type: "roles", payload: roles });
        //For Edit Role
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });
  }
  /**
   * @description: while the radio button is TA while att_user login
   * @author: Azamuddin
   * @date: 10-11-2020
   */
  const taStaff = () => {
    setAttDropDown(false)
    settaDropDown(true)
    setCRMStaff(false)
    dispatch({ type: "utyp", payload: "ta_user" })
    dispatch({ type: "roles", payload: [] });
    dispatch({type:"region_selected",payload:[]})
    setValue([])
  }

  /**
   * @description: set company and roles data according to user ta
   * @author: Azamuddin
   * @date: 10-11-2020
   */
  function companyList(comp, userObj) {
    if (comp == true) {
      let com = [];
      if (context.logindata.cid) {
        let obj = {
          "value": context.logindata.cid,
          "label": context.logindata.cnm
        }
        com.push(obj)
      }
      dispatch({ type: 'cmpid', payload: com })
      //fetch roles for ta_user
      // /companyRoles/:id

      const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: "TA", "cid": context.logindata.cid ,is_admin: context.logindata.is_admin}
      rolesList(role).then(response => {
        let roles = [];
        if (response.data.suc) {
          //  setRoles(response.data.res)
          for (let val of response.data.res.data) {
            const payload = { "value": val.rid, "label": val.rn }
            roles.push(payload);
          }
          dispatch({ type: "roles", payload: roles });
          //For Edit Role
          if (userObj) {
            let finalRoles = [];
            roles.forEach((b1) => userObj.urmap.forEach((b2) => {
              if (Number(b1.value) === b2) {
                finalRoles.push(b1);
              }
            }
            ));
            setValue(finalRoles)
            setData({ data: finalRoles })
          }
        } else {
          //setMessage({showMessage:true})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getReportingUserList function---" + error
        );
      });
    } else {
      getSTACompanyList(context.logindata.cid).then(response => {
        if (response.data.suc) {
          dispatch({ type: "companyList", payload: response.data.res });
          let compList = [];
          for (let val of response.data.res) {
            if (val.value == userObj.cmpid) {
              let obj = {
                value: userObj.cmpid,
                label: val.label
              };
              compList.push(obj);
            }
            dispatch({ type: 'cmpid', payload: compList })
          }
          const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: "STA", "cid": userObj.cmpid,is_admin: context.logindata.is_admin }
          rolesList(role).then(response => {
            let roles = [];
            if (response.data.suc) {
              //  setRoles(response.data.res)
              for (let val of response.data.res.data) {
                const payload = { "value": val.rid, "label": val.rn }
                roles.push(payload);
              }
              dispatch({ type: "roles", payload: roles });
              //For Edit Role
              if (userObj) {
                let finalRoles = [];
                roles.forEach((b1) => userObj.urmap.forEach((b2) => {
                  if (Number(b1.value) === b2) {
                    finalRoles.push(b1);
                  }
                }
                ));
                setValue(finalRoles)
                setData({ data: finalRoles })
              }
            } else {
              //setMessage({showMessage:true})
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getReportingUserList function---" + error
            );
          });
        } else {
          //setMessage({showMessage:true})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
  }
  /**
* @description: while the radio button is TA while ta_user login
* @author: Azamuddin
* @date: 10-11-2020
*/
  const tStaff = (e) => {
    settaDropDown(true)
    dispatch({ type: "utyp", payload: "ta_user" })
    let com = [];
    if (context.logindata.cid) {
      let obj = {
        "value": context.logindata.cid,
        "label": context.logindata.cnm
      }
      com.push(obj)
    }
    dispatch({ type: 'cmpid', payload: com })
    //fetch roles for ta_user
    // /companyRoles/:id
    let applicable_to = "TA"
    if (state.utype == "STA") {
      applicable_to = "STA";
    }
    let cid = context.logindata.cid
    if (state.cmpid.length > 0) {
      cid = state.cmpid[0].value
    }
    const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: applicable_to, "cid": cid,is_admin: context.logindata.is_admin }
    console.log('tarolesreq', role)
    rolesList(role).then(response => {
      console.log('TA Roles', response)
      let roles = [];
      if (response.data.suc) {
        //  setRoles(response.data.res)
        for (let val of response.data.res.data) {
          const payload = { "value": val.rid, "label": val.rn }
          roles.push(payload);
        }
        dispatch({ type: "roles", payload: roles });
        //For Edit Role
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });
  }

  /**
 * @description: while the radio button is STA while ta_user login
 * @author: Azamuddin
 * @date: 10-11-2020
 */
  const staStaff = (e) => {
    settaDropDown(false)
    setAttDropDown(false)
    setCRMStaff(false)
    dispatch({ type: "utyp", payload: "sta_user" })
    dispatch({ type: "roles", payload: [] });
    setData({ data: [] })
    setValue([])
    dispatch({ type: 'cmpid', payload: [] })
    getSTACompanyList(context.logindata.cid).then(response => {
      if (response.data.suc) {
        dispatch({ type: "companyList", payload: response.data.res });
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });

  }

  /**
   * @description: get edit response
   * @author: Azamuddin
   * @date: 10-11-2020
   */
  const convertUserEdit = () => {
    let comp = false
    dispatch({ type: 'updatable', payload: true })
    if(userObj.act === 0) {
      dispatch({type:'act',payload:false})
    } else {
      // dispatch({type:'act',payload:true})
      state.act = true
    }
    if (userObj.utyp == "att_user") {
      setAttDropDown(true)
    }else if(userObj.utyp == "ta_user" || userObj.utyp == "TA_USER"){
      comp = true
      settaDropDown(true)
    }else if(userObj.utyp == "crm_user"){
      setCRMStaff(true)
    }
    dispatch({ type: 'utyp', payload: userObj.utyp })
    dispatch({ type: 'ufn', payload: userObj.ufn })
    dispatch({ type: 'uem', payload: userObj.uem })
    dispatch({ type: 'uln', payload: userObj.uln })
    dispatch({ type: 'uph', payload: userObj.uph })
    //setting user phone code value

    if (context.logindata.utype == "ta_user") {
      companyList(comp, userObj)
    } else {

    }
    //setForm({bid:userObj.bid,uid:userObj.uid,uem:userObj.uem, ufn:userObj.ufn,uln:userObj.uln,act:userObj.act,rpuid:"2",uph:"123456789",uphc:"91",enableBtn:true,cmpid:2,roles:[]})
  }

  /**
   * @description : fetch company by the branch selected in dropdown
   * @author: Azamuddin
   * @date: 11-11-2020
   */
  const handleBranchChange = (e) => {
    // const { id, value } = e.target
    dispatch({ type: "bid", payload: e });
    dispatch({ type: "branchData", payload: e });
    dispatch({ type: "roles", payload: [] });
    setValue([])
    dispatch({ type: "cmpid", payload: [] })
    if(e.length > 0) {
    getCompanyList(e[0].value).then(response => {
      if (response.data.suc) {
        dispatch({ type: "companyList", payload: response.data.res });
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
  } else {
    dispatch({type:"companyList",payload:[]})
  }
  }

  useEffect(() => {
    if (context.logindata !== undefined && context.logindata.utype == "ta_user") {
      dispatch({ type: "utyp", payload: "ta_user" })
      let compid = []
      let compData = {
        value: context.logindata.cid,
        label: context.logindata.cnm
      }
      compid.push(compData)
      dispatch({ type: 'cmpid', payload: compid })
    } else {
      dispatch({ type: "utyp", payload: "att_user" })
    }

    if (userObj) {

      //setting of edit response for different functional use
      state.edit_user_response = userObj;
      dispatch({type:"edit_user_response",payload:state.edit_user_response})
      //region type set for edit
    }
    //***************REGION_LEVEL HANDLE************** */

    //****---->START */
    //----------->att_user admin
    if(context.logindata !== undefined && context.logindata.utype == "att_user" && context.logindata.is_admin == 1) {
      let finalArry = [];
      let data = JSON.parse(localStorage.getItem(COUNTRY_DATA));
      // data.forEach((element)=>{
      //   finalArry.push({"label":element.con_name,"value":element.con_name})
      // })
      setRegion(data)
    } else {

      //att_staff or ta_staff
      if(context.logindata.region_type) {
        //array filteration
        // let array_load = []
        let data = JSON.parse(localStorage.getItem(COUNTRY_DATA));
        // data.forEach((element)=>{
        //   array_load.push({"label":element.con_name,"value":element.con_name})
        // })
        setRegion(data)
          if(context.logindata.utype != "att_user") {
            handleRegionChange(data)
          }
      }

      
    }

    if(userObj) {
      //region type set for edit
      if(userObj && userObj.region_type) {
        //set varience for the edit response for user
        let regions_edit = []
        let regional_response = userObj.region_type.split(",")
        let regional_array = regional_response.filter(function(s) {
          let payload = {
            "label" : s,
            "value" : s
          }
          regions_edit.push(payload)
          return s;
        })
        //region edit save
        handleRegionChange(regions_edit);
      }
    }



    //****END <------*/
    reportingList(context.logindata.uid).then(response => {
      if (response.data.suc) {
        let userList = []
        for (let val of response.data.res) {
          let paylod = {
            value: val.uid,
            label: val.fn + " " + val.ln
          }
          userList.push(paylod)
        }
        dispatch({ type: "reportUsers", payload: userList });
        if (userObj) {
          if (isNotNull(userObj.rpunm)) {
            let rp = []
            for (let val of userList) {
              if (val.label == userObj.rpunm) {
                let obj = {
                  "value": val.value,
                  "label": userObj.rpunm
                }
                rp.push(obj)
              }
            }
            dispatch({ type: 'rpuid', payload: rp })
          }
        }
      } else {
        //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });
    if (context.logindata.utype == "att_user") {
      let applicable_to = "ATT"
      if (state.utype == "TA") {
        applicable_to = "TA";
      }
      let cid = null;
      if (userObj) {
        if (userObj.utyp == "att_user") {
          applicable_to = "ATT"
        } else {
          applicable_to = "TA";
        }
        if (userObj.cmpid) {
          cid = userObj.cmpid
        }
      }
      const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: applicable_to, "cid": cid,is_admin: context.logindata.is_admin }
      console.log('rolesss', role)
      rolesList(role).then(response => {
        let roles = [];
        if (response.data.suc) {
          //  setRoles(response.data.res)
          for (let val of response.data.res.data) {
            const payload = { "value": val.rid, "label": val.rn }
            roles.push(payload);
          }
          dispatch({ type: "roles", payload: roles });
          //For Edit Role
          if (userObj) {
            let finalRoles = [];
            roles.forEach((b1) => userObj.urmap.forEach((b2) => {
              if (Number(b1.value) === b2) {
                finalRoles.push(b1);
              }
            }
            ));
            setValue(finalRoles)
            setData({ data: finalRoles })
          }
        } else {
          //setMessage({showMessage:true})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getReportingUserList function---" + error
        );
      });
    } else {
      let applicable_to = "TA"
      if (state.utype == "STA") {
        applicable_to = "STA";
      }
      let cid = context.logindata.cid
      if (state.cmpid.length > 0) {
        cid = state.cmpid[0].value
      }
      const role = { "utype": context.logindata.utype, "user_id": context.logindata.uid, appto: applicable_to, "cid": cid,is_admin: context.logindata.is_admin }
      console.log('tarolesreq', role)
      context.logindata.utype !="crm_user" &&rolesList(role).then(response => {
        console.log('TA Roles', response)
        let roles = [];
        if (response.data.suc) {
          //  setRoles(response.data.res)
          for (let val of response.data.res.data) {
            const payload = { "value": val.rid, "label": val.rn }
            roles.push(payload);
          }
          dispatch({ type: "roles", payload: roles });
          //For Edit Role
        } else {
          //setMessage({showMessage:true})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getReportingUserList function---" + error
        );
      });
    }
    let stdCodes = []
    for (let val of stdCodesMaster) {
      let obj = {
        "value": val.name,
        "label": val.name
      }
      stdCodes.push(obj)
    }
    dispatch({ type: 'stdCodeMenu', payload: stdCodes })
    if (userObj && isNotNull(userObj.uphc)) {
      dispatch({ type: 'disabled', payload: true })
      let stdeditCodes = []
      // setStdCode({stdCodeMenu:stdCodes})
      for (let val of stdCodesMaster) {
        if (val.code == userObj.uphc) {
          let payLoad = {
            "value": val.name,
            "label": val.name
          };
          stdeditCodes.push(payLoad);
        }
      }
      dispatch({ type: 'uphc', payload: stdeditCodes });
    }
    if (userObj) {
      dispatch({ type: 'disabled', payload: true })
      convertUserEdit();
    }

  }, [userObj]);


  const pickldata = (data) => {
    setData({ data: data })
  }

  const pickBranch = (data) => {
    dispatch({ type: 'branchData', payload: data })
  }

  const handleUserChange = (e) => {
    dispatch({ type: "rpuid", payload: e.length>0?e:[]})
  }
  /**
   * @description: user update call
   * @author: Laxmi & azamuddin
   * @date: 10-11-2020
   */
  const handleUserUpdate = (e) => {
    e.preventDefault();
    dispatch({ type: 'upby', payload: context.logindata.uid })
    if (!state.act && userObj?.is_admin && userObj?.comp_status){
      state.enableAlert=false
    }
    if (state.enableAlert ){
    // form.upby="laxmi@gmail.com";
    let selRoles;
    let selBranches;
    if (dataState.data) {
      selRoles = dataState.data.map((obj) => {
        if (obj.value)
          return obj.value;
      });
    }
    if (state.branchData) {
      selBranches = state.branchData.map((obj) => {
        if (obj.value)
          return obj.value;
      });
    }
    dispatch({ type: 'urmap', type: selRoles })
    dispatch({ type: 'ubmap', type: selBranches })
    // form.urmap=selRoles;
    // form.ubmap=selBranches;
    // form.rpunm=uname;
    let phn = "93"
    /**region handle for update */
    let region = ""
    if(state.region_selected?.length > 0) {
      let filter_region = state.region_selected.filter(function(s) {
          if(region == '') {
            region = s.value
          } else {
            region = region +","+ s.value
          }
      })
    }
    if(state.uphc.length > 0) {
      if(state.uphc[0].value !== "Select") {
      let bhpcSplit = state.uphc[0].value.split("(")
      let bhpc = bhpcSplit[1].split(")")
      phn = bhpc[0]
      }
    }
    const userPayload = {
      usreq: {
        "uid": userObj.uid,
        "uem": state.uem,
        "ufn": state.ufn,
        "uln": state.uln,
        "uph": state.uph,
        "uphc": phn,
        "utyp": state.utyp,
        "bid": state.bid.length > 0 && state.bid[0].value ? state.bid[0].value : null,
        "cmpid": state.cmpid!==null && state.cmpid.length > 0 && state.cmpid[0].value ? state.cmpid[0].value : null,
        "act": state.act,
        "rpuid": state.rpuid.length > 0 && state.rpuid[0].value ? state.rpuid[0].value : null,
        "upby": context.logindata.uid,
        "rpunm": state.rpuid.length > 0 && state.rpuid[0].label ? state.rpuid[0].label : null,
        "urmap": selRoles,
        "ubmap": selBranches,
        "region_type" : region
      }
    }
    let checkValid = validations(userPayload)
    if (checkValid) {

      let fn = false;
      let ln = false;
      let ph = false
      let em = false
      // fnError : false,lnError: false,phError: false,mailError: false
      if (characternameValidation(state.ufn) == true) {
        fn = true
      } else {
        dispatch({ type: 'fnError', payload: true })
      }
      if (characternameValidation(state.uln) == true) {
        ln = true
      } else {
        dispatch({ type: 'lnError', payload: true })
      }
      if (mobileNumberValidation(state.uph) == true) {
        ph = true
      } else {
        dispatch({ type: 'phError', payload: true })
      }
      if (emailValidation(state.uem) == true) {
        em = true
      } else {
        dispatch({ type: 'mailError', payload: true })
      }
      if (em && ph && ln && fn) {
        dispatch({type:"isItineraryLoading", payload:true})
        update(userPayload).then(response => {
          if (response.res &&  response.res == "Updated Successfully") {
            setNotiMessageShow(true);
            setNotiMessage('User updated successfully');
            setNotiVarient('success')
            setTimeout(function () {
            return (window.location = "/ezytrip/administration/user");
            }, 5000);
          } else {
            dispatch({type:"isItineraryLoading", payload:false})
            setNotiMessageShow(true);
            setNotiMessage('User Details Not Updated');
            setNotiVarient('danger')
            hidemessage();
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getUserData function---" + error
          );
        });
      }
    } else {
      setNotiMessageShow(true);
      setNotiMessage('Please Enter All The Mandatory Fields');
      setNotiVarient('danger')
      hidemessage();
    }
  }else{
      setNotiMessageShow(true);
      setNotiMessage(`This email ID is linked to ${userObj.cname} , so it should be disabled in the company configuration.`);
      setNotiVarient('danger')
      hidemessage();
  }
  }

  /**
   * @description : handle validation during create and update user
   * @author: Azamuddin
   * @date : 30-11-2020
   */
  const validations = (datas) => {
    let validated = false;
    let data = datas.usreq
    if (context.logindata !== "") {
      if (context.logindata.utype == "att_user" || context.logindata.utype == "crm_user") {
        if (data.utyp == "att_user") {
          if (data.region_type && data.ubmap.length > 0 && data.urmap.length > 0 && data.ufn && data.uln && data.uph && data.uphc && data.rpunm && data.uem) {
            validated = true
            return validated
            // }
          } else {
            validated = false
            return validated
          }
        } 
        else if (data.utyp == "crm_user") {
          if (data.region_type && data.urmap.length > 0 && data.ufn && data.uln && data.uph && data.uphc && data.rpunm && data.uem && data.cmpid) {
            return true
          } else {
            return validated
          }
        }
        else {
          //ta
          if (data.region_type && data.urmap.length > 0 && data.ufn && data.uln && data.uph && data.uphc && data.rpunm && data.uem && data.cmpid && data.bid) {
            return true
          } else {
            return validated
          }
        }
      } else if (context.logindata.utype == "ta_user") {
        if (data.utyp == "ta_user") {
          if ( data.urmap.length > 0 && data.ufn && data.uln && data.uph && data.uphc && data.rpunm && data.uem) {
            return true
          } else {
            return false
          }
        } else {
          if (data.region_type && data.urmap.length > 0 && data.cmpid && data.ufn && data.uln && data.uph && data.uphc && data.rpunm && data.uem) {
            return true
          } else {
            return false
          }
        }
      }
    }
  }
  /**
    * @description: user create call
    * @author: Laxmi & azamuddin
    * @date: 10-11-2020
    */
  const handleUserCreation = (e) => {
    e.preventDefault();
    dispatch({ type: 'crby', type: context.logindata.uid })
    let selRoles = [];
    let selBranches = [];
    if (dataState.data) {
      selRoles = dataState.data.map((obj) => {
        if (obj.value)
          return obj.value;
      });
    }
    if (state.branchData) {
      selBranches = state.branchData.map((obj) => {
        if (obj.value)
          return obj.value;
      });
    }
    dispatch({ type: 'urmap', type: selRoles })

    dispatch({ type: 'ubmap', type: selBranches })
    let phn = "93"

    /**region handle for save */
    let region = ""
    if(state.region_selected?.length > 0) {
      let filter_region = state.region_selected.filter(function(s) {
          if(region == '') {
            region = s.value
          } else {
            region = region+ "," + s.value
          }
      })
    }
    if (context.logindata.utype == "ta_user"){
      region=context.logindata.region_type
    }
    if(state.uphc.length > 0) {
      if(state.uphc[0].value !== "Select") {
      let bhpcSplit = state.uphc[0].value.split("(")
      let bhpc = bhpcSplit[1].split(")")
      phn = bhpc[0]
      }
    }
    let CRMCMPIF = state.cmpid!==undefined && state.cmpid.length > 0?state.cmpid.map(country => country.value).join(','):"";
    const userPayload = {
      usreq: {
        "uem": state.uem,
        "ufn": state.ufn,
        "uln": state.uln,
        "uph": state.uph,
        "uphc": phn,
        "sales_channel": state.salesChannel,
        "utyp": state.utyp,
        "bid": state.branchData.length > 0 && state.branchData[0].value ? state.branchData[0].value : null,
        "cmpid":!crmStaff && state.cmpid!==undefined && state.cmpid.length > 0 && state.cmpid[0].value?state.cmpid[0].value:CRMCMPIF,
        "act": state.act,
        "rpuid": state.rpuid.length > 0 && state.rpuid[0].value ? state.rpuid[0].value : null,
        "crby": context.logindata.uid,
        "rpunm": state.rpuid.length > 0 && state.rpuid[0].label ? state.rpuid[0].label : null,
        "urmap": selRoles,
        "ubmap": selBranches,
        "protype":!crmStaff? "B2B" :"B2C",
        "belongs_to" : !crmStaff? "B2B" : "B2C",
        "region_type" : region
      }
    }
    let checkValid = validations(userPayload)
    if (checkValid) {
      let fn = false;
      let ln = false;
      let ph = false
      let em = false
      // fnError : false,lnError: false,phError: false,mailError: false
      if (characternameValidation(state.ufn) == true) {
        fn = true
      } else {
        dispatch({ type: 'fnError', payload: true })
      }
      if (characternameValidation(state.uln) == true) {
        ln = true
      } else {
        dispatch({ type: 'lnError', payload: true })
      }
      if (mobileNumberValidation(state.uph) == true) {
        ph = true
      } else {
        dispatch({ type: 'phError', payload: true })
      }
      if (emailValidation(state.uem) == true) {
        em = true
      } else {
        dispatch({ type: 'mailError', payload: true })
      }
      if (em && ph && ln && fn) {
        dispatch({type:"isItineraryLoading", payload:true})
        creation(userPayload).then(response => {
          //console.log("Branch response : " + JSON.stringify(response));
          if (response.data.res && response.data.res.message == "saved successfully") { 
            dispatch({ type: 'mailError', payload: false })
            dispatch({ type: 'phError', payload: false })
            dispatch({ type: 'lnError', payload: false })
            dispatch({ type: 'fnError', payload: false })
            setNotiMessageShow(true);
            setNotiMessage('User saved successfully');
            setNotiVarient('success')
            setTimeout(function () {
            return (window.location = "/ezytrip/administration/user");
            }, 5000);
          } else if(response.data.suc){
            dispatch({ type: 'mailError', payload: false })
            dispatch({ type: 'phError', payload: false })
            dispatch({ type: 'lnError', payload: false })
            dispatch({ type: 'fnError', payload: false })
            setNotiMessageShow(true);
            setNotiMessage('User saved successfully');
            setNotiVarient('success')
            setTimeout(function () {
            return (window.location = "/ezytrip/administration/user");
            }, 5000);
          }else if (response.data.err && (response.data.err.includes('Duplicate')||response.data.err.includes('already') || response.data.err.includes('Something'))) {
            dispatch({type:"isItineraryLoading", payLoad:false})
            setNotiMessageShow(true);
            setNotiMessage('Email Already Exists');
            setNotiVarient('danger')
            hidemessage()
          } else {
            dispatch({type:"isItineraryLoading", payLoad:false})
            setNotiMessageShow(true);
            setNotiMessage('User has not saved..');
            setNotiVarient('danger')
            hidemessage()
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getUserData function---" + error
          );
        });
      }
    } else {
      dispatch({type:"isItineraryLoading", payload:false})
      setNotiMessageShow(true);
      setNotiMessage('Please Enter All The Mandatory Fields');
      setNotiVarient('danger')
      hidemessage();

    }
  }

  /**
   * @description : when user clicked on Reset it will clear all values
   * @author :Arshad
   * @date: 21-02-2023
   */
  const handleReset = () => {
    dispatch ({type:"region_selected", payload:[]})
    dispatch ({type:"uem", payload:""})
    dispatch ({type:"ufn", payload:""})
    dispatch ({type:"uln", payload:""})
    dispatch ({type:"uphc", payload:""})
    dispatch ({type:"uph", payload:""})
    dispatch ({type:"rpuid", payload:""})
    dispatch ({type:"bid", payload:[]})
    dispatch ({type:"cmpid", payload:[]})
    dispatch ({type:"branchList", payload:[]})
    setValue([])
    setValues([])
    let stdCodes = []
    for (let val of stdCodesMaster) {
      let obj = {
        "value": val.name,
        "label": val.name
      }
      stdCodes.push(obj)
    }
    dispatch({ type: 'stdCodeMenu', payload: stdCodes })
  }
  const handleCancel = (e) => {
    setNotiMessageShow(false)
    return window.location = "/ezytrip/administration/user";
  }
  function hidemessage() {
    setTimeout(function () {
      setNotiMessageShow(false);
      setNotiMessage('');
      setNotiVarient('')
    }, 5000);
  }

  /**
   * @description: handle company selection
   * @author: Azamuddin
   * @date: 10-11-2020
   */
  const handleCompType = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: "roles", payload: [] });
      setValue([])
      var type = "";
      if (state.utyp == "ta_user") {
        type = "TA";
      } else if (state.utyp == "sta_user") {
        type = "STA";
      }
      dispatch({ type: 'cmpid', payload: e })
      const pyload = { "utype": context.logindata.utype, "user_id": context.logindata.uid, "appto": type, "cid": e[0].value,is_admin: context.logindata.is_admin }
      rolesList(pyload).then(response => {
        let roles = [];
        if (response.data.suc) {
          for (let val of response.data.res.data) {
            const payload = { "value": val.rid, "label": val.rn }
            roles.push(payload);
          }
          dispatch({ type: "roles", payload: roles });
          if (userObj) {
            let finalRoles = [];
            roles.forEach((b1) => userObj.urmap.forEach((b2) => {
              if (Number(b1.value) === b2) {
                finalRoles.push(b1);
              }
            }
            ));
            setValue(finalRoles)
            setData({ data: finalRoles })
          }
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getReportingUserList function---" + error
        );
      });
    }
  }


  /**
   * @description: handle phonenumber
   * @author: Azamuddin
   * @date: 10-11-2020
   */
  const handlephChange = (e) => {
    dispatch({ type: 'uphc', payload: e.length>0?e:[] })
    if(e.length > 0) {
      dispatch({type:"phError",payload:false})
    }
  }
  /**
   * @description : region selection handle
   * @author : Azamuddin
   * @date : 9/1/2023
   */
  const handleRegionChange = (value) => {


    //att_user- || ta_user
    state.region_selected = value
    dispatch({type:'region_selected',payload:state.region_selected})
    
    //initiating emptying of data
    setValues([])
    state.branchData = []
    dispatch({type:"branchData",payload:state.branchData})

    if(state.region_selected ) {
      let region = '' //contains the selected  regions filtered in string

      let temp_regionfilter = state.region_selected.filter(function(s) { //filteration of the region in place of accordance to selection
        if(s) {
            if(region == '') {
              region = s.label
            } else {
              region = region+","+ s.label
            }

          }
          return s;
        }
      )

      /***********************************FILTERATION OF BRANCHES IN PLACE OF SELECTION OF REGION******************************************* */
      const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid, fetch: 'mapping', is_admin:context.logindata.is_admin,region_type : region}
      loadBranchList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: "branchList", payload: response.data.res });
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: "branchMenu", payload: response.data.res });
          //For Edit branch
          if (state.edit_user_response) {
            dispatch({ type: 'disabled', payload: true })
            if (context.logindata.utype == "att_user" && state.edit_user_response.utyp == "att_user") {
              let finalBranch = [];
              response.data.res.forEach((b1) => state.edit_user_response.ubmap.forEach((b2) => {
                if (Number(b1.value) === b2) {
                  finalBranch.push(b1);
                }
              }
              ));
              setValues(finalBranch)
              dispatch({ type: 'branchData', payload: finalBranch });
            } else if (context.logindata.utype == "att_user" && state.edit_user_response.utyp == "ta_user" && state.edit_user_response.bid) {
              let finalBranch = [];
              let carr = []
              for (let val of response.data.res) {
                if (val.value == state.edit_user_response.bid) {
                  let obj = {
                    "value": state.edit_user_response.bid,
                    "label": val.label
                  }
                  finalBranch.push(obj)
                }
              }
              dispatch({ type: "bid", payload: finalBranch })
              dispatch({ type: "branchData", payload: finalBranch })
              if (finalBranch.length > 0) {
                getCompanyList(finalBranch[0].value).then(response => {
                  if (response.data.suc) {
                    // dispatch({ type: "companyList", payload: response.data.res });

                    if (state.edit_user_response.cmpid) {
                      for (let val of response.data.res) {
                        if (val.value == state.edit_user_response.cmpid) {
                          let obj = {
                            "value": state.edit_user_response.cmpid,
                            "label": val.label
                          }
                          carr.push(obj)
                        }
                      }
                      dispatch({ type: 'cmpid', payload: carr })
                    }
                  } else {
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCompanyList function---" + error
                  );
                });
              }
            }

          }
        } else {
          //setMessage({showMessage:true})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }
  }
 //CRM Staff type
  const handleCrmChange = (value) => {
    dispatch({ type: "roles", payload: [] });
    dispatch({ type: "companyList", payload: [] });
    state.region_selected = value
    dispatch({type:'region_selected',payload:state.region_selected})

    const countryValues = state.region_selected.map(country => country.value);
    let reg = countryValues.join(',')

    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_selected,sales_channel:"B2C"}
    AttCommonSerivce.getCompanyCRMUSER(reg).then(response => {
      if (response.data.suc && response.data.status === 200) {
        dispatch({ type: "companyList", payload: response.data.res });
      }
    })
    //Load CRM Roles
    const role = { "appto":"CRM"}
    CRMrolesList(role).then(response => {
      let roles = [];
      if (response.data.suc){
        for (let val of response.data.res.data) {
          const payload = { "value": val.rid, "label": val.rn }
          roles.push(payload);
        }
        dispatch({ type: "roles", payload: roles });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getReportingUserList function---" + error
      );
    });
  }

  const handleCompanyChange = (value) => {
    state.cmpid = value
    dispatch({type:'cmpid',payload:state.cmpid})
  }
  return (
    <>
      <Alert className="notification" variant={notiVarient} show={notiMessageShow}
        onClose={() => setNotiMessageShow(false)}
        dismissible> {notiMessage}</Alert>
      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
      <form onSubmit={e => handleUserCreation(e)}>

        <Form.Row className="justify-content-between">
          {context.logindata.utype !== "ta_user" &&
            <Form.Group as={Col} className="inputCheck"  >
              <Form.Label>Staff Type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  className="mr-4"
                  label="ATT Staff"
                  name="staffType"
                  id="attStadd"
                  disabled={state.disabled}
                  onChange={attStaff}
                  checked={attDropDown}
                  custom
                />
                <Form.Check
                  type="radio"
                  label="TA Staff"
                  name="staffType"
                  // value={state.utyp}
                  id="taStaff"
                  disabled={state.disabled}
                  onChange={taStaff}
                  checked={taDropDown}
                  custom
                />
                <Form.Check
                  type="radio"
                  label="CRM Staff"
                  name="staffType"
                  id="crmStaff"
                  disabled={state.disabled}
                  onChange={crmStaffChange}
                  checked={crmStaff}
                  custom
                />
              </div>
            </Form.Group>
          }
          {context.logindata.utype == "ta_user" &&
            <Form.Group as={Col} className="inputCheck"  >
              <Form.Label>Staff Type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  className="mr-4"
                  label="TA"
                  name="staffType"
                  id="attStadd"
                  onClick={tStaff}
                  disabled={state.disabled}
                  checked={taDropDown}
                  custom
                />
                <Form.Check
                  type="radio"
                  label="STA"
                  name="staffType"
                  // value={state.utyp}
                  id="taStaff"
                  onClick={staStaff}
                  disabled={state.disabled}
                  checked={!taDropDown}
                  custom
                />
              </div>
            </Form.Group>
          }
        </Form.Row>
        {/* ATT STAFF */}
        {attDropDown && context.logindata.utype !== "ta_user" && (
        <Form.Row>
            <>
            <Col xs={12}>
            <Form.Group as={Col} xs={3} className="selectplugin pl-0" controlId="regionType">
            <Select
               multi
               options={region}
               searchable={true}
               values={state.region_selected}
               onChange={handleRegionChange}
               />
             <Form.Label>Region<sup>*</sup></Form.Label>
            </Form.Group>
            </Col>
            <Form.Group as={Col} className="picklist" xs={6}>
              <Form.Label>Branch<sup>*</sup></Form.Label>
              <Picklist
                options={state.branchList}
                value={values}
                onChange={(values) => (setValues(values), pickBranch(values))}
                leftPaneLabel="Available Branches"
                rightPaneLabel="Selected Branches"
              />
            </Form.Group>
            </>
      </Form.Row>
       )}
          {/* TA STAFF */}
        {taDropDown && context.logindata.utype !== "ta_user" && (
        <Form.Row>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="regionType">
            <Select
               options={region}
               searchable={true}
               values={state.region_selected}
               onChange={handleRegionChange}
            />
             <Form.Label>Region<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
              <Select
                options={state.branchMenu}
                searchable={true}
                values={state.branchData}
                onChange={handleBranchChange}
              />
              <Form.Label>Branch<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
              <Select
                options={state.companyList}
                searchable={true}
                values={state.cmpid}
                onChange={(value) => (handleCompType(value))}
              />
              <Form.Label>Company<sup>*</sup></Form.Label>
            </Form.Group>
            </Form.Row>
        )}   
         {crmStaff && context.logindata.utype !== "ta_user" && (
        <Form.Row>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="regionType">
        <Select
               multi
               options={region}
               searchable={true}
               values={state.region_selected}
               onChange={handleCrmChange} />
             <Form.Label>Region<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
              <Select
                multi
                options={state.companyList}
                searchable={true}
                values={state.cmpid}
                onChange={handleCompanyChange}
              />
              <Form.Label>Company<sup>*</sup></Form.Label>
            </Form.Group>
            </Form.Row>
        )}
          <Form.Row>
          <Form.Group as={Col} className="picklist mb-0" xs={6}>
            <Form.Label>Roles<sup>*</sup></Form.Label>
            <Picklist
              options={state.roles}
              value={value}
              onChange={(value) => (setValue(value), pickldata(value))}
              leftPaneLabel="Available Roles"
              rightPaneLabel="Selected Roles"
            />
           </Form.Group>
            </Form.Row>
         {/* TA USER LOGIN */}
           <Form.Row>
          {context.logindata.utype === "ta_user" && taDropDown == false && (
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="cmpid">
              <Select
                options={state.companyList}
                searchable={true}
                values={state.cmpid}
                onChange={(value) => (handleCompType(value))}
              />
              <Form.Label>Company<sup>*</sup></Form.Label>
            </Form.Group>
          )}
        </Form.Row>
        
        <hr className="borderLine" />
        <Form.Row>
          {!state.enableBtn && (
            <Form.Group as={Col} xs={3} controlId="uem">
              <Form.Control type="text" placeholder="User Email" value={state.uem} onChange={handleChange} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>
              {state.mailError ? (
                <p className="vError">Please Enter A Valid Email </p>
              ) : (
                  <p className="note">Note : This Email id will be consider as User name</p>
                )}
            </Form.Group>
          )}
          {state.enableBtn && (
            <Form.Group as={Col} xs={3} controlId="uem">
              <Form.Control type="text" disabled="true" value={state.uem} onChange={handleChange} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>
              {state.mailError && (
                <p className="vError">Please Enter A Valid Email </p>
              )}
              <p className="note">Note : This Email id will be consider as User name</p>

            </Form.Group>
          )}
          <Form.Group as={Col} xs={3} controlId="ufn">
            <Form.Control type="text" placeholder="First Name" value={state.ufn} onChange={handleChange} />
            <Form.Label>First Name <sup>*</sup></Form.Label>
            {state.fnError && (
              <p className="vError">Please Enter A Valid First Name </p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="uln">
            <Form.Control type="text" placeholder="Last Name" value={state.uln} onChange={handleChange} />
            <Form.Label>Last Name <sup>*</sup></Form.Label>
            {state.lnError && (
              <p className="vError">Please Enter A Valid Last Name </p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="uphc">
            <Select
              options={state.stdCodeMenu}
              searchable={true}
              values={state.uphc}
              onChange={(value) => (handlephChange(value))}
            //onChange={(value) => (setDates(value))}
            />
            <Form.Label>Country code <sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="uph">
            <Form.Control type="text" placeholder="Contact Number" value={state.uph} onChange={handleChange} />
            <Form.Label>Contact Number <sup>*</sup></Form.Label>
            {state.phError && (
              <p className="vError">Please Enter A Valid Contact Number </p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="rpuid">
            <Select
              options={state.reportUsers}
              searchable={true}
              values={state.rpuid}
              onChange={(value) => (handleUserChange(value))}
            //onChange={(value) => (setDates(value))}
            />
            <Form.Label>Reporting To <sup>*</sup></Form.Label>
            {state.rpuError && (
              <p className="vError">Please Select Reporting User </p>
            )}
          </Form.Group>
        </Form.Row>
      </form>
      <div className="switchPlus buttonGrop pb-0">
        <Form.Group as={Col} className="topSwitch" controlId="act">
          <Form.Check
            type="switch"
            className="mr-3"
            id="act"
            label="Status"
           checked={state.act} value={state.act} onChange={handleChange}
          />
        </Form.Group>
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={handleCancel}
        > Back</Button>
        {!state.updatable && !props.view && (
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleReset}
          > Reset</Button>
        )}
        {!state.updatable && (
          <Button
            size="xs"
            variant="outline-primary"
            type="submit"
            onClick={handleUserCreation}
          > Save</Button>
        )}
        {state.updatable && !props.view && (
          <Button
            size="xs"
            variant="outline-primary"
            type="submit"
            onClick={handleUserUpdate}
          > Update</Button>
        )}

      </div>

    </>
  )
}
export default CreateUser