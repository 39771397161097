
import React, { useState } from 'react';
import Select from 'react-dropdown-select';

function ReactDropdownSelect() {

    const SelectOptions = [{ label: "Albania", value: 355 },
    { label: "Argentina", value: 54 },
    { label: "Austria", value: 43 },
    { label: "Cocos Islands", value: 61 },
    { label: "Kuwait", value: 965 },
    { label: "Sweden", value: 46 },
    { label: "Venezuela", value: 58 }]


    return (
        <>
            <Select
                options={SelectOptions}
                values={[]}
                onChange={(value) => console.log(value)}
                searchable={false}
            /><br/> 
            <Select
                multi
                options={SelectOptions}
                values={[]}
                onChange={(value) => console.log(value)}
            />
        </>
    )
}

export default ReactDropdownSelect
