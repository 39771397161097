
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import OfficieIdConfig from '../../../../services/administration/OfficieIdConfig';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */

export const getSuppliersData = async (obj) => {
    let holdBookigsList = [];
    await AttCommonSerivce.getOfficeID(obj).then(response => {
        if (response.data.suc) {
            holdBookigsList = response.data.res;
        }
    });
    return Promise.resolve(holdBookigsList);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.saveMaxHits(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.updateMaxHits(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};


export const deleteMaxHits = async (obj) => {
    let finalRes = {};
    await AttCommonSerivce.deleteMaxHits(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const getOfcIdList = async (obj) => {
    let companies = [];
    await OfficieIdConfig.getOIDMasterData(obj).then(response => {
        companies = response;
    });
    return Promise.resolve(companies);
}
