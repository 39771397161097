
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, {useState } from 'react';
import logo from "../../assets/images/email/jpg/logoEzyTrip.jpg";
import hotelImage from "../../assets/images/email/jpg/htlimg.jpg";
import star from "../../assets/images/email/jpg/star.jpg";
import map from "../../assets/images/email/jpg/map.jpg";
import mapimg from "../../assets/images/map.jpg";
// Create styles
const styles = StyleSheet.create({
  page: {marginTop: '0', height:'100%', padding:'30px', paddingTop: '20px'},
  main: {
    width: '100%',
    height: '140mm',
    marginBottom: '15px',
    fontFamily: 'Roboto',
    backgroundColor: '#fff',

    margin: '10 0 ',


  },

  table: { margin: '0 0 10px', border: '1 solid #ccc',  borderRadius: '4px', padding:'5px 10px 10px'},
  mainSec: {

    fontFamily: 'Roboto',
    width: '100%',


  },
  img: {
    width: 60,
    height: '24',
    padding: '1',
  },
  hotelImages: {
    width: 80,
    height: 80,
    borderRadius: '10px',
  },
  sta: {
    width: '10px',
    height: '10px',
  },
  mapIcon: {
    width: '8px',
    marginRight: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10',
    fontFamily: 'Roboto',
  },
  heading: {
    fontSize:'20px',
    color: '#000',
    textAlign: 'center',
    textTransform :'uppercase',
  },
  hedingunderline:{
    textDecoration: 'underline',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '14px',
    color: '#475F7B',
    textAlign: 'center',
    marginBottom: '10px',
  },
  tableIn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  companyDetails:{
    display:'flex',
    flexDirection: 'row'
  },
  logo :{
    display: 'flex',
    justifyContent: 'top',
    paddingRight: '15px',
  },
  companyName: {
    fontSize: '12px'
  },
  lablList: {
    display:'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
  },
  lablListPlus: {
    display:'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    width:'400px'
  },
  tid :{
    color:'#475F7B',
  },
  confStatus:{
    color:'#1DAD81',
  },
  Secheading :{
    fontSize: '12px',
    backgroundColor: '#e7e7e7',
    padding: '4px 6px',
    borderRadius: '4px',
    margin:'0 0 15px',
  },
  hotelPart: {
    display:'flex',
    flexDirection: 'row',
    marginBottom:'15px,'
  },
  hotelImage: {
    marginRight: '10px',
    width: '160px'
  },
  hotelName:{
    display :'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px'
  },
  maneHtl :{
    fontSize: '12px',
    marginRight: '10px',
  },
  hotelAdres:{
    display:'flex',
    flexDirection:'row',
  },
  adress:{
    color:'#888888',
    fontSize: '10px',
  },
  infolist:{
    display:'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
  },
  time:{
    color:'#F26F22',
  },
  roomType:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'11px',
    marginBottom:'6px',
  },
  tableHeading:{
    borderTop:'1px solid #ccc',
    borderBottom:'1px solid #ccc',
    padding:'6px 0',
    display:'flex',
    flexDirection:'row',
    color:'#00367A',
    fontSize:'11px',
  },
  headingTaxt:{
    width:'20%',
  },
  tableData:{
    padding:'6px 0',
    display:'flex',
    flexDirection:'row',
    color:'#888',
    fontSize:'10px',
  },
  status:{
    color:'#1DAD81 ',
  },
  SpecialRequest:{
    border:'1px dashed #CACED5',
    marginTop:'5px',
    padding:'6px',
    borderRadius:'6px',
    fontSize:'11px',
  },
  note:{
    border:'1px dashed #CED7E5',
    marginTop:'5px',
    padding:'6px',
    borderRadius:'6px',
    fontSize:'11px',
    backgroundColor:'#F3F4F8',
  },
  spCont:{
    color:'#888',
    fontSize:'10px',
    marginTop:'4px',
    lineHeight:'1.5px',
  },
  noteTitl:{
    color:'#00367A',
    fontWeight:'bold',
  },
  dueAtLay:{
    fontSize:'10px',
    fontWeight:'normal',
    color:'#888',
    margin:'10px 0',
  },
  maplayout:{
    width:'1000px',
  },
  copy:{
    textAlign:'right',
    fontSize:'8px',
    marginTop:'10px',
    fontStyle:'italic',
    color:'#00367A',
  },
});
function PDFDemo(props) {


  return (
    <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
      <Document>
      <Page size="A4" style={styles.page} wrap>
          <View style={styles.heading}>
          <Text style={styles.hedingunderline}>Hotel Voucher</Text>
          </View>
          <View style={styles.subheading}>
            <Text style={styles.subhedingunderline}>Present at the hotel when Check-In</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableIn}>
            <View style={styles.companyDetails}>
            <View style={styles.logo}>
              <Text >
                <Image style={styles.img} src={logo} />
              </Text>
            </View>
            <View>
            <View style={styles.lablList}>
              <Text style={styles.companyName}>Travel Company</Text>
              </View>
              <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Conatct No : </Text>
              <Text style={styles.tid}>+91 9533806170</Text>
              </View>
              <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Email Id : </Text>
              <Text style={styles.tid}>test@gmail.com</Text>
              </View>

            </View>
            </View>
            <View>
            <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Booking ID : </Text>
              <Text style={styles.tid}>B2105061012</Text>
              </View>
              <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Booking Date : </Text>
              <Text style={styles.tid}>Thu, 06 May, 2021</Text>
              </View>
              <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Booking Status: </Text>
              <Text style={styles.tid}>
              <Text style={styles.confStatus}>Confirmed</Text>
                </Text>
              </View>
              </View>
            </View>
          </View>
          <View>
          <Text style={styles.Secheading}>Hotel Details</Text>
          </View>
          <View style={styles.hotelPart}>
          <View style={styles.hotelImage}>
          <Image style={styles.hotelImages} src={hotelImage} />
          </View>
          <View style={styles.rightPart}>
            <View style={styles.hotelName}>
            <Text style={styles.maneHtl}>Tulip Hotel Apartments Bur Dubai</Text>
            <Text style={styles.star}>
            <Image style={styles.sta} src={star} />
            <Image style={styles.sta} src={star} />
            <Image style={styles.sta} src={star} />
            <Image style={styles.sta} src={star} />
            </Text>
            </View>
            <View style={styles.hotelAdres}>
            <Image style={styles.mapIcon} src={map} />
            <Text style={styles.adress}>Tulip Hotel Apartments Bur Dubai</Text>
            </View>
            <View style={styles.bookInfo}>
             <View style={styles.infolist}>
             <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>No. Of Nts : </Text>
              <Text style={styles.tid}>4</Text>
              </View>
              <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>Phone  : </Text>
              <Text style={styles.tid}> +91 9533806170</Text>
              </View>
             </View>
             <View style={styles.infolist}>
             <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>No. Of Rooms : </Text>
              <Text style={styles.tid}>4</Text>
              </View>
              <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>Email   : </Text>
              <Text style={styles.tid}> Tulip.info@gmail.com</Text>
              </View>
             </View>
             <View style={styles.infolist}>
             <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>Check-In : </Text>
              <Text style={styles.tid}>15/12/2020 <Text style={styles.time}>14:00</Text></Text>
              </View>
              <View style={styles.lablListPlus}>
              <Text style={styles.taskLabl}>	Check-Out : </Text>
              <Text style={styles.tid}>18/12/2020 <Text style={styles.time}>12:00</Text></Text>
              </View>
             </View>

            </View>


          </View>
          </View>
          <View>
          <Text style={styles.Secheading}>Guest Itinerary</Text>
          </View>
          <View style={styles.table}>
          <View style={styles.roomType}>
             <Text style={styles.taskLabl}>Room 1 : Deluxe Room </Text>
              <Text style={styles.ref}>Supplier Ref No <Text style={styles.tid}> : 273-336421</Text></Text>
          </View>

          <View style={styles.tableHeading}>
          <Text style={styles.headingTaxt}>Guest Name </Text>
          <Text style={styles.headingTaxt}>Occupancy </Text>
          <Text style={styles.headingTaxt}>Board Type </Text>
          <Text style={styles.headingTaxt}>Hotel Conf No</Text>
          <Text style={styles.headingTaxt}>Status</Text>
          </View>
          <View style={styles.tableData}>
          <Text style={styles.headingTaxt}>Peter Anderson </Text>
          <Text style={styles.headingTaxt}>1 Adult	 </Text>
          <Text style={styles.headingTaxt}>	Room Only </Text>
          <Text style={styles.headingTaxt}>273-336421</Text>
          <Text style={styles.headingTaxt}><Text style={styles.status}>Confirmed</Text></Text>

          </View>
           <View style={styles.SpecialRequest}>
          <Text style={styles.spTitl}>Special Request</Text>
          <Text style={styles.spCont}>Top floor, Non Smoking Room, King Size Bed, Extra Bed, Late Check-out</Text>
          </View>
          <View style={styles.SpecialRequest}>
          <Text style={styles.spTitl}>Cancellation Policy</Text>
          <Text style={styles.spCont}>Free Cancellation before May 8-2021,20:30:00 of local hotel timing.1 night(s) amount will be debited if cancelled between May 8-2021,20:30:00 - May 9-2021,20:30:00 of local hotel timing</Text>
          </View>

          </View>
          <View style={styles.table}>
          <View style={styles.roomType}>
             <Text style={styles.taskLabl}>Room 1 : Deluxe Room </Text>
              <Text style={styles.ref}>Supplier Ref No <Text style={styles.tid}> : 273-336421</Text></Text>
          </View>

          <View style={styles.tableHeading}>
          <Text style={styles.headingTaxt}>Guest Name </Text>
          <Text style={styles.headingTaxt}>Occupancy </Text>
          <Text style={styles.headingTaxt}>Board Type </Text>
          <Text style={styles.headingTaxt}>Hotel Conf No</Text>
          <Text style={styles.headingTaxt}>Status</Text>
          </View>
          <View style={styles.tableData}>
          <Text style={styles.headingTaxt}>Peter Anderson </Text>
          <Text style={styles.headingTaxt}>1 Adult	 </Text>
          <Text style={styles.headingTaxt}>	Room Only </Text>
          <Text style={styles.headingTaxt}>273-336421</Text>
          <Text style={styles.headingTaxt}><Text style={styles.status}>Confirmed</Text></Text>

          </View>
           <View style={styles.SpecialRequest}>
          <Text style={styles.spTitl}>Special Request</Text>
          <Text style={styles.spCont}>Top floor, Non Smoking Room, King Size Bed, Extra Bed, Late Check-out</Text>
          </View>
          <View style={styles.SpecialRequest}>
          <Text style={styles.spTitl}>Cancellation Policy</Text>
          <Text style={styles.spCont}>Free Cancellation before May 8-2021,20:30:00 of local hotel timing.1 night(s) amount will be debited if cancelled between May 8-2021,20:30:00 - May 9-2021,20:30:00 of local hotel timing</Text>
          </View>
          </View>
          <View style={styles.note}>
          <Text style={styles.spCont}><Text style={styles.noteTitl}>Note:</Text> Free Cancellation before May 8-2021,20:30:00 of local hotel timing.1 night(s) amount will be debited if cancelled between May 8-2021,20:30:00 - May 9-2021,20:30:00 of local hotel timing</Text>
          </View>
          <View style={styles.note}>
          <Text style={styles.noteTitl}>Important Info</Text>
          <Text style={styles.spCont}>Free Cancellation before May 8-2021,20:30:00 of local hotel timing.1 night(s) amount will be debited if cancelled between May 8-2021,20:30:00 - May 9-2021,20:30:00 of local hotel timing</Text>
          </View>

          <View style={styles.dueAtLay}>
          <Text style={styles.dueAt}><Text style={styles.noteTitl}>Due at property:</Text> Free Cancellation before</Text>
          </View>
          <View>
          <Image style={styles.mapimg} src={mapimg} />
          </View>
          <View>
            <Text style={styles.copy}>Copyright © 2021 ezytrip. All rights reserved.</Text>
          </View>

        </Page>
      </Document></PDFViewer>
    )
}


export default PDFDemo;
