import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";


//This function will show the Air markups List and Add Markup
function BookingDetails(props) {


  return (
    <>
        <div className="resultSection boxShadow bg-white summary mb-4">
          <Row className="pb-2 secondSpancolor">
       <Col>
       <span>Booking Ref Number :</span> <span>{props.data.booking_id}</span>
       </Col>
       <Col>
       <span>Booking Status : </span> <span className={props.data.booking_status == "Confirmed" ? ('text-success'):
       props.data.booking_status == "In Complete" ? ('text-primary') : ""}>{props.data.booking_status}</span>
       </Col>
       <Col>
       <span>Customer Name: </span> <span>{props.data.customer_name}</span>
       </Col>

       <Col>
       <span>Agency:</span> <span>{props.data.compnay_name}</span>
       </Col>
          </Row>
        <Row className="pb-2 secondSpancolor">
       <Col>
       <span>Booking Date:</span> <span>{props.data.booking_date}</span>
       </Col>
       <Col>
       <span>Channel: </span> <span>Online</span>
       </Col>
       <Col>
       <span>Phone:  </span><span>{props.data.phone_no}</span>
       </Col>
       <Col>
       <span>Booking By:  </span><span>{props.data.booked_by}</span>
       </Col>

        </Row>
        <Row className="pb-2 secondSpancolor">
       <Col>
       <span>Email:</span> <span>{props.data.email}</span>
       </Col>
       <Col>
       <span>Bound Type:  </span><span>{props.data.bound_type==="0"?"Outbound":"Inbound"}</span>
       </Col>
       {props.data.bound_type==="1" &&
       <Col>
       <span>Sector:  </span><span>{props.data.bound_type==="1"?props.cnt_name +"- Qatar":""}</span>
       </Col>
       }
       {props.data.bound_type==="0" &&
      <Col>
      <span></span>
      </Col>
       }
       <Col>
       <span></span>
       </Col>

        </Row>
        </div>

    </>
  )
}
export default BookingDetails