import React, {useState,useEffect,useReducer,useContext,useRef } from 'react';
import log from "loglevel";
import { Col, Row, Form, Modal, Button,Alert } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Footer from '../../common/Footer';
import Header from '../../common/Header';
import BookingDetails from './BookingDetails';
import PolicyDetails from './PolicyDetails';
import PlanCover from './PlanCover';
import PassangerDetails from './PassangerDetails';
import {loadInsuranceSummary} from './operations';
import PaymentDetails from './PaymentDetails';
import InsFareDetails from '../../bookingFlows/insurance/confirmation/InsFareDetails';
import InsuranceMainPdf from './downloadDocs/InsuranceMainPdf';
import Tabs from '../../common/tabs/Tabs';
import { useReactToPrint } from "react-to-print";
import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
import { sendConfirmationObj } from '../../bookingFlows/insurance/review/operation';

import { emailValidation } from "../../commonUtils/FormValidations";
import { Context } from "../../../../App";
export const IS_LATEST = "IS_LATEST";
/**
  * Initial State Declaration
  */
 const initialState = { notiMessageShow: false, notiMessage: '', notiVarient: '',showPopup: false, email: ""
 ,notiMessage:"",notiVarient:"" };

 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {
   switch (action.type) {
     default:
       return { ...state, [action.type]: action.payload };
   }
 };
//This function will show the Air markups List and Add Markup
function InsuranceSummary(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [insResponse,setInsResponse] = useState("")
  const [openMore,setOpenMOre] = useState(false)
  const [context, setContext] = useContext(Context);
  const [buser_type , setBuser_type] = useState("sta_user")
  const [isInvoice , setIsInvoice] = useState(true)
  const [isItinarary , setIsItinaray] = useState(false)
  const [downloadtype , setDownloadtype] = useState("INVOICE")
  const [enableDownload , setEnableDownload] = useState(false)
  const [showPdf , setShowPdf] = useState(false)
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: insResponse.booking_details !== undefined && insResponse.booking_details.booking_id !== undefined && ("INS_" + downloadtype + "_" + insResponse.booking_details.booking_id)
  }
  );
  useEffect(() => {
    let booking_ref="";
    if (isNotNull(window.location.pathname)) {
      let url_array = window.location.pathname.split("/");
      booking_ref = url_array[url_array.length - 1];
    } else {
      let url_array1 = window.location.href.split("/");
      booking_ref = url_array1[url_array1.length - 1]
    }
    loadInsuranceSummary(booking_ref).then((response) => {
    if(response !== undefined && response.data.suc){
      setInsResponse(response.data.res)
      }else{
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'No data found...'})
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      }
    });

  }, [])
  const shareItinerary = (data) =>{
    sendConfirmationObj(data).then(response=>{
      if(response.suc){
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response.res })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }
     }).catch(function (error) {
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'email', payload: "" })
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
   }
  const sendEmail = ()=>{
    let booking_ref="";
    if (isNotNull(window.location.pathname)) {
      let url_array = window.location.pathname.split("/");
      booking_ref = url_array[url_array.length - 1];
    } else {
      let url_array1 = window.location.href.split("/");
      booking_ref = url_array1[url_array1.length - 1]
    }
    validateEmail()
    if(validateEmail()){
      const payload = {
        "email": state.email,
        "tid": insResponse && insResponse.booking_details.tid,
        "booking_id": insResponse && insResponse.booking_details.booking_id
             }
       let aa = shareItinerary(payload)

      // if(Number(state.b_status) == 23){
      //   if(state.flow_type == "normal"){
      //     if(state.flt_supplier == "0"){
      //       if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE/SPLIT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT/SPLIT"){
      //         if(state.splittid == undefined || state.splittid == ""){
      //           state.splittid = state.summaryRes[0].bkng_det[0].tid
      //            }
      //       const payload = {
      //         "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //         "tid": state.splittid,
      //         "email_id":state.email,
      //         "sup": state.summaryRes[0].bkng_det[0].supplier,
      //         "jtype": state.summaryRes[0].bkng_det[0].journey_type,
      //         "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //        }
      //        shareItinerary(payload)
      //       }
      //       else if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE" || state.summaryRes[0].bkng_det[0].bktype == "DIRECT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT/REISSUE" || state.summaryRes[0].bkng_det[0].bktype == "INCOMPLETE"){
      //       const payload = {
      //         "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //         "tid":  state.summaryRes[0].bkng_det[0].tid,
      //         "email_id":state.email,
      //         "sup": state.summaryRes[0].bkng_det[0].supplier,
      //         "jtype": state.summaryRes[0].bkng_det[0].journey_type == "1" ? "O" : "O/R",
      //         "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //        }
      //        shareItinerary(payload)
      //       }
      //     }
      //     else{
      //       const payload1 = {
      //         "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //         "tid": state.summaryRes[0].bkng_det[0].tid,
      //         "email_id":state.email,
      //         "sup": state.summaryRes[0].bkng_det[0].supplier,
      //         "jtype": "O",
      //         "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //        }
      //        shareItinerary(payload1)
      //        if(state.summaryRes[0].bkng_det[0].journey_type !== "1"){
      //         setTimeout(() => {
      //           const payload2 = {
      //             "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //             "tid": state.summaryRes[0].bkng_det[0].tid,
      //             "email_id":state.email,
      //             "sup": state.summaryRes[0].bkng_det[0].supplier,
      //             "jtype": "R",
      //             "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //            }
      //          shareItinerary(payload2)
      //         }, 10000);
      //       }
      //     }
      //   }
      //   else{
      //     if(state.summaryRes[1].seg_det[0].supplier == "0" && state.summaryRes[1].seg_det[1].supplier == "0"){
      //       if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE/SPLIT"){
      //         if(state.splittid == undefined){
      //           state.splittid = state.summaryRes[0].bkng_det[0].tid
      //            }
      //       const payload = {
      //         "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //         "tid": state.splittid,
      //         "email_id":state.email,
      //         "sup": state.summaryRes[0].bkng_det[0].supplier,
      //         "jtype": state.summaryRes[0].bkng_det[0].journey_type,
      //         "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //        }
      //        shareItinerary(payload)
      //       }
      //     else if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE" || state.summaryRes[0].bkng_det[0].bktype == "DIRECT" || state.summaryRes[0].bkng_det[0].bktype == "INCOMPLETE"){
      //     const payload = {
      //       "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //       "tid": state.fbfreturn ? state.summaryRes[2].pax_det[1][0].tid : state.summaryRes[2].pax_det[0][0].tid,
      //       "email_id":state.email,
      //       "sup": state.summaryRes[0].bkng_det[0].supplier,
      //       "jtype": state.fbfreturn ? "R" : "O",
      //       "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //      }
      //      shareItinerary(payload)
      //     }
      //   }
      //   else{
      //     const payload1 = {
      //       "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //       "tid": state.summaryRes[0].bkng_det[0].tid,
      //       "email_id":state.email,
      //       "sup": state.summaryRes[0].bkng_det[0].supplier,
      //       "jtype": "O/R",
      //       "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //      }
      //      shareItinerary(payload1)
      //     //  if(state.summaryRes[0].bkng_det[0].journey_type !== "1"){
      //     //   setTimeout(() => {
      //     //     const payload2 = {
      //     //       "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
      //     //       "tid": state.summaryRes[0].bkng_det[0].tid,
      //     //       "email_id":state.email,
      //     //       "sup": state.summaryRes[0].bkng_det[0].supplier,
      //     //       "jtype": "R",
      //     //       "ftype": state.summaryRes[0].bkng_det[0].flow_type
      //     //      }
      //     //    shareItinerary(payload2)
      //     //   }, 10000);
      //     // }
      //   }
      // }
      // }

    }
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }

  const handleCancel = (e) => {
    return window.location="/ezytrip/dashboard";
  }
  const openshareevent = () => {
    dispatch({ type: 'showPopup', payload: true })
  }
  const handleChangeemail = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
       dispatch({ type: 'email', payload: trimmedEmail })
   }
   const handleemailClose = ()=>{
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
    // dispatch({ type: 'splittid', payload: "" })
    // dispatch({ type: 'fbfreturn', payload: false })
   }
   const validateEmail = ()=>{
    if(state.email == ""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     else if(!emailValidation(state.email)){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     return true
   }
  const openInsuranceMOre = () => {
    if (openMore) {
      setOpenMOre(false)
    } else {
      setOpenMOre(true)
    }
  }

   //For tabs selection
   const [refPriceTab, setRefPriceTab] = useState({
    active: "TA",
  });

  useEffect(() => {
    if (context.logindata.utype !== undefined) {
      if (context.logindata.utype === "ta_user" || context.logindata.utype === "att_user") {
        setRefPriceTab({ active: "TA" })
      } else if (context.logindata.utype === "sta_user") {
        setRefPriceTab({ active: "STA" })
      }
    }
  }, [context.logindata.utype]);

  const selectedstatushandleChange = (e) => {
     if (e.target.id === "invoice") {
       setDownloadtype("INVOICE");
       setIsItinaray(false);
       setIsInvoice(true);
       setShowPdf(false);
     }else if (e.target.id === "itinerary") {
      setDownloadtype("ITINERARY");
      setIsItinaray(true);
      setIsInvoice(false);
      setShowPdf(false);
     }
   }

   const openDownload=()=>{
     setEnableDownload(true);
   }

   const enablePdf=()=>{
    setShowPdf(true);
   }


  return (
    <>
      <Header />
      {state.notiMessageShow &&
           <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
         }
      {insResponse!=="" &&
      <div className="container confirmation insuranceConfirm">
        <div className='summarytopSec'>


        {window.sessionStorage.getItem(IS_LATEST)!==null &&
        <Button
                 variant="link"
                className="backbtn ezyIcon icon-rightArrow"
                onClick={handleCancel}
              >Back</Button>
        }
        {insResponse.booking_details.booking_status==="Confirmed" &&<div className="printSec">
          <Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link">Print Summary</Button>
        </div>}
        </div>
        <div className="resultSection boxShadow bg-white summary transactionSummeryinsu mb-4">
          <h5 className="title">Booking Details</h5>
          <div className='bookingListinfo'>
          <BookingDetails data={insResponse.booking_details} cnt_name={insResponse.policy_details.src_country_name}/>
          </div>
        </div>
        <div className="resultSection boxShadow bg-white PassengerDetails  mb-4">
          <div className='poDetails'>
          <h5 className="title d-flex justify-content-start">Policy Detail</h5>
          <h5 className="title d-flex justify-content-end">
            {insResponse.booking_details.booking_status==="Confirmed" &&
            <div className='socialIcons'>
          <Button className="prient ezyIcon icon-share" onClick={openshareevent} variant="link"></Button>
          </div>
            }
            {insResponse.booking_details.booking_status==="Confirmed" &&
            <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openInsuranceMOre}>
        <div className="resultSection boxShadow bg-white mb-4">
        <div className={"showDetails " + (openMore ? 'active' : 'inactive')}>
            <ul className="d-flex flex-column">
            <li>
              {/* <Link to="#" onClick={openDownload}>Download Docs</Link> */}
              {insResponse.policy_details.qic_report_url!==null &&
              <a href={insResponse.policy_details.qic_report_url}>Download Voucher</a>
              }
             {insResponse.policy_details.policy_report_url!==null &&
              <a href={insResponse.policy_details.policy_report_url}>Download Policy T&C</a>
             }
            </li>
              </ul>
            </div>
      </div>
      </span>
       }
      </h5>

          </div>
          <div className='bookingListinfo'>
         <PolicyDetails policy={insResponse.policy_details}/>
         </div>

         <div className='schInsu'>
         <h5 className="title"><span  className="prient ezyIcon icon-plussheild"></span>{insResponse.plan_details.plan.scheme_name} Plan</h5>
         <PlanCover plans={insResponse.plan_details.plan}/>
         </div>
         <p>&nbsp;</p>
        </div>
        {/* <div className="resultSection boxShadow bg-white schemeDetails mb-4">
        <h5 className="title">{insResponse.plan_details.plan.scheme_name} Plan</h5>

         <PlanCover plans={insResponse.plan_details.plan}/>
        </div> */}
        <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
          <h5 className="title mb-2">Passenger Details</h5>
         <PassangerDetails paxList={insResponse.pax_details}/>
        </div>
        <div className="resultSection bg-white boxShadow PassengerfareDetails mb-4">
          <h5 className="title mb-2">Fare Details (QAR)</h5>
        <InsFareDetails plans={insResponse.plan_details.plan}/>
        </div>

        <div className="resultSection bg-white boxShadow PassengerDetails mb-4">
          <h5 className="title mb-2">Payment Details</h5>
        <PaymentDetails payments={insResponse.pay_details}/>
        </div>

        </div>
       }

       {/* Download Docs popup--start */}
       {enableDownload &&
             <Modal
               show={enableDownload}
               onHide={()=>setEnableDownload(false)}
               className="downloadInvoice"
               backdrop="static"
               keyboard={false}
               size="lg"
             >
               <Modal.Header closeButton className="pb-0"><h5>Download Docs</h5></Modal.Header>
               <Modal.Body className="pb-0 pt-0" >
                 <div className="tabLink tabList">
                   {(context.logindata.utype === "ta_user" || context.logindata.utype === "att_user") &&
                     <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                       <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                       {buser_type === "sta_user" &&
                         <span key="STA">Sub Travel Agent</span>
                       }
                     </Tabs>
                   }
                   {context.logindata.utype === "sta_user" && buser_type === "sta_user" &&
                     <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                       <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                     </Tabs>
                   }
                   <div className="tabContent">
                     <div className="searchbyforDownload">
                       <Form.Row>
                         <Form.Group as={Col} xs={6} controlId="appon" className="inputCheck">
                           <Form.Label>Download <sup></sup></Form.Label>
                           <div className="d-flex align-items-center">
                             <Form.Check type="radio" label="Invoice" className="ml-0" name="Invoice" id="invoice" onChange={selectedstatushandleChange} checked={isInvoice} custom />
                             <Form.Check type="radio" label="Itinerary" className="ml-3" name="Itinerary" id="itinerary" onChange={selectedstatushandleChange} checked={isItinarary} custom />
                           </div>
                         </Form.Group>

                         <Form.Group as={Col} controlId="appon" className="vewbutton text-right mb-0">
                           <Button
                             size="xs"
                             variant="primary"
                             onClick={enablePdf}
                             type="submit"
                           >Download</Button>
                         </Form.Group>
                       </Form.Row>
                     </div>
                     <div className="linkforinvoice">
                       {insResponse!==undefined &&
                          <div style={{ display: "none" }}>
                          <InsuranceMainPdf ref={componentRef} compLogo={context.logindata.comp_logo_url} response={insResponse} type={downloadtype}/>
                        </div>
                       }
                        {insResponse!==undefined && showPdf &&
                          <Link className="prient mr-3 ezyIcon icon-pdf" to="#" onClick={handlePrint}></Link>
                        }
                       </div>
                   </div>
                 </div>
               </Modal.Body>

             </Modal>
           }
           <Modal
              show={state.showPopup}
              onHide={handleemailClose}
              className="importPNRMdl shareIpop"
              backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h5>Share Itinerary</h5>
                <Row>
                <Col xs={10}>
                <Form.Group controlId="uem">
                    <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
                    <Form.Label>Email Id <sup>*</sup></Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={2}>
                <div className="d-flex justify-content-center">
                  <Button variant="outline-dark" onClick={sendEmail}>Submit
                </Button>
                  </div>
                </Col>
                </Row>
              </Modal.Body>
            </Modal>
      <Footer />
    </>
  )
}
export default InsuranceSummary