import BranchCreationService from '../../../../services/administration/BranchCreationService';
import OnholdConfig from '../../../../services/administration/OnholdConfig';
/**
 * @description: CRUD operations for Branch
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */

export const getairlineData = async (id) => {
    let airlineList = [];
    await OnholdConfig.getAirlineData().then(response => {
        if (response.data.suc) {
            airlineList = response.data.res;
        }
    });
    return Promise.resolve(airlineList);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const airlineCreation = async (obj) => {
    let finalRes = {};
    const saveObj = {
        ac: obj.ac,
        fcnt: obj.fcnt,
        tcnt: obj.tcnt,
        fair: obj.fair,
        tair: obj.tair,
        rbd: obj.rbd,
        type: obj.type
    };
    await OnholdConfig.saveAirline(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const airlineUpdate = async (obj) => {
    const updateObj = {
        "ac": obj.ac,
        "fair": obj.fair,
        "fcnt": obj.fcnt,
        "id": obj.id ,
        "rbd": obj.rbd,
        "tair": obj.tair,
        "tcnt": obj.tcnt,
        "type": obj.type
    }
    let finalRes = {};
    await OnholdConfig.updateAirline(updateObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
export const airlineDelete = async (obj) => {
    const deleteObj = {
        id: obj.id
    }
    let delete_res = [];
    await OnholdConfig.deleteAirline(deleteObj).then(response => {
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}