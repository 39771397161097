import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import Tooltip from "react-simple-tooltip";
import { Context } from "../../../../App";
import { processRefund } from '../../postBooking/summary/operations';
import Button from '../../common/buttons/Button';
import Footer from '../../common/Footer';
import Header from '../../common/Header';
import Utilities from '../../commonUtils/Utilities';
import RefundSummary from './RefundSummary.js';

/**
 * Initial State Declaration
 */
const initialState = {
    taxPopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', remarks: "", attPaxPrice: [], taPaxPrice: [], staPaxPrice: [], showPopup: false,setPtype : "",enableLoader: false, notiMessage: '',
    othrPriceAdt: 0 ,othrPriceChd:0,othrPriceInf:0,othrPricetaAdt:0,othrPricetaChd:0,othrPricetaInf:0,othrPriceStaAdt:0,othrPriceStaChd:0,othrPriceStaInf:0,
    fareUPriceAdt: 0 ,fareUPriceChd:0,fareUPriceInf:0,fareUPricetaAdt:0,fareUPricetaChd:0,fareUPricetaInf:0,fareUPriceStaAdt:0,fareUPriceStaChd:0,fareUPriceStaInf:0,count:0
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {

    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};
function RefundRecord(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [othrPriceAdt, setOthrPriceAdt] = useState(0);
    const [othrPriceChd, setOthrPriceChd] = useState(0);
    const [othrPriceInf, setOthrPriceInf] = useState(0);

    const [othrPricetaAdt, setOthrPriceTAAdt] = useState(0);
    const [othrPricetaChd, setOthrPriceTAChd] = useState(0);
    const [othrPricetaInf, setOthrPriceTAInf] = useState(0);

    const [othrPriceStaAdt, setOthrPriceSTAAdt] = useState(0);
    const [othrPriceStaChd, setOthrPriceSTAChd] = useState(0);
    const [othrPriceStaInf, setOthrPriceSTAInf] = useState(0);



    const [fareUPriceAdt, setfareUPriceAdt] = useState(0);
    const [fareUPriceChd, setfareUPriceChd] = useState(0);
    const [fareUPriceInf, setfareUPriceInf] = useState(0);

    const [fareUPricetaAdt, setfareUPriceTAAdt] = useState(0);
    const [fareUPricetaChd, setfareUPriceTAChd] = useState(0);
    const [fareUPricetaInf, setfareUPriceTAInf] = useState(0);

    const [fareUPriceStaAdt, setfareUPriceSTAAdt] = useState(0);
    const [fareUPriceStaChd, setfareUPriceSTAChd] = useState(0);
    const [fareUPriceStaInf, setfareUPriceSTAInf] = useState(0);

    const [prices, setprices] = useState("");
    const [chdprices, setCHDprices] = useState("");
    const [infprices, setINFprices] = useState("");
    const [type, setType] = useState("");
    const [refundData, setRequest] = useState(props.location.state);
    const [resp, setResp] = useState();
    const history = useHistory();
    const [refund, setRefund] = useState(props.location.state.req)
    //   let refund = require('../../../masterData/Reissue.json');

    const cancelRefund = () => {
        return window.location = "/ezytrip/dashboard/bookingsummary/" + refundData.bref;
    }
    //Calculating the ATT,TA and STA Summary
    useEffect(() => {
        if (refund.data.psngrs !== undefined) {
            refundSummaryChanges()
        }
    }, []);

    //data to show in refund summary components
    const refundSummaryChanges = () => {
        let attList = []; let taList = []; let staList = [];
        for (let pax of refund.data.psngrs) {
            if(state.count === 0) {
                if(pax.ptype == "ADT") {
                    for (let price of pax.price) {
                        if(price.type == "ATT") {
                            price.initVal = price.subtotal
                            setfareUPriceAdt(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "TA") {
                            price.initVal = price.subtotal
                            setfareUPriceTAAdt(price.fare_used  && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "STA"){
                            price.initVal = price.subtotal
                            setfareUPriceSTAAdt(price.fare_used   && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        }
                    }
                }

                if(pax.ptype == "CHD") {
                    for (let price of pax.price) {
                        if(price.type == "ATT") {
                            price.initVal = price.subtotal
                            setfareUPriceChd(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "TA") {
                            price.initVal = price.subtotal
                            setfareUPriceTAChd(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "STA"){
                            price.initVal = price.subtotal
                            setfareUPriceSTAChd(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        }
                    }
                }

                if(pax.ptype == "INF") {
                    for (let price of pax.price) {
                        if(price.type == "ATT") {
                            price.initVal = price.subtotal
                            setfareUPriceInf(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "TA") {
                            price.initVal = price.subtotal
                            setfareUPriceTAInf(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        } else if(price.type == "STA"){
                            price.initVal = price.subtotal
                            setfareUPriceSTAInf(price.fare_used && price.fare_used.price ? Number(price.fare_used.price) : 0)
                        }
                    }
                }
                dispatch({type:"count",payload:1})
            }
            for (let price of pax.price) {
                if (price.type === "ATT") {
                    var taxes = Number(calculateTax(price));
                    const att = {
                        "ptype": pax.ptype,
                        "subtotal": price.subtotal,
                        "count": pax.count,
                        "refunSupPrice": Utilities.addPrice(price.basefare + taxes - price.aircharge - price.ocharges - price.fare_used.newprice, pax.count, "Cnt"),
                        "refundToCl": Utilities.addPrice(price.subtotal, pax.count, "Cnt")
                    }
                    attList.push(att)
                } else if (price.type === "TA") {

                    const ta = {
                        "ptype": pax.ptype,
                        "subtotal": price.subtotal,
                        "count": pax.count,
                        "refunSupPrice": Utilities.addPrice(pax.price[0].subtotal, pax.count, "Cnt"),
                        "refundToCl": Utilities.addPrice(price.subtotal, pax.count, "Cnt")
                    }
                    taList.push(ta);
                } else if (price.type === "STA") {
                    const sta = {
                        "ptype": pax.ptype,
                        "subtotal": price.subtotal,
                        "count": pax.count,
                        "refunSupPrice": Utilities.addPrice(pax.price[1].subtotal, pax.count, "Cnt"),
                        "refundToCl": Utilities.addPrice(price.subtotal, pax.count, "Cnt")
                    }
                    staList.push(sta);
                }
            }
            pax.attlist = attList;
            pax.talist = taList;
            pax.stalist = staList;
            dispatch({ type: 'attPaxPrice', payload: attList })
            dispatch({ type: 'taPaxPrice', payload: taList })
            dispatch({ type: 'staPaxPrice', payload: staList })
        }
    }
    //Calculating the TAX
    const calculateTax = (price) => {
        const total = price.taxes.reduce((prev, t) => {
            return Utilities.addPrice(prev, t.newprice, "Add");
        }, 0);
        return total;
    }

    //Proceess Refund flow
    const procesRefundFlow = () => {
        dispatch({type:'enableLoader',payload: true})
        refund.data.tkt_user_type=context.logindata.utype;
        refund.data.tkt_user_id=context.logindata.uid;
        refund.data.journey_type=props.location.state.journey_type;
        refund.data.region_type=props.location.state.region_type;
        refund.data.req_cur=props.location.state.req_cur;
        processRefund(refund.data).then((resp) => {
            dispatch({ type: 'showPopup', payload: false });
            if (resp.suc) {
                let canList = [];
                resp.data.type=refund.type;
                for (let val of resp.data.psngrs) {
                canList = val.tktnum.filter(obj => (obj.st === 42 || obj.st === 44));
                }
                if (canList.length !== 0 && canList !== undefined) {
                    gotoSummary(true, resp.data);
                } else {
                    gotoSummary(false, resp.data);
                }
            } else {
                gotoSummary(false, resp.data);
            }
            dispatch({type:'enableLoader',payload: false})
        })
    }

    const gotoSummary = (suc, response) => {
        if (suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Refund processed Successfully..' })
            dispatch({ type: 'notiVarient', payload: 'success' });
            setTimeout(() => {
                return window.location = '/ezytrip/dashboard/bookingsummary/' + props.location.state.bref;
                // history.push({
                //     pathname: '/ezytrip/dashboard/bookingsummary/' + props.location.state.bref,
                //     state: {
                //         req: response,
                //         type: props.location.state.type
                //     }
                // });
            }, 2000)
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Unable to process the Refund for selected tickets' })
            dispatch({ type: 'notiVarient', payload: 'danger' });
            setTimeout(() => {
                history.push({
                    pathname: '/ezytrip/dashboard/bookingsummary/' + props.location.state.bref,
                    state: {
                        req: undefined,
                        type: props.location.state.type
                    }
                });
            }, 2000)
        }

    }

    const handleChange = (obj, idx , type) => (e) => {
        let { id, value } = e.target;
        if(value===undefined || value===''){
            value=parseInt(0);
        }
        obj.ocharges = parseInt(value);
        if (obj.orgtotal == undefined || obj.orgtotal == "") {
            obj.orgtotal =obj.orgsubTtl && obj.type !== 'ATT' ? obj.orgsubTtl : parseInt(obj.initVal)
        }
        if (obj.orgtotal) {
            let condt_value = 0;
                        if(obj.type == 'TA') {
                            if(type == "ADT") {
                                condt_value = parseInt(state.othrPriceAdt)
                            }else if(type == "CHD") {
                                condt_value = parseInt(state.othrPriceChd)
                            }else if(type == "INF") {
                                condt_value =  parseInt(state.othrPriceInf)
                            }
                         }else if(obj.type == "STA") {
                            if(type == "ADT") {
                                condt_value =  parseInt(state.othrPricetaAdt)
                            }else if(type == "CHD") {
                                condt_value =  parseInt(state.othrPricetaChd)
                            }else if(type == "INF") {
                                condt_value =  parseInt(state.othrPricetaInf)
                            }
                        }
                        // parent fare used calculated
                        let pfc = 0
                        if(obj.parentfareUchanged) {
                            pfc = obj.parentfareUchanged
                        }
                        //this calculates if fare used has been applied and changes are done to the other charges
                        let fare_used = 0
                        if(obj.fareChanged) {
                            fare_used = parseInt(obj.fareChanged)
                        }
            obj.subtotal = parseInt(obj.orgtotal - (parseInt(value) + condt_value) - fare_used);
            obj.orgFUtotal = parseInt(obj.orgtotal - (parseInt(value) + condt_value));
        }
        if (obj.type === "ATT") {
            if(type==="ADT"){
            setOthrPriceAdt(value);
            state.othrPriceAdt = value
            updateOtherCharges(type,"TA",value,obj.fareChanged)
            }else if(type==="CHD"){
            setOthrPriceChd(value);
            state.othrPriceChd = value
            updateOtherCharges(type,"TA",value,obj.fareChanged)
            }else if(type==="INF"){
            setOthrPriceInf(value);
            state.othrPriceInf = value
            updateOtherCharges(type,"TA",value,obj.fareChanged)
            }
        } else if (obj.type === "TA") {
            if(type==="ADT"){
                setOthrPriceTAAdt(value);
                state.othrPricetaAdt = value
                updateOtherCharges(type,"STA",value,obj.fareChanged)
            }else if(type==="CHD"){
                setOthrPriceTAChd(value);
                state.othrPricetaChd = value
                updateOtherCharges(type,"STA",value,obj.fareChanged)
            }else if(type==="INF"){
                setOthrPriceTAInf(value);
                state.othrPricetaInf = value
                updateOtherCharges(type,"STA",value,obj.fareChanged)
            }
        } else if (obj.type === "STA") {
            if(type==="ADT"){
                setOthrPriceSTAAdt(value);
                state.othrPriceStaAdt = value
            }else if(type==="CHD"){
                setOthrPriceSTAChd(value);
                state.othrPriceStaChd = value
            }else if(type==="INF"){
                setOthrPriceSTAInf(value);
                state.othrPriceStaInf = value
            }
        }
        refundSummaryChanges()

    }
    //Calculating  the Other Charges
    const updateOtherCharges=(paxType,type,value,fareChanged)=>{
        for(let pax of refund.data.psngrs){
            if(pax.ptype===paxType){
                for(let price of pax.price){
                    if(price.type===type){
                        let condt_value = 0;
                        if(type == 'TA') {
                            if(paxType == "ADT") {
                                condt_value = parseInt(state.othrPriceAdt) + parseInt(state.othrPricetaAdt)
                            }else if(paxType == "CHD") {
                                condt_value = parseInt(state.othrPriceChd) +  parseInt(state.othrPricetaChd)
                            }else if(paxType == "INF") {
                                condt_value =  parseInt(state.othrPriceInf) +  parseInt(state.othrPricetaInf)
                            }
                         }else if(type == "STA") {
                            if(paxType == "ADT") {
                                condt_value =  parseInt(state.othrPricetaAdt) +  parseInt(state.othrPriceStaAdt)
                            }else if(paxType == "CHD") {
                                condt_value =  parseInt(state.othrPricetaChd) +  parseInt(state.othrPriceStaChd)
                            }else if(paxType == "INF") {
                                condt_value =  parseInt(state.othrPricetaInf) +  parseInt(state.othrPriceStaInf)
                            }
                        }

                        // }
                        if (price.orgairCharge == undefined || price.orgairCharge == "") {
                            price.orgairCharge = parseInt(price.aircharge)
                        }
                        if (price.orgairCharge) {
                            price.aircharge = Utilities.addPrice(price.orgairCharge,value, "Add");
                        }
                        if (price.orgsubTtl == undefined || price.orgsubTtl == "") {
                            price.orgsubTtl = parseInt(price.initVal)
                        }
                        if(value) {
                            price.parentOcharges = value
                        }
                        if (price.orgsubTtl) {
                            let fc = 0 //farecharged for the parent type
                            if(fareChanged) {
                                fc = fareChanged
                            }
                            let fare_used = 0 //farecharge for the type
                            if(price.fareChanged) {
                                fare_used = parseInt(price.fareChanged)
                            }
                            price.subtotal = parseInt(price.orgsubTtl - condt_value) - fare_used;
                            price.parentfareUchanged = fareChanged ? fareChanged : 0
                            price.orgFUtotal = parseInt(price.orgsubTtl - condt_value)
                        }
                        break;
                    }
                }
            }
        }
    }

    /**
     * @description : the update farechanges are done here and force rendered to original price.
     * @author : Azamuddin
     * @date : 14-04-2021
     */
    const updateFareUCharges=(paxType,type,value)=>{
        for(let pax of refund.data.psngrs){
            if(pax.ptype===paxType){
                for(let price of pax.price){
                    let initialFare = 0
                    if(price.type===type){
                        let condt_value = 0;
                        if(type == 'TA') {
                            if(paxType == "ADT") {
                                condt_value = parseInt(state.fareUPriceAdt) + parseInt(state.fareUPricetaAdt)
                            }else if(paxType == "CHD") {
                                condt_value = parseInt(state.fareUPriceChd) +  parseInt(state.fareUPricetaChd)
                            }else if(paxType == "INF") {
                                condt_value =  parseInt(state.fareUPriceInf) +  parseInt(state.fareUPricetaInf)
                            }
                         }else if(type == "STA") {
                            if(paxType == "ADT") {
                                condt_value =  parseInt(state.fareUPricetaAdt) +  parseInt(state.fareUPriceStaAdt)
                            }else if(paxType == "CHD") {
                                condt_value =  parseInt(state.fareUPricetaChd) +  parseInt(state.fareUPriceStaChd)
                            }else if(paxType == "INF") {
                                condt_value =  parseInt(state.fareUPricetaInf) +  parseInt(state.fareUPriceStaInf)
                            }
                        }

                        // }
                        if (price.orgfareused == undefined || price.orgfareused == "") {
                            price.orgfareused = parseInt(price.fare_used.price)
                            initialFare = parseInt(price.fare_used.price)
                        }
                        // if (price.orgfareused) {
                        //     price.fare_used.newprice = Utilities.addPrice(price.orgfareused,value, "Add");
                        // }
                        if (price.orgFUtotal == undefined || price.orgFUtotal == "") {
                            price.orgFUtotal = parseInt(price.subtotal)
                        }
                        if (price.orgFUtotal) {
                            price.subtotal = parseInt(price.orgFUtotal - condt_value);
                            price.parentfareUchanged = value
                        }
                        break;
                    }
                }
            }
        }
    }

    const handleChangeTaxes = (code, idx,type) => (e) => {
        let data = null

        //data re-renders with according to the pax type
        if(type === "ADT") {
            data = prices
        } else if(type === "CHD") {
            data = chdprices
        } else if(type === "INF") {
            data = infprices
        }
        let newResp = data.taxes.map((tax, sidx) => {
            const { id, value } = e.target;
            if (idx !== sidx && code !== tax.code) return tax;
            else {
                if (code === tax.code && idx === sidx) {
                    return { ...tax, newprice: value ? parseInt(value) : 0 };
                } else {
                    return tax;
                }
            }
        });
        if(type ==="ADT") {
        setprices({ ...prices, taxes: newResp })
        } else if(type ==="CHD") {
            setCHDprices({...chdprices,taxes: newResp })
        } else if(type ==="INF") {
            setINFprices({...infprices,taxes: newResp})
        }
        for (let pax of refund.data.psngrs) {
            let ptype = pax.ptype //refund pax type
            for (let pr of pax.price) {
                 if(ptype===type){ //checking the refunded passenger data paxtype with the selected paxtype
                let oldTax = calculateTax(pr);
                let wild  = pr.subtotal
                pr.taxes = newResp;
                let newTax = calculateTax(pr);

                let tap = pr.subtotal
                let orgtap = pr.subtotal
                let poc = pr.parentOcharges ? parseInt(pr.parentOcharges) : 0
                let ztap = parseInt(pr.initVal) - pr.ocharges
                pr.subtotal = Utilities.addPrice(pr.subtotal - oldTax, newTax, "Add");

                if(pr.orgtotal) {
                    tap = pr.orgtotal
                }
                if(pr.orgsubTtl) {
                    orgtap = parseInt(pr.initVal)
                }
                pr.orgtotal = Utilities.addPrice(tap - oldTax, newTax, "Add");
                pr.orgsubTtl = Utilities.addPrice(orgtap - oldTax, newTax, "Add");
                pr.orgFUtotal = Utilities.addPrice(parseInt(pr.initVal) - poc - pr.ocharges - oldTax, newTax, "Add");
                pr.initVal = Utilities.addPrice(pr.initVal - oldTax,newTax,"Add")
                 }
            }
        }
        //set refund summary data change in order to cross check in drop tables with updated values
        refundSummaryChanges()
        const payload = {
            "type": data.type,
            "basefare": data.basefare,
            "totalfare": data.totalfare,
            "bkmarkup": data.bkmarkup,
            "bkservice": data.bkservice,
            "canmarkup": data.canmarkup,
            "canservice": data.canservice,
            "aircharge": data.aircharge,
            "supcharge": data.supcharge,
            "supsfee": data.supsfee,
            "ocharges": data.ocharges,
            "subtotal": data.subtotal,
            "ttlrefund": data.ttlrefund,
            "taxes": newResp
        }
        calculateTax(payload);
    }

    const callTaxes = (priceObj, type,utype) => () => {
        if(utype === "ADT") {

            //set priceObj to Adult
        setprices(priceObj);
        dispatch({type:"setPtype",payload:"ADT"})

        } else if(utype === "CHD") {

            //set priceObj to Child
            setCHDprices(priceObj);
            dispatch({type:"setPtype",payload:"CHD"})

        } else if(utype === "INF") {

            // set price obj for infant
            setINFprices(priceObj);
            dispatch({type:"setPtype",payload:"INF"})

        }
        setType(type);
        dispatch({ type: 'taxPopup', payload: true });
    }


    const showPopup = () => {
        dispatch({ type: 'showPopup', payload: true });
    }
    const handleCLClose = () => {
        dispatch({ type: 'showPopup', payload: false });
    }

    const handleTaxClose = (type) => {
        let data = null
        if(type === "ADT") {
        data = prices
    } else if(type === "CHD") {
        data = chdprices
    } else if(type === "INF") {
        data = infprices
    }
    let valid = true
    let newResp = data.taxes.map((tax, sidx) => {
        if(parseInt(tax.newprice) === 0) {
          valid = false
        }
    });
        if(valid) {
           dispatch({ type: 'taxPopup', payload: false });
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Taxes Should be more than 0' })
                dispatch({ type: 'notiVarient', payload: 'danger' });
            setTimeout(() => {
                dispatch({ type: 'notiMessageShow', payload: false })
                dispatch({ type: 'notiMessage', payload: '' })
                dispatch({ type: 'notiVarient', payload: '' });
            },5000)
        }


    }

    /**
     * @description :fare used calculations
     * @author : Azamuddin
     * @date : 14-04-2021
     */
    const updateFUchanges = (obj, idx , type) => (e) => {
        let { id, value } = e.target;
        if(value===undefined || value===''){
            value=parseInt(0);
        }
        for(let val of obj.price) {
             if (val.orgFUtotal == undefined || val.orgFUtotal == "") {
            val.orgFUtotal =val.orgFUtotal && val.type !== 'ATT' ? val.orgFUtotal : parseInt(val.initVal)
            }
            if(val.orgFUtotal) {
                let initialVal = 0
              //if intial time then add the price and remove the new price
                        if(Number(val.fare_used.newprice) === Number(val.fare_used.price)) {
                            initialVal = Number(val.fare_used.newprice)
                        }
            val.subtotal = parseInt(val.orgFUtotal + initialVal - parseInt(value));

        //     // 1---> subtotal + oldfareused - comingvalue
        //     // 2===> subtotal - comingvalue
        }
        val.fare_used.newprice = parseInt(value);
        val.fareChanged = parseInt(value)
        }
        if(obj.ptype == "ADT") {
            setfareUPriceAdt(value)
        } else if(obj.ptype == "CHD") {
            setfareUPriceChd(value)
        } else {
            setfareUPriceInf(value)
        }
        refundSummaryChanges()
    }
    return (
        <>

            <Header />

            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
            <div className="container confirmation refundRecord">
                <div className="topSec summarytopSec">
                    <Button
                        variant="link"
                        className="backbtn ezyIcon icon-rightArrow"
                        onClick={cancelRefund}
                    >Back</Button>

                </div>
                {refund !== undefined && refund.data.psngrs !== undefined &&
                    refund.data.psngrs.map((pax, index) => (
                        <React.Fragment key={index}>
                            <div className="resultSection boxShadow bg-white recordInfo tax mb-4 pb-0">
                                <h5 className="title d-flex align-items-center">Passenger Details :
                                        {pax.ptype === "ADT" &&
                                 <span className='d-inline-block ml-1'> Adult</span>
                                    }
                                    {pax.ptype === "CHD" &&
                                        <span className='d-inline-block ml-1'>Child</span>
                                    }
                                    {pax.ptype === "INF" &&
                                        <span className='d-inline-block ml-1'>Infant</span>
                                    } ( <span className="ezyIcon icon-person"></span> X {pax.count} ) {index === 0 && refund.rtype}</h5>

                                <div className="perDetils mt-2">
                                    <ul className='p-2'>
                                        {pax.tktnum.map((tkt, index) => (
                                            <li className="ezyIcon icon-confirmedOne"> {tkt.pname} <span> (Tkt# : {tkt.orgTktNum})</span></li>
                                        ))}
                                    </ul>
                                </div>

                                <h5 className="title d-flex align-items-center mt-2"><span className="ezyIcon icon-refund mr-1"></span> Refund Details ({refundData.req_cur})</h5>
                                <Row className="m-0">
                                    <Col xs={12} className="p-0">
                                        <Table>
                                            {pax.price.map((price, idx) => (
                                                <React.Fragment key={idx}>
                                                    {idx === 0 &&
                                                        <thead>
                                                            <tr>
                                                                <th>Company Name</th>
                                                                <th>Base Fare</th>
                                                                <th>Supplier Fee</th>
                                                                <th>Markup</th>
                                                                <th>Service Fee</th>
                                                                <th className="canclInfo"> <h5>Cancellation</h5>Airline Charges</th>
                                                                <th className="canclInfo">Supplier Charges</th>
                                                                <th className="canclInfo">Refund Fee</th>
                                                                <th className="canclInfo">Refund Markup</th>
                                                                <th className="canclInfo">Other Charges</th>
                                                                <th className="canclInfo">Fare Used</th>
                                                                <th>Taxes
                                        {refund.data.rtype === "ATC Refund" &&
                                                                        <Tooltip className="toolTop" content={price.taxes.map((tax, index) => (
                                                                            <div className="d-flex"> <span className="mr-4">{tax.code}</span>
                                                                                <span>{tax.price}</span>
                                                                            </div>
                                                                        ))}>
                                                                            <span className="ezyIcon icon-info"></span>
                                                                        </Tooltip>
                                                                    }
                                                                    {refund.data.rtype === "NON-ATC Refund" &&
                                                                        <span className="showtax" onClick={callTaxes(price, price.type,pax.ptype)}>Show Taxes
                                                                                  <span className="ezyIcon icon-info"></span></span>
                                                                    }
                                                                </th>
                                                                <th className="text-right">Sub Total Refund</th>
                                                                <th className="noa">{pax.ptype === "ADT" &&
                                                                    <span>No. Of Adult</span>
                                                                }
                                                                    {pax.ptype === "CHD" &&
                                                                        <span>No. Of Child</span>
                                                                    }
                                                                    {pax.ptype === "INF" &&
                                                                        <span>No. Of Infant</span>
                                                                    }</th>
                                                                <th className="text-right">Total Refund</th>
                                                            </tr>
                                                        </thead>
                                                    }
                                                    {/* ATT Login */}

                                                    {context.logindata.utype === "att_user" &&

                                                        <tr>
                                                            <td>
                                                                <span>{price.type}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.basefare}</span>
                                                            </td>
                                                            <td>
                                                                {price.type === "ATT" &&
                                                                    <span>0.00</span>
                                                                }
                                                                {price.type === "TA" &&
                                                                    <span>{pax.price[0].bkservice}</span>
                                                                }
                                                                {price.type === "STA" &&
                                                                    <span>{pax.price[1].bkservice}</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <span>{price.bkmarkup}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.bkservice}</span>
                                                            </td>

                                                            <td className="canclInfo">
                                                                <span>{price.aircharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.supcharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canmarkup}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                {refund.data.rtype === "ATC Refund" &&
                                                                    <span>{price.ocharges}</span>
                                                                }
                                                                {price.type === "ATT" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                            <Form.Control type="text" value={othrPriceAdt} onClick={()=>setOthrPriceAdt("")} onChange={handleChange(price,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                            <Form.Control type="text" value={othrPriceChd} onClick={()=>setOthrPriceChd("")} onChange={handleChange(price,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPriceInf} onClick={()=>setOthrPriceInf("")} onChange={handleChange(price,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        </Form.Row>
                                                                     }

                                                                     {price.type === "TA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPricetaAdt} onClick={()=>setOthrPriceTAAdt("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPricetaChd} onClick={()=>setOthrPriceTAChd("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPricetaInf} onClick={()=>setOthrPriceTAInf("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                                    {price.type === "STA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPriceStaAdt} onClick={()=>setOthrPriceSTAAdt("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaChd} onClick={()=>setOthrPriceSTAChd("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaInf} onClick={()=>setOthrPriceSTAInf("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>

                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                            </td>

                                                            <td className="canclInfo">
                                                                {refund.data.rtype === "ATC Refund" &&
                                                                    <span>{price.fare_used.price}</span>
                                                                }
                                                                {price.type === "ATT" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                            <Form.Control type="text" value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")} onChange={updateFUchanges(pax,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                            <Form.Control type="text" value={fareUPriceChd} onClick={()=>setfareUPriceChd("")} onChange={updateFUchanges(pax,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(pax,index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        </Form.Row>
                                                                     }

                                                                     {price.type === "TA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" disabled={true} value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")}onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceChd} onClick={()=>setfareUPriceChd("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                                    {price.type === "STA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" disabled={true} value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceChd} onClick={()=>setfareUPriceChd("")}onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>

                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                            </td>
                                                            <td>
                                                                <span>{Number(calculateTax(price)) + Number(price.bk_tot_gst)}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span>{price.subtotal}</span>
                                                            </td>
                                                            <td className="text-center noa">
                                                                <span>{pax.count}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span>{Utilities.addPrice(price.subtotal, pax.count, "Cnt")}</span>
                                                            </td>
                                                        </tr>

                                                    }
                                                    {/* TA Login */}
                                                    {context.logindata.utype === "ta_user" && price.type !== "ATT" &&

                                                        <tr>
                                                            <td>
                                                                <span>{price.type}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.basefare}</span>
                                                            </td>
                                                            <td>
                                                                {price.type === "TA" &&
                                                                    <span>{pax.price[0].bkservice}</span>
                                                                }
                                                                {price.type === "STA" &&
                                                                    <span>{pax.price[1].bkservice}</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <span>{price.bkmarkup}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.bkservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.aircharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.supcharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canmarkup}</span>
                                                            </td>
                                                            <td>
                                                                <td className="canclInfo">
                                                                    {refund.rtype === "ATC Refund" &&
                                                                        <span>{price.ocharges}</span>
                                                                    }
                                                                    {price.type === "TA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPricetaAdt} onClick={()=>setOthrPriceTAAdt("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPricetaChd} onClick={()=>setOthrPriceTAChd("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPricetaInf} onClick={()=>setOthrPriceTAInf("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                    </Form.Row>
                                                                }
                                                                    {price.type === "STA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPriceStaAdt} onClick={()=>setOthrPriceSTAAdt("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaChd} onClick={()=>setOthrPriceSTAChd("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaInf} onClick={()=>setOthrPriceSTAInf("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                    </Form.Row>
                                                                    }

                                                                </td>
                                                            </td>
                                                            <td className="canclInfo">
                                                                {refund.rtype === "ATC Refund" &&
                                                                    <span>{price.fare_used.price}</span>
                                                                }
                                                                     {price.type === "TA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={fareUPriceChd} onClick={()=>setfareUPriceChd("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                                    {price.type === "STA" && (refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" disabled={true} value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceChd} onClick={()=>setfareUPriceChd("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" disabled={true} value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(price, index,pax.ptype)} />
                                                                        </Form.Group>

                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                            </td>
                                                            <td>
                                                                <span>{calculateTax(price) + Number(price.bk_tot_gst)}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.subtotal}</span>
                                                            </td>
                                                            <td className="text-center noa">
                                                                <span>{pax.count}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span>{Utilities.addPrice(price.subtotal, pax.count, "Cnt")}</span>
                                                            </td>
                                                        </tr>

                                                    }
                                                    {/* STA Login */}
                                                    {context.logindata.utype === "sta_user" && price.type === "STA" &&

                                                        <tr>
                                                            <td>
                                                                <span>{price.type}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.basefare}</span>
                                                            </td>
                                                            <td>
                                                                <span>{pax.price[1].bkservice}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.bkmarkup}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.bkservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.aircharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.supcharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.canmarkup}</span>
                                                            </td>
                                                            <td>
                                                                <td className="canclInfo">
                                                                    {refund.rtype === "ATC Refund" &&
                                                                        <span>{price.ocharges}</span>
                                                                    }
                                                                    {(refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={othrPriceStaAdt} onClick={()=>setOthrPriceSTAAdt("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaChd} onClick={()=>setOthrPriceSTAChd("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={othrPriceStaInf} onClick={()=>setOthrPriceSTAInf("")} onChange={handleChange(price, index,pax.ptype)} />
                                                                        </Form.Group>

                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                                </td>
                                                            </td>
                                                            <td className="canclInfo">
                                                                {refund.rtype === "ATC Refund" &&
                                                                    <span>{price.fare_used.price}</span>
                                                                }
                                                                    {(refund.data.rtype === "NON-ATC Refund" || refund.data.rtype === "NON ATC Refund") &&
                                                                    <Form.Row>
                                                                        {pax.ptype==="ADT" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                        <Form.Control type="text" value={fareUPriceAdt} onClick={()=>setfareUPriceAdt("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="CHD" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={fareUPriceChd} onClick={()=>setfareUPriceChd("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>
                                                                        }
                                                                        {pax.ptype==="INF" &&
                                                                        <Form.Group as={Col} controlId="ocharges" className="d-flex align-items-center justify-content-between">
                                                                         <Form.Control type="text" value={fareUPriceInf} onClick={()=>setfareUPriceInf("")} onChange={updateFUchanges(pax, index,pax.ptype)} />
                                                                        </Form.Group>

                                                                        }
                                                                    </Form.Row>
                                                                    }
                                                            </td>
                                                            <td>
                                                                <span>{calculateTax(price) + Number(price.bk_tot_gst)}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.subtotal}</span>
                                                            </td>
                                                            <td className="text-center noa">
                                                                <span>{pax.count}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span>{Utilities.addPrice(price.subtotal, pax.count, "Cnt")}</span>
                                                            </td>
                                                        </tr>
                                                    }

                                                </React.Fragment>
                                            ))}

                                        </Table>
                                    </Col>
                                </Row>

                            </div>
                        </React.Fragment>
                    ))
                }
                <Row>
                    {/* ATT Login */}
                    {context.logindata.utype === "att_user" &&
                        <>
                            {state.attPaxPrice && state.attPaxPrice !== 0 &&
                                <RefundSummary priceData={state.attPaxPrice} type={"ATT"} flow={"Refund"} flowType={"Refund"} butype={context.logindata.butype} />
                            }
                            {state.taPaxPrice && state.taPaxPrice.length !== 0 &&
                                <RefundSummary priceData={state.taPaxPrice} type={"TA"} flow={"Refund"} flowType={"Refund"} butype={context.logindata.butype}/>
                            }
                            {state.staPaxPrice && state.staPaxPrice.length !== 0 &&
                                <RefundSummary priceData={state.staPaxPrice} type={"STA"} flow={"Refund"} flowType={"Refund"} butype={context.logindata.butype} />
                            }
                        </>
                    }
                    {/* TA Login */}
                    {context.logindata.utype === "ta_user" &&
                        <>
                            {state.taPaxPrice && state.taPaxPrice.length !== 0 &&
                                <RefundSummary priceData={state.taPaxPrice} type={"TA"} butype={context.logindata.butype} />
                            }
                            {state.staPaxPrice && state.staPaxPrice.length !== 0 &&
                                <RefundSummary priceData={state.staPaxPrice} type={"STA"} butype={context.logindata.butype}/>
                            }
                        </>
                    }
                    {/* STA Login */}
                    {context.logindata.utype === "sta_user" && state.staPaxPrice && state.staPaxPrice !== undefined &&
                        <RefundSummary priceData={state.staPaxPrice} type={"STA"} butype={context.logindata.butype} />
                    }
                </Row>
                <div className="text-right buttonGrop mt-4">
                    <Button
                        size="xs"
                        variant="outline-dark"
                        type="submit"
                        onClick={cancelRefund}
                    > Cancel Refund</Button>
                    <Button
                        size="xs"
                        variant="outline-primary"
                        type="submit"
                        onClick={showPopup}
                    > Process Refund</Button>
                </div>

                <Modal
                    show={state.showPopup}
                    onHide={handleCLClose}
                    className="importPNRMdl requiredFare"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <div className="popUplayout center">
                            <div className="icons warning"></div>
                            <div>
                                <strong>Are you sure, you want to process the refund?</strong>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Footer className="justify-content-center">
                        {state.enableLoader ? '' : <Button variant="outline-dark" onClick={handleCLClose}>
                            Close
                       </Button>}
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={procesRefundFlow}
                        > {!state.enableLoader ? 'Yes' : 'Processing...'}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={state.taxPopup}
                    onHide={() =>handleTaxClose(state.setPtype)}
                    className="importPNRMdl "
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <div className="popUplayout center">
                            {prices !== "" && prices.taxes !== undefined && state.setPtype === "ADT" && prices.taxes.map((tax, index) => (
                                <div className="d-flex">
                                    <Form.Row>
                                        <span> {tax.code} </span>
                                        <Form.Group as={Col} controlId="othrPrice" className="d-flex align-items-center justify-content-between">
                                            <Form.Control type="text" value={tax.newprice} disabled={!tax.refundable} onChange={handleChangeTaxes(tax.code, index,state.setPtype)} />
                                        </Form.Group>
                                        <span> {tax.refundable ? "(R)": "(NR)"} </span>
                                    </Form.Row>
                                </div>
                            ))}
                            {chdprices !== "" && chdprices.taxes !== undefined && state.setPtype === "CHD" && chdprices.taxes.map((tax, index) => (
                                <div className="d-flex">
                                    <Form.Row>
                                        <span> {tax.code} </span>
                                        <Form.Group as={Col} controlId="othrPrice" className="d-flex align-items-center justify-content-between">
                                            <Form.Control type="text" value={tax.newprice} disabled={!tax.refundable} onChange={handleChangeTaxes(tax.code, index,state.setPtype)} />
                                        </Form.Group>
                                        <span> {tax.refundable ? "(R)": "(NR)"} </span>
                                    </Form.Row>
                                </div>
                            ))}
                            {infprices !== "" && infprices.taxes !== undefined && state.setPtype === "INF" && infprices.taxes.map((tax, index) => (
                                <div className="d-flex">
                                    <Form.Row>
                                        <span> {tax.code} </span>
                                        <Form.Group as={Col} controlId="othrPrice" className="d-flex align-items-center justify-content-between">
                                            <Form.Control type="text" value={tax.newprice} disabled={!tax.refundable} onChange={handleChangeTaxes(tax.code, index, state.setPtype)} />
                                        </Form.Group>
                                        <span> {tax.refundable ? "(R)": "(NR)"} </span>
                                    </Form.Row>
                                </div>
                            ))}
                        </div>
                    </Modal.Header>
                </Modal>
            </div>



            <Footer />
        </>
    )
}
export default RefundRecord