
import React, { Component, useState, useEffect, useContext,useRef } from 'react';
import Tabs from '../../../common/tabs/Tabs';
import PayInsurance from './PayInsurance';
import { Context } from "../../../../../App";
/**
 * @description:This function will fire the Book request and Process the Payment
 * @author: Lakshmi
 * @param {*}
 * @function Payment details
 * @date : 03-09-2020
 */

function InsPayment(props) {
    const [state, setState] = useState({})
    const [enableConfirm, setEnableConfirm] = useState(true)
    const [context, setContext] = useContext(Context);
    const paySec = useRef(null)
    const executeScroll = () =>{ return paySec.current.scrollIntoView()};
    const [payment, setPayment] = useState({
        active: 'confirm'
    });

    const showFailedMsg = () => {
        props.sendFailedMsg("Book");
    }

    const backToResults=()=>{
        props.backResults();
    }
    
    const content = {
        confirm:<PayInsurance bdata={props.bookingdata} sendFailedMsg={showFailedMsg} back={backToResults}/>
    };

    return (
        <>
            <div className="paymentSec boxLayout" ref={paySec}>
                <h5 className="panelTitle ezyIcon icon-payment mb-4"> Payment Details</h5>
                <div className="tabLink d-flex">
                    <Tabs active={payment.active} onChange={active => setPayment({ active })}>
                       <span key="confirm" className="ezyIcon icon-confirm"> CONFIRM</span>
                        </Tabs>
                    <div className="tabContent">
                        {content[payment.active]}
                    </div>
                </div>
            </div>
        </>
    )
}


export default InsPayment;
