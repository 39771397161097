import React, { useContext, useEffect, useReducer, useState } from "react";
import { Alert, Col, Form } from "react-bootstrap";
import { Context } from "../../../../../App";
import Breadcrumb from "../../../../components/common/breadcrumb/Breadcrumb";
import Button from "../../../../components/common/buttons/Button";
import Footer from "../../../../components/common/Footer";
import InnerLoader from "../../../../components/common/loader/InnerLoader";
import MainMenu from "../../../../components/common/MainMenu";
import OrganizationMenu from "../../../common/subMenu/OrganizationMenu";
import log from "loglevel";
import {
  saveGstInfo,
  getGstInfo,
  deleteGSTInfo,
  updateGSTInfo,
  getCompanyListGst,
  getCompaniesListGst,
} from "./operations";
import Select from "react-dropdown-select";
import RegionType from "../../common/RegionType";
import SalesChannel from "../../common/SalesChannel";
import PickList from "../../../common/pickList/PickList";
import NoRecord from "../../../common/popUps/NoRecord";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getCompaniesList,
  getCompanyList,
} from "../../airServices/officeid/operations";
import { confirmAlert } from "react-confirm-alert";
import { isNotNull } from "../../../commonUtils/validators";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
export const USER_AUTH_DATA = "userAuthData";

/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Ubedullah
 * @param {*}
 * @function GST Configuration
 * @date : 22-03-2023
 */

/**
 * Initial State Declaration
 */
const initialState = {
  gstForm: {},
  branchmasterList: [],
  selsource: "",
  officeidData: [],
  airlineType: [],
  companyList: [],
  selbranch: [],
  selcompany: [],
  selgds: [],
  companyDisplay_data: [],
  is_branch_data: "",
  selofficeid: [],
  data: "",
  notiMessageShow: "",
  isLoadingMore: false,
  notiMessage: "",
  notiVarient: "",
  compType: true,
  editOfficeIdData: [],
  res: [],
  isLoadingMore: "",
  enableView: "",
  officeIdName: "",
  capAmount: "",
  isDefault: true,
  editOfficeId: false,
  selectedValue: "",
  comp_map: [],
  comapny_temp_List: [],
  showdelete_popup: false,
  comp_map_id: null,
  delete_type: "",
  disCap: false,
  disDefcheck: false,
  isActive: true,
  id:[],
  agy: [],
  rts_edit_data: [],
  disableButton: false,
  rts: [],
  sel_off_id: "",
  airlinesList: [],
  nameError: false,
  amtError: false,
  airNError: false,
  branchList: [],
  bid: null,
  region_type: "",
  region_type_edit: "",
  ofc_mp: [],
  final_ofc_data: [],
  airTError: false,
  offError: false,
  bnError: false,
  pfError: false,
  gdsinv: [],
  gdsinvError: false,
  branch: [],
  profile: [],
  isItineraryLoading: false,
  edit_salesChanel: "",
  sales_chanel: "",
  enableDevices: false,
  b2bBranch: false,
  isDeviceAll: true,
  showBranch: true,
  disabled: "",
  sameState: true,
  sameStateEnable: true,
  otherStateEnable: true,
  otherState: false,
  stateType: "same",
  sgst: "",
  cgst: "",
  igst: "",
  resetBtnEdit:true
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        company_id: 0,
        showMessage: false,
        loadCategory: false,
        loadEdit: false,
        loadSubmit: false,
        id: 0,
        compList: [],
        res: [],
        enbaleUpdate: false,
        noRecords: false,
        notiMessage: "",
        notiMessageShow: false,
        notiVarient: "",
        enableView: false,
        isLoadingMore: false,
        nameError: false,
        companyError: false,
        noError: false,
        isItineraryLoading: false,
        region_type: "",
        RegionSelected: false,
        disabled: "",
        sameState: false,
        otherState: false,
        stateType: "same",
        sgst: "",
        cgst: "",
        igst: "",
      };
    case "editBranch":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Ubedullah Mohammed
 * @param {*}
 * @function GST Configuration
 */
function GSTConfig() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [gstStatus, setGstStatus] = useState("ss"); //ss: same State gst os: other state gst
  const [context, setContext] = useContext(Context);
  const [companydata, setCompanyPick] = useState([]);
  const [data, setData] = useState([]);
  const [regionType, setRegionType] = useState("");
  const [enableGstCompany, setEnableGstCompany] = useState(true);

  const updateSalesChanel = (salesChanel) => {
    if (state.resetBtnEdit){
    setData([])
    dispatch({ type: "selcompany", payload: [] });
    dispatch({ type: "selbranch", payload: [] })
  }
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if (salesChanel === "B2C") {
      dispatch({ type: "enableDevices", payload: true });
      dispatch({ type: "b2bBranch", payload: false });
      dispatch({ type: "branchList", payload: [] });
    } else {
      dispatch({ type: "enableDevices", payload: false });
      dispatch({ type: "b2bBranch", payload: true });
    }
    //dispatch({ type: 'officeIds', payload: [] })
    setCompanyPick([]);
  };

  // useEffect(() => {
  //   console.log("region_typeregion_type", state.salesChannel);
  //   if (state.salesChannel === "B2C") {
  //     dispatch({ type: "showBranch", payload: false });
  //     dispatch({ type: "selBranch", payload: [] });

  //     console.log(
  //       "region_typeregion_type22222",
  //       state.salesChannel,
  //       state.showBranch
  //     );
  //   } else {
  //     dispatch({ type: "showBranch", payload: true });
  //   }
  //   dispatch({ type: "region_type", payload: "" });
  //   dispatch({ type: "selBranch", payload: [] });
  //   dispatch({ type: "companyNames", payload: [] });
  // }, [state.salesChannel]);

  const gstHandler = (gstStatus) => {
    setGstStatus(gstStatus);
  };

  const handleRegionType = (e) => {
    dispatch({ type: "selbranch", payload: [] });
    dispatch({ type: "branchList", payload: e });
    dispatch({ type: "RegionSelected", payload: true });
    dispatch({ type: "selTa", payload: [] });
  };

  const updateRegion = (region) => {
    if (state.resetBtnEdit){
    dispatch({ type: "selcompany", payload: [] });
    setCompanyPick([])
    setData([]);
  }
    dispatch({ type: "region_type", payload: region });
    setRegionType(region);
    // dispatch({ type: "selbranch", payload: [] });
    // dispatch({ type: "branchList", payload: [] });
    if (state.enableDevices && state.sales_chanel === "B2C") {
      let pyload = {
        userId: context.logindata.uid,
        pcid: context.logindata.pcid,
        utype: context.logindata.utype,
        region_type: region,
      };
      getCompaniesListGst(pyload)
        .then((response) => {
          let authData = [];
          if (response.data.suc) {
            for (let val of response.data.res.data) {
              let auth = {
                value: val.value,
                label: val.label,
                sales_channel: val.sales_channel,
              };
              authData.push(auth);
            }
            let companyList = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(companyList);
            dispatch({ type: "comapny_temp_List", payload: authData });
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCompanyList function---" + error);
        });
    }
  };

  const handleBranchChange = (e) => {
   if (state.resetBtnEdit) {
    setData([])
    dispatch({ type: "selcompany", payload: [] });
    setCompanyPick([]);
  }
    dispatch({ type: "selbranch", payload: e });
    if (e !== undefined && e.length !== 0) {
      dispatch({ type: "bid", payload: e[0].value });
      getCompanyListGst(e[0].value)
        .then((response) => {
          if (response.data.suc) {
            let companyList = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(companyList);
            dispatch({ type: "comapny_temp_List", payload: response.data.res });
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCompanyList function---" + error);
        });
    }
  };

  const handleGSTchange = (e) => {
    const { id, value } = e.target;
    const re = /^[0-9]*[0-9][0-9]*$/;
    if(id=="csgt"){
    if (e.target.value === '' || re.test(e.target.value)) {
      dispatch({type:"cgst", payload: e.target.value})
    }}
    if(id=="ssgt"){
    if (e.target.value === '' || re.test(e.target.value)) {
      dispatch({type:"sgst", payload: e.target.value})
    }}
    if(id=="isgt"){
    if (e.target.value === '' || re.test(e.target.value)) {
      dispatch({type:"igst", payload: e.target.value})
    }}
   }
  const compdata = (data) => {
    dispatch({ type: "selcompany", payload: data });
  };

  const handleSaveGSTInfoBtn = (e) => {
    // isEditable(Boolean);
    // state.
  };
  const columns = [
    {
      dataField: "sales_channel",
      text: "Sales Channel",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "branch",
      text: "Branch",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "company",
      text: "Company",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "state",
      text: "State Type",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "sgst",
      text: "SGST",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "cgst",
      text: "CGST",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "igst",
      text: "IGST",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "options",
      text: "Action",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const loadResponse = (response) => {
    let tableData = [];
    // var enableWEdit = false;
    var enableWDel = false;
    if(context.logindata!==""){
      // if(context.logindata.roles.includes('E_G_S_T')) {
      //   enableWEdit = true;
      // }
      if(context.logindata.roles.includes('D_G_S_T')) {
        enableWDel = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      // if(userData.roles.includes('E_G_S_T')) {
      //   enableWEdit = true;
      // }
      if(userData.roles.includes('D_G_S_T')) {
        enableWDel = true;
      }
    }
    for (let val of response) {
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.reg){
      let company_name = val.comp_list.map((comp) => comp.label).join(", ");
      tableData.push({
        company: company_name,
        sales_channel: val.cha,
        region: val.reg,
        branch: val.branch_nm,
        state: val.state_type,
        sgst: val.sgst,
        cgst: val.cgst,
        igst: val.igst,
        options: (
          <>
            <span className="tbAction">
              {
                <a
                  className="ezyIcon icon-edit"
                  onClick={editGstCompanyBtnClick(val, "Edit")}
                ></a>
              }
              {enableWDel &&
                <a
                  className="ezyIcon icon-delete"
                  onClick={editGstCompanyBtnClick(val, "delete")}
                ></a>
              }
            </span>
          </>
        ),
      });
    }
      if (response.length!==0) {
        dispatch({ type: 'res', payload: tableData })
    }
    dispatch({ type: 'isLoadingMore', payload: false })
    }
  };
  useEffect(() => {
    dispatch({ type: "isLoadingMore", payload: false });
    dispatch({ type: "enbaleUpdate", payload: false });
    // getGstInfo().then((response) => {
    //   if (response.length !== 0) {
    //     loadGSTresponse(response);
    //   }
    //   dispatch({ type: "isLoadingMore", payload: false });
    // });
    getGstInfo({"is_admin":context.logindata.is_admin, "region_type":context.logindata.region_type}).then((response) => {
      if (response.success) {
        loadResponse(response.data);
      }
    });
  }, [context.logindata.region_type]);


  // useEffect(() => {
  //   if(context.logindata!==undefined && state.region_type){
  //   let is_admin=context.logindata.utype==="att_user"?1:0
  //    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
  //     AttCommonSerivce.att_companies(pyload).then(response => {
  //       if (response.data.suc && response.data.res.status === 200) {
  //         // dispatch({ type: "branchList", payload: response.data.res.data });

  //       }
  //     })
  //   }
  // }, [context.logindata,state.region_type]);


  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  }

  const handleCancel = () => {
    window.location = "/ezytrip/administration/gst";
  };
  const resetValues = () => {
    window.location= "/ezytrip/administration/gst"
    };
  const gstValidation = () => {
    if (state.sales_chanel ==""){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please Select Sales Channel' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    }else if (state.stateType==""){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please Select State Type' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.region_type==""){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please Select Region' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.selbranch=="" && state.sales_chanel=="B2B"){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please Select Branch' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.selcompany==[] || state.selcompany==""){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'Please Select Company' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.sgst==="" && gstStatus === "ss"){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'SGST Is Required' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.cgst==="" && gstStatus === "ss"){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'CGST Is Required' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
    } else if (state.igst==="" && gstStatus === "os"){
    dispatch({ type: 'notiMessageShow', payload: true });
    dispatch({ type: 'notiMessage', payload: 'IGST Is Required' });
    dispatch({ type: 'notiVarient', payload: 'danger' });
    hidemessage();
    return false;
   }
   return true;
  }
  const addGstInfo = () => {
    if (gstValidation()){
      let suc=true
      for(let val of state.res){
        if((val.company).toLowerCase()==(state.selcompany[0].label).toLowerCase()){
          suc=false
        }
      }
      if (suc){
      let gstData = {
        state_type: state.stateType,
        cha: state.sales_chanel,
        reg: context.logindata.utype === "att_user"
        ? state.region_type
        : context.logindata.region_type,
        branch: state.bid,
        comp_list:
          state.selcompany.length > 0
            ? state.selcompany.map((comp) => comp.value)
            : [],
        // comp_list: state.selcompany,
        sgst: state.stateType === "same" ? state.sgst : "",
        cgst: state.stateType === "same" ? state.cgst : "",
        igst: state.stateType === "other" ? state.igst : "",
        crby: context.logindata.uid,

      // state.stateType,
      // cha: state.sales_chanel,
      // reg: state.region_type,
      // branch: state.bid,
      // comp_list:
      //   state.selcompany.length > 0
      //     ? state.selcompany.map((comp) => comp.value)
      //     : [],
      // // comp_list: state.selcompany,
      // sgst: state.sgst,
      // cgst: state.cgst,
      // igst: state.igst,
      // crby: 1,
    };
    saveGstInfo(gstData).then((response) => {
      // if (response) {
      //   window.location.reload();
      // }
      if (response.suc) {
        resetValues();
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "GST Config saved successfully",
        });
        dispatch({ type: "notiVarient", payload: "success" });
        window.location = "/ezytrip/administration/gst";
      }
      // else {
      //   if (isNotNull(response.err)) {
      //     dispatch({ type: "isItineraryLoading", payload: false });
      //     dispatch({ type: "notiMessageShow", payload: true });
      //     dispatch({
      //       type: "notiMessage",
      //       payload: response.data.res.message,
      //     });
      //     dispatch({ type: "notiVarient", payload: "danger" });
      //     hidemessage();
      //   } else {
      //     console.log("GST Config Not saved successfull");
      //     dispatch({ type: "notiMessageShow", payload: true });
      //     dispatch({
      //       type: "notiMessage",
      //       payload: "GST Config Id Not saved",
      //     });
      //     dispatch({ type: "notiVarient", payload: "danger" });
      //     hidemessage();
      //   }
      // }
        });
      } else{
        dispatch({ type: 'notiMessageShow', payload: true });
        dispatch({ type: 'notiMessage', payload: 'Company already exist' });
        dispatch({ type: 'notiVarient', payload: 'danger' });
        hidemessage();
        return false;
      }
    }
  };

  const editGstCompanyBtnClick = (obj, type) => () => {
    dispatch({type:"resetBtnEdit", payload:false})
    dispatch({type:"selbranch", payload:[]})
    dispatch({type:"selcompany", payload:[]})
    // debugger;
    // console.log("Edit Markup Response: " + JSON.stringify(obj));
    // setEditofc(true)
    // setCreateOfc(false)
    // obj.type=type;
    // setEditOfcData(obj)


    if (type == "Edit") {
      // if (obj.cha=="B2B"){
      //   dispatch({type:"b2bBranch", payload:true})
      //   dispatch({type:"enableView", payload:true})
      // }
      setData(obj.comp_list);
      dispatch({ type: "selcompany", payload: obj.comp_list });
      dispatch({ type: "id", payload: obj.id });
      dispatch({ type: "edit_salesChanel", payload: obj.cha });
      dispatch({ type: "region_type_edit", payload: obj.reg });
      dispatch({ type: "region_type", payload: obj.reg });
      dispatch({ type: "selbranch", payload: [{ "value": obj.branch_id, "label": obj.branch_nm }] });
      dispatch({ type: "stateType", payload: obj.state_type });
      // dispatch({ type: "branchList", payload: [{"label":obj.branch_nm, "value":obj.brnach_id}] });
      // dispatch({ type: "disabled", payload: obj.state_type });
      if (obj.state_type === "same") {
        dispatch({ type: "selbranch", payload: [{ "value": obj.branch_id, "label": obj.branch_nm }] });
        dispatch({ type: "sameState", payload: true });
        dispatch({ type: "sameStateEnable", payload: true });
        dispatch({ type: "otherStateEnable", payload: false });
        dispatch({ type: "otherState", payload: false });
        dispatch({ type: "stateType", payload: obj.state_type });
        dispatch({ type: "cgst", payload: obj.cgst });
        dispatch({ type: "sgst", payload: obj.sgst });
        setGstStatus("ss")
      } else if (obj.state_type === "other") {
        dispatch({ type: "selbranch", payload: [{ "value": obj.branch_id, "label": obj.branch_nm }] });
        dispatch({ type: "sameState", payload: false });
        dispatch({ type: "sameStateEnable", payload: false });
        dispatch({ type: "otherStateEnable", payload: true });
        dispatch({ type: "otherState", payload: true });
        dispatch({ type: "igst", payload: obj.igst });
        dispatch({ type: "stateType", payload: obj.state_type });
        setGstStatus("os")
      }
      dispatch({ type: "enabeleEdit", payload: true });
      dispatch({ type: "enbaleUpdate", payload: true });
    }

    setEnableGstCompany(true);
    setEnableGstCompany(false);

    if (type === "delete") {
      confirmDlg(obj);
    }

    // dispatch({ type: "sales_chanel", payload: state.cha });
    // dispatch({ type: "selbranch", payload: state.branch_nm });
  };

  const updateGstInfo = () => {
    if (gstValidation()){
    let obj = {
      id: state.id,
      state_type: state.stateType,
      cha: state.sales_chanel,
      reg: context.logindata.utype === "att_user"
      ? state.region_type_edit
      : context.logindata.region_type,
      branch: state.selbranch[0].value,
      comp_list:
        state.selcompany.length > 0
          ? state.selcompany.map((comp) => comp.value)
          : [],
      // comp_list: state.selcompany,
      sgst: state.sgst,
      cgst: state.cgst,
      igst: state.igst,
      crby: context.logindata.uid,


      // state_type: state.stateType,
      // cha: state.sales_chanel,
      // reg: state.region_type_edit,
      // branch: state.selbranch[0].value,
      // comp_list:
      //   state.selcompany.length > 0
      //     ? state.selcompany.map((comp) => comp.value)
      //     : [],
      // // comp_list: state.selcompany,
      // sgst: state.sgst,
      // cgst: state.cgst,
      // igst: state.igst,
      // crby: context.logindata.uid,
    };

    updateGSTInfo(obj).then((response) => {
      if (response) {
        window.location.reload();
      }
    });

    // console.log("handleSaveOfficeidBtn----");
    // dispatch({ type: "nameError", payload: false });
    // e.preventDefault();
    // if (isNotNull(state.officeIdName) && state.final_ofc_data.length !== 0) {
    //   /* below is the construct main json for saving */
    //   dispatch({ type: "isItineraryLoading", payload: true });
    //   const main_json = {
    //     onm: state.officeIdName,
    //     reg: regionType,
    //     cha: state.sales_chanel,
    //     act: state.isActive,
    //     com_map: state.comp_map,
    //     ofc_map: state.ofc_mp,
    //   };
    //   if (!state.enabeleEdit) {
    //     main_json.crby = context.logindata.uid;
    //   } else {
    //     main_json.id = state.ofc_id;
    //     main_json.upby = context.logindata.uid;
    //   }
    //   const payload = {
    //     ofcreq: main_json,
    //   };
    //   console.log("main json is payload----" + JSON.stringify(payload));
    //   if (!state.enabeleEdit) {
    //     saveGstInfo(payload)
    //       .then((response) => {
    //         console.log(
    //           "saveOffcieIdConfigDetails : " + JSON.stringify(response)
    //         );
    //         if (response.data.suc) {
    //           resetValues();
    //           dispatch({ type: "notiMessageShow", payload: true });
    //           dispatch({
    //             type: "notiMessage",
    //             payload: "Offcie Id saved successfully",
    //           });
    //           dispatch({ type: "notiVarient", payload: "success" });
    //           window.location = "/ezytrip/administration/officeid";
    //         } else {
    //           if (isNotNull(response.data.res.message)) {
    //             dispatch({ type: "isItineraryLoading", payload: false });
    //             dispatch({ type: "notiMessageShow", payload: true });
    //             dispatch({
    //               type: "notiMessage",
    //               payload: response.data.res.message,
    //             });
    //             dispatch({ type: "notiVarient", payload: "danger" });
    //             hidemessage();
    //           } else {
    //             console.log("Offcie Id Not saved successfull");
    //             dispatch({ type: "notiMessageShow", payload: true });
    //             dispatch({
    //               type: "notiMessage",
    //               payload: "Offcie Id Not saved",
    //             });
    //             dispatch({ type: "notiVarient", payload: "danger" });
    //             hidemessage();
    //           }
    //         }
    //       })
    //       .catch(function (error) {
    //         console.error(
    //           "Exception occured in Save office id  function---" + error
    //         );
    //       });
    //   } else {
    //     updateGSTInfo(payload)
    //       .then((response) => {
    //         console.log(
    //           "updateOffcieIdConfigDetails : " + JSON.stringify(response)
    //         );
    //         if (response.data.suc) {
    //           dispatch({ type: "notiMessageShow", payload: true });
    //           dispatch({
    //             type: "notiMessage",
    //             payload: "Offcie id updated successfully",
    //           });
    //           dispatch({ type: "notiVarient", payload: "success" });
    //           hidemessage();
    //           window.location = "/ezytrip/administration/officeid";
    //         } else {
    //           dispatch({ type: "isItineraryLoading", payload: false });
    //           dispatch({ type: "notiMessageShow", payload: true });
    //           dispatch({
    //             type: "notiMessage",
    //             payload: "Offcie Id Not updated successfull",
    //           });
    //           dispatch({ type: "notiVarient", payload: "danger" });
    //           hidemessage();
    //           console.log("Offcie Id Not saved successfull");
    //         }
    //       })
    //       .catch(function (error) {
    //         console.error(
    //           "Exception occured in saveCompanyDetails function---" + error
    //         );
    //       });
    //   }
    // } else if (state.officeIdName === "") {
    //   dispatch({ type: "nameError", payload: true });
    // } else {
    //   dispatch({ type: "notiMessageShow", payload: true });
    //   dispatch({
    //     type: "notiMessage",
    //     payload: "Please add atleast one configuration and proceed",
    //   });
    //   dispatch({ type: "notiVarient", payload: "danger" });
    //   hidemessage();
    // }
    }
  };
  // const updateGstInfo = () => {
  //   if (state.oidMaster != "") {
  //     let suc = true;
  //     for (let val of state.res) {
  //       if (val.officeID.toLowerCase() == state.oidMaster.toLowerCase()) {
  //         suc = false;
  //       }
  //     }
  //     if (suc) {
  //       dispatch({ type: "isItineraryLoading", payload: true });
  //       const req = {
  //         id: state.id,
  //         office_id: state.oidMaster,
  //         upby: context.logindata.uid,
  //       };
  //       update(req).then((response) => {
  //         if (response.suc) {
  //           dispatch({ type: "notiMessageShow", payload: true });
  //           dispatch({ type: "notiMessage", payload: response.res });
  //           dispatch({ type: "notiVarient", payload: "success" });
  //           hidemessage();
  //           window.location = "/ezytrip/administration/oidMaster";
  //         }
  //       });
  //     } else {
  //       dispatch({ type: "isItineraryLoading", payload: false });
  //       dispatch({ type: "notiMessageShow", payload: true });
  //       dispatch({ type: "notiMessage", payload: "Office id already exists" });
  //       dispatch({ type: "notiVarient", payload: "danger" });
  //       hidemessage();
  //     }
  //   } else {
  //     dispatch({ type: "isItineraryLoading", payload: false });
  //     dispatch({ type: "notiMessageShow", payload: true });
  //     dispatch({ type: "notiMessage", payload: "Office id is required" });
  //     dispatch({ type: "notiVarient", payload: "danger" });
  //     hidemessage();
  //   }
  // };
  const editOfficeID = (obj, type) => () => {
    // dispatch({ type: 'oidMaster', payload: obj.office_id });
    if (type === "delete") {
      // confirmDlg(obj);
    } else {
      dispatch({ type: "enbaleUpdate", payload: true });
      dispatch({ type: "enableView", payload: false });
      dispatch({ type: "oidMaster", payload: obj.id });
      dispatch({ type: "id", payload: obj.id });
    }
  };
  const confirmDlg = (obj, cb) => {
    confirmAlert({
      customUI: () => {
        return (
          <>
            <div className="custom-ui">
              <span className="confirmD"></span>
              <h3>Are you sure?</h3>
              <p className="t-color2 mb-1">
                You want to delete the GST ?
              </p>
            </div>
            <div className="buttonGrop text-center border-0 pb-0">
              <button
                className="btn btn-xs btn-secondary pl-4 pr-4"
                onClick={() => {
                  handleDelete(obj);
                }}
              >
                {" "}
                Yes{" "}
              </button>
              <button
                className="btn btn-primary btn-xs pl-4 pr-4"
                onClick={handleCancel}
              >
                No{" "}
              </button>
            </div>
          </>
        );
      },
    });
  };
  const handleDelete = (obj) => {
    deleteGSTInfo(obj.id).then((response) => {
      if (response) {
        window.location.reload();
      }
    });
  };

  const handleStateChange = (event) => {
    if (event.target.id === "same") {
      gstHandler("ss");
      dispatch({ type: "stateType", payload: event.target.value });
      dispatch({ type: "sameState", payload: true });
      dispatch({ type: "otherState", payload: false });
      dispatch({ type: "igst", payload: ""});
    } else if (event.target.id === "other") {
      gstHandler("os");
      dispatch({ type: "stateType", payload: "other" });
      dispatch({ type: "sameState", payload: false });
      dispatch({ type: "otherState", payload: true });
      dispatch({ type: "cgst", payload: ""});
      dispatch({ type: "sgst", payload: ""});
    }
  };

  return (
    <>
      {state.notiMessageShow && (
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
      )}
      {state.isItineraryLoading ? (
        <div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <MainMenu active="Organization/CM" />
      <Breadcrumb activePage="GST Configuration" />
      <div className="container">
        <div className="cardMainlayout gstConfigurationscreen pt-0">
          <OrganizationMenu active="GST Config" />
          {/* <Form id="gstConfigForm"> */}
          <br/>
          <Form.Row>
            {/* {!state.stateType && ( */}
            <Form.Group
              as={Col}
              xs={6}
              controlId="stateType"
              className="inputCheck"
            >
              <Form.Label className="mr-2">
                State Type <sup>*</sup>
              </Form.Label>

              <div className="d-flex align-items-center">
                {state.sameStateEnable&& <Form.Check
                  type="radio"
                  label="Same State"
                  id="same"
                  checked={state.sameState}
                  value={state.stateType}
                  disabled={state.disabled}
                  onChange={handleStateChange}
                  custom
                  name="classes"
                />}
                {state.otherStateEnable&& <Form.Check
                  type="radio"
                  label="Other State"
                  id="other"
                  checked={state.otherState}
                  value={state.stateType}
                  disabled={state.disabled}
                  onChange={handleStateChange}
                  custom
                  name="classes"
                  className="ml-3"
                />}
              </div>
              {/* <Form.Label>Company Type</Form.Label> */}
            </Form.Group>
            {/* )} */}
          </Form.Row>
          <Form.Row>
            <SalesChannel
              sendSalesChanel={updateSalesChanel}
              edit_sales={state.edit_salesChanel}
            />
            {context.logindata.utype !== "ta_user" && (
                  <>
            <RegionType
              sendResponse={(e) => handleRegionType(e)}
              regionUpdate={state.region_type_edit}
              disabled={state.enabeleEdit}
              regionError={state.regionError}
              regionType={updateRegion}
              callBranch={state.enableDevices}
            />

            {/* {state.showBranch && (
              <Form.Group
                as={Col}
                xs={3}
                className="selectplugin"
                controlId="branch_id"
              >
                <Select
                  options={state.branchList}
                  values={state.selBranch}
                  searchable={false}
                  disabled={state.enbaleUpdate}
                  onChange={(e) => handleBranchChange(e)}
                />
                {state.supplierError && (
                  <p className="vError">Please Select branches</p>
                )}
                <Form.Label>
                  Branch<sup>*</sup>
                </Form.Label>
              </Form.Group>
            )} */}

            {state.b2bBranch && (
                <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="branchName"
                >
                  <Select
                    options={state.branchList}
                    values={state.selbranch}
                    onChange={handleBranchChange}
                    disabled={state.enableView || state.enabeleEdit}
                  />
                  {state.bnError && (
                    <p className="vError">Please Select Branch</p>
                  )}
                  <Form.Label>
                    Branch<sup>*</sup>
                  </Form.Label>
                </Form.Group>
              )}
              </>
            )}
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="picklist" xs={7}>
              <PickList
                leftPaneLabel="Available Company Names"
                rightPaneLabel="Selected Company Names"
                type={state.compType}
                selData={data}
                dataList={companydata !== undefined && companydata}
                sendData={compdata}
              />
              <Form.Label>
                Company Name<sup>*</sup>
              </Form.Label>
              {state.pfError && <p className="vError">Please Select Company</p>}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            {gstStatus === "ss" && (
              <>
                <Form.Group as={Col} xs={3} controlId="csgt">
                  <Form.Label className="mr-2">
                    CGST (%) <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleGSTchange}
                    value={state.cgst}
                  ></Form.Control>
                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="ssgt">
                  <Form.Label className="mr-2">
                    SGST (%) <sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleGSTchange}
                    value={state.sgst}
                  ></Form.Control>
                </Form.Group>
              </>
            )}
            {gstStatus === "os" && (
              <Form.Group as={Col} xs={3} controlId="isgt">
                <Form.Label className="mr-2">
                  IGST (%) <sup>*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  min={0}
                  onChange={handleGSTchange}
                  value={state.igst}
                ></Form.Control>
              </Form.Group>
            )}
          </Form.Row>

          <div className="text-right pb-0 buttonGrop  creat_absolute">
            {state.enbaleUpdate && (
              <Button
                size="xs"
                variant="outline-secondary"
                onClick={handleCancel}
                type="submit"
              >
                {" "}
                Back
              </Button>
            )}
            {context.logindata.roles !== undefined && !state.enbaleUpdate && (
              <Button
                size="xs"
                variant="outline-secondary"
                onClick={resetValues}
                  type="submit"
                isDisabled={state.enableView}
              >
                Reset
              </Button>
            )}
            {!state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('C_G_S_T') && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={addGstInfo}
                type="submit"
              >
                Save
              </Button>
            )}
            {state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('U_G_S_T') &&  (
              <Button
               size="xs"
               variant="outline-primary"
               onClick={updateGstInfo}
              //  isDisabled={state.enbaleUpdate}
               type="submit"
              > Update</Button>
            )}
          </div>
        </div>

        <div className="cardMainlayout tableRes p-0">
          {state.isLoadingMore ? (
            <InnerLoader />
          ) : (
            <>
              {(state.res.length !== 0 && (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={
                    state.res.length > 10 && state.res.length <= 25
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                          ],
                        })
                      : state.res.length > 25 && state.res.length <= 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                          ],
                        })
                      : state.res.length > 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                            { text: "50", value: 50 },
                          ],
                        })
                      : ""
                  }
                />
              )) ||
                (state.res.length === 0 && <NoRecord />)}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GSTConfig;
