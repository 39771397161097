import ExchangeRateConfigService from '../../../../services/administration/ExchangeRateConfigService';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */

export const getExchangeRates = async () => {
    let resp = [];
    await ExchangeRateConfigService.getAllExchnagerates().then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const save = async (obj) => {
    let finalRes = {};
    await ExchangeRateConfigService.save(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Supplier Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes = {};
    await ExchangeRateConfigService.update(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

