import React, { useContext, useEffect, useReducer, useState,useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { countriesWitCode} from '../../adminstration/utils';
import { Alert, Col, Form, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../App";
import Breadcrumb from "../../../components/common/breadcrumb/Breadcrumb";
import Button from "../../../components/common/buttons/Button";
import Footer from "../../../components/common/Footer";
import InnerLoader from "../../../components/common/loader/InnerLoader";
import MainMenu from "../../../components/common/MainMenu";
import NoRecord from "../../common/popUps/NoRecord";
import VisaMenu from "../../common/subMenu/VisaMenu";
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import log from "loglevel";
import { getVisaSearchData} from "./operations";
import Select from "react-dropdown-select";
import RegionType from "../common/RegionType";
import SalesChannel from "../common/SalesChannel"
export const USER_AUTH_DATA = "userAuthData";




/**
 * Initial State Declaration
 */
const initialState = {
  "region_type":"",
  "sales_chanel":"",
  "na" :"",
  "trvel_cnt" :"",
  "visa" :"",
  "visa_type" :"",
  "entry_type" :"",
  "category" :"",
  notiMessage: "",
  notiVarient: "",
  showMessage: false,
  countriesMenu: []
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        "region_type":"",
        "sales_chanel":"",
        "na" :"india",
        "trvel_cnt" :"",
        "visa" :"",
        "visa_type" :"",
        "entry_type" :"",
        "category" :""
      };
    case "editVisa":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function VisaMain() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [validated, setValidated] = useState(false);
  const [response,setResponse] = useState([]);
  const [countries, setCountries] = useState([]);
  const [visaTypes, setVisaTypes] = useState([{value:"Standard Visa",label:"Standard Visa"},{value:"Express Visa",label:"Express Visa"}]);
  const [entryTypes, setEntryTypes] = useState([{value:"Single Entry",label:"Single Entry"},{value:"Multiple Entry",label:"Multiple Entry"}]);
  const [visaList, setVisaList] = useState([{value:"E- Visa",label:"E- Visa"},{value:"Normal Visa",label:"Normal Visa"}]);
  const [categoryList, setCategoryList] = useState([{value:"Tourist",label:"Tourist"},{value:"Student",label:"Student"},{value:"Transit",label:"Transit"},{value:"Medical",label:"Medical"},{value:"Business",label:"Business"}]);
  
  useEffect(() => {
    setIsLoadingMore(true)
    if(context.logindata!=""){
    let payload = {
      "region_type": "",
      "sales_chanel": "",
      "na": "",
      "trvel_cnt": "",
      "visa": "",
      "visa_type": "",
      "entry_type": "",
      "category": "",
      "uid": context.logindata.uid,
      "utype": context.logindata.utype,
      "is_admin": context.logindata.is_admin
  }
  
    getVisaSearchData(payload).then((response) => {
      
        if(response.data.suc) {
        console.log("Load all Visa response : " + JSON.stringify(response));
        convertVisaData(response.data.res);
      }
      setIsLoadingMore(false)
    });
    countriesWitCode().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
    });
    AttCommonSerivce.getCountryList().then((response) => {
        if (response.data.suc) {
          let transformedCountries = response.data.res.map(country => ({
            value: country.name,
            label: country.name
        }));          
        setCountries(transformedCountries);
        }
      })
      .catch(function (error) {
        log.error("Exception occured in getCountryList function---" + error);
      });
      }
  }, [context.logindata.uid]);
  useEffect(() => {
  console.log("countries",countries)
  }, [countries])
 
  //Converting all suppliers
  const convertVisaData = (response) => {
    
    let data = [];
    
    if (response !== undefined) {
      for (let val of response) {
        if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){
          data.push({
          sales_cha:val.sales_chanel,
          region_type: val.region_type,   
          na:val.na,
          trvel_cnt:val.trvel_cnt,
          visa: val.visa,   
          visa_type: val.visa_type,
          entry_type: val.entry_type,
          category: val.category,
          options: (
            <>
              <span className="tbAction">
              {<a
                    className="ezyIcon icon-view"
                    onClick={()=>editVisa(val, "view")}
                  ></a>}
              {<a
                    className="ezyIcon icon-edit"
                    onClick={()=>editVisa(val, "edit")}
                  ></a>}
              </span>
            </>
          ),
        });
      }
      }
      setResponse(data)
      
    }
    setIsLoadingMore(false)
  };

  const handleSalesChanel=(chnel)=>{
    dispatch({ type: "sales_chanel", payload: chnel });
  }
  const handleRegionChange=(region)=>{
    dispatch({ type: "region_type", payload: region });
  }

  const handleNaChange = (e) => {
    if (e[0]) {
        dispatch({ type: "na", payload: e[0].name });
    }
  };
  const handleTravelChange = (e) => {
    if (e[0]) {
        dispatch({ type: "trvel_cnt", payload: e[0].name });
    }
  };


  function hidemessage() {
    setTimeout(function () {
      dispatch({type:"notiMessageShow", payload:true});
      dispatch({ type: 'notiMessage', payload: "" })
      dispatch({type:"notiVarient", payload:""});
    }, 5000);
  }

  const handleSearchVisa=()=>{
    if(
     state.sales_chanel!="" ||
     state.region_type!=""|| 
     state.na!=""|| 
     state.trvel_cnt!=""|| 
     state.visa!=""|| 
     state.visa_type!=""|| 
     state.entry_type!=""|| 
     state.category!=""){
    getVisaSearchData(state).then((response) => {
      if(response.data.suc) {
        console.log("Load all Visa response : " + JSON.stringify(response));
        convertVisaData(response.data.res);
        setIsLoadingMore(false)
      }else{
        convertVisaData([]);
        setIsLoadingMore(false)
      }
    })
   }else{
      dispatch({type:"notiMessageShow", payload:true});
      dispatch({type:"notiVarient", payload:"danger"});
      dispatch({ type: 'notiMessage', payload: "Please select atleast one search criteria" })
      hidemessage()
    setIsLoadingMore(false)
  }
  }

  const resetVisa=()=>{
    window.location="/ezytrip/administration/visa";
  }

  //Edit editVisa
  const editVisa = (obj,type) =>{
    obj.flow_type=type;
    history.push({
      pathname: '/ezytrip/administration/visa-config',
      state: {
        req: obj
      }
    });
  };

  const addVisa=()=>{
    window.location="/ezytrip/administration/visa-config";
  }


  const columns = [
    {
      dataField: "sales_cha",
      text: "Sales Channel",
      sort: true,
      filter: textFilter(),
    },{
      dataField: "region_type",
      text: "Region",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "na",
      text: "Nationality",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "trvel_cnt",
      text: "Travel Country",
      sort: true,
      filter: textFilter(),
    },
    {
    dataField: "visa",
    text: "Visa",
    sort: true,
    filter: textFilter(),
      },
      {
        dataField: "visa_type",
        text: "Visa Type",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "entry_type",
        text: "Entry Type",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "category",
        text: "Category",
        sort: true,
        filter: textFilter(),
      },
    {
      dataField: "options",
      text: "Actions",
      class: "tes",
    },
  ];
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  return (
    <>
      <MainMenu active="Visa" />
      <Breadcrumb activePage="Visa Services" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <VisaMenu active="visa" />
          <div className="contentArea">
            {/* <Alert
              className="notification"
              variant={state.notiVarient}
              show={state.notiMessageShow}
              onClose={() =>
                dispatch({ type: "notiMessageShow", payload: false })
              }
              dismissible
            >
              {" "}
              {state.notiMessage}
            </Alert> */}
            <Form noValidate validated={validated} id="formId">
              <Form.Row>
                 <SalesChannel flowType={"visaConfig"} sendSalesChanel={handleSalesChanel} />
                  <>
                  <RegionType
                      regionUpdate={""}
                      regionType={handleRegionChange}
                      callBranch={true}
                      flowType={"visaConfig"}
                    />

                  </>
                 <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="na"
                  >
                  <Select
                    options={countries}
                    value={state.na}
                    onChange={(value) =>  dispatch({ type: "na", payload: value[0].label})}

                  />
                  <Form.Label>
                    Nationality
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="trvel_cnt"
                >
                  <Select
                    options={countries}
                    value={state.trvel_cnt}
                    onChange={(value) =>  dispatch({ type: "trvel_cnt", payload: value[0].label })}
                  />
                  <Form.Label>
                    Travelling Country
                  </Form.Label>
                </Form.Group>
              </Form.Row>
              <Form.Row>
              <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="visa_type"
                  >
                  <Select
                    options={visaTypes}
                    value={state.visa_type}
                    onChange={(value) =>  dispatch({ type: "visa_type", payload: value[0].label })}
                  />
                  <Form.Label>
                    Visa Type
                  </Form.Label>
             </Form.Group>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="entry_type"
                  >
                  <Select
                    options={entryTypes}
                    value={state.entry_type}
                    onChange={(value) =>  dispatch({ type: "entry_type", payload: value[0].label })}
                  />
                  <Form.Label>
                    Entry Type
                  </Form.Label>
             </Form.Group>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="category"
                  >
                  <Select
                    options={categoryList}
                    value={state.category}
                    onChange={(value) =>  dispatch({ type: "category", payload: value[0].label })}
                  />
                  <Form.Label>
                    Category
                  </Form.Label>
             </Form.Group>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="visa_type"
                  >
                  <Select
                    options={visaList}
                    value={state.visa}
                    onChange={(value) =>  dispatch({ type: "visa", payload: value[0].label })}
                  />
                  <Form.Label>
                    Visa
                  </Form.Label>
             </Form.Group>
              </Form.Row>
            
              <Form>
                <Table></Table>
              </Form>
            </Form>
            <div className="d-flex addBtnvsa text-right buttonGrop pb-0">
              <div className="addButtnvisa">
               <Button
                  size="xs"
                  variant="primary"
                  onClick={addVisa}
                  type="submit"
                  className="btn addPlus ezyIcon icon-plus"
                >
                  {" "}
                  Add Visa
                </Button>
                </div>
                <div className="restVisabtn">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  onClick={resetVisa}
                  type="submit"
                >
                  Reset
                </Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={handleSearchVisa}
                  type="submit"
                >
                  {" "}
                  Search
                </Button>
                </div>
            </div>
          </div>
          <div></div>
        </div>

        <div className="cardMainlayout tableRes p-0">
          {isLoadingMore ? (
            <InnerLoader />
          ) : (
            <>
              {(response.length !== 0 && (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl suplierConfig"
                  data={response}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={
                    response.length > 10 && response.length <= 25
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                          ],
                        })
                      : response.length > 25 && response.length <= 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                          ],
                        })
                      : response.length > 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                            { text: "50", value: 50 },
                          ],
                        })
                      : ""
                  }
                />
              )) ||
                (response.length === 0 && <NoRecord />)}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VisaMain;
