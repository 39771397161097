import axios from 'axios'

const API_URL = process.env.REACT_APP_PRE_BOOK_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Hold Bookings
 * @param {*} Service 
 */

class CommissionDiscountService {
    async loadAllDiscounts(obj) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/comdisc/retrieve-disc`,obj)  
      }

      async createComDisc(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/comdisc/create`,data)  
      }

      async updateComDisc(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/comdisc/update`,data)  
      }

      async getDiscCompanyList(id) {
        console.log("API_URL",API_URL)
        return await axios.get(`${API_URL}/comdisc/retrieve-comp/${id}`)  
      }

      async deleteDiscount(id) {
        console.log("API_URL",API_URL)
        return await axios.delete(`${API_URL}/comdisc/delete/${id}`)  
      }

      async search(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/comdisc/search`,data)  
      }
     
    }

export default new CommissionDiscountService()