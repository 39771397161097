
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useReducer } from 'react';
 import { Col, Row} from "react-bootstrap";

 /**
  * Initial State Declaration
  */
 const initialState = {
     source: '', destination: '', duration: '', travel_type: '', departure_date: '' , gdspnr:"",notiMessageShow: false, notiMessage: '', notiVarient: ''
 };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 /**
  * @description:This function will Display the the Segment details
  * @author: Lakshmi
  * @param {*}
  * @function Pax details
  * @date : 18-09-2020
  */
 function PaxFarePdf(props) {
     const [state, dispatch] = useReducer(reducer, initialState)

     return (
         <>
                <div className="pdffare">
               <h3 className="pdfSubTitl">
              {props.flowType} Details ({props.cur})
              </h3>
                 <div className="header">
                     <Row>
                         <Col xs="2">Traveller</Col>
                         <Col xs="1">Type</Col>
                         <Col xs="2">Ticket#</Col>
                         {props.sup==="4" &&
                         <Col xs="1">PNR</Col>
                         }
                         <Col>Fare Paid</Col>
                         <Col>Charges</Col>
                        {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="same" &&
                         <Col xs="1">CGST</Col>
                        }
                        {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="same"  &&
                         <Col xs="1">SGST</Col>
                        }
                        {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="other" &&
                        <Col xs="1">IGST</Col>
                       }
                         <Col xs="2">{props.type === "TA" ? "Refund Amount" : "Refund to Customer"}</Col>
                     </Row>
                    </div>

                     {props.fareList.map((pax, index) => (
                         <React.Fragment key={index}>
                             <div className="passengerList">
                             <Row>
                                 <Col xs="2">{pax.paxname}</Col>
                                 <Col xs="1">
                                    {pax.ptype === "ADT" &&
                                    "Adult"
                                    }
                                    {pax.ptype === "CHD" &&
                                   "Child"
                                    }
                                    {pax.ptype === "INF" &&
                                    "Infant"
                                    }
                                </Col>
                                 <Col xs="2">{pax.tkt_no}</Col>
                                 {props.sup==="4" &&
                                 <Col xs="1">{pax.pnr}</Col>
                                 }
                                 <Col xs="2">{pax.paid}</Col>
                                 <Col xs="2">{pax.charges}</Col>
                                 {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="same" &&( props.invoicetype=="CREDIT NOTE"?
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[0].cgst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[0].cgst_val)}</Col> :
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[1].cgst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[1].cgst_val)}</Col>)
                                  }
                                {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="same" &&( props.invoicetype=="CREDIT NOTE"?
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[0].sgst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[0].sgst_val)}</Col> :
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[1].sgst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[1].sgst_val)}</Col>)
                                  }
                                {props.ow_gst!==undefined && props.ow_gst!="" && props.ow_gst.type!==undefined && props.ow_gst.type=="other" &&( props.invoicetype=="CREDIT NOTE"?
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[0].igst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[0].igst_val)}</Col> :
                                  <Col xs="1"> {props.triptype!==undefined && (props.triptype!="2"?props.ow_gst.gst_invoice[1].igst_val:props.rt_gst!=="" && props.rt_gst!==undefined&& props.rt_gst.gst_invoice[1].igst_val)}</Col>)
                                  }
                                 <Col xs="2">{pax.total}</Col>
                                </Row>
                                </div>
                         </React.Fragment>
                     ))}

              </div>
         </>

     )
 }


 export default PaxFarePdf;
