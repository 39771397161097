import React from 'react';
import { confirmAlert } from "react-confirm-alert";
    //Confirmation dialog popup to delete the Branch
    export const confirmBranchDlg = (obj,name,cb) => {
        console.log('confirm box....................')
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <>
                        <div className="custom-ui">
                            <span className="confirmD"></span>
                            <h3>Are you sure?</h3>
                            <p className="t-color2 mb-1">You want to delete the {name} <strong>{obj.bn}?</strong></p>
                        </div>
                        <div className="buttonGrop text-center border-0 pb-0">
                            <button
                                className="btn btn-xs btn-secondary pl-4 pr-4"
                                onClick={() => {
                                    cb();
                                    onClose();
                                }}>
                                Yes
                            </button>
                            <button className="btn btn-primary btn-xs pl-4 pr-4" onClick={onClose}>
                                No
                            </button>
                        </div>
                    </>
                );
            }
        });
    };
