
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";

function PopularRoutes(props) {
    const [state, setState] = useState({})


    return (
        <>
            <div className="popularRoutes">
                <h3>Popular Routes</h3>
                <p className="mb-4">World’s best tourist destinations</p>
                <Row>
                    <Col>
                        <div className="routs">
                            <div className="d-flex align-items-center">
                                <span className="ezyIcon icon-flight">Abu Dhabi (AUH)</span>
                            </div>
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">London (LHR)</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="routs">
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Dubai (DXB)</span>
                            </div>
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Singapore (SIN)</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="routs">
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Abu Dhabi (AUH)</span>
                            </div>
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Bangkok (BKK)</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="routs">
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Dubai (DXB)</span>
                            </div>
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Houston (IAH)</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="routs">
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">Abu Dhabi (AUH)</span>
                            </div>
                            <div className="d-flex align-items-center">
                            <span className="ezyIcon icon-flight">New York (JFK)</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default PopularRoutes;
