import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Context } from "../../../../App";
import { Alert, Button,Col, Form, Row, Table,Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import {getAllAlerts , getAlertView } from '../../dashBoard/operations';
import DateUtils from "../../commonUtils/DateUtils";
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce"
import AirportMemo from '../../common/airlines/airportNames';
import NoRecordFound from '../popUps/NoRecordFound';
import AlertModal from './AlertModal'
/**
 * Initial State Declaration
 */
 const initialState = {
  notiMessage: '', notiMessageShow: false, notiVarient: '',
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AdminAlertlist(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [remainder, setRemainder] = useState({alertData: []})
    const [updateriminder, setUpdateriminder,] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState(new Date());
    const [day, setDay] = useState("");
    const [user, setUser] = useState("");
    const [name, setName] = useState("");
    const [endDate, setEndDate] = useState("");
    const [ customDate, setCustomDate ] = useState(false)
    const [ alertObj, setAlertobj ] = useState("")
    const [ alertType, setAlertType ] = useState([])
    const [ mainResponse, setMainResponse ] = useState("")
    const [ companyList, setCompanyList ] = useState([])
    const [ selEvntName, selEventName ] = useState("")
    const [ alertViewData, setAlertViewDate ] = useState("")
    // const [ noRecords, showNoRecords ] = useState(false)


    const days = [
    { label: "Today", value: "Today" },
    { label: "Tomorrow", value: 'Tomorrow' },
    { label: "This Week", value: "This Week" },
    { label: "Custom Dates", value: "Custom Dates" }]

    useEffect(() => {
      let data1 = [];
      if(context.logindata!==undefined){
        let is_admin=context.logindata.utype==="att_user"?1:0
        getAllAlerts({"userId":context.logindata.uid,"pcid":context.logindata.pcid,"utype":context.logindata.utype,"is_admin":is_admin}).then(response => {
          if(response.suc) {
            convertData(response.res)
          }
          // else{
          //   showNoRecords(true)
          // }
    })
    }
    setRemainder({alertData: data1});
    }, [context.logindata]);
    useEffect(() => {
      if(context.logindata!==undefined){
      let is_admin=context.logindata.utype==="att_user"?1:0
       let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, is_admin: is_admin}
        AttCommonSerivce.att_companies(pyload).then(response => {
          if (response.data.suc && response.data.res.status === 200) {
            setCompanyList(response.data.res.data)
          }
        })
      }
    }, [context.logindata]);

       //Opne Vew Alert
      const openAlert = (obj) => {
        setAlertobj(obj)
        setShowAlert(false)
        selEventName(obj.alert_name)
        setAlertViewDate("")
          getAlertView(obj.alert_id).then(response => {
            if(response.suc){
              setShowAlert(true)
              setAlertViewDate(response.res)
            }else{
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later'})
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
            }
      })
      }

      const setAlert = (data)=>{
       let data1 = []
       let alert = data.map(item =>{
         return data1.push({label : item.alert_name, value : item.alert_name})
        })
        setAlertType(data1)
      }
    const convertData = (response)=> {
      let data1 = [];
      if (response!==undefined && response.length!==0) {
        setAlert(response)
        response.map((item) => {
          data1.push({ alert_name: item.alert_name,
            noti_dates: new Date(item.noti_date).toISOString(),
            noti_date: moment((item.noti_date)).format('LLLL'),
            noti_date1: DateUtils.prettyDate(item.noti_date, 'DD-MM-YYYY'),
            trvl_date:moment(Date(item.trvl_date)).format('LLLL'),
            pnr: item.pnr,
            company_name: item.company_name,
            company_id: item.company_id,
            booking_id: item.booking_id,
            options: <> <span className="tbAction"><a className="ezyIcon icon-view" onClick={()=>openAlert(item)} ></a> </span></>
                })
            })

         setRemainder({ alertData: data1 });
    setMainResponse(data1)
    }
    }


    const columns = [{
        dataField: 'alert_name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'noti_date',
        text: 'Notification Date & Time',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'trvl_date',
        text: 'Travel Date & Time',
        sort: true,
        filter: textFilter()
      },

      {
        dataField: 'pnr',
        text: 'PNR',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'booking_id',
        text: 'Booking id',
        sort: true,
        filter: textFilter()

      },

      {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
      }
    ];

      const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
      }];
      function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

      const showDate = (value) =>{
        if(value.value === "Custom Dates"){
          setCustomDate(true)
        }
        else{
          setCustomDate(false)
        }
        setDay(value);
      }
    const handleSearch = ()=>{
      if(validateMSearch()){
      checkDate(mainResponse, day.label, name.value, user.value )
      }
    }
    const validateMSearch = () =>{
      if(day=="" && user=="" && name==""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one of the search criteria' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false
      }
      return true;
    }
    const validateSearch=()=>{
      if(startDate===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }else if(endDate===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      return true;
    }
    const checkDate = (array, date, alertName, companyName)=>{
      let currentDate = new Date().toISOString()
      let todayDate = DateUtils.prettyDate(new Date(), 'DD-MM-YYYY')
      let testDate = DateUtils.addDatePlusOne(new Date())
      let tomorrowDate = DateUtils.prettyDate(testDate, 'DD-MM-YYYY')
      let testDate1 = DateUtils.addDatePlusSeven(new Date())
      let weekDate = new Date(DateUtils.addDatePlusOne(testDate1)).toISOString()
      if(date !== undefined){
      // for Today
      if(date == "Today"){
        if(alertName !== undefined && companyName !== undefined){
          let result = array.filter((item) => {
            return item.noti_date1 == todayDate && item.company_id == companyName && item.alert_name == alertName
        });
        setRemainder({alertData : result})
      return
        }
      else if(alertName !== undefined){
      let result = array.filter((item) => {
        return item.noti_date1 == todayDate && item.alert_name == alertName
    });
    setRemainder({alertData : result})
    return
      }
     else if(companyName !== undefined){
      let result = array.filter((item) => {
        return item.noti_date1 == todayDate && item.company_id == companyName
    });
    setRemainder({alertData : result})
    return
      }
     else{
      let result = array.filter((item) => {
        return item.noti_date1 == todayDate
    });
    setRemainder({alertData : result})
    return
     }
    }
    // for tomorrow
      if(date == "Tomorrow"){
        if(alertName !== undefined && companyName !== undefined){
      let result = array.filter((item) => {
        return item.noti_date1 == tomorrowDate && item.alert_name == alertName && item.company_id == companyName
    });
    setRemainder({alertData : result})
    return
    }
       else if(alertName !== undefined){
      let result = array.filter((item) => {
        return item.noti_date1 == tomorrowDate && item.alert_name == alertName
    });
    setRemainder({alertData : result})
    return
    }
       else if(companyName !== undefined){
      let result = array.filter((item) => {
        return item.noti_date1 == tomorrowDate && item.company_id == companyName
    });
    setRemainder({alertData : result})
    return
    }
    else{
      let result = array.filter((item) => {
        return item.noti_date1 == tomorrowDate
    });
    setRemainder({alertData : result})
    return
     }

  }
    // for Weekly
      if(date == "This Week"){
        if(alertName !== undefined && companyName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= currentDate
          &&
          item.noti_dates <= weekDate && item.alert_name == alertName && item.company_id == companyName
      });
    setRemainder({alertData : result})
    return
      }
       else if(alertName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= currentDate
          &&
          item.noti_dates <= weekDate && item.alert_name == alertName
      });
    setRemainder({alertData : result})
    return
      }
        if(companyName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= currentDate
          &&
          item.noti_dates <= weekDate && item.company_id == companyName
      });
    setRemainder({alertData : result})
    return
      }
        else{
        let result = array.filter((item) => {
          return item.noti_dates >= currentDate
          &&
          item.noti_dates <= weekDate
      });
    setRemainder({alertData : result})
    return
      }

    }
    // for Custom Dates
      if(date == "Custom Dates"){
        if(validateSearch()){
        let fromDate1 = new Date(startDate).toISOString()
        let toDate1 = new Date(DateUtils.addDatePlusOne(endDate)).toISOString()
        if(alertName !== undefined && companyName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= fromDate1
          &&
          item.noti_dates <= toDate1 && item.alert_name == alertName
      });
        setRemainder({alertData : result})
        return
        }
        else if(alertName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= fromDate1
          &&
          item.noti_dates <= toDate1 && item.alert_name == alertName
      });
        setRemainder({alertData : result})
        return
        }
        else if(companyName !== undefined){
        let result = array.filter((item) => {
          return item.noti_dates >= fromDate1
          &&
          item.noti_dates <= toDate1 && item.alert_name == alertName
      });
        setRemainder({alertData : result})
        return
        }
        else{
        let result = array.filter((item) => {
          return item.noti_dates >= fromDate1
          &&
          item.noti_dates <= toDate1
      });
        setRemainder({alertData : result})
        return
        }

      }
    }
  }
  else{
    if(alertName !== undefined && companyName !== undefined){
      let result = array.filter((item) => {
        return item.alert_name == alertName && item.company_id == companyName
    });
      setRemainder({alertData : result})
      return
    }
    else if(alertName !== undefined && companyName == undefined){
      let result = array.filter((item) => {
        return item.alert_name == alertName
    });
      setRemainder({alertData : result})
      return
    }
    else if(alertName == undefined && companyName !== undefined){
      let result = array.filter((item) => {
        return item.company_id == companyName
    });
      setRemainder({alertData : result})
      return
    }
  }
 }

 const handleClose=()=>{
  setShowAlert(false)
 }

    return (
        <>
            <Header />
            <div className="container dashBordLayout pt-3">
                <div className="Topfilterbutton">
                    <div class="left-button">
                    <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                    onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                    dismissible> {state.notiMessage}</Alert>
                        <Button className="rmBtn " variant="outline-secondary">
                            <span className="ezyIcon icon-alarm remaindbotton"><Link
            to="/ezytrip/dashboard/remainders/list"
            title="Reminder">
            Reminders
          </Link></span>
                        </Button>

                        <Button className="rmBtn active" variant="outline-secondary">
                            <span className="ezyIcon icon-warning alertbotton">Alerts</span>
                        </Button>
                    </div>
                    <div class="right-filterbutton">
                    <Form.Row>
                            <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                                <Select
                                    options={days}
                                    searchable={false}
                                    values={day}
                                    onChange={showDate}
                                />
                            </Form.Group>

                            <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                                <Select
                                    options={alertType}
                                    searchable={false}
                                    values={name}
                                    onChange={(value)=> setName(value)}
                                />
                            </Form.Group>
                            <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                                <Select
                                    options={companyList}
                                    searchable={false}
                                    values={user}
                                    onChange={ (value) => setUser(value)}
                                />
                            </Form.Group>
                            <Button
                size="sm"
                className="changeSearch ezyIcon icon-search"
                variant="primary"
                onClick={handleSearch}
                >
                Search
              </Button>
                            </Form.Row>
                            { customDate && <Form.Row className="alrtdate">
                            <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="fromDate">
                  <DatePicker
                    selected={startDate}
                    showPreviousMonths
                    minDate = {new Date()}
                    maxDate = {endDate}
                    onChange={(date) => setStartDate(date)}
                    monthsShown={1}
                    // disabled={viewRemainder}
                  />
                  <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>From Date<sup>*</sup></Form.Label>
                </Form.Group>
                 <Form.Group as={Col} xs={3} className="selectplugin datePicker" controlId="toDate">
                  <DatePicker
                    selected={endDate}
                    showPreviousMonths
                    minDate = {startDate}
                    onChange={(date) => setEndDate(date)}
                    monthsShown={1}
                    // disabled={viewRemainder}
                  />
                  <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>To Date<sup>*</sup></Form.Label>
                            </Form.Group>

                            </Form.Row> }
                    </div>
                </div>
                <div className="cardMainlayout pb-0">
{/* {!noRecords ?  */}
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={remainder.alertData}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={remainder.alertData.length >10 && remainder.alertData.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  remainder.alertData.length >25 &&remainder.alertData.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  remainder.alertData.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
{/* :<NoRecordFound/>} */}
                </div>

        </div>

        {showAlert && alertViewData!==undefined &&
          <AlertModal showAlert={showAlert} alertViewData={alertViewData} selEvntName= {selEvntName} handleClose={handleClose}/>
        }
        
              <Footer />
        </>
    )
}


export default AdminAlertlist;
