
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import MainMenu from '../../../../components/common/MainMenu';
import OrganizationMenu from '../../../../components/common/subMenu/OrganizationMenu';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import NoRecord from '../../../common/popUps/NoRecord';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { creation, deletion, getCategoryData, update } from './operations';


/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Rambabu
 * @param {*}
 * @function Categeroy
 * @date : 13-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
    categoryName: '', showMessage: false, loadCategory: false, loadEdit: false, loadSubmit: false, id: 0,
    res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,isItineraryLoading:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, categoryName: ''
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Category
 */
function Category() {

    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        dispatch({ type: 'isLoadingMore', payload: true });
        dispatch({ type: 'enbaleUpdate', payload: false })
        getCategoryData().then(response => {
            if (response && response.length !== 0) {
                loadAllCategories(response);

            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }, [context.logindata]);

    const loadAllCategories = (response) => {

        let data = [];
        var enableWEdit = false;
        var enableDel = false;
        if (context.logindata !== "" && context.logindata.roles !== undefined) {
            if (context.logindata.roles.includes('C_T_U')) {
                enableWEdit = true;
            }
            if (context.logindata.roles.includes('C_T_D')) {
                enableDel = true;
            }

            for (let val of response) {
                data.push({ cname: val.cn, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editCategory(val, "view")}></a> {enableWEdit && <a className="ezyIcon icon-edit" onClick={editCategory(val, "edit")}></a>}</span>  </> })

            }
            dispatch({ type: 'res', payload: data })

        }
        dispatch({ type: 'res', payload: data })
        dispatch({ type: 'isLoadingMore', payload: false });
    }



    // OnChange event
    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === 'categoryName') {
            dispatch({ type: id, payload: value });
        }
    }


    //redirect to admin page
    const handleCancel = (e) => {
        dispatch({ type: 'categoryName', payload: '' })
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    //Create Categeory
    const handleCategoryCreation = () => {

        if (state.categoryName !== null && state.categoryName !== undefined && state.categoryName !== '') {
            let suc = true
            for (let val of state.res) {
                if ((val.cname).toLowerCase() == (state.categoryName).toLowerCase()) {
                    suc = false
                }
            }
            if (suc) {
                state.crby = context.logindata.uid;
                if (characterValidation(state.categoryName)) {
                    dispatch({ type: "nameError", payload: false })
                    dispatch({ type: 'isItineraryLoading', payload: true });
                    creation(state).then((addRes) => {
                        if (addRes.suc) {
                            getCategoryData().then(response => {
                                if (response && response.length !== 0) {
                                    loadAllCategories(response);

                                }
                                dispatch({ type: 'isItineraryLoading', payload: false });
                                dispatch({ type: 'isLoadingMore', payload: false });
                            })
                            dispatch({ type: 'categoryName', payload: '' });
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Category saved successfully' });
                            dispatch({ type: 'notiVarient', payload: 'success' });
                            hidemessage()
                            dispatch({ type: 'isItineraryLoading', payload: false });
                        } else {
                            dispatch({ type: 'isItineraryLoading', payload: false });
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Category has not saved..' });
                            dispatch({ type: 'notiVarient', payload: 'danger' });
                            hidemessage()
                        }
                    });
                } else {
                    dispatch({ type: "nameError", payload: true })
                    dispatch({ type: 'isItineraryLoading', payload: false });
                }
            } else {
                dispatch({ type: 'notiMessageShow', payload: true });
                dispatch({ type: 'notiMessage', payload: 'Category already exists' });
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
            }
        } else {
            dispatch({ type: 'isItineraryLoading', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Category name is required' });
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
        }
    }

    //Edit Categeory
    const editCategory = (obj, type) => () => {
        dispatch({ type: 'categoryName', payload: obj.cn });
        dispatch({ type: 'id', payload: obj.id });
        dispatch({ type: 'enbaleUpdate', payload: true });
        if (type === "view") {
            dispatch({ type: 'enableView', payload: true });
        } else {
            dispatch({ type: 'enableView', payload: false });
        }

    }

    //Update Categeory
    const updateCategory = (e) => {
        dispatch({ type: 'showMessage', payload: false });
        dispatch({ type: 'loadCategory', payload: true });
        state.upby = context.logindata.uid;
        dispatch({ type: 'isItineraryLoading', payload: true });
        update(state).then(response => {
            if (response.suc) {
                let updtedCat = state.res.map(
                    (obj, sidx) => {
                        if (obj.id !== state.id) return obj;
                        return {
                            ...obj,
                            cn: state.categoryName
                        };
                    }
                );
                dispatch({ type: 'res', payload: updtedCat })
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Category updated successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                dispatch({ type: 'loadCategory', payload: false });
                dispatch({ type: 'enbaleUpdate', payload: false });
                dispatch({ type: 'categoryName', payload: "" });
                getCategoryData().then(response => {
                    if (response && response.length !== 0) {
                        loadAllCategories(response);

                    }
                    dispatch({ type: 'isItineraryLoading', payload: false });
                })

            } else {
                dispatch({ type: 'isLoadingMore', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Category has not updated..' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
                dispatch({ type: 'loadCategory', payload: false });
            }
            dispatch({ type: 'isItineraryLoading', payload: false });
        })
    }

    // Delete Categeory(Soft delete)
    const deleteCat = (obj) => {
        deletion(obj).then((response) => {
            if (response.suc) {
                var array = [...state.res]; // make a separate copy of the array
                var index = array.indexOf(obj)
                if (index !== -1) {
                    array.splice(index, 1);
                    dispatch({ type: 'res', payload: array })
                }
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Category deleted successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' })

            } else {

                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Category Not deleted..' })
                dispatch({ type: 'notiVarient', payload: 'danger' })

            }
        });
    }

    //Confirmation dialog popup to delete the Branch
    const confirmDlg = obj => () => {
        confirmBranchDlg(obj, 'Categeroy', function () {
            deleteCat(obj);
            dispatch({ type: 'loadSubmit', payload: false })
        });
    };

    const columns = [{
        dataField: 'cname',
        text: 'Category Name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
    }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const handleSizePage = () =>{
       let list=[];
       if(state.res !==undefined ){
           const size = state.res.length;


            list.add( {
                text: '10', value: 10
              })


            list.add({
                text: '25', value: 25
              })


            list.add({
                text: '30', value: 30
              })


            list.add({
                text: '50', value: 50
              })

       }
       return list;
    }


    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="Category Creation" />
            <div className="container">
                <div className="cardMainlayout pt-0">
                    <OrganizationMenu active="Category Creation" />
                    <div className="contentArea">
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
                        <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                                <Form.Group as={Col} xs={3} controlId="categoryName">

                                    <Form.Control type="text" placeholder="Enter Category Name" autoComplete="off" value={state.categoryName} onChange={handleChange} />
                                    <Form.Label>Category Name<sup>*</sup></Form.Label>
                                    {state.showMessage && (
                                        <div className="invalid-feedback">
                                            Enter Category Name
                                        </div>
                                    )}
                                    {state.nameError && (
                                        <p className="vError">Please Enter Valid Category</p>
                                    )}
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <div className="text-right buttonGrop pb-0">
                            {context.logindata.roles !== undefined && context.logindata.roles.includes('C_T_C') &&
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={handleCancel}
                                    type="submit"
                                    isDisabled={state.enableView}
                                >Reset</Button>
                            }
                            {!state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('C_T_C') && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={handleCategoryCreation}
                                    loading={state.loadCategory}
                                    type="submit"
                                    disabled={!context.logindata.roles.includes('C_T_C') ? true : false}
                                > Add Category</Button>
                            )}
                            {state.enbaleUpdate && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateCategory}
                                    loading={state.loadCategory}
                                    isDisabled={state.enableView}
                                    type="submit"
                                > Update Category</Button>
                            )}
                        </div>

                    </div>
                    <div>
                    </div>
                </div>

                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl cateGorycreation"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}



                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}

                </div>

            </div>

            <Footer />
        </>
    )


}

export default Category