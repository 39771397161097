
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useEffect, useReducer, useState } from 'react';
 import { useHistory } from 'react-router-dom';
 import AirlinesMemo from '../../common/airlines';
 import DateUtils from "../../commonUtils/DateUtils";
 import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
 import SummaryLayoverDetails from './SummaryLayoverDetails';
 import { Col, Form, Row,Table, Alert, Modal, Button} from "react-bootstrap";
 import {getSummaryLongfareRules,getItnarary} from './operations';
 import ShowRules from '../../bookingFlows/flight/fareRules/ShowRules';
 export const EDIT_DATA = "editData";
 /**
  * Initial State Declaration
  */
 const initialState = {
     source: '', destination: '', duration: '', travel_type: '', departure_date: '' , gdspnr:"",notiMessageShow: false, notiMessage: '', notiVarient: ''
 };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 /**
  * @description:This function will Display the the Segment details
  * @author: Rambabu
  * @param {*}
  * @function FlightSummarySegmentDetails
  * @date : 18-09-2020
  */
 function FlightSummarySegmentDetails(props) {

     const [state, dispatch] = useReducer(reducer, initialState);
     const history = useHistory();
     const [lgShow, setLgShow] = useState(false);
     const [fareload, setFareload] = useState(false);
     const [longrules, setLongRules] = useState([])
     const [serviceFee, setServiceFee] = useState("")
     const [applicables, setApplicables] = useState([])
     const [isLoadingMore, setIsLoadingMore,] = useState(false);
     const [showLong, setShowLong] = useState(false)
     const [request, setRequest] = useState("")
     const [showData, setShowData] = useState("")


     const [tablist, setTablist] = useState({
        active: "Onward"

      });


     /**
      * @description:This function will Display the the Segment details
      * @author: Rambabu
      * @date : 02-10-2020
      * @param {*} code
      * @param {*} val
      */
     function getAirportdata(code, val) {

         if (isNotNull(props.airport_data) && isNotNull(code)) {

             for (let a in props.airport_data) {

                 let air_data = props.airport_data[a];

                 if (isNotNull(air_data.airportCode)) {

                     if (air_data.airportCode === code && val === 'c') {

                         return air_data.cityName;

                     } else if (air_data.airportCode === code && val === 'a') {

                         return air_data.airportName;
                     }
                 }
             }
         }
     }

     const handleSegChange = (data,type,flowType) => (e) => {
         const { id, value } = e.target
         dispatch({ type: id, payload: value });
         localStorage.removeItem(EDIT_DATA);
         if(props.data.length!==0){
             for(let seg of props.data){
                if(flowType==="normal"){
                 if(type==="gds"){
                     seg.normal_gds_pnr=value;
                     seg.changed=true;
                 }
                 if(seg.segid===data.segid){
                     seg.changed=true;
                     if(type==="airline"){
                         seg.normal_airline_pnr=value;
                     }
                    }
                 }else{
                 if(type==="gds"){
                     seg.split_gds_pnr=value;
                     seg.changed=true;
                 }
                 if(seg.segid===data.segid){
                     seg.changed=true;
                     if(type==="airline"){
                     seg.split_airline_pnr=value;
                     }
                     }
                 }
             }
             localStorage.setItem(EDIT_DATA,JSON.stringify(props.data));
        }
     }

      //Showing the Rules for Mini fares
      const openLongRules = () => {
        getItnarary({"tid":props.data[0].tid}).then(resp => {

            if (resp.success && resp.data !== undefined) {
                setRequest(resp.data[0]);
                let request=resp.data[0];
                request.ruleType="ALL";
                setFareload(true)
                getSummaryLongfareRules(request).then(response => {
                    if (response !== undefined && response.longRuleRes !== undefined && response.longRuleRes.status === "success") {
                        let cnt=1;
                        for(let val of response.longRuleRes.data[0].sectors){
                            if(cnt===1){
                                val.selected=true;
                                 setShowData(val);
                            }else{
                                val.selected=false;
                            }
                            cnt=cnt+1;
                        }
                        setLongRules(response.longRuleRes.data);
                        setApplicables(response.longRuleRes.data[0].apcbleRules)
                        setShowLong(true);
                        setLgShow(true);
                        setIsLoadingMore(false);
                        setFareload(false)
                        if(response.longRuleRes.data[0].sf!==undefined){
                        setServiceFee(response.longRuleRes.data[0].sf)
                        }
                    }else{
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
                        dispatch({ type: 'notiVarient', payload: 'danger' })
                        hidemessage();
                    }
                })
            }
          })

     };

     const hidemessage = () => {
         setTimeout(function () {
           dispatch({ type: 'notiMessageShow', payload: false })
           dispatch({ type: 'notiMessage', payload: '' })
           dispatch({ type: 'notiVarient', payload: '' })
         }, 5000);
       }

       const conversion = (ex) => {
           if(ex.includes(",")) {
            let pnrarray = ex.split(",")
            return pnrarray[0]
           } else {
            return ex
           }
       }

        //Updating seleted segment
  const handleChangeRule = (val) => (e) => {
  if(longrules.length && applicables.length!==0){
    let secs = longrules[0].sectors.filter((q, idx) => q.selected);
      for(let rule of applicables){
          if (!rule.selected && rule.value===val){
              rule.selected = true;
              var req = request;
              req.ruleType=val;
              getSummaryLongfareRules(req).then(response => {
                if (response !== undefined && response.longRuleRes !== undefined && response.longRuleRes.status === "success") {
                    setShowLong(false);
                    for(let sec of response.longRuleRes.data[0].sectors){
                        if(secs[0].id===sec.id){
                            sec.selected=true;
                            setShowData(sec);
                        }else{
                            sec.selected=false;
                            }
                    }
                    setLongRules(response.longRuleRes.data);
                      setShowLong(true);
                      setIsLoadingMore(false);
                      setLgShow(true);
                  } else {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage();
                  }
              })
          }else{
              rule.selected = false;
            }
          }
  }
}

const handleTabChange=(obj)=>{
    for(let sec of longrules[0].sectors){
     if(sec.id===obj.id){
        sec.selected=true;
        setShowData(obj);
     }else{
        sec.selected=false;
     }
    }
}



     return (
         <>
            {state.notiMessageShow &&
           <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
            }
             {isArrayNotEmpty(props.data) && (
                 props.data.map((seg, index) => (

                     <div className="flightRoutInfo">

                         <Row className="align-items-center rs-row">

                             <Col xs={3} className="rs-rTypeDate">
                                 <div className="airLogo d-flex align-items-center gg">
                                     <img
                                         src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + seg.markting_airline + '.png'}
                                         className="logo"
                                         alt="AirLine"
                                     />
                                     <div className="flightNo d-flex flex-column ml-4">
                                         <strong><AirlinesMemo code={seg.markting_airline} /></strong>
                                         <span>
                                        {/*  <Icon className="mr-1" color="#888888" size={14} icon="Aircraft" /> */}
                                         {/* Boeing  */}
                                         {seg.markting_airline}-{seg.flt_no}</span>

                                         <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={seg.operating_airline} /></span></div>
                                         {/* <span>{seg.flt_no}</span> */}
                                         {/* <span>WY - 668</span> */}
                                         {/* <span>Boeing 737-800</span> */}

                                     </div>
                                 </div>
                             </Col>
                             <Col xs={7} className="rs-FromTo">
                                 <Row className="align-items-center">
                                     <Col className="d-flex flex-column">
                                         {/* <strong>03:55</strong>
                                         <span>Doha (DOH),</span>
                                         <span> Hamad International Airport</span> */}
                                         <span><strong>{DateUtils.prettyDate(seg.depart_time, "HH:mm")}</strong> {DateUtils.prettyDate(seg.depart_time, "ddd, DD MMM YYYY")}</span>
                                         <span>{getAirportdata(seg.seg_source, 'c')} ({seg.seg_source}),</span>
                                         <span>{getAirportdata(seg.seg_source, 'a')}</span>
                                         {seg.depart_terminal &&
                                         <small className="trm">{"Terminal - " + seg.depart_terminal}</small>
                                         }
                                     </Col>
                                     <Col className="p-0" xs="1"><span className="ezyIcon icon-flight ml-2"></span></Col>
                                     <Col className="d-flex flex-column">
                                         {/* <strong>06:25</strong>
                                         <span> Dubai (DXB),</span>
                                         <span> Dubai International Airport</span> */}
                                         <span><strong>{DateUtils.prettyDate(seg.arrival_time, "HH:mm")}</strong> {DateUtils.prettyDate(seg.arrival_time, "ddd, DD MMM YYYY")}</span>
                                         <span>{getAirportdata(seg.seg_destination, 'c')} ({seg.seg_destination}),</span>
                                         <span>{getAirportdata(seg.seg_destination, 'a')}</span>
                                         {seg.arrival_terminal &&
                                         <small className="trm">{"Terminal - " + seg.arrival_terminal}</small>
                                         }
                                     </Col>
                                 </Row>

                                 <span>

                                 </span>
                             </Col>
                             <Col xs={2} className={props.isEdit ? 'text-right':''}>
                                 {props.supplier === 0 || props.supplier === "0"?<p><span>Class : </span>{seg.cabin_class} {seg.fare_class ? " - " + seg.fare_class : ""}</p>:<p><span>Fare Type : </span>{seg.fare_type==="Regular - R"? "SAVER":seg.fare_type==="Flexi - J"?"FLEXI":seg.fare_type==="Corporate - F"?"CORPORATE":seg.fare_type}</p>}
                                 {!props.isEdit && !props.isEditReturn &&
                                  <>
                                 {seg.flow_type === 'split' && (<p><span>Airline PNR : </span> {seg.split_airline_pnr ? seg.split_airline_pnr : "N/A"}</p>)}
                                 {seg.flow_type === 'normal' && (<p><span>Airline PNR : </span> {seg.normal_airline_pnr ? props.supplier == 7 ? (seg.normal_airline_pnr.includes("/") ? (seg.journey_type == "O" ? (seg.normal_airline_pnr.split("/")[0]):(seg.normal_airline_pnr.split("/")[1])): (seg.normal_airline_pnr)): (seg.normal_airline_pnr) : "N/A"}</p>)}
                                  </>
                                 }
                                  {props.isEdit && seg.flow_type === 'normal' &&
                                      <Form.Row className="justify-content-end align-items-center">
                                          <Form.Label>Airline PNR</Form.Label>
                                        <Form.Group as={Col} xs={8} controlId="normal_airline_pnr" className="edittime d-flex align-items-center justify-content-between">
                                         <Form.Control type="text" placeholder="Enter Ticket number" value={seg.normal_airline_pnr} onChange={handleSegChange(seg,"airline",seg.flow_type)} />
                                          </Form.Group>

                                          </Form.Row>
                                         }
                                     {seg.flow_type === 'split' && (props.isEdit || props.isEditReturn) &&
                                      <Form.Row className="justify-content-end align-items-center">
                                          <Form.Label>Airline PNR</Form.Label>
                                        <Form.Group as={Col} xs={8} controlId="split_airline_pnr" className="edittime d-flex align-items-center justify-content-between">
                                         <Form.Control type="text" placeholder="Enter Ticket number" value={seg.split_airline_pnr} onChange={handleSegChange(seg,"airline",seg.flow_type)} />
                                          </Form.Group>

                                          </Form.Row>
                                         }
                                {!props.isEdit && !props.isEditReturn && (props.supplier == undefined || props.supplier === 0)  &&
                                  <>
                                 {seg.flow_type === 'split' && (<p><span>GDS PNR : </span> {seg.split_gds_pnr ? seg.split_gds_pnr : seg.normal_gds_pnr ? seg.normal_gds_pnr : "N/A"}</p>)}
                                 {seg.flow_type === 'normal' && (<p><span>GDS PNR : </span> {seg.normal_gds_pnr ? seg.normal_gds_pnr.includes(",") ? conversion(seg.normal_gds_pnr): seg.normal_gds_pnr : "N/A"}</p>)}
                                 </>
                                }
                                 {props.isEdit && seg.flow_type === 'normal' &&
                                      <Form.Row className="justify-content-end align-items-center">
                                          <Form.Label>GDS PNR</Form.Label>
                                            <Form.Group as={Col} xs={8} controlId="normal_gds_pnr" className="edittime d-flex align-items-center justify-content-between">
                                            <Form.Control type="text" placeholder="Enter Ticket number" value={seg.normal_gds_pnr} onChange={handleSegChange(seg,"gds",seg.flow_type)} />
                                          </Form.Group>
                                          </Form.Row>
                                 }
                                 {seg.flow_type === 'split' && (props.isEdit || props.isEditReturn) &&
                                      <Form.Row className="justify-content-end align-items-center">
                                          <Form.Label>GDS PNR</Form.Label>
                                            <Form.Group as={Col} xs={8} controlId="split_gds_pnr" className="edittime d-flex align-items-center justify-content-between">
                                            <Form.Control type="text" placeholder="Enter Ticket number" value={seg.split_gds_pnr} onChange={handleSegChange(seg,"gds",seg.flow_type)} />
                                          </Form.Group>
                                          </Form.Row>
                                 }
                             </Col>
                         </Row>
                         {/* {seg.bgd  && ("Baggage Info : " + "Cabin "+seg.bgd + "  per Adult & Child")} */}
                         {((seg.sup != undefined && seg.sup==="0") || seg.supplier==="0") &&
                         <div className="bgginfo">
                         <Row>
                             <Col xs="10">{seg.bgd  && <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                             </Col>
                             {(props.supplier == 0  || seg.supplier==="0") &&
                             <Col><span className="linkStyle" onClick={openLongRules}>{fareload ? <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : ''}Fare rules</span></Col>
                             }
                         </Row>
                         </div>
                          }
                          {(((seg.sup != undefined && seg.sup!=="0") || seg.supplier!=="0") && seg.bgd && seg.bgd !=='-') &&
                         <div className="bgginfo">
                         <Row>
                             <Col xs="10">{seg.bgd  && <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                             </Col>
                         </Row>
                         </div>
                          }
                         {/* LayoverDetails */}
                         {props.data.length > 1 &&
                             (isNotNull(props.data[(index + 1)]))
                             && <SummaryLayoverDetails layoverdata={[props.data[index + 1].depart_time, props.data[(index)].arrival_time, seg.seg_destination]} mappings={props.airport_data} />}

                     </div>
                     

                 ))

             )}

            <Modal
                size="lg"
                show={lgShow}
                className="rulesancharges longfare"
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className="mb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Flight Long Fare Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                {serviceFee!==undefined && serviceFee!=="" && serviceFee.service_fee_value!==undefined &&
                <Alert variant="info" className="ezyIcon icon-info">
                <p className="ml-1">Travel Agency service of QAR {serviceFee.service_fee_value} applies {serviceFee.applicable_on} for reissue and refund.</p>
                </Alert>
                }
                <strong>Please select the menu and confirm the content of each rule :</strong>
          <Row>
              <Col xs={4}>
            <div className="selectoption">
            {applicables.length!==0 &&  applicables.map((app, index) => (
            <React.Fragment key={index}>
            <Form.Check type="radio" id={index + 1 +"-"+app.value} onChange={handleChangeRule(app.value)} checked={app.selected} value={app.value} label={app.label} name="longfare" custom />
            </React.Fragment>
            ))}
            </div>
              </Col>
              <Col xs={8}>
              <div className="fareLink">
                   {longrules.length!==0 && longrules[0].sectors.map((sec, index) => (
                    <Button
                    size="xs"
                    variant="link"
                    type="submit"
                    className={"selectFare " + (sec.selected ? "active" : " ")}
                    onClick={()=>handleTabChange(sec)}
                    >{sec.dep} {"-"} {sec.arr}</Button>

                   ))}
                   </div>
                   {showData!=="" &&
                   <ShowRules data={showData} mappings={props.airport_data} type={"O"}/>
                   }
                   </Col>
              </Row>
              </Modal.Body>
            </Modal>
         </>

     )
 }


 export default FlightSummarySegmentDetails;
