import React from 'react';
import { Col, Row,Button,Modal,Form,Navbar,Nav, Container,NavDropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import LoginForm from '../../components/authentication/LoginForm';
import Indialogmap from "../../assets/images/map.png";
import logo from "../../assets/images/logo.svg";
import Footerimg from "../../assets/images/footer.png";
function Login(props) {
    return (
<>
<Container fluid>
<section>



    <div className='d-flex indloginSec'>
    <Link to="/ezytrip/login" ><span className='ezyIcon icon-rightArrow pt-2'>Back</span></Link>
        <Row>
            <Col lg={6} md={12}>
            <div className='indleftMap'>

<div className="mapImgind">
<span className='mapImg'><img
              src={Indialogmap}
              title=""
              alt="img"

              /></span>

              <div className='contusInd'>
                <ul className='d-flex'>
                    <li>
                    <span>Our team is here to Help.</span>
                    <a id="mail" href="mailto:support@ezytrip.com" className="ezyIcon icon-new-email">support@ezytrip.com</a>
                    </li>
                    <li>
                    <span>Contact Us.</span>
                    <a id="phone" href="tel:9121027812" className="ezyIcon icon-contactUs"> +91 9121027812</a></li>
                    <li>
                    <span>24x7 Support.</span>
                    <a id="phone" href="tel:4035168021" className="ezyIcon icon-support">040 4035168021</a>
                   </li>
                </ul>
              </div>

<span className='footerimgInd'><img
              src={Footerimg}
              title=""
              alt="img"
              /></span>
</div>
</div>
            </Col>
            <Col lg={6} md={12}>
            <div className='indrightform'>
                <div className='bg'></div>
                <div className='d-flex formSectionind'>
                    <div className='lefOne'>

                     <LoginForm />

                     </div>

                     <div className='rigOne'>
                     <ul className='indLoginicons'>
                            <li>
                                <span className='ezyIcon icon-plane'>Flights</span>
                            </li>
                            <li>
                                <span className='ezyIcon icon-newhotel'>Hotels</span>
                            </li>
                            <li>
                                <span className='ezyIcon icon-plussheild'>Insurances</span>
                            </li>
                            <li>
                                <span className='ezyIcon icon-visa'>Visa</span>
                            </li>

                        </ul>
                     </div>



        </div>
            </div>
            </Col>
        </Row>


    </div>

</section>
<Col lg={12}>


</Col>
</Container>
</>

            )
  }
  export default Login