
import React, {useState, useEffect, useReducer } from 'react';
import { Form, Button} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import InputRange from 'react-input-range';
import { Scrollbars } from 'react-custom-scrollbars';
import { isNotNull, isArrayNotEmpty } from '../../../../commonUtils/validators';
import ShowHide from '../../../../common/showHide/ShowHide'
import Sticky from '../../../../common/sticky/Sticky';
const _ = require("lodash");


const initialState = {
    searchresponse: "", price: { min: 0, max: 0 }, expedia: { min: 0, max: 0 }, rating: [], accomadation: [], amenities: [],
    localResponse: {}, finalResponse: {}, searchhtlname: [], hotelnameslist: '', boardbasis: [], area: [], landmark: [], arealist: '',
    searchareaname: [], htlname: [], arename: [], landmarklist: '', searchlandmarkname: [], landmarkname: [], strratdisplay: [], accomdisplay: [],
    pricedisplay: { min: 0, max: 0 }, expediadisplay: { min: 0, max: 0 }, amenitiesdisplay: [], boradbasisdisplay: [], isbordbasisfilter: false,
    ispricefilter: false, isstartratfilter: false, isaccomfilter: false, isexpratfilter: false, isareafilter: false, islandmarkfilter: false,
    isamenitifilter: false, showAreaList : true

};
let accmsno = [];
let amenitesno = [];
let brdbasesno = [];
let areasno = [];
let areanamesno = [];
let landmarksno = [];
let lmarknamesno = [];

// Reducer function for userReact Hook
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state,rating:[],accomadation:[],amenities:[],boardbasis:[],area:[],landmark:[],arename:[],landmarkname:[],searchhtlname:[],

            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};



//Main Function
function Filter(props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [showFull, setShowfull] = useState(false);
    const [showFullamt, setShowFullamt] = useState(false);
    const [showFullBds, setShowFullBds] = useState(false);
    const [showArea, setShowArea] = useState(false);
    const [showLandmarks, setShowLandmarks] = useState(false);
    useEffect(() => {
        const searcresponse = props.hotelresponse;
        dispatch({ type: 'finalResponse', payload: Object.assign({}, searcresponse) })
        dispatch({ type: 'localResponse', payload: searcresponse })
        dispatch({ type: 'hotelnameslist', payload: searcresponse.filters.hotelname })
        if(searcresponse.filters.area == undefined ){
        dispatch({ type: 'showAreaList', payload: false})
        }
        dispatch({ type: 'arealist', payload: _.orderBy(searcresponse.filters.area, ['count'], ['desc']) })
        dispatch({ type: 'landmarklist', payload:  _.orderBy(searcresponse.filters.landmark, ['count'], ['desc']) })
        dispatch({ type: 'price', payload: { min: props.hotelresponse.filters.price.minprc, max: props.hotelresponse.filters.price.maxprc } })
        dispatch({ type: 'pricedisplay', payload: { min: props.hotelresponse.filters.price.minprc, max: props.hotelresponse.filters.price.maxprc } })
        dispatch({ type: 'expedia', payload: { min: props.hotelresponse.filters.exprat.minprc, max: props.hotelresponse.filters.exprat.maxprc } })
        dispatch({ type: 'expediadisplay', payload: { min: props.hotelresponse.filters.exprat.minprc, max: props.hotelresponse.filters.exprat.maxprc } })
        dispatch({ type: 'strratdisplay', payload: props.hotelresponse.filters.strrat })
        let accom = _.orderBy(props.hotelresponse.filters.accom, ['count'], ['desc'])
        dispatch({ type: 'accomdisplay', payload: accom })
        let boradbasis = _.orderBy(props.hotelresponse.filters.boardbasis, ['count'], ['desc'])
        dispatch({ type: 'boradbasisdisplay', payload: boradbasis })
        let ameni = _.orderBy(props.hotelresponse.filters.amenities, ['count'], ['desc'])
        dispatch({ type: 'amenitiesdisplay', payload: ameni })
        dispatch({ type: 'clear', payload: true })
         accmsno = [];
         amenitesno = [];
         brdbasesno = [];
         areasno = [];
         areanamesno = [];
         landmarksno = [];
         lmarknamesno = [];


    }, [props.hotelresponse])


    /**
     *
     * @param {*} value
     * @description:Price Filter
     * @author: Rambabu
     * @date:01-03-2021
     */
    const pricehandleChange = (value) => {

        dispatch({ type: 'price', payload: value })
        dispatch({ type: 'ispricefilter', payload: true })

        //price filter
        let filterres = state.finalResponse.prpts.filter(htl => {
            return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
        })


        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })

        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }


        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }

        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.prpts = filterres;
        state.localResponse.filters.hotelname = htlList;
        props.filterresponse(state.localResponse, '', 'Filter');
    }

    /**
    *
    * @param {*} value
    * @description:priceChange Filter
    * @author: Rambabu
    * @date:29-04-2021
    */
    const priceChange = (value) => {
        dispatch({ type: 'price', payload: value })
    }

    /**
    * @param {*} e
    * @description:Star Rating Filter
    * @author: Rambabu
    * @date : 01-03-2021
    */
    const startratinghandleChange = data => e => {

        dispatch({ type: 'isstartratfilter', payload: true })
        let strrating = _.cloneDeep(state.finalResponse.filters.strrat);

        //if checked
        if (e.target.checked) {
            state.rating.push(e.target.value)
        }//if unchecked
        else {
            for (var i = 0; i < state.rating.length; i++) {
                if (state.rating[i] === e.target.value) {
                    state.rating.splice(i, 1);
                    i--;
                }
            }
        }

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }


        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })

        }

        //Check box displaying
        if (isArrayNotEmpty(state.rating)) {
            for (let acc in state.rating) {
                let val = state.rating[acc];
                if (e.target.checked) {
                    for (let obj of strrating) {
                        if (obj.rat == val) {
                            obj.ischeck = true;
                        }
                    }
                } else {

                    strrating.map(obj => {
                        if ((obj.rat != val) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.ischeck = false;
                        } else {
                            obj.ischeck = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })


                }
            }
        } else {
            strrating.map(obj => { obj.ischeck = false; })
        }

        //after condition is rendered isseleted made it as false
        strrating.map(obj => { obj.isselected = false; })
        strrating = _.orderBy(strrating, ['count'], ['desc'])
        dispatch({ type: 'strratdisplay', payload: strrating });


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }


        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }


        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.strrat = strrating;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }


    /**
    * @param {*} e
    * @description:Accomadation Filter
    * @author: Rambabu
    * @date : 01-03-2021
    */
    const accomadationhandleChange = data => e => {

        dispatch({ type: 'isaccomfilter', payload: true })
        let accomm = _.cloneDeep(state.finalResponse.filters.accom);

        //if checked
        if (e.target.checked) {
            state.accomadation.push(e.target.value);
            accmsno = accmsno.concat(data.sno);

        }//if unchecked
        else {
            for (var i = 0; i < state.accomadation.length; i++) {
                if (state.accomadation[i] === e.target.value) {
                    state.accomadation.splice(i, 1);
                    i--;
                    let removelist = _.remove(accmsno, function (obj) {
                        return data.sno.includes(obj);
                    });

                }
            }
        }

        if (isArrayNotEmpty(state.accomadation)) {
            for (let acc in state.accomadation) {
                let val = state.accomadation[acc];
                if (e.target.checked) {
                    for (let obj of accomm) {
                        if (obj.type.toUpperCase() === val.toUpperCase()) {
                            obj.isaccom = true;
                        }
                    }
                } else {
                    accomm.map(obj => {
                        if ((obj.type.toUpperCase() !== val.toUpperCase()) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.isaccom = false;
                        } else {
                            obj.isaccom = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })


                }
            }
        } else {
            //after condition is rendered isseleted made it as false
            accomm.map(obj => { obj.isaccom = false; })
        }
        //after condition is rendered isseleted made it as false
        accomm.map(obj => { obj.isselected = false; })
        accomm = _.orderBy(accomm, ['count'], ['desc'])
        dispatch({ type: 'accomdisplay', payload: accomm });


        let filterres = state.finalResponse.prpts;
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //price filter
        if (state.ispricefilter) {
            filterres = filterres.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }


        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        state.localResponse.filters.accom = accomm;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
     *
     * @param {*} value
     * @description:Expedia Filter
     * @author: Rambabu
     * @date:01-03-2021
     */
    const expediafilterChange = (value) => {

        dispatch({ type: 'expedia', payload: value })
        dispatch({ type: 'isexpratfilter', payload: true })

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        // star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }


        //expedia rating
        filterres = filterres.filter(htl => {
            return (htl.cont.urat >= value.min && htl.cont.urat <= value.max)
        })



        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    const expediaChange = (value) => {
        dispatch({ type: 'expedia', payload: value })
    }

    /**
    * @param {*} e
    * @description:Amenities Filter
    * @author: Rambabu
    * @date : 01-03-2021
    */
    const amenitieshandleChange = data => e => {

        dispatch({ type: 'isamenitifilter', payload: true })
        let ameniti = _.cloneDeep(state.finalResponse.filters.amenities);

        //if checked
        if (e.target.checked) {
            state.amenities.push(e.target.value);
            amenitesno = amenitesno.concat(data.sno);
        }//if unchecked
        else {
            for (var i = 0; i < state.amenities.length; i++) {
                if (state.amenities[i] === e.target.value) {
                    state.amenities.splice(i, 1);
                    i--;
                }
            }
            amenitesno = [];
            state.amenities.map((obj) => {
                let ameee = ameniti.filter(lm => lm.name == obj);
                amenitesno = amenitesno.concat(ameee[0].sno);
            })
        }


        //price filter
        let filterres = state.finalResponse.prpts
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        if (isArrayNotEmpty(state.amenities)) {
            for (let ame in state.amenities) {
                let val = state.amenities[ame];
                if (e.target.checked) {
                    for (let obj of ameniti) {
                        if (obj.name.toUpperCase() === val.toUpperCase()) {
                            obj.ischeck = true;
                        }
                    }
                } else {
                    ameniti.map(obj => {
                        if ((obj.name.toUpperCase() !== val.toUpperCase()) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.ischeck = false;
                        } else {
                            obj.ischeck = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })


                }
            }
        } else {
            ameniti.map(obj => { obj.ischeck = false; })
        }

        //after condition is rendered isseleted made it as false
        ameniti.map(obj => { obj.isselected = false; })
        ameniti = _.orderBy(ameniti, ['count'], ['desc'])
        dispatch({ type: 'amenitiesdisplay', payload: ameniti })

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.amenities = ameniti;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:Amenities Filter
    * @author: Rambabu
    * @date : 03-03-2021
    */
    const boardbasishandleChange = data => e => {

        dispatch({ type: 'isbordbasisfilter', payload: true })
        let boardbs = _.cloneDeep(state.finalResponse.filters.boardbasis);

        //if checked
        if (e.target.checked) {
            state.boardbasis.push(e.target.value);
            brdbasesno = brdbasesno.concat(data.sno);
        }//if unchecked
        else {
            for (var i = 0; i < state.boardbasis.length; i++) {
                if (state.boardbasis[i] === e.target.value) {
                    state.boardbasis.splice(i, 1);
                    i--;
                }
            }
            brdbasesno = [];
            state.boardbasis.map((obj) => {
                let brdbasee = boardbs.filter(lm => lm.name == obj);
                brdbasesno = brdbasesno.concat(brdbasee[0].sno);
            })
        }


        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        if (isArrayNotEmpty(state.boardbasis)) {
            for (let brd in state.boardbasis) {
                let val = state.boardbasis[brd];
                if (e.target.checked) {
                    for (let obj of boardbs) {
                        if (obj.name.toUpperCase() === val.toUpperCase()) {
                            obj.ischeck = true;
                        }
                    }
                } else {

                    boardbs.map(obj => {
                        if ((obj.name.toUpperCase() !== val.toUpperCase()) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.ischeck = false;
                        } else {
                            obj.ischeck = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })

                }
            }
        } else {
            boardbs.map(obj => { obj.ischeck = false; })
        }

        //after condition is rendered isseleted made it as false
        boardbs.map(obj => { obj.isselected = false; })
        boardbs = _.orderBy(boardbs, ['count'], ['desc'])
        dispatch({ type: 'boradbasisdisplay', payload: boardbs });


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }


        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }

        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })
        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.boardbasis = boardbs;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }


    /**
    * @param {*} e
    * @description:Area Filter
    * @author: Rambabu
    * @date : 04-03-2021
    */
    const areahandleChange = data => e => {


        dispatch({ type: 'isareafilter', payload: true })
        dispatch({ type: 'arename', payload: [] })
        let area = _.cloneDeep(state.finalResponse.filters.area);


        //if checked
        if (e.target.checked) {
            state.area.push(e.target.value);
            areasno = areasno.concat(data.sno);

        }//if unchecked
        else {
            for (var i = 0; i < state.area.length; i++) {
                if (state.area[i] === e.target.value) {
                    state.area.splice(i, 1);
                    i--;
                }
            }
            areasno = [];
            state.area.map((obj) => {
                let aremark = area.filter(lm => lm.name == obj);
                areasno = areasno.concat(aremark[0].sno);
            })
        }


        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = filterres.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        if (isArrayNotEmpty(state.area)) {
            for (let acc in state.area) {
                let val = state.area[acc];
                if (e.target.checked) {
                    area.map(obj => {
                        if (obj.name.toUpperCase() === val.toUpperCase()) {
                            obj.ischeck = true;
                        }
                    })
                } else {

                    area.map(obj => {
                        if ((obj.name.toUpperCase() !== val.toUpperCase()) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.ischeck = false;
                        } else {
                            obj.ischeck = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })

                }
            }
        } else {
            area.map(obj => { obj.ischeck = false; })
        }
        //after condition is rendered isseleted made it as false
        area.map(obj => { obj.isselected = false; })
        area = _.orderBy(area, ['count'], ['desc']);
        dispatch({ type: 'arealist', payload: area })


        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        state.localResponse.filters.area = area;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:Landmark check box Filter
    * @author: Rambabu
    * @date : 07-03-2021
    */
    const landmarkhandleChange = data => e => {

        dispatch({ type: 'islandmarkfilter', payload: true })
        dispatch({ type: 'landmarkname', payload: [] })
        let landmrk = _.cloneDeep(state.finalResponse.filters.landmark);

        //if checked
        if (e.target.checked) {
            state.landmark.push(e.target.value);
            landmarksno = landmarksno.concat(data.sno);

        }//if unchecked
        else {
            for (var i = 0; i < state.landmark.length; i++) {
                if (state.landmark[i] === e.target.value) {
                    state.landmark.splice(i, 1);
                    i--;
                }
            }
            landmarksno = [];
            state.landmark.map((obj) => {
                let lmark = landmrk.filter(lm => lm.name == obj);
                landmarksno = landmarksno.concat(lmark[0].sno);
            })
        }



        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = filterres.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }


        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        if (isArrayNotEmpty(state.landmark)) {
            for (let lnd in state.landmark) {
                let val = state.landmark[lnd];
                if (e.target.checked) {
                    landmrk.map(obj => {
                        if (obj.name.toUpperCase() == val.toUpperCase()) {
                            obj.ischeck = true;
                        }
                    })
                } else {
                    landmrk.map(obj => {
                        if ((obj.name.toUpperCase() !== val.toUpperCase()) && (!isNotNull(obj.isselected) || !obj.isselected)) {
                            obj.ischeck = false;
                        } else {
                            obj.ischeck = true;
                            obj.isselected = true;//This is dummy value for skip the existing selected value
                        }
                    })
                }
            }
        } else {
            landmrk.map(obj => { obj.ischeck = false; })
        }
        //after condition is rendered isseleted made it as false
        landmrk.map(obj => { obj.isselected = false; })
        landmrk = _.orderBy(landmrk, ['count'], ['desc']);
        dispatch({ type: 'landmarklist', payload: landmrk })


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.landmark = landmrk;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} data
    * @description:Search by hotel name selected event
    * @author: Rambabu
    * @date : 04-03-2021
    */
    const areanameOnchange = (data) => {

        dispatch({ type: 'searchareaname', payload: data })
        dispatch({ type: 'area', payload: [] })
        dispatch({ type: 'arename', payload: data })
        areanamesno = [];

        //if (!isArrayNotEmpty(data)) {

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        // star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }


        //area name search filter
        if (isArrayNotEmpty(data)) {
            areanamesno = data[0].sno;
            filterres = filterres.filter(htl => {
                return (htl.sno && data[0].sno.includes(htl.sno))
            })
        }

        let area = _.cloneDeep(state.finalResponse.filters.area);
        area.map(obj => {
            if (obj.name!==undefined && data.length > 0 && data[0].name!==undefined && obj.name.toUpperCase() === data[0].name.toUpperCase()) {
                obj.ischeck = true;
            } else {
                obj.ischeck = false;
            }
        })
        area = _.orderBy(area, ['count'], ['desc']);
        dispatch({ type: 'arealist', payload: area })


        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })



        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');




    }


    /**
    * @param {*} data
    * @description:Search by hotel name selected event
    * @author: Rambabu
    * @date : 07-03-2021
    */
    const landmarknameOnchange = (data) => {

        dispatch({ type: 'landmarkname', payload: data })
        dispatch({ type: 'landmark', payload: [] });
        lmarknamesno = [];


        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        // star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }


        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(data)) {
            lmarknamesno = data[0].sno;
            filterres = filterres.filter(htl => {
                return (htl.sno && data[0].sno.includes(htl.sno))
            })
        }

        let landmrk = _.cloneDeep(state.finalResponse.filters.landmark);
        landmrk.map(obj => {
            if (obj.name.toUpperCase() === data[0].name.toUpperCase()) {
                obj.ischeck = true;
            } else {
                obj.ischeck = false;
            }
        })
        landmrk = _.orderBy(landmrk, ['count'], ['desc']);
        dispatch({ type: 'landmarklist', payload: landmrk })

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');




    }


    /**
      *
      * @param {*} value
      * @description:Price Filter clear
      * @author: Rambabu
      * @date:02-03-2021
      */
    const priceFilterClear = () => {


        dispatch({ type: 'price', payload: { min: state.finalResponse.filters.price.minprc, max: state.finalResponse.filters.price.maxprc } })
        let value = { min: state.finalResponse.filters.price.minprc, max: state.finalResponse.filters.price.maxprc }
        dispatch({ type: 'ispricefilter', payload: false });

        //price filter
        let filterres = state.finalResponse.prpts.filter(htl => {
            return (Math.round(htl.rtf) >= value.min && Math.round(htl.rtf) <= value.max)
        })


        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })

        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }


        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:Star Rating Filter clear
    * @author: Rambabu
    * @date : 02-03-2021
    */
    const startratingfilterclear = () => {


        dispatch({ type: 'rating', payload: [] })
        //star rating we are put empty for clear purpose

        let strrating = _.cloneDeep(state.finalResponse.filters.strrat);

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }


        //star rating
        strrating.map(obj => { obj.ischeck = false; })
        strrating = _.orderBy(strrating, ['count'], ['desc'])
        dispatch({ type: 'strratdisplay', payload: strrating });




        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.strrat = strrating;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }


    /**
    * @param {*} e
    * @description:Accomadation Filter clear
    * @author: Rambabu
    * @date : 02-03-2021
    */
    const accomadationfilterclear = () => {
        dispatch({ type: 'accomadation', payload: [] })

        let accomm = _.cloneDeep(state.finalResponse.filters.accom);

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        accmsno = [];
        accomm.map(obj => { obj.isaccom = false; })
        accomm = _.orderBy(accomm, ['count'], ['desc'])
        dispatch({ type: 'accomdisplay', payload: accomm });

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }


        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })



        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        state.localResponse.filters.accom = accomm;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
     *
     * @param {*} value
     * @description:Expedia Filter
     * @author: Rambabu
     * @date:02-03-2021
     */
    const expediafilterclear = () => {

        dispatch({ type: 'expedia', payload: { min: state.finalResponse.filters.exprat.minprc, max: state.finalResponse.filters.exprat.maxprc } })
        let value = { min: state.finalResponse.filters.exprat.minprc, max: state.finalResponse.filters.exprat.maxprc }
        state.isexpratfilter = false;
        dispatch({ type: 'isexpratfilter', payload: false });

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }


        // star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= value.min && htl.cont.urat <= value.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }


        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }

        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })
        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:Amenities Filter clear
    * @author: Rambabu
    * @date : 02-03-2021
    */
    const amenitiesfilterclear = () => {

        dispatch({ type: 'amenities', payload: [] })
        //amenities we are put empty for clear purpose

        let ameni = _.cloneDeep(state.finalResponse.filters.amenities);

        //price filter
        let filterres = state.finalResponse.prpts.filter(htl => {
            return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
        })


        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        filterres = filterres.filter(htl => {
            return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
        })

        //amenities
        amenitesno = [];
        ameni.map(obj => { obj.ischeck = false; });
        ameni = _.orderBy(ameni, ['count'], ['desc'])
        dispatch({ type: 'amenitiesdisplay', payload: ameni })

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.amenities = ameni;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:board basis Filter clear
    * @author: Rambabu
    * @date : 03-03-2021
    */
    const boardbasisfilterclear = () => {

        dispatch({ type: 'boardbasis', payload: [] })
        //boardbasis we are put empty for clear purpose

        let brdbasis = _.cloneDeep(state.finalResponse.filters.boardbasis);

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }


        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }



        //boardbasis
        brdbasesno = [];
        brdbasis.map(obj => { obj.ischeck = false; })
        brdbasis = _.orderBy(brdbasis, ['count'], ['desc'])
        dispatch({ type: 'boradbasisdisplay', payload: brdbasis });

        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }


        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.boardbasis = brdbasis;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:area Filter clear
    * @author: Rambabu
    * @date : 04-03-2021
    */
    const areafilterclear = () => {

        dispatch({ type: 'area', payload: [] })
        let are = _.cloneDeep(state.finalResponse.filters.area);

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }


        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        areasno = [];
        are.map(obj => { obj.ischeck = false; })
        are = _.orderBy(are, ['count'], ['desc']);
        dispatch({ type: 'arealist', payload: are })
        dispatch({ type: 'arename', payload: [] })

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }

        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.area = are;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} e
    * @description:landmark Filter clear
    * @author: Rambabu
    * @date : 07-03-2021
    */
    const landmarkfilterclear = () => {

        dispatch({ type: 'landmark', payload: [] })
        let land = _.cloneDeep(state.finalResponse.filters.landmark);

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        //star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }

        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }

        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        landmarksno = [];
        lmarknamesno = [];
        land.map(obj => { obj.ischeck = false; })
        land = _.orderBy(land, ['count'], ['desc']);
        dispatch({ type: 'landmarklist', payload: land })
        dispatch({ type: 'landmarkname', payload: [] })


        //Hotel search filter
        if (isArrayNotEmpty(state.searchhtlname)) {
            let phid = state.searchhtlname[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }
        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })


        state.localResponse.filters.hotelname = htlList;
        state.localResponse.filters.landmark = land;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }

    /**
    * @param {*} data
    * @description:Search by hotel name selected event
    * @author: Rambabu
    * @date : 03-03-2021
    */
    const hotelnameOnchange = (data) => {

        dispatch({ type: 'searchhtlname', payload: data })
        dispatch({ type: 'htlname', payload: data })

        //price filter
        let filterres = state.finalResponse.prpts;
        if (state.ispricefilter) {
            filterres = state.finalResponse.prpts.filter(htl => {
                return (Math.round(htl.rtf) >= state.price.min && Math.round(htl.rtf) <= state.price.max)
            })
        }

        // star rating
        if (isArrayNotEmpty(state.rating)) {
            filterres = filterres.filter(htl => {
                return (htl.cont && state.rating.includes(Math.ceil(htl.cont.ratings).toString()))
            })
        }


        //accomadation
        if (isArrayNotEmpty(state.accomadation)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && accmsno.includes(htl.sno))
            })
        }

        //expedia rating
        if (state.isexpratfilter) {
            filterres = filterres.filter(htl => {
                return (htl.cont.urat >= state.expedia.min && htl.cont.urat <= state.expedia.max)
            })
        }



        //amenities
        if (isArrayNotEmpty(state.amenities)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && amenitesno.includes(htl.sno))
            })
        }

        //boardbasis
        if (isArrayNotEmpty(state.boardbasis)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && brdbasesno.includes(htl.sno))
            })
        }


        //area
        if (isArrayNotEmpty(state.area)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areasno.includes(htl.sno))
            })
        }

        //area name search filter
        if (isArrayNotEmpty(state.arename)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && areanamesno.includes(htl.sno))
            })
        }

        //landmark
        if (isArrayNotEmpty(state.landmark)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && landmarksno.includes(htl.sno))
            })
        }


        //landmark name search filter
        if (isArrayNotEmpty(state.landmarkname)) {
            filterres = filterres.filter(htl => {
                return (htl.sno && lmarknamesno.includes(htl.sno))
            })
        }

        //Hotel search filter
        if (isArrayNotEmpty(data)) {
            let phid = data[0].pid;
            filterres = filterres.filter(htl => htl.pid === phid);
        }

        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })

        state.localResponse.filters.hotelname = htlList;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter');

    }




    const allFiltersClear = () => {

        let filterres = state.finalResponse.prpts;

        //price filter clear
        dispatch({ type: 'price', payload: { min: state.finalResponse.filters.price.minprc, max: state.finalResponse.filters.price.maxprc } })
        let value = { min: state.finalResponse.filters.price.minprc, max: state.finalResponse.filters.price.maxprc }
        dispatch({ type: 'ispricefilter', payload: false });

        //star rating clear
        let strrating =isNotNull(state.finalResponse.filters.strrat)?_.cloneDeep(state.finalResponse.filters.strrat):[];
        strrating.map(obj => { obj.ischeck = false; })
        dispatch({ type: 'rating', payload: [] })
        strrating = _.orderBy(strrating, ['count'], ['desc'])
        dispatch({ type: 'strratdisplay', payload: strrating });


        //accomadation clear
        let accomm =isNotNull(state.finalResponse.filters.accom)?_.cloneDeep(state.finalResponse.filters.accom):[];
        accmsno = [];
        accomm.map(obj => { obj.isaccom = false; })
        dispatch({ type: 'accomadation', payload: [] })
        accomm = _.orderBy(accomm, ['count'], ['desc'])
        dispatch({ type: 'accomdisplay', payload: accomm });


        //boardbasis clear
        let brdbasis =isNotNull(state.finalResponse.filters.boardbasis)?_.cloneDeep(state.finalResponse.filters.boardbasis):[];
        brdbasesno = [];
        brdbasis.map(obj => { obj.ischeck = false; })
        brdbasis = _.orderBy(brdbasis, ['count'], ['desc'])
        dispatch({ type: 'boradbasisdisplay', payload: brdbasis });
        dispatch({ type: 'boardbasis', payload: [] })




        //expedia rating clear
        dispatch({ type: 'isexpratfilter', payload: false });
        dispatch({ type: 'expedia', payload: { min: state.finalResponse.filters.exprat.minprc, max: state.finalResponse.filters.exprat.maxprc } })


        //area filter clear
        let are =isNotNull(state.finalResponse.filters.area)?_.cloneDeep(state.finalResponse.filters.area):[];
        areasno = [];
        are.map(obj => { obj.ischeck = false; })
        are = _.orderBy(are, ['count'], ['desc']);
        dispatch({ type: 'arealist', payload: are })
        dispatch({ type: 'arename', payload: [] })
        dispatch({ type: 'area', payload: [] });


        //landmark filter clear
        let land =isNotNull(state.finalResponse.filters.landmark)?_.cloneDeep(state.finalResponse.filters.landmark):[];
        landmarksno = [];
        lmarknamesno = [];
        land.map(obj => { obj.ischeck = false; })
        land = _.orderBy(land, ['count'], ['desc']);
        dispatch({ type: 'landmarklist', payload: land })
        dispatch({ type: 'landmark', payload: [] })
        dispatch({ type: 'landmarkname', payload: [] })



        //amenities clear
        let ameni =isNotNull(state.finalResponse.filters.amenities)?_.cloneDeep(state.finalResponse.filters.amenities):[];
        amenitesno = [];
        ameni.map(obj => { obj.ischeck = false; });
        ameni = _.orderBy(ameni, ['count'], ['desc'])
        dispatch({ type: 'amenitiesdisplay', payload: ameni })
        dispatch({ type: 'amenities', payload: [] })


        //Hotel search filter
        dispatch({ type: 'searchhtlname', payload: [] })
        dispatch({ type: 'htlname', payload: [] })


        //contruct hotelname filters
        let htlList = [];
        filterres.map(htl => {
            let obj = { pid: htl.cont.pid, hname: htl.cont.name }
            htlList.push(obj);
        })
        dispatch({ type: 'hotelnameslist', payload: htlList })
        state.localResponse.filters.hotelname = htlList;

        //Initial sorting for Result filter (only when we click on all filter clear)
        //let category_arr = ["HOTELHD", "HOTELNHD", "HOTDEAL", "NOHOTDEAL"];//Order of Results
        //filterres.sort((a, b) => (category_arr.indexOf(a.hdtype) - category_arr.indexOf(b.hdtype)));

        let list1 = filterres.filter(obj => obj.hdtype == 'HOTELHD');
        let list2 = filterres.filter(obj => obj.hdtype == 'HOTELNHD');
        let list3 = filterres.filter(obj => obj.hdtype == 'HOTDEAL');
        let list4 = filterres.filter(obj => obj.hdtype == 'NOHOTDEAL');
        list1.sort(function (htl1, htl2) {
            return (isNotNull(htl1.disc) && isNotNull(htl2.disc) && (Number(htl2.disc) - Number(htl1.disc)));
        });
        list2.sort(function (htl1, htl2) {
            return (isNotNull(htl1.rtf) && isNotNull(htl2.rtf) && (Number(htl2.rtf) - Number(htl1.rtf)));
        });
        list3.sort(function (htl1, htl2) {
            return (isNotNull(htl1.disc) && isNotNull(htl2.disc) && (Number(htl2.disc) - Number(htl1.disc)));
        });
        list4.sort(function (htl1, htl2) {
            return (isNotNull(htl1.rtf) && isNotNull(htl2.rtf) && (Number(htl2.rtf) - Number(htl1.rtf)));
        });

        list1 = _.concat(list1, list2);
        list1 = _.concat(list1, list3);
        list1 = _.concat(list1, list4);
        filterres = list1;


        state.localResponse.filters.boardbasis = brdbasis;
        state.localResponse.filters.strrat = strrating;
        state.localResponse.filters.accom = accomm
        state.localResponse.filters.area = are
        state.localResponse.filters.landmark = land
        state.localResponse.filters.amenities = ameni;
        state.localResponse.prpts = filterres;
        props.filterresponse(state.localResponse, '', 'Filter','allclear');


    }















    function getpricefiltervalues(htlpriceObj, type) {
        //find the max ,min total price value
        let maxpr = _.max(htlpriceObj);
        let minpr = _.min(htlpriceObj);

        let chepest = isNotNull(minpr) ? minpr : 0;
        let minPrice = 0;
        if (type.toUpperCase() === 'PRICE') {
            minPrice = isNotNull(minpr) ? minpr : 0;
        } else {
            minPrice = isNotNull(minpr) ? minpr : 0;
        }
        let maxPrice = isNotNull(maxpr) ? maxpr : 0;

        //construting filter
        let pricefilter = {
            minprc: minPrice,
            maxprc: maxPrice,
        }
        return pricefilter;

    }




    return (

        <>
       <Sticky>
                <div className="filter hotelFilter sticky-bottom">
                    <div className="title d-flex justify-content-between">
                        <h5>Result Filter</h5>
                        <Link class="ezyIcon icon-reset" onClick={allFiltersClear}></Link>
                    </div>

                    {/* Hotel Filter */}
                    <div className="filterSec accommodation">
                        <Form.Group controlId="htlid" className="searchbyhotel mb-0">
                            <div className="filterLabel d-flex justify-content-between">
                                <p>Hotel Name</p>
                            </div>

                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="htllistid"
                                    labelKey={htl => `${htl.hname}`}
                                    options={state.hotelnameslist}
                                    onChange={htl => (hotelnameOnchange(htl))}
                                    placeholder="Search by Hotel"
                                    selected={state.htlname}
                                    readOnly
                                    minLength={3}
                                    clearable
                                    renderMenuItemChildren={(htl) => (<><strong> {htl.hname} </strong></>)} />
                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>
                        </Form.Group>
                    </div>
                    {/* Price filter */}
                    <div className="filterSec price">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Price</p>
                            <Link onClick={() => priceFilterClear()}>Clear</Link>
                        </div>
                        <div className="ranges price">

                            {/* Price filter */}
                            <InputRange
                                draggableTrack
                                formatLabel={value => `${props.hotelresponse.cur} ${value}`}
                                allowSameValues={true}
                                maxValue={state.pricedisplay && state.pricedisplay.max}
                                minValue={state.pricedisplay && state.pricedisplay.min}
                                value={state.price}
                                onChangeComplete={(value) => pricehandleChange(value)}
                                onChange={value => priceChange(value)} />
                        </div>
                    </div>
                    {/* Expedia Rating */}
                    <div className="filterSec expediaRating">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>User Reviews</p>
                            <Link onClick={() => expediafilterclear()}>Clear</Link>
                        </div>
                        <div className="ranges">

                            <InputRange
                                allowSameValues={true}
                                maxValue={state.expediadisplay && state.expediadisplay.max}
                                minValue={state.expexpediadisplayedia && state.expediadisplay.min}
                                value={state.expedia}
                                onChangeComplete={(value) => expediafilterChange(value)}
                                onChange={value => expediaChange(value)} />

                        </div>
                    </div>
                    {/* Star rating */}
                    <div className="filterSec starRating">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Star Rating</p>
                            <Link onClick={() => startratingfilterclear()}>Clear</Link>
                        </div>
                        <ul className="rating">
                            {isNotNull(state.strratdisplay) && (isArrayNotEmpty(state.strratdisplay)) && state.strratdisplay.map((rating, index) => (
                                <React.Fragment key={index}>
                                    {rating.rat !== 0 &&
                                        <li>
                                            <Form.Check
                                                type="checkbox"
                                                id={index + 1}
                                                label={<span className="star ezyIcon icon-star"> {rating.rat}
                                                </span>}
                                                value={rating.rat}
                                                checked={rating.ischeck}
                                                onChange={startratinghandleChange(rating.rat)} />
                                        </li>
                                    }
                                </React.Fragment>
                            ))}
                        </ul>

                    </div>
                    {/* Board Basis Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Board Basis</p>
                            <Link onClick={() => boardbasisfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 80 }}>
                            <ul className="m-0 p-0">
                                {isArrayNotEmpty(state.boradbasisdisplay) && state.boradbasisdisplay.map((ame, index) => (
                                    <React.Fragment key={index}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ame.name}
                                                label={<span title={ame.name}>{ame.name}</span>}
                                                value={ame.name}
                                                checked={ame.ischeck}
                                                onChange={boardbasishandleChange(ame)}
                                                custom
                                            />
                                            <span className="count" > ({ame.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFull ? state.boradbasisdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {isArrayNotEmpty(state.boradbasisdisplay) && state.boradbasisdisplay.length >= 5 ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowfull(!showFull)}>
                                {showFull ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>






                    {/* Accomadation Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Type of Accommodation</p>
                            <Link onClick={() => accomadationfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="m-0 p-0">
                                {isNotNull(state.accomdisplay) && (isArrayNotEmpty(state.accomdisplay)) && state.accomdisplay.map((acc, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={acc.type}
                                                label={<span title={acc.type}>{acc.type}</span>}
                                                value={acc.type}
                                                checked={acc.isaccom}
                                                onChange={accomadationhandleChange(acc)}
                                                custom />
                                            <span className="count" >  ({acc.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFullamt ? state.accomdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {isNotNull(state.accomdisplay) && (isArrayNotEmpty(state.accomdisplay)) && state.accomdisplay.length >= 5 ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowFullamt(!showFullamt)}>
                                {showFullamt ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>




                    {/* Area Filter */}
                    {/*  {isArrayNotEmpty(state.arealist) &&( */}
                   { state.showAreaList && <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Area</p>
                            <Link onClick={() => areafilterclear()}>Clear</Link>
                        </div>

                        {state.arealist && (
                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="arealistid"
                                    labelKey={area => `${area.name}`}
                                    options={state.arealist}
                                    onChange={area => (areanameOnchange(area))}
                                    placeholder="Search by Area"
                                    selected={state.arename}
                                    readOnly
                                    minLength={3}
                                    renderMenuItemChildren={(area) => (<><strong> {area.name} </strong></>)} />

                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>)}
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="mb-0 p-0 mt-2">
                                {isNotNull(state.arealist) && isArrayNotEmpty(state.arealist) && state.arealist.map((ar, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ar.name + `${1}`}
                                                label={<span title={ar.name}>{ar.name}</span>}
                                                value={ar.name}
                                                checked={ar.ischeck}
                                                onChange={areahandleChange(ar)}
                                                custom />
                                            <span className="count" > ({ar.count})</span>

                                        </li>
                                    </React.Fragment>
                                )).slice(0, showArea ? state.arealist.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.arealist) && isArrayNotEmpty(state.arealist) && state.arealist.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowArea(!showArea)}>
                                {showArea ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>}
                    {/*  )} */}


                    {/* Land mark Filter */}
                    {/*  {isArrayNotEmpty(state.landmarklist) && ( */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Landmarks</p>
                            <Link onClick={() => landmarkfilterclear()}>Clear</Link>
                        </div>
                        {state.landmarklist && (
                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="arealistid"
                                    labelKey={landmark => `${landmark.name}`}
                                    options={state.landmarklist}
                                    onChange={landmark => (landmarknameOnchange(landmark))}
                                    placeholder="Search by Landmark"
                                    selected={state.landmarkname}
                                    readOnly
                                    minLength={3}
                                    renderMenuItemChildren={(landmark) => (<><strong> {landmark.name} </strong></>)} />

                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>)}
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="mb-0 p-0 mt-2">
                                {isNotNull(state.landmarklist) && isArrayNotEmpty(state.landmarklist) && state.landmarklist.map((ln, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ln.name + `${1}`}
                                                label={<span title={ln.name}>{ln.name}</span>}
                                                value={ln.name}
                                                checked={ln.ischeck}
                                                onChange={landmarkhandleChange(ln)}
                                                custom />
                                            <span className="count" > ({ln.count})</span>

                                        </li>
                                    </React.Fragment>
                                )).slice(0, showLandmarks ? state.landmarklist.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.landmarklist) && isArrayNotEmpty(state.landmarklist) && state.landmarklist.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowLandmarks(!showLandmarks)}>
                                {showLandmarks ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>
                    {/* )} */}



                    {/* Amenties Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Amenities</p>
                            <Link onClick={() => amenitiesfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="m-0 p-0">
                                {isNotNull(state.amenitiesdisplay) && isArrayNotEmpty(state.amenitiesdisplay) && state.amenitiesdisplay.map((ame, index) => (
                                    <React.Fragment key={index}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ame.name + `${1}`}
                                                label={<span title={ame.name}>{ame.name}</span>}
                                                value={ame.name}
                                                checked={ame.ischeck}
                                                onChange={amenitieshandleChange(ame)}
                                                custom
                                            />
                                            <span className="count" >  ({ame.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFullBds ? state.amenitiesdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.amenitiesdisplay) && isArrayNotEmpty(state.amenitiesdisplay) && state.amenitiesdisplay.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowFullBds(!showFullBds)}>
                                {showFullBds ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>

                 </div>

                <div className='showInmobile'>
                <div className="filter">
                <ShowHide icon="" title="Filter">
                <div className="filter hotelFilter sticky-bottom">
                    <div className="title d-flex justify-content-between">
                        <h5>Result Filter</h5>
                        <Link class="ezyIcon icon-reset" onClick={allFiltersClear}></Link>
                    </div>

                    {/* Hotel Filter */}
                    <div className="filterSec accommodation">
                        <Form.Group controlId="htlid" className="searchbyhotel mb-0">
                            <div className="filterLabel d-flex justify-content-between">
                                <p>Hotel Name</p>
                            </div>

                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="htllistid"
                                    labelKey={htl => `${htl.hname}`}
                                    options={state.hotelnameslist}
                                    onChange={htl => (hotelnameOnchange(htl))}
                                    placeholder="Search by Hotel"
                                    selected={state.htlname}
                                    readOnly
                                    minLength={3}
                                    clearable
                                    renderMenuItemChildren={(htl) => (<><strong> {htl.hname} </strong></>)} />
                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>
                        </Form.Group>
                    </div>
                    {/* Price filter */}
                    <div className="filterSec price">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Price</p>
                            <Link onClick={() => priceFilterClear()}>Clear</Link>
                        </div>
                        <div className="ranges price">

                            {/* Price filter */}
                            <InputRange
                                draggableTrack
                                formatLabel={value => `${props.hotelresponse.cur} ${value}`}
                                allowSameValues={true}
                                maxValue={state.pricedisplay && state.pricedisplay.max}
                                minValue={state.pricedisplay && state.pricedisplay.min}
                                value={state.price}
                                onChangeComplete={(value) => pricehandleChange(value)}
                                onChange={value => priceChange(value)} />
                        </div>
                    </div>
                    {/* Expedia Rating */}
                    <div className="filterSec expediaRating">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>User Reviews</p>
                            <Link onClick={() => expediafilterclear()}>Clear</Link>
                        </div>
                        <div className="ranges">

                            <InputRange
                                allowSameValues={true}
                                maxValue={state.expediadisplay && state.expediadisplay.max}
                                minValue={state.expexpediadisplayedia && state.expediadisplay.min}
                                value={state.expedia}
                                onChangeComplete={(value) => expediafilterChange(value)}
                                onChange={value => expediaChange(value)} />

                        </div>
                    </div>
                    {/* Star rating */}
                    <div className="filterSec">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Star Rating</p>
                            <Link onClick={() => startratingfilterclear()}>Clear</Link>
                        </div>
                        <ul className="rating">
                            {isNotNull(state.strratdisplay) && (isArrayNotEmpty(state.strratdisplay)) && state.strratdisplay.map((rating, index) => (
                                <React.Fragment key={index}>
                                    {rating.rat !== 0 &&
                                        <li>
                                            <Form.Check
                                                type="checkbox"
                                                id={index + 1}
                                                label={<span className="star ezyIcon icon-star"> {rating.rat}
                                                </span>}
                                                value={rating.rat}
                                                checked={rating.ischeck}
                                                onChange={startratinghandleChange(rating.rat)} />
                                        </li>
                                    }
                                </React.Fragment>
                            ))}
                        </ul>

                    </div>
                    {/* Board Basis Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Board Basis</p>
                            <Link onClick={() => boardbasisfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="m-0 p-0">
                                {isArrayNotEmpty(state.boradbasisdisplay) && state.boradbasisdisplay.map((ame, index) => (
                                    <React.Fragment key={index}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ame.name}
                                                label={<span title={ame.name}>{ame.name}</span>}
                                                value={ame.name}
                                                checked={ame.ischeck}
                                                onChange={boardbasishandleChange(ame)}
                                                custom
                                            />
                                            <span className="count" > ({ame.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFull ? state.boradbasisdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {isArrayNotEmpty(state.boradbasisdisplay) && state.boradbasisdisplay.length >= 5 ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowfull(!showFull)}>
                                {showFull ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>






                    {/* Accomadation Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Type of Accommodation</p>
                            <Link onClick={() => accomadationfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="m-0 p-0">
                                {isNotNull(state.accomdisplay) && (isArrayNotEmpty(state.accomdisplay)) && state.accomdisplay.map((acc, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={acc.type}
                                                label={<span title={acc.type}>{acc.type}</span>}
                                                value={acc.type}
                                                checked={acc.isaccom}
                                                onChange={accomadationhandleChange(acc)}
                                                custom />
                                            <span className="count" >  ({acc.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFullamt ? state.accomdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {isNotNull(state.accomdisplay) && (isArrayNotEmpty(state.accomdisplay)) && state.accomdisplay.length >= 5 ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowFullamt(!showFullamt)}>
                                {showFullamt ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>




                    {/* Area Filter */}
                    {/*  {isArrayNotEmpty(state.arealist) &&( */}
                   { state.showAreaList && <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Area</p>
                            <Link onClick={() => areafilterclear()}>Clear</Link>
                        </div>

                        {state.arealist && (
                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="arealistid"
                                    labelKey={area => `${area.name}`}
                                    options={state.arealist}
                                    onChange={area => (areanameOnchange(area))}
                                    placeholder="Search by Area"
                                    selected={state.arename}
                                    readOnly
                                    minLength={3}
                                    renderMenuItemChildren={(area) => (<><strong> {area.name} </strong></>)} />

                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>)}
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="mb-0 p-0 mt-2">
                                {isNotNull(state.arealist) && isArrayNotEmpty(state.arealist) && state.arealist.map((ar, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ar.name + `${1}`}
                                                label={<span title={ar.name}>{ar.name}</span>}
                                                value={ar.name}
                                                checked={ar.ischeck}
                                                onChange={areahandleChange(ar)}
                                                custom />
                                            <span className="count" > ({ar.count})</span>

                                        </li>
                                    </React.Fragment>
                                )).slice(0, showArea ? state.arealist.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.arealist) && isArrayNotEmpty(state.arealist) && state.arealist.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowArea(!showArea)}>
                                {showArea ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>}
                    {/*  )} */}


                    {/* Land mark Filter */}
                    {/*  {isArrayNotEmpty(state.landmarklist) && ( */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Landmarks</p>
                            <Link onClick={() => landmarkfilterclear()}>Clear</Link>
                        </div>
                        {state.landmarklist && (
                            <div className="search filterSearch d-flex align-items-center">
                                <Typeahead
                                    id="arealistid"
                                    labelKey={landmark => `${landmark.name}`}
                                    options={state.landmarklist}
                                    onChange={landmark => (landmarknameOnchange(landmark))}
                                    placeholder="Search by Landmark"
                                    selected={state.landmarkname}
                                    readOnly
                                    minLength={3}
                                    renderMenuItemChildren={(landmark) => (<><strong> {landmark.name} </strong></>)} />

                                <button className="searchIcon ezyIcon icon-search"></button>
                            </div>)}
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="mb-0 p-0 mt-2">
                                {isNotNull(state.landmarklist) && isArrayNotEmpty(state.landmarklist) && state.landmarklist.map((ln, id) => (
                                    <React.Fragment key={id}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ln.name + `${1}`}
                                                label={<span title={ln.name}>{ln.name}</span>}
                                                value={ln.name}
                                                checked={ln.ischeck}
                                                onChange={landmarkhandleChange(ln)}
                                                custom />
                                            <span className="count" > ({ln.count})</span>

                                        </li>
                                    </React.Fragment>
                                )).slice(0, showLandmarks ? state.landmarklist.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.landmarklist) && isArrayNotEmpty(state.landmarklist) && state.landmarklist.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowLandmarks(!showLandmarks)}>
                                {showLandmarks ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>
                    {/* )} */}



                    {/* Amenties Filter */}
                    <div className="filterSec accommodation">
                        <div className="filterLabel d-flex justify-content-between">
                            <p>Amenities</p>
                            <Link onClick={() => amenitiesfilterclear()}>Clear</Link>
                        </div>
                        <Scrollbars className="filterScrl" style={{ height: 160 }}>
                            <ul className="m-0 p-0">
                                {isNotNull(state.amenitiesdisplay) && isArrayNotEmpty(state.amenitiesdisplay) && state.amenitiesdisplay.map((ame, index) => (
                                    <React.Fragment key={index}>

                                        <li>
                                            <Form.Check
                                                name="airlinesow"
                                                type="checkbox"
                                                id={ame.name + `${1}`}
                                                label={<span title={ame.name}>{ame.name}</span>}
                                                value={ame.name}
                                                checked={ame.ischeck}
                                                onChange={amenitieshandleChange(ame)}
                                                custom
                                            />
                                            <span className="count" >  ({ame.count})</span>
                                        </li>
                                    </React.Fragment>
                                )).slice(0, showFullBds ? state.amenitiesdisplay.length : '5')}
                            </ul>
                        </Scrollbars>
                        {(isNotNull(state.amenitiesdisplay) && isArrayNotEmpty(state.amenitiesdisplay) && state.amenitiesdisplay.length >= 5) ?
                            <Button className="showmorelink" variant="link" onClick={() => setShowFullBds(!showFullBds)}>
                                {showFullBds ? "- Show less" : "+ Show All"}
                            </Button> : ''}
                    </div>




                </div>
                </ShowHide>
</div>
                    </div>
                    </Sticky>
        </>
    )
}


export default Filter;
