import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";


//This function will show the Air markups List and Add Markup
function InsTransactionDetails(props) {
  const [state, setState] = useState({
    loading: false
  })

  const openSummary = (data, idx)=>{
    if(props.resp.booking_id !== undefined){
        window.open("/ezytrip/dashboard/insurance/bookingsummary/"+props.resp.booking_id);
    }
}

  return (
    <>
      <div className="resultSection boxShadow bg-white summary mb-4 newlayoutDesign">
        <Row className="pb-2">
       <Col><span>Booking Number :</span> <span className='bookNum' onClick = {openSummary}>{props.resp.booking_id}</span></Col>
       <Col>
       <span>Requested Date : </span> {props.resp.req_date}
       </Col>
       <Col>
       <span>Requested By : </span> {props.resp.req_by}
       </Col>
          </Row>
        <Row className="pb-2 ">
       <Col>
       <span>Customer Name:</span> <span className='bookNum'>{props.resp.comp_name}</span>
       </Col>
       <Col>
       <span>Lead Passenger: </span> {props.resp.lead_name}
       </Col>
       <Col>
       <span>Booking Status: </span> <span className='conFirmedi'> {props.resp.st}</span>
       </Col>
        </Row>
        </div>
    </>
  )
}
export default InsTransactionDetails