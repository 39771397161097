import log from "loglevel";
import React, { useEffect, useReducer, useState,useRef } from 'react';
import { Col, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import ModifySearch from '../../../../bookingFlows/flight/common/ModifySearch';
import Footer from '../../../../common/Footer';
import Header from '../../../../common/Header';
import Sticky from '../../../../common/sticky/Sticky';
import DateUtils from '../../../../commonUtils/DateUtils';
import Utilities from '../../../../commonUtils/Utilities';
import { fireSearchReq } from '../../review/operations';
import FBFResultsPageLoader from '../../searchLoaders/FBFResultsPageLoader';
import FilterSplit from '../filtersFBF/FilterSplit';
import OnwardPackages from './OnwardPackages';
import ReturnPackages from './ReturnPackages';
import SelectedPackage from './SelectedPackage';
import ResultsNotFound from '../../../../common/popUps/ResultsNotFound';
import {getSalamAirBaggage} from '../../review/operations';
import ApplayMarkup from '../../../hotel/common/result/ApplayMarkup';
const _ = require("lodash");
export const ONFLY_FLT_MKP = "onflyFltMkp";
export const SEL_AIR_PRICE = "selAirPrice";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
export const CLIENT_IP = "clientIp";

//Initial State
const initialState = {
  response: undefined, sortType: 'chepest', loading: false, results: false, enable: true, load: true, searchRequest: "", currency: "", setctors: [], tripType: 0,
  owSelected: {}, rtSelected: {},initialowSelected: {}, initialrtSelected: {}, owSelectedHeader: {}, rtSelectedHeader: {}, onwService: 0, retService: 0, enableService: false,onwardResp : [],searchRespCount:undefined,
  sortClear: false , owLCCPirceObj:{},markp:0,markupApplied:false,addMrkup:false,secMrkp:0,updateSlmTf:false
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {

    case 'test':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This function will show the Air markups List and Add Markup
function FBFResultsPage(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [request, setRequest] = useState(props.location);
  const [initsrequest,setinitsRequest] =useState(_.cloneDeep(props.location))
  const [isItineraryLoading , setIsItineraryLoading] = useState(false);
  const [gstObj , setGstObj] = useState(false);
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    if (request !== undefined && request.state !== undefined && request.state.req !== undefined) {
      window.sessionStorage.removeItem(SEL_AIR_PRICE);
      if (request.state.req.flowType === "DIRECT") {
        createSearchReq(request.state.req, false);
      } else {
        createOnholdSearchReq(request.state.req, false);
      }
    } else {
      return history.push({
        pathname: '/ezytrip/bookings/flight/error',
        state: {
          req: "Farerule"
        }
      });
    }
  }, [request]);

  //=====================================
  // Creating the Search request.state.req
  //=====================================
  const createOnholdSearchReq = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    var requrl = "";
    var departure = request.origns[0].srcorigns[0].seg[0].da;
    var arrival = request.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
    const deptDate = DateUtils.convertStringToDate(request.origns[0].srcorigns[0].seg[0].dd);
    var cls = setClsType(request.origns[0].srcorigns[0]);
    const arrDate = DateUtils.convertStringToDate(request.origns[0].srcorigns[0].seg.slice(-1)[0].ad);
    requrl = "dep=" + departure + "&arr=" + arrival + "&dd=" + deptDate + "&ad=" + arrDate + "&tt=" + 2 + "&cl=" + cls + "&ns=" + "" + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "split" + "&cur=" + request.cur + "&pa=" + "" + "" + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + "DOHQR2718";
    callSearchReq(requrl, false);
  }

  const setClsType = (cls) => {
    var cl = "E";
    if (cls === "Economy") {
      cl = "E"
    } else if (cls === "Business") {
      cl = "B"
    } else if (cls === "First class") {
      cl = "F"
    } else if (state.cls == "Premium") {
      cl = "P"
    }
    return cl;
  }

  //=====================================
  // Creating the Search request.state.req
  //=====================================
  const createSearchReq = (request, modify) => {
    var randomStr = Math.random().toString(36).slice(2);
    var requrl = "dep=" + request.srcCode + "&arr=" + request.destCode + "&dd=" + request.dd + "&ad=" + request.ad + "&tt=" + 2 + "&cl=" + request.cls + "&ns=" + request.ns + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "split" + "&cur=" + request.cur + "&pa=" + request.pa + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + request.office_id + "&req_cur=" + request.cur + "&region_type=" + request.region_type +"&src_cnt=" + request.src[0].countryCode + "&dest_cnt=" + request.dest[0].countryCode;
    callSearchReq(requrl, modify);
  }

  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callSearchReq = (requrl, modify) => {
    try {
      if (modify) {
        dispatch({ type: "results", payload: false })
      }
      window.sessionStorage.removeItem(SEL_SALAM_ONE)
      window.sessionStorage.removeItem(SEL_SALAM_TWO)
      fireSearchReq(requrl).then((res) => {
        localStorage.setItem("RESP", JSON.stringify(res));
        if (res !== undefined && res[0].status === "success") {
          dispatch({ type: "owSelectedHeader", payload: res[0].trip === 'onward' ? res[0].data[0].origns[0] : res[1].data[0].origns[0] })
          dispatch({ type: "rtSelectedHeader", payload: res[1].trip === 'arrival' ? res[1].data[0].origns[0] : res[0].data[0].origns[0] })
          setGstObj(res[0].data[0].gst)
          let list=[];
          if(res[0].data[0].origns[0].sup==="2"){
            const requst1={
              "srcorigns":[res[0].data[0].origns[0].srcorigns[0]],
              "paxpr":res[0].data[0].origns[0].paxpr,
              "pr":res[0].data[0].origns[0].pr,
              "sup":"2",
              "servicefee":res[0].data[0].origns[0].servicefee
            }
            const payload1={
              "tid":res[0].data[0].origns[0].srcorigns[0].tid,
              "resp":[requst1]
            }
            list.push(payload1);
            const request2={
              "srcorigns":[res[1].data[0].origns[0].srcorigns[0]],
              "paxpr":res[1].data[0].origns[0].paxpr,
              "pr":res[1].data[0].origns[0].pr,
              "sup":"2",
              "servicefee":res[1].data[0].origns[0].servicefee
            }
            const payload2={
              "tid":res[1].data[0].origns[0].srcorigns[0].tid,
              "resp":[request2]
            }
            list.push(payload2)
            window.sessionStorage.setItem(SEL_AIR_PRICE,JSON.stringify(list));
          }
          let salamOwResults=res[0].data[0].origns.filter(obj=>obj.sup==="6");
          let salamRtResults=res[1].data[0].origns.filter(obj=>obj.sup==="6");
          if(salamOwResults.length!==0 || res[0].data[0].origns[0].sup==="6"){
            if(window.sessionStorage.getItem(SEL_SALAM_ONE)===null){
              if(salamOwResults.length!==0 && salamOwResults[0].srcorigns!==undefined){
                getBagFare(salamOwResults[0].srcorigns[0],"1");
              }else{
                getBagFare(res[0].data[0].origns[0].srcorigns[0],"1");
              }
            }
           if(window.sessionStorage.getItem(SEL_SALAM_TWO)===null){
            if(salamRtResults.length!==0 && salamRtResults[0].srcorigns!==undefined){
              getBagFare(salamRtResults[0].srcorigns[0],"2");
            }else{
              getBagFare(res[1].data[0].origns[0].srcorigns[0],"2");
            }
            }
            
          }

          if(res[0]) {
          dispatch({type:"onwardResp",payload: res[0]})
          }
          dispatch({ type: "load", payload: true })
          dispatch({ type: "response", payload: res })
          dispatch({ type: "owSelected", payload: res[0].trip === 'onward' ? res[0].data[0] : res[1].data[0] })
          dispatch({ type: "rtSelected", payload: res[1].trip === 'arrival' ? res[1].data[0] : res[0].data[0] })
          dispatch({ type: "initialowSelected", payload: _.cloneDeep(res[0].trip === 'onward' ? res[0].data[0] : res[1].data[0]) })
          dispatch({ type: "initialrtSelected", payload: _.cloneDeep(res[1].trip === 'arrival' ? res[1].data[0] : res[0].data[0]) })
          dispatch({ type: "currency", payload: res[0].data[0].cur })
          let count = 0
          for(let val of res[0].data[0].origns) {
            for(let seg of val.srcorigns) {
              // for(let taf of seg.seg) {
                count++;
              // }
            }
          }
          dispatch({type:"searchRespCount",payload:count})
          dispatch({ type: "setctors", payload: res[0].data[0].sec })
          dispatch({ type: "tripType", payload: res[0].data[0].tt })
          dispatch({ type: "results", payload: true })


        setTimeout(() => {
          document.querySelector('.resultLegsOnward > div:first-child .rs-block').classList.add('selected');
          document.querySelector('.resultLegsReturn > div:first-child .rs-block').classList.add('selected');
        }, 1000);


          var userType = (request.state.req.butype === "ta_user") ? "TA" : "STA";
          if (res[0].trip === "onward") {
            let servicefee = Utilities.calculateServiceFee(res[0].data[0].origns[0].servicefee, userType, request.state.req);
            dispatch({ type: 'onwService', payload: servicefee })
          } else {
            let servicefee = Utilities.calculateServiceFee(res[0].data[0].origns[0].servicefee, userType, request.state.req);
            dispatch({ type: 'retService', payload: servicefee })
          }
          if (res[1].trip === "arrival") {
            let servicefee = Utilities.calculateServiceFee(res[1].data[0].origns[0].servicefee, userType, request.state.req);
            dispatch({ type: 'retService', payload: servicefee })
          } else {
            let servicefee = Utilities.calculateServiceFee(res[1].data[0].origns[0].servicefee, userType, request.state.req);
            dispatch({ type: 'onwService', payload: servicefee })
          }
          dispatch({ type: "enableService", payload: true })
        } else {
          return history.push({
            pathname: '/ezytrip/bookings/flight/error',
            state: {
              req: "Farerule"
            }
          });
        }
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }

  const handleChangeCallBackOw = (res, type) => {
    if( res!==undefined && res.origns !==undefined && res.origns.length!==undefined){
      let response = res.origns
      if(type === 'clear') {
        response = state.initialowSelected.origns
        window.scrollTo(0, 0);
      }
      let count = 0
      for(let val of response) {
        for(let seg of val.srcorigns) {
          // for(let taf of seg.seg) {
            count++;
          // }
        }
      }
      dispatch({type:"searchRespCount",payload:count})

    }

    if (type === 'split') {
      if(res.sup==="6"){
        let fareop=[];
        dispatch({type:"updateSlmTf",payload:false})
        if(window.sessionStorage.getItem(SEL_SALAM_ONE)===null){
          fareop= getBagFare(res.srcorigns[0],"1");
        }else{
          let resp=JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE));
          if(resp.tid===res.srcorigns[0].tid){
            fareop=resp.fareop;
          }else{
            fareop=getBagFare(res.srcorigns[0],"1");
          }
        }
        if(fareop.length!==0){
        for(let val of state.owSelected.origns){
           if(val.sup==="6"){
           for(let src of val.srcorigns){
            let exSrc=src.fareop;
             if(src.tid===res.srcorigns[0].tid){
              let finalfare=exSrc.filter(obj=>obj.selected);
                src.fareop=fareop;
                for(let route of src.fareop){
                  if(route.routeId===finalfare[0].routeId){
                    window.sessionStorage.removeItem(SEL_SALAM_ONE);
                    route.selected=true;
                    const req={
                      "tid":res.srcorigns[0].tid,
                      "fareop":src.fareop
                     }
                    window.sessionStorage.setItem(SEL_SALAM_ONE,JSON.stringify(req))
                  }else{
                    route.selected=false;
                  }
                }
             }
           }
           }
        }
      }
        dispatch({type:"updateSlmTf",payload:true})
      }
      dispatch({ type: "owSelectedHeader", payload: type !== 'clear' ? res  : state.initialowSelected})
      
     // window.scrollTo(0, 0);
    }
    else {
      dispatch({ type: "owSelectedHeader", payload: type !== 'clear' ?  res.origns[0] :  state.initialowSelected.origns[0]})
      dispatch({ type: "owSelected", payload: type !== 'clear' ? res  : state.initialowSelected })
      dispatch({ type: 'sortType', payload: type })
    }
    if(type === 'clear') {
      dispatch({type:'sortClear',payload: true})
      window.scrollTo(0, 0);
    } else {
      dispatch({type:'sortClear',payload: false})
      //window.scrollTo(0, 0);
    }
  }
  const handleChangeCallBackAr = (res, type) => {
    //window.scrollTo(0, 0);


    if (type === 'split'){
      if(res.sup==="6"){
        let fareop=[];
        dispatch({type:"updateSlmTf",payload:false})
        if(window.sessionStorage.getItem(SEL_SALAM_TWO)===null){
          fareop=getBagFare(res.srcorigns[0],"2");
        }else{
          let resp=JSON.parse(window.sessionStorage.getItem(SEL_SALAM_TWO));
          if(resp.tid===res.srcorigns[0].tid){
            fareop=resp.fareop;
          }else{
            fareop=getBagFare(res.srcorigns[0],"2");
          }
        }
        if(fareop.length!==0){
        for(let val of state.rtSelected.origns){
           if(val.sup==="6"){
           for(let src of val.srcorigns){
            let exSrc=src.fareop;
             if(src.tid===res.srcorigns[0].tid){
              let finalfare=exSrc.filter(obj=>obj.selected);
                src.fareop=fareop;
                for(let route of src.fareop){
                  if(route.routeId===finalfare[0].routeId){
                    window.sessionStorage.removeItem(SEL_SALAM_TWO);
                    route.selected=true;
                    const req={
                      "tid":res.srcorigns[0].tid,
                      "fareop":src.fareop
                     }
                    window.sessionStorage.setItem(SEL_SALAM_TWO,JSON.stringify(req))
                  }else{
                    route.selected=false;
                  }
                }
             }
           }
           }
        }
      }
      dispatch({type:"updateSlmTf",payload:true})
      }
      dispatch({ type: "rtSelectedHeader", payload: type !== 'clear' ? res  : state.initialrtSelected })
    }
    else {
      dispatch({ type: "rtSelectedHeader", payload: type !== 'clear' ? res.origns[0]  : state.initialrtSelected.origns[0] })
      dispatch({ type: "rtSelected", payload: type !== 'clear' ? res  : state.initialrtSelected })
      dispatch({ type: 'sortType', payload: type })
    }

  }
  //Get Baggage Info for Salam Air
  const getBagFare= (flt,tt)=>{
    setIsItineraryLoading(true)
    const req={
        "token":flt.token,
        "ip_address":localStorage.getItem(CLIENT_IP),
        "fareop":flt.fareop
    }
    getSalamAirBaggage(req).then(response => {
    if(response.status){
        flt.fareop=response.fareop;
        const req={
            "tid":flt.tid,
            "fareop":flt.fareop
        }
        if(tt==="1"){
            window.sessionStorage.setItem(SEL_SALAM_ONE,JSON.stringify(req))
        }else{
            window.sessionStorage.setItem(SEL_SALAM_TWO,JSON.stringify(req))
        }
        setIsItineraryLoading(false)
    }else{
        setIsItineraryLoading(false)
    }
    })

    return flt.fareop;
}
  const returnServiceFee = (fee) => {
    if (state.enableService) {
      dispatch({ type: 'retService', payload: fee })
    }
  }

  const onwardServiceFee = (fee) => {
    if (state.enableService) {
      dispatch({ type: 'onwService', payload: fee })
    }
  }

  //Updating Onward Price
  const updateOWPrice = (price) => {
    const owprice=state.owSelectedHeader;
    owprice.pr=price[0].pr;
    owprice.paxpr=price[0].paxpr;
    for(let val of state.owSelectedHeader.srcorigns[0].fareop){
      if(val.routeId===price[0].routeId){
         val.selected=true;
      }else{
        val.selected=false;
      }
    }
    dispatch({ type: 'owSelectedHeader', payload: owprice })
  }

  //Updating Return Price
  const updateRTPrice = (price) => {
    const rtprice=state.rtSelectedHeader;
    rtprice.pr=price[0].pr;
    rtprice.paxpr=price[0].paxpr;
    for(let val of state.rtSelectedHeader.srcorigns[0].fareop){
      if(val.routeId===price[0].routeId){
         val.selected=true;
      }else{
         val.selected=false;
      }
    }
    dispatch({ type: 'rtSelectedHeader', payload: rtprice })
  }

  const updateMarkup = (data) => {
    dispatch({ type: 'markp', payload: data })
    dispatch({ type: 'secMrkp', payload: data/2 })
    dispatch({type: "markupApplied",payload: true})
    window.sessionStorage.setItem(ONFLY_FLT_MKP,data);
  }

  const openMarkUp = () => {
    if (state.addMrkup) {
        dispatch({type: "addMrkup",payload: false})
    } else {
        dispatch({type: "addMrkup",payload: true})
    }
  }

  //Updating Onward Price
  const updateAirOwPrice = (price) => {
    dispatch({ type: 'owSelectedHeader', payload: price[0] })
  }

  //Updating Onward Price
  const updateAirRtprice = (price) => {
    dispatch({ type: 'rtSelectedHeader', payload: price[0] })
  }



  return (

    <>

      <Header />
      {request.state !== undefined && request.state.req !== undefined && state.load &&
        <ModifySearch searchRes={state.owSelected} flow={"Direct"} response={state.onwardResp} sreq={request.state.req} initreq={initsrequest.state.req} load={state.load} dateAvailable={true} routeAvailable={true} countSRP ={state.searchRespCount}/>
      }
      {isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
      {state.results ?
        <div className="container resultLayout mb-4">
        <div className={"mrkup " + (state.addMrkup ? 'open':'') } ref={ref} >
        <span onClick={openMarkUp}><span className="ezyIcon markup icon-arrowDown"></span></span>
        {state.addMrkup &&
            <div className={"admarkup " + (state.addMrkup ? 'active':'') }>
            <ApplayMarkup sendMarkup={updateMarkup} markVal={state.markp}/>
            </div>
            }
        </div>
          <Row>
            <Col className="filterLayout">
              <FilterSplit owSearchRes={state.owSelected} initialowSearchRes={state.initialowSelected} rtSearchRes={state.rtSelected} initialrtSearchRes={state.initialrtSelected} owHandelChange={handleChangeCallBackOw} srequest={request.state.req} arHandelChange={handleChangeCallBackAr} />
            </Col>
            <Col className="resultSection fbfFlow">
              <Sticky>
                {(state.owSelectedHeader && state.owSelectedHeader.srcorigns[0] && state.owSelected && state.rtSelected && state.rtSelectedHeader && state.rtSelectedHeader.srcorigns[0] && state.currency) && <SelectedPackage srequest={request.state.req} owPrice={state.owSelectedHeader.pr} owSelectObj={state.owSelectedHeader} rtSelectObj={state.rtSelectedHeader} owMapping={state.owSelected.mappings} rtMapping={state.rtSelected.mappings} rtPrice={state.rtSelectedHeader.pr} currency={state.currency} onward={state.owSelectedHeader.srcorigns[0]} arrival={state.rtSelectedHeader.srcorigns[0]} trav={Number(state.owSelected.adt) + Number(state.owSelected.chd) + Number(state.owSelected.inf)} onwardService={state.onwService} retrnService={state.retService} onMarkp={state.markp} regionType={state.owSelected.region_type} gst={gstObj}/>}
              </Sticky>
              {/* <RoundTripPackages searchRes={state.response[0]} /> */}
              <div className="roundTrip ">
                <Row>
                  <Col lg={6} md={12} className="owned pr-2">
                    {(state.owSelected.origns && state.owSelected.origns[0]) ? <OnwardPackages owHandelChange={handleChangeCallBackOw} arHandelChange={handleChangeCallBackAr} searchRes={state.owSelected} trip={state.tripType} sectors={state.setctors} cur={state.currency} secType={'onward'} mapping={state.owSelected.mappings} srequest={request.state.req} owsplitFlights={state.owSelectedHeader} onwardService={onwardServiceFee} sendOwPrice={updateOWPrice} sendOwAirPrice={updateAirOwPrice} onSecMkp={state.secMrkp} regionType={state.owSelected.region_type} gst={gstObj} /> :

                    <div className="filterNoResultFound">
                    <ResultsNotFound  filterChange={true} product={"Flight"}/>
                    </div>}
                  </Col>
                  <Col lg={6} md={12} className="return pl-2">
                    {(state.rtSelected.origns && state.rtSelected.origns[0]) ? <ReturnPackages owHandelChange={handleChangeCallBackOw} arHandelChange={handleChangeCallBackAr} searchRes={state.rtSelected} trip={state.tripType} sectors={state.setctors} cur={state.currency} secType={'onward'} mapping={state.rtSelected.mappings} srequest={request.state.req} rtsplitFlights={state.rtSelectedHeader} returnService={returnServiceFee} sendRtPrice={updateRTPrice} onSecMkp={state.secMrkp} sendRtAirPrice={updateAirRtprice} regionType={state.rtSelected.region_type} gst={gstObj} /> :
                    <div className="filterNoResultFound">
                    <ResultsNotFound  filterChange={true} product={"Flight"}/>
                    </div>}
                  </Col>
                </Row>

              </div>
            </Col>

          </Row>

        </div>
        : <div className="container resultLayout mb-4"><FBFResultsPageLoader /></div>
      }
      <Footer />
    </>
  )
}
export default FBFResultsPage