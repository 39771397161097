import React, { useEffect, useReducer} from 'react';
import ImageGallery from 'react-image-gallery';
//import "~react-image-gallery/styles/scss/image-gallery.scss";
// import img1 from '../images/image1.png'
// import img1l from '../images/image1l.png'
// import img2 from '../images/image2.png'
// import img2l from '../images/image2l.png'
// import img3 from '../images/image3.png'
// import img3l from '../images/image3l.png'

const initialState = {
  hotelContentResp:"",loading: false,hotelRoomResp:"",showPop:false,imageContent:[]
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
          ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ImageSlider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const images = [
    // {
    //   original: img1l,
    //   thumbnail: img1,
    // },


  ];

  useEffect(() => {
if(props.img !== undefined) {
  let imgArr = []
for(let img of props.img) {
  let obj = {
    "original" : img,
    "thumbnail": img
  }
  imgArr.push(obj)
}
  dispatch({type:"imageContent",payload:imgArr})
}
  }, [props.img])

 const renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <button
        type='button'
        className={
          `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
        onClick={onClick}
      />
    );
  }

  return (
    <>

        { state.imageContent.length > 0 &&
        <div className="gallerySlider">
          <ImageGallery items={state.imageContent} thumbnailPosition="right" showNav={false}  lazyLoad={true} />
        </div>
}

    </>
  );
}
export default ImageSlider;