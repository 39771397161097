import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../../assets/images/landingAirlineLogo/qatar-airways.svg";
import logo2 from "../../../assets/images/landingAirlineLogo/turkish-airlines.svg";
import logo3 from "../../../assets/images/landingAirlineLogo/indigo-airlines.svg";
import logo4 from "../../../assets/images/landingAirlineLogo/oman-air.svg";
import logo5 from "../../../assets/images/landingAirlineLogo/air-canada.svg";
import logo6 from "../../../assets/images/landingAirlineLogo/fly-dubai.svg";

function TopAirlines(props) {
  const [state, setState] = useState({});

  return (
    <>
      <div className="topAirlines">
        <h3><span>TOP</span> AIRLINES</h3>
        <p className="mb-4">World’s best Airlines</p>
        <Row>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo1}
                className="logo"
                title="Qatar airways"
                alt="Qatar airways"
              />
            </Link>
          </Col>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo2}
                className="logo"
                title="Turkish airlines"
                alt="Turkish airlines"
              />
            </Link>
          </Col>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo3}
                className="logo"
                title="indigo Airlines"
                alt="indigo Airlines"
              />
            </Link>
          </Col>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo4}
                className="logo"
                title="Oman Air"
                alt="Oman Air"
              />
            </Link>
          </Col>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo5}
                className="logo"
                title="Air Canada"
                alt="Air Canada"
              />
            </Link>
          </Col>
          <Col>
            <Link to="#" className="airline">
              <img
                src={logo6}
                className="logo"
                title="fly Dubai"
                alt="fly Dubai"
              />
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TopAirlines;
