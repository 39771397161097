
import {Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, {useState , useEffect } from 'react';
import AirlinesMemo from '../../../common/airlines';
import DateUtils from "../../../commonUtils/DateUtils";
import { isNotNull } from '../../../commonUtils/validators';
import departure from "../../../../assets/images/pdficons/wns.jpg";
import flight from "../../../../assets/images/pdficons/flt.jpg";
import departure2 from "../../../../assets/images/pdficons/rtn.jpg";
let airportsData = require('../../../../../client/components/masterData/airlinesData.json');
// Create styles
const styles = StyleSheet.create({
  page: { marginTop: '0', height: '100%', padding: '30px', paddingTop: '20px' },
  main: {
    width: '100%',
    height: '140mm',
    marginBottom: '15px',
    fontFamily: 'Roboto',
    backgroundColor: '#fff',

    margin: '10 0 ',


  },

  table: { margin: '0 0 10px', border: '1 solid #ccc', borderRadius: '4px', padding: '5px 10px 10px' },
  mainSec: {

    fontFamily: 'Roboto',
    width: '100%',


  },
  img: {
    width: 60,
    height: '24',
    padding: '1',
  },
  hotelImages: {
    width: 80,
    height: 80,
    borderRadius: '10px',
  },
  sta: {
    width: '10px',
    height: '10px',
  },
  mapIcon: {
    width: '8px',
    marginRight: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10',
    fontFamily: 'Roboto',
  },
  heading: {
    fontSize: '20px',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  hedingunderline: {
    textDecoration: 'underline',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '14px',
    color: '#475F7B',
    textAlign: 'center',
    marginBottom: '10px',
  },
  tableIn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    display: 'flex',
    justifyContent: 'top',
    paddingRight: '15px',
  },
  companyName: {
    fontSize: '12px'
  },
  lablList: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    flexWrap:'wrap',
  },
  lablListPlus: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    width: '400px'
  },
  tid: {
    color: '#475F7B',
  },
  confStatus: {
    color: '#1DAD81',
  },
  Secheading: {
    fontSize: '12px',
    backgroundColor: '#e7e7e7',
    padding: '4px 6px',
    borderRadius: '4px',
    margin: '0 0 15px',
  },
  hotelPart: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px,'
  },
  hotelImage: {
    marginRight: '10px',
    width: '160px'
  },
  hotelName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px'
  },
  maneHtl: {
    fontSize: '12px',
    marginRight: '10px',
  },
  hotelAdres: {
    display: 'flex',
    flexDirection: 'row',
  },
  adress: {
    color: '#888888',
    fontSize: '10px',
  },
  infolist: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  time: {
    color: '#F26F22',
  },
  roomType: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: '6px',
  },
  tableHeading: {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
    color: '#00367A',
    fontSize: '11px',
  },
  headingTaxt: {
    width: '20%',
  },
  tableData: {
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
    color: '#888',
    fontSize: '10px',
  },
  status: {
    color: '#1DAD81 ',
  },
  SpecialRequest: {
    border: '1px dashed #CACED5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
  },
  note: {
    border: '1px dashed #CED7E5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
    backgroundColor: '#F3F4F8',
  },
  spCont: {
    color: '#888',
    fontSize: '10px',
    marginTop: '4px',
    lineHeight: '1.5px',
  },
  noteTitl: {
    color: '#00367A',
    fontWeight: 'bold',
  },
  dueAtLay: {
    fontSize: '10px',
    fontWeight: 'normal',
    color: '#888',
    margin: '10px 0',
  },
  maplayout: {
    width: '1000px',
  },
  copy: {
    textAlign: 'right',
    fontSize: '8px',
    marginTop: '10px',
    fontStyle: 'italic',
    color: '#00367A',
  },
  strongText: {
    fontSize: '12px',
  },
  bookinglablList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: '1px solid #ccc',
    marginTop: '10px',
  },
  col3: {
    width: '100%',
    maxWidth: '33%',
  },
  iItineraryName:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'10px',
    backgroundColor:'#f3f3f3',
    padding:'6px',
    borderRadius:'6px'
  },
  iItineraryNameInfo:{
    display:'flex',
  },
  ref:{
    paddingLeft:'120px',
    display:'flex',
    color:'#475F7B',
  },
  refID:{
    color:'#888888',
  },
  flightLoop:{
    padding:'10px',
   // borderBottom:'1px solid #ccc',
  },
  flightSec:{
    border:'1px solid #ccc',
    borderRadius:'6px',
    overflow:'hidden',
    marginBottom:'10px',
  },
  flightLogo:{
    width:'20px',
    height:'30px'
  },
  flightSegPart:{
    display:'flex',
    flexDirection:'row',
  },
  flightSecOne:{
    display:'flex',
    flexDirection:'column',
    paddingRight:'10px',
    alignItems:'flex-start',
    width:'25%',
  },
  flightNameNo:{
    display:'flex',
    flexDirection:'column',
    fontSize:'10px',
    paddingLeft:'5px',

  },
  flightName:{
    color:'#475F7B',
    paddingBottom:'4px',
  },
  flightNo:{
    color:'#888888',
  },
  flightSecTwo:{
    fontSize:'10px',
    color:'#475F7B',
    display:'flex',
    justifyContent:'flex-start',
    width:'25%',
  },
  time:{
    fontSize:'14px',
    color:'#475F7B',
    fontWeight:'bold',
  },
  date:{
    fontSize:'10px',
  },
  city:{
    paddingTop:'4px',
  },
  terminal:{
    fontSize:'8px',
  },
  layOvr:{
    display:'flex',
    flexDirection:'column',
    fontSize:'10px',
    alignItems:'center',
    color:'#475F7B',
    padding:'0 10px',
    justifyContent:'flex-start',
    width:'25%',
  },
  flightIcon:{
    padding:'4px',
  },
  dept:{
    width:'15px',
    marginBottom:'4px',
  },
  tableRow:{
    borderBottom:'1px solid #ccc'
  },
  flightlogo:{
    height:'30px',
  },
});

function SegmentDetails(props) {
  const [onwardLayoverTime, setOnwardLayoverTime] = useState()
  const [returnLayoverTime, setReturnLayoverTime] = useState()
  var randomStr = Math.random().toString(36).slice(2);
  const path = "https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + 'GF' + '.png'";
  /**
     * @description:This function will Display the the Segment details
     * @author: Lakshmi
     * @date : 18-05-2021
     * @param {*} code
     * @param {*} val
     */
   function getAirportdata(code, val) {
    if (isNotNull(props.airport_data) && isNotNull(code)) {
        for (let a in props.airport_data) {
            let air_data = props.airport_data[a];
            if (isNotNull(air_data.airportCode)) {
                if (air_data.airportCode === code && val === 'c') {
                    return air_data.cityName;
                } else if (air_data.airportCode === code && val === 'a') {
                    return air_data.airportName;
                }
            }
        }
    }
}

useEffect(() => {
  if(props.onwardList!==undefined){
    let totTime = Math.abs(new Date(props.onwardList[0].depart_time) - new Date(props.onwardList[0].arrival_time));
    setOnwardLayoverTime(DateUtils.msToTime(totTime));
  }
  if(props.returnList!==undefined){
    let totTime = Math.abs(new Date(props.returnList[0].depart_time) - new Date(props.returnList[0].arrival_time));
    setReturnLayoverTime(DateUtils.msToTime(totTime));
  }
}, [])

//Get Airline name
const getAirlineName=(code)=>{
  let name="";
  if(airportsData!==undefined && airportsData.filter((obj) => obj.code === code)[0]){
    name = airportsData.filter((obj) => obj.code === code)[0].english;
  }
  return (name);
}


const returnSrc=()=>{
  const path = "https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + 'GF' + '.png'";
  return { uri: path, method: 'GET', body: '', headers: {'Access-Control-Allow-Origin': '*', 'crossOrigin': "anonymous"} };
}

  return (
       <View style={styles.roomType}>
          {props.type==="Onward" &&  props.onwardList && props.onwardList[0]!==undefined &&
              <View style={styles.flightsegment}  wrap={false} >
                <View style={styles.flighttable} >
                <View style={styles.flightLoop} >
                  <Text >
                  <Image style={styles.dept} source={departure} />
                  </Text>
                <View style={styles.flightSegPart}>
                <View style={styles.flightSecOne}>
                <View>
                {/* <Image src={{ uri: {path}, method: "GET", headers:{ "Access-Control-Allow-Origin": "*", crossOrigin: "anonymous" } }} cache={false} ></Image> */}
                <Image style={styles.flightlogo}
                  src={path + `?noCache=` + randomStr }
                  source={{
                    header: {
                      'Access-Control-Allow-Origin': '*',
                      'crossOrigin': "anonymous"
                    }
                  }}
                  ></Image>

                {/* <img style={styles.flightLogo} source={returnSrc}  /> */}
                </View>
                <View style={styles.flightNameNo}>
                  <Text style={styles.flightName}>{getAirlineName(props.onwardList[0].markting_airline)}</Text>
                  <Text style={styles.flightNo}>{props.onwardList[0].tkt_airline} - {props.onwardList[0].flt_no}</Text>
                </View>
                </View>
                <View style={styles.flightSecTwo}>
                  <Text style={styles.time}>{DateUtils.prettyDate(props.onwardList[0].depart_time, "HH:mm")} <Text style={styles.date}>{DateUtils.prettyDate(props.onwardList[0].depart_time, "ddd, DD MMM YYYY")} </Text></Text>
                  <Text style={styles.city}>{getAirportdata(props.onwardList[0].seg_source, 'c')} ({props.onwardList[0].seg_source}),</Text>
                  <Text style={styles.airport}>{getAirportdata(props.onwardList[0].seg_source, 'a')}</Text>
                </View>
                <View style={styles.layOvr}>
                <Text style={styles.layover}>Layover {onwardLayoverTime} </Text>
                  <Text style={styles.flightIcon}><Image style={styles.flighticonSec} src={flight} /></Text>
                  <Text style={styles.stop}>{props.onwardList.length - 1 === 0 ? 'Non ' : (props.onwardList.length - 1)} Stop</Text>
                  <Text style={styles.stop}>{props.onwardList.length > 1 ? props.onwardList.slice(1, props.onwardList.length).map(segs => segs.da) : ''}</Text>
                </View>
                <View style={styles.flightSecTwo}>
                  <Text style={styles.time}>{DateUtils.prettyDate(props.onwardList.slice(-1)[0].arrival_time, "HH:mm")} <Text style={styles.date}>{DateUtils.prettyDate(props.onwardList.slice(-1)[0].arrival_time, "ddd, MMM DD, YYYY ")}</Text></Text>
                  <Text style={styles.city}>{getAirportdata(props.onwardList.slice(-1)[0].seg_destination, 'c')} ({props.onwardList.slice(-1)[0].seg_destination}),</Text>
                  <Text style={styles.airport}>{getAirportdata(props.onwardList.slice(-1)[0].seg_destination, 'a')},</Text>
                  <Text style={styles.terminal}>Terminal {props.onwardList[0].depart_terminal}</Text>
                </View>

                </View>
                </View>

               </View>
              </View>
                }
              {props.type==="Return" && props.returnList && props.returnList[0]!==undefined &&
              <View style={styles.flightsegment}  wrap={false} >
                <View style={styles.flighttable} >
                <View style={styles.flightLoop} >
                  <Text >
                  <Image style={styles.dept} source={departure2} />
                  </Text>
                <View style={styles.flightSegPart}>

                <View style={styles.flightSecOne}>
                <View>
                {/* <Image
                  src={path + `?noCache=` + randomStr }
                  source={{
                    header: {
                      'Access-Control-Allow-Origin': '*',
                      crossOrigin: "anonymous"
                    }
                  }}
                /> */}
                 {/* <Image source={{ uri: {path}, method: "GET", headers:{ "Access-Control-Allow-Origin": "*", crossOrigin: "anonymous" } }} cache={false} ></Image>
                 */}
                {/* <Image style={styles.flightLogo}   crossOrigin="anonymous"
                  src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/'+'WY'+'.png'} />
                 */}
                </View>
                <View style={styles.flightNameNo}>
                  <Text style={styles.flightName}><AirlinesMemo code={props.returnList[0].markting_airline} /></Text>
                  <Text style={styles.flightNo}>{props.returnList[0].tkt_airline} - {props.returnList[0].flt_no}</Text>
                </View>
                </View>
                <View style={styles.flightSecTwo}>
                <Text style={styles.headingTaxt}></Text>
                  <Text style={styles.time}>{DateUtils.prettyDate(props.returnList[0].depart_time, "HH:mm")} <Text style={styles.date}>{DateUtils.prettyDate(props.returnList[0].depart_time, "ddd, DD MMM YYYY")} </Text></Text>
                  <Text style={styles.city}>{getAirportdata(props.returnList[0].seg_source, 'c')} ({props.returnList[0].seg_source}),</Text>
                  <Text style={styles.airport}>{getAirportdata(props.returnList[0].seg_source, 'a')}</Text>
                </View>
                <View style={styles.layOvr}>
                <Text style={styles.layover}>Layover {returnLayoverTime}</Text>
                  <Text style={styles.flightIcon}><Image style={styles.flighticonSec} src={flight} /></Text>
                  <Text style={styles.stop}>{props.returnList.length - 1 === 0 ? 'Non ' : (props.returnList.length - 1)} Stop</Text>
                  <Text style={styles.stop}>{props.returnList.length > 1 ? props.returnList.slice(1, props.returnList.length).map(segs => segs.da) : ''}</Text>
                </View>
                <View style={styles.flightSecTwo}>
                  <Text style={styles.time}>{DateUtils.prettyDate(props.returnList.slice(-1)[0].arrival_time, "HH:mm")} <Text style={styles.date}>{DateUtils.prettyDate(props.returnList.slice(-1)[0].arrival_time, "ddd, MMM DD, YYYY ")}</Text></Text>
                  <Text style={styles.city}>{getAirportdata(props.returnList.slice(-1)[0].seg_destination, 'c')} ({props.returnList.slice(-1)[0].seg_destination}),</Text>
                  <Text style={styles.airport}>{getAirportdata(props.returnList.slice(-1)[0].seg_destination, 'a')},</Text>
                  <Text style={styles.terminal}>Terminal {props.returnList[0].depart_terminal}</Text>
                </View>
                </View>
                </View>
               </View>
              </View>
                }
</View>
);
}


export default SegmentDetails;
