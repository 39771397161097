import React from 'react';
import { Alert} from "react-bootstrap";
import AirlinesMemo from '../../../common/airlines';
import AirportMemo from '../../../common/airlines/airportNames';
/**
 * @description:This function will call the Mini fare rules
 * @author: Pravas
 * @param {*}
 * @function TravellerFusionFare details
 * @date : 28-06-2021
 */


//This function will show the Air markups List and Add Markup
function ShowRules(props) {

    return (
        <>
            <div className="bookingdetails">
            <p><span>Airline</span><span><AirlinesMemo code={props.data.ac} /></span></p>
            <p><span>Departure Location.</span><span><AirportMemo code={props.data.dep} mapping={props.mappings} type={'Rule'} /></span></p>
            <p><span>Arrival Location</span><span> <AirportMemo code={props.data.arr} mapping={props.mappings} type={'Rule'} /></span></p>
            <p><span>Fare Basis Code.</span><span> {props.data.fbc}</span></p>
            </div>
            {props.data.rules.length!==0 &&
            <>
            <span>{props.data.rules[0].type}</span>
            <div dangerouslySetInnerHTML={{__html: props.data.rules[0].notes}} ></div>
            </>
            }
            {props.data.rules.length===0 &&
                <Alert className="notification" variant="info"> Rules Not found</Alert>
            }
        </>
    )
}
export default ShowRules