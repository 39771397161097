import React from 'react';
// import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import slide2 from "../../assets/images/bannerTop.jpg";
import slide1 from "../../assets/images/newBnr.jpg";
import { Slide } from 'react-slideshow-image';


const Slider = () => {
    return (

        <div className="slide-container demo">
        <Slide>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slide1})`}}>
              <span>Slide 1</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${slide2})`}}>
              <span>Slide 2</span>
            </div>
          </div>

        </Slide>
      </div>

    )
};

export default Slider;




