
import React, {useState} from 'react';
import { Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function Overview(props) {
  const [state, setState] = useState({})

  return (

    <>


          <Row>
              <Col xs={12}>
              {props.userreview!==undefined &&
              <p>{props.userreview.location!==undefined && props.userreview.location}
              {props.userreview.amenities!==undefined &&  props.userreview.amenities}
              {props.userreview.businessAmenities!==undefined &&  props.userreview.businessAmenities}
              {props.userreview.rooms!==undefined &&  props.userreview.rooms}
              {props.userreview.dining!==undefined &&  props.userreview.dining}
              </p>
              }
              </Col>
          </Row>

    </>
  )
}


export default Overview;
