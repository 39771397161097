import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import Button from '../../common/buttons/Button';
import DateUtils from '../../commonUtils/DateUtils';
import Utilities from '../../commonUtils/Utilities';
import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
const _ = require('lodash')
/**
 * @description:This function will creaet all the routes
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 09-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  showDates: false, aplType: [], notiMessage: "", notiMessageShow: false, notiVarient: "", selDates: [], applicablesArray: [], applicable: [], applicableError: false,setIHdate:new Date(),setHDate:new Date()
};

let applicableArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    case 'clear':
      return {
        ...state, showDates: false, aplType: [], notiMessage: "", notiMessageShow: false, notiVarient: "", selDates: [], applicablesArray: [], applicable: [], applicableError: false,setIHdate:new Date(),setHDate:new Date()
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ApplicableDates(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [applicable, setApplicable,] = useState({ applDates: [] });
  const [disableButton, setDisableButton] = useState();
  const [updateBtn, setUpdateBtn] = useState(false);


  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);
  useEffect(() => {
    if (props.updateBtn) {
      setUpdateBtn(true);
    }
  }, [props.updateBtn]);

  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      setFromDate()
      setToDate()
      setApplicable({ applDates: [] })
      setDisableButton()
      applicableArray = []
    }
  }, [props.reset])

  useEffect(() => {
    if (props.configType !== "") {
      applicableArray = []
    }
  }, [props.configType]);

  //inital htl applicable date "to" should be +1 to from date
useEffect(() => {
  let d = new Date()
  d.setDate(d.getDate() + 1)
  dispatch({type:"setIHdate",payload: d})
},[])
  useEffect(() => {
    if (props.applDatesData !== undefined) {
      let dates = [];
      let applicabe = []
      for (let val of props.applDatesData) {
        if (isNotNull(val.id) && isNotNull(val.tty)) {
          const ad = {
            "id": val.id,
            "tty": val.tty,
            "fm": DateUtils.convertStringToDate(val.fm),
            "to": val.too ? DateUtils.convertStringToDate(val.too) : DateUtils.convertStringToDate(val.to)
          }
          const app = {
            "tty": val.tty,
            "fm": DateUtils.convertStringToDate(val.fm),
            "to": val.too ? DateUtils.convertStringToDate(val.too) : DateUtils.convertStringToDate(val.to)
          }
          applicableArray.push(app);
          dates.push(ad);
        }
      }
      setApplicable({ applDates: dates });
      if (isArrayNotEmpty(dates)) {
        dispatch({ type: 'showDates', payload: true })
      } else {
        dispatch({ type: 'showDates', payload: false })
      }

    }
  }, [props.applDatesData]);

  const booking = [{ label: "Booking", value: "Booking" },
  { label: "Travel", value: "Travel" }]

  const hotel_booking = [{ label: "Booking Period", value: "Booking Period" },
  { label: "CheckIn Period", value: "CheckIn Period" }]
  const handleDates = () => {
    // console.log("handleDates" + state.aplType[0].value);
    if (state.aplType && state.aplType.length > 0 && state.aplType[0].value !== undefined) {
      const exist = applicable.applDates.some(existingItem=> existingItem.tty==state.aplType[0].value && existingItem.fm==DateUtils.convertStringToDate(fromDate) && existingItem.to==DateUtils.convertStringToDate(toDate));
      const dateExistfm = applicable.applDates.some(existingItem=> existingItem.tty==state.aplType[0].value && existingItem.fm<=DateUtils.convertStringToDate(fromDate) && existingItem.to>=DateUtils.convertStringToDate(fromDate));
      const dateExistto = applicable.applDates.some(existingItem=> existingItem.tty==state.aplType[0].value && existingItem.fm<=DateUtils.convertStringToDate(toDate) && existingItem.to>=DateUtils.convertStringToDate(toDate));
      if (validateDates(exist, dateExistfm, dateExistto, fromDate, toDate)) {
      let todaDate = toDate
      let fromdaDate = _.cloneDeep(fromDate)
      console.log("todaDate",fromDate)
      if(props.hotel !== undefined) {
        if(fromDate == toDate) {
          todaDate = state.setIHdate
        }
      }
      if(fromDate && (todaDate == null || todaDate === undefined)) {
        todaDate = fromDate
        if(props.hotel !== undefined) {
          let d = fromdaDate
          let n = new Date()
          n.setDate(d.getDate() + 1)
          todaDate = n
        }
      }
      const ad = {
        "tty": state.aplType[0].value,
        "fm": DateUtils.convertStringToDate(fromDate),
        "to": DateUtils.convertStringToDate(todaDate)
      }
        //dispatch({ type: 'applicablesArray', payload: applicableArray })
        applicableArray.push(ad);
        setApplicable({ applDates: [...applicable.applDates, ad] });
        dispatch({ type: 'showDates', payload: true })
        props.sendAppDates(ad);
        dispatch({ type: 'selDates', payload: [] })
        setFromDate()
        setToDate()
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Applicable Type' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  // checking dates already exist or not n is date empty 
  const validateDates = (exist, dateExistfm, dateExistto, fmDateExist, toDateExist) => {
    // let data = Utilities.getUniqData(applicableArray);
    // if (applicableArray.length !== data.length) {
    //   applicableArray = data;
    //   dispatch({ type: 'notiMessageShow', payload: true })
    //   dispatch({ type: 'notiMessage', payload: 'Applicable dates already exist' })
    //   dispatch({ type: 'notiVarient', payload: 'danger' })
    //   hidemessage()
    //   return false;
    // }
    if (fmDateExist==undefined || fmDateExist==""){
      dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select from date' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false;
    }
    if (toDateExist==undefined || toDateExist==""){
      dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select to date' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false;
    }
    if (exist || dateExistfm ||dateExistto ){
      dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Applicable dates already exist' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false;
    }
    return true;
  }
  const handleRemoveDates = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      props.sendApplicableId(obj.id);
      setApplicable({ applDates: applicable.applDates.filter((s, sidx) => idx !== sidx) });
      props.updateData(applicable.applDates.filter((s, sidx) => idx !== sidx));
    } else {
      let quad_respose = applicable.applDates.filter((s, sidx) => idx !== sidx)
      console.log("edit data:::::dates",JSON.stringify(quad_respose),state.showDates)
      setApplicable({ applDates: applicable.applDates.filter((s, sidx) => idx !== sidx) });
      let dateList = applicableArray;
      applicableArray = [];
      applicableArray.push(dateList.filter(item => item.tty !== undefined && item.tty.trim() !== obj.tty.trim()));
      props.updateData(quad_respose);
    }
  };
  // Adding Class and rbds
  const setDates = (data) => {
    dispatch({ type: 'aplType', payload: data })
    dispatch({ type: 'selDates', payload: data })
  }

  const changedate = (date) => {
    console.log("old date",fromDate)
    if(date) {
      let y = _.cloneDeep(date)
      setFromDate(y)
      let d = date
      d.setDate(d.getDate() + 1)
      console.log("new date",d)
      dispatch({type:'setHDate',payload: d})
    }
  }
  return (
    <>

      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <Form.Row>
             <Form.Group as={Col} lg={3} md={3} className="selectplugin" controlId="aplType">
                <Select
                  options={props.hotel !== undefined ? hotel_booking : booking}
                  searchable={false}
                  values={state.selDates}
                  onChange={(value) =>  setDates(value)}
                />
                <Form.Label>Applicable<sup>*</sup></Form.Label>
              </Form.Group>

        <Form.Group as={Col} lg={3} md={6} className="mb-0">
          <Form.Row>
            <Form.Group as={Col} lg={6} md={6}  className="selectplugin datePicker" controlId="fromDate">
              <DatePicker
                selected={fromDate}
                onChange={date => (setToDate(),changedate(date))}
                fromDate={fromDate}
                placeholderText="DD-MM-YYYY"
                dateFormat="dd-MM-yyyy"
                toDate={toDate}
                minDate={new Date()}
              />
               <span className="calendar ezyIcon icon-newcalendar"></span>
              <Form.Label>From Date<sup>*</sup></Form.Label>
            </Form.Group>

            <Form.Group as={Col} lg={6} md={6} className="selectplugin datePicker" controlId="toDate">
              <DatePicker
                selected={toDate}
                onChange={date => setToDate(date) + 1}
                fromDate={fromDate}
                placeholderText="DD-MM-YYYY"
                dateFormat="dd-MM-yyyy"
                toDate={toDate}
                minDate={fromDate ? fromDate : new Date()}
                // minDate={fromDate ? (props.hotel !== undefined ? state.setHDate : fromDate) : (props.hotel !== undefined ? state.setIHdate : new Date())}
                className="datefield"
              />
               <span className="calendar ezyIcon icon-newcalendar"></span>
              <Form.Label>To Date<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>

        </Form.Group>



        <Form.Group as={Col}>
          {!disableButton &&
            <Button
              size="xs"
              onClick={handleDates}
              variant="outline-primary"
              loading={state.loadBranch}
              type="submit"
            > Add</Button>
          }
          {disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              loading={state.loadBranch}
              isDisabled={true}
              type="submit"
            > Add</Button>
          }
        </Form.Group>
      </Form.Row>
      {state.showDates && applicable.applDates.length !== 0 &&
        <Form.Row>
          <Form.Group as={Col} xs={6}>
            <div className="selectedRout tableLayout">
              <Table>
                <thead>
                  <tr>
                    <th>Applicable Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {applicable.applDates.map((ap, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <span>{ap.tty}</span>
                        </td>
                        <td>
                          <span>{DateUtils.convertToDateHifen(ap.fm)}</span>
                        </td>
                        <td>
                          <span>{DateUtils.convertToDateHifen(ap.to)}</span>
                        </td>
                        <td>
                          {!disableButton && (!updateBtn || applicable.applDates.length>1) &&
                            <Button
                              size="xs"
                              onClick={handleRemoveDates(ap, index)}
                             variant="link" className="ezyIcon icon-delete">
                            </Button>
                          }
                          {disableButton &&
                            <Button
                              size="xs"
                              isDisabled={true}
                             variant="link" className="ezyIcon icon-delete">
                            </Button>
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Form.Row>
      }

    </>
  )
}
export default ApplicableDates