
import { Base64 } from 'js-base64';
import log from "loglevel";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import { Context } from "../../../App";
import Awardfunction from "../../assets/images/awward.png";
import AuthenticationService from '../../services/authService/AuthenticationService';
import { emailValidation} from '../commonUtils/FormValidations';
export const USER_AUTH_DATA = "userAuthData";
export const USER_NAME = "userName";
export const USER_PW = "userPw";
export const USER_REMEMBER = "userRemember";
export const INTIAL_USER_AUTH_DATA = "authData"
export const CLIENT_IP = "clientIp";
export const USERSTATE = "userState";
export const SHOW_NOTIFY = "showNotify";
export const SHOW_NOTIFY_HOTEL = "showNotifyHotel";
export const COMP_ID = "COMP_ID";
export const COUNTRY_DATA = "COUNTRY_DATA";
let countryMaster = require('../masterData/CountryMaster.json');

/**
 * @description:This function is for Login form
 * @author: Pravas
 * @param {*}
 * @function Login details
 * @date : 21-09-2020
 */

 function LoginForm(props) {
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [state, setState] = useState({ eid: "", pwd: "" })
    const [isLoad, setIsLoad] = useState(false);
    const [isfLoad, setfIsLoad] = useState(false);
    const [forgotpassword, setForgotpassword] = useState(false);
    const [showUserName, setShowUserName] = useState('');
    const [showUserPw, setShowUserPw] = useState('')
    const [error, setError] = useState(false);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [rememberme, setRememberme] = useState(false)
    const [emailsentsuc, setEmailsentsuc] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const focusRef = useRef();
    const [femail,setForgotEmail] = useState('')
    const [invalidUser, setInvalidUser] = useState('')
    const [enableOtp, setEnableOtp] = useState(false)
    const [otp, setOtp] = useState('')
    const [otpPin, setOtpPin] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [otpValidate, setOtpValidate] = useState("");
    const [enableLogin, setEnableLogin] = useState(true)
    const publicIp = require('public-ip');
    const [qtr,setQtr] = useState(sessionStorage.getItem("REGION_COUNTRY")==="QA"?true:false)
    
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
        if(id === "eid" && value===process.env.REACT_APP_ADMIN_USER_NAME
        ){
         setEnableLogin(true);
        }else if(id === "eid" && value!==process.env.REACT_APP_ADMIN_USER_NAME
        ){
         setEnableLogin(false);
        }
    };
    
    const handleOTPChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
        setOtpPin(value)
    };

    const handleChangeCheck = (e) => {
        if (rememberme) {
            setRememberme(false);
        } else {
            setRememberme(true);
        }
    };

    useEffect(() => {
        const remember = localStorage.getItem(USER_REMEMBER);
        if (remember) {
            const uname = localStorage.getItem(USER_NAME);
            const pw = localStorage.getItem(USER_PW);
            var decodePw = Base64.decode(pw);
            setState({ eid: uname, pwd: decodePw })
            setRememberme(true);
            // history.push("/ezytrip/dashboard");
        }
    }, [context.logindata]);

    const updateCache=(response)=>{
        var roles=response.data.res.roles;
        let firstChar = roles.charAt(0);
        if(firstChar===","){
            response.data.res.roles=roles.substring(1);
        }
        setContext({ logindata: response.data.res })
        localStorage.setItem(USER_AUTH_DATA, JSON.stringify(response.data.res));
        localStorage.setItem(INTIAL_USER_AUTH_DATA, JSON.stringify(response.data.res));
        localStorage.setItem(USERSTATE, "active");
        if (rememberme) {
            var encodePw = Base64.encode(state.pwd);
            localStorage.setItem(USER_NAME, state.eid);
            localStorage.setItem(USER_PW, encodePw);
            localStorage.setItem(USER_REMEMBER, true);
        }
        localStorage.setItem(SHOW_NOTIFY, true);
        localStorage.setItem(SHOW_NOTIFY_HOTEL, true);
        if(response.data.res.utype==="ta_user"){
            localStorage.setItem(COMP_ID, response.data.res.cid);
        }
        //Getting the CLIENT IP
       (async () => {localStorage.setItem(CLIENT_IP, await publicIp.v4());})();
        history.push("/ezytrip/dashboard");
    }


    const confirmOtp=()=>{
        setOtpValidate("")
        setIsLoad(true);
        if(otpPin!==""){
        AuthenticationService.confirmOTP(state).then(response => {
            console.log("Login test : " + JSON.stringify(response.data));
            let finalReg=[];
            if (response.data.suc) {
                if (response.data.res.roles && response.data.res.roles !== undefined) {
                    response.data.res.butype = response.data.res.utype;
                    if(response.data.res.is_admin && response.data.res.uid===1){
                        AuthenticationService.getCountryMasterData().then((resp) => {
                       
                               let list=[];
                               for(let val of resp.data.res){
                                const req={
                                    value:val.con_name,
                                    label:val.con_name,
                                    cur:val.cur
                                   }
                                   list.push(req);
                               }
                               localStorage.setItem("COUNTRY_DATA",JSON.stringify(list))
                               response.data.res.region_list=list;
                               response.data.res.region_type="Qatar";
                               response.data.res.bothRegions=true;
                               response.data.res.cur="QAR";
                               response.data.res.bcur="QAR";
                               updateCache(response);
                         });
                         
                    }else{
                    if(response.data.res.region_type!==undefined && response.data.res.region_type.includes(",")){
                        let regions=response.data.res.region_type.split(",");
                        for(let val of regions){
                        let list=countryMaster.filter(obj=>obj.label===val);
                        const req={
                        value: val,
                        label: val,
                        cur:list[0].cur
                        }
                        finalReg.push(req)
                        }
                        localStorage.setItem("COUNTRY_DATA",JSON.stringify(finalReg))
                        response.data.res.region_type=regions[0];
                        response.data.res.bothRegions=true;
                        let list=countryMaster.filter(obj=>obj.label===regions[0]);
                        response.data.res.cur=list[0].cur;
                        response.data.res.bcur=list[0].cur;
                    }else if(response.data.res.utype==="att_user"){
                        let list=countryMaster.filter(obj=>obj.label===response.data.res.region_type);
                        const req={
                            value: response.data.res.region_type,
                            label: response.data.res.region_type,
                            cur:list[0].cur
                            }
                            finalReg.push(req);
                        localStorage.setItem("COUNTRY_DATA",JSON.stringify(finalReg))
                        response.data.res.cur=list[0].cur;
                        response.data.res.bcur=list[0].cur;
                        response.data.res.bothRegions=false;
                    }else{
                        response.data.res.bothRegions=false;
                        response.data.res.cur=response.data.res.bcur;
                        response.data.res.is_bal=false;
                    }
                    updateCache(response);
                    }
                    console.log("Login SUC : " + JSON.stringify(response.data));
                } else {
                    setInvalidUser("Access Denied, Please check with Admin")
                    setIsLoad(false);
                    history.push("/ezytrip/login");
                    console.log('i m in faileddddd user', state.eid)
                }
            }
            else {
                 if(response.data.err === "Invalid otp"){
                    setIsLoad(false);
                    setNotiMessageShow(true);
                    setOtpPin('')
                    setNotiMessage('Invalid otp');
                    setNotiVarient('danger')
                    qtr && alert('Invalid otp')
                    hidemessage()
                }else if(response.data.err === "otp got expired"){
                    setIsLoad(false);
                    setNotiMessageShow(true);
                    setOtpPin('')
                    setNotiMessage('OTP got expired!! Please click on resend OTP');
                    setNotiVarient('danger')
                    hidemessage()
                   qtr && alert('OTP got expired!! Please click on resend OTP')
                }
                else if(response.data.res.is_active){//User name and Pw is Incorrect
                    setError(true);
                }else{//For Deactivated Users
                    setInvalidUser("Access Denied, Please check with Admin")
                    setIsLoad(false);
                }
                setIsLoad(false);
                setShowUserName("");
                setShowUserPw("");
            }
        }).catch(function (error) {
            log.error(
                "Exception occured in userLogin  function---" + error
            );
        });
    }else{
        setOtpValidate(<span className='indUser'><span className='ezyIcon icon-infonew'>Enter OTP</span></span>)
    }
    }
    const resendOtp=()=>{
        if(state.eid!==""){
        AuthenticationService.resendOTP({"eid":state.eid,"resend":true}).then(response => {
            if(response.data.suc) {
            setNotiMessageShow(true);
            setNotiMessage('OTP Sent successfully to your Email');
            setNotiVarient('success')
            qtr && alert('OTP Sent successfully to your Email')
            }else {
            setNotiMessageShow(true);
            setNotiMessage('OTP Not sent to Email');
            setNotiVarient('danger')
            qtr && alert('OTP Not sent to Email')
            }
        }).catch(function (error) {
            log.error(
                "Exception occured in resendOtp  function---" + error
            );
        });
    }else{
      setShowUserName(<span className='indUser'><span className='ezyIcon icon-infonew'>Enter User Name</span></span>);
    }
    }
    const handleUserLogin = (e) => {
        // e.stoppropagation()       
        e.preventDefault();
        setInvalidUser("")
        setError(false);
        setIsLoad(true);
        if (state.eid !== "" && state.pwd !== "") {
            if (state.eid === process.env.REACT_APP_ADMIN_USER_NAME && enableLogin){
                let payload = {}
                payload.eid=state.eid
                payload.pwd=state.pwd
                // payload.otp= 2236
                payload.is_admin=true
                AuthenticationService.confirmOTP(payload).then(response => {
                    console.log("Login test : " + JSON.stringify(response.data));
                    let finalReg=[];
                    if (response.data.suc) {
                        if (response.data.res.roles && response.data.res.roles !== undefined) {
                            response.data.res.butype = response.data.res.utype;
                            if(response.data.res.is_admin && response.data.res.uid===1){
                                AuthenticationService.getCountryMasterData().then((resp) => {
                               
                                       let list=[];
                                       for(let val of resp.data.res){
                                        const req={
                                            value:val.con_name,
                                            label:val.con_name,
                                            cur:val.cur
                                           }
                                           list.push(req);
                                       }
                                       localStorage.setItem("COUNTRY_DATA",JSON.stringify(list))
                                       response.data.res.region_list=list;
                                       response.data.res.region_type="Qatar";
                                       response.data.res.bothRegions=true;
                                       response.data.res.cur="QAR";
                                       response.data.res.bcur="QAR";
                                       updateCache(response);
                                 });
                                 
                            }else{
                            if(response.data.res.region_type!==undefined && response.data.res.region_type.includes(",")){
                                let regions=response.data.res.region_type.split(",");
                                for(let val of regions){
                                let list=countryMaster.filter(obj=>obj.label===val);
                                const req={
                                value: val,
                                label: val,
                                cur:list[0].cur
                                }
                                finalReg.push(req)
                                }
                                localStorage.setItem("COUNTRY_DATA",JSON.stringify(finalReg))
                                response.data.res.region_type=regions[0];
                                response.data.res.bothRegions=true;
                                let list=countryMaster.filter(obj=>obj.label===regions[0]);
                                response.data.res.cur=list[0].cur;
                                response.data.res.bcur=list[0].cur;
                            }else if(response.data.res.utype==="att_user"){
                                let list=countryMaster.filter(obj=>obj.label===response.data.res.region_type);
                                const req={
                                    value: response.data.res.region_type,
                                    label: response.data.res.region_type,
                                    cur:list[0].cur
                                    }
                                    finalReg.push(req);
                                localStorage.setItem("COUNTRY_DATA",JSON.stringify(finalReg))
                                response.data.res.cur=list[0].cur;
                                response.data.res.bcur=list[0].cur;
                                response.data.res.bothRegions=false;
                            }else{
                                response.data.res.bothRegions=false;
                                response.data.res.cur=response.data.res.bcur;
                            }
                            updateCache(response);
                            }
                            console.log("Login SUC : " + JSON.stringify(response.data));
                        } else {
                            setInvalidUser("Access Denied, Please check with Admin")
                            setIsLoad(false);
                            history.push("/ezytrip/login");
                            console.log('i m in faileddddd user', state.eid)
                        }
                    }
                    else {
                        if(response.data.res.is_active){//User name and Pw is Incorrect
                            setError(true);
                            setIsLoad(false)
                        }else{//For Deactivated Users
                            setInvalidUser("Access Denied, Please check with Admin")
                            setIsLoad(false);
                        }
                        setIsLoad(false);
                        setShowUserName("");
                        setShowUserPw("");
                    }
                }).catch(function (error) {
                    log.error(
                        "Exception occured in userLogin  function---" + error
                    );
                });
            }else{
                if(!enableOtp){AuthenticationService.userLogin(state).then(response => {
                console.log("OTP Sent Success " + JSON.stringify(response.data));
                if (response.data.suc){
                    setOtpPin("")
                    setOtpSent(true)
                    setEnableOtp(true);
                    setIsLoad(false);
                    setNotiMessageShow(true);
                    setNotiMessage('OTP Sent successfully to your Email');
                    setNotiVarient('success')
                    hidemessage()
                    qtr && alert('OTP Sent successfully to your Email')
                }else{
                    if(response.data.res.is_active){//User name and Pw is Incorrect
                        setError(true);
                        setIsLoad(false)
                    }else{
                        setNotiMessageShow(true);
                        setNotiMessage('Invalid user.Access denied');
                        setNotiVarient('danger')
                        setIsLoad(false);
                        hidemessage()
                        qtr && alert('Invalid user.Access denied')
                    }
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in userLogin  function---" + error
                );
            });}
        }
            
        } else {
            // setEmailsentsuc(false)
            setIsLoad(false);
            if(!emailsentsuc ){
            if (state.eid === "") {
                console.log('i m in failed user')
                setShowUserName(<span className='indUser'><span className='ezyIcon icon-infonew'>Enter User Name</span></span>);
            }
            else {
                console.log('i m in failed user')
                setShowUserName("");
            }
            if (state.pwd === "") {
                console.log('i m in failed pwd')
                setShowUserPw(<span className='indPassword'><span className='ezyIcon icon-infonew'>Enter Password</span></span>);
            }
            else {
                console.log('i m in failed pwd')
                setShowUserPw("");
            }}else{
                setShowUserName("");
                setShowUserPw("");
                setEmailsentsuc(false)
            }
        }

    }
    const hidemessage = () => {

        setTimeout(function () {
            setNotiMessageShow(false);
            setNotiMessage('');
            setNotiVarient('')
        }, 5000);
    
      }
    
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const showForgotPassword = () => {
        if (forgotpassword) {
          setForgotpassword(false)
        } else {
          setForgotpassword(true)
        }
      }
      const closeme = () => {
        setForgotpassword(false)
      }

      const handleemChange = (e) => {
        const {id,value} = e.target
        console.log("email::::",value)
        setForgotEmail(value)
      }

      const dispalceFunction = () => {
        setTimeout(function () {
            setNotiMessageShow(false);
            setNotiMessage('');
            setNotiVarient('')
        }, 3000);
      }
      /**
       * @description : handle forgot password
       * @author : Azamuddin
       * @date : 03-06-2021
       */
      const handleForgotPWD = () => {
        setfIsLoad(true)
        state.eid = ""
        state.pwd = ""
        setRememberme(false);
        if(emailValidation(femail)) {
            setEmailsentsuc(true)
            let req_json = {
                email: femail,
                pftype:"B2B",
                isbelongto:"B2B"
            }
            AuthenticationService.handleForgotPWD(req_json).then(response => {
                if(response.data && response.data.suc) {
                    setForgotEmail('')
                    setfIsLoad(false)
                    setNotiMessageShow(true);
                    setNotiMessage('Password Sent to your Email');
                    setNotiVarient('success')
                    qtr && alert('Password Sent to your Email')
                    setForgotEmail('')
                    dispalceFunction()
                    closeme()
                } else {
                    setfIsLoad(false)
                    setNotiMessageShow(true);
                    setNotiMessage('This user is not registered with Ezytrip');
                    setNotiVarient('danger')
                    qtr && alert('This user is not registered with Ezytrip')
                    dispalceFunction()
                }
            })
        } else {
            setfIsLoad(false)
            setNotiMessageShow(true);
            setNotiMessage('Please Enter A Valid Email');
            setNotiVarient('danger')
            qtr && alert('Please Enter A Valid Email')
            dispalceFunction()
        }
      }
    return (


        <div className="loginForm">
        <div className="error">
                    <Alert className="notification" variant={notiVarient} show={notiMessageShow}
                        onClose={() => setNotiMessageShow(false)}
                        dismissible> {notiMessage}</Alert>
                </div>

            <img
              src={logo}
              title=""
              alt="img"
              width={620}
              />
            <h5>{forgotpassword ? 'Forgot Password': 'Login as Agent!'}</h5>
                       <form onSubmit={e => handleUserLogin(e)} ref={focusRef}>
                
                {!forgotpassword ? <>
                <Form.Row>
                    <Form.Group as={Col} xs={12} controlId="eid">

                    <span className="mailIcon ezyIcon icon-new-email"></span>
                        <Form.Control type="text" className="username" placeholder="Enter User name" disabled = {otpSent}name="username" autoComplete="off" value={state.eid} onChange={handleChange} autoFocus />
                        {/* <Form.Label>Email/Username</Form.Label> */}
                        <Form.Text className='errorMes'>
                            {showUserName}
                        </Form.Text>

                    </Form.Group>
                    <Form.Group as={Col} xs={12} controlId="pwd" className="password">

                    <span className="passcodeIcon ezyIcon icon-password"></span>
                        <Form.Control type={passwordShown ? "text" : "password"} className="password" disabled = {otpSent}placeholder="Enter Password" name="password" autoComplete="off" value={state.pwd} onChange={handleChange} />
                        {/* <Form.Label>Password</Form.Label> */}
                        <Form.Text className='errorMes'>
                            {showUserPw}
                        </Form.Text>
                        <span onClick={togglePasswordVisiblity} className={"ezyIcon icon-view " + (passwordShown ? "show" : "hide")}></span>


                    </Form.Group>
                    <div className='inValidnumberuser'>
                    {invalidUser!=="" && invalidUser}
                    </div>
                  {enableOtp && 
                    <Form.Group as={Col} xs={12} controlId="otp">
                   
                        <Form.Control type="text" className="otpName" placeholder="Enter OTP" name="otp" 
                        autoComplete="off" value={otpPin} onChange={handleOTPChange} autoFocus />
                        <Form.Text className='errorMes'>
                            {otpValidate}
                        </Form.Text>
                    </Form.Group>
                    }
                </Form.Row>
                {error && <Form.Text className="loginError">
                    User Name and Password Incorrect
            </Form.Text>}


                </>:<>
                <div className="forgotpassword">
                      {!enableOtp && <p>Enter your login email, will send you a new password</p> }
                    <Form.Group as={Col} xs={12} controlId="eid">

                        <Form.Control type="text" className="username" onChange={handleemChange} placeholder="Enter User Email/Username" name="username" autoComplete="off"  autoFocus />
                        <Form.Text>
                          <span className='forIndemail'><span className='ezyIcon icon-infonew'>Enter your Email Id</span></span>
                        </Form.Text>
                    </Form.Group>
                </div></>}
                <div className="text-right">
                   {enableLogin && !forgotpassword && !enableOtp &&
                    <Button type="submit" variant="primary" className={(isLoad ? 'progress-bar-striped progress-bar-animated' : '')} size="lg" block onClick={handleUserLogin}>
                        {(isLoad ? 'Logging In...' : 'Login')}
                        </Button>
                    }
                    {!enableLogin && !forgotpassword && !enableOtp &&
                    <Button type="submit" variant="primary" className={(isLoad ? 'progress-bar-striped progress-bar-animated' : '')} size="lg" block onClick={handleUserLogin}>
                        {(isLoad ? 'Processing...' : 'Get OTP')}
                        </Button>
                    }
                    {forgotpassword &&
                    <>
                     <Button type="submit" variant="primary" className={(isfLoad ? 'progress-bar-striped progress-bar-animated' : '')} size="lg" block onClick={handleForgotPWD} >
                        Send Email
                        </Button>
                        <p className="fpws  mb-4">Suddenly remember password <Link onClick={closeme} className="ml-1" > Sign in</Link> </p> 
                    </>
                    }
                    <div className="text-right">
                    {enableOtp && 
                    <>
                    <Button type="submit" variant="primary" className={(isLoad ? 'progress-bar-striped progress-bar-animated' : '')} 
                      size="lg" block onClick={confirmOtp}>
                        {(isLoad ? 'Logging In...' : 'Confirm OTP')}
                    </Button>
                    <Link to="#" onClick={resendOtp}>Resend OTP</Link>
                    </>
                     }
                   </div>  
                   {!enableLogin  &&  <span className='epasOtp'> <span className='ezyIcon icon-info mr-2'></span> Enter email and password so that we can send an OTP </span>} 
                </div>
                {!forgotpassword && <div className="checkedDiv mt-3">

                    <Form.Check
                        type="checkbox"
                        id="save"
                        label="Remember me"
                        value={rememberme}
                        onChange={handleChangeCheck}
                        checked={rememberme}
                        custom
                    />
                    <Link to="#" onClick={showForgotPassword}>Forgot Password?</Link>

                </div>}
            </form>
        </div>

    )

}

export default LoginForm
