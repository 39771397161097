import React, { useEffect, useState, useRef } from "react";
import { Alert, Col, Form ,Modal, Button, Table} from "react-bootstrap";


function VisaConfirmation(props) {
    return (
        <>
        <div className="visaConfirmation">
        <span className="d-flex">Your application is successfully submitted with <span className="bookinRef"> Booking Ref Number: {props?.finalResp?.res?.booking_id}</span></span>
        <div className="tableScroll">
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>Traveller Name</th>
          <th>Visa Status</th>
          <th>Applied Country</th>
        </tr>
      </thead>
      <tbody>
      {props?.finalResp && props.finalResp?.res?.pax_info?.map((pax, idx) => (
        <React.Fragment key={idx}>
        <tr>
        <td>{pax.tl} {pax.fn} {pax.ln}({pax.ty})</td>
        <td className="inPro">{pax.visa_st}</td>
        <td>{pax.applied_cnt}</td>
      </tr>
      </React.Fragment>
       ))}
      </tbody>
    </Table>

        </div>
          

        </div>
        </>
     );
}

export default VisaConfirmation;