import BranchCreationService from "../../../../services/administration/BranchCreationService";

/**
 * @description: CRUD operations for CountryMaster
 * @author: Shaikh Arshad Hussain
 * @param {*}
 * @function countryMaster
 * @date : 27-02-2023
 */

export const getCountryMasterData = async (obj) => {
    let countryList = [];
    await BranchCreationService.getCountryMasterData(obj).then(response => {
        if (response.data.suc) {
            countryList = response.data.res;
        }
    });
    return Promise.resolve(countryList);
}

// ========================================================================
//  country master Create Operation
//  This will create country master and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    const saveObj = {
        con_id:obj.con_id,
        con_name:obj.con_name,
        cur:obj.cur,
        crby: obj.crby
    };
    await BranchCreationService.saveCountryMaster(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
