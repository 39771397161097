import React, { useContext, useEffect, useReducer, useState,useRef } from 'react';
import { Alert, Col, Row , Modal} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../../App";
import Button from '../../../../common/buttons/Button';
import Utilities from '../../../../commonUtils/Utilities';
import ResultsNotFound from '../../../../common/popUps/ResultsNotFound';
import Filter from '../filters/Filter';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';
import ResultpageTopfilter from '../../../flight/search/common/ResultpageTopFilter';
import Sort from '../filters/Sort';
import ResultBlock from './ResultBlock';
// import ResultpageTopfilter from './ResultpageTopFilter'
import ApplayMarkup from '../../../hotel/common/result/ApplayMarkup';
export const CLIENT_IP = "clientIp";
export const ONFLY_FLT_MKP = "onflyFltMkp";
export const SEL_AIR_PRICE = "selAirPrice";
export const OTID = "otid";
export const RTID = "rtid";
export const SEL_FLT_COMBO= "selFltCombo";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
const _ = require("lodash");

//Initial State
const initialState = {
  response: {}, sortType: 'cheapest',topFilter:"", srcorigns: "", destorigins: "", selPrice: "", multiOrgOne: "", multiOrgTwo: "", multiOrgThre: "", multiOrgFour: "", multiOrgFive: "", multiOrgSix: "", notiMessage: "", notiMessageShow: false, notiVarient: "danger", serviceFee: [],
  renderRes : false,sortList: false,initialresponse:{},showPop: false,minPrice:null,sortClear: false,showPopReissue:false,selTFOnwardPrice:"",selTFReturnPrice:"",markp:0,markupApplied:false,addMrkup:false,isResetSelected:false
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {

    case 'test':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//Main function For Result Section
function ResultSection(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [newclass, setNewClass] = useState('');
  const history = useHistory();
  const [onwards, setOnwards] = useState();
  const [price, setPrice] = useState();
  const [context, setContext] = useContext(Context);
  const [filterLoad, setFilterLoad] = useState(false);
  const [enable, setEnable] = useState(false);
  const ref = useRef();
  const [gccCodes , setGccCodes] = useState(["XNB","DWC","DXB","AZAZ","DZB","DOH","XSB","KWI","MCT","DMM","SHJ","IST","JED"]);

  useEffect(() => {
    dispatch({ type: 'response', payload: props.searchRes })
    dispatch({ type: 'initialresponse', payload: _.cloneDeep(props.searchRes) })
    if (state.minPrice == null && props.searchRes !== undefined && props.searchRes.origns !== undefined
      && props.searchRes.origns.length !== 0) {
      dispatch({type:'minPrice',payload:props.searchRes.origns[0].pr.tf})
    }
    if(props.triptype!==undefined && props.triptype===2){
      setNewClass('column')
    }
    window.sessionStorage.removeItem(ONFLY_FLT_MKP);
    window.sessionStorage.removeItem(SEL_AIR_PRICE);
    window.sessionStorage.removeItem(OTID);
    window.sessionStorage.removeItem(RTID);
    window.sessionStorage.removeItem(SEL_FLT_COMBO);
    window.sessionStorage.removeItem(SEL_SALAM_ONE);
    window.sessionStorage.removeItem(SEL_SALAM_TWO);
    // if(props.searchRes !==undefined && window.sessionStorage.getItem(SEL_AIR_PRICE)===null){
    //   for(let orgs of props.searchRes.origns){
    //      if(orgs.sup==="2" && window.sessionStorage.getItem(OTID)===null){
    //       if(props.triptype===1){
    //         const requst1={
    //            "srcorigns":[orgs.srcorigns[0]],
    //            "paxpr":orgs.paxpr,
    //            "pr":orgs.pr,
    //            "sup":"2",
    //            "servicefee":orgs.servicefee
    //          }
    //          const payload1={
    //           "tid":orgs.srcorigns[0].tid,
    //           "resp":[requst1]
    //         }
    //         window.sessionStorage.setItem(OTID,orgs.srcorigns[0].tid);
    //         window.sessionStorage.setItem(SEL_AIR_PRICE,JSON.stringify([payload1]));
    //         }
    //         if(props.triptype===2 && window.sessionStorage.getItem(RTID)===null){
    //           const requst2={
    //             "srcorigns":[orgs.srcorigns[0]],
    //             "destorigins":[orgs.destorigins[0]],
    //             "paxpr":orgs.paxpr,
    //             "pr":orgs.pr,
    //             "sup":"2",
    //             "servicefee":orgs.servicefee
    //           }
    //           const payload2={
    //             "tid":orgs.srcorigns[0].tid+"/"+orgs.destorigins[0].tid,
    //             "resp":[requst2]
    //           }
    //           window.sessionStorage.setItem(OTID,orgs.srcorigns[0].tid);
    //           window.sessionStorage.setItem(RTID,orgs.destorigins[0].tid);
    //           window.sessionStorage.setItem(SEL_AIR_PRICE,JSON.stringify([payload2]));
    //           window.sessionStorage.setItem(SEL_FLT_COMBO,orgs.srcorigns[0].tid+"/"+orgs.destorigins[0].tid);
    //          }

    //      }
    //   }

    // }

  }, []);

  /*   useEffect(() => {
        dispatch({ type: 'newClass', payload: state.newclass })
    }, [state.newclass]); */

  const handleCancel = (e) => {
    return window.location = "/ezytrip/bookings/flight/itinerary";
  }

  const topFilter=(airline) => {
    dispatch({type:'topFilter',payload:airline})
  }

  const handleChangeCallBack = (res, type) => {

    if(res.origns.length > 0) {
      dispatch({type:'showPop',payload: false})

    }  else {

      dispatch({type:'showPop',payload: true})


    }
    if(type == 'filter') {
      dispatch({ type: 'sortList', payload: true })
      dispatch({ type:"isResetSelected",payload:false})
    }
     if(type == 'clear') {
      dispatch({type : 'sortClear',payload: true})
    } else {
      dispatch({type : 'sortClear',payload: false})
    }
    dispatch({ type: 'response', payload: type !== 'clear' ? res : state.initialresponse })
    dispatch({ type: 'sortType', payload: type })

    setFilterLoad(false)
  props.handleResultSection(res);
  //window.scrollTo(0, 0);
  }
  const viewStyle = (res) => {
    /*  
     if (res === 'list') {
         dispatch({ type: 'newClass', payload: 'list' })
     } else{
         dispatch({ type: 'newClass', payload: 'column' })
     }  */
    setNewClass(res);

  }
  const convertFareReq = (selectedFlt) => {
    let bag=[];
    if(props.srequest.flowType!=="REISSUE" && selectedFlt.bgd!==undefined && selectedFlt.bgd.length!==0){
      bag=selectedFlt.bgd;
      bag[0].type="Checkin";
      const req={
        "qun": "7",
        "wt": "kg",
        "type":"Cabin"
      }
      bag.push(req)
    }else{
      if(selectedFlt.bgd.length===1){
        bag=selectedFlt.bgd;
        bag[0].type="Checkin";
        const req={
          "qun": "7",
          "wt": "kg",
          "type":"Cabin"
        }
        bag.push(req)
      }else{
        bag=selectedFlt.bgd;
        for(let val of bag){
          val.type="Checkin";
        }
        const req={
          "qun": "7",
          "wt": "kg",
          "type":"Cabin"
        }
        bag.push(req);
      }
    }
    const payload = {
      "tid": selectedFlt.tid,
      "ref": selectedFlt.ref,
      "sdur": selectedFlt.sdur,
      "re": selectedFlt.re,
      "stop": selectedFlt.stop,
      "sup": selectedFlt.sup,
      "cl": selectedFlt.cl,
      "ft": selectedFlt.ft,
      "tktair": selectedFlt.tktair,
      "tecst": selectedFlt.tecst,
      "seg": selectedFlt.seg,
      "bgd": bag,
      "pr": state.selPrice.pr,
      "paxpr": state.selPrice.paxpr,
      "serviceFee": state.serviceFee,
      "dealcode":selectedFlt.dealcode,
      "office_id": selectedFlt.office_id!==undefined?selectedFlt.office_id:"",
    }
    return payload;
  }

  const createBaggage=(type1,qant1,type2,qant2)=>{
    let bag=[];
    const req1={
      "qun": qant1,
      "wt": "kg",
      "type":type1
    }
    bag.push(req1);
    const req2={
      "qun": qant2,
      "wt": "kg",
      "type":type2
    }
    bag.push(req2);
    return bag;
  }

  //Convert Travel Fusion Object
  const convertTFFareReq =  (selectedFlt) => {
    let bag=[];
    var check_qun="";
    var cabin_qun="0";
    let selFareObj = selectedFlt.fareop.filter((obj) => obj.selected);
    if(state.srcorigns.sup==="4"){
      if(selFareObj[0].pr.ftyp==="Regular - R" || selFareObj[0].pr.ftyp==="Flexi - J"){
        if(state.response.region_type==="Domestic"){
          check_qun="15";
        }else if(state.response.region_type==="International"){
        if((props.srequest.srcCode==="JED" && props.srequest.dest.length!==0 && props.srequest.dest[0].countryCode==="IN") || (props.srequest.src.length!==0 && props.srequest.src[0].countryCode==="IN" && props.srequest.destCode==="JED")){
          check_qun="30";
        }else if(gccCodes.includes(props.srequest.srcCode) || gccCodes.includes(props.srequest.destCode)){
          check_qun="30";
        }else{
          check_qun="20";
      }
      }
        cabin_qun="7";
      }else if(selFareObj[0].pr.ftyp.includes("Corporate")){
          cabin_qun="10";
        if(state.response.region_type==="Domestic"){
          check_qun="15";
        }else{
          check_qun="30";
        }
      }else{
        check_qun="30";
        cabin_qun="7";
      }
      bag=createBaggage("Checkin",check_qun,"Cabin",cabin_qun);
    }else{ // Setting Check in baggage
      if(selFareObj[0].pr.cabb!==undefined){
        if(selFareObj[0].pr.cabb.includes("Chargable") || selFareObj[0].pr.cabb.includes("Chargeable")){
         check_qun="0";
        }else{
         if(selFareObj[0].pr.cabb.includes("free") || selFareObj[0].pr.cabb.includes("Free")){
           if(selFareObj[0].pr.bagage_info!==undefined){
           if(selFareObj[0].pr.bagage_info.includes("20")){
             check_qun="20";
           }else if(selFareObj[0].pr.bagage_info.includes("30")){
             check_qun="30";
           }else if(selFareObj[0].pr.bagage_info.includes("40")){
             check_qun="40";
           }else if(selFareObj[0].pr.bagage_info.includes("15")){
             check_qun="15";
           }
         }
         }else if(selFareObj[0].pr.cabb.includes("20")){
              check_qun="20";
         }else if(selFareObj[0].pr.cabb.includes("30")){
           check_qun="30";
         }else if(selFareObj[0].pr.cabb.includes("40")){
           check_qun="40";
         }
        }
      }
      if(selFareObj[0].pr.chekb !==undefined && selFareObj[0].pr.chekb!==""){// Setting Cabin baggage
       if(selFareObj[0].pr.chekb.includes("Chargable") || selFareObj[0].pr.chekb.includes("Chargeable")){
         cabin_qun="0";
         if(state.srcorigns.sup==="6"){
          cabin_qun="7";
        }else if(state.srcorigns.sup==="2"){
          cabin_qun="10";
        }
       }else{
        if(selFareObj[0].pr.chekb.includes("7")){
         cabin_qun="7";
        }else if(selFareObj[0].pr.chekb.includes("10")){
         cabin_qun="10";
        }else if(state.srcorigns.sup==="2"){
          cabin_qun="10";
        }
       }
     }else{
      if(state.srcorigns.sup==="6"){
        cabin_qun="7";
      }else if(state.srcorigns.sup==="2"){
        cabin_qun="10";
      }
     }
     bag=createBaggage("Checkin",check_qun,"Cabin",cabin_qun);
    }
    const payload = {
      "tid": selectedFlt.tid,
      "routeId":selFareObj[0].routeId,
      "prouteId":selectedFlt.prouteId,
      "ref": selectedFlt.ref,
      "sdur": selectedFlt.sdur,
      "re": selectedFlt.re,
      "stop": selectedFlt.stop,
      "sup": selectedFlt.sup,
      "cl": selectedFlt.cl,
      "ft": selectedFlt.ft,
      "tktair": selectedFlt.tktair,
      "tecst": selectedFlt.tecst,
      "seg": selectedFlt.seg,
      "bgd": bag,
      "pr": selFareObj[0].pr,
      "paxpr": selFareObj[0].paxpr,
      "serviceFee": selFareObj[0].servicefee,
      "dealcode":selectedFlt.dealcode,
      "transId":selectedFlt.transId,
      "jsessionId":selectedFlt.jsessionId
    }
    if(selectedFlt.token) {
      payload.token = selectedFlt.token
      payload.extra = selectedFlt.extra ? selectedFlt.extra : ""
    }
    if(selectedFlt.journeyKey) {
      payload.journeyKey = selectedFlt.journeyKey
    }
    if(selFareObj[0].aj_fare_key) {
      payload.aj_fare_key = selFareObj[0].aj_fare_key
    }
    if(selFareObj[0].fareKey) {
      payload.fareKey = selFareObj[0].fareKey
    }
    if(selFareObj[0].promotion_code) {
      payload.promotion_code = selFareObj[0].promotion_code
    }
    return payload;
  }

  function calculateHoursandMinsbetweendates(endDate, startDate) {
   var timeStart = new Date(startDate).getTime();
   var timeEnd = new Date(endDate).getTime();
   var hourDiff = timeEnd - timeStart; //in ms
   var secDiff = hourDiff / 1000; //in s
   var minDiff = hourDiff / 60 / 1000; //in minutes
   var hDiff = hourDiff / 3600 / 1000; //in hours
   let hours = Math.floor(hDiff);
   return (hours);
 }

  const fireFareRuleReq = (flt) => {
    /*   dispatch({ type: "notiMessage", payload: "Please select your flight" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true }) */
    var request = "";
    let selectedFltSame = false;
    let checkResedVal = true;

      if(state.srcorigns !== undefined && state.srcorigns !== ''){
       let srcs = flt.srcorigns.filter((src)=>{return src.tid===state.srcorigns.tid});
       if(srcs.length >0){
        selectedFltSame = true;
       }

      }
      if(props.impTag == "IMPORT" && props.srequest.flowType==="REISSUE") {
        state.multiOrgOne = flt.srcorigns[0]
        dispatch({type:"multiOrgOne",payload:state.multiOrgOne})
        state.selPrice = flt
        dispatch({type:"selPrice",payload:state.selPrice})
      }
      if(props.srequest.flowType==="REISSUE" || parseInt(state.response.tt) === 3){
        selectedFltSame = true;
      }

      if(props.srequest.flowType==="REISSUE"){
        if(state.selPrice!==undefined && state.selPrice.pr!==undefined && state.selPrice.pr.resdval!==undefined && state.selPrice.pr.resdval!==0){
          checkResedVal=false;
          dispatch({ type: "showPopReissue", payload: true })
        }
      }
          //Calculating the time for Air arabia
          let isSucces=true;
          if(parseInt(state.response.tt) === 2 && flt.sup==="2"){
           let hrs=calculateHoursandMinsbetweendates(flt.destorigins[0].seg[0].dd,flt.srcorigns[0].seg.slice(-1)[0].ad);
           if(Number(hrs)<=4){
            isSucces=false;
           }
          }
      if(isSucces){
      if (checkResedVal && selectedFltSame && ((state.srcorigns !== undefined && state.srcorigns !== '') || (state.multiOrgOne !== "" && parseInt(state.response.tt) === 3))) {
        /* if ((flt.srcorigns[0].tid === state.srcorigns.tid) || parseInt(state.response.tt) ===3) { */
        let check=true;
        if (parseInt(state.response.tt) === 1) {
          request = createOnewFareRuleReq(flt);
        } else if (parseInt(state.response.tt) === 2) {
          if(state.srcorigns.sup!=="4" || (state.srcorigns.sup==="4" && checkIndiFareCombination(flt))){
            request = createRoundFareRuleReq(flt);
          }else{
            check=false;
          }
        } else if (parseInt(state.response.tt) === 3) {
          request = createMultiFareRuleReq();
        }
        if(check){
          history.push({
            pathname: '/ezytrip/bookings/flight/itinerary',
            state: {
              req: request,
              searchReq:props.srequest
            }
          });
        }else{
          dispatch({ type: "showFrePopup", payload: true })
        }
        /*  } */
      }else if(checkResedVal){
        dispatch({ type: "notiMessage", payload: "Please select your flight" })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hideMsg();
      }
    }else{
      dispatch({ type: "notiMessage", payload: "Unable to select flight due to Arival and departure is less than 4hrs" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hideMsg();
    }




    // var newWindow = window.open('/ezytrip/bookings/flight/itinerary');
    // newWindow.req = request;
  }

  const hideMsg=()=>{
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const createOnewFareRuleReq = (flt) => {
    let src_origns = []
    if(flt.srcorigns.length > 1){
      for(let src of flt.srcorigns){
        if(src.tid===window.sessionStorage.getItem(OTID)){
          src_origns=[src];
        }
        if(state.srcorigns.sup==="6"){
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE))
            if(src.tid===resp.tid){
              src_origns=[src];
              break;
            }
          }
        /* else{
          src_origns=flt.srcorigns;
        } */
      }
      }else{
      src_origns=flt.srcorigns;
      }

    let indigo_sessionKey = ''

    if(state.srcorigns.sup==="5" || state.srcorigns.sup==="6") {
      src_origns = flt.srcorigns.filter(s => s.tid == state.srcorigns.tid)
    }
    if(state.srcorigns.sup==="4") {
      src_origns = flt.srcorigns.filter(s => s.tid == state.srcorigns.tid)
      indigo_sessionKey = src_origns[0].indigo_ugkey
    }
    const payload = {
      "echo": state.response.echo,
      "cha": state.response.cha,
      "cur": state.response.cur,
      "req_cur": state.response.cur,
      "adt": state.response.adt,
      "chd": state.response.chd,
      "inf": state.response.inf,
      "cid": state.response.cid,
      "sty": state.response.sty,
      "tt": state.response.tt,
      "office_id": state.srcorigns.office_id!==undefined?state.srcorigns.office_id:"",
      "tid": flt.srcorigns.tid ? flt.srcorigns.tid : state.srcorigns.tid,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "pcid": context.logindata.pcid,
      "tkt_user_type": context.logindata.utype,
      "tkt_user_id": context.logindata.uid,
      "client_ip":localStorage.getItem(CLIENT_IP),
      "journey_type":props.srequest!==undefined!==undefined && props.srequest.journey_type!==undefined?props.srequest.journey_type:"Onward",
      "mappings": state.response.mappings,
      "origns": [{
        "srcorigns": [state.srcorigns.sup==="0"?convertFareReq(state.srcorigns):convertTFFareReq(src_origns[0])]
      }],
      "psngrs": (!props.srequest.paxChanged && props.srequest !== undefined && props.srequest.psngrs !== undefined) ? props.srequest.psngrs : "",
      "flowType": "DIRECT",
      "src_country":props.srequest.src!==undefined && props.srequest.src.length!==0 && props.srequest.src[0].countryCode,
      "dest_country":props.srequest.dest!==undefined && props.srequest.dest.length!==0 && props.srequest.dest[0].countryCode,
      "src_code":props.srequest.srcCode,
      "dest_code":props.srequest.destCode,
      "region_type":state.response.region_type,
      "gst":flt.gst!==undefined?flt.gst:""
    }
    if(indigo_sessionKey) {
      payload.indigo_sessionKey = indigo_sessionKey
    }
    if(flt.ugk && flt.sup_currency) {
      payload.ugk = flt.ugk
      payload.sup_currency = flt.sup_currency
    }
    return payload;
  }
  const createRoundFareRuleReq = (flt) => {
    let src_origns = []
    if(flt.srcorigns.length > 1){
      for(let src of flt.srcorigns){
        if(src.tid===window.sessionStorage.getItem(OTID)){
          src_origns=[src];
          break;
        }
        //For Salam Air
        if(state.srcorigns.sup==="6"){
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE))
            if(src.tid===resp.tid){
              src_origns=[src];
              break;
            }
          }
      }
      }else{
      src_origns=flt.srcorigns;
      }
      let dest_origns = []
      if(flt.destorigins.length > 1){
        for(let src of flt.destorigins){
          if(src.tid===window.sessionStorage.getItem(RTID)){
            dest_origns=[src];
            break;
          }
          //For Salam Air
        if(state.srcorigns.sup==="6"){
          let resp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_TWO))
            if(src.tid===resp.tid){
              dest_origns=[src];
              break;
            }
          }
        }
        }else{
          dest_origns=flt.destorigins;
          if(state.srcorigns.sup=="7"){
            dest_origns[0].fareop[0].pr.bagage_info=flt.srcorigns[0].fareop[0].pr.bagage_info;
            dest_origns[0].fareop[0].pr.chekb=flt.srcorigns[0].fareop[0].pr.chekb;
            dest_origns[0].fareop[0].pr.cabb=flt.srcorigns[0].fareop[0].pr.cabb;
          }
        }
    let indigo_sessionKey = ''
    //flyDubai exception
    if(state.srcorigns.sup==="5" || state.srcorigns.sup==="6") {
      src_origns = flt.srcorigns.filter(s => s.tid == state.srcorigns.tid)
    }
    if(state.destorigins.sup === "5") {
      dest_origns = flt.destorigins.filter(s => s.tid == state.destorigins.tid)
      let tids=state.srcorigns.tid.replace("_1","_2");
      dest_origns[0].tid=tids;
    }
    if(state.srcorigns.sup==="4") {
      src_origns = flt.srcorigns.filter(s => s.tid == state.srcorigns.tid)
      indigo_sessionKey = src_origns[0].indigo_ugkey
    }
    if(state.destorigins.sup === "4") {
      dest_origns = flt.destorigins.filter(s => s.tid == state.destorigins.tid)
      let tids=state.srcorigns.tid.replace("_1","_2");
      dest_origns[0].tid=tids;
    }
    const payload = {
      "echo": state.response.echo,
      "cha": state.response.cha,
      "cur": state.response.cur,
      "req_cur": state.response.cur,
      "adt": state.response.adt,
      "chd": state.response.chd,
      "inf": state.response.inf,
      "cid": state.response.cid,
      "sty": state.response.sty,
      "tt": state.response.tt,
      "tid": state.srcorigns.tid,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "pcid": context.logindata.pcid,
      "office_id": state.srcorigns.office_id!==undefined?state.srcorigns.office_id:"",
      "tkt_user_type": context.logindata.utype,
      "tkt_user_id": context.logindata.uid,
      "client_ip":localStorage.getItem(CLIENT_IP),
      "journey_type":props.srequest!==undefined!==undefined && props.srequest.journey_type!==undefined?props.srequest.journey_type:"Onward",
      "mappings": state.response.mappings,
      "origns": [{
        "srcorigns": [state.srcorigns.sup==="0"?convertFareReq(state.srcorigns):convertTFFareReq(src_origns[0])],
        "destorigins": [state.destorigins.sup==="0"?convertFareReq(state.destorigins):convertTFFareReq(dest_origns[0])]
      }],
      "psngrs": (!props.srequest.paxChanged && props.srequest !== undefined && props.srequest.psngrs !== undefined) ? props.srequest.psngrs : [],
      "flowType": "DIRECT",
      "src_country":props.srequest.src!==undefined && props.srequest.src.length!==0 && props.srequest.src[0].countryCode,
      "dest_country":props.srequest.dest!==undefined && props.srequest.dest.length!==0 && props.srequest.dest[0].countryCode,
      "src_code":props.srequest.srcCode,
      "dest_code":props.srequest.destCode,
      "region_type":state.response.region_type,
      "gst":flt.gst!==undefined?flt.gst:""
    }
    if(indigo_sessionKey) {
      payload.indigo_sessionKey = indigo_sessionKey
    }
    if(flt.ugk && flt.sup_currency) {
      payload.ugk = flt.ugk
      payload.sup_currency = flt.sup_currency
    }
    return payload;
  }

  //Checking both Fare options
  const checkIndiFareCombination=(flt)=>{
    let src_origns = []
    if(flt.srcorigns.length > 1 && window.sessionStorage.getItem(OTID)!==null){
      for(let src of flt.srcorigns){
        if(src.tid===window.sessionStorage.getItem(OTID)){
          src_origns=[src];
          break;
        }
      }
      }else{
      src_origns=flt.srcorigns;
      }
      let dest_origns = []
      if(flt.destorigins.length > 1 && window.sessionStorage.getItem(RTID)!==null){
        for(let src of flt.destorigins){
          if(src.tid===window.sessionStorage.getItem(RTID)){
            dest_origns=[src];
            break;
          }
        }
        }else{
          dest_origns=flt.destorigins;
        }
        if(src_origns.length > 0 && dest_origns.length > 0){
          let srcList=src_origns[0].fareop.filter(obj=>obj.selected);
          let destList=dest_origns[0].fareop.filter(obj=>obj.selected)
          if(srcList.length!==0 && destList.length!==0 && srcList[0].pr.ftyp!==destList[0].pr.ftyp){//Checking both fare options are equal or not
            if(srcList[0].pr.ftyp==="Regular - R"){
              dispatch({ type: 'onward_fare', payload: "SAVER" })
            }else if(srcList[0].pr.ftyp==="Flexi - J"){
              dispatch({ type: 'onward_fare', payload: "FLEXI" })
            }else{
              dispatch({ type: 'onward_fare', payload: "CORPORATE" })
            }
            if(destList[0].pr.ftyp==="Regular - R"){
              dispatch({ type: 'return_fare', payload: "SAVER" })
            }else if(destList[0].pr.ftyp==="Flexi - J"){
              dispatch({ type: 'return_fare', payload: "FLEXI" })
            }else{
              dispatch({ type: 'return_fare', payload: "CORPORATE" })
            }
            return false;
          }
         }
   return true;
  }

  const createMultiFareRuleReq = () => {
    let selectedFlts = [];

    if (state.multiOrgOne) {
      selectedFlts.push(convertFareReq(state.multiOrgOne));
    }
    if (state.multiOrgTwo) {
      selectedFlts.push(convertFareReq(state.multiOrgTwo));
    }
    if (state.multiOrgThre !== "") {
      selectedFlts.push(convertFareReq(state.multiOrgThre));
    }
    if (state.multiOrgFour !== "") {
      selectedFlts.push(convertFareReq(state.multiOrgFour));
    }
    if (state.multiOrgFive !== "") {
      selectedFlts.push(convertFareReq(state.multiOrgFive));
    }
    if (state.multiOrgSix !== "") {
      selectedFlts.push(convertFareReq(state.multiOrgSix));
    }
    const payload = {
      "tid": state.multiOrgOne.tid,
      "echo": state.response.echo,
      "cha": state.response.cha,
      "cur": state.response.cur,
      "req_cur": state.response.cur,
      "adt": state.response.adt,
      "chd": state.response.chd,
      "inf": state.response.inf,
      "cid": state.response.cid,
      "sty": state.response.sty,
      "tt": state.response.tt,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "bktype":props.srequest.bktype,
      "office_id": state.multiOrgOne.office_id!==undefined?state.multiOrgOne.office_id:"",
      "mappings": state.response.mappings,
      "pcid": context.logindata.pcid,
      "client_ip":localStorage.getItem(CLIENT_IP),
      "journey_type":props.srequest!==undefined!==undefined && props.srequest.journey_type!==undefined?props.srequest.journey_type:"Onward",
      "origns": [{
        "srcorigns": selectedFlts
      }],
      "psngrs": (!props.srequest.paxChanged && props.srequest !== undefined && props.srequest.psngrs !== undefined) ? props.srequest.psngrs : "",
      "flowType": (props.srequest !== undefined && props.srequest.flowType !== undefined) ? props.srequest.flowType : "DIRECT",
      "tkts": (props.srequest !== undefined && props.srequest.tkts !== undefined && props.srequest.tkts.length !== 0) ? props.srequest.tkts : [],
      "bkref": (props.srequest !== undefined && props.srequest.bref !== undefined) ? props.srequest.bref : "",
      "oldseg": (props.srequest !== undefined && props.srequest.seg !== undefined && props.srequest.seg.length !== undefined) ? props.srequest.seg : [],
      "pnr": (props.srequest !== undefined && props.srequest.pnr !== undefined) ? props.srequest.pnr : "",
      "oldtid": props.srequest.tid,
      "segments":state.response.segments!==undefined?state.response.segments:[],
      "src_country":convertSrcCountry(props.srequest.search),
      "dest_country":convertDestCountry(props.srequest.search),
      "gst":state.selPrice.gst!==undefined?state.selPrice.gst:""
    }
    return payload;
  }

  const convertSrcCountry=(srequest)=>{
   var countryCodes="";
   if(srequest.orignDataOne!==undefined && srequest.orignDataOne.length!==0){
    countryCodes=srequest.orignDataOne[0].countryCode;
   }
   if(srequest.orignDataTwo!==undefined && srequest.orignDataTwo.length!==0){
    countryCodes=countryCodes+","+srequest.orignDataTwo[0].countryCode;
   }
   if(srequest.orignDataThree!==undefined && srequest.orignDataThree.length!==0){
    countryCodes=countryCodes+","+srequest.orignDataThree[0].countryCode;
   }
   if(srequest.orignDataFour!==undefined && srequest.orignDataFour.length!==0){
    countryCodes=countryCodes+","+srequest.orignDataFour[0].countryCode;
   }
   if(srequest.orignDataFive!==undefined && srequest.orignDataFive.length!==0){
    countryCodes=countryCodes+","+srequest.orignDataFive[0].countryCode;
   }
   if(srequest.orignDataSix!==undefined && srequest.orignDataSix.length!==0){
    countryCodes=countryCodes+","+srequest.orignDataSix[0].countryCode;
   }
   if(countryCodes!=="" && countryCodes!==undefined){
    countryCodes = Array.from(new Set(countryCodes.split(','))).toString();
   }
   return countryCodes;
  }
  const convertDestCountry=(srequest)=>{
    var countryCodes="";
    if(srequest.destDataOne!==undefined && srequest.destDataOne.length!==0){
     countryCodes=srequest.destDataOne[0].countryCode;
    }
    if(srequest.destDataTwo!==undefined && srequest.destDataTwo.length!==0){
     countryCodes=countryCodes+","+srequest.destDataTwo[0].countryCode;
    }
    if(srequest.destDataThree!==undefined && srequest.destDataThree.length!==0){
     countryCodes=countryCodes+","+srequest.destDataThree[0].countryCode;
    }
    if(srequest.destDataFour!==undefined && srequest.destDataFour.length!==0){
     countryCodes=countryCodes+","+srequest.destDataFour[0].countryCode;
    }
    if(srequest.destDataFive!==undefined && srequest.destDataFive.length!==0){
     countryCodes=countryCodes+","+srequest.destDataFive[0].countryCode;
    }
    if(srequest.destDataSix!==undefined && srequest.destDataSix.length!==0){
     countryCodes=countryCodes+","+srequest.destDataSix[0].countryCode;
    }
    if(countryCodes!=="" && countryCodes!==undefined){
      countryCodes = Array.from(new Set(countryCodes.split(','))).toString();
    }
    return countryCodes;
   }
  //=====================================
  // This function will get Class
  //=====================================
  const selFlight = (data, price, serFee) => {
    var selectedFlt = JSON.parse(data);
    dispatch({ type: 'selPrice', payload: price })
    dispatch({ type: 'serviceFee', payload: serFee })
    if (selectedFlt.tid.includes("_1") && selectedFlt.ref == 1) {
      dispatch({ type: 'srcorigns', payload: selectedFlt })
    } else {
      dispatch({ type: 'destorigins', payload: selectedFlt })
    }
  }

  const selMultiFlight = (selflt, price, serFee) => {
    var data = JSON.parse(selflt);
    if (data.tid.includes("_1")) {
      dispatch({ type: 'selPrice', payload: price })
      dispatch({ type: 'serviceFee', payload: serFee })
      dispatch({ type: 'multiOrgOne', payload: data })
    } else if (data.tid.includes("_2")) {
      dispatch({ type: 'multiOrgTwo', payload: data })
    } else if (data !== undefined && data.tid.includes("_3")) {
      dispatch({ type: 'multiOrgThre', payload: data })
    } else if (data !== undefined && data.tid.includes("_4")) {
      dispatch({ type: 'multiOrgFour', payload: data })
    } else if (data !== undefined && data.tid.includes("_5")) {
      dispatch({ type: 'multiOrgFive', payload: data })
    } else if (data !== undefined && data.tid.includes("_6")) {
      dispatch({ type: 'multiOrgSix', payload: data })
    }

  }

  const showMoreFlightsColView = (event) => {
    if (event.target.parentNode.classList.contains("moreFltOpen")) {
      event.target.parentNode.classList.remove("moreFltOpen");
      event.target.innerHTML = "+ More flight options";
    } else {
      event.target.parentNode.classList.add("moreFltOpen");
      event.target.innerHTML = "- Less flight options";
    }
  }

  //Calculation the DISC
  const calculateDisc = (taxes) => {
    if (taxes !== undefined) {
      for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
        var mak = taxes[taxIndex];
        if (mak !== undefined && mak.dealmkp!==undefined && mak.dealmkp !== 0) {
          return Number(mak.dealmkp);
        } else {
          return 0.00;
        }
      }
    } else {
      return 0.00;
    }
  }

  const calculateGST=(flight)=>{
    let total=0;
    let gst={};
    if(state.response.gst!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type==="India"){
      gst = Utilities.applyGST(flight.servicefee,state.response.gst,flight.pr.tax,props.srequest,"Flight");
      if(gst.gst_invoice.length!==0){
        for(let val of gst.gst_invoice){
          if(gst.type==="same"){
          if(total===0){
            total=Number(val.cgst_val)+Number(val.sgst_val);
          }else{
            total=total+Number(val.cgst_val)+Number(val.sgst_val);
          }
         }else{
          if(total===0){
            total=Number(val.igst_val);
          }else{
            total=total+Number(val.igst_val);
          }
         }
      }
    }
    gst.total_gst=total;
    flight.gst=gst;
    }
    return Number(total).toFixed(2);
  }

  //Calculation the DISC
  const calcuateServiceFee = (serviceFeeList) => {
    var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
      if(servicefee==="NaN"){
        return 0.00;
      }else{
        return servicefee;
      }
    } else {
      return 0.00;
    }
  }
const loaderTrue = () =>{
  setFilterLoad(true)
}

const handleCLClose = () => {
  dispatch({ type: 'showPopReissue', payload: false });
}

const handleFareClose = () => {
  dispatch({ type: 'showFrePopup', payload: false });
}

const getTotalFare=(paxprList,total,serviceFee,gst)=>{
  let totalFare=0;
  let totalValue=0;
  let adtVal=0;
  let chdVal=0;
  let infVal=0;
  for(let pax of paxprList){
  if(pax.ptype==="ADT" && pax.resdval!==0){
      adtVal=pax.resdval * Number(state.response.adt);
    }else if(pax.ptype==="CHD"){
      chdVal=pax.resdval * Number(state.response.chd);
    }else if(pax.ptype==="INF"){
      infVal=pax.resdval * Number(state.response.inf);
    }
  }
  totalFare=adtVal+chdVal+infVal;
  totalValue=Utilities.addPrice(parseFloat(total) + parseFloat(serviceFee) + parseFloat(gst), totalFare, "Sub");
  return totalValue;
}

const checkTaxes=(Taxes1,Taxes2,type)=>{
  for(let taxIndex=0; taxIndex < Taxes1.length; taxIndex++){
    var mak=Taxes1[taxIndex];
    if(mak.OT!==undefined){
      let OTC=Taxes2.filter((obj)=>obj.OT);
      if(OTC.length!==0){
        mak.OT = type==="Sub"?mak.OT- OTC[0].OT:mak.OT + OTC[0].OT
      }
    }
    if(mak.attdisc!==undefined){
      let attdiscs=Taxes2.filter((obj)=>obj.attdisc);
      if(attdiscs.length!==0){
        mak.attdisc = type==="Sub"?mak.attdisc - attdiscs[0].attdisc:mak.attdisc + attdiscs[0].attdisc;
      }
    }
    if(mak.tadisc!==undefined){
      let tadiscs=Taxes2.filter((obj)=>obj.tadisc);
      if(tadiscs.length!==0){
      mak.tadisc = type==="Sub"? mak.tadisc - tadiscs[0].tadisc : mak.tadisc + tadiscs[0].tadisc;
      }
    }
    if(mak.stadisc!==undefined){
      let stadiscs=Taxes2.filter((obj)=>obj.stadisc);
      if(stadiscs.length!==0){
       mak.stadisc= type==="Sub"?mak.stadisc - stadiscs[0].stadisc : mak.stadisc + stadiscs[0].stadisc;
      }
    }
  }
 return Taxes1;
}

const updateServiceFee=(sf,list,selPrveObj)=>{
  let currenct_sf = []
  var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
  for(let i in list){
    let selected_sf = sf[i]
    let initResponse = list[i]
    let previous_sf = selPrveObj[0].servicefee[i]
    if(initResponse && previous_sf && selected_sf) {
      initResponse.adt = Number((initResponse.adt - previous_sf.adt) + selected_sf.adt)
      if(initResponse.chd > 0) {
        initResponse.chd = Number((initResponse.chd - previous_sf.chd) + selected_sf.chd)
      }
      if(initResponse.inf > 0) {
        initResponse.inf = Number((initResponse.inf - previous_sf.inf) + selected_sf.inf)
      }
      initResponse.complete_sfvalue = Number((initResponse.complete_sfvalue - previous_sf.complete_sfvalue) + selected_sf.complete_sfvalue)
      initResponse.sf = Number((initResponse.sf - previous_sf.sf) + selected_sf.sf)
      initResponse.sup_fee = Number((initResponse.sup_fee - previous_sf.sup_fee) + selected_sf.sup_fee)
    }
    currenct_sf.push(initResponse)
    }
    return currenct_sf
}

//Updating the Selected Price for Onward and Return
const updateTFPrice=(price)=>{
  let selPrveObj = price[2].filter((obj) => obj.selected);
   for(let org of state.response.origns){
     let tid=price[1];
     let selSecType=price[3];
     if(selSecType==="onward"){
       for(let val of org.srcorigns){
         if(val.tid===tid && selPrveObj!==undefined && selPrveObj[0]!==undefined){//Matching with Source TID
           let total=org.pr.tf - selPrveObj[0].pr.tf;
           let bf=org.pr.bf - selPrveObj[0].pr.bf;
           selPrveObj[0].selected=false;
           org.pr.tf=total + price[0].pr.tf;
           let taxes = checkTaxes(org.pr.tax, selPrveObj[0].pr.tax,"Sub")
           let finalTaxes = checkTaxes(taxes, price[0].pr.tax,"Add");
           let sup1=org.pr.sup_fare.split("_");
           let sup2=price[0].pr.sup_fare.split("_");
           let supBf=Number(sup1[0])+Number(sup2[0]);
           let supTf=Number(sup1[1])+Number(sup2[1]);
           let pr="";
           let sf = price[0].servicefee
           if(state.response.tt==="1"){
             pr={
               "bf":price[0].pr.bf,
               "tf": price[0].pr.tf,
               "tax" : price[0].pr.tax,
               "sup_fare": price[0].pr.sup_fare
             }
             org.servicefee = sf
           }else{
            pr={
               "bf":bf + price[0].pr.bf,
               "tf": total + price[0].pr.tf,
               "tax" : finalTaxes,
               "sup_fare": supBf+"_"+supTf
             }
            //Updating service Fee
            if(org.servicefee.length!==0 && org.servicefee!==undefined){
              org.servicefee = updateServiceFee(sf,org.servicefee,selPrveObj)
            }
           }
           org.pr=pr;
           let paxprList=[];
           for(let obj of org.paxpr){
             for(let obj1 of selPrveObj[0].paxpr){
               if(obj.ptype===obj1.ptype){
               obj.bf=obj.bf-obj1.bf;
               obj.tf=obj.tf-obj1.tf;
               let taxes = checkTaxes(obj.tax, obj1.tax,"Sub")
               obj.tax=taxes;
               }
             }
           }
           for(let pax of org.paxpr){
              for(let pax1 of price[0].paxpr){
                 if(pax.ptype===pax1.ptype){
                   let sup1=pax.psup_fare.split("_");
                   let sup2=pax1.psup_fare.split("_");
                   let supBf=Number(sup1[0])+Number(sup2[0]);
                   let supTf=Number(sup1[1])+Number(sup2[1]);
                   let taxes=[];
                   taxes = checkTaxes(pax.tax, pax1.tax,"Add");
                   let paxpr=""
                   if(state.response.tt==="1"){
                     paxpr={
                       "bf":Number(pax1.bf),
                       "tf": Number(pax1.tf),
                       "tax" : pax1.tax,
                       "psup_fare": pax1.psup_fare,
                       "ptype": pax.ptype
                     }
                     paxprList.push(paxpr);
                   }else{
                     paxpr={
                       "bf":Number(pax.bf) + Number(pax1.bf),
                       "tf": Number(pax.tf) + Number(pax1.tf),
                       "tax" : taxes,
                       "psup_fare": supBf+"_"+supTf,
                       "ptype": pax.ptype
                     }
                     paxprList.push(paxpr);
                   }

                 }
              }
           }
           org.paxpr=_.uniq(paxprList);

           //org.pr=price[0].pr;
           price[0].selected=true;
           for(let fare of val.fareop){
               if(fare.routeId===price[0].routeId){//Matching with ROUTE ID and UPDATING SELECTED ONE
                 fare.selected=true;
               }else{
                 fare.selected=false;
               }
           }
           window.sessionStorage.setItem(OTID,tid)
           dispatch({ type: 'selTFOnwardPrice', payload: price });
           break;
         }
       }
     }else if(selSecType==="arrival"){
       for(let val of org.destorigins){
         if(val.tid===tid){//Matching with Source TID
           let total=org.pr.tf - selPrveObj[0].pr.tf;
           let bf=org.pr.bf - selPrveObj[0].pr.bf;
           selPrveObj[0].selected=false;
           org.pr.tf=total + price[0].pr.tf;
           let taxes = checkTaxes(org.pr.tax, selPrveObj[0].pr.tax,"Sub")
           let finalTaxes = checkTaxes(taxes, price[0].pr.tax,"Add");
           let sup1=org.pr.sup_fare.split("_");
           let sup2=price[0].pr.sup_fare.split("_");
           let supBf=Number(sup1[0])+Number(sup2[0]);
           let supTf=Number(sup1[1])+Number(sup2[1]);
           let pr="";
           let sf = price[0].servicefee
           if(state.response.tt==="1"){
             pr={
               "bf":price[0].pr.bf,
               "tf": price[0].pr.tf,
               "tax" : price[0].pr.tax,
               "sup_fare": price[0].pr.sup_fare
             }
             org.servicefee = sf;
           }else{
            pr={
             "bf":bf + price[0].pr.bf,
             "tf": total + price[0].pr.tf,
             "tax" : finalTaxes,
             "sup_fare": supBf+"_"+supTf,
             "scur":price[0].pr.scur,
             "markupnames":price[0].pr.markupnames
             }
            //Updating service Fee
            if(org.servicefee.length!==0 && org.servicefee!==undefined){
              org.servicefee = updateServiceFee(sf,org.servicefee,selPrveObj)
            }
           }
           org.pr=pr;

           let paxprList=[];
           for(let obj of org.paxpr){
            for(let obj1 of selPrveObj[0].paxpr){
              if(obj.ptype===obj1.ptype){
              obj.bf=obj.bf-obj1.bf;
              obj.tf=obj.tf-obj1.tf;
              let taxes = checkTaxes(obj.tax, obj1.tax,"Sub")
              obj.tax=taxes;
              }
            }
          }
           for(let pax of org.paxpr){
              for(let pax1 of price[0].paxpr){
                 if(pax.ptype===pax1.ptype){
                   let sup1=pax.psup_fare.split("_");
                   let sup2=pax1.psup_fare.split("_");
                   let supBf=Number(sup1[0])+Number(sup2[0]);
                   let supTf=Number(sup1[1])+Number(sup2[1]);
                   let taxes=[];
                   taxes = checkTaxes(pax.tax, pax1.tax,"Add");
                   let paxpr=""
                   if(state.response.tt==="1"){
                     paxpr={
                       "bf":Number(pax1.bf),
                       "tf": Number(pax1.tf),
                       "tax" : pax1.tax,
                       "psup_fare": pax1.psup_fare,
                       "ptype": pax.ptype
                     }
                     paxprList.push(paxpr);
                   }else{
                     paxpr={
                       "bf":Number(pax.bf) + Number(pax1.bf),
                       "tf": Number(pax.tf) + Number(pax1.tf),
                       "tax" : taxes,
                       "psup_fare": supBf+"_"+supTf,
                       "ptype": pax.ptype
                     }
                     paxprList.push(paxpr);
                   }
                 }
              }
           }
           org.paxpr=_.uniq(paxprList);
           //org.pr=price[0].pr;
           price[0].selected=true;
           for(let fare of val.fareop){
               if(fare.routeId===price[0].routeId){//Matching with ROUTE ID and UPDATING SELECTED ONE
                 fare.selected=true;
               }else{
                 fare.selected=false;
               }
           }
           window.sessionStorage.setItem(RTID,tid)
           dispatch({ type: 'selTFReturnPrice', payload: price });
           break;
         }
       }
     }
   }
 }

const updateMarkup = (data) => {
  dispatch({ type: 'markp', payload: data })
  dispatch({type: "markupApplied",payload: true})
  window.sessionStorage.setItem(ONFLY_FLT_MKP,data);
}

const openMarkUp = () => {
  if (state.addMrkup) {
      dispatch({type: "addMrkup",payload: false})
  } else {
      dispatch({type: "addMrkup",payload: true})
  }
}

const updatePrice=(data)=>{
  let respObj=data[0];
  let selIndex=data[1];
  let index=0;
  let allowed=false;
  let allowedDest=false;
  setEnable(false)
  for(let obj of state.response.origns){
    if(!allowed){
      if(obj.srcorigns.length > 1){
        for(let src of obj.srcorigns){
          if(src.tid===respObj[0].srcorigns[0].tid){
            src.fareop=respObj[0].srcorigns[0].fareop;
            allowed=true;
            window.sessionStorage.setItem(OTID,src.tid)
          }
        }
      }else{
        if(obj.srcorigns[0].tid===respObj[0].srcorigns[0].tid){
          obj.srcorigns=respObj[0].srcorigns;
          allowed=true;
        }
      }
      if(allowed){
        obj.paxpr=respObj[0].paxpr;
        obj.pr=respObj[0].pr;
        obj.servicefee=respObj[0].servicefee!==undefined?respObj[0].servicefee:[];
        obj.sup="2";
      }
    }

    if(props.srequest.tt===2){
      if(!allowedDest){
        if(obj.destorigins.length > 1){
          for(let src of obj.destorigins){
            if(src.tid===respObj[0].destorigins[0].tid){
              src.fareop=respObj[0].destorigins[0].fareop;
              allowedDest=true;
              window.sessionStorage.setItem(RTID,src.tid)
            }
          }
        }else{
          if(obj.destorigins[0].tid===respObj[0].destorigins[0].tid){
          obj.destorigins=respObj[0].destorigins;
          allowedDest=true;
          }
        }
        if(allowedDest){
          obj.paxpr=respObj[0].paxpr;
          obj.pr=respObj[0].pr;
          obj.servicefee=respObj[0].servicefee!==undefined?respObj[0].servicefee:[];
          obj.sup="2";
        }
      }
      }
  }
  setEnable(true)

}
//Cal Total Fare
const calculateTotal=(flt)=>{
let total = parseFloat(flt.pr.tf)+parseFloat(calculateGST(flt))+parseFloat(calcuateServiceFee(flt.servicefee))+parseFloat(state.markp);
return Number(total.toFixed(2))
}

const calculateTotalDisc=(flt)=>{
  let total = parseFloat(flt.pr.tf)+parseFloat(calculateGST(flt))+parseFloat(calcuateServiceFee(flt.servicefee))-parseFloat(calculateDisc(flt.pr.tax));
  return Number(total.toFixed(2))
  
  }

let allClear = ()=>{
  dispatch({ type:"isResetSelected",payload:true})
}
  return (
    <>


      <Row>
     {props.srequest.bktype!=="IMPORT/REISSUE" && <Col className="filterLayout">
          {state.response.origns && props.srequest && <Filter topFilter = {state.topFilter} allClear = {allClear}srequest={props.srequest} loaderflight={loaderTrue} triptype={props.triptype} initResp={state.initialresponse} forceRender={state.renderRes} mapping={state.response.mappings} searchRes={state.response} handelChange={handleChangeCallBack} />}
        </Col>}

        <div className={"mrkup " + (state.addMrkup ? 'open':'') } ref={ref} >
        <span onClick={openMarkUp}><span className="ezyIcon markup icon-arrowDown"></span></span>
        {state.addMrkup &&
            <div className={"admarkup " + (state.addMrkup ? 'active':'') }>
            <ApplayMarkup sendMarkup={updateMarkup} markVal={state.markp}/>
            </div>
            }
        </div>
        <Col className="resultSection ">
        <div className='resultpageTopfilter'>
             <ResultpageTopfilter topFilter={topFilter} searchRes={state.response} initResp={state.initialresponse} handelChange={handleChangeCallBack} loaderflight={loaderTrue} isResetSelected={state.isResetSelected}/>
              </div>
          {state.response.origns && props.srequest && props.srequest.bktype!=="IMPORT/REISSUE" && <Sort triptype={props.triptype} searchRes={state.response} initResp={state.initialresponse} filterSorted={state.sortList} handelChange={handleChangeCallBack} sortClear={state.sortClear} resultViewType={viewStyle} srequest = {props.srequest}/>}
          {state.response.origns && state.response.origns.map((flights, index) => (

            <div className={"result listView  boxShadow d-flex flex-column mb-4 " + (newclass === 'column' ? 'columnView' : '')}>



              <>

                <div data-flt={flights.pr.tf} className="rs-top d-flex justify-content-between align-items-center">
                  <div className='d-flex'>
                  <div className="srTagpart">
                    {(index === 0 && state.minPrice === flights.pr.tf) && <div className="rs-cheapest ezyIcon icon-cheapest"> Lowest</div>}
                    {/* {(index === 0 && state.sortType === 'fastest') && <div className="rs-cheapest"><Icon className="cheap mr-2" size={20} icon="fastest" /> Fastest</div>} */}
                    {(index === 0 && state.sortType === 'best') && <div className="rs-cheapest ezyIcon icon-bestValue"> Recommended</div>}
                    {/* One way */}
                    {props.srequest.tt===1 && flights.srcorigns[0] && flights.srcorigns[0].sup!=="0" &&
                    <div className="ltag lcc ezyIcon icon-nFlight"> LCC </div>
                    }
                    {props.srequest.tt===1 && flights.srcorigns[0] && flights.srcorigns[0].sup==="0" &&
                    <div className="ltag fcc ezyIcon icon-nFlight"> FSC </div>
                    }
                    {/* Round Trip */}
                    {props.srequest.tt===2 && (flights.srcorigns!==undefined && flights.srcorigns[0] && flights.srcorigns[0].sup!=="0" || flights.destorigins!==undefined && flights.destorigins[0] && flights.destorigins[0].sup!=="0") &&
                    <div className="ltag lcc ezyIcon icon-nFlight"> LCC </div>
                    }
                    {props.srequest.tt===2 && (flights.srcorigns !== undefined && flights.srcorigns[0] && flights.srcorigns[0].sup==="0" || flights.destorigins!==undefined && flights.destorigins[0] && flights.destorigins[0].sup==="0") &&
                    <div className="ltag fcc ezyIcon icon-nFlight"> FSC </div>
                    }
                  </div>
                  <div>
                  {flights.srcorigns !== undefined && flights.srcorigns[0] && flights.srcorigns[0].sup==="2" && props.srequest.tt===1 &&
                      <div className='infoFarechange'>INFO : Fare may change for the each selected flight </div>
                    }
                    {flights.srcorigns !== undefined && flights.srcorigns[0] && flights.srcorigns[0].sup==="2" && props.srequest.tt===2 &&
                      <div className='infoFarechange'>INFO : Fare may change for the selected flight combination </div>
                    }
                  </div>
                  </div>
                  <div className="rs-price">
                    <div className="blockPrice">
                      {calculateDisc(flights.pr.tax) !==0 &&
                        <div className="pricetag offers"><em>{state.response.cur}</em><strong>{<FormateCurrency value={calculateTotalDisc(flights)} />} </strong></div>
                      }
                      {props.srequest.flowType !== "REISSUE" &&
                        <div className="blockPriceInfo">
                          <div className="pricetag"><em>{state.response.cur}</em><strong>{<FormateCurrency value={calculateTotal(flights)} />}</strong></div>
                          <span>(For {Number(state.response.adt) + Number(state.response.chd) + Number(state.response.inf)} travellers)</span>
                        </div>
                      }
                      {props.srequest.flowType === "REISSUE" && (flights.pr.resdval===undefined || flights.pr.resdval===0) &&
                         <div className="blockPriceInfo">
                          <div className="pricetag"><em>{state.response.cur} </em><strong>{<FormateCurrency value={getTotalFare(flights.paxpr,flights.pr.gtd,calcuateServiceFee(flights.servicefee),calculateGST(flights))} />} </strong></div>
                          <span>(For {Number(state.response.adt) + Number(state.response.chd) + Number(state.response.inf)} travellers)</span>
                        </div>
                      }
                    </div>
                    <Button
                      size="sm"
                      onClick={() => { fireFareRuleReq(flights) }}
                      className="selectFlightBtn ezyIcon"
                      variant="primary">
                      SELECT

                    </Button>
                  </div>
                </div>
                <Row>


                  {(state.response.tt === '1' || state.response.tt === '2') && <ResultBlock sendPriceResp={updatePrice} sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns} trip={state.response.tt} sect={state.response.sec} currency={state.response.cur} secType={'onward'} sectors={flights} fltIndex={index} selectedFlt={selFlight} srequest={props.srequest} flightsSel={[state.srcorigns, state.destorigins]} serviceFee={flights.servicefee} sendTfPrice={updateTFPrice} viewType={newclass} onFlyMkp={0} regionType={state.response.region_type}/>}

                  {flights.destorigins && !filterLoad && <ResultBlock sendPriceResp={updatePrice} sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.destorigins} trip={state.response.tt} sect={state.response.sec} currency={state.response.cur} secType={'arrival'} fltIndex={index} selectedFlt={selFlight} sectors={flights} srequest={props.srequest} flightsSel={[state.srcorigns, state.destorigins]} serviceFee={flights.servicefee} sendTfPrice={updateTFPrice} viewType={newclass} onFlyMkp={0} regionType={state.response.region_type}/>}

                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 0  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 1 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 1 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-1'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 1  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 2 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 2 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-2'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 2  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 3 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 3 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-3'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 3  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 4 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 4 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-4'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 4  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 5 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 5 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-5'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                  {(parseInt(state.response.tt) === 3 && state.response.sec.length > 5  && !filterLoad ) && <ResultBlock sty={'normal'} impTag={props.impTag} mapping={state.response.mappings} searchRes={flights.srcorigns.filter((flt) => { return flt.ref === 6 })} trip={state.response.tt} sect={state.response.sec.filter((sect) => { return sect.leg === 5 })} currency={state.response.cur} secType={'onward'} secTypeIndex={'Sector-6'} fltIndex={index} srequest={props.srequest} sectors={flights} multiFlt={selMultiFlight} serviceFee={flights.servicefee} mulflights={[state.multiOrgOne, state.multiOrgTwo, state.multiOrgThre, state.multiOrgFour, state.multiOrgFive, state.multiOrgSix]} onFlyMkp={0} regionType={state.response.region_type}/>}
                </Row>
                {/* <Button
                  onClick={showMoreFlightsColView}
                  size="sm"
                  className="d-flex align-items-center moreResult colMore"
                  variant="outline-secondary">
                  + More flight options

                                      </Button> */}



              </>


            </div>
          ))}

         {state.showPop &&
         <div className="filterNoResultFound">
          <ResultsNotFound  filterChange={true} product={"Flight"}/>
          </div>
          }

        </Col>

      </Row>

      { state.notiMessageShow && (
        <Alert className="notification" variant={state.notiVarient} onClose={() => dispatch({ type: "notiMessageShow", payload: false })} dismissible> {state.notiMessage}</Alert>
      )}

  <Modal
        show={state.showPopReissue}
        onHide={handleCLClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="unableBookpr text-center">
              <strong>We are Unable to process this booking.</strong>
              <p>Please Contact support team For The Ticket Issuance</p>
              <div className='d-flex'>
              <a id="phone" className="navbar-brand ezyIcon icon-contactUs" href="tel:+97444798899"> +974 44798899</a>
              <a id="mail" href="mailto:support@ezytrip.com" className="navbar-brand ezyIcon icon-new-email"><span className='ml-2'>support@ezytrip.com</span></a></div>
               </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-dark" onClick={handleCLClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.showFrePopup}
        onHide={handleFareClose}
        className="importPNRMdl selectSamefare"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
        <div className="popUplayout center">
            <div className='d-flex contFareopt'>
            <div className="ezyIcon icon-alertname"></div>

              <p> Invalid Fare Combination: Your Onward selection is <span className='fareType'>{state.onward_fare}</span> and Return is <span className='fareType'>{state.return_fare}</span> You must choose the same fare type</p>

               </div>
          </div>
          </Modal.Body>
         </Modal>

    </>
  )
}

export default ResultSection;
