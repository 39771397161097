import axios from 'axios'

const REACT_APP_B2B = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the VISA CURD Operations for Exchange rate
 * @param {*} Service 
 */

class VisaService {
   
    async saveConfiguration(data) {
        return await axios.post(`${REACT_APP_B2B}/att/visa/create`,data)
    }
    async updateConfiguration(data) {
        return await axios.post(`${REACT_APP_B2B}/att/visa/update`,data)
    }
    async retriveVisaDetails(data) {
        return await axios.post(`${REACT_APP_B2B}/att/visa/retrieve`,data)
    }
    async getVisaConfiguration(id) {
        return await axios.get(`${REACT_APP_B2B}/att/visa/edit/${id}`)
    }
    async deleteDocs(data) {
        let dddd = {
            "ids": [
                data.ids[0]
            ]
        }
        return await axios.delete(`${REACT_APP_B2B}/att/visa/delete/docs`,{ data: dddd })
    }
    async deletePax(data) {
        
        let dddd = {
            "ids": [
                data.ids[0]
            ]
        }
        return await axios.delete(`${REACT_APP_B2B}/att/visa/delete/pax`,{ data: dddd })
    }
    }
export default new VisaService()
