import React from 'react';
import { Alert, Button, Col, Form, Row, Table, } from 'react-bootstrap';
import { deleteRemainder,closeStatus} from '../../dashBoard/operations';

const DeletePopup = (props) => {
    const {show, close, itemName} = props;

    const handleRemainder=()=>{
        if(props.remainder_Id!==undefined){
            const payload={
                "remai_id":props.remainder_Id,
                "upby":props.upby
              }
            if(show.deleteShow){
                deleteRemainder(payload).then(response => {
                    if (response.suc) {
                      props.sendMessage("Delete"); 
                    }else{
                      props.sendMessage("Failed");
                    }
                })
            }else{
                closeStatus(payload).then(response => {
                    if (response.suc) {
                      props.sendMessage("Close");
                    }else{
                      props.sendMessage("Failed");
                    }
                })
            }
        }
       }


    return (
        (show.deleteShow || show.statusShow) && 
            <div className="Deletepopup">
            <div className="addRmPopUp">
                <div className="addRmPopUpInner">
                    <h3 className="d-flex justify-content-between"><span>{show.deleteShow ? "Delete Reminder" : "Status Update"} </span> <span className="colose ezyIcon icon-close" onClick={close}></span></h3>
                    {show.deleteShow ? 
                    <p> {`Are you sure, you want to delete "${itemName}" ?`}</p> :
                    <p> {`Are you sure, you want to Close "${itemName}" Reminder ?`}</p>
                    }
                    <div className="btn_div">
                    <Button size="md" className="btn_yes" onClick={handleRemainder} variant="primary">Yes</Button>
                    <Button size="md" className="btn_no" variant="primary" onClick={close}>No</Button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default DeletePopup;


