import React, { useState, useEffect, useReducer } from 'react';
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import log from "loglevel";
import Button from '../../common/buttons/Button'
import Select from 'react-dropdown-select';
import Utilities from '../../commonUtils/Utilities';

/**
 * @description:This function will creaet all the routes
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 09-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  appon: "Per Booking", mrkon: "", mvalue: "", discVal: "", passengerType: true, percentage: "", value: "", paxtype: "", mtyp: [], markupOn: [], showmsg: false,mkp_id:0
};

let markupArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    case 'clear':
      return {
        ...state, appon: "Per Booking", mrkon: "", mvalue: "", discVal: "", passengerType: true, percentage: "", value: "", paxtype: "", mtyp: [], markupOn: [], showmsg: false
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ApplicableDates(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [markups, setMarkups] = useState({ showMarkups: [] })
  const [enablePerBooking, setEnablePerBooking] = useState(true)
  const [enablePerNight, setEnablePerNight] = useState(false)
  const [enablePerLT, setEnablePerLT] = useState(false)
  const [disableButton, setDisableButton] = useState(false);
  const [data, setDataLS] = useState({ LS: []});

  useEffect(() => {
    setDataLS({ LS: [{ fm: 0, to: 0, val: 0, disc: 0}] })
  }, []);
  const paxTypes = [{ label: "All", value: "All" },
  { label: "Adult", value: "ADT" }, { label: "Child", value: "CHD" }, { label: "Infant", value: "INF" }]

  const markupOnTypes = [{ label: "Base Fare", value: "BF" },
  { label: "Total Fare", value: "TF" }, { label: "Base Fare+YQ", value: "YQ" }, { label: "Base Fare+YR", value: "YR" }]

  const markupHOnTypes = [{ label: "Base Fare", value: "BF" },
  { label: "Total Fare", value: "TF" }]


  const markupTypes = [{ label: "Percentage", value: "Percentage" },
  { label: "Value", value: "Value" }]


  const handleAddLS = () => {
    setDataLS({
        LS: data.LS.concat([{ fm: 0, to: 0, val: 0, disc: 0}])
    });
  };
  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      markupArray = []
      setMarkups({ showMarkups: [] })
      setEnablePerBooking(true)
      setDisableButton(false)
    }
  }, [props.reset])
  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);

  useEffect(() => {
    if (props.configType !== "") {
      markupArray = []
    }
  }, [props.configType]);


  useEffect(() => {
    if (props.editPaxData !== undefined) {
      if (props.editPaxData.applon) {
        if (props.editPaxData.applon === "Per Booking") {
          dispatch({ type: 'appon', payload: "Per Booking" })
          setEnablePerBooking(true);
          setEnablePerNight(false)
          setEnablePerLT(false)
        } else if((props.editPaxData.applon === "Per Night")){
            setEnablePerBooking(false);
            setEnablePerNight(true)
            setEnablePerLT(false)
          dispatch({ type: 'appon', payload: "Per Night" })
        } else {
            setEnablePerBooking(false);
            setEnablePerNight(false)
            setEnablePerLT(true)
            dispatch({ type: 'appon', payload: "Longest Stay" })
        }
      }
      let pax = [];
      for (let val of props.editPaxData.applist) {

    if(val.ls == undefined || val.ls.length === 0) {
        const ap = {
          "id": val.id,
          "appon": val.applied_on,
          "mtype": val.markup_type,
          "val": val.markup_value,
          "disc": val.disc_value
        }
        pax.push(ap);
        props.sendPaxData([props.editPaxData.applon, ap]);
    } else {
        let ls = []
        if(val.ls) {
            for(let o of val.ls) {
                let obj = {
                    "id" : o.id,
                    "nights": o.nights_count,
                      "val": o.markup_value,
                      "disc": o.disc_value
                }
                ls.push(obj)
            }
        }
        dispatch({type:"mkp_id",payload: val.id})
        const ap = {
            "id": val.id,
            "appon": val.applied_on,
            "mtype": val.markup_type,
            "val": val.markup_value,
            "disc": val.disc_value,
            "ls": ls
          }
          pax.push(ap);
        props.sendPaxData([props.editPaxData.applon, ap]);
        }
      }
      setMarkups({ showMarkups: pax });
      // props.sendPaxData([props.editPaxData.applon,pax]);
    }
  }, [props.editPaxData]);


  const handlePaxMarkup = () => {
    if (validateMarkup()) {
      var paxType = "";
      if (state.paxtype !== undefined && state.paxtype[0] !== undefined && state.paxtype[0].value !== undefined) {
        paxType = state.paxtype[0].value;
      }
      if(state.appon === "Longest Stay") {
          let validateLS = data.LS[data.LS.length - 1];
          if(validateLS.fm !== 0 && validateLS.to !== 0 && validateLS.val !== 0) {
              let ls = []
              for (let o of data.LS) {
                  let obj = {
                      "nights": o.fm+"-"+o.to,
                      "val": o.val,
                      "disc": o.disc
                  }
                  ls.push(obj)
              }
      const markup = {
        "appon": state.markupOn[0].value,
        "mtype": state.mtyp[0].value,
        "val": state.mvalue,
        "disc": state.discVal,
        "ls":ls
      }
      setMarkups({ showMarkups: [...markups.showMarkups, markup] });
      props.sendPaxData([state.appon, markup]);
      dispatch({ type: 'markupOn', payload: [] })
      dispatch({ type: 'mtyp', payload: [] })
      dispatch({ type: 'mvalue', payload: "" })
      dispatch({ type: 'discVal', payload: "" })
    } else {
        dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter All  Mandatory Details of  Stay ' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
    } else {
        const markup = {
            "appon": state.markupOn[0].value,
            "mtype": state.mtyp[0].value,
            "val": state.mvalue,
            "disc": state.discVal
          }
          setMarkups({ showMarkups: [...markups.showMarkups, markup] });
          props.sendPaxData([state.appon, markup]);
          dispatch({ type: 'markupOn', payload: [] })
          dispatch({ type: 'mtyp', payload: [] })
          dispatch({ type: 'mvalue', payload: "" })
          dispatch({ type: 'discVal', payload: "" })
    }
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateMarkup = () => {
    if (state.mvalue === "" && state.appon !== "Longest Stay") {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(props.configType == "Markup") {
      dispatch({ type: 'notiMessage', payload: 'Please enter Markup Value' })
      } else {
        dispatch({ type: 'notiMessage', payload: 'Please enter ServiceFee Value' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    } else if (state.mtyp.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(props.configType == "Markup") {
      dispatch({ type: 'notiMessage', payload: 'Please select Markup Type' })
      } else {
        dispatch({ type: 'notiMessage', payload: 'Please select ServiceFee Type' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    } else if (state.markupOn.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Markup On' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    }
    if (state.appon === "Per Booking" || state.appon === "Longest Stay" || state.appon === "Per Night") {
      if (markups.showMarkups.length != 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Applicable on Already Defined' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false
      }
    }
    if(state.appon === "Longest Stay" && data.LS.length === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter atleast One Stay Details' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    }
    if (state.mtyp[0].value) {
      var json = "";
      if (state.appon === "Per Ticket") {
        json = {
          "paxtyp": state.paxtype[0].value,
          "appon": state.markupOn[0].value,
        }
      } else {
        json = {
          "appon": state.markupOn[0].value,
          "mtype": state.mtyp[0].value,
        }
      }
      markupArray.push(json);
      let data = Utilities.getUniqData(markupArray);
      if (data && state.appon === "Per Ticket") {
        var pax = data.map(q => q.paxtyp !== "" && q.paxtyp !== "All");
        if (pax.length >= 2) {
          for (let val of pax) {
            // if (!val) {
            //   dispatch({ type: 'notiMessageShow', payload: true })
            //   dispatch({ type: 'notiMessage', payload: 'Passenger Type Already Exists' })
            //   dispatch({ type: 'notiVarient', payload: 'danger' })
            //   return false;
            // }
          }
        }
      }
      if (markupArray.length !== data.length) {
        markupArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Passenger Type Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }

    return true;
  }



  const handleRemoveMarkup = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      props.sendAppMarkupId(obj.id);
      let afterdeleteArr = markups.showMarkups.filter((s, sidx) => idx !== sidx)
      setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => s.id !== obj.id) });
      props.updateData([state.appon, afterdeleteArr]);
    } else {
      setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => idx !== sidx) });
      let markupList = markupArray;
      markupArray = [];
      if (state.appon === "Per Night") {
        markupArray.push(markupList.filter(item => item.appon !== undefined && item.mtype !== obj.mtype && item.appon !== obj.appon));
        props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => idx !== sidx)]);
      } else {
        markupArray.push(markupList.filter(item => item.appon !== undefined && item.mtype !== obj.mtype && item.appon !== obj.appon));
        props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => idx !== sidx)]);
      }
    }
  };

  const handleLSRemove = (obj,idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
        if(markups.showMarkups[0].ls.length > 1) {
            props.sendLSid(obj.id)

        let afterdeleteArr = markups.showMarkups[0].ls.filter((s, sidx) => idx !== sidx)
        markups.showMarkups[0].ls = afterdeleteArr
        setMarkups({ showMarkups: markups.showMarkups});
      props.updateData([state.appon, markups.showMarkups]);
        } else {
            if(state.mkp_id !== 0) {
            props.sendAppMarkupId(state.mkp_id);
            setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => s.id !== state.mkp_id) });
            props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => s.id !== state.mkp_id)]);
            dispatch({type:"mkp_id",payload:0})
            }
        }
    } else {
        if(markups.showMarkups[0].ls.length > 1) {
            //if new ls deleting where ls length is greater than 0
            let afterdeleteArr = markups.showMarkups[0].ls.filter((s, sidx) => idx !== sidx)
            markups.showMarkups[0].ls = afterdeleteArr
           setMarkups({ showMarkups: markups.showMarkups});
           props.updateData([state.appon, markups.showMarkups]);
        } else {
            if(state.mkp_id !== 0) {
                //old data deleting new ls which was the only one
                props.sendAppMarkupId(state.mkp_id);
                setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => s.id !== state.mkp_id) });
                props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => s.id !== state.mkp_id)]);
                } else {
                    //after deleting inserting new ls data then deleting
                    setMarkups({ showMarkups: [] });
                    props.updateData([state.appon, []]);
                }
        }
    }
  }

  const handlePerChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }


  const handleOnChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }
  const handleMarPType = () => {
    dispatch({ type: 'percentage', payload: "" })
    dispatch({ type: 'enablePercen', payload: false })
  };

//   const perTicket = (e) => {
//     dispatch({ type: 'passengerType', payload: true })
//     dispatch({ type: 'appon', payload: "Per Ticket" })
//     dispatch({ type: 'mvalue', payload: "" })
//     dispatch({ type: 'discVal', payload: "" })
//     dispatch({ type: 'markupOn', payload: [] })
//     dispatch({ type: 'mtyp', payload: [] })
//     setMarkups({ showMarkups: [] })
//     setEnablePerTicket(true);
//     markupArray = [];
//     props.updateData(["Per Booking", []]);
//   }
  const perBooking = (e) => {
    setEnablePerBooking(true);
    setEnablePerNight(false)
    setEnablePerLT(false)
    dispatch({ type: 'passengerType', payload: false })
    dispatch({ type: 'appon', payload: "Per Booking" })
    dispatch({ type: 'markupOn', payload: [] })
    dispatch({ type: 'mtyp', payload: [] })
    dispatch({ type: 'mvalue', payload: "" })
    dispatch({ type: 'discVal', payload: "" })
    dispatch({ type: 'paxtype', payload: "" })
    setMarkups({ showMarkups: [] })
    markupArray = [];
    props.updateData(["Per Booking", []]);
    setDataLS({ LS: [{ fm: 0, to: 0, val: 0, disc: 0}] })
  }
  const perNight = (e) => {
    setEnablePerBooking(false);
    setEnablePerNight(true)
    setEnablePerLT(false)
    dispatch({ type: 'passengerType', payload: true })
    dispatch({ type: 'appon', payload: "Per Night" })
    dispatch({ type: 'markupOn', payload: [] })
    dispatch({ type: 'mtyp', payload: [] })
    dispatch({ type: 'mvalue', payload: "" })
    dispatch({ type: 'discVal', payload: "" })
    dispatch({ type: 'paxtype', payload: "" })
    setMarkups({ showMarkups: [] })
    markupArray = [];
    props.updateData(["Per Night", []]);
    setDataLS({ LS: [{ fm: 0, to: 0, val: 0, disc: 0}] })
  }
  const perLT = (e) => {
    setEnablePerBooking(false);
    setEnablePerNight(false)
    setEnablePerLT(true)
    dispatch({ type: 'passengerType', payload: false })
    dispatch({ type: 'appon', payload: "Longest Stay" })
    dispatch({ type: 'markupOn', payload: [] })
    dispatch({ type: 'mtyp', payload: [] })
    dispatch({ type: 'mvalue', payload: "" })
    dispatch({ type: 'discVal', payload: "" })
    dispatch({ type: 'paxtype', payload: "" })
    setMarkups({ showMarkups: [] })
    markupArray = [];
    props.updateData(["Longest Stay", []]);
  }

  const handleRemoveLS = idx => () => {
    setDataLS({
      LS: data.LS.filter((s, sidx) => idx !== sidx)
    });
  };

  const handleLSChange = idx => evt => {
    try {
      let newContacts = data.LS.map((obj, sidx) => {
        if (idx !== sidx) return obj;
        else {
          return { ...obj, [evt.target.id]: evt.target.value };
        }
      });
      setDataLS({ LS: newContacts })
    } catch (err) {
      log.error('Exception occured in LS function---' + err);
    }
  }
  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }

      <Form.Row>
        <Form.Group as={Col} controlId="appon" className="inputCheck">
          <div className="d-flex align-items-center">
            <Form.Check type="radio" disabled={props.editPaxData && markups.showMarkups.length > 0} onChange={perBooking} checked={enablePerBooking} value={state.appon} label="Per Booking" className="ml-0" name="ApplicableOn" id="perBooking" custom />
            <Form.Check type="radio" disabled={props.editPaxData && markups.showMarkups.length > 0} onChange={perNight} checked={enablePerNight} value={state.appon} label="Per Night" className="ml-3" name="ApplicableOn" id="perNight" custom />
            {/* <Form.Check type="radio" disabled={props.editPaxData && markups.showMarkups.length > 0} onChange={perLT} checked={enablePerLT} value={state.appon} label="Longest Stay" className="ml-3" name="ApplicableOn" id="perLT" custom /> */}
          </div>
          <Form.Label>Applicable On <sup>*</sup></Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row>

        <Form.Group as={Col} xs={3} className="selectplugin" controlId="markupOn">
          <Select
            options={props.type !== undefined ? markupHOnTypes : markupOnTypes}
            searchable={false}
            values={state.markupOn}
            onChange={(value) => (dispatch({ type: 'markupOn', payload: value }))}
          />
          {props.configType == "Markup" ? (<Form.Label>Mark-up On<sup>*</sup></Form.Label>) : (<Form.Label>Service Fee On<sup>*</sup></Form.Label>)}

        </Form.Group>

        <Form.Group as={Col} xs={3} className="selectplugin" controlId="mtyp">
          <Select
            options={markupTypes}
            searchable={false}
            values={state.mtyp}
            onChange={(value) => (dispatch({ type: 'mtyp', payload: value }))}
          />
          {props.configType == "Markup" ? (<Form.Label>Markup Type<sup>*</sup></Form.Label>) : (<Form.Label>ServiceFee Type<sup>*</sup></Form.Label>)}

        </Form.Group>
        { state.appon !== "Longest Stay" &&
        <Form.Group as={Col} xs={3} controlId="mvalue" className="inputCheck" className="d-flex align-items-center">
          <Form.Control type="text" placeholder= {props.configType == "Markup" ? "Markup":"Value"} value={state.mvalue} onChange={handleOnChange} />
          {props.configType == "Markup" ? (<Form.Label>Markup<sup>*</sup></Form.Label>) : (<Form.Label>Value<sup>*</sup></Form.Label>)}

        </Form.Group>
       }
        {props.configType == "Markup" && state.appon !== "Longest Stay" &&
          <Form.Group as={Col} controlId="discVal" className="inputCheck" className="d-flex align-items-center mr-4">
            <Form.Control type="text" placeholder="Discount" value={state.discVal} onChange={handleOnChange} />
            <Form.Label>Discount </Form.Label>
          </Form.Group>}
          </Form.Row>
          <Row className={state.appon !== "Longest Stay" ? 'justify-content-end':''}>
        {state.appon === "Longest Stay" &&
        <>


        {
            data.LS.map((obj,index) => (
                <React.Fragment key={index}>
                  <Col xs={8}>
                    <Form.Row>
                    <Form.Group as={Col} xs={3} controlId="fm">
                  <Form.Control type="number" max={30} placeholder="From"  onChange={handleLSChange(index)} />
                  <Form.Label>From Night<sup>*</sup></Form.Label>

                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="to">
                  <Form.Control type="number" max={30} placeholder="To"  onChange={handleLSChange(index)} />
                  <Form.Label>To Night<sup>*</sup></Form.Label>

                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="val">
                  <Form.Control type="number" placeholder="Value"   onChange={handleLSChange(index)}/>
                  <Form.Label>Value<sup>*</sup></Form.Label>

                </Form.Group>
                { props.configType == "Markup" &&
                <Form.Group as={Col} xs={2} controlId="disc">
                  <Form.Control type="number" placeholder="Discount"  onChange={handleLSChange(index)}/>
                  <Form.Label>Discount</Form.Label>

                </Form.Group>
                  }
                  {(index > 0) ? (
                  <Col xs={1} className="d-flex justify-content-end pr-0 mt-2">
                    <Button
                      size="xs"
                      variant="link"
                      className="dlt iconBtn mt-1 ezyIcon icon-delete"
                      onClick={handleRemoveLS(index)}
                    //   loading={dataInfo.loadEdit}
                      type="submit">
                    </Button></Col>
                ) : ''}
                 </Form.Row>
                 </Col>
                </React.Fragment>
            ))
        }
         <Col className="w30 mt-1 p-0"><Button
          size="xs"
          variant="link"
          className="iconBtn add ezyIcon icon-plus align-self-end  mt-1 mb-4-3"
          onClick={handleAddLS}
          type="submit"
        ></Button></Col>

        </>

        }

        <Col  xs={1}>
          {!disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              className="mb-4"
              onClick={handlePaxMarkup}
            > Add</Button>
          }
          {disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              isDisabled={true}
            > Add</Button>
          }
        </Col>
        </Row>

      {markups.showMarkups && markups.showMarkups.length !== 0 && state.appon !== "Longest Stay" &&
            <div className="selectedRout tableLayout mb-4">
              <Table>
                <thead>
                  <tr>
                    {props.configType == "Markup" ? (<th>Markup On</th>) : (<th>ServiceFee On</th>)}
                    {props.configType == "Markup" ? (<th>Markup Type</th>) : (<th>ServiceFee type</th>)}
                    {props.configType == "Markup" ? (<th>Markup Value</th>) : (<th>ServiceFee Value</th>)}
                    {state.disc !== "" && props.configType == "Markup" &&
                      <th>Discount Value</th>
                    }
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {markups.showMarkups.map((markup, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          {markup.appon === "BF" &&
                            <span>Base Fare</span>
                          }
                          {markup.appon === "TF" &&
                            <span>TotalFare</span>
                          }
                          {markup.appon !== "TF" && markup.appon !== "BF" &&
                            <span>{markup.appon}</span>
                          }
                        </td>
                        <td>
                          <span>{markup.mtype}</span>
                        </td>
                        <td>
                          <span>{markup.val}</span>
                        </td>
                        {state.disc !== "" && props.configType == "Markup" &&
                          <td>
                            <span>{markup.disc}</span>
                          </td>
                        }
                        <td>
                          <Button
                            size="xs"
                            isDisabled={(props.disabledBtn) ? true : false}
                            onClick={handleRemoveMarkup(markup, index)}
                           variant="link" className="ezyIcon icon-delete">
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
      }

{markups.showMarkups && markups.showMarkups.length !== 0 && state.appon === "Longest Stay" &&  markups.showMarkups[0].ls !== undefined && markups.showMarkups[0].ls.length !== 0 &&
            <div className="selectedRout tableLayout mb-4">
              <Table>
                <thead>
                  <tr>
                    {props.configType == "Markup" ? (<th>Markup On</th>) : (<th>ServiceFee On</th>)}
                    {props.configType == "Markup" ? (<th>Markup Type</th>) : (<th>ServiceFee type</th>)}
                    <th>Nights</th>
                    {props.configType == "Markup" ? (<th>Markup Value</th>) : (<th>ServiceFee Value</th>)}
                    {state.disc !== "" && props.configType == "Markup" &&
                      <th>Discount Value</th>
                    }
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {markups.showMarkups[0].ls.map((markup, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          {markups.showMarkups[0].appon === "BF" &&
                            <span>Base Fare</span>
                          }
                          {markups.showMarkups[0].appon === "TF" &&
                            <span>TotalFare</span>
                          }
                          {markups.showMarkups[0].appon !== "TF" && markups.showMarkups[0].appon !== "BF" &&
                            <span>{markup.appon}</span>
                          }
                        </td>
                        <td>
                          <span>{markups.showMarkups[0].mtype}</span>
                        </td>
                        <td>
                          <span>{markup.nights}</span>
                        </td>
                        <td>
                          <span>{markup.val}</span>
                        </td>
                        {state.disc !== "" && props.configType == "Markup" &&
                          <td>
                            <span>{markup.disc}</span>
                          </td>
                        }
                        <td>
                          {!props.disabledBtn &&
                          <Button
                            size="xs"
                            isDisabled={(props.disabledBtn) ? true : false}
                            onClick={handleLSRemove(markup, index)}
                           variant="link" className="ezyIcon icon-delete">
                          </Button>
}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
      }



      {state.enablePercen &&
        <Form.Row>
          <Form.Group as={Col} xs={3} controlId="mtyp">
            <Form.Control as="select">
              <option value="Percentage">Percentage</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="per" className="d-flex align-items-center">
            <Form.Control type="text" placeholder="Enter Percentage" value={state.percentage} onChange={handlePerChange} />
            <Form.Label>Enter Value</Form.Label>
          </Form.Group>
          <Col>
            <Button
              size="xs"
              variant="link"
              onClick={handleMarPType}
              className="iconBtn add ezyIcon icon-delete align-self-end mb-4"
            ></Button>
          </Col>
        </Form.Row>
      }

    </>
  )
}
export default ApplicableDates