import React, { useState,useContext } from 'react';
import { Table } from "react-bootstrap";
import {Context} from '../../../../../App'
import FormateCurrency from '../../../commonUtils/FormateCurrency';


/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function HotelRefundDetails(props) {
    const [context, setContext] = useContext(Context);

    return (
        <div className="recordInfo">
            {/* FOR EXPEDIA */}
            {props.supplier==0 && props.refundData.length !== 0 && props.refundData !== undefined &&
                <Table className="m-0">
                    <thead>
                        <tr>
                            <th>Room</th>
                            <th>Base Fare</th>
                            <th>Taxes</th>
                            <th>Markup</th>
                            <th>Service Fee</th>
                            <th className="canclInfo">Discount</th>
                            <th className="canclInfo">Supplier Fee</th>
                            <th className="canclInfo">Supplier Charges</th>
                            <th className="canclInfo">Refund Markup</th>
                            <th className="canclInfo">Refund Fee</th>
                            {props.type === "ATT" &&
                            <th className="canclInfo">Commission Discount</th>
                            }
                            <th>Sub Total</th>
                            <th>Refund from Supplier</th>
                            {props.type === "ATT" &&
                                <th>Refund to TA</th>
                            }
                            {props.type === "TA" &&
                             <>
                             {context.logindata.butype == "sta_user" ? (
                                 <th>Refund to STA</th>
                             ) : <th>Refund to Customer</th>}
                                 </>
                            }
                            {props.type === "STA" &&
                                <th>Refund to Customer</th>
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {props.refundData[0].rooms.map((pr, idx) => (
                            <tr>
                                <td>
                                    <span>{pr.rno}: {pr.rnm}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.bf} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.bkmarkup} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.bkservice} />}</span>
                                </td>
                                {props.type === "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.mta_disc} />}</span>
                                </td>
                                }
                                {props.type !== "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.ta_disc} />}</span>
                                </td>
                                }
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.supfee} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.cancharges} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.canmarkup} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.canservice} />} </span>
                                </td>
                                {props.type === "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.com_disc} />} </span>
                                </td>
                                }
                                <td>
                                    <span>{<FormateCurrency value={pr.subtotal} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.reffrmusr} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.subtotal} />}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            }
            {/* FOR OTILLA */}
            {props.supplier==1 && props.refundData.length !== 0 && props.refundData !== undefined &&
                <>
                Rooms Info:
                <Table className="m-0 Otilaroomavb">
                   <tbody>
                    <tr>
                                <td>
                        {props.refundData[0].rooms.map((pr, idx) => (

                                    <span>{pr.rno}: {pr.rnm}</span>

                        ))}
                             </td>
                            </tr>
                    </tbody>
                </Table>
                Price Info :
                <Table className="m-0">
                    <thead>
                        <tr>
                            <th>Base Fare</th>
                            <th>Taxes</th>
                            <th>Markup</th>
                            <th>Service Fee</th>
                            <th className="canclInfo">Discount</th>
                            <th className="canclInfo">Supplier Fee</th>
                            <th className="canclInfo">Supplier Charges</th>
                            <th className="canclInfo">Refund Markup</th>
                            <th className="canclInfo">Refund Fee</th>
                            {props.type === "ATT" &&
                            <th className="canclInfo">Commission Discount</th>
                            }
                            <th>Sub Total</th>
                            <th>Refund from Supplier</th>
                            {props.type === "ATT" &&
                                <th>Refund to TA</th>
                            }
                            {props.type === "TA" &&
                             <>
                             {context.logindata.butype == "sta_user" ? (
                                 <th>Refund to STA</th>
                             ) : <th>Refund to Customer</th>}
                                 </>
                            }
                            {props.type === "STA" &&
                                <th>Refund to Customer</th>
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {props.refundData[0].otillaRefundDetails.map((pr, idx) => (
                            <tr>
                                <td>
                                    <span>{<FormateCurrency value={pr.bf} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.bkmarkup} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.bkservice} />}</span>
                                </td>
                                {props.type === "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.mta_disc} />}</span>
                                </td>
                                }
                                {props.type !== "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.ta_disc} />}</span>
                                </td>
                                }
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.supfee} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.cancharges} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.canmarkup} />}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.canservice} />} </span>
                                </td>
                                {props.type === "ATT" &&
                                <td className="canclInfo">
                                    <span>{<FormateCurrency value={pr.com_disc} />} </span>
                                </td>
                                }
                                <td>
                                    <span>{<FormateCurrency value={pr.subtotal} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.reffrmusr} />}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pr.subtotal} />}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </>
            }
        </div>
    )
}
export default HotelRefundDetails