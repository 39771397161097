import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import { characterNumberValidation, characterValidation, emailValidation } from '../../../commonUtils/FormValidations';
import BranchCreation from './index';
import { deletion, edit, getAllBranches, searchBranch } from "./operations";
export const BranchContext = React.createContext();


/**
 * Initial State Declaration
 */
const initialState = {
  res: [], updateBranch: false, createBranch: false, showCreateBtn: true, branchName: "", branchCode: "",
  branchEmail: "", userType: "", userId: "", enableView: false, nameError: false, emailError: false, bcError: false
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editCompany':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will Load the all companies from service
 * @author: Lakshmi
 * @param {*}
 * @function Branch
 */
function BranchMain() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, setData] = useState()
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [create, setCreate] = useState(false)

  //This fnction will call when component will load
  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "" && context.logindata.roles !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      getAllBranches(context.logindata.uid, context.logindata.utype, context.logindata.is_admin, context.logindata.region_type).then((res) => {
        if (res && res.suc) {
          loadAllBranches(res.res);
        }
      });
      setIsLoadingMore(false);
    }

  }, [context.logindata]);

  // Load All the Branches for Data Table
  const loadAllBranches = (res) => {
    let data = [];
    var enableWEdit = false;
    var enableDel = false;
    if (context.logindata.roles.includes('B_C_U')) {
      enableWEdit = true;
    }
    if (context.logindata.roles.includes('B_C_D')) {
      enableDel = true;
    }
    for (let value of res) {
      if (context.logindata.is_admin==1 || context.logindata.region_type==value.cntnm){
      data.push({cntnm:value.cntnm, bname: value.bn, currency: value.cur, country: value.cntnm, city: value.ctynm, status: (value.act) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editBranch(value, "view")}></a> {enableWEdit && <a className="ezyIcon icon-edit" onClick={editBranch(value, "edit")}></a>} </span> </> })
    }
  }
    dispatch({ type: 'res', payload: data })
    setIsLoadingMore(false)
  }

  //to add delete icon please add this ---{enableDel && <a onClick={confirmDlg(value)}><Icon className="edit ml-1 mr-1" color="#475F7B" size={15} icon="delete" /></a>} in above code
  //Confirmation dialog popup to delete the Branch
  const confirmDlg = (obj) => () => {
    confirmBranchDlg(obj, 'Branch', function () {
      deleteBranch(obj);
    });
  };

  // Delete Branch
  const deleteBranch = (obj) => () => {
    const deleteBy = { delby: context.logindata.uid }
    deletion(obj, deleteBy).then((deleteRes) => {
      var array = [...state.res]; // make a separate copy of the array
      var index = array.indexOf(obj)
      if (index !== -1) {
        array.splice(index, 1);
        dispatch({ type: 'res', payload: array })
      }
      dispatch({ type: 'showMsg', payload: true })
      dispatch({ type: 'messages', payload: ['Deleted Succ'] })
    });
  }

  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }

  const columns = [
    {
      dataField: 'cntnm',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
  {
    dataField: 'bname',
    text: 'Branch Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'country',
    text: 'Country',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'city',
    text: 'City',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  // Edit Branch
  const editBranch = (obj, type) => () => {
    edit(obj).then((editObj) => {
      if (editObj.suc) {
        setData(editObj)
        dispatch({ type: 'updateBranch', payload: true })
        dispatch({ type: 'createBranch', payload: false })
        dispatch({ type: 'showCreateBtn', payload: false })
        if (type === "view") {
          dispatch({ type: 'enableView', payload: true })
        } else {
          dispatch({ type: 'enableView', payload: false })
        }
      }
    })
  }
  const createCompBtnClick = (e) => {
    dispatch({ type: 'createBranch', payload: true })
    dispatch({ type: 'updateBranch', payload: false })
    dispatch({ type: 'enableView', payload: false })
    setData()
    dispatch({ type: 'showCreateBtn', payload: false })
  }

  const handleSearch = () => {
    if (state.branchName !== "" || state.branchCode !== "" || state.branchEmail !== "") {
      let branchnamevalidations = false
      let emailvalidations = false
      let bcValidations = false
      //branch name validations
      if (state.branchName.length > 0) {
        if (state.branchName && characterValidation(state.branchName)) {
          branchnamevalidations = true
          dispatch({ type: "nameError", payload: false })
        } else {
          dispatch({ type: "nameError", payload: true })
        }
      }

      //email validations
      if (state.branchEmail.length > 0) {
        if (emailValidation(state.branchEmail)) {
          emailvalidations = true
          dispatch({ type: "emailError", payload: false })
        } else {
          dispatch({ type: "emailError", payload: true })
        }
      }
      //branch code validations
      if (state.branchCode.length > 0) {
        if (characterNumberValidation(state.branchCode)) {
          bcValidations = true
          dispatch({ type: "bcError", payload: false })
        } else {
          dispatch({ type: "bcError", payload: true })
        }
      }
      dispatch({ type: 'notiMessageShow', payload: false })
      if ((branchnamevalidations) || (emailvalidations) || (bcValidations)) {
        const payload = {
          "type": state.userType,
          "userid": state.userId,
          "bcode": state.branchCode,
          "bname": state.branchName,
          "bemail": state.branchEmail,
          "is_admin":context.logindata.is_admin
        }
        searchBranch(payload).then((resp) => {
          if (resp.suc) {
            loadAllBranches(resp.res);
          }
        })
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select at least one search criteria' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }

  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const resetSearch = () => {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'branchName', payload: '' })
    dispatch({ type: 'branchCode', payload: '' })
    dispatch({ type: 'branchEmail', payload: '' })

    getAllBranches(context.logindata.uid, context.logindata.utype).then((res) => {
      if (res && res.suc) {
        loadAllBranches(res.res);
      }
    });
  }

  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Organization/CM' />
      <Breadcrumb activePage="Branch Creation" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <OrganizationMenu active="Branch Creation" />
          <div className="contentArea cmnSearch">
            {state.showCreateBtn &&
              <>
                <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="branchName">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.branchName} onChange={handleChange} />
                    <Form.Label>Branch Name</Form.Label>
                    {state.nameError && (
                      <p className="vError">Please Enter A Valid Branch Name</p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="branchCode">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.branchCode} onChange={handleChange} />
                    <Form.Label>Branch Code</Form.Label>
                    {state.bcError && (
                      <p className="vError">Please Enter A Valid Branch Code</p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="branchEmail">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.branchEmail} onChange={handleChange} />
                    <Form.Label>Branch Email</Form.Label>
                    {state.emailError && (
                      <p className="vError">Please Enter A Valid Email</p>
                    )}
                  </Form.Group>
                </Form.Row>

                <div className="text-right pb-0 buttonGrop  creat_absolute">
                  <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={resetSearch}
                  > Reset</Button>
                  <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                    onClick={handleSearch}
                  > Search</Button>
                </div>
                {state.showCreateBtn && context.logindata.roles !== undefined && context.logindata.roles.includes('B_C_C') && (
                  <div className="buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      onClick={createCompBtnClick}
                      loading={state.loadBranch}
                      className="addPlus ezyIcon icon-plus"
                      type="submit"
                    >  Create Branch</Button>
                  </div>
                )}
              </>
            }

            {state.createBranch && (
              <BranchCreation create={true} />
            )}
            {state.updateBranch && (
              <BranchContext.Provider value={data}>
                <BranchCreation enbleUpdate={state.enableView} create={false} />
              </BranchContext.Provider>
            )}
          </div>
        </div>

        {state.showCreateBtn ? (
          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
              {state.res.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || state.res.length === 0 &&
                <NoRecord />
              }</>)}
          </div>

        ) : ('')}
      </div>
      <Footer />

    </>
  )
}
export default BranchMain