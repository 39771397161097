import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import InsHomeSearch from "../search/InsHomeSearch";
export const EXIT_ITINARARY = "extItinarary";
export const EXIT_STORE = "is_store";
const initialState = {
  srcDate: "",
  destDate: "",
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function InsModifySearch(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modifySearch, setModifySearch] = useState(false);
  const [response, setResponse] = useState();
  const [initreq, setinitreq] = useState(props.initreq);

  const openSearch = () => {
    if (modifySearch) {
      setModifySearch(false);
    } else {
      setModifySearch(true);
    }
  };

  useEffect(() => {
    if (props.modify) {
      setModifySearch(false);
    }
  }, [props.modify]);
  return (
    <>
      <section className="modifySearch insuRance">
        <div className="searchInfo">
          <div className="container">
            <div className="modifycontainer">
              <div className="modifySearchInfo">
                {initreq.tt === 2 && (
                  <div className="rout d-flex flex- c">
                    <div className="routsList">
                      <span className="rtInfo">
                        {initreq.srcCity} ({initreq.srcCode})
                      </span>{" "}
                      <span className={"ezyIcon icon-oneside"}></span>
                      <span className="rtInfo">{"Qatar"} </span>
                    </div>
                  </div>
                )}
                {initreq.tt === 1 && (
                  <div className="rout d-flex flex- c">
                    <div className="routsList">
                      <span className="rtInfo">{"Qatar"} </span>
                    </div>
                  </div>
                )}
                <div className="dateInfo flex-column">
                  <div className="trvelDate d-flex">
                    {" "}
                    {moment(initreq.srcDate).format("ddd, Do MMM YYYY")} -{" "}
                    {moment(initreq.destDate).format("ddd, Do MMM YYYY")}{" "}
                    <div className="paxInfo">
                      <span className="paxDetails">
                        Travellers {initreq.adt + initreq.chd}
                      </span>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <span
              onClick={openSearch}
              className={"changeSearch" + (modifySearch ? " active" : "")}
            >
              Change Search <span className="ezyIcon icon-arrowDown" />
            </span>
          </div>
        </div>
        <div className="container">
          {modifySearch && (
            <InsHomeSearch
              search="modifySearch"
              srequest={initreq}
              ismodify={modifySearch}
              bound_type={props.initreq.bound_type}
            />
          )}
        </div>
      </section>
    </>
  );
}
export default InsModifySearch;
