import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Context } from "../../../../App";
import { processIndigoRefund } from '../../postBooking/summary/operations';
import Button from '../../common/buttons/Button';
import Footer from '../../common/Footer';
import Header from '../../common/Header';

let refundJson = require('../../masterData/Hotel.json');

/**
 * Initial State Declaration
 */
const initialState = {
    taxPopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', remarks: "", attPaxPrice: [], taPaxPrice: [], showPopup: false,setPtype : "",enableLoader: false, notiMessage: ''
};
let totalRefund=0;
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {

    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};
function FlightIndigoRefund(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [refundData, setRequest] = useState(props.location.state.req);
    const [refund, setRefund] = useState(props.location.state.req)
    const [paxList , setPaxList] = useState([])
    //Calculating the ATT,TA and STA Summary
    useEffect(() => {
        if (refund.data !== undefined) {
            let attList=[];
            let taList=[];
            let list=[];
            if(refundData.data.tktnum.length!==0){
                for(let val of refundData.data.tktnum){
                    if(list.length===0){
                        list.push(val);
                    }else{
                        let list1=list.filter((pax)=>pax.pname===val.pname);
                        if(list1.length===0){
                            list.push(val);
                        }
                    }
                }
                setPaxList(list);
            }
            if(refund.data!==undefined){
                if(refund.data.price[0].type === "ATT") {
                    let attprice=refund.data.price[0];
                    const att = {
                        "refunfrmsup": attprice.att_refundamt,
                        "refundprice": attprice.subtotal
                    }
                    attList.push(att);
                }
                if (refund.data.price[1].type === "TA") {
                    const ta = {
                        "refunfrmsup": refund.data.price[0].totalfare,
                        "refundprice": refund.data.price[1].subtotal
                    }
                    taList.push(ta);
              }
            }
            dispatch({ type: 'attPaxPrice', payload: attList })
            dispatch({ type: 'taPaxPrice', payload: taList })
        }
    }, []);


    //Proceess Refund flow
    const procesRefundFlow = () => {
        dispatch({type:'enableLoader',payload: true})
        refund.data.tkt_user_type=context.logindata.utype;
        refund.data.tkt_user_id=context.logindata.uid;
       // refund.data.journey_type=props.location.state.journey_type;
        refund.data.type="Refund/cancel";
        refund.data.cus_code=context.logindata.cuscode;
        processIndigoRefund(refund.data).then((resp) => {
            dispatch({ type: 'showPopup', payload: false });
            if (resp.suc) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Refund processed Successfully..' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                setTimeout(() => {
                    return window.location = '/ezytrip/dashboard/bookingsummary/' + refund.data.booking_id;
                }, 2000)
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Unable to process the Refund for selected tickets' })
                dispatch({ type: 'notiVarient', payload: 'danger' });
            }
            dispatch({type:'enableLoader',payload: false})
        })
    }

    const showPopup = () => {
        dispatch({ type: 'showPopup', payload: true });
    }
    const handleCLClose = () => {
        dispatch({ type: 'showPopup', payload: false });
    }

    const cancelRefund=()=>{
        window.location="/ezytrip/dashboard/bookingsummary/" + refund.data.booking_id;
    }

    return (
        <>

            <Header />

            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
            <div className="container confirmation refundRecord">
                <div className="topSec summarytopSec">
                    <Button
                        variant="link"
                        className="backbtn ezyIcon icon-rightArrow"
                        onClick={cancelRefund}
                    >Back</Button>
                </div>
                {refundData !== undefined && refundData.data.split_pnr !== undefined && refundData.data.split_pnr !== "" &&
                   <span className='indRoutes d-flex'><span  className="ezyIcon icon-info">Your booking got split successfully with  <span className='refundBooId'> Booking Id : {refundData.data.booking_id} </span> & <span className='refundBooId'> PNR : {refundData.data.split_pnr}</span> </span></span>
                }
                <div className="resultSection boxShadow bg-white summary  bookingSummery mb-4">

                <h5 className="title d-flex align-items-center mt-4" > <span className="ezyIcon icon-person mr-1"></span> Passenger Details</h5>
        <div className='flightdBg'>
                <div className='penal'>
                { refundData !== undefined && refundData.data.routes !== undefined && refundData.data.rtype === "Segment" &&
                   <span className='indRoutes'> <span>{refundData.data.routes} </span></span>
                }
            <div className="resultSectionin">

           <ul className='d-flex pl-0 mt-3'>
                {paxList !== undefined && paxList.length !== 0 &&
                    paxList.map((pax, index) => (
                        <React.Fragment key={index}>

                                    <li className="ezyIcon icon-confirmedOne"> <span className='pasNm'>{pax.pname}</span> ({pax.ptype})</li>

                                </React.Fragment>
                                ))
                }
                    </ul>

                </div>
                {refundData !== undefined && refundData.data.price !== undefined &&
                           <div className="resultSection boxShadow bg-white PassengerDetails mb-2 mt-0">
                                <h5 className="title d-flex align-items-center mt-4"><span className="ezyIcon icon-refund mr-1"></span> Refund Details ({refundData.req_cur})</h5>
                                <Row className="m-0">
                                    <Col xs={12} className="p-0">
                                    <div className='bookingListinfo indigoRefund'>
                                        <Table>
                                            {refundData.data.price.map((price, idx) => (
                                                <React.Fragment key={idx}>
                                                    {idx === 0 &&
                                                        <thead>
                                                            <tr>
                                                            <th colSpan={3}>
                                                                </th>
                                                                <th colSpan={5} className="canclInfo noBrd">
                                                                <h5>Cancellation</h5>
                                                                </th>
                                                                <th>
                                                                    &nbsp;
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Company Name</th>
                                                                <th>Fare paid</th>
                                                                <th>Markup</th>
                                                                <th className="canclInfo"> Supplier Fee</th>
                                                                <th className="canclInfo">Service Fee</th>
                                                                <th className="canclInfo">Airline Charges</th>
                                                                <th className="canclInfo">Refund Fee</th>
                                                                <th className="canclInfo">Refund Markup</th>
                                                                <th className="text-right">Total Refund</th>
                                                            </tr>
                                                        </thead>
                                                    }
                                                    {/* ATT Login */}

                                                    {context.logindata.utype === "att_user" &&
                                                        <tr>
                                                            <td>
                                                                <span>{price.type}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.totalfare}</span>
                                                            </td>
                                                            <td>
                                                                <span>{price.bkmarkup}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                                <span>{price.supsfee}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                            <span>{price.bkservice}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                            <span>{price.aircharge}</span>
                                                            </td>
                                                            <td className="canclInfo">
                                                            <span>{price.canservice}</span>
                                                            </td>
                                                            <td className="canclInfo brdRdius">
                                                            <span>{price.canmarkup}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span>{price.subtotal}</span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            ))}

                                        </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                }
                </div>
                </div>
             </div>

                <div className="ticketSummerynew indigoticketSummery">
                <Row>
                <Col sm={6}>

                <div class="resultSection boxShadow bg-white recordInfo pb-0">
  <h5 class="title m-0 ezyIcon icon-document">Refund Summary - ATT</h5>
  <div class="bookingListinfo">
    <table class="m-0 table">
    {state.attPaxPrice!==undefined && state.attPaxPrice.length!==0 && state.attPaxPrice.map((price, idx) => (
         <React.Fragment key={idx}>
      <thead>
        <tr>
          <th>Refund from Supplier</th>

          <th align='right'>
          <span>
              <span>Refund to TA</span>
            </span>

          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td >
            <span>{price.refunfrmsup}</span>
          </td>

          <td align='right'>
            <span>
              <span>{price.refundprice}</span>
            </span>
          </td>
        </tr>

      </tbody>
      </React.Fragment>
      ))}
    </table>
  </div>
</div>
                </Col>

                <Col sm={6}>
                <div class="resultSection boxShadow bg-white recordInfo pb-0">
  <h5 class="title m-0 ezyIcon icon-document">Refund Summary - TA</h5>
  <div class="bookingListinfo">
    <table class="m-0 table">
    {state.taPaxPrice!==undefined && state.taPaxPrice.length!==0 && state.taPaxPrice.map((price, idx) => (
         <React.Fragment key={idx}>
    <thead>
        <tr>
          <th>Refund from Supplier</th>

          <th align='right'>
            <span>
              <span>Refund to Customer</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          {state.attPaxPrice!==undefined && state.attPaxPrice.length!==0 &&
            <span>{state.attPaxPrice[0].refundprice}</span>
          }
          </td>

          <td align='right'>
            <span>
              <span>{price.refundprice}</span>
            </span>
          </td>
        </tr>

      </tbody>
      </React.Fragment>
      ))}
    </table>
  </div>
</div>

</Col>
</Row>
</div>


                <div className="text-right buttonGrop mt-4">
                    <Button
                        size="xs"
                        variant="outline-dark"
                        type="submit"
                        onClick={cancelRefund}
                    > Cancel Refund</Button>
                    <Button
                        size="xs"
                        variant="outline-primary"
                        type="submit"
                        onClick={showPopup}
                    > Process Refund</Button>
                </div>

                <Modal
                    show={state.showPopup}
                    onHide={handleCLClose}
                    className="importPNRMdl requiredFare"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <div className="popUplayout center">
                            <div className="icons warning"></div>
                            <div>
                                <strong>Are you sure, you want to process the refund?</strong>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Footer className="justify-content-center">
                        {state.enableLoader ? '' : <Button variant="outline-dark" onClick={handleCLClose}>
                            Close
                       </Button>}
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={procesRefundFlow}
                        > {!state.enableLoader ? 'Yes' : 'Processing...'}</Button>
                    </Modal.Footer>
                </Modal>


            </div>



            <Footer />
        </>
    )
}
export default FlightIndigoRefund