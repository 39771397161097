import React from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
//This function will show the Air markups List and Add Markup
function PaymentFail() {
   const backToHome=()=>{
        return window.location="/ezytrip/dashboard";
      }
    return (
        <>
        <Modal
        show={true}
        onHide={backToHome}
        className="importPNRMdl creditCardpay"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
        <div>
        <div className="custom-ui">
         <span className="confirmD"></span>
        <div className="creditINfo d-flex flex-column align-items-center">
        <h4>Payment has failed.. </h4>
          <p className="text-center">Sorry we are unable to process this transaction.
            Please check with adminstrator.
         </p>
        </div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
              size="xs"
              variant="outline-primary"
              onClick={backToHome}
              type="submit"
            >Back To Home</Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}
export default PaymentFail