
import React, { Component, useState, useEffect, useReducer } from 'react';
import { Form } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import Select from 'react-dropdown-select';
import { isArrayNotEmpty } from '../../../../commonUtils/validators';
const _ = require("lodash");



const initialState = {
  applToData: [],listView: true, mapView: false, sortIcon:'Hot Deal',
};

// Reducer function for userReact Hook
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//Main Function
function Sort(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [enable, setEnable] = useState()

 /*  let offers = [{ label: "Price - Low to High", value: "LH" },
  { label: "Price - High to Low", value: "HL" },{ label: "Recommended", value: "RC" }]
  if(props.hotelresponse.filters.ishotdeal){
    let obj={ label: "Hot Deal", value: "Hot Deal" };
    state.applToData.push(obj);
    offers.push(obj);
  }  */

  useEffect(() => {
    const searcres = props.hotelresponse;
    dispatch({ type: 'finalsortResponse', payload: Object.assign({}, searcres) })
    dispatch({ type: 'localsortResponse', payload: searcres })
    window.scrollTo(0, 0)
  }, [])

     /**
      *
      * @param {*} value
      * @description:Price Filter
      * @author: Rambabu
      * @date:01-03-2021
      */

  const sortinghandlechange = (obj) => {
    dispatch({ type: 'sortIcon', payload: obj.length!=0?obj[0].value:[] });
    //if (!props.isreset) {
    let filterres = _.cloneDeep(props.hotelresponse.prpts);
    dispatch({ type: 'applToData', payload: obj })
    if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'HOT DEAL') {

      //descending order
      filterres.sort(function (htl1, htl2) {
        return Number(htl2.disc) - Number(htl1.disc);
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'LH') {//low to high

      //ascending order
      filterres.sort(function (htl1, htl2) {        
        return (Number(htl1.rtf)) - (Number(htl2.rtf));
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse, obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'HL') {//high to low

      //descending order
      filterres.sort(function (htl1, htl2) {        
        return (Number(htl2.rtf)) - (Number(htl1.rtf));
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    } else if (isArrayNotEmpty(obj) && obj[0].value.toUpperCase() === 'RC') {//Recommended

      //descending order
      filterres.sort(function (htl1, htl2) {
        return Number(htl2.score) - Number(htl1.score);
      });
      state.localsortResponse.prpts = filterres;
      props.sortresponse(state.localsortResponse,obj,'sort');

    }else {
      state.localsortResponse.prpts = state.finalsortResponse.prpts;
      props.sortresponse(state.localsortResponse,obj,'sort');

    }
  /*   } else {
      props.resetvalue('sort');
    } */

  }
  const showList=()=>{
    props.shwoListView(true)
    dispatch({ type: 'listView', payload: true })
        dispatch({ type: 'mapView', payload: false })
  }
  const showMap=()=>{
    props.shwoMapView(true)
    dispatch({ type: 'listView', payload: false })
        dispatch({ type: 'mapView', payload: true })
  }

  return (

    <>
      <div class="sort">
        {!props.ismapview ? (
          <div class="sortByValue">
            <div class="sortRange">
              <span class="sorting">Sort by : </span>
              <div className="sortdropdown">
                <Form.Group className="selectplugin deal" controlId="dealid">
                  <Select
                    options={props.offers}
                    searchable={false}
                    values={props.applToData}
                    onChange={value => (sortinghandlechange(value))}
                   // clearable
                  />
                    {/* let offers_data = [{ label: "Price - Low to High", value: "LH" },
  { label: "Price - High to Low", value: "HL" },{ label: "Recommended", value: "RC" }] */}
                  <span className={"ezyIcon dlIcon icon-"+(state.sortIcon==='Hot Deal'&& 'Hot-Deal' || state.sortIcon)}></span>

                </Form.Group>
              </div>

            </div>
          </div>)
          : (<div class="sortByValue">
            <div class="sortRange">
              <span class="sorting"> </span>
              <div className="sortdropdown">
              </div>
            </div>
          </div>)}

        <div className="gridStyle d-flex">
          <div className="sortRange list">
            <Link className={state.listView ? 'active' : ''} onClick={showList}>
              <span className="sortPrice">
                List
                       </span>
                       <span className="ezyIcon icon-list"></span>


            </Link>
          </div>
          <div className="sortRange box">
            <Link className={state.mapView ? 'active' : ''} onClick={showMap}>
            Map
              <span className="sortPrice">
                       </span>
                       <span className="ezyIcon icon-mapPlus"></span>

            </Link>
          </div>
        </div>

      </div>

    </>
  )
}


export default Sort;
