
import log from "loglevel";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Col, Form ,Modal} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import useOnclickOutside from 'react-cool-onclickoutside';
import Fifacup from '../../../../../../src/client/assets/images/fifaworlcup.png'
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import PredectiveSearch from '../../../../services/predective';
import DateUtils from '../../../commonUtils/DateUtils';
import Button from '../../../common/buttons/Button';
import debounce from 'lodash.debounce';
import moment from 'moment';
import InsuAddTravellers from '../../insurance/search/InsuAddTravellers';

/**
 * @description:This function provide the search criteria for One way and Round trip
 * @author: Lakshmi
 * @param {*}
 * @function Insurance Search
 * @date : 09-11-2021
 */

const initialState = {
  airportsOW: [], airportsRT: [],days:"", src: "", dest: "", dd: DateUtils.convertStringToDate(new Date()), data: "", pa: "", ns: false, ad: "", paxCount: 1, cls: "Economy", adt: 1, chd: 0, inf: 0, orignData: [], destData: [], selOrigin: [], selDest: [], notiMessage: "", notiMessageShow: false, notiVarient: "danger", ismodify: false, srcDate: DateUtils.convertStringToDate(new Date()), destDate: "", _onwardCal: false, _destiCal: false, openArivalDate: new Date(), selAir: [], flowType: "", paxCounts: 0, clearob: false, cleardb: false,showInboundPopup:false
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, airportsOW: [], airportsRT: [], src: "", dest: "", data: "", pa: "", ns: false, ad: "", adt: 1, chd: 0, inf: 0
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function InsuSearch(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [airline, setAirline] = useState([]);
  const [addTraveller, setAddTraveller,] = useState(false);
  const [isLoad, setIsLoad,] = useState(false);
  const [originIsFocus, setOriginIsFocus,] = useState(false);
  const [destinationIsFocus, setDestinationIsFocus,] = useState(false);
  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState("");//DateUtils.convertToDateHifen(moment(new Date()).add(1, 'days'))
  const uref = useRef();
  const originref = useRef();
  const [context, setContext] = useContext(Context);
  const [date, setDate] = useState(new Date());
  const [maxInsuDate,setMaxInsuDate] = useState(new Date());
  const  [tripType , setTripType] = useState(0);

  useEffect(() => {
    if(props.tt!==undefined){
      setTripType(props.tt);
       if(props.tt===1){
        const dateObj = moment(departureDate).add(180, 'days');
        setMaxInsuDate(moment(dateObj).toLocaleString())
       }else{
        const dateObj = moment(departureDate).add(365, 'days');
        setMaxInsuDate(moment(dateObj).toLocaleString())
       }
    }

  }, [props.tt])
  useEffect(() => {
    if (props.srcMultiData !== undefined && props.srcMultiData[0] !== undefined) {
      dispatch({ type: "src", payload: props.srcMultiData[0].airportCode })
      dispatch({ type: "selOrigin", payload: props.srcMultiData })
    }
  }, [props.srcMultiData]);

  useEffect(() => {
    if (props.destMultiData !== undefined && props.destMultiData[0] !== undefined) {
      dispatch({ type: "dest", payload: props.destMultiData[0].airportCode })
      dispatch({ type: "selDest", payload: props.destMultiData })
    }
  }, [props.destMultiData]);


  useEffect(() => {
    if (props.load) {
      setIsLoad(false)
    }

  }, [props.load]);

  useEffect(() => {
    if(props.stopButtonLoad) {
      setIsLoad(false)
    }
  },[props.stopButtonLoad])
  useEffect(() => {
    if (props.srequest !== undefined && props.srequest.tt !== undefined) {
      dispatch({ type: "ismodify", payload: props.ismodify })
      setTripType(props.srequest.tt);
        if(props.srequest.tt===2){
          dispatch({ type: "src", payload: props.srequest.src[0].airportCode })
          dispatch({ type: "orignData", payload: props.srequest.src })
          dispatch({ type: "selOrigin", payload: props.srequest.src })
        }
        dispatch({ type: "days", payload: props.srequest.days })
        dispatch({ type: "adt", payload: props.srequest.adt })
        dispatch({ type: "chd", payload: props.srequest.chd })
        dispatch({ type: "dd", payload: props.srequest.dd })
        dispatch({ type: "ad", payload: props.srequest.ad })
        setDepartureDate(DateUtils.convertNewToDate(props.srequest.dd))
        setArrivalDate(DateUtils.convertToDateHifen(props.srequest.ad))
        dispatch({ type: "paxCount", payload: props.srequest.adt + props.srequest.chd})
        const srcd = DateUtils.prettyDate(props.srequest.dd, "ddd, DD MMM YYYY");
        dispatch({ type: "srcDate", payload: srcd })
        dispatch({ type: "destDate", payload: props.srequest.ad })
        dispatch({type: "clearob", payload: true})
    }
  }, [props.srequest !== undefined]);

  const refOutClick = useOnclickOutside(() => {
    setAddTraveller(false);
    document.querySelector('.origin').classList.remove('activeSerField');
    document.querySelector('.destination').classList.remove('activeSerField');
    document.querySelector('.datePick').classList.remove('activeSerField');
    document.querySelector('.traveller > div').classList.remove('activeSerField');
  });

  const errorRemoveBlur = () => {
    dispatch({ type: "notiMessageShow", payload: false })
    document.querySelector('.origin').classList.remove('errorSerField');
    document.querySelector('.destination').classList.remove('errorSerField');
    document.querySelector('.datePick').classList.remove('errorSerField');
    document.querySelector('.traveller > div').classList.remove('errorSerField');
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOrigin = (input, e) => {
    dispatch({type: "src", payload: ""})
    dispatch({ type: "orignData", payload: [] })
    dispatch({type: "selOrigin", payload: []})
    if(input.length > 0){
      dispatch({type: "clearob", payload: true})
    }
    else{
      dispatch({type: "clearob", payload: false})
    }
    if(input.length>2){
      state.selOrigin = [];
     // predectiveCall(input, 'airportsOW')
     let obj ={
       textValue:input,
       type : 'airportsOW'
     }
      debouncedSave(obj);
    }
    else{
      state.selOrigin = [];
      dispatch({ type: 'airportsOW', payload: [] })
    }

  }

  // highlight-starts
	const debouncedSave = useCallback(
		debounce(obj => predectiveCall(obj.textValue, obj.type), 500),
		[], // will be created only once initially
	);
	// highlight-ends

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOrigin = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "src", payload: obj[0].airportCode })
      dispatch({ type: "orignData", payload: obj })
      dispatch({ type: "selOrigin", payload: obj })
      uref.current.focus();
      //errorRemoveBlur();
      props.sendSrc(obj);
    }
  }

  //=====================================
  // This function will open the Onward Calendar on Blur or Destination field
  //=====================================
  const openOnwardCal = () => state._onwardCal.setOpen(true);
  const openDestiCal = () => state._destiCal.setOpen(true);



  //=====================================
  // This function will call the service to get the Airport data
  //=====================================
  const predectiveCall = async (input, route) => {
    if (input.length > 1) {
      await PredectiveSearch.retreieveSearch(input).then((data) => {
        dispatch({ type: route, payload: data.data !== "" ? data.data : [] })

      });
    }
    else {
      dispatch({ type: route, payload: [] })
    }
  }

  //=====================================
  // This function will update the state
  //=====================================
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (addTraveller) {
      setAddTraveller(false)
    } else {
      setAddTraveller(true)
      activeTraveller()
    }
    dispatch({ type: id, payload: value });
  }

  //=====================================
  // Passing Active Class for the SearchField parents
  //=====================================
  const activeOrigin = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
    companySelect();
  }
  const activeDesti = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
  }
  const activeDate = (event) => {
    let element = document.querySelector('.datePick');
    element.classList.add('activeSerField')
  }
  const activeTraveller = (event) => {
    let element = document.querySelector('.traveller > div');
    element.classList.add('activeSerField')
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  //=====================================
  // This function will validate the Search Fields data exists or not
  //=====================================
  const validateSearch = () => {
    if (state.src === "" && props.tt===2) {
      document.querySelector('.origin').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter Country." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.dd === "" || state.dd==="Invalid date") {
      document.querySelector('.datePick').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter Onward Date." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }

    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        document.querySelector('.domin').classList.add('dominFocus');
        hidemessage();
        return false;
      }
    }
    if (state.days ==="" || Number(state.days)<1) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter days' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      document.querySelector('.domin').classList.add('dominFocus');
      hidemessage();
      return false;
    }else if(state.days!=="" && Number(state.days)>=366){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Days should be less than 366 days' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      document.querySelector('.domin').classList.add('dominFocus');
      hidemessage();
      return false;
    }
    return true;
  }
  const companySelect = () =>{
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        document.querySelector('.domin').classList.add('dominFocus');
      }else{
        document.querySelector('.domin').classList.remove('dominFocus');
      }
    }
  }

  const searchInsurance=()=>{
    if(validateSearch()){
     if(props.tt===1){
      handleInsuranceSearch();
     }else{
        let checkCard = DateUtils.checkHayyCardDate(state.dd,"QA");
        if(checkCard){
          dispatch({ type: "showInboundPopup", payload: true })
        }else{
          handleInsuranceSearch();
        }
     }
    }
  }

  //=====================================
  // This function will call the service for Search response
  //=====================================
  const handleInsuranceSearch = () => {
      dispatch({ type: "notiMessageShow", payload: false })
      dispatch({ type: "showInboundPopup", payload: false })
      try {
        setIsLoad(true);
        const payload = {
          "src": props.tt===2?state.selOrigin:"",
          "adt": state.adt,
          "chd": state.chd,
          "dd": state.dd,
          "ad": state.ad,
          "tt": props.tt,
          "bound_type":props.tt===1?0:1,
          "cur": "QAR",
          "cid": context.logindata.cid,
          "modify": state.ismodify,
          "srcCode": state.src,
          "destCode": "QAR",
          "srcCity": props.tt===2?state.selOrigin[0].cityName:"",
          "destCity": "Qatar",
          "srcDate": state.srcDate,
          "destDate": state.destDate,
          "bid": context.logindata.bid,
          "butype": context.logindata.butype,
          "bktype": "DIRECT",
          "pcid": (context.logindata.pcid !== undefined) ? context.logindata.pcid : 0,
          "flowType": "DIRECT",
          "days":state.days
        }
        if(state.ismodify){
          setIsLoad(false);
        }
        sessionStorage.setItem("CMPID", context.logindata.cid)
        history.push({
          pathname: '/ezytrip/bookings/insurance/results',
          state: {
            req: payload
          }
        });
      } catch (e) {
        log.error('Exception occured in handleChange function---' + e);
      }
  }


  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const coloseTravel = (type) => {
    if (type === "coseTrv") {
      setAddTraveller(false);
    }

  }

   //=====================================
  // This function will update the state
  //=====================================
  const handleChangeDays = (e) => {
    const { id, value } = e.target;
    var dateObj=null;
    if(e.target.value===1){
      dateObj = moment(departureDate).add(e.target.value, 'days');
    dispatch({ type: 'days', payload: e.target.value })
    setArrivalDate(DateUtils.convertToDateHifen(departureDate));
    const dest = DateUtils.prettyDate(departureDate, "ddd, DD MMM, YYYY")
    dispatch({ type: 'destDate', payload: dest })
    const convertDate = DateUtils.convertStringToDate(departureDate);
    dispatch({ type: 'ad', payload: convertDate })
    dispatch({ type: id, payload: value });
    }else{
      dateObj = moment(departureDate).add(e.target.value-1, 'days');
    dispatch({ type: 'days', payload: e.target.value })
    setArrivalDate(DateUtils.convertToDateHifen(dateObj));
    const dest = DateUtils.prettyDate(dateObj, "ddd, DD MMM, YYYY")
    dispatch({ type: 'destDate', payload: dest })
    const convertDate = DateUtils.convertStringToDate(dateObj);
    dispatch({ type: 'ad', payload: convertDate })
    dispatch({ type: id, payload: value });
    }
  }


  // OnChange event we will call this and update the state
  const handleDepDateChange = date => {
    const srcd = DateUtils.prettyDate(date, "ddd, DD MMM, YYYY")
    dispatch({ type: 'srcDate', payload: srcd })
    const convertDate = DateUtils.convertStringToDate(date);
    dispatch({ type: 'dd', payload: convertDate })
    setDepartureDate(date);
    setArrivalDate("")
    dispatch({ type: 'days', payload: "" })
  }


  // OnChange event we will call this and update the state
  const handleArvDateChange = date => {
    const dest = DateUtils.prettyDate(date, "ddd, DD MMM, YYYY")
    dispatch({ type: 'destDate', payload: dest })
    const convertDate = DateUtils.convertStringToDate(date);
    dispatch({ type: 'ad', payload: convertDate })
    dispatch({ type: 'openArivalDate', payload: date })
    setArrivalDate(date);
     if(departureDate!==undefined && date!==undefined && departureDate!==""){
      var diff_In_Time = date.getTime() - departureDate.getTime();
      var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
      dispatch({ type: "days", payload: diff_In_Days })
    }
    //errorRemoveBlur();
    setAddTraveller(true);
    activeTraveller();
  }

const clearOrigin = ()=>{
  dispatch({type: "src", payload: ""})
  dispatch({ type: "orignData", payload: [] })
  dispatch({type: "selOrigin", payload: []})
  dispatch({type: "clearob", payload: false})
  originref.current.clear()
}

const updateAdtCount=(cnt)=>{
    dispatch({ type: "paxCount", payload: state.chd+cnt})
    dispatch({ type: "adt", payload: cnt })
}

const updateChdCount=(cnt)=>{
  dispatch({ type: "paxCount", payload: state.adt+cnt })
  dispatch({ type: "chd", payload: cnt })
}

const closePopup =()=>{
  dispatch({ type: "showInboundPopup", payload: false })
}


  return (
    <>
      <div>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        <Form.Row>
        {tripType === 2 &&
          <Form.Group as={Col}>
            <div className={"addInput origin" + (originIsFocus ? " active" : "")}>
              <Form.Label><span className="ezyIcon icon-plane"></span> Travelling From</Form.Label>
              <Typeahead
                //defaultInputValue={state.selectedOrigin}
                id="originmaster"
                labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                onInputChange={handleOrigin}
                options={state.airportsOW}
                selected={state.selOrigin}
                onChange={airport => handleOnOrigin(airport)}
                filterBy={['cityName','airportName','airportCode','countryName']}
                //onFocus={originOnFocus} onBlur={originOnBlur}
                placeholder="Select Country"
                readOnly
                minLength={1}
                onFocus={activeOrigin}
                renderMenuItemChildren={(airport) => (
                  <><span className="ezyIcon icon-plane"></span>
                    <strong> {airport.cityName} </strong>
                    <span>{airport.countryName} </span>
                    <small>{airport.airportCode}</small>

                  </>
                )}
                ref={originref}

              />
              {state.clearob && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearOrigin}
                variant="link">
              </Button>
              }

            </div>

          </Form.Group>
           }

          <Form.Group as={Col}>
            <div className={"addInput destination" + (destinationIsFocus ? " active" : "")}>
              <Form.Label><span className="ezyIcon icon-plane"></span>{props.tt === 2?"Destination":"Travelling From"} </Form.Label>
              <Typeahead disabled
                id="basic-typeahead-single"
                placeholder="Qatar"
                onFocus={activeDesti}
                ref={uref}
              />
              {/* <Form.Control type="text" onFocus={destinationOnFocus} onBlur={destinationOnBlur} placeholder="To Country" autoComplete="off" /> */}
            </div>
          </Form.Group>

          <Form.Group>
            <div className="addInput datePick datePicker departDate d-flex">
              <div className="from">
                <Form.Label><span className="ezyIcon icon-newcalendar"></span> Departure</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={departureDate}
                  //value={state.dd}
                  onChange={handleDepDateChange}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  // monthsShown={2}
                  minDate={new Date()}
                  maxDate={new Date(maxInsuDate)}
                  onCalendarOpen={activeDate}
                  ref={(c) => state._onwardCal = c}
                  selectsStart
                  startDate={departureDate}
                  //endDate={arrivalDate}
                  onFocus={e => e.target.blur()}
                  popperModifiers={{
                    flip: {
                        behavior: ['bottom'] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                />
              </div>

            </div>
          </Form.Group>

          <Form.Group className="daysDate" as={Col} xs={1} controlId="days">
            <div className="addInput datePick datePicker d-flex flex-column">
                <Form.Label>Days</Form.Label>
                  <Form.Control type="text" placeholder="Enter days" disabled={departureDate===undefined} value={state.days} onChange={handleChangeDays} />
               </div>
                </Form.Group>
                <Form.Group>

                  <div className="addInput datePick datePicker departDate d-flex">
                <div className="from">
                  <Form.Label><span className="ezyIcon icon-newcalendar"></span> Arrival</Form.Label>

                  <Form.Control type="text" disabled={true} placeholder="Arrival Date" value={arrivalDate} />

                 </div>
                 </div>
                 </Form.Group>

          <Form.Group as={Col} className="traveller" >
            <div className="addInput">
              <Form.Label><span className="ezyIcon icon-person"></span> Travellers</Form.Label>
              <Form.Control onClick={handleChange} type="text" placeholder={state.adt + " Adult " +state.chd +" Children"} autoComplete="off" readOnly />
            </div>
            {addTraveller && <InsuAddTravellers sendAdtCount={updateAdtCount}  sendChdCount={updateChdCount} closetravaler={coloseTravel} sendCount={[state.adt, state.chd]} ismodify={state.ismodify} tripType={props.tt} />}
          </Form.Group>
          <Form.Group className="searchHere" as={Col}>

            <Button
              size="lg" block
              className="d-flex"
              onClick={searchInsurance}
              variant="primary"
            ><span className={"d-flex loading ezyIcon icon-search " + (isLoad ? 'progress-bar-striped progress-bar-animated' : '')}>SEARCH{isLoad ? <>  </> : <> {!state.ismodify ? "" : ""} </>} </span></Button>

          </Form.Group>
        </Form.Row>

      </div>


      { state.notiMessageShow && (
        <Alert className="notification" variant={state.notiVarient} onClose={() => dispatch({ type: "notiMessageShow", payload: false })} dismissible> {state.notiMessage}</Alert>
      )}

<Modal
        show={state.showInboundPopup}
        //onHide={handlePriceClose}
        className="importPNRMdl comingtoFifa"
        backdrop="static"
        keyboard={false}
      >
           <Modal.Header>
                          <Modal.Title>Coming to Qatar?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <img src={Fifacup} />
              <p>You need Hayya card to enter the state of Qatar between 1st Novmber 2022 - 23rd December 2022 to access the stadiums.(Non Citizens & RP Holders)</p>
             <span><a className="d-inline-flex" href="https://www.qatar2022.qa/en/coming-to-qatar" target="_blank">For more info : <span> www.qatar2022.qa/en/coming-to-qatar</span></a></span>
              </Modal.Body>
        <Modal.Footer className="justify-content-end">
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={closePopup}
            >No</Button>
            <Button
              size="xs"
              onClick={handleInsuranceSearch}
              variant="outline-primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default InsuSearch;
