import React, { useState, useEffect, useReducer } from 'react';
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead'
import Button from '../../common/buttons/Button'
import Select from 'react-dropdown-select';
import 'react-datepicker/dist/react-datepicker.css';
import PickList from '../../common/pickList/PickList';
import 'react-datepicker/dist/react-datepicker.css';
import PredectiveSearch from '../../../services/hotelpredictive';
import {fetchCountries,fetchCities,fetchHotels} from '../hotelMarkup/operations'
/**
 * @description:This function will creaet all the routes
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 09-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
    showDates: false, aplType: "Travel", notiMessage: "", notiMessageShow: false, notiVarient: "", selDates: [], hotels: [], hotelSelected: [], applicablesArray: [], countrySelected: [], citySelected: [], applDates: [],
    hotelId: [], hotelsSelectedList: [],include: true,countries:[],cities:[],hotels_data:[],cityHtls:[],enableEdit: false,selectedCityPredictive:[],id:0
};

let applicableArray = []; //to handled city dublication

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        case 'clear':
            return {
                ...state, showDates: false, aplType: "Travel", notiMessage: "", notiMessageShow: false, notiVarient: "", selDates: [], applicablesArray: [],cities:[],hotels_data:[],enableEdit: false,selectedCityPredictive:[], countrySelected: [], citySelected: [], applDates: [],
                hotelId: [], hotelsSelectedList: [],id:0
            }
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function HotelSelected(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [dataState, setData] = useState({ data: [] });
    const [applicableDate, setApplicableDate,] = useState(false);
    const [selData,sethotelData] = useState([])
    const [city,setCity] = useState([])
    const [editCity,setEditCity] = useState([])
    const [editHotel,setEditH] = useState([])
    const [editHotelId,setEditHc] = useState([])
    const airline = [{ label: "Albania", value: 355 },
    { label: "Argentina", value: 54 },
    { label: "Austria", value: 43 },
    { label: "Cocos Islands", value: 61 },
    { label: "Kuwait", value: 965 },
    { label: "Sweden", value: 46 },
    { label: "Venezuela", value: 58 }]

    const [hotelList, setHotelList] = useState([
        { value: 1, label: 'Hotel 1' },
        { value: 2, label: 'Hotel 2' },
        { value: 3, label: 'Hotel 3' }
    ]);
    /**
     * @description : fetch countries to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
    useEffect(() => {
        if(state.countries.length === 0) {
            fetchCountries().then(response => {
                if(response.data.status == 'success') {
                    let country = []
                    let data = response.data.data
                    for(let val in response.data.data) {
                        let country_data = data[val]
                        let obj = {
                            "value":country_data.countryCode,
                            "label":country_data.name.en
                        }
                        country.push(obj)
                    }
                    dispatch({type:"countries",payload:country})
                }
            })
        }
    },[state.countries])

    /**
     * @description : when clciked on reset button in parent data is emptied
     * @author : Azamuddin
     * @date: 05-05-2021
     */
    useEffect(() => {
        if(props.reset) {
            dispatch({type:"clear",payload:true})
            sethotelData([])
            setCity([])
            setApplicableDate(false)
            setData({data:[]})
            setEditCity([])
            setEditH([])
            setEditHc([])
            applicableArray = []
        }
    },[props.reset])

    useEffect(()=>{
        if(props.countrySelected?.length>0){
            dispatch({type:"countrySelected",payload: props.countrySelected})
            handleCountryChange(props.countrySelected)
            PredectiveSearch.retreieveSearch(props.citySelected[0].label).then(response=>{
                if(response.data.status == 'success') {
                    setCity([])
                    let city = []
                    let data = response.data.data.city
                    dispatch({type:"cities",payload:data})
                    if(data.length > 0) {
                        let filtered_city = data.filter((s, sidx) => s.text == props.citySelected[0].label)
                        setCity(filtered_city)
                    }}
            })
            // handleCityChange(props.citySelected)
        }
    },[props.countrySelected,props.citySelected])

    useEffect(() => {
        if(props.editHotelResponse.length > 0) {
            state.hotelsSelectedList = []
            applicableArray = []
             //when editing clearing hotel the field just in case as its common component
            setApplicableDate(true)
            for(let htlData of props.editHotelResponse) {
            let arr = []
            let hotels_persist = []
            if(htlData.htl_obj.length > 0 && htlData.htl_obj[0].name) {
            for (let val of htlData.htl_obj) {
                let obj = {
                    "include": val.include,
                    "id": val.id,
                    "name": val.name
                }
                hotels_persist.push(obj)
                arr.push(val.name)
            }
        }
            let obj = {
                "cnt": htlData.cnt,
                "cty": htlData.cty,
                "cnt_code": htlData.cnt_code,
                "cty_code": htlData.cty_code,
                "include": htlData.htl_obj.length > 0 ? (htlData.htl_obj[0].include == 1 ? true : false) : true,
                "htl_obj": hotels_persist,
                "htl_id":hotels_persist.length > 0 ?  arr : "All",
                "id": htlData.id
            }
            //  arr = [obj]
            // dispatch({ type: "hotelsSelectedList", payload: { ...arr } })
            applicableArray.push(htlData.cty)
            state.hotelsSelectedList.push(obj)
        }
        }
    },[props.editHotelResponse])
    /**
     * @description : fetch cities to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
    const handleCountryChange = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: "countrySelected", payload: e })
            dispatch({ type: "citySelected", payload: [] })
            dispatch({ type: "hotels", payload: [] })
            dispatch({ type: "hotelSelected", payload: [] })
            dispatch({type:"cities",payload:[]})
            setCity([])
            setData({data:[]})
            sethotelData([])
        dispatch({ type: "hotels", payload: [] })
        dispatch({type:"hotels_data",payload:[]})
        if(props.configType && props.configType=="crossSell"){
        props.sendCntData("cnt",e)
    }
            // call for select city

            // fetchCities(e[0].value).then(response => {
            //     console.log("city response::::",response)
            //     if(response.data.status == 'success') {
            //         let city = []
            //         let data = response.data.data
            //         for(let val in response.data.data) {
            //             let city_data = data[val]
            //             let obj = {
            //                 "value":city_data._id,
            //                 "label":city_data.name.en
            //             }
            //             city.push(obj)
            //         }
            //         dispatch({type:"cities",payload:city})

            //     }
            // })
        }
    }

    const fetchCity =  (input,e) => {
        if(input.length > 2 && state.countrySelected.length > 0) {
            PredectiveSearch.retreieveSearch(input).then(response => {
            if(response.data.status == 'success') {
                setCity([])
                let city = []
                let data = response.data.data.city
                dispatch({type:"cities",payload:data})
                dispatch({type:"selectedCityPredictive",payload:data})
            }
        })
    }
    }
    /**
     * @description : fetch hotel to show in predective list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
    const handleCityChange = (e) => {
        if (e && e.length > 0) {
            let citySelectedJson = {
                "label": e[0].text,
                // "value" : e[0]._id,
                "value" : e[0].expediaCityId
            }
            if(props.configType && props.configType=="crossSell"){
            props.sendCntData("cty",citySelectedJson)
            }
            setCity()
            dispatch({ type: "citySelected", payload: [citySelectedJson] })
            dispatch({type:"hotels_data",payload:[]})
            dispatch({ type: "hotels", payload: [] })
            dispatch({ type: "hotelSelected", payload: [] })
            setData({ data: [] })
            sethotelData([])
        dispatch({ type: "hotels", payload: [] })
            // call for select hotels
             PredectiveSearch.retreieveSearch(citySelectedJson.label).then(response => {
                if(response.data.status == 'success') {
                    let hotels = []
                    let data = response.data.data.property
                    if(data.length > 0) {
                    // for(let val in response.data.data.property) {
                        // let hotel_data = data[val]
                 hotels = data.map(hotel_data=>({
                            "value":hotel_data.expediapid,
                            "label":hotel_data.description
                    }))
                    // }
                    let arr= hotels
                    arr.unshift({ value: "All", label: "All" });
                    let htls = getUnique(arr, "value");
                    dispatch({type:"cityHtls",payload:htls})
                    dispatch({type:"hotels_data",payload:htls})
                }
                }
            })
        } else {
            let citySelectedJson = {
                "label": "",
                // "value" : e[0]._id,
                "value" : ""
            }
            if(props.configType && props.configType=="crossSell"){
                props.sendCntData("cty", citySelectedJson)
                }
        }
    }

    const handleTypeChange = (e) => {
        if (e.target.id === "include") {
            dispatch({ type: "include", payload: true })
        } else if (e.target.id === "exclude") {
            dispatch({ type: "include", payload: false })
        }
    }

    const setAir = (data) => {
        if (data.length > 0 && data[0].value === "All") {
            dispatch({ type: "hotels", payload: data })
            dispatch({ type: "hotels_data", payload: data });
            sethotelData(data);
        }else if(data.length > 0 && data[0].value !== "All"){
            dispatch({ type: "hotels", payload: data })
            sethotelData(data)
            removeAll();
        }else {
            dispatch({ type: "hotels", payload: [] });
            sethotelData(data);
            defaultHotels();
          }
        setData({ data: data })
        let hotels_id = []
        for (let val of data) {
            hotels_id.push(val.label)
        }
        // sethotelData(data)
        // dispatch({ type: "hotels", payload: data })
        if(state.enableEdit) {
            setEditH(data)
            setEditHc(hotels_id)
        }
        dispatch({ type: "hotelId", payload: hotels_id })
    }

    const removeAll = (e) => {
        let arr = [];
        for (let val of state.hotels_data) {
          arr.push(val.value);
        }
        if (arr.indexOf("All") > -1) {
          const index = arr.indexOf("All");
    
          state.hotels_data.splice(index, 1);
        }
      };

    const defaultHotels = (e) => {
        let htls = [];
        state.cityHtls.unshift({ value: "All", label: "All" })
         htls = getUnique(state.cityHtls, "value");
        dispatch({ type: "hotels_data", payload:  htls });
      };
       /**
   * @description: to remove all multiples in array of objects
   * @author : Azamuddin
   * @date : 03-11-2020
   */

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

    /**
     * @description : add hotel list data to DataTable
     * @author : Azamuddin
     * @date : 06-05-2021
     */
    const openApplicableDate = () => {
        //construction of hotel selected dataList={}
        let arr = []
        let hotels_persist = []
        let existAll = []
        if(state.hotels_data.length === state.hotels.length || state.hotels.length === 0) {
        const exist = state.hotels_data.some(existingItem=> existingItem.value==="All");
        if(exist){
                let obj = {
                    "include": state.include,
                    "id": "All",
                    "name": "All"
                }
                existAll.push(obj)
                hotels_persist = []
        }else{
            existAll = []
            hotels_persist = []
        }
        } else {
        for (let val of state.hotels) {
            let obj = {
                "include": state.include,
                "id": val.value,
                "name": val.label
            }
            hotels_persist.push(obj)
        }
    }
        //to check city and country data as hotel data is not mandatory
        if(state.countrySelected.length > 0) {
            //validation check
            let valid = false
            if(state.citySelected.length > 0) {
                if(!applicableArray.includes(state.citySelected[0].label)) {
                    valid = true
                }
            } else {
                valid = true
            }
            if(valid) {
        let obj = {
            "cnt": state.countrySelected[0].label,
            "cty":state.citySelected.length > 0 ? state.citySelected[0].label : "All",
            "cnt_code": state.countrySelected[0].value,
            "cty_code": state.citySelected.length > 0 ? state.citySelected[0].value : "All",
            "include": state.include,
            "htl_obj": hotels_persist.length==0?existAll:hotels_persist,
            "htl_id": hotels_persist.length > 0 ? state.hotelId : "All"
        }
         arr = [obj]
         if(state.citySelected.length > 0) {
         applicableArray.push(state.citySelected[0].label)
         }
        // dispatch({ type: "hotelsSelectedList", payload: { ...arr } })
        state.hotelsSelectedList.push(obj)
        setApplicableDate(true)
        dispatch({type: "countrySelected",payload:[]})
        dispatch({type: "citySelected",payload:[]})
        dispatch({type: "hotels",payload:[]})
        dispatch({type: "hotels_id",payload:[]})
        dispatch({type: "include",payload:true})
        dispatch({type:"hotels_data",payload:[]})
        setData({ data: [] })
        sethotelData([])
        setCity([])
        props.hotelSelected(state.hotelsSelectedList)
    } else {
        dispatch({
            type: "notiMessage",
            payload: "City Data is already added",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true })
          hidemessage()
    }
    } else {
        dispatch({
            type: "notiMessage",
            payload: "Please select City ",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true })
          hidemessage()
    }
    }
    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const handleDelete = (obj,idx) => () => {
        if (obj !== undefined && obj.id !== undefined) {
            props.hotelDeleteId(obj.id)
            let filtered_response = state.hotelsSelectedList.filter((s, sidx) => idx !== sidx)
            dispatch({type:"hotelsSelectedList",payload:filtered_response})
            let filtered_city = applicableArray.filter((s, sidx) => idx !== sidx)
            applicableArray = filtered_city
            props.updatedHotelData(filtered_response)
        } else {
            let filtered_response = state.hotelsSelectedList.filter((s, sidx) => idx !== sidx)
            dispatch({type:"hotelsSelectedList",payload:filtered_response})
            let filtered_city = applicableArray.filter((s, sidx) => idx !== sidx)
            applicableArray = filtered_city
            props.updatedHotelData(filtered_response)

        }
    }

    /**
     * @description : hafter clicking on edit button the json construction and calls are done here.
     * @author : Azamuddin
     * @date : 06-05-2021

     */
    const handleEdit =  (obj,idx) => () => {

        dispatch({type:"enableEdit",payload: true})
        let filtered_response = state.hotelsSelectedList.filter((s, sidx) => idx !== sidx)
            dispatch({type:"hotelsSelectedList",payload:filtered_response})
        //edit data for selected hotelsSelectedList
        //country selected
        if(obj.cnt && obj.cnt_code) {
            let cnt_obj = {
                "value": obj.cnt_code,
                "label": obj.cnt
            }
            dispatch({type:"countrySelected",payload: [cnt_obj]})
        }
        //include radio button
        dispatch({type:"include",payload: obj.include})
        dispatch({type:"id",payload:obj.id ? obj.id : 0})
        //city selected
        if(obj.cty && obj.cty_code && obj.cty !== "All") {
            let cty_obj = {
                "value":obj.cty_code,
                "label": obj.cty
            }
            let arr = [];
            arr.push(cty_obj)
            if(arr.length > 0) {
            state.citySelected = arr
            setEditCity(arr)
            let filtered_city = applicableArray.filter((s, sidx) => idx !== sidx)
            applicableArray = filtered_city
            }
            // dispatch({type:"citySelected",payload:[cty_obj]})
            //fetch city predictive to show in front
            if(obj.cty !== "All") {            
                // call for select hotels
                PredectiveSearch.retreieveSearch(obj.cty).then(response => {
                if(response.data.status == 'success') {
                    setCity([])
                    let city = []
                    let citiesList = response.data.data.city
                    dispatch({type:"cities",payload:citiesList})
                    if(citiesList.length > 0) {
                        let filtered_city = citiesList.filter((s, sidx) => s.text == obj.cty)
                        setCity(filtered_city)
                    }

                    let htls = []
                    let data = response.data.data.property
                    if(data.length > 0) {
                    // for(let val in response.data.data.property) {
                        htls = data.map(hotel_data=>({
                            "value":hotel_data.expediapid,
                            "label":hotel_data.description
                    }))
                        // hotels.push(obj)
                    // }
                    let hotels = getUnique(htls, "value");
                    dispatch({type:"cityHtls",payload:hotels})
                    dispatch({type:"hotels_data",payload:hotels})
                    if(obj.htl_obj.length === 0) {
                        sethotelData(hotels)
                        setEditH(hotels)
                        dispatch({typr:"hotels",payload:hotels})
                    } else {
                        let arr = []
                        let id = []
                        for(let val of obj.htl_obj) {
                            let obj = {
                                "value": val.id,
                                "label":val.name
                            }
                            arr.push(obj)
                            id.push(val.name)
                        }
                        sethotelData(arr)
                        dispatch({typr:"hotels",payload:arr})
                        setEditH(arr)
                        setEditHc(id)
                        dispatch({type:"hotelId",payload:id})
                    }
                }
                }

            })
        }

        }
    }
    /**
     * @description : after clicking on update button the json update are done here.
     * @author : Azamuddin
     * @date : 06-05-2021

     */
    const update = () => {
        let arr = []
        let hotels_persist = []
        let existAll = []
        if(state.hotels_data.length === editHotel.length || editHotel.length === 0) {
            const exist = state.hotels_data.some(existingItem=> existingItem.value==="All");
            if(exist){
            let obj = {
            "include": state.include,
            "id": "All",
            "name": "All"
            }
            existAll.push(obj)
            hotels_persist = []
         }else{
            existAll = []
            hotels_persist = []
        }
        } else {
        for (let val of editHotel) {
            let obj = {
                "include": state.include,
                "id": val.value,
                "name": val.label
            }
            hotels_persist.push(obj)
        }
    }
        //to check city and country data as hotel data is not mandatory
        if(state.countrySelected.length > 0) {
            let obj = {};
            //if old saved data
            if(state.id !== 0) {
                obj = {
                    "id": state.id,
                    "cnt": state.countrySelected[0].label,
                    "cty": editCity.length > 0 ? editCity[0].label : "All",
                    "cnt_code": state.countrySelected[0].value,
                    "cty_code":editCity.length > 0 ?  editCity[0].value : "All",
                    "include": state.include,
                    "htl_obj": hotels_persist.length==0?existAll:hotels_persist,
                    "htl_id": hotels_persist.length > 0 ? state.hotelId : "All",
                    "update" : true
                };
            } else {
                //new data which was edited
                obj = {
                    "cnt": state.countrySelected[0].label,
                    "cty": editCity.length > 0 ? editCity[0].label : "All",
                    "cnt_code": state.countrySelected[0].value,
                    "cty_code": editCity.length > 0 ?  editCity[0].value : "All",
                    "include": state.include,
                    "htl_obj": hotels_persist.length==0?existAll:hotels_persist,
                    "htl_id": hotels_persist.length > 0 ? editHotelId : "All"
                }
            }
         arr = [obj]
         if(editCity.length > 0) {
         applicableArray.push(editCity[0].label)
         }
        // dispatch({ type: "hotelsSelectedList", payload: { ...arr } })
        state.hotelsSelectedList.push(obj)
        setApplicableDate(true)
        dispatch({type: "countrySelected",payload:[]})
        dispatch({type: "citySelected",payload:[]})
        dispatch({type: "hotels",payload:[]})
        dispatch({type: "hotels_id",payload:[]})
        dispatch({type: "include",payload:true})
        dispatch({type:"hotels_data",payload:[]})
        dispatch({type:"enableEdit",payload:false})
        setData({ data: [] })
        sethotelData([])
        setCity([])
        setEditCity([])
        setEditH([])
        setEditHc([])
        props.updatedHotelData(state.hotelsSelectedList)
    } else {
        dispatch({
            type: "notiMessage",
            payload: "Please select City ",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true })
          hidemessage()
    }
    }
    /**
     * @description : array to string coversion divided by commas
     * @author : Azamuddin <
     * @date : 12-05-2021
     */
    const conversion = (data) => {
        let str = "";
        if(data && data !== "All") {
        for(let val of data) {
            if(str === "") {
                str = val
            } else {
                str = str + " , " +val
            }
        }

        return str
    } else {
        return data
    }
    }
    return (
        <>

            {state.notiMessageShow &&
                <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                    onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                    dismissible> {state.notiMessage}</Alert>
            }
            <Form.Row>
                <Form.Group as={Col} xs={3} className="selectplugin" controlId="icalInput">
                    <Select
                        options={state.countries}
                        values={state.countrySelected}
                        disabled={state.enableEdit}
                        onChange={(value) => (handleCountryChange(value))}
                    />
                    <Form.Label>Country</Form.Label>
                </Form.Group >
                <Form.Group as={Col} xs={3} className="selectplugin">
                    {/* <Select
                        options={state.cities}
                        values={state.citySelected}
                        onChange={(value) => (handleCityChange(value))}
                    /> */}
                      <Form.Label>City<sup>*</sup></Form.Label>
                     <Typeahead
                                        //defaultInputValue={state.selectedOrigin}
                                        id="originmaster"
                                        labelKey={ta => `${ta.text}`}
                                        onInputChange={fetchCity}
                                        options={state.cities}
                                        selected={city}
                                        onChange={ta => (handleCityChange(ta))}
                                        disabled={state.enableEdit}
                                        //onFocus={originOnFocus} onBlur={originOnBlur}
                                        placeholder="Select City"
                                        readOnly
                                        minLength={3}
                                        // onFocus={activeOrigin}
                                    />
                    <Form.Label>City</Form.Label>
                </Form.Group>
            </Form.Row>
            {props.enableHotel &&
            <Form.Row>
                <Form.Group as={Col} className="d-flex align-items-center">
                    <Form.Check type="radio" label="Include" value={state.ctyp} onChange={handleTypeChange} checked={state.include} custom name="ctype" id="include" />
                    <Form.Check type="radio" label="Exclude" value={state.ctyp} onChange={handleTypeChange} checked={!state.include} custom name="ctype" id="exclude" />
                </Form.Group>
            </Form.Row>
             }
            {props.enableHotel &&
            <Form.Row>
                <Form.Group as={Col} className="picklist" xs={6}>

                    <PickList leftPaneLabel="Available Hotels" rightPaneLabel="Selected Hotels"
                    ofcsData={selData} type={state.compType} dataList={state.hotels_data} sendData={setAir} />
                    <Form.Label>Hotels</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                    {!state.enableEdit ? (
                    <Button
                        size="xs"
                        variant="outline-primary"
                        onClick={openApplicableDate}
                        type="submit"
                    > Add</Button>) : (
                        <Button
                        size="xs"
                        variant="outline-primary"
                        onClick={update}
                        type="submit"
                    > Update</Button>
                    )
}
                </Form.Group>
            </Form.Row>
}
            {applicableDate && state.hotelsSelectedList.length > 0 && <div className="selectedRout tableLayout hotelListtab mb-4">
                <Table>
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>City</th>
                            <th>Type</th>
                            <th>Hotels Selected</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.hotelsSelectedList.map((hotelData, i) => (
                            <React.Fragment key={i}>
                            <tr>
                                <td>
                                    <span>{hotelData.cnt}</span>
                                </td>
                                <td>
                                    <span>{hotelData.cty}</span>
                                </td>
                                <td>
                                    <span>{hotelData.htl_obj.length > 0 ? (hotelData.htl_obj[0].include === 1 || hotelData.htl_obj[0].include ? "Include" : "Exclude") : "Include"}</span>
                                </td>
                                <td>
                                    <span className='hotelMore'>{conversion(hotelData.htl_id)}</span>
                                </td>

                                <td>
                                    {!props.disabledBtn &&
                                        <>
                                <Button
                                        size="xs"
                                        onClick={handleEdit(hotelData,i)}
                                        className="ezyIcon icon-edit"
                                        variant="link">
                                    </Button>
                                    {!state.enableEdit &&
                                    <Button
                                        size="xs"
                                        onClick={handleDelete(hotelData,i)}
                                        className="ezyIcon icon-delete"
                                        variant="link">
                                    </Button>
}
</>
}
                                </td>
                            </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>}

        </>
    )
}
export default HotelSelected