import AirConfigurationService from '../../../../services/administration/AirConfigurationService';
// import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for promo
 * @author: arshad
 * @param {*}
 * @function Branch
 * @date : 18-11-23
 */


// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const creation = async (obj) => {
    let finalRes = [];
    await AirConfigurationService.savePromoDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// retrieve promo
export const retrievePromo = async (obj) => {
    let ofcList = [];
    await AirConfigurationService.getpromo(obj).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const update = async (obj) => {
    let finalRes = [];
    await AirConfigurationService.updatePromoDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// // ========================================================================
// //  Branch Create Operation
// //  This will create Branch and save the details 
// // ========================================================================
export const editPromoCode = async (id) => {
    let finalRes = [];

    await AirConfigurationService.editPromo(id).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deletePromoRoutes = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deletePromoRoute(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deletePromoAplDate = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deletePromoAplDates(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================

export const deletePromoFMap = async (id) => {
    let ofcList = [];
        await AirConfigurationService.deletePromoFMap(id).then(response => {
            ofcList = response;
        });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const delPromoClass = async (id) => {
    let ofcList = [];
    await AirConfigurationService.deletePromoClass(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
