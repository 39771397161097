
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Alert, Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';
import { purchaseServicePostCall,checkPayment} from '../../services/payment/paymentService'
import { deductCreditLimit, fireHotelBook, fireHoldHotel, updateHoldPayment,fireHotelOtillaBook} from '../bookingFlows/hotel/payment/opearations';
import Utilities from '../commonUtils/Utilities';
import Processing from '../common/processingPopUp/Processing'
import { confirmNomoreDlg } from '../common/sessionTimeOut/SessionNomore';
import FormateCurrency from '../commonUtils/FormateCurrency';
import Paymentprocessingimg from '../../assets/images/Payment-Processing.gif';
import { Scrollbars } from 'react-custom-scrollbars';
import Naps from '../../assets/images/NAPSS.jpg'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils";
export const USER_AUTH_DATA = "userAuthData";
export const PAY_DATA = "payData";
export const PAY_REQ = "payRequest";
export const PAY_RESP = "payResp";
export const PRODUCT_TYPE = "productType";
export const GET_PAY_LINK= "PayLink";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";

let payment=false;
let isPaymentFailed=false;
class PaymentDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMsgs: [],
      bookingStatus: 'payment-done',
      errorExists: false,
      isLoading: false,
      pleasewait: false,
      name: '',
      number: '',
      expiry: '',
      cvc: '',
      focused: '',
      issuer: '',
      formData: '',
      surchrge: 100,
      bookSuc: false,
      terms: false,
      notiMessage: '',
      notiMessageShow: false,
      notiVarient: '',
      showTerms: false,
      pnr: "",
      clBal: 0,
      enablePayPopup:false,
      showCl: false, clCurrentBal: 0, payAmount: 0, overcl: false, showOverCl: false, showHoldPop: false, holdResp: "", showFailed: false,
      pay_type:"Credit Card"
    };

    //Callling Ticket request after Payment
    var bookingData=JSON.parse(window.sessionStorage.getItem(PAY_DATA));
    if(bookingData!==undefined && bookingData!==null){
      payment=true;
      this.checkPaymentResp(bookingData);
    }
  }

  //Checking the Payment response and firing the Ticketing service
  checkPaymentResp=(bookingData)=>{
    var paylink=window.sessionStorage.getItem(GET_PAY_LINK);
    let pay_resp=window.sessionStorage.getItem(PAY_RESP);
    if(paylink!==null && paylink!==undefined && pay_resp!==null){
    var payReq=JSON.parse(window.sessionStorage.getItem(PAY_REQ));
    window.sessionStorage.removeItem(GET_PAY_LINK);
    checkPayment({"url":paylink}).then((resp) => {
    if(resp.data.suc){
      window.sessionStorage.removeItem(PAY_DATA);
      window.sessionStorage.removeItem(PAY_REQ);
      window.sessionStorage.removeItem(PAY_RESP);
      window.sessionStorage.removeItem(PRODUCT_TYPE);
      let bookReq=this.bookingPayRequest(bookingData,payReq,resp.data);
      fireHotelBook(bookReq).then((response) => {
        this.props.history.push({
          pathname: '/ezytrip/bookings/hotel/confirmation',
          state: {
            req: response
          }
        });
      });
    }else{
      return window.location="/ezytrip/bookings/pay-fail";
    }
  });
  }
}

getUrlVars(url) {
  var hash;
  var myJson = {};
  var hashes = url.slice(url.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      myJson[hash[0]] = hash[1];
      // If you want to get in native datatypes
      // myJson[hash[0]] = JSON.parse(hash[1]);
  }
  return myJson;
}

 confirmHotelBooking= (e) => {
  e.preventDefault();
  //Call Expedia
  if(this.props.bdata.sup==="0" || this.props.bdata.sup===0){
    this.confirmBooking();
  }else{//Call Otilla
    this.confirmOtilaBooking();
  }
}

//Calling Otilla Confirm Booking
confirmOtilaBooking = () => {
  if(this.state.terms){
  this.setState({
    isLoading: true,
    pleasewait: true
  })
   window.sessionStorage.removeItem(CHECK_BOOKING);
   window.sessionStorage.removeItem(BOOKING_REF_NO);
   let bookReq = this.bookRequest("CL", null, null, false, this.props.bdata.enableOvrwriteCL);
   bookReq.ot_prebook_res=this.props.bdata.otila_prebk_res;
   bookReq.roompax=this.props.bdata.roompax;
   bookReq.otila_prebk_req=this.props.bdata.otila_prebk_req;
   bookReq.avail_room_details=this.props.bdata.ot_room_details;
   fireHotelOtillaBook(bookReq).then((resp) => {
     if (resp.suc && resp.data !== undefined && resp.suc) {
       if (this.props.bdata.butype === "sta_user") {
         this.convertCLObj(this.props.bdata, "deduct", false);
       }
     }
     this.props.history.push({
       pathname: '/ezytrip/bookings/hotel/confirmation',
       state: {
         req: resp
       }
     });
   });
  }else{

    this.setState({
  
      notiMessageShow: true,
  
      notiMessage: "Please accept terms and conditions and proceed",
  
      notiVarient: 'danger',
  
      isLoading: false
  
    })
  
    this.hidemessage()
  
  }
}

//Creating the Payment request
bookingPayRequest=(bookingData,payreq,payresp)=>{
  const payload = {
    "hid": bookingData.hid,
    "bookId": bookingData.bookingId,
    "cha": "B2C",
    "cur": bookingData.req_cur,
    "butype": bookingData.butype,
    "ofcId": bookingData.ofcId,
    "bktype": bookingData.bookingType,
    "uid": bookingData.userId,
    "region_type":bookingData.region_type,
    "pay": [{
      "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
      "amt": bookingData.price,
      "ccode": bookingData.cur,
      "psta": "Confirmed",
      "pgauthid": payresp.res.id,
      "pgtid": payresp.res.organizationId,
      "pgstc": "000",
      "csur": this.state.surchrge,
      "ctype": "VISA",
      "cno": payreq.card,
      "cname": payreq.member,
      "cexp": payreq.expMonth + "/" + payreq.expYear,
      "cvfr": ""
    }, {
      "ptype": "Credit Limit",
      "amt": bookingData.price,
      "ccode": bookingData.cur,
      "crbal": bookingData.creditBal,
      "psta": "Pending",
      "formofpay": ""
    }]
  }
  return payload;
}




  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    if (this.state.terms) {
      this.setState({
        terms: false
      });
    } else {
      this.setState({
        terms: true
      });
    }
  }

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });

    // Crad type validation
    if (target.name === "number") {
      const x = target.value;
      const z = x.substring(0, 2);
      console.log(z);
      const numberFormated = target.value.replace(/\D/g, '')
      var patterns = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        AMEX: /^3[47][0-9]{13}$/,
        ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        DANKORT: /^(5019)\d+$/,
        INTERPAYMENT: /^(636)\d+$/,
        UNIONPAY: /^(62|88)\d+$/,
      }
      for (var key in patterns) {
        if (patterns[key].test(numberFormated)) {

          this.setState({
            issuer: key
          });
          return key
        }
      }
    }
  };
  //Calling Hotel Confirm Booking for CL
  confirmBooking = () => {
     window.sessionStorage.removeItem(CHECK_BOOKING);
     window.sessionStorage.removeItem(BOOKING_REF_NO);
    if (this.state.terms) {
      this.setState({
        isLoading: true,
        pleasewait: true
      })
      if (this.props.bdata.bookingType === "DIRECT") {
        let holdReq = { "bookId": this.props.bdata.bookingId, "hid": this.props.bdata.hid , "region_type":this.props.bdata.region_type,
        "req_cur":this.props.bdata.req_cur}
        fireHoldHotel(holdReq).then((response) => {
          if (response.suc && response.data.st == "success") {
            let bookReq = this.bookRequest("CL", null, null, false, this.props.bdata.enableOvrwriteCL);
            fireHotelBook(bookReq).then((bresp) => {
              if (bresp.suc && bresp.data !== undefined && bresp.data.st === "Confirmed") {
                if (this.props.bdata.butype === "sta_user") {
                  this.convertCLObj(this.props.bdata, "deduct", false);
                }
              }
              this.props.history.push({
                pathname: '/ezytrip/bookings/hotel/confirmation',
                state: {
                  req: bresp
                }
              });
            });
          } else {
            //Failed Hold and show pop upo
            //  let holdFailed=false;
            //  for(let val of response.data.rooms){
            //    if(val.stat!=="success"){
            //     holdFailed=true;
            //     break;
            //    }
            //  }
            //  if(holdFailed){
            //   this.setState({
            //     showFailed:true
            //   })
            //  }else{
            // this.setState({
            //   isLoading: false,
            //   pleasewait: false,
            //   showHoldPop:true,
            //   holdResp:response
            // })
            // }
            this.setState({
              showFailed: true,
              isLoading: false,
              pleasewait: false
            })
          }
        });
      } else {
        this.processOnholdPay(this.props.bdata.enableOvrwriteCL);
      }
    } else {
      this.setState({
        notiMessageShow: true,
        notiMessage: "Please accept terms and conditions and proceed",
        notiVarient: 'danger'
      })
      this.hidemessage()
    }
    sessionStorage.removeItem("COMPID");
  }
  hidemessage = () => {
    setTimeout(() => this.setState({
      notiMessageShow: false,
      notiMessage: "",
      notiVarient: ''
    }), 5000);
  }

  //Processing On hold payment
  processOnholdPay = (overRide) => {
    this.props.bdata.request.pay = [{
      "ptype": "",
      "amt": 0,
      "ccode": "",
      "psta": "Pending",
      "crbal": 0,
      "pgauthid": "",
      "pgtid": "",
      "pgstc": "",
      "csur": "",
      "ctype": "",
      "cno": "",
      "cname": "",
      "cexp": "",
      "cvfr": ""
    }, {
      "ptype": "Credit Limit",
      "amt": this.props.bdata.price,
      "ccode": this.props.bdata.cur,
      "crbal": this.props.bdata.creditBal - this.props.bdata.price,
      "psta": "Confirmed",
      "formofpay": overRide ? "Override CL" : ""
    }];
    this.props.bdata.request.upby = this.props.bdata.userId;
    updateHoldPayment(this.props.bdata.request).then((bresp) => {
      if (bresp.suc && bresp.res.data !== undefined && (bresp.res.data.st === "Confirmed" || bresp.res.data.st === "Partially Confirmed")) {
        if (this.props.bdata.butype === "sta_user") {
          this.convertCLObj(this.props.bdata, "deduct", false);
        }
      }
      this.props.history.push({
        pathname: '/ezytrip/bookings/hotel/confirmation',
        state: {
          req: bresp.res
        }
      });
    });
  }

  //Calling Hotel Confirm Booking for CL
  confirmHoldBooking = (e) => {
    e.preventDefault();
     window.sessionStorage.removeItem(CHECK_BOOKING);
     window.sessionStorage.removeItem(BOOKING_REF_NO);
    this.setState({
      isLoading: true,
      pleasewait: true,
      showHoldPop: false
    })
    let bookReq = this.bookRequest("CL", null, null, false);
    fireHotelBook(bookReq).then((bresp) => {
      if (bresp.suc && bresp.data !== undefined && bresp.data.st === "success") {
        if (this.props.bdata.butype === "sta_user") {
          this.convertCLObj(this.props.bdata, "deduct", false);
        }
      }
      this.props.history.push({
        pathname: '/ezytrip/bookings/hotel/confirmation',
        state: {
          req: bresp
        }
      });
    });

  }
  //Converting Payload for Deduction
  convertCLObj = (data, type, partial) => {
    var bal = null;
    if (data.butype === "sta_user") {
      //Deducting Parent CL
      const payload = {
        "comid": data.cid,
        "method": type,
        "value": data.price,
        "pid": 0,
        "pvalue": 0,
        "cuscode": data.custCode
      }
      bal = this.deductCL(payload);
    }
    return bal;
  }

  //=====================================
  // Updating CL to Local storage
  //=====================================
  createCLPayload = (price) => {
    var userData = localStorage.getItem(USER_AUTH_DATA);
    var resObj = JSON.parse(userData);
    resObj.cbal = price;
    localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
  }


  //=====================================
  // Calling service for Deduct the CL
  //=====================================
  deductCL = (payload) => {
    deductCreditLimit(payload).then((response) => {
      if (response.data !== undefined && response.data.st !== undefined && response.data.st === "Insuficiant") {
        return response.data.bal;
      } else {
        return null;
      }
    });
  }


  //Creating Book request
  bookRequest = (flow, payresp, payreq, partialFlow, ovride) => {
    if (flow === "CL" && !partialFlow) {
      const payload = {
        "hid": this.props.bdata.hid,
        "bookId": this.props.bdata.bookingId,
        "cha": "B2C",
        "cur": this.props.bdata.req_cur,
        "butype": this.props.bdata.butype,
        "ofcId": this.props.bdata.ofcId,
        "bktype": this.props.bdata.bookingType,
        "uid": this.props.bdata.userId,
        "region_type":this.props.bdata.region_type,
        "pay": [{
          "ptype": "",
          "amt": 0,
          "ccode": "",
          "psta": "Pending",
          "crbal": 0,
          "pgauthid": "",
          "pgtid": "",
          "pgstc": "",
          "csur": "",
          "ctype": "",
          "cno": "",
          "cname": "",
          "cexp": "",
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": this.props.bdata.price,
          "ccode": this.props.bdata.cur,
          "crbal": this.props.bdata.creditBal - this.props.bdata.price,
          "psta": "Confirmed",
          "formofpay": ovride ? "Override CL" : ""
        }]
      }
      return payload;
    } else if (flow === "PAY" && !partialFlow) {
      const payload = {
        "hid": this.props.bdata.hid,
        "bookId": this.props.bdata.bookingId,
        "cha": "B2C",
        "cur": this.props.bdata.cur,
        "butype": this.props.bdata.butype,
        "ofcId": this.props.bdata.ofcId,
        "bktype": this.props.bdata.bookingType,
        "uid": this.props.bdata.userId,
        "pay": [{
          "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
          "amt": payreq.amount,
          "ccode": this.props.bdata.cur,
          "psta": "Confirmed",
          "pgauthid": payresp.authcode,
          "pgtid": payresp.tranid,
          "pgstc": payresp.responsecode,
          "csur": this.state.surchrge,
          "ctype": "VISA",
          "cno": payreq.card,
          "cname": payreq.member,
          "cexp": payreq.expMonth + "/" + payreq.expYear,
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": this.props.bdata.price,
          "ccode": this.props.bdata.cur,
          "crbal": this.props.bdata.creditBal,
          "psta": "Pending",
          "formofpay": ""
        }]
      }
      return payload;
    } else {
      const payload = {
        "hid": this.props.bdata.hid,
        "bookId": this.props.bdata.bookingId,
        "cha": "B2C",
        "cur": this.props.bdata.cur,
        "butype": this.props.bdata.butype,
        "ofcId": this.props.bdata.ofcId,
        "bktype": this.props.bdata.bookingType,
        "uid": this.props.bdata.userId,
        "pay": [{
          "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
          "amt": payreq.amount,
          "ccode": this.props.bdata.cur,
          "psta": "Confirmed",
          "pgauthid": payresp.authcode,
          "pgtid": payresp.tranid,
          "pgstc": payresp.responsecode,
          "csur": this.state.surchrge,
          "ctype": "VISA",
          "cno": payreq.card,
          "cname": payreq.member,
          "cexp": payreq.expMonth + "/" + payreq.expYear,
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": this.props.bdata.price,
          "ccode": this.props.bdata.cur,
          "crbal": this.props.bdata.creditBal - this.props.bdata.price,
          "psta": "Confirmed",
          "formofpay": ""
        }]
      }
      return payload;
    }
  }



  //=====================================
  // Calling Payment service
  //=====================================
  confirmPayment = (e) => {
    e.preventDefault();
    if(this.state.terms) {
      this.setState({
        isLoading: true,
        pleasewait: true
      })
      let totalprice = "";
      let partial = false;
      let clBal = 0;
      let holdReq = { "bookId": this.props.bdata.bookingId, "hid": this.props.bdata.hid, "region_type":this.props.bdata.region_type,
      "req_cur":this.props.bdata.req_cur, }
      fireHoldHotel(holdReq).then((response) => {
        if (response.suc && response.data.st === "success") {
          if (this.props.bdata.paytype === "credit" && this.props.bdata.diffamt !== 0 && this.props.bdata.diffamt > 0) {
            totalprice = Utilities.addPrice(this.props.bdata.diffamt, this.state.surchrge, "Add");
            partial = true;
            clBal = Math.round(Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub"));
          } else {
            totalprice = Utilities.addPrice(this.props.bdata.price, this.state.surchrge, "Add");
          }
          //Creating the Payment request
          var obj="";
          if(this.state.pay_type==="Credit Card"){
          obj =
          {
            card: this.state.number.replace(/ +/g, ""),
            cvv2: this.state.cvc,
            expYear: this.state.expiry.split('/')[1],
            expMonth: this.state.expiry.split('/')[0],
            member: this.state.name,
            currencyCode: 'QAR',
            address: '',
            city: '',
            statecode: '',
            zip: '',
            CountryCode: 'QA',
            "email": "anil@test.com",
            amount: totalprice,
            chanal:"ATT_B2B",
            bookingid:this.props.bdata.bookingId,
            product:"Hotel",
            cardtype:"creditcard",
            flow_type:"B2B"
          }
        }else{
          obj =
          {
            currencyCode: 'QAR',
            amount: "1.00",
            cardtype:"naps",
            booking_id:this.props.bdata.bookingId,
            flow_type:"B2B"
          };
        }
          purchaseServicePostCall(obj).then((response) => {
            if(response.data.suc){
              window.sessionStorage.setItem(PRODUCT_TYPE, "Hotel");
              window.sessionStorage.setItem(PAY_DATA, JSON.stringify(this.props.bdata));
              window.sessionStorage.setItem(PAY_REQ, JSON.stringify(obj));
              window.sessionStorage.setItem(GET_PAY_LINK,response.data.res.self);
              return window.location=response.data.res.checkout;
            } else {
              this.setState({
                isLoading: false,
                pleasewait: false
              })
              this.confirmDlg();
            }
          })
        } else {
          //Failed Hold and show pop upo
          this.setState({
            showFailed: true,
            isLoading: false,
            pleasewait: false
          })
          // let holdFailed=false;
          // for(let val of response.data.rooms){
          //   if(val.stat!=="success"){
          //     holdFailed=true;
          //     break;
          //    }
          //  }
          //  if(holdFailed){
          //   this.setState({
          //     showFailed:true
          //   })
          //  }else{
          // this.setState({
          //   isLoading: false,
          //   pleasewait: false,
          //   showHoldPop:true,
          //   holdResp:response
          // })
          // }
        }
      });
    } else {
      this.setState({
        notiMessageShow: true,
        notiMessage: "Please accept terms and conditions and proceed",
        notiVarient: 'danger'
      })
      this.hidemessage();
    }
  }

  //=====================================
  // Calling Payment service
  //=====================================
  confirmHoldOnlinePayment = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      pleasewait: true,
      showHoldPop: false
    })
    let totalprice = "";
    let partial = false;
    let clBal = 0;
    if (this.props.bdata.paytype === "credit" && this.props.bdata.diffamt !== 0 && this.props.bdata.diffamt > 0) {
      totalprice = Utilities.addPrice(this.props.bdata.diffamt, this.state.surchrge, "Add");
      partial = true;
      clBal = Math.round(Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub"));
    } else {
      totalprice = Utilities.addPrice(this.props.bdata.price, this.state.surchrge, "Add");
    }
    //Creating the Payment request
    const obj =
    {
      card: this.state.number.replace(/ +/g, ""),
      cvv2: this.state.cvc,
      expYear: this.state.expiry.split('/')[1],
      expMonth: this.state.expiry.split('/')[0],
      member: this.state.name,
      currencyCode: 'QAR',
      address: '',
      city: '',
      statecode: '',
      zip: '',
      CountryCode: 'QA',
      "email": "anil@test.com",
      amount: totalprice
    }
    purchaseServicePostCall(obj).then((response) => {
      if (response.data.result === "Successful" && response.data.responsecode === "000" && response.data.error === undefined) {
        let bookReq = this.bookRequest("PAY", response, obj, partial, false);
        fireHotelBook(bookReq).then((response) => {
          if (response.suc) {
            if (clBal !== 0) {
              this.createCLPayload(clBal);
              if (this.props.bdata.butype === "sta_user") {
                this.convertCLObj(this.props.bdata, "deduct", true);
              }
            }
            this.props.history.push({
              pathname: '/ezytrip/bookings/hotel/confirmation',
              state: {
                req: response
              }
            });
          } else {
            this.props.sendFailedMsg();
          }
        });
      } else {
        this.setState({
          isLoading: false,
          pleasewait: false
        })
        this.confirmDlg();
      }
    })
  }


  showTermsConditions = () => {
    this.setState({
      showTerms: true
    })
  }

  handleClose = () => {
    this.setState({
      showTerms: false
    })
  }
  handleCLClose = () => {
    this.setState({
      showCl: false
    })
  }

  handleOverClose = () => {
    this.setState({
      showOverCl: false
    })
  }

  handleChangeOver = (e) => {
    this.setState({
      terms: false
    });
    const { id, value } = e.target;
    if (this.state.overcl) {
      this.setState({
        overcl: false,
        showOverCl: false,
      });
    } else {
      this.setState({
        overcl: true,
        showOverCl: true
      });
    }
  }
  confirmChangeOver = (e) => {
    const { id, value } = e.target;
    this.setState({
      overcl: true,
      showOverCl: false,
      terms: false
    });
  }
  //Confirmation dialog popup to delete the Branch
  confirmDlg = () => {
    confirmNomoreDlg('Payment', function () {
      return window.location = "/ezytrip/dashboard";
    });
  };

  //Back To results page
  backToResults = (e) => {
    this.setState({
      showFailed: false
    })
    //return window.location = "/ezytrip/bookings/hotel/results";
  }

  backToHome=()=>{
    return window.location="/ezytrip/dashboard";
  }

  handlePayChange=(type)=>{
    if(type == "credit"){
      this.setState({
        pay_type : "Credit Card",
      })
    }else if(type == "debit"){
      this.setState({
        pay_type : "Debit Card",
      })
    }else if(type == "apple"){

    }
   }

  //  gotogent = () => {
  //     return window.location = ("/ezytrip/gent/termsandconditions");
  //   }
  //   gotosupplier = () => {
  //     return window.location = ("/ezytrip/supplier/termsandconditions");
  //   }

  render() {
    return (
      <>
        <Alert className="notification" variant={this.state.notiVarient} show={this.state.notiMessageShow}
          onClose={() => this.setState({
            notiMessageShow: false
          })}
          dismissible> {this.state.notiMessage}</Alert>
          {payment ? <Processing/> : "" }

        <div key="Payment">
        {this.props.bdata!==undefined && !this.props.bdata.enableHold &&  Math.sign(this.props.bdata.creditBal)!==1 && this.props.bdata.utype !== "att_user" &&
          <Alert variant="info">
         <span>You don't have enough Balance to do the booking. Please contact Ezytrip Support Team for any queries!</span>
         </Alert>
         }
         {this.props.bdata!==undefined && this.props.bdata.enableHold &&  Math.sign(this.props.bdata.creditBal)!==1 && this.props.bdata.utype !== "att_user" &&
          <Alert variant="info">
         <span>You don't have enough balance to confirm the booking, use the hold booking or contact ezytrip support team</span>
         </Alert>
         }
          {(this.props.bdata!==undefined && this.props.bdata.enableOvrwriteCL && this.props.bdata.utype === "att_user" && this.props.bdata.overwriteRole || this.props.bdata.enableOveride) &&
            <div>
              <div className="overwrite">

                <div className="totalPay mb-2">
                  <span>The amount going to be debited from your credit balance is :</span><span><strong> {this.props.bdata.cur} <FormateCurrency value={this.props.bdata.price} /> (Purchase Amount) </strong></span>
                </div>
                <div className="creditchk">
                  <Form.Check
                    type="checkbox"
                    id="ov"
                    label="Override Credit Balance"
                    checked={this.state.overcl}
                    value={this.state.overcl}
                    onChange={this.handleChangeOver}
                    custom
                  />
                </div>

                <div className="creditchk note">
                  <span>Note : Your updated credit balance will be : <strong> {this.props.bdata.cur}<FormateCurrency value={Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub")} /></strong></span>
                </div>
              </div>
              <br />
              {this.state.overcl &&
                <>
                  <Row className="htlConfirm">
                    <Col xs="9">
                      <div className="d-flex mt-2 tc"><Form.Check
                        type="checkbox"
                        id="cb"
                        label=""
                        value={this.state.terms}
                        onChange={this.handleChange}
                        custom
                      /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>

                    </Col>
                    <Col xs="3" className="d-flex justify-content-end align-items-center">
                      <div className="d-flex justify-content-end">
                        <Button variant="success" size="lg"
                          onClick={this.confirmHotelBooking}
                          className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
                          disabled={this.state.isLoading && this.state.pleasewait ? true : false} >
                          {this.state.isLoading ? "Processing..." : "Confirm"}
                        </Button>
                      </div>
                      {this.state.pleasewait ? <Processing /> : ""}
                    </Col>
                  </Row>


                </>
              }
            </div>
          }
          {this.props.bdata!==undefined && this.props.bdata.paytype === "credit" && !this.state.showOverCl && this.props.bdata.diffamt > 0 &&
            <div>
              <div className="totalPay d-flex justify-content-between align-items-center"><span>The amount going to be debited from your credit balance is</span><span>Total: <strong> {this.props.bdata.cur} <FormateCurrency value={this.props.bdata.creditBal} /> </strong></span></div>
              <div className="cardHilight">
                <p className="m-0">Your credit balance is too low to confirm this booking. You can pay remaining amount by credit card for instant confirmation.</p>
                <div className="creditINfo d-flex flex-column align-items-end">
                  <span>Balance: <strong>{this.props.bdata.cur} {this.props.bdata.diffamt}</strong></span>
                  <span>Handling Fee: <strong>{this.props.bdata.cur} {this.state.surchrge}</strong></span>
                </div>
              </div>
            </div>
          }
            {this.props.bdata!==undefined && this.props.bdata.enableOnlinePay && !this.state.overcl && this.props.bdata.enablePayment &&
            <form ref={c => (this.form = c)} onSubmit={this.confirmPayment}>
              <div className="cardPayment">

              <div className='cardPaymentin'>
                <div className="paymentTop d-flex justify-content-between">
                  <div>
                    <div className='creditCardr'>

                    <Form.Check
                      type="radio"
                      id="credit"
                      label=" "
                      checked={this.state.pay_type==="Credit Card"}
                      value={this.state.pay_type}
                      onChange={()=>this.handlePayChange("credit")}
                      custom
                    />
                          <h6 className="subTitle m-0">Credit Card</h6>
                    </div>
                    <p>Enter your card details to continue payment process</p>
                  </div>
                  {this.props.bdata.diffamt > 0 && <span>Pay: <strong>{this.props.bdata.cur} <FormateCurrency value={Utilities.addPrice(this.props.bdata.diffamt, this.state.surchrge, "Add")} /></strong></span>}
                  {this.props.bdata.diffamt === 0 && this.props.bdata.price > 0 &&
                    <div className="tobePaid topTotall">
                      <div className='priceupdates'><span>Total: </span> <div className="pricetag"> <strong>{this.cur} <FormateCurrency value={Utilities.addPrice(this.props.bdata.price, 0, "Add")} /> </strong></div></div>
                      <div className='priceupdates'><span>Handling Fee: </span> <div className="pricetag"><strong>{this.cur} {this.state.surchrge}</strong></div></div>
                      <div className='priceupdates'><span>Pay: </span> <div className="pricetag"> <strong>{this.cur} <FormateCurrency value={Utilities.addPrice(this.props.bdata.price, this.state.surchrge, "Add")} /></strong></div></div>
                    </div>
                  }
                </div>
                <>
                  <Form.Row autoComplete="off">
                    <Form.Group as={Col} xs={9} className="cardnumber">
                      <input
                        type="tel"
                        name="number"
                        className="form-control"
                        placeholder="Enter Card Number"
                        pattern="[\d| ]{16,22}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        disabled={this.state.pay_type==="Debit Card"}
                      />
                      {/* <span className={"icon cardPhoto " + this.state.issuer}></span> */}
                      <Form.Label>Card number</Form.Label>
                      <span className="ezyIcon icon-creditCards"></span>
                    </Form.Group>

                    <Form.Group as={Col} xs={9} >
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        autoComplete="off"
                        disabled={this.state.pay_type==="Debit Card"}
                      />
                      <Form.Label>Holder Name</Form.Label>
                      <span className="ezyIcon icon-profile"></span>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} xs={5} >
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="MM/YY"
                        pattern="\d\d/\d\d"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        disabled={this.state.pay_type==="Debit Card"}
                      />
                      <Form.Label>Expiry date(MM/YY)</Form.Label>
                      <span className="ezyIcon icon-newcalendar"></span>
                    </Form.Group>
                    <Form.Group as={Col} xs={4}>
                      <input
                        type="tel"
                        name="cvc"
                        className="form-control"
                        placeholder="CVC"
                        pattern="\d{3}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        disabled={this.state.pay_type==="Debit Card"}
                      />
                      <Form.Label>CVV</Form.Label>
                      <span className="ezyIcon icon-lock"></span>
                      <small>The 3 digit number printed on the back of the card</small>
                    </Form.Group>
                  </Form.Row>
                </>
                <input type="hidden" name="issuer" value={this.state.issuer} />
                </div>
                <div className='brdLinee'></div>




           <div className='cardPaymentin'>
            <div className="d-flex tc dcardMain">
              <div className='d-flex align-items-center justify-content-start '>

              <Form.Check
              type="radio"
              id="debit"
              label=" "
              checked={this.state.pay_type==="Debit Card"}
              value={this.state.pay_type}
              onChange={()=>this.handlePayChange("debit")}
              custom
             />
             <h6 className="subTitle m-0">Debit Card</h6>
             </div>
             <div className='debitCardLg'>
                <img src={Naps} />
                </div>

                </div>
                <span> We are redirecting to QPay Page</span>
                <div className='dCardcontent'>
              {/* <span className='piconLogo'><img width={40}
                        src={""}
                        className="apaylogo"
                        title="ATT"
                        alt="Apay"
                    /></span> */}

          <span className='debitCardd'>

                {this.props.bdata.diffamt===0 && this.props.bdata.price>0 &&
          <div className="tobePaid">
          <div className="priceupdates"><span>Total :  </span > <div className="pricetag"> <em>{this.cur}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,0,"Add")} /></strong></div></div>
          <div className="priceupdates"> <span>Handling Fee :  </span> <div className="pricetag"> <em>{this.cur}</em><strong> <FormateCurrency value={this.state.surchrge} /> </strong></div></div>
          <div className="priceupdates"> <span>Pay :  </span> <div className="pricetag"> <em>{this.cur}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,this.state.surchrge,"Add")} /> </strong></div></div>
          </div>
          }

          </span>
           </div>
                </div>



              </div>
              <Row className="htlConfirm">
                  <Col xs="9">
                  <div className="d-flex mt-2 tc"><Form.Check
                type="checkbox"
                id="cb"
                label=""
                value={this.state.terms}
                onChange={this.handleChange}
                custom
              /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>
                     <br/>

                  </Col>
                  <Col xs="3" className="d-flex justify-content-end align-items-center">
                  <div className="d-flex justify-content-end">
                  <Button variant="success" size="lg" className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')} disabled = {this.state.isLoading && this.state.pleasewait ? true : false } >{this.state.isLoading ? "Processing..." : "Confirm and Pay" } </Button>
                  </div>
                  {this.state.pleasewait ? <Processing /> : ""}
                  </Col>
                </Row>
            </form>
          }
        </div>

        {this.props.bdata!==undefined && this.props.bdata.isclavail && this.props.bdata.creditBal !== 0 &&

          <div className="conffirm crdt">
            <div className="totalPay mb-2">
              <span className="mr-1">The amount going to be debited from your credit balance is  : </span> <strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price, 0, "Add")} /> (Purchase Amount)</strong></div>
            <div className="totalPay">
              <span className="mr-1">The remaining credit will be  : </span><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.creditBal, this.props.bdata.price, "Sub")} /></strong></div>
            <Row className="htlConfirm">
              <Col xs="9">
                <div className="d-flex mt-2 tc"><Form.Check
                  type="checkbox"
                  id="cb"
                  label=""
                  value={this.state.terms}
                  onChange={this.handleChange}
                  custom
                /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>

              </Col>
              <Col xs="3" className="d-flex justify-content-end align-items-center">
                <div className="d-flex justify-content-end">
                  <Button variant="success" size="lg"
                    onClick={this.confirmHotelBooking}
                    className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
                    disabled={this.state.isLoading && this.state.pleasewait ? true : false} >
                    {this.state.isLoading ? "Processing..." : "Confirm"}
                  </Button>
                </div>
                {this.state.pleasewait ? <Processing /> : ""}
              </Col>
            </Row>
          </div>

        }
        <Modal
          show={this.state.showTerms}
          onHide={this.handleClose}
          className="importPNRMdl"
          backdrop="static"
          size="lg" dialogClassName="modal-90w"
          keyboard={false}>
          <Modal.Body>
            <h5>Agent terms of use</h5>
            <Scrollbars style={{ height: 500 }}>
              <div className="dstn">
                <p>These Agent Terms of Use (these Terms) govern the terms upon which you market Hotel Information and undertake Bookings for End Customers through the Approved Platforms.</p>
              </div>
            </Scrollbars>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.handleClose}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showCl}
          onHide={this.handleCLClose}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}>
          <Modal.Body>
            <div>
              <div className="custom-ui">
                <span className="confirmD"></span>
                <p className="m-0">Your credit balance is too low to confirm this booking.
                 You can pay remaining amount by using credit card for booking confirmation</p>
                <div className="creditINfo d-flex flex-column align-items-end">
                  <span>Credit Balance : <strong>QAR {this.state.clCurrentBal}</strong></span>
                  <span>Credit card Amount :<strong>QAR {this.state.payAmount}</strong></span>
                </div>
              </div>
              <p className="m-0">Do you want to contine the payment?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.handleCLClose}>
              Close
          </Button>
            <Button
              size="xs"
              variant="outline-primary"
              onClick={this.gotoPayment}
              type="submit"
            >Yes</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showOverCl}
          onHide={this.handleChangeOver}
          className="importPNRMdl creditPop"
          backdrop="static"
          keyboard={false}>
          <Modal.Body className="text-center clpopup">

            {/* <div className="popIcon" >
              <span className="icons clIcon"></span>
            </div> */}
            <div className="backGroundgif">
<span className='backGroundbot'></span>
<span className='aarabQar'>QAR</span>
<img src={Paymentprocessingimg} />
            </div>

            <h4>Credit balance alert!</h4>
            <div className="dstn">
              <p>{this.props.bdata!==undefined && this.props.bdata.cname} Doesn't have sufficient credit balance</p>

              <p>Would like to continue with the Overwrite credit balance?</p>
            </div>
            <div className="buttonGrop pb-0 border-0">
              <Button size="xs" variant="secondary" onClick={this.handleChangeOver}>
                Cancel
          </Button>
              <Button variant="primary" size="xs" onClick={this.confirmChangeOver}>
                Yes
          </Button>
            </div>
          </Modal.Body>

        </Modal>

        <Modal
          show={this.state.showFailed}
          onHide={this.backToResults}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}>
          <Modal.Body className="text-center clpopup">

            <div className="popIcon" >
              <span className="icons clIcon"></span>
            </div>
            <h4>Hotel availability is gone.Please choose another hotel in results page</h4>
            <div className="buttonGrop pb-0 border-0">
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={this.backToResults}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showHoldPop}
          onHide={this.handleClose}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}>
          <Modal.Body>
            <h5>Unable to hold the selected Rooms,Please check the below room status</h5>
            <div className="resultSection boxShadow bg-white recordInfo pb-0">
              <h5 className="title m-0 ezyIcon icon-document" > Room details</h5>
              <Table className="m-0">
                <thead>
                  <tr>
                    <th>Room No</th>
                    <th>Room Name</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.holdResp && this.state.holdResp.data.rooms.map((room, idx) => (
                    <tr>
                      <td>
                        <span>{room.rmindex} </span>
                      </td>
                      <td>
                        <span>{room.rnm}</span>
                      </td>
                      <td>
                        <span>{room.stat}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="dstn">
              Still do you want to process the booking?
        </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.bdata!==undefined && this.props.bdata.enableOnlinePay &&
              <Button variant="outline-dark" onClick={this.confirmHoldOnlinePayment}>
                Yes
          </Button>
            }
            {this.props.bdata!==undefined && !this.props.bdata.enableOnlinePay &&
              <Button variant="outline-dark" onClick={this.confirmHoldBooking}>
                Yes
          </Button>
            }
            <Button variant="outline-dark" onClick={this.backToResults}>
              Back to Results
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
        show={this.state.enablePayPopup}
        onHide={this.backToHome}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}>

        <Modal.Body>
        <div>
        <div className="custom-ui">
         <span className="confirmD"></span>
        <div className="creditINfo d-flex flex-column align-items-end">
        <h3>Payment has failed.. </h3>
          <p>Sorry we are unable to process this transaction.
            Please check with adminstrator.
         </p>
        </div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
              size="xs"
              variant="outline-primary"
              onClick={this.backToHome}
              type="submit"
            >Back To Home</Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  }
}

export default withRouter(PaymentDetails);
