/**
 * @description : Dash Board will display Latest Bookings here
 * @author : Anil Kumar Ch
 * @date : 04-09-2020
 */
 import React, { useContext, useEffect, useReducer } from 'react';
 import { Col, Row  ,Form} from "react-bootstrap";
 import { Context } from "../../../App";
 import AirlinesMemo from '../common/airlines';
 import InnerLoader from '../common/loader/InnerLoader';
 import NoRecordFound from '../common/popUps/NoRecordFound.jsx';
 import { getRecentData } from './operations';
 export const ONHOLD_STY = "ONHOLD_STY";
 export const IS_LATEST = "IS_LATEST";
 var farReq = require('../masterData/FareRule.json');
 /**
  * Initial State Declaration
  */
 const initialState = {
   categoryName: '', showMessage: false, loadCategory: false, loadEdit: false, loadSubmit: false, id: 0,
   res: [], noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', loader: true
 }
 const reducer = (state, action) => {
   switch (action.type) {
     case 'clear':
       return {
         ...state, categoryName: ''
       };
     case 'editBranch':
       return Object.assign(state, action.payload);
     default:
       return { ...state, [action.type]: action.payload };
   }
 };

 /**
    * @description:on click booking id we are fetching data and put into window object,booking id also put into localstorage.
    * @date:18-09-2020
    * @author:Rambabu
    * @param {*} data
    * @param {*} idx
    */
 const openBookingSummary = (data, idx) => () => {
   window.sessionStorage.removeItem(IS_LATEST)
   if(data.product==="Flight"){
     window.open("/ezytrip/dashboard/bookingsummary/" + data.bid);
   }else if(data.product==="Hotel"){
     window.open("/ezytrip/dashboard/hotel/bookingsummary/" + data.bid);
   }else if(data.product==="Visa"){
    window.open("/ezytrip/dashboard/visa/summary/" + data.bid);
  }else if(data.product==="Insurance"){
    window.open("/ezytrip/dashboard/insurance/bookingsummary/" + data.bid);
   }
 }


 function FlightLatestBookings(props) {
   const [context, setContext] = useContext(Context);
   const [state, dispatch] = useReducer(reducer, initialState);
   
   useEffect(() => {
     if (props.cid !== undefined || context.logindata.region_type!==undefined ||props.BookingListRes) {
       let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
       let rq_json = {
         id: props.cid,
         utype: localstorage.utype,
         uid: localstorage.uid,
         landing: true,
         is_admin:localstorage.is_admin,
         region_type:localstorage.region_type
       }
       getRecentData(rq_json).then(response => {

         if (response) {
           loadResponse(response);
           dispatch({ type: 'res', payload: response })
         } else {
           dispatch({ type: 'noRecords', payload: true })
         }
         dispatch({ type: 'loader', payload: false })
       })
     }
   }, [props.cid || context.logindata.region_type || props.BookingListRes]);
   localStorage.removeItem(ONHOLD_STY)
 const loadResponse = (records) => {
     let data = []
     for (let val of records) {
       // let status = ""
       // let ststusCls =""
       // let ststusIcon =""
       if(val) {
       if (val.stat == 21) {
         val.status = " In Complete"
         val.ststusCls= " pending"
         val.ststusIcon ="infonew"
       }
       if (val.stat == 22) {
         val.status = " On Hold"
         val.ststusCls= " onhold"
         val.ststusIcon ="infonew"
       }
       if (val.stat == 23) {
         val.status = " Confirmed"
         val.ststusCls= " confirmedOne"
         val.ststusIcon ="confirmedOne"
       }
       if (val.stat == 24) {
         val.status = " Waiting For Review"
         val.ststusCls= " pending"
         val.ststusIcon ="waiting-review"
       }
       if (val.stat == 25) {
         val.status = " Failed"
         val.ststusCls= " failed"
         val.ststusIcon ="cancelOne"
       }
       if (val.stat == 26) {
         val.status = " Cancel Request"
         val.ststusCls= " cancelled"
         val.ststusIcon ="cancelOne"
       }
       if (val.stat == 27) {
         val.status = " Cancellation in progress"
         val.ststusCls= " pending"
         val.ststusIcon ="cancelOne"
       }
       if (val.stat == 28) {
         val.status = " Cancelled"
         val.ststusCls= " cancelled"
         val.ststusIcon ="cancelOne"
       }
       if (val.stat == 29) {
         val.status = " Booked"
         val.ststusCls= " confirm"
         val.ststusIcon ="ticket"
       }
       if (val.stat == 30) {
         val.status = " Void in progress"
         val.ststusCls= " pending"
         val.ststusIcon ="void-process"
       }
       if (val.stat == 31) {
         val.status = " Voided"
         val.ststusCls= " voideed"
         val.ststusIcon ="void-process"
       }
       if (val.stat == 32) {
         val.status = " Modified"
         val.ststusCls= " modified"
         val.ststusIcon ="waiting-review"
       }
       if (val.stat == 33) {
         val.status = " PNR Failed"
         val.ststusCls= " failed"
         val.ststusIcon ="ticket"
       }
       if (val.stat == 34) {
         val.status = " Ticketing Fail"
         val.ststusCls= " failed"
         val.ststusIcon ="ticket"
       }
       if (val.stat == 35) {
         val.status = " Discarded"
         val.ststusCls= " discard"
         val.ststusIcon ="discarded"
       }
       if (val.stat == 36) {
         val.status = " Partially Ticketed"
         val.ststusCls= " confirmedOne"
         val.ststusIcon ="ticket"
       }
       if (val.stat == 37) {
         val.status = " Pending"
         val.ststusCls= " pending"
         val.ststusIcon ="exclamation"
       }
       if (val.stat == 38) {
         val.status = " Void Book Fail"
         val.ststusCls= " cancelled"
         val.ststusIcon ="void-process"
       }
       if (val.stat == 39) {
         val.status = " Void Cancel"
         val.ststusCls= " cancelled"
         val.ststusIcon ="void-process"
       }
       if (val.stat == 40) {
         val.status = " Void cancel Fail"
         val.ststusCls= " cancelled"
         val.ststusIcon ="void-process"
       }
       if (val.stat == 41) {
         val.status = " Partially Confirmed"
         val.ststusCls= " confirm"
         val.ststusIcon ="confirm"
       }
       if(val.stat == 47) {
        val.status = " Offline"
        val.ststusCls= " offlinenew"
        val.ststusIcon ="confirm"
      }
     }
     }
   }
   return (

     <>
       {state.loader ? <InnerLoader className="ltBookingLst" /> : (
         <div className="latestBooking table-responsive">
           {state.res.length > 0 ? (

             <>
              {/* <h4 className="dashBordTitle"><span>Latest Bookings</span> <Link className="expnd ezyIcon icon-openNext" title="Bookings List" to={'/ezytrip/dashboard/bookingList'}></Link></h4> */}
               <div className="heading">
               <Row>
                   <Col className="leadPaxbooking">Lead Pax/Booking Id</Col>
                   <Col className="leadPaxbooking">Route/Hotel/Country</Col>
                   <Col className="leadPaxbooking">Booked on</Col>
                   <Col className="leadStatus">Status</Col>
                 </Row>
               </div>


               {/* {state.loader && <InnerLoader className="ltBookingLst"/>} */}
               {state.res.map((cat, index) => (

                 <React.Fragment key={index}>
                   {cat != null &&
                     <div className="bookingList" onClick={openBookingSummary(cat, index)}>
                       <Row>
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <div className="listIcons">
                        <span className={cat.bktype!=="REISSUE/SPLIT" && cat.bktype!=="REISSUE" &&  "listIcon ezyIcon icon-" + (cat.product=="Flight" && 'plane'  || cat.product=="Insurance" && 'policy' || cat.product=="Hotel" && 'hotel'|| cat.product=="Visa" && 'visa')}></span>
                        <span className={"import ml-1 ezyIcon icon-" + ((cat.bktype==="IMPORT" || cat.bktype==="IMPORT/REISSUE" || cat.bktype==="IMPORT/SPLIT") ? 'import':'' ) }></span>
                        <span className={cat.bktype==="REISSUE" || cat.bktype==="REISSUE/SPLIT"?"listIcon ezyIcon icon-reissue":"" }></span>
                        </div>
                         <div className="bookings d-flex flex-row">
                           <span className="passengerName" title={(cat.fn)+ "-" + (cat.tn)}>{cat.fn} {cat.tn}</span>
                           <span className="bookingIdnumber">{cat.bid}</span>
                         </div>
                       </Col>
                       {cat.product==="Flight" &&
                       <>
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <span className="ezyIcon icon-map"></span>
                         <div className="bookings d-flex flex-row">
                           <span className="passengerName" title={(cat.source_city_name)+ "-" + (cat.dest_city_name)}>{cat.source_city_name} - {cat.dest_city_name}</span>
                           <span className="bookingIdnumber" title={(cat.markting_airline)}><AirlinesMemo code={cat.markting_airline} /></span>
                         </div></Col>
                         </>
                      }
                      {cat.product==="Hotel" &&
                       <>
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <span className="ezyIcon icon-map"></span>
                         <div className="bookings d-flex flex-row">
                           <span className="passengerName"  title={(cat.htlname)}>{cat.htlname}</span>
                           <span className="bookingIdnumber" title={(cat.htlcity)} >{cat.htlcity}</span>
                         </div></Col>
                         </>
                      }
                      {cat.product==="Visa" &&
                       <>
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <span className="ezyIcon icon-map"></span>
                         <div className="bookings d-flex flex-row">
                           <span className="passengerName"  title={(cat.na)+ "-" + (cat.trvl_to)}>{cat.na} - {cat.trvl_to}</span>
                           <span className="bookingIdnumber" title={(cat.visa_type)} >{cat.visa_type}</span>
                         </div></Col>
                         </>
                      }
                      {cat.product==="Insurance" &&
                       <>
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <span className="ezyIcon icon-map"></span>
                         <div className="bookings d-flex flex-row">
                           <span className="passengerName"  title={(cat.src_country_name)}>{cat.bound_type==="1"?cat.src_country_name+"-"+"Qatar":cat.src_country_name}</span>
                           <span>{cat.bound_type==="1"?"Inbound":"Outbound"}</span>
                         </div></Col>
                         </>
                      }
                       <Col className="d-flex align-items-center leadPaxbooking">
                        <span className="ezyIcon icon-newcalendar"></span>
                         <div className="bookings d-flex flex-row">
                         <span className="dtinfo">{cat.dt}</span>
                         </div>
                         </Col>

                       <Col className={"status leadStatus" + cat.ststusCls}>
                         <div className="d-flex">

                         <span className="bookingSta" title={cat.status}>{cat.status}</span>
                         </div>
                         </Col>

                     </Row>
                     </div>}

                 </React.Fragment>
               ))}

             </>) : <NoRecordFound />}
         </div>
       )}
     </>
   )
 }


 export default FlightLatestBookings;
