import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Alert, Button, Col, Form, Row, Table, Modal} from 'react-bootstrap';
import { deleteRemainder,closeStatus} from '../../dashBoard/operations';
import DateUtils from "../../commonUtils/DateUtils";
import AirportMemo from '../../common/airlines/airportNames';

const AlertModal = (props) => {

       return (
        <div>
        {props.showAlert &&  props.alertViewData !== undefined && props.alertViewData !== "" &&
         <Modal
        show={props.showAlert}
        onHide={props.handleClose}
        className="importPNRMdl ViewAlertpopup"
        backdrop="static"
        size="lg"
        keyboard={false}
        >
        <Modal.Header closeButton>
          <div className="popUplayout">
          <div className="addRmPopUpInner">
          <h6>View Alert {props.selEvntName}</h6>
          <p>flight {props.alertViewData.prv_data.fn} originally scheduled at {DateUtils.prettyDate(props.alertViewData.prs_data.dd, 'hh:mm A')} on {DateUtils.prettyDate(props.alertViewData.prs_data.dd, 'MMM ddd, YYYY')}</p>
          <div className="flightAlert present">
          <span className="ezyIcon icon-flight">Active Flight Info</span>
          <div className="infotable">
          <table>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Departure</th>
                <th>Arrival</th>
              </tr>
              <tr>
                <td><div className="d-flex flex-column"> <AirportMemo code={props.alertViewData.prs_data.da} mapping={props.alertViewData.mappings} type={'Alert'}/> </div></td>
                <td><div className="d-flex flex-column">  <AirportMemo code={props.alertViewData.prs_data.ar} mapping={props.alertViewData.mappings} type={'Alert'}/> </div></td>
                <td><strong className="d-flex flex-column">{DateUtils.prettyDate(props.alertViewData.prs_data.dd, "HH:mm")} <small>{DateUtils.prettyDate(props.alertViewData.prs_data.dd, "ddd, MMM DD, YYYY")}</small></strong></td>
                <td><strong className="d-flex flex-column">{DateUtils.prettyDate(props.alertViewData.prs_data.ad, "HH:mm")} <small>{DateUtils.prettyDate(props.alertViewData.prs_data.ad, "ddd, MMM DD, YYYY")}</small></strong></td>
              </tr>
            </table>
          </div>
          </div>
          <div>
          {props.alertViewData.prs_data.fc!==undefined &&  props.alertViewData.prs_data.fc!=="" &&
          <span>Flight Cancellation : {props.alertViewData.prs_data.fc==="A"?"Active":"Cancelled"}</span>
          }
          <br/>
          {props.alertViewData.prs_data.depart_gate!==undefined && props.alertViewData.prs_data.depart_gate!=="" &&
          <span>Departure Gate Change : {props.alertViewData.prs_data.depart_gate}</span>
          }
          <br/>
          {props.alertViewData.prs_data.arrival_gate!==undefined && props.alertViewData.prs_data.arrival_gate!=="" &&
          <span>Arrival Gate Change : {props.alertViewData.prs_data.arrival_gate}</span>
          }
          <br/>
           {props.alertViewData.prs_data.delay!==undefined && props.alertViewData.prs_data.delay!=="" &&
          <span>Schedule Change Prepond : {props.alertViewData.prs_data.delay}Min</span>
          }
          <br/>
          {props.alertViewData.prs_data.delay!==undefined && props.alertViewData.prs_data.delay!=="" &&
          <span>Schedule Change Delay : {props.alertViewData.prs_data.delay}Min</span>
          }
          <br/>
          {props.alertViewData.prs_data.bag_belt!==undefined && props.alertViewData.prs_data.bag_belt!=="" &&
          <span>Baggage Belt : {props.alertViewData.prs_data.bag_belt}</span>
          }
          </div>

          <div className="flightAlert previous">
          <span className="ezyIcon icon-flight">Previous Flight Info</span>
          <div className="infotable">
            <table>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Departure</th>
                <th>Arrival</th>
              </tr>
              <tr>
                <td><div className="d-flex flex-column"><AirportMemo code={props.alertViewData.prv_data.da} mapping={props.alertViewData.mappings} type={'Alert'} /></div></td>
                <td><div className="d-flex flex-column"><AirportMemo code={props.alertViewData.prv_data.ar} mapping={props.alertViewData.mappings} type={'Alert'} /></div></td>
                <td><strong className="d-flex flex-column">{DateUtils.prettyDate(props.alertViewData.prv_data.dd, "HH:mm")} <small>{DateUtils.prettyDate(props.alertViewData.prv_data.dd, "ddd, MMM DD, YYYY")}</small></strong></td>
                <td><strong className="d-flex flex-column">{DateUtils.prettyDate(props.alertViewData.prv_data.ad, "HH:mm")} <small>{DateUtils.prettyDate(props.alertViewData.prv_data.ad, "ddd, MMM DD, YYYY")}</small></strong></td>
              </tr>
            </table>
          </div>

          </div>
        </div>
          </div>
        </Modal.Header>
      </Modal>
      }
        </div>
    )
}


export default AlertModal;