
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import MainMenu from '../../../../components/common/MainMenu';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import {getExchangeRates,save,update } from './operations';
import Select from 'react-dropdown-select';
import {currencies} from '../../utils';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Exchange Rate Configuration
 * @date : 13-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id:0,from_currency:"", to_currency:"", exchange_rate:"", buffer:"", commission_exchange_rate:"",currencyMenu: currencies(), res:[], selFromCurrency: [], selToCurrency: [],enbaleUpdate: false,
    noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, bufferError:false, nameError: false,fromError:false,toError:false,isItineraryLoading:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, id:0,from_currency:"", to_currency:"", exchange_rate:"", buffer:"",currencyMenu: currencies(), res:[], selFromCurrency: [], selToCurrency: [],enbaleUpdate: false,
                noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, bufferError:false, nameError: false,fromError:false,toError:false, isItineraryLoading:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function ExchangeRate Configuration
 */
function ExchangeRate() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [validated, setValidated] = useState(false);
    const [context, setContext] = useContext(Context);

    useEffect(() => {
        getExchangeRates().then(response => {
            if (response.data.suc) {
                convertData(response.data);
            }
        })
    }, []);

    //Converting all suppliers
    const convertData = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response.data){
                    data.push({ from_currency: val.from_currency,to_currency: val.to_currency, exchange_rate:val.exchange_rate,buffer:val.buffer, total_exrate:val.total_exrate, com_exrate:val.commission_exchange_rate,modify_date:val.modified_date, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editExchange(val, "view")}></a> {<a className="ezyIcon icon-edit" onClick={editExchange(val, "edit")}></a>}</span>  </> })
                }
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'isLoadingMore', payload: false });
    }

     //Edit Categeory
     const editExchange = (obj, type) => () => {
        dispatch({ type: 'exchange_rate', payload: obj.exchange_rate });
        dispatch({ type: 'buffer', payload: obj.buffer });
        dispatch({ type: 'commission_exchange_rate', payload: obj.commission_exchange_rate });
        dispatch({ type: 'id', payload: obj.id });
        dispatch({ type: 'selFromCurrency', payload: [{ "value": obj.from_currency, "label": obj.from_currency }] })
        dispatch({ type: 'selToCurrency', payload: [{ "value": obj.to_currency, "label": obj.to_currency }] })
        if (type === "view"){
            dispatch({ type: 'enableView', payload: true });
            dispatch({ type: 'enbaleUpdate', payload: false });
        }else{
            dispatch({ type: 'enableView', payload: false });
            dispatch({ type: 'enbaleUpdate', payload: true });
        }
    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
      };

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'nameError', payload: false })
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    //Validate Exchange rate
    const validate=()=>{
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "bufferError", payload: false })
        dispatch({ type: "fromError", payload: false })
        dispatch({ type: "toError", payload: false })
       if(state.exchange_rate===""){
        dispatch({ type: "nameError", payload: true })
        return false
       }
       if(state.buffer===""){
        dispatch({ type: "bufferError", payload: true })
        return false
       }
       if(state.from_currency===""){
        dispatch({ type: "fromError", payload: true })
        return false
       }
       if(state.to_currency===""){
        dispatch({ type: "toError", payload: true })
        return false
       }
       if(state.from_currency!=="" && state.to_currency!=="" && state.from_currency===state.to_currency){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'From and To Currencies are should not be the same' })
        dispatch({ type: 'notiVarient', payload: 'danger' });
        hidemessage()
        return false
       }
       if(!state.enbaleUpdate && state.res!==undefined && state.from_currency!=="" && state.to_currency!==""){
        for(let obj of state.res){
          if(obj.from_currency===state.from_currency && obj.to_currency===state.to_currency){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Already created record with same creteria.' })
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
            return false
          }
        }
       }
       return true;
      }

    //Save Exchange rate
    const saveExchangeRate = (e) => {
        if(validate()){
            dispatch({type:"isItineraryLoading", payload:true})
            const payload={
                "from_currency":state.from_currency,
                "to_currency":state.to_currency,
                "exchange_rate":state.exchange_rate,
                "buffer":state.buffer,
                "crby":context.logindata.uid,
                "commission_exchange_rate":state.commission_exchange_rate
            }
            save(payload).then(response => {
            if (response.suc) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Exchange Rate saved successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                return window.location = window.location.pathname;
            }
        })
        dispatch({type:"isItineraryLoading", payload:false})
        }
        }

     //Update Exchange rate
    const updateExchangeRate = (e) => {
        if(validate()){
            dispatch({type:"isItineraryLoading", payload:true})
            const payload={
                "from_currency":state.from_currency,
                "to_currency":state.to_currency,
                "exchange_rate":state.exchange_rate,
                "buffer":state.buffer,
                "upby":context.logindata.uid,
                "id":state.id,
                "commission_exchange_rate":state.commission_exchange_rate
            }
        update(payload).then(response => {
            if (response.suc) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Exchange Rate updated successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                return window.location = window.location.pathname;
            }
            dispatch({type:"isItineraryLoading", payload:false})
        })
        }
        }

        const cancel=()=>{
            return window.location = window.location.pathname;
        }

        const handleCurrency = (obj,type) => {
            if (obj.length > 0) {
                if(type==="From"){
                    dispatch({ type: 'from_currency', payload: obj[0].value })
                }else{
                    dispatch({ type: 'to_currency', payload: obj[0].value })
                }
            }
        }

        const columns = [{
            dataField: 'from_currency',
            text: 'From Currency',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'to_currency',
            text: 'To Currency',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'exchange_rate',
            text: 'Exchange Rate',
            sort: true
        },
        {
            dataField: 'buffer',
            text: 'Buffer',
            sort: true
        },
        {
            dataField: 'total_exrate',
            text: 'Total Exchange Rate',
            sort: true
        },
        {
            dataField: 'com_exrate',
            text: 'Commision Exchange Rate',
            sort: true
        },
        {
            dataField: 'modify_date',
            text: 'Modify Date',
            sort: true
        },
        {
            dataField: 'options',
            text: 'Actions',
            class: 'tes'
        }];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];


    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="Exchange Rates" />
            <div className="container">

                <div className="cardMainlayout pt-0">
                <OrganizationMenu active="Exchange Rate" />
                    <div className="contentArea">
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
                        <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                            <Form.Group as={Col} xs={3} className={"selectplugin " + (state.currencyError && "error")} controlId="cur">
                            <Select
                                options={state.currencyMenu}
                                searchable={true}
                                values={state.selFromCurrency}
                                onChange={(value) => (handleCurrency(value,"From"))}
                                disabled={state.enableView}
                            />
                            <Form.Label>From Currency <sup>*</sup></Form.Label>
                            {state.fromError && (
                                <p className="vError">Please Select a From Currency</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.currencyError && "error")} controlId="cur">
                            <Select
                                options={state.currencyMenu}
                                searchable={true}
                                values={state.selToCurrency}
                                onChange={(value) => (handleCurrency(value,"To"))}
                                disabled={state.enableView}
                            />
                            <Form.Label>To Currency <sup>*</sup></Form.Label>
                            {state.toError && (
                                <p className="vError">Please Select a To Currency</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="exchange_rate">
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Company Name"
                                value={state.exchange_rate}
                                onChange={handleChange}
                                disabled={state.enableView}
                            />
                            <Form.Label>
                             Exchange Rate<sup>*</sup>
                            </Form.Label>
                            {state.nameError && (
                                <p className="vError">Please Enter Exchange Rate</p>
                            )}
                            </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="buffer">
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Buffer"
                                value={state.buffer}
                                onChange={handleChange}
                                disabled={state.enableView}
                            />
                            <Form.Label>
                            Buffer<sup>*</sup>
                            </Form.Label>
                            {state.bufferError && (
                                <p className="vError">Please Enter Buffer </p>
                            )}
                            </Form.Group>

                            <Form.Group as={Col} xs={3} controlId="commission_exchange_rate">
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Company Name"
                                value={state.commission_exchange_rate}
                                onChange={handleChange}
                                disabled={state.enableView}
                            />
                            <Form.Label>
                             Commision Exchange Rate
                            </Form.Label>
                            </Form.Group>
                        </Form.Row>
                        </Form>
                        <div className="text-right buttonGrop pb-0">
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={cancel}
                            type="submit"
                        > Reset</Button>
                         {!state.enbaleUpdate && !state.enableView &&
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={saveExchangeRate}
                            type="submit"
                        > Save</Button>
                         }
                          {state.enbaleUpdate &&
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={updateExchangeRate}
                            type="submit"
                        > Update</Button>
                          }
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ExchangeRate