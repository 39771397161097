import React, { Component, useState, useRef, useContext,useEffect } from 'react';
import { Col, Row,Button,Modal,Form,Navbar,Nav, Container,NavDropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import logogray from "../../../assets/images/logo.svg";
import Bannerinnerimage from "../../../assets/images/flighthotel_banner.png";
import Bannerinnertext from "../../../assets/images/Text.png";
import Backgroundvideo from "../../../assets/images/GoldenOrangeCurtain _ Free Animationbackground.mp4";
import LoginForm from '../../authentication/LoginForm';
import useOutsideClick from '../../common/OutsideClick';
import RegistrationForm from './RegistrationForm';
/**
 * @description:This DashBord Login Header details
 * @author: Pravas
 * @param {*}
 * @function Login details
 * @date : 21-09-2020
 */


function DashBordLoginHeader(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openLink, setOpenLink] = useState(false);
  const [enablePop, setEnablePop] = useState(false);
  const ref = useRef();

  const openPopUp = () => {
    if (openLink) {
      setOpenLink(false)
    } else {
      setOpenLink(true)
    }
  }
  useOutsideClick(ref, () => {
    if (openLink) setOpenLink(false);
  });


  const openRegForm = () =>{
    setEnablePop(true)
  }

  const closedRegForm = ()=> {
    setEnablePop(false)
  }
  const dashBorad = (e) => {
    return window.location = "/ezytrip/login";
  }


  return (
    <>
    <section className="dashBordHeader" id="stcickyHeader">
    <div className="container ">

<Row className="headerWrapper d-flex align-items-center justify-content-between">

{/* <Col xs={2} className="logo">
<Link className="app-header-logo mr-4" onClick={dashBorad}>
                <img
                  src={logogray}
                  title="EZY Trip"
                  alt="logo"
                />

              </Link>

</Col> */}
{/* <Col xs={8} className="nav">
<Navbar expand="lg" className="m-auto">
   <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav defaultActiveKey="#home" className="m-auto">
        <Nav.Link href="#home" data-hover="Home">Home</Nav.Link>
        <Nav.Link href="#aboutUs" data-hover="About Us">About Us</Nav.Link>
        <Nav.Link href="#whatWeoffer" data-hover="What we offer">What we offer</Nav.Link>
        <Nav.Link href="#whyChoose" data-hover="Why Ezytrip">Why Ezytrip</Nav.Link>
        <Nav.Link href="#trendingPackage" data-hover="Packages">Packages</Nav.Link>


      </Nav>
    </Navbar.Collapse>

</Navbar>

</Col> */}

<Col xs={8}>

<Navbar expand="lg">
  <Container>
    <Navbar.Brand>
    <Link className="app-header-logo mr-4" onClick={dashBorad}>
                <img
                  src={logogray}
                  title="EZY Trip"
                  alt="logo"
                />

              </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav defaultActiveKey="#home" className="m-auto">
        <Nav.Link href="#home" data-hover="Home">Home</Nav.Link>
        <Nav.Link href="#aboutUs" data-hover="About Us">About Us</Nav.Link>
        <Nav.Link href="#whatWeoffer" data-hover="What we offer">What we offer</Nav.Link>
        <Nav.Link href="#whyChoose" data-hover="Why Ezytrip">Why Ezytrip</Nav.Link>
        <Nav.Link href="#trendingPackage" data-hover="Packages">Packages</Nav.Link>
        
        <NavDropdown title="Contact Us" id="basic-nav-dropdown">
          <NavDropdown.Item href="tel:974 44798899" id="phone"><span className="navbar-brand ezyIcon icon-contactUs">  <span>974 44798899</span> </span></NavDropdown.Item>
          <NavDropdown.Item id="whatsApp" href="tel:+974 5572 2351"><span className="navbar-brand ezyIcon icon-whatsapp"><span> +974 5572 2351 </span></span></NavDropdown.Item>
          <NavDropdown.Item id="mail" href="mailto:support@ezytrip.com"><span className="navbar-brand ezyIcon icon-new-email"><span>support@ezytrip.com</span></span></NavDropdown.Item>


        </NavDropdown>


      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

</Col>

<Col xs={4} className="contactUs">
{/* <div className={"topMenu d-flex align-items-center " + (openLink ? 'active' : 'inactive')} ref={ref} ><span className="showUp ezyIcon icon-call-service">Contact Us </span>


</div> */}
<Button className="registerInd" variant="outline-primary mr-4" onClick={openRegForm}> Register With Us</Button>
   <div className={"topMenu d-flex align-items-center " + (openLink ? 'active' : 'inactive')} ref={ref} onClick={openPopUp}><span className="showUp ezyIcon icon-support">24x7 Support <span className="ezyIcon icon-down-arrow"></span></span>


<nav className={"navigation d-flex align-items-center " + (openLink ? 'active' : 'inactive')}>
  <ul className="d-flex flex-column">
    <li><a id="phone" href="tel:974 44798899" className="navbar-brand ezyIcon icon-contactUs"> 974 44798899</a></li>
    <li><a id="whatsApp" href="tel:+974 5572 2351" className="navbar-brand ezyIcon icon-whatsapp"> +974 5572 2351</a></li>
    <li><a id="mail" href="mailto:support@ezytrip.com" className="navbar-brand ezyIcon icon-new-email">support@ezytrip.com</a></li>
  </ul>
</nav>

</div>
{/* <Nav.Link onClick={openRegForm} data-hover="Register With US"></Nav.Link> */}

</Col>
</Row>

      </div>



    </section>


    <div id="home"></div>
    <section className="dashBordLogin dashBordLoginbg d-flex">


<div className="container ">
{/* <video autoplay="autoplay" muted="muted" loop="loop" id="myVideo">
  <source src={Backgroundvideo} type="video/mp4"></source>

</video> */}
<div className='headerNewbackground'></div>
  <div className="headerContent newDesignheader">
    <Row>
      <Col xs={8} className="quote">
        <h1>Want to become a travel agent ?</h1>
        <h3>Start your profitable business today with best <br/>
B2B travel portal in Qatar.</h3>
<div className="innerBannerimg">

  <div className='d-flex'>
<span><img
                  src={Bannerinnerimage}
                  title="Winner-2022"
                  alt="bannerinnerimage"
                /></span>

                {/* <span>
                <img className='textImage'
                  src={Bannerinnertext}
                  title="World Travel Awards Winner - EZY Trip"
                  alt="bannerinnerimage"
                />
                  </span> */}
  </div>

                </div>
      </Col>
      <Col xs={4} className="loginLayout">
          <LoginForm/>
      </Col>
    </Row>




  </div>

</div>
</section>

       <Modal
        show={enablePop}
        onHide={closedRegForm}
        className="importPNRMdl registratPop"
        backdrop="static"
        keyboard={false}
        >
           <Modal.Header closeButton>
          <Modal.Title>Resgister with Us!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
        <RegistrationForm />

          </Modal.Body>
                </Modal>

      <Modal show={show} onHide={handleClose} className="register" size="lg" >
        <Modal.Header closeButton>
          <Modal.Title><span className="ezyIcon icon-useradd"></span> Resgister</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
        <Form.Row>
        <Form.Group as={Col} xs={6} controlId="companyName">
            <Form.Control type="text" className="companyName" placeholder="Enter User name" name="companyName" autoComplete="off"  autoFocus />
            <Form.Label>Company Name</Form.Label>
            {/* <Form.Text className="invalid-feedback">
                {showUserName}
            </Form.Text> */}
        </Form.Group>
        <Form.Group as={Col} xs={6} controlId="contactName">
            <Form.Control type="text" className="companyName" placeholder="Enter Contact Name" name="contactName" autoComplete="off"  autoFocus />
            <Form.Label>Contact Name</Form.Label>
            {/* <Form.Text className="invalid-feedback">
                {showUserName}
            </Form.Text> */}
        </Form.Group>
    </Form.Row>
    <Form.Row>
        <Form.Group as={Col} xs={6} controlId="companyName">
            <Form.Control type="tel" className="companyName" placeholder="Enter User name" name="companyName" autoComplete="off"  autoFocus />
            <Form.Label>Mobile Number</Form.Label>
            {/* <Form.Text className="invalid-feedback">
                {showUserName}
            </Form.Text> */}
        </Form.Group>
        <Form.Group as={Col} xs={6} controlId="contactName">
            <Form.Control type="mail" className="companyName" placeholder="Enter Contact Name" name="contactName" autoComplete="off"  autoFocus />
            <Form.Label>Email Id</Form.Label>
            {/* <Form.Text className="invalid-feedback">
                {showUserName}
            </Form.Text> */}
        </Form.Group>
    </Form.Row>

          </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
          Register
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default DashBordLoginHeader;