import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Hold Bookings
 * @param {*} Service 
 */

class GSTConfigurationService {
    async saveGST(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/att/gst/save`,data)  
      }
      async updateGST(data) {
        return await axios.put(`${API_URL}/att/gst/update/${data.id}`,data)  
       }
       async retriveGST(obj) {
        return await axios.post(`${API_URL}/att/gst/retrieve`,obj)
       } 
       async deleteGST(id) {
        return await axios.delete(`${API_URL}/att/gst/delete/${id}`)
       } 
    }

export default new GSTConfigurationService()