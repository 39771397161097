import React, { Component, useState,useContext } from 'react'; 
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";


function MasterServicesMenuFun(props) {   
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
   /* const redirect = (event) => {
      event.preventDefault();
      if (event.target.id === "branch") {
        history.push("/ezytrip/administration/branch");
      }else if(event.target.id === "category"){
        history.push("/ezytrip/administration/category");
      }else if(event.target.id === "markup"){
        history.push("/ezytrip/administration/markup");
      }else if(event.target.id === "user"){
        history.push("/ezytrip/administration/user");
      }else if(event.target.id === "role"){
        history.push("/ezytrip/administration/role");
      }
     
    }*/
  //const [mainNav, setmainNav,] = useState(false);

return (
    
        <div className="submenu">
            <ul>
            {context.logindata.roles && context.logindata.roles.split(",").includes('U_M') &&  context.logindata.utype !== "sta_user"  && <li  className={state.active==="User Management" ? 'active':''}>
              <Link
              to="/ezytrip/administration/user"              
              title="User Management"
            >                
              User Management
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('R_M') && context.logindata.utype !== "sta_user"  && <li  className={state.active==="Role Management" ? 'active':''}>
              <Link
              to="/ezytrip/administration/role"              
              title="Role Management"
            >                
              Role Management
            </Link>
            </li> }         
            </ul>
          </div>
   
  );
};



class MasterServicesMenu extends Component {
  constructor(props) {
  super(props);
  console.log(props)
  this.state = {
         active:props.active
          
      }; 
  }

  render() {
  return <MasterServicesMenuFun state={this.state} />
}
}

export default MasterServicesMenu;

