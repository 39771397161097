import log from "loglevel";
import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import CommonSearch from '../../common/CommonSearch';
import CreateServiceFee from './index';
import { editServiceFee, searchServiceFee } from './operations';
export const MarkupContext = React.createContext();
//This function will show the Air serviceFees List and Add Markup
function AirMarkupConfiguration() {
  const history = useHistory();
  const [serviceFees, setServiceList] = useState({ servicedata: [] });
  const [editData, setEditData] = useState();
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableView, setEnableView,] = useState(false);
  const [OldServiceCreated, setOldNames] = useState({ oldData: [] })

  const [state, setState] = useState({
    createServiceFee: false,
    loading: false,
    editMarkup: false
  })
  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "") {
      const payload = {
        "type": context.logindata.utype,
        "userid": context.logindata.uid,
        "is_admin": context.logindata.is_admin,
        "aplto": "",
        "bid": "",
        "cid": "",
        "apltyp": "",
        "ttype": "",
        "fmdate": "",
        "todate": "",
        "pcid" : context.logindata.cid,
        "agency_admin" : context.logindata.agency_admin,
        "region_type" : context.logindata.region_type
      }
      if(serviceFees!==undefined && serviceFees.servicedata.length===0){
      searchServiceFee(payload).then(response => {
        if (response.data.suc) {
          loadResponse(response);
          setIsLoadingMore(false);
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getServiceFee function---" + error
        );
      });
    }
  }
  }, [context.logindata && serviceFees.servicedata.length===0]);

  const createServiceFeeBtnClick = (e) => {
    setState({ createServiceFee: true, loadBranch: false })
  }

  const editServiceBtnClick = (obj, type) => () => {
    setState({ editMarkup: true })
    editServiceFee(obj.id).then(response => {
      response.data.res.office_label = obj.office_label
      if (response.data.suc) {
        setEditData(response)
        if (type === "view") {
          setEnableView(true)
        } else {
          setEnableView(false)
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editServiceFee function---" + error
      );
    });
  }


  const loadResponse = (response) => {
    let data = [];
    let dataName = []
    var enableEdit = false;
    if (context.logindata.roles.includes('S_F_U')) {
      enableEdit = true;
    }
    for (let val of response.data.res) {
      var flowType = "";
      let ttype = ""
      if (val.ftype.includes("B")) {
        flowType = "Booking";
      }
      if (val.ftype.includes("A")) {
        flowType = flowType + " | Amendment";
      }
      if (val.ftype.includes("C")) {
        flowType = flowType + " | Cancellation";
      }
      if (val.ftype.includes("V")) {
        flowType = flowType + " | Void";
      }
      if (val.ttype === 0) {
        ttype = "All"
      }
      if (val.ttype === 1) {
        ttype = "One Way"
      }
      if (val.ttype === 2) {
        ttype = "Round Trip"
      }
      if (val.ttype === 3) {
        ttype = "Multi City"
      }
      dataName.push(val.sname)
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){ data.push({ mn: val.sname,office_id:val.office_id,office_label:val.office_label!==null?val.office_label:"All",region_type:val.region_type, sales_channel:val.sales_channel,ft: flowType, tt: ttype, status: (val.act === 1) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editServiceBtnClick(val, "view")}></a> {enableEdit && <a className="ezyIcon icon-edit" onClick={editServiceBtnClick(val, "edit")}></a>}</span></> })}
    }
    setOldNames({ oldData: dataName })
    setServiceList({ servicedata: data })
  }
  const columns = [{
    dataField: 'sales_channel',
    text: 'Sales Channel',
    sort: true,
    filter: textFilter()
  },
  (context.logindata.utype === "att_user" && {
    dataField: 'office_label',
    text: 'Office Id',
    sort: true,
    filter: textFilter()
  })
  ,{
    dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter()
  },{
    dataField: 'mn',
    text: 'ServiceFee Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ft',
    text: 'Flow Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'tt',
    text: 'Travel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const searchFee = (data) => {
    searchServiceFee(data).then(response => {
      if (response.data.suc) {
        loadResponse(response);
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editServiceFee function---" + error
      );
    });
  }


  return (
    <>
      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Service Fee" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Service Fee" />
          <div className="contentArea cmnSearch">
            {(!state.createServiceFee && !state.editMarkup) ? (
              <>
                <CommonSearch configType={"Markup"} sendSearchCreteria={searchFee} />

                {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_C') &&
                  <div className="d-flex justify-content-between buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn"
                      className="addPlus ezyIcon icon-plus"
                      onClick={createServiceFeeBtnClick}
                      type="submit">
                    Create Service Fee
                  </Button>
                  </div>
                }

              </>
            ) : (
                (!state.editMarkup) ? (
                  <>
                    <CreateServiceFee oldDataNames={OldServiceCreated.oldData} />
                  </>
                ) : (
                    <>
                      <CreateServiceFee sendEditData={editData} disableBtn={enableView} />
                    </>)
              )
            }
          </div>
        </div>

        {(!state.createServiceFee && !state.editMarkup) ? (

          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
              {serviceFees.servicedata.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={serviceFees.servicedata}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={serviceFees.servicedata.length >10 && serviceFees.servicedata.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  serviceFees.servicedata.length >25 && serviceFees.servicedata.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  serviceFees.servicedata.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || serviceFees.servicedata.length === 0 &&
                <NoRecord />
              }
            </>)}

          </div>
        ) : ('')}

        {state.editMarkup ? (
          <>
            ''
      </>
        ) : ('')}

      </div>
      <Footer />
    </>
  )
}
export default AirMarkupConfiguration