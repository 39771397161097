
import { apiPostHelper, apiGetHelper } from '../../../../components/commonUtils/helper';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
const API_URL = process.env.REACT_APP_B2B_URL;


/**
 * @description: Fetch All Travel Agencies List for drop down
 * @author: Rambabu.K
 * @returns 
 * @function talist
 * @date : 30-06-2021
 */
export const getallTAlist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

/**
 * @description: Create baggage markup and meal markup configuration screen
 * @author: Rambabu.K
 * @returns 
 * @function creation
 * @date : 01-07-2021
 */
export const creation = async (obj) => {     
    let finalRes = {};
    const headers = { headers: { 'Content-Type': 'application/json' } };
    await apiPostHelper(`${API_URL}/att/markup/baggageMkpcreate`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: Retrieve baggage markup and meal markup list for display data table
 * @author: Rambabu.K
 * @returns 
 * @function Retrieve
 * @date : 02-07-2021
 */
export const retrievebgdlist = async (obj) => {     
    let finalRes = {};
    const headers = { headers: { 'Content-Type': 'application/json' } };
    await apiPostHelper(`${API_URL}/att/markup/retrievebgdlist`,obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: Update baggage markup and meal markup configuration screen
 * @author: Rambabu.K
 * @returns 
 * @function creation
 * @date : 02-07-2021
 */
export const update = async (obj) => {
    let finalRes = {};
    const headers = { headers: { 'Content-Type': 'application/json' } };
    await apiPostHelper(`${API_URL}/att/markup/baggageMkpupdate`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


