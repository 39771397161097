import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: Integrated the Branch and Category services
 * @author: Lakshmi
 * @param {*} req 
 */
class BranchCreationService {
    
    async getBrnchData(id) {
        console.log("ID"+id);
        return await axios.get(`${API_URL}/att/branch/retrieve/${id}`);
    }

    
    async saveBranch(branchData) {
        return await axios.post(`${API_URL}/att/branch/create`, branchData)
    }

    async updateBranch(branchData) {
        return await axios.put(`${API_URL}/att/branch/update/${branchData.id}`,branchData)
    }

    async deleteBranch(id,deleteObj) {
        return await axios.put(`${API_URL}/att/branch/delete/${id}`,deleteObj)
    }

    async editBranch(id) {
        return await axios.get(`${API_URL}/att/branch/edit/${id}`)
    }

    //Country Master calls
    async getCountryMasterData(obj) {
        return await axios.post(`${API_URL}/att/common/region_config/retrieve`,obj); 
    }

    async saveCountryMaster(countryData) {
        return await axios.post(`${API_URL}/att/common/region_config/create`,countryData)
    }
    //Category Calls
    async getCategoryData(id) {
        return await axios.get(`${API_URL}/att/category/retrieve`);
    }

    async saveCategory(catData) {
        return await axios.post(`${API_URL}/att/category/create`,catData)
    }

    async updateCategory(catData) {
        return await axios.put(`${API_URL}/att/category/update/${catData.id}`,catData)
    }

    async deleteCategory_bkp(id) {
        return await axios.delete(`${API_URL}/att/category/delete/${id}`)
    }

    async deleteCategory(req) {
        console.log('deleteCategory----req is---'+JSON.stringify(req));
        let id=req.id;        
        return await axios.put(`${API_URL}/att/category/delete/${id}`,req)
    }
   /**
    * @description:Delete Branch Contact details(Hard Delete)
    * @author:Rambabu
    * @date:20-08-2020
    * @param {*} id 
    */
    async deleteContactDetails(id) {
        return await axios.delete(`${API_URL}/att/branch/delete?contactid=${id}`)
    }

    async searchBranch(obj) {
        return await axios.post(`${API_URL}/att/branch/getBranches`,obj)
    }

   /*  async searchBranch(obj) {
        return await axios.post(`${API_URL}/att/branch/getAllBranches`,obj)
    } */

    async getAllBranches() {
        return await axios.get(`${API_URL}/att/branch/getAllBranches`)
    }
    
}

export default new BranchCreationService()