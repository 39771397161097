import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import DateUtils from '../../../commonUtils/DateUtils';
import { getCompanyTAList,getBranchList, getCompaniesList, getDsrReportData } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../../common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../../common/ReportMenu';
import FlightreportMenu from '../../../common/subMenu/FlightreportMenu';
import CoomonExportExcel from '../../../common/CoomonExportExcel';
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
let airportsData = require('../../../masterData/airlinesData.json');


/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "", aplType: "", applToData: [], companyData: [], branchData: [], applTypData: [], travelData: [], totals: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air dsrdata List and Add Markup
function DSRReports(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enabelATT, setEnableATT] = useState(false);
  const [enabelTA, setEnableTA] = useState(false);
  const [enabelType, setEnableType] = useState(false);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [dsrdata, setDsrData] = useState({ dsrReportList: [] });
  const [dsrExceldata, setDsrExcelData] = useState({ dsrReportExcelList: [] });


  const applicabelTo = [{ label: "TA", value: 'TA' }, { label: "STA", value: 'STA' }]
  useEffect(() => {

    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      if(context.logindata.utype==="sta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
        dispatch({ type: 'aplTo', payload: "STA" })
      }else if(context.logindata.utype==="ta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
        setEnableTA(true)
      }
      dispatch({ type: 'userId', payload: context.logindata.uid })
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      getCompaniesList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'companies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    //loadResponse();
  }, [context.logindata]);
  useEffect(()=>{
    if(dsrdata.dsrReportList.length !== 0){
dsrExceldata.dsrReportExcelList = dsrdata.dsrReportList
let restotal = state.totals
let total = {
  trstype: "Grand Total",
  publishfare: restotal.tot_publishfare.toFixed(2),
  basefare: restotal.tot_basefare.toFixed(2),
  tax: restotal.tot_tax.toFixed(2),
  servicefee: restotal.tot_servicefee.toFixed(2),
  cost : restotal.tot_cost.toFixed(2),
  markup: restotal.tot_markup.toFixed(2),
  bag_fare: restotal.tot_bag_fare.toFixed(2),
  meal_fare: restotal.tot_meal_fare.toFixed(2),
  seat_fare: restotal.tot_seat_fare.toFixed(2),
  penalty: restotal.tot_penalty.toFixed(2),
  disc: restotal.tot_disc.toFixed(2),
  cccharge : restotal.tot_cccharge !== null && restotal.tot_cccharge.toFixed(2),
  plb: restotal.tot_plb.toFixed(2),
  selprice : restotal.tot_selprice.toFixed(2),
  profit: restotal.tot_profit.toFixed(2),
  supfee: restotal.tot_supfee.toFixed(2),
  cgst: restotal.tot_cgst.toFixed(2),
  sgst: restotal.tot_sgst.toFixed(2),
  igst: restotal.tot_igst.toFixed(2),
  tds: restotal.tot_tds.toFixed(2),
  tds: restotal.tot_sup_disc
}
 let excelData = dsrExceldata.dsrReportExcelList.concat(total)
setDsrExcelData({dsrReportExcelList: excelData})
  }
  }, [dsrdata.dsrReportList] )
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateSearch=()=>{
    if(fromDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(toDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if( state.userType !== "sta_user" && state.aplTo!=="" && state.bid===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    return true;
  }

  //Handling the search creterai based pn the selection and dates
  const handleSearch = () => {
      if (validateSearch()){
        setDsrData({ dsrReportList: []})
        setDsrExcelData({dsrReportExcelList: []})
        dispatch({ type: 'totals', payload: "" })
        setIsLoadingMore(true)
        if(state.userType === "att_user" && state.aplTo===""){
          const req = {
            "region_type":context.logindata.region_type,
            "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
            "branch_name": state.branchData.length!==0?state.branchData[0].label:"",
            "vtype": "MTA",
            "uid": state.userId,
            "cid": "",
            "utype":"att_user",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
          }
          getDsrReportData(req).then(response => {
            if (response.res.success){
              dispatch({ type: 'totals', payload: response.res.totals })
              for(let val of response.res.data){
                if(val.ticket_status == "Void" || val.ticket_status == "Void/cancel" || val.ticket_status == "Refund/booking"	|| val.ticket_status == "Refund/cancel"){
                  val.region_type = val.region_type
                  val.currency = val.region_type=="Qatar"?"QAR":"INR"
                  val.publishfare = -val.publishfare
                  val.basefare = -val.basefare
                  val.tax = -val.tax
                  val.servicefee = -val.servicefee
                  val.cost = -val.cost
                  val.markup = -val.markup
                  val.disc = -val.disc
                  val.cccharge = val.cccharge !== undefined ? -val.cccharge : 0
                  val.plb = -val.plb
                  val.penalty = -val.penalty
                  val.selprice = -val.selprice
                  val.profit = -val.profit
                  val.supfee = -val.supfee
                  val.cgst = -val.cgst
                  val.sgst = -val.sgst
                  val.igst = -val.igst
                  val.tds_amt = -val.tds_amt
                  if((state.userType === "att_user"&&state.aplTo=="TA")||state.userType === "ta_user"){
                    val.sup_disc = -val.sup_disc
                  }
                }
                if(val.markting_airline.includes(",")){
                  let airAry=val.markting_airline.split(",")
                  let airlineName=getAirlineName(airAry[0])+","+getAirlineName(airAry[1])+","+getAirlineName(airAry[2]);
                  val.markting_airline=airlineName;
                }else{
                  val.markting_airline=getAirlineName(val.markting_airline);
                }
                  val.region_type = val.region_type
                  val.currency = val.region_type=="Qatar"?"QAR":"INR"
                  val.selprice = val.selprice.toFixed(2)
                  val.cost = val.cost.toFixed(2)
                  val.publishfare = val.publishfare.toFixed(2)
                  val.basefare = val.basefare.toFixed(2)
                  val.tax = val.tax.toFixed(2)
                  val.servicefee = val.servicefee.toFixed(2)
                  val.markup = val.markup.toFixed(2)
                  val.bag_fare = val.bag_fare.toFixed(2)
                  val.meal_fare = val.meal_fare.toFixed(2)
                  val.seat_fare = val.seat_fare.toFixed(2)
                  val.profit = val.profit.toFixed(2)
                  val.cgst = val.cgst
                  val.sgst = val.sgst
                  val.igst = val.igst
                  val.tds_amt = val.tds_amt
                  if ((state.userType === "att_user"&&state.aplTo=="TA")||state.userType === "ta_user"){
                    val.sup_disc = val.sup_disc
                  }
                  val.seccodefltnoExcel = val.seccodefltno
                  val.seccodefltno = <span className="sectOrdestin" title={val.seccodefltno}>{val.seccodefltno}</span>
                }
              setDsrData({ dsrReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
         if(state.cid!==0 && state.cid!==""){
          var userType = (state.userType === "ta_user") ? "TA" : "STA";
          const request = {
            "region_type":context.logindata.region_type,
            "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
            "branch_name": state.branchData.length!==0?state.branchData[0].label:"",
            "vtype": (state.aplTo!=="" && state.aplTo!==undefined)?state.aplTo:userType,
            "uid": state.userId,
            "utype":state.userType,
            "cid": (state.cid !== 0) ? state.cid : "",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
         }
         getDsrReportData(request).then(response => {

          if (response.res.success){
            dispatch({ type: 'totals', payload: response.res.totals })
            for(let val of response.res.data){
              if(val.ticket_status == "Void" || val.ticket_status == "Void/cancel" || val.ticket_status == "Refund/booking"	|| val.ticket_status == "Refund/cancel"){
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.publishfare = -val.publishfare
                val.basefare = -val.basefare
                val.tax = -val.tax
                val.servicefee = -val.servicefee
                val.cost = -val.cost
                val.markup = -val.markup
                val.disc = -val.disc
                val.cccharge = val.cccharge !== undefined ? -val.cccharge : 0
                val.plb = -val.plb
                val.selprice = -val.selprice
                val.profit = -val.profit
                val.supfee = -val.supfee
                val.cgst = -val.cgst
                val.sgst = -val.sgst
                val.igst = -val.igst
                val.tds_amt = -val.tds_amt
                if((state.userType === "att_user"&&state.aplTo=="TA")|| state.userType === "ta_user"){
                  val.sup_disc = -val.sup_disc
                }
              }
              if(val.markting_airline.includes(",")){
                let airAry=val.markting_airline.split(",")
                let airlineName=getAirlineName(airAry[0])+","+getAirlineName(airAry[1])+","+getAirlineName(airAry[2]);
                val.markting_airline=airlineName;
              }else{
                val.markting_airline=getAirlineName(val.markting_airline);
              }
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.selprice = val.selprice?val.selprice.toFixed(2):null
                val.cost = val.cost?val.cost.toFixed(2):null
                val.publishfare = val.publishfare?val.publishfare.toFixed(2):null
                val.basefare = val.basefare?val.basefare.toFixed(2):null
                val.tax = val.tax?val.tax.toFixed(2):null
                val.servicefee = val.servicefee?val.servicefee.toFixed(2):null
                val.markup = val.markup?val.markup.toFixed(2):null
                val.bag_fare = val.bag_fare?val.bag_fare.toFixed(2):null
                val.meal_fare = val.meal_fare?val.meal_fare.toFixed(2):null
                val.seat_fare = val.seat_fare?val.seat_fare.toFixed(2):null
                val.profit = val.profit?val.profit.toFixed(2):null
                val.cgst = val.cgst
                val.sgst = val.sgst
                val.igst = val.igst
                val.tds_amt = val.tds_amt
                if ((state.userType === "att_user"&&state.aplTo=="TA")|| state.userType === "ta_user"){
                  val.sup_disc = val.sup_disc
                }
                val.seccodefltnoExcel = val.seccodefltno
                val.seccodefltno = <span className="sectOrdestin" title={val.seccodefltno}>{val.seccodefltno}</span>
              }
            setDsrData({ dsrReportList: response.res.data })
            setIsLoadingMore(false)
          }else{
            setIsLoadingMore(false)
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
        }
        else{
        setIsLoadingMore(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        }
      }
      }
    }
    //Get Airline Name
    const getAirlineName=(code)=>{
      let name = airportsData.filter((obj) => obj.code === code);
      if(name!==undefined && name[0]!==undefined && name[0].english!==undefined){
        return name!==undefined && name[0]!==undefined && name[0].english!==undefined? name[0].english:"";
      }

    }

  const setCompany = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'cid', payload: obj[0].value })
      dispatch({ type: 'companyData', payload: obj })
    }
  }


  const resetSearch = () => {
    setEnableType(false);
    setEnableATT(false)
    setEnableTA(false)
    setFromDate("")
    setToDate("")
    dispatch({ type: 'applTypData', payload: [] })
    dispatch({ type: 'companyData', payload: [] })
    dispatch({ type: 'branchData', payload: [] })
    dispatch({ type: 'applToData', payload: [] })
  }


  const setAplTo = (obj) => {
    if (obj[0] !== undefined && obj[0].value !== undefined) {
      dispatch({ type: 'aplTo', payload: obj[0].value })
    }
    dispatch({ type: 'applToData', payload: obj })
    dispatch({ type: 'cid', payload: 0 })
    if (obj[0] !== undefined && obj[0].value === "TA") {
      // const pyload = { "type": state.userType, "userId": state.userId }
      //Load all branches based on for ATT
      setEnableATT(true);
      setEnableTA(false)
      if(context.logindata.is_admin){
        AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type}).then(response => {       
          if (response.data.suc){
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in Loadi branches based on Region function---" + error
          );
        });
      }else{
        AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type,"uid":context.logindata.uid}).then(response => {      
          if (response.data.suc){
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in Loadi branches based on Region function---" + error
          );
        });
      }
    } else if (obj[0] !== undefined && obj[0].value === "STA") {
      // if (state.userType === "att_admin") {
        setEnableATT(true);
            setEnableTA(false)
            if(context.logindata.is_admin){
              AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type}).then(response => {
                if (response.data.suc){
                  dispatch({ type: 'branches', payload: response.data.res })
                }
              }).catch(function (error) {
                log.error(
                  "Exception occured in Loadi branches based on Region function---" + error
                );
              });
            }else{
              AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type,"uid":context.logindata.uid}).then(response => {
                if (response.data.suc){
                  dispatch({ type: 'branches', payload: response.data.res })
                }
              }).catch(function (error) {
                log.error(
                  "Exception occured in Loadi branches based on Region function---" + error
                );
              });
            }
      // }
    }else{
      setEnableATT(false);
      setEnableTA(false)
    }
  }

  const selectBranch = (obj) => {
    var branchId = obj[0].value;
    dispatch({ type: 'bid', payload: branchId })
    dispatch({ type: 'branchData', payload: obj })
    if (enabelATT) {
        getCompanyTAList(branchId,state.aplTo).then(response => {
          if (response.data.suc) {
            setEnableTA(true)
            dispatch({ type: 'companyData', payload: [] })
            dispatch({ type: 'companies', payload: response.data.res })
            dispatch({ type: 'aplTo', payload: "TA" })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      // } else {
      //   setEnableTA(false)
      //   console.log("Branch  : ");
      // }
    } else {
      setEnableTA(false);
    }
  }

  const columns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch',
      text: 'Branch',
     sort: false,
      filter: ""
    },
    {
      dataField: 'custnm',
      text: 'Company',
     sort: false,
      filter: ""
    },
    {
      dataField: "currency",
      text: 'Currency',
     sort: false,
      filter: ""
    },
    {
      dataField: 'doc_date',
      text: 'Document Date',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_id',
      text: 'BKR No',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_type',
      text: 'Booking Type',
     sort: false,
      filter: ""
    },
    {
    dataField: 'pnrdte',
    text: 'PNR Creation Date',
    sort: false,
    filter: ""
  },
  {
    dataField: 'tofcid',
    text: 'Ticketing Office Id',
   sort: false,
    filter: ""
  },
  {
    dataField: 'wofcid',
    text: 'Owner Office Id',
   sort: false,
    filter: ""
  },
  {
    dataField: 'branch',
    text: 'Cost Center',
   sort: false,
    filter: ""
  },
  {
    dataField: 'dcmntno',
    text: 'Document No',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier',
    text: 'Supplier',
   sort: false,
    filter: ""
  },
  {
    dataField: 'saleschnl',
    text: 'Sales Channel',
   sort: false,
    filter: ""
  },
  {
    dataField: 'servicetype',
    text: 'Type of Service',
   sort: false,
    filter: ""
  },
  {
    dataField: 'gds_pnr',
    text: 'GDS PNR',
   sort: false,
    filter: ""
  },
  {
    dataField: 'airlnpnr',
    text: 'Airline PNR',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custcode',
    text: 'Customer Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custnm',
    text: 'Customer Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custgrp',
    text: 'Customer Group',
   sort: false,
    filter: ""
  },
  {
    dataField: 'agentnm',
    text: 'Agent Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ticketno',
    text: 'Ticket Number',
   sort: false,
    filter: ""
  },
  {
    dataField: 'lstcunjticketno',
    text: 'Last Conjuction Ticket Number',
   sort: false,
    filter: ""
  },
  {
    dataField: 'pname',
    text: 'Pax Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ptyp',
    text: 'Pax Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'nopax',
    text: 'No of Pax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'deptdate',
    text: 'Deparure Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'arvaldate',
    text: 'Arrival Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'route',
    text: 'Route',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ticket_status',
    text: 'Ticket Status',
   sort: false,
    filter: ""
  },
  {
    dataField: 'nosegments',
    text: 'No of Segments',
   sort: false,
    filter: ""
  },
  {
    dataField: 'dealcode',
    text: 'Deal Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'destcode',
    text: 'Destination Code',
    sort: false,
    filter: ""
  },
  {
    dataField: 'desttination',
    text: 'Destination',
   sort: false,
    filter: ""
  },
  {
    dataField: 'country',
    text: 'Country',
   sort: false,
    filter: ""
  },
  {
    dataField: 'farebasis',
    text: 'Fare Basis',
   sort: false,
    filter: ""
  },
  {
    dataField: 'seccodefltno',
    text: 'Sector and Flight No',
   sort: false,
    filter: ""
  },
  {
    dataField: 'rbd',
    text: 'RBD Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ocls',
    text: 'Onward Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'rcls',
    text: 'Return Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'trstype',
    text: 'Transaction Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'publishfare',
    text: 'Published Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'basefare',
    text: 'Base Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'tax',
    text: 'Tax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'servicefee',
    text: 'Service Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cost',
    text: 'Cost',
   sort: false,
    filter: ""
  },
  {
    dataField: 'markup',
    text: 'Markup',
   sort: false,
    filter: ""
  },
  {
    dataField: 'bag_fare',
    text: 'Baggage Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'meal_fare',
    text: 'Meal Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'seat_fare',
    text: 'Seat Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'disc',
    text: 'Discount',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cccharge',
    text: 'CC Charges',
   sort: false,
    filter: ""
  },
  {
    dataField: 'plb',
    text: 'PLB',
   sort: false,
    filter: ""
  },
  {
    dataField: 'penalty',
    text: 'Penalty',
   sort: false,
    filter: ""
  },
];
if (context.logindata.region_type=="India"){
 var columnsData= [
  
  {
    dataField: 'cgst',
    text: 'CGST',
   sort: false,
    filter: ""
  },
  {
    dataField: 'sgst',
    text: 'SGST',
   sort: false,
    filter: ""
  },
  {
    dataField: 'igst',
    text: 'IGST',
   sort: false,
    filter: ""
  },
  {
    dataField: 'tds_amt',
    text: 'TDS',
   sort: false,
    filter: ""
  },
  {
    dataField: 'selprice',
    text: 'Selling Price',
   sort: false,
    filter: ""
  },
  (((state.userType === "att_user"&&state.aplTo=="TA") || state.userType === "ta_user")&& {
    dataField: 'sup_disc',
    text: 'Supplier Discount',
   sort: false,
    filter: ""
  }),
  {
    dataField: 'profit',
    text: 'Profit',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supfee',
    text: 'Supplier Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'udid_fields',
    text: 'UDID',
   sort: false,
    filter: ""
  },

  {
    dataField: 'markting_airline',
    text: 'Airline Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'agentnm',
    text: 'Staff Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'emd_number',
    text: 'EMD Number',
   sort: false,
    filter: ""
  }]
  columns.push(...columnsData)
} else {
var columnsData =[
  {
    dataField: 'selprice',
    text: 'Selling Price',
   sort: false,
    filter: ""
  },
  {
    dataField: 'profit',
    text: 'Profit',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supfee',
    text: 'Supplier Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'udid_fields',
    text: 'UDID',
   sort: false,
    filter: ""
  },

  {
    dataField: 'markting_airline',
    text: 'Airline Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'agentnm',
    text: 'Staff Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'emd_number',
    text: 'EMD Number',
   sort: false,
    filter: ""
  }]
  columns.push(...columnsData)
}

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const csvHeaders = [
{ label: 'Region Type', key: 'region_type' },
{ label: 'Branch', key: 'branch' },
{ label: 'Company', key: 'custnm' },
{ label: 'Currency', key: 'currency' },
{ label: 'Document Date', key: 'doc_date' },
{ label: 'Booking Ref No', key: 'booking_id' },
{ label: 'Booking Type', key: 'booking_type' },
{ label: 'PNR Creation Date', key: 'pnrdte' },
{ label: 'Ticketing Office Id', key: 'tofcid' },
{ label: 'Owner Office Id', key: 'wofcid' },
{ label: 'Document No', key: 'dcmntno' },
{ label: 'Supplier', key: 'supplier' },
{ label: 'Sales Channel', key: 'saleschnl' },
{ label: 'Type of Service', key: 'servicetype' },
{ label: 'GDS PNR', key: 'gds_pnr' },
{ label: 'Airline PNR', key: 'airlnpnr' },
{ label: 'Customer Code', key: 'custcode' },
{ label: 'Customer Name', key: 'custnm' },
{ label: 'Customer Group', key: 'custgrp' },
{ label: 'Ticket Number', key: 'ticketno' },
{ label: 'Last Conjuction Ticket No', key: 'lstcunjticketno' },
{ label: 'Pax Name', key: 'pname' },
{ label: 'Pax Type', key: 'ptyp' },
{ label: 'No of Pax', key: 'nopax' },
{ label: 'Departure Date', key: 'deptdate' },
{ label: 'Arrival Date', key: 'arvaldate' },
{ label: 'Route', key: 'route' },
{ label: 'Ticket Status', key: 'ticket_status' },
{ label: 'No of Segments', key: 'nosegments' },
{ label: 'Deal Code', key: 'dealcode' },
{ label: 'Destination', key: 'desttination' },
{ label: 'Destination Code', key: 'destcode' },
{ label: 'Country', key: 'country' },
{ label: 'Farebasis', key: 'farebasis' },
{ label: 'Sector and Flight No', key: 'seccodefltnoExcel' },
{ label: 'RBD', key: 'rbd' },
{ label: 'Onward Class', key: 'ocls' },
{ label: 'Return Class', key: 'rcls' },
{ label: 'Transaction Type', key: 'trstype' },
{ label: 'Published Fare', key: 'publishfare' },
{ label: 'Base Fare', key: 'basefare' },
{ label: 'Tax', key: 'tax' },
{ label: 'Service Fee', key: 'servicefee' },
{ label: 'Penalty', key: 'penalty' },
{ label: 'Cost', key: 'cost' },
{ label: 'Markup', key: 'markup' },
{ label: 'Baggage Fare', key: 'bag_fare' },
{ label: 'Meal Fare', key: 'meal_fare' },
{ label: 'Seat Fare', key: 'seat_fare' },
{ label: 'Disc', key: 'disc' },
{ label: 'CC Charge', key: 'cccharge' },
{ label: 'PLB', key: 'plb' },
];
if (context.logindata.region_type=="India"){
 var exportData= [
{ label: 'CGST', key: 'cgst' },
{ label: 'SGST', key: 'sgst' },
{ label: 'IGST', key: 'igst' },
{ label: 'TDS', key: 'tds_amt' },
{ label: 'Selling Price', key: 'selprice' },
{ label: 'Supplier Discount', key: 'sup_disc' },
{ label: 'Profit', key: 'profit' },
{ label: 'Supplier Fee', key: 'supfee' },
{ label: 'UDID Fields', key: 'udid_fields' },
{ label: 'Airline Name', key: 'markting_airline' },
{ label: 'Staff Name', key: 'agentnm' },
{ label: 'EMD Number', key: 'emd_number' }
  ]
  csvHeaders.push(...exportData)
} else{
 var exportData= [
{ label: 'Selling Price', key: 'selprice' },
{ label: 'Profit', key: 'profit' },
{ label: 'Supplier Fee', key: 'supfee' },
{ label: 'UDID Fields', key: 'udid_fields' },
{ label: 'Airline Name', key: 'markting_airline' },
{ label: 'Staff Name', key: 'agentnm' },
{ label: 'EMD Number', key: 'emd_number' }
  ]
  csvHeaders.push(...exportData)
}
const paxDate = (dt) => {
  // if(toDate){
    let oneYearDate= new Date(toDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() -1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
//   }else{
//   return new Date(dt.setFullYear(dt.getFullYear()));
//  }
}
const returnDate = ()=>{
  // if(fromDate){
  //   let beforecurrentDate = paxDate(new Date()).setDate(new Date().getDate() - 30)
  //   let fromdateadded = paxDate(fromDate).setDate(fromDate.getDate())
  //   if(fromdateadded < beforecurrentDate ){
      let oneYearDate= new Date(fromDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() + 1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
  //   }
  //  else{
  //    return new Date()
  //  }
  // }
  // else{
  //   return new Date()
  // }
}
  return (

    <>
     <ReportMenu active='Air Services' />
      <Breadcrumb activePage="DSR Report" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <FlightreportMenu active="Flight DSR" />
      <div>
        <br/>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        {state.userType === "att_user" &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={applicabelTo}
                searchable={false}
                values={state.applToData}
                onChange={(value) => (setAplTo(value))}
              />
              <Form.Label>Applicable To</Form.Label>
            </Form.Group>
            {enabelATT &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.branches}
                  searchable={false}
                  values={state.branchData}
                  onChange={(value) => (selectBranch(value))}
                />
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
            }
            {enabelTA && (state.aplTo === "TA" || state.userType === "att_user") &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.companies}
                  searchable={false}
                  values={state.companyData}
                  onChange={(value) => (setCompany(value))}
                />
                <Form.Label>Company<sup>*</sup></Form.Label>
              </Form.Group>
            }
          </Form.Row>
        }
        {state.userType === "ta_user" && state.companies.length !== 0 &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={state.companies}
                searchable={false}
                values={state.companyData}
                onChange={(value) => (setCompany(value))}
              />
              <Form.Label>Company</Form.Label>
            </Form.Group>
          </Form.Row>
        }
        <Form.Row>
          <Form.Group as={Col} xs={3}>
            <Form.Row>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}

                    fromDate={fromDate}
                    toDate={toDate}
                    maxDate={ toDate ? toDate : new Date()}
                    minDate={toDate && paxDate(toDate).setDate(toDate.getDate()) }
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>From Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate && fromDate}
                    maxDate= {returnDate()}
                    className="datefield"
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>To Date<sup>*</sup></Form.Label>
                </Form.Group>
            </Form.Row>
          </Form.Group>
        </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
        <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={handleSearch}
        > Search</Button>
      </div>

      <h4 class="subTitle plusHr mt-3 mb-3">DSR Report</h4>
       {dsrExceldata.dsrReportExcelList!==undefined && dsrExceldata.dsrReportExcelList.length !== 0 &&
        <CoomonExportExcel data={dsrExceldata.dsrReportExcelList} headers={csvHeaders} show={true} name={"DSR_Report.csv"}/>
       }
      <div className="cardMainlayout tableRes p-0">
      {isLoadingMore ? <InnerLoader /> : (<>
        {dsrdata.dsrReportList.length !== 0 &&
        <div className="tablescroll">
          <BootstrapTable
          bootstrap4
          keyField="id"
          classes="markup filterTbl myReports"
          data={dsrdata.dsrReportList}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={dsrdata.dsrReportList.length >10 && dsrdata.dsrReportList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
          dsrdata.dsrReportList.length >25 && dsrdata.dsrReportList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
          dsrdata.dsrReportList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
          />
          {state.totals !== undefined && state.totals !== "" && <div className="cardMainlayout finalTotal">

            <div className="react-bootstrap-table">
            <table className="table table-bordered markup filterTbl myReports">
              <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Published Fare</th>
                <th>Base Fare	</th>
                <th>Tax</th>
                <th>Service Fee	</th>
                <th>Cost</th>
                <th>Markup</th>
                <th>Baggage Fare</th>
                <th>Meal Fare</th>
                <th>Seat Fare</th>
                <th>Discount</th>
                <th>CC Charges</th>
                <th>PLB</th>
                <th>Penalty</th>
                {context.logindata.region_type=="India" &&
                <th>CGST</th>}
                {context.logindata.region_type=="India" &&
                <th>SGST</th>}
                {context.logindata.region_type=="India" &&
                <th>IGST</th>}
                {context.logindata.region_type=="India" &&
                <th>TDS</th>}
                <th>Selling Price	</th>
                {((state.userType === "att_user"&&state.aplTo=="TA") || state.userType === "ta_user" )&& <th>Supplier Discount	</th>}
                <th>Profit</th>
                <th>Supplier Fee</th>

              </tr>
              </thead>

            <tr>
              <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_publishfare.toFixed(2)}</td>
              <td>{state.totals.tot_basefare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_servicefee.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_bag_fare.toFixed(2)}</td>
              <td>{state.totals.tot_meal_fare.toFixed(2)}</td>
              <td>{state.totals.tot_seat_fare.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_cccharge !== null && state.totals.tot_cccharge.toFixed(2)}</td>
              <td>{state.totals.tot_plb.toFixed(2)}</td>
              <td>{state.totals.tot_penalty.toFixed(2)}</td>
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_cgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_sgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_igst.toFixed(2)}</td>}
              {context.logindata.region_type=="India" &&
              <td>{state.totals.tot_tds.toFixed(2)}</td>}
              <td>{state.totals.tot_selprice.toFixed(2)}</td>
              {((state.userType === "att_user"&&state.aplTo=="TA") || state.userType === "ta_user")&& 
              <td>{Number(state.totals.tot_sup_disc).toFixed(2)}</td>}
              <td>{state.totals.tot_profit.toFixed(2)}</td>
              <td>{state.totals.tot_supfee.toFixed(2)}</td>
            </tr>
            </table>

          </div>
          </div>}
        </div>
          || dsrdata.dsrReportList.length === 0
          && <NoRecord />}</>)}
      </div>

              </div>
              </div>
<Footer/>
    </>
  )
}
export default DSRReports