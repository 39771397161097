import React, { Component, useState,useContext } from 'react'; 
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";
import Header from '../Header';
import MainMenu from '../../../components/common/MainMenu';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';


function OrganizationMenuFun(props) {   
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
   /* const redirect = (event) => {
      event.preventDefault();
      if (event.target.id === "branch") {
        history.push("/ezytrip/administration/branch");
      }else if(event.target.id === "category"){
        history.push("/ezytrip/administration/category");
      }else if(event.target.id === "markup"){
        history.push("/ezytrip/administration/markup");
      }else if(event.target.id === "user"){
        history.push("/ezytrip/administration/user");
      }else if(event.target.id === "role"){
        history.push("/ezytrip/administration/role");
      }
     
    }*/
  //const [mainNav, setmainNav,] = useState(false);

return (
  <>

        <div className="submenu">
          
            <ul>
            {context.logindata.roles && context.logindata.roles.split(",").includes('B_C') && (context.logindata.utype && context.logindata.utype == "att_user") && <li  className={state.active==="Branch Creation" ? 'active':''}>
              <Link
                to="/ezytrip/administration/branch" 
                title="Branch Creation"
              >                
                Branch Creation
              </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('C_C_M') &&(context.logindata.utype && context.logindata.utype=="att_user") && <li  className={state.active==="CountryMaster" ? 'active':''}>
              <Link
              to="/ezytrip/administration/CountryMaster"              
              title="Country Master"
            >               
              Country Master
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('C_T') && (context.logindata.utype && context.logindata.utype == "att_user")  && <li  className={state.active==="Category Creation" ? 'active':''}>
              <Link
              to="/ezytrip/administration/category"              
              title="Category Creation"
            >               
              Category Creation
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('C_O_M') && (context.logindata.utype && context.logindata.utype=="att_user") && <li  className={state.active==="OIDMaster" ? 'active':''}>
              <Link
              to="/ezytrip/administration/oidMaster"              
              title="OID Master"
            >               
              OID Master
            </Link>
              </li>}
              {context.logindata.roles && context.logindata.roles.split(",").includes('C_C')  && <li  className={state.active==="Company Creation" ? 'active':''}>
              <Link
              to="/ezytrip/administration/company"
              title="Company Creation"
            >                
              Company Creation
            </Link>
              </li>}
              
              {context.logindata.utype == "att_user" && context.logindata.roles && context.logindata.roles.split(",").includes('C_F')  && <li  className={state.active==="Custom Fields" ? 'active':''}>
              <Link
              to="/ezytrip/administration/customFields"
              title="Custom Fields"
            >                
              Custom Fields
            </Link>
              </li>}

              {context.logindata.roles && context.logindata.roles.split(",").includes('C_M_O_H') && context.logindata.utype == "att_user" && <li  className={state.active==="OID-Max Hits" ? 'active':''}>
              <Link
              to="/ezytrip/administration/oidMaxHits"
              title="OID-Max Hits"
            >                
              OID-Max Hits
            </Link>
              </li>}

               {context.logindata.utype == "att_user" && <li  className={state.active==="Supplier Config" ? 'active':''}>
              <Link
              to="/ezytrip/administration/supplierConfig"
              title="Supplier Config"
            >                
              Supplier
            </Link>
              </li>}

              {context.logindata.roles && context.logindata.roles.split(",").includes('G_S_T') && context.logindata.utype == "att_user" && <li  className={state.active==="GST Config" ? 'active':''}>
              <Link
              to="/ezytrip/administration/gst"
              title="GST"
              >                
               GST
            </Link>
              </li>}

              {context.logindata.roles && context.logindata.roles.split(",").includes('T_D_S') && context.logindata.utype == "att_user" && <li  className={state.active==="TDS Config" ? 'active':''}>
              <Link
              to="/ezytrip/administration/tds"
              title="TDS Config"
              >                
              TDS
            </Link>
              </li>}

              {context.logindata.utype == "att_user" && <li  className={state.active==="Exchange Rate" ? 'active':''}>
              <Link
              to="/ezytrip/administration/exchangeRate"
              title="Exchange Rates"
            >                
              Exchange Rates
            </Link>
              </li>}

              {/* {context.logindata.utype == "att_user" && <li  className={state.active==="Customer Request" ? 'active':''}>
              <Link
              to="/ezytrip/administration/custRequests"
              title="Customer Requests"
            >                
              Customer Requests
            </Link>
              </li>}

            {context.logindata.utype == "att_user" && <li  className={state.active==="User Profile" ? 'active':''}>
              <Link
              to="/ezytrip/administration/userprofile"
              title="User Profiles"
            >                
              User Profiles
            </Link>
              </li>} */}
              
            </ul>
          </div>
   </>
  );
};



class OrganizationMenu extends Component {
  constructor(props) {
  super(props);
  console.log("propspropsprops",props)
  this.state = {
         active:props.active
          
      }; 
  }

  render() {
  return <OrganizationMenuFun state={this.state} />
}
}

export default OrganizationMenu;

