import React, { useState,useContext,useRef  } from 'react';
import { Table, Modal,Button, Overlay, Tooltip  } from "react-bootstrap";
import {Context} from '../../../../App'



/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function IndRefundDetails(props) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [refundResp, setResponse] = useState(props.response);
    const [attPriceList, setattPriceList] = useState();
    const [context, setContext] = useContext(Context);


    return (
        <div className="recordInfo RefunDet">
            {props.refundData.length !== 0 && props.refundData !== undefined &&
                <Table className="m-0">
                    <thead>
                        <tr>
                            <th>Pax Name</th>
                            <th>Fare Paid</th>
                            <th className="canclInfo">Supplier Fee</th>
                            <th className="canclInfo">Service Fee</th>
                            <th className="canclInfo">Airline Charges</th>
                            <th className="canclInfo">Refund Markup</th>
                            <th className="canclInfo">Refund Fee</th>
                            <th>Total Refund</th>
                        </tr>
                    </thead>

                    <tbody>
                        {props.refundData.map((pr, idx) => (
                            <tr>
                                <td>

                                    <div className='indigoTooltip d-flex'>
                                    <span>{pr.pax_name[0].toUpperCase()}</span>

{pr.pax_name.length > 1 &&

<span className="d-flex toolTipInfo"><span className='viewAllp'>View all Pax</span>
<div className="toolTipCUstoom"
style={{"width":"260px"}}>
<span>{pr.pax_name.map((pax, idx) => (
                                <span>{pax.toUpperCase()}  -  {pr.split_pnr!==null && pr.split_pnr!==undefined?pr.split_pnr:pr.pnr}<br/></span>
                                 ))}</span>
</div>
</span>



}



                                    </div>



                                </td>
                                <td>
                                    <span>{pr.totalfare}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.supsfee}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.bkservice}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.aircharge}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.canmarkup}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.canservice}</span>
                                </td>
                                <td>
                                    <span>{pr.subtotal}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            }
        </div>
    )
}
export default IndRefundDetails