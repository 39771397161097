/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Lakshmi
 *
 */
 import React, { useState, useEffect } from 'react';

 /**
  * @description: This function will show the Booking Details section
  * @date:18-09-2020
  * @author:Lakshmi
  *
  */
 function InsuranceFarePdf(props) {
   const [state, setstate] = useState()
   const [premiumPrice,setPremiumPrice] = useState(0)

   useEffect(() => {
      let price=0;
      if(props.response!==undefined && props.response.plan!==undefined && props.response.plan.optional_covers!==undefined && props.response.plan.optional_covers.length!==0){
        for(let val of props.response.plan.optional_covers){
          if(price===0){
            price=Number(val.premium);
          }else{
           price=Number(price)+Number(val.premium);
          }
       }
      }
      setPremiumPrice(price);
  }, [])
   
   return (
     <>
     {props.response!==undefined && props.response.plan!==undefined &&
       <div className="flightbookinginfoPdf">
       <h5 className="mb-4"> Fare details(QAR)</h5>
         <div className="flightheader">
         <span>Plan Type {props.response.plan.scheme_name}</span><span></span>
         <span>Amount  QAR {Number(props.response.plan.net_premium)-Number(premiumPrice)}</span>
          {props.response.plan!==undefined && props.response.plan.optional_covers.length!==0 && props.response.plan.optional_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span> QAR {plan.premium}
                         </div>
                     </React.Fragment>
          ))}
         </div>
       </div>
      }
     </>
   )
 }
 
 
 export default InsuranceFarePdf;
 