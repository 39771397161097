
 import React, { useState, useEffect, useReducer } from 'react';
 import {Table } from "react-bootstrap";
 import { isNotNull, isArrayNotEmpty } from '../../commonUtils/validators';
 import FormateCurrency from '../../commonUtils/FormateCurrency';

 /**
  * Initial State Declaration
  */
 const initialState = {
     fareobj: '', grand_total: 0
 };

 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };

 /**
  * @description: This function will show the FareDetails
  * @date:09-01-2020
  * @author:Laskhmi
  *
  */
 function ImportPriceDetails(props) {
     const [state, dispatch] = useReducer(reducer, initialState);

     useEffect(() => {
         //if props data avaliable
         if (isNotNull(props.faredet)){
            let adts=props.faredet.psngrs.filter((obj) => obj.ty === "ADT");
            let inf=0;
            for(let val of adts){
               if(val.inf!==undefined && val.inf!=="" && val.inf.ty==="INF"){
                   inf=inf+1;
               }
            }
             let chds=props.faredet.psngrs.filter((obj) => obj.ty === "CHD");
             let paxCount=adts.length+chds.length+inf;
             let pax_array = [];
             let grandtotal = 0;
             for (let p in props.faredet.psngrs) {
                 let pax = props.faredet.psngrs[p];
                 let onward_sector = '';
                 //below code if normal flow we are taking srcorigns data
                     console.log('Entered normal flow');
                     //get teh total fare amount from pr tag
                     grandtotal = props.faredet.origns[0]['srcorigns'][0].pr.tf;
                     //if normal flow we are taking srcorigns tag data only
                     onward_sector = props.faredet.origns[0]['srcorigns'][0];
                     //adult fare data from srcorigns paxpr tag
                     let adult_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'ADT'
                     });
                     //child fare data from srcorigns paxpr tag
                     let chd_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'CHD'
                     });
                     //infant fare data from srcorigns paxpr tag
                     let inf_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'INF'
                     });
                     //if adult, constructing fare data
                     if (pax.ty.toUpperCase() === 'ADT') {
                         let totalfare = adult_fare_data[0].tf;//total_fare
                         let basefare = adult_fare_data[0].bf;//base_fare
                         let tax=getTaxValue(adult_fare_data[0].tax);
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Adult';
                         let lead = pax.lead;
                         const obj = {
                             nm: pax_name,
                             lead: lead,
                             ty: paxtype,
                             bf: basefare,
                             tax: parseFloat(tax),
                             tf: totalfare
                         }
                         pax_array.push(obj);
                       if(pax.inf!==undefined && pax.inf!==""){
                        let pax_name = pax.inf.fn + ' ' + pax.inf.ln;
                        let paxtype = 'Infant';
                        let totalfare = inf_fare_data[0].tf;//total_fare
                        let basefare = inf_fare_data[0].bf;//base_fare
                        let tax=getTaxValue(inf_fare_data[0].tax);
                        const obj = {
                            nm: pax_name,
                            ty: paxtype,
                            bf: basefare,
                            tax: parseFloat(tax),
                            tf: totalfare
                        }
                        pax_array.push(obj);
                       }

                     }else if (pax.ty.toUpperCase() === 'CHD') {
                         let totalfare = chd_fare_data[0].tf;//total_fare
                         let basefare = chd_fare_data[0].bf;//base_fare
                         let tax=getTaxValue(chd_fare_data[0].tax);
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Child';
                         const obj = {
                             nm: pax_name,
                             ty: paxtype,
                             bf: basefare,
                             tax: parseFloat(tax),
                             tf: totalfare
                         }
                         pax_array.push(obj);
                     }
             }
             dispatch({ type: 'fareobj', payload: pax_array });
             dispatch({ type: 'grand_total', payload: grandtotal});
         }
     }, []);

     /**
      * @description:gettaxvalue
      * @date:11-12-2020
      * @author:Rambabu
      */
     function getTaxValue(taxarry) {
         let tax = 0
         for (let t in taxarry) {
             let tax_d = taxarry[t];
             if (isNotNull(tax_d.YQ)) {
                 tax = tax+tax_d.YQ;
             }
             if (isNotNull(tax_d.YR)) {
                 tax = tax+tax_d.YR;
             }
             if (isNotNull(tax_d.OT)) {
                 tax = tax+tax_d.OT;
             }
         }
         return tax;
     }


     return (
         isNotNull(state.fareobj) && (
             <>
               <div className="selectedRout tableLayout">
            <Table>
              <thead>
                <tr>
                <th>Traveller</th>
                <th>Pax Type</th>
                <th>Fare</th>
                <th>Taxes & Fee</th>
                <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
              {state.fareobj.map((fdet, index) => (
                         <React.Fragment key={index}>
                             <tr>
                                 <td>{fdet.nm.toUpperCase()}{(isNotNull(fdet.lead) && fdet.lead) && '(Lead)'}</td>
                                 <td>{fdet.ty}</td>
                                 <td>{props.faredet.cur} {<FormateCurrency value={fdet.bf} />}</td>
                                 <td>{props.faredet.cur} {<FormateCurrency value={fdet.tax} />}</td>
                                 <td  className="text-right">{props.faredet.cur} {<FormateCurrency value={fdet.tf} />}</td>
                                 </tr>
                         </React.Fragment>
                     ))}
                     <tr><td className="total" colSpan="5">
                     <div className="d-flex justify-content-end"><span>Grand Total : </span> <div className="pricetag"><em>{props.faredet.cur}</em> <strong>{<FormateCurrency value={state.grand_total} />}</strong></div>
                         </div></td></tr>
              </tbody>
              </Table>
              </div>


             </>))
 }
 export default ImportPriceDetails;
