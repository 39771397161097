
import React, { useState} from 'react';
import 'react-input-range/lib/css/index.css';
import Tabs from '../../../../common/tabs/Tabs';
import Map from '../../../hotel/common/reviewpage/Map'
import Overview from '../../../hotel/common/reviewpage/Overview'
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function MapView(props) {
  const [state, setState] = useState({})

  const [tablist, setTablist] = useState({
    active: 'Map'
  });

  const content = {
    Map: <Map attractions={props.attractions} location={props.location} hname={props.hname}/>,
    OverView: <Overview userreview={props.description} />,
  };


  return (

    <>

      <div className="tabLink">
          <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
            <span key="Map">Map</span>
            {props.description !== undefined && <span key="OverView">Overview</span>}
          </Tabs>
          <div className="tabContent pb-0">
            {content[tablist.active]}
          </div>
        </div>

    </>
  )
}


export default MapView;
