/**
 * @description : Dash Board Details Will diplay in this page
 * @author :Praavs Kumar
 * @date : 04-09-2020
 */
 import React, { useContext, useReducer, useState, useEffect } from 'react';
 import { Alert, Col, Row } from "react-bootstrap";



 /**
  * Initial State Declaration
  */
 const initialState = {

 }
 // Reducer function for useReact Hook which will update the state
 const reducer = (state, action) => {
   switch (action.type) {
     case 'clear':
       return {
         ...state, categoryName: ''
       };
     default:
       return { ...state, [action.type]: action.payload };
   }
 };
 //=================
 // Function Implementaton which will recevie props
 //=================
 function WaitingForReview(props) {
   const [state, dispatch] = useReducer(reducer, initialState);

   const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

   return (
     <>
       <p>Waiting for Review</p>
     </>
   )

 }
 export default WaitingForReview