import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import BooingDetailsPdf from './BooingDetailsPdf';
import TransactionSummaryPdf from './TransactionSummaryPdf';
import { Alert, Col, Row } from "react-bootstrap";
import RoomPaxInfoPdf from './RoomPaxInfoPdf';
import ShowHide from '../../../common/showHide/ShowHide';
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";

const initialState = { mtaList:[]};
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };
const HotelVoucherPdf = React.forwardRef((props, ref) => {

const [state, dispatch] = useReducer(reducer, initialState);

useEffect(() => {
  let mtaList=props.fareDetails.filter((obj) => obj.type==="MTA")
     dispatch({type:"mtaList" , payload: mtaList})
    }, [props.fareDetails])

  return (
    <div className="bg-gray-200 p-6" ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
                <h2>
                  Hotel Voucher
                </h2>
                <p>Present at the hotel when Check-In</p>
                </div>
              <div className="bookinginfoPdf">
              {props.bkngdetails!==undefined && props.bkngdetails!==null && props.bkngdetails.company_logo!==null? (
               <div className="img"> <img
               src={props.bkngdetails.company_logo}
               className="htlImg"
             /> </div>
             ) : (
              <div className="img"> <img
               src={logo}
               className="htlImg"
               /> </div>
              )
              }
               {props.bkngdetails !== undefined &&
               <TransactionSummaryPdf bkngdetails={props.bkngdetails} />
               }
              </div>

              <div className="">
              <h3 className="pdfSubTitl">
                  Hotel Details
                </h3>
                {props.htldetails!==undefined &&
                 <BooingDetailsPdf htldetails={props.htldetails} roomsCount={props.roomsCount} />
                }
              </div>

              <div className="roomlist">
              <h3 className="pdfSubTitl">
                  Guest Itinerary
              </h3>
              {state.mtaList.length!==0 && state.mtaList[0].rooms.map((room, index) => (
              <>
              {room.rst !== "Cancelled" &&
              <div className="list">
                <Row>
                  <Col xs={7}>
                  <h5><span className='mr-4'>{"Room "+(index+1)+" : "+ room.rnm} </span></h5>
                  </Col>
                  <Col xs={5}>
                  <h5><span>{room.htlconfno!==undefined && room.htlconfno?"Supplier Ref No:   "+room.htlconfno:"N/A"}</span></h5>
                  </Col>
                </Row>

               <RoomPaxInfoPdf roomObj={room} type={"MTA"} policy={props.enablePolicy} allPax = {props.allPax} bookType={props.bkngdetails.bktype}/>
               </div>
               }
               </>
               ))}
              </div>
              <div className="info">
                 <p><strong>Note:</strong> Special requests are not guaranteed and are at the hotel's discretion. Additional charges may apply depending on the hotels policies.</p>
                 </div>
                 <p className="mt-4"><strong>Due at property:</strong> {props.bkngdetails!==undefined && props.bkngdetails.price_note!==""?props.bkngdetails.price_note:"N/A"}</p>

            </div>

            <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>
      </div>
    </div>
    </div>
  );
});

export default HotelVoucherPdf;
