
import log from "loglevel";
import React, { Component, useContext, useEffect, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import InnerLoader from '../../../common/loader/InnerLoader';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import MasterServicesMenu from '../../../common/subMenu/MasterServicesMenu';
import CreateRole from './index';
import { getRolesSearch } from './operations';
export const RoleContext = React.createContext();

//This is common function for the User managment.
function RoleManagementFun() {
  const history = useHistory();
  const [enableView, setEnableView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [context, setContext] = useContext(Context);
  const [utype, setUtype] = useState([])
  const [urole, setRole] = useState('')
  const [state, setState] = useState({ res: [] });
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [data, setData] = useState({
    loading: false,
  })

  let applicabelTo = []
  if(context.logindata.utype == "att_user") {
   applicabelTo = [{ value: "TA", label: 'Travel Agent' }, { value: "ATT", label: 'ATT Staff' },
   { value: "STA", label: 'Sub Travel Agent' }]
  } else if(context.logindata.utype == "ta_user") {
     applicabelTo = [{ value: "TA", label: 'Travel Agent' },
   { value: "STA", label: 'Sub Travel Agent' }]
  } else {
    applicabelTo = [{ value: "STA", label: 'Sub Travel Agent' }]
  }
  const createRolesBtnClick = (e) => {
    setState({ CreateRole: true })
  }

  const editRole = (obj, type) => () => {
    setState({ CreateRole: true })
    setData(obj)
    if (type === "view") {
      setEnableView(true);
    } else {
      setEnableView(false);
    }
    if (type === "edit") {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }

  const columns = [{
    dataField: 'name',
    text: 'Role Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'applType',
    text: 'Applicable Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== undefined) {
      let rq_Json = {
        "uid": context.logindata.uid,
        "is_admin":context.logindata.is_admin,
        "usertype":context.logindata.utype
      }
      setIsLoadingMore(true)
      getRolesSearch(rq_Json).then(response => {
        if (response.data.suc) {
          loadAllRoles(response.data.res)
        } else {
        }
        setIsLoadingMore(false)
      }).catch(function (error) {
        log.error(
          "Exception occured in getUserData function---" + error
        );
      });
    }
  }, [context.logindata]);

  const loadAllRoles = (res) => {
    let data = [];
    var enableWEdit = false;
    if (context.logindata.roles.includes('R_M_U')) {
      enableWEdit = true;
    }
    let response = [];
      if(context.logindata.is_admin == 1) {
        response = res.filter((s,i) => s.ctype !== "STA")
    } else {
      response = res
    }
    for (let value of response) {
      data.push({ name: value.rn, applType: value.ctype, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editRole(value, "view")}></a>{enableWEdit && <a className="ezyIcon icon-edit" onClick={editRole(value, "edit")}></a>}</span> </> })
    }
    setState({ res: data })
  }

  /**
    * @description : searchCriteria function
    * @author : Azamuddin
    * @date : 27-10-2020
    */
  const search = () => {
    if (utype.length > 0 || urole !== '') {
      //request
      let rq_Json = {
        "urole": urole,
        "utype": utype.length > 0 ? utype[0].value : '',
        "uid": context.logindata.uid,
        "is_admin":context.logindata.is_admin,
        "usertype":context.logindata.utype

      }
      getRolesSearch(rq_Json).then(response => {
        if (response && response.data && response.data.suc) {
          loadAllRoles(response.data.res)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getUserData function---" + error
        );
      });
    } else {
      console.log('No data Available foe Search Criteria')
    }
  }

  const resetValue = () => {

    setRole('')
    setUtype([])
  }
  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'userRole') {
      setRole(value)
    }
  }

  return (
    <>

      <MainMenu active='Master Services' />
      <Breadcrumb activePage="Role Management" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <MasterServicesMenu active="Role Management" />
          <div className="contentArea">

            {!state.CreateRole ? (
              <>
                <Form.Row>

                  <Form.Group as={Col} xs={3} controlId="userRole">
                    <Form.Control type="text" value={urole} onChange={handleChange} placeholder="User Role" autoComplete="off" />
                    <Form.Label>User Role</Form.Label>
                  </Form.Group>

                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="companyType">
                    <Select
                      options={applicabelTo}
                      searchable={false}
                      values={utype}
                      onChange={(value) => (setUtype(value))}
                    />
                    <Form.Label>Applicable Type</Form.Label>
                  </Form.Group>
                </Form.Row>



                <div className="buttonGrop pb-0 d-flex justify-content-between">
                  {context.logindata && context.logindata.roles !== undefined && context.logindata.roles.includes('R_M_C') &&
                    <Button
                      size="xs"
                      variant="primary"
                      onClick={createRolesBtnClick}
                      type="submit"
                      className="addPlus ezyIcon icon-plus"
                    > Create Role</Button>
                  }

                  <div className="d-flex">
                    <Button
                      size="xs"
                      variant="outline-secondary"
                      type="submit"
                      onClick={resetValue}
                    > Reset</Button>
                    <Button
                      size="xs"
                      variant="outline-primary"
                      type="submit"
                      onClick={search}
                    > Search</Button>
                  </div>


                </div>

              </>) : (
                <>
                  <RoleContext.Provider value={data}>
                    <CreateRole disableBtn={enableView} editAction={edit} />
                  </RoleContext.Provider>

                </>
              )}
          </div>
        </div>
        {!state.CreateRole ? (<div className="cardMainlayout p-0 tableRes">
          {isLoadingMore ? <InnerLoader /> : (<>
            {state.res.length !== 0 &&
              <BootstrapTable
                bootstrap4
                keyField="id"
                classes="markup filterTbl"
                data={state.res}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
              />
              || state.res.length === 0 &&
              <NoRecord />
            }
          </>)}
        </div>) : ('')}
      </div>
      <Footer />
    </>

  )
}
class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {


    };
  }

  render() {
    return <RoleManagementFun state={this.state} />
  }
}
export default RoleManagement