
import React, { useEffect, useReducer } from 'react';
import Select from 'react-select';
import Button from '../../common/buttons/Button';

const initialState = {
  adultCount: 1, childCount: 0, infantsCount: 0, TravellClass: null, adtmax: 9, chdmax: 9, infmax: 9, cls: "Economy"
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AddTravellerClass(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "adultCount", payload: props.sendCount[0] })
    dispatch({ type: "childCount", payload: props.sendCount[1] })
    dispatch({ type: "infantsCount", payload: props.sendCount[2] })
    dispatch({ type: "TravellClass", payload: [{ "value": props.sendCount[3], "label": props.sendCount[3] }] })
  }, [props.sendCount]);

  useEffect(() => {
    if(props.oldCls!==undefined && props.oldCls!==""){
      dispatch({ type: "TravellClass", payload: [{ "value": props.oldCls, "label": props.oldCls }] })
    }
  }, [props.oldCls]);

  const handleIncrementAdlt = () => {
    if (state.adultCount < state.adtmax && state.adultCount + state.childCount < 9) {
      dispatch({ type: "adultCount", payload: state.adultCount + 1 })
      totalPax("Increment", "ADT");

    }
  };
  const handleDecrementAdlt = () => {
    if (state.adultCount > 1) {
      dispatch({ type: "adultCount", payload: state.adultCount - 1 })
      if (state.adultCount === state.infantsCount) {
        dispatch({ type: "infantsCount", payload: state.adultCount - 2 })
      }
      totalPax("Decrement", "ADT")
    }
  };

  const handleIncrementChild = () => {
    if (state.adultCount + state.childCount < 9) {
      dispatch({ type: "childCount", payload: state.childCount + 1 })
      totalPax("Increment", "CHD")
    }
  };
  const handleDecrementChild = () => {
    if (state.childCount > 0) {
      dispatch({ type: "childCount", payload: state.childCount - 1 })
      totalPax("Decrement", "CHD")
    }
  };

  const handleIncrementInf = () => {
    if (state.adultCount !== state.infantsCount) {
      dispatch({ type: "infantsCount", payload: state.infantsCount + 1 })
      totalPax("Increment", "INF")
    }
  };
  const handleDecrementInf = () => {
    if (state.infantsCount > 0) {
      dispatch({ type: "infantsCount", payload: state.infantsCount - 1 })
      totalPax("Decrement", "INF")
    }
  };
  const totalPax = (type, pax) => {
    if (type === "Increment") {
      const totalCount = state.adultCount + state.childCount + state.infantsCount + 1;
      if (pax === "ADT") {
        props.sendData([state.adultCount + 1, state.childCount, state.infantsCount, totalCount]);
      } else if (pax === "CHD") {
        props.sendData([state.adultCount, state.childCount + 1, state.infantsCount, totalCount]);
      } else if (pax === "INF") {
        props.sendData([state.adultCount, state.childCount, state.infantsCount + 1, totalCount]);
      }
    } else {
      const totalCount = state.adultCount + state.childCount + state.infantsCount - 1;
      if (pax === "ADT") {
        if (state.adultCount === state.infantsCount) {
          props.sendData([state.adultCount - 1, state.childCount, state.adultCount - 1, totalCount - 1]);
        } else {
          props.sendData([state.adultCount - 1, state.childCount, state.infantsCount, totalCount]);
        }
      } else if (pax === "CHD") {
        props.sendData([state.adultCount, state.childCount - 1, state.infantsCount, totalCount]);
      } else if (pax === "INF") {
        props.sendData([state.adultCount, state.childCount, state.infantsCount - 1, totalCount]);
      }
    }
  }
  const TravellClass = [
    { value: 'Economy', label: 'Economy' },
    {value: 'Premium',label:'Premium Economy'},
    { value: 'Business', label: 'Business' },
    { value: 'First class', label: 'First class' }]

  const handleChange = TravellClass => {
    dispatch({ type: "TravellClass", payload: TravellClass })
    props.sendClass(TravellClass.value);
  };
  const closeTrvl = () => {
    props.closetravaler("coseTrv");
  }
  return (
    <>
      <div className="dropDownPopUP travell">
        <h5>Travellers <span onClick={closeTrvl} className="closeTravel ezyIcon icon-close"></span></h5>
        <ul className="m-0 p-0">
          <li className="d-flex  align-items-center justify-content-between"><label>Adult</label>
            <div className="incDec d-flex align-items-center justify-content-center">

              <Button size="md" className="minus ezyIcon icon-minus" onClick={handleDecrementAdlt} variant="link" isDisabled={props.flowType === "REISSUE"} ></Button>

              <input type="text" className="number" value={state.adultCount}></input>
              <Button size="md" className="plus ezyIcon icon-plus" onClick={handleIncrementAdlt} variant="link" isDisabled={props.flowType === "REISSUE"}></Button>
            </div>
          </li>

          <li className="d-flex  align-items-center justify-content-between"><label>Children <span>(2-12 yrs)</span></label>
            <div className="incDec d-flex align-items-center justify-content-center">

              <Button size="md" className="minus ezyIcon icon-minus" onClick={handleDecrementChild} variant="link" isDisabled={props.flowType === "REISSUE"} >
               </Button>

              <input type="text" className="number" value={state.childCount}></input>
              <Button size="md" className="plus ezyIcon icon-plus" onClick={handleIncrementChild} variant="link" isDisabled={props.flowType === "REISSUE"}>
               </Button>
            </div>
          </li>

          <li className="d-flex  align-items-center justify-content-between"><label>Infants<span> (below 2 yrs)</span></label>
            <div className="incDec d-flex align-items-center justify-content-center">

              <Button size="md" className="minus ezyIcon icon-minus" onClick={handleDecrementInf} variant="link" isDisabled={props.flowType === "REISSUE"} >
               </Button>

              <input type="text" className="number" value={state.infantsCount}></input>
              <Button size="md" className="plus ezyIcon icon-plus" onClick={handleIncrementInf} variant="link" isDisabled={props.flowType === "REISSUE"}>
               </Button>
            </div>
          </li>

        </ul>
        <div className="class d-flex  align-items-center justify-content-between">
          <label>Travel Class</label>
          <div className="selectWidth"><Select
            isSearchable={false}
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={handleChange}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#F0F0F0',
                primary: '#00367A',
              },
            })}
            options={TravellClass}
            value={state.TravellClass}
          /></div>
        </div>
      </div>
    </>
  )
}


export default AddTravellerClass;
