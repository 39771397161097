
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import ApplicableDates from '../../../adminstration/common/ApplicableDates';
import Routes from '../../../adminstration/common/Routes';
import Button from '../../../common/buttons/Button';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { countries, deleteAppcableDate, deleteRoute, saveServiceFee, update } from './operations';
import AirMarkupFareMapForm from './ServiceMap';
import PickList from '../../../common/pickList/PickList';
let airlineMaster = require('../../../masterData/AirlinesMasterData.json');
const JSON = require('circular-json');

// intial state declare
const initialState = {
  routDetails: false, passengerType: false, isActive: true, fromDate: new Date(), toDate: new Date(), date: new Date(),
  countrys: [], countryd: [], srvcName: '', airlineName: [], tType: [], classType: [], commissionble: '', applicable: '',
  messages: [], showMsg: false, applicable_display_data: [], sourceAirport: [], destinationAirport: [], sccode: '', dccode: '',
  sarray: [], darray: [], routes_display_json: [], scname: '', dcname: '', srccntname: '', descntname: '', cnt: '', cty: '',
  routes_save_json: [], classCode: [], class_json: [], comissionOn: [], commissionType: [], comissionValue: '',
  branch_master_data: [], officeId_master_data: [], branch_data: '', officeid_data: [], save_oficeid_json: [], comission_display_data: [],
  comission_List: [], bdes: '', countriesMenu: [], citiesMenu: [], notiMessageShow: false, notiMessage: '',
  notiVarient: '', selsource_air: [], seldestination_air: [], selclass_code: [], selclass_type: [], resetPick1: true,
  resetPick2: false, is_edit: false, commission_id: '', atyp: false, btyp: true, ctyp: false, vtyp: false, rts: [], applDates: [], passType: false, padt: true, pchd: false, pinf: false,
  serviceOn: [], serviceType: [], amountvalue: '', supplier: [], srvcId: null, act: true, airlinesList: [], airlines: null, srvcList: null, enableUpdate: false, className: null,
  validated: false, nameError: false,reset:false, tTypeError: false, supplierError: false, airlinesError: false,edit_applDates:[], flError: false,region_type:"",isItineraryLoading:false,edit_salesChanel:"",edit_ofcid:"",sales_channel:"",ofc_id:"",edit_supplier: []
}


const reducer = (state, action) => {

  switch (action.type) {
    case "clear" :
    return {
      ...state, routDetails: false, passengerType: false, isActive: true, fromDate: new Date(), toDate: new Date(), date: new Date(),
      countrys: [], countryd: [], srvcName: '', airlineName: [], tType: [], classType: [], commissionble: '', applicable: '',
      messages: [], showMsg: false, applicable_display_data: [], sourceAirport: [], destinationAirport: [], sccode: '', dccode: '',
      sarray: [], darray: [], routes_display_json: [], scname: '', dcname: '', srccntname: '', descntname: '', cnt: '', cty: '',
      routes_save_json: [], classCode: [], class_json: [], comissionOn: [], commissionType: [], comissionValue: '',
      branch_master_data: [], officeId_master_data: [], branch_data: '', officeid_data: [], save_oficeid_json: [], comission_display_data: [],
      comission_List: [], bdes: '', countriesMenu: [], citiesMenu: [], notiMessageShow: false, notiMessage: '',
      notiVarient: '', selsource_air: [], seldestination_air: [], selclass_code: [], selclass_type: [], resetPick1: true,
      resetPick2: false, is_edit: false, commission_id: '', atyp: false, btyp: true, ctyp: false, vtyp: false, rts: [], applDates: [], passType: false, padt: true, pchd: false, pinf: false,
      serviceOn: [], serviceType: [], amountvalue: '', supplier: [], srvcId: null, act: true, airlines: null, srvcList: null, enableUpdate: false, className: null,
      validated: false, nameError: false,reset:true,edit_applDates:[],region_type:"",edited_region:"",isItineraryLoading:false,sales_channel:"",ofc_id:""
    }
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This function will create the serviceFee defination and some other details.
function ServiceFee(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [applicableDate, setApplicableDate,] = useState(false);
  const [addMarkUp, setAddMarkUp,] = useState(false);
  const [dataState, setData] = useState({ data: [] });
  const [app, setApp] = useState({ applDates: [] });
  const [fareMap, setFareMaps] = useState({ fareMaps: [] })
  const [fare, setFare] = useState([])
 
  const [context, setContext] = useContext(Context);
  const [routDetails, setRoutDetails] = useState({ rts: [] });
  const [disableButton, setDisableButton] = useState(false);
  const [dataAl, setDataAl] = useState([])
  const setFlowType = (e) => {
    let flowtpes = [];
    flowtpes.push(state.btyp);
    flowtpes.push(state.ctyp);
    flowtpes.push(state.atyp);
    flowtpes.push(state.vtyp);
    // flowtpes.push(vType);
    var ftype = "";
    flowtpes.map((obj) => {
      if (ftype) {
        if (state.btyp && !ftype.includes("B")) {
          ftype = ftype + "|" + "B";
        } else if (state.ctyp && !ftype.includes("C")) {
          ftype = ftype + "|" + "C";
        } else if (state.atyp && !ftype.includes("A")) {
          ftype = ftype + "|" + "A";
        } else if (state.vtyp && !ftype.includes("V")) {
          ftype = ftype + "|" + "V";
        }
      } else {
        if (state.btyp) {
          ftype = "B";
        } else if (state.ctyp) {
          ftype = "C";
        } else if (state.atyp) {
          ftype = "A";
        } else if (state.vtyp) {
          ftype = "V";
        }
      }
    });
    return ftype;
  }
  useEffect(() => {
    if (props.disableBtn) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [props.disableBtn]);
  useEffect(() => {
if(setFlowType() == ""){
  dispatch({type: "flError", payload: true})
}
else{
  dispatch({type: "flError", payload: false})
}
  }, [setFlowType()]);

  const travelType = [{ label: "All", value: "0" }, { label: "One Way", value: "1" },
  { label: "Round Trip", value: "2" }, { label: "Multi City", value: "3" }]

  const suppliers = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6}, {label:"Riya Travel", value: 7}];
  // const suppliers = [{ label: "Amadeus", value: "Amadeus" }]

  const airlineclass = [{ label: "All", value: "All" },
  { label: "Economy", value: "Economy" },
  { label: "Premium Economy", value: "Premium Economy" },
  { label: "Business", value: "Business" },
  { label: "First", value: "First" }]


  useEffect(() => {
    if (props.sendEditData && props.sendEditData !== undefined) {
      convertEditServiceFee(props.sendEditData);
    }
  }, [props.sendEditData]);

  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })

  }, []);

  useEffect(() => {
    //fetch countries data
    countries().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
    });

  }, []);

  /**
   * @description: to remove all multiples in array of objects
   * @author : Azamuddin
   * @date : 03-11-2020
   */

  function getUnique(arr, comp) {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }
  /**
   * @description: Edit data is handeled
   * @author : Azamuddin
   * @date : 03-11-2020
   */
  const convertEditServiceFee = (srvcobj) => {
    var obj = srvcobj.data.res;
    dispatch({ type: 'srvcName', payload: obj.sname })
    dispatch({ type: 'srvcId', payload: obj.id })
    dispatch({ type: 'enableUpdate', payload: true })
    dispatch({ type: 'region_type', payload: obj.region_type })
    dispatch({ type: 'edited_region', payload: obj.region_type })
    dispatch({ type: 'edit_salesChanel', payload: obj.sales_channel })
    if(obj.office_id.includes("All")){
      dispatch({ type: "edit_ofcid", payload: [{ value:"All", label: "All" }] });
    }else{
      dispatch({ type: "edit_ofcid", payload: [{ value:obj.office_id, label: obj.office_label }] });
    }
    if (obj.ftype.includes("B")) {
      dispatch({ type: 'btyp', payload: true })
    }
    else{
      dispatch({ type: 'btyp', payload: false })
    }
    if (obj.ftype.includes("C")) {
      dispatch({ type: 'ctyp', payload: true })
    }
    if (obj.ftype.includes("A")) {
      dispatch({ type: 'atyp', payload: true })
    }
    if (obj.ftype.includes("V")) {
      dispatch({ type: 'vtyp', payload: true })
    }
    if (obj.act === 1) {
      dispatch({ type: 'act', payload: true })
    } else {
      dispatch({ type: 'act', payload: false })
    }

    if (obj.apdate) {
      dispatch({ type: 'applDates', payload: obj.apdate })
      dispatch({type:"edit_applDates",payload:obj.apdate})
    }
    if (obj.rts && obj.rts !== undefined) {
      convertEditRoutes(obj);
    }
    if (obj.al) {
      let acarray = obj.al.split(",");
      let airList = [];
      for (let code of acarray) {
        for (let air of airlineMaster) {
          if (code === air.value) {
            const pauload = {
              value: code,
              label: air.label
            }
            airList.push(pauload)
          }
        }
      }
      dispatch({ type: 'airlineName', payload: getUnique(airList, "value") })
      dispatch({ type: 'airlines', payload: getUnique(airList, "value") })
      setDataAl(getUnique(airList, "value"))
    }
    if (obj.ttype || obj.ttype === 0) {
      let airList = [];
      for (let air of travelType) {
        if (obj.ttype === parseInt(air.value)) {
          const pauload = {
            value: air.value,
            label: air.label
          }
          airList.push(pauload)
        }
      }
      dispatch({ type: 'tType', payload: airList })
    }
    if (obj.supp || obj.supp === 0) {
      let airList = [];
      for (let air of suppliers) {
        if (Number(obj.supp) === air.value) {
          const pauload = {
            value: obj.supp,
            label: air.label
          }
          airList.push(pauload)
        }
      }
      dispatch({ type: 'supplier', payload: airList })
      dispatch({ type: 'edit_supplier', payload: airList })
    }
    if (obj.cls) {
      let airList = [];
      let clsarray = obj.cls.split(",");
      for (let code of clsarray) {
        for (let air of airlineclass) {
          if (code === air.value) {
            const pauload = {
              value: code,
              label: air.label
            }
            airList.push(pauload)
          }
        }
      }
      dispatch({ type: 'classType', payload: airList })
      dispatch({ type: 'className', payload: airList })
    }
    // obj.srvcmap[0].region_type = obj.region_type
    dispatch({ type: 'srvcList', payload: obj.srvcmap })
    window.scrollTo(0, 0);
  }


  const convertEditRoutes = (obj) => {
    let routes = [];
    for (let value of obj.rts) {
      let srcArray = [];
      let destArray = [];
      let airs = [];
      let dests = [];
      if (value.srcair && value.srcair.length !== 0) {
        srcArray = value.srcair.split(",");
        airs = srcArray.filter((q, idx) => srcArray.indexOf(q) === idx)
      }
      if (value.destair && value.destair.length !== 0) {
        destArray = value.destair.split(",");
        dests = destArray.filter((q, idx) => destArray.indexOf(q) === idx)
      }
      if (airs) {
        airs.map((obj) => {
          var route = "";
          if (obj && obj !== undefined) {
            route = {
              "id": value.id,
              "srcct": value.srcct,
              "scname": value.scname + " " + "[" + obj + "]",
              "destct": value.destct,
              "dcname": value.dcname + " " + "[" + dests + "]",
              "srcair": airs,
              "destair": dests,
              "is_db": value.is_db
            }
          }
          routDetails.rts.push(route);
          setRoutDetails({ rts: routDetails.rts });
          let route_json = {
            srcct: value.srcct,
            destct: value.destct,
            srcair: airs,
            destair: dests,
            scname: value.scname,
            dcname: value.dcname,
            is_db: value.is_db
          }
          routes.push(route_json);
        }
        );
        dispatch({ type: 'rts', payload: routes })
      }

    }
  }
  
  const handleChange = (e) => {

    const { id, value } = e.target;
    //serviceName
    if (id === 'serviceFeelName') {

      dispatch({ type: 'srvcName', payload: value });
      dispatch({ type: "nameError", payload: false })


    } //status
    else if (id === 'act') {
      dispatch({ type: 'act', payload: e.target.checked });

    }

    else if (id === 'valueOn') {
      dispatch({ type: 'amountvalue', payload: value });

    }

  }
 const getRegionType = (data)=>{
  dispatch({type: "region_type", payload: data})
 }
  const fareMapData = (data) => {
    dispatch({ type:"reset",payload:false})
    fare.push(data)
    setFareMaps({ fareMaps: [...fareMap.fareMaps, data] });
    dispatch({ type:"editsales",payload:false})
    dispatch({ type:"editRegion",payload:false})

  }
  const handleBookTypeChange = (e) => {
    if (e.target.id === "flb") {
      if (state.btyp) {
        dispatch({ type: 'btyp', payload: false })
      } else {
        dispatch({ type: 'btyp', payload: true })
      }
    }
  }

  const handleCanTypeChange = (e) => {
    if (e.target.id === "flc") {
      if (state.ctyp) {
        dispatch({ type: 'ctyp', payload: false })
      } else {
        dispatch({ type: 'ctyp', payload: true })
      }
    }
  }
  const handleAmendTypeChange = (e) => {
    if (e.target.id === "fla") {
      if (state.atyp) {
        dispatch({ type: 'atyp', payload: false })
      } else {
        dispatch({ type: 'atyp', payload: true })
      }
    }
  }
  const handleVoidTypeChange = (e) => {
    if (e.target.id === "flv") {
      if (state.vtyp) {
        dispatch({ type: 'vtyp', payload: false })
      } else {
        dispatch({ type: 'vtyp', payload: true })
      }
    }
  }
  const handleCancel = (e) => {
    return window.location = "/ezytrip/administration/serviceFee";
  }
  const routesInfo = (route) => {
    dispatch({ type:"reset",payload:false})
    let rtt = []
    rtt.push(route)
    dispatch({ type: 'rts', payload: rtt })
  }
  const applicableDates = (ad) => {
    dispatch({ type:"reset",payload:false})
    state.applDates.push(ad);
    dispatch({ type: 'applDates', payload: state.applDates })
  }

  const updateApplDates = (data) => {
    dispatch({ type:"reset",payload:false})
    dispatch({ type: 'applDates', payload: data })
    // setDates({datesList : [data]})
  }

  const setAir = (data) => {
    if (data.length > 0 && data[0].value === "All") {
      let finalAirs = [];
      data.map((obj) => {
        const al = {
          code: obj.value,
          name: obj.label,
        };
        finalAirs.push(al);
      });
      dispatch({ type: "airlines", payload: finalAirs });
      dispatch({ type: "airlinesList", payload: data });
      dispatch({ type: 'airlineName', payload: data })
      // setValue(data);
    } else if (data.length > 0 && data[0].value !== "All") {
      let finalAirs = [];
      data.map((obj) => {
        const al = {
          code: obj.value,
          name: obj.label,
        };
        finalAirs.push(al);
      });
      dispatch({ type: "airlines", payload: finalAirs });
      dispatch({ type: 'airlineName', payload: data })
      // setValue(data);
      removeAll();
    } else {
      dispatch({ type: "airlines", payload: [] });
      dispatch({ type: 'airlineName', payload: data })

      // setValue(data);
      defaultAirlines();
    }
  };

  const removeAll = (e) => {
    let arr = [];
    for (let val of state.airlinesList) {
      arr.push(val.value);
    }
    if (arr.indexOf("All") > -1) {
      const index = arr.indexOf("All");

      state.airlinesList.splice(index, 1);
    }
  };

  const defaultAirlines = (e) => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" });
    let airlines = getUnique(airs, "value");
    dispatch({ type: "airlinesList", payload: airlines });
  };

  const setClass = (data) => {
    let finalClass = [];
    data.map((obj) => {
      const al = {
        "code": obj.value,
        "name": obj.label
      }
      finalClass.push(al);
    }
    );
    dispatch({ type: 'className', payload: finalClass })
  }
  const callDeleteApp = (id) => {
    deleteAppcableDate(id).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  const routeDelete = (data) => {
    const payload = {
      "src_air": data.srcair,
      "desc_air": data.destair,
      "service_fee_id": state.srvcId
    }
    deleteRoute(payload).then(response => {
      if (response.data.suc) {
        console.log("Delete Date Deleted SUC :");
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deleteRoute function---" + error
      );
    });
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const saveServiceAction = (e) => {
    let srvcnamevalidations = false
    let flowvalidations = false
      if (characterValidation(state.srvcName)) {
        srvcnamevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
      if (state.airlineName && state.airlineName.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'airlinesError', payload: true })
      }
      if (state.tType && state.tType.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'tTypeError', payload: true })
      }
      // if (state.supplier && state.supplier.length === 0) {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   dispatch({ type: 'supplierError', payload: true })
      // }
      if(setFlowType() !== ""){
        flowvalidations = true;
        dispatch({type: "flError", payload: false})
      }
      else{
        dispatch({type: "flError", payload: true})
      }
      if(fare.length > 0) {
      if (srvcnamevalidations && flowvalidations ) {
        if (props.oldDataNames.indexOf(state.srvcName) === -1) {

          var aircodes = "";
          if (state.airlines) {
            state.airlines.map((obj) => {
              if (aircodes) {
                aircodes = aircodes + "," + obj.code;
              } else {
                aircodes = obj.code;
              }
            });
          // }
          var classCodes = ""
          if (state.className) {
            state.className.map((obj) => {
              if (classCodes) {
                classCodes = classCodes + "," + obj.code
              } else {
                classCodes = obj.code
              }
            })
          }
          dispatch({ type: "isItineraryLoading", payload: true });
          let fltype = setFlowType()
          let ofcId="";
          if(context.logindata.utype==="att_user"){
            if(state.supplier[0].label==="Amadeus"){
              ofcId = state.ofc_id[0].value;
            }else{
              ofcId=0;
            }
          }else{
            ofcId="All";
          }
          let rq_json = {
            "sname": state.srvcName,
            "ftype": fltype,
            "utype": context.logindata.utype,
            "ltype": context.logindata.utype,
            "ttype": state.tType && state.tType.length > 0 && state.tType[0].value ? state.tType[0].value : null,
            "cls": classCodes ? classCodes : "All",
            "supp": state.supplier && state.supplier.length > 0 ? state.supplier[0].value : null,
            "al": aircodes,
            "act": state.act,
            "apdate": state.applDates,
            "rts": state.rts,
            "srvcmap": fare,
            "crby": context.logindata.uid,
            "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
            "sales_channel" :context.logindata.utype==="att_user"?state.sales_channel:context.logindata.channel,
            'office_id':ofcId
            // "office_id": null,
          }
          let obj = {
            "srvcreq": rq_json
          }
          saveServiceFee(obj).then(response => {
            if (response && response.data && response.data.res && response.data.suc) {
              dispatch({ type: 'notiMessage', payload: "Service Saved Successfully" })
              dispatch({ type: 'notiVarient', payload: "success" })
              dispatch({ type: 'notiMessageShow', payload: true })
              hidemessage()
              return window.location = "/ezytrip/administration/serviceFee";
            } else if (response.data.res.error && response.data.res.error.message && response.data.res.error.message.includes("Duplicate")) {

              dispatch({ type: 'notiMessage', payload: "Service Name Already Exist" })
              dispatch({ type: 'notiVarient', payload: "danger" })
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: "isItineraryLoading", payload: false });
              hidemessage()
            } else {
              dispatch({ type: 'notiMessage', payload: "Service Fee Not Saved" })
              dispatch({ type: 'notiVarient', payload: "danger" })
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: "isItineraryLoading", payload: false });
              hidemessage()
            }
          })
        } else {
          dispatch({ type: 'notiMessage', payload: "Service Name Already Exist" })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
        }
      }
    } else {
          dispatch({ type: 'notiMessage', payload: "Please Define Atleast One ServiceFee Profile" })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
    }
    } else {
      dispatch({ type: 'notiMessage', payload: "Please add Atleast one Servie Fee Profile *" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }

  }

  const handleReset = () => {
    dispatch({type:"clear",payload:true})
  }
  const updateServiceFee = (e) => {
    e.preventDefault();
    let srvcnamevalidations = false
    let flowvalidations = false
    if (state.srvcName !== "" && state.srvcName && state.airlines && state.airlines.length > 0  && state.tType.length > 0 && state.applDates.length > 0) {
      var aircodes = "";
      dispatch({ type: "isItineraryLoading", payload: true });
      if (state.airlines) {
        state.airlines.map((obj) => {
          if (aircodes) {
            aircodes = aircodes + "," + obj.code !== undefined ? obj.code : obj.value;
          } else {
            aircodes = obj.code !== undefined ? obj.code : obj.value;
          }
        });
      }

      var classCodes = ""
      if (state.className) {
        state.className.map((obj) => {
          if (classCodes) {
            classCodes = classCodes + "," + obj.code
          } else {
            classCodes = obj.code
          }
        })
      }
      let fltype = setFlowType()
      const payload = {
        "srvcreq": {
          "id": state.srvcId,
          "sname": state.srvcName,
          "utype": context.logindata.utype,
          "ftype": fltype,
          "ttype": state.tType && state.tType.length > 0 && state.tType[0].value ? state.tType[0].value : null,
          "cls": classCodes ? classCodes : "All",
          "supp": state.supplier && state.supplier.length > 0 ? state.supplier[0].value : null,
          "al": aircodes,
          "act": state.act,
          "apdate": state.applDates,
          "rts": state.rts,
          "upby": context.logindata.uid,
          "srvcmap": fare,
        }
      }
      if (payload.srvcreq.ftype !== "") {
        flowvalidations = true
        dispatch({ type: "flError", payload: false })
      } else {
        dispatch({ type: "flError", payload: true })
      }
      if (characterValidation(state.srvcName)) {
        srvcnamevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
      if (srvcnamevalidations && flowvalidations) {
        update(payload).then(response => {
          if (response.data.suc) {
            dispatch({ type: 'notiMessage', payload: "ServiceFee updated successfully" })
            dispatch({ type: 'notiVarient', payload: "success" })
            dispatch({ type: 'notiMessageShow', payload: true })
            setTimeout(function () {
           return window.location = "/ezytrip/administration/serviceFee";
            }, 2000);
          } else {
            dispatch({ type: 'notiMessage', payload: "ServiceFee Not updated.." })
            dispatch({ type: 'notiVarient', payload: "danger" })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: "isItineraryLoading", payload: false });
            hidemessage()
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      }
      dispatch({ type: 'showMessage', payload: false })
    }  else {
      if (state.supplier.length > 0) {
        dispatch({ type: 'notiMessage', payload: "Please Enter The Supplier Fields *" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      }
      dispatch({ type: 'notiMessage', payload: "Please Enter All The Mandatory Fields *" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }
  }

  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_channel", payload: salesChanel });
  }
  
  const updateOfcId=(ofc)=>{
    dispatch({ type: "ofc_id", payload: ofc });
  }

  const updateSupplier=(sup)=>{
    dispatch({ type: "supplier", payload: sup });
  }
  
  const updateRoutesData = (data) => {
    dispatch({ type: 'rts', payload: data })
    dispatch({ type:"reset",payload:false})
  }
  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
      <Form.Row>
        <Form.Group as={Col} xs={3} controlId="serviceFeelName">
          <Form.Control type="text" placeholder="Service Fee Name" onChange={handleChange} value={state.srvcName} />
          <Form.Label>Service Fee Name<sup>*</sup></Form.Label>
          {state.nameError && (
            <p className="vError">Please Enter Valid Service Fee Name</p>
          )}
        </Form.Group>
        <Form.Group as={Col} xs={6} className="inputCheck"  >
          <Form.Label>Flow Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
            <Form.Check type="checkbox" id="flb" label="Booking" custom className="ml-0" checked={state.btyp} value={state.btyp} onChange={handleBookTypeChange} />
            <Form.Check type="checkbox" id="flc" label="Cancellation" custom className="ml-3" checked={state.ctyp} value={state.ctyp} onChange={handleCanTypeChange} />
            <Form.Check type="checkbox" id="fla" label="Amendment" custom className="ml-3" checked={state.atyp} value={state.atyp} onChange={handleAmendTypeChange} />
            <Form.Check type="checkbox" id="flv" label="Void" custom className="ml-3" checked={state.vtyp} value={state.vtyp} onChange={handleVoidTypeChange} />

          </div>
          {state.flError && (
            <p className="vError">Please Select FlowType</p>
          )}
        </Form.Group>

      </Form.Row>

      {/* Routes */}
      <Routes configType={"ServiceFee"} reset={state.reset} sendRoutesData={routesInfo} updateData={updateRoutesData} routesData={routDetails.rts} sendRouteDelData={routeDelete} disabledBtn={props.disableBtn} />


      <Form.Row>
      <Form.Group as={Col} className="picklist" xs={6} >
                {state.airlinesList && state.airlinesList.length !== 0 &&
                  <PickList leftPaneLabel="Available Airlines" rightPaneLabel="Selected Airlines" ofcsData={dataAl} type={state.compType}  dataList={state.airlinesList} sendData={setAir} />
                }
                <Form.Label>Airlines</Form.Label>
              </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={2} className="selectplugin" controlId="selectedValue">
          <Select
            options={travelType}
            values={state.tType}
            onChange={value =>{ dispatch({ type: 'tType', payload: value });
            dispatch({ type: 'tTypeError', payload: false })}
          }
          />
           {state.tTypeError && (
                  <p className="vError">Please Select Travel Type</p>
                )}
          <Form.Label>Travel Type <sup>*</sup></Form.Label>
        </Form.Group>
        {/* <Form.Group as={Col} xs={2} className="selectplugin" controlId="supplier">
          <Select
            options={suppliers}
            values={state.supplier}
            onChange={(value) => (dispatch({ type: 'supplier', payload: value }), dispatch({ type: 'supplierError', payload: false }))}
          />
            {state.supplierError && (
                  <p className="vError">Please Select Supplier</p>
                )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
        </Form.Group> */}
        <Form.Group as={Col} xs={2} className="selectplugin" controlId="icalInput">
          <Select
            options={airlineclass}
            multi
            values={state.classType}
            onChange={(value) => (dispatch({ type: 'classType', payload: value }), setClass(value))}
          />
          <Form.Label>Class</Form.Label>
        </Form.Group >

      </Form.Row>
      {/* Applicabe Dates */}

      <ApplicableDates configType={"ServiceFee"} sendAppDates={applicableDates} updateData={updateApplDates} applDatesData={state.edit_applDates} sendApplicableId={callDeleteApp} disabledBtn={props.disableBtn} reset={state.reset}/>

      <AirMarkupFareMapForm configType={"ServiceFee"} sendFareData={fareMapData} fareData={state.srvcList} markupId={state.srvcId} reset={state.reset} disabledBtn={props.disableBtn} getRegionType={getRegionType} 
      edit_region={state.edited_region}
      sendChannel={updateSalesChanel}
      sendOfcId={updateOfcId}
      edit_salesChanel={state.edit_salesChanel}
      edit_ofcid={state.edit_ofcid}
      edit_supplier={state.edit_supplier}
      sendSup={updateSupplier}
      />


      <div>
        <div className={"switchPlus buttonGrop mt-4 pb-0" + (state.enableUpdate ? 'text-right' : 'switchPlus')}>
        <Form.Group as={Col} className="topSwitch">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              defaultChecked
              checked={state.act} value={state.act} onChange={handleChange}
              />
          </Form.Group>
          <>
       
            {state.enableUpdate && !props.disableBtn &&
              // <div className="text-right buttonGrop">
      
                <Button
                  size="xs"
                  onClick={updateServiceFee}
                  variant="outline-primary"
                  type="submit"
                  isDisabled={props.disableBtn}
                >Update</Button>
              // </div>
            }
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleCancel}
          > Back</Button>
          {!state.enableUpdate &&
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleReset}
            > Reset</Button>
          }
          {!state.enableUpdate &&
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={saveServiceAction}
            >Save</Button>
          }
          </>
        </div>
      </div>
    </>
  )
}
export default ServiceFee