
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useReducer } from 'react';
 import { Col, Row} from "react-bootstrap";

 /**
  * Initial State Declaration
  */
 const initialState = {
     source: '', destination: '', duration: '', travel_type: '', departure_date: '', gdspnr: "", notiMessageShow: false, notiMessage: '', notiVarient: ''
 };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 /**
  * @description:This function will Display the the Segment details
  * @author: Lakshmi
  * @param {*}
  * @function Pax details
  * @date : 18-09-2020
  */
 function PaxPdf(props) {
     const [state, dispatch] = useReducer(reducer, initialState)

     const bagLabelConstruction = (tag) => {
         let tagChanged = ""
         if(tag && tag.includes("total")) {
            let splitted_tag = tag.split("total")
            tagChanged = splitted_tag[0]
         } else if(tag) {
             let splitted_tag = tag.split("-")
             tagChanged = splitted_tag[0] +" - "+splitted_tag[1]
         }

         return tagChanged;
     }
     return (
         <>
             <div className="pdffare">
                 <h3 className="pdfSubTitl">
                     {props.flowType} Details
                 </h3>
                 <div className="header">
                     <Row>
                         <Col>Traveller </Col>
                         <Col>Type</Col>
                         {props.paxList[0].supplier === "4" &&
                         <Col>PNR</Col>
                         }
                         {(props.paxList[0].supplier == undefined || props.paxList[0].supplier == "0") ? (

                             <Col>Ticket#</Col>
                         ) : (
                             <>
                             <Col>Baggage</Col>
                             <Col>Meals</Col>
                             </>
                         )}
                          {props.paxList[0].supplier && Number(props.paxList[0].supplier) !== 0 && (
                          <>
                           <Col>Seat</Col>
                          </>
                         )}
                         <Col>Status</Col>
                     </Row>
                 </div>

                 {props.paxList.map((pax, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                             <Row>
                                 <Col>{pax.pax_name}</Col>
                                 <Col >
                                     {pax.pax_type === "ADT" &&
                                         "Adult"
                                     }
                                     {pax.pax_type === "CHD" &&
                                         "Child"
                                     }
                                     {pax.pax_type === "INF" &&
                                         "Infant"
                                     }
                                 </Col>
                                 {props.paxList[0].supplier === "4" &&
                                    <Col>{pax.split_pnr!==null?pax.split_pnr:pax.gds_pnr}</Col>
                                 }
                                 {(pax.supplier == undefined || pax.supplier == "0") ? (
                                     <Col>{pax.tkt_no}</Col>
                                 ) : (
                                     <>
                                     <Col>{pax.bag_lable ? bagLabelConstruction(pax.bag_lable) : "N/A"}</Col>
                                     <Col>{pax.meal_lable ? pax.meal_lable : "N/A"}</Col>
                                     </>
                                 )}
                                    {props.paxList[0].supplier && Number(props.paxList[0].supplier) !== 0 && (
                                  <>
                                  <Col>{pax.seg_seat_no ?   pax.seg_seat_no :"N/A"  }</Col>
                                  </>
                                )}
                                 {/* <Col xs="2">{pax.seg_seat_no ?   pax.seg_seat_no :"N/A"  }</Col> */}
                                 <Col className={pax.sts === 'Confirmed' ? "text-success" : 'text-primary'}>{pax.sts}</Col>
                             </Row>
                         </div>
                     </React.Fragment>
                 ))}

             </div>
         </>

     )
 }


 export default PaxPdf;
