import React, { Component } from "react";
import { Link } from 'react-router-dom';
/**
 * @description:This function will add breadcrumb in respected page.
 * @author: Pravas
 * @param {*}
 * @function Breadcrumb
 * @date : 01-09-2020
 */

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

const clickOn = () => {
  return window.location = window.location.pathname
}
function BreadcrumbFun(props){
    const { state } = props || {};
  return (
    console.log("windows Location",window.location.pathname),
    <div className="container">
        <div className="breadcrumb hderSec align-items-center">
            <Link className="d-block backArrow" to='/ezytrip/dashboard'>
            Home
            </Link>
            <span className="ezyIcon icon-arrowDown"></span>
            <Link className="d-block backArrow" to={window.location.pathname} onClick={clickOn}>
            {state.activePage}
            </Link>
            </div>
      </div>
  );
};

class Breadcrumb extends Component {
	constructor(props) {

        super(props);
        console.log('props', props)
		this.state = {
            activePage:props.activePage
		};

    }

    render() {
		return (
			<BreadcrumbFun
				state={this.state}

			/>
		);
	}

}

export default Breadcrumb;
