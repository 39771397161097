
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Image1 from '../../../assets/images/hotelImg/hotelImg1.png';
import Image2 from '../../../assets/images/hotelImg/hotelImg2.png';
import Image3 from '../../../assets/images/hotelImg/hotelImg3.png';
import Image4 from '../../../assets/images/hotelImg/hotelImg4.png';
function PopularCities(props) {
    const [state, setState] = useState({})


    return (
        <>
            <div className="bestSellingPKG hoteldashBord">
            <Row>
                <Col>
                    <h3>
                    Popular Cities
                        </h3>
                    <p>World’s best tourist destinations</p>
                </Col>
            </Row>
                <Row>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image2}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                        <div className="d-flex align-items-center"><span className="placeName">ROME</span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image1}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                        <div className="d-flex align-items-center"><span className="placeName">DOHA</span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image3}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                        <div className="d-flex align-items-center"><span className="placeName">Dubai</span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image4}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                        <div className="d-flex align-items-center"><span className="placeName">Al Khobar</span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>


                </Row>
            </div>
        </>
    )
}


export default PopularCities;
