
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import { isNotNull } from '../../../commonUtils/validators';
import RegionType from "../../common/RegionType";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { stalist, talist } from '../../utils';
import { CustomContext } from './CustomMain';
import { branches, creation, deletion, update } from './operation';

/**
 * @description:This function will create Custome details for Company
 * @author: Lakshmi
 * @param {*}
 * @function Custom details
 * @date : 07-09-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  ctyp: "TA", id: 0, name: "", type: "", branchMenu: [], taMenu: [], staMenu: [], enableSta: false, res: [], noRecords: false, ista: false, taid: 1, eid: 0,ta_id:0,ta_name:"",
  selbranch: [], selTa: [], selSta: [], selType: [], selMandatory: [], showMessage: false, loadCustom: false, enableAtt: true, enableTa: true, enableSta: false, enablearea: false, disable: false, custList: [{ flbl: "", fval: "", ftpe: [], flen: "", fmand: [{label: "Yes",value:"Yes"}], rm_type: [] }]
  , brError: false, typeError: false, manError: false, flblError: false, defError: false, lenError: false, disabled: false, is_checked: false, rm_type: [], is_disabled: false, notiMessageShow: false,edit_id:0, showUpdate: true,region_type:"",region_type_edit:"",isItineraryLoading: false
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, id: 0, name: "", type: "", enableSta: false, noRecords: false, is_checked:false,
        showMessage: false, loadCustom: false, loadEdit: false, custList: [{ flbl: "", fval: "", ftpe: [], flen: "", fmand: [], rm_type: [] }], brError: false, typeError: false, manError: false, flblError: false, defError: false, lenError: false, notiMessageShow: false,edit_id:0, isItineraryLoading: false
      };
    case 'editCustom':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};


/**
 * @description:This function will creaet Custom fields and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Custom fields
 */
function CustomFields(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, setValue] = useState([]);
  const [attDropDown, setAttDropDown] = useState(false)
  const [context, setContext] = useContext(Context);
  const [enableView, setEnableView] = useState(false)
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableSTA, setEnableSTA] = useState(false)
  const customObj = useContext(CustomContext);
  const [validated, setValidated] = useState(false);
  const [disable, setDisable] = useState(false)
  const [resetRegion, setResetRegion] = useState(false)
  const [enable, setEnable] = useState(false)

  const mandatory = [{ value: "Yes", label: "Yes" },
  { value: "No", label: "No" }]
  const types = [{ value: "A_N", label: "Alpha numeric" }, { value: "T_A", label: "Text Area" },
  { value: "D_D", label: "Drop Down" }, { value: "D_U", label: "Doc upload" }]

  const remarks_type = [{ value: "RM", label: "RM" }, { value: "RIR", label: "RIR" },
  { value: "RII", label: "RII" }, { value: "RIF", label: "RIF" }, { value: "RC", label: "RC" },
  { value: "RIZ", label: "RIZ" }]




  useEffect(() => {
    if (state.ista) {
      loadStaList(state.taid);
      dispatch({ type: "ctyp", payload: "STA" });
      dispatch({ type: "type", payload: "Company" });
      dispatch({ type: "enableSta", payload: true });
      dispatch({ type: "tacomp", payload: "Att Travel Agency" });
    } else {
      // if (context.logindata.uid !== undefined) {
      //   const payload = { type: context.logindata.utype, userId: context.logindata.uid,is_admin:context.logindata.is_admin }
      //   branches(payload).then((response) => {
      //     console.log("Load all branch response : " + JSON.stringify(response));
      //     if (response.data.suc) {
      //       dispatch({ type: 'branchMenu', payload: response.data.res })
      //     }
      //   });
      //   // talist().then((res) => {
      //   //   dispatch({ type: 'taMenu', payload: res })
      //   // });
      // }
    }
  }, [context.logindata]);

  useEffect(() => {
    if (customObj !== undefined && customObj.res !== undefined) {
      editCustomField(customObj);
    }
  }, [customObj !== undefined]);
  useEffect(()=>{
    if(props.enbleUpdate){
      dispatch({ type: 'showUpdate', payload: false })      }
  }, [props.enbleUpdate])



  // This function will update the State
  const handleChange = evt => {
    try {
      const { id, value } = evt.target;
      dispatch({ type: id, payload: value });
      // dispatch({type:'selBranch',payload:[]})
      // dispatch({type:'selTa',payload:[]})
      // dispatch({type:'selSta',payload:[]})
      if (id === "att") {
        dispatch({ type: 'selTa', payload: [] })
        dispatch({ type: 'selSta', payload: [] })
        dispatch({ type: 'selBranch', payload: [] })
        dispatch({ type: "ctyp", payload: "ATT" });
        dispatch({ type: "enableAtt", payload: true });
        dispatch({ type: "enableTa", payload: false });
        dispatch({ type: "enableSta", payload: false });
      } else if (id === "ta") {
        dispatch({ type: 'selSta', payload: [] })
        dispatch({ type: 'selBranch', payload: [] })
        dispatch({ type: "ctyp", payload: "TA" });
        dispatch({ type: "enableAtt", payload: false });
        dispatch({ type: "enableTa", payload: true });
        dispatch({ type: "enableSta", payload: false });
      } else if (id === "sta") {
        dispatch({ type: 'selBranch', payload: [] })
        dispatch({ type: "ctyp", payload: "STA" });
        dispatch({ type: "enableAtt", payload: false });
        dispatch({ type: "enableTa", payload: false });
        dispatch({ type: "enableSta", payload: true });
      }
      else if (id === 'remarks') {
        setDisable(false)
        dispatch({ type: 'is_checked', payload: evt.target.checked });
      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }
  //This function will update the state Vlaue
  const handleChangeCustom = idx => evt => {
    try {
      let newCustoms = state.custList.map((obj, sidx) => {
        if (idx !== sidx) return obj;
        else {
          if (evt.target.id == "flbl") {
            dispatch({ type: "flblError", payload: false })
          }
          if (evt.target.id == "flen") {
            dispatch({ type: "lenError", payload: false })
          }
          if (evt.target.id == "fval") {
            dispatch({ type: "defError", payload: false })
          }
          return { ...obj, [evt.target.id]: evt.target.value };
        }
      });
      dispatch({ type: "custList", payload: newCustoms });
    } catch (err) {
      log.error('Exception occured in handleChangeCustom function---' + err);
    }
  }
  //Adding Branch or Company Name
  const setTypeValue = (data, type) => {
    dispatch({ type: "selSta", payload: [] });
    if (data && data.length > 0) {
      dispatch({ type: "type", payload: type });
      // dispatch({ type: "selbranch", payload: data });
      if (state.ctyp !== "STA") {
        dispatch({ type: "id", payload: data[0].value });
        dispatch({ type: "name", payload: data[0].label });
        dispatch({ type: "enableSta", payload: false });
        dispatch({ type: "selTa", payload: data });
      } else {
        dispatch({ type: "selTa", payload: data });
        dispatch({ type: "ta_id", payload: data[0].value });
        dispatch({ type: "ta_name", payload: data[0].label });
        setEnableSTA(true)
        loadStaList(data[0].value);
        dispatch({ type: "enableSta", payload: true });
      }
    }
  }
  //Adding Branch or Company Name
  const handleChangeType = (idx, num) => (e) => {
    try {
      dispatch({ type: "is_disabled", payload: false });
      let newCustoms = state.custList.map((obj, sidx) => {
        if (idx !== sidx) return obj;
        else if (num == 1) {
          dispatch({ type: "typeError", payload: false })
          dispatch({ type: "selType", payload: e });
          if (e.value === "D_D") {
            dispatch({ type: "enablearea", payload: true });
            dispatch({ type: "disable", payload: true });
          } else if (e.value === "D_U") {
            dispatch({ type: "disable", payload: true });
            dispatch({ type: "enablearea", payload: false });
          } else {
            dispatch({ type: "enablearea", payload: false });
            dispatch({ type: "disable", payload: false });
          }
          return { ...obj, ftpe: e };
        } else if (num == 2) {
          dispatch({ type: "manError", payload: false })
          dispatch({ type: "selMandatory", payload: e });
          return { ...obj, fmand: e };
        } else if (num == 4) {
          setDisable(true)
          dispatch({ type: "selType", payload: [{ value: "T_A", label: "Text Area" }] });
          dispatch({ type: "is_disabled", payload: true });
          return { ...obj, rm_type: e, ftpe: [{ value: "T_A", label: "Text Area" }] };
        }
      });
      dispatch({ type: "custList", payload: newCustoms });
    } catch (err) {
      log.error('Exception occured in handleChangeType function---' + err);
    }
  }

  //Load all STA Under TA
  const loadStaList = (id) => {
    stalist(id).then((res) => {
      dispatch({ type: 'staMenu', payload: res });
    })
  }
  //Set Value for STA
  const setStaValue = (data) => {
    dispatch({ type: "type", payload: "Company" });
    dispatch({ type: "id", payload: data.length!==0? data[0].value:"" });
    dispatch({ type: "name", payload:  data.length!==0?data[0].label:"" });
    dispatch({ type: "selSta", payload: data });
  }
  //redirect to admin page
  const resetData = (e) => {
    window.location = "/ezytrip/administration/customFields";
  }
  // Remove Custom details while Creating
  const handleRemovePerson = idx => () => {
    dispatch({ type: 'custList', payload: state.custList.filter((s, sidx) => idx !== sidx) });
  };
  // Add Custom details
  const handleAddPerson = () => {
    dispatch({ type: 'custList', payload: state.custList.concat([{ flbl: "", fval: "", ftpe: [], flen: "", fmand: [{label: "Yes",value:"Yes"}], rm_type: [] }]) })
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  //Custom Creation
  const customCreation = (event) => {
    // if (value.length === 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   dispatch({ type: 'brError', payload: true })
    // }
    // if (state.selMandatory.length === 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   dispatch({ type: 'manError', payload: true })
    // }
    // if (state.selType.length === 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   dispatch({ type: 'typeError', payload: true })
    // }
    // if (state.custList) {
    //   for (let each of state.custList) {
    //     if (each.flbl == "") {
    //       event.preventDefault();
    //       event.stopPropagation();
    //       dispatch({ type: 'flblError', payload: true })
    //     }
    //     if (each.flen == "") {
    //       event.preventDefault();
    //       event.stopPropagation();
    //       dispatch({ type: 'lenError', payload: true })
    //     }
    //     if (each.fval == "") {
    //       event.preventDefault();
    //       event.stopPropagation();
    //       dispatch({ type: 'defError', payload: true })
    //     }
    //   }

    // }
    if (state.ctyp !== "") {
      try{
      if (validateCust(state.custList)) {
        dispatch({type:"isItineraryLoading", payload: true}) 
        const saveObj = {
          "ctpe": state.ctyp,
          "tpe": state.type,
          "id": state.id,
          "name": state.name,
          "is_remarks": state.is_checked,
          "crby": context.logindata.uid,
          "custdls": state.custList,
          "ta_id": state.ta_id !== 0 ? state.ta_id : null,
          "ta_name": state.ta_name ? state.ta_name : null,
          "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
          "branch_name":state.selbranch[0].label,
          "branch_id":state.selbranch[0].value
        };
        creation(saveObj).then((resp) => {
          if (resp.suc) {
            dispatch({ type: 'clear', payload: true })
            dispatch({ type: 'ctyp', payload: state.ctyp })
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Custom details saved successfully' });
            dispatch({ type: 'notiVarient', payload: 'success' });
            hidemessage()
            return window.location = "/ezytrip/administration/customFields";
          } else {
            dispatch({ type: 'isItineraryLoading', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Custom details has not saved..' });
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
          }
        });
      }
     } 
     catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
    //  else {
    //     dispatch({ type: 'notiMessageShow', payload: true });
    //     dispatch({ type: 'notiMessage', payload: 'Custom Label is Required..' });
    //     dispatch({ type: 'notiVarient', payload: 'danger' });
    //     hidemessage()
    //   }
    } else if (state.ctyp === "") {
      dispatch({ type: 'isItineraryLoading', payload: false });
      dispatch({ type: 'notiMessageShow', payload: true });
      dispatch({ type: 'notiMessage', payload: 'Company Type is required' });
      dispatch({ type: 'notiVarient', payload: 'danger' });
      hidemessage()
    }
  }
  //Validation for Custom Label
  const validateCust = (data) => {
    for (let item of data) {
      if (item.flbl == "") {
        dispatch({ type: "notiMessage", payload: "Custom Label is Required.." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
        return false;
      }
      if (item.ftpe.length == 0 || item.ftpe[0] == {}) {
        dispatch({ type: "notiMessage", payload: "Please select Type." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
        return false;
      }
      if (item.fmand.length == 0 || item.fmand[0] == {}) {
        dispatch({ type: "notiMessage", payload: "Please select Is Mandatory." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
        return false;     
      }
      if(state.region_type===""){
        dispatch({ type: "notiMessage", payload: "Please select Region Type." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
        return false;
      }
      if(state.selbranch.length===0){
        dispatch({ type: "notiMessage", payload: "Please select Branch." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
        hidemessage();
        return false;
      }
    }
    if(state.selTa.length===0){
      dispatch({ type: "notiMessage", payload: "Please select TA Company." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false;
    }
    if(state.enableSta && state.selSta.length===0){
      dispatch({ type: "notiMessage", payload: "Please select STA Company." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false;
    }
    return true
  }

  const resetVal = () => {
    dispatch({ type: "clear", payload: true })
    setDisable(false)
    setResetRegion(true)
    dispatch({ type: "selTa", payload: [] })
    dispatch({ type: "selbranch", payload: [] })
  }
  //Update Categeory
  const updateCustom = (e) => {
    dispatch({ type: 'showMessage', payload: false });
    dispatch({ type: 'loadCustom', payload: true });
    //add condition to check mandatory Fields
    try{
    if (validateCust(state.custList)) {
      dispatch({ type: 'isItineraryLoading', payload: true });
    update(state).then(response => {
      if (response.suc) {
        let updtedCustom = state.res.map(
          (obj, sidx) => {
            if (obj.id !== state.id) return obj;
            return {
              ...obj,
              ctyp: state.ctyp,
              type: state.type,
              id: state.id,
              name: state.name,
              custList: state.custList
            };
          }
        );
        dispatch({ type: 'res', payload: updtedCustom })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Custom Fileds updated successfully' })
        dispatch({ type: 'notiVarient', payload: 'success' });
        return window.location = "/ezytrip/administration/customFields";
      } else {
        dispatch({ type: 'isItineraryLoading', payload: false });
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Custom Fileds has not updated..' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
      }
    })
  }
}
  catch (e) {
    log.error('Exception occured in handleChange function---' + e);
  }
  }
  // Edit Custom
  const editCustomField = (editObj) => {
    if (editObj.res.ctpe !== "ATT") {
      setAttDropDown(true)
    } else {
      setAttDropDown(false)
    }
    let assignedCustdls = []
    dispatch({type:"edit_id",payload: editObj.res.eid})
    dispatch({type: "type",payload:editObj.res.tpe})
    if (editObj.res.custdls) {
      let cus = editObj.res.custdls
      for (let val of cus) {
        if (val.rm_type) {
          setDisable(true)
        }
        let type = {}
        let demand = {}
        let rm_type = {}
        if (val.ftpe) {
          for (let t of types) {
            if (t.value == val.ftpe) {
              type = {
                "label": t.label,
                "value": t.value
              }
            }
          }
        }

        if (val.fmand) {
          if (val.fmand.toUpperCase() == "YES") {
            demand = {
              "label": "Yes",
              "value": "Yes"
            }
          } else {
            demand = {
              "label": "No",
              "value": "No"
            }
          }
        }
        if(val.rm_type) {
          for (let t of remarks_type) {
            if (t.value == val.rm_type) {
              rm_type = {
                "label": t.label,
                "value": t.value
              }
            }
          }
        }
        let obj = {
          cust_id: val.cust_id,
          flbl: val.flbl,
          fval: val.fval,
          ftpe: [type],
          flen: val.flen,
          fmand: [demand],
          rm_type: [rm_type]
        }
        assignedCustdls.push(obj)
      }
    }
    Object.assign(state.custList, assignedCustdls);
    let ctype=editObj.res.ctpe!=="STA"?"TA":"STA";
    dispatch({ type: "ctyp", payload: ctype });
    if (editObj.res.is_remarks) {
      if (editObj.res.is_remarks === 0) {
        dispatch({ type: 'is_checked', payload: false })
      } else {
        dispatch({ type: 'is_checked', payload: true })
      }
    }

    dispatch({ type: 'disabled', payload: true })
    dispatch({ type: 'region_type_edit', payload: editObj.res.region_type })
    if (ctype === "TA") {
      dispatch({ type: "enableAtt", payload: false });
      dispatch({ type: "enableTa", payload: true });
      dispatch({ type: "enableSta", payload: false });
    } else if (ctype === "STA") {
      dispatch({ type: "enableAtt", payload: false });
      dispatch({ type: "enableTa", payload: false });
      dispatch({ type: "enableSta", payload: true });
    }
    dispatch({ type: 'enbaleUpdate', payload: true })
    dispatch({ type: 'editCustom', payload: editObj.res })
    // dispatch({ type: 'custList', payload: editObj.res.custdls })

    if (ctype === "TA" && editObj.res.tpe === "Company") {
      dispatch({ type: 'selTa', payload: [{ "value": editObj.res.id, "label": editObj.res.name }] })
    } else if (ctype === "STA" && editObj.res.tpe === "Company") {
      dispatch({ type: 'selTa', payload: [{ "value": editObj.res.ta_id, "label": editObj.res.ta_name }] })
      dispatch({ type: 'selSta', payload: [{ "value": editObj.res.id, "label": editObj.res.name }] })
      setEnableSTA(true)
      dispatch({ type: "enableSta", payload: true });
    }
    dispatch({ type: "selbranch", payload: [{value:editObj.res.branch_id,label:editObj.res.branch_name}] });
  }

  //Confirmation dialog popup to delete the Custom
  const confirmDlg = obj => evt => {
    confirmBranchDlg(obj, 'Custom', function () {
      deleteCustom(obj);
    });
  };
  // Delete Custom
  const deleteCustom = (obj) => {
    const deleteBy = { delby: 'user@gmail.com' }
    deletion(obj, deleteBy).then((deleteRes) => {
      var array = [...state.custList]; // make a separate copy of the array
      var index = array.indexOf(obj)
      if (index !== -1) {
        array.splice(index, 1);
        dispatch({ type: 'custList', payload: array })
      }
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Deleted successfully' })
      dispatch({ type: 'notiVarient', payload: 'success' });
    });
  }
  const handleRegionChange = (e) => {
    setResetRegion(false)
    dispatch({ type: 'selTa', payload: [] }); 
    dispatch({ type: 'selbranch', payload: [] });
    dispatch({ type: 'branchMenu', payload: e });
    dispatch({ type: 'selTa', payload: [] });
    dispatch({ type: 'selbranch', payload: [] });
    setEnable(true)
  }

  const handleBranchChange = (e) => {
    dispatch({ type: 'selTa', payload: []});
    if(e!==undefined && e.length!==0){
    dispatch({ type: 'selbranch', payload: e});
    AttCommonSerivce.getCompanyList(e.length!==0?e[0].value:"")
    .then((response) => {
      if (response.data.suc) {
          dispatch({ type: 'taMenu', payload: response.data.res });
        }
      })
      .catch(function (error) {
        log.error("Exception occured in getCompanyList function---" + error);
      });
    }
  };

  const updateRegion=(region)=>{
    setResetRegion(false)
    dispatch({ type: 'region_type', payload: region });
  }

  return (
    <>
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>

       {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
      {state.ista &&
        <> <b><span>{state.tacomp}</span></b><br /></>
      }
      <Form.Row>
        {!state.ista &&
          <Form.Group as={Col} xs={3} controlId="ctyp" className="inputCheck">
            <div className="d-flex align-items-center">
              {/* <Form.Check type="radio" label="ATT" id="att" checked={state.enableAtt} disabled={state.disabled} custom value={state.ctyp} onChange={handleChange} custom name="classes" className="ml-0" /> */}
              <Form.Check type="radio" label="TA" id="ta" checked={state.enableTa} value={state.ctyp} disabled={state.disabled} onChange={handleChange} custom name="classes" className="ml-3" />
              <Form.Check type="radio" label="STA" id="sta" checked={state.enableSta} value={state.ctyp} disabled={state.disabled} onChange={handleChange} custom name="classes" className="ml-3" />
            </div>
            <Form.Label>Company Type</Form.Label>
          </Form.Group>
        }
      </Form.Row>
      <div className="contact d-flex">
        <div className="slnData">
          <Form.Row>
            
            {state.ista &&
              <Form.Group as={Col} xs={3} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label="STA" id="sta" checked={!attDropDown} disabled={state.disabled} value={state.ctyp} onChange={handleChange} custom name="classes" className="ml-3" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }

             {!state.ista &&
              <><RegionType sendResponse={(e)=>{handleRegionChange(e)}} regionType={updateRegion} regionUpdate={state.region_type_edit} reset={resetRegion}/>
              <Form.Group as={Col} xs="3" className={"selectplugin " + (state.brError && "error")} controlId="cn">
                <Select
                  options={state.branchMenu}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled || state.enbaleUpdate}
                  values={state.selbranch}
                  onChange={handleBranchChange}
                />
                <Form.Label>Branch<sup>*</sup></Form.Label>
                {state.brError && (
                  <p className="vError">Please Select Branch</p>
                )}
              </Form.Group></>}

            {(state.enableTa || state.enableSta) &&
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={state.taMenu}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled || state.enbaleUpdate}
                  values={state.selTa}
                  onChange={(value) => (setValue(value), setTypeValue(value, "Company"))}
                />
                <Form.Label>Ta Company<sup>*</sup></Form.Label>
              </Form.Group>
            }

            {/* Remarks check box when we select TA it will be enable */}
            {isNotNull(state.ctyp) && state.ctyp === 'TA' && (

              <Form.Group as={Col} xs={2} controlId="icalInput" className="d-flex align-items-center">
                <Form.Check type="checkbox" id="remarks" label="Remarks"
                  checked={state.is_checked} onChange={handleChange} custom className="ml-0" />
              </Form.Group>
            )}

            {state.enableSta &&
            
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={state.staMenu}
                  id="class"
                  isSearchable={false}
                  values={state.selSta}
                  disabled={state.disabled || state.enbaleUpdate}
                  onChange={(value) => (setValue(value), setStaValue(value))}
                />
                <Form.Label>STA Company<sup>*</sup></Form.Label>
              </Form.Group>

            }
          </Form.Row>


          {state.custList.map((cust, idx) => (
            <Form.Row>
              <React.Fragment key={idx}>
                <Form.Group as={Col} xs={1} controlId="sno">
                  <Form.Control type="text" placeholder="Enter Label" value={idx + 1} disabled="true" onChange={handleChangeCustom(idx)} />
                  <Form.Label>S no</Form.Label>
                </Form.Group>

                {/* Remarks type drop down */}
                {state.is_checked && state.ctyp === 'TA' && (

                  <Form.Group as={Col} xs="2" className={"selectplugin " + (state.typeError && "error")} controlId="rm_type">
                    <Select
                      options={remarks_type}
                      id="class"
                      values={customObj !== undefined ? (cust && cust.rm_type ? cust.rm_type : state.rm_type) : cust.rm_type}
                      // value={cust.rm_type ? cust.rm_type : state.rm_type }
                      onChange={handleChangeType(idx, 4)}
                    />
                    <Form.Label>Remark Type</Form.Label>

                  </Form.Group>

                )}



                <Form.Group as={Col} xs={2} controlId="flbl">
                  <Form.Control type="text" placeholder="Enter Label" value={cust.flbl} onChange={handleChangeCustom(idx)} />
                  <Form.Label>Label<sup>*</sup></Form.Label>
                </Form.Group>

                <Form.Group as={Col} xs="2" className={"selectplugin " + (state.typeError && "error")} controlId="ftpe">
                  <Select
                    options={types}
                    id="class"
                    isSearchable={false}
                    disabled={disable ? true : false}

                    values={customObj !== undefined ? (cust && cust.ftpe ? cust.ftpe : state.selType) :cust.ftpe}
                    // value={state.selType}
                    onChange={handleChangeType(idx, 1)}
                  />
                  <Form.Label>Type<sup>*</sup></Form.Label>
                  {/* {state.typeError && (
                    <p className="vError">Please Select Type</p>
                  )} */}
                </Form.Group>
                <Form.Group as={Col} xs={2} controlId="flen">
                  <Form.Control type="number" min={0} placeholder="Enter Number" disabled={state.disable} value={cust.flen} onChange={handleChangeCustom(idx)} />
                  <Form.Label>Length</Form.Label>
                  {/* {state.lenError && (
                    <p className="vError">Please Enter Length</p>
                  )} */}
                </Form.Group>
                <Form.Group as={Col} xs={2} controlId="fval">
                  {!state.enablearea &&
                    <Form.Control type="text" placeholder="Enter Value" value={cust.fval} disabled={state.disable} onChange={handleChangeCustom(idx)} />
                  }
                  {state.enablearea &&
                    <Form.Control as="textarea" rows="1" placeholder="Enter Value" value={cust.fval} onChange={handleChangeCustom(idx)} />
                  }
                  <Form.Label>Default Value</Form.Label>
                  {/* {state.defError && (
                    <p className="vError">Please Enter Default Value</p>
                  )} */}
                </Form.Group>

                <Form.Group as={Col} xs="2" className={"selectplugin " + (state.manError && "error")} controlId="cn">
                  <Select
                    options={mandatory}
                    id="class"
                    isSearchable={false}
                    values={customObj !== undefined ? (cust && cust.fmand ? cust.fmand : state.selMandatory) : cust.fmand}
                    // value={state.selMandatory}
                    onChange={handleChangeType(idx, 2)}
                  />
                  <Form.Label>Is Mandatory?<sup>*</sup></Form.Label>
                  {/* {state.manError && (
                    <p className="vError">Please Select Is Mandatory</p>
                  )} */}
                </Form.Group>
                {(idx > 0) ? (
                  <Col className="d-flex dtlgroup justify-content-end">
                    {!state.enbaleUpdate &&
                      <Button
                        size="xs"
                        variant="link"
                        className="dlt iconBtn ezyIcon icon-delete"
                        onClick={handleRemovePerson(idx)}
                        //loading={dataInfo.loadEdit}
                        type="submit">
                      </Button>
                    }
                    {state.enbaleUpdate &&
                      <Button
                        size="xs"
                        variant="link"
                        className="dlt iconBtn ezyIcon icon-delete"
                        onClick={confirmDlg(cust)}
                        //loading={dataInfo.loadEdit}
                        type="submit">
                      </Button>
                    }
                  </Col>
                ) : ''}

              </React.Fragment>
            </Form.Row>
          ))}
        </div>
        <div className="d-flex"><Button
          size="xs"
          variant="link"
          className="iconBtn add ezyIcon icon-plus align-self-end mb-4-3"
          onClick={handleAddPerson}
          type="submit"
        ></Button></div>
      </div>
      <div className="text-right buttonGrop pb-0">
        <Button
          size="xs"
          variant="outline-secondary"
          onClick={resetData}
          isDisabled={enableView}
          type="submit"
        >Back</Button>
        <Button
          size="xs"
          variant="outline-secondary"
          onClick={resetVal}
          loading={state.loadCategory}
          type="submit"
        > Reset</Button>
        {!state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('C_F_C') && (
          <Button
            size="xs"
            variant="outline-primary"
            onClick={customCreation}
            loading={state.loadCategory}
            type="submit"
          > Add</Button>
        )}

        {state.enbaleUpdate &&  state.showUpdate &&(
          <Button
            size="xs"
            variant="outline-primary"
            onClick={updateCustom}
            loading={state.loadCategory}
            isDisabled={enableView}
            type="submit"
          > Update</Button>
        )}
      </div>


    </>
  )


}

export default CustomFields