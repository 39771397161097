
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";
import TripCost from '../../bookingFlows/flight/review/TripCost';
import AirlinesMemo from '../../common/airlines';
import Button from '../../common/buttons/Button';
import DateUtils from '../../commonUtils/DateUtils';
import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
import { retrivePrice, saveSegDetails } from '../summary/operations';

/**
 * @description:This function will load the Import PNR un ticket details
 * @author: Rambabu
 * @param {*}
 * @function ImportPNRunticketDetails
 * @date : 03-12-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  all: false, selSegment: false, notiMessage: '', notiMessageShow: false, notiVarient: '', priceResp: "", showSegments: [], adt: 0, chd: 0, inf: 0,grandTotal: 0,
  showimportbtn: false,itenaryShowbox : false,indexVal:0
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ImportPNRunticketDetails(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selSegments, setSelSegments] = useState({ segments: [] })
  const [response, setResponse] = useState(props.pnrResponse.res)
  const [mappings, setMappings] = useState(props.pnrResponse.res.mappings)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [context, setContext] = useContext(Context);



  useEffect(() => {

    if (response !== undefined) {
      for(let eachPnr of response) {
        if(eachPnr.origns && eachPnr.origns.length > 0) {
      for (let segment of eachPnr.origns[0].srcorigns[0].seg) {
        if (segment.st.toUpperCase() === 'PENDING') {
          segment.selected = false;
          if(!state.itenaryShowbox) {
          state.itenaryShowbox = true
          }
        }
      }
      }
    }
    }
  }, [response]);


  useEffect(() => {

    if (isNotNull(response)) {
      for(let eachPnr of response) {
        if(eachPnr.origns && eachPnr.origns.length > 0) {
      for (let s in eachPnr.origns[0].srcorigns[0].seg) {
        let segment_data = eachPnr.origns[0].srcorigns[0].seg[s];
        if (segment_data.st.toUpperCase() === 'PENDING') {
          dispatch({ type: 'showimportbtn', payload: true })
          break;
        }
      }
      }
    }

    }
  }, [])



  //set grand total getting from tripcost props
const setGrandTotal = (gt) => {
  if(gt > 0 && state.grandTotal === 0) {
    dispatch({ type: "grandTotal" , payload: gt}) //grand total
  }
}


  //Updating seleted segment
  const handleChangeSegment = (obj, idx,selected_pnr) => (e) => {
    var selectedSeg = false;

    for(let eachPnr of response) {
    //removing of different pnrResponse

      if(eachPnr.origns) {
    for (let segment of eachPnr.origns[0].srcorigns[0].seg) {
      if (!segment.selected) {
        if (segment.ref === obj.ref && segment.st.toUpperCase() === "PENDING" && eachPnr.pnr == selected_pnr) {
          segment.selected = true;
          dispatch({ type: 'selSegment', payload: true })
        }
      } else {
        if (segment.ref === obj.ref && segment.st.toUpperCase() === "PENDING") {
          segment.selected = false;
        }

      }
      if(eachPnr.pnr !== selected_pnr) {
        segment.selected = false;
      }
    }
    //Checking segement seleted or Not
    for (let segment of eachPnr.origns[0].srcorigns[0].seg) {
      if (segment.selected) {
        selectedSeg = true;
        break;
      }
    }
  }
  }
    if (!selectedSeg) {
      dispatch({ type: 'selSegment', payload: false })
    }

    //dispatch({ type: 'all', payload: false })
  }

  //Calling Price service for seleted Segment(s)
  const showPriceItinarary = () => {
    if (state.all || state.selSegment) {
      setIsLoadingMore(true);
      let segments = [];
      let selectedSegments = [];
      let indexVal = 0
      if (state.all) {
        for(let index in response) {
          let eachPnr = response[index]
          indexVal = index
        for (let seg of eachPnr.origns[0].srcorigns[0].seg) {
          if (seg.st.toUpperCase() === "PENDING") {
            segments.push({ "da": seg.da, "ar": seg.ar });
            selectedSegments.push(seg);
          }
        }
      }
        setSelSegments({ segments: selectedSegments })
        dispatch({ type: 'showSegments', payload: segments })
      } else {
        for(let index in response) {
          let eachPnr = response[index]
          if(eachPnr.origns) {
        for (let seg of eachPnr.origns[0].srcorigns[0].seg) {
          if (seg.st.toUpperCase() === "PENDING" && seg.selected) {
            indexVal = index
            segments.push({ "da": seg.da, "ar": seg.ar });
            selectedSegments.push(seg);
          }
        }
      }
      }
        setSelSegments({ segments: selectedSegments })
        dispatch({ type: 'showSegments', payload: segments })
      }
      dispatch({type:"indexVal",payload:indexVal})
      var priceReq = createPriceReq(selectedSegments,indexVal);
      retrivePrice(priceReq).then((resp) => {
        if (resp.suc) {
          dispatch({ type: 'priceResp', payload: resp.res })
          setShow(true)
          setIsLoadingMore(false);
        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'We are unable to retrive price details' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          setIsLoadingMore(false);
        }
      })

    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one segment and proceed' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      setIsLoadingMore(false);
    }

  }


  //createing pricing request
  const createPriceReq = (segments,i) => {
    let segms = [];
    if (segments && segments !== undefined) {
      segms = segments;
    } else {
      segms = selSegments.segments;
    }
    const payload = {
      "cha": "B2B",
      "adt": response[i].adt,
      "chd": response[i].chd,
      "inf": response[i].inf,
      "cur": response[i].cur,
      "cid": context.logindata.cid,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "sty": "normal",
      "tt": "3",
      "tid": "",
      "office_id": response[i].office_id,
      "region_type": context.logindata.region_type,
      "req_cur": context.logindata.cur,
      "origns": [{
        "srcorigns": [
          {
            "tid": response[i].origns[0].srcorigns[0].tid,
            "office_id": response[i].office_id,
            "ofcid": response[i].origns[0].srcorigns[0].ofcid,
            "sdur": response[i].origns[0].srcorigns[0].sdur,
            "re": response[i].origns[0].srcorigns[0].re,
            "stop": response[i].origns[0].srcorigns[0].stop,
            "sup": response[i].origns[0].srcorigns[0].sup,
            "cl": response[i].origns[0].srcorigns[0].cl,
            "ft": response[i].origns[0].srcorigns[0].ft,
            "tktair": response[i].origns[0].srcorigns[0].tktair,
            "tecst": response[i].origns[0].srcorigns[0].tecst,
            "pnr": response[i].pnr,
            "seg": segms
          }
        ]
      }]
    }
    return payload;
  }

  //create Book request
  const createBookReq = (tid) => {
    const payload = {
      "adt": response[state.indexVal].adt,
      "chd": response[state.indexVal].chd,
      "inf": response[state.indexVal].inf,
      "cur": response[state.indexVal].cur,
      "cid": context.logindata.cid,
      "sty": "normal",
      "tt": "3",
      "tid": tid!==""?tid:"",
      "flowType": "IMPORT",
      "trans_id": response[state.indexVal].trans_id,
      "bkref": (response[state.indexVal].bkref !== undefined) ? response[state.indexVal].bkref : "",
      "psngrs": response[state.indexVal].psngrs,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "region_type": context.logindata.region_type,
      "req_cur": context.logindata.cur,
      "origns": [{
        "srcorigns": [
          {
            "tid": response[state.indexVal].origns[0].srcorigns[0].tid,
            "ref": response[state.indexVal].origns[0].srcorigns[0].ref,
            "ofcid": response[state.indexVal].origns[0].srcorigns[0].ofcid,
            "sdur": response[state.indexVal].origns[0].srcorigns[0].sdur,
            "re": response[state.indexVal].origns[0].srcorigns[0].re,
            "stop": response[state.indexVal].origns[0].srcorigns[0].stop,
            "sup": response[state.indexVal].origns[0].srcorigns[0].sup,
            "cl": response[state.indexVal].origns[0].srcorigns[0].cl,
            "ft": response[state.indexVal].origns[0].srcorigns[0].ft,
            "tktair": response[state.indexVal].origns[0].srcorigns[0].tktair,
            "tecst": response[state.indexVal].origns[0].srcorigns[0].tecst,
            "pnr": response[state.indexVal].pnr,
            "seg": selSegments.segments,
            "bgd": state.priceResp.origns[0].srcorigns[0].bgd,
            "pr": state.priceResp.origns[0].srcorigns[0].pr,
            "paxpr": state.priceResp.origns[0].srcorigns[0].paxpr,
            "serviceFee": state.priceResp.service_fee
          }
        ]
      }],
      "mappings": response[state.indexVal].mappings,
      "serviceFee": state.priceResp.service_fee
    }
    return payload;
  }

  //continue booking and redirecting to review page
  const continueBooking = () => {
    var req = createBookReq("");
    saveSegDetails(req).then((resp) => {
      if (resp.suc) {
        var bookReq = createBookReq(resp.res.tid);
        history.push({
          pathname: '/ezytrip/bookings/flight/itinerary',
          state: {
            req: bookReq
          }
        });
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went Wrong.Please try Once again' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
      }
    })
  }
  const cancel = () => {
    return window.location = "/ezytrip/dashboard";
  }


  //get airport data
  function getAirportdata(code, val,index) {
    if (isNotNull(props.pnrResponse.res[index].mappings) && isNotNull(code)) {

      for (let a in props.pnrResponse.res[index].mappings) {

        let air_data = props.pnrResponse.res[index].mappings[a];

        if (isNotNull(air_data.airportCode)) {

          if (air_data.airportCode === code && val === 'c') {

            return air_data.cityName;

          } else if (air_data.airportCode === code && val === 'a') {

            return air_data.airportName;
          }
        }
      }
    }
  }

  return (

    <>

      {/* Displaying messages */}

      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>



      <>
        {state.itenaryShowbox &&
        <div className="resultSection boxShadow unticketed mb-4">
          <h5 className="title d-flex justify-content-between">Itinerary-Unticketed</h5>
          {isArrayNotEmpty(response) && (response.map((eachPnr,i) => (
          <React.Fragment key={i}>
          {eachPnr.origns && isArrayNotEmpty(eachPnr.origns) && isArrayNotEmpty(eachPnr.origns[0].srcorigns[0].seg) && (eachPnr.origns[0].srcorigns[0].seg.map((sege, index) => (

            sege.st.toUpperCase() === "PENDING" && (
              <div className="flightRoutInfo mb-3">

                <Row className="align-items-center rs-row">

                  <Col xs={3} className="rs-rTypeDate">
                    <Form.Check type="checkbox" label="" custom id={eachPnr.pnr + index} checked={sege.selected} onChange={handleChangeSegment(sege, index,eachPnr.pnr)} />

                    <div className="airLogo d-flex align-items-center">
                      <img
                        src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + sege.ma + '.png'}
                        className="logo"
                        alt="AirLine"
                      />
                      <div className="flightNo d-flex flex-column ml-2">
                        <strong><AirlinesMemo code={sege.ma} /></strong>
                        <span>{sege.ma} {sege.fn}</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={7} className="rs-FromTo">
                    <Row className="align-items-center">
                      <Col className="d-flex flex-column">
                        <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, DD MMM, YYYY")}</small></strong>
                        <span>{getAirportdata(sege.da, 'c',i)} ({sege.da}),</span>
                        <span>{getAirportdata(sege.da, 'a',i)}</span>
                      </Col>
                      <Col className="p-0" xs="1"><span className="ezyIcon icon-flight"></span></Col>
                      <Col className="d-flex flex-column">
                        <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, DD MMM, YYYY")}</small></strong>
                        <span>{getAirportdata(sege.ar, 'c',i)} ({sege.ar}),</span>
                        <span>{getAirportdata(sege.ar, 'a',i)}</span>
                      </Col>
                    </Row>

                    <span>

                    </span>
                  </Col>
                  <Col xs={2} className="rs-rTypeDate">
                  {/*   <p><span>Class : </span>{sege.cabin_class} </p> */}
                    <p><span>GDS PNR : </span> {eachPnr.pnr}</p>
                  </Col>

                </Row>


              </div>
            )

          )
          )
          )}
          </React.Fragment>
        )
        )
        )
        }

        </div>
        }


        {/* If any segment penind that time only buttons will show */}
        {state.showimportbtn && (

          <div className="text-right buttonGrop mt-4">
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={cancel}
            > Cancel</Button>
            <Button
              size="xs"
              variant="outline-primary"
              onClick={showPriceItinarary}
              type="submit"
            >Price Itinerary</Button>

          </div>


        )}
      </>

      {isLoadingMore && <div class="loaderPageBackdrop">
        <div class="loaderPage">
          <div class="loaderIcon"></div>
        </div>
      </div>}
      {show &&
        <Modal
          show={show}
          onHide={handleClose}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <h5>Flight Details</h5>
            <div className="dstn">
              {state.showSegments !== 0 && state.showSegments.map((seg, sidx) => (
                <span className="inline-flex">{seg.da} <em className="ezyIcon icon-oneside"></em> {seg.ar}</span>
              ))}
            </div>
            <h5 className="mt-3">Fare Details</h5>
            {state.priceResp !== undefined &&
              <TripCost seatPrice={0} tripCost={state.priceResp.origns[0]} srequest={state.priceResp} cur={state.priceResp.cur} flowtype="import" serviceFee={state.priceResp.service_fee} grandTotal={setGrandTotal}/>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancel
          </Button>
            <Button
              size="xs"
              variant="outline-primary"
              onClick={continueBooking}
              type="submit"
            >Continue</Button>
          </Modal.Footer>
        </Modal>
      }


    </>
  )
}
export default ImportPNRunticketDetails