
import React, { useState,useEffect } from 'react';
import { Col, Row, Modal } from "react-bootstrap";
import ItemsCarousel from 'react-items-carousel';
import {getAllPackages} from '../../dashBoard/operations';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slideone from "../../../assets/images/GeorgiaGDEzyTripWebsiteBanner.jpg";
import Slidetwo from "../../../assets/images/ThailandEzyTripWebsiteBanner.jpg";
import Slidethree from "../../../assets/images/slidethree.png";
import PackageDetails from '../../dashBoard/PackageDetails';

function EzytripUpdateandPromotion(props) {


    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      autoplay: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };



    return (
        <>
<section className="ezytripPromotions innerTrending dashBordLayout ezytrip pb-5" id="trendingPackage">
<div className="promtionNew">

<div className="trendingItemscont">
<h2 className="titleLine"><span><span>EZYTRIP</span> UPDATES & PROMOTIONS</span></h2>

</div>
        <Slider {...settings}>
          <div>
          <div className='hoverEff'><img src={Slideone} /></div>
          </div>
          <div>
          <div className='hoverEff'><img src={Slidetwo} /></div>
          </div>
          {/* <div>
          <img src={Slidethree} />
          </div> */}
          {/* <div>
          <img src={Slidetwo} />
          </div> */}

        </Slider>
        </div>
      </section>
        </>


    )
}


export default EzytripUpdateandPromotion;
