import React, { useReducer } from 'react';
import { Alert, Form, Modal, Col, Row } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../common/buttons/Button';
import Processing from '../../../common/processingPopUp/Processing';
import DateUtils from '../../../commonUtils/DateUtils';
import { fireBookReq } from '../review/operations';
import {fireHotelBook, fireHoldHotel , checkHoldBookings } from '../../../bookingFlows/hotel/payment/opearations';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";

const initialState = {
  id: 0, terms: false, showTerms: false, notiMessage: '', notiMessageShow: false, notiVarient: '', isLoading: false, pleasewait: false,onHoldPermitFailed:false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function HoldPayment(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  //Coomon function for Flight and Hotel
    const processOnholdBooking =()=>{
      window.sessionStorage.removeItem(CHECK_BOOKING);
      window.sessionStorage.removeItem(BOOKING_REF_NO);
    if(props.product!==undefined) {
    if(props.product==="Flight"){
      callBookReqest();
    }else if(props.product==="Hotel"){
      const payload={
        "type":props.bdata.butype==="ta_user"?"TA":"STA",
        "company_id":props.bdata.cid,
        "pcompany_id":props.bdata.butype==="ta_user"?props.bdata.cid:props.bdata.pcid,
        "region_type":props.bdata.region_type,
        "cur":props.bdata.req_cur,
      }
      checkHoldBookings(payload).then((resp) => {
        if (resp.suc && resp.res!==undefined && resp.res.is_available){
          confirmBooking();
        }else{
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "You have exceeded maximum On hold bookings allowed, please manage your Hold Bookings done or contact support." })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        }
        });
    }
    }
  }


  //=====================================
  // Calling to Booking Service to get the PNR
  //=====================================
  const callBookReqest = () => {
    if (state.terms) {
      dispatch({ type: 'isLoading', payload: true })
      dispatch({ type: 'pleasewait', payload: true })
      const payload = {
        "tid": props.bdata.tid,
        "bookId": props.bdata.bookingId,
        "onhold": "yes",
        "cha": "B2C",
        "cur": props.bdata.cur,
        "sty": props.bdata.sty,
        "tt": props.bdata.tt,
        "bid": props.bdata.bid,
        "butype": props.bdata.butype,
        "pcid": props.bdata.pcid,
        "service_fee": props.bdata.service_fee,
        "ofcId": props.bdata.ofcId,
        "plb": props.bdata.plb,
        "ttl": props.bdata.ttl,
        "oldseg": props.bdata.oldseg,
        "tkts": props.bdata.tkts,
        "trans_id": props.bdata.trans_id,
        "tkt_user_type": props.bdata.utype,
        "tkt_user_id": props.bdata.tkt_user_id,
        "region_type":props.bdata.region_type,
        "req_cur":props.bdata.req_currency,
        "pay": [{
          "ptype": "",
          "amt": 0,
          "ccode": "",
          "psta": "Pending",
          "crbal": 0,
          "pgauthid": "",
          "pgtid": "",
          "pgstc": "",
          "csur": "",
          "ctype": "",
          "cno": "",
          "cname": "",
          "cexp": "",
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": props.bdata.price,
          "ccode": props.bdata.cur,
          "crbal": props.bdata.creditBal,
          "psta": "Pending"
        }]
      }
      fireBookReq(payload, props.bdata.bookingType).then((response) => {
        if (response.bookRs !== undefined && response.bookRs.status !== "bookfailed") {
          const payload = {
            "response": response,
            "mappings": props.bdata.mappings
          }
          history.push({
            pathname: '/ezytrip/bookings/flight/confirmation',
            state: {
              req: payload
            }
          });
        } else {
          dispatch({ type: 'isLoading', payload: false })
          dispatch({ type: 'pleasewait', payload: false })
          props.sendBook("Book");
        }
        dispatch({ type: 'isLoading', payload: false })
        dispatch({ type: 'pleasewait', payload: false })
      });
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: "Please accept terms and conditions and proceed" })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 3000);
  }

  //Calling Hotel Confirm Booking for CL
  const confirmBooking = () => {
    if (state.terms){
      dispatch({ type: 'isLoading', payload: true })
      dispatch({ type: 'pleasewait', payload: true })
      let holdReq = {"bookId": props.bdata.bookingId,"hid":props.bdata.hid,"cur":props.bdata.cur,"region_type":props.bdata.region_type}
      fireHoldHotel(holdReq).then((response) => {
        if (response.suc && response.data.st=="success"){
          let bookReq = bookRequest();
          fireHotelBook(bookReq).then((bresp) => {
            if (bresp.suc && bresp.data!==undefined && bresp.data.st==="Confirmed"){
            }
            history.push({
              pathname: '/ezytrip/bookings/hotel/confirmation',
              state: {
                req: bresp
              }
            });
          });
        }else{
          dispatch({ type: 'isLoading', payload: false })
          dispatch({ type: 'pleasewait', payload: false })
          dispatch({ type: 'showFailed', payload: false })
          dispatch({type:"onHoldPermitFailed",payload:true})

      }
      });
    }else{
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: "Please accept terms and conditions and proceed" })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

  //Creating Book request
  const bookRequest = () => {
      const payload = {
        "hid": props.bdata.hid,
        "bookId": props.bdata.bookingId,
        "cha": "B2C",
        "cur": props.bdata.req_cur,
        "butype": props.bdata.butype,
        "ofcId": props.bdata.ofcId,
        "bktype": "On Hold",
        "uid" : props.bdata.userId,
        "pay": [{
          "ptype": "",
          "amt": 0,
          "ccode": "",
          "psta": "Pending",
          "crbal": 0,
          "pgauthid": "",
          "pgtid": "",
          "pgstc": "",
          "csur": "",
          "ctype": "",
          "cno": "",
          "cname": "",
          "cexp": "",
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": props.bdata.price,
          "ccode": props.bdata.cur,
          "crbal": props.bdata.creditBal,
          "psta": "Pending"
        }]
      }
      return payload;
  }



  const handleChange = (e) => {
    const { id, value } = e.target;
    if (state.terms) {
      dispatch({ type: 'terms', payload: false })
    } else {
      dispatch({ type: 'terms', payload: true })
    }
  }

  const handleClose = () => {
    dispatch({ type: 'showTerms', payload: false })
    dispatch({ type: 'onHoldPermitFailed',payload:false})
  }
/**
 * @description : Whenever onHold for hotel fails then popup to close the page action.
 * @author:Azamuddin
 * @date: 16-07-2021
 * @action : window closes up
 */

const closePageOnholdFlow = () => {
  return window.close();
}

  return (
    <>
      <div>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <Modal
          show={state.showTerms}
          onHide={handleClose}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}>
          <Modal.Body>
            <h5>Terms and Condtions</h5>
            <div className="dstn">
              Terms and Condtions
        </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleClose}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>

      {state.onHoldPermitFailed &&
      <Modal
              show={state.onHoldPermitFailed}
              onHide={handleClose}
              // onClick={handleClose}
              className="importPNRMdl "
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <div className="popUplayout center">
                  <div className="icons warning"></div>
                  <h4 className="mt-3 mb-3">Unable to proceed with this hotel</h4>
                  <div className="message">
                  Please check for other Hotel
                  </div>
                </div>

              </Modal.Header>

              <Modal.Footer className="justify-content-center">
                <Button
                  size="xs"
                  variant="primary"
                  className="pl-4 pr-4 mb-2"
                  onClick={closePageOnholdFlow}
                  type="submit"
                >Close</Button>




              </Modal.Footer>
            </Modal>
}
        {props.product==="Flight" &&
        <>
        <Alert variant="info">
          Note: This booking is not guaranted unless it is ticketed, the ticketing time line is subject change as per the airline
        </Alert>
         <p className="onholdTimeInfo ezyIcon icon-info">  Please note, a ticketing time limit is <strong className="ml-1"> {DateUtils.prettyDate(props.bdata.ttl, "ddd, MMM DD, YYYY HH:mm:ss")} {props.bdata.region_type==="India"?"IST":"QST"}</strong> </p>
        </>
        }
        {props.product==="Hotel" &&
        <>
        <Alert variant="info">
          Note: You can hold this booking till {DateUtils.prettyDate(props.bdata.freCanclDate, "ddd, MMM DD, YYYY HH:mm:ss")} {props.bdata.region_type==="India"?"IST":"QST"}
        </Alert>
         <p className="hotelOnhold ezyIcon icon-info"> To confirm please pay {props.bdata.req_cur} <FormateCurrency value={props.bdata.price} /> before {DateUtils.prettyDate(props.bdata.freCanclDate, "ddd, MMM DD, YYYY HH:mm:ss")}. Failed to pay will lead to cancel the booking automatically.
         </p>
        </>
        }
           <Row className="htlConfirm">

                  <Col xs="9">
                  {props.product==="Hotel" ? <div className="d-flex tc"><Form.Check
          type="checkbox"
          id="tc"
          label=""
          value={state.terms}
          onChange={handleChange}
          custom
        />
    <label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>:

        <div className="d-flex mt-2 tc"><Form.Check
          type="checkbox"
          id="tc"
          label=""
          value={state.terms}
          onChange={handleChange}
          custom
        />
          <label className="tAcCommen">I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>}
          </Col>
          <Col>
          <div className="d-flex justify-content-end">
          <Button variant="primary" size="lg"
            onClick={processOnholdBooking}
            className={"progress-bar continue d-flex onholdbtn  " + (state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
            disabled={state.isLoading && state.pleasewait ? true : false} >
            {state.isLoading ? "Processing..." : "ON HOLD"}

          </Button>
          {state.pleasewait ? <Processing onhold={props.bdata.onhold} /> : ""}
        </div>

          </Col>
          </Row>
</div>
    </>
  )
}
export default HoldPayment