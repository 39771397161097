
import React, { useState, useEffect, useReducer, useContext } from 'react';
import log from "loglevel";
import Button from '../../common/buttons/Button'
import { useHistory } from 'react-router-dom';
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import AirMarkupFareMapForm from './fareMap'
import {
  emailValidation,
  mobileNumberValidation,
  characterValidation,
  characterNumberValidation,
} from "../../commonUtils/FormValidations";
import ApplicableDates from '../../adminstration/common/ApplicableDates';
import HotelSelected from '../../adminstration/common/HotelSelected'
import {creation,update,deleteapplicableConfig,deleteAppcableDate} from './operations'
import { Context } from "../../../../App";
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css';
let airlineMaster = require('../../masterData/AirlinesMasterData.json');

const initialState = {
  mn: "", str: [], ftype: "", supp: [], atyp: false, btyp: true, ctyp: false, className: null, countrySelected: [], citySelected: [], applDates: [], hotels: [], hotelSelected: [],
  include: true, star_rating: [],applied_hotels:[],markupId:0,enableUpdate:false,edited_applied_hotels:[],markupId:0, supplierError: false, markupError: false,reset:false,edit_applDates:[],region_details:"",edit_region:[],isItineraryLoading:false,sales_chanel:"",isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,edit_sales_channel:""
}

const reducer = (state, action) => {

  switch (action.type) {
    case "clear":
      return {...state,mn: "", str: [], ftype: "", supp: [], atyp: false, btyp: true, ctyp: false, className: null, countrySelected: [], citySelected: [], applDates: [], hotels: [], hotelSelected: [],
      include: true, star_rating: [],applied_hotels:[],markupId:0,enableUpdate:false,edited_applied_hotels:[],markupId:0, supplierError: false, markupError: false,reset:true,edit_applDates:[],region_details:"",edit_region:[]}
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This function will create the air markup defination and some other details.
function Markup(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [routDetails, setRoutDetails,] = useState(false);
  const [applicableDate, setApplicableDate,] = useState(false);
  const [passengerType, setPassengerType] = useState(false);
  const [addMarkUp, setAddMarkUp,] = useState(false);
  const [fareMap, setFareMaps] = useState({ fareMaps: [] });
  const [act, setAct] = useState(true);


  const StarRating = [{ label: "Five Star", value: "5" },
  { label: "Four Star", value: "4" },
  { label: "Three Star", value: "3" },
  { label: "Two Star", value: "2" },
  { label: "One Star", value: "1" },
  { label: "No Star", value: "0" }]


  const [hotelList, setHotelList] = useState([
    { value: 1, label: 'Hotel 1' },
    { value: 2, label: 'Hotel 2' },
    { value: 3, label: 'Hotel 3' }
  ]);

  const applicableOn = [{ label: "Booking Period", value: 1 },
  { label: "Checkin Period", value: 2 }]


  const supplier = [{ label: "Expedia", value: "Expedia" }, { label: "Otilla", value: "Otilla" }]
  useEffect(() => {
    if(props.createCall) {
      dispatch({type:"clear",payload:true})
      setTimeout(() => dispatch({type:"clear",payload:false}),1000)
    }
  },[props.createCall])
  useEffect(() => {
    if (props.sendEditData && props.sendEditData !== undefined) {
      convertEditMarkup(props.sendEditData);
    }
  }, [props.sendEditData]);

  const convertEditMarkup = (editData) => {
    var obj = editData.data.res;
    dispatch({ type: "mn", payload: obj.mn });
    dispatch({ type: "markupId", payload: obj.id });
    dispatch({ type: "enableUpdate", payload: true });
    dispatch({type:'markupId',payload: obj.id})
    dispatch({type:"region_selected",payload:obj.region_type})
    dispatch({type:"edit_sales_channel",payload:obj.sales_channel})
    let regional_data = []
    let temp_regional_data = obj.region_type ? obj.region_type.split(",").filter(function(s) {
      let payload = {
        "label":s,
        "value": s
      }
      regional_data.push(payload)
    }) : []
    dispatch({type:"edit_region",payload:regional_data})

    // supplier set to data
    if (obj.hs) {
      let suppList = [];
      for (let air of supplier) {
        if (obj.hs === air.value) {
          const pauload = {
            value: obj.hs,
            label: air.label,
          };
          suppList.push(pauload);
        }
      }
      dispatch({ type: "supp", payload: suppList });
    }

    // flow type
    if (obj.ftype.includes("B")) {
      dispatch({type:"btyp",payload:true})
    } else {
      dispatch({type:"btyp",payload:false})
    }
    if (obj.ftype.includes("C")) {
      dispatch({type:"ctyp",payload:true})
    }
    if (obj.ftype.includes("A")) {
      dispatch({type:"atyp",payload:true})
    }

    if (obj.act === 1) {
      setAct(true);
    } else {
      setAct(false);
    }
    //star ratings
    if (obj.str) {
      let acarray = obj.str.split(",");
      let strList = [];
      for (let code of acarray) {
        for (let air of StarRating) {
          if (code === air.value) {
            const pauload = {
              value: code,
              label: air.label,
            };
            strList.push(pauload);
          }
        }
      }
      dispatch({ type: "str", payload: getUnique(strList, "value") });
    }

    if(obj.app_htls && obj.app_htls.length > 0) {
      dispatch({type:"edited_applied_hotels",payload:obj.app_htls})
      dispatch({type:"applied_hotels",payload:obj.app_htls})
    }
    if(obj.apdate && obj.apdate.length > 0) {
      let arr =[]
      for(let val of obj.apdate) {
        let obj = {
            "id": val.id,
            "mkp_id": val.mkp_id,
            "config_type": "Markup",
            "tty": val.tty,
            "fm":  moment(val.fm,"DD-MMM-YYYY").format("YYYY-MM-DD"),
            "too":  moment(val.too,"DD-MMM-YYYY").format("YYYY-MM-DD")
        }
        arr.push(obj)
      }
      dispatch({type:"applDates",payload:arr})
      dispatch({type:"edit_applDates",payload:arr})
    }
    dispatch({ type: "fareList", payload: obj.hfmap });
    window.scrollTo(0, 0);
  }

  /**
   * @description: to remove all multiples in array of objects
   * @author : Azamuddin
   * @date : 03-11-2020
   */

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }
  const openroutBtnClick = () => {
    setRoutDetails(true)
  }
  const openApplicableDate = () => {
    setApplicableDate(true)
  }
  useEffect(() => {
    // setState({ airlinesData: airlineMaster })
  }, []);

  const addedMarkup = () => {
    if (addMarkUp) {
      setAddMarkUp(false)
    } else {
      setAddMarkUp(true)
    }
  }
  const perTicket = () => {
    setPassengerType(true)
  }
  const perBooking = () => {
    setPassengerType(false)
  }
  const handleChange = (e) => {
    const { id, value } = e.target
    // setState(prevState => ({
    //   ...prevState,
    //   [id]: value
    // }))
    if (e.target.id === "icalInput") {
      if (state.isActive) {
        // setState({ isActive: false })
      } else {
        // setState({ isActive: true })
      }
    }
    if (id == "mn") {
      dispatch({ type: "mn", payload: value });
      dispatch({ type: "markupError", payload: false})
    }

    if (id === "act") {
      if (act) {
        setAct(false);
      } else {
        setAct(true);
      }
    }
  }


  const handleReset = (e) => {
    state.reset = false
    dispatch({ type: "clear", payload: true });
    setAir([]);
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateMarkupConfig = () => {
    if (state.mn === "") {
      dispatch({ type: "notiMessage", payload: "Please enter Markup name" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    } else if (fareMap.fareMaps.length === 0) {
      dispatch({
        type: "notiMessage",
        payload: "Please define atleast one Markup",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    } else if (state.applDates.length === 0) {
      dispatch({
        type: "notiMessage",
        payload: "Please Enter All The Mandatory Fields *",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }
    return true;
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handleSaveMarkup = (e) => {
    e.preventDefault();
    let namevalidations = false;
    if (state.supp && state.supp.length === 0) {
      e.preventDefault();
      e.stopPropagation();
      dispatch({ type: 'supplierError', payload: true })
    }
    if (validateMarkupConfig()) {
      if (characterValidation(state.mn)) {

        namevalidations = true;
        dispatch({ type: "nameError", payload: false });
      } else {
        dispatch({ type: "nameError", payload: true });
      }
      if (namevalidations) {
        // if (props.oldDataNames && props.oldDataNames.length > 0 && props.oldDataNames.indexOf(state.mn) === -1) {
        var ftype = setFlowType();
        var starcodes = "";
        if (state.star_rating) {
          state.star_rating.map((obj) => {
            if (starcodes) {
              starcodes = starcodes + "," + obj.code;
            } else {
              starcodes = obj.code;
            }
          });
        }
        const payload = {
          mrkreq: {
            mn: state.mn,
            ftype: ftype,
            act: act,
            ttype: state.ttype,
            crby: context.logindata.uid,
            utype: context.logindata.utype,
            str: starcodes ? starcodes : "All",
            app_htls: state.applied_hotels,
            apdate: state.applDates,
            rts: state.rts,
            hfmap: fareMap.fareMaps,
            hs: state.supp.length > 0 ? state.supp[0].value : null,
            region_type:context.logindata.utype==="att_user"?state.region_details:context.logindata.region_type,
            "isDeviceAll":state.isDeviceAll,
        "isB2cWeb":state.isDeviceAll?true:state.isB2cWeb,
        "isB2cApp":state.isDeviceAll?true:state.isB2cApp,
        "isMWeb":state.isDeviceAll?true:state.isMWeb,
        "sales_channel":state.sales_chanel

          },
        };
        //region validation
        if(payload.mrkreq.region_type) {
          dispatch({ type: "isItineraryLoading", payload: true });
        creation(payload.mrkreq)
          .then((response) => {
            if (response.suc) {
              dispatch({
                type: "notiMessage",
                payload: "Markup saved successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
              return (window.location = "/ezytrip/administration/hotel/markup");
            }  else if(response.data.err && response.data.err.includes("Duplicate") && !response.data.suc) {
              dispatch({ type: "isItineraryLoading", payload: false });
              dispatch({
                type: "notiMessage",
                payload: "Markup name already exist, please provide unique name.",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
            }else {
              dispatch({ type: "isItineraryLoading", payload: false });
              dispatch({
                
                type: "notiMessage",
                payload: "Markup Not saved..",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        dispatch({ type: "showMessage", payload: false });
      } else {
        dispatch({
          type: "notiMessage",
          payload: "Please Enter All The Mandatory Fields *",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        dispatch({ type: "notiMessageShow", payload: true });
        hidemessage()
      }
      }
    }
  }

  const updateMarkup = (e) => {
    e.preventDefault();
    let namevalidations = false;
    if (state.mn !== "") {
      if (characterValidation(state.mn)) {
        namevalidations = true;
        dispatch({ type: "nameError", payload: false });
      } else {
        dispatch({ type: "nameError", payload: true });
      }
      if (namevalidations) {

        // if (props.oldDataNames && props.oldDataNames.length > 0 && props.oldDataNames.indexOf(state.mn) === -1) {
        var ftype = setFlowType();
        var starcodes = "";
        if (state.star_rating) {
          state.star_rating.map((obj) => {
            if (starcodes) {
              starcodes = starcodes + "," + obj.code;
            } else {
              starcodes = obj.code;
            }
          });
        }
        const payload = {
          mrkreq: {
            id: state.markupId,
            mn: state.mn,
            ftype: ftype,
            act: act,
            ttype: state.ttype,
            upby: context.logindata.uid,
            utype: context.logindata.utype,
            str: starcodes ? starcodes : "All",
            app_htls: state.applied_hotels,
            apdate: state.applDates,
            hs: state.supp.length > 0 ? state.supp[0].value : null,
            region_type: context.logindata.utype==="att_user"?state.region_details:context.logindata.region_type
          },
        };
        if(payload.mrkreq.region_type) {
          dispatch({ type: "isItineraryLoading", payload: true });
        update(payload.mrkreq)
          .then((response) => {
            if (response.suc) {
              dispatch({
                type: "notiMessage",
                payload: "Markup Updated successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
              return (window.location = "/ezytrip/administration/hotel/markup");
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });
              dispatch({               
                type: "notiMessage",
                payload: "Markup Not Updated..",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        dispatch({ type: "showMessage", payload: false });
        } else {
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({
            type: "notiMessage",
            payload: "Please Enter All The Mandatory Fields *",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true });
          hidemessage()
        }
      }
    }
  }
  const setFlowType = (e) => {
    let flowtpes = [];
    flowtpes.push(state.btyp);
    flowtpes.push(state.ctyp);
    flowtpes.push(state.atyp);
    flowtpes.push(state.vtyp);
    // flowtpes.push(vType);
    var ftype = "";
    flowtpes.map((obj) => {
      if (ftype) {
        if (state.btyp && !ftype.includes("B")) {
          ftype = ftype + "|" + "B";
        } else if (state.ctyp && !ftype.includes("C")) {
          ftype = ftype + "|" + "C";
        } else if (state.atyp && !ftype.includes("A")) {
          ftype = ftype + "|" + "A";
        } else if (state.vtyp && !ftype.includes("V")) {
          ftype = ftype + "|" + "V";
        }
      } else {
        if (state.btyp) {
          ftype = "B";
        } else if (state.ctyp) {
          ftype = "C";
        } else if (state.atyp) {
          ftype = "A";
        } else if (state.vtyp) {
          ftype = "V";
        }
      }
    });
    return ftype;
  }

  const handleBookTypeChange = (e) => {
    if (e.target.id === "flb") {
      if (state.btyp) {
        dispatch({ type: 'btyp', payload: false })
      } else {
        dispatch({ type: 'btyp', payload: true })
      }
    }
  }

  const handleCanTypeChange = (e) => {
    if (e.target.id === "flc") {
      if (state.ctyp) {
        dispatch({ type: 'ctyp', payload: false })
      } else {
        dispatch({ type: 'ctyp', payload: true })
      }
    }
  }
  const handleAmendTypeChange = (e) => {
    if (e.target.id === "fla") {
      if (state.atyp) {
        dispatch({ type: 'atyp', payload: false })
      } else {
        dispatch({ type: 'atyp', payload: true })
      }
    }
  }
  const handleCancel = (e) => {
    return (window.location = "/ezytrip/administration/hotel/markup");
  }

  const handleChangeSupplier = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: "supp", payload: e })
      dispatch({type: "supplierError", payload: false })
    }
  }

  const setAir = (data) => {
    let finalClass = [];
    data.map((obj) => {
      const al = {
        "code": obj.value,
        "name": obj.label
      }
      finalClass.push(al);
    }
    );
    dispatch({ type: 'star_rating', payload: finalClass })
  };


  const applicableDates = (ad) => {

    state.applDates.push(ad);
    dispatch({type:"reset",payload:false})
    dispatch({ type: 'applDates', payload: state.applDates })
  }

  const updateApplDates = (data) => {
    dispatch({type:"reset",payload:false})
    dispatch({ type: 'applDates', payload: data })
    // setDates({datesList : [data]})
  }

  const callDeleteApp = (id) => {
    deleteAppcableDate(id).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }

  const deleteRecordedHotels = (id) => {
    deleteapplicableConfig(id).then(response => {
      if (response.data.suc) {
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
  }
  const selectedHotels = (e) => {
    if(e && e.length > 0)
    dispatch({type:"reset",payload:false})
     dispatch({type:"applied_hotels",payload: e})
  }

  const updatedHotelData = (e) => {
    dispatch({type:"reset",payload:false})
    dispatch({type:"applied_hotels",payload:e})
  }

  //FareMap  Data
  const fareMapData = (data) => {
    dispatch({ type: "reset", payload: false });
    setFareMaps({ fareMaps: [...fareMap.fareMaps, data] });
  };
  const afterDeleteFmapData = (data) => {
    dispatch({ type: "reset", payload: false });
    // setFareMaps(data)
    setFareMaps({ fareMaps: fareMap.fareMaps.filter((s,idx) => idx !== data) });

  }
  /**region handle  */
  const handleRegionChange = (value) => {
    let region = '' //contains the selected  regions filtered in string

      let temp_regionfilter = value.filter(function(s) { //filteration of the region in place of accordance to selection
        if(s) {
            if(region == '') {
              region = s.label
            } else {
              region = region +","+ s.label
            }
           
          }
          return s;
        }
      )
      state.region_details = region
      dispatch({type:"region_details",payload:state.region_details})
  }
  const sendChannel = (salesChanel) => {
    dispatch({ type: "sales_chanel", payload: salesChanel });

  }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }

  const updateProps = (isDeviceAll,isB2cWeb,isB2cApp,isMWeb)=>{
    dispatch({ type: 'isDeviceAll', payload: isDeviceAll })
    dispatch({ type: 'isB2cWeb', payload: isB2cWeb })
    dispatch({ type: 'isB2cApp', payload: isB2cApp })
    dispatch({ type: 'isMWeb', payload: isMWeb })
  }

  
  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }
  return (
    <>
      {state.notiMessageShow && (
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
      )}
      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
      <Form.Row>
        <Form.Group as={Col} xs={3} controlId="mn" className="d-flex align-items-center">
          <Form.Control type="text" placeholder="Markup Name" onChange={handleChange} value={state.mn} />
          {state.markupError && (
              <p className="vError">Please select Markup Name</p>
            )}
          <Form.Label>Markup Name<sup>*</sup></Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={6} className="inputCheck"  >
          <Form.Label>Flow Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
            <Form.Check type="checkbox" id="flb" label="Booking" custom className="ml-0" checked={state.btyp} value={state.btyp} onChange={handleBookTypeChange} />
            <Form.Check type="checkbox" id="flc" label="Cancellation" custom className="ml-3" checked={state.ctyp} value={state.ctyp} onChange={handleCanTypeChange} />
            {/* <Form.Check type="checkbox" id="fla" label="Amendment" custom className="ml-3" checked={state.atyp} value={state.atyp} onChange={handleAmendTypeChange} /> */}
          </div>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select

            options={supplier}
            values={state.supp}
            onChange={(value) => (handleChangeSupplier(value))}
          />
           {state.supplierError && (
              <p className="vError">Please select Supplier</p>
            )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={StarRating}
            multi
            values={state.str}
            onChange={(value) => (dispatch({ type: 'str', payload: value }), setAir(value))}
          />
          <Form.Label>Star Rating</Form.Label>
        </Form.Group>
      </Form.Row>

      {/* hotels Selection */}
      <HotelSelected enableHotel={true} hotelSelected={selectedHotels} hotelDeleteId={deleteRecordedHotels} editHotelResponse={state.edited_applied_hotels} updatedHotelData={updatedHotelData} reset={state.reset} disabledBtn={props.disableBtn}/>
      {/* Applicabe Dates */}

      <ApplicableDates updateBtn={state.enableUpdate} configType={"Markup"} sendAppDates={applicableDates} hotel={true}  updateData={updateApplDates} applDatesData={state.edit_applDates} sendApplicableId={callDeleteApp} reset={state.reset} disabledBtn={props.disableBtn} />

      {/* fare map component */}
      <AirMarkupFareMapForm configType="Markup" sendFareData={fareMapData}
        fareData={state.fareList}
        markupId={state.markupId}
        disabledBtn={props.disableBtn}
        reset={state.reset} saveDeleteFareMap={afterDeleteFmapData} region_type={handleRegionChange} editRegion={state.edit_region} sendChannel = {sendChannel} DeviceType = {handleDeviceTypeChange} updateProps={updateProps} edit_sales_channel={state.edit_sales_channel}/>

      <div className={"buttonGrop mt-4 pb-0 " + (state.enableUpdate ? 'text-right' : 'switchPlus')}>
          <Form.Group as={Col} className="topSwitch">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              checked={act}
              value={act}
              onChange={handleChange}
            />
          </Form.Group>
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={handleCancel}
        >
          Back
        </Button>
       {state.enableUpdate && !props.disableBtn &&
        <Button
            size="xs"
            onClick={updateMarkup}
            variant="outline-primary"
            type="submit"
            isDisabled={props.disableBtn || !context.logindata.roles.split(",").includes('H_M_U')}
          >
            Update
          </Button>
        }
        {!state.enableUpdate &&
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleReset}
          >
            Reset
        </Button>
        }
        {!state.enableUpdate && (
          <Button
            size="xs"
            onClick={handleSaveMarkup}
            variant="outline-primary"
            type="submit"
          >
            Save
          </Button>
        )}
        </div>
    </>
  );
}
export default Markup