import React from 'react';

/**
 * @description:This function will Calculate the Room wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 12-02-2021
 */

/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function ImportantInfo(props) {

  return (
    <>
      {props.checkin!==undefined &&
      <div className="costBox bookingSummary">
       <p className="impotantinfo toolTipInfo satya">Important Info
              <span className="ezyIcon icon-info"></span>
          <div className="toolTipCUstoom cnclInfo">
          {/* <p>{props.checkin.instructions}</p>
          <p>{props.checkin.specialInstructions}</p> */}
          <p dangerouslySetInnerHTML={{__html: props.checkin.instructions}} ></p>
          <p dangerouslySetInnerHTML={{__html: props.checkin.specialInstructions}} ></p>
          </div>
          {(props.checkin.instructions==="" || props.checkin.specialInstructions==="") &&
          <div className="toolTipCUstoom cnclInfo">
          <p>N/A</p>
          </div>
          }
        </p>
        </div>

    }
    </>

  )
}
export default ImportantInfo