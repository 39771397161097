/**
 * @description: Flight Summary page Root component
 * @date:17-09-2020
 * @author:Rambabu
 *
 */
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useRef, useState, } from 'react';
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Alert, Col, Modal, Row, Table, Form } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import InnerLoader from '../../../common/loader/InnerLoader';
import useOutsideClick from '../../../common/OutsideClick';
import ProcessingReissue from '../../../common/processingPopUp/ProcessingReissue';
import Processing from '../../../common/processingPopUp/Processing';
import ShowHide from '../../../common/showHide/ShowHide';
import DateUtils from '../../../commonUtils/DateUtils';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import FlightSummaryImportSectors from '../../importPNR/FlightSummaryImportSectors';
import ImportPNRunticketDetails from '../../importPNR/ImportPNRunticketDetails.jsx';
import { callDiscardBook, fetchfareDetailsReissued, callTicketing, cancelVoid, fireIncompleteService, getairportdata, getATCAirline, getCountryCodes, getFltSummaryData, getRefundDetails, getReissueDetails, retriveFareDetails, saveVoid, showRefunds, voidFareFetch, processEditBooking, cancelledSplit, atc_refundairlines, getcompanyAddress, getReissueSplitResp, cancelBookingFlow } from '../../summary/operations';
import SplitPnr from '../../void/SplitPnr.jsx';
import FlightSummaryBookingDetails from '../../summary/FlightSummaryBookingDetails.jsx';
import FlightSummaryFareDetails from '../../summary/FlightSummaryFareDetails.jsx';
import FlightSummaryPaxDetails from '../../importPNR/FlightSummaryImportPaxDetails.jsx';
import FlightSummaryRefund from '../../refund/FlightSummaryRefund';
import FlightSummarySectors from '../../summary/FlightSummarySectors.jsx';
import FlightSummarySectorHeader from "../../summary/FlightSummarySectorHeader";
import FlightSummarySegmentDetails from "../../summary/FlightSummarySegmentDetails";
import RefundType from "../../refund/RefundType";
import VoidSummaryDetails from '../../void/VoidSummaryDetails.jsx';
import Reissue from '../../reIssue/Reissue';
import Utilities from '../../../commonUtils/Utilities';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FlightInvoice from '../invoice/FlightInvoice';
import FlightInvoicePdf from '../invoice/FlightInvoicePdf';
import Tabs from '../../../common/tabs/Tabs';
import SplitReissuePNR from './SplitReissuePNR';
import { useReactToPrint } from "react-to-print";
const _ = require('lodash');
const JSON = require('circular-json');
export const USER_AUTH_DATA = "userAuthData";
export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
export const EDIT_DATA = "editData";
export const EDIT_TICKET_DATA = "editTicketData";
const SUR_CHARGE = process.env.BOOKING_SUR_CHARGE;

/**
 * Initial State Declaration
 */
const initialState = {
    summary_Res: [], bkng_det: [], pax_det: [], seg_det: [], no_trip: false, fare_det: [], fare_det_normal: [], pay_det: [], airport_data: [], ticketResp: [],
    incompletpopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', onholdResp: "", flow_type: '', bktype: '', enableVoidBooking: false, voidCountrydata: [],
    voidBid: '', voidPNR: '', tkts: [], voidTid: '', voidtransId: null, voidOfcId: '', rfAmount: null, voidSaveResponse: [], enableVoidCancel: false, disableCheckbox: false,
    pnr_Response: '', company_id: 0, enableRefund: false, aircodes: "", showRefund: false, ticketsVoided: [], ticketsVoidedCancelled: [], valid: true, isLoading: false, voidLoading: false, refundAvail: false,
    voidCancelTickets: [], reIssueResponse: [], b_status: '', comp_id: 0, showReissue: false, enableReissue: false, atcmsg: "", allTktslength: 0, voidSelectedLength: 0, clickedOn: "", butypehandler: [], owpaxTNO: [], rtpaxTNO: [], owpaxVoid: [], rtpaxVoid: [], isCancelBook: false,
    btype: 'partial', refund_split: [], enableEdit: false, updateBstatus: "", showEditpop: false, enableReturnEdit: false, enableEditBooking: false, onwardAvail: false, returnAvail: false, voidFareResponse: [], splitissue: false, splitCancel: false, voidenable: false, refundenable: false, splitPNR: "", enableOnwardIti: true, ticketsRefunded: [], airCodesArray: [], atc_refundable: false
    , loader: false, trip_type: "", hideReturn: false, enabledownloaddocs: false, popuppaxdata: [], allpopuppaxdata: [],
    ticketed: true, void: false, refund: false, downloadtype: 'INVOICE', invoice: true, cinvoice: false, itinerary: false,
    popupticketsarray: [], popupticketsdata: [], refundsplitonward: [], refundsplitreturn: [], flightDocsData: "", pupupmsg: 'No records found',
    popupallc: false, selected_data: [], index_selected: null, openClickedIndex: 0, resissued_data: [], isCancelBooking: false, journey_type: "", enableSplitReissue: [], enableRSplitPopup: false, process: false, cancelPopup: false,
    todaysDate : Utilities.getTimeZoneDate(new Date(),"QA"), showitiarray: [], changeStatus: "", allpopuppaxticketeddata: [], showitineraryRadio: true
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

/**
 * @description: This function will show the Flight Summary page
 * @date:17-09-2020
 * @author:Rambabu
 *
 */
function FlightReissueSplit(props) {


    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const ref = useRef();
    const [editMore, setEditMore,] = useState(false);
    const [editvoidMore, setvoidEditMore,] = useState(false);
    const [editrefundMore, setrefundEditMore,] = useState(false);
    const [share, setShare,] = useState(false);
    const [editItinerary, setEditItinerary,] = useState(false);
    const [editPayment, setEditPayment,] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); dispatch({ type: 'incompletpopup', payload: false }, dispatch({ type: 'voidLoading', payload: false }, dispatch({ type: "cancelPopup", payload: false }))) }
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [context, setContext] = useContext(Context);
    const [voidTicket, setVoidTicket] = useState([]); //applied void tickets during selective
    const handleShow = () => setShow(true);
    const [request, setRequest] = useState(props.location);
    const voidCncl = useRef(null)
    const executeScrolltop = () => {
        if (voidCncl && voidCncl.current !== null) {
            voidCncl.current.scrollIntoView()
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: state.bkng_det !== undefined && state.bkng_det[0] !== undefined && state.bkng_det[0].booking_id !== undefined && ("FLIGHT_" + state.downloadtype + "_" + state.bkng_det[0].booking_id)
    }
    );
    const atc_refund_airlines = ["BR", "BT", "BW", "CI", "CU", "DE", "E9", "EN", "ET", "G3", "GL", "GP", "H1", "HM", "HR", "IB", "IG", "J9", "JJ", "JP", "JU", "J2", "KC", "KE",
        "KU", "LH", "LR", "LX", "MF", "MH", "MI", "NE", "NH", "NZ", "O6", "OB", "OS", "OZ", "PC", "PG", "PK", "PM", "PS", "QR", "QS", "QV", "RJ", "S4", "SB", "SK", "SN", "SP", "SQ", "SS",
        "ST", "SV", "SW", "T0", "TA", "TF", "TN", "TK", "TP", "U6", "UA", "UK", "VA", "VS", "WB", "WE", "WX", "WY", "X1", "X3", "Z9", "ZL", "ZM"]
    const openMOre = (idx) => () => {
        if (editMore) {
            setEditMore(false)
            dispatch({ type: "openClickedIndex", payload: 0 })
        } else {
            setEditMore(true)
            //  selected_data:[],index_selected:null
            dispatch({ type: "selected_data", payload: [] })
            dispatch({ type: "index_selected", payload: null })
            dispatch({ type: "enableVoidBooking", payload: false })
            dispatch({ type: "enableVoidCancel", payload: false })
            dispatch({ type: "enableRefund", payload: false })
            dispatch({ type: "enableReissue", payload: false })
            dispatch({ type: "openClickedIndex", payload: idx + 1 })
        }
    }
    const openSplitMOre = () => {
        if (editvoidMore) {
            setvoidEditMore(false)
        } else {
            setvoidEditMore(true)
        }
    }
    const openSplitRefundMOre = () => {
        if (editrefundMore) {
            setrefundEditMore(false)
        } else {
            setrefundEditMore(true)
        }
    }
    const openshare = () => {
        if (share) {
            setShare(false)
        } else {
            setShare(true)
        }
    }
    const openeditItinerary = () => {
        if (editItinerary) {
            setEditItinerary(false)
        } else {
            setEditItinerary(true)
        }
    }
    const openeeditPayment = () => {
        if (editPayment) {
            setEditPayment(false)
        } else {
            setEditPayment(true)
        }
    }

    useOutsideClick(ref, () => {
        if (editMore) setEditMore(false);
        if (share) setShare(false);
        if (editItinerary) setEditItinerary(false);
        if (editPayment) setEditPayment(false);
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {

        let booking_ref = '';
        let unique_airport_codes = [];
        let unique_airline_codes = [];
        //fetch data from window pathname && window.location.href
        if (isNotNull(window.location.pathname)) {
            let url_array = window.location.pathname.split("/");
            booking_ref = url_array[url_array.length - 1];

        } else {
            let url_array1 = window.location.href.split("/");
            booking_ref = url_array1[url_array1.length - 1]

        }

        //fetch data from window param and call api for summary
        if (isNotNull(booking_ref)) {
            const req = {
                booking_id: booking_ref
            }

        }
    }, []);

    //propped reissued data implementation for UI
    useEffect(() => {
        if (props.reissued_data && props.reissued_data.length > 0) {
            dispatch({ type: "resissued_data", payload: props.reissued_data })
            let  showiti = []
            for(let val of props.reissued_data[0]){
                console.log(val)
                let voided = val[1].pax_det[0].filter(paxx=>paxx.split_pnr == null )
                if(voided.length !== 0){
                    console.log(val)
                    showiti.push(props.reissued_data[0].indexOf(val))
                }
            }
            dispatch({type: "showitiarray", payload: showiti })
    }
    }, [props.reissued_data])
    //Edit Booking Partials
    useEffect(() => {
        if (state.pax_det.length !== 0 && state.bkng_det[0].booking_status == "36") {
            if (state.flow_type == 'split') {
                let tidAry = state.bkng_det[0].tid.split("/");
                for (let pax of state.pax_det) {
                    for (let val of pax) {
                        if (val.tid === tidAry[0] && val.sts === "Ticketing failed") {
                            // dispatch({ type: 'enableEdit', payload: true })
                            dispatch({ type: 'onwardAvail', payload: true })
                            break;
                        } else if (val.tid === tidAry[1] && val.sts === "Ticketing failed") {
                            dispatch({ type: 'enableReturnEdit', payload: true })
                            dispatch({ type: 'returnAvail', payload: true })
                            break;
                        }
                    }
                }
            }
        }
    }, [state.pax_det])

    useEffect(() => {
        if (props.bookingData) {
            dispatch({ type: "bkng_det", payload: props.bookingData })
            dispatch({ type: 'flow_type', payload: props.bookingData[0].flow_type });
            dispatch({ type: 'bktype', payload: props.bookingData[0].bktype });
            dispatch({ type: 'b_status', payload: props.bookingData[0].booking_status })
            dispatch({ type: 'company_id', payload: props.bookingData[0].company_id })
        }
    }, [props.bookingData])

    function uniquearray(array) {
        var uniqueArray = [];

        // Loop through array values
        for (let i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    /**
   * @description : check if the void is accessible or not
   * @author : azamuddin
   * @date: 30-Nov-2020
   * --conditions to success the void
   *  -todays booking
   *  -confirmed booking
   */
    //check if the void is accessible or not
    const handleVoidBooking = (data, idx) => () => {
        dispatch({ type: 'enableVoidCancel', payload: false })
        //  selected_data:[],index_selected:null
        // if (props.bookingData[0].booking_status == "23" || props.bookingData[0].booking_status == "36") {
            let tkt_time = ''
            for (let val in data[1].pax_det) {
                let pax = data[1].pax_det[val]
                if (val.length > 0) {
                    for (let dub of pax) {
                        if(dub.tkt_time) {
                            tkt_time = dub.tkt_time
                            break;
                        }
                    }}}
                    tkt_time = tkt_time ? tkt_time : moment(props.bookingData[0].booking_date,"YYYY-MM-DD").format("DD-MMM-YYYY")
                    let bk_date = parseInt(moment(tkt_time,"DD-MMM-YYYY").format("YYYYMMDD")) //booking date
                    let todaysDate = parseInt(moment(state.todaysDate,"YYYY-MM-DD HH:mm:ss").format("YYYYMMDD"))
            if (bk_date === todaysDate) {
                if (state.valid) {
                    executeScrolltop();
                    state.butypehandler = []
                    let status = true
                    //if true then display the checkbox for the tickets
                    //only for the selected iterate we need to the void
                    dispatch({ type: "selected_data", payload: data }) //selected array for void
                    dispatch({ type: "index_selected", payload: idx })
                    let all_tkts = []
                    let allpax = []
                    //if split then save the journey_type
                    if (data[1].pax_det.length > 0 && state.flow_type == "split") {
                        let pax = data[1].pax_det[0]
                        dispatch({ type: "journey_type", payload: pax[0].journey_type })
                    }
                    for (let val in data[1].pax_det) {
                        let pax = data[1].pax_det[val]
                        if (val.length > 0) {
                            for (let dub of pax) {
                                allpax.push(val)
                                if (dub.split_pnr == null) {
                                    all_tkts.push(dub.tkt_no)
                                } else {
                                    if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                                        all_tkts.push(dub.tkt_no)
                                    }
                                }
                                if (dub.stsid === "31" || dub.stsid === "39") {
                                    //if booking normal
                                    state.butypehandler.push(dub.tkt_no)
                                }
                            }
                        }

                    }
                    dispatch({ type: "allTktslength", payload: all_tkts.length })
                    if (status) {
                        dispatch({ type: 'enableVoidBooking', payload: true })
                        dispatch({ type: "voidenable", payload: false })
                        dispatch({ type: "refundenable", payload: false })
                        dispatch({ type: "splitissue", payload: false })
                        dispatch({ type: "splitCancel", payload: false })
                    } else {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
                        dispatch({ type: 'notiVarient', payload: 'danger' })
                        hidemessage()
                    }
                } else {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'This Booking has already been Voided' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        // } else {
        //     dispatch({ type: 'notiMessageShow', payload: true })
        //     if (state.bkng_det[0].booking_status == "31") {
        //         dispatch({ type: 'notiMessage', payload: 'This Booking has already been Voided' })
        //     } else {
        //         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
        //     }

        //     dispatch({ type: 'notiVarient', payload: 'danger' })
        //     hidemessage()
        // }
    }

    /**
     * @author :Azamuddin
     * @param { data } contains each itn data
     * @param { idx } index value
     * @returns boolean value
     */

    const validConfirmScenario = (data, idx) => {
        let return_value = true
        let pax_det = data[1].pax_det
        for (let i of pax_det) {
          for(let val of i) {
            if (val.stsid !== "23") {
                return_value = false
            }
        }
        }
        return return_value
    }
    /**
  * @description : void cancel select
  * @author : azamuddin
  * @date : 07-12-2020
  */
    const handleVoidCancel = (data, idx) => (e) => {
        dispatch({ type: 'enableVoidBooking', payload: false })

        // if (state.bkng_det[0].booking_status == "23" || props.bookingData[0].booking_status == "36") {
            let tkt_time = ''
            for (let val in data[1].pax_det) {
                let pax = data[1].pax_det[val]
                if (val.length > 0) {
                    for (let dub of pax) {
                        if(dub.tkt_time) {
                            tkt_time = dub.tkt_time
                            break;
                        }
                    }}}
                    tkt_time = tkt_time ? tkt_time : moment(props.bookingData[0].booking_date,"YYYY-MM-DD").format("DD-MMM-YYYY")
                    let bk_date = parseInt(moment(tkt_time,"DD-MMM-YYYY").format("YYYYMMDD")) //booking date
                    let todaysDate = parseInt(moment(state.todaysDate,"YYYY-MM-DD HH:mm:ss").format("YYYYMMDD"))
            if (bk_date === todaysDate) {
                if (state.valid) {
                    executeScrolltop();
                    let status = true
                    state.butypehandler = []

                    dispatch({ type: "selected_data", payload: data }) //selected array for void cancel
                    dispatch({ type: "index_selected", payload: idx })
                    let all_tkts = []
                    let allpax = []
                    if (data[1].pax_det.length > 0 && state.flow_type == "split") {
                        let pax = data[1].pax_det[0]
                        dispatch({ type: "journey_type", payload: pax[0].journey_type })
                    }
                    for (let val in data[1].pax_det) {
                        let pax = data[1].pax_det[val]
                        if (val.length > 0) {
                            for (let dub of pax) {
                                allpax.push(val)
                                if (dub.split_pnr == null) {
                                    all_tkts.push(dub.tkt_no)
                                } else {
                                    if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                                        all_tkts.push(dub.tkt_no)
                                    }
                                }
                                if (dub.stsid === "31" || dub.stsid === "39") {
                                    //if booking normal
                                    state.butypehandler.push(dub.tkt_no)
                                }
                            }
                        }

                    }
                    dispatch({ type: "allTktslength", payload: all_tkts.length }) //alltkts length to check btype in functionalities

                    if (status) {
                        dispatch({ type: 'enableVoidCancel', payload: true })
                        dispatch({ type: "voidenable", payload: false })
                        dispatch({ type: "refundenable", payload: false })
                        dispatch({ type: "splitissue", payload: false })
                        dispatch({ type: "splitCancel", payload: false })
                    } else {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
                        dispatch({ type: 'notiVarient', payload: 'danger' })
                        hidemessage()
                    }

                    //if true then display the checkbox for the tickets

                } else {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'This Booking has already been Void/Cancelled' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        // } else {
        //     dispatch({ type: 'notiMessageShow', payload: true })
        //     if (state.bkng_det[0].booking_status == "39") {
        //         dispatch({ type: 'notiMessage', payload: 'This Booking has already been Void/Cancelled' })
        //     } else {

        //         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
        //     }
        //     dispatch({ type: 'notiVarient', payload: 'danger' })
        //     hidemessage()
        // }
    }

    //cancel booking flow json construction and api call
    const handleCancelBookingFlow = () => {
        //request needed for backend
        let pnr = ""
        let segList = []
        segList = state.selected_data[0].seg_det
        if (segList.length !== 0) {
            if (segList !== undefined && segList[0] !== undefined) {
                pnr = segList[0].normal_gds_pnr !== "" ? segList[0].normal_gds_pnr : segList[0].split_gds_pnr;
            }
        }
        let req_json = {
            "tid": segList[0].tid,
            "isTicketed": state.bkng_det[0].booking_status == "23" ? true : false,
            "booking_id": state.bkng_det[0].booking_id,
            "pnr": pnr
        }
        cancelBookingFlow(req_json).then((response) => {
            if (response.data.suc) {
                let data = response.data.data
                if (data.cancelled) {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Booking Cancelled Successfully' })
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    setTimeout(() => {
                        return window.location = window.location.pathname
                    }, [1500])
                }
            }
        })
    }

    /**
   * @description : tickets which are to be voided
   * @author : azamuddin
   * @date: 30-Nov-2020
   * --tickets selective from pax details ticket data
   */
    const voidBookReq = (e) => {
        setVoidTicket(e)
    }

    /**
   * @description : void popup
   * @author : azamuddin
   * @date: 30-Nov-2020
   * --two services - (getCountryCodes , voidFareFetch)
   */
    const handlevShow = (e) => {
        dispatch({ type: "loader", payload: true })
        let req = []
        let resp = []
        let selected_paxDetils = []
        let selected_segdetails = state.selected_data[0].seg_det
        //get country codes by passing city codes
        if (state.selected_data[1].pax_det.length > 0) {
            selected_paxDetils = state.selected_data[1].pax_det[0]
            req.push(selected_paxDetils[0].source)
            req.push(selected_paxDetils[selected_paxDetils.length - 1].destination)

        }
        getCountryCodes(req).then((res) => {
            if (res.data.length > 0) {
                let arr = [] //country codes
                for (let val of res.data) {
                    arr.push(val.country_code)
                }
                if (voidTicket.length > 0) {
                    dispatch({ type: "voidSelectedLength", payload: voidTicket.length })
                    let rq_Json = {
                        "tktno": voidTicket,
                        "status": 23,
                        "sa": selected_paxDetils[0].source,
                        "da": selected_paxDetils[selected_paxDetils.length - 1].destination,
                        "cid": props.bookingData[0].company_id,
                        "ftype": "v",
                        "ttype": parseInt(selected_segdetails[0].trvel_type),
                        "cls": selected_segdetails[0].cabin_class,
                        "supplier": "Amadeus",
                        "sc_code": arr[0],
                        "dc_code": arr[1],
                        "tf": DateUtils.prettyDate(selected_segdetails[0].depart_time, "YYYY-MM-DD"),
                        "tt": DateUtils.prettyDate(selected_segdetails[0].arrival_time, "YYYY-MM-DD"),
                        "bf": new Date(),
                        "bt": new Date(),
                        "ac": selected_segdetails[0].tkt_airline,
                        "pcid": context.logindata.pcid,
                        "butype": context.logindata.butype,
                        "bid": context.logindata.bid,
                        "paxCount": state.allTktslength
                    }

                    voidFareFetch(rq_Json).then((res) => {
                        let statusArr = []
                        if (res.success && res.data && res.data !== "Data not avaliable") {
                            setShow(true)
                            if (res.data.booking_id) {
                                dispatch({ type: 'voidBid', payload: res.data.booking_id }) //bookingid
                            }
                            if (res.data.offid) {
                                dispatch({ type: 'voidOfcId', payload: res.data.offid }) //ofcid
                            }
                            if (res.data.fk_trans_id) {
                                dispatch({ type: 'voidtransId', payload: res.data.fk_trans_id }) //transId
                            }
                            if (res.data.pnr) {
                                dispatch({ type: 'voidPNR', payload: res.data.pnr }) //PNR
                            }
                            if (res.data.tid) {
                                dispatch({ type: 'voidTid', payload: res.data.tid }) //tid
                            }
                            if (res.data.tkts) {
                                let arr = res.data.tkts
                                for (var i = 0; i < res.data.tkts.length; i++) {
                                    statusArr.push(arr[i].tkt)

                                    const voidServiceFeeMta = arr[i].sf && arr[i].sf.attsf ? arr[i].sf.attsf : 0;
                                    const voidServiceFeeTA = arr[i].sf && arr[i].sf.tasf ? arr[i].sf.tasf : 0;
                                    const voidServiceFeeSTA = arr[i].sf && arr[i].sf.stasf ? arr[i].sf.stasf : 0;

                                    const mtaSF = arr[i].bsf && arr[i].bsf.attsf ? arr[i].bsf.attsf : 0;
                                    const taSF = arr[i].bsf && arr[i].bsf.tasf ? arr[i].bsf.tasf : 0;
                                    const staSF = arr[i].bsf && arr[i].bsf.stasf ? arr[i].bsf.stasf : 0;

                                    const serviceFee = mtaSF + taSF + staSF;
                                    const voidFee = voidServiceFeeMta + voidServiceFeeTA + voidServiceFeeSTA;

                                    const mtaPLB = arr[i].mtaplb ? arr[i].mtaplb : 0;
                                    const taPLB = arr[i].taplb ? arr[i].taplb : 0;

                                    const plb = mtaPLB + taPLB;

                                    /* arr[i].tf = (arr[i].tf +serviceFee) - plb;
                                    arr[i].ra = parseInt(arr[i].tf-voidServiceFee-serviceFee);
                                    arr[i].sf = voidServiceFee+serviceFee; */


                                    arr[i].attsf = voidServiceFeeMta;
                                    arr[i].tasf = voidServiceFeeTA;
                                    arr[i].stasf = voidServiceFeeSTA;


                                    if (context.logindata.butype == "sta_user") {
                                        arr[i].tf = (arr[i].tf + taSF + staSF - plb);
                                        arr[i].sf = voidFee + taSF + staSF;
                                        arr[i].ra = parseInt(arr[i].tf - voidFee - (taSF + staSF));
                                    }
                                    else {
                                        arr[i].tf = (arr[i].tf + serviceFee - plb);
                                        arr[i].sf = voidFee + serviceFee;
                                        arr[i].ra = parseInt(arr[i].tf - voidFee - serviceFee);
                                    }

                                }
                                //void pnr handle
                                let voidedArray = state.butypehandler.length > 0 ? uniqueJson(state.butypehandler) : []
                                let computedArr = uniqueJson(statusArr.concat(voidedArray))
                                if (state.allTktslength === computedArr.length) {
                                    dispatch({ type: 'btype', payload: "full" })
                                }
                                dispatch({ type: 'tkts', payload: arr }) //tickets selected
                                let sum = []
                                for (let val of arr) {
                                    sum.push(val.ra)
                                }
                                //total refund amount
                                let rfAmount = sum.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                                dispatch({ type: 'rfAmount', payload: rfAmount })
                                handleShow()
                            }
                        } else {
                            dispatch({ type: 'notiMessageShow', payload: true })
                            dispatch({ type: 'notiMessage', payload: 'Data not Available' })
                            dispatch({ type: 'notiVarient', payload: 'danger' })
                            hidemessage()
                        }
                    })
                } else {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Select atleast one ticket to proceed futher' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            }
        })
    }

    function uniqueJson(jsonarray) {
        let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
        const mySetSerialized = new Set(myArrSerialized);
        const myUniqueArrSerialized = [...mySetSerialized];
        const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

        return myUniqueArr;
    }
    /**
     * @description : to save the void to the tickets selected
     * @author : azamuddin
     * @serviceFrom : Chandra
     * @date : 30-11-2020
     */
    const setVoid = (e) => {
        dispatch({ type: 'voidLoading', payload: true })
        let arrofpaxvoided = uniqueJson(state.butypehandler)
        /**handling journey type */
        let journey_type = ""
        if (state.flow_type !== "split") {
            if (state.bkng_det[0].travel_type == 1) {
                journey_type = "O"
            } else if (state.bkng_det[0].travel_type == 2) {
                journey_type = "O/R"
            } else {
                journey_type = "O"
            }
        } else {
            journey_type = state.journey_type
        }
        let re_Json = {
            "tkts": state.tkts, //tickets
            // "office_id": "DOHQR28IH",
            "office_id": state.voidOfcId, //ofcId
            "tid": state.voidTid, //tid
            "pnr": state.voidPNR, //PNR
            "fk_trans_id": state.voidtransId, //transId
            "booking_id": state.voidBid, //bookingId
            "btype": state.btype,
            "tkt_user_type": context.logindata.utype,
            "tkt_user_id": context.logindata.uid,
            "bktype": "IMPORT",
            "journey_type": journey_type,
            "cus_code" : props.cus_code
        }
        saveVoid(re_Json).then((response) => {
            if (response.data.suc) {
                let res = response.data.data
                if (res && response.data.data.tkts && response.data.data.tkts.length > 0) {
                    let arr = []
                    let ticketsVoided = []
                    for (let val of res.tkts) {

                        if (val.tktst == '31') {
                            arr.push(val)
                            ticketsVoided.push(val.tkt)
                        }
                    }
                    dispatch({ type: 'ticketsVoided', payload: ticketsVoided })
                    dispatch({ type: 'voidSaveResponse', payload: arr })
                    dispatch({ type: 'enableVoidBooking', payload: false })
                    handleClose()
                    if (ticketsVoided.length > 0) {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Successfull' })
                        dispatch({ type: 'notiVarient', payload: 'success' });
                        hidemessage()
                        return window.location = window.location.pathname

                    } else {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Unsuccessfull' })
                        dispatch({ type: 'notiVarient', payload: 'danger' });
                        hidemessage()
                        setIsLoadingMore(false);
                        dispatch({ type: 'voidLoading', payload: false })
                        dispatch({ type: 'enableVoidBooking', payload: false })
                        dispatch({ type: 'enableVoidCancel', payload: false })
                        dispatch({ type: 'disableCheckbox', payload: true })
                    }
                }
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Unsuccessfull' })
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
                dispatch({ type: 'voidLoading', payload: false })
                setIsLoadingMore(false);
                dispatch({ type: 'enableVoidBooking', payload: false })
                dispatch({ type: 'enableVoidCancel', payload: false })
                dispatch({ type: 'disableCheckbox', payload: true })
                handleClose()
            }
        })
        dispatch({ type: 'disableCheckbox', payload: false })

    }


    const getCityReissueName = (code, mappings) => {
        for (let val of mappings) {
            if (val.airportCode === code) {
                return val.cityName;
            }
        }
    }

    //Converting Multicity
    const convertMultiCityReissueObj = (response) => {
        var search = new Object();
        if (response) {
            for (let src of response.seg) {
                if (src.ref !== undefined) {
                    if (src.ref == 1) {
                        search.dep1 = src.da;
                        search.arr1 = src.ar;
                        search.dd1 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptOne = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty1 = getCityReissueName(src.da, response.mappings);
                        search.acty1 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataOne = response.mappings.filter((obj) => obj.airportCode === src.da);
                        search.destDataOne = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    } else if (src.ref == 2) {
                        search.dep2 = src.da;
                        search.arr2 = src.ar;
                        search.dd2 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptTwo = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty2 = getCityReissueName(src.da, response.mappings);
                        search.acty2 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataTwo = response.mappings.filter((obj) => obj.airportCode === src.da);
                        search.destDataTwo = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    } else if (src.ref == 3) {
                        search.dep3 = src.da;
                        search.arr3 = src.ar;
                        search.dd3 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptThree = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty3 = getCityReissueName(src.da, response.mappings);
                        search.acty3 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataThree = response.mappings.filter((obj) => obj.airportCode === src.da);
                        search.destDataThree = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    } else if (src.ref == 4) {
                        search.dep4 = src.da;
                        search.arr4 = src.ar;
                        search.dd4 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptFour = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty4 = getCityReissueName(src.da, response.mappings);
                        search.acty4 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataFour = response.mappings.filter((obj) => obj.airportCode === src.da);
                        search.destDataFour = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    } else if (src.ref == 5) {
                        search.dep5 = src.da;
                        search.arr5 = src.ar;
                        search.dd5 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptFive = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty5 = getCityReissueName(src.da, response.mappings);
                        search.acty5 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataFive = response.mappings.filter((obj) => obj.airportCode === src.da);
                        search.destDataFive = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    } else if (src.ref == 6) {
                        search.dep6 = src.da;
                        search.arr6 = src.ar;
                        search.dd6 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
                        search.deptSix = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
                        search.dcty6 = getCityReissueName(src.da, response.mappings);
                        search.acty6 = getCityReissueName(src.ar, response.mappings);
                        search.orignDataSix = response.mappings.filter((obj) => obj.airportCode === src.da)
                        search.destDataSix = response.mappings.filter((obj) => obj.airportCode === src.ar);
                    }
                }
            }
        }
        search.cls = setClsType(response.cl);
        search.adt = response.adt;
        search.chd = response.chd;
        search.inf = response.inf;
        response.search = search
        return response;
    }

    const setClsType = (cls) => {
        var cl = "Economy";
        if (cls === "E") {
            cl = "Economy"
        } else if (cls === "B") {
            cl = "Business"
        } else if (cls === "F") {
            cl = "First"
        }
        return cl;
    }

    /**
     * @description : to remove the checkboxes during void Selection
     * @author : Azamuddin
     * @date: 07-12-2020
     */
    const cancelCheckBoxes = () => {
        dispatch({ type: 'enableVoidBooking', payload: false })
        dispatch({ type: 'enableVoidCancel', payload: false })
        dispatch({ type: 'disableCheckbox', payload: true })
        dispatch({ type: "selected_data", payload: [] }) //selected array for void
        dispatch({ type: "index_selected", payload: null })
    }

    /**
    * @description : to set void cancellation during void Selection in DB
    * @author : Azamuddin
    * @serviceFrom : Chandra
    * @date: 07-12-2020
    */
    const setCancelVoid = (e) => {
        dispatch({ type: 'voidLoading', payload: true })
        let arrofpaxvoided = uniqueJson(state.butypehandler)
        let journey_type = ""
        if (state.flow_type !== "split") {
            if (state.bkng_det[0].travel_type == 1) {
                journey_type = "O"
            } else if (state.bkng_det[0].travel_type == 2) {
                journey_type = "O/R"
            } else {
                journey_type = "O"
            }
        } else {
            journey_type = state.journey_type
        }
        let re_Json = {
            "tkts": state.tkts, //tickets
            // "office_id": "DOHQR2718",
            "office_id": state.voidOfcId, //ofcId
            "tid": state.voidTid, //tid
            "pnr": state.voidPNR, //PNR
            "fk_trans_id": state.voidtransId, //transId
            "booking_id": state.voidBid, //bookingId
            "btype": state.btype,
            "tkt_user_type": context.logindata.utype,
            "tkt_user_id": context.logindata.uid,
            "bktype": "IMPORT",
            "journey_type": journey_type, //journey type
            "cus_code" : props.cus_code
        }
        cancelVoid(re_Json).then((response) => {
            if (response.data.suc) {
                let res = response.data.data
                if (res && response.data.data.tkts && response.data.data.tkts.length > 0) {
                    let arr = []
                    let voidedTicket = []
                    for (let val of res.tkts) {
                        if (val.tktst == '39') {
                            arr.push(val)
                            voidedTicket.push(val.tkt)
                        }
                    }
                    dispatch({ type: 'ticketsVoidedCancelled', payload: voidedTicket })

                    dispatch({ type: 'voidSaveResponse', payload: arr })
                    dispatch({ type: 'enableVoidCancel', payload: false })
                    handleClose()
                    if (voidedTicket.length > 0) {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Successfull' })
                        dispatch({ type: 'notiVarient', payload: 'success' });
                        hidemessage()
                        return window.location = window.location.pathname

                    } else {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Unsuccessfull' })
                        dispatch({ type: 'notiVarient', payload: 'danger' });
                        hidemessage()
                        setIsLoadingMore(false);
                        dispatch({ type: 'enableVoidBooking', payload: false })
                        dispatch({ type: 'enableVoidCancel', payload: false })
                        dispatch({ type: 'disableCheckbox', payload: true })
                    }
                }
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Unsuccessfull' })
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
                setIsLoadingMore(false);
                cancelCheckBoxes()
                handleClose()
            }
        })
        dispatch({ type: 'disableCheckbox', payload: false })
    }

    const handleCancel = (e) => {
        return history.push("/ezytrip/dashboard/bookingList");
    }
    const pnr_response = (response) => {
        dispatch({ type: 'pnr_Response', payload: response })
    }

    //Call refund service to get the details
    const reissueTickts = (data) => {
        if (data && data.length !== 0) {
            setIsLoadingMore(true);
            let adts = [];
            let chds = [];
            let infs = [];
            let finalPax = [];
            let tktsData = Utilities.getUniqData(data);
            if (tktsData !== undefined && tktsData.length !== 0) {
                adts = tktsData.filter(x => x.ty === "ADT");
                for (let adt of adts) {
                    finalPax.push(adt);
                }
                chds = tktsData.filter(x => x.ty === "CHD");
                if (chds !== undefined && chds !== 0) {
                    for (let chd of chds) {
                        finalPax.push(chd);
                    }
                }
                infs = tktsData.filter(x => x.ty === "INF");
                if (infs !== undefined && infs !== 0) {
                    for (let inf of infs) {
                        finalPax.push(inf);
                    }
                }
            }
            let tid = "";
            let pnr = "";
            let cls = "";
            let ofcid = "";
            let seg_det = state.selected_data[0].seg_det
            if (state.bkng_det[0].flow_type === "normal") {
                let spnr = seg_det[0].normal_gds_pnr
                if (seg_det[0].normal_gds_pnr.includes(",")) {
                    let spnrarray = seg_det[0].normal_gds_pnr.split(",")
                    spnr = spnrarray[0]
                }
                tid = seg_det[0].tid;
                ofcid = seg_det[0].office_id;
                pnr = spnr;
                cls = seg_det[0].cabin_class;
            } else {

                if (seg_det.length !== 0) {
                    let segList = seg_det.filter(x => x.journey_type === state.journey_type);
                    if (segList !== undefined && segList[0] !== undefined) {
                        tid = segList[0].tid;
                        ofcid = segList[0].office_id;
                        pnr = segList[0].normal_gds_pnr !== "" ? segList[0].normal_gds_pnr : segList[0].split_gds_pnr;
                        cls = segList[0].cabin_class;
                    }
                }

            }
            const payload = {
                "tid": tid,
                "pnr": pnr,
                // "ofcid": "DOHQR2718",
                "office_id": ofcid,
                "butype": context.logindata.butype,
                "tkts": finalPax,
                "cid": state.bkng_det[0].company_id,
                "adt": adts.length,
                "chd": chds.length,
                "inf": infs.length,
                "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
                "tt": parseInt(seg_det[0].trvel_type),
                "cur": state.bkng_det[0].usr_curr_code,
                "bktype": "IMPORT/REISSUE",
                "sty": state.bkng_det[0].flow_type,
                "bref": state.bkng_det[0].booking_id,
                "journey_type": (state.journey_type == "O" || state.journey_type == "") ? "Onward" : "Return",
                "unticketTag": props.unticketTag,
                "region_type":state.bkng_det[0].region_type,
                "req_cur":state.bkng_det[0].usr_curr_code
            }
            getReissueDetails(payload).then((resp) => {
                if (resp.suc && resp.data !== undefined && resp.data.change) {
                    resp.data.tt = 3;
                    resp.data = convertMultiCityReissueObj(resp.data);
                    resp.data.clstyp = "Economy";
                    resp.data.ns = false;
                    history.push({
                        pathname: '/ezytrip/dashboard/reissue',
                        state: {
                            req: resp.data,
                            bref: state.bkng_det[0].booking_id,
                            cls: cls,
                            region_type:state.bkng_det[0].region_type,
                            req_cur:state.bkng_det[0].usr_curr_code
                        }
                    });
                } else {
                    setIsLoadingMore(false);
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            })
        } else {
            setIsLoadingMore(false);
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }

    //Call Reissue Return service
    const reissueReturnTickts = (data) => {
        if (data && data.length !== 0) {
            setIsLoadingMore(true);
            let adts = [];
            let chds = [];
            let infs = [];
            let finalPax = [];
            let tktsData = Utilities.getUniqData(data);
            if (tktsData !== undefined && tktsData.length !== 0) {
                adts = tktsData.filter(x => x.ty === "ADT");
                for (let adt of adts) {
                    finalPax.push(adt);
                }
                chds = tktsData.filter(x => x.ty === "CHD");
                if (chds !== undefined && chds !== 0) {
                    for (let chd of chds) {
                        finalPax.push(chd);
                    }
                }
                infs = tktsData.filter(x => x.ty === "INF");
                if (infs !== undefined && infs !== 0) {
                    for (let inf of infs) {
                        finalPax.push(inf);
                    }
                }
            }
            let tid = "";
            let pnr = "";
            let cls = "";
            let ofcid = "";
            if (state.bkng_det[0].bktype === "DIRECT") {
                let tidAry = state.bkng_det[0].tid.split("/");
                let ofcidAry = state.bkng_det[0].office_id.split("/");
                let prnrAry = state.seg_det[0].normal_gds_pnr.split(",");
                tid = tidAry[1];
                ofcid = ofcidAry[1];
                pnr = prnrAry[1];
                cls = state.seg_det[0].cabin_class;
            } else {
                if (state.seg_det.length !== 0) {
                    let segList = state.seg_det.filter(x => x.journey_type === "R");
                    if (segList !== undefined && segList[0] !== undefined) {
                        tid = segList[0].tid;
                        ofcid = segList[0].office_id;
                        pnr = segList[0].split_gds_pnr !== "" ? segList[0].split_gds_pnr : segList[0].normal_gds_pnr;
                        cls = segList[0].cabin_class;
                    }
                }
            }
            const payload = {
                "tid": tid,
                "pnr": pnr,
                // "ofcid": "DOHQR2718",
                "office_id": ofcid,
                "butype": context.logindata.butype,
                "tkts": finalPax,
                "cid": state.bkng_det[0].company_id,
                "adt": adts.length,
                "chd": chds.length,
                "inf": infs.length,
                "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
                "tt": parseInt(state.seg_det[0].trvel_type),
                "cur": state.bkng_det[0].usr_curr_code,
                "bktype": "IMPORT",
                "sty": state.bkng_det[0].flow_type,
                "bref": state.bkng_det[0].booking_id,
                "journey_type": "Return",
                "region_type":state.bkng_det[0].region_type,
                "req_cur":state.bkng_det[0].usr_curr_code
            }
            getReissueDetails(payload).then((resp) => {
                if (resp.suc && resp.data !== undefined && resp.data.change) {
                    resp.data.tt = 3;
                    resp.data = convertMultiCityReissueObj(resp.data);
                    resp.data.clstyp = "Economy";
                    resp.data.ns = false;
                    history.push({
                        pathname: '/ezytrip/dashboard/reissue',
                        state: {
                            req: resp.data,
                            bref: state.bkng_det[0].booking_id,
                            cls: cls,
                            region_type:state.bkng_det[0].region_type,
                            req_cur:state.bkng_det[0].usr_curr_code
                        }
                    });
                } else {
                    setIsLoadingMore(false);
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            })
        } else {
            setIsLoadingMore(false);
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }

    //Call refund service to get the details
    const continueRefund = (data) => {
        // dispatch({type:"isLoading",payload:true})
        let seg_det = state.selected_data[0].seg_det
        let pax_det = state.selected_data[1].pax_det
        let journeyType = "";
        let valid = false
        if (data[1] == "ATC Refund") {
            if (state.atc_refundable) {
                valid = true
            }
        } else {
            valid = true
        }
        if (valid) {
            let btype = 'partial'
            setIsLoadingMore(true);
            let req = []
            if (pax_det.length > 0) {
                req.push(pax_det[0][0].source)
                req.push(pax_det[0][0].destination)
            }
            let arr = [] //country codes
            getCountryCodes(req).then((res) => {
                if (res.data !== undefined && res.data.length > 0) {
                    for (let val of res.data) {
                        arr.push(val.country_code)
                    }
                }
                var tid = "";
                var ofcid = "";
                let pnr = '';
                if (state.bkng_det[0] !== undefined && state.bkng_det[0].flow_type !== "split") {
                    tid = seg_det[0].tid;
                    ofcid = seg_det[0].office_id;
                    if (seg_det[0].normal_gds_pnr.includes(",")) {
                        let pnr_arry = seg_det[0].normal_gds_pnr.split(",")
                        pnr = pnr_arry[0]
                    } else {
                        pnr = seg_det[0].normal_gds_pnr
                    }
                    if (state.bkng_det[0].travel_type == 1) {
                        journeyType = "O"
                    } else if (state.bkng_det[0].travel_type == 2) {
                        journeyType = "O/R"
                    } else {
                        journeyType = "O"
                    }
                } else {
                    let tid_c = ""
                    let ofcid_c = ""
                    let pnr_c = ""
                    //if reissue latest pnr and tid for reqpective itenary is need so selecteing form seg_det
                    tid_c = seg_det[0].tid
                    ofcid_c = seg_det[0].office_id
                    if (seg_det[0].normal_gds_pnr.includes(",")) {
                        let pnr_arry = seg_det[0].normal_gds_pnr.split(",")
                        pnr_c = pnr_arry[0]
                    } else {
                        pnr_c = seg_det[0].normal_gds_pnr
                    }
                    tid = tid_c;
                    ofcid = ofcid_c;
                    pnr = pnr_c;
                    journeyType = "O";
                }
                if (state.bkng_det[0].flow_type !== "split") {
                    let circulated_arr = data[0].concat(state.ticketsRefunded)
                    if (uniqueJson(circulated_arr).length === state.allTktslength) {
                        btype = 'full'
                    }
                    // journeyType="O";
                } else {
                    /****split *****/
                    let type = ""
                    let refundedTkts = []
                    //checking if selected ticket is onward or return
                    if (state.journey_type == "O") {
                        refundedTkts = data[0].concat(state.ticketsRefunded)
                    } else {
                        refundedTkts = data[0].concat(state.ticketsRefunded)
                    }
                    if (uniqueJson(refundedTkts).length === state.allTktslength) {
                        btype = 'full'
                    }
                }
                //Tickets Info
                let paxInfo=[];
                if(data[1]==="Manual Refund"){
                    for(let tkt of data[0]){
                      if(state.bkng_det[0] !== undefined && state.bkng_det[0].flow_type === "normal"){
                        let paxList =pax_det[0].filter((pax)=>pax.tkt_no===tkt);
                        paxInfo.push(paxList[0]);
                      }else{
                        if(journeyType==="O"){
                          let paxList =pax_det[0].filter((pax)=>pax.tkt_no===tkt);
                          paxInfo.push(paxList[0]);
                        }else if(journeyType==="R"){
                          if(pax_det[1]!==undefined){
                            let paxList =pax_det[1].filter((pax)=>pax.tkt_no===tkt);
                            paxInfo.push(paxList[0]);
                          }
                        }
                      }
                     }
                   }
                const payload = {
                    "tid": tid,
                    "rtype": data[1],
                    "btype": btype,
                    "cancelPnr": state.isCancelBook,
                    "pnr": pnr,
                    // "ofcid": "DOHQR2718",
                    "office_id": ofcid,
                    "butype": context.logindata.butype,
                    "tkts": data[0],
                    "tkts_info":paxInfo,
                    "bref": state.bkng_det[0].booking_id,
                    "ac": state.aircodes,
                    "dep": pax_det[0][0].source,
                    "arr": pax_det[0][0].destination,
                    "cid": state.bkng_det[0].company_id,
                    "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
                    "tt": parseInt(seg_det[0].trvel_type),
                    "cls": seg_det[0].cabin_class,
                    "supplier": "Amadeus",
                    "bktype": "IMPORT",
                    "sc_code": arr[0],
                    "dc_code": arr[1],
                    "dd": DateUtils.prettyDate(seg_det[0].depart_time, "YYYY-MM-DD"),
                    "ad": DateUtils.prettyDate(seg_det[0].arrival_time, "YYYY-MM-DD"),
                    "bf": new Date(),
                    "bt": new Date(),
                    "rbd": "",
                    "ftype": "Refund",
                    "bid": context.logindata.bid,
                    "paxCount": state.allTktslength,
                    "cus_code" : props.cus_code,
                    "region_type":state.bkng_det[0].region_type,
                    "req_cur":state.bkng_det[0].usr_curr_code

                }
                getRefundDetails(payload, data[1]).then((resp) => {
                    if (resp.refundPricingRes.suc && resp.refundPricingRes.data !== undefined && resp.refundPricingRes.data.psngrs.length !== 0) {
                        setIsLoadingMore(false);
                        history.push({
                            pathname: data[1]!=="Manual Refund"?'/ezytrip/dashboard/refund':'/ezytrip/dashboard/manual-refund',
                            state: {
                                req: resp.refundPricingRes,
                                bref: state.bkng_det[0].booking_id,
                                type: data[2],
                                journey_type: journeyType,
                                region_type:state.bkng_det[0].region_type,
                                req_cur:state.bkng_det[0].usr_curr_code
                            }
                        });
                    } else {
                        setIsLoadingMore(false);
                        // dispatch({type:"isLoading",payload:false})
                        dispatch({ type: 'notiMessageShow', payload: true })
                        if (data[1] === "ATC Refund") {
                            dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later or Proceed for Void' })
                        } else {
                            dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later or Proceed with ATC Refund' })
                        }
                        dispatch({ type: 'notiVarient', payload: 'danger' })
                        hidemessage()
                    }
                })
            })
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Airline is not eligible for ATC Refund' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()

        }
    }

    //Handle refund
    const handleEdiyBooking = () => {
        dispatch({ type: 'enableEdit', payload: true })
        dispatch({ type: 'enableReturnEdit', payload: false })
        dispatch({ type: 'enableEditBooking', payload: true })

    }
    const handleEditReturn = () => {
        dispatch({ type: 'enableReturnEdit', payload: true })
        dispatch({ type: 'enableEdit', payload: false })
        dispatch({ type: 'enableEditBooking', payload: true })
    }

    //Handle refund
    /**
     * @description : to check avail of refund and enable refund related Functionalities
     * @author : Azamuddin
     * @date : 14-06-2021

     */
    const handleRefundTicket = (data, idx) => () => {
        let seg_details = data[0].seg_det //segment details
        let airCodes = [];  //airline codes
        let values = [];
        let unique_airline_codes = []
        for (var i = 0; i < seg_details.length; i++) {
            airCodes.push(seg_details[i]['tkt_airline']);
            if (seg_details[i].hasOwnProperty('seg_source') || seg_details[i].hasOwnProperty('seg_destination'))
                values.push(seg_details[i]['seg_source']);
            values.push(seg_details[i]['seg_destination']);
        };
        let unique_airport_codes = uniquearray(values);
        newArray = uniquearray(airCodes);
        var newArray = unique_airline_codes.toString();
        dispatch({ type: 'aircodes', payload: newArray })

        dispatch({ type: "airCodesArray", payload: airCodes })
        let req_json = {
            "ac": uniqueJson(airCodes)
        }
        //fetching if airline is atc or non-atc for refund
        atc_refundairlines(req_json).then((resp) => {
            if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Refund") {
                dispatch({ type: "atc_refundable", payload: true })
            } else {
                dispatch({ type: "atc_refundable", payload: false })
            }
        })
        /// fetching alltkts wrt to the split
        let all_tkts = []
        let allpax = []
        if (data[1].pax_det.length > 0 && state.flow_type == "split") {
            let pax = data[1].pax_det[0]
            dispatch({ type: "journey_type", payload: pax[0].journey_type })
        }
        for (let val in data[1].pax_det) {
            let pax = data[1].pax_det[val]
            if (val.length > 0) {
                for (let dub of pax) {
                    if (dub.stsid === "42" || dub.stsid === "44") {
                        allpax.push(dub.tkt_no)
                    }
                    if (dub.split_pnr == null) {
                        all_tkts.push(dub.tkt_no)
                    } else {
                        if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                            all_tkts.push(dub.tkt_no)
                        }
                    }
                }
            }

        }
        dispatch({ type: "ticketsRefunded", payload: allpax })
        dispatch({ type: "allTktslength", payload: all_tkts.length }) //alltkts length to check btype in functionalities
        dispatch({ type: 'enableRefund', payload: true })
        dispatch({ type: "voidenable", payload: false })
        dispatch({ type: "refundenable", payload: false })
        dispatch({ type: "splitissue", payload: false })
        dispatch({ type: "splitCancel", payload: false })
        dispatch({ type: 'enableReissue', payload: false })
        dispatch({ type: "selected_data", payload: data }) //selected array for refund
        dispatch({ type: "index_selected", payload: idx })
        executeScrolltop();
    }

    /**
     * @description : reissue call for the tickest selected
     * @author : Azamuddin
     * @date : 13-06-2021
     */
    //Handle Reissue
    const handleReissueTicket = (data, idx) => () => {
        //dispatch({type:'enableReissue',payload: true})
        let seg_details = data[0].seg_det
        getATCAirline({ "ac": seg_details[0].markting_airline ? [seg_details[0].markting_airline] : [] }).then((resp) => {
            if (data[1].pax_det.length > 0 && state.flow_type == "split") {
                let pax = data[1].pax_det[0]
                dispatch({ type: "journey_type", payload: pax[0].journey_type })
            }
            if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Gurantee") {
                dispatch({ type: 'enableReissue', payload: true })
                dispatch({ type: 'enableRefund', payload: false })
                dispatch({ type: "voidenable", payload: false })
                dispatch({ type: "refundenable", payload: false })
                dispatch({ type: "splitissue", payload: false })
                dispatch({ type: "splitCancel", payload: false })
                dispatch({ type: "hideReturn", payload: true })
                dispatch({ type: 'atcmsg', payload: "Guarantee" })
                dispatch({ type: "selected_data", payload: data }) //selected array for reissue
                dispatch({ type: "index_selected", payload: idx })
                executeScrolltop();
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'This Booking/Airline is not eligible for Reissue' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        })
    }

    const upateOnwardReissue = () => {
        dispatch({ type: 'enableReissue', payload: false })
    }

    //Handle Reissue
    const handleReissueTicketReturn = () => {
        const payload = {
            "ac": (state.seg_det.length !== 0) ? [state.seg_det[0].markting_airline] : []
        }
        //dispatch({type:'enableReissue',payload: true})
        getATCAirline(payload).then((resp) => {
            if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Gurantee") {
                dispatch({ type: 'enableReissue', payload: true })
                dispatch({ type: 'enableRefund', payload: false })
                dispatch({ type: "voidenable", payload: false })
                dispatch({ type: "refundenable", payload: false })
                dispatch({ type: "splitissue", payload: false })
                dispatch({ type: "splitCancel", payload: false })
                dispatch({ type: 'atcmsg', payload: resp.data[0].atc })
                executeScrolltop();
            } else {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'This Booking/Airline is not eligible for Reissue' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        })
    }


    //Handle Booking Status
    const updateBstatus = (data) => {
        dispatch({ type: 'updateBstatus', payload: data })
    }


    const callEditBooking = () => {
        dispatch({ type: 'showEditpop', payload: true })
    }

    const cancelEdit = () => {
        return window.location = window.location.pathname;
    }

    const hidemessage = () => {
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
    }

    const validateGds = (type) => {
        if (type === "gds") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter the GDS PNR.' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter the Airline PNR.' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
        }

    }

    const cancelBookUpdate = (data) => {
        dispatch({ type: 'isCancelBook', payload: data })
    }

    const getSegmentNormalObj = (seg) => {
        const obj = {
            "segid": seg.segid,
            "gds": seg.normal_gds_pnr,
            "airline": seg.normal_airline_pnr
        }
        return obj;
    }

    const getSegmentSplitObj = (seg) => {
        const obj = {
            "segid": seg.segid,
            "gds": seg.split_gds_pnr,
            "airline": seg.split_airline_pnr
        }
        return obj;
    }

    const processEditBookingFlow = () => {
        if (state.enableEdit) {
            processEditBookingFlowOnward();
        } else if (state.enableEditBooking) {
            processEditBookingSplitReturn();
        }
    }

    const checkBookingStatus = () => {
        if (state.onwardAvail && state.returnAvail) {
            if (state.updateBstatus === "Partially Ticketed") {
                return true;
            } else {
                return false;
            }
        } else if ((!state.onwardAvail && state.returnAvail) || (state.onwardAvail && !state.returnAvail)) {
            if (state.updateBstatus === "Confirmed") {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    //Handle Edit Booking flow
    const processEditBookingFlowOnward = () => {
        let segsList = [];
        let validate = true; var onwardTid = "";
        let value = false;
     //Checking Airline and GDS PNRS
      var data = JSON.parse(localStorage.getItem(EDIT_DATA));
      if(data!==null){
       for(let seg1 of state.seg_det){
       for(let seg2 of data){
        if(Number(seg1.segid) === Number(seg2.segid)){
         if(seg2.changed!==undefined && seg2.changed){
           seg1.changed=true;
           seg1.normal_airline_pnr=seg2.normal_airline_pnr;
           seg1.normal_gds_pnr=seg2.normal_gds_pnr;
         }
        }
       }
      }
      }
        if (state.bkng_det[0].flow_type === "normal") {
            if (state.updateBstatus === "Confirmed") {
                value = true;
            }
        } else {
            if (state.bkng_det[0].booking_status === "34") {
                if (state.updateBstatus === "Partially Ticketed") {
                    value = true;
                }
            } else {
                value = checkBookingStatus();
            }
        }
        if (value) {
            if (state.seg_det.length !== 0) {
                for (let seg of state.seg_det) {
                    if (state.bkng_det[0].flow_type === "normal") {
                        if (seg.normal_airline_pnr !== undefined && seg.normal_airline_pnr !== "") {
                            if (seg.normal_gds_pnr !== undefined && seg.normal_gds_pnr !== "") {
                                if (seg.changed !== undefined && seg.changed) {
                                    segsList.push(getSegmentNormalObj(seg));
                                }
                            } else {
                                validate = false;
                                validateGds("gds");
                            }
                        } else {
                            validate = false;
                            validateGds("airline");
                        }
                    } else {
                        let tidArray = state.bkng_det[0].tid.split("/");
                        onwardTid = tidArray[0];
                        if (seg.tid === onwardTid) {
                            if (seg.split_airline_pnr !== undefined && seg.split_airline_pnr !== "") {
                                if (seg.split_gds_pnr !== undefined && seg.split_gds_pnr !== "") {
                                    if (seg.changed !== undefined && seg.changed) {
                                        segsList.push(getSegmentSplitObj(seg));
                                    }
                                } else {
                                    validate = false;
                                    validateGds();
                                }
                            } else {
                                validate = false;
                                validateGds("airline");
                            }
                        }
                    }
                }
            }
            let paxList = [];
            var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
            if(ticketsInfo!==null && ticketsInfo.length.length !== 0) {
                for (let pax of ticketsInfo) {
                    for (let val of pax) {
                        if (state.bkng_det[0].flow_type === "normal") {
                            if (validatePax(val) && val.changed !== undefined && val.changed) {
                                const obj = {
                                    "paxid": val.trv_op_id,
                                    "tst": val.sts,
                                    "tnum": val.tkt_no
                                }
                                paxList.push(obj);
                            } else {
                                validate = false;
                            }
                        } else {
                            if (val.tid === onwardTid) {
                                if (validatePax(val) && val.changed !== undefined && val.changed) {
                                    const obj = {
                                        "paxid": val.trv_op_id,
                                        "tst": val.sts,
                                        "tnum": val.tkt_no
                                    }
                                    paxList.push(obj);
                                } else {
                                    validate = false;
                                }
                            }
                        }
                    }
                }
            }
            var payload = "";
            if (validate) {
                if (state.bkng_det[0].flow_type === "normal") {
                    payload = {
                        "booking_id": state.bkng_det[0].booking_id,
                        "bst": state.updateBstatus,
                        "upby": context.logindata.uid,
                        "bkngdetails": [{
                            "tid": state.bkng_det[0].tid,
                            "pax": paxList,
                            "segs": segsList
                        }]
                    }
                } else if (state.bkng_det[0].flow_type === "split") {
                    payload = {
                        "booking_id": state.bkng_det[0].booking_id,
                        "bst": state.updateBstatus,
                        "upby": context.logindata.uid,
                        "bkngdetails": [{
                            "tid": onwardTid,
                            "pax": paxList,
                            "segs": segsList
                        }]
                    }
                }
                processEditBooking(payload).then((resp) => {
                    dispatch({ type: 'showEditpop', payload: false })
                    localStorage.removeItem(EDIT_DATA);
                    localStorage.removeItem(EDIT_TICKET_DATA);
                    if (resp.suc) {
                        validateEditBooking(true);
                    } else {
                        validateEditBooking(false);
                    }
                })
            }
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
        }
    }

    //Handle Edit Booking flow
    const processEditBookingSplitReturn = () => {
        let segsList = [];
        let validate = true; var returnTid = "";
        let value = false;
        //Checking Airline and GDS PNRS
      var data = JSON.parse(localStorage.getItem(EDIT_DATA));
      if(data!==null){
       for(let seg1 of state.seg_det){
       for(let seg2 of data){
        if(Number(seg1.segid) === Number(seg2.segid)){
         if(seg2.changed!==undefined && seg2.changed){
           seg1.changed=true;
           seg1.normal_airline_pnr=seg2.normal_airline_pnr;
           seg1.normal_gds_pnr=seg2.normal_gds_pnr;
         }
        }
       }
      }
      }
        if (state.bkng_det[0].booking_status === "34") {
            if (state.updateBstatus === "Partially Ticketed") {
                value = true;
            }
        } else {
            value = checkBookingStatus();
        }
        if (value) {
            let tidArray = state.bkng_det[0].tid.split("/");
            returnTid = tidArray[1];
            if (state.seg_det.length !== 0) {
                for (let seg of state.seg_det) {
                    if (seg.tid === returnTid) {
                        if (seg.split_airline_pnr !== undefined && seg.split_airline_pnr !== "") {
                            if (seg.split_gds_pnr !== undefined && seg.split_gds_pnr !== "") {
                                if (seg.changed !== undefined && seg.changed) {
                                    segsList.push(getSegmentSplitObj(seg));
                                }
                            } else {
                                validate = false;
                                validateGds();
                            }
                        } else {
                            validate = false;
                            validateGds("airline");
                        }
                    }
                }
            }
            let paxList = [];
            var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
            if(ticketsInfo!==null && ticketsInfo.length.length !== 0) {
                for (let pax of ticketsInfo) {
                    for (let val of pax) {
                        if (val.tid === returnTid && val.changed !== undefined && val.changed) {
                            if (validatePax(val)) {
                                const obj = {
                                    "paxid": val.trv_op_id,
                                    "tst": val.sts,
                                    "tnum": val.tkt_no
                                }
                                paxList.push(obj);
                            } else {
                                validate = false;
                            }
                        }
                    }
                }
            }

            if (validate) {
                const payload = {
                    "booking_id": state.bkng_det[0].booking_id,
                    "bst": state.updateBstatus,
                    "upby": context.logindata.uid,
                    "bkngdetails": [{
                        "tid": returnTid,
                        "pax": paxList,
                        "segs": segsList
                    }]
                }
                processEditBooking(payload).then((resp) => {
                    dispatch({ type: 'showEditpop', payload: false })
                    localStorage.removeItem(EDIT_DATA);
                    localStorage.removeItem(EDIT_TICKET_DATA);
                    if (resp.suc) {
                        validateEditBooking(true);
                    } else {
                        validateEditBooking(false);
                    }
                })
            }
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
        }
    }

    //Validate the Pax
    const validatePax = (pax) => {
        if (pax.tkt_no === undefined || pax.tkt_no === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please Enter the Ticket numbers.' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false
        } else if (pax.sts !== "Confirmed") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select proper Ticketing status' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false
        } else if (pax.tkt_no !== undefined && pax.tkt_no.length !== 14) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please provide valid Ticket number and include hyfen after ticketing Code' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false
        }
        return true;
    }

    const validateEditBooking = (suc) => {
        if (suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Booking details are updated Successfully' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            setTimeout(() => {
                return window.location = window.location.pathname;
            }, 2000)

        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Something went wrong.Booking details are not updated' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
        }
    }

    const dummy = () => {
        console.log("void popup hit")
    }

    const oncloseEidt = () => {
        dispatch({ type: 'showEditpop', payload: false })
    }
    /**
    * @description : When issue ticket is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
    const handleIssueTicket = () => {
        dispatch({ type: "splitissue", payload: true })
        dispatch({ type: "voidenable", payload: true }) //enable void
        dispatch({ type: "refundenable", payload: false }) //refund
        dispatch({ type: "enableVoidBooking", payload: false })
        dispatch({ type: "enableVoidCancel", payload: false })
    }

    /**
    * @description : When issue cancel is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
    const handleCancelBooking = () => {
        dispatch({ type: "splitCancel", payload: true }) //enabling cancel
        dispatch({ type: "voidenable", payload: true }) //enable void
        dispatch({ type: "refundenable", payload: false }) //refund
        dispatch({ type: "enableVoidBooking", payload: false })
        dispatch({ type: "enableVoidCancel", payload: false })
    }

    /**
    * @description : When refund ticket is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
    const handlerefundIssueTicket = () => {
        dispatch({ type: "splitissue", payload: true })
        dispatch({ type: "refundenable", payload: true })
        dispatch({ type: "voidenable", payload: false })
        dispatch({ type: "enableVoidBooking", payload: false })
        dispatch({ type: "enableVoidCancel", payload: false })

    }

    /**
    * @description : When issue cancel is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
    const handlerefundCancelBooking = () => {
        dispatch({ type: "splitCancel", payload: true })
        dispatch({ type: "refundenable", payload: true })
        dispatch({ type: "voidenable", payload: false })
        dispatch({ type: "enableVoidBooking", payload: false })
        dispatch({ type: "enableVoidCancel", payload: false })

    }

    const pnrFetch = (e) => {
        dispatch({ type: "splitPNR", payload: e })
    }
    /**
     * @description : cancel the pnr selected from SPLIT PNR
     * @author : Azamuddin
     * @date : 10-04-2021
     */
    const cancelSplit = () => {
        if (state.splitPNR) {
            dispatch({ type: 'isLoading', payload: true });
            // request json for cancelling the pnr
            let req_json = {
                "pnr": state.splitPNR,
                "booking_id": state.bkng_det[0].booking_id,
                "flowtype": state.voidenable ? "Void" : "Refund"
            }
            cancelledSplit(req_json).then(response => {
                if (response.data.suc) {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'PNR Cancelled successfully' })
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    hidemessage()
                    return window.location = window.location.pathname
                }
            })
        } else {
            dispatch({ type: 'isLoading', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one PNR to further proceed the cancellation' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }

    /**
     * @description : selection of dropdown from slpit booking
     * @author : Azamuddin
     * @date : 10-04-2021
     */
    const splitSplitDropdown = (type, status) => {
        if (type == "cancel") {
            dispatch({ type: "splitCancel", payload: true })
            dispatch({ type: "enableVoidBooking", payload: false })
            dispatch({ type: "enableVoidCancel", payload: false })
        }
        if (status) {
            dispatch({ type: "voidenable", payload: true })
        } else {
            dispatch({ type: "voidenable", payload: false })
        }
    }

    /**
     * @description : cancel button to diable SPLIT pnr checkboxes
     * @author : Azamuddin
     * @date : 14-04-2021
     */
    const cancelSplitAction = () => {
        dispatch({ type: "voidenable", payload: false })
        dispatch({ type: "refundenable", payload: false })
        dispatch({ type: "splitissue", payload: false })
        dispatch({ type: "splitCancel", payload: false })
    }
    const refundedTicketsSplit = (e) => {
        if (state.bkng_det[0].flow_type !== "normal") {
            if (state.ticketsRefunded.length === 0) {
                dispatch({ type: "ticketsRefunded", payload: e })
            }
        }
    }


    /**
     *
     * @param {*} data
     * @description:below function call when we click on Downloddocs
     * @author:Rambabu
     * @date:16-05-2021
     */
    const handledownloaddocs = (obj, i) => () => {
        let allpax = []
        for (let val in obj[1].pax_det) {
            let pax = obj[1].pax_det[val]
            if (val.length > 0) {
                for (let dub of pax) {

                    if (dub.split_pnr == null) {
                        allpax.push(dub)
                    } else {
                        if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                            allpax.push(dub)
                        }
                    }
                }
            }

        }
        let data = allpax
        let confirmedPax = []
        for(let val of data){
          if(val.stsid == 23){
            confirmedPax.push(val)
          }
        }
        if(confirmedPax.length == 0 ){
         dispatch({ type: 'showitineraryRadio', payload: false });
        }
        dispatch({ type: "seg_det", payload: obj[0].seg_det })
        dispatch({ type: "fare_det", payload: obj[2].fare_det })
        dispatch({ type: "selected_data", payload: obj })
        let cloned_data = _.cloneDeep(data);
        let VRdata = _.cloneDeep(cloned_data.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID') || obj.sts.toUpperCase().includes('REFUND'))));
        VRdata.map(obj => {
            obj.sts = 'Confirmed';
            obj.stsid = 23;
            obj.segst = 'Confirmed';
        })
        let final_data = cloned_data.concat(VRdata);
        final_data = _.orderBy(final_data, ['pax_type', 'is_lead_pax', 'pax_name'], ['asc', 'desc', 'asc']);
        dispatch({ type: 'allpopuppaxdata', payload: final_data });
        dispatch({ type: 'allpopuppaxticketeddata', payload: data });
        dispatch({ type: 'enabledownloaddocs', payload: true });

    }


    /**
     *
     * @description:below function call when we click on close button
     * @author:Rambabu
     * @date:16-05-2021
     */
    const oncloseCancel = () => {
        dispatch({ type: 'ticketed', payload: true })
        dispatch({ type: 'void', payload: false })
        dispatch({ type: 'refund', payload: false })
        dispatch({ type: 'invoice', payload: true })
        dispatch({ type: 'cinvoice', payload: false })
        dispatch({ type: 'itinerary', payload: false })
        dispatch({ type: 'downloadtype', payload: 'Invoice' })
        dispatch({ type: 'popupticketsarray', payload: [] })
        dispatch({ type: 'allpopuppaxdata', payload: [] })
        dispatch({ type: 'allpopuppaxticketeddata', payload: [] })
        dispatch({ type: 'showitineraryRadio', payload: true })
        dispatch({ type: 'changeStatus', payload: "" })
        dispatch({ type: 'popuppaxdata', payload: [] })
        dispatch({ type: 'popupticketsdata', payload: [] })
        dispatch({ type: 'enabledownloaddocs', payload: false })
        dispatch({ type: 'pupupmsg', payload: '' })
        dispatch({ type: 'popupallc', payload: false })
    }
    //For tabs selection
    const [refPriceTab, setRefPriceTab] = useState({
        active: "TA",
    });

    let invResp = require('../../../masterData/Hotel.json');

    /**
      *
      * @param {*} e
      * @description:below function call when we click on (Ticketed or Refund or Void)
      * @author:Rambabu
      * @date:17-05-2021
     */
    const selectedstatushandleChange = (e) => {
        dispatch({ type: 'popuppaxdata', payload: [] });
        dispatch({ type: 'pupupmsg', payload: 'No records found' });
        dispatch({ type: 'flightDocsData', payload: '' });
        dispatch({ type: 'popupticketsdata', payload: [] });

        if (e.target.id === "ticketed") {
            dispatch({ type: 'ticketed', payload: true })
            dispatch({ type: 'refund', payload: false })
            dispatch({ type: 'void', payload: false })

          } else if (e.target.id === "refund") {
            dispatch({ type: 'ticketed', payload: false })
            dispatch({ type: 'refund', payload: true })
            dispatch({ type: 'void', payload: false })

          } else if (e.target.id === "void") {
            dispatch({ type: 'ticketed', payload: false })
            dispatch({ type: 'refund', payload: false })
            dispatch({ type: 'void', payload: true })
          }

        if (e.target.id === "invoice") {
            dispatch({ type: 'downloadtype', payload: 'INVOICE' })
            dispatch({ type: 'invoice', payload: true })
            dispatch({ type: 'cinvoice', payload: false })
            dispatch({ type: 'itinerary', payload: false })

        } else if (e.target.id === "cinvoice") {
            dispatch({ type: 'downloadtype', payload: 'CREDIT NOTE' })
            dispatch({ type: 'invoice', payload: false })
            dispatch({ type: 'cinvoice', payload: true })
            dispatch({ type: 'itinerary', payload: false })

        } else if (e.target.id === "itinerary") {
            dispatch({ type: 'downloadtype', payload: 'ITINERARY' })
            dispatch({ type: 'invoice', payload: false })
            dispatch({ type: 'cinvoice', payload: false })
            dispatch({ type: 'itinerary', payload: true })

        }

    }


    /**
      * @description:below function call when we click on pax check box on popup
      * @author:Rambabu
      * @date:17-05-2021
     */
    const selectedpaxdataeventchange = (paxd) => (e) => {
        const { id, value } = e.target;
        if (state.popupticketsarray.indexOf(id) > -1) {
            const index = state.popupticketsarray.indexOf(id);
            if (index > -1) {
                state.popupticketsarray = state.popupticketsarray.filter(x => x !== id)
                state.popupticketsdata = state.popupticketsdata.filter(x => x !== paxd)
                dispatch({ type: 'popupallc', payload: false })
                dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
            }
        } else {
            state.popupticketsarray = [...state.popupticketsarray, id]
            state.popupticketsdata = [...state.popupticketsdata, paxd]
            dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
            if (state.popuppaxdata.length === state.popupticketsdata.length) {
                dispatch({ type: 'popupallc', payload: true })
            }
        }
        pdfdownloadonclick();
    }

    /**
      * @description:below function call when we click on pax check box on popup
      * @author:Rambabu
      * @date:17-05-2021
     */
    const selectedallpaxaeventchange = (e) => {
        if (e.target.checked && isArrayNotEmpty(state.popuppaxdata)) {
            dispatch({ type: 'popupallc', payload: true })
            dispatch({ type: 'popupticketsarray', payload: [] })
            dispatch({ type: 'popupticketsdata', payload: [] })
            for (let paxs in state.popuppaxdata) {
                let pax_d = state.popuppaxdata[paxs];
                state.popupticketsarray.push(pax_d.tkt_no + paxs);
                state.popupticketsdata.push(pax_d);
            }
            dispatch({ type: 'popupticketsarray', payload: state.popupticketsarray })
            dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })

        } else if (!e.target.checked) {
            state.popupticketsarray = [];
            state.popupticketsdata = [];
            dispatch({ type: 'popupticketsarray', payload: state.popupticketsarray })
            dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
            dispatch({ type: 'popupallc', payload: false })

        }
        pdfdownloadonclick();
    }



    /**
      * @description:below function call when click on Viewdetails button
      * @author:Rambabu
      * @date:18-05-2021
     */
    const docsViewDetailsonclick = () => {
        let poppaxdata = [];
        dispatch({ type: 'popupticketsarray', payload: [] })
        dispatch({ type: 'popupticketsdata', payload: [] })
        dispatch({ type: 'popupallc', payload: false })
        dispatch({ type: 'changeStatus', payload: "" })
        if (state.ticketed) {
            dispatch({ type: 'changeStatus', payload: "Confirmed" })
            if(state.itinerary){
            let tktdata = state.allpopuppaxticketeddata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('CONFIRMED')));
            poppaxdata = _.concat(poppaxdata, tktdata);
      }
      else{
            let tktdata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('CONFIRMED')));
            poppaxdata = _.concat(poppaxdata, tktdata);
         }
        }
        if (state.refund) {
            dispatch({ type: 'changeStatus', payload: "Cancelled" })
            let refunddata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('REFUND')));
            refunddata = refunddata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('FAILED')));
            poppaxdata = _.concat(poppaxdata, refunddata);
        }
        if (state.void) {
            dispatch({ type: 'changeStatus', payload: "Void" })
            let voiddata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID')));
            voiddata = voiddata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('FAILED')));
            poppaxdata = _.concat(poppaxdata, voiddata);
        }
        poppaxdata = _.orderBy(poppaxdata, ['pax_type', 'is_lead_pax', 'pax_name'], ['asc', 'desc', 'asc']);
        dispatch({ type: 'popuppaxdata', payload: poppaxdata });

        //if selected criteria dont have any records need to display no records found
        if (!isArrayNotEmpty(poppaxdata)) {
            dispatch({ type: 'pupupmsg', payload: 'No records found' });
        } else {
            dispatch({ type: 'pupupmsg', payload: '' });
        }

        //38-voidbookfail,40-voidcancelfail,43-refundbookfail,45-refundcancelfail
        if (!state.ticketed && !state.refund && !state.void) {
            let final_d = state.allpopuppaxdata.filter(obj => isNotNull(obj.stsid) && (Number(obj.stsid) !== 38 && Number(obj.stsid) !== 40 &&
                Number(obj.stsid) !== 43 && Number(obj.stsid) !== 45));

            //if selected criteria dont have any records need to display no records found
            if (!isArrayNotEmpty(final_d)) {
                dispatch({ type: 'pupupmsg', payload: 'No records found' });
            } else {
                dispatch({ type: 'pupupmsg', payload: '' });
            }
            dispatch({ type: 'popuppaxdata', payload: final_d });
        }

    }

    /**
     * @description:below function call when click on PDF Donload option
     * @author:Rambabu
     * @date:18-05-2021
    */
    const pdfdownloadonclick = () => {

        let voided_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID')));
        let refund_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('REFUND')));
        let ticketed_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('REFUND') && !obj.sts.toUpperCase().includes('VOID')));

        let com_data = [];
        let seg_details = [];
        if (isArrayNotEmpty(voided_data)) {
            com_data = voided_data;

        } else if (isArrayNotEmpty(refund_data)) {
            com_data = refund_data;

        } else if (isArrayNotEmpty(ticketed_data)) {
            com_data = ticketed_data;

        }
        if (isArrayNotEmpty(com_data)) {
            if (com_data[0].flow_type.toUpperCase() == 'NORMAL') {
                seg_details = state.seg_det;
            } else if (com_data[0].flow_type.toUpperCase() == 'SPLIT' && com_data[0].journey_type.toUpperCase() == 'O') {
                seg_details = state.seg_det.filter(obj => obj.journey_type === "O");
            } else if (com_data[0].flow_type.toUpperCase() == 'SPLIT' && com_data[0].journey_type.toUpperCase() == 'R') {
                seg_details = state.seg_det.filter(obj => obj.journey_type === "R");
            }
        }

        let void_fare_pax = []
        let void_paxdata = [];
        let tkt_fare_pax = []
        let tkt_paxdata = [];
        let refund_fare_pax = [];
        let refund_paxdata = [];
        let data = [];

        let final_obj = {}


        //If TA Tab selected
        if (isNotNull(refPriceTab.active) && refPriceTab.active == 'TA') {

            //voided checkbox--start
            if (isArrayNotEmpty(voided_data) && state.selected_data.length > 0 && state.selected_data[3].void_det && state.selected_data[3].void_det.length > 0 && (state.invoice || state.cinvoice)) {

                for (let vd of voided_data) {
                    let void_paxobj = {}
                    let v_tid = vd.tid
                    let void_faredata = state.selected_data[3].void_det.filter(obj => obj.tid === v_tid);
                    let agency_data = [];
                    //invoice radio button
                    if (state.invoice) {
                        agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'MTA') : [];
                    } //customer invoice radio button
                    else if (state.cinvoice) {
                        agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'TA') : [];
                    }
                    let void_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt_no === vd.tkt_no) : [];
                    if (isArrayNotEmpty(void_pax_faredata)) {
                        void_paxobj.paxname = void_pax_faredata[0].paxname;
                        void_paxobj.ptype = void_pax_faredata[0].ptype;
                        void_paxobj.tkt_no = void_pax_faredata[0].tkt_no;
                        void_paxobj.paid = void_pax_faredata[0].totalfare;
                        //invoice radio button
                        if (state.invoice) {
                            void_paxobj.voidfee = void_pax_faredata[0].attvoidservicefee;
                        } //customer invoice radio button
                        else if (state.cinvoice) {
                            void_paxobj.voidfee = void_pax_faredata[0].tavoidservicefee;
                        }
                        void_paxobj.total = void_pax_faredata[0].totalfare - void_pax_faredata[0].attvoidservicefee;
                        void_fare_pax.push(void_paxobj);
                    }
                }
            }
            //itinerary radio button
            if (state.itinerary) {
                void_paxdata = voided_data;
            }
            //voided checkbox--End


            //Ticketed check box---start
            if (isArrayNotEmpty(ticketed_data) && (state.invoice || state.cinvoice)) {

                for (let tkt of ticketed_data) {
                    let tkt_paxobj = {}
                    let tkt_tid = tkt.tid
                    let tkt_faredata = state.fare_det.filter(obj => obj.tid === tkt_tid);
                    let agency_data = isArrayNotEmpty(tkt_faredata) ? tkt_faredata[0].pasngrs.filter(obj => obj.type === 'TA') : [];
                    let tkt_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt === tkt.tkt_no) : [];
                    let tktpax_data = '';
                    //invoice radio button
                    if (state.invoice) {
                        tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].purchase : [];
                    } //customer invoice radio button
                    else if (state.cinvoice) {
                        tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].sales : [];
                    }
                    if (isNotNull(tktpax_data) && isArrayNotEmpty(tkt_pax_faredata)) {
                        tkt_paxobj.paxname = tkt_pax_faredata[0].paxname;
                        tkt_paxobj.ptype = tkt_pax_faredata[0].ptype;
                        tkt_paxobj.tkt_no = tkt_pax_faredata[0].tkt;
                        tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.supcharge;
                        //customer invoice radio button
                        if (state.cinvoice) {
                            tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.servicefee + tktpax_data.supcharge
                        }
                        tkt_paxobj.taxes = tkt_pax_faredata[0].taxes;
                        tkt_paxobj.servicefee = tktpax_data.servicefee;
                        tkt_paxobj.disc = tktpax_data.disc;
                        tkt_paxobj.total = tktpax_data.total;
                        tkt_fare_pax.push(tkt_paxobj);
                    }
                }

            }
            //itinerary radio button
            if (state.itinerary) {
                tkt_paxdata = ticketed_data;
            }
            //Ticketed check box---end

            //Refund checkbox ---started
            if (isArrayNotEmpty(refund_data) && state.selected_data.length > 0 && state.selected_data[4].refund_det && state.selected_data[4].refund_det.length > 0 && (state.invoice || state.cinvoice)) {

                for (let rf of refund_data) {
                    let journeytype = rf.journey_type;
                    let flowtype = rf.flow_type;
                    let refundpax_data = {};

                    refundpax_data = state.selected_data[4].refund_det;
                    if (isNotNull(refundpax_data) && isArrayNotEmpty(refundpax_data.psngrs)) {
                        for (let refpaxre of refundpax_data.psngrs) {
                            let refundpaxdata = refpaxre.tktnum.filter(obj => obj.number === rf.tkt_no);
                            if (isArrayNotEmpty(refundpaxdata)) {
                                let tktpaxrefudata = [];
                                let fare_used = 0;
                                //invoice radio button
                                if (state.invoice) {
                                    tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'ATT')
                                    fare_used = refundpaxdata[0].mta_fare_used;
                                } //customer invoice radio button
                                else if (state.cinvoice) {
                                    tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'TA');
                                    fare_used = refundpaxdata[0].ta_fare_used;
                                }
                                if (isArrayNotEmpty(tktpaxrefudata)) {
                                    let refund_paxobj = {};
                                    refund_paxobj.paxname = refundpaxdata[0].pname;
                                    refund_paxobj.ptype = refpaxre.ptype;
                                    refund_paxobj.tkt_no = refundpaxdata[0].number;
                                    refund_paxobj.paid = tktpaxrefudata[0].totalfare;
                                    refund_paxobj.charges = tktpaxrefudata[0].bkservice + tktpaxrefudata[0].aircharge +
                                        tktpaxrefudata[0].supcharge + tktpaxrefudata[0].canmarkup + tktpaxrefudata[0].canservice + tktpaxrefudata[0].ocharges + fare_used;
                                    refund_paxobj.total = tktpaxrefudata[0].totalfare - refund_paxobj.charges;
                                    refund_fare_pax.push(refund_paxobj);
                                }
                            }

                        }

                    }

                }
            }
            //itinerary radio button
            if (state.itinerary) {
                refund_paxdata = refund_data
            }
            //Refund checkbox ---End


        }//If TA Tab selected--End

        //If STA Tab selected--start
        if (isNotNull(refPriceTab.active) && refPriceTab.active == 'STA') {

            //voided checkbox--start
            if (isArrayNotEmpty(voided_data) && state.selected_data.length > 0 && state.selected_data[3].void_det && state.selected_data[3].void_det.length > 0 && (state.invoice || state.cinvoice)) {

                for (let vd of voided_data) {
                    let void_paxobj = {}
                    let v_tid = vd.tid
                    let void_faredata = state.selected_data[3].void_det.length.filter(obj => obj.tid === v_tid);
                    let agency_data = [];
                    //invoice radio button
                    if (state.invoice) {
                        agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'TA') : [];
                    } //customer invoice radio button
                    else if (state.cinvoice) {
                        agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'STA') : [];
                    }
                    let void_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt_no === v_tid.tkt_no) : [];
                    if (isArrayNotEmpty(void_pax_faredata)) {
                        void_paxobj.paxname = void_pax_faredata[0].paxname;
                        void_paxobj.ptype = void_pax_faredata[0].ptype;
                        void_paxobj.tkt_no = void_pax_faredata[0].tkt_no;
                        void_paxobj.paid = void_pax_faredata[0].totalfare;
                        //invoice radio button
                        if (state.invoice) {
                            void_paxobj.voidfee = void_pax_faredata[0].tavoidservicefee;
                        } //customer invoice radio button
                        else if (state.cinvoice) {
                            void_paxobj.voidfee = void_pax_faredata[0].stavoidservicefee;
                        }
                        void_paxobj.total = void_pax_faredata[0].totalfare - void_pax_faredata[0].attvoidservicefee;
                        void_fare_pax.push(void_paxobj);
                    }
                }

            }
            //itinerary radio button
            if (state.itinerary) {
                void_paxdata = voided_data;
            }
            //voided checkbox--end


            //Ticketed check box---start
            if (isArrayNotEmpty(ticketed_data) && (state.invoice || state.cinvoice)) {
                for (let tkt of ticketed_data) {
                    let tkt_paxobj = {}
                    let tkt_tid = tkt.tid
                    let tkt_faredata = state.fare_det.filter(obj => obj.tid === tkt_tid);
                    let agency_data = isArrayNotEmpty(tkt_faredata) ? tkt_faredata[0].pasngrs.filter(obj => obj.type === 'STA') : [];
                    let tkt_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt === tkt.tkt_no) : [];
                    let tktpax_data = '';
                    //invoice radio button
                    if (state.invoice) {
                        tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].purchase : [];
                    } //customer invoice radio button
                    else if (state.cinvoice) {
                        tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].sales : [];
                    }
                    if (isNotNull(tktpax_data) && isArrayNotEmpty(tkt_pax_faredata)) {
                        tkt_paxobj.paxname = tkt_pax_faredata[0].paxname;
                        tkt_paxobj.ptype = tkt_pax_faredata[0].ptype;
                        tkt_paxobj.tkt_no = tkt_pax_faredata[0].tkt;
                        tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.supcharge;
                        if (state.cinvoice) {
                            tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.servicefee + tktpax_data.supcharge
                        }
                        tkt_paxobj.taxes = tkt_pax_faredata[0].taxes;
                        tkt_paxobj.servicefee = tktpax_data.servicefee;
                        tkt_paxobj.disc = tktpax_data.disc;
                        tkt_paxobj.total = tktpax_data.total;
                        tkt_fare_pax.push(tkt_paxobj);
                    }
                }
            }
            //itinerary radio button
            if (state.itinerary) {
                tkt_paxdata = ticketed_data;
            }
            //Ticketed check box---end

            //Refund checkbox ---started
            if (isArrayNotEmpty(refund_data) && state.selected_data.length > 0 && state.selected_data[4].refund_det && state.selected_data[4].refund_det.length > 0 && (state.invoice || state.cinvoice)) {

                for (let rf of refund_data) {
                    let journeytype = rf.journey_type;
                    let flowtype = rf.flow_type;
                    let refundpax_data = {};
                    refundpax_data = state.selected_data[4].refund_det;

                    if (isNotNull(refundpax_data) && isArrayNotEmpty(refundpax_data.psngrs)) {
                        for (let refpaxre of refundpax_data.psngrs) {
                            let refundpaxdata = refpaxre.tktnum.filter(obj => obj.number === rf.tkt_no);
                            if (isArrayNotEmpty(refundpaxdata)) {
                                let tktpaxrefudata = [];
                                let fare_used = 0;
                                //invoice radio button
                                if (state.invoice) {
                                    tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'TA')
                                    fare_used = refundpaxdata[0].mta_fare_used;
                                } //customer invoice radio button
                                else if (state.cinvoice) {
                                    tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'STA');
                                    fare_used = refundpaxdata[0].ta_fare_used;
                                }
                                if (isArrayNotEmpty(tktpaxrefudata)) {
                                    let refund_paxobj = {};
                                    refund_paxobj.paxname = refundpaxdata[0].pname;
                                    refund_paxobj.ptype = refpaxre.ptype;
                                    refund_paxobj.tkt_no = refundpaxdata[0].number;
                                    refund_paxobj.paid = tktpaxrefudata[0].totalfare;
                                    refund_paxobj.charges = tktpaxrefudata[0].bkservice + tktpaxrefudata[0].aircharge +
                                        tktpaxrefudata[0].supcharge + tktpaxrefudata[0].canmarkup + tktpaxrefudata[0].canservice + tktpaxrefudata[0].ocharges + fare_used;
                                    refund_paxobj.total = tktpaxrefudata[0].totalfare - refund_paxobj.charges;
                                    refund_fare_pax.push(refund_paxobj);
                                }
                            }

                        }
                    }

                }
            }
            //itinerary radio button
            if (state.itinerary) {
                refund_paxdata = refund_data;
            }
            //Refund checkbox ---End

        }
        //If STA Tab selected--End

        //If void selected, and select the (invoice or cinvoice ) radiobutton selected
        if (isArrayNotEmpty(void_fare_pax)) {
            let void_det = { void_det: void_fare_pax }
            data.push(void_det);
        }
        //if void selected and itinerary radiobutton selected
        if (state.itinerary && isArrayNotEmpty(void_paxdata)) {
            let void_pax_det = { void_pax_det: void_paxdata }
            data.push(void_pax_det);
        }

        //If Ticketed selected, and select the (invoice or cinvoice ) radiobutton selected
        if (isArrayNotEmpty(tkt_fare_pax)) {
            let fare_det = { fare_det: tkt_fare_pax }
            data.push(fare_det);
        }

        //if Ticketed selected and itinerary radiobutton selected
        if (state.itinerary && isArrayNotEmpty(tkt_paxdata)) {
            let pax_det = { pax_det: tkt_paxdata }
            data.push(pax_det);
        }

        //If Refund selected, and select the (invoice or cinvoice ) radiobutton selected
        if (isArrayNotEmpty(refund_fare_pax)) {
            let refund_det = { refund_det: refund_fare_pax }
            data.push(refund_det);
        }
        //if refund selected and itinerary radiobutton selected
        if (state.itinerary && isArrayNotEmpty(refund_paxdata)) {
            let refund_pax_det = { refund_pax_det: refund_paxdata }
            data.push(refund_pax_det);
        }

        //constructing final json
        if (isArrayNotEmpty(data)) {
            if (isArrayNotEmpty(seg_details)) {
                let seg_det = { seg_det: seg_details }
                data.push(seg_det);
            }
            if(state.ticketed && state.downloadtype == "CREDIT NOTE"){
                final_obj.invoicetype = "CUST INVOICE";
               }
               else if(!state.ticketed && state.downloadtype == "INVOICE" ){
                final_obj.invoicetype = "CREDIT NOTE";
               }
               else if(!state.ticketed && state.downloadtype == "CREDIT NOTE" ){
                final_obj.invoicetype = "CUST CREDIT NOTE";
               }
               else{
                final_obj.invoicetype = state.downloadtype;
               }
            final_obj.type = refPriceTab.active;
            final_obj.isticketed = state.ticketed;
            final_obj.isrefund = state.refund;
            final_obj.isvoid = state.void;
            final_obj.data = data;
            dispatch({ type: 'flightDocsData', payload: final_obj });
        }


    }

    /**
     * @description : when splitted reissued selected then we call this function
     * @author: Azamuddin,Lakshmi
     * @date : 13-06-2021
     * @param {*} data -- consist of particular itenary data
     */
    //Call refund service to get the details
    const reissueSplitTickts = (data) => {
        dispatch({ type: 'process', payload: true });
        if (data && data.length !== 0) {
            //dispatch({type:"enableRSplitPopup",payload:false})
            //  setIsLoadingMore(true);
            let adts = [];
            let chds = [];
            let infs = [];
            let finalPax = [];
            let amd_ref_no = []
            let tid = ''
            let tktsData = Utilities.getUniqData(data);
            /** construction of pax selected  */
            if (tktsData !== undefined && tktsData.length !== 0) {
                adts = tktsData.filter(x => x.ty === "ADT");
                for (let adt of adts) {
                    finalPax.push(adt);
                    amd_ref_no.push(adt.amd_ref_num)
                    if (tid == '') {
                        tid = adt.tid
                    }
                }
                chds = tktsData.filter(x => x.ty === "CHD");
                if (chds !== undefined && chds !== 0) {
                    for (let chd of chds) {
                        finalPax.push(chd);
                        amd_ref_no.push(chd.amd_ref_num)
                        if (tid == '') {
                            tid = chd.tid
                        }
                    }
                }
                infs = tktsData.filter(x => x.ty === "INF");
                if (infs !== undefined && infs !== 0) {
                    for (let inf of infs) {
                        finalPax.push(inf);
                        amd_ref_no.push(inf.amd_ref_num)
                        if (tid == '') {
                            tid = inf.tid
                        }
                    }
                }
            }
            let seg_det = state.selected_data[0].seg_det
            let tagged_data = handleSplitDataConstructionforSelectedPax(props.segments, props.pax_det, amd_ref_no, tid)
            let splitSegs = []; //saves splitted segments
            for (let seg of seg_det) {
                const segpayload = {
                    "src": seg.seg_source,
                    "des": seg.seg_destination,
                    "fn": seg.flt_no ? seg.flt_no.toString() : "",
                    "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
                    "airpnr": seg.normal_airline_pnr,
                    "tid": seg.tid
                }
                splitSegs.push(segpayload)
            }
            if (tagged_data.seg_det.length > 0) {
                splitSegs = splitSegs.concat(tagged_data.seg_det)

            }
            if (tagged_data.pax_det.length > 0) {
                finalPax = finalPax.concat(tagged_data.pax_det)
            }
            let tidselected = seg_det[0].tid
            if (tagged_data.tids.length > 0) {
                for (let eachtid of tagged_data.tids) {
                    tidselected = tidselected + "/" + eachtid
                }
            }
            let pnr = seg_det[0].normal_gds_pnr
            if (pnr.includes(",")) {
                let pnrarray = seg_det[0].normal_gds_pnr.split(",")
                pnr = pnrarray[0]
            }
            const payload = {
                "tid": tidselected, //tid
                "pnr": pnr, //pnr
                "booking_id": state.bkng_det[0].booking_id, //booking id
                "tkts": finalPax, //pax selected
                "journey_type": (state.journey_type == "O" || state.journey_type == "") ? "Onward" : "Return", //journey type
                "split_segs": splitSegs, //splitted segments
                "bktype": "IMPORT/SPLIT",
                "unticketTag": props.unticketTag
            }
            getReissueSplitResp(payload).then((resp) => {
                if (resp.suc && resp.data !== undefined) {
                    dispatch({ type: "enableRSplitPopup", payload: false })
                    dispatch({ type: 'splitReissueResp', payload: resp }) //response data saved
                    // setIsLoadingMore(false);
                    dispatch({ type: 'process', payload: false });
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Split Of PNR is successful' })
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    setTimeout(() => {
                        return window.location = window.location.pathname
                    }, [1500])
                } else {
                    //  setIsLoadingMore(false);
                    dispatch({ type: 'process', payload: false });
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage()
                }
            })
        } else {
            //  setIsLoadingMore(false);
            dispatch({ type: 'process', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }

    /**
     * @description : this function handled cancel booking check box when refund is enabled
     * @author : Azamuddin
     * @date : 15-06-2021
     * @param {*} evt
     */
    // refund changes to handle can be done here
    const handlerefChange = evt => {
        const { id, value } = evt.target;
        let isbook = false;
        if (state.isCancelBooking) { //tag for cancel booking
            dispatch({ type: "isCancelBooking", payload: false })
            isbook = false;
        } else {
            dispatch({ type: "isCancelBooking", payload: true })
            isbook = true;
        }
    }

    /**
     * @description :cancel booking function calls
     * the function cn only be call for normal booking or onward FBF
     * @author : Azamuddin
     * @date : 28-05-2021
     * @functions : cancelBookPopup,handleCancelBookFlowReturn,handleCancelBookingFlow
     */
    //cancel pop up call for onward or normal booking
    const cancelBookPopup = (data, idx) => () => {
        dispatch({ type: "cancelPopup", payload: true })
        dispatch({ type: "selected_data", payload: data })
    }
    const clearPopList = () => {
        return window.location = window.location.pathname
    }

    const constructFareDetails = (data) => {
        let all_tkts = []
        let split_tickets = []
        let pax_det = data[1].pax_det
        let fare_det = data[2].fare_det
        if (pax_det.length > 0) {
            for (let val in pax_det) {
                let pax = pax_det[val]
                if (val.length > 0) {
                    for (let dub of pax) {
                        // allpax.push(val)
                        if (dub.split_pnr == null) {
                            all_tkts.push(dub.tkt_no)
                        } else {
                            if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "40" || dub.stsid === "42" || dub.stsid === "43" || dub.stsid === "44" || dub.stsid === "45" || dub.stsid === "38") {
                                all_tkts.push(dub.tkt_no)
                            } else {
                                split_tickets.push(dub.tkt_no)
                            }
                        }
                    }
                }

            }
        }

        if (split_tickets.length > 0) {
            for (let val in fare_det) {
                let each_pax = fare_det[val]
                for (let pax in each_pax.pasngrs) {
                    let data_track = each_pax.pasngrs[pax]
                    if (data_track.pax_split == undefined) {
                        data_track.pax_split = data_track.pax.filter(s => split_tickets.includes(s.tkt))
                    }
                    data_track.pax = data_track.pax.filter(s => all_tkts.includes(s.tkt))

                }
            }
        }
        return fare_det
    }

    /**
  * @author : Azamuddin
  * @param {consist of seg details} seg_det
  * @param {consist of pax details} pax_det
  * @param {amadeus_refno} amdno
  * @param {*} tid
  * @returns "pax_det": [],
             "seg_det": [],
             "tids" : []
  * @date : 30-07-2021
  */
    const handleSplitDataConstructionforSelectedPax = (seg_det, pax_det, amdno, tid) => {
        //data construction for split
        let data_to_return = {
            "pax_det": [],
            "seg_det": [],
            "tids": []
        }

        if (pax_det.length > 0) {
            let allpax = []; //pax with same amd ref num is saved here
            let allseg = []
            let tids = []
            for (let initPax of pax_det) {
                //****condition for pax details*******/
                for (let eachPax of initPax) {
                    let amdTag = false
                    for (let amd of amdno) {
                        if (amd == eachPax.amedeus_ref_num) {
                            amdTag = true
                        }
                    }
                    if (eachPax.tid !== tid && amdTag && eachPax.split_pnr == null && eachPax.stsid == "23") {
                        let obj = { //structure req for the split of pax
                            "nm": eachPax.pax_name,
                            "tkt": eachPax.tkt_no,
                            "ty": eachPax.pax_type,
                            "paxsno": eachPax.paxsno,
                            "amd_ref_num": eachPax.amedeus_ref_num ? eachPax.amedeus_ref_num : "",
                            "tid": eachPax.tid
                        }
                        allpax.push(obj)
                        tids.push(eachPax.tid) //tid for each pax which have amadeus ref num
                    }
                }
            }

            if (props.all_split_pax.length > 0) { //reissued all pax details
                for (let eachPax of props.all_split_pax) {
                    let amdTag = false
                    for (let amd of amdno) {
                        if (amd == eachPax.amedeus_ref_num) {
                            amdTag = true
                        }
                    }
                    if (eachPax.tid !== tid && amdTag && eachPax.split_pnr == null && eachPax.stsid == "23") {
                        let obj = { //structure req for the split of pax
                            "nm": eachPax.pax_name,
                            "tkt": eachPax.tkt_no,
                            "ty": eachPax.pax_type,
                            "paxsno": eachPax.paxsno,
                            "amd_ref_num": eachPax.amedeus_ref_num ? eachPax.amedeus_ref_num : "",
                            "tid": eachPax.tid
                        }
                        allpax.push(obj)
                        tids.push(eachPax.tid) //tid for each pax which have amadeus ref num
                    }
                }
            }
            if (tids.length > 0) {
                //handle seg details for the respective tids and
                let uniqTid = uniqueJson(tids)
                let seg_details = seg_det.concat(props.all_split_segments) //reissued segment details
                for (let seg of seg_details) {
                    for (let eachTid of uniqTid) {
                        if (eachTid == seg.tid) {
                            const segpayload = {
                                "src": seg.seg_source,
                                "des": seg.seg_destination,
                                "fn": seg.flt_no ? seg.flt_no.toString() : "",
                                "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
                                "airpnr": seg.normal_airline_pnr,
                                "tid": seg.tid
                            }
                            allseg.push(segpayload)
                        }
                    }
                }
            }
            data_to_return.pax_det = allpax
            data_to_return.seg_det = allseg
            data_to_return.tids = tids
            return data_to_return
        }
        //iteration for each pax element
        //***---- conditions to be checked if the pax doesnot consist of split pnr status to be confirmed and amadeus_ref_num

    }
    const enableSplitPopup = (data) => {
        dispatch({ type: 'enableRSplitPopup', payload: true })
        dispatch({ type: "enableSplitReissueO", payload: data })
    }

    /**
 * @author : Azamuddin
 * @description : validate if tickets enable for further oid cancellation
 */

    const validateVoidSplit = (voidData) => {
        let return_value = false
        for (let eachData of voidData) {
            if (eachData.sts == "31") {
                return_value = true
            }
        }
        return return_value
    }

    /**
     * @author : Azamuddin
     * @description : validate if tickets enable for further oid cancellation
     */

    const validateRfundSplit = (refundData) => {
        let return_value = false
        for (let eachData of refundData) {
            if (eachData.sts == "42") {
                return_value = true
            }
        }
        return return_value
    }
    const handleShare=(itinery, idx)=>{
        const splitted = itinery[1].pax_det[0].filter(st=>st.split_pnr == null)
        props.openshareevent()
        props.sendTid(splitted[0].tid)
    }
    return (
        <>
            {state.isLoading ? (<Processing onhold='yes' />
            ) : ""}
            {isLoadingMore ? (<ProcessingReissue tag={state.enableReissue ? "REISSUE" : "REFUND"} />
            ) : ""}
            <>
                {state.notiMessageShow &&
                    <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
                }
                {isArrayNotEmpty(props.bookingData) && isArrayNotEmpty(state.resissued_data) &&
                    <div className="confirmation">
                        {state.resissued_data !== undefined && state.resissued_data.map((val, index) => (
                            <React.Fragment key={index}>
                                {val.map((obj, i) => (
                                    <React.Fragment key={i}>

                                        <>
                                            <div className="resultSection boxShadow bg-white PassengerDetails mb-4 ">
                                            <div  className="flightdBg">
                                                <ShowHide visible="true" icon="" title="Flight  Details ">
                                                    <div className="showHide-content pl-0 pr-0 pb-0">
                                                        {obj.map((itinarary, idx) => (
                                                            <React.Fragment key={idx}>
                                                                {itinarary.seg_det !== undefined && itinarary.seg_det.length !== 0 &&
                                                                    <>


                                                                        <div className="clickTOShow  d-flex">
                                                                        {state.showitiarray.includes(i) &&<div className='socialIcons'>
                                                                        <Button className="prient ezyIcon icon-share" onClick={()=> {handleShare(obj, i)}} variant="link"></Button>
                                                                        </div>}

                                                                            <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openMOre(i)}>

                                                                            <div className={"showDetails " + (editMore && i + 1 === state.openClickedIndex ? 'active' : 'inactive')}>
                                                                                <ul className="d-flex flex-column">
                                                                                    {validConfirmScenario(obj, i) &&
                                                                                        <>
                                                                                            {context.logindata.roles.includes('B_S_V_B') &&
                                                                                                <li>
                                                                                                    <Link to="#" onClick={handleVoidBooking(obj, i)}>Void Ticket</Link>
                                                                                                </li>
                                                                                            }
                                                                                            {context.logindata.roles.includes('B_S_V_C') &&
                                                                                                <li>
                                                                                                    <Link to="#" onClick={handleVoidCancel(obj, i)}>Void/Cancel</Link>
                                                                                                </li>
                                                                                            }
                                                                                            {props.refund && context.logindata.roles.includes('B_S_R_T') && (props.bookingData[0].booking_status == "23" || (state.bktype === 'IMPORT' || state.bktype === 'IMPORT/REISSUE' || state.bktype === 'IMPORT/SPLIT')) &&
                                                                                                <li>
                                                                                                    <Link to="#" onClick={handleRefundTicket(obj, i)}>Refund Ticket</Link>
                                                                                                </li>
                                                                                            }
                                                                                            {context.logindata.roles.includes('B_S_T_R') && (props.bookingData[0].booking_status == "23" || (state.bktype === 'IMPORT' || state.bktype === 'IMPORT/REISSUE' || state.bktype === 'IMPORT/SPLIT')) &&
                                                                                                <li>
                                                                                                    <Link to="#" onClick={handleReissueTicket(obj, i)}>Reissue Ticket</Link>
                                                                                                </li>
                                                                                            }
                                                                                            {((state.bkng_det[0].booking_status == "23" && context.logindata.roles.includes('C_B_F_C')) || (state.bkng_det[0].booking_status == "22" && context.logindata.roles.includes('C_B_F_O'))) &&
                                                                                                <li>
                                                                                                    <Link to="#" onClick={cancelBookPopup(obj, i)}>Cancel Booking</Link>
                                                                                                </li>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    <li>
                                                                                        <Link to="#" onClick={handledownloaddocs(obj, i)}>Download Docs</Link>
                                                                                        {/* <Link to="#" onClick={() => handledownloaddocs(state.pax_det[0])} >Download Docs new</Link> */}
                                                                                    </li>

                                                                                </ul>
                                                                            </div>  </span></div>
                                                                        <FlightSummarySectorHeader
                                                                            data={itinarary.seg_det}
                                                                            airport_data={props.airportdata}
                                                                            triptype={props.triptype}
                                                                            isEdit={false}
                                                                            sectorTab={"IMPORT"}
                                                                            supplier={props.supplier}
                                                                        />
                                                                        {props.triptype !== "3" && (
                                                                            <>
                                                                                <FlightSummarySegmentDetails
                                                                                    data={itinarary.seg_det}
                                                                                    airport_data={props.airportdata}
                                                                                    isEdit={false}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </>
                                                                }
                                                                {/* </div> */}
                                                                {itinarary.pax_det !== undefined && itinarary.pax_det.length !== 0 &&
                                                                    <>
                                                                        <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1" >
                                                                            {state.index_selected === i ? (
                                                                                <div ref={voidCncl}><h5 className="title mb-2 mt-2"  >Passenger Details</h5></div>) : (<div><h5 className="title mb-2 mt-2" >Passenger Details</h5></div>)}

                                                                            <FlightSummaryPaxDetails pax_det={itinarary.pax_det !== undefined && [itinarary.pax_det[0].filter(st => (st.split_pnr == null || st.stsid === "31" || st.stsid === "39" || st.stsid === "40" || st.stsid === "42" || st.stsid === "43" || st.stsid === "44" || st.stsid === "45" || st.stsid === "38"))]}
                                                                                enableVoidBooking={state.index_selected === i && state.enableVoidBooking ? true : false}
                                                                                enableVoidCancel={state.index_selected === i && state.enableVoidCancel ? true : false}
                                                                                tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled}
                                                                                disableCheckbox={state.disableCheckbox} voidTickets={voidBookReq}
                                                                                enableRefundTkt={state.index_selected === i && state.enableRefund ? true : false}
                                                                                sendRefund={continueRefund} bookDetails={state.bkng_det[0]}
                                                                                refundAvailable={state.index_selected === i && state.refundAvail ? true : false} enableReisueTkt={state.index_selected === i && state.enableReissue ? true : false}
                                                                                sendReissueTickts={reissueTickts} reissueMsg={state.atcmsg} bookingId={props.bookingData[0].booking_id} flowType={props.bookingData[0].flow_type}
                                                                                isEdit={state.enableEdit} sendCancelBook={cancelBookUpdate} hideReturn={state.hideReturn}
                                                                                sendReissueSplit={enableSplitPopup} tag={"IMPORT"} tickestSelected={handlevShow} />
                                                                        </div>
                                                                        <Form.Row>
                                                                            {state.index_selected === i && state.enableRefund && state.flow_type == "split" &&
                                                                                <Col xs={12} className="d-flex refundBotBlock">
                                                                                    {state.index_selected === i && state.enableRefund && (
                                                                                        <RefundType
                                                                                            type={"Onward"}
                                                                                            ticketsList={voidTicket}
                                                                                            bookDetails={props.bookingData}
                                                                                            sendRefund={continueRefund}
                                                                                        />
                                                                                    )}
                                                                                    {state.index_selected === i && state.enableRefund && (
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            label="Cancel Booking"
                                                                                            custom
                                                                                            id="bkR"
                                                                                            value={state.isCancelBooking}
                                                                                            onChange={handlerefChange}
                                                                                        />
                                                                                    )}
                                                                                </Col>
                                                                            }
                                                                        </Form.Row>


                                                                        {/* reissued split pnr */}
                                                                        {itinarary.pax_det[0].filter(s => s.split_pnr && s.stsid == "23").length > 0 &&
                                                                            <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1">
                                                                                <h5 className="title mb-2 mt-2"> Split PNR</h5>
                                                                                <SplitReissuePNR paxList={itinarary.pax_det !== undefined && itinarary.pax_det[0]} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }


                                                                {/* fare details */}
                                                                {itinarary.fare_det !== undefined && itinarary.fare_det.length !== 0 &&
                                                                    <div className="resultSection boxShadow bg-white PassengerDetails pb-0 m-0">
                                                                        <h5 className="title mb-2 d-flex justify-content-between">Fare Details ({props.userCurrency})
                                                                            <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openeeditPayment}>
                                                                            </span></h5>
                                                                        <FlightSummaryFareDetails faredata={itinarary.fare_det !== undefined && constructFareDetails(obj)} bookedTo={"TEST"} bktype={"TEST"} supplier={props.supplier} bk_region_type={props.bk_region_type} userCurrency={props.userCurrency}/>
                                                                    </div>

                                                                }
                                                                {/* void_det_split */}

                                                                {isNotNull(itinarary.void_det) && isArrayNotEmpty(itinarary.void_det) &&
                                                                    <VoidSummaryDetails flowType={state.bkng_det[0].flow_type} voidFareResp={itinarary.void_det[0]} />}

                                                                {/* void split pnr */}
                                                                {isNotNull(state.flow_type) && isNotNull(state.bktype) && isArrayNotEmpty(itinarary.void_det_split) &&
                                                                    <div className="resultSection boxShadow bg-white PassengerDetails  mb-4">
                                                                        <h5 className="title d-flex justify-content-between">Void Split PNR {validateVoidSplit(itinarary.void_det_split) && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitMOre}>
                                                                            <div className={"showDetails " + (editvoidMore ? 'active' : 'inactive')}>
                                                                                <ul className="d-flex flex-column">
                                                                                    {/* <li>
                                                                                        <Link to="#" onClick={handleIssueTicket}>Issue Ticket</Link>
                                                                                    </li> */}

                                                                                    {itinarary.void_det_split.length !== 0 && <li>
                                                                                        <Link to="#" onClick={handleCancelBooking}>Cancel Booking</Link>
                                                                                    </li>}

                                                                                </ul>
                                                                            </div>
                                                                        </span>}  </h5>
                                                                        {isArrayNotEmpty(itinarary.void_det_split) && (<SplitPnr res={itinarary.void_det_split} type={"Void"} voidEnable={state.voidenable} pnr={pnrFetch} />)}
                                                                    </div>
                                                                }

                                                                {/* refund details */}

                                                                {itinarary.refund_det && itinarary.refund_det.length > 0 &&
                                                                    <FlightSummaryRefund refundResp={itinarary.refund_det[0]} bookedTo={state.bkng_det[0].buser_type} />}

                                                                {/* Displaying Refund Split PNR Details */}
                                                                {itinarary.refund_det_split && itinarary.refund_det_split.length > 0 &&
                                                                    <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                                                                        <h5 className="title d-flex justify-content-between">Refund Split PNR{validateRfundSplit(itinarary.refund_det_split) && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitRefundMOre}>
                                                                            <div className={"showDetails " + (editrefundMore ? 'active' : 'inactive')}>
                                                                                <ul className="d-flex flex-column">
                                                                                    {/* <li>
                                                                                        <Link to="#" onClick={handlerefundIssueTicket}>Issue Ticket</Link>
                                                                                    </li> */}

                                                                                    <li>
                                                                                        <Link to="#" onClick={handlerefundCancelBooking}>Cancel Booking</Link>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                        </span>}</h5>
                                                                        {isNotNull(itinarary.refund_det_split) && isArrayNotEmpty(itinarary.refund_det_split) &&
                                                                            <SplitPnr res={itinarary.refund_det_split} type={"Refund"} refundEnable={state.refundenable} pnr={pnrFetch} />
                                                                        }
                                                                    </div>}
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </ShowHide>
                                                </div>
                                            </div>
                                        </>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                }
                {show && (state.enableVoidBooking || state.enableVoidCancel) &&
                    <Modal
                        show={show}
                        onHide={handleClose}
                        className="fairPopUp"
                        backdrop="static"
                        keyboard={false}
                        size="lg"

                    >
                        <Modal.Body className="pb-2">
                            <h5>Fare Details</h5>
                            <div className="header">
                                <Row>
                                    <Col xs="3">Traveller</Col>
                                    <Col xs="3">Ticket Numbers</Col>
                                    <Col xs="2">Fare</Col>
                                    <Col xs="2">Void Fee</Col>
                                    <Col xs="2" className="text-right">Refund Amount</Col>
                                </Row>
                            </div>
                            <div className="passengerList">
                                {isArrayNotEmpty(state.tkts) && state.tkts.map((obj, ind) => (
                                    <React.Fragment key={ind}>
                                        <Row className="pList">
                                            <Col xs="3"><span>{obj.pn} {obj.lead ? "(Lead)" : ""}</span></Col>
                                            <Col xs="3"><span>{obj.tkt}</span></Col>
                                            <Col xs="2"><span>QAR {obj.tf ? obj.tf.toFixed(2) : ''}</span></Col>
                                            <Col xs="2"><span>QAR {obj.sf ? obj.sf.toFixed(2) : '0'}</span></Col>
                                            <Col xs="2" className="text-right"><span>QAR {obj.ra ? obj.ra.toFixed(2) : ''}</span></Col>
                                        </Row>
                                    </React.Fragment>

                                ))}
                                <div className="total d-flex justify-content-end mb-2">
                                    <span className="mr-2">Refund Total : </span> <strong > QAR {state.rfAmount ? state.rfAmount.toFixed(2) : ''}</strong>
                                </div>

                            </div>
                        </Modal.Body>
                        {state.voidSelectedLength !== state.allTktslength &&
                            <div className="infoSec">
                                <Alert variant="info">
                                    <span className="ezyIcon icon-info"></span> Selected Passenger will now split as all Passenger in the PNR are not selected.
                                </Alert></div>
                        }
                        <Modal.Footer>
                            {state.voidLoading ? ('') : <Button variant="secondary" className={"d-flex loading " + (state.voidLoading ? ' deseble progress-bar-striped progress-bar-animated' : '')} onClick={handleClose}>
                                Cancel
                            </Button>}
                            {/* <Button variant="outline-secondary" className={"d-flex loading " + (state.voidLoading ? ' deseble progress-bar-striped progress-bar-animated' : '')} onClick={handleClose}>
                          Cancel
                         </Button> */}
                            {state.enableVoidBooking &&
                                <Button disable={state.voidLoading} className={"d-flex loading " + (state.voidLoading ? 'progressIn progress-bar-striped progress-bar-animated' : '')} variant="primary" onClick={!state.voidLoading ? setVoid : dummy}>{!state.voidLoading ? "Void" : "Process..."}</Button>
                            }
                            {state.enableVoidCancel &&
                                <Button variant="primary" disable={state.voidLoading} className={"d-flex loading " + (state.voidLoading ? 'progressIn progress-bar-striped progress-bar-animated' : '')} onClick={!state.voidLoading ? setCancelVoid : dummy}>{!state.voidLoading ? "Void" : "Process..."}</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                }

                { /*cancel booking  */}
                {state.cancelPopup &&
                    <Modal
                        show={state.cancelPopup}
                        onHide={handleClose}
                        onClick={handleClose}
                        className="importPNRMdl requiredFare"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <div className="popUplayout center">
                                <div className="icons warning"></div>
                                <h4 className="mt-3 mb-3">Are you sure?</h4>
                                <div className="message">
                                    You want to cancel the booking
                                </div>
                            </div>

                        </Modal.Header>

                        <Modal.Footer className="justify-content-center">
                            <Button
                                size="xs"
                                variant="outline-primary"
                                onClick={handleClose}
                                type="submit"
                            >No</Button>
                            <Button
                                size="xs"
                                variant="primary"
                                onClick={() => handleCancelBookingFlow()}
                                type="submit"
                            >Yes</Button>

                        </Modal.Footer>
                    </Modal>
                }

                {state.enableRSplitPopup &&
                    <Modal
                        show={state.enableRSplitPopup}
                        onHide={handleClose}
                        className="importPNRMdl "
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <div className="popUplayout center">
                                <div className="icons warning"></div>
                                <h4 className="mt-3 mb-3">Are you sure?</h4>
                                <div className="message">
                                    As all passengers are not selected ,we will split the selected pax now.
                                    Please click on yes to continue.
                                </div>
                            </div>

                        </Modal.Header>

                        <Modal.Footer className="justify-content-center">
                            {state.process ?
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    className="pl-4 pr-4 mb-2"
                                    type="submit"
                                >Processing..</Button> :
                                <> <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    className="pl-4 pr-4 mb-2"
                                    onClick={clearPopList}
                                    type="submit"
                                >Cancel</Button>
                                    <Button
                                        size="xs"
                                        variant="outline-primary"
                                        className="pl-4 pr-4 mb-2"
                                        onClick={() => (reissueSplitTickts(state.enableSplitReissueO))}
                                        type="submit"
                                    >Yes</Button></>}




                        </Modal.Footer>
                    </Modal>
                }

                {/*if void ticket or cancel clicked for split reissue  */}
                {/* {(state.enableVoidBooking || state.enableVoidCancel) &&
                    <div className="text-right buttonGrop border-0 pt-0">
                        {!state.loader ? <Button
                            size="xs"
                            variant="outline-secondary"
                            type="submit"
                            onClick={cancelCheckBoxes}
                        > Cancel</Button> : null}
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={handlevShow}
                        > {!state.loader ? "Continue" : "Process..."} </Button>

                    </div>
                } */}
                {(state.splitissue || state.splitCancel) &&
                    <div className="text-right buttonGrop border-0 pt-0">
                        {state.isLoading ?
                            ' ' : <Button
                                size="xs"
                                variant="outline-secondary"
                                type="submit"
                                onClick={cancelSplitAction}
                            > Cancel</Button>
                        }
                        {state.isLoading ? <Button
                            size="xs"
                            variant="outline-secondary"
                            className="progressIn"
                            type="submit"
                        // onClick={cancelEdit}
                        > Process...</Button> : <>
                            {state.splitissue &&
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    type="submit"
                                // onClick={cancelEdit}
                                > Continue</Button>
                            }

                            {state.splitCancel &&
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    type="submit"
                                    onClick={cancelSplit}
                                > Continue</Button>

                            }
                        </>
                        }
                    </div>
                }



                {/* For incomplete booking this button will be enable*/}
                {state.enableEditBooking &&
                    <div className="text-right buttonGrop border-0 pt-0">
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            type="submit"
                            onClick={cancelEdit}
                        > Cancel</Button>
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={callEditBooking}
                        > Update</Button>
                    </div>
                }



                {state.showEditpop &&
                    <Modal
                        show={state.showEditpop}
                        onHide={oncloseEidt}
                        className="importPNRMdl"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton className="border-bottom-0">
                            <div className="popUplayout center">
                                <div className="icons warning"></div>
                                <strong>Are you sure! You want to proceed with the Edit Booking? </strong>

                                <p className="text-center d-flex mb-2"><span className="text-secondary mr-1 "> NOTE : </span> Please cross check all GDS PNR's Should be same.</p>
                            </div>

                        </Modal.Header>
                        <Modal.Footer className="justify-content-center border-top-0 pb-4">
                            <Button
                                size="xs"
                                variant="secondary"
                                onClick={oncloseEidt}
                                type="submit"
                            >No</Button>
                            <Button
                                size="xs"
                                variant="primary"
                                onClick={processEditBookingFlow}
                                type="submit"
                            >Yes</Button>
                        </Modal.Footer>
                    </Modal>
                }

                {/* Download Docs popup--start */}
                {state.enabledownloaddocs &&
                    <Modal
                        show={state.enabledownloaddocs}
                        onHide={oncloseCancel}
                        className="downloadInvoice"
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton className="pb-0"><h5>Download Itinerary/Invoice</h5></Modal.Header>
                        <Modal.Body className="pb-0 pt-0" >

                            <div className="tabLink tabList">
                                {context.logindata.utype === "att_user" &&
                                    <Tabs disable={true} active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                                        <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                                        <span key="TA">Traval Agent</span>
                                        {state.bkng_det[0].buser_type === "sta_user" &&
                                            <span key="STA">Sub Travel Agent</span>
                                        }
                                    </Tabs>
                                }
                                {context.logindata.utype === "ta_user" &&
                                    <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                                        <span className="alertList alerts d-flex align-items-center" key="TA">Traval Agent</span>
                                        {state.bkng_det[0].buser_type === "sta_user" &&
                                            <span key="STA">Sub Travel Agent</span>
                                        }
                                    </Tabs>
                                }
                                {context.logindata.utype === "sta_user" && state.bkng_det[0].buser_type === "sta_user" &&
                                    <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                                        <span className="alertList alerts d-flex align-items-center" key="STA">Sub Traval Agent</span>
                                    </Tabs>
                                }

                                <div className="tabContent">
                                    <div className="searchbyforDownload">
                                        <Form.Row>
                                            <Form.Group as={Col} xs={4} className="inputCheck">
                                                <Form.Label>Ticket Status<sup></sup></Form.Label>
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        type="radio"
                                                        id="ticketed"
                                                        label="Ticketed"
                                                        className="ml-0"
                                                        checked={state.ticketed}
                                                        onChange={selectedstatushandleChange}
                                                        custom
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="refund"
                                                        label="Refund"
                                                        className="ml-3"
                                                        checked={state.refund}
                                                        onChange={selectedstatushandleChange}
                                                        custom
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="void"
                                                        label="Void"
                                                        className="ml-3"
                                                        checked={state.void}
                                                        onChange={selectedstatushandleChange}
                                                        custom
                                                    />
                                                </div>

                                            </Form.Group>

                                            <Form.Group as={Col} xs={6} controlId="appon" className="inputCheck">
                                                <Form.Label>Download <sup></sup></Form.Label>
                                                <div className="d-flex align-items-center">
                                                    <Form.Check type="radio" label={state.ticketed ? "Invoice": "Credit Note"} className="ml-0" name="Invoice" id="invoice" onChange={selectedstatushandleChange} checked={state.invoice} custom />
                                                    <Form.Check type="radio" label={state.ticketed ? "Customer Invoice" : "Cust Credit Note"}className="ml-3" name="Customer Invoice" id="cinvoice" onChange={selectedstatushandleChange} checked={state.cinvoice} custom />
                                                    {state.ticketed && state.showitineraryRadio && <Form.Check type="radio" label="Itinerary" className="ml-3" name="Itinerary" id="itinerary" onChange={selectedstatushandleChange} checked={state.itinerary} custom />}
                                                </div>

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            {/* <Form.Group as={Col} xs={4} className="inputCheck mb-0">
                           <Form.Label>Search By<sup></sup></Form.Label>
                             <div className="d-flex align-items-center">
                               <Form.Check
                                 type="checkbox"
                                 id="currentStatus"
                                 label="Current Status"
                                 className="ml-0"
                                 custom
                               />
                               <Form.Check
                                 type="checkbox"
                                 id="previousStatus"
                                 label="Previous Status"
                                 className="ml-3"
                                 custom
                               />
                             </div>

                           </Form.Group> */}
                                            <Form.Group as={Col} controlId="appon" className="vewbutton text-right mb-0">
                                                <Button
                                                    size="xs"
                                                    variant="primary"
                                                    onClick={docsViewDetailsonclick}
                                                    type="submit"
                                                >View Detail</Button>
                                            </Form.Group>
                                        </Form.Row>
                                    </div>

                                    <Form.Row>
                                        {/* {state.flightDocsData!=="" && isArrayNotEmpty(state.popupticketsdata) &&
                      <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
                      <FlightInvoice  bookingData={state.bkng_det[0]} response={state.flightDocsData} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} />
                      </PDFViewer>
                      } */}
                                        {state.flightDocsData !== "" && isArrayNotEmpty(state.popupticketsdata) &&
                                            //* NEW DOWNLOAD */}
                                            <div style={{ display: "none" }}>
                                                <FlightInvoicePdf ref={componentRef} bk_det={state.bkng_det} response={state.flightDocsData} airport_data={props.airportdata} phone_num={context.logindata.phno} emialId={context.logindata.unm} compLogo={context.logindata.comp_logo_url} parent_id={context.logindata.pcid} selectedTab={refPriceTab.active} changeStatus={state.changeStatus} />
                                            </div>

                                            //  <PDFDownloadLink fileName="flightInvoice.pdf" document={<FlightInvoice  bookingData={state.bkng_det[0]} response={state.flightDocsData} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} />} >
                                            //  {({ blob, url, showVoucher, error }) =>
                                            //    showVoucher ? 'Loading document...' : <Icon className="share" size={20} icon="pdf" />
                                            //  }
                                            //  </PDFDownloadLink>
                                        }
                                        <div className="linkforinvoice">

                                            {/* NEW DOWNLOAD */}
                                            {state.flightDocsData !== "" && isArrayNotEmpty(state.popupticketsdata) &&
                                                <Link className="prient mr-3 ezyIcon icon-pdf" to="#" onClick={handlePrint}></Link>
                                            }

                                            {/* <Link className="prient mr-3" to="#"><Icon className="share" size={20} icon="pdf" /></Link> */}
                                            {/* <Link to="#"><Icon className="print" size={20} icon="print" /></Link>
                       <Link to="#"><Icon className="share" size={20} icon="share" /></Link> */}


                                        </div>


                                    </Form.Row>


                                    <div className="passengerList">
                                        <Table className="paxTable">
                                            <tr>
                                                <th width="10%"><p className="d-flex m-0">
                                                    {isArrayNotEmpty(state.popuppaxdata) && isNotNull(state.popuppaxdata) && (<Form.Check type="checkbox" label="" checked={state.popupallc} onChange={selectedallpaxaeventchange}
                                                        id='all' custom />)} Select</p></th>
                                                <th width="25%">Traveller</th>
                                                <th width="10%">Pax Type</th>
                                                <th width="10%">Sectors</th>
                                                <th width="15%">Ticket Numbers</th>
                                                <th width="15%">Segment Status</th>
                                                <th width="15%">Ticketing status</th>
                                            </tr>


                                            {isArrayNotEmpty(state.popuppaxdata) && isNotNull(state.popuppaxdata) && state.popuppaxdata.map((pax, index) => (

                                                <React.Fragment key={index}>

                                                    <tr className='list'>
                                                        <td>
                                                            <Form.Check type="checkbox" label="" checked={state.popupticketsarray.includes(pax.tkt_no + index)} id={pax.tkt_no + index}
                                                                onChange={selectedpaxdataeventchange(pax)} custom />
                                                        </td>
                                                        <td>
                                                            {pax.pax_name.toUpperCase()}{(isNotNull(pax.is_lead_pax) && pax.is_lead_pax !== 0) && '(lead)'}</td>

                                                        <td>{pax.pax_type}</td>
                                                        {pax.trip_type !== "2" &&
                                                            <td><div className="destSec"><span>{pax.source}-{pax.destination}</span></div></td>
                                                        }
                                                        {pax.trip_type === "2" &&
                                                            <td><div className="destSec"><span>
                                                                {pax.source}-{pax.destination}<br />
                                                                {pax.destination}- {pax.source}
                                                            </span></div></td>
                                                        }
                                                        <td>
                                                            <span> {pax.tkt_no ? pax.tkt_no : "N/A"} </span>
                                                        </td>
                                                        <td>
                                                            <span className={"status paxstatus " + ((pax.segst == "Confirmed") && ('text-success') || (pax.segst == "Incomplete") && ('text-primery') || (pax.segst == "On Hold") && ('text-primery') || (pax.segst == "Discarded") && ('text-danger') || (pax.segst == "Ticketing failed") && ('text-danger') || (pax.segst == "Cancelled") && ('text-danger'))}> {pax.segst}</span>
                                                        </td>
                                                        <td >
                                                            <span className={"status paxstatus " + ((pax.sts == "Confirmed") && ('text-success') || (pax.sts == "Incomplete") && ('text-primery') || (pax.sts == "On Hold") && ('text-primery') || (pax.sts == "Discarded") && ('text-danger') || (pax.sts == "Failed") && ('text-danger') || (pax.sts == "Cancelled") && ('text-danger'))}>{pax.sts}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="spaceNew" colSpan="8"></td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}


                                            {/* if no popupdata below msh will disply */}





                                        </Table>
                                        <p>{isNotNull(state.pupupmsg) && state.pupupmsg}</p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>
                }
            </>

        </>
    )
}
export default FlightReissueSplit