import React, { Component, useState,useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";

function InsuranceReportMenuFun(props) {
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);


return (

        <div className="submenu">
            <ul>
              <li  className={state.active==="Insurance DSR" ? 'active':''}>
              <Link
                to="/ezytrip/administration/insreport"
                title="Insurance DSR Report"
              >
               Insurance DSR Report
              </Link>
            </li>
            </ul>
          </div>

  );
};



class InsuranceReportmenu extends Component {
  constructor(props) {
  super(props);
  console.log(props)
  this.state = {
         active:props.active

      };
  }

  render() {
  return <InsuranceReportMenuFun state={this.state} />
}
}

export default InsuranceReportmenu;

