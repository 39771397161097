/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useState, useEffect } from 'react';
 import { Alert, Col, Form, Row } from "react-bootstrap";

 /**
  * @description: This function will show the Booking Details section
  * @date:18-09-2020
  * @author:Lakshmi
  *
  */
 function InsurancePolicyPdf(props) {
   const [state, setstate] = useState()
 
   
   return (
     <>
       <div className="flightbookinginfoPdf">
       <h5 className="mb-4"> Policy Details </h5>
         <div className="flightheader">
           <Row>
             <Col xs={10}>
             <Row>
               <Col xs={6}>
               <Col xs={12}><span>No of Pax: </span>{props.response.policy_details.no_ofpax}</Col>
               <Col xs={12}><span>Email : </span>{props.response.policy_details.email}</Col>
               <Col xs={12}><span>Contact Number: </span>{props.response.policy_details.phone_code}{props.response.policy_details.phone_no}</Col>
               <Col xs={12}><span>Covarage Start: </span>{props.response.policy_details.cover_start_date}</Col>
               <Col xs={12}><span>Covarage End: </span>{props.response.policy_details.cover_end_date}</Col>
               <Col xs={12}><span>Covarage Duration: </span>{props.response.policy_details.cover_dur}</Col>
               </Col>
             
               <Col xs={6}>
               <Col xs={12}><span>Policy Number: </span>{props.response.policy_details.policy_no}</Col>
               <Col xs={12}><span>Departure Date : </span>{props.response.policy_details.depart_date}</Col>
               <Col xs={12}><span>Arrival Date: </span>{props.response.policy_details.return_date}</Col>
               <Col xs={12}><span>Travel Duration: </span>{props.response.policy_details.travel_dur}</Col>
             </Col>
             </Row>
             </Col>
           </Row>
         </div>
       </div>
     </>
   )
 }
 
 
 export default InsurancePolicyPdf;
 