
import log from "loglevel";
import React, { useCallback, useContext, useEffect, useReducer, useState, useRef } from 'react';
import { Alert, Col, Form, Row,Modal } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import useOnclickOutside from 'react-cool-onclickoutside';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";
import PredectiveSearch from '../../../services/predective';
import Fifacup from '../../../../../src/client/assets/images/fifaworlcup.png'
import DateUtils from '../../commonUtils/DateUtils';
import Button from '../../common/buttons/Button';
import AddTravellerClass from './AddTravellerClass';
let airlineMaster = require('../../masterData/AirlinesMasterData.json');

const initialState = {
  dep1: "", arr1: "", dd1: DateUtils.convertStringToDate(new Date()), "dcty1": "", "acty1": "", dep2: "", arr2: "", dd2: DateUtils.convertStringToDate(new Date()), "dcty2": "", "acty2": "",
  dep3: "", arr3: "", dd3: "", "dcty3": "", "acty3": "", dep4: "", arr4: "", dd4: "", "dcty4": "", "acty4": "",
  dep5: "", arr5: "", dd5: "", "dcty5": "", "acty5": "", dep6: "", arr6: "", dd6: "", "dcty6": "", "acty6": "", enbaleFive: false, enableSix: false,
  deptOne: DateUtils.prettyDate(new Date(), "ddd, DD MMM, YYYY"), deptTwo: DateUtils.prettyDate(new Date(), "ddd, DD MMM, YYYY"), deptThree: "", deptFour: "", deptFive: "", deptSix: "", enableSegTwo: true, enableSegThree: true, enableSegFour: false, enableSegFive: false, enableSegSix: false,
  orignDataOne: [], destDataOne: [], orignDataTwo: [], destDataTwo: [], orignDataThree: [], destDataThree: [], orignDataFour: [], destDataFour: [], orignDataFive: [], destDataFive: [], orignDataSix: [], destDataSix: [],
  sectors: [], airportsOW: [], airportsRT: [], pa: "", ns: false, paxCount: 1, cls: "Economy", adt: 1, chd: 0, inf: 0, selAir: [], pa: "", notiMessage: "", notiMessageShow: false, notiVarient: "danger", dateAvailable: true, routeAvailable: true, clearob1: false, clearob2: false, clearob3: false, clearob4: false, clearob5: false, clearob6: false, cleardb1: false,  cleardb2: false, cleardb3: false, cleardb4: false, cleardb5: false, cleardb6: false

};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function MultiCitySearch(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [airline, setAirline] = useState([]);
  const [addTraveller, setAddTraveller,] = useState(false);
  const [originIsFocus, setOriginIsFocus,] = useState(false);
  const [destinationIsFocus, setDestinationIsFocus,] = useState(false);
  const [departureDate1, setDepartureDate1] = useState(new Date());
  const [departureDate2, setDepartureDate2] = useState(new Date());
  const [departureDate3, setDepartureDate3] = useState(new Date());
  const [departureDate4, setDepartureDate4] = useState(new Date());
  const [departureDate5, setDepartureDate5] = useState(new Date());
  const [departureDate6, setDepartureDate6] = useState(new Date());
  const [sector, setSectors] = useState({ sectors: [] });
  const [isLoad, setIsLoad,] = useState(false);
  const [reverse, setReverse,] = useState(false);
  const [context, setContext] = useContext(Context);
  const origin1 = useRef()
  const origin2 = useRef()
  const origin3 = useRef()
  const origin4 = useRef()
  const origin5 = useRef()
  const origin6 = useRef()
  const destination1 = useRef()
  const destination2 = useRef()
  const destination3 = useRef()
  const destination4 = useRef()
  const destination5 = useRef()
  const destination6 = useRef()
  const airref = useRef();


  useEffect(() => {
    if (props.load) {
      setIsLoad(false)
    }
  }, [props.load]);

  useEffect(() => {
    if (props.stopButtonLoad) {
      setIsLoad(false)
    }
  }, [props.stopButtonLoad])
  useEffect(() => {
    if (props.srcData !== undefined && props.srcData[0] !== undefined) {
      dispatch({ type: "orignDataOne", payload: props.srcData })
      dispatch({ type: "dep1", payload: props.srcData[0].airportCode })
      dispatch({ type: "dcty1", payload: props.srcData[0].cityName })
    }
  }, [props.srcData]);

  useEffect(() => {
    if (props.destData !== undefined && props.destData[0] !== undefined) {
      dispatch({ type: "destDataOne", payload: props.destData })
      dispatch({ type: "arr1", payload: props.destData[0].airportCode })
      dispatch({ type: "acty1", payload: props.destData[0].cityName })
    }
  }, [props.destData !== undefined]);


  useEffect(() => {
    if (props.srequest !== undefined) {
      dispatch({ type: "ismodify", payload: true })
      dispatch({ type: "cls", payload: props.srequest.search !== undefined ? props.srequest.search.cls : props.srequest.clstyp})
      if (props.srequest.tt !== undefined && props.srequest.search !== undefined) {
        var request = props.srequest.search;
        if (request.dep2 !== "" && request.dep2 !== undefined && request.arr2 !== "" && request.arr2 !== undefined) {
          dispatch({ type: "enableSegTwo", payload: true })
        } else {
          dispatch({ type: "enableSegTwo", payload: false })
        }

        if (request.dep3 !== "" && request.dep3 !== undefined && request.arr3 !== "" && request.arr3 !== undefined) {
          dispatch({ type: "enableSegThree", payload: true })
        } else {
          dispatch({ type: "enableSegThree", payload: false })
        }

        if (request.dep4 !== "" && request.dep4 !== undefined && request.arr4 !== "" && request.arr4 !== undefined) {
          dispatch({ type: "enableSegFour", payload: true })
        }
        if (request.dep5 !== "" && request.dep5 !== undefined && request.arr5 !== "" && request.arr5 !== undefined) {
          dispatch({ type: "enableSegFive", payload: true })
        }
        if (request.dep6 !== "" && request.dep6 !== undefined) {
          dispatch({ type: "enableSegSix", payload: true })
        }
        if (request.ns) {
          dispatch({ type: "ns", payload: true })
        }
        if(props.oldCls!=="" && props.oldCls!==undefined){
          dispatch({ type: "cls", payload: props.oldCls })
        }
        dispatch({ type: "selAir", payload: request.selAir })
        dispatch({ type: "dep1", payload: request.dep1 })
        dispatch({ type: "arr1", payload: request.arr1 })
        dispatch({ type: "dcty1", payload: request.dcty1 })
        dispatch({ type: "acty1", payload: request.acty1 })
        dispatch({ type: "dep2", payload: request.dep2 })
        dispatch({ type: "arr2", payload: request.arr2 })
        dispatch({ type: "dcty2", payload: request.dcty2 })
        dispatch({ type: "acty2", payload: request.acty2 })
        dispatch({ type: "dep3", payload: request.dep3 })
        dispatch({ type: "arr3", payload: request.arr3 })
        dispatch({ type: "dcty3", payload: request.dcty3 })
        dispatch({ type: "acty3", payload: request.acty3 })
        dispatch({ type: "dep4", payload: request.dep4 })
        dispatch({ type: "arr4", payload: request.arr4 })
        dispatch({ type: "dcty4", payload: request.dcty4 })
        dispatch({ type: "acty4", payload: request.acty4 })
        dispatch({ type: "dep5", payload: request.dep5 })
        dispatch({ type: "arr5", payload: request.arr5 })
        dispatch({ type: "dcty5", payload: request.dcty5 })
        dispatch({ type: "acty5", payload: request.acty5 })
        dispatch({ type: "dep6", payload: request.dep6 })
        dispatch({ type: "arr6", payload: request.arr6 })
        dispatch({ type: "dcty6", payload: request.dcty6 })
        dispatch({ type: "acty6", payload: request.acty6 })
        dispatch({ type: "adt", payload: request.adt })
        dispatch({ type: "chd", payload: request.chd })
        dispatch({ type: "inf", payload: request.inf })
        dispatch({ type: "pa", payload: request.pa })
        dispatch({ type: "tt", payload: request.tt })
        dispatch({ type: "paxCount", payload: request.adt + request.chd + request.inf })
        dispatch({ type: "orignDataOne", payload: request.orignDataOne })
        dispatch({ type: "destDataOne", payload: request.destDataOne })
        dispatch({ type: "orignDataTwo", payload: request.orignDataTwo })
        dispatch({ type: "destDataTwo", payload: request.destDataTwo })
        dispatch({ type: "orignDataThree", payload: request.orignDataThree })
        dispatch({ type: "destDataThree", payload: request.destDataThree })
        dispatch({ type: "orignDataFour", payload: request.orignDataFour })
        dispatch({ type: "destDataFour", payload: request.destDataFour })
        dispatch({ type: "orignDataFive", payload: request.orignDataFive })
        dispatch({ type: "destDataFive", payload: request.destDataFive })
        dispatch({ type: "orignDataSix", payload: request.orignDataSix })
        dispatch({ type: "destDataSix", payload: request.destDataSix })
        dispatch({ type: "dd1", payload: request.dd1 })
        dispatch({ type: "dd2", payload: request.dd2 })
        dispatch({ type: "dd3", payload: request.dd3 })
        dispatch({ type: "dd4", payload: request.dd4 })
        dispatch({ type: "dd5", payload: request.dd5 })
        dispatch({ type: "dd6", payload: request.dd6 })
        dispatch({ type: "clearob1", payload: true })
        dispatch({ type: "clearob2", payload: true })
        dispatch({ type: "clearob3", payload: true })
        dispatch({ type: "clearob4", payload: true })
        dispatch({ type: "clearob5", payload: true })
        dispatch({ type: "clearob6", payload: true })
        dispatch({ type: "cleardb1", payload: true })
        dispatch({ type: "cleardb2", payload: true })
        dispatch({ type: "cleardb3", payload: true })
        dispatch({ type: "cleardb4", payload: true })
        dispatch({ type: "cleardb5", payload: true })
        dispatch({ type: "cleardb6", payload: true })

        if (request.deptOne !== undefined) {
          dispatch({ type: "deptOne", payload: request.deptOne })
        }
        if (request.deptTwo !== undefined) {
          dispatch({ type: "deptTwo", payload: request.deptTwo })
        }
        if (request.deptThree !== undefined) {
          dispatch({ type: "deptThree", payload: request.deptThree })
        }
        if (request.deptFour !== undefined) {
          dispatch({ type: "deptFour", payload: request.deptFour })
        }
        if (request.deptFive !== undefined) {
          dispatch({ type: "deptFive", payload: request.deptFive })
        }
        if (request.deptSix !== undefined) {
          dispatch({ type: "deptSix", payload: request.deptSix })
        }
        dispatch({ type: "dateAvailable", payload: props.dateAvailable })
        dispatch({ type: "routeAvailable", payload: props.routeAvailable })


        setDepartureDate1(DateUtils.convertNewToDate(request.dd1));
        setDepartureDate2(DateUtils.convertNewToDate(request.dd2));
        setDepartureDate3(DateUtils.convertNewToDate(request.dd3));
        setDepartureDate4(DateUtils.convertNewToDate(request.dd4));
        setDepartureDate5(DateUtils.convertNewToDate(request.dd5));
        setDepartureDate6(DateUtils.convertNewToDate(request.dd6));
      }
    }
  }, [props.srequest !== undefined]);

  const handleOrigin = (input, o) => {
      if(o == "o1"){
        if(input.length > 0){
        dispatch({ type: "clearob1", payload: true })
      }
      else{
        dispatch({ type: "clearob1", payload: false })
      }
    }
    else if(o == "o2"){
      if(input.length > 0){
        dispatch({ type: "clearob2", payload: true })
      }
      else{
        dispatch({ type: "clearob2", payload: false })
      }
    }
    else if(o == "o3"){
      if(input.length > 0){
        dispatch({ type: "clearob3", payload: true })
      }
      else{
        dispatch({ type: "clearob3", payload: false })
      }
    }
    else if(o == "o4"){
      if(input.length > 0){
        dispatch({ type: "clearob4", payload: true })
      }
      else{
        dispatch({ type: "clearob4", payload: false })
      }
    }
    else if(o == "o5"){
      if(input.length > 0){
        dispatch({ type: "clearob5", payload: true })
      }
      else{
        dispatch({ type: "clearob5", payload: false })
      }
    }
    else if(o == "o6"){
      if(input.length > 0){
        dispatch({ type: "clearob6", payload: true })
      }
      else{
        dispatch({ type: "clearob6", payload: false })
      }
    }
    predectiveCall(input, 'airportsOW')
  }

  // const ref = useOnclickOutside(() => {
  //   setAddTraveller(false);
  // });

  const refOutClick = useOnclickOutside(() => {
    setAddTraveller(false);

    var deps = document.querySelectorAll('.origin');
    deps.forEach(function (depsItem) {
      depsItem.classList.remove('activeSerField')
    });

    var arrs = document.querySelectorAll('.destination');
    arrs.forEach(function (arrsItem) {
      arrsItem.classList.remove('activeSerField')
    });

    var dds = document.querySelectorAll('.datePick .addInput');
    dds.forEach(function (ddsItem) {
      ddsItem.classList.remove('activeSerField')
    });
    document.querySelector('.traveller > div').classList.remove('activeSerField');
  });

  const errorRemoveBlur = () => {
    console.log('errorRemoveBlur');
    dispatch({ type: "notiMessageShow", payload: false })

    var deps = document.querySelectorAll('.origin');
    deps.forEach(function (depsItem) {
      depsItem.classList.remove('errorSerField')
    });

    var arrs = document.querySelectorAll('.destination');
    arrs.forEach(function (arrsItem) {
      arrsItem.classList.remove('errorSerField')
    });

    var dds = document.querySelectorAll('.datePick .addInput');
    dds.forEach(function (ddsItem) {
      ddsItem.classList.remove('errorSerField')
    });

    document.querySelector('.traveller > div').classList.remove('errorSerField');
  };


  //=====================================
  // Passing Active Class for the SearchField parents
  //=====================================
  const activeOrigin = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
    companySelect();
  }
  const activeDesti = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
    companySelect();
  }
  const activeDate1 = (event) => {
    let element = document.querySelector('.datePick .dd1');
    element.classList.add('activeSerField')
  }
  const activeDate2 = (event) => {
    let element = document.querySelector('.datePick .dd2');
    element.classList.add('activeSerField')
  }
  const activeDate3 = (event) => {
    let element = document.querySelector('.datePick .dd3');
    element.classList.add('activeSerField')
  }
  const activeDate4 = (event) => {
    let element = document.querySelector('.datePick .dd4');
    element.classList.add('activeSerField')
  }
  const activeDate5 = (event) => {
    let element = document.querySelector('.datePick .dd5');
    element.classList.add('activeSerField')
  }
  const activeDate6 = (event) => {
    let element = document.querySelector('.datePick .dd6');
    element.classList.add('activeSerField')
  }
  const activeTraveller = (event) => {
    let element = document.querySelector('.traveller > div');
    element.classList.add('activeSerField')
  }

  const handleDestination = (input, d) => {
    if(d == "d1"){
      if(input.length > 0){
      dispatch({ type: "cleardb1", payload: true })
    }
    else{
      dispatch({ type: "cleardb1", payload: false })
    }
  }
  else if(d == "d2"){
    if(input.length > 0){
    dispatch({ type: "cleardb2", payload: true })
  }
  else{
    dispatch({ type: "cleardb2", payload: false })
  }
}
  else if(d == "d3"){
    if(input.length > 0){
    dispatch({ type: "cleardb3", payload: true })
  }
  else{
    dispatch({ type: "cleardb3", payload: false })
  }
}
  else if(d == "d4"){
    if(input.length > 0){
    dispatch({ type: "cleardb4", payload: true })
  }
  else{
    dispatch({ type: "cleardb4", payload: false })
  }
}
  else if(d == "d5"){
    if(input.length > 0){
    dispatch({ type: "cleardb5", payload: true })
  }
  else{
    dispatch({ type: "cleardb5", payload: false })
  }
}
  else if(d == "d6"){
    if(input.length > 0){
    dispatch({ type: "cleardb6", payload: true })
  }
  else{
    dispatch({ type: "cleardb6", payload: false })
  }
}
    predectiveCall(input, 'airportsRT')
  }


  const predectiveCall = (input, route) => {
    if (input.length > 0) {
      PredectiveSearch.retreieveSearch(input).then((data) => {
        dispatch({ type: route, payload: data.data !== "" ? data.data : [] })
      });
    }
    else {
      dispatch({ type: route, payload: [] })
    }
  }

  const handleChange = (e) => {
    if (addTraveller) {
      setAddTraveller(false)
    } else {
      setAddTraveller(true)
    }
  }
  const originOnFocus = (e) => {
    setOriginIsFocus(true)
    //setDestinationIsFocus(true)
  }
  const originOnBlur = (e) => {
    setOriginIsFocus(false)
    //setDestinationIsFocus(false)
  }
  const destinationOnFocus = (e) => {
    setDestinationIsFocus(true)
  }
  const destinationOnBlur = (e) => {
    setDestinationIsFocus(false)
  }

  const clearPrf = () => {
    dispatch({ type: "selAir", payload: '' })
    dispatch({ type: "pa", payload: '' })
    dispatch({ type: "clearair", payload: false })

    airref.current.clear()

  }
  //=====================================
  // This function will update the Airline
  //=====================================
  const handleAirChange = (obj) => {
    dispatch({ type: "selAir", payload: obj })
    if (obj !== undefined && obj[0] !== undefined) {
      dispatch({ type: "pa", payload: obj[0].value })
    }
    // if (input.length > 0) {
    //   dispatch({ type: "clearair", payload: true })
    // }
    // else {
    //   dispatch({ type: "clearair", payload: false })
    //   }
  }
  const handleOnchangeMulty = (obj, type) => {
    errorRemoveBlur();
    try {
      if (type === "srcOne") {
        dispatch({ type: "orignDataOne", payload: obj })
        dispatch({ type: "dep1", payload: obj[0].airportCode })
        dispatch({ type: "dcty1", payload: obj[0].cityName })
        props.sendMultiSrc(obj);
      } else if (type === "destOne") {
        dispatch({ type: "destDataOne", payload: obj })
        dispatch({ type: "arr1", payload: obj[0].airportCode })
        dispatch({ type: "acty1", payload: obj[0].cityName })
        dispatch({ type: "orignDataTwo", payload: obj })
        dispatch({ type: "dep2", payload: obj[0].airportCode })
        dispatch({ type: "dcty2", payload: obj[0].cityName })
        dispatch({ type: "clearob2", payload: true })
        props.sendMultiDest(obj);
      } else if (type === "srcTwo") {
        dispatch({ type: "orignDataTwo", payload: obj })
        dispatch({ type: "dep2", payload: obj[0].airportCode })
        dispatch({ type: "dcty2", payload: obj[0].cityName })
      } else if (type === "destTwo") {
        dispatch({ type: "destDataTwo", payload: obj })
        dispatch({ type: "arr2", payload: obj[0].airportCode })
        dispatch({ type: "acty2", payload: obj[0].cityName })
        if (state.enableSegThree) {
          dispatch({ type: "clearob3", payload: true })
          dispatch({ type: "orignDataThree", payload: obj })
          dispatch({ type: "dep3", payload: obj[0].airportCode })
          dispatch({ type: "dcty3", payload: obj[0].cityName })
        }

      } else if (type === "srcThree") {
        dispatch({ type: "orignDataThree", payload: obj })
        dispatch({ type: "dep3", payload: obj[0].airportCode })
        dispatch({ type: "dcty3", payload: obj[0].cityName })
      } else if (type === "destThree") {
        dispatch({ type: "destDataThree", payload: obj })
        dispatch({ type: "arr3", payload: obj[0].airportCode })
        dispatch({ type: "acty3", payload: obj[0].cityName })
        if (state.enableSegFour) {
          dispatch({ type: "orignDataFour", payload: obj })
          dispatch({ type: "dep4", payload: obj[0].airportCode })
          dispatch({ type: "dcty4", payload: obj[0].cityName })
        }

      } else if (type === "srcFour") {
        dispatch({ type: "orignDataFour", payload: obj })
        dispatch({ type: "dep4", payload: obj[0].airportCode })
        dispatch({ type: "dcty4", payload: obj[0].cityName })
      } else if (type === "destFour") {
        dispatch({ type: "clearob4", payload: true })
        dispatch({ type: "destDataFour", payload: obj })
        dispatch({ type: "arr4", payload: obj[0].airportCode })
        dispatch({ type: "acty4", payload: obj[0].cityName })

        if (state.enableSegFive) {
          dispatch({ type: "orignDataFive", payload: obj })
          dispatch({ type: "dep5", payload: obj[0].airportCode })
          dispatch({ type: "dcty5", payload: obj[0].cityName })
        }

      } else if (type === "srcFive") {
        dispatch({ type: "orignDataFive", payload: obj })
        dispatch({ type: "dep5", payload: obj[0].airportCode })
        dispatch({ type: "dcty5", payload: obj[0].cityName })
      } else if (type === "destFive") {
        dispatch({ type: "clearob5", payload: true })
        dispatch({ type: "destDataFive", payload: obj })
        dispatch({ type: "arr5", payload: obj[0].airportCode })
        dispatch({ type: "acty5", payload: obj[0].cityName })
        if (state.enableSegSix) {
          dispatch({ type: "orignDataSix", payload: obj })
          dispatch({ type: "dep6", payload: obj[0].airportCode })
          dispatch({ type: "dcty6", payload: obj[0].cityName })
        }
      } else if (type === "srcSix") {
        dispatch({ type: "orignDataSix", payload: obj })
        dispatch({ type: "dep6", payload: obj[0].airportCode })
        dispatch({ type: "dcty6", payload: obj[0].cityName })
      } else if (type === "destSix") {
        dispatch({ type: "destDataSix", payload: obj })
        dispatch({ type: "arr6", payload: obj[0].airportCode })
        dispatch({ type: "acty6", payload: obj[0].cityName })
      }
    } catch (err) {
      log.error('Exception occured in handleOnOrigin function---' + err);
    }
  }


  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegOne = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "dep1", payload: state.dep1 })
      dispatch({ type: "arr1", payload: state.arr1 })
      dispatch({ type: "dcty1", payload: state.dcty1 })
      dispatch({ type: "acty1", payload: state.acty1 })
      dispatch({ type: "orignDataOne", payload: state.orignDataOne })
      dispatch({ type: "destDataOne", payload: state.destDataOne })
    } else {
      setReverse(true)
      dispatch({ type: "orignDataOne", payload: state.destDataOne })
      dispatch({ type: "destDataOne", payload: state.orignDataOne })
      dispatch({ type: "dep1", payload: state.arr1 })
      dispatch({ type: "arr1", payload: state.dep1 })
      dispatch({ type: "dcty1", payload: state.acty1 })
      dispatch({ type: "acty1", payload: state.dcty1 })

    }
  }

  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegTwo = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "orignDataTwo", payload: state.orignDataTwo })
      dispatch({ type: "destDataTwo", payload: state.destDataTwo })
      dispatch({ type: "dep2", payload: state.dep2 })
      dispatch({ type: "arr2", payload: state.arr2 })
      dispatch({ type: "dcty2", payload: state.dcty2 })
      dispatch({ type: "acty2", payload: state.acty2 })
    } else {
      setReverse(true)
      dispatch({ type: "orignDataTwo", payload: state.destDataTwo })
      dispatch({ type: "destDataTwo", payload: state.orignDataTwo })
      dispatch({ type: "dep2", payload: state.arr2 })
      dispatch({ type: "arr2", payload: state.dep2 })
      dispatch({ type: "dcty2", payload: state.acty2 })
      dispatch({ type: "acty2", payload: state.dcty2 })
    }
  }

  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegThree = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "orignDataThree", payload: state.orignDataThree })
      dispatch({ type: "destDataThree", payload: state.destDataThree })
      dispatch({ type: "dep3", payload: state.dep3 })
      dispatch({ type: "arr3", payload: state.arr3 })
      dispatch({ type: "dcty3", payload: state.dcty3 })
      dispatch({ type: "acty3", payload: state.acty3 })
    } else {
      setReverse(true)
      dispatch({ type: "orignDataThree", payload: state.destDataThree })
      dispatch({ type: "destDataThree", payload: state.orignDataThree })
      dispatch({ type: "dep3", payload: state.arr3 })
      dispatch({ type: "arr3", payload: state.dep3 })
      dispatch({ type: "dcty3", payload: state.acty3 })
      dispatch({ type: "acty3", payload: state.dcty3 })

    }
  }

  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegFour = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "orignDataFour", payload: state.orignDataFour })
      dispatch({ type: "destDataFour", payload: state.destDataFour })
      dispatch({ type: "dep4", payload: state.dep4 })
      dispatch({ type: "arr4", payload: state.arr4 })
      dispatch({ type: "dcty4", payload: state.dcty4 })
      dispatch({ type: "acty4", payload: state.acty4 })
    } else {
      setReverse(true)
      dispatch({ type: "destDataFour", payload: state.orignDataFour })
      dispatch({ type: "orignDataFour", payload: state.destDataFour })
      dispatch({ type: "dep4", payload: state.arr4 })
      dispatch({ type: "arr4", payload: state.dep4 })
      dispatch({ type: "dcty4", payload: state.acty4 })
      dispatch({ type: "acty4", payload: state.dcty4 })

    }
  }


  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegFive = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "orignDataFive", payload: state.orignDataFive })
      dispatch({ type: "destDataFive", payload: state.destDataFive })
      dispatch({ type: "dep5", payload: state.dep5 })
      dispatch({ type: "arr5", payload: state.arr5 })
      dispatch({ type: "dcty5", payload: state.dcty5 })
      dispatch({ type: "acty5", payload: state.acty5 })
    } else {
      setReverse(true)
      dispatch({ type: "destDataFive", payload: state.orignDataFive })
      dispatch({ type: "orignDataFive", payload: state.destDataFive })
      dispatch({ type: "dep5", payload: state.arr5 })
      dispatch({ type: "arr5", payload: state.dep5 })
      dispatch({ type: "dcty5", payload: state.acty5 })
      dispatch({ type: "acty5", payload: state.dcty5 })

    }
  }

  const companySelect = () =>{
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        document.querySelector('.domin').classList.add('dominFocus');
      }else{
        document.querySelector('.domin').classList.remove('dominFocus');
      }
    }
  }

  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClickSegSix = () => {
    if (reverse) {
      setReverse(false)
      dispatch({ type: "orignDataSix", payload: state.orignDataSix })
      dispatch({ type: "destDataSix", payload: state.destDataSix })
      dispatch({ type: "dep6", payload: state.dep6 })
      dispatch({ type: "arr6", payload: state.arr6 })
      dispatch({ type: "dcty6", payload: state.dcty6 })
      dispatch({ type: "acty6", payload: state.acty6 })
    } else {
      setReverse(true)
      dispatch({ type: "destDataSix", payload: state.orignDataSix })
      dispatch({ type: "orignDataSix", payload: state.destDataSix })
      dispatch({ type: "dep6", payload: state.arr6 })
      dispatch({ type: "arr6", payload: state.dep6 })
      dispatch({ type: "dcty6", payload: state.acty6 })
      dispatch({ type: "acty6", payload: state.dcty6 })

    }
  }



  // OnChange event we will call this and update the state
  const handleDepDateChange = (types) => date => {
    errorRemoveBlur();
    const convertDate = DateUtils.convertStringToDate(date);
    const dateFormate = DateUtils.prettyDate(date, "ddd, DD MMM YYYY")
    if (types === "deptOne") {
      dispatch({ type: "dd1", payload: convertDate })
      dispatch({ type: "deptOne", payload: dateFormate })
      setDepartureDate1(date);
    } else if (types === "deptTwo") {
      dispatch({ type: "dd2", payload: convertDate })
      dispatch({ type: "deptTwo", payload: dateFormate })
      setDepartureDate2(date);
    } else if (types === "deptThree") {
      dispatch({ type: "dd3", payload: convertDate })
      dispatch({ type: "deptThree", payload: dateFormate })
      setDepartureDate3(date);
    } else if (types === "deptFour") {
      dispatch({ type: "dd4", payload: convertDate })
      dispatch({ type: "deptFour", payload: dateFormate })
      setDepartureDate4(date);
    } else if (types === "deptFive") {
      dispatch({ type: "dd5", payload: convertDate })
      dispatch({ type: "deptFive", payload: dateFormate })
      setDepartureDate5(date);
    } else if (types === "deptSix") {
      dispatch({ type: "dd6", payload: convertDate })
      dispatch({ type: "deptSix", payload: dateFormate })
      setDepartureDate6(date);
    }

  }

  const handleRemoveSector = () => {
    if (state.enableSegSix) {
      dispatch({ type: "enableSegSix", payload: false })
      dispatch({ type: "orignDataSix", payload: [] })
      dispatch({ type: "destDataSix", payload: [] })
      dispatch({ type: "enableSix", payload: false })
      dispatch({ type: "acty6", payload: undefined })
      dispatch({ type: "dcty6", payload: undefined })
      dispatch({ type: "arr6", payload: undefined })
      dispatch({ type: "dep6", payload: undefined })
    }
    if (!state.enableSegSix && state.enableSegFive) {
      dispatch({ type: "enbaleFive", payload: true })
      dispatch({ type: "enableSegFive", payload: false })
      dispatch({ type: "orignDataFive", payload: [] })
      dispatch({ type: "destDataFive", payload: [] })
      dispatch({ type: "acty5", payload: undefined })
      dispatch({ type: "dcty5", payload: undefined })
      dispatch({ type: "arr5", payload: undefined })
      dispatch({ type: "dep5", payload: undefined })
    }
    if (!state.enableSegSix && !state.enableSegFive && state.enableSegFour) {
      dispatch({ type: "enableSegFour", payload: false })
      dispatch({ type: "orignDataFour", payload: [] })
      dispatch({ type: "destDataFour", payload: [] })
      dispatch({ type: "acty4", payload: undefined })
      dispatch({ type: "dcty4", payload: undefined })
      dispatch({ type: "arr4", payload: undefined })
      dispatch({ type: "dep4", payload: undefined })

    }
  };

  const handleAddSector = () => {
    if (!state.enableSegThree) {
      dispatch({ type: "enableSegThree", payload: true })
    }
      else{
      dispatch({ type: "enableSegFour", payload: true })
    }

    if (state.enableSegFour) {
      dispatch({ type: "enableSegFive", payload: true })
      dispatch({ type: "enableFive", payload: true })
    }

    if (state.enableSegFour && state.enableSegFive && !state.enableSegSix) {
      dispatch({ type: "enableSegSix", payload: true })
      dispatch({ type: "enableSix", payload: true })
    }

  };

  //=====================================
  // This function will get the Pax count and Total count for Add traveller
  //=====================================
  const paxCount = useCallback((data) => {
    dispatch({ type: "adt", payload: data[0] })
    dispatch({ type: "chd", payload: data[1] })
    dispatch({ type: "inf", payload: data[2] })
    dispatch({ type: "paxCount", payload: data[3] })
  }, []);

  //=====================================
  // This function will get Class
  //=====================================
  const clsType = useCallback((type) => {
    dispatch({ type: "cls", payload: type })
  }, []);

  //=====================================
  // This function will update Non stop state
  //=====================================
  const handleChangeNs = (e) => {
    const { id, value } = e.target;
    if (state.ns) {
      dispatch({ type: "ns", payload: false })
    } else {
      dispatch({ type: "ns", payload: true })
    }
  }
  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const coloseTravel = (type) => {
    if (type === "coseTrv") {
      setAddTraveller(false);
    }

  }
  //=====================================
  // This function will check the Class type
  //=====================================
  const setClsType = () => {
    var cl = "E";
    if (state.cls === "Economy") {
      cl = "E"
    } else if (state.cls === "Business") {
      cl = "B"
    } else if (state.cls === "First class") {
      cl = "F"
    }
      else if (state.cls == "Premium") {
      cl = "P"
    }
    return cl;
  }

  const checkHayyaCountry =()=>{
    let country =false;
    if(state.destDataOne[0].countryCode==="QA" || state.destDataTwo[0].countryCode==="QA" || (state.destDataThree.length!==0 && state.destDataThree[0].countryCode==="QA")){
      country=true;
    }
    return country;
  }

  const checkFlightSearch = (e) => {
    if (validateSearch()){
      dispatch({ type: "notiMessageShow", payload: false })
      props.onClickHandler && props.onClickHandler()
      props.onClickHandler && sessionStorage.setItem("MODIFY_SEARCH","yes")
      sessionStorage.setItem("CMPID", context.logindata.cid )
        if(props.flowType !== "REISSUE" && checkHayyaCountry()){
          let checkCard = false;
          if(state.destDataOne[0].countryCode==="QA"){
            checkCard = DateUtils.checkHayyCardDate(state.dd1,"QA");
          }else if(state.destDataTwo[0].countryCode==="QA"){
            checkCard = DateUtils.checkHayyCardDate(state.dd2,"QA");
          }else if(state.dd3!==undefined && state.destDataThree.length!==0 && state.destDataThree[0].countryCode==="QA"){
            checkCard = DateUtils.checkHayyCardDate(state.dd3,"QA");
          }
          if(checkCard){
            dispatch({ type: "showInboundPopup", payload: true })
          }else{
            handleMultiSearch();
          }
        }else{
            handleMultiSearch();
        }
    }
  }



  //=====================================
  // This function will call the service for Search response
  //=====================================
  const handleMultiSearch = () => {
    try {
        setIsLoad(true);
        var cls = setClsType();
        var air = "";
        if (airline) {
          air = airline[0];
        }
        if (props.flowType !== undefined && props.flowType === "REISSUE") {
          let searchReq = Object.assign({}, props.srequest);
          delete searchReq.mappings;
          delete searchReq.search;
          searchReq.search = state;
          searchReq.cl = cls;
          searchReq.modify = true;
          searchReq.flowType = "REISSUE";
          searchReq.dateAvailable = state.dateAvailable;
          searchReq.routeAvailable = state.routeAvailable;
          searchReq.oldCls = props.oldCls;
          history.push({
            pathname: '/ezytrip/bookings/flight/results',
            state: {
              req: searchReq
            }
          });

        } else {
          const payload = {
            "search": state,
            "tt": 3,
            "cl": cls,
            "cur": context.logindata.cur,
            "chan": "B2C",
            "cid": context.logindata.cid,
            "pa": state.pa,
            "sty": "normal",
            "modify": state.ismodify,
            "bid": context.logindata.bid,
            "flowType": "DIRECT",
            "bktype": "DIRECT",
            "butype": context.logindata.butype,
            "pcid": context.logindata.pcid,
            "journey_type": "Onward",
            "region_type" : context.logindata.region_type,
            "office_id":context.logindata.office_id
          }
          sessionStorage.setItem("CMPID",context.logindata.cid)
          history.push({
            pathname: '/ezytrip/bookings/flight/results',
            state: {
              req: payload
            }
          });
        }
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }

  }



  //=====================================
  // This function will validate the Search Fields data exists or not
  //=====================================
  const validateSearch = () => {

    if (state.dep1 === "") {
      document.querySelector('.dep1').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter departure1." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.arr1 === "") {
      document.querySelector('.arr1').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter arrival1." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.dd1 === "") {
      document.querySelector('.dd1').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter departure Date1." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })

      return false
    }
    if (state.dep2 === "") {
      document.querySelector('.dep2').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter departure2." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.arr2 === "") {
      document.querySelector('.arr2').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter arrival2." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.dd2 === "") {
      document.querySelector('.dd2').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter departure Date2." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    // if (state.dep3 === "") {
    //   document.querySelector('.dep3').classList.add('errorSerField');
    //   dispatch({ type: "notiMessage", payload: "Please enter dep3 Origin." })
    //   dispatch({ type: "notiVarient", payload: "danger" })
    //   dispatch({ type: "notiMessageShow", payload: true })

    //   return false
    // }
    // if (state.arr3 === "") {
    //   document.querySelector('.arr3').classList.add('errorSerField');
    //   dispatch({ type: "notiMessage", payload: "Please enter arr3 Origin." })
    //   dispatch({ type: "notiVarient", payload: "danger" })
    //   dispatch({ type: "notiMessageShow", payload: true })

    //   return false
    // }
    // if (state.dd3 === "") {
    //   document.querySelector('.dd3').classList.add('errorSerField');
    //   dispatch({ type: "notiMessage", payload: "Please enter dd3 Date." })
    //   dispatch({ type: "notiVarient", payload: "danger" })
    //   dispatch({ type: "notiMessageShow", payload: true })

    //   return false
    // }
    /*
      if (state.dep4 === "") {
        document.querySelector('.dep4').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dep4 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.arr4 === "") {
        document.querySelector('.arr4').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter arr4 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.dd4 === "") {
        document.querySelector('.dd4').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dd4 Date." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.dep5 === "") {
        document.querySelector('.dep5').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dep5 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.arr5 === "") {
        document.querySelector('.arr5').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter arr5 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.dd5 === "") {
        document.querySelector('.dd5').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dd5 Date." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.dep6 === "") {
        document.querySelector('.dep6').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dep6 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.arr6 === "") {
        document.querySelector('.arr6').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter arr6 Origin." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
      if (state.dd6 === "") {
        document.querySelector('.dd6').classList.add('errorSerField');
        dispatch({ type: "notiMessage", payload: "Please enter dd6 Date." })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })

        return false
      }
    */

    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }
    }
    return true;
  }


  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
const clearorignDataOne = ()=>{
  origin1.current.clear()
  origin1.current.focus()
  dispatch({ type: "orignDataOne", payload: [] })
  dispatch({ type: "dep1", payload: "" })
  dispatch({ type: "clearob1", payload: false })
}
const clearorignDataTwo = ()=>{
  origin2.current.clear()
  origin2.current.focus()
  dispatch({ type: "orignDataTwo", payload: [] })
  dispatch({ type: "dep2", payload: "" })
  dispatch({ type: "clearob2", payload: false })
}
const clearorignDataThree = ()=>{
  origin3.current.clear()
  origin3.current.focus()
  dispatch({ type: "orignDataThree", payload: [] })
  dispatch({ type: "dep3", payload: "" })
  dispatch({ type: "clearob3", payload: false })
}
const clearorignDataFour = ()=>{
  origin4.current.clear()
  origin4.current.focus()
  dispatch({ type: "orignDataFour", payload: [] })
  dispatch({ type: "dep4", payload: "" })
  dispatch({ type: "clearob4", payload: false })
}
const clearorignDataFive = ()=>{
  origin5.current.clear()
  origin5.current.focus()
  dispatch({ type: "orignDataFive", payload: [] })
  dispatch({ type: "dep5", payload: "" })
  dispatch({ type: "clearob5", payload: false })
}
const clearorignDataSix = ()=>{
  origin6.current.clear()
  origin6.current.focus()
  dispatch({ type: "orignDataSix", payload: [] })
  dispatch({ type: "dep6", payload: "" })
  dispatch({ type: "clearob6", payload: false })
}
const cleardestDataOne = ()=>{
  destination1.current.clear()
  destination1.current.focus()
  dispatch({ type: "destDataOne", payload: [] })
  dispatch({ type: "arr1", payload: "" })
  dispatch({ type: "cleardb1", payload: false })

}
const cleardestDataTwo = ()=>{
  destination2.current.clear()
  destination2.current.focus()
  dispatch({ type: "destDataTwo", payload: [] })
  dispatch({ type: "arr2", payload: "" })
  dispatch({ type: "cleardb2", payload: false })
}
const cleardestDataThree = ()=>{
  destination3.current.clear()
  destination3.current.focus()
  dispatch({ type: "destDataThree", payload: [] })
  dispatch({ type: "arr3", payload: "" })
  dispatch({ type: "cleardb3", payload: false })
}
const cleardestDataFour = ()=>{
  destination4.current.clear()
  destination4.current.focus()
  dispatch({ type: "destDataFour", payload: [] })
  dispatch({ type: "arr4", payload: "" })
  dispatch({ type: "cleardb4", payload: false })
}
const cleardestDataFive = ()=>{
  destination5.current.clear()
  destination5.current.focus()
  dispatch({ type: "destDataFive", payload: [] })
  dispatch({ type: "arr5", payload: "" })
  dispatch({ type: "cleardb5", payload: false })
}
const cleardestDataSix = ()=>{
  destination6.current.clear()
  destination6.current.focus()
  dispatch({ type: "destDataSix", payload: [] })
  dispatch({ type: "arr6", payload: "" })
  dispatch({ type: "cleardb6", payload: false })
}

const closePopup =()=>{
  dispatch({ type: "showInboundPopup", payload: false })
}

  return (
    <>
      <div>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <Row className="multySelect">
          <Col className="multyOrigin pr-0">
          <Form.Row>
               <Form.Group as={Col}>
                <div className={"addInput origin dep1" + (originIsFocus ? " active" : "")}>
                  <Form.Label><span className="ezyIcon icon-plane"></span> Origin</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                    onInputChange={value => handleOrigin(value, "o1")}
                    options={state.airportsOW} filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                    selected={state.orignDataOne}
                    onChange={airport => handleOnchangeMulty(airport, "srcOne")}

                    placeholder="From"
                    readOnly
                    minLength={1}
                    onFocus={activeOrigin}
                    disabled={!state.routeAvailable}
                    renderMenuItemChildren={(airport) => (
                      <><span className="ezyIcon icon-plane"></span>
                        <strong> {airport.cityName} </strong>
                        <span>{airport.countryName} </span>
                        <small>{airport.airportCode}</small>
                      </>
                    )}
                    ref = {origin1}
                  />
                  {state.clearob1 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataOne}
                variant="link">
              </Button>
              }
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegOne}></span>
                <div className={"addInput destination arr1" + (destinationIsFocus ? " active" : "")}>
                  <Form.Label><span className="ezyIcon icon-plane"></span> Destination</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                    onInputChange={value => handleDestination(value, "d1")}
                    options={state.airportsRT}
                    selected={state.destDataOne}
                    //onChange={handleOnchangeMulty("destOne")}
                    onChange={airport => handleOnchangeMulty(airport, "destOne")}
                    filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                    placeholder="To"
                    readOnly
                    minLength={1}
                    onFocus={activeDesti}
                    disabled={!state.routeAvailable}
                    renderMenuItemChildren={(airport) => (
                      <><span className="ezyIcon icon-plane"></span>
                        <strong> {airport.cityName} </strong>
                        <span>{airport.countryName} </span>
                        <small>{airport.airportCode}</small>
                      </>
                    )}
                    ref = {destination1}
                  />
                  {state.cleardb1 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataOne}
                variant="link">
              </Button>
              }
                </div>
              </Form.Group>
              <Form.Group className="datePick datePicker" as={Col}>
                <div className="addInput d-flex dd1">
                  <div className="from">
                    <Form.Label><span className="ezyIcon icon-newcalendar"></span> Departure</Form.Label>


                    <DatePicker
                      className="form-control"
                      selected={departureDate1}
                      //value={state.dd1}
                      onChange={handleDepDateChange("deptOne")}
                      placeholderText="DD-MM-YYYY"
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      onCalendarOpen={activeDate1}
                      disabled={!state.dateAvailable}
                      onFocus={e => e.target.blur()}
                      popperModifiers={{
                        flip: {
                            behavior: ['bottom'] // don't allow it to flip to be above
                        },
                        preventOverflow: {
                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                        }
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Form.Row>
            {state.enableSegTwo &&
              <Form.Row>
                <Form.Group as={Col}>
                  <div className={"addInput origin dep2" + (originIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleOrigin(value, "o2")}
                      options={state.airportsOW}
                      selected={state.orignDataTwo}
                      onChange={airport => handleOnchangeMulty(airport, "srcTwo")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      //onChange={handleOnchangeMulty("srcTwo")}
                      placeholder="Select Origin"
                      readOnly
                      minLength={1}
                      onFocus={activeOrigin}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {origin2}
                    />
                    {state.clearob2 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataTwo}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegTwo}></span>
                  <div className={"addInput destination arr2" + (destinationIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleDestination(value, "d2")}
                      options={state.airportsRT}
                      selected={state.destDataTwo}
                      //onChange={handleOnchangeMulty("destTwo")}
                      onChange={airport => handleOnchangeMulty(airport, "destTwo")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Destination"
                      readOnly
                      minLength={1}
                      onFocus={activeDesti}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {destination2}
                    />
                    {state.cleardb2 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataTwo}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group className="datePick datePicker" as={Col}>
                  <div className="addInput d-flex dd2">
                    <div className="from">

                      <DatePicker
                        className="form-control multicityDatealign"
                        selected={departureDate2}
                        //value={state.dd2}
                        onChange={handleDepDateChange("deptTwo")}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={departureDate1 === undefined ? new Date() : departureDate1}
                        onCalendarOpen={activeDate2}
                        disabled={!state.dateAvailable}
                        onFocus={e => e.target.blur()}
                        popperModifiers={{
                          flip: {
                              behavior: ['bottom'] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                      <span className="datePickerIcon ezyIcon icon-newcalendar"></span>
                    </div>
                  </div>
                </Form.Group>
              </Form.Row>
            }
            {state.enableSegThree &&
              <Form.Row>
                <Form.Group as={Col}>
                  <div className={"addInput origin dep3" + (originIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleOrigin(value, "o3")}
                      selected={state.orignDataThree}
                      options={state.airportsOW}
                      //onChange={handleOnchangeMulty("srcThree")}
                      onChange={airport => handleOnchangeMulty(airport, "srcThree")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Origin"
                      readOnly
                      minLength={1}
                      onFocus={activeOrigin}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {origin3}
                    />
                    {state.clearob3  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataThree}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegThree}></span>
                  <div className={"addInput destination arr3" + (destinationIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleDestination(value, "d3")}
                      options={state.airportsRT}
                      //onChange={handleOnchangeMulty("destThree")}
                      onChange={airport => handleOnchangeMulty(airport, "destThree")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      selected={state.destDataThree}
                      placeholder="Select Destination"
                      readOnly
                      minLength={1}
                      onFocus={activeDesti}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {destination3}
                    />
                    {state.cleardb3  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataThree}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group className="datePick datePicker" as={Col}>
                  <div className="addInput d-flex dd3">
                    <div className="from">

                      <DatePicker
                        className="form-control multicityDatealign"
                        selected={departureDate3}
                        //value={state.dd3}
                        onChange={handleDepDateChange("deptThree")}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={departureDate2 === undefined ? new Date() : departureDate2}
                        onCalendarOpen={activeDate3}
                        disabled={!state.dateAvailable}
                        onFocus={e => e.target.blur()}
                        popperModifiers={{
                          flip: {
                              behavior: ['bottom'] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                       <span className="datePickerIcon ezyIcon icon-newcalendar"></span>
                    </div>
                  </div>
                </Form.Group>
              </Form.Row>
            }
            {state.enableSegFour &&
              <Form.Row>
                <Form.Group as={Col}>
                  <div className={"addInput origin dep4" + (originIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleOrigin(value, "o4")}
                      selected={state.orignDataFour}
                      options={state.airportsOW}
                      //onChange={handleOnchangeMulty("srcFour")}
                      onChange={airport => handleOnchangeMulty(airport, "srcFour")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Origin"
                      readOnly
                      minLength={1}
                      onFocus={activeOrigin}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {origin4}
                    />
                    {state.clearob4 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataFour}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegFour}></span>
                  <div className={"addInput destination arr4" + (destinationIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleDestination(value, "d4")}
                      options={state.airportsRT}
                      selected={state.destDataFour}
                      //onChange={handleOnchangeMulty("destFour")}
                      onChange={airport => handleOnchangeMulty(airport, "destFour")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Destination"
                      readOnly
                      minLength={1}
                      onFocus={activeDesti}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {destination4}
                    />
                    {state.cleardb4 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataFour}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group className="datePick datePicker" as={Col}>
                  <div className="addInput d-flex dd4">
                    <div className="from">

                      <DatePicker
                        className="form-control multicityDatealign"
                        selected={departureDate4}
                        //value={state.dd4}
                        onChange={handleDepDateChange("deptFour")}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={departureDate3 === undefined ? new Date() : departureDate3}
                        onCalendarOpen={activeDate4}
                        disabled={!state.dateAvailable}
                        onFocus={e => e.target.blur()}
                        popperModifiers={{
                          flip: {
                              behavior: ['bottom'] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                       <span className="datePickerIcon ezyIcon icon-newcalendar"></span>
                    </div>
                  </div>
                  {state.enableSegFour && !state.enableSegFive && state.dateAvailable && state.routeAvailable &&
                    <Button size="md" className="rout closeMe" onClick={handleRemoveSector} variant="link">
                      <span className="closeSeg ezyIcon icon-close"> Delete</span></Button>
                  }
                </Form.Group>
              </Form.Row>
            }
            {state.enableSegFive &&
              <Form.Row>
                <Form.Group as={Col}>
                  <div className={"addInput origin dep5" + (originIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleOrigin(value, "o5")}
                      options={state.airportsOW}
                      selected={state.orignDataFive}
                      //onChange={handleOnchangeMulty("srcFive")}
                      onChange={airport => handleOnchangeMulty(airport, "srcFive")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Origin"
                      readOnly
                      minLength={1}
                      onFocus={activeOrigin}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {origin5}
                    />
                    {state.clearob5 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataFive}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegFive}></span>
                  <div className={"addInput destination arr5" + (destinationIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleDestination(value, "d5")}
                      options={state.airportsRT}
                      selected={state.destDataFive}
                      //onChange={handleOnchangeMulty("destFive")}
                      onChange={airport => handleOnchangeMulty(airport, "destFive")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Destination"
                      readOnly
                      minLength={1}
                      onFocus={activeDesti}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref={destination5}
                    />
                    {state.cleardb5 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataFive}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group className="datePick datePicker" as={Col}>
                  <div className="addInput d-flex dd5">
                    <div className="from">

                      <DatePicker
                        className="form-control multicityDatealign"
                        selected={departureDate5}
                        //value={state.dd5}
                        onChange={handleDepDateChange("deptFive")}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={departureDate4 === undefined ? new Date() : departureDate4}
                        onCalendarOpen={activeDate5}
                        disabled={!state.dateAvailable}
                        onFocus={e => e.target.blur()}
                        popperModifiers={{
                          flip: {
                              behavior: ['bottom'] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                       <span className="datePickerIcon ezyIcon icon-newcalendar"></span>
                    </div>
                  </div>
                  {state.enableSegFive && !state.enableSix && state.dateAvailable && state.routeAvailable &&
                    <Button size="md" className="rout closeMe" onClick={handleRemoveSector} variant="link">
                      <span className="closeSeg ezyIcon icon-close"> Delete</span></Button>
                  }
                </Form.Group>
              </Form.Row>
            }
            {state.enableSegSix &&
              <Form.Row>
                <Form.Group as={Col}>
                  <div className={"addInput origin dep6" + (originIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleOrigin(value, "o6")}
                      options={state.airportsOW}
                      selected={state.orignDataSix}
                      //onChange={handleOnchangeMulty("srcSix")}
                      onChange={airport => handleOnchangeMulty(airport, "srcSix")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Origin"
                      readOnly
                      minLength={1}
                      onFocus={activeOrigin}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {origin6}
                    />
                    {state.clearob6  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearorignDataSix}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClickSegSix}></span>
                  <div className={"addInput destination arr6" + (destinationIsFocus ? " active" : "")}>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                      onInputChange={value => handleDestination(value, "d6")}
                      options={state.airportsRT}
                      selected={state.destDataSix}
                      //onChange={handleOnchangeMulty("destSix")}
                      onChange={airport => handleOnchangeMulty(airport, "destSix")}
                      filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                      placeholder="Select Destination"
                      readOnly
                      minLength={1}
                      onFocus={activeDesti}
                      disabled={!state.routeAvailable}
                      renderMenuItemChildren={(airport) => (
                        <><span className="ezyIcon icon-plane"></span>
                          <strong> {airport.cityName} </strong>
                          <span>{airport.countryName} </span>
                          <small>{airport.airportCode}</small>
                        </>
                      )}
                      ref = {destination6}
                    />
                    {state.cleardb6 && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={cleardestDataSix}
                variant="link">
              </Button>
              }
                  </div>
                </Form.Group>

                <Form.Group className="datePick datePicker" as={Col}>
                  <div className="addInput d-flex dd6">
                    <div className="from">

                      <DatePicker
                        className="form-control multicityDatealign"
                        selected={departureDate6}
                        //value={state.dd6}
                        onChange={handleDepDateChange("deptSix")}
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        minDate={departureDate5 === undefined ? new Date() : departureDate5}
                        onCalendarOpen={activeDate6}
                        disabled={!state.dateAvailable}
                        onFocus={e => e.target.blur()}
                        popperModifiers={{
                          flip: {
                              behavior: ['bottom'] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                      <span className="datePickerIcon ezyIcon icon-newcalendar"></span>
                    </div>
                  </div>
                  {state.enableSix && state.dateAvailable && state.routeAvailable &&
                    <Button size="md" className="rout closeMe" onClick={handleRemoveSector} variant="link">
                      <span className="closeSeg ezyIcon icon-close"> Delete</span></Button>
                  }
                </Form.Group>
              </Form.Row>
            }
            {!state.enableSegSix && state.dateAvailable && state.routeAvailable &&
              <Form.Row>
                <div className="addMoreFlight">
                  <Button size="md" className="addMe" onClick={handleAddSector} variant="link">
                    <span className="mr-1 plus ezyIcon icon-plus"> Add more flights</span></Button>
                </div>
              </Form.Row>
            }
          </Col>
          <Col className="selectTrvel">
            <Form.Row>
              <Form.Group as={Col} className="traveller" ref={refOutClick}>
                <div className="addInput">
                  <Form.Label><span className="ezyIcon icon-usernew"></span> Traveller & Class</Form.Label>
                  <Form.Control onClick={handleChange} type="text" placeholder={state.paxCount + " Traveller, " + state.cls} autoComplete="off" />
                </div>
                {addTraveller && <AddTravellerClass sendData={paxCount} sendClass={clsType} closetravaler={coloseTravel} sendCount={[state.adt, state.chd, state.inf, state.cls]} flowType={props.flowType} oldCls={props.oldCls}/>}
              </Form.Group>
              <Form.Group as={Col} className="preferred pl-0 mb-0" xs={7}>
              <div className="addInput">
         <Form.Label><span className="ezyIcon icon-star"></span> Preferred Airline</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey={air => `${air.label} [${air.value}]`}
                  readOnly
                  //onChange={handleAirChange}
                  onChange={air => handleAirChange(air)}
                  options={airlineMaster}
                  placeholderText="Enter Airline"
                  placeholder="Select Airline"
                  //selected={state.pa}
                  selected={state.selAir}
                  ref = {airref}
                />
                {state.clearair && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearPrf}
                variant="link">
              </Button>}
              </div>
              </Form.Group >
              <Form.Group className="searchHere" as={Col}>
                <Button
                  size="lg" block
                  className="d-flex"
                  onClick={checkFlightSearch}
                  variant="primary"
                ><span className={"d-flex loading ezyIcon icon-newsearch " + (isLoad ? 'progress-bar-striped progress-bar-animated' : '')}>SEARCH{isLoad ? <> </> : <>  {!state.ismodify ? "" : ""}  </>} </span></Button>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
        {props.flowType !== "REISSUE" &&
          <Form.Row className="nonStopflgt">
            <Col className="addAirportCode">
                            <Form.Group className="m-0" controlId="ns">
                <Form.Check type="checkbox" checked={state.ns} value={state.ns} label="Non stop flight" onClick={handleChangeNs} custom />
              </Form.Group>
            </Col>
          </Form.Row>
        }
      </div>
      <Modal
        show={state.showInboundPopup}
        //onHide={handlePriceClose}
        className="importPNRMdl comingtoFifa"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
                          <Modal.Title>Coming to Qatar?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <img src={Fifacup} />
              <p>You need Hayya card to enter the state of Qatar between 1st Novmber 2022 - 23rd December 2022 to access the stadiums.(Non Citizens & RP Holders)</p>
             <span><a className="d-inline-flex" href="https://www.qatar2022.qa/en/coming-to-qatar" target="_blank">For more info : <span> www.qatar2022.qa/en/coming-to-qatar</span></a></span>
              </Modal.Body>
        <Modal.Footer className="justify-content-end">
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={closePopup}
            >No</Button>
            <Button
              size="xs"
              onClick={handleMultiSearch}
              variant="outline-primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MultiCitySearch;
