import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import PickList from '../../../common/pickList/PickList';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import ApplicableDates from '../../common/ApplicableDates';
import Routes from '../../common/Routes';
import {
  companyList, creation,
  deleteDealcodeApplicableData, deleteDealcodeinventoryData, deleteDealcodeRBDData,
  deleteDealcodeRoutesData, getBranches, officeidList, update,getOfficeIdListS,fetch_company_list
} from './operations';
import RegionType from '../../common/RegionType';
import SalesChannel from "../../common/SalesChannel";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';

let airlineMaster = require('../../../masterData/AirlinesMasterData.json');
let class_Code_List = require('../../../masterData/BookingClassCode.json');

/**
 * Initial State Declaration
 */
const initialState = {
  dealcodeName: '', dealcodeType: [], dealcodeValue: '', isActive: true, commissionType: [], comissionOn: [], amountvalue: '', travelType: [],supplierType: [],
  airlineName: [], codeshare: [], applDates: [], rts: [], classType: [], classCode: [], notiMessageShow: false, notiMessage: '',
  notiVarient: '', ta_company_type: false, class_json: [], att_user: false, login_branch_data: [], sel_Branch: [], sel_Branch_Companies: [],
  office_id_masterdata: [], sel_company_data: [], sel_officeid_data: [], save_oficeid_json: [], inventory_display_data: [],
  resetPick1: true, resetPick2: false, edit_response: [], is_edit_dealcode: false, rts_edit_data: [], deal_code_id: '', markup_disc: '', mrkp_Disc:false, disableDiscountEdit:true,
  comission_List: [], airlinesList: [], nameError: false, dealcodeError: false, airlineError: false,region_type:"",branchList:[],RegionSelected:false,selBranch:[],region_type_edit:"",resetValue:false,  isItineraryLoading:false,isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,sales_chanel:"",edit_salesChanel:""

};


//temp varibale for class
let cls_com_json = [];

//temp varibale for officeid and branch
let br_Of_json = [];

//logged in username and user id
let loggedin_uname;
let loggedin_uid;



const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function DealCodeConfig(props) {


  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [disableButton, setDisableButton] = useState();
  const [data, setData] = useState([])


  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);

  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })

  }, []);

  const dealCodeApplied = [{ label: "Backend Discount", value: "1" },
  { label: "Up-Front Discount", value: "2" },
  { label: "Nego-Rate", value: "3" },
  { label: "Corporate Deal", value: "4" },]

  const travelType = [{ label: "All", value: "0" }, { label: "One Way", value: "1" },
  { label: "Round Trip", value: "2" }, { label: "Multi City", value: "3" }]
  const supplierType=[{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6},{label: "Riya Travel", value: 7}];


  const commissionType = [{ label: "% Percentage", value: "percentage" },
  { label: "Value", value: "value" }]

  const commission_On = [{ label: "Base Fare", value: "BF" },
  { label: "Total Fare", value: "TF" }, { label: "Base Fare+YQ", value: "YQ" }, { label: "Base Fare+YR", value: "YR" }]

  const codeShare = [{ label: "Yes", value: "yes" },
  { label: "No", value: "no" }]

  const airlineclass = [{ label: "All", value: "All" },
  { label: "Economy", value: "Economy" },
  { label: "Premium Economy", value: "Premium Economy" },
  { label: "Business", value: "Business" },
  { label: "First", value: "First" }]





  useEffect(() => {

    //get user data from local storage
    const loginUserData = localStorage.getItem('userAuthData');



    //get user type
    let user_type = JSON.parse(loginUserData).utype;
    //user_type='ATT_USER';

    //checking user type,if att admin or att_staff login
    if (isNotNull(user_type) && (user_type.toUpperCase() === 'ATT_USER' || user_type.toUpperCase() === 'ATT_ADMIN')) {
      dispatch({ type: 'att_user', payload: true });
      dispatch({ type: 'ta_company_type', payload: false });

    }//if TA staff login
    else if (isNotNull(user_type) && user_type.toUpperCase() === 'TA_USER') {
      dispatch({ type: 'att_user', payload: false });
      dispatch({ type: 'ta_company_type', payload: true })
    }

    //get branch data
    const branch_id = JSON.parse(loginUserData).bid;
    const branch_name = JSON.parse(loginUserData).bnm;
    //logged uname
    loggedin_uname = JSON.parse(loginUserData).unm;
    //logged in uid
    loggedin_uid = JSON.parse(loginUserData).uid;


    /* let br_data = [];
    const obj = { value: branch_id, label: branch_name }
    const obj1 = { value: 112, label: 'nea branch112' }
    const obj2 = { value: 113, label: 'new branch 113' }
    br_data.push(obj);
    br_data.push(obj1);
    br_data.push(obj2);
    dispatch({ type: 'login_branch_data', payload: br_data }); */

    //fetch Office id Master List data
    officeidList().then((res) => {
      if (res && res.length > 0) {
        dispatch({ type: 'office_id_masterdata', payload: res })
      }
    });

  }, [context.logindata]);


  //if Edit response is avaliable then below hook will execute
  useEffect(() => {

    state.rts_edit_data = [];
    state.applDates = [];
    state.class_json = [];
    dispatch({ type: 'applDates', payload: [] });
    dispatch({ type: 'class_json', payload: [] });
    dispatch({ type: 'rts_edit_data', payload: [] });
    cls_com_json = [];
    br_Of_json = [];
    if (isNotNull(props.edit_result)) {
      dispatch({ type: 'edit_response', payload: props.edit_result });
      dispatch({ type: 'is_edit_dealcode', payload: true });
      dispatch({ type: 'dealcodeName', payload: props.edit_result.dnm });
      dispatch({ type: 'dealcodeValue', payload: props.edit_result.dcode });
      dispatch({ type: 'isActive', payload: props.edit_result.act });
      dispatch({ type: 'deal_code_id', payload: props.edit_result.id });
      dispatch({ type: 'region_type_edit', payload: props.edit_result.region_type});
      dispatch({ type: 'edit_salesChanel', payload: props.edit_result.sales_channel})
      dispatch({ type: 'ofcs', payload: [{ value:props.edit_result.office_id, label: props.edit_result.office_label }] });
      dispatch({ type: "officeIds", payload: [{ value:props.edit_result.office_id, label: props.edit_result.office_label }] });
      //setting deal type value
      let deal_type = dealCodeApplied.filter((b, index) => parseInt(b.value) === parseInt(props.edit_result.dty));
      let sup_type = supplierType.filter((b, index) => parseInt(b.value) === parseInt(props.edit_result.sup));


      dispatch({ type: 'dealcodeType', payload: deal_type });
      dispatch({ type: 'supplierType', payload: sup_type });

      //setting Travel type value
      let trave_type = travelType.filter((b, index) => parseInt(b.value) === parseInt(props.edit_result.tt));
      dispatch({ type: 'travelType', payload: trave_type });

      //setting airline type value
      let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })
      let airline_type = airlines.filter((b, index) => b.value === props.edit_result.al);
      console.log("edit airlines",airline_type,state.airlinesList)
      dispatch({ type: 'airlineName', payload: getUnique(airline_type, "value") });

      //setting airline type value
      let codeshare_type = codeShare.filter((b, index) => isNotNull(b.value) && (b.value.toUpperCase() === props.edit_result.csha.toUpperCase()));
      dispatch({ type: 'codeshare', payload: codeshare_type });

      //setting Comission type value
      if (isNotNull(props.edit_result.comtyp)) {
        let com_type = commissionType.filter((b, index) => isNotNull(b.value) && (b.value.toUpperCase() === props.edit_result.comtyp.toUpperCase()));
        dispatch({ type: 'comissionType', payload: com_type });
      }

      //setting Comission on value
      if (isNotNull(props.edit_result.comon)) {
        let com_on = commission_On.filter((b, index) => isNotNull(b.value) && (b.value.toUpperCase() === props.edit_result.comon.toUpperCase()));
        dispatch({ type: 'comissionOn', payload: com_on });
      }

      //setting Comission amt value
      dispatch({ type: 'amountvalue', payload: props.edit_result.camount });
      dispatch({ type: 'markup_disc', payload: props.edit_result.com_disc });
      dispatch({type:"disableDiscountEdit", payload:false})





      //constructing Applicable Edit display data
      dispatch({ type: 'applDates', payload: props.edit_result.apdate });

      //constructing source and destination Edit display data

      for (let value of props.edit_result.rt) {
        let srcArray = value.srcair.split(",");
        let destArray = value.destair.split(",");

        //converting array and removed duplicates
        let airs = srcArray.filter((q, idx) => srcArray.indexOf(q) === idx)
        let dirs = destArray.filter((q, idx) => destArray.indexOf(q) === idx)

        if (airs) {
          airs.map((obj) => {
            var route = "";
            if (obj && obj !== undefined) {
              let src_ = [];
              src_.push(obj);
              route = {
                "deal_id": props.edit_result.id,
                "srcct": value.srcct,
                "scname": value.scname + " " + "[" + obj + "]",
                "destct": value.destct,
                "dcname": value.dcname + " " + "[" + dirs + "]",
                "srcair": src_,
                "destair": dirs,
                "is_db": value.is_db
              }
            }
            state.rts_edit_data.push(route);
            dispatch({ type: 'rts_edit_data', payload: state.rts_edit_data });
          }
          );
        }
      }


      //Edit display Class commission Data
      state.class_json = [];
      if (isArrayNotEmpty(props.edit_result.cls)) {

        for (let c in props.edit_result.cls) {
          let data = props.edit_result.cls[c];
          const class_data = {
            cid: data.cid,
            ty: data.ty,
            rbd: data.rbd,
          }
          state.class_json.push(class_data);
          cls_com_json.push(class_data);
          dispatch({ type: 'class_json', payload: state.class_json });
        }
      }

      //Constructing edit office id and comapny data
      //state.save_oficeid_json = [];
      if (isArrayNotEmpty(props.edit_result.fmap)) {

        for (let f in props.edit_result.fmap) {

          let data = props.edit_result.fmap[f];

          const obj = {
            fmap_id: data.fmap_id ? data.fmap_id : undefined,
            ctyp: data.ctyp,
            id: data.id,
            bid: data.bid,
            bnm: data.bnm,
            cid: data.cid,
            cnm: data.cnm,
            of_id: data.oid,
            label: props.edit_result.office_label,
            value: data.value
          }
          br_Of_json.push(obj)

        }


        //handling duplicates for br_json
        let br_jsondata = br_Of_json.filter(function (a) {
          let key = '';
          if (isNotNull(a.ctyp) && a.ctyp.toUpperCase() === 'ATT') {
            key = a.ctyp + '|' + a.bid + '|' + a.value;
          } else if (isNotNull(a.ctyp) && a.ctyp.toUpperCase() === 'TA') {
            key = a.ctyp + '|' + a.cid + '|' + a.value;
          }

          if (!this[key]) {
            this[key] = true;
            return true;
          }
        }, Object.create(null));


        dispatch({ type: 'inventory_display_data', payload: br_jsondata });

      }

      // const obj = {
      //   uid: context.logindata.uid,
      //   region_type:props.edit_result.region_type
      // }
      // AttCommonSerivce.loadBranches(obj).then((res) => {
      //   if (res && res.length > 0) {
      //     dispatch({ type: 'login_branch_data', payload: res })
      //   }
      // });


    } else {
      state.rts_edit_data = [];
      state.applDates = [];
      state.class_json = [];
      dispatch({ type: 'applDates', payload: state.applDates });
      dispatch({ type: 'class_json', payload: state.class_json });
      dispatch({ type: 'rts_edit_data', payload: state.rts_edit_data });
      dispatch({ type: 'is_edit_dealcode', payload: false });
      cls_com_json = [];
      br_Of_json = [];

    }

  }, [isNotNull(props.edit_result)]);
  useEffect(()=>{
    console.log("oooooooooooooooooooooooooooooooooo",state.ofcs)
  },[state.ofcs])
  useEffect(() => {
    if (state.sales_chanel!== "" && state.region_type !== "") {
       const payload = {"sales_channel": state.sales_chanel, "region_type": state.region_type}
       getOfficeIdListS(payload).then(response => {
          if (response.data.suc) {
            if (response.data.res) {
              let changedOfficeId = response.data.res
              // let authData = []
              // let officeIds = response.data.res.filter((val)=>{
              //   return val.office_id_val != null;
              // })
              // for (let val of officeIds){
              //   let auth = {
              //     "value": val.id,
              //     "label": val.office_id_val
              //   }
              //   authData.push(auth)
              // }
              dispatch({ type: 'officeIds', payload: changedOfficeId })
              dispatch({ type: 'enableOfcs', payload: true })
            }
          }
        }).catch(function (error) {
          console.log("dddddddddddddddddddddddddddddddd",error)
          console.log(
            "Exception occured in getBranchData function---" + error
          );
        });
       }
  }, [state.sales_chanel,state.region_type]);
  useEffect(() => {
    if(!state.enableDevices){
      dispatch({ type: 'sel_Branch_Companies', payload: [] })     }
    if(state.enableDevices && state.ofcID){
      const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}
              fetch_company_list(payload).then(response => {
          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "label": val.company_name,
            "value": val.fk_company_id,
            "sales_channel": val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            dispatch({ type: 'sel_Branch_Companies', payload: cmpanis })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
    }
  }, [!state.enableDevices,state.ofcID]);
  useEffect(() => {
    if(state.ofcID ){
      const payload = {"sales_channel": state.sales_chanel, "region_type": state.region_type, "userid": context.logindata.uid}

      AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}).then(response => {  
        if (response.data.suc){
          let authData = []
          let BRANCHES = response.data.res.filter((val)=>{
                  return val.fk_branch_id != null;
                })
                for (let val of BRANCHES){
                  let auth = {
                    "value": val.fk_branch_id
                    ,
                    "label": val.office_name
                  }
                  authData.push(auth)
                }
          let branchesList = authData.map((branch) => (
            <option value={branch.value}>{branch.label}</option>
          ));
          state.branchMenu = branchesList;
          dispatch({ type: "branchList", payload: authData });
          // dispatch({ type: "branchList", payload: authData });
        }
      }).catch(function (error) {
        console.log(
          "Exception occured in Loadi branches based on Region function---" + error
        );
      });
    }
  }, [state.ofcID])

  /**
   * @description:Handle Change Event
   * @author:Rambabu
   * @param {*} e
   * @date:15-10-2020
   */
  const sendResponse = (response)=>{
    let branchesList = response.map((branch) =>
    <option value={branch.value}>{branch.label}</option>
  );
  dispatch({ type: 'login_branch_data', payload: branchesList })
  }
  // const handleRegionType = (val)=>{
  //   dispatch({type:"region_type",payload: val})
  //   dispatch({type:"RegionSelected", payload: true })
  // }
  const handleRegionType = (e) => {
    state.branchList = e
    // dispatch({ type: "branchList", payload: e });
    dispatch({ type: "regionError", payload: false });
    dispatch({ type: "selBranch", payload: [] });
    dispatch({ type: "selTa", payload: [] });
}

const updateRegion=(region)=>{
    dispatch({ type: "region_type", payload: region });
  }
  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    // dispatch({ type: 'ofcs', payload:"" })
    // setCompanyPick([])
    // props.sendChannel(salesChanel)
  }
  const updateOfcId=(ofc)=>{
    dispatch({type:"ofcs", payload:ofc})
    if(ofc.length!==0){
     if(state.sales_chanel!==""){
        if(state.sales_chanel==="B2C"){
          let payload="";
          if(context.logindata.is_admin){
            payload={ofc_id:ofc[0].value}
          }else{
            payload={ofc_id:ofc[0].value,uid:context.logindata.uid}
          }
          AttCommonSerivce.att_companies(payload).then(response => {
            if (response.data.suc && response.data.res.status === 200) {
              let authData = []
              for (let val of response.data.res.data){
                let auth = {
                  "value": val.value,
                  "label": val.label
                }
                authData.push(auth)
              }
              // setCompanyPick(authData)
            }
          }).catch(function (error) {
           console.log(
              "Exception occured in getBranchData function---" + error
            );
          });
        }else{
          let payload="";
          if(context.logindata.is_admin){
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,}
          }else{
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_chanel:state.sales_chanel,uid:context.logindata.uid}
          }

        }
     }else if(state.sales_chanel===""){

     }else if(state.region_type===""){
     }
    }
    dispatch({ type: "ofcID", payload: ofc.length!=0?ofc[0].value:"" });


    // props.sendOfcId(ofc)
  }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }

  const handleDiscount = (e)=> {
    dispatch({type:"mrkp_Disc", payload:false})
    if (Number(state.amountvalue)>=Number(e.target.value)){
    dispatch({type:"markup_disc", payload:e.target.value})
  } else { 
    dispatch({type:"mrkp_Disc", payload:true})
   }
  }
  const handleDealCode = (e) => {
    if (state.disableDiscountEdit){
      dispatch({type:"markup_disc", payload:""})}
    dispatch({ type: 'dealcodeType', payload: e })
    dispatch({type: "dealcodeTypeError", payload: false})
  }
  const handleChange = (e) => {
    if (state.disableDiscountEdit){
      dispatch({type:"markup_disc", payload:""})}
    const { id, value } = e.target;

    //Deal code name
    if (id === 'dealName') {

      dispatch({ type: 'dealcodeName', payload: value });
      dispatch({ type: "nameError", payload: false })


    } //Deal / Corporate Code / Cryptic
    else if (id === 'dealCode') {
      dispatch({ type: 'dealcodeValue', payload: value });
      dispatch({ type: 'dealcodeError', payload: false });


    } //Status
    else if (id === 'act') {
      dispatch({ type: 'isActive', payload: e.target.checked });

    }
    //Enter Amount value
    else if (id === 'amtid') {
      dispatch({ type: 'amountvalue', payload: value });

    }

  }



  /**
   * @description:Applicable config Data
   * @author:Rambabu
   * @param {*} e
   * @date:15-10-2020
   */
  const applicableDates = (ad) => {

    state.applDates.push(ad);
    dispatch({ type: 'applDates', payload: state.applDates })
  }



  /**
   * @description:Routes config Data
   * @author:Rambabu
   * @param {*} e
   * @date:15-10-2020
   */
  const routesInfo = (route) => {

    state.rts.push(route);
    dispatch({ type: 'rts', payload: state.rts })
  }


  /**
   * @description:Routes Delete Data
   * @author:Rambabu
   * @param {*} e
   * @date:20-10-2020
   */

  const routeDelete = (data) => {

    const payload = {
      dealcode_id: data.deal_id,
      src_air: data.srcair,
      desc_air: data.destair
    }



    deleteDealcodeRoutesData(payload).then((res) => {
      if (isNotNull(res)) {

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: res })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispalceFunction()

      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Routes not deleted successfully' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispalceFunction()

      }
    });

  }


  /**
   * @description:Applicable Delete Data
   * @author:Rambabu
   * @param {*} e
   * @date:20-10-2020
   */
  const deleteApplicable = (id) => {

    deleteDealcodeApplicableData(id).then((res) => {
      if (isNotNull(res)) {

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: res })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispalceFunction()

      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Applicable Config not deleted successfully' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispalceFunction()

      }
    });
  }
  /**
   * @description:when we select booking code below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*} e
   */
  const bookicodeEvent = (e) => {
    //console.log('bookicodeEvent---' + JSON.stringify(e));
    dispatch({ type: 'classCode', payload: e })
  }


  /**
   * @description:when we click RBD Add button below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*} e
   */
  const addRBDBtn = () => {

    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })


    if (isArrayNotEmpty(state.classType) && isArrayNotEmpty(state.classCode)) {
      let code = '';
      for (let c in state.classCode) {
        let code_data = state.classCode[c];
        if (!isNotNull(code)) {
          code = code_data.value;
        } else {
          code = code + '|' + code_data.value;
        }
      }
      const class_data = {
        ty: (isArrayNotEmpty(state.classType[0].value) ? state.classType[0].value : '-'),
        rbd: (isNotNull(code) ? code : '-'),
      }

      cls_com_json.push(class_data);


      //validating duplicate json
      let jsondata = getUnique(cls_com_json, "ty");
      // if(state.RegionSelected === false){
      //   dispatch({ type: 'notiMessageShow', payload: true })
      //   dispatch({ type: 'notiMessage', payload: 'Please select Region type ' })
      //   dispatch({ type: 'notiVarient', payload: 'danger' })
      //   dispalceFunction()
      // }
      if (cls_com_json.length !== jsondata.length) {
        cls_com_json = jsondata;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Class type already exist' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        dispalceFunction()

      } else {

        let cls_filter_data = [];
        //checking All, if classtype is All avaliable not allowing any class type
        if (isArrayNotEmpty(cls_com_json) && cls_com_json.length > 1) {
          cls_filter_data = cls_com_json.filter((cl, sidx) => cl.ty.toString() === 'All');
        }

        if (isArrayNotEmpty(cls_filter_data)) {
          //if json having all then we are removing all data and assging to cls_com_json.
          let remove_all_data = cls_com_json.filter((cl, sidx) => cl.ty.toString() !== 'All');
          cls_com_json = remove_all_data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class type already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()
        } else {
          state.class_json.push(class_data);
          dispatch({ type: 'class_json', payload: state.class_json });
        }

      }
      //after add button clear the fileds
      dispatch({ type: 'classType', payload: [] })
      dispatch({ type: 'classCode', payload: [] })

    } else {

      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Class & rbd required' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispalceFunction()

    }

  }

  /**
  * @description:when we click class Remove button below event will call
  * @date:15-10-2020
  * @author:Rambabu
  * @param {*} data
  * @param {*} index
  */
  const deleteRBDBtn = (data, index) => () => {


    let filter_data = state.class_json.filter((cls, sidx) => index !== sidx);
    dispatch({ type: 'class_json', payload: filter_data });
    let filter_cls_com_json = cls_com_json.filter((cls, sidx) => cls.ty.toString() !== data.ty.toString());

    cls_com_json = filter_cls_com_json;

    //if id is there in object that is db record,so we have to delete that record
    if (isNotNull(data.cid)) {

      deleteDealcodeRBDData(data.cid).then((res) => {
        if (isNotNull(res)) {

          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: res })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()

        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class (RBD) not deleted successfully' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()

        }
      });

    }
  }


  /**
  * @description:when we select ATT radio button below event will call
  * @date:15-10-2020
  * @author:Rambabu
  */
  const attType = () => {
    console.log('Entere att type select event---');
    if (state.resetPick1) {
      dispatch({ type: 'resetPick2', payload: true })
      dispatch({ type: 'resetPick1', payload: false })
    } else {
      dispatch({ type: 'resetPick2', payload: false })
      dispatch({ type: 'resetPick1', payload: true })

    }
    dispatch({ type: 'sel_company_data', payload: [] });
    dispatch({ type: 'ta_company_type', payload: false })
  }

  /**
  * @description:when we select TA Radio button below event will call
  * @date:15-10-2020
  * @author:Rambabu
  */
  const taType = () => {
    console.log('Entere ta type select event---');
    if (state.resetPick1) {
      dispatch({ type: 'resetPick2', payload: true })
      dispatch({ type: 'resetPick1', payload: false })
    } else {
      dispatch({ type: 'resetPick2', payload: false })
      dispatch({ type: 'resetPick1', payload: true })

    }
    dispatch({ type: 'sel_company_data', payload: [] });
    dispatch({ type: 'ta_company_type', payload: true })
  }


  /**
   * @description:when we select TA,branch drop down will be display,when we select branch below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*} e
   */
  const taSelBranchEvent = (e) => {
    if (state.resetPick1) {
      dispatch({ type: 'resetPick2', payload: true })
      dispatch({ type: 'resetPick1', payload: false })
    } else {
      dispatch({ type: 'resetPick2', payload: false })
      dispatch({ type: 'resetPick1', payload: true })

    }
    dispatch({ type: 'sel_Branch', payload: e })
    //fetch company list based on branch id
    const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel,"branch_id":e[0].value}
    fetch_company_list(payload).then(response => {
      if (response.data.suc) {
        let authData = []
        for (let val of response.data.res){
          let auth = {
            "label": val.company_name,
            "value": val.fk_company_id,
            "sales_channel": val.sales_channel
          }
          authData.push(auth)
        }
        let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
        dispatch({ type: 'sel_Branch_Companies', payload: cmpanis })
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });

  }

  /**
  * @description:when we select pick list data below event will call
  * @date:15-10-2020
  * @author:Rambabu
  * @param {*} data
  * @param {*} id
  */
  const pickldata = (data, id) => {


    //if branch or company selection
    if (isNotNull(id) && (id === 'b_id' || id === 'c_id')) {
      dispatch({ type: 'sel_company_data', payload: data });
      setData(data);
    }
    //if office id selection
    else if (isNotNull(id) && id === 'inv_id') {
      dispatch({ type: 'sel_officeid_data', payload: data })
    }

  }


  /**
   * @description:when we select (Branch or compant and inventory) Add button below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*}
   */
  const inventoryAddBtn = () => {

    if (isArrayNotEmpty(state.sel_company_data)) {

      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })

      //here ta_company_type varibale true then ta_company_type is TA,other wise user type is ATT
      const u_type = state.ta_company_type ? "TA" : "ATT";

      // for (let o in state.sel_officeid_data) {
      //   let office_data = state.sel_officeid_data[o];
      for (let b in state.sel_company_data) {
        let b_data = state.sel_company_data[b];

        //if user type is att copany id and cid will not be there
        if (u_type === 'ATT') {
          const obj = {
            ctyp: u_type,
            bid: state.sel_Branch.length!==0 ? state.sel_Branch[0].value : '-',
            bnm: state.sel_Branch.length!==0 ? state.sel_Branch[0].label : '-',
            cid: b_data.value,
            cnm: b_data.label,
            label: "DOHQR2718",
            value: 1
          }
          br_Of_json.push(obj);

        }//if user type is TA company id,comany name,branch id and branch id will be avaliable
        else if (u_type === 'TA') {
          const obj = {
            ctyp: u_type,
            bid: state.sel_Branch.length!==0 ? state.sel_Branch[0].value : '-',
            bnm: state.sel_Branch.length!==0 ? state.sel_Branch[0].label : '-',
            cid: b_data.value,
            cnm: b_data.label,
            label: "DOHQR2718",
            value: 1
          }
          br_Of_json.push(obj);
        }
      }
      //}



      if (isArrayNotEmpty(br_Of_json)) {


        let jsondata = br_Of_json.filter(function (a) {
          let key = '';
          if (a.ctyp.toUpperCase() === 'ATT') {
            key = a.ctyp + '|' + a.bid + '|' + a.value;
          } else if (a.ctyp.toUpperCase() === 'TA') {
            key = a.ctyp + '|' + a.cid + '|' + a.value;
          }
          if (!this[key]) {
            this[key] = true;
            return true;
          }
        }, Object.create(null));


        //checking the duplicates based on length(original json and unique json length)
        if (br_Of_json.length !== jsondata.length) {
          br_Of_json = jsondata;
          dispatch({ type: 'inventory_display_data', payload: jsondata });
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Configuration already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()
        } else {
          dispatch({ type: 'inventory_display_data', payload: jsondata });
          //dispatch({ type: 'save_oficeid_json', payload: uni_off });
          if (state.resetPick1) {
            dispatch({ type: 'resetPick2', payload: true })
            dispatch({ type: 'resetPick1', payload: false })
          } else {
            dispatch({ type: 'resetPick2', payload: false })
            dispatch({ type: 'resetPick1', payload: true })

          }
        }


      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: '' })
        dispatch({ type: 'notiVarient', payload: '' })
        dispalceFunction()
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter required fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispalceFunction()
    }
  }


  /**
   * @description:when we select inventory Remove button below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*} data
   * @param {*} index
   */
  const inventoryDeleteBtn = (data, index) => () => {

    //removed data from displayed data table resepective index
    let com_filter_data = state.inventory_display_data.filter((com, sidx) => index !== sidx);
    dispatch({ type: 'inventory_display_data', payload: com_filter_data })

    //find index of exact matched data json object and remove that object from temp array

    let indexvvv = '';
    if (data.ctyp!==undefined && data.ctyp.toUpperCase() === 'TA') {
      //com_filter_data = state.inventory_display_data.filter((com, sidx) => com.cid !== data.cid && com.value !== data.value);
      //dispatch({ type: 'inventory_display_data', payload: com_filter_data });
      indexvvv = br_Of_json.findIndex(obj => (obj.cid === data.cid && obj.value === data.value));

    } else if (data.ctyp!==undefined && data.ctyp.toUpperCase() === 'ATT') {
      //com_filter_data = state.inventory_display_data.filter((com, sidx) => com.bid !== data.bid && com.value !== data.value);
      //dispatch({ type: 'inventory_display_data', payload: com_filter_data })
      indexvvv = br_Of_json.findIndex(obj => (obj.bid === data.bid && obj.value === data.value));

    }

    var array = [...br_Of_json]; // make a separate copy of the array
    array.splice(indexvvv, 1);
    br_Of_json = array;

    //dispatch({ type: 'save_oficeid_json', payload: off_id });
    dispatch({ type: 'sel_company_data', payload: [] })
    dispatch({ type: 'sel_officeid_data', payload: [] })

    //if id is there in object that is db record,so we have to delete that record
    if (isNotNull(data.fmap_id)) {

      const obj = {
        fmap_id: data.fmap_id
      }

      deleteDealcodeinventoryData(obj).then((res) => {
        if (isNotNull(res)) {

          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "Deleted successfully" })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()
        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Office id not deleted successfully' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()
        }
      });

    }
  }

  const dispalceFunction = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  /**
   * @description:when we select Save Deal code button below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*}
   */
  const handleSaveDealCodeBtn = (e) => {
    console.log('Enter handleSaveCommissionBtn---' + JSON.stringify(state.inventory_display_data));

    //state.applDates
    //state.rts
    //state.class_json
    //state.ta_company_type
    //state.inventory_display_data
    //state.save_oficeid_json
    let namevalidations = false
    let dealcodevalidations = false
    // if (isArrayNotEmpty(state.applDates) && isArrayNotEmpty(state.inventory_display_data) && state.dealcodeName !== '' && state.dealcodeType.length > 0 && state.airlineName.length > 0) {
      if (state.dealcodeType && state.dealcodeType.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'dealcodeTypeError', payload: true })
      }
      if (state.airlineName && state.airlineName.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'airlineError', payload: true })
      }
      if (characterValidation(state.dealcodeValue)) {


        dealcodevalidations = true
        dispatch({ type: "dealcodeError", payload: false })
      } else {
        dispatch({ type: "dealcodeError", payload: true })
      }
      if (characterValidation(state.dealcodeName)) {
        namevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
      if (namevalidations && state.airlineName.length > 0 && state.applDates.length > 0 && state.inventory_display_data.length > 0) {
        if (props.oldDataNames.indexOf(state.dealcodeName) === -1) {
          const main_json = {
            dnm: state.dealcodeName,
            dcode: state.dealcodeValue,
            dt: 1,
            dty: state.dealcodeType[0].value,
            tt: state.travelType.length > 0 ? state.travelType[0].value : "All",
            act: state.isActive,
            al: state.airlineName[0].value,
            csha: state.codeshare.length > 0 ? state.codeshare[0].value : "",
            comon: isArrayNotEmpty(state.comissionOn) ? state.comissionOn[0].value : null,
            comtyp: isArrayNotEmpty(state.comissionType) ? state.comissionType[0].value : null,
            camnt: state.amountvalue,
            crby: loggedin_uid,
            uid: loggedin_uid,
            utype: context.logindata.utype ? context.logindata.utype : '',
            apdate: state.applDates,
            rt: state.rts,
            cls: state.class_json,
            fmap: state.inventory_display_data,
            sup:state.supplierType.length>0?state.supplierType[0].value:null,
            //ofc: state.save_oficeid_json
            "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
            branchList:state.branchList,
            sales_channel:context.logindata.utype==="att_user"?state.sales_chanel:context.logindata.channel,
            office_id:context.logindata.utype==="att_user"?state.ofcID.toString():"All",
            com_disc:state.markup_disc,

          }

          const payload = {
            dcreq: main_json
          }
          dispatch({ type: "isItineraryLoading", payload: true });
          creation(payload).then((res) => {
            if (isNotNull(res.suc) && res.suc) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Deal code Saved Successfully' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              //window.location.reload();
              return window.location = "/ezytrip/administration/dealCode";
              //fetch all comission list
              /*  getComissionList().then((res) => {
                 dispatch({ type: 'comission_List', payload: res })
               }); */
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });

              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Deal code not saved successfully' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              dispalceFunction()
            }
          });
        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'DealCode already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispalceFunction()

        }
      // }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please add mandatory fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispalceFunction()

    }

  }

  /**
   * @description : when click on cancel set url to config component
   * @author :Azamuddin
   * @date: 03-11-2020
   */
  const handleCancel = (e) => {
    return window.location = "/ezytrip/administration/dealCode";
  }

   /**
   * @description : when click on Reset it will clear all values
   * @author :Arshad
   * @date: 21-02-2023
   */
  const handleReset = (e) => {
    dispatch({type:"sel_Branch_Companies", payload:[]})
    dispatch({type:"sel_Branch", payload:[]})
    dispatch({type:"dealcodeName", payload:''})
    dispatch({type:"dealcodeType", payload:[]})
    dispatch({type:"dealcodeValue", payload:''})
    dispatch({type:"comissionType", payload:[]})
    dispatch({type:"comissionOn", payload:[]})
    dispatch({type:"amountvalue", payload:''})
    dispatch({type:"travelType", payload:[]})
    dispatch({type:"supplierType", payload:[]})
    dispatch({type:"airlineName", payload:[]})
    dispatch({type:"codeshare", payload:[]})
    dispatch({type:"classType", payload:[]})
    dispatch({type:"classCode", payload:[]})
    dispatch({type:"sel_company_data",payload:[]})
    dispatch({type:"resetValue", payload:true})
    dispatch({type:"markup_disc", payload:""})
    setData([])
  }

  /**
   * @description:when we select Save Deal code button below event will call
   * @date:15-10-2020
   * @author:Rambabu
   * @param {*}
   */
  const handleUpdateDealCodeBtn = () => {
    console.log('Enter handleUpdateDealCodeBtn---' + JSON.stringify(state.inventory_display_data));

    //state.applDates
    //state.rts
    //state.class_json
    //state.ta_company_type
    //state.inventory_display_data
    //state.save_oficeid_json

    let namevalidations = false
    let dealcodevalidations = false
    let dealcodetypevalidations = false
    if (isArrayNotEmpty(state.applDates) && state.dealcodeName !== ''&& state.inventory_display_data.length > 0 && state.airlineName.length > 0) {
      if (characterValidation(state.dealcodeName)) {

        namevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
      if (state.dealcodeType && state.dealcodeType.length !== 0) {
        dealcodetypevalidations = true
        dispatch({ type: 'dealcodeTypeError', payload: false })
      }
      else{
        dispatch({ type: 'dealcodeTypeError', payload: true })
      }
      if (characterValidation(state.dealcodeValue)) {
        dealcodevalidations = true
        dispatch({ type: "dealcodeError", payload: false })
      } else {
        dispatch({ type: "dealcodeError", payload: true })
      }
      if (namevalidations  && state.applDates.length > 0 && dealcodevalidations && dealcodetypevalidations ) {
        const main_json = {
          id: state.deal_code_id,
          dnm: state.dealcodeName,
          dcode: state.dealcodeValue,
          dty: state.dealcodeType[0].value,
          tt: state.travelType.length > 0 ? state.travelType[0].value : "All",
          act: state.isActive,
          al: state.airlineName[0].value,
          csha: state.codeshare.length > 0 ?  state.codeshare[0].value : "",
          comon: isArrayNotEmpty(state.comissionOn) ? state.comissionOn[0].value : null,
          comtyp: isArrayNotEmpty(state.comissionType) ? state.comissionType[0].value : null,
          camnt: state.amountvalue,
          upby: loggedin_uid,
          uid: loggedin_uid,
          utype: context.logindata.utype ? context.logindata.utype : '',
          ato: null,
          apdate: state.applDates,
          rt: state.rts,
          cls: state.class_json,
          fmap: state.inventory_display_data,
          sup:state.supplierType && state.supplierType[0]?state.supplierType[0].value:null,
          "region_type":context.logindata.utype==="att_user"?state.region_type_edit:context.logindata.region_type,
          com_disc:state.markup_disc,
          //ofc: state.save_oficeid_json
          sales_channel:context.logindata.utype==="att_user"?state.sales_chanel:context.logindata.channel,
          office_id:context.logindata.utype==="att_user"?state.ofcID.toString():"All",

        }
        dispatch({ type: "isItineraryLoading", payload: true });

        const payload = {
          dcreq: main_json
        }
        update(payload).then((res) => {
          if (isNotNull(res.suc) && res.suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Deal Code updated successfully' })
            dispatch({ type: 'notiVarient', payload: 'success' });
            dispalceFunction()
            //fetch all comission list
            /* getComissionList().then((res) => {
              dispatch({ type: 'comission_List', payload: res })
            }); */
            handleCancel()
          } else {
            dispatch({ type: "isItineraryLoading", payload: false });

            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Deal code not updated successfully' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            dispalceFunction()
          }
        });
      }

    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please add mandatory fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispalceFunction()

    }

  }




  //to get unique by passing argument
  function getUnique(arr, comp) {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }


  /**
  * @description:Removed duplicates in json objects in json array based on All value comparison
  * @author:Rambabu
  * @date:14-09-2020
  * @param {*} jsonarray
  */
  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
    // console.log('myUniqueArr----' + JSON.stringify(myUniqueArr))

    return myUniqueArr;
  }


  /**
     *
     * @description:Remove duplicate data from array
     * @athor:Rambabu
     * @date:10-08-2020
     * @param {*} array
     */
  function uniqueArray(array) {
    var uniqueArray = [];
    // Loop through array values
    var i;
    for (i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }


  const updateRoutesData = (data) => {
    dispatch({ type: 'rts', payload: data })
  }


  const updateApplDates = (data) => {
    dispatch({ type: 'applDates', payload: data })
    // setDates({datesList : [data]})
  }








  return (

    //console.log('Data is return statement1 -----' + JSON.stringify(state.save_oficeid_json)),
    //console.log('Data is return statement2 -----' + JSON.stringify(state.rts)),

    <>



      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
      <div className="contentArea">

        <Form.Row>

          {/* Deal code name text field*/}
          <Form.Group as={Col} xs={3} controlId="dealName">
            <Form.Control type="text" placeholder="Deal Code" value={state.dealcodeName} onChange={handleChange} />
            <Form.Label>Deal Name<sup>*</sup></Form.Label>
            {state.nameError && (
              <p className="vError">Please Enter Deal Name</p>
            )}
          </Form.Group>

          {/* Deal code applied on drop down */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="dealapplied">
            <Select
              options={dealCodeApplied}
              values={state.dealcodeType}
              onChange={handleDealCode} />
                 {state.dealcodeTypeError && (
                  <p className="vError">Please Select Deal Code Applied</p>
                )}
            <Form.Label>Deal Code Applied <sup>*</sup></Form.Label>
          </Form.Group>


          {/* eal / Corporate Code / Cryptic  test field*/}
          <Form.Group as={Col} xs={3} controlId="dealCode">
            <Form.Control type="text" placeholder="Commission Name" value={state.dealcodeValue} onChange={handleChange} />
            <Form.Label>Deal / Corporate Code / Cryptic<sup>*</sup></Form.Label>
            {state.dealcodeError && (
              <p className="vError">Please Enter Deal / Corporate Code / Cryptic</p>
            )}
          </Form.Group>

        </Form.Row>

        {/* if upfront deal code only below fields will be enable */}
        {isArrayNotEmpty(state.dealcodeType) && (state.dealcodeType[0].value === '2'||state.dealcodeType[0].value === '4') && (
          <Form.Row>

            {/* Markup type (% or value) drop down */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="comissionType">
              <Select
                options={commissionType}
                values={state.comissionType}
                onChange={(value) => (dispatch({ type: 'comissionType', payload: value }))} />
              <Form.Label>Markup Type</Form.Label>
            </Form.Group>


            {/* Comission on (Base fare/Total Fare) drop dwon */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="comissionOn">
              <Select
                options={commission_On}
                values={state.comissionOn}
                onChange={(value) => (dispatch({ type: 'comissionOn', payload: value }))} />
              <Form.Label>Markup On</Form.Label>
            </Form.Group>
            {/* Entered value Text field*/}
            <Form.Group as={Col} xs={3} controlId="amtid" className="d-flex align-items-center">
              <Form.Control type="text" placeholder="Commission Name" value={state.amountvalue} onChange={handleChange} />
              <Form.Label>Enter Value</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="disc" className="d-flex align-items-center">
              <Form.Control type="text" placeholder="Discount" value={state.markup_disc} onChange={handleDiscount} />
              <Form.Label>Discount</Form.Label>
              {state.mrkp_Disc && (<p className="vError">Markup discount should be less than markup value</p>)}
            </Form.Group>
          </Form.Row>
        )}


        <Form.Row>

          {/* Travel Type Drop down */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
            <Select
              options={travelType}
              values={state.travelType}
              onChange={(value) => (dispatch({ type: 'travelType', payload: value }))} />
            <Form.Label>Travel Type</Form.Label>
          </Form.Group>
          {/* Suplier Drop down */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
            <Select
              options={supplierType}
              values={state.supplierType}
              onChange={(value) => (dispatch({ type: 'supplierType', payload: value }))} />
            <Form.Label>Supplier<sup>*</sup></Form.Label>
          </Form.Group>

          {/* Airline Drop Down */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="airlineid">
            <Select
              options={state.airlinesList}
              values={state.airlineName}
              onChange={(value) => (dispatch({ type: 'airlineName', payload: value }), dispatch({ type: "airlineError", payload: false }))}
            />
            {state.airlineError && (
              <p className="vError">Please select Airlines</p>
            )}
            <Form.Label>Airlines <sup>*</sup></Form.Label>
          </Form.Group>

          {/*code share drop down  */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="codeshareid">
            <Select
              options={codeShare}
              values={state.codeshare}
              onChange={(value) => (dispatch({ type: 'codeshare', payload: value }))}
            />
            <Form.Label>Code Share</Form.Label>
          </Form.Group>
        </Form.Row>


        {/* Applicabe Dates */}
        <ApplicableDates configType={"Deal code"} sendAppDates={applicableDates} updateData={updateApplDates} applDatesData={state.applDates} sendApplicableId={deleteApplicable} disabledBtn={props.disabledBtn} reset={state.resetValue} />



        {/* Routes */}
        <Routes configType={"Deal Code"} updateData={updateRoutesData} sendRoutesData={routesInfo} routesData={state.rts_edit_data} sendRouteDelData={routeDelete} disabledBtn={props.disabledBtn} reset={state.resetValue} />

        <h4 className="subTitle plusHr">RBD</h4>

        <Form.Row>

          {/* Air line class type */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="cls_id">
            <Select
              options={airlineclass}
              values={state.classType}
              onChange={(value) => (dispatch({ type: 'classType', payload: value }))}
            />
            <Form.Label>Class</Form.Label>
          </Form.Group >

          {/* RBD */}
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="code_id">
            <Select multi
              options={class_Code_List}
              values={state.classCode}
              onChange={(valuec) => (bookicodeEvent(valuec))} />
            <Form.Label>RBD</Form.Label>
          </Form.Group >

          {/* RBD add button */}
          <Form.Group as={Col}>
            {!disableButton &&
              <Button
                size="xs"
                variant="outline-primary"
                onClick={addRBDBtn}
              > Add</Button>
            }
            {disableButton &&
              <Button
                size="xs"
                isDisabled={true}
                variant="outline-primary"
              > Add</Button>
            }
          </Form.Group>
        </Form.Row>

        {/* Aftter click on RBD add button display data is */}
        {isArrayNotEmpty(state.class_json) &&
          (<Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Class</th>
                      <th>RBD</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.class_json.map((cls, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <span>{isNotNull(cls.ty) && cls.ty}</span>
                          </td>
                          <td>
                            <span>{isNotNull(cls.rbd) && cls.rbd}</span>
                          </td>
                          <td>
                            {isNotNull(cls.ty) && isNotNull(cls.rbd) && !disableButton && (
                              <Button
                                size="xs"
                                onClick={deleteRBDBtn(cls, index)}
                               variant="link" className="ezyIcon icon-delete">
                              </Button>
                            )}
                            {isNotNull(cls.ty) && isNotNull(cls.rbd) && disableButton && (
                              <Button
                                size="xs"
                                onClick={deleteRBDBtn(cls, index)}
                                isDisabled={true}
                               variant="link" className="ezyIcon icon-delete">
                              </Button>
                            )}

                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>)}



        <Form.Row>

          {/* ATT and TA Radio buttons */}
          <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={state.edit_salesChanel}/>
          { <RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={state.region_type_edit}
          disabled = {state.enabeleEdit}
          regionError = {state.regionError}
                          />}
                          <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="supplier"
           >
          <Select
          disabled= {state.region_type_edit?true:false}
            options={state.officeIds}
            values={state.ofcs}
            onChange={updateOfcId}
          />
          <Form.Label>
            Office Id <sup>*</sup>
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} className="inputCheck">
          <div className="d-flex align-items-center">

          </div>
        </Form.Group>
          {/* if ATT user we are showing both radio buttons */}

      </Form.Row>
      <Form.Row>
        {state.att_user &&
            (

              <Form.Group as={Col} xs={3} controlId="utypeatt">
                <div className="d-flex align-items-center">
                  {/* <Form.Check type="radio" id="att" label="ATT" className="ml-0" name="ta_company_type" checked={!state.ta_company_type} custom onChange={attType} /> */}
                  <Form.Check type="radio" id="ta" label="TA" className="ml-3" name="ta_company_type" checked={true} custom onChange={taType} />
                </div>
              </Form.Group>

            )}
            {/* <RegionType
                regionType={handleRegionType}
                regionUpdate={state.region_type}
                disabled = {state.enabeleEdit}
                sendResponse={sendResponse}
              /> */}


          {/* if TA user we are showing only TA radio button */}
          {!state.att_user &&
            (

              <Form.Group as={Col} xs={3} controlId="utypeta">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" id="ta" label="TA" className="ml-3" name="ta_company_type" checked={state.ta_company_type} custom onChange={taType} />
                </div>
              </Form.Group>


            )}

        {state.enableDevices &&

<Form.Group as={Col} lg={4} md={6} className="inputCheck">
<Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
            </div>
            </Form.Group>

          }
             </Form.Row>
        {!state.enableDevices &&<Form.Row>
          {/*if TA Radio button Branch drop down will be display  */}
          {/* {context.logindata.utype!=="att_user" && ( */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="codeshareid">
              <Select
                options={state.branchList}
                values={state.sel_Branch}
                onChange={(valuec) => (taSelBranchEvent(valuec))}
              />
              <Form.Label>Branch</Form.Label>
            </Form.Group>
           {/* )} */}

        </Form.Row>}



        <Form.Row>
          {/* Branch names */}
          {/* here state.ta_company_type is true means TA , state.ta_company_type is false ATT selected*/}
          {/* Here we are maintaining two pick list why beceause for one pick list, fields are not clearing,
              and once we removed the data from datatable,if i add any new one removed one also adding,Due to this
              here two picklist we are using */}
          {/* {(!state.ta_company_type && state.resetPick1 ) && ( */}
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Available Company" rightPaneLabel="Selected Company"
                pickid="b_id" dataList={state.sel_Branch_Companies} sendData={pickldata} selData={data} type="Deal"/>
                  {state.branchError && (
              <p className="vError">Please select company</p>
            )}
              <Form.Label>Company Name<sup>*</sup></Form.Label>
            </Form.Group>
          {/* )} */}
{/*
          {(!state.ta_company_type && state.resetPick2) && (
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Available Branch" rightPaneLabel="Selected Branch"
                pickid="b_id" dataList={state.branchList} sendData={pickldata} />
              <Form.Label>Branch Name<sup>*</sup></Form.Label>
            </Form.Group>
          )} */}


          {/* Company names */}
          {/* here state.ta_company_type is true means TA , state.ta_company_type is false ATT selected*/}
          {/* {(state.ta_company_type && state.resetPick1) && (
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Available Company " rightPaneLabel="Selected Company"
                pickid="c_id" dataList={state.sel_Branch_Companies} sendData={pickldata} />
              <Form.Label>Company<sup>*</sup></Form.Label>
            </Form.Group>
          )}*/}

          {(state.ta_company_type && state.resetPick2) && (
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Available Branch" rightPaneLabel="Selected Branch"
                pickid="c_id" dataList={state.branchList} sendData={pickldata} />
              <Form.Label>Branch Name<sup>*</sup></Form.Label>
            </Form.Group>
          )}


          {/* Inventory names */}
          {/* {state.resetPick1 && (
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected"
                pickid="inv_id" dataList={state.office_id_masterdata} sendData={pickldata} />
              <Form.Label>Inventory/Office ID22<sup>*</sup></Form.Label>
            </Form.Group>
          )} */}

          {/* {state.resetPick2 && (
            <Form.Group as={Col} className="picklist" xs={6}>
              <PickList leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected"
                pickid="inv_id" dataList={state.office_id_masterdata} sendData={pickldata} />
              <Form.Label>Inventory/Office ID33<sup>*</sup></Form.Label>
            </Form.Group>
          )} */}

          {/* branch/company/inventory add button */}
          <Form.Group as={Col} xs={1}>
            {!disableButton &&
              <Button
                size="xs"
                variant="outline-primary"
                onClick={inventoryAddBtn}
              > Add</Button>
            }
            {disableButton &&
              <Button
                size="xs"
                isDisabled={true}
                variant="outline-primary"
              > Add</Button>
            }
          </Form.Group>
        </Form.Row>

        {/* Aftter click on Inventory add button inventory_display data table */}
        {isArrayNotEmpty(state.inventory_display_data) &&
          (<Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Branch/Company</th>
                      <th>Officeid</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.inventory_display_data.map((com, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            {isNotNull(com.ctyp) && com.ctyp.toUpperCase() === 'ATT' && (<span>{com.bnm}</span>)}
                            {isNotNull(com.ctyp) && com.ctyp.toUpperCase() === 'TA' && (<span>{com.cnm}</span>)}
                          </td>
                          <td>
                            <span>{isNotNull(com.label) && com.label}</span>
                          </td>
                          <td>

                            {isNotNull(com.ctyp) && isNotNull(com.label) && context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_D') && !disableButton && (

                              <Button
                                size="xs"
                                onClick={inventoryDeleteBtn(com, index)}
                               variant="link" className="ezyIcon icon-delete">
                              </Button>

                            )}
                            {disableButton &&
                              <Button
                                size="xs"
                                isDisabled={true}
                               variant="link" className="ezyIcon icon-delete">
                              </Button>
                            }


                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>)}


        <div>
          <div className="switchPlus buttonGrop pb-0">



            {/* Status check box  */}
            <Form.Group as={Col} className="topSwitch">
              <Form.Check
                type="switch"
                id="act"
                label="Status"
                checked={state.isActive} value={state.isActive} onChange={handleChange} />
            </Form.Group>

            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleCancel}
            > Back</Button>
            {!state.is_edit_dealcode && (
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleReset}
            > Reset</Button>)}

            {/* Deal code Save button */}
            {!state.is_edit_dealcode && (<Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={handleSaveDealCodeBtn}
            >Save</Button>)}


            {/* Deal code Update button */}
            {state.is_edit_dealcode && !disableButton && (
              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
                onClick={handleUpdateDealCodeBtn}
              >Update</Button>
            )}

            {state.is_edit_dealcode && disableButton &&
              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
                isDisabled={true}
              >Update</Button>

            }


          </div>
        </div>
      </div>


    </>
  )
}
export default DealCodeConfig