import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";


//This function will show the Air markups List and Add Markup
function InsPlanDetails(props) {
  const [state, setState] = useState({
    loading: false
  })

  return (
    <>
<div className="chooseBestinsu">
 <Row>
<Col  xs={12}>
    <div className="mt-3">
        <div className="contentInsubody">
        <Row>
        <Col>
          <Row>

          <Col xs={12}>
                <div className="travelInscon">
                    <Row className="travellInsurance">

                          <ul>

                            {props.plans.basic_covers.map((basic,idx1)=>(
                              <li>
                                <div className="allPax-list">
                                {basic.name}  <span className="pax-price">Inclusive</span>
                                </div>
                                </li>
                            ))}


                          {props.plans.inclusive_covers!==undefined && props.plans.inclusive_covers.map((incl,idx2)=>(
                               <li>
                            <div className="allPax-list">
                              {incl.name} <span className="pax-price">Inclusive</span>
                            </div>
                            </li>
                            ))}


                            {props.plans.optional_covers!==undefined && props.plans.optional_covers.map((opt,idx3)=>(
                                   <li>
                             <div className="allPax-list">
                             {opt.name} <span className="pax-price">Inclusive</span>
                           </div>
                           </li>
                            ))}



                            {props.plans.discount_covers!==undefined && props.plans.discount_covers.map((disc,idx4)=>(
                                <li>
                            <div className="allPax-list">
                            {disc.name}
                          </div>
                          </li>
                            ))}


                          </ul>
                            <div className="firstBlock">




                       </div>

                        {/* <Col xs={4}>
                            <div className="secomBlock">
                               {props.plans.basic_covers.map((basic,index)=>(
                                <span className="pax-price">Inclusive</span>
                                ))}
                                {props.plans.inclusive_covers!==undefined && props.plans.inclusive_covers.map((incl,index1)=>(
                                     <span className="pax-price">Inclusive</span>
                                ))
                                }
                                {props.plans.optional_covers!==undefined && props.plans.optional_covers.map((opt,index2)=>(
                                     <span className="pax-price">Inclusive</span>
                                ))}
                                {props.plans.discount_covers!==undefined && props.plans.discount_covers.map((disc,index3)=>(
                                     <span className="pax-price">Inclusive</span>
                                ))}
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </Col>
          </Row>

          </Col>





        </Row>
    </div>
    </div>
</Col>


              </Row>
            </div>
    </>
  )
}
export default InsPlanDetails