import React, { useState,useEffect } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";


//This function will show the Air markups List and Add Markup
function InsFareDetails(props) {
  const [state, setState] = useState({
    loading: false,totalPrice:0,premiumPrice:0
  })

  useEffect(() => {
    if(props.plans!==undefined){
      let price=0;
      if(props.plans.optional_covers!==undefined && props.plans.optional_covers.length!==0){
        for(let val of props.plans.optional_covers){
          if(price===0){
            price=Number(val.premium);
          }else{
           price=Number(price)+Number(val.premium);
          }
       }
      }
      //setState({totalPrice:Number(price)+Number(props.plans.net_premium)})
      setState({premiumPrice:Number(props.plans.net_premium)-Number(price)})
    }
  }, [props.plans])


  return (
    <>
      <div className="headerlist">
      <div className='columnnSpr'>

          <Row>
          <Col xs={6}>Plan Type</Col>
          <Col xs={6} className='secondChild'>Amount</Col>
        </Row>
        <div className="total mt-2 p-0"></div>
        <div className='innerContentcolor'>
        <Row>
          <Col xs={6}>{props.plans.scheme_name}</Col>
          <Col xs={6} className='secondChild'> <span>QAR </span>{state.premiumPrice}</Col>
        </Row>
        </div>
      </div>
      </div>
      <div>
      {props.plans.optional_covers !== undefined && props.plans.optional_covers.length !== 0 &&
  <div className="travelInscon">
      <div className='columnnSpr'>
        <Row>

          <Col xs={12}>
            <span className='subTitleins'>Optional Covers</span>
          </Col>

        </Row>
        </div>
        </div>
        }

        {props.plans.optional_covers !== undefined && props.plans.optional_covers.length !== 0 &&
          <div className="travelInscon optionalCoverr">
             <div className='columnnSpr'>
            <Row>
              <Col xs={6}>
                <div>
                  {props.plans.optional_covers.map((opt, idx3) => (
                    <div className="allPax-list">
                      {opt.name}
                    </div>
                  ))}
                </div>
              </Col>
              <Col xs={6} className='secondChild'>
                <div>
                  {props.plans.optional_covers.map((opt, index2) => (
                    <div className="allPax-list"><span>QAR </span> {opt.premium}.00</div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
          </div>
        }

  <div className='travelInscon'>
        <div className="total d-flex justify-content-end">
          <span>Grand Total : </span> <div className="pricetag"><em>QAR</em> <strong>{props.plans.net_premium}</strong></div>
        </div>
       </div>
      </div>
    </>
  )
}
export default InsFareDetails