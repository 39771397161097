
import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { isNotNull, isArrayNotEmpty } from '../../../../commonUtils/validators';
import Utilities from '../../../../commonUtils/Utilities';
import ShowMoreText from 'react-show-more-text';
import moment from 'moment';
/**
 * Initial State Declaration
 */
const initialState = {
    pax_data: '', price_data: '', bkres: '', occupancy: '', impinformation: false
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};


//Passenger details component
function PassengerDetails(props) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [room, setRoom] = useState(props.price.rmpr[0]);
    useEffect(() => {
        dispatch({ type: 'pax_data', payload: props.paxDetails })
        props.price.rmpr[0].freeCancelDate=moment(props.price.rmpr[0].freeCancelDate, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm');
        dispatch({ type: 'price_data', payload: props.price })
        dispatch({ type: 'bkres', payload: props.bkres })

        //checking for important info
        if(props.bkres && props.bkres.htldtls && props.bkres.htldtls.remarks && props.bkres.htldtls.remarks.instructions!==undefined && props.bkres.htldtls.remarks.instructions!=="" && props.bkres.htldtls.remarks.specialInstructions!==undefined && props.bkres.htldtls.remarks.specialInstructions!=="") {
            state.impinformation = true
            dispatch({ type: 'impinformation', payload: state.impinformation })
        }
        //constructing occupency value
        if (isNotNull(props.price)) {
            let occup = '';
            if (isNotNull(props.price.adt)) {
                occup = occup + props.price.adt +' '+ 'Adult(s)';
            }
            if (isNotNull(props.price.chd ) && props.price.chd > 0) {
                occup = occup +  ', ' + props.price.chd +' '+ 'Child(s)' + props.price.chdage;
            }
            dispatch({ type: 'occupancy', payload: occup })
        }
    }, [])

    const executeOnClick =(isExpanded) =>{
    }
    return (

        <>

            <h5 className="title roomInfoId">Room {state.pax_data.rmindex} : {state.price_data.rnm} <span className="cnfId">{props.bkres.st!=="On hold" && state.price_data.confirmnum!==undefined && state.price_data.confirmnum? <span className="code"><em>Supplier Ref No : </em>{state.price_data.confirmnum} </span> :"Supplier Ref No : N/A"}</span></h5>
            <div className="header">
                <Row>
                    <Col>Guest Name</Col>
                    <Col>Occupancy</Col>
                    <Col>Bed Type</Col>
                    <Col>Board Type</Col>
                    <Col>Hotel Conf No</Col>
                    <Col >Status</Col>
                    <Col>Special Request</Col>


                </Row>
            </div>

            <div className="passengerList">
            <Row>
                    {/* firstname & last name */}
                    <Col>{isNotNull(state.pax_data) && isArrayNotEmpty(state.pax_data.pax)
                        && ((state.pax_data.pax[0].fn) + ' ' + (state.pax_data.pax[0].ln))}
                        {isNotNull(state.pax_data) && isArrayNotEmpty(state.pax_data.pax) && state.pax_data.pax[0].lead && ('(lead)')}</Col>
                    {/*  <Col xs="2">{props.price.adt} Adult(s), {isNotNull(props.price.chd)&&(props.price.chd)+',Children'}</Col> */}

                    {/* occupancy */}
                    <Col><span title={state.occupancy} className="occupancy">{state.occupancy}</span></Col>
                    {/* bedtype */}
                    <Col>{room.bed}</Col>
                    {/* board type */}
                    <Col>{state.price_data.rmpr!==undefined && state.price_data.rmpr[0] !==undefined && state.price_data.rmpr[0].ra!==undefined && state.price_data.rmpr[0].ra[0]!==undefined?Utilities.getBoardBasisHotel(state.price_data.rmpr[0].ra):"Room Only"}</Col>
                    {/* Hotel reference number */}
                    <Col>{state.price_data.stat==="Confirmed" && state.price_data.htlrefnum!==undefined && state.price_data.htlrefnum?state.price_data.htlrefnum:"N/A"}</Col>
                    {/* Room level status */}
                    <Col className={state.price_data.stat === "On hold" ? 'text-primary': 'text-success' }>{state.price_data.stat}</Col>
                    {/* Special Request */}
                    <Col>{state.pax_data.spr!==""&& state.pax_data.spr!==undefined?state.pax_data.spr:"N/A"}</Col>
                </Row>
            </div>

            <div className='mobileRoominfo'>

            <div className="passengerList">

            <Row>
                    {/* firstname & last name */}
                    <Col><span>Guest Name :</span> {isNotNull(state.pax_data) && isArrayNotEmpty(state.pax_data.pax)
                        && ((state.pax_data.pax[0].fn) + ' ' + (state.pax_data.pax[0].ln))}
                        {isNotNull(state.pax_data) && isArrayNotEmpty(state.pax_data.pax) && state.pax_data.pax[0].lead && ('(lead)')}</Col>
                    {/*  <Col xs="2">{props.price.adt} Adult(s), {isNotNull(props.price.chd)&&(props.price.chd)+',Children'}</Col> */}

                    {/* occupancy */}
                    <Col><span>Occupancy :</span> <span title={state.occupancy} className="occupancy">{state.occupancy}</span></Col>
                    {/* bedtype */}
                    <Col><span>Bed Type :</span>{room.bed}</Col>
                    {/* board type */}
                    <Col><span>Board Type :</span>{state.price_data.rmpr!==undefined && state.price_data.rmpr[0] !==undefined && state.price_data.rmpr[0].ra!==undefined && state.price_data.rmpr[0].ra[0]!==undefined?Utilities.getBoardBasisHotel(state.price_data.rmpr[0].ra):"Room Only"}</Col>
                    {/* Hotel reference number */}
                    <Col><span>Hotel Conf No : </span>{state.price_data.stat==="Confirmed" && state.price_data.htlrefnum!==undefined && state.price_data.htlrefnum?state.price_data.htlrefnum:"N/A"}</Col>
                    {/* Room level status */}
                    <Col className={state.price_data.stat === "On hold" ? 'text-primary': 'text-success' }> <span>Status:</span> {state.price_data.stat}</Col>
                    {/* Special Request */}
                    <Col><span>Special Request: </span> {state.pax_data.spr!==""&& state.pax_data.spr!==undefined?state.pax_data.spr:"N/A"}</Col>
                </Row>



            </div>
            </div>

            <div className="passInfo">
                        <Row>
                            <Col xs={state.impinformation ? 6 : 12}>
                            <div className="info">
                    {/* Cancellation Policy */}
                    {state.price_data.stat==="Confirmed" && state.price_data.rmpr!==undefined && state.price_data.rmpr[0]!==undefined && state.price_data.rmpr[0].canpol.length!==0 &&
                    <div className="plcyInfo">
                        <strong>Cancellation Policy</strong>
                        {state.price_data.rmpr[0].canpol.map((can, idx) => (
                            <React.Fragment key={idx}>
                              <p>{can}</p>
                            </React.Fragment>
                        ))}

                    </div>
                    }
                    {state.price_data.stat==="On hold" &&
                     <div className="plcyInfo">
                     <strong>Cancellation Policy (Hold Booking)</strong>
                     <p>Your booking is on hold till  {state.price_data.rmpr[0].freeCancelDate}. Please Pay before to avoid auto cancellation.</p>
                     <p></p>
                     </div>
                    }
                    {state.price_data.rmpr!==undefined && state.price_data.rmpr[0]!==undefined && state.price_data.rmpr[0].canpol.length===0 &&
                     <div className="plcyInfo">
                     <strong>Cancellation Policy</strong>
                        <p>Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws.</p>
                        <p className="m-0">
                        <span className="refundable non">{"Non Refundable"}</span>
                       </p>
                       </div>
                        }
                </div>
                            </Col>
                            { state.impinformation &&
                            <Col xs={state.impinformation ? 6 : 0}>
                            <div className="info">
                    {/* Importinfo(Remarks) */}
                    <strong>Important Info</strong>
                    <div className="plcyInfo">
                    <ShowMoreText
                    /* Default options */
                    lines={2}
                    more='Show more'
                    less='Show less'
                    className='content-css'
                    anchorClass='my-anchor-css-class'
                    onClick={executeOnClick}
                    expanded={false}            >
                        <p dangerouslySetInnerHTML={{__html: props.bkres.htldtls.remarks.instructions}} ></p>
                      
                        <p dangerouslySetInnerHTML={{__html: props.bkres.htldtls.remarks.specialInstructions}} ></p>
                    </ShowMoreText>
                    </div>

                </div>
                            </Col>
}
                        </Row>




            </div>


        </>
    )
}


export default PassengerDetails;
