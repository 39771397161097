
import React, { useState, useEffect, useReducer, useContext } from 'react';
import log from "loglevel";
import Button from '../../common/buttons/Button'
import { useHistory } from 'react-router-dom';
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import 'react-datepicker/dist/react-datepicker.css';
import PickList from '../../common/pickList/PickList';
import { branchDataList,loadBranchesList, getCompaniesList, categoryDataList, ofcDataList, getCompanyCatLsit, getCompanyList, retriveFMDetails, deledeFMap, delPaxServiceFee, updateFMRequest,getCompanyTAList,getSTACompanyList,userbranchDataList,editServiceFee } from './operations';
import ApplicableMarkup from '../common/HotelApplicableMarkup';
import { Context } from "../../../../App";
import { confirmBranchDlg } from '../../common/confirmDialogue';
import Utilities from '../../commonUtils/Utilities';
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
import SalesChannel from "../common/SalesChannel";
let rbdMaster = require('../../masterData/Rbd.json');
export const COUNTRY_DATA ="COUNTRY_DATA";


/**
 * Initial State Declaration
 */
const initialState = {
  cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "TA", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [], branches: [], branchMenu: [], catMenu: [],
  categories: "", enbleType: true, showCat: true, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
  enabeleEdit: false, fmId: "", notiMessage: "", notiMessageShow: false, notiVarient: "", userType: "",staCompanies:[],ta_comp:[],selBranches:[],reset:false,hideDelete:false,region_selected: [],region_disable: false,
  isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,sales_chanel:""
};

let serviceFeeArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
    return {
      ...state,cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "TA", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: []
      , enbleType: true, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
      enabeleEdit: false, fmId: "",staCompanies:[],ta_comp:[],selBranches:[],hideDelete:false,region_selected: [],region_disable: false,
      isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,sales_chanel:""
    }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function AirMarkupFareMapForm(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [classState, setRbdClass] = useState({ class: [] });
  const [companydata, setCompanyPick] = useState();
  const [context, setContext] = useContext(Context);
  const [fares, setShowFareList] = useState({ faresList: [] });
  const [value, setValue] = useState([]);
  const [perNight, setperNight] = useState({ perNight: [] })
  const [longestStay, setlongestStay] = useState({ longestStay: [] })
  const [perBooking, setPerBooking] = useState({ perBookings: [] })
  const [applicable, setApplicableType] = useState();
  const [enApplicable, setEnApplicable] = useState(true);
  const [companyType, setCompanyType] = useState(true);
  const [paxData, setPaxData] = useState()
  const [data, setData] = useState([])
  const [officeData, setOfficeData] = useState([])
  const [region,setRegion] = useState([])
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);

  useEffect(() => {
    if(props.reset) {
      setShowFareList({ faresList: [] })
      setCompanyPick()
      setValue([])
      setlongestStay({ longestStay: [] })
      setPerBooking({ perBookings: [] })
      setperNight({ perNight: [] })
      setApplicableType()
      setCompanyType(true)
      setEnApplicable(true)
      setPaxData()
      setData([])
      dispatch({type:'clear',payload:true})
    }
  },[props.reset])

  useEffect(() => {
    let fareList = [];
    if (props.fareData && props.fareData !== undefined) {
      for (let value of props.fareData) {
        var name = "";
        if (value.branch_name !== null && value.branch_name !== "") {
          name = value.branch_name;
        } else if (value.category_name !== null) {
          name = value.category_name;
        }
        const fares = {
          fmid: value.pk_fare_map_id,
          applyon: value.applicable_on,
          borcname: name,
          company: (value.company_name) ? value.company_name : value.com_type,
          sup: value.supplier_name + "[" + value.fk_office_id + "]"
        }
        serviceFeeArray.push({name: (value.company_name) ? value.company_name : value.com_type})
        fareList.push(fares);
      }
      setShowFareList({ faresList: fareList })
        dispatch({ type: 'enabeleEdit', payload: true })
    }
  }, [props.fareData]);


  useEffect(() => {
    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      if(context.logindata.utype== "sta_user") {
        dispatch({type:"ctyp",payload:"STA"})
      }

      if(context.logindata.utype == "att_user") {
         let data = JSON.parse(localStorage.getItem(COUNTRY_DATA));
         setRegion(data)
      }else {

          //att_staff or ta_staff
          if(context.logindata.region_type) {
            //array filteration
            let constational_data = []
              let regional_array = context.logindata.region_type.split(",")
              for(let eachRegion of regional_array) {
                let payload = {
                  "label" : eachRegion,
                  "value" : eachRegion
                }
                region.push(payload)
                constational_data.push(payload)
              }
              //other than att the region is not given as option it is initially stabilised to response
              if(context.logindata.utype != "att_user") {
                state.region_selected = constational_data
                dispatch({type:"region_selected",payload:state.region_selected})
              }
          }

       }
      if (context.logindata.utype !== "ta_user" && context.logindata.utype !== "sta_user") {
        dispatch({ type: 'enableOfcs', payload: true })
        // ofcDataList().then(response => {
        //   if (response.data.suc) {
        //     if (response.data.res) {
        //       dispatch({ type: 'suppliers', payload: response.data.res })
        //       dispatch({ type: 'enableOfcs', payload: true })
        //     }
        //   }
        // }).catch(function (error) {
        //   log.error(
        //     "Exception occured in getBranchData function---" + error
        //   );
        // });
      } else {
        //Load all companies based on TA
        const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
        //Load all companies based Login type as TA
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }, [context.logindata]);

   //region edit response handle
   useEffect(()=>{

    if(props.editRegion && props.editRegion.length > 0) {
      dispatch({type:"region_selected",payload:props.editRegion})
      dispatch({type:"region_disable",payload:true})
    }
  },[props.editRegion])
  useEffect(()=>{
    if(state.enableDevices && state.sales_chanel==="B2C" && state.region_selected.length>0){
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_selected[0].label}
      AttCommonSerivce.att_companies(pyload).then(response => {
        let authData = []
        if (response.data.suc && response.data.res && response.data.res.data) {

          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel":val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: authData });
          return
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });

    }
  },[state.enableDevices , state.sales_chanel,state.region_selected])
  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    //dispatch({ type: 'officeIds', payload: [] })
    setCompanyPick([])
    props.sendChannel(salesChanel)
  }
  // const handleDeviceTypeChange = (e) => {
  //   props.DeviceType(e)
  // }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
 props.updateProps(state.isDeviceAll,state.isB2cWeb,state.isB2cApp,state.isMWeb)
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }

  const pickldata = (data) => {
    dispatch({ type: 'data', payload: data })
  }
  const compdata = (data) => {
    dispatch({ type: 'compData', payload: data })
  }
  //   const ofcidsdata = (data) => {
  //     let ofcids=[];
  //     data.map((obj) => {
  //       if(obj.value){
  //         const ofc={
  //           "of":obj.value,
  //           "su":obj.label
  //         }
  //         ofcids.push(ofc);
  //       }
  //      }
  //      );
  //     dispatch({ type: 'ofcs', payload: ofcids})
  //   }

  const handleCategoryChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    getCompanyCatLsit(value).then(response => {
      if (response.data.suc) {
        setCompanyPick(response.data.res)
      } else {
        //   //setMessage({showMessage:true})
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
  }

  const handleBranchChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    if(state.ctyp === "TA") {
      getCompanyList(value).then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    } else {
      getCompanyTAList(value,"TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    state.branches.map((obj) => {
      let blist=[];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

  const handleAppTypeChange = (e) => {
    if (e.target.id === "branch") {
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      dispatch({ type: 'ctyp', payload: "ATT" })
      setCompanyPick("");
      setEnApplicable(true);
    } else {
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
      setEnApplicable(false);
    }
  }

  const handleCompTypeChange = (e) => {
    if (e.target.id === "att") {
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      dispatch({ type: 'ofcs', payload: [] })
      setCompanyType(true);
    } else if(e.target.id === "ta") {
      dispatch({type:'bid',payload: 0})
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(false);
      setEnApplicable(true);
    } else {
      dispatch({type:'bid',payload: 0})
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(false);
      setEnApplicable(false);
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const validateFareMap = () => {
    if(perBooking.perBookings.length===0 && perNight.perNight.length===0 && longestStay.longestStay.length===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please define Applicable On*' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    if (state.userType === "att_user") {
      if(state.region_selected && state.region_selected.length == 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Region' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      if (state.aptype === "Branch" && state.ctyp === "ATT") {
        if (state.data.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else {
        if (state.ctyp === "TA" && state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }

        if (state.ctyp === "STA" && state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }

    }
    if (state.aptype === "Branch") {
      var exist = false;
      if (state.ctyp === "ATT") {
        if (state.data.length !== 0) {
          for (let val of state.data) {
            const payload = {
              "bid": val.value
            }
            serviceFeeArray.push(payload);
          }
          let data = Utilities.getUniqData(serviceFeeArray);
          if (serviceFeeArray.length !== data.length) {
            serviceFeeArray = data;
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Branch is already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        }
      } else if (state.ctyp === "TA") {
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value
            "name": val.label
          }
          serviceFeeArray.push(payload);
        }
        let data = Utilities.getUniqData(serviceFeeArray);
        if (serviceFeeArray.length !== data.length) {
          serviceFeeArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company is already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })

          serviceFeeArray = data
          return false;
        }
      }
      if (state.ctyp === "STA") {
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value,
            "name": val.label
          }
          serviceFeeArray.push(payload);
        }
        let data = Utilities.getUniqData(serviceFeeArray);
        // console.log('markuparr',markupFareArray)
        if (serviceFeeArray.length !== data.length) {
          serviceFeeArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          let correction = serviceFeeArray[serviceFeeArray.length - 1].name
          serviceFeeArray = data
          return false;
        }
      }
    }
    return true;
  }

  // ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
        // to selective ta and sta when repective tag is added to data so handle dublicate
        if((state.userType === "ta_user" && state.ctyp === "TA") || (state.userType === "sta_user" && state.ctyp === "STA")) {
          let companyData = []
          let trac_obj = {
            "label": context.logindata.cnm
          }
          companyData.push(trac_obj)
          let lvl = true
          for(let val of state.compData) {
            if(val.label == context.logindata.cnm) {
              lvl = false
            }
          }
          if(lvl) {
            state.compData.push(trac_obj)
          }
        }
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      let fList = [];
      var ofcIds = "";

      let selection_region = ""

      //region declaration
      if(state.region_selected.length > 0) {
        let temp_regionfilter = state.region_selected.filter(function(s) { //filteration of the region in place of accordance to selection
          if(s) {
              if(region == '') {
                selection_region = s.value
              } else {
                selection_region = selection_region + s.value
              }

            }
            return s;
          }
        )
      }
      if (state.userType === "att_user" && state.ofcs) {
        state.ofcs.map((obj) => {
          if (ofcIds) {
            ofcIds = ofcIds + "-" + obj.su + "[" + obj.of + "]";
          } else {
            ofcIds = obj.su + "[" + obj.of + "]";
          }
        });
      }
      if (state.userType === "att_user" && state.aptype === "Branch" && state.ctyp === "ATT") {
        if (state.data) {
          state.data.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: "ATT",
                borcname: obj.label,
                sup: ofcIds
              }
              branches.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } else if (state.userType === "att_user" && (state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || state.aptype === "Category") {
        let fList = [];
        var branchcatName = "";
        if (state.branches && state.bid !== 0 && state.aptype === "Branch") {
          state.branches.map((obj) => {
            if (Number(obj.value) === Number(state.bid)) {
              branchcatName = obj.label;
              branches.push({ "id": obj.value, "name": obj.label });
              category = [];
            }
          });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Category") {
          state.categories.map((obj) => {
            if (Number(obj.id) === Number(state.cat)) {
              branchcatName = obj.name;
              category.push({ "id": obj.id, "name": obj.name });
              branches = [];
            }
          });
        }
        if (state.compData) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname: branchcatName
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              dispatch({type:'compData',payload:[]})
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({type:'staCompanies',payload:[]})
              setCompanyPick([])
              dispatch({type:'bid',payload:0})
              dispatch({type:'ta_comp',payload:[]})
            }
          });
        }
      } else if(state.userType === "att_user"){
        let fList = [];
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              applyon: applicable,
              borcname: branchcatName
            }
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      let paxData = [];
      if (applicable === "Per Booking") {
        paxData = perBooking.perBookings;
      } else if(applicable === "Per Night"){

        paxData = perNight.perNight;
      } else {
        paxData = longestStay.longestStay
      }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (state.userType === "ta_user") {
        if(state.ctyp==="TA"){
          const fare = {
            applyon: applicable,
            company: context.logindata.cnm,
            compType:"TA"
          }
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          setShowFareList({ faresList: fares.faresList });
        }else{
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              applyon: applicable,
              company: obj.label,
              compType:"STA"
            }
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
        }
      }
      if(state.userType === "sta_user") {
        const fare = {
          applyon: applicable,
          company: context.logindata.cnm,
          compType:"STA"
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
         if(state.userType === "att_user") {
        if (!state.enabeleEdit) {
          createFareMapRequest(paxData, companyType, branches, companys, category,selection_region);
        } else {
          createFMUpdatedPayload(paxData, companyType, branches, companys, category,selection_region);
        }
      } else if(state.userType === "ta_user"){
        if (!state.enabeleEdit) {
          createFareMapTARequest(paxData, companys,selection_region);
        } else {
          createFMUpdatedTAPayload(paxData, companys,selection_region);
        }

      } else {
        if (!state.enabeleEdit) {
          createFareMapSTARequest(paxData, companys,selection_region);
        } else {
          createFMUpdatedSTAPayload(paxData, companys,selection_region);
        }
      }
    }
  }
  const createFareMapTARequest = (paxData, companies,region) => {
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "class": classState.class,
      "ctyp": state.ctyp,
      "bid": [],
      "cat": [],
      "aptype": "",
      "cmpid": uniqueJson(companies),
      "ofc": [],
      "utype": "ta_user",
      "taCmp":state.ta_comp
    }
    props.sendFareData(payload);
  }
  const createFareMapRequest = (paxData, companyType, branches, companies, categories,region) => {
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "ctyp": companyType,
      "bid": branches,
      "cat": categories,
      "aptype": state.aptype,
      "cmpid": uniqueJson(companies),
      "taCmp":state.ta_comp,
      "utype": companyType == "STA" ? "ta_user" : context.logindata.utype
      // "ofc":state.ofcs
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedTAPayload = (paxData, companies,region) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "sid": props.markupId,
        "utype": "ta_user",
        "applon": applicable,
        "class": classState.class,
        "ctyp": state.ctyp,
        "aptype": "",
        "applist": paxData,
        "bid": [],
        "cat": [],
        "cmpid": uniqueJson(companies),
        "ofc": [],
        "taCmp":state.ta_comp
      }
    }
    createFMPayload(payload);
  }
  const createFMUpdatedPayload = (paxData, companyType, branches, companies, categories,region) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "sid": props.markupId,
        "applon": applicable,
        "class": classState.class,
        "ctyp": companyType,
        "aptype": state.aptype,
        "applist": paxData,
        "bid": branches,
        "cat": categories,
        "cmpid": uniqueJson(companies),
        "taCmp":state.ta_comp,
        "utype": companyType == "STA" ? "ta_user" : context.logindata.utype
      }
    }
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    if(paxData && paxData.length > 0) {
    updateFMRequest(payload).then(response => {
      let fareList = [];
      if (response.data.suc) {
        dispatch({type:'hideDelete',payload:false})
        setShowFareList({ faresList: [] })
        editServiceFee(props.markupId).then(response => {
          if (response.data.suc) {
            var obj = response.data.res;
            var srvcmap = obj.fmap
            if(srvcmap && srvcmap.length > 0) {
              for (let value of srvcmap) {
                var name = "";
                if (value.branch_name !== null && value.branch_name !== "") {
                  name = value.branch_name;
                } else if (value.category_name !== null) {
                  name = value.category_name;
                }
                const fares = {
                  fmid: value.pk_fare_map_id,
                  applyon: value.applicable_on,
                  borcname: name,
                  company: (value.company_name) ? value.company_name : value.com_type,
                  sup: value.supplier_name + "[" + value.fk_office_id + "]"
                }
                fareList.push(fares);
              }
              dispatch({type:'enabeleEdit',payload:true})
              setShowFareList({ faresList: uniqueJson(fareList) })
            }
            // setEditData(response)
            // if(type==="view"){
            //   setEnableView(true)
            // }else{
            //   setEnableView(false)
            // }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in editServiceFee function---" + error
          );
        });
        dispatch({ type: 'notiMessage', payload: "ServiceFee updated successfully" })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        // dispatch({type: 'reset',payload:true})
        setCompanyPick()
        setValue([])
        setlongestStay({ longestStay: [] })
      setPerBooking({ perBookings: [] })
      setperNight({ perNight: [] })
        setApplicableType()
        setCompanyType(true)
        setEnApplicable(true)
        setPaxData()
        setData([])
        // dispatch({type:'clear',payload:true})
      } else {
        dispatch({ type: 'notiMessage', payload: "ServiceFee Not updated.." })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  } else {
        dispatch({ type: 'notiMessage', payload: "Please Select Applicable Data" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
  }
  }
  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

    return myUniqueArr;
  }
  const createFMPayload = (payload) => {
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    updateFMRequest(payload).then(response => {
      let fareList = [];
      if (response.data.suc) {
        for (let value of response.data.res.data) {
          var name = "";
          if (value.branch_name !== null && value.branch_name !== "") {
            name = value.branch_name;
          } else if (value.category_name !== null) {
            name = value.category_name;
          }
          const fares = {
            fmid: value.pk_service_fee_id,
            applyon: value.applicable_on,
            borcname: name,
            company: (value.company_name) ? value.company_name : value.com_type
          }
          fareList.push(fares);
        }
        setShowFareList({ faresList: fareList })
        dispatch({ type: 'notiMessage', payload: "Service Profile updated successfully" })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      } else {
        dispatch({ type: 'notiMessage', payload: "Service Profile Not updated.." })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  const createFareMapSTARequest = (paxData, companies,region) => {
    let barr = []
    let obj = {
      id:context.logindata.bid,
      name:context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "class": classState.class,
      "ctyp": "STA",
      "bid": barr,
      "cat": [],
      "aptype": "Branch",
      "cmpid": uniqueJson(companies),
      "ofc": [],
      "taCmp":state.ta_comp,
      "utype": "sta_user"
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedSTAPayload = (paxData,companies,region) => {
    let barr = []
    let obj = {
      id:context.logindata.bid,
      name:context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "sid": props.markupId,
        "utype": "sta_user",
        "applon": applicable,
        "class": classState.class,
        "ctyp": "STA",
        "aptype": "Branch",
        "applist": paxData,
        "bid": barr,
        "cat": [],
        "cmpid": uniqueJson(companies),
        "ofc": [],
        "taCmp":state.ta_comp
      }
    }
    createFMPayload(payload)
  }
  const paxMarkup = (data) => {
    setApplicableType(data[0]);
    dispatch({type: 'reset',payload: false})
    if (data[0] !== undefined && data[0] === "Per Booking") {
      perBooking.perBookings.push(data[1]);
      setPerBooking({ perBookings: perBooking.perBookings });
    } else if(data[0] !== undefined && data[0] === "Per Night"){
        perNight.perNight.push(data[1]);
    //   setPerTicket({ perTickts: perTicket.perTickts });
      setperNight({ perNight: perNight.perNight });
    } else {
        longestStay.longestStay.push(data[1])
        setlongestStay({longestStay:longestStay.longestStay})
    }
  }

  // const loadClasses=(resp)=>{
  //   let clssess=[];
  //   for(let value of resp.class){
  //    const cls={
  //      "id":value.cid,
  //      "cls":value.cls,
  //      "rbd":value.rbd
  //    }
  //    clssess.push(cls);
  //   }
  //   setRbdClass({class: clssess});
  //   dispatch({ type: 'showCls', payload: true})
  // }

  const setCompType = (resp) => {
    if (resp.ctyp === "ATT") {
      setCompanyType(true)
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      if (resp.bid && resp.bid !== undefined) {
        let compas = [];
        let data = [];
        for (let val of resp.bid) {
          const comp = {
            "value": val.id,
            "label": val.name
          }
          data.push(comp);
          compas.push(val.id);
        }
        setData(data);
        dispatch({ type: 'data', payload: resp.bid })
      }
    } else if(resp.ctyp === "TA"){
      dispatch({ type: 'bid', payload: resp.bid[0].id })
      setCompanyType(false)
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'enableOfcs', payload: true })
      if (resp.cmpid && resp.cmpid.length !== 0) {
        let compas = [];
        let data = [];
        for (let val of resp.cmpid) {
          const comp = {
            "value": val.id,
            "label": val.name
          }
          data.push(comp);
          compas.push(val.id);
        }
        setData(data);
        dispatch({ type: 'compData', payload: data })
      }
      getCompanyList(resp.bid[0].id).then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });
    }  else {
      dispatch({ type: 'bid', payload: resp.bid[0].id })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(true);
      setEnApplicable(false)
      setData([]);
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.id, "name": obj.name });
        }
      });
      let taCmpValue = {
        "value":resp.taCmp[0].id,
        "label":resp.taCmp[0].name
      }
      dispatch({type:'ta_comp',payload:[taCmpValue]})
      getCompanyTAList(resp.bid[0].id,"TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      let value = resp.taCmp[0].id
      getSTACompanyList(resp.taCmp[0].id).then(response => {
        if (response.data.suc) {
          dispatch({type:'staCompanies',payload:response.data.res})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }

  const loadCompanyData = (companies) => {
    let compas = [];
    let data = [];
    // setData(data);
    // dispatch({ type: 'compData', payload: data })
    for (let val of companies) {
      const comp = {
        "value": val.id,
        "label": val.name
      }
      data.push(comp);
      compas.push(val.id);
    }
    setData(data);
    dispatch({ type: 'compData', payload: data })
  }

  const setAppType = (appType) => {
    if (appType === "Branch") {
      setEnApplicable(true);
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyPick("");
    } else {
      setEnApplicable(false);
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
    }
  }

  // const loadOfcIds=(ofcs)=>{
  //     let compas=[];
  //     let data=[];
  //    for(let val of ofcs){
  //      const comp={
  //        "value":val.od,
  //        "label":val.su
  //      }
  //      data.push(comp);
  //      compas.push(val.od);
  //    }
  //    setOfficeData(data);
  //    dispatch({ type: 'ofcs', payload: ofcs})

  // }

  const editFareMap = (obj) => () => {

    retriveFMDetails(obj.fmid).then(response => {
      if (response.data.suc) {
        serviceFeeArray = serviceFeeArray.filter((d,id) => d.name !== obj.company)
        dispatch({type:'hideDelete',payload:true})
        dispatch({type: 'reset',payload:false})
        dispatch({ type: 'enableUpdate', payload: true })
        dispatch({ type: 'fmId', payload: obj.fmid })
        var resp = response.data.res
        setPaxData(resp)
        if (resp.cmpid && resp.cmpid.length !== 0){
        loadCompanyData(resp.cmpid)}
        // if (resp.class && resp.class.length !== 0) {
        //   loadClasses(resp);
        // }
        // if (resp.ofc && resp.ofc !== undefined) {
        //   loadOfcIds(resp.ofc);
        // }
        if (resp.ctyp) {
          if(state.userType === "att_user"){
            setCompType(resp);
          }
        if (state.userType === "ta_user" && resp.cmpid && resp.cmpid.length !== 0) {
          if(resp.ctyp==="TA"){
            dispatch({ type: 'ctyp', payload: "TA" })
            setCompanyType(true)
          }else{
            loadCompanyData(resp.cmpid);
            dispatch({ type: 'ctyp', payload: "STA" })
            setCompanyType(false)
            const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
            //Load all companies based Login type as TA
            if(companydata && companydata.length===0){
            getCompaniesList(pyload).then(response => {
              if (response.data.suc) {
                setCompanyPick(response.data.res)
              }
            }).catch(function (error) {
              log.error(
                "Exception occured in getCompanyList function---" + error
              );
            });
            }
          }
        }
        }
        if (resp.aptype) {
          setAppType(resp.aptype);
        }
      }
    }).catch(function (error) {
      console.log('err:::',error)
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  const editFareMapFk = (obj) => () => {
    retriveFMDetails(obj.fmid).then(response => {

      if (response.data.suc) {
        dispatch({ type: 'enableUpdate', payload: true })
        dispatch({ type: 'fmId', payload: obj.fmid })
        var resp = response.data.res
        setPaxData(resp)
        if (resp.ctyp) {
          setCompType(resp);
        }
        if (resp.aptype) {
          setAppType(resp.aptype);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }


  const deleteFareMap = (obj, idx) => () => {
    let deleted_data = null;
    if (obj !== undefined && obj.fmid !== undefined) {
      confirmBranchDlg(obj, 'Service Fee', function () {
        deledeFMap(obj.fmid).then(response => {
          if (response.data.suc) {
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.fmid !== obj.fmid) });

            let companyList = serviceFeeArray;
            serviceFeeArray = companyList.filter((item,i) => item.name !== obj.company);
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
      deleted_data = fares.faresList.filter((s, sidx) => idx !== sidx)
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      let companyList = serviceFeeArray;
      serviceFeeArray = companyList.filter((item,i) => item.name !== obj.company);
      props.saveDeleteFmapId(idx)
    }

    if(deleted_data && deleted_data.length == 0) {
      dispatch({type:"region_disable",payload:false})
    }
  }


  const deletePaxMarakup = (id) => {
    dispatch({type: 'reset',payload:false})
    delPaxServiceFee(id).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deletePaxMarakup function---" + error
      );
    });
  }

  const handleTypeChange = (e) => {
    if (e.target.id === "ta") {
      dispatch({ type: 'ctyp', payload: "TA" })
      setCompanyType(true);
      setCompanyPick([])
    } else {
      dispatch({ type: 'ctyp', payload: "STA" })
      setCompanyType(false);
    const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
    //Load all companies based Login type as TA
    if(companydata && companydata.length===0){
    getCompaniesList(pyload).then(response => {
      if (response.data.suc) {
        setCompanyPick(response.data.res)
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
    }
    }
  }
  const updatePaxData = (data) => {
    dispatch({type: 'reset',payload:false})

    if (data[0] !== undefined && data[0] === "Per Booking") {
        setPerBooking({ perBookings: data[1] });
      } else if(data[0] !== undefined && data[0] === "Per Night"){
        setperNight({ perNight: data[1] });
      } else {
          setlongestStay({longestStay: data[1]})
      }
  }
  const handleStaChange = (e) => {
    dispatch({type:'staCompanies',payload:[]})
    setData([])
    if(e && e.length > 0) {
      // staCompanies
      dispatch({type:'ta_comp',payload:e});
      getSTACompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          dispatch({type:'staCompanies',payload:response.data.res})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }

  const handleRegionChange = (value) => {
    dispatch({type:'bid',payload:0})
    dispatch({type:'branchMenu',payload:[]})
    //att_user- || ta_user
    state.region_selected = value
    dispatch({type:'region_selected',payload:state.region_selected})
   props.region_type(value)
    if(state.region_selected ) {
      let region = '' //contains the selected  regions filtered in string

      let temp_regionfilter = state.region_selected.filter(function(s) { //filteration of the region in place of accordance to selection
        if(s) {
            if(region == '') {
              region = s.label
            } else {
              region = region + s.label
            }

          }
          return s;
        }
      )

      if(context.logindata.utype == "att_user") {
        setCompanyPick("");
        state.compData = []
        dispatch({type:"compData",payload : state.compData})
     if(context.logindata.is_admin){
      loadBranchesList({"region_type": region }).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'branches', payload: response.data.res })
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }else{
      loadBranchesList({"region_type": region,"uid": context.logindata.uid}).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'branches', payload: response.data.res })
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }
    }
    }
  }
  return (
    <>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>

      <h4 className="subTitle plusHr mt-0">Profiles Service Fee</h4>
      {context.logindata.utype == "att_user" &&
          <Form.Row>
            <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={props.edit_sales_channel}/>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="regionType">
            <Select

               options={region}
               searchable={true}
               values={state.region_selected}
               disabled={state.region_disable}
               onChange={handleRegionChange}
 //onChange={(value) => (setDates(value))}
 />
             <Form.Label>Region<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>
}
<Form.Row className='mb-2'>
  <Form.Group as={Col} >
{state.enableDevices &&
<>
<div className="d-flex align-items-center">
<Form.Check
  type="checkbox"
  id="deviceAll"
  label="All"
  custom
  className="ml-0 mr-2"
  checked = {state.isDeviceAll}
  value={state.isDeviceAll}
  onChange={handleDeviceTypeChange}
/>
<Form.Check
  type="checkbox"
  id="web"
  label="B2C Web"
  custom
  className="ml-0"
  checked = {state.isB2cWeb}
  value={state.isB2cWeb}
  onChange={handleDeviceTypeChange}
/>
<Form.Check
  type="checkbox"
  id="app"
  label="B2C App"
  custom
  className="ml-3"
  checked={state.isB2cApp}
  value={state.isB2cApp}
  onChange={handleDeviceTypeChange}
/>
<Form.Check
  type="checkbox"
  id="mweb"
  label="M Web"
  custom
  className="ml-3"
  checked={state.isMWeb}
  value={state.isMWeb}
  onChange={handleDeviceTypeChange}
/>
</div>
</>
}
</Form.Group>
            </Form.Row>
      <ApplicableMarkup configType={props.configType} sendPaxData={paxMarkup}  type={"hotel"} sendAppMarkupId={deletePaxMarakup} reset={props.reset} editPaxData={paxData} updateData={updatePaxData} disabledBtn={props.disabledBtn} />
      <hr />
      <Form.Row>
        {state.userType === "att_user" &&
          <Form.Group as={Col} controlId="classes" className="aptype">
            <Form.Label>Applicable Type</Form.Label>
            <div className="d-flex align-items-center">
              {/* <Form.Check type="radio" label="Branch" onChange={handleAppTypeChange} value={state.aptype} checked={enApplicable} custom name="applicableTo" className="ml-0" id="branch" /> */}
              {/* <Form.Check type="radio" label="Category" onChange={handleAppTypeChange} value={state.aptype} checked={!enApplicable} custom name="applicableTo" className="ml-3" id="category" /> */}
              <Form.Check type="radio" label="Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={state.ctyp == "TA" ? true : false} custom name="abc" id="ta" />
              <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={state.ctyp == "STA" ? true : false} custom name="abc" id="sta" />
            </div>
          </Form.Group>
        }

         {state.userType === "ta_user" &&
          <Form.Group as={Col} xs={3} controlId="ctyp" className="inputCheck">
            <div className="d-flex align-items-center">
              <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} onChange={handleTypeChange} checked={companyType} custom name="zed" id="ta" />
              <Form.Check type="radio" label="STA" value={state.ctyp} onChange={handleTypeChange} checked={!companyType} custom name="zed" id="sta" />
            </div>
            <Form.Label>Company Type</Form.Label>
          </Form.Group>
          }

        {state.userType === "sta_user" &&
          <Form.Group as={Col} xs={3} controlId="ctyp" className="inputCheck">
            <div className="d-flex align-items-center">
              <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} checked={companyType} custom name="ctype" className="ml-0" id="sta" />
            </div>
            <Form.Label>Company Type</Form.Label>
          </Form.Group>
          }
        {/* {state.enbleType && state.userType!=="ta_user"  && */}

        {/* <Form.Group as={Col} controlId="ctyp" className="inputCheck">
   <div className="d-flex align-items-center">
     <Form.Check type="radio" label="ATT" value={state.ctyp} onChange={handleCompTypeChange} checked={companyType} custom name="ctype" className="ml-0" id="att" />
     <Form.Check type="radio" label="TA" value={state.ctyp} onChange={handleCompTypeChange} checked={!enApplicable} custom name="ctype" className="ml-3" id="ta" />

     </div>
     <Form.Label>Company Type</Form.Label>
   </Form.Group> */}

        {/* } */}
      </Form.Row>
      <Form.Row>
      {state.enbleType && state.compType && state.userType === "att_user" && state.ctyp == "ATT" &&

          <Form.Group as={Col} className="picklist" xs={7} >
            {state.branches &&
              <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" selData={data} dataList={state.branches} sendData={pickldata} />
            }
            <Form.Label>Branch Names</Form.Label>
          </Form.Group>

      }

      {state.userType === "att_user" && state.userType!=="ta_user" && !state.enableDevices &&

          <Form.Group as={Col} xs={3} controlId="bid">
            <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
              <option value="">Select</option>
              {state.branchMenu}
            </Form.Control>
            <Form.Label>Branch<sup>*</sup></Form.Label>
          </Form.Group>

      }

           {!state.showCat && state.ctyp == "STA" && companydata &&
                <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                    <Select
                    options={companydata}
                    id="class"
                    isSearchable={false}
                    disabled = {state.disabled}
                    values={state.ta_comp}
                    onChange={(value) => (handleStaChange(value))}
                />
                <Form.Label>Ta Company</Form.Label>
                </Form.Group>
          }
      {companydata && companydata.length !== 0 && state.userType === "att_user" && state.ctyp === "TA" &&

          <Form.Group as={Col} className="picklist" xs={7}>
            <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
            <Form.Label>Company Name<sup>*</sup></Form.Label>
          </Form.Group>

      }

{state.staCompanies && state.staCompanies.length !== 0 && state.userType === "att_user"  && state.ctyp === 'STA' &&

<Form.Group as={Col} className="picklist" xs={7}>
<PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={state.staCompanies} sendData={compdata} />
<Form.Label>STA Company Name<sup>*</sup></Form.Label>
</Form.Group>

}
      {!companyType && companydata && companydata.length !== 0 && state.userType === "ta_user" &&

       <Form.Group as={Col} className="picklist" xs={7}>
         <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
         <Form.Label>Company Name<sup>*</sup></Form.Label>
       </Form.Group>

}
        {/* {state.suppliers && state.enableOfcs &&
  <Form.Group as={Col} className="picklist" xs={7}>
    <PickList dataList={state.suppliers} leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected" ofcsData={officeData} sendData={ofcidsdata} />
    <Form.Label>Inventory/Office ID</Form.Label>
  </Form.Group>
} */}
        <Form.Group as={Col} xs={2}>
          {!state.enableUpdate && !disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              onClick={showMarkupFares}
            > Add</Button>
          }
          {disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              isDisabled={true}
            > Add</Button>
          }
        </Form.Group>
      </Form.Row>
      {state.enableUpdate &&
      <div className="text-right buttonGrop mt-4">
        <Button
          size="xs"
          onClick={showMarkupFares}
          variant="outline-primary"
          type="submit"
        >Update Applicable</Button>
        </div>
      }
      {fares.faresList.length!==0 &&
      <div className="tableLayout">
        <Table>
          <thead>
            <tr>
              <th>Applicable On</th>
              {state.userType === "att_user" &&
                <th>Branch</th>
              }
              <th>Company Name</th>
              <th className="text-right" width="8%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {fares.faresList.map((fare, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <span>{fare.applyon}</span>
                  </td>
                  {state.userType === "att_user" &&
                    <td>
                      <span>{fare.borcname}</span>
                    </td>
                  }
                  <td>
                    <span>{fare.company}</span>
                  </td>
                  <td className="text-right">
                    {state.enabeleEdit &&
                      <Button
                        size="xs"
                        variant="link"
                        onClick={editFareMap(fare)}
                        className="ezyIcon icon-edit"
                        type="submit">
                      </Button>
                    }
                    {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_D') && !state.hideDelete &&
                      <Button
                        size="xs"
                        variant="link"
                        onClick={deleteFareMap(fare, index)}
                        className="ezyIcon icon-delete"
                        type="submit">
                      </Button>
                    }
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      }
    </>
  )
}
export default AirMarkupFareMapForm