import React, { Component, useState, useEffect } from 'react';
import { Alert} from "react-bootstrap";

function Notification(props) {
  console.log(props, "prrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const { state } = props || {}
  const [show, setShow] = useState(props.show);
  const [variant] = useState(props.variant);
  const [idx] = useState(props.idx);


        return (
          <>
            { props.show && (
              <Alert key={idx} className="notification" variant={variant} onClose={() => setShow(false)} dismissible>
              {props.messageText}
              </Alert>
            )}
          </>
        );






};
// [
//   'primary',
//   'secondary',
//   'success',
//   'danger',
//   'warning',
//   'info',
//   'light',
//   'dark',
// ]

Notification.defaultProps = {
  variant:'success',
  onClick:()=>{},
  messageText: 'successFull Added'

}


export default Notification;