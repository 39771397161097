
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Modal, Row, Table, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Footer from '../../../../components/common/Footer';
import MainMenu from '../../../../components/common/MainMenu';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import {getCustomerRequests} from './operations';
import {currencies} from '../../utils';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Exchange Rate Configuration
 * @date : 13-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id:0,reqObj:"",from_currency:"", to_currency:"", exchange_rate:"",commission_exchange_rate:"",currencyMenu: currencies(), res:[], selFromCurrency: [], selToCurrency: [],enbaleUpdate: false,
    noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false,showPoppup:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, id:0,from_currency:"", to_currency:"", exchange_rate:"",currencyMenu: currencies(), res:[], selFromCurrency: [], selToCurrency: [],enbaleUpdate: false,
                noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function CustomerRequest Configuration
 */
function CustomerRequest() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [validated, setValidated] = useState(false);
    const [context, setContext] = useContext(Context);

    useEffect(() => {
        getCustomerRequests().then(response => {
            if (response.data.suc) {
                convertData(response.data);
            }
        })
    }, []);

    const openSummay=(bookingId)=>{
        window.open("/ezytrip/dashboard/bookingsummary/" + bookingId);
    }

    //Converting all suppliers
    const convertData = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response.res){
                    data.push({ book_id:<span className="tbAction"><a  onClick={()=>openSummay(val.booking_id)}><stong>{val.booking_id}</stong></a></span>,req_type: val.request_type, st:"Open",date:val.created_date,options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={()=>viewRequest(val)}></a></span></>})
                }
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'isLoadingMore', payload: false });
    }

     //Edit Categeory
     const viewRequest = (obj) => {
        dispatch({ type: 'reqObj', payload: obj });
        dispatch({ type: 'showPoppup', payload: true });
    }

     //Edit Categeory
     const cancelReq = () => {
        dispatch({ type: 'reqObj', payload: "" });
        dispatch({ type: 'showPoppup', payload: false });
    }

        const columns = [
            {
                dataField: 'date',
                text: 'Creation Date',
                sort: true
            },
        {
            dataField: 'book_id',
            text: 'Booking Id',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'req_type',
            text: 'Request Type',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'st',
            text: 'Status',
            sort: true
        },
        {
            dataField: 'options',
            text: 'Actions',
            class: 'tes'
        }
        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];


    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="Customer Requests" />
            <div className="container">
              <div className="cardMainlayout pt-0">
                <OrganizationMenu active="Customer Request" />
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
        <Modal
        show={state.showPoppup}
        onHide={cancelReq}
        className="importPNRMdv shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
          <h5>View Request</h5>
        <Modal.Body>
          <Form.Row>
          <Form.Group as={Col} xs={12} controlId="req_desc">
        <Form.Control type="textarea" placeholder="Enter Desc" disabled={true} autoComplete="off"
           value={state.reqObj.booking_id}/>
        <Form.Label>Booking Id</Form.Label>
      </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={12} controlId="req_desc">
        <Form.Control type="textarea" disabled={true} placeholder="Enter Desc" autoComplete="off"
        value={state.reqObj.request_type}/>
        <Form.Label>Request Type</Form.Label>
      </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} controlId="req_desc">
        <Form.Control type="textarea" disabled={true} placeholder="Enter Desc" autoComplete="off"
        value={state.reqObj.desc}/>
        <Form.Label>Description</Form.Label>
      </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} controlId="req_desc">
        <Form.Control type="textarea" disabled={true} placeholder="Enter Desc" autoComplete="off"
        value={"Open"}/>
        <Form.Label>Status</Form.Label>
      </Form.Group>
      </Form.Row>
        </Modal.Body>
        </Modal>
            </div>
            <Footer />
        </>
    )
}

export default CustomerRequest