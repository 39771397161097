import React, { useState, useEffect, useRef, useReducer, useContext, useCallback } from 'react';
import { Alert, Col, Form, Tab, Nav, Link, Row } from "react-bootstrap";
import Footer from '../../../../common/Footer';
import Header from '../../../../common/Header';
import Breadcrumb from '../../../../common/breadcrumb/Breadcrumb';
import ShowHide from '../../../../common/showHide/ShowHide';
import StickyBox from "react-sticky-box/dist/esnext";

function TaC(props) {
    const tcGeneral = useRef(null)
    const tc1 = useRef(null)
    const tc2 = useRef(null)
    const tc3 = useRef(null)
    const tc4 = useRef(null)
    const tc5 = useRef(null)
    const tc6 = useRef(null)
    const tc7 = useRef(null)
    const tc8 = useRef(null)
    const tc9 = useRef(null)
    const tc10 = useRef(null)
    const executeScrolltop = () => tcGeneral.current.scrollIntoView();
    const executeScrolltop1 = () => tc1.current.scrollIntoView();
    const executeScrolltop2 = () => tc2.current.scrollIntoView();
    const executeScrolltop3 = () => tc3.current.scrollIntoView();
    const executeScrolltop4 = () => tc4.current.scrollIntoView();
    const executeScrolltop5 = () => tc5.current.scrollIntoView();
    const executeScrolltop6 = () => tc6.current.scrollIntoView();
    const executeScrolltop7 = () => tc7.current.scrollIntoView();
    const executeScrolltop8 = () => tc8.current.scrollIntoView();
    const executeScrolltop9 = () => tc9.current.scrollIntoView();
    const executeScrolltop10 = () => tc10.current.scrollIntoView();
    const general = (e) => {
        executeScrolltop();
      }
      const general1 = (e) => {
        executeScrolltop1();
      }
      const general2 = (e) => {
        executeScrolltop2();
      }
      const general3 = (e) => {
        executeScrolltop3();
      }
      const general4 = (e) => {
        executeScrolltop4();
      }
      const general5 = (e) => {
        executeScrolltop5();
      }
      const general6 = (e) => {
        executeScrolltop6();
      }
      const general7 = (e) => {
        executeScrolltop7();
      }
      const general8 = (e) => {
        executeScrolltop8();
      }
      const general9 = (e) => {
        executeScrolltop9();
      }
      const general10 = (e) => {
        executeScrolltop10();
      }
      useEffect(() => {
        window.scrollTo(0, 0)
      })
    return (
        <>
            <Header />
            <Breadcrumb activePage="Terms & Condtions" />
            <div className="container tndC">
                <h2 className="title">Terms and Condtions   <span className="ezyIcon icon-check"></span></h2>
                <Tab.Container id="left-tabs-example" defaultActiveKey="general">
                    <Row>
                        <Col sm={3} className="pr-0">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="general" onClick={general}>General</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="clientResp" onClick={general1}>Client Responsibility</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="disclaimer" onClick={general2}>Disclaimer</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="pvcStatement" onClick={general3}>Privacy Statement</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="copyright" onClick={general3}>Copy right</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="agency" onClick={general5}>Obligations of the agency</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="confidentiality" onClick={general6}>Confidentiality</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="indemnity" onClick={general7}>Indemnity</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="governingLaw" onClick={general7}>Governing Law</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="forceMajeure" onClick={general8}>Force Majeure</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="variations" onClick={general8}>Variations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="indiv" onClick={general9}>Individuality</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tndc" onClick={general10}>Terms & Conditions</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            </StickyBox>
                        </Col>
                        <Col sm={9} className="tcLayout">
                            <div ref={tcGeneral} >
                            <ShowHide visible="true" icon="" title="General">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2" >
                                <p>These Terms and Conditions (“Terms”) governs the use of the website (“Service”). The Terms “Ezytrip”, “We”, “Us”, “Our” refer to Tawfeeq Travel and their affiliates.</p>

                                <p>Ezytrip is not a tour operator and works within the framework of a contract for services, Ezytrip fulfils the booking orders received from the Agency for the individual objects of services indicated in the contract. A contractual relationship shall not be established between Ezytrip and the Agency’s customers.</p>

                                <p>The following General Terms and Conditions of Business shall apply to the business relationship between Ezytrip and  the agencies on the other hand. By your use of the Service, you agree to comply with all of the terms and conditions set out herein, as amended periodically. Ezytrip may terminate your account at any time, with or without notice, for conduct that is in breach of these Terms, for conduct that we believe is harmful to our business, or for conduct where the use of the Service is harmful to any other party.</p>

                                <p>We may, in our sole discretion, change or modify these Terms at any time, with or without notice. You are responsible to read this document from time to time to ensure that your use of the Service remains in compliance with these Terms.</p>

                                <p>These Terms and Conditions and any additional terms posted on www.ezytrip.com (“Site”) together constitute the basis of the relationship between ezytrip and You (“Customer”) with respect to your use of this Site.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc1} >
                            <ShowHide visible="true"  icon="" title="Client Responsibility">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>When you apply to use our services, you will be provided with an Agent ID and will be required to select your password. The Agent ID and password are the means through which you access certain services (“Account”). You acknowledge and agree that it is your responsibility to safeguard the Agent ID and password from any unauthorized use. In no event will  Ezytrip be liable for the Unauthorized use Or Misuse Of Your Agent Id Or Password</p>

                                <p>Ezytrip is acting as intermediary or a “Booking Agent” for products and services that are not directly supplied by us (e.g., air tickets, hotel accommodations, meals, tours, cruises,ground transportation etc.). We are not a co-vendor of such products and services and thus, the client doesn’t have the right to claim financial reimbursement. Any advance amount paid by Client will be non-interest bearing and should be utilized within 1 year from the date of payment, during which time it shall be held in trust by us. If such advance is not utilized within this period of 1 year, the unutilized portion shall be deemed forfeited . It is clients responsibility to claim such amount from Ezytrip within  period of 1 Year. Ezytrip shall not be held liable/ be obligated to entertain any payment request after the same.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc2} >
                            <ShowHide visible="true"  icon="" title="Disclaimer">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>Service information displayed on the portal along with any information shared via email/call  is sourced directly from Global Distribution Systems/registerd service providers for Hotels or any other product. The supplier for respective product are responsible for the accuracy of such information. Such Information can change at any time without prior notice.</p>

                                <p>The client shall always re-confirm details such as  arrival and departure information, booking period  with your airline/ Hotel/Supplier respectively. Ezytrip will do everything possible to ensure that we provide the most accurate information at all times. However, Ezytrip willnot be liable for non-delivery of message and cannot guarantee the timeliness or reliability of messages sent to you or your service provider.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc3} >
                            <ShowHide visible="true"  icon="" title="Privacy Statement">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>As per the attached privacy statement</p>
                                </div>
                            </ShowHide>
                            <ShowHide visible="true"  icon="" title="Copy right">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>The information and images contained on this website, including downloadable files, are protected by copyright. No part may be reproduced or reused for any other purpose without due acknowledgement and advance written permission of the copyright holder. The content on this website is provided for information purposes only. While every effort has been made to ensure the accuracy and completeness of this information, Ezytrip makes no guarantee and accepts no responsibility for any errors or omissions it may contain. It is the responsibility of users to make their own decisions about the accuracy, reliability and correctness of information contained on these sites.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc4} >
                            <ShowHide visible="true"  icon="" title="Obligations of the agency">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>The Client shall be, obliged for the following:<br/>

                                    A) To prohibit unauthorized parties, in particular other agencies, from gaining access to booking platform and shall always keep confidential their login credentials.<br/>

                                    B) To clarify immediately any obscurities in the booking and payment transactions via Ezytrip.<br/>

                                    C) To comply with the pre-requisites for the booking and payment transactions as well as for the settlement in the event of failures and to examine confirmations in the processing of bookings with the usual diligence.<br/>

                                    D) To observe the legal regulations of data protection in the own company and during the transfer of data, and to guarantee their fulfillment.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc5} >
                            <ShowHide visible="true"  icon="" title="Confidentiality">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>Any and all information, price lists, data and contractual information which are made available on Ezytrip and which are not published shall be deemed to be confidential and the client is prohibited to pass on these data to other agencies, service providers or competitors. The Price lists, information concerning the service contents and service providers must be used for customer advisory services only.</p>
                                </div>
                            </ShowHide>
                            </div>

                            <div ref={tc6} >
                            <ShowHide visible="true"  icon="" title="Indemnity">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>Subject to the terms of respective agreement and the terms and conditions refered on the website (www.ezytrip.com), the customer agrees to indemnify, defend and hold harmless Ezytrip from and against all claims, liabilities, judgments, actions, penalties and other expenses (collectively, “Claims”) asserted against or incurred by the customer for</p>
                                <p>(i) performance of services, acts and omissions, breach of this agreement;</p>
                                <p>(ii) any act or services provided by the customer or omission by the customer in any agreement which relates to a  client, prospective client or this  Agreement, including any other agreement entered with its customers or suppliers/contractors and</p>
                                <p>(iii) Failure to comply with applicable law.</p>
                                <p>A) Without prejudice to the foregoing, the customer will indemnify and keep indemnified  Ezytrip (Registered brand under Tawfeeq Travel) and its Related Companies from and against direct/indirect loss, actual damage, or liability incurred by the customer and its Related Companies, including costs, claims, suits, actions, proceedings, damages, losses, judgments, settlements, penalties, fines, liabilities and reasonable expense of investigation and defence of any claim (including reasonable legal fees and disbursements, reasonable consultants fees and disbursements) arising from any of the following or otherwise:</p>
                                <p>1) Failure to comply with or contravention of any applicable present and future authorizations, registrations, duties of care, codes of conduct, regulations, notices, permits, consents, approvals, and licenses issued, imposed, or directed by any relevant body relating to the protection of the environment, use of property, harm to human health, injury, damage or loss whatsoever to any person or property owned or occupied by the customer; and/or</p>
                                <p>2) The deliberate or negligent act, error or omission of the customer or of by its employees or agents.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc7} >
                            <ShowHide visible="true"  icon="" title="Governing Law">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>These terms and conditions are  governed by Qatari law and the courts at Qatar shall have exclusive jurisdiction over the subject matter. If any dispute arises between you and us during your use of the Service or thereafter, in connection with and arising from your use or attempt to use this Site and the Service, the dispute shall be referred to arbitration. Each Party shall mutually appoint a sole arbitrator. The place of arbitration shall be Doha, Qatar.</p>
                                </div>
                            </ShowHide>
                            <ShowHide visible="true"  icon="" title="Force Majeure">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>Neither party shall be responsible or liable for or deemed in breach of these Terms because of any delay or failure in the performance of these Terms due to any event or circumstance the occurrence and the effect of which the party affected thereby is unable to prevent and avoid, including, without limitation acts of God; government regulations, curtailment of transportation facilities, strikes, lock-outs or other industrial actions or trade disputes of whatever nature (whether involving employees of a party or a third party), terrorist attacks, haze, sabotage, riots, civil disturbances, insurrections, national emergencies (whether in fact or law), blockades, acts of war, (declared or not),Endemic, Epidemic, Pandemic etc. (a “Force Majeure Event”). The non-performing party shall give the other party written notice describing the particulars of the Force Majeure Event as soon as possible.</p>
                                </div>
                            </ShowHide>
                            <div ref={tc8} >
                            <ShowHide visible="true"  icon="" title="Variations">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>In the event of any variations in the payment terms, the billing cycle along with the due date of payment shall be in line with the respective agreement terms. In case where bookings are kept on hold, the hold value shall be paid in line with the credit terms.</p>
                                </div>
                            </ShowHide>
                            </div>
                            </div>
                            <div ref={tc9} >
                            <ShowHide visible="true"  icon="" title="Individuality">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p>Ezytrip and the customer are independent entities. There is no relationship of partnership, joint venture, employment or franchise between the parties. Neither party will have the power to bind the other or incur obligations on the other’s behalf without the parties’ prior written consent.</p>
                                </div>
                            </ShowHide>
                            </div>
                            <div ref={tc10} >
                            <ShowHide visible="true"  icon="" title="Terms and conditions">
                                <div className="showHide-content pl-0 pr-0 pb-0 pt-2">
                                <p><strong>No Shows</strong>
                                    No show by the guest at the accommodation establishment without prior warning shall be considered a cancellation and no refund policy shall not be applicable.</p>

                                    <p><strong>Rates and Currency</strong>
                                    Unless otherwise specified all rates quoted are net and non-commissionable, inclusive of all taxes and service charges except any city taxes, resort fee, or other charges directly payable at the hotel by the guests. You must pay the invoices in the same currency as they are raised. In case You are desirous of settling invoices in a different currency, we may allow so only at our discretion at an exchange rate determined by us.</p>

                                   <p><strong> Chargeback</strong>
                                    You may use your company credit card or guest’s credit card for bookings. A guest’s credit card must only be used to pay for bookings of the same Guest. You are solely responsible for ensuring that a genuine credit card is used for paying for bookings. You take complete liability for any losses or damages arising out of the use of fake or stolen credit card or from any chargeback.</p>

                                    <p><strong>Relocations</strong>
                                    For us to support the End Customers if the Supplier is not able to honor any Booking, you:
                                    (a) shall continue to provide us with your up to date contact details for us to notify you of any changes to a Booking;<br/>
                                    (b) shall cooperate with us to facilitate such changes, including promptly liaising with the End Customers; and<br/>
                                    (c) agree that changes shall be in accordance with our standard process from time to time. Rate difference if any will be shared before charging to your account</p>

                                   <p><strong>Refunds</strong>
                                    The refund shall be made to the original instrument used at the time of booking. All refunds shall be subject to the refund policy as per the product offered. In the event Ezytrip is unable to immediately refund the amount, such amount shall lie as future available credit for the customer. Refunds will be done only through the Original Mode of Payment.</p>

                                    <p><strong>Declaration</strong>
                                    You warrant that neither You nor any of your clients, stakeholders or, directors are on the list of Blocked Persons as per the place of origin or destination. You shall immediately advise us in writing if You or any of your stakeholders or directors or clients are placed on the above lists.</p>

                                   <p> Method of Payment, Card Types accepted and Currency</p>

                                    <p>
                                    We accept payments online using Visa and MasterCard credit/debit card in QAR (or any other agreed currency) You can also use the Top-ups made to do the bookings online.</p>

                                    <p>

                                    Re-issue /Amendments
                                    Re-issue /Amendments shall be allowed subject to the policy for the respective product.</p>
                                </div>
                            </ShowHide>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>

            </div>

            <Footer />

        </>
    )
}


export default TaC;
