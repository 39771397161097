import React, { useContext, useEffect, useReducer, useState,useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Alert, Col, Form, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../../App";
import Breadcrumb from "../../../../components/common/breadcrumb/Breadcrumb";
import Button from "../../../../components/common/buttons/Button";
import Footer from "../../../../components/common/Footer";
import InnerLoader from "../../../../components/common/loader/InnerLoader";
import MainMenu from "../../../../components/common/MainMenu";
import NoRecord from "../../../common/popUps/NoRecord";
import OrganizationMenu from "../../../common/subMenu/OrganizationMenu";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import log from "loglevel";
import { creation, getSuppliersData, update, talist,deleteMaxHits ,getOfcIdList } from "./operation";
import Select from "react-dropdown-select";
import RegionType from "../../common/RegionType";
import SalesChannel from "../../common/SalesChannel"
let supp_json = require("../../../masterData/Suppliers.json");
export const USER_AUTH_DATA = "userAuthData";

/**
 * Initial State Declaration
 */
const initialState = {
  selBranch: [],
  enableReset:true,
  branchList: [],
  selRegion: [],
  region_type: [],
  region_type_edit: "",
  company_id: 0,
  showMessage: false,
  loadCategory: false,
  loadEdit: false,
  loadSubmit: false,
  id: 0,
  compList: [],
  suppliers: supp_json,
  company_name: "",
  res: [],
  enbaleUpdate: false,
  noRecords: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  enableView: false,
  isLoadingMore: false,
  nameError: false,
  companyError: false,
  noError: false,
  companyNames: [],
  showSave: true,
  isItineraryLoading: false,
  salesChannel: "",
  showBranch: true,
  hitsPerSearch: "",
  ofc_id: [],
  edit_salesChanel:"",
  clickedAdd : false
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        company_id: 0,
        showMessage: false,
        loadCategory: false,
        loadEdit: false,
        loadSubmit: false,
        id: 0,
        compList: [],
        suppliers: supp_json,
        res: [],
        enbaleUpdate: false,
        noRecords: false,
        notiMessage: "",
        notiMessageShow: false,
        notiVarient: "",
        enableView: false,
        isLoadingMore: false,
        nameError: false,
        companyError: false,
        noError: false,
        isItineraryLoading: false,
        selBranch: [],
        salesChannel: "",
        showBranch: true,
        hitsPerSearch: "",
        ofc_id: [],
        edit_salesChanel:"",
        clickedAdd : false
      };
    case "editBranch":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function MaxHits() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [validated, setValidated] = useState(false);
  const [classState, setRbdClass] = useState({ class: [] });
  const [OfficeIds, setOfficeIds] = useState([]);
  
  useEffect(() => {
    dispatch({ type: "isLoadingMore", payload: true });
    dispatch({ type: "enbaleUpdate", payload: false });
    getSuppliersData({"is_admin":context.logindata.is_admin, "region_type":context.logindata.region_type}).then((response) => { 
      if (response) {
        convertSupData(response);
      }
      dispatch({ type: "isLoadingMore", payload: false });
    });
    getOfcIdList().then((res) => {
      let list=[];
      if(res.data.suc){
       for(let val of res.data.res){
         const req={
         value:val.office_id,
         label:val.office_id
         }
         list.push(req)
      }
      setOfficeIds(list)
      }
   });
  }, [context.logindata.region_type]);
  useEffect(()=>{

  },[classState])
  useEffect(() => {

    if(context.logindata!==undefined && state.salesChannel === "B2C" && state.region_type){
    let is_admin=context.logindata.utype==="att_user"?1:0
     let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
      AttCommonSerivce.att_companies(pyload).then(response => {
        if (response.data.suc && response.data.res.status === 200) {
          let companyList = response.data.res.data.filter((obj)=>obj.sales_channel===state.salesChannel)
          dispatch({ type: "compList", payload: companyList });
        }
      })
    }
  }, [context.logindata,state.salesChannel,state.region_type]);
  
  const agentTypes = [ "B2B", "B2C"]
 

  useEffect(() => {
    if (state.salesChannel === "B2C") {
      dispatch({ type: "showBranch", payload: false });
    } else {
      dispatch({ type: "showBranch", payload: true });
    }
  }, [state.salesChannel]);

  //Converting all suppliers
  const convertSupData = (response) => {
    let data = [];
    var enableWEdit = false;
    var enableWDel = false;
    if(context.logindata!==""){
      if(context.logindata.roles.includes('C_M_O_H_E')) {
        enableWEdit = true;
      }
      if(context.logindata.roles.includes('C_M_O_H_D')) {
        enableWDel = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      if(userData.roles.includes('C_M_O_H_E')) {
        enableWEdit = true;
      }
      if(userData.roles.includes('C_M_O_H_D')) {
        enableWDel = true;
      }
    }
    if (response !== undefined) {
      for (let val of response) {
        let officeId = []
        let noOfHits = []
        for (let ofc of val.oid_list){
          officeId.push(ofc.ofc_id)
          officeId.push(" - ")
          noOfHits.push(ofc.hits)
          noOfHits.push(" - ")
        }
        officeId.pop()
        noOfHits.pop()
        if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){
          data.push({
          sales_cha:val.sales_cha,
          cname: val.cname,
          region_type: val.region_type,   
          ofc_id:officeId,
          no_of_hits: noOfHits,
          options: (
            <>
              <span className="tbAction">
              {enableWEdit && <a
                    className="ezyIcon icon-edit"
                    onClick={editSupplier(val, "edit")}
                  ></a>}
              {enableWDel && <a
                  onClick={editSupplier(val, "del")}
                  className="ezyIcon icon-delete"
                ></a>}
              </span>
            </>
          ),
        });
      }
      }
      console.log("res++++++++++++++++-------", JSON.stringify(data));
      dispatch({ type: "res", payload: data });
    }
    dispatch({ type: "isLoadingMore", payload: false });
  };

  //redirect to admin page
  const handleCancel = (e) => {
    window.location = "/ezytrip/administration/oidMaxHits";
  };
  const handleOfficeIdChange = (e) => {
    dispatch({ type: "ofc_id", payload: e });
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  }
  // Add officeID
  const addOfficeId = (e) => {
    e.preventDefault();
    
    if (state.ofc_id && state.hitsPerSearch.length>0 && state.salesChannel.length !== 0 && state.region_type.length !== 0 && (state.selBranch.length !== 0 || state.salesChannel === "B2C") && state.companyNames.length !== 0) {
     
      const payload = {
        ofc_id: state.ofc_id.lenght!=0?state.ofc_id[0].value:"",
        hits: state.hitsPerSearch,
      };
      setRbdClass({ class: [...classState.class, payload] });

      ;
      dispatch({ type: "ofc_id", payload: [] });
      dispatch({ type: "hitsPerSearch", payload: "" });
      dispatch({ type: "region_type_edit", payload: state.region_type });
      dispatch({ type: "edit_salesChanel", payload: state.salesChannel });
      dispatch({ type: "clickedAdd", payload: true });

    }else if(state.salesChannel ===""){
      dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: "Enter sales channel.." });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
    }else if(state.region_type.length === 0 ){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Region Type" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else if(state.selBranch.length === 0 && state.salesChannel === "B2B"){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Branch" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else if(state.companyNames.length === 0){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Company" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else{
        dispatch({ type: "isItineraryLoading", payload: false });
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: "Fill the details" });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
    }
  };
  // delete office id
  const handleRemoveClass = (idx,type) => () => {
    if(type === "del"){
      confirmDlg(idx)

    }else{
      let selected = classState.class.filter((s, sidx) => idx === sidx)
      // ofc_id: state.ofc_id,
      //   hits: state.hitsPerSearch,
      let ofsc = OfficeIds.filter((obj)=>obj.label===selected[0].ofc_id)
      dispatch({ type: "ofc_id", payload:ofsc });
      dispatch({ type: "hitsPerSearch", payload: selected[0].hits });
      let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
      setRbdClass({ class: classState.class.filter((s, sidx) => idx !== sidx) });
      setRbdClass({ class: [] });
    }
      
  }

  //Create Categeory
  const handleSuppCreation = (e) => {
    if(state.salesChannel ===""){
      dispatch({ type: "notiMessageShow", payload: true });
            dispatch({ type: "notiMessage", payload: "Enter sales channel.." });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
    }else if(state.region_type.length === 0 ){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Region Type" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else if(state.selBranch.length === 0 && state.salesChannel === "B2B"){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Branch" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else if(state.companyNames.length === 0){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "select Company" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }else if(classState.class.length === 0){
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Add Office Id and Max Hits" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
      else{
        dispatch({ type: "isItineraryLoading", payload: true });
        const payload = {
        "sales_cha": state.salesChannel,
        "region_type": context.logindata.utype === "att_user"
        ? state.region_type
        : context.logindata.region_type,
        "branch_id":state.selBranch.length>0?state.selBranch[0].value:"",
        "cid": state.companyNames[0].value,
        "crby": context.logindata.uid,
        "oid_list": classState.class
    }
      creation(payload).then((response) => {
        if (response.success) {
          // dispatch({ type: "selBranch", payload: [] });
          // dispatch({ type: "companyNames", payload: [] });
          // dispatch({ type: "salesChannel", payload: "" });
          // dispatch({ type: "showBranch", payload: true });
          // dispatch({ type: "hitsPerSearch", payload: "" });
          // dispatch({ type: "edit_salesChanel", payload: "" });
          // dispatch({ type: "ofc_id", payload: [] });
          // setRbdClass({ class: [] });
          // dispatch({ type: "region_type", payload: [] });

          // getSuppliersData().then((res) => {
          //   if (res && res.res !== 0) {
          //     convertSupData(res);
          //   }
          //   dispatch({ type: "isLoadingMore", payload: false });
          // });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: "MaxData Saved Successfully" })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                window.location = '/ezytrip/administration/oidMaxHits'
                dispatch({ type: 'isItineraryLoading', payload: false });
        } else {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({ type: "notiMessage", payload: "MaxData not saved.." });
          dispatch({ type: "notiVarient", payload: "danger" });
          hidemessage();
          dispatch({ type: "isItineraryLoading", payload: false });
          // window.location = "/ezytrip/administration/oidMaxHits";
        }
      });
    // e.preventDefault()
  }
    // window.location = "/ezytrip/administration/oidMaxHits";
     }
  

  //Edit Categeory
  const editSupplier = (obj, type) => () => {
    dispatch({ type: "id", payload: obj.id });
    dispatch({ type: "enableReset", payload: false });
    if(type==="del"){
      confirmDelete(obj, type)
    }
    // debugger
    
    dispatch({ type: "enableView", payload: false });
    dispatch({ type: "region_type_edit", payload: obj.region_type });
    dispatch({ type: "region_type", payload: obj.region_type });
    dispatch({ type: "company_id", payload: obj.cid });
    setRbdClass({ class:  obj.oid_list });
    dispatch({ type: "salesChannel", payload:obj.sales_cha });
    dispatch({ type: "companyNames", payload: [{ "value": obj.cid, "label": obj.cname }] });
    dispatch({ type: 'selBranch', payload: [{ "value": obj.branch_id, "label": obj.branch_name }] })
    dispatch({ type: "edit_salesChanel", payload: obj.sales_cha });
    


    if (type === "view") {
      dispatch({ type: "enableView", payload: true });
      dispatch({ type: "enbaleUpdate", payload: false });
      dispatch({ type: "showSave", payload: false });
    } else {
      dispatch({ type: "enableView", payload: false });
      dispatch({ type: "enbaleUpdate", payload: true });
      dispatch({ type: "showSave", payload: false });
    }
  };

  //Update Supplier
  const updateSupplier = (e) => {    
    let pld = {
      "id":state.id,
      "sales_cha": state.salesChannel,
      "region_type": context.logindata.utype === "att_user"
          ? state.region_type_edit
          : context.logindata.region_type,
      "branch_id":state.selBranch[0].value,
      "cid": state.company_id,
      "upby": context.logindata.uid,
      "oid_list": classState.class
  }

    dispatch({ type: "isItineraryLoading", payload: true });
    update(pld).then((response) => {
      if (response.success) {
        dispatch({ type: "companyNames", payload: [] });
        dispatch({ type: "insSupNames", payload: [] });
        dispatch({ type: "supNames", payload: [] });
        dispatch({ type: "loadCategory", payload: false });
        talist().then((res) => {
          dispatch({ type: "compList", payload: res });
        });
        dispatch({ type: "enbaleUpdate", payload: false });
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Supplier updated successfully",
        });
        dispatch({ type: "notiVarient", payload: "success" });
        dispatch({ type: "showSave", payload: true });
        hidemessage();
        return (window.location = window.location.pathname);
      } else {
        dispatch({ type: "isItineraryLoading", payload: false });
        dispatch({ type: "loadCategory", payload: false });
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Supplier has not updated..",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        hidemessage();
      }
    });
  };


  const columns = [
    {
      dataField: "sales_cha",
      text: "Sales Channel",
      sort: true,
      filter: textFilter(),
    },{
      dataField: "cname",
      text: "Company Name",
      sort: true,
      filter: textFilter(),
    },{
      dataField: "region_type",
      text: "Region",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "ofc_id",
      text: "Office Id",
      sort: true,
      filter: textFilter(),
    },
    
    {
      dataField: "no_of_hits",
      text: "No. Of Hits",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "options",
      text: "Actions",
      class: "tes",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const handleRegionType = (e) => {
   if(state.enableReset){ 
    // dispatch({ type: "selBranch", payload: [] });
    // dispatch({ type: "companyNames", payload: [] })
  }
    dispatch({ type: "branchList", payload: e });
  };

  const updateRegion = (region) => {
    dispatch({ type: "region_type", payload: region });
  };

  const companySet = (value) =>{
    dispatch({ type: "companyNames", payload: value })
  }

  const handleBranchChange = (e) => {
    if (state.enableReset){
      // dispatch({ type: "companyNames", payload: []})
    }
    if (e !== undefined && e.length !== 0) {
      dispatch({ type: "selBranch", payload: e });
      e &&
        e[0] &&
        e[0].value &&
        AttCommonSerivce.getCompanyList(e[0].value)
          .then((response) => {
            if (response.data.suc) {
              let companyList = response.data.res.filter((obj)=>obj.sales_channel===state.profileType)
              dispatch({ type: "compList", payload: companyList });
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    ;
  };
  const handleProfileType = (schanel) => {
    if(state.enableReset){
    // dispatch({type:"selBranch", payload:[]})
    // dispatch({type:"companyNames", payload:[]})
  }
    if (schanel) {
      dispatch({
        type: "selAgent",
        payload: [{ label: "Travel Agent", value: "TA" }],
      });
      dispatch({ type: "profileType", payload: schanel });
      dispatch({ type: "salesChannel", payload: schanel });
      if (schanel === "B2C") {
        dispatch({ type: "enableB2C", payload: true });
      } else {
        dispatch({ type: "enableB2C", payload: false });
      }
      dispatch({ type: "selProfiles", payload: [{value:schanel,label:schanel}] });
    }
  }
  const confirmDlg = (obj) => {
    confirmAlert({
        customUI: ({onClose}) => {
            return (
                <>
                    <div className="custom-ui">
                        <span className="confirmD"></span>
                        <h3>Are you sure?</h3>
                        <p className="t-color2 mb-1">You want to delete the configuration ?</p>
                    </div>
                    <div className="buttonGrop text-center border-0 pb-0">
                        <button
                            className="btn btn-xs btn-secondary pl-4 pr-4"
                            onClick={() => {
                                handleDelete(obj);
                                onClose();
                            }}>
                            Yes
                        </button>
                        <button className="btn btn-primary btn-xs pl-4 pr-4"onClick={() => {
                                onClose();
                            }} >
                            No
                        </button>
                    </div>
                </>
            );
        }
    });
};
const confirmDelete = (obj, type) => {
  confirmAlert({
      customUI: ({onClose}) => {
          return (
              <>
                  <div className="custom-ui">
                      <span className="confirmD"></span>
                      <h3>Are you sure?</h3>
                      <p className="t-color2 mb-1">You want to delete the configuration ?</p>
                  </div>
                  <div className="buttonGrop text-center border-0 pb-0">
                      <button
                          className="btn btn-xs btn-secondary pl-4 pr-4"
                          onClick={() => {
                              handleDeleteconfiguration(obj, type);
                              onClose();
                          }}>
                          Yes
                      </button>
                      <button className="btn btn-primary btn-xs pl-4 pr-4"onClick={() => {
                              onClose();
                          }} >
                          No
                      </button>
                  </div>
              </>
          );
      }
  });
};
const handleDeleteconfiguration = (obj, type)=>{
  dispatch({ type: "isItineraryLoading", payload: true });

      deleteMaxHits(obj.id).then((response) => {
        if (response.res.success) {
          dispatch({ type: "notiMessageShow", payload: true });
          dispatch({
            type: "notiMessage",
            payload: "Deleted successfully",
          });
          dispatch({ type: "notiVarient", payload: "success" });
          dispatch({ type: "showSave", payload: true });
          dispatch({ type: "isItineraryLoading", payload: false });
          window.location = "/ezytrip/administration/oidMaxHits";
return
        }
      else{
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "Not deleted successfully",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        dispatch({ type: "showSave", payload: true });
        dispatch({ type: "isItineraryLoading", payload: false });
        window.location = "/ezytrip/administration/oidMaxHits";
return
      }
    })
}
const handleDelete = (idx)=>{
  
  let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
  setRbdClass({ class: classState.class.filter((s, sidx) => idx !== sidx) });
}

  return (
    <>
      <MainMenu active="Organization/CM" />
      <Breadcrumb activePage="OID-Max Hits" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <OrganizationMenu active="OID-Max Hits" />
          <div className="contentArea">
            <Alert
              className="notification"
              variant={state.notiVarient}
              show={state.notiMessageShow}
              onClose={() =>
                dispatch({ type: "notiMessageShow", payload: false })
              }
              dismissible
            >
              {" "}
              {state.notiMessage}
            </Alert>
            {state.isItineraryLoading ? (
              <div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Form noValidate validated={validated} id="formId">
              <Form.Row>
                 <SalesChannel sendSalesChanel={handleProfileType} edit_sales={state.edit_salesChanel}/>
                {context.logindata.utype !== "ta_user" && (
                  <>
                    <RegionType
                      sendResponse={(e) => handleRegionType(e)}
                      regionType={updateRegion}
                      regionUpdate={state.region_type_edit}
                    />
                    {state.showBranch  && (
                      <Form.Group
                        as={Col}
                        xs={3}
                        className="selectplugin"
                        controlId="branch_id"
                      >
                        <Select
                          options={state.branchList}
                          values={state.selBranch}
                          searchable={false}
                          disabled={state.enbaleUpdate || state.clickedAdd}
                          onChange={(e) => handleBranchChange(e)}
                        />
                        {state.supplierError && (
                          <p className="vError">Please Select branches</p>
                        )}
                        <Form.Label>
                          Branch<sup>*</sup>
                        </Form.Label>
                      </Form.Group>
                    )} 
                  </>
                )}
                 <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="selectedValue"
                >
                  <Select
                    options={state.compList}
                    values={state.companyNames}
                    //onChange={(value) => (setValue(value), setCompany(value))}
                    onChange={(value) => companySet(value)
                    }
                    disabled={state.enbaleUpdate || state.enableView|| state.clickedAdd}
                  />
                  <Form.Label>
                    Company<sup>*</sup>
                  </Form.Label>
                  {state.companyError && (
                    <p className="vError">Please Select a Company</p>
                  )}
                </Form.Group> 
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="ofc_id"
                >
                  <Select
                    options={OfficeIds}
                    values={state.ofc_id}
                    // searchable={false}
                    // disabled={state.enbaleUpdate}
                    onChange={handleOfficeIdChange}
                  />
                  {state.supplierError && (
                    <p className="vError">Please Select branches</p>
                  )}
                  <Form.Label>
                    Office Id<sup>*</sup>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="hitsPerSearch">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter Company Name"
                    value={state.hitsPerSearch}
                    onChange={handleChange}
                  />
                  <Form.Label>
                    #Hits Per Search<sup>*</sup>
                  </Form.Label>
                  {state.nameError && (
                    <p className="vError">Please Enter Exchange Rate</p>
                  )}
                </Form.Group>
                <div
                  className="text-right buttonGrop pb-0"
                  style={{ padding: "0px" }}
                >
                  {context.logindata.roles!==undefined && context.logindata.roles.includes('C_M_O_H_C') &&
                    <Button
                    size="xs"
                    variant="outline-primary"
                    onClick={addOfficeId}
                  >
                    {" "}
                    Add
                  </Button>}
                </div>
              </Form.Row>
              <Form.Row>
                {classState.class.length>0 &&
                <div className="tableLayout" style={{ width: "59%" }}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Office Id</th>

                        <th>No. Of Hits</th>

                        <th className="text-right" width="8%">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {classState.class.map((cl, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <span>{cl.ofc_id}</span>
                            </td>
                            <td>
                              <span>{cl.hits}</span>
                            </td>
                            <td>
                            <span className="tbAction">
              
              
                            <a
                                onClick={handleRemoveClass(index,"edit")}
                                className="ezyIcon icon-edit"
                              ></a>
                              <a
                                onClick={handleRemoveClass(index,"del")}
                                className="ezyIcon icon-delete"
                              ></a></span>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
                }
              </Form.Row>

              <Form>
                <Table></Table>
              </Form>
            </Form>
            <div className="text-right buttonGrop pb-0">
              {context.logindata.roles !== undefined && (
                <Button
                  size="xs"
                  variant="outline-secondary"
                  onClick={handleCancel}
                  type="submit"
                  isDisabled={state.enableView}
                >
                  Reset
                </Button>
              )}
              {!state.enbaleUpdate && state.showSave && (
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={handleSuppCreation}
                  isDisabled={state.enableView}
                  loading={state.loadCategory}
                  type="submit"
                >
                  {" "}
                  Save
                </Button>
              )}
              {state.enbaleUpdate && (
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={updateSupplier}
                  loading={state.loadCategory}
                  isDisabled={state.enableView}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              )}
            </div>
          </div>
          <div></div>
        </div>

        <div className="cardMainlayout tableRes p-0">
          {state.isLoadingMore ? (
            <InnerLoader />
          ) : (
            <>
              {(state.res.length !== 0 && (
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl suplierConfig"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={
                    state.res.length > 10 && state.res.length <= 25
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                          ],
                        })
                      : state.res.length > 25 && state.res.length <= 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                          ],
                        })
                      : state.res.length > 30
                      ? paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "25", value: 25 },
                            { text: "30", value: 30 },
                            { text: "50", value: 50 },
                          ],
                        })
                      : ""
                  }
                />
              )) ||
                (state.res.length === 0 && <NoRecord />)}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MaxHits;
