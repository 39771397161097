import axios from 'axios'

const API_URL = process.env.REACT_APP_PRE_BOOK_URL
/**
 * @author: Shaik Faiyaz
 * @description: Integrated the Markup CURD Operations for hotel service
 * @param {*} Service 
 */

class HotelCrossSellService {
    
    async getCorssSellList(obj) {
        return await axios.post(`${API_URL}/crosssell/retrieve`, obj);
    }

    async saveCrossSell(promoData) {
        return await axios.post(`${API_URL}/crosssell/create`,promoData)
    }

    async updateCrossSell(promoData) {
        return await axios.put(`${API_URL}/crosssell/update/${promoData.id}`,promoData)
    }

    async editCrossSell(id) {
        return await axios.get(`${API_URL}/crosssell/edit/${id}`)
    }
    
   
    async deletePromoFMap(id) {
        return await axios.delete(`${API_URL}/crosssell/com/delete/${id}`)
    }
}

export default new HotelCrossSellService()