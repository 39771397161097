import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Table } from "react-bootstrap";

//This function will show the Air markups List and Add Markup
//Initial State

  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================


function DomesticContent(props) {
return(
  <>
<Table>
<thead>
  <tr>
    <th>Fare Type</th>
    <th>Saver</th>
    <th>Flexi </th>
    {props.isCorporate && <th>Corporate</th>}

  </tr>
  </thead>
  <tbody>
  <tr>
    <td><strong>6E Tiffin</strong></td>
    <td>Chargeable</td>
    <td>Complimentary</td>
    {props.isCorporate && <td>Complimentary</td>}

  </tr>
  <tr>
    <td><strong>Standard Seat</strong></td>
    <td>Chargeable</td>
    <td>Included</td>
    {props.isCorporate && <td>Included</td>}

  </tr>
  <tr>
    <td><strong>Date change fee per passenger Per sector</strong></td>
    <td>Up to INR 3000</td>
    <td>0-3 days** : INR 3250* 4 days** & above : No Fee</td>
    {props.isCorporate && <td>No Change Fee</td> }

  </tr>
  <tr>
    <td><strong>Cancellation charges per passenger per sector</strong></td>
    <td>Up to INR 3500</td>
    <td>0-3 days** : INR 3500* 4 days** & above : INR 500*</td>
    {props.isCorporate && <td>No Cancellation Fee</td>}

  </tr>
  <tr>
    <td><strong>XL Seat</strong></td>
    <td>No discount</td>
    <td>Upto 50% discount on Retail Rate</td>
    {props.isCorporate && <td>Upto 50% discount on Retail Rate</td>}

  </tr>
  <tr>
    <td><strong>Check-In Baggage</strong></td>
    <td>15KG</td>
    <td>15KG</td>
    {props.isCorporate && <td>15KG</td>}

  </tr>
  <tr>
    <td><strong>Hand Baggage</strong></td>
    <td>One hand bag up to 7 kgs and 115 cms (L+W+H), shall be allowed per customer</td>
    <td>One hand bag up to 7 kgs and 115 cms (L+W+H), shall be allowed per customer</td>
    {props.isCorporate && <td>One hand bag up to 10 kgs and 115 cms (L+W+H), shall be allowed per customer</td>}

  </tr>
  <tr>
    <td><strong>Cancellation charges</strong></td>
    <td>-</td>
    <td>-</td>
    {props.isCorporate && <td>-</td>}

  </tr>


  </tbody>

  </Table>

  </>
)


}
export default DomesticContent