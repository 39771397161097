import React, { useState } from "react";
import DashBordAlert from "../common/dashBoard/DashBordAlert";
import { Alert, Col, Row, Modal, Button, Tab } from "react-bootstrap";
import DashBordOffer from "../common/dashBoard/DashBordOffer";
import DashBordReminder from "../common/dashBoard/DashBordReminder";
import Tabs from "../common/tabs/Tabs";

let AdminDashbordAlert = require(".././masterData/notificationAlert.json");

function DashBordNotifications(props) {
  const [state, setState] = useState({});
  const [alrtsSize, setAlertsSize] = useState(0);
  const [alrts, setAlerts] = useState();
  const [tablist, setTablist] = useState({
    active: "Reminders",
  });

  const updateSize = (size) => {
    setAlertsSize(size);
  };
  const updateAlerts = (alerts) => {
    setAlerts(alerts);
  };

  const content = {
    Alerts: <DashBordAlert sendAlertsSize={updateSize} Alerts={alrts} />,
    Reminders: (
      <DashBordReminder sendAlertsSize={updateSize} sendAlerts={updateAlerts} />
    ),
    // Offers: <DashBordOffer />
  };

  return (
    <>
      <div className="dashBordNotification">
        {/* <h4 className="dashBordTitle mb-3">Notifications</h4> */}
        {/* <div className="Topfilterbutton">

          <div class="left-button">
            <Button className="rmBtn active" variant="outline-secondary">
              <span className="ezyIcon icon-alarm remaindbotton">Reminders</span>
            </Button>

            <Button className="rmBtn" variant="outline-secondary">
              <span className="ezyIcon icon-warning alertbotton">  <Link
                to="/ezytrip/dashboard/alerts/list"
                title="Reminder"> Alerts </Link></span>
            </Button>

          </div>

        </div> */}

        <div className="tabLink mb-3">
          <div className="tabContent">{content[tablist.active]}</div>
        </div>
      </div>
    </>
  );
}

export default DashBordNotifications;
