import React, { Component } from "react";
import footerBrand from "../../assets/images/footerBrand.svg";
const Footer = props => {
  return (
    <footer>
       <div className="container text-center">
       <img
                src={footerBrand}
                className="footerlogos mt-3"
                title="footerlogos"
                alt="logo"
              />
        <p className="t-color2 mt-3">Copyright © {new Date().getFullYear()} ezytrip</p>
        </div>
    </footer>
  );
};

export default Footer;
