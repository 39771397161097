import React, { useState,useContext } from 'react';
import { Table } from "react-bootstrap";
import Utilities from '../../commonUtils/Utilities';
import {Context} from '../../../../App'



/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function RefundDetails(props) {
    const [refundResp, setResponse] = useState(props.response);
    const [attPriceList, setattPriceList] = useState();
    const [context, setContext] = useContext(Context);

    const calculateRefundSup = (price) => {
        const total = price.reduce((prev, t) => {
            return Utilities.addPrice(prev, t.refundsup, "Add");
        }, 0);

        return total;
    }

    const calculateRefundCL = (price) => {
        const total = price.reduce((prev, t) => {
            return Utilities.addPrice(prev, t.subtotal, "Add");
        }, 0);

        return total;
    }

    return (
        <div className="recordInfo">
            {props.refundData.length !== 0 && props.refundData !== undefined &&
                <Table className="m-0">
                    <thead>
                        <tr>
                            <th>Passenger Name</th>
                            <th>Passenger Type</th>
                            <th>Base Fare</th>
                            <th>Taxes</th>
                            <th>Markup</th>
                            <th className="canclInfo">Service Fee</th>
                            <th className="canclInfo">Airline Charges</th>
                            <th className="canclInfo">Supplier Charges</th>
                            <th className="canclInfo">Refund Markup</th>
                            <th className="canclInfo">Refund Fee</th>
                            <th className="canclInfo">Other Charges</th>
                            <th className="canclInfo">Fare Used</th>
                            <th>Sub Total</th>
                            <th>Refund from Supplier</th>
                            {props.type === "ATT" &&
                                <th>Refund to TA</th>
                            }
                            {props.type === "TA" &&
                             <>
                             {context.logindata.butype == "sta_user" ? (
                                 <th>Refund to STA</th>
                             ) : <th>Refund to Customer</th>}
                                 </>
                                                             }
                            {props.type === "STA" &&
                                <th>Refund to Customer</th>
                            }

                        </tr>
                    </thead>

                    <tbody>
                        {props.refundData.map((pr, idx) => (
                            <tr>
                                <td>
                                    <span>{pr.paxname.toUpperCase()}</span>
                                </td>
                                <td>
                                    {pr.ptype === "ADT" &&
                                        <span>Adult</span>
                                    }
                                    {pr.ptype === "CHD" &&
                                        <span>Child</span>
                                    }
                                    {pr.ptype === "INF" &&
                                        <span>Infant</span>
                                    }
                                </td>
                                <td>
                                    <span>{pr.basefare}</span>
                                </td>
                                <td>
                                    <span>{Number(pr.taxes) + Number(pr.bk_tot_gst) + Number(pr.bk_tot_tds)}</span>
                                </td>
                                <td>
                                    <span>{pr.bkmarkup}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.bkservice}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.aircharge}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.supcharge}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.canmarkup}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.canservice}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.ocharges}</span>
                                </td>
                                <td className="canclInfo">
                                    <span>{pr.fareused}</span>
                                </td>
                                <td>
                                    <span>{pr.subtotal}</span>
                                </td>
                                <td>
                                    <span>{pr.refundsup}</span>
                                </td>
                                <td>
                                    <span>{pr.subtotal}</span>
                                </td>
                            </tr>
                        ))}
                        <tr className="totalAmount">
                            <td colSpan="12"></td>
                            <td><strong>Total :</strong></td>
                            <td > <strong>  {calculateRefundSup(props.refundData)}</strong></td>
                            <td> <strong> {calculateRefundCL(props.refundData)}</strong></td>
                        </tr>
                    </tbody>

                </Table>
            }
        </div>
    )
}
export default RefundDetails