import axios from 'axios'

const API_HOTEL_URL = process.env.REACT_APP_PREDICTIVE_URL
const API_URL = process.env.REACT_APP_DB_URL
const API_URL_PREDICTIVE = process.env.REACT_APP_SEARCH_URL

/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations service
 * @param {*} Service 
 */
class PredectiveSearch {
    
    async retreieveSearch(data) {
        return await axios.get(`${API_URL_PREDICTIVE}/search/`+data+'?skip=0&limitCity=10&limitProperty=50');
    };

    async retriveRecentSearch(id) {
        return await axios.get(`${API_URL}/common/fetchPredictive/${id}`);
    }

   
}
export default new PredectiveSearch()