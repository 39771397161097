import log from "loglevel";
import React, { useCallback, useEffect, useState, useReducer, useContext, useRef } from 'react';
import { Context} from "../../../App";
import { Alert, Col, Form, Row, Table, Tabs, Tab ,} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import { stdCodes, countriesWitCode, mealPrefs, seatPrefs, specialPrefs, stdBookingCodes, titles } from '../adminstration/utils';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Button from '../common/buttons/Button';
import Footer from '../common/Footer';
import Header from '../common/Header';
import useOnclickOutside from 'react-cool-onclickoutside';
import PredectiveSearch from '../../services/hotelpredictive';
import ManualPaymentForm from "./ManualPaymentForm";
import CustomInformaion from "../bookingFlows/flight/review/CustomInformaion";
import Utilities from '../commonUtils/Utilities';
import FormateCurrency from '../commonUtils/FormateCurrency';
import FileUpload from '../commonUtils/FileUpload';
import { Scrollbars } from 'react-custom-scrollbars';
import { getCrediLimit } from "../bookingFlows/hotel/pages/operations";
import {saveHotelBooking,sendManualEmail} from './operations';
import {characterNumberValidation, characterValidation, emailValidation, mobileNumberValidation } from '../commonUtils/FormValidations';
import debounce from 'lodash.debounce';
import moment from "moment";
import DateUtils from "../../components/commonUtils/DateUtils"
import supplierlist from './supplier.json'
import AttCommonSerivce from "../../services/commonService/AttCommonSerivce";
export const USER_AUTH_DATA = "userAuthData";
export const CLIENT_IP = "clientIp";

/**
 * @description:This function will create manual booking request
 * @author: Pawan
 * @param {*}
 * @function Manual Booking Hotel
 * @date : 16-11-2021
 */

/**
 * Initial State Declaration
 */
 const initialState = {
  isItineraryLoading:false, sup:"",notiMessage: '', notiMessageShow: false, notiVarient: '', destName:"",cleardb:false , hotelData: [] , selHotel:[] , hotelPredectiveDropDown:false , _startDateCal: false, _endDateCal: false,selRating:[] , rating:"" ,nights:0,
   cat:"", landmark:"",phno:"",add:"" , total_price:0, roomDetails:[] , childAgeList:[] ,showCustoms:true, stdCodeMenu: stdCodes(),payData:"",custms:[],conf_mail: false, inv_mail: false,enablePay:false,dest_obj:"",hotel_img:"", refundable: false, supError: false, destError: false, htnameError: false, checkinError: false, checkoutError: false, htphnError: false, sup_name: "",
   gst_type:"",gst_total:0,cgst:0, sgst:0, igst:0,att_gst:0, att_cgst:0, att_sgst:0,att_igst:0,ta_gst:0, ta_cgst:0, ta_sgst:0, ta_igst:0, tds_per:0
  };
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return { ...state, [action.type]: action.payload };
    }
  };


function HotelManualBooking(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [originIsFocus, setOriginIsFocus,] = useState(false);
    const [hotelData,setHotelData] = useState([]);
    const [selRating,setSelRating] = useState([]);
    const [selAccom,setSelAccom] = useState([]);
    const [selAdts,setSelAdts] = useState([{"value": "", "label": "Select"}]);
    const [selChds,setSelChds] = useState([{"value": "", "label": "Select"}]);
    const [selTl,setSelTl] = useState([{"value": "", "label": "Select"}]);
    const [selPhc,setSelPhc] = useState([]);
    const [selSup,setSelSup] = useState([]);
    const [selAge,setSelAge] = useState([]);
    const [isSameRoom,setIsSameRoom] = useState(false);
    const [toDate, setToDate] = useState(null);
    const destination = useRef();

  const [ratings,setRatings] = useState([
    {"value":"1.0","label":"1.0"},
    {"value":"2.0","label":"2.0"},
    {"value":"3.0", "label": "3.0"},
    {"value":"4.0", "label": "4.0"},
    {"value":"5.0", "label": "5.0"}
  ])
  const [accomTypes,setAccomTypes] = useState([
    {"value":"Apartment","label":"Apartment"},
    {"value":"Aparthotel", "label": "Aparthotel"},
    {"value":"All-inclusive property", "label": "All-inclusive property"},
    {"value":"Campsite", "label": "Campsite"},
    {"value":"Condominium resort", "label": "Condominium resort"},
    {"value":"Guesthouse", "label": "Guesthouse"},
    {"value":"Hotel","label":"Hotel"},
    {"value":"Hostel/Backpacker accommodation", "label": "Hostel/Backpacker accommodation"},
    {"value":"Property","label":"Property"},
    {"value":"Palace","label":"Palace"},
    {"value":"Private vacation home", "label": "Private vacation home"},
    {"value":"Resort", "label": "Resort"},
    {"value":"Residence", "label": "Residence"},
    {"value":"Safari/Tentalow","label":"Safari/Tentalow"},
    {"value":"Villa", "label": "Villa"},
    
  ])

const title = [
  { label: "Select", value: "" },
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Shka", value: "Shka" },
  { label: "Shk", value: "Shk" },
];

const [adutlsList,setAdultsList] = useState([
  {"value": "", "label": "Select"},
  {"value":1,"label":"1"},
  {"value":2,"label":"2"},
  {"value":3, "label": "3"},
  {"value":4, "label": "4"},
  {"value":5, "label": "5"},
  {"value":6, "label": "6"}
])

const [chdsList,setChdsList] = useState([
  {"value":"","label":"Select"},
  {"value":1,"label":"1"},
  {"value":2,"label":"2"},
  {"value":3, "label": "3"},
  {"value":4, "label": "4"},
  {"value":5, "label": "5"}
])

const ChildernAge = [
  { value: '1', label: '1' },{ value: '2', label: '2' },{ value: '3', label: '3' },{ value: '4', label: '4' },{ value: '5', label: '5' },{ value: '6', label: '6' },{ value: '7', label: '7' },{ value: '8', label: '8' },{ value: '9', label: '9' },{ value: '10', label: '10' },{ value: '11', label: '11' }]


useEffect(() => {
  convertRoomPax(false)
}, []);

const convertRoomPax = (isAdd) => {
  let roomInitRequest = [];
  const obj = {
    sno: !isAdd?1:state.roomDetails.length+1,
    tl: "",
    ty: "ADT",
    plbl: "Adult",
    lead: true,
    fn: "",
    ln: "",
    em: "",
    phc: "",
    phn: "",
    selTl:[]
  };
  const fare={
    attBaseFare:"",
    attTaxes:"",
    attMarkup:0,
    attServiceFee:"",
    attCommision: 0,
    attSupFee:"",
    attDisc:"",
    attAgentDisc:0,
    attTotal:"",
    taMarkup:"",
    taServiceFee:"",
    taSupFee:"",
    taDisc:"",
    taTotal:"",
    gst_type:"",
    tds_val:0,
    tds_per:0,
    sgst:0,
    cgst:0,
    igst:0,
    att_cgst:0,
    att_sgst:0,
    att_igst:0,
    ta_cgst:0,
    ta_sgst:0,
    ta_igst:0,
    gst_total:0
  }
  let payload = {
    rmindex: !isAdd?1:state.roomDetails.length+1,
    rmid: !isAdd?1:state.roomDetails.length+1,
    adt: 1,
    chd: 0,
    chdage:"",
    rnm:"",
    pax: obj,
    spr:"",
    canpolicy:"",
    imp_info:"",
    sup_ref:"",
    htl_conf:"",
    ra:"",
    pr:fare,
    isSameRoom:false,
    selAdts:[],
    selChds:[]
  };
  roomInitRequest.push(payload);
  if(isAdd){
    let roomsLIst=state.roomDetails;
    roomsLIst.push(payload)
    dispatch({ type: "roomDetails", payload: roomsLIst });
    setSelAdts([]);
    setSelChds([]);
  }else{
    dispatch({ type: "roomDetails", payload: roomInitRequest });
  }

}

const addRooms=()=>{
  convertRoomPax(true);
}

  const openStartDateCal = () => state._startDateCal.setOpen(true);
  const errorRemoveBlur = () => {
    dispatch({ type: "notiMessageShow", payload: false })
    document.querySelector('.origin').classList.remove('errorSerField');
  };

  const refOutClick = useOnclickOutside(() => {
    dispatch({ type: "hotelPredectiveDropDown", payload: false })
    document.querySelector('.datePick').classList.remove('activeSerField');

  });
  //=====================================
  // This function will get the Source data
  //=====================================
const handleChangOrigin = (e) => {
  const { id, value } = e.target;
  if(value.length > 0){
    dispatch({type: "cleardb", payload: true})
  }
  else{
    dispatch({type: "cleardb", payload: false})
  }

  if(value.length>2){
    let obj ={
      textValue:value
    }
    debouncedSave(obj);
  }else{
    dispatch({ type: 'hotelData', payload: [] })
  }
  dispatch({ type: id, payload: value });
}

const companySelect = () =>{
  if (context.logindata.utype === "att_user") {
    if (context.logindata.cid === 0) {
      document.querySelector('.domin').classList.add('dominFocus');
    }else{
      document.querySelector('.domin').classList.remove('dominFocus');
    }
  }
}

// highlight-starts
const debouncedSave = useCallback(
  debounce(obj => predectiveCall(obj.textValue), 500),
  [], // will be created only once initially
);

const clearDestination = ()=>{
  dispatch({type: "destName", payload: ""})
  dispatch({type: "hotelData", payload: []})
  dispatch({type: "destError", payload: true })
  dispatch({type: "cleardb", payload: false})
}

//=====================================
// This function will call the service to get the Hotel data
//=====================================
const predectiveCall = (input) => {
  dispatch({ type: "hotelPredectiveDropDown", payload: false })
  PredectiveSearch.retreieveSearch(input).then((data) => {
    dispatch({ type: "hotelData", payload: data.data.data!== "" ? [data.data.data] : [] })
    dispatch({ type: "showResults", payload: true })
    dispatch({ type: "hotelPredectiveDropDown", payload: true })

  });

}

//=====================================
// This function will get the Source data
//=====================================
const handleOnOrigin = (obj,type) =>() => {
  companySelect();
  if(obj!==undefined){
    dispatch({ type: "hotelPredectiveDropDown", payload: false })
    obj.type=type;
    if(type!=="city"){
      dispatch({ type: "destName", payload: obj.description+", "+obj.country})
    }else{
      if(obj.description!==null){
        dispatch({ type: "destName", payload: obj.description})
      }else{
        dispatch({ type: "destName", payload: obj.text+", "+obj.country})
      }
    }
    if(obj._id!==undefined && obj._id!==""){
    dispatch({ type: "dest_obj", payload: obj})
    PredectiveSearch.retreieveSearch(obj.text).then(response => {
      if(response.data.status == 'success') {
          let hotels = []
          let data = response.data.data.property
          if(data.length > 0) {
          for(let val in response.data.data.property) {
              let hotel_data = data[val]
              let obj = {
                  "value":hotel_data.pid?hotel_data.pid:"",
                  "label":hotel_data.description + ", "+ hotel_data.country,
                  "phn":hotel_data.phone?hotel_data.phone:"",
                  "rating":hotel_data.ratings?hotel_data.ratings:"",
                  "cat":hotel_data.category?hotel_data.category.name:"",
                  "add":hotel_data.address ?(hotel_data.address.line1+","+hotel_data.address.city+","+hotel_data.address.countryCode):"",
              }
              hotels.push(obj)
          }
          setHotelData(hotels)
          dispatch({ type: 'destError', payload: false });
      }
      }
  })
  }
    dispatch({ type: "selHotel", payload: [] })
    //openStartDateCal();
    errorRemoveBlur();
  }
}

// OnChange event we will call this and update the state
const handleCheckout = date => {
  setEndDate(date);
  dispatch({ type: "checkoutError", payload: false})
  if(startDate!==undefined && date!==undefined && date !== null && startDate!==""){
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
if(utc2 < utc1){
  return 0
}
else{
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
}
const a = new Date(startDate),
    b = new Date(date),
    difference = dateDiffInDays(a, b);
    var diff_In_Time = date - startDate;
    var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
    dispatch({ type: "nights", payload: difference})
  }
}

//=====================================
  // This function will update the Airline
  //=====================================
  const handleHtlChange = (obj) => {
    dispatch({ type: "selHotel", payload: obj })
    if(obj.length == 0){
      dispatch({ type: "htnameError", payload: true })
    }
    else{
      dispatch({ type: "htnameError", payload: false })
    }
    if(obj[0]!==undefined){
    dispatch({ type: "phno", payload:obj[0].phn!==undefined?obj[0].phn.replaceAll("-", ""):"" })
    dispatch({ type: "add", payload: obj[0].add!==undefined?obj[0].add:"" })
    if(obj[0].rating!=="" && obj[0].rating!==undefined){
      setSelRating({value:obj[0].rating,label:obj[0].rating})
    }
    if(obj[0].cat!=="" && obj[0].cat!==undefined){
      dispatch({ type: "cat", payload:obj[0].cat})
      setSelAccom({value:obj[0].cat,label:obj[0].cat})
    }
  }
  }

  const handleChangeManual = (evt) => {
    const { id, value } = evt.target;
    dispatch({ type: id, payload: value });
}

  const setAccomdation=(obj,idx)=>{
    setSelAccom(obj)
    dispatch({ type: "cat", payload: obj.value})
  }

  const setRtatings=(obj,idx)=>{
    setSelRating(obj)
    dispatch({ type: "rating", payload: obj.value})
  }

  const setAdults = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        room.selAdtsError = false
        return {
            ...room, adt:e.value,selAdts:[{label:e.value,value:e.value}]
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }

  const setPhoneCode=(obj,idx)=>{
    setSelPhc(obj)
    for(let room of state.roomDetails){
      if(room.rmid===idx+1){
          room.pax.phc=obj.value;
          room.paxphcError = false
      }
     }
  }


  const setTitle = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        room.tlError =false;
        return {
            ...room, pax: {
            ...room.pax, tl: e.value,selTl:[{label:e.value,value:e.value}]
            }
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
    
  }

  const updateSup=(obj)=>{
    setSelSup(obj);
    dispatch({ type: "sup", payload: obj.value})
    dispatch({ type: "sup_name", payload: obj.label})
    dispatch({ type: "supError", payload: false})
  }

  const setChilds = (idx) => (e) => {
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
      let chdList=[]
        for (var i = 0; i < Number(e.value); i++) {
          const req={
            "room":idx+1,
            "chd_count":i,
            "sel_age":[]
          }
          chdList.push(req);
        }
        return {
            ...room, chd:e.value,selChds:e.value==""?[]:[{label:e.value,value:e.value}],childAgeList:chdList
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
  }

  
  const clearRoom=(room)=>{
    room.adt=1;
    room.chd=0;
    room.chdage="";
    room.rnm="";
    room.sup_ref="";
    room.htl_conf="";
    room.spr="";
    room.pax.fn="";
    room.pax.ln="";
    room.pax.em="";
    room.pax.tl="";
    room.canpolicy="";
    room.imp_info="";
    room.pr.attCommision = "";
    room.ra="";
    room.pr.attBaseFare="";
    room.pr.attTaxes="";
    room.pr.attMarkup=0;
    room.pr.attDisc="";
    room.pr.attAgentDisc=0;
    room.pr.attTotal="";
    room.pr.taMarkup="";
    room.pr.taServiceFee="";
    room.pr.taSupFee="";
    room.pr.taDisc="";
    room.pr.taTotal="";
  }

  const updateRoomData = (idx) => (e) => {
      for(let room of state.roomDetails){
        if(Number(room.rmid)===Number(idx)){
        if(room.isSameRoom){  
          room.isSameRoom=false;
          //clearRoom(room);
          // let newRoomList = state.roomDetails.map((room, sidx) => {
          //   if (idx !== sidx) return room;
          //   else{
          //     return {
          //         ...room, pr: {attBaseFare: "", attTaxes: ""}
          //     }
          //   }
          // });
          // dispatch({ type: "roomDetails", payload: newRoomList})
          setSelTl()
          break;
        }else{
          let oldrm=idx-1;
          let oldRoomData=state.roomDetails.filter((obj)=>Number(obj.rmid)===Number(oldrm));
          room.isSameRoom=true;
          room.rnm=oldRoomData[0].rnm;
          room.attBaseFareError = oldRoomData[0].attBaseFareError
          room.attTaxesError = oldRoomData[0].attTaxesError
          room.canpolicyError = oldRoomData[0].canpolicyError
          room.fnError = oldRoomData[0].fnError
          room.lnError = oldRoomData[0].lnError
          room.rmhtl_confError = oldRoomData[0].rmhtl_confError
          room.rmrnmError = oldRoomData[0].rmrnmError
          room.rmsup_refError = oldRoomData[0].rmsup_refError
          room.selAdtsError = oldRoomData[0].selAdtsError
          room.tlError = oldRoomData[0].tlError
          room.sup_ref=oldRoomData[0].sup_ref;
          room.htl_conf=oldRoomData[0].htl_conf;
          room.spr=oldRoomData[0].spr;
          room.pax=oldRoomData[0].pax;
          room.canpolicy=oldRoomData[0].canpolicy;
          room.imp_info=oldRoomData[0].imp_info;
          room.ra=oldRoomData[0].ra;
          room.pr=oldRoomData[0].pr;
          room.adt=oldRoomData[0].adt;
          room.chd=oldRoomData[0].chd;
          room.chdage=oldRoomData[0].chdage;
          room.selAdts=[{"label":oldRoomData[0].adt,"value":oldRoomData[0].adt}]
          room.selChds=[{"label":oldRoomData[0].chd,"value":oldRoomData[0].chd}]
          room.selTl=[{"label":oldRoomData[0].pax.tl,"value":oldRoomData[0].pax.tl}]
          setSelTl({label:oldRoomData[0].pax.tl,value:oldRoomData[0].pax.tl})
    }
    }
    }
    
  }

  const handleChdAge=(obj,idx,index)=>{
    for(let room of state.roomDetails){
      if(Number(room.rmid)===Number(idx+1)){
        setSelAge(obj)
        if(room.chdage===""){
          room.chdage=obj.value;
        }else{
          room.chdage=room.chdage+","+obj.value;
        }
        for(let age of room.childAgeList){
           if(age.chd_count===index){
              age.sel_age=[obj]
           }
        }
      }
     }
  }

  

  const handleChangePax = (idx) => (e) => {
    const { id, value } = e.target;
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else{
        if(id == "fn"){
          if(value !== ""){
          room.fnError = false
        }
        else{
          room.fnError = true
        }
      }
        if(id == "ln"){
          if(value !== ""){
          room.lnError = false
        }
        else{
          room.lnError = true
        }
      }
        if(id == "em"){
          if(value !== ""){
          room.emError = false
        }
        else{
          room.emError = true
        }
      }
        if(id == "phn"){
          if(value !== ""){
          room.paxphnError = false
        }
        else{
          room.paxphnError = true
        }
      }
      
        return {
            ...room, pax: {
            ...room.pax, [id]: value,
            }
        }
      }
    });
    dispatch({ type: 'roomDetails', payload: newRoomList })
    
  }
  


  // OnChange event we will call this and update the state
  const handleChange = (type, idx) => (e) => {
    try {
      idx=idx+1;
        const { id, value } = e.target;
        for(let room of state.roomDetails){
            if(room.rmid===idx){
              if(type==="rn"){
                room.rnm=value;
                if(value.length == 0){
                  room.rmrnmError = true
                }
                else{
                  room.rmrnmError = false
                }
                break;
              }else if(type==="sup"){
                room.sup_ref=value;
                if(value.length == 0){
                room.rmsup_refError = true
                }
                else{
                  room.rmsup_refError = false
                }
              }else if(type==="conf"){
                room.htl_conf=value;
                if(value.length == 0){
                  room.rmhtl_confError = true
                  }
                  else{
                    room.rmhtl_confError = false
                  }
              }else if(type==="spr"){
                room.spr=value;
              }else if(type==="policy"){
                room.canpolicy=value;
                if(value.length == 0){
                  room.canpolicyError = true
                  }
                  else{
                    room.canpolicyError = false
                  }
              }else if(type==="info"){
                room.imp_info=value;
              }else if(type==="rfundable"){
                room.rfundable=e.target.checked;
              }else if(type==="bb"){
                room.ra=value;
              }
              // }else if(type==="fn"){
              //   room.pax.fn=value;
              // }else if(type==="ln"){
              //   room.pax.ln=value;
              // }else if(type==="em"){
              //   room.pax.em=value;
              // }else if(type==="phn"){
              //   room.pax.phn=value;
              // }
            }
        }
        dispatch({ type: id, payload: value });
    } catch (err) {
      log.error("Exception occured in handleChange function---" + err);
    }
  };
  useEffect(()=>{
    if(context.logindata.cid!==0 && context.logindata.cid!==undefined && context.logindata.region_type==="India"){
      AttCommonSerivce.getFetchGSTDetails({cid:context.logindata.cid}).then(response => {
        if (response.data.suc && response.data.data.length !== 0){
          dispatch({type: "gst_type", payload: response.data.data[0].state_type})
          dispatch({type: "cgst", payload: response.data.data[0].cgst})
          dispatch({type: "sgst", payload: response.data.data[0].sgst})
          dispatch({type: "igst", payload: response.data.data[0].igst})
        }
      })
    }
    if (context.logindata.region_type==="India"){
      AttCommonSerivce.getTdsDetails({region_type:context.logindata.region_type,product:"Hotel"}).then(response => {
      if (response.data.suc && response.data.res.length !== 0){
        dispatch({type:"tds_per", payload: Number(response.data.res[0].commission)})
      }
    })}
   }, [context.logindata.cid])

  const handleChangePrice = (type,idx) => (e) => {
    const { id, value } = e.target;
    let newRoomList = state.roomDetails.map((room, sidx) => {
      if (idx !== sidx) return room;
      else if(type==="ATT"){
        if(id == "attBaseFare"){
        if(value.length == 0){
          room.attBaseFareError = true
        }
        else{
          room.attBaseFareError = false
        }
      }
      else if( id== "attTaxes"){
        if(value.length == 0){
          room.attTaxesError = true
        }
        else{
          room.attTaxesError = false
        }
      }
        return {
            ...room, pr: {
            ...room.pr, [id]: value,
            }
        }
      }else{
        return {
          ...room, pr: {
          ...room.pr, [id]: value,
          }
      }
      }
    });
    for(let room of newRoomList){
      if(Number(room.rmid)===Number(idx+1)){
        if(type==="ATT"){
          room.pr.taTaxes=room.pr.attTaxes;
          room.pr.attTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attDisc) - Number(room.pr.attAgentDisc);
          room.pr.taTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attAgentDisc);
           if(room.pr.attAgentDisc!="" || room.pr.attAgentDisc!=0){
            room.pr.tds_per = state.tds_per
            room.pr.tds_val = Number(room.pr.tds_per/100)*Number(room.pr.attAgentDisc)
            room.pr.attTotal = Number(room.pr.attTotal) + Number(room.pr.tds_val)
            room.pr.taTaxes = Number(room.pr.taTaxes) + Number(room.pr.tds_val)
            room.pr.taTotal = Number(room.pr.taTotal) + Number(room.pr.tds_val)
        }
        if (room.pr.attServiceFee!="" ||room.pr.attMarkup!="" ){
          let totalGst =  Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attDisc) 
          if(Number(totalGst)>0){
            if(state.gst_type==="same"){
              const attCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
              const attSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
              room.pr.att_cgst=Number(attCgst)
              room.pr.att_sgst=Number(attSgst)
              room.pr.att_gst=Number(attSgst)+ Number(attCgst)
              room.pr.attTotal= room.pr.attTotal+Number(attCgst)+Number(attSgst)
              room.pr.taTotal=room.pr.taTotal+Number(attCgst)+Number(attSgst)
            } else {
              const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
              room.pr.att_igst=Number(attIgst)
              room.pr.att_gst=Number(attIgst)
              room.pr.attTotal= room.pr.attTotal+Number(attIgst)
              room.pr.taTotal=room.pr.taTotal+Number(attIgst)
            }
          }
        }
        }else if(type==="TA"){
          room.pr.taTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.taMarkup) + Number(room.pr.attServiceFee) + Number(room.pr.attMarkup) + Number(room.pr.taServiceFee) - Number(room.pr.taDisc) - Number(room.pr.attAgentDisc);
          if (room.pr.taMarkup!="" ||room.pr.taServiceFee!="" ||room.pr.taDisc !="" ){
          let totalGst = Number(room.pr.taMarkup) + Number(room.pr.taServiceFee) - Number(room.pr.taDisc)
          if(Number(totalGst)>0){
            if(state.gst_type==="same"){
              const taCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
              const taSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
              room.pr.ta_cgst=Number(taCgst)
              room.pr.ta_sgst=Number(taSgst)
              room.pr.ta_gst=Number(taSgst)+ Number(taCgst)
              room.pr.taTotal= room.pr.taTotal+Number(taCgst)+Number(taSgst)
            } else {
              const taIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
              room.pr.ta_igst=Number(taIgst)
              room.pr.ta_gst=Number(taIgst)
              room.pr.taTotal= room.pr.taTotal+Number(taIgst)
            }
          }
         }
        }
        room.pr.gst_total=Number(room.pr.att_gst) + Number(room.pr.ta_gst)
        }
    }
    dispatch({ type: 'roomDetails', payload: newRoomList })
    
  }
  
  // const handleChangePrice = (type,idx,val) => (e) => {
  //   const { id, value } = e.target;
  //   dispatch({ type: id, payload: value });
  //      idx=idx+1;
  //     for(let room of state.roomDetails){
  //       if(Number(room.rmid)===Number(idx)){
  //         if(type==="ATT"){
  //           if(val==="base"){
  //             room.pr.attBaseFare=value;
  //           }else if(val==="tax"){
  //             room.pr.attTaxes=value;
  //             room.pr.taTaxes=value;
  //           // }else if(id==="attMarkup"){
  //           //   room.pr.attMarkup=value;
  //           // }else if(id==="attServiceFee"){
  //           //   room.pr.attServiceFee=value;
  //           // }else if(id==="attDisc"){
  //           //   room.pr.attDisc=value;
  //           // }else if(id==="attAgentDisc"){
  //           //   room.pr.attAgentDisc=value;
  //           }
  //           room.pr.attTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attDisc) - Number(room.pr.attAgentDisc);
  //           room.pr.taTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.attMarkup) + Number(room.pr.attServiceFee) - Number(room.pr.attAgentDisc);
  //           break;
  //         }else{
  //           // if(id==="taMarkup"){
  //           //   room.pr.taMarkup=value;
  //           // }else if(id==="taServiceFee"){
  //           //   room.pr.taServiceFee=value;
  //           // }else if(id==="taDisc"){
  //           //   room.pr.taDisc=value;
  //           // }
  //           room.pr.taTotal=Number(room.pr.attBaseFare) + Number(room.pr.attTaxes) + Number(room.pr.taMarkup) + Number(room.pr.attServiceFee) + Number(room.pr.attMarkup) + Number(room.pr.taServiceFee) - Number(room.pr.taDisc) - Number(room.pr.attAgentDisc);
  //         }
  //       }
  //     }
      
  // }

  //Calculating the Total Purchase Amount
  const totalPrice = (data,type) => {
    let total=0;
    if(data.length!==0){
    if(type==="ATT"){
        for(let val of data){
          if(val.pr.attTotal!=="" && val.pr.attTotal!==undefined){
            if(total===0){
              total=Utilities.addPrice(Number(val.pr.attTotal),0, "Add");
            }else{
              total=Utilities.addPrice(total, Number(val.pr.attTotal), "Add");
            }
          }
        }
    }else{
       let tprice=0;
        for(let val of data){
          if(val.pr.taTotal!=="" && val.pr.taTotal!==undefined){
            if(total===0){
              total=Utilities.addPrice(Number(val.pr.taTotal),0, "Add");
              tprice=total;
            }else{
              total=Utilities.addPrice(total, Number(val.pr.taTotal), "Add");
              tprice=total;
            }
          }
        }

    }
  }
   return total;
}

const removeRoom = (index) => () => {
  dispatch({ type: "roomDetails", payload: state.roomDetails.filter((s, sidx) => index !== sidx) });
};

const reset=()=>{
  window.location="/ezytrip/dashboard/hotelmanual";
}

const handleConf = ()=>{
  dispatch({ type: "conf_mail", payload: !state.conf_mail })
}
const handleInv = ()=>{
  dispatch({ type: "inv_mail", payload: !state.inv_mail })
}

const hidemessage =() =>{
  setTimeout(function () {
  dispatch({ type: 'notiMessageShow', payload: false })
  dispatch({ type: 'notiMessage', payload: '' })
  dispatch({ type: 'notiVarient', payload: '' })
}, 5000);
}

const checkErrors=() =>{
  if(state.sup!==""){
    dispatch({ type: 'supError', payload: false })
  }
  else{
    dispatch({ type: 'supError', payload: true })
  }
  
  if(state.hotelData.length===0){
      dispatch({ type: 'destError', payload: true })
    }
    else{
      dispatch({ type: 'destError', payload: false })
    }
  if(state.selHotel.length===0){
    dispatch({ type: 'htnameError', payload: true })
  }
  else{
    dispatch({ type: 'htnameError', payload: false })
  }
  if(startDate===null || startDate===""){
    dispatch({ type: 'checkinError', payload: true })
  }
  else{
    dispatch({ type: 'checkinError', payload: false })
  }
  if(endDate===null || endDate===""){
    dispatch({ type: 'checkoutError', payload: true })
  }
  else{
    dispatch({ type: 'checkoutError', payload: false })
  }
  if(state.phno!=="" && !mobileNumberValidation(state.phno)){
    dispatch({ type: 'htphnError', payload: true })
  }
  else{
    dispatch({ type: 'htphnError', payload: false })
  }
//Rooms Validation
for(let room of state.roomDetails){
  if(room.rnm===""){
    room.rmrnmError = true
  }if(state.sup!=="2" && room.sup_ref===""){
    room.rmsup_refError = true
  }if(room.htl_conf===""){
    room.rmhtl_confError = true
  }if(room.pax.tl===""){
    room.tlError = true
  }if(room.pax.fn===""){
    room.fnError = true
  }if(room.pax.ln===""){
    room.lnError = true
  }if(room.pax.em===""){
    room.emError = true
  }if(room.pax.em!=="" && !emailValidation(room.pax.em)){
    room.emvalidError = true
  }if( room.pax.phn===""){
    room.paxphnError = true
  }if(room.pax.phn!=="" && !mobileNumberValidation(room.pax.phn)){
    room.paxphnvalidError = true
  } if(room.pax.phc===""){
    room.paxphcError = true
  }if(room.selAdts.length===0){
    room.selAdtsError = true
  }if(room.canpolicy===""){
    room.canpolicyError = true
  }if(room.pr.attBaseFare==="" || room.pr.attBaseFare==="0"){
    room.attBaseFareError = true
  }if(room.pr.attTaxes==="" || room.pr.attTaxes==="0"){
    room.attTaxesError = true
  }
}
}

const validatePrice=() => {
  
  if(state.sup===""){
      return false;
  }
else if(state.hotelData.length===0){

      return false;
  }else if(state.selHotel.length===0){
      return false;
  }else if(startDate===null || startDate===""){
    return false;
   }else if(endDate===null || endDate===""){
    return false;
}else if(state.phno!=="" && !mobileNumberValidation(state.phno)){
  return false;
}
//Rooms Validation
for(let room of state.roomDetails){
  if(room.rnm===""){
    return false;
  }else if(state.sup!=="2" && room.sup_ref===""){
    return false;
  }else if(room.htl_conf===""){
    return false;
  }else if(room.pax.tl===""){
    return false;
  }else if(room.pax.fn===""){
    return false;
  }else if(room.pax.ln===""){
    return false;
  }else if(room.rmid===1 && room.pax.em===""){
    return false;
  }else if(room.rmid===1 && room.pax.em!=="" && !emailValidation(room.pax.em)){
    return false;
  }else if(room.rmid===1 && room.pax.phn===""){
    return false;
  }else if(room.rmid===1 && room.pax.phn!=="" && !mobileNumberValidation(room.pax.phn)){
    return false;
  }else if(room.rmid===1 && room.pax.phc===""){
    return false;
  }else if(room.selAdts.length===0){
    return false;
  }else if(room.canpolicy===""){
    return false;
  }else if(room.pr.attBaseFare==="" || room.pr.attBaseFare==="0"){
    return false;
  }else if(room.pr.attTaxes==="" || room.pr.attTaxes==="0"){
    return false;
  }
}
  return true;
}

const continueBooking = ()=>{
  checkErrors()
  if(validatePrice()){
    if(DateUtils.convertToDateHifen(startDate)!==DateUtils.convertToDateHifen(endDate)){     
    if(context.logindata!==undefined && context.logindata.cid!==0){
      //  dispatch({ type: 'enablePay', payload: true })
    let total=0;
  for(let val of state.roomDetails){
      if(total===0){
        total=Utilities.addPrice(Number(val.pr.taTotal),0, "Add");
      }else{
        total=Utilities.addPrice(total, Number(val.pr.taTotal), "Add");
      }
  }
       let pcompId=0;
      if (Number(context.logindata.cid) === Number(context.logindata.pcid)) {
          pcompId = 0;
      }else{
          pcompId=context.logindata.pcid;
      }
    const req = {
        "comid": Number(context.logindata.cid),
        "pid": pcompId,
        "cuscode": context.logindata.cuscode
        }
       getCrediLimit(req).then((response) => {
        if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
          dispatch({ type: 'creditBal', payload: response.res.data.cbal });
          dispatch({ type: 'enablePay', payload: true })
          let clbal=Number(response.res.data.cbal);
          let val = Math.sign(clbal);
          let paytype="credit";
          if (Number(val) === 1) {
            if (context.logindata.butype === "sta_user") {
              var pclbal = 0;
              if (response.res.data.pcbal !== undefined) {
                 pclbal = response.res.data.pcbal;
              }
              if (clbal !== 0) {
                if (paytype === "credit" && Number(clbal) >= Number(total) && pclbal >= Number(total)) {
                    createManualPayload(true, "credit", clbal,false,total);
                } else if (paytype === "credit" && Number(total) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                    createManualPayload(false, "credit", clbal,true,total);
                }
            }
            } else {
              if (clbal !== 0) {
                if (paytype === "credit" && clbal >= Number(total)) {
                    createManualPayload(true, "credit", clbal,false,total);
                } else if (paytype === "credit" && Number(total) > 0 && (clbal === 0 || clbal < 0)) {
                    createManualPayload(false, "credit", clbal,true,total);
                }
              }
            }
        }else{
            createManualPayload(false, "credit", clbal,true,total);
        }
       }
      });
    }
    else{
      dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Company'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
    }
  }
  else{
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Check-In and Check-Out dates cannot be same'})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
  }
    }
    else{
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter all the mandatory fields'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
    }
}

const createManualPayload=(clAvail,payType,clBal,overdieCL,totalFare)=>{
   const payload={
    enableCl:clAvail,
    type:payType,
    creditBal:clBal,
    enableOvrwriteCL:overdieCL,
    totalPrice:totalFare,
    cname: context.logindata.cnm
   }
   dispatch({ type: 'payData', payload: payload });
   dispatch({ type: 'disable', payload: true });
}

const createRoomList=()=>{
  
  let finalRooms=[];
  for(let rm of state.roomDetails){
    let gst ={
      "gst": {
         "type": state.gst_type,
         "sgst": state.sgst,
         "igst": state.igst,
         "cgst": state.cgst,
         "gst_invoice": [
         {
         "cgst_val": rm.pr.att_cgst,
         "igst_val": rm.pr.att_igst,
         "sgst_val": rm.pr.att_sgst,
         "type": "ATT"
         },
         {
         "cgst_val": rm.pr.ta_cgst,
         "igst_val": rm.pr.ta_igst,
         "sgst_val": rm.pr.ta_sgst,
         "type": "TA"
         }
         ]}}
  const req={
  "count": rm.rmindex,
  "rmindex": rm.rmindex,
  "rmimg": "",
  "imgecnt": "",
  "adt": rm.adt,
  "chd": rm.chd,
  "chdage": "1 ",
  "rmid": rm.rmindex,
  "rnm": rm.rnm,
  "hs": 0,
  "ocp": rm.adt+"-"+rm.chdage,
  "note": rm.imp_info,
  "rmpr": [
    {
      "tid": "",
      "pid": state.selHotel[0].value,
      "rtid": "",
      "refundable": rm.rfundable,
      "freeCancelDate":null,
      "rsize": "",
      "ra": [{
        id:0,
        name:rm.ra
      }],
      "bed": "",
      "canpol": [rm.canpolicy],
      "available_rooms": "",
      "servicefee": [
        {
          "type": "MTA",
          "servicefee_name": "ATT Manual Service Fee",
          "apl_on": "Per Booking",
          "sf": Number(rm.pr.attServiceFee),
          "sup_fee": 0
        },
        {
          "type": "TA",
          "servicefee_name": "TA Manual Service Fee",
          "apl_on": "Per Booking",
          "sf": Number(rm.pr.taServiceFee),
          "sup_fee": Number(rm.pr.attServiceFee)
        }
      ],
      "pr": [
        {
          "tf": Number(rm.pr.attTotal),
          "bf": Number(rm.pr.attBaseFare),
          "tax": [
            {
              "OT": Number(rm.pr.attTaxes),
              "SUP_OT":Number(rm.pr.attTaxes)
            },
            {
              "attmkp": Number(rm.pr.attMarkup)
            },
            {
              "tamkp": Number(rm.pr.taMarkup)
            },
            {
              "stamkp": 0
            },
            {
              "attdisc": Number(rm.pr.attDisc)
            },
            {
              "tadisc": Number(rm.pr.taDisc)
            },
            {
              "stadisc": 0
            }
          ],
          "per_night": Number(rm.pr.attTotal),
          "ty": state.cat,
          "disc": Number(rm.pr.attDisc),
          "dwtf": Number(rm.pr.attTotal) + Number(rm.pr.attDisc),
          "cur": context.logindata.cur,
          "sup_fare": rm.pr.attBaseFare+"_"+rm.pr.attTotal,
          "package": false,
          "ezy_deal": false,
          "market_fee": Number(rm.pr.attCommision),
          "sup_market_fee": rm.pr.attCommision,
          "sup_disc": 0,
          "sup_dwtf": 0,
          "sup_usr_exc": 1,
          "sup_usr_com_exc": 1,
          "rtf": Number(rm.pr.attTotal),
          "rdwtf": Number(rm.pr.attTotal) + Number(rm.pr.attDisc),
          "com_disc": Number(rm.pr.attAgentDisc),
          "markupnames": createMarkupname(),
          "gst":gst.gst,
          "gst_total":rm.pr.gst_total,
          "tds_val":Number(rm.pr.tds_val),
          "tds_per":Number(rm.pr.tds_per)
        }
      ]
    }
  ],
  "selected1": true,
  "stat": "Confirmed",
  "confirmnum": rm.sup_ref,
  "htlrefnum": rm.htl_conf
  }
  finalRooms.push(req);
}
return finalRooms;
}

const createMarkupname=()=>{
  let markNames=[];
  if(state.attMarkup!==0){
    const att={
     "code":"MTA",
     "name" : "MTA Manual Markup"
    }
    markNames.push(att);
  }
  if(state.tamkp!==0){
    const ta={
     "code":"TA",
     "name" : "TA Manual Markup"
    }
    markNames.push(ta);
  }
  return markNames;
}



const confirmManualBooking = (data)=>{
  dispatch({ type: 'isItineraryLoading', payload: true })
      let dt=moment(startDate).format('YYYY-MM-DD');
      let time=moment(startDate).format('HH:mm:ss');
      let adt=moment(endDate).format('YYYY-MM-DD');
      let atime=moment(endDate).format('HH:mm:ss');
      const htldtls={
        cin: dt+" "+time,
        cout: adt+" "+atime,
        htnm: state.selHotel[0].label,
        htimg: state.hotel_img,
        pid: state.selHotel[0].value,
        add: state.add,
        city: state.dest_obj.text,
        country: state.dest_obj.country+","+state.dest_obj.countryCode,
        cityId:state.dest_obj.externalId,//_id
        landmark: state.landmark,
        rating: state.rating,
        category:{
          "id": "1",
          "name":state.cat,
        },
        phone_no: state.phno
      }
     const payload={
             "pbreq":{
                "echo": "7ekspsb1zj9",
                "cha": "B2B",
                "cur": context.logindata.cur,
                "cid": context.logindata.cid,
                "cname": context.logindata.cnm,
                "sty": "normal",
                "ctid": state.dest_obj.externalId,
                "utype" : context.logindata.utype,
                "bid": context.logindata.bid,
                "butype": context.logindata.butype,
                "pcid": context.logindata.pcid,
                "ofcId": context.logindata.office_id,
                "tkt_user_type": context.logindata.utype,
                "tkt_user_id": context.logindata.uid,
                "client_ip": localStorage.getItem(CLIENT_IP),
                "sup": state.sup,
                "sup_name": state.sup_name,
                "bkby": context.logindata.nm,
                "cuscode": context.logindata.cuscode,
                "bktype" :"MANUAL",
                "crby": context.logindata.uid,
                "user_id" :context.logindata.uid,
                "nonights":state.nights,
                "roompax": state.roomDetails,
                "rooms":createRoomList(),
                "htldtls":htldtls,
                "conf": state.conf_mail,
                "inv": state.inv_mail,
                "pay" : [data],
                "custms": state.custms,
                "region_type":context.logindata.region_type
             }

    }
    saveHotelBooking(payload).then((response) => {
      if (response.suc !== undefined && response.res !== null && response.res.pbres!==undefined) {
        if (response.res.pbres.status !== undefined && response.res.pbres.status === "inserted") {
          dispatch({ type: 'isItineraryLoading', payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Booking created successfully with '+response.res.pbres.bookingId})
          dispatch({ type: 'notiVarient', payload: 'success' })
          setTimeout(function () {
            window.location="/ezytrip/dashboard/hotelmanual";
           }, 3000);
        }else{
          dispatch({ type: 'isItineraryLoading', payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Persistance Failed..Please try again later'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        }
      } else {
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Persistance Failed..Please try again later'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
    });
  }


const sendConfirmationEmail=(response,resp,sup,tid,jtpe)=>{
  const requestMail={
    "booking_id":response.res.data.pbres.bookingId,
    "tid":tid,
    "email_id":resp.res,
    "sup":sup,
    "jtype":jtpe,
    "ftype":"normal",
    "is_eticket":state.conf_mail,
    "is_invoice":state.inv_mail
}
sendManualEmail(requestMail).then((res) => {
     if(res.data.suc){
      dispatch({ type: 'isItineraryLoading', payload: false })
      dispatch({ type: 'isItineraryLoading', payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: " Booking created and Mail sent Successfully with "+response.res.data.pbres.bookingId})
      dispatch({ type: 'notiVarient', payload: 'success' })
     }
    setTimeout(function () {
       window.location="/ezytrip/dashboard/manualbooking";
      }, 2000);
});
}


const hideCustoms = () => {
  dispatch({ type: 'showCustoms', payload: false })
}

const customData = useCallback((data) => {
  let customList = [];
  let remarkList = [];
  if (data) {
    for (let val of data) {
      const payload = {
        "clbl": val.flbl,
        "cval": val.fval,
        "cmand": val.fmand
      }
      customList.push(payload);
      if (val.rm_type!==null && val.rm_type!==undefined) {
        const remarkpayload = {
          "key": val.rm_type,
          "value": val.fval,
        }
        remarkList.push(remarkpayload);
      }
    }
  }
  dispatch({ type: 'custms', payload: customList })
  dispatch({ type: 'remarks', payload: remarkList })
}, [state.custms]);

const updateFileName=(url)=>{
  dispatch({ type: "hotel_img", payload: url });
}

const handleCheckin = (date)=>{
  setStartDate(date)
  dispatch({ type: "checkinError", payload: false });
  if(endDate!==undefined && date!==undefined && date !== null && endDate!==""){
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
const a = new Date(date),
    b = new Date(endDate)
    let difference = dateDiffInDays(a, b);
    dispatch({ type: "nights", payload: difference})
  }
}


    return (
        <>
            <Header/>
            <div className="container dashBordLayout manualBooking pt-3">
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
              ) : ""}
            <div className="cardMainlayout minHight0 pb-0">
                    <h5 className="layoutTitle  d-flex justify-content-between"><span>Hotel Booking</span></h5>
                    <form>
                        <Form.Row>
                            <Form.Group className="selectplugin" as={Col} xs={3} >
                               <Select
                                    options={supplierlist}
                                    searchable={false}
                                    value={selSup}
                                    isDisabled={state.disable}
                                    onChange={(value) => updateSup(value)}
                                />
                                <Form.Label>Supplier <sup>*</sup></Form.Label>
                                {state.supError && (
                      <p className="vError">Please select Supplier</p>
                    )}
                            </Form.Group>
                        </Form.Row>
                    </form>
                    </div>
                    <div className="cardMainlayout pb-0">
                    <h5 className="layoutTitle  d-flex justify-content-between"><span>Accommodation Details</span></h5>
                    <>

              <div className="destination hotelMannual">
              <Form.Row>
              <Form.Group as={Col} xs={4} controlId="destName">
              <div className={"addInput origin" + (originIsFocus ? " active" : "")}>

              <Form.Label><span className="ezyIcon icon-map"></span>Destination<sup>*</sup></Form.Label>
              <Form.Control type="text" disabled={state.disable} autoComplete="off" title={(state.destName)} value={state.destName}
              placeholder="City or Hotel Name"  onChange={handleChangOrigin} ref = {destination} />
              {state.cleardb  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearDestination}
                variant="link">
              </Button>
            }
               </div>
               {state.destError && (
                      <p className="vError">Please select Destination</p>
                    )}
              {state.hotelPredectiveDropDown &&

             <div className={state.hotelData!==undefined && state.hotelData.length!==0 && state.hotelData[0].city.length!==0 ? "pdtSerchPart cityNam":"pdtSerchPart nocity"}>
              <Scrollbars style={{ height: 300 }}>
                <div className="searchContent">
             {state.hotelData!==undefined && state.showResults && state.hotelData.length!==0 &&

                <>
                {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                {state.hotelData!==undefined && state.hotelData[0].city.length===0 && state.hotelData[0].landmark.length ===0 && state.hotelData[0].area.length ===0 && state.hotelData[0].property.length ===0 && <p className="nomachFound">
                  No matches found.
                  </p>}
                  <Row>
                {state.hotelData[0].city!==undefined && state.hotelData[0].city.length!==0 &&
                  <Col className="pr-2">
                  {state.hotelData[0].city.map((cty, index) => (
                    <>
                    {index===0 &&
                    <>
                      <h4 className="typ">City</h4>
                      </>
                    }
                    <div className="plc ezyIcon icon-map" onClick={handleOnOrigin(cty,"city")}>
                    {cty.description!==null &&
                      <span  className="plcNm" title={cty.description}> {cty.description}</span>
                    }
                    {cty.description==null &&
                      <span  className="plcNm"title={cty.text + cty.country} > {cty.text}, {cty.country}</span>
                    }
                    </div>
                    </>
                  ))}

                  </Col>
                 }
                </Row>
                </>
             }
             {state.hotelData.length===0 &&
             <h4 className="typ">No Results Found</h4>
             }
             </div>
          </Scrollbars>
             </div>

              }
          </Form.Group>
                </Form.Row>

              </div>
           </>

                          <Form.Row>
                          <Form.Group
                                as={Col}
                                controlId="fn"
                                className="selectplugin"
                                xs={3}
                              >
                                <Typeahead
                                id="basic-typeahead-single"
                                labelKey={htl => `${htl.label}`}
                                allowNew
                                readOnly
                                disabled={state.disable}
                                onChange={htl => handleHtlChange(htl)}
                                options={hotelData}
                                placeholderText="Enter Hotel Name"
                                selected={state.selHotel}
                              />
                                <Form.Label>
                                  Hotel name<sup>*</sup>
                                </Form.Label>
                                {state.htnameError && (
                      <p className="vError">Please select Hotel Name</p>
                    )}
                              </Form.Group>
                              <Form.Group className="selectplugin" as={Col} xs={3} >
                              <Select
                                  options={accomTypes}
                                  searchable={false}
                                  value={selAccom}
                                  isDisabled={state.disable}
                                  onChange={(value) => setAccomdation(value)}
                                />
                                <Form.Label>Accomdation Type</Form.Label>
                            </Form.Group>
                            <Form.Group className="selectplugin" as={Col} xs={3} >
                              <Select
                                  options={ratings}
                                  searchable={false}
                                  isDisabled={state.disable}
                                  value={selRating}
                                  onChange={(value) => setRtatings(value)}
                                />
                                <Form.Label>Star Rating</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} className="selectplugin datePicker arrivalTime" controlId="from">
                              <DatePicker
                              // selected={arrival_date}
                                      onChange={date=> handleCheckin(date)}
                                      selected={startDate}
                                      showTimeSelect
                                      placeholderText="DD-MM-YYYY"
                                      timeFormat="p"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="Pp"
                                      disabled = {state.disable}
                                      popperModifiers={{
                                          flip: {
                                              behavior: ['bottom'] // don't allow it to flip to be above
                                          },
                                          preventOverflow: {
                                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                          },
                                          hide: {
                                              enabled: false // turn off since needs preventOverflow to be enabled
                                          }
                                        }}
                                  fromDate={startDate}
                                  toDate={toDate}
                                  minDate={new Date(moment().startOf('month'))}
                                  maxDate={endDate !== "" && endDate }
                          />

                          <Form.Label>
                              Check In<sup>*</sup>
                            </Form.Label>
                            {state.checkinError && (
                      <p className="vError">Please select Checkin Date</p>
                    )}
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                          <Form.Group as={Col} xs={3} className="selectplugin datePicker arrivalTime" controlId="from">
                          <DatePicker
                          // selected={arrival_date}
                                  onChange={handleCheckout}
                                  selected={endDate}
                                  showTimeSelect
                                  placeholderText="DD-MM-YYYY"
                                  timeFormat="p"
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  disabled = {state.disable}
                                  dateFormat="Pp"
                                  popperModifiers={{
                                      flip: {
                                          behavior: ['bottom'] // don't allow it to flip to be above
                                      },
                                      preventOverflow: {
                                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                      },
                                      hide: {
                                          enabled: false // turn off since needs preventOverflow to be enabled
                                      }
                                    }}
                              fromDate={startDate}
                              minDate={startDate == "" ? new Date(moment().startOf('month')) : startDate}
                      />
                          <Form.Label>
                            Check Out<sup>*</sup>
                          </Form.Label>
                          {state.checkoutError && (
                      <p className="vError">Please select Checkout Date</p>
                    )}
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                                </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} xs={3} controlId="nights">
                          <Form.Control type="text" value={state.nights} disabled={true} placeholder="Number of Nights" autoComplete="off" />
                          <Form.Label>Number of Nights</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="landmark">
                          <Form.Control type="text" value={state.landmark} disabled={state.disable} onChange={handleChangeManual} placeholder="Land Mark" autoComplete="off" />
                          <Form.Label>Land Mark</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="phno">
                          <Form.Control type="text" value={state.phno} disabled={state.disable} onChange={handleChangeManual} placeholder="Phone Number" autoComplete="off" />
                          <Form.Label>Phone Number<sup>*</sup></Form.Label>
                          {state.htphnError && (
                      <p className="vError">Please select Valid Phone Number</p>
                    )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="add">
                          <Form.Control type="textarea" value={state.add} disabled={state.disable} onChange={handleChangeManual} placeholder="Hotel Address" autoComplete="off" />
                          <Form.Label>Hotel Address</Form.Label>
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>

<Form.Group as={Col} xs={12}>
    <FileUpload reportUrls={state.logo_url} type={"Hotel"} sendFileUrl={updateFileName} disabled = {state.disable}/>
    </Form.Group>
</Form.Row>

                </div>
                {/* ROOM DETAILS */}
                {state.roomDetails.map((room, idx) => (
                  <React.Fragment key={idx}>
                <div className="cardMainlayout pb-0">
                    <h5 className="layoutTitle  d-flex justify-content-between"><span>Room{idx+1} Details</span></h5>
                        <Form.Row>
                            <Form.Group as={Col} xs={3} controlId="rnm">
                                <Form.Control type="text" disabled={state.disable} value={room.rnm}
                              onChange={handleChange("rn", idx)}  placeholder="Enter Room Type"
                              autoComplete="off" />
                                <Form.Label>Enter Room Name <sup>*</sup></Form.Label>
                                
                                {room.rmrnmError == true && (
                      <p className="vError">Please select room name</p>
                    )}
                            </Form.Group>
                            {state.sup!=="2" &&
                            <Form.Group className="selectplugin" as={Col} xs={3} >
                            <Form.Control type="text" disabled={state.disable} value={room.sup_ref} onChange={handleChange("sup", idx)} placeholder="Supplier Ref No"
                              autoComplete="off" />
                                <Form.Label>Supplier Ref No <sup>*</sup></Form.Label>
                                                                {room.rmsup_refError == true && (
                      <p className="vError">Please enter Supplier Ref No</p>
                    )}
                            </Form.Group>
                            }
                            {idx>=1 &&
                            <Form.Group className="selectplugin" as={Col} xs={3} >
                            <Form.Check type="checkbox" id={"rm"+idx+1}
                            label={"Same as Room"+idx}
                            disabled={state.disable}
                            value={room.isSameRoom} checked={room.isSameRoom} onChange={updateRoomData(idx+1)} custom />
                            </Form.Group>
                            }
                        </Form.Row>
                        <div className="sepRationbrd"></div>
                        <Form.Row>
                        <Form.Group className="selectplugin" as={Col} xs={3} >
                              <Select
                                  options={title}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  value={room.pax.selTl}
                                  onChange={setTitle(idx)}
                                />
                                <Form.Label>Title <sup>*</sup></Form.Label>
                                {room.tlError == true && (
                      <p className="vError">Please select Title</p>
                    )}
                            </Form.Group>
                              <Form.Group as={Col} xs={3} controlId="fn">
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.fn} onChange={handleChangePax(idx)}
                                  placeholder="First Name" autoComplete="off" />
                                  <Form.Label>First Name<sup>*</sup></Form.Label>
                                  {room.fnError == true && (
                      <p className="vError">Please enter First Name</p>
                    )}
                              </Form.Group>
                              <Form.Group as={Col} xs={3} controlId="ln">
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.ln} onChange={handleChangePax(idx)}  placeholder="Last Name" autoComplete="off" />
                                  <Form.Label>Last Name<sup>*</sup></Form.Label>
                                  {room.lnError == true && (
                      <p className="vError">Please enter Last Name</p>
                    )}
                              </Form.Group>
                              {idx==0 &&
                                <Form.Group as={Col} xs={3} controlId="em">
                                  <Form.Control type="text" disabled={state.disable} value={room.pax.em} onChange={handleChangePax(idx)} placeholder="Email" autoComplete="off" />
                                  <Form.Label>Email <sup>*</sup></Form.Label>
                                  {room.emError == true && (
                      <p className="vError">Please enter Email</p>
                    )}
                                  {room.emvalidError == true && (
                      <p className="vError">Please enter valid Email</p>
                    )}
                              </Form.Group>
                              }
                              </Form.Row>
                              <Form.Row>
                              {idx==0 &&
                              <Form.Group className="selectplugin" as={Col} xs={3} >
                              <Select
                                    options={state.stdCodeMenu}
                                    searchable={false}
                                    value={selPhc}
                                    isDisabled ={state.disable}
                                    onChange={(value) => setPhoneCode(value,idx)}
                                />
                                <Form.Label>Phone Code<sup>*</sup></Form.Label>
                                {room.paxphcError == true && (
                      <p className="vError">Please enter Phone Code</p>
                    )}
                                  </Form.Group>
                               }
                              {idx==0 &&
                              <Form.Group as={Col} xs={3} controlId="phn">
                              <Form.Control type="text" disabled={state.disable} value={room.pax.phn} onChange={handleChangePax(idx)} placeholder="Phone Number" autoComplete="off" />
                              <Form.Label>Phone Number<sup>*</sup></Form.Label>
                              {room.paxphnError == true && (
                      <p className="vError">Please enter Phone Number</p>
                    )}
                              </Form.Group>
                               }
                              <Form.Group as={Col} xs={3} controlId="ra">
                                      <Form.Control type="text" disabled={state.disable} value={room.ra} onChange={handleChange("bb", idx)} placeholder="Board Type" autoComplete="off" />
                                      <Form.Label>Board Type</Form.Label>
                                  </Form.Group>
                              <Form.Group as={Col} xs={3} controlId="htl_conf">
                                      <Form.Control type="text" disabled={state.disable} value={room.htl_conf} onChange={handleChange("conf", idx)} placeholder="Hotel Conf#" autoComplete="off" />
                                      <Form.Label>Hotel Conf#<sup>*</sup></Form.Label>
                                      {room.rmhtl_confError == true && (
                      <p className="vError">Please Enter Hotel Conf#</p>
                    )}
                                  </Form.Group>
                              </Form.Row>
                               <Form.Row>
                                  <Form.Group className="selectplugin" as={Col} xs={3} >
                                  <Select
                                  options={adutlsList}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  placeholder="Select"
                                  value={room.selAdts}
                                  onChange={setAdults(idx)}
                                />
                                          <Form.Label>Adult (12+)<sup>*</sup></Form.Label>
                                          {room.selAdtsError == true && (
                      <p className="vError">Please select Adults</p>
                    )}
                                      </Form.Group>
                                      </Form.Row>
                                      <Form.Row>
                                  <Form.Group className="selectplugin" as={Col} xs={2}>
                                  <Select
                                  options={chdsList}
                                  searchable={false}
                                  isDisabled ={state.disable}
                                  placeholder="Select"
                                  value={room.selChds}
                                  onChange={setChilds(idx)}
                                />
                                <Form.Label>Children (0-11)</Form.Label>
                                  </Form.Group>
                                  {room.childAgeList!==undefined && room.childAgeList.map((rm, index) => (
                                  <React.Fragment key={index}>
                                  <Form.Group className="selectplugin" as={Col} xs={2}>
                                  <Select
                                  isDisabled ={state.disable}
                                  options={ChildernAge}
                                  searchable={false}
                                  value={rm.sel_age}
                                  onChange={(value) => handleChdAge(value,idx,index)}
                                />
                                 <Form.Label>Age of Child{idx+1}</Form.Label>
                                  </Form.Group>
                                  </React.Fragment>
                                  ))}
                                  </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="spr">
                                            <Form.Control as="textarea" value={room.spr}
                                            placeholder="Special Request" disabled={state.disable} onChange={handleChange("spr", idx)} autoComplete="off" />
                                            <Form.Label>Special Request</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3} controlId="imp_info">
                                            <Form.Control as="textarea" disabled={state.disable} value={room.imp_info} onChange={handleChange("info", idx)} placeholder="Important Information" autoComplete="off" />
                                            <Form.Label>Important Information</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={3} controlId="canpolicy">
                                            <Form.Control as="textarea" disabled={state.disable} value={room.canpolicy} onChange={handleChange("policy", idx)} placeholder="Cancellation Policy" autoComplete="off" />
                                            <Form.Label>Cancellation Policy<sup>*</sup></Form.Label>
                                            {room.canpolicyError == true && (
                      <p className="vError">Please enter Cancellation Policy</p>
                    )}
                                        </Form.Group>
                                        <Form.Group className="m-0" as={Col}>
                            <Form.Check type="checkbox" id="rfundable" label="Refundable" disabled={state.disable}
value={room.rfundable} onChange={handleChange("rfundable", idx)} custom />
                        </Form.Group>
                                    </Form.Row>
                            <div className="prDetails">
                            <h5 className="layoutTitle  d-flex justify-content-between"><span>Price Details {context.logindata.cur}</span></h5>
                            <div className="table-responsive">
                            <table class="m-0 table passDetails">
                            <tr>
                            <th width="15%">Company Type</th>
                            <th width="15%">Base Fare</th>
                            <th width="10%">Taxes</th>
                            <th width="10%">Markup</th>
                            <th width="10%">Service Fee</th>
                            <th width="10%">Supplier Fee</th>
                            <th width="10%">Commision</th>
                            {context.logindata.region_type=="India" &&
                              <th width="10%">GST</th>}
                            {context.logindata.region_type=="India" &&
                              <th width="10%">TDS</th>}
                            <th width="10%">Discount(-)</th>
                            <th width="20%">Agent Discount(-)</th>
                            <th width="10%">Total Amount</th>
                        </tr>
                        <tr>
                        <td><span>ATT  </span></td>
                        <td>
                          <Form.Group as={Col}  controlId={"attBaseFare"}>
                            <Form.Control type="number" disabled={state.disable} min={0} value={room.pr.attBaseFare} placeholder="Base Fare" autoComplete="off"  onChange={handleChangePrice("ATT",idx)}/>
                            {room.attBaseFareError == true && (
                      <p className="vError">Please enter Base Fare</p>
                    )}
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId={"attTaxes"}>
                            <Form.Control type="number" disabled={state.disable} min={0} value={room.pr.attTaxes}  placeholder="Tax Fare" autoComplete="off"  onChange={handleChangePrice("ATT",idx)} />
                            {room.attTaxesError == true && (
                      <p className="vError">Please enter Tax Fare</p>
                    )}
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="attMarkup">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attMarkup} onChange={handleChangePrice("ATT",idx)} />
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="attServiceFee">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attServiceFee} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="attSupFee">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attSupFee} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                        <Form.Group as={Col} controlId="attCommision">
                          <Form.Control type="number" disabled={state.disable} min={0} placeholder="Base Fare" autoComplete="off" value={room.pr.attCommision} onChange={handleChangePrice("ATT", idx)} />
                        </Form.Group>

                      </td>
                      {context.logindata.region_type=="India" &&
                          <td>
                        <Form.Group as={Col} controlId="attGST">
                          <Form.Control type="number" disabled={true} min={0} placeholder="gst" autoComplete="off" value={room.pr.att_gst} />
                        </Form.Group>

                      </td>}
                      {context.logindata.region_type=="India" &&
                          <td>
                        <Form.Group as={Col} controlId="attTDS">
                          <Form.Control type="number" disabled={true} min={0} placeholder="tds" autoComplete="off" value={room.pr.tds_val} />
                        </Form.Group>

                      </td>}
                        <td>
                          
                          <Form.Group as={Col}  controlId="attDisc">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attDisc} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="attAgentDisc">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attAgentDisc} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="adtbase">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.attTotal}/>
                        </Form.Group>

                        </td>

                          </tr>
                          <tr>
                        <td><span>TA  </span></td>
                        <td>
                          <Form.Group as={Col}  controlId="taBaseFare">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Base Fare" autoComplete="off"  value={Number(room.pr.attBaseFare) + Number(room.pr.attMarkup)} />
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="taTaxes">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Base Fare" autoComplete="off"  value={Number(room.pr.taTaxes)} />
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="taMarkup">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.taMarkup} onChange={handleChangePrice("TA",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="taServiceFee">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.taServiceFee} onChange={handleChangePrice("TA",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="attServiceFee">
                            <Form.Control type="number" min={0}  placeholder="Base Fare" autoComplete="off" disabled={true} value={room.pr.attServiceFee} onChange={handleChangePrice("TA",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                        <Form.Group as={Col} controlId="attCommision">
                          <Form.Control type="number" min={0} placeholder="Base Fare" autoComplete="off" disabled={true} value={0} />
                        </Form.Group>

                      </td>
                        {context.logindata.region_type=="India" &&
                          <td>
                        <Form.Group as={Col} controlId="tagst">
                          <Form.Control type="number" min={0} placeholder="gst" autoComplete="off" disabled={true} value={room.pr.ta_gst} />
                        </Form.Group>
                      </td>}
                        {context.logindata.region_type=="India" &&
                          <td>
                        <Form.Group as={Col} controlId="tatds">
                          <Form.Control type="number" min={0} placeholder="tds" autoComplete="off" disabled={true} value={room.pr.ta_tds} />
                        </Form.Group>
                      </td>}
                        <td>
                          <Form.Group as={Col}  controlId="taDisc">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.taDisc} onChange={handleChangePrice("TA",idx)}/>
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="taAgentDisc">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Base Fare" autoComplete="off" />
                        </Form.Group>

                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="taTotal">
                            <Form.Control type="number" disabled={state.disable} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.pr.taTotal}/>
                        </Form.Group>

                        </td>

                          </tr>
</table>
</div>

<div className="text-right pb-4">
{(idx > 0) ? (
    <Col>
        <div className="d-flex justify-content-end roomDelet">
          <span className="iconBtn add ezyIcon icon-delete align-self-end"
            onClick={removeRoom(idx)}
        ></span></div>
    </Col>
) : ''}
</div>

</div>
                </div>
                </React.Fragment>
                ))}
    <div className="d-flex justify-content-end mt-4">
    <Button
        size="xs"
        variant="outline-primary m-0"
        type="submit"
        isDisabled={state.disable}
        onClick={addRooms}
    >Add Room</Button>
    </div>
<div className="ticketSummerynew  d-flex">
<Col xs={6} className="cardMainlayout mr-2">
<h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Price Details ATT</span></h5>
<div className="table-responsive">
<table class="m-0 table">


      <tr>
          <th width="50%" align="left">No Rooms</th>
          <th width="50%" align="right" class="text-right">Charge to TA</th>
      </tr>

  {state.roomDetails.map((room, idx) => (

      <tr>
          <td width="50%" align="left">Room {idx+1}</td>
          <td width="50%" align="right" class="text-right">{context.logindata.cur} {Number(room.pr.attTotal).toFixed(2)}</td>
      </tr>


  ))}
     <tr>
        <td></td>
        <td colspan="6" class="text-right"><span><strong>Total Charge: {<FormateCurrency value={totalPrice(state.roomDetails,"ATT")} />}</strong></span></td>
      </tr>
      </table>
  </div>
</Col>
<Col xs={6} className="cardMainlayout mr-2">
<h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Price Details TA</span></h5>
<div className="table-responsive">
<table class="m-0 table">
      <tr>
          <th width="50%" align="left">No Rooms</th>
          <th width="50%" align="right" class="text-right">Charge to Customer</th>
      </tr>

  {state.roomDetails.map((room, idx) => (

      <tr>
          <td width="50%" align="left">Room {idx+1}</td>
          <td width="50%" align="right" class="text-right">{context.logindata.cur} {Number(room.pr.taTotal).toFixed(2)}</td>
      </tr>

  ))}
     <tr>
        <td></td>
        <td colspan="6" class="text-right"><span><strong>Total Charge: {<FormateCurrency value={totalPrice(state.roomDetails,"TA")} />}</strong></span></td>
      </tr>
      </table>
  </div>
</Col>


</div>
<div>
{state.showCustoms &&
                <div className="remarkField cardMainlayout">
                    <CustomInformaion customs={customData} compid={context.logindata.cid} noCustoms={hideCustoms}/>
                </div>
                 }
                {!state.enablePay &&
                <div className="cardMainlayout contRest minHight0 d-flex justify-content-between">
                    <Form.Row className="align-items-center">
                        <Form.Group className="m-0" as={Col}>
                            <Form.Check type="checkbox" id="xy" label="I would like to receive an booking Confirmation Mail" value="" onChange={handleConf} custom />
                            <Form.Check type="checkbox" id="xx" label="I would like to receive an Invoice Mail" value="" onChange={handleInv} custom />
                        </Form.Group>
                    </Form.Row>

                    <div class="d-flex justify-content-end mt-4 mb-4">
                    <button class="btn-primary btn-xs undefined d-flex justify-content-center align-items-center btn mr-3" onClick={reset}
                    >Reset</button>
                      <button class="btn-primary btn-xs undefined d-flex justify-content-center align-items-center  btn" onClick={continueBooking}
                    >Continue <span class="ezyIcon icon-rightArrow ml-2"></span></button>
                    </div>
                </div>
              }
              {state.enablePay &&
                <div className="cardMainlayout minHight0 justify-content-between">
                    <ManualPaymentForm payobj={state.payData} callBooking={confirmManualBooking}/>
                </div>
                }
</div>


              </div>
            <Footer />
        </>
    )
}
export default HotelManualBooking