import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import DateUtils from "../../../commonUtils/DateUtils";

//This function will show the Air markups List and Add Markup
function InsPassengers(props) {
  const [state, setState] = useState({
    loading: false
  })

  return (
    <>
        <div className="headerlist">
                     <Row>
                         <Col>Traveller</Col>
                         <Col>Pax Type</Col>
                         <Col>Date of Birth</Col>
                         <Col>Passport Number</Col>
                         <Col>Country of Residence</Col>
                         {/* <Col>Covid Extension</Col>
                         <Col>Winter Sport Extension </Col> */}
                     </Row>
                 </div>
                    <div className="passengerList">
                    {props.paxList && props.paxList.map((pax,idx)=>(
                             <div className="list">

                                     <Row>
                                     <Col>{pax.tl} {pax.fn} {pax.ln}</Col>
                                     <Col>{pax.ty}</Col>
                                     <Col>{DateUtils.convertToDateHifen(pax.dob)}</Col>
                                     <Col>{pax.doc.num}</Col>
                                     <Col>{pax.doc.isc}</Col>
                                     {/* <Col>{pax.is_covid?"YES":"NO"}</Col>
                                     <Col>{pax.is_winsport?"YES":"NO"} </Col> */}
                                     </Row>

                             </div>
                                ))
                              }
                     </div>
    </>
  )
}
export default InsPassengers