
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React from 'react';
 import { Col, Form, Row, Table, Alert, Modal } from "react-bootstrap";
 import DateUtils from "../../../commonUtils/DateUtils";
 
 /**
  * @description:This function will Display the the Segment details
  * @author: Lakshmi
  * @param {*}
  * @function Pax details
  * @date : 18-09-2020
  */
 function InsurancePaxPdf(props) {
   
     return (
         <>
             <div className="pdffare">
                 <h3 className="pdfSubTitl">
                     Passenger Details
                 </h3>
                 <div className="header">
                     <Row>
                         <Col xs="2">Traveller</Col>
                         <Col xs="2">Gender</Col>
                         <Col xs="2">Type</Col>
                         <Col xs="2">Date of Birth</Col>
                         <Col xs="2">Passport Number</Col>
                         <Col xs="2">Country of Residency</Col>
                     </Row>
                 </div>

                 {props.paxList!==undefined && props.paxList.map((pax, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                             <Row>
                            <Col xs="2">{pax.pax_name}</Col>
                            <Col xs="2">{pax.gender}</Col>
                            <Col xs="2">{pax.pax_type}</Col>
                            <Col xs="2">{DateUtils.convertToDateHifen(pax.dob)}</Col>
                            <Col xs="2">{pax.doc_id}</Col>
                            <Col xs="2">{pax.isc}</Col>
                             </Row>
                         </div>
                     </React.Fragment>
                 ))}

             </div>
         </>

     )
 }


 export default InsurancePaxPdf;
