
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import InnerLoader from '../../../common/loader/InnerLoader';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import { isNotNull } from '../../../commonUtils/validators';
import CommonSearch from '../../common/CommonSearch';
import Deal from './Deal';
import { editDealcode, getDealcodeList } from './operations';


/**
 * Initial State Declaration
 */
const initialState = {
  createDealCode: false, editDealCode: false, deal_code_data_list: [], edit_deal_code_res: [], loading: false
};





const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};


/**
 * @author: Rambabu
 * @description: This function will create the airline comission defination.
 * @date:14-09-2020
 */
function DealCode() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dealcode, setDealcodeList] = useState({ dealcodedata: [] });
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableView, setEnableView,] = useState(false);
  const [OlddealcodeCreated, setOldNames] = useState({ oldData: [] })


  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== undefined) {
      let userId = context.logindata.uid
      let rq_Json = {   //search initial object
        "type": context.logindata.utype,
        "userid": context.logindata.uid,
        "is_admin": context.logindata.is_admin,
        "region_type" : context.logindata.region_type
      }
      //Fetch dealode data list
      getDealcodeList(rq_Json).then((res) => {
        // dispatch({ type: 'deal_code_data_list', payload: res })
        if (res) {
          loadResponse(res);
          setIsLoadingMore(false);
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getMarkupList function---" + error
        );
      });
    }

  }, [context.logindata]);



  const createDealBtnClick = (e) => {
    dispatch({ type: 'createDealCode', payload: true })
  }

  const searchDealcode = (data) => {
    getDealcodeList(data).then(response => {
      if (response) {
        loadResponse(response);
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in fetching dealcode function---" + error
      );
    });
  }


  /**
  * @description:Edit deal code data
  * @author:Rambabu
  * @date:16-10-2020
  * @param {*} obj
  */
  const editDealcodeBtnClick = (obj, type) => () => {
    /*  dispatch({ type: 'notiMessageShow', payload: false })
     dispatch({ type: 'notiMessage', payload: '' })
     dispatch({ type: 'notiVarient', payload: '' })
     dispatch({ type: 'editDealCode', payload: true })*/
    editDealcode(obj.id).then(response => {
      if (isNotNull(response)) {
        dispatch({ type: 'edit_deal_code_res', payload: response });
        dispatch({ type: 'editDealCode', payload: true })
      } else {

        dispatch({ type: 'editDealCode', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Edit deal code data is not avaliable' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
      }
      if (type === "view") {
        setEnableView(true);
      } else {
        setEnableView(false);
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in edit deal code function---" + error
      );
    });
  }

  /**
 * @description: response construction
 * @param {raw response we get after search} response
 */
  const loadResponse = (response) => {
    let data = [];
    let dataName = []
    var enableEdit = false;
    if (context.logindata.roles.includes('D_C_U')) {
      enableEdit = true;
    }
    for (let val of response) {
      let ttype = ""
      if (val.ttype == "0") {
        ttype = "All"
      }
      else if (val.ttype == "1") {
        ttype = "One Way"
      }
      if (val.ttype == "2") {
        ttype = "Round Trip"
      }
      else if (val.ttype == "3") {
        ttype = "Multi City"
      }
      let dcType = ""
      if (val.dt == "1") {
        if (val.dctype == "1") {
          dcType = "Backend Discount"
        } else if (val.dctype == "2") {
          dcType = "Up-Front Discount"
        } else if (val.dctype == "3") {
          dcType = "Nego-Rate"
        }else if(val.dctype == "4"){
          dcType = "Corporate Deal"
        }
      } else {
        if (val.dctype == "1") {
          dcType = "Monthly Settlement"
        } else if (val.dctype == "2") {
          dcType = "Trans Settlement"
        }
      }
      dataName.push(val.mn)
      console.log('response init', JSON.stringify(val))
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){
      data.push({ region_type:val.region_type,dname: val.mn,office_label:"ALL",sales_channel:val.sales_channel, dctype: dcType, ttype: ttype, status: (val.act === 1) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editDealcodeBtnClick(val, "view")}></a> {enableEdit && <a className="ezyIcon icon-edit" onClick={editDealcodeBtnClick(val, "edit")}></a>}</span> </> })
    }}
    console.log('response final', data)
    setOldNames({ oldData: dataName }) //set all dealcode name in order to check for duplication while creation
    setDealcodeList({ dealcodedata: data })
  }

  const columns = [
    {
      dataField: 'sales_channel',
      text: 'Sale channel',
      sort: true,
      filter: textFilter()
    },{
    dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'office_label',
    text: 'Office Id',
    sort: true,
    filter: textFilter()
  },{
    dataField: 'dname',
    text: 'Deal Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'dctype',
    text: 'Deal Code Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ttype',
    text: 'Travel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];









  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Deals" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Deals" />
          <div className="contentArea cmnSearch">
            {(!state.createDealCode && !state.editDealCode) ? (
              <>

                <CommonSearch configType={"DealCode"} sendSearchCreteria={searchDealcode} />
                {context.logindata.roles !== undefined && context.logindata.roles.includes('D_C_C') &&
                  <div className="d-flex justify-content-between buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn"
                      className="addPlus ezyIcon icon-plus"
                      onClick={createDealBtnClick}
                      type="submit">
                    Create Deal
                  </Button>
                  </div>
                }

              </>
            ) : (

                (isNotNull(state.edit_deal_code_res) && state.editDealCode)
                  ? (
                    <>
                      <Deal edit_res={state.edit_deal_code_res} edit_deal={state.editDealCode} disableBtn={enableView} />
                    </>

                  ) : ((state.createDealCode) ? (
                    <>
                      <Deal oldDataNames={OlddealcodeCreated.oldData} />
                    </>
                  ) : ('loading....')
                  )
              )
            }
          </div>
        </div>

        {(!state.createDealCode && !state.editDealCode) ? (
          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
              {dealcode.dealcodedata.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={dealcode.dealcodedata}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={dealcode.dealcodedata.length >10 && dealcode.dealcodedata.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  dealcode.dealcodedata.length >25 && dealcode.dealcodedata.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  dealcode.dealcodedata.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || dealcode.dealcodedata.length === 0
                && <NoRecord />}</>)}
          </div>

        ) : ('')}

        {state.editMarkup ? (
          <>
            ''
      </>
        ) : ('')}
      </div>
      <Footer />
    </>
  )
}
export default DealCode