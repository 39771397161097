/**
  * @description: Flight Confirmation page Root Fare details component
  * @date:09-01-2020
  * @author:Rambabu
  *
  */
 import React, { useState, useEffect, useReducer } from 'react';
 import { useHistory } from 'react-router-dom';
 import { Alert, Col, Container, Form, Row } from "react-bootstrap";
 import { isNotNull, isArrayNotEmpty } from '../../../commonUtils/validators';
 import Utilities from '../../../commonUtils/Utilities';
 import FormateCurrency from '../../../commonUtils/FormateCurrency';
 export const ONFLY_FLT_MKP = "onflyFltMkp";

 /**
  * Initial State Declaration
  */
 const initialState = {

     fareobj: '', grand_total: 0 , plbdisc:0,otCharge:0,bagAvail:false,mealAvail:false,seatAvail:false
 };


 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };

 /**
  * @description: This function will show the FareDetails
  * @date:09-01-2020
  * @author:Rambabu
  *
  */
 function FareDetails(props) {




     const [state, dispatch] = useReducer(reducer, initialState);
     const history = useHistory();



     useEffect(() => {
         //if props data avaliable
         if (isNotNull(props.faredet)) {
            var userType=(props.faredet.butype==="ta_user")?"TA":"STA";
            let adts=props.faredet.psngrs.filter((obj) => obj.ty === "ADT");
            let inf=0;
            for(let val of adts){
               if(val.inf!==undefined && val.inf!=="" && val.inf.ty==="INF"){
                   inf=inf+1;
               }
            }
            let chds=props.faredet.psngrs.filter((obj) => obj.ty === "CHD");
            let paxCount=adts.length+chds.length+inf;

             let pax_array = [];
             let grandtotal = 0;
             let adtService=0;
             let chdService=0;
             let infService=0;
             let adtReturnService=0;
             let chdReturnService=0;
             let infReturnService=0;
             let plbdisc=0;
             let adtDisc=0;
             let chdDisc=0;
             let infDisc=0;
             let bagAdtPrice=0;
             let mealAdtPrice=0;
             let bagChdPrice=0;
             let mealChdPrice=0;
             let totalOtCharge=0;
             let seatAdtPrice=0;
             let seatChdPrice=0;
             let onFlyMarkup=0
             let onFlyPaxMkp=0
             let total_gst=0
             let attGst_total=0;
             let taGst_total=0;
             let attGst=0
             let taGst=0;
             let adtTds=0;
             let chdTds=0;
             let infTds=0;
             let tds_total=0;
             //Cal GST :
             if (isNotNull(props.faredet.sty) && props.faredet.sty === 'normal') {
             if(props.gst!==undefined && props.gst!==""){
                total_gst=props.gst.total_gst;
                let att = props.gst.gst_invoice.filter(obj => obj.type==="ATT");
                let ta = props.gst.gst_invoice.filter(obj => obj.type==="TA");
                if(att.length!==0){
                    if(Number(att[0].cgst_val)!==0 && Number(att[0].sgst_val)!==0){
                        attGst_total= Number(att[0].cgst_val) + Number(att[0].sgst_val)
                    }else{
                        attGst_total= Number(att[0].igst_val)
                    }
                        attGst=Number(attGst_total/paxCount);
                }
                if(ta.length!==0){
                    if(Number(ta[0].cgst_val)!==0 && Number(ta[0].sgst_val)!==0){
                        taGst_total= Number(ta[0].cgst_val) + Number(ta[0].sgst_val)
                    }else{
                        taGst_total= Number(ta[0].igst_val)
                    }
                        taGst=Number(taGst_total/paxCount);
                }
            }
            }else{
                if(props.ow_gst!==undefined && props.ow_gst!=="" && props.rt_gst!==undefined && props.rt_gst!==""){
                    total_gst=Number(props.ow_gst.total_gst) + Number(props.rt_gst.total_gst);
                    let att1 = props.ow_gst.gst_invoice.filter(obj => obj.type==="ATT");
                    let att2 = props.rt_gst.gst_invoice.filter(obj => obj.type==="ATT");
                    let ta1 = props.ow_gst.gst_invoice.filter(obj => obj.type==="TA");
                    let ta2 = props.rt_gst.gst_invoice.filter(obj => obj.type==="TA");
                    if(att1.length!==0 && att2.length!==0){
                        if(Number(att1[0].cgst_val)!==0 && Number(att2[0].sgst_val)!==0 && Number(att2[0].cgst_val)!==0 && Number(att2[0].sgst_val)!==0){
                            attGst_total= Number(att1[0].cgst_val) + Number(att1[0].sgst_val) + Number(att2[0].cgst_val) + Number(att2[0].sgst_val);
                        }else{
                            attGst_total= Number(att1[0].igst_val) + Number(att2[0].igst_val)
                        }
                            attGst=Number(attGst_total/paxCount);
                    }
                    if(ta1.length!==0 && ta2.length!==0){
                        if(Number(ta1[0].cgst_val)!==0 && Number(ta1[0].sgst_val)!==0 && Number(ta2[0].cgst_val)!==0 && Number(ta2[0].sgst_val)!==0){
                            taGst_total= Number(ta1[0].cgst_val) + Number(ta1[0].sgst_val) + Number(ta2[0].cgst_val) + Number(ta2[0].sgst_val);
                        }else{
                            taGst_total= Number(ta1[0].igst_val) + Number(ta2[0].igst_val)
                        }
                            taGst=Number(taGst_total/paxCount);
                    }
                    }
            }

             //OnFly Markup
             let markup=window.sessionStorage.getItem(ONFLY_FLT_MKP);
            if(markup!==null){
                onFlyMarkup=Number(markup);
                onFlyPaxMkp=Number(markup/paxCount);
                window.sessionStorage.removeItem(ONFLY_FLT_MKP);
            }
            //Other charge for all pax
             if(props.faredet.otcharge!==undefined && props.faredet.otcharge!==null){
                totalOtCharge=Number(props.faredet.otcharge);
                dispatch({ type: 'otCharge', payload:Number(props.faredet.otcharge/paxCount)})
             }
             if(props.faredet.bktype!=="REISSUE"){
             if(props.faredet.plb!==undefined && props.faredet.plb!==null && Object.keys(props.faredet.plb).length !== 0){
                 if(props.faredet.sty==="normal"){
                     if(props.faredet.plb.adt!==undefined){
                        adtDisc=Utilities.addPrice(props.faredet.plb.adt,adts.length,"Cnt");
                     }
                     if(props.faredet.plb.chd){
                        chdDisc=Utilities.addPrice(props.faredet.plb.chd,chds.length,"Cnt");
                     }
                     if(props.faredet.plb.inf!==undefined){
                        infDisc=Utilities.addPrice(props.faredet.plb.inf,inf,"Cnt");
                     }
                     if(adtDisc==="NaN"){
                        adtDisc=0;
                     }
                     if(chdDisc==="NaN"){
                        chdDisc=0;
                     }
                     if(infDisc==="NaN"){
                        infDisc=0;
                     }
                    plbdisc=Utilities.addPrice(parseFloat(adtDisc)+parseFloat(chdDisc)+parseFloat(infDisc),0,"Add");
                    dispatch({ type: 'plbdisc', payload: parseFloat(plbdisc)})
                 }else{

                   if(props.faredet.plb.adt!==undefined && props.faredet.plb.adt!==null){
                     let adtPlb=props.faredet.plb.adt.split("/");
                     adtDisc=Utilities.addPrice(adtPlb[0],adtPlb[1],"Add");
                   }
                   if(props.faredet.plb.chd!==undefined && props.faredet.plb.chd!==null){
                     let chdPlb=props.faredet.plb.chd.split("/");
                   chdDisc=Utilities.addPrice(chdPlb[0],chdPlb[1],"Add");
                   }
                   if(props.faredet.plb.inf!==undefined && props.faredet.plb.inf!==null){
                     let infPlb=props.faredet.plb.inf.split("/");
                     infDisc=Utilities.addPrice(infPlb[0],infPlb[1],"Add");
                   }
                   plbdisc=Utilities.addPrice(parseFloat(adtDisc)+parseFloat(chdDisc)+parseFloat(infDisc),0,"Add");
                   dispatch({ type: 'plbdisc', payload: plbdisc})

                 }
             }
         }

         let onhold_tab = localStorage.getItem('ONHOLD_STY')
             //iterating pax data
             for (let p in props.faredet.psngrs) {

                 let pax = props.faredet.psngrs[p];

                 let onward_sector = '';
                 let return_sector = '';

                 //below code if normal flow we are taking srcorigns data
                 if (isNotNull(props.faredet.sty) && (props.faredet.sty === 'normal' || onhold_tab)) {

                     //get teh total fare amount from pr tag
                     grandtotal = props.faredet.origns[0]['srcorigns'][0].pr.tf;

                     //if normal flow we are taking srcorigns tag data only
                     onward_sector = props.faredet.origns[0]['srcorigns'][0];

                     //adult fare data from srcorigns paxpr tag
                     let adult_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'ADT'
                     });

                     //child fare data from srcorigns paxpr tag
                     let chd_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'CHD'
                     });

                     //infant fare data from srcorigns paxpr tag
                     let inf_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'INF'
                     });


                     //if adult, constructing fare data
                     if (pax.ty.toUpperCase() === 'ADT') {

                         let totalfare = adult_fare_data[0].tf;//total_fare
                         let basefare = adult_fare_data[0].bf;//base_fare
                        // let tax = adult_fare_data[0].tax;//tax
                         let tax=0;
                         let penalty=0;
                         let resVal=0;
                         if(props.faredet.bktype!=="REISSUE"){
                          tax=getTaxValue(adult_fare_data[0].tax);
                         }else{
                          tax=adult_fare_data[0].tfd;
                          penalty = adult_fare_data[0].penlty;
                          resVal = adult_fare_data[0].resdval;
                         }

                         let disc=Utilities.dicountCalculation(adult_fare_data[0].tax);
                         let serviceFee=[];
                         if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                             serviceFee=props.faredet.service_fee;
                         }else{
                             serviceFee=props.faredet.origns[0].srcorigns[0].serviceFee;
                         }
                         let adtFee=0;
                         if(serviceFee!==null && serviceFee!==undefined && serviceFee.length!==0){
                             adtService = Utilities.calculateServiceFeePax(serviceFee,userType,"ADT",props.faredet.adt,paxCount);
                             if(adtService!==undefined && adtService!==0){
                               adtFee=adtService;
                               adtService=adtService*props.faredet.adt;
                             }else{
                                adtService=0;
                             }
                         }

                         let adtplb=0;
                         if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.adt!==undefined){
                             adtplb=props.faredet.plb.adt;
                             //Per pax PLB
                            //  if(adtplb!==undefined && adtplb!==0){
                            //      adtplb=adtplb/props.faredet.adt;
                            //  }
                         }
                         let bagPrice=0;
                         if(pax.baggage!==undefined && pax.baggage.price!==undefined){
                            bagPrice=Number(pax.baggage.price);
                            if(bagAdtPrice===0){
                                bagAdtPrice=bagPrice;
                            }else{
                                bagAdtPrice=bagAdtPrice+bagPrice;
                            }
                            if(bagPrice!==0){
                                dispatch({ type: 'bagAvail', payload: true });
                              }
                         }
                         let mealPrice=0;
                         if(pax.meal!==undefined && pax.meal.price!==undefined){
                            mealPrice=Number(pax.meal.price);
                            if(mealAdtPrice===0){
                                mealAdtPrice=mealPrice;
                            }else{
                                mealAdtPrice=mealAdtPrice+mealPrice;
                            }
                            if(mealAdtPrice!==undefined){
                                dispatch({ type: 'mealAvail', payload: true });
                            }
                         }
                         let adtPrice=0;
                         if(pax.seats!==undefined && pax.seats.length!==0){
                            var seatsList = pax.seats.filter((obj) => (obj.jtype === props.sector ) || (props.sector==="1" || props.sector==="2"));
                            if(seatsList.length!==0){
                                for(let val of seatsList){
                                   if(seatAdtPrice===0){
                                    seatAdtPrice=val.price;
                                    adtPrice=val.price;
                                   }else{
                                    seatAdtPrice=seatAdtPrice+val.price;
                                    adtPrice=adtPrice+val.price;
                                   }
                                }
                               if(seatAdtPrice!==0){
                                   dispatch({ type: 'seatAvail', payload: true });
                                 }
                            }
                         }
                        //TDS
                         if(props.tds!==undefined && props.tds!==""){
                            if(props.tds.adtTds_val!==undefined){
                                adtTds = props.tds.adtTds_val;
                            }
                            if(props.tds.chdTds_val!==undefined){
                                chdTds = props.tds.chdTds_val;
                            }
                            if(props.tds.inftds_val!==undefined){
                                infTds = props.tds.inftds_val;
                            }
                            if(props.tds.total_tds!==undefined){
                                tds_total = props.tds.total_tds;
                            }
                        }

                         let vat = 0;
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Adult';
                         let lead = pax.lead;

                         const obj = {
                             nm: pax_name,
                             lead: lead,
                             ty: paxtype,
                             bf: basefare+Number(onFlyPaxMkp),
                             tax: parseFloat(tax) + Number(attGst) + Number(adtTds),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(adtplb),"Add"),
                             sf: parseFloat(adtFee),
                             gst:taGst,
                             penalty:penalty,
                             resVal:resVal,
                             vat: vat,
                             bagprice:bagPrice,
                             mealprice:mealPrice,
                             seatprice:adtPrice,
                             tf: totalfare+adtFee+bagPrice+mealPrice+adtPrice+state.otCharge+Number(onFlyPaxMkp) + Number(attGst) + Number(taGst) + Number(adtTds) -parseFloat(adtplb)
                         }
                         pax_array.push(obj);

                         //if infant, constructing fare data
                         if (isNotNull(pax.inf) && inf_fare_data!==undefined && inf_fare_data.length!==0) {

                             let totalfare = inf_fare_data[0].tf;//total_fare
                             let basefare = inf_fare_data[0].bf;//base_fare
                             //let tax = inf_fare_data[0].tax;//tax
                             let tax=0;
                             let penalty=0;
                             let resVal=0;
                             if(props.faredet.bktype!=="REISSUE"){
                                 tax=getTaxValue(inf_fare_data[0].tax);
                                }else{
                                 tax=inf_fare_data[0].tfd;
                                 penalty = inf_fare_data[0].penlty;
                                 resVal = inf_fare_data[0].resdval;
                                }
                             let disc=Utilities.dicountCalculation(inf_fare_data[0].tax);
                             let serviceFee=[];
                             if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                                 serviceFee=props.faredet.service_fee;
                             }else{
                                 serviceFee=props.faredet.origns[0].srcorigns[0].serviceFee;
                             }
                             let infFee=0;
                             if(isNotNull(serviceFee) && serviceFee!==undefined && serviceFee.length!==0){
                                 infService = Utilities.calculateServiceFeePax(serviceFee,userType,"INF",props.faredet.inf,paxCount);
                                 if(infService!==undefined && infService!==0){
                                     infFee=infService;
                                     infService=infService*props.faredet.inf;
                                 }else{
                                    infService=0;
                                 }
                             }
                             let infplb = 0;
                             if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.inf!==undefined){
                                 infplb=props.faredet.plb.inf;
                             //Per pax PLB
                            //  if(infplb!==undefined && infplb!==0){
                            //      infplb=infplb/props.faredet.inf;
                            //  }
                             }
                             let vat = 0;
                             let pax_name = pax.inf.fn + ' ' + pax.inf.ln;
                             let paxtype = 'Infant';

                             const obj = {
                                 nm: pax_name,
                                 ty: paxtype,
                                 bf: basefare + Number(onFlyPaxMkp),
                                 tax: parseFloat(tax) + Number(attGst) + Number(infTds),
                                 disc:Utilities.addPrice(parseFloat(disc),parseFloat(infplb),"Add"),
                                 sf: parseFloat(infFee),
                                 gst:taGst,
                                 penalty:penalty,
                                 resVal:resVal,
                                 vat: vat,
                                 seatprice:0.00,
                                 bagprice:0.00,
                                 mealprice:0.00,
                                 tf: totalfare+infFee+state.otCharge+Number(onFlyPaxMkp) + Number(attGst) + Number(taGst) + Number(infTds) -parseFloat(infplb)
                             }
                             pax_array.push(obj);
                         }

                     }//if child, constructing fare data
                     else if (pax.ty.toUpperCase() === 'CHD' && chd_fare_data.length!==0) {

                         let totalfare = chd_fare_data[0].tf;//total_fare
                         let basefare = chd_fare_data[0].bf;//base_fare
                         //let tax = chd_fare_data[0].tax;//tax
                         let tax=0;
                         let penalty=0;
                         let resVal=0;
                         if(props.faredet.bktype!=="REISSUE"){
                             tax=getTaxValue(chd_fare_data[0].tax);
                            }else{
                             tax=chd_fare_data[0].tfd;
                             penalty = chd_fare_data[0].penlty;
                             resVal = chd_fare_data[0].resdval;
                            }
                         let disc=Utilities.dicountCalculation(chd_fare_data[0].tax);
                         let serviceFee=[];
                             if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                                 serviceFee=props.faredet.service_fee;
                             }else{
                                 serviceFee=props.faredet.origns[0].srcorigns[0].serviceFee;
                             }
                             let chdFee=0;
                         if(isNotNull(serviceFee) && serviceFee!==undefined && serviceFee.length!==0){
                             chdService = Utilities.calculateServiceFeePax(serviceFee,userType,"CHD",props.faredet.chd,paxCount);
                             if(chdService!==undefined && chdService!==0){
                                 chdFee=chdService;
                                 chdService=chdService*props.faredet.chd;
                             }else{
                                chdService=0;
                             }
                         }

                         let chdplb = 0;
                         if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.chd!==undefined){
                             chdplb=props.faredet.plb.chd;
                             //Per pax PLB
                            //  if(chdplb!==undefined && chdplb!==0){
                            //      chdplb=chdplb/props.faredet.chd;
                            //  }
                         }
                         let vat = 0;

                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Child';
                         let bagPrice=0;
                         if(pax.baggage!==undefined && pax.baggage.price!==undefined){
                            bagPrice=Number(pax.baggage.price);
                            if(bagChdPrice===0){
                                bagChdPrice=bagPrice;
                            }else{
                                bagChdPrice=bagChdPrice+bagPrice;
                            }
                            if(bagPrice!==0){
                                dispatch({ type: 'bagAvail', payload: true });
                              }
                         }
                         let mealPrice=0;
                         if(pax.meal!==undefined && pax.meal.price!==undefined){
                            mealPrice=Number(pax.meal.price);
                            if(mealChdPrice===0){
                                mealChdPrice=mealPrice;
                            }else{
                                mealChdPrice=mealChdPrice+mealPrice;
                            }
                            if(mealChdPrice!==undefined){
                                dispatch({ type: 'mealAvail', payload: true });
                             }
                         }
                         let price=0;
                         if(pax.seats!==undefined && pax.seats.length!==0){
                            var seatsList = pax.seats.filter((obj) => (obj.jtype === props.sector ) || (props.sector==="1" || props.sector==="2"));
                            if(seatsList.length!==0){
                                for(let val of seatsList){
                                   if(seatChdPrice===0){
                                    seatChdPrice=val.price;
                                    price=val.price;
                                   }else{
                                    seatChdPrice=seatChdPrice+val.price;
                                    price=price+val.price;
                                   }
                                }
                               if(seatChdPrice!==0){
                                   dispatch({ type: 'seatAvail', payload: true });
                                 }
                            }
                         }

                         const obj = {
                             nm: pax_name,
                             ty: paxtype,
                             bf: basefare + Number(onFlyPaxMkp),
                             tax: parseFloat(tax) + Number(attGst) + Number(chdTds),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(chdplb),"Add"),
                             sf: parseFloat(chdFee),
                             gst:taGst,
                             penalty:penalty,
                             resVal:resVal,
                             vat: vat,
                             bagprice:bagPrice,
                             mealprice:mealPrice,
                             seatprice:price,
                             tf: totalfare+chdFee+bagPrice+mealPrice+price+state.otCharge+Number(onFlyPaxMkp) + Number(attGst) + Number(taGst) + Number(chdTds)-parseFloat(chdplb)
                         }
                         pax_array.push(obj);

                     } else {
                         const obj = { nm: '', ty: '', bf: 0, tax: 0, sf: 0, vat: 0, tf: 0 }
                         pax_array.push(obj);
                     }

                 }//below code if split fare flow we are taking srcorigns data and destorigins data
                 else if (isNotNull(props.faredet.sty) && props.faredet.sty === 'split') {

                     //for total amount adding onward and return pr tag
                     if(props.faredet.bktype!=="REISSUE"){
                        grandtotal = props.faredet.origns[0]['srcorigns'][0].pr.tf + props.faredet.origns[0]['destorigins'][0].pr.tf;
                     }else{
                        grandtotal = props.faredet.origns[0]['srcorigns'][0].pr.tf;
                     }

                     //onward sector
                     onward_sector = props.faredet.origns[0]['srcorigns'][0];

                     //return sector
                     if(props.faredet.bktype!=="REISSUE"){
                     return_sector = props.faredet.origns[0]['destorigins'][0];
                     }
                     //onward adult fare data from srcorigns paxpr tag
                     let onward_adult_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'ADT'
                     });

                     //return adult fare data from destorigins paxpr tag
                     let return_adult_fare_data =[];
                     if(props.faredet.bktype!=="REISSUE"){
                        return_adult_fare_data=return_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'ADT'
                     });
                    }

                     //onward child fare data from srcorigns paxpr tag
                     let onward_chd_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'CHD'
                     });

                     //return child fare data from destorigins paxpr tag
                     let return_chd_fare_data = [];
                     if(props.faredet.bktype!=="REISSUE"){
                        return_chd_fare_data=return_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'CHD'
                     });
                    }

                     //onward infant fare data from srcorigns paxpr tag
                     let onward_inf_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'INF'
                     });

                     //return infant fare data from destorigins paxpr tag
                     let return_inf_fare_data = [];
                     if(props.faredet.bktype!=="REISSUE"){
                        return_inf_fare_data= return_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'INF'
                     });
                    }

                     //if adult, constructing fare data
                     if (pax.ty.toUpperCase() === 'ADT') {

                         let totalfare = 0;
                         if(props.faredet.bktype!=="REISSUE"){
                            totalfare=onward_adult_fare_data[0].tf + return_adult_fare_data[0].tf;//total_fare
                         }else{
                            totalfare=onward_adult_fare_data[0].tf;//total_fare
                         }
                         let basefare =0;
                         if(props.faredet.bktype!=="REISSUE"){
                            basefare=onward_adult_fare_data[0].bf + return_adult_fare_data[0].bf;//base_fare
                         }else{
                            basefare=onward_adult_fare_data[0].bf;//base_fare
                         }
                        // let tax = onward_adult_fare_data[0].tax + return_adult_fare_data[0].tax;//tax
                         let tax=0;
                         if(props.faredet.bktype!=="REISSUE"){
                            tax=getTaxValue(onward_adult_fare_data[0].tax)+getTaxValue(return_adult_fare_data[0].tax);
                         }else{
                            tax=getTaxValue(onward_adult_fare_data[0].tax)
                         }
                         let disc=0;
                         let odisc=Utilities.dicountCalculation(onward_adult_fare_data[0].tax);
                         let rdisc=0;
                         if(props.faredet.bktype!=="REISSUE"){
                            rdisc=Utilities.dicountCalculation(return_adult_fare_data[0].tax);
                         }
                         if(odisc!==undefined && rdisc!==undefined){
                             disc=Utilities.addPrice(parseFloat(odisc),parseFloat(rdisc),"Add");
                         }else if(odisc!==undefined){
                             disc=odisc;
                         }else if(rdisc!==undefined){
                             disc=rdisc;
                         }

                         let servicefee = 0;
                         let vat = 0;
                         adtService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['srcorigns'][0].serviceFee,userType,"ADT",props.faredet.adt,paxCount);
                         if(props.faredet.bktype!=="REISSUE"){
                         adtReturnService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['destorigins'][0].serviceFee,userType,"ADT",props.faredet.adt,paxCount);
                         }
                         if(adtService!==undefined && adtReturnService!==undefined){
                         servicefee=Utilities.addPrice(parseFloat(adtService),parseFloat(adtReturnService),"Add");
                         }else if(adtService!==undefined){
                             servicefee=parseFloat(adtService);
                         }else if(adtReturnService!==undefined){
                             servicefee=parseFloat(adtReturnService);
                         }
                         //Per pax service
                         if(isNotNull(servicefee) && servicefee!==undefined && servicefee!==0){
                          servicefee=servicefee/props.faredet.adt;
                         }
                         let plbadt=0;
                         if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.adt!==undefined){
                             let adtPlb=props.faredet.plb.adt.split("/");
                             plbadt=Utilities.addPrice(adtPlb[0],adtPlb[1],"Add");
                             //Per pax PLB
                            //  if(plbadt!==undefined && plbadt!==0){
                            //      plbadt=plbadt/props.faredet.adt;
                            //  }
                         }
                         if(props.tds!==undefined && props.tds!=="" && props.tds.adtTds_val!==null){
                            if(props.tds.adtTds_val!==undefined && props.tds.adtTds_val.includes("/")){
                                let adt= props.tds.adtTds_val.split("/");
                                adtTds = Number(adt[0]) + Number(adt[1]);
                            }
                            if(props.tds.chdTds_val!==undefined && props.tds.chdTds_val.includes("/")){
                                let chd= props.tds.chdTds_val.split("/");
                                chdTds =  Number(chd[0]) + Number(chd[1]);
                            }
                            if(props.tds.inftds_val!==undefined && props.tds.inftds_val.includes("/")){
                                let inf= props.tds.inftds_val.split("/");
                                infTds = Number(inf[0]) + Number(inf[1]);
                            }
                            if(props.tds.total_tds!==undefined){
                                tds_total = props.tds.total_tds;
                            }
                        }
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Adult';
                         let lead = pax.lead;

                         const obj = {
                             nm: pax_name,
                             lead: lead,
                             ty: paxtype,
                             bf: basefare + Number(onFlyPaxMkp),
                             tax: parseFloat(tax) + Number(attGst) + Number(adtTds),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(plbadt),"Add"),
                             sf: parseFloat(servicefee),
                             gst : Number(taGst),
                             vat: vat,
                             tf: totalfare+parseFloat(servicefee)+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)+Number(adtTds)-parseFloat(plbadt)
                         }
                         pax_array.push(obj);

                         //if infant, constructing fare data
                         if (isNotNull(pax.inf)) {

                             let totalfare =0
                             if(props.faredet.bktype!=="REISSUE"){
                                totalfare= onward_inf_fare_data[0].tf + return_inf_fare_data[0].tf;//total_fare
                             }else{
                                totalfare=onward_inf_fare_data[0].tf;
                             }
                             let basefare =0;
                             if(props.faredet.bktype!=="REISSUE"){
                                basefare=onward_inf_fare_data[0].bf + return_inf_fare_data[0].bf;//base_fare
                             }else{
                                basefare=onward_inf_fare_data[0].bf;//base_fare
                             }
                            // let tax = onward_inf_fare_data[0].tax + return_inf_fare_data[0].tax;//tax
                             let tax=0;
                             if(props.faredet.bktype!=="REISSUE"){
                             tax=getTaxValue(onward_inf_fare_data[0].tax)+getTaxValue(return_inf_fare_data[0].tax);
                             }else{
                             tax=getTaxValue(onward_inf_fare_data[0].tax);
                             }
                             let disc=0;
                             let odisc=Utilities.dicountCalculation(onward_inf_fare_data[0].tax);
                             let rdisc=0;
                             if(props.faredet.bktype!=="REISSUE"){
                                rdisc=Utilities.dicountCalculation(return_inf_fare_data[0].tax);
                             }
                             if(odisc!==undefined && rdisc!==undefined){
                                 disc=Utilities.addPrice(parseFloat(odisc),parseFloat(rdisc),"Add");
                             }else if(odisc!==undefined){
                                 disc=odisc;
                             }else if(rdisc!==undefined){
                                 disc=rdisc;
                             }

                             let infservicefee = 0;
                             let vat = 0;
                             infService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['srcorigns'][0].serviceFee,userType,"INF",props.faredet.inf,paxCount);
                             if(props.faredet.bktype!=="REISSUE"){
                             infReturnService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['destorigins'][0].serviceFee,userType,"INF",props.faredet.inf,paxCount);
                             }
                             if(infService!==undefined && infReturnService!==undefined){
                                 infservicefee=Utilities.addPrice(parseFloat(infService),parseFloat(infReturnService),"Add");
                             }else if(infService!==undefined){
                                 infservicefee=parseFloat(infService);
                             }else if(infReturnService!==undefined){
                                 infservicefee=parseFloat(infReturnService);
                             }
                             //Per pax serice fee
                             if(isNotNull(infservicefee) && infservicefee!==undefined && infservicefee!==0){
                                 infservicefee=infservicefee/props.faredet.inf;
                                }
                             let plbinf=0;
                             if(props.faredet.plb!==undefined && props.faredet.plb!==null && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb.inf!==undefined){
                                 let infPlb=props.faredet.plb.inf.split("/");
                                 plbinf=Utilities.addPrice(infPlb[0],infPlb[1],"Add");

                                //  if(plbinf!==undefined && plbinf!==0){
                                //      plbinf=plbinf/props.faredet.inf;
                                //  }
                             }
                             let pax_name = pax.inf.fn + ' ' + pax.inf.ln;
                             let paxtype = 'Infant';

                             const obj = {
                                 nm: pax_name,
                                 ty: paxtype,
                                 bf: basefare + Number(onFlyPaxMkp),
                                 tax: parseFloat(tax) + Number(attGst)+Number(infTds),
                                 disc:Utilities.addPrice(parseFloat(disc),parseFloat(plbinf),"Add"),
                                 sf: parseFloat(infservicefee),
                                 gst : taGst,
                                 vat: vat,
                                 tf: totalfare+parseFloat(infservicefee)+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)+Number(infTds)-parseFloat(plbinf)
                             }
                             pax_array.push(obj);

                         }

                     }//if child, constructing fare data
                     else if (pax.ty.toUpperCase() === 'CHD') {
                         let totalfare =0
                         if(props.faredet.bktype!=="REISSUE"){
                            totalfare= onward_chd_fare_data[0].tf + return_chd_fare_data[0].tf;//total_fare
                         }else{
                            totalfare= onward_chd_fare_data[0].tf;
                         }
                         let basefare = 0;
                         if(props.faredet.bktype!=="REISSUE"){
                            basefare=onward_chd_fare_data[0].bf + return_chd_fare_data[0].bf;//base_fare
                         }else{
                            basefare= onward_chd_fare_data[0].bf;//base_fare
                         }
                         //let tax = onward_chd_fare_data[0].tax + return_chd_fare_data[0].tax;//tax
                         let tax=0
                         if(props.faredet.bktype!=="REISSUE"){
                         tax=getTaxValue(onward_chd_fare_data[0].tax)+getTaxValue(return_chd_fare_data[0].tax);
                         }else{
                         tax=getTaxValue(onward_chd_fare_data[0].tax);
                         }
                         let disc=0;
                         let odisc=Utilities.dicountCalculation(onward_chd_fare_data[0].tax);
                         let rdisc=0;
                         if(props.faredet.bktype!=="REISSUE"){
                          rdisc=Utilities.dicountCalculation(return_chd_fare_data[0].tax);
                         }
                         if(odisc!==undefined && rdisc!==undefined){
                             disc=Utilities.addPrice(parseFloat(odisc),parseFloat(rdisc),"Add");
                         }else if(odisc!==undefined){
                             disc=odisc;
                         }else if(rdisc!==undefined){
                             disc=rdisc;
                         }
                         let chdservicefee = 0;
                         let vat = 0;
                         chdService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['srcorigns'][0].serviceFee,userType,"CHD",props.faredet.chd,paxCount);
                         if(props.faredet.bktype!=="REISSUE"){
                         chdReturnService = Utilities.calculateServiceFeePax(props.faredet.origns[0]['destorigins'][0].serviceFee,userType,"CHD",props.faredet.chd,paxCount);
                         }
                         if(chdService!==undefined && chdReturnService!==undefined){
                             chdservicefee=Utilities.addPrice(parseFloat(chdService),parseFloat(chdReturnService),"Add");
                         }else if(chdService!==undefined){
                             chdservicefee=parseFloat(chdService);
                         }else if(chdReturnService!==undefined){
                             chdservicefee=parseFloat(chdReturnService);
                         }
                        //Per pax service
                         if(isNotNull(chdservicefee) && chdservicefee!==undefined && chdservicefee!==0){
                             chdservicefee=chdservicefee/props.faredet.chd;
                            }

                         let plbchd=0;
                         if(props.faredet.plb!==undefined && props.faredet.plb!==null && Object.keys(props.faredet.plb).length !== 0 &&  props.faredet.plb.chd!==undefined){
                             let chdPlb=props.faredet.plb.chd.split("/");
                             plbchd=Utilities.addPrice(chdPlb[0],chdPlb[1],"Add");
                            //  if(plbchd!==undefined && plbchd!==0){
                            //      plbchd=plbchd/props.faredet.chd;
                            //  }
                         }
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Child';

                         const obj = {
                             nm: pax_name,
                             ty: paxtype,
                             bf: basefare + Number(onFlyPaxMkp),
                             tax: parseFloat(tax) + Number(attGst)+Number(chdTds),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(plbchd),"Add"),
                             sf: parseFloat(chdservicefee),
                             gst : Number(taGst),
                             vat: vat,
                             tf: totalfare+parseFloat(chdservicefee)+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)+Number(chdTds)-parseFloat(plbchd)
                         }
                         pax_array.push(obj);
                     } else {
                         const obj = { nm: '', ty: '', bf: 0, tax: 0, sf: 0, vat: 0, tf: 0 }
                         pax_array.push(obj);
                     }
                 }
             }
             dispatch({ type: 'fareobj', payload: pax_array });
             dispatch({ type: 'grand_total', payload: grandtotal+parseFloat(adtService)+parseFloat(adtReturnService)+parseFloat(chdService)+parseFloat(chdReturnService)+parseFloat(infService)+parseFloat(infReturnService)+parseFloat(bagAdtPrice)+parseFloat(bagChdPrice)+parseFloat(mealAdtPrice)+parseFloat(mealChdPrice)+parseFloat(seatAdtPrice)+parseFloat(seatChdPrice)+parseFloat(totalOtCharge)+parseFloat(onFlyMarkup)+parseFloat(total_gst)+parseFloat(tds_total)-parseFloat(plbdisc) });
         }

     }, []);

     /**
      * @description:gettaxvalue
      * @date:11-12-2020
      * @author:Rambabu
      */
     function getTaxValue(taxarry) {
         let tax = 0
         for (let t in taxarry) {
             let tax_d = taxarry[t];
             if (isNotNull(tax_d.YQ)) {
                 tax = tax+tax_d.YQ;
             }
             if (isNotNull(tax_d.YR)) {
                 tax = tax+tax_d.YR;
             }
             if (isNotNull(tax_d.OT)) {
                 tax = tax+tax_d.OT;
             }
         }
         return tax;
     }


     return (

         isNotNull(state.fareobj) && (
             <>
             <div className='table-responsive mobileTable'>



                 <div className="header headerlist ">

                     <Row>
                         <Col xs="2">Traveller</Col>
                         <Col xs="1">Pax Type</Col>
                         {props.faredet.bktype!=="REISSUE" ? (
                         <Col >Fare</Col>
                         ):(
                         <Col>Fare Diffrence</Col>
                         )}
                         {props.faredet.bktype!=="REISSUE" ? (
                         <Col>Taxes & Fee</Col>
                         ):(
                         <Col>Taxes Diffrence</Col>
                         )}
                         {props.faredet.bktype!=="REISSUE" &&
                         <Col>Discount</Col>
                         }
                         <Col>Service Fee</Col>
                         {props.faredet.cur!==undefined && props.faredet.cur==="INR" &&
                         <Col>GST</Col>
                         }

                         {props.faredet.bktype==="REISSUE" &&
                         <>
                         <Col>Penalty</Col>
                         </>
                         }
                          {props.faredet.bktype==="REISSUE" &&
                         <>
                         <Col>Res.Value</Col>
                         </>
                         }
                         {state.bagAvail &&
                            <Col>Baggage</Col>
                         }
                        {state.mealAvail &&
                            <Col>Meal</Col>
                         }
                         {state.seatAvail &&
                            <Col>Seat</Col>
                         }
                         {state.otCharge!==0&&
                            <Col>OT Charge</Col>
                         }

                         {props.faredet.bktype!=="REISSUE" ? (
                         <Col className="text-rightt">Total</Col>
                         ):(
                         <Col  className="text-right">Total Collection</Col>
                         )}
                     </Row>

                 </div>
                 <div className="passengerList">


                 {/* confirmation page fare details onward fare and return fare adding and displaying. */}
                     {state.fareobj.map((fdet, index) => (
                         <React.Fragment key={index}>
                                  <div className='list'>
                             <Row>
                                 <Col xs="2">{fdet.nm.toUpperCase()}{(isNotNull(fdet.lead) && fdet.lead) && '(Lead)'}</Col>
                                 <Col xs="1">{fdet.ty}</Col>
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.bf} />}</Col>
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.tax} />}</Col>
                                 {props.faredet.bktype!=="REISSUE" &&
                                  <Col>{props.faredet.cur} {<FormateCurrency value={fdet.disc} />}</Col>
                                 }
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.sf} />}</Col>
                                 {props.faredet.cur==="INR" &&
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.gst} />}</Col>
                                 }
                                 {props.faredet.bktype==="REISSUE" &&
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.penalty} />}</Col>
                                 }
                                 {props.faredet.bktype==="REISSUE" &&
                                 <Col>{props.faredet.cur} {<FormateCurrency value={fdet.resVal} />}</Col>
                                 }
                                 {state.bagAvail &&
                                  <Col>{props.faredet.cur} {<FormateCurrency value={fdet.bagprice} />}</Col>
                                 }
                                 {state.mealAvail &&
                                  <Col>{props.faredet.cur} {<FormateCurrency value={fdet.mealprice} />}</Col>
                                 }
                                  {state.seatAvail &&
                                  <Col>{props.faredet.cur} {<FormateCurrency value={fdet.seatprice} />}</Col>
                                 }
                                 {state.otCharge!==0 &&
                                    <Col>{props.faredet.cur} {<FormateCurrency value={state.otCharge} />}</Col>
                                 }
                                 <Col  className="text-rightt">{props.faredet.cur} {<FormateCurrency value={fdet.tf} />}</Col>
                                 </Row>
                                 </div>
                         </React.Fragment>
                     ))}
<div className='gTotaldiv'>
                     <div className="total d-flex justify-content-end">
                         <span>Grand Total : </span> <div className="pricetag"><em>{props.faredet.cur}</em> <strong>{<FormateCurrency value={state.grand_total} />}</strong></div>
                     </div>
                     </div>
                 </div>
                 </div>
             </>))
 }


 export default FareDetails;
