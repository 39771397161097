
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import MainMenu from '../../../../components/common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import log from "loglevel";
import { creation, getSuppliersData, update ,talist } from './operations';
import Select from 'react-dropdown-select';
import RegionType from '../../common/RegionType';
import SalesChannel from "../../common/SalesChannel"
let supp_json = require('../../../masterData/Suppliers.json');

/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Supplier Configuration
 * @date : 13-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    enableReset:true, selBranch:[], branchList:[],selRegion:[] ,region_type:"",region_type_edit:"",company_id:0, showMessage: false, loadCategory: false, loadEdit: false, loadSubmit: false, id: 0,compList:[],suppliers:supp_json,company_name:"", htlsuppliers:[{value:"0",label:"Expedia"}],inssuppliers:[{value:"0",label:"QIC"}],
    res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false,supNames:[],companyNames:[],htlSupNames:[],insSupNames:[], showSave: true,isItineraryLoading: false,edit_salesChanel:"",salesChannel:"",showBranch: true,


}



// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, company_id:0, showMessage: false, loadCategory: false, loadEdit: false, loadSubmit: false, id: 0,compList:[],suppliers:supp_json,
                res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false, isItineraryLoading:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Supplier Configuration
 */
function SupplierConfig() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState([]);
    const [value1, setValue1] = useState([]);
    const [branchData, setBranchData] = useState(false);
    const [regionType,setRegionType] = useState("")

    useEffect(() => {
        dispatch({ type: 'isLoadingMore', payload: true });
        dispatch({ type: 'enbaleUpdate', payload: false })
        // talist().then((res) => {
        //     dispatch({ type: 'compList', payload: res })
        //   });
          getSuppliersData({"is_admin":context.logindata.is_admin, "region_type":context.logindata.region_type}).then(response => {
            if (response) {
                convertSupData(response);
            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }, [context.logindata.region_type]);
    useEffect(() => {
        if(context.logindata!==undefined && state.salesChannel === "B2C" && state.region_type){
         
        let is_admin=context.logindata.utype==="att_user"?1:0
         let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
          AttCommonSerivce.att_companies(pyload).then(response => {
            if (response.data.suc && response.data.res.status === 200) {
                let companyList = response.data.res.data.filter((obj)=>obj.sales_channel===state.salesChannel)
              dispatch({ type: "compList", payload: companyList });
    
            }
          })
        }else if(state.salesChannel === "B2B" && state.selBranch.length ===0){
            dispatch({ type: "compList", payload: [] });
        }
      }, [context.logindata,state.salesChannel,state.region_type]);
      useEffect(() => {
        if (state.salesChannel === "B2C") {
          dispatch({ type: "showBranch", payload: false });
          if (state.enableReset){
            dispatch({ type: "selBranch", payload: [] });
        }

        } else {
          dispatch({ type: "showBranch", payload: true });
        }
       if (state.enableReset){
        dispatch({ type: "region_type", payload: "" })
        dispatch({ type: "selBranch", payload:[] })
        dispatch({ type: 'companyNames', payload: [] })
    }
      }, [state.salesChannel]);
    const getInsSupName = (code)=>{
        if (code === "0"){
            return "QIC"
        }
    }

    const getSupplierName=(code)=>{
            if(code!==undefined){
            if(!code.includes(",")){
                if(code==="0"){
                return "Amadeus"
                }else if(code==="1"){
                return "Travel Fusion"
                }else if(code==="2"){
                return "Air Arabia"
                }else if(code==="3"){
                return "Jazeera"
                }else if(code==="4"){
                return "Indigo"
                }else if(code==="5"){
                return "Flydubai"
                }else if(code==="6"){
                return "Salam Air"
                }else if(code==="7"){
                return "Riya Travel"
                }
               }else{
                   let supary=code.split(",");
                   var supname="";
                   for(let val of supary){
                    let supnames=state.suppliers.filter((obj)=>obj.value===val);
                    if(supnames.length!==0){
                        if(supname===""){
                            supname=supnames[0].label;
                        }else{
                            supname=supname+","+supnames[0].label;
                        }
                    }
                   }
                 return supname;
               }
        }
    }
    const getHtlSupplierName=(code)=>{
            if(code!==undefined && code!== null){
            if(!code.includes(",")){
                if(code==="0"){
                return "Expedia";
                }
                if(code==="1"){
                return "Otilla";
                }
               }else{
                   let htlsupary=code.split(",");
                   var htlsupname="";
                   for(let val of htlsupary){
                    let htlsupnames=state.htlsuppliers.filter((obj)=>obj.value===val);
                    if(htlsupnames.length!==0){
                        if(htlsupname===""){
                            htlsupname=htlsupnames[0].label;
                        }else{
                            htlsupname=htlsupname+","+htlsupnames[0].label;
                        }
                    }
                   }
                 return htlsupname;
               }
        }
    }

    //Converting all suppliers
    const convertSupData = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response){
                    if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){
                    data.push({ticket_enable:val.ticket_enable==1?"Yes":"No", region_type:val.region_type,branch:val.branch_name,cname: val.company_name,fsupplier:getSupplierName(val.supplier),hsupplier:getHtlSupplierName(val.htl_supplier),inssupplier:getInsSupName(val.ins_supplier), options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editSupplier(val, "view")}></a> {<a className="ezyIcon icon-edit" onClick={editSupplier(val, "edit")}></a>} </span> </> })
                }
            }
                console.log('res++++++++++++++++-------', JSON.stringify(data))
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'isLoadingMore', payload: false });
    }

    //redirect to admin page
    const handleCancel = (e) => {
       window.location="/ezytrip/administration/supplierConfig";
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    //Create Categeory
    const handleSuppCreation = () => {
        dispatch({ type: "companyError", payload: false })
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "noError", payload: false })
        if(state.companyNames.length!==0 && (state.supNames.length!==0 || state.insSupNames.length!==0 || state.htlSupNames.length!==0)){
            if(validateDuplicateSup()){
            let finalComp = [];
                    state.companyNames.map((obj) => {
                        const comp = {
                        id: obj.value,
                        name: obj.label,
                        };
                        finalComp.push(comp);
                    });
                    let finalSup = [];
                     state.supNames.map((obj) => {
                        finalSup.push(obj.value);
                    });
                        let finalHtlSup = [];
                        state.htlSupNames.map((obj) => {
                        finalHtlSup.push(obj.value);
                       });
                       let finalInsSup = [];
                       state.insSupNames.map((obj)=>{
                           finalInsSup.push(obj.value);
                       })
                    const payload={
                         "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
                         "branch_id":state.selBranch.length!=0?state.selBranch[0].value:"",
                         "branch_name":state.selBranch.length!=0?state.selBranch[0].label:"",
                         "sup": finalSup.toString(),
                         "hsup": finalHtlSup.toString(),
                         "crby":context.logindata.uid,
                         "inssup":finalInsSup.toString(),
                         "comp":finalComp,
                         "sales_channel": state.salesChannel,
                         "ticket_enable":state.ticket_enable
                    }
                    dispatch({ type: "isItineraryLoading", payload: true })
                    creation(payload).then((addRes) => {
                        if (addRes.suc){
                            dispatch({ type: 'selBranch', payload: [] });
                            dispatch({ type: 'companyNames', payload: [] });
                            dispatch({ type: 'supNames', payload: [] });
                            dispatch({ type: 'htlSupNames', payload: [] });
                            dispatch({ type: 'insSupNames', payload: [] });
                            getSuppliersData({"is_admin":context.logindata.is_admin, "region_type":context.logindata.region_type}).then(response => {
                                if (response && response.res !== 0) {
                                    convertSupData(response);
                                }
                                dispatch({ type: 'isLoadingMore', payload: false });
                            })
                            talist().then((res) => {
                                let companyList = res.filter((obj)=>obj.sales_channel===state.salesChannel)
                                dispatch({ type: 'compList', payload: companyList })
                              });
                            dispatch({ type: "suppliers", payload: supp_json })
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Supplier saved successfully' });
                            dispatch({ type: 'notiVarient', payload: 'success' });
                            dispatch({ type: 'showSave', payload: true });
                            hidemessage()
                            window.location = window.location.pathname;
                        } else {
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Supplier not saved..' });
                            dispatch({ type: 'notiVarient', payload: 'danger' });
                            hidemessage()
                        }
                    });
            }else{
                dispatch({ type: 'isItineraryLoading', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true });
                dispatch({ type: 'notiMessage', payload: 'Already created Supplier with the selected Company(s)'});
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
            }
            }else{
                dispatch({ type: 'isItineraryLoading', payload: false });
           if(state.supNames.length===0 && state.htlSupNames.length===0 && state.insSupNames.length===0){
                dispatch({ type: 'notiMessageShow', payload: true });
                dispatch({ type: 'notiMessage', payload: 'Please select atleast one Supplier'});
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
            }else if(state.region_type===""){
                dispatch({ type: 'loadCategory', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Please Select Region Type' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }else if(state.selBranch.length===0){
                dispatch({ type: 'loadCategory', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Please Select Branch' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
              }else  if(state.companyNames.length===0){
                dispatch({ type: "companyError", payload: true })
            }
        }
    }

    //Edit Categeory
    const editSupplier = (obj, type) => () => {
        dispatch({ type: 'enableView', payload: false })
        dispatch({ type: 'enableReset', payload: false })
        dispatch({ type: 'ticket_enable', payload: obj.ticket_enable==1?true:false });
        dispatch({ type: 'isAmadeus', payload: obj.ticket_enable });
        dispatch({ type: 'enableB2C', payload: obj.ticket_enable});
        dispatch({ type: 'region_type_edit', payload: obj.region_type });
        dispatch({ type: 'edit_salesChanel', payload: obj.sales_channel });
        dispatch({ type: 'company_id', payload: obj.company_id });
        dispatch({ type: 'selBranch', payload: [{ "value": obj.branch_id, "label": obj.branch_name }] })
        dispatch({ type: 'companyNames', payload: [{ "value": obj.company_id, "label": obj.company_name }] })
        if(obj.ins_supplier ==="0"){
            dispatch({type:"insSupNames",payload: [{value:"0",label:"QIC"}]})
        }

        if(obj.supplier.includes(",")){
            let sups=obj.supplier.split(",");
            var supname=[];
            for(let val of sups){
                let supnames=state.suppliers.filter((obj)=>obj.value===val);
                if(supnames.length!==0){
                    supname.push({ "value": supnames[0].value, "label": supnames[0].label });
                }
               }
               dispatch({ type: 'supNames', payload: supname });
        }else if(obj.supplier==="0"){
        dispatch({ type: 'supNames', payload: [{ "value": "0", "label": "Amadeus" }] });
        }else if(obj.supplier==="1"){
        dispatch({ type: 'supNames', payload: [{ "value": "1", "label": "Travel Fusion" }] });
        }else if(obj.supplier==="2"){
        dispatch({ type: 'supNames', payload: [{ "value": "2", "label": "Air Arabia" }] });
        }else if(obj.supplier==="3"){
        dispatch({ type: 'supNames', payload: [{ "value": "3", "label": "Jazeera" }] });
        }else if(obj.supplier==="4"){
        dispatch({ type: 'supNames', payload: [{ "value": "4", "label": "Indigo" }] });
        }else if(obj.supplier==="5"){
        dispatch({ type: 'supNames', payload: [{ "value": "5", "label": "Flydubai" }] });
        }else if(obj.supplier==="6"){
        dispatch({ type: 'supNames', payload: [{"value": "6", "label": "Salam Air"}] });
        }else if(obj.supplier==="7"){
        dispatch({ type: 'supNames', payload: [{"value": "7", "label": "Riya Travel"}] });
        }
        if(obj.htl_supplier!=="" && obj.htl_supplier!==undefined){
            if(obj.htl_supplier.includes(",")){
                let htlsups=obj.htl_supplier.split(",");
                var htlsupname=[];
                for(let val of htlsups){
                    let htlsupnames=state.htlsuppliers.filter((obj)=>obj.value===val);
                    if(htlsupnames.length!==0){
                        htlsupname.push({ "value": htlsupnames[0].value, "label": htlsupnames[0].label });
                    }
                   }
                }
                   dispatch({ type: 'htlSupNames', payload: htlsupname });
            if(obj.htl_supplier==="0"){
            dispatch({ type: 'htlSupNames', payload: [{ "value": "0", "label": "Expedia" }] })
        }
        else if(obj.htl_supplier==="1"){
            dispatch({ type: 'htlSupNames', payload: [{ "value": "1", "label": "Otilla" }] })
        }
    }
        if (type === "view"){
            dispatch({ type: 'enableView', payload: true });
            dispatch({ type: 'enbaleUpdate', payload: false });
            dispatch({ type: 'showSave', payload: false });
        }else{
            dispatch({ type: 'enableView', payload: false });
            dispatch({ type: 'enbaleUpdate', payload: true });
            dispatch({ type: 'showSave', payload: false });
        }
    }

    //Update Supplier
    const updateSupplier = (e) => {
        if(state.supNames.length!=0 || state.htlSupNames.length!=0 || state.insSupNames.length!=0){
        dispatch({ type: 'showMessage', payload: false });
        dispatch({ type: 'loadCategory', payload: true });
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "noError", payload: false })
        let finalSup = [];
        if(state.supNames.length!==0){
            state.supNames.map((obj) => {
            finalSup.push(obj.value);
        });}

        let finalHtlSup = [];
        if(state.htlSupNames.length!==0){
        state.htlSupNames.map((obj) => {
            finalHtlSup.push(obj.value);
        });
        }
        let finalInsSup = [];
        if(state.insSupNames.length!==0){
        state.insSupNames.map((obj)=>{
            finalInsSup.push(obj.value);
        })}
        const payload={
             "comp":state.company_id,
             "sup": finalSup.toString(),
             "hsup": finalHtlSup.toString(),
             "upby":context.logindata.uid,
             "inssup":finalInsSup.toString(),
             "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
             "branch_id":state.selBranch.length!=0?state.selBranch[0].value:"",
             "branch_name":state.selBranch.length!=0?state.selBranch[0].label:"",
             "ticket_enable":state.ticket_enable
        }
        dispatch({ type: 'isItineraryLoading', payload: true });
        update(payload).then(response => {
            if (response.suc) {
                dispatch({ type: 'companyNames', payload: [] });
                dispatch({ type: 'insSupNames', payload: [] });
                dispatch({ type: 'supNames', payload: [] });
                dispatch({ type: 'loadCategory', payload: false });
                talist().then((res) => {
                    let companyList = res.filter((obj)=>obj.sales_channel===state.salesChannel)
                    dispatch({ type: 'compList', payload: companyList })
                });
                dispatch({ type: 'enbaleUpdate', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Supplier updated successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                dispatch({ type: 'showSave', payload: true });
                hidemessage()
                return window.location = window.location.pathname;
            } else {
                dispatch({ type: 'isItineraryLoading', payload: false });
                dispatch({ type: 'loadCategory', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Supplier has not updated..' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
          })
         } else {
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one Supplier'});
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
        }
      }
    //Cheking the duplicate Supplier
    const validateDuplicateSup=()=>{
        let checkSup=false;
        let availCompany=[];
          for(let comp of state.companyNames){
            availCompany=state.res.filter((obj)=> obj.cname===comp.label);
            if(availCompany.length!==0){
                checkSup=true;
                break;
            }
          }
          if(checkSup){
           return false;
          }else{
           return true;
          }
        }

    const columns = [
    {
        dataField: 'region_type',
        text: 'Region Type',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'branch',
        text: 'Branch name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'cname',
        text: 'Company Name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'fsupplier',
        text: 'Flight Supplier',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'hsupplier',
        text: 'Hotel Supplier',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'inssupplier',
        text: 'INS Supplier',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'ticket_enable',
        text: 'Ticket Enable',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
    }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];
    const handleProfileType = (schanel) => {
        if (state.enableReset){
        dispatch({type:"companyNames", payload:[]})
        dispatch({type:"selBranch", payload:[]})
    }
        if (schanel) {
          dispatch({ type: "salesChannel", payload: schanel });
          if (schanel === "B2C") {
            dispatch({ type: "enableB2C", payload: true });
          } else {
            dispatch({ type: "enableB2C", payload: false });
          }
        }
      }
    const handleRegionType = (e) => {
        dispatch({ type: "branchList", payload: e });
        if (state.enableReset){
            dispatch({ type: 'selBranch', payload: [] });
        dispatch({ type: 'companyNames', payload: [] });
    }
    }
    
    const updateRegion=(region)=>{
        dispatch({ type: "region_type", payload: region });
    }
    
    const handleFsup = (e) => {
        state.supNames=e
            const isenable= state.supNames.some(obj => obj.label=="Amadeus" )
            dispatch({type:"isAmadeus", payload:isenable})
    }

    const handleTicketEnable = ()=> {
       if (state.ticket_enable==false) {
            dispatch({type:"ticket_enable", payload: true})
        } else {
            dispatch({type:"ticket_enable", payload: false})
        }
    }
    const handleBranchChange = (e)=> {
        dispatch({ type: 'companyNames', payload: [] });
        if(e!==undefined && e.length!==0){
            dispatch({ type: 'selBranch', payload: e});
            e && e[0] && e[0].value && AttCommonSerivce.getCompanyList(e.length!=0?e[0].value:"")
            .then((response) => {
                if (response.data.suc) {
                    let companyList = response.data.res.filter((obj)=>obj.sales_channel===state.salesChannel)
                    dispatch({ type: 'compList', payload: companyList });
                }
              })
              .catch(function (error) {
                log.error("Exception occured in getCompanyList function---" + error);
              });
            }
          };


    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="Supplier Configuration" />
            <div className="container">
                <div className="cardMainlayout pt-0">
                <OrganizationMenu active="Supplier Config" />
                    <div className="contentArea">
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                  {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
                        <Form noValidate validated={validated} id="formId">
                        <Form.Row>
                        <SalesChannel sendSalesChanel={handleProfileType} edit_sales={state.edit_salesChanel}/>

                            {context.logindata.utype !== "ta_user" && <><RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={state.region_type_edit} />
                                </>}
                                {state.showBranch  && (
                                <Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                                <Select
                                options={state.branchList}
                                values={state.selBranch}
                                searchable={false}
                                disabled={state.enbaleUpdate}
                                onChange={(e)=>handleBranchChange(e)}
                                />
                                {state.supplierError && (
                                 <p className="vError">Please Select branches</p>
                                )}
                                <Form.Label>Branch<sup>*</sup></Form.Label>
                            </Form.Group>
                                )}
                            <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin"
                            controlId="selectedValue"
                            >
                            <Select
                            multi
                            options={state.compList}
                            values={state.companyNames}
                            //onChange={(value) => (setValue(value), setCompany(value))}
                            onChange={(value) => (dispatch({ type: 'companyNames', payload: value }))}
                            disabled={state.enbaleUpdate || state.enableView}
                            />
                            <Form.Label>Company<sup>*</sup></Form.Label>
                            {state.companyError && (
                                <p className="vError">Please Select a Company</p>
                            )}
                            </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="selectedValue"
                                >
                                    <Select
                                    multi
                                    options={state.suppliers}
                                    values={state.supNames}
                                    disabled={state.enableView}
                                    onChange={(value) => handleFsup(value)}
                                    />
                                    <Form.Label>Flight Supplier</Form.Label>
                                </Form.Group>
                                <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="selectedValue"
                                >
                                    <Select
                                    multi
                                    options={state.htlsuppliers}
                                    values={state.htlSupNames}
                                    disabled={state.enableView}
                                    onChange={(value) => {
                                        (dispatch({ type: 'htlSupNames', payload: value }))
                                    }}
                                    />
                                    <Form.Label>Hotel Supplier</Form.Label>
                                </Form.Group>
                                <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="selectedValue"
                                >
                                    <Select
                                    multi
                                    options={state.inssuppliers}
                                    values={state.insSupNames}
                                    disabled={state.enableView}
                                    onChange={(value) => {
                                        (dispatch({ type: 'insSupNames', payload: value }))
                                        }}/>
                                    <Form.Label>INS Supplier</Form.Label>
                                </Form.Group>
                         {state.enableB2C && state.isAmadeus &&
                          <Form.Group as={Col} className="topSwitch" controlId="act">
                           <Form.Check
                            type="checkbox"
                            id="ticket"
                            label="Ticket Enable"
                            custom
                            checked={state.ticket_enable} 
                            value={state.ticket_enable}
                            // disabled={!state.enableView}
                            onChange={handleTicketEnable}
                            />
                             </Form.Group>}
                            </Form.Row>
                        </Form>
                        <div className="text-right buttonGrop pb-0">
                            {context.logindata.roles !== undefined &&
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={handleCancel}
                                    type="submit"
                                    isDisabled={state.enableView}
                                >Reset</Button>
                            }
                            {!state.enbaleUpdate && state.showSave &&
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={handleSuppCreation}
                                    isDisabled={state.enableView}
                                    loading={state.loadCategory}
                                    type="submit"
                                > Save</Button>
                            }
                            {state.enbaleUpdate && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateSupplier}
                                    loading={state.loadCategory}
                                    isDisabled={state.enableView}
                                    type="submit"
                                > Update</Button>
                            )}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>

                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl suplierConfig"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SupplierConfig