import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button'

/**
 * @description:This function will work for Payment Failed pop Up.
 * @author: Pravas
 * @param {*}
 * @function NoRecordFound
 * @date : 01-09-2020
 */
function NoRecordFound(props) {
  const gotoResults=()=>{
    return window.location="/ezytrip/dashboard";
  }
  return (
    <>

            <div className="norecord">
              <strong>No Booking's Yet!</strong>
              <p>You don't have any booking history.</p>
          {/* <div className="buttons mt-2">
          <Button onClick={() => {
                gotoResults();
              }}
                size="md"
                className="refresh"
                variant="primary"
              >BOOK NOW</Button>

          </div> */}

            </div>


    </>
  )
}
export default NoRecordFound