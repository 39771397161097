import React, { Component, useEffect, useState,useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../App";
import Header from './Header';


function ReportMenuFun(props) {
  const [context, setContext] = useContext(Context);
  const { state } = props || {}
  //const [mainNav, setmainNav,] = useState(false);

  return (
    /*
This Main Menu component expecing the following Props:
- active = "PageName"
Based on that link will be active
*/
    <>

<Header />
<section className="menyLayout">

    <div className="container">
      <div className="MainMenu">
        <ul className="d-flex">
          { context.logindata !== "" && context.logindata.roles.includes("F_R_P_T") &&context.logindata.roles.includes("D_F_R_T") &&
          <li className={state.active === "Air Services" ? 'active':''}>
            <Link to="/ezytrip/administration/flightreport" title="Flight Report">
              <span className="ezyIcon icon-flight">Flight Report</span>
            </Link>
          </li>
          }
          { context.logindata !== "" && context.logindata.roles.includes("H_R_P_T") &&context.logindata.roles.includes("D_H_R_T") &&
          <li className={state.active === "Hotel Services" ? 'active':''}>
            <Link to="/ezytrip/administration/hotelreport" title="Hotel Report">
              <span className="ezyIcon icon-hotel">Hotel Report</span>
            </Link>
          </li>
          }
          {context.logindata !== "" && context.logindata.roles.split(',').includes("I_R_P")  &&
          <li className={state.active === "Insurance Report" ? 'active':''}>
            <Link to="/ezytrip/administration/insreport" title="Insurance Report">
              <span className="ezyIcon icon-policy">Insurance Report</span>
            </Link>
          </li>
          }
          { context.logindata !== "" && context.logindata.roles.includes("M_Y_R") &&
          <li className={state.active === "My Reports" ? 'active':''}>
            <Link to="/ezytrip/administration/myreport" title="My Reports">
              <span className="ezyIcon icon-report">My Reports</span>
            </Link>
          </li>
          }
          {context.logindata !== "" && (context.logindata.roles.includes("T_R_P") && context.logindata.roles.includes("R_C_P")) &&
          <li className={state.active === "Transaction" ? 'active':''}>
            <Link to="/ezytrip/administration/transaction" title="Transaction">
              <span className="ezyIcon icon-report">Daily Transactions</span>
            </Link>
          </li>
          }
          {context.logindata !== "" && (context.logindata.roles.includes("T_R_P") && !context.logindata.roles.includes("R_C_P")) &&
          <li className={state.active === "Transaction" ? 'active':''}>
            <Link to="/ezytrip/administration/transaction" title="Transaction">
              <span className="ezyIcon icon-report">Daily Transactions</span>
            </Link>
          </li>
          }
          {context.logindata !== "" && (!context.logindata.roles.includes("T_R_P") && context.logindata.roles.includes("R_C_P")) &&
          <li className={state.active === "Receipt" ? 'active':''}>
            <Link to="/ezytrip/administration/receipt" title="Receipt">
              <span className="ezyIcon icon-report">Daily Transactions</span>
            </Link>
          </li>
          }
        </ul>
      </div>
    </div>
    </section>
    </>
  );
};



class ReportMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active

    };
  }

  render() {
    return <ReportMenuFun state={this.state} />
  }
}

export default ReportMenu;

