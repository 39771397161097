import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL

// const API_URL = "http://localhost:3005"
/**
 * @description: Integrated all CRUD Operations
 * @author: Lakshmi
 * @param {*} req 
 */
class CustomFieldsService {
    
    async getCustomsData(data) {
        return await axios.post(`${API_URL}/att/custom/search`,data);
    }

    async saveCustom(data) {
        return await axios.post(`${API_URL}/att/custom/create`, data)
    }

    async updateCustom(data) {
        return await axios.put(`${API_URL}/att/custom/update/${data.eid}`,data)
    }

    async deleteCustom(id) {
        return await axios.delete(`${API_URL}/att/custom/delete/${id}`)
    }

    async editCustom(id) {
        return await axios.get(`${API_URL}/att/custom/edit/${id}`)
    }
}

export default new CustomFieldsService()