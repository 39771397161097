
import React, { useState } from 'react';
import PNRImport from '../bookingFlows/flight/operations/PNRImport';
import PNRSync from '../bookingFlows/flight/operations/PNRSync';
import ReissueRefund from '../bookingFlows/flight/operations/ReissueRefund';
import Tabs from '../common/tabs/Tabs';
function OperationSearch(props) {
    //const [state, setState] = useState({})

    const [tablist, setTablist] = useState({
        active: 'PNRImport'
    });

    const importPnr = (data) => {
        props.sendPnrStats(data);
    }

    const content = {
        PNRImport: <PNRImport sendImport={importPnr} />,
        PNRSync: <PNRSync />,
        ReissueRefund: <ReissueRefund />,
        QueuedPNR: <ReissueRefund />
    };

    return (
        <>
            <div className="searchTab operationFrom ">
                <div className="tabLink">
                    <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                        <span key="PNRImport">  </span>
                        <span key="PNRSync">PNR Sync</span>
                        <span key="ReissueRefund">Reissue & Refund</span>
                        <span key="QueuedPNR">Queued PNR</span>
                    </Tabs>
                    <div className="tabContent">
                        {content[tablist.active]}
                    </div>
                    {/* <span className="noteQpnr"><span>Note :</span> Please Queue your PNR to DOHQR28IH</span> */}
                </div>
            </div>
        </>
    )
}


export default OperationSearch;
