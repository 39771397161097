
import React, { useState, useEffect, useRef, useReducer, useContext, useCallback } from 'react';
import { Button, Col, Row, Modal, Alert,Form } from "react-bootstrap";
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import 'react-input-range/lib/css/index.css';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Sticky from '../../../common/sticky/Sticky'
import ProfileDetails from '../../flight/review/ProfileDetails'
import GustDetails from '../common/gustDetails/GustDetails'
import { emailValidation, mobileNumberValidation, characterValidation } from '../../../commonUtils/FormValidations'
import BookingSummary from '../common/result/BookingSummary';
import HotelPayment from '../payment/HotelPayment.jsx';
import { Context } from "../../../../../App";
import Utilities from '../../../commonUtils/Utilities'
import CustomInformaion from '../../flight/review/CustomInformaion';
import { saveHotelBooking, getCrediLimit, savePriceCheck,saveOtillaPriceCheck} from './operations';
export const PAY_DATA = "payData";
export const PAY_REQ = "payRequest";
export const PAY_RESP = "payResp";
export const PRODUCT_TYPE = "productType";
export const GET_PAY_LINK= "PayLink";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function

/**
 * Initial State Declaration
 */
const initialState = {
  prof: "", travelsList: [], roomDetails: [], bdata: "", showPop: false, prepersist: false, htlPriceCheckResp: '', diffamt: 0, pcomName: "", showNote: "", htlFail: false, bookfail: false, custms: [], showCustoms: true, priceRequst: "", allpax: false,bookingType:"DIRECT",freeCancelDate:null,enableOnhold:false,
  firstName:"",lastName:"",pcode:"",phoneNum:"",email:"",title:"",cnfEmail:"",otilla_resp:"",ot_avail_resp:"",acceptOtilla:false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
function HotelGuestDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [payment, setPayment] = useState({ doPayment: false, showContinueButton: true });
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [request, setRequest] = useState(props.location);
  const [enableCHDOtila, setEnableCHDOtila] = useState(false);
  const [isConinueLoading, setIsConinueLoading] = useState(false);
  const moment = require('moment');
  const handleClosee = () => {
    dispatch({ type: "showRemarks", payload: false })
    };

  useEffect(() => {
    window.sessionStorage.removeItem(PAY_DATA);
    window.sessionStorage.removeItem(PAY_REQ);
    window.sessionStorage.removeItem(PAY_RESP);
    window.sessionStorage.removeItem(PRODUCT_TYPE);
    if (request.state !== undefined && request.state.req !== undefined) {
      dispatch({ type: 'bookingType', payload: request.state.req.bktype })
      if(request.state.req.bktype==="DIRECT"){
        savePriceCheckRequest();
        dispatch({ type: 'enableImprtFlow', payload: false })
        let dates=[];
        if(request.state.req.rooms.length > 1){
          for(let room of request.state.req.rooms){
            if(room.rmpr[0].refundable && room.rmpr[0].freeCancelDate!==undefined && room.rmpr[0].freeCancelDate!==""){
              dates.push(new Date(room.rmpr[0].freeCancelDate))
            }
            if(request.state.req.rooms[0].sup==1){
              setEnableCHDOtila(true)
            } else {
              setEnableCHDOtila(false)
            }
        }
        var minimumDate=new Date(Math.min.apply(null, dates));
        if(request.state.req.rooms[0].rmpr[0].freeCancelDate!==undefined &&  request.state.req.rooms[0].rmpr[0].freeCancelDate!=="" ){
          dispatch({ type: 'freeCancelDate', payload: request.state.req.rooms[0].rmpr[0].freeCancelDate })
          if(dates.length===request.state.req.rooms.length){
            dispatch({ type: 'enableOnhold', payload: true })
          }
        }
        }else{
          if(request.state.req.rooms[0].rmpr[0]!==undefined && request.state.req.rooms[0].rmpr[0].freeCancelDate!==""){
            dispatch({ type: 'freeCancelDate', payload: request.state.req.rooms[0].rmpr[0].freeCancelDate })
            dispatch({ type: 'enableOnhold', payload: true })
            if(request.state.req.rooms[0].sup==1){
              setEnableCHDOtila(true)
            } else {
              setEnableCHDOtila(false)
            }
          }
        }
      }else{
        dispatch({ type: 'leadPaxObj', payload: request.state.req.prof })
        dispatch({ type: 'enableImprtFlow', payload: true })
        dispatch({ type: 'enableHold', payload: false })
        checkCreditLimit(request.state.req, "credit");
      }
    }
    window.scrollTo(0, 0)
  }, [])

//   useEffect(() => {
//     let check=sessionStorage.getItem(CHECK_BOOKING);
//     let bookingId=sessionStorage.getItem(BOOKING_REF_NO);
//     if (check!==null && check && bookingId!==null && bookingId!==undefined){
//         AuthenticationService.updateStatus({"booking_id":bookingId}).then(response => {
//             sessionStorage.removeItem(CHECK_BOOKING);
//             sessionStorage.removeItem(BOOKING_REF_NO);
//         })
//     }
// }, []);


  //Call service to save the Price request for the selected rooms
  const savePriceCheckRequest = () => {
    let otilacityid = localStorage.getItem("HTL_OTILACITYID")
    if (request.state.req !== undefined) {
      request.state.req.cityId = otilacityid
      if(request.state.req.hs===0){//For Expedia
        savePriceCheck(request.state.req).then((response) => {
          if (response.suc) {
          }
        });
      }else{//For Otilla
        dispatch({ type: 'showRemarks', payload: false })
        saveOtillaPriceCheck(request.state.req).then((response) => {
          if (response.suc && response.otillaPreBook!==undefined) {
            dispatch({ type: 'ot_avail_resp', payload: response.otillaPreBook.otillaPrebookResp });
          }
        });
      }

    } else {
      dispatch({ type: 'showPop', payload: true });
      dispatch({ type: 'htlFail', payload: true });
    }
  }

  const validatePax = () => {
    if(state.bookingType!=="ONHOLD"){
    if (state.roomDetails && state.roomDetails.length !== 0) {
      var paxType = "";
      var count = 1;
      let paxName = "";
      for (let eachRoom of state.roomDetails) {
        if (eachRoom.pax && eachRoom.pax.length !== 0) {
          for (let val of eachRoom.pax) {
            if (val.ty === "ADT") {
              paxType = "Adult";
            } else {
              paxType = "Child";
            }
            if (val.tl === "") {
              return validateTitle(val.sno, paxType,eachRoom.rmindex);
            }
            if (val.fn === "") {
              return validateName(false, "first name", val.sno, paxType,eachRoom.rmindex)
            } else {
              val.fn = val.fn.trim()
              if (!characterValidation(val.fn)) {
                return validateName(true, "first name", val.sno, paxType,eachRoom.rmindex)
              }
            }
            if (val.ln === "") {
              return validateName(false, "last name", val.sno, paxType,eachRoom.rmindex)
            } else {
              val.ln = val.ln.trim()
              if (!characterValidation(val.ln)) {
                return validateName(true, "last name", val.sno, paxType,eachRoom.rmindex)
              }
            }
            if (val.fn !== "" && val.fn !== undefined && val.fn.length === 1) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'First name should be minimum two characters ' + paxType + "" + val.sno +" for Room " + eachRoom.rmindex })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Last name should be minimum two characters ' + paxType + "" + val.sno  +" for Room " + eachRoom.rmindex})
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }

            if (val.fn !== "" && val.fn !== undefined && val.ln !== "" && val.ln !== undefined && val.fn === val.ln) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'First name and Last name cant be same for ' + paxType + "" + val.sno  +" for Room " + eachRoom.rmindex })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            //Validate Duplicate Pax
            if (val.fn !== "" && val.fn !== undefined && val.ln !== "" && val.ln !== undefined) {
              paxName = val.fn + " " + val.ln;
            }
            if (paxName !== "") {
              for (let room of state.roomDetails) {
                if (room.rmindex !== eachRoom.rmindex) {
                  for (let pax of room.pax) {
                    let name = pax.fn + " " + pax.ln;
                    if (paxName === name) {
                      dispatch({ type: 'notiMessageShow', payload: true })
                      dispatch({ type: 'notiMessage', payload: 'Lead Guest Names cannot be the same' })
                      dispatch({ type: 'notiVarient', payload: 'danger' })
                      hidemessage();
                      return false;
                    }
                  }
                }
              }
            }

            //Lead pax validations
            if (val.ty === "ADT" && val.lead && eachRoom.rmindex === 1) {
              if (val.phn !== undefined) {
                if(val.phn !== null) {
                  val.phn = val.phn.trim()
                }
                if (val.phn === "") {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Please enter phone number for Room One Lead pax' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                } else if (!mobileNumberValidation(val.phn)) {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Please enter valid phone number for Room One Lead pax' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                }
              }
              if (val.em !== undefined) {
                if(val.em !== "") {
                  val.em = val.em.trim()
                }
                if (val.em === "") {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Please enter email id for Room One Lead pax' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                } else if (!emailValidation(val.em)) {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Please enter valid email id for Room One Lead pax' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                }
              }
              if (val.phc !== undefined) {
                if (val.phc === "") {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Please select Phone Code for Room One Lead pax' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                }
              }
            }
          }

        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter guest details' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.custms !== undefined) {
          for (let val of state.custms) {
            if (val.cval === "") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter Additional info mandatory fileds ' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
          }
        }
        count = count + 1;
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter guest details' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    }
    return true;
  }

  const validateTitle = (count, type,rm) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select title for guest ' + type + "" + count + " for Room "+ rm })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
  const validateName = (name, type, count, pax,rm) => {
    if (!name) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter ' + type + ' for guest ' + pax + "" + count + " for Room "+ rm })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid ' + type + ' for guest ' + pax + "" + count  + " for Room "+ rm})
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 3000);
  }
  //Callig Save on click of Continue
  const continueBook = async (e) => {
    if (validatePax()) {
      if(request.state.req.hs===0 || (state.acceptOtilla && (request.state.req.hs===1 || request.state.req.hs==="1"))){
      //create prebook request
      setIsConinueLoading(true)
      let regionType="";
      if(context.logindata!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type!==""){
        regionType=context.logindata.region_type;
      }else{
        regionType=context.logindata.cur==="QAR"?"Qatar":"India";
      }
      let proReq="";
      if(state.prof===""){
        proReq = {
          "tl": state.title,
          "fn": state.firstName,
          "ln": state.lastName,
          "phc": state.pcode,
          "phn": state.phoneNum,
          "em": state.email,
          "cem": state.cnfEmail,
          "profid": ""
        }
      }
      if (request !== undefined && request.state.req !== undefined && request.state.req.hid !== undefined) {
        let rq_Json = {
          "hid": request.state.req.hid,
          "sup":request.state.req.hs,
          "isallpax": state.allpax,
          "prof": state.prof!==""?state.prof:proReq,
          "roompax": state.roomDetails,
          "custms":state.custms,
          "freeCancelDate":state.freeCancelDate,
          "region_type" :request.state.req.bktype==="DIRECT"? regionType :request.state.req.region_type
        }

        await saveHotelBooking(rq_Json).then((response) => {
          if (response.pbres !== undefined && response.pbres.status !== undefined && response.pbres.status === "inserted") {
            sessionStorage.setItem(CHECK_BOOKING,true)
            sessionStorage.setItem(BOOKING_REF_NO,response.pbres.bookingId)
            checkCreditLimit(response, "credit");
            //executeScroll();
          } else {

            setIsConinueLoading(false)
            dispatch({ type: "showPop", payload: true })
            dispatch({ type: "prepersist", payload: true })

          }
        });
      }
    }else{
      dispatch({ type: "showRemarks", payload: true })
    }
    }
  }

  //Checking the CL and Enableing the Payment
  const checkCreditLimit = async (resp) => {
    var paytype = "credit";
    const req = {
      "comid": Number(context.logindata.cid),
      "pid": context.logindata.butype === "ta_user"?0:context.logindata.pcid,
      "cuscode": context.logindata.cuscode
    }
    let totalFae = 0;
    if (request.state.req.rooms !== undefined) {
      for (let room of request.state.req.rooms) {
        for (let rmp of room.rmpr) {
          if (totalFae === 0) {
            totalFae = rmp.pr[0].tf;
          } else {
            totalFae = Utilities.addPrice(totalFae, rmp.pr[0].tf, "Add");
          }
        }
      }
    }
    await getCrediLimit(req).then((response) => {
      if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
        const clbal = response.res.data.cbal;
        dispatch({ type: 'creditBal', payload: clbal });
        let val = Math.sign(clbal);
        if (Number(val) === 1) {
          if (context.logindata.butype === "sta_user") {
            var pclbal = 0;
            if (response.res.data.pcbal !== undefined) {
              pclbal = response.res.data.pcbal;
            }
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae) && pclbal >= Number(totalFae)) {
                Utilities.subPrice(clbal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false, totalFae);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true, totalFae);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, totalFae);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, totalFae);
              } else if (paytype === "credit" && clbal >= Number(totalFae) && pclbal < Number(totalFae)) {
                dispatch({ type: 'pcomName', payload: response.res.data.pcname });
                dispatch({ type: 'showNote', payload: true });
                createBookPayload(false, paytype, resp, 0, 0, true, true, totalFae);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, totalFae);
            }
          } else {
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae)) {
                Utilities.subPrice(state.creditBal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false, totalFae);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true, totalFae);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, totalFae);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, totalFae);
              } else {
                createBookPayload(false, paytype, resp, 0, 0, true, true, totalFae);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, totalFae);
            }
          }
        } else {
          createBookPayload(false, paytype, resp, 0, clbal, true, true, totalFae);
        }
      } else {
        createBookPayload(false, paytype, resp, 0, 0, true, true, totalFae);
      }
    });
  }






  const travDetails = (e) => {
    if (e && e.length > 0)
      dispatch({ type: "roomDetails", payload: e })
  }
  //Profile  Data
  const profileData = (data) => {
    dispatch({ type: 'prof', payload: data })
  }


  const travelsData = (data) => {
    dispatch({ type: 'travelsList', payload: data })
  }



//Calculating the TAX
const calServiceFee = (rooms) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    totalFare=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,context.logindata.butype,"All");
  }else{
    let service=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,context.logindata.butype,"All");
    totalFare=parseFloat(totalFare) + parseFloat(service);
  }
  }
  return totalFare;
}

//Calculating the TAX
const calculateServiceFee = (rooms,type) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    totalFare=Utilities.getSTAServiceFee(room.rmpr[0].servicefee,type);
  }else{
    let service=Utilities.getSTAServiceFee(room.rmpr[0].servicefee,type);
    totalFare=parseFloat(totalFare) + parseFloat(service);
  }
  }
  return totalFare;
}

//Calculating the TAX
const calMarkup = (rooms,type) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    totalFare=Utilities.getMarkup(room.rmpr[0].pr[0].tax,type);
  }else{
    let markup=Utilities.getMarkup(room.rmpr[0].pr[0].tax,type);
    totalFare=parseFloat(totalFare) + parseFloat(markup);
  }
  }
  return totalFare;
}

//Calculating the TAX
const calculateDisc = (rooms,type) => {
  let totalDisc=0;
  for(let room of rooms){
  if(totalDisc===0){
    totalDisc=Utilities.hotelDiscount(room.rmpr[0].pr[0].tax,type);
  }else{
    let disc=Utilities.hotelDiscount(room.rmpr[0].pr[0].tax,type);
    totalDisc=parseFloat(totalDisc) + parseFloat(disc);
  }
  }
  return totalDisc;
}

//Calculating the TAX
const calculateCommDisc = () => {
  let totalDisc=0;
  for(let room of request.state.req.rooms){
  if(totalDisc===0){
    if(room.rmpr[0].pr[0].com_disc!==undefined && room.rmpr[0].pr[0].com_disc!==null){
      totalDisc=room.rmpr[0].pr[0].com_disc;
    }
  }else{
    if(room.rmpr[0].pr[0].com_disc!==undefined && room.rmpr[0].pr[0].com_disc!==null){
    totalDisc=parseFloat(totalDisc) + parseFloat(room.rmpr[0].pr[0].com_disc);
    }
  }
  }
  return totalDisc;
}

//Calculating the TAX
const calculateGST = () => {
  let gst=0;
  for(let room of request.state.req.rooms){
  if(gst===0){
    if(room.rmpr[0].pr[0].gst_total!==undefined && room.rmpr[0].pr[0].gst_total!==0){
      gst=room.rmpr[0].pr[0].gst_total;
    }
  }else{
    if(room.rmpr[0].pr[0].gst_total!==undefined && room.rmpr[0].pr[0].gst_total!==0){
      gst=parseFloat(gst) + parseFloat(room.rmpr[0].pr[0].gst_total);
    }
  }
  }
  return gst;
}
//Calculating the TAX
const calculateTDS = () => {
  let tds=0;
  for(let room of request.state.req.rooms){
  if(tds===0){
    if(room.rmpr[0].pr[0].tds_val!==undefined && room.rmpr[0].pr[0].tds_val!==0){
      tds=room.rmpr[0].pr[0].tds_val;
    }
  }else{
    if(room.rmpr[0].pr[0].tds_val!==undefined && room.rmpr[0].pr[0].tds_val!==0){
      tds=parseFloat(tds) + parseFloat(room.rmpr[0].pr[0].tds_val);
    }
  }
  }
  return tds;
}

  const createBookPayload = (clavail, paytype, resp, amt, clbal, offline, ovewriteCL, totalFare) => {
    //If CL is available then call book request
    var enableOnline = false;
    if (!clavail && paytype === "credit") {
      enableOnline = true;
    }
    let serviceFee=0;
    if(request.state.req!==undefined && request.state.req.rooms!==undefined){
      serviceFee=calServiceFee(request.state.req.rooms);
      if(serviceFee!==0){
        totalFare=Utilities.addPrice(totalFare,parseFloat(serviceFee),"Add");
      }
    }
    //Deducting STA MARKUP AND SERVICE FEE
    let serviceFeeVal=0;
    let markup=0;
    let discAmt=0;
    let pDisc=0;
    let purchaseAmount=0;
    let comm_disc=0;
    if(context.logindata.butype==="ta_user"){
      serviceFeeVal=calculateServiceFee(request.state.req.rooms,"TA");
      markup=calMarkup(request.state.req.rooms,"TA");
      discAmt=calculateDisc(request.state.req.rooms,"ta_user");
      pDisc=calculateDisc(request.state.req.rooms,"att_user");
      purchaseAmount=parseFloat(totalFare)-parseFloat(serviceFeeVal)-parseFloat(markup)+parseFloat(discAmt)-parseFloat(pDisc);
    }else{
      serviceFeeVal=calculateServiceFee(request.state.req.rooms,"STA");
      markup=calMarkup(request.state.req.rooms,"STA");
      discAmt=calculateDisc(request.state.req.rooms,"sta_user");
      pDisc=calculateDisc(request.state.req.rooms,"ta_user");
      purchaseAmount=parseFloat(totalFare)-parseFloat(serviceFeeVal)-parseFloat(markup)+parseFloat(discAmt)-parseFloat(pDisc);
    }
    //Commision Discount
    if(context.logindata.butype==="ta_user"){
     comm_disc=calculateCommDisc();
     purchaseAmount=parseFloat(purchaseAmount)-parseFloat(comm_disc);
     totalFare=parseFloat(totalFare)-parseFloat(comm_disc);
    }
    //GST
    if(context.logindata.region_type!==undefined && context.logindata.region_type==="India"){
      totalFare=parseFloat(totalFare) + parseFloat(calculateGST()) + parseFloat(calculateTDS())
    }
    const payload = {
      hid: request.state.req.hid,
      bookingId:resp.pbres!==undefined && resp.pbres.bookingId!==undefined?resp.pbres.bookingId:request.state.req.booking_id,
      trans_id: resp.pbres?.trans_id,
      price: totalFare,
      cur: context.logindata.cur,
      diffamt: amt,
      isclavail: clavail,
      enableOnlinePay: false,
      enablePayment : (context.logindata.roles !== undefined && context.logindata.roles.includes('C_P_M')) ? true : false,
      paytype: paytype,
      cid: context.logindata.cid,
      onhold: "yes",
      creditBal: clbal!==undefined?clbal:0,
      bid: context.logindata.bid,
      butype: context.logindata.butype,
      pcid: context.logindata.pcid,
      ofcId: context.logindata.office_id ,
      utype: context.logindata.utype,
      markup: 0,
      service_fee: [],
      bookingType: request.state.req.bktype,
      enableOffline: offline,
      enableOvrwriteCL: ovewriteCL,
      cname: context.logindata.cnm,
      overwriteRole: (context.logindata.roles !== undefined && context.logindata.roles.includes('O_C_L_E')) ? true : false,
      userId: context.logindata.uid,
      stamarkup:markup,
      staservice:serviceFeeVal,
      onFlyMarkup:request.state.req.onflymarkup,
      totalPurchase:purchaseAmount,
      enableHold:state.enableOnhold && (request.state.req.hs==="0" || request.state.req.hs===0)?true:false,
      freCanclDate:moment(state.freeCancelDate, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm'),
      request:request.state.req,
      region_type : context.logindata.region_type,
      req_cur : state.bookingType==="DIRECT"? context.logindata.cur : request.state.req.cur,
      sup:"0",
      otila_prebk_req:resp.ot_prebook_res!==undefined?resp.ot_prebook_res:"",
      otila_prebk_res:state.ot_avail_resp!==undefined?state.ot_avail_resp:"",
      roompax: state.roomDetails,
      ot_room_details:state.ot_avail_resp!=="" && state.ot_avail_resp.RoomDetails!==undefined?state.ot_avail_resp.RoomDetails:"",
      enableOveride:(Number(context.logindata.cid)===231 || Number(context.logindata.cid)===232)?true:false
    }
    console.log("pay obj++++++++++++++"+payload)
    dispatch({ type: 'bdata', payload: payload });
    setPayment({ doPayment: true, showContinueButton: false })
  }

  const handleClose = () => {
    dispatch({ type: 'showPop', payload: false });
    //return window.location="/ezytrip/bookings/hotel/results";
  }

  const sendMesage = () => {
    dispatch({ type: 'showPop', payload: true })
    dispatch({ type: 'htlFail', payload: true })
  }

  const hideCustoms = () => {
    dispatch({ type: 'showCustoms', payload: false })
  }

  const accpetOtillaRemark=()=>{
    if(request.state.req.hs===1 && !state.acceptOtilla){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please accept the Terms and Conditions' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else{
      dispatch({ type: 'acceptOtilla', payload: true })
      dispatch({ type: 'showRemarks', payload: false })
      continueBook();
    }
  }

  const handleChangeOtla=()=>{
    if(state.acceptOtilla){
      dispatch({ type: 'acceptOtilla', payload: false })
    }else{
      dispatch({ type: 'acceptOtilla', payload: true })
    }

  }


  //Docs  Data
  const customData = useCallback((data) => {
    let customList = [];
    if (data) {
      for (let val of data) {
        const payload = {
          "clbl": val.flbl,
          "cval": val.fval,
        }
        customList.push(payload);
      }
    }
    dispatch({ type: 'custms', payload: customList })
  });

  const handleCLClose = () => {
    dispatch({ type: 'showNote', payload: false });
  }

  const allpaxcheckbox = (checked) => {
    dispatch({ type: 'allpax', payload: checked })
  }

  const updateFName=(nm)=>{
    dispatch({ type: 'firstName', payload: nm })
   }

   const updateLName=(nm)=>{
     dispatch({ type: 'lastName', payload: nm })
    }

    const updatePhoneNum=(num)=>{
     dispatch({ type: 'phoneNum', payload: num })
    }

    const updateCode=(code)=>{
     dispatch({ type: 'pcode', payload: code })
    }

    const updateEmail=(em)=>{
     dispatch({ type: 'email', payload: em })
    }

    const updateTitle=(title)=>{
      dispatch({ type: 'title', payload: title })
    }

    const updateCnfEmail=(mail)=>{
      dispatch({ type: 'cnfEmail', payload: mail })
    }


  return (

    <>
      <Header />
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <div className="container resultLayout mb-4">
        {request.state !== undefined && request.state.req !== undefined &&
          <div className="reviewTopSec pt-4">


            <Row>
              <Col lg={8} md={12}>

                <ProfileDetails sendProfile={profileData} sendName={updateFName} sendLastname={updateLName} sendPhcode={updateCode} sendPhone={updatePhoneNum} sendEmail={updateEmail} sendTitle={updateTitle} travelres={travelsData} importFlow={state.enableImprtFlow} importLeadPax={state.leadPaxObj}  type={"Hotel"}  flowType={state.bookingType} sendCEmail={updateCnfEmail} />

                <GustDetails travellersList={state.travelsList} roomsresponse={travDetails} srequest={request.state.req} allpaxcheckbox={allpaxcheckbox} prof={state.prof}
                fname={state.firstName} lname={state.lastName} phone={state.phoneNum} code={state.pcode} email={state.email} title={state.title} enableCHDOtila={enableCHDOtila}/>
                {state.showCustoms &&
                  <CustomInformaion customs={customData} compid={context.logindata.cid} noCustoms={hideCustoms} isOnhold={state.enableImprtFlow} />
                }

                {(payment.showContinueButton && !isConinueLoading) &&
                  (<div className="d-flex justify-content-end mt-4 mb-4">
                    <Button
                      size="lg"
                      className={"continue d-flex"}
                      onClick={continueBook}
                      variant="primary"
                    >Continue <span className="search ml-3 ezyIcon icon-rightArrow"></span></Button>
                  </div>)
                }
                {(payment.showContinueButton && isConinueLoading) && (<div className="d-flex justify-content-end mt-4 mb-4"> <Button
                  size="lg"
                  className={"continue d-flex " + ('progress-bar-striped progress-bar-animated')}
                  variant="primary"
                >Continue <span className="search ml-3 ezyIcon icon-rightArrow"></span></Button></div>)}


                <div>
                {payment.doPayment &&
                    <HotelPayment bookingdata={state.bdata} sendFailedMsg={sendMesage} refProp={true} />}
                </div>



              </Col>


              <Col lg={4} md={12}>
                <Sticky>
                  <>
                    <div className="boxLayout costLayout">
                      <h5 className="panelTitle">Booking Summary</h5>
                      {request.state.req !== undefined &&
                        <BookingSummary request={request.state.req} />
                      }
                    </div>

                    {/* <div className="boxLayout costLayout policy">
                    <h5 className="panelTitle">Cancellation Policy</h5>
                    <p>Free Cancellation before 12 Mar - 2021, 05:59:59 of Local hotel timing. </p>
                    <p>QAR 210.00 from 12 Mar-2021, 06:00:00 of local hotel timing QAR 632.50 for no show</p>
                  </div>

                  <div className="boxLayout costLayout remark">
                    <h5 className="panelTitle">Remarks</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div> */}
                  </>
                </Sticky>
              </Col>
            </Row>
          </div>
        }
      </div>

      <Modal
        show={state.showNote}
        onHide={handleCLClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>We are Unable to process this booking due to insufficient balance</strong>
              <p>Please check with {state.pcomName} Travel Agent</p>
              {context.logindata.utype === "att_user" &&
                <p>otherwise please proceed with the Override Credit Limit Option</p>
              }
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-dark" onClick={handleCLClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={state.showPop}
        onHide={handleClose}
        className="importPNRMdl "
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            {state.prepersist &&
              <div className="text-center">
                <strong>Pre Persistance is Failed</strong>
                <p>Please check with Administrator</p>
              </div>
            }
            {state.htlFail &&
              <div className="text-center">
                <strong>Selected Hotel is no more available</strong>
                <p>Please choose another Hotel in results page</p>
              </div>
            }
          </div>
        </Modal.Header>
      </Modal>

      <Modal
        show={state.showRemarks}
        className="importPNRMdl accRemark"
        backdrop="static"
        keyboard={false}
         >
        <Modal.Body>

          <div className='overFlowscroll'>
          <div className="popUplayout center">
{request.state.req !== undefined && request.state.req.htldtls !== undefined && request.state.req.htldtls.htnm}
<div className="text-left">
Remarks: <br/>
 {state.ot_avail_resp !== "" && state.ot_avail_resp?.Policies !== undefined && state.ot_avail_resp?.Policies.length !== 0 ? (
<div dangerouslySetInnerHTML={{ __html: state.ot_avail_resp.Policies[0].Remark }}></div>
) : null}
</div>
</div>


            </div>
          </Modal.Body>

          <Modal.Footer>

          <div className="d-flex mt-2 tc">
        <Form.Check
        type="checkbox"
        id="cb"
        label="I have accept the remerks/terms & conditions"
        value={state.acceptOtilla}
        onChange={handleChangeOtla}
        custom
        />
            </div>
            <div className='d-flex chooBtn'>
            <Button size="lg" variant="outline-secondary" onClick={handleClosee} className='choose mr-2'>
            Back
        </Button>
            <Button
            size="lg"
            className={"choose d-flex"}
            onClick={accpetOtillaRemark}
            variant="primary"
            >Accept <span className="search ml-3 ezyIcon icon-rightArrow"></span>
            </Button>
            </div>
          </Modal.Footer>
      </Modal>


      <Footer />
    </>
  )
}


export default HotelGuestDetails;
