import HotelConfigurationService from '../../../services/administration/HotelConfigurationService';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function Branch
 * @date : 06-10-20
 */


// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes=[];
    await HotelConfigurationService.saveMarkupDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const update = async (obj) => {
    let finalRes=[];
    await HotelConfigurationService.updateMarkupDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const editMarkup = async (id) => {
    let finalRes=[];

    await HotelConfigurationService.editMarkup(id).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const branchDataList = async () => {
    let branchesList = [];
    await AttCommonSerivce.getBranchData().then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Category Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const categoryDataList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getCategoryList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getAirportData = async (code) => {
    let airportList = [];
    await AttCommonSerivce.getAirportData(code).then(response => {
        airportList = response;
    });
    return Promise.resolve(airportList);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const ofcDataList = async () => {
    let ofcList = [];
    await AttCommonSerivce.getSuppOfcIds().then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyCatLsit = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyCatLsit(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deleteAppcableDate = async (id) => {
    let ofcList = [];
    await HotelConfigurationService.deleteAppcableDate(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const deleteRoute = async (id) => {
    let ofcList = [];
    await HotelConfigurationService.deleteRoute(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================
export const retriveFMDetails = async (configType,id) => {
    let ofcList = [];
    if(configType == "Markup") {
    await HotelConfigurationService.retriveFMDetails(id).then(response => {
        ofcList = response;
    });
} else {
    await HotelConfigurationService.retriveSFDetails(id).then(response => {
        ofcList = response;
    });
}
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit 
// ========================================================================

export const deledeFMap = async (configType,id) => {
    let ofcList = [];
    if(configType == "Markup") {
    await HotelConfigurationService.deleteFareMap(id).then(response => {
        ofcList = response;
    });
} else {
    await HotelConfigurationService.deleteServiceMap(id).then(response => {
        ofcList = response;
    });
}
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const delPaxMarakup = async (configType,id) => {
    let ofcList = [];
    if(configType == "Markup") {
    await HotelConfigurationService.deletePaxMarakup(id).then(response => {
        ofcList = response;
    });
} else {
    await HotelConfigurationService.deletePaxServiceFee(id).then(response => {
        ofcList = response;
    });
}
    return Promise.resolve(ofcList);
}


export const deleteLSid = async (configType,id) => {
    let ofcList = [];
    if(configType == "Markup") {
    await HotelConfigurationService.deleteLSid(id).then(response => {
        ofcList = response;
    });
} else {
    await HotelConfigurationService.deleteLSid(id).then(response => {
        ofcList = response;
    });
}
    return Promise.resolve(ofcList);
}
// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const delClass = async (id) => {
    let ofcList = [];
    await HotelConfigurationService.deleteClass(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const updateFMRequest = async (configType,id,data) => {
    let ofcList = [];
    if(configType == "Markup") {
    await HotelConfigurationService.updateFareMap(data).then(response => {
        ofcList = response;
    });
} else {
    await HotelConfigurationService.updateSrvcMap(id,data).then(response => {
        ofcList = response;
    });
}
    return Promise.resolve(ofcList);
}

export const deleteapplicableConfig = async (id) => {
    let ofcList = [];
    await HotelConfigurationService.deleteapplicableConfig(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
// ========================================================================
// All deledeFMap 
//  This function update the state values while clicking on Edit 
// ========================================================================
export const retrieveMarkups = async () => {
    let ofcList = [];
    await HotelConfigurationService.markupRetrieve().then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const loadBranchesList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.loadBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const getuserBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompaniesList= async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const searchMarkups= async (obj) => {
    let branchesList = [];
    await HotelConfigurationService.getSearchMarkups(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const getCompanyTAList = async (obj,type) => {
    let branchesList = [];
    await AttCommonSerivce.getCompanyTAList(obj,type).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const getSTACompanyList = async (id) => {
    let branchesList = [];
    await AttCommonSerivce.getSTACompanyList(id).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

/**
 *@description :  fetch all countries for hotels 
 @author : Azamuddin 
 @date : 27-04-2021
 */

 export const fetchCountries = async () => {
     let countriesList = [];
     await AttCommonSerivce.fetchHotelCountries().then(response => {
         countriesList = response
     })
     return Promise.resolve(countriesList);
    }

    /**
     * @description : fetch cities to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */

    export const fetchCities = async (id,type) => {
        let countriesList = [];
        await AttCommonSerivce.fetchHotelCities(id,type).then(response => {
            countriesList = response
        })
        return Promise.resolve(countriesList);
       }

       
       /**
     * @description : fetch hotels to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
       export const fetchHotels = async (id) => {
        let countriesList = [];
        await AttCommonSerivce.fetchHotels(id).then(response => {
            countriesList = response
        })
        return Promise.resolve(countriesList);
       }