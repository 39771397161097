/**
 * @description: Flight Summary page Flight details  section
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useEffect, useReducer, useRef, useState,useContext } from "react";
 import { Link, useHistory } from "react-router-dom";
 import { Form, Col, Row } from "react-bootstrap";
 import Button from '../../common/buttons/Button';
 import { isArrayNotEmpty, isNotNull } from "../../commonUtils/validators";
 import RefundType from "../../postBooking/refund/RefundType";
 import RefundTypeReturn from "../../postBooking/refund/RefundTypeReturn";
 import FlightSummaryFareDetails from "../summary/FlightSummaryFareDetails";
 import { Context } from "../../../../App";
 import FlightSummaryPaxDetails from "../summary/FlightSummaryPaxDetails";
 import RefundDetails from "../../postBooking/refund/RefundDetails";
 import SplitPnr from "../../postBooking/void/SplitPnr";
 import FlightSummarySectorHeader from "./FlightSummarySectorHeader";
 import FlightSummarySegmentDetails from "./FlightSummarySegmentDetails";
 import FlightSummaryRefund from "../refund/FlightSummaryRefund";
 import VoidSummaryDetails from "../void/VoidSummaryDetails";
 import { showRefunds,callTicketing } from "./operations";
 import ShowHide from "../../common/showHide/ShowHide";
 import Reissue from "../reIssue/Reissue";
 import SplitReissuePNR from '../flight/splitReissue/SplitReissuePNR';
 import FlightReissueSplit from '../flight/splitReissue/FlightReissueSplit';
 import IndigoSummaryDetails from "../refund/IndigoSummaryDetails";


 /**
  * Initial State Declaration
  */
 const initialState = {
   onward_list: [],
   return_list: [],
   flow_type: "",
   bktype: "",
   setVoidTicket: [],
   enableVoidBookingOnward: false,
   enableVoidBookingReturn: false,
   onward_cancel_void: [],
   return_cancel_void: [],
   enableVoidCancelOnward: false,
   enableVoidCancelReturn: false,
   enableRefundBookingOnward: false,
   enableRefundBookingReturn: false,
   selectedTickets: [],
   onward_void: [],
   onRf: 0,
   return_void: [],
   reRf: [],
   refundAvailable: false,
   refund_split_onward: [],
   refund_split_return: [],
   enableEditReturn: false,
   isCancelBooking: false,
   splitCancel: false,
   refundenable: false,
   voidenable: false,
   splitissue: false,
   enableReturnIti: true,
   enbaleReissueReturn: false,
   onwardReissueList: [],
   returnReissueList: [],
   cancelled_void_ticket_onward:[],
   cancelled_void_ticket_return:[],
   reissued_onward:[],
   reissued_return:[],
   onward_hold_split_pnr:false,
   return_hold_split_pnr:false,
   onhold_data_return:false,
   flow_type_fbf:"O",
   onward_tid : "",
   return_tid:"",
   checkboxRState : false,
   type_selected: "Onward",
   onward: true,
   showCancel: false
 };

 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {
   switch (action.type) {
     default:
       return { ...state, [action.type]: action.payload };
   }
 };
 let onward_pax = [];
 let return_pax = [];
 let onward_fare = [];
 let return_fare = [];
 let onward_payment = [];
 let return_payment = [];
 let onward_pax_tkts = [];
 let return_pax_tkts = [];
 let onward_seg = [];
 let return_seg = [];
 /**
  * @description:This function will Display the the Sector detals and Segment details
  * @author: Rambabu
  * @param {*}
  * @function FlightSummarySectors
  * @date : 18-09-2020
  */
 function FlightSummarySectors(props) {
   const [state, dispatch] = useReducer(reducer, initialState);
   const [context, setContext] = useContext(Context);
   const history = useHistory();
   const ref = useRef();
   const [editPayment, setEditPayment] = useState(false);
   const [request, setRequest] = useState(props.refundsData);
   const [refundDataOnward, setRefundDataOnward] = useState();
   const [refundDataReturn, setRefundDataReturn] = useState();
   const [editItinerary, setEditItinerary] = useState(true);
   const [editSec, setEditSec] = useState(false);
   const [editvoidMore, setvoidEditMore] = useState(false);
   const [editrefundMore, setrefundEditMore] = useState(false);
   const [show, setShow] = useState(false);
   const returnFunctionalitiesREF = useRef(null)
   const executeScrolltoReturn = () => returnFunctionalitiesREF.current.scrollIntoView()



   const openSecEdit = () => {
     if (editSec) {
       setEditSec(false);
     } else {
       setEditSec(true);
     }
   };
   const openSplitMOre = () => {
     if (editvoidMore) {
       setvoidEditMore(false);
     } else {
       setvoidEditMore(true);
     }
   };
   const openSplitRefundMOre = () => {
     if (editrefundMore) {
       setrefundEditMore(false);
     } else {
       setrefundEditMore(true);
     }
   };
   useEffect(() => {
    dispatch({type:"onward",payload:props.onward})
   },[props.onward])
   useEffect(() => {
     // let onward_seg = [];
     // let return_seg = [];
     onward_pax = [];
     return_pax = [];
     dispatch({ type: "flow_type", payload: props.segments[0].flow_type });
     dispatch({ type: "bktype", payload: props.segments[0].bktype });

     if (
       isArrayNotEmpty(props.segments) &&
       isNotNull(props.segments[0].flow_type) &&
       (props.segments[0].flow_type === "split" || props.normalTFtwoLcc)
     ) {
       onward_pax.push(props.pax_det[0]);
       if(onward_pax.length > 0) {
        for(let arr of onward_pax) {
          for(let eachPax of arr){
          if(eachPax.stsid == "37"  && (eachPax.segst == "Confirmed" || eachPax.segst == "Pending")) {
            dispatch({type:"onward_tid",payload:eachPax.tid})
            dispatch({type:"onward_hold_split_pnr", payload:true})
            break;
          }
        }
      }
      }
       return_pax.push(props.pax_det[1]);
       let onhold_array = []
       if(return_pax.length > 0) {
        for(let arr of return_pax) {
          for(let eachPax of arr){
          if(eachPax.stsid == "37"  && (eachPax.segst == "Confirmed" || eachPax.segst == "Pending")) {
            dispatch({type:"return_tid",payload:eachPax.tid})
            onhold_array.push(eachPax.stsid)
            dispatch({type:"return_hold_split_pnr",payload:true})
          }
        }
        }
        if(onhold_array.length > 1) {
          dispatch({type:"onhold_data_return",payload:true})
        }
      }
       //  onward_fare.push(props.faredata[0]);
       //  if(props.faredata!==undefined && props.faredata[1]!==undefined){
       //   return_fare.push(props.faredata[1]);
       //  }
       onward_payment.push(props.payment_data[0]);
       if (
         props.payment_data !== undefined &&
         props.payment_data[1] !== undefined
       ) {
         return_payment.push(props.payment_data[1]);
       }
       for (let val of props.pax_det[0]) {
         onward_pax_tkts.push(val.tkt_no);
       }
       if (props.pax_det !== undefined && props.pax_det[1] !== undefined) {
         for (let val of props.pax_det[1]) {
           if (val.sts === "Confirmed") {
             dispatch({ type: "enableReturnIti", payload: false });
           }
           return_pax_tkts.push(val.tkt_no);
         }
       }

       if (props.faredata && isArrayNotEmpty(props.faredata)) {
         let rt_fare = [];
         let ow_fare = [];
         for (let val of props.segments) {
           if (val.journey_type == "O") {
             ow_fare = props.faredata.filter((obj) => obj.tid == val.tid);
             if(props.normalTFtwoLcc) {
              ow_fare = [ow_fare[0]]
             }
           } else if (val.journey_type == "R") {
             rt_fare = props.faredata.filter((obj) => obj.tid == val.tid);
             if(props.normalTFtwoLcc) {
              if(rt_fare.length > 1) {
                rt_fare = [rt_fare[1]]
               }
            }
           }
         }
         if(isArrayNotEmpty(props.segments) &&
         isNotNull(props.segments[0].supplier) &&
         (Number(props.segments[0].supplier) !== 0)) {
         if(rt_fare.length!==0 && rt_fare[0].paydetails!==null && rt_fare[0].paydetails.length == 0){
         let newRT = ow_fare[0].paydetails[0];
         rt_fare[0].paydetails.push(newRT)
        }
         }
         if (ow_fare.length > 0) {
           let all_tkts = []
           let split_tickets = []
           if (onward_pax.length > 0) {
             for (let val in onward_pax) {
               let pax = onward_pax[val]
               if (val.length > 0) {
                 for (let dub of pax) {
                   if(dub.split_pnr == null) {
                    all_tkts.push(dub.tkt_no)
                  } else {
                    if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                      all_tkts.push(dub.tkt_no)
                    } else {
                      split_tickets.push(dub.tkt_no)
                     }
                   }

                   if (state.trip_type == "") {
                     dispatch({ type: "trip_type", payload: dub.trip_type })
                   }
                 }
               }

             }
           }
           if(props.reissue_split_tag) {
             for(let val in ow_fare) {
               let each_pax = ow_fare[val]
               for (let pax in each_pax.pasngrs) {
                 let data = each_pax.pasngrs[pax]
                 data.pax_split = data.pax.filter(s => split_tickets.includes(s.tkt))
                 data.pax = data.pax.filter(s =>  all_tkts.includes(s.tkt))

               }
             }
             onward_fare = ow_fare;
           } else {
             onward_fare = ow_fare;
           }

           // onward_payment = ow_fare[0].paydetails
         }
         if (rt_fare.length > 0) {
           let all_tkts = []
           let split_tickets = []
           if (onward_pax.length > 0) {
             for (let val in return_pax) {
               let pax = return_pax[val]
               if (val.length > 0) {
                 for (let dub of pax) {
                   if(dub.split_pnr == null) {
                    all_tkts.push(dub.tkt_no)
                  } else {
                    if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44") {
                      all_tkts.push(dub.tkt_no)
                    } else {
                      split_tickets.push(dub.tkt_no)
                     }
                   }

                   if (state.trip_type == "") {
                     dispatch({ type: "trip_type", payload: dub.trip_type })
                   }
                 }
               }

             }
           }
           if(props.reissue_split_tag) {
             for(let val in rt_fare) {
               let each_pax = rt_fare[val]
               for (let pax in each_pax.pasngrs) {
                 let data = each_pax.pasngrs[pax]
                 data.pax_split = data.pax.filter(s => split_tickets.includes(s.tkt))
                 data.pax = data.pax.filter(s =>  all_tkts.includes(s.tkt))

               }
             }
             return_fare = rt_fare;
           } else {
             return_fare = rt_fare;
           }

           // return_payment = rt_fare[0].paydetails
         }
       }
     }
     //here seperate the onward list and return list
     onward_seg = []
     return_seg = []
     for (let s in props.segments) {
       let seg_ = props.segments[s];

       //if not multi city
       if (
         props.bookingType !== undefined &&
         props.bookingType !== "REISSUE" &&
         isNotNull(seg_.trvel_type) &&
         parseInt(seg_.trvel_type) !== 3
       ) {
         if (seg_.journey_type === "O") {
           onward_seg.push(seg_);
         } else if (seg_.journey_type == "R") {
           return_seg.push(seg_);
         }
       } //if multi city
       else if (
         props.bookingType !== undefined &&
         props.bookingType !== "REISSUE" &&
         isNotNull(seg_.trvel_type) &&
         parseInt(seg_.trvel_type) === 3
       ) {
         onward_seg.push(seg_);
       } else {
         if (
           props.bookingType !== undefined &&
           props.bookingType === "REISSUE"
         ) {
           if (seg_.journey_type === "O") {
             onward_seg.push(seg_);
           } else if (seg_.journey_type === "R") {
             return_seg.push(seg_);
           }
         }
       }
     }

     dispatch({ type: "onward_list", payload: onward_seg });
     dispatch({ type: "return_list", payload: return_seg });
   }, []);

   useEffect(() => {
    if(props.flow_type_onward && props.enablesptpnr) {
      dispatch({type:"flow_type_fbf",payload:"O"})
      dispatch({type:"onhold_return_split",payload:false})
      // dispatch({type:"onward_tid",payload:state.onward_tid})
    }
   },[props.flow_type_onward,props.enablesptpnr])


   useEffect(() => {

     if(props.reissuedSplitResponse.length > 0) {
       if(state.flow_type == "split") {
         if(props.reissuedSplitResponse[0].length > 0) {
           dispatch({type:"reissued_onward",payload:props.reissuedSplitResponse[0]})
         }
         if(props.reissuedSplitResponse[1] !== undefined && props.reissuedSplitResponse[1].length > 0) {
           dispatch({type:"reissued_return",payload:props.reissuedSplitResponse[1]})
         }
       }
     }
   },[props.reissuedSplitResponse])
   useEffect(() => {
     if (props.bookingData !== undefined && props.bookingData[0] !== undefined) {
       let tids = props.bookingData[0].tid.split("/");
       if (props.voidFareResp !== undefined && props.voidFareResp.length !== 0 && state.flow_type == "split") {
         let onwardVoid = props.voidFareResp.filter(
           (obj) => obj.tid === onward_seg[0].tid
         );
         let void_onward_cancelled = [];
         let void_return_cancelled = [];
         let cancelled_ticket_onward = []
         let cancelled_tiket_return = [];
         // cancelled_void_ticket_onward:[],
   // cancelled_void_ticket_return:[]
         dispatch({ type: "onward_void", payload: onwardVoid });
         for (let initdata of onwardVoid) {
           for (let data of initdata.passenger) {
             if (data.type == "MTA") {
               for (let eachPax of data.pax) {
                 if (eachPax.split_pnr) {
                   let cobj = {
                     pn: eachPax.paxname, //pax name
                     lp: eachPax.is_lead_pax_no, //lead pax boolean
                     tkt: eachPax.tkt_no, //ticket number
                     sts: eachPax.ticket_status, //ticket status
                     type: eachPax.ptype, //pax type
                     pnr: eachPax.split_pnr, //pnr
                     bt: eachPax.ticket_status === "31" ? "Void" : "VoidCancel", //status
                   };
                   void_onward_cancelled.push(cobj);
                 }
                 if(eachPax.ticket_status === "39") {
                   cancelled_ticket_onward.push(eachPax.tkt_no)
                 }
               }
             }
           }
         }
         dispatch({
           type: "onward_cancel_void",
           payload: void_onward_cancelled,
         });
         dispatch({type:"cancelled_void_ticket_onward",payload:cancelled_ticket_onward})
         let returnVoid = props.voidFareResp.filter(
           (obj) => obj.tid === return_seg[0].tid
         );
         for (let initdata of returnVoid) {
           for (let data of initdata.passenger) {
             if (data.type == "MTA") {
               for (let eachPax of data.pax) {
                 if (eachPax.split_pnr) {
                   let cobj = {
                     pn: eachPax.paxname, //pax name
                     lp: eachPax.is_lead_pax_no, //lead pax boolean
                     tkt: eachPax.tkt_no, //ticket number
                     sts: eachPax.ticket_status, //ticket status
                     type: eachPax.ptype, //pax type
                     pnr: eachPax.split_pnr, //pnr
                     bt: eachPax.ticket_status === "31" ? "Void" : "VoidCancel", //status
                   };
                   void_return_cancelled.push(cobj);
                 }
                 if(eachPax.ticket_status === "39") {
                   cancelled_tiket_return.push(eachPax.tkt_no)
                 }
               }
             }
           }
         }
         dispatch({type:"cancelled_void_ticket_return",payload:cancelled_tiket_return})
         dispatch({ type: "return_void", payload: returnVoid });
         dispatch({
           type: "return_cancel_void",
           payload: void_return_cancelled,
         });
       }
     }
   }, [props.voidFareResp]);
  //  useEffect(()=>{
  //   dispatch({ type: "onhold_return_split", payload: false})
  //  }, [props.enablesptpnr == true])

   const handleReturnOnHoldSplit = () =>{
     dispatch({ type: "checkboxRState", payload: !state.checkboxRState})
     dispatch({ type: "onhold_return_split", payload: true})
     dispatch({type:"flow_type_fbf",payload:"R"})
     props.showOnward(false);
     props.handleonHoldreturn()
     }
   const openeditItinerary = () => {
     if (editItinerary) {
       setEditItinerary(false);
     } else {
       setEditItinerary(true);
     }
   };


   const handleInsertPnr=()=>{
   props.openInsertPnr("RETURN")
   }

   const manualReissue=()=>{
    props.openManualPnr("RETURN")
    }


   /**
    * @description -- enable void booking in split return_list
    * @author: azamuddin
    */
   useEffect(() => {
     if (props.enableVoidBooking) {
       dispatch({ type: "enableVoidBookingOnward", payload: true });
       dispatch({ type: "enableVoidBookingReturn", payload: true });
     }
   }, [props.enableVoidBooking]);

   /**
    * @description -- enable void booking in split return_list
    * @author: azamuddin
    */
   useEffect(() => {
     if (props.enableVoidCancel) {
       dispatch({ type: "enableVoidBookingOnward", payload: true });
       dispatch({ type: "enableVoidBookingReturn", payload: true });
     }
   }, [props.enableVoidCancel]);

   /**
    * @description -- enable void booking in split return_list
    * @author: azamuddin
    */
   useEffect(() => {
     if (props.enableRefundTkt) {
       dispatch({ type: "enableVoidBookingOnward", payload: true });
       dispatch({ type: "enableVoidBookingReturn", payload: true });
      }
   }, [props.enableRefundTkt]);

   const openeeditPayment = () => {
     if (editPayment) {
       setEditPayment(false);
     } else {
       setEditPayment(true);
     }
   };

   /**
    *@description :  set the void applied tickets to an array
    * @author: Azamuddin
    * ----function only for split
    * @date : 03-12-2020
    */
   const voidBookReq = (e) => {
     if (e && e.length > 0) {
       dispatch({ type: "selectedTickets", payload: e });
       props.voidTickets(e);
     }
   };
   /**
    * @dewcription : disabel Return when onward void tickets are selected
    * @author : azamuddin
    * @date : 03-12-2020
    */
   const disablevReturn = (e) => {
     if (e && e.length > 0) {
       dispatch({ type: "enableVoidBookingReturn", payload: false });
       if (props.enableRefundTkt) {
         dispatch({ type: "enableRefundBookingReturn", payload: false });
       }
     }
   };
   const disablecancelReturn = (e) => {
     if (e && e.length > 0) {
       dispatch({ type: "enableVoidBookingReturn", payload: false });
     }
   };

   /**
    * @description : disabel onward when return void tickets are selected
    * @author : azamuddin
    * @date : 03-12-2020
    */
   const disablevOnward = (e) => {
     if (e && e.length > 0) {
       dispatch({ type: "enableVoidBookingOnward", payload: false });
       // if(props.enableRefundTkt) {
       //     dispatch({ type: 'enableRefundBookingOnward',payload:false})
       // }
     }
   };

   const disablecancelOnward = (e) => {
     if (e && e.length > 0) {
       dispatch({ type: "enableVoidCancelReturn", payload: false });
     }
   };

   const continueRefunds = (data) => {
     props.sendRefund(data);
   };

   const reissueOnwardPax = (data) => {
     props.sendOnwardReissue(data);
   };

   const reissueReturnPax = (data) => {
     props.sendRetruenReissue(data);
   };

   useEffect(() => {
     if (request !== undefined) {
       //For Onward Refund details
       let final_array_refunded = [];
       let onward_refunded_tickets = [];
       let retrun_refunded_tickets = [];
       let paxData = [];
       let tno = [];
       if (onward_pax.length !== 0) {
         for (let val of onward_pax) {
           for (let p of val) {
             if (
               p != undefined &&
               (p.sts === "Refund/booking" || p.sts === "Refund/cancel")
             ) {
               paxData.push(p.trv_id);
               tno.push(p.tkt_no);
             }
           }
         }
       }
       if (props.onward_supplier===0 && paxData !== undefined && paxData.length !== 0) {
         const payload = {
           booking_id: props.bookingData[0].booking_id,
           tid: onward_pax[0][0].tid,
           trans_id:
             props.bookingData[0] !== undefined &&
             props.bookingData[0].trans_id !== undefined
               ? props.bookingData[0].trans_id
               : "",
           trav_id: paxData,
           tkt_no: tno,
         };
         showRefunds(payload).then((resp) => {
           if (resp.suc && resp.data !== undefined) {
             dispatch({ type: "refundAvailable", payload: true });

             let pnr_data = [];
             for (let r in resp.data.psngrs) {
               let paxwise_data = resp.data.psngrs[r];
               let p_type = paxwise_data.ptype;
               for (let tkt in paxwise_data.tktnum) {
                 let tikt_data = paxwise_data.tktnum[tkt];
                 if (isNotNull(tikt_data.split_pnr)) {
                   let split_pnr_obj = {
                     pn: tikt_data.pname,
                     lp: tikt_data.paxsno,
                     tkt: tikt_data.number,
                     type: p_type,
                     pnr: tikt_data.split_pnr,
                     bt: tikt_data.st,
                     sts: tikt_data.stn,
                   };
                   pnr_data.push(split_pnr_obj);
                 }
                 onward_refunded_tickets.push(tikt_data.number);
               }
             }
             let refundpnr = []
                      for(let item of pnr_data){
                       if(item.sts == 44){
                        refundpnr.push(item)
                       }
                     };
                     if(refundpnr.length == pnr_data.length ){
                      dispatch({ type: 'showCancel', payload: false })
                     }
                     else{
                      dispatch({ type: 'showCancel', payload: true })
                     }
             dispatch({ type: "refund_split_onward", payload: pnr_data });
             setRefundDataOnward(resp.data);
             props.refund_split_onward(resp.data);
           }
         });
       }
       //For return Refund details
       //For Refund
       let paxReData = [];
       let tktre_no = [];
       if (return_pax.length !== 0) {
         for (let val of return_pax) {
           for (let p of val) {
             if (
               p != undefined &&
               (p.sts === "Refund/booking" || p.sts === "Refund/cancel")
             ) {
               paxReData.push(p.trv_id);
               tktre_no.push(p.tkt_no);
             }
           }
         }
       }
       if (props.return_supplier===0 && paxReData !== undefined && paxReData.length !== 0) {
         const payload = {
           booking_id: props.bookingData[0].booking_id,
           tid: return_pax[0][0].tid,
           trans_id:
             props.bookingData[0] !== undefined &&
             props.bookingData[0].trans_id !== undefined
               ? props.bookingData[0].trans_id
               : "",
           trav_id: paxReData,
           tkt_no: tktre_no,
         };
         showRefunds(payload).then((resp) => {
           if (resp.suc && resp.data !== undefined) {
             dispatch({ type: "refundAvailable", payload: true });

             let pnr_data = [];
             for (let r in resp.data.psngrs) {
               let paxwise_data = resp.data.psngrs[r];
               let p_type = paxwise_data.ptype;
               for (let tkt in paxwise_data.tktnum) {
                 let tikt_data = paxwise_data.tktnum[tkt];
                 if (isNotNull(tikt_data.split_pnr)) {
                   let split_pnr_obj = {
                     pn: tikt_data.pname,
                     lp: tikt_data.paxsno,
                     tkt: tikt_data.number,
                     type: p_type,
                     pnr: tikt_data.split_pnr,
                     bt: tikt_data.st,
                     sts: tikt_data.stn,
                   };
                   pnr_data.push(split_pnr_obj);
                 }
                 retrun_refunded_tickets.push(tikt_data.number);
               }
             }
             dispatch({ type: "refund_split_return", payload: pnr_data });
             setRefundDataReturn(resp.data);
             props.refund_split_return(resp.data);
           }
         });
       }
       //to set the refunded tickets to check btype in parent components
       final_array_refunded.push(onward_refunded_tickets);
       final_array_refunded.push(retrun_refunded_tickets);
       props.final_array_refunded(final_array_refunded);
     }
   }, []);

   //Handle refund
   const handleEdiyBooking = () => {
     dispatch({ type: "enableEditReturn", payload: true });
     props.SendEditFlow();
   };

   const handleChange = (evt) => {
     const { id, value } = evt.target;
     let isbook = false;
     if (state.isCancelBooking) {
       dispatch({ type: "isCancelBooking", payload: false });
       isbook = false;
     } else {
       dispatch({ type: "isCancelBooking", payload: true });
       isbook = true;
     }
     dispatch({ type: id, payload: value });
     props.sendCancelBook(isbook);
   };

   useEffect(() => {
     if (props.hideReturn && props.hideReturn !== undefined) {
       dispatch({ type: "enbaleReissueReturn", payload: false });
     }
   }, [props.hideReturn]);

   //Handle refund
   const handleReissueReturn = () => {
     dispatch({ type: "enbaleReissueReturn", payload: true });
     dispatch({type:"onward",payload:false})
     executeScrolltoReturn()
     props.hideOnward();
     //props.sendReissueReturn();
   };

   //Handle Downloddocs
   const handleDownloaddocsReturn = (data) => {
     props.returnpopupdata(data);
   };

   /**
    * @description : When issue ticket is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
   const handleIssueTicket = () => {
     dispatch({ type: "splitissue", payload: true });
     dispatch({ type: "voidenable", payload: true }); //enable void
     dispatch({ type: "refundenable", payload: false }); //refund
   };

   /**
    * @description : When issue cancel is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
   const handleCancelBooking = () => {
     dispatch({ type: "splitCancel", payload: true }); //enabling cancel
     dispatch({ type: "voidenable", payload: true }); //enable void
     dispatch({ type: "refundenable", payload: false }); //refund
     props.dropdown("cancel", true);
   };

   /**
    * @description : When refund ticket is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
   const handlerefundIssueTicket = () => {
     dispatch({ type: "splitissue", payload: true });
     dispatch({ type: "refundenable", payload: true });
     dispatch({ type: "voidenable", payload: false });
   };

   /**
    * @description : When issue cancel is selected from split PNR dropdown
    * @date: 10-04-2021
    * @author : <Azamuddin>
    */
   const handlerefundCancelBooking = () => {
     dispatch({ type: "splitCancel", payload: true });
     dispatch({ type: "refundenable", payload: true });
     dispatch({ type: "voidenable", payload: false });
     props.dropdown("cancel", false);
   };
   const pnrFetch = (e) => {
     dispatch({ type: "splitPNR", payload: e });
     props.selectedPNR(e);
   };

   /**
    * @description -- enable void booking in split return_list
    * @author: azamuddin
    */
   useEffect(() => {
     if (
       props.reIssueResponse !== undefined &&
       props.reIssueResponse.length !== 0
     ) {
       let onwardsList = [];
       let returnList = [];
       if(props.reIssueResponse[0].length > 0) {
         onwardsList = props.reIssueResponse[0]
       }
       if(props.reIssueResponse[1] !== undefined && props.reIssueResponse[1].length > 0) {
         returnList = props.reIssueResponse[1]
       }
       dispatch({ type: "onwardReissueList", payload: onwardsList });
       dispatch({ type: "returnReissueList", payload: returnList });
     }
   }, [props.reIssueResponse]);

   /**
    * @description : if cancel booking clicked then on return
    * @author : Azamuddin
    * @date : 28-05-2021
    */
   const hancleCancelBookFlow = () => {
     props.handleCancelBookFlowReturn(true)
   }

   const reissuesplitOnward = (tkt) => {
     props.sendReissueSTickts(tkt)
   }
   const OnHoldSplitOnward = (tkt) => {
    props.sendOnHoldSplitOnward(tkt)
  }



  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
    return myUniqueArr;
  }
  const onHoldReturnTickets = (tkt) => {
    props.sendOnHoldTkts(tkt)
  }
   const reissuesplitReturn = (tkt) => {
     props.sendReissueTicktsSReturn(tkt)
   }
      //Calling Ticket request for ONHOLD
      /**
       * @author : Azamuddin
       * @param { type  } defines the journey type
       * @date : 30-08-2021
       */
      const fireTicketing = (type) => () => {
        //if(validateImport()){
        dispatch({ type: "isLoading", payload: true })
        // setIsLoadingMore(true);
        let segList = []; //seg list
        let paxList = []; //pax list
        let splitted_pax = []
        let tid = ''
        if(type == "Onward") {
          segList = state.onward_list
          paxList = onward_pax
        } else {
          segList = state.return_list
          paxList = return_pax
        }
        var request = "";
        let pnr = "";
        var gdsPNR = "";
        for(let val of paxList[0]) {
          if(val.split_pnr !== null) {
            splitted_pax.push(val.paxsno)
          }
        }
        tid = segList[0].tid
        if (!segList[0].normal_gds_pnr.includes(",")) {
          gdsPNR = segList[0].normal_gds_pnr

          request = {
            "tid": segList[0].tid,
           "pnr": segList[0].normal_gds_pnr,
            "sty": "normal",
            "splitted_pax":uniqueJson(splitted_pax),
            "onhold_sty": "split"
           }
        } else {
          pnr = segList[0].normal_gds_pnr.split(",");
          gdsPNR = type == "Onward" ? pnr[0] : pnr[1];
          request = {
            "tid": segList[0].tid,
            "pnr": gdsPNR,
            "sty": "normal",
            "splitted_pax":uniqueJson(splitted_pax),
            "onhold_sty": "split"
           }
        }
        callTicketing(request).then((resp) => {
          if (resp.status === "success" && resp.data !== undefined) {
            resp.data.flowType = "ONHOLD";
            resp.data.bktype = "ONHOLD";
            resp.data.bookingId = props.bookingData[0].booking_id;
            resp.data.bkref = props.bookingData[0].booking_id;
            resp.data.pnr = gdsPNR;
            resp.data.tid = tid
            resp.data.onhold_sty = "split";
            resp.data.region_type=props.bookingData[0].region_type;
            resp.data.req_cur=props.bookingData[0].usr_curr_code;
            if(splitted_pax.length > 0) {
              resp.data.splitted_pax = uniqueJson(splitted_pax)
            }
            dispatch({ type: "isLoading", payload: false })
            history.push({
              pathname: '/ezytrip/bookings/flight/itinerary',
              state: {
                req: resp.data
              }
            });
          } else {
            setShow(true)
            props.setOnholdDiscard({
              "data":resp.data,
              "tid": tid
            })
            dispatch({ type: "isLoading", payload: false })
          }
          dispatch({ type: 'onholdResp', payload: resp.data })
          dispatch({ type: "isLoading", payload: false })
          // setIsLoadingMore(false);
        })
      }
      useEffect(() => {
        if(props.type_selected) {
          dispatch({type:"type_selected",payload:props.type_selected})
        }
      },[props.type_selected])
      const handleVoidBooking = () => {
        // dispatch({type:"type_selected",payload: true})
        dispatch({type:"onward",payload:false})
        executeScrolltoReturn()
        props.enableVoidBookingReturn(true)
      }
      const handleVoidCancel = () => {
        // dispatch({type:"type_selected",payload: true})
        dispatch({type:"onward",payload:false})
        executeScrolltoReturn()
        props.enableVoidCancelReturn(true)
      }
      const handleRefundTicket = () => {
        // dispatch({type:"type_selected",payload: true})
        dispatch({type:"onward",payload:false})
        executeScrolltoReturn()
        props.enablerefundReturn(true)
      }
      const handleShare =()=>{
        props.openshareevent();
        props.fbfReturn()
      }

      const showIndigoRefundPopup=()=>{
        props.showIndigoPopup();
      }


   return (
     <>

       {/* On ward sector and segment details */}
       {isArrayNotEmpty(state.onward_list) && (

         <>
          {props.onward_supplier && props.onward_supplier !== 0 ? (
          <div className="ltag lcc ezyIcon icon-nFlight">LCC </div>
        ): (
          <div className="ltag fcc ezyIcon icon-nFlight">FSC</div>
        )}
           <FlightSummarySectorHeader
             data={state.onward_list}
             airport_data={props.airportdata}
             triptype={props.triptype}
             isEdit={props.isEdit}
             supplier={props.onward_supplier}
             sectorTab={props.bookingType}
           />
           {props.triptype !== "3" && (
             <>
               <FlightSummarySegmentDetails
                 data={state.onward_list}
                 airport_data={props.airportdata}
                 isEdit={props.isEdit}
                 supplier={props.onward_supplier}
               />
             </>
           )}

           {isNotNull(state.flow_type) && (state.flow_type === "split" || props.normalTFtwoLcc) && (
             <>
               <div className="resultSection boxShadow bg-white PassengerDetails mt-5">
                 <h5 className="title">Passenger Details</h5>
                 {/* Pax Details Section */}
                 {/*  passeneger details for split fare onward pax and return pax we are showing seperatly */}
                 {isArrayNotEmpty(props.pax_det) &&
                   props.pax_det !== undefined &&
                   props.pax_det !== null && (
                     <FlightSummaryPaxDetails
                       onhold_selected_split={state.flow_type_fbf}
                       pax_det={onward_pax}
                       voidedCancelled={props.voidedCancelled}
                       enableVoidBookingOnward={state.type_selected == "Onward" && state.enableVoidBookingOnward ? true : false}
                       disableCheckbox={props.disableCheckbox}
                       disableReturn={disablevReturn}
                       segment={"onward"}
                       journey_type={"O"}
                       tktsVoided={props.tktsVoided}
                       voidTickets={voidBookReq}
                       enableRefundOnward={state.type_selected == "Onward" && state.enableRefundBookingOnward ? true : false}
                       onTkts={onward_pax_tkts}
                       retTkts={return_pax_tkts}
                       refundAvailable={state.type_selected == "Onward" && state.refundAvailable ? true : false}
                       enableRefundTkt={state.type_selected == "Onward" && props.enableRefundTkt ? true : false}
                       isEdit={props.isEdit}
                       enableReisueTkt={state.type_selected == "Onward" && props.enableReisueTkt ? true : false}
                       sendReissueTickts={reissueOnwardPax}
                       sendReissueSplit={reissuesplitOnward}
                       sendOnHoldTkts = {OnHoldSplitOnward}
                       hideReturn={props.hideReturn}
                       bookingId={props.bookingData[0].booking_id}
                       enablesptpnr = {props.enablesptpnr}
                       checkboxFocus = {props.checkboxFocus}
                       tid = {state.onward_tid}
                       onward={state.onward}
                     />
                   )}

                 <Form.Row>
                   <Col xs={12} className="d-flex refundBotBlock">
                     {props.enableRefundTkt  && state.onward && (
                       <RefundType
                         type={"Onward"}
                         ticketsList={state.selectedTickets}
                         bookDetails={props.bookDetails}
                         sendRefund={continueRefunds}
                       />
                     )}
                     {props.enableRefundTkt  && state.onward && (
                       <Form.Check
                         type="checkbox"
                         label="Cancel Booking"
                         custom
                         id="bkR"
                         value={state.isCancelBooking}
                         onChange={handleChange}
                       />
                     )}
                   </Col>
                 </Form.Row>
               </div>
               {props.return_supplier === 0 && isArrayNotEmpty(onward_pax[0]) && onward_pax[0].filter(s => s.split_pnr && s.stsid == "23").length > 0 &&
                      <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1">
                     <h5 className="title mb-2 mt-2"> Reissue Split PNR</h5>
                     <SplitReissuePNR paxList={onward_pax[0]} />
                     </div> }
               <div className="resultSection boxShadow bg-white PassengerDetails mb-2 mt-0">
                 <h5 className="title mb-2 d-flex justify-content-between">
                   Fare Details{" "}
                   {/* <span
                     className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                     ref={ref}
                     onClick={openeeditPayment}
                   >

                     <div
                       className={
                         "showDetails " + (editPayment ? "active" : "inactive")
                       }
                     >
                       <ul className="d-flex flex-column">
                         <li>
                           <Link to="#">Edit Fare</Link>
                         </li>
                         <li>
                           <Link to="#">Update Fare</Link>
                         </li>
                       </ul>
                     </div>{" "}
                   </span> */}
                 </h5>
                 {/*  <span className="itnry"><em>Itinerary Ref # :</em> EZ125198-1</span> */}
                 {/* Fare details section */}
                 {/* fare details onward fare and return fare we are showing seperaly */}
                 {isArrayNotEmpty(props.faredata) &&
                   props.faredata.length !== 0 &&
                   props.faredata !== null && (
                     <FlightSummaryFareDetails
                       faredata={onward_fare}
                       bktype={props.bktype}
                       supplier={props.onward_supplier}
                       bookingType={state.onward_list[0].bktype}
                       bk_region_type={props.bk_region_type}
                       userCurrency={props.userCurrency}
                     />
                   )}

                 {/* splifare payment details onward payment and return payment we are showing seperaly */}
                 {/* <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                                  <h5 className="title mb-2">Payment Details</h5>
                                  {isArrayNotEmpty(props.payment_data) && (<FlightSummaryPaymentDetails payment_data={onward_payment} />)}
                                  <br />
                              </div> */}
               </div>

               {isArrayNotEmpty(state.onward_void) && (
                 <VoidSummaryDetails
                   voidFareResp={state.onward_void[0]}
                   flowType={"split"}
                 />
               )}

               {isArrayNotEmpty(state.onward_cancel_void) && (
                 <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                   <h5 className="title d-flex justify-content-between">
                     Void Split PNR{" "}
                     <span
                       className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                       onClick={openSplitMOre}
                     >

                       <div
                         className={
                           "showDetails " +
                           (editvoidMore ? "active" : "inactive")
                         }
                       >
                         <ul className="d-flex flex-column">
                           <li>
                             <Link to="#" onClick={handleIssueTicket}>
                               Issue Ticket
                             </Link>
                           </li>
                           {state.cancelled_void_ticket_onward.length !== state.onward_cancel_void.length &&
                           <li>
                             <Link to="#" onClick={handleCancelBooking}>
                               Cancel Booking
                             </Link>
                           </li>
                          }
                         </ul>
                       </div>
                     </span>
                   </h5>

                   {isArrayNotEmpty(state.onward_cancel_void) && (
                     <SplitPnr
                       res={state.onward_cancel_void}
                       type={"Void"}
                       voidEnable={state.voidenable}
                       pnr={pnrFetch}
                     />
                   )}
                 </div>
               )}

               {isNotNull(refundDataOnward) && (
                 <FlightSummaryRefund
                   refundResp={refundDataOnward}
                   bookedTo={props.bookingData[0].buser_type}
                 />
               )}

                     {props.onward_supplier===4 && state.flow_type === "split" && props.indigoRefundOnwardList.length!==0 &&
                         <IndigoSummaryDetails indigoRefundList={props.indigoRefundOnwardList}/>}

               {isNotNull(refundDataOnward) &&
                 isArrayNotEmpty(state.refund_split_onward) && (
                   <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                     <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                       <h5 className="title d-flex justify-content-between">
                         Refund Split PNR{" "}
                         <span
                           className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                           onClick={openSplitMOre}
                         >

                           <div
                             className={
                               "showDetails " +
                               (editvoidMore ? "active" : "inactive")
                             }
                           >
                             <ul className="d-flex flex-column">
                               <li>
                                 <Link to="#" onClick={handlerefundIssueTicket}>
                                   Issue Ticket
                                 </Link>
                               </li>

                               <li>
                                 <Link
                                   to="#"
                                   onClick={handlerefundCancelBooking}
                                 >
                                   Cancel Booking
                                 </Link>
                               </li>
                             </ul>
                           </div>
                         </span>
                       </h5>
                       <SplitPnr
                         res={state.refund_split_onward}
                         type={"Refund"}
                         refundEnable={state.refundenable}
                         pnr={pnrFetch}
                       />
                     </div>
                   </div>
                 )}
                {state.onward_hold_split_pnr && props.reissue_split_tag && props.showOnholdowcontinue &&
                             <div className="text-right buttonGrop border-0 pt-0">

                              <Button
                                 size="xs"
                                 variant="primary"
                                 className="mr-3"
                                 onClick={fireTicketing("Onward")}
                              >Continue</Button>
                               </div>
                            }
 {props.bookingData!==undefined && props.airportdata!==undefined && props.airportdata.length!==0 && isArrayNotEmpty(state.reissued_onward) &&
             //  <div className="resultSection boxShadow bg-white PassengerDetails mb-4" >
              <FlightReissueSplit bookingData={props.bookingData} type={"Onward"} reissued_data = {[state.reissued_onward]} airportdata={props.airportdata} refund={props.staterefund} triptype={props.triptype} cus_code = {props.cus_code} openshareevent = {props.openshareevent} sendTid = {props.sendTid}/>
             //  </div>
            }
               {state.onwardReissueList !== undefined &&
                 state.onwardReissueList.length > 0 &&
                 state.onwardReissueList.map((seg, index) => (
                   <div className="resultSection history boxShadow bg-white PassengerDetails mb-4">
                     <ShowHide icon="" title="History">
                       <div className="showHide-content pl-0 pr-0">
                         {isNotNull(seg) && (
                           <Reissue
                             res={seg}
                             fareData={props.faredata}
                             bkngDetails={props.bookingData}
                             airportdata={props.airportdata}
                             triptype={props.triptype}
                             sectortab={props.bktype}
                             supplier={props.supplier}
                             bk_region_type={props.bk_region_type}
                           />
                         )}
                       </div>
                     </ShowHide>
                   </div>
                 ))}
             </>
           )}
         </>
       )}

       {/* Return sector and segment details */}

       {state.flow_type !== undefined && (state.flow_type === "split" || props.normalTFtwoLcc) && (
         <div className="d-flex justify-content-end">
           {/* <div className="clickTOShow d-flex"> */}
                          {props.returnshowitinerary && props.booking_status !== "47" && props.returnshowitinerary && props.booking_status !== "22" && props.booking_status !== "21" && props.booking_status !== "34" && props.booking_status !== "35" && props.booking_status !== "25" && <div className='socialIcons'>
                           <Button className="prient ezyIcon icon-share" onClick={handleShare} variant="link"></Button>
                </div>}
           {props.booking_status !== "47" && props.bookingData[0].booking_status !== "21" &&  props.booking_status !== "25" && props.booking_status !== "34" &&
           <span
             className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
             onClick={openSecEdit}
           >

             <div className={"showDetails " + (editSec ? "active" : "inactive")}>
               <ul className="d-flex flex-column"> 
                                 {props.bktype !=="22" && context.logindata.roles.includes('B_S_V_B') && props.return_supplier === 0 && !state.onhold_data_return &&
                                    <li>
                                      <Link to="#" onClick={handleVoidBooking}>Void Ticket</Link>
                                    </li>
                                  }
                                  {props.bktype !=="22"  && context.logindata.roles.includes('B_S_V_C') && props.return_supplier === 0 && !state.onhold_data_return &&
                                    <li>
                                      <Link to="#" onClick={handleVoidCancel}>Void/Cancel</Link>
                                    </li>
                                  }
                                  {props.bktype !=="22" && props.staterefund && props.return_supplier === 0 && !state.onhold_data_return  &&
                                   <li>
                                    <Link to="#" onClick={handleRefundTicket}>Refund Ticket</Link>
                                   </li>
                                  }
                                  {/* { state.flt_supplier === 0 && context.logindata.roles.includes('B_S_T_R') && state.bkng_det[0].booking_status == "23" && !state.onward_hold_varient &&
                                   <li>
                                     <Link to="#" onClick={handleReissueTicket}>Reissue Ticket</Link>
                                   </li>
                                 } */}
                 {state.enableReturnIti && !state.return_hold_split_pnr &&
                   (props.bktype === "34" || props.retrunEdit) && (
                     <li>
                       <Link to="#" onClick={handleEdiyBooking}>
                         Edit Booking
                       </Link>
                     </li>
                   )}
                 {props.bktype === "23" && props.return_supplier === 0 && !state.return_hold_split_pnr && (
                   <li>
                     <Link to="#" onClick={handleReissueReturn}>
                       Reissue Ticket
                     </Link>
                   </li>
                 )}

                 {props.bktype != "22" &&
                   props.bktype != "21" &&
                   props.bktype != "35" && (
                     <li>
                       <Link
                         to="#"
                         onClick={() =>
                           handleDownloaddocsReturn(props.pax_det[1])
                         }
                       >
                         Download Docs
                       </Link>
                     </li>
                   )}
                   {props.enableIndigo && props.bktype === "23" && props.return_supplier === 4 &&
                        <li>
                          <Link to="#" onClick={() => showIndigoRefundPopup("R")} >Refund Booking</Link>
                        </li>
                       }
                   {(props.bktype == "22" || props.bktype == "23") && props.return_supplier === 0 && props.enablertConfirmFunctions && props.showrtReissuelink &&(
                     <li>
                       <Link
                         to="#"
                         onClick={hancleCancelBookFlow}
                       >
                         Cancel Booking
                       </Link>
                     </li>
                   )}
                {state.return_hold_split_pnr && state.onhold_data_return && <li>
                       <Link
                         to="#"
                         onClick={handleReturnOnHoldSplit}
                       >
                         Split PNR
                       </Link>
                     </li>
                }
                {/* {context.logindata.roles.includes('F_I_P_D') && props.bktype === "23" && props.return_supplier === 0 &&
                     <li>
                       <Link to="#" onClick={handleInsertPnr}>Insert PNR</Link>
                   </li>
                 } */}

                {context.logindata.roles.includes('F_I_P_D') && props.bktype === "23" && props.return_supplier === 0 &&
                                   <li>
                                     <Link to="#" onClick={() => manualReissue()}>Manual Reissue</Link>
                                   </li>
                                   }
                                 {/* {props.bktype === "23" && props.return_supplier === 4  && <li> <Link to="#" onClick={()=>props.openrefundPopup()}>Refund Flow</Link></li>} */}
               </ul>
             </div>{" "}
           </span> }
           {/* <ul className="d-flex flex-column">
                         <li>
                         <Link to="#" onClick={handleEdiyBooking}>Edit Booking</Link>
                         </li>
                       </ul> */}

                       
         </div>
       )}

       {isArrayNotEmpty(state.return_list) && (
         <>
          {props.return_supplier && props.return_supplier !==0 ? (
          <div className="ltag lcc ezyIcon icon-nFlight">LCC </div>
        ): (
          <div className="ltag fcc ezyIcon icon-nFlight">FSC</div>
        )}

           <FlightSummarySectorHeader
             data={state.return_list}
             airport_data={props.airportdata}
             isEdit={props.isEdit}
             triptype={props.triptype}
             supplier={props.return_supplier}
             sectorTab={props.bookingType}
           />
           {props.triptype !== "3" && props.triptype !== "1" ?
             <>
               <FlightSummarySegmentDetails
                 data={state.return_list}
                 airport_data={props.airportdata}
                 isEditReturn={state.enableEditReturn}
                 isEdit={props.isEdit}
                 supplier={props.return_supplier}
               />
             </>:<>
               <FlightSummarySegmentDetails
                 data={state.return_list}
                 airport_data={props.airportdata}
                 isEditReturn={state.enableEditReturn}
                //  isEdit={props.isEdit}
                 supplier={props.return_supplier}
               />
               </>}

           {isNotNull(state.flow_type) && (state.flow_type === "split" || props.normalTFtwoLcc) && (
             <>
               <div className="resultSection boxShadow bg-white PassengerDetails mb-4" ref={returnFunctionalitiesREF}>
                 <h5 className="title">Passenger Details</h5>
                 {/* Pax Details Section */}
                 {/*  passeneger details for split fare onward pax and return pax we are showing seperatly */}
                 {isArrayNotEmpty(props.pax_det) &&
                   props.pax_det !== undefined &&
                   props.pax_det !== null && (
                     <FlightSummaryPaxDetails
                     onhold_selected_split={state.flow_type_fbf}
                       pax_det={return_pax}
                       enableVoidBookingReturn={state.type_selected == "Return" && state.enableVoidBookingReturn ? true : false}
                       voidedCancelled={props.voidedCancelled}
                       disableOnward={disablevOnward}
                       disableCheckbox={props.disableCheckbox}
                       tktsVoided={props.tktsVoided}
                       voidTickets={voidBookReq}
                       segment={"return"}
                       journey_type={"R"}
                       enableRefundReturn={state.type_selected == "Return" && state.enableRefundBookingReturn ? true : false}
                       onTkts={onward_pax_tkts}
                       retTkts={return_pax_tkts}
                       refundAvailable={state.refundAvailable}
                       enableRefundTkt={state.type_selected == "Return" && props.enableRefundTkt ? true : false}
                       isEditReturn={state.enableEditReturn}
                       enbaleReissueReturn={state.enbaleReissueReturn}
                       sendReissueTicktsReturn={reissueReturnPax}
                       sendReissueSplitReturn={reissuesplitReturn}
                       hideReturn={state.hideReturn}
                       bookingId={props.bookingData[0].booking_id}
                       enableRsptpnr = {state.onhold_return_split}
                       tid = {state.return_tid}
                       sendOnHoldTktsReturn={onHoldReturnTickets}
                       checkboxFocus = {props.checkboxFocus}
                       checkboxRState = {state.type_selected == "Return" && state.checkboxRState ? true : false}
                       onward={state.onward}
                     />
                   )}
                 <Row>
                   <Col xs={12} className="d-flex refundBotBlock">
                     {props.enableRefundTkt && !state.onward && (
                       <RefundTypeReturn
                         type={"Return"}
                         ticketsList={state.selectedTickets}
                         bookDetails={props.bookDetails}
                         sendRefund={continueRefunds}
                       />
                     )}
                     {props.enableRefundTkt && !state.onward && (
                       <Form.Check
                         type="checkbox"
                         label="Cancel Booking"
                         custom
                         id="bk"
                         value={state.isCancelBooking}
                         onChange={handleChange}
                       />
                     )}
                   </Col>
                 </Row>
               </div>
               {props.return_supplier === 0 && isArrayNotEmpty(return_pax[0]) && return_pax[0].filter(s => s.split_pnr && s.stsid == "23").length > 0 &&
                      <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1">
                     <h5 className="title mb-2 mt-2"> Reissue Split PNR</h5>
                     <SplitReissuePNR paxList={return_pax[0]} />
                     </div> }
               <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                 <h5 className="title mb-2 d-flex justify-content-between">
                   Fare Details{" "}
                   {/* <span
                     className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                     ref={ref}
                     onClick={openeeditPayment}
                   >

                     <div
                       className={
                         "showDetails " + (editPayment ? "active" : "inactive")
                       }
                     >
                       <ul className="d-flex flex-column">
                         <li>
                           <Link to="#">Edit Fare</Link>
                         </li>
                         <li>
                           <Link to="#">Update Fare</Link>
                         </li>
                       </ul>
                     </div>{" "}
                   </span> */}
                 </h5>
                 {/*  <span className="itnry"><em>Itinerary Ref # :</em> EZ125198-1</span> */}
                 {/* Fare details section */}
                 {/* fare details onward fare and return fare we are showing seperaly */}
                 {isArrayNotEmpty(props.faredata) &&
                   props.faredata.length !== 0 &&
                   props.faredata !== null && (
                     <FlightSummaryFareDetails
                       faredata={return_fare}
                       bktype={props.bktype}
                       supplier={props.return_supplier}
                       bookingType={state.return_list[0].bktype}
                       bk_region_type={props.bk_region_type}
                       userCurrency={props.userCurrency}
                     />
                   )}

                 {/* splifare payment details onward payment and return payment we are showing seperaly */}
                 {/* <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                                  <h5 className="title mb-2">Payment Details</h5>
                                  {isArrayNotEmpty(props.payment_data) && (<FlightSummaryPaymentDetails payment_data={return_payment} />)}
                                  <br />
                              </div> */}
               </div>

               {isArrayNotEmpty(state.return_void) && (
                 <VoidSummaryDetails
                   flowType={"split"}
                   voidFareResp={state.return_void[0]}
                 />
               )}
               {isArrayNotEmpty(state.return_cancel_void) && (
                 <div className="resultSection boxShadow bg-white PassengerDetails  mb-4">
                   <h5 className="title d-flex justify-content-between">
                     Void Split PNR{" "}
                     <span
                       className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                       onClick={openSplitMOre}
                     >

                       <div
                         className={
                           "showDetails " +
                           (editvoidMore ? "active" : "inactive")
                         }
                       >
                         <ul className="d-flex flex-column">
                           <li>
                             <Link to="#" onClick={handleIssueTicket}>
                               Issue Ticket
                             </Link>
                           </li>
                         {state.cancelled_void_ticket_return.length !== state.return_cancel_void.length &&
                           <li>
                             <Link to="#" onClick={handleCancelBooking}>
                               Cancel Booking
                             </Link>
                           </li>
                         }
                         </ul>
                       </div>
                     </span>
                   </h5>
                   {isArrayNotEmpty(state.return_cancel_void) && (
                     <SplitPnr
                       res={state.return_cancel_void}
                       type={"Void"}
                       voidEnable={state.voidenable}
                       pnr={pnrFetch}
                     />
                   )}
                 </div>
               )}
               {isNotNull(refundDataReturn) && (
                 <FlightSummaryRefund
                   refundResp={refundDataReturn}
                   bookedTo={props.bookingData[0].buser_type}
                 />
               )}

              {props.return_supplier===4 && props.indigoRefundReturnList.length!==0 &&
                <IndigoSummaryDetails indigoRefundList={props.indigoRefundReturnList} />}

               {isNotNull(refundDataReturn) &&
                 isArrayNotEmpty(state.refund_split_return) && (
                   <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                     <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                       <h5 className="title d-flex justify-content-between">
                         Refund Split PNR{" "}
                         <span
                           className="moreDetails bookingInfo onclickTag ezyIcon icon-more"
                           onClick={openSplitMOre}
                         >
                           <div
                             className={
                               "showDetails " +
                               (editvoidMore ? "active" : "inactive")
                             }
                           >
                             <ul className="d-flex flex-column">
                               <li>
                                 <Link to="#" onClick={handlerefundIssueTicket}>
                                   Issue Ticket
                                 </Link>
                               </li>

                               { state.showCancel && <li>
                                 <Link
                                   to="#"
                                   onClick={handlerefundCancelBooking}
                                 >
                                   Cancel Booking
                                 </Link>
                               </li>}
                             </ul>
                           </div>
                         </span>
                       </h5>
                       <SplitPnr
                         res={state.refund_split_return}
                         type={"Refund"}
                         refundEnable={state.refundenable}
                         pnr={pnrFetch}
                       />
                     </div>
                   </div>
                 )}
                           {state.return_hold_split_pnr && props.reissue_split_tag && props.showOnholdrtcontinue &&
                             <div className="text-right buttonGrop border-0 pt-0">

                              <Button
                                 size="xs"
                                 variant="primary"
                                 className="mr-3"
                                 onClick={fireTicketing("Return")}
                              >Continue</Button>
                               </div>
                            }

           {props.bookingData!==undefined && props.airportdata!==undefined && props.airportdata.length!==0 && isArrayNotEmpty(state.reissued_return) &&
             //  <div className="resultSection boxShadow bg-white PassengerDetails mb-4" >
              <FlightReissueSplit bookingData={props.bookingData} type={"Return"} reissued_data = {[state.reissued_return]} airportdata={props.airportdata} refund={props.staterefund} triptype={props.triptype} cus_code = {props.cus_code} openshareevent = {props.openshareevent} sendTid = {props.sendTid} rtcancelledPax={props.rtcancelledPax}/>
             //  </div>
            }
               {state.returnReissueList !== undefined &&
                 state.returnReissueList.length > 0 &&
                 state.returnReissueList.map((seg, index) => (
                   <div className="resultSection history boxShadow bg-white PassengerDetails mb-4">
                     <ShowHide icon="" title="History">
                       <div className="showHide-content pl-0 pr-0">
                         {isNotNull(seg) && (
                           <Reissue
                             res={seg}
                             bkngDetails={props.bookingData}
                             fareData={props.faredata}
                             airportdata={props.airportdata}
                             triptype={props.triptype}
                             supplier={props.supplier}
                             bk_region_type={props.bk_region_type}
                           />
                         )}
                       </div>
                     </ShowHide>
                   </div>
                 ))}
             </>
           )}
         </>
       )}
     </>
   );
 }

 export default FlightSummarySectors;
