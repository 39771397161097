import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Alert, Col, Form, Table, Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import Button from "../../../common/buttons/Button";
import RegionType from "../../common/RegionType";
import SalesChannel from "../../common/SalesChannel";
import DatePicker from "react-datepicker";
import DateUtils from "../../../commonUtils/DateUtils";
import {
  getOfcIdList,
  searchService,
  getCompanyList,
  getCompaniesList,
  fetch_company_list,
} from "./operations";
import { Typeahead } from "react-bootstrap-typeahead";
import { Context } from "../../../../../App";
import PredectiveSearch from "../../../../services/predective";
import debounce from "lodash.debounce";
import { isArrayNotEmpty } from "../../../commonUtils/validators";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
const _ = require("lodash");

let airlineMaster = require("../../../masterData/AirlinesMasterData.json");
let class_Code_List = require("../../../masterData/BookingClassCode.json");

/**
 * Initial State Declaration
 */

const initialState = {
  airportList: [],
  selAirport: [],
  airportsOW: [],
  airportsRT: [],
  src: "",
  dest: "",
  selOrigin: "",
  selDest: "",
  companyList: [],
  selCompany: [],
  branchMenu: [],
  bid: "",
  markupList: [],
  salesChanel: "",
  classCode: [],
  region_type: "",
  resetChannel:false,
  resetRegion:false,
  officeIds: [],
  ofcs: [],
  region_type_edit: "",
  selDates: [],
  setIHdate: new Date(),
  setHDate: new Date(),
  notiMessageShow:false,
  notiMessage: '',
  notiVarient: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
      ...state,ofcs:[],salesChanel:"",selCompany:[],dest:[],selOrigin:[],selDest:[],airportsOW:[],airportsRT:[],selDates:[],classCode:[],
      resetRegion:true, resetChannel:true,
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function ServiceType(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [serviceType, setServiceType] = useState(props.type);

  const uref = useRef();
  const originref = useRef();

  /**
 * @author: Lakshmi
 * @description: This function will Load the Branches based on the selected OFC ID
 * @date:14-09-2020
 * @function : getBranchesWithOfficeId
 */
 useEffect(() => {
  if(state.ofcs.length>0 &&state.salesChanel=="B2B"){
    
    AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.ofcs[0]?.value,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.salesChanel}).then(response => {
      if (response.data.suc){
        let authData = []
        let BRANCHES = response.data.res.filter((val)=>{
                return val.fk_branch_id != null;
              })
              for (let val of BRANCHES){
                let auth = {
                  "value": val.fk_branch_id
                  ,
                  "label": val.office_name
                }
                authData.push(auth)
              }
        let branchesList = authData.map((branch) => (
          <option value={branch.value}>{branch.label}</option>
        ));
        state.branchMenu = branchesList;
        dispatch({ type: "branchMenu", payload: branchesList });
        dispatch({ type: "branches", payload: branchesList });
      }
    })
  }
}, [state.ofcs,state.salesChanel])

  const updateSalesChanel = (salesChanel) => {
    dispatch({ type: "resetChannel", payload: false })
    dispatch({ type: "resetRegion", payload: true });
    dispatch({ type: "selCompany", payload: [] })
    dispatch({ type: "bid", payload: [] })
    dispatch({ type: "region_type", payload: "" })
    dispatch({type:"ofcs", payload:[]})
    dispatch({ type: "salesChanel", payload: salesChanel });
  };
  const handleRegionType = (e) => {
    dispatch({ type: "resetRegion", payload: false });
  };
  const updateRegion = (region) => {
    dispatch({ type: "resetRegion", payload: false });
    dispatch({type:"ofcs", payload:[]})
    dispatch({ type: "selCompany", payload: [] })
    dispatch({ type: "bid", payload: [] })
    dispatch({ type: "region_type", payload: region });
  };
  useEffect(() => {
    getOfcIdList().then((res) => {
      let list = [];
      if (res.data.suc) {
        for (let val of res.data.res) {
          const req = {
            value: val.id,
            label: val.office_id,
          };
          list.push(req);
        }
        dispatch({ type: "officeIds", payload: list });
      }
    });
  }, [state.salesChanel, state.region_type]);

  const updateOfcId = (ofc) => {
    dispatch({type:"selCompany", payload:[]})
    dispatch({type:"bid", payload:[]})
    dispatch({ type: "ofcs", payload: ofc });
    if (ofc.length !== 0) {
      if (state.salesChanel !== "") {
        if (state.salesChanel === "B2C") {
            const payload = {
              office_id: ofc[0].value,
              userid: context.logindata.uid,
              region_type: state.region_type,
              sales_channel: state.salesChanel,
            };
            fetch_company_list(payload).then((response) => {
              if (response.data.suc) {
                let authData = [];
                for (let val of response.data.res) {
                  let auth = {
                    label: val.company_name,
                    value: val.fk_company_id,
                    sales_channel: val.sales_channel
                  };
                  authData.push(auth);
                }
                let cmpanis = authData.filter((obj)=>obj.sales_channel===state.salesChanel)
                dispatch({ type: "companyList", payload: cmpanis });
              }
            });

        } else {
          let payload = "";
          if (context.logindata.is_admin) {
            payload = { region_type: state.region_type, ofc_id: ofc[0].value };
          } else {
            payload = {
              region_type: state.region_type,
              ofc_id: ofc[0].value,
              salesChanel: state.salesChanel,
              uid: context.logindata.uid,
            };
          }
        }
      }
    }
    dispatch({ type: "ofcID", payload: ofc.length != 0 ? ofc[0].value : "" });
  };
  const handleBranchChange = (e) => {
    let selbr = state.branches.filter((val)=>{
      if(val.value){
       if ( val.value == e.target.value){
        dispatch({ type: 'data', payload: [{"value":val.value, "label":val.children}]})
       }
      }
        else if(val.props.value){
        if(val.props.value == e.target.value){
          dispatch({ type: 'data', payload: [{"value":val.props.value, "label":val.props.children}] })
        }
      }
    })
    // dispatch({ type: 'selected_branch', payload: selbr });
    // state.selected_branch.push(selbr)
    // setCompanyPick("");
    dispatch({ type: "companyList", payload: [] });
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    dispatch({ type: "bid", payload: value });
    const payload = {"office_id":state.ofcs[0].value,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.salesChanel,"branch_id":e.target.value}
    
      fetch_company_list(payload).then(response => {
        if (response.data.suc) {
          let authData = []
          for (let val of response.data.res){
            let auth = {
              "value": val.fk_company_id,
              "label": val.company_name,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }

          // setCompanyPick(authData)
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.salesChanel)
          dispatch({type:"companyList", payload:cmpanis})
        }
      })

    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

  const handleCompany = (e) => {
    dispatch({ type: "selCompany", payload: e });
  };
  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOrigin = (input, e) => {
    dispatch({ type: "src", payload: "" });
    dispatch({ type: "orignData", payload: [] });
    dispatch({ type: "selOrigin", payload: [] });
    if (input.length > 0) {
      dispatch({ type: "clearob", payload: true });
    } else {
      dispatch({ type: "clearob", payload: false });
    }
    if (input.length > 2) {
      state.selOrigin = [];
      // predectiveCall(input, 'airportsOW')
      let obj = {
        textValue: input,
        type: "airportsOW",
      };
      debouncedSave(obj);
    } else {
      state.selOrigin = [];
      dispatch({ type: "airportsOW", payload: [] });
    }
  };

  // highlight-starts
  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue, obj.type), 500),
    [] // will be created only once initially
  );
  // highlight-ends

  //=====================================
  // This function will call the service to get the Airport data
  //=====================================
  const predectiveCall = async (input, route) => {
    if (input.length > 1) {
      await PredectiveSearch.retreieveSearch(input).then((data) => {
        dispatch({ type: route, payload: data.data !== "" ? data.data : [] });
      });
    } else {
      dispatch({ type: route, payload: [] });
    }
  };

  const handleOnOrigin = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "src", payload: obj[0].airportCode });
      dispatch({ type: "orignData", payload: obj });
      dispatch({ type: "selOrigin", payload: obj });
      // uref.current.focus();
      // errorRemoveBlur();
      // props.sendSrc(obj);
    }
  };
  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnDest = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "dest", payload: obj[0].airportCode });
      dispatch({ type: "destData", payload: obj });
      dispatch({ type: "selDest", payload: obj });
      // openOnwardCal();
      // errorRemoveBlur();
      // props.sendDest(obj);
    }
  };

  //=====================================
  // This function will get the dest data
  //=====================================
  const handleDestination = (input, e) => {
    dispatch({ type: "dest", payload: "" });
    dispatch({ type: "destData", payload: [] });
    dispatch({ type: "selDest", payload: [] });
    if (input.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    if (input.length > 2) {
      state.selDest = [];
      //predectiveCall(input, 'airportsRT')
      let obj = {
        textValue: input,
        type: "airportsRT",
      };
      debouncedSave(obj);
    } else {
      state.selDest = [];
      dispatch({ type: "airportsRT", payload: [] });
    }
  };
  const booking = [
    { label: "Booking", value: "Booking" },
    { label: "Travel", value: "Travel" },
  ];
  const activeDesti = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add("activeSerField");
  };
  const setDates = (data) => {
    dispatch({ type: "selDates", payload: data });
  };
  const changedate = (date) => {
    if (date) {
      let y = _.cloneDeep(date);
      setFromDate(y);
      let d = date;
      d.setDate(d.getDate() + 1);
      console.log("new date", d);
      dispatch({ type: "setHDate", payload: d });
    }
  };
  const resetSearch = () => {
    dispatch({ type: "clear", payload: true });
    setServiceType("")
      setFromDate('')
       setToDate('')
  };

  const hidemessage =() => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const Validation = ()=> {
    if (state.salesChanel==""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select sales channel' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    if (state.region_type==""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select region' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    if (state.ofcs=="" || state.ofcs==undefined ){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select office id' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    if (state.selCompany=="" || state.selCompany==undefined){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select company' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    return true;
  }
  const handleSearch = (obj, type) => {
    if (Validation()){
    let payload = {
      "service_type": serviceType,
      "sales_channel": state.salesChanel,
      "region_type": state.region_type,
      "office_id": state.ofcs.length!=0?state.ofcs[0].label:"",
      "cid": state.selCompany.length!=0?state.selCompany[0].value:"",
      "bid": state.bid.length!=0?state.bid[0].value:"",
      "src_code": state.src,
      "dest_code": state.dest,
      "app_type": state.selDates.length!=0?state.selDates[0].label:"",
      "start_dt": fromDate!=""?DateUtils.convertStringToDate(fromDate):"",
      "end_dt": toDate!=""?DateUtils.convertStringToDate(toDate):"",
      "rbd": state.classCode.length!=0?state.classCode[0].label:"",
      "user_id": context.logindata.uid,
    };
    searchService(payload).then((response) => {
      if (response.suc) {
        dispatch({ type: "markupList", payload: response.res });
      }
    });
   }
  };

  return (
    <>
    {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <div className="mt-4">
        <Form.Row>
          <SalesChannel
            sendSalesChanel={updateSalesChanel}
            edit_sales={props.edit_salesChanel}
            resetChannel={state.resetChannel}
          />
          <RegionType
            sendResponse={(e) => handleRegionType(e)}
            regionType={updateRegion}
            regionUpdate={props.edit_region ? props.edit_region : ""}
            flowType={"Service"}
            resetRegion={state.resetRegion}
          />
          <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="supplier"
          >
            <Select
              options={state.officeIds}
              values={state.ofcs}
              onChange={updateOfcId}
            />
            <Form.Label>
              Office Id <sup>*</sup>
            </Form.Label>
          </Form.Group>
          {state.salesChanel=="B2B" &&
              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value={state.data}>Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
            }
          <Form.Group
            as={Col}
            xs="3"
            className="selectplugin"
            controlId="company_id"
          >
            <Select
              //disabled={state.enableView || state.enbaleUpdate}
              options={state.companyList}
              values={state.selCompany}
              onChange={handleCompany}
            />
            <Form.Label>
              Company<sup>*</sup>
            </Form.Label>
          </Form.Group>

          <Form.Group as={Col} xs="3" className="selectplugin revMan">
            <Typeahead
              //defaultInputValue={state.selectedOrigin}
              id="originmaster"
              labelKey={(airport) =>
                `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`
              }
              onInputChange={handleOrigin}
              options={state.airportsOW}
              selected={state.selOrigin}
              onChange={(airport) => handleOnOrigin(airport)}
              filterBy={[
                "cityName",
                "airportName",
                "airportCode",
                "countryName",
              ]}
              //onFocus={originOnFocus} onBlur={originOnBlur}
              placeholder="From"
              readOnly
              autoFocus
              minLength={1}
              // onFocus={activeOrigin}
              renderMenuItemChildren={(airport) => (
                <>
                 <div className="searchTabb">
                  <span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                    <strong> {airport.cityName}, </strong>
                    <span
                      className="airportContname"
                      title={airport.countryName}
                    >
                      {airport.countryName}{" "}
                    </span>
                    <small>{airport.airportCode}</small>
                  </div>

                  <span className="airportName" title={airport.airportName}>
                    {airport.airportName}{" "}
                  </span>
                  </div>
                </>
              )}
              ref={originref}
            />
            <Form.Label>
              Source Airport
            </Form.Label>
          </Form.Group>

          <Form.Group as={Col} xs="3" className="selectplugin revMan">
            <Typeahead
              //defaultInputValue={state.selectedOrigin}
              id="basic-typeahead-single"
              labelKey={(airport) =>
                `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`
              }
              onInputChange={handleDestination}
              options={state.airportsRT}
              //  onFocus={originOnFocus} onBlur={originOnBlur}
              placeholder="To"
              selected={state.selDest}
              onChange={(airport) => handleOnDest(airport)}
              filterBy={[
                "cityName",
                "airportName",
                "airportCode",
                "countryName",
              ]}
              readOnly
              minLength={1}
              // onFocus={activeDesti}
              renderMenuItemChildren={(airport) => (
                <>
                <div className="searchTabb">
                  <span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                    <strong> {airport.cityName}, </strong>
                    <span
                      className="airportContname"
                      title={airport.countryName}
                    >
                      {airport.countryName}{" "}
                    </span>
                    <small>{airport.airportCode}</small>
                  </div>

                  <span className="airportName" title={airport.airportName}>
                    {airport.airportName}{" "}
                  </span>
                  </div>
                </>
              )}
              ref={uref}
            />
            <Form.Label>
              Destination Airport
            </Form.Label>
          </Form.Group>
          <Form.Group
            as={Col}
            lg={3}
            md={3}
            className="selectplugin"
            controlId="aplType"
          >
            <Select
              options={booking}
              searchable={false}
              values={state.selDates}
              onChange={(value) => setDates(value)}
            />
            <Form.Label>Applicable Type</Form.Label>
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} className="mb-0">
            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                md={6}
                className="selectplugin datePicker"
                controlId="fromDate"
              >
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => (setToDate(), changedate(date))}
                  fromDate={fromDate}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  toDate={toDate}
                  minDate={new Date()}
                />
                <span className="calendar ezyIcon icon-newcalendar"></span>
                <Form.Label>From Date</Form.Label>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                md={6}
                className="selectplugin datePicker"
                controlId="toDate"
              >
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date) + 1}
                  fromDate={fromDate}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  toDate={toDate}
                  minDate={
                    fromDate
                      ? props.hotel !== undefined
                        ? state.setHDate
                        : fromDate
                      : props.hotel !== undefined
                      ? state.setIHdate
                      : new Date()
                  }
                  className="datefield"
                />
                <span className="calendar ezyIcon icon-newcalendar"></span>
                <Form.Label>To Date</Form.Label>
              </Form.Group>
            </Form.Row>
          </Form.Group>
          <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="code_id"
          >
            <Select
              // multi
              options={class_Code_List}
              values={state.classCode}
              onChange={(value) =>
                dispatch({ type: "classCode", payload: value })
              }
            />
            <Form.Label>RBD</Form.Label>
          </Form.Group>

        </Form.Row>

        <div className="buttonGrop pb-0 text-right mb-4">

            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={resetSearch}
            >
              Reset
            </Button>
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={handleSearch}
            >
              Search
            </Button>

          </div>
      </div>
      {isArrayNotEmpty(state.markupList) && (
        <Form.Row>
          <Form.Group as={Col} xs={12}>
            <div className="selectedRout tableLayout">
              <Table>
                <thead>
                  {serviceType == "Markup" && (
                    <tr>
                      <th>Markup Name</th>
                      <th>Markup On</th>
                      <th>Markup Type</th>
                      <th>Markup Value</th>
                      <th>Markup End date</th>
                      <th>Markup Sector</th>
                      <th>Markup RBD</th>
                      <th>Markup Airport</th>
                      <th>Markup Airlines</th>
                    </tr>
                  )}
                  {serviceType == "ServiceFee" && (
                    <tr>
                      <th>Service Fees Name</th>
                      <th>Service Fees On</th>
                      <th>Service Fees Type</th>
                      <th>Service Fees Value</th>
                      <th>Service Fees End date</th>
                      <th>Service Fees Sector</th>
                      <th>Service Fees RBD</th>
                      <th>Service Fees Airport</th>
                      <th>Service Fees Airlines</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {state.markupList.map((com, index) => (
                    <React.Fragment key={index}>
                      {serviceType == "Markup" &&
                        <tr>

                            <td>{com.markup_name}</td>
                            <td>{com.markup_on}</td>
                            <td>{com.markup_type}</td>
                            <td>{com.markup_value}</td>
                            <td>{com.markup_end_date}</td>
                            <td>{com.sector}</td>
                            <td>{com.rbd}</td>
                            <td>{com.src_code}</td>
                            <td>{com.dest_code}</td>

                        </tr>
                      }
                      {serviceType == "ServiceFee" &&
                        <tr>

                            <td>{com.service_fee_name}</td>
                            <td>{com.service_fee_on}</td>
                            <td>{com.service_fee_type}</td>
                            <td>{com.service_fee_value}</td>
                            <td>{com.service_fee_end_date}</td>
                            <td>{com.sector}</td>
                            <td>{com.rbd}</td>
                            <td>{com.src_code}</td>
                            <td>{com.dest_code}</td>

                        </tr>
                      }
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Form.Row>
      )}
    </>
  );
}
export default ServiceType;
