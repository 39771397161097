import React, { useCallback , useState } from 'react';
import Countdown from "react-countdown";
import { useHistory } from 'react-router-dom';
import SessionTimeOut from '../../../common/sessionTimeOut/SessionTimeOut';
/**
 * @description:This function will display the Count down for Traveller
 * @author: Lakshmi
 * @param {*}
 * @function Countdown
 * @date : 02-09-2020
 */
function Countdowns(props) {
  const history = useHistory();
 const [state, setstate] = useState({date:Date.now()})
  const sendData = useCallback((data) => {
    props.sendToReview(data)
  }, []);

  const results = useCallback((data) => {
    props.sendToResult(data)
  }, []);

  const Completionist = () => {
    return (
      <SessionTimeOut sendToResult={results} sendToReview={sendData} />
    );
  }
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {

    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  //Confirmation dialog fro delete the Branch

  return (
    <>

      <Countdown date={state.date + 900000} renderer={renderer} />

    </>
  )
}
export default React.memo(Countdowns)