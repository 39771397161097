
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import {apiPostHelper,apiGetHelper} from '../../../components/commonUtils/helper';
const HOTEL_PRE_BOOK_URL = process.env.REACT_APP_PRE_BOOK_URL
const API_URL = process.env.REACT_APP_B2B_URL


/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function Branch
 * @date : 06-10-20
 */

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const getCompanyTAList = async (obj, type) => {
    let branchesList = [];
    await AttCommonSerivce.getCompanyTAList(obj, type).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompaniesList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

/**
 * @description: Fetch compane details based on branch id
 * @author: Rambabu
 * @param {*}
 * @function companyList
 * @date : 15-10-2020
 */
 export const companyList = async (id) => {
    let company_res = '';
    await AttCommonSerivce.fetchcompany(id).then(response => {
        if (response.data.suc) {
            company_res = response.data.res;
        }
    })
    return Promise.resolve(company_res);
}


/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
 export const getTransactionData = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/reports/gettransreport`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
 export const saveReceipt = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/reports/save-trx`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
 export const updateReceipt = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/reports/update-trx`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
 export const getReceipts = async () => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiGetHelper(`${API_URL}/att/reports/get-trx`,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
 export const saveReceiptReversal = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/reports/save-rev-trx`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}