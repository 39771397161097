import React, { useState } from 'react';
function CityNameMemo(props) {
  const [mapping, setMapping] = useState(props.mapping);

  const airportInfo = mapping.filter((airport) => {
    return airport.airportCode === props.code
  })

  //console.log('d..........airportData...', airportInfo)
  if (airportInfo[0] && airportInfo[0].cityName !== '')
    return (<span>{airportInfo[0].cityName}</span>);
  else
    return ('')
}

export default React.memo(CityNameMemo)