/**
 * @description : Dash Board Details Will diplay in this page
 * @author :Praavs Kumar
 * @date : 04-09-2020
 */
import React, { useContext, useReducer, useState, useEffect } from 'react';
import { Alert, Col, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import moment from "moment";
import Moment from 'react-moment';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Tabs from '../common/tabs/Tabs';
import { Line } from 'react-chartjs-2';
import ConfirmedSales from './ConfirmedSales';
import OnHold from './OnHold';
import WaitingForReview from './WaitingForReview'

//=================
// Function Implementaton which will recevie props
//=================

let lineChartData = require('../masterData/airConfirmedBookingsData.json');

function AdminEzyDashBoard(props) {
  const [state, setState] = useState({
    active: 'cnfTab'
  })
  const airdata = {
    labels: ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu'],
    datasets: [
      {
        label: '',
        data: [12, 19, 33, 50, 12, 30, 22],
        fill: false,
        backgroundColor: 'rgb(53 198 53)',
        borderColor: 'rgba(53, 198, 53, 0.4)',
      },
    ],
  };
  const nonairdata = {
    labels: ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu'],
    datasets: [
      {
        label: '',
        data: [12, 19, 33, 50, 12, 30, 22],
        fill: false,
        backgroundColor: 'rgb(58 132 255)',
        borderColor: 'rgba(58, 132, 255, 0.4)',
      },
    ],
  };
  const cncldata = {
    labels: ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu'],
    datasets: [
      {
        label: '',
        data: [12, 19, 33, 50, 12, 30, 22],
        fill: false,
        backgroundColor: 'rgb(242 111 34)',
        borderColor: 'rgba(242, 111, 34, 0.4)',
      },
    ],
  };
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //let now =days ;
  //var day = days[ now.getDay() ];
  //var loIsDate = new Date(lsDate);

  const dateToFormat = new Date('dd-mm-yyyy');
  // let date_create = 
  const data = {
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    labels: lineChartData.data.labels,
    datasets: [
      // {
      // 	label: 'Count',
      // 	type: 'line',
      // //	data: [51, 65, 40, 49, 60, 37, 40],
      //  // data:airConfirmedSample.data.cnfairscount ,
      //  data: lineChartData.data.cnfairs.map(item => item.ccount),
      // 	fill: false,
      // 	borderColor: '#EC932F',
      // 	backgroundColor: '#EC932F',
      // 	pointBorderColor: '#EC932F',
      // 	pointBackgroundColor: '#EC932F',
      // 	pointHoverBackgroundColor: '#EC932F',
      // 	pointHoverBorderColor: '#EC932F',

      //   // yAxisID: 'y-axis-2'
      // },

      {
        label: 'Total price',
        type: 'line',
        //	data: [200, 185, 590, 621, 250, 400, 95],
        // data:airConfirmedSample.data.cnfairstotal,
        data: lineChartData.data.cnfairs.map(item => item.total),
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        //yAxisID: 'y-axis-1'
      }
    ]
  };

  const options = {
    responsive: true,
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    },
    scales: {
      xAxes: [
        {
          display: false,
          id: 'x-axis-1',
          gridLines: {
            display: false
          },

          //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
        }
      ],
      yAxes: [
        {
          type: 'line',
          display: false,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          labels: {
            show: false
          }
        },
        {
          type: 'line',
          display: false,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          labels: {
            show: false
          }
        }
      ]
    }
  };
  const hoteldata = {
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    labels: lineChartData.data.labels,
    datasets: [
      {
        label: 'Count',
        type: 'line',
        //	data: [51, 65, 40, 49, 60, 37, 40],
        // data:airConfirmedSample.data.cnfairscount ,
        data: lineChartData.data.cnfhtls.map(item => item.ccount),
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        yAxisID: 'y-axis-2'
      },

      {
        label: 'Total price',
        type: 'line',
        //	data: [200, 185, 590, 621, 250, 400, 95],
        // data:airConfirmedSample.data.cnfairstotal,
        data: lineChartData.data.othrbkns.map(item => item.total),
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        //yAxisID: 'y-axis-1'
      }
    ]
  }

  const hoteloptions = {
    scales: {
      xAxes: [
        {
          display: false,
          id: 'x-axis-1',
          gridLines: {
            display: false
          },

          //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
        }
      ],
      yAxes: [
        {
          type: 'line',
          display: false,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          labels: {
            show: false
          }
        },
        {
          type: 'line',
          display: false,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          labels: {
            show: false
          }
        }
      ]
    }
  };

  const content = {
    cnfTab: <ConfirmedSales />,
    Onhold: <OnHold />,
    wfReview: <WaitingForReview />
  };
  let totalCount = 0;
  lineChartData.data.cnfairs.forEach(item => {
    totalCount = totalCount + item.ccount;
  }
  );
  let totalCountHotel = 0;
  lineChartData.data.cnfhtls.forEach(item => {
    totalCountHotel = totalCountHotel + item.ccount;
  }
  );
  let totalCountHothers = 0;
  lineChartData.data.othrbkns.forEach(item => {
    totalCountHothers = totalCountHothers + item.ccount;
  }
  );
  let totalAmount = 0;
  lineChartData.data.cnfairs.forEach(item => {
    totalAmount = totalAmount + item.total;
  }
  );
  let totalAmountHotel = 0;
  lineChartData.data.cnfhtls.forEach(item => {
    totalAmountHotel = totalAmountHotel + item.total;
  }
  );
  let totalAmountOthers = 0;
  lineChartData.data.othrbkns.forEach(item => {
    totalAmountOthers = totalAmountOthers + item.total;
  }
  );

  console.log("lineChartData.data.cnfairs.map(item => item.total", lineChartData.data.cnfairs.map(item => item.total), lineChartData.data.cnfairs.map(item => item.ccount))
  return (
    <>
      <Header />
      <div className="container admindashBordLayout pt-3 mb-4">
        <h2>Dashboard</h2>
        <Row>
          <Col xs={4} className="latestList">
            <div className="cardMainlayout">
              <div className="chtHeader confirmed">
                <div className="headerleft ezyIcon icon-today">
                  <p>Air Confirmed Bookings - <span>{totalCount}</span></p>
                  <strong>QAR {totalAmount}</strong>
                </div>
                <div className="headerRight">
                  <span>{moment().format('dddd')}</span>
                  <p><Moment format="YYYY/MM/DD">
                    {Date().toLocaleString()}
                  </Moment></p>
                </div>
              </div>
              <div><Line data={airdata} options={options} /></div>

              {/* </div> */}

            </div>
          </Col>
          <Col xs={4} className="latestList">
            <div className="cardMainlayout">
              <div className="chtHeader sales">
                <div className="headerleft ezyIcon icon-sales">
                  <p>Non-Air Confirmed Bookings - <span>{totalCountHotel}</span></p>
                  <strong>QAR {totalAmountHotel}</strong>
                </div>
                <div className="headerRight">
                  <span>{moment().format('dddd')}</span>
                  <p><Moment format="YYYY/MM/DD">
                    {Date().toLocaleString()}
                  </Moment></p>
                </div>
              </div>
              <div><Line data={nonairdata} options={options} /></div>

              {/* <div><Line data={hoteldata} options={hoteloptions} /></div> */}

            </div>
          </Col>
          <Col xs={4} className="latestList">
            <div className="cardMainlayout">
              <div className="chtHeader noncancel">
                <div className="headerleft ezyIcon icon-noncancel">
                  <p>Cancelled/Void Bookings - <span>{totalCountHothers}</span></p>
                  <strong>QAR {totalAmountOthers}</strong>
                </div>
                <div className="headerRight">
                  <span>
                  {moment().format('dddd')}4

                  </span>
                  <p>
                    <Moment format="YYYY/MM/DD">
                      {Date().toLocaleString()}
                    </Moment>
                    {/* <Moment date={dateToFormat} /> */}

                  </p>
                </div>
              </div>
              <div><Line data={cncldata} options={options} /></div>

              {/* <div><Line data={data} options={options} /></div> */}

            </div>
          </Col>
        </Row>
        <div className="cardMainlayout">
          <div className="tabLink">
            <Tabs active={state.active} onChange={active => setState({ active })}>
              <span key="cnfTab">Confirmed Sales</span>
              <span key="Onhold">On Hold</span>
              <span key="wfReview">Waiting for Review</span>
            </Tabs>
            <div className="tabContent">
              {content[state.active]}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )

}
export default AdminEzyDashBoard