import React, { useState } from 'react';
function AirportMemo(props) {
  const [mapping, setMapping] = useState(props.mapping);
  const airportInfo = mapping.filter((airport) => {
    return airport.airportCode === props.code
  })
  props.seg==="sege"&& console.log("aiprortNameInfo",props,airportInfo)
  if (props.type === 'filters') {
    if (airportInfo[0] && airportInfo[0].cityName !== '')
      return (<><span className="dstCode ss">{props.code}, {airportInfo[0].cityName} </span></>);
  }
  else if (props.type !== 'Layover' && props.type !== 'Rule' && props.type !== 'City' && airportInfo[0] && airportInfo[0].cityName !== '' && props.field && props.field === "ResultSectionHeader"){
    return (<><span className="dstCode cc"> {props.code}</span><span className="dstName">{airportInfo[0].airportName}</span> </>);
  }
  else if (props.type !== 'Layover' && props.type !== 'Rule' && props.type !== 'City' && airportInfo[0] && airportInfo[0].cityName !== ''){
    return (<><span className="dstCode cc"> {airportInfo[0].cityName}</span><span className="dstName">{airportInfo[0].airportName}</span> </>);
  }else if(props.type === 'Layover' && airportInfo[0] && airportInfo[0].cityName !== ''){
    return (<><span className="dstCode">{airportInfo[0].cityName}, {airportInfo[0].airportName} </span></>);
  }else if(props.type === 'Rule' && airportInfo[0] && airportInfo[0].cityName !== ''){
    return (<><span className="dstCode">{airportInfo[0].airportName},{airportInfo[0].cityName},{airportInfo[0].countryName}  </span></>);
  }else if(props.type === 'City' && airportInfo[0] && airportInfo[0].cityName !== ''){
      return (<><span className="dstCode">{airportInfo[0].cityName}</span></>);
  }else{
  return ('')
  }
}

export default React.memo(AirportMemo)