import axios from 'axios'

export const apiPostHelper = async (URL, PAYLOAD,HEADERS) => {
    try {
        console.log('url:::::::::',URL)
        const responseData = await axios.post(URL, PAYLOAD, HEADERS);
        console.log('resdata:::::::::'+JSON.stringify(responseData))
        return responseData;
    }  catch (e) {
        if (!e.response) {
            // network error
            const obj={
                data:{
                    errors:[{
                        errorMessage:'Error: Network Error'
                    }
                    ]
                }
            }
            console.log("error::",obj);
            return obj;
        }
        console.log("error::"+JSON.stringify(e));
        const obj={
            data:{
                errors:[{
                    errorMessage:'Something went wrong'
                }
                ]
            }
        }
        return obj;
    }
}

export const apiPostFormHelper = async (URL, PAYLOAD) => {
    try {
        const responseData = await axios.post(URL, PAYLOAD, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return responseData
    }  catch (e) {
        if (!e.response) {
            // network error
            const obj={
                data:{
                    errors:[{
                        errorMessage:'Error: Network Error'
                    }
                    ]
                }
            }
            console.log("error::",obj);
            return obj;
        }
        console.log("error::"+JSON.stringify(e));
        const obj={
            data:{
                errors:[{
                    errorMessage:'Something went wrong'
                }
                ]
            }
        }
        return obj;
    }
}

export const apiGetHelper = async (URL, HEADERS_PARAMS) => {
    try {
        console.log("headers::"+JSON.stringify(HEADERS_PARAMS));
        const responseData = await axios.get(URL, HEADERS_PARAMS);
        console.log("res::"+JSON.stringify(responseData));
        return responseData;
    } 
      catch (e) {
        if (!e.response) {
            // network error
            const obj={
                data:{
                    errors:[{
                        errorMessage:'Error: Network Error'
                    }
                    ]
                }
            }
            console.log("error::",obj);
            return obj;
        }
        console.log("error::"+JSON.stringify(e));
        const obj={
            data:{
                errors:[{
                    errorMessage:'Something went wrong'
                }
                ]
            }
        }
        return obj;
    }
}

export const apiPutHelper = async (URL,DATA,HEADERS_PARAMS) => {
    console.log(`DATA`,   JSON.stringify(DATA))
    try {
        let formData = new FormData();
            formData.append('userDetails', JSON.stringify(DATA));
        const responseData = await axios.put(URL,formData,HEADERS_PARAMS);
        return responseData;
    }  catch (e) {
        if (!e.response) {
            // network error
            const obj={
                data:{
                    errors:[{
                        errorMessage:'Error: Network Error'
                    }
                    ]
                }
            }
            console.log("error::",obj);
            return obj;
        }
        console.log("error::"+JSON.stringify(e));
        const obj={
            data:{
                errors:[{
                    errorMessage:'Something went wrong'
                }
                ]
            }
        }
        return obj;
    }
}