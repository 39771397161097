import React, { useReducer,useContext } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Button from '../../common/buttons/Button';
import { Context } from "../../../../App";

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Lakshmi
 *
 */

const initialState = {
    rtype: "ATC Refund", notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false,atc:true,isManual:false
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, rtype: "ATC Refund"
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};
function RefundType(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    // This function will update the State
    const handleChange = evt => {
        const { id, value } = evt.target;
        if (id === "atc") {
            dispatch({ type: "rtype", payload: "ATC Refund" })
            dispatch({type:'atc',payload:true})
            dispatch({type:'isManual',payload:false})
            //props.refundType("ATC Refund");
        } else if (id === "natc") {
            dispatch({ type: "rtype", payload: "NON-ATC Refund" });
            dispatch({type:'atc',payload:false})
            dispatch({type:'isManual',payload:false})
            //props.refundType("ATC NON Refund");
        }else if (id === "manref") {
            dispatch({ type: "rtype", payload: "Manual Refund" });
            dispatch({type:'isManual',payload:true})
            dispatch({type:'atc',payload:false})
            //props.refundType("ATC NON Refund");
          }
        dispatch({ type: id, payload: value });
    }

    const cancelRefund = () => {
        return window.location = "/ezytrip/dashboard/bookingsummary/" + props.bookDetails.booking_id;
    }
    const hidemessage = () => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const continueRefund = () => {
        if (props.ticketsList && props.ticketsList.length !== 0) {
            props.sendRefund([props.ticketsList, state.rtype, "Onward"]);
        } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed to Refund' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
        }
    }


    return (
        <>
                {state.notiMessageShow &&
                    <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                        dismissible> {state.notiMessage}</Alert>
                }

                        <Form.Group controlId="icalInput" className="d-flex align-items-center">
                            {context.logindata.roles.includes('A_T_R')  && (props.refundFull == undefined || props.refundFull)&&
                            <Form.Check type="radio" label="ATC Refund" id="atc" checked={state.atc && !state.isManual} custom value={state.rtype} onChange={handleChange} custom name="classes" className="ml-0" />
}
                            {context.logindata.roles.includes('N_A_T_R') && (props.refundFull == undefined || props.refundFull) &&
                            <Form.Check type="radio" label="NON ATC Refund" id="natc" checked={!state.atc && !state.isManual} value={state.rtype} onChange={handleChange} custom name="classes" className="ml-3" />
                             }
                             {context.logindata.roles.includes('M_R') && (props.refundFull == undefined || props.refundFull) &&
                              <Form.Check type="radio" label="Manual Refund" id="manref" checked={!state.atc && state.isManual} value={state.rtype} onChange={handleChange} custom name="classes" className="ml-3" />
                              }
                              </Form.Group>

                     {state.rtype &&  <div className={state.rtype == "NON-ATC Refund" ? "passengerList mb-5 pb-1":'passengerList mb-3 pb-1'}>
                            <Button
                                size="xs"
                                variant="outline-secondary"
                                className="mr-3"
                                onClick={cancelRefund}
                            >Cancel</Button>
                            {(context.logindata.roles.includes('A_T_R') || context.logindata.roles.includes('N_A_T_R')) && (props.refundFull == undefined || props.refundFull) &&
                            <Button
                                size="xs"
                                variant="outline-primary"
                                onClick={continueRefund}
                            >Continue</Button>
                            }
                         {(context.logindata.roles.includes('A_T_R') || context.logindata.roles.includes('N_A_T_R')) && props.enableImportSplit &&
                            <Button
                                size="xs"
                                variant="outline-primary"
                                onClick={continueRefund}
                            >Split Pax</Button>
                            }

                        </div>}
                        {state.rtype == "NON-ATC Refund" &&
                         <div className="reissueSMS">
                         <span className="ezyIcon icon-info"> Non-ATC refunds are not guaranteed and Ezytrip is not responsible for any ADM’s</span>

                     </div>
                    }
        </>
    )
}
export default RefundType