import log from "loglevel";
import React, { useContext, useEffect, useState,useReducer } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Form,Alert} from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MainMenu from '../../../common/MainMenu';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import { Context } from "../../../../../App";
import InnerLoader from '../../../common/loader/InnerLoader';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import OfficeId from "./index";
import {deleteRevenue, editRevenue , getRevenueData} from './operations';
export const USER_AUTH_DATA = "userAuthData";

let airlineMaster = require("../../../masterData/AirlinesMasterData.json");

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: '', notiMessageShow: false, notiVarient: '', response:[], viewResponse:[]
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This function will show the all the Office Id records and Create funcationality
function RevenuMain() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ofcList, setOfcList] = useState([]);
  const [context, setContext] = useContext(Context);
  const [createOfc, setCreateOfc] = useState(false);
  const [editOfc, setEditofc] = useState(false);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [editOfcData, setEditOfcData,] = useState("");

  useEffect(() => {
    setIsLoadingMore(true)
    getRevenueData().then(response => {
        loadResponse(response);
    }).catch(function (error) {
      log.error(
        "Exception occured in getOfficeIdsList function---" + error
      );
    });
  }, [])



  const createOfcId = (e) => {
    setCreateOfc(true)
    setEditofc(false)
  }

  const editServiceBtnClick = (obj, type) => () => {
    if (type=="View"){
      editRevenue(obj.id).then((response)=>{
        if (response){
          dispatch({type:"viewResponse", payload:response})
          setEditofc(true)
        setCreateOfc(false)
        obj.type=type;
        setEditOfcData(obj)
        }
      })
  }
    if (type=="Edit"){
      editRevenue(obj.id).then((response)=>{
        if (response){
          dispatch({type:"response", payload:response})
          setEditofc(true)
        setCreateOfc(false)
        obj.type=type;
        setEditOfcData(obj)
        }
      })
  }
    if (type=="Delete"){
      deleteRevenue(obj.id).then((response)=>{
        if (response.suc){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Record deleted succesfully' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      return window.location = "/ezytrip/administration/revenue"
        }{
        dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Record is not deleted' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      return window.location = "/ezytrip/administration/revenue"
        }
      })
  }
  }
  const loadResponse = (response) => {
    setOfcList([])
    let data = [];
    var enableDelete = false;
    if(context.logindata!==""){
      if(context.logindata.roles.split(",").includes('D_R_V_M')) {
        enableDelete = false;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      if(userData.roles.includes('D_R_V_M')) {
        enableDelete = false;
      }
    }
    for (let val of response) {
      let aiList=""
     for (let data of airlineMaster){
       if(val.al==data.value){
        aiList=data.label
    } else if(val.al=="All"){
      aiList="All"
  }
  }
      data.push({
      ofc_id: val.ofc_label,region_type:val.region_type, sales_cha: val.sales_channel, ttype:val.ttype=="1"?"Oneway":val.ttype=="2"?"Roundtrip":"",
      airlines:aiList, flt_no:val.flt_no, rbd:val.rbd, rtype:val.rtype, value: val.sign=="minus"?-val.val:val.val, status: (val.act === 1) ? "Active" : "In-Active" ,options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editServiceBtnClick(val, "View")}></a> { <a className="ezyIcon icon-edit" onClick={editServiceBtnClick(val, "Edit")}></a>} {enableDelete && <a className="ezyIcon icon-delete" onClick={editServiceBtnClick(val, "Delete")}></a>}  <div className='custom-switch'>
      <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
         </Form.Group>
         </div></span></> })
    }
    setOfcList(data)
    setIsLoadingMore(false)
  }
  const columns = [
    {
      dataField: 'ofc_id',
      text: 'Office Id Name',
      sort: true,
      filter: textFilter()
    },
    {
    dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter()
    },
  {
    dataField: 'sales_cha',
    text: 'Sales Channel',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ttype',
    text: 'Travel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'airlines',
    text: 'Airlines',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'flt_no',
    text: 'Flight No',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'rbd',
    text: 'RBD',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'rtype',
    text: 'Revenue Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'value',
    text: 'Value',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  return (
    <>
    <MainMenu active='Air Services' />
      <Breadcrumb activePage="Revenue Management" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Revenue Management" />
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
          <div className="contentArea cmnSearch revenueMangement">
            {(!createOfc && !editOfc) ? (
              <>
                {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_C') &&
                  <div className="d-flex justify-content-between buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn addPlus ezyIcon icon-plus"
                      onClick={createOfcId}
                      type="submit">
                    Create Revenue Management
                  </Button>
                  </div>
                }
              </>
            ) : (
                (createOfc) ? (
                  <>
                    <OfficeId sendEditData={undefined} response={state.response} viewResponse={state.viewResponse}/>
                  </>
                ) : (
                  <>
                    <OfficeId sendEditData={editOfcData} response={state.response} viewResponse={state.viewResponse}/>
                  </>)
              )
            }
          </div>
        </div>
        {(!createOfc && !editOfc) ? (
          <div className="cardMainlayout tableRes p-0 table-responsive">
            {isLoadingMore? <InnerLoader /> : (<>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl revnUman"
                  data={ofcList}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={ofcList.length >10 && ofcList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  ofcList.length >25 && ofcList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  ofcList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {ofcList.length===0 &&
                  <NoRecord />
                }
            </>)}
          </div>
          ) : ('')
          }
      </div>
      <Footer />
    </>
  )
}
export default RevenuMain