import React from 'react';
/**
 * @description:This component will work for processing popUp .
 * @author: Pravas
 * @param {*}
 * @function Processing
 * @date : 01-09-2020
 */
function ProcessingReissue(props) {

  return (
    <>
          <div className="layover">
            <div className="popUpBox processing reissue">
              <div className="icons reissue"></div>
              <strong>Processing…</strong>
              {props.tag == 'REFUND' && 
              <p>Validating ticket eligibility for refund.</p>
              }

               {props.tag == 'REISSUE' && 
              <p>Validating ticket exchange eligibility.</p>
               }
            </div>
          </div>

    </>
  )
}
export default ProcessingReissue