import axios from 'axios'
import { Console } from 'winston/lib/winston/transports';

const API_URL = process.env.REACT_APP_B2B_URL
const API_URL_SEARCH = process.env.REACT_APP_B2B_SEARCH

/**
 * @description: This function will get the all services fro Flight Booking flow
 * @author: Lakshmi
 * @param {*}
 * @function Flight Service
 * @date : 02-09-2020
 */
class InsuranceService {
    
    async saveBooking(prereq) {
        return await axios.post(`${API_URL}/att/booking/prebook`,prereq);
    }
    async sendConfirmationObj(req) {
        return await axios.post(`${API_URL}/att/insurance/share-itinerary`,req)
    }
    // curl --location --request POST 'http://localhost:3005/att/insurance/share-itinerary' \

    
}

export default new InsuranceService()