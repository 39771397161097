
import React, { useState, useEffect, useReducer, useContext } from 'react';
import log from "loglevel";
import Button from '../../common/buttons/Button'
import { useHistory } from 'react-router-dom';
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import 'react-datepicker/dist/react-datepicker.css';
import PickList from '../../common/pickList/PickList';
import { getBranchList,loadBranchesList, getCompaniesList, categoryDataList, ofcDataList, getCompanyCatLsit, getCompanyList, retriveFMDetails, deledeFMap, delPaxMarakup, delClass, updateFMRequest, getCompanyTAList, getSTACompanyList,editMarkup,deleteLSid } from './operations';
import ApplicableMarkup from '../common/HotelApplicableMarkup';
import { confirmBranchDlg } from '../../common/confirmDialogue';
import Utilities from '../../commonUtils/Utilities';
import SalesChannel from "../common/SalesChannel";
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";

import { Context } from "../../../../App";
let rbdMaster = require('../../masterData/Rbd.json');
export const COUNTRY_DATA ="COUNTRY_DATA";

/**
 * Initial State Declaration
 */
const initialState = {
  cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "ATT", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [], branches: [], branchMenu: [], catMenu: [],
  categories: [], enbleType: false, showCat: false, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
  enabeleEdit: false, fmId: "", notiMessage: "", notiMessageShow: false, notiVarient: "", classCode: [], userType: "", selBranches: [], classType: [], staCompanies: [], ta_comp: [], rbdMaster: [], defaultRBDmaster: [],
  reset: false,hideDelete:false,companyType:true,sta:false,region_selected: [],region_disable: false,isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,sales_chanel:""
};

let classArray = [];
let markupFareArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "TA", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [],
       enbleType: false, showCat: false, suppliers: "", enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
        enabeleEdit: false, fmId: "", classCode: [], selBranches: [], classType: [], staCompanies: [], ta_comp: [],hideDelete:false,region_selected:[],region_disable: false,isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,sales_chanel:""
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function AirMarkupFareMapForm(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [classState, setRbdClass] = useState({ class: [] });
  const [companydata, setCompanyPick] = useState([]);
  const [fares, setShowFareList] = useState({ faresList: [] });
  const [value, setValue] = useState([]);
  const [perNight, setperNight] = useState({ perNight: [] })
  const [longestStay, setlongestStay] = useState({ longestStay: [] })
  const [perBooking, setPerBooking] = useState({ perBookings: [] })
  const [applicable, setApplicableType] = useState();
  const [enApplicable, setEnApplicable] = useState(true);
  const [companyType, setCompanyType] = useState(true);
  const [sta, setSTA] = useState(false);
  const [paxData, setPaxData] = useState()
  const [data, setData] = useState([])
  const [officeData, setOfficeData] = useState([])
  const [disableButton, setDisableButton] = useState(false);
  const [region,setRegion] = useState([])
  const cls = [{ "label": "Economy", "value": "Economy" }, { "label": "Business", "value": "Business" }, { "label": "First", "value": "First" }]

  useEffect(() => {
    if (rbdMaster.length > 0) {
      dispatch({ type: 'rbdMaster', payload: rbdMaster })
      dispatch({ type: 'defaultRBDmaster', payload: rbdMaster })
    }
  }, [rbdMaster])
  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      setRbdClass({ class: [] })
      setCompanyPick([])
      setShowFareList({ faresList: [] })
      setperNight({ perNight: [] })
      setPerBooking({ perBookings: [] })
      setlongestStay({longestStay: []})
      setApplicableType()
      setEnApplicable(true)
      setCompanyType(true)
      setSTA(false)
      setPaxData()
      setData([])
      setOfficeData([])
      setDisableButton(false)
    }
  }, [props.reset])
  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);
  useEffect(()=>{
    if(state.enableDevices && state.sales_chanel==="B2C" && state.region_selected.length>0){
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_selected[0].label}
      AttCommonSerivce.att_companies(pyload).then(response => {
        let authData = []
        if (response.data.suc && response.data.res && response.data.res.data) {

          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: authData });
          return
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });

    }
  },[state.enableDevices , state.sales_chanel,state.region_selected])
  useEffect(() => {
    let fareList = [];
    if (props.fareData && props.fareData !== undefined) {
      for (let value of props.fareData) {
        var name = "";
        if (value.branch_name !== null && value.branch_name !== "") {
          name = value.branch_name;
        } else if (value.category_name !== null) {
          name = value.category_name;
        }
        var type = "";
        if (state.utype == "ta_user") {
          type = value.com_type;
        }
        const fares = {
          fmid: value.fare_map_id,
          applyon: value.applicable_on,
          borcname: name,
          company: (value.company_name) ? value.company_name : value.com_type,
          sup: value.supplier_name + "[" + value.fk_office_id + "]",
          compType: type
        }
        markupFareArray.push({name: (value.company_name) ? value.company_name : value.com_type })
        fareList.push(fares);
      }
      setShowFareList({ faresList: fareList })
     if (fareList.length>0){
      dispatch ({type:"enabeleEdit", payload:true})
     }
    }
  }, [props.fareData]);


  useEffect(() => {
    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })

      if(context.logindata.utype == "att_user") {

        dispatch({ type: 'bid', payload: 0 })
        dispatch({ type: 'enableOfcs', payload: false })
        setCompanyPick("");
        dispatch({ type: 'compType', payload: false })
        dispatch({ type: 'enbleType', payload: true })
        dispatch({ type: 'showCat', payload: false })
        dispatch({ type: 'ctyp', payload: "TA" })
        dispatch({ type: 'ofcs', payload: [] })
        dispatch({ type: 'enableOfcs', payload: true })
        dispatch({ type: 'aptype', payload: "Branch" })
        dispatch({ type: 'ta_comp', payload: [] })
        setEnApplicable(false)
        setCompanyType(false);
        setSTA(false)
      }
      if(context.logindata.utype == "ta_user" || context.logindata.utype == "sta_user") {
        dispatch({type:"aptype",payload:"Branch"})
      }
      if(context.logindata.utype== "sta_user") {
        dispatch({type:"ctyp",payload:"STA"})
      }
       if(context.logindata.utype == "ta_user") {
         state.ctyp = "TA"
        dispatch({type:"ctyp",payload:state.ctyp})

      }


       //****handle of region declaration according to att_user */

    if(context.logindata.utype == "att_user") {
      let data = JSON.parse(localStorage.getItem(COUNTRY_DATA))
      // let finalArray=[];
      // data.forEach((element)=>{
      //   finalArray.push({"label":element.con_name, "value":element.con_id})
      // })
      setRegion(data)
     }
      else {

        //att_staff or ta_staff
        if(context.logindata.region_type) {
          //array filteration
          let constational_data = []
            let regional_array = context.logindata.region_type.split(",")
            for(let eachRegion of regional_array) {
              let payload = {
                "label" : eachRegion,
                "value" : eachRegion
              }
              region.push(payload)
              constational_data.push(payload)
            }
            //other than att the region is not given as option it is initially stabilised to response
            if(context.logindata.utype != "att_user") {
              state.region_selected = constational_data
              dispatch({type:"region_selected",payload:state.region_selected})
            }
        }

     }
      if (context.logindata.utype !== "ta_user") {


        // categoryDataList(pyload).then(response => {
        //   console.log('category resp::::', response)
        //   if (response.data.suc) {
        //     let catList = response.data.res.map((cat) =>
        //       <option value={cat.values}>{cat.label}</option>
        //     );
        //     dispatch({ type: 'catMenu', payload: catList })
        //     dispatch({ type: 'categories', payload: response.data.res })
        //   }
        // }).catch(function (error) {
        //   log.error(
        //     "Exception occured in getCategoryData function---" + error
        //   );
        // });
        // ofcDataList().then(response => {
        //   if (response.data.suc) {
        //     if (response.data.res) {
        //       dispatch({ type: 'suppliers', payload: response.data.res })
        //       dispatch({ type: 'enableOfcs', payload: true })
        //     }
        //   }
        // }).catch(function (error) {
        //   log.error(
        //     "Exception occured in getBranchData function---" + error
        //   );
        // });
      } else {
        dispatch({ type: 'ctyp', payload: "TA" })
        dispatch({ type: 'utype', payload: "ta_user" })
      }
    }
  }, [context.logindata]);

  //region edit response handle
  useEffect(()=>{

    if(props.editRegion && props.editRegion.length > 0) {
      dispatch({type:"region_selected",payload:props.editRegion})
      dispatch({type:"region_disable",payload:true})
    }
  },[props.editRegion])
  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    //dispatch({ type: 'officeIds', payload: [] })
    setCompanyPick([])
    props.sendChannel(salesChanel)
  }
  // const handleDeviceTypeChange = (e) => {
  //   debugger
  //   props.DeviceType(e)
  // }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
 props.updateProps(state.isDeviceAll,state.isB2cWeb,state.isB2cApp,state.isMWeb)
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }




  const pickldata = (data) => {
    dispatch({ type: 'data', payload: data })
  }
  const compdata = (data) => {
    dispatch({ type: 'compData', payload: data })
  }
  const ofcidsdata = (data) => {
    let ofcids = [];
    data.map((obj) => {
      if (obj.value) {
        const ofc = {
          "of": obj.value,
          "su": obj.label
        }
        ofcids.push(ofc);
      }
    }
    );
    dispatch({ type: 'ofcs', payload: ofcids })
  }

  const handleCategoryChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    // getCompanyCatLsit(value).then(response => {
    //   console.log("Company response : " + JSON.stringify(response));
    //   if (response.data.suc) {
    //     setCompanyPick(response.data.res)
    //   } else {
    //     //   //setMessage({showMessage:true})
    //   }
    // }).catch(function (error) {
    //   log.error(
    //     "Exception occured in getCompanyList function---" + error
    //   );
    // });
  }

  const handleBranchChange = (e) => {
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    if (!sta) {
      getCompanyList(value).then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    } else {
      dispatch({ type: 'ta_comp', payload: [] })
      getCompanyTAList(value, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

  const handleClsChange = (e) => {
    const { id, value } = e.target
    if (e.target.id === "eco") {
      dispatch({ type: 'type', payload: "Economy" })
    } else if (e.target.id === "bus") {
      dispatch({ type: 'type', payload: "Business" })
    } else if (e.target.id === "frst") {
      dispatch({ type: 'type', payload: "First" })
    }
    dispatch({ type: id, payload: value });
  }

  const handleAppTypeChange = (e) => {
    if (e.target.id === "branch") {
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyPick("");
      setEnApplicable(true);
      // markupFareArray = [];
    } else {
      setCompanyType(true);
      setSTA(false)
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'ctyp', payload: 'ATT' })
      dispatch({ type: 'staCompanies', payload: [] })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
      setEnApplicable(true);
      dispatch({ type: 'ta_comp', payload: [] })
      // markupFareArray = [];
    }
  }

  const handleCompTypeChange = (e) => {

    if (e.target.id === "att") {
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      dispatch({ type: 'ofcs', payload: [] })
      setCompanyType(true);
      // markupFareArray = [];
    } else if (e.target.id === "ta") {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      dispatch({ type: 'ta_comp', payload: [] })
      setEnApplicable(false)
      setCompanyType(false);
      setSTA(false)
      // markupFareArray = [];
    } else {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyType(true);
      setSTA(true)
      setEnApplicable(false)
      // markupFareArray = [];
    }
  }

  const handleTypeChange = (e) => {
    dispatch({ type: 'aptype', payload: "Branch" })
    if (e.target.id === "ta") {
      dispatch({ type: 'ctyp', payload: "TA" })
      setCompanyType(true);
      setCompanyPick([])
    } else {
      dispatch({ type: 'ctyp', payload: "STA" })
      setCompanyType(false);
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      if (companydata && companydata.length === 0) {
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }


  //Adding to Classes list
  const handleClses = () => {
    if (validateCls()) {
      const payload = {
        "cls": state.type,
        "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
      }
      setRbdClass({ class: [...classState.class, payload] });
      dispatch({ type: 'classCode', payload: [] })
      dispatch({ type: 'type', payload: "" })
      dispatch({ type: 'classType', payload: [] })
      dispatch({type:"rbd",payload:[]})
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  //checking the duplicates based on length(original json and unique json length)
  const validateCls = () => {
    if (state.type === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Class' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    } else if (state.type !== "") {
      const payload = {
        "cls": state.type,
        "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
      }
      classArray.push(payload);
      let data = Utilities.getUniqData(classArray);
      var int = 0;
      for (let val of data) {
        if (val.cls == "Economy") {
          if (int !== 0 && data.includes("All")) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
          int = int + 1;
        } else if (val.cls == "Business") {
          if (int !== 0 && data.includes("All")) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
          int = int + 1;
        } else if (val.cls == "First") {
          if (int !== 0 && data.includes("All")) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
          int = int + 1;
        }
        if (classArray.length !== data.length) {
          classArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }
    }
    return true;
  }

  function getUnique(arr, comp) {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  // Adding Class and rbds
  const setCls = (data) => {
    if (data.length > 0 && data[0].value === "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      dispatch({ type: 'rbdMaster', payload: data })
      setValue(data)
    } else if (data.length > 0 && data[0].value !== "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      setValue(data)
      removeAll()
    } else {
      let cls = getUnique(state.defaultRBDmaster, "value")
      dispatch({ type: 'rbdMaster', payload: cls })

      dispatch({ type: 'rbd', payload: [] })
      dispatch({ type: 'classCode', payload: data })
      setValue(data)

    }

  }

  const removeAll = (e) => {
    let arr = []
    for (let val of state.rbdMaster) {
      arr.push(val.value)
    }
    if (arr.indexOf("All") > -1) {
      const index = arr.indexOf("All");

      state.rbdMaster.splice(index, 1);
    }
  }
  const defaultRBD = (e) => {
    dispatch({ type: 'rbdMaster', payload: rbdMaster })
  }
  const handleRemoveClass = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      delClass(obj.id).then(response => {
        if (response.data.suc) {
          let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
          if(classArray && classArray.length > 0) {
            classArray = matchedArr
          }
          setRbdClass({ class: classState.class.filter((s, sidx) => s.id !== obj.id) });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
    } else {
      let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
      setRbdClass({ class: classState.class.filter((s, sidx) => idx !== sidx) });
      if(classArray && classArray.length > 0) {
        classArray = matchedArr
      }
    }
    if (classState.class.length === 0) {
      dispatch({ type: 'showCls', payload: false })
    }

  }

  const validateFareMap = () => {
    if (state.userType === "att_user") {
      if(state.region_selected && state.region_selected.length == 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Region' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    else  if (state.aptype === "Branch" && state.ctyp === "ATT") {
        if (state.data.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else if (state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) {
        if (state.bid === 0 && !state.enableDevices) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (state.ctyp === "STA") {
          if (state.ta_comp.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
          if(state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        } else {
          if(state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        }
      } else if (state.aptype === "Category") {
        if (state.cat === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Category' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }
    } else {
        if (state.utype == "ta_user" && state.ctyp === "STA" && state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
    }
    //Validating profile markup
    if (perBooking.perBookings.length === 0 && perNight.perNight.length === 0 && longestStay.longestStay.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please define Applicable markup' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    if (state.aptype === "Branch") {
      var exist = false;
      if (state.ctyp === "ATT") {
        if (state.data.length !== 0) {
          for (let val of state.data) {
            const payload = {
              "bid": val.value,
              "name": val.label
            }
            markupFareArray.push(payload);
          }
          let data = Utilities.getUniqData(markupFareArray);
          if (markupFareArray.length !== data.length) {
            markupFareArray = data;
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Branch is already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        }
      } else if (state.ctyp === "TA") {
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value,
            "name": val.label
          }
          markupFareArray.push(payload);
        }
        let data = Utilities.getUniqData(markupFareArray);
        if (markupFareArray.length !== data.length) {
          markupFareArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          let correction = markupFareArray[markupFareArray.length - 1].name
          markupFareArray = data
          return false;
        }
      } else if(state.ctyp === "STA") {
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value,
            "name": val.label
          }
          markupFareArray.push(payload);
        }
        let data = Utilities.getUniqData(markupFareArray);
        if (markupFareArray.length !== data.length) {
          markupFareArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          let correction = markupFareArray[markupFareArray.length - 1].name
          markupFareArray = data
          return false;
        }
      }
    } else if (state.aptype === "Category" &&  state.utype == "att_user") {
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        let correction = markupFareArray[markupFareArray.length - 1].name
          markupFareArray = markupFareArray.filter((s,idx) => (s.name !== correction))
        return false;
      }
    }
    // if (state.ctyp === "STA" && state.utype == "ta_user") {
    //   console.log('hiiiii',state.compData)
    //   for (let val of state.compData) {
    //     const payload = {
    //       // "cid": val.value,
    //       "name": val.label
    //     }
    //     markupFareArray.push(payload);
    //   }
    //   let data = Utilities.getUniqData(markupFareArray);
    //   console.log('markuparr',markupFareArray)
    //   console.log('dataa',data)
    //   if (markupFareArray.length !== data.length) {
    //     markupFareArray = data;
    //     dispatch({ type: 'notiMessageShow', payload: true })
    //     dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
    //     dispatch({ type: 'notiVarient', payload: 'danger' })
    //     let correction = markupFareArray[markupFareArray.length - 1].name
    //       markupFareArray = markupFareArray.filter((s,idx) => (s.name !== correction))
    //     return false;
    //   }
    // }

    return true;
  }

  // ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
    // to selective ta and sta when repective tag is added to data so handle dublicate
    if((state.userType === "ta_user" && state.ctyp === "TA") || (state.userType === "sta_user" && state.ctyp === "STA")) {
      let companyData = []
      let trac_obj = {
        "label": context.logindata.cnm
      }
      companyData.push(trac_obj)
      let lvl = true
      for(let val of state.compData) {
        if(val.label == context.logindata.cnm) {
          lvl = false
        }
      }
      if(lvl) {
        state.compData.push(trac_obj)
      }
    }
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      let fList = [];
      var ofcIds = "";
      let selection_region = ""

      //region declaration
      if(state.region_selected.length > 0) {
        let temp_regionfilter = state.region_selected.filter(function(s) { //filteration of the region in place of accordance to selection
          if(s) {
              if(region == '') {
                selection_region = s.value
              } else {
                selection_region = selection_region + s.value
              }

            }
            return s;
          }
        )
      }
      if (state.userType === "att_user" && state.ofcs) {
        state.ofcs.map((obj) => {
          if (ofcIds) {
            ofcIds = ofcIds + "-" + obj.su + "[" + obj.of + "]";
          } else {
            ofcIds = obj.su + "[" + obj.of + "]";
          }
        });
      }
      if (state.userType === "att_user" && state.aptype === "Branch" && state.ctyp === "ATT") {
        if (state.data) {
          state.data.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: "ATT",
                borcname: obj.label,
                sup: ofcIds
              }
              branches.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } else if ((state.userType === "att_user" && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || (state.aptype !== undefined && state.aptype === "Category")) {
        let fList = [];

        var branchcatName = "";
        if (state.branches && state.bid !== 0 && state.aptype === "Branch") {
          state.branches.map((obj) => {
            if (Number(obj.value) === Number(state.bid)) {
              branchcatName = obj.label;
              branches.push({ "id": obj.value, "name": obj.label });
              category = [];
            }
          });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Category") {
          state.categories.map((obj) => {
            if (obj.label == state.cat) {

              branchcatName = obj.label;
              category.push({ "id": obj.value, "name": obj.label });
              branches = [];
              dispatch({type:'cat',payload:0})
            }
          });
        }
        if (state.compData && category.length === 0) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname: branchcatName,
                sup: ofcIds
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              dispatch({type:'compData',payload:[]})
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({type:'staCompanies',payload:[]})
              setCompanyPick([])
              dispatch({type:'bid',payload:0})
              dispatch({type:'ta_comp',payload:[]})
            }
          });
        }
      } else if (state.userType === "att_user" && category.length === 0) {
        let fList = [];
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              applyon: applicable,
              borcname: branchcatName,
              sup: ofcIds
            }
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      if (category.length > 0) {
        const fare = {
          applyon: applicable,
          borcname: branchcatName,
          sup: ofcIds
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      //Seeting Pax datat
      let paxData = [];
      if (applicable === "Per Booking") {
        paxData = perBooking.perBookings;
      } else if(applicable === "Per Night"){
        paxData = perNight.perNight;
      } else {
          paxData = longestStay.longestStay
      }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (state.userType === "ta_user") {
        if (state.ctyp !== "STA") {
          const fare = {
            applyon: applicable,
            company: context.logindata.cnm,
            compType: "TA"
          }
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          setShowFareList({ faresList: fares.faresList });
        } else {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                compType: "STA"
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } if (state.userType === "sta_user") {
        const fare = {
          applyon: applicable,
          company: context.logindata.cnm,
          compType: "STA"
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      if (state.userType === "att_user") {
        if (!state.enabeleEdit) {
          createFareMapRequest(paxData, companyType, branches, companys, category,selection_region);
        } else {
          createFMUpdatedPayload(paxData, companyType, branches, companys, category,selection_region);
        }
      } else if (state.userType === "ta_user") {
        if (!state.enabeleEdit) {
          createFareMapTARequest(paxData, companys,selection_region);
        } else {
          createFMUpdatedTAPayload(paxData, companys,selection_region);
        }

      } else {
        if (!state.enabeleEdit) {
          createFareMapSTARequest(paxData, companys,selection_region);
        } else {
          createFMUpdatedSTAPayload(paxData, companys,selection_region);
        }
      }

      if(fares.faresList.length > 0) {
        dispatch({type:"region_disable",payload:true})
      }
    }
  }
  const createFareMapTARequest = (paxData, companies,region) => {
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "class": classState.class,
      "ctyp": state.ctyp == "STA" ? "STA" : "TA",
      "act": true,
      "bid": [],
      "cat": [],
      "aptype": "",
      "cmpid": uniqueJson(companies),
      "ofc": [],
      "taCmp": state.ta_comp,
      "utype":"ta_user"
    }
    props.sendFareData(payload);
  }


  const createFareMapSTARequest = (paxData, companies,region) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "class": classState.class,
      "ctyp": "STA",
      "bid": barr,
      "act": true,
      "cat": [],
      "aptype": "Branch",
      "cmpid": uniqueJson(companies),
      "ofc": [],
      "taCmp": state.ta_comp,
      "utype": "sta_user"
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedSTAPayload = (paxData, companies,region) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mkpid": props.markupId,
        "act": true,
        "utype": "sta_user",
        "applon": applicable,
        "class": classState.class,
        "ctyp": "STA",
        "aptype": "Branch",
        "applist": paxData,
        "bid": barr,
        "cat": [],
        "cmpid": uniqueJson(companies),
        "ofc": [],
        "taCmp": state.ta_comp
      }
    }
    createFMPayload(payload)
  }
  const createFareMapRequest = (paxData, companyType, branches, companies, categories,region) => {
    const payload = {
      "applon": applicable,
      "applist": paxData,
      "act": true,
      "class": classState.class,
      "act": true,
      "ctyp": companyType,
      "bid": branches,
      "cat": categories,
      "aptype": state.aptype,
      "cmpid": uniqueJson(companies),
      "ofc": state.ofcs,
      "taCmp": state.ta_comp,
      "utype": companyType == "STA" ? "ta_user" : context.logindata.utype
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedTAPayload = (paxData, companies) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mkpid": props.markupId,
        "utype": "ta_user",
        "act": true,
        "applon": applicable,
        "class": classState.class,
        "ctyp": state.ctyp == "STA" ? "STA" : "TA",
        "aptype": "",
        "applist": paxData,
        "bid": [],
        "cat": [],
        "cmpid": uniqueJson(companies),
        "ofc": [],
        "taCmp": state.ta_comp
      }
    }
    createFMPayload(payload);
  }
  const createFMUpdatedPayload = (paxData, companyType, branches, companies, categories,region) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mkpid": props.markupId,
        "act": true,
        "applon": applicable,
        "utype": state.userType,
        "class": classState.class,
        "ctyp": companyType,
        "aptype": state.aptype,
        "applist": paxData,
        "bid": branches,
        "cat": categories,
        "cmpid": uniqueJson(companies),
        "ofc": state.ofcs,
        "taCmp": state.ta_comp,
        "utype": companyType == "STA" ? "ta_user" : context.logindata.utype

      }
    }
    createFMPayload(payload);
  }

  const createFMPayload = (payload) => {
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    if(payload && payload.fmureq.applist.length > 0) {
    updateFMRequest(props.configType, requestId, payload.fmureq).then(response => {
      let fareList = [];
      if (response.data.suc) {
        dispatch({type: 'hideDelete',payload: false})
        setShowFareList({ faresList: [] })
        editMarkup(props.markupId).then(response => {
          if (response.data.suc) {
            var obj = response.data.res;
            var srvcmap = obj.hfmap
            if(srvcmap && srvcmap.length > 0) {
              for (let value of srvcmap) {
                var name = "";
                if (value.branch_name !== null && value.branch_name !== "") {
                  name = value.branch_name;
                } else if (value.category_name !== null) {
                  name = value.category_name;
                }
                const fares = {
                  fmid: value.fare_map_id,
                  applyon: value.applicable_on,
                  borcname: name,
                  company: (value.company_name) ? value.company_name : value.com_type

                }
                fareList.push(fares);
              }
              dispatch({type:'enabeleEdit',payload:true})
              setShowFareList({ faresList: uniqueJson(fareList) })
            }
            // setEditData(response)
            // if(type==="view"){
            //   setEnableView(true)
            // }else{
            //   setEnableView(false)
            // }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in editServiceFee function---" + error
          );
        });
        setShowFareList({ faresList: fareList })
        dispatch({ type: 'notiMessage', payload: "Markup updated successfully" })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        // dispatch({ type: 'clear', payload: true })
        // dispatch({type: 'reset',payload: true})
        setRbdClass({ class: [] })
        // markupFareArray= []
        setCompanyPick([])
        // setShowFareList({ faresList: [] })
        setperNight({ perNight: [] })
        setPerBooking({ perBookings: [] })
        setlongestStay({longestStay: []})
        setApplicableType()
        setEnApplicable(true)
        setCompanyType(true)
        setSTA(false)
        setPaxData()
        setData([])
        setOfficeData([])
        setDisableButton(false)
      } else {
        dispatch({ type: 'notiMessage', payload: "Markup Not updated.." })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  } else {
        dispatch({ type: 'notiMessage', payload: "Please Define Applicable Markup." })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
  }
  }

  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

    return myUniqueArr;
  }
  const paxMarkup = (data) => {
    setApplicableType(data[0]);
    dispatch({type: 'reset',payload: false})
    if (data[0] !== undefined && data[0] === "Per Booking") {
      perBooking.perBookings.push(data[1]);
      setPerBooking({ perBookings: perBooking.perBookings });
    } else if(data[0] !== undefined && data[0] === "Per Night"){
        perNight.perNight.push(data[1]);
    //   setPerTicket({ perTickts: perTicket.perTickts });
      setperNight({ perNight: perNight.perNight });
    } else {
        longestStay.longestStay.push(data[1])
        setlongestStay({longestStay:longestStay.longestStay})
    }
  }

  const updatePaxData = (data) => {
    if (data[0] !== undefined && data[0] === "Per Booking") {
      setPerBooking({ perBookings: data[1] });
    } else if(data[0] !== undefined && data[0] === "Per Night"){
      setperNight({ perNight: data[1] });
    } else {
        setlongestStay({longestStay: data[1]})
    }
  }

  const loadClasses = (resp) => {
    let clssess = [];
    let classArr = []
    for (let value of resp.class) {
      const cls = {
        "id": value.cid,
        "cls": value.cls,
        "rbd": value.rbd
      }
      clssess.push(cls);
      const obj = {
        "cls": value.cls,
        "rbd": [value.rbd]
      }
      classArr.push(obj)
    }
    setRbdClass({ class: clssess });
    classArray = classArr
    dispatch({ type: 'showCls', payload: true })
  }

  const setCompType = (resp) => {
    if (resp.ctyp === "ATT") {
      setCompanyType(true)
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      if (resp.bid && resp.bid !== undefined) {
        loadCompanyData(resp.bid);
      }
    } else if (resp.ctyp === "TA") {
      dispatch({ type: 'bid', payload: resp.bid[0]?.id!=undefined?resp.bid[0]?.id?.id:resp.bid })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      setEnApplicable(false)
      setCompanyType(false);
      setSTA(false)
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      
      if (resp.bid[0]?.id!=undefined){
        getCompanyList(resp.bid[0]?.id).then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });} else{
        let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_selected[0].label}
      AttCommonSerivce.att_companies(pyload).then(response => {
        let authData = []
        if (response.data.suc && response.data.res && response.data.res.data) {

          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
          dispatch({ type: "comapny_temp_List", payload: authData });
          return
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      }
    } else {
      dispatch({ type: 'bid', payload: resp.bid[0].id })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(true);
      setSTA(true)
      setEnApplicable(false)
      setData([]);
      dispatch({ type: 'compData', payload: [] })
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });
      dispatch({ type: 'ta_comp', payload: [{"label": resp.tacmp[0].name,"value": resp.tacmp[0].id}] })
      getCompanyTAList(resp.bid[0].id, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      getSTACompanyList(resp.tacmp[0].id).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  //updating all seoected companies
  const loadCompanyData = (companies) => {
    let compas = [];
    let data = [];
    for (let val of companies) {
      const comp = {
        "value": val.id,
        "label": val.name
      }
      data.push(comp);
      compas.push(val.id);
    }
    setData(data);
    dispatch({ type: 'compData', payload: data })
  }

  const setAppType = (appType) => {
    if (appType === "Branch") {
      setEnApplicable(true);
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyPick("");
    } else {
      setEnApplicable(false);
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
    }
  }

  const loadOfcIds = (ofcs) => {
    let compas = [];
    let data = [];
    for (let val of ofcs) {
      const comp = {
        "value": val.od,
        "label": val.su
      }
      data.push(comp);
      compas.push(val.od);
    }
    setOfficeData(data);
    dispatch({ type: 'ofcs', payload: ofcs })

  }

  const editFareMap = (obj) => () => {
    retriveFMDetails(props.configType, obj.fmid).then(response => {
      if (response.data.suc) {
        markupFareArray = markupFareArray.filter((d,id) => d.name !== obj.company)
        dispatch({type: 'hideDelete',payload: true})
        dispatch({ type: 'enableUpdate', payload: true })
        dispatch({ type: 'fmId', payload: obj.fmid })
        var resp = response.data.res
        setPaxData(resp)
        if (resp.cmpid && resp.cmpid.length !== 0){
          loadCompanyData(resp.cmpid)
        }
        if (resp.class && resp.class.length !== 0) {
          loadClasses(resp);
        }
        if (resp.ofc && resp.ofc !== undefined) {
          loadOfcIds(resp.ofc);
        }
        if (resp.ctyp) {
          if (state.userType === "att_user") {
            setCompType(resp);
          }
          if (state.userType === "ta_user" && resp.cmpid && resp.cmpid.length !== 0) {
            if (resp.ctyp === "TA") {
              dispatch({ type: 'ctyp', payload: "TA" })
              setCompanyType(true)
            } else {
              loadCompanyData(resp.cmpid);
              dispatch({ type: 'ctyp', payload: "STA" })
              setCompanyType(false)
              const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
              //Load all companies based Login type as TA
              if (companydata && companydata.length === 0) {
                getCompaniesList(pyload).then(response => {
                  if (response.data.suc) {
                    setCompanyPick(response.data.res)
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCompanyList function---" + error
                  );
                });
              }
            }
          }
        }
        if (resp.aptype) {
          setAppType(resp.aptype);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }


  const deleteFareMap = (obj, idx) => () => {
    //console.log("xxxxxxxxxxxxxxxxx"+JSON.stringify(markupFareArray));
    let deleted_data = null;
    if (obj !== undefined && obj.fmid !== undefined) {
      confirmBranchDlg(obj, 'Markup', function () {
        deledeFMap("Markup", obj.fmid).then(response => {
          if (response.data.suc) {
            // deleted_data = fares.faresList.filter((s, sidx) => s.fmid !== obj.fmid)
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.fmid !== obj.fmid) });
            let companyList = markupFareArray;
            markupFareArray = companyList.filter((item,i) => item.name !== obj.company);
            // markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
       deleted_data = fares.faresList.filter((s, sidx) => idx !== sidx)
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      let companyList = markupFareArray;
      markupFareArray = [];
      markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
      props.saveDeleteFareMap(idx)
    }

    if(deleted_data && deleted_data.length == 0) {
      dispatch({type:"region_disable",payload:false})
    }
  }


  const deletePaxMarakup = (id) => {
    delPaxMarakup(props.configType, id).then(response => {
      if (response.data.suc) {

      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deletePaxMarakup function---" + error
      );
    });
  }

  const handleStaChange = (e) => {
    dispatch({type:'staCompanies',payload:[]})
    setData([])
    if (e && e.length > 0) {
      // staCompanies
      dispatch({ type: 'ta_comp', payload: e });
      getSTACompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  const deleteLS = (id) => {
    deleteLSid(props.configType, id).then(response => {
        if (response.data.suc) {

        }
      }).catch(function (error) {
        log.error(
          "Exception occured in deletePaxMarakup function---" + error
        );
      });
  }

  const handleRegionChange = (value) => {
    dispatch({type:'bid',payload:0})
    dispatch({type:'branchMenu',payload:[]})
    //att_user- || ta_user
    state.region_selected = value
    dispatch({type:'region_selected',payload:state.region_selected})
   props.region_type(value)
    if(state.region_selected ) {
      let region = '' //contains the selected  regions filtered in string

      let temp_regionfilter = state.region_selected.filter(function(s) { //filteration of the region in place of accordance to selection
        if(s) {
            if(region == '') {
              region = s.label
            } else {
              region = region + s.label
            }

          }
          return s;
        }
      )
      if(context.logindata.utype == "att_user") {
        setCompanyPick("");
        state.compData = []
        dispatch({type:"compData",payload : state.compData})
      if(context.logindata.is_admin){
      loadBranchesList({"region_type": region }).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'branches', payload: response.data.res })
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }else{
      loadBranchesList({ "region_type": region, "uid": context.logindata.uid}).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'branches', payload: response.data.res })
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }
  }
    }
  }
  return (
    <>
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
      <Form.Row>
        <div className="container">
          {props.configType === "Markup" ? (<h4 className="subTitle plusHr">Profiles MarkUp</h4>) : (<h4 className="subTitle plusHr">ServiceFee Profiles</h4>)}
          {context.logindata.utype == "att_user" && <>
          <Form.Row>

            <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={props.edit_sales_channel}/>


          <Form.Group as={Col} xs={3} className="selectplugin" controlId="regionType">
            <Select

               options={region}
               searchable={true}
               values={state.region_selected}
               disabled={state.region_disable}
               onChange={handleRegionChange}
 //onChange={(value) => (setDates(value))}
 />
             <Form.Label>Region<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>
          </>
}
<Form.Row className='mb-2'>
  <Form.Group as={Col} >
          {state.enableDevices &&
          <>
           <div className="d-flex align-items-center">
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
              </div>
            </>
          }

          </Form.Group>
            </Form.Row>
          <ApplicableMarkup configType={props.configType} type={"hotel"} sendPaxData={paxMarkup} updateData={updatePaxData} reset={props.reset || state.reset} sendAppMarkupId={deletePaxMarakup} sendLSid={deleteLS} editPaxData={paxData} disabledBtn={props.disabledBtn} />

          <Form.Row>
            {state.userType == "att_user" &&
              <Form.Group as={Col} controlId="classes" className="inputCheck ">

                <div className="d-flex align-items-center">
                  {/* <Form.Check type="radio" label="Branch" onChange={handleAppTypeChange} value={state.aptype} checked={enApplicable} custom name="applicableTo" className="ml-0" id="branch" /> */}
                  {/* <Form.Check type="radio" label="Category" onChange={handleAppTypeChange} value={state.aptype} checked={state.aptype !== "Branch"} custom name="applicableTo" id="category" /> */}
                  <Form.Check type="radio" label="Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={state.ctyp == "TA" ? true : false} custom name="abc" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={state.ctyp == "STA" ? true : false} custom name="abc" id="sta" />
                </div>
                <Form.Label>Applicable Type</Form.Label>
              </Form.Group>
            }


            {state.userType === "ta_user" &&
              <Form.Group as={Col}  controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} onChange={handleTypeChange} checked={companyType} custom name="xyz" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleTypeChange} checked={!companyType} custom name="xyz" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
            {state.userType === "sta_user" &&
              <Form.Group as={Col} xs={2} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} checked={companyType} custom name="ctype" className="ml-3" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
          </Form.Row>
          <Form.Row>
            {state.enbleType && state.compType && state.userType === "att_user" && !sta && (state.ctyp !== "TA" && state.ctyp !== "STA") &&

              <Form.Group as={Col} className="picklist" xs={7} >
                {state.branches && state.branches.length !== 0 &&
                  <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" type={state.compType} selData={data} dataList={state.branches} sendData={pickldata} />
                }
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>

            }
            {!state.enbleType && state.showCat && (state.ctyp !== "TA" && state.ctyp !== "STA") && state.userType == "att_user" &&

              <Form.Group as={Col} xs={3} controlId="cat">
                <Form.Control as="select" value={state.cat} onChange={handleCategoryChange}>
                  <option value="">Select</option>
                  {state.catMenu}
                </Form.Control>
                <Form.Label>Category<sup>*</sup></Form.Label>
              </Form.Group>

            }
            {!state.showCat && context.logindata.utype === "att_user" && !state.enableDevices &&

              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value="">Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>



            }

            {!state.showCat && sta && companydata &&
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={companydata}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled}
                  values={state.ta_comp}
                  onChange={(value) => (handleStaChange(value))}
                />
                <Form.Label>Ta Company</Form.Label>
              </Form.Group>
            }
            {companydata && companydata.length !== 0 && state.userType === "att_user" &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {state.staCompanies && state.staCompanies.length !== 0 && state.userType === "att_user" && sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={state.staCompanies} sendData={compdata} />
                <Form.Label>STA Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {!companyType && companydata && companydata.length !== 0 && state.userType === "ta_user" &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company4 Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {state.suppliers && state.suppliers.length !== 0 && state.enableOfcs && props.configType == "Markup" &&
              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList dataList={state.suppliers} leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected" ofcsData={officeData} sendData={ofcidsdata} />
                <Form.Label>Inventory/Office ID</Form.Label>
              </Form.Group>
            }
            <Form.Group as={Col} xs={2}>
              {!state.enableUpdate && !disableButton &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={showMarkupFares}
                > Add</Button>
              }
              {disableButton &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add</Button>
              }
            </Form.Group>
          </Form.Row>
          {state.enableUpdate &&
            <div className="text-right buttonGrop">
              <Button
                size="xs"
                onClick={showMarkupFares}
                variant="outline-primary"
                type="submit"
              >Update Applicable</Button>
            </div>
          }
        </div>
      </Form.Row>
      {fares.faresList.length!==0 &&
      <div className="tableLayout">
        <Table>
          <thead>
            <tr>
              <th>Applicable On</th>
              {state.userType === "att_user" &&
                <th>Branch/Category</th>
              }
              <th>Company Name</th>
              {/* {state.userType === "att_user" && props.configType == "Markup" &&
                <th>Supplier</th>
              } */}
              <th className="text-right" width="8%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {fares.faresList.map((fare, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <span>{fare.applyon}</span>
                  </td>
                  {state.userType === "att_user" &&
                    <td>
                      <span>{fare.borcname}</span>
                    </td>
                  }
                  {/* {state.userType !== "ta_user" &&
                    <td>
                      <span>{fare.borcname}</span>
                    </td>
                  } */}
                  <td>
                    <span>{fare.company}</span>
                  </td>
                  {/* {state.userType !== "ta_user" && props.configType == "Markup" &&
                    <td>
                      <span>{fare.sup}</span>
                    </td>
                  } */}
                  <td className="text-right">
                    {state.enabeleEdit &&
                      <Button
                        size="xs"
                        variant="link"
                        onClick={editFareMap(fare)}
                        isDisabled={(props.disabledBtn) ? true : false}
                        className="ezyIcon icon-edit"
                        type="submit">
                      </Button>
                    }
                    {context.logindata.roles !== undefined && context.logindata.roles.includes('M_U_D') && !state.hideDelete && !props.disabledBtn &&
                      <Button
                        size="xs"
                        variant="link"
                        className="ezyIcon icon-delete"
                        onClick={deleteFareMap(fare, index)}
                        isDisabled={(props.disabledBtn) ? true : false}
                        type="submit">
                      </Button>
                    }
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      }
    </>
  )
}
export default AirMarkupFareMapForm