
import log from "loglevel";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Col, Form,Modal } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import useOnclickOutside from 'react-cool-onclickoutside';
import Fifacup from '../../../../../src/client/assets/images/fifaworlcup.png'
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";
import PredectiveSearch from '../../../services/predective';
import DateUtils from '../../commonUtils/DateUtils';
import Button from '../../common/buttons/Button';
import AddTravellerClass from './AddTravellerClass';
import debounce from 'lodash.debounce';

let airlineMaster = require('../../masterData/AirlinesMasterData.json');

/**
 * @description:This function provide the search criteria for One way and Round trip
 * @author: Lakshmi
 * @param {*}
 * @function Flight Search
 * @date : 15-09-2020
 */

const initialState = {
  airportsOW: [], airportsRT: [], src: "", dest: "", dd: DateUtils.convertStringToDate(new Date()), data: "", pa: "", ns: false, ad: DateUtils.convertStringToDate(new Date()), paxCount: 1, cls: "Economy", adt: 1, chd: 0, inf: 0, orignData: [], destData: [], selOrigin: [], selDest: [], notiMessage: "", notiMessageShow: false, notiVarient: "danger", ismodify: false, srcDate: DateUtils.prettyDate(new Date(), "ddd, DD MMM, YYYY"), destDate: DateUtils.prettyDate(new Date(), "ddd, DD MMM, YYYY"), _onwardCal: false, _destiCal: false, openArivalDate: new Date(), selAir: [], flowType: "", paxCounts: 0, clearob: false, cleardb: false,clearair: false,showInboundPopup:false
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, airportsOW: [], airportsRT: [], src: "", dest: "", data: "", pa: "", ns: false, ad: "", adt: 1, chd: 0, inf: 0
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function CommonFlightSearch(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [airline, setAirline] = useState([]);
  const [addTraveller, setAddTraveller,] = useState(false);
  const [isLoad, setIsLoad,] = useState(false);
  const [originIsFocus, setOriginIsFocus,] = useState(false);
  const [destinationIsFocus, setDestinationIsFocus,] = useState(false);
  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [reverse, setReverse,] = useState(false);
  const uref = useRef();
  const originref = useRef();
  const airref = useRef();
  const [context, setContext] = useContext(Context);


  useEffect(() => {
    if (props.srcMultiData !== undefined && props.srcMultiData[0] !== undefined) {
      dispatch({ type: "src", payload: props.srcMultiData[0].airportCode })
      dispatch({ type: "selOrigin", payload: props.srcMultiData })
    }
  }, [props.srcMultiData]);

  useEffect(() => {
    if (props.destMultiData !== undefined && props.destMultiData[0] !== undefined) {
      dispatch({ type: "dest", payload: props.destMultiData[0].airportCode })
      dispatch({ type: "selDest", payload: props.destMultiData })
    }
  }, [props.destMultiData]);


  useEffect(() => {
    if (props.load) {
      setIsLoad(false)
    }

  }, [props.load]);

  useEffect(() => {
    if (props.stopButtonLoad) {
      setIsLoad(false)
    }
  }, [props.stopButtonLoad])
  useEffect(() => {
    if (props.srequest !== undefined && props.srequest.tt !== undefined) {
      dispatch({ type: "ismodify", payload: true })
      if(props.srequest.tt !== 3) {
        dispatch({ type: "src", payload: props.srequest.src[0].airportCode })
        dispatch({ type: "orignData", payload: props.srequest.src })
        dispatch({ type: "selOrigin", payload: props.srequest.src })
        dispatch({ type: "dest", payload: props.srequest.dest[0].airportCode })
        dispatch({ type: "destData", payload: props.srequest.dest })
        dispatch({ type: "selDest", payload: props.srequest.dest })
        dispatch({ type: "adt", payload: props.srequest.adt })
        dispatch({ type: "chd", payload: props.srequest.chd })
        dispatch({ type: "inf", payload: props.srequest.inf })
        dispatch({ type: "dd", payload: props.srequest.dd })
        dispatch({ type: "ad", payload: props.srequest.ad })
        dispatch({ type: "cls", payload: props.srequest.clstyp })
        setDepartureDate(DateUtils.convertNewToDate(props.srequest.dd))
        setArrivalDate(DateUtils.convertNewToDate(props.srequest.ad))
        dispatch({ type: "pa", payload: props.srequest.pa })
        dispatch({ type: "tt", payload: props.srequest.tt })
        dispatch({ type: "selAir", payload: props.srequest.selAir })
        dispatch({ type: "paxCount", payload: props.srequest.adt + props.srequest.chd + props.srequest.inf })
        dispatch({ type: "flowType", payload: props.srequest.flowType })
        dispatch({ type: "paxCounts", payload: props.srequest.adt + props.srequest.chd + props.srequest.inf })
        const srcd = DateUtils.prettyDate(props.srequest.dd, "ddd, DD MMM YYYY");
        dispatch({ type: "srcDate", payload: srcd })
        const destd = DateUtils.prettyDate(props.srequest.ad, "ddd, DD MMM YYYY");
        dispatch({ type: "destDate", payload: destd })
        dispatch({ type: "clearob", payload: true})
        dispatch({ type: "cleardb", payload: true})
        if(props.srequest.selAir.length !== 0 && props.srequest.selAir[0].label.length > 0){
          dispatch({ type: "clearair", payload: true })
          }
          else{
            dispatch({ type: "clearair", payload: false })
          }
        if (props.srequest.ns) {
          dispatch({ type: "ns", payload: true })
        }
      }
    }
  }, [props.srequest !== undefined]);

  const refOutClick = useOnclickOutside(() => {
    setAddTraveller(false);
    document.querySelector('.origin').classList.remove('activeSerField');
    document.querySelector('.destination').classList.remove('activeSerField');
    document.querySelector('.datePick').classList.remove('activeSerField');
    document.querySelector('.traveller > div').classList.remove('activeSerField');
  });

  const errorRemoveBlur = () => {
    console.log('errorRemoveBlur');
    dispatch({ type: "notiMessageShow", payload: false })
    document.querySelector('.origin').classList.remove('errorSerField');
    document.querySelector('.destination').classList.remove('errorSerField');
    document.querySelector('.datePick').classList.remove('errorSerField');
    document.querySelector('.traveller > div').classList.remove('errorSerField');
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOrigin = (input, e) => {
    dispatch({ type: "src", payload: "" })
    dispatch({ type: "orignData", payload: [] })
    dispatch({ type: "selOrigin", payload: [] })
    if (input.length > 0) {
      dispatch({ type: "clearob", payload: true })
    }
    else {
      dispatch({ type: "clearob", payload: false })
    }
    if (input.length > 2) {
      state.selOrigin = [];
      // predectiveCall(input, 'airportsOW')
      let obj = {
        textValue: input,
        type: 'airportsOW'
      }
      debouncedSave(obj);
    }
    else {
      state.selOrigin = [];
      dispatch({ type: 'airportsOW', payload: [] })
    }

  }

  // highlight-starts
  const debouncedSave = useCallback(
    debounce(obj => predectiveCall(obj.textValue, obj.type), 500),
    [], // will be created only once initially
  );
  // highlight-ends

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnOrigin = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "src", payload: obj[0].airportCode })
      dispatch({ type: "orignData", payload: obj })
      dispatch({ type: "selOrigin", payload: obj })
      uref.current.focus();
      errorRemoveBlur();
      props.sendSrc(obj);
    }
  }

  //=====================================
  // This function will open the Onward Calendar on Blur or Destination field
  //=====================================
  const openOnwardCal = () => state._onwardCal.setOpen(true);
  const openDestiCal = () => state._destiCal.setOpen(true);

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnDest = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "dest", payload: obj[0].airportCode })
      dispatch({ type: "destData", payload: obj })
      dispatch({ type: "selDest", payload: obj })
      openOnwardCal();
      errorRemoveBlur();
      props.sendDest(obj);
    }
  }

  //=====================================
  // This function will get the dest data
  //=====================================
  const handleDestination = (input, e) => {
    dispatch({ type: "dest", payload: "" })
    dispatch({ type: "destData", payload: [] })
    dispatch({ type: "selDest", payload: [] })
    if (input.length > 0) {
      dispatch({ type: "cleardb", payload: true })
    }
    else {
      dispatch({ type: "cleardb", payload: false })
    }
    if (input.length > 2) {
      state.selDest = [];
      //predectiveCall(input, 'airportsRT')
      let obj = {
        textValue: input,
        type: 'airportsRT'
      }
      debouncedSave(obj);
    }
    else {
      state.selDest = [];
      dispatch({ type: 'airportsRT', payload: [] })
    }
  }


  //=====================================
  // This function will call the service to get the Airport data
  //=====================================
  const predectiveCall = async (input, route) => {
    if (input.length > 1) {
      await PredectiveSearch.retreieveSearch(input).then((data) => {
        dispatch({ type: route, payload: data.data !== "" ? data.data : [] })

      });
    }
    else {
      dispatch({ type: route, payload: [] })
    }
  }

  //=====================================
  // This function will update the state
  //=====================================
  const handleChange = (e) => {

    const { id, value } = e.target;
    if (addTraveller) {
      setAddTraveller(false)
    } else {
      setAddTraveller(true)
      activeTraveller()
    }
    if (id === "ns") {
      if (state.ns) {
        dispatch({ type: "ns", payload: false })
      } else {
        dispatch({ type: "ns", payload: true })
      }
    }
    dispatch({ type: id, payload: value });
  }

  //=====================================
  // This function will update Non stop state
  //=====================================
  const handleChangeNs = (e) => {
    const { id, value } = e.target;
    if (state.ns) {
      dispatch({ type: "ns", payload: false })
    } else {
      dispatch({ type: "ns", payload: true })
    }
  }

  //=====================================
  // This function will check the Class type
  //=====================================
  const setClsType = () => {
    var cl = "E";
    if (state.cls === "Economy") {
      cl = "E"
    } else if (state.cls === "Business") {
      cl = "B"
    } else if (state.cls === "First class") {
      cl = "F"
    } else if (state.cls == "Premium") {
      cl = "P"
    }
    return cl;
  }

  //=====================================
  // Passing Active Class for the SearchField parents
  //=====================================
  const activeOrigin = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
    companySelect();
  }
  const activeDesti = (event) => {
    let el = event.target;
    el.closest(".addInput").classList.add('activeSerField')
  }
  const activeDate = (event) => {
    let element = document.querySelector('.datePick');
    element.classList.add('activeSerField')
  }
  const activeTraveller = (event) => {
    let element = document.querySelector('.traveller > div');
    element.classList.add('activeSerField')
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  //=====================================
  // This function will validate the Search Fields data exists or not
  //=====================================
  const validateSearch = () => {
    if (state.src === "") {
      document.querySelector('.origin').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please Enter Origin." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.dest === "") {
      document.querySelector('.destination').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please Enter Destination." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.dd === "" || state.dd === "Invalid date") {
      document.querySelector('.datePick').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please enter Onward Date." })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (props.tt === 2 && (state.ad === "" || state.ad === "Invalid date")) {
      document.querySelector('.datePick').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Please select return date" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (departureDate>arrivalDate && props.tt === 2 ){
      dispatch({ type: "notiMessage", payload: "Please select return date" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (state.src === state.dest) {
      //  alert('Src and Dest should not same'+state.src+"--"+state.dest)
      document.querySelector('.origin').classList.add('errorSerField');
      document.querySelector('.destination').classList.add('errorSerField');
      dispatch({ type: "notiMessage", payload: "Origin and Destination cannot be same" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
      hidemessage();
      return false
    }
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        document.querySelector('.domin').classList.add('dominFocus');
        hidemessage();
        return false;
      }
    }
    return true;
  }
  const companySelect = () => {
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        document.querySelector('.domin').classList.add('dominFocus');
      } else {
        document.querySelector('.domin').classList.remove('dominFocus');
      }
    }
  }

  const checkFlightSearch = (e) => {
    if (validateSearch()){
      dispatch({ type: "notiMessageShow", payload: false })
      props.onClickHandler && props.onClickHandler()
      props.onClickHandler && sessionStorage.setItem("MODIFY_SEARCH","yes")
      sessionStorage.setItem("CMPID", context.logindata.cid )
      if(state.selDest[0].countryCode!=="QA"){
          handleFlightSearch();
      }else{
        let checkCard = DateUtils.checkHayyCardDate(state.dd,state.selDest[0].countryCode);
        if(checkCard){
          dispatch({ type: "showInboundPopup", payload: true })
        }else{
          handleFlightSearch();
        }
      }
    }
  }


  //=====================================
  // This function will call the service for Search response
  //=====================================
  const handleFlightSearch = () => {
    dispatch({ type: "showInboundPopup", payload: false })
    try {
        var isPaxChanged = false;
        if(state.flowType !== undefined && (state.flowType === "ONHOLD REBOOK" || state.flowType === "INCOMPLETE REBOOK")) {
          var paxCount = state.adt + state.chd + state.inf;
          if (Number(paxCount) !== Number(state.paxCounts)) {
            isPaxChanged = true;
          }
        }
        setIsLoad(true);
        var cls = setClsType();
        let region="";
        if(context.logindata!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type!==""){
          region=context.logindata.region_type;
        }else{
          region = context.logindata.cur==="QAR"?"Qatar":"India";
        }
        const payload = {
          "src": state.selOrigin,
          "dest": state.selDest,
          "adt": state.adt,
          "chd": state.chd,
          "inf": state.inf,
          "dd": state.dd,
          "ad": state.ad,
          "tt": props.tt,
          "selAir": state.selAir,
          "ns": state.ns,
          "cls": cls,
          "clstyp": state.cls,
          "pa": state.pa,
          "cur": context.logindata.cur,
          "cid": context.logindata.cid,
          "modify": state.ismodify,
          "srcCode": state.src,
          "destCode": state.dest,
          "srcCity": state.selOrigin[0].cityName,
          "destCity": state.selDest[0].cityName,
          "srcDate": state.srcDate,
          "destDate": state.destDate,
          "sty": "normal",
          "bid": context.logindata.bid,
          "butype": context.logindata.butype,
          "bktype": "DIRECT",
          "pcid": (context.logindata.pcid !== undefined) ? context.logindata.pcid : 0,
          "flowType": "DIRECT",
          "paxChanged": isPaxChanged,
          "journey_type": "Onward",
          "region_type" : region,
          "office_id":context.logindata.office_id
        }
        history.push({
          pathname: '/ezytrip/bookings/flight/results',
          state: {
            req: payload
          }
        });
      }catch (e) {
        log.error('Exception occured in handleFlightSearch function---' + e);
      }
  }

  //=====================================
  // This function will get the Pax count and Total count for Add traveller
  //=====================================
  const paxCount = useCallback((data) => {
    dispatch({ type: "adt", payload: data[0] })
    dispatch({ type: "chd", payload: data[1] })
    dispatch({ type: "inf", payload: data[2] })
    dispatch({ type: "paxCount", payload: data[3] })
  }, []);

  //=====================================
  // This function will get Class
  //=====================================
  const clsType = useCallback((type) => {
    dispatch({ type: "cls", payload: type })
  }, []);

  //=================================================
  // This function will close Travel details pop up
  //=================================================
  const coloseTravel = (type) => {
    if (type === "coseTrv") {
      setAddTraveller(false);
    }

  }
  // OnChange event we will call this and update the state
  const handleDepDateChange = date => {
    const srcd = DateUtils.prettyDate(date, "ddd, DD MMM, YYYY")
    dispatch({ type: 'srcDate', payload: srcd })
    const convertDate = DateUtils.convertStringToDate(date);
    dispatch({ type: 'dd', payload: convertDate })
    dispatch({ type: 'openArivalDate', payload: date })
    setDepartureDate(date);
    errorRemoveBlur();
    if (props.tt === 2) {
      openDestiCal();
    } else {
      setAddTraveller(true);
    }
  }

  // OnChange event we will call this and update the state
  const handleArvDateChange = date => {
    const dest = DateUtils.prettyDate(date, "ddd, DD MMM, YYYY")
    dispatch({ type: 'destDate', payload: dest })
    const convertDate = DateUtils.convertStringToDate(date);
    dispatch({ type: 'ad', payload: convertDate })
    dispatch({ type: 'openArivalDate', payload: date })
    setArrivalDate(date);
    errorRemoveBlur();
    setAddTraveller(true);
    activeTraveller();
  }

  //=====================================
  // This function will update the Airline
  //=====================================
  const handleAirChange = (obj) => {
    dispatch({ type: "selAir", payload: obj })
    if (obj !== undefined && obj[0] !== undefined) {
      dispatch({ type: "pa", payload: obj[0].value })
    }
    else{
      dispatch({ type: "pa", payload: ""})
    }
    }
    const handlepfairChange = (obj)=>{
    if (obj.length > 0) {
      dispatch({ type: "clearair", payload: true })
    }
    else {
      dispatch({ type: "clearair", payload: false })
      }
    }

  //=====================================
  // This function will swap the Onward and Return Values
  //=====================================
  const reverseClick = () => {
    if (reverse) {
      if (state.orignData !== undefined && state.destData !== undefined && state.destData[0] !== undefined && state.destData[0].airportCode && state.orignData[0] !== undefined && state.orignData[0].airportCode !== undefined) {
        setReverse(false)
        dispatch({ type: "selOrigin", payload: state.orignData })
        dispatch({ type: "src", payload: state.orignData[0].airportCode })
        dispatch({ type: "selDest", payload: state.destData })
        dispatch({ type: "dest", payload: state.destData[0].airportCode })
      }
    } else {
      if (state.orignData !== undefined && state.destData !== undefined && state.destData[0] !== undefined && state.destData[0].airportCode && state.orignData[0] !== undefined && state.orignData[0].airportCode !== undefined) {
        setReverse(true)
        dispatch({ type: "selOrigin", payload: state.destData })
        dispatch({ type: "src", payload: state.destData[0].airportCode })
        dispatch({ type: "selDest", payload: state.orignData })
        dispatch({ type: "dest", payload: state.orignData[0].airportCode })
      }
    }
  }

  const addReturnDate = () => {
    props.sendReturn("Round");
  }

  const clearOrigin = () => {
    dispatch({ type: "src", payload: "" })
    dispatch({ type: "orignData", payload: [] })
    dispatch({ type: "selOrigin", payload: [] })
    dispatch({ type: "clearob", payload: false })
    originref.current.clear()
    originref.current.focus();
  }

  const clearPrf = () => {
    dispatch({ type: "selAir", payload: '' })
    dispatch({ type: "pa", payload: '' })
    dispatch({ type: "clearair", payload: false })

    airref.current.clear()

  }
  const clearDestination = () => {
    dispatch({ type: "dest", payload: "" })
    dispatch({ type: "destData", payload: [] })
    dispatch({ type: "selDest", payload: [] })
    dispatch({ type: "cleardb", payload: false })
    uref.current.clear()
    uref.current.focus();

  }

  const closePopup =()=>{
    dispatch({ type: "showInboundPopup", payload: false })
  }


  return (
    <>
      <div>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        <Form.Row>
          <Form.Group as={Col}>
            <div className={"addInput origin" + (originIsFocus ? " active" : "")}>
              <Form.Label><span className="ezyIcon icon-plane"></span> Origin</Form.Label>
              <Typeahead
                //defaultInputValue={state.selectedOrigin}
                id="originmaster"
                labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                onInputChange={handleOrigin}
                options={state.airportsOW}
                selected={state.selOrigin}
                onChange={airport => handleOnOrigin(airport)}
                filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                //onFocus={originOnFocus} onBlur={originOnBlur}
                placeholder="From"
                readOnly
                autoFocus
                minLength={1}
                // onFocus={activeOrigin}
                renderMenuItemChildren={(airport) => (
                  <><span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                  <strong> {airport.cityName}, </strong>
                    <span className="airportContname" title={airport.countryName}>{airport.countryName} </span>
                    <small>{airport.airportCode}</small>
                  </div>

                    <span className="airportName" title={airport.airportName}>{airport.airportName} </span>

                  </>
                )}
                ref={originref}

              />
              {state.clearob && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearOrigin}
                variant="link">
              </Button>
              }
              {/* <Form.Control type="text" onClick={handleChange} onFocus={originOnFocus} onBlur={originOnBlur} placeholder="From Country" autoComplete="off" /> */}
            </div>

          </Form.Group>
          <Form.Group as={Col}>
            <span className={"fromto ezyIcon icon-transfernew " + (reverse ? 'active' : '')} onClick={reverseClick}></span>
            <div className={"addInput destination" + (destinationIsFocus ? " active" : "")}>
              <Form.Label><span className="ezyIcon icon-plane"></span> Destination</Form.Label>
              <Typeahead
                //defaultInputValue={state.selectedOrigin}
                id="basic-typeahead-single"
                labelKey={airport => `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`}
                onInputChange={handleDestination}
                options={state.airportsRT}
                //  onFocus={originOnFocus} onBlur={originOnBlur}
                placeholder="To"
                selected={state.selDest}
                onChange={airport => handleOnDest(airport)}
                filterBy={['cityName', 'airportName', 'airportCode', 'countryName']}
                readOnly
                minLength={1}
                onFocus={activeDesti}
                renderMenuItemChildren={(airport) => (
                  <><span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                  <strong> {airport.cityName}, </strong>
                    <span className="airportContname" title={airport.countryName}>{airport.countryName} </span>
                    <small>{airport.airportCode}</small>
                  </div>


                    <span className="airportName" title={airport.airportName}>{airport.airportName} </span>
                  </>
                )}
                ref={uref}
              />
              {state.cleardb && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearDestination}
                variant="link">
              </Button>
              }
              {/* <Form.Control type="text" onFocus={destinationOnFocus} onBlur={destinationOnBlur} placeholder="To Country" autoComplete="off" /> */}
            </div>
          </Form.Group>

          <Form.Group as={Col}>
            <div className="addInput datePick datePicker d-flex">
              <div className="from">
                <Form.Label><span className="ezyIcon icon-newcalendar"></span> Departure</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={departureDate}
                  //value={state.dd}
                  onChange={handleDepDateChange}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  // monthsShown={2}
                  onCalendarOpen={activeDate}
                  ref={(c) => state._onwardCal = c}
                  selectsStart
                  startDate={departureDate}
                  endDate={arrivalDate}
                  onFocus={e => e.target.blur()}
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                />
              </div>
              {props.tt === 1 &&
                <div className="to onewayTo d-flex align-items-center" onClick={addReturnDate}>
                  <Form.Label> Add Arrival Date</Form.Label>
                  <span className="addDate ezyIcon icon-plus"></span>
                </div>
              }
              {props.tt === 2 &&
                <div className="from">
                  <Form.Label><span className="ezyIcon icon-newcalendar"></span> Arrival</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={arrivalDate}
                    openToDate={state.openArivalDate}
                    //value={state.ad}
                    onChange={handleArvDateChange}
                    // monthsShown={2}
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    minDate={departureDate === undefined ? (departureDate> arrivalDate?departureDate:arrivalDate) : departureDate}
                    onCalendarOpen={activeDate}
                    ref={(c) => state._destiCal = c}
                    selectsEnd
                    startDate={departureDate}
                    endDate={arrivalDate}
                    onFocus={e => e.target.blur()}
                    // popperPlacement='bottom'
                    popperModifiers={{
                      flip: {
                        behavior: ['bottom'] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                  />
                </div>
              }
            </div>
          </Form.Group>
          <Form.Group as={Col} className="traveller" ref={refOutClick}>
            <div className="addInput">
              <Form.Label><span className="ezyIcon icon-usernew"></span> Traveller & Class</Form.Label>
              <Form.Control onClick={handleChange} type="text" placeholder={state.paxCount + " Traveller, " + state.cls} autoComplete="off" readOnly />
            </div>
            {addTraveller && <AddTravellerClass sendData={paxCount} sendClass={clsType} closetravaler={coloseTravel} sendCount={[state.adt, state.chd, state.inf, state.cls]} />}
          </Form.Group>
         <Form.Group as={Col} className="preferred pl-0 mb-0">
         <div className="addInput">
         <Form.Label><span className="ezyIcon icon-star"></span> Preferred Airline</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey={air => `${air.label} [${air.value}]`}
                readOnly
                onInputChange={air =>handlepfairChange(air)}
                onChange={air => handleAirChange(air)}
                options={airlineMaster}
                placeholderText="Enter Airline"
                placeholder="Select Airline"
                //selected={state.pa}
                ref = {airref}
                selected={state.selAir}
              />
              {state.clearair && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearPrf}
                variant="link">
              </Button>}
</div>
            </Form.Group >


          <Form.Group className="searchHere" as={Col}>

            <Button
              size="lg" block
              className="d-flex"
              onClick={checkFlightSearch}
              variant="primary"
            ><span className={"d-flex loading ezyIcon icon-newsearch " + (isLoad ? 'progress-bar-striped progress-bar-animated' : '')}>SEARCH{isLoad ? <> </> : <> {!state.ismodify ? "" : ""} </>} </span></Button>

          </Form.Group>
        </Form.Row>
        <Form.Row className="nonStopflgt">
          <Col className="addAirportCode">
            <Form.Group className="m-0 " controlId="ns">
              <Form.Check type="checkbox" checked={state.ns} value={state.ns} label="Non stop flight" onClick={handleChangeNs} custom />
            </Form.Group>
          </Col>
        </Form.Row>
      </div>


      {state.notiMessageShow && (
        <Alert className="notification" variant={state.notiVarient} onClose={() => dispatch({ type: "notiMessageShow", payload: false })} dismissible> {state.notiMessage}</Alert>
      )}

<Modal
        show={state.showInboundPopup}
        //onHide={handlePriceClose}
        className="importPNRMdl comingtoFifa"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
                          <Modal.Title>Coming to Qatar?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <img src={Fifacup} />
              <p>You need Hayya card to enter the state of Qatar between 1st Novmber 2022 - 23rd December 2022 to access the stadiums.(Non Citizens & RP Holders)</p>
             <span><a className="d-inline-flex" href="https://www.qatar2022.qa/en/coming-to-qatar" target="_blank">For more info : <span> www.qatar2022.qa/en/coming-to-qatar</span></a></span>
              </Modal.Body>
        <Modal.Footer className="justify-content-end">
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={closePopup}
            >No</Button>
            <Button
              size="xs"
              onClick={handleFlightSearch}
              variant="outline-primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default CommonFlightSearch;
