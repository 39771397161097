import log from "loglevel";
import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-dropdown-select';
import { countriesMenu } from '../../../components/adminstration/utils';
import Button from '../../common/buttons/Button';
import Utilities from '../../commonUtils/Utilities';
import { getAirportData } from './../airServices/operations';
/**
 * @description:This function will creaet all the routes
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 09-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  id: 0, countriesList: [], source: [], destination: [], sccode: "", scname: "", dccode: "", dcname: "", sarray: [], darray: [], showRoute: false,
  selsource_air: [], seldestination_air: [], notiMessage: "", notiMessageShow: false, notiVarient: ""
};

let routesArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    case 'clear':
      return {
        ...state, id: 0, source: [], destination: [], sccode: "", scname: "", dccode: "", dcname: "", sarray: [], darray: [], showRoute: false,
        selsource_air: [], seldestination_air: [], notiMessage: "", notiMessageShow: false, notiVarient: ""
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function Routes(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [routDetails, setRoutDetails] = useState({ rts: [] });
  const [countrys, setCountrys] = useState([]);
  const [countryd, setCountryd] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [disableButton, setDisableButton] = useState();
  //console.log("Config Type : "+props.configType);

  useEffect(() => {
    if (props.routesData !== undefined) {
      setRoutDetails({ rts: props.routesData });
      dispatch({ type: 'showRoute', payload: true })
    }
  }, [props.routesData]);

  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      setRoutDetails({ rts: [] })
      setCountrys([])
      setCountryd([])
      setValue1([])
      setValue2([])
      setDisableButton()
    }
  }, [props.reset])
  useEffect(() => {
    if (props.configType !== "") {
      routesArray = []
    }
  }, [props.configType]);

  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);


  useEffect(() => {
    countriesMenu().then(response => {
      if (response.data.suc) {
        response.data.res.unshift({ name: "All", id: 0, code: "All" });
        dispatch({ type: 'countriesList', payload: response.data.res })
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCountryList function---" + error
      );
    });

  }, []);


  const handleChangeCountry = (obj, type) => {
    if (obj[0]) {
      if (type === "source") {
        setCountrys();
        dispatch({type:"sarray",payload:[]})
        dispatch({type:"selsource_air",payload:[]})
        dispatch({ type: 'sccode', payload: obj[0].code })
        dispatch({ type: 'scname', payload: obj[0].name + "(" + obj[0].code + ")" })
        if (obj[0].code !== "All") {
          getAirportData(obj[0].code).then(response => {
            if (response.data.suc) {
              response.data.res.unshift({ value: "All", label: "All" });
              dispatch({ type: 'source', payload: response.data.res })
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getAirportData function---" + error
            );
          });
        } else {
          dispatch({ type: 'source', payload: [{ value: "All", label: "All" }] })
        }
      } else {
        setCountryd();
        dispatch({type:"seldestination_air",payload:[]})
        dispatch({type:"darray",payload:[]})
        dispatch({ type: 'dccode', payload: obj[0].code })
        dispatch({ type: 'dcname', payload: obj[0].name + "(" + obj[0].code + ")" })
        if (obj[0].code !== "All") {
          getAirportData(obj[0].code).then(response => {
            if (response.data.suc) {
              response.data.res.unshift({ value: "All", label: "All" });
              dispatch({ type: 'destination', payload: response.data.res })
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getAirportData function---" + error
            );
          });
        } else {
          dispatch({ type: 'destination', payload: [{ value: "All", label: "All" }] })
        }
      }
    } else {
      //If Source Country and Destination Country
      if (type === "source") {
        dispatch({ type: 'source', payload: [] })
      } else {
        dispatch({ type: 'destination', payload: [] })
      }
    }
  }

  const handleRoutes = () => {
    var data = "";
    if (validateRoutes()) {
      if (state.sarray && state.sarray.length !== 0) {
        state.sarray.map((obj) => {
          var route = "";
          if (obj && obj !== undefined) {
            route = {
              "srcct": state.sccode,
              "scname": state.scname + " " + "[" + obj + "]",
              "destct": state.dccode,
              "dcname": state.dcname + " " + "[" + state.darray + "]",
              "srcair": state.sarray,
              "destair": state.darray
            }
          }
          routesArray.push(route);
          routDetails.rts.push(route);
          setRoutDetails({ rts: routDetails.rts });
          data = route;
          dispatch({ type: 'sarray', payload: [] })
          dispatch({ type: 'darray', payload: [] })
        }
        );
        dispatch({ type: 'selsource_air', payload: [] })
        dispatch({ type: 'seldestination_air', payload: [] })
        dispatch({ type: 'source', payload: [] })
        dispatch({ type: 'destination', payload: [] })
        setCountrys([]);
        setCountryd([]);

        //saving json object construction
        let route_json = {
          srcct: state.sccode,
          destct: state.dccode,
          srcair: state.sarray,
          destair: state.darray,
          scname: state.scname,
          dcname: state.dcname
        }
        props.sendRoutesData(route_json);
        //props.sendRoutesData(data);
        dispatch({ type: 'showRoute', payload: true })
      } else {
        if (state.sccode === "All" && state.dccode === "All") {
          let route_json = {
            srcct: "All",
            destct: "All",
            scname: "All",
            dcname: "All"
          }
          props.sendRoutesData(route_json);
          routDetails.rts.push(route_json);
          setRoutDetails({ rts: routDetails.rts });
          dispatch({ type: 'showRoute', payload: true })
        }
      }
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  //checking the duplicates based on length(original json and unique json length)
  const validateRoutes = () => {
    if (state.sccode === "" && state.dccode === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Source and Destination Country' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    } else if (state.sarray.length !== 0 && state.darray.length !== 0) {
      //construction source and destination json for validationg duplicate
      for (let s in state.sarray) {
        let src = state.sarray[s];
        for (let d in state.darray) {
          let des = state.darray[d];
          let route = {
            scnt: state.sccode,
            dcnt: state.dccode,
            source: src,
            destination: des,
          }
          routesArray.push(route);
        }
      }
      let data = Utilities.getUniqData(routesArray);
      if (routesArray.length !== data.length) {
        routesArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Routes are already exist' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()

        return false;
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please Select Source and Destination City' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()

      return false;
    }
    return true;
  }

  const handleRemoveRoute = (route, idx) => () => {
    if (route !== undefined && route.is_db !== undefined && route.is_db === 1) {
      props.sendRouteDelData(route)
      setRoutDetails({ rts: routDetails.rts.filter((s, sidx) => idx !== sidx) });
      props.updateData(routDetails.rts.filter((s, sidx) => idx !== sidx));
    } else {
      setRoutDetails({ rts: routDetails.rts.filter((s, sidx) => idx !== sidx) });
      let routesList = routesArray;
      routesArray = [];
      routesArray.push(routesList.filter(item => item.scnt !== undefined && item.scnt !== route.srcct && item.dcnt !== route.destct && route.srcair.includes(item.source)));
      //let test=route.scname.split(" ");
      //var scode=test[1].replace(/[\[\]']+/g,'');
      props.updateData(routDetails.rts.filter((s, sidx) => idx !== sidx));
    }
  };

  const setRoutes = (data, val) => {
    let sairs = [];
    let dairs = [];
    if (state.sccode && val === 1) {
      dispatch({ type: 'selsource_air', payload: data });
      data.map((obj) => {
        if (obj.value) {
          sairs.push(obj.value);
          dispatch({ type: 'sarray', payload: sairs })
        }
      }
      );
    }
    if (state.dccode && val === 2) {
      dispatch({ type: 'seldestination_air', payload: data });
      data.map((obj) => {
        if (obj.value) {
          dairs.push(obj.value);
          dispatch({ type: 'darray', payload: dairs })
        }
      }
      );
    }
  }


  return (
    <>
      <div>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        <Form.Row>
          <Form.Group as={Col} className="selectplugin" lg={3} md={4} >
            <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              onChange={cnt => handleChangeCountry(cnt, "source")}
              options={state.countriesList}
              placeholder="Choose Country.."
              selected={countrys}
            />
            <Form.Label>From Country <sup>*</sup></Form.Label>
          </Form.Group >
          <Form.Group as={Col} className="selectplugin" lg={3} md={4}>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              onChange={cnt => handleChangeCountry(cnt, "dest")}
              options={state.countriesList}
              placeholder="Choose Country..."
              selected={countryd}
            />
            <Form.Label>To Country <sup>*</sup></Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}  lg={3} md={4} className="selectplugin" controlId="selectedValue">
            <Select
              multi
              options={state.source}
              values={state.selsource_air}
              onChange={(value1) => (setValue1(value1), setRoutes(value1, 1))}
            />
            <Form.Label>Source Airport <sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col}  lg={3} md={4} className="selectplugin" controlId="xx">
            <Select
              multi
              options={state.destination}
              values={state.seldestination_air}
              //onChange={(value) => console.log(value)}
              onChange={(value2) => (setValue2(value2), setRoutes(value2, 2))}
            />
            <Form.Label>Destination Airport <sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            {!disableButton &&
              <Button
                size="xs"
                variant="outline-primary"
                onClick={handleRoutes}
                isDisabled={(props.disabledBtn) ? true : false}
              > Add</Button>
            }
            {disableButton &&
              <Button
                size="xs"
                variant="outline-primary"
                loading={state.loadBranch}
                isDisabled={true}
                type="submit"
              > Add</Button>
            }
          </Form.Group>
        </Form.Row>
        {state.showRoute && routDetails.rts.length !== 0 &&
          <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Source - Destination</th>
                      <th width="8%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routDetails.rts && routDetails.rts.map((route, index) => (
                      <React.Fragment key={index}>
                        {route.scname !== undefined && route.dcname !== undefined &&
                          <tr>
                            <td>
                              <span>{route.scname} {"--->"} {route.dcname}</span>
                            </td>

                            <td>
                              {!disableButton &&
                                <Button
                                  size="xs"
                                  onClick={handleRemoveRoute(route, index)}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                              {disableButton &&
                                <Button
                                  size="xs"
                                  isDisabled={true}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>}
      </div>
    </>
  )
}
export default Routes