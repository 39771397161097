import axios from 'axios'

const REACT_APP_B2B = process.env.REACT_APP_B2B_URL
const API_URL = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the VISA CURD Operations for Exchange rate
 * @param {*} Service 
 */

class VisaBookingService {

    async getVisaDetails(obj){
        return await axios.post(`${API_URL}/att/visa/getNationalities`,obj)
    }
    async getVisaSummaryData(id){
        return await axios.get(`${API_URL}/att/visa/summary/${id}`,)
    }
    async getVisaSearch(data){
        return await axios.post(`${API_URL}/att/visa/getPrice`, data)
    }
    async getViseaPriceDetails(id){
        return await axios.get(`${API_URL}/att/visa/edit/${id}`)
    }
    async saveVisaBooking(req){
        return await axios.post(`${API_URL}/att/visa/preBook`,req)
    }
    async updateSummary(req){
        return await axios.post(`${API_URL}/att/visa/summary/pax/edit`, req)
    }
    async getVisaTravCntsDetails(obj){
        return await axios.post(`${API_URL}/att/visa/getTravelCountries`,obj)
    }
    }
export default new VisaBookingService()