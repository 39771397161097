
import React, { useEffect, useState } from 'react';
import ResultBlock from '../../../../bookingFlows/flight/search/common/ResultBlock';
import SortrtSplit from '../filtersFBF/SortReturn';
function ReturnPackages(props) {
    const [returnFlights, setReturnFlights] = useState(props.searchRes);
    const [destorigins, setDestorigins] = useState([]);
    const [selPrice, setSelPrice] = useState();
    const [update, setUpdate] = useState(false);
    const tripType = props.trip;
    const secDet = props.sectors;
    const currency = props.cur;
    const mappings = props.mapping;

    useEffect(() => {
        setReturnFlights(props.searchRes)
    }, [props.searchRes]);

    const handleChangeCallBack = (res, type) => {
        setReturnFlights(res);
        props.arHandelChange(res)
    }


    const handleArrivalSelectedCallBack = (res, selected) => {
        let responseFiltered = Object.assign({}, res);
        responseFiltered.srcorigns = undefined;
        responseFiltered.srcorigns = [JSON.parse(selected)];
        props.arHandelChange(responseFiltered, 'split');
    }

    const selRetunFlt = (data, price) => {
        setDestorigins({ srcorigns: data });
        setSelPrice(price)
    }

    const returnServiceFee = (fee) => {
        props.returnService(fee)
    }

    const updateRTPrice=(price)=>{
        for(let val of returnFlights.origns){
            for(let src of val.srcorigns){
                if(src.tid===price[1]){
                    val.pr=price[0].pr;
                    val.paxpr=price[0].paxpr;
                    break;
                }
            }
        }
        setUpdate(true);
        props.sendRtPrice(price)
    }

    const updateRTAirPrice=(orign)=>{
        let priceOrgn=orign[0];
        for(let val of returnFlights.origns){
        if(val.srcorigns[0].tid===priceOrgn[0].srcorigns[0].tid){
            val.pr=priceOrgn[0].pr;
            val.paxpr=priceOrgn[0].paxpr;
            val.servicefee=priceOrgn[0].servicefee;
            val.show=true;
            props.sendRtAirPrice(priceOrgn)
            break;
        }  
        }
    }

    return (
        <>
            <div className="resultSection p-0 mb-3">
                {returnFlights &&
                <SortrtSplit searchRes={returnFlights} handelChange={handleChangeCallBack} srequest={props.srequest}  sortClear={props.sortClear} sectors={secDet} mapping={mappings} />
                  }
                </div>
            
            <div class="resultLegsReturn">
            {returnFlights && returnFlights.origns.map((flights, index) => (
                <ResultBlock sect={secDet} sty={'split'} secType={'arrival'} arrivalSelectedCallBack={handleArrivalSelectedCallBack} mapping={mappings} fltIndex={index} searchRes={flights.srcorigns} trip={tripType} sectors={flights} currency={currency} price={flights.pr} retunFlt={selRetunFlt} srequest={props.srequest} rtsplitFlights={props.rtsplitFlights} returnService={returnServiceFee} sendTfPrice={updateRTPrice} onFlyMkp={props.onSecMkp} sendPriceResp={updateRTAirPrice} regionType={props.regionType} gst={props.gst}/>
            ))}
            </div>

        </>
    )
}


export default ReturnPackages;
