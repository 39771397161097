import React, { useEffect, useReducer, useRef, useState , useContext} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Link } from 'react-router-dom';
import { Context } from "../../../../../../App";
import DateUtils from '../../../../commonUtils/DateUtils';
import Utilities from '../../../../commonUtils/Utilities';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';
import { Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';

//Initial State
const initialState = {
    response: {}, finalResponse: {}, sortList: false, listView: false, columnView: true, fastestPrice: 0,dprt:false,arvl:false,trdp:false,prc:false,
    activeTab: 'cheapest',arvllatest:false,trdpearliest:false,prclowest:false,dprtlatest:false
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {

        case 'min':
            return { ...state, price: action.payload };
            case 'clear':
                 return {...state,sortList: false, listView: false, columnView: true,dprt:false,arvl:false,trdp:false,prc:false,
                    activeTab: 'cheapest',arvllatest:false,trdpearliest:false,prclowest:false,dprtlatest:false}
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//Main function
function Sort(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [sortActive, sortInActive,] = useState(true);
    const [context, setContext] = useContext(Context);

    const uref = useRef();
    const dep = useRef(null);
    const arr = useRef(null);
    const tDS = useRef(null);
    const pri = useRef(null);
    useEffect(() => {
        const searcRs = props.searchRes;
        dispatch({ type: 'response', payload: searcRs })
        dispatch({ type: 'finalResponse', payload: Object.assign({}, searcRs) })
        dispatch({ type: 'fastestPrice', payload: searcRs.filter.fstst })
        if(props.sortClear) {
            dispatch({type:'clear',payload:true})
            // props.handelChange(props.initResp,'')
        }
    }, [props.searchRes])

    useEffect(() => {
      if(dep&&dep.current&&dep.current.state&&dep.current.state.values){dep.current.state.values = []}
      if(arr&&arr.current&&arr.current.state&&arr.current.state.values){arr.current.state.values = []}
      if(tDS&&tDS.current&&tDS.current.state&&tDS.current.state.values){tDS.current.state.values = []}
      if(pri&&pri.current&&pri.current.state&&pri.current.state.values){pri.current.state.values = []}
    },[sessionStorage.getItem("ISRESET")])

    useEffect(() => {
        if(props.filterSorted) {
            dispatch({ type: 'sortList', payload: false })
        }
    },[props.filterSorted])
    //Departure Date and Time Sort
    const departureTimeSort = (depType) => {
        dispatch({ type: 'sortList', payload: false })
        let res ;

        if(depType[0].value==='earliest'){
            dispatch({ type: 'dprt', payload: true });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

             res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isAfter(one.srcorigns[0].seg[0].dd, two.srcorigns[0].seg[0].dd)) {
                    return 1;
                }
                else return -1;
            })
        }
        else{
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: true });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

             res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isBefore(one.srcorigns[0].seg[0].dd, two.srcorigns[0].seg[0].dd)) {
                    return 1;
                }
                else return -1;
            })

        }


        state.response.origns = res;
        props.handelChange(state.response, '')

    }

    //Departure Date and Time Sort
    const arrivalTimeSort = (arrType) => {
        dispatch({ type: 'sortList', payload: false });
        let res ;
        if(arrType[0].value==='earliest'){

            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: true });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

            res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isAfter(one.srcorigns[0].seg[(one.srcorigns[0].seg.length - 1)].ad, two.srcorigns[0].seg[(two.srcorigns[0].seg.length - 1)].ad)) {
                    return 1;
                }
                else return -1;
            })
        }
        else{
            dispatch({ type: 'arvllatest', payload: true });
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

             res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isBefore(one.srcorigns[0].seg[(one.srcorigns[0].seg.length - 1)].ad, two.srcorigns[0].seg[(two.srcorigns[0].seg.length - 1)].ad)) {
                    return 1;
                }
                else return -1;
            })
        }
        state.response.origns = res;
        props.handelChange(state.response, '')

    }

    // Duration Sort
    const tripDurationSort = (tripType) => {
        var selClass = document.querySelectorAll('.fdnextOpen');
        for (var s = 0; s < selClass.length; s++) {
            selClass[s].classList.remove("fdnextOpen");
        }
        dispatch({ type: 'activeTab', payload: 'fastest' })
        dispatch({ type: 'sortList', payload: false })
        let res = [];
        state.finalResponse.origns.forEach(flights => {
            let results = flights.srcorigns.sort(function (one, two) {
                if (DateUtils.isAfterTime(one.sdur, two.sdur)) {
                    return 1;
                }
                else return -1;
            });


            flights.srcorigns = [];
            flights.srcorigns = results;
            res.push(flights)
        });
        state.response.origns = res;

        if(tripType[0].value==='shortest'){
            dispatch({ type: 'trdp', payload: true });
            dispatch({ type: 'trdpearliest', payload: false });
            state.response.origns.sort((one, two) => {
                return one.srcorigns[0].sdur.localeCompare(two.srcorigns[0].sdur);
            })
        }
        else{
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: true });
            state.response.origns.sort((one, two) => {
                return two.srcorigns[0].sdur.localeCompare(one.srcorigns[0].sdur);
            })
        }
        dispatch({ type: 'dprt', payload: false });
        dispatch({ type: 'dprtlatest', payload: false });
        dispatch({ type: 'arvl', payload: false });
        dispatch({ type: 'arvllatest', payload: false });
        dispatch({ type: 'prc', payload: false });
        dispatch({ type: 'prclowest', payload: false });
        
        let idx = 0;
        for (let index = 0; index < state.response.origns.length; index++) {
            if (index <= 3 && state.response.origns[index].pr.tf === state.fastestPrice) {
                idx = index;
                break;
            }
        }

        if (idx !== 0) {
            [state.response.origns[idx], state.response.origns[0]] = [state.response.origns[0], state.response.origns[idx]];
        }

        props.handelChange(state.response, 'fastest')
    }

    //Price Sort
    const priceSort = (filt) => {
        if (filt[0].value === 'cheapest') {
            dispatch({ type: 'activeTab', payload: 'cheapest' })
        }
        if (filt[0].value === 'best') {
            dispatch({ type: 'activeTab', payload: 'best' })
        }
        dispatch({ type: 'sortList', payload: false });
        let results;
        if(filt[0].value==='lowest'){
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: true });

             results = state.finalResponse.origns.sort(function (one, two) {

                let oneprice = one.pr.tf
                let twoprice = two.pr.tf
                if(one.pr.tax && two.pr.tax) {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Add")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Add")
                } else if(props.srequest.flowType !== "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Sub")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Sub")
                } else if(props.srequest.flowType == "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.gtd, calcuateServiceFee(one.servicefee), "Add")
                    twoprice = Utilities.addPrice(two.pr.gtd, calcuateServiceFee(two.servicefee), "Add")
                }
                return (oneprice - twoprice)
            });
        }
        else{
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: true });
            dispatch({ type: 'prclowest', payload: false });
            results = state.finalResponse.origns.sort(function (one, two) {
                let oneprice = one.pr.tf
                let twoprice = two.pr.tf
                if(one.pr.tax && two.pr.tax) {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Add")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Add")
                } else if(props.srequest.flowType !== "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Sub")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Sub")
                } else if(props.srequest.flowType == "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.gtd, calcuateServiceFee(one.servicefee), "Add")
                    twoprice = Utilities.addPrice(two.pr.gtd, calcuateServiceFee(two.servicefee), "Add")
                }
                return (twoprice - oneprice)
            });
        }



        state.response.origns = results;
        props.handelChange(state.response, filt)
    }

    //Price Sort
    const sortSingles = (filt) => {
        let results;
        if (filt === 'cheapest') {
            dispatch({ type: 'activeTab', payload: 'cheapest' })
            results = state.finalResponse.origns.sort(function (one, two) {
                return (one.pr.tf - two.pr.tf)
            });
        }
        if (filt === 'best') {
            dispatch({ type: 'activeTab', payload: 'best' });

            let orignsRefundable = [];
            let orignsNonRefundable = [];
            let bestValue;
            state.finalResponse.origns.forEach(v => {
                if (v.srcorigns[0].re === 'Refundable Before Departure') {
                    orignsRefundable.push(v);
                };
                if (orignsRefundable.length === 0 && v.srcorigns[0].re === 'Non-Refundable') {
                    orignsNonRefundable.push(v);
                };
            })


            if (orignsRefundable.length > 0) {

                let orignsRefundableSort = orignsRefundable.sort(function (one, two) {
                    if (DateUtils.isBeforeTime(one.srcorigns[0].sdur, two.srcorigns[0].sdur)) {
                        return 1;
                    }
                    else return -1;
                });


                orignsRefundable = orignsRefundableSort.slice(0, 5);

                orignsRefundable.sort((a, b) => a.pr.tf - b.pr.tf);
                bestValue = orignsRefundable[0];

            }
            else if (orignsNonRefundable.length > 0) {
                let orignsRefundableSort = orignsRefundable.sort(function (one, two) {
                    if (DateUtils.isBeforeTime(one.srcorigns[0].sdur, two.srcorigns[0].sdur)) {
                        return 1;
                    }
                    else return -1;
                });
                orignsNonRefundable = orignsRefundableSort.slice(0, 5);
                orignsNonRefundable.sort((a, b) => a.pr.tf - b.pr.tf);
                bestValue = orignsNonRefundable[0];
            }

            results = [bestValue, ...state.finalResponse.origns]
        }

        let finalResults = []
        for(let val of results) {
            if(val) {
                finalResults.push(val)
            }
        }
        state.response.origns = finalResults;
        props.handelChange(state.response, filt)
    }

    const openSortList = (e) => {
        if (state.sortList) {
            dispatch({ type: 'sortList', payload: false })
        } else {
            dispatch({ type: 'sortList', payload: true })
        }
    }

    const listPassing = () => {
        dispatch({ type: 'listView', payload: true })
        dispatch({ type: 'columnView', payload: false })
        props.resultViewType("list")
    }

    const columnPassing = () => {
        dispatch({ type: 'columnView', payload: true })
        dispatch({ type: 'listView', payload: false })
        props.resultViewType("column")
    }
    const refOutClick = useOnclickOutside(() => {
        dispatch({ type: 'sortList', payload: false })
    });

      //Calculation the DISC
      const calcuateServiceFee = (serviceFeeList) => {
        var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
        let servicefee = 0;
        if (serviceFeeList !== undefined) {
          servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
          if(servicefee==="NaN"){
            return 0.00;
          }else{
            return servicefee;
          }
        } else {
          return 0.00;
        }
      }


        //Calculation the DISC
        const calculateDisc = (taxes) => {
            if (taxes !== undefined) {
              for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
                var mak = taxes[taxIndex];
                if (mak !== undefined && mak.dealmkp!==undefined && mak.dealmkp !== 0) {
                  return mak.dealmkp;
                } else {
                  return 0.00;
                }
              }
            } else {
              return 0.00;
            }
          }


    return (
      <>
        {state.response.filter && (
          <>
            <div className="sort">
              <div className="sortByValue">
                <ul>
                  <li
                    className={state.activeTab === "cheapest" ? "active" : ""}
                  >
                    <Link
                      onClick={() => {
                        sortSingles("cheapest");
                      }}
                    >
                      <span className="sortLabel  ezyIcon icon-cheapest">
                        Lowest
                      </span>
                      <span className="pricetag sortPrice">
                        <em>{state.response.cur}</em>{" "}
                        <strong>
                          <FormateCurrency
                            value={state.response.filter.chpst}
                          />
                        </strong>
                      </span>
                    </Link>
                  </li>
                  {/* <li className={state.activeTab === 'fastest' ? "active" : ''}><Link onClick={tripDurationSort}>
                                    <span className="sortLabel">
                                        <Icon className="hotel" size={20} icon="fastest" /> Fastest
                       </span>
                                    <span className="pricetag sortPrice">
                                    <em>{state.response.cur}</em><strong>{state.response.filter.fstst + + calcuateServiceFee()}</strong>
                                    </span>
                                </Link></li> */}
                  {/* <li className={state.activeTab === 'best' ? "active" : ''}><Link onClick={() => { sortSingles('best') }}>
                                    <span className="sortLabel">
                                        <Icon className="hotel" size={16} icon="bestValue" /> Recommended
                       </span>
                                    <span className="pricetag sortPrice">
                                    <em> {state.response.cur}</em> <strong><FormateCurrency value={state.response.filter.best} /></strong>
                                    </span>
                                </Link></li> */}
                </ul>

                <div className="sortRange sortbyDep d-flex">
                  <span className="sortTitle">Sort by : </span>
                  <div className="selCtstr deparTure">
                  <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                ref={dep}
                options={[{ label: "Earliest", value: 'earliest' }, { label: "Latest", value: 'latest' }]}
                searchable={false}
                onChange={(value) => (departureTimeSort(value))}
                placeholder="Depature"
              />
              {/* <Form.Label>Depature</Form.Label> */}
            </Form.Group>
                    {/* <select
                      id="depature"
                      onChange={(e) => departureTimeSort(e.target.value)}
                    >
                      <option value="depature">Depature</option>
                      <option value="earliest">Earliest</option>
                      <option value="latest">Latest</option>
                    </select> */}
                  </div>
                  <div className="selCtstr">
                  <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                ref={arr}
                options={[{ label: "Earliest", value: 'earliest' }, { label: "Latest", value: 'latest' }]}
                searchable={false}
                onChange={(value) => (arrivalTimeSort(value))}
                placeholder="Arrival"
              />
              {/* <Form.Label>Arrival</Form.Label> */}
            </Form.Group>
                    {/* <select
                      id="Arrival"
                      onChange={(e) => arrivalTimeSort(e.target.value)}
                    >
                      <option value="arrival">Arrival</option>
                      <option value="earliest">Earliest</option>
                      <option value="latest">Latest</option>
                    </select> */}
                  </div>
                  <div className="selCtstr tripDuration">
                  <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
              ref={tDS}
                options={[{ label: "Shortest", value: 'shortest' }, { label: "Longest", value: 'earliest' }]}
                searchable={false}
                onChange={(value) => (tripDurationSort(value))}
                placeholder="Trip Duration"
              />
              {/* <Form.Label>Trip Duration</Form.Label> */}
            </Form.Group>
                    {/* <select
                      id="Duration"
                      onChange={(e) => tripDurationSort(e.target.value)}
                    >
                      <option value="duration">Trip Duration</option>
                      <option value="shortest">Shortest</option>
                      <option value="earliest">Longest</option>
                    </select> */}
                  </div>
                  <div className="selCtstr price">
                  <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                ref={pri}
                options={[{ label: "Highest", value: 'highest' }, { label: "Lowest", value: 'lowest' }]}
                searchable={false}
                onChange={(value) => (priceSort(value))}
                placeholder="Price"
              />
              {/* <Form.Label>Price</Form.Label> */}
            </Form.Group>
                    {/* <select
                      id="Price"
                      onChange={(e) => priceSort(e.target.value)}
                    >
                      <option value="price">Price</option>
                      <option value="highest">Highest</option>
                      <option value="lowest">Lowest</option>
                    </select> */}
                  </div>
                  {/* <Link onClick={openSortList} className={state.sortList ? 'active' : 'inactive'}>
                                    <span className="sortPrice">
                                        Sort</span>
                                        <span className=" ezyIcon icon-fromTo"></span>

                                </Link> */}

                  {/* <div className={"sortBy " + (state.sortList ? 'active' : 'inactive')} ref={refOutClick}>
                                    <ul className="b-flex">
                                        <li className={state.dprt ? 'active' : 'inactive'}><Link onClick={()=>departureTimeSort('earliest')}>Departure (Earliest)</Link></li>
                                        <li className={state.dprtlatest ? 'active' : 'inactive'}><Link onClick={()=>departureTimeSort('latest')}>Departure (Latest)</Link></li>
                                        <li className={state.arvl ? 'active ' : 'inactive'}><Link onClick={()=>arrivalTimeSort('earliest')}>Arrival (Earliest)</Link></li>
                                        <li className={state.arvllatest ? 'active ' : 'inactive'}><Link onClick={()=>arrivalTimeSort('latest')}>Arrival (Latest)</Link></li>
                                        <li className={state.trdp ? 'active' : 'inactive'}><Link onClick={()=>tripDurationSort('shortest')}>Duration (Shortest)</Link></li>
                                        <li className={state.trdpearliest ? 'active' : 'inactive'}><Link onClick={()=>tripDurationSort('earliest')}>Duration (Longest)</Link></li>
                                        <li className={state.prc ? 'active' : 'inactive'}><Link onClick={()=>priceSort('highest')}>Price (Highest)</Link></li>
                                        <li className={state.prclowest ? 'active' : 'inactive'}><Link onClick={()=>priceSort('lowest')}>Price (Lowest)</Link></li>
                                    </ul>
                                </div> */}
                </div>
              </div>
              {props.triptype === 2 && (
                <div className="gridStyle d-flex">
                  <div className="sortRange list">
                    <Link
                      onClick={listPassing}
                      className={state.listView ? "active" : ""}
                    >
                      {/* <span className="sortPrice"> List </span> */}
                      <span className=" ezyIcon icon-list"></span>
                    </Link>
                  </div>
                  <div className="sortRange box">
                    <Link
                      onClick={columnPassing}
                      className={state.columnView ? "active" : ""}
                    >
                      {/* <span className="sortPrice">Column</span> */}
                      <span className="ezyIcon icon-box"></span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
}
export default Sort;
