/**
 * @description: Flight Confirmation page Root component
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
 import React, {useState } from 'react';
 import { isNotNull } from '../../../commonUtils/validators';
 import FlightSectors from '../../../bookingFlows/flight/common/FlightSectors';
 import TFFareDetails from './TFFareDetails.jsx';
 import PassengerDetails from './PassengerDetails.jsx';

 /**
  * @description: This function will show the Confirmation page
  * @date:09-01-2020
  * @author:Rambabu
  *
  */
 function TFConfirmation(props) {
   const [confirmationObj , setConfirmationObj] = useState(props.confirmationObj)

   return (

     <>
       {isNotNull(confirmationObj) && (
         <>
             {/* Segment details section */}
             <div className="resultSection bg-white boxShadow mb-4 pb-0">
               <h5 className="title">Booking Details</h5>
               {confirmationObj.origns.map((sector, idx) => (
                 <div>
                    {sector['srcorigns'][0].ref===1 &&
                     <div className='newlayoutDesign tfcConfirm'>
                    {sector['srcorigns'] &&
                     <FlightSectors origin={[sector['srcorigns'][0], 'Confirm']}
                       mapping={confirmationObj.mappings}
                       tripType={confirmationObj.tt}
                       originsList={[sector['srcorigns'], 'Confirm']} confirmation={true}/>
                    }
                    <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                    <h5 className="title">Passenger Details</h5>
                    <PassengerDetails sector={"O"} paxdata={sector['srcorigns'][0]} sty={confirmationObj.sty} flowType={confirmationObj.bktype} tf_flow={confirmationObj.tf_flow} bkd={confirmationObj.bkd} />
                    </div>
                    {props.type !== "pdf" &&
                    <div className="resultSection boxShadow bg-white PassengerfareDetails mt-0">
                    <h5 className="title">Fare Details({props.usrCurrency})</h5>
                    <TFFareDetails sector={"O"} faredet={sector['srcorigns'][0]} butype={confirmationObj.butype} sty={confirmationObj.sty} tf_flow={confirmationObj.tf_flow} otherCharge={confirmationObj.otcharge} onwardSup={props.confirmationObj.origns!==undefined && props.confirmationObj.origns[0].srcorigns[0].sup} returnSup={props.confirmationObj.origns!==undefined && props.confirmationObj.origns[0].destorigins!==undefined && props.confirmationObj.origns[0].destorigins[0].sup} currency = {confirmationObj.cur} gst={props.gst} ow_gst={props.ow_gst} rt_gst={props.rt_gst}/>
                    </div>
                    }
                    </div>
                     }
                     {sector['destorigins'][0].ref===2 &&
                     <div className='newlayoutDesign tfcConfirm'>
                    {sector['destorigins'] &&
                     <FlightSectors origin={[sector['destorigins'][0], 'Confirm']}
                     mapping={confirmationObj.mappings}
                     tripType={confirmationObj.tt}
                       originsList={[sector['destorigins'], 'Confirm']} confirmation={true}/>
                    }
                    <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                    <h5 className="title">Passenger Details</h5>
                    <PassengerDetails sector={"R"} paxdata={sector['destorigins'][0]} sty={confirmationObj.sty} flowType={confirmationObj.bktype} tf_flow={confirmationObj.tf_flow} bkd={confirmationObj.bkd} />
                    </div>
                    {props.type !== "pdf" &&
                    <div className="resultSection boxShadow bg-white PassengerDetails mb-4 mt-0">
                    <h5 className="title">Fare Details({props.usrCurrency})</h5>
                    <TFFareDetails sector={"R"} faredet={sector['destorigins'][0]} butype={confirmationObj.butype} sty={confirmationObj.sty} tf_flow={confirmationObj.tf_flow} otherCharge={confirmationObj.otcharge} onwardSup={props.confirmationObj.origns!==undefined && props.confirmationObj.origns[0].srcorigns[0].sup} returnSup={props.confirmationObj.origns!==undefined && props.confirmationObj.origns[0].destorigins[0].sup} currency = {confirmationObj.cur} gst={props.gst} ow_gst={props.ow_gst} rt_gst={props.rt_gst}/>
                    </div>
                    }
                    </div>
                     }
                    </div>
               ))}

             </div>
          </>
       )}
     </>
   )
 }
 export default TFConfirmation