import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button'

/**
 * @description:This function will work for Payment Failed pop Up.
 * @author: Pravas
 * @param {*}
 * @function NoRecord
 * @date : 01-09-2020
 */
function NoRecord(props) {
  const { state } = props || {}
  const gotoResults=()=>{
    return window.location="/ezytrip/dashboard";
  }
  return (
    <>

            <div className="norecord">
              <strong>No Record Found.</strong>
             {props.title !==undefined ? <p>{props.title}</p> :  <>
              <p>You don't have any record history.</p>
              </>}
            </div>


    </>
  )
}
export default NoRecord