/**
 * @description: Flight Summary page Root component
 * @date:17-09-2020
 * @author:Rambabu
 *
 */
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import {  Form } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import useOutsideClick from '../../../common/OutsideClick';
import BookingDetails from '../BookingSummary/BookingDetails';
import TransactionSummary from '../BookingSummary/TransactionSummary';
import PassengerDetails from '../BookingSummary/PassengerDetails'
import PaymentDetails from '../BookingSummary/PaymentDetails'
import CustomFields from '../../summary/SummaryCustomFields'
import Tabs from '../../../common/tabs/Tabs';
import log from "loglevel";
import { Alert, Col, Modal, Row, Table } from "react-bootstrap";
import { getHtlSummaryData, processEditBooking, checkCancelPrice, processCancelService, validateBookingSummary, getHotelReviewDetails, processHoldCancelService } from './operations'
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import { Context } from "../../../../../App";
import Utilities from '../../../commonUtils/Utilities';
import HotelRefundDetails from './HotelRefundDetails';
import RefundPriceInfo from './RefundPriceInfo';
import HotelVoucherPdf from '../pdf/HotelVoucherPdf'
import { useReactToPrint } from "react-to-print";
import HotelInvoicePdf from '../pdf/HotelInvoicePdf';
import DateUtils from '../../../commonUtils/DateUtils';
import { emailValidation } from '../../../commonUtils/FormValidations';
import { sendHotelConfirmationObj } from '../../../bookingFlows/hotel/pages/operations';
export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
export const IS_LATEST = "IS_LATEST";
let hotelResp = require('../../../masterData/Hotel.json');


/**
 * Initial State Declaration
 */
const initialState = { htlresponse: "", enableEdit: false, updateBstatus: "", showEditpop: false, notiMessageShow: false, notiMessage: '', notiVarient: '', enableCancel: false, isprocess: false, showCancelPop: false, cancelPriceResp: [], cancelConfirm: false, processRequst: [], comp_id: 0, showVoucher: false, enablePay: false, checkCancel: false, totalPayAmount: 0, enableVoucher: false, enablePolicy: false, enableCancelHold: false, enableHoldBooking: false, enableTAInv: false, enableCust: false, remarks: "", rmError: false,validationFld:false,
message_array:[],refundmsgshow:false, refund_data_show:false,showPopup: false, email: "",enableManulRefund:false,enableOtillaPop:false };


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description: This function will show the Flight Summary page
 * @date:17-09-2020
 * @author:Pravas
 *
 */
function HotelSummaryPage(props) {


  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const ref = useRef();
  const [editMore, setEditMore] = useState(false);
  const [editMoreBD, setEditMoreBD] = useState(false);
  const [share, setShare,] = useState(false);
  const [editItinerary, setEditItinerary,] = useState(false);
  const [editPayment, setEditPayment,] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); dispatch({ type: 'incompletpopup', payload: false }, dispatch({ type: 'voidLoading', payload: false })) }
  const handleShow = () => setShow(true);
  const voidCncl = useRef(null)
  const executeScrolltop = () => voidCncl.current.scrollIntoView()
  const [context, setContext] = useContext(Context);
  const [cur, setCur] = useState(context.logindata.cur)
  const [mtaList, setMtaList] = useState([]);
  const [taList, setTaList] = useState([]);
  const [staList, setStaList] = useState([]);
  const [mtaPriceList, setPriceMtaList] = useState([]);
  const [taPriceList, setPriceTaList] = useState([]);
  const [staPriceList, sePricetStaList] = useState([]);
  const [viewType, setViewType] = useState("Ezytrip");
  const [refundType, setRefundType] = useState("ATT");
  const [remarks, setRemarks] = useState("")
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_VOUCHER_" + state.htlresponse.bkng_det.booking_id)
  }
  );
  const componentRefAP = useRef();
  const handlePrintAP = useReactToPrint({
    content: () => componentRefAP.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_VOUCHER_" + state.htlresponse.bkng_det.booking_id)
  }
  );

  const componentVoucherRefWAP = useRef();
  const handlePrintNcpWAP = useReactToPrint({
    content: () => componentVoucherRefWAP.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_VOUCHER_" + state.htlresponse.bkng_det.booking_id)
  }
  );
  const componentVoucherRef = useRef();
  const handlePrintNcp = useReactToPrint({
    content: () => componentVoucherRef.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_VOUCHER_" + state.htlresponse.bkng_det.booking_id)
  }
  );

  const componentRefInv = useRef();
  const handlePrintTa = useReactToPrint({
    content: () => componentRefInv.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_INVOICE_" + state.htlresponse.bkng_det.booking_id)
  }
  );
  const componentRefSTA = useRef();
  const handlePrintSta = useReactToPrint({
    content: () => componentRefSTA.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_INVOICE_" + state.htlresponse.bkng_det.booking_id)
  }
  );

  const componentRefCust = useRef();
  const handlePrintCust = useReactToPrint({
    content: () => componentRefCust.current,
    documentTitle: state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_id !== undefined && ("HOTEL_CUST_INV_" + state.htlresponse.bkng_det.booking_id)
  }
  );
  const handleChange = (e) => {
    setRemarks(e.target.value)
  }
  const clickInvoice = (type) => {
    if (type === "TA") {
      handlePrintTa();
    } else if (type === "STA") {
      handlePrintSta();
    } else if (type === "CUSTOMER") {
      handlePrintCust();
    }
  }

  const openMOre = () => {
    if (editMore) {
      setEditMore(false)
    } else {
      setEditMore(true)
    }
  }
  const openBookinDetailsMore = () =>{
   if (editMoreBD) {
     setEditMoreBD(false)
   } else {
     setEditMoreBD(true)
   }
  }
  const openshare = () => {
    if (share) {
      setShare(false)
    } else {
      setShare(true)
    }
  }
  const openeditItinerary = () => {
    if (editItinerary) {
      setEditItinerary(false)
    } else {
      setEditItinerary(true)
    }
  }
  const openeeditPayment = () => {
    if (editPayment) {
      setEditPayment(false)
    } else {
      setEditPayment(true)
    }
  }
  useOutsideClick(ref, () => {
    if (editMore) setEditMore(false);
    if (share) setShare(false);
    if (editItinerary) setEditItinerary(false);
    if (editPayment) setEditPayment(false);
  });

  const clickVoucher = () => {
    handlePrint();
  }
  const clickVoucherAP = () => {
    handlePrintAP();
  }


  const clickVoucherNcpWAP = () => {
    handlePrintNcpWAP();
  }
  const clickVoucherNcp = () => {
    handlePrintNcp();
  }
  useEffect(() => {
    //Call Hotel details
    let booking_ref = '';
    //fetch data from window pathname && window.location.href
    if (isNotNull(window.location.pathname)) {
      let url_array = window.location.pathname.split("/");
      booking_ref = url_array[url_array.length - 1];
    } else {
      let url_array1 = window.location.href.split("/");
      booking_ref = url_array1[url_array1.length - 1]
    }

    if (booking_ref !== undefined) {
      if (context.logindata !== undefined && context.logindata !== "") {
        const loginUserData = JSON.parse(localStorage.getItem('authData'));
        let rq_json = {
          "cid": loginUserData.cid,
          "uid": context.logindata.uid,
          "is_admin": context.logindata.is_admin,
          "bid": booking_ref
        }
        //validation of booking fetch
        validateBookingSummary(rq_json).then((result) => {
          if (result.data.status) {


            try {
              getHtlSummaryData(booking_ref).then((response) => {
                if (response.suc) {
                  dispatch({ type: 'htlresponse', payload: response.res })
                  localStorage.setItem("SUMMARY_COMP_ID", response.res.bkng_det.company_id)
                  dispatch({ type: 'comp_id', payload: response.res.bkng_det.company_id })
                  if (response.res.refund_details !== undefined) {
                    response.res.htldtls.free_cancel_date = moment(response.res.htldtls.free_cancel_date, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm')
                    let type = response.res.bkng_det.buser_type === "ta_user" ? "TA" : "STA";
                    let fareList = response.res.fare_det.filter((obj) => obj.type === type);
                    let mtaList = response.res.fare_det.filter((obj) => obj.type === "MTA");
                    setMtaList(response.res.refund_details.filter((obj) => obj.type === "MTA"));
                    setTaList(response.res.refund_details.filter((obj) => obj.type === "TA"));
                    setStaList(response.res.refund_details.filter((obj) => obj.type === "STA"));
                    let total = 0;
                    for (let room of fareList[0].rooms) {
                      if (room.rst === "On hold" || room.rst === "On Hold"){
                        dispatch({ type: 'checkCancel', payload: true });
                        if (total === 0) {
                          total = room.rmpr.purpr.tf;
                        } else {
                          total = total + room.rmpr.purpr.tf;
                        }
                      }
                    }
                    dispatch({ type: 'totalPayAmount', payload: total });
                  }
                } else {
                  dispatch({ type: 'showPop', payload: true });
                }
              });
            } catch (e) {
              log.error('Exception occured in handleChange function---' + e);
            }
          }
        }).catch(function (error) {

        })
      }
    }
    window.scrollTo(0, 0)
  }, [context.logindata])

  const handleCancel = (e) => {
    return history.push("/ezytrip/dashboard/bookingList");
  }
  const handleEdiyBooking = () => {
    dispatch({ type: 'enableEdit', payload: true })
    dispatch({ type: 'enableCancel', payload: false })
  }

  const handleCancelBooking = () => {
     if(state.htlresponse.bkng_det.supplier_id==0){
      dispatch({ type: 'enableCancel', payload: true })
      dispatch({ type: 'enableEdit', payload: false })
      dispatch({ type: 'enableManulRefund', payload: false })
     }else{
      dispatch({ type: 'enableOtillaPop', payload: true }) 
     }
     
  }

  const handleRefundBooking = () => {
   dispatch({ type: 'enableManulRefund', payload: true })
   dispatch({ type: 'enableEdit', payload: false })
   dispatch({ type: 'enableCancel', payload: true })
 }

  const handlePayBooking = () => {
    dispatch({ type: 'enablePay', payload: true })
    dispatch({ type: 'enableEdit', payload: false })
    dispatch({ type: 'enableCancel', payload: false })
    dispatch({ type: 'checkCancel', payload: false })
  }


  const updateBstatus = (data) => {
    dispatch({ type: 'updateBstatus', payload: data })
  }

  const callEditBooking = () => {
    dispatch({ type: 'showEditpop', payload: true })
  }

  const cancelEdit = () => {
    dispatch({ type: 'showEditpop', payload: false })
    return window.location = window.location.pathname;
  }

  const oncloseEidt = () => {
    dispatch({ type: 'showEditpop', payload: false })
  }

  const oncloseCancelConfirm = () => {
    dispatch({ type: 'cancelConfirm', payload: false })
  }

  const oncloseCancel = () => {
    dispatch({ type: 'showCancelPop', payload: false })
  }

  const otillaCancelConfirm = () => {
   dispatch({ type: 'enableOtillaPop', payload: false })
 }

  const closeCancel = () => {
    dispatch({ type: 'enableCancel', payload: false })
    dispatch({ type: 'checkCancel', payload: true })
    dispatch({ type: 'enablePay', payload: false })
    let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA")
    for (let room of mtaList[0].rooms) {
      room.selected = false;
    }
  }

  const backCancel = () => {
   dispatch({ type: 'enableCancel', payload: false })
   dispatch({ type: 'enableManulRefund', payload: false })
   dispatch({ type: 'enablePay', payload: false })
   let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA")
   for (let room of mtaList[0].rooms) {
     room.selected = false;
   }
   window.location= window.location = window.location.pathname;
 }

  const validateRoom = (room) => {
    if (room.htlrefno.includes('-') && state.updateBstatus === "Confirmed") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Hotel Reference number' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    return true;
  }

  const callPayRequest = () => {
    let selRooms = [];
    let selected = false;
    let mtaList = [];
    if (context.logindata.utype === "att_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    } else if (context.logindata.utype === "ta_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    } else if (context.logindata.utype === "sta_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    }
    for (let val of mtaList[0].rooms) {
      if (val.selected) {
        const req = {
          "rmid": val.rmid,
          "rmindex": val.room_no
        }
        selRooms.push(req);
        selected = true;
      }
    }
    if (selected) {
      dispatch({ type: 'isprocess', payload: true })
      const payload = {
        "booking_id": state.htlresponse.bkng_det.booking_id,
        "hid": state.htlresponse.bkng_det.hid,
        "roomInfo": selRooms,
        "cur": state.htlresponse.bkng_det.usr_curr_code,
        "region_type":state.htlresponse.bkng_det.region_type
      }
      getHotelReviewDetails(payload).then((response) => {
        if (response.suc) {
          response.res[0].bktype = "ONHOLD";
          response.res[0].booking_id = state.htlresponse.bkng_det.booking_id;
          response.res[0].cur = state.htlresponse.bkng_det.usr_curr_code;
          response.res[0].region_type = state.htlresponse.bkng_det.region_type;
          history.push({
            pathname: '/ezytrip/bookings/hotel/guestDetails',
            state: {
              req: response.res[0]
            }
          });
          dispatch({ type: 'isprocess', payload: false })
        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispatch({ type: 'isprocess', payload: false })
          hidemessage();
        }
      });
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one room and proceed for the Payment' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }




  }

  const createRoomPrice=(room,type)=>{
    let total=Number(room.rmpr.salespr.bf)+Number(room.rmpr.salespr.tax)+Number(room.rmpr.salespr.markup)+Number(room.rmpr.salespr.servicefee)+Number(room.rmpr.salespr.onflymkp);
    if(room.rmpr.salespr.mtadisc!==undefined && room.rmpr.salespr.mtadisc!==0){
     total = total - Number(room.rmpr.salespr.mtadisc)
    }
    if(room.rmpr.salespr.tadisc!==undefined && room.rmpr.salespr.tadisc!==0){
     total = total - Number(room.rmpr.salespr.tadisc)
    }
    if(room.rmpr.salespr.com_disc!==undefined && room.rmpr.salespr.com_disc!==0){
     total = total - Number(room.rmpr.salespr.com_disc)
    }
   //  if(room.rmpr.salespr.bkservice!==undefined && room.rmpr.salespr.bkservice!==0){
   //   total = total - Number(room.rmpr.salespr.bkservice)
   //  }//SUP Fee we are not refunding
    if(type==="TA" && room.rmpr.salespr.sup_fee!==undefined && room.rmpr.salespr.sup_fee!==0){
     total = total - Number(room.rmpr.salespr.sup_fee);
    }
    let refundFromSup=0;
    if(type==="ATT"){
     refundFromSup=Number(room.rmpr.salespr.bf)+Number(room.rmpr.salespr.tax);
    }else{
     refundFromSup=Number(room.rmpr.salespr.bf)+Number(room.rmpr.salespr.tax) + Number(room.rmpr.salespr.servicefee);

    }
    const req={
     "type":type,
     "bf": room.rmpr.salespr.bf,
     "taxes": room.rmpr.salespr.tax,
     "bkmarkup": room.rmpr.salespr.markup,
     "bkservice": room.rmpr.salespr.servicefee,
     "supfee": room.rmpr.salespr.sup_fee!==undefined && room.rmpr.salespr.sup_fee!==0?room.rmpr.salespr.sup_fee:0,
     "canservice": 0,
     "canmarkup": 0,
     "refundprice": total,
     "reffrmsup": refundFromSup,
     "roomprice": total,
     "supcancharges": 0,
     "cancharges": 0,
     "salestaxes": room.rmpr.salespr.sales_tax,
     "onflymkp": room.rmpr.salespr.onflymkp!==undefined && room.rmpr.salespr.onflymkp!==0?room.rmpr.salespr.onflymkp:0,
     "canmarkupdisc": 0,
     "com_disc" :room.rmpr.salespr.com_disc!==undefined && room.rmpr.salespr.com_disc!==0? room.rmpr.salespr.com_disc:0,
     "sup_disc": 0,
     "mtadisc" : room.rmpr.salespr.mtadisc!==undefined && room.rmpr.salespr.mtadisc!==0?room.rmpr.salespr.mtadisc:0,
     "tadisc": room.rmpr.salespr.tadisc!==undefined && room.rmpr.salespr.tadisc!==0?room.rmpr.salespr.tadisc:0,
     "reffrmusr": 0,
     "expediacharges": 0,
     "cur": state.htlresponse.bkng_det.usr_curr_code,
     "region_type":state.htlresponse.bkng_det.region_type
   }
   return req;
  }
  //Creating the Refund Rooms List
  const createRefundRoomsList=()=>{
    let roomList=[];
    for(let fare of state.htlresponse.fare_det){
       if(fare.type==="MTA"){
         let selRooms=fare.rooms.filter((room)=>room.selected);
          for(let room of selRooms){
           let taList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
           let taRoomsList = taList[0].rooms.filter((obj) => Number(obj.rmid)===Number(room.rmid));
           var obj={
             "rmid": room.rmid,
             "rnm": room.rnm,
             "rno": room.room_no,
             "adt": room.adt,
             "chd": room.chd,
             "htl_ref_num": room.htlrefno,
             "htlconfno":room.htlconfno,
             "rst": "Cancelled",
             "mtaprice":createRoomPrice(room,"ATT"),
             "taprice":createRoomPrice(taRoomsList[0],"TA"),
             "cur": state.htlresponse.bkng_det.usr_curr_code,
             "region_type":state.htlresponse.bkng_det.region_type
           }
           roomList.push(obj);
       }
    }
   }
    let status = true;
    let st = "";
    let isRoomCancel = false;
    let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    for (let val of mtaList[0].rooms){
      if (val.rst === "Confirmed" && !val.selected) {
        status = false;
        break;;
      } else if (val.rst === "Cancelled") {
        isRoomCancel = true;
      }
    }
    if (status === true) {
      st = "Cancelled";
    } else {
      st = "Partially Confirmed";
    }
   const request={
     "st": st,
     "isRoomCancel": isRoomCancel,
     "upby": 1,
     "butype":context.logindata.butype,
     "cid":context.logindata.cid,
     "pcid":context.logindata.pcid,
     "booking_id":state.htlresponse.bkng_det.booking_id,
     "hid": state.htlresponse.bkng_det.hid,
     "refundprice":roomList,
     "cur": state.htlresponse.bkng_det.usr_curr_code,
     "region_type":state.htlresponse.bkng_det.region_type
   }
   history.push({
     pathname: '/ezytrip/hotel/bookingsummary/manual-refund',
     state: {
       req: request
     }
   });

  }

  //Calling service to get the Refund prices
  const callCancelPrice = () => {
    let selRooms = [];
    let selected = false;
    let mtaList = [];
    let nonrefundable = false;
    let roomNo = "";
    if (context.logindata.utype === "att_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    } else if (context.logindata.utype === "ta_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    } else if (context.logindata.utype === "sta_user") {
      mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    }
    for (let val of mtaList[0].rooms) {
      if(val.rmpr.canpol=="non refundable" && state.htlresponse.bkng_det.supplier_id==1) {
        nonrefundable=true
        roomNo=val.room_no
      }
      if (val.selected) {
        const req = {
          "rmid": val.rmid,
          "htlconfno": val.htlconfno,
          "htlrefno": val.htlrefno,
          "room_number": val.room_no
        }
        selRooms.push(req);
        selected = true;
      }
    }
    if(!nonrefundable){
    if (selected) {
      if(state.enableCancel && !state.enableManulRefund){
      dispatch({ type: 'isprocess', payload: true })
      const payload = {
        "sup":state.htlresponse.bkng_det.supplier_id,
        "booking_id": state.htlresponse.bkng_det.booking_id,
        "hid": state.htlresponse.bkng_det.hid,
        "roomInfo": selRooms,
        "upby": context.logindata.uid,
        "butype": context.logindata.butype,
        "cid": context.logindata.cid,
        "pcid": context.logindata.pcid,
        "cur": state.htlresponse.bkng_det.usr_curr_code,
        "region_type":state.htlresponse.bkng_det.region_type
      }
      checkCancelPrice(payload).then((response) => {
        if (response.suc && response.data.refundprice !== undefined) {
          var userType = (context.logindata.butype === "ta_user") ? "TA" : "STA";
          let priceList = response.data.refundprice.filter((obj) => obj.type === userType);

          //Validation Room wise st and msg tag values for displyaing--start
          let mta_data = response.data.refundprice.filter((obj) => obj.type === "MTA");
          let msg_arry = [];
          let roommsg_arry = [];
          if (isArrayNotEmpty(mta_data)) {
            mta_data[0].rooms.map((rmo) => {
              if (isNotNull(rmo.st) && !rmo.st) {
                let room_no = 'Room - ' + rmo.rno;
                let msg = rmo.message;
                let rm_obj = { label: room_no, value: msg }
                msg_arry.push(rm_obj);
                roommsg_arry.push(room_no);
              }
            })
          }
          if (isArrayNotEmpty(msg_arry)) {
            let cmmsg = 'Unable to process the cancelation of ' + roommsg_arry.toString() + ', please contact our customer support team. Dial: +974 44484848 ';
            let cm_obj = { label: '', value: cmmsg }
            msg_arry.push(cm_obj);
            dispatch({ type: 'message_array', payload: msg_arry });
            dispatch({ type: 'refundmsgshow', payload: true });
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage();
          }
          let mta_data1 = response.data.refundprice.filter((obj) => obj.type === "MTA");
          mta_data1 = isArrayNotEmpty(mta_data1) ? mta_data1[0].rooms.filter(obj => obj.st) : [];
          let ta_data2 = response.data.refundprice.filter((obj) => obj.type === "TA");
          ta_data2 = isArrayNotEmpty(ta_data2) ? ta_data2[0].rooms.filter(obj => obj.st) : [];
          let sta_data3 = response.data.refundprice.filter((obj) => obj.type === "STA");
          sta_data3 = isArrayNotEmpty(sta_data3) ? sta_data3[0].rooms.filter(obj => obj.st) : [];
          if (isArrayNotEmpty(mta_data1) || isArrayNotEmpty(ta_data2) || isArrayNotEmpty(sta_data3)) {
            dispatch({ type: 'refund_data_show', payload: true });
          }
          //Validation Room wise st and msg tag values for displyaing--End

          setPriceMtaList(response.data.refundprice.filter((obj) => obj.type === "MTA"));
          setPriceTaList(response.data.refundprice.filter((obj) => obj.type === "TA"));
          sePricetStaList(response.data.refundprice.filter((obj) => obj.type === "STA"));
          dispatch({ type: 'cancelPriceResp', payload: priceList })
          dispatch({ type: 'processRequst', payload: response.data.refundprice });
          dispatch({ type: 'showCancelPop', payload: true });
          dispatch({ type: 'isprocess', payload: false })
        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Unable to process the cancelation, please contact our customer support team. Dial: +974 44484848 ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          dispatch({ type: 'isprocess', payload: false })
          hidemessage();
        }
      });
      }else if(state.enableCancel && state.enableManulRefund){ //For Manual Refund
       createRefundRoomsList();
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one room and proceed for the Cancellation' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  } else{
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: `Room${roomNo} is non refundable` })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
  }
  }

  //Calling for Process Cancellation
  const processCancellation = () => {
    dispatch({ type: 'isprocess', payload: true })
    let roomsList = [];
    if (context.logindata.utype === "att_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    } else if (context.logindata.utype === "ta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    } else if (context.logindata.utype === "sta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    }
    let status = true;
    let st = "";
    let isRoomCancel = false;
    for (let val of roomsList[0].rooms) {
      if (val.rst === "Confirmed" && !val.selected) {
        status = false;
        break;;
      } else if (val.rst === "Cancelled") {
        isRoomCancel = true;
      }
    }
    if (status === true) {
      st = "Cancelled";
    } else {
      st = "Partial Confirmed";
    }
    const payload = {
      "st": st,
      "isRoomCancel": isRoomCancel,
      "upby": context.logindata.uid,
      "booking_id": state.htlresponse.bkng_det.booking_id,
      "hid": state.htlresponse.bkng_det.hid,
      "refundprice": state.processRequst,
      "cancel_remarks": remarks,
      "cur": state.htlresponse.bkng_det.usr_curr_code,
      "region_type":state.htlresponse.bkng_det.region_type,
      "sup":state.htlresponse.bkng_det.supplier_id,
    }
    processCancelService(payload).then((response) => {
      if (response.suc) {

        //Validation Room wise st and msg tag values for displyaing--start
        let mta_data = response.data.refundprice.filter((obj) => obj.type === "MTA");
        let msg_arry = [];
        let roommsg_arry = [];
        if (isArrayNotEmpty(mta_data)) {
          mta_data[0].rooms.map((rmo) => {
            if (isNotNull(rmo.st) && rmo.st.toUpperCase()==='FAILED') {
              let room_no = 'Room - ' + rmo.rno;
              let msg = rmo.message;
              let rm_obj = { label: room_no, value: msg }
              msg_arry.push(rm_obj);
              roommsg_arry.push(room_no);
            }
          })
        }
        if (isArrayNotEmpty(msg_arry)) {
          let cmmsg = 'Unable to process the cancelation of ' + roommsg_arry.toString() + ', please contact our customer support team. Dial: +974 44484848 ';
          let cm_obj = { label: '', value: cmmsg }
          msg_arry.push(cm_obj);
          dispatch({ type: 'message_array', payload: msg_arry });
          dispatch({ type: 'refundmsgshow', payload: true });
          dispatch({ type: 'notiVarient', payload: 'danger' });
          dispatch({ type: 'isprocess', payload: false })
          dispatch({ type: 'cancelConfirm', payload: false })
          //hidemessage();
          setTimeout(() => {
            return window.location = window.location.pathname;
          }, 5000)
        } //Validation Room wise st and msg tag values for displyaing--end
        else {
          dispatch({ type: 'isprocess', payload: false })
          dispatch({ type: 'cancelConfirm', payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Cancellation processed Successfully' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          setTimeout(() => {
            return window.location = window.location.pathname;
          }, 2000)
        }
      } else {
        dispatch({ type: 'isprocess', payload: false })
        dispatch({ type: 'cancelConfirm', payload: false })
        dispatch({ type: 'showCancelPop', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went wrong. We are unable to process the Cancellation' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
      }
    });
  }

  const cancelProcessPopUp = () => {
    if (remarks == "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter cancellation remark' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispatch({ type: 'validationFld', payload: true })
      hidemessage();
    }
    else {
      dispatch({ type: 'cancelConfirm', payload: true });
      dispatch({ type: 'validationFld', payload: false })
      hidemessage();
    }
  }


  //Processing the Edit Booking
  const processEditBookingFlow = () => {
    let roomList = [];
    let validate = false;
    if (state.updateBstatus !== "") {
      if (state.htlresponse.fare_det.length !== 0) {
        let roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
        if (roomsList.length !== 0) {
          for (let room of roomsList[0].rooms) {
            //if(validateRoom(room)){
            if (room.changed !== undefined && room.changed) {
              const payload = {
                "roomId": room.hotel_room_id,
                "rst": room.rst,
                "htlrefno": room.htlrefno
              }
              roomList.push(payload);
              validate = true;
            }
            //}
          }
        }
      }
      if (validate) {
        const payload = {
          "booking_id": state.htlresponse.bkng_det.booking_id,
          "bst": state.updateBstatus,
          "upby": context.logindata.uid,
          "bkngdetails": roomList
        }
        processEditBooking(payload).then((resp) => {
          dispatch({ type: 'showEditpop', payload: false })
          if (resp.suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Booking details are updated Successfully' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            setTimeout(() => {
              return window.location = window.location.pathname;
            }, 2000)
          } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Something went wrong.Booking details are not updated' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
          }
        })
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

  const calculateRefundPrice = (price) => {
    const total = price.reduce((prev, t) => {
      return Utilities.addPrice(prev, t.refundprice, "Add");
    }, 0);
    return total;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
      dispatch({ type: 'message_array', payload: [] })
      dispatch({ type: 'refundmsgshow', payload: false })
      //dispatch({ type: 'refund_data_show', payload: false })
    }, 5000);
  }

  const showNonRefundable = (room) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: "Room" + room.room_no + ":" + room.rnm + ' is Non Refundable.' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
  }

  const closeCancelHold = () => {
    dispatch({ type: 'enableCancelHold', payload: false })
    dispatch({ type: 'enableCancel', payload: false })
    dispatch({ type: 'checkCancel', payload: true })
    dispatch({ type: 'enablePay', payload: false })

    let mtaList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA")
    for (let room of mtaList[0].rooms) {
      room.selected = false;
    }
  }

  const handleCancelHoldBooking = () => {
    dispatch({ type: 'enableCancelHold', payload: true })
    dispatch({ type: 'enablePay', payload: false })
    dispatch({ type: 'enableEdit', payload: false })
    dispatch({ type: 'enableCancel', payload: false })
    dispatch({ type: 'checkCancel', payload: false })
  }

  const cancelHoldBookingRoom = () => {
    let roomsList = [];
    let selected = false;
    if (context.logindata.utype === "att_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    } else if (context.logindata.utype === "ta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    } else if (context.logindata.utype === "sta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    }
    for (let val of roomsList[0].rooms) {
      if (val.rst === "On hold" && val.selected) {
        selected = true;
        break;
      }
    }
    if (selected) {
      dispatch({ type: 'cancelConfirm', payload: true })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one room and proceed for the Cancellation' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
    }
  }

   //Cancellation Both Confirm and Onhold
   const processOtillaBookingCancel = () => {
     dispatch({ type: 'enableCancel', payload: true })
     dispatch({ type: 'enableEdit', payload: false })
     dispatch({ type: 'enableManulRefund', payload: false })
     dispatch({ type: 'enableOtillaPop', payload: false })
   }
  //Cancellation Both Confirm and Onhold
  const processBookingCancel = () => {
    dispatch({ type: 'message_array', payload: [] });
    dispatch({ type: 'refundmsgshow', payload: false });
    dispatch({ type: 'refund_data_show', payload: false })
    dispatch({ type: 'notiVarient', payload: '' })
    if (state.enableCancel) {
      processCancellation();
    } else if (state.enableCancelHold) {
      processHoldCancellation()
    }
  }

  //Calling for Process Hold Cancellation
  const processHoldCancellation = () => {
    let roomsList = [];
    if (context.logindata.utype === "att_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    } else if (context.logindata.utype === "ta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "TA");
    } else if (context.logindata.utype === "sta_user") {
      roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "STA");
    }
    let rooms = [];
    for (let val of roomsList[0].rooms) {
      if (val.rst === "On hold" && val.selected) {
        rooms.push(val.room_no)
      }
    }

    dispatch({ type: 'isprocess', payload: true })
    const payload = {
      "upby": context.logindata.uid,
      "booking_id": state.htlresponse.bkng_det.booking_id,
      "hid": state.htlresponse.bkng_det.hid,
      "booking_status": "22",
      "free_cancel_date": state.htlresponse.htldtls.free_cancel_date,
      "roomIds": rooms,
      "cur": state.htlresponse.bkng_det.usr_curr_code,
      "region_type":state.htlresponse.bkng_det.region_type
    }
    processHoldCancelService(payload).then((response) => {
      if (response.suc) {
        dispatch({ type: 'isprocess', payload: false })
        dispatch({ type: 'cancelConfirm', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Selected Room(s) Cancelled Successfully' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        setTimeout(() => {
          return window.location = window.location.pathname;
        }, 2000)
      } else {
        dispatch({ type: 'isprocess', payload: false })
        dispatch({ type: 'cancelConfirm', payload: false })
        dispatch({ type: 'showCancelPop', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went wrong. We are unable to process the Cancellation' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
      }
    });
  }


  const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }

  useEffect(() => {
    if (context.logindata.utype !== undefined) {
      if (context.logindata.utype === "att_user") {
        setViewType("Ezytrip")
        setRefundType("ATT");
      } else if (context.logindata.utype === "ta_user") {
        setViewType("TravelAgent")
        setRefundType("TA");
      } else if (context.logindata.utype === "sta_user") {
        setViewType("SubTravelAgent")
        setRefundType("STA");
      }
    }
  }, [context.logindata.utype]);

  //Updating the Selected Room
  const checkSelRoom = (obj) => {
    let roomsList = state.htlresponse.fare_det.filter((obj) => obj.type === "MTA");
    if (roomsList.length !== 0) {
      for (let room of roomsList[0].rooms) {
        if (room.hotel_room_id === obj.hotel_room_id) {
          room.changed = true;
          room.rst = obj.rst;
          room.stid = obj.stid;
          room.htlrefno = obj.htlrefno;
        }
      }
    }
  }


  const [tablist, setTablist] = useState({
    active: viewType

  });

  const content = {
    Ezytrip: <PassengerDetails bookingType={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.bktype} fareDetails={state.htlresponse.fare_det} type={"MTA"} enableEdit={state.enableEdit} enabelCancel={state.enableCancel} userType={context.logindata.utype} sendRoomInfo={showNonRefundable} sendSelRoom={checkSelRoom} enablePay={state.enablePay} enableCancelHold={state.enableCancelHold} currency={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    TravelAgent: <PassengerDetails bookingType={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.bktype} fareDetails={state.htlresponse.fare_det} type={"TA"} enableEdit={state.enableEdit} enabelCancel={state.enableCancel} userType={context.logindata.utype} sendRoomInfo={showNonRefundable} sendSelRoom={checkSelRoom} enablePay={state.enablePay} enableCancelHold={state.enableCancelHold} currency={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    SubTravelAgent: <PassengerDetails bookingType={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.bktype} fareDetails={state.htlresponse.fare_det} type={"STA"} enableEdit={state.enableEdit} enabelCancel={state.enableCancel} userType={context.logindata.utype} sendRoomInfo={showNonRefundable} sendSelRoom={checkSelRoom} enablePay={state.enablePay} enableCancelHold={state.enableCancelHold} currency={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
  };

  const [refundTab, setRefundTab] = useState(context.logindata.utype == "att_user" ? {
    active: "ATT"
  } : {active: "TA"})

  const refund = {
    ATT: <HotelRefundDetails refundData={mtaList} type={"ATT"} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    TA: <HotelRefundDetails refundData={taList} type={"TA"} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    STA: <HotelRefundDetails refundData={staList} type={"STA"} supplier={state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id}/>
  };

  const [refPriceTab, setRefPriceTab] = useState({
    active: "ATT",
  });
  const setRemark = (data) => {
    dispatch({ type: "remarks", payload: data })
  }
  const refundPrice = {
    ATT: <RefundPriceInfo priceData={mtaPriceList}
      remarks={setRemark}
      type={"ATT"} currency={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    TA: <RefundPriceInfo priceData={taPriceList} type={"TA"} currency={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id!==undefined && state.htlresponse.bkng_det.supplier_id}/>,
    STA: <RefundPriceInfo priceData={staPriceList} type={"STA"} currency={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.usr_curr_code!==undefined && state.htlresponse.bkng_det.usr_curr_code} supplier={state.htlresponse!==undefined && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.supplier_id!==undefined && state.htlresponse.bkng_det.supplier_id}/>
  };


  const openshareevent = ()=>{
   dispatch({ type: 'showPopup', payload: true })
  }
  const handleemailClose = ()=>{
   dispatch({ type: 'showPopup', payload: false })
   dispatch({ type: 'email', payload: "" })
  }
  const handleChangeemail = (e) => {
   e.preventDefault();
   let trimmedEmail = e.target.value.trim();
   dispatch({ type: 'email', payload: trimmedEmail })
    }
  const shareItinerary = (data) =>{
   sendHotelConfirmationObj(data).then(response=>{
     if(response.suc){
       dispatch({ type: 'email', payload: "" })
       dispatch({ type: 'showPopup', payload: false })
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: response.res })
       dispatch({ type: 'notiVarient', payload: 'success' })
       hidemessage();
     }
     else{
       dispatch({ type: 'showPopup', payload: false })
       dispatch({ type: 'email', payload: "" })
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: "Unable to send Email Please try again later" })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     }
    }).catch(function (error) {
     dispatch({ type: 'showPopup', payload: false })
     dispatch({ type: 'email', payload: "" })
     log.error(
       "Exception occured in getCompanyList function---" + error
     );
   });
  }
  const sendEmail = ()=>{
   if(validateEmail()){
     const payload = {
         "bookingid" : state.htlresponse.bkng_det.booking_id,
         "email" : state.email
      }
      shareItinerary(payload)
   }
   return (
     <>
       {state.comp_id !== 0 &&
         <Header />
       }
       <div className="container confirmation hotelbookingSummery">
         {state.notiMessageShow &&
           <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
         }

         {/* Displaying Messages for Refund Pricing room wise */}
         {isArrayNotEmpty(state.message_array) &&
           <Alert className="notification" variant={state.notiVarient} show={state.refundmsgshow}
             onClose={() => dispatch({ type: "refundmsgshow", payload: false })}
             dismissible>{state.message_array.map((item, index) => (
               <span key={index}>{item.label} {item.value}<br/></span>
             ))}</Alert>
         }

         <div className="summarytopSec">
         {window.sessionStorage.getItem(IS_LATEST)!==null &&
           <Button
             variant="link"
            className="backbtn ezyIcon icon-rightArrow"
             onClick={handleCancel}
          >Back</Button>
         }
           <div className="printSec">
             {/* <Button
                   size="xs"
                   variant="primary"
                   className="mr-3"
                 >Add New Booking</Button> */}
                {state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&<div className='socialIcons'>
            <Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link">Print Summary
             </Button>
             </div>}
            {/* <span className="moreDetails share onclickTag ezyIcon icon-share" ref={ref} onClick={openshare}>
               <div className={"showDetails " + (share ? 'active' : 'inactive')}>
                 <ul className="d-flex flex-column">
                       <li>
                         <Link to="#">Itinerary</Link>
                       </li>
                       <li>
                         <Link to="#">Invoice</Link>
                       </li>
                       <li>
                         <Link to="#">E-Ticket</Link>
                       </li>

                     </ul>
               </div>
             </span> */}
           </div>
         </div>
         {/* {state.htlresponse.fare_det !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.htldtls !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_C_B_S') && (state.htlresponse.bkng_det.booking_status === "23") &&
                <>
                {context.logindata.butype === "ta_user" &&
                 <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
                 <HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} />
                 </PDFViewer>
               }
               </>
         } */}
         <div className="resultSection boxShadow bg-white summary mb-4">
           <h5 className="title d-flex justify-content-between">Booking Details
                    {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openBookinDetailsMore}>

                     <div className={"showDetails " + (editMoreBD ? 'active' : 'inactive')}>
                       <ul className="d-flex flex-column">
                          <li>
                           <Link to="#" >Void Booking</Link>
                         </li>
                         <li>
                           <Link to="#">Void/Cancel</Link>
                         </li>
                         <li>
                             <Link to="#">Refund Ticket</Link>
                           </li>

                         <li>
                             <Link to="#">Reissue Ticket</Link>
                           </li> 

            </ul>
                     </div>  </span>  */}
           </h5>

           {/* Booking Details Section */}
           {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
             <TransactionSummary bkngdetails={state.htlresponse.bkng_det} enableEdit={state.enableEdit} sendBookStatus={updateBstatus} />
           }
         </div>



         <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
          <h5 className="title d-flex justify-content-between">Hotel Details
          <div className='d-flex'>
          {state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
          <div className='socialIcons'>
                           <Button className="prient ezyIcon icon-share" onClick={openshareevent} variant="link"></Button>
                </div>}
                {state.htlresponse.bkng_det!==undefined&& state.htlresponse.bkng_det.booking_status   && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openMOre}>

             <div className={"showDetails " + (editMore ? 'active' : 'inactive')}>
               <ul className="d-flex flex-column">
                 {context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_E_B_S') && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.booking_status !== "28" &&
                   <li>
                     <Link to="#" onClick={handleEdiyBooking} >Edit Booking</Link>
                   </li>
                 }
                 {context.logindata !== undefined && state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                   <li>
                     <Link to="#" onClick={() => clickVoucher()} >Voucher with CP</Link>
                     <Link to="#" onClick={() => clickVoucherNcp()} >Voucher without CP </Link>
                     <Link to="#" onClick={() => clickVoucherAP()} >Voucher with CP all pax</Link>
                     <Link to="#" onClick={() => clickVoucherNcpWAP()} >Voucher without CP all pax </Link>
                   </li>

                 }
                 {state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_status !== undefined && state.htlresponse.bkng_det.booking_status === "22" &&
                   <li>
                     <Link to="#" onClick={handleCancelHoldBooking} >Cancel Hold Booking</Link>
                   </li>
                 }
                 {state.htlresponse.bkng_det !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_C_B_S') && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                   <li>
                     <Link to="#" onClick={handleCancelBooking} >Cancel Booking</Link>
                   </li>
                 }
                 {state.htlresponse.bkng_det !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_B_S_R') && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                   <li>
                     <Link to="#" onClick={handleRefundBooking} >Manual Refund</Link>
                   </li>
                 }
                 {((state.htlresponse.fare_det !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.htldtls !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41")) ||state.htlresponse.bkng_det.booking_status === "28") &&
                   <>
                     <li>
                       {context.logindata.utype !== "sta_user" &&
                         <Link to="#" onClick={() => clickInvoice("TA")} >{state.htlresponse.bkng_det.booking_status === "28"?"TA Credit Note":"TA Invoice" }</Link>
                       }
                       {context.logindata.butype === "sta_user" &&
                         <Link to="#" onClick={() => clickInvoice("STA")} >STA Invoice </Link>
                       }
                       <Link to="#" onClick={() => clickInvoice("CUSTOMER")} >{state.htlresponse.bkng_det.booking_status === "28"?"Customer Credit Note":"Customer Invoice" } </Link>

                       {/* <PDFDownloadLink  fileName="voucher_with_cp.pdf" document={<HotelVoucher roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} cancelPolicy={true} utpye={context.logindata.butype} />} >
                         {({ blob, url, showVoucher, error }) =>
                           showVoucher ? 'Loading document...' : 'Voucher with CP'
                         }
                       </PDFDownloadLink> */}
                     </li>
                     {/* <li>
                       <PDFDownloadLink fileName="voucher_withOut_cp.pdf" document={<HotelVoucher roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} cancelPolicy={false} utpye={context.logindata.butype} />} >
                         {({ blob, url, showVoucher, error }) =>
                           showVoucher ? 'Loading document...' : 'Voucher without CP'
                         }
                       </PDFDownloadLink>
                     </li> */}
                     {/* {context.logindata.utype !== "sta_user" &&
                       <li>
                         <PDFDownloadLink fileName="ta_invoce.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"ta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"TA"} />} >
                           {({ blob, url, showVoucher, error }) =>
                             showVoucher ? 'Loading document...' : 'TA Invoice'
                           }
                         </PDFDownloadLink>
                       </li>
                     }
                     {context.logindata.butype === "sta_user" &&
                       <li>
                         <PDFDownloadLink fileName="sta_invoce.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"sta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"STA"} parent_id={context.logindata.pcid} />} >
                           {({ blob, url, showVoucher, error }) =>
                             showVoucher ? 'Loading document...' : 'STA Invoice'
                           }
                         </PDFDownloadLink>
                       </li>
                     }
                     <li>
                       <PDFDownloadLink fileName="customer_inv.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} />} >
                         {({ blob, url, showVoucher, error }) =>
                           showVoucher ? 'Loading document...' : 'Customer Invoice'
                         }
                       </PDFDownloadLink>
                     </li> */}
                   </>
                 }
                 {/* <li>s
                           <Link to="#" >Void Booking</Link>
                         </li>
                         <li>
                           <Link to="#">Void/Cancel</Link>
                         </li>
                         <li>
                             <Link to="#">Refund Ticket</Link>
                           </li>

                         <li>
                             <Link to="#">Reissue Ticket</Link>
                           </li> */}

               </ul>
             </div>  </span> } </div></h5>

           {/* Booking Details Section */}
           {state.htlresponse !== undefined && state.htlresponse.fare_det !== undefined &&
             <BookingDetails htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} />
           }

           <div className="gustinfo">
             <div className="tabLink">
               {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
                 <>
                   {context.logindata.utype !== undefined && context.logindata.utype === "att_user" &&
                     <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                       <span key="Ezytrip">Ezytrip</span>
                       <span key="TravelAgent"> Travel Agent</span>
                       {state.htlresponse.bkng_det.buser_type === "sta_user" &&
                         <span key="SubTravelAgent">Sub Travel Agent</span>
                       }
                     </Tabs>
                   }
                   {context.logindata.utype !== undefined && context.logindata.utype === "ta_user" &&
                     <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                       <span key="TravelAgent"> Travel Agent</span>
                       {state.htlresponse.bkng_det.buser_type === "sta_user" &&
                         <span key="SubTravelAgent">Sub Travel Agent</span>
                       }
                     </Tabs>
                   }

                   {context.logindata.utype !== undefined && context.logindata.utype === "sta_user" &&
                     <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                       <span key="SubTravelAgent">Sub Travel Agent</span>
                     </Tabs>
                   }

                   <div className="tabContent">
                     {content[tablist.active]}
                   </div>
                 </>
               }
             </div>
           </div>

           {state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.price_note !== undefined && state.htlresponse.bkng_det.price_note !== null && state.htlresponse.bkng_det.price_note !== "" &&

            <div className="costList total otherCust">
               <span className="priceNote"><span>Due at property :</span> {state.htlresponse.bkng_det.price_note} </span>
             </div>
           }


         </div>
         {state.htlresponse.paydetails !== undefined && state.htlresponse.paydetails.length !== 0 &&
           <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
             <h5 className="title d-flex justify-content-between">Payment Details</h5>
             {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
               <PaymentDetails payments={state.htlresponse.paydetails} bktype={state.htlresponse.bkng_det.booking_status} />
             }
           </div>
         }
         {state.htlresponse.custms !== undefined && state.htlresponse.custms.length !== 0 &&
           <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
             <h5 className="title d-flex justify-content-between">Custom Fields Remarks</h5>
             {state.htlresponse !== undefined && state.htlresponse.custms !== undefined &&
               <CustomFields res={state.htlresponse.custms} />
             }
           </div>
         }
         {mtaList.length !== 0 && mtaList.length !== 0 &&
           <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
             <h4 className="title d-flex justify-content-between">Refund Details ({context.logindata.cur})</h4>
             <div className="tabLink tabList gustinfo">
               {context.logindata.utype === "att_user" && mtaList.length !== 0 &&
                 <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                   <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                   <span key="TA">Travel Agent</span>
                   {staList.length !== 0 && props.bookedTo === "sta_user" &&
                     <span key="STA">Sub Travel Agent</span>
                   }
                 </Tabs>
               }
               {context.logindata.utype === "ta_user" && taList.length !== 0 &&
                 <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                   <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                   {staList.length !== 0 && props.bookedTo === "sta_user" &&
                     <span key="STA">Sub Travel Agent</span>
                   }
                 </Tabs>
               }
               {context.logindata.utype === "sta_user" && staList.length !== 0 && props.bookedTo === "sta_user" &&
                 <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                   <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                 </Tabs>
               }
               <div className="tabContent">
                 {refund[refundTab.active]}
               </div>
             </div>
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelVoucherPdf ref={componentRef} enablePolicy={true} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} allPax={false} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelVoucherPdf ref={componentRefAP} enablePolicy={true} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} allPax={true} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelVoucherPdf ref={componentVoucherRef} enablePolicy={false} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} emialId={context.logindata.unm} companyLogo={context.logindata.comp_logo_url} allPax={false} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelVoucherPdf ref={componentVoucherRefWAP} enablePolicy={false} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} emialId={context.logindata.unm} companyLogo={context.logindata.comp_logo_url} allPax={true} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelInvoicePdf ref={componentRefInv} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"ta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Purchase"} type={"TA"} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelInvoicePdf ref={componentRefSTA} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"sta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Purchase"} type={"STA"} parent_id={context.logindata.pcid} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} />
           </div>
         }
         {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
           <div style={{ display: "none" }}>
             <HotelInvoicePdf ref={componentRefCust} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} companyLogo={context.logindata.comp_logo_url} />
           </div>
         }

              {state.checkCancel &&
                  <div className="resultSection bg-white boxShadow PassengerDetails mb-4">
                 <h5 className="title mb-2">Payment Details</h5>
                 <div className="hotelPaynow priceNote">
                 <p>{state.htlresponse.bkng_det.usr_curr_code} {Number(state.totalPayAmount).toFixed(2)} is pending to confirm the booking before  {DateUtils.prettyDate(state.htlresponse.htldtls.free_cancel_date, "ddd, MMM DD, YYYY HH:mm:ss")}  </p>
                 <div className="d-flex">
                 <Button
                   size="xs"
                   variant="outline-secondary"
                   type="submit"
                   className="mr-4"
                   onClick={handleCancelHoldBooking}
                 > CANCEL HOLD BOOKING</Button>
                 <Button
                   size="xs"
                   variant="primary"
                   className="d-flex align-items-center"
                   type="submit"
                   onClick={handlePayBooking}
                > PAY NOW <span className="ezyIcon icon-rightArrow"></span></Button></div>
                 </div>

             </div>
               }

         {state.enableEdit &&
           <div className="text-right buttonGrop border-0 pt-0">
             <Button
               size="xs"
               variant="outline-secondary"
               type="submit"
               onClick={cancelEdit}
             > Cancel</Button>
             <Button
               size="xs"
               variant="outline-primary"
               type="submit"
               onClick={callEditBooking}
             > Update</Button>
           </div>
         }

        {state.enablePay &&
         <div className="text-right buttonGrop border-0 pt-0">
           {state.enablePay && !state.isprocess &&
             <Button
               size="xs"
               variant="outline-secondary"
               type="submit"
               onClick={closeCancel}
             > Cancel</Button>
           }
           {state.enablePay && state.isprocess ? <Button
             size="xs"
             variant="outline-primary"
             className="progressIn"
             type="submit"
           > Process...</Button>
             : <>

               {state.enablePay &&
                 <Button
                   size="xs"
                   variant="outline-primary"
                   type="submit"
                   onClick={callPayRequest}
                 > CONFIRM SELECTED</Button>
               }
             </>
           }

         </div>}


         {state.enableCancel &&
           <div className="text-right buttonGrop border-0 pt-0">
             {state.isprocess ? <Button
               size="xs"
               variant="outline-primary"
               className="progressIn"
               type="submit"
             > Process...</Button>
               : <> <Button
                 size="xs"
                 variant="outline-secondary"
                 type="submit"
                 onClick={backCancel}
               > Back</Button>
                 <Button
                   size="xs"
                   variant="outline-primary"
                   type="submit"
                   onClick={callCancelPrice}
                 > Continue</Button>
               </>}

           </div>
         }

         {state.showEditpop &&
           <Modal
             show={state.showEditpop}
             onHide={oncloseEidt}
             className="importPNRMdl requiredFare"
             backdrop="static"
             keyboard={false}
           >
             <Modal.Header closeButton>
               <div className="popUplayout center">
                 <div className="icons warning"></div>
                 <strong>Are you sure to update the change(s)? </strong>
               </div>

             </Modal.Header>
             <Modal.Footer className="justify-content-center">
               <Button
                 size="xs"
                 variant="secondary"
                 onClick={oncloseEidt}
                 className="mr-2"
                 type="submit"
               >No</Button>
               <Button
                 size="xs"
                 variant="primary"
                 onClick={processEditBookingFlow}
                 type="submit"
               >Yes</Button>
             </Modal.Footer>
           </Modal>
         }
         {state.showCancelPop && state.refund_data_show &&
           <Modal
             show={state.showCancelPop}
             onHide={oncloseCancel}
             className="cnclpopUp"
             backdrop="static"
             keyboard={false}
             size="lg"
           >
             <Modal.Body className="pb-0">
               <h5>Price Details({state.htlresponse.bkng_det.usr_curr_code})</h5>
               <div className="tabLink tabList">
                 {context.logindata.utype === "att_user" && mtaPriceList.length !== 0 &&
                   <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                     <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                     <span key="TA">Travel Agent</span>
                     {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                       <span key="STA">Sub Travel Agent</span>
                     }
                   </Tabs>
                 }
                 {context.logindata.utype === "ta_user" && taPriceList.length !== 0 &&
                   <Tabs active={refPriceTab.active == "ATT" ? "TA" : refPriceTab.active } onChange={active => setRefPriceTab({ active })}>
                     <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                     {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                       <span key="STA">Sub Travel Agent</span>
                     }
                   </Tabs>
                 }
                 {context.logindata.utype === "sta_user" && staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                   <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                     <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                   </Tabs>
                 }
                 <div className="tabContent pb-0">
                   {refundPrice[refPriceTab.active]}
                 </div>
               </div>
             </Modal.Body>
            <div className="pl-4 pr-4 pb-4">
                 <Form.Group as={Col} xs={12} className={state.validationFld ? "error p-0": "p-0"}>
                 <Form.Control as="textarea" rows="2" value={remarks} placeholder="Remarks: Cancelling as per customer request" onChange={handleChange} required />
                 <Form.Label>Remarks</Form.Label>
               </Form.Group>
               <div className="text-right buttonGrop pb-0">
                 {/* {state.cancelPriceResp && state.cancelPriceResp[0] !== undefined &&
                   <Alert variant="info" className="text-left">
                     <Icon className="mr-1" color="#004085" size={15} icon="info" />  {cur} {<FormateCurrency value={calculateRefundPrice(state.cancelPriceResp[0].rooms)} />} will be credited on successfull Cancellation
                       </Alert>} */}

                   <Button
                     size="xs"
                     variant="secondary"
                     onClick={oncloseCancel}
                     className="mr-2"
                     type="submit"
                   >No</Button>
                   <Button
                     size="xs"
                     variant="primary"
                     onClick={cancelProcessPopUp}
                     type="submit"
                   >Continue Cancellation</Button>
                 </div>


                 </div>
           </Modal>
         }

         {state.enableCancelHold &&
           <div className="text-right buttonGrop border-0 pt-0">
             {state.isprocess ? <Button
               size="xs"
               variant="outline-primary"
               type="submit"
               className="progressIn"
             > Process...</Button>
               : <> <Button
                 size="xs"
                 variant="outline-secondary"
                 type="submit"
                 onClick={closeCancelHold}
               > Cancel</Button>
                 <Button
                   size="xs"
                   variant="outline-primary"
                   type="submit"
                   onClick={cancelHoldBookingRoom}
                 > Continue</Button>
               </>}

           </div>
         }

         {state.cancelConfirm &&
           <Modal
             show={state.cancelConfirm}
             onHide={oncloseCancelConfirm}
             className="processCancel requiredFare"
             backdrop="static"
             keyboard={false}
           >
             <Modal.Header>
               <div className="popUplayout center">
                 <div className="icons warning"></div>
                 <strong>Are you sure you want to process the Cancel?</strong>
               </div>

             </Modal.Header>
             <Modal.Footer className="justify-content-center">
               {state.isprocess ?
                 <span
                  className="btn btn-secondary progressIn"

                 > Process...</span> :
                 <><Button
                   size="xs"
                   variant="secondary"
                   onClick={oncloseCancelConfirm}
                   className="mr-2"
                   type="submit"
                 >No</Button>
                   <Button
                     size="xs"
                     variant="primary"
                     onClick={processBookingCancel}
                     type="submit"
                   >Yes</Button></>}
             </Modal.Footer>
           </Modal>
         }
       </div>
       <Modal
        show={state.showPopup}
        onHide={handleemailClose}
        className="importPNRMdl shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5>Share Itinerary</h5>
          <Row>
          <Col xs={10}>
          <Form.Group controlId="uem">
              <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>


            </Form.Group>
          </Col>
          <Col xs={2}>
          <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={sendEmail}>Submit
          </Button>
            </div>
          </Col>
          </Row>



        </Modal.Body>
            </Modal>

       <Footer />
     </>
   )
 }
 const validateEmail = ()=>{
   if(state.email == ""){
     dispatch({ type: 'notiMessageShow', payload: true })
     dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
     dispatch({ type: 'notiVarient', payload: 'danger' })
     hidemessage();
     return false
    }
    else if(!emailValidation(state.email)){
     dispatch({ type: 'notiMessageShow', payload: true })
     dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
     dispatch({ type: 'notiVarient', payload: 'danger' })
     hidemessage();
     return false
    }
    return true
  }
  return (
    <>
      {state.comp_id !== 0 &&
        <Header />
      }
      <div className="container confirmation hotelbookingSummery">
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }

        {/* Displaying Messages for Refund Pricing room wise */}
        {isArrayNotEmpty(state.message_array) &&
          <Alert className="notification" variant={state.notiVarient} show={state.refundmsgshow}
            onClose={() => dispatch({ type: "refundmsgshow", payload: false })}
            dismissible>{state.message_array.map((item, index) => (
              <span key={index}>{item.label} {item.value}<br/></span>
            ))}</Alert>
        }

        <div className="summarytopSec">
        {window.sessionStorage.getItem(IS_LATEST)!==null &&
          <Button
            variant="link"
           className="backbtn ezyIcon icon-rightArrow"
            onClick={handleCancel}
         >Back</Button>
        }
          <div className="printSec">
            {/* <Button
                  size="xs"
                  variant="primary"
                  className="mr-3"
                >Add New Booking</Button> */}
               {state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&<div className='socialIcons'>
           <Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link">Print Summary
            </Button>
            </div>}
           {/* <span className="moreDetails share onclickTag ezyIcon icon-share" ref={ref} onClick={openshare}>
              <div className={"showDetails " + (share ? 'active' : 'inactive')}>
                <ul className="d-flex flex-column">
                      <li>
                        <Link to="#">Itinerary</Link>
                      </li>
                      <li>
                        <Link to="#">Invoice</Link>
                      </li>
                      <li>
                        <Link to="#">E-Ticket</Link>
                      </li>

                    </ul>
              </div>
            </span> */}
          </div>
        </div>
        {/* {state.htlresponse.fare_det !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.htldtls !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_C_B_S') && (state.htlresponse.bkng_det.booking_status === "23") &&
               <>
               {context.logindata.butype === "ta_user" &&
                <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
                <HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} />
                </PDFViewer>
              }
              </>
        } */}
        <div className="resultSection boxShadow bg-white summary mb-4">
          <h5 className="title d-flex justify-content-between">Booking Details
                   {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openBookinDetailsMore}>

                    <div className={"showDetails " + (editMoreBD ? 'active' : 'inactive')}>
                      <ul className="d-flex flex-column">
                         <li>
                          <Link to="#" >Void Booking</Link>
                        </li>
                        <li>
                          <Link to="#">Void/Cancel</Link>
                        </li>
                        <li>
                            <Link to="#">Refund Ticket</Link>
                          </li>

                        <li>
                            <Link to="#">Reissue Ticket</Link>
                          </li> 

           </ul>
                    </div>  </span>  */}
          </h5>

          {/* Booking Details Section */}
          {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
            <TransactionSummary bkngdetails={state.htlresponse.bkng_det} enableEdit={state.enableEdit} sendBookStatus={updateBstatus} utype={context.logindata.utype} />
          }
        </div>



        <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
         <h5 className="title d-flex justify-content-between">Hotel Details
         <div className='d-flex'>
         {state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
         <div className='socialIcons'>
                          <Button className="prient ezyIcon icon-share" onClick={openshareevent} variant="link"></Button>
               </div>}
               {state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.booking_status !== "28" && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openMOre}>

            <div className={"showDetails " + (editMore ? 'active' : 'inactive')}>
              <ul className="d-flex flex-column">
                {context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_E_B_S') && state.htlresponse.bkng_det!==undefined && state.htlresponse.bkng_det.booking_status !== "28" &&
                  <li>
                    <Link to="#" onClick={handleEdiyBooking} >Edit Booking</Link>
                  </li>
                }
                {context.logindata !== undefined && state.htlresponse.bkng_det !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                  <li>
                    <Link to="#" onClick={() => clickVoucher()} >Voucher with CP</Link>
                    <Link to="#" onClick={() => clickVoucherNcp()} >Voucher without CP </Link>
                    <Link to="#" onClick={() => clickVoucherAP()} >Voucher with CP all pax</Link>
                    <Link to="#" onClick={() => clickVoucherNcpWAP()} >Voucher without CP all pax </Link>
                  </li>

                }
                {state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.booking_status !== undefined && state.htlresponse.bkng_det.booking_status === "22" &&
                  <li>
                    <Link to="#" onClick={handleCancelHoldBooking} >Cancel Hold Booking</Link>
                  </li>
                }
                {state.htlresponse.bkng_det !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_C_B_S') && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                  <li>
                    <Link to="#" onClick={handleCancelBooking} >Cancel Booking</Link>
                  </li>
                }
                {state.htlresponse.bkng_det !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && context.logindata.roles.includes('H_B_S_R') && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                  <li>
                    <Link to="#" onClick={handleRefundBooking} >Manual Refund</Link>
                  </li>
                }
                {state.htlresponse.fare_det !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.htldtls !== undefined && context.logindata !== undefined && context.logindata.roles !== undefined && (state.htlresponse.bkng_det.booking_status === "23" || state.htlresponse.bkng_det.booking_status === "41") &&
                  <>
                    <li>
                      {context.logindata.utype !== "sta_user" &&
                        <Link to="#" onClick={() => clickInvoice("TA")} >TA Invoice </Link>
                      }
                      {context.logindata.butype === "sta_user" &&
                        <Link to="#" onClick={() => clickInvoice("STA")} >STA Invoice </Link>
                      }
                      <Link to="#" onClick={() => clickInvoice("CUSTOMER")} >Customer Invoice </Link>

                      {/* <PDFDownloadLink  fileName="voucher_with_cp.pdf" document={<HotelVoucher roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} cancelPolicy={true} utpye={context.logindata.butype} />} >
                        {({ blob, url, showVoucher, error }) =>
                          showVoucher ? 'Loading document...' : 'Voucher with CP'
                        }
                      </PDFDownloadLink> */}
                    </li>
                    {/* <li>
                      <PDFDownloadLink fileName="voucher_withOut_cp.pdf" document={<HotelVoucher roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} cancelPolicy={false} utpye={context.logindata.butype} />} >
                        {({ blob, url, showVoucher, error }) =>
                          showVoucher ? 'Loading document...' : 'Voucher without CP'
                        }
                      </PDFDownloadLink>
                    </li> */}
                    {/* {context.logindata.utype !== "sta_user" &&
                      <li>
                        <PDFDownloadLink fileName="ta_invoce.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"ta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"TA"} />} >
                          {({ blob, url, showVoucher, error }) =>
                            showVoucher ? 'Loading document...' : 'TA Invoice'
                          }
                        </PDFDownloadLink>
                      </li>
                    }
                    {context.logindata.butype === "sta_user" &&
                      <li>
                        <PDFDownloadLink fileName="sta_invoce.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"sta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"STA"} parent_id={context.logindata.pcid} />} >
                          {({ blob, url, showVoucher, error }) =>
                            showVoucher ? 'Loading document...' : 'STA Invoice'
                          }
                        </PDFDownloadLink>
                      </li>
                    }
                    <li>
                      <PDFDownloadLink fileName="customer_inv.pdf" document={<HotelInvoice roomsData={state.htlresponse.fare_det} bookingData={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} />} >
                        {({ blob, url, showVoucher, error }) =>
                          showVoucher ? 'Loading document...' : 'Customer Invoice'
                        }
                      </PDFDownloadLink>
                    </li> */}
                  </>
                }
                {/* <li>s
                          <Link to="#" >Void Booking</Link>
                        </li>
                        <li>
                          <Link to="#">Void/Cancel</Link>
                        </li>
                        <li>
                            <Link to="#">Refund Ticket</Link>
                          </li>

                        <li>
                            <Link to="#">Reissue Ticket</Link>
                          </li> */}

              </ul>
            </div>  </span> } </div></h5>

          {/* Booking Details Section */}
          {state.htlresponse !== undefined && state.htlresponse.fare_det !== undefined &&
            <BookingDetails htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} />
          }

          <div className="gustinfo">
            <div className="tabLink">
              {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
                <>
                  {context.logindata.utype !== undefined && context.logindata.utype === "att_user" &&
                    <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                      <span key="Ezytrip">Ezytrip</span>
                      <span key="TravelAgent"> Travel Agent</span>
                      {state.htlresponse.bkng_det.buser_type === "sta_user" &&
                        <span key="SubTravelAgent">Sub Travel Agent</span>
                      }
                    </Tabs>
                  }
                  {context.logindata.utype !== undefined && context.logindata.utype === "ta_user" &&
                    <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                      <span key="TravelAgent"> Travel Agent</span>
                      {state.htlresponse.bkng_det.buser_type === "sta_user" &&
                        <span key="SubTravelAgent">Sub Travel Agent</span>
                      }
                    </Tabs>
                  }

                  {context.logindata.utype !== undefined && context.logindata.utype === "sta_user" &&
                    <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                      <span key="SubTravelAgent">Sub Travel Agent</span>
                    </Tabs>
                  }

                  <div className="tabContent">
                    {content[tablist.active]}
                  </div>
                </>
              }
            </div>
          </div>

          {/* {state.htlresponse.bkng_det !== undefined && state.htlresponse.bkng_det.price_note !== null && state.htlresponse.bkng_det.price_note !== undefined &&

           <div className="costList total otherCust">
              <span className="priceNote"><span>Due at property :</span> {state.htlresponse.bkng_det.price_note} </span>
            </div>
          } */}

{state.htlresponse.bkng_det?.price_note !== "" && state.htlresponse.bkng_det?.price_note !== null && state.htlresponse.bkng_det?.price_note !== undefined &&

<div className="costList total otherCust">
   <span className="priceNote"><span>Due at property :</span> {state.htlresponse.bkng_det.price_note} </span>
 </div>
}

        </div>
        {state.htlresponse.paydetails !== undefined && state.htlresponse.paydetails.length !== 0 &&
          <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
            <h5 className="title d-flex justify-content-between">Payment Details</h5>
            {state.htlresponse !== undefined && state.htlresponse.bkng_det !== undefined &&
              <PaymentDetails payments={state.htlresponse.paydetails} bktype={state.htlresponse.bkng_det.booking_status} />
            }
          </div>
        }
        {state.htlresponse.custms !== undefined && state.htlresponse.custms.length !== 0 &&
          <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4">
            <h5 className="title d-flex justify-content-between">Custom Fields Remarks</h5>
            {state.htlresponse !== undefined && state.htlresponse.custms !== undefined &&
              <CustomFields res={state.htlresponse.custms} />
            }
          </div>
        }
        {mtaList.length !== 0 && mtaList.length !== 0 &&
          <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
            <h4 className="title d-flex justify-content-between">Refund Details ({context.logindata.cur})</h4>
            <div className="tabLink tabList gustinfo">
              {context.logindata.utype === "att_user" && mtaList.length !== 0 &&
                <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                  <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                  <span key="TA">Travel Agent</span>
                  {staList.length !== 0 && props.bookedTo === "sta_user" &&
                    <span key="STA">Sub Travel Agent</span>
                  }
                </Tabs>
              }
              {context.logindata.utype === "ta_user" && taList.length !== 0 &&
                <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                  <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                  {staList.length !== 0 && props.bookedTo === "sta_user" &&
                    <span key="STA">Sub Travel Agent</span>
                  }
                </Tabs>
              }
              {context.logindata.utype === "sta_user" && staList.length !== 0 && props.bookedTo === "sta_user" &&
                <Tabs active={refundTab.active} onChange={active => setRefundTab({ active })}>
                  <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                </Tabs>
              }
              <div className="tabContent">
                {refund[refundTab.active]}
              </div>
            </div>
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelVoucherPdf ref={componentRef} enablePolicy={true} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} allPax={false} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelVoucherPdf ref={componentRefAP} enablePolicy={true} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} allPax={true} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelVoucherPdf ref={componentVoucherRef} enablePolicy={false} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} emialId={context.logindata.unm} companyLogo={context.logindata.comp_logo_url} allPax={false} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelVoucherPdf ref={componentVoucherRefWAP} enablePolicy={false} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} roomsCount={state.htlresponse.fare_det[0].rooms.length} emialId={context.logindata.unm} companyLogo={context.logindata.comp_logo_url} allPax={true} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelInvoicePdf ref={componentRefInv} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"ta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Purchase"} type={"TA"} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelInvoicePdf ref={componentRefSTA} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={"sta_user"} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Purchase"} type={"STA"} parent_id={context.logindata.pcid} roomsCount={state.htlresponse.fare_det[0].rooms.length} companyLogo={context.logindata.comp_logo_url} />
          </div>
        }
        {state.htlresponse.htldtls !== undefined && state.htlresponse.bkng_det !== undefined && state.htlresponse.fare_det !== undefined &&
          <div style={{ display: "none" }}>
            <HotelInvoicePdf ref={componentRefCust} fareDetails={state.htlresponse.fare_det} bkngdetails={state.htlresponse.bkng_det} htldetails={state.htlresponse.htldtls} utpye={context.logindata.butype} phone_num={context.logindata.phno} emialId={context.logindata.unm} invType={"Sales"} type={"CUSTOMER"} companyLogo={context.logindata.comp_logo_url} />
          </div>
        }

             {state.checkCancel &&
                 <div className="resultSection bg-white boxShadow PassengerDetails mb-4">
                <h5 className="title mb-2">Payment Details</h5>
                <div className="hotelPaynow priceNote">
                <p>{state.htlresponse.bkng_det.usr_curr_code} {state.totalPayAmount} is pending to confirm the booking before  {DateUtils.prettyDate(state.htlresponse.htldtls.free_cancel_date, "ddd, MMM DD, YYYY HH:mm:ss")}  </p>
                <div className="d-flex">
                <Button
                  size="xs"
                  variant="outline-secondary"
                  type="submit"
                  className="mr-4"
                  onClick={handleCancelHoldBooking}
                > CANCEL HOLD BOOKING </Button>
                <Button
                  size="xs"
                  variant="primary"
                  className="d-flex align-items-center"
                  type="submit"
                  onClick={handlePayBooking}
               > PAY NOW <span className="ezyIcon icon-rightArrow"></span></Button></div>
                </div>

            </div>
              }

        {state.enableEdit &&
          <div className="text-right buttonGrop border-0 pt-0">
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={cancelEdit}
            > Cancel</Button>
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={callEditBooking}
            > Update</Button>
          </div>
        }

       {state.enablePay &&
        <div className="text-right buttonGrop border-0 pt-0">
          {state.enablePay && !state.isprocess &&
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={closeCancel}
            > Cancel</Button>
          }
          {state.enablePay && state.isprocess ? <Button
            size="xs"
            variant="outline-primary"
            className="progressIn"
            type="submit"
          > Process...</Button>
            : <>

              {state.enablePay &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={callPayRequest}
                > CONFIRM SELECTED</Button>
              }
            </>
          }

        </div>}


        {state.enableCancel &&
          <div className="text-right buttonGrop border-0 pt-0">
            {state.isprocess ? <Button
              size="xs"
              variant="outline-primary"
              className="progressIn"
              type="submit"
            > Process...</Button>
              : <> <Button
                size="xs"
                variant="outline-secondary"
                type="submit"
                onClick={backCancel}
              > Back</Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={callCancelPrice}
                > Continue</Button>
              </>}

          </div>
        }

        {state.showEditpop &&
          <Modal
            show={state.showEditpop}
            onHide={oncloseEidt}
            className="importPNRMdl requiredFare"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <div className="popUplayout center">
                <div className="icons warning"></div>
                <strong>Are you sure to update the change(s)? </strong>
              </div>

            </Modal.Header>
            <Modal.Footer className="justify-content-center">
              <Button
                size="xs"
                variant="secondary"
                onClick={oncloseEidt}
                className="mr-2"
                type="submit"
              >No</Button>
              <Button
                size="xs"
                variant="primary"
                onClick={processEditBookingFlow}
                type="submit"
              >Yes</Button>
            </Modal.Footer>
          </Modal>
        }
        {state.showCancelPop && state.refund_data_show &&
          <Modal
            show={state.showCancelPop}
            onHide={oncloseCancel}
            className="cnclpopUp"
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body className="pb-0">
              <h5>Price Details({state.htlresponse.bkng_det.usr_curr_code})</h5>
              <div className="tabLink tabList">
                {context.logindata.utype === "att_user" && mtaPriceList.length !== 0 &&
                  <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                    <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                    <span key="TA">Travel Agent</span>
                    {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                      <span key="STA">Sub Travel Agent</span>
                    }
                  </Tabs>
                }
                {context.logindata.utype === "ta_user" && taPriceList.length !== 0 &&
                  <Tabs active={refPriceTab.active == "ATT" ? "TA" : refPriceTab.active } onChange={active => setRefPriceTab({ active })}>
                    <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                    {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                      <span key="STA">Sub Travel Agent</span>
                    }
                  </Tabs>
                }
                {context.logindata.utype === "sta_user" && staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                  <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                    <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                  </Tabs>
                }
                <div className="tabContent pb-0">
                  {refundPrice[refPriceTab.active]}
                </div>
              </div>
            </Modal.Body>
           <div className="pl-4 pr-4 pb-4">
                <Form.Group as={Col} xs={12} className={state.validationFld ? "error p-0": "p-0"}>
                <Form.Control as="textarea" rows="2" value={remarks} placeholder="Remarks: Cancelling as per customer request" onChange={handleChange} required />
                <Form.Label>Remarks<sup>*</sup></Form.Label>
              </Form.Group>
              <div className="text-right buttonGrop pb-0">
                {/* {state.cancelPriceResp && state.cancelPriceResp[0] !== undefined &&
                  <Alert variant="info" className="text-left">
                    <Icon className="mr-1" color="#004085" size={15} icon="info" />  {cur} {<FormateCurrency value={calculateRefundPrice(state.cancelPriceResp[0].rooms)} />} will be credited on successfull Cancellation
                      </Alert>} */}

                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={oncloseCancel}
                    className="mr-2"
                    type="submit"
                  >No</Button>
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={cancelProcessPopUp}
                    type="submit"
                  >Continue Cancellation</Button>
                </div>


                </div>
          </Modal>
        }

        {state.enableCancelHold &&
          <div className="text-right buttonGrop border-0 pt-0">
            {state.isprocess ? <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              className="progressIn"
            > Process...</Button>
              : <> <Button
                size="xs"
                variant="outline-secondary"
                type="submit"
                onClick={closeCancelHold}
              > Cancel</Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  type="submit"
                  onClick={cancelHoldBookingRoom}
                > Continue</Button>
              </>}

          </div>
        }

        {state.cancelConfirm &&
          <Modal
            show={state.cancelConfirm}
            onHide={oncloseCancelConfirm}
            className="processCancel requiredFare"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <div className="popUplayout center">
                <div className="icons warning"></div>
                <strong>Are you sure you want to process the Cancel?</strong>
              </div>

            </Modal.Header>
            <Modal.Footer className="justify-content-center">
              {state.isprocess ?
                <span
                 className="btn btn-secondary progressIn"

                > Process...</span> :
                <><Button
                  size="xs"
                  variant="secondary"
                  onClick={oncloseCancelConfirm}
                  className="mr-2"
                  type="submit"
                >No</Button>
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={processBookingCancel}
                    type="submit"
                  >Yes</Button></>}
            </Modal.Footer>
          </Modal>
        }
      </div>
      
          <Modal
            show={state.enableOtillaPop}
            onHide={otillaCancelConfirm}
            className="processCancel requiredFare"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <div className="popUplayout center">
                <div className="icons warning"></div>
                <strong>Partial room cancellations are not allowed.  Please contact the support team if you wish to cancel a specific room.</strong>
              </div>
            </Modal.Header>
            <Modal.Footer className="justify-content-center">
              {state.isprocess ?
                <span
                 className="btn btn-secondary progressIn"

                > Process...</span> :
                <><Button
                  size="xs"
                  variant="secondary"
                  onClick={otillaCancelConfirm}
                  className="mr-2"
                  type="submit"
                >No</Button>
                  <Button
                    size="xs"
                    variant="primary"
                    onClick={processOtillaBookingCancel}
                    type="submit"
                  >Yes</Button></>}
            </Modal.Footer>
          </Modal>
        
      <Modal
       show={state.showPopup}
       onHide={handleemailClose}
       className="importPNRMdl shareIpop"
       backdrop="static"
       keyboard={false}>
         <Modal.Header closeButton></Modal.Header>
       <Modal.Body>
         <h5>Share Itinerary</h5>
         <Row>
         <Col xs={10}>
         <Form.Group controlId="uem">
             <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
             <Form.Label>Email Id <sup>*</sup></Form.Label>


           </Form.Group>
         </Col>
         <Col xs={2}>
         <div className="d-flex justify-content-center">
           <Button variant="outline-dark" onClick={sendEmail}>Submit
         </Button>
           </div>
         </Col>
         </Row>



       </Modal.Body>
           </Modal>

      <Footer />
    </>
  )
}
export default HotelSummaryPage