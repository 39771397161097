
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useReducer } from 'react';
 import AirlinesMemo from '../../../common/airlines';
 import DateUtils from "../../../commonUtils/DateUtils";
 import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
 import { Col, Row} from "react-bootstrap";
import SummaryLayoverDetails from '../../summary/SummaryLayoverDetails';
 /**
  * Initial State Declaration
  */
 const initialState = {
     source: '', destination: '', duration: '', travel_type: '', departure_date: '' , gdspnr:"",notiMessageShow: false, notiMessage: '', notiVarient: ''
 };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 /**
  * @description:This function will Display the the Segment details
  * @author: Rambabu
  * @param {*}
  * @function SegmentDetailsPdf
  * @date : 18-09-2020
  */
 function SegmentDetailsPdf(props) {
     const [state, dispatch] = useReducer(reducer, initialState)

      /**
     * @description:This function will Display the the Segment details
     * @author: Lakshmi
     * @date : 18-05-2021
     * @param {*} code
     * @param {*} val
     */
   function getAirportdata(code, val) {
    if (isNotNull(props.airport_data) && isNotNull(code)) {
        for (let a in props.airport_data) {
            let air_data = props.airport_data[a];
            if (isNotNull(air_data.airportCode)) {
                if (air_data.airportCode === code && val === 'c') {
                    return air_data.cityName;
                } else if (air_data.airportCode === code && val === 'a') {
                    return air_data.airportName;
                }
            }
        }
    }
}

     return (
         <>
            <div className="flightRoutInfo">
            <div className="type mt-3">
                {props.type ==='Onward'}    {props.type ==='Return'}
        {props.supplier && props.supplier !== "0" ? (
          <div className="ltag lcc ezyIcon icon-nFlight mb-2">LCC</div>
        ): (
          <div className="ltag fcc ezyIcon icon-nFlight mb-2">FSC</div>
        )}
          </div>
             {isArrayNotEmpty(props.list) && (
                 props.list.map((seg, index) => (
                     <div className="flightRoutInfo">
                         <div className='baggInfFlightlay'>
                         <Row className="align-items-center rs-row">
                             <Col xs={3} className="rs-rTypeDate">
                                 <div className="airLogo d-flex align-items-center gg">
                                     <img
                                         src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + seg.markting_airline + '.png'}
                                         className="logo"
                                         title="ATT"
                                         alt="AirLine"
                                     />
                                     <div className="flightNo d-flex flex-column ml-4">
                                         <strong><AirlinesMemo code={seg.markting_airline} /></strong>
                                         <span>
                                         {seg.markting_airline}-{seg.flt_no}</span>
                                         <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={seg.operating_airline} /></span></div>
                                     </div>
                                 </div>
                             </Col>
                             <Col xs={7} className="rs-FromTo">
                                 <Row className="align-items-center">
                                     <Col className="d-flex flex-column">
                                         <span><strong>{DateUtils.prettyDate(seg.depart_time, "HH:mm")}</strong> {DateUtils.prettyDate(seg.depart_time, "ddd, DD MMM YYYY")}</span>
                                         <span>{getAirportdata(seg.seg_source, 'c')} ({seg.seg_source}),</span>
                                         <span>{getAirportdata(seg.seg_source, 'a')}</span>
                                         {seg.depart_terminal &&
                                         <small className="trm">{"Terminal - " + seg.depart_terminal}</small>
                                         }
                                     </Col>
                                     <Col className="p-0" xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                     <Col className="d-flex flex-column">
                                         <span><strong>{DateUtils.prettyDate(seg.arrival_time, "HH:mm")}</strong> {DateUtils.prettyDate(seg.arrival_time, "ddd, DD MMM YYYY")}</span>
                                         <span>{getAirportdata(seg.seg_destination, 'c')} ({seg.seg_destination}),</span>
                                         <span>{getAirportdata(seg.seg_destination, 'a')}</span>
                                         {seg.arrival_terminal &&
                                         <small className="trm">{"Terminal - " + seg.arrival_terminal}</small>
                                         }
                                     </Col>
                                 </Row>

                                 <span>

                                 </span>
                             </Col>
                             <Col xs={2} className={props.isEdit ? 'text-right':''}>
                             {props.supplier==="0" || props.supplier===0?<p className="m-0 ecoNomy pdfAlignment"><span>Class : </span>{seg.cabin_class} {seg.fare_class ? " - " + seg.fare_class : ""}</p>:<p className="m-0 ecoNomy pdfAlignment"><span>Fare Type : </span>{seg.fare_type==="Regular - R"? "SAVER":seg.fare_type==="Flexi - J"?"FLEXI":seg.fare_type==="Corporate - F"?"CORPORATE":seg.fare_type}</p>}
                                  <>
                                 {props.supplier!=="4" && seg.flow_type === 'split' && (<p className="m-0 pdfAlignment"><span>Airline PNR : </span> {seg.split_airline_pnr ? seg.split_airline_pnr : "N/A"}</p>)}
                                 {props.supplier!=="4" && seg.flow_type === 'normal' && (<p className="m-0 pdfAlignment"><span>Airline PNR : </span> {seg.normal_airline_pnr ? seg.normal_airline_pnr : "N/A"}</p>)}
                                  </>
                                 {seg.supplier === "0" && seg.flow_type === 'split' && (<p className="m-0 pdfAlignment"><span>GDS PNR  : </span> {seg.split_gds_pnr ? seg.split_gds_pnr : seg.normal_gds_pnr ? seg.normal_gds_pnr : "N/A"}</p>)}
                                 {seg.supplier === "0" && seg.flow_type === 'normal' && (<p className="m-0 pdfAlignment"><span>GDS PNR  : </span> {seg.normal_gds_pnr ? seg.normal_gds_pnr.split(",")[0] : "N/A"}</p>)}

                             </Col>
                         </Row>
                         {/* {seg.bgd  && ("Baggage Info : " + "Cabin "+seg.bgd + "  per Adult & Child")} */}

                         <div className="bgginfo">
                         {seg.supplier === "0" &&
                         <Row>
                             <Col xs="10" className='d-flex justify-content-start'>{seg.bgd  && <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                             </Col>
                         </Row>
                          }
                          {seg.supplier !== "0" && seg.bgd!==undefined &&
                         <Row>
                             <Col xs="10" className='d-flex justify-content-start'>{seg.bgd  && seg.bgd !=='-' && <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {seg.cabin_bag}, Check-in  {seg.bgd} per Adult & Child </div>}
                             </Col>
                         </Row>
                          }
                         </div>

                         </div>
                         {  props.list.length > 1 &&
                             (isNotNull(  props.list[(index + 1)]))
                             && <SummaryLayoverDetails layoverdata={[  props.list[index + 1].depart_time,   props.list[(index)].arrival_time, seg.seg_destination]} mappings={props.airport_data} />
                            }
                     </div>
                 ))
             )}
             </div>
         </>

     )
 }


 export default SegmentDetailsPdf;
