import log from "loglevel";
import React, { useContext, useEffect, useReducer } from 'react';
import { Col, Form } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import { stdCodes, titlesBooking } from '../../../adminstration/utils';
import ShowHide from '../../../common/showHide/ShowHide';
import { getProfilesList, getTravellersList } from '../review/operations';



/**
 * @description:This function will creaet all the Profile details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 27-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  id: 0, profid: 0, tl: "", fn: "", mn: "", ln: "", phc: "", phn: "", em: "", cem: "", stdCodeMenu: stdCodes(), titleList: titlesBooking(), data: "", profiles: [], profile: "", selProfile: [], proEnable: false, selTitle: [], selPhc: [], enableImport: false, flowType: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ProfileDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  let stdCodesMaster = require('../../../masterData/CountrySTDCodes.json');

  const titles = [
    { label: "Mr", value: "Mr" },
    { label: "Miss", value: "Miss" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Shka", value: "Shka" },
    { label: "Shk", value: "Shk" },
  ];
  useEffect(() => {
    var id = 0;
    if (context.logindata !== "") {
      if (context.logindata.butype === "ta_user" || context.logindata.butype === "sta_user") {
        id = context.logindata.cid;
      } else {
        id = context.logindata.bid;
      }
      if (id !== 0) {
        getProfilesList(context.logindata.butype, id).then(response => {
          if (response.suc) {
            dispatch({ type: 'proEnable', payload: true })
            dispatch({ type: 'profiles', payload: response.res })
          } else {
            dispatch({ type: 'noRecords', payload: true })
          }
        })
      }
    }
  }, [context.logindata]);

  useEffect(() => {
    if (props.flowType !== undefined && props.importLeadPax && props.importLeadPax != undefined) {
      convertImportProfile(props.importLeadPax);
      if (props.importFlow) {
        dispatch({ type: 'enableImport', payload: true })
      } else {
        dispatch({ type: 'enableImport', payload: false })
      }
    }
  }, [props.importLeadPax]);

  const convertProfile = (obj) => {
    //Object.assign(initialState, obj);
    dispatch({ type: 'tl', payload: obj.title })
    dispatch({ type: 'fn', payload: obj.fn })
    dispatch({ type: 'ln', payload: obj.ln })
    dispatch({ type: 'em', payload: obj.eml })
    dispatch({ type: 'cem', payload: obj.ceml })
    dispatch({ type: 'phc', payload: obj.phc })
    dispatch({ type: 'phn', payload: obj.phn })
    dispatch({ type: 'profid', payload: obj.id })
    dispatch({ type: 'selTitle', payload: [{ "value": obj.title, "label": obj.title }] })
    stdCodesMaster.map((std, sidx) => {
      if (Number(std.code) === Number(obj.phc)) {
        dispatch({ type: 'selPhc', payload: [{ "value": std.name, "label": std.name }] })
      }
    });
    const payload = {
      "tl": obj.title,
      "fn": obj.fn,
      "ln": obj.ln,
      "mn": obj.mn,
      "phc": obj.phc,
      "phn": obj.phn,
      "em": obj.eml,
      "cem": (obj.ceml !== "" && obj.ceml !== undefined) ? obj.ceml : obj.eml,
      "profid": obj.id
    }
    props.sendProfile(payload);

  }

  const convertImportProfile = (obj) => {
    dispatch({ type: 'selProfile', payload: [obj] })
    dispatch({ type: 'tl', payload: obj.tl })
    dispatch({ type: 'fn', payload: obj.fn })
    dispatch({ type: 'ln', payload: obj.ln })
    dispatch({ type: 'em', payload: obj.em })
    dispatch({ type: 'cem', payload: obj.em })
    dispatch({ type: 'phc', payload: obj.phc })
    dispatch({ type: 'phn', payload: obj.phn })
    dispatch({ type: 'selTitle', payload: [{ "value": obj.tl, "label": obj.tl }] })
    stdCodesMaster.map((std, sidx) => {
      if (Number(std.code) === Number(obj.phc)) {
        dispatch({ type: 'selPhc', payload: [{ "value": std.name, "label": std.name }] })
      }
    });
    const payload = {
      "tl": obj.tl,
      "fn": obj.fn,
      "ln": obj.ln,
      "mn": obj.mn,
      "phc": obj.phc,
      "phn": obj.phn,
      "em": obj.em,
      "cem": (obj.cem !== "" && obj.cem !== undefined) ? obj.cem : obj.em,
      "profid": obj.id
    }
    props.sendProfile(payload);
  }

  const handleTitle=(e)=>{
    if(e!==undefined && e.length!==0){
      dispatch({ type: 'tl', payload: e[0].value })
      props.sendTitle(e[0].value)
    }
  }


  const handleProfile = (e) => {
    if (e[0] !== undefined && e[0].id !== undefined) {
      convertProfile(e[0]);
      getTravellersList(e[0].id,props.type).then(response => {
        if (response.suc) {
          props.travelres(response);
        }
      })
      props.sendName(e[0].fn);
    } else {
      dispatch({ type: 'fn', payload: e })
      props.sendName(e);
    }
  }

  const handleChangeProfile = (obj) => {
    dispatch({ type: "selProfile", payload: obj })
    if (obj[0] !== undefined && obj[0].id !== undefined) {
      convertProfile(obj[0]);
      getTravellersList(obj[0].id,props.type).then(response => {
        if (response.suc) {
          props.travelres(response);
        }
      })
    }
  }

  const handleChange = (evt) => {
    try {
      const { id, value } = evt.target;
      evt.preventDefault();
      evt.nativeEvent.stopImmediatePropagation();
      dispatch({ type: id, payload: value });
        const payload = {
        "tl": state.tl,
        "fn": state.fn,
        "ln": state.ln,
        "mn": state.mn,
        "phc": state.phc,
        "phn": state.phn,
        "em": state.em,
        //"cem":state.cem,//here state value is not updataing
        "cem": id === 'cem' ? value : "",
        "profid": state.profid
      }
      dispatch({ type: "data", payload: payload });
      if(id==="ln"){
        props.sendLastname(value);
      }else if(id==="phn"){
        props.sendPhone(value);
      }else if(id==="em"){
        props.sendEmail(value);
      }else if(id==="cem"){
        props.sendCEmail(value);
      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }

  const handlephnCode = (e) => {
    if(e && e.length > 0) {
      if(e[0].value !== "Select") {
        let bhpcSplit = e[0].value.split("(")
        let bhpc = bhpcSplit[1].split(")")
        dispatch({ type: 'phc', payload: bhpc[0] })
        props.sendPhcode(bhpc[0])
        }


    }

  }
  return (
    <>
      <div className="boxLayout">
        <ShowHide visible={false} icon="contact" title="Profile Details">
          <div className="showHide-content pt-4 pl-0 pr-0">
            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctid">
                <Select
                  options={props.type==="Flight"?state.titleList:titles}
                  searchable={false}
                  values={state.selTitle}
                  disabled={state.enableImport}
                  onChange={handleTitle}
                />
                <Form.Label>Title</Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="fn" className="selectplugin" xs={3}>
                {props.flowType !== undefined && props.flowType === "DIRECT" &&
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey={prof => `${prof.fn}`}
                    options={state.profiles}
                    placeholder="First Name"
                    selected={state.profile}
                    onInputChange={handleProfile}
                    onChange={handleProfile}
                  />
                }
                {props.flowType !== undefined && (props.flowType === "INCOMPLETE" || props.flowType === "IMPORT" || props.flowType === "REISSUE" || props.flowType === "ONHOLD REBOOK" || props.flowType === "ONHOLD") &&
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey={prof => `${prof.fn}`}
                    options={state.profiles}
                    placeholder="First Name"
                    selected={state.selProfile}
                    onInputChange={handleProfile}
                    onChange={prof => handleChangeProfile(prof)}
                    disabled={(props.flowType === "IMPORT" || props.flowType === "ONHOLD" || props.flowType === "REISSUE") ? true : false}
                  />
                }

                <Form.Label>First Name</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3}controlId="ln">
                <Form.Control type="text" disabled={state.enableImport} placeholder="Last Name" value={state.ln} onChange={handleChange}/>
                <Form.Label>Last Name</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin mb-0" controlId="ctid">
                <Select
                  options={state.stdCodeMenu}
                  searchable={true}
                  values={state.selPhc}
                  disabled={state.enableImport}
                  onChange={(value) => (handlephnCode(value))}
                />
                <Form.Label>Phone Code</Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="phn" className="mb-0">
                <Form.Control type="text" disabled={state.enableImport} placeholder="Mobile Number" data-ignorepaste="" value={state.phn} onChange={handleChange} />
                <Form.Label>Mobile Number</Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="em" className="mb-0">
                <Form.Control type="em" disabled={state.enableImport} placeholder="Email ID" data-ignorepaste="" value={state.em} onChange={handleChange} />
                <Form.Label>Email ID</Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="cem" className="mb-0">
                <Form.Control type="text" disabled={state.enableImport} placeholder="Confirm Email" value={state.cem} onChange={handleChange} />
                <Form.Label>Confirm Email </Form.Label>
              </Form.Group>
            </Form.Row>
          </div></ShowHide>
      </div>
    </>
  )
}
export default ProfileDetails