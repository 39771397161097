import React, { Component, useContext ,useState,useEffect } from 'react';
import './App.scss';
import '../src/client/assets/animation/animation.css';
import '../src/client/assets/animation/animate.min.css';
import RouterApp from './client/components/router';
import useGeoLocation from "react-ipgeolocation";
export const UserContext = React.createContext();
let airportsData = require('./client/components/masterData/airlinesData.json');
export const AirlinesContext = React.createContext();
export const Context = React.createContext();
export const SearchContext = React.createContext();
export const REGION_COUNTRY = "REGION_COUNTRY";

function App(props) {
  const [context, setContext] = useState({logindata:""});
  const [rescontext, setRescontext] = useState({response:[]});
  const location = useGeoLocation();
  // //Onlclik Browser close Btn
  // useEffect(() => {
  //   window.addEventListener('unload', handleTabClose);
  // }, []);
 
  // const handleTabClose = event => {
  //   event.preventDefault();
  //   localStorage.removeItem("userAuthData")
  // };

  if(location!==undefined && location.country!==undefined && window.sessionStorage.getItem(REGION_COUNTRY)===null){
    console.log("IP COUNTRY CODE : ",location.country);
    window.sessionStorage.setItem(REGION_COUNTRY, location.country);
}
  console.log("new date",new Date())
  return (
    <>
      <AirlinesContext.Provider value={airportsData}>
      <Context.Provider value={[context, setContext]}>
      <SearchContext.Provider value={[rescontext, setRescontext]}>
      <RouterApp />
      </SearchContext.Provider>
      </Context.Provider>
      </AirlinesContext.Provider>

    </>
  );
}




export default App;