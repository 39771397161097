import React, { useState ,use, useEffect} from "react";
import { Button, Form, Row, Col, InputGroup, FormControl,Alert } from "react-bootstrap";
import { deleteDocument} from "./operations";
function  VisaDocuments(props) {
  const [records, setRecords] = useState([
    { type: "", docs: [{ name: "", is_mand: false }] }
  ]);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();


  useEffect(() => {
    if (props.docs_info && props.docs_info.length > 0) {
      setRecords(props.docs_info);
    } else {
      setRecords([{ type: "", docs: [{ name: "", is_mand: false }] }]);
    }
  }, [props.docs_info]);
  const handleInputChange = (recordIndex, entryIndex, field, value) => {
    const newRecords = [...records];
    if (field === "type") {
      newRecords[recordIndex].type = value;
    } else {
      newRecords[recordIndex].docs[entryIndex][field] = field === "is_mand" ? value === "1"?"1":"0" : value;
    }
    setRecords(newRecords);
    props.onDataChange(newRecords);
  };

  const handleAddDocClick = (recordIndex) => {
    if (!isValidToAdd()) {
      setNotiMessage("Please fill all the fields before adding a new document.");
      setNotiVarient("danger");
      setNotiMessageShow(true);
      return;
    }
    const newRecords = [...records];
    newRecords[recordIndex].docs.push({ name: "", is_mand: false });
    setRecords(newRecords);
  };
  
  const handleAddRecordClick = () => {
    if (!isValidToAdd()) {
      setNotiMessage("Please fill all the fields before adding a new record.");
      setNotiVarient("danger");
      setNotiMessageShow(true);
      return;
    }
    setRecords([...records, { type: "", docs: [{ name: "", is_mand: false }] }]);
  };
  
  const handleDeleteDocClick = (recordIndex, docIndex,type) => {
    const newRecords = [...records];
    newRecords[recordIndex].docs.splice(docIndex, 1);
    setRecords(newRecords);
  };

  function hidemessage() {
    setTimeout(function () {
      setNotiMessageShow(true);
      setNotiMessage("");
      setNotiVarient("");
    }, 5000);
  }
  const isValidToAdd = () => {
    for (let record of records) {
      if (!record.type) return false;
      for (let doc of record.docs) {
        if (!doc.name) return false;
      }
    }
    return true;
  };
  const handleDeleteRecordClick = (e,recordIndex,docIndex,type) => {
    const newRecords = [...records];
    let list = [];
    let req={
      "ids": []
     }
    if(type==="Main"){
      if(props.enableUpdate!==undefined && props.enableUpdate){
        let newlist = []
        newRecords[recordIndex].docs.map((doc) => {
          newlist.push(doc.id)
          })
          console.log("in main delete",newlist)
          req.ids=newlist
          list.push(newRecords[recordIndex].docs[0].id)
      }
      newRecords.splice(recordIndex, 1);
    }else{
      if(props.enableUpdate!==undefined && props.enableUpdate){
        let li = [newRecords[recordIndex].docs[docIndex].id ]
        console.log("in sub delete",li)
        req.ids=li
      }
      newRecords[recordIndex].docs.splice(docIndex, 1);
      e.preventDefault()

    }
    setRecords(newRecords);
    if(props.enableUpdate!==undefined && props.enableUpdate){
    deleteDocument(req).then((res) => {
      if(res.data.suc){
        setNotiMessageShow(true);
        setNotiMessage("Selected Document Deleted successfully...");
        setNotiVarient("success");
        hidemessage()
      }else{
        setNotiMessageShow(true);
        setNotiMessage("Document Not Deleted.Please try agan later");
        setNotiVarient("danger");
        hidemessage()
      }
    });
  }
  };

  return (
    <>
            <Alert
              className="notification"
              variant={notiVarient}
              show={notiMessageShow}
              onClose={() =>
                setNotiMessageShow(false)
              }
              dismissible
            >
              {" "}
              {notiMessage}
            </Alert>
    <Form>
      {records.map((record, recordIndex) => (
        <div key={recordIndex} className="visaDd">
          <Row className="mb-2 align-items-center">
         
            <Form.Group as={Col} xs={3}>
            <Form.Control placeholder="Passport"
                  value={record.type}
                  //disabled={props.enableUpdate}
                  onChange={(e) =>
                    handleInputChange(recordIndex, null, "type", e.target.value)
                  }
                />
                <Form.Label>Document Type<sup>*</sup></Form.Label>
              </Form.Group>        
              
        
            {records.length > 1 && (
               <Form.Group as={Col} xs='auto'>
                <Button variant="outline-primary"           //disabled={props.enableUpdate}
 className="ezyIcon icon-delete" onClick={(e) => handleDeleteRecordClick(e,recordIndex,null,"Main")}>
                  Delete Record
                </Button>
              </Form.Group>     
            )}
          </Row>

          {record.docs.map((doc, docIndex) => (
            <Row key={docIndex} className="mb-3 mt-3 ">
               <Form.Group as={Col} xs={3}>
                
                  <Form.Control
                    value={doc.name}
                    //disabled={props.enableUpdate}

                    onChange={(e) =>
                      handleInputChange(recordIndex, docIndex, "name", e.target.value)
                    }
                  />
                   <Form.Label>Document Name<sup>*</sup></Form.Label>
          
              </Form.Group>
              <Col xs={3}>
                
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Is Mandatory?<sup>*</sup></InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    value={doc.is_mand.toString()}
                    //disabled={props.enableUpdate}

                    onChange={(e) =>
                      handleInputChange(recordIndex, docIndex, "is_mand", e.target.value)
                    }
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </Form.Control>
                </InputGroup>
              </Col>
              <Col xs="auto">
                {record.docs.length > 1 ? (
                  <Button variant="outline-primary"           //disabled={props.enableUpdate}
                   className="pmVis"  size="xs" onClick={() => handleDeleteRecordClick(recordIndex, docIndex,"Sub")}>
                    -
                  </Button>
                ) : null}
                {docIndex === record.docs.length - 1 ? (
                  <Button variant="outline-secondary"           //disabled={props.enableUpdate}
                   className="pmVis ml-2" onClick={() => handleAddDocClick(recordIndex)}>
                    +
                  </Button>
                ) : null}
              </Col>
            </Row>
          ))}

        </div>
      ))}

      <Row className="mt-3">
        <Col>
          <Button variant="outline-primary"           //disabled={props.enableUpdate}
 type="button" onClick={handleAddRecordClick}>

            Add New Record
          </Button>
        </Col>
      </Row>
    </Form>
    </>
  );
}

export default VisaDocuments;
