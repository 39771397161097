import React from 'react';
import Button from '../../../common/buttons/Button';
/**
 * @description:This function will retunr the error msg for booking
 * @author: Lakshmi
 * @param {*}
 * @function Booking  Error
 * @date : 10-09-2020
 */
function BookingError(props) {
  console.log("ERROR : " + JSON.stringify(props));
  const gotohome = () => {
    return window.location = "/ezytrip/dashboard";
  }
  const gotoresults = () => {
    return window.location = "/ezytrip/bookings/flight/results";
  }
  const gotoSummary = () => {
    return window.location = "/ezytrip/dashboard/bookingsummary/" + props.location.state.ref;
  }


  return (
    <div className="layover">
      <div className="popUpBox sessionTimeOut">
        <div className="icons sessionIcon"></div>

        {props.location.state.req === "Farerule" &&
          <div>
            <strong>Something went wrong....</strong>
            <p>Please check with Administration</p>
          </div>
        }

        {props.location.state.req === "Results" &&
          <div>
            <strong>Something went wrong....</strong>
            <p>Please check with Administration</p>
          </div>
        }
        {props.location.state.req === "Persistance" &&
          <div>
            <strong>Pre Persistance is Failed.</strong>
            <p>Please check with Administrator</p>
          </div>
        }

        {props.location.state.req === "Book" &&
          <div>
            <strong>Flight availability is gone..</strong>
            <p>Please select another flight</p>
          </div>
        }

        {props.location.state.req === "Payment" &&
          <div>
            <strong>Payment has failed..</strong>
            <p>Sorry we are unable to process this transaction.</p>
            <p>Please try again with another card or a different payment method</p>
          </div>
        }

        {props.location.state.req === "summary" &&
          <div>
            <strong>Payment has failed..</strong>
            <p>Sorry we are unable to process this transaction.</p>
            <p>Please try again with another card or a different payment method</p>
          </div>
        }


        {props.location.state.req === "summary" &&
          <Button onClick={() => {
            gotoSummary();
          }}
            size="md"
            className="refresh"
            variant="secondary"
          >Back To Summary</Button>
        }


        {props.location.state.req === "Persistance" &&
          <Button onClick={() => {
            gotohome();
          }}
            size="md"
            className="refresh"
            variant="secondary"
          >Back to Home</Button>
        }
        {props.location.state.req === "Book" &&
          <Button onClick={() => {
            gotoresults();
          }}
            size="md"
            className="refresh"
            variant="secondary"
          >Back to Result</Button>
        }

        {props.location.state.req === "Results" &&
          <Button onClick={() => {
            gotohome();
          }}
            size="md"
            className="refresh"
            variant="secondary"
          >Back to Home</Button>
        }

        {props.location.state.req === "Payment" &&
          <div>
            <Button onClick={() => {
              gotohome();
            }}
              size="md"
              className="refresh"
              variant="primary"
            >Back to Home</Button>
            <Button onClick={() => {
              gotohome();
            }}
              size="md"
              className="refresh"
              variant="secondary"
            >Retry with other card</Button>
          </div>
        }

        {props.location.state.req === "Farerule" &&
          <Button onClick={() => {
            gotoresults();
          }}
            size="md"
            className="refresh"
            variant="secondary"
          >Back to Results</Button>
        }
      </div>
    </div>
  )
}
export default BookingError