import React, { useContext } from 'react';
import { AirlinesContext } from '../../../../App'
function AirlinesMemo({code}) {
  const airlinesInfo = useContext(AirlinesContext)
  let name='';
  if(airlinesInfo!==undefined && airlinesInfo.filter((obj) => obj.code === code)[0]){
    name = airlinesInfo.filter((obj) => obj.code === code)[0].english;
  }
  return (name);
}

export default React.memo(AirlinesMemo)