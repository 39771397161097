import React, { useEffect, useReducer, useState } from 'react';


/**
 * Initial State Declaration
 */
const initialState = {
  taxPopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', remarks: "", attPaxPrice: [], taPaxPrice: [], staPaxPrice: [], showPopup: false, dateAvail: false, routeAvail: false, changeAvail: false, penaltyAvail: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {

  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function ReissueDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [reissueData, setReissueData] = useState(props.reissueDetails);

  useEffect(() => {
    if (reissueData !== undefined) {
      for (let val of reissueData.elig) {
        if (val.code === "route" && val.allowed) {
          dispatch({ type: "routeAvail", payload: true })
        } else if (val.code === "date" && val.allowed) {
          dispatch({ type: "dateAvail", payload: true })
        } else if (val.code === "penalty" && val.allowed) {
          dispatch({ type: "penaltyAvail", payload: true })
        }
      }
    }
  }, [reissueData]);

  return (
    <>
      <div className="bg-white reissueSearchDtls">
        <div className="container">
          <div className="listAllowed">
            {state.routeAvail &&
              <span className="ezyIcon icon-confirmedOne"> Route Change Allowed</span>
            }<br />
            {state.dateAvail &&
              <span className="ezyIcon icon-confirmedOne">  Date Change Allowed</span>
            }
            {state.penaltyAvail &&
              <span className="ezyIcon icon-confirmedOne">  Penalty applicable</span>
            }
          </div>

          <h6>Selected Tickets for Reissue</h6>
          <div className="paxList">
            <ul>
              {reissueData !== undefined &&
                reissueData.tkts.map((pax, index) => (
                  <React.Fragment key={index}>
                    <li>
                      <span>
                        <em>
                          {pax.ty === "ADT" &&
                          <span className="ezyIcon icon-person"></span>
                          }
                          {pax.ty === "CHD" &&
                            <span className="ezyIcon icon-child"></span>
                          }
                          {pax.ty === "INF" &&
                           <span className="ezyIcon icon-infant"></span>
                          }
                        </em>
                        {pax.nm}<span className="typ"> ({pax.ty === "ADT" &&
                          <>Adult</>
                        }
                          {pax.ty === "CHD" &&
                            <>Child</>
                          }
                          {pax.ty === "INF" &&
                            <>Infant</>
                          })</span></span> <span> (Tkt# : {pax.tkt})</span>
                    </li>
                  </React.Fragment>
                ))}</ul>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReissueDetails