import React, { useState } from 'react';
import { Col, Row, Table } from "react-bootstrap";
import FormateCurrency from '../../commonUtils/FormateCurrency';

//This function will show the Air markups List and Add Markup
function PaymentDetails(props) {


  return (
    <>
        <div className="resultSection boxShadow bg-white summary mb-4">
        {props.payments!==undefined &&  props.payments.length > 0 &&
                <Row>
                    <Col className="farepaymentInfo">
                    <div class="table-responsive">
                        <Table>
                            <tr>
                                <th>Payment Type</th>
                                <th>Form of Payment</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                            {props.payments && props.payments.map((pay, idx) => (
                                <>
                                    <tr>
                                    <td>
                                        <span>{"Credit Limit"}</span>
                                        </td>
                                        <td>
                                        <span>{pay.form_of_pay!==undefined && pay.form_of_pay!==""?pay.form_of_pay:"N/A"}</span>
                                        </td>
                                        <td>QAR {<FormateCurrency value={pay.purchase_amount} />}</td>
                                        {pay.payment_type.toUpperCase() !== "CREDIT CARD" &&
                                            <>
                                        <td className={pay.payment_status === "Confirmed" ? "status paxstatus confirm" : "status paxstatus "}>{pay.payment_status} </td>
                                        </>
                                        }
                                    </tr>
                                </>
                            ))}
                        </Table>
</div>

                    </Col>
                </Row>
            }
        </div>

    </>
  )
}
export default PaymentDetails