/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useState, useEffect } from 'react';
 import { Alert, Col, Form, Row } from "react-bootstrap";
 import DateUtils from "../../../commonUtils/DateUtils";
 import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";

 /**
  * @description: This function will show the Booking Details section
  * @date:18-09-2020
  * @author:Lakshmi
  *
  */
 function InsurnceBookingPdf(props) {
   const [state, setstate] = useState()
 
   
   return (
     <>
       <div className="flightbookinginfoPdf">
         <div className="flightheader">
           <Row>
             <Col xs={2}>
               <div className="img"> <img
                src={logo}
                className="htlImg"
                /></div>
             </Col>
             <Col xs={10}>
             <Row>
               <Col xs={6}>
               <Col xs={12}><strong>{props.response.booking_details.compnay_name}</strong></Col>
               <Col xs={12}><span>Contact Number: </span>{props.response.booking_details.phone_no}</Col>
               <Col xs={12}><span>Email : </span>{props.response.booking_details.email}</Col>
              </Col>
              <Col xs={6}>
               <Col xs={12}><span>Booking Id :</span>{props.response.booking_details.booking_id}</Col>
               <Col xs={12}><span>Booking Date: </span>{DateUtils.prettyDate(props.response.booking_details.booking_date, "ddd, DD MMM, YYYY")}</Col>
               <Col xs={12}><span>Booking Status : </span>{props.response.booking_details.booking_status}</Col>
              </Col>
             </Row>
             </Col>
           </Row>
         </div>
       </div>
     </>
   )
 }
 
 
 export default InsurnceBookingPdf;
 