/**
 * @description : Show SPlit PNR details
 * @author : <Azamuddin>
 * @date: 10-jan-2021
 */
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Col, Form, Row,Table, Alert} from "react-bootstrap";
import { isArrayNotEmpty } from '../../commonUtils/validators';
const JSON = require('circular-json');


const initialState = {
    pnr:""
}
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };

  let pnr = ""
function SplitPnr(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    //Selection of void enable inital pnr will be empty
    useEffect(() => {
        if(props.voidEnable) {
            pnr = ""
            props.pnr(pnr)
        }
    },[ props.voidEnable])

    //Selection of refund enable inital pnr will be empty
    useEffect(() => {
        if(props.refundEnable) {
            pnr = ""
            props.pnr(pnr)
        }
    },[ props.refundEnable])
    /**
     * @description : selected pnr is associated from here to prop
     * @param {e} pnr id
     * @author: Azamuddin
     * @date : 10-04-2021
     */
    const selectedPNR = (data) => (e)=> {
        const { id, value } = e.target;
        dispatch({type:"pnr",payload:""})
        if(pnr == id) {
            //if same then remove
            pnr = ""
        } else {
            //if empty or different then add (or) change
            pnr = id
        }
        props.pnr(pnr)
    }
    return (
        <div className="passengerList">
                <Table className="paxTable table m-0">
                    <tr>
                    <th width="40%">Traveller</th>
                    <th width="20%">Type</th>
                    <th width="20%">PNR</th>
                    <th width="20%">Status</th>
                    {/* <th xs="3">Iteary Ref#</th> */}
                    </tr>




            {isArrayNotEmpty(props.res) && (props.res.map((obj, index) => (
                <React.Fragment key={index}>
                    <tr className={(obj.sts === "31"  && props.voidEnable) || (obj.sts == "42" && props.refundEnable)? "list  pnrNumCol": "list"}>

                        <td>
                    <div className="voidTkt">
                         {obj.sts === "31"  && props.voidEnable &&
                            <Form.Check type="checkbox" label=""  checked={pnr && pnr == obj.pnr ? true : false} onClick={selectedPNR(obj)} custom id={obj.pnr} />
                        }
                        {obj.sts == "42" && props.refundEnable &&
                            <Form.Check type="checkbox" label=""  checked={pnr && pnr == obj.pnr ? true : false} onClick={selectedPNR(obj)} custom id={obj.pnr} />
                        }

                        {obj.pn} {obj.lp && obj.type==='ADT' ? "(Lead)" : ""}
                        </div>
                        </td>
                        <td>{obj.type}</td>
                        <td>{obj.pnr}</td> 
                                            
                        <td className="text-danger">{props.type == "Void" ? (obj.bt == "VoidCancel" ? "Void/Cancel" : obj.bt) : (obj.sts == "42" ? "Refund/booking" : "Refund/cancel")}</td>
                        {/* <Col xs="3">QAR {obj.ra}</Col> */}
                    </tr>
                    <tr><td colSpan="4" className="spaceNew"></td></tr>
                </React.Fragment>
            )))}
            </Table>
            </div>
    )
}


export default SplitPnr;
