
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";


//Main Function
function FBFResultBlockLoader(props) {
    const [state, setState] = useState({})

    return (

        <>
            <div className="rs-block">
                <Row>
                    <Col xs={4} className="srpLogo">
                        <div className="airLogo d-flex align-items-center pl-0">
                            <div className="width80"><div className="fltLogoLoad"></div></div>
                            <div className="logoBg1 width80 mr-3"></div>
                            <div className="flightNo d-flex flex-column width100">
                                <div className="stopsLoader4"></div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={5} className="short-timeStops d-flex justify-content-between align-items-center">
                        <div className="time d-flex flex-column width80">
                            <div className="stopsLoader4"></div>
                        </div>
                        <div className="stop d-flex flex-column align-items-center pl-2 pr-2">
                            <div className="width50"><div className="stops"></div></div>
                            <span className="icons"><span className="ezyIcon icon-flight"></span></span>
                            <div className="width50"><div className="stops"></div></div> </div>
                        <div className="time d-flex flex-column  width80">
                            <div className="stopsLoader4"></div> </div>
                    </Col>
                    <div className="duration  d-flex flex-column width100 align-items-center justify-content-center ml-3">
                        <div className="stops"></div> </div>
                </Row>
                <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                    <div className="flightAmenities d-flex width140">
                        <div className="flightAmenities"></div></div>
                    <div className="flightDetailsLink">
                        Flight Details <span className="ezyIcon icon-arrowDown"></span>
                    </div>

                </div>
            </div>



        </>
    )
}


export default FBFResultBlockLoader;
