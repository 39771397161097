import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { Alert, Col, Form ,Modal, Button, Table, Container,Row} from "react-bootstrap";
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import ShowHide from '../../../common/showHide/ShowHide';
import Attimage from '../../../../assets/images/GDEidd.jpg';
import getVisaSummaryData from '../../../../services/bookingService/visa/VisaBookingService'
import { Context } from "../../../../../App";
import VisaTravellers from "../review/VisaTravellers";
import VisaBookingService from "../../../../services/bookingService/visa/VisaBookingService";
import VisaSummaryEdit from "./VisaSummaryEdit";



// const initialState= {
//   summaryResp:[], notiMessageShow: false, notiMessage: '', notiVarient: '',
// }

// const reducer = (state, action) => {
//   switch (action.type) {

//     case 'test':
//       return Object.assign(state, action.payload);
//     default:
//       return { ...state, [action.type]: action.payload };
//   }
// };

function VisaSummary(props) {
  const [context, setContext] = useContext(Context);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enbleTravelrs, setEnbleTravelrs] = useState(false);
  const [jsonDAta, setJsonData] = useState("");
  const [passengerData, setPassengerData] = useState([]);
  const [paxData, setPaxData] = useState([]);
  const [paxID, setPaxID] = useState();
  const [adt_gst, setAdt_gst] = useState(0);
  const [chd_gst, setChd_gst] = useState(0);

  const dateOpt={
    weekday:'short',
    day:'2-digit',
    month:'short',
    year:'numeric'
  }
  const dateFormt={
    day:'2-digit',
    month:'2-digit',
    year:'numeric'
  }

  // const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(()=>{
  if(context.logindata?.utype=="att_user"){
    setEnableEdit(true)
  }
  let booking_id=""
  if(props.location.state?.booking_id!==undefined){
    booking_id=props.location.state.booking_id
  }else{
    let url_array = window.location.pathname.split("/");
    booking_id= url_array[url_array.length - 1];  
  }
    VisaBookingService.getVisaSummaryData(booking_id).then((response)=>{
      if (response.data.suc){
        for(let pax of response.data.res?.pax_info){
       if (pax.dob!=null && pax.dob!==undefined && pax.dob!=""){ 
          let newdob= new Date(pax.dob)
          pax.dob=newdob.toLocaleDateString('en-US', dateFormt)
        }
       if (pax.pas_exp!=null && pax.pas_exp!==undefined && pax.pas_exp!=""){ 
          let newpas_exp= new Date(pax.pas_exp)
          pax.pas_exp=newpas_exp.toLocaleDateString('en-US', dateFormt)
          }
        }
        let newDate= new Date(response.data.res.bkng_det.booking_date);
        response.data.res.bkng_det.booking_date=newDate.toLocaleDateString('en-US', dateOpt)
        setJsonData(response.data.res)
        setPassengerData(response.data.res?.pax_info)

        // gst calculation
        if (response.data.res.bkng_det.region_type=="India"){
        let agst= response.data.res.fare_det.adt_charge.adt_gst * response.data.res.bkng_det.no_adults
          setAdt_gst(agst) 
        let cgst= response.data.res.fare_det.chd_charge.chd_gst * response.data.res.bkng_det.no_childs
            setChd_gst(cgst)
          }
      }
    })
    },[context.logindata])


  const handleVisasummeryedit = () =>{
    // window.location='/ezytrip/dashboard/visa/summaryedit'
   }
    
  const editClick = (pax, idx)=>(e)=>{
    setEnbleTravelrs(true)
    setPaxData(pax)
    setPaxID(idx)
  }
   
    return (
        <>
        <Header/>
        <Container>         
       {jsonDAta.length!=0 && <div className="resultSection visaSummary boxShadow bg-white summary mb-4">
        <h5 className="title">Transaction Summary</h5>
            <div className="newlayoutDesign">
              <div className="bookingListinfo">
            <Row className="pb-2">
              <Col><span>Booking Ref Number : </span> <span onClick={handleVisasummeryedit}>{jsonDAta.bkng_det.booking_id}</span></Col>
              <Col><span>Requested Date : </span> <span>{jsonDAta.bkng_det.booking_date}</span></Col>
              <Col><span>Booking Status : </span> <span className="confirSum"> {jsonDAta.bkng_det.booking_status_str}</span></Col>
              </Row>
            </div>
            </div>         
              </div>}

              <div className="resultSection visaSummary boxShadow bg-white summary mb-4">
         <h5 className="title">Traveller Details</h5>
            <div className="iinernewlayoutDesign" >
        { jsonDAta.pax_info && jsonDAta.pax_info.map((pasenger, idx)=>( 
          <div key={idx} className="paxDetvisa">
            {(!enbleTravelrs ||paxID!=idx) && enableEdit && <a onClick={editClick(pasenger,idx)}>Edit</a>}
            <div className="paxDetinn">
           { (!enbleTravelrs ||paxID!=idx) &&
            <span className="SummeryTit">{pasenger.plbl} {pasenger.sno}. {pasenger.fn} {pasenger.ln}</span>
           } 
               { (!enbleTravelrs || paxID!=idx)&&
            <span className="summeryApp">"|" Application#: {pasenger.application_id}</span>
          } 
            </div>
            {paxID!=idx?<ShowHide visible={pasenger.lead? true :false} icon="xxx" >
              <Col>
              <div className="visaStatusv"><span>Visa Status: </span>  {!enbleTravelrs && <span className="confirSum"> 
              {pasenger.pax_status_str}</span>}</div></Col>
            
            <hr/>
            <div className="bookingListinfoinner">
            <Row className="pb-2">
              {pasenger.fn &&<Col xs={3}><span>First Name : </span> <span>{pasenger.fn}</span></Col>}
              {pasenger.ln &&<Col xs={3}><span>Last Name : </span> <span>{pasenger.ln}</span></Col>}
              {pasenger.application_id && <Col xs={3}><span>Application # : </span> <span className="confirSum"> {pasenger.application_id}</span></Col>}
              </Row>
              <Row className="pb-2">
              {pasenger.dob && <Col xs={3}><span>Date of Birth : </span> <span>{pasenger.dob}</span></Col>}
              {pasenger.gen &&<Col xs={3}><span>Gender : </span> <span>{pasenger.gen}</span></Col>}
              {pasenger.phn &&<Col xs={3}><span>Mobile Number : </span> <span className="confirSum"> {pasenger.phn}</span></Col>}
              </Row>
              <Row className="pb-2">
              {pasenger.em && <Col xs={3}><span>Email Id : </span> <span>{pasenger.em}</span></Col>}
              {pasenger.na &&<Col xs={3}><span>Nationality : </span> <span>{pasenger.na}</span></Col>}
              {pasenger.city && <Col xs={3}><span>City :</span> <span className="confirSum"> {pasenger.city}</span></Col>}
              </Row>
              <Row className="pb-2">
              {pasenger.state &&<Col xs={3}><span>State : </span> <span>{pasenger.state}</span></Col>}
              {pasenger.pas_num &&<Col xs={3}><span>Passport # : </span> <span>{pasenger.pas_num}</span></Col>}
              {pasenger.pas_exp &&<Col xs={3}><span>Passport Expiry : </span> <span className="confirSum"> {pasenger.pas_exp}</span></Col>}
              </Row>
              {pasenger.cur_adrs &&<Row className="pb-2">
              <Col xs={3}><span>Address : </span> <span>{pasenger.cur_adrs}</span></Col>
             
              </Row>}
            </div>
            <hr/>
            {pasenger?.docs_info!=undefined && pasenger?.docs_info>0 && <h5>Attachments :</h5>}
            {pasenger?.docs_info?.map((doc, i)=>(
            <div className="attSection" key={i}>
              <h5>{doc.type}:</h5>
             <Row className="pb-4">
              {doc.docs.map((docx, index)=>(
                <div key={index}>
            <Col xs={3}>
                <div className="downImage">
                <span className="attImm"><img src={Attimage}/> </span> 
                <span>{docx.front}</span> <span className="ezyIcon icon-Download"></span>
                </div>
                </Col>
            <Col xs={3}><div className="downImage"><span className="attImm"><img src={Attimage}/> </span> 
             <span>{docx.back}</span> <span className="ezyIcon icon-Download"></span></div>
            </Col>
            </div>))}
            </Row>

            {/* <hr/>
            <h5>Visa Copy :</h5>
             <Row className="pb-4">
            <Col><div className="downImage"><span className="attImm"><img src={Attimage}/> </span> <span>Visa-copy.JPEG</span> <span className="ezyIcon icon-Download"></span></div></Col>
           
            </Row> */}

            </div>))}
            </ShowHide>
            :  <VisaSummaryEdit uid={context.logindata.uid} bkng_det={jsonDAta.bkng_det} paxData={paxData} pax_id={paxID}/> 
            }
            </div>
          ))}
               
          </div> 
              </div>

           
              <div className="resultSection visaSummary boxShadow bg-white summary mb-4">
            <h5 className="title">Fare Details ({jsonDAta.bkng_det?.usr_curr_code})</h5>
            <div className="newlayoutDesign">
              <div className="bookingListinfo">
            <Row className="pb-2">
              <Col xs={2}><span className="visaTy">Visa Type</span></Col>
              <Col xs={3}><span className="visaTy">Entry Type</span></Col>
              <Col xs={3}><span className="visaTy">Amount</span></Col>
              {enableEdit && jsonDAta?.bkng_det?.region_type=="India" && <Col xs={2}><span className="visaTy">GST</span></Col>}
              <Col xs={2}><span className="visaTy">Total Amount</span></Col>
            </Row>
              <hr/>
          <Row className="pb-2">
              <Col xs={2}><span  className="visaTyr">{jsonDAta?.bkng_det?.entry_type}</span></Col>
              <Col xs={3}><span className="visaTyr">{jsonDAta?.bkng_det?.dur} {jsonDAta?.bkng_det?.dur_type} {jsonDAta?.bkng_det?.visa} </span></Col>
              <Col xs={3}><span  className="visaTyr">{jsonDAta?.bkng_det?.usr_curr_code} {jsonDAta?.bkng_det?.total_amount}</span></Col>
              {enableEdit && jsonDAta?.bkng_det?.region_type=="India" && <Col xs={2}><span className="visaTyr">{adt_gst+chd_gst} </span></Col>}
              <Col xs={2}><span  className="visaTyr">{jsonDAta?.bkng_det?.usr_curr_code} {jsonDAta?.bkng_det?.total_amount+adt_gst+chd_gst}</span></Col>
               </Row>
           
            </div>
            </div>         
              </div>


              <div className="resultSection visaSummary boxShadow bg-white summary mb-4">
            <h5 className="title">Payment Details</h5>
            <div className="newlayoutDesign">
              <div className="bookingListinfo">
            <Row className="pb-2">
              <Col><span className="visaTy">Payment Type</span></Col>
              <Col><span className="visaTy">Form of Payment</span></Col>
              <Col><span  className="visaTy">Amount</span></Col>
              <Col><span  className="visaTy">Status</span></Col>
              </Row>
              <hr/>
              {jsonDAta.length!=0 && jsonDAta.paydetails.map((pay)=>(
              <div>
          <Row className="pb-2">
              <Col><span className="visaTyr">{pay.payment_type}</span></Col>
              <Col><span className="visaTyr">{pay.form_of_pay}</span></Col>
              <Col><span className="visaTyr">  {jsonDAta.bkng_det?.usr_curr_code} {jsonDAta.bkng_det?.total_amount+adt_gst+chd_gst}</span></Col>
              <Col><span className="confirSum"> {pay.status}</span></Col>
              </Row>
              </div>))}
            </div>
            </div>         
              </div>  
      
        </Container>
        <Footer/>
        </>
     );




    }
    
    export default VisaSummary;