
import React, { useState, useEffect , useReducer,useContext ,useRef , useCallback} from 'react';
import { Form, Button, Col, Row , Alert,Modal} from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Fifacup from '../../../../../src/client/assets/images/fifaworlcup.png'
import AddGuestDetails from './AddGuestDetails';
import DateUtils from '../../commonUtils/DateUtils';
import { useHistory } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import log from "loglevel";
import PredectiveSearch from '../../../services/hotelpredictive';
import { Context } from "../../../../App";
import { Scrollbars } from 'react-custom-scrollbars';
import debounce from 'lodash.debounce';

const initialState = {
  htlData:[],nights:1,pkgRate:false, isSearching:false,rmCount:1,guestCount:1,hotelData: [],selHotel:[],notiMessage:"", notiMessageShow:false, notiVarient:"danger",roomsInfo:[],ismodify:false,flow:"Results",showResults:false,destName:"",hotelPredectiveDropDown:false, _startDateCal: false, _endDateCal: false,isSearching:false,  openArivalDate: new Date(), selDest: [], cleardb: false,showInboundPopup:false
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
          ...state,      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function HotelSearchPart(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const history = useHistory();
  const [addGuests, setAddGuests,] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endtDate, setEndtDate] = useState(DateUtils.addDatePlusOne(new Date()));
  const [checkOutDate, setCheckOutDate] = useState(DateUtils.addDatePlusOne(new Date()));
  const [originIsFocus, setOriginIsFocus,] = useState(false);
  const [enableSearch, setEnableSearch,] = useState(true);
  const [packageRate, setPackageRate] = useState(true);
  const [isLoad, setIsLoad,] = useState(false);


  const destination = useRef();
  useEffect(()=>{
if(props.cleardb !== undefined && props.cleardb){
  dispatch({ type: "cleardb", payload: true })
}
  }, [])
  useEffect(() => {
       if(props.srequest!==undefined && props.srequest.htlData!==undefined){
         let obj=props.srequest;
        dispatch({ type: "ismodify", payload: true })
        dispatch({ type: "flow", payload: props.flow })
        if(obj.htlData[0].type!=="city" && obj.htlData[0].description!==undefined && obj.htlData[0].description.type!=="city"){
          dispatch({ type: "destName", payload: obj.htlData[0].description+", "+props.srequest.htlData[0].country})
        }else{
          if(obj.htlData[0].description!==null){
            dispatch({ type: "destName", payload: obj.htlData[0].description})
          }else{
            dispatch({ type: "destName", payload: obj.htlData[0].text+", "+obj.htlData[0].country})
          }
        }
        dispatch({ type: "selHotel", payload: obj.htlData })
        dispatch({ type: "hotelData", payload: obj.htlData })
        dispatch({ type: "roomsInfo", payload: obj.roomsInfo })
        dispatch({ type: "rmCount", payload: obj.roomsInfo.length })
        dispatch({ type: "guestCount", payload: obj.guestcnt })
        dispatch({ type: "nights", payload: obj.nights })
        dispatch({ type: "pkgrate", payload: obj.pkgrate })
        dispatch({ type: "flowType", payload: obj.flowType })
        setStartDate(DateUtils.convertNewToDate(obj.cinui))
        setEndtDate(DateUtils.convertNewToDate(obj.coutui))
        if(obj.package===1){
          setPackageRate(true)
        }else{
          setPackageRate(false)
        }
       }
  }, [props.srequest]);

  useEffect(() =>{
    if(props.respAvail){
      setIsLoad(false)
    }
  },[props.respAvail])
useEffect(() => {
if(JSON.parse(window.sessionStorage.getItem("isPack"))){
  setPackageRate(true)
}
},[JSON.parse(window.sessionStorage.getItem("isPack"))])
  const handleChange = (e) => {
    if (addGuests) {
      setAddGuests(false)
    } else {
      setAddGuests(true)
    }
  }

  const handleChangePkg = (e) => {
    //For Package Rate
    if(packageRate){
      setPackageRate(false)
    }else{
      setPackageRate(true)
    }
  }

  const closeGust=()=>{
      setAddGuests(false)
  }


    //=====================================
  // This function will open the Onward Calendar on Blur or Destination field
  //=====================================
  const openStartDateCal = () => state._startDateCal.setOpen(true);
  const openEndDateCal = () => state._endDateCal.setOpen(true);

  // OnChange event we will call this and update the state
  const handleStartDate = date => {
    setStartDate(date);
    setCheckOutDate(DateUtils.addDatePlusOne(date));
    openEndDateCal();
    dispatch({ type: 'openArivalDate', payload: date })
    if(endtDate!==undefined && date!==undefined && endtDate!==""){
      var diff_In_Time = endtDate.getTime() - date.getTime();
      var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
      dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
      setAddGuests(true);
    }

  }

  // OnChange event we will call this and update the state
  const handleCheckout = date => {
    setEndtDate(new Date(date));
    if(startDate!==undefined && date!==undefined && startDate!==""){
      var diff_In_Time = date.getTime() - startDate.getTime();
      var diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
      dispatch({ type: "nights", payload: Math.round(diff_In_Days) })
      setAddGuests(true);
    }
  }

  const searchHotel=()=>{
    if(validateSearch()){
     if(state.selHotel[0].countryCode!=="QA"){
      handleHotelSearch();
     }else{
        let checkCard = DateUtils.checkHayyCardDate(startDate,"QA");
        if(checkCard){
          dispatch({ type: "showInboundPopup", payload: true })
        }else{
          handleHotelSearch();
        }
     }
    }

  }



  //=====================================
// This function will call the service for Search response
//=====================================
const handleHotelSearch = () => {
  dispatch({ type: "showInboundPopup", payload: false })
    try {
        setIsLoad(true);
        let rooms=[]
        if(state.roomsInfo.length===0){
          const payload={
            rmno:1,
            adt:1,
            chd:0,
            chdArray:[],
            chdAges:[]
          }
          rooms.push(payload)
        }
        let pkg=0;
        if(packageRate){
          pkg=1;
        }
        let regionType="";
        if(context.logindata!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type!==""){
          regionType=context.logindata.region_type;
        }else{
          regionType=context.logindata.cur==="QAR"?"Qatar":"India";
        }
      const payload={
        "cin":DateUtils.convertToDateHifen(startDate),
        "cout":DateUtils.convertToDateHifen(endtDate),
        "cinui":DateUtils.prettyDate(startDate,'ddd, DD MMM, YYYY'),
        "coutui":DateUtils.prettyDate(endtDate,'ddd, DD MMM, YYYY'),
        "nights":state.nights!==0?state.nights:1,
        "htlData":state.selHotel,
        "cid":context.logindata.cid,
        "bid":context.logindata.bid,
        "butype":context.logindata.butype,
        "package":1,
        "flowType":"DIRECT",
        "roomsInfo":(state.roomsInfo!==undefined && state.roomsInfo.length!==0)?state.roomsInfo:rooms,
        "guestcnt":state.guestCount,
        "ismodify":state.ismodify,
        "cur":context.logindata.cur,
        "region_type":regionType
      }
      if(state.flow==="Results"){
        sessionStorage.setItem("CMPID", context.logindata.cid)
        history.push({
          pathname: '/ezytrip/bookings/hotel/results',
          state: {
            req: payload
          }
        });
      }else{
        history.push({
          pathname: '/ezytrip/bookings/hotel/review',
          state: {
            req: payload
          }
        });
      }
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
}

//=====================================
// This function will get the Source data
//=====================================
const handleOrigin = (input, e) => {
  state.selHotel=[];
  //predectiveCall(e)
}

//=====================================
  // This function will get the Source data
  //=====================================
const handleChangOrigin = (e) => {
  const { id, value } = e.target;
  if(value.length > 0){
    dispatch({type: "cleardb", payload: true})
  }
  else{
    dispatch({type: "cleardb", payload: false})
  }
  dispatch({ type: "isSearching", payload: true })
  if(value.length>2){
    let obj ={
      textValue:value
    }
    debouncedSave(obj);
  }else{
    dispatch({ type: 'hotelData', payload: [] })
  }
  dispatch({ type: id, payload: value });
  dispatch({ type: "isSearching", payload: false })
}

const clearDestination = ()=>{
  dispatch({type: "destName", payload: ""})
  dispatch({type: "cleardb", payload: false})
}
// highlight-starts
const debouncedSave = useCallback(
  debounce(obj => predectiveCall(obj.textValue), 500),
  [], // will be created only once initially
);

//=====================================
// This function will call the service to get the Hotel data
//=====================================
const predectiveCall = (input) => {
    dispatch({ type: "hotelPredectiveDropDown", payload: false })
    PredectiveSearch.retreieveSearch(input).then((data) => {
      dispatch({ type: "hotelData", payload: data.data.data!== "" ? [data.data.data] : [] })
      dispatch({ type: "showResults", payload: true })
      dispatch({ type: "hotelPredectiveDropDown", payload: true })

    });

}

//=====================================
// This function will get the Source data
//=====================================
const handleOnOrigin = (obj,type) =>() => {
  companySelect();
  if(obj!==undefined){
    dispatch({ type: "hotelPredectiveDropDown", payload: false })
    obj.type=type;
    if(type!=="city"){
      dispatch({ type: "destName", payload: obj.description+", "+obj.country})
    }else{
      if(obj.description!==null){
        dispatch({ type: "destName", payload: obj.description})
      }else{
        dispatch({ type: "destName", payload: obj.text+", "+obj.country})
      }

    }

    dispatch({ type: "selHotel", payload: [obj] })
    openStartDateCal();
    errorRemoveBlur();
  }
}
const activeOrigin=(event)=>{
  let el = event.target;
      el.closest(".addInput").classList.add('activeSerField')
}

const refOutClick = useOnclickOutside(() => {
  setAddGuests(false);
  dispatch({ type: "hotelPredectiveDropDown", payload: false })
  //document.querySelector('.origin').classList.remove('activeSerField');
  document.querySelector('.datePick').classList.remove('activeSerField');

});

const errorRemoveBlur = () => {
  dispatch({ type: "notiMessageShow", payload: false })
  document.querySelector('.origin').classList.remove('errorSerField');
};

//=====================================
// This function will validate the Search Fields data exists or not
//=====================================
const validateSearch = () => {
  if (state.destName==="" && state.flow==="Results") {
    document.querySelector('.origin').classList.add('errorSerField');
    dispatch({ type: "notiMessage", payload: "Please enter Destination." })
    dispatch({ type: "notiVarient", payload: "danger" })
    dispatch({ type: "notiMessageShow", payload: true })
    hidemessage();
    return false
  }

  if (startDate === "") {
    document.querySelector('.datePick').classList.add('errorSerField');
    dispatch({ type: "notiMessage", payload: "Please select Check-In Date." })
    dispatch({ type: "notiVarient", payload: "danger" })
    dispatch({ type: "notiMessageShow", payload: true })
    hidemessage();
    return false
  }
  if (endtDate === "") {
    document.querySelector('.datePick').classList.add('errorSerField');
    dispatch({ type: "notiMessage", payload: "Please select Check-Out Date." })
    dispatch({ type: "notiVarient", payload: "danger" })
    dispatch({ type: "notiMessageShow", payload: true })
    hidemessage();
    return false
  }
  if(startDate!=="" && endtDate!=="" && DateUtils.convertToDateHifen(startDate)===DateUtils.convertToDateHifen(endtDate)){
    dispatch({ type: "notiMessage", payload: "Check-In and Check-Out dates cannot be same" })
    dispatch({ type: "notiVarient", payload: "danger" })
    dispatch({ type: "notiMessageShow", payload: true })
    hidemessage();
    return false
  }
  if(context.logindata.utype==="att_user"){
    if(context.logindata.cid===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Company' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
  }
  if(state.selHotel.length===0){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please enter proper Destination' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
}
if(state.nights < 1){
  dispatch({ type: 'notiMessageShow', payload: true })
  dispatch({ type: 'notiMessage', payload: 'Please select proper Check-In and Check-Out dates' })
  dispatch({ type: 'notiVarient', payload: 'danger' })
  hidemessage();
  return false;
  }
  return true;
}
const companySelect = () =>{
  if (context.logindata.utype === "att_user") {
    if (context.logindata.cid === 0) {
      document.querySelector('.domin').classList.add('dominFocus');
    }else{
      document.querySelector('.domin').classList.remove('dominFocus');
    }
  }
}

const hidemessage = () => {
  setTimeout(function () {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
  }, 5000);
}

const activeDate=(event)=>{
  let element = document.querySelector('.datePick');
     element.classList.add('activeSerField')
}

const guestCountupdate=(data)=>{
  dispatch({ type: "guestCount", payload: data })
}

const roomCountupdate=(data)=>{
  dispatch({ type: "rmCount", payload: data })
  }

const roomInfoupdate=(data)=>{
  dispatch({ type: "roomsInfo", payload: data })
 }

const getCictyName=(city)=>{
  if(city!==undefined && city!==null){
    let name=city.split(",");
    return name[1];
  }
}

const closePopup =()=>{
  dispatch({ type: "showInboundPopup", payload: false })
}


  return (
    <>
          <Form.Group>
          {state.notiMessageShow &&
         <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
         }
         {/* <div className="labeinsuRadio d-flex align-items-start justify-content-start">
                  <label>
                    <input
                      type="radio"
                      name="name"
                      checked={packageRate}
                      value={packageRate}
                      onChange={handleChangePkg}
                    />
                    Package Rates
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="name"
                      checked={!packageRate}
                      value={!packageRate}
                      onChange={handleChangePkg}
                    />
                    Standalone Rates
                  </label>
                </div> */}
            <div className="schangesearch">
              <>
              {state.flow==="Results" &&
              <div className="destination">
              <Form.Group as={Col} controlId="destName"  ref={refOutClick}>
              <div className={"addInput origin" + (originIsFocus ? " active" : "")}>

              <Form.Label><span className="ezyIcon icon-map"></span>Destination</Form.Label>
              <Form.Control type="text" autoComplete="off" autoFocus title={(state.destName)} value={state.destName} placeholder="City or Hotel Name"  onChange={handleChangOrigin} ref = {destination} />
              {state.cleardb  && <Button
                size="sm"
                className="predictiveClear ezyIcon icon-close"
                onClick={clearDestination}
                variant="link">
              </Button>
            }
               </div>
               {state.hotelPredectiveDropDown &&

             <div className={state.hotelData!==undefined && state.hotelData.length!==0 && state.hotelData[0].city.length!==0 ? "pdtSerchPart":"pdtSerchPart nocity"}>
              <Scrollbars style={{ height: 300 }}>
                <div className="searchContent">
             {state.hotelData!==undefined && state.showResults && state.hotelData.length!==0 &&

                <>
                {/* {state.isSearching && <p className="searchig">Searching...</p>} */}
                {state.hotelData!==undefined && state.hotelData[0].city.length===0 && state.hotelData[0].landmark.length ===0 && state.hotelData[0].area.length ===0 && state.hotelData[0].property.length ===0 && <p className="nomachFound">
                  No matches found.
                  </p>}
                  <Row>
                {state.hotelData[0].city!==undefined && state.hotelData[0].city.length!==0 &&
                  <Col className="pr-2">
                  {state.hotelData[0].city.map((cty, index) => (
                    <>
                    {index===0 &&
                    <>
                      <h4 className="typ">City</h4>
                      </>
                    }
                    <div className="plc ezyIcon icon-map" onClick={handleOnOrigin(cty,"city")}>
                    {cty.description!==null &&
                      <span  className="plcNm" title={cty.description}> {cty.description}</span>
                    }
                    {cty.description==null &&
                      <span  className="plcNm"title={cty.text + cty.country} > {cty.text}, {cty.country}</span>
                    }
                    {/* <span className="plcTyp">City</span> */}

                    </div>
                    </>
                  ))}

                  </Col>
                  }

                  {/* {state.hotelData[0].landmark!==undefined && state.hotelData[0].landmark.length!==0 &&
                  <Col className="pr-2">
                  {state.hotelData[0].landmark.map((land, index) => (
                    <>
                    {index===0 &&
                    <>
                      <h4 className="typ">Landmark</h4>
                      </>
                    }
                    <div className="plc" onClick={handleOnOrigin(land,"land")}><span  className="plcNm"> {land.text}, {land.cityName}, {land.country}</span>
                    </div>
                    </>
                  ))}

                  </Col>
                  }

                {state.hotelData[0].area!==undefined && state.hotelData[0].area.length!==0 &&
                  <Col className="pr-2">
                  {state.hotelData[0].area.map((obj, index) => (
                    <>
                    {index===0 &&
                    <>
                      <h4 className="typ">Area</h4>
                      </>
                    }
                    <div className="plc" onClick={handleOnOrigin(obj,"area")}><span  className="plcNm"> {obj.text}, {obj.cityName}, {obj.country}</span>
                    </div>
                    </>
                  ))}

                  </Col>
                  } */}

                {state.hotelData[0].property!==undefined && state.hotelData[0].property.length!==0 &&
                   <Col className="pl-2">
                  {state.hotelData[0].property.map((hotel, index) => (
                    <>
                    {index===0 &&
                    <>
                       <h4 className="typ">Hotel</h4>
                      </>
                    }
                    <div className="plc ezyIcon icon-hotel" onClick={handleOnOrigin(hotel,"property")}><span  className="plcNm" title={hotel.description + hotel.country} >  {hotel.description}, {hotel.country} </span>
                    </div>
                    </>
                  ))}

                  </Col>
                  }

                </Row>
                </>
             }
             {state.hotelData.length===0 &&
             <h4 className="typ">No Results Found</h4>
             }
             </div>
          </Scrollbars>
             </div>

              }
          </Form.Group>
              </div>
            }
           </>
           <div className="addInput datePick datePicker d-flex">
              <div className="from">
                <Form.Label><span className="ezyIcon icon-newcalendar"></span> Check-In</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  //value={state.dd}
                  onChange={handleStartDate}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  //maxDate={endtDate}
                  startDate={startDate}
                  endDate={endtDate}
                  onFocus={e => e.target.blur()}
                  onCalendarOpen={activeDate}
                  ref={(c) => state._startDateCal = c}
                  popperModifiers={{
                    flip: {
                        behavior: ['bottom'] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                  />
                  </div>
                <div className="to">
                <Form.Label><span className="ezyIcon icon-newcalendar"></span> Check-Out</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={endtDate}
                  openToDate={state.openArivalDate}
                  onChange={handleCheckout}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  minDate={startDate == "" ? new Date() : startDate}
                  startDate={startDate}
                  endDate={endtDate}
                  onCalendarOpen={activeDate}
                  onFocus={e => e.target.blur()}
                  ref={(c) => state._endDateCal = c}
                  popperModifiers={{
                    flip: {
                        behavior: ['bottom'] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                  />
                </div>
                </div>
            <div className="night">
              <Form.Label><span className="ezyIcon icon-night"></span>Nights</Form.Label>
              <Form.Control type="text" value={state.nights} readOnly placeholder={state.nights} autoComplete="off"/>
            </div>

            <div className="rooms">
            <Form.Group as={Col} className="traveller p-0 m-0" ref={refOutClick}>
            <div className="rooms"  >
              <Form.Label><span className="ezyIcon icon-night"></span>Rooms & Guest</Form.Label>
            <Form.Control onClick={handleChange} type="text" placeholder={state.rmCount + " Room, " +state.guestCount +" Guest(s)"} autoComplete="off" readOnly />
            </div>
            {addGuests && <AddGuestDetails sendGuseCount={guestCountupdate} sendRoomsCount={roomCountupdate} sendRoomsInfo={roomInfoupdate} roomsInfo={state.roomsInfo} closeBtn={closeGust} ismodify={state.ismodify}/>}
          </Form.Group>
{/*
              <Form.Label><span><Icon className="flight" size={10} icon="person" /></span>Rooms & Guest</Form.Label>
              <Form.Control type="text" placeholder="05" autoComplete="off"/> */}
            </div>
            <Form.Group className="searchHere m-0" as={Col}>
            <Button
                size="sm"
                className="changeSearchBtn"
                onClick={searchHotel}
                variant="primary">
                  <span className={"d-flex loading ezyIcon icon-newsearch " + (isLoad ? 'progress-bar-striped progress-bar-animated' : '')}>SEARCH{isLoad ? <> <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> </> : <> </>} </span>

          </Button>
          </Form.Group>
          </div>
          </Form.Group>

          <Modal
        show={state.showInboundPopup}
        //onHide={handlePriceClose}
        className="importPNRMdl comingtoFifa"
        backdrop="static"
        keyboard={false}
      >
         <Modal.Header>
                          <Modal.Title>Coming to Qatar?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <img src={Fifacup} />
              <p>You need Hayya card to enter the state of Qatar between 1st Novmber 2022 - 23rd December 2022 to access the stadiums.(Non Citizens & RP Holders)</p>
             <span><a className="d-inline-flex" href="https://www.qatar2022.qa/en/coming-to-qatar" target="_blank">For more info : <span> www.qatar2022.qa/en/coming-to-qatar</span></a></span>
              </Modal.Body>
        <Modal.Footer className="justify-content-end">
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={closePopup}
            >No</Button>
            <Button
              size="xs"
              onClick={handleHotelSearch}
              variant="outline-primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}


export default HotelSearchPart;
