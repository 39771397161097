import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import BookingDetailsPdf from './BookingDetailsPdf';
import SegmentDetailsPdf from './SegmentDetailsPdf';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import { Col, Row } from "react-bootstrap";

const initialState = { mtaList:[]};
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };
const FlightIndigoInvoicePdf = React.forwardRef((props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cur, setCur] = useState(props.userCurrency)
  const [paxList, setPaxList] = useState([])
  const [fareList, setFareList] = useState([])
  const [refundList, setRefundList] = useState([])
  const [onwardList, setOnwardList] = useState([])
  const [returnList, setReturnList] = useState([])


  useEffect(() => {
    if (props.response !== undefined) {


      for (let r in props.response.data) {
        let data = props.response.data[r];
        if(isNotNull(data['refund_det'])) {
          setPaxList(data.refund_det[0].price[0].pax_name);
          setRefundList([data.refund_det[0].price[0]])
        }
        if (isNotNull(data['seg_det'])) {
          let onwards = data.seg_det.filter((obj) => obj.journey_type === "O");
          setOnwardList(onwards);
          let returns = data.seg_det.filter((obj) => obj.journey_type === "R");
          if(returns!==undefined && returns[0]!==undefined){
          setReturnList(returns);
          }
        }
    }

    }
  }, [props.response])


  return (
    <div className="bg-gray-200 p-6" title={"FLIGHT" + props.response.invoicetype} ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
            <h2 className="mb-4">
                 FLIGHT {props.response.invoicetype}
                </h2>
                </div>
               {props.bk_det !== undefined &&
               <BookingDetailsPdf bk_det={props.bk_det} invoicetype={props.response.invoicetype} company_logo={props.compLogo} parent_id={props.parent_id} selectedTab={props.selectedTab} changeStatus={props.changeStatus}/>
               }

              <div className="pdfflightDetails">
              {(paxList.length!==0 || fareList.length!==0) &&
                <div>
                <h3 className="pdfSubTitl">
                Flight Details
                {/* - Status :{"Confirmed"} */}
                </h3>
                {onwardList && onwardList[0]!==undefined &&
                <SegmentDetailsPdf  list={onwardList} supplier={"4"} airport_data={props.airport_data} type={"Onward"}/>
                }
                {returnList && returnList[0]!==undefined &&
                <SegmentDetailsPdf list={returnList} supplier={"4"} airport_data={props.airport_data} type={"Return"}/>
                }
                </div>
              }
              </div>
              {paxList.length!==0 &&
              <div className="pdffare">
               <h3 className="pdfSubTitl">
               Passenger Details ({props.response.sel_pnr})
              </h3>
              <Row>
                 {paxList.map((pax, index) => (
                         <React.Fragment key={index}>
                             <div className="passengerList indiGo">

                                 <Col><span className='ezyIcon icon-tick'><span className='paxnaMe ml-2'>{pax}</span></span></Col>

                                </div>
                         </React.Fragment>
                     ))}
                      </Row>
              </div>
              }
           {refundList.length!==0 &&
            <div className="pdffare">
               <h3 className="pdfSubTitl">
              Refund Details ({cur})
              </h3>
                 <div className="header">
                     <Row>
                         <Col>Fare paid</Col>
                         <Col>Supplier Fee</Col>
                         <Col>Service Fee</Col>
                         <Col>Airline Charges</Col>
                         <Col>Refund Markup</Col>
                         <Col>Refund Fee</Col>
                         <Col>Total Refund</Col>
                     </Row>
                    </div>

                     {refundList.map((pr, index) => (
                         <React.Fragment key={index}>
                             <div className="passengerList">
                             <Row>
                                 <Col>{pr.totalfare}</Col>
                                 <Col>{pr.supsfee}</Col>
                                 <Col>{pr.bkservice}</Col>
                                 <Col>{pr.aircharge}</Col>
                                 <Col>{pr.canmarkup}</Col>
                                 <Col>{pr.canservice}</Col>
                                 <Col>{pr.subtotal}</Col>
                                </Row>
                                </div>
                         </React.Fragment>
                     ))}

              </div>
           }

          </div>
        </div>
        </div>
        <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>

      </div>
  );
});

export default FlightIndigoInvoicePdf;
