
import React, { useCallback, useEffect, useState } from 'react';
import Tabs from '../../common/tabs/Tabs';
import CommonFlightSearch from './CommonFlightSearch';
import MultiCitySearch from './MultiCitySearch';


function FlightSearch(props) {
  const [source, setSource] = useState([])
  const [destination, setDestination] = useState([])
  const [tablist, setTablist] = useState({
    active: 'RoundTrip'
  });


  useEffect(() => {
    if (props.srequest !== undefined && props.flowType !== undefined) {
      if (props.srequest.tt === 1 && props.tt !== 3) {
        setTablist({ active: 'OneWay' })
      } else if (props.srequest.tt === 2 && props.tt !== 3) {
        setTablist({ active: 'RoundTrip' })
      } else if (props.srequest.tt === 3 || props.tt === 3) {
        setTablist({ active: 'MultiCity' })
      }
    }
  }, [props.srequest !== undefined]);

  const retValue = useCallback((type) => {
    if (type === "Round") {
      setTablist({ active: 'RoundTrip' })
    }
  }, []);

  const srcObj = (obj) => {
    setSource(obj);
  };

  const destObj = (obj) => {
    setDestination(obj);
  };

  const srcMultiObj = (obj) => {
    setSource(obj);
  };

  const destMultiObj = (obj) => {
    setDestination(obj);
  };

  const content = {
    RoundTrip: <>{props.flowType !== "REISSUE" && <CommonFlightSearch stopButtonLoad={props.stopButtonLoad} sendSrc={srcObj} sendDest={destObj} srcMultiData={source} destMultiData={destination} srequest={props.srequest} sendReturn={retValue} tt={2} load={props.load} onClickHandler={props.onClickHandler}/>}</>,
    OneWay: <>{props.flowType !== "REISSUE" && <CommonFlightSearch stopButtonLoad={props.stopButtonLoad} sendSrc={srcObj} sendDest={destObj} srcMultiData={source} destMultiData={destination} srequest={props.srequest} sendReturn={retValue} tt={1} load={props.load} onClickHandler={props.onClickHandler}/>}</>,
    MultiCity: <>{<MultiCitySearch stopButtonLoad={props.stopButtonLoad} sendMultiSrc={srcMultiObj} sendMultiDest={destMultiObj} srcData={source} destData={destination} srequest={props.srequest} tt={3} load={props.load} dateAvailable={props.dateAvailable} routeAvailable={props.routeAvailable} flowType={props.flowType} oldCls={props.oldCls} />}</>
  };

  return (
    <>
      <div className="searchTab">
        <div className="tabLink flightSearchFrom">
          <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
            {props.flowType !== "REISSUE" && <span key="RoundTrip">Round Trip</span>}
            {props.flowType !== "REISSUE" && <span key="OneWay">One Way</span>}
            <span key="MultiCity">Multi City</span>
          </Tabs>
          <div className="tabContent">
            {content[tablist.active]}
          </div>
        </div>
      </div>
    </>
  )
}


export default FlightSearch;
