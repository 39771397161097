import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { Col, Form, Button,Tab, Tabs, Modal, Table, Alert } from "react-bootstrap";
import Select from "react-dropdown-select";
import log from "loglevel";
import { Context } from "../../../../../App";
import {creation,update,deletePromoFMap, retrieveCrossSell} from './operations'
import SalesChannel from "../../common/SalesChannel";
import RegionType from "../../common/RegionType";
import { getCompaniesList, getCompanyList, getCompanyTAList, getSTACompanyList } from "../../hotelMarkup/operations";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import Utilities from "../../../commonUtils/Utilities";
import PickList from "../../../common/pickList/PickList";
import { confirmBranchDlg } from "../../../common/confirmDialogue";
import HotelSelected from "../../common/HotelSelected";


const initialState = {
    update_id:"",crosel_nm:"",per_room:"",no_nights:"",per_adt:"", cnt_id:"", city_id:0,cnt_name:"",city_name:"", resp_city_name:"", supp: [], atyp: false, btyp: true, userType:"",utype:"", ctyp: "ATT", className: null, countrySelected: [], citySelected: [],retrieveResp:[],
    aptype:"Branch",selBrnch:[], branchMenu:[],branches:[], bid:[],compData:[],selCompny:[],cmpid:[], data:[], notiVarient:"",notiMessage:"",notiMessageShow:false,enableEdit:false,regionSelected:false, region_type:"",edit_salesChanel:"",sales_channel:"",
    include: true, star_rating: "",star_rating_list: [],  markupId:0,enableUpdate:false,edited_applied_hotels:[],markupId:0, supplierError: false, markupError: false,reset:false,edit_applDates:[],region_details:"",edit_region:"",isItineraryLoading:false,
    isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,edit_sales_channel:"", enableDevices:false,act:true,per_room_list:[],no_nights_list:[],per_adt_list:[]
  }

  let markupFareArray = [];

  // Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
      case 'clear':
          return {
              ...state,
          };
      case 'editCross':
          return Object.assign(state, action.payload);
      default:
          return { ...state, [action.type]: action.payload };
  }
};

  
  function CreateCrossSell(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [companydata, setCompanyPick] = useState([]);
    const [companyType, setCompanyType] = useState(true);
    const [enApplicable, setEnApplicable] = useState(true);
    const [sta, setSTA] = useState(false);
    const [fares, setShowFareList] = useState({ faresList: [] });
    const [data, setData] = useState([])
    const [disableButton, setDisableButton] = useState(false);
    const [noOfNights, setNoOfNights] = useState([])



    const supplier = [{ label: "Expedia", value: "0" }]
    const star_ratings = [{ label: "3", value: "3" },{ label: "4", value: "4" },{ label: "5", value: "5" },{ label: "7", value: "7" }]
    const noOfRoomsList = [{label:"Select",value:""},{ label: "1", value: "1" },{ label: "2", value: "2" },{ label: "3", value: "3" },{ label: "4", value: "4" },{ label: "5", value: "5" },{ label: "6", value: "6" },{ label: "7", value: "7" },{ label: "8", value: "8" }]
    const noOfAdtList = [{label:"Select",value:""},{ label: "1", value: "1" },{ label: "2", value: "2" },{ label: "3", value: "3" },{ label: "4", value: "4" },{ label: "5", value: "5" },{ label: "6", value: "6" }]
   
    
    useEffect(()=>{
      var list = [{label:"Select",value:""}];
      for (var i = 1; i <= 60; i++){
      if(String(i).length===1){
        i = i;
      }
      list.push({label:i,value:i});
      }
      setNoOfNights(list);
    },[])
    
    useEffect(() => {
        if (context.logindata !== undefined) {
          dispatch({ type: 'userType', payload: context.logindata.utype })
          dispatch({ type: 'userId', payload: context.logindata.uid })
          if (context.logindata.utype !== "ta_user") {
            dispatch({ type: 'enableOfcs', payload: true })
          } else {
            dispatch({ type: 'ctyp', payload: "TA" })
            dispatch({ type: 'utype', payload: "ta_user" })
          }
        }
      }, [context.logindata]);

      useEffect(()=>{
        if( state.sales_channel==="B2C" && state.region_type!=""){
          let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
          AttCommonSerivce.att_companies(pyload).then(response => {
            let authData = []
            if (response.data.suc && response.data.res && response.data.res.data) {
    
              for (let val of response.data.res.data) {
                let auth = {
                  "value": val.value,
                  "label": val.label,
                  "sales_channel": val.sales_channel
                }
                authData.push(auth)
              }
              let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_channel)
              setCompanyPick(cmpanis)
              dispatch({ type: "comapny_temp_List", payload: authData });
              return
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
    
        }
      },[ state.sales_channel,state.region_type])

      useEffect(() => {
        if (props.sendEditData && props.sendEditData !== undefined) {
          convertEditCross(props.sendEditData);
        }
      }, [props.sendEditData]);

      useEffect(() => {
        if (state.sales_channel==="B2B" && state.region_type!="") {
        let req=""
        if(context.logindata.is_admin){
            req={"region_type":state.region_type}
          }else{
            req={"region_type":state.region_type,"uid":context.logindata.uid}
          }
            AttCommonSerivce.loadBranches(req).then(response => {
                if (response.data.suc){
                    let branchesList = response.data.res.map((branch) =>
                    <option value={branch.value}>{branch.label}</option>
                    );
                  //   state.branchMenu = branchesList
                    dispatch({ type: 'branchMenu', payload: branchesList})
                    dispatch({ type: "branches", payload: response.data.res });
                }
              }).catch(function (error) {
                log.error(
                  "Exception occured in Loadi branches based on Region function---" + error
                );
          });        
        }
      }, [state.sales_channel && state.region_type!=""]);
      
      /**
       * @author: Shaik
       * @description: This function will Edit the markup Based on the Markup Id
       * @date:14-09-2020
       * @function : convertEditCross
       */
      const convertEditCross = (marobj) => {
        var obj = marobj.data.res;
        console.log(obj)
        dispatch({ type: "editCross", payload: obj });
        for (let suplr of supplier){
          if (suplr.value==obj.supp){
        dispatch({ type: "supp", payload: [{value:suplr.value, label:suplr.label}] });
       }
      }
      let starRatingArray = obj.star_rating.split(','); 
      let convertedStarRating = starRatingArray.map(value => {     return { label: value, value: value }; });
      dispatch({ type: "star_rating_list", payload: convertedStarRating});
      dispatch({ type: "edit_region", payload: obj.region_type});
      dispatch({ type: "countrySelected", payload: [{label:obj.cnt_nm, value:obj.cnt_id}]});
      dispatch({ type: "citySelected", payload: [{label:obj.city_nm, value:obj.city_id}]});
      dispatch({ type: "cnt_name", payload: obj.cnt_nm});
      dispatch({ type: "city_name", payload: obj.city_nm});
      dispatch({ type: "resp_city_name", payload: obj.city_nm});
      let a = String(obj.per_adult).split(','); 
      let aa = a.map(value => {     return { label: value, value: value }; });
      let b = String(obj.per_night).split(','); 
      let bb = b.map(value => {     return { label: value, value: value }; });
      let c = String(obj.per_room).split(','); 
      let cc = c.map(value => {     return { label: value, value: value }; });
       
       
      dispatch({ type: "per_adt_list", payload: aa});
      dispatch({ type: "no_nights_list", payload: bb});
      dispatch({ type: "per_room_list", payload: cc});


        dispatch({ type: "edit_salesChanel", payload: obj.sales_channel });
        dispatch({ type: "sales_channel", payload: obj.sales_channel });
        dispatch({ type: "enableUpdate", payload: true });
        if (obj.act == 1) {
          dispatch({ type: "act", payload: true });
        } else {
          dispatch({ type: "act", payload: false });  
        }
        dispatch({ type: 'isDeviceAll', payload: obj.isDeviceAll })      
        dispatch({ type: 'isB2cWeb', payload: obj.isB2cWeb })      
        dispatch({ type: 'isB2cApp', payload: obj.isB2cApp })      
        dispatch({ type: 'isMWeb', payload: obj.isMWeb })    
        const cmpnyname= obj.cmpid.map(cid=>({
            comid:cid.id,
            name:cid.name,
            id:cid.company_id
          }))
        dispatch({ type: 'selCompny', payload: cmpnyname })  
        dispatch({ type: 'update_id', payload: obj.id })  
        dispatch({ type: 'bid', payload: obj.bid }) 
        if(obj.sales_channel=="B2B"){     
        dispatch({ type: 'selBrnch', payload: obj.bid })
      } else{
        dispatch({ type: 'selBrnch', payload: [] })
        } 
        fares.faresList= obj.cmpid.map((cid, id)=>({
          company:cid.name,
          id:cid.company_id,
          borcname:obj.bid[0]?.name!=undefined?obj.bid[0]?.name:"N/A"
        }))
        window.scrollTo(0, 0);
      };
      
      const handleChange = (e)=>{
        const { id, value } = e.target;
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        const re = /^[0-9\b]+$/;
        const en=/^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/i
        const ar=/^[\u0600-\u06FF0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/i
      
        if(id == "crosel_nm"){
          if ( e.target.value === '' || en.test(e.target.value)) {
          dispatch({type: "crosel_nm", payload : value})
          }
        }
        if(id == "offer_name_ar"){
          if ( e.target.value === '' || ar.test(e.target.value)) {
          dispatch({type: "offer_name_ar", payload : value})
          }
        }
        if(id == "offer_desc_en"){
          if ( e.target.value === '' || en.test(e.target.value)) {
          dispatch({type: "offer_desc_en", payload : value})
          }
        }
        if(id == "offer_desc_ar"){
          if ( e.target.value === '' || ar.test(e.target.value)) {
          dispatch({type: "offer_desc_ar", payload : value})
          }
        }
        if(id == "offer_det_en"){
          if ( e.target.value === '' || en.test(e.target.value)) {
          dispatch({type: "offer_det_en", payload : value})
          }
        }
        if(id == "offer_det_ar"){
          if ( e.target.value === '' || ar.test(e.target.value)) {
          dispatch({type: "offer_det_ar", payload : value})
          }
        }
        if (id =="disc" ){
         if ( e.target.value === '' || re.test(e.target.value)) {
          dispatch({type:"disc", payload: e.target.value})}
        }
        if (id =="no_bkng_allow" ){
         if ( e.target.value === '' || re.test(e.target.value)) {
          dispatch({type:"no_bkng_allow", payload: e.target.value})}
        }
        if (id =="max_bkng_allow" ){
         if ( e.target.value === '' || re.test(e.target.value)) {
          dispatch({type:"max_bkng_allow", payload: e.target.value})}
        }
        if (id =="min_purchase" ){
         if ( e.target.value === '' || re.test(e.target.value)) {
          dispatch({type:"min_purchase", payload: e.target.value})}
        }
        if (id === "act") {
          if (state.act) {
            dispatch({type:"act", payload: false})    
          } else {
            dispatch({type:"act", payload: true})  
          }
        }
      }

      const applicableDates = (ad) => {

        console.log('applicableDates-----------' + JSON.stringify(ad))
        state.applDates.push(ad);
        dispatch({type:"reset",payload:false})
        dispatch({ type: 'applDates', payload: state.applDates })
      }
    
      const updateApplDates = (data) => {
        dispatch({type:"reset",payload:false})
        dispatch({ type: 'applDates', payload: data })
        // setDates({datesList : [data]})
      }
    

      const updateSalesChanel=(salesChanel)=>{
        if (!state.enableUpdate){
          dispatch({ type: "bid", payload: [] });
        dispatch({ type: "selBrnch", payload: [] });
        dispatch({ type: "data", payload: [] });
      }
        dispatch({ type: "sales_channel", payload: salesChanel });
        if(salesChanel==="B2C"){
          dispatch({ type: "enableDevices", payload: true });
        }else{
          dispatch({ type: "enableDevices", payload: false });
        }
        // dispatch({ type: 'office_id', payload:"" })
        setCompanyPick([])
        // props.sendChannel(salesChanel)
      }
      
      const updateRegion=(region)=>{
        dispatch({ type: "region_type", payload: region });
        dispatch({type:"regionSelected", payload: true })
      }
      const handleRegionType = (e) => {
        if(e.length!==0){
          let branchesList = e.map((branch) =>
          <option value={branch.value}>{branch.label}</option>
          );
        //   state.branchMenu = branchesList
          dispatch({ type: 'branchMenu', payload: branchesList})
          dispatch({ type: "branches", payload: e });
          // dispatch({ type: "regionError", payload: false });
          dispatch({type:"regionSelected", payload: true })
        }else{
          if(props.sendEditData==undefined){
          dispatch({type:"branchMenu", payload:[]})
          dispatch({type:"bid", payload:0})}
        }
      }
      
      const handleChangeStar= (e) => {
        if (e && e.length > 0) {
          dispatch({ type: "star_rating", payload: e })
          dispatch({type: "supplierError", payload: false })
        }
      }
      const handleChangeRoom= (e) => {
        if (e && e.length > 0) {
          dispatch({ type: "per_room", payload: e[0].label })
          dispatch({ type: "per_room_list", payload: e })
        }
      }

      const handleChangeNights= (e) => {
        if (e && e.length > 0) {
          dispatch({ type: "no_nights", payload: e[0].label })
          dispatch({ type: "no_nights_list", payload: e })
        }
      }
      const handleChangeAdts= (e) => {
        if (e && e.length > 0) {
          dispatch({ type: "per_adt", payload: e[0].label })
          dispatch({ type: "per_adt_list", payload: e })
        }
      }
      const handleCompTypeChange = (e) => {
        if (e.target.id === "att") {
          dispatch({ type: 'ctyp', payload: "ATT" })
          dispatch({ type: 'compType', payload: true })
          setCompanyPick("");
          setCompanyType(true);
          // markupFareArray = [];
        } else if (e.target.id === "ta") {
          dispatch({ type: 'bid', payload: 0 })
          setCompanyPick("");
          dispatch({ type: 'compType', payload: false })
          dispatch({ type: 'enbleType', payload: true })
          dispatch({ type: 'showCat', payload: false })
          dispatch({ type: 'ctyp', payload: "TA" })
          dispatch({ type: 'ofcs', payload: [] })
          dispatch({ type: 'enableOfcs', payload: true })
          dispatch({ type: 'aptype', payload: "Branch" })
          dispatch({ type: 'ta_comp', payload: [] })
          setEnApplicable(false)
          setCompanyType(false);
          setSTA(false)
          // markupFareArray = [];
        } else {
          dispatch({ type: 'bid', payload: 0 })
          dispatch({ type: 'enableOfcs', payload: false })
          setCompanyPick("");
          dispatch({ type: 'compType', payload: false })
          dispatch({ type: 'enbleType', payload: true })
          dispatch({ type: 'showCat', payload: false })
          dispatch({ type: 'ctyp', payload: "STA" })
          dispatch({ type: 'ofcs', payload: [] })
          dispatch({ type: 'enableOfcs', payload: true })
          dispatch({ type: 'aptype', payload: "Branch" })
          setCompanyType(true);
          setSTA(true)
          setEnApplicable(false)
          // markupFareArray = [];
        }
      }

 /**
 * @author: Arsh
 * @description: This function will update the Device type for B2C Sales channel
 * @date:19-11-2023
 * @function : handleDeviceTypeChange
 */
const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.isB2cWeb){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.isB2cApp){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.isMWeb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }
  
  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }
  const handleTypeChange = (e) => {
    dispatch({ type: 'aptype', payload: "Branch" })
    if (e.target.id === "ta") {
      dispatch({ type: 'ctyp', payload: "TA" })
      setCompanyType(true);
      setCompanyPick([])
    } else {
      dispatch({ type: 'ctyp', payload: "STA" })
      setCompanyType(false);
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      if (companydata && companydata.length === 0) {
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }

  const pickldata = (data) => {
    dispatch({ type: 'data', payload: data })
  }

  /**
 * @author: Arshad
 * @description: This function will Load the Companies Based on the Branch for B2B
 * @date:19-11-2023
 * @function : getCompanyList
 */
const handleBranchChange = (e) => {
    let selbr = state.branches.filter((val)=>{
      if(val.value){
       if ( val.value == e.target.value){
        dispatch({ type: 'data', payload: [{"value":val.value, "label":val.label}]})
       }
      }
        else if(val.props.value){
        if(val.props.value == e.target.value){
          dispatch({ type: 'data', payload: [{"value":val.props.value, "label":val.props.label}] })
        }
      }
    })
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    // const payload = {"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_channel,"branch_id":e.target.value}
    
    if (!sta) {
        getCompanyList(value).then(response => {
        if (response.data.suc) {    
      let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_channel)
      setCompanyPick(cmpanis)      
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
  
    } else {
      dispatch({ type: 'ta_comp', payload: [] })
      getCompanyTAList(value, "TA").then(response => {
        if (response.data.suc) {
      let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_channel)
      setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

  const handleStaChange = (e) => {
    dispatch({ type: 'staCompanies', payload: [] })
    setData([])
    if (e && e.length > 0) {
      // staCompanies
      dispatch({ type: 'ta_comp', payload: e });
      getSTACompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  
  const compdata = (data) => {
    dispatch({ type: 'compData', payload: data })
  }

  const handleCancel = () => {
    return (window.location = "/ezytrip/administration/hotel/crossell");
  };
  const handleReset = () => {
    dispatch({ type: 'clear', payload: true })};
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const validateFareMap = () => {
    if (state.userType === "att_user") {
      if(!state.regionSelected && props.edit_region==undefined && state.region_type===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Region type ' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
        // }else if((!state.office_id || state.office_id?.length === 0) && state.supp[0]?.label=="Amadeus") {
        // dispatch({ type: 'notiMessageShow', payload: true })
        // dispatch({ type: 'notiMessage', payload: 'Please select OFC ID' })
        // dispatch({ type: 'notiVarient', payload: 'danger' })
        // hidemessage()
        // return false;
      }
      if (state.aptype === "Branch" && state.ctyp === "ATT") {
  
        if (state.data.length === 0 && !state.enableDevices) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (companydata.length===0 || state.compData.length===0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else if (!state.enableDevices && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) {
        if (state.bid === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (state.ctyp === "STA") {
          if (state.ta_comp.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            return false;
          }
          if (state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        } else {
          if (state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        }
      }
    } else {
      if (state.utype == "ta_user" && state.ctyp === "STA" && state.compData.length === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    // //Validating profile markup
    // if (perBooking.perBookings.length === 0 && perTicket.perTickts.length === 0) {
    //   dispatch({ type: 'notiMessageShow', payload: true })
    //   dispatch({ type: 'notiMessage', payload: 'Please define Applicable markup' })
    //   dispatch({ type: 'notiVarient', payload: 'danger' })
    //   hidemessage()
    //   return false;
    // }
  
    if (state.aptype === "Branch") {
      if (state.ctyp === "TA") {
        markupFareArray = []
        if(fares.faresList && fares.faresList.length > 0) {
  
          for(let eachFare of fares.faresList) {
  
            if(eachFare.company !== state.selected_company) {
  
              let obj = {
                "name" : eachFare.company
              }
              markupFareArray.push(obj)
            }
          }
        }
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value,
            "name": val.label
          }
          markupFareArray.push(payload);
        }
        let data = Utilities.getUniqData(markupFareArray);
        if (markupFareArray.length !== data.length) {
          // markupFareArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          // let correction = markupFareArray[markupFareArray.length - 1].name
          // markupFareArray = uniqueJson(markupFareArray)
          return false;
        }
      }
    } else if (state.aptype === "Category" && state.utype == "att_user") {
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        let correction = markupFareArray[markupFareArray.length - 1].name
        markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
        return false;
      }
    }
    if (state.ctyp === "STA" && state.utype == "ta_user") {
      markupFareArray = []
      if(fares.faresList && fares.faresList.length > 0) {
  
        for(let eachFare of fares.faresList) {
  
          if(eachFare.company !== state.selected_company) {
  
            let obj = {
              "name" : eachFare.company
            }
            markupFareArray.push(obj)
          }
        }
      }
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        // markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        // let correction = markupFareArray[markupFareArray.length - 1].name
        // markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
        return false;
      }
    }
  
    let cmpanieslist=false
    state.compData.forEach(element => {
      const exist = state.selCompny.some(existingItem=> existingItem.id===element.value);
      if (exist){
       cmpanieslist=true
      }
    });
    if(cmpanieslist){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false
    }

    
    return true;
  }

  // ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      if (context.logindata.utype === "att_user" && state.aptype === "Branch") {
        if (state.compData) {
          if(!state.enableDevices){
            branches.push({ "id": state.data[0].value, "name": state.data[0].label });
            state.selBrnch.push({ "id": state.data[0].value, "name": state.data[0].label });
          }
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices? (state.data[0].props?state.data[0].props.children:state.data[0].label):"",
                //sup: state.ofcs[0].label
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              setData([])
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if ((context.logindata.utype === "att_user" && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || (state.aptype !== undefined && state.aptype === "Category")) {
        let fList = [];
        var branchcatName = "";
        if (state.branches && state.bid !== 0 && !state.enableDevices) {
          branches.push({ "id": state.data[0].value, "name": state.data[0].label });
          state.selBrnch.push({ "id": state.data[0].value, "name": state.data[0].label });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Branch") {
          state.categories.map((obj) => {
            if (obj.label == state.cat) {
              branchcatName = obj.label;
              category.push({ "id": obj.value, "name": obj.label });
              branches = [];
              dispatch({ type: 'cat', payload: 0 })
            }
          });
        }
        if (state.compData && category.length === 0) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices?state.data[0].label:"",
                //sup:state.ofcs[0].label
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if (context.logindata.utype === "att_user" && category.length === 0) {
        let fList = [];
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              // applyon: applicable,
              borcname:!state.enableDevices? state.data[0].label:"",
              //sup: state.ofcs[0].label
            }
            state.selCompny.push({ "id": obj.value, "name": obj.label })
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      if (category.length > 0) {
        const fare = {
          // applyon: applicable,
          borcname: branchcatName,
          //sup: state.ofcs[0].label
        }
        state.selCompny.push({ "id": context.logindata.cid, "name": context.logindata.cnm })
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      //Seeting Pax datat
      // let paxData = [];
      // if (applicable === "Per Booking") {
      //   paxData = perBooking.perBookings;
      // } else {
      //   paxData = perTicket.perTickts;
      // }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (context.logindata.utype === "ta_user") {
        if (state.ctyp === "TA") {
          const fare = {
            // applyon: applicable,
            company: context.logindata.cnm,
            compType: "TA"
          }
          state.selCompny.push({ "id":context.logindata.cid, "name": context.logindata.cnm })
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          fares.faresList = fares.faresList.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === value.place && t.name === value.name
              )
          );
          setShowFareList({ faresList: fares.faresList });
        } else {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                compType: "STA"
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } if (context.logindata.utype === "sta_user") {
        const fare = {
          // applyon: applicable,
          company: context.logindata.cnm,
          compType: "STA"
        }
        state.selCompny.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }

      if (context.logindata.utype === "att_user") {
      //   dispatch({ type: 'disableOfc', payload: true })
        if (!state.enabeleEdit) {
          dispatch({ type: 'bid', payload: branches })      
          dispatch({ type: 'cmpid', payload: companys })      
          //     createFareMapRequest(paxData, companyType, branches, companys, category);
        } else {
          dispatch({ type: 'bid', payload: branches })      
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFMUpdatedPayload(paxData, companyType, branches, companys, category);
        }
      } else if (context.logindata.utype === "ta_user") {
        if (!state.enabeleEdit) {
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFareMapTARequest(paxData, companys);
        } else {
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFMUpdatedTAPayload(paxData, companys);
        }

      } else {
        if (!state.enabeleEdit) {
          dispatch({ type: 'cmpid', payload: companys }) 
      //     createFareMapSTARequest(paxData, companys);
        } else {
          dispatch({ type: 'cmpid', payload: companys }) 
      //     createFMUpdatedSTAPayload(paxData, companys);
        }
      }
      dispatch({ type: 'branchAdded', payload: true })
    }
  }
   /**
 * @author: Lakshmi
 * @description: This function will Delete the Sel Fare Map 
 * @date:14-09-2020
 * @function : deleteFareMap
 */
   const deleteFareMap = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      confirmBranchDlg(obj, 'Fare Map', function () {
        deletePromoFMap(obj.id).then(response => {
          if (response.data.suc) {
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.id !== obj.id) });
            state.selCompny = state.selCompny.filter((item, i) => item.name !== obj.company);
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      let companyList = markupFareArray;
      markupFareArray = [];
      markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
    }
  }

 
useEffect(()=>{
  retrieveCrossSell().then((resp) => {
    if (resp.data.suc) {
      dispatch({ type: "retrieveResp", payload: resp.data.res });
    }})
},[])
  const validateConfig = () => {
    
       const respons = state.retrieveResp.some(existingItem=> existingItem.crosel_nm.toUpperCase() ==state.crosel_nm.toUpperCase());
       const cityResp = state.retrieveResp.some(existingItem=> existingItem.city_nm ==state.city_name && existingItem.region_type ==state.region_type && existingItem.cnt_nm ==state.cnt_name);
       if(respons==true && !state.enableUpdate){
         dispatch({ type: "notiMessage", payload: "Cross Sell Name Already Exists " });
         dispatch({ type: "notiVarient", payload: "danger" });
         dispatch({ type: "notiMessageShow", payload: true });
         hidemessage()
         return false
         } 
       if(cityResp==true && state.resp_city_name!=state.city_name){
         dispatch({ type: "notiMessage", payload: "City Name Already Exists " });
         dispatch({ type: "notiVarient", payload: "danger" });
         dispatch({ type: "notiMessageShow", payload: true });
         hidemessage()
         return false
         } 
         if (state.crosel_nm === "") {
          dispatch({ type: "notiMessage", payload: "Please enter Cross Sell Name" });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true });
          hidemessage()
          return false;
    } else if (state.star_rating === ""){
      dispatch({ type: "notiMessage", payload: "Please select Star Rating" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    } else if (state.cnt_id == ""){
      dispatch({ type: "notiMessage", payload: "Please select Country" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.city_id == 0){
      dispatch({ type: "notiMessage", payload: "Please select City" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.per_room == "" || state.per_room == "Select" || state.per_room == "0"){
      dispatch({ type: "notiMessage", payload: "Please select Per Room" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.no_nights == "" || state.no_nights == "Select" || state.no_nights == "0"){
      dispatch({ type: "notiMessage", payload: "Please select Per Night" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.per_adt == "" || state.per_adt == "Select" || state.per_adt == "0"){
      dispatch({type: "notiMessage",payload: "Please select Per Adult"});
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.sales_channel == ""){
      dispatch({ type: "notiMessage", payload: "Please select sales channel" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.region_type == "" ){
      dispatch({ type: "notiMessage", payload: "Please select region type" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.supp.length == 0 ){
      dispatch({ type: "notiMessage", payload: "Please select supplier" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.sales_channel== "B2B" && state.selBrnch==""){
      dispatch({ type: "notiMessage", payload: "Please select branch" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (state.selCompny==""){
      dispatch({ type: "notiMessage", payload: "Please select company" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }else if (!state.isDeviceAll && !state.isB2cWeb && !state.isB2cApp &&!state.isMWeb){
      dispatch({ type: "notiMessage", payload: "Please select applicable type" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }
    return true;
  }
    const handleSaveCrossSell = (e) => {
    e.preventDefault();
    if (validateConfig()){
      var starcodes = "";
      if (state.star_rating) {
        state.star_rating.map((obj) => {
          if (starcodes) {
            starcodes = starcodes + "," + obj.value;
          } else {
            starcodes = obj.value;
          }
        });
      }
      let suc = true;
      // for (let val of props.resdata) {
      //   if (val.crosel_nm.toLowerCase() == state.crosel_nm.toLowerCase()) {
      //     suc = false;
      //   }
      // }
      if (suc) {
          const payload = {
            crosel_nm: state.crosel_nm,
            utype: context.logindata.utype,
            cnt_id:state.cnt_id,
            city_id:state.city_id,
            cnt_nm :state.cnt_name,
            city_nm:state.city_name,
            per_room:state.per_room,
            no_nights:state.no_nights,
            per_adt:state.per_adt,
            star_rating:starcodes,
            act: state.act,
            ctyp:state.ctyp,
            aptype:state.aptype,
            cmpid:state.selCompny,
            bid:state.selBrnch,
            region_type:context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
            sales_channel:context.logindata.utype==="att_user"?state.sales_channel:context.logindata.channel,
            supp:state.supp[0]?.value,
            isDeviceAll:state.isDeviceAll,
            isB2cWeb:state.isDeviceAll?true:state.isB2cWeb,
            isB2cApp:state.isDeviceAll?true:state.isB2cApp,
            isMWeb:state.isDeviceAll?true:state.isMWeb,
          };
          if(!state.enableUpdate && state.update_id==""){
            payload.crby=context.logindata.uid;
            creation(payload)
            .then((response) => {
              if (response.suc) {
                dispatch({
                  type: "notiMessage",
                  payload: "Cross Sell saved successfully",
                });
                dispatch({ type: "notiVarient", payload: "success" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
                return (window.location = "/ezytrip/administration/hotel/crossell");
              } else {
                dispatch({ type: "isItineraryLoading", payload: false });
                dispatch({
                  type: "notiMessage",
                  payload: "Cross Sell not saved..",
                });
                dispatch({ type: "notiVarient", payload: "danger" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
              }
            })
            .catch(function (error) {
              log.error(
                "Exception occured in saveCompanyDetails function---" + error
              );
            });
            dispatch({ type: "showMessage", payload: false });
          }else{
            payload.id= state.update_id;
            payload.upby= context.logindata.uid;
            update(payload).then((response)=>{
              if (response.suc) {
                dispatch({
                  type: "notiMessage",
                  payload: "Cross Sell updated successfully",
                });
                dispatch({ type: "notiVarient", payload: "success" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
                return (window.location = "/ezytrip/administration/hotel/crossell");
              } else {
                dispatch({ type: "isItineraryLoading", payload: false });
                dispatch({
                  type: "notiMessage",
                  payload: "cross sell not updated..",
                });
                dispatch({ type: "notiVarient", payload: "danger" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
              }
            })
          }
        } else {
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({
            type: "notiMessage",
            payload: "cross sell name already exists",
          });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true });
          hidemessage()
        }
    }
  };
  const handleChangeSupplier = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: "supp", payload: e })
      dispatch({type: "supplierError", payload: false })
    }
  }
  const updateData=(type,obj)=>{
    if(type==="cnt"){
      dispatch({ type: "cnt_id", payload: obj[0].value });
      dispatch({ type: "cnt_name", payload: obj[0].label });
    }
    else{
      dispatch({ type: "city_id", payload: obj.value });
      dispatch({ type: "city_name", payload:obj.label });
    }
    
   
  }

    return (
        <div>
             <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
        <div className="contentArea cmnSearch">
        <Form.Row>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="crosel_nm">
                  <Form.Control type="text" placeholder="Offer Name English" value={state.crosel_nm} 
                   onChange={handleChange}
                  />
                 <Form.Label>Cross Sell Name<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={supplier}
            values={state.supp}
            onChange={(value) => (handleChangeSupplier(value))}
          />
           {state.supplierError && (
              <p className="vError">Please select Supplier</p>
            )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={star_ratings}
            values={state.star_rating_list}
            multi
            onChange={(value) => (handleChangeStar(value))}
          />
          <Form.Label>Star Rating<sup>*</sup></Form.Label>
          </Form.Group>
         </Form.Row>     
         <HotelSelected countrySelected={state.countrySelected} citySelected={state.citySelected} hotelSelected={""} hotelDeleteId={""} editHotelResponse={""} updatedHotelData={""} reset={state.reset} disabledBtn={props.disableBtn} enableHotel={false} configType={"crossSell"} sendCntData={updateData}/> 
         </div>
        <h4 className="subTitle plusHr">Cross Sell Profile</h4>
        <Form.Row>
        <SalesChannel sendSalesChanel={updateSalesChanel} 
         edit_sales={state.edit_salesChanel}
      />
        <RegionType sendResponse={(e)=>handleRegionType(e)} 
          regionType={updateRegion} 
          regionUpdate={state.edit_region} 
          flowType={"corssSell"} />
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={noOfRoomsList}
            values={state.per_room_list}
            onChange={(value) => (handleChangeRoom(value))}
          />
          <Form.Label>Per Room<sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={noOfNights}
            values={state.no_nights_list}
            onChange={(value) => (handleChangeNights(value))}
          />
          <Form.Label>Per Night<sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
          <Select
            options={noOfAdtList}
            values={state.per_adt_list}
            onChange={(value) => (handleChangeAdts(value))}
          />
          <Form.Label>Per Adult<sup>*</sup></Form.Label>
          </Form.Group>
        </Form.Row>
          <Form.Row>
            {state.userType == "att_user" &&
              <Form.Group as={Col}  lg={3} md={6} controlId="classes" className="inputCheck ">
                <div className="d-flex align-items-center">
                   <Form.Check type="radio" label="Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={!sta} custom name="ctype" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={sta} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Applicable Type</Form.Label>
              </Form.Group>
            }
            <br/>
          {state.enableDevices &&
          <>
           <Form.Group as={Col} lg={4} md={6} className="inputCheck">
            <Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
              <div className="d-flex align-items-center">
           <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
             </div>
             </Form.Group>
            </>

          }
          {state.userType === "ta_user" &&
              <Form.Group as={Col} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} onChange={handleTypeChange} checked={companyType} custom name="ctype" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleTypeChange} checked={!companyType} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
            {state.userType === "sta_user" &&
              <Form.Group as={Col} xs={2} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} checked={companyType} custom name="ctype" className="ml-3" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
          </Form.Row>

          <Form.Row>
            {!state.enableDevices && state.enbleType && state.compType && state.userType === "att_user" && !sta && (state.ctyp !== "TA" && state.ctyp !== "STA") &&

              <Form.Group as={Col} className="picklist" xs={7} >
                {state.branches && state.branches.length !== 0 &&
                  <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" type={state.compType} selData={data} dataList={state.branches} sendData={pickldata} />
                }
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>

            }
            { !state.enableDevices &&state.branchMenu.length!==0 &&
              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value={state.data}>Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
             } 

            {!state.showCat && sta && companydata && companydata.length!==0 &&
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={companydata}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled}
                  values={state.ta_comp}
                  onChange={(value) => (handleStaChange(value))}
                />
                <Form.Label>Ta Company</Form.Label>
              </Form.Group>
            }
            {companydata && companydata.length !== 0 &&  !sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            {state.staCompanies && state.staCompanies.length !== 0 && state.userType === "att_user" && sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={state.staCompanies} sendData={compdata} />
                <Form.Label>STA Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {!companyType && companydata && companydata.length !== 0 && state.userType === "ta_user" &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            <Form.Group as={Col} xs={2}>
              {/* {!state.enableUpdate && !disableButton && */}
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={showMarkupFares}
                > Add</Button>
              {/* } */}
              {disableButton &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add</Button>
              }
            </Form.Group>
          </Form.Row>
          {fares.faresList.length !== 0 &&
        <div className="tableLayout">
          <Table>
            <thead>
              <tr>
                {/* <th>Applicable On</th> */}
                {state.userType === "att_user" &&
                  <th>Branch</th>
                }
                <th>Company Name</th>

                <th className="text-right" width="8%">Actions</th>
              </tr>
            </thead>
            <tbody>
              {fares.faresList.map((fare, index) => (
                <React.Fragment key={index}>
                  <tr>
                    {/* <td>
                      <span>{fare.applyon}</span>
                    </td> */}
                    {state.userType === "att_user" &&
                      <td>
                        <span>{fare.borcname!==""?fare.borcname:"N/A"}</span>
                      </td>
                    }
                    <td>
                      <span>{fare.company}</span>
                    </td>
                    <td className="text-right">
                      {!props.disableBtn && !state.hideDelete && fares.faresList.length >1  &&
                        <Button
                          size="xs"
                          variant="link"
                          onClick={deleteFareMap(fare, index)}
                          className="ezyIcon icon-delete"
                          type="submit">
                        </Button>
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      }
      <div className="buttonGrop mt-4 pb-0 switchPlus">
            <Form.Group as={Col} className="topSwitch">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              checked={state.act}
              value={state.act}
              onChange={handleChange}
              />
          </Form.Group>
              {state.enableUpdate && !props.disableBtn && (
                <div className="text-right">

                  <Button
                    // disabled = {!(context.logindata.roles && context.logindata.roles.split(",").includes('H_M_U'))}
                    size="xs"
                    className="mr-2"
                    onClick={handleSaveCrossSell}
                    variant="outline-primary"
                    type="submit"
                  >
                    Update
                  </Button>
                 </div>
               )} 
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={handleCancel}
        >
          Back
        </Button>
        {!state.enableUpdate && !props.disableBtn &&
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleReset}
          >
            Reset
        </Button>
         }
        {!state.enableUpdate && !props.disableBtn && (
          <Button
            size="xs"
            onClick={handleSaveCrossSell}
            variant="outline-primary"
            type="submit"
          >
            Save
          </Button>
        )} 
      </div>
        </div>
    );
  }

  
  export default CreateCrossSell;