import React, { useState, useEffect, useReducer , useContext} from 'react';
import { Form, Button, Col, Row, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Context } from "../../../../../App";
import Utilities from '../../../commonUtils/Utilities';
import CancellationPolicy from './CancellationPolicy';
import FormateCurrency from '../../../commonUtils/FormateCurrency';

const initialState = {
  selRoom1: false, selRoom2: false, selRoom3: false, selRoom4: false, selRoom5: false, selRoom6: false, selRoom7: false, selRoom8: false, selected: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will the Room Occupancy
 * @author: Lakshmi
 * @param {*}
 * @function Confirm details
 * @date : 13-02-2021
 */
function RoomOccupancyDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [roominfo, setRoomOcp1] = useState(props.rmocp);
  const [rmpr, setRoomPrice] = useState(props.rmpr);
  const [cur, setCur] = useState(context.logindata.cur);


  const handleShow = (roomObj) => () => {
    props.sendRoom(roomObj);
  }

  useEffect(() => {
    setRoomOcp1(props.rmocp)
  }, [props.rmocp])

  const calPricePerNight=(price,serviceFee,gstprice,tdsVal)=>{
    let total= Number(price) + Number(props.onflyMkp) + Number(gstprice) + tdsVal + Number(Utilities.calHotelServiceFee(serviceFee,props.butype,"All"));
    let totalPrice=Utilities.addPrice(total,props.nights,"divide");
    return totalPrice;
    }


  return (
    <>
      <Col xs={6}>
        <div className="roomInfo">
          <div className="himg icons">
            {roominfo.rmimg!==undefined && roominfo.rmimg!=="" && <img
              src={roominfo.rmimg}
              className="htlImg"
              title="Hotel"
              alt="Hotel"
            />}
            {/* <span>{roominfo.rmimg.length}</span> */}
            <span className="moreimage ezyIcon icon-noImage" onClick={roominfo.rmimg!==undefined && roominfo.rmimg!=="" && handleShow(roominfo)}>{roominfo.imgecnt!==undefined && roominfo.imgecnt!=="" && roominfo.imgecnt}</span>
          </div>

          <div className="roomDetails">
            <div>
            <strong>{roominfo.rnm}</strong>
            <div className="bedType"><span className="ezyIcon icon-bed"> Bed Type : </span> <strong>{roominfo.rmpr !== undefined && roominfo.rmpr.bed !== undefined ? roominfo.rmpr.bed : roominfo.rmpr[0].bed}</strong>
            </div>
            </div>
            <div className="onhold">
              <Row>
                <Col xs="6">    {roominfo.rmimg!==undefined && roominfo.rmimg!=="" &&
                  <Link onClick={handleShow(roominfo)}>Show room information</Link>
                  }</Col>
                <Col xs="6" className="holdtag">  {roominfo.rmpr[0].refundable && roominfo.rmpr[0].freeCancelDate!=="" && roominfo.rmpr[0].freeCancelDate!==null && context.logindata.roles.includes('O_P_B')!==undefined && context.logindata.roles.includes('O_P_B') ? <span className="ezyIcon icon-time"> Hold Booking</span>: ''}</Col>
              </Row>


            </div>

          </div>
        </div>
      </Col>
      <Col xs={2} className="typeofRoom">
      {roominfo.rmpr[0]!==undefined && roominfo.rmpr[0].ra.length!==0 &&
       <div className="rmtyp">
          {Utilities.getBoardBasisHotel(roominfo.rmpr[0].ra)}<br/>
       </div>
      }
        {roominfo.rmpr[0]!==undefined && roominfo.rmpr[0].ra.length===0 &&
                <div className="rmtyp">
                <p>Room Only</p>
               </div>

             }

           {roominfo.rmpr[0]!==undefined && roominfo.rmpr[0].canpol!==undefined && roominfo.rmpr[0].refundable &&
            <CancellationPolicy canPolicy={roominfo.rmpr[0].canpol} isRefund={true}/>
           }
           {!roominfo.rmpr[0].refundable &&
            <CancellationPolicy canPolicy={roominfo.rmpr[0].canpol} isRefund={false}/>
           }
          <p className="m-0">

         </p>
        <p className="m-0">
         <span className={roominfo.rmpr[0].refundable ? "refundable":"refundable non"}>{roominfo.rmpr[0].refundable ? "Penalty Applies" : "Non Refundable"}</span>
        </p>
      </Col>
      <Col xs={3} className="priceperRoom">
        <div className="dealparts">
          {roominfo.rmpr[0].pr[0].disc !== undefined && roominfo.rmpr[0].pr[0].disc !== 0 &&
            <div className="deals mr-1">Hot Deal</div>
          }
          {roominfo.rmpr[0].pr[0].package !== undefined && roominfo.rmpr[0].pr[0].package &&
            <div className="pkgRate"> <span className="ezyIcon icon-Package">Package Rate</span> </div>
          }
          {roominfo.rmpr[0].pr[0].ezy_deal !== undefined && roominfo.rmpr[0].pr[0].ezy_deal &&
            <div className="pkgRate  mr-1">Ezytrip Deal</div>
          }

        </div>
        {roominfo.rmpr[0].pr[0].disc !== "0" && roominfo.rmpr[0].pr[0].disc !== 0 ? (
          <>
            {!props.pricePerNight ? (
              <>
                <div className="blockPrice">
                  <strong className="offers"><em>{cur}</em><FormateCurrency value={Number(roominfo.rmpr[0].pr[0].dwtf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All"))} /> </strong>
                </div>
                <div className="blockPrice">
                  <strong><em>{cur}</em> <FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All")) - Number(roominfo.rmpr[0].pr[0].com_disc)} />
              {context.logindata.roles.includes('E_H_C') &&
                <span className="toolTipInfo pricebrk ezyIcon icon-info g">
                <div className="toolTipCUstoom fairbrkUp hotelFairbrkup">
              <div className="toolTipCont">
              {/* <div className="list"><span>Base Fare</span> <span>QAR 450.00</span></div> */}
              <div className="list"><span>Total Price</span> <span>{cur} <FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All"))} /></span></div>
              <div className="list"><span>Discount</span> <span>{cur} {roominfo.rmpr[0].pr[0].com_disc}</span></div>
              <div className="list sumTotall"><span>Total Amount</span> <span>{cur} <FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(props.onflyMkp) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All")) - Number(roominfo.rmpr[0].pr[0].com_disc)} /></span></div>
              <div className="list"><span>Commission</span> <span>{cur} {roominfo.rmpr[0].pr[0].market_fee}</span></div>

              {/* <div className="list total"><span>Total Gross Rate</span> <span>QAR 630.00</span></div> */}

            </div>
          </div>
                 </span>
              }
                  </strong>

                </div>

              </>
            ) : (
              <>
                <div className="blockPrice">
                  <strong className="offers"><em>{cur}</em>{<FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].dwtf, roominfo.rmpr[0].servicefee,roominfo.rmpr[0].pr[0].gst_total,Number(roominfo.rmpr[0].pr[0].tds_val))} />} </strong>
                </div>
                <div className="blockPrice">
                  <strong><em>{cur}</em>{<FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf - Number(roominfo.rmpr[0].pr[0].com_disc), roominfo.rmpr[0].servicefee,roominfo.rmpr[0].pr[0].gst_total,Number(roominfo.rmpr[0].pr[0].tds_val))} />}</strong>
                {context.logindata.roles.includes('E_H_C') &&
                <span className="toolTipInfo pricebrk ezyIcon icon-info k">
                <div className="toolTipCUstoom fairbrkUp hotelFairbrkup">
              <div className="toolTipCont">
              <div className="list"><span>Total Price</span> <span>{cur} <FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf, roominfo.rmpr[0].servicefee,roominfo.rmpr[0].pr[0].gst_total,Number(roominfo.rmpr[0].pr[0].tds_val))} /></span></div>
              <div className="list"><span>Discount</span> <span>{cur} {Number(roominfo.rmpr[0].pr[0].com_disc)}</span></div>
              <div className="list sumTotall"><span>Total Amount</span> <span>{cur} <FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf - Number(roominfo.rmpr[0].pr[0].com_disc), roominfo.rmpr[0].servicefee,roominfo.rmpr[0].pr[0].gst_total,Number(roominfo.rmpr[0].pr[0].tds_val))} /></span></div>
              <div className="list"><span>Commission</span> <span>{cur} {roominfo.rmpr[0].pr[0].market_fee}</span></div>
              {/* <div className="list total"><span>Total Gross Rate</span> <span>QAR 630.00</span></div> */}

            </div>
            </div>
             </span>
              }
              </div>
              </>
            )
            }
          </>
        ) : (
          <div>
            {!props.pricePerNight ? (
              <div className="blockPrice">
                <strong><em>{cur}</em>{<FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All")) - Number(roominfo.rmpr[0].pr[0].com_disc)} />}</strong>
                {context.logindata.roles && context.logindata.roles.includes('E_H_C')!==undefined && context.logindata.roles.includes('E_H_C') &&
                 <span className="toolTipInfo pricebrk ezyIcon icon-info kl">
                    <div className="toolTipCUstoom fairbrkUp hotelFairbrkup">
                      <div className="toolTipCont">
                      <div className="list"><span>Total Price</span> <span>{cur} <FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All"))} /></span></div>
                      <div className="list"><span>Discount</span> <span>{cur} {roominfo.rmpr[0].pr[0].com_disc}</span></div>
                      <div className="list sumTotall"><span>Total Amount</span> <span>{cur} <FormateCurrency value={Number(roominfo.rmpr[0].pr[0].tf) + Number(props.onflyMkp) + Number(roominfo.rmpr[0].pr[0].gst_total) + Number(roominfo.rmpr[0].pr[0].tds_val) + Number(Utilities.calHotelServiceFee(roominfo.rmpr[0].servicefee, props.butype, "All")) - Number(roominfo.rmpr[0].pr[0].com_disc)} /></span></div>
                      <div className="list"><span>Commission</span> <span>{cur} {roominfo.rmpr[0].pr[0].market_fee}</span></div>
                        {/* <div className="list total"><span>Total Gross Rate</span> <span>QAR 630.00</span></div> */}

                      </div>
                    </div>
                  </span>
                }
              </div>
            ) : (
              <div className="blockPrice">
                <strong><em>{cur}</em>{<FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf - Number(roominfo.rmpr[0].pr[0].com_disc), roominfo.rmpr[0].servicefee,Number(roominfo.rmpr[0].pr[0].gst_total),Number(roominfo.rmpr[0].pr[0].tds_val))} />}</strong>
                {context.logindata.utype == "att_user" &&
                  <span className="toolTipInfo pricebrk ezyIcon icon-info lk">
                    <div className="toolTipCUstoom fairbrkUp hotelFairbrkup">
                      <div className="toolTipCont">
                      <div className="list"><span>Total Price</span> <span>{cur} <FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf, roominfo.rmpr[0].servicefee,Number(roominfo.rmpr[0].pr[0].gst_total),Number(roominfo.rmpr[0].pr[0].tds_val))} /></span></div>
                      <div className="list"><span>Discount</span> <span>{cur} {Number(roominfo.rmpr[0].pr[0].com_disc)}</span></div>
                      <div className="list sumTotall"><span>Total Amount</span> <span>{cur} <FormateCurrency value={calPricePerNight(roominfo.rmpr[0].pr[0].tf -Number(roominfo.rmpr[0].pr[0].com_disc), roominfo.rmpr[0].servicefee,Number(roominfo.rmpr[0].pr[0].gst_total),Number(roominfo.rmpr[0].pr[0].tds_val))} /></span></div>
                      <div className="list"><span>Commission</span> <span>{cur} {roominfo.rmpr[0].pr[0].market_fee}</span></div>
                        {/* <div className="list total"><span>Total Gross Rate</span> <span>QAR 630.00</span></div> */}

                      </div>
                    </div>
                  </span>
                }
              </div>
            )
            }
          </div>
        )
        }
        {!props.pricePerNight ? (
          <p>Price for all for {props.nights} nights</p>
        ) : (
          <p>Price for 1 night </p>
        )
        }
      </Col>
    </>

  )
}
export default RoomOccupancyDetails