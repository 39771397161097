
import { apiPostHelper } from '../../../components/commonUtils/helper';
import axios from 'axios'
const COMMON_APP = process.env.REACT_APP_B2B_SEARCH
const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @author: Rambabu
 * @date: 28-11-2020
 * @description: Integrated all Flight Post booking services
 * @param {*} Service 
 */
class PostBookingService {

    /**
     * @author: Rambabu
     * @description: checkIncompletebooking
     * @date:28-11-2020 
     */
    async getIncompletebooking(data) {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return await apiPostHelper(`${COMMON_APP}/flight/searchbestprice`, data, headers)
    }
    async gerFareDetails(id) {
        return await axios.get(`${API_URL}/att/fltsummary/retrieve-fare-details/${id}`);
    }


}

export default new PostBookingService()