import axios from 'axios'

const API_URL_DB = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Exchange rate
 * @param {*} Service 
 */

class ExchangeRateConfigService {
    async save(data) {
        return await axios.post(`${API_URL_DB}/att/exchangerates/save`,data)  
      }
    async getAllExchnagerates() {
        return await axios.get(`${API_URL_DB}/att/exchangerates/getRates`)
    }
    async update(data) {
        return await axios.post(`${API_URL_DB}/att/exchangerates/update`,data)
    }
    }
    
export default new ExchangeRateConfigService()