import log from "loglevel";
import React, { useState, useEffect, useReducer } from 'react';
import { Alert, Form, Modal, Col, Row } from "react-bootstrap";
import BookingDetails from '../common/confirmation/BookingDetails';
import PriceDetails from '../common/confirmation/PriceDetails';
import PassengerDetails from '../common/confirmation/PassengerDetails';
import PaymentDetails from '../common/confirmation/PaymentDetails';
import TransactionSummary from '../common/confirmation/TransactionSummary';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import Button from '../../../common/buttons/Button';
import {sendHotelConfirmationObj} from '../pages/operations';
import { useHistory } from 'react-router-dom';
import { isNotNull, isArrayNotEmpty } from '../../../commonUtils/validators';
import { emailValidation } from '../../../commonUtils/FormValidations';
/**
 * @description:Showing the Hotel confiramtion details
 *
 * @author: Lakshmi
 * @param {*}
 * @function Confirm details
 * @date : 12-02-2021
 */
/**
 * Initial State Declaration
 */
 const initialState = {

  notiMessage: '', notiMessageShow: false, notiVarient: '',
  showPopup: false, email: ""
};


const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ConfirmationPage(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [state, setState] = useState({
  //   loading: false
  // })
  const [response, setResponse] = useState()
  const history = useHistory();
  const [markup, setMarkup] = useState(0)
  const [showPay , setShowpay] = useState(false)

  useEffect(() => {
    if(props.location!==undefined && props.location.state!==undefined && props.location.state.req!==undefined){
      setResponse(props.location.state.req);
      let rmCount= props.location.state.req.data.rooms.length;
      if(props.location.state.req.data.onFlyMarkup!==undefined && props.location.state.req.data.onFlyMarkup!==0){
        setMarkup(props.location.state.req.data.onFlyMarkup/rmCount);
      }
    }
    if(props.location.state.req.data!==undefined && props.location.state.req.data.pay!==undefined){
      for(let val of props.location.state.req.data.pay){
         if(val.psta.toUpperCase()!=="PENDING"){
          setShowpay(true)
         }
      }
  }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    return () => {
      console.log(history.action)
      if (history.action === "POP") {
        history.push({ pathname: '/ezytrip/dashboard' })
      }
    };
  }, [history]);

  const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }
    const openshareevent = (e) => {
      e.preventDefault();
      dispatch({ type: 'showPopup', payload: true })
    }
  const handleChange = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
    dispatch({ type: 'email', payload: trimmedEmail })
     }
   const handleClose = ()=>{
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
   }
   const validateEmail = ()=>{
    if(state.email == ""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     else if(!emailValidation(state.email)){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     return true
   }
   const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
   const shareItinerary = (data) =>{
    sendHotelConfirmationObj(data).then(response=>{
      if(response.suc){
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response.res })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }
      else{
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: "Unable to send Email Please try again later"})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
     }).catch(function (error) {
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'email', payload: "" })
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
   }
   const sendEmail =()=>{
    if(validateEmail()){
         const payload = {
             "bookingid" : response.data.booking_id,
              "email" : state.email
            }
            shareItinerary(payload)
        }
      }

  return (
    <>
      <Header />
      {response!==undefined && response.data !==undefined &&
      <div className="container confirmation hotelconfirMation">
        {state.notiMessageShow &&
           <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
         }
        <div className="confirmMessage d-flex align-items-center justify-content-between mt-4 mb-4">
        <div className='resultMes'>
          <div className="d-flex align-items-center resconfirMation ">
          <div className="confirmStatus done done ezyIcon icon-tick"></div>
          {(response.data.st==="Confirmed" || response.data.st==="CONFIRMED") &&
          <div className="message">
            <h3 className="m-0">Thank you for booking with us!</h3>
            <p className="m-0 pt-1">The booking confirmation has been sent to your email address...</p>
          </div>
          }
          {response.data.st==="On Hold" || response.data.st==="On hold" &&
          <div className="message">
            <h3 className="m-0">Thank you, your booking is On Hold!</h3>
            <p className="m-0 pt-1">The On hold booking confirmation has been sent to your email address.</p>
          </div>
          }
          {response.data.st==="Partially Confirmed" &&
          <div className="message">
            <h3 className="m-0">Thank you, your selected Room(s) are Confirmed!</h3>
            <p className="m-0 pt-1">The booking confirmation has been sent to your email address.</p>
          </div>
          }
          </div>
          </div>
            <div className='socialIcons'>
          {(response.data.st == "Confirmed" || response.data.st==="Partially Confirmed") &&
                <Button size="xs" className="prient ezyIcon icon-share" onClick={openshareevent} variant="link">Share Itinerary
                </Button>
              }
          {(response.data.st == "Confirmed" || response.data.st==="Partially Confirmed") &&<Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link">

              </Button>}
              </div>
        </div>

        <div className="resultSection boxShadow bg-white summary mb-4">
          <h5 className="title">Transaction Summary</h5>
          <div className="table-responsive mobileTable newlayoutDesign">
          <TransactionSummary bookDetails={response.data} />
          </div>
        </div>

        <div className="resultSection boxShadow bg-white mb-4">
          <h5 className="title">Booking Details</h5>
          <BookingDetails bkres={response.data} />

        </div>


        {isNotNull(response.data) && response.data.rooms.map((room, index) => (
          <React.Fragment key={index}>
            {response.data.roompax.map((rm, idx) => (
              <React.Fragment key={idx}>
                {room.rmindex === rm.rmindex &&
                  <div className="resultSection boxShadow bg-white PassengerDetails duplexSite mb-4">
                    <PassengerDetails paxDetails={rm} price={room} bkres={response.data} />
                    <h5 className="title mb-2 mt-4">Price Details({response.data.cur})</h5>
                    <div className="duplexsiteInner">
                    <PriceDetails priceDetails={room} butype={response.data.butype} onFlyMarkup={response.data.onFlyMarkup} roomMarkup={markup!==undefined && markup} currency={response.data.cur}/>
                    </div>
                  </div>
                }
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <div className="resultSection bg-white boxShadow PassengerDetails paymentDetailshotel mb-4">
          {showPay &&
          <>
           <h5 className="title mb-2">Payment Details</h5>
           <div className="PaymentDetails">
          <PaymentDetails payDetails={response.data.pay} />
          </div>
          </>
          }
            {response.data?.priceNote!==undefined && response.data?.priceNote!=="" &&
          <div className="costList total otherCust">
            <span className="dueproperty"><span>Due at property :</span> {response.data.priceNote} </span>
        </div>
            }
        </div>



      </div>

              }

      <Footer />
      <Modal
        show={state.showPopup}
        onHide={handleClose}
        className="importPNRMdl shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <h5>Share Itinerary</h5>
          <Row>
          <Col xs={10}>
          <Form.Group controlId="uem">
              <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChange} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>
            </Form.Group>
            </Col>
            <Col xs={2}>
            <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={sendEmail}>Submit
          </Button>
            </div>
            </Col>
            </Row>
               </Modal.Body>
            </Modal>
    </>
  )
}
export default ConfirmationPage