
/**
 * @description: CRUD operations for Deal code
 * @author: Rambabu
 * @param {*}
 * @function Commission
 * @date : 15-10-2020
 */


import OfficieIdConfig from '../../../../services/administration/OfficieIdConfig';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import {apiPostHelper } from '../../../../components/commonUtils/helper';
const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: Fetch Branch details
 * @author: Rambabu
 * @param {*}
 * @function getBranchData
 * @date : 15-10-2020
 */
export const getBranchData = async () => {
    let branch_res = '';
    await AttCommonSerivce.getBranchData().then(response => {
        if (response.data.suc) {
            branch_res = response.data.res;
        }
    })
    return Promise.resolve(branch_res);
}

/**
 * @description: Fetch getOfficeIdList 
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdList
 * @date : 15-10-2020
 */
export const getOfficeIdList = async () => {
    let officeid_res = [];
    await AttCommonSerivce.getOfficeIdList().then(response => {
        if (response.data.suc) {
            officeid_res = response.data.res;
        }
    })
    return Promise.resolve(officeid_res);
}


/**
 * @description: Fetch getOfficeIdDetails 
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdDetails
 * @date : 15-10-2020
 */
export const getOfficeId = async (obj) => {
    let officeid_det_res = [];
    await OfficieIdConfig.getOfficeIDList(obj).then(response => {
        if (response.data.suc) {
            officeid_det_res = response.data.res;
        }
    })
    return Promise.resolve(officeid_det_res);
}


/**
 * @description: Fetch compane details based on branch id
 * @author: Rambabu
 * @param {*}
 * @function companyList
 * @date : 15-10-2020
 */
export const companyList = async (id) => {
    let company_res = [];
    await AttCommonSerivce.fetchcompany(id).then(response => {
        if (response.data.suc) {
            company_res = response.data.res;
        }
    })
    return Promise.resolve(company_res);
}


/**
 * @description: Fetch getOfficeIdDetails 
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdDetails
 * @date : 15-10-2020
 */
export const saveOfficeidConfigData = async (payload) => {
    let officeid_config_res = '';
    await OfficieIdConfig.saveOffcieIdConfigDetails(payload).then(response => {
        if (response.data.suc) {
            officeid_config_res = response;
        }
    })
    return Promise.resolve(officeid_config_res);
}



/**
 * @description: Fetch getOfficeIdDetails 
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdDetails
 * @date : 15-10-2020
 */
export const editOfficeId = async (id) => {
    let officeid_config_res = '';
    await OfficieIdConfig.retrieveOfficeId(id).then(response => {
        if (response.data.suc) {
            officeid_config_res = response;
        }
    })
    return Promise.resolve(officeid_config_res);
}


/**
 * @description: Fetch getOfficeIdDetails 
 * @author: Rambabu
 * @param {*}
 * @function getOfficeIdDetails
 * @date : 15-10-2020
 */
export const updateOffcieIdConfigDetails = async (payload) => {
    let officeid_update_res = '';
    await OfficieIdConfig.updateOffcieIdConfigDetails(payload).then(response => {
        if (response.data.suc) {
            officeid_update_res = response;
        }
    })
    return Promise.resolve(officeid_update_res);
}


/**
 * @description: deleteOfficeidChildData 
 * @author: Rambabu
 * @param {*}
 * @function deleteOfficeidChildData
 * @date : 15-10-2020
 */
export const deleteOfficeidChildData = async (obj) => {
    let company_delete_res = '';
    await OfficieIdConfig.deleteOfficeidChildData(obj).then(response => {
        if (response.data.suc) {
            company_delete_res = response;
        }
    })
    return Promise.resolve(company_delete_res);
}




/**
 * @description: Delete Routes data 
 * @author: Rambabu
 * @param {*}
 * @function deleteDealcodeRoutesData
 * @date : 20-10-2020
 */
export const deleteOfficeidRoutesData = async (req) => {
    let delete_res = [];
    await OfficieIdConfig.deleteOfficeidRoutesData(req).then(response => {
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}


export const getCompaniesList = async (obj) => {
    let companies = [];
    await AttCommonSerivce.att_companies(obj).then(response => {
        companies = response;
    });
    return Promise.resolve(companies);
}

export const getOfcIdList = async (obj) => {
    let companies = [];
    await OfficieIdConfig.getOIDMasterData(obj).then(response => {
        companies = response;
    });
    return Promise.resolve(companies);
}


/**
 * @description: deleteOfficeidChildData 
 * @author: Rambabu
 * @param {*}
 * @function deleteOfficeidMap
 * @date : 15-10-2020
 */
export const deleteOfficeidMap = async (type, id) => {
    let company_delete_res = '';
    await OfficieIdConfig.deleteOfficeidChildData(type, id).then(response => {
        if (response.data.suc) {
            company_delete_res = response;
        }
    })
    return Promise.resolve(company_delete_res);
}


//Update status
export const updateStatus = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/officeid/updatestatus`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}






