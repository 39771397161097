import React from 'react';
//This function will show the Air markups List and Add Markup
function IndigoFareopContent(props) {
    return (
        <>
                 <div>
                    {(props.regionType === "Domestic" || props.regionType === "International") &&
                    <>
                    <p><span className="ezyIcon icon-cabinbaggage">Hand Baggage </span>
                     <span>{props.ftype.includes("Corporate")?"10KG":"7KG"}</span></p>
                    <p><span className="ezyIcon icon-checkinbag">Checkin Baggage </span>
                     {props.regionType === "Domestic" &&
                     <span>15KG</span>
                     }
                     {props.regionType === "International" && props.baggage==="30KG" &&
                     <span>30KG</span>
                     }
                    {props.regionType === "International" && props.ftype!=="Corporate - F" && props.baggage==="20KG" &&
                     <span>20KG</span>
                     }
                     {props.regionType === "International" && props.ftype.includes("Corporate") && props.baggage==="20KG" &&
                     <span>30KG</span>
                     }
                     </p>
                     <p><span className="ezyIcon icon-mealsselection">Meal Selection </span>
                     {props.ftype.includes("Regular") &&
                     <span>Chargeable</span>
                     }
                    {(props.ftype.includes("Flexi") || props.ftype.includes("Corporate")) &&
                     <span>{props.regionType === "Domestic"?"Complimentary":"Included"}</span>
                     }
                     </p>
                     <p><span className="ezyIcon icon-newseatselection">Seat Selection</span>
                     {props.ftype.includes("Regular") &&
                     <span>Chargeable</span>
                     }
                     {(props.ftype.includes("Flexi") || props.ftype.includes("Corporate")) &&
                     <span>Included</span>
                     }
                     </p>
                     </>
                    }
                 </div>
        </>


    )
}
export default IndigoFareopContent