import axios from 'axios' 

const API_URL = process.env.REACT_APP_B2B_URL
//const API_URL = "http://localhost:3005";
const COMMON_URL = process.env.REACT_APP_B2B_SEARCH


/**
 * @author: Rambabu
 * @date: 17-09-2020
 * @description: Integrated all Flight Summary services
 * @param {*} Service 
 */
class FlightSummaryService {

    /**
     * @author: Rambabu
     * @description: Fetch office id  data from config table
     * @date:16-09-2020 
     */
    async getFlightSummaryDetails(id) {
        return await axios.get(`${API_URL}/att/fltsummary/retrieve/${id}`);
    }

        /**
     * @author: Azamuddin
     * @description: Fetch Resiisue split data for booking summary
     * @date: 10-06-2021
     */
    async getretrievereissuesplit(id) {
        return await axios.get(`${API_URL}/att/fltsummary/retrievereissuesplit/${id}`);
    }
    // getretrievereissuesplit

    /**
     * @description :fetch booking history data by passing bid
     * @author: Azamuddin
     * @date : 11-06-2021
     * @serviceBy : Rambabu
     */
    async getHistory(id) {
        return await axios.get(`${API_URL}/att/fltsummary/getReissueExchange/${id}`);
        // return await axios.get(`${API_URL}/att/fltsummary/getReissueExchange/${id}`);
    }
    /**
     * @author: Rambabu
     * @description: Fetch office id  data from config table
     * @param {*} req 
     * @date:01-10-2020 
     */
    async getAirportData(data) {
        return await axios.post(`${API_URL}/att/fltsummary/airportdata`, data);
    }
    
    async voidFareFetch(req) {
        return await axios.post(`${COMMON_URL}/postTicket/void_fare_fetch`,req)
    }
     async saveVoid(req) {
        return await axios.post(`${COMMON_URL}/postTicket/void-booking-ticket`,req)
     }
     
     async cancelVoid(req) {
        return await axios.post(`${COMMON_URL}/postTicket/void-cancel-ticket`,req)
     }

     async cancelBookingFlow(req) {
        return await axios.post(`${COMMON_URL}/pnr/cancel_booking`,req)
     }
    /**
    * @author: Rambabu
    * @description: Get compnay address and branch address based on compnay id
    * @date:19-05-2021 
    */
    async getCompnayAddress(id) {
        return await axios.get(`${API_URL}/att/common/getcompanyaddress/${id}`);
    }

}

export default new FlightSummaryService()