
import React, { Component, useState, useEffect, useContext,useRef } from 'react';
import Tabs from '../../../../components/common/tabs/Tabs';
import OfflinePay from '../../flight/payment/OfflinePay';
import ConfirmPayment from '../../../paymentUnique/payHotel'
import { Context } from "../../../../../App";
import HoldPayment from '../../flight/payment/HoldPayment';
/**
 * @description:This function will fire the Book request and Process the Payment
 * @author: Lakshmi
 * @param {*}
 * @function Payment details
 * @date : 03-09-2020
 */

function Payment(props) {
    const [state, setState] = useState({})
    const [enableConfirm, setEnableConfirm] = useState(true)
    const [context, setContext] = useContext(Context);
    const paySec = useRef(null)
    const executeScroll = () =>{ return paySec.current.scrollIntoView()};
    const [payment, setPayment] = useState({
        active: 'confirm'
    });

    const showFailedMsg = () => {
        props.sendFailedMsg("Book");
    }
    useEffect(() => {
        if(props.refProp){
            executeScroll();
        }
      }, [props.refProp])

      const sendBook = () => {

      }

    const content = {
        confirm: <>{enableConfirm && <ConfirmPayment bdata={props.bookingdata} sendFailedMsg={showFailedMsg} />}</>,
        hold: <>{props.bookingdata.enableHold && context.logindata.roles.includes('O_P_B') && <HoldPayment bdata={props.bookingdata} sendBook={sendBook} sendBook={sendBook} product={"Hotel"}/>}</>,
        offline: <>{props.bookingdata.enableOffline && props.bookingdata.utype === "att_user" && context.logindata.roles.includes('O_P_E') && <OfflinePay bdata={props.bookingdata} product={"Hotel"} sendFailedMsg={showFailedMsg}/>}</>
    };

    return (
        <>
            <div className="paymentSec boxLayout" ref={paySec}>
                <h5 className="panelTitle ezyIcon icon-payment mb-4"> Payment Details</h5>
                <div className="tabLink d-flex">
                    <Tabs active={payment.active} onChange={active => setPayment({ active })}>
                        {enableConfirm && <span key="confirm" className="ezyIcon icon-confirm"> CONFIRM</span>}
                        {props.bookingdata.enableHold && context.logindata.roles.includes('O_P_B') && <span key="hold" className="ezyIcon icon-hold"> HOLD BOOKING</span>}
                        {props.bookingdata.enableOffline && props.bookingdata.utype === "att_user" && context.logindata.roles.includes('O_P_E') &&
                        <span key="offline ezyIcon icon-offline mb-4">  OFFLINE PAY</span>
                        }
                        </Tabs>
                    <div className="tabContent">
                        {content[payment.active]}
                    </div>
                </div>
            </div>
        </>
    )
}


export default Payment;
