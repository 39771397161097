
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import FBFResultBlockLoader from './FBFResultBlockLoader';
import FilterLoader from './FilterLoader';
//Main Function
function ResultPageLoader(props) {
    const [state, setState] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (

        <>
            <Row>
                <Col className="filterLayout">
                    <FilterLoader triptype={props.triptype} />
                </Col>
                <Col className="resultSection fbfFlow">

                    <div className="selectedRoutes mb-4">
                        <p className="ezyIcon icon-flight">  Your Selection</p>
                        <Row>
                            <Col xs={9}>
                                <Row>
                                    <Col xs={6} className="d-flex owned justify-content-between align-items-center">
                                        <div className="width50">
                                            <div className="logoBg"></div>
                                        </div>
                                        <div className="d-flex flex-column width80">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="d-flex flex-column width50">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="ezyIcon icon-flight"></div>
                                        <div className="d-flex flex-column width50">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="d-flex flex-column width80">
                                            <div className="stopsLoader3"></div>
                                        </div>

                                    </Col>
                                    <Col xs={6} className="d-flex owned justify-content-between align-items-center">
                                        <div className="width50">
                                            <div className="logoBg"></div>
                                        </div>
                                        <div className="d-flex flex-column width80">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="d-flex flex-column width50">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="ezyIcon icon-flight"></div>
                                        <div className="d-flex flex-column width50">
                                            <div className="stopsLoader3"></div>
                                        </div>
                                        <div className="d-flex flex-column width80">
                                            <div className="stopsLoader3"></div>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3} className=" price d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-column">
                                    <div class="priceLoaderSelect"></div>
                                    <span>(For Person)</span>
                                </div>
                                <Button
                                    size="md"
                                    className="d-flex"
                                    variant="primary"
                                >BOOK NOW</Button></Col>

                        </Row>
                    </div>
                    {/* <p className="mb-2 offerLabel">Roundtrip Offers</p>
                    <div className="Offers d-flex justify-content-between mb-4">

                        <div className="airline d-flex align-items-center pt-2 pb-2">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>
                        <div className="airline d-flex align-items-center">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>
                        <div className="airline d-flex align-items-center">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>
                        <div className="airline d-flex align-items-center">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>
                        <div className="airline d-flex align-items-center">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>
                        <div className="airline d-flex align-items-center">
                            <div className="width50 mr-2"><div className="logoBg1"></div></div>
                            <div className="d-flex flex-column width100">
                                <div class="stopsLoader4"></div>

                            </div>
                        </div>

                    </div> */}
                    <div className="roundTrip">
                        <Row>
                            <Col xs={6} className="owned pr-2">
                                <div className="resultSection p-0 mb-3">
                                    <div className="rs-headerBlock">
                                        <Row className="align-items-center rs-row">

                                            <Col xs={3} className="rs-rTypeDate">
                                                <span className="departure ezyIcon icon-flight">  Departure</span>
                                            </Col>
                                            <Col xs={9} className="rs-FromTo">
                                                <Row className="align-items-center m-0">
                                                    <Col className="d-flex flex-column width100">
                                                        <div class="stopsLoader4"></div>
                                                    </Col>
                                                    <Col className="d-flex flex-column">
                                                        <div className="fbfSort" >
                                                            <label><strong>Sort By:</strong> <span>Departure Time</span> </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <span>
                                                </span>
                                            </Col>
                                        </Row>
                                    </div></div>
                                <Col className="flightBlock">
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                </Col>
                            </Col>
                            <Col xs={6} className="return pl-2">
                                <div className="resultSection p-0 mb-3"><div className="rs-headerBlock">
                                    <Row className="align-items-center rs-row">

                                        <Col xs={3} className="rs-rTypeDate">
                                        <span className="departure ezyIcon icon-flight"> Arrival</span>
                                        </Col>
                                        <Col xs={9} className="rs-FromTo">
                                            <Row className="align-items-center m-0">
                                                <Col className="d-flex flex-column width100">
                                                    <div class="stopsLoader4"></div>
                                                </Col>
                                                <Col className="d-flex flex-column">
                                                    <div className="fbfSort" >
                                                        <label><strong>Sort By:</strong> <span>Return Time</span> </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <span>
                                            </span>
                                        </Col>
                                    </Row>
                                </div></div>
                                <Col className="flightBlock">
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                    <FBFResultBlockLoader />
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default ResultPageLoader;
