
import {apiPostHelper} from '../../../commonUtils/helper';
const HOTEL_BOOK_URL = process.env.REACT_APP_HOTEL_BOOK_URL
const HOTEL_HOLD_BOOK_URL = process.env.REACT_APP_B2B_URL
 /**
 * @description: Visa page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */

// ========================================================================
//  Calling for Final Book
// ========================================================================
export const savePaymentInfo = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_HOLD_BOOK_URL}/att/visa/postbook`,obj,headers).then(response => {      
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Calling for HOLD THE BOOK
// ========================================================================
export const fireHoldHotel = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotel/onholdbook `,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Calling for HOLD THE BOOK
// ========================================================================
export const updateHoldPayment = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_HOLD_BOOK_URL}/hotel/onholdpostbook`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
 
// ========================================================================
//  Calling for Final Book
// ========================================================================
export const checkHoldBookings = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_HOLD_BOOK_URL}/hotelonholdconfig/checkonholdconfig`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

