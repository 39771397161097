
/**
 * @description: Flight Summary page Flight details  section Segement details
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Form, Row,Table, Alert, Modal} from "react-bootstrap";
import FormateCurrency from '../../../commonUtils/FormateCurrency';
/**
 * @description:This function will Display the the Segment details
 * @author: Lakshmi
 * @param {*}
 * @function Pax details
 * @date : 18-09-2020
 */
function HotelRoomPricePdf(props) {
    //Calculating Total Fare details
  const calculateTotal = (price) => {
  if(props.invType==="Sales"){
   const total = price.reduce((prev, t) => {
       return prev + (t.rst==="Confirmed" && t.rmpr.salespr.tf);
   }, 0);
   return total;
  }else{
   const total = price.reduce((prev, t) => {
       return prev + (t.rst==="Confirmed" && t.rmpr.purpr.tf);
   }, 0);
   return total;
  }
 }
 console.log("roomsList",props.roomsList)

    return (
        <>

             <h3 className="pdfSubTitl">
               Price Details ({props.currency})
             </h3>
                <div className="header">
                    <Row>
                        <Col >Room(s)</Col>
                        <Col>Lead Guest</Col>
                        <Col>Room Type#</Col>
                        <Col>Base Fare</Col>
                        <Col align="center">Taxes & Fee</Col>
                        {props.invType==="Purchase" &&
                        <Col>Supplier Fee</Col>
                        }
                        {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="same" && props.region_type==="India" &&
                        <Col>cgst</Col>
                        }
                        {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="same" && props.region_type==="India" &&
                        <Col>sgst</Col>
                        }
                        {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="other" && props.region_type==="India" &&
                        <Col>igst</Col>
                        }
                        {props.region_type==="India" && props.type==="TA" && props.roomsList[0].rmpr.salespr.tds_val!=="" &&
                        <Col>TDS</Col>
                        }
                        {props.type!=="ATT" && props.invType==="Purchase" &&
                        <Col>Discount</Col>
                        }
                        <Col className="text-right">Total Price</Col>
                    </Row>
                   </div>
                <div className="passengerList">
                    {props.roomsList.map((room, index) => (
                        <React.Fragment key={index}>
                            {(room.rst==="Confirmed" ||room.rst=== "Cancelled") &&
                            <Row>
                                <Col >Room{index+1}</Col>
                                <Col >{room.rmpax[0].tl} {room.rmpax[0].fn} {room.rmpax[0].ln}</Col>
                                <Col >{room.rnm} </Col>
                                <Col>{props.invType==="Sales"?<FormateCurrency value={room.rmpr.salespr.bf + room.rmpr.salespr.markup + room.rmpr.salespr.onflymkp + room.rmpr.salespr.servicefee + room.rmpr.salespr.sup_fee - room.rmpr.salespr.tadisc}/>:<FormateCurrency value={room.rmpr.purpr.bf}/>}</Col>
                                <Col align="center">{props.invType==="Sales"?<FormateCurrency value={room.rmpr.salespr.tax + room.rmpr.salespr.sales_tax}/>:<FormateCurrency value={room.rmpr.purpr.tax + room.rmpr.purpr.sales_tax}/>}</Col>
                                {props.invType==="Purchase" &&
                                <Col><FormateCurrency value={room.rmpr.purpr.servicefee}/></Col>
                                }
                                {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="same" && props.region_type==="India" &&
                                <Col><FormateCurrency value={props.type=="TA"?props.fareDetails[0].rooms[0].rmpr.salespr.cgst_val:props.fareDetails[1].rooms[0].rmpr.salespr.cgst_val}/></Col>
                                }
                                {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="same" && props.region_type==="India" &&
                                <Col><FormateCurrency value={props.type=="TA"?props.fareDetails[0].rooms[0].rmpr.salespr.sgst_val:props.fareDetails[1].rooms[0].rmpr.salespr.sgst_val}/></Col>
                                }
                                {props.roomsList[0].rmpr.salespr.type!== undefined && props.roomsList[0].rmpr.salespr.type==="other" && props.region_type==="India" &&
                                <Col><FormateCurrency value={(props.type=="TA"&& props.invType==="Purchase")?props.fareDetails[0].rooms[0].rmpr.salespr.igst_val:props.fareDetails[1].rooms[0].rmpr.salespr.igst_cal}/></Col>
                                }
                                {props.region_type==="India" && props.type==="TA" && props.fareDetails!==undefined && props.fareDetails[0].rooms[0].rmpr.salespr.tds_val!=="" &&
                                <Col><FormateCurrency value={props.fareDetails[0].rooms[0].rmpr.salespr.tds_val}/></Col>
                                 }
                                {props.type==="TA" && props.invType==="Purchase" &&
                                <Col><FormateCurrency value={room.rmpr.purpr.mtadisc}/></Col>
                                 }
                                 {props.type==="STA" && props.invType==="Purchase" &&
                                <Col><FormateCurrency value={room.rmpr.purpr.tadisc}/></Col>
                                 }
                                <Col className="text-right">{props.invType==="Sales"?<FormateCurrency value={room.rmpr.salespr.tf}/>:<FormateCurrency value={room.rmpr.purpr.tf}/>}</Col>
                               </Row>
                               }
                        </React.Fragment>
                    ))}
                </div>
                {props.roomsList!==undefined &&
                    <div className="total">
                    <span>Grand Total : </span> <div className="pricetag"><em>({props.currency})</em> <strong> {<FormateCurrency value={calculateTotal(props.roomsList)} />}</strong></div>
                </div>
                }

        </>

    )
}


export default HotelRoomPricePdf;
