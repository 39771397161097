import React, { useState, useEffect, useReducer, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Table } from "react-bootstrap";
import Utilities from '../../../commonUtils/Utilities';
import RefundSummary from '../../../postBooking/refund/RefundSummary';
import { Context } from "../../../../../App";
import ShowHide from '../../../common/showHide/ShowHide';
import FormateCurrency from '../../../commonUtils/FormateCurrency';

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessageShow: false, notiMessage: '', notiVarient: '',remarks:"",attPaxPrice:[],taPaxPrice:[],staPaxPrice:[],showPopup:false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {

  switch (action.type) {
    case 'upprof':
            return Object.assign(state, action.payload);
    default:
        return { ...state, [action.type]: action.payload };
}
};
function ReissueFareDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [refundData , setRequest] =  useState(props.reissueData);
  const history = useHistory();


  const totalCharges = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.basefare + t.taxes+ t.bkmarkup+ t.bkservice+t.supcharge, "Add");
    }, 0);
    return Utilities.addPrice(total, 0, "Add");;
}

   //Calculating the ATT,TA and STA Summary
    useEffect(() => {
        if(refundData.fareinfo!==undefined){
            let attList=[];let taList=[];let staList=[];
            let gst_Obj = props.gstObj
            gst_Obj.type = gst_Obj.state_type
            if(context.logindata.region_type === "India"){
                for(let pax of refundData.fareinfo){
                    for(let i = 0; i<pax.price.length;i++){
                        // (flight.servicefee,state.response.gst,flight.pr.tax,props.srequest,"Flight");
                   let gst =  calculateGST(props.srequest.origns[0].srcorigns[0].serviceFee,gst_Obj,props.srequest.origns[0].srcorigns[0].pr.tax,props.srequest,"Flight")
                   pax.price[i].subtotal += gst
                   pax.price[i].taxes += gst
                }
                }
            }
            for(let pax of refundData.fareinfo){
                for(let price of pax.price){
                    if(price.type==="ATT"){
                        const att={
                           "ptype":pax.ptype,
                           "subtotal":price.subtotal,
                           "count":pax.count,
                           "refunSupPrice":Utilities.addPrice(price.subtotal,pax.count,"Cnt"),
                           "refundToCl":Utilities.addPrice(price.subtotal,pax.count,"Cnt")
                           }
                                attList.push(att)
                        }else if(price.type==="TA"){
                        const ta={
                            "ptype":pax.ptype,
                            "subtotal":price.subtotal,
                            "count":pax.count,
                            "refunSupPrice":Utilities.addPrice(pax.price[0].subtotal,pax.count,"Cnt"),
                            "refundToCl":Utilities.addPrice(price.subtotal,pax.count,"Cnt")
                            }
                            taList.push(ta);
                        }else if(price.type==="STA"){
                        const sta={
                            "ptype":pax.ptype,
                            "subtotal":price.subtotal,
                            "count":pax.count,
                            "refunSupPrice":Utilities.addPrice(pax.price[1].subtotal,pax.count,"Cnt"),
                            "refundToCl":Utilities.addPrice(price.subtotal,pax.count,"Cnt")
                            }
                            staList.push(sta);
                            }
                            }
                            pax.attlist=attList;
                            pax.talist=taList;
                            pax.stalist=staList;
                            dispatch({ type: 'attPaxPrice', payload: attList })
                            dispatch({ type: 'taPaxPrice', payload: taList })
                            dispatch({ type: 'staPaxPrice', payload: staList })
        }
        }
     }, []);
const calculateGST=(a,b,c,d,e)=>{
        let total=0;
        let gst={};
          gst = Utilities.applyGST(a,b,c,d,e);
          if(gst.gst_invoice.length!==0){
            for(let val of gst.gst_invoice){
              if(gst.type==="same"){
              if(total===0){
                total=Number(val.cgst_val)+Number(val.sgst_val);
              }else{
                total=total+Number(val.cgst_val)+Number(val.sgst_val);
              }
             }else{
              if(total===0){
                total=Number(val.igst_val);
              }else{
                total=total+Number(val.igst_val);
              }
             }
          }
        }
        gst.total_gst=total;
        // flight.gst=gst;
        dispatch({ type: 'gst_value', payload: total });
        return total
      }
    return (
        <>
            <div className="confirmation refundRecord reissuefare bg-white mb-4">
            <ShowHide visible="true" icon="priceDlr" title="Fare Details">
            <div className="showHide-content pl-0 pr-0 pb-0 pt-4">
                {refundData!==undefined && refundData.fareinfo!==undefined &&
                refundData.fareinfo.map((pax, index) => (
                <React.Fragment key={index}>
                <div className="resultSection boxShadow bg-white recordInfo tax mb-4 pb-0">
                    <div className="pasTicket">
                    <h5 className="title d-flex align-items-center">{index===0 && "Passenger Details : "}
                                        {pax.ptype==="ADT" &&
                                        <span className='d-inline-block ml-1'>Adult</span>
                                        }
                                        {pax.ptype==="CHD" &&
                                        <span className='d-inline-block ml-1'>Child</span>
                                        }
                                        {pax.ptype==="INF" &&
                                        <span className='d-inline-block ml-1'>Infant</span>
                                        } ( <span className="ezyIcon icon-person"></span> X {pax.count} )</h5>

                    <div className="perDetils">
                        <ul>
                        {pax.tktnum.map((tkt, index) => (
                            <li className="ezyIcon icon-confirmedOne"> {tkt.pname} <span> (Tkt# : {tkt.number})</span></li>
                            ))}
                            </ul>
                    </div>
                    </div>

                    <h5 className="subtitel ezyIcon icon-refundPrice mt-4">Ticket Details ({props.currency})</h5>
                    <Row className="m-0">
                        <Col xs={12}  className="p-0">
                            <Table>
                            {pax.price.map((price, index) => (
                            <React.Fragment key={index}>
                              {index===0 &&
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Fare Diffrence</th>
                                        <th>Supplier Fee</th>
                                        <th>Markup</th>
                                        <th>Service Fee</th>
                                        <th>Penalty</th>
                                        <th>Residual Value</th>
                                        <th>Taxes Diffrence</th>
                                        <th>Total Ticket Charges</th>
                                        <th className="noa">{pax.ptype==="ADT" &&
                                        <span>No. Of Adult</span>
                                        }
                                        {pax.ptype==="CHD" &&
                                        <span>No. Of Child</span>
                                        }
                                        {pax.ptype==="INF" &&
                                        <span>No. Of Infant</span>
                                        }</th>
                                        <th className="text-right">Sub Total Charges</th>
                                    </tr>
                                </thead>
                               }
                               {/* ATT Login */}

                                {context.logindata.utype==="att_user"  &&
                                    <>
                                    {price.type==="ATT" &&
                                    <tr>
                                        <td>
                                            <span>{price.type}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.basefare} />}</span>
                                        </td>
                                        <td>
                                        {price.type==="ATT" &&
                                          <span>0.00</span>
                                        }
                                        {price.type==="TA" &&
                                        <span>{<FormateCurrency value={pax.price[0].bkservice} />}</span>
                                        }
                                        {price.type==="STA" &&
                                        <span>{<FormateCurrency value={pax.price[1].bkservice} />}</span>
                                        }
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkmarkup} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.penalty} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.resdvalue} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.taxes} />}</span>
                                        </td>
                                        <td>
                                         <span>{<FormateCurrency value={price.subtotal} />}</span>
                                        </td>
                                        <td className="text-center noa">
                                            <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(price.subtotal,pax.count,"Cnt")} />}</span>
                                        </td>
                                    </tr>
                                   }

                                   {context.logindata.butype==="ta_user" && price.type==="TA" &&
                                    <tr>
                                        <td>
                                            <span>{price.type}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.basefare} />}</span>
                                        </td>
                                        <td>
                                        {price.type==="ATT" &&
                                          <span>0.00</span>
                                        }
                                        {price.type==="TA" &&
                                        <span>{<FormateCurrency value={pax.price[0].bkservice} />}</span>
                                        }
                                        {price.type==="STA" &&
                                        <span>{<FormateCurrency value={pax.price[1].bkservice} />}</span>
                                        }
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkmarkup} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.penalty} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.resdvalue} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.taxes} />}</span>
                                        </td>
                                        <td>
                                         <span>{<FormateCurrency value={price.subtotal} />}</span>
                                        </td>
                                        <td className="text-center noa">
                                            <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(price.subtotal,pax.count,"Cnt")} />}</span>
                                        </td>
                                    </tr>
                                   }
                                   {context.logindata.butype==="sta_user" && price.type==="STA" &&
                                    <tr>
                                        <td>
                                            <span>{price.type}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.basefare} />}</span>
                                        </td>
                                        <td>
                                        {price.type==="ATT" &&
                                          <span>0.00</span>
                                        }
                                        {price.type==="TA" &&
                                        <span>{<FormateCurrency value={pax.price[0].bkservice} />}</span>
                                        }
                                        {price.type==="STA" &&
                                        <span>{<FormateCurrency value={pax.price[1].bkservice} />}</span>
                                        }
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkmarkup} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.penalty} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.resdvalue} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.taxes} />}</span>
                                        </td>
                                        <td>
                                         <span>{<FormateCurrency value={price.subtotal} />}</span>
                                        </td>
                                        <td className="text-center noa">
                                            <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(price.subtotal,pax.count,"Cnt")} />}</span>
                                        </td>
                                    </tr>
                                   }
                                    </>
                                }
                                {/* TA Login */}
                                {context.logindata.utype==="ta_user" && price.type!=="ATT" &&

                                    <tr>
                                         <td>
                                            <span>{price.type}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.basefare} />}</span>
                                        </td>
                                        <td>
                                        {price.type==="TA" &&
                                        <span>{<FormateCurrency value={pax.price[0].bkservice} />}</span>
                                        }
                                        {price.type==="STA" &&
                                        <span>{<FormateCurrency value={pax.price[1].bkservice} />}</span>
                                        }
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkmarkup} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.penalty} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.resdvalue} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.taxes} />}</span>
                                        </td>
                                        <td>
                                         <span>{<FormateCurrency value={price.subtotal} />}</span>
                                        </td>
                                        <td className="text-center noa">
                                            <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(price.subtotal,pax.count,"Cnt")} />}</span>
                                        </td>
                                    </tr>

                                }
                                {/* STA Login */}
                                {context.logindata.utype==="sta_user" && price.type==="STA" &&
                                    <tr>
                                        <td>
                                            <span>{price.type}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.basefare} />}</span>
                                        </td>
                                        <td>
                                        <span>{<FormateCurrency value={pax.price[1].bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkmarkup} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.bkservice} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.penalty} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.resdvalue} />}</span>
                                        </td>
                                        <td>
                                            <span>{<FormateCurrency value={price.taxes} />}</span>
                                        </td>
                                        <td>
                                         <span>{<FormateCurrency value={price.subtotal} />}</span>
                                        </td>
                                        <td className="text-center noa">
                                            <span>{pax.count}</span>
                                        </td>
                                        <td className="text-right">
                                            <span>{<FormateCurrency value={Utilities.addPrice(price.subtotal,pax.count,"Cnt")} />}</span>
                                        </td>
                                    </tr>
                                }

                                 </React.Fragment>
                                ))}

                            </Table>
                        </Col>
                    </Row>

                </div>
                </React.Fragment>
                ))
                }
                <Row>
                    {/* ATT Login */}
                    {context.logindata.utype==="att_user" &&
                    <>
                    {state.attPaxPrice && state.attPaxPrice!==0 &&
                      <RefundSummary priceData={state.attPaxPrice} type={"ATT"} flow={"Charge"} flowType={"Ticket"} butype={context.logindata.butype}/>
                    }
                    {state.taPaxPrice && state.taPaxPrice.length!==0 &&
                      <RefundSummary priceData={state.taPaxPrice} type={"TA"} flow={"Charge"} flowType={"Ticket"} butype={"ta_user"}/>
                    }
                    {state.staPaxPrice && state.staPaxPrice.length!==0 && context.logindata.butype==="sta_user" &&
                      <RefundSummary priceData={state.staPaxPrice} type={"STA"} flow={"Charge"} flowType={"Ticket"} butype={"sta_user"}/>
                    }
                    </>
                    }
                    {/* TA Login */}
                    {context.logindata.utype==="ta_user" &&
                    <>
                    {state.taPaxPrice && state.taPaxPrice.length!==0 &&
                      <RefundSummary priceData={state.taPaxPrice} type={"TA"} flow={"Charge"} flowType={"Ticket"} butype={context.logindata.butype}/>
                    }
                    {state.staPaxPrice && state.staPaxPrice.length!==0 && context.logindata.butype==="sta_user" &&
                      <RefundSummary priceData={state.staPaxPrice} type={"STA"} flow={"Charge"} flowType={"Ticket"} butype={"sta_user"}/>
                    }
                    </>
                    }
                    {/* STA Login */}
                    {context.logindata.utype==="sta_user" && state.staPaxPrice && state.staPaxPrice!==undefined &&
                      <RefundSummary priceData={state.staPaxPrice} type={"STA"} flow={"Charge"} flowType={"Ticket"} butype={"sta_user"}/>
                    }
                    </Row>
                    </div>
                    </ShowHide>
            </div>
        </>
    )
}
export default ReissueFareDetails