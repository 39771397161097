
import React from 'react';
import { Col, Row } from "react-bootstrap";
import { isArrayNotEmpty } from '../../commonUtils/validators';
const JSON = require('circular-json');


function VoidSector(props) {


    return (
        <>
            <h5 className="title mb-2 d-flex justify-content-between">Voided Details</h5>

            <div className="header">
                <Row>
                    <Col xs="3">Traveller</Col>
                    <Col xs="3">Ticket Numbers</Col>
                    <Col xs="2">Fare</Col>
                    <Col xs="2">Void Fee</Col>
                    <Col xs="2" className="text-right">Refund Amount</Col>
                </Row>

            </div>
            {isArrayNotEmpty(props.res) && (props.res.map((obj, index) => (
                <React.Fragment key={index}>
                    <Row>
                        <Col xs="3">{obj.pn} {obj.lp && obj.lp === 1 ? "(Lead)" : ""}</Col>
                        <Col xs="3">{obj.tkt}</Col>
                        <Col xs="2">QAR {obj.tf}</Col>
                        <Col xs="2">QAR {obj.sf}</Col>
                        <Col xs="2" className="text-right">QAR {obj.ra}</Col>
                    </Row>
                </React.Fragment>
            )))}
            <div className="total d-flex justify-content-end">
                <span className="mr-2">Grand Total  : </span> <strong > QAR {props.rfAmount}</strong>
            </div>

        </>
    )
}


export default VoidSector;
