import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Button,Tab, Tabs } from "react-bootstrap";
import ShowHide from '../../../common/showHide/ShowHide';

function VisaTransactionsSummary(props) {
   const [fareRes, setFareRes] = useState(props.fareRes.req);
   const [fnRes, setFnRes] = useState(false);
   const [trvelr_detels, setTrvelr_detels] = useState();

   useEffect(()=>{
      if(props.trvelr_detels){
         setTrvelr_detels(props.trvelr_detels)}
         if (props.fareRes?.res?.pax_info){
            for (let fn of props.fareRes?.res?.pax_info){
               if (fn.type=="ADT"){
                  for (let isFn of fn.pax){
                     if (isFn.name.toUpperCase()==("FIRST NAME"||"FIRSTNAME")){
                  setFnRes(true)
                }
               }
               } else if (fn.type=="CHD"){
                  for (let isFn of fn.pax){
                     if (isFn.name.toUpperCase()==("FIRST NAME"||"FIRSTNAME")){
                  setFnRes(true)
                }
              }
           }
         }}
      },[props.trvelr_detels])
    
    return (
        <>
               <div className="boxLayout costLayout summeryVisasec">
                 <div className="dropContent">
                 <div class="costBox">
   <div class="costList">
          <div class="dropContent">
         <div class="pList pb-2">
            <span>Travelling To</span>
            <div class="pricetag"><strong><span>{fareRes.trvel_cnt}</span> </strong></div>
         </div>
         <div class="pList pb-2">
         <span>Visa Type</span>
            <div class="pricetag"><strong><span>{fareRes.visa_type}</span> </strong></div>
         </div>
         <div class="pList pb-2">
         <span>Entry Type</span>
            <div class="pricetag"><strong><span>{fareRes.dur+" "+fareRes.dur_type+" "+ fareRes.visa}</span> </strong></div>
         </div>
      </div>
   </div>
   {fnRes && <div className="costList brdcostVisa">
   <ShowHide visible={true} icon="xxx" title="Traveller Detail">
    <div className="PaxvisDetails">
      {trvelr_detels && trvelr_detels.map((item, index)=>(
        <ul>
             {item.fn!=undefined && item.fn!=""&&<li>{item.fn} {item.ty!=undefined &&"("+item.ty+")"}</li>}
        </ul>
    ))}
    </div>
    </ShowHide>
   </div>}
   <div className="costList brdcostVisa">
   {fareRes.adt_cnt>0 &&
           <div class="pList pb-2">
             <span className="adultVisa">Adult (<span className="ezyIcon icon-person"></span> X {fareRes.adt_cnt} ) </span>
            <div class="pricetag"><strong><span>{fareRes.cur} {fareRes.adt_pr *fareRes.adt_cnt}</span> </strong></div>
         </div>}
         {fareRes.chd_cnt>0 && <div class="pList pb-2">
            <span className="adultVisa">Child (<span className="ezyIcon icon-person"></span> X {fareRes.chd_cnt} ) </span>
            <div class="pricetag"><strong><span>{fareRes.cur} {fareRes.chd_pr * fareRes.chd_cnt}</span> </strong></div>
         </div>}
 
   </div>
   <div class="costList total">
      <div class="pList">
         <span>Total Amount</span>
         <div class="pricetag">{fareRes.cur} <strong> <span> {(fareRes.adt_pr*fareRes.adt_cnt) + (fareRes.chd_pr*fareRes.chd_cnt)}</span> </strong></div>
      </div>
      <p>Taxes and Fees Included</p>
   </div>

</div>
                 </div>
               
                </div>

        </>
   );

}

export default VisaTransactionsSummary;