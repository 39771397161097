
import React, { useState } from 'react';
import Utilities from '../../../commonUtils/Utilities';
import moment from 'moment'

function BooingDetailsPdf(props) {
    const [state, setState] = useState({})
    const [flightDetails, setFlightDetails] = useState(false);
    const [htlData, setHtlData] = useState(props.htldetails)



    return (
        <>

            <div className="confirmHotel">
                <div className="img"> <img
                    src={htlData.htimg}
                    className="htlImg"
                    title="Hotel"
                    alt="Hotel"
                /></div>
                <div className="sumryInfo"> <h5>{htlData.htnm} </h5>
                    <span className="stars">
                        {Utilities.getHotelStartRating(htlData.rating).map((star, idx) => (
                            <React.Fragment key={idx}>
                                <span className="ezyIcon icon-stat"></span>
                            </React.Fragment>
                        ))}
                    </span>


                    <p className="place ezyIcon icon-map"> {htlData.add}, {htlData.city}, {htlData.country}</p>
                    <p className="place">{htlData.phone_no !== undefined && htlData.phone_no !== null && <span className="place ezyIcon icon-phone"> {htlData.phone_no}</span>} {htlData.email !== undefined && htlData.email !== null && htlData.email !== '' && <span className="place ezyIcon icon-mail"> {htlData.email}</span>} </p>

                    <div className="adderss">
                        <p>Nights : <span> {htlData.nights}</span></p>
                        <p>Rooms : <span> {htlData.noofrooms}</span></p>
                        <p>Check-In :

                            <span>

                            {moment(htlData.cin, 'YYYY-MM-DD HH:mm').format(' DD-MM-YYYY HH:mm')}</span>
                            {/* <span>

                            {DateUtils.prettyDate(htlData.cin, "ddd, MMM DD, YYYY ")}</span> */}
                            </p>
                        <p>Check-Out :
                            <span>{moment(htlData.cout, 'YYYY-MM-DD HH:mm').format(' DD-MM-YYYY HH:mm')}</span>
                        </p>

                    </div>
                </div>
            </div>

        </>
    )
}


export default BooingDetailsPdf;
