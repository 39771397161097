import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
// const API_URL = "http://localhost:3005"

/**
 * @author: Lakshmi
 * @description: Integrated the User and Role CURD Operations service
 * @param {*} Service 
 */
class UserRoleManagementService {
    //User service
    async getUserData() {
        return await axios.get(`${API_URL}/att/user/retrive`);
    }

    async saveUserDetails(userData) {
        console.log('data', userData);
        return await axios.post(`${API_URL}/att/user/create`,userData)
    }

    async updateUserDetails(userData) {
        return await axios.put(`${API_URL}/att/user/update/${userData.usreq.uid}`,userData.usreq)
    }

    //Role service
    async getRolesData() {
        return await axios.get(`${API_URL}/att/role/retrieve`);
    }

    async saveRoleDetails(roleData) {
        return await axios.post(`${API_URL}/att/role/create`,roleData)
    }

    async updateRoleDetails(roleData) {
        return await axios.post(`${API_URL}/att/role/update/${roleData.id}`,roleData)
    }
    
    async getUserSearch(data) {
        return await axios.post(`${API_URL}/att/user/userSearch`,data)
    }

    async getRolesSearch(data) {
        return await axios.post(`${API_URL}/att/role/rolesSearch`,data)
    }

}

export default new UserRoleManagementService()