import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { Navbar } from "react-bootstrap";
import FlightSearch from '../../../landing/flight/FlightSearch';
import ShowItinarary from './ShowItinarary';
export const EXIT_ITINARARY = "extItinarary";
export const EXIT_STORE = "is_store";
const initialState = {
  srcDate: "", destDate: ""
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


//This function will show the Air markups List and Add Markup
function ModifySearch(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modifySearch, setModifySearch] = useState(false);
  const [response, setResponse] = useState();
  const [request, setRequest] = useState(props.sreq);
  const [results, setResults] = useState({ totalsize: [0] });
  const [initreq,setinitreq] = useState(props.initreq)
  const [existItinarary,setExistItnarary] = useState(undefined)

  const openSearch = () => {
    if (modifySearch) {
      setModifySearch(false)
    } else {
      setModifySearch(true)
    }
  }
  useEffect(() => {
    if(props.flow === "REISSUE" && props.response === undefined) {
      localStorage.setItem(EXIT_ITINARARY,JSON.stringify(props.initreq))
      localStorage.setItem(EXIT_STORE,true)
      setModifySearch(true)
    } else {
      setModifySearch(false)
    }
    if (props.response) {
      if (props.response.status === "success") {
        if (props.response.data[0] !== undefined && props.response.data[0].origns) {
          setResults({ totalsize: props.response.data[0].origns.length })
        }

      }
    }
  }, [props.response]);

  useEffect(() => {
    let store=JSON.parse(localStorage.getItem(EXIT_STORE));
    if(existItinarary===undefined && store && props.flow === "REISSUE"){
      let itinaray=JSON.parse(localStorage.getItem(EXIT_ITINARARY));
      setExistItnarary(itinaray)
    }
  }, []);
  useEffect(() => {
    if(props.countSRP !== undefined && sessionStorage.getItem("MODIFY_SEARCH")==="no"){
    setResults({ totalsize: props.countSRP})
    }
  }, [props.countSRP]);

  return (
    <>
 <Navbar className="searchBarnav" sticky="top">
      <section className="modifySearch">
        <div className="searchInfo">
          <div className="container">
            <div className="modifycontainer">
            {props.flow == "REISSUE" &&
             <h5 className="existingtitle">Existing Itinerary</h5>
              }
              {window.location.pathname !== "/ezytrip/dashboard/reissue" ?
            (<div className="modifySearchInfo">
              {request.sty === "normal" && props.flow !== "REISSUE" &&
                <div className="resultCount">
                  Showing <strong>{results.totalsize} flights</strong>
                </div>
              }
              {request.tt !== 3 &&
                <div className="rout d-flex flex- c">
                  <div className="routsList"><span className="rtInfo">
                    {request.srcCity} ({request.srcCode})</span><span className={request.tt === 2 ? "ezyIcon icon-fromTo" : "ezyIcon icon-oneside"}></span>
                    <span className="rtInfo">{request.destCity} ({request.destCode})</span>
                  </div>
                </div>
              }
              {request.tt == 2 && <div className="dateInfo flex-column"><div className="trvelDate">{request.srcDate} - {request.destDate}</div></div>}
              {request.tt == 1 && <div className="dateInfo flex-column"><div className="trvelDate">{request.srcDate}</div></div>}

              {request.tt === 3 && existItinarary===undefined &&
                <div className="rout d-flex flex-column b">
                  <div className="routsList"><span className="rtInfo">
                    {request.search.dcty1} ({request.search.dep1})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo">{request.search.acty1} ({request.search.arr1}) </span>  <span className="multiDate">{request.search.deptOne}</span>
                  </div>
                  {request.search.dcty2 !== undefined && request.search.dep2 !== "" && request.search.arr2 !== undefined && request.search.arr2 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {request.search.dcty2} ({request.search.dep2})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {request.search.acty2} ({request.search.arr2}) </span>  <span className="multiDate">{request.search.deptTwo}</span>
                    </div>
                  }
                  {request.search.dep3 !== undefined && request.search.dep3 !== "" && request.search.deptThree!=="" && request.search.arr3 !== undefined && request.search.arr3 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {request.search.dcty3} ({request.search.dep3})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {request.search.acty3} ({request.search.arr3}) </span>  <span className="multiDate">{request.search.deptThree}</span>
                    </div>
                  }
                  {request.search.dep4 !== undefined && request.search.dep4 !== "" && request.search.deptFour!=="" && request.search.arr4 !== undefined && request.search.arr4 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {request.search.dcty4} ({request.search.dep4})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {request.search.acty4} ({request.search.arr4}) </span>  <span className="multiDate">{request.search.deptFour}</span>
                    </div>
                  }
                  {request.search.dep5 !== undefined && request.search.dep5 !== "" && request.search.deptFive!=="" && request.search.arr5 !== undefined && request.search.arr5 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {request.search.dcty5} ({request.search.dep5})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {request.search.acty5} ({request.search.arr5}) </span>  <span className="multiDate">{request.search.deptFive}</span>
                    </div>
                  }
                  {request.search.dep5 !== undefined && request.search.dep5 !== "" && request.search.deptSix!=="" && request.search.arr6 !== undefined && request.search.arr6 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {request.search.dcty6} ({request.search.dep6})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {request.search.acty6} ({request.search.arr6}) </span>  <span className="multiDate">{request.search.deptSix}</span>
                    </div>
                  }
                </div>
              }
              {request.tt === 3 && existItinarary===undefined &&
                <div className="paxInfo"> <span>Travellers : {request.search.adt + request.search.chd + request.search.inf}</span> <span>Class : {request.search.cls}</span></div>
              }
              {request.tt !== 3 &&
                <div className="paxInfo"> <span>Travellers : {request.adt + request.chd + request.inf}</span> <span>Class : {request.clstyp}</span></div>
              }
            </div>) : (<div className="modifySearchInfo">
              {initreq.tt !== 3 &&
                <div className="rout d-flex flex- c">
                  <div className="routsList"><span className="rtInfo">
                    {initreq.srcCity} ({initreq.srcCode})</span> <span className={initreq.tt === 2 ? "ezyIcon icon-fromTo" : "ezyIcon icon-oneside"}></span>
                    <span className="rtInfo">{initreq.destCity} ({initreq.destCode})</span>
                  </div>
                </div>
              }
              {initreq.tt == 2 && <div className="dateInfo flex-column"><div className="trvelDate">{initreq.srcDate} - {initreq.destDate}</div></div>}
              {initreq.tt == 1 && <div className="dateInfo flex-column"><div className="trvelDate">{initreq.srcDate}</div></div>}

              {initreq.tt === 3 && existItinarary===undefined && props.flow === "REISSUE" &&
                <div className="rout d-flex flex-column b">
                  <div className="routsList"><span className="rtInfo">
                    {initreq.search.dcty1} ({initreq.search.dep1})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty1} ({initreq.search.arr1}) </span>  <span className="multiDate">{initreq.search.deptOne}</span>
                  </div>
                  {initreq.search.dcty2 !== undefined && initreq.search.dep2 !== "" && initreq.search.arr2 !== undefined && initreq.search.arr2 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {initreq.search.dcty2} ({initreq.search.dep2})</span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty2} ({initreq.search.arr2}) </span>  <span className="multiDate">{initreq.search.deptTwo}</span>
                    </div>
                  }
                  {initreq.search.dep3 !== undefined && initreq.search.dep3 !== "" && initreq.search.deptThree!=="" && initreq.search.arr3 !== undefined && initreq.search.arr3 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {initreq.search.dcty3} ({initreq.search.dep3}) </span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty3} ({initreq.search.arr3}) </span>  <span className="multiDate">{initreq.search.deptThree}</span>
                    </div>
                  }
                  {initreq.search.dep4 !== undefined && initreq.search.dep4 !== "" && initreq.search.deptFour!=="" && initreq.search.arr4 !== undefined && initreq.search.arr4 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {initreq.search.dcty4} ({initreq.search.dep4}) </span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty4} ({initreq.search.arr4}) </span>  <span className="multiDate">{initreq.search.deptFour}</span>
                    </div>
                  }
                  {initreq.search.dep5 !== undefined && initreq.search.dep5 !== "" && initreq.search.deptFive!=="" && initreq.search.arr5 !== undefined && initreq.search.arr5 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {initreq.search.dcty5} ({initreq.search.dep5}) </span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty5} ({initreq.search.arr5}) </span>  <span className="multiDate">{initreq.search.deptFive}</span>
                    </div>
                  }
                  {initreq.search.dep5 !== undefined && initreq.search.dep5 !== "" && initreq.search.deptSix!=="" && initreq.search.arr6 !== undefined && initreq.search.arr6 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {initreq.search.dcty6} ({initreq.search.dep6}) </span> <span className="ezyIcon icon-oneside"></span> <span className="rtInfo"> {initreq.search.acty6} ({initreq.search.arr6}) </span>  <span className="multiDate">{initreq.search.deptSix}</span>
                    </div>
                  }
                </div>
              }
              {initreq.tt === 3 && existItinarary===undefined &&
                <div className="paxInfo"> <span>Travellers : {initreq.search.adt + initreq.search.chd + initreq.search.inf}</span> <span>Class : {initreq.search.cls}</span></div>
              }
              {initreq.tt !== 3 &&
                <div className="paxInfo"> <span>Travellers : {initreq.adt + initreq.chd + initreq.inf}</span> <span>Class : {initreq.clstyp}</span></div>
              }
            </div>)
              }
              {initreq.tt === 3 && existItinarary!==undefined && props.flow === "REISSUE" &&
                <ShowItinarary existItinarary={existItinarary} segments ={props.segments} class={request.search.cls} />
              }
            </div>
            {request.bktype!==undefined && request.bktype!=="IMPORT/REISSUE" &&
            <Link to="#" onClick={openSearch} className={"changeSearch" + (modifySearch ? ' active' : '')}>Change Search <span className="ezyIcon icon-arrowDown"></span></Link>
            }

          </div>
        </div>
        {modifySearch && request.bktype!=="IMPORT/REISSUE" &&
          <div className="container">
            <FlightSearch search="modifySearch" flowType={props.flow} stopButtonLoad={props.stopButtonLoad} srequest={window.location.pathname !== "/ezytrip/dashboard/reissue" ? request : initreq} load={props.load} dateAvailable={props.dateAvailable} routeAvailable={props.routeAvailable} oldCls={props.oldCls} onClickHandler={props.onClickHandler}/>
          </div>
       }

      </section>
 <LoadingBar
       color='#02367B'
       progress={100}
       className='proGressbar'
       containerClassName='proGresbartop'
              />

      </Navbar>
    </>
  )
}
export default ModifySearch