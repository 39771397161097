
import React, { useState, useRef, useReducer, useContext,useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import DateUtils from '../../../../commonUtils/DateUtils';
import Utilities from '../../../../commonUtils/Utilities';
import useOutsideClick from '../../../../common/OutsideClick';

//Initial State
const initialState = {
    response: {}, finalResponse: {}, sortList: false, listView: true, columnView: false, fastestPrice: 0,dprt:false,arvl:false,trdp:false,prc:false,
    activeTab: 'cheapest',arvllatest:false,trdpearliest:false,prclowest:false,dprtlatest:false
}


//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {

        case 'min':
            return { ...state, price: action.payload };
        case 'clear':
            return {...state,sortList: false, listView: true, columnView: false,dprt:false,arvl:false,trdp:false,prc:false,
                activeTab: 'cheapest',arvllatest:false,trdpearliest:false,prclowest:false,dprtlatest:false}
        default:
            return { ...state, [action.type]: action.payload };
    }
};
function SortowSplit(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [secDet, setSecDet] = useState(props.sectors)
    const [mappings, setMappings] = useState(props.mapping);
    const [response, setResponse] = useState(props.searchRes);
    const [sortOpt, setSortOpt] = useState('Price');
    const [soddShowHide, setSoddShowHide] = useState(false);
    const [fastestPrice, setFastestPrice] = useState(props.searchRes.filter.fstst);

    const ref = useRef();

    const sortOptDropDown = () => {
        if (soddShowHide) {
            setSoddShowHide(false)
        } else {
            setSoddShowHide(true)
        }
    }
    useOutsideClick(ref, () => {
        setSoddShowHide(false)
    });

    useEffect(() => {
        const searcRs = props.searchRes;
        dispatch({ type: 'response', payload: searcRs })
        dispatch({ type: 'finalResponse', payload: Object.assign({}, searcRs) })
        dispatch({ type: 'fastestPrice', payload: searcRs.filter.fstst })
        if(props.sortClear) {
            dispatch({type:'clear',payload:true})
        }
    }, [props.searchRes])

    useEffect(() => {
        if(props.filterSorted) {
            dispatch({ type: 'sortList', payload: false })

        }
    },[props.filterSorted])


      //Calculation the DISC
      const calcuateServiceFee = (serviceFeeList) => {
        var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
        let servicefee = 0;
        if (serviceFeeList !== undefined) {
          servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
          if(servicefee==="NaN"){
            return 0.00;
          }else{
            return servicefee;
          }
        } else {
          return 0.00;
        }
      }


        //Calculation the DISC
        const calculateDisc = (taxes) => {
            if (taxes !== undefined) {
              for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
                var mak = taxes[taxIndex];
                if (mak !== undefined && mak.dealmkp!==undefined && mak.dealmkp !== 0) {
                  return mak.dealmkp;
                } else {
                  return 0.00;
                }
              }
            } else {
              return 0.00;
            }
          }

    //Price Sort
    const priceSort = (filt) => {
        if (filt === 'cheapest') {
            dispatch({ type: 'activeTab', payload: 'cheapest' })
        }
        if (filt === 'best') {
            dispatch({ type: 'activeTab', payload: 'best' })
        }
        dispatch({ type: 'sortList', payload: false });
        let results;
        if(filt==='lowest'){
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: true });

             results = state.finalResponse.origns.sort(function (one, two) {
                let oneprice = one.pr.tf
                let twoprice = two.pr.tf
                if(one.pr.tax && two.pr.tax) {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Add")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Add")
                } else if(props.srequest.flowType == undefined || props.srequest.flowType !== "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Sub")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Sub")
                }
                return (oneprice - twoprice)
            });
        }
        else{
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: true });
            dispatch({ type: 'prclowest', payload: false });
            results = state.finalResponse.origns.sort(function (one, two) {
                let oneprice = one.pr.tf
                let twoprice = two.pr.tf
                if(one.pr.tax && two.pr.tax) {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Add")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Add")
                } else if(props.srequest.flowType == undefined || props.srequest.flowType !== "REISSUE") {
                    oneprice = Utilities.addPrice(one.pr.tf + calcuateServiceFee(one.servicefee), calculateDisc(one.pr.tax), "Sub")
                    twoprice = Utilities.addPrice(two.pr.tf + calcuateServiceFee(two.servicefee), calculateDisc(two.pr.tax), "Sub")
                }
                return (twoprice - oneprice)
            });
        }



        state.response.origns = results;
        props.handelChange(state.response,'')

    }

    //Departure Date and Time Sort
    const departureTimeSort = (e) => {

            dispatch({ type: 'sortList', payload: false })
            let res ;

            if(e==='earliest'){
                dispatch({ type: 'dprt', payload: true });
                dispatch({ type: 'dprtlatest', payload: false });
                dispatch({ type: 'arvl', payload: false });
                dispatch({ type: 'arvllatest', payload: false });
                dispatch({ type: 'trdp', payload: false });
                dispatch({ type: 'trdpearliest', payload: false });
                dispatch({ type: 'prc', payload: false });
                dispatch({ type: 'prclowest', payload: false });

                 res = state.finalResponse.origns.sort((one, two) => {
                    if (DateUtils.isAfter(one.srcorigns[0].seg[0].dd, two.srcorigns[0].seg[0].dd)) {
                        return 1;
                    }
                    else return -1;
                })
            }
            else{
                dispatch({ type: 'dprt', payload: false });
                dispatch({ type: 'dprtlatest', payload: true });
                dispatch({ type: 'arvl', payload: false });
                dispatch({ type: 'arvllatest', payload: false });
                dispatch({ type: 'trdp', payload: false });
                dispatch({ type: 'trdpearliest', payload: false });
                dispatch({ type: 'prc', payload: false });
                dispatch({ type: 'prclowest', payload: false });

                 res = state.finalResponse.origns.sort((one, two) => {
                    if (DateUtils.isBefore(one.srcorigns[0].seg[0].dd, two.srcorigns[0].seg[0].dd)) {
                        return 1;
                    }
                    else return -1;
                })

            }


            state.response.origns = res;
            props.handelChange(state.response,'')

    }

    //Departure Date and Time Sort
    const arrivalTimeSort = (e) => {
        dispatch({ type: 'sortList', payload: false });
        let res ;
        if(e==='earliest'){

            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: true });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

            res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isAfter(one.srcorigns[0].seg[(one.srcorigns[0].seg.length - 1)].ad, two.srcorigns[0].seg[(two.srcorigns[0].seg.length - 1)].ad)) {
                    return 1;
                }
                else return -1;
            })
        }
        else{
            dispatch({ type: 'arvllatest', payload: true });
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

             res = state.finalResponse.origns.sort((one, two) => {
                if (DateUtils.isBefore(one.srcorigns[0].seg[(one.srcorigns[0].seg.length - 1)].ad, two.srcorigns[0].seg[(two.srcorigns[0].seg.length - 1)].ad)) {
                    return 1;
                }
                else return -1;
            })
        }
        state.response.origns = res;
        props.handelChange(state.response,'')
    }

    // Duration Sort
    const tripDurationSort = (e) => {
        var selClass = document.querySelectorAll('.fdnextOpen');
        for (var s = 0; s < selClass.length; s++) {
            selClass[s].classList.remove("fdnextOpen");
        }
        dispatch({ type: 'activeTab', payload: 'fastest' })
        dispatch({ type: 'sortList', payload: false })
        let res = [];
        state.finalResponse.origns.forEach(flights => {
            let results = flights.srcorigns.sort(function (one, two) {
                if (DateUtils.isAfterTime(one.sdur, two.sdur)) {
                    return 1;
                }
                else return -1;
            });


            flights.srcorigns = [];
            flights.srcorigns = results;
            res.push(flights)
        });
        state.response.origns = res;

        if(e==='shortest'){
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: true });
            dispatch({ type: 'trdpearliest', payload: false });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

            state.response.origns.sort((one, two) => {
                if (DateUtils.isAfterTime(one.srcorigns[0].sdur, two.srcorigns[0].sdur)) {
                    return 1;
                }
                else return -1;
            })
        }
        else{
            dispatch({ type: 'dprt', payload: false });
            dispatch({ type: 'dprtlatest', payload: false });
            dispatch({ type: 'arvl', payload: false });
            dispatch({ type: 'arvllatest', payload: false });
            dispatch({ type: 'trdp', payload: false });
            dispatch({ type: 'trdpearliest', payload: true });
            dispatch({ type: 'prc', payload: false });
            dispatch({ type: 'prclowest', payload: false });

            state.response.origns.sort((one, two) => {
                if (DateUtils.isBeforeTime(one.srcorigns[0].sdur, two.srcorigns[0].sdur)) {
                    return 1;
                }
                else return -1;
            })
        }

        let idx = 0;
        for (let index = 0; index < state.response.origns.length; index++) {
            if (index <= 3 && state.response.origns[index].pr.tf === state.fastestPrice) {
                idx = index;
                break;
            }
        }

        if (idx !== 0) {
            [state.response.origns[idx], state.response.origns[0]] = [state.response.origns[0], state.response.origns[idx]];
        }

        props.handelChange(state.response,'')
    }

    return (
        <>
            <div className="rs-headerBlock">
                <Row className="rs-row">

                    <Col xs={7} className="rs-rTypeDate d-flex flex-column">
                        <div className='d-flex flex-row'>
                        <span className="departure ezyIcon icon-flight">Departure</span>
                        <span>{DateUtils.convertToLetterDate(secDet[0].dd)}</span>
                        </div>
                        <div className='destination'>
                        <span className='cityNamed d-flex'> <span>{secDet[0].src}</span> <span className="ezyIcon icon-oneside"></span> <span>{secDet[0].des}</span></span>

                        </div>
                    </Col>


                    <Col xs={5} className="rs-FromTo">
                        <Row className="rs-FromTo-info align-items-center m-0">

                            <Col className="fbfFilter d-flex flex-column">
                                <div className="fbfSort" onClick={sortOptDropDown} ref={ref}>
                                    <label><strong>Sort By:</strong> <span>{state.dprt ? 'Departure (Earliest)' : state.dprtlatest ? 'Departure (Latest)' : state.arvl ? 'Arrival (Earliest)' : state.arvllatest ? 'Arrival (Latest)' : state.trdp ? 'Duration (Shortest)' : state.trdpearliest ? 'Duration (Longest)' :state.prc ? 'Price (Highest)' : state.prclowest ? 'Price (Lowest)' : ''}</span> </label>

                                    <div className={"dropDownPopUP " + (soddShowHide ? "d-block" : "d-none")}>
                                        <ul className="m-0 p-0 b-flex">
                                        <li className={state.dprt ? 'active' : 'inactive'}><Link onClick={()=>departureTimeSort('earliest')}>Departure (Earliest)</Link></li>
                                        <li className={state.dprtlatest ? 'active' : 'inactive'}><Link onClick={()=>departureTimeSort('latest')}>Departure (Latest)</Link></li>
                                        <li className={state.arvl ? 'active ' : 'inactive'}><Link onClick={()=>arrivalTimeSort('earliest')}>Arrival (Earliest)</Link></li>
                                        <li className={state.arvllatest ? 'active ' : 'inactive'}><Link onClick={()=>arrivalTimeSort('latest')}>Arrival (Latest)</Link></li>
                                        <li className={state.trdp ? 'active' : 'inactive'}><Link onClick={()=>tripDurationSort('shortest')}>Duration (Shortest)</Link></li>
                                        <li className={state.trdpearliest ? 'active' : 'inactive'}><Link onClick={()=>tripDurationSort('earliest')}>Duration (Longest)</Link></li>
                                        <li className={state.prc ? 'active' : 'inactive'}><Link onClick={()=>priceSort('highest')}>Price (Highest)</Link></li>
                                        <li className={state.prclowest ? 'active' : 'inactive'}><Link onClick={()=>priceSort('lowest')}>Price (Lowest)</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <span>

                        </span>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default SortowSplit;
