import React, { useEffect, useReducer, useState } from 'react';
import { Col, Row, Modal } from "react-bootstrap";
import AirlinesMemo from '../../../../common/airlines';
import Button from '../../../../common/buttons/Button';
import FlightDetails from '../../common/FlightDetails';
import ResultSectionHeader from './ResultSectionHeader';
import Utilities from '../../../../commonUtils/Utilities';
import DateUtils from '../../../../commonUtils/DateUtils';
import ResultBlockLoader from '../../../../bookingFlows/flight/searchLoaders/ResultBlockLoader'
import FormateCurrency from '../../../../commonUtils/FormateCurrency';
import TravelFusion from '../common/TravelFusion';
import {getPriceInfo,getSalamAirBaggage, getRiyaAirBaggage} from '../../review/operations';
export const SEL_AIR_PRICE = "selAirPrice";
export const SEL_FLT_COMBO = "selFltCombo";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
export const CLIENT_IP = "clientIp";
let isPriceCheck=true;
//Initial State
const initialState = {
    currency: '', flights: [], tripType: '', sectors: [], secType: '', serviceFee: 0,gst_value:0,
    mapping: [], price: undefined, selOnward: "", selRetun: "", sty: 'normal', secTypeIndex: '', showtravelFusion: false, activeClass: false,selTFlight:"", enableTf:false,flightDetailsClicked:false
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

const reducer = (state, action) => {
    switch (action.type) {

        case 'test':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function ResultBlock(props) {
    const [isItineraryLoading , setIsItineraryLoading] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!(props.secTypeIndex !== undefined && props.secTypeIndex.includes('Sector'))) {
            var selClass = document.querySelectorAll('.selected');
            if (selClass.length > 0 && parseInt(state.tripType) !== 1)
                for (var s = 0; s < selClass.length; s++) {
                    selClass[s].classList.remove("selected");
                }
        }
        dispatch({ type: 'currency', payload: props.currency });
        dispatch({ type: 'flights', payload: props.searchRes });
        dispatch({ type: 'tripType', payload: props.trip });
        dispatch({ type: 'sectors', payload: props.sect });
        dispatch({ type: 'secType', payload: props.secType });
        dispatch({ type: 'mapping', payload: props.mapping });
        dispatch({ type: 'price', payload: props.price });
        dispatch({ type: 'sty', payload: props.sty });
        dispatch({ type: 'secTypeIndex', payload: props.secTypeIndex });
        calcuateServiceFee(props.sectors.servicefee);
        if(props.sectors.gst!==undefined && props.sty==="normal"){
            dispatch({ type: 'gst_value', payload: props.sectors.gst.total_gst });
        }else if(props.gst!==undefined && props.gst.type!==undefined && props.srequest!==undefined && props.srequest.region_type==="India"){
            calculateGST(props.sectors)
        }
       
        //window.sessionStorage.removeItem(SEL_AIR_PRICE);
    }, [props.searchRes]);

const calculateGST=(flight)=>{
        let total=0;
        let gst={};
          gst = Utilities.applyGST(flight.servicefee,props.gst,flight.pr.tax,props.srequest,"Flight");
          if(gst.gst_invoice.length!==0){
            for(let val of gst.gst_invoice){
              if(gst.type==="same"){
              if(total===0){
                total=Number(val.cgst_val)+Number(val.sgst_val);
              }else{
                total=total+Number(val.cgst_val)+Number(val.sgst_val);
              }
             }else{
              if(total===0){
                total=Number(val.igst_val);
              }else{
                total=total+Number(val.igst_val);
              }
             }
          }
        }
        gst.total_gst=total;
        flight.gst=gst;
        dispatch({ type: 'gst_value', payload: total });
      }
    
    const showFlightInfo = (event) => {
       if (event.target.parentNode.classList.contains("fdnextOpen")) {
            event.target.parentNode.classList.remove("fdnextOpen");
            event.target.classList.remove("active");
        } else {
            var containerSelinfo = document.querySelectorAll(`.flightDetailsLink`);
            for (var i = 0; i < containerSelinfo.length; i++) {
                containerSelinfo[i].classList.remove("active");
            }
            var containerSelnxtopen = document.querySelectorAll(`.rsb-bottom`);
            for (var i = 0; i < containerSelnxtopen.length; i++) {
                containerSelnxtopen[i].classList.remove("fdnextOpen");
            }
            var containerSeltravel = document.querySelectorAll(`.flightDetailsLink`);
            for (var i = 0; i < containerSeltravel.length; i++) {
                containerSeltravel[i].classList.remove("activeme");
            }
            var containerSelbottom = document.querySelectorAll(`.rsb-bottom`);
            for (var i = 0; i < containerSelbottom.length; i++) {
                containerSelbottom[i].classList.remove("trvFsnextOpen");
            }
            event.target.parentNode.classList.add("fdnextOpen");
            event.target.classList.add("active");
            event.target.parentNode.classList.remove("trvFsnextOpen");
            state.flightDetailsClicked = true
            dispatch({type:"flightDetailsClicked",payload:state.flightDetailsClicked})
        }
        //event.stopPropagation();
    }
    const showtravelFusionlist =(flt)=> (event) => {


           dispatch({ type: 'enableTf', payload: false })
        if (event.target.parentNode.classList.contains("trvFsnextOpen")) {
            event.target.parentNode.classList.remove("trvFsnextOpen");
            event.target.classList.remove("activeme");
        } else {
            var containerSelinfo = document.querySelectorAll(`.flightDetailsLink`);
        for (var i = 0; i < containerSelinfo.length; i++) {
            containerSelinfo[i].classList.remove("active");
        }
        var containerSelnxtopen = document.querySelectorAll(`.rsb-bottom`);
        for (var i = 0; i < containerSelnxtopen.length; i++) {
            containerSelnxtopen[i].classList.remove("fdnextOpen");
        }

        var containerSeltravel = document.querySelectorAll(`.flightDetailsLink`);
        for (var i = 0; i < containerSeltravel.length; i++) {
            containerSeltravel[i].classList.remove("activeme");
        }
        var containerSelbottom = document.querySelectorAll(`.rsb-bottom`);
        for (var i = 0; i < containerSelbottom.length; i++) {
            containerSelbottom[i].classList.remove("trvFsnextOpen");
        }
            dispatch({ type: 'enableTf', payload: true })
            dispatch({ type: 'selTFlight', payload: flt })
            event.target.parentNode.classList.add("trvFsnextOpen");
            event.target.classList.add("activeme");
            event.target.parentNode.classList.remove("fdnextOpen");
            state.flightDetailsClicked = false
            dispatch({type:"flightDetailsClicked",payload:state.flightDetailsClicked})

        }
        //event.stopPropagation();
    }
    //Get Baggage Info for Salam Air
    const getBagFare=(flt,tt)=>{
        setIsItineraryLoading(true)
        if(flt.sup == '6') {
            const req={
                "token":flt.token,
                "ip_address":localStorage.getItem(CLIENT_IP),
                "fareop":flt.fareop,
                "region_type" : props.srequest.region_type,
                "req_cur":props.currency,
            }
            getSalamAirBaggage(req).then(response => {
            if(response.status){
                flt.fareop=response.fareop;
                dispatch({ type: 'selTFlight', payload: flt })
                const req={
                    "tid":flt.tid,
                    "fareop":flt.fareop
                }
                if(tt==="1"){
                    window.sessionStorage.setItem(SEL_SALAM_ONE,JSON.stringify(req))
                }else{
                    window.sessionStorage.setItem(SEL_SALAM_TWO,JSON.stringify(req))
                }
                dispatch({ type: 'enableTf', payload: true })
                setIsItineraryLoading(false)
            }else{
                setIsItineraryLoading(false)
            }
            })
        } else if(flt.sup == '7') {
            let segs = []

            //each segment flight ID
            for(let eachSegs of flt.seg) {
                if(eachSegs.segID) {
                    segs.push(eachSegs.segID)
                }
            }
            const req={
                "token":flt.token,
                "routeId":flt.trackid,
                "fareop":flt.fareop,
                "segID" : segs
            }
            getRiyaAirBaggage(req).then(response => {
            if(response.status){
                flt.fareop=response.fareop;
                dispatch({ type: 'selTFlight', payload: flt })
                const req={
                    "tid":flt.tid,
                    "fareop":flt.fareop
                }
                if(tt==="1"){
                    window.sessionStorage.setItem(SEL_SALAM_ONE,JSON.stringify(req))
                }else{
                    window.sessionStorage.setItem(SEL_SALAM_TWO,JSON.stringify(req))
                }
                dispatch({ type: 'enableTf', payload: true })
                setIsItineraryLoading(false)
            }else{
                setIsItineraryLoading(false)
            }
            })
        }

    }

    const showtrv = (flt) => (event) => {
            dispatch({ type: 'enableTf', payload: true })
            dispatch({ type: 'selTFlight', payload: flt })
            dispatch({ type: 'showtravelFusion', payload: true })
            dispatch({ type: 'activeClass', payload: true })
    }
    const handleCLClose = () => {
        dispatch({ type: 'showtravelFusion', payload: false });
        dispatch({ type: 'activeClass', payload: false })
    }
    /*  const selectedFlight = (e, flt) => {

         var selClass = document.querySelectorAll(".selected.onward");
         var elemCount = selClass.length;
         if (e.currentTarget.classList.contains("onward")) {
         for (var i = 0; i < elemCount; i++) {
             selClass[i].classList.remove("selected");
         }
     }

         var selClassArr = document.querySelectorAll(".selected.arrival");
         var elemCountArr = selClassArr.length;
         if (e.currentTarget.classList.contains("arrival")) {
         for (var i = 0; i < elemCountArr; i++) {
             selClassArr[i].classList.remove("selected");
         }
     }

         if (e.currentTarget.classList.contains("onward")) {
             e.currentTarget.classList.add('selectedow')
         }
         if (e.currentTarget.classList.contains("arrival")) {
             e.currentTarget.classList.add('selectedar')
         }
         if (e.currentTarget.classList.contains("return")) {
             e.currentTarget.classList.add('selected')
         }
         if(state.tripType!==3){
         if(flt.tid.includes("_1")){
             props.onwardFlt(flt,props.sectors);
         }else if(flt.tid.includes("_2")){
             props.retunFlt(flt,props.sectors);
         }
         }else{
             props.multiFlt(flt,props.sectors);
         }
     } */
       const selectedFlight = (e, flt,index) => {
        isPriceCheck=true;
        if (state.sty === 'split') {
            let splitType = e.currentTarget.getAttribute('data-name').split('_')[0];
            let property = splitType + "_"
            var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
            for (var k = 0; k < container.length; k++) {
                container[k].classList.remove("selected");
            }
            e.currentTarget.classList.add("selected");
            if (splitType === 'onward') {
                props.onwardSelectedCallBack(props.sectors, e.currentTarget.getAttribute('data-flt'));
                if (props.sectors.servicefee) {
                    calcuateServiceFee(props.sectors.servicefee)
                }
            }
            else {
                props.arrivalSelectedCallBack(props.sectors, e.currentTarget.getAttribute('data-flt'));
                if (props.sectors.servicefee) {
                    calcuateServiceFee(props.sectors.servicefee)
                }
            }

            if(flt.sup==="2"){
                checkAirArabiaPrice(flt,"1",flt.tid,"",true,index);
            }
        }
        else {
            //  var ele = document.querySelectorAll('.flightSelRadio:checked');
            var selClass = document.querySelectorAll('.selected');
            let isSelFlight=false;
            //e.getAttribute('data-flt');
            let flightType = e.currentTarget.getAttribute('data-name').split('_')[0];
            let flightIndex = e.currentTarget.getAttribute('data-name').split('_')[1];
            var sel_opp_flt="";
            let oppFlightType = '';
            // One - way
            if (parseInt(state.tripType) === 1) {
                /*  for (var k = 0; k < ele.length; k++) {
                     ele[k].checked = false;
                 } */
                for (var s = 0; s < selClass.length; s++) {
                    selClass[s].classList.remove("selected");
                }
                 //checkprice Air araia
                 if(flt.sup==="2"){
                    checkAirArabiaPrice(flt,"1",flt.tid,"",true,index);
                }
            }
            // Round trip
            if (parseInt(state.tripType) === 2) {
                let otid="";let rtid="";
                if (flightType === 'onward') {
                    oppFlightType = 'arrival';
                    otid=flt.tid;
                    isSelFlight=true;
                }
                else if (flightType === 'arrival') {
                    oppFlightType = 'onward';
                    rtid=flt.tid;
                }


                let property = oppFlightType + "_" + flightIndex + "_"
                //  var container = document.querySelectorAll(`.flightSelRadio[name^=${property}]`);
                var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);

                let oppFlight = true;
                //Round trip
                for (var k = 0; k < selClass.length; k++) {
                    if (selClass[k].getAttribute('data-name').startsWith(oppFlightType + '_' + flightIndex + '_')) {
                        oppFlight = false;
                        if(flt.sup==="2"){
                        let req=JSON.parse(selClass[k].getAttribute('data-flt'));
                        sel_opp_flt=req;
                        if(otid==""){
                            otid=req.tid;
                        }else{
                            rtid=req.tid;
                        }
                        }
                        props.selectedFlt(selClass[k].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                    else {
                        selClass[k].classList.remove("selected");
                    }
                }

                if (oppFlight) {

                    container[0].classList.add("selected");

                    if(flt.sup==="2"){
                       let data=JSON.parse(container[0].getAttribute('data-flt'));
                        if(otid==""){
                            otid=data.tid;
                        }else{
                            rtid=data.tid;
                        }
                        sel_opp_flt=data;
                    }
                    props.selectedFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                }
                //checkprice Air araia
                if(flt.sup==="2"){
                    checkAirArabiaPrice(flt,"2",otid,rtid,isSelFlight,index);
                }
            }

            // Multi-city
            if (parseInt(state.tripType) === 3) {
                /* let legoneAval = true;
                let legtwoAval = true;
                let legthreeAval = true;
                let legFourAval = true;
                let legFiveAval = true;
                let legSixAval = true; */

                let indexRemoved = 0;

                for (var k = 0; k < selClass.length; k++) {

                    if (selClass[k].getAttribute('data-name') === e.currentTarget.getAttribute('data-name')) {
                        //legoneAval = false;
                        //props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                        selClass[k].classList.remove("selected");
                    }
                    else if (!selClass[k].getAttribute('data-name').startsWith(flightType + '_' + flightIndex + '_')) {
                        indexRemoved = indexRemoved + 1;
                        selClass[k].classList.remove("selected");
                    }

                    /* else if (selClass[k].getAttribute('data-name') === (flightType + '_' + flightIndex + '_' + '2')) {
                        legtwoAval = false;
                        //props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                        selClass[k].classList.remove("selected");
                    }
                    else if (selClass[k].getAttribute('data-name') === (flightType + '_' + flightIndex + '_' + '3')) {
                        legthreeAval = false;
                       // props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                       selClass[k].classList.remove("selected");
                    }
                    else if (selClass[k].getAttribute('data-name') === (flightType + '_' + flightIndex + '_' + '4')) {
                        legFourAval = false;
                       // props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                       selClass[k].classList.remove("selected");
                    }
                    else if (selClass[k].getAttribute('data-name') === (flightType + '_' + flightIndex + '_' + '5')) {
                        legFiveAval = false;
                      //  props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                      selClass[k].classList.remove("selected");
                    }
                    else if (selClass[k].getAttribute('data-name') === (flightType + '_' + flightIndex + '_' + '6')) {
                        legSixAval = false;
                       // props.multiFlt(selClass[k].getAttribute('data-flt'),props.sectors);
                       selClass[k].classList.remove("selected");
                    } */
                    else {
                        props.multiFlt(selClass[k].getAttribute('data-flt'), props.sectors)
                    }

                }
                let refDataEle = JSON.parse(e.currentTarget.getAttribute('data-flt')).ref;
                if (selClass.length == indexRemoved && refDataEle !== 1) {
                    let property = flightType + "_" + flightIndex + "_" + "1";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
                if (selClass.length == indexRemoved && refDataEle !== 2) {
                    let property = flightType + "_" + flightIndex + "_" + "2";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
                if (selClass.length == indexRemoved && refDataEle !== 3) {
                    let property = flightType + "_" + flightIndex + "_" + "3";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
                if (selClass.length == indexRemoved && refDataEle !== 4) {
                    let property = flightType + "_" + flightIndex + "_" + "4";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
                if (selClass.length == indexRemoved && refDataEle !== 5) {
                    let property = flightType + "_" + flightIndex + "_" + "5";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
                if (selClass.length == indexRemoved && refDataEle !== 6) {
                    let property = flightType + "_" + flightIndex + "_" + "6";
                    var container = document.querySelectorAll(`.rs-block[data-name^=${property}]`);
                    if (container.length > 0) {
                        container[0].classList.add("selected");
                        props.multiFlt(container[0].getAttribute('data-flt'), props.sectors, props.serviceFee);
                    }
                }
            }

            //e.currentTarget.firstElementChild.checked = true;
            e.currentTarget.classList.add("selected");
            if (parseInt(state.tripType) !== 3) {
                props.selectedFlt(e.currentTarget.getAttribute('data-flt'), props.sectors, props.serviceFee);
                if(flt.sup==="6" || flt.sup==="7"){
                if(parseInt(state.tripType) === 1){
                    if(window.sessionStorage.getItem(SEL_SALAM_ONE)===null || window.sessionStorage.getItem(SEL_SALAM_ONE)===undefined){
                        getBagFare(flt,"1");
                    }
                }else{
                    if(sel_opp_flt==="" && (window.sessionStorage.getItem(SEL_SALAM_ONE)===null || window.sessionStorage.getItem(SEL_SALAM_TWO) === null)){
                      sel_opp_flt = JSON.parse(container[0].getAttribute('data-flt'))
                    }
                    if(flightType === 'onward'){
                    if(window.sessionStorage.getItem(SEL_SALAM_ONE)===null){
                        getBagFare(flt,"1");
                        if(oppFlightType = 'arrival'){
                        getBagFare(sel_opp_flt,"2");
                        }
                    }else{
                        let bagResp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_ONE));
                        if(flt.tid===bagResp.tid){
                            let list = flt.fareop.filter((obj)=>obj.selected);
                            flt.fareop=bagResp.fareop;
                              for(let val of flt.fareop){
                                if(val.routeId===list[0].routeId){
                                    val.selected=true;
                                }else{
                                    val.selected=false;
                                }
                              }
                            dispatch({ type: 'enableTf', payload: true })
                            dispatch({ type: 'selTFlight', payload: flt })
                        }else{
                            getBagFare(flt,"1");
                        }
                        }
                    }else{
                    if(window.sessionStorage.getItem(SEL_SALAM_TWO)===null){
                        getBagFare(flt,"2");
                        if(oppFlightType = 'onward'){
                        getBagFare(sel_opp_flt,"1");
                        }
                    }else{
                        let bagResp = JSON.parse(window.sessionStorage.getItem(SEL_SALAM_TWO));
                        if(flt.tid===bagResp.tid){
                            let list = flt.fareop.filter((obj)=>obj.selected);
                            flt.fareop=bagResp.fareop;
                              for(let val of flt.fareop){
                                if(val.routeId===list[0].routeId){
                                    val.selected=true;
                                }else{
                                    val.selected=false;
                                }
                              }
                            dispatch({ type: 'enableTf', payload: true })
                            dispatch({ type: 'selTFlight', payload: flt })
                        }else{
                            getBagFare(flt,"1");
                        }
                        }
                    }
                }
            }
            } else {
                // if(selClass.length!==0){
                props.multiFlt(e.currentTarget.getAttribute('data-flt'), props.sectors, props.serviceFee);
                //  }
            }
        }
        // if(props.sty!==undefined && props.sty==="split" && isPriceCheck){
        //     setTimeout(() => {
        //         var containerSel = document.querySelectorAll(`.rs-block`);
        //                 for(var i=0;i< containerSel.length; i++) {
        //                     containerSel[i].classList.remove("selected");
        //                     }
        //         document.querySelector('.resultLegsOnward > div:first-child .rs-block').classList.add('selected');
        //         document.querySelector('.resultLegsReturn > div:first-child .rs-block').classList.add('selected');
        //       }, 1000);
        // }

    }

//Checking the price for Air arabia
    const checkAirArabiaPrice = async (flt,tt,tid1,tid2,isSelFlight,index) =>{
        let priceResp=JSON.parse(window.sessionStorage.getItem(SEL_AIR_PRICE));
        let isPriceAllowed=true;
        let isSelected=true;
        let checkList=[];
        let tidCombo=tid1+"/"+tid2;
        if(priceResp!==null && priceResp.length!==0){
            if(tt==="1"){
                checkList=priceResp.filter((obj) => obj.tid === flt.tid);
            }else{
                let rtid=tidCombo;
                checkList=priceResp.filter((obj) => obj.tid === rtid);
            }
         if(checkList.length!==0){
            isPriceAllowed=false;
            isPriceCheck=false;
         }
        }
        if(tt==="2" && checkList.length!==0){
            let selFlt=window.sessionStorage.getItem(SEL_FLT_COMBO);
            if(selFlt!==null && selFlt===tidCombo){
                isSelected=false;
            }
        }

         if(isSelected){
         if(isPriceAllowed){
          setIsItineraryLoading(true)
          let srcList = props.sectors.srcorigns.filter((src) => src.tid === tid1);
          let payload="";
          let destList=[];
         if(props.sectors!==undefined && tt==="2"){
            destList = props.sectors.destorigins.filter((dest) => dest.tid === tid2);
            const req={
                "tid":flt.tid,
                "ac":flt.seg[0].ma,
                "tt": tt,
                "dd": props.srequest.dd,
                "ad": props.srequest.ad,
                "cl":props.srequest.cl,
                "ns": "false",
                "cid": props.srequest.cid,
                "cha": "B2B",
                "echo": "6h41ct5s05b",
                "sty": props.srequest.sty,
                "cur": props.currency,
                "pa": "",
                "bid": props.srequest.bid,
                "butype":props.srequest.butype,
                "pcid": props.srequest.pcid,
                "ofcId": "DOHQR2718",
                "dep": props.srequest.srcCode,
                "arr": props.srequest.destCode,
                "adt": props.srequest.adt,
                "chd": props.srequest.chd,
                "inf": props.srequest.inf,
                "region_type" : props.srequest.region_type,
                "req_cur":props.currency,
                "origns": [{
                   "srcorigns": srcList,
                   "destorigins":destList
                 }]
            }
            payload=req;
           }else{
            const request={
                "tid":flt.tid,
                "ac":flt.seg[0].ma,
                "tt": tt,
                "dep": props.srequest.srcCode,
                "arr": props.srequest.destCode,
                "adt": props.srequest.adt,
                "chd": props.srequest.chd,
                "inf": props.srequest.inf,
                "dd": props.srequest.dd,
                "ad": props.srequest.ad,
                "cl":props.srequest.cl,
                "ns": "false",
                "cid": props.srequest.cid,
                "cha": "B2B",
                "echo": "6h41ct5s05b",
                "sty": props.srequest.sty,
                "cur": props.currency,
                "pa": "",
                "bid": props.srequest.bid,
                "butype":props.srequest.butype,
                "pcid": props.srequest.pcid,
                "ofcId": "DOHQR2718",
                "region_type" : props.srequest.region_type,
                "req_cur":props.currency,
                "origns": [{
                   "srcorigns": srcList
                 }]
            }
            payload=request;
           }
           await getPriceInfo(payload).then(response => {
            if (response.data.suc && response.data.data !== undefined) {
                if(isSelFlight){
                    let ind=0;
                    for(let fp of response.data.data.origns[0].srcorigns[0].fareop){
                         if(ind===0){
                            fp.selected=true;
                         }else{
                            fp.selected=false;
                         }
                         ind=ind+1;
                    }
                    flt.fareop=response.data.data.origns[0].srcorigns[0].fareop;
                    dispatch({ type: 'selTFlight', payload: flt })

                }else if(tt==="2"){
                    let index=0;
                    for(let fp of response.data.data.origns[0].destorigins[0].fareop){
                         if(index===0){
                            fp.selected=true;
                         }else{
                            fp.selected=false;
                         }
                         index=index+1;
                    }
                    flt.fareop=response.data.data.origns[0].destorigins[0].fareop;
                    dispatch({ type: 'selTFlight', payload: flt })
                }
                props.sendPriceResp([response.data.data.origns,index])
                dispatch({ type: 'enableTf', payload: true })
                dispatch({ type: 'activeClass', payload: true })
                let priceResp=response.data.data.origns;
                let request1="";
                if(tt==="1"){
                    request1={
                        "tid":flt.tid,
                        "resp":priceResp
                    }
                }else{
                    request1={
                        "tid":tid1+"/"+tid2,
                        "resp":priceResp
                    }
                }
                if(tt==="2"){
                window.sessionStorage.setItem(SEL_FLT_COMBO,request1.tid)
                }
                if(window.sessionStorage.getItem(SEL_AIR_PRICE)===null){
                    window.sessionStorage.setItem(SEL_AIR_PRICE,JSON.stringify([request1]));
                }else{
                let response=JSON.parse(window.sessionStorage.getItem(SEL_AIR_PRICE));
                response.push(request1);
                window.sessionStorage.setItem(SEL_AIR_PRICE,JSON.stringify(response));
                }
                setIsItineraryLoading(false)
            } else {
                setIsItineraryLoading(false)
            }
          })
        }else{
            if(tt==="1"){
               let fpList=flt.fareop.filter((obj) => obj.selected);
               if(checkList!==undefined && checkList.length!==0 && checkList[0].resp!==undefined){
                    for(let fp of checkList[0].resp[0].srcorigns[0].fareop){
                      if(fp.routeId===fpList[0].routeId){
                        fp.selected=true;
                        checkList[0].resp[0].paxpr=fpList[0].paxpr;
                        checkList[0].resp[0].pr=fpList[0].pr;
                        checkList[0].resp[0].servicefee=fpList[0].servicefee;
                        if(fp.pr.ftyp==="Basic"){
                            fp.selectTf="selectedOne";
                        }else if(fp.pr.ftyp==="Extra"){
                            fp.selectTf="selectedTwo";
                        }else if(fp.pr.ftyp==="Value"){
                            fp.selectTf="selectedThree";
                        }
                      }else{
                        fp.selected=false;
                      }
                    }
                    props.sendPriceResp([checkList[0].resp,index])
               }
            }else{
                let tids=tid1+"/"+tid2;
                let fpList=flt.fareop.filter((obj) => obj.selected);
                if(flt.tid===tid1 && fpList.length!==0){
                    if(checkList!==undefined && checkList.length!==0 && checkList[0].resp!==undefined){
                        // for(let fp of checkList[0].resp[0].srcorigns[0].fareop){
                        //   if(fp.routeId===fpList[0].routeId){
                        //     fp.selected=true;
                        //     if(fp.pr.ftyp==="Basic"){
                        //         fp.selectTf="selectedOne";
                        //     }else if(fp.pr.ftyp==="Extra"){
                        //         fp.selectTf="selectedTwo";
                        //     }else if(fp.pr.ftyp==="Value"){
                        //         fp.selectTf="selectedThree";
                        //     }
                        //   }else{
                        //     fp.selected=false;
                        //   }
                        // }
                        let optionsList=checkList[0].resp[0].srcorigns[0].fareop.filter((obj) => obj.selected);
                        if(optionsList.length===0){
                            for(let fare of checkList[0].resp[0].srcorigns[0].fareop){
                                if(fare.pr.ftyp==="Basic"){
                                    fare.selected=true;
                                    fare.selectTf="selectedOne";
                                }
                              }
                        }
                   }
                }
               if(flt.tid===tid2 && fpList.length!==0){
               if(checkList!==undefined && checkList.length!==0 && checkList[0].resp!==undefined){
                    // for(let fp of checkList[0].resp[0].destorigins[0].fareop){
                    //     if(fp.routeId===fpList[0].routeId){
                    //       fp.selected=true;
                    //       if(fp.pr.ftyp==="Basic"){
                    //         fp.selectTf="selectedOne";
                    //        }else if(fp.pr.ftyp==="Extra"){
                    //         fp.selectTf="selectedTwo";
                    //        }else if(fp.pr.ftyp==="Value"){
                    //         fp.selectTf="selectedThree";
                    //       }
                    //     }else{
                    //       fp.selected=false;
                    //     }
                    //   }
                      let optionsList=checkList[0].resp[0].destorigins[0].fareop.filter((obj) => obj.selected);
                        if(optionsList.length===0){
                            for(let fare of checkList[0].resp[0].destorigins[0].fareop){
                                if(fare.pr.ftyp==="Basic"){
                                    fare.selected=true;
                                    fare.selectTf="selectedOne";
                                }
                              }
                        }
                    }
               }
               window.sessionStorage.setItem(SEL_FLT_COMBO,tids)
               props.sendPriceResp([checkList[0].resp,index])

            }

        }
    }
    }


    const showMoreFlights = (event) => {
        if (event.target.parentNode.classList.contains("moreFltOpen")) {
            event.target.parentNode.classList.remove("moreFltOpen");
            event.target.innerHTML = "+ More flight options";
        } else {
            event.target.parentNode.classList.add("moreFltOpen");
            event.target.innerHTML = "- Less flight options";
        }
    }

    const layOverTimeCal = (one, two) => {
        let totTime = Math.abs(new Date(one) - new Date(two));
        return DateUtils.msToTime(totTime);
    }

    //Calculation the DISC props.sectors.servicefee
    const calcuateServiceFee = (serviceFeeList) => {
        var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
        let servicefee = 0;
        if (serviceFeeList !== undefined) {
            servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
            dispatch({ type: 'serviceFee', payload: Number(servicefee) });
            if (props.sty === "split") {
                if (props.secType === "onward") {
                    props.onwardService(servicefee);
                } else if (props.secType === "arrival") {
                    props.returnService(servicefee);
                }
            }
        }
    }

    const calcuateServiceFeeCal = (serviceFeeList) => {
        var userType = (props.srequest!==undefined && props.srequest.butype === "ta_user") ? "TA" : "STA";
        let servicefee = 0;
        if (serviceFeeList !== undefined) {
          servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
          if(servicefee==="NaN"){
            return 0.00;
          }else{
            return servicefee;
          }
        } else {
          return 0.00;
        }
      }

    const updateTFPrice=(obj)=>{
        props.sendTfPrice([obj,state.selTFlight.tid,state.selTFlight.fareop,state.secType]);
        dispatch({ type: 'showtravelFusion', payload:false });
        if(state.sty == 'split'){
            dispatch({ type: 'price', payload: obj.pr });
            var userType = (props.srequest.butype === "ta_user") ? "TA" : "STA";
            let servicefee = 0;
            if (obj.servicefee !== undefined) {
                servicefee = Utilities.calculateServiceFee(obj.servicefee, userType, props.srequest);
                if(servicefee!==0){
                    dispatch({ type: 'serviceFee', payload: Number(servicefee) });
                }
            }
        }
    }
    const conditionsAJ = (data,type) => {
        let seats = ""
        let refundable = false
        let avail_seats = data.filter(s => s.selected == true)
        if(avail_seats.length > 0) {
            seats = avail_seats[0].seats_avail
            refundable = avail_seats[0].refundable
        }
        return type == "seats" ? seats : refundable
    }
    return (
        <>

            <Col className="flightBlock">
            {isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
                {state.sty === 'normal' && (props.impTag == "" || props.impTag == undefined ) && <ResultSectionHeader mapping={state.mapping} sectors={state.sectors} secType={state.secType} secTypeIndex={state.secTypeIndex} trip={state.tripType} />}

                 <div className={state.tripType === '2' ? "rBlocks" : "rBlocks"}>
                    {state.flights.length === 0 && state.sty == 'normal' &&
                        <div className="rBlocks">
                                            <div className="rs-block">
                                <ResultBlockLoader />
                            </div>

                        </div>
                    }

                    {state.flights && state.flights.length > 0 && state.flights.map((flt, index) => (
                        <>
                            {flt.seg && <div className={"rs-block " + (props.secType)} data-flt={JSON.stringify(flt)} onClick={(e) => { selectedFlight(e, flt,index) }} data-name={props.secType + "_" + props.fltIndex + "_" + flt.ref}>
                            {(props.impTag == "" || props.impTag == undefined ) &&
                                <Row>

                                    <Col xs={3} className="srpLogo">
                                    {(flt.seg.filter((abc)=>abc.ma !==flt.seg[0].ma)).length>0?
                                            <>
                                             <div className="airLogo">
                                            <span className="flightSelect"> </span>

                                            <img
                                                // src='../src/client/assets/images/multiairline.svg'
                                                src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/multipleAirline.png'}
                                                className="logo"
                                                alt="AirLine"
                                            />
                                            <div className="flightNo d-flex flex-column">
                                                <strong>Multiple Airline</strong>
                                                <span>{flt.seg[0].ma} - {flt.seg[0].fn}</span>

                                            </div>
                                        </div>

                                    </>:
                                        <div className="airLogo">
                                            <span className="flightSelect"> </span>

                                            <img
                                                src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + flt.seg[0].ma + '.png'}
                                                className="logo"
                                                alt="AirLine"
                                            />
                                            <div className="flightNo d-flex flex-column">
                                                <strong><AirlinesMemo code={flt.seg[0].ma} /></strong>
                                                <span>{flt.seg[0].ma} - {flt.seg[0].fn}</span>

                                            </div>
                                        </div>}
                                    </Col>

                                    <Col xs={5} className="short-timeStops d-flex justify-content-between align-items-start">
                                        <div className="time d-flex flex-column">
                                            <span> {flt.seg[0].dd.split('T')[1].substring(0, flt.seg[0].dd.split('T')[1].length - 3)}</span>
                                            <strong>{flt.seg[0].da}</strong>
                                        </div>
                                        <div className="stop d-flex flex-column align-items-center 2 tt">
                                            {(props.impTag == "" || props.impTag == undefined ) &&
                                            <>
                                           <span className="layoverTimes"> {(flt.seg.length > 1) && <> {layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)}</>}</span>
                                            </>
                                            }
                                            <div className='d-flex flex-row layOvernew'>
                                            <span className="ezyIcon icon-flight"></span><span className="icons"></span>
                                            {flt.seg.length === 2 && <span className="ezyIcon icon-Group-4816 toolTipInfo">
                                                <div className="toolTipCUstoom">
                                                    <span>
                                                        <span className='airPortnam'>{(flt.seg.length > 1 ? flt.seg.slice(1, flt.seg.length).map(segs => "(" + segs.da + ")") : '').toString()}</span> <br/> Layover : {layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)}
                                                    </span>
                                                </div>
                                            </span>}

                                            {flt.seg.length === 3 && <span className="twoStops ezyIcon icon-Group-4816 toolTipInfo">
                                            <span className="ezyIcon icon-Group-4816"></span>

                                                <div className="toolTipCUstoom">
                                                    <span>
                                                    <span className='airPortnam'>{(flt.seg.length > 1 ? flt.seg.slice(1, flt.seg.length).map(segs => "(" + segs.da + ")") : '').toString()}</span> <br/> Layover : {layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)}
                                                    </span>
                                                </div>
                                            </span>}

                                            <span className="ezyIcon icon-flight"></span>
                                            </div>
                                            {/* {(props.impTag == "" || props.impTag == undefined ) &&  */}
                                            <div className="layoverStp"><span>{flt.seg.length - 1 === 0 ? 'Non ' : (flt.seg.length - 1)} Stop </span> {flt.seg.length !== 1 && <span className="space"> </span>} <span>{(flt.seg.length > 1 ? flt.seg.slice(1, flt.seg.length).map(segs => "- " + segs.da + "") : '').toString()}</span> </div>
                                            {/* } */}
                                        </div>



                                        <div className="time d-flex flex-column">
                                            <span> {flt.seg[(flt.seg.length - 1)].ad.split('T')[1].substring(0, flt.seg[(flt.seg.length - 1)].ad.split('T')[1].length - 3)}</span>
                                            <strong>{flt.seg[(flt.seg.length - 1)].ar}</strong>
                                        </div>
                                    </Col>

                                    <Col xs={4} className="timeEx d-flex flex-row justify-content-start">
                                        <div className='d-flex justify-content-between'>
                                        <div className="duration d-flex justify-content-between">
                                            {flt.sdur!=="" && flt.sdur!==undefined &&
                                            <span className="time mr-2"><span className='ezyIcon icon-timenew'>{flt.sdur.split(":")[0] + " h " + flt.sdur.split(":")[1] + " m "}</span></span>
                                            }
                                            {flt.bgd!==undefined && flt.bgd.length!==0 && <span className="baggage ezyIcon icon-baggage mr-2">  {flt.bgd[0].qun} {flt.bgd[0].wt} </span>}
                                        </div>

                                        <div className="seatsLeft d-flex  justify-content-between mr-3">
                                            <span className="ezyIcon icon-seatsleft">   {flt.sup == "3" ? conditionsAJ(flt.fareop,"seats") : flt.seg[0].seat} Seats Left</span>
                                            {flt.sup !== "3" ? (
                                                <>
                                            {flt.re!=="" && flt.re!==undefined &&
                                            <>
                                             {flt.re === 'Refundable Before Departure' ? <span className="refundType refund ml-2">Penalty Applies</span> : <span className="refundType nonrefund">Non-Refundable</span>}
                                            </>
                                            }
                                            </>
                                            ) : (
                                                <>
                                                {conditionsAJ(flt.fareop,"refund") ? <span className="refundType refund ml-2">Penalty Applies</span> : <span className="refundType nonrefund">Non-Refundable</span>}
                                                </>
                                            )}
                                        </div>
                                        {/* <div className='baggaGe'>
                                        <span className="ezyIcon icon-baggage">30kg</span>
                                        </div> */}

                                        </div>
{/*
                                        <div className='penalitis d-flex'>
                                        <span className="">Penality Applies</span>
                                        </div> */}

                                    </Col>
                                    <Col className='price-block'>
                                    {state.sty == 'split' &&
                                    <>
                                    {flt.sup!=="0" &&

                                    <div className="tftag lcc ezyIcon icon-nFlight">LCC </div>
                                    }
                                    {flt.sup==="0" &&
                                    <div className="tftag fcc ezyIcon icon-nFlight">FSC </div>
                                    }
                                    </>
                                        }
                                    {state.price && <div class="flighPrice d-flex justify-content-end col">
                                    {flt.sup!=="2" &&
                                        <strong><em class="priceTag"> {state.currency}</em> {<FormateCurrency value={Utilities.addPrice(state.price.tf + Number(props.onFlyMkp) + state.gst_value, state.serviceFee, "Add")} />}</strong>
                                    }
                                    {flt.sup==="2" && props.sectors.show &&
                                        <strong><em class="priceTag"> {state.currency}</em> {<FormateCurrency value={Utilities.addPrice(props.price.tf + Number(props.onFlyMkp) + state.gst_value, state.serviceFee, "Add")} />}</strong>
                                    }
                                    </div>}


                                    </Col>
                                </Row>
                                    }
                                <div className={"rsb-bottom d-flex justify-content-between "  + ((props.impTag == "IMPORT" && index == 0) ?  "fdnextOpen" : "")}>
                                    {/* <div className="flightAmenitiess d-flex">

                                        {flt.seg[0].fa.includes("USB") ? <span className="usb ezyIcon icon-usb">
                                             USB
                                        </span> : ''}
                                        {flt.seg[0].fa.includes("Wi-Fi") ? <span className="wi-fi ezyIcon icon-wifi">
                                            Wi-Fi
                                        </span> : ''}
                                        {flt.seg[0].fa.includes("Fresh Meals") ? <span className="fresh-meals ezyIcon icon-meal">
                                             Fresh Meals
                                        </span> : ''}
                                        &nbsp;
                                    </div> */}

                                     {flt.fareop!==undefined && flt.fareop.map((obj, index) => (
                                        <>
                                        {obj.selected &&
                                        <>


                                        {props.viewType=="column" ? <strong className={state.activeClass ? 'trvfsonPopUp  active' : 'trvfsonPopUp'} onClick={showtrv(flt)} ><span className='fareOptionss'>Fare Options</span></strong>
                                         :<>
                                         {props.sty !== "split" &&
                                        <div onClick={showtravelFusionlist(flt)} className="flightDetailsLink tfpop">
                                         Fare Options
                                       </div>
                                         }</>}
                                       {props.sty === "normal" &&

                                       <span className={"travelfusiononSelect " +obj.pr.ftyp.toLowerCase() +" "+ (obj.selectTf===undefined?'selectedOne':obj.selectTf)}><span className="ezyIcon icon-tick-fare"></span>
                                       {obj.pr.ftyp==="Regular - R" &&
                                        <span>SAVER </span>
                                        }
                                        {obj.pr.ftyp==="Flexi - J" &&
                                        <span>FLEXI</span>
                                        }
                                        {obj.pr.ftyp.toUpperCase()==="CORPORATE - F" &&
                                        <span>CORPORATE </span>
                                        }
                                        {obj.pr.ftyp!=="Regular - R" && obj.pr.ftyp!=="Flexi - J" && obj.pr.ftyp.toUpperCase()!=="CORPORATE - F" &&
                                        <span>{obj.pr.ftyp.toUpperCase()}</span>
                                        }
                                        </span>

                                        }
                                         </>
                                        }
                                        </>
                                     ))}

                                    {props.sty === "split" &&  flt.sup!=="0" && flt.fareop!==undefined && flt.fareop.map((obj, index) => (
                                        <>
                                        {obj.selected &&
                                        <>
                                          <strong className={state.activeClass ? 'trvfsonPopUp  active' : 'trvfsonPopUp flexiComp'} onClick={showtrv(flt)} >Fare Options</strong>
                                        <span className={"travelfusiononSelect " + (obj.selectTf===undefined?'selectedOne':obj.selectTf) + (flt.sup === "2" && ' showupPrice')}> <span className="ezyIcon icon-tick-fare"></span>

                                        {obj.pr.ftyp==="Regular - R" &&
                                        <span>SAVER </span>
                                        }
                                        {obj.pr.ftyp==="Flexi - J" &&
                                        <span>FLEXI</span>
                                        }
                                        {obj.pr.ftyp.toUpperCase()==="CORPORATE - F" &&
                                        <span>CORPORATE </span>
                                        }
                                        {obj.pr.ftyp!=="Regular - R" && obj.pr.ftyp!=="Flexi - J" && obj.pr.ftyp.toUpperCase()!=="CORPORATE - F" &&
                                        <span>{obj.pr.ftyp.toUpperCase()}</span>
                                        }


                                        </span>

                                         </>
                                        }
                                        </>
                                     ))}



                                       <div onClick={showFlightInfo} className={"flightDetailsLink flexiComptrav ezyIcon icon-arrowDown "  + ((props.impTag == "IMPORT" && index == 0) ?  "active" : "")}>
                                        Flight Details

                                    </div>

                                </div>


                                <FlightDetails sty={props.sty} secType={state.secType} fareop={state.flightDetailsClicked} sup={flt.sup} bktype={props.impTag ? "IMPORT" : ""} page={"RESULT"} origin={[flt, 'Result']} mapping={state.mapping} sector={props.sectors} srequest={props.srequest} currency={props.currency} flights={props.flightsSel} mulflights={props.mulflights} splitFlights={[props.owsplitFlights, props.rtsplitFlights]} regionType={props.regionType}/>
                                {state.enableTf  && flt.sup!=="0" &&
                                <div className="flightDetails listTF">
                                <TravelFusion selFlight={flt} sup={flt.sup} sendFarePrice={updateTFPrice} srequest={props.srequest} regionType={props.regionType} sectors={props.sectors}/>
                                </div>
                                }
                            </div>}

                        </>

                    ))}
                    <Button
                        onClick={showMoreFlights}
                        size="sm"
                        className="d-flex align-items-center moreResult"
                        variant="outline-secondary">
                        + More flight options

                    </Button>
                    <Modal
                        show={state.showtravelFusion}
                        onHide={handleCLClose}
                        className="morefaeoption"
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                    >
                        <Modal.Header closeButton className="pb-0">
                            <div className="popUplayout">
                                <h4>More Fare Options</h4>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="resultSection pt-0 mt-0">

                        {state.sty === 'normal' && <ResultSectionHeader mapping={state.mapping} sectors={state.sectors} secType={state.secType} secTypeIndex={state.secTypeIndex} trip={state.tripType} />}
                        <div class="rs-block onward ">
                            {state.selTFlight!==undefined && state.selTFlight!=="" &&
                        <Row>
                                    <Col xs={3} className="srpLogo">
                                        <div className="airLogo">
                                            <img
                                                src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + state.selTFlight.seg[0].ma + '.png'}
                                                className="logo"
                                                alt="AirLine"
                                            />
                                            <div className="flightNo d-flex flex-column">
                                                <strong><AirlinesMemo code={state.selTFlight.seg[0].ma} /></strong>
                                                <span>{state.selTFlight.seg[0].ma} - {state.selTFlight.seg[0].fn}</span>
                                                <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={state.selTFlight.seg[0].oa} /></span></div>

                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={5} className="short-timeStops d-flex justify-content-between align-items-start">
                                        <div className="time d-flex flex-column">
                                            <span> {state.selTFlight.seg[0].dd.split('T')[1].substring(0, state.selTFlight.seg[0].dd.split('T')[1].length - 3)}</span>
                                            <strong>{state.selTFlight.seg[0].da}</strong>
                                        </div>
                                        <div className="stop d-flex flex-column align-items-center 2">

                                            {(state.selTFlight.seg.length > 1) && <span className="layoverTimes"><em>Layover: </em> {layOverTimeCal(state.selTFlight.seg[0].ad, state.selTFlight.seg[state.selTFlight.seg.length - 1].dd)}</span>}
                                            <span class="icons"><span class="ezyIcon icon-flight"></span></span>
                                            <div className="layoverStp"><span>{state.selTFlight.seg.length - 1 === 0 ? 'Non ' : (state.selTFlight.seg.length - 1)} Stop </span> {state.selTFlight.seg.length !== 1 && <span className="space"> </span>} <span>{(state.selTFlight.seg.length > 1 ? state.selTFlight.seg.slice(1, state.selTFlight.seg.length).map(segs => "- " + segs.da + "") : '').toString()}</span></div>
                                        </div>



                                        <div className="time d-flex flex-column">
                                            <span> {state.selTFlight.seg[(state.selTFlight.seg.length - 1)].ad.split('T')[1].substring(0, state.selTFlight.seg[(state.selTFlight.seg.length - 1)].ad.split('T')[1].length - 3)}</span>
                                            <strong>{state.selTFlight.seg[(state.selTFlight.seg.length - 1)].ar}</strong>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="timeEx d-flex p-3">
                                        <div className="duration  d-flex flex-column">
                                            {state.selTFlight.sdur!=="" && state.selTFlight.sdur!==undefined &&
                                            <span className="time mr-4"><span className="ezyIcon icon-timenew"> {state.selTFlight.sdur.split(":")[0] + " hrs " + state.selTFlight.sdur.split(":")[1] + " mins"}</span></span>
                                            }
                                            {state.selTFlight.bgd!==undefined && state.selTFlight.bgd.length!==0 && <span className="baggage ezyIcon icon-baggage"> {state.selTFlight.bgd[0].qun} {state.selTFlight.bgd[0].wt} </span>}
                                        </div>

                                        <div className="seatsLeft  d-flex flex-column mr-3">
                                            <span className="ezyIcon icon-running">  {state.selTFlight.seg[0].seat} Seats Left</span>
                                                {state.selTFlight.re !== "" && state.selTFlight.re !== undefined &&
                                                    <>
                                                        {state.selTFlight.re === 'Refundable Before Departure' ? <span className="refundType refund">Penalty Applies</span> : <span className="refundType nonrefund">Non-Refundable</span>}
                                                    </>}
                                        </div>
                                    </Col>
                                </Row>
                                 }

                 </div>

                       <div className="trvlFusion">

                 {state.selTFlight!==undefined && state.selTFlight!=="" &&
                   <TravelFusion selFlight={state.selTFlight} sendFarePrice={updateTFPrice} srequest={props.srequest} regionType={props.regionType} sectors={props.sectors}/>
                }

                </div>
                        </Modal.Body>
                    </Modal>
                </div>


            </Col>

        </>
    )
}


export default ResultBlock;
