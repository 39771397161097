import React, {useState,useEffect,useContext} from 'react';
import Select from 'react-dropdown-select';
import { Alert, Col, Container, Form, Row, Table, Modal } from "react-bootstrap";
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import log from "loglevel";
import { Context } from "../../../../App";
export const USER_AUTH_DATA = "userAuthData";
export const COUNTRY_DATA = "COUNTRY_DATA";
/**
 * @description: This function is common for All Region type
 * @date:02-01-2023
 * @author:Lakshmi
 *
 */
function RegionType(props) {
    const [regionType,setRegionType] = useState([])
    const [regionsList,setRegionsList] = useState([])
    const [disable,setDisable] = useState(false)
    const [visaDisable,setVisaDisable] = useState(true)
    const [context, setContext] = useContext(Context);

    useEffect(() => {
      if(props.regionUpdate!=="" && props.regionUpdate!==undefined){
        setRegionType([{value:props.regionUpdate,label:props.regionUpdate}])
        setDisable(true)
      }
      if (props.flowType && props.flowType=="visaConfig"){
        setVisaDisable(false)
      }
    }, [props.regionUpdate,props.flowType])
    
    useEffect(()=>{
      if(props.resetRegion){
        setRegionType([])
      }
    },[props.resetRegion])

    useEffect(() => {
      if(context.logindata!==undefined){
        let data = JSON.parse(localStorage.getItem(COUNTRY_DATA));
        // let finalArray = [];
        // data.forEach((element) => {
        //   finalArray.push({"label":element.con_name,"value":element.con_id})
        // });
       if(context.logindata.utype!==undefined && context.logindata.region_type!==undefined){
         if(context.logindata.utype=="att_user" && context.logindata.bothRegions){
           setRegionsList(data)
         }else{
           setRegionsList(data)
         }
       }else{
         var userData =localStorage.getItem(USER_AUTH_DATA);
         var resObj = JSON.parse(userData);
         if(resObj.utype=="att_user" && resObj.bothRegions){
           setRegionsList(data)
         }else{
           setRegionsList([{value:resObj.region_type,label:resObj.region_type}])
         }
        }
      }
   }, [])


   const getBranchList = (e) => {
    setRegionType(e)
    if(props.regionUpdate===""){
    props.regionType(e.length!=0?e[0].label:"")
    if(!props.callBranch && (props.flowType===undefined || (props.flowType!=="Markup" && props.flowType!=="Service"))){
    if(context.logindata!==undefined && context.logindata.utype==="att_user"){
          let req="";
          if(context.logindata.is_admin){
            req={"region_type":e.length!=0?e[0].label:""}
          }else{
            req={"region_type":e.length!=0?e[0].label:"","uid":context.logindata.uid}
          }
          AttCommonSerivce.loadBranches(req).then(response => {
            if (response.data.suc){
                props.sendResponse(response.data.res);
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
      });
    }
    }
    }
    }

    return (
        <>
                             <Form.Group as={Col} xs={3} className="selectplugin promorgionType" controlId="region_type">
                                <Select
                                options={regionsList}
                                values={regionType}
                                searchable={false}
                                disabled={disable}
                                onChange={getBranchList}
                                />
                                <Form.Label>Region Type {visaDisable &&<sup>*</sup>}</Form.Label>
                                {props.regionError && (
                    <p className="vError"> Please Enter Region Type</p>
                  )}
                            </Form.Group>

        </>
    )
}
export default RegionType