
import React, { Component, useState } from 'react';
function TransfersFun(props) {
    const { state} = props || {}

    const [transfersSearch, setTransfersSearch] = useState({
        active:'TransfersTab'
    });

    const content = {
        FlightTab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        OperationsTab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        };

    return(
        <>
          <div className="searchTab hotelSearchPart">
         <h1 className="transSearch">Transfers Search part</h1>
          </div>


        </>
    )}

    class TransfersSearch extends Component {
      constructor(props) {
        super(props);
        this.state = {


        };
      }

      render() {
        return <TransfersFun state={this.state} />
      }
    }

    export default TransfersSearch;
