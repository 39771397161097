import axios from 'axios'
import { Console } from 'winston/lib/winston/transports';

const API_URL = process.env.REACT_APP_B2B_URL
const API_URL_SEARCH = process.env.REACT_APP_B2B_SEARCH

/**
 * @description: This function will get the all services fro Flight Booking flow
 * @author: Lakshmi
 * @param {*}
 * @function Flight Service
 * @date : 02-09-2020
 */
class FlightService {
    async getMinifareRules(request) {
        return await axios.post(`${API_URL_SEARCH}/flight/mini-rules`,request);
    }

    async getLongfareRules(request) {
        return await axios.post(`${API_URL_SEARCH}/flight/long-rules`,request);
    }

    async saveBooking(prereq) {
        return await axios.post(`${API_URL}/att/booking/prebook`,prereq);
    }

    async saveManualBooking(prereq) {
        return await axios.post(`${API_URL}/att/booking/manualbook`,prereq);
    }

    async fireBookReq(book) {
        return await axios.post(`${API_URL_SEARCH}/flight/book`,book)
    }

    async fireTicketReq(tkt) {
        return await axios.post(`${API_URL_SEARCH}/flight/ticket`,tkt)
    }

    async getEqipmentLabel(code) {
        return await axios.get(`${API_URL}/att/branch/retrieve`);
    }

    async getCustomDetails(bid,compid) {
        return await axios.get(`${API_URL}/att/custom/booking?branchid=${bid}&companyid=${compid}`)
    }

    async fireFareRuleReq(fare) {
        return await axios.post(`${API_URL_SEARCH}/flight/availability`,fare)
    }

    async fireFlightSearch(url) {
        return await axios.get(`${API_URL_SEARCH}/flight/search?${url}`)
    }

    async sendConfirmationObj(req) {
        return await axios.post(`${API_URL}/att/booking/share-itinerary`,req)
    }
    async fireMultyCityReq(req) {
        return await axios.post(`${API_URL_SEARCH}/flight/multi-search`,req)
    }

    async updatePax(req) {
        return await axios.post(`${API_URL}/att/fltsummary/updatepaxinfo`,req)
    }

    async getBaggage(req) {
        return await axios.post(`${API_URL_SEARCH}/flight/salam-air/fetch-fareop-details`,req)
    }
    async getriyaBaggage(req) {
        return await axios.post(`${API_URL_SEARCH}/flight/riya/fetch-fareop-details`,req)
    }
}

export default new FlightService()