import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
// const API_URL = "http://localhost:3005"

/**
 * @description: Integrated the Deal code services
 * @author: Rambabu
 * @date:16-10-2020
 * @param {*} req 
 */
class DealCodeService {

    /**
     * @description: Save Deal code services
     * @author: Rambabu
     * @date:16-10-2020
     * @param {*} req 
     */
    async saveDealcode(req) {
        console.log('save Deal code data is----' + JSON.stringify(req))
        return await axios.post(`${API_URL}/att/dealcode/create`, req)
    }



    /**
     * @description: Fetch All Deal Data
     * @author: Rambabu
     * @date:16-10-2020
     * @param {*} req 
     */
    async fetchDealCodeData(reqData) {
        console.log('request for dealcode search', reqData)
        if(reqData.type) {
        return await axios.post(`${API_URL}/att/dealcode/get_deal_codes`,reqData);
        } else {
            return ''
        }
    }


    /**
     * @description: Edit Deal code service
     * @author: Rambabu
     * @date:16-10-2020
     * @param {*} req 
     */
    async editDealCode(id) {
        return await axios.get(`${API_URL}/att/dealcode/edit/${id}`);
    }


    /**
     * @description: Update Deal Code Data
     * @author: Rambabu
     * @date:19-10-2020
     * @param {*} data 
     */
    async updateDealCodeData(data) {
        console.log("updateDealCodeData" + JSON.stringify(data));
        return await axios.post(`${API_URL}/att/dealcode/update`, data)
    }


    /**
     * @description: Delete Deal Code Offid and fmap data
     * @author: Rambabu
     * @date:19-10-2020
     * @param {*} data 
     */
    async deleteDealcodeinventoryChildData(data) {
        console.log("deleteDealcodeinventoryChildData----" + JSON.stringify(data));
        return await axios.post(`${API_URL}/att/dealcode/deleteCompany`, data);

    }


     /**
     * @description: Delete Deal Code RBD Data
     * @author: Rambabu
     * @date:20-10-2020
     * @param {*} data 
     */
    async deleteDealcodeRBDChildData(id) {
        console.log("deleteDealcodeRBDChildData----" + id);
        return await axios.delete(`${API_URL}/att/dealcode/deleteClass/${id}`);

    }


    /**
     * @description: Delete Deal Code Routes Data
     * @author: Rambabu
     * @date:20-10-2020
     * @param {*} data 
     */
    async deleteDealcodeRoutesChildData(data) {
        console.log("deleteDealcodeRoutesChildData----" + JSON.stringify(data));
        return await axios({ method: "DELETE", url: `${API_URL}/att/dealcode/deleteRoutes`, data: data });


    }

    /**
     * @description: Delete Deal Code Applicable Data
     * @author: Rambabu
     * @date:20-10-2020
     * @param {*} data 
     */
    async deleteDealcodeApplicableChildData(id) {
        console.log("deleteDealcodeApplicableChildData----" + id);
        return await axios.delete(`${API_URL}/att/dealcode/deleteApplicable/${id}`);

    }


    



    /**
     * @description: Fetch Resepective commission id commission Data
     * @author: Rambabu
     * @date:21-09-2020
     * @param {*} id 
     */
    async getComissionDetails(id) {

        return await axios.get(`${API_URL}/att/commission/edit/${id}`);

    }

    /**
     * @description: Delete Applicable Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id 
     */
    async deleteapplicableChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteApto/${id}`)
    }

    /**
     * @description: Delete Child Data(commission_route)
     * @author: Rambabu
     * @date:22-08-2020 
     * @param {*} req        
     */
    async deleteCommissionRoutesData(req) {
        console.log('deleteOfficeidRoutesData----' + JSON.stringify(req));
        return await axios({ method: "DELETE", url: `${API_URL}/att/commission/deleteRoutes`, data: req });
    }

    /**
     * @description: Delete Class(RBD) Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id  
     */
    async deleteCommissionClassChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteClass/${id}`)
    }

    /**
     * @description: Delete Office id Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id 
     */
    async deleteCommissionOfficeidChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteOffice/${id}`)
    }


    


}

export default new DealCodeService()