import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import ModifySearch from '../../bookingFlows/flight/common/ModifySearch';
import Footer from '../../common/Footer';
import Header from '../../common/Header';
import ReissueDetails from './ReissueDetails.jsx';
import AirlinesMemo from '../../common/airlines';
import AirportMemo from '../../common/airlines/airportNames';
import DateUtils from '../../commonUtils/DateUtils';
import Button from '../../common/buttons/Button';
import {checkReissuePrice} from '../summary/operations';
import { useHistory } from 'react-router-dom';
export const REISUE_RESP = "reisueResp";
const _ = require('lodash')


/**
 * Initial State Declaration
 */
const initialState = {
  taxPopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', remarks: "", attPaxPrice: [], taPaxPrice: [], staPaxPrice: [], showPopup: false, dateAvail: false, routeAvail: false, changeAvail: false, penaltyAvail: false,enbalePrice:false,selSegment: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {

  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function FlightReissueDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [reissueData, setReissueData] = useState(props.location.state);
  const [initsrequest,setinitsRequest] =useState(_.cloneDeep(props.location.state))
  const [oldCls,setOldCls] =useState(props.location.state!==undefined && props.location.state.cls!==undefined && props.location.state.cls)
  const [mappings, setMappings] = useState(props.location.state!==undefined && props.location.state.req!==undefined && props.location.state.req.mappings)
  const history = useHistory();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    if (reissueData !== undefined) {
      if (reissueData.req !== undefined) {
        for (let val of reissueData.req.elig) {
          if (val.code === "route" && val.allowed) {
            dispatch({ type: "routeAvail", payload: true })
          } else if (val.code === "date" && val.allowed) {
            dispatch({ type: "dateAvail", payload: true })
          } else if (val.code === "penalty" && val.allowed) {
            dispatch({ type: "penaltyAvail", payload: true })
          }
        }
      }
    }
  }, [reissueData]);

  //Updating seleted segment
  const handleChangeSegment = (obj, idx) => (e) => {
    for (let segment of reissueData.req.seg) {
      if (!segment.selected) {
        if (segment.ref === obj.ref) {
            segment.selected = true;
        }
      } else {
        if (segment.ref === obj.ref) {
           segment.selected = false;
        }
      }
    }
    //Checking segement seleted or Not
    var selectedSeg = false;
    for (let segment of reissueData.req.seg) {
      if (segment.selected) {
        selectedSeg = true;
        dispatch({ type: 'selSegment', payload: true })
        break;
      }
    }
    if (!selectedSeg) {
      dispatch({ type: 'selSegment', payload: false })
    }
  }

  const cancel = () => {
    return window.location = '/ezytrip/dashboard/bookingsummary/' + props.location.state.bref;
  }

 //Calling Price service for seleted Segment(s)
 const showPriceItinarary = () => {
  if (state.selSegment) {
     let segs=[];
     let totalSegs=reissueData.req.seg;
     if(reissueData.req!==undefined){
      for (let segment of reissueData.req.seg){
        if(segment.selected){
          segs.push(segment);
        }
     }
     }
     if(segs.length!==0){
      reissueData.req.seg=segs;
     }
     checkReissuePrice(reissueData.req).then((resp) => {
      if (resp.status==="success" && resp.data !== undefined){
        localStorage.removeItem(REISUE_RESP);
        reissueData.req.flowType="REISSUE";
        history.push({
          pathname: '/ezytrip/bookings/flight/results',
          state:{
            req: reissueData.req,
            flow:"IMPORT/REISSUE",
            response:resp
          }
        });
      } else {
        reissueData.req.seg=totalSegs;
          setIsLoadingMore(false);
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload:resp.data!==undefined && resp.data.description!==undefined && resp.data.description})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
      }
  })
  }else{
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select atleast one segment and proceed' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
  }
}

const hidemessage = () => {
  setTimeout(function () {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
  }, 3000);
}

  return (
    <>
      <Header />

      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
      <div>
        <ReissueDetails reissueDetails={reissueData.req} />
      </div>
      <div>


       <div className="reissueMSearch">
        <ModifySearch flow={"REISSUE"} sreq={reissueData.req} initreq={initsrequest.req} load={state.load} dateAvailable={state.dateAvail} routeAvailable={state.routeAvail} oldCls={oldCls} segments = {initsrequest.segments} />
        </div>

{reissueData !== undefined && reissueData.req.bktype==="IMPORT/REISSUE" &&
<div className="container">
<div className="importReissue mb-4">
          <div className="selectedRout">
          <h5 className="title d-flex justify-content-between p-3">Itinerary - Unticketed </h5>
            <Table>
              <tbody>
                {reissueData.req.seg.map((sege, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr>
                        <td>
                          <Form.Check type="checkbox" label="" custom id={index + 1} checked={sege.selected} onChange={handleChangeSegment(sege, index)} />
                        </td>
                        <td>
                        <strong><AirlinesMemo code={sege.ma} /></strong><br/>
                          <span>{sege.ac} {sege.fn}</span>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                          </div>
                        </td>
                          <td className="text-center status">
                            <div className="bookings d-flex justify-content-center">
                              <span>Class : {sege.cl}</span>
                            </div>
                            <div className="bookings d-flex justify-content-center">
                              <span>GDS PNR : {reissueData.req.pnr}</span>
                            </div>
                          </td>

                      </tr>
                    </>
                  </React.Fragment>
                ))
                }
              </tbody>

            </Table>
          </div>

            <div className="text-right buttonGrop pb-0">
              <Button
                size="xs"
                variant="outline-secondary"
                type="submit"
                onClick={cancel}
              > Cancel</Button>
              <Button
                size="xs"
                variant="outline-primary"
                onClick={showPriceItinarary}
                type="submit"
              >Check price</Button>
            </div>

        </div>
        </div>
        }
      </div>

      <Footer />
    </>
  )
}
export default FlightReissueDetails