

import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import serviceFee from '../../../services/administration/HotelServiceFeeConfig'



export const countries = async (obj) => {
    let country_res = '';
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            country_res = response.data.res;
        }
    })
    return Promise.resolve(country_res);
}

export const loadBranchesList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.loadBranches(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const deleteapplicableConfig = async (id) => {
    let ofcList = [];
    await serviceFee.deleteapplicableConfig(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

/**
 * @description: Fetch airportlist for airport selection
 * @author: Rambabu
 * @param {*}
 * @function airportlist
 * @date : 16-09-2020
 */
export const airportlist = async (e) => {
    let airport_res = '';
    await AttCommonSerivce.getAirportData(e[0].code).then(response => {
        if (response.data.suc) {
            airport_res = response.data.res;
        }
    })
    return Promise.resolve(airport_res);
}

export const deleteAppliacableData = async (id) => {
    let deleteres = [];
    await serviceFee.deleteapplicableChildData(id).then(response => {
        if (response.data.suc) {
            deleteres = response.data.res;
        }
    });
    return Promise.resolve(deleteres);
}

export const deleteRoutesData = async (obj) => {
    let deleteroutesres = [];
    await serviceFee.deleteCommissionRoutesData(obj).then(response => {
        if (response.data.suc) {
            deleteroutesres = response.data.res;
        }
    });
    return Promise.resolve(deleteroutesres);
}


// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const branchDataList = async (data) => {
    let branchesList = [];
    await AttCommonSerivce.getBranchData(data).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const userbranchDataList = async (data) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(data).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
// ========================================================================
// All Category Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const categoryDataList = async () => {
    let cateList = [];
    await AttCommonSerivce.getCategoryList().then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getAirportData = async (code) => {
    let airportList = [];
    await AttCommonSerivce.getAirportData(code).then(response => {
        airportList = response;
    });
    return Promise.resolve(airportList);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const ofcDataList = async () => {
    let ofcList = [];
    await AttCommonSerivce.getSuppOfcIds().then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyCatLsit = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyCatLsit(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}


export const delPaxServiceFee = async (id) => {
    let ofcList = [];
    await serviceFee.deletePaxServiceFee(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const deledeFMap = async (id) => {
    let ofcList = [];
    await serviceFee.deleteFareMap(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const retriveFMDetails = async (id) => {
    let ofcList = [];
    await serviceFee.retriveFMDetails(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const updateFMRequest = async (data) => {
    let ofcList = [];
    await serviceFee.updateFareMap(data).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const delClass = async (id) => {
    let ofcList = [];
    await serviceFee.deleteClass(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const creation = async (req) => {
    let res = null
    await serviceFee.saveServiceFee(req).then(response=> {
        res = response
    })
    return Promise.resolve(res);
}

export const retrieveServiceFee = async (req) => {
    let res = null
    await serviceFee.retrieveServiceFee(req).then(response => {
        res = response
    })
    return Promise.resolve(res)
}

export const editServiceFee = async (req) => {
    let res = null
    await serviceFee.editServiceFee(req).then(response=> {
        res = response
    })
    return Promise.resolve(res)
}

export const deleteRoute = async (id) => {
    let ofcList = [];
    await serviceFee.deleteRoute(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const deleteAppcableDate = async (id) => {
    let ofcList = [];
    await serviceFee.deleteAppcableDate(id).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const update =async (id,data) => {
    let serviceResponse = [];
    await serviceFee.update(id,data).then(response => {
        serviceResponse = response;
    })
    return Promise.resolve(serviceResponse);
}

export const getCompaniesList= async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}

export const searchServiceFee =async (obj) => {
    let serviceResponse = [];
    await serviceFee.searchServiceFee(obj).then(response => {
        serviceResponse = response
    });
    return Promise.resolve(serviceResponse);

}

export const getCompanyTAList = async (obj,type) => {
    let branchesList = [];
    await AttCommonSerivce.getCompanyTAList(obj,type).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}
export const getSTACompanyList = async (id) => {
    let branchesList = [];
    await AttCommonSerivce.getSTACompanyList(id).then(response => {
            branchesList = response;
    });
    return Promise.resolve(branchesList);
}   