
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import PickList from '../../../common/pickList/PickList';
import Utilities from '../../../commonUtils/Utilities';
import ApplicableMarkup from '../../common/ApplicableMarkup';
import {delClass, deledeFMap, delPaxMarakup, editMarkup, getBranchList, getCompaniesList, getCompanyList,fetch_company_list, getCompanyTAList, getSTACompanyList, ofcDataList, retriveFMDetails, updateFMRequest,getOfficeIdListS,loadBranchesList ,fetchBranches} from '../operations';
import RegionType from '../../common/RegionType';
import SalesChannel from "../../common/SalesChannel";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
let rbdMaster = require('../../../masterData/Rbd.json');

/**
 * Initial State Declaration
 */
const initialState = {
  cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "ATT", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [], branches: [], branchMenu: [], catMenu: [],
  categories: [], enbleType: false, showCat: true, officeIds: [], enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
  enabeleEdit: false, fmId: "", notiMessage: "", notiMessageShow: false, notiVarient: "", classCode: [], userType: "", selBranches: [], classType: [], staCompanies: [], ta_comp: [], rbdMaster: [], defaultRBDmaster: [],
  reset: false, hideDelete: false,selected_company:'',region_type:"",regionSelected:false,region_type_edit:"",selected_branch:[],isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,enableDevices:false,sales_chanel:"",supplier: [],supplierError:false,enableOfcId:true,edit_supplier: []  };

let classArray = [];
let markupFareArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, cat: 0, bid: 0, cmpid: 0, pax: "", ctyp: "ATT", aptype: "Branch", compType: true, don: "", dtype: "", ofcs: [], type: "", rbd: [],
        enbleType: false, showCat: true, officeIds: [{value:1,label:"DOHQR28IH"},{value:2,label:"HYDINR28IH"}], enableOfcs: false, compData: [], enablePercen: false, enableValue: false, showCls: false, data: [], enableUpdate: false,
        enabeleEdit: false, fmId: "", classCode: [], selBranches: [], classType: [], staCompanies: [], ta_comp: [], hideDelete: false,selected_company:'',region_type:"",regionSelected:false,branchAdded:false,
        isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @author: Lakshmi
 * @description: This function will create the Makrup Fare maps based on the Company
 * @date:14-09-2020
 * @function : AirMarkupFareMapForm
 */
function AirMarkupFareMapForm(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [classState, setRbdClass] = useState({ class: [] });
  const [companydata, setCompanyPick] = useState([]);
  const [fares, setShowFareList] = useState({ faresList: [] });
  const [value, setValue] = useState([]);
  const [perTicket, setPerTicket] = useState({ perTickts: [] })
  const [perBooking, setPerBooking] = useState({ perBookings: [] })
  const [applicable, setApplicableType] = useState();
  const [enApplicable, setEnApplicable] = useState(true);
  const [companyType, setCompanyType] = useState(true);
  const [sta, setSTA] = useState(false);
  const [paxData, setPaxData] = useState()
  const [data, setData] = useState([])
  const [disableButton, setDisableButton] = useState(false);
  const [editOfc, setEditOfc] = useState([]);
  const cls = [{ "label": "Economy", "value": "Economy" },{ "label": "Premium Economy", "value": "Premium Economy" }, { "label": "Business", "value": "Business" }, { "label": "First", "value": "First" }]
  const supplier = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6},{label:"Riya Travel", value: 7}];

  useEffect(() => {
    if (rbdMaster.length > 0) {
      dispatch({ type: 'rbdMaster', payload: rbdMaster })
      dispatch({ type: 'defaultRBDmaster', payload: rbdMaster })
    }
  }, [rbdMaster])
  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      setRbdClass({ class: [] })
      setCompanyPick([])
      setShowFareList({ faresList: [] })
      setPerTicket({ perTickts: [] })
      setPerBooking({ perBookings: [] })
      setApplicableType()
      setEnApplicable(true)
      setCompanyType(true)
      setSTA(false)
      setPaxData()
      setData([])
      setDisableButton(false)
    }
  }, [props.reset])
  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);

const updateRegion=(region)=>{
  dispatch({ type: "region_type", payload: region });
  dispatch({type:"regionSelected", payload: true })
}
const handleRegionType = (e) => {
  if(e.length!==0 && !state.enableDevices){
    let branchesList = e.map((branch) =>
    <option value={branch.value}>{branch.label}</option>
    );
    state.branchMenu = branchesList
    // dispatch({ type: 'branchMenu', payload: branchesList})
    dispatch({ type: "branches", payload: e });
    // dispatch({ type: "regionError", payload: false });
    dispatch({type:"regionSelected", payload: true })
  }else{
    dispatch({type:"branchMenu", payload:[]})
    dispatch({type:"bid", payload:0})
}
}
const updateSupplier=(sup)=>{
  
  if(sup!==undefined && sup.length!==0){
    props.sendSup(sup);
    if(sup[0].label==="Amadeus"){
      dispatch({type:"enableOfcId", payload:true})
    }else{
      dispatch({type:"enableOfcId", payload:false})
    }
    dispatch({type:"branchMenu", payload:[]})
    dispatch({type:"bid", payload:0})
    setCompanyPick([])
    if(sup[0].label!=="Amadeus"){
      if(state.sales_chanel==="B2B"){
      if(context.logindata.utype == "att_user") {
     if(context.logindata.is_admin){
      fetchBranches({"region_type": state.region_type }).then(response => {
        if (response.data.suc) {
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
          dispatch({ type: 'branches', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }else{
      AttCommonSerivce.loadBranches({"region_type": state.region_type,"uid": context.logindata.uid}).then(response => {
        if (response.data.suc) {
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
          dispatch({ type: 'branches', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }
    }
    }else{
    setCompanyPick([])
    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type,sales_channel:state.sales_chanel}
    fetch_company_list(pyload).then(response => {
      if (response.data.suc) {
        let authData = []
        for (let val of response.data.res){
          let auth = {
            "value": val.fk_company_id,
            "label": val.company_name,
            "sales_channel":val.sales_channel
          }
          authData.push(auth)
        }
        let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
        setCompanyPick(cmpanis)
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
    }
    }
  }
}
  useEffect(() => {
    let fareList = [];
    if (props.fareData && props.fareData !== undefined) {
      dispatch({type:"edit_salesChanel", payload: [{value:props.edit_salesChanel,label:props.edit_salesChanel}] })
      for (let value of props.fareData) {
        var name = "";
        if (value.branch_name !== null && value.branch_name !== "") {
          name = value.branch_name;
        } else if (value.category_name !== null) {
          name = value.category_name;
        }
        var type = "";
        if (state.utype == "ta_user") {
          type = value.com_type;
        }

        const fares = {
          fmid: value.pk_fare_map_id,
          applyon: value.applicable_on,
          borcname: name,
          company: (value.company_name) ? value.company_name : value.com_type,
          sup: value.supplier_name + "[" + value.fk_office_id + "]",
          compType: type
        }
        markupFareArray.push({ name: (value.company_name) ? value.company_name : value.com_type })
        fareList.push(fares);
      }
      setShowFareList({ faresList: fareList })
      if (fareList && fareList.length !== 0) {
        dispatch({ type: 'enabeleEdit', payload: true })
      }
    }
  }, [props.fareData]);
useEffect(() => {
  if(props.edit_ofcid){dispatch({type:"ofcs", payload: props.edit_ofcid })}
  if(props.edit_supplier && props.edit_supplier.length > 0) {
    dispatch({type:"supplier", payload: props.edit_supplier})
    dispatch({type:"edit_supplier", payload:props.edit_supplier})
    }
}, [props.edit_supplier,props.edit_ofcid])

/**
 * @author: Lakshmi
 * @description: This function will Load the Companies based on the selected OFC ID
 * @date:14-09-2020
 * @function : fetch_company_list
 */
  useEffect(() => {
    if(!state.enableDevices){
     setCompanyPick([])
    }
    if(state.enableDevices && state.ofcID){
    const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}
        fetch_company_list(payload).then(response => {
          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "value": val.fk_company_id,
                "label": val.company_name,
                "sales_channel":val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
    }
  }, [!state.enableDevices,state.ofcID]);

  useEffect(()=>{
    console.log("dgjldfgn",state.supplier)

  },[state.supplier])
/**
 * @author: Lakshmi
 * @description: This function will Load the Branches based on the selected OFC ID
 * @date:14-09-2020
 * @function : getBranchesWithOfficeId
 */
 useEffect(() => {
  if(state.ofcID && state.supplier &&state.supplier[0] &&state.supplier[0].label ==="Amadeus"){
    
    AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel}).then(response => {
      if (response.data.suc){
        let authData = []
        let BRANCHES = response.data.res.filter((val)=>{
                return val.fk_branch_id != null;
              })
              for (let val of BRANCHES){
                let auth = {
                  "value": val.fk_branch_id
                  ,
                  "label": val.office_name
                }
                authData.push(auth)
              }
        let branchesList = authData.map((branch) => (
          <option value={branch.value}>{branch.label}</option>
        ));
        state.branchMenu = branchesList;
        dispatch({ type: "branchMenu", payload: branchesList });
        dispatch({ type: "branches", payload: branchesList });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in Loadi branches based on Region function---" + error
      );
    });
  }
}, [state.ofcID,state.supplier])

useEffect(() => {
  if(state.supplier === "Amadeus"){
  
  }
},[state.supplier])
/**
 * @author: Lakshmi
 * @description: This function will Load the OFC IDS based on the selected Sales channel and Region
 * @date:14-09-2020
 * @function : getOfficeIdListS
 */
  useEffect(() => {
    if (state.sales_chanel!== "" && state.region_type !== "") {
       const payload = {"sales_channel": state.sales_chanel, "region_type": state.region_type, "userid": context.logindata.uid}
       getOfficeIdListS(payload).then(response => {
          if (response.data.suc) {
            if (response.data.res) {
              dispatch({ type: 'officeIds', payload: response.data.res })
              dispatch({ type: 'enableOfcs', payload: true })
            }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
       }
  }, [state.sales_chanel,state.region_type]);

  useEffect(() => {
    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      if (context.logindata.utype !== "ta_user") {
        dispatch({ type: 'enableOfcs', payload: true })
      } else {
        dispatch({ type: 'ctyp', payload: "TA" })
        dispatch({ type: 'utype', payload: "ta_user" })
      }
    }
  }, [context.logindata]);

  const pickldata = (data) => {
    dispatch({ type: 'data', payload: data })
  }
  const compdata = (data) => {
    dispatch({ type: 'compData', payload: data })
  }
/**
 * @author: Lakshmi
 * @description: This function will Load the Companies Based on the Branch for B2B
 * @date:14-09-2020
 * @function : fetch_company_list
 */
  const handleBranchChange = (e) => {
    let selbr = state.branches.filter((val)=>{
      if(val.value){
       if ( val.value == e.target.value){
        dispatch({ type: 'data', payload: [{"value":val.value, "label":val.children}]})
       }
      }
        else if(val.props.value){
        if(val.props.value == e.target.value){
          dispatch({ type: 'data', payload: [{"value":val.props.value, "label":val.props.children}] })
        }
      }
    })
    dispatch({ type: 'selected_branch', payload: selbr });
    state.selected_branch.push(selbr)
    setCompanyPick("");
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    const payload = {"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_chanel,"branch_id":e.target.value}
    
    if (!sta) {
      fetch_company_list(payload).then(response => {
        if (response.data.suc) {
          let authData = []
          for (let val of response.data.res){
            let auth = {
              "value": val.fk_company_id,
              "label": val.company_name,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });

    } else {
      dispatch({ type: 'ta_comp', payload: [] })
      getCompanyTAList(value, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }


  const handleCompTypeChange = (e) => {
    if (e.target.id === "att") {
      dispatch({ type: 'ctyp', payload: "ATT" })
      dispatch({ type: 'compType', payload: true })
      setCompanyPick("");
      dispatch({ type: 'ofcs', payload: [] })
      setCompanyType(true);
      // markupFareArray = [];
    } else if (e.target.id === "ta") {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      dispatch({ type: 'ta_comp', payload: [] })
      setEnApplicable(false)
      setCompanyType(false);
      setSTA(false)
      // markupFareArray = [];
    } else {
      dispatch({ type: 'bid', payload: 0 })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyType(true);
      setSTA(true)
      setEnApplicable(false)
      // markupFareArray = [];
    }
  }

  const handleTypeChange = (e) => {
    dispatch({ type: 'aptype', payload: "Branch" })
    if (e.target.id === "ta") {
      dispatch({ type: 'ctyp', payload: "TA" })
      setCompanyType(true);
      setCompanyPick([])
    } else {
      dispatch({ type: 'ctyp', payload: "STA" })
      setCompanyType(false);
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      if (companydata && companydata.length === 0) {
        getCompaniesList(pyload).then(response => {
          if (response.data.suc) {
            setCompanyPick(response.data.res)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }
  }

/**
 * @author: Lakshmi
 * @description: This function will update the Device type for B2C Sales channel
 * @date:14-09-2020
 * @function : handleDeviceTypeChange
 */
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }

  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }


  //Adding to Classes list
  const handleClses = () => {
    if (validateCls()) {
      const payload = {
        "cls": state.type,
        "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
      }
      setRbdClass({ class: [...classState.class, payload] });
      dispatch({ type: 'classCode', payload: [] })
      dispatch({ type: 'type', payload: "" })
      dispatch({ type: 'classType', payload: [] })
      dispatch({ type: "rbd", payload: [] })
    }
  }
  //checking the duplicates based on length(original json and unique json length)
  const validateCls = () => {
    if (state.type === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Class' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    } else if (state.type !== "") {
      const payload = {
        "cls": state.type,
        "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
      }
      classArray.push(payload);
      let data = Utilities.getUniqData(classArray);
      var int = 0;
      for (let val of data) {
        if (val.cls == "Economy") {
          int = int + 1;
        } else if (val.cls == "Premium Economy") {
          int = int + 1;
          if (int !== 0 && data.includes("All")) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
          int = int + 1;
        }
        if (classArray.length !== data.length) {
          classArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }
    }
    return true;
  }

  function getUnique(arr, comp) {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  // Adding Class and rbds
  const setCls = (data) => {
    if (data.length > 0 && data[0].value === "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      dispatch({ type: 'rbdMaster', payload: data })
      setValue(data)
    } else if (data.length > 0 && data[0].value !== "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      setValue(data)
      removeAll()
    } else {
      let cls = getUnique(state.defaultRBDmaster, "value")
      dispatch({ type: 'rbdMaster', payload: cls })

      dispatch({ type: 'rbd', payload: [] })
      dispatch({ type: 'classCode', payload: data })
      setValue(data)

    }

  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const removeAll = (e) => {
    let arr = []
    for (let val of state.rbdMaster) {
      arr.push(val.value)
    }
    if (arr.indexOf("All") > -1) {
      const index = arr.indexOf("All");

      state.rbdMaster.splice(index, 1);
    }
  }
  
  const handleRemoveClass = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      delClass(obj.id).then(response => {
        if (response.data.suc) {
          let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
          if (classArray && classArray.length > 0) {
            classArray = matchedArr
          }
          setRbdClass({ class: classState.class.filter((s, sidx) => s.id !== obj.id) });
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
    } else {
      let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
      setRbdClass({ class: classState.class.filter((s, sidx) => idx !== sidx) });
      if (classArray && classArray.length > 0) {
        classArray = matchedArr
      }
    }
    if (classState.class.length === 0) {
      dispatch({ type: 'showCls', payload: false })
    }

  }

  const validateFareMap = () => {
    if (state.userType === "att_user") {
      if(!state.regionSelected && props.edit_region==undefined && state.region_type===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Region type ' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
        }else if((!state.ofcs || state.ofcs.length === 0) && editOfc.length === 0 && state.supplier[0].label=="Amadeus") {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select OFC ID' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      if (state.aptype === "Branch" && state.ctyp === "ATT") {

        if (state.data.length === 0 && !state.enableDevices) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (companydata.length===0 || state.compData.length===0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else if (!state.enableDevices && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) {
        if (state.bid === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        if (state.ctyp === "STA") {
          if (state.ta_comp.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            return false;
          }
          if (state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        } else {
          if (state.compData.length === 0) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select atleast one TA Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
          }
        }
      }
    } else {
      if (state.utype == "ta_user" && state.ctyp === "STA" && state.compData.length === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    //Validating profile markup
    if (perBooking.perBookings.length === 0 && perTicket.perTickts.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please define Applicable markup' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }

    if (state.aptype === "Branch") {
      if (state.ctyp === "TA") {
        markupFareArray = []
        if(fares.faresList && fares.faresList.length > 0) {

          for(let eachFare of fares.faresList) {

            if(eachFare.company !== state.selected_company) {

              let obj = {
                "name" : eachFare.company
              }
              markupFareArray.push(obj)
            }
          }
        }
        for (let val of state.compData) {
          const payload = {
            // "cid": val.value,
            "name": val.label
          }
          markupFareArray.push(payload);
        }
        let data = Utilities.getUniqData(markupFareArray);
        if (markupFareArray.length !== data.length) {
          // markupFareArray = data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          // let correction = markupFareArray[markupFareArray.length - 1].name
          // markupFareArray = uniqueJson(markupFareArray)
          return false;
        }
      }
    } else if (state.aptype === "Category" && state.utype == "att_user") {
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        let correction = markupFareArray[markupFareArray.length - 1].name
        markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
        return false;
      }
    }
    if (state.ctyp === "STA" && state.utype == "ta_user") {
      markupFareArray = []
      if(fares.faresList && fares.faresList.length > 0) {

        for(let eachFare of fares.faresList) {

          if(eachFare.company !== state.selected_company) {

            let obj = {
              "name" : eachFare.company
            }
            markupFareArray.push(obj)
          }
        }
      }
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        // markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        // let correction = markupFareArray[markupFareArray.length - 1].name
        // markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
        return false;
      }
    }

    return true;
  }

  // ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      if (context.logindata.utype === "att_user" && state.aptype === "Branch") {
        if (state.compData) {
          if(!state.enableDevices){
            if(state.data.length > 0) {
              // branches.push({ "id": state.data[0]?.value, "name": state.data[0]?.label });
              branches.push({ "id": state.data[0]?.props !== undefined ? state.data[0]?.props.value : state.data[0]?.value, "name": state.data[0]?.props !== undefined ? state.data[0]?.props.children : state.data[0]?.label });
            }
          }
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices? (state.data[0]?.props !== undefined ? state.data[0]?.props.children : state.data[0]?.label) : "",
                //sup: state.ofcs[0].label
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if ((context.logindata.utype === "att_user" && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || (state.aptype !== undefined && state.aptype === "Category")) {
        let fList = [];
        var branchcatName = "";
        if (state.branches && state.bid !== 0 && !state.enableDevices) {
          branches.push({ "id": state.data[0]?.value, "name": state.data[0]?.label });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Branch") {
          state.categories.map((obj) => {
            if (obj.label == state.cat) {
              branchcatName = obj.label;
              category.push({ "id": obj.value, "name": obj.label });
              branches = [];
              dispatch({ type: 'cat', payload: 0 })
            }
          });
        }
        if (state.compData && category.length === 0) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices ? state.data[0]?.label : "",
                //sup:state.ofcs[0].label
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if (context.logindata.utype === "att_user" && category.length === 0) {
        let fList = [];
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              applyon: applicable,
              borcname:!state.enableDevices ? state.data[0]?.label : "",
              //sup: state.ofcs[0].label
            }
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      if (category.length > 0) {
        const fare = {
          applyon: applicable,
          borcname: branchcatName,
          //sup: state.ofcs[0].label
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      //Seeting Pax datat
      let paxData = [];
      if (applicable === "Per Booking") {
        paxData = perBooking.perBookings;
      } else {
        paxData = perTicket.perTickts;
      }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (context.logindata.utype === "ta_user") {
        if (state.ctyp === "TA") {
          const fare = {
            applyon: applicable,
            company: context.logindata.cnm,
            compType: "TA"
          }
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          fares.faresList = fares.faresList.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === value.place && t.name === value.name
              )
          );
          setShowFareList({ faresList: fares.faresList });
        } else {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                applyon: applicable,
                company: obj.label,
                compType: "STA"
              }
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } if (context.logindata.utype === "sta_user") {
        const fare = {
          applyon: applicable,
          company: context.logindata.cnm,
          compType: "STA"
        }
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }

      if (context.logindata.utype === "att_user") {
        dispatch({ type: 'disableOfc', payload: true })
        if (!state.enabeleEdit) {
          createFareMapRequest(paxData, companyType, branches, companys, category);
        } else {
          createFMUpdatedPayload(paxData, companyType, branches, companys, category);
        }
      } else if (context.logindata.utype === "ta_user") {
        if (!state.enabeleEdit) {
          createFareMapTARequest(paxData, companys);
        } else {
          createFMUpdatedTAPayload(paxData, companys);
        }

      } else {
        if (!state.enabeleEdit) {
          createFareMapSTARequest(paxData, companys);
        } else {
          createFMUpdatedSTAPayload(paxData, companys);
        }
      }
      dispatch({ type: 'branchAdded', payload: true })
    }
  }
  const createFareMapTARequest = (paxData, companies) => {
    const payload = {
      "applon": applicable,
      "applist": uniqueJson(paxData),
      "class": classState.class,
      "ctyp": state.ctyp,
      "bid": [],
      "cat": [],
      "utype": context.logindata.utype,
      "aptype": "",
      "cmpid": companies,
      "ofc": [],
      "taCmp": state.ta_comp
    }
    props.sendFareData(payload);
  }


  const createFareMapSTARequest = (paxData, companies) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "applon": applicable,
      "applist": uniqueJson(paxData),
      "class": classState.class,
      "ctyp": "STA",
      "bid": barr,
      "utype": context.logindata.utype,
      "cat": [],
      "aptype": "Branch",
      "cmpid": companies,
      "ofc": [],
      "taCmp": state.ta_comp
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedSTAPayload = (paxData, companies) => {
    let barr = []
    let obj = {
      id: context.logindata.bid,
      name: context.logindata.bnm
    }
    barr.push(obj)
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mid": props.markupId,
        "utype": "sta_user",
        "ltype": context.logindata.utype,
        "applon": applicable,
        "class": classState.class,
        "ctyp": "STA",
        "aptype": "Branch",
        "applist": uniqueJson(paxData),
        "bid": barr,
        "cat": [],
        "cmpid": companies,
        "ofc": [],
        "taCmp": state.ta_comp
      }
    }
    createFMPayload(payload)
  }
  const createFareMapRequest = (paxData, companyType, branches, companies, categories) => {
    const payload = {
      "applon": applicable,
      "applist": uniqueJson(paxData),
      "class": classState.class,
      "ctyp": "TA",
      "bid": branches,
      "utype": companyType == "STA" ? "ta_user" : context.logindata.utype,
      "ltype": context.logindata.utype,
      "cat": categories,
      "aptype": "Branch",
      "cmpid": companies,
      "ofc": [],
      "taCmp": state.ta_comp,
      "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
      "isDeviceAll":state.isDeviceAll,
      "isB2cWeb":state.isB2cWeb,
      "isB2cApp":state.isB2cApp,
      "isMWeb":state.isMWeb,
    }
    props.sendFareData(payload);
  }
  const createFMUpdatedTAPayload = (paxData, companies) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mid": props.markupId,
        "utype": "ta_user",
        "ltype": context.logindata.utype,
        "applon": applicable,
        "class": classState.class,
        "ctyp": state.ctyp,
        "aptype": "Branch",
        "applist": uniqueJson(paxData),
        "bid": [],
        "cat": [],
        "cmpid": companies,
        "ofc": [],
        "taCmp": state.ta_comp,        
        "region_type":context.logindata.region_type,
        "sales_channel":context.logindata.channel,
        'office_id':"All",
      }
    }
    createFMPayload(payload);
  }
  const createFMUpdatedPayload = (paxData, companyType, branches, companies, categories) => {
    const payload = {
      "fmureq": {
        "id": state.fmId,
        "mid": props.markupId,
        "applon": applicable,
        "utype": companyType == "STA" ? "ta_user" : state.userType,
        "ltype": context.logindata.utype,
        "class": classState.class,
        "ctyp": companyType,
        "aptype": "Branch",
        "applist": uniqueJson(paxData),
        "bid": branches,
        "cat": categories,
        "cmpid": companies,
        "ofc": [],
        "taCmp": state.ta_comp,
        "isDeviceAll":state.isDeviceAll,
        "isB2cWeb":state.isDeviceAll?true:state.isB2cWeb,
        "isB2cApp":state.isDeviceAll?true:state.isB2cApp,
        "isMWeb":state.isDeviceAll?true:state.isMWeb,
        "supp": state.supplier.length > 0 ? state.supplier[0].value : null,
        "region_type":props.edit_region,
        "sales_channel":state.sales_chanel,
        'office_id':state.ofcID,
      }
    }
    createFMPayload(payload);
  }
/**
 * @author: Lakshmi
 * @description: This function will create the Fare Map Request
 * @date:14-09-2020
 * @function : createFMPayload
 */
  const createFMPayload = (payload) => {
    var requestId = "";
    if (state.fmId && state.fmId !== "") {
      requestId = state.fmId;
    } else {
      requestId = props.markupId;
    }
    if (payload && payload.fmureq.applist.length > 0) {
      updateFMRequest(props.configType, requestId, payload).then(response => {
        let fareList = [];
        if (response.data.suc) {
          dispatch({ type: 'hideDelete', payload: false })
          setShowFareList({ faresList: [] })
          editMarkup(props.markupId).then(response => {
            if (response.data.suc) {
              var obj = response.data.res;
              var srvcmap = obj.fmap
              if (srvcmap && srvcmap.length > 0) {
                for (let value of srvcmap) {
                  var name = "";
                  if (value.branch_name !== null && value.branch_name !== "") {
                    name = value.branch_name;
                  } else if (value.category_name !== null) {
                    name = value.category_name;
                  }
                  const fares = {
                    fmid: value.pk_fare_map_id,
                    applyon: value.applicable_on,
                    borcname: name,
                    company: (value.company_name) ? value.company_name : value.com_type,
                    sup: value.supplier_name + "[" + value.fk_office_id + "]"
                  }
                  fareList.push(fares);
                }
                dispatch({ type: 'enabeleEdit', payload: true })
                setShowFareList({ faresList: uniqueJson(fareList) })
              }
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in editServiceFee function---" + error
            );
          });
          setShowFareList({ faresList: fareList })
          dispatch({ type: 'notiMessage', payload: "Markup updated successfully" })
          dispatch({ type: 'notiVarient', payload: "success" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
          dispatch({ type: 'clear', payload: true })
          dispatch({ type: 'reset', payload: true })
          setRbdClass({ class: [] })
          // markupFareArray= []
          setCompanyPick([])
          // setShowFareList({ faresList: [] })
          setPerTicket({ perTickts: [] })
          setPerBooking({ perBookings: [] })
          setApplicableType()
          setEnApplicable(true)
          setCompanyType(true)
          setSTA(false)
          setPaxData()
          setData([])
          setDisableButton(false)
        } else {
          dispatch({ type: 'notiMessage', payload: "Markup Not updated.." })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
    } else {
      dispatch({ type: 'notiMessage', payload: "Please Define Applicable Markup." })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }
  }

  function uniqueJson(jsonarray) {
    let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
    const mySetSerialized = new Set(myArrSerialized);
    const myUniqueArrSerialized = [...mySetSerialized];
    const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

    return myUniqueArr;
  }
  const paxMarkup = (data) => {
    setApplicableType(data[0]);
    dispatch({ type: 'reset', payload: false })
    if (data[0] !== undefined && data[0] === "Per Booking") {
      perBooking.perBookings.push(data[1]);
      setPerBooking({ perBookings: perBooking.perBookings });
    } else {
      perTicket.perTickts.push(data[1]);
      setPerTicket({ perTickts: perTicket.perTickts });
    }
  }

  const updatePaxData = (data) => {
    if (data[0] !== undefined && data[0] === "Per Booking") {
      setPerBooking({ perBookings: data[1] });
    } else {
      setPerTicket({ perTickts: data[1] });
    }
  }

  const loadClasses = (resp) => {
    let clssess = [];
    let classArr = []
    for (let value of resp.class) {
      const cls = {
        "id": value.cid,
        "cls": value.cls,
        "rbd": value.rbd
      }
      clssess.push(cls);
      const obj = {
        "cls": value.cls,
        "rbd": [value.rbd]
      }
      classArr.push(obj)
    }
    setRbdClass({ class: clssess });
    classArray = classArr
    dispatch({ type: 'showCls', payload: true })
  }

  const setCompType = (resp) => {
    if (resp.ctyp === "ATT") {
      setCompanyType(true);
     setSTA(false)
    dispatch({ type: 'enbleType', payload: false })
    dispatch({ type: 'showCat', payload: true })
    dispatch({ type: 'ctyp', payload: 'ATT' })
    dispatch({ type: 'staCompanies', payload: [] })
    dispatch({ type: 'aptype', payload: "Branch" })
    setCompanyPick("");
    setEnApplicable(true);
    dispatch({ type: 'ta_comp', payload: [] })

    dispatch({type:"cat",payload : resp.cat[0].name})
    } else if (resp.ctyp === "TA") {
      dispatch({ type: 'bid', payload: resp.bid[0]?.id })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      dispatch({ type: 'aptype', payload: "Branch" })
      setEnApplicable(false)
      setCompanyType(false);
      setSTA(false)
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      if(state.sales_chanel === "B2C") {
        setCompanyPick([])
        let pyload
        if(state.supplier[0].value == 0) {
          pyload = {"office_id":state.ofcs.length !== 0 ? state.ofcs[0].value : editOfc[0].value,"userid": context.logindata.uid,"region_type":props.edit_region,"sales_channel":state.sales_chanel}
        } else {
          pyload = {userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: props.edit_region,sales_channel:state.sales_chanel}
        }
        fetch_company_list(pyload).then(response => {
          if (response.data.suc) {
            let authData = []
            for (let val of response.data.res){
              let auth = {
                "value": val.fk_company_id,
                "label": val.company_name,
                "sales_channel":val.sales_channel
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      } else {
        getCompanyList(resp.bid[0].id).then(response => {
          if (response.data.suc) {
            let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });
    } else {
      dispatch({ type: 'bid', payload: resp.bid[0].id })
      dispatch({ type: 'enableOfcs', payload: false })
      setCompanyPick("");
      dispatch({ type: 'compType', payload: false })
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'ctyp', payload: "STA" })
      dispatch({ type: 'ofcs', payload: [] })
      dispatch({ type: 'enableOfcs', payload: true })
      setCompanyType(true);
      setSTA(true)
      setEnApplicable(false)
      setData([]);
      dispatch({ type: 'compData', payload: [] })
      if (resp.cmpid && resp.cmpid.length !== 0) {
        loadCompanyData(resp.cmpid);
      }
      state.branches.map((obj) => {
        if (Number(obj.value) === Number(resp.bid[0].id)) {
          state.selBranches.push({ "id": obj.value, "name": obj.label });
        }
      });
      dispatch({ type: 'ta_comp', payload: resp.taCmp })
      getCompanyTAList(resp.bid[0].id, "TA").then(response => {
        if (response.data.suc) {
          let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
          setCompanyPick(cmpanis)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      getSTACompanyList(resp.taCmp[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  //updating all seoected companies
  const loadCompanyData = (companies) => {
    let compas = [];
    let data = [];
    for (let val of companies) {
      const comp = {
        "value": val.id,
        "label": val.name
      }
      data.push(comp);
      compas.push(val.id);
    }
    setData(data);
    dispatch({ type: 'compData', payload: data })
  }

  const setAppType = (appType) => {
    if (appType === "Branch") {
      setEnApplicable(true);
      dispatch({ type: 'enbleType', payload: true })
      dispatch({ type: 'compType', payload: true })
      dispatch({ type: 'showCat', payload: false })
      dispatch({ type: 'aptype', payload: "Branch" })
      setCompanyPick("");
    } else {
      setEnApplicable(false);
      dispatch({ type: 'enbleType', payload: false })
      dispatch({ type: 'showCat', payload: true })
      dispatch({ type: 'aptype', payload: "Category" })
      setCompanyPick("");
    }
  }
/**
 * @author: Lakshmi
 * @description: This function will Edit the Fare Map Request
 * @date:14-09-2020
 * @function : editFareMap
 */
  const editFareMap = (obj) => () => {
    setEditOfc(state.ofcs.length !== 0 ? state.ofcs : editOfc);
    retriveFMDetails(props.configType, obj.fmid).then(response => {
      if (response.data.suc) {
        markupFareArray = markupFareArray.filter((d, id) => d.name !== obj.company)
        dispatch({type:"selected_company",payload:obj.company})
        dispatch({ type: 'hideDelete', payload: true })
        dispatch({ type: 'enableUpdate', payload: true })
        dispatch({ type: 'fmId', payload: obj.fmid })
        // dispatch({ type: 'ofcs', payload: [] })
        var resp = response.data.res
        setPaxData(resp)
        if (resp.class && resp.class.length !== 0) {
          loadClasses(resp);
        }
        // if (resp.ofc && resp.ofc !== undefined) {
        //   loadOfcIds(resp.ofc);
        // }
        if(resp.bid !== undefined && resp.bid.length!==0){
          dispatch({ type: 'data', payload: [{value:resp.bid[0].id,label:resp.bid[0].name}] })
        }
        if (resp.ctyp) {
          if (state.userType === "att_user") {
            setCompType(resp);
          }
          if (state.userType === "ta_user" && resp.cmpid && resp.cmpid.length !== 0) {
            if (resp.ctyp === "TA") {
              dispatch({ type: 'ctyp', payload: "TA" })
              setCompanyType(true)
            } else {
              loadCompanyData(resp.cmpid);
              dispatch({ type: 'ctyp', payload: "STA" })
              setCompanyType(false)
              const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
              //Load all companies based Login type as TA
              if (companydata && companydata.length === 0) {
                getCompaniesList(pyload).then(response => {
                  if (response.data.suc) {
                    setCompanyPick(response.data.res)
                  }
                }).catch(function (error) {
                  log.error(
                    "Exception occured in getCompanyList function---" + error
                  );
                });
              }
            }
          }
        } else {
          if(state.userType === "att_user") {
          setCompanyType(true);
         setSTA(false)
        dispatch({ type: 'enbleType', payload: false })
        dispatch({ type: 'showCat', payload: true })
        dispatch({ type: 'ctyp', payload: 'ATT' })
        dispatch({ type: 'staCompanies', payload: [] })
        dispatch({ type: 'aptype', payload: "Category" })
        setCompanyPick("");
        setEnApplicable(true);
        dispatch({ type: 'ta_comp', payload: [] })

        dispatch({type:"cat",payload : resp.cat[0].name})
          }
        }
        if (resp.aptype) {
          setAppType(resp.aptype);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }

/**
 * @author: Lakshmi
 * @description: This function will Delete the Sel Fare Map 
 * @date:14-09-2020
 * @function : deleteFareMap
 */
  const deleteFareMap = (obj, idx) => () => {
    if (obj !== undefined && obj.fmid !== undefined) {
      confirmBranchDlg(obj, 'Applicable Type', function () {
        deledeFMap(props.configType, obj.fmid).then(response => {
          if (response.data.suc) {
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.fmid !== obj.fmid) });
            let companyList = markupFareArray;
            markupFareArray = companyList.filter((item, i) => item.name !== obj.company);
            // markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      let companyList = markupFareArray;
      markupFareArray = [];
      markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
    }
  }


  const deletePaxMarakup = (id) => {
    delPaxMarakup(props.configType, id).then(response => {
      if (response.data.suc) {

      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deletePaxMarakup function---" + error
      );
    });
  }

  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    // dispatch({ type: 'ofcs', payload:"" })
    setCompanyPick([])
    props.sendChannel(salesChanel)
  }

  const updateOfcId=(ofc)=>{
    dispatch({type:"ofcs", payload:ofc})
    setEditOfc(ofc);
    if(ofc.length!==0){
     if(state.sales_chanel!==""){
        if(state.sales_chanel==="B2C"){
          let payload="";
          if(context.logindata.is_admin){
            payload={ofc_id:ofc[0].value}
          }else{
            payload={ofc_id:ofc[0].value,uid:context.logindata.uid}
          }
          AttCommonSerivce.att_companies(payload).then(response => {
            if (response.data.suc && response.data.res.status === 200) {
              let authData = []
              for (let val of response.data.res.data){
                let auth = {
                  "value": val.value,
                  "label": val.label,
                  "sales_channel": val.sales_channel
                }
                authData.push(auth)
              }
              let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
              setCompanyPick(cmpanis)
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getBranchData function---" + error
            );
          });
        }else{
          let payload="";
          if(context.logindata.is_admin){
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_chanel:state.sales_chanel}
          }else{
            payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_chanel:state.sales_chanel,uid:context.logindata.uid}
          }

        }
     }else if(state.sales_chanel===""){

     }else if(state.region_type===""){
     }
    }
    if(ofc.length>0){
      dispatch({ type: "ofcID", payload: ofc[0].value });
    }

    props.sendOfcId(ofc)
  }

  const handleStaChange = (e) => {
    dispatch({ type: 'staCompanies', payload: [] })
    setData([])
    if (e && e.length > 0) {
      // staCompanies
      dispatch({ type: 'ta_comp', payload: e });
      getSTACompanyList(e[0].value).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'staCompanies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanySTAList function---" + error
        );
      });
    }
  }
  return (
    <>
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
      <Form.Row>
        <div className="container">
          {props.configType === "Markup" ? (<h4 className="subTitle plusHr">Profiles MarkUp</h4>) : (<h4 className="subTitle plusHr">ServiceFee Profiles</h4>)}
          {state.userType === "att_user" &&
          <Form.Row>
          <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={props.edit_salesChanel}/>
          <RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={props.edit_region} callBranch={state.enableDevices} flowType={"Markup"} />
          <Form.Group as={Col} xs={2} className="selectplugin" controlId="supplier">
          <Select
            options={supplier}
            values={state.supplier}
            disabled={state.edit_supplier && state.edit_supplier.length > 0 ? true : false}
            // onChange={updateSupplier}
            onChange={(value) => (dispatch({ type: "supplier", payload: value }), dispatch({type: "supplierError", payload: false}),updateSupplier(value))}
          />
            {state.supplierError && (
              <p className="vError">Please Select Supplier</p>
            )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
         </Form.Group>

          {state.enableOfcId &&<Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="supplier"
           >
          <Select
          disabled= {props.edit_region?true:false}
            options={state.officeIds}
            values={state.ofcs.length !== 0 ? state.ofcs : editOfc}
            onChange={updateOfcId}
          />
          <Form.Label>
            Office Id <sup>*</sup>
          </Form.Label>
        </Form.Group>}
           </Form.Row>
           }
          <ApplicableMarkup configType={props.configType} sendPaxData={paxMarkup} updateData={updatePaxData} reset={props.reset || state.reset} sendAppMarkupId={deletePaxMarakup} editPaxData={paxData} disabledBtn={props.disabledBtn} />
          {(props.configType == "Markup") && (<h4 className="subTitle plusHr">RBD</h4>)}
          {props.configType == "Markup" &&
            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="cls_id">
                <Select
                  options={cls}
                  values={state.classType}
                  onChange={(value) => (dispatch({ type: 'classType', payload: value }), dispatch({ type: 'type', payload: value.length > 0 && value[0].value }))}
                />
                <Form.Label>Class <sup>*</sup></Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  multi
                  options={state.rbdMaster}
                  values={state.classCode}
                  onChange={(value) => (setValue(value), setCls(value))}
                />
                <Form.Label>RBD</Form.Label>
              </Form.Group>
              <Form.Group as={Col}>
                {!disableButton &&
                  <Button
                    size="xs"
                    variant="outline-primary"
                    onClick={handleClses}
                  > Add</Button>
                }
                {disableButton &&
                  <Button
                    size="xs"
                    variant="outline-primary"
                    isDisabled={true}
                  > Add</Button>
                }
              </Form.Group>
            </Form.Row>
          }
          {classState.class.length !== 0 && <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Class*</th>
                      <th>RBD</th>
                      <th width="8%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classState.class.map((cl, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <span>{cl.cls}</span>
                          </td>
                          <td>
                            <span>{cl.rbd}</span>
                          </td>
                          <td>
                            <Button
                              size="xs"
                              onClick={handleRemoveClass(cl, index)}
                              isDisabled={(props.disabledBtn) ? true : false}
                             variant="link" className="ezyIcon icon-delete">
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>}


          <Form.Row>
            {state.userType == "att_user" &&
              <Form.Group as={Col}  lg={3} md={6} controlId="classes" className="inputCheck ">

                <div className="d-flex align-items-center">
                  {/* <Form.Check type="radio" label="Branch" onChange={handleAppTypeChange} value={state.aptype} checked={enApplicable} custom name="applicableTo" className="ml-0" id="branch" /> */}
                  {/* <Form.Check type="radio" label="Category" onChange={handleAppTypeChange} value={state.aptype} checked={state.aptype !== "Branch"} custom name="applicableTo" id="category" /> */}
                  <Form.Check type="radio" label="Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={!sta} custom name="ctype" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={sta} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Applicable Type</Form.Label>
              </Form.Group>
            }
            <br/>


          {state.enableDevices &&
          <>
 <Form.Group as={Col} lg={4} md={6} className="inputCheck">
<Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
             </div>
             </Form.Group>
            </>

          }




            {state.userType === "ta_user" &&
              <Form.Group as={Col} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} onChange={handleTypeChange} checked={companyType} custom name="ctype" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleTypeChange} checked={!companyType} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
            {state.userType === "sta_user" &&
              <Form.Group as={Col} xs={2} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} checked={companyType} custom name="ctype" className="ml-3" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
          </Form.Row>
          <Form.Row>
            {!state.enableDevices && state.enbleType && state.compType && state.userType === "att_user" && !sta && (state.ctyp !== "TA" && state.ctyp !== "STA") &&

              <Form.Group as={Col} className="picklist" xs={7} >
                {state.branches && state.branches.length !== 0 &&
                  <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" type={state.compType} selData={data} dataList={state.branches} sendData={pickldata} />
                }
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>

            }
            {/* {context.logindata.utype === "ta_user" &&

              <Form.Group as={Col} xs={3} controlId="cat">
                <Form.Control as="select" value={state.cat} onChange={handleCategoryChange}>
                  <option value="">Select</option>
                  {state.catMenu}
                </Form.Control>
                <Form.Label>Categoryy<sup>*</sup></Form.Label>
              </Form.Group>

            } */}
            {/* !state.showCat && context.logindata.utype !== "sta_user" && */}
            {state.branchMenu.length!==0 && context.logindata.utype !== "ta_user"&& context.logindata.utype !== "sta_user" && !state.enableDevices &&
              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value={state.data}>Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
            }

            {!state.showCat && sta && companydata && companydata.length!==0 &&
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={companydata}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled}
                  values={state.ta_comp}
                  onChange={(value) => (handleStaChange(value))}
                />
                <Form.Label>Ta Company</Form.Label>
              </Form.Group>
            }
            {companydata && companydata.length !== 0 &&  !sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            {state.staCompanies && state.staCompanies.length !== 0 && state.userType === "att_user" && sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={state.staCompanies} sendData={compdata} />
                <Form.Label>STA Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {!companyType && companydata && companydata.length !== 0 && state.userType === "ta_user" &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            <Form.Group as={Col} xs={2}>
              {!state.enableUpdate && !disableButton && (state.userType === "ta_user" || state.officeIds.length!==0) &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={showMarkupFares}
                > Add</Button>
              }
              {disableButton &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add</Button>
              }
            </Form.Group>
          </Form.Row>
          {state.enableUpdate &&
            <div className="text-right buttonGrop">
              <Button
                size="xs"
                onClick={showMarkupFares}
                variant="outline-primary"
                type="submit"
              >Update Applicable</Button>
            </div>
          }
        </div>
      </Form.Row>
      {fares.faresList.length !== 0 &&
        <div className="tableLayout">
          <Table>
            <thead>
              <tr>
                <th>Applicable On</th>
                {state.userType === "att_user" &&
                  <th>Branch</th>
                }
                <th>Company Name</th>

                <th className="text-right" width="8%">Actions</th>
              </tr>
            </thead>
            <tbody>
              {fares.faresList.map((fare, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <span>{fare.applyon}</span>
                    </td>
                    {state.userType === "att_user" &&
                      <td>
                        <span>{fare.borcname!==""?fare.borcname:"N/A"}</span>
                      </td>
                    }
                    <td>
                      <span>{fare.company}</span>
                    </td>
                    <td className="text-right">
                      {
                        <Button
                          size="xs"
                          variant="link"
                          onClick={editFareMap(fare)}
                          isDisabled={false}
                          className="ezyIcon icon-edit"
                          type="submit">
                        </Button>
                      }
                      {context.logindata.roles !== undefined && context.logindata.roles.split(",").includes('M_U_D') && !state.hideDelete && fares.faresList.length >1  &&
                        <Button
                          size="xs"
                          variant="link"
                          onClick={deleteFareMap(fare, index)}
                          isDisabled={(props.disabledBtn) ? true : false}
                          className="ezyIcon icon-delete"
                          type="submit">
                        </Button>
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </>
  )
}
export default AirMarkupFareMapForm