import React, { useContext, useState,useEffect,useRef } from "react";
import { Context } from "../../../../App";
import Flight from "../../landing/flight/Flight";
import Header from "../Header";
import Hotel from "../hotel/Hotel";
import Visa from "../../bookingFlows/visa/search/VisaSearch"
import TransfersSearch from "../hotel/Transfers";
import DashBordSlider from "../imageSlider/DashBordSlider";
import DashBordSlidervisa from "../imageSlider/DashBordSlidervisa";
import DashBordSliderhotel from "../imageSlider/DashBordSliderhotel";
import DashBordSliderinsurances from "../imageSlider/DashBordSliderinsurances";
import Insurance from "../../bookingFlows/insurance/search/Insurance";
import SaudiActivities from "../../common/dashBoard/SaudiActivities"
import Saudiwelcome from "../../../assets/images/saidiwelcome.png";
import Clickopenpackage from "../../../assets/images/summerpack.jpg";
import {getBookingsData,getCompanyBookingsData} from '../../dashBoard/operations';
import { Modal, Button } from "react-bootstrap";
import DashBoardBookings from "./DashBoardBookings";
export const SHOW_NOTIFY_HOTEL = "showNotifyHotel";

function DashBordHeader(props) {
  const { state } = props || {};
  const [flight, setFlight] = useState(true);
  const [hotel, setHotel] = useState(false);
  const [transfers, setTransfers] = useState();
  const [context, setContext] = useContext(Context);
  const [insurance, setInsurance] = useState(false);
  const [visa, setVisa] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [disableHotel, setDisableHotel] = useState(false);
  const [bookingData, setBookingsData] = useState("");
  const [showPanel, setShowPanel] = useState(false);
  const [noRecord, setNoRecord] = useState(false);
  const [tabs, setTabs] = useState("");
  const [dashBoradLoader, setDashBoradLoader] = useState(false);
  const [onmarkup,setOnmarkup] = useState(true);
  const ref = useRef();

  const clickedTabs = (active) => {
    setTabs(active);
  };

  const openMarkUp = () => {
    if(onmarkup) {
      setOnmarkup(false)
    } else {
      setOnmarkup(true)
    }
  }


  useEffect(() => {
    if(localStorage.getItem("COMP_ID")!==null && showPanel){
      setShowPanel(false)
      setBookingsData("")
      setNoRecord(false)
    }
  }, [localStorage.getItem("COMP_ID")]);
  useEffect(() => {

    if(context && context.logindata&&context.logindata.roles&&context.logindata.roles.split(',').includes("D_B_F")){
      openFlight()
    }else if(context && context.logindata&&context.logindata.roles&&context.logindata.roles.split(',').includes("D_B_H")){
      openHotel()
    }else if(context && context.logindata&&context.logindata.roles&&context.logindata.roles.split(',').includes("I_S_D")){
      openInsurance()
    }
  },[context && context.logindata&&context.logindata.roles&&context.logindata.roles])

  const handleClose = () => {
    setEnableDelete(false);
  };

  const domin = [
    { label: "QAR", value: "QAR" },
    { label: "DXB", value: "DXB" },
  ];
  const openFlight = () => {
    if (flight) {
      //setFlight(false);
      setHotel(false);
      setInsurance(false);
    } else {
      setFlight(true);
      setHotel(false);
      setInsurance(false);
      setVisa(false);
      props.clickedOnHotel(false);
    }
  };
  const openHotel = () => {
    if (hotel) {
      // setHotel(false);
      setFlight(false);
      setInsurance(false);
      setVisa(false);
      props.clickedOnHotel(false);
    } else {
      setHotel(true);
      setFlight(false);
      setVisa(false);
      setInsurance(false);
      props.clickedOnHotel(true);
    }
  };

  const openVisa = () => {
    if (visa) {
      setHotel(false);
      setFlight(false);
      setInsurance(false);
    } else {
      setVisa(true);
      setHotel(false);
      setFlight(false);
      setInsurance(false);
     
    }
  };


  const openInsurance = () => {
    setTabs("");

    setEnableDelete(true);
    if (insurance) {
      setHotel(false);
      setFlight(false);
      setVisa(false);
    } else {
      setHotel(false);
      setFlight(false);
      setVisa(false);
      setInsurance(true);
    }
  };
  const handleIns = (e) => {
    setTabs(e.target.value);
    handleClose();
  };

  const openTransfers = () => {
    if (transfers) {
      setHotel(false);
      setFlight(false);
      setTransfers(false);
    } else {
      setTransfers(true);
      setHotel(false);
      setFlight(false);
    }
  };
  const importPnr = (data) => {
    props.sendPnrVal(data);
  };
  const sendHotelresp = (data) => {
    props.sendHotelRespo(data);
  };
  const newBooking = (data) => {
    props.newBooking(data);
  };

  const bookingRespDetail = (res) => {
    props.bookingListRes(res)
  }

  const getDashBoardBookings=()=>{
    setBookingsData("")
    setDashBoradLoader(true)
    if(!showPanel){
      setNoRecord(false)
      setShowPanel(true);
    let companyId=localStorage.getItem("COMP_ID");
    if(companyId!==null && companyId!=="" && companyId!=="0"){
      const req={
        "region_type":context.logindata.region_type,
        "cid":Number(companyId)
      }
      getCompanyBookingsData(req).then(response => {
        if(response.data!==undefined && response.data.suc){
      setBookingsData(response.data.res);
    }else{
      setNoRecord(true);
    }
    }).catch(function (error) {
    console.log('setBookingsData response error ', error)
    });
    setDashBoradLoader(false)
    }else{
      getBookingsData({"region_type":context.logindata.region_type}).then(response => {
        if (response.data!==undefined && response.data.suc) {
      setBookingsData(response.data.res);
    }
     }).catch(function (error) {
    console.log('setBookingsData response error ', error)
     });
    }
    setDashBoradLoader(false);
  }else{
    setShowPanel(false);
    setDashBoradLoader(false);
  }
  }

  return (
    <>
      <Header bookingListRes={bookingRespDetail} activeHeader="dashbord" />
      {/* <DashBordSlider /> */}
      <section className={"dashBord d-flex " + (flight ? "flight" : "") + (insurance ? "insurance" : "") + (hotel ? "hotel" : "") + (visa ? "visa" : "")}>
        <div className="travelPlane">
        <DashBordSlider />
        </div>
        <div className="travelHotel">
        <DashBordSliderhotel />
        </div>
        <div className="travelInsurances">
        <DashBordSliderinsurances />
        </div>
        <div className="travelVisa">
        <DashBordSlidervisa />
        </div>

        <div className="container">
          <h1 className="text-center mt-5 mb-5">
      DISCOVER PLACES WITH US
         </h1>
          <div className="searchNewTab">
            <ul className="d-flex booking p-0">
              {context.logindata.roles &&
                context.logindata.roles.includes("D_B_F") && (
                  <li onClick={openFlight} className={"d-flex " + (flight ? "active" : "")}>
                    <span className="d-flex flex-row align-items-center prodLink ezyIcon icon-plane">
                      <span>Flights</span>
                    </span>
                  </li>
                )}
               {context.logindata.roles &&
                context.logindata.roles.includes("D_B_H") && (
                  <li onClick={openHotel}
                  className={"d-flex newTag " + (hotel ? "active" : "")}>
                    <span
                      className="d-flex flex-row align-items-center prodLink ezyIcon icon-newhotel">
                      <span>Hotels</span>
                    </span>
                  </li>
                )}
              {context.logindata.roles && context.logindata.region_type==="Qatar" &&
                context.logindata.roles.includes("I_S_D") && (
                  <li  onClick={openInsurance} className={"d-flex newTag " + (insurance ? "active" : "")}>
                    <span

                      className="d-flex flex-row align-items-center prodLink ezyIcon icon-plussheild ">
                      {/* { <span className="newTagline">
                        <img src={Newtag} />
                      </span> } */}
                      <span>Insurance</span>
                    </span>
                  </li>
                )}
                  {/* <li  onClick={openVisa} className={"d-flex newTag " + (visa ? "active" : "")}>
                     <span className="d-flex flex-row align-items-center prodLink ezyIcon icon-Visa">
                      <span>Visa</span>
                    </span>
                </li> */}
              {/* <li> <span onClick={openTransfers} className={"d-flex flex-row align-items-center prodLink ezyIcon icon-bus " + (transfers ? 'active' : '')}>
                            <span>Transfers</span>
                          </span></li> */}
            </ul>
            {flight && (
              <Flight sendPnrStats={importPnr} newBooking={newBooking} />
            )}
             {hotel && <Hotel />}
             {visa && <Visa />}
            {insurance && context.logindata!==undefined && context.logindata.region_type==="Qatar" &&(
              <Insurance
                tabs={tabs}
                clickedTabs={(active) => clickedTabs(active)}
              />
            )}
          </div>

          <div className="saudiActivi">
      <div className="saudiActivity d-flex">

          <div className="saudiImage">
          <img src={Saudiwelcome} />
          </div>
          <div className="saudiImageslide">
          <SaudiActivities />
            </div>
            <div className="saudiCont">
              <div className="mainBg">

                <div className="inManibg">

                  <h3>
                  Discover the country’s hidden gems & breathtaking landscapes
                  </h3>
                  <span className="knowMore"><a href="https://sauditourism.ezytrip.com/activity/home" target="_blank" >Know More</a><span className="ezyIcon icon-rightArrow"></span></span>
                </div>


              </div>
            </div>
            <br/>

    </div>
      </div>
      <div className={"mrkup " + (onmarkup ? 'open':'') } ref={ref} >
      <span className="arrBg" onClick={openMarkUp}><span className="ezyIcon markup icon-arrowDown"></span></span>
      {onmarkup &&
            <div className={"admarkup " + (onmarkup ? 'active':'') }>
    <a href="https://www.flipbookpdf.net/web/site/3e67e61979e193fa6b000ab124784ab58bb374d3FBP28055022.pdf.html#page/1" target="_blank"><img src={Clickopenpackage} /></a>
            </div>
            }

        </div>
        </div>
      </section>
      <section>
      <div className="container">

      </div>
      </section>

      <section>



        <div className="container">
        {dashBoradLoader ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <div className={"dashboardLastseven "+showPanel}>
        <Button onClick={getDashBoardBookings} className="d-flex viewSevendays"><span className="ezyIcon icon-viewsevendays"></span>Dashboard (View Last 7 days transactions)</Button>
        {bookingData!=="" && showPanel &&
         <DashBoardBookings bookingsData={bookingData}/>
        }
        {noRecord &&
          <span>No Data found</span>
        }

         </div>
        </div>

      </section>


      <Modal
        show={enableDelete}
        onHide={handleClose}
        className="importPNRMdl requiredInsu"
        backdrop="static"
        keyboard={false}
      >
            <Modal.Header closeButton>
          </Modal.Header>
        <Modal.Body onHide={handleClose}>
          <div className="popUplayout center">
            <div className="backGroundgif">
              <span className="backGroundbot"></span>
              <span className="ezyIcon icon-plussheild"></span>
            </div>
            <div className="bCont mt-4">
              <span className="searchingText">
                Choose your travel insurance request type
              </span>
              <form>
                <div className="labeinsuRadio d-flex align-items-center justify-content-center">
                  <label>
                    <input
                      type="radio"
                      name="name"
                      value="Inbound"
                      onChange={(e) => {
                        handleIns(e);
                      }}
                    />
                    Inbound
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="name"
                      value="Outbound"
                      onChange={(e) => handleIns(e)}
                    />
                    Outbound
                  </label>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DashBordHeader;
