/**
 * @description: Flight Summary page Root component
 * @date:17-09-2020
 * @author:Rambabu
 *
 */
 import log from "loglevel";
 import moment from 'moment';
 import React, { useContext, useEffect, useReducer, useRef, useState, } from 'react';
 import Select from 'react-select';
 import { Alert, Col, Modal, Row, Table, Form } from "react-bootstrap";
 import { Link, useHistory } from 'react-router-dom';
 import { Context } from "../../../../App";
 import Button from '../../common/buttons/Button';
 import Footer from '../../common/Footer';
 import Header from '../../common/Header';
 import InnerLoader from '../../common/loader/InnerLoader';
 import useOutsideClick from '../../common/OutsideClick';
 import ProcessingReissue from '../../common/processingPopUp/ProcessingReissue';
 import Processing from '../../common/processingPopUp/Processing';
 import ProcessingVoidSPlitPNR from '../../common/processingPopUp/ProcessingVoidSplitPNR';
 import ShowHide from '../../common/showHide/ShowHide';
 import DateUtils from '../../commonUtils/DateUtils';
 import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
 import Expire from '../../../assets/images/warningoutline.gif'
 import FlightSummaryImportSectors from '../../postBooking/importPNR/FlightSummaryImportSectors';
 import ImportPNRunticketDetails from '../../postBooking/importPNR/ImportPNRunticketDetails.jsx';
 import {sendRequest, callDiscardBook, fetchfareDetailsReissued, callTicketing, cancelVoid, fireIncompleteService, getairportData, getATCAirline, getCountryCodes, getFltSummaryData, getRefundDetails, getReissueDetails, retriveFareDetails, saveVoid, showRefunds, voidFareFetch, processEditBooking, cancelledSplit, atc_refundairlines, getcompanyAddress, validateBookingSummary, cancelBookingFlow, getReissueSplitResp, retrievereissuesplit, fetchHistoryData,pnrSearch, insertPNRData,getPNRDetails,getIndigoRefundPricing,showIndigoRefunds, getItnarary } from './operations';
 import SplitPnr from '../void/SplitPnr.jsx';
 import FlightSummaryBookingDetails from './FlightSummaryBookingDetails.jsx';
 import FlightSummaryFareDetails from './FlightSummaryFareDetails.jsx';
 import FlightSummaryPaxDetails from './FlightSummaryPaxDetails.jsx';
 import FlightSummaryRefund from '../refund/FlightSummaryRefund';
 import FlightSummarySectors from './FlightSummarySectors.jsx';
 import VoidSummaryDetails from '../void/VoidSummaryDetails.jsx';
 import Reissue from '../reIssue/Reissue';
 import SplitReissuePNR from '../flight/splitReissue/SplitReissuePNR';
 import Utilities from '../../commonUtils/Utilities';
 import FlightInvoicePdf from '../flight/invoice/FlightInvoicePdf';
 import FlightIndigoInvoicePdf from '../flight/invoice/FlightIndigoInvoicePdf';
 import Tabs from '../../common/tabs/Tabs';
 import CustomFields from './SummaryCustomFields';
 import FlightReissueSplit from '../flight/splitReissue/FlightReissueSplit';
 import ShowInsertPnrData from '../../postBooking/importPNR/ShowInsertPnrData';
 import ShowManualPnrData from "../importPNR/ShowManualPnrData";
 import { useReactToPrint } from "react-to-print";
import { emailValidation } from "../../commonUtils/FormValidations";
import { sendConfirmationObj } from "../../bookingFlows/flight/review/operations";
import IndigoSummaryDetails from "../refund/IndigoSummaryDetails";
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
 const _ = require('lodash');
 const JSON = require('circular-json');
 export const USER_AUTH_DATA = "userAuthData";
 export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
 export const SUMMARY_COMP_REGION = "SUMMARY_COMP_REGION";
 export const EDIT_DATA = "editData";
 export const EDIT_TICKET_DATA = "editTicketData";
 export const IS_LATEST = "IS_LATEST";
 const SUR_CHARGE = process.env.BOOKING_SUR_CHARGE;
 let indigopaxList = []
 let selPNRS = []
 /**
  * Initial State Declaration
  */
 const initialState = {
  gstObj:[],gst_obj:"", attServiceFee:0, taServiceFee:0, onHoldItinerary:true,
  ow_ofcid:"", rt_ofcid:"", ow_gst:"",rt_gst:"",summary_Res: [], bkng_det: [], pax_det: [], seg_det: [], no_trip: false, fare_det: [], fare_det_normal: [], pay_det: [], airport_data: [], ticketResp: [],
   incompletpopup: false, notiMessageShow: false, notiMessage: '', notiVarient: '', onholdResp: "", flow_type: '', bktype: '', enableVoidBooking: false, voidCountrydata: [],
   voidBid: '', voidPNR: '', tkts: [], voidTid: '', voidtransId: null, voidOfcId: '', rfAmount: null, voidSaveResponse: [], enableVoidCancel: false, disableCheckbox: false,
   pnr_Response: '', company_id: 0, enableRefund: false, aircodes: "", showRefund: false, ticketsVoided: [], ticketsVoidedCancelled: [], valid: true, isLoading: false, isvoidSplitLoading: false, voidLoading: false, refundAvail: false,
   voidCancelTickets: [], reIssueResponse: [], b_status: '', comp_id: 0, showReissue: false, enableReissue: false, atcmsg: "", allTktslength: 0, voidSelectedLength: 0, clickedOn: "", butypehandler: [], owpaxTNO: [], rtpaxTNO: [], owpaxVoid: [], rtpaxVoid: [], isCancelBook: false,
   btype: 'partial', refund_split: [], enableEdit: false, updateBstatus: "", showEditpop: false, enableReturnEdit: false, enableEditBooking: false, onwardAvail: false, returnAvail: false, voidFareResponse: [], splitissue: false, splitCancel: false, voidenable: false, refundenable: false, splitPNR: "", enableOnwardIti: true, ticketsRefunded: [], airCodesArray: [], atc_refundable: false
   , loader: false, trip_type: "", hideReturn: false, enabledownloaddocs: false, popuppaxdata: [], allpopuppaxdata: [], flt_supplier: 0, flt_supplier_return: 0,
   ticketed: true, void: false, refund: false, downloadtype: 'INVOICE', invoice: true, cinvoice: false, itinerary: false, normalTFtwoLcc: false,
   popupindigopaxListay: [], popupticketsdata: [], refundsplitonward: [], refundsplitreturn: [], flightDocsData: "", pupupmsg: 'No records found',type_selected:"Onward",
   popupallc: false, custms: [], cancelPopup: false, cancel_flow_type: "O", reissue_split_response: [], reissue_split_tag: false, all_tkts: [], split_tickets: [],
    enableSplitReissueO: [], enableSplitReissueR: [], enableRSplitPopup: false, process: false, initialRefundResponse: [],dd_supplier:"0", hold_tkts: [], enablesptpnr: false,  enableOnHoldPopup: false, OnHoldtkts : [], onward_hold_split: false,
    hold_split_type: "", show_return : true,tid:'',flow_type_fbf : "O", enableOnHoldRPopup: false, OnHoldRtkts: [],onward_hold_varient:false,onhold_tag : false, checkboxFocus : false,enableConfirmFunctions: false,onward:true,pnrResponse:"",enablePnrResp:false,enableInsert:false,insertPnr:"",insertResp:[],jtype:"", showCancel: false,cus_code:'',insertPax:[],
    todaysDate : Utilities.getTimeZoneDate(new Date(),"QA"),showPopup: false, email: "",enableManualReissue:false,manualResponse:"",continueReissue:false,isItineraryLoading:false,fbfreturn: false,showitinerary: "", returnshowitinerary: "",oldTid:"", showReissuelink: false, cancelledPax: [], rtcancelledPax: [], showOnholdcontinue : false, showOnholdowcontinue: false,  showOnholdreturncontinue: false, changeStatus:"", allpopuppaxticketeddata: [], showitineraryRadio: true,
    showCustReqPopup:false,req_type:"",req_desc:"",selReqtype:[],showIndigoRefund:false,indigo_refund_type:true,indigoPaxList:[],enableIndigoRefund:true,isIndigoAll:false, refundSeg: [] , showmessage:false,indigoRefundList:[],indigoRefundRetList:[],selIndigoSector:"O", enableOWIndigo:false , enableREIndigo:false , showRefndSectors:[],temp_onwardList:[],temp_returnList:[],showContinueBtn:true,temp_paxList:[], showOffline_msg:false, showSelectAll:true, indigoDocsList:[],indigo_refund_docs:{},sel_tid:"",enable_pdf:false,bk_region_type:"",usr_cur:""};


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

   switch (action.type) {

     default:
       return { ...state, [action.type]: action.payload };
   }
 };

 /**
  * @description: This function will show the Flight Summary page
  * @date:17-09-2020
  * @author:Rambabu
  *
  */
 function FlightSummaryPage(props) {
   const [state, dispatch] = useReducer(reducer, initialState);
   const history = useHistory();
   const ref = useRef();
   const [editMore, setEditMore,] = useState(false);
   const [editvoidMore, setvoidEditMore,] = useState(false);
   const [editrefundMore, setrefundEditMore,] = useState(false);
   const [share, setShare,] = useState(false);
   const [editItinerary, setEditItinerary,] = useState(false);
   const [editPayment, setEditPayment,] = useState(false);
   const [show, setShow] = useState(false);
   const handleClose = () => { setShow(false); dispatch({ type: 'incompletpopup', payload: false }, dispatch({ type: 'voidLoading', payload: false }, dispatch({ type: "cancelPopup", payload: false },dispatch({type:"enableOnHoldRPopup",payload:false},dispatch({type:"enableOnHoldPopup",payload:false}),dispatch({type:"enableInsert",payload:false}) ,dispatch({type:"enableManualReissue",payload:false}))))) }
   const [isLoadingMore, setIsLoadingMore] = useState(false);
   const [context, setContext] = useContext(Context);
   const [voidTicket, setVoidTicket] = useState([]); //applied void tickets during selective
   const handleShow = () => setShow(true);
   const [request, setRequest] = useState(props.location);
   const voidCncl = useRef(null)
   const executeScrolltop = () => voidCncl.current.scrollIntoView()
   const [requestType,setRequestType]= useState([{ label:"Cancel my Booking", value:'Cancel my Booking'},{label:"Change my Booking", value:'Change my Booking'},{label:"Other Queries", value:'Other Queries'}])
   const componentRef = useRef();
   const [segments,setSegments] = useState([]);
   const handlePrint = useReactToPrint({
     content: () => componentRef.current,
     documentTitle: state.bkng_det !== undefined && state.bkng_det[0] !== undefined && state.bkng_det[0].booking_id !== undefined && ("FLIGHT_" + state.downloadtype + "_" + state.bkng_det[0].booking_id)
   }
   );
   const [ow_ofcid, setOw_ofcid] = useState("");
   const [rt_ofcid, setRt_ofcid] = useState("");   
   const [officeID, setOfficeID] = useState("");
   const [tds_percentage_plb, setTdsPrecentagePlb] = useState(0)

  
   const atc_refund_airlines = ["BR", "BT", "BW", "CI", "CU", "DE", "E9", "EN", "ET", "G3", "GL", "GP", "H1", "HM", "HR", "IB", "IG", "J9", "JJ", "JP", "JU", "J2", "KC", "KE",
     "KU", "LH", "LR", "LX", "MF", "MH", "MI", "NE", "NH", "NZ", "O6", "OB", "OS", "OZ", "PC", "PG", "PK", "PM", "PS", "QR", "QS", "QV", "RJ", "S4", "SB", "SK", "SN", "SP", "SQ", "SS",
     "ST", "SV", "SW", "T0", "TA", "TF", "TN", "TK", "TP", "U6", "UA", "UK", "VA", "VS", "WB", "WE", "WX", "WY", "X1", "X3", "Z9", "ZL", "ZM"]
   const openMOre = () => {
     if (editMore) {
       setEditMore(false)
     } else {
       setEditMore(true)
     }
   }
   const openSplitMOre = () => {
     if (editvoidMore) {
       setvoidEditMore(false)
     } else {
       setvoidEditMore(true)
     }
   }
   const openSplitRefundMOre = () => {
     if (editrefundMore) {
       setrefundEditMore(false)
     } else {
       setrefundEditMore(true)
     }
   }
   const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }
   const openshare = () => {
     if (share) {
       setShare(false)
     } else {
       setShare(true)
     }
   }
   const openeditItinerary = () => {
     if (editItinerary) {
       setEditItinerary(false)
     } else {
       setEditItinerary(true)
     }
   }
   const openeeditPayment = () => {
     if (editPayment) {
       setEditPayment(false)
     } else {
       setEditPayment(true)
     }
   }

   useOutsideClick(ref, () => {
     if (editMore) setEditMore(false);
     if (share) setShare(false);
     if (editItinerary) setEditItinerary(false);
     if (editPayment) setEditPayment(false);
   });

   useEffect(() => {
     window.scrollTo(0, 0)
   }, [])

   useEffect(() => {

     let booking_ref = '';
     let unique_airport_codes = [];
     let unique_airline_codes = [];
     //fetch data from window pathname && window.location.href
     if (isNotNull(window.location.pathname)) {
       let url_array = window.location.pathname.split("/");
       booking_ref = url_array[url_array.length - 1];

     } else {
       let url_array1 = window.location.href.split("/");
       booking_ref = url_array1[url_array1.length - 1]

     }

     //fetch data from window param and call api for summary
     if (isNotNull(booking_ref)) {
       const req = {
         booking_id: booking_ref
       }
       if (context.logindata !== undefined && context.logindata !== "") {
         const loginUserData = JSON.parse(localStorage.getItem('authData'));
         let rq_json = {
           "cid": loginUserData.cid,
           "uid": context.logindata.uid,
           "is_admin": context.logindata.is_admin,
           "bid": booking_ref
         }
         //validation of booking fetch
         validateBookingSummary(rq_json).then((result) => {
           if (result.data.status) {

             //call api for booking summary resepective booking_id
             getFltSummaryData(req).then((res) => {
               dispatch({ type: 'summaryRes', payload: res })
               let all_tkts = [];
               let bookingData = [];
               let paxData = [];
               let paxIndigoData = [];
               let tkno_refundable = []
               let allpax = []
               let reissue = false
               let ft = ""
               let reissue_split = false
               let seg_details = []
               let split_tickets = []
               let pnrs = []
               let hold_tkts = []
               let hold_tkts_split = []

               for (let r in res) {
                 let data_ = res[r];

                 //Booking_details_section
                 if (isNotNull(data_['bkng_det'])) {
                   bookingData = data_.bkng_det;
                   dispatch({ type: 'bkng_det', payload: data_.bkng_det })
                   dispatch({ type: 'flow_type', payload: data_.bkng_det[0].flow_type });
                   reissue_split = data_.bkng_det[0].issplitreissue //reiisue tag call to function
                   ft = data_.bkng_det[0].flow_type
                   if(data_.bkng_det[0].flow_type !== "split") {
                   dispatch({type:"tid",payload:data_.bkng_det[0].tid})
                   }
                   dispatch({type:"cus_code",payload:data_.bkng_det[0].customer_code})
                   dispatch({ type: "flt_supplier", payload: Number(data_.bkng_det[0].supplier) })
                   dispatch({ type: "flt_supplier_return", payload: Number(data_.bkng_det[0].supplier) })
                   dispatch({ type: 'bktype', payload: data_.bkng_det[0].bktype });
                   dispatch({ type: 'b_status', payload: data_.bkng_det[0].booking_status })
                   dispatch({ type: 'company_id', payload: data_.bkng_det[0].company_id })
                   dispatch({ type: "normalTFtwoLcc", payload: data_.bkng_det[0].normalTFtwoLcc })
                   localStorage.setItem("SUMMARY_COMP_ID", data_.bkng_det[0].company_id)
                   window.sessionStorage.setItem("SUMMARY_COMP_REGION", data_.bkng_det[0].region_type)
                   dispatch({ type: 'comp_id', payload: data_.bkng_det[0].company_id })
                   dispatch({ type: "custms", payload: data_.bkng_det[0].custms })
                   dispatch({ type: "reissue_split_tag", payload: data_.bkng_det[0].issplitreissue ? data_.bkng_det[0].issplitreissue : false })
                   dispatch({ type: "bk_region_type", payload: data_.bkng_det[0].region_type })
                   dispatch({ type: "usr_cur", payload: data_.bkng_det[0].usr_curr_code })
                   pnrs = data_.bkng_det[0].pnr_arr && data_.bkng_det[0].pnr_arr.length > 0 ? data_.bkng_det[0].pnr_arr : []

                   //  call api for gst
               if(data_.bkng_det[0].region_type=="India"){
                getItnarary({"tid":data_.bkng_det[0].tid}).then((response)=>{
                if ((res[0].bkng_det[0].journey_type=="Onward" || res[0].bkng_det[0].journey_type=="O"|| res[0].bkng_det[0].journey_type=="O/R") && !data_.bkng_det[0].tid.includes("/")){
                dispatch({type:"ow_gst", payload: response.data[0].gst})
              }
                if ((res[0].bkng_det[0].journey_type=="Onward" || res[0].bkng_det[0].journey_type=="Return" || res[0].bkng_det[0].journey_type=="O")&& data_.bkng_det[0].tid.includes("/")){
                dispatch({type:"ow_gst", payload: response.data[0].ow_gst})
                dispatch({type:"rt_gst", payload: response.data[0].rt_gst})
              }
               })}

             
                if(data_.bkng_det[0].region_type=="India"){
                  getItnarary({"tid":data_.bkng_det[0].tid}).then((response)=>{
                if ((res[0].bkng_det[0].journey_type=="Onward" || res[0].bkng_det[0].journey_type=="O"|| res[0].bkng_det[0].journey_type=="O/R") && !data_.bkng_det[0].tid.includes("/")){
                  // if ((response.data[0].journey_type=="Onward" && response.data[0].journey_type!=="" && response.data[0].journey_type!==undefined )){
                dispatch({type:"ow_gst", payload: response.data[0].gst})
                setRequest({...props.location, ow_gst: response.data[0].gst})
              } 
              if ((res[0].bkng_det[0].journey_type=="Onward" || res[0].bkng_det[0].journey_type=="Return" || res[0].bkng_det[0].journey_type=="O")&& data_.bkng_det[0].tid.includes("/")){
                // if ((response.data[0].journey_type=="Return" && response.data[0].journey_type!=="" && response.data[0].journey_type!==undefined)){
                dispatch({type:"ow_gst", payload: response.data[0].ow_gst})
                dispatch({type:"rt_gst", payload: response.data[0].rt_gst})
                setRequest({...props.location, ow_gst: response.data[0].gst, rt_gst: response.data[0].gst})
              }
               })}

               


               
                  //  if(data_.bkng_det[0].region_type=="India") {
                    // getItnarary({"tid":data_.bkng_det[0].tid}).then((response)=> {
                    //   if((response.journey_type=="Onward")){
                    //     dispatch({type:"ow_gst", payload: response.data[0].gst})
                    //   }
                    //   if((response.journey_type=="Return")) {
                    //     dispatch({type:"ow_gst", payload: response.data[0].ow_gst})
                    //     dispatch({type:"rt_gst", payload: response.data[0].rt_gst})
                    //   }
                    // })
                  //  }

                   // ATT - 5929 Multi OID post booking
                  //  if(data_.bkng_det[0].flow_type === "normal") {
                  //   setOw_ofcid(data_.bkng_det[0].office_id)
                  //  } else if (data_.bkng_det[0].flow_type === "split") {
                  //     if(res[1].seg_det[0].flow_type === "split" && res[1].seg_det[0].journey_type === "O") {
                  //       setOw_ofcid(res[1].seg_det[0].office_id)
                  //     } else if (res[1].seg_det[0].flow_type === "split" && res[1].seg_det[0].journey_type === "R") {
                  //       setRt_ofcid(res[1].seg_det[1].office_id)
                  //     }
                  //  }
                   
                   if(data_.bkng_det[0].flow_type === "normal") {
                    dispatch({type: "ow_ofcid", payload: data_.bkng_det[0].office_id});
                    setRequest({...props.location, ow_ofcid: data_.bkng_det[0].office_id})
                    // setOw_ofcid(data_.bkng_det[0].office_id)
                   } else if (data_.bkng_det[0].flow_type === "split") {
                    // let officeIdArray = data_.bkng_det[0].office_id.split("/");
                    let officeIdArray = data_.bkng_det[0].tid.split("/");
                    let onwardOfficeId = officeIdArray[0];
                    let returnOfficeId = officeIdArray[1];
                    dispatch({type: "ow_ofcid", payload: onwardOfficeId});
                    dispatch({type: "rt_ofcid", payload: returnOfficeId});
                    setRequest({...props.location, ow_ofcid: onwardOfficeId, rt_ofcid: returnOfficeId })
                    // setOw_ofcid(onwardOfficeId);
                    // setRt_ofcid(returnOfficeId);
                   }

                   //For Reissue
                   var date = new Date(bookingData[0].booking_date);
                   var nextDate = date.setDate(date.getDate() + 1);
                   let dateNew = parseInt(DateUtils.prettyDate(nextDate, "YYYYMMDD"))
                   let todaysDate = parseInt(moment(new Date()).format("YYYYMMDD"))
                   if (dateNew >= todaysDate && data_.bkng_det[0].flow_type === "normal") {
                     dispatch({ type: 'showReissue', payload: true })
                   }
                   //Checking Ticketing time Limit condition
                   if(bookingData[0].booking_status==="22" && bookingData[0].tkt_time_limit!==undefined) {
                    const time_limit = new Date(bookingData[0].tkt_time_limit);
                    const todaysDate = new Date(new Date());
                    if(todaysDate >= time_limit){
                      dispatch({ type: 'showContinueBtn', payload: false })
                    }
                   }
                  //Updatig the On hold
                   if(data_.bkng_det[0].booking_status === "22"){
                    if(data_.bkng_det[0].flow_type == "normal"){
                      dispatch({ type: 'showOnholdcontinue', payload: true })
                    }else{
                      dispatch({ type: 'showOnholdowcontinue', payload: true })
                      dispatch({ type: 'showOnholdrtcontinue', payload: true })
                    }
                  }
                   //get compnay address  and branch address based on compnay id
                   if (isNotNull(data_.bkng_det[0].company_id)) {
                     const com_req = {
                       cid: data_.bkng_det[0].company_id
                     }
                     getcompanyAddress(com_req).then((response) => {
                       if (isArrayNotEmpty(response)) {
                         let com_data = response[0];
                         data_.bkng_det[0].comp_add = com_data.company_add;
                         data_.bkng_det[0].comp_city = com_data.comp_city_name;
                         data_.bkng_det[0].comp_cnt = com_data.comp_cnt_name;
                         data_.bkng_det[0].comp_lmark = null;
                         data_.bkng_det[0].branch_name = com_data.branch_name;
                         data_.bkng_det[0].branch_add = com_data.branch_add;
                         data_.bkng_det[0].branch_city = com_data.branch_city_name;
                         data_.bkng_det[0].branch_cnt = com_data.branch_cnt_name;
                         data_.bkng_det[0].branch_lmark = com_data.branch_landmark;
                         data_.bkng_det[0].branch_phone = com_data.branch_phone_no;
                         data_.bkng_det[0].branch_email = com_data.branch_email;
                         data_.bkng_det[0].logo_comp_url = com_data.comp_logo_url;
                         dispatch({ type: 'bkng_det', payload: data_.bkng_det })
                       }
                     });
                   }

                 }//pax_details_section
                 else if (isNotNull(data_['pax_det'])) {
                   let onhold_pax_arr = []
                   dispatch({ type: 'pax_det', payload: data_.pax_det })
                   dispatch({ type: 'temp_paxList', payload: data_.pax_det })
                   if (data_.pax_det.length > 0) {
                     for (let val in data_.pax_det) {
                       let pax = data_.pax_det[val]
                       if (val.length > 0) {
                         let voidrefundpax = []
                         let cancelledseg = []
                         for (let dub of pax) {
                          if(state.summaryRes !== undefined && state.summaryRes[0].bkng_det[0].flow_type == "normal"){
                            dispatch({type:"showitinerary",payload: true})
                            if(dub.stsid == "31" || dub.stsid == "39" || dub.stsid == "42" || dub.stsid == "44"){
                              voidrefundpax.push(dub)
                            }
                            if(dub.segst == "Cancelled"){
                              cancelledseg.push(dub)
                            }
                          }
                           allpax.push(val)
                           if(dub.stsid == "23" && !state.enableConfirmFunctions) {
                             dispatch({type:"enableConfirmFunctions",payload:true})
                           }
                           if (dub.split_pnr == null) {
                             if(dub.sts == "Pending" || dub.sts == "On Hold" ) {
                             if(val == 0) {
                              onhold_pax_arr.push(dub.sts)
                              dispatch({type:"onward_hold_varient",payload:true})
                             }
                              if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "42" || dub.stsid === "44" || dub.split_pnr == null) {
                                hold_tkts.push(dub.pax_name)
                              } else {
                                hold_tkts_split.push(dub.pax_name)
                              }
                             }
                             else{
                             all_tkts.push(dub.tkt_no)
                             }
                           } else {
                             if (dub.stsid === "31" || dub.stsid === "39" || dub.stsid === "40" || dub.stsid === "42" || dub.stsid === "43" || dub.stsid === "44" || dub.stsid === "45" || dub.stsid === "38") {
                               all_tkts.push(dub.tkt_no)
                             } else {
                               split_tickets.push(dub.tkt_no)
                             }
                           }

                           if (state.trip_type == "") {
                             dispatch({ type: "trip_type", payload: dub.trip_type })
                           }
                         }
                         if(pax.length == voidrefundpax.length){
                            dispatch({type:"showitinerary",payload: false})
                         }
                         dispatch({type:"showReissuelink",payload:true})
                         dispatch({type:"showitinerary",payload:true})
                         if(pax.length == cancelledseg.length){
                           dispatch({type:"enableConfirmFunctions",payload:false})
                           dispatch({type:"showReissuelink",payload:false})
                         }
                         else{
                          dispatch({type:"enableConfirmFunctions",payload:true})
                          dispatch({type:"showReissuelink",payload:true})
                         }
                       }

                     }
                     if(onhold_pax_arr.length > 1) {
                     dispatch({type:"onhold_tag",payload:true})
                     }
                     dispatch({ type: "hold_tkts", payload: hold_tkts })
                     dispatch({ type: "all_tkts", payload: all_tkts })
                     dispatch({ type: "split_tickets", payload: split_tickets })
                   }
                   //For Refund
                   if (paxData.length === 0) {
                     for (let val of data_.pax_det) {
                       for (let p of val) {
                         if (p != undefined && (p.sts === "Refund/booking" || p.sts === "Refund/cancel")) {
                           paxData.push(p.trv_id);
                           const req={
                            "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                            "id": p.trv_id
                           }
                           paxIndigoData.push(req);
                           tkno_refundable.push(p.tkt_no)
                         }
                       }
                     }
                   }
                   //For Edit Booking
                   if (bookingData[0].flow_type == 'split') {
                     for (let val of data_.pax_det[0]) {
                       if (val.sts === "Confirmed") {
                         dispatch({ type: "enableOnwardIti", payload: false })
                       }
                     }
                   }
                   if (bookingData[0].flow_type == 'split') {
                     dispatch({ type: "allTktslength", payload: all_tkts.length / 2 })
                     //onward pax
                     let owpax = data_.pax_det[0]
                     let rtpax = data_.pax_det[1]
                     dispatch({ type: "flt_supplier", payload: Number(owpax[0].supplier) })
                     dispatch({ type: "flt_supplier_return", payload: Number(rtpax[0].supplier) })
                     let owrefundpax = []
                     let cancelledowseg = []
                     for (let val of owpax) {

                      state.owpaxTNO.push(val.tkt_no)
                       if(val.stsid == "31" || val.stsid == "39" || val.stsid == "42" || val.stsid == "44"){
                        owrefundpax.push(val)
                      }
                      if(val.segst == "Cancelled"){
                        cancelledowseg.push(val)
                      }
                     }
                     if(owrefundpax.length == owpax.length){
                      dispatch({type:"showitinerary",payload: false})
                     }
                     else{
                      dispatch({type:"showitinerary",payload: true})
                    }
                     dispatch({type:"showReissuelink",payload:true})
                     if(cancelledowseg.length == owpax.length ){
                      dispatch({type:"enableConfirmFunctions",payload:false})
                      dispatch({type:"showReissuelink",payload:false})
                     }
                     else{
                      dispatch({type:"enableConfirmFunctions",payload:true})
                      dispatch({type:"showReissuelink",payload:true})
                     }

                     let rtrefundpax = []
                     let cancelledrtseg = []
                     for (let sval of rtpax) {
                        if(sval.stsid == "31" || sval.stsid == "39" || sval.stsid == "42" || sval.stsid == "44"){
                          rtrefundpax.push(sval)
                        }
                       state.rtpaxTNO.push(sval.tkt_no)
                       if(sval.segst == "Cancelled"){
                        cancelledrtseg.push(sval)
                      }
                     }
                     if(rtrefundpax.length == rtpax.length){
                      dispatch({type:"returnshowitinerary",payload: false})
                     }
                    else{
                       dispatch({type:"returnshowitinerary",payload: true})
                     }
                     dispatch({type:"showrtReissuelink",payload:true})
                     dispatch({type:"enablertConfirmFunctions",payload:true})

                     if(cancelledrtseg.length == rtpax.length ){
                      dispatch({type:"enablertConfirmFunctions",payload:false})
                      dispatch({type:"showrtReissuelink",payload:false})
                     }
                     else{
                      dispatch({type:"enablertConfirmFunctions",payload:true})
                      dispatch({type:"showrtReissuelink",payload:true})
                     }
                   } else {
                     dispatch({ type: "allTktslength", payload: all_tkts.length })
                   }

                 } //segment_details_section
                 else if (isNotNull(data_['seg_det'])) {

                   //below code collecting all src and destination airport codes
                   let values = [];
                   let airCodes = [];
                   seg_details = data_.seg_det
                   for (var i = 0; i < data_.seg_det.length; i++) {
                     airCodes.push(data_.seg_det[i]['tkt_airline']);
                     if (data_.seg_det[i].hasOwnProperty('seg_source') || data_.seg_det[i].hasOwnProperty('seg_destination'))
                       values.push(data_.seg_det[i]['seg_source']);
                     values.push(data_.seg_det[i]['seg_destination']);
                   };

                   dispatch({ type: "airCodesArray", payload: airCodes })
                   //Here find uniqu airport codes
                   unique_airport_codes = uniquearray(values);
                   newArray = uniquearray(airCodes);
                   var newArray = unique_airline_codes.toString();
                   dispatch({ type: 'aircodes', payload: newArray })
                   //Here load airport data based on airport codes
                   if (isNotNull(unique_airport_codes) && state.airport_data.length === 0) {
                     const req_code = {
                       searchCriteria: unique_airport_codes
                     }
                     getairportData(req_code).then((res) => {

                       dispatch({ type: 'airport_data', payload: res });
                     });
                   }


                   dispatch({ type: 'seg_det', payload: data_.seg_det })

                   let todaysDate = parseInt(moment(new Date()).format("YYYYMMDD"))
                   let dept_date = parseInt(DateUtils.prettyDate(data_.seg_det.depart_time, "YYYYMMDD"))
                   if (dept_date >= todaysDate) {
                     dispatch({ type: 'showRefund', payload: true })
                   }



                 } //Fare details
                 else if (isNotNull(data_['fare_det'])) {

                   //dispatch({ type: 'fare_det', payload: data_.fare_det })

                 }//Payemnt details
                 else if (isNotNull(data_['pay_det'])) {

                   dispatch({ type: 'pay_det', payload: data_.pay_det })

                 } else if (data_['void_det'] && isNotNull(data_['void_det'])) {
                   if (data_['void_det'].length > 0) {
                     let voidDetails = []
                     let cancelledVoid = []
                     let all = []
                     let void_details = []
                     let void_cancelled = []

                     //set of void fare details to show in UI
                     dispatch({ type: 'voidFareResponse', payload: data_['void_det'] })

                     //SPLIT PNR structure creation

                     for (let intidata of data_['void_det']) {

                       //constructing data with each passenger details with MTA inorder to prevent duplication
                       for (let data of intidata.passenger) {
                         if (data.type == "MTA") {

                           //each ticket perpspective data is looped to contruct the json
                           for (let eachPax of data.pax) {
                             let obj = {
                               "pn": eachPax.paxname,
                               "tkt": eachPax.tkt_no,
                               "tf": eachPax.totalfare,
                               "sts": eachPax.ticket_status,
                               "lp": eachPax.is_lead_pax_no,
                             }
                             void_details.push(obj)

                             //split pnr UI display
                             if (eachPax.split_pnr) {
                               let cobj = {
                                 "pn": eachPax.paxname, //pax name
                                 "lp": eachPax.is_lead_pax_no, //lead pax boolean
                                 "tkt": eachPax.tkt_no, //ticket number
                                 "sts": eachPax.ticket_status, //ticket status
                                 "type": eachPax.ptype, //pax type
                                 "pnr": eachPax.split_pnr, //pnr
                                 "bt": eachPax.ticket_status === "31" ? "Void" : "VoidCancel" //status
                               }
                               void_cancelled.push(cobj)
                             }
                             /***  saving void booking and void cancel data for multiple task ***/
                             if (eachPax.ticket_status === "31" || eachPax.ticket_status === "39") {
                               //if booking normal
                               if (bookingData[0].flow_type !== 'split') {
                                 state.butypehandler.push(eachPax.tkt_no)
                               } else {
                                 // if split
                                 if (state.owpaxTNO.includes(eachPax.tkt_no)) {
                                   state.owpaxVoid.push(eachPax.tkt_no)
                                 } else {
                                   state.rtpaxVoid.push(eachPax.tkt_no)
                                 }
                               }
                               all.push(eachPax.tkt_no)
                             }
                             if (eachPax.ticket_status === "31") { //success(Void)
                               voidDetails.push(eachPax.tkt_no)
                             } else if (eachPax.ticket_status === "39") { //voidcancel(Void/cancel)
                               cancelledVoid.push(eachPax.tkt_no)
                             }
                           }
                         }
                       }
                     }

                     //checking if not voided  and void cancelled  tickets remaining for validation
                     if (all_tkts.length === all.length) {
                       dispatch({ type: 'valid', payload: false })
                     }
                     dispatch({ type: 'ticketsVoided', payload: voidDetails }) //tickets voided data
                     dispatch({ type: 'ticketsVoidedCancelled', payload: cancelledVoid }) //tickets void cancelled
                     dispatch({ type: 'voidCancelTickets', payload: void_cancelled }) //tickets void cancelled with json
                     dispatch({ type: 'voidSaveResponse', payload: void_details }) //tickets voided  with json

                   }
                 } else if (data_['reIssue'] && data_['reIssue'] !== undefined && isArrayNotEmpty(data_['reIssue'])) {
                   reissue = true
                   dispatch({ type: 'reIssueResponse', payload: data_.reIssue })
                 }

                 //if all detials are empty we should show trip not found page
                 if (isArrayNotEmpty(data_.bkng_det) && isArrayNotEmpty(data_.pax_det) &&
                   isArrayNotEmpty(data_.seg_det) && isArrayNotEmpty(data_.fare_det) &&
                   isArrayNotEmpty(data_.pay_det)) {
                   dispatch({ type: 'no_trip', payload: true });

                 }



               }

               //Calling Refund Details

               if (bookingData.length!==0 && bookingData[0]!==undefined && bookingData[0].supplier==="0" && paxData !== undefined && paxData.length !== 0 && bookingData[0].flow_type === "normal" && bookingData[0].bktype != "IMPORT" && bookingData[0].bktype != "IMPORT/SPLIT" && bookingData[0].bktype != "IMPORT/REISSUE") {
                 const payload = {
                   "booking_id": booking_ref,
                   "tid": (bookingData[0] !== undefined && bookingData[0].tid !== undefined) ? bookingData[0].tid : "",
                   "trans_id": (bookingData[0] !== undefined && bookingData[0].trans_id !== undefined) ? bookingData[0].trans_id : "",
                   "trav_id": paxData, "tkt_no": tkno_refundable,
                   "region_type": state.bk_region_type,
                   "req_cur": state.usr_cur
                 }
                 showRefunds(payload).then((resp) => {
                   if (resp.suc && resp.data !== undefined) {
                     //constructing Splitpnr data for display--start
                     let pnr_data = [];
                     for (let r in resp.data.psngrs) {
                       let paxwise_data = resp.data.psngrs[r];
                       let p_type = paxwise_data.ptype;
                       for (let tkt in paxwise_data.tktnum) {
                         let tikt_data = paxwise_data.tktnum[tkt];
                         if (isNotNull(tikt_data.split_pnr)) {
                           let split_pnr_obj = {
                             "pn": tikt_data.pname, "lp": tikt_data.paxsno, "tkt": tikt_data.number, "type": p_type,
                             "pnr": tikt_data.split_pnr, "bt": tikt_data.st, "sts": tikt_data.stn
                           }
                           pnr_data.push(split_pnr_obj);
                         }
                         state.ticketsRefunded.push(tikt_data.number)
                       }
                     }
                     let refundpnr = []
                      for(let item of pnr_data){
                       if(item.sts == 44){
                        refundpnr.push(item)
                       }
                     };
                     if(refundpnr.length == pnr_data.length ){
                      dispatch({ type: 'showCancel', payload: false })
                     }
                     else{
                      dispatch({ type: 'showCancel', payload: true })
                     }
                     dispatch({ type: 'refund_split', payload: pnr_data })
                     //constructing Splitpnr data for display--End
                     dispatch({ type: "initialRefundResponse", payload: resp.data })

                     setRequest(resp.data);
                     dispatch({ type: 'refundAvail', payload: true })
                   }
                 })
               }
               //Calling INDIGO Refund details :
               if(state.indigoRefundList.length==0 && bookingData.length!==0 && bookingData[0].supplier==="4" && bookingData[0].flow_type==="normal"){
                if(state.trip_type!==""){
                  if(state.trip_type==="2"){
                    checkIndigoRefundAvail("O");
                    checkIndigoRefundAvail("R");
                  }else{
                    dispatch({ type: 'enableOWIndigo', payload: true })
                  }
                }
                const payload = {
                  "booking_id": booking_ref,
                  "tid": (bookingData[0] !== undefined && bookingData[0].tid !== undefined) ? bookingData[0].tid : "",
                  "trans_id": (bookingData[0] !== undefined && bookingData[0].trans_id !== undefined) ? bookingData[0].trans_id : "",
                  "trav_id": paxIndigoData,
                  "butype": context.logindata.butype
                }
                showIndigoRefunds(payload).then((resp) => {
                  if(resp.refundRetrieveRes !== undefined && resp.refundRetrieveRes.suc && resp.refundRetrieveRes.data!==undefined) {
                    dispatch({ type: 'indigoRefundList', payload:  resp.refundRetrieveRes.data.pnr_price})
                    dispatch({ type: 'indigoRefundRetList', payload:  resp.refundRetrieveRes.data.pnr_price})
                    setRequest(resp.refundRetrieveRes.data);

                  }
                })
              }else if(bookingData.length!==0 && bookingData[0].supplier==="4" && bookingData[0].flow_type==="split"){
                checkIndigoRefundAvail("O");
                //Calling for Onward Indigo Refund
               if(state.indigoRefundList.length==0){
                let paxListOne=[];
                let tid1="";
                for (let val of state.pax_det) {
                  for (let p of val) {
                    if (p != undefined && p.journey_type==="O" && p.sts === "Refund/cancel"){
                      tid1=p.tid;
                      const req={
                        "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                        "id": p.trv_id
                       }
                      paxListOne.push(req);
                    }
                  }
                }
                const payload1 = {
                  "booking_id": booking_ref,
                  "tid": tid1,
                  "trans_id": (bookingData[0] !== undefined && bookingData[0].trans_id !== undefined) ? bookingData[0].trans_id : "",
                  "trav_id": paxListOne,
                  "butype": context.logindata.butype
                }
                if(tid1!=="" && paxListOne.length!==0){
                  showIndigoRefunds(payload1).then((resp) => {
                    if(resp.refundRetrieveRes !== undefined && resp.refundRetrieveRes.suc && resp.refundRetrieveRes.data!==undefined) {
                      dispatch({ type: 'indigoRefundList', payload:  resp.refundRetrieveRes.data.pnr_price})
                      setRequest(resp.refundRetrieveRes.data);
                    }
                  })
                }
               }
               checkIndigoRefundAvail("R");
               //Calling for Return Indigo Refund
               if(state.indigoRefundRetList.length==0){
                let paxListTwo=[];
                let tid2="";
                for (let val of state.pax_det) {
                  for (let p of val) {
                    if (p != undefined && p.journey_type==="R" && p.sts === "Refund/cancel"){
                      tid2=p.tid;
                      const req={
                        "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                        "id": p.trv_id
                       }
                      paxListTwo.push(req);
                    }
                  }
                }
                const payload2 = {
                  "booking_id": booking_ref,
                  "tid": tid2,
                  "trans_id": (bookingData[0] !== undefined && bookingData[0].trans_id !== undefined) ? bookingData[0].trans_id : "",
                  "trav_id": paxListTwo,
                  "butype": context.logindata.butype
                }
                if(tid2!=="" && paxListTwo.length!==0){
                showIndigoRefunds(payload2).then((resp) => {
                  if(resp.refundRetrieveRes !== undefined && resp.refundRetrieveRes.suc && resp.refundRetrieveRes.data!==undefined) {
                    dispatch({ type: 'indigoRefundRetList', payload:  resp.refundRetrieveRes.data.pnr_price})
                    setRequest(resp.refundRetrieveRes.data);
                  }
                })
              }
              }
              }

               if (seg_details.length > 0) {
                 retriveFareDetails(booking_ref).then((resp) => {
                   if (resp.suc) {
                     dispatch({ type: 'fare_det', payload: resp.faredetails })
                     if (ft == "normal" && seg_details.length > 0) {
                       let respected_fare = []
                       for (let val of seg_details) {
                         respected_fare = resp.faredetails.filter((obj) => obj.tid == val.tid) //normal response
                       }
                       //check for split response
                       if (ft == "normal" && reissue_split) {
                         // all_tkts
                         let split_arr = []
                         let norm_arr = []
                         for (let val in respected_fare) {
                           let each_pax = respected_fare[val]
                           for (let pax in each_pax.pasngrs) {
                             let data = each_pax.pasngrs[pax]
                             if(hold_tkts_split.length > 0 || hold_tkts.length > 0) {
                              data.pax_split = data.pax.filter(s => hold_tkts_split.includes(s.paxname))
                              data.pax = data.pax.filter(s => hold_tkts.includes(s.paxname))
                             } else {
                              data.pax_split = data.pax.filter(s => split_tickets.includes(s.tkt))
                              data.pax = data.pax.filter(s => all_tkts.includes(s.tkt))
                             }


                           }
                         }
                         dispatch({ type: "fare_det_normal", payload: respected_fare })
                       } else {
                         dispatch({ type: "fare_det_normal", payload: respected_fare })
                       }

                     }
                   }
                 })
               }

               let reissued_tag = false
               //reissue split function  call
               if (reissue_split && bookingData.length > 0) {
                reissued_tag = true
                 reissueFunctionCall(bookingData)
               }
              //fetch unticketed in pnrs
              if((bookingData.length!==0 && bookingData[0] !== undefined && (bookingData[0].bktype === 'IMPORT'|| bookingData[0].bktype === 'IMPORT/REISSUE' || bookingData[0].bktype === 'IMPORT/SPLIT'))) {

                               if(pnrs.length > 0) {
                                if (isNotNull(pnrs) && pnrs.length > 0) {
                                  let pnrarr = []
                                  for(let val of pnrs) {
                                    if(val) {
                                      pnrarr.push(val)
                                    }
                                  }
                                  let rq_json  = {
                                    "pnrs" : pnrarr
                                  }
                                  pnrSearch(rq_json).then((resp) => {
                                      if (resp.suc) {
                                          dispatch({type:"unticketedTag",payload:true})
                                          dispatch({ type: 'pnr_Response', payload: resp })
                                          // props.pnrResponse(resp)
                                      }
                                  })
                              }
                               }
                              }
              if(bookingData.length!==0){
                fetchHistoryData(bookingData[0].booking_id).then((response) => {
                  if (response.length > 0) {
                    dispatch({ type: 'reIssueResponse', payload: response })
                  }
                })
              }
             });
           }
         })
       }
     }
   }, [context.logindata]);

   

  useEffect(() => {
    AttCommonSerivce.getTdsDetails({region_type:request.region_type,product:"Flight"}).then(response => {
      if (response.data.suc && response.data.res.length !== 0){
        setTdsPrecentagePlb(response.data.res[0].plb)
      }
    })
  }, [])

   
  useEffect(()=>{
    if(context.logindata.cid!==0 && context.logindata.cid!==undefined && context.logindata.region_type==="India"){
      AttCommonSerivce.getFetchGSTDetails({cid:context.logindata.cid}).then(response => {
        if (response.suc && response.res.length !== 0){
          dispatch({type: "gstObj", payload: response.data.data[0]})
          let gst = calculateVoidGST(state.attServiceFee, state.taServiceFee, response.data.data[0]);
        }
      })
    }
   }, [context.logindata.cid])

  //  Cal GST on Void
  const calculateVoidGST = (attServiceFee, taServiceFee, obj) => {
    let gst={}
    const req={
      "gst":{
        "type":obj.state_type,
        "sgst":obj.sgst,
        "igst":obj.igst,
        "cgst":obj.cgst,
        "gst_invoice":[],
      }
    }
    let total=0;
    gst = Utilities.applyGSTOnVoid(attServiceFee, taServiceFee, req.gst, "Flight");
    if(gst.gst_invoice.length!==0){
      for(let val of gst.gst_invoice){
        if(gst.type==="same"){
        if(total===0){
          total=Number(val.cgst_val)+Number(val.sgst_val);
        }else{
          total=total+Number(val.cgst_val)+Number(val.sgst_val);
        }
      }else{
        if(total===0){
          total=Number(val.igst_val);
        }else{
          total=total+Number(val.igst_val);
        }
      }
    }
  }
  gst.total_gst=total;
  dispatch({type: "gst_obj", payload: gst})
  return gst;
}


   const checkIndigoRefundAvail=(sector)=>{
    let check = false;
    for (let val of state.pax_det) {
      let list = val.filter((obj)=>obj.sts !== "Refund/cancel" && obj.journey_type===sector);
      if(list.length!==0){
        check=true;
        if(sector==="O"){
          dispatch({ type: 'enableOWIndigo', payload: true })
        }else{
          dispatch({ type: 'enableREIndigo', payload: true })
        }
        break;
      }
    }
    return check;
   }

   /**
    * @description : to fetch split reissue data id resissuesplit tag in summary gets true
    * @author: Azamuddin
    * @serviceBy : rambabu
    * @date : 10-06-2021
    */

   const reissueFunctionCall = (data) => {
     // getretrievereissuesplit
     //req -> booking_id
     let seg_det_air_codes = []
     retrievereissuesplit(data[0].booking_id).then((response) => {
       if (response.length > 0) {
         for (let i in response) {
           let eachTag = response[i]
           for (let j in eachTag) {
             let initResp = eachTag[j]
             for (let each in initResp) {
               let itn = initResp[each]
               if(itn.seg_det && itn.seg_det.length > 0) {
                 for(let val of itn.seg_det) {
                   seg_det_air_codes.push(val.seg_destination)
                   seg_det_air_codes.push(val.seg_source)
                 }
               }
               if (itn.void_det && itn.void_det.length > 0) {
                 let void_cancelled = []
                 // for (let intidata of itn.void_det) {
                 let intidata = itn.void_det[0]
                 //constructing data with each passenger details with MTA inorder to prevent duplication
                 for (let data of intidata.passenger) {
                   if (data.type == "MTA") {

                     //each ticket perpspective data is looped to contruct the json
                     for (let eachPax of data.pax) {
                       //split pnr UI display
                       if (eachPax.split_pnr) {
                         let cobj = {
                           "pn": eachPax.paxname, //pax name
                           "lp": eachPax.is_lead_pax_no, //lead pax boolean
                           "tkt": eachPax.tkt_no, //ticket number
                           "sts": eachPax.ticket_status, //ticket status
                           "type": eachPax.ptype, //pax type
                           "pnr": eachPax.split_pnr, //pnr
                           "bt": eachPax.ticket_status === "31" ? "Void" : "VoidCancel" //status
                         }
                         void_cancelled.push(cobj)
                       }

                     }
                     /***  saving void booking and void cancel data for multiple task ***/
                     if (void_cancelled.length > 0) {
                       initResp.push({ void_det_split: void_cancelled })
                     }
                   }
                 }
               }
               if (itn.refund_det && itn.refund_det.length > 0) {
                 let pnr_data = [];
                 let resp = itn.refund_det[0]
                 for (let r in resp.psngrs) {
                   let paxwise_data = resp.psngrs[r];
                   let p_type = paxwise_data.ptype;
                   for (let tkt in paxwise_data.tktnum) {
                     let tikt_data = paxwise_data.tktnum[tkt];
                     if (isNotNull(tikt_data.split_pnr)) {
                       let split_pnr_obj = {
                         "pn": tikt_data.pname, "lp": tikt_data.paxsno, "tkt": tikt_data.number, "type": p_type,
                         "pnr": tikt_data.split_pnr, "bt": tikt_data.st, "sts": tikt_data.stn
                       }
                       pnr_data.push(split_pnr_obj);
                     }
                   }
                 }
                 if (pnr_data.length > 0) {
                   initResp.push({ refund_det_split: pnr_data })
                 }
               }
             }
           }
         }
         dispatch({ type: "reissue_split_response", payload: response })
         if(seg_det_air_codes.length > 0) {
          if (isNotNull(seg_det_air_codes)) {
            let unique_airport_codes = uniqueJson(seg_det_air_codes)
            const req_code = {
              searchCriteria: unique_airport_codes
            }
            getairportData(req_code).then((res) => {

              // state.airport_data.concat(res)
              let arr = state.airport_data.concat(res)
              dispatch({type:"airport_data",payload:arr})
            });
          }
         }
       }

     })
   }
   useEffect(()=>{
    if(state.reissue_split_response.length !== 0){
      if(state.bkng_det[0].flow_type == "normal"){
     let reissueResponse = state.reissue_split_response[0]
    let allCancelled= []
    for(let val of reissueResponse){
        let cancelledPax = val[1].pax_det[0].filter(paxx=>paxx.split_pnr == null  && paxx.segst == "Cancelled")
          if(cancelledPax.length !== 0){
            allCancelled.push(reissueResponse.indexOf(val))
          }
        }
        dispatch({ type: 'cancelledPax', payload: allCancelled })
    }
    else{
      let owreissueResponse = state.reissue_split_response[0]
      let rtreissueResponse = state.reissue_split_response[1]
    let owallCancelled= []
    for(let val of owreissueResponse){
        let owcancelledPax = val[1].pax_det[0].filter(paxx=>(paxx.split_pnr == null ))
        let owcancelledSeg= owcancelledPax.filter(paxx=>(paxx.segst == "Cancelled"))
          if(owcancelledPax.length !== owcancelledSeg.length){
            owallCancelled.push(owreissueResponse.indexOf(val))
          }
        }
        dispatch({ type: 'cancelledPax', payload: owallCancelled })
    if(state.reissue_split_response[1] !== undefined){
    let rtallCancelled= []
    for(let val of rtreissueResponse){
        let rtcancelledPax = val[1].pax_det[0].filter(paxx=>paxx.split_pnr == null )
        let rtcancelledSeg= rtcancelledPax.filter(paxx=>(paxx.segst == "Cancelled"))
          if(rtcancelledPax.length !== rtcancelledSeg.length){
            rtallCancelled.push(rtreissueResponse.indexOf(val))
          }
        }
        dispatch({ type: 'rtcancelledPax', payload: rtallCancelled })
    }
  }

  }
   }, [state.reissue_split_response])
   //Edit Booking Partials
   useEffect(() => {
     if (state.pax_det.length !== 0 && state.bkng_det[0].booking_status == "36") {
       if (state.flow_type == 'split') {
         let tidAry = state.bkng_det[0].tid.split("/");
         for (let pax of state.pax_det) {
           for (let val of pax) {
             if (val.tid === tidAry[0] && val.sts === "Ticketing failed") {
               // dispatch({ type: 'enableEdit', payload: true })
               dispatch({ type: 'onwardAvail', payload: true })
               break;
             } else if (val.tid === tidAry[1] && val.sts === "Ticketing failed") {
               dispatch({ type: 'enableReturnEdit', payload: true })
               dispatch({ type: 'returnAvail', payload: true })
               break;
             }
           }
         }
       }
     }
   }, [state.pax_det])


   function uniquearray(array) {
     var uniqueArray = [];

     // Loop through array values
     for (let i = 0; i < array.length; i++) {
       if (uniqueArray.indexOf(array[i]) === -1) {
         uniqueArray.push(array[i]);
       }
     }
     return uniqueArray;
   }

   /**
  * @description : check if the void is accessible or not
  * @author : azamuddin
  * @date: 30-Nov-2020
  * --conditions to success the void
  *  -todays booking
  *  -confirmed booking
  */
   //check if the void is accessible or not
   const handleVoidBooking = (typ) => () => {
     dispatch({ type: 'enableVoidCancel', payload: false })
     if (state.bkng_det[0].booking_status == "23") {
       //ticket number date
       let tkt_time = ''
       for(let eachArr of state.pax_det) {
         for(let eachPax of eachArr) {
          tkt_time = eachPax.tkt_time
         }
       }
       tkt_time = tkt_time ? tkt_time : moment(state.bkng_det[0].booking_date,"YYYY-MM-DD").format("DD-MMM-YYYY")
       let bk_date = parseInt(moment(tkt_time,"DD-MMM-YYYY").format("YYYYMMDD")) //booking date
       let todaysDate = parseInt(moment(state.todaysDate,"YYYY-MM-DD HH:mm:ss").format("YYYYMMDD"))
       if (bk_date === todaysDate) {
         if (state.valid) {
           if(typ == "Onward") {
             dispatch({type:"onward",payload:true})
           executeScrolltop();
           } else {
            dispatch({type:"onward",payload:false})
           }
           let status = true
           //   for(let sal of state.pax_det) {

           //     for(let val of sal) {
           //      if(val && val.is_refundable !== "Non-Refundable") {
           //       status = true
           //     }
           //   }
           // }
           //if true then display the checkbox for the tickets
           if (status) {
             dispatch({type:"type_selected",payload:typ != "Onward" ? "Return" : "Onward"})
             dispatch({ type: 'enableVoidBooking', payload: true })
             dispatch({ type: "voidenable", payload: false })
             dispatch({ type: "refundenable", payload: false })
             dispatch({ type: "splitissue", payload: false })
             dispatch({ type: "splitCancel", payload: false })
             dispatch({ type: 'enableReissue', payload: false })
           } else {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
             dispatch({ type: 'notiVarient', payload: 'danger' })
             hidemessage()
           }
         } else {
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'This Booking has already been Voided' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
       }
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       if (state.bkng_det[0].booking_status == "31") {
         dispatch({ type: 'notiMessage', payload: 'This Booking has already been Voided' })
       } else {
         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
       }

       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   const handlesptpnrBooking=()=>{
      dispatch({type:"showOnholdcontinue",payload: false})
      dispatch({type:"showOnholdowcontinue",payload: false})
      dispatch({type:"checkboxFocus",payload:!state.checkboxFocus})
    dispatch({type:"flow_type_fbf",payload:"O"})
   if(state.hold_tkts.length > 1){
    dispatch({ type: 'enablesptpnr', payload: true })
    }
   }

   /**
  * @description : tickets which are to be voided
  * @author : azamuddin
  * @date: 30-Nov-2020
  * --tickets selective from pax details ticket data
  */
   const voidBookReq = (e) => {
     setVoidTicket(e)
   }

   /**
  * @description : void popup
  * @author : azamuddin
  * @date: 30-Nov-2020
  * --two services - (getCountryCodes , voidFareFetch)
  */
   const handlevShow = (e) => {
     dispatch({ type: "loader", payload: true })
     let req = []
     let resp = []
     //get country codes by passing city codes
     if (state.pax_det.length > 0) {
       req.push(state.pax_det[0][0].source)
       req.push(state.pax_det[0][0].destination)

     }
     getCountryCodes(req).then((res) => {
       if (res.data.length > 0) {
         let arr = [] //country codes
         for (let val of res.data) {
           arr.push(val.country_code)
         }
         if (voidTicket.length > 0) {
           dispatch({ type: "voidSelectedLength", payload: voidTicket.length })
           let rq_Json = {
             "tktno": voidTicket,
             "status": 23,
             "sa": state.pax_det[0][0].source,
             "da": state.pax_det[0][0].destination,
             "cid": state.bkng_det[0].company_id,
             "ftype": "v",
             "ttype": parseInt(state.seg_det[0].trvel_type),
             "cls": state.seg_det[0].cabin_class,
             "supplier": 0,
             "sc_code": arr[0],
             "dc_code": arr[1],
             "tf": DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD"),
             "tt": DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD"),
             "bf": new Date(),
             "bt": new Date(),
             "ac": state.seg_det[0].tkt_airline,
             "pcid": context.logindata.pcid,
             "butype": context.logindata.butype,
             "bid": context.logindata.bid,
             "paxCount": state.allTktslength,
             "region_type": state.bk_region_type,
             "req_cur": state.usr_cur
           }

           voidFareFetch(rq_Json).then((res) => {
             let statusArr = []
             if (res.success && res.data && res.data !== "Data not avaliable") {
               setShow(true)
               dispatch({ type: "loader", payload: false })
               if (res.data.booking_id) {
                 dispatch({ type: 'voidBid', payload: res.data.booking_id }) //bookingid
               }
               if (res.data.offid) {
                 dispatch({ type: 'voidOfcId', payload: res.data.offid }) //ofcid
               }
               if (res.data.fk_trans_id) {
                 dispatch({ type: 'voidtransId', payload: res.data.fk_trans_id }) //transId
               }
               if (res.data.pnr) {
                 dispatch({ type: 'voidPNR', payload: res.data.pnr }) //PNR
               }
               if (res.data.tid) {
                 dispatch({ type: 'voidTid', payload: res.data.tid }) //tid
               }
               if (res.data.tkts) {
                 let arr = res.data.tkts
                 for (var i = 0; i < res.data.tkts.length; i++) {
                   statusArr.push(arr[i].tkt)

                   const voidServiceFeeMta = arr[i].sf && arr[i].sf.attsf ? arr[i].sf.attsf : 0;
                   const voidServiceFeeTA = arr[i].sf && arr[i].sf.tasf ? arr[i].sf.tasf : 0;
                   const voidServiceFeeSTA = arr[i].sf && arr[i].sf.stasf ? arr[i].sf.stasf : 0;         

                   if(context.logindata.region_type==="India"){
                    let gst = calculateVoidGST(arr[i].sf && arr[i].sf.attsf ? arr[i].sf.attsf : 0, arr[i].sf && arr[i].sf.tasf ? arr[i].sf.tasf : 0, state.gstObj);
                  
                    arr[i].gst = gst;
                   }

                   const mtaSF = arr[i].bsf && arr[i].bsf.attsf ? arr[i].bsf.attsf : 0;
                   const taSF = arr[i].bsf && arr[i].bsf.tasf ? arr[i].bsf.tasf : 0;
                   const staSF = arr[i].bsf && arr[i].bsf.stasf ? arr[i].bsf.stasf : 0;
        
                  // gst on void 
                   const attServiceFee = mtaSF;
                   const taServiceFee = taSF;
                   const staServiceFee = staSF;

                  // gst on void 
                  let attvoidFee;
                   if(context.logindata.region_type==="India"){
                      attvoidFee = voidServiceFeeMta + arr[i].gst.gst_invoice[0].cgst_val + arr[i].gst.gst_invoice[0].sgst_val + arr[i].gst.gst_invoice[0].igst_val;
                    } else {
                      attvoidFee = voidServiceFeeMta
                    }
                  let tavoidFee;
                   if(context.logindata.region_type==="India"){
                      tavoidFee = voidServiceFeeTA + arr[i].gst.gst_invoice[1].cgst_val + arr[i].gst.gst_invoice[1].sgst_val + arr[i].gst.gst_invoice[1].igst_val;
                    } else {
                      tavoidFee = voidServiceFeeTA
                    }
                  // const attvoidFee = voidServiceFeeMta + arr[i].gst.total_gst;
                  // const tavoidFee = voidServiceFeeTA + arr[i].gst.total_gst;
                  const stavoidFee = voidServiceFeeSTA;


                   const mtaPLB = arr[i].mtaplb ? arr[i].mtaplb : 0;
                   const taPLB = arr[i].taplb ? arr[i].taplb : 0;

                   const plb = mtaPLB + taPLB;

                   /* arr[i].tf = (arr[i].tf +serviceFee) - plb;
                   arr[i].ra = parseInt(arr[i].tf-voidServiceFee-serviceFee);
                   arr[i].sf = voidServiceFee+serviceFee; */


                   arr[i].attsf = voidServiceFeeMta;
                   arr[i].tasf = voidServiceFeeTA;
                   arr[i].stasf = voidServiceFeeSTA;

                  //  addition of GST, TDS, DISCOUNT, and PLB
                  // const taxesVoidTotalFare = state.fare_det_normal[0].pasngrs[0].pax[0].sales.gst + state.fare_det_normal[0].pasngrs[1].pax[0].sales.gst + state.fare_det_normal[0].pasngrs[0].pax[0].sales.tds_amt

                   if (context.logindata.butype == "sta_user") {
                     arr[i].tf = (arr[i].tf + taSF + staSF - plb);
                     arr[i].sf = stavoidFee + taSF + staSF;
                     arr[i].ra = parseInt(arr[i].tf - stavoidFee - (taSF + staSF));
                   } else {
                     arr[i].tf = (arr[i].tf + attServiceFee + taServiceFee - plb);
                    //  arr[i].tf = (arr[i].tf + attServiceFee + taServiceFee - plb);
                     arr[i].sf = attvoidFee + attServiceFee;
                     arr[i].cf = tavoidFee + taServiceFee;
                     arr[i].ra = parseInt(arr[i].tf - (attvoidFee + tavoidFee) - (attServiceFee + taServiceFee));
                   }

                 }
                 //void pnr handle
                 if (state.flow_type !== 'split') {
                   let voidedArray = state.butypehandler.length > 0 ? uniqueJson(state.butypehandler) : []
                   let computedArr = uniqueJson(statusArr.concat(voidedArray))
                   if (state.allTktslength === computedArr.length) {

                     dispatch({ type: 'btype', payload: "full" })
                   }
                 } else {
                   //split handle btype
                   let seg = 'onward'
                   //checking here if the void selected is onward or from return
                   for (let val of statusArr) {
                     if (state.owpaxTNO.includes(val)) {
                       seg = 'onward'

                       // return;
                     } else if (state.rtpaxTNO.includes(val)) {
                       seg = 'return'
                       // return;
                     }
                   }

                   if (seg === 'onward') {
                     let voidedArray = state.owpaxVoid.length > 0 ? uniqueJson(state.owpaxVoid) : []
                     let computedArr = uniqueJson(statusArr.concat(voidedArray))
                     let paxdata = uniqueJson(state.owpaxTNO)
                     if (paxdata.length === computedArr.length) {
                       dispatch({ type: 'btype', payload: "full" })
                     }
                   } else {
                     let voidedArray = state.rtpaxVoid.length > 0 ? uniqueJson(state.rtpaxVoid) : []
                     let computedArr = uniqueJson(statusArr.concat(voidedArray))
                     let paxdata = uniqueJson(state.rtpaxTNO)
                     if (paxdata.length === computedArr.length) {
                       dispatch({ type: 'btype', payload: "full" })
                     }
                   }
                 }





                 dispatch({ type: 'tkts', payload: arr }) //tickets selected
                 let sum = []
                 for (let val of arr) {
                   sum.push(val.ra)
                 }
                 //total refund amount
                 let rfAmount = sum.reduce(function (a, b) {
                   return a + b;
                 }, 0);
                 dispatch({ type: 'rfAmount', payload: rfAmount })
                 handleShow()
               }
             } else {
               dispatch({ type: 'notiMessageShow', payload: true })
               dispatch({ type: 'notiMessage', payload: 'Data not Available' })
               dispatch({ type: 'notiVarient', payload: 'danger' })
               dispatch({ type: "loader", payload: false })
               hidemessage()
             }
           })
         } else {
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Select atleast one ticket to proceed futher' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           dispatch({ type: "loader", payload: false })
           hidemessage();
         }
       }
     })
   }

   //to get unique by passing argument
   function getUnique(arr, comp) {

    // store the comparison  values in array
    const unique = arr.map(e => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

   function uniqueJson(jsonarray) {
     let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
     const mySetSerialized = new Set(myArrSerialized);
     const myUniqueArrSerialized = [...mySetSerialized];
     const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));

     return myUniqueArr;
   }
   /**
    * @description : to save the void to the tickets selected
    * @author : azamuddin
    * @serviceFrom : Chandra
    * @date : 30-11-2020
    */
   const setVoid = (e) => {
     dispatch({ type: 'voidLoading', payload: true })
     let arrofpaxvoided = uniqueJson(state.butypehandler)
     /**handling journey type */
     let journey_type = ""
     if (state.flow_type == "normal") {
       if (state.bkng_det[0].travel_type == 1) {
         journey_type = "O"
       } else if (state.bkng_det[0].travel_type == 2) {
         journey_type = "O/R"
       } else {
         journey_type = "O"
       }
     } else {
       for (let val of state.tkts) {
         if (state.owpaxTNO.includes(val.tkt)) {
           journey_type = "O"
         } else if (state.rtpaxTNO.includes(val.tkt)) {
           journey_type = "R"
         }
       }
     }
     let re_Json = {
       "tkts": state.tkts, //tickets
      //  "office_id": journey_type = "O" ? ow_ofcid : rt_ofcid,
      //  "office_id": "DOHQR28IH",
       "office_id": state.voidOfcId, //ofcId
       "tid": state.voidTid, //tid
       "pnr": state.voidPNR, //PNR
       "fk_trans_id": state.voidtransId, //transId
       "booking_id": state.voidBid, //bookingId
       "btype": state.btype,
       "tkt_user_type": context.logindata.utype,
       "tkt_user_id": context.logindata.uid,
       "journey_type": journey_type,
       "cus_code": state.cus_code,
       "region_type": state.bk_region_type,
       "req_cur": state.usr_cur,
       "tds" : {    
        "tds_pct": tds_percentage_plb,
        "tds_amt": state.fare_det_normal[0].pasngrs[0].pax[0].sales.tds_amt    
        }
     }
     saveVoid(re_Json).then((response) => {
       if (response.data.suc) {
         let res = response.data.data
         if (res && response.data.data.tkts && response.data.data.tkts.length > 0) {
           let arr = []
           let ticketsVoided = []
           for (let val of res.tkts) {

             if (val.tktst == '31') {
               arr.push(val)
               ticketsVoided.push(val.tkt)
             }
           }
           dispatch({ type: 'ticketsVoided', payload: ticketsVoided })
           dispatch({ type: 'voidSaveResponse', payload: arr })
           dispatch({ type: 'enableVoidBooking', payload: false })
           handleClose()
           if (ticketsVoided.length > 0) {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Successfull' })
             dispatch({ type: 'notiVarient', payload: 'success' });
             hidemessage()
             return window.location = window.location.pathname

           } else {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Unsuccessfull' })
             dispatch({ type: 'notiVarient', payload: 'danger' });
             hidemessage()
             setIsLoadingMore(false);
             dispatch({ type: 'voidLoading', payload: false })
             dispatch({ type: 'enableVoidBooking', payload: false })
             dispatch({ type: 'enableVoidCancel', payload: false })
             dispatch({ type: 'disableCheckbox', payload: true })
           }
         }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Voiding Unsuccessfull' })
         dispatch({ type: 'notiVarient', payload: 'danger' });
         hidemessage()
         dispatch({ type: 'voidLoading', payload: false })
         setIsLoadingMore(false);
         dispatch({ type: 'enableVoidBooking', payload: false })
         dispatch({ type: 'enableVoidCancel', payload: false })
         dispatch({ type: 'disableCheckbox', payload: true })
         handleClose()
       }
     })
     dispatch({ type: 'disableCheckbox', payload: false })

   }
   //Calling Ticket request for ONHOLD
   const fireTicketing = (type) => {
     //if(validateImport()){
     dispatch({ type: "isLoading", payload: true })
     // setIsLoadingMore(true);
     var request = "";
     let pnr = "";
     var gdsPNR = "";
     let adt = 0
     let inf = 0
     let chd = 0
     let splitted_pax = []
     if (state.bkng_det[0].flow_type === "normal") {
       request = { "tid": state.seg_det[0].tid, "pnr": state.seg_det[0].normal_gds_pnr, "sty": "normal", "office_id":state.bkng_det[0].office_id }
       for(let val of state.pax_det[0]) {
         if(val.split_pnr !== null) {
           splitted_pax.push(val.paxsno)
         }
       }
     } else {
       pnr = state.seg_det[0].normal_gds_pnr.split(",");
       gdsPNR = pnr[0] + "/" + pnr[1];
       request = { "tid": state.seg_det[0].tid, "pnr": gdsPNR, "sty": "split","splitted_pax": splitted_pax }
     }
     if(type==="Hold"){
      request.page="summary";
      request.booking_id = state.bkng_det[0].booking_id;
     }
     request.region_type=state.bk_region_type;
     request.req_cur = state.usr_cur;
     callTicketing(request).then((resp) => {
       if (resp.status === "success" && resp.data !== undefined) {
         resp.data.flowType = "ONHOLD";
         resp.data.bktype = "ONHOLD";
         resp.data.bookingId = state.bkng_det[0].booking_id;
         resp.data.bkref = state.bkng_det[0].booking_id;
         if (state.bkng_det[0].flow_type === "normal") {
           resp.data.pnr = state.seg_det[0].normal_gds_pnr;
         } else {
           resp.data.pnr = gdsPNR;
         }
         resp.data.region_type=state.bk_region_type;
         resp.data.req_cur=state.usr_cur;
         dispatch({ type: "isLoading", payload: false })
         history.push({
           pathname: '/ezytrip/bookings/flight/itinerary',
           state: {
             req: resp.data
           }
         });
       }else if(resp.status === "offline"){
         dispatch({ type: "showOffline_msg", payload: true })
         dispatch({ type: "isLoading", payload: false })
       }else{
        setShow(true)
        dispatch({ type: "isLoading", payload: false })
       }
       dispatch({ type: 'onholdResp', payload: resp.data })
       dispatch({ type: "isLoading", payload: false })
       // setIsLoadingMore(false);
     })
   }

   //Calling Discard booking for ONHOLD
   const discrdBooking = (type, clickedOn) => () => {
     const request = {
       booking_id: state.bkng_det[0].booking_id,
       "region_type":state.bk_region_type,
       "req_cur":state.usr_cur,
     }
     callDiscardBook(request).then((resp) => {
       if (resp.success) {
         setShow(false);
         dispatch({ type: 'incompletpopup', payload: false });
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Booking Discarded successfully' })
         dispatch({ type: 'notiVarient', payload: 'success' });
         hidemessage()
         if (state.onholdResp) {
           var response = state.onholdResp;
           response.flowType = type;
           response.bktype = type;
           response.bookingId = state.bkng_det[0].booking_id;
           response.bkref = state.bkng_det[0].booking_id;
           if (response.tt !== 3) {
             response = convertOnholdObj(response);
           } else {
             response = convertMultiCityObj(response);
           }
           response.clstyp = state.onholdResp.origns[0].srcorigns[0].cl;
           response.ns = false;
           response.paxChanged = false;
           response.region_type=state.bk_region_type;
           response.req_cur=state.usr_cur;
           var userData = localStorage.getItem(USER_AUTH_DATA);
           var resObj = JSON.parse(userData);
           resObj.cid = state.bkng_det[0].company_id;
           resObj.butype = "ta_user";
           updateContext(resObj);
           if (clickedOn == 'close' && state.clickedOn !== 'yes') {
             state.clickedOn = 'close'
             history.push({
               pathname: '/ezytrip/dashboard/bookingList',
             });
           }
           else if (state.bkng_det[0].flow_type === "normal" && clickedOn == 'yes' && state.clickedOn !== 'close') {
             //  dispatch({type:'clickedOn' ,payload: "yes"})
             state.clickedOn = 'yes'
             history.push({
               pathname: '/ezytrip/bookings/flight/results',
               state: {
                 req: response
               }
             });
           } else if (clickedOn == 'yes' && state.clickedOn !== 'close') {
             state.clickedOn = 'yes'
             history.push({
               pathname: '/ezytrip/bookings/flight/flightbyflight',
               state: {
                 req: response
               }
             });
           }
         }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Booking Discard failed.Please try again later' })
         dispatch({ type: 'notiVarient', payload: 'danger' });
         hidemessage()
       }
     })
   }

   const updateContext = (resObj) => {
     localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
     if (resObj) {
       setContext({ logindata: resObj });
     }
   }

   const validateImport = () => {
     if (context.logindata.utype === "att_user") {
       if (context.logindata.bid === 0) {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
         return false;
       }
       if (context.logindata.cid === 0) {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Please select Company' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
         return false;
       }
     }
     return true;
   }

   const getCityName = (code) => {
     if (state.onholdResp.mappings) {
       for (let val of state.onholdResp.mappings) {
         if (val.airportCode === code) {
           return val.cityName;
         }
       }
     }
   }

   const getCityReissueName = (code, mappings) => {
     for (let val of mappings) {
       if (val.airportCode === code) {
         return val.cityName;
       }
     }
   }

   //Converting Onward and Rountrip obj
   const convertOnholdObj = (response) => {
     var src = state.onholdResp.origns[0].srcorigns[0].seg[0].da;
     var dest = state.onholdResp.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
     if (response.mappings) {
       for (let val of response.mappings) {
         if (val.airportCode === src) {
           response.srcCity = val.cityName;
           response.src = [val];
         }
         if (val.airportCode === dest) {
           response.destCity = val.cityName;
           response.dest = [val];
         }
       }
     }
     response.srcCode = src;
     response.destCode = dest;
     response.dd = DateUtils.prettyDate(state.onholdResp.origns[0].srcorigns[0].seg[0].dd, "YYYY-MM-DD");
     response.ad = DateUtils.prettyDate(state.onholdResp.origns[0].srcorigns[0].seg.slice(-1)[0].ad, "YYYY-MM-DD");
     response.srcDate = DateUtils.prettyDate(state.onholdResp.origns[0].srcorigns[0].seg[0].dd, "ddd, DD MMM YYYY");
     response.destDate = DateUtils.prettyDate(state.onholdResp.origns[0].srcorigns[0].seg.slice(-1)[0].ad, "ddd, DD MMM YYYY");
     return response;
   }

   //Converting Multicity
   const convertMultiCityObj = (response) => {
     var search = new Object();
     if (state.onholdResp.origns[0].srcorigns) {
       for (let src of state.onholdResp.origns[0].srcorigns) {
         if (src.ref !== undefined) {
           if (src.ref === 1) {
             search.dep1 = src.seg[0].da;
             search.arr1 = src.seg.slice(-1)[0].ar;
             search.dd1 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptOne = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty1 = getCityName(src.seg[0].da);
             search.acty1 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataOne = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataOne = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           } else if (src.ref === 2) {
             search.dep2 = src.seg[0].da;
             search.arr2 = src.seg.slice(-1)[0].ar;
             search.dd2 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptTwo = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty2 = getCityName(src.seg[0].da);
             search.acty2 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataTwo = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataTwo = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           } else if (src.ref === 3) {
             search.dep3 = src.seg[0].da;
             search.arr3 = src.seg.slice(-1)[0].ar;
             search.dd3 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptThree = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty3 = getCityName(src.seg[0].da);
             search.acty3 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataThree = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataThree = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           } else if (src.ref === 4) {
             search.dep4 = src.seg[0].da;
             search.arr4 = src.seg.slice(-1)[0].ar;
             search.dd4 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptFour = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty4 = getCityName(src.seg[0].da);
             search.acty4 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataFour = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataFour = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           } else if (src.ref === 5) {
             search.dep5 = src.seg[0].da;
             search.arr5 = src.seg.slice(-1)[0].ar;
             search.dd5 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptFive = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty5 = getCityName(src.seg[0].da);
             search.acty5 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataFive = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataFive = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           } else if (src.ref === 6) {
             search.dep6 = src.seg[0].da;
             search.arr6 = src.seg.slice(-1)[0].ar;
             search.dd6 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
             search.deptSix = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
             search.dcty6 = getCityName(src.seg[0].da);
             search.acty6 = getCityName(src.seg.slice(-1)[0].ar);
             search.orignDataSix = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
             search.destDataSix = state.onholdResp.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
           }
         }
       }
     }
     search.cls = state.onholdResp.origns[0].srcorigns[0].cl;
     search.adt = state.onholdResp.adt;
     search.chd = state.onholdResp.chd;
     search.inf = state.onholdResp.inf;
     response.search = search
     return response;
   }

   //Converting Multicity
   const convertMultiCityReissueObj = (response) => {
     var search = new Object();
     if (response) {
       for (let src of response.seg) {
         if (src.ref !== undefined) {
           if (src.ref == 1) {
             search.dep1 = src.da;
             search.arr1 = src.ar;
             search.dd1 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptOne = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty1 = getCityReissueName(src.da, response.mappings);
             search.acty1 = getCityReissueName(src.ar, response.mappings);
             search.orignDataOne = response.mappings.filter((obj) => obj.airportCode === src.da);
             search.destDataOne = response.mappings.filter((obj) => obj.airportCode === src.ar);
           } else if (src.ref == 2) {
             search.dep2 = src.da;
             search.arr2 = src.ar;
             search.dd2 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptTwo = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty2 = getCityReissueName(src.da, response.mappings);
             search.acty2 = getCityReissueName(src.ar, response.mappings);
             search.orignDataTwo = response.mappings.filter((obj) => obj.airportCode === src.da);
             search.destDataTwo = response.mappings.filter((obj) => obj.airportCode === src.ar);
           } else if (src.ref == 3) {
             search.dep3 = src.da;
             search.arr3 = src.ar;
             search.dd3 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptThree = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty3 = getCityReissueName(src.da, response.mappings);
             search.acty3 = getCityReissueName(src.ar, response.mappings);
             search.orignDataThree = response.mappings.filter((obj) => obj.airportCode === src.da);
             search.destDataThree = response.mappings.filter((obj) => obj.airportCode === src.ar);
           } else if (src.ref == 4) {
             search.dep4 = src.da;
             search.arr4 = src.ar;
             search.dd4 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptFour = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty4 = getCityReissueName(src.da, response.mappings);
             search.acty4 = getCityReissueName(src.ar, response.mappings);
             search.orignDataFour = response.mappings.filter((obj) => obj.airportCode === src.da);
             search.destDataFour = response.mappings.filter((obj) => obj.airportCode === src.ar);
           } else if (src.ref == 5) {
             search.dep5 = src.da;
             search.arr5 = src.seg.ar;
             search.dd5 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptFive = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty5 = getCityReissueName(src.da, response.mappings);
             search.acty5 = getCityReissueName(src.ar, response.mappings);
             search.orignDataFive = response.mappings.filter((obj) => obj.airportCode === src.da);
             search.destDataFive = response.mappings.filter((obj) => obj.airportCode === src.ar);
           } else if (src.ref == 6) {
             search.dep6 = src.da;
             search.arr6 = src.ar;
             search.dd6 = DateUtils.prettyDate(src.dd, "YYYY-MM-DD");
             search.deptSix = DateUtils.prettyDate(src.dd, "ddd, DD MMM YYYY");
             search.dcty6 = getCityReissueName(src.da, response.mappings);
             search.acty6 = getCityReissueName(src.ar, response.mappings);
             search.orignDataSix = response.mappings.filter((obj) => obj.airportCode === src.da)
             search.destDataSix = response.mappings.filter((obj) => obj.airportCode === src.ar);
           }
         }
       }
     }
     search.cls = setClsType(response.cl);
     search.adt = response.adt;
     search.chd = response.chd;
     search.inf = response.inf;
     response.search = search
     return response;
   }

   const setClsType = (cls) => {
     var cl = "Economy";
     if (cls === "E") {
       cl = "Economy"
     } else if (cls === "B") {
       cl = "Business"
     } else if (cls === "F") {
       cl = "First"
     }
     return cl;
   }




   /**
    * @author: Rambabu
    * @description: Fire incomplete flow request
    * @date:28-11-2020
    */
   const incompleteService = (data) => () => {
     dispatch({ type: 'isLoading', payload: true });
     const request = {
       tid: state.bkng_det[0].tid,
       sty: state.bkng_det[0].flow_type,
       tf: state.bkng_det[0].sup_total_amt,
       region_type: state.bk_region_type,
       req_cur : state.usr_cur
     }


     fireIncompleteService(request).then((resp) => {
       dispatch({ type: 'onholdResp', payload: resp.data });
       if (resp.status === 'success') {
         //redirect to review page
         resp.data.flowType = "INCOMPLETE";
         resp.data.bktype = "INCOMPLETE";
         resp.data.bookingId = state.bkng_det[0].booking_id;
         resp.data.bkref = state.bkng_det[0].booking_id;
         resp.data.farechange = resp.data.isFareChange;//time being hard coded(true for testing) need to do dynamic
         resp.data.region_type=state.bk_region_type;
         resp.data.req_cur=state.usr_cur;
         history.push({
           pathname: '/ezytrip/bookings/flight/itinerary',
           state: {
             req: resp.data
           }
         });


       } else {

         //need to show popup(if response fails)
         dispatch({ type: 'incompletpopup', payload: true });
         dispatch({ type: 'isLoading', payload: false });
       }
     })
   }


   /**
  * @description : void cancel select
  * @author : azamuddin
  * @date : 07-12-2020
  */
   const handleVoidCancel = (typ)=> () => {
     dispatch({ type: 'enableVoidBooking', payload: false })

     if (state.bkng_det[0].booking_status == "23") {
      let tkt_time = ''
      for(let eachArr of state.pax_det) {
        for(let eachPax of eachArr) {
         tkt_time = eachPax.tkt_time
        }
      }
      tkt_time = tkt_time ? tkt_time : moment(state.bkng_det[0].booking_date,"YYYY-MM-DD").format("DD-MMM-YYYY")
      let bk_date = parseInt(moment(tkt_time,"DD-MMM-YYYY").format("YYYYMMDD")) //booking date
       let todaysDate = parseInt(moment(state.todaysDate,"YYYY-MM-DD HH:mm:ss").format("YYYYMMDD"))
       if (bk_date === todaysDate) {
         if (state.valid) {
           if(typ == "Onward") {
            dispatch({type:"onward",payload:true})
           executeScrolltop();
           } else {
            dispatch({type:"onward",payload:false})

           }
           let status = true
           //   for(let sal of state.pax_det) {

           //     for(let val of sal) {
           //      if(val && val.is_refundable !== "Non-Refundable") {
           //       status = true
           //     }
           //   }
           // }
           //if true then display the checkbox for the tickets
           if (status) {
            dispatch({type:"type_selected",payload:typ !== "Onward" ? "Return" : "Onward"})
             dispatch({ type: 'enableVoidCancel', payload: true })
             dispatch({ type: "voidenable", payload: false })
             dispatch({ type: "refundenable", payload: false })
             dispatch({ type: "splitissue", payload: false })
             dispatch({ type: "splitCancel", payload: false })
             dispatch({ type: 'enableReissue', payload: false })
           } else {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
             dispatch({ type: 'notiVarient', payload: 'danger' })
             hidemessage()
           }

           //if true then display the checkbox for the tickets

         } else {
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'This Booking has already been Void/Cancelled' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
       }
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       if (state.bkng_det[0].booking_status == "39") {
         dispatch({ type: 'notiMessage', payload: 'This Booking has already been Void/Cancelled' })
       } else {

         dispatch({ type: 'notiMessage', payload: 'Ticket is not eligible for Void' })
       }
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   /**
    * @description : to remove the checkboxes during void Selection
    * @author : Azamuddin
    * @date: 07-12-2020
    */
   const cancelCheckBoxes = () => {
     dispatch({ type: 'enableVoidBooking', payload: false })
     dispatch({ type: 'enableVoidCancel', payload: false })
     dispatch({ type: 'disableCheckbox', payload: true })
   }

   /**
   * @description : to set void cancellation during void Selection in DB
   * @author : Azamuddin
   * @serviceFrom : Chandra
   * @date: 07-12-2020
   */
   const setCancelVoid = (e) => {
     dispatch({ type: 'voidLoading', payload: true })
     let arrofpaxvoided = uniqueJson(state.butypehandler)
     let journey_type = ""
     if (state.flow_type == "normal") {
       if (state.bkng_det[0].travel_type == 1) {
         journey_type = "O"
       } else if (state.bkng_det[0].travel_type == 2) {
         journey_type = "O/R"
       } else {
         journey_type = "O"
       }
     } else {
       for (let val of state.tkts) {
         if (state.owpaxTNO.includes(val.tkt)) {
           journey_type = "O"
         } else if (state.rtpaxTNO.includes(val.tkt)) {
           journey_type = "R"
         }
       }
     }
     let re_Json = {
       "tkts": state.tkts, //tickets
      //  "office_id": journey_type = "O" ? ow_ofcid : rt_ofcid,
      //  "office_id": ow_ofcid,
       "office_id": state.voidOfcId, //ofcId
       "tid": state.voidTid, //tid
       "pnr": state.voidPNR, //PNR
       "fk_trans_id": state.voidtransId, //transId
       "booking_id": state.voidBid, //bookingId
       "btype": state.btype,
       "tkt_user_type": context.logindata.utype,
       "tkt_user_id": context.logindata.uid,
       "journey_type": journey_type, //journey type
       "cus_code": state.cus_code,
       "region_type": state.bk_region_type, //journey type
       "req_cur": state.usr_cur,
       "tds" : {    
        "tds_pct": tds_percentage_plb,
        "tds_amt": state.fare_det_normal[0].pasngrs[0].pax[0].sales.tds_amt    
        }

     }
     cancelVoid(re_Json).then((response) => {
       if (response.data.suc) {
         let res = response.data.data
         if (res && response.data.data.tkts && response.data.data.tkts.length > 0) {
           let arr = []
           let voidedTicket = []
           for (let val of res.tkts) {
             if (val.tktst == '39') {
               arr.push(val)
               voidedTicket.push(val.tkt)
             }
           }
           dispatch({ type: 'ticketsVoidedCancelled', payload: voidedTicket })

           dispatch({ type: 'voidSaveResponse', payload: arr })
           dispatch({ type: 'enableVoidCancel', payload: false })
           handleClose()
           if (voidedTicket.length > 0) {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Successfull' })
             dispatch({ type: 'notiVarient', payload: 'success' });
             hidemessage()
             return window.location = window.location.pathname

           } else {
             dispatch({ type: 'notiMessageShow', payload: true })
             dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Unsuccessfull' })
             dispatch({ type: 'notiVarient', payload: 'danger' });
             hidemessage()
             setIsLoadingMore(false);
             dispatch({ type: 'enableVoidBooking', payload: false })
             dispatch({ type: 'enableVoidCancel', payload: false })
             dispatch({ type: 'disableCheckbox', payload: true })
           }
         }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: ' Selected Ticket(s) Cancellation Unsuccessfull' })
         dispatch({ type: 'notiVarient', payload: 'danger' });
         hidemessage()
         setIsLoadingMore(false);
         cancelCheckBoxes()
         handleClose()
       }
     })
     dispatch({ type: 'disableCheckbox', payload: false })
   }

   const handleCancel = (e) => {
     return history.push("/ezytrip/dashboard/bookingList");
   }
   const pnr_response = (response) => {
     dispatch({ type: 'pnr_Response', payload: response })
   }

   //Call refund service to get the details
   const reissueTickts = (data) => {
     if (data && data.length !== 0) {
       setIsLoadingMore(true);
       let adts = [];
       let chds = [];
       let infs = [];
       let finalPax = [];
       let tktsData = Utilities.getUniqData(data);
       if (tktsData !== undefined && tktsData.length !== 0) {
         adts = tktsData.filter(x => x.ty === "ADT");
         for (let adt of adts) {
           finalPax.push(adt);
         }
         chds = tktsData.filter(x => x.ty === "CHD");
         if (chds !== undefined && chds !== 0) {
           for (let chd of chds) {
             finalPax.push(chd);
           }
         }
         infs = tktsData.filter(x => x.ty === "INF");
         if (infs !== undefined && infs !== 0) {
           for (let inf of infs) {
             finalPax.push(inf);
           }
         }
       }
       let tid = ""; //trans_id
       let pnr = ""; //pnr
       let cls = ""; //class
       // ATT - 5929
       let ofcid = ""; //office id
       if (state.bkng_det[0].flow_type === "normal") {
         tid = state.bkng_det[0].tid;
         pnr = state.seg_det[0].normal_gds_pnr;
         cls = state.seg_det[0].cabin_class;
         // ATT - 5929
         ofcid = state.bkng_det[0].office_id
       } else {
         let segList = state.seg_det.filter(x => x.journey_type === "O"); //fetch seg details wrt to journey type
         if (segList[0].tid.includes("/")) {
           let tidAry = segList[0].tid.split("/");
           tid = tidAry[0];
           cls = segList[0].cabin_class;
         } else {
           if (segList.length !== 0) {
             if (segList !== undefined && segList[0] !== undefined) {
               tid = segList[0].tid;
               cls = segList[0].cabin_class;
             }
           }
         }

         // ATT - 5929
         if (segList[0].office_id.includes("/")) {
          let ofcidAry = segList[0].office_id.split("/");
          ofcid = ofcidAry[0];
          cls = segList[0].cabin_class;
        } else {
          if (segList.length !== 0) {
            if (segList !== undefined && segList[0] !== undefined) {
              ofcid = segList[0].office_id;
              cls = segList[0].cabin_class;
            }
          }
        }
         //if normal without reissued if multiple pnr then split ","
         if (segList[0].normal_gds_pnr !== "" && segList[0].normal_gds_pnr.includes(",")) {
           let prnrAry = segList[0].normal_gds_pnr.split(",");
           pnr = prnrAry[0];
           cls = segList[0].cabin_class;
         } else {
           //if reissued then we receive only one pnr latest
           if (segList.length !== 0) {
             if (segList !== undefined && segList[0] !== undefined) {
               pnr = segList[0].normal_gds_pnr !== "" ? segList[0].normal_gds_pnr : segList[0].split_gds_pnr;
               cls = segList[0].cabin_class;
             }
           }
         }
       }
       const payload = {
         "tid": tid,
         "pnr": pnr,
        //  "ofcid": "DOHQR2718",
         "office_id": ofcid,
        //  "ofcid": state.bkng_det[0].office_id,
         "bktype": "DIRECT/REISSUE",
         "butype": context.logindata.butype,
         "tkts": finalPax,
         "cid": state.bkng_det[0].company_id,
         "adt": adts.length,
         "chd": chds.length,
         "inf": infs.length,
         "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
         "tt": parseInt(state.seg_det[0].trvel_type),
         "cur": state.usr_cur,
         "sty": state.bkng_det[0].flow_type,
         "bref": state.bkng_det[0].booking_id,
         "journey_type": "Onward",
         "region_type": state.bk_region_type,
         "req_cur": state.usr_cur
       }
       getReissueDetails(payload).then((resp) => {
         if (resp.suc && resp.data !== undefined && resp.data.change) {
           resp.data.tt = 3;
           resp.data = convertMultiCityReissueObj(resp.data);
           resp.data.clstyp = "Economy";
           resp.data.ns = false;
           history.push({
             pathname: '/ezytrip/dashboard/reissue',
             state: {
               req: resp.data,
               bref: state.bkng_det[0].booking_id,
               cls: cls,
               region_type:state.bk_region_type,
               req_cur:state.usr_cur
             }
           });
         } else {
           setIsLoadingMore(false);
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       })
     } else {
       setIsLoadingMore(false);
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }


   //Call Reissue Return service
   const reissueReturnTickts = (data) => {
     if (data && data.length !== 0) {
       setIsLoadingMore(true);
       let adts = [];
       let chds = [];
       let infs = [];
       let finalPax = [];
       let tktsData = Utilities.getUniqData(data);
       if (tktsData !== undefined && tktsData.length !== 0) {
         adts = tktsData.filter(x => x.ty === "ADT");
         for (let adt of adts) {
           finalPax.push(adt);
         }
         chds = tktsData.filter(x => x.ty === "CHD");
         if (chds !== undefined && chds !== 0) {
           for (let chd of chds) {
             finalPax.push(chd);
           }
         }
         infs = tktsData.filter(x => x.ty === "INF");
         if (infs !== undefined && infs !== 0) {
           for (let inf of infs) {
             finalPax.push(inf);
           }
         }
       }
       let tid = ""; //tid
       let pnr = ""; //pnr
       let cls = ""; //class
       // ATT - 5929
       let ofcid = "";
       let segList = state.seg_det.filter(x => x.journey_type === "R"); //check for segdet with journey_type : "R"(Return)
       //check tid is is from normal or different bktype
       if (segList[0].tid.includes("/")) {

         let tidAry = segList[0].tid.split("/");
         tid = tidAry[1]; //tid assigning
         cls = segList[0].cabin_class; //class
       } else {
         if (segList.length !== 0) {

           if (segList !== undefined && segList[0] !== undefined) {
             tid = segList[0].tid;
             cls = segList[0].cabin_class;
           }
         }
       }

       //ATT - 5929
       if (segList[0].office_id.includes("/")) {
        let ofcidAry = segList[0].office_id.split("/");
        ofcid = ofcidAry[1]; //tid assigning
        cls = segList[0].cabin_class; //class
      } else {
        if (segList.length !== 0) {

          if (segList !== undefined && segList[0] !== undefined) {
            ofcid = segList[0].office_id;
            cls = segList[0].cabin_class;
          }
        }
      }
       //check pnr is is from normal or different bktype
       if (segList[0].normal_gds_pnr !== "" && segList[0].normal_gds_pnr.includes(",")) {
         let prnrAry = segList[0].normal_gds_pnr.split(",");
         pnr = prnrAry[1];
         cls = segList[0].cabin_class;
       } else {
         if (segList.length !== 0) {
           if (segList !== undefined && segList[0] !== undefined) {
             pnr = segList[0].normal_gds_pnr !== "" ? segList[0].normal_gds_pnr : segList[0].split_gds_pnr;
             cls = segList[0].cabin_class;
           }
         }
       }
       const payload = {
         "tid": tid,
         "pnr": pnr,
        //  "ofcid": "DOHQR2718",
         "office_id": ofcid,
         "butype": context.logindata.butype,
         "tkts": finalPax,
         "cid": state.bkng_det[0].company_id,
         "adt": adts.length,
         "chd": chds.length,
         "inf": infs.length,
         "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
         "tt": parseInt(state.seg_det[0].trvel_type),
         "cur": state.usr_cur,
         "sty": state.bkng_det[0].flow_type,
         "bref": state.bkng_det[0].booking_id,
         "journey_type": "Return",
         "region_type": state.bk_region_type,
         "req_cur": state.usr_cur
       }
       getReissueDetails(payload).then((resp) => {
         if (resp.suc && resp.data !== undefined && resp.data.change) {
           resp.data.tt = 3;
           resp.data = convertMultiCityReissueObj(resp.data);
           resp.data.clstyp = "Economy";
           resp.data.ns = false;
           resp.data.region_type=state.bk_region_type;
           resp.data.req_cur=state.usr_cur;
           history.push({
             pathname: '/ezytrip/dashboard/reissue',
             state: {
               req: resp.data,
               bref: state.bkng_det[0].booking_id,
               cls: cls
             }
           });
         } else {
           setIsLoadingMore(false);
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       })
     } else {
       setIsLoadingMore(false);
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   //Call refund service to get the details
   const continueRefund = (data) => {
     // dispatch({type:"isLoading",payload:true})
     let journeyType = "";
     let valid = false
     if (data[1] == "ATC Refund") {
       if (state.atc_refundable) {
         valid = true
       }
     } else {
      if(data[0].length!==0){
        valid = true
      }
     }
     if (valid) {
       let btype = 'partial'
       setIsLoadingMore(true);
       let req = []
       if (state.pax_det.length > 0) {
         req.push(state.pax_det[0][0].source)
         req.push(state.pax_det[0][0].destination)
       }
       let arr = [] //country codes
       getCountryCodes(req).then((res) => {
         if (res.data !== undefined && res.data.length > 0) {
           for (let val of res.data) {
             arr.push(val.country_code)
           }
         }
         var tid = "";
         var ofcid = "";
         let pnr = '';
         if (state.bkng_det[0] !== undefined && state.bkng_det[0].flow_type === "normal") {
           tid = state.bkng_det[0].tid;
           ofcid = state.bkng_det[0].office_id;
           pnr = state.seg_det[0].normal_gds_pnr;
           if (state.bkng_det[0].travel_type == 1) {
             journeyType = "O"
           } else if (state.bkng_det[0].travel_type == 2) {
             journeyType = "O/R"
           } else {
             journeyType = "O"
           }
         } else {
           var type = data[2];
           let tidAry = state.bkng_det[0].tid.split("/");
           let ofcidAry = state.bkng_det[0].office_id.split("/");
           let pnrArray = state.seg_det[0].normal_gds_pnr.split(",");
           if (type === "Onward") {
             let tid_c = ""
             let ofcid_c = ""
             let pnr_c = ""
             if (state.bktype.toUpperCase() !== "REISSUE") {
               tid_c = tidAry[0]
               ofcid_c = ofcidAry[0]
               pnr_c = pnrArray[0]
             } else {
               //if reissue latest pnr and tid for reqpective itenary is need so selecteing form seg_det
               tid_c = state.seg_det[0].tid
               ofcid_c = state.seg_det[0].office_id
               pnr_c = state.seg_det[0].normal_gds_pnr
             }
             tid = tid_c;
             ofcid = ofcid_c;
             pnr = pnr_c;
             journeyType = "O";
           } else {
             //return
             let tid_c = ""
             let ofcid_c = ""
             let pnr_c = ""
             if (state.bktype.toUpperCase() !== "REISSUE") {
               tid_c = tidAry[1]
               ofcid_c = ofcidAry[1]
               pnr_c = pnrArray[1]
             } else {
               //if reissue latest pnr and tid for reqpective itenary is need so selecteing form seg_det
               tid_c = state.seg_det[1].tid
               ofcid_c = state.seg_det[1].office_id
               pnr_c = state.seg_det[1].normal_gds_pnr
             }
             tid = tid_c;
             ofcid = ofcid_c;
             pnr = pnr_c;
             journeyType = "R";
           }
         }
         if (state.bkng_det[0].flow_type === "normal") {
           let circulated_arr = data[0].concat(state.ticketsRefunded)
           if (uniqueJson(circulated_arr).length === state.allTktslength) {
             btype = 'full'
           }
           // journeyType="O";
         } else {
           /****split *****/
           let type = ""
           let refundedTkts = []
           //checking if selected ticket is onward or return
           for (let val of data[0]) {
             if (state.owpaxTNO.includes(val)) {
               type = "onward"
               journeyType = "O";
             } else {
               type = "return"
               journeyType = "R";
             }
           }
           if (type == "onward") {
             refundedTkts = data[0].concat(state.ticketsRefunded[0])
           } else {
             refundedTkts = data[0].concat(state.ticketsRefunded[1])
           }
           if (uniqueJson(refundedTkts).length === state.allTktslength) {
             btype = 'full'
           }
         }
         //Tickets Info
         let paxInfo=[];
         if(data[1]==="Manual Refund"){
          for(let tkt of data[0]){
            if(state.bkng_det[0] !== undefined && state.bkng_det[0].flow_type === "normal"){
              let paxList =state.pax_det[0].filter((pax)=>pax.tkt_no===tkt);
              paxInfo.push(paxList[0]);
            }else{
              if(journeyType==="O"){
                let paxList =state.pax_det[0].filter((pax)=>pax.tkt_no===tkt);
                paxInfo.push(paxList[0]);
              }else if(journeyType==="R"){
                if(state.pax_det[1]!==undefined){
                  let paxList =state.pax_det[1].filter((pax)=>pax.tkt_no===tkt);
                  paxInfo.push(paxList[0]);
                }
              }
            }
           }
         }
         const payload = {
           "tid": tid,
           "rtype": data[1],
           "btype": btype,
           "cancelPnr": state.isCancelBook,
           "pnr": pnr,
          //  "ofcid": "DOHQR2718",
           "office_id": ofcid,
           "butype": context.logindata.butype,
           "tkts": data[0],
           "tkts_info":paxInfo,
           "bref": state.bkng_det[0].booking_id,
           "ac": state.aircodes,
           "dep": state.pax_det[0][0].source,
           "arr": state.pax_det[0][0].destination,
           "cid": state.bkng_det[0].company_id,
           "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
           "tt": parseInt(state.seg_det[0].trvel_type),
           "cls": state.seg_det[0].cabin_class,
           "supplier": "Amadeus",
           "sc_code": arr[0],
           "dc_code": arr[1],
           "dd": DateUtils.prettyDate(state.seg_det[0].depart_time, "YYYY-MM-DD"),
           "ad": DateUtils.prettyDate(state.seg_det[0].arrival_time, "YYYY-MM-DD"),
           "bf": new Date(),
           "bt": new Date(),
           "rbd": "",
           "ftype": "Refund",
           "bid": context.logindata.bid,
           "paxCount": state.allTktslength,
           "cus_code": state.cus_code,
           "bktype" : state.bkng_det[0].bktype,
           "region_type": state.bk_region_type, //journey type
           "req_cur": state.usr_cur

         }
         getRefundDetails(payload, data[1]).then((resp) => {
           if (resp.refundPricingRes.suc && resp.refundPricingRes.data !== undefined && resp.refundPricingRes.data.psngrs.length !== 0) {
             setIsLoadingMore(false);
             history.push({
               pathname: data[1]!=="Manual Refund"?'/ezytrip/dashboard/refund':'/ezytrip/dashboard/manual-refund',
               state: {
                 req: resp.refundPricingRes,
                 bref: state.bkng_det[0].booking_id,
                 type: data[2],
                 journey_type: journeyType,
                 region_type:state.bk_region_type,
                 req_cur:state.usr_cur
               }
             });
           } else {
             setIsLoadingMore(false);
             // dispatch({type:"isLoading",payload:false})
             dispatch({ type: 'notiMessageShow', payload: true })
             if (data[1] === "ATC Refund") {
               if (context.logindata.roles.includes('N_A_T_R')) {
                 dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later or Proceed with Non ATC Refund' })
               } else {
                 dispatch({ type: 'notiMessage', payload: 'Non-ATC refund is not supported by airlines, Please connect the Ezytrip support team.' })
               }
             } else {
               if (context.logindata.roles.includes('A_T_R')) {
                 dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later or Proceed with ATC Refund' })
               } else {
                 dispatch({ type: 'notiMessage', payload: 'ATC refund is not supported by airlines, Please connect the Ezytrip support team.' })
               }
             }
             dispatch({ type: 'notiVarient', payload: 'danger' })
             hidemessage()
           }
         })
       })
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       if(data[0].length!==0){
        if (context.logindata.roles.includes('N_A_T_R')) {
          dispatch({ type: 'notiMessage', payload: 'Something went wrong. Please try again later or Proceed with Non ATC Refund' })
        } else {
          dispatch({ type: 'notiMessage', payload: 'Non-ATC refund is not supported by airlines, Please connect the Ezytrip support team.' })
        }
       }else{
        dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Refund' })
       }
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   //Handle refund
   const handleEdiyBooking = () => {
     dispatch({ type: 'enableEdit', payload: true })
     dispatch({ type: 'enableReturnEdit', payload: false })
     dispatch({ type: 'enableEditBooking', payload: true })

   }
   const handleEditReturn = () => {
     dispatch({ type: 'enableReturnEdit', payload: true })
     dispatch({ type: 'enableEdit', payload: false })
     dispatch({ type: 'enableEditBooking', payload: true })
   }

   //Handle refund
   const handleRefundTicket = (typ) => () => {
     let req_json = {
       "ac": uniqueJson(state.airCodesArray)
     }
     atc_refundairlines(req_json).then((resp) => {
       if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Refund") {
         dispatch({ type: "atc_refundable", payload: true })
       } else {
         dispatch({ type: "atc_refundable", payload: false })
       }
     })
     dispatch({type:"type_selected",payload:typ != "Onward" ? "Return" : "Onward"})
     dispatch({ type: 'enableRefund', payload: true })
     dispatch({ type: "voidenable", payload: false })
     dispatch({ type: "refundenable", payload: false })
     dispatch({ type: "splitissue", payload: false })
     dispatch({ type: "splitCancel", payload: false })
     dispatch({ type: 'enableReissue', payload: false })
     dispatch({ type: 'enableVoidBooking', payload: false })
    dispatch({ type: 'enableVoidCancel', payload: false })
     if(typ == "Onward") {
      dispatch({type:"onward",payload:true})
     executeScrolltop();
     } else {
      dispatch({type:"onward",payload:false})
     }
   }


   //Handle Reissue
   const handleReissueTicket = (typ) => () => {
     const payload = {
       "ac": (state.seg_det.length !== 0) ? [state.seg_det[0].markting_airline] : []
     }
     //dispatch({type:'enableReissue',payload: true})
     getATCAirline(payload).then((resp) => {
       if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Gurantee") {
        dispatch({type:"type_selected",payload:typ != "Onward" ? "Return" : "Onward"})
         dispatch({ type: 'enableReissue', payload: true })
         dispatch({ type: 'enableRefund', payload: false })
         dispatch({ type: "voidenable", payload: false })
         dispatch({ type: "refundenable", payload: false })
         dispatch({ type: "splitissue", payload: false })
         dispatch({ type: "splitCancel", payload: false })
         dispatch({ type: "hideReturn", payload: true })
         dispatch({ type: 'atcmsg', payload: "Guarantee" })
         dispatch({ type: 'enableVoidBooking', payload: false })
        dispatch({ type: 'enableVoidCancel', payload: false })
        if(typ == "Onward") {
          dispatch({type:"onward",payload:true})
         executeScrolltop();
        } else {
          dispatch({type:"onward",payload:false})
        }
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Non-ATC reissue is not supported by airlines, Please connect the Ezytrip support team.' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
       }
     })
   }

   const upateOnwardReissue = () => {
     dispatch({ type: 'enableReissue', payload: false })
   }

   //Handle Reissue
   const handleReissueTicketReturn = () => {
     const payload = {
       "ac": (state.seg_det.length !== 0) ? [state.seg_det[0].markting_airline] : []
     }
     //dispatch({type:'enableReissue',payload: true})
     getATCAirline(payload).then((resp) => {
       if (resp.suc && resp.data.length > 0 && resp.data[0].atc == "Gurantee") {
         dispatch({ type: 'enableReissue', payload: true })
         dispatch({ type: 'enableRefund', payload: false })
         dispatch({ type: "voidenable", payload: false })
         dispatch({ type: "refundenable", payload: false })
         dispatch({ type: "splitissue", payload: false })
         dispatch({ type: "splitCancel", payload: false })
         dispatch({ type: 'atcmsg', payload: resp.data[0].atc })
         executeScrolltop();
       } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Non-ATC reissue is not supported by airlines, Please connect the Ezytrip support team.' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
       }
     })
   }


   //Handle Booking Status
   const updateBstatus = (data) => {
     dispatch({ type: 'updateBstatus', payload: data })
   }


   const callEditBooking = () => {
     dispatch({ type: 'showEditpop', payload: true })
   }

   const cancelEdit = () => {
     return window.location = window.location.pathname;
   }

   const hidemessage = () => {
     setTimeout(function () {
       dispatch({ type: 'notiMessageShow', payload: false })
       dispatch({ type: 'notiMessage', payload: '' })
       dispatch({ type: 'notiVarient', payload: '' })
     }, 5000);
   }

   const validateGds = (type) => {
     if (type === "gds") {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please enter the GDS PNR.' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please enter the Airline PNR.' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     }

   }

   const cancelBookUpdate = (data) => {
     dispatch({ type: 'isCancelBook', payload: data })
   }

   const getSegmentNormalObj = (seg) => {
     const obj = {
       "segid": seg.segid,
       "gds": seg.normal_gds_pnr,
       "airline": seg.normal_airline_pnr
     }
     return obj;
   }

   const getSegmentSplitObj = (seg) => {
     const obj = {
       "segid": seg.segid,
       "gds": seg.split_gds_pnr,
       "airline": seg.split_airline_pnr
     }
     return obj;
   }

   const processEditBookingFlow = () => {
     if (state.enableEdit) {
       processEditBookingFlowOnward();
     } else if (state.enableEditBooking) {
       processEditBookingSplitReturn();
     }
   }

   const checkBookingStatus = () => {
     if (state.onwardAvail && state.returnAvail) {
       if (state.updateBstatus === "Partially Ticketed") {
         return true;
       } else {
         return false;
       }
     } else if ((!state.onwardAvail && state.returnAvail) || (state.onwardAvail && !state.returnAvail)) {
       if (state.updateBstatus === "Confirmed") {
         return true;
       } else {
         return false;
       }
     }
     return false;
   }

   //Handle Edit Booking flow
   const processEditBookingFlowOnward = () => {
     let segsList = [];
     let validate = true; var onwardTid = "";
     let value = false;
     //Checking Airline and GDS PNRS nad Ticket Numbers
     var data = JSON.parse(localStorage.getItem(EDIT_DATA));
     if(data!==null){
      for(let seg1 of state.seg_det){
      for(let seg2 of data){
       if(Number(seg1.segid) === Number(seg2.segid)){
        if(seg2.changed!==undefined && seg2.changed){
          seg1.changed=true;
          seg1.normal_airline_pnr=seg2.normal_airline_pnr;
          seg1.normal_gds_pnr=seg2.normal_gds_pnr;
          seg1.split_airline_pnr=seg2.split_airline_pnr;
          seg1.split_gds_pnr=seg2.split_gds_pnr;
        }
       }
      }
     }
     }
     if (state.bkng_det[0].flow_type === "normal") {
       if (state.updateBstatus === "Confirmed") {
         value = true;
       }
     } else {
       if (state.bkng_det[0].booking_status === "34") {
         if (state.updateBstatus === "Partially Ticketed") {
           value = true;
         }
       } else {
         value = checkBookingStatus();
       }
     }
     if (value) {
       if (state.seg_det.length !== 0) {
         for (let seg of state.seg_det) {
           if (state.bkng_det[0].flow_type === "normal") {
             if (seg.normal_airline_pnr !== undefined && seg.normal_airline_pnr !== "") {
               if (seg.normal_gds_pnr !== undefined && seg.normal_gds_pnr !== "") {
                 if (seg.changed !== undefined && seg.changed) {
                   segsList.push(getSegmentNormalObj(seg));
                 }
               } else {
                 validate = false;
                 validateGds("gds");
               }
             } else {
               validate = false;
               validateGds("airline");
             }
           } else {
             let tidArray = state.bkng_det[0].tid.split("/");
             onwardTid = tidArray[0];
             if (seg.tid === onwardTid) {
               if (seg.split_airline_pnr !== undefined && seg.split_airline_pnr !== "") {
                 if (seg.split_gds_pnr !== undefined && seg.split_gds_pnr !== "") {
                   if (seg.changed !== undefined && seg.changed) {
                     segsList.push(getSegmentSplitObj(seg));
                   }
                 } else {
                   validate = false;
                   validateGds();
                 }
               } else {
                 validate = false;
                 validateGds("airline");
               }
             }
           }
         }
       }
       let paxList = [];
       var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
       if (ticketsInfo!==null && ticketsInfo.length.length !== 0) {
         for (let pax of ticketsInfo) {
           for (let val of pax) {
             if (state.bkng_det[0].flow_type === "normal") {
               if (validatePax(val) && val.changed !== undefined && val.changed) {
                 const obj = {
                   "paxid": val.trv_op_id,
                   "tst": val.sts,
                   "tnum": val.tkt_no
                 }
                 paxList.push(obj);
               } else {
                 validate = false;
               }
             } else {
               if (val.tid === onwardTid) {
                 if (validatePax(val) && val.changed !== undefined && val.changed) {
                   const obj = {
                     "paxid": val.trv_op_id,
                     "tst": val.sts,
                     "tnum": val.tkt_no
                   }
                   paxList.push(obj);
                 } else {
                   validate = false;
                 }
               }
             }
           }
         }
       }
       var payload = "";
       if (validate) {
         if (state.bkng_det[0].flow_type === "normal") {
           payload = {
             "booking_id": state.bkng_det[0].booking_id,
             "bst": state.updateBstatus,
             "upby": context.logindata.uid,
             "region_type": state.bk_region_type,
             "req_cur": state.usr_cur,
             "bkngdetails": [{
               "tid": state.bkng_det[0].tid,
               "pax": paxList,
               "segs": segsList
             }]
           }
         } else if (state.bkng_det[0].flow_type === "split") {
           payload = {
             "booking_id": state.bkng_det[0].booking_id,
             "bst": state.updateBstatus,
             "upby": context.logindata.uid,
             "region_type": state.bk_region_type,
             "req_cur": state.usr_cur,
             "bkngdetails": [{
               "tid": onwardTid,
               "pax": paxList,
               "segs": segsList
             }]
           }
         }
         processEditBooking(payload).then((resp) => {
           dispatch({ type: 'showEditpop', payload: false })
           localStorage.removeItem(EDIT_DATA);
           localStorage.removeItem(EDIT_TICKET_DATA);
           if (resp.suc) {
             validateEditBooking(true);
           } else {
             validateEditBooking(false);
           }
         })
       }
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     }
   }

   //Handle Edit Booking flow
   const processEditBookingSplitReturn = () => {
     let segsList = [];
     let validate = true; var returnTid = "";
     let value = false;
     var data = JSON.parse(localStorage.getItem(EDIT_DATA));
     if(data!==null){
      for(let seg1 of state.seg_det){
      for(let seg2 of data){
       if(Number(seg1.segid) === Number(seg2.segid)){
        if(seg2.changed!==undefined && seg2.changed){
          seg1.changed=true;
          // seg1.normal_airline_pnr=seg2.normal_airline_pnr;
          // seg1.normal_gds_pnr=seg2.normal_gds_pnr;
          seg1.split_airline_pnr=seg2.split_airline_pnr;
          seg1.split_gds_pnr=seg2.split_gds_pnr;
        }
       }
      }
     }
     }
     if (state.bkng_det[0].booking_status === "34") {
       if (state.updateBstatus === "Partially Ticketed") {
         value = true;
       }
     } else {
       value = checkBookingStatus();
     }
     if (value) {
       let tidArray = state.bkng_det[0].tid.split("/");
       returnTid = tidArray[1];
       if (state.seg_det.length !== 0) {
         for (let seg of state.seg_det) {
           if (seg.tid === returnTid) {
             if (seg.split_airline_pnr !== undefined && seg.split_airline_pnr !== "") {
               if (seg.split_gds_pnr !== undefined && seg.split_gds_pnr !== "") {
                 if (seg.changed !== undefined && seg.changed) {
                   segsList.push(getSegmentSplitObj(seg));
                 }
               } else {
                 validate = false;
                 validateGds();
               }
             } else {
               validate = false;
               validateGds("airline");
             }
           }
         }
       }
       let paxList = [];
      var ticketsInfo = JSON.parse(localStorage.getItem(EDIT_TICKET_DATA));
       if (ticketsInfo!==null && ticketsInfo.length.length !== 0) {
         for (let pax of ticketsInfo) {
           for (let val of pax) {
             if (val.tid === returnTid && val.changed !== undefined && val.changed) {
               if (validatePax(val)) {
                 const obj = {
                   "paxid": val.trv_op_id,
                   "tst": val.sts,
                   "tnum": val.tkt_no
                 }
                 paxList.push(obj);
               } else {
                 validate = false;
               }
             }
           }
         }
       }

       if (validate) {
         const payload = {
           "booking_id": state.bkng_det[0].booking_id,
           "bst": state.updateBstatus,
           "upby": context.logindata.uid,
           "region_type": state.bk_region_type,
           "req_cur": state.usr_cur,
           "bkngdetails": [{
             "tid": returnTid,
             "pax": paxList,
             "segs": segsList
           }]
         }
         processEditBooking(payload).then((resp) => {
           dispatch({ type: 'showEditpop', payload: false })
           localStorage.removeItem(EDIT_DATA);
           localStorage.removeItem(EDIT_TICKET_DATA);
           if (resp.suc) {
             validateEditBooking(true);
           } else {
             validateEditBooking(false);
           }
         })
       }
     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please select proper Booking status' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     }
   }

   //Validate the Pax
   const validatePax = (pax) => {
     if (pax.tkt_no === undefined || pax.tkt_no === "") {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please Enter the Ticket numbers.' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
       return false
     } else if (pax.sts !== "Confirmed") {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please select proper Ticketing status' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
       return false
     } else if (pax.tkt_no !== undefined && pax.tkt_no.length !== 14) {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please provide valid Ticket number and include hyfen after ticketing Code' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
       return false
     }
     return true;
   }

   const validateEditBooking = (suc) => {
     if (suc) {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Booking details are updated Successfully' })
       dispatch({ type: 'notiVarient', payload: 'success' })
       setTimeout(() => {
         return window.location = window.location.pathname;
       }, 2000)

     } else {
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Something went wrong.Booking details are not updated' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage();
     }
   }

   const dummy = () => {
     console.log("void popup hit")
   }

   const oncloseEidt = () => {
     dispatch({ type: 'showEditpop', payload: false })
   }
   /**
   * @description : When issue ticket is selected from split PNR dropdown
   * @date: 10-04-2021
   * @author : <Azamuddin>
   */
   const handleIssueTicket = () => {
     dispatch({ type: "splitissue", payload: true })
     dispatch({ type: "voidenable", payload: true }) //enable void
     dispatch({ type: "refundenable", payload: false }) //refund
     dispatch({ type: "enableVoidBooking", payload: false })
     dispatch({ type: "enableVoidCancel", payload: false })
   }

   /**
   * @description : When issue cancel is selected from split PNR dropdown
   * @date: 10-04-2021
   * @author : <Azamuddin>
   */
   const handleCancelBooking = () => {
     dispatch({ type: "splitCancel", payload: true }) //enabling cancel
     dispatch({ type: "voidenable", payload: true }) //enable void
     dispatch({ type: "refundenable", payload: false }) //refund
     dispatch({ type: "enableVoidBooking", payload: false })
     dispatch({ type: "enableVoidCancel", payload: false })
   }

   /**
   * @description : When refund ticket is selected from split PNR dropdown
   * @date: 10-04-2021
   * @author : <Azamuddin>
   */
   const handlerefundIssueTicket = () => {
     dispatch({ type: "splitissue", payload: true })
     dispatch({ type: "refundenable", payload: true })
     dispatch({ type: "voidenable", payload: false })
     dispatch({ type: "enableVoidBooking", payload: false })
     dispatch({ type: "enableVoidCancel", payload: false })

   }

   /**
   * @description : When issue cancel is selected from split PNR dropdown
   * @date: 10-04-2021
   * @author : <Azamuddin>
   */
   const handlerefundCancelBooking = () => {
     dispatch({ type: "splitCancel", payload: true })
     dispatch({ type: "refundenable", payload: true })
     dispatch({ type: "voidenable", payload: false })
     dispatch({ type: "enableVoidBooking", payload: false })
     dispatch({ type: "enableVoidCancel", payload: false })

   }

   const pnrFetch = (e) => {
     dispatch({ type: "splitPNR", payload: e })
   }
   /**
    * @description : cancel the pnr selected from SPLIT PNR
    * @author : Azamuddin
    * @date : 10-04-2021
    */
   const cancelSplit = () => {
     if (state.splitPNR) {
       dispatch({ type: 'isvoidSplitLoading', payload: true });
       // request json for cancelling the pnr
       let req_json = {
         "pnr": state.splitPNR,
         "booking_id": state.bkng_det[0].booking_id,
         "flowtype": state.voidenable ? "Void" : "Refund",
         "region_type": state.bk_region_type,
         "req_cur": state.usr_cur
       }
       cancelledSplit(req_json).then(response => {
         if (response.data.suc) {
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'PNR Cancelled successfully' })
           dispatch({ type: 'notiVarient', payload: 'success' })
           return window.location = window.location.pathname
         }
       })
     } else {
       dispatch({ type: 'isvoidSplitLoading', payload: false });
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Please select atleast one PNR to further proceed the cancellation' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   /**
    * @description : selection of dropdown from slpit booking
    * @author : Azamuddin
    * @date : 10-04-2021
    */
   const splitSplitDropdown = (type, status) => {
     if (type == "cancel") {
       dispatch({ type: "splitCancel", payload: true })
       dispatch({ type: "enableVoidBooking", payload: false })
       dispatch({ type: "enableVoidCancel", payload: false })
     }
     if (status) {
       dispatch({ type: "voidenable", payload: true })
     } else {
       dispatch({ type: "voidenable", payload: false })
     }
   }

   /**
    * @description : cancel button to diable SPLIT pnr checkboxes
    * @author : Azamuddin
    * @date : 14-04-2021
    */
   const cancelSplitAction = () => {
     dispatch({ type: "voidenable", payload: false })
     dispatch({ type: "refundenable", payload: false })
     dispatch({ type: "splitissue", payload: false })
     dispatch({ type: "splitCancel", payload: false })
   }
   const refundedTicketsSplit = (e) => {
     if (state.bkng_det[0].flow_type !== "normal") {
       if (state.ticketsRefunded.length === 0) {
         dispatch({ type: "ticketsRefunded", payload: e })
       }
     }
   }


   /**
    *
    * @param {*} data
    * @description:below function call when we click on Downloddocs
    * @author:Rambabu
    * @date:16-05-2021
    */
   const handledownloaddocs = (data) => {
    dispatch({ type: 'sel_tid', payload: ""});
    dispatch({ type: 'indigoDocsList', payload: []});
     let confirmedPax = []
     for(let val of data){
       if(val.stsid == 23){
         confirmedPax.push(val)
       }
     }
     if(confirmedPax.length == 0 ){
      dispatch({ type: 'showitineraryRadio', payload: false });
     }
     let cloned_data = _.cloneDeep(data);
     let VRdata = _.cloneDeep(cloned_data.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID') || obj.sts.toUpperCase().includes('REFUND'))));
     VRdata.map(obj => {
       obj.sts = 'Confirmed';
       obj.stsid = 23;
       obj.segst = 'Confirmed';
     })
     let final_data = cloned_data.concat(VRdata);
     final_data = _.orderBy(final_data, ['pax_type', 'is_lead_pax', 'pax_name'], ['asc', 'desc', 'asc']);
     dispatch({ type: 'sel_tid', payload: data[0].tid });
     dispatch({ type: 'allpopuppaxdata', payload: data });
     dispatch({ type: 'enabledownloaddocs', payload: true });
     dispatch({ type: 'allpopuppaxticketeddata', payload: data });
   }



   const handledItinerary = (data) => {
    dispatch({ type: 'sel_tid', payload: ""});
    dispatch({ type: 'indigoDocsList', payload: []});
      dispatch({ type: 'showitineraryRadio', payload: true });
      dispatch({ type: 'onHoldItinerary', payload: false });
      dispatch({ type: 'cinvoice', payload: false });
      dispatch({ type: 'invoice', payload: false });
      dispatch({ type: 'itinerary', payload: true });
     dispatch({ type: 'enabledownloaddocs', payload: true });
     dispatch({ type: 'allpopuppaxticketeddata', payload: data });
     let poppaxdata=[]
      let tktdata = data.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('PENDING')));
      poppaxdata = _.concat(poppaxdata, tktdata);
      dispatch({ type: 'popuppaxdata', payload: poppaxdata });
   }


   /**
    *
    * @description:below function call when we click on close button
    * @author:Rambabu
    * @date:16-05-2021
    */
   const oncloseCancel = () => {
     dispatch({ type: 'ticketed', payload: true })
     dispatch({ type: 'void', payload: false })
     dispatch({ type: 'refund', payload: false })
     dispatch({ type: 'invoice', payload: true })
     dispatch({ type: 'cinvoice', payload: false })
     dispatch({ type: 'itinerary', payload: false })
     dispatch({ type: 'downloadtype', payload: 'INVOICE' })
     dispatch({ type: 'popupindigopaxListay', payload: [] })
     dispatch({ type: 'allpopuppaxdata', payload: [] })
     dispatch({ type: 'allpopuppaxticketeddata', payload: [] })
     dispatch({ type: 'showitineraryRadio', payload: true })
     dispatch({ type: 'changeStatus', payload: "" })
     dispatch({ type: 'popuppaxdata', payload: [] })
     dispatch({ type: 'popupticketsdata', payload: [] })
     dispatch({ type: 'enabledownloaddocs', payload: false })
     dispatch({ type: 'pupupmsg', payload: '' })
     dispatch({ type: 'popupallc', payload: false })
   }

   //For tabs selection
   const [refPriceTab, setRefPriceTab] = useState({
     active: "TA",
   });


   //set the active tab based on logged in user
   useEffect(() => {
     if (context.logindata.utype !== undefined) {
       if (context.logindata.utype === "ta_user" || context.logindata.utype === "att_user") {
         setRefPriceTab({ active: "TA" })
       } else if (context.logindata.utype === "sta_user") {
         setRefPriceTab({ active: "STA" })
       }
     }
   }, [context.logindata.utype]);





   /**
     *
     * @param {*} e
     * @description:below function call when we click on (Ticketed or Refund or Void)
     * @author:Rambabu
     * @date:17-05-2021
    */
   const selectedstatushandleChange = (e) => {
    dispatch({ type: 'popuppaxdata', payload: [] });
    dispatch({ type: 'pupupmsg', payload: 'No records found' });
    dispatch({ type: 'flightDocsData', payload: '' });
    dispatch({ type: 'popupticketsdata', payload: [] });
    dispatch({ type: 'indigoDocsList', payload: [] });
     if (e.target.id === "ticketed") {
      dispatch({ type: 'ticketed', payload: true })
      dispatch({ type: 'refund', payload: false })
      dispatch({ type: 'void', payload: false })

    } else if (e.target.id === "refund") {
      dispatch({ type: 'ticketed', payload: false })
      dispatch({ type: 'refund', payload: true })
      dispatch({ type: 'void', payload: false })

    } else if (e.target.id === "void") {
      dispatch({ type: 'ticketed', payload: false })
      dispatch({ type: 'refund', payload: false })
      dispatch({ type: 'void', payload: true })
    }

     if (e.target.id === "invoice") {
       dispatch({ type: 'downloadtype', payload: 'INVOICE' })
       dispatch({ type: 'invoice', payload: true })
       dispatch({ type: 'cinvoice', payload: false })
       dispatch({ type: 'itinerary', payload: false })

     } else if (e.target.id === "cinvoice") {
       dispatch({ type: 'downloadtype', payload: 'CREDIT NOTE' })
       dispatch({ type: 'invoice', payload: false })
       dispatch({ type: 'cinvoice', payload: true })
       dispatch({ type: 'itinerary', payload: false })

     } else if (e.target.id === "itinerary") {
       dispatch({ type: 'downloadtype', payload: 'ITINERARY' })
       dispatch({ type: 'invoice', payload: false })
       dispatch({ type: 'cinvoice', payload: false })
       dispatch({ type: 'itinerary', payload: true })

     }

   }


   /**
     * @description:below function call when we click on pax check box on popup
     * @author:Rambabu
     * @date:17-05-2021
    */
   const selectedpaxdataeventchange = (paxd) => (e) => {
     const { id, value } = e.target;
     let adultDetails=[];
     if (state.popupindigopaxListay.indexOf(id) > -1) {
       const index = state.popupindigopaxListay.indexOf(id);
       if (index > -1) {
         state.popupindigopaxListay = state.popupindigopaxListay.filter(x => x !== id)
         state.popupticketsdata = state.popupticketsdata.filter(x => x !== paxd)
         state.popupticketsdata = state.popupticketsdata.filter(x=>x.infant_map_id !== paxd.trv_id)
         dispatch({ type: 'popupallc', payload: false })
         dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
         adultDetails=[];
         state.popupticketsdata=[];
       }
     } else {
      let infantDetils =[]
       if(paxd.pax_type=="INF"){
       infantDetils = state.allpopuppaxdata.filter(x=>x.infant_map_id === paxd.trv_id)
       adultDetails = state.allpopuppaxdata.filter(x=>x.pax_type ==="ADT")
       state.popupticketsdata=[]
      } 
       if(paxd.pax_type=="ADT"){
       infantDetils = state.allpopuppaxdata.filter(x=>x.infant_map_id === paxd.trv_id)
       adultDetails = [];
       state.popupticketsdata=[]
      } 
       state.popupindigopaxListay = [...state.popupindigopaxListay, id]
       state.popupticketsdata = [...state.popupticketsdata, ...adultDetails, ...infantDetils,paxd]
       dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
       if (state.popuppaxdata.length === state.popupticketsdata.length) {
         dispatch({ type: 'popupallc', payload: true })
       }
     }
     pdfdownloadonclick();
   }

   /**
     * @description:below function call when we click on pax check box on popup
     * @author:Rambabu
     * @date:17-05-2021
    */
   const selectedallpaxaeventchange = (e) => {
     if (e.target.checked && isArrayNotEmpty(state.popuppaxdata)) {
      // dispatch({ type: 'popupallc', payload: false })
      //    dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
       dispatch({ type: 'popupallc', payload: true })
       dispatch({ type: 'popupindigopaxListay', payload: [] })
       dispatch({ type: 'popupticketsdata', payload: [] })
       state.popupticketsdata = []
       for (let paxs in state.popuppaxdata) {
         let pax_d = state.popuppaxdata[paxs];
         state.popupindigopaxListay.push(pax_d.tkt_no + paxs);
         state.popupticketsdata.push(pax_d);
       }
       dispatch({ type: 'popupindigopaxListay', payload: state.popupindigopaxListay })
       dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })

     } else if (!e.target.checked) {
       state.popupindigopaxListay = [];
       state.popupticketsdata = [];
       dispatch({ type: 'popupindigopaxListay', payload: state.popupindigopaxListay })
       dispatch({ type: 'popupticketsdata', payload: state.popupticketsdata })
       dispatch({ type: 'popupallc', payload: false })

     }
     pdfdownloadonclick();
   }




   /**
     * @description:below function call when refund splionward data from flightsummarysector
     * @author:Rambabu
     * @date:17-05-2021
    */
   const refundsplitonward = (data) => {
     dispatch({ type: 'refundsplitonward', payload: data })

   }

   /**
     * @description:below function call when refund splireturn data from flightsummarysector
     * @author:Rambabu
     * @date:17-05-2021
    */
   const refundsplitreturn = (data) => {
     dispatch({ type: 'refundsplitreturn', payload: data })

   }

   /**
     * @description:below function call when click on Viewdetails button
     * @author:Rambabu
     * @date:18-05-2021
    */
    const docsViewDetailsonclick = () => {
       let poppaxdata = [];
       dispatch({ type: 'popupindigopaxListay', payload: [] })
       dispatch({ type: 'popupticketsdata', payload: [] })
       dispatch({ type: 'popupallc', payload: false })
       dispatch({ type: 'changeStatus', payload: "" })
       if (state.ticketed) {
          dispatch({ type: 'changeStatus', payload: "Confirmed" })
         if(state.itinerary){
         let tktdata = state.allpopuppaxticketeddata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('CONFIRMED')));
         poppaxdata = _.concat(poppaxdata, tktdata);
         }
         else{
         let tktdata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('CONFIRMED'))
        //  || (obj.sts.toUpperCase().includes('REFUND')|| obj.sts.toUpperCase().includes('REFUND/CANCEL'))
         );
         poppaxdata = _.concat(poppaxdata, tktdata);
       }
       }
       if (state.refund && state.flt_supplier!==4) {
          dispatch({ type: 'changeStatus', payload: "Cancelled" })
         let refunddata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('REFUND')|| obj.sts.toUpperCase().includes('REFUND/CANCEL')));
         refunddata = refunddata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('FAILED')));
         poppaxdata = _.concat(poppaxdata, refunddata);
       }
       if (state.void) {
        dispatch({ type: 'changeStatus', payload: "Void" })
         let voiddata = state.allpopuppaxdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID')));
         voiddata = voiddata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('FAILED')));
         poppaxdata = _.concat(poppaxdata, voiddata);
       }
       poppaxdata = _.orderBy(poppaxdata, ['pax_type', 'is_lead_pax', 'pax_name'], ['asc', 'desc', 'asc']);
       dispatch({ type: 'popuppaxdata', payload: poppaxdata });

       //if selected criteria dont have any records need to display no records found
       if (!isArrayNotEmpty(poppaxdata)) {
         dispatch({ type: 'pupupmsg', payload: 'No records found' });
       } else {
         dispatch({ type: 'pupupmsg', payload: '' });
       }

       //38-voidbookfail,40-voidcancelfail,43-refundbookfail,45-refundcancelfail
       if (!state.ticketed && !state.refund && !state.void) {
         let final_d = state.allpopuppaxdata.filter(obj => isNotNull(obj.stsid) && (Number(obj.stsid) !== 38 && Number(obj.stsid) !== 40 &&
           Number(obj.stsid) !== 43 && Number(obj.stsid) !== 45));

         //if selected criteria dont have any records need to display no records found
         if (!isArrayNotEmpty(final_d)) {
           dispatch({ type: 'pupupmsg', payload: 'No records found' });
         } else {
           dispatch({ type: 'pupupmsg', payload: '' });
         }
         dispatch({ type: 'popuppaxdata', payload: final_d });
        }
         if(state.refund && state.flt_supplier===4){
          dispatch({ type: 'indigoDocsList', payload: [] });

          //if(state.bkng_det[0].flow_type==="normal"){
          let paxIndigoData=[];
            for (let val of state.pax_det){
              for (let p of val) {
                if (p != undefined && (p.sts === "Refund/booking" || p.sts === "Refund/cancel")) {
                  const req={
                   "pnr": isNotNull(p.split_pnr)?p.split_pnr:p.gds_pnr,
                   "id": p.trv_id
                  }
                  paxIndigoData.push(req);
                }
              }
            }
            const payload = {
              "booking_id": state.bkng_det[0].booking_id,
              "tid": state.sel_tid,
              "trans_id": (state.bkng_det[0].trans_id !== undefined) ? state.bkng_det[0].trans_id : "",
              "trav_id": paxIndigoData,
              "butype": context.logindata.butype
            }
            showIndigoRefunds(payload).then((resp) => {
              if(resp.refundRetrieveRes !== undefined && resp.refundRetrieveRes.suc && resp.refundRetrieveRes.data!==undefined) {
                for(let val of resp.refundRetrieveRes.data.pnr_price){
                  val.selected=false;
                }
                dispatch({ type: 'indigoDocsList', payload: resp.refundRetrieveRes.data.pnr_price });
                dispatch({ type: 'pupupmsg', payload: '' });
              }
            })
          //}
        }
     }




   /**
    * @description:below function call when click on PDF Donload option
    * @author:Rambabu
    * @date:18-05-2021
   */
   const pdfdownloadonclick = () => {
     let voided_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('VOID')));
     let refund_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (obj.sts.toUpperCase().includes('REFUND')||obj.sts.toUpperCase().includes('REFUND/CANCEL')));
     let ticketed_data = state.popupticketsdata.filter(obj => isNotNull(obj.sts) && (!obj.sts.toUpperCase().includes('REFUND') && !obj.sts.toUpperCase().includes('VOID')));
     let com_data = [];
     let seg_details = [];
     if (isArrayNotEmpty(voided_data)) {
       com_data = voided_data;

     } else if (isArrayNotEmpty(refund_data)) {
       com_data = refund_data;

     } else if (isArrayNotEmpty(ticketed_data)) {
       com_data = ticketed_data;

     }
     if (isArrayNotEmpty(com_data)) {
       if ((com_data[0]!==undefined && com_data[0].flow_type!==null &&  com_data[0].flow_type.toUpperCase() == 'NORMAL') || (state.bkng_det[0].bktype==="MANUAL")) {
        if(com_data[0]!==undefined && com_data[0].supplier==="0"){
           seg_details = state.seg_det;
         }else{
           let segsList=state.seg_det.filter(obj => obj.tid === com_data[0].tid);
           seg_details=segsList;
         }
       } else if (com_data[0]!==undefined && com_data[0].flow_type!==null && com_data[0].flow_type.toUpperCase() == 'SPLIT' && com_data[0].journey_type.toUpperCase() == 'O') {
         seg_details = state.seg_det.filter(obj => obj.journey_type === "O");
       } else if (com_data[0]!==undefined && com_data[0].flow_type!==null && com_data[0].flow_type.toUpperCase() == 'SPLIT' && com_data[0].journey_type.toUpperCase() == 'R') {
         seg_details = state.seg_det.filter(obj => obj.journey_type === "R");
       }
     }


     let void_fare_pax = []
     let void_paxdata = [];
     let tkt_fare_pax = []
     let tkt_paxdata = [];
     let refund_fare_pax = [];
     let refund_paxdata = [];
     let data = [];

     let final_obj = {}

     if(state.popupticketsdata.length > 0) {
     dispatch({type:"dd_supplier",payload:state.popupticketsdata[0].supplier})
     }
     //If TA Tab selected
     if (isNotNull(refPriceTab.active) && refPriceTab.active == 'TA' ) {
       //voided checkbox--start
       if (isArrayNotEmpty(voided_data) && (state.invoice || state.cinvoice)) {

         for (let vd of voided_data) {
           let void_paxobj = {}
           let v_tid = vd.tid
           let void_faredata = state.voidFareResponse.filter(obj => obj.tid === v_tid);
           let agency_data = [];
           //invoice radio button
           if (state.invoice) {
             agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'MTA') : [];
           } //customer invoice radio button
           else if (state.cinvoice) {
             agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'TA') : [];
           }
           let void_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt_no === vd.tkt_no) : [];
           if (isArrayNotEmpty(void_pax_faredata)) {
             void_paxobj.paxname = void_pax_faredata[0].paxname;
             void_paxobj.ptype = void_pax_faredata[0].ptype;
             void_paxobj.tkt_no = void_pax_faredata[0].tkt_no;
             void_paxobj.paid = void_pax_faredata[0].totalfare;
             //invoice radio button
             if (state.invoice) {
               void_paxobj.voidfee = void_pax_faredata[0].attvoidservicefee;
               void_paxobj.voidfee = Utilities.addPrice(void_paxobj.voidfee, 0, "Add");
             } //customer invoice radio button
             else if (state.cinvoice) {
               void_paxobj.voidfee = void_pax_faredata[0].tavoidservicefee;
               void_paxobj.voidfee = Utilities.addPrice(void_paxobj.voidfee, 0, "Add");
             }
             void_paxobj.total = void_pax_faredata[0].totalfare - Number(void_paxobj.voidfee);
             void_paxobj.total = Utilities.addPrice(void_paxobj.total, 0, "Add");
             void_fare_pax.push(void_paxobj);
           }
         }
       }
       //itinerary radio button
       if (state.itinerary) {
         void_paxdata = voided_data;
       }
       //voided checkbox--End


       //Ticketed check box---start
       if (isArrayNotEmpty(ticketed_data) && (state.invoice || state.cinvoice)) {

         for (let tkt of ticketed_data) {
           let tkt_paxobj = {}
           let tkt_tid = tkt.tid
           let tkt_faredata = state.fare_det.filter(obj => obj.tid === tkt_tid);
           let agency_data = [];
           let tkt_pax_faredata=[]
           if (state.invoice) {
            agency_data = isArrayNotEmpty(tkt_faredata) ? tkt_faredata[0].pasngrs.filter(obj => obj.type === 'MTA') : [];
            tkt_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt ? obj.tkt === tkt.tkt_no : obj.paxname === tkt.pax_name) : [];
          }else if(state.cinvoice){
             agency_data = isArrayNotEmpty(tkt_faredata) ? tkt_faredata[0].pasngrs.filter(obj => obj.type === 'TA') : [];
             tkt_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt ? obj.tkt === tkt.tkt_no : obj.paxname === tkt.pax_name) : [];
          }
           let tktpax_data = '';
           //invoice radio button
           if (state.invoice) {
             tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].sales : [];
           } //customer invoice radio button
           else if (state.cinvoice) {
             tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].sales : [];
           }
           if (isNotNull(tktpax_data) && isArrayNotEmpty(tkt_pax_faredata)) {
             tkt_paxobj.paxname = tkt_pax_faredata[0].paxname;
             tkt_paxobj.ptype = tkt_pax_faredata[0].ptype;
             tkt_paxobj.tkt_no = tkt_pax_faredata[0].tkt;
             //customer invoice radio button
             if (state.cinvoice) {
               tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.servicefee + tktpax_data.supcharge + tktpax_data.markup
               tkt_paxobj.basefare = Utilities.addPrice(tkt_paxobj.basefare, 0, "Add");
             } else if (state.invoice) {
               //tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.supcharge;
               tkt_paxobj.basefare = tktpax_data.basefare;
               tkt_paxobj.basefare = Utilities.addPrice(tkt_paxobj.basefare, 0, "Add");
             }
             tkt_paxobj.taxes = tkt_pax_faredata[0].taxes;
             tkt_paxobj.tds_amt = tkt_pax_faredata[0].sales.tds_amt;
             tkt_paxobj.servicefee = tktpax_data.servicefee;
             tkt_paxobj.supcharge = tktpax_data.supcharge;
             tkt_paxobj.supplier = state.popupticketsdata[0].supplier;
             tkt_paxobj.pnr = tkt.split_pnr!==null && tkt.split_pnr?tkt.split_pnr:tkt.gds_pnr;
             tkt_paxobj.meals = tktpax_data.meal_price;
             tkt_paxobj.baggage = tktpax_data.baggage_price;
             tkt_paxobj.seats=tktpax_data.seat_price;
             tkt_paxobj.disc = tktpax_data.disc;
             tkt_paxobj.plb = tkt_pax_faredata[0].sales.plb;
             tkt_paxobj.penalty = tktpax_data.penalty;
             tkt_paxobj.total = tktpax_data.total;
             tkt_paxobj.total = Utilities.addPrice(tkt_paxobj.total, 0, "Add");
             tkt_fare_pax.push(tkt_paxobj);
           }
         }

       }
       //itinerary radio button
       if (state.itinerary) {
         tkt_paxdata = ticketed_data;
       }
       //Ticketed check box---end

       //Refund checkbox ---started
       if (isArrayNotEmpty(refund_data) && (state.invoice || state.cinvoice)) {
         for (let rf of refund_data) {
           let journeytype = rf.journey_type;
           let flowtype = rf.flow_type;
           let refundpax_Alldata = {};
           let refundpax_data = {}
           if (flowtype.toUpperCase() == 'NORMAL') {
            refundpax_Alldata = request;
            //let allPaxdata = refundpax_Alldata.pnr_price[0].price[0]
            refundpax_data = refundpax_Alldata
          } else if (flowtype.toUpperCase() == 'SPLIT' && journeytype.toUpperCase() == 'O') {
             refundpax_data = state.refundsplitonward;
           } else if (flowtype.toUpperCase() == 'SPLIT' && journeytype.toUpperCase() == 'R') {
             refundpax_data = state.refundsplitreturn;
           }
           if (isNotNull(refundpax_data) && isArrayNotEmpty(refundpax_data.psngrs)) {
            let paxData = []
            for (let refpaxre of refundpax_data.psngrs) {
              let tktpaxrefudata = [];
              if (state.invoice) {
                tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'ATT')
              } //customer invoice radio button
              else if (state.cinvoice) {
                tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'TA');
              }
              if (isArrayNotEmpty(tktpaxrefudata)) {
                let refund_paxobj = {};
                refund_paxobj.paxname = rf.pax_name;
                refund_paxobj.ptype = rf.pax_type;
                refund_paxobj.pnr = rf.split_pnr!=="" && rf.split_pnr?rf.split_pnr:rf.gds_pnr;
                refund_paxobj.tkt_no = refpaxre.number!==undefined?refpaxre.number:refpaxre.tktnum[0]?.number !==undefined?refpaxre.tktnum[0].number:"N/A";
                refund_paxobj.paid = tktpaxrefudata[0].totalfare;
                refund_paxobj.charges = tktpaxrefudata[0].aircharge + tktpaxrefudata[0].bkservice;
                refund_paxobj.total = tktpaxrefudata[0].subtotal;
                refund_fare_pax.push(refund_paxobj);
                refund_paxobj.total = tktpaxrefudata[0].totalfare - refund_paxobj.charges;
                refund_paxobj.total = Utilities.addPrice(refund_paxobj.total, 0, "Add");
              }
            }
              // for (let i=0; i< tktpaxrefudata[0].pax_name.length; i++){
              //   let data = {}
              //   data.paxname = tktpaxrefudata[0].pax_name[i].split("(")[0]
              //   data.ptype = tktpaxrefudata[0].pax_name[i].split("(")[1].slice(0, 3)
              //   data.tkt_no = "NA"
              //   data.paid = tktpaxrefudata[0].totalfare
              //   data.charges =tktpaxrefudata[0].sup_aircharge + tktpaxrefudata[0].bkservice
              //   data.total = tktpaxrefudata[0].subtotal;
              //   paxData.push(data)
              // }
            }

          //    for (let data of paxData) {
          //        if (isArrayNotEmpty(paxData)) {
          //         // Type Ticket# Fare Paid Charges Refund Amount
          //          let refund_paxobj = {};
          //          refund_paxobj.paxname = data.paxname;
          //          refund_paxobj.ptype = data.ptype;
          //          refund_paxobj.tkt_no = data.tkt_no;
          //          refund_paxobj.paid = data.paid;
          //          refund_paxobj.charges =data.charges
          //          refund_paxobj.total = data.total;
          //          refund_fare_pax.push(refund_paxobj);
          //        }
          //    }

          //  }

         }
       }
       //itinerary radio button
       if (state.itinerary) {
         refund_paxdata = refund_data
       }
       //Refund checkbox ---End


     }//If TA Tab selected--End

     //If STA Tab selected--start
     if (isNotNull(refPriceTab.active) && refPriceTab.active == 'STA') {

       //voided checkbox--start
       if (isArrayNotEmpty(voided_data) && (state.invoice || state.cinvoice)) {

         for (let vd of voided_data) {
           let void_paxobj = {}
           let v_tid = vd.tid
           let void_faredata = state.voidFareResponse.filter(obj => obj.tid === v_tid);
           let agency_data = [];
           //invoice radio button
           if (state.invoice) {
             agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'TA') : [];
           } //customer invoice radio button
           else if (state.cinvoice) {
             agency_data = isArrayNotEmpty(void_faredata) ? void_faredata[0].passenger.filter(obj => obj.type === 'STA') : [];
           }
           let void_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt_no === v_tid.tkt_no) : [];
           if (isArrayNotEmpty(void_pax_faredata)) {
             void_paxobj.paxname = void_pax_faredata[0].paxname;
             void_paxobj.ptype = void_pax_faredata[0].ptype;
             void_paxobj.tkt_no = void_pax_faredata[0].tkt_no;
             void_paxobj.paid = void_pax_faredata[0].totalfare;
             //invoice radio button
             if (state.invoice) {
               void_paxobj.voidfee = void_pax_faredata[0].tavoidservicefee;
             } //customer invoice radio button
             else if (state.cinvoice) {
               void_paxobj.voidfee = void_pax_faredata[0].stavoidservicefee;
             }
             void_paxobj.total = void_pax_faredata[0].totalfare - void_pax_faredata[0].attvoidservicefee;
             void_paxobj.total = Utilities.addPrice(void_paxobj.total, 0, "Add");
             void_fare_pax.push(void_paxobj);
           }
         }

       }
       //itinerary radio button
       if (state.itinerary) {
         void_paxdata = voided_data;
       }
       //voided checkbox--end


       //Ticketed check box---start
       if (isArrayNotEmpty(ticketed_data) && (state.invoice || state.cinvoice)) {
         for (let tkt of ticketed_data) {
           let tkt_paxobj = {}
           let tkt_tid = tkt.tid
           let tkt_faredata = state.fare_det.filter(obj => obj.tid === tkt_tid);
           let agency_data = isArrayNotEmpty(tkt_faredata) ? tkt_faredata[0].pasngrs.filter(obj => obj.type === 'STA') : [];
           let tkt_pax_faredata = isArrayNotEmpty(agency_data) ? agency_data[0].pax.filter(obj => obj.tkt === tkt.tkt_no) : [];
           let tktpax_data = '';
           //invoice radio button
           if (state.invoice) {
             tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].purchase : [];
           } //customer invoice radio button
           else if (state.cinvoice) {
             tktpax_data = isArrayNotEmpty(tkt_pax_faredata) ? tkt_pax_faredata[0].sales : [];
           }
           if (isNotNull(tktpax_data) && isArrayNotEmpty(tkt_pax_faredata)) {
             tkt_paxobj.paxname = tkt_pax_faredata[0].paxname;
             tkt_paxobj.ptype = tkt_pax_faredata[0].ptype;
             tkt_paxobj.tkt_no = tkt_pax_faredata[0].tkt;
             if (state.cinvoice) {
               tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.servicefee + tktpax_data.supcharge + tktpax_data.markup
             } else if (state.invoice) {
               //tkt_paxobj.basefare = tktpax_data.basefare + tktpax_data.supcharge;
               tkt_paxobj.basefare = tktpax_data.basefare;
             }
             tkt_paxobj.taxes = tkt_pax_faredata[0].taxes;
             tkt_paxobj.tds_amt = tkt_pax_faredata[0].sales.tds_amt;
             tkt_paxobj.servicefee = tktpax_data.servicefee;
             tkt_paxobj.supcharge = tktpax_data.supcharge;
             tkt_paxobj.disc = tktpax_data.disc;
             tkt_paxobj.supplier = state.popupticketsdata[0].supplier;
             tkt_paxobj.meals = tktpax_data.meal_price;
             tkt_paxobj.baggage = tktpax_data.baggage_price;
             tkt_paxobj.total = tktpax_data.total;
             tkt_paxobj.total = Utilities.addPrice(tkt_paxobj.total, 0, "Add");
             tkt_fare_pax.push(tkt_paxobj);
           }
         }
       }
       //itinerary radio button
       if (state.itinerary) {
         tkt_paxdata = ticketed_data;
       }
       //Ticketed check box---end

       //Refund checkbox ---started
       if (isArrayNotEmpty(refund_data) && (state.invoice || state.cinvoice)) {

         for (let rf of refund_data) {
           let journeytype = rf.journey_type;
           let flowtype = rf.flow_type;
           let refundpax_data = {};
           if (flowtype.toUpperCase() == 'NORMAL') {
             refundpax_data = request;
           } else if (flowtype.toUpperCase() == 'SPLIT' && journeytype.toUpperCase() == 'O') {
             refundpax_data = state.refundsplitonward;
           } else if (flowtype.toUpperCase() == 'SPLIT' && journeytype.toUpperCase() == 'R') {
             refundpax_data = state.refundsplitreturn;
           }
           if (isNotNull(refundpax_data) && isArrayNotEmpty(refundpax_data.psngrs)) {
             for (let refpaxre of refundpax_data.psngrs) {
               let refundpaxdata = refpaxre.tktnum.filter(obj => obj.number === rf.tkt_no);
               if (isArrayNotEmpty(refundpaxdata)) {
                 let tktpaxrefudata = [];
                 let fare_used = 0;
                 //invoice radio button
                 if (state.invoice) {
                   tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'TA')
                   fare_used = refundpaxdata[0].mta_fare_used;
                 } //customer invoice radio button
                 else if (state.cinvoice) {
                   tktpaxrefudata = refpaxre.price.filter(obj => obj.type === 'STA');
                   fare_used = refundpaxdata[0].ta_fare_used;
                 }
                 if (isArrayNotEmpty(tktpaxrefudata)) {
                   let refund_paxobj = {};
                   refund_paxobj.paxname = refundpaxdata[0].pname;
                   refund_paxobj.ptype = refpaxre.ptype;
                   refund_paxobj.tkt_no = refundpaxdata[0].number;
                   refund_paxobj.paid = tktpaxrefudata[0].totalfare;
                   refund_paxobj.charges = tktpaxrefudata[0].bkservice + tktpaxrefudata[0].aircharge +
                     tktpaxrefudata[0].supcharge + tktpaxrefudata[0].canmarkup + tktpaxrefudata[0].canservice + tktpaxrefudata[0].ocharges + fare_used;
                   refund_paxobj.charges = Utilities.addPrice(refund_paxobj.charges, 0, "Add");
                   refund_paxobj.total = tktpaxrefudata[0].totalfare - refund_paxobj.charges;
                   refund_paxobj.total = Utilities.addPrice(refund_paxobj.total, 0, "Add");
                   refund_fare_pax.push(refund_paxobj);
                 }
               }

             }
           }

         }
       }
       //itinerary radio button
       if (state.itinerary) {
         refund_paxdata = refund_data;
       }
       //Refund checkbox ---End

     }
     //If STA Tab selected--End

     //If void selected, and select the (invoice or cinvoice ) radiobutton selected
     if (isArrayNotEmpty(void_fare_pax)) {
       let void_det = { void_det: void_fare_pax }
       data.push(void_det);
     }
     //if void selected and itinerary radiobutton selected
     if (state.itinerary && isArrayNotEmpty(void_paxdata)) {
       let void_pax_det = { void_pax_det: void_paxdata }
       data.push(void_pax_det);
     }

     //If Ticketed selected, and select the (invoice or cinvoice ) radiobutton selected
     if (isArrayNotEmpty(tkt_fare_pax)) {
       let fare_det = { fare_det: tkt_fare_pax }
       data.push(fare_det);
     }

     //if Ticketed selected and itinerary radiobutton selected
     if (state.itinerary && isArrayNotEmpty(tkt_paxdata)) {
       let pax_det = { pax_det: tkt_paxdata }
       data.push(pax_det);
     }

     //If Refund selected, and select the (invoice or cinvoice ) radiobutton selected
     if (isArrayNotEmpty(refund_fare_pax)) {
       let refund_det = { refund_det: refund_fare_pax }
       data.push(refund_det);
     }
     //if refund selected and itinerary radiobutton selected
     if (state.itinerary && isArrayNotEmpty(refund_paxdata)) {
       let refund_pax_det = { refund_pax_det: refund_paxdata }
       data.push(refund_pax_det);
     }

     //constructing final json
     if (isArrayNotEmpty(data)) {
       if (isArrayNotEmpty(seg_details)) {
         let seg_det = { seg_det: seg_details }
         data.push(seg_det);
       }
       if(state.ticketed && state.downloadtype == "CREDIT NOTE"){
        final_obj.invoicetype = "CUST INVOICE";
       }
       else if(!state.ticketed && state.downloadtype == "INVOICE" ){
        final_obj.invoicetype = "CREDIT NOTE";
       }
       else if(!state.ticketed && state.downloadtype == "CREDIT NOTE" ){
        final_obj.invoicetype = "CUST CREDIT NOTE";
       }
       else{
        final_obj.invoicetype = state.downloadtype;
       }
       final_obj.type = refPriceTab.active;
       final_obj.isticketed = state.ticketed;
       final_obj.isrefund = state.refund;
       final_obj.isvoid = state.void;
       final_obj.data = data;
       dispatch({ type: 'flightDocsData', payload: final_obj });
     }


   }

   /**
    * @description:below function call when click on PDF Donload option
    * @author:Rambabu
    * @date:18-05-2021
   */
    const indigoPdfdownloadonClick = () => {
      dispatch({ type: 'indigo_refund_docs', payload: {} });
      let final_obj = {}
      let data = [];
      //constructing final json
        let refund_list = state.indigoDocsList.filter(obj=>obj.selected);
        if(state.flow_type == "normal" && state.trip_type==="2"){
          if(state.sel_tid.includes("_1")){
          let paxInfo = state.pax_det[1].filter(obj=>obj.sts==="Refund/cancel");
          if(paxInfo.length!==0){
            if(refund_list[0].split_pnr!==null && refund_list[0].split_pnr!==undefined){
              let list = paxInfo.filter(obj=>obj.split_pnr!==null && obj.split_pnr===refund_list[0].split_pnr);
              if(list.length!==0){
                let seg_det = { seg_det: state.seg_det }
                data.push(seg_det);
              }else{
                let segsList=state.seg_det.filter(obj => obj.tid === state.sel_tid);
                let seg_det = { seg_det: segsList }
                data.push(seg_det);
              }
            }else{
              let seg_det = { seg_det: state.seg_det }
                data.push(seg_det);
            }
          }else{
            let segsList=state.seg_det.filter(obj => obj.tid === state.sel_tid);
            let seg_det = { seg_det: segsList }
            data.push(seg_det);
          }
          }else{
          let paxInfo = state.pax_det[0].filter(obj=>obj.sts==="Refund/cancel");
          if(paxInfo.length!==0){
            if(refund_list[0].split_pnr!==null && refund_list[0].split_pnr!==undefined){
              let list = paxInfo.filter(obj=>obj.split_pnr!==null && obj.split_pnr===refund_list[0].split_pnr);
              if(list.length!==0){
                let seg_det = { seg_det: state.seg_det }
                data.push(seg_det);
              }else{
                let segsList=state.seg_det.filter(obj => obj.tid === state.sel_tid);
                let seg_det = { seg_det: segsList }
                data.push(seg_det);
              }
            }else{
              let seg_det = { seg_det: state.seg_det }
              data.push(seg_det);
            }
          }else{
            let segsList=state.seg_det.filter(obj => obj.tid === state.sel_tid);
            let seg_det = { seg_det: segsList }
            data.push(seg_det);
          }
          }
        }else{
          let segsList=state.seg_det.filter(obj => obj.tid === state.sel_tid);
          let seg_det = { seg_det: segsList }
          data.push(seg_det);
        }
        let refund_det = { refund_det: refund_list }
        data.push(refund_det);
        if(state.refund && state.downloadtype == "INVOICE"){
         final_obj.invoicetype = "CREDIT NOTE";
         dispatch({ type: 'downloadtype', payload: "CREDIT_NOTE" });
        }
        else if(state.refund && state.downloadtype == "CREDIT NOTE" ){
         final_obj.invoicetype = "CUST CREDIT NOTE";
         dispatch({ type: 'downloadtype', payload: "CUST_CREDIT_NOTE" });
        }
        else{
         final_obj.invoicetype = state.downloadtype;
        }
        final_obj.type = refPriceTab.active;
        final_obj.isticketed = state.ticketed;
        final_obj.isrefund = state.refund;
        final_obj.isvoid = state.void;
        final_obj.sel_pnr = refund_list[0].split_pnr!==null?refund_list[0].split_pnr:refund_list[0].pnr;
        final_obj.data = data;
        dispatch({ type: 'indigo_refund_docs', payload: final_obj });
    }



   /**
    * @description :cancel booking function calls
    * the function cn only be call for normal booking or onward FBF
    * @author : Azamuddin
    * @date : 28-05-2021
    * @functions : cancelBookPopup,handleCancelBookFlowReturn,handleCancelBookingFlow
    */
   //cancel pop up call for onward or normal booking
   const cancelBookPopup = () => {
     dispatch({ type: "cancelPopup", payload: true })
     dispatch({ type: "cancel_flow_type", payload: "O" })
   }

   //cancel popup for return booking
   const handleCancelBookFlowReturn = (e) => {
     dispatch({ type: "cancelPopup", payload: true })
     dispatch({ type: "cancel_flow_type", payload: "R" })
   }

   //cancel booking flow json construction and api call
   const handleCancelBookingFlow = () => {
     //request needed for backend gggg
     let pnr = ""
     let segList = []
     if (state.cancel_flow_type == "O") {
       segList = state.seg_det.filter(x => x.journey_type === "O"); //fetch seg details wrt to journey type
     } else {
       segList = state.seg_det.filter(x => x.journey_type === "R"); //fetch seg details wrt to journey type
     }
     if (segList[0].normal_gds_pnr !== "" && segList[0].normal_gds_pnr.includes(",")) {
       let prnrAry = segList[0].normal_gds_pnr.split(",");
       if (state.cancel_flow_type == "O") {
         pnr = prnrAry[0];
       } else {
         pnr = prnrAry[1];
       }

     } else {
       if (segList.length !== 0) {
         if (segList !== undefined && segList[0] !== undefined) {
           pnr = segList[0].normal_gds_pnr !== "" ? segList[0].normal_gds_pnr : segList[0].split_gds_pnr;
         }
       }
     }
     let req_json = {
       "tid": segList[0].tid,
       "isTicketed": state.bkng_det[0].booking_status == "23" ? true : false,
       "booking_id": state.bkng_det[0].booking_id,
       "pnr": pnr,
       "region_type": state.bk_region_type,
       "req_cur": state.usr_cur
     }
     cancelBookingFlow(req_json).then((response) => {
       if (response.data.suc) {
         let data = response.data.data
         if (data.cancelled) {
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Booking Cancelled Successfully' })
           dispatch({ type: 'notiVarient', payload: 'success' })
           setTimeout(() => {
             return window.location = window.location.pathname
           }, [1500])
         }
       }
     })
   }

   const enableSplitPopup = (data) => {
     dispatch({ type: 'enableRSplitPopup', payload: true })
     dispatch({ type: "enableSplitReissueO", payload: data })
   }

   const enableHoldSplitPopup = (data) => {
     dispatch({ type: 'enableOnHoldPopup', payload: true })
     dispatch({ type: "OnHoldtkts", payload: data })
   }

   const enableHoldRSplitPopup = (data) => {
     dispatch({ type: 'enableOnHoldRPopup', payload: true })
     dispatch({ type: "OnHoldRtkts", payload: data })
   }

   const enableSplitRPopup = (data) => {
     dispatch({ type: 'enableRSplitPopup', payload: true })

     dispatch({ type: "enableSplitReissueR", payload: data })

   }
   const onHoldSplitTickts = (data)=>{
    dispatch({ type: "process", payload: true })
    if (data && data.length !== 0) {
      //dispatch({type:"enableRSplitPopup",payload:false})
      // setIsLoadingMore(true);
      let adts = [];
      let chds = [];
      let infs = [];
      let finalPax = [];
      let tktsData = Utilities.getUniqData(data);
      if (tktsData !== undefined && tktsData.length !== 0) {
        adts = tktsData.filter(x => x.ty === "ADT");
        for (let adt of adts) {
          finalPax.push(adt);
        }
        chds = tktsData.filter(x => x.ty === "CHD");
        if (chds !== undefined && chds !== 0) {
          for (let chd of chds) {
            finalPax.push(chd);
          }
        }
        infs = tktsData.filter(x => x.ty === "INF");
        if (infs !== undefined && infs !== 0) {
          for (let inf of infs) {
            finalPax.push(inf);
          }
        }
      }
      // let seg_det = state.selected_data[0].seg_det
      let splitSegs = [];
      let  segList = []//check for segdet with journey_type : "R"(Return)
      if(state.flow_type == "split") {
        segList = state.seg_det.filter(x => x.journey_type === "O");
      } else {
        segList = state.seg_det
      }
      for (let seg of segList) {
        const segpayload = {
          "src": seg.seg_source,
          "des": seg.seg_destination,
          "fn": seg.flt_no ? seg.flt_no.toString() : "",
          "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
          "airpnr": seg.normal_airline_pnr,
          "tid": seg.tid
        }
        splitSegs.push(segpayload)
      }
      let pnr = ''
      if (segList[0].normal_gds_pnr.includes(',')) {
        let pnrarray = segList[0].normal_gds_pnr.split(',')
        pnr = pnrarray[0]
      } else {
        pnr = segList[0].normal_gds_pnr
      }
      const payload = {
        "tid": segList[0].tid,
        "pnr": pnr,
        "booking_id": state.bkng_det[0].booking_id,
        "tkts": finalPax,
        "journey_type": state.flow_type == "split" ? "Onward" : null,
        "split_segs": splitSegs,
        "bktype": "ONHOLD/SPLIT",
        "region_type": state.bk_region_type,
        "req_cur": state.usr_cur
      }
      if(state.bkng_det[0].flow_type == "split") {
        payload.onhold_sty = "split"
      }
      getReissueSplitResp(payload).then((resp) => {
        if (resp.suc && resp.data !== undefined) {
          dispatch({ type: "enableOnHoldPopup", payload: false })
          dispatch({ type: 'onHoldSplitResp', payload: resp })
          // setIsLoadingMore(false);
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Split Of PNR During OnHold is successful' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          dispatch({ type: "process", payload: false })
          setTimeout(() => {
            return window.location = window.location.pathname
          }, 1500)
        } else {
          // setIsLoadingMore(false);
          dispatch({ type: "process", payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      })
    } else {
      // setIsLoadingMore(false);
      dispatch({ type: "process", payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Select atleast One passenger and proceed for Split' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
   }


   const onward_hold = () => {
    dispatch({type:"onward_hold_split",payload:true})
   //  dispatch({type:"onhold_split",payload:"Onward"})
  }
  const change_selcteed = () => {
    dispatch({type:"onhold_split",payload:"Return"})
  }
   const onHoldRSplitTickts = (data) => {
    dispatch({ type: "process", payload: true })
    if (data && data.length !== 0) {
      //dispatch({type:"enableRSplitPopup",payload:false})
      // setIsLoadingMore(true);
      let adts = [];
      let chds = [];
      let infs = [];
      let finalPax = [];
      let tktsData = Utilities.getUniqData(data);
      if (tktsData !== undefined && tktsData.length !== 0) {
        adts = tktsData.filter(x => x.ty === "ADT");
        for (let adt of adts) {
          finalPax.push(adt);
        }
        chds = tktsData.filter(x => x.ty === "CHD");
        if (chds !== undefined && chds !== 0) {
          for (let chd of chds) {
            finalPax.push(chd);
          }
        }
        infs = tktsData.filter(x => x.ty === "INF");
        if (infs !== undefined && infs !== 0) {
          for (let inf of infs) {
            finalPax.push(inf);
          }
        }
      }
      // let seg_det = state.selected_data[0].seg_det
      let splitSegs = [];
      let segList = state.seg_det.filter(x => x.journey_type === "R"); //check for segdet with journey_type : "R"(Return)

      for (let seg of segList) {
        const segpayload = {
          "src": seg.seg_source,
          "des": seg.seg_destination,
          "fn": seg.flt_no ? seg.flt_no.toString() : "",
          "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
          "airpnr": seg.normal_airline_pnr,
          "tid" : seg.tid
        }
        splitSegs.push(segpayload)
      }
      let pnr = ''
      if (segList[0].normal_gds_pnr.includes(',')) {
        let pnrarray = segList[0].normal_gds_pnr.split(',')
        pnr = pnrarray[1]
      } else {
        pnr = segList[0].normal_gds_pnr
      }
      const payload = {
        "tid": segList[0].tid,
        "pnr": pnr,
        "booking_id": state.bkng_det[0].booking_id,
        "tkts": finalPax,
        "journey_type": "Return",
        "split_segs": splitSegs,
        "bktype": "ONHOLD/SPLIT",
        "region_type": state.bk_region_type,
        "req_cur": state.usr_cur
      }
      if(state.bkng_det[0].flow_type == "split") {
        payload.onhold_sty = "split"
      }
      getReissueSplitResp(payload).then((resp) => {
        if (resp.suc && resp.data !== undefined) {
          dispatch({ type: "enableOnHoldRPopup", payload: false })
          dispatch({ type: 'splitReissueResp', payload: resp })
          // setIsLoadingMore(false);
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Split Of PNR During OnHold is successful' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          dispatch({ type: "process", payload: false })
          setTimeout(() => {
            return window.location = window.location.pathname
          }, 1500)
        } else {
          // setIsLoadingMore(false);
          dispatch({ type: "process", payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      })
    } else {
      // setIsLoadingMore(false);
      dispatch({ type: "process", payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
   }
   //Call refund service to get the details
   const reissueSplitTickts = (data) => {
     dispatch({ type: "process", payload: true })
     if (data && data.length !== 0) {
       // dispatch({type:"enableRSplitPopup",payload:false})
       // setIsLoadingMore(true);
       let adts = [];
       let chds = [];
       let infs = [];
       let finalPax = [];
       let tktsData = Utilities.getUniqData(data);
       if (tktsData !== undefined && tktsData.length !== 0) {
         adts = tktsData.filter(x => x.ty === "ADT");
         for (let adt of adts) {
           finalPax.push(adt);
         }
         chds = tktsData.filter(x => x.ty === "CHD");
         if (chds !== undefined && chds !== 0) {
           for (let chd of chds) {
             finalPax.push(chd);
           }
         }
         infs = tktsData.filter(x => x.ty === "INF");
         if (infs !== undefined && infs !== 0) {
           for (let inf of infs) {
             finalPax.push(inf);
           }
         }
       }
       // let seg_det = state.selected_data[0].seg_det
       let splitSegs = [];
       let segList = []
       if(state.flow_type == "split") {
        segList = state.seg_det.filter(x => x.journey_type === "O"); //check for segdet with journey_type : "R"(Return)
       } else {
         segList = state.seg_det
       }
       for (let seg of segList) {
         const segpayload = {
           "src": seg.seg_source,
           "des": seg.seg_destination,
           "fn": seg.flt_no ? seg.flt_no.toString() : "",
           "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
           "airpnr": seg.normal_airline_pnr,
           "tid":seg.tid
         }
         splitSegs.push(segpayload)
       }
       let pnr = ''
       if (segList[0].normal_gds_pnr.includes(',')) {
         let pnrarray = segList[0].normal_gds_pnr.split(',')
         pnr = pnrarray[0]
       } else {
         pnr = segList[0].normal_gds_pnr
       }
       const payload = {
         "tid": segList[0].tid,
         "pnr": pnr,
         "booking_id": state.bkng_det[0].booking_id,
         "tkts": finalPax,
         "journey_type": "Onward",
         "split_segs": splitSegs,
         "region_type": state.bk_region_type,
         "req_cur": state.usr_cur
       }
       getReissueSplitResp(payload).then((resp) => {
         if (resp.suc && resp.data !== undefined) {
           dispatch({ type: "enableRSplitPopup", payload: false })
           dispatch({ type: 'splitReissueResp', payload: resp })
           // setIsLoadingMore(false);
           dispatch({ type: "process", payload: false })
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Split Of PNR During REISSUE is successful' })
           dispatch({ type: 'notiVarient', payload: 'success' })
           setTimeout(() => {
             return window.location = window.location.pathname
           }, 1500)
         } else {
           // setIsLoadingMore(false);
           dispatch({ type: "process", payload: false })
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       })
     } else {
       setIsLoadingMore(false);
       dispatch({ type: "process", payload: false })
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   const reissueRktsReturn = (data) => {
     dispatch({ type: "process", payload: true })
     if (data && data.length !== 0) {
       //dispatch({type:"enableRSplitPopup",payload:false})
       // setIsLoadingMore(true);
       let adts = [];
       let chds = [];
       let infs = [];
       let finalPax = [];
       let tktsData = Utilities.getUniqData(data);
       if (tktsData !== undefined && tktsData.length !== 0) {
         adts = tktsData.filter(x => x.ty === "ADT");
         for (let adt of adts) {
           finalPax.push(adt);
         }
         chds = tktsData.filter(x => x.ty === "CHD");
         if (chds !== undefined && chds !== 0) {
           for (let chd of chds) {
             finalPax.push(chd);
           }
         }
         infs = tktsData.filter(x => x.ty === "INF");
         if (infs !== undefined && infs !== 0) {
           for (let inf of infs) {
             finalPax.push(inf);
           }
         }
       }
       // let seg_det = state.selected_data[0].seg_det
       let splitSegs = [];
       let segList = state.seg_det.filter(x => x.journey_type === "R"); //check for segdet with journey_type : "R"(Return)

       for (let seg of segList) {
         const segpayload = {
           "src": seg.seg_source,
           "des": seg.seg_destination,
           "fn": seg.flt_no ? seg.flt_no.toString() : "",
           "dd": DateUtils.prettyDate(seg.depart_time, "YYYY-MM-DD"),
           "airpnr": seg.normal_airline_pnr,
           "tid":seg.tid
         }
         splitSegs.push(segpayload)
       }
       let pnr = ''
       if (segList[0].normal_gds_pnr.includes(',')) {
         let pnrarray = segList[0].normal_gds_pnr.split(',')
         pnr = pnrarray[1]
       } else {
         pnr = segList[0].normal_gds_pnr
       }
       const payload = {
         "tid": segList[0].tid,
         "pnr": pnr,
         "booking_id": state.bkng_det[0].booking_id,
         "tkts": finalPax,
         "journey_type": "Return",
         "split_segs": splitSegs,
         "region_type": state.bk_region_type,
         "req_cur": state.usr_cur
       }
       getReissueSplitResp(payload).then((resp) => {
         if (resp.suc && resp.data !== undefined) {
           dispatch({ type: "enableRSplitPopup", payload: false })
           dispatch({ type: 'splitReissueResp', payload: resp })
           // setIsLoadingMore(false);
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Split Of PNR During REISSUE is successful' })
           dispatch({ type: 'notiVarient', payload: 'success' })
           dispatch({ type: "process", payload: false })
           setTimeout(() => {
             return window.location = window.location.pathname
           }, 1500)
         } else {
           // setIsLoadingMore(false);
           dispatch({ type: "process", payload: false })
           dispatch({ type: 'notiMessageShow', payload: true })
           dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
           dispatch({ type: 'notiVarient', payload: 'danger' })
           hidemessage()
         }
       })
     } else {
       // setIsLoadingMore(false);
       dispatch({ type: "process", payload: false })
       dispatch({ type: 'notiMessageShow', payload: true })
       dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed for Reissue' })
       dispatch({ type: 'notiVarient', payload: 'danger' })
       hidemessage()
     }
   }

   const clearPopList = () => {
     return window.location = window.location.pathname
   }


   const bagLabelConstruction = (tag) => {
     let tagChanged = ""
     if(tag && tag.includes("total")) {
        let splitted_tag = tag.split("total")
        tagChanged = splitted_tag[0]
     } else if(tag) {
         let splitted_tag = tag.split("-")
         tagChanged = splitted_tag[0] +" - "+splitted_tag[1]
     }

     return tagChanged;
 }
 const disableOnholdSplit = () => {
   dispatch({type:"enablesptpnr",payload: false})
 }

 const handleChange = (e) => {
  const { id, value } = e.target
  dispatch({ type: id, payload: value });
};

  //cancel pop up call for onward or normal booking
  const insertData = () => {
    dispatch({ type: "enableInsert", payload: true })
    dispatch({ type: "enablePnrResp", payload: false })
    dispatch({ type: "pnrResponse", payload: "" })
    dispatch({ type: "insertPnr", payload: "" })
    dispatch({ type: "jtype", payload: "ONWARD" })
    dispatch({ type: "bktype", payload: "REISSUE" })
  }

  //cancel pop up call for onward or normal booking
  const manualReissueReturn = (type) => {
    dispatch({ type: "enableManualReissue", payload: true })
    dispatch({ type: "enablePnrResp", payload: false })
    dispatch({ type: "manualResponse", payload: "" })
    dispatch({ type: "insertPnr", payload: "" })
    dispatch({ type: "jtype", payload: type })
    // dispatch({ type: "bktype", payload: "REISSUE" })
    if (state.bkng_det[0].flow_type === "normal"){
      dispatch({ type: "oldTid", payload: state.bkng_det[0].tid })
    }else if(state.bkng_det[0].flow_type === "split"){
      let segList = state.seg_det.filter(x => x.journey_type === type); //fetch seg details wrt to journey type
      if (segList[0].tid.includes("/")) {
        let tidAry = segList[0].tid.split("/");
        dispatch({ type: "oldTid", payload: type==="ONWARD"?tidAry[0]:tidAry[1] })
      }else{
        if (segList.length !== 0) {
          if (segList !== undefined && segList[0] !== undefined) {
            dispatch({ type: "oldTid", payload: segList[0].tid })
          }
        }
      }
    }else{
      dispatch({ type: "oldTid", payload: state.bkng_det[0].tid })
    }
  }

  //cancel pop up call for onward or normal booking
  const manualReissue = () => {
    dispatch({ type: "enableManualReissue", payload: true })
    dispatch({ type: "enablePnrResp", payload: false })
    dispatch({ type: "manualResponse", payload: "" })
    dispatch({ type: "insertPnr", payload: "" })
    dispatch({ type: "jtype", payload: "ONWARD" })
    dispatch({ type: "oldTid", payload: state.bkng_det[0].tid })
    // dispatch({ type: "bktype", payload: "REISSUE" })
  }
  const updateInserPnr=(type)=>{
    dispatch({ type: "enableInsert", payload: true })
    dispatch({ type: "enablePnrResp", payload: false })
    dispatch({ type: "pnrResponse", payload: "" })
    dispatch({ type: "insertPnr", payload: "" })
    dispatch({ type: "jtype", payload: type })
    dispatch({ type: "bktype", payload: "REISSUE" })
}

const updateInserImpPnr=()=>{
  dispatch({ type: "enableInsert", payload: true })
  dispatch({ type: "enablePnrResp", payload: false })
  dispatch({ type: "pnrResponse", payload: "" })
  dispatch({ type: "insertPnr", payload: "" })
  dispatch({ type: "jtype", payload: "ONWARD" })
  dispatch({ type: "bktype", payload: "IMPORT/REISSUE" })
}
const handleSegmentone = (seg1, seg2)=>{
  let clone_ow_pax = _.cloneDeep(state.pax_det[0])
  let ow_group = handleGroup(Object.values(clone_ow_pax), "split_pnr")
    let order1= []
    let order2= []
    for(let i=0; i< ow_group.length; i++){
      order1.push(seg1);
      if(seg2 !== undefined){
      order2.push(seg2);
      }
    }

    if(seg2 == undefined){
      let data1 = order1.map((seg, idx)=>{
        return{
          ...seg, pax_data : ow_group[idx]
        }
      })
      let final_seg = data1.map((segment, idx)=>{
        return{
          ...segment, segment_id: idx
        }
      })
        return final_seg
    }
    else{
      let data1 = order1.map((seg, idx)=>{
        return{
          ...seg, pax_data : ow_group[idx]
        }
      })
        let data2 = order2.map((seg, idx)=>{
          return{
            ...seg, pax_data : ow_group[idx]
          }
        })
        let data = data1.concat(data2)
        let final_seg = data.map((segment, idx)=>{
          return{
            ...segment, segment_id: idx
          }
        })
          return final_seg
      }

}
const handleSegment = (seg1, seg2,sector,list)=>{
let clone_ow_pax = [];
let ow_group=[];
if(state.bkng_det[0].flow_type === "normal"){
if(state.pax_det.length!==0){
  if(sector==="O"){
    clone_ow_pax = _.cloneDeep(state.pax_det[0])
  }else{
    clone_ow_pax = _.cloneDeep(state.pax_det[1])
  }
  ow_group = handleGroup(Object.values(clone_ow_pax), "split_pnr")
}
}else{
  clone_ow_pax = _.cloneDeep(list[0])
  ow_group = handleGroup(Object.values(clone_ow_pax), "split_pnr")
}
let clone_rt_pax = [];
let rt_group = [];
if(state.pax_det.length > 1){
  clone_rt_pax= _.cloneDeep(state.pax_det[1])
  rt_group = handleGroup(Object.values(clone_rt_pax), "split_pnr")
}
  let order1= []
  let order2= []
  if(ow_group.length!==0){
    for(let i=0; i< ow_group.length; i++){
      order1.push(seg1);
    }
  }
  if(seg2 !== undefined && rt_group.length!==0){
  for(let i=0; i< rt_group.length; i++){
      order2.push(seg2);
  }
}
  let data1 = order1.map((seg, idx)=>{
        return{
          ...seg, pax_data : ow_group[idx]
        }
      })

      let data2 = order2.map((seg, idx)=>{
            return{
              ...seg, pax_data : rt_group[idx]
            }
          })
  let data = data1.concat(data2)
let final_seg = data.map((segment, idx)=>{
  return{
    ...segment, segment_id: idx
  }
})
  return final_seg

 }

 const checkselectall = (pax)=>{
   let count = 0
for(let val of pax){
if(val.split_pnr !== null){
count ++
}
if(count == 0){
return true
}
else{
  return false
}
}
 }

 const openIndigoPopup=()=>{
  dispatch({ type: 'selIndigoSector', payload: "R" })
  showIndigoRefundPopup("R");

 }

 //Open Indigo Pop up
const showIndigoRefundPopup=(sector)=>{
  let ow_Seg = []
  let rt_Seg = []
  let cloneSeg =  _.cloneDeep(state.seg_det)
  for(let val of cloneSeg){
    val.selected = false
  }
  dispatch({ type: "showIndigoRefund", payload: true })
  if(state.temp_onwardList.length===0 && state.temp_returnList.length===0){
    dispatch({ type: "temp_onwardList", payload:  [state.pax_det[0]]})
    dispatch({ type: "temp_returnList", payload:  [state.pax_det[1]]})
  }
  let pnr_list=state.pax_det[0].filter((pax)=>pax.split_pnr!==null);
    if(pnr_list.length > 0){
        dispatch({ type: 'showSelectAll', payload: false })
    }
  if(state.bkng_det[0].flow_type == "split"){
    let sectors=[];
    let count=1;
    let list =[];
    for (let seg_ of cloneSeg) {
      if(sector==="O" && seg_.journey_type === "O"){
        ow_Seg.push(seg_);
        sectors.push(ow_Seg[0])
        if(state.temp_onwardList.length!==0){
          list=state.temp_onwardList;
          dispatch({ type: "pax_det", payload:  state.temp_onwardList})
        }else{
          list=[state.pax_det[0]];
          dispatch({ type: "pax_det", payload:  list})
        }
      }else{
        if(sector==="R" && seg_.journey_type === "R"){
          ow_Seg.push(seg_);
          if(sectors.length===0){
            sectors.push(seg_)
          }
          if(state.temp_returnList.length!==0){
            list=state.temp_returnList;
            dispatch({ type: "pax_det", payload:  state.temp_returnList})
          }else{
            list=[state.pax_det[1]];
            dispatch({ type: "pax_det", payload:  list})
          }
        }
      }
      count=count+1;
    }
    if(checkselectall(state.pax_det[0])){
      dispatch({ type: 'isIndigoAll', payload: true })
      for(let val of ow_Seg){
        val.selected = true
      }
    }
    else{
      dispatch({ type: 'isIndigoAll', payload: false })
      for(let val of ow_Seg){
        val.selected = false
      }
    }
  dispatch({ type: "refundSeg", payload: handleSegment(ow_Seg[0], ow_Seg[1] , sector, list)})
  dispatch({ type: 'selIndigoSector', payload: sector })
  dispatch({ type: "showRefndSectors", payload: sectors})
  }
  else{
      let sectors=[];
      let list1=cloneSeg.filter((obj)=>obj.journey_type==="O");
      let list2=cloneSeg.filter((obj)=>obj.journey_type==="R");
      if(state.bkng_det[0].travel_type === "1"){
        list1[0].seg_destination=list1.slice(-1)[0].seg_destination
        ow_Seg.push(list1[0]);
        sectors.push(list1[0])
        if(list2.length!==0){
          list2[0].seg_destination=list2.slice(-1)[0].seg_destination
          rt_Seg.push(list2[0]);
          sectors.push(list2[0])
        }
      }else{
        let onwardSector=checkIndigoRefundAvail("O");
        if(onwardSector){
          list1[0].seg_destination=list1.slice(-1)[0].seg_destination
          ow_Seg.push(list1[0]);
          sectors.push(list1[0])
        }
        if(list2.length!==0){
          let returnSector=checkIndigoRefundAvail("R");
          if(returnSector){
            list2[0].seg_destination=list2.slice(-1)[0].seg_destination
            rt_Seg.push(list2[0]);
            sectors.push(list2[0])
          }
        }
      }
      dispatch({ type: "showRefndSectors", payload: sectors})
    if(rt_Seg.length === 0){
      let ow_final = handleSegmentone(ow_Seg[0], undefined)
      dispatch({ type: "refundSeg", payload: ow_final})
    }
    else{
    let ow_final = handleSegment(ow_Seg[0], undefined,"O" , null)
    let rt_final = handleSegment(rt_Seg[0], undefined,"R" , null)
    let final_seg = ow_final.concat(rt_final)
    dispatch({ type: "refundSeg", payload: final_seg})
    }
  if(sector==="O"){
    if(state.temp_onwardList.length!==0){
      dispatch({ type: "pax_det", payload: state.temp_onwardList})
    }else{
      dispatch({ type: "pax_det", payload: [state.pax_det[0]]})
    }
  }else{
    if(state.temp_returnList.length!==0){
      dispatch({ type: "pax_det", payload: state.temp_returnList})
    }else{
      dispatch({ type: "pax_det", payload: [state.pax_det[1]]})
    }

  }
  }
}

//Retriveing the PNR Details
const retrivePNRData=()=>{
  if(state.insertPnr!==""){
    dispatch({ type: 'isItineraryLoading', payload: true })
    const req={
      "pnr":state.insertPnr,
      "office_id":"DOHQR28IH",
      "region":context.logindata.region_type,
      "req_cur":context.logindata.cur,
      "type": "INSERT_PNR"
    }
    getPNRDetails(req).then((resp) => {
      if (resp.suc) {
        //dispatch({ type: 'pnrResponse', payload: resp })
        dispatch({ type: 'manualResponse', payload: resp })
        dispatch({ type: 'enablePnrResp', payload: true })
        dispatch({ type: 'isItineraryLoading', payload: false })
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessage', payload: 'PNR details not available.' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        setIsLoadingMore(false);
        hidemessage();
      }
    })
  }else{
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Enter PNR' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        setIsLoadingMore(false);
        hidemessage();
  }
}

const validateTktNumber=()=>{
  if(state.insertPax.length!==0){
      let tkts1 = state.insertPax.filter((obj) => obj.tkt === "");
      let tkts2 = state.insertPax.filter((obj) => obj.inf!==undefined && obj.inf.tkt==="");
         if(tkts1.length!==0 || tkts2.length!==0){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Enter Ticket Number'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
         }else{
          return true;
         }
  }else{
    return true;
  }
}

//Inserting the Booking data
const insertBookingData=()=>{
  if(validateTktNumber()){
  if(state.pnrResponse!==""){
    var tid="";
    if (state.bkng_det[0].flow_type === "normal") {
      tid=state.bkng_det[0].tid;
    }else if(state.bkng_det[0].flow_type === "split"){
      var type=state.jtype!=="" && state.jtype==="ONWARD"?"O":"R";
      let segList = state.seg_det.filter(x => x.journey_type === type); //fetch seg details wrt to journey type
      if (segList[0].tid.includes("/")) {
        let tidAry = segList[0].tid.split("/");
        tid=type==="O"?tidAry[0]:tidAry[1];
      } else {
        if (segList.length !== 0) {
          if (segList !== undefined && segList[0] !== undefined) {
            tid = segList[0].tid;
          }
        }
      }
    }else{
      tid=state.bkng_det[0].tid;
    }
    let request=state.pnrResponse.res;
    request.is_newiti="Yes";
    request.bktype=state.bktype;
    request.sty=state.bkng_det[0].flow_type;
    request.bkref = state.bkng_det[0].booking_id;
    request.journey_type=state.jtype;
    request.cid=state.bkng_det[0].company_id;
    request.bid=context.logindata.bid;
    request.utype=context.logindata.utype;
    request.old_tid=tid;
    request.cname=state.bkng_det[0].company_name;
    request.bkby=state.bkng_det[0].booked_by;
    request.butype=context.logindata.butype;
    request.crby=state.bkng_det[0].cr_by;
    request.origns[0].srcorigns[0].cl=state.pnrResponse.res.origns[0].srcorigns[0].cl;
    request.cuscode=context.logindata.cuscode;
    request.psngrs=state.insertPax.length!==0?state.insertPax:state.pnrResponse.res.psngrs;
    request.region_type=state.bk_region_type;
    request.req_cur=state.usr_cur;
    insertPNRData(request).then((resp) => {
      if(resp.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'PNR Data inserted successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        setTimeout(function () {
            return window.location = window.location.pathname
        }, 2000);
      }else{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
    })
  }
}
}

const updatePaxTkts=(data)=>{
  dispatch({ type: 'insertPax', payload: data })
}


 /**
  * calling discard call if fbf is spliited and need to be discarded
  * @author : Azamuddin
  * @date : 30-08-2021
  */
 const discardCall = (resp) => {
   setShow(true)
  dispatch({ type: 'onholdResp', payload: resp.data })
 }
 const sendTid = (tid) => {
   if(tid !== undefined && tid !== ""){
  dispatch({ type: 'splittid', payload: tid })
   }
}
 const openshareevent = () => {
  dispatch({ type: 'showPopup', payload: true })
}
const handleChangeemail = (e) => {
  e.preventDefault();
     let trimmedEmail = e.target.value.trim();
       dispatch({ type: 'email', payload: trimmedEmail })
 }
const sendEmail = ()=>{
  if(validateEmail()){
    if(Number(state.b_status) == 23 || Number(state.b_status) == 36 ){
      if(state.flow_type == "normal"){
        if(state.flt_supplier == "0"){
          if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE/SPLIT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT/SPLIT"){
            if(state.splittid == undefined || state.splittid == ""){
              state.splittid = state.summaryRes[0].bkng_det[0].tid
               }
          const payload = {
            "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
            "tid": state.splittid,
            "email_id":state.email,
            "sup": state.summaryRes[0].bkng_det[0].supplier,
            "jtype": state.summaryRes[0].bkng_det[0].journey_type,
            "ftype": state.summaryRes[0].bkng_det[0].flow_type
           }
           shareItinerary(payload)
          }
          else if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE" || state.summaryRes[0].bkng_det[0].bktype == "DIRECT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT" || state.summaryRes[0].bkng_det[0].bktype == "IMPORT/REISSUE"){
          const payload = {
            "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
            "tid":  state.summaryRes[0].bkng_det[0].tid,
            "email_id":state.email,
            "sup": state.summaryRes[0].bkng_det[0].supplier,
            "jtype": state.summaryRes[0].bkng_det[0].journey_type == "1" ? "O" : "O/R",
            "ftype": state.summaryRes[0].bkng_det[0].flow_type
           }
           shareItinerary(payload)
          }
        }
        else{
          const payload1 = {
            "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
            "tid": state.seg_det[0].trvel_type !== "1" ? state.summaryRes[0].bkng_det[0].tid +"/"+state.summaryRes[0].bkng_det[0].tid.slice(0, -1)+"2" :  state.summaryRes[0].bkng_det[0].tid ,
            "email_id":state.email,
            "sup": state.summaryRes[0].bkng_det[0].supplier,
            "jtype": "O",
            "ftype": state.summaryRes[0].bkng_det[0].flow_type
           }
           shareItinerary(payload1)
           if(state.seg_det[0].trvel_type !== "1"){
            setTimeout(() => {
              const payload2 = {
                "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
                "tid": state.summaryRes[0].bkng_det[0].tid +"/"+state.summaryRes[0].bkng_det[0].tid.slice(0, -1)+"2",
                "email_id":state.email,
                "sup": state.summaryRes[0].bkng_det[0].supplier,
                "jtype": "R",
                "ftype": state.summaryRes[0].bkng_det[0].flow_type
               }
             shareItinerary(payload2)
            }, 10000);
          }
        }
      }
      else{
          if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE/SPLIT"){
            if(state.splittid == undefined){
              state.splittid = state.summaryRes[0].bkng_det[0].tid
               }
          const payload = {
            "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
            "tid": state.splittid,
            "email_id":state.email,
            "sup": state.fbfreturn ? state.flt_supplier_return : state.flt_supplier,
            "jtype": state.fbfreturn ? "R" : "O",
            "ftype": "split"
           }
           shareItinerary(payload)
          }
        else if(state.summaryRes[0].bkng_det[0].bktype == "REISSUE" || state.summaryRes[0].bkng_det[0].bktype == "DIRECT" ){
          const payload = {
            "booking_id": state.summaryRes[0].bkng_det[0].booking_id,
            "tid": state.fbfreturn ? state.pax_det[1][0].tid : state.pax_det[0][0].tid,
            "email_id":state.email,
            "sup": state.fbfreturn ? state.flt_supplier_return : state.flt_supplier ,
            "jtype":state.fbfreturn ? "R" : "O",
            "ftype": "split"
           }
           shareItinerary(payload)
        }
      }
    }
  }
}
const validateEmail = ()=>{
  if(state.email == ""){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false
   }
   else if(!emailValidation(state.email)){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false
   }
   return true
 }
 const shareItinerary = (data) =>{
  sendConfirmationObj(data).then(response=>{
    if(response.suc){
      dispatch({ type: 'email', payload: "" })
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: response.res.data })
      dispatch({ type: 'notiVarient', payload: 'success' })
      dispatch({ type: 'splittid', payload: "" })
      hidemessage();
      dispatch({ type: 'fbfreturn', payload: false })
    }
   }).catch(function (error) {
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
    dispatch({ type: 'splittid', payload: "" })
    dispatch({ type: 'fbfreturn', payload: false })
    log.error(
      "Exception occured in getCompanyList function---" + error
    );
  });
 }

 const handleemailClose = ()=>{
  dispatch({ type: 'showPopup', payload: false })
  dispatch({ type: 'email', payload: "" })
  dispatch({ type: 'splittid', payload: "" })
  dispatch({ type: 'fbfreturn', payload: false })
 }
 const fbfReturn = ()=>{
  dispatch({ type: 'fbfreturn', payload: true })
 }
 const closeIndigoRefund=()=>{
  dispatch({ type: 'showIndigoRefund', payload: false })
  dispatch({ type: 'isIndigoAll', payload: false })
  dispatch({ type: 'indigo_refund_type', payload: true })
  window.location=window.location.pathname;
 }

 const closeDialog=()=>{
  dispatch({ type: 'enableManualReissue', payload: false })
 }
 const handleonHoldreturn =()=>{
  dispatch({ type: 'showOnholdcontinue', payload: false })
  dispatch({ type: 'showOnholdrtcontinue', payload: false })
 }

 const showCustReq=()=>{
  dispatch({ type: 'showCustReqPopup', payload: true })
 }

 const closeCustDlg = ()=>{
  dispatch({ type: 'showCustReqPopup', payload: false })
 }

 const setTypeRequest = (obj)=>{
  dispatch({type: "req_type", payload:obj.value})
  dispatch({type: "selReqtype", payload:[obj]})
}

const handleChangeDesc = (e) => {
  const { id, value } = e.target;
  dispatch({ type: id, payload: value });
}

const sendB2CRequest=()=>{
  if(state.req_type!==""){
    const obj={
      "user_id": context.logindata.uid,
      "booking_id": state.bkng_det[0].booking_id,
      "description": state.req_desc,
      "created_by": context.logindata.uid,
      "cid":context.logindata.cid,
      "cname":state.bkng_det[0].company_name,
      "request_type": state.req_type,
      "region_type":state.bk_region_type,
      "req_cur":state.usr_cur
    }
    sendRequest(obj).then((resp) => {
      if(resp.suc){
        dispatch({ type: 'showCustReqPopup', payload: false })
        dispatch({ type: 'selReqtype', payload: [] })
        dispatch({ type: 'req_desc', payload: "" })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Request sent successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }else{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
      }
    })
  }else{
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Request Type' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
  }
}

const updateIndAllSegs=()=>{
  for(let seg of state.refundSeg){
    if(seg.seg_status==="23"){
      if(state.isIndigoAll){
        seg.selected=false;
        dispatch({ type: 'isIndigoAll', payload: false })
      }else{
        seg.selected=true;
        dispatch({ type: 'isIndigoAll', payload: true })
      }
    }
  }

}

const handlesameseg = (id, segments)=>{
  let filtered = segments.filter(val=> val.segment_id !== id.segment_id && val.segid===id.segid)
  let count = 0
  for(let val of filtered){
    if(val.selected == true)
    count++
  }
  if(count == 0){
    return false
  }
  else{
    dispatch({ type: 'showmessage', payload: true })
    return true

  }
  }

  const checkSegment=(segment)=>{
    var pnr="";
    if(state.bkng_det[0].flow_type === "split"){
      pnr=segment.split_airline_pnr!==null && segment.split_airline_pnr!==undefined?segment.split_airline_pnr:segment.pax_data[0].normal_airline_pnr;
    }else{
      pnr=segment.pax_data[0].split_pnr!==null && segment.pax_data[0].split_pnr!==undefined?segment.pax_data[0].split_pnr:segment.pax_data[0].gds_pnr;
    }
     if(segments.length===0){
     let list=[];
     list.push(pnr);
     setSegments(list);
   }else{
    if(segments.includes(pnr)){
      let pnrs=segments;
      if(segment.selected){
        pnrs.splice(0,1);
        setSegments(pnrs);
      }else{
        let pnrs=segments;
        pnrs.push(pnr);
        setSegments(pnrs);
      }
      return false
    }else{
      dispatch({ type: 'showmessage', payload: true })
      return true
    }
   }
  }

const updateIndigoSegment=(segment)=>{
  let count = 0;
  if(checkSegment(segment)){
return
  }
  let final_seg = []
  for(let seg of state.refundSeg){
    if(seg.seg_status==="23"){
      if(segment.segment_id == seg.segment_id && seg.segid == segment.segid){
        if(seg.selected){
          seg.selected=false;
          dispatch({ type: 'showmessage', payload: false })
        }else{
          seg.selected=true;
          dispatch({ type: 'showmessage', payload: false })
        }
}
  if(seg.selected == true){
    count++
  }
  final_seg.push(seg)
}
}
if(count == state.refundSeg.length){
  dispatch({ type: 'isIndigoAll', payload: true })
}else{
  dispatch({ type: 'isIndigoAll', payload: false })
}
dispatch({ type: 'refundSeg', payload: final_seg })
}
  const handleGroup = (pax, pnr) => {
    let grouped = [];
    for (let i=0; i<pax.length; i++) {
      var p = pax[i][pnr];
      if (!grouped[p]) { grouped[p] = []; }
      grouped[p].push(pax[i]);
    }
  return Object.values(grouped)
}

const updateIndRefundType = () => {

  if(state.bkng_det[0].flow_type == "split"){
      dispatch({ type: "pax_det", payload:  state.temp_returnList})
  }
  if(state.indigo_refund_type){
    dispatch({ type: 'indigo_refund_type', payload: false })
    dispatch({ type: 'isIndigoAll', payload: false })
    for(let seg of state.refundSeg){
      seg.selected=false;
   }
  }else{
    dispatch({ type: 'indigo_refund_type', payload: true })
    indigopaxList=[];
    selPNRS=[]
        for(let seg of state.refundSeg){
           seg.selected=false;
        }
  }
  if(state.bkng_det[0].flow_type == "split"){
    if(state.selIndigoSector==="O" && state.temp_onwardList.length!==0){
      dispatch({ type: "pax_det", payload:  state.temp_onwardList})
    }else if(state.selIndigoSector==="R" && state.temp_returnList.length!==0){
      dispatch({ type: "pax_det", payload:  state.temp_returnList})
    }
  }
}

const updateIndPax = (obj,type) => (e) => {
  const { id, value } = e.target;
  dispatch({ type: 'showmessage', payload: false })
    if (indigopaxList.indexOf(Number(id)) > -1) {
      const index = indigopaxList.indexOf(Number(id));
      if (index > -1) {
        indigopaxList = indigopaxList.filter(x => x !== Number(id))
      }
      let chdTkt = ""
      if(obj.pax_type === "ADT") {
        for (let val of state.pax_det[0]) {
          if (val.pax_type === "INF" && val.amedeus_ref_num === obj.amedeus_ref_num) {
                const idx = indigopaxList.indexOf(val.trv_id)
                if(idx > -1) {
                    chdTkt = val.trv_id
                    indigopaxList = indigopaxList.filter(x => x !== val.trv_id);
                }
            }
        }
    }

    if(obj.pax_type === "CHD"){
      for (let val of state.pax_det[0]) {
        if (val.pax_type === "CHD" && val.amedeus_ref_num === obj.amedeus_ref_num) {
              const idx = indigopaxList.indexOf(val.trv_id)
              if(idx > -1) {
                  chdTkt = val.trv_id
                  indigopaxList = indigopaxList.filter(x => x !== val.trv_id);
              }
          }
      }
    }

    if(obj.pax_type === "INF") { //removal of adt if inf is selected
      for (let val of state.pax_det[0]) {
          if (val.pax_type === "ADT" && val.amedeus_ref_num === obj.amedeus_ref_num) {
              const idx = indigopaxList.indexOf(val.trv_id)
              if(idx > -1) {
                  chdTkt = val.trv_id
                  indigopaxList = indigopaxList.filter(x => x !== val.trv_id);
                  if(indigopaxList.length===0){
                    selPNRS=[];
                  }
              }
          }
      }
  }
  selPNRS = selPNRS.filter(x => x.trv_id !== Number(id))
  if(state.pax_det[0].length===indigopaxList.length){
    dispatch({ type: 'enableIndigoRefund', payload: true })
  }else{
    dispatch({ type: 'enableIndigoRefund', payload: false })
  }
  if(state.pax_det[0].length===1){
    dispatch({ type: 'enableIndigoRefund', payload: true })
  }
}else{
  let pnr=obj.gds_pnr;
  let isSplit=false;
  let check=true;
  if(obj.split_pnr!==null){
    pnr=obj.split_pnr;
    isSplit=true;
  }
 if(selPNRS.length===0 || checkPNR(pnr,type)){
  selPNRS.push(obj);
  for(let pax of state.pax_det[0]){
    if(pax.sts==="Confirmed" && pax.trv_id===obj.trv_id){
      if(pax.pax_type==="ADT"){
        let list=state.pax_det[0].filter((pax)=>pax.pax_type === "INF" &&  pax.amedeus_ref_num === obj.amedeus_ref_num)
        indigopaxList.push(pax.trv_id);
        if(list.length!==0){
          indigopaxList.push(list[0].trv_id);
        }
      }else{
        indigopaxList.push(pax.trv_id);
        if(pax.pax_type==="INF"){
        let list=state.pax_det[0].filter((pax)=>pax.pax_type === "ADT" &&  pax.amedeus_ref_num === obj.amedeus_ref_num)
        if(list.length!==0){
          indigopaxList.push(list[0].trv_id);
        }
        }
      }
    }
  }
  }else{
    check=false
    dispatch({ type: 'showmessage', payload: true })
  }

  if(check && (state.pax_det[0].length===1 || state.pax_det[0].length===indigopaxList.length)){
    dispatch({ type: 'enableIndigoRefund', payload: true })
  }else if(check){
    let pnr_list=state.pax_det[0].filter((pax)=>pax.split_pnr!==null &&  pax.split_pnr === obj.split_pnr);
    if(pnr_list.length > 0 && pnr_list.length===indigopaxList.length){
      dispatch({ type: 'enableIndigoRefund', payload: true })
    }else{
      let list=state.pax_det[0].filter((pax)=>pax.gds_pnr!==null && pax.split_pnr===null && pax.gds_pnr === obj.gds_pnr);
      if(list.length > 0 && list.length===indigopaxList.length){
        dispatch({ type: 'enableIndigoRefund', payload: true })
      }else{
        dispatch({ type: 'enableIndigoRefund', payload: false })
      }
    }
  }

}
}

const checkPNR=(pnr,type)=>{
  for(let pax of selPNRS){
    if(pax.split_pnr!==null && type==="Split" && pax.split_pnr===pnr){
      return true;
    }else if(pax.split_pnr===null && type==="GDS" && pax.gds_pnr===pnr){
      return true;
    }else{
      return false;
    }
  }
}
const createSegList=(seg)=>{
  let segments=[];
  let list=state.seg_det.filter((obj)=>obj.journey_type===seg.journey_type);
  for(let s of list){
     const obj={
      "dep":s.seg_source,
      "arr":s.seg_destination
     }
     segments.push(obj)
  }
  return segments;
}

const checkSeg=()=>{
  let check=false;
  for(let seg of state.refundSeg){
    if(seg.selected){
      check=true;
     break;
    }
  }
  return check;
}

const getIndigoRefundDetails=()=>{
  let paxList=[];
  let segList=[];
  let splitTid="";
  let splitOfcid="";
  let pssengersList=[];
  var pnr = "";
  dispatch({ type: 'isItineraryLoading', payload: true })
  if((state.indigo_refund_type && indigopaxList.length!==0) || (!state.indigo_refund_type && checkSeg())){
  if(state.indigo_refund_type && indigopaxList.length!==0){
    if(indigopaxList.length!==0){
      for(let pax of indigopaxList){
          if(state.bkng_det[0].flow_type==="normal"){
            let paxInfo=state.pax_det[0].filter((obj)=>obj.trv_id===Number(pax));
            pssengersList = state.pax_det[0].filter((obj)=>obj.trv_id===Number(indigopaxList[0]));
            const req={
              "trv_id":Number(pax),
              "amd_ref_num":paxInfo[0].amedeus_ref_num,
              "pname": paxInfo[0].pax_name,
              "ptype":paxInfo[0].pax_type
            }
            paxList.push(req);
            if(state.temp_paxList[1]!==undefined && state.temp_paxList[1].length!==0){
              let paxInfo1=state.temp_paxList[1].filter((obj)=>Number(obj.paxsno)===Number(paxInfo[0].paxsno) && obj.sts==="Confirmed" && paxInfo[0].pax_type===obj.pax_type);
              if(paxInfo1.length!==0 && Number(pax)!==Number(paxInfo1[0].trv_id)){
                const request={
                  "trv_id":paxInfo1[0].trv_id,
                  "amd_ref_num":paxInfo1[0].amedeus_ref_num,
                  "pname": paxInfo1[0].pax_name,
                  "ptype":paxInfo1[0].pax_type
                }
                paxList.push(request);
              }else{
                if(state.bkng_det[0].travel_type === "2"){
                  let paxInf=state.temp_paxList[0].filter((obj)=>Number(obj.paxsno)===Number(paxInfo[0].paxsno) && obj.sts==="Confirmed" && paxInfo[0].pax_type===obj.pax_type);
                  if(paxInf.length!==0){
                    const request={
                      "trv_id":paxInf[0].trv_id,
                      "amd_ref_num":paxInf[0].amedeus_ref_num,
                      "pname": paxInf[0].pax_name,
                      "ptype":paxInf[0].pax_type
                    }
                    paxList.push(request);
                  }
                }
              }
            }
          }else{
           if(state.selIndigoSector==="O"){
            let paxInf=state.temp_paxList[0].filter((obj)=>obj.trv_id===Number(pax));
            pssengersList = state.temp_paxList[0].filter((obj)=>obj.trv_id===Number(indigopaxList[0]));
            if(paxInf.length!==0){
            const req={
              "trv_id":Number(pax),
              "amd_ref_num":paxInf[0].amedeus_ref_num,
              "pname": paxInf[0].pax_name,
              "ptype":paxInf[0].pax_type
            }
            paxList.push(req);
            splitTid=paxInf[0].tid;
            splitOfcid=paxInf[0].office_id;
            }
           }else{
            let paxListRTet=state.temp_paxList[1].filter((obj)=>obj.trv_id===Number(pax));
            pssengersList = state.temp_paxList[1].filter((obj)=>obj.trv_id===Number(indigopaxList[0]));
            if(paxListRTet.length!==0){
            const req1={
              "trv_id":paxListRTet[0].trv_id,
              "amd_ref_num":paxListRTet[0].amedeus_ref_num,
              "pname": paxListRTet[0].pax_name,
              "ptype":paxListRTet[0].pax_type
            }
            paxList.push(req1);
            splitTid=paxListRTet[0].tid;
            splitOfcid=paxListRTet[0].office_id;
            }
           }
          }
      }
      }
  }else if(!state.indigo_refund_type){
    for(let seg of state.refundSeg){
      if(seg.selected){
        const req={
          "sector": seg.seg_source+"-"+seg.seg_destination,
          "da": seg.depart_time,
          "ad": seg.arrival_time,
          "fn": seg.flt_no,
          "seg":createSegList(seg)
        }
        pnr = seg.pax_data[0].split_pnr!==null?seg.pax_data[0].split_pnr:seg.pax_data[0].gds_pnr;
        segList.push(req)
        if(seg.journey_type==="O"){
          splitTid=seg.tid;
          splitOfcid=seg.office_id;
          let paxInfo = [];
          if(state.selIndigoSector==="O"){
            paxInfo=seg.pax_data.filter((obj)=>obj.sts==="Confirmed");
          }else{
            paxInfo=state.pax_det[0].filter((obj)=>obj.sts==="Confirmed"  && obj.split_pnr!==null && obj.split_pnr===pnr);
            if(paxInfo.length===0){
              paxInfo=state.pax_det[0].filter((obj)=>obj.sts==="Confirmed" && obj.split_pnr===null && obj.gds_pnr===pnr);
            }
          }
          for(let val of paxInfo){
            const req={
              "trv_id":val.trv_id,
              "amd_ref_num":val.amedeus_ref_num,
              "pname": val.pax_name,
              "ptype":val.pax_type
            }
            paxList.push(req);
          }
        }else{
          splitTid=seg.tid;
          splitOfcid=seg.office_id;
          let paxInfo1=[];
          if(state.temp_paxList.length > 1){
            paxInfo1=state.temp_paxList[1].filter((obj)=>obj.sts==="Confirmed" && obj.journey_type === "R" && obj.split_pnr!==null && obj.split_pnr===pnr);
            if(paxInfo1.length===0){
              paxInfo1=state.temp_paxList[1].filter((obj)=>obj.sts==="Confirmed" && obj.journey_type === "R" && obj.split_pnr===null && obj.gds_pnr===pnr);
            }
            if(paxList.length!==0){
              let paxInfList = [];
             for(let val of paxList){
             let paxInfo=paxInfo1.filter((obj)=>obj.pax_name===val.pname);
             paxInfList.push(paxInfo[0])
             }
             if(paxInfList.length!==0){
              paxInfo1 = paxInfList;
             }
            }
          }else{
            paxInfo1=state.pax_det[0].filter((obj)=>obj.sts==="Confirmed"  && obj.split_pnr!==null && obj.split_pnr===pnr);
            if(paxInfo1.length===0){
              paxInfo1=state.pax_det[0].filter((obj)=>obj.sts==="Confirmed" && obj.split_pnr===null && obj.gds_pnr===pnr);
            }
          }
          for(let val of paxInfo1){
          const req1={
            "trv_id":val.trv_id,
            "amd_ref_num":val.amedeus_ref_num,
            "pname": val.pax_name,
            "ptype":val.pax_type
          }
          paxList.push(req1);
        }
        }
        }
    }
  }
  if(state.isIndigoAll && parseInt(state.refundSeg[0].trvel_type)===2){
  let newPaxList=[];
  let paxInfo=state.temp_paxList[0].filter((obj)=>obj.sts==="Confirmed");
  for(let val of paxInfo){
    const req1={
      "trv_id":val.trv_id,
      "amd_ref_num":val.amedeus_ref_num,
      "pname": val.pax_name,
      "ptype":val.pax_type
    }
    newPaxList.push(req1);
  }
  let paxInfo1=state.temp_paxList[1].filter((obj)=>obj.sts==="Confirmed");
  for(let val of paxInfo1){
    const req1={
      "trv_id":val.trv_id,
      "amd_ref_num":val.amedeus_ref_num,
      "pname": val.pax_name,
      "ptype":val.pax_type
    }
    newPaxList.push(req1);
  }
  paxList = newPaxList;
  }
   let list=paxList.filter((obj)=>obj.ptype!=="INF");
   let tid="";
   let ofcid="";
   if(state.bkng_det[0].flow_type==="normal"){
    if(state.temp_paxList[0].length!==0){
      tid=state.temp_paxList[0][0].tid;
      ofcid=state.temp_paxList[0][0].office_id;
    }
    if(state.temp_paxList.length > 1){
      tid=tid+"/"+state.temp_paxList[1][0].tid;
      ofcid=ofcid+"/"+state.temp_paxList[1][0].office_id;
    }
   }else{
     tid=splitTid;
     ofcid=splitOfcid;
   }
   //Added index to all Pax

   let paxIndexList = [];
   if(state.indigo_refund_type){
   if(state.bkng_det[0].flow_type==="normal"){
   if(pssengersList[0].split_pnr!==null){
    paxIndexList = state.pax_det[0].filter((obj)=>obj.split_pnr===pssengersList[0].split_pnr);
    pnr=pssengersList[0].split_pnr;
   }else{
    paxIndexList = state.pax_det[0].filter((obj)=>obj.gds_pnr===pssengersList[0].gds_pnr && obj.split_pnr===null);
    pnr=pssengersList[0].gds_pnr;
   }
  }else{
    if(state.selIndigoSector==="O"){
      if(pssengersList[0].split_pnr!==null){
        paxIndexList = state.temp_paxList[0].filter((obj)=>obj.split_pnr===pssengersList[0].split_pnr);
        pnr=pssengersList[0].split_pnr;
       }else{
        paxIndexList = state.temp_paxList[0].filter((obj)=>obj.gds_pnr===pssengersList[0].gds_pnr && obj.split_pnr===null);
        pnr=pssengersList[0].gds_pnr;
       }
    }else{
      if(pssengersList[0].split_pnr!==null){
        paxIndexList = state.temp_paxList[1].filter((obj)=>obj.split_pnr===pssengersList[0].split_pnr);
        pnr=pssengersList[0].split_pnr;
       }else{
        paxIndexList = state.temp_paxList[1].filter((obj)=>obj.gds_pnr===pssengersList[0].gds_pnr && obj.split_pnr===null);
        pnr=pssengersList[0].gds_pnr;
       }
    }
  }
   paxIndexList = paxIndexList.map((currElement, index) => {
    currElement.paxind=index;
    return currElement; //equivalent to list[index]
  });
   for(let pax of paxList){
    for(let p of paxIndexList){
     if(pax.pname===p.pax_name){
      pax.pax_index=p.paxind;
     }
    }
   }
  }
   let uniqPax = getUnique(paxList,"trv_id");
  const payload = {
    "tid": tid,
    "rtype": state.indigo_refund_type?"Passenger":"Segment",
    "btype": state.enableIndigoRefund?"Full":"Partial",
    "cancelPnr": false,
    "pnr": pnr,
    // "ofcid": "DOHQR2718",
    "office_id": ofcid,
    // "office_id": ow_ofcid,
    "pax_cnt":state.indigo_refund_type && state.bkng_det[0].travel_type === "2"?list.length/2:list.length,
    "butype": context.logindata.butype,
    "tkts": uniqPax,
    "seg_info":segList,
    "booking_id": state.bkng_det[0].booking_id,
    "ac": state.aircodes!==""?state.aircodes:"6E",
    "dep": state.pax_det[0][0].source,
    "arr": state.pax_det[0][0].destination,
    "cid": state.bkng_det[0].company_id,
    "pcid": (context.logindata.pcid !== null) ? context.logindata.pcid : 0,
    "tt": parseInt(state.refundSeg[0].trvel_type),
    "cl": state.refundSeg[0].cabin_class,
    "supplier": 4,
    "dd": DateUtils.prettyDate(state.refundSeg[0].depart_time, "YYYY-MM-DD"),
    "ad": DateUtils.prettyDate(state.refundSeg[0].arrival_time, "YYYY-MM-DD"),
    "jtype": state.bkng_det[0].travel_type === "1" ? "O" : "O/R",
    "rbd": "",
    "ftype": "Refund",
    "bid": context.logindata.bid,
    "cus_code": state.cus_code,
    "bktype" : state.bkng_det[0].bktype,
    "region_type": state.bk_region_type,
    "req_cur": state.usr_cur
  }
  getIndigoRefundPricing(payload).then((resp) => {
    if (resp.refundPricingRes.suc && resp.refundPricingRes.data !== undefined) {
      setIsLoadingMore(false);
      resp.refundPricingRes.region_type=state.bk_region_type;
      resp.refundPricingRes.req_cur=state.usr_cur;
      history.push({
        pathname:'/ezytrip/bookingsummary/flight/lcc-refund',
        state: {
          req: resp.refundPricingRes
        }
      });
    } else {
      setIsLoadingMore(false);
      dispatch({ type: 'isItineraryLoading', payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  })
}else{
  dispatch({ type: 'isItineraryLoading', payload: false })
  dispatch({ type: 'notiMessageShow', payload: true })
  dispatch({ type: 'notiMessage', payload: 'Please select atleast One Pax OR Segement and proceed for the Refund' })
  dispatch({ type: 'notiVarient', payload: 'danger' })
  hidemessage();
}
}

const refundpopupReturn = ()=>{
  let ow_Seg = []
  let rt_Seg = []
  let cloneSeg =  _.cloneDeep(state.seg_det)
  for(let val of cloneSeg){
    val.selected = false
  }
  dispatch({ type: "showIndigoRefund", payload: true })
  if(state.bkng_det[0].flow_type == "split"){
    for (let seg_ of cloneSeg) {
      if(seg_.journey_type === "R"){
        rt_Seg.push(seg_);
    }
    }
    if(checkselectall(state.pax_det[1])){
      dispatch({ type: 'isIndigoAll', payload: true })
      for(let val of rt_Seg){
        val.selected = true
      }
    }
    else{
      dispatch({ type: 'isIndigoAll', payload: false })
      for(let val of rt_Seg){
        val.selected = false
      }
    }
  dispatch({ type: "refundSeg", payload: handleSegment(rt_Seg[0], rt_Seg[1])})
  }
  else{
    for (let seg_ of cloneSeg) {
      if(seg_.journey_type === "O"){
        ow_Seg.push(seg_);
    }
    }
    for (let seg_ of cloneSeg) {
      if(seg_.journey_type === "R"){
        rt_Seg.push(seg_);
    }
    }
 let ow_final = handleSegment(ow_Seg[0], ow_Seg[1])
 let rt_final = handleSegment(rt_Seg[0], rt_Seg[1])
 let final_seg = ow_final.concat(rt_final)
 dispatch({ type: "refundSeg", payload: final_seg})
  }
}

const loadOfflineBooking=()=>{
  return window.location=window.location.pathname;
}

/**
     * @description:below function call when we click on pax check box on popup
     * @author:Lakshmi
     * @date:17-05-2021
    */
 const selectedIndigoPNR = (selPnr) => (e) => {
  const { id, value } = e.target;
  let list = state.indigoDocsList;
  for(let val of list){
    var pnr=val.split_pnr!==null?val.split_pnr:val.pnr;
    if(pnr===selPnr){
      val.selected=true;
    }else{
      val.selected=false;
    }
  }
  dispatch({ type: 'indigoDocsList', payload: list })
  dispatch({ type: 'enable_pdf', payload: true })
  indigoPdfdownloadonClick();
}




   return (
     <>
       {state.isLoading ? (<Processing onhold='yes' />
       ) : ""}
       {state.isvoidSplitLoading ? (<ProcessingVoidSPlitPNR/>
       ) : ""}

       {isLoadingMore ? (<ProcessingReissue tag={state.enableReissue ? "REISSUE" : "REFUND"} />
       ) : ""}
       {state.comp_id !== 0 &&
         <Header />
       }
       {isArrayNotEmpty(state.bkng_det) && isArrayNotEmpty(state.seg_det) && isArrayNotEmpty(state.fare_det) && isArrayNotEmpty(state.airport_data) ? (
         <>
           {state.notiMessageShow &&
             <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
               onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
               dismissible> {state.notiMessage}</Alert>
           }

           <div className="container confirmation">
           <div className="summarytopSec">
            {window.sessionStorage.getItem(IS_LATEST)!==null &&
               <Button
                 variant="link"
                className="backbtn ezyIcon icon-rightArrow"
                onClick={handleCancel}
              >Back</Button>
            }
               <div className="printSec">
                 {/* <Button
                       size="xs"
                       variant="primary"
                       className="mr-3"
                     >Add New Booking</Button> */}

{ (state.showitinerary && state.summaryRes[0].bkng_det[0].booking_status !== "22" && state.summaryRes[0].bkng_det[0].booking_status !== "34" && state.summaryRes[0].bkng_det[0].booking_status !== "21" && state.summaryRes[0].bkng_det[0].booking_status !== "35" && state.summaryRes[0].bkng_det[0].booking_status !== "25") &&<Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link">Print Summary</Button>}
                {/* <span className="moreDetails share onclickTag ezyIcon icon-share" ref={ref} onClick={openshare}>
                   <div className={"showDetails " + (share ? 'active' : 'inactive')}>
                     <ul className="d-flex flex-column">
                       <li>
                         <Link to="#">Itinerary</Link>
                       </li>
                       <li>
                         <Link to="#">Invoice</Link>
                       </li>
                       <li>
                         <Link to="#">E-Ticket</Link>
                       </li>

                     </ul>
                   </div>
                 </span> */}
               </div>
             </div>
             {/* <div className="summarytopSec">
             <span>Customer Alert :<br/></span>
             {state.bkng_det[0].channel==="B2C" && state.bkng_det[0].ureq!==undefined &&  state.bkng_det[0].ureq.map((req, index) => (
             <span>{req.request_type} <br/></span>
             ))}
             </div> */}
             {/* <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
                 <FlightInvoice  bookingData={state.bkng_det[0]} response={invResp} airport_data={state.airport_data} />
                 {/* <FlightInvoice  bookingData={state.bkng_det[0]} response={state.flightDocsData} airport_data={state.airport_data} /> */}
             {/* </PDFViewer> */}
             {state.bkng_det[0]!==undefined && state.bkng_det[0].booking_status === "34" && state.pax_det[0] !== null && state.pax_det[0]!== undefined && state.pax_det[0][0]!==undefined && state.pax_det[0][0].imp_err !== undefined && state.pax_det[0][0].imp_err !== null && <Alert variant="danger">
          This is invalid booking due to  {state.pax_det[0][0].imp_err}  </Alert>}

             {!state.no_trip && (
               <>
                 <div className="resultSection boxShadow bg-white summary  bookingSummery mb-4">
                  <h5 className="title d-flex justify-content-between" ref={ref}>Booking Details
                  {/* {state.bkng_det[0].booking_status != "21" && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more"  onClick={openMOre}>

                     <div className={"showDetails " + (editMore ? 'active' : 'inactive')}>
                       <ul className="d-flex flex-column"> */}
                         {/* <li>
                               <Link to="#">Edit Details</Link>
                             </li>
                             <li>
                               <Link to="#">Add Reminder</Link>
                             </li>
                             <li>
                               <Link to="#">Amend Booking</Link>
                             </li>
                             <li>
                               <Link to="#">Cancel Booking</Link>
                             </li> */}



                         {/* {state.bktype !== 'IMPORT'  && state.bktype !== 'IMPORT/SPLIT'&& state.bktype !== 'IMPORT/REISSUE' && state.flow_type === 'normal' && state.flt_supplier === 0 && context.logindata.roles.includes('B_S_T_R') && (state.bkng_det[0].booking_status == "23" || state.bktype === 'IMPORT' || state.reIssueResponse.length !== 0) && state.enableConfirmFunctions &&
                           <li>
                             <Link to="#" onClick={handleReissueTicket}>Reissue Ticket</Link>
                           </li>
                         } */}

                         {/* {state.flow_type === 'normal' && (state.bkng_det[0].booking_status != "22" && state.bkng_det[0].booking_status != "21" && state.bkng_det[0].booking_status != "35") &&
                           <li>
                             <Link to="#" onClick={() => handledownloaddocs(state.pax_det[0])}>Download Docs</Link>
                             {/* <Link to="#" onClick={() => handledownloaddocs(state.pax_det[0])} >Download Docs new</Link> */}

                           {/* </li> */}
                         {/* }  */}

                       {/* </ul>
                     </div>  </span>
                    } */}
                    </h5>

                   {/* Booking Details Section */}
                   {isArrayNotEmpty(state.bkng_det) && (<FlightSummaryBookingDetails bk_det={state.bkng_det} isEdit={state.enableEditBooking} sendBookStatus={updateBstatus} utype={context.logindata.utype} return_supplier={state.normalTFtwoLcc ? state.flt_supplier : state.flt_supplier_return} onward_supplier={state.flt_supplier}/>)}
                 </div>
                 {state.bktype !== 'IMPORT' && state.bktype !== 'IMPORT/SPLIT'&& state.bktype !== 'IMPORT/REISSUE' &&

                   <div ref={voidCncl} className="resultSection bg-white boxShadow mb-4">
                     <div  className="flightdBg">
                     <ShowHide visible="true" icon="" title="Flight Details">
                       {/* Segment Details Section */}
                       {state.bktype !== 'IMPORT' &&

                         <div className="showHide-content pl-0 pr-0 pb-0">

                          <div className="clickTOShow d-flex">
                          { (state.showitinerary && state.summaryRes[0].bkng_det[0].booking_status !== "47" && state.summaryRes[0].bkng_det[0].booking_status !== "22" && state.summaryRes[0].bkng_det[0].booking_status !== "34" && state.summaryRes[0].bkng_det[0].booking_status !== "21" && state.summaryRes[0].bkng_det[0].booking_status !== "35" && state.summaryRes[0].bkng_det[0].booking_status !== "25") &&<div className='socialIcons'>
                           <Button className="prient ezyIcon icon-share" onClick={openshareevent} variant="link"></Button>
                </div>}
                {state.summaryRes[0].bkng_det[0].booking_status !== "47" && state.bkng_det[0].booking_status != "21" && state.bkng_det[0].booking_status != "25" && state.bkng_det[0].booking_status != "35" && state.bkng_det[0].booking_status != "34" && <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openeditItinerary}>
                             <div className={"showDetails " + (editItinerary ? 'active' : 'inactive')}>
                               <ul className="d-flex flex-column">
                                  {state.bkng_det[0].booking_status != "22" && state.bktype !== 'IMPORT' && state.bktype !== 'IMPORT/SPLIT'&& state.bktype !== 'IMPORT/REISSUE' && context.logindata.roles.includes('B_S_V_B') && ((state.flow_type == "normal" && state.flt_supplier === 0) || (state.flow_type == "split" && (state.flt_supplier === 0 || state.flt_supplier_return === 0))) && state.enableConfirmFunctions &&
                                    <li>
                                      <Link to="#" onClick={handleVoidBooking("Onward")}>Void Tickets</Link>
                                    </li>
                                  }
                                  {state.bkng_det[0].booking_status != "22" && state.bktype !== 'IMPORT' && state.bktype !== 'IMPORT/SPLIT'&& state.bktype !== 'IMPORT/REISSUE' && context.logindata.roles.includes('B_S_V_C') && ((state.flow_type == "normal" && state.flt_supplier === 0) || (state.flow_type == "split" && (state.flt_supplier === 0 || state.flt_supplier_return === 0))) && state.enableConfirmFunctions &&
                                    <li>
                                      <Link to="#" onClick={handleVoidCancel("Onward")}>Void/Cancel</Link>
                                    </li>
                                  }
                                  {state.bkng_det[0].booking_status != "22" && state.bktype !== 'IMPORT' && state.bktype !== 'IMPORT/SPLIT'&& state.bktype !== 'IMPORT/REISSUE' && state.showRefund && context.logindata.roles.includes('B_S_R_T')  && ((state.flow_type == "normal" && state.flt_supplier === 0) || (state.flow_type == "split" && (state.flt_supplier === 0 || state.flt_supplier_return === 0))) && state.enableConfirmFunctions &&
                                   <li>
                                    <Link to="#" onClick={handleRefundTicket("Onward")}>Refund Ticket</Link>
                                   </li>
                                  }
                                  {state.flt_supplier === 0 && context.logindata.roles.includes('B_S_T_R') && state.bkng_det[0].booking_status == "23" && !state.onward_hold_varient && state.showReissuelink &&
                                   <li>
                                     <Link to="#" onClick={handleReissueTicket("Onward")}>Reissue Ticket</Link>
                                   </li>
                                 }
                                 {context.logindata.roles.includes('F_E_B_S') && state.enableOnwardIti && (state.bkng_det[0].booking_status == "34" || state.bkng_det[0].booking_status == "36") && !state.onward_hold_varient &&
                                   <li>
                                     <Link to="#" onClick={handleEdiyBooking}>Edit Booking</Link>
                                   </li>
                                 }
                                 {(state.bkng_det[0].booking_status != "22" && state.bkng_det[0].booking_status != "21" && state.bkng_det[0].booking_status != "35") &&
                                   <li>
                                     <Link to="#" onClick={() => handledownloaddocs(state.pax_det[0])}>Download Docs</Link>
                                     {/* <Link to="#" onClick={() => handledownloaddocs(state.pax_det[0])} >Download Docs new</Link> */}
                                   </li>
                                 }
                                 {(state.bkng_det[0].booking_status == "22") &&
                                   <li>
                                     <Link to="#" onClick={() => handledItinerary(state.pax_det[0])}>Download Itinerary</Link>
                                   </li>
                                 }

                                 {((state.bkng_det[0].booking_status == "23" && state.flt_supplier === 0 && context.logindata.roles.includes('C_B_F_C')) || (state.bkng_det[0].booking_status == "22" && context.logindata.roles.includes('C_B_F_O'))) && state.showReissuelink &&
                                   <li>
                                     <Link to="#" onClick={() => cancelBookPopup()}>Cancel Booking</Link>
                                   </li>
                                 }
                                 {state.onward_hold_varient && state.onhold_tag &&
                                 <li>
                                 <Link to="#" onClick={handlesptpnrBooking}>Split PNR</Link>
                                 </li>
                                  }

                                {/* {context.logindata.roles.includes('F_I_P_D') && state.bkng_det[0].booking_status == "23" && state.flt_supplier === 0  &&
                                   <li>
                                     <Link to="#" onClick={() => insertData()}>Insert PNR</Link>
                                   </li>
                                   } */}
                                   {context.logindata.roles.includes('F_I_P_D') && state.bkng_det[0].booking_status == "23" && state.flt_supplier === 0  && state.showReissuelink &&
                                   <li>
                                     <Link to="#" onClick={() => manualReissue()}>Manual Reissue</Link>
                                   </li>
                                   }

                                 {state.enableOWIndigo && state.bkng_det[0].booking_status == "23" && state.flt_supplier === 4 &&
                                   <li>
                                     <Link to="#" onClick={() => showIndigoRefundPopup("O")}>Refund Booking</Link>
                                   </li>
                                   }

                                {/* {state.bkng_det[0].booking_status == "23" && state.bkng_det[0].channel==="B2C" &&
                                   <li>
                                     <Link to="#" onClick={() =>showCustReq()}>Send Request</Link>
                                   </li>
                                 } */}
                                  {/* <Button onClick={openshareevent} variant="link">Share Email Itinerary</Button> */}
                                 {/* <li>
                                   <Link to="#">Edit Itinerary</Link>
                                 </li>
                                 <li>
                                   <Link to="#">Cancel Flight</Link>
                                 </li>
                                 <li>
                                   <Link to="#">Amend Flight</Link>
                                 </li>
                                 <li>
                                   <Link to="#">Revalidate Flight</Link>
                                 </li>
                                 <li>
                                   <Link to="#">Email Itinerary</Link>
                                 </li>
                                 <li>
                                   <Link to="#">Email Ticket</Link>
                                 </li> */}
                               </ul>
                             </div>  </span>}</div>


                           {/* If not equal import pnr below sectors will call */}
                           {(isArrayNotEmpty(state.seg_det) && isArrayNotEmpty(state.airport_data) && isNotNull(state.bktype)
                             && state.bktype !== 'IMPORT') &&
                             (<FlightSummarySectors supplier={0} bookingData={state.bkng_det} refundsData={request} segments={state.seg_det} airportdata={state.airport_data} enableRefundTkt={state.enableRefund}
                               pax_det={state.pax_det} faredata={state.fare_det} payment_data={state.pay_det} enableVoidBooking={state.enableVoidBooking} enableVoidCancel={state.enableVoidCancel}
                               tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled} disableCheckbox={state.disableCheckbox} voidTickets={voidBookReq}
                               bookDetails={state.bkng_det[0]} voidData={state.voidSaveResponse} voidsplitpnr={state.voidCancelTickets} rfamount={state.rfAmount} normalTFtwoLcc={state.normalTFtwoLcc}
                               sendRefund={continueRefund} bktype={state.b_status} isEdit={state.enableEdit} SendEditFlow={handleEditReturn} retrunEdit={state.enableReturnEdit}
                               voidFareResp={state.voidFareResponse} dropdown={splitSplitDropdown} selectedPNR={pnrFetch} sendCancelBook={cancelBookUpdate} final_array_refunded={refundedTicketsSplit} triptype={state.trip_type} enableReisueTkt={state.enableReissue}
                               sendOnwardReissue={reissueTickts} sendRetruenReissue={reissueReturnTickts} hideOnward={upateOnwardReissue} hideReturn={state.hideReturn} reIssueResponse={state.reIssueResponse} bookingType={state.bktype}
                               returnpopupdata={handledownloaddocs} refund_split_onward={refundsplitonward} refund_split_return={refundsplitreturn} handleCancelBookFlowReturn={handleCancelBookFlowReturn}
                               sendReissueSTickts={enableSplitPopup} sendReissueTicktsSReturn={enableSplitRPopup} reissuedSplitResponse={state.reissue_split_response} staterefund={state.showRefund} reissue_split_tag={state.reissue_split_tag} all_tkts={state.all_tkts} split_tickets={state.split_tickets} return_supplier={state.normalTFtwoLcc ? state.flt_supplier : state.flt_supplier_return} onward_supplier={state.flt_supplier}
                               sendOnHoldSplitOnward = {enableHoldSplitPopup} onward_split_hold = {onward_hold} setOnholdDiscard={discardCall} type_selected={state.type_selected}
                               enablesptpnr = {state.enablesptpnr} flow_type_onward={state.flow_type_fbf} showOnward ={disableOnholdSplit} sendOnHoldTkts = {enableHoldRSplitPopup}
                                checkboxFocus = {state.checkboxFocus} confirmFunctionalities={state.enableConfirmFunctions} enableVoidBookingReturn={handleVoidBooking("RETURN")}
                                enableVoidCancelReturn={handleVoidCancel("RETURN")} enablerefundReturn={handleRefundTicket("RETURN")} enablereissueReturn={handleReissueTicket("RETURN")}
                                onward={state.onward} openInsertPnr={updateInserPnr} openManualPnr={manualReissueReturn} cus_code = {state.cus_code} openshareevent={openshareevent} sendTid = {sendTid} booking_status = {state.summaryRes[0].bkng_det[0].booking_status} fbfReturn = {fbfReturn} returnshowitinerary= {state.returnshowitinerary} enablertConfirmFunctions={state.enablertConfirmFunctions} showrtReissuelink={state.showrtReissuelink} rtcancelledPax={state.rtcancelledPax} handleonHoldreturn={handleonHoldreturn}
                                showOnholdowcontinue={state.showOnholdowcontinue} showIndigoPopup={openIndigoPopup} indigoRefundOnwardList={state.indigoRefundList} indigoRefundReturnList={state.indigoRefundRetList}
                                showOnholdrtcontinue={state.showOnholdrtcontinue} openrefundPopup = {refundpopupReturn} enableIndigo={state.enableREIndigo} bk_region_type={state.bk_region_type} userCurrency={state.bkng_det[0].usr_curr_code}
                             />)}

                         </div>
                       }


                       {/* not equal import pnr and split flow below components it will not call  */}
                       {/* {isLoadingMore && <InnerLoader className="yest" />} */}
                       {isNotNull(state.flow_type) && state.flow_type !== 'split' && state.bktype !== 'IMPORT' && !state.normalTFtwoLcc && (
                         <>
                           <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1" ref={voidCncl}>
                             <h5 className="title mb-2 mt-2">Passenger Details</h5>
                             {/* Pax Details Section */}
                             {/*  passeneger details for split fare onward pax and return pax we are showing seperatly */}
                             {isArrayNotEmpty(state.pax_det) && (<FlightSummaryPaxDetails onward={state.onward} pax_det={state.pax_det} enableVoidBooking={state.enableVoidBooking} enableVoidCancel={state.enableVoidCancel} tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled} disableCheckbox={state.disableCheckbox} tid = {state.tid} voidTickets={voidBookReq} enableRefundTkt={state.enableRefund} sendRefund={continueRefund} bookDetails={state.bkng_det[0]} refundAvailable={state.refundAvail} enableReisueTkt={state.enableReissue} sendReissueTickts={reissueTickts} reissueMsg={state.atcmsg} bookingId={state.bkng_det[0].booking_id} flowType={state.bkng_det[0].flow_type} isEdit={state.enableEdit} sendCancelBook={cancelBookUpdate} hideReturn={state.hideReturn} sendReissueSplit={enableSplitPopup} enablesptpnr = {state.enablesptpnr} sendOnHoldTkts = {enableHoldSplitPopup}  onhold_selected_split={"O"} checkboxFocus = {state.checkboxFocus}/>)}
                           </div>

                           {state.flt_supplier === 0 && isArrayNotEmpty(state.pax_det) && state.pax_det[0].filter(s => s.split_pnr && s.stsid == "23").length > 0 && state.bktype !== 'IMPORT' &&
                             <div className="resultSection boxShadow bg-white PassengerDetails mb-4 pb-1" ref={voidCncl}>
                               <h5 className="title mb-2 mt-2"> Reissue Split PNR</h5>
                               <SplitReissuePNR paxList={state.pax_det[0]} />
                             </div>
                           }
                           <div className="resultSection boxShadow bg-white PassengerDetails pb-0 mb-4">
                           {state.fare_det_normal.length !== 0 && state.fare_det_normal !== undefined && state.fare_det_normal !== null && state.bktype !== 'IMPORT' &&
                            <h5 className="title mb-2 d-flex justify-content-between">Fare Details ({state.bkng_det[0].usr_curr_code})  </h5>
                           }
                            {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} onClick={openeeditPayment}>

                               <div className={"showDetails " + (editPayment ? 'active' : 'inactive')}>
                                 <ul className="d-flex flex-column">
                                   <li>
                                     <Link to="#">Edit Fare</Link>
                                   </li>
                                   <li>
                                     <Link to="#">Update Fare</Link>
                                   </li>

                                 </ul>
                               </div>  </span> */}

                             {/*  <span className="itnry"><em>Itinerary Ref # :</em> EZ125198-1</span> */}
                             {/* Fare details section */}
                             {/* fare details onward fare and return fare we are showing seperaly */}
                             {state.fare_det_normal.length !== 0 && state.fare_det_normal !== undefined && state.fare_det_normal !== null && state.bktype !== 'IMPORT' &&
                               <FlightSummaryFareDetails faredata={state.fare_det_normal} bookedTo={state.bkng_det[0].buser_type} bktype={state.b_status} bookingType={state.bkng_det[0].bktype} supplier={state.flt_supplier} userCurrency={state.bkng_det[0].usr_curr_code} bk_region_type={state.bk_region_type}/>
                             }
                           </div>
                           {state.onward_hold_varient && state.reissue_split_tag &&
                             <div className="text-right buttonGrop border-0 pt-0">

                              <Button
                                 size="xs"
                                 variant="primary"
                                 className="mr-3"
                                 onClick={()=>fireTicketing("NotHold")}
                              >Continue</Button>
                               </div>
                            }
                           {isArrayNotEmpty(state.voidFareResponse) && state.bkng_det[0].flow_type === "normal" && state.bktype !== 'IMPORT' &&
                             <VoidSummaryDetails flowType={"normal"} voidFareResp={state.voidFareResponse[0]} userCurrency={state.usr_cur} />}

                           {isNotNull(state.flow_type) && state.flow_type !== 'split' && isNotNull(state.bktype) && state.bktype !== 'IMPORT' && isArrayNotEmpty(state.voidCancelTickets) &&
                             <div className="resultSection boxShadow bg-white PassengerDetails  mb-4">
                              <h5 className="title d-flex justify-content-between">Void Split PNR <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitMOre}>
                                 <div className={"showDetails " + (editvoidMore ? 'active' : 'inactive')}>
                                   <ul className="d-flex flex-column">
                                     {/* <li>
                                       <Link to="#" onClick={handleIssueTicket}>Issue Ticket</Link>
                                     </li> */}

                                     {state.voidCancelTickets.length !== 0 && state.voidCancelTickets.length !== state.ticketsVoidedCancelled.length && <li>
                                       <Link to="#" onClick={handleCancelBooking}>Cancel Booking</Link>
                                     </li>}

                                   </ul>
                                 </div>
                               </span></h5>
                               {isArrayNotEmpty(state.voidSaveResponse) && (<SplitPnr res={state.voidCancelTickets} type={"Void"} voidEnable={state.voidenable} pnr={pnrFetch} />)}
                             </div>
                           }
                         </>)}


                       {state.refundAvail && state.bkng_det[0].flow_type === "normal" && state.bktype !== 'IMPORT' &&
                         <FlightSummaryRefund refundResp={request} bookedTo={state.bkng_det[0].buser_type} />}
                        {parseInt(state.seg_det[0].trvel_type)===1 && state.bkng_det[0].flow_type === "normal" && state.flt_supplier===4 && state.indigoRefundList.length!==0 &&
                         <IndigoSummaryDetails indigoRefundList={state.indigoRefundList} />
                        }

                       {/* Displaying Refund Split PNR Details */}
                       {state.refundAvail && state.bkng_det[0].flow_type === "normal" && isArrayNotEmpty(state.refund_split) && state.bktype !== 'IMPORT' &&
                         <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                          <h5 className="title d-flex justify-content-between">Refund Split PNR <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" onClick={openSplitRefundMOre}>
                             <div className={"showDetails " + (editrefundMore ? 'active' : 'inactive')}>
                               <ul className="d-flex flex-column">
                                 {/* <li>
                                   <Link to="#" onClick={handlerefundIssueTicket}>Issue Ticket</Link>
                                 </li> */}

                                {state.showCancel && <li>
                                   <Link to="#" onClick={handlerefundCancelBooking}>Cancel Booking</Link>
                                 </li>}

                               </ul>
                             </div>
                           </span></h5>
                           {isArrayNotEmpty(state.refund_split) &&
                             <SplitPnr res={state.refund_split} type={"Refund"} refundEnable={state.refundenable} pnr={pnrFetch} />
                           }
                         </div>}

                     </ShowHide>
                     </div>
                   </div>
                 }

                 {(state.bktype == 'IMPORT' || state.bktype == 'IMPORT/SPLIT' || state.bktype == 'IMPORT/REISSUE') &&
                   <div className="resultSection bg-white boxShadow mb-4" ref={voidCncl}>
                     <>
                       {/* If import pnr below sectors will call */}

                       {(isArrayNotEmpty(state.seg_det) && isArrayNotEmpty(state.airport_data) && isNotNull(state.bktype)
                         && (state.bktype == 'IMPORT' || state.bktype == 'IMPORT/SPLIT' || state.bktype == 'IMPORT/REISSUE')) &&
                         (<FlightSummaryImportSectors bookingData={state.bkng_det} segments={state.seg_det} airportdata={state.airport_data}
                           pax_det={state.pax_det} faredata={state.fare_det} payment_data={state.pay_det} enableVoidBooking={state.enableVoidBooking} enableVoidCancel={state.enableVoidCancel} voidsplitpnr={state.voidCancelTickets} tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled} disableCheckbox={state.disableCheckbox} voidData={state.voidFareResponse} rfamount={state.rfAmount} bk_region_type={state.bk_region_type}
                           voidTickets={voidBookReq} pnrResponse={pnr_response} bktype={state.b_status} bookedTo={state.bkng_det[0].buser_type} triptype={state.trip_type} refund={state.showRefund} refundResp={state.initialRefundResponse} reissued_data={state.reissue_split_response} reissue_split_tag={state.reissue_split_tag}  openInsertImpPnr={updateInserImpPnr} openManualImpPnr={manualReissue} cus_code = {state.cus_code} supplier = {state.flt_supplier} openshareevent= {openshareevent} sendTid = {sendTid} showitinerary={state.showitinerary} booking_status = {state.summaryRes[0].bkng_det[0].booking_status} userCurrency={state.usr_cur} />)}
                     </>
                   </div>
                 }
                 {state.bkng_det !== undefined && state.airport_data !== undefined && state.airport_data.length !== 0 && isArrayNotEmpty(state.reissue_split_response) && state.bkng_det[0].flow_type === "normal" && state.bktype !== 'IMPORT' && state.bktype !== 'IMPORT/SPLIT' && state.bktype !== 'IMPORT/REISSUE' &&
                   //  <div className="resultSection boxShadow bg-white PassengerDetails mb-4 azam" >
                   <FlightReissueSplit bookingData={state.bkng_det} type={"Onward"} reissued_data={state.reissue_split_response} airportdata={state.airport_data} refund={state.showRefund} triptype={state.trip_type} cus_code = {state.cus_code} openshareevent = {openshareevent} sendTid = {sendTid} cancelledPax={state.cancelledPax} supplier={0} userCurrency={state.usr_cur} bk_region_type={state.bk_region_type}/>
                   //  </div>
                 }

                 {isArrayNotEmpty(state.custms) &&
                   <div className="resultSection PassengerDetails boxShadow bg-white summary mb-4 cstFieldremarks">
                     <h5 className="title d-flex justify-content-between">Custom Fields Remarks</h5>
                     {isArrayNotEmpty(state.custms) &&
                       <CustomFields res={state.custms} />
                     }
                   </div>
                 }

                  {/* Import PNR Un ticketed Data */}
                  {isNotNull(state.pnr_Response) && (
                         <ImportPNRunticketDetails pnrResponse={state.pnr_Response} airport_data={state.airport_data} />)}


                 {state.bkng_det[0].flow_type === "normal" && state.reIssueResponse !== undefined && state.reIssueResponse.length > 0 && state.reIssueResponse[0].length > 0 && state.reIssueResponse[0].map((seg, index) => (
                   <div className="resultSection history  boxShadow bg-white PassengerDetails mb-4" >
                     <ShowHide icon="" title="History">
                       <div className="showHide-content pl-0 pr-0">
                         {/* Reissue Section */}
                         {/*  Reissue data will iterate to show as per the JSON */}
                         {isNotNull(seg) && (<Reissue res={seg} fareData={state.fare_det} bktype={(state.bktype == 'IMPORT' || state.bktype == 'IMPORT/SPLIT' || state.bktype == 'IMPORT/REISSUE') ? "IMPORT" : state.bktype} airportdata={state.airport_data} bkngDetails={seg.bkng_det} triptype={state.trip_type} supplier={0} userCurrency={state.usr_cur}/>)}
                       </div></ShowHide></div>
                 ))}
                 {show && (state.enableVoidBooking || state.enableVoidCancel) &&
                   <Modal
                     show={show}
                     onHide={handleClose}
                     className="fairPopUp"
                     backdrop="static"
                     keyboard={false}
                     size="lg"

                   >
                     <Modal.Body className="pb-2">
                       <h5>Fare Details({state.bkng_det[0].usr_curr_code})</h5>

                       <div className="header">
                         <Row>
                           <Col xs="2">Traveller</Col>
                           <Col xs="2">Ticket Numbers</Col>
                           <Col xs="2">Fare</Col>
                           {/* <Col xs="2">Void Fee</Col> */}
                           {/* // gst on void */}
                           <Col xs="2">Supplier Fee</Col>
                           <Col xs="2">Customer Fee</Col>
                           <Col xs="2" className="text-right">Refund Amount</Col>
                         </Row>

                       </div>
                       <div className="passengerList">
                         {isArrayNotEmpty(state.tkts) && state.tkts.map((obj, ind) => (

                           <React.Fragment key={ind}>
                             <Row className="pList">
                               <Col xs="2"><span>{obj.pn} {obj.lead ? "(Lead)" : ""}</span></Col>
                               <Col xs="2"><span>{obj.tkt}</span></Col>
                               <Col xs="2"><span>{state.usr_cur} {obj.tf ? obj.tf.toFixed(2) : ''}</span></Col>
                               {/* <Col xs="2"><span>{state.usr_cur} {obj.sf ? obj.sf.toFixed(2) : '0'}</span></Col> */}
                               {/* //gst on void */}
                               <Col xs="2"><span>{state.usr_cur} {obj.sf ? obj.sf.toFixed(2) : '0'}</span></Col>
                               <Col xs="2"><span>{state.usr_cur} {obj.cf ? obj.cf.toFixed(2) : '0'}</span></Col>
                               {/* <Col xs="2"><span>{state.usr_cur} {08}</span></Col> */}
                               <Col xs="2" className="text-right"><span>{state.usr_cur} {obj.ra ? obj.ra.toFixed(2) : ''}</span></Col>
                             </Row>
                           </React.Fragment>

                         ))}
                         <div className="total d-flex justify-content-end mb-2">
                           <span className="mr-2">Refund Total : </span> <strong > {state.usr_cur} {state.rfAmount ? state.rfAmount.toFixed(2) : ''}</strong>
                         </div>

                       </div>
                     </Modal.Body>
                     {state.voidSelectedLength !== state.allTktslength &&
                       <div className="infoSec">
                         <Alert variant="info">
                          <span className="ezyIcon icon-info"></span> Selected Passenger will now split as all Passenger in the PNR are not selected.
                         </Alert></div>
                     }
                     <Modal.Footer>
                       {state.voidLoading ? ('') : <Button variant="secondary" className={"d-flex loading " + (state.voidLoading ? ' deseble progress-bar-striped progress-bar-animated' : '')} onClick={handleClose}>
                         Cancel
                       </Button>}
                       {/* <Button variant="outline-secondary" className={"d-flex loading " + (state.voidLoading ? ' deseble progress-bar-striped progress-bar-animated' : '')} onClick={handleClose}>
                            Cancel
                           </Button> */}
                       {state.enableVoidBooking &&
                         <Button disable={state.voidLoading} className={"d-flex loading " + (state.voidLoading ? 'progressIn progressIn progress-bar-striped progress-bar-animated' : '')} variant="primary" onClick={!state.voidLoading ? setVoid : dummy}>{!state.voidLoading ? "Void" : "Process..."}</Button>
                       }
                       {state.enableVoidCancel &&
                         <Button variant="primary" disable={state.voidLoading} className={"d-flex loading " + (state.voidLoading ? 'progressIn progressIn progress-bar-striped progress-bar-animated' : '')} onClick={!state.voidLoading ? setCancelVoid : dummy}>{!state.voidLoading ? "Void" : "Process..."}</Button>
                       }
                     </Modal.Footer>
                   </Modal>
                 }

               </>)

             }

             {/* below buttons if On hold booking status only it will display */}

             {(isArrayNotEmpty(state.bkng_det) && isNotNull(state.bkng_det[0].booking_status_str)
               && parseInt(state.bkng_det[0].booking_status) === 22) && state.flt_supplier === 0 && state.flt_supplier_return === 0 && !state.reissue_split_tag && state.showOnholdcontinue && state.showContinueBtn &&
               <div className="text-right buttonGrop border-0 pt-0">

                 <Button
                   size="xs"
                   variant="primary"
                   className="mr-3"
                   onClick={()=>fireTicketing("Hold")}
                 >Continue</Button>
               </div>
             }
             {(state.enableVoidBooking || state.enableVoidCancel) &&
               <div className="text-right buttonGrop border-0 pt-0">
                 {!state.loader ? <Button
                   size="xs"
                   variant="outline-secondary"
                   type="submit"
                   onClick={cancelCheckBoxes}
                 > Cancel</Button> : null}
                 <Button
                   size="xs"
                   variant="outline-primary"
                   className={!state.loader ? "" : "progressIn"}
                   type="submit"
                   onClick={handlevShow}
                 > {!state.loader ? "Continue" : "Process..."} </Button>

               </div>
             }
             {(state.splitissue || state.splitCancel) &&
               <div className="text-right buttonGrop border-0 pt-0">
                 {state.isLoading ?
                   ' ' : <Button
                     size="xs"
                     variant="outline-secondary"
                     type="submit"
                     onClick={cancelSplitAction}
                   > Cancel</Button>
                 }
                 {state.isLoading ? <Button
                   size="xs"
                   variant="outline-secondary"
                   type="submit"
                   className="progressIn"
                 // onClick={cancelEdit}
                 > Process...</Button> : <>
                   {state.splitissue &&
                     <Button
                       size="xs"
                       variant="outline-secondary"
                       type="submit"
                     // onClick={cancelEdit}
                     > Continue</Button>
                   }

                   {state.splitCancel &&
                     <Button
                       size="xs"
                       variant="outline-primary"
                       type="submit"
                       onClick={cancelSplit}
                     > Continue</Button>

                   }
                 </>
                 }
               </div>
             }
             {state.no_trip && ('Trip Not Found')}

             {/* For incomplete booking this button will be enable*/}
             {(isArrayNotEmpty(state.bkng_det) && isNotNull(state.bkng_det[0].booking_status)
               && !state.enableVoidBooking && !state.enableVoidCancel && parseInt(state.bkng_det[0].booking_status) === 21) && state.flt_supplier === 0 && state.flt_supplier_return === 0 && (
                 <div className="text-right buttonGrop border-0 pt-0">
                   <Button
                     size="xs"
                     variant="outline-primary"
                     type="submit"
                     onClick={incompleteService('no')}
                   > Continue</Button>
                 </div>)}

             {/* For incomplete booking this button will be enable*/}
             {state.enableEditBooking &&
               <div className="text-right buttonGrop border-0 pt-0">
                 <Button
                   size="xs"
                   variant="outline-secondary"
                   type="submit"
                   onClick={cancelEdit}
                 > Cancel</Button>
                 <Button
                   size="xs"
                   variant="outline-primary"
                   type="submit"
                   onClick={callEditBooking}
                 > Update</Button>
               </div>
             }
           </div>

           {/* below popup when onhold fail case below one will excute */}
           {show && !state.enableVoidBooking && !state.enableVoidCancel &&
             <Modal
               show={show}
               onHide={handleClose}
               onClick={discrdBooking("ONHOLD REBOOK", 'close')}
               className="importPNRMdl "
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header closeButton>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Required fare is no more avaialable</h4>
                   <div className="message">
                     Would you like to try some other options?
                   </div>
                 </div>

               </Modal.Header>

               <Modal.Footer className="justify-content-center">
                 <Alert variant="info mb-2">
                  <span className="ezyIcon icon-info"></span> As there is no more fare available your request will be discarded
                 </Alert>
                 <Button
                   size="xs"
                   variant="primary"
                   className="pl-4 pr-4 mb-2"
                   onClick={discrdBooking("ONHOLD REBOOK", 'yes')}
                   type="submit"
                 >Yes</Button>




               </Modal.Footer>
             </Modal>
           }


           {(state.enableOnHoldPopup || state.enableOnHoldRPopup) &&
             <Modal
               show={state.enableOnHoldPopup || state.enableOnHoldRPopup}
               onHide={handleClose}
               className="importPNRMdl "
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Are you sure?</h4>
                   <div className="message">
                     As all passengers are not selected ,we will split the selected pax now.
                     Please click on yes to continue.
                   </div>
                 </div>

               </Modal.Header>

               <Modal.Footer className="justify-content-center">
                 {state.process ?
                   <Button
                     size="xs"
                     variant="outline-secondary"
                     className="pl-4 pr-4 mb-2"
                     type="submit"
                   >Processing..</Button> :

                   <>
                     <Button
                       size="xs"
                       variant="outline-secondary"
                       className="pl-4 pr-4 mb-2"
                       onClick={clearPopList}
                       type="submit"
                     >Cancel</Button>
                     <Button
                       size="xs"
                       variant="outline-primary"
                       className="pl-4 pr-4 mb-2"
                       onClick={() =>{
                        //  (state.OnHoldtkts.length > 0 &&
                        state.enableOnHoldRPopup ? onHoldRSplitTickts(state.OnHoldRtkts) : onHoldSplitTickts(state.OnHoldtkts)}}
                       type="submit"
                     >Yes</Button></>}
               </Modal.Footer>
             </Modal>
           }

           {state.enableRSplitPopup &&
             <Modal
               show={state.enableRSplitPopup}
               onHide={handleClose}
               className="importPNRMdl "
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Are you sure?</h4>
                   <div className="message">
                     As all passengers are not selected ,we will split the selected pax now.
                     Please click on yes to continue.
                   </div>
                 </div>

               </Modal.Header>

               <Modal.Footer className="justify-content-center">
                 {state.process ?
                   <Button
                     size="xs"
                     variant="outline-secondary"
                     className="pl-4 pr-4 mb-2"
                     type="submit"
                   >Processing..</Button> :

                   <>
                     <Button
                       size="xs"
                       variant="outline-secondary"
                       className="pl-4 pr-4 mb-2"
                       onClick={clearPopList}
                       type="submit"
                     >Cancel</Button>
                     <Button
                       size="xs"
                       variant="outline-primary"
                       className="pl-4 pr-4 mb-2"
                       onClick={() => (state.enableSplitReissueR.length > 0 ? reissueRktsReturn(state.enableSplitReissueR) : reissueSplitTickts(state.enableSplitReissueO))}
                       type="submit"
                     >Yes</Button></>}




               </Modal.Footer>
             </Modal>
           }


           {/* displaying incomplete popup(if incomplete response is fail) */}
           {state.incompletpopup &&
             <Modal
               show={state.incompletpopup}
               onHide={handleClose}
               onClick={discrdBooking("ONHOLD REBOOK", 'close')}
               className="importPNRMdl requiredFare"
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header closeButton>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Required fare is no more available</h4>
                   <div className="message">
                     Would you like to try some other options?
                   </div>
                 </div>

               </Modal.Header>

               <Modal.Footer className="justify-content-center">
                 <Button
                   size="xs"
                   variant="outline-primary"
                   onClick={discrdBooking('INCOMPLETE REBOOK', 'yes')}
                   type="submit"
                 >Yes</Button>
               </Modal.Footer>
             </Modal>
           }

           {state.cancelPopup &&
             <Modal
               show={state.cancelPopup}
               onHide={handleClose}
               onClick={handleClose}
               className="importPNRMdl requiredFare"
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Are you sure?</h4>
                   <div className="message">
                     You want to cancel the booking
                   </div>
                 </div>

               </Modal.Header>

               <Modal.Footer className="justify-content-center">
                 <Button
                   size="xs"
                   variant="outline-primary"
                   onClick={handleClose}
                   type="submit"
                 >No</Button>
                 <Button
                   size="xs"
                   variant="primary"
                   onClick={() => handleCancelBookingFlow()}
                  type="submit"
                 >Yes</Button>

               </Modal.Footer>
             </Modal>
           }
{state.enableInsert &&
             <Modal
               show={state.enableInsert}
               className={state.enablePnrResp && state.pnrResponse!=="" ? "morefaeoption inpnr" : ""}
               backdrop="static"
               keyboard={false}
               size={state.enablePnrResp && state.pnrResponse!=="" ? "lg" : ""}
             >

               <Modal.Body className={state.enablePnrResp && state.pnrResponse!=="" ? "pb-0" : ""}>
               <div className="pnrSummaryInput">
                 <Form.Row>
                <Form.Group className="m-0" as={Col} xs={state.enablePnrResp && state.pnrResponse!=="" ? '4': '12'} controlId="insertPnr">
                  <Form.Control  type="text" disabled={state.enablePnrResp} required placeholder="Enter PNR" value={state.insertPnr} onChange={handleChange} />
                    <Form.Label>PNR <sup>*</sup></Form.Label>
                </Form.Group>
                </Form.Row>
                {state.enablePnrResp && state.pnrResponse!=="" &&
                <ShowInsertPnrData pnrResponse={state.pnrResponse} sendPaxInfo={updatePaxTkts} userCurrency={state.usr_cur}/>
                }
                 </div>
               </Modal.Body>
               <Modal.Footer className="justify-content-end border-top pt-3">
                 <Button
                   size="xs"
                   variant="outline-primary"
                   onClick={handleClose}
                   type="submit"
                 >Cancel</Button>
                {!state.enablePnrResp &&
                <Button
                size="xs"
                variant="primary"
                onClick={() => retrivePNRData()}
                type="submit"
                >Submit</Button>
                 }
                {state.enablePnrResp &&
                <Button
                size="xs"
                variant="primary"
                onClick={() => insertBookingData()}
                type="submit"
                >Insert Data</Button>
                 }
               </Modal.Footer>
             </Modal>
           }
           {state.enableManualReissue &&
             <Modal
               show={state.enableManualReissue}
               className={state.enablePnrResp && state.manualResponse!=="" ? "morefaeoption inpnr" : "" + 'reissuePnrpop'}
               backdrop="static"
               keyboard={false}
               size={state.enablePnrResp && state.manualResponse!=="" ? "lg" : ""}
             >

               <Modal.Body className={state.enablePnrResp && state.manualResponse!=="" ? "pb-0" : ""}>
               <div className="pnrSummaryInput dd">
                 <Form.Row>
                <Form.Group className="m-0" as={Col} xs={state.enablePnrResp && state.manualResponse!=="" ? '4': '12'} controlId="insertPnr">
                  <Form.Control  type="text" disabled={state.enablePnrResp} required placeholder="Enter PNR" value={state.insertPnr} onChange={handleChange} />
                    <Form.Label>PNR <sup>*</sup></Form.Label>
                </Form.Group>
                </Form.Row>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
              ) : ""}
                {state.enablePnrResp && state.manualResponse!=="" &&
                <ShowManualPnrData pnrResponse={state.manualResponse} sendPaxInfo={updatePaxTkts} enableReissue={state.continueReissue} closePopup={closeDialog} bookingId={state.bkng_det[0].booking_id} paxList={state.pax_det} oldtid={state.oldTid} userCurrency={state.usr_cur}/>
                }
                 </div>

               </Modal.Body>
               <Modal.Footer className="d-flex justify-content-end border-top pt-3">
                {!state.enablePnrResp &&


                 <Button
                size="xs"
                variant="outline-primary"
                onClick={handleClose}
                type="submit"
                >Cancel</Button>
                 }
                {!state.enablePnrResp &&
                <Button
                size="xs"
                variant="primary"
                onClick={() => retrivePNRData()}
                type="submit"
                >Submit</Button>

                 }
               </Modal.Footer>


             </Modal>
           }
           {state.showEditpop &&
             <Modal
               show={state.showEditpop}
               onHide={oncloseEidt}
               className="importPNRMdl editpnrPop"
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header closeButton className="border-bottom-0">
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <strong>Are you sure! You want to proceed with the Edit Booking? </strong>

                   <p className="text-center d-flex mb-2"><span className="text-secondary mr-1 "> NOTE : </span> Please cross check all GDS PNR's Should be same.</p>
                 </div>

               </Modal.Header>
               <Modal.Footer className="justify-content-center border-top-0 pb-4">
                 <Button
                   size="xs"
                   variant="secondary"
                   onClick={oncloseEidt}
                   type="submit"
                 >No</Button>
                 <Button
                   size="xs"
                   variant="primary"
                   onClick={processEditBookingFlow}
                   type="submit"
                 >Yes</Button>
               </Modal.Footer>
             </Modal>
           }

           {/* Download Docs popup--start */}
           {state.enabledownloaddocs &&
             <Modal
               show={state.enabledownloaddocs}
               onHide={oncloseCancel}
               className="downloadInvoice IternearyInvoic"
               backdrop="static"
               keyboard={false}
               size="lg"
             >
               <Modal.Header closeButton className="pb-0"><h5>Download Itinerary/Invoice</h5></Modal.Header>
               <Modal.Body className="pb-0 pt-0" >

                 <div className="tabLink tabList">
                   {/*    {context.logindata.utype === "att_user" &&
                        <Tabs disable={true} active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                          <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                          <span key="TA">Travel Agent</span>
                          {state.bkng_det[0].buser_type === "sta_user" &&
                            <span key="STA">Sub Travel Agent</span>
                          }
                        </Tabs>
                      } */}
                   {(context.logindata.utype === "ta_user" || context.logindata.utype === "att_user") &&
                     <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                       <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
                       {state.bkng_det[0].buser_type === "sta_user" &&
                         <span key="STA">Sub Travel Agent</span>
                       }
                     </Tabs>
                   }
                   {context.logindata.utype === "sta_user" && state.bkng_det[0].buser_type === "sta_user" &&
                     <Tabs active={refPriceTab.active} onChange={active => setRefPriceTab({ active })}>
                       <span className="alertList alerts d-flex align-items-center" key="STA">Sub Travel Agent</span>
                     </Tabs>
                   }

                   <div className="tabContent">
                     <div className="searchbyforDownload">
                       <Form.Row>
                         {state.onHoldItinerary &&<Form.Group as={Col} xs={4} className="inputCheck">
                           <Form.Label>Ticket Status<sup></sup></Form.Label>
                           <div className="d-flex align-items-center">
                             <Form.Check
                               type="radio"
                               id="ticketed"
                               label="Ticketed"
                               className="ml-0"
                               checked={state.ticketed}
                               onChange={selectedstatushandleChange}
                               custom
                             />
                             {state.onHoldItinerary &&
                              <Form.Check
                               type="radio"
                               id="refund"
                               label="Refund"
                               className="ml-3"
                               checked={state.refund}
                               onChange={selectedstatushandleChange}
                               custom
                             />}
                             {state.onHoldItinerary &&
                             <Form.Check
                               type="radio"
                               id="void"
                               label="Void"
                               className="ml-3"
                               checked={state.void}
                               onChange={selectedstatushandleChange}
                               custom
                             />}
                           </div>

                         </Form.Group>}

                         <Form.Group as={Col} xs={6} controlId="appon" className="inputCheck">
                           <Form.Label>Download <sup></sup></Form.Label>
                           <div className="d-flex align-items-center">
                             {state.onHoldItinerary && <Form.Check type="radio" label={state.ticketed ? "Invoice": "Credit Note"} className="ml-0" name="Invoice" id="invoice" onChange={selectedstatushandleChange} checked={state.invoice} custom />}
                             {state.onHoldItinerary &&<Form.Check type="radio" label={state.ticketed ? "Customer Invoice" : "Cust Credit Note"} className="ml-3" name="Customer Invoice" id="cinvoice" onChange={selectedstatushandleChange} checked={state.cinvoice} custom />}
                             {state.ticketed && state.showitineraryRadio && <Form.Check type="radio" label="Itinerary" className="ml-3" name="Itinerary" id="itinerary" onChange={selectedstatushandleChange} checked={state.itinerary} custom />}
                           </div>

                         </Form.Group>
                       </Form.Row>
                       <Form.Row>
                         {/* <Form.Group as={Col} xs={4} className="inputCheck mb-0">
                             <Form.Label>Search By<sup></sup></Form.Label>
                               <div className="d-flex align-items-center">
                                 <Form.Check
                                   type="checkbox"
                                   id="currentStatus"
                                   label="Current Status"
                                   className="ml-0"
                                   custom
                                 />
                                 <Form.Check
                                   type="checkbox"
                                   id="previousStatus"
                                   label="Previous Status"
                                   className="ml-3"
                                   custom
                                 />
                               </div>

                             </Form.Group> */}
                         <Form.Group as={Col} controlId="appon" className="vewbutton text-right mb-0">
                           {state.onHoldItinerary &&
                           <Button
                             size="xs"
                             variant="primary"
                             onClick={docsViewDetailsonclick}
                             type="submit"
                           >View Detail</Button>}
                         </Form.Group>
                       </Form.Row>
                     </div>

                     <Form.Row>
                       {/* {state.flightDocsData!=="" && isArrayNotEmpty(state.popupticketsdata) &&
                        <PDFViewer style={{ marginTop: '1px', width: '100%', height: '12000' }}>
                        <FlightInvoice  bookingData={state.bkng_det[0]} response={state.flightDocsData} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} />
                        </PDFViewer>
                        } */}
                        { isArrayNotEmpty(state.indigoDocsList) &&
                         //* NEW DOWNLOAD */}
                         <div style={{ display: "none" }}>
                           <FlightIndigoInvoicePdf ref={componentRef} supplier={state.dd_supplier} bk_det={state.bkng_det} response={state.indigo_refund_docs} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} compLogo={context.logindata.comp_logo_url} parent_id={context.logindata.pcid} selectedTab={refPriceTab.active} changeStatus={state.changeStatus} userCurrency={state.usr_cur}/>
                         </div>
                       }
                       { isArrayNotEmpty(state.popupticketsdata) &&
                         //* NEW DOWNLOAD */}
                         <div style={{ display: "none" }}>
                           <FlightInvoicePdf ref={componentRef} supplier={state.dd_supplier} bk_det={state.bkng_det} response={state.flightDocsData} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} compLogo={context.logindata.comp_logo_url} parent_id={context.logindata.pcid} selectedTab={refPriceTab.active} changeStatus={state.changeStatus} userCurrency={state.usr_cur} ow_gst={state.ow_gst} rt_gst={state.rt_gst} noOfPax={state.popuppaxdata}/>
                         </div>

                         //  <PDFDownloadLink fileName="flightInvoice.pdf" document={<FlightInvoice  bookingData={state.bkng_det[0]} response={state.flightDocsData} airport_data={state.airport_data} phone_num={context.logindata.phno} emialId={context.logindata.unm} />} >
                         //  {({ blob, url, showVoucher, error }) =>
                         //    showVoucher ? 'Loading document...' : <Icon className="share" size={20} icon="pdf" />
                         //  }
                         //  </PDFDownloadLink>
                       }
                       <div className="linkforinvoice">

                         {/* NEW DOWNLOAD */}
                         {(isArrayNotEmpty(state.popupticketsdata) || (isArrayNotEmpty(state.indigoDocsList) && state.enable_pdf)) &&
                            <Link className="prient mr-3 ezyIcon icon-pdf" to="#" onClick={handlePrint}></Link>
                         }

                         {/* <Link className="prient mr-3" to="#"><Icon className="share" size={20} icon="pdf" /></Link> */}
                         {/* <Link to="#"><Icon className="print" size={20} icon="print" /></Link>
                           <Link to="#"><Icon className="share" size={20} icon="share" /></Link> */}


                       </div>


                     </Form.Row>

                     {state.indigoDocsList.length===0 &&
                     <div className="passengerList">
                       <Table className="paxTable">
                         <tr>
                           <th width="10%"><p className="d-flex m-0">
                             {isArrayNotEmpty(state.popuppaxdata) && isNotNull(state.popuppaxdata) && (<Form.Check type="checkbox" label="" checked={state.popupallc} onChange={selectedallpaxaeventchange}
                               id='all' custom />)} Select</p></th>
                           <th width="25%">Traveller</th>
                           <th width="10%">Pax Type</th>
                           {state.summaryRes[0].bkng_det[0].supplier === "4" &&
                           <th width="10%">PNR</th>
                           }
                           {(state.popuppaxdata.length === 0 || state.popuppaxdata[0].supplier !== "1") ? (
                             <th width="15%">Ticket Numbers</th>
                           ) : (
                             <>
                             <th width="15%">Baggage</th>
                             <th width="15%">Meals</th>
                             </>
                           )}

                           <th width="15%">Segment Status</th>
                           <th width="15%">Ticketing status</th>
                         </tr>


                         {isArrayNotEmpty(state.popuppaxdata) && isNotNull(state.popuppaxdata) && state.popuppaxdata.map((pax, index) => (

                           <React.Fragment key={index}>

                             <tr className='list'>
                               <td>
                                 <Form.Check type="checkbox" label="" checked={state.popupindigopaxListay.includes(pax.tkt_no + index)} id={pax.tkt_no + index}
                                   onChange={selectedpaxdataeventchange(pax)} custom />
                               </td>
                               <td>
                                 {pax.pax_name.toUpperCase()}{(isNotNull(pax.is_lead_pax) && pax.is_lead_pax !== 0) && '(lead)'}</td>

                               <td>{pax.pax_type}</td>
                               {pax.supplier === "4" &&
                               <td>{pax.split_pnr!==null?pax.split_pnr:pax.gds_pnr}</td>
                               }

                               {pax.supplier !== "1" ? (
                                 <td>
                                 <span> {pax.tkt_no ? pax.tkt_no : "N/A"} </span>
                               </td>
                               ) : (
                                 <>
                               <td>
                                 <span> {pax.bag_lable ? bagLabelConstruction(pax.bag_lable) : "N/A"} </span>
                               </td>
                               <td>
                                 <span> {pax.meal_lable ? pax.meal_lable : "N/A"} </span>
                               </td>
                               </>
                               )
                               }

                               <td>
                                 <span className={"status paxstatus " + ((pax.segst == "Confirmed") && ('text-success') || (pax.segst == "Incomplete") && ('text-primery') || (pax.segst == "On Hold") && ('text-primery') || (pax.segst == "Discarded") && ('text-danger') || (pax.segst == "Ticketing failed") && ('text-danger') || (pax.segst == "Cancelled") && ('text-danger'))}> {pax.segst}</span>
                               </td>
                               <td >
                                 <span className={"status paxstatus " + ((pax.sts == "Confirmed") && ('text-success') || (pax.sts == "Incomplete") && ('text-primery') || (pax.sts == "On Hold") && ('text-primery') || (pax.sts == "Discarded") && ('text-danger') || (pax.sts == "Failed") && ('text-danger') || (pax.sts == "Cancelled") && ('text-danger'))}>{pax.sts}</span>
                               </td>
                             </tr>
                             <tr>
                               <td className="spaceNew" colSpan="8"></td>
                             </tr>
                           </React.Fragment>
                         ))}


                         {/* if no popupdata below msh will disply */}





                       </Table>
                       <p>{state.onHoldItinerary&& isNotNull(state.pupupmsg) && state.pupupmsg}</p>
                     </div>
                      }

                     {state.indigoDocsList.length!==0 &&
                     <div className="passengerList">
                       <Table className="paxTable">
                         <tr>
                           <th width="5%">Select</th>
                           <th width="15%">PNR</th>
                           <th width="35%">Pax Name</th>
                           <th width="25%">Segment Status</th>
                           <th width="25%">Ticketing status</th>
                         </tr>
                         {isArrayNotEmpty(state.indigoDocsList) && isNotNull(state.indigoDocsList) && state.indigoDocsList.map((pax, index) => (
                           <React.Fragment key={index}>
                             <tr className='list 2'>
                               <td>
                                 <Form.Check type="radio" label="" checked={pax.selected} id={pax.split_pnr!==null?pax.split_pnr+1:pax.pnr+1}
                                   onChange={selectedIndigoPNR(pax.split_pnr!==null?pax.split_pnr:pax.pnr)} custom />
                               </td>
                               <td>
                                 {pax.split_pnr!==undefined?pax.split_pnr:pax.pnr}
                               </td>
                               <td>
                                <span className="d-flex">
                                <span>{pax.price[0].pax_name[0].toUpperCase()}</span>
                                {pax.price[0].pax_name.length > 1 &&

                                <span className="d-flex toolTipInfo"><span className='viewAllp ml-2'>View all Pax</span>
                                <div className="toolTipCUstoom"
                                style={{"width":"260px"}}>
                                <span>{pax.price[0].pax_name.map((pr, idx) => (
                                      <span>{pr.toUpperCase()}  -  {pr.split_pnr!==null && pr.split_pnr!==undefined?pr.split_pnr:pr.pnr}<br/></span>
                                ))}</span>
                                </div>
                                </span>
                                }
                                </span>

                                 </td>
                               <td>
                                <span className="canFailed"> {"Cancelled"}</span>
                               </td>
                               <td>
                               <span className="refuFailed">{"Refund/Cancel"}</span>
                               </td>
                             </tr>
                             <tr>
                               <td className="spaceNew" colSpan="8"></td>
                             </tr>
                           </React.Fragment>
                         ))}
                       </Table>
                       <p>{isNotNull(state.pupupmsg) && state.pupupmsg}</p>
                     </div>
                }


                   </div>
                 </div>
               </Modal.Body>

             </Modal>
           }
           {/* Download Docs popup--end */}
           {/* share email popup start*/}
      <Modal
        show={state.showCustReqPopup}
        onHide={closeCustDlg}
        className="importPNRMdl shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
          <h5>Send Request</h5>
        <Modal.Body>
          <Form.Row>
          <Form.Group className="selectplugin" as={Col} xs={6} controlId="req_type">
            <Select
                options={requestType}
                searchable={false}
                value={state.selReqtype}
                onChange={value =>setTypeRequest(value)}
            />
            <Form.Label>Request Type<sup>*</sup></Form.Label>
        </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={6} controlId="req_desc">
        <Form.Control type="textarea" placeholder="Enter Desc" autoComplete="off"
        onChange={handleChangeDesc} value={state.req_desc}/>
        <Form.Label>Description</Form.Label>
      </Form.Group>
      </Form.Row>
      <br/>
        <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={sendB2CRequest}>Submit
          </Button>
        </div>
        </Modal.Body>
        </Modal>

        <Modal
        show={state.showPopup}
        onHide={handleemailClose}
        className="importPNRMdl shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5>Share Itinerary</h5>
          <Row>
          <Col xs={10}>
          <Form.Group controlId="uem">
              <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>
            </Form.Group>
          </Col>
          <Col xs={2}>
          <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={sendEmail}>Submit
          </Button>
            </div>
          </Col>
          </Row>
        </Modal.Body>
        </Modal>

        <Modal
        show={state.showIndigoRefund}
        onHide={closeIndigoRefund}
        className="importPNRMdl indigorefundPop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>

          <Modal.Title>Refund Details</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <Form.Row>
        <Form.Group as={Col} xs={12} controlId="ttype" className="inputCheck pt-0 mb-0">
          <div className="d-flex bySegment">
            <Form.Check type="radio" id="passenger" label="By Passenger" className="ml-0" name="applicableOn" checked={state.indigo_refund_type} custom onChange={updateIndRefundType} />
            <Form.Check type="radio" id="segment" label="By Sector" className="ml-3" name="applicableOn" checked={!state.indigo_refund_type} custom onChange={updateIndRefundType} />
          </div>
        </Form.Group>
        </Form.Row>
        <Form.Row>
         {state.showmessage &&
         <span className="errorMessage">Please select same PNR.</span>
         }
        </Form.Row>
        {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
              ) : ""}
        {state.indigo_refund_type &&
        <div className="passengerList">
           {/* <Form.Row>
           Sector : <span>{state.seg_det[0].seg_source} - {state.seg_det[0].seg_destination}</span> {" | "+state.seg_det.length > 1 && <span>{state.seg_det[1].seg_source} - {state.seg_det[1].seg_destination}</span> }
          </Form.Row> */}
          <div className="header">
            <Row>

              <Col xs="3"><span className="titleHeader">Sector</span></Col>

            </Row>
          </div>
          <Row className="pList">
            <Col xs="12"><span>
            {isArrayNotEmpty(state.showRefndSectors) && state.showRefndSectors.map((seg, idx) =>  (
          <>

            {seg.seg_status==="23" &&
            <span className="mr-2 sectOrn">{seg.seg_source +"-"+ seg.seg_destination}</span>
            }

            </>
            ))}

              </span></Col>
            </Row>



<div className="header mt-2">
            <Row>


              <Col xs="4"><span className="titleHeader">Traveller</span></Col>
              <Col xs="4"><span className="titleHeader">Pax Type</span></Col>
              <Col xs="4"><span className="titleHeader">PNR</span></Col>
            </Row>
          </div>


        {isArrayNotEmpty(state.pax_det) && state.pax_det[0].length!==0 && state.pax_det[0].map((obj, ind) => (
          <>
          {obj.sts==="Confirmed" &&
            <React.Fragment key={ind}>
            <Row className="pList">


              <Col xs="4">
                <div className="d-flex">
              <Form.Check type="checkbox" id={obj.trv_id} label="" className="ml-0" name="applicableOn"
                checked={indigopaxList.includes(obj.trv_id)} custom onChange={updateIndPax(obj,obj.split_pnr!==null?"Split":"GDS")} />
                <span className="sectOrn">{obj.pax_name} {obj.is_lead_pax ? "(Lead)" : ""}</span>
                </div>
                </Col>
              <Col xs="4"><span className="sectOrn">{obj.pax_type}</span></Col>
              <Col xs="4"><span className="sectOrn">{obj.split_pnr!==null?obj.split_pnr:obj.gds_pnr}</span></Col>
            </Row>
          </React.Fragment>
          }
          </>
        ))}
      </div>
        }

    {!state.indigo_refund_type &&
        <div className="passengerList">
             <div className="header">
              {state.showSelectAll &&
                <Form.Check type="checkbox" id={"all"} label="Select All" className="ml-0" name="applicableOn"
                checked={state.isIndigoAll} custom onChange={updateIndAllSegs} />
              }
            <Row>
              <Col xs="3"><span className="titleHeader">Sector</span></Col>
              <Col xs="3"><span className="titleHeader  d-flex justify-content-center">Traveller</span></Col>
              <Col xs="3"><span className="titleHeader d-flex justify-content-center">Pax Type</span></Col>
              <Col xs="3"><span className="titleHeader d-flex justify-content-center">PNR</span></Col>
            </Row>
          </div>


        {isArrayNotEmpty(state.refundSeg) && state.refundSeg.map((seg, idx) => (
          <>
<Row className="pList">
          {seg.seg_status==="23" &&
            <React.Fragment key={idx}>
              {seg.pax_data[0].sts === "Confirmed" &&
                <Col  xs="3">
              <Form.Check type="checkbox" id={idx+1} label={seg.seg_source +"-"+ seg.seg_destination} className="ml-0 sectOrn" name="applicableOn"
             checked={seg.selected} custom onChange={()=>updateIndigoSegment(seg)} />
              </Col>
              }
        {isArrayNotEmpty(seg.pax_data) && seg.pax_data.map((obj, ind) => (
          <>
          {obj.sts==="Confirmed" &&
            <React.Fragment key={ind}>
              <Col xs="3"><span className="sectOrn">{obj.pax_name} {obj.is_lead_pax ? "(Lead)" : ""}</span></Col>
              <Col xs="3"><span className="sectOrn d-flex justify-content-center">{obj.pax_type}</span></Col>
              {/* {state.bkng_det[0].flow_type === "split" &&
                <Col xs="3"><span className="sectOrn d-flex justify-content-center">{seg.split_airline_pnr!==null?seg.split_airline_pnr:seg.normal_airline_pnr}</span></Col>
              } */}
              <Col xs="3"><span className="sectOrn  d-flex justify-content-center">{obj.split_pnr!==null?obj.split_pnr:obj.gds_pnr}</span></Col>

          </React.Fragment>
          }
          </>
        ))}
       </React.Fragment>
 }
 </Row>
          </>
        ))}
      </div>
        }

        <br/>
        {state.indigo_refund_type &&
          <div className="d-flex justify-content-end">
            <Button variant="outline-dark" onClick={getIndigoRefundDetails}>{state.enableIndigoRefund?"Continue":"Split PNR"}
          </Button>
        </div>
        }
        {!state.indigo_refund_type &&
          <div className="d-flex justify-content-end">
            <Button variant="outline-dark" onClick={getIndigoRefundDetails}>Continue
          </Button>
        </div>
        }

        </Modal.Body>
        </Modal>

        <Modal
        show={state.showOffline_msg}
        className="importPNRMdl ticketGds"
        backdrop="static"
        keyboard={false}>
              <Modal.Body>
        <div className="popUplayout ticketGdr center">
          <div className="backGroundgif">
<span className='backGroundbot'></span>
<img src={Expire} />
            </div>
              <div className="text-center">
                <strong>A ticket issued from GDS</strong>
                <p>Please call the offline team if you have any further requests <a href="tel:+97444798899">+97444798899</a> </p>
              </div>
              <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={loadOfflineBooking}>OK
          </Button>

          </div>

          </div>
        </Modal.Body>
        </Modal>


           <Footer />
         </>
       ) : <InnerLoader className="summaryDetailsL" />
       }
     </>
   )
 }
 export default FlightSummaryPage