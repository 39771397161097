
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import 'react-input-range/lib/css/index.css';
import Remarks from '../../pages/Remarks';
import HotelDetailMap from '../../map/HotelDetailMap'
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function Map(props) {
  const [state, setState] = useState({})


  return (

    <>
      <Row>
        <Col xs={8}>
          {props.location!==undefined &&
              <HotelDetailMap locations={props.location} hname={props.hname} />
          }
        </Col>
        <Col xs={4}>
          <h5>{props.hname}</h5>
          {props.attractions!==undefined &&
            <Scrollbars style={{ height: 265 }}>
            <Remarks attractions={props.attractions}/>
          </Scrollbars>
          }
        </Col>
      </Row>

    </>
  )
}


export default Map;
