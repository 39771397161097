import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../App";
import Button from '../../common/buttons/Button';
import DateUtils from '../../commonUtils/DateUtils';
import {getBranchList,getTransactionData,companyList } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../common/loader/InnerLoader';
import NoRecord from '../../common/popUps/NoRecord';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../common/ReportMenu';
import DailyReportMenu from '../../common/subMenu/DailyReportMenu';
import CoomonExportExcel from '../../common/CoomonExportExcel';
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
let bookingStatus = require('../../masterData/BookingStatus..json');


/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  branches: [],sel_branch:[], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, tkt_no: "", sel_booking:[],sel_product:[],
  receipt_no: "", applToData: [], companyData: [], branchData: [], applTypData: [], travelData: [],booking_status:[], totals: "",total_sale:0,total_receipt_amt:0
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",bound_type:""
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air transactionsdata List and Add Markup
function TransactionMain(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [transactionsdata, setTransactionData] = useState({ transactionReportList: [] });
  const [dsrExceldata, setDsrExcelData] = useState({ dsrReportExcelList: [] });
  const [products,setProducts] =useState([]);


  useEffect(() => {

    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      if(context.logindata.utype==="sta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }else if(context.logindata.utype==="ta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }
      dispatch({ type: 'userId', payload: context.logindata.uid })
      //Load all branches based on for ATT
      if(context.logindata.utype==="att_user"){
        // const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid }
        if(context.logindata.is_admin){
          AttCommonSerivce.loadBranches({"region_type":context.logindata.region_type}).then(response => {
            if (response.data.suc){
              dispatch({ type: 'branches', payload: response.data.res })
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
          });
        }else{
          AttCommonSerivce.loadBranches({"region_type":context.logindata.region_type,"uid":context.logindata.uid}).then(response => {
            if (response.data.suc){
              dispatch({ type: 'branches', payload: response.data.res })
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in Loadi branches based on Region function---" + error
            );
          });
        }
      }
      let productsList=[];
      if(context.logindata.roles!==undefined){
      if(context.logindata.roles.includes("F_R_P_T")){
        productsList.push({ label: "Flight", value: 'Flight' });
      }
      if(context.logindata.roles.includes("H_R_P_T")){
        productsList.push({ label: "Hotel", value: 'Hotel' });
      }
      if(context.logindata.roles.includes("I_D_R_P")){
        productsList.push({ label: "Insurance", value: 'Insurance' });
      }
      productsList.push({ label: "Receipt", value: 'Receipt' });
      setProducts(productsList);
    }
  }
  }, [context.logindata]);

//   useEffect(()=>{
// if(transactionsdata.transactionReportList.length !== 0){
// dsrExceldata.dsrReportExcelList = transactionsdata.transactionReportList
// let restotal = state.totals
// let total = {
//   transaction_type: "Grand Total",
//   fare: restotal.tot_fare.toFixed(2),
//   tax: restotal.tot_tax.toFixed(2),
//   covid_ext: restotal.tot_covid_ext.toFixed(2),
//   win_spo_ext : restotal.tot_win_spo_ext.toFixed(2),
//   service_fee : restotal.tot_servicefee.toFixed(2),
//   cost: restotal.tot_cost.toFixed(2),
//   markup: restotal.tot_markup.toFixed(2),
//   disc: restotal.tot_disc.toFixed(2),
//   cc_charges : restotal.tot_ccharge.toFixed(2),
//   selling_price: restotal.tot_selling_price.toFixed(2),
//   profit: restotal.tot_profit.toFixed(2),
//   supplier_fee: restotal.tot_supplier_fee.toFixed(2),
// }
//  let excelData = dsrExceldata.dsrReportExcelList.concat(total)
// setDsrExcelData({dsrReportExcelList: excelData})
//   }
//   }, [transactionsdata.transactionReportList] )


  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateSearch=()=>{
    if(fromDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(toDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    return true;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }

  //Handling the search creterai based pn the selection and dates
  const handleSearch = () => {
      if (validateSearch()){
        setTransactionData({ transactionReportList: []})
        setDsrExcelData({dsrReportExcelList: []})
        dispatch({ type: 'totals', payload: "" })
        let cid="";
        if(context.logindata.utype === "att_user" && state.companyData.length!==0){
          cid=state.companyData[0].value;
        }else{
          cid=context.logindata.cid!==0?context.logindata.cid:"";
        }
        setIsLoadingMore(true)
          const req = {
            "region_type":context.logindata.region_type,
            "branch":state.sel_branch.length!==0?state.sel_branch[0].value:"",
            "product": state.sel_product.length!==0?state.sel_product[0].value:"",
            "company": cid,
            "ticketno": state.tkt_no,
            "receptno": state.receipt_no,
            "bk_status": state.sel_booking.length!==0?state.sel_booking[0].value:"",
            "uid": state.userId,
            "utype":"att_user",
            "from_date": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "to_date": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
          }
          getTransactionData(req).then(response => {
            if(response.suc){
              dispatch({ type: 'total_sale', payload: response.res.total_sales })
              dispatch({ type: 'total_receipt_amt', payload: response.res.total_recept_amount })
              for(let val of response.res.data){
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
              }
              setTransactionData({ transactionReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
              setTransactionData({ transactionReportList: [] })
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
      }
    }

  const setCompany = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'companyData', payload: obj })
    }
  }

  const selBookingtype = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'sel_booking', payload: obj })
    }
  }

  const selProducttype = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'sel_product', payload: obj })
    }
  }


  const resetSearch = () => {
    setFromDate("")
    setToDate("")
    dispatch({ type: 'tkt_no', payload: "" })
    dispatch({ type: 'receipt_no', payload: "" })
    dispatch({ type: 'companyData', payload: [] })
    dispatch({ type: 'branchData', payload: [] })
    dispatch({ type: 'sel_branch', payload: [] })
    dispatch({ type: 'sel_booking', payload: [] })
    dispatch({ type: 'sel_product', payload: [] })
  }


  const setSelbranch = (obj) => {
    dispatch({ type: 'companyData', payload: [] })
    if(obj!==undefined && obj.length!==0){
      companyList(obj[0].value).then((res) => {
        let arr = []
        for (let val of res) {
          if (val.type == "TA") {
            let obj = {
              "label": val.label,
              "value": val.value
            }
            arr.push(obj)
          }
        }
        dispatch({ type: 'companies', payload: arr })
        dispatch({ type: 'sel_branch', payload: obj })
      });
    }
  }

  const columns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch',
      text: 'Branch Name',
      sort: false,
      filter: textFilter()
    },
    {
      dataField: 'company',
      text: 'Company Name',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'servicetype',
      text: 'Service',
      sort: false,
      filter: ""
    },
    {
      dataField: 'doc_date',
      text: 'Document Date',
     sort: false,
      filter: ""
    },
    {
      dataField: 'isu_date',
      text: 'Issue Date',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'booking_id',
      text: 'Booking Id',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'booked_by',
      text: 'Booked By',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'pname',
      text: 'Pax Name',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'ticketno',
      text: 'Ticket Number',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'sup_conf_no',
      text: 'Supp Conf',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'bk_status',
      text: 'Booking Status',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'trstype',
      text: 'Form of Pay',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'dcmntno',
      text: 'Trcs Ref Number',
      sort: false,
      filter: textFilter()
    },
    {
      dataField: 'cgst',
      text: 'CGST',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'sgst',
      text: 'SGST',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'igst',
      text: 'IGST',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'tds_amt',
      text: 'TDS',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'selprice',
      text: 'Sale price',
      sort: false,
      filter: ""
    },
    {
      dataField: 'recept_amount',
      text: 'Receipt Amount',
      sort: false,
      filter: ""
    },
    {
      dataField: 'creation_date',
      text: 'creation date',
     sort: false,
      filter: textFilter()
    },
];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


const csvHeaders = [
{ label: 'Region Type', key: 'region_type' },
{ label: 'Branch', key: 'branch' },
{ label: 'Compnay', key: 'company' },
{ label: 'Currency', key: 'currency' },
{ label: 'Sevice', key: 'servicetype' },
{ label: 'Document Date', key: 'doc_date' },
{ label: 'Issue Date', key: 'isu_date' },
{ label: 'Booking Id', key: 'booking_id' },
{ label: 'Booked By', key: 'booked_by' },
{ label: 'Pax Name', key: 'pname' },
{ label: 'Ticket Number', key: 'ticketno' },
{ label: 'Sup Confirn', key: 'sup_conf_no' },
{ label: 'Booking Status', key: 'bk_status' },
{ label: 'Form of Pay', key: 'trstype' },
{ label: 'Trcs Ref Number', key: 'dcmntno' },
{ label: 'CGST', key: 'cgst' },
{ label: 'SGST', key: 'sgst' },
{ label: 'IGST', key: 'igst' },
{ label: 'TDS', key: 'tds_amt' },
{ label: 'Sale Amount', key: 'selprice' },
{ label: 'Receipt Amount', key: 'recept_amount' },
{ label: 'Creation Date', key: 'creation_date' }
];
const paxDate = (dt) => {
  // if(toDate){
    let oneYearDate= new Date(toDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() -1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
//   }else{
//   return new Date(dt.setFullYear(dt.getFullYear()));
//  }
  // return new Date(dt.setFullYear(dt.getFullYear()));
}
const returnDate = ()=>{
  // if(fromDate){
  //   let beforecurrentDate = paxDate(new Date()).setDate(new Date().getDate() - 15)
  //   let fromdateadded = paxDate(fromDate).setDate(fromDate.getDate())
  //   if(fromdateadded < beforecurrentDate ){
      let oneYearDate= new Date(fromDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() + 1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
  //   }
  //  else{
  //    return new Date()
  //  }
  // }
  // else{
  //   return new Date()
  // }
}
  return (

    <>
     <ReportMenu active='Transaction' />
      <Breadcrumb activePage="Transaction Report" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <DailyReportMenu active="Transaction" />
      <div>
        <br/>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
          <Form.Row>
            {context.logindata.utype === "att_user" &&
            <>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={state.branches}
                searchable={true}
                values={state.sel_branch}
                onChange={(value) => (setSelbranch(value))}
              />
              <Form.Label>Branch</Form.Label>
            </Form.Group>
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.companies}
                  searchable={true}
                  values={state.companyData}
                  onChange={(value) => (setCompany(value))}
                />
                <Form.Label>Company</Form.Label>
              </Form.Group>
              </>
            }
              <Form.Group as={Col} xs={3} controlId="tkt_no">
              <Form.Control type="text"  placeholder="Ticket" value={state.tkt_no} onChange={handleChange} />
              <Form.Label>Ticket#</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="receipt_no">
              <Form.Control type="text" placeholder="Tracs Ref#" value={state.receipt_no} onChange={handleChange} />
              <Form.Label>Tracs Ref#</Form.Label>
            </Form.Group>
          </Form.Row>
        <Form.Row>
        <Form.Group className="selectplugin" as={Col} xs={3} controlId="bound_type">
              <Select
                options={products}
                searchable={true}
                values={state.sel_product}
                onChange={(value) => (selProducttype(value))}
              />
              <Form.Label>Product</Form.Label>
            </Form.Group>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="bound_type">
              <Select
                options={bookingStatus}
                searchable={true}
                values={state.sel_booking}
                onChange={(value) => (selBookingtype(value))}
              />
              <Form.Label>Booking Status</Form.Label>
            </Form.Group>
          <Form.Group>
            <Form.Row>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}
                    fromDate={fromDate}
                    toDate={toDate}
                    maxDate={ toDate ? toDate : new Date()}
                    minDate={toDate && paxDate(toDate).setDate(toDate.getDate()) }
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>From Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate && fromDate}
                    maxDate= {returnDate()}
                    className="datefield"
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>To Date<sup>*</sup></Form.Label>
                </Form.Group>

            </Form.Row>
          </Form.Group>

        </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
        <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={handleSearch}
        > Search</Button>
      </div>

      <h4 class="subTitle plusHr mt-3 mb-3">Transaction Report</h4>
       {transactionsdata.transactionReportList!==undefined && transactionsdata.transactionReportList.length !== 0 &&
        <CoomonExportExcel data={transactionsdata.transactionReportList} headers={csvHeaders} show={true} name={"Transaction_Report.csv"}/>
       }
      <div className="cardMainlayout tableRes p-0">
      {isLoadingMore ? <InnerLoader /> : (<>
        {transactionsdata.transactionReportList.length !== 0 &&
        <div className="tablescroll">
          <BootstrapTable
          bootstrap4
          keyField="id"
          classes="markup filterTbl transactionReport"
          data={transactionsdata.transactionReportList}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={transactionsdata.transactionReportList.length >10 && transactionsdata.transactionReportList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
          transactionsdata.transactionReportList.length >25 && transactionsdata.transactionReportList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
          transactionsdata.transactionReportList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
          />
        <div className="cardMainlayout finalTotal">
        <div className="react-bootstrap-table">
        <table className="table table-bordered markup filterTbl">
        <tr>
          <td><span className="totalRec">Total Amount:</span></td>
          <td>{state.total_sale}</td>
          <td>{state.total_recept_amount}</td>
        </tr>
        </table>
        </div>
        </div>
        </div>
          || transactionsdata.transactionReportList.length === 0
          && <NoRecord />}</>)}
      </div>

              </div>
              </div>
<Footer/>
    </>
  )
}
export default TransactionMain