/**
* @description: Flight Confirmation page Root Payment details component
* @date:09-01-2020
* @author:Rambabu
*
*/
import React, { useEffect, useReducer,useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Utilities from '../../../commonUtils/Utilities';
import { isNotNull } from '../../../commonUtils/validators';
import FormateCurrency from '../../../commonUtils/FormateCurrency';


/**
 * Initial State Declaration
 */
const initialState = {

    payobj: ''
};


const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

/**
 * @description: This function will show the PaymentDetails
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
function PaymentDetails(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [paymentData,setPaymentData] = useState(props.paydata)
    
    // useEffect(() => {
    //     //dispatch({ type: 'paxobj', payload: pax_array })
    //     if(paymentData!==undefined && paymentData.length!==0){
    //    if(props.sty==="normal" && props.gst!=="" && props.gst!==undefined){
    //      for(let val of paymentData){
    //         if(val.psta==="Confirmed"){
    //             val.amt=parseFloat(val.amt) + parseFloat(props.gst.total_gst)
    //         }
    //      }
    //    }else if(props.ow_gst!=="" &&  props.ow_gst!==undefined && props.rt_gst!=="" && props.rt_gst!==undefined){
    //     for(let val of paymentData){
    //         if(val.psta==="Confirmed"){
    //         val.amt=parseFloat(val.amt) + parseFloat(props.ow_gst.total_gst) + parseFloat(props.rt_gst.total_gst)
    //         }
    //     }
    //    }
    // }
    // }, []);

    return (
        isNotNull(props.paydata) && (
            <>
                <div className='table-responsive mobileTable'>
                <div className="header">
                    <Row>
                        <Col>Payment Method</Col>
                        <Col>Sur Charge</Col>
                        <Col>Amount</Col>
                        <Col>Payment Status</Col>
                        <Col>Mode of Payment</Col>
                        <Col>Ref Number</Col>
                        <Col>Remarks</Col>
                    </Row>
                </div>
                <div className="passengerList">
                    {props.paydata.map((pay, index) => (
                        (isNotNull(pay.psta) && pay.psta.toUpperCase() !== 'PENDING') && (
                            <React.Fragment key={index}>
                                <div className='list'>
                                {isNotNull(pay.ptype) && (
                                    <Row>
                                        <Col>{pay.formofpay!==undefined && pay.formofpay!=="" && pay.formofpay.includes("Offline")?"Offline Payment":pay.ptype}</Col>
                                        <Col >{pay.csur}</Col>
                                        <Col>{pay.ccode} {<FormateCurrency value={Utilities.priceFormat(parseFloat(pay.amt))} />}</Col>
                                        <Col>{props.st!==undefined && props.st==="Ticketing failed"?"Pending":"Confirmed"}</Col>
                                        <Col>{pay.paymode ? pay.paymode : "-"}</Col>
                                        <Col>{pay.payref ? pay.payref : "-"}</Col>
                                        <Col>{pay.remarks ? pay.remarks : "-"}</Col>
                                    </Row>)}
                                    </div>
                            </React.Fragment>
                        )

                    ))}


                    {/*  <Row>
                        <Col xs="2">{props.paydata.ptype}</Col>
                        <Col xs="3">{props.paydata.ccode}{props.paydata.amt}</Col>
                    </Row> */}



                </div>
                </div>
            </>
        ))
}


export default PaymentDetails;
