import React, { Component, useState, useRef, useContext, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, option } from "react-bootstrap";
// import Button from '../common/buttons/Button';
import { useHistory } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
//import AwardwinLogonew from "../../assets/images/Award2023.png";
import Select from 'react-select';
import { cities, countries, currencies, stdCodes } from '../../components/adminstration/utils/index';
import useOutsideClick from '../common/OutsideClick';
import { Link } from 'react-router-dom';
import { Context } from "../../../App";
import AttCommonSerivce from '../../services/commonService/AttCommonSerivce';
import AwardwinLogonew from '../../../../src/client/assets/images/Award2023.png';
import HeaderInfo from '../../components/common/headerInfo/HeaderInfo'
import FormateCurrency from '../commonUtils/FormateCurrency';
export const USER_AUTH_DATA = "userAuthData";
export const ATT_BRANCHES = "attBranches";
export const ATT_COMPANIES = "attCompanys";
export const BOOKING_SERVICE = "bkServiceFee";
export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const COMP_ID = "COMP_ID";
export const PLB_DISCOUNT = "PLB_DISCOUNT";
export const STA_SERVICE_FEE = "staFee";
export const REISUE_RESP = "reisueResp";
export const CLIENT_IP = "clientIp";
export const PAY_DATA = "payData";
export const PAY_REQ = "payRequest";
export const PAY_RESP = "payResp";
export const ONFLY_FLT_MKP = "onflyFltMkp";
export const SEL_AIR_PRICE = "selAirPrice";
export const PRODUCT_TYPE = "productType";
export const PAY_DONE = "payDone";
export const IS_PACKAGE = "isPack";
export const USERSTATE = "userState";
export const SHOW_NOTIFY_HOTEL = "showNotifyHotel";
export const SHOW_NOTIFY = "showNotify";
export const EDIT_DATA = "editData";
export const EDIT_TICKET_DATA = "editTicketData";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
export const IS_LATEST = "IS_LATEST";
export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
export const SUMMARY_COMP_REGION = "SUMMARY_COMP_REGION";
export const COUNTRY_DATA = "COUNTRY_DATA";
const initialState = {
  old_pwd: '', new_pwd: '', confirm_pwd: '', notiMessage: "", notiMessageShow: false, notiVarient: "", loader: false, new_pwd_err: "", conf_pwd_err: "", old_pwd_err: "", quick_search_varient: '', quick_search_lable: ''
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, old_pwd: '', new_pwd: '', confirm_pwd: '', notiMessage: "", notiMessageShow: false, notiVarient: "", loader: false, new_pwd_err: "", conf_pwd_err: "", old_pwd_err: "", quick_search_varient: '', quick_search_lable: ''
      };
    default:
      console.log('returnnn', state)
      return { ...state, [action.type]: action.payload };
  }
};
function Header(props) {
  const [init, dispatch] = useReducer(reducer, initialState);
  const { state } = props || {
    bookingFlow: true,
    adminstration: true,
    dashBorad: true,
    rolemanagement: true
  }
  /* load language type from local storage */
  let lan = localStorage.getItem('language');

  /* if language is null put language type in loclastore */
  if (lan === null || lan === undefined) {
    localStorage.setItem('language', 'en');
    lan = 'en';
  }
  //console.log('selected language in header js is----' + lan);
  if (lan === 'ar') {
    var { cnf, review, search } = require('../../../locale/ar/index');
  } else {
    var { cnf, review, search } = require('../../../locale/en/index');
  }
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [activeHeader, setActiveHeader,] = useState(props.activeHeader);
  const [mainNav, setmainNav,] = useState(false);
  const [language_list, setLanguage_list,] = useState([{ label: "EN", value: "en" }, { label: "AR", value: "ar" }]);
  const ref = useRef();
  const [response, setResponse] = useState(context.res)
  const [attcredit, setAttCredit] = useState([])
  const [domin, setDomain] = useState([])
  const [intitalLang, setIntialLang] = useState([])
  const [loader, setLoader] = useState(false)
  const [cl, setCL] = useState([])
  const [path, setPath] = useState()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openLink, setOpenLink] = useState(false);
  const [typeselect, setTypeselect] = useState({
    selectedOption: null,
  });
  const [showHeader, setShowHeader] = useState(true);
  //================================
  // Fetch All Master Currencies
  //================================
  useEffect(() => {
    const domain = currencies();
    setDomain(domain)
    setIntialLang([{
      "value": "QAR",
      "label": "QAR"
    }])
  }, [currencies()])

  useEffect(() => {
    if(context.logindata.region_type!==null && context.logindata.region_type!==undefined){
      setIntialLang([{
        "value": context.logindata.cur,
        "label": context.logindata.cur
      }])
    }
  }, [context.logindata.region_type])

  useEffect(() => {
    let url_array = window.location
    console.log('urllll::::header', JSON.parse(JSON.stringify(url_array.pathname)))
    setPath(url_array.pathname)
    if(JSON.parse(JSON.stringify(url_array.pathname))==="/ezytrip/dashboard"){
      setShowHeader(false)
    }
  }, [window.location])

  useEffect(() => {
    if (context.logindata && context.logindata.utype == "ta_user" && !context.logindata.is_bal) {
      let pyload = { "pid": 0, "comid": context.logindata.cid, "cuscode": context.logindata.cuscode }
      AttCommonSerivce.getCLBforATT(pyload).then(response => {
        console.log('responsive for intil  flow', response)
        if (response.data.suc) {
          var userData = localStorage.getItem(USER_AUTH_DATA);
          var resObj = JSON.parse(userData);
          resObj.cbal = response.data.res.data.cbal;
          resObj.is_bal = true;
          localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
          if (resObj) {
            setContext({ logindata: resObj });
          }
          // let array = []
          // if (response.data.res.data) {
          //   let val = response.data.res.data
          //   let obj = {
          //     "value": val.cbal,
          //     "cur": val.cur ? val.cur : "QAR"
          //   }
          //   array.push(obj)
          //   setCL(array)
          //   props.creditData(array)
          // }
        }
      }).catch(function (error) {

      })
    }
  }, [context.logindata])
  const [quicksearch, setQuickSearch] = useState('')
  //console.log("Header :"+context.logindata.nm)

  const openMainNav = () => {
    if (mainNav) {
      setmainNav(false)
    } else {
      setmainNav(true)
    }
  }
  useOutsideClick(ref, () => {
    if (mainNav) setmainNav(false);
  });
  const redirect = (e) => {
    try {
      //var domainpatredirecth = "att";
      e.preventDefault();
      if (e.target.id === 'dashboard') {
        if (context.logindata.utype === "att_user") {
          var userData = localStorage.getItem(USER_AUTH_DATA);
          var resObj = JSON.parse(userData);
          resObj.bid = 0;
          resObj.cid = 0;
          updateContext(resObj);
        }
        history.push('/ezytrip/dashboard');
      } else if (e.target.id === 'booking') {
        history.push('/ezytrip/dashboard/manualbooking');
      } else if (e.target.id === 'report') {
        if(context.logindata !== ""){
          if(context.logindata.roles.split(",").includes("F_R_P_T")){
          history.push('/ezytrip/administration/flightreport');
        }
        else if(context.logindata.roles.split(",").includes("H_R_P_T")){
            if(context.logindata.roles.split(",").includes("F_R_P_T") && context.logindata.roles.split(",").includes("D_F_R_T")){
              history.push('/ezytrip/administration/flightreport');
              }
              else if((!context.logindata.roles.split(",").includes("F_R_P_T") || !context.logindata.roles.split(",").includes("D_F_R_T")) && (context.logindata.roles.split(",").includes("H_R_P_T") && context.logindata.roles.split(",").includes("D_H_R_T"))){
                history.push('/ezytrip/administration/hotelreport');
                }
          }
        else if(context.logindata.roles.split(",").includes("M_Y_R")){
          if(context.logindata.roles.split(",").includes("F_R_P_T") && context.logindata.roles.split(",").includes("D_F_R_T")){
          history.push('/ezytrip/administration/flightreport');
          }
           else if((!context.logindata.roles.split(",").includes("F_R_P_T") || !context.logindata.roles.split(",").includes("D_F_R_T")) && (context.logindata.roles.split(",").includes("H_R_P_T") && context.logindata.roles.split(",").includes("D_H_R_T"))){
            history.push('/ezytrip/administration/hotelreport');
            }
            else if((!context.logindata.roles.split(",").includes("F_R_P_T") || !context.logindata.roles.split(",").includes("D_F_R_T")) && (!context.logindata.roles.split(",").includes("H_R_P_T") || !context.logindata.roles.split(",").includes("D_H_R_T"))){
                        history.push('/ezytrip/administration/myreport');
            }
          }
        }
      } else if (e.target.id === 'admin') {
        //Organization/CM
        if (context.logindata.roles && context.logindata.roles.split(",").includes('O_R')) {
          if (context.logindata.roles && context.logindata.roles.split(",").includes('B_C') && (context.logindata.utype && context.logindata.utype == "att_user")) {
            history.push('/ezytrip/administration/branch');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_C')) {
            history.push('/ezytrip/administration/company');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_T') && (context.logindata.utype && context.logindata.utype == "att_user")) {
            history.push('/ezytrip/administration/category');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_F')) {
            history.push('/ezytrip/administration/customFields');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_C_M')&& (context.logindata.utype && context.logindata.utype == "att_user")) {
            history.push('/ezytrip/administration/CountryMaster');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_O_M') && (context.logindata.utype && context.logindata.utype == "att_user")) {
            history.push('/ezytrip/administration/oidMaster');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_M_O_H') && (context.logindata.utype && context.logindata.utype == "att_user")) {
            history.push('/ezytrip/administration/oidMaxHits');
          }

        }

        //Master Service
        else if (context.logindata.roles && context.logindata.roles.split(",").includes('M_S') && context.logindata.utype !== "sta_user") {
          if (context.logindata.roles && context.logindata.roles.split(",").includes('U_M')) {
            history.push('/ezytrip/administration/user');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('R_M')) {
            history.push('/ezytrip/administration/role');
          }

        }

        //Air Services
        else if (context.logindata.roles && context.logindata.roles.split(",").includes('A_S')) {
          if (context.logindata.roles && context.logindata.roles.split(",").includes('M_U')) {
            history.push('/ezytrip/administration/markup');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('S_F')) {
            history.push('/ezytrip/administration/serviceFee');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('D_C')) {
            history.push('/ezytrip/administration/dealCode');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('C_M')) {
            history.push('/ezytrip/administration/commission');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('O_I')) {
            history.push('/ezytrip/administration/officeid');
          }
          else if (context.logindata.roles && context.logindata.roles.split(",").includes('D_S_R')) {
            history.push('/ezytrip/administration/dsrreport');
          }
        }

        //Payment & Billing
        else if (context.logindata.roles && context.logindata.roles.split(",").includes('P_B')) {
          if (context.logindata.roles && context.logindata.roles.split(",").includes('C_R')) {
            history.push('/ezytrip/administration/creditmanagement');
          }
        }


      }
      else if (e.target.id === 'logout') {
        localStorage.removeItem(USER_AUTH_DATA);
        localStorage.removeItem(ATT_BRANCHES);
        localStorage.removeItem(ATT_COMPANIES);
        localStorage.removeItem(BOOKING_SERVICE);
        localStorage.removeItem(HTL_REQUEST);
        localStorage.removeItem(HTL_MARKUP);
        localStorage.removeItem(HTL_CHD_AGES);
        localStorage.removeItem(COMP_ID);
        localStorage.removeItem(STA_SERVICE_FEE);
        localStorage.removeItem(PLB_DISCOUNT);
        localStorage.removeItem(REISUE_RESP);
        localStorage.removeItem(CLIENT_IP);
        localStorage.removeItem(PAY_DATA);
        localStorage.removeItem(PAY_REQ);
        localStorage.removeItem(PAY_RESP);
        window.sessionStorage.removeItem(ONFLY_FLT_MKP);
        window.sessionStorage.removeItem(SEL_AIR_PRICE);
        window.sessionStorage.removeItem(PAY_DONE);
        window.sessionStorage.removeItem(PRODUCT_TYPE);
        window.sessionStorage.removeItem(IS_PACKAGE);
        sessionStorage.removeItem("CMPID")
        localStorage.removeItem(USERSTATE);
        localStorage.removeItem(SHOW_NOTIFY_HOTEL);
        localStorage.removeItem(SHOW_NOTIFY);
        localStorage.removeItem(EDIT_DATA);
        localStorage.removeItem(EDIT_TICKET_DATA);
        window.sessionStorage.removeItem(SEL_SALAM_ONE)
        window.sessionStorage.removeItem(SEL_SALAM_TWO)
        window.sessionStorage.removeItem(IS_LATEST)
        localStorage.removeItem(COUNTRY_DATA)
        localStorage.removeItem(SUMMARY_COMP_ID)
        window.sessionStorage.removeItem(SUMMARY_COMP_REGION)
        history.push('/ezytrip/login');
      }
    } catch (err) {
      console.log("error", err)
    }
  }
  const dashBorad = (e) => {
    if (context.logindata.utype === "att_user") {
      var userData = localStorage.getItem(USER_AUTH_DATA);
      var resObj = JSON.parse(userData);
      if(resObj!==null){
      resObj.bid = 0;
      resObj.cid = 0;
      updateContext(resObj);
      }
    }
    sessionStorage.removeItem("CMPID")
    return window.location = "/ezytrip/dashboard";
  }

  const updateContext = (resObj) => {
    localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
    if (resObj) {
      setContext({ logindata: resObj });
    }
  }

  /* set index for selected language display*/
  let index_val = 0;
  if (lan === 'ar') {
    index_val = 1;
  } else {
    index_val = 0;
  }

  const handleChange = (e) => {

    const { id, value } = e.target;
    console.log('Entered handleChange id---' + id);
    console.log('Entered handleChange value---' + value);
    if (id === 'com_id') {
      setQuickSearch(value)
      console.log('value', quicksearch)
    }

    // return history.push("/ezytrip/dashboard/bookingList);
  }
  const quickSearch = (e) => {
    console.log("key", e)
    if (e && e.key == 'Enter' && init.quick_search_varient) {
      if (quicksearch) {
        // history.push({
        //   pathname: '/ezytrip/dashboard/bookingList',
        //   state: {
        //     req: quicksearch
        //   }
        // });
        window.open(`/ezytrip/dashboard/bookingList?${init.quick_search_varient}=` + quicksearch);
      }
    } else {
      if (init.quick_search_varient == '') {
        dispatch({ type: "notiMessage", payload: "Please select Type of Search and Value to be searched" })
        dispatch({ type: "notiVarient", payload: "danger" })
        dispatch({ type: "notiMessageShow", payload: true })
      }

    }
  }
  const clickSearch = () => {
    if (quicksearch && init.quick_search_varient) {
      // history.push({
      //   pathname: '/ezytrip/dashboard/bookingList',
      //   state: {
      //     req: quicksearch
      //   }
      // });
      window.open(`/ezytrip/dashboard/bookingList?${init.quick_search_varient}=` + quicksearch);
    } else {
      dispatch({ type: "notiMessage", payload: "Please select Type of Search and Value to be searched" })
      dispatch({ type: "notiVarient", payload: "danger" })
      dispatch({ type: "notiMessageShow", payload: true })
    }
  }
  /**
   * @description:This event will when we change the language
   * @author: Rambabu
   * @param {*}
   * @function language event
   * @date : 09-09-2020
   */
  const languageEventt = (e) => {
    console.log('languageEventt-----' + e.value)
    if (e !== undefined && e !== '' && e !== null) {
      if (e.value === 'en') {
        localStorage.setItem('language', 'en');
        var { cnf, review, search } = require('../../../locale/en/index');
        props.languageData('en');
      } else {
        localStorage.setItem('language', 'ar');
        var { cnf, review, search } = require('../../../locale/ar/index');
        props.languageData('ar');
      }
    }
  }
  //  set credit balance for ATT company selected
  const setCredit = (e) => {
    setLoader(true)
    if (e && e.length > 0) {
      setAttCredit(e)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const bookingRespDetail = (res) => {
    props.bookingListRes(res)
  }

  /**
   * @description : Change pwd tag data is inserted or changed
   * @author : azamuddin
   * @date: - 03-06-2021
 -  */
  const handleChangePwd = (e) => {
    const { id, value } = e.target
    //  if(id && value) {
    dispatch({ type: id, payload: value })
    //  }
  }

  /**
   * @description : this function checks validation for change password
   * @author : Azamuddin
   * @returns ---- success ? true  : false
   */
  const validation = (e) => {
    if (init.old_pwd == '') {
      dispatch({ type: "old_pwd_err", payload: "Please Enter Old Password" })
      return false
    } else {
      dispatch({ type: "old_pwd_err", payload: "" })
    } if (init.new_pwd == '') {
      dispatch({ type: "new_pwd_err", payload: "Please Enter New Password" })
      return false
    } else {
      dispatch({ type: "new_pwd_err", payload: "" })
    }
    if (init.confirm_pwd == '') {
      dispatch({ type: "conf_pwd_err", payload: "Please Enter Confirm Password" })
      return false
    } else {
      dispatch({ type: "conf_pwd_err", payload: "" })
    }

    if (init.new_pwd.length > 4 && init.new_pwd.length < 17) {
      if (init.new_pwd == init.confirm_pwd) {
        return true
      } else {
        dispatch({ type: "conf_pwd_err", payload: "New and Confirmed Password should be same" })
        return false
      }
    } else {
      dispatch({ type: "new_pwd_err", payload: "New Password length should be in between 5 to 16" })
      return false
    }
  }
  const dispalceFunction = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
      dispatch({ type: "clear", payload: true })
    }, 3000);
  }
  const openPopUp = () => {
    if (openLink) {
      setOpenLink(false)
    } else {
      setOpenLink(true)
    }
  }
  useOutsideClick(ref, () => {
    if (openLink) setOpenLink(false);
  });

  const changePWD = (e) => {
    // old_pwd:'',new_pwd:'',confirm_pwd:''
    let valid = validation()
    if (valid) {
      init.loader = true
      dispatch({ type: "loader", payload: init.loader })
      let req_json = {
        email: context.logindata.unm, //user email
        pwd: init.old_pwd, //old password
        newPwd: init.new_pwd, //new password
        confirmPwd: init.confirm_pwd,
        pftype: "B2B",
        isbelongto: "B2B"
      }
      AttCommonSerivce.changePWD(req_json).then(response => {
        if (response.data && response.data.suc) {
          dispatch({ type: "notiMessage", payload: "Password Changed Successfully!" })
          dispatch({ type: "notiVarient", payload: "success" })
          dispatch({ type: "notiMessageShow", payload: true })

          dispalceFunction()
          handleClose()

        } else {
          dispatch({ type: "notiMessage", payload: "Change Password Unsuccessful" })
          dispatch({ type: "notiVarient", payload: "danger" })
          dispatch({ type: "notiMessageShow", payload: true })
          dispalceFunction()
        }
      })
    }

  }
  const SelectOptions = [
    { label: 'Airline PNR', value: 'airlinepnr' },
    { label: 'GDS PNR', value: 'gdspnr' },
    { label: 'Last Name', value: 'lastname' },
    { label: 'TKT Number', value: 'tkt_num' },
    { label: 'Booking ID', value: 'booking_id' },
    { label: 'Hotel Confi.. No', value: 'hotel_num' },
    {label: 'Policy No', value:'policy_number'}
  ];
  const selectChange = selectedOption => {
    setTypeselect({ selectedOption });
    if (selectedOption) {
      setQuickSearch('')
      dispatch({ type: "quick_search_lable", payload: selectedOption.label })
      dispatch({ type: "quick_search_varient", payload: selectedOption.value })
    }
    console.log(`Option selected:`, selectedOption);
  };
  return (
    // console.log('header js language is---------',path),

    <>

      {init.notiMessageShow && (
        <Alert className="notification" variant={init.notiVarient} onClose={() => dispatch({ type: "notiMessageShow", payload: false })} dismissible> {init.notiMessage}</Alert>
      )}


      <header className={"dashBordHeader "+showHeader}>
        <div className="headerTop">

          <div className="container">


            <div className="hraderRight d-flex">

              <Link className="app-header-logo mr-4" to="/ezytrip/dashboard" onClick={dashBorad}>

                {context.logindata.utype === "att_user" &&
                  <img
                    src={activeHeader === "dashbord" ? logo : logo}
                    title="EZY Trip"
                    alt="EZY Trip"
                  />
                }
                {context.logindata.utype !== "att_user" && (context.logindata.comp_logo_url === "" || context.logindata.comp_logo_url === null) &&
                  <img
                    src={activeHeader === "dashbord" ? logo : logo}
                    title="EZY Trip"
                    alt="EZY Trip"
                  />
                }
                {context.logindata.utype !== "att_user" && context.logindata.comp_logo_url !== "" && context.logindata.comp_logo_url !== null &&
                  <img width="20%" height="20%"
                    src={activeHeader === "dashbord" ? context.logindata.comp_logo_url : context.logindata.comp_logo_url}
                    title="EZY Trip"
                    alt="EZY Trip"
                  />
                }
              </Link>
              <div className="hedeartopRightcont">
              <div className='AwardwinLogo shake'>
                  <img src={AwardwinLogonew} title="Winner-2022" alt="bannerinnerimage" />

                </div>
                <div className="wallatDetails">
                  <div className="user ezyIcon icon-user">{context.logindata.nm}</div>
                  {path && (!path.includes("/administration") && !path.includes("/confirmation")) &&
                    <>
                      {context.logindata.utype === "sta_user" && cl.length > 0 &&
                        <div className="myWallet ezyIcon icon-riyadh"><FormateCurrency value={cl[0].value} /> {context.logindata.cur}</div>
                      }
                      {loader ? (console.log("hi loader got true")) : (
                        <>
                          {attcredit && attcredit.length > 0 &&
                            <div className={context.logindata.cur==='QAR'? 'myWallet ezyIcon icon-riyadh':'myWallet ezyIcon icon-rupee'}><FormateCurrency value={attcredit[0].value} /> {context.logindata.cur}</div>
                          }
                         {attcredit.length ===0 && context.logindata.cid!==0 && context.logindata.cbal!==null && context.logindata.cbal!==undefined &&
                         <div className={context.logindata.cur==='QAR'? 'myWallet ezyIcon icon-riyadh':'myWallet ezyIcon icon-rupee'}><FormateCurrency value={context.logindata.cbal} /> {context.logindata.cur}</div>}
                        </>
                      )
                      }
                    </>
                  }
                </div>

                  <div className='domainContactsec d-flex'>
                {path && !path.includes("/administration") &&
                  <div className="domins">
                    <Form.Group controlId="domin">
                      <Select
                        options={intitalLang}
                        className='react-select-container'
                        classNamePrefix="react-select"
                        isSearchable={false}
                        value={intitalLang}
                        // placeholder = "Currency"
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#F0F0F0',
                            primary: '#00367A',
                          },
                        })}
                        onChange={(value) => setIntialLang(value)}
                      />
                    </Form.Group>




                  </div>
                }

                <div className="wallatDetails mr-1 showDesktop contactSec">
                  {/* <div className="showUp ezyIcon icon-call-service">Contact Us </div> */}

                  <div className={"topMenu d-flex align-items-center " + (openLink ? 'active' : 'inactive')} ref={ref} onClick={openPopUp}><span className="showUp ezyIcon icon-support">24X7 Support <span className="ezyIcon icon-down-arrow"></span></span>


                    <nav className={"navigation d-flex align-items-center " + (openLink ? 'active' : 'inactive')}>
                      <ul className="d-flex flex-column">
                        <li><a id="phone" href="tel:974 44798899" className="navbar-brand ezyIcon icon-contactUs"> 974 44798899</a></li>
                        <li><a id="whatsApp" href="tel:+974 5572 2351" className="navbar-brand ezyIcon icon-whatsapp"> +974 5572 2351</a></li>
                        <li><a id="mail" href="mailto:support@ezytrip.com" className="navbar-brand ezyIcon icon-new-email">support@ezytrip.com</a></li>
                      </ul>
                    </nav>

                  </div>

                </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div className='headerTopmenu'>
        <div className="container">
          <div className="app-header-wrapper d-flex align-items-center justify-content-between">
            <div className="search mr-3">
              <div className="domins airlinePnrsearch">
                <Form.Group className="mb-0">
                  <Select
                    className='react-select-container'
                    classNamePrefix="react-select"
                    placeholder="Quick Search"
                    isSearchable={true}
                    onChange={selectChange}
                    options={SelectOptions}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F0F0F0',
                        primary: '#00367A',
                      },
                    })}
                  />
                </Form.Group>




              </div>
              {/* <input className="form-control" onChange={handleChange} placeholder="PNR / L.Name / TKT#" /> */}
              {init.quick_search_varient &&
                <>
                  <Form.Control type="text" id="com_id" placeholder={init.quick_search_lable + "..."} value={quicksearch} onKeyPress={quickSearch} onChange={handleChange} />
                  <button className="searchIcon ezyIcon icon-search" color="#888888" size={16} onClick={clickSearch} icon="search"></button>
                </>
              }
            </div>


            <div className="hraderRight d-flex">

               <div className='deskTop d-flex'>



              {(context.logindata.utype === "att_user" || context.logindata.utype === "ta_user") &&
                <HeaderInfo bookingListRes={bookingRespDetail} contextData={context.logindata} selBranch={props.branchId} creditData={setCredit} selCompany={props.companyId} />
              }

                {/* <div className="domin  mr-3">
                <Select
                options={language_list}
                className='react-select-container'
                classNamePrefix="react-select"
                defaultValue={language_list[index_val]}
                isSearchable={false}
                onChange={languageEventt}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#F0F0F0',
                    primary: '#00367A',
                  },
                 })}
                 /></div> */}

                <div className={"topMenu ezyIcon icon-" + (!mainNav ? 'menu1 ' : 'navclose ') + (mainNav ? 'active' : 'inactive')} ref={ref} onClick={openMainNav}>


                <nav className={"navigation dashBoadnav d-flex align-items-center " + (mainNav ? 'active' : 'inactive')}>
                  <ul className="d-flex flex-column">
                    <li><a id="dashboard" href="/ezytrip/dashboard" onClick={redirect} className="navbar-brand ezyIcon icon-dashboard"> Dashboard</a></li>
                    {/* <li><a id="dashboard" href="#" onClick={redirect} className="navbar-brand"><Icon color="#00367A" size={14} icon="report" /> Reports</a></li> */}
                    {context.logindata.roles && context.logindata.roles.split(",").includes('A_N') && <li><a id="admin" href="/ezytrip/administration/branch" onClick={redirect} className="navbar-brand ezyIcon icon-Admin"> Administration</a></li>}
                    {context.logindata.roles && context.logindata.roles.split(",").includes("R_P_T") &&
                    <li><a id="report" href="/ezytrip/administration/flightreport" onClick={redirect} className="navbar-brand ezyIcon icon-favoritenotes"> Reports</a></li>
                    }
                    {context.logindata.roles && context.logindata.roles.split(",").includes('M_B') && <li><a id="booking" href="/ezytrip/dashboard/manualbooking" onClick={redirect} className="navbar-brand ezyIcon icon-booking">Flight Manual Booking</a></li>}
                    {context.logindata.roles && context.logindata.roles.split(",").includes('H_M_B') &&
                    <li><a id="#" href="/ezytrip/dashboard/hotelmanual" className="navbar-brand passw ezyIcon icon-hotel">Hotel Manual Booking</a></li>
                    }
                    <li><a id="logout" href="#" onClick={handleShow} className="navbar-brand passw ezyIcon icon-luckpass"> Change Password</a></li>
                    <li><a id="logout" href="#" onClick={redirect} className="navbar-brand ezyIcon icon-logout"> Log out</a></li>

                  </ul>
                </nav>

              </div>
              </div>
            </div>



          </div>

          <div className='mobileIssue'>

          <div className="hraderRight d-flex">
            <div className='desktop'>

           {(context.logindata.utype === "att_user" || context.logindata.utype === "ta_user") &&
  <HeaderInfo contextData={context.logindata} selBranch={props.branchId} creditData={setCredit} selCompany={props.companyId} />
}

  {/* <div className="domin  mr-3">
  <Select
  options={language_list}
  className='react-select-container'
  classNamePrefix="react-select"
  defaultValue={language_list[index_val]}
  isSearchable={false}
  onChange={languageEventt}
  theme={theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#F0F0F0',
      primary: '#00367A',
    },
   })}
   /></div> */}

  <div className={"topMenu ezyIcon icon-" + (!mainNav ? 'menu1 ' : 'navclose ') + (mainNav ? 'active' : 'inactive')} ref={ref} onClick={openMainNav}>


  <nav className={"navigation d-flex align-items-center " + (mainNav ? 'active' : 'inactive')}>
    <ul className="d-flex flex-column">
      <li><a id="dashboard" href="/ezytrip/dashboard" onClick={redirect} className="navbar-brand ezyIcon icon-dashboard"> Dashboard</a></li>
      {/* <li><a id="dashboard" href="#" onClick={redirect} className="navbar-brand"><Icon color="#00367A" size={14} icon="report" /> Reports</a></li> */}
      {context.logindata.roles && context.logindata.roles.split(",").includes('A_N') && <li><a id="admin" href="/ezytrip/administration/branch" onClick={redirect} className="navbar-brand ezyIcon icon-Admin"> Administration</a></li>}
      {context.logindata.roles && context.logindata.roles.split(",").includes("R_P_T") &&
      <li><a id="report" href="/ezytrip/administration/flightreport" onClick={redirect} className="navbar-brand ezyIcon icon-favoritenotes"> Reports</a></li>}
      {context.logindata.roles && context.logindata.roles.split(",").includes('M_B') && <li><a id="booking" href="/ezytrip/dashboard/manualbooking" onClick={redirect} className="navbar-brand ezyIcon icon-booking">Flight Manual Booking</a></li>}
      {context.logindata.roles && context.logindata.roles.split(",").includes('H_M_B') &&
      <li><a id="#" href="/ezytrip/dashboard/manualbookinghotel" className="navbar-brand passw ezyIcon icon-hotel">Hotel Manual Booking </a></li>
      }
      <li><a id="logout" href="#" onClick={handleShow} className="navbar-brand passw ezyIcon icon-luckpass"> Change Password</a></li>
      <li><a id="logout" href="#" onClick={redirect} className="navbar-brand ezyIcon icon-logout"> Log out</a></li>

    </ul>
  </nav>

</div>
</div>
</div>
          </div>
        </div>
        </div>
        <Modal show={show} onHide={handleClose} className="changePassword" >
          <Modal.Header closeButton>
            <Modal.Title className="ezyIcon icon-luckpass"> Change Password ?</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pt-0">
            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="old_pwd">
                <Form.Control type="password" className="companyName" value={init.old_pwd} placeholder="Enter User name" name="companyName" autoComplete="off" onChange={handleChangePwd} autoFocus />
                <Form.Label>Old Password</Form.Label>
                {/* // new_pwd_err:"",conf_pwd_err:"",old_pwd_err:"" */}
                {init.old_pwd_err !== "" && (
                  <p className="vError">{init.old_pwd_err}</p>
                )}
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="new_pwd">
                <Form.Control type="password" className="companyName" value={init.new_pwd} placeholder="Enter Contact Name" name="contactName" autoComplete="off" onChange={handleChangePwd} autoFocus />
                <Form.Label>New Password</Form.Label>
                {init.new_pwd_err !== "" && (
                  <p className="vError">{init.new_pwd_err}</p>
                )}

              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="confirm_pwd">
                <Form.Control type="text" className="companyName" value={init.confirm_pwd} placeholder="Enter Contact Name" name="contactName" autoComplete="off" onChange={handleChangePwd} autoFocus />
                <Form.Label>Confirm New Password</Form.Label>
                {init.conf_pwd_err !== "" && (
                  <p className="vError">{init.conf_pwd_err}</p>
                )}
              </Form.Group>
            </Form.Row>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" isDisabled={init.loader} onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" className={(init.loader ? 'progress-bar-striped progress-bar-animated' : '')} isDisabled={init.loader} onClick={changePWD}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>

  );
};



export default Header;