import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Company CURD Operations service
 * @param {*} Service 
 */
class CompanyCreationService {
    
    async getCompanyData(id) {
        return await axios.get(`${API_URL}/att/company/retrieve/${id}`);
    }

    async saveCompanyDetails(compData) {
        return await axios.post(`${API_URL}/att/company/create`,compData)
    }

    async editCompanyDetails(id) {
        return await axios.get(`${API_URL}/att/company/edit/${id}`)
    }

    async updateCompanyDetails(compnyData) {
        return await axios.put(`${API_URL}/att/company/update/${compnyData.creq.id}`,compnyData)
    }

    async deleteContactDetails(id) {
        return await axios.delete(`${API_URL}/att/company/delete?contactid=${id}`)
    }

    async companySearch(obj) {
        return await axios.post(`${API_URL}/att/company/search`,obj)
    }



}
export default new CompanyCreationService()