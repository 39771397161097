import React, { useReducer, useState } from 'react';
import ConfirmationBookingPdf from './ConfirmationBookingPdf';
import FlightSectors from '../common/FlightSectors';
import PassengerDetails from './PassengerDetails';
import TFConfirmation from './TFConfirmation';
const initialState = { mtaList:[]};
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };
const FlightItinerarypdf = React.forwardRef((props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cur, setCur] = useState("QAR")
  const [paxList, setPaxList] = useState([])
  const [fareList, setFareList] = useState([])
  const [refundList, setRefundList] = useState([])
  const [voidList, setVoidList] = useState([])
  const [onwardList, setOnwardList] = useState([])
  const [returnList, setReturnList] = useState([])
  const [refundPaxList, setRefundPaxList] = useState([])
  const [voidPaxList, setVoidPaxList] = useState([])

  return (
    <div className="bg-gray-200 p-6" title={"FLIGHT ITINERARY"} ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
            <h2 className="mb-4">
                 FLIGHT ITINERARY
                </h2>
                </div>
                {props.confirmationObj !== undefined &&
               <ConfirmationBookingPdf confirmationObj={props.confirmationObj}  company_logo={props.compLogo} phone_num={props.phone_num} customer={props.customer} emialId={props.emialId}/>
               }

              <div className="pdfflightDetails">
              <div id="pagewidth">
              <div id="sitecontent">
              <cfloop
				index="intPara"
				from="1"
				to="20"
				step="1">

{!props.confirmationObj.tf_flow &&
             <>
             <div className="resultSection bg-white boxShadow mb-4 p-2 mt-4">
               <h5 className="title">Booking Details</h5>
               {props.confirmationObj.origns.map((sector, idx) => (
                 <div>
                   {sector['srcorigns'] &&
                     <FlightSectors origin={[sector['srcorigns'][0], 'Confirm']}
                       mapping={props.cobj.mappings}
                       tripType={props.confirmationObj.tt}
                       bktype={props.confirmationObj.bktype}
                       originsList={[props.confirmationObj.origns[0], 'Confirm']} confirmation={true}/>
                   }
                   {sector['destorigins'] &&
                     <FlightSectors origin={[sector['destorigins'][0], 'Confirm']}
                       mapping={props.cobj.mappings}
                       tripType={props.confirmationObj.tt} bktype={props.confirmationObj.bktype} confirmation={true}/>
                   }
                 </div>
               ))}
               <br />
             </div>
             {/* Passenger details section */}
             {/* comfirmation page passenger details for split fare onward pax and return pax we are showing seperatly */}
             <div className="resultSection boxShadow bg-white PassengerDetails mb-4 p-0">
               <h5 className="title">Passenger Details</h5>
               <PassengerDetails sector={props.confirmationObj.tt} sty={props.confirmationObj.sty} paxdata={props.confirmationObj} flowType={ props.cobj!==undefined && props.cobj.response.bookRs!==undefined &&  props.cobj.response.bookRs.data!==undefined &&  props.confirmationObj.bktype} tf_flow={props.confirmationObj.tf_flow}  sup={props.cobj!==undefined && props.cobj.response.bookRs!==undefined &&  props.cobj.response.bookRs.data!==undefined &&  props.confirmationObj.sup}/>
             </div>
             </>
              }
          </cfloop>
</div>
                </div>

              {props.confirmationObj.tf_flow &&
              <TFConfirmation confirmationObj={props.confirmationObj} type={"pdf"}/>
             }
              </div>


          </div>
        </div>
        </div>
        <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>
      </div>
  );
});

export default FlightItinerarypdf;
