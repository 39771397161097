
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Image5 from '../../../assets/images/hotelImg/hotelImg5.png';
import Image6 from '../../../assets/images/hotelImg/hotelImg6.png';
import Image7 from '../../../assets/images/hotelImg/hotelImg7.png';
import Image8 from '../../../assets/images/hotelImg/hotelImg8.png';
function TopHotels(props) {
    const [state, setState] = useState({})


    return (
        <>
            <div className="bestSellingPKG hoteldashBord topHotel">
            <Row>
                <Col>
                    <h3>
                    Top Hotels
                        </h3>
                    <p>World’s best Hotels</p>
                </Col>
            </Row>
                <Row>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image5}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                        <div className="topHotelInfo">
                                            <span className="placeName">World’s best Hotels</span>
                                                <em>Doha, Qatar</em>
                                                <span className="stars">
                                <span class="star star5"></span>
                                </span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image6}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                    <div className="topHotelInfo">
                                            <span className="placeName">Iberostar Selection Anthelia,</span>
                                                <em>Spain</em>
                                                <span className="stars">
                                <span class="star star5"></span>
                                </span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image7}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                    <div className="topHotelInfo">
                                            <span className="placeName">The Ritz-Carlton</span>
                                                <em>Riyadh, Saudi Arabia</em>
                                                <span className="stars">
                                <span class="star star5"></span>
                                </span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <div className="packDetails">
                                    <figure>
                                        <img
                                            src={Image8}
                                            className="topPackage"
                                            title="ATT"
                                            alt="package"
                                        />
                                    </figure>
                                    <div className="packInfo">
                                    <div className="topHotelInfo">
                                            <span className="placeName">Kempinski Nile Hotel</span>
                                                <em>Cairo, Egypt</em>
                                                <span className="stars">
                                <span class="star star5"></span>
                                </span>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>


                </Row>
            </div>
        </>
    )
}


export default TopHotels;
