import CustomFieldsService from '../../../../services/administration/CustomFieldsService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * @description: CRUD operations for Branch
 * @author: Lakshmi
 * @param {*}
 * @function Custome Fields
 * @date : 08-09-2020
 */

export const getCustomsData = async (id) => {
    let customList = [];
    await CustomFieldsService.getCustomsData(id).then(response => {
        if (response) {
            customList = response;
        }
    });
    return Promise.resolve(customList);
}

export const creation = async (obj) => {
    let finalRes = {};
    await CustomFieldsService.saveCustom(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const edit = async (id) => {
    let finalRes = {};
    await CustomFieldsService.editCustom(id).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const update = async (obj) => {
    const upObj = {
        "eid":obj.edit_id,
        "ctpe": obj.ctyp,
        "tpe": obj.type,
        "id": obj.id,
        "name": obj.name,
        "upby": "user@gmail.com",
        "custdls": obj.custList, 
        "is_remarks": obj.checked
    };
    let finalRes = {};
    await CustomFieldsService.updateCustom(upObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const deletion = async (obj, delby) => {
    let finalRes = {};
    if (obj !== "") {
        await CustomFieldsService.deleteCustom(obj.cust_id).then(response => {
            finalRes = response.data;
        })
    }
    return Promise.resolve(finalRes);
}

// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const branches = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        if (response.data.suc) {
            branchesList = response;
        }
    });
    return Promise.resolve(branchesList);
}


/**
 * @description: Fetch company credit limit data
 * @author: Rambabu
 * @param {*}
 * @function:companycreditlimtData
 * @date : 08-09-2020
 */
export const getCompaniesList = async (obj) => {
    let companyList = [];
    await AttCommonSerivce.getCompaniesList(obj).then(response => {
        companyList = response;
    });
    return Promise.resolve(companyList);
}



