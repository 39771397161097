import React, { useContext, useEffect, useReducer, useState, useRef } from 'react';
import { Alert,Col, Form, Button } from 'react-bootstrap';
import Select from 'react-dropdown-select';
import MainMenu from '../../../common/MainMenu';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import InnerLoader from '../../../common/loader/InnerLoader';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory,{textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecord from '../../../common/popUps/NoRecord';
import { BranchContext } from '../branch/BranchMain';
import { creation, getCountryMasterData } from './operations';
import { Context } from '../../../../../App';
let country = require('../../../masterData/CountryMaster.json')

const initialState = {
    cur:"", selCurrency:[], currencyMenu:[], countriesMenu:[], selCountry:[], notiMessageShow:false,notiVarient:"",notiMessage:"",isLoadingMore:false, res:[], enableView:false, isItineraryLoading:false,

}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
        };
      case 'editCompany':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

// main function started from here

function CountryMaster() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [isActive, setIsActive] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const comapnyObj = useContext(BranchContext);


    useEffect(()=>{
      let countries=country
      dispatch({type:"countriesMenu", payload:countries})
               //For Edit Country
            if (comapnyObj !== undefined && comapnyObj.countries !== undefined && comapnyObj.countries.cnt !== undefined) {
                countries.map((con) => {
                    if (con.value === comapnyObj.countries.cnt) {
                        dispatch({ type: 'selCountry', payload: [con] })
                    }
                });
            }
    },[])

    // this function will load country and currency as per country
const handleCountryChange = (e)=> {
    if (e.length > 0) {
        dispatch({ type: 'selCountry', payload: e })
            dispatch({ type: 'currencyMenu', payload: [{"label":e[0].cur,"value":e[0].cur}]})
            dispatch({ type: 'selCurrency', payload:  [{"label":e[0].cur,"value":e[0].cur}]})
    }
   }
const handleCurrency =(obj)=> {
    if (obj.length > 0) {
        dispatch({ type: 'cur', payload: obj[0].value })
        dispatch({ type: "selCurrency", payload: obj })
    }
   }
   function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  // Add country
const addCountry =() => {
    if (state.selCountry.length!=0){
      let suc=true
      for(let val of state.res){
        if((val.country).toLowerCase()==(state.selCountry[0].label).toLowerCase()){
          suc=false
        }
      }
      if (suc){
      dispatch({type:"isItineraryLoading", payload:true})
    const req = {
        "con_id":state.selCountry[0].value,
        "con_name": state.selCountry[0].label,
        "cur":state.cur,
        "crby":context.logindata.uid
    }
    creation(req).then((addRes) => {
        if (addRes.suc){
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: addRes.res.data })
         dispatch({ type: 'notiVarient', payload: 'success' });
         hidemessage()
         window.location = '/ezytrip/administration/CountryMaster'
        }
      })
    } else{
      dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Country already exists' })
         dispatch({ type: 'notiVarient', payload: 'danger' });
         hidemessage()
    }

 } else {
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Please select country' })
         dispatch({ type: 'notiVarient', payload: 'danger' });
         hidemessage()
 }
}
const handleCancel = () => {
  window.location = '/ezytrip/administration/CountryMaster'
}
// this function will clear all fields 
const resetValues =()=>{
  dispatch({type:"selCountry", payload:[]})
  dispatch({type:"selCurrency", payload:[]})
}
//This fnction will call when component will load
useEffect(() => {
    setIsLoadingMore(true);      
      getCountryMasterData().then((res) => {
        if (res) {
          loadCountries(res);
        }
        setIsLoadingMore(false);
      });
  }, []);
  const ViewRecords = (obj, type) => () => {
    dispatch({type:"selCountry", payload:[{"label":obj.con_name, "value":obj.con_id}]})
    dispatch({type:"selCurrency", payload:[{"label":obj.cur,"value":obj.cur}]})
        if (type === "view") {
          dispatch({ type: 'enableView', payload: true })
        } else {
        dispatch({ type: 'enableView', payload: false })
        }
  }
// Load All the Branches for Data Table
const loadCountries = (res) => {
    let data = [];
    for (let value of res) {
      data.push({ currency: value.cur, country: value.con_name, status:"Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={ViewRecords(value, "view")}></a> </span> </> })
    }
    dispatch({ type: 'res', payload: data })
    setIsLoadingMore(false)
  }
const columns = [{
    dataField: 'country',
    text: 'Country',
    sort: true,
    filter: textFilter()
},
{
    dataField: 'currency',
    text: 'Currency',
    sort: true,
    filter: textFilter()
  },
{
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
}];

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];
    return (
        <>
        {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        }
        {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
      <MainMenu active='Organization/CM' /> 
      <Breadcrumb activePage="CountryMaster" />
      <div className="container">
        <div className="cardMainlayout pt-0"> 
        <OrganizationMenu  active="CountryMaster"/>
        <div className="contentArea cmnSearch">
        <Form id="formId">
            <Form.Row>
            <Form.Group as={Col} xs={3} className={"selectplugin " + (state.countryError && "error")} controlId="cnt">
                            <Select
                                options={state.countriesMenu}
                                searchable={true}
                                values={state.selCountry}
                                disabled={state.enableView}
                                onChange={value => (handleCountryChange(value))}
                            />
                            <Form.Label>Country<sup>*</sup></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.currencyError && "error")} controlId="cur">
                            <Select
                                options={state.currencyMenu}
                                searchable={true}
                                values={state.selCurrency}
                                disabled={state.enableView}
                                onChange={(value) => (handleCurrency(value))}
                            />
                            <Form.Label>Currency <sup>*</sup></Form.Label>
                        </Form.Group>
            </Form.Row>
          </Form>
          </div>
                   <div className="text-right pb-0 buttonGrop  creat_absolute">
                       {state.enableView &&
                          <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={handleCancel}
                            type="submit"
                        > Back</Button>}
                       {!state.enableView &&
                          <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={resetValues}
                            type="submit"
                            isDisabled={state.enableView}
                        > Reset</Button>}
                      {!state.enableView &&
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={addCountry}
                            type="submit"
                        > Add Country</Button>
                        }
                   </div>
                   <div className="cardMainlayout tableRes p-0">
                    {isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}

                </div>
        </div>
      </div>

        </>
    );
}

export default CountryMaster;