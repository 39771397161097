
import React, { useContext, useEffect, useReducer, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Context } from "../../../App";
import { Col, Row, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import InnerLoader from '../common/loader/InnerLoader';
import NoRecordFound from '../common/popUps/NoRecordFound';
import DateUtils from "../commonUtils/DateUtils";
import { isNotNull } from '../commonUtils/validators';
import FormateCurrency from '../commonUtils/FormateCurrency';
import { getbookingData, quickSearch } from './operations';
import CoomonExportExcel from '../common/CoomonExportExcel';

import moment from 'moment';
var farReq = require('../masterData/FareRule.json');
export const IS_LATEST = "IS_LATEST";

const initialState = {
  id: 0, allActive: true, pendingActive: false, cancelledActive: false, confirmedActive: false, isLoadingMore: true, initalRes: [], excelData: [],
  res: [], noRecords: false, notiMessage: '', boolean: false, notiVarient: '', allBooking: [], pendingBooking: [], cancelledBooking: [], confirmBooking: [], branchesList: [], dataTableData: [], intit: false, onHoldBooking: [], onHoldActive: false,
  product_tag:[{ label: "All", value: "All" }]
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      console.log('returnnn', state)
      return { ...state, [action.type]: action.payload };
  }
};
function ListOfBooking(props) {

  const [quickSearchBoolean, setquickBoolean] = useState(true)
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [quicksearch, setQuickSearch] = useState(null)
  const [cid, setcid] = useState()
  const product = [
    { label: "All", value: "All" }, { label: "Flight", value: "Flight" }, { label: "Hotel", value: "Hotel" }, {label : "Insurance",value:"Insurance"},{label : "Visa",value:"Visa"}
  ]
  /**
   * @description: quickSearch
   * @author : Azamuddin
   * @date:1-10-2020
   */
  useEffect(() => {
    if (isNotNull(window.location.href)) {

      console.log('window.location.pathname is------' + window.location.href)
      let url_array = window.location.href.split("?");
      console.log('window.location.pathname url_array----' + url_array);
      if (window.location.search) {  //checking if params is there or not
        setQuickSearch(url_array[url_array.length - 1])
        dispatch({ type: 'isLoadingMore', payload: true })
        console.log('state boolean', state.boolean)
        if (quicksearch) {
          if(quicksearch.includes("=")) {
            let quicksearch_varient = quicksearch.split("=")
            let label = quicksearch_varient[0]
            let value = quicksearch_varient[1]
          let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
          let req = {
            cid: localstorage.cid,
            id: value,
            tag: label,
            uid: localstorage.uid,
            utype: localstorage.utype,
            is_admin: localstorage.is_admin
          };
          console.log("req quicksearch", req)
          quickSearch(req).then(response => {
            if (response && response.length > 0) {
              dispatch({ type: "initalRes", payload: response })
              setResponse(response)
              dispatch({ type: 'isLoadingMore', payload: false })
              dispatch({ type: 'boolean', payload: true })
            } else {
              dispatch({ type: 'isLoadingMore', payload: false })
              dispatch({ type: 'noRecords', payload: true })
              dispatch({ type: 'boolean', payload: true })
            }
          })
        }
        }
      }
    }
  }, [props])

  /**
   * @description : initial load to get latest 15 bookings
   * @author : Azamuddin
   */
  useEffect(() => {
    let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
    setcid(localstorage.cid)
    console.log('cid set', cid)
    if (window.location.search == '' && state.res.length === 0) {
      let rq_json = {
        id: localstorage.utype!=="att_user"?localstorage.cid:0,
        utype: localstorage.utype,
        uid: localstorage.uid,
        is_admin: localstorage.is_admin,
        region_type:localstorage.region_type
      }
      getbookingData(rq_json).then(response => {
        if (response) {
          dispatch({ type: "initalRes", payload: response })
          setResponse(response)
        } else {
          dispatch({ type: 'noRecords', payload: true })
        }
      })
    }
  }, [state.res.length === 0]);


  //advance search working criteria request
  useEffect(() => {
    if (props && props.searchResponse && props.searchResponse.length > 0 && state.res !== null) {
      dispatch({ type: "initalRes", payload: props.searchResponse })
      setResponse(props.searchResponse)
    } else if ( props.searchResponse == "undefined" || props.searchResponse.length === 0) {
      console.log('props.searchResponse', props.searchResponse)
      dispatch({ type: 'dataTableData', payload: [] })
      dispatch({ type: 'noRecords', payload: true })
    }
  }, [props.searchResponse, props.loader])


  const emptyDataMessage = () => { return 'No Data to Display';}



  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  /**
   * @description : response construction to show in data table
   * @param {*} response
   * @date : 04-12-2020
   */
  const setResponse = (response) => {
    dispatch({ type: 'isLoadingMore', payload: true })
    dispatch({ type: 'res', payload: response })

    let Allbookings = response
    let pendingbookings = []
    let cancelledBooking = []
    let confirmedBooking = []
    let onholdBooking = []
    console.log('response suc:::::', JSON.stringify(response))
    for (let val of response) {
      if (val.stat == '21') {
        pendingbookings.push(val)
      }
      if (val.stat == '23') {
        confirmedBooking.push(val)
      }
      if (val.stat == '28') {
        cancelledBooking.push(val)
      }
      if (val.stat == '22') {
        onholdBooking.push(val)
      }
    }
    console.log('Allbooking', Allbookings)
    console.log('pendingbookings', pendingbookings)

    console.log('confirmedBooking', confirmedBooking)

    console.log('cancelledBooking', cancelledBooking)

    dispatch({ type: 'allBooking', payload: Allbookings })
    dispatch({ type: 'pendingBooking', payload: pendingbookings })
    dispatch({ type: 'cancelledBooking', payload: cancelledBooking })
    dispatch({ type: 'confirmBooking', payload: confirmedBooking })
    dispatch({ type: 'onHoldBooking', payload: onholdBooking })
    dispatch({ type: 'noRecords', payload: false })
    loadResponse(response)
    dispatch({ type: 'isLoadingMore', payload: false })
  }
  /**
     * @description:on click booking id we are fetching data and put into window object,booking id also put into localstorage.
     * @date:20-09-2020
     * @author:Rambabu
     * @param {*} data
     * @param {*} idx
     */
  const openBookingSummary = (data, idx) => () => {
    console.log('Entered open booking summary click event')
    window.sessionStorage.setItem(IS_LATEST,true)
    if (data.product === "Hotel") {
      window.open("/ezytrip/dashboard/hotel/bookingsummary/" + data.bid);
    } else if(data.product === "Flight") {
      window.open("/ezytrip/dashboard/bookingsummary/" + data.bid);
    }else if(data.product === "Insurance"){
      window.open("/ezytrip/dashboard/insurance/bookingsummary/" + data.bid);
    }else if(data.product === "Visa"){
      window.open("/ezytrip/dashboard/visa/summary/" + data.bid);
    }
  }

  /**
   * @description : handle selection of product type and set the response
   * @date : 28-06-2021
   * @author : Azamuddin
   */
  const productsChange = (e) => {
    if (e.length > 0) {
      dispatch({type:"product_tag",payload:e})
      dispatch({ type: 'allActive', payload: true })
      dispatch({ type: 'pendingActive', payload: false })
      dispatch({ type: 'cancelledActive', payload: false })
      dispatch({ type: 'confirmedActive', payload: false })
      dispatch({ type: "onHoldActive", payload: false })
      let selected_value = e[0]
      let flights_booking = []
      let hotel_bookings = []
      let visa_bookings = []
      let insurance_bookings = []
      for (let val of state.initalRes) {
        if (val.product == "Flight") {
          flights_booking.push(val)
        } else if(val.product == "Hotel") {
          hotel_bookings.push(val)
        }else if(val.product == "Visa") {
          visa_bookings.push(val)
        } else {
          insurance_bookings.push(val)
        }
      }
      if (selected_value.value == "All") {
        setResponse(state.initalRes)
      } else if (selected_value.value == "Flight") {
        setResponse(flights_booking)
      } else if (selected_value.value == "Hotel") {
        setResponse(hotel_bookings)
      }else if (selected_value.value == "Visa") {
        setResponse(visa_bookings)
      } else {
        setResponse(insurance_bookings)
      }
    }
  }
  const csvHeaders = [
    { label: 'Region Type', key: 'region_type' },
    { label: 'Service', key: 'srvc' },
    { label: 'Booking Ref', key: 'bref' },
    { label: 'Lead Pax', key: 'paxname' },
    { label: 'Agency Name', key: 'cn' },
    { label: 'Booked By', key: 'bky' },
    { label: 'Airline/Name/Visa Type', key: 'name' },
    { label: 'Route/Hotel/Country', key: 'rh' },
    { label: 'Booked On', key: 'dtm' },
    { label: 'Total Price', key: 'price' },
    { label: 'Status', key: 'status' },
  ];
  const columns = [
    {
      dataField: 'srvc',
      text: 'Service',
      // sort: true,
      // filter: textFilter()
    },
    {
      dataField: 'bref',
      text: 'Booking Ref',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'paxname',
      text: 'Lead Pax',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'cn',
      text: 'Agency Name',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'bky',
      text: 'Booked By',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'name',
      text: 'Airline/Name/Visa Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'rh',
      text: 'Route/Hotel/Country',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'dtm',
      text: 'Booked on',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'price',
      text: 'Total Price',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'options',
      text: 'Actions',
      class: 'tes'
    }];
  const loadResponse = (records) => {
    let data = []
    let excelData = []
    console.log('recordssss:::', records)
    if (records.length === 0) {
      dispatch({ type: "noRecords", payload: true })
    }
    for (let val of records) {

      let amt = ""
      if (val.amt || val.amt === 0) {
        amt = val.amt === 0 ? "0.00" : val.amt.toFixed(2)
      }
      let status = ""
      let ststusCls = ""
      let ststusIcon = ""
      if (val.stat == 21) {
        status = " In Complete"
        ststusCls = " pending"
        ststusIcon = "on-hold"
      }
      if (val.stat == 22) {
        status = " On Hold"
        ststusCls = " pending"
        ststusIcon = "on-hold"
      }
      if (val.stat == 23) {
        status = " Confirmed"
        ststusCls = " confirm"
        ststusIcon = "confirm"
      }
      if (val.stat == 24) {
        status = " Waiting For Review"
        ststusCls = " pending"
        ststusIcon = "waiting-review"
      }
      if (val.stat == 25) {
        status = " Failed"
        ststusCls = " cancelled"
        ststusIcon = "cancelled"
      }
      if (val.stat == 26) {
        status = " Cancel Request"
        ststusCls = " cancelled"
        ststusIcon = "cancelled"
      }
      if (val.stat == 27) {
        status = " Cancellation in progress"
        ststusCls = " pending"
        ststusIcon = "cancelled"
      }
      if (val.stat == 28) {
        status = " Cancelled"
        ststusCls = " cancelled"
        ststusIcon = "cancelled"
      }
      if (val.stat == 29) {
        status = " Booked"
        ststusCls = " confirm"
        ststusIcon = "ticket"
      }
      if (val.stat == 30) {
        status = " Void in progress"
        ststusCls = " pending"
        ststusIcon = "void-process"
      }
      if (val.stat == 31) {
        status = " Voided"
        ststusCls = " cancelled"
        ststusIcon = "void-process"
      }
      if (val.stat == 32) {
        status = " Modified"
        ststusCls = " modified"
        ststusIcon = "waiting-review"
      }
      if (val.stat == 33) {
        status = " PNR Failed"
        ststusCls = " cancelled"
        ststusIcon = "ticket"
      }
      if (val.stat == 34) {
        status = " Ticketing Fail"
        ststusCls = " cancelled"
        ststusIcon = "ticket"
      }
      if (val.stat == 35) {
        status = " Discarded"
        ststusCls = " cancelled"
        ststusIcon = "discarded"
      }
      if (val.stat == 36) {
        status = " Partially Ticketed"
        ststusCls = " confirm"
        ststusIcon = "ticket"
      }
      if (val.stat == 37) {
        status = " Pending"
        ststusCls = " pending"
        ststusIcon = "exclamation"
      }
      if (val.stat == 38) {
        status = " Void Book Fail"
        ststusCls = " cancelled"
        ststusIcon = "void-process"
      }
      if (val.stat == 39) {
        status = " Void Cancel"
        ststusCls = " cancelled"
        ststusIcon = "void-process"
      }
      if (val.stat == 40) {
        status = " Void cancel Fail"
        ststusCls = " cancelled"
        ststusIcon = "void-process"
      }
      if (val.stat == 41) {
        status = " Partially Confirmed"
        ststusCls = " confirm"
        ststusIcon = "confirm"
      }
      if (val.stat == 47) {
        status = " Offline"
        ststusCls = " confirm"
        ststusIcon = "confirm"
      }
      let price = 0
      if(amt!==undefined && amt!==0) {
         let tag  =<FormateCurrency value={amt} />
         price = tag.props.value
         console.log("price",price)
      }
      let bky = ""
      if(val.booked_by) {
        bky = val.booked_by
      } else {
        bky = val.fn
      }
    if(val.bid!==null){
    if(val.product!=="Insurance"){
      data.push({
        region_type: val.region_type,
        bref: val.bid,
        srvc: (val.product === "Flight") ? <div className="d-flex">{val.bktype!=undefined && (val.bktype==="DIRECT"||val.bktype==="INCOMPLETE") && <span className="ptype ezyIcon icon-plane" title="Flight"></span>}{val.bktype!=undefined && (val.bktype==="REISSUE" || val.bktype=="REISSUE/SPLIT") && <span className="ptype ezyIcon icon-reissue" title="Flight"></span>}{val.bktype!=undefined && val.bktype==="MANUAL" && <span className="ptype ezyIcon icon-Manual-B" title="Flight"></span>}{(val.bktype=="IMPORT" || val.bktype=="IMPORT/REISSUE" || val.bktype=="IMPORT/SPLIT") && <span className="ptype ezyIcon icon-plane"></span>} <span  className={"ml-2 "+((val.bktype=="IMPORT" || val.bktype=="IMPORT/REISSUE" || val.bktype=="IMPORT/SPLIT") ? 'import ezyIcon icon-import':'' )}></span></div> : (val.product === "Hotel") ?<div className="d-flex">{val.bktype!=undefined && val.bktype==="DIRECT"?<span className="ptype ezyIcon icon-hotel" title="Hotel"></span>:<span className="ptype ezyIcon icon-manualhotel" title="Hotel"></span> }<span className={"ml-2 "+((val.bktype=="IMPORT" || val.bktype=="IMPORT/REISSUE" || val.bktype=="IMPORT/SPLIT") ? 'import ezyIcon icon-import':'' )}></span></div>:<div className="d-flex">{val.bktype!=undefined && <span className="ptype ezyIcon icon-visa" title="Visa"></span>}</div>,
        paxname:<span className="ctname" title={val.fn + " " + val.tn}>{val.fn + " " + val.tn}</span>,
        bky: bky,
        name: (val.product==="Flight") ? val.markting_airline:(val.product === "Hotel")?<span className="ctname" title={val.htlname}>{val.htlname}</span>:val.visa_type,
        rh: (val.product==="Flight")?<span><span className="ctname" title={val.source_city_name +" "+ " - "+" " + val.dest_city_name}>{val.source_city_name +" "+ " - "+" " + val.dest_city_name}</span> <span className="datess">{DateUtils.prettyDate(val.depttime, "ddd  DD, MMM, YYYY")}</span> </span>:(val.product === "Hotel") ?<span><span className="ctname" title={val.htlname}>{val.htlname}</span><span className="datess">{DateUtils.prettyDate(val.depttime, "ddd  DD, MMM, YYYY")}</span> </span>:<span><span className="ctname" title={val.na+" "+ " - "+" " +val.trvl_to}>{val.na+" "+ " - "+" " +val.trvl_to}</span><span className="datess">{DateUtils.prettyDate(val.depttime, "ddd  DD, MMM, YYYY")}</span> </span>,
        dtm: <span className="dtinfo">{val.dt}</span>,
        price: price,
        cn:  <span className="ctname" title={val.cname}>{val.cname}</span>,
        status: <span className={"status "+ ststusCls + " ezyIcon icon-" +ststusIcon}>{status}</span>,
        options: <><a className="ezyIcon icon-view" onClick={openBookingSummary(val, "view")}></a></>
      })
      excelData.push({
        region_type: val.region_type,
        bref: val.bid,
        srvc: val.product ,
        paxname: val.fn + " " + val.tn,
        bky: bky,
        name: (val.product==="Flight") ? val.markting_airline:(val.product==="Hotel")?val.htlname:val.visa_type,
        rh: (val.product==="Flight")?val.source_city_name +" "+ " - "+" " + val.dest_city_name:(val.product==="Hotel")?val.htlname:val.na+" "+ " - "+" " +val.trvl_to,
        dtm: val.dt,
        price: price,
        cn:  val.cname,
        status: status,
      })
    }else{
      data.push({
        region_type: val.region_type,
        bref: val.bid,
        srvc: <div className="d-flex"><span className="ptype ezyIcon icon-policy" title="Insurances"></span><span className={"ml-2 "+((val.bktype=="IMPORT" || val.bktype=="IMPORT/REISSUE" || val.bktype=="IMPORT/SPLIT") ? 'import ezyIcon icon-import':'' )}></span></div>,
        paxname: <span><span className="travlName" title={val.fn + " " + val.tn}>{val.fn + " " + val.tn} </span> <span className="d-flex">{val.adt_cnt+val.chd_cnt}  Travellers</span></span>,
        bky: bky,
        name:<span className="ctname" title={val.plan_name}>{val.plan_name}</span>,
        rh: <span><span className="ctname" title={val.src_country_name}></span>{val.bound_type==="0"?val.src_country_name+" | Outbound":val.src_country_name+"- Qatar"+" | Inbound"} <span className="datess">{DateUtils.prettyDate(val.depttime, "ddd  DD, MMM, YYYY")}</span> </span>,
        dtm: <span className="dtinfo">{val.dt}</span>,
        price: price,
        cn:<span className="ctname" title={val.cname}>{val.cname}</span>,
        status: <span className={"status "+ ststusCls + " ezyIcon icon-" +ststusIcon}>{status}</span>,
        options: <><a className="ezyIcon icon-view" onClick={openBookingSummary(val, "view")}></a></>
      })
      excelData.push({
        region_type: val.region_type,
        bref: val.bid,
        srvc: val.product ,
        paxname: val.fn + " " + val.tn,
        bky: bky,
        name: val.plan_name,
        rh: val.bound_type==="0"?val.src_country_name+" | Outbound":val.src_country_name+"- Qatar"+" | Inbound",
        dtm: val.dt,
        price: price,
        cn:  val.cname,
        status: status,
      })
    }
    }

    }
    if (data.length === 0) {
      dispatch({ type: "noRecords", payload: true })
    }
    dispatch({ type: 'dataTableData', payload: data })
    dispatch({ type: 'excelData', payload: excelData })
  }
  /**
   * @description : on click of pending bookings icons
   * @author: Azamuddin
   * @date: 04-12-2020
   */
  const getPendingBooking = () => {
    // dispatch({ type: 'res', payload: state.pendingBooking })
    loadResponse(state.pendingBooking)
    dispatch({ type: 'allActive', payload: false })
    dispatch({ type: 'pendingActive', payload: true })
    dispatch({ type: 'cancelledActive', payload: false })
    dispatch({ type: 'confirmedActive', payload: false })
    dispatch({ type: "onHoldActive", payload: false })

  }


  /**
   * @description : on click of confirm bookings icons
   * @author: Azamuddin
   * @date: 04-12-2020
   */
  const confirmBooking = () => {
    // dispatch({ type: 'res', payload: state.confirmBooking })
    loadResponse(state.confirmBooking)
    dispatch({ type: 'allActive', payload: false })
    dispatch({ type: 'pendingActive', payload: false })
    dispatch({ type: 'cancelledActive', payload: false })
    dispatch({ type: 'confirmedActive', payload: true })
    dispatch({ type: "onHoldActive", payload: false })
  }


  /**
   * @description : on click of cancel bookings icons
   * @author: Azamuddin
   * @date: 04-12-2020
   */
  const cancelBooking = () => {
    // dispatch({ type: 'res', payload: state.cancelledBooking })
    loadResponse(state.cancelledBooking)
    dispatch({ type: 'allActive', payload: false })
    dispatch({ type: 'pendingActive', payload: false })
    dispatch({ type: 'cancelledActive', payload: true })
    dispatch({ type: 'confirmedActive', payload: false })
    dispatch({ type: "onHoldActive", payload: false })
  }

  /**
   * @description : on click of all bookings icons
   * @author: Azamuddin
   * @date: 04-12-2020
   */
  const getAllBookings = () => {
    // dispatch({ type: 'res', payload: state.allBooking })
    loadResponse(state.allBooking)
    dispatch({ type: 'allActive', payload: true })
    dispatch({ type: 'pendingActive', payload: false })
    dispatch({ type: 'cancelledActive', payload: false })
    dispatch({ type: 'confirmedActive', payload: false })
    dispatch({ type: "onHoldActive", payload: false })
  }
  const onHoldBooking = () => {
    loadResponse(state.onHoldBooking)
    dispatch({ type: 'allActive', payload: false })
    dispatch({ type: 'pendingActive', payload: false })
    dispatch({ type: 'cancelledActive', payload: false })
    dispatch({ type: 'confirmedActive', payload: false })
    dispatch({ type: "onHoldActive", payload: true })
  }
  return (
    <>
      <div className="sortBookingList mt-3">
        <div className="d-flex">
        {props.enableProduct &&
        <button
          size="xs"
          variant="outline-dark"
          type="submit"
          className={"btn-outline-dark btn-xs btn " + (state.allActive ? "ml-2 active" : "ml-2")}
          onClick={getAllBookings}
        ><span className="ezyIcon icon-allBooking mr-1"></span> All Bookings</button>
        }

        {!state.noRecords && state.pendingBooking.length !== 0 && (
          <button
            size="xs"
            variant="outline-dark"
            type="submit"
            className={"btn-outline-dark btn-xs btn " + (state.pendingActive ? "ml-2 active" : "ml-2")}
            onClick={getPendingBooking}
          ><span className="ezyIcon icon-pendingBooking mr-1"></span> Pending</button>
        )}
        {!state.noRecords && state.confirmBooking.length !== 0 && (
          <button
            size="xs"
            variant="outline-dark"
            type="submit"
            className={"btn-outline-dark btn-xs btn " + (state.confirmedActive ? "ml-2 active" : "ml-2")}
            onClick={confirmBooking}
          ><span className="ezyIcon icon-confirmedOne mr-1"></span> Confirmed</button>
        )}
        {!state.noRecords && state.onHoldBooking.length !== 0 && (
          <button
            size="xs"
            variant="outline-dark"
            type="submit"
            className={"btn-outline-dark btn-xs btn " + (state.onHoldActive ? "ml-2 active" : "ml-2")}
            onClick={onHoldBooking}
          ><span className="ezyIcon icon-on-hold mr-1"></span> On Hold</button>
        )}
        {!state.noRecords && state.cancelledBooking.length !== 0 && (
          <button
            size="xs"
            variant="outline-dark"
            type="submit"
            className={"btn-outline-dark btn-xs btn " + (state.cancelledActive ? "ml-2 active" : "ml-2")}
            onClick={cancelBooking}
          ><span className="ezyIcon icon-cancelOne mr-1"></span> Cancelled</button>
        )}
</div>
        {!state.noRecords && state.initalRes.length > 0 && props.enableProduct &&
          <Form.Group className="selectplugin m-0" as={Col} xs={3} controlId="firstName">
            <Select
              options={product}
              searchable={false}
              onChange={(value) => productsChange(value)}
            />
            <Form.Label>Products</Form.Label>
          </Form.Group>
        }
        {state.dataTableData!==undefined && state.dataTableData.length !== 0 &&
        <CoomonExportExcel data={state.excelData} show={true} headers={csvHeaders} name={"Report.csv"}/>
       }
      </div>
      <div className="cardMainlayout allBkngList p-0">
      {console.log("state.dataTableData",state.dataTableData) }
        {state.isLoadingMore || props.loader ? <InnerLoader /> : (<>
          {state.dataTableData.length > 0 ?
            <BootstrapTable
              bootstrap4
              keyField="id"
              classes="markup filterTbl table-responsive"
              data={state.dataTableData}
              columns={columns}
              defaultSorted={defaultSorted}
              noDataIndication={emptyDataMessage}
              filter={filterFactory()}
              pagination={state.dataTableData.length >10 && state.dataTableData.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                            state.dataTableData.length >25 && state.dataTableData.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                            state.dataTableData.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
            : (state.noRecords ? <NoRecordFound/>:<InnerLoader/>)
           }
            </>)}
      </div>


    </>
  )

}


export default ListOfBooking;
