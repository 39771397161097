
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Modal, Row, Table, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import MainMenu from '../../../../components/common/MainMenu';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import {searchProfile,talist} from './operations';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
import { useHistory } from 'react-router-dom';
/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Exchange Rate Configuration
 * @date : 13-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id:0, compList:[],companyNames:[],email:"",res:[],enbaleUpdate: false,isItineraryLoading:false,
    noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false,showPoppup:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, id:0,enbaleUpdate: false,
                noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function UserProfile Configuration
 */
function UserProfile() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [validated, setValidated] = useState(false);
    const [context, setContext] = useContext(Context);
    const history = useHistory();

    useEffect(() => {
        talist().then((res) => {
            dispatch({ type: 'compList', payload: res })
          });
    }, []);

    //Search User based on the company
    const searchUsers=()=>{
        if(state.companyNames.length!==0){
            dispatch({ type: 'isItineraryLoading', payload: true});
            const req={"company_id":Number(state.companyNames[0].value),"email":state.email}
            searchProfile(req).then(response => {
                dispatch({ type: 'isItineraryLoading', payload: false});
                dispatch({ type: 'res', payload: [] });
                if (response.data.suc){
                    convertData(response.data);
                }
            })
        }else{
            dispatch({ type: 'isItineraryLoading', payload: false});
            dispatch({ type: 'enbaleUpdate', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please Select Company' })
            dispatch({ type: 'notiVarient', payload: 'danger' });
            dispatch({ type: 'showSave', payload: true });
            hidemessage()
        }
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    const editprofile=(obj)=>{
        history.push({
            pathname: '/ezytrip/administration/editprofile',
            state: {
              req: obj
            }
          });
    }

    //Converting all suppliers
    const convertData = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response.res){
                    if(val.email!==null){
                        data.push({name:val.tl+" "+val.fn+" "+val.ln,email: val.email, phn:val.phc!==null?val.phc+"-"+val.phn:val.phn,options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editprofile(val)}></a></span></>})
                    }
                }
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'isLoadingMore', payload: false });
    }

     //Edit Categeory
     const viewRequest = (obj) => {
        dispatch({ type: 'reqObj', payload: obj });
        dispatch({ type: 'showPoppup', payload: true });
    }

     //Edit Categeory
     const cancelReq = () => {
        dispatch({ type: 'reqObj', payload: "" });
        dispatch({ type: 'showPoppup', payload: false });
    }

        const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'email',
            text: 'Email Id',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'phn',
            text: 'Phone Number',
            sort: true
        },
        {
            dataField: 'options',
            text: 'Actions',
            class: 'tes'
        }
        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];

        const handleChange = evt => {
              const { id, value } = evt.target;
              dispatch({ type: id, payload: value });
            }

        const resetData=()=>{
            dispatch({ type: 'email', payload: "" });
            dispatch({ type: 'companyNames', payload: [] });
        }


    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="User Profiles" />
            <div className="container">
              <div className="cardMainlayout pt-0">
                <OrganizationMenu active="User Profile" />
                <div className="contentArea">
                   {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                    <div class="loaderPage">
                        <div class="loaderIcon"></div>
                    </div>
                    </div>
                    ) : ""}
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                        <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                            <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin"
                            controlId="selectedValue"
                            >
                            <Select
                            options={state.compList}
                            values={state.companyNames}
                            //onChange={(value) => (setValue(value), setCompany(value))}
                            onChange={(value) => (dispatch({ type: 'companyNames', payload: value }))}
                            disabled={state.enbaleUpdate || state.enableView}
                            />
                            <Form.Label>Company<sup>*</sup></Form.Label>
                            {state.companyError && (
                                <p className="vError">Please Select a Company</p>
                            )}
                            </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="email">
                            <Form.Control type="textarea" placeholder="Enter Email" autoComplete="off"
                            value={state.email} onChange={handleChange} />
                            <Form.Label>Email Id</Form.Label>
                            </Form.Group>
                            </Form.Row>
                            </Form>
                            <div className="text-right buttonGrop pb-0">
                            <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={resetData}
                                    type="submit"
                                >Reset</Button>
                            <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={searchUsers}
                                    loading={state.loadCategory}
                                    type="submit"
                                > Search</Button>
                        </div>
                            </div>
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
            </div>
            <Footer />
        </>
    )
}

export default UserProfile