import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState, useCallback } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import PaymentBillingMenu from '../../../common/subMenu/PaymentBillingMenu';
import DateUtils from "../../../commonUtils/DateUtils";
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import { companycreditlimtData, companyList, companypredticiveTAList, creation, getCompaniesList, update } from './operations';
import debounce from 'lodash.debounce';
/**
 * @description:This function will create teh credit management
 * @author: Rambabu
 * @param {*}
 * @function:Credit Management
 * @date : 08-09-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
    companyType: '', bid: "", bname: "", company_ta_id: '', company_sta_id: '', creditLimit: '', is_alert: false, low_credit_limt: '', currency: '',
    company_TA_list: [], company_STA_list: [], notiMessage: '', notiMessageShow: false, notiVarient: '', branchMenu: [],
    credit_limit_data: [], updatebtn: false, id: '', disable: false, ta_radio_btn: false, sta_radio_btn: false, updatable: false, amount: 0, trans_type: '',
    enableType: true, pType: 'debit', refData: '', remarks: '', crby: null, selBranch: [], selTA: [], selSTA: [], selTrans: [], companies: [], stacompanies: [],
    ledger: [],isItineraryLoading:false

};


/**
 * @description:Reducer function for userReact Hook, which will update the state
 * @author: Rambabu
 * @param {*} state
 * @param {*} action
 * @function:Credit Management
 * @date : 08-09-2020
 */
const reducer = (state, action) => {
    switch (action.type) {
        default:
            return { ...state, [action.type]: action.payload };
    }
};

function Creditmanagement() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [confirmation, setConfirmation] = useState({});
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [enableView, setEnableView] = useState(false)
    const t_type = [{ label: "Credit", value: "Credit" }, { label: "Debit", value: "Debit" }, { label: "Payment", value: "Payment" }, { label: "Others", value: "Others" }]
    /**
     * @description:useEffect call intial will call
     * @author: Rambabu
     * @function:useEffect
     * @date : 08-09-2020
     */
    useEffect(() => {
        console.log('Enter use effect in credit management---')
        var preUrl = localStorage.getItem('language');
        if (preUrl === 'ar') {
            var { cnf, review, search } = require('../../../../../locale/ar/index');
            setConfirmation(cnf);
        } else {
            var { cnf, review, search } = require('../../../../../locale/en/index');
            setConfirmation(cnf)

        }

    }, []);

    useEffect(() => {
        if (context.logindata.utype !== "ta_user") {
            dispatch({ type: 'crby', payload: context.logindata.uid })
            const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid,  is_admin: context.logindata.is_admin}
            AttCommonSerivce.getBranches(pyload).then(response => {
                //   if(response.data.suc){
                //     let branchesList = response.data.res.map((branch) =>
                //     <option value={branch.value}>{branch.label}</option>
                //     );
                dispatch({ type: 'branchMenu', payload: response.data.res });
                //}
            }).catch(function (error) {
                log.error(
                    "Exception occured in getBranchData function---" + error
                );
            });
        } else {
            //Loading TA Companies
            dispatch({ type: 'crby', payload: context.logindata.uid })
            const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
            getCompaniesList(pyload).then(response => {
                if (response.data.suc) {
                    dispatch({ type: 'company_STA_list', payload: response.data.res });
                    dispatch({ type: 'companyType', payload: 'STA' });
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in getCompanyList function---" + error
                );
            });
        }
    }, [context.logindata]);


    /**
     * @description:Cancel Call
     * @author: Rambabu
     * @function:handleCancel
     * @param{*} e
     * @date : 08-09-2020
     */
    const handleCancel = (e) => {
        return (window.location = "/ezytrip/administration/creditmanagement")

    }



    /**
     * @description:Change Event
     * @author: Rambabu
     * @function:handleChange
     * @param{*} e
     * @date : 08-09-2020
     */
    const handleChange = (e) => {
        console.log('agencyEvent---');

        if (e.target.id === "credit") {
            dispatch({ type: 'enableType', payload: false })
            dispatch({ type: 'pType', payload: "credit" })
        }
        if (e.target.id === "debit") {
            dispatch({ type: 'enableType', payload: true })
            dispatch({ type: 'pType', payload: "debit" })
        }
        if (e.target.id === "ref") {
            dispatch({ type: 'refData', payload: e.target.value })
        }
        if (e.target.id === "amt") {
            dispatch({ type: 'amount', payload: e.target.value })
        }
        if (e.target.id === "remarks") {
            dispatch({ type: 'remarks', payload: e.target.value })
        }
        if (e.target.id === "TA") {
            dispatch({ type: 'companyType', payload: 'TA' });
            dispatch({ type: 'ta_radio_btn', payload: true });
            const req = { company_type: 'TA' }
            companyList(req).then((res) => {
                dispatch({ type: 'company_TA_list', payload: res })
            });

        } else if (e.target.id === "STA") {
            dispatch({ type: 'companyType', payload: 'STA' });
            dispatch({ type: 'sta_radio_btn', payload: true });
            const req = { company_type: 'TA' }
            companyList(req).then((res) => {
                dispatch({ type: 'company_TA_list', payload: res })
            });

        } else if (e.target.id === 'crLimit') {

            dispatch({ type: 'creditLimit', payload: e.target.value });

        } else if (e.target.id === 'ac') {
            dispatch({ type: 'is_alert', payload: e.target.checked });

        } else if (e.target.id === 'lowcrLimit') {
            dispatch({ type: 'low_credit_limt', payload: e.target.value });

        }

    }

    /**
     * @description:This event will call when we select TA Dropdown
     * @author: Rambabu
     * @function:company_TA_event
     * @param{*} e
     * @date : 08-09-2020
     */
    const company_TA_event = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: 'selTA', payload: e })
            dispatch({ type: 'company_ta_id', payload: e[0] });
            dispatch({ type: 'notiMessage', payload: '' });
            dispatch({ type: 'notiMessageShow', payload: false });
            dispatch({ type: 'notiVarient', payload: '' });
            dispatch({ type: 'companyType', payload: 'TA' });
            dispatch({ type: 'bname', payload: e[0].label });
            dispatch({ type: 'bid', payload: e[0].value });
            const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": e[0].value }
            getCompaniesList(pyload).then(response => {
                if (response.data.suc) {
                    dispatch({ type: 'company_STA_list', payload: response.data.res });
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in getCompanyList function---" + error
                );
            });
        }
        // getCompanyCreditlimitData(e.value);

    }

    /**
     * @description:This event will call when we select STA Dropdown
     * @author: Rambabu
     * @function:company_STA_event
     * @param{*} e
     * @date : 08-09-2020
     */
    const company_STA_event = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: 'selSTA', payload: e })
            dispatch({ type: 'company_sta_id', payload: e[0] });
            if (context.logindata.utype === "ta_user") {
                dispatch({ type: 'currency', payload: context.logindata.bcur });
                dispatch({ type: 'bname', payload: context.logindata.bnm });
                dispatch({ type: 'bid', payload: context.logindata.bid });
            }
            dispatch({ type: 'companyType', payload: 'STA' });
            getCompanyCreditlimitData(e[0].value);
        }
    }

    /**
     * @description:This function will load resepective company credit limit data
     * @author: Rambabu
     * @function:getCompanyCreditlimitData
     * @param{*} e
     * @date : 08-09-2020
     */
    function getCompanyCreditlimitData(id) {
        const req = { company_id: id }
        companycreditlimtData(req).then((res) => {
            if (isArrayNotEmpty(res)) {
                dispatch({ type: 'creditLimit', payload: res[0].balance });
                // dispatch({ type: 'is_alert', payload: res[0].is_alert });
                dispatch({ type: 'low_credit_limt', payload: res[0].low_credit_limit });
                dispatch({ type: 'disable', payload: true })
                dispatch({ type: 'id', payload: res[0].id })
                dispatch({ type: 'updatable', payload: true })
                dispatch({ type: 'ledger', payload: res[0].ledger })
                if (res[0].ledger && res[0].ledger.length !== 0) {
                    loadCreditLimits(res[0].ledger);
                }
            } else {

                dispatch({ type: 'credit_limit_data', payload: [] })
                dispatch({ type: 'creditLimit', payload: '' });
                dispatch({ type: 'is_alert', payload: false });
                dispatch({ type: 'low_credit_limt', payload: '' });
                dispatch({ type: 'disable', payload: false })
                dispatch({ type: 'updatebtn', payload: false })
                dispatch({ type: 'updatable', payload: false })

            }
        });


    }

    const loadCreditLimits = (response) => {
        let data = [];
        var enableWEdit = false;
        if (context.logindata.roles.includes('C_R_U')) {
            enableWEdit = true;
        }
        let sorted_response = response.sort(function (a, b) {
            return b.id - a.id;
        });
        for (let value of sorted_response) {
            let ttype = null
            if (value.paymant_type == "credit") {
                ttype = "Credit"
            } else {
                ttype = "Debit"
            }

            data.push({ ttype: value.transaction_type, pType: ttype, ref: value.ref_no, amt: value.amount, crDate: DateUtils.prettyDate(value.created_date, "DD/MM/YYYY"), crBy: value.created_by, remarks: value.remarks })
        }
        dispatch({ type: 'credit_limit_data', payload: data })
    }

    const columns = [{
        dataField: 'ttype',
        text: 'Transaction Type',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'pType',
        text: 'Payment Type',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'ref',
        text: 'Ref#',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'amt',
        text: 'Amount',
        sort: true,
        // filter: textFilter()
    },
    {
        dataField: 'crDate',
        text: 'Created Date',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'crBy',
        text: 'Created By',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'remarks',
        text: 'Remarks',
        sort: true,
        filter: textFilter()
    }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];



    /**
    * @description:This Event  will call Save credit limit
    * @author: Rambabu
    * @function:handleSaveBtn
    * @param{*} e
    * @date : 08-09-2020
    */
    function hidemessage() {
        setTimeout(function () {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
    }

    const handleSaveBtn = (e) => {
        e.preventDefault();
        dispatch({ type: 'notiMessage', payload: '' });
        dispatch({ type: 'notiMessageShow', payload: false });
        dispatch({ type: 'notiVarient', payload: '' });
        dispatch({ type: 'crby', payload: context.logindata.uid })
        if (state.updatable) {           
            if (state.amount !== 0 && state.refData && state.pType && state.trans_type && state.remarks && state.low_credit_limt) {
                dispatch({ type: "isItineraryLoading", payload: true });

                update(state).then((addRes) => {
                    if (addRes.suc) {
                        dispatch({ type: 'notiMessage', payload: 'Credit limit updated successfully' });
                        dispatch({ type: 'notiMessageShow', payload: true });
                        dispatch({ type: 'notiVarient', payload: 'success' });
                        hidemessage()
                        handleCancel()
                    } else {
                        dispatch({ type: "isItineraryLoading", payload: false });

                        dispatch({ type: 'notiMessage', payload: 'Credit limit not Update successfully' });
                        dispatch({ type: 'notiMessageShow', payload: true });
                        dispatch({ type: 'notiVarient', payload: 'danger' });
                        hidemessage()
                    }

                })
            } else {
                dispatch({ type: 'notiMessage', payload: 'Please Enter All the required fields' });
                dispatch({ type: 'notiMessageShow', payload: true });
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
            }
        } else {

            if (isNotNull(state.companyType) && isNotNull(state.company_sta_id) && isNotNull(state.creditLimit) && state.low_credit_limt && state.remarks) {



                if (parseInt(state.creditLimit) > parseInt(state.low_credit_limt)) {
                    creation(state).then((addRes) => {
                        if (addRes.suc) {
                            dispatch({ type: 'notiMessage', payload: 'Credit limit configured successfully' });
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiVarient', payload: 'success' });
                            dispatch({ type: 'companyType', payload: '' });
                            dispatch({ type: 'company_ta_id', payload: '' });
                            dispatch({ type: 'company_sta_id', payload: [] });
                            dispatch({ type: 'creditLimit', payload: '' });
                            dispatch({ type: 'currency', payload: '' });
                            dispatch({ type: 'is_alert', payload: '' });
                            dispatch({ type: 'low_credit_limt', payload: '' });
                            hidemessage()
                            handleCancel()
                        } else {
                            dispatch({ type: 'notiMessage', payload: 'Credit limit not configured successfully' });
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiVarient', payload: 'danger' });
                            hidemessage()
                        }
                    });
                } else {
                    dispatch({ type: 'notiMessage', payload: 'Credit limit value should be greater than low credit limit value' });
                    dispatch({ type: 'notiMessageShow', payload: true });
                    dispatch({ type: 'notiVarient', payload: 'danger' });
                    hidemessage()
                }
            } else {
                dispatch({ type: 'notiMessage', payload: 'Please Enter All the required fields' });
                dispatch({ type: 'notiMessageShow', payload: true });
                dispatch({ type: 'notiVarient', payload: 'danger' });
                hidemessage()
            }
        }


    }

    /**
     * @description:This Event  will call Edit credit limit
     * @author: Rambabu
     * @function:editCreditlimit
     * @param{*} obj
     * @date : 08-09-2020
     */
    const editCreditlimit = (obj, type) => () => {

        dispatch({ type: 'updatebtn', payload: true });
        dispatch({ type: 'id', payload: obj.id });
        dispatch({ type: 'creditLimit', payload: obj.balance });
        dispatch({ type: 'currency', payload: obj.cur });
        dispatch({ type: 'is_alert', payload: obj.is_alert });
        dispatch({ type: 'low_credit_limt', payload: obj.low_credit_limit });
        dispatch({ type: 'disable', payload: false })
        if (type === "view") {
            setEnableView(true)
        } else {
            setEnableView(false)
        }
    }

    /**
     * @description:This Event  will call update credit limit
     * @author: Rambabu
     * @function:handleUpdateBtn
     * @param{*} e
     * @date : 08-09-2020
     */
    const handleUpdateBtn = (e) => {
        e.preventDefault();
        if (state.creditLimit > state.low_credit_limt) {
            dispatch({ type: "isItineraryLoading", payload: true });

            update(state).then((addRes) => {
                if (addRes.suc) {
                    dispatch({ type: 'notiMessage', payload: 'Credit limit updated successfully' });
                    dispatch({ type: 'notiMessageShow', payload: true });
                    dispatch({ type: 'notiVarient', payload: 'success' });
                    hidemessage()
                    dispatch({ type: 'companyType', payload: '' });
                    dispatch({ type: 'company_ta_id', payload: '' });
                    dispatch({ type: 'company_sta_id', payload: '' });
                    dispatch({ type: 'creditLimit', payload: '' });
                    dispatch({ type: 'currency', payload: '' });
                    dispatch({ type: 'is_alert', payload: '' });
                    dispatch({ type: 'low_credit_limt', payload: '' });
                    dispatch({ type: 'updatebtn', payload: false });
                    dispatch({ type: 'credit_limit_data', payload: [] });
                    dispatch({ type: 'company_TA_list', payload: [] });
                    dispatch({ type: 'company_STA_list', payload: [] });
                    dispatch({ type: 'ta_radio_btn', payload: false });
                    dispatch({ type: 'sta_radio_btn', payload: false });

                } else {
                    dispatch({ type: "isItineraryLoading", payload: false });

                    dispatch({ type: 'notiMessage', payload: 'Credit limit not updated successfully' });
                    dispatch({ type: 'notiMessageShow', payload: true });
                    dispatch({ type: 'notiVarient', payload: 'danger' });
                    hidemessage()
                }
            });
        } else {
            dispatch({ type: "isItineraryLoading", payload: false });

            dispatch({ type: 'notiMessage', payload: 'Credit limit value should be greater than low credit limit value' });
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiVarient', payload: 'danger' });
        }
    }



    /**
     * @description:This Event  will call when we select change language in header component
     * @author: Rambabu
     * @function:language
     * @param{*} data
     * @date : 08-09-2020
     */
    const language = (data) => {
        if (data === 'ar') {
            var { cnf, review, search } = require('../../../../../locale/ar/index');
            setConfirmation(cnf);
        } else {
            var { cnf, review, search } = require('../../../../../locale/en/index');
            setConfirmation(cnf);
        }

    }

    const handleBranchChange = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: 'selBranch', payload: e })
            AttCommonSerivce.getCompanyTAList(e[0].value, "TA").then(response => {
                if (response.data.suc) {
                    dispatch({ type: 'company_TA_list', payload: response.data.res })
                    dispatch({ type: 'currency', payload: e.currency });
                    dispatch({ type: 'bname', payload: e.label });
                    dispatch({ type: 'bid', payload: e.value });
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in getCompanyList function---" + error
                );
            });
        }
    }

    const transactTypeChange = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: 'selTrans', payload: e })
            dispatch({ type: 'trans_type', payload: e[0].value })
        }
    }

    const handleOrigin = (input, e) => {
        if (input.length > 2) {
            let pyload = {
                uid: context.logindata.uid,
                type: input
            }

            debouncedSave(pyload);
            // companypredticiveTAList(pyload).then(response => {
            //     console.log(response)
            //     if (response.length > 0) {
            //         dispatch({ type: 'companies', payload: response })
            //     } else {
            //         dispatch({ type: 'companies', payload: [] })
            //     }
            // })
        }
    }
    const debouncedSave = useCallback(
        debounce(obj => companypredticiveTAList(obj).then(response => {
            if (response.length > 0) {
                dispatch({ type: 'companies', payload: response })
            } else {
                dispatch({ type: 'companies', payload: [] })
            }
        }), 500),
        [], // will be created only once initially
    );
    const debouncedSTASave = useCallback(
        debounce(obj => companypredticiveTAList(obj).then(response => {
            if (response.length > 0) {
                dispatch({ type: 'stacompanies', payload: response })
            } else {
                dispatch({ type: 'stacompanies', payload: [] })
            }
        }), 500),
        [], // will be created only once initially
    );
    const handlestaOrigin = (input, e) => {
        if (input.length > 1) {
            let pyload = {}
            if (context.logindata.utype && "att_user" && state.selTA && state.selTA.length > 0) {
                pyload = {
                    cid: state.selTA[0].value,
                    type: input
                }
                debouncedSTASave(pyload)
                // companypredticiveTAList(pyload).then(response => {
                //     console.log(response)
                //     if (response.length > 0) {
                //         dispatch({ type: 'stacompanies', payload: response })
                //     } else {
                //         dispatch({ type: 'stacompanies', payload: [] })
                //     }
                // })
            } else if (context.logindata.utype === "ta_user") {
                pyload = {
                    cid: context.logindata.cid,
                    type: input
                }
                debouncedSTASave(pyload)
                // companypredticiveTAList(pyload).then(response => {
                //     console.log(response)
                //     if (response.length > 0) {
                //         dispatch({ type: 'stacompanies', payload: response })
                //     } else {
                //         dispatch({ type: 'stacompanies', payload: [] })
                //     }
                // })
            }
        }
    }
    const handleOnOrigin = (e) => {
        dispatch({ type: 'creditlimit', payload: '' })
        dispatch({ type: 'low_credit_limit', payload: '' })
        dispatch({ type: 'updatable', payload: false });
        dispatch({ type: 'amount', payload: 0 });
        dispatch({ type: 'trans_type', payload: '' });
        dispatch({ type: 'pType', payload: 'debit' });
        dispatch({ type: 'refData', payload: '' });
        dispatch({ type: 'remarks', payload: '' });
        dispatch({ type: 'selBranch', payload: [] })
        dispatch({ type: 'selTA', payload: [] })
        dispatch({ type: 'selSTA', payload: [] })
        dispatch({ type: 'selTrans', payload: [] })
        dispatch({ type: 'credit_limit_data', payload: [] })
        dispatch({ type: 'stacompanies', payload: [] })
        if (e && e.length > 0) {
            let compData = [{ label: e[0].cnm, value: e[0].cid }]
            dispatch({ type: 'selTA', payload: compData })
            dispatch({ type: 'company_ta_id', payload: compData[0] });
            dispatch({ type: 'notiMessage', payload: '' });
            dispatch({ type: 'notiMessageShow', payload: false });
            dispatch({ type: 'notiVarient', payload: '' });
            dispatch({ type: 'companyType', payload: 'TA' });
            dispatch({ type: 'bname', payload: e[0].bnm });
            dispatch({ type: 'bid', payload: e[0].bid });
            dispatch({ type: 'currency', payload: e[0].cur })
        }
    }
    const handleOnstaOrigin = (e) => {
        dispatch({ type: 'creditlimit', payload: '' })
        dispatch({ type: 'low_credit_limit', payload: '' })
        dispatch({ type: 'updatable', payload: false });
        dispatch({ type: 'amount', payload: 0 });
        dispatch({ type: 'trans_type', payload: '' });
        dispatch({ type: 'pType', payload: 'debit' });
        dispatch({ type: 'refData', payload: '' });
        dispatch({ type: 'remarks', payload: '' });
        dispatch({ type: 'selBranch', payload: [] })
        dispatch({ type: 'credit_limit_data', payload: [] })
        dispatch({ type: 'stacompanies', payload: [] })
        if (e && e.length > 0) {
            let compData = [{ label: e[0].cnm, value: e[0].cid }]

            dispatch({ type: 'selSTA', payload: compData })
            dispatch({ type: 'company_sta_id', payload: compData[0] });
            if (context.logindata.utype === "ta_user") {
                dispatch({ type: 'currency', payload: context.logindata.bcur });
                dispatch({ type: 'bname', payload: context.logindata.bnm });
                dispatch({ type: 'bid', payload: context.logindata.bid });
            }
            dispatch({ type: 'companyType', payload: 'STA' });
            getCompanyCreditlimitData(e[0].cid);
        }
    }
    const activeOrigin = (obj) => {
        //         dispatch({ type: "src", payload: obj[0].airportCode })
        //   dispatch({ type: "orignData", payload: obj })
        //   dispatch({ type: "selOrigin", payload: obj })
    }
    return (
        //console.log('credit_limit_data return----' + JSON.stringify(state.credit_limit_data)),
        //console.log('language event in credit manamgenet return------', confirmation.content),
        <>
            <MainMenu active='Payment & Billing' />
            <Breadcrumb activePage="Credit Management" />
            {/* <Notification messageText={state.notiMessage} variant={state.notiVarient} show={state.notiMessageShow} /> */}
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
                {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
            <div className="container">
                <div className="cardMainlayout pt-0">
                    <PaymentBillingMenu active="Credit management" />

                    <div className="contentArea">

                        {/* TA,STA Radio buttons
                        <Form.Row>
                            {state.branchMenu.length !== 0 && context.logindata.utype !== "ta_user" &&
                                <Form.Group as={Col} xs={3} className="selectplugin" controlId="ta">
                                    <Select
                                        isDisabled={enableView || state.updatebtn}
                                        options={state.branchMenu}
                                        id="companyid"
                                        values={state.selBranch}
                                        onChange={handleBranchChange} />
                                    <Form.Label> Branch <sup>*</sup></Form.Label>
                                </Form.Group>
                            }

                            {context.logindata.utype === "ta_user" &&
                                <Form.Group as={Col} xs={3} className="selectplugin" controlId="ta">
                                    <Select
                                        disabled={true}
                                        options={[{ "label": context.logindata.bnm, "value": context.logindata.bid }]}
                                        id="companyid"
                                        values={[{ "label": context.logindata.bnm, "value": context.logindata.bid }]}
                                    />
                                    <Form.Label> Branch <sup>*</sup></Form.Label>
                                </Form.Group>
                            }
                        </Form.Row> */}

                        <Form.Row>
                            {/* selected TAcompany dropdown */}
                            {context.logindata.utype === "att_user" &&
                                <Form.Group as={Col} xs={3} className="selectplugin predictiveSch" controlId="ta">
                                    <Form.Label>Select Travel Agent<sup>*</sup></Form.Label>
                                    <Typeahead
                                        //defaultInputValue={state.selectedOrigin}
                                        id="originmaster"
                                        labelKey={ta => `${ta.cnm} - ${ta.bnm}`}
                                        onInputChange={handleOrigin}
                                        options={state.companies}
                                        // selected={state.selcompany}
                                        onChange={ta => (handleOnOrigin(ta))}
                                        //onFocus={originOnFocus} onBlur={originOnBlur}
                                        placeholder="Select Ta Company"
                                        readOnly
                                        minLength={3}
                                        // onFocus={activeOrigin}
                                        renderMenuItemChildren={(ta) => (
                                            <>
                                                <strong className="ezyIcon icon-company"> {ta.cnm} </strong>
                                                <span>{ta.bnm} , {ta.country_name} </span>

                                            </>
                                        )}
                                    />
                                </Form.Group>
                            }

                            {/* STAcompany dropdown */}
                            {(isArrayNotEmpty(state.selTA) || context.logindata.utype === "ta_user") &&
                                <Form.Group as={Col} xs={3} className="selectplugin predictiveSch" controlId="sta">
                                    <Form.Label>Select Sub Travel Agent<sup>*</sup></Form.Label>
                                    <Typeahead
                                        //defaultInputValue={state.selectedOrigin}
                                        id="originmaster"
                                        labelKey={ta => `${ta.cnm} - ${ta.bnm}`}
                                        onInputChange={handlestaOrigin}
                                        options={state.stacompanies}
                                        // selected={state.selcompany}
                                        onChange={ta => handleOnstaOrigin(ta)}
                                        //onFocus={originOnFocus} onBlur={originOnBlur}
                                        placeholder="Select Ta Company"
                                        readOnly
                                        minLength={3}
                                        // onFocus={activeOrigin}
                                        renderMenuItemChildren={(ta) => (
                                            <>
                                                <strong className="ezyIcon icon-company"> {ta.cnm}  </strong>
                                                <span>{ta.bnm} , {ta.country_name} </span>

                                            </>
                                        )}
                                    />
                                </Form.Group>
                            }

                            <Form.Group as={Col} xs={3} className="selectplugin mb-0" controlId="ta">
                                <Select
                                    disabled={true}
                                    options={[{ "label": state.bname, "value": state.bid }]}
                                    id="companyid"
                                    values={[{ "label": state.bname, "value": state.bid }]}
                                />
                                <Form.Label> Branch <sup>*</sup></Form.Label>
                            </Form.Group>
                            {/* credit limit text box */}
                            <Form.Group as={Col} className="mb-0" xs={3}>
                                <Form.Row>
                                    {/* Branch currency */}
                                    <Form.Group as={Col} xs={5} controlId="crLimit" className="d-flex align-items-center">
                                        <Form.Control type="text" placeholder="Credit Limit" value={state.currency} disabled />
                                        <Form.Label>Currency<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={7} controlId="crLimit" className="d-flex align-items-center">
                                        <Form.Control type="number" placeholder="Credit Limit" value={state.creditLimit} onChange={handleChange} disabled={state.disable} />
                                        <Form.Label>Credit Limit<sup>*</sup></Form.Label>
                                    </Form.Group>


                                </Form.Row>
                            </Form.Group>
                        </Form.Row>

                        {/* alert check box */}
                        {!state.updatable &&
                            <>
                                <Form.Row>


                                    {/* Low credit limit value */}
                                    <Form.Group as={Col} xs={3} controlId="lowcrLimit" className="d-flex align-items-center">
                                        <Form.Control type="number" placeholder="Low Credit Limit" value={state.low_credit_limt} onChange={handleChange} disabled={state.disable} />
                                        <Form.Label>Low Credit Limit<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={4} controlId="icalInput" className="d-flex align-items-center">
                                        <Form.Check type="checkbox" id="ac" label="Alert when the avaliable credit limit is less than low credit value"
                                            checked={state.is_alert} onChange={handleChange}
                                            custom className="ml-0" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="remarks">
                                        <Form.Control as="textarea" rows="3" value={state.remarks} onChange={handleChange} />
                                        <Form.Label>Remarks<sup>*</sup></Form.Label>
                                    </Form.Group>
                                </Form.Row>
                            </>
                        }
                        {state.updatable &&
                            <div>

                                <Form.Row>
                                    <Form.Group as={Col} xs={3} className="selectplugin" controlId="t_type">
                                        <Select
                                            options={t_type}
                                            id="t_type"
                                            values={state.selTrans}
                                            onChange={(value) => (transactTypeChange(value))} />
                                        <Form.Label>Transaction Type<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={3} controlId="ref" className="d-flex align-items-center">
                                        <Form.Control type="text" value={state.refData} onChange={handleChange} />
                                        <Form.Label>Ref#<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={3} controlId="amt" className="d-flex align-items-center">
                                        <Form.Control type="number" placeholder="Amount" value={state.amount} onChange={handleChange} />
                                        <Form.Label>Amount<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="ttype" className="inputCheck">
                                        <div className="d-flex align-items-center">
                                            <Form.Check type="radio" id="debit" label="Debit" className="ml-0" name="ttype" custom checked={state.enableType} onChange={handleChange} />
                                            <Form.Check type="radio" id="credit" label="Credit" className="ml-3" name="ttype" custom checked={!state.enableType} onChange={handleChange} />
                                        </div>
                                        <Form.Label>Payment Type<sup>*</sup></Form.Label>
                                    </Form.Group>
                                </Form.Row>
                                {/* Low credit limit value */}
                                <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="lowcrLimit" className="d-flex align-items-center">
                                        <Form.Control type="number" placeholder="Low Credit Limit" value={state.low_credit_limt} onChange={handleChange} />
                                        <Form.Label>Low Credit Limit<sup>*</sup></Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={4} controlId="icalInput" className="d-flex align-items-center">
                                        <Form.Check type="checkbox" id="ac" label="Alert when the avaliable credit limit is less than low credit value"
                                            checked={state.is_alert} onChange={handleChange}
                                            custom className="ml-0" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="remarks">
                                        <Form.Control as="textarea" rows="3" value={state.remarks} onChange={handleChange} />
                                        <Form.Label>Remarks<sup>*</sup></Form.Label>
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        }
                        {/* buttons save */}
                        <div className="text-right buttonGrop pb-0">

                            {/* buttons cancel */}

                            {!state.updatebtn &&
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    type="submit"
                                    onClick={handleCancel}
                                > Reset</Button>
                            }

                            {context.logindata.roles !== undefined && context.logindata.roles.includes('C_R_C') &&
                                <Button
                                    size="xs"
                                    onClick={handleSaveBtn}
                                    variant="outline-primary"
                                    type="submit"
                                >Save</Button>
                            }


                            {/* buttons cancel */}
                            {state.updatebtn && <Button
                                size="xs"
                                variant="outline-secondary"
                                type="submit"
                                onClick={handleCancel}
                            > Reset</Button>}
                            {/* buttons update*/}
                            {state.updatebtn && <Button
                                size="xs"
                                onClick={handleUpdateBtn}
                                variant="outline-primary"
                                type="submit"
                                isDisabled={enableView}
                            >Update</Button>}


                        </div>
                    </div>



                </div>
                {/* Company credit limit data table */}
                {isArrayNotEmpty(state.credit_limit_data) &&
                    <div className="cardMainlayout tableRes p-0">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            classes="markup filterTbl"
                            data={state.credit_limit_data}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            pagination={state.credit_limit_data.length > 10 && state.credit_limit_data.length <= 25 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }] }) :
                                state.credit_limit_data.length > 25 && state.credit_limit_data.length <= 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }] }) :
                                    state.credit_limit_data.length > 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }, { text: '50', value: 50 }] }) : ''}
                        />
                    </div>
                }
                {state.credit_limit_data === 0 &&
                    <NoRecord />
                }

            </div>
            <Footer />
        </>
    )
}
export default Creditmanagement