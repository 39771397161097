import log from "loglevel";
import React, { useEffect, useReducer, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Button from '../../../../common/buttons/Button';
import ModifySearch from '../../common/ModifySearch';
import Footer from '../../../../common/Footer';
import Header from '../../../../common/Header';
import DateUtils from '../../../../commonUtils/DateUtils';
import ReissueDetails from '../../../../postBooking/reIssue/ReissueDetails.jsx';
import { fireMultiCityReissueSearch, fireMultiCitySearch, fireSearchReq } from '../../review/operations';
import ResultPageLoader from '../../searchLoaders/ResultPageLoader';
import ResultSection from '../common/ResultSection';
import ResultsNotFound from '../../../../common/popUps/ResultsNotFound';
export const CLIENT_IP = "clientIp";
export const REISUE_RESP = "reisueResp";
const _ = require('lodash')
const initialState = {
  searchResp: "", loading: false,clickedModifySearch:false, results: true, enable: true, load: true, searchRequest: "", showpop: false,backhome:false,searchRespCount:undefined,impTag:"", showPopReissue: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function FlightResultsPage(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [request, setRequest] = useState(props.location);
  const [initsrequest,setinitsRequest] =useState(_.cloneDeep(props.location))
  const history = useHistory();

  useEffect(() => {
    resultLoad();
    if (props.location.state !== undefined) {
      if (props.location.state !== undefined && props.location.state.req !== undefined &&
        // request.state.req.modify!==undefined &&
        props.location.state.req.modify) {
        createSearchReq(props.location.state.req, true);
        setRequest(props.location);
      } else {//For ONHOLD Rebook
        if (request!==undefined && request.state!==undefined && request.state.req!==undefined && request.state.req.flowType !== undefined && (request.state.req.flowType === "ONHOLD REBOOK" ||
          request.state.req.flowType === "INCOMPLETE REBOOK")) {
          resultLoad();
          setRequest(props.location);
          if (request.state.req.tt !== 3) {
            createOnholdSearchReq(props.location.state.req);
          } else {
            createMultiOnholdRequest(props.location.state.req);
          }
        }
      }
    }
  }, [props.location !== undefined && props.location.state !== undefined && props.location.state.flow===undefined]);

  useEffect(() => {
    window.scrollTo(0, 0);
    resultLoad();
    if (request.state !== undefined) {
      if (request.state.req.modify == false && request.state.req.flowType!==undefined && request.state.req.flowType === "DIRECT") {
        createSearchReq(request.state.req, false);
      }
      else if (request.state.req.modify == undefined && request.state.req.flowType!==undefined && request.state.req.flowType === "DIRECT") {
        createSearchReq(request.state.req, false);
      }
    } else {
      if (props.location.state !== undefined && props.location.state.req !== undefined && props.location.state.req.flowType !== undefined
        && (props.location.state.req.flowType !== "ONHOLD REBOOK" || request.state.req.flowType === "INCOMPLETE REBOOK")) {
        return history.push({
          pathname: '/ezytrip/bookings/flight/error',
          state: {
            req: "Farerule"
          }
        });
      } else {
        resultLoad();
      }
    }
  }, [request]);

  useEffect(() => {
    dispatch({ type: "loading", payload: true })
    if(props.location.state !== undefined && props.location.state.flow==="IMPORT/REISSUE"){
        dispatch({ type: "load", payload: false })
        dispatch({ type: "enable", payload: false })
        dispatch({ type: "results", payload: true })
        if(props.location.state.response!==undefined){
          dispatch({ type: "searchResp", payload: props.location.state.response})
          localStorage.setItem(REISUE_RESP,JSON.stringify(props.location.state.response))
        }else{
          let resp=localStorage.getItem(REISUE_RESP);
          dispatch({ type: "searchResp", payload: JSON.parse(resp)})
        }
        dispatch({ type: "enable", payload: true })
        dispatch({ type: "loading", payload: false })
        dispatch({ type: "load", payload: true })
        dispatch({ type: "showpop", payload: false })
    }
  }, [props.location !== undefined && props.location.state !== undefined && props.location.state.flow!==undefined]);

  const setClsType = (cls) => {
    var cl = "E";
    if (cls === "Economy") {
      cl = "E"
    } else if (cls === "Business") {
      cl = "B"
    } else if (cls === "First class") {
      cl = "F"
    } else if (state.cls == "Premium") {
      cl = "P"
    }
    return cl;
  }
  //=====================================
  // Creating the Search request.state.req
  //=====================================
  const createOnholdSearchReq = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    var requrl = "";
    var departure = request.origns!==undefined && request.origns.length!==0 && request.origns[0].srcorigns[0].seg[0].da?request.origns[0].srcorigns[0].seg[0].da:request.srcCode;
    var arrival = request.origns!==undefined && request.origns.length!==0 && request.origns[0].srcorigns[0].seg.slice(-1)[0].ar?request.origns[0].srcorigns[0].seg.slice(-1)[0].ar:request.destCode;
    const deptDate = request.origns!==undefined && request.origns.length!==0 && request.origns[0].srcorigns[0].seg[0].dd?DateUtils.convertStringToDate(request.origns[0].srcorigns[0].seg[0].dd):request.dd;
    var cls = request.origns!==undefined && request.origns.length!==0?setClsType(request.origns[0].srcorigns[0]):setClsType(request.clstyp);
    if (request.tt === "1") {
      requrl = "dep=" + departure + "&arr=" + arrival + "&dd=" + deptDate + "&tt=" + 1 + "&cl=" + cls + "&ns=" + request.ns + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "normal" + "&cur=" + request.cur + "&pa=" + request.pa + "" + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + "DOHQR2718";
    } else if (request.tt === "2") {
      const arrDate = DateUtils.convertStringToDate(request.origns[0].srcorigns[0].seg.slice(-1)[0].ad);
      requrl = "dep=" + departure + "&arr=" + arrival + "&dd=" + deptDate + "&ad=" + arrDate + "&tt=" + 2 + "&cl=" + cls + "&ns=" + "" + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "normal" + "&cur=" + request.cur + "&pa=" + "" + "" + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + "DOHQR2718";
    }
    callSearchReq(requrl, false);
  }

  //=====================================
  // Creating the Search request.state.req
  //=====================================
  const createSearchReq = (request, modify) => {
    var randomStr = Math.random().toString(36).slice(2);
    var requrl = "";
    window.scrollTo(0, 0);
    if (Number(request.tt) === 1) {
      requrl = "dep=" + request.srcCode + "&arr=" + request.destCode + "&dd=" + request.dd + "&tt=" + 1 + "&cl=" + request.cls + "&ns=" + request.ns + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "normal" + "&cur=" + request.cur + "&pa=" + request.pa + "" + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + request.office_id + "&src_cnt=" + request.src[0].countryCode + "&dest_cnt=" + request.dest[0].countryCode + "&client_ip=" + localStorage.getItem(CLIENT_IP) + "&req_cur=" + request.cur + "&region_type=" + request.region_type;
      callSearchReq(requrl, modify);
    } else if (Number(request.tt) === 2) {
      requrl = "dep=" + request.srcCode + "&arr=" + request.destCode + "&dd=" + request.dd + "&ad=" + request.ad + "&tt=" + 2 + "&cl=" + request.cls + "&ns=" + request.ns + "&adt=" + request.adt + "&chd=" + request.chd + "&inf=" + request.inf + "&cid=" + request.cid + "&cha=" + "B2B" + "&echo=" + randomStr + "&sty=" + "normal" + "&cur=" + request.cur + "&pa=" + request.pa + "" + "&bid=" + request.bid + "&butype=" + request.butype + "&pcid=" + request.pcid + "&ofcId=" + request.office_id + "&src_cnt=" + request.src[0].countryCode + "&dest_cnt=" + request.dest[0].countryCode + "&client_ip=" + localStorage.getItem(CLIENT_IP) + "&req_cur=" + request.cur + "&region_type=" + request.region_type;
      callSearchReq(requrl, modify);
    } else if (Number(request.tt) === 3 && request.flowType === "DIRECT") {
      var mrequest = createMultiCityRequest(request);
      if (request.modify) {
        dispatch({ type: "results", payload: false })
      }
      fireMultiCitySearch(mrequest).then((response) => {
        localStorage.setItem("MULTI", JSON.stringify(response));
        if (response !== undefined && response.status === "success") {
          localStorage.setItem("RESPONSE", JSON.stringify(response));
          dispatch({ type: "load", payload: false })
          dispatch({ type: "enable", payload: false })
          dispatch({ type: "searchResp", payload: response })
          dispatch({ type: "results", payload: true })
          dispatch({ type: "enable", payload: true })
          dispatch({ type: "loading", payload: false })
          dispatch({ type: "load", payload: true })
          dispatch({ type: "showpop", payload: false })
        } else {
          dispatch({ type: "load", payload: false })
          dispatch({ type: "showpop", payload: true })
          dispatch({ type: "backhome", payload: true })
          dispatch({ type: "load", payload: true })

        }
      });
    } else if (Number(request.tt) === 3 && request.flowType === "REISSUE") {
      var mrequest = createMultiCityReissueRequest(request);
      if(mrequest.bktype) {
        dispatch({type:"impTag",payload:"IMPORT/REISSUE"})
      }
      if (request.modify) {
        dispatch({ type: "results", payload: false })
      }
      fireMultiCityReissueSearch(mrequest).then((response) => {
        if (response !== undefined && response.status === "success") {
          dispatch({ type: "load", payload: false })
          dispatch({ type: "enable", payload: false })
          dispatch({ type: "searchResp", payload: response })
          dispatch({ type: "results", payload: true })
          dispatch({ type: "enable", payload: true })
          dispatch({ type: "loading", payload: false })
          dispatch({ type: "load", payload: true })
          dispatch({ type: "showpop", payload: false })
        } else if(response.data!==undefined && response.data.is_flown!==undefined && response.data.is_flown){
          dispatch({ type: "load", payload: false })
          dispatch({ type: "showPopReissue", payload: true })
          dispatch({ type: "loading", payload: false })
          dispatch({ type: "load", payload: true })
        }else{
          dispatch({ type: "load", payload: false })
          dispatch({ type: "showpop", payload: true })
          dispatch({ type: "loading", payload: false })
          dispatch({ type: "load", payload: true })
          dispatch({ type: "showPopReissue", payload: false })
        }
      });
    }
  }
  const handleCLClose = () => {
    dispatch({ type: 'showPopReissue', payload: false });
    window.location = "/ezytrip/dashboard/bookingsummary/" + request.state.req.booking_id
  }

  const convertSegments = (request) => {
    let seg = [];
    const payOne = {
      dep: request.search.dep1,
      arr: request.search.arr1,
      dd: request.search.dd1,
      seg: 1
    }
    seg.push(payOne);
    if (request.search.dep2 !== "" && request.search.arr2 !== "" && request.search.dep2 !== undefined && request.search.arr2 !== undefined) {
      const payTwo = {
        dep: request.search.dep2,
        arr: request.search.arr2,
        dd: request.search.dd2,
        seg: 2
      }
      seg.push(payTwo);
    }
    if (request.search.dep3 !== "" && request.search.arr3 !== "" && request.search.dep3 !== undefined && request.search.arr3 !== undefined && request.search.dd3!==undefined) {
      const payThree = {
        dep: request.search.dep3,
        arr: request.search.arr3,
        dd: request.search.dd3,
        seg: 3
      }
      seg.push(payThree);
    }
    if (request.search.dep4 !== "" && request.search.dep4 !== undefined && request.search.dd4!==undefined) {
      const payFour = {
        dep: request.search.dep4,
        arr: request.search.arr4,
        dd: request.search.dd4,
        seg: request.flowType!=="REISSUE"?4:2
      }
      seg.push(payFour);
    }
    if (request.search.dep5 !== "" && request.search.dep5 !== undefined) {
      const payFive = {
        dep: request.search.dep5,
        arr: request.search.arr5,
        dd: request.search.dd5,
        seg: request.flowType!=="REISSUE"?5:3
      }
      seg.push(payFive);
    }
    if (request.search.dep6 !== "" && request.search.arr6 !== undefined) {
      const paySix = {
        dep: request.search.dep6,
        arr: request.search.arr6,
        dd: request.search.dd6,
        seg: request.flowType!=="REISSUE"?6:3
      }
      seg.push(paySix);
    }
    return seg;

  }
  const onClickHandler = () =>{
    dispatch({ type: "clickedModifySearch", payload: true })
    }
  const isResetSelected =() => {
    dispatch({ type: "clickedModifySearch", payload: false })
  }
  const createMultiCityRequest = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    const payload = {
      "tt": 3,
      "cl": request.cl,
      "ns": request.search.ns,
      "cur": request.cur,
      "chan": "B2C",
      "adt": request.search.adt,
      "chd": request.search.chd,
      "inf": request.search.inf,
      "echo": randomStr,
      "sty": "normal",
      "pa": request.search.pa,
      "cid": request.cid,
      "seg": convertSegments(request),
      "bid:": request.bid,
      "butype": request.butype,
      "pcid": request.pcid,
      "region_type" : request.region_type,
      "ofcId":request.office_id,
      "req_cur": request.cur
    }
    return payload;
  }

  //Create Multi city onhold request
  const createMultiOnholdRequest = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    const payload = {
      "tt": 3,
      "cl": request.cl,
      "ns": false,
      "cur": request.cur,
      "chan": "B2C",
      "adt": request.adt,
      "chd": request.chd,
      "inf": request.inf,
      "echo": randomStr,
      "sty": "normal",
      "pa": "",
      "cid": request.cid,
      "seg": convertSegments(request),
      "bid:": request.bid,
      "butype": request.butype,
      "pcid": request.pcid
    }
    fireMultiCitySearch(payload).then((response) => {
      if (response !== undefined && response.status === "success") {
        dispatch({ type: "load", payload: false })
        dispatch({ type: "enable", payload: false })
        dispatch({ type: "searchResp", payload: response })
        dispatch({ type: "results", payload: true })
        dispatch({ type: "enable", payload: true })
        dispatch({ type: "loading", payload: false })
        dispatch({ type: "load", payload: true })
        dispatch({ type: "showpop", payload: false })
      } else {
        dispatch({ type: "load", payload: false })
        dispatch({ type: 'showpop', payload: true });
        dispatch({ type: "backhome", payload: true })
        dispatch({ type: "loading", payload: false })
        dispatch({ type: "load", payload: true })
      }
    });
  }

  //Convert Reissue Multicity request
  const createMultiCityReissueRequest = (request) => {
    var randomStr = Math.random().toString(36).slice(2);
    request.echo = randomStr;
    request.newseg = convertSegments(request)
    request.old_cl=setClsType(request.oldCls)
    let searchReq = Object.assign({}, request);
    delete searchReq.search;
    delete searchReq.flowType;
    delete searchReq.modify;
    delete searchReq.clstyp;
    delete searchReq.ns;
    return searchReq;
  }



  //=====================================
  // Calling the Search request.state.req
  //=====================================
  const callSearchReq = (requrl, modify) => {
    try {
      if (modify) {
        dispatch({ type: "results", payload: false })
      }
      fireSearchReq(requrl).then((response) => {
        if (response !== undefined && response.status === "success") {
          localStorage.setItem("RESPONSE", JSON.stringify(response));

          dispatch({ type: "load", payload: false })
          dispatch({ type: "enable", payload: false })
          dispatch({ type: "searchResp", payload: response })
          dispatch({ type: "results", payload: true })
          dispatch({ type: "enable", payload: true })
          dispatch({ type: "loading", payload: false })
          dispatch({ type: "load", payload: true })
          dispatch({ type: "showpop", payload: false })
        } else {
          dispatch({ type: "load", payload: false })
          dispatch({ type: 'showpop', payload: true });
          dispatch({ type: "backhome", payload: true })
          dispatch({ type: "loading", payload: false })
         dispatch({ type: "load", payload: true })
        }
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }

  const resultLoad = () => {
    if (state.searchResp !== undefined && state.searchResp.status !== "" && state.searchResp.status === "success") {
      dispatch({ type: "loading", payload: false })
    } else {
      dispatch({ type: "loading", payload: true })
    }
  }

  const handleResultCallBack = (res) => {

    dispatch({ type: "searchRespCount", payload: res.origns.length })
  }

  const handleCL = () => {
    dispatch({ type: 'showpop', payload: false });
    dispatch({ type: "loading", payload: false })
    dispatch({ type: "load", payload: false })
    if(request.state.req.flowType==="REISSUE"){
      return history.push({
        pathname: '/ezytrip/dashboard/reissue',
        state: {
          req: request.state.req
        }
      });
    }else{
      return window.location="/ezytrip/dashboard";
    }
  }

  return (
    <>

      <Header branchId={request.state !== undefined && request.state.req !== undefined && request.state.req.bid} companyId={request.state !== undefined && request.state.req !== undefined && request.state.req.cid} />

      {request.state !== undefined && request.state.req !== undefined &&
        <div>
          {request.state.req.flowType === "REISSUE" &&
            <div>
              <ReissueDetails impTag={state.impTag} reissueDetails={request.state.req} />
            </div>
          }
          {state.load && <ModifySearch onClickHandler={onClickHandler}response={state.searchResp} flow={request.state.req.flowType} sreq={request.state.req} initreq={initsrequest.state.req} load={state.load} dateAvailable={true} routeAvailable={true} countSRP ={ state.showpop ? 0 : state.searchRespCount} stopButtonLoad={state.showpop}/>}
          {!state.showpop &&
          <div className="container resultLayout mb-4">
            {state.results && state.searchResp !== undefined && state.searchResp.data!==null && state.searchResp.data !== undefined ?
            <ResultSection isResetSelected = {isResetSelected} isModified={state.clickedModifySearch}searchRes={state.searchResp.data[0]} impTag={request.state.req && request.state.req.bktype && request.state.req.bktype == "IMPORT/REISSUE" ? "IMPORT" : ""} triptype={request.state.req.tt} srequest={request.state.req} handleResultSection={handleResultCallBack}/> : <ResultPageLoader triptype={request.state.req.tt} />}
          </div>
          }
        </div>
      }
      <Modal
        show={state.showPopReissue}
        onHide={handleCLClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>We are Unable to process this booking.</strong>
              <p>Please Contact support team For The Ticket Issuance</p>
              <a id="phone" className="navbar-brand ezyIcon icon-contactUs" href="tel:+97444798899"> +974 44798899</a>
              <a id="mail" href="mailto:support@ezytrip.com" className="navbar-brand ezyIcon icon-new-email mr-1" >support@ezytrip.com</a>
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-dark" onClick={handleCLClose}>
            Back
        </Button>
        </Modal.Footer>
      </Modal>
    {state.showpop &&
      <ResultsNotFound  product={"Flight"}/>
    }

      <Footer />
    </>
  )
}
export default FlightResultsPage