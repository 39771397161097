
import React, { Component, useState, useEffect } from 'react';
import { Form, Button, Col, Row } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function Amenities(props) {
  const [state, setState] = useState({})
  const [payments , seyPayments] = useState()
  const [cash, setCash] = useState("")
  const [debitcardNot, setDebitCardNot] = useState("")
  const [debitcard, setDebitCard] = useState("")
  const [masterCard, setMasterCard] = useState("")
  const [dinersClub, setDinersClub] = useState("")
  const [jsb, setJsb] = useState("")
  const [visa, setVisa] = useState("")
  const [american, setAmerican] = useState("")
  const [discover, setDiscover] = useState("")


  useEffect(() => {
    if(props.payments!==undefined && props.payments.types!==null){
      seyPayments(props.payments.types)
      if(props.payments.types!==undefined){
        let cash=props.payments.types[407]
        if(cash!==undefined){
          setCash(cash.name)
        }
        let cardnot=props.payments.types[1073744166]
        if(cardnot!==undefined && cardnot.name!==undefined){
          setDebitCardNot(cardnot.name)
        }
        let acpcard=props.payments.types[1073742461]
        if(acpcard!==undefined && acpcard.name!==undefined){
          setDebitCard(acpcard.name)
        }
        let american=props.payments.types[171]
        if(american!==undefined && american.name!==undefined){
          setAmerican(american.name)
        }
        let diners=props.payments.types[179]
        if(diners!==undefined && diners.name!==undefined){
          setDinersClub(diners.name)
        }
        let jcb=props.payments.types[181]
        if(jcb!==undefined && jcb.name!==undefined){
          setJsb(jcb.name)
        }
        let disc=props.payments.types[184]
        if(disc!==undefined && disc.name!==undefined){
          setMasterCard(disc.name)
        }
        let visa=props.payments.types[189]
        if(visa!==undefined && visa.name!==undefined){
          setVisa(visa.name)
        }
      }
    }
    window.scrollTo(0, 0)
  }, [])

  return (

    <>


          <Row>
              <Col xs={12}>
               <strong className="mt-0">Check-in and Check-out</strong>
               <Row>
                 <Col xs={4} className="d-flex justify-content-between chkIn">
                 <p>Check-in <span>from {props.cin.beginTime}</span></p> <p>Check-out <span>before {props.cout.time}</span></p>
                 </Col>
               </Row>

                  <strong>Special check-in instructions</strong>
                  
                    {props.canpolicy.instructions!==undefined && props.canpolicy.instructions!==null && props.canpolicy.specialInstructions!==undefined ? (
                      <>
                      <div className="cnrvtTag" dangerouslySetInnerHTML={{ __html: props.canpolicy.instructions }} />
                      
                      <div className="cnrvtTag" dangerouslySetInnerHTML={{ __html: props.canpolicy.specialInstructions }} />
                      </>
                      
                    ) : (
                      <p>Not Avaialable</p>
                    )
                    
                    }
                  <strong>Policies</strong>
                  {props.policy.knowBeforeYouGo!==undefined && props.policy.knowBeforeYouGo!==null ? (
                      <div className="cnrvtTag" dangerouslySetInnerHTML={{ __html: props.policy.knowBeforeYouGo }} />
                    ) : (
                      <p>Not Avaialable</p>
                    )
                    }
                  {cash!=="" && cash!==undefined &&
                   <strong className="mt-3">Accepted Cash</strong>
                  }
                  {debitcardNot!=="" && debitcardNot!==undefined &&
                   <strong className="mt-3">Debit Cards Not Accepted </strong>
                  }
                  {debitcard!=="" && debitcard!==undefined &&
                   <>
                   <strong className="mt-3"> Debit Cards Accepted</strong>
                   <div className="acptCard"><div className="icons mstr"></div><div className="icons visa"></div></div>
                  </>
                  }
                  {(masterCard!=="" || dinersClub!=="" || jsb!=="" || visa!=="" || american!=="") &&
                   <>
                   <strong className="mt-3"> Credit Cards Accepted</strong>
                   <div className="acptCard"><div className="icons mstr"></div><div className="icons visa"></div><div className="icons amc"></div></div>
                  </>
                  }
              </Col>
          </Row>

    </>
  )
}


export default Amenities;
