
import React, { useCallback, useEffect, useState } from 'react';
import Tabs from '../../../common/tabs/Tabs';
import FareRules from '../fareRules/FareRules';
import TravelFusionFareRules from '../fareRules/TravelFusionFareRules';
import TripCost from '../review/TripCost';
import Baggage from '../search/common/Baggage';
import FlightSectors from './FlightSectors';

function FlightDetails(props) {
    const [flightDetails, setFlightDetails] = useState({ active: 'flight' });
    const [onwardSup , setOnwardSup] = useState("")
    const [returnSup , setReturnSup] = useState("")
    const mappings = props.mapping;
    useEffect(() => {
        setFlightDetails({ active: 'flight' })
    }, [props.sector]);

    const updateServiceFee = useCallback((data) => {

    })

    useEffect(() => {
        if(props.reviewFlight!==undefined){
          setOnwardSup(props.reviewFlight.origns[0].srcorigns[0].sup);
          if(props.reviewFlight.origns[0]!==undefined && props.reviewFlight.origns[0].destorigins!==undefined){
          setReturnSup(props.reviewFlight.origns[0].destorigins[0].sup)
          }
        }
    }, [props.reviewFlight]);

    useEffect(() => {
        if(props.fareop) {
        setFlightDetails({ active: 'flight' })
        }
    },[props.fareop])
    const content = {
        flight: <FlightSectors origin={[props.origin[0], 'Sector']} page={props.page} bktype={props.bktype} mapping={mappings} />,
        baggage: <>{props.sup!==undefined && props.sup==="0" && <Baggage origin={props.origin[0]} mapping={mappings} />}</>,
        fareDetails: (props.origin[1] === "Result") ? <TripCost sty={props.sty} seatPrice={0} tripCost={props.sector} srequest={props.srequest} cur={props.currency} flowtype="result" sendSrviceFee={updateServiceFee} /> : "",
        fareRules:<>{(props.flights!==undefined && props.flights[0]!==undefined && props.flights[0].sup==="0" ) || (props.reviewFlight!==undefined && props.reviewFlight.tt===3) || (props.selFlt===1 && onwardSup!=="" && onwardSup==="0") || (props.selFlt===2 && returnSup!=="" && returnSup==="0") || (props.secType==="onward" && props.splitFlights!==undefined && props.splitFlights[0]!==undefined && props.splitFlights[0].srcorigns!==undefined && props.splitFlights[0].srcorigns[0]!==undefined && props.splitFlights[0].srcorigns[0].sup==="0") || (props.secType==="arrival" && props.splitFlights!==undefined && props.splitFlights[1]!==undefined && props.splitFlights[1].srcorigns!==undefined && props.splitFlights[1].srcorigns[0]!==undefined && props.splitFlights[1].srcorigns[0].sup==="0") || (props.mulflights !== undefined) ? <FareRules mapping={mappings} sty={props.sty} flights={props.flights} mulflights={props.mulflights} selectedFlight={props.sector} srequest={props.srequest} reviewFlight={props.reviewFlight} splitFlights={props.splitFlights} selFlt={props.selFlt}/>:<TravelFusionFareRules secType={props.secType} sty={props.sty} flights={props.flights} selectedFlight={props.sector} srequest={props.srequest} reviewFlight={props.reviewFlight} splitFlights={props.splitFlights} selFlt={props.selFlt} regionType={props.regionType} />}</>
    };

    return (
        <>
            <div className="flightDetails">
                <div className="tabLink">
                    <Tabs active={flightDetails.active} onChange={active => setFlightDetails({ active })}>
                        <span key="flight">Flight details</span>
                        {props.sup!==undefined && props.sup==="0" &&
                        <span key="baggage"> Baggage</span>
                        }
                        {props.origin[1] !== "Confirm" &&
                            <span key="fareDetails">Fare Details</span>
                        }
                      <span key="fareRules"> Fare Rules</span>
                    </Tabs>
                    <div className="tabContent">
                        {content[flightDetails.active]}
                    </div>
                </div>
            </div>

        </>
    )
}

export default FlightDetails;
