import axios from 'axios'
import { Console } from 'winston/lib/winston/transports';

const HOTEL_BOOK_URL = process.env.REACT_APP_HOTEL_BOOK_URL
const B2B_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: This function will get the all services fro Flight Booking flow
 * @author: Lakshmi
 * @param {*}
 * @function Flight Service
 * @date : 02-09-2020
 */
class HotelService {
       async fireHotelSearch(url) { 
        const req = {
            headers: {
                'Content-Type': 'application/json'
            }
           }
           return await axios.get(`${HOTEL_BOOK_URL}/hotelavailability/check?${url}`,req)
    }
    async sendHotelConfirmationObj(req) {
        return await axios.post(`${B2B_URL}/ezytrip/postbook/send_email`,req)
    }

    async saveManualBooking(prereq) {
        return await axios.post(`${B2B_URL}/ezytrip/hotel/manualbook`,prereq);
    }
}
export default new HotelService()