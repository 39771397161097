import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
const CoomonExportExcel = ({
data, headers, name, reference, show,
}) => {
const [csvHeaders, setCsvHeaders] = useState([]);
console.log(data);

useEffect(() => {
if (headers && headers.length > 0) {
setCsvHeaders(headers);
}
}, [headers]);

return (
<CSVLink
data={data}
headers={csvHeaders}
filename={name}
className="export-csv"
ref={reference}
title="Export"
>{show && <p className="export"><span className="expBtn"><span className="ezyIcon icon-report mr-2"></span> Export</span></p>}
</CSVLink>);
};

export default CoomonExportExcel;