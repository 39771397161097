
import React, { useState } from 'react';
import { Col, Row, Table } from "react-bootstrap";
import FormateCurrency from '../../../commonUtils/FormateCurrency';

function InsPayment(props) {
    const [state, setState] = useState({})
    const [flightDetails, setFlightDetails] = useState(false);
    return (
        <>
                <Row>
                    <Col className="farepaymentInfo">
                    <div class="table-responsive">
                        <Table>
                            <tr>
                                <th>Payment Type</th>
                                <th>Form of Payment</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                            {props.payments && props.payments.map((pay, idx) => (
                                <>
                                {pay.psta!=="Pending" &&
                                <tr>
                                        <td><span>{"Credit Limit"}</span> </td>
                                        <td><span>{pay.formofpay!==""?pay.formofpay:"N/A"}</span> </td>
                                        <td>{props.cur} {<FormateCurrency value={pay.amt} />}</td>
                                        <td className={pay.psta === "Confirmed" ? "status paxstatus confirm" : "status paxstatus "}>{pay.psta} </td>
                                    </tr>
                                }

                                </>
                            ))}
                        </Table>
                        </div>
                    </Col>
                </Row>
        </>
    )
}


export default InsPayment;
