import log from "loglevel";
import moment from "moment";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Row, Tab,Tabs } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import { AirlinesContext } from '../../../../../App';
import { countriesWitCode, mealPrefs, seatPrefs, specialPrefs, stdBookingCodes, titles } from '../../../adminstration/utils';
import ShowHide from '../../../common/showHide/ShowHide';
import DateUtils from '../../../commonUtils/DateUtils';
import AddBaggage from '../review/extra/AddBaggage';
import AddMeal from '../review/extra/AddMeal';
import { isNotNull } from "../../../commonUtils/validators";
/**
 * @description:This function will creaet all the Passenger details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 26-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  chds: 0, infs: 0, doc: "Yes", doct: "P", passengers: [], stdCodeMenu: stdBookingCodes(), countriesMenu: [], titleList: titles(), mealPrefs: mealPrefs(), spPrefs: specialPrefs(), seatPrefs: seatPrefs(), airlines: [], selTraveller: "", adtList: [], chdList: [], infList: [], enableImport: false, passportEnable: false, flowType: "", title: [], enbalePPInfo: false, docMandatory: "", infTitles: [], baggageOptions: [], baggageReturnOptions: [], mealOptions: [], bOwname: "", bRtname: "", owbagTid: "", rtBagTid: "", enableBagOption: false, cheked: false,mealReturnOptions: [],
  passengerUniqueKey:[],alJazeeraBooking:false,enableFields:true,onwardAJbaggage:false,returnAJbaggage:false,owmealTid:'',rtmealTid:'',deptDate:'',depYear:0,childDeptDate:''
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//=====================================
// This function will create the List travellers beased on the selected Pax
//=====================================
function TravellersDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [otherPreference, setOtherPreference] = useState(false)
  const [frequentFlyerNumber, setFrequentFlyerNumber] = useState(false)
  const [resp, setResponse] = useState(props.response);
  const [adts, setAdt] = useState(props.srequest.adt)
  const [chds, setChd] = useState(props.srequest.chd)
  const [infs, setInf] = useState(props.srequest.inf)
  const [airlineCode, setAirlineCode] = useState(props.srequest.origns[0].srcorigns[0].seg[0].ma)
  const [issueDate1, setIssueDate1] = useState()
  const [issueDate2, setIssueDate2] = useState()
  const [expDate1, setExpDate1] = useState()
  const [expDate2, setExpDate2] = useState()
  const airlinesInfo = useContext(AirlinesContext)
  const dobManditory = props.checkMandatoryFlagDOB



  useEffect(() => {
    const tlist = [{ "value": "", "label": "Select" }, { "value": "Miss", "label": "Miss" }, { "value": "Mstr", "label": "Mstr" }, { "value": "Ms", "label": "Ms" }]
    const titleList = tlist.map((title) =>
      <option value={title.value}>{title.label}</option>
    );
    dispatch({ type: 'title', payload: titleList })

    const list = [{ "value": "", "label": "Select" }, { "value": "Miss", "label": "Miss" }, { "value": "Mstr", "label": "Mstr" }]
    const listTitles = list.map((title) =>
      <option value={title.value}>{title.label}</option>
    );
    dispatch({ type: 'infTitles', payload: listTitles })
    if (props.fareresp !== undefined && props.fareresp.data != undefined && props.fareresp.data[0] !== undefined) {
      dispatch({ type: 'docMandatory', payload: props.fareresp.data[0].doc })
      if (props.fareresp.data[0].mand !== undefined && props.fareresp.data[0].mand[0] !== undefined) {
        if(props.fareresp.data[0].sup == undefined) {
        if (props.srequest.sty === "normal") {
          if (props.srequest.tt === "1") {
            if(props.fareresp.data[0].mand[0].bag!==undefined && props.fareresp.data[0].mand[0].bag.length!==0){
            let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions") );
            let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            if(bagList.length!==0 && bagList[0]!==undefined){
              dispatch({ type: 'bOwname', payload: bagList[0].nm })
              dispatch({ type: 'baggageOptions', payload: bagList[0].optins })
            }
            if(mealList.length !== 0 && mealList[0] !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealList[0].optins })
            }
          }
          } else {
            if(props.fareresp.data[0].mand[0].bag!==undefined && props.fareresp.data[0].mand[0].bag.length!==0){
            let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardLuggageOptions");
            let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
            let mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            if (bagOnwardList.length!==0 && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload: bagOnwardList[0].optins })
              dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
            }
            if (bagRetunList.length!==0 && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload: bagRetunList[0].optins })
              dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
            }
            if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
            }
            if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
              dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
            }
            if (bagOnwardList.length === 0 && bagRetunList.length === 0) {
              let onwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "LuggageOptions");
              if(onwardList.length!==0){
                dispatch({ type: 'baggageOptions', payload: onwardList[0].optins })
                dispatch({ type: 'bOwname', payload: onwardList[0].nm })
                dispatch({ type: 'enableBagOption', payload: true })
              }
            }
          }
        }
        } else {
          let onwardList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].srcorigns[0].tid);
          let retunList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].destorigins[0].tid);
          let bagAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
          let bagAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "ReturnAJLuggageOptions");
          let mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
          let mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");
          let bagOnwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions"));
          let bagRetunList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions" || bag.nm === "ReturnLuggageOptions"));
          let mealOnward  = undefined
          let mealReturn = undefined
          if(props.fareresp.data[0].mand.filter(s=>s.sup == 4)) {
            if(onwardList.length > 0) {
            mealOnward = onwardList[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            if(mealOnward && mealOnward.length > 0) {
              dispatch({type:"owmealTid",payload:onwardList[0].tid})
            }
          }
          if(retunList.length > 0) {
            mealReturn = retunList[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            if(mealReturn && mealReturn.length > 0) {
              dispatch({type:"rtmealTid",payload:retunList[0].tid})
            }
          }
          } else {
            if(props.fareresp.data[0].mand && props.fareresp.data[0].mand.length > 0) {
             mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
             mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "ReturnMealOptions"));
          }
        }

          if(bagAJonwardList !== undefined&& bagAJonwardList[0] !== undefined && bagAJonwardList[0].optins !== undefined) {
            dispatch({ type: 'baggageOptions', payload: bagAJonwardList[0].optins })
            dispatch({ type: 'bOwname', payload: bagAJonwardList[0].nm })
            dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
            dispatch({type:"onwardAJbaggage",payload:true})
          }
          if(bagAJreturnList !== undefined&& bagAJreturnList[0] !== undefined && bagAJreturnList[0].optins !== undefined) {
            dispatch({ type: 'baggageReturnOptions', payload: bagAJreturnList[0].optins })
            dispatch({ type: 'bRtname', payload: bagAJreturnList[0].nm })
            dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
            dispatch({type:"returnAJbaggage",payload:true})
          }
          if(mealAJonwardList !== undefined&& mealAJonwardList[0] !== undefined && mealAJonwardList[0].optins !== undefined) {
            dispatch({ type: 'mealOptions', payload: mealAJonwardList })
            dispatch({type:"alJazeeraBooking",payload:true})
            dispatch({type:"owmealTid",payload:mealAJonwardList[0].tid})
          }
          if(mealAJreturnList !== undefined&& mealAJreturnList[0] !== undefined && mealAJreturnList[0].optins !== undefined) {
            
            dispatch({ type: 'mealReturnOptions', payload: mealAJreturnList })
            dispatch({type:"alJazeeraBooking",payload:true})
            dispatch({type:"rtmealTid",payload:mealAJreturnList[0].tid})
          }
          if (bagOnwardList !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
            dispatch({ type: 'baggageOptions', payload: bagOnwardList[0].optins })
            dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
            dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
          }
          if (bagRetunList !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
            dispatch({ type: 'baggageReturnOptions', payload: bagRetunList[0].optins })
            dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
            dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
          }
          if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
            dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
          }
          if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
            
            dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
          }
        }
      } else {
         //********************************************AL JAZEERA FLOW HANDLING *********************************************/
        if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7) {
          dispatch({type:"alJazeeraBooking",payload:true})
          if(props.srequest.sty === "normal") {
            if (props.srequest.tt === "1") {
              //one way
            if(props.fareresp.data[0].sup == 3){//For Jazzara
              let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
              if (bagList.length !== 0 && bagList[0] !== undefined) {
                dispatch({ type: 'bOwname', payload: bagList[0].nm})
                dispatch({ type: 'baggageOptions', payload: bagList[0].optins })
                }
            }else{//For Indigo
              let bagList = props.fareresp.data[0].mand[0].bag.filter((bag) => (bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions") )
              if(bagList.length!==0 && bagList[0]!==undefined){
                dispatch({ type: 'bOwname', payload: bagList[0].nm })
                dispatch({ type: 'baggageOptions', payload: bagList[0].optins })
              }
            }
            let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS");
            if (mealList.length !== 0 && mealList[0] !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealList })
            }
            } else {
              if(props.fareresp.data[0].sup == 3){//For Jazzara
                let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
                let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnAJLuggageOptions");
                if (bagOnwardList[0] !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
                  dispatch({ type: 'baggageOptions', payload: bagOnwardList[0].optins })
                  dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
                }
                if (bagRetunList[0] !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
                  dispatch({ type: 'baggageReturnOptions', payload: bagRetunList[0].optins })
                  dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
                }
              }else{//For Indigo
                let bagOnwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardLuggageOptions");
                let bagRetunList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnLuggageOptions");
                if (bagOnwardList.length!==0 && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
                  dispatch({ type: 'baggageOptions', payload: bagOnwardList[0].optins })
                  dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
                }
                if (bagRetunList.length!==0 && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined){
                  dispatch({ type: 'baggageReturnOptions', payload: bagRetunList[0].optins })
                  dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
                }
                if (bagOnwardList.length === 0 && bagRetunList.length === 0) {
                  let onwardList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "LuggageOptions");
                  if(onwardList.length!==0){
                    dispatch({ type: 'baggageOptions', payload: onwardList[0].optins })
                    dispatch({ type: 'bOwname', payload: onwardList[0].nm })
                    dispatch({ type: 'enableBagOption', payload: true })
                  }
                }
              }
              let mealList = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
              let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "MEALOPTIONS"&& bag.jtype == "R");
              if (mealList.length !== 0 && mealList[0] !== undefined) {
                dispatch({ type: 'mealOptions', payload: mealList })
              }
              if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
                
                dispatch({ type: 'mealReturnOptions', payload: mealReturn })
              }
            }
          } else {
            let onwardList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].srcorigns[0].tid);
            let retunList = props.fareresp.data[0].mand.filter((type) => type.tid === props.srequest.origns[0].destorigins[0].tid);
            let bagAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
            let bagAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "OutwardAJLuggageOptions");
            let mealAJonwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "O");
            let mealAJreturnList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "MEALOPTIONS" && bag.jtype == "R");
            let bagOnwardList = onwardList[0] !== undefined && onwardList[0].bag.length !== 0 && onwardList[0].bag.filter((bag) => bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions");
            let bagRetunList = retunList[0] !== undefined && retunList[0].bag.length !== 0 && retunList[0].bag.filter((bag) => bag.nm === "LuggageOptions" || bag.nm === "OutwardLuggageOptions");
            let mealOnward = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "OutwardMealOptions");
            let mealReturn = props.fareresp.data[0].mand[0].bag.filter((bag) => bag.nm === "ReturnMealOptions");
            if(bagAJonwardList !== undefined&& bagAJonwardList[0] !== undefined && bagAJonwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload: bagAJonwardList[0].optins })
              dispatch({ type: 'bOwname', payload: bagAJonwardList[0].nm })
              dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
              dispatch({type:"onwardAJbaggage",payload:true})
            }
            if(bagAJreturnList !== undefined&& bagAJreturnList[0] !== undefined && bagAJreturnList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload: bagAJreturnList[0].optins })
              dispatch({ type: 'bRtname', payload: bagAJreturnList[0].nm })
              dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
              dispatch({type:"returnAJbaggage",payload:true})
            }
            if(mealAJonwardList !== undefined&& mealAJonwardList[0] !== undefined && mealAJonwardList[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealAJonwardList })
              dispatch({type:"alJazeeraBooking",payload:true})
              dispatch({type:"owmealTid",payload:mealAJonwardList[0].tid})
            }
            if(mealAJreturnList !== undefined&& mealAJreturnList[0] !== undefined && mealAJreturnList[0].optins !== undefined) {
              
              dispatch({ type: 'mealReturnOptions', payload: mealAJreturnList })
              dispatch({type:"alJazeeraBooking",payload:true})
              dispatch({type:"rtmealTid",payload:mealAJreturnList[0].tid})
            }
            if (bagOnwardList !== undefined && bagOnwardList[0] !== undefined && bagOnwardList[0].optins !== undefined) {
              dispatch({ type: 'baggageOptions', payload: bagOnwardList[0].optins })
              dispatch({ type: 'bOwname', payload: bagOnwardList[0].nm })
              dispatch({ type: 'owbagTid', payload: onwardList[0].tid })
            }
            if (bagRetunList !== undefined && bagRetunList[0] !== undefined && bagRetunList[0].optins !== undefined) {
              dispatch({ type: 'baggageReturnOptions', payload: bagRetunList[0].optins })
              dispatch({ type: 'bRtname', payload: bagRetunList[0].nm })
              dispatch({ type: 'rtBagTid', payload: retunList[0].tid })
            }
            if (mealOnward !== undefined && mealOnward[0] !== undefined && mealOnward[0].optins !== undefined) {
              dispatch({ type: 'mealOptions', payload: mealOnward[0].optins })
            }
            if (mealReturn !== undefined && mealReturn[0] !== undefined && mealReturn[0].optins !== undefined) {
  
              dispatch({ type: 'mealReturnOptions', payload: mealReturn[0].optins })
            }
          }
        }
      }
      }
    } else {
      if(props.srequest.doc !== undefined && props.srequest.doc!==""){
        dispatch({ type: 'docMandatory', payload: props.srequest.doc})
      }
    }

    if(props.passengerUniqueKey && props.passengerUniqueKey.length > 0) {
      state.passengerUniqueKey = props.passengerUniqueKey;
      dispatch({type:"passengerUniqueKey",payload:state.passengerUniqueKey})
    }

    createPaxList();
    let airlinesList = []
    if (props.srequest.tt === "1") {
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns[0] !== undefined) {
        let airlines = props.srequest.origns[0].srcorigns[0].seg.map(q => q.ac);
        let airs = airlines.filter((q, idx) => airlines.indexOf(q) === idx)
        if (airs) {
          for (let value of airs) {
            if (airlinesInfo.filter((obj) => obj.code === value)[0]) {
              var name = airlinesInfo.filter((obj) => obj.code === value)[0].english;
              airlinesList.push({ label: name, value: value });
            }
          }
        }
      }
      const airliens = airlinesList.map((air) =>
        <option value={air.value}>{air.label}</option>
      );
      dispatch({ type: 'airlines', payload: airliens })
    } else if (props.srequest.tt === "2") {
      let finailAirlines = [];
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns[0] !== undefined
        && props.srequest.origns[0] !== undefined && props.srequest.origns[0].destorigins[0] !== undefined) {
        let onairlines = props.srequest.origns[0].srcorigns[0].seg.map(q => q.ac);
        let onairs = onairlines.filter((q, idx) => onairlines.indexOf(q) === idx)
        finailAirlines.push(onairs);
        let reairlines = props.srequest.origns[0].destorigins[0].seg.map(q => q.ac);
        let reairs = reairlines.filter((q, idx) => reairlines.indexOf(q) === idx)
        finailAirlines.push(reairs);
        let finalList = finailAirlines.map(q => q[0]);
        let airsList = finalList.filter((q, idx) => finalList.indexOf(q) === idx)
        let airlineList = [];
        if (finalList) {
          for (let val of finalList) {
            if (airlinesInfo.filter((obj) => obj.code === val)[0]) {
              var name = airlinesInfo.filter((obj) => obj.code === val)[0].english;
              airlineList.push({ label: name, value: val });
            }
          }
        }
        const airliens = airlineList.map((air) =>
          <option value={air.value}>{air.label}</option>
        );
        dispatch({ type: 'airlines', payload: airliens })
      }
    } else if (props.srequest.tt === 3) {
      if (props.srequest.origns[0] !== undefined && props.srequest.origns[0].srcorigns !== undefined) {
        for (let value of props.srequest.origns[0].srcorigns) {
          let airlines = value.seg.map(q => q.ac);
          let airs = airlines.filter((q, idx) => airlines.indexOf(q) === idx)
          if (airs) {
            for (let value of airs) {
              if (airlinesInfo.filter((obj) => obj.code === value)[0]) {
                var name = airlinesInfo.filter((obj) => obj.code === value)[0].english;
                airlinesList.push({ label: name, value: value });
              }
            }
          }
        }
      }
      const airliens = airlinesList.map((air) =>
        <option value={air.value}>{air.label}</option>
      );
      dispatch({ type: 'airlines', payload: airliens })
    }
    //const airlinesList = [{ label: "Gulf", value: "GF" }, { label: "Air India", value: "AI" }]


    countriesWitCode().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
    });
    if (props.bookType !== undefined && (props.bookType === "ONHOLD REBOOK" || props.bookType === "INCOMPLETE")) {
      dispatch({ type: 'enableImport', payload: false })
      dispatch({ type: 'flowType', payload: props.bookType })
      dispatch({ type: 'passengers', payload: props.paxList })
      dispatch({ type: 'selTraveller', payload: props.paxList[0] })
    } else {
      dispatch({ type: 'enableImport', payload: false })
      if(props.bookType === "IMPORT"){
        dispatch({ type: 'enableFields', payload: false })
      }
      if (props.paxList !== undefined && props.paxList[0] !== undefined) {
        dispatch({ type: 'passengers', payload: props.paxList })
        dispatch({ type: 'selTraveller', payload: props.paxList[0] })
      }
    }

  }, [!props.importFlow]);

useEffect(() => {
if(props.enableFlynas){
  if (props.enableFlynas){
    dispatch({ type: 'docMandatory', payload: "yes"})
  }
}
},[props.enableFlynas])

  useEffect(() => {
    checkTravellerList();
  }, [props.travellersList.res]);

  useEffect(() => {
    let profilearray = props.prof;
    if (state.cheked == true && state.passengers.length > 0) {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      state.passengers[objindex].fn = profilearray.fn
      state.passengers[objindex].ln = profilearray.ln
      state.passengers[objindex].tl = profilearray.tl
      state.passengers[objindex].phc = profilearray.phc
      state.passengers[objindex].em = profilearray.em
      state.passengers[objindex].phn = profilearray.phn
      dispatch({ type: "passengers", payload: state.passengers })
    }
  }, [props.prof]);
  useEffect(()=>{

  },[props])

  useEffect(() => {
    if (props.importFlow && props.paxList && props.paxList.length != 0) {
      for (let value of props.paxList) {
          if(isNotNull(value.dob)){
            value.disablePax=true;
           }else{
            value.disablePax=false;
           }
           if(isNotNull(value.inf)){
           if(isNotNull(value.inf.dob)){
            value.inf.disablePax=true;
           }else{
            value.inf.disablePax=false;
           }
          }
        if (value.doc !== undefined && value.doc.ty !== undefined) {
          dispatch({ type: 'passportEnable', payload: true })
        }
      }
      dispatch({ type: 'enableImport', payload: true })
      if (props.bookType === "ONHOLD") {
        dispatch({ type: 'enbalePPInfo', payload: false })
      }
      if(props.bookType === "IMPORT"){
        dispatch({ type: 'enableFields', payload: false })
        if(props.srequest.doc !== undefined && props.srequest.doc!==""){
          dispatch({ type: 'docMandatory', payload: props.srequest.doc})
        }
      }else{
        if(props.bookType === "REISSUE" && props.document!==undefined && props.document!==""){
          dispatch({ type: 'docMandatory', payload: props.document})
        }
      }
      dispatch({ type: 'flowType', payload: props.bookType })
      dispatch({ type: 'passengers', payload: props.paxList })
      dispatch({ type: 'selTraveller', payload: props.paxList[0] })
    }
  }, [props.importFlow]);


  //=====================================
  // This function will create the ADT,CHD and INF List travellers beased on selected Profile.
  //=====================================
  const checkTravellerList = () => {
    let adultsList = []; let childsList = []; let infantsList = [];
    if (props.travellersList.res) {
      for (let p1 of props.travellersList.res) {
        if (p1.ty === "ADT" && adts > 0) {
          adultsList.push(p1);
        } else if (p1.ty === "CHD" && chds > 0) {
          childsList.push(p1);
        } else if (p1.ty === "INF" && infs > 0) {
          infantsList.push(p1);
        }
      }
      dispatch({ type: 'adtList', payload: adultsList })
      dispatch({ type: 'chdList', payload: childsList })
      dispatch({ type: 'infList', payload: infantsList })
    }
  }



  const paxDate = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() - n));
  }
  const childMin = (dt, n)=>{
    return new Date(dt.setFullYear(dt.getFullYear() - n)).setDate(new Date().getDate()+1);
}

  //=====================================
  // This function will create the Pax List based on selection
  // which will update the state
  //=====================================
  const createPaxList = () => {
    let paxList = [];
    var adtcount = 1;
    var infCount = infs;
    if (adts) {
      for (var i = 0; i < adts; i++) {
        let passKey = []
        if(state.passengerUniqueKey.length > 0) {
          passKey = state.passengerUniqueKey.filter(s => s.type == "ADT")
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : ""
        const payload = createPaxPayload("ADT", adtcount, "Adult", infCount, adtcount,uniqueKey);
        paxList.push(payload);
        adtcount = adtcount + 1;
        infCount = infCount - 1;
      }
    }
    if (chds && chds > 0) {
      var chdcount = adtcount;
      var fesno = 1
      for (var i = 0; i < chds; i++) {
        let passKey = []
        if(state.passengerUniqueKey.length > 0) {
          passKey = state.passengerUniqueKey.filter(s => s.type == "CHD")
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : ""
        const payload = createPaxPayload("CHD", chdcount, "Child", 0, fesno,uniqueKey);
        paxList.push(payload);
        chdcount = chdcount + 1;
        fesno = fesno + 1
      }
    }
    dispatch({ type: 'passengers', payload: paxList })
  }
  //=====================================
  // This is common function to create passengers
  //=====================================
  const createPaxPayload = (type, count, lable, infs, sno,pUkey) => {

    var lead = false; var docPayload = ""; var prefPayload = ""; var ffPayload = ""; var infPayload = "";
    (type === "ADT" && count === 1) ? lead = true : lead = false;
    docPayload = { ty: "P", num: "", na: "", isc: '', isd: null, exd: null };
    dispatch({ type: 'passportEnable', payload: true })

    if (type !== "INF") {
      prefPayload = { sa: "", sp: "", mp: "" };
      ffPayload = { ffnum: "", air: "" };
    }
    if (infs > 0) {
      infPayload = { sno: count, tl: "", ty: "INF", plbl: "Infant", fn: "", ln: "", dob: null, "doc": docPayload }
    }
    return {
      sno: count,passengerKey:pUkey ? pUkey : "", fesno: sno, tl: "", ty: type, plbl: lable, lead: lead, fn: "", ln: "", em: "", phc: "", phn: "", dob: null, "inf": infPayload, "doc": docPayload, "opref": prefPayload, "ffinfo": ffPayload,"baggage": [],"meals": [],"seats": []
    }
  }
  //=====================================
  // This function to enable the FFN Info
  //=====================================
  const showfrequentFlyerNumber = () => {
    if (frequentFlyerNumber) {
      setFrequentFlyerNumber(false)
    } else {
      setFrequentFlyerNumber(true)
    }
    setOtherPreference(false);
  }

  //=====================================
  // This function to enable the Other Pref Info
  //=====================================

  const showOtherPreference = () => {
    if (otherPreference) {
      setOtherPreference(false)
    } else {
      setOtherPreference(true)
    }
    setFrequentFlyerNumber(false);
  }


  // OnChange event we will call this and update the state
  const handleInfChange = (idx) => (e) => {
    try {
      if (e[0] === undefined) {
        const { id, value } = e.target;
        let newpax = state.passengers.map((pax, sidx) => {
          if (idx !== sidx) return pax;
          else if (id !== "num" && id !== "na" && id !== "isc") {
            return {
              ...pax, inf: {
                ...pax.inf, [id]: value, limitError: handleLimitError(value, id, pax.inf)
              }
            }
          } else {
            return {
              ...pax,
              inf: {
                ...pax.inf, doc: {
                  ...pax.inf.doc, [id]: value
                }, limitError: handleLimitError(value, id, pax.inf)
              }
            }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax)
      } else {
        let newpax = state.passengers.map((pax, sidx) => {
          if (idx !== sidx) return pax;
          else if (e[0].inf !== undefined && e[0].inf.doc !== undefined) {
            return {
              ...pax,
              inf: { tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, limitError: handleLimitError(e[0].fn, "fn", e[0]), dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : null }
            }
          } else {
            return {
              ...pax,
              inf: {
                ...pax.inf, tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, dob: e[0].dob && e[0].dob !== null ? DateUtils.convertStringToDate(e[0].dob) : null, limitError: handleLimitError(e[0].fn, "fn", e[0]),
                doc: {
                  ...pax.inf.doc, num: e[0].doc.num, na: e[0].doc.na, ty: "P", isc: e[0].doc.isc, isd: e[0].doc.isd !== null ? DateUtils.convertStringToDate(e[0].doc.isd) : null, exd: e[0].doc.exd !== null ? DateUtils.convertStringToDate(e[0].doc.exd) : null
                }
              }
            }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);
      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }
  const showProfile = () => {
    dispatch({type: "cheked", payload: !state.cheked})
    let check = document.getElementById("pax").checked;
    let profilearray = props.prof;
    if (check == true && state.passengers.length > 0 ) {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      if(props.fname && props.fname!==""){
        state.passengers[objindex].fn = props.fname===""?profilearray.fn:props.fname;
      }
      state.passengers[objindex].ln = props.lname===""?profilearray.ln:props.lname;
      state.passengers[objindex].tl = props.title===""?profilearray.tl:props.title;
      state.passengers[objindex].phc =props.code===""?profilearray.phc:props.code ;
      state.passengers[objindex].em = props.email===""?profilearray.em:props.email;
      state.passengers[objindex].phn = props.phone===""?profilearray.phn:props.phone;
      dispatch({ type: "passengers", payload: state.passengers })
    }
    else {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      state.passengers[objindex].fn = ""
      state.passengers[objindex].ln = ""
      state.passengers[objindex].tl = ""
      state.passengers[objindex].phc = ""
      state.passengers[objindex].em = ""
      state.passengers[objindex].phn = ""
      dispatch({ type: "passengers", payload: state.passengers })
    }
  }
  const handleLimitError = (data, type, pax)=>{
        if(type == "fn" ){
          if((data.length + pax.ln.length) > 29){
          if(data.length > pax.ln.length){
            return {
              showFirst: true,
              showLast: false
            }
          }
          else{
            return {
              showFirst: false,
              showLast: true
            }
          }
        }
        else{
          return {
            showFirst: false,
            showLast: false
          }
        }
        }
        else if(type == "ln" ){
          if((data.length + pax.fn.length) > 29){
         if(data.length > pax.fn.length ){
          return {
            showFirst: false,
            showLast: true
          }
        }
        else{
          return {
            showFirst: true,
            showLast: false
          }
        }
      }
      else{
        return {
          showFirst: false,
          showLast: false
        }
      }
      }
      else{
        return {
          showFirst: false,
          showLast: false
        }
      }
    }



  const handleOnInpChange = (txt, id, idx, type) => {
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else if (id !== "num" && id !== "na" && id !== "isc") {
        if (type !== "INF") {
          return { ...pax, [id]: txt, limitError: handleLimitError(txt, id, pax)};
        } else {
          return {
            ...pax, inf: {
              ...pax.inf, [id]: txt, limitError: handleLimitError(txt, id, pax.inf)
            }
          }
        }
      } else {
        return {
          ...pax, doc: {
            ...pax.doc, [id]: txt
          },
          limitError: handleLimitError(txt, id, pax)
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax);
  }

  // OnChange event we will call this and update the state
  const handleChange = (idx) => (e) => {
    try {
      if (e[0] === undefined) {
        let newpax = state.passengers.map((pax, sidx) => {
          const { id, value } = e.target;
          if (idx !== sidx) return pax;
          else if (id !== "num" && id !== "na" && id !== "isc") {
            return { ...pax, [id]: value, limitError: handleLimitError(value, id, pax)};
          } else {
            return {
              ...pax, doc: {
                ...pax.doc, [id]: value
              }, limitError: handleLimitError(value, id, pax)
            }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);
      } else {
        let newpax = state.passengers.map((pax, sidx) => {
          if (idx !== sidx) return pax;
          else if (e[0].doc !== undefined) {
            return {
              ...pax, tl: e[0].tl, fn: e[0].fn, limitError: handleLimitError(e[0].fn, "fn", e[0]), ln: e[0].ln, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", destnum: e[0].destnum,
              doc: {
                ...pax.doc, num: e[0].doc.num, na: e[0].doc.na, num: e[0].doc.num, ty: "P", isc: e[0].doc.isc, isd: e[0].doc.isd !== null ? DateUtils.convertStringToDate(e[0].doc.isd) : null, exd: e[0].doc.exd !== null ? DateUtils.convertStringToDate(e[0].doc.exd) : null
              }
            }
          } else {
            return { ...pax, tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", destnum: e[0].destnum, limitError: handleLimitError(e[0].fn, "fn", e[0]) }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);

      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }
  // OnChange event we will call this and update the state
  const handlePrefsChange = idx => evt => {
    try {
      const { id, value } = evt.target;
      let newpax = state.passengers.map((pax, sidx) => {
        if (idx !== sidx) return pax;
        else if (id === "ffnum" || id === "air") {
          return {
            ...pax, ffinfo: {
              ...pax.ffinfo, [id]: value
            }
          }
        } else {
          return {
            ...pax, opref: {
              ...pax.opref, [id]: value
            }
          }
        }
      });
      dispatch({ type: 'passengers', payload: newpax })
      props.sendPsngersData(newpax)
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }
  // OnChange event we will call this and update the state
  const handleDobChange = idx => date => {
    const convertDate = (date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "");
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, dob: convertDate, dobOrg: date !== null ? date : null, limitError: handleLimitError(convertDate, "dob", pax), doc: {
          ...pax.doc, isd: null, exd: null
        }
      };
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleInfDobChange = idx => date => {
    const convertDate = (date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "");
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, inf: {
          ...pax.inf, dob: convertDate, dobOrg: (date !== null ? date : null), limitError: handleLimitError(convertDate, "dob", pax), doc: {
            ...pax.inf.doc, isd: null, exd: null
          }
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleInfIsDateChange = idx => date => {
    const convertDate = date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "";
    setIssueDate2(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax,
        inf: {
          ...pax.inf, doc: {
            ...pax.inf.doc, isd: convertDate
          }, limitError: handleLimitError(date, "isd", pax.inf)
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleInfExDateChange = idx => date => {
    const convertDate = date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "";
    setExpDate2(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax,
        inf: {
          ...pax.inf, doc: {
            ...pax.inf.doc, exd: convertDate
          },
          limitError: handleLimitError(date, "exd", pax.inf)
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleIssuDateChange = idx => date => {
    const convertDate = date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "";
    setIssueDate1(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, doc: {
          ...pax.doc, isd: convertDate
        }, limitError: handleLimitError(date,"isd" ,pax)
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleExpDateChange = idx => date => {
    const convertDate = date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "";
    setExpDate1(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, doc: {
          ...pax.doc, exd: convertDate
        }, limitError: handleLimitError(date,"exd" ,pax)
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }

  //Creating Selected Baggage List for Normal and Spli flow
  const updateBagprice = (price, no, idx, flow) => (obj) => {
    props.selBagprice([obj, price, no, flow]);
    let baggage = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = createBaggageReq(obj, state.bOwname, props.srequest.sty === "normal" ? props.fareresp.data[0].mand[0].tid : state.owbagTid);
          if (pax.baggage === undefined) {
            baggage.push(onward);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.nm != onward.nm; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.nm === onward.nm && el.tid !== onward.tid; });
            }
            baggage.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: baggage };
          }
        } else {
          const retun = createBaggageReq(obj, state.bRtname, props.srequest.sty === "normal" ? props.fareresp.data[0].mand[0].tid : state.rtBagTid);
          if (pax.baggage === undefined) {
            baggage.push(retun);
            return { ...pax, baggage: baggage };
          } else {
            var filtered = [];
            if (props.srequest.sty === "normal") {
              filtered = pax.baggage.filter(function (el) { return el.nm != retun.nm; });
            } else {
              filtered = pax.baggage.filter(function (el) { return el.nm === retun.nm && el.tid !== retun.tid; });
            }
            baggage.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              baggage.push(filtered[0])
            }
            return { ...pax, baggage: baggage };
          }
        }
      }
    });
    state.passengers=newpax
    //dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax);
  }

  //This is common for Baggage and Meal
  const createBaggageReq = (obj, name, tid,route) => {
    const payload = {
      "tid": tid,
      "nm": name,
      "val": obj.value,
      "label": obj.label,
      "price": obj.price,
      "sup_price": obj.sup_price,
      "sup_cur_price": obj.sup_cur_price,
      "mkp": obj.mkp
    }
    if(route !== undefined) {
      payload.route = route
    }
    if(obj.ssrKey) {
      payload.ssrKey = obj.ssrKey
    }
    if(obj.hashcode) {
      payload.hashcode = obj.hashcode
      payload.routeIds = obj.routeIds
      payload.code = obj.code
      payload.offerId = obj.offerId
    }
    return payload;
  }

  //Creating Selected Baggage List for Normal and Spli flow
  const updateMealprice = (price, no, idx, flow,route) => (obj) => {
    props.selMealPrice([obj, price, no, flow,route]);
    let meals = [];
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else {
        if (flow === "O") {
          const onward = createBaggageReq(obj, "OutwardMealOptions", state.owmealTid ? state.owmealTid : props.fareresp.data[0].mand[0].tid,route);
          if (pax.meals === undefined) {
            meals.push(onward);
            return { ...pax, meals: meals };
          } else {
            var filtered = pax.meals.filter(function (el) { return  el.route && onward.route ? el.route != onward.route : el.nm != onward.nm; });
            meals.push(onward);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7){
              meals=createMealsObj(pax,onward);
            }

            return { ...pax, meals: meals };
          }
        } else {
          const retun = createBaggageReq(obj, "ReturnMealOptions",  state.rtmealTid ? state.rtmealTid : props.fareresp.data[0].mand[0].tid,route);
          if (pax.meals === undefined) {
            meals.push(retun);
            return { ...pax, meals: meals };
          } else {
            var filtered = pax.meals.filter(function (el) { return  el.route && retun.route ? el.route != retun.route : el.nm != retun.nm; });
            meals.push(retun);
            if (filtered.length !== 0 && filtered[0] !== undefined) {
              meals.push(filtered[0])
            }
            if(props.fareresp.data[0].sup == 3 || props.fareresp.data[0].sup == 4 || props.fareresp.data[0].sup == 7){
              meals=createMealsObj(pax,retun)
            }
            return { ...pax, meals: meals };
          }
        }
      }
    });
    state.passengers=newpax
    props.sendPsngersData(newpax);
  }

  const createMealsObj=(pax,obj)=>{
    let matched=false;
    let meals=pax.meals;
   for(let val of meals){
      if(val.route===obj.route){
        val.label=obj.label;
        val.price=obj.price;
        val.tid=obj.tid;
        val.sup_price=obj.sup_price;
        val.sup_cur_price=obj.sup_cur_price;
        val.val=obj.val;
        if(obj.ssrKey) {
          val.ssrKey = obj.ssrKey
        }
        if(obj.hashcode) {
          val.hashcode = obj.hashcode 
          val.routeIds = obj.routeIds 
          val.code = obj.code 
          val.offerId = obj.offerId
        }
        matched=true;
        break;
      }
   }
   if(!matched){
    meals=[];
    meals=pax.meals;
    meals.push(obj);
   }
   return meals;
  }

  /**
   * @author : Azamuddin
   * @description : this function return for the passengerkey required data
   * @date : 21-sept-2021
   */
  const getEachKeySSR = (data,key) => {
    let respected_data = [];
    for(let eachOptin of data) {
        for(let eacPax of eachOptin) {
          if(eacPax.paxKey && key!=="" && eacPax.paxKey == key) {
            respected_data = eachOptin
            break;
          }
        }
    }
    return respected_data
  }
  const returnTitle = (data,type) => {

  }
  useEffect(() => {
    if(props.deptDate) {
      let limit_year = moment(props.deptDate).subtract(2, 'years');
      let cops_date = new Date(limit_year)
      state.deptDate = cops_date
      dispatch({type:"deptDate",payload:state.deptDate})
    }
    if(props.deptDate) {
      let climit_year = moment(props.deptDate).subtract(12, 'years');
      let ccops_date = new Date(climit_year)
      state.childDeptDate = ccops_date
      dispatch({type:"childDeptDate",payload:state.childDeptDate})
    }
  },[props.deptDate])
  return (
    <>
      <div className="boxLayout">
        <ShowHide visible="true" icon="person" title="Travellers Details">
          <div className="showHide-content p-0">
          {((props.prof !== undefined && props.prof !== null && props.prof.length !== 0 )||
          props.email!==""||props.fname!==""||props.lname!==""||props.phone!== ""
          )&&
            <div className="allPax flightpax"><Form.Check

            type="checkbox"
                onChange={showProfile}
                disabled={state.enableImport}
                id="pax"
                label="Use Profile Details as Lead Pax"
                custom
              />
            </div>
          }

            <Alert variant="info mt-3">Note: Please ensure the name you enter is matching to your passport </Alert>

            {state.passengers.length > 0 &&
              <div className="travellerList">
                {state.passengers.map((pasenger, idx) => (
                  <>
                    <ShowHide visible={pasenger.lead ? "true" : ""} title={pasenger.plbl + " " + pasenger.fesno + " " + (pasenger.lead ? "(Lead)" : "")}>
                      <div className="showHide-content pt-4">
                        <Form.Row>
                          <React.Fragment key={idx}>
                            <Form.Group as={Col} xs={3} controlId="tl" className="customSelect">
                              {pasenger.ty.toUpperCase() === "ADT" &&
                                <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.tl} onChange={handleChange(idx)}>
                                  {state.titleList}
                                </Form.Control>
                              }
                              {pasenger.ty.toUpperCase() === "CHD" &&
                                <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.tl} onChange={handleChange(idx)}>
                                  {state.title}
                                </Form.Control>
                              }
                              <Form.Label>Title<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} controlId="fn" className="selectplugin" xs={3}>
                              {pasenger.ty.toUpperCase() === "ADT" &&
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="fn"
                                  onChange={handleChange(idx)}
                                  onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, '', "ADT")}
                                  options={state.adtList}
                                  placeholder="Choose Country.."
                                  selected={[pasenger]}
                                  disabled={state.enableImport || props.disable}
                                />
                              }
                              {pasenger.ty.toUpperCase() === "CHD" &&
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="fn"
                                  onChange={handleChange(idx)}
                                  onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, "CHD")}
                                  options={state.chdList}
                                  placeholder="Choose Country.."
                                  selected={[pasenger]}
                                  disabled={state.enableImport || props.disable}
                                />
                              }
                              <Form.Label>First Name{(props.onwardSupp !== "" && props.onwardSupp !== "0") && <sup>*</sup>}</Form.Label>
                              {pasenger.limitError!== undefined && pasenger.limitError.showFirst == true && <p className="nameToolong"><span className="longnameError ezyIcon icon-warning">Name is too long, Ticket may get failed</span></p>}
                            </Form.Group>
                             <Form.Group as={Col} xs={3} controlId="ln">
                              <Form.Control type="text" disabled={state.enableImport || props.disable} placeholder="Last Name" value={pasenger.ln} onChange={handleChange(idx)} />
                              <Form.Label>Last Name<sup>*</sup></Form.Label>
                              {pasenger.limitError !== undefined && pasenger.limitError.showLast == true && <p className="nameToolong"><span className="longnameError ezyIcon icon-warning">Name is too long, Ticket may get failed</span></p>}
                            </Form.Group>
                           <Form.Group as={Col} xs="3" className="selectplugin datePicker yearlist" controlId="dob">
                              {pasenger.ty === "ADT" &&
                                <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  //value={pasenger.dob}
                                  onCalendarOpen ={handleDobChange(idx)}
                                  onChange={handleDobChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  maxDate={paxDate(new Date(), 12).setDate(new Date().getDate()-1)}
                                  minDate={new Date(1900,1,1) }
                                  preventOpenOnFocus={true}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.disable || (state.enableImport && pasenger.disablePax!==undefined && pasenger.disablePax)}
                                />
                              }
                              {pasenger.ty === "CHD" &&
                                <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  // value={pasenger.dob}
                                  onCalendarOpen ={handleDobChange(idx)}
                                  onChange={handleDobChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  maxDate={paxDate(new Date(),2).setDate(new Date().getDate()-1)}
                                  minDate={childMin(new Date(),12)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.disable || (state.enableImport && pasenger.disablePax!==undefined && pasenger.disablePax)}
                                />
                              }
                              {pasenger.ty === "ADT" &&
                                <Form.Label>Date of Birth{state.docMandatory === "yes" || (props.onwardSupp==="3" || props.returnSupp==="3"|| props.onwardSupp==="4" || props.returnSupp==="4" || props.onwardSupp==="5" || props.returnSupp==="5" || props.onwardSupp==="6" || props.returnSupp==="6" || props.onwardSupp==="7" || props.returnSupp==="7")? <sup>*</sup> : ""}</Form.Label>
                              }
                              {pasenger.ty === "CHD" &&
                                <Form.Label>Date of Birth<sup>*</sup></Form.Label>
                              }
                              <span className="calendar ezyIcon icon-newcalendar"></span>

                            </Form.Group>
                            {pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="em">
                                <Form.Control type="text" disabled={state.enableFields && (state.enableImport || props.disable)} placeholder="Email" value={pasenger.em} onChange={handleChange(idx)} />
                                <Form.Label>Email<sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            {props.bookType!==undefined && props.bookType!=="IMPORT" &&   pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="phc" className="customSelect">
                                <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.phc} onChange={handleChange(idx)}>
                                  {state.stdCodeMenu}
                                </Form.Control>
                                <Form.Label>Phone code <sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            { pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="phn">
                                <Form.Control type="text" disabled={state.enableFields && (state.enableImport || props.disable)} placeholder="Phone Number" value={pasenger.phn} onChange={handleChange(idx)} />
                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            {pasenger.lead && <Form.Group as={Col} xs={3} controlId="destnum">
                              <Form.Control type="text" placeholder="Destination contact number" value={pasenger.destnum} onChange={handleChange(idx)} disabled={props.disabledcn ? true : false} />
                              <Form.Label>Destination Contact number</Form.Label>
                            </Form.Group>
                            }
                          </React.Fragment>
                        </Form.Row>
                        {state.passportEnable &&
                          <div>
                            <h6 className="subTitle">Passport Info</h6>
                            <Form.Row>
                              <Form.Group as={Col} xs={3} controlId="num">
                                <Form.Control type="text"
                                  disabled={state.enbalePPInfo || props.disable || props.srequest.flowType !== undefined && props.srequest.flowType === "IMPORT" &&
                                   props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc && props.srequest.psngrs[idx].doc.num && props.srequest.psngrs[idx].doc.num.length > 0}
                                  placeholder="Passport Name" value={pasenger.doc.num}
                                  onChange={handleChange(idx)} />
                                <Form.Label>Passport Number {state.docMandatory === "yes" || props.indPassportEnable ? <sup>*</sup> : ""} </Form.Label>
                              </Form.Group>
                              <Form.Group as={Col} controlId="na" className="customSelect">
                                <Form.Control as="select" disabled={state.enbalePPInfo || props.disable || props.srequest.flowType !== undefined && props.srequest.flowType === "IMPORT" && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc

                                  && props.srequest.psngrs[idx].doc.na && props.srequest.psngrs[idx].doc.na.length > 0} value={pasenger.doc.na} onChange={handleChange(idx)}>
                                  <option value="">Select</option>
                                  {state.countriesMenu}
                                </Form.Control>
                                <Form.Label>Nationality {state.docMandatory === "yes" || props.checkMandatoryFlagNat ===true ? <sup>*</sup> : ""}</Form.Label>
                              </Form.Group>
                              <Form.Group as={Col} controlId="isc" className="customSelect">
                                <Form.Control as="select" disabled={state.enbalePPInfo || props.disable ||props.srequest.flowType!==undefined && props.srequest.flowType==="IMPORT"
                         && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc

                         && props.srequest.psngrs[idx].doc.isc && props.srequest.psngrs[idx].doc.isc.length>0} value={pasenger.doc.isc} onChange={handleChange(idx)}>

                                  <option value="">Select</option>
                                  {state.countriesMenu}
                                </Form.Control>
                                <Form.Label>Issued Country {state.docMandatory === "yes" ? <sup>*</sup> : ""}</Form.Label>
                              </Form.Group>
                              {pasenger.ty === "ADT" &&<Form.Group as={Col} className="selectplugin datePicker" controlId="isd">
                                <DatePicker
                                  className="form-control"
                                  selected={pasenger.doc.isd !== null ? DateUtils.convertNewToDate(pasenger.doc.isd) : null}
                                  onCalendarOpen ={handleIssuDateChange(idx)}
                                  onChange={handleIssuDateChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  //minDate={pasenger.dob ? pasenger.dobOrg : ""}
                                  maxDate={new Date()}
                                  //minDate={paxDate(new Date(), 12).setDate(new Date().getDate()-1)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={state.enbalePPInfo || props.disable || props.srequest.flowType!==undefined && props.srequest.flowType==="IMPORT"
                                  && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc

                                  && props.srequest.psngrs[idx].doc.isc && props.srequest.psngrs[idx].doc.isc.length>0}
                                />


                                <Form.Label>Issued Date</Form.Label>
                                <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
}
                              {pasenger.ty === "CHD" && <Form.Group as={Col} className="selectplugin datePicker" controlId="isd">
                                <DatePicker
                                  className="form-control"
                                  selected={pasenger.doc.isd !== null ? DateUtils.convertNewToDate(pasenger.doc.isd) : null}
                                  onCalendarOpen ={handleIssuDateChange(idx)}
                                  onChange={handleIssuDateChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  //minDate={pasenger.dob ? pasenger.dobOrg : ""}
                                  maxDate={new Date()}
                                  minDate={state.childDeptDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={state.enbalePPInfo || props.disable || props.srequest.flowType!==undefined && props.srequest.flowType==="IMPORT"
                                  && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc && props.srequest.psngrs[idx].doc.isd && props.srequest.psngrs[idx].doc.isd.length>0} />

                                <Form.Label>Issued Date</Form.Label>
                                <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
}
                              <Form.Group as={Col} className="selectplugin datePicker" controlId="exd">

                                <DatePicker
                                  className="form-control"
                                  selected={pasenger.doc.exd !== null ? DateUtils.convertNewToDate(pasenger.doc.exd) : null}
                                  onCalendarOpen ={handleExpDateChange(idx)}
                                  onChange={handleExpDateChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  minDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={state.enbalePPInfo || props.disable ||props.srequest.flowType!==undefined && props.srequest.flowType==="IMPORT"
                                  && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc && props.srequest.psngrs[idx].doc.exd && props.srequest.psngrs[idx].doc.exd.length>0}                                />

                                <Form.Label>Expiry Date {state.docMandatory==="yes" || props.indPassportEnable?<sup>*</sup>:""}</Form.Label>
                                <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                            </Form.Row>
                          </div>
                        }

                        {!state.enableImport && props.onwardSupp!=="6" && props.returnSupp!=="6" &&
                          <>
                            <hr className="mt-0" />
                            <div className="OtherPreferenceInfo">
                            <Tabs defaultActiveKey="" id="otherInfo">
                           {props.srequest.sty!==undefined && props.srequest.sty==="normal" && props.onwardSupp!=="" && props.onwardSupp!=="0" &&
                           <Tab eventKey="extra" title={<><span className="iconSy">
                             <span className="plus ezyIcon icon-plus"></span>
                             <span className="minus ezyIcon icon-minus"></span>
                             </span>Extras</>}>
                            <div className="extradetails extra eachList">
                              <Tabs defaultActiveKey="bgg" id="extraType" className="extralist">
                              <Tab eventKey="bgg" title={<><span className="ezyIcon icon-tBaggage"></span> Add Baggage </>}>
                               <Tabs defaultActiveKey="dtp" id="roots">
                                  {props.onwardSupp!=="" && props.onwardSupp!=="0" &&  state.baggageOptions.length!==0 &&
                                  <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                  <AddBaggage sendBagprice={updateBagprice(pasenger.ty,pasenger.fesno,idx,"O")} bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageOptions,pasenger.passengerKey) : state.baggageOptions} disable={props.disable}/>
                                  </Tab>
                                  }
                                  {((props.onwardSupp!=="" && props.onwardSupp==="0") || state.baggageOptions.length === 0) &&
                                  <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                    <div className="extrainfolcc">
                                    <span className="extraIcon ezyIcon icon-tBaggage"></span>
                                   <span>Baggage option is not available for this flight</span>
                                    </div>
                                  </Tab>
                                  }
                                  {((props.returnSupp!=="" && props.returnSupp==="0") || state.baggageReturnOptions.length === 0) &&
                                  <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                  <div className="extrainfolcc">
                                    <span className="extraIcon ezyIcon icon-tBaggage"></span>
                                   <span>Baggage option is not available for this flight</span></div>
                                  </Tab>
                                  }

                                            {props.returnSupp !== "" && props.returnSupp !=="0" && state.baggageReturnOptions.length !== 0 &&
                                              <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                <AddBaggage sendBagprice={updateBagprice(pasenger.ty, pasenger.fesno, idx, "R")} bagList={state.alJazeeraBooking && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageReturnOptions,pasenger.passengerKey) : state.baggageReturnOptions} disable={props.disable} />
                                              </Tab>
                                            }
                                          </Tabs>
                                        </Tab>

                              {(state.mealOptions.length!==0 || state.mealReturnOptions.length !== 0) &&
                                <Tab eventKey="admeal" title={<><span className="ezyIcon icon-meal1"></span> Add Meal</>}>
                                   {state.alJazeeraBooking ? (
                                     <>
                                  <Tabs defaultActiveKey="onml" id="roots">
                                  {state.mealOptions.length !== 0 &&
                                  <Tab eventKey="onml" title={props.onwardRoute}>
                                  <Row>
                                  {state.mealOptions.length !== 0 && state.mealOptions.map((each,index) => (
                                   <React.Fragment key={index}>
                                    <Col xs={6}>
                                      <label className="mealLabel">{each.route}</label>
                                      <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"O",each.route)} passengerKey= {pasenger.passengerKey} mealList={props.fareresp.data[0].sup == 3?getEachKeySSR(each.optins,pasenger.passengerKey):each.optins[0]} disable={props.disable}/>
                                    </Col>
                                    </React.Fragment>
                                  ))}
                                  </Row>
                                 </Tab>
                                  }

                                {state.mealReturnOptions.length !== 0 &&
                                  <Tab eventKey="rtml" title={props.returnRoute}>
                                  <Row>
                                  {state.mealReturnOptions.length !== 0 && state.mealReturnOptions.map((each,index) => (
                                   <React.Fragment key={index}>
                                    <Col xs={6}>
                                      <label className="mealLabel">{each.route}</label>
                                      <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"R",each.route)} passengerKey= {pasenger.passengerKey} mealList={props.fareresp.data[0].sup == 3?getEachKeySSR(each.optins,pasenger.passengerKey):each.optins[0]} disable={props.disable}/>
                                    </Col>
                                    </React.Fragment>
                                  ))}
                                  </Row>
                                 </Tab>
                                  }
                                 </Tabs>
                                      </>
                                  ):(
                                    <>
                                     <Tabs defaultActiveKey="dtp" id="roots">
                                     <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                     <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"O",props.onwardRoute)} passengerKey= {pasenger.passengerKey} mealList={state.mealOptions} disable={props.disable}/>
                                     </Tab>

                                <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                     <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"R",props.returnRoute)}  mealList={state.mealReturnOptions} disable={props.disable}/>
                                     </Tab>

                                    </Tabs>
                                    </>
                                  )}
                               </Tab>
                              }

                             {/* {props.srequest.tt==="2" && state.mealReturnOptions.length!==0 && !state.alJazeeraBooking &&
                                <Tab eventKey="admeal" title={<><span className="ezyIcon icon-meal1"></span> Add Meal</>}>
                                <Tabs defaultActiveKey="arv" id="roots">
                                <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                     <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"R",props.returnRoute)} mealList={state.mealReturnOptions} disable={props.disable}/>
                                     </Tab>
                                    </Tabs>
                                </Tab>
                              } */}
                              {/* <Tab eventKey="spAss" title={<><span className="ezyIcon icon-wheelchair"></span> Add Special Assistance</>}>
                              <Tabs defaultActiveKey="dtp" id="roots">
                                      <Tab eventKey="dtp" >
                                        <SpecialAssistance spPrefs={state.spPrefs} />
                                        </Tab>
                                      </Tabs>
                              </Tab> */}

                                      </Tabs>
                                    </div>
                                  </Tab>
                                }

                        {props.srequest.sty!==undefined && props.srequest.sty==="split" && (props.onwardSupp!=="" && props.returnSupp!=="" && (props.onwardSupp!=="0" || props.returnSupp!=="0")) &&
                           <Tab eventKey="extra" title={<><span className="iconSy">
                             <span className="plus ezyIcon icon-plus"></span>
                             <span className="minus ezyIcon icon-minus"></span>
                             </span>Extras</>}>
                            <div className="extradetails extra eachList">
                              <Tabs defaultActiveKey="bgg" id="extraType" className="extralist">
                              <Tab eventKey="bgg" title={<><span className="ezyIcon icon-tBaggage"></span> Add Baggage </>}>
                               <Tabs defaultActiveKey="dtp" id="roots">
                                  {props.onwardSupp!=="" && props.onwardSupp!=="0" &&  state.baggageOptions.length!==0 &&
                                  <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                  <AddBaggage sendBagprice={updateBagprice(pasenger.ty,pasenger.fesno,idx,"O",props.onwardRoute)} bagList={state.onwardAJbaggage && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageOptions,pasenger.passengerKey) : state.baggageOptions} disable={props.disable}/>
                                  </Tab>
                                  }
                                  {((props.onwardSupp!=="" && props.onwardSupp==="0") || state.baggageOptions.length === 0) &&
                                  <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                    <div className="extrainfolcc">
                                    <span className="extraIcon ezyIcon icon-tBaggage"></span>
                                   <span>Baggage option is not available for this flight</span>
                                    </div>
                                  </Tab>
                                  }
                                  {((props.returnSupp!=="" && props.returnSupp==="0") || state.baggageReturnOptions.length === 0) &&
                                  <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                  <div className="extrainfolcc">
                                    <span className="extraIcon ezyIcon icon-tBaggage"></span>
                                   <span>Baggage option is not available for this flight</span></div>
                                  </Tab>
                                  }

                                            {props.returnSupp !== "" && props.returnSupp !=="0" && state.baggageReturnOptions.length !== 0 &&
                                              <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                <AddBaggage sendBagprice={updateBagprice(pasenger.ty, pasenger.fesno, idx, "R",props.returnRoute)} bagList={state.returnAJbaggage && props.fareresp.data[0].sup == 3 ? getEachKeySSR(state.baggageReturnOptions,pasenger.passengerKey) : state.baggageReturnOptions} disable={props.disable} />
                                              </Tab>
                                            }
                                          </Tabs>
                                        </Tab>
                                        {(state.mealOptions.length !== 0 || state.mealReturnOptions.length !== 0)&&
                                           <Tab eventKey="admeal" title={<><span className="ezyIcon icon-meal1"></span> Add Meal</>}>
                                             {state.alJazeeraBooking ? (
                                     <>
                                  <Tabs defaultActiveKey="onml" id="roots">
                                  {state.mealOptions.length !== 0 &&
                                  <Tab eventKey="onml" title={props.onwardRoute}>
                                  <Row>
                                  {state.mealOptions.length !== 0 && state.mealOptions.map((each,index) => (
                                   <React.Fragment key={index}>
                                    <Col xs={6}>
                                      <label className="mealLabel">{each.route}</label>
                                      <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"O",each.route)} passengerKey= {pasenger.passengerKey} mealList={props.fareresp.data[0].sup == 3?getEachKeySSR(each.optins,pasenger.passengerKey):each.optins[0]} disable={props.disable}/>
                                    </Col>
                                    </React.Fragment>
                                  ))}
                                  </Row>
                                 </Tab>
                                  }

                                {state.mealReturnOptions.length !== 0 &&
                                  <Tab eventKey="rtml" title={props.returnRoute}>
                                  <Row>
                                  {state.mealReturnOptions.length !== 0 && state.mealReturnOptions.map((each,index) => (
                                   <React.Fragment key={index}>
                                    <Col xs={6}>
                                      <label className="mealLabel">{each.route}</label>
                                      <AddMeal sendMealprice={updateMealprice(pasenger.ty,pasenger.fesno,idx,"R",each.route)} passengerKey= {pasenger.passengerKey} mealList={props.fareresp && props.fareresp.data&& props.fareresp.data[0] &&props.fareresp.data[0].sup && props.fareresp.data[0].sup == 3?getEachKeySSR(each.optins,pasenger.passengerKey):each.optins} disable={props.disable}/>
                                    </Col>
                                    </React.Fragment>
                                  ))}
                                  </Row>
                                 </Tab>
                                  }
                                 </Tabs>
                                      </>
                                  ):(
                                    <>
                                     <Tabs defaultActiveKey="dtp" id="roots">
                                              {props.onwardSupp !== "" && props.onwardSupp !=="0" &&
                                                <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                                  <AddMeal sendMealprice={updateMealprice(pasenger.ty, pasenger.fesno, idx, "O",props.onwardRoute)} mealList={state.mealOptions} disable={props.disable} />
                                                </Tab>
                                              }

                                              {props.onwardSupp !== "" && props.onwardSupp==="0" &&
                                                <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                                  <div className="extrainfolcc">
                                                  <span className="extraIcon ezyIcon icon-meal1"></span>
                                                  <Form.Group as={Col} xs="4" className="selectplugin m-0 p-0" controlId="mp">
                                                    <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.mp} onChange={handlePrefsChange(idx)}>
                                                      {state.mealPrefs}
                                                    </Form.Control>
                                                    <Form.Label>Meal Preference</Form.Label>
                                                  </Form.Group>
                                                  </div>
                                                </Tab>
                                              }
                                              {props.returnSupp !== "" && props.returnSupp !=="0" && state.mealReturnOptions.length!==0 &&
                                                <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                  <AddMeal sendMealprice={updateMealprice(pasenger.ty, pasenger.fesno, idx, "R",props.returnRoute)} mealList={state.mealReturnOptions} disable={props.disable} />
                                                </Tab>
                                              }

                                              {props.returnSupp !== "" && props.returnSupp === "0" &&
                                                <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                   <div className="extrainfolcc">
                                                  <span className="extraIcon ezyIcon icon-meal1"></span>
                                                  <Form.Group as={Col} xs="4" className="selectplugin m-0 p-0" controlId="mp">
                                                    <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.mp} onChange={handlePrefsChange(idx)}>
                                                      {state.mealPrefs}
                                                    </Form.Control>
                                                    <Form.Label>Meal Preference</Form.Label>
                                                  </Form.Group>
                                                  </div>
                                                </Tab>
                                              }
                                            </Tabs>
                                    </>
                                  )}

                                          </Tab>
                                        }

                                        {/* <Tab eventKey="spAss" title={<><Icon className="spsl" size={20} icon="wheelchair" /> Special Assistance</>}>
                               <Tabs defaultActiveKey="dtp" id="roots">
                                      <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                        <SpecialAssistance spPrefs={state.spPrefs} />
                                        </Tab>
                                        <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                        <SpecialAssistance spPrefs={state.spPrefs} />
                                        </Tab>
                                      </Tabs>
                              </Tab> */}

                              {((props.onwardSupp !== "" && Number(props.onwardSupp) == "0") || (props.returnSupp !== "" && Number(props.returnSupp) == "0")) && <Tab eventKey="seatPref" title={<><span className="ezyIcon icon-wheelchair"></span> Seat Preference</>}>
                                          <Tabs defaultActiveKey="dtp" id="roots">
                                            {props.onwardSupp !== "" && props.onwardSupp === "0" &&
                                              <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                                <div className="extrainfolcc">
                                                <span className="extraIcon ezyIcon icon-wheelchair"></span>
                                                <Form.Group as={Col} xs="4" className="selectplugin m-0 p-0" controlId="sp">
                                                  <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.sp} onChange={handlePrefsChange(idx)}>
                                                    {state.seatPrefs}
                                                  </Form.Control>
                                                </Form.Group>
                                                </div>
                                              </Tab>
                                            }
                                            {props.onwardSupp !== "" && props.onwardSupp !== "0" &&
                                              <Tab eventKey="dtp" title={<>{props.onwardRoute}</>}>
                                                 <div className="extrainfolcc">
                                                <span className="extraIcon ezyIcon icon-wheelchair"></span>
                                                <span>Seat option is not available for this flight</span>
                                                </div>
                                              </Tab>
                                            }
                                            {props.returnSupp !== "" && props.returnSupp === "0" &&
                                              <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                 <div className="extrainfolcc">
                                                <span className="plus extraIcon ezyIcon icon-tBaggage"></span>
                                                <Form.Group as={Col} xs="4" className="selectplugin m-0 p-0" controlId="sp">
                                                  <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.sp} onChange={handlePrefsChange(idx)}>
                                                    {state.seatPrefs}
                                                  </Form.Control>
                                                </Form.Group>
                                                </div>
                                              </Tab>
                                            }
                                            {props.returnSupp !== "" && props.returnSupp !== "0" &&
                                              <Tab eventKey="arv" title={<>{props.returnRoute}</>}>
                                                 <div className="extrainfolcc">
                                                <span className="plus extraIcon ezyIcon icon-wheelchair"></span>
                                                <span>Seat option is not available for this flight</span>
                                                </div>
                                              </Tab>
                                            }
                                          </Tabs>
                                        </Tab>}
                                        {/* <Tab eventKey="bgg" title={<><span className="ezyIcon icon-tBaggage"></span> Select Seats </>}>
                                          </Tab> */}
                                      </Tabs>
                                    </div>
                                  </Tab>
                                }

                                {((props.srequest.sty === "normal" && props.onwardSupp !== "" && props.onwardSupp === "0") || (props.srequest.sty === "split" && props.onwardSupp !== "" && props.returnSupp !== "" && props.onwardSupp === "0" && props.returnSupp === "0")) &&
                        <Tab eventKey="home" title={<><span className="iconSy">
                          <span className="plus ezyIcon icon-plus"></span>
                             <span className="minus ezyIcon icon-minus"></span>
                          </span>Other Preference</>}>
                                    {!props.enablePay &&
                                      <div className="extradetails OtherPreference">
                                        <Form.Row>
                                          <Form.Group as={Col} xs={4} controlId="sa">
                                            <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.sa} onChange={handlePrefsChange(idx)}>
                                              {state.spPrefs}
                                            </Form.Control>
                                            <Form.Label>Special Assistance </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} xs="4" className="selectplugin" controlId="sp">
                                            <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.sp} onChange={handlePrefsChange(idx)}>
                                              {state.seatPrefs}
                                            </Form.Control>
                                            <Form.Label>Seat Preference</Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} xs="4" className="selectplugin" controlId="mp">
                                            <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.opref.mp} onChange={handlePrefsChange(idx)}>
                                              {state.mealPrefs}
                                            </Form.Control>
                                            <Form.Label>Meal Preference</Form.Label>
                                          </Form.Group>
                                        </Form.Row>
                                      </div>
                                    }
                                  </Tab>
                                }
                        <Tab eventKey="profile" title={<><span className="iconSy">
                          <span className="plus ezyIcon icon-plus"></span>
                             <span className="minus ezyIcon icon-minus"></span>
                          </span>Add Frequent Flyer Number</>}>
                                  {!props.enablePay &&
                                    <div className="extradetails FrequentFlyerNumber">
                                      <Form.Row>
                                        <Form.Group as={Col} xs={4} controlId="air" className="customSelect">
                                          <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.ffinfo.air} onChange={handlePrefsChange(idx)}>
                                            <option value="">Select</option>
                                            {state.airlines}
                                          </Form.Control>
                                          <Form.Label>Airline Name</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="4" controlId="ffnum">
                                          <Form.Control type="text" disabled={state.enableImport || props.disable} placeholder="Freq.Number" value={pasenger.ffinfo.ffnum} onChange={handlePrefsChange(idx)} />
                                          <Form.Label>Freq. Number</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} className="selectplugin" controlId="cn">
                                        </Form.Group>
                                      </Form.Row>
                                    </div>
                                  }

                                </Tab>

                              </Tabs>

                            </div>
                          </>
                        }
                      </div>
                    </ShowHide>

                    {pasenger.inf !== "" &&
                      <ShowHide visible={pasenger.sno === 1 ? "true" : ""} title={"Traveller (Infant " + pasenger.fesno + ")"}>
                        <div className="showHide-content pt-4 pb-0">
                          <Form.Row>
                            <Form.Group as={Col} xs={3} controlId="tl" className="customSelect">
                              <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.inf.tl} onChange={handleInfChange(idx)}>
                                {state.title}
                              </Form.Control>
                              <Form.Label>Title<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} controlId="fn" className="selectplugin" xs={3}>
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="fn"
                                onChange={handleInfChange(idx)}
                                onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, "INF")}
                                options={state.infList}
                                placeholder="Choose Country.."
                                selected={[pasenger.inf]}
                                disabled={state.enableImport || props.disable}
                              />
                              <Form.Label>First Name{(props.onwardSupp !== "" && props.onwardSupp !== "0") && <sup>*</sup>}</Form.Label>
                              {pasenger.inf.limitError !== undefined && pasenger.inf.limitError.showFirst == true && <p className="nameToolong"><span className="longnameError ezyIcon icon-warning">Name is too long, Ticket may get failed</span></p>}
                            </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="ln">
                              <Form.Control type="text" disabled={state.enableImport || props.disable} placeholder="Last Name" value={pasenger.inf.ln} onChange={handleInfChange(idx)} />
                              <Form.Label>Last Name<sup>*</sup></Form.Label>
                              {pasenger.inf.limitError !== undefined && pasenger.inf.limitError.showLast == true && <p className="nameToolong"><span className="longnameError ezyIcon icon-warning">Name is too long, Ticket may get failed</span></p>}
                            </Form.Group>
                            <Form.Group as={Col} xs="3" className="selectplugin datePicker" controlId="dob">
                              <DatePicker className="ml-3"
                                selected={pasenger.inf.dob && DateUtils.convertNewToDate(pasenger.inf.dob)}
                                onCalendarOpen ={handleInfDobChange(idx)}
                                onChange={handleInfDobChange(idx)}
                                className="form-control"
                                placeholderText="DD-MM-YYYY"
                                dateFormat="dd-MM-yyyy"
                                maxDate={paxDate(new Date(),0)}
                                minDate={childMin(new Date(),2)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                disabled={props.disable || (state.enableImport && pasenger.inf.disablePax!==undefined && pasenger.inf.disablePax)}
                              />
                              <Form.Label>Date of Birth<sup>*</sup></Form.Label>
                              <span className="calendar ezyIcon icon-newcalendar"></span>
                            </Form.Group>
                          </Form.Row>
                          {pasenger.inf !== "" && state.passportEnable &&
                            <>
                              <h6 className="subTitle">Passport Info</h6>
                              <Form.Row>
                                <Form.Group as={Col} xs={3} controlId="num">
                                  <Form.Control type="text" disabled={state.enbalePPInfo || props.disable ||props.srequest.sty!==undefined && props.srequest.sty==="IMPORT"
                            && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].inf && props.srequest.psngrs[idx].inf.doc && props.srequest.psngrs[idx].inf.doc.num && props.srequest.psngrs[idx].inf.doc.num.length>0
                             } placeholder="Passport Name" value={pasenger.inf.doc.num} onChange={handleInfChange(idx)} />

                                  <Form.Label>Passport Number {state.docMandatory === "yes" || props.indPassportEnable? <sup>*</sup> : ""}</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="na">
                                  <Form.Control as="select" disabled={state.enbalePPInfo || props.disable ||props.srequest.sty!==undefined && props.srequest.sty==="IMPORT"
 && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].inf && props.srequest.psngrs[idx].inf.doc && props.srequest.psngrs[idx].inf.doc.na && props.srequest.psngrs[idx].inf.doc.na.length>0
} value={pasenger.inf.doc.na} onChange={handleInfChange(idx)}>
                                    <option value="">Select</option>
                                    {state.countriesMenu}
                                  </Form.Control>
                                  <Form.Label>Nationality {state.docMandatory === "yes" || props.checkMandatoryFlagNat ===true ? <sup>*</sup> : ""}</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="isc">
                                  <Form.Control as="select" disabled={state.enbalePPInfo || props.disable || props.srequest.sty!==undefined && props.srequest.sty==="IMPORT"
 && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc

 && props.srequest.psngrs[idx].inf.doc.isc && props.srequest.psngrs[idx].inf.doc.isc.length>0} value={pasenger.inf.doc.isc} onChange={handleInfChange(idx)}>
                                    <option value="">Select</option>
                                    {state.countriesMenu}
                                  </Form.Control>
                                  <Form.Label>Issued Country {state.docMandatory === "yes" ? <sup>*</sup> : ""}</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} className="selectplugin datePicker" controlId="isd">
                                  <DatePicker
                                    className="form-control"
                                    selected={pasenger.inf.doc.isd !== null ? DateUtils.convertNewToDate(pasenger.inf.doc.isd) : null}
                                    onCalendarOpen ={handleInfIsDateChange(idx)}
                                    onChange={handleInfIsDateChange(idx)}
                                    placeholderText="DD-MM-YYYY"
                                    dateFormat="dd-MM-yyyy"
                                    minDate={pasenger.inf.dob ? pasenger.inf.dobOrg : ""}
                                    maxDate={new Date()}
                                    minDate={state.deptDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={state.enbalePPInfo || props.disable|| props.srequest.flowType!==undefined && props.srequest.flowType==="IMPORT" && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].inf && props.srequest.psngrs[idx].inf.doc && props.srequest.psngrs[idx].inf.doc.isd && props.srequest.psngrs[idx].inf.doc.isd.length>0
                                  }
                                  />

                                  <Form.Label>Issued Date</Form.Label>
                                  <span className="calendar ezyIcon icon-newcalendar"></span>
                                </Form.Group>
                                <Form.Group as={Col} className="selectplugin datePicker" controlId="exd">
                                  <DatePicker
                                    className="form-control"
                                    selected={pasenger.inf.doc.exd !== null ? DateUtils.convertNewToDate(pasenger.inf.doc.exd) : null}
                                    onCalendarOpen ={handleInfExDateChange(idx)}
                                    onChange={handleInfExDateChange(idx)}
                                    placeholderText="DD-MM-YYYY"
                                    dateFormat="dd-MM-yyyy"
                                    minDate={new Date()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={state.enbalePPInfo || props.disable ||props.srequest.sty!==undefined && props.srequest.sty==="IMPORT"
                                    && props.srequest.psngrs && props.srequest.psngrs[idx] && props.srequest.psngrs[idx].doc && props.srequest.psngrs[idx].inf.doc.isd && props.srequest.psngrs[idx].inf.doc.isd.length>0}
                                  />
                                  <Form.Label>Expiry Date {state.docMandatory === "yes" || props.indPassportEnable? <sup>*</sup> : ""}</Form.Label>
                                  <span className="calendar ezyIcon icon-newcalendar"></span>
                                </Form.Group>
                              </Form.Row></>
                          }

                        </div>
                      </ShowHide>
                    }
                  </>

                ))}
              </div>



            }
          </div>
        </ShowHide>
      </div>
    </>
  )
}
export default React.memo(TravellersDetails)