
import React, { useState, useReducer, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import Button from '../buttons/Button';
import Select from 'react-select';

const initialState = {
  adultCount:1,childCount:0,adtmax:6,chdmax:5,noOfRooms:1,childAgeArray:[],roomsArray:[],age:0,removedRm:0
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
function AddGuestDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addRooms, setAddRooms,] = useState(false);
  const [chdList, setchdList,] = useState([]);
  const [roomsList, setRoomsList,] = useState([]);

  useEffect(() => {
  if(state.roomsArray.length===0){
    const payload={
      rmno:1,
      adt:1,
      chd:0,
      chdArray:[],
      chdAges:[]
    }
    roomsList.push(payload);
    dispatch({ type: "roomsArray", payload: roomsList })
  }
  }, [state.roomsArray]);

  useEffect(() => {
    if(props.roomsInfo.length!==0){
      dispatch({ type: "roomsArray", payload: props.roomsInfo })
      dispatch({ type: "noOfRooms", payload: props.roomsInfo.length })
    }
    }, [props.roomsInfo]);

  const handleIncrementAdlt = (rmno) =>()=> {
    let adtsCount=0;
    let chdCounts=0;
    //if(props.ismodify){
    for(let val of state.roomsArray){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
      }
      //}
        for(let val of state.roomsArray){
           if(val.adt < 6 && val.rmno===rmno){
              val.adt=val.adt+1;
              dispatch({ type: "adultCount", payload: state.adultCount+1 })
              props.sendGuseCount(adtsCount+1 + chdCounts);
           }
        }
        props.sendRoomsInfo(state.roomsArray);
  };
  const handleDecrementAdlt = (rmno) =>()=> {
    let adtsCount=0;
    let chdCounts=0;
    //if(props.ismodify){
    for(let val of state.roomsArray){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
      }
      //}
        for(let val of state.roomsArray){
          if(val.adt>1 && val.rmno===rmno){
           val.adt=val.adt-1;
           dispatch({ type: "adultCount", payload: state.adultCount-1 })
            props.sendGuseCount(adtsCount-1 + chdCounts);
          }
       }
       props.sendRoomsInfo(state.roomsArray);
  };
  const handleIncrementChild = (rmno) =>()=> {
    let adtsCount=0;
    let chdCounts=0;
    //if(props.ismodify){
    for(let val of state.roomsArray){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
      }
     // }
    for(let val of state.roomsArray){
      if(val.chd < 5 && val.rmno===rmno){
         val.chd=val.chd+1;
         const pay={
           "chd":val.chd,
           "age":1
         }
         val.chdArray.push(pay);
         //val.chdAges.push(1);
         props.sendGuseCount(adtsCount + chdCounts+1);
         dispatch({ type: "childCount", payload: state.childCount+1 })
      }
    }
    chdList.push(state.childCount+1);
    dispatch({ type: "childAgeArray", payload: chdList })
    props.sendRoomsInfo(state.roomsArray);
  };
  const handleDecrementChild = (rmno) =>()=> {
    let adtsCount=0;
    let chdCounts=0;
    //if(props.ismodify){
    for(let val of state.roomsArray){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
      }
      //}
    for(let val of state.roomsArray){
      if(val.chd!==0 &&  val.rmno===rmno){
       val.chd=val.chd-1;
       val.chdArray.splice(val.chd);
       val.chdAges.splice(1);
       dispatch({ type: "childCount", payload: state.childCount-1 })
       props.sendGuseCount(adtsCount + chdCounts-1);
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const ChildernAge = [
    { value: '1', label: '1' },{ value: '2', label: '2' },{ value: '3', label: '3' },{ value: '4', label: '4' },{ value: '5', label: '5' },{ value: '6', label: '6' },{ value: '7', label: '7' },{ value: '8', label: '8' },{ value: '9', label: '9' },{ value: '10', label: '10' },{ value: '11', label: '11' }]

  const handleChange =(room,index) => e => {
    let ages=[];
    for(let val of state.roomsArray){
      if(val.rmno===room.rmno && val.chd===room.chd){
        let count=1;
        for(let chd of val.chdArray){
           if(count===index){
            chd.age=Number(e.value);
            val.chdAges.push(chd.age);
           }
           count=count+1;
        }
      }
    }
    props.sendRoomsInfo(state.roomsArray);
  };

  const addMoreRooms = (e) => {
    setAddRooms(true)
    let roomNo=0;
    let list=state.roomsArray;
    let adtsCount=0;
    let chdCounts=0;
    for(let val of list){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
        roomNo=roomNo+1;
      }
      const payload={
        rmno:list.length+1,
        adt:1,
        chd:0,
        chdArray:[],
        chdAges:[]
      }
      list.push(payload);

      dispatch({ type: "adultCount", payload: adtsCount+1 })
      dispatch({ type: "noOfRooms", payload: list.length })
      props.sendGuseCount(adtsCount+1 + chdCounts);
      props.sendRoomsCount(list.length)
      dispatch({ type: "roomsArray", payload: list })
      props.sendRoomsInfo(list);
  }
  const dltMoreRooms =(room) => (e) => {
    setAddRooms(false)
    let rcount=state.roomsArray.length-1;
    let adtsCount=0;
    let chdCounts=0;
    for(let val of state.roomsArray){
      //if(props.ismodify){
        if(adtsCount===0){
          adtsCount=val.adt;
          chdCounts=val.chd;
        }else{
          adtsCount=adtsCount+val.adt;
          chdCounts=chdCounts+val.chd;
        }
      //}
      }
    state.roomsArray.splice(state.roomsArray.findIndex(a => a.rmno === room.rmno) , 1);
    let count=1;
    for(let val of state.roomsArray){
            val.rmno=count;
            count=count+1;
    }
    dispatch({ type: "noOfRooms", payload: rcount })
    dispatch({ type: "roomsArray", payload: state.roomsArray })
    dispatch({ type: "removedRm", payload: room })
    dispatch({ type: "adultCount", payload: state.adultCount-room.adt })
    dispatch({ type: "childCount", payload: state.childCount-room.chd })
    //if(props.ismodify){
      props.sendGuseCount(adtsCount-room.adt + chdCounts-room.chd);
      dispatch({ type: "adultCount", payload: adtsCount-room.adt })
      dispatch({ type: "childCount", payload: chdCounts-room.chd })
    // }else{
    //   props.sendGuseCount(state.adultCount-room.adt + state.childCount-room.chd);
    // }
    props.sendRoomsCount(rcount)

}

const handleCloseBtn =() => {
   props.closeBtn();
};

  return (
    <>
      <div className={state.roomsArray.length!==1? 'dropDownPopUP guest addplus':'dropDownPopUP guest '}>
        <h5>Add Rooms & Guest <span onClick={handleCloseBtn} className="closeTravel ezyIcon icon-close"></span></h5>
        <Row>

        {state.roomsArray.length!==0 && state.roomsArray.map((room, idx) => (
          <Col  className="addrooms" xs={state.roomsArray.length!==1 ? 6:12}>
        <React.Fragment key={idx}>
          <h6><span className="ezyIcon icon-bedroom"> Room {room.rmno} </span>{room.rmno!==1 && <Button className="removeRoom ezyIcon icon-cancelOne" variant="link" onClick={dltMoreRooms(room)}></Button>}</h6>
          <Row>
            <Col xs={12} className="addpaxType d-flex">
              <div className="addme">
            <label>Adults (12+)</label>
              <div className="incDec">
                <Button size="md" className="minus ezyIcon icon-minus" onClick={handleDecrementAdlt(room.rmno)} variant="link" ></Button>
                <input type="text" className="number" value={room.adt}></input>
                <Button size="md" className="plus ezyIcon icon-plus" onClick={handleIncrementAdlt(room.rmno)} variant="link"></Button>
              </div>
              </div>
              <div className="addme">
            <label>Children  <span>(0-11)</span></label>
              <div className="incDec">
                <Button size="md" className="minus ezyIcon icon-minus" onClick={handleDecrementChild(room.rmno)} variant="link" >
                 </Button>
                <input type="text" className="number" value={room.chd}></input>
                <Button size="md" className="plus ezyIcon icon-plus" onClick={handleIncrementChild(room.rmno)} variant="link">
                 </Button>
              </div>
                </div>
            </Col>

            <div className="childAge">
             {room.chdArray !==undefined &&
              <div className="incDec age">
                <Row>
               {room.chdArray.map((chd, index) => (
                 <React.Fragment key={index}>
               {index===0 &&<Col className="p-0" xs={12}><label>Age of children</label></Col> }
                <Col xs={4}>
                <Select
                  isSearchable={false}
                  className='react-select-container-age'
                  classNamePrefix="react-select-age"
                  //defaultValue={{ label: "1", value: 1 }}
                  onChange={handleChange(room,index+1)}
                  value={[{"value":chd.age,"label":chd.age}]}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#F0F0F0',
                      primary: '#475F7B',
                    },
                  })}
                  options={ChildernAge}
                />
                </Col>
              </React.Fragment>
               ))}
              </Row>
              </div>
               }
            </div>
            </Row>
          </React.Fragment>

        </Col> ))}

        </Row>


        <div className="clickTOAdd">
          {state.noOfRooms<8 &&
         <Button className="ezyIcon icon-plus" variant="link" onClick={addMoreRooms}> Add Room</Button>
          }
        </div>
        {/* <Button
          size="md" block
          className="updates"
          variant="primary"
        >DONE
        <span>2 Rooms, 6 Travellers</span>
        </Button> */}
      </div>
    </>
  )
}


export default AddGuestDetails;
