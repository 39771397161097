
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import ShowHide from '../../../common/showHide/ShowHide';
import RoomPriceDetails from './RoomPriceDetails';
import RoomPaxDetails from './RoomPaxDetails';
import moment from 'moment';

function PassengerDetails(props) {
    const [state, setState] = useState({})
    const [fareData , setFareData] = useState(props.fareDetails)
    const [type , setType] = useState(props.type)
    const [mtaList,setMtaList] = useState([]);
    const [taList,setTaList] = useState([]);
    const [staList,setStaList] = useState([]);
    const [disableTA,setDisableTA] = useState(false);
    const [sel, setSel] = useState(false)


useEffect(() => {
    if(props.fareDetails!==undefined){
        let mtaList=props.fareDetails.filter((obj) => obj.type==="MTA")
        for(let room of mtaList[0].rooms){
             if(props.supplier==0){
                room.selected=false;
                room.disble=false;
             }else{
                room.selected=true;
                room.disble=true;
             }
             room.changed=false;
             room.rmpr.freeCancelDate=moment(room.rmpr.freeCancelDate, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm');

        }
        setMtaList(mtaList);

        let taList=props.fareDetails.filter((obj) => obj.type==="TA")
        if (taList !=undefined && taList.length!==0){
        for(let room of taList[0].rooms){
            if(props.supplier==0){
                room.selected=false;
                room.disble=false;
             }else{
                room.selected=true;
                room.disble=true;
             }
             room.changed=false;
             room.rmpr.freeCancelDate=moment(room.rmpr.freeCancelDate, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm');
        }
        setTaList(taList)}

        let staList=props.fareDetails.filter((obj) => obj.type==="STA")
        if(staList!==undefined && staList.length!==0){
            for(let room of staList[0].rooms){
                if(props.supplier==0){
                    room.selected=false;
                    room.disble=false;
                 }else{
                    room.selected=true;
                    room.disble=true;
                 }
                room.changed=false;
                room.rmpr.freeCancelDate=moment(room.rmpr.freeCancelDate, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm');
           }
           setStaList(staList);
        }

    }
}, [props.fareDetails]);

useEffect(()=>{
 if(props.userType!==undefined){
   if(props.userType==="ta_user"){
    if(props.type!==undefined && props.type==="STA"){
        setTaList([]);
        setDisableTA(true)
    }else{
        let taList=props.fareDetails.filter((obj) => obj.type==="TA")
    for(let room of taList[0].rooms){
         room.selected=false;
         room.disble=false;
         room.changed=false;
    }
    setDisableTA(false)
    setTaList(taList);
    }
   }
  if(props.userType==="att_user" && (props.type==="TA" || props.type==="STA")){
    if (props.supplier==1){
        let taList=props.fareDetails.filter((obj) => obj.type==="TA")
    for(let room of taList[0].rooms){
         room.selected=true;
         room.disble=true;
         room.changed=true;
    }
    setTaList(taList)
     } 
    setDisableTA(false)
  }
}
},[props.type])

const handleChange = (obj) =>()=>{
    let rooms=[];
    if(props.userType==="att_user"){
        rooms=mtaList[0].rooms;
    }else if(props.userType==="ta_user"){
        rooms=taList[0].rooms;
    }else if(props.userType==="sta_user"){
        rooms=staList[0].rooms;
    }
  for(let room of rooms){
    if(obj.rmpr.canpol!=="non refundable" && room.room_no===obj.room_no && (obj.rst==="Confirmed" || obj.rst==="On hold")){
        if(obj.selected){
            room.selected=false;
            setSel(false)
        }else{
            room.selected=true;
            setSel(true)
        }
    }else if(obj.rmpr.canpol==="non refundable"){
       room.disble=true;
       room.selected=false;
       props.sendRoomInfo(obj);
       break;
    }
}
}

const updateSelRoom=(obj)=>{
    props.sendSelRoom(obj);
}


return (
<>
{props.userType==="att_user" && props.type==="MTA" && mtaList.length!==0 && mtaList[0].rooms.map((room, index) => (
<React.Fragment key={index}>
    <div className={props.enablePay || props.enabelCancel || props.enableCancelHold ? 'chkme':' '}>
       <ShowHide visible="true" icon="" title={"Room "+(index+1)+" : "+ room.rnm}  subtitle={room.htlconfno!==undefined && room.htlconfno?"Supplier Ref No :   "+room.htlconfno:"N/A"}>
           {props.enabelCancel && room.rst==="Confirmed" &&
            <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
           }
           {room.rst==="On hold" && (props.enablePay || props.enableCancelHold ) &&
            <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
           }
            <div className="showHide-content pl-0 pr-0 pb-0">
           <RoomPaxDetails roomObj={room} type={"MTA"} enableEdit={props.enableEdit} sendSelRoom={updateSelRoom} bookingType={props.bookingType}/>
           <RoomPriceDetails roomObj={room} type={"MTA"} currency={props.currency}/>
            </div>
        </ShowHide>
        </div>
 </React.Fragment>
 ))}

{(props.userType==="ta_user" || props.type==="TA") && taList.length!==0 && taList[0].rooms.map((room, index) => (
<React.Fragment key={index}>
<div className={props.enablePay || props.enabelCancel || props.enableCancelHold ? 'chkme':''}>
       <ShowHide visible="true" icon="" title={"Room  "+(index+1)+ " : "+ room.rnm}  subtitle={room.rst!=="On hold" &&  room.htlconfno!==undefined && room.htlconfno?"Supplier Ref No :   "+room.htlconfno:"N/A"}>
       {!disableTA &&
        <>
        {props.enabelCancel && room.rst==="Confirmed" &&
       <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
       }
       {room.rst==="On hold" && (props.enablePay || props.enableCancelHold ) &&
        <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
        }
        </>
       }
         <div className="showHide-content pl-0 pr-0 pb-0">
           <RoomPaxDetails roomObj={room} type={"TA"} bookingType={props.bookingType}/>
           <RoomPriceDetails roomObj={room} type={"TA"} index={index} currency={props.currency}/>
            </div>
        </ShowHide>
        </div>
 </React.Fragment>
 ))}

{(props.userType==="sta_user" || props.type==="STA") && staList.length!==0 && staList[0].rooms.map((room, index) => (
<React.Fragment key={index}>
<div className={props.enablePay || props.enabelCancel || props.enableCancelHold ? 'chkme':''}>
       <ShowHide visible="true" icon="" title={"Room "+(index+1)+" "+ room.rnm}  subtitle={room.rst!=="On hold" && room.htlconfno!==undefined && room.htlconfno?"Supplier Ref No :   "+room.htlconfno:"N/A"}>
       {!disableTA &&
        <>
       {props.enabelCancel && room.rst==="Confirmed" &&
       <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
       }
       {room.rst==="On hold" && (props.enablePay || props.enableCancelHold ) &&
        <Form.Check type="checkbox" disabled={room.disble} label="" custom id={index+1} checked={room.selected} value={room.selected}  onChange={handleChange(room)} />
        }
        </>
        }
         <div className="showHide-content pl-0 pr-0 pb-0">
           <RoomPaxDetails roomObj={room} type={"STA"} bookingType={props.bookingType}/>
           <RoomPriceDetails roomObj={room} type={"STA"} index={index} currency={props.currency}/>
            </div>
        </ShowHide>
        </div>
 </React.Fragment>
 ))}

 </>
    )
}


export default PassengerDetails;
