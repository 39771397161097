
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';
import {isNotNull } from '../../../commonUtils/validators';
let bookingStatus = require('../../../masterData/BookingStatus..json');

function RoomPaxInfoPdf(props) {
    const [state, setState] = useState({})
    const [room, setRoom] = useState(props.roomObj);
    const [remarks, setRemarks] = useState("");
    const [cancel, setCancel] = useState("");
    const [policy, setPolicy] = useState(props.policy);
    const [brdbs, setBrbds] = useState([]);
    const [roomStatusList, setRoomStatusList] = useState([]);

    useEffect(() => {
        if (props.roomObj !== undefined) {
            if(props.bookType!==undefined && props.bookType==="DIRECT"){
            if(isNotNull(props.roomObj.remarks)){
                let remrk = JSON.parse(props.roomObj.remarks);
                setRemarks(remrk)
            }
            if (props.roomObj.rmpr.canpol.includes("non refundable")) {
                setCancel("Non Refundable")
            } else {
                let can = JSON.parse(props.roomObj.rmpr.canpol)
                setCancel(can)
            }
            let bbs = JSON.parse(props.roomObj.brdbs)
            setBrbds(bbs)
        }else{
            setCancel(props.roomObj.rmpr.canpol)
            setRemarks(props.roomObj.remarks)
        }
        }
    }, [])

    useEffect(() => {
        if (props.policy !== undefined) {
            setPolicy(props.policy)
        }
    }, [props.policy])

    const executeOnClick = (isExpanded) => {
    }
    const statusList = [
        { value: '23', label: 'Confirmed' },
        { value: '28', label: 'Cancelled' },
        { value: '24', label: 'Amended' },
        { value: '22', label: 'On hold' },
        { value: '41', label: 'Partially Confirmed' }]


    const getStatus = (id) => {
        let stary = statusList.filter((obj) => obj.value === id);
        return stary[0].label;
    }
    const handleRoomChange = (type) => (e) => {
        const { id, value } = e.target
        setState({ [e.target.name]: e.target.value });
        if (room !== undefined) {
            if (type === "status") {
                room.rst = getStatus(value);
                room.changed = true;
                room.stid = value;
            }
            if (type === "ref") {
                room.htlrefno = value;
                room.changed = true;
            }
        }
        props.sendSelRoom(room)
    }

    useEffect(() => {
        const ticketsList = statusList.map((obj) =>
            <option value={obj.value}>{obj.label}</option>
        );
        setRoomStatusList(ticketsList);
        for (let val of statusList) {
            if (val.label === room.rst) {
                room.stid = val.value;
            }
        }
    }, [props.enableEdit])



    return (
        <>
            <div className="header">
                <Row>
                    <Col xs="2">Guest Name</Col>
                    <Col xs="2">Occupancy</Col>
                    <Col xs="2">Bed Type</Col>
                    <Col xs="2">Board Type</Col>
                    <Col xs="2">Hotel Conf No</Col>
                    <Col xs="2">Status</Col>
                    {/* <Col xs="2">Special Request</Col> */}
                </Row>
            </div>
            <div className="passengerList">
                <Row>
                    {/* firstname & last name */}
                    <Col xs="2">{room.rmpax[0].tl} {room.rmpax[0].fn} {room.rmpax[0].ln}</Col>
                    {/*  <Col xs="2">{props.price.adt} Adult(s), {isNotNull(props.price.chd)&&(props.price.chd)+',Children'}</Col> */}
                    {/* occupancy */}
                    <Col xs="2">{room.adt} Adult(s), {room.chd} Child(s){room.chd !== 0 && room.child_age !== undefined && room.child_age}</Col>
                      {/* Bed Type */}
                    <Col xs="2">{room.bed_type}</Col>
                    {/* board type */}
                    <Col xs="2">
                    <span className="d-flex">
                        {brdbs.length !== 0 && brdbs.map((bb, index) => (
                           <> {bb.name}, </>
                        ))}
                        </span>
                        {brdbs.length === 0 &&
                            <span> {"Room only"}</span>
                        }

                    </Col>
                    {/* Hotel reference number */}
                    <Col xs="2">
                        {!props.enableEdit && (room.rst !== "On Hold" && room.htlrefno !== undefined && room.htlrefno ? room.htlrefno : "N/A")}
                    </Col>
                    {/* Room level status */}
                    <Col className="status" >
                        {!props.enableEdit &&
                            <>
                                {room.rst === "Cancelled" &&
                                    <span className="status paxstatus text-danger"> Cancelled</span>
                                }
                                {room.rst === "On hold" &&
                                    <span className="status paxstatus"> On Hold</span>
                                }
                                {room.rst === "Confirmed" &&
                                    <span className="status paxstatus text-success"> Confirmed</span>
                                }
                                {room.rst === "Incomplete" &&
                                    <span className="status paxstatus text-danger">{room.rst}</span>
                                }
                                {/* <span className={room.rst==="Confirmed" ? 'status confirm' : 'text-danger'}>{room.rst}</span> */}
                            </>
                        }
                    </Col>
                    {/* Special Request */}
                    {/* <Col xs="2">{room.rmpax[0].spreq !== undefined && room.rmpax[0].spreq ? room.rmpax[0].spreq : "N/A"}</Col> */}
                </Row>
            </div>
            {props.allPax &&
            <>
                <div className="header">
                    <Row>
                        <Col xs="2">Guest Name</Col>
                        <Col xs="2">Passport No</Col>
                        <Col xs="2">Issue Date</Col>
                        <Col xs="2">Expiry Date</Col>
                        <Col xs="2">Date of Birth</Col>
                        <Col xs="2">Nationality</Col>
                    </Row>
                    </div >
                    <div className="passengerList">
                        {room.rmpax[0].pax_details.map((pax, idx) => (
                            < Row >
                                {/* firstname & last name */}
                                < Col xs="2">{pax.tl} {pax.fn} {pax.ln}</Col>
                                {/*  <Col xs="2">{props.price.adt} Adult(s), {isNotNull(props.price.chd)&&(props.price.chd)+',Children'}</Col> */}
                                {/* occupancy */}
                                <Col xs="2">{pax.isd !== undefined && pax.num ? pax.num : "N/A" }</Col>
                                {/* board type */}
                                <Col xs="2">
                                    {pax.isd !== undefined && pax.isd ? DateUtils.prettyDate(pax.isd, "DD/MM/YYYY") == "Invalid date" ? null : DateUtils.prettyDate(pax.isd, "DD/MM/YYYY") : "N/A"}
                                </Col>
                                {/* Hotel reference number */}
                                <Col xs="2">
                                    {pax.exd !== undefined && pax.exd ? DateUtils.prettyDate(pax.exd, "DD/MM/YYYY") == "Invalid date" ? null : DateUtils.prettyDate(pax.exd, "DD/MM/YYYY") : "N/A"}                                </Col>
                                {/* Room level status */}
                                {/* <Col className="status" >
                                {pax.dob}
                                </Col> */}
                                {/* Special Request */}
                                <Col xs="2">{pax.dob !== undefined && pax.dob ? DateUtils.prettyDate(pax.dob, "DD/MM/YYYY") == "Invalid date" ? null : DateUtils.prettyDate(pax.dob, "DD/MM/YYYY") : "N/A"}</Col>
                                <Col xs="2">{pax.dob !== undefined && pax.dob ? pax.na : "N/A"}</Col>
                            </Row>
                        ))}
                    </div>
                    </>


            }
            <div className="passInfo">
                {props.policy &&
                    <>
                    {cancel !== undefined && cancel !== "Non Refundable" &&
                      <div className="info">
                        <strong>Cancellation Policy</strong>
                            <div className="plcyInfo">
                                <p>{cancel}</p>
                            </div>
                        </div>
                      }
                    </>
                }
                {props.policy && cancel !== undefined && cancel == "Non Refundable" &&
                 <div className="info">
                 <strong>Cancellation Policy</strong>
                    <div className="plcyInfo">
                        <p>Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws.</p>
                    </div>
                    </div>
                }


                <div className="info">
                    {/* Importinfo(Remarks) */}
                    <strong>Important Information</strong>
                    <div className="plcyInfo">
                        {props.bookType!==undefined && props.bookType==="DIRECT" &&
                         <>
                         {remarks!==null && remarks.instructions!==null &&
                        <div dangerouslySetInnerHTML={{__html: remarks.instructions}} ></div>
                        }
                        {remarks!==null && remarks.specialInstructions!==null &&
                            <div dangerouslySetInnerHTML={{__html: remarks.specialInstructions}} ></div>
                        }
                         </>
                        }
                        {props.bookType!==undefined && props.bookType==="MANUAL" &&
                        <>
                        {remarks}
                        </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}


export default RoomPaxInfoPdf;
