import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: Integrated the Onhold configuration services
 * @author: Pawan
 * @param {*} req 
 */
class OnholdService {
    

    async getAirlineData() {
        return await axios.get(`${API_URL}/att/holdairline/retrieve`);
    }

    async saveAirline(holdData) {
        return await axios.post(`${API_URL}/att/holdairline/create`,holdData)
    }

    async updateAirline(holdData) {
        return await axios.put(`${API_URL}/att/holdairline/update/${holdData.id}`,holdData)
    }    
    async deleteAirline(data) {
        return await axios.delete(`${API_URL}/att/holdairline/delete`, {data})
    }
}

export default new OnholdService()