import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../App";
import Button from '../../common/buttons/Button';
import DateUtils from '../../commonUtils/DateUtils';
import { getCompanyTAList,getBranchList, getCompaniesList, getDsrReportData } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../common/loader/InnerLoader';
import NoRecord from '../../common/popUps/NoRecord';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../common/ReportMenu';
import InsuranceReportmenu from "../../common/subMenu/InsuranceReportmenu";
import CoomonExportExcel from '../../common/CoomonExportExcel';
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
let airportsData = require('../../masterData/airlinesData.json');


/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "", aplType: "", applToData: [], companyData: [], branchData: [], applTypData: [], travelData: [],bound_type:"",selBound_type:[], totals: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",bound_type:""
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air dsrdata List and Add Markup
function DSRReports(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enabelATT, setEnableATT] = useState(false);
  const [enabelTA, setEnableTA] = useState(false);
  const [enabelType, setEnableType] = useState(false);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [dsrdata, setDsrData] = useState({ dsrReportList: [] });
  const [dsrExceldata, setDsrExcelData] = useState({ dsrReportExcelList: [] });


  const applicabelTo = [{ label: "TA", value: 'TA' }, { label: "STA", value: 'STA' }]
  const boundType = [{ label: "Select", value: '' },{ label: "Inbound", value: 'Inbound' }, { label: "Outbound", value: 'Outbound' }]
  useEffect(() => {

    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      if(context.logindata.utype==="sta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
        dispatch({ type: 'aplTo', payload: "STA" })
      }else if(context.logindata.utype==="ta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
        setEnableTA(true)
      }
      dispatch({ type: 'userId', payload: context.logindata.uid })
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      getCompaniesList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'companies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
    //loadResponse();
  }, [context.logindata]);
  useEffect(()=>{
    if(dsrdata.dsrReportList.length !== 0){
dsrExceldata.dsrReportExcelList = dsrdata.dsrReportList
let restotal = state.totals
let total = {
  region_type:restotal.region_type,
  currency: restotal.region_type=="Qatar"?"QAR":"INR",
  transaction_type: "Grand Total",
  fare: restotal.tot_fare.toFixed(2),
  tax: restotal.tot_tax.toFixed(2),
  covid_ext: restotal.tot_covid_ext.toFixed(2),
  win_spo_ext : restotal.tot_win_spo_ext.toFixed(2),
  service_fee : restotal.tot_servicefee.toFixed(2),
  cost: restotal.tot_cost.toFixed(2),
  markup: restotal.tot_markup.toFixed(2),
  disc: restotal.tot_disc.toFixed(2),
  cc_charges : restotal.tot_ccharge.toFixed(2),
  selling_price: restotal.tot_selling_price.toFixed(2),
  profit: restotal.tot_profit.toFixed(2),
  supplier_fee: restotal.tot_supplier_fee.toFixed(2),
}
 let excelData = dsrExceldata.dsrReportExcelList.concat(total)
setDsrExcelData({dsrReportExcelList: excelData})
  }
  }, [dsrdata.dsrReportList] )
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateSearch=()=>{
    if(fromDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(toDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.aplTo!=="" && state.bid==="" && state.userType !== "sta_user"){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    return true;
  }

  //Handling the search creterai based pn the selection and dates
  const handleSearch = () => {
      if (validateSearch()){
        setDsrData({ dsrReportList: []})
        setDsrExcelData({dsrReportExcelList: []})
        dispatch({ type: 'totals', payload: "" })
        setIsLoadingMore(true)
        if(state.userType === "att_user" && state.aplTo===""){
          var boundType="";
          if(state.bound_type==="Inbound"){
            boundType=1;
          }else if(state.bound_type==="Outbound"){
            boundType=0;
          }
          const req = {
            "region_type":context.logindata.region_type,
            "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
            "branch_name": state.branchData.length!==0?state.branchData[0].label:"",
            "vtype": "MTA",
            "uid": state.userId,
            "cid": "",
            "utype":"att_user",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
            "bound_type":boundType!==""?Number(boundType):null,
            "rtype":null
          }
          getDsrReportData(req).then(response => {
            if(response.suc){
              dispatch({ type: 'totals', payload: response.res.totals })
              for(let val of response.res.data){
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
              }
              setDsrData({ dsrReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
              setDsrData({ dsrReportList: [] })
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
         if(state.cid!==0 && state.cid!==""){
          var userType = (state.userType === "ta_user") ? "TA" : "STA";
          var boundType="";
          if(state.bound_type==="Inbound"){
            boundType=1;
          }else if(state.bound_type==="Outbound"){
            boundType=0;
          }
          const request = {
            "region_type":context.logindata.region_type,
            "branch_id": state.branchData.length!==0?state.branchData[0].value:0,
            "branch_name": state.branchData.length!==0?state.branchData[0].label:"",           
            "vtype": (state.aplTo!=="" && state.aplTo!==undefined)?state.aplTo:userType,
            "uid": state.userId,
            "utype":state.userType,
            "cid": (state.cid !== 0) ? state.cid : "",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
            "bound_type":boundType!==""?Number(boundType):null,
            "rtype":null
         }
         getDsrReportData(request).then(response => {
          if(response.suc){
            dispatch({ type: 'totals', payload: response.res.totals })
            for(let val of response.res.data){
              val.currency = val.region_type=="Qatar"?"QAR":"INR"
            }
            setDsrData({ dsrReportList: response.res.data })
            setIsLoadingMore(false)
          }else{
            setIsLoadingMore(false)
            setDsrData({ dsrReportList: [] })
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
        }
        else{
        setIsLoadingMore(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        }
      }
      }
    }

  const setCompany = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'cid', payload: obj[0].value })
      dispatch({ type: 'companyData', payload: obj })
    }
  }

  const selBoundtype = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'bound_type', payload: obj[0].value })
      dispatch({ type: 'selBound_type', payload: obj })
    }
  }


  const resetSearch = () => {
    setEnableType(false);
    setEnableATT(false)
    setEnableTA(false)
    setFromDate("")
    setToDate("")
    dispatch({ type: 'selBound_type', payload: [] })
    dispatch({ type: 'applTypData', payload: [] })
    dispatch({ type: 'companyData', payload: [] })
    dispatch({ type: 'branchData', payload: [] })
    dispatch({ type: 'applToData', payload: [] })
  }


  const setAplTo = (obj) => {
    if (obj[0] !== undefined && obj[0].value !== undefined) {
      dispatch({ type: 'aplTo', payload: obj[0].value })
    }
    dispatch({ type: 'applToData', payload: obj })
    dispatch({ type: 'cid', payload: 0 })
    if (obj[0] !== undefined && obj[0].value === "TA") {
      // const pyload = { "type": state.userType, "userId": state.userId }
      setEnableATT(true);
      setEnableTA(false)
      //Load all branches based on for ATT
        const payload = {"region_type": (context.logindata.region_type),"uid":context.logindata.uid}
        AttCommonSerivce.loadBranches(payload).then(response => {
          if (response.data.suc){
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in Loadi branches based on Region function---" + error
          );
        });
    } else if (obj[0] !== undefined && obj[0].value === "STA") {
      // if (state.userType === "att_admin") {
        const pyload = { "type": state.userType, "userId": state.userId }
        setEnableATT(true);
        setEnableTA(false)
        const payload = {"region_type": (context.logindata.region_type),"uid":context.logindata.uid}
        AttCommonSerivce.loadBranches(payload).then(response => {
          if (response.data.suc){
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in Loadi branches based on Region function---" + error
          );
        });
      // }
    }else{
      setEnableATT(false);
      setEnableTA(false)
    }
  }

  const selectBranch = (obj) => {
    var branchId = obj[0].value;
    dispatch({ type: 'bid', payload: branchId })
    dispatch({ type: 'branchData', payload: obj })
    if (enabelATT) {
        getCompanyTAList(branchId,state.aplTo).then(response => {
          if (response.data.suc) {
            setEnableTA(true)
            dispatch({ type: 'companies', payload: response.data.res })
            dispatch({ type: 'aplTo', payload: "TA" })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      // } else {
      //   setEnableTA(false)
      //   console.log("Branch  : ");
      // }
    } else {
      setEnableTA(false);
    }
  }

  const columns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch_name',
      text: 'Branch',
     sort: false,
      filter: ""
    },
    {
      dataField: 'customer_name',
      text: 'Company',
     sort: false,
      filter: ""
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: ""
    },
    {
      dataField: 'document_date',
      text: 'Document Date',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_refno',
      text: 'BKR No',
     sort: false,
      filter: ""
    },
    {
      dataField: 'trip_type',
      text: 'Trip Type',
     sort: false,
      filter: ""
    },
    {
    dataField: 'plan_type',
    text: 'Plan Type',
    sort: false,
    filter: ""
  },
  {
    dataField: 'route',
    text: 'Route',
   sort: false,
    filter: ""
  },
  {
    dataField: 'document_no',
    text: 'Document Number',
    sort: false,
    filter: ""
  },
  {
    dataField: 'staff_name',
    text: 'Staff Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier',
    text: 'Supplier',
   sort: false,
    filter: ""
  },
  {
    dataField: 'sales_channel',
    text: 'Sales Channel',
   sort: false,
    filter: ""
  },
  {
    dataField: 'type_of_service',
    text: 'Type of Service',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_code',
    text: 'Customer Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_name',
    text: 'Customer Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_group',
    text: 'Customer Group',
   sort: false,
    filter: ""
  },
  {
    dataField: 'policy_number',
    text: 'Policy Number',
   sort: false,
    filter: ""
  },

  {
    dataField: 'pax_name',
    text: 'Pax Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'pax_type',
    text: 'Pax Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'noof_pax',
    text: 'No of Pax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'departure_date',
    text: 'Deparure Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'arrival_date',
    text: 'Arrival Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cover_start_date',
    text: 'Cover Start Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cover_end_date',
    text: 'Cover End Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'booking_status',
    text: 'Booking Status',
   sort: false,
    filter: ""
  },
  {
    dataField: 'transaction_type',
    text: 'Transaction Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'fare',
    text: 'Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'tax',
    text: 'Tax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'covid_ext',
    text: 'Covid Extension',
   sort: false,
    filter: ""
  },
  {
    dataField: 'win_spo_ext',
    text: 'Winter Sport Extension',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cost',
    text: 'Cost',
   sort: false,
    filter: ""
  },
  {
    dataField: 'markup',
    text: 'Markup',
   sort: false,
    filter: ""
  },
  {
    dataField: 'service_fee',
    text: 'Service Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'disc',
    text: 'Discount',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cc_charges',
    text: 'CC Charges',
   sort: false,
    filter: ""
  },
  {
    dataField: 'selling_price',
    text: 'Selling Price',
   sort: false,
    filter: ""
  },
  {
    dataField: 'profit',
    text: 'Profit',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier_fee',
    text: 'Supplier Fee',
   sort: false,
    filter: ""
  }

];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const csvHeaders = [
{ label: 'Region Type', key: 'region_type' },
{ label: 'Branch', key: 'branch_name' },
{ label: 'Company', key: 'customer_name' },
{ label: 'Currency', key: 'currency' },
{ label: 'Document Date', key: 'document_date' },
{ label: 'Booking Ref No', key: 'booking_refno' },
{ label: 'Trip Type', key: 'trip_type' },
{ label: 'Plan Type', key: 'plan_type' },
{ label: 'Document No', key: 'document_no' },
{ label: 'Supplier', key: 'supplier' },
{ label: 'Staff Naame', key: 'staff_name' },
{ label: 'Sales Channel', key: 'sales_channel' },
{ label: 'Type of Service', key: 'type_of_service' },
{ label: 'Customer Code', key: 'customer_code' },
{ label: 'Customer Name', key: 'customer_name' },
{ label: 'Customer Group', key: 'customer_group' },
{ label: 'Policy Number', key: 'policy_number' },
{ label: 'Pax Name', key: 'pax_name' },
{ label: 'Pax Type', key: 'pax_type' },
{ label: 'No of Pax', key: 'noof_pax' },
{ label: 'Departure Date', key: 'departure_date' },
{ label: 'Arrival Date', key: 'arrival_date' },
{ label: 'Cov Start Date', key: 'cover_start_date' },
{ label: 'Cov End Date', key: 'cover_end_date' },
{ label: 'Route', key: 'route' },
{ label: 'Booking Status', key: 'booking_status' },
{ label: 'Transaction Type', key: 'transaction_type' },
{ label: 'Fare', key: 'fare' },
{ label: 'Tax', key: 'tax' },
{ label: 'Covid Ext Fare', key: 'covid_ext' },
{ label: 'Winter Sport Fare', key: 'win_spo_ext' },
{ label: 'Service Fee', key: 'service_fee' },
{ label: 'Cost', key: 'cost' },
{ label: 'Markup', key: 'markup' },
{ label: 'Disc', key: 'disc' },
{ label: 'CC Charge', key: 'cc_charges' },
{ label: 'Selling Price', key: 'selling_price' },
{ label: 'Profit', key: 'profit' },
{ label: 'Supplier Fee', key: 'supplier_fee' }
];
const paxDate = (dt) => {
  // if(toDate){
    let oneYearDate= new Date(toDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() - 1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
//   }else{
//   return new Date(dt.setFullYear(dt.getFullYear()));
//  }
  // return new Date(dt.setFullYear(dt.getFullYear()));
}
const returnDate = ()=>{
  // if(fromDate){
  //   let beforecurrentDate = paxDate(new Date()).setDate(new Date().getDate() - 30)
  //   let fromdateadded = paxDate(fromDate).setDate(fromDate.getDate())
  //   if(fromdateadded < beforecurrentDate ){
      let oneYearDate= new Date(fromDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() + 1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
  //   }
  //  else{
  //    return new Date()
  //  }
  // }
  // else{
  //   return new Date()
  // }
}
  return (

    <>
     <ReportMenu active='Insurance Report' />
      <Breadcrumb activePage="DSR Report" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <InsuranceReportmenu active="Insurance DSR" />
      <div>
        <br/>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        {state.userType === "att_user" &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={applicabelTo}
                searchable={false}
                values={state.applToData}
                onChange={(value) => (setAplTo(value))}
              />
              <Form.Label>Applicable To</Form.Label>
            </Form.Group>
            {enabelATT &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.branches}
                  searchable={false}
                  values={state.branchData}
                  onChange={(value) => (selectBranch(value))}
                />
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
            }
            {enabelTA && (state.aplTo === "TA" || state.userType === "att_user") &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.companies}
                  searchable={false}
                  values={state.companyData}
                  onChange={(value) => (setCompany(value))}
                />
                <Form.Label>Company<sup>*</sup></Form.Label>
              </Form.Group>
            }
          </Form.Row>
        }
        {state.userType === "ta_user" && state.companies.length !== 0 &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={state.companies}
                searchable={false}
                values={state.companyData}
                onChange={(value) => (setCompany(value))}
              />
              <Form.Label>Company</Form.Label>
            </Form.Group>
          </Form.Row>
        }
        <Form.Row>
          <Form.Group as={Col} xs={3}>
            <Form.Row>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}
                    fromDate={fromDate}
                    toDate={toDate}
                    maxDate={ toDate ? toDate : new Date()}
                    minDate={toDate && paxDate(toDate).setDate(toDate.getDate()) }
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>From Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate && fromDate}
                    maxDate= {returnDate()}
                    className="datefield"
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>To Date<sup>*</sup></Form.Label>
                </Form.Group>
            </Form.Row>
          </Form.Group>
          <Form.Group className="selectplugin" as={Col} xs={3} controlId="bound_type">
              <Select
                options={boundType}
                searchable={false}
                values={state.selBound_type}
                onChange={(value) => (selBoundtype(value))}
              />
              <Form.Label>Trip Type</Form.Label>
            </Form.Group>
        </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
        <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={handleSearch}
        > Search</Button>
      </div>

      <h4 class="subTitle plusHr mt-3 mb-3">DSR Report</h4>
       {dsrExceldata.dsrReportExcelList!==undefined && dsrExceldata.dsrReportExcelList.length !== 0 &&
        <CoomonExportExcel data={dsrExceldata.dsrReportExcelList} headers={csvHeaders} show={true} name={"DSR_INS_Report.csv"}/>
       }
      <div className="cardMainlayout tableRes p-0">
      {isLoadingMore ? <InnerLoader /> : (<>
        {dsrdata.dsrReportList.length !== 0 &&
        <div className="tablescroll">
          <BootstrapTable
          bootstrap4
          keyField="id"
          classes="markup filterTbl"
          data={dsrdata.dsrReportList}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={dsrdata.dsrReportList.length >10 && dsrdata.dsrReportList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
          dsrdata.dsrReportList.length >25 && dsrdata.dsrReportList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
          dsrdata.dsrReportList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
          />
          {state.totals !== "" && <div className="cardMainlayout finalTotal insurTotal"><div className="react-bootstrap-table">
          <table className="table table-bordered markup filterTbl">
              <thead>
              <tr>
                   <th>&nbsp;</th>
                <th>Fare	</th>
                <th>Tax</th>
                <th>Covid Extension	</th>
                <th>Winter Sport Extension</th>
                <th>Cost</th>
                <th>Markup</th>
                <th>Service Fee</th>
                <th>Discount</th>
                <th>CC Charges</th>
                <th>Selling Price	</th>
                <th>Profit</th>
                <th>Supplier Fee	</th>
                </tr>
                </thead>
              <tr>
              <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_fare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_covid_ext.toFixed(2)}</td>
              <td>{state.totals.tot_win_spo_ext.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_servicefee.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_ccharge.toFixed(2)}</td>
              <td>{state.totals.tot_selling_price.toFixed(2)}</td>
              <td>{state.totals.tot_profit.toFixed(2)}</td>
              <td>{state.totals.tot_supplier_fee.toFixed(2)}</td>
            </tr>
            </table>
          </div></div>}
        </div>
          || dsrdata.dsrReportList.length === 0
          && <NoRecord />}</>)}
      </div>

              </div>
              </div>
<Footer/>
    </>
  )
}
export default DSRReports