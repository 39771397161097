
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import InnerLoader from '../../../common/loader/InnerLoader';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import MasterServicesMenu from '../../../common/subMenu/MasterServicesMenu';
import CreateUser from './index';
import { getUserSearch } from './operations';
export const UserContext = React.createContext();

let initialState = {
  uname: "", rto: "", fn: "", ln: "", role: "", notiMessageShow: false
}

//reducer
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This is common function for the User managment.
function UserManagement() {
  const history = useHistory();
  const [istate, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [state, setState] = useState({ res: [] })
  const [data, setData] = useState()
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [view, setView] = useState(false)
  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "") {
      let rq_Json = {
        "uid": context.logindata.uid,
        "utype" : context.logindata.utype,
        "is_admin":context.logindata.is_admin,
        "region_type":context.logindata.region_type
      }
      setIsLoadingMore(true);
      getUserSearch(rq_Json).then(response => {
        if (response.data.suc) {
          let data = []
          for(let val of response.data.res){
            val.role = val.role.split(",").join(", ")
            data.push(val)
          }
          loadAllUsers(data);
        }
        setIsLoadingMore(false);
      }).catch(function (error) {
        log.error(
          "Exception occured in getUserData function---" + error
        );
      });
    }
  }, [context.logindata]);

  const createUserBtnClick = (e) => {
    setState({ CreateUser: true })
  }
  const editUser = (obj, type) => () => {
    setState({ CreateUser: true })
    setData(obj)
    if (type === "view") {
      setView(true)
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }
  //Loading all users
  const loadAllUsers = (res) => {
    setIsLoadingMore(false);
    let data = [];
    var enableEdit = false;
    if (context.logindata.roles.includes('U_M_U')) {
      enableEdit = true;
    }
    let response = res
    if(context.logindata.utype == "att_user") {
      let res_response = []
      for (let val of res) {
        if(val.utyp !== "sta_user") {
          res_response.push(val)
        }
      }
      response = res_response
    }
    for (let value of response) {
      var userEdit = true;
     if (context.logindata.uid==value.uid){
      userEdit= false
      }
      if (context.logindata.is_admin==1 || context.logindata.region_type==value.region_type || (value.region_type && value.region_type.includes(",")) ){
      data.push({ uemail: value.uem,region:value.region_type, cname : value.cname, branch :<div className="roleValuee" title={value.branch}>{value.branch}</div> , type : value.type, role: <div className="roleValuee" title={value.role}>{value.role}</div>, name: value.ufn + " " + value.uln, reprting: value.rpunm, status: (value.act) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editUser(value, "view")}></a>{enableEdit && userEdit && <a className="ezyIcon icon-edit" onClick={editUser(value, "edit")}></a>}</span> </> })
    }
  }
    setState({ res: data })
  }
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const columns = [{
    dataField: 'uemail',
    text: 'User Email',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'region',
    text: 'Region',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cname',
    text: 'Company',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'branch',
    text: 'Branch' ,
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'type',
    text: 'Staff Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'role',
    text: 'Role of the User',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'reprting',
    text: 'Reporting To',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];


  /**
  * @description: reset the search criteria
  * @author: Azamuddin
  * @date : 18-11-2020
  */
  //reset the search criteria
  const resetSearch = () => {
    dispatch({ type: 'uname', payload: "" })
    dispatch({ type: 'rto', payload: "" })
    dispatch({ type: 'role', payload: "" })
    dispatch({ type: 'fn', payload: "" })
    dispatch({ type: 'ln', payload: "" })
  }
  /**
   * @description: get records with search criteria
   * @author: Azamuddin
   * @date : 18-11-2020
   */
   function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const searchUser = () => {
    if (istate.uname !== "" || istate.rto !== "" || istate.role !== "" || istate.fn !== "" || istate.ln !== "") {
      let sr_json = {
        uname: istate.uname,
        rto: istate.rto,
        role: istate.role,
        fn: istate.fn,
        ln: istate.ln,
        "utype" : context.logindata.utype,
        "is_admin":context.logindata.is_admin,
        "region_type":context.logindata.region_type
      }
      getUserSearch(sr_json).then(response => {
        if (response.data.suc) {
          let data = []
          for(let val of response.data.res){
            val.role = val.role.split(",").join(", ")
            data.push(val)
          }
          loadAllUsers(data);
        } else {
          dispatch({ type: 'notiMessage', payload: "No Records Found" })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getUserData function---" + error
        );
      });
    } else {
      dispatch({ type: 'notiMessage', payload: "Please Enter Alteast One field*" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }
  }
  return (
    <>
      <Alert className="notification" variant={istate.notiVarient} show={istate.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {istate.notiMessage}</Alert>
      <MainMenu active='Master Services' />
      <Breadcrumb activePage="User Management" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <MasterServicesMenu active="User Management" />
          <div className="contentArea">

            {!state.CreateUser ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="uname">
                    <Form.Control type="text" placeholder="User Name" value={istate.uname} onChange={handleChange} autoComplete="off" />
                    <Form.Label>User Name</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="rto">
                    <Form.Control type="text" placeholder="Reporting To" value={istate.rto} onChange={handleChange} autoComplete="off" />
                    <Form.Label>Reporting To</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="role">
                    <Form.Control type="text" placeholder="User Role" value={istate.role} onChange={handleChange} autoComplete="off" />
                    <Form.Label>User Role</Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="fn">
                    <Form.Control type="text" placeholder="First Name" value={istate.fn} onChange={handleChange} autoComplete="off" />
                    <Form.Label>First Name</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="ln">
                    <Form.Control type="text" placeholder="Last Name" value={istate.ln} onChange={handleChange} autoComplete="off" />
                    <Form.Label>Last Name</Form.Label>
                  </Form.Group>
                </Form.Row>


                <div className="buttonGrop pb-0 d-flex justify-content-between">
                  {context.logindata.roles && context.logindata.roles.split(",").includes('U_M_C')&&<div><Button
                    size="xs"
                    variant="primary m-0"
                    onClick={createUserBtnClick}
                    type="submit"
                    className="addPlus ezyIcon icon-plus"
                  >  Create User</Button></div>}

                  <div><Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={resetSearch}
                  > Reset</Button>
                    <Button
                      size="xs"
                      variant="outline-primary"
                      type="submit"
                      onClick={searchUser}
                    > Search</Button></div>
                </div>

              </>) : (
                <>
                  <UserContext.Provider value={data}>
                    <CreateUser view={view} />
                  </UserContext.Provider>
                </>
              )}


          </div>
        </div>
        {(!state.CreateUser) ? (
          <div className="cardMainlayout p-0 usermanagmenttablr tableRes">
            {isLoadingMore ? <InnerLoader /> : (<>
              {state.res.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                  
                />
                || state.res.length === 0 &&
                <NoRecord />}</>)}

          </div>
        ) : ('')}

      </div>
      <Footer />
    </>

  )
}

export default UserManagement