
import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Alert, Col, Row } from "react-bootstrap";
import Utilities from '../../../../commonUtils/Utilities';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';


/**
 * Initial State Declaration
 */
const initialState = {
    price_data: '',
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

function FareDetails(props) {

    const [state, dispatch] = useReducer(reducer, initialState);


    useEffect(() => {

        dispatch({ type: 'price_data', payload: props.priceDetails })

    }, [])


    const calATTGst=(pr,type)=>{
    let gst=0;
    if(type==="ATT"){
        if(pr.gst!==undefined && pr.gst.type==="same" && pr.gst.gst_invoice!==undefined && pr.gst.gst_invoice.length!==0){
            gst = parseFloat(pr.gst.gst_invoice[0].cgst_val) + parseFloat(pr.gst.gst_invoice[0].sgst_val)
        }else if(pr.gst!==undefined && pr.gst.gst_invoice!==undefined && pr.gst.gst_invoice.length!==0){
            gst = parseFloat(pr.gst.gst_invoice[0].igst_val)
        }
    }else{
        if(pr.gst!==undefined && pr.gst.type==="same" && pr.gst.gst_invoice!==undefined && pr.gst.gst_invoice.length!==0){
            if(pr.gst!==undefined && pr.gst.gst_invoice.length > 1){
                gst = parseFloat(pr.gst.gst_invoice[1].cgst_val) + parseFloat(pr.gst.gst_invoice[1].sgst_val)
            }
        }else if(pr.gst!==undefined && pr.gst.gst_invoice!==undefined && pr.gst.gst_invoice.length!==0){
            if(pr.gst.gst_invoice.length > 1){
            gst = parseFloat(pr.gst.gst_invoice[1].igst_val)
            }
        } 
    }
    return gst;
    }



    return (
        <>


            <div className="header">
                <Row>
                    <Col>Base Fare</Col>
                    <Col>Taxes & Fee</Col>
                    <Col>Sales Tax</Col>
                    <Col>Service Fee</Col>
                    {props.currency==="INR" &&
                    <Col>GST</Col>
                    }
                    <Col>Discount</Col>
                    <Col>Total Price</Col>
                </Row>
            </div>
            <div className="passengerList">
            {state.price_data.rmpr!==undefined && state.price_data.rmpr[0] !==undefined &&
                <Row>
                   {/* Basefare */}
                    <Col>{props.currency}  <FormateCurrency value={state.price_data.rmpr[0].pr[0].bf + props.roomMarkup + Utilities.getServiceFee(state.price_data.rmpr[0].servicefee,props.butype) - state.price_data.rmpr[0].pr[0].com_disc} /></Col>

                    {/* Taxes */}
                     {props.currency!=="INR" &&
                    <Col>{props.currency} <FormateCurrency value={Utilities.hotelTaxCalculation(state.price_data.rmpr[0].pr[0].tax)} /></Col>
                     }
                    {props.currency==="INR" &&
                    <Col>{props.currency} <FormateCurrency value={Utilities.hotelTaxCalculation(state.price_data.rmpr[0].pr[0].tax) + parseFloat(state.price_data.rmpr[0].pr[0].tds_val) + parseFloat(calATTGst(state.price_data.rmpr[0].pr[0],"ATT"))} /></Col>
                     }

                    {/* Sales Tax */}
                    {Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)!==0 &&
                    <Col>{props.currency} <FormateCurrency value={Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)} /></Col>
                    }
                    {Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)===0 &&
                    <Col> N/A</Col>
                    }
                    {/* ServiceFee */}
                    <Col>{props.currency} <FormateCurrency value={Utilities.calHotelServiceFee(state.price_data.rmpr[0].servicefee,props.butype,"Not")} /></Col>
                    
                    {props.currency==="INR" &&
                    <Col>{props.currency} <FormateCurrency value={calATTGst(state.price_data.rmpr[0].pr[0],"TA")} /></Col>
                    }

                     {/* Discount */}
                     <Col>{props.currency} <FormateCurrency value={Utilities.hotelDiscount(state.price_data.rmpr[0].pr[0].tax,props.butype)} /></Col>

                    {/* TotalFare */}
                    <Col>{props.currency} <FormateCurrency value={(Number(state.price_data.rmpr[0].pr[0].tf - state.price_data.rmpr[0].pr[0].com_disc)-Utilities.hotelDiscount(state.price_data.rmpr[0].pr[0].tax,props.butype) + Number(props.roomMarkup) + Number(state.price_data.rmpr[0].pr[0].gst_total) + Number(Utilities.calHotelServiceFee(state.price_data.rmpr[0].servicefee,props.butype,"All")))} /> </Col>
                </Row>
                }
            </div>
<div className='mobileRoominfo'>
            <div className="passengerList">
            {state.price_data.rmpr!==undefined && state.price_data.rmpr[0] !==undefined &&
                <Row>
                   {/* Basefare */}
                    <Col> <span>Base Fare : </span> {props.currency}  <FormateCurrency value={state.price_data.rmpr[0].pr[0].bf + props.roomMarkup + Utilities.getServiceFee(state.price_data.rmpr[0].servicefee,props.butype)} /></Col>

                    {/* Taxes */}
                    <Col><span>Taxes & Fee :</span> {props.currency} <FormateCurrency value={Utilities.hotelTaxCalculation(state.price_data.rmpr[0].pr[0].tax)} /></Col>
                     {/* Sales Tax */}
                    {Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)!==0 &&
                    <Col><span>Sales Tax : </span> {props.currency} <FormateCurrency value={Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)} /></Col>
                    }
                    {Utilities.hotelSTaxCalculation(state.price_data.rmpr[0].pr[0].tax)===0 &&
                    <Col> N/A</Col>
                    }
                    {/* ServiceFee */}
                    <Col> <span>Service Fee :</span> {props.currency} <FormateCurrency value={Utilities.calHotelServiceFee(state.price_data.rmpr[0].servicefee,props.butype,"Not")} /></Col>

                     {/* Discount */}
                     <Col> <span>Discount :</span> {props.currency} <FormateCurrency value={Utilities.hotelDiscount(state.price_data.rmpr[0].pr[0].tax,props.butype)} /></Col>

                    {/* TotalFare */}
                    <Col> <span>Total Price :</span> {props.currency} <FormateCurrency value={(Number(state.price_data.rmpr[0].pr[0].tf) + Number(props.roomMarkup) + Number(Utilities.calHotelServiceFee(state.price_data.rmpr[0].servicefee,props.butype,"All")))} /> </Col>
                </Row>
                }



            </div>
            </div>

        </>
    )
}


export default FareDetails;
