

import { apiGetHelper, apiPostHelper } from '../../../commonUtils/helper';
import commonService from '../../../../services/commonService/AttCommonSerivce';


//import response from './response.json';
/**
 * @author: Rambabu
 * @description: Fetch Flight booking summary details resepective booking id
 * @param {*} req 
 * @date:16-09-2020  X
 */

const API_HOTEL_URL = process.env.REACT_APP_PRE_BOOK_URL
const API_HOTEL_BOOK_URL=process.env.REACT_APP_HOTEL_BOOK_URL
const API_URL = process.env.REACT_APP_B2B_URL
// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const getHtlSummaryData= async (refNum) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiGetHelper(`${API_HOTEL_URL}/postbook/summaryretrieve/`+refNum,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const processEditBooking = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_URL}/postbook/summary/hotel_edit`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
//Cancel Price Service
export const checkCancelPrice = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_BOOK_URL}/hotelpostbook/htlrefundpricing`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Cancel Service
export const processCancelService = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_BOOK_URL}/hotelpostbook/htlrefundprocessing`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Get Parent Company Data
// ========================================================================
export const getParentCompData= async (id) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiGetHelper(`${API_URL}/att/common/getcompanyaddress/`+id,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
/**
 * @description : validate summary 
 * @author : Azamuddin,
 * @date : 27-05-2021
 */
 export const validateBookingSummary = async(req) => {
    let response = '';
    await commonService.validateBookingSummary(req).then(resp => {
        if(resp) {
            response = resp
        }
    })
    return Promise.resolve(response)
}

//Cancel Price Service
export const getHotelReviewDetails = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_URL}/hotel/getpricecheckfetch`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Cancel Service
export const processHoldCancelService = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_BOOK_URL}/hotel/onhold_cancel`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Cancel Price Service
export const processHotelRefund = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_HOTEL_BOOK_URL}/hotelpostbook/htlmanualrefund`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}