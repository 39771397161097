import React from 'react';

import Paymentprocessingimg from '../../../assets/images/Payment-Processing.gif'
/**
 * @description:This component will work for processing popUp .
 * @author: Pravas
 * @param {*}
 * @function Processing
 * @date : 01-09-2020
 */
function Processing(props) {

  return (
    <>
          <div className="layover zIndex999">
            <div className="popUpBox processing">
            <div className="backGroundgif">
<span className='backGroundbot'></span>
<span className='aarabQar'>QAR</span>
<img src={Paymentprocessingimg} />
            </div>
              {/* <strong>Please wait...</strong> */}
              {
                props.onhold !== undefined && props.onhold == "yes" ? (
                  <p className="processInfo">Validating ticket eligibility.</p>
                ) : (
                  <p className="processInfo"><strong>We are processing payment</strong></p>
                )
              }

              <p className="m-0">Do not refresh the page, please wait</p>

            </div>
          </div>

    </>
  )
}
export default Processing