import { apiPostHelper } from '../../../components/commonUtils/helper';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH
const PRE_BOOK = process.env.REACT_APP_B2B_URL
/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @param {*}
 * @function Insurance flow
 * @date : 20-12-2021
 */
 export const fireSearch = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/insurance/search`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const checkSelPlan = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/insurance/net_premium`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const saveBooking = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${PRE_BOOK}/att/insurance/insurance-prebook`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Get Updated CL  
// ========================================================================
export const getCrediLimit = async (req) => {
    let clRes = [];
    await AttCommonSerivce.getUpdateCreditLimit(req).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}

