
/**
 * @description: Flight Summary page Flight details  section sector data
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useEffect, useReducer } from 'react';
 import { Col, Row } from "react-bootstrap";
 import { useHistory } from 'react-router-dom';
 import DateUtils from "../../commonUtils/DateUtils";
 import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
 import FlightSummarySegmentDetails from './FlightSummarySegmentDetails';

 const _ = require('lodash')

 /**
  * Initial State Declaration
  */
 const initialState = {
     source: '', destination: '', duration: '', travel_type: '', departure_date: '',multi_city_arr:[]
 };

 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };

 /**
  * @description:This function will Display the the Sector detals
  * @author: Rambabu
  * @param {*}
  * @function Flight sector details
  * @date : 18-09-2020
  */
 function FlightSummarySectorHeader(props) {

     const [state, dispatch] = useReducer(reducer, initialState);
     const history = useHistory();


     useEffect(() => {

         console.log('FlightSummarySectorHeader data  ---' + JSON.stringify(props.data));

         console.log('FlightSummarySectorHeader airport_data is  ---' + JSON.stringify(props.airport_data));

         if (isArrayNotEmpty(props.data)) {
             let src_ = props.data[0].seg_source;
             let des_ = props.data[props.data.length - 1].seg_destination;
             let travel_type_ = '';
             let duartionn = '';
             let departure_date_ = '';
             //console.log('src----' + src_)
             //console.log('des----' + des_);
             if (isNotNull(props.data[0].journey_type) && props.data[0].journey_type === "O") {
                 travel_type_ = 'Departure';
             } else if (isNotNull(props.data[0].arrival_time) && props.data[0].journey_type === "R") {
                 travel_type_ = 'Arrival';
             }
             //console.log('props.data[0].arrival_time---' + props.data[0].depart_time)
             //console.log('props.data[props.data.length - 1].depart_time---' + props.data[props.data.length - 1].arrival_time)
            //  duartionn = DateUtils.calculateHoursandMinsbetweendates(props.data[props.data.length - 1].arrival_time, props.data[0].depart_time);
             departure_date_ = DateUtils.prettyDate(props.data[0].depart_time, "ddd, DD MMM, YYYY");
             //duration handle
             if(props.data!==undefined &&  props.data.length!==0 && props.data[0].duration) {
                 let duration = props.data[0].duration
                 if(Number(duration)) {
                     duartionn = duration < 10 ? " 0" + duration +" hrs 00 mins" : duration +" hrs 00 mins"
                 }
                 if(duration.includes(":")) {
                let durTag = props.data[0].duration.split(":")
                if(durTag.length > 0) {
                duartionn = durTag[0] +" hrs "+durTag[1] +" mins"
                }
            }
             }

             dispatch({ type: 'source', payload: src_ })
             dispatch({ type: 'destination', payload: des_ })
             dispatch({ type: 'duration', payload: duartionn })
             dispatch({ type: 'travel_type', payload: travel_type_ })
             dispatch({ type: 'departure_date', payload: departure_date_ })


             //multicity data construction

             if(props.triptype == "3") {
                 if(props.data.length > 0) {
                    let initData = props.data
                    if(props.sectorTab == "IMPORT") {
                      let  new_seg_details =  constructSegmentForImport(props.data)
                      if(new_seg_details !== null && new_seg_details.length > 0) {
                          initData = new_seg_details
                      }
                    }
                   else if(props.sectorTab !== undefined && props.sectorTab == "REISSUE") {
                      let  new_seg_details =  constructSegmentForImport(props.data)
                      if(new_seg_details !== null && new_seg_details.length > 0) {
                          initData = new_seg_details
                      }
                    }
                   else if(props.sectorTab !== undefined && props.sectorTab == "MANUAL/REISSUE") {
                      let  new_seg_details =  constructSegmentForImport(props.data)
                      if(new_seg_details !== null && new_seg_details.length > 0) {
                          initData = new_seg_details
                      }
                    }
               let arr =  _.groupBy(initData,"trip_type" )
               const propertyNames = Object.values(arr);
               
              dispatch({type:"multi_city_arr",payload:propertyNames})
                 }

             }
         }


     }, []);

     /**
      * @author :Azamuddin
      * @param { req } tripduration handling request
      * @returns response
      * @functions : handle trip duration
      */

    /*   const handleTripDuration = (req) => {
        let duartionn = ""
       let duration = req
       if(Number(duration)) {
           duartionn = duration < 10 ? " 0" + duration +" hrs 00 mins" : duration +" hrs 00 mins"
       }
       if(duration.includes(":")) {
      let durTag = req.split(":")
      if(durTag.length > 0) {
      duartionn = durTag[0] +" hrs "+durTag[1] +" mins"
      }
  }
   return duartionn
    } */
     
/**
 * @Author : Azamuddin 
 * @date : 11-08-2021
 * @description : construction of segments in placement of business with jira id 2313 
 
 */
const constructSegmentForImport = (data) => {
    let seg_data = data //segment details

    // scenarios->

    if(seg_data.length > 0 && seg_data.length === 2) {
        //check if its like one way with connectivity
      let duration =  DateUtils.msToTime(Math.abs(new Date(seg_data[1].depart_time) - new Date(seg_data[0].arrival_time))) //layover time
      let dayExceedTag = false
      if(duration.includes("day") || duration.includes("days") ) {
        dayExceedTag = true
      }
    //   if(dayExceedTag) {
        if(seg_data[0].seg_destination == seg_data[1].seg_source) { //checking arrival of first segment and departure of second segment
            if(seg_data[0].seg_source == seg_data[1].seg_destination) { //checking departure of first segment and arrival of first segment
                seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //no changes
            } else {
                if(dayExceedTag) {
                    seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                    seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //changes
                } else {
                    return null //no changes
                }
            }
        } else {
            if(seg_data[0].seg_source == seg_data[1].seg_destination) {
                seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //changes
            } else {
                return null //no changes
            }
        }
   
    } else {
        let count = 1 //intial count of trip type
        let segment_number = 1
        //iterate each of the seg to handle same condition as above
        for(let indexOf in seg_data) {
            let current_seg = seg_data[indexOf] //iterative current segment
            let next_seg = null
            if(seg_data[Number(indexOf) + 1]) {
            next_seg = seg_data[Number(indexOf) + 1] //next segment
            }
            //****condition to check whether next segment is available or not *****/
            if(next_seg) {
                //if current segment arrival === next segment destination
                // if(current_seg.seg_destination == next_seg.seg_source) {
                    let duration =  DateUtils.msToTime(Math.abs(new Date(next_seg.depart_time) - new Date(current_seg.arrival_time))) //layover time
                    let dayExceedTag = false
                      if(duration.includes("day") || duration.includes("days") || duration.includes("d") ) {
                         dayExceedTag = true
                        }
                        //this means its more than or equal to 24 hrs
                        if(dayExceedTag) {
                            count = count + 1
                            segment_number = 1
                        }
                        let next_seg_count = count
                        next_seg.trip_type = next_seg_count //assiginng new trip type(AKA segment header count) to show as new departure
                        if(dayExceedTag) {
                        next_seg.seg_order = 1 // assigning inital seg order
                        } else {
                            segment_number = segment_number + 1
                            next_seg.seg_order = segment_number
                        }
                // }
            }
        }
              return seg_data //changes depends on layover time
          }
}
     /**
      * @description:This function will Display the the Segment details
      * @author: Rambabu
      * @date : 02-10-2020
      * @param {*} code
      * @param {*} val
      */
     function getAirportdata(code, val) {

         if (isNotNull(props.airport_data) && isNotNull(code)) {
             //console.log('getAirportdata------------' + code);
             //console.log('props.airport_data------------' + props.airport_data);

             for (let a in props.airport_data) {

                 let air_data = props.airport_data[a];

                 // console.log('air_data-----------' + JSON.stringify(air_data));

                 if (isNotNull(air_data.airportCode)) {

                     if (air_data.airportCode === code && val === 'c') {

                         //  console.log('obj.cityName------------' + air_data.cityName);
                         return air_data.cityName;

                     } else if (air_data.airportCode === code && val === 'a') {
                         //  console.log('obj.airportName------------' + air_data.airportName);

                         return air_data.airportName;
                     }
                 }
             }
         }
     }

        /**
      * @author :Azamuddin
      * @param { req } tripduration handling request
      * @returns response
      * @functions : handle trip duration
      */
  const handleTripDuration = (req) => {
            let duartionn = ""
           let duration = req
           if(Number(duration)) {
               duartionn = duration < 10 ? " 0" + duration +" hrs 00 mins" : duration +" hrs 00 mins"
           }
           if(duration.includes(":")) {
          let durTag = req.split(":")
          if(durTag.length > 0) {
          duartionn = durTag[0] +" hrs "+durTag[1] +" mins"
          }
      }
       return duartionn
        }




     return (
         <>
         {props.triptype !== "3"  ? (
             <div className="rs-headerBlock mt-3 mb-4">
                 <Row className="align-items-center rs-row">

                     <Col xs={3} className="rs-rTypeDate">
                         <span className={state.travel_type === "Return" ? "departure arrival" : "departure "} >
                             <span className="ml-2 mr-2 ezyIcon icon-flight"></span>
                             {state.travel_type}</span>
                         <span>{state.departure_date}</span>
                     </Col>
                     <Col xs={7} className="rs-FromTo">
                         <Row className="align-items-center">
                             <Col className="d-flex flex-column">
                                 <span>{getAirportdata(state.source, 'c')} ({state.source}),</span>
                                 <span>{getAirportdata(state.source, 'a')}</span>
                             </Col>
                             <Col xs="1" className="p-0"><span className="ezyIcon icon-flight"></span></Col>
                             <Col className="d-flex flex-column">
                                 <span>{getAirportdata(state.destination, 'c')} ({state.destination}),</span>
                                 <span>{getAirportdata(state.destination, 'a')}</span>
                             </Col>
                         </Row>

                         <span>

                         </span>
                     </Col>
                     <Col xs={2}><span>Trip Duration : {state.duration}</span></Col>
                 </Row>
             </div>
         ):(
             <>
             {state.multi_city_arr.length > 0 && state.multi_city_arr.map((sec,index) => (
                 <>
                             <div className="rs-headerBlock mt-3 mb-4">
                             <Row className="align-items-center rs-row">

                                 <Col xs={3} className="rs-rTypeDate">
                                     <span className={state.travel_type === "Return" ? "departure arrival" : "departure "} >
                                     <span className="ml-2 mr-2 ezyIcon icon-flight"></span>
                                         {state.travel_type}</span>
                                     <span>{DateUtils.prettyDate(sec[0].depart_time, "ddd, DD MMM YYYY")}</span>
                                 </Col>
                                 <Col xs={7} className="rs-FromTo">
                                     <Row className="align-items-center">
                                         <Col className="d-flex flex-column">
                                             <span>{getAirportdata(sec[0].seg_source, 'c')} ({sec[0].seg_source}),</span>
                                             <span>{getAirportdata(sec[0].seg_source, 'a')}</span>
                                         </Col>
                                         <Col xs="1" className="p-0"> <span className="ezyIcon icon-flight"></span></Col>
                                         <Col className="d-flex flex-column">
                                             <span>{getAirportdata(sec[0].seg_destination, 'c')} ({sec[0].seg_destination}),</span>
                                             <span>{getAirportdata(sec[0].seg_destination, 'a')}</span>
                                         </Col>
                                     </Row>

                                     <span>

                                     </span>
                                 </Col>
                                 {props.sectorTab !== "IMPORT" && 
                                 <Col xs={2}><span>Trip Duration : {handleTripDuration(sec[0].duration)}</span></Col>
                                 }
                             </Row>
                         </div>
                         {props.triptype == "3" && (<FlightSummarySegmentDetails data={sec} airport_data={props.airport_data} supplier={props.supplier} isEdit={props.isEdit} />)}
                         </>
             ))}
             </>
         )}
         </>
     )
 }


 export default FlightSummarySectorHeader;
