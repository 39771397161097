import React, { useEffect, useState,useContext } from "react";
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Context } from "../../../../../App";
import FormateCurrency from "../../../commonUtils/FormateCurrency";

function RoomPriceDetails(props) {
  const [state, setState] = useState({});
  const [roomprice, setRoomPrice] = useState();
  const [context, setContext] = useContext(Context);
  const [cur, setCur] = useState(props.currency);

  useEffect(() => {
    //Cal MTA
    if (props.type !== undefined) {
      setRoomPrice(props.roomObj.rmpr);
    }
  }, []);

  return (
    <>
    <div className="resultSection PassengerDetails boxShadow bg-white summary ml-2">
            <h5 className="title d-flex justify-content-between">
              Price Details({cur})
            </h5>

      <Row className="hotelroomprc m-0">


        <Col className="fareTableLayout p-0 pr-2 flex-column">
          <div className="titlePurchasessale">
          <h5 className="title d-flex justify-content-between">
          Purchase
            </h5>
            <h5 className="title d-flex justify-content-between">
            Sales
            </h5>
          </div>
          <div className="d-flex">
          <Table className="fareTable noHover mb-0 ">
            <thead>
              <tr>
                <th>Base Fare</th>
                <th>Taxes</th>
                <th>Sales Tax</th>
                {props.type === "MTA" && <th>Commission</th>}
                {props.type !== "MTA" && <th>Supplier Fee</th>}
                {props.type !== "MTA" && <th>Discount</th>}
                <th className="text-right">Purchase</th>
              </tr>
              {/* <tr>
             <td colSpan="5" className="space"> <span></span></td>
             </tr> */}
            </thead>
            <tbody>
              {roomprice !== undefined && (
                <>
                  <tr>
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.purpr.bf} />
                      </span>
                    </td>
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.purpr.tax + (roomprice.purpr?.gst!==undefined?roomprice.purpr.gst:0)} />
                      </span>
                    </td>
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.purpr.sales_tax} />
                      </span>
                    </td>
                    {props.type === "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency
                            value={roomprice.purpr.marketingFee}
                          />
                        </span>
                      </td>
                    )}
                    {props.type !== "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.purpr.servicefee} />
                        </span>
                      </td>
                    )}
                    {props.type === "TA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.purpr.mtadisc} />
                        </span>
                      </td>
                    )}
                    {props.type === "STA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.purpr.tadisc} />
                        </span>
                      </td>
                    )}
                    <td className="totalPrice tdborder">
                      <span className="curformate justify-content-end">
                        <FormateCurrency value={roomprice.purpr.tf} />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td
                      colSpan={props.type !== "MTA" ? "6" : "5"}
                      className="ttl tdborder"
                    >
                      <span className="curformate justify-content-end">
                        Total Purchases :{" "}
                        <span className="pr-1 pl-1">{cur}</span>{" "}
                        <FormateCurrency value={roomprice.purpr.tf} />
                      </span>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          <Table className="fareTable noHover mb-0">
            <thead>
              <tr>
                <th>Base Fare</th>
                <th>Taxes</th>
                <th>Sales Tax</th>
                <th>Markup</th>
                {props.type !== "MTA" && <th>OnFly Markup</th>}
                {props.type !== "MTA" && <th>Supplier Fee</th>}
                <th>Service Fee</th>
                {cur==="INR" &&
                <th>GST</th>
                }
                {props.type === "MTA" && cur==="INR" &&
                <th>TDS</th>
                }
                <th>Discount</th>
                {props.type === "MTA" && <th>Agent Discount</th>}
                <th className="text-right">Sales</th>
              </tr>
              {/* <tr>
             <td colSpan={props.type!=="MTA" ? '7':'6'} className="space"> <span></span></td>
             </tr> */}
            </thead>
            <tbody>
              {roomprice !== undefined && (
                <>
                  <tr>
                    <td className="tdborder">
                      <span className="curformate">
                        <FormateCurrency value={roomprice.salespr.bf} />
                      </span>
                    </td>
                    <td className="tdborder">
                      <span className="curformate">
                        <FormateCurrency value={roomprice.salespr.tax} />
                      </span>
                    </td>
                    <td className="tdborder">
                      <span className="curformate">
                        <FormateCurrency value={roomprice.salespr.sales_tax} />
                      </span>
                    </td>
                    <td className="tdborder">
                      <span className="curformate">
                        <FormateCurrency value={roomprice.salespr.markup} />
                      </span>
                    </td>
                    {props.type !== "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.onflymkp} />
                        </span>
                      </td>
                    )}
                    {props.type !== "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.sup_fee} />
                        </span>
                      </td>
                    )}
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.salespr.servicefee} />
                      </span>
                    </td>
                    {cur==="INR" && roomprice.salespr.gst!==undefined &&
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.salespr.gst} />
                      </span>
                    </td>
                    }
                    {props.type === "MTA" && cur==="INR" && roomprice.salespr.tds_val!==undefined &&
                    <td className="tdborder">
                      <span className="curformate">
                        {" "}
                        <FormateCurrency value={roomprice.salespr.tds_val} />
                      </span>
                    </td>
                    }
                    {props.type === "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.mtadisc} />
                        </span>
                      </td>
                    )}
                    {props.type === "TA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.tadisc} />
                        </span>
                      </td>
                    )}
                    {props.type === "STA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.stadisc} />
                        </span>
                      </td>
                    )}
                    {props.type === "MTA" && (
                      <td className="tdborder">
                        <span className="curformate">
                          {" "}
                          <FormateCurrency value={roomprice.salespr.com_disc} />
                        </span>
                      </td>
                    )}
                    <td className="totalPrice tdborder">
                      <span className="curformate justify-content-end">
                        <FormateCurrency value={roomprice.salespr.tf} />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ttl tdborder"
                      colSpan={props.type !== "MTA" ? "12" : "12"}
                    >
                      <span className="curformate justify-content-end">
                        Total Sales : <span className="pr-1 pl-1">{cur}</span>{" "}
                        <FormateCurrency value={roomprice.salespr.tf} />
                      </span>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          </div>
        </Col>
      </Row>
      </div>
    </>
  );
}

export default RoomPriceDetails;
