import React from 'react';

function ResultBlockLoader(props) {
    return (
        <>
            <div class="fltLegLoad"></div>

        </>
    )
}


export default ResultBlockLoader;
