
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
function DashBordOffer(props) {
    const [state, setState] = useState({})


    return (
        <>
            <Scrollbars style={{ height: 480 }}>
                <div className="dashBordOffer pr-3">
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate ezyIcon icon-offer">
                            <p className="m-0">Dubai  <span className="ezyIcon icon-rightArrow ml-2 mr-2"></span> Riyadh</p>
                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-rightArrow ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-rightArrow ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                    <div className="alertListDetails d-flex justify-content-between align-items-center">
                        <div className="alertDate  ezyIcon icon-offer">

                            <p className="m-0">Dubai  <span className="ezyIcon icon-fromTo ml-2 mr-2"></span> Riyadh</p>

                        </div>
                        <span>12/02/2020</span>
                    </div>
                </div>
            </Scrollbars>
        </>
    )
}


export default DashBordOffer;
