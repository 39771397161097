import React from 'react';
import { Table } from "react-bootstrap";

//This function will show the Air markups List and Add Markup
//Initial State

  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================


function InternationalContent(props) {
return(
  <>
<Table>
<thead>
  <tr>
    <th>Fare Type</th>
    <th>Saver</th>
    <th>Flexi</th>
    {props.isCorporate && <th>Corporate</th>}
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><strong>{props.showJeddah?"Fare Configuration ":"6E Tiffin"}</strong></td>
    <td>{props.showJeddah?"Saver link":"Chargeable"}</td>
    <td>{props.showJeddah?"Flexi Link":"Included"}</td>
    {props.isCorporate && <td>Included</td>}

  </tr>
  <tr>
    <td><strong>Standard Seat </strong></td>
    <td>Chargeable</td>
    <td>Included</td>
    {props.isCorporate && <td>Included </td>}

  </tr>
  <tr>
    <td><strong>Date change fee per passenger Per sector</strong></td>
    <td>Please <a href="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html" target="_blank">click here</a> for details.</td>
    <td>0-3 days# : INR 5000 4 days# & above : No Fee</td>
    {props.isCorporate && <td>Please <a href="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html" target="_blank">click here</a> for details.</td>}

  </tr>
  <tr>
    <td><strong>Cancellation charges per passenger per sector</strong></td>
    <td>Please <a href="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html" target="_blank">click here</a> for details.</td>
    <td>0-3 days# : Up to INR 6500 4 days# & above : INR 1000</td>
    {props.isCorporate && <td>Please <a href="https://www.goindigo.in/travel-information/en/fare-rules.en-fd9bd1d98a5b47909c2518489e0e617c.html" target="_blank">click here</a> for details.</td>}

  </tr>
  <tr>
    <td><strong>XL Seat</strong></td>
    <td>{!props.showContent && props.showJeddah && !props.showDiscLabel?"Upto 50% discount on Retail Rate":"No discount"}</td>
    <td>{props.showDiscLabel?"Upto 50% discount on Retail Rate":"No discount"}</td>
    <td>{props.isCorporate && props.showDiscLabel?"Upto 50% discount on Retail Rate":""}</td>
    <td>{props.isCorporate && !props.showDiscLabel && "No discount"}</td>

  </tr>
  <tr>
    <td><strong>Check-In Baggage</strong></td>
    <td><span className='d-flex toolTipInfo'>{props.baggage} KG {!props.showContent && props.showJeddah && <span className='ezyIcon icon-info'>

                                         <div className="toolTipCUstoom">
                                          <span>25 KG + 5L ZamZam Water </span>
                                          </div>
                                          </span>} </span>
    </td>
    <td><span className='d-flex toolTipInfo'>{props.baggage} KG {!props.showContent && props.showJeddah && <span className='ezyIcon icon-info'>

                                          <div className="toolTipCUstoom">
                                          <span>25 KG + 5L ZamZam Water </span>
                                          </div>
                                          </span>}</span>


    </td>
    {props.isCorporate && <td><span className='d-flex toolTipInfo'>{30} KG {!props.showContent && props.showJeddah && <span className='ezyIcon icon-info'>

                                          <div className="toolTipCUstoom">
                                          <span>25 KG + 5L ZamZam Water </span>
                                          </div>
                                          </span>}</span>

    </td>}

  </tr>
  <tr>
    <td><strong>Hand Baggage</strong></td>
    <td>One hand bag up to 7 kgs and 115 cms (L+W+H), shall be allowed per customer</td>
    <td>One hand bag up to 7 kgs and 115 cms (L+W+H), shall be allowed per customer</td>
    {props.isCorporate && <td>One hand bag up to 10 kgs and 115 cms (L+W+H), shall be allowed per customer.</td>}

  </tr>
  {props.showVandeBharath &&
    <tr>
    <td><strong>Cancellation charges</strong></td>
    <td>In Case of Vande Bharath Flight 100% Cancellation fee of the fare is applicable</td>
    <td>In Case of Vande Bharath Flight 100% Cancellation fee of the fare is applicable</td>
    {props.isCorporate && <td>In Case of Vande Bharath Flight 100% Cancellation fee of the fare is applicable</td>}

  </tr>
  }
  
  </tbody>

  </Table>

  </>
)


}
export default InternationalContent