/**
 * @description: Flight Confirmation page Flight details Layover section
 * @date:18-09-2020
 * @author:Rambabu
 * 
 */
import React from 'react';
import { Row } from "react-bootstrap";
import DateUtils from '../../commonUtils/DateUtils';
import { isNotNull } from '../../commonUtils/validators';
import AirportMemo from '../../../components/common/airlines/airportNames';

function SummaryLayoverDetails(props) {

    let layovertime = '';
    let layoverairport = '';

    //Calculate the Layover time duration and Layover airport details
    if (isNotNull(props.layoverdata)) {
        //console.log('origina data in LayoverDetails---' + JSON.stringify(props.layoverdata));
        let totTime = Math.abs(new Date(props.layoverdata[0]) - new Date(props.layoverdata[1]));
        //console.log('origina data in LayoverDetails totTime---' + totTime);//milliseconds
        layovertime = DateUtils.msToTime(totTime);
        //console.log('origina data in LayoverDetails durations---' + layovertime);//hours mins ex:1hr 40mins
        layoverairport = props.layoverdata[2];
    } else {
        console.log('origina data in LayoverDetails null---');
    }


    return (

        isNotNull(layovertime) && (
            <Row className="align-items-center rs-row justify-content-center">
                {/* <div className="layoverInfo">Layover: {layovertime}, Muscat, Muscat International Airport</div> */}
                <div className="layoverInfo">Layover: {layovertime}, <AirportMemo code={layoverairport} mapping={props.mappings} type={'Layover'} /></div>
            </Row>)
    )
}


export default SummaryLayoverDetails;
