import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import BooingDetailsPdf from './BooingDetailsPdf';
import InvoiceSummaryPdf from './InvoiceSummaryPdf';
import HotelRoomPricePdf from './HotelRoomPricePdf';
import Utilities from '../../../commonUtils/Utilities';

const initialState = { mtaList:[]};
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };
const HotelInvoicePdf = React.forwardRef((props, ref) => {
const [state, dispatch] = useReducer(reducer, initialState);
const [roomsInfo , setRoomsInfo] = useState([])
const [ratingList , setRatingList] = useState([])

useEffect(() => {
    if(props.utpye!==undefined && props.fareDetails!==undefined && props.htldetails!==undefined){
        var userType = (props.utpye === "ta_user") ? "TA" : "STA";
        let roomList = props.fareDetails.filter((obj) => obj.type === userType);
        setRoomsInfo(roomList[0])
        let ratings=Utilities.getHotelStartRating(props.htldetails.rating);
        setRatingList(ratings);
    }
    }, [props.utpye])

useEffect(() => {
   let mtaList=props.fareDetails.filter((obj) => obj.type==="MTA")
     dispatch({type:"mtaList" , payload: mtaList})
    }, [props.fareDetails])

  return (
    <div className="bg-gray-200 p-6" ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
                <h2 className="mb-4">
                  Invoice
                </h2>
                </div>

               {props.bkngdetails !== undefined &&
               <InvoiceSummaryPdf bkngdetails={props.bkngdetails} utpye={props.utpye} phone_num={props.phone_num} emialId={props.emialId} invType={props.invType} type={props.type} parent_id={props.parent_id} companyLogo={props.companyLogo}/>
               }

              <div className="">
              <h3 className="pdfSubTitl">
                  Hotel Details
                </h3>
                {props.htldetails!==undefined &&
                 <BooingDetailsPdf htldetails={props.htldetails} roomsCount={props.roomsCount} />
                }
              </div>
               <div className="faredetails">
                {roomsInfo!==undefined && roomsInfo.rooms!==undefined &&
                 <HotelRoomPricePdf fareDetails={props.fareDetails} roomsList={roomsInfo.rooms} invType={props.invType} type={props.type} currency={props.bkngdetails.usr_curr_code} region_type={props.bkngdetails.region_type}/>
                }
              </div>
            </div>
        </div>
        <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
});

export default HotelInvoicePdf;
