import React, { Component, useState,useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../App";
import Header from './Header';


function MainMenuFun(props) {
  const [context, setContext] = useContext(Context);
  const { state } = props || {}
  //const [mainNav, setmainNav,] = useState(false);

  return (
    /*
This Main Menu component expecing the following Props:
- active = "PageName"
Based on that link will be active
*/
    <>

<Header />
<section className="menyLayout">

    <div className="container">
      <div className="MainMenu">
        <ul className="d-flex">
        {context.logindata.roles && context.logindata.roles.split(",").includes('O_R')  && <li className={state.active === "Organization/CM" ? 'active' : ''}>
            <Link to={context.logindata.roles.split(",").includes('B_C') && context.logindata.utype === "att_user"?"/ezytrip/administration/branch":
                     context.logindata.roles.split(",").includes('C_C')?"/ezytrip/administration/company":
                     context.logindata.roles.split(",").includes('C_T') && context.logindata.utype === "att_user"?"/ezytrip/administration/category":
                     context.logindata.roles.split(",").includes('C_C_M') && context.logindata.utype === "att_user"?"/ezytrip/administration/CountryMaster":
                     context.logindata.roles.split(",").includes('C_O_M') && context.logindata.utype === "att_user"?"/ezytrip/administration/oidMaster":
                     context.logindata.roles.split(",").includes('C_M_O_H') && context.logindata.utype === "att_user"?"/ezytrip/administration/oidMaxHits":
                     context.logindata.roles.split(",").includes('C_F')?"/ezytrip/administration/customFields":"#"} title="Organization/CM">
              <span className="ezyIcon icon-company">Organization/CM</span>
            </Link>

          </li>}

          {context.logindata.roles && (context.logindata.roles.split(",").includes('M_S') || context.logindata.roles.split(",").includes('M_S'))  && context.logindata.utype !== "sta_user" && <li className={state.active === "Master Services" ? 'active':''}>
            <Link to={context.logindata.roles.split(",").includes('U_M') && context.logindata.utype !== "sta_user" ? "/ezytrip/administration/user" :
                      context.logindata.roles.split(",").includes('R_M') && context.logindata.utype !== "sta_user" ? "/ezytrip/administration/role" : "#"} title="Master Services">
              <span className="ezyIcon icon-configuration">Master Services</span>
            </Link>
          </li>}
          {context.logindata.roles && context.logindata.roles.split(",").includes('A_S')  && <li className={state.active === "Air Services" ? 'active':''}>
            <Link to={context.logindata.roles.split(",").includes('M_U') ? "/ezytrip/administration/markup" :
            context.logindata.roles.split(",").includes('S_F') ? "/ezytrip/administration/serviceFee" :
            context.logindata.roles.split(",").includes('D_C') ? "/ezytrip/administration/dealCode" :
            context.logindata.roles.split(",").includes('C_M') ? "/ezytrip/administration/commission" :
            context.logindata.roles.split(",").includes('O_I') ? "/ezytrip/administration/officeid" : 
            "/ezytrip/administration/holdairline"
            // "#"
            } title="Air Services">
              <span className="ezyIcon icon-flight">Air Services</span>
            </Link>
          </li>}
          {context.logindata.roles && context.logindata.roles.split(",").includes('G_S')  &&
          <li className={state.active === "Hoel Services" ? 'active':''}><Link to={context.logindata.roles.split(",").includes('H_M') ? "/ezytrip/administration/hotel/markup" :
          context.logindata.roles.split(",").includes('H_S') ? "/ezytrip/administration/hotel/serviceFee" : "#"} title="Hoel Services">
          <span className="ezyIcon icon-hotel">Hotel Services</span></Link></li>
          }
          {/* {context.logindata.roles && context.logindata.roles.split(",").includes('G_S')  && <li className={state.active === "Ground Services" ? 'active':''}>
            <Link to="#" title="Ground Services">
              <Icon size={18} icon="settings" />
              <span>Ground Services</span>
            </Link>
          </li>} */}
          {context.logindata.roles && context.logindata.roles.split(",").includes('P_B')  && <li className={state.active === "Payment & Billing" ? 'active':''}>
            <Link to={context.logindata.roles.split(",").includes('C_R') ?"/ezytrip/administration/creditmanagement" : "#"} title="Payment & Billing">
              <span className="ezyIcon icon-moneySettings">Payment & Billing</span>
            </Link>
          </li>}
          {context.logindata.roles && context.logindata.roles.split(",").includes('P_Y')  &&  <li className={state.active === "Policy" ? 'active':''}>
            <Link to="#" title="Policy">
              <span className="ezyIcon icon-policy">Policy</span>
            </Link>
          </li>}
          { context.logindata.utype != "ta_user" &&<li className={state.active === "Visa" ? 'active':''}>
            <Link to={"/ezytrip/administration/visa"} title="VISA">
              <span className="ezyIcon icon-moneySettings">VISA</span>
            </Link>
          </li>}
        </ul>
      </div>
    </div>
    </section>
    </>
  );
};



class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active

    };
  }

  render() {
    return <MainMenuFun state={this.state} />
  }
}

export default MainMenu;

