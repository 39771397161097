
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import PickList from '../../../common/pickList/PickList';
import DateUtils from '../../../commonUtils/DateUtils';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import ApplicableDates from '../../common/ApplicableDates';
import Routes from '../../common/Routes';
import { CommissionContext } from './CommissionMain';
import {
  airportlist, countries, creation,
  deleteAppliacableData, deleteClassData, deleteCommissionOfficeidData, deleteRoutesData, getbranchList, getComissionList, getOfficeIdList,

  update
} from './operations';
let airlineMaster = require('../../../masterData/AirlinesMasterData.json');
let class_Code_List = require('../../../masterData/BookingClassCode.json');
const JSON = require('circular-json');


/**
 * Initial State Declaration
 */
const initialState = {
  routDetails: false, passengerType: false, isActive: true, fromDate: '', toDate: '', date: '',
  countrys: [], countryd: [], comissionName: '', airlineName: [], travelType: [], commissionble: [], applicable: '',
  messages: [], showMsg: false, applicable_display_data: [], sourceAirport: [], destinationAirport: [], sccode: '', dccode: '',
  sarray: [], darray: [], routes_display_json: [], scname: '', dcname: '', srccntname: '', descntname: '', cnt: '', cty: '',
  routes_save_json: [], classType: [], classCode: [], class_json: [], comissionOn: [], comissionType: [], comissionValue: '',
  branch_master_data: [], officeId_master_data: [], branch_data: '', officeid_data: [], save_oficeid_json: [], comission_display_data: [],
  comission_List: [], bdes: '', countriesMenu: [], citiesMenu: [], notiMessageShow: false, notiMessage: '',
  notiVarient: '', selsource_air: [], seldestination_air: [], selclass_code: [], selclass_type: [], resetPick1: true,
  resetPick2: false, is_edit: false, commission_id: '', isLoadingMore: false, enableView: false, applDates: [], airlinesList: [], airlineError: false, commissionable: [], commissionableError: false, comissionTypeError: false, comissionOnError: false,  isItineraryLoading:false
};

//temp variable for routes
let rr_json = [];

//temp varibale for class
let cls_com_json = [];

//temp varibale for officeid and branch
let br_Of_json = [];

const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};


/**
 * @author: Lakshmi
 * @description: This function will create the airline comission defination.
 * @date:14-09-2020
 */
function AirlineCommission() {

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);
  const [routDetails, setRoutDetails] = useState({ rts: [] });
  const commssionObj = useContext(CommissionContext);
  const [dates, setDates] = useState({ datesList: [] });
  const [applicable, setApplicable,] = useState({ applDates: [] });
  //const [isLoadingMore, setIsLoadingMore,] = useState(false);
  useEffect(() => {

    //fetch countries data
    countries().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
    });

    if (context.logindata !== undefined) {
      //fetch resepective office id branch data
      getbranchList(context.logindata.uid).then((res) => {
        dispatch({ type: 'branch_master_data', payload: res })
      });
    }
  }, [context.logindata]);

  //Edit Commission
  useEffect(() => {
    if (commssionObj !== undefined && commssionObj[0] !== undefined) {

      editcommissionBtnClick(commssionObj);
    }
  }, [commssionObj !== undefined]);

  //set All for the current Airline Master
  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })

  }, [])

  const booking = [{ label: "Booking", value: "Booking" },
  { label: "Travel", value: "Travel" }]

  const airline_class_List = [{ label: "All", value: "All" },
  { label: "Economy", value: "Economy" },
  { label: "Premium Economy", value: "Premium Economy" },
  { label: "Business", value: "Business" },
  { label: "First", value: "First" }]

  const travel_Type_list = [{ label: "All", value: "0" }, { label: "One Way", value: "1" },
  { label: "Round Trip", value: "2" }, { label: "Multi City", value: "3" }]

  const commissionable_list = [{ label: "Yes", value: "yes" },
  { label: "No", value: "no" }]

  const commission_Type = [{ label: "% Percentage", value: "% Percentage" },
  { label: "Value", value: "Value" }]

  const commissionapplyon = [{ label: "Base Fare", value: "BF" },
  { label: "Total Fare", value: "TF" }, { label: "Base Fare+YQ", value: "YQ" }, { label: "Base Fare+YR", value: "YR" }]



  /**
   * @description:Handle Change Event
   * @author:Rambabu
   * @param {*} e
   * @date:14-09-2020
   */
  const handleChange = (e) => {

    const { id, value } = e.target;

    //comission name
    if (id === 'com_id') {

      dispatch({ type: 'comissionName', payload: value });
      dispatch({ type: "nameError", payload: false })

    } //status
    else if (id === 'act') {
      dispatch({ type: 'isActive', payload: e.target.checked });

    } //commission value
    else if (id === 'com_val_id') {
      dispatch({ type: 'comissionValue', payload: e.target.value });

    }

  }

  /**
   * @description: When we click Applicable ADD button below event will call
   * @param {*} e
   * @date:14-09-2020
   * @author:Rambabu
   */

  const applicableDates = (ad) => {
    state.applDates.push(ad);
    dispatch({ type: 'applDates', payload: state.applDates })
  }

  const updateApplDates = (data) => {
    setDates({ datesList: data })
  }
  const applicableAddButton = (e) => {
    e.preventDefault(e);
    console.log('commission nmae-----' + state.comissionName);
    console.log('Airline company-----' + JSON.stringify(state.airlineName));
    console.log('Travel Type-----' + JSON.stringify(state.travelType));
    console.log('commissionbale-----' + JSON.stringify(state.commissionble));
    console.log('status-----' + state.isActive);
    console.log('Applicale Type-----' + JSON.stringify(state.applicable));
    console.log('from date-----' + state.fromDate);
    console.log('To date Type-----' + state.date);

    if (isNotNull(state.comissionName) && isArrayNotEmpty(state.commissionble) &&
      isArrayNotEmpty(state.travelType) && isArrayNotEmpty(state.comissionName) &&
      isArrayNotEmpty(state.applicable) && isNotNull(state.fromDate) && isNotNull(state.date)) {
      let arr = [];
      const display_data = {
        fm: state.fromDate,
        to: state.date,
        fm1: DateUtils.convertToDateFormatewithoutTime(state.fromDate),
        to1: DateUtils.convertToDateFormatewithoutTime(state.date),
        ty: state.applicable[0].value
      }

      //applicable only one record allow
      if (!isArrayNotEmpty(state.applicable_display_data)) {
        arr.push(display_data)
        dispatch({ type: 'applicable_display_data', payload: arr });
        dispatch({ type: 'notiMessageShow', payload: false })
        dispatch({ type: 'notiMessage', payload: '' })
        dispatch({ type: 'notiVarient', payload: '' })
        hidemessage()
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Applicable Already avaliable' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter required fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }


  }

  const callDeleteApp = (id) => {
    deleteAppliacableData(id).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  }
  /**
   * @description: When we click Applicable Remove button below event will call
   * @param {*} e
   * @date:14-09-2020
   * @author:Rambabu
   */
  const applicableRemoveButton = (data, index) => () => {

    let filter_data = state.applicable_display_data.filter((rt, sidx) => index !== sidx);
    dispatch({ type: 'applicable_display_data', payload: filter_data });

    //if id is there in object that is db record,so we have to delete that record
    if (isNotNull(data.id)) {

      deleteAppliacableData(data.id).then((res) => {
        if (isNotNull(res)) {

          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: res })
          dispatch({ type: 'notiVarient', payload: 'success' })
          hidemessage()

        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Applicable not deleted successfully' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      });


    }

  }


  /**
   * @description: When we select source Country below event will call
   * @date:14-09-2020
   * @author:Rambabu
   * @param {*} e
   *
   */
  const handleSCntChange = (e) => {
    if (e !== null && e !== undefined && e !== '' && e.length > 0 && e[0]) {
      dispatch({ type: 'countrys', payload: e });
      dispatch({ type: 'sccode', payload: e[0].code });
      dispatch({ type: 'scname', payload: (e[0].name + "(" + e[0].code + ")") });
      dispatch({ type: 'srccntname', payload: e[0].name });

      //fetch airport list
      airportlist(e).then((res) => {
        dispatch({ type: 'sourceAirport', payload: res })
      });

    } else {

      dispatch({ type: 'countrys', payload: [] });
      dispatch({ type: 'sccode', payload: '' });
      dispatch({ type: 'scname', payload: '' });
      dispatch({ type: 'srccntname', payload: '' });
      dispatch({ type: 'sourceAirport', payload: [] })
    }
  }

  /**
   * @description: When we select Destination Country below event will call
   * @date:14-09-2020
   * @author:Rambabu
   * @param {*} e
   *
   */
  const handleDCntChange = (e) => {
    if (e !== null && e !== undefined && e !== '' && e.length > 0 && e[0]) {
      dispatch({ type: 'countryd', payload: e });
      dispatch({ type: 'dccode', payload: e[0].code });
      dispatch({ type: 'dcname', payload: (e[0].name + "(" + e[0].code + ")") });
      dispatch({ type: 'descntname', payload: e[0].name });

      //fetch airport list
      airportlist(e).then((res) => {
        dispatch({ type: 'destinationAirport', payload: res })
      });
    } else {
      dispatch({ type: 'countryd', payload: '' });
      dispatch({ type: 'dccode', payload: '' });
      dispatch({ type: 'dcname', payload: '' });
      dispatch({ type: 'descntname', payload: '' });
      dispatch({ type: 'destinationAirport', payload: [] })
    }
  }

  /**
   * @description: When we select Source and destination airport  below event will call
   * @date:14-09-2020
   * @author:Rambabu
   * @param {*} data
   * @param {*} val
   */
  const setRoutes = (data, val) => {
    // console.log('setRoutes event----' + JSON.stringify(data));
    //console.log('routs val----' + val);

    let sairs = [];
    let dairs = [];

    //Source Airport event
    if (state.sccode && val === 1) {
      dispatch({ type: 'selsource_air', payload: data });
      data.map((obj) => {
        if (obj.value) {
          sairs.push(obj.value);
          dispatch({ type: 'sarray', payload: sairs });

        }
      }
      );
    }
    //Destination Airport Event
    if (state.dccode && val === 2) {
      dispatch({ type: 'seldestination_air', payload: data });
      data.map((obj) => {
        if (obj.value) {
          dairs.push(obj.value);
          dispatch({ type: 'darray', payload: dairs })
        }
      }
      );
    }
  }

  /**
   * @description: When we Click Source and destination airport add button  below event will call
   * @date:14-09-2020
   * @author:Rambabu
   *
   */
  const routesAddBtnClick = (e) => {
    e.preventDefault(e);

    if (isNotNull(state.sccode) && isNotNull(state.dccode)) {
      if (state.sccode !== state.dccode) {
        if (isArrayNotEmpty(state.sarray) && isArrayNotEmpty(state.darray)) {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })

          //construction source and destination json for validationg duplicate
          for (let s in state.sarray) {
            let src = state.sarray[s];
            for (let d in state.darray) {
              let des = state.darray[d];
              let r_json = {
                source: src,
                destination: des,
              }
              rr_json.push(r_json);
            }
          }

          //remove duplicate and get unique values
          let jsondata = uniqueJson(rr_json);

          //checking the duplicates based on length(original json and unique json length)
          if (rr_json.length !== jsondata.length) {
            rr_json = jsondata;
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Routes already exist' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          } //if duplicate not
          else {
            dispatch({ type: 'notiMessageShow', payload: false })
            dispatch({ type: 'notiMessage', payload: '' })
            dispatch({ type: 'notiVarient', payload: '' })
            hidemessage()
            if (isArrayNotEmpty(state.sarray)) {
              //Dipslay routes json data table
              state.sarray.map((src) => {
                let route = "";
                let src_ = [];

                //below code due to some array issue i have written here for destination airport array(~0~)
                let arr_2 = [];
                for (let d in state.darray) {
                  let des = state.darray[d];
                  arr_2.push(des);
                }

                if (src) {
                  src_.push(src);
                  route = {
                    source: state.scname + " " + "[" + src + "]",
                    destination: state.dcname + " " + "[" + state.darray + "]",
                    srccnt: state.sccode,
                    destcnt: state.dccode,
                    srcair: src_,
                    destair: arr_2
                  }
                }
                state.routes_display_json.push(route)
              });
              dispatch({ type: 'routes_display_json', payload: state.routes_display_json })

              //saving json object construction
              let route_json = {
                srccnt: state.sccode,
                destcnt: state.dccode,
                srcair: state.sarray,
                destair: state.darray,
                scnt: state.srccntname,
                dcnt: state.descntname
              }
              state.routes_save_json.push(route_json);
              dispatch({ type: 'routes_save_json', payload: state.routes_save_json })
            }

          }
          //after add button clear the fileds
          dispatch({ type: 'sarray', payload: [] })
          dispatch({ type: 'darray', payload: [] })
          dispatch({ type: 'countrys', payload: [] })
          dispatch({ type: 'countryd', payload: [] })
          dispatch({ type: 'selsource_air', payload: [] })
          dispatch({ type: 'seldestination_air', payload: [] })
          dispatch({ type: 'destinationAirport', payload: [] })
          dispatch({ type: 'sourceAirport', payload: [] })

        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Source airport and destination airport required' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Source and destination should not be same' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
      }
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Source country and destination country is required' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }


  }


  /**
   * @description: When we Click Source and destination airport remove  button  below event will call
   * @date:14-09-2020
   * @author:Rambabu
   * @param {*} data
   * @param {*} index
   */
  const routesRemoveBtnClick = (data, index) => () => {

    //below code remove selected route from display list
    let filter_data = state.routes_display_json.filter((rt, sidx) => index !== sidx);
    dispatch({ type: 'routes_display_json', payload: filter_data });

    //below code need to remove seleted one from routes saving object
    for (let s in state.routes_save_json) {
      let saved_obj = state.routes_save_json[s];
      let src_ = [];
      for (let d in saved_obj.srcair) {
        let src = saved_obj.srcair[d];
        console.log('src!==data.srcair----' + (src.toString().trim() === data.srcair.toString().trim()));
        if (src.toString().trim() !== data.srcair.toString().trim()) {
          src_.push(src);
        }
      }

      if (isArrayNotEmpty(src_)) {
        saved_obj.srcair = src_;
      }  //if srcair is empty removed complete obj from saved json object
      else {
        state.routes_save_json.splice(s, 1);
      }
      dispatch({ type: 'routes_save_json', payload: state.routes_save_json });

    }

    //below code removed selected obj from temp variable
    let s_d_arr = rr_json;
    for (let s in data.srcair) {
      let src = data.srcair[s];
      //console.log('src---'+src);
      for (let d in data.destair) {
        let des = data.destair[d];
        // console.log('des---'+des);
        let r_json = {
          source: src,
          destination: des,
        }
        //console.log('r_json obj is----'+JSON.stringify(r_json));
        //console.log('s_d_arr---- '+JSON.stringify(s_d_arr));
        let indexvvv = s_d_arr.findIndex(obj => (obj.source === src && obj.destination === des));
        //console.log('indexvvv---'+indexvvv);
        let array = [...s_d_arr]; // make a separate copy of the array
        //console.log('copy of array----'+JSON.stringify(array));
        array.splice(indexvvv, 1);
        //console.log('after splice array----'+JSON.stringify(array));
        s_d_arr = array;

      }
    }
    rr_json = s_d_arr;

    //if db tag is avalibale ,tehn we have to delete data from table
    if (isNotNull(data.is_db) && data.is_db === 1) {

      const rt_obj = {
        src_air: data.src_air,//source airport
        desc_air: data.destair,//destination airport array
        commission_id: data.commission_id//office_id
      }

      deleteRoutesData(rt_obj).then((res) => {
        if (isNotNull(res)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: res })
          dispatch({ type: 'notiVarient', payload: 'success' })
          hidemessage()


        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Routes is not deleted successfully' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      });
    }

  }


  const routeDelete = (data) => {
    const payload = {
      "src_air": data.srcair,
      "desc_air": data.destair,
      "commission_id": state.commission_id
    }
    deleteRoutesData(payload).then(response => {
      if (response.data.suc) {
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in deleteRoute function---" + error
      );
    });
  }


  /**
   * @description:when we select booking code below event will call
   * @date:15-09-2020
   * @author:Rambabu
   * @param {*} e
   */
  const bookicodeEvent = (e) => {
    dispatch({ type: 'classCode', payload: e })
  }


  /**
   * @description:when we click class Add button below event will call
   * @date:15-09-2020
   * @author:Rambabu
   * @param {*} e
   */
  const addClassCommissionBtn = () => {

    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
    hidemessage()
    if (isArrayNotEmpty(state.classType) && isArrayNotEmpty(state.classCode)) {
      let code = '';
      for (let c in state.classCode) {
        let code_data = state.classCode[c];
        if (!isNotNull(code)) {
          code = code_data.value;
        } else {
          code = code + '|' + code_data.value;
        }
      }
      const class_data = {
        ty: (isArrayNotEmpty(state.classType[0].value) ? state.classType[0].value : '-'),
        rbd: (isNotNull(code) ? code : '-'),
      }

      cls_com_json.push(class_data);


      //validating duplicate json
      //let jsondata = uniqueJson(cls_com_json);

      let jsondata = getUnique(cls_com_json, "ty");
      if (cls_com_json.length !== jsondata.length) {
        cls_com_json = jsondata;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Class type already exist' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()

      } else {

        let cls_filter_data = [];
        //checking All, if classtype is All avaliable not allowing any class type
        if (isArrayNotEmpty(cls_com_json) && cls_com_json.length > 1) {
          cls_filter_data = cls_com_json.filter((cl, sidx) => cl.ty.toString() === 'All');
        }

        if (isArrayNotEmpty(cls_filter_data)) {
          //if json having all then we are removing all data and assging to cls_com_json.
          let remove_all_data = cls_com_json.filter((cl, sidx) => cl.ty.toString() !== 'All');
          cls_com_json = remove_all_data;
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class type already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        } else {
          state.class_json.push(class_data);
          dispatch({ type: 'class_json', payload: state.class_json });
        }

      }
      //after add button clear the fileds
      dispatch({ type: 'classType', payload: [] })
      dispatch({ type: 'classCode', payload: [] })

    } else {

      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Class & rbd required' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }

  }


  /**
   * @description:when we click class Remove button below event will call
   * @date:15-09-2020
   * @author:Rambabu
   * @param {*} data
   * @param {*} index
   */
  const removeClassCommissionBtn = (data, index) => () => {


    let filter_data = state.class_json.filter((cls, sidx) => index !== sidx);
    dispatch({ type: 'class_json', payload: filter_data });
    let filter_cls_com_json = cls_com_json.filter((cls, sidx) => cls.ty.toString() !== data.ty.toString());

    cls_com_json = filter_cls_com_json;

    //if id is there in object that is db record,so we have to delete that record
    if (isNotNull(data.id)) {

      deleteClassData(data.id).then((res) => {
        if (isNotNull(res)) {

          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: res })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()

        } else {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class (RBD) not deleted successfully' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
        }
      });

    }
  }

  /**
 * @description:when we select pick list data below event will call
 * @date:15-09-2020
 * @author:Rambabu
 * @param {*} data
 * @param {*} id
 */
  const pickldata = (data, id) => {


    //constructing branch ids for office id data
    if (isNotNull(id) && id === 'b_id') {

      let br_ids = [];
      for (let d in data) {
        let br = data[d];
        br_ids.push(br.value);
      }

      const obj = {
        ids: br_ids
      }

      //call api for office id data resepective branch
      getOfficeIdList(obj).then((res) => {
        if (isArrayNotEmpty(res)) {
          let da_ = [];
          for (let r in res) {
            let data = res[r];
            const obj = {
              label: data.label + '(' + data.office_id_label + ')',
              value: data.value,
              off_id: data.office_id_label
            }
            //state.officeId_master_data.push(obj);
            da_.push(obj)
          }
          dispatch({ type: 'officeId_master_data', payload: da_ });
        } else {
          dispatch({ type: 'officeId_master_data', payload: [] });
        }

      });

      dispatch({ type: 'branch_data', payload: data })
    } else if (isNotNull(id) && id === 'i_id') {
      dispatch({ type: 'officeid_data', payload: data })
    }

  }


  /**
   * @description:when we select Comission Add button below event will call
   * @date:15-09-2020
   * @author:Rambabu
   * @param {*}
   */
  const commissionAddBtn = () => {

    if (isArrayNotEmpty(state.officeid_data) && isArrayNotEmpty(state.branch_data)
      && isArrayNotEmpty(state.commissionType) && isArrayNotEmpty(state.comissionOn)) {
      dispatch({ type: 'officeId_master_data', payload: [] });
      let disply_data = [];
      for (let o in state.officeid_data) {
        let office_data = state.officeid_data[o];
        for (let b in state.branch_data) {
          let b_data = state.branch_data[b];
          const obj = {
            branch_label: b_data.label,
            branch_value: b_data.value,
            label: office_data.label,
            value: office_data.value,
            off_id: office_data.off_id
          }
          br_Of_json.push(obj);
        }
      }

      if (isArrayNotEmpty(br_Of_json)) {
        //let jsondata = uniqueJson(br_Of_json);
        let jsondata = getUniquebasedontwotags(br_Of_json, "branch_value", "value");


        //below code office id save json object
        let off_id = [];
        for (let sa in jsondata) {
          let saved_da = jsondata[sa];
          const obj = {
            value: saved_da.office_id_value,
            label: saved_da.office_id_label,
            off_id: saved_da.off_id
          }
          state.save_oficeid_json.push(obj)
        }
        dispatch({ type: 'comission_display_data', payload: jsondata });
        dispatch({ type: 'save_oficeid_json', payload: jsondata });
        if (state.resetPick1) {
          dispatch({ type: 'resetPick2', payload: true })
          dispatch({ type: 'resetPick1', payload: false })
        } else {
          dispatch({ type: 'resetPick2', payload: false })
          dispatch({ type: 'resetPick1', payload: true })

        }
      }
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter required fields' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      }
    }
    function hidemessage() {
      setTimeout(function () {
        dispatch({ type: 'notiMessageShow', payload: false })
        dispatch({ type: 'notiMessage', payload: '' })
        dispatch({ type: 'notiVarient', payload: '' })
      }, 5000);
    }
    /**
     * @description:when we select Comission Remove button below event will call
     * @date:15-09-2020
     * @author:Rambabu
     * @param {*} data
     * @param {*} index
     */
    const commissionRemoveBtn = (data, index) => () => {

      //removed data from displayed data table resepective index
      let com_filter_data = state.comission_display_data.filter((com, sidx) => index !== sidx);
      dispatch({ type: 'comission_display_data', payload: com_filter_data })

      //find index of exact matched data json object and remove that object from temp array
      var indexvvv = br_Of_json.findIndex(obj => (obj.branch_value === data.branch_value && obj.value === data.value));
      var array = [...br_Of_json]; // make a separate copy of the array
      array.splice(indexvvv, 1);
      br_Of_json = array;

      //below code after removed office id, construct office id save json object
      let off_id = [];
      for (let sa in br_Of_json) {
        let saved_da = br_Of_json[sa];
        const obj = {
          value: saved_da.value,
          label: saved_da.label,
          branch_label: saved_da.branch_label,
          branch_value: saved_da.branch_value,
        }
        off_id.push(obj)
      }
      dispatch({ type: 'save_oficeid_json', payload: off_id });
      dispatch({ type: 'commissionType', payload: [] });
      dispatch({ type: 'comissionOn', payload: [] });
      dispatch({ type: 'comissionValue', payload: '' });

      dispatch({ type: 'branch_data', payload: [] })
      dispatch({ type: 'officeid_data', payload: [] })

      //if id is there in object that is db record,so we have to delete that record
      if (isNotNull(data.id)) {

        deleteCommissionOfficeidData(data.id).then((res) => {
          if (isNotNull(res)) {

            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: res })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          } else {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Office id not deleted successfully' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          }
        });

      }


    }

    const convertEditRoutes = (obj) => {
      let routes = [];
      for (let value of obj) {
        let srcArray = [];
        let destArray = [];
        let airs = [];
        let dests = [];
        if (value.srcair && value.srcair.length !== 0) {
          srcArray = value.srcair.split(",");
          airs = srcArray.filter((q, idx) => srcArray.indexOf(q) === idx)
        }
        if (value.destair && value.destair.length !== 0) {
          destArray = value.destair.split(",");
          dests = destArray.filter((q, idx) => destArray.indexOf(q) === idx)
        }
        if (airs) {
          airs.map((obj) => {
            var route = "";
            if (obj && obj !== undefined) {
              route = {
                "id": value.id,
                "srcct": value.srcct,
                "scname": value.scname + " " + "[" + obj + "]",
                "destct": value.destct,
                "dcname": value.dcname + " " + "[" + dests + "]",
                "srcair": airs,
                "destair": dests,
                "is_db": value.is_db
              }
            }
            routDetails.rts.push(route);
            setRoutDetails({ rts: routDetails.rts });
            let route_json = {
              srcct: value.srcct,
              destct: value.destct,
              srcair: airs,
              destair: dests,
              scname: value.scname,
              dcname: value.dcname
            }
            routes.push(route_json);
          }
          );
          dispatch({ type: 'rts', payload: routes })
        }

      }
    }

    /**
   * @description:when we select Save Comission button below event will call
   * @date:15-09-2020
   * @author:Rambabu
   * @param {*}
   */
    const handleSaveCommissionBtn = (e) => {

      //applicable_display_data
      //routes_save_json
      //save_oficeid_json
      //class_json
      if (state.airlineName && state.airlineName.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'airlineError', payload: true })
      }
      if (state.travelType && state.travelType.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'tTypeError', payload: true })
      }
      if (state.commissionable && state.commissionable.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'commissionableError', payload: true })
      }
      if (state.comissionType && state.comissionType.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'comissionTypeError', payload: true })
      }
      if (state.comissionOn && state.comissionOn.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: 'comissionOnError', payload: true })
      }
      let namevalidations = false
      if (characterValidation(state.comissionName)) {
        namevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
      if (
        isArrayNotEmpty(state.save_oficeid_json) &&
        state.comissionName !== ''
        && isArrayNotEmpty(state.airlineName) && isArrayNotEmpty(state.commissionble) &&
        isArrayNotEmpty(state.travelType) && state.comissionValue !== '' && isArrayNotEmpty(state.commissionType) && isArrayNotEmpty(state.comissionOn)
      ) {
        const main_json = {
          cnm: state.comissionName,
          act: state.isActive,
          al: state.airlineName[0].value,
          iscom: state.commissionble[0].value,
          tt: state.travelType[0].value,
          crby: context.logindata.uid,
          apto: state.applDates,
          rt: state.routes_save_json,
          cls: state.class_json,
          comon: state.comissionOn[0].value,
          comtyp: state.commissionType[0].value,
          camnt: state.comissionValue,
          ofc: state.save_oficeid_json

        }

        const payload = {
          cmreq: main_json
        }
        if (namevalidations) {
          dispatch({ type: "isItineraryLoading", payload: true });

          creation(payload).then((res) => {
            if (isNotNull(res.suc) && res.suc) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Commission Saved Successfully' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
              //fetch all comission list
              // getComissionList().then((res) => {
              //   dispatch({ type: 'comission_List', payload: res })
              // });
              return window.location = "/ezytrip/administration/commission"
            }
          });
        }
      } else {
        dispatch({ type: "isItineraryLoading", payload: false });

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please add required fields' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
      }
    }
    const updateRoutesData = (data) => {
      dispatch({ type: 'routes_save_json', payload: data })
    }

    const rotesInfo = (route) => {
      state.routes_save_json.push(route);
      dispatch({ type: 'routes_save_json', payload: state.routes_save_json })


    }

    /**
   * @description:when we click Update Comission button below event will call
   * @date:24-09-2020
   * @author:Rambabu
   * @function:handleUpdateCommissionBtn
   * @param {*}
   */
    const handleUpdateCommissionBtn = () => {

      //applicable_display_data
      //routes_save_json
      //save_oficeid_json
      //class_json



      if (isArrayNotEmpty(state.applDates) && isArrayNotEmpty(state.save_oficeid_json) && isNotNull(state.comissionName) && state.comissionName !== '') {
        let namevalidations = false
        if (characterValidation(state.comissionName)) {
          dispatch({ type: "isItineraryLoading", payload: true });
          namevalidations = true
          dispatch({ type: "nameError", payload: false })
        } else {
          dispatch({ type: "nameError", payload: true })
        }
        if (namevalidations) {
          const main_json = {
            com_id: state.commission_id,
            cnm: state.comissionName,
            act: state.isActive,
            al: (isArrayNotEmpty(state.airlineName) && isNotNull(state.airlineName[0].value) ? state.airlineName[0].value : ''),
            iscom: (isArrayNotEmpty(state.commissionble) && isNotNull(state.commissionble[0].value) ? state.commissionble[0].value : ''),
            tt: (isArrayNotEmpty(state.travelType) && isNotNull(state.travelType[0].value) ? state.travelType[0].value : ''),
            upby: context.logindata.uid,
            apto: state.applDates,
            rt: state.routes_save_json,
            cls: state.class_json,
            comon: (isArrayNotEmpty(state.comissionOn) && isNotNull(state.comissionOn[0].value) ? state.comissionOn[0].value : ''),
            comtyp: (isArrayNotEmpty(state.commissionType) && isNotNull(state.commissionType[0].value) ? state.commissionType[0].value : ''),
            camnt: state.comissionValue,
            ofc: state.save_oficeid_json

          }

          const payload = {
            cmreq: main_json
          }

          update(payload).then((res) => {
            if (isNotNull(res.suc) && res.suc) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Commission updated successfully' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              hidemessage()
              dispatch({ type: 'is_edit', payload: false });
              //fetch all comission list
              getComissionList().then((res) => {
                dispatch({ type: 'comission_List', payload: res })
              });
              return window.location = "/ezytrip/administration/commission"
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });

              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Commission not updated successfully' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
            }
          });
        }
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please add required fields' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()

      }

    }


    /**
     * @description:Removed duplicates in json objects in json array based on All value comparison
     * @author:Rambabu
     * @date:14-09-2020
     * @param {*} jsonarray
     */
    function uniqueJson(jsonarray) {
      let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
      const mySetSerialized = new Set(myArrSerialized);
      const myUniqueArrSerialized = [...mySetSerialized];
      const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
      // console.log('myUniqueArr----' + JSON.stringify(myUniqueArr))

      return myUniqueArr;
    }

    //to get unique by passing argument
    function getUnique(arr, comp) {

      // store the comparison  values in array
      const unique = arr.map(e => e[comp])

        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);

      return unique;
    }

    /**
     * @description:Get unique json data based on two tags
     * @author:Rambabu
     * @date:14-09-2020
     * @function:getUniquebasedontwotags
     */
    function getUniquebasedontwotags(arr, comp, compp) {

      // store the comparison  values in array
      const unique = arr.map(e => (e[comp] && e[compp]))

        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);

      return unique;
    }


    /**
     * @description:When we click on Edit commission displaying data resepective fileds
     * @author:Rambabu
     * @date:21-09-2020
     * @param {*} data
     * @param {*} index
     */
    const editcommissionBtnClick = (res) => {

      dispatch({ type: 'is_edit', payload: true });
      dispatch({ type: 'commission_id', payload: res[0].cid });

      //window.scrollTo(0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });

      let arr = [];
      if (isArrayNotEmpty(res)) {

        //Edit display commission name
        dispatch({ type: 'comissionName', payload: res[0].cnm });

        //Edit display applicable  data
        if (isArrayNotEmpty(res[0].apto)) {
          dispatch({ type: 'applDates', payload: res[0].apto })
          // dispatch({ type: 'applicable_display_data', payload: res[0].apto });
        }
        //setting airline type value
        let airline_type = state.airlinesList.filter((b, index) => b.value === res[0].al);
        dispatch({ type: 'airlineName', payload: airline_type });

        let tr_type = travel_Type_list.filter((b, index) => b.value === res[0].tt);
        dispatch({ type: 'travelType', payload: tr_type });

        let commissionble = commissionable_list.filter((b, index) => b.value === res[0].iscom);
        dispatch({ type: 'commissionble', payload: commissionble })
        //Edit display Class commission Data
        if (isArrayNotEmpty(res[0].cls)) {

          for (let c in res[0].cls) {
            let data = res[0].cls[c];
            const class_data = {
              id: data.cid,
              ty: data.ty,
              rbd: data.rbd,
            }
            state.class_json.push(class_data);
            cls_com_json.push(class_data);
            dispatch({ type: 'class_json', payload: state.class_json });
          }
        }

        //Edit display Routes Data
        if (isArrayNotEmpty(res[0].rt)) {
          convertEditRoutes(res[0].rt)
        }
        //Edit display comission  and branch,office id data
        if (isArrayNotEmpty(res[0].ofc)) {

          for (let b in res[0].ofc) {
            let b_data = res[0].ofc[b];
            //below code for displaying branch name
            let matched_data = state.branch_master_data.filter((b, index) => b.value === b_data.branchId);        
            let branch_name = '';
            if (isArrayNotEmpty(matched_data)) {
              branch_name = matched_data[0].label;
            } else {
              branch_name = b_data.branchId
            }
            const obj = {
              id: b_data.oid,
              branch_label: branch_name,
              branch_value: b_data.value,
              label: b_data.label,
              value: b_data.value
            }
            br_Of_json.push(obj);
            state.save_oficeid_json.push(obj)
          }

          dispatch({ type: 'comission_display_data', payload: uniqueJson(state.save_oficeid_json) })


        }


      }

    }

    const handleCancel = (e) => {
      return window.location = "/ezytrip/administration/commission"
    }
    /**
       * @description:construct Routes display data table
       * @author:Rambabu
       * @date:11-08-2020
       * @param {*} rt_obj
       */
    function editRoutesDisplay(rt_obj) {

      let srcArray = rt_obj.src_air;
      let srccntname = rt_obj.srccntname;
      let descntname = rt_obj.descntname;
      let desArry = rt_obj.des_air;
      let srccntcode = rt_obj.srccntcode;
      let descntcode = rt_obj.descntcode;
      let is_db = rt_obj.is_db;
      let commission_id = rt_obj.commission_id;
      let rt_id = rt_obj.rt_id


      //constructing the source and destination jsn objects for validating the duplicates
      for (let s in srcArray) {
        let src = srcArray[s];
        for (let d in desArry) {
          let des = desArry[d];
          let r_json = {
            source: src,
            destination: des,
          }
          rr_json.push(r_json);
        }
      }


      //below code to display routes data table when we click edit button
      if (srcArray) {
        srcArray.map((src) => {
          let route = "";
          let src_air_array = [];
          src_air_array.push(src);

          if (src) {
            route = {
              source: srccntname + " " + "[" + src + "]",
              destination: descntname + " " + "[" + desArry + "]",
              srccnt: srccntcode,
              destcnt: descntcode,
              //srcair: srcArray,
              destair: desArry,
              is_db: is_db,
              src_air: src_air_array,
              commission_id: rt_obj.commission_id,
              temp_id: rt_id

            }
          }
          //console.log('rt_id ----' + rt_id);
          //route_display_Data.rts.push(route);
          state.routes_display_json.push(route)
          //console.log('editRoutesDisplay display data----' + JSON.stringify(route_display_Data));
          //setRoute_display_Data({ rts: route_display_Data.rts });
          dispatch({ type: 'routes_display_json', payload: uniqueJson(state.routes_display_json) });
          rt_id = rt_id + 1
          //console.log('rt_id after ----' + rt_id);
        }
        );
        //setIs_routeDetails(true);
      }
      return rt_id;
    }

    /**
       *
       * @description:Remove duplicate data from array
       * @athor:Rambabu
       * @date:10-08-2020
       * @param {*} array
       */
    function uniqueArray(array) {
      var uniqueArray = [];

      // Loop through array values
      var i;
      for (i = 0; i < array.length; i++) {
        if (uniqueArray.indexOf(array[i]) === -1) {
          uniqueArray.push(array[i]);
        }
      }
      return uniqueArray;
    }



    return (
      <>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <div className="contentArea">
          {/* Comission Name */}
          <Form.Row>
            <Form.Group as={Col} xs={3} controlId="com_id">
              <Form.Control type="text" placeholder="Commission Name" onChange={handleChange} value={state.comissionName} />
              <Form.Label>Commission Name<sup>*</sup></Form.Label>
              {state.nameError && (
                <p className="vError">Please Enter Valid Name</p>
              )}
            </Form.Group>

            {/* Airline Drop Down */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="airline_id">
              <Select
                options={state.airlinesList}
                values={state.airlineName}
                //onChange={(value) => console.log(value),airlinesEvent(value)}
                onChange={(value) => (console.log('Airline selected value----' + value), dispatch({ type: 'airlineName', payload: value }), dispatch({ type: "airlineError", payload: false }))}
              />

              {state.airlineError && (
                <p className="vError">Please select Airlines</p>
              )}
              <Form.Label>Airlines <sup>*</sup></Form.Label>

              {/* Travel Type Drop Down */}
            </Form.Group>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="trvl_id">
              <Select
                options={travel_Type_list}
                values={state.travelType}
                onChange={(value) => (dispatch({ type: 'travelType', payload: value }), dispatch({ type: 'tTypeError', payload: false }))}
              />
              {state.tTypeError && (
                <p className="vError">Please select Airlines</p>
              )}
              <Form.Label>Travel Type <sup>*</sup></Form.Label>
            </Form.Group>

            {/* Comissionble Drop Down */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
              <Select
                options={commissionable_list}
                values={state.commissionble}
                onChange={(value) => (dispatch({ type: 'commissionable', payload: value }), dispatch({ type: 'commissionableError', payload: false }))}
              />
              {state.commissionableError && (
                <p className="vError">Please select Commissionable</p>
              )}
              <Form.Label>Commissionable <sup>*</sup></Form.Label>
            </Form.Group>



          </Form.Row>



          {/* Applicable DropDown */}
          <ApplicableDates configType={"Markup"} sendAppDates={applicableDates} updateData={updateApplDates} applDatesData={state.applDates} sendApplicableId={callDeleteApp} />

          {/* Aftter click on applicable add button applicable_display_data table */}
          {isArrayNotEmpty(state.applicable_display_data) &&
            <Form.Row>
              <Form.Group as={Col} xs={6}>
                <div className="selectedRout tableLayout">
                  <Table>
                    <thead>
                      <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.applicable_display_data.map((app, index) => (
                        <React.Fragment key={index}>

                          <tr>
                            <td>
                              <span>{app.fm1}</span>
                            </td>
                            <td>
                              <span>{app.to1}</span>
                            </td>
                            <td>
                              <span>{isArrayNotEmpty(app.ty) && (app.ty)}</span>
                            </td>
                            <td>
                              {!state.enableView &&
                                <Button
                                  size="xs"
                                  onClick={applicableRemoveButton(app, index)}
                                  variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                              {state.enableView &&
                                <Button
                                  size="xs"
                                  isDisabled={true}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                            </td>
                          </tr>

                        </React.Fragment>
                      ))}


                    </tbody>
                  </Table>
                </div>
              </Form.Group>
            </Form.Row>}


          <h4 className="subTitle plusHr">Route Commission</h4>

          <Routes configType={"Commission"} updateData={updateRoutesData} sendRoutesData={rotesInfo} routesData={routDetails.rts} sendRouteDelData={routeDelete} />

          {/* Class Type */}
          <h4 className="subTitle plusHr">Class Commission</h4>
          <Form.Row>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="cls_id">
              <Select
                options={airline_class_List}
                values={state.classType}
                onChange={(value) => (dispatch({ type: 'classType', payload: value }))}
              />
              <Form.Label>Class</Form.Label>
            </Form.Group >


            {/* Booking Code(Class) */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="code_id">
              <Select multi
                options={class_Code_List}
                values={state.classCode}
                onChange={(valuec) => (console.log('airline name selected value----' + valuec), bookicodeEvent(valuec))}
              />
              <Form.Label>RBD</Form.Label>
            </Form.Group >



            {/* Class comission ADD button */}
            <Form.Group as={Col}>
              {!state.enableView &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={addClassCommissionBtn}
                > Add</Button>
              }
              {state.enableView &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add Route</Button>
              }
            </Form.Group>
          </Form.Row>

          {/* Aftter click on routes add button applicable_display_data table */}
          {isArrayNotEmpty(state.class_json) &&
            (<Form.Row>
              <Form.Group as={Col} xs={6}>
                <div className="selectedRout tableLayout">
                  <Table>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Code</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.class_json.map((cls, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <span>{cls.ty}</span>
                            </td>
                            <td>
                              <span>{cls.rbd}</span>
                            </td>
                            <td>
                              {!state.enableView &&
                                <Button
                                  size="xs"
                                  onClick={removeClassCommissionBtn(cls, index)}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                              {state.enableView &&
                                <Button
                                  size="xs"
                                  isDisabled={true}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }

                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Form.Group>
            </Form.Row>)}

          <h4 className="subTitle plusHr">Commission</h4>
          {/* Comission Type */}
          <Form.Row>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
              <Select
                options={commission_Type}
                values={state.commissionType}
                onChange={(value) => (dispatch({ type: 'comissionType', payload: value }), dispatch({ type: "comissionTypeError", payload: false }))}
              />
              {state.comissionTypeError && (
                <p className="vError">Please select Commission On</p>
              )}
              <Form.Label>Commission Type <sup>*</sup></Form.Label>
            </Form.Group>


            {/* Comission On */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
              <Select
                options={commissionapplyon}
                values={state.comissionOn}
                onChange={(value) => (dispatch({ type: 'comissionOn', payload: value }), dispatch({ type: "comissionOnError", payload: false }))}
              />
              {state.comissionOnError && (
                <p className="vError">Please select Commission On</p>
              )}
              <Form.Label>Commission On <sup>*</sup></Form.Label>
            </Form.Group>

            {/* Comission value */}
            <Form.Group as={Col} xs={3} controlId="com_val_id">
              <Form.Control type="number" autoComplete="off" placeholder="Commission" value={state.comissionValue} onChange={handleChange} />
              <Form.Label>Commission </Form.Label>
            </Form.Group>
          </Form.Row>


          <Form.Row>
            {/* <Form.Group as={Col} xs={11}>
            <Form.Row> */}
            {/* Avaliable Branch names */}

            {/* Here we are maintaining two pick list why beceause for one pick list, fields are not clearing,
              and once we removed the data from datatable,if i add any new one removed one also adding,Due to this
              here two picklist we are using */}
            {(isArrayNotEmpty(state.branch_master_data) && state.resetPick1) &&
              <Form.Group as={Col} className="picklist" xs={6}>
                <PickList leftPaneLabel="Available Branch" rightPaneLabel="Selected Branch"
                  pickid="b_id" dataList={state.branch_master_data} sendData={pickldata} />
                <Form.Label>Branch Name<sup>*</sup></Form.Label>
              </Form.Group>}


            {/* ddd={[]} for clear the fileds we are using */}
            {(isArrayNotEmpty(state.branch_master_data) && state.resetPick2) &&
              <Form.Group as={Col} className="picklist" xs={6}>
                <PickList leftPaneLabel="Available Branch" rightPaneLabel="Selected Branch"
                  pickid="b_id" dataList={state.branch_master_data} sendData={pickldata} />
                <Form.Label>Branch Name<sup>*</sup></Form.Label>
              </Form.Group>
            }


            {/* Inventory/Office id */}
            {(state.resetPick1) &&
              <Form.Group as={Col} className="picklist" xs={6}>
                <PickList leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected"
                  pickid="i_id" dataList={state.officeId_master_data} sendData={pickldata} />
                <Form.Label>Inventory/Office ID<sup>*</sup></Form.Label>
              </Form.Group>
            }

            {/* isArrayNotEmpty(state.officeId_master_data) &&  */}
            {(state.resetPick2) &&
              <Form.Group as={Col} className="picklist" xs={6}>
                <PickList leftPaneLabel="Inventory available" rightPaneLabel="Inventory selected"
                  pickid="i_id" dataList={state.officeId_master_data} sendData={pickldata} />
                <Form.Label>Inventory/Office ID<sup>*</sup></Form.Label>
              </Form.Group>
            }
            {/* </Form.Row>
            </Form.Group> */}

            <Form.Group as={Col} >
              {/* Commission Add button */}
              {!state.enableView &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={commissionAddBtn}
                > Add</Button>
              }
              {state.enableView &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add</Button>
              }
            </Form.Group>
          </Form.Row>


          {/* Aftter click on Commission add button comission_display data table */}
          {isArrayNotEmpty(state.comission_display_data) &&
            (<Form.Row>
              <Form.Group as={Col} xs={6}>
                <div className="selectedRout tableLayout">
                  <Table>
                    <thead>
                      <tr>
                        <th>Branch</th>
                        <th>Officeid</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.comission_display_data.map((com, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <span>{com.branch_label}</span>
                            </td>
                            <td>
                              <span>{com.label}</span>
                            </td>
                            <td>
                              {!state.enableView &&
                                <Button
                                  size="xs"
                                  onClick={commissionRemoveBtn(com, index)}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                              {state.enableView &&
                                <Button
                                  size="xs"
                                  isDisabled={true}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Form.Group>
            </Form.Row>)}



          {/* Cancel Button */}

          <div className="switchPlus buttonGrop pb-0">
            {/* Status  */}
            <Form.Group as={Col} className="topSwitch">
              <Form.Check type="switch" id="act" label="Status" defaultChecked value={state.isActive}
                onChange={handleChange}
                custom className="ml-0" />
            </Form.Group>

            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleCancel}
            > Back</Button>

            {/* Save Button */}
            {!state.is_edit && context.logindata.roles !== undefined && context.logindata.roles.includes('C_M_C') && (
              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
                onClick={handleSaveCommissionBtn}
              >Save</Button>)}

            {/* Update Button */}
            {state.is_edit && !state.enableView && (<Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={handleUpdateCommissionBtn}
            >Update</Button>)}

            {state.is_edit && state.enableView &&
              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
                isDisabled={true}
              >Update</Button>
            }

          </div>

        </div>
      </>
    )
  }
  export default AirlineCommission