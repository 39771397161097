
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../App";
import Tabs from '../../common/tabs/Tabs';
import Utilities from '../../commonUtils/Utilities';
import RefundDetails from './RefundDetails';

function FlightSummaryRefund(props) {
  const [state, setState] = useState({})
  const [context, setContext] = useContext(Context);
  const [attPriceList, setAttPriceList] = useState([]);
  const [taPriceList, setTaPriceList] = useState([]);
  const [staPriceList, setStaPriceList] = useState([]);
  const [refundRes, setRefundRes] = useState(props.refundResp);
  const [tablist, setTablist] = useState({
    active: 'ATT'
  });

  useEffect(() => {
    if (refundRes !== undefined && refundRes.psngrs !== undefined) {
      if (context.logindata.utype === "sta_user") {
        setTablist({ active: "STA" });
      }
      if (context.logindata.utype === "ta_user") {
        setTablist({ active: "TA" });
      }
      let attFinalList = []; let taFinalList = []; let staFinalList = [];
      let attList = []; let taList = []; let staList = [];
      for (let pax of refundRes.psngrs) {
        let att = pax.price.filter(obj => obj.type !== undefined && obj.type === "ATT");
        attList.push(att[0]);
        let ta = pax.price.filter(obj => obj.type !== undefined && obj.type === "TA");
        taList.push(ta[0]);
        let sta = pax.price.filter(obj => obj.type !== undefined && obj.type === "STA");
        if (sta !== 0 && sta.length !== 0) {
          staList.push(sta[0]);
        }
      }
      let attOth=0;
      let taOth=0;
      for (let pax of refundRes.psngrs) {
        for (let val of pax.tktnum) {
          if (val.st === "Refund/booking" || val.st === "Refund/cancel") {
            if (attList.length !== 0) {
              attOth=val.att_ot_charges;
              createRefundPrice(val.pname, pax.ptype, attList, "ATT", attFinalList, taFinalList, staFinalList,val.att_ot_charges,0,val.mta_fare_used);
            }
            if (taList.length !== 0 && taList !== undefined) {
              taOth=val.ta_ot_charges;
              createRefundPrice(val.pname, pax.ptype, taList, "TA", attFinalList, taFinalList, staFinalList,val.ta_ot_charges,attOth,val.ta_fare_used);
            }
            if (staList !== 0 && staList.length !== 0) {
              createRefundPrice(val.pname, pax.ptype, staList, "STA", attFinalList, taFinalList, staFinalList,val.sta_ot_charges,taOth,val.sta_fare_used);
            }
          }
        }
      }
    }
  }, [])

  //Calculating the TAX
  const calculateTax = (taxes) => {
    if(taxes) {
    const total = taxes.reduce((prev, t) => {
      return Utilities.addPrice(prev, t.price, "Add");
    }, 0);
    return total;
  } else {
    return 0
  }
  }
  //Creating the Refund Price List ATT,TA and STA
  const createRefundPrice = (pname, paxType, list, type, attFinalList, taFinalList, staFinalList,otherCharge,prevOthrCharge,fare_used) => {
    if (paxType === "ADT" && list!==undefined) {
      var price = Utilities.addPrice(list[0].basefare, calculateTax(list[0].taxes), "Add");
      var refSup = Utilities.addPrice(Number(price), list[0].aircharge, "Sub");
     const adtreq=createFareRequest(pname,paxType,list[0],refSup,otherCharge,fare_used);
      if (type === "ATT") {
        adtreq.refundsup=adtreq.refundsup-otherCharge-fare_used;
        attFinalList.push(adtreq);
      } else if (type === "TA") {
        adtreq.refundsup=attFinalList[0].subtotal;
        if(prevOthrCharge!==0){
        adtreq.aircharge=Utilities.addPrice(adtreq.aircharge, prevOthrCharge, "Add");
        adtreq.subtotal=Utilities.addPrice(adtreq.subtotal, prevOthrCharge, "Sub");
        }
        taFinalList.push(adtreq);
      } else if (type === "STA") {
        adtreq.refundsup=taFinalList[0].subtotal;
        if(prevOthrCharge!==0){
          adtreq.aircharge=Utilities.addPrice(adtreq.aircharge, prevOthrCharge, "Add");
          adtreq.refundsup=Utilities.addPrice(adtreq.refundsup, prevOthrCharge, "Sub");
          adtreq.subtotal=Utilities.addPrice(adtreq.subtotal, prevOthrCharge, "Sub");
          }
        staFinalList.push(adtreq);
      }
      } else if (paxType === "CHD"){
      let chdreq="";
      if(list[0]!==undefined && list.length===1){
        var price = Utilities.addPrice(list[0].basefare, calculateTax(list[0].taxes), "Add");
        var refSup = Utilities.addPrice(Number(price), list[0].aircharge, "Sub");
        chdreq=createFareRequest(pname,paxType,list[0],refSup,otherCharge,fare_used);
      }else if(list[1]!==undefined){
        var price = Utilities.addPrice(list[1].basefare, calculateTax(list[1].taxes), "Add");
        var refSup = Utilities.addPrice(Number(price), list[1].aircharge, "Sub");
        chdreq=createFareRequest(pname,paxType,list[1],refSup,otherCharge,fare_used);
      }
      if (type === "ATT") {
        chdreq.refundsup=chdreq.refundsup-otherCharge-fare_used;
        attFinalList.push(chdreq);
      } else if (type === "TA") {
          //chdreq.refundsup=attFinalList[0].subtotal;
        if(prevOthrCharge!==0){
          chdreq.aircharge=Utilities.addPrice(chdreq.aircharge, prevOthrCharge, "Add");
          chdreq.refundsup=Utilities.addPrice(chdreq.refundsup, prevOthrCharge+fare_used, "Sub"); 
          chdreq.subtotal=Utilities.addPrice(chdreq.subtotal, prevOthrCharge, "Sub");
        }
        taFinalList.push(chdreq);
      } else if (type === "STA") {
         //chdreq.refundsup=taFinalList[0].subtotal;
        if(prevOthrCharge!==0){
          chdreq.aircharge=Utilities.addPrice(chdreq.aircharge, prevOthrCharge, "Add");
          chdreq.refundsup=Utilities.addPrice(chdreq.refundsup, prevOthrCharge+fare_used, "Sub");
          chdreq.subtotal=Utilities.addPrice(chdreq.subtotal, prevOthrCharge, "Sub");
          }
        staFinalList.push(chdreq);
      }
    } else if (paxType === "INF") {
      let infreq="";
      if(list[0]!==undefined && list.length===1){
        var price = Utilities.addPrice(list[0].basefare, calculateTax(list[0].taxes), "Add");
        var refSup = Utilities.addPrice(Number(price), list[0].aircharge, "Sub");
        infreq=createFareRequest(pname,paxType,list[0],refSup,otherCharge,fare_used);
      }else if(list[1]!==undefined && list.length===2){
        var price = Utilities.addPrice(list[1].basefare, calculateTax(list[1].taxes), "Add");
        var refSup = Utilities.addPrice(Number(price), list[1].aircharge, "Sub");
         infreq=createFareRequest(pname,paxType,list[1],refSup,otherCharge,fare_used);
    }else if(list[2]!==undefined && list.length===3){
        var price = Utilities.addPrice(list[2].basefare, calculateTax(list[2].taxes), "Add");
        var refSup = Utilities.addPrice(Number(price), list[2].aircharge, "Sub");
         infreq=createFareRequest(pname,paxType,list[2],refSup,otherCharge,fare_used);
    }
    if (type === "ATT") {
      infreq.refundsup=infreq.refundsup-otherCharge-fare_used;
      attFinalList.push(infreq);
    } else if (type === "TA") {
        //infreq.refundsup=attFinalList[0].subtotal;
        if(prevOthrCharge!==0){
          infreq.aircharge=Utilities.addPrice(infreq.aircharge, prevOthrCharge, "Add");
          infreq.refundsup=Utilities.addPrice(infreq.refundsup, prevOthrCharge+fare_used, "Sub");
          infreq.subtotal=Utilities.addPrice(infreq.subtotal, prevOthrCharge, "Sub");
        }
      taFinalList.push(infreq);
    } else if (type === "STA") {
      //infreq.refundsup=taFinalList[0].subtotal;
      if(prevOthrCharge!==0){
        infreq.aircharge=Utilities.addPrice(infreq.aircharge, prevOthrCharge, "Add");
        infreq.refundsup=Utilities.addPrice(infreq.refundsup, prevOthrCharge+fare_used, "Sub");
        infreq.subtotal=Utilities.addPrice(infreq.subtotal, prevOthrCharge, "Sub");
        }
      staFinalList.push(infreq);
    }
    }
    setAttPriceList(attFinalList);
    setTaPriceList(taFinalList);
    setStaPriceList(staFinalList);
  }

  //Common Fare response
  const createFareRequest =(pname,paxType,fare,supRef,otCharge,fare_used)=>{
   const  resp = {
      paxname: pname,
      ptype: paxType,
      basefare: fare.basefare,
      bkmarkup: fare.bkmarkup,
      bkservice: fare.bkservice,
      canmarkup: fare.canmarkup,
      canservice: fare.canservice,
      aircharge: fare.aircharge,
      supcharge: fare.supcharge,
      ocharges: otCharge,
      subtotal: fare.ttlrefund-otCharge-fare_used,
      taxes: calculateTax(fare.taxes),
      refundsup: supRef,
      fareused: fare_used,
      bk_tot_gst: Number(fare.bk_tot_gst).toFixed(2),
      bk_tot_tds: Number(fare.bk_tot_tds).toFixed(2)
    }
    return resp;
  }

  const content = {
    ATT: <RefundDetails refundData={attPriceList} type={"ATT"} />,
    TA: <RefundDetails refundData={taPriceList} type={"TA"} />,
    STA: <RefundDetails refundData={staPriceList} type={"STA"} />
  };


  return (
    <>
      {attPriceList.length !== 0 && taPriceList.length !== 0 &&
        <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
          <h4 className="dashBordTitle mb-3">Refund Details</h4>
          <div className="tabLink tabList">
            {context.logindata.utype === "att_user" && attPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                <span key="TA">Traval Agent</span>
                {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                  <span key="STA">Sub Travel Agent</span>
                }
              </Tabs>
            }
            {context.logindata.utype === "ta_user" && taPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="TA">Traval Agent</span>
                {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                  <span key="STA">Sub Travel Agent</span>
                }
              </Tabs>
            }
            {context.logindata.utype === "sta_user" && staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="STA">Sub Traval Agent</span>
              </Tabs>
            }
            <div className="tabContent">
              {content[tablist.active]}
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default FlightSummaryRefund;
