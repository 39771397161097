import React, { useState, useEffect, useContext } from 'react';
import Footer from '../../common/Footer'
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import MainMenu from '../../common/MainMenu'
import AirServiceConfigMenu from '../../common/subMenu/HotelServiceConfigMenu'
import log from "loglevel";
import Button from '../../common/buttons/Button'
import { useHistory } from 'react-router-dom';
import CreateServiceFee from './index';
import CommonSearch from '../common/HotelCommonSearch';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { retrieveServiceFee, editServiceFee, searchServiceFee } from './operations';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../../components/common/loader/InnerLoader'
import NoRecord from '../../common/popUps/NoRecord'
import { Context } from "../../../../App";
export const MarkupContext = React.createContext();
//This function will show the Air markups List and Add Markup
function AirMarkupConfiguration() {
  const history = useHistory();
  const [data, setData] = useState()
  const [markups, setMarkupList] = useState({ markupdata: [] });
  const [editData, setEditData] = useState();
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableView, setEnableView,] = useState(false);
  const [OldServiceCreated, setOldNames] = useState({oldData: []})

  const [state, setState] = useState({
    createMarkup: false,
    loading: false,
    editMarkup: false
  })


  // ========================================================================
  //  retrieveMarkups
  // Load all the data based on Login type and user id
  // ========================================================================
  // useEffect(() => {
  //   var userid="1";
  //   var utype="ATT_USER";
  //   if(utype!==undefined && userid!==undefined){
  //    const payload= {"userid":1,"utype":"ATT_USER"}
  //    retrieveServiceFee(payload).then(response => {
  //        console.log('retrive response',response)
  //     if(response.data.suc){
  //         console.log('entered success')
  //       let data=[];
  //       for(let val of response.data.res){
  //           console.log('entered for')
  //         var flowType="";
  //         if(val.ftype.includes("B")){
  //           flowType="Booking";
  //         }
  //         if(val.ftype.includes("A")){
  //           flowType=flowType+ " | Amendment";
  //         }
  //         if(val.ftype.includes("C")){
  //           flowType=flowType+ " | Cancellation";
  //         }
  //         if(val.ftype.includes("V")){
  //           flowType=flowType+ " | Void";
  //         }
  //         data.push({ mn: val.sname,ft:flowType, tt: val.ttype,status:(val.act===1)?"Active":"In-Acive", options: <><a onClick={editServiceBtnClick(val)}>Edit</a></> })
  //         console.log('data',data)
  //       }
  //       setMarkupList({markupdata:data})
  //     }
  //   }).catch(function (error) {
  //     log.error(
  //       "Exception occured in getMarkupList function---" + error
  //     );
  //   });
  //  }
  //  }, []);

  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "") {
      var userid = context.logindata.uid;
      var utype = context.logindata.utype;
      const payload = {
        "type": context.logindata.utype,
        "userid": context.logindata.uid,
        "str": "",
        "aplto":"" ,
        "bid": null,
        "cid": null,
        "cnt":"",
        "cty":"",
        "apltyp": "",
        "fmdate": "",
        "todate": "",
        "pcid" : context.logindata.cid,
        "agency_admin" : context.logindata.agency_admin,
        "region_type" : context.logindata.region_type,
        "is_admin": context.logindata.is_admin,
      }
      searchServiceFee(payload).then(response => {
        if (response.data.suc) {
          loadResponse(response);
          setIsLoadingMore(false);
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getServiceFee function---" + error
        );
      });
    }
  }, [context.logindata]);

  const createMarkupBtnClick = (e) => {
    setState({ createMarkup: true, loadBranch: false })
  }

  const editServiceBtnClick = (obj,type) => () => {
    setState({ editMarkup: true })
    editServiceFee(obj.id).then(response => {
      if (response.data.suc) {
        setEditData(response)
        if(type==="view"){
          setEnableView(true)
        }else{
          setEnableView(false)
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editServiceFee function---" + error
      );
    });
  }


  const loadResponse = (response) => {
    let data = [];
    let dataName = []
    var enableEdit = false;
    if (context.logindata.roles.includes('S_F_U')) {
      enableEdit = true;
    }
    for (let val of response.data.res) {
      var flowType = "";
      let ttype = ""
      if (val.fftype.includes("B")) {
        flowType = "Booking";
      }
      if (val.fftype.includes("A")) {
        flowType = flowType + " | Amendment";
      }
      if (val.fftype.includes("C")) {
        flowType = flowType + " | Cancellation";
      }
      if (val.fftype.includes("V")) {
        flowType = flowType + " | Void";
      }
      dataName.push(val.sname)
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){

      data.push({ mn: val.sn,region:val.region_type, ft: flowType, tt:val.hs, status: (val.act === 1) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editServiceBtnClick(val,"view")}></a> {enableEdit && <a className="ezyIcon icon-edit" onClick={editServiceBtnClick(val,"edit")}></a>}</span></> })
    }}
    console.log('namess',dataName)
    setOldNames({oldData: dataName})
    setMarkupList({ markupdata: data })
  }
  const columns = [{
    dataField: 'mn',
    text: 'ServiceFee Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'region',
    text: 'Region',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ft',
    text: 'Flow Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'tt',
    text: 'Supplier',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const searchFee = (data) => {
    searchServiceFee(data).then(response => {
      if (response.data.suc) {
        loadResponse(response);
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editServiceFee function---" + error
      );
    });
  }


  return (
    <>
      <MainMenu active='Hoel Services' />
      <Breadcrumb activePage="Service Fee" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Service Fee" />
          <div className="contentArea cmnSearch">
            {(!state.createMarkup && !state.editMarkup) ? (
              <>
                <CommonSearch configType={"Markup"} sendSearchCreteria={searchFee} />

                  {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_C') &&
                    <div className="d-flex justify-content-between buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn"
                      className="addPlus ezyIcon icon-plus"
                      onClick={createMarkupBtnClick}
                      type="submit">
                    Create Service Fee
                  </Button>
                  </div>
                  }

              </>
            ) : (
                (!state.editMarkup) ? (
                  <>
                    <CreateServiceFee createCall={true} oldDataNames = {OldServiceCreated.oldData}/>
                  </>
                ) : (
                    <>
                      <CreateServiceFee sendEditData={editData} disableBtn={enableView}/>
                    </>)
              )
            }
          </div>
        </div>

        {(!state.createMarkup && !state.editMarkup) ? (

          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
            {markups.markupdata.length !== 0 &&
              <BootstrapTable
                bootstrap4
                keyField="id"
                classes="markup filterTbl"
                data={markups.markupdata}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                pagination={markups.markupdata.length >10 && markups.markupdata.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                markups.markupdata.length >25 && markups.markupdata.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                markups.markupdata.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
              />
              || markups.markupdata.length === 0 &&
              <NoRecord />
            }
          </>)}

          </div>
        ) : ('')}

        {state.editMarkup ? (
          <>
            ''
      </>
        ) : ('')}

      </div>
      <Footer />
    </>
  )
}
export default AirMarkupConfiguration