/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
import React,{useState , useEffect} from 'react';
import {Alert, Col, Form, Row} from "react-bootstrap";
import DateUtils from "../../commonUtils/DateUtils";
import { isArrayNotEmpty } from '../../commonUtils/validators';
import Select from 'react-dropdown-select';
let bookingStatus = require('../../masterData/BookingStatus..json');
/**
 * @description: This function will show the Booking Details section
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
function FlightBookingDetails(props) {
    const [state, setstate] = useState()
    const [bookid, setBookid]=  useState({selBooking:[]})

    //setBooking Status
    const setStatus = (data) => {
    props.sendBookStatus(data[0].label)
  }

  useEffect(() => {
  if(props.isEdit && props.bk_det!==undefined && props.bk_det.length!==0){
  let bookingList=bookingStatus;
  for(let val of bookingList){
      if(val.value===props.bk_det[0].booking_status){
        setBookid({selBooking : [{ label: val.label, value: val.value }]})
      }
  }
  }
  }, [props.isEdit])




    return (

        <>

            {isArrayNotEmpty(props.bk_det) && (props.bk_det.map((bdet, index) => (
                <div className='bookingListinfo'>
                <React.Fragment key={index}>

                    <Row className="pb-2">
                        <Col xs={4}><span>Booking Number :</span> {bdet.booking_id}
                        </Col>
                        <Col xs={4} className={props.isEdit ? 'd-flex':''}><span>Booking Status : </span>
                        {!props.isEdit &&
                        <span className={(bdet.booking_status == "21") && ('text-primary') || (bdet.booking_status == "23") && ('text-success') || (bdet.booking_status == "25") && ('text-danger') || (bdet.booking_status == "28") && ('text-danger') || (bdet.booking_status == "30") && ('text-primary') || (bdet.booking_status == "22") && ('text-primary') || (bdet.booking_status == "21") && ('text-primary') || (bdet.booking_status == "23") && ('Confirmed') || (bdet.booking_status == "24") && ('text-primary')|| (bdet.booking_status == "31") && ('text-danger')|| (bdet.booking_status == "34") && ('text-danger') || (bdet.booking_status == "35") && ('text-danger') || (bdet.booking_status == "39") && ('text-danger') || (bdet.booking_status == "25") && ('text-primary') || (bdet.booking_status == "36") && ('text-success')}>{bdet.booking_status_str == "VoidCancel" ? "Void/Cancel" : bdet.booking_status_str}{} </span>

                        }
                       {props.isEdit &&
                       <Form.Group className="selectplugin editOne m-0" as={Col}  xs={6} controlId="firstName">
                       <Select
                         options={bookingStatus}
                         searchable={false}
                         values={bookid.selBooking}
                         onChange={(value) => (setStatus(value))}
                       />
                      </Form.Group>
                      }
                    </Col>

                        <Col xs={4}><span className='d-flex'>Customer Name : <span class="ctname pl-1" title={bdet.customer_name}> {bdet.customer_name}</span></span> </Col>



                    </Row>
                    <Row className="pb-2">
                        <Col xs={4}><span>Company Name : </span>{bdet.company_name}</Col>
                        <Col xs={4}><span>Booking Date : </span>{DateUtils.prettyDate(bdet.booking_date, "ddd, DD MMM, YYYY")}</Col>
                        <Col xs={4}><span>Phone : </span>{bdet.isd_code ? ("+" + bdet.isd_code + " - ") : ""} {bdet.phone_no}</Col>
                    </Row>

                    <Row className="pb-1">
                        <Col xs={4}><span>Email : </span> {bdet.email}</Col>
                        <Col xs={4}><span>Booked By : </span> {bdet.booked_by}</Col>
                        <Col xs={4}><span>Booking Type : </span> {bdet.bktype}</Col>
                      {bdet.dest_contact_no !== undefined && bdet.dest_contact_no !== null && bdet.dest_contact_no !== "" && <Col xs={4}><span>Destination Contact Number : </span>{bdet.dest_contact_no}</Col>}

                    </Row>
                   {bdet.tkt_time_limit !== null && <Row className="pb-1 pt-1">
                    {bdet.booking_status == "22" && bdet.tkt_time_limit !== null &&
                            <Col xs={4}><span>Ticketing Time Limit :</span>{bdet.tkt_time_limit}</Col>
                        }
                    </Row>}
                    {bdet.flow_type == "split" ?
                            <>
                                <Row className="pb-1 pt-1">
                                    {bdet.office_id !== null && props.utype == "att_user" &&
                                        <Col xs={4}><span>Onward Office Id : </span>{props.onward_supplier == 0 ? bdet.office_id.split("/")[0] : "N/A"}</Col>
                                    }
                                    {bdet.office_id !== null && props.utype == "att_user" &&
                                        <Col xs={4}><span>Return Office Id : </span>{props.return_supplier == 0 ? bdet.office_id.split("/")[1] : "N/A"}</Col>
                                    }
                                </Row>
                            </>
                            :
                            <>
                                {bdet.office_id !== null && props.utype == "att_user" && bdet.supplier == 0 &&
                                    <Row className="pb-1 pt-1">
                                        <Col xs={4}><span>Office Id : </span>{bdet.office_id}</Col>
                                    </Row>}
                            </>
                        }
                </React.Fragment>
                </div>
            )))}


        </>
    )
}


export default FlightBookingDetails;
