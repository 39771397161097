import React, { useState, useEffect } from "react";
import Picklist from "c2-react-picklist";

function PickList(props) {
  const [leftPaneLabel] = useState(props.leftPaneLabel);
  const [rightPaneLabel] = useState(props.rightPaneLabel);
  const [id] = useState(props.pickid);
  const [value, setValue] = useState([]);
  const [options, setoptions] = useState(props.dataList);

  useEffect(() => {
    if (props.selData !== undefined && props.type) {
      setValue(props.selData);
    }
  }, [props.selData]);

  useEffect(() => {
    if (props.ofcsData !== undefined) {
      setValue(props.ofcsData);
    }
  }, [props.ofcsData]);

  return (
    <Picklist
      options={props.dataList}
      leftPaneLabel={leftPaneLabel}
      rightPaneLabel={rightPaneLabel}
      value={value}
      onChange={(value) => (
        console.log("child.key---" + JSON.stringify(value)),
        setValue(value),
        props.sendData(value, id)
      )}
    />
  );
}

export default PickList;
