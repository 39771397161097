import React, { useReducer, useState, useEffect } from "react";
import { Alert, Col, Form, Row, Modal, Button } from "react-bootstrap";
import ShowHide from "../../../common/showHide/ShowHide";
import AirlinesMemo from "../../../common/airlines";
import { getSeatMapDetails } from "../review/operations";
//import flighttop from '../../../../assets/images/planefront.png'
export const SEL_SEG1 = "seg_1";
export const SEL_SEG2 = "seg_2";
export const SEL_SEG3 = "seg_3";
export const SEL_SEG4 = "seg_4";
export const SEL_SEG5 = "seg_5";
export const SEL_SEG6 = "seg_6";

const _ = require("lodash");

let check = false;
/**
 * Initial State Declaration
 */
const initialState = {
  noRecords: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  segmentList: [],
  flt_no: "",
  airline: "",
  seatsList: [],
  showSegments: true,
  showSetMap: true,
  confirmSeat: false,
  showSlectedSeats: false,
  paxList: 0,
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function SelectsSeats(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tepmSeglist, setTempSegList] = useState([]);

  useEffect(() => {
    if (props.paxList.length !== 0 && state.segmentList.length === 0) {   
      constructSeatMap();
      dispatch({ type: "paxList", payload: props.paxList.length });
    }
  }, [props.paxList]);

  const constructSeatMap = () => {
    let segmentsList = [];
    let counter = 1;
    if (
      props.srequest.origns[0].srcorigns[0].sup === "2" ||
      props.srequest.origns[0].srcorigns[0].sup === "4" ||
      props.srequest.origns[0].srcorigns[0].sup === "3" ||
      props.srequest.origns[0].srcorigns[0].sup === "7"
    ) {
      for (let seg of props.srequest.origns[0].srcorigns[0].seg) {
        seg.seg_id = counter;
        const payload = {
          seg_id: counter,
          dept: seg.da,
          flt_num: seg.fn,
          air_code: seg.ma,
          arvl: seg.ar,
          jtype: "O",
          selected: counter == 1 ? true : false,
          paxlist: createSeatPaxList(),
          tid: props.srequest.origns[0].srcorigns[0].tid,
        };
        if (counter === 1) {
          dispatch({ type: "flt_no", payload: seg.fn });
          dispatch({ type: "airline", payload: seg.ma });
        }
        segmentsList.push(payload);
        counter = counter + 1;
      }
    }
    if (props.srequest.tt === "2" || props.srequest.tt === 2) {
      if (
        props.srequest.origns[0].destorigins[0].sup === "2" ||
        props.srequest.origns[0].destorigins[0].sup === "3" ||
        props.srequest.origns[0].destorigins[0].sup === "4" ||
        props.srequest.origns[0].destorigins[0].sup === "7"
      ) {
        for (let seg of props.srequest.origns[0].destorigins[0].seg) {
          seg.seg_id = counter;
          const payload1 = {
            seg_id: counter,
            dept: seg.da,
            arvl: seg.ar,
            flt_num: seg.fn,
            air_code: seg.ma,
            selected: counter == 1 ? true : false,
            jtype: "R",
            paxlist: createSeatPaxList(),
            tid:
              props.srequest.sty == "split"
                ? props.srequest.origns[0].destorigins[0].tid
                : props.srequest.origns[0].srcorigns[0].tid,
          };
          if (counter=== 1) {
            dispatch({ type: "flt_no", payload: seg.fn });
            dispatch({ type: "airline", payload: seg.ma });
          }
          segmentsList.push(payload1);
          counter = counter + 1;
        }
      }
    }
    dispatch({ type: "segmentList", payload: segmentsList });
  };

  const createSeatPaxList = () => {
    let paxList = [];
    if (props.paxList.length !== 0) {
      for (let pax of props.paxList) {
        if (pax.fn !== "" && pax.ln !== "") {
          if (pax.ty !== "INF") {
            const req = {
              pax_id: pax.sno,
              pax_name: pax.tl + " " + pax.fn + " " + pax.ln,
              seat: "",
              rno: "",
              st: "",
              price: 0,
              sup_price: 0,
              sup_cur_price: 0,
              selected:false,
              is_inf:
                pax.inf !== undefined && pax.inf !== "" && pax.inf.ty === "INF"
                  ? 1
                  : 0,
            };
            paxList.push(req);
          }
        }
      }
      return paxList;
    }
  };

  const handleShow = () => {
    dispatch({ type: "seatsList", payload: [] });
    setShow(false);
    dispatch({ type: "showSetMap", payload: false });
    constructSeatMap();
    window.sessionStorage.removeItem(SEL_SEG1);
    window.sessionStorage.removeItem(SEL_SEG2);
    window.sessionStorage.removeItem(SEL_SEG3);
    window.sessionStorage.removeItem(SEL_SEG4);
    window.sessionStorage.removeItem(SEL_SEG5);
    window.sessionStorage.removeItem(SEL_SEG6);
    let request = "";
    if (
      props.paxList !== undefined &&
      props.paxList.length !== 0 &&
      props.paxList[0].fn !== ""
    ) {
      if (props.srequest.sty === "normal") {
        request = _.cloneDeep(props.srequest, false, "");
        if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
           request.origns[0].srcorigns[0].seg = [
            props.srequest.origns[0].srcorigns[0].seg[0],
          ];
        }else{
          request.origns[0].srcorigns = props.srequest.origns[0].srcorigns;
          request.origns[0].srcorigns[0].sel_ref=1;
        }
       
        if(request.origns[0].srcorigns[0].sup!=="7") {
        delete request.origns[0].destorigins;
        }
        request.sup = request.origns[0].srcorigns[0].sup;
        delete request.mappings;
        delete request.psngrs;
        delete request.flowType;
        for (let pax of props.paxList) {
          pax.seats = [];
        }
        request.psngrs = props.paxList
      } else {
        for (let org of props.srequest.origns) {
          if (
            org.srcorigns[0].sup === "2" ||
            org.srcorigns[0].sup === "3" ||
            org.srcorigns[0].sup === "4" ||
            org.srcorigns[0].sup === "7"
          ) {
            request = _.cloneDeep(props.srequest, false, "");
            request.origns[0].srcorigns = org.srcorigns;
            if((request.origns[0].srcorigns[0].sup!=="2" || request.origns[0].srcorigns[0].sup!=="0")  && (request.origns[0].srcorigns[0].sup!=="7" || request.origns[0].srcorigns[0].sup!=="0")){
            // if(request.origns[0].srcorigns[0].sup!=="2"  && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].sel_ref=1;
            }
            request.sup = request.origns[0].srcorigns[0].sup;
            request.tid= org.srcorigns[0].tid
            delete request.origns[0].destorigins;
            delete request.mappings;
            delete request.psngrs;
            delete request.flowType;
            for (let pax of props.paxList) {
              pax.seats = [];
            }
            request.psngrs = props.paxList
          } else {
            request = _.cloneDeep(props.srequest, false, "");
            request.origns[0].srcorigns = org.destorigins;
            request.sup = request.origns[0].srcorigns[0].sup;
            if((request.origns[0].srcorigns[0].sup!=="2" || request.origns[0].srcorigns[0].sup!=="0")  && (request.origns[0].srcorigns[0].sup!=="7" || request.origns[0].srcorigns[0].sup!=="0")){
            // if(request.origns[0].srcorigns[0].sup!=="2"  && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].sel_ref=1;
            }
            delete request.origns[0].destorigins;
            delete request.mappings;
            delete request.psngrs;
            delete request.flowType;
            request.tid= org.destorigins[0].tid
            request.src_code = props.srequest.dest_code
            request.dest_code = props.srequest.src_code
            for (let pax of props.paxList) {
              pax.seats = [];
            }
            request.psngrs = props.paxList
          }
          dispatch({ type: "seatsList", payload: [] });
        }
      }
      getSeatMapDetails(request).then((response) => {
        if (response.status === "success") {

          
          // if (props.srequest.sty === "normal") {

            if (request.origns[0].srcorigns[0].sup == 7) {
              //riya travels
              let riya_seat_response = response.data
              for (let eachSegment of state.segmentList) {
                let seat_availability = riya_seat_response.filter(function (s) {
                  if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                    return s
                  }
                })
                if (seat_availability && seat_availability.length > 0) {
                  if (eachSegment.seg_id == 1) {
                    dispatch({ type: "seatsList", payload: seat_availability[0].res });
                  }
                  let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                  window.sessionStorage.setItem(
                    tab_value,
                    JSON.stringify(seat_availability[0].res)
                  );

                }
              }

            } else {
              dispatch({ type: "seatsList", payload: response.data });
              window.sessionStorage.setItem(
                SEL_SEG1,
                JSON.stringify(response.data)
              );
            }

         

          setIsLoadingMore(true);
          setShow(true);
          dispatch({ type: "showSetMap", payload: true });

        } else {
          if (
            props.srequest.sty === "normal" &&
            props.srequest.origns[0].destorigins != undefined && props.srequest.origns[0].destorigins[0].sup != 7
          ) {
            var request1 = _.cloneDeep(props.srequest, false, "");
            if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
            request1.origns[0].srcorigns[0].seg = [
              props.srequest.origns[0].destorigins[0].seg[0],
            ];
           }else{
            request1.origns[0].srcorigns = props.srequest.origns[0].destorigins;
           }
            delete request1.origns[0].destorigins;
            delete request1.mappings;
            delete request1.psngrs;
            delete request1.flowType;
            getSeatMapDetails(request1).then((resp) => {
              if (resp.status === "success") {
                if (request.origns[0].srcorigns[0].sup == 7) {
                  //riya travels
                  let riya_seat_response = response.data
                  for (let eachSegment of state.segmentList) {
                    let seat_availability = riya_seat_response.filter(function (s) {
                      if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                        return s
                      }
                    })
                    if (seat_availability && seat_availability.length > 0) {
                      if (eachSegment.seg_id == 1) {
                        dispatch({ type: "seatsList", payload: seat_availability[0].res });
                      }
                      let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                      window.sessionStorage.setItem(
                        tab_value,
                        JSON.stringify(seat_availability[0].res)
                      );
    
                    }
                  }
    
                } else {
                  dispatch({ type: "seatsList", payload: response.data });
                  window.sessionStorage.setItem(
                    SEL_SEG1,
                    JSON.stringify(response.data)
                  );
                }
                // dispatch({ type: "seatsList", payload: resp.data });
                setIsLoadingMore(true);
                setShow(true);
                dispatch({ type: "showSetMap", payload: true });
                // window.sessionStorage.setItem(
                //   SEL_SEG1,
                //   JSON.stringify(resp.data)
                // );
              } else {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({
                  type: "notiMessage",
                  payload: "Seats are Not available for the selected Route",
                });
                dispatch({ type: "notiVarient", payload: "danger" });
                hidemessage();
              }
            });
          } else {
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Seats are Not available for the selected Route",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
          }
        }
      });
    } else {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({
        type: "notiMessage",
        payload: "Please enter atleast one pax details and proceed",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
    }
  };

  const handleEditShow = () => {
    let selSegData1 = JSON.parse(window.sessionStorage.getItem(SEL_SEG1));
    if (selSegData1 !== null && selSegData1 !== undefined) {
      for(let val of selSegData1){
        for(let seat of val.seats){
          seat.selected=false;
        }
      }
      let counter = 1;
      for (let seg of state.segmentList) {
        if (counter === 1) {
          seg.selected = true;
        } else {
          seg.selected = false;
        }
        for (let pax of seg.paxlist) {
          for (let seat of selSegData1) {
            if (
              pax.rno !== undefined &&
              pax.rno === seat.rno &&
              seg.jtype === "O" && counter === 1
            ) {
              for (let obj of seat.seats) {
                if (obj.sn === pax.seat) {
                  obj.selected = true;
                  break;
                }
              }
            }
          }
        }
        counter = counter + 1;
      }
      let list = _.cloneDeep(state.segmentList);
      setTempSegList(list);
      dispatch({ type: "seatsList", payload: selSegData1 });
      dispatch({ type: "showSetMap", payload: true });
      dispatch({ type: 'flt_no', payload: list[0].flt_num })
      dispatch({ type: 'airline', payload: list[0].air_code })
      setShow(true);
    }
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };

  const calculatePrice = (seg) => {
    let totalPrice = 0;
    for (let pax of seg.paxlist) {
      if (pax.seat !== "" && pax.price !== 0 && pax.price !== undefined) {
        if (totalPrice === 0) {
          totalPrice = Number(pax.price);
        } else {
          totalPrice = Number(totalPrice + Number(pax.price));
        }
      }
    }
    return totalPrice;
  };

  const handleChangeSegment = (segId) => (e) => {
    
    for (let obj of state.segmentList) {
      if (obj.seg_id === 1) {
        obj.selected = false;
      }
      if (obj.seg_id === segId) {
        dispatch({ type: "showSetMap", payload: false });
        obj.selected = true;
        var fltNumber = "";
        var airCode = "";
        let request = _.cloneDeep(props.srequest, false, "");
        for (let val of props.srequest.origns[0].srcorigns[0].seg) {
          if((props.srequest.origns[0].srcorigns[0].stop == 1 && segId <= 2) || segId == 1){
          if (val.seg_id === segId) {
            const SEG = "seg_" + segId;
            let selSegData1 = JSON.parse(window.sessionStorage.getItem(SEG));
            let restriction = false
            if(props.srequest.sty == "normal") {
              if(request.origns[0].srcorigns[0].sup == "7") {
                restriction = true
              }
            }
            if (selSegData1 !== null && selSegData1 !== undefined) {
                for(let val of selSegData1){
                  for(let seat of val.seats){
                    seat.selected=false;
                  }
                }
                if (obj.selected && obj.seg_id === segId) {
                  fltNumber = obj.flt_num;
                  airCode = obj.air_code;
                  for (let pax of obj.paxlist) {
                    for (let seat of selSegData1) {
                      if (
                        pax.rno !== undefined &&
                        pax.rno === seat.rno &&
                        obj.jtype === "O"
                      ) {
                        for (let s of seat.seats) {
                          if (s.sn === pax.seat) {
                            s.selected = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              dispatch({ type: "seatsList", payload: selSegData1 });
              dispatch({ type: "showSetMap", payload: true });
              dispatch({ type: "flt_no", payload: fltNumber });
              dispatch({ type: "airline", payload: airCode });
            } else if(!restriction){
              if(request.origns[0].srcorigns[0].sup!=="2" && request.origns[0].srcorigns[0].sup!=="7"){
              request.origns[0].srcorigns[0].seg = [val];
              }else{
              request.origns[0].srcorigns[0].sel_ref=val.ref;
              }
              // if(request.sup == undefined) {
              request.sup = props.srequest.origns[0].srcorigns[0].sup;
              if(props.srequest.sty === "split"){
                request.tid= props.srequest.origns[0].srcorigns[0].tid
              }

              // }
              delete request.origns[0].destorigins;
              delete request.mappings;
              delete request.psngrs;
              delete request.flowType;
              getSeatMapDetails(request).then((response) => {
                if (response.status === "success") {
                  if (request.origns[0].srcorigns[0].sup == 7) {
                    //riya travels
                    let riya_seat_response = response.data
                    for (let eachSegment of state.segmentList) {
                      let seat_availability = riya_seat_response.filter(function (s) {
                        if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                          return s
                        }
                      })
                      if (seat_availability && seat_availability.length > 0) {
                        if (eachSegment.seg_id == 1) {
                          dispatch({ type: "seatsList", payload: seat_availability[0].res });
                        }
                        let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                        window.sessionStorage.setItem(
                          tab_value,
                          JSON.stringify(seat_availability[0].res)
                        );
      
                      }
                    }
      
                  } else {
                    dispatch({ type: "seatsList", payload: response.data });
                    const SEG = "seg_" + segId;
                    window.sessionStorage.setItem(
                      SEG,
                      JSON.stringify(response.data)
                    );
                  }
                  // dispatch({ type: "seatsList", payload: response.data });
                  setShow(true);
                  dispatch({ type: "showSetMap", payload: true });
                  dispatch({ type: "flt_no", payload: obj.flt_num });
                  dispatch({ type: "airline", payload: obj.air_code });
                  const SEG = "seg_" + segId;
                  // window.sessionStorage.setItem(
                  //   SEG,
                  //   JSON.stringify(response.data)
                  // );
                } else {
                  dispatch({ type: "notiMessageShow", payload: true });
                  dispatch({
                    type: "notiMessage",
                    payload: "Seats are Not available for the selected Route",
                  });
                  dispatch({ type: "notiVarient", payload: "danger" });
                  hidemessage();
                }
              });
            }
          }
        } else if ((props.srequest.tt === "2" || props.srequest.tt === 2) && segId >= 2){
            const SEG = "seg_" + segId;
            let selSegData2 = JSON.parse(window.sessionStorage.getItem(SEG));
            var fltNumber = "";
            var airCode = "";
            let restriction = false
            if(props.srequest.sty == "normal") {
              if(request.origns[0].destorigins && request.origns[0].destorigins[0].sup == "7") {
                restriction = true
              }
            }
            if (selSegData2 !== null && selSegData2 !== undefined) {
              for(let val of selSegData2){
                for(let seat of val.seats){
                  seat.selected=false;
                }
              }
              for (let seg of state.segmentList) {
                if (seg.selected && seg.seg_id === segId) {
                  fltNumber = seg.flt_num;
                  airCode = seg.air_code;
                  for (let pax of seg.paxlist) {
                    for (let seat of selSegData2) {
                      if (
                        pax.rno !== undefined &&
                        pax.rno === seat.rno &&
                        seg.jtype === "R"
                      ) {
                        for (let obj of seat.seats) {
                          if (obj.sn === pax.seat) {
                            obj.selected = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
              dispatch({ type: "seatsList", payload: selSegData2 });
              dispatch({ type: "showSetMap", payload: true });
              dispatch({ type: "flt_no", payload: fltNumber });
              dispatch({ type: "airline", payload: airCode });
            } else if(props.srequest.origns[0].destorigins && !restriction){
              for (let val of props.srequest.origns[0].destorigins[0].seg) {
                if (val.seg_id === segId) {
                  if(request.origns[0].srcorigns[0].sup!=="2" && props.srequest.origns[0].destorigins[0].sup!=="7"){
                    request.origns[0].srcorigns[0].seg = [val];
                    }else{
                    request.origns[0].srcorigns=props.srequest.origns[0].destorigins;
                    request.origns[0].srcorigns[0].sel_ref=val.ref;
                    }
                  delete request.origns[0].destorigins;
                  delete request.mappings;
                  delete request.psngrs;
                  delete request.flowType;
                  request.sup = props.srequest.origns[0].destorigins[0].sup;
                  if(props.srequest.sty === "split"){
                  request.tid= props.srequest.origns[0].destorigins[0].tid
                  }

                  if (request.sup == 3) {
                    if (props.srequest.return_ugk) {
                      request.ugk = props.srequest.return_ugk;
                      request.sup_currency = props.srequest.return_sup_currency;
                    }
                  }
                  request.src_code = props.srequest.dest_code
                  request.dest_code = props.srequest.src_code
                  getSeatMapDetails(request).then((response) => {
                    if (response.status === "success") {
                      if (request.origns[0].srcorigns[0].sup == 7) {
                        //riya travels
                        let riya_seat_response = response.data
                        for (let eachSegment of state.segmentList) {
                          let seat_availability = riya_seat_response.filter(function (s) {
                            if (s.segment == (eachSegment.dept + "_" + eachSegment.arvl)) {
                              return s
                            }
                          })
                          if (seat_availability && seat_availability.length > 0) {
                            if (eachSegment.seg_id == 1) {
                              dispatch({ type: "seatsList", payload: seat_availability[0].res });
                            }
                            let tab_value = eachSegment.seg_id == 1 ? SEL_SEG1 : eachSegment.seg_id == 2 ? SEL_SEG2 : eachSegment.seg_id == 3 ? SEL_SEG3 : eachSegment.seg_id == 4 ? SEL_SEG4 : eachSegment.seg_id == 5 ? SEL_SEG5 : SEL_SEG6
                            window.sessionStorage.setItem(
                              tab_value,
                              JSON.stringify(seat_availability[0].res)
                            );
          
                          }
                        }
          
                      } else {
                        dispatch({ type: "seatsList", payload: response.data });
                        const SEG = "seg_" + segId;
                        window.sessionStorage.setItem(
                          SEG,
                          JSON.stringify(response.data)
                        );
                      }
                      // dispatch({ type: "seatsList", payload: response.data });
                      dispatch({ type: "showSetMap", payload: true });
                      dispatch({ type: "flt_no", payload: obj.flt_num });
                      dispatch({ type: "airline", payload: obj.air_code });
                      setShow(true);
                      const SEG = "seg_" + segId;
                      // window.sessionStorage.setItem(
                      //   SEG,
                      //   JSON.stringify(response.data)
                      // );
                    } else {
                      dispatch({ type: "notiMessageShow", payload: true });
                      dispatch({
                        type: "notiMessage",
                        payload:
                          "Seats are Not available for the selected Route",
                      });
                      dispatch({ type: "notiVarient", payload: "danger" });
                      hidemessage();
                    }
                  });
                }
              }
            }
          }
        }
      } else {
        obj.selected = false;
      }
    }
  };

  const checkIndigoInfAvail = (infAvail, infSeat,isPaxSelect) => {
    let checkInf = true;
    if (
      props.srequest.sty === "normal" &&
      props.srequest.origns[0].srcorigns[0].sup === "4"
    ) {
      if (infAvail === 1 && !infSeat && !isPaxSelect) {
        checkInf = false;
      }
    } else if (props.srequest.sty === "split") {
      let osupplier = props.srequest.origns[0].srcorigns[0].sup;
      let retsupplier = props.srequest.origns[0].destorigins[0].sup;
      if (osupplier === "4" || retsupplier === "4") {
        if (infAvail === 1 && !infSeat && !isPaxSelect) {
          checkInf = false;
        }
      }
    }
    return checkInf;
  };
  
  const handleSelectSeat = (obj, rowNo) => {
    let paxIsTrue = true
    dispatch({ type: "showSegments", payload: false });
    for (let seg of state.segmentList) {
      if (seg.selected) {
        for (let sobj of state.seatsList) {
          if (sobj.rno === rowNo) {
            for (let seat of sobj.seats) {
              for (let pax of seg.paxlist) {
                if(paxIsTrue){
                if (seat.id === obj.id) {
                  if (
                    seat.infant === undefined ||
                    (seat.infant !== undefined &&
                      checkIndigoInfAvail(pax.is_inf, seat.infant,pax.selected))
                  ) {
                    if (pax.seat === "" && !obj.selected) {
                      if (obj.seatKey) {
                        pax.seatKey = obj.seatKey;
                        pax.segKey = obj.segKey;
                      }
                      if(obj.seatId) {
                        pax.seatId = obj.seatId
                      }
                      pax.seat = obj.sn;
                      pax.rno = rowNo;
                      pax.price = obj.price;
                      pax.sup_price = obj.sup_price;
                      pax.sup_cur_price = obj.sup_cur_price;
                      pax.st = obj.st;
                      pax.selected=true;
                      obj.selected = true;
                      seg.price = calculatePrice(seg);
                      seat.selected = true;
                      dispatch({ type: "showSegments", payload: true });
                      if (props.srequest.sty === "split") {
                        window.sessionStorage.setItem(
                          SEL_SEG1,
                          JSON.stringify(state.seatsList)
                        );
                      }
                      paxIsTrue=!paxIsTrue
                      break;
                    } else {
                      if (
                        pax.seat !== "" &&
                        pax.seat === obj.sn &&
                        pax.rno === rowNo &&
                        seat.id === obj.id &&
                        obj.selected
                      ) {
                        if (obj.seatKey) {
                          pax.seatKey = obj.seatKey;
                          pax.segKey = obj.segKey;
                        }
                        if(obj.seatId) {
                          pax.seatId = obj.seatId
                        }
                        seat.selected = false;
                        obj.selected = false;
                        pax.seat = "";
                        pax.rno = "";
                        seg.price = seg.price - pax.price;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.price = 0;
                        pax.sup_price = 0;
                        pax.sup_cur_price = 0;
                        pax.selected=false;
                        paxIsTrue=!paxIsTrue
                        break;
                      }
                    }
                  } else {
                    dispatch({ type: "notiMessageShow", payload: true });
                    dispatch({
                      type: "notiMessage",
                      payload: "Infant Seat not available for selected Pax",
                    });
                    dispatch({ type: "notiVarient", payload: "danger" });
                    hidemessage();
                    break;
                  }
                }
              }
             }
            }
          }
        }
      }
    }
    dispatch({ type: "showSegments", payload: true });
  };

  const confirmSeat = () => {
    let checkPax = true;
    var seat = "";
    var seatRet = "";
    if (
      props.srequest.sty === "normal" &&
      (props.onwardSupp === "2" ||
        props.onwardSupp === "3" ||
        props.onwardSupp === "4")
    ) {
      seat = props.srequest.origns[0].srcorigns[0].pr.seats;
      if (props.srequest.tt === "2" || props.srequest.tt === 2) {
        seatRet = props.srequest.origns[0].destorigins[0].pr.seats;
      }
    }
    if (props.srequest.sty === "split") {
      if (
        props.onwardSupp === "2" ||
        props.onwardSupp === "3" ||
        props.onwardSupp === "4"
      ) {
        seat = props.srequest.origns[0].srcorigns[0].pr.seats;
      }
      if (
        props.returnSupp === "2" ||
        props.returnSupp === "4" ||
        props.returnSupp === "3"
      ) {
        seatRet = props.srequest.origns[0].destorigins[0].pr.seats;
      }
    }
    for (let seg of state.segmentList) {
      for (let pax of seg.paxlist) {
        if (pax.seat === "" && props.srequest.sty === "normal") {
          if (props.srequest.tt === "1" || props.srequest.tt === 1) {
            if (seat !== "" && seat === "Free") {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Please select Seat for all Pax",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            }
          } else {
            if (seg.seg_id === 1 && seat !== "" && seat === "Free") {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Please select Seat for all Pax",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            } else if (
              seg.seg_id === 2 &&
              seatRet !== "" &&
              seatRet === "Free"
            ) {
              checkPax = false;
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({
                type: "notiMessage",
                payload: "Please select Seat for all Pax",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              hidemessage();
            }
          }
        } else if (props.srequest.sty === "split") {
          if (
            seg.seg_id === 1 &&
            pax.seat === "" &&
            seat === "Free" &&
            seg.selected
          ) {
            checkPax = false;
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Please select Seat for all Pax",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
            break;
          } else if (
            seg.seg_id === 2 &&
            pax.seat === "" &&
            seatRet === "Free" &&
            seg.selected
          ) {
            checkPax = false;
            dispatch({ type: "notiMessageShow", payload: true });
            dispatch({
              type: "notiMessage",
              payload: "Please select Seat for all Pax",
            });
            dispatch({ type: "notiVarient", payload: "danger" });
            hidemessage();
            break;
          }
        }
      }
    }
    if (checkPax) {
      dispatch({ type: "confirmSeat", payload: true });
    }
  };
  const handleClose = () => {
    dispatch({ type: "segmentList", payload: tepmSeglist });
    setShow(false);
  };

  const closeConfirm = () => {
    dispatch({ type: "confirmSeat", payload: false });
  };

  const continueSelection = () => {
    dispatch({ type: "confirmSeat", payload: false });
    setShow(false);
    dispatch({ type: "showSlectedSeats", payload: true });
    let totalPrice = 0;
    if (state.segmentList.length !== 0) {
      for (let seg of state.segmentList) {
        for (let pax of seg.paxlist) {
          if (pax.seat !== "" && pax.price !== 0) {
            if (totalPrice === 0) {
              totalPrice = Number(pax.price);
            } else {
              totalPrice = Number(totalPrice + Number(pax.price));
            }
          }
        }
      }
      props.sendSeatPrice(totalPrice);
      let list = _.cloneDeep(state.segmentList);
      setTempSegList(list);
      props.sendSelectedSeats(state.segmentList);
    }
  };


  return (
    <>
      <div className="boxLayout additionalinfo pb-0 mt-3">
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
        <ShowHide visible="true" icon="seatSelect" title="Seat Selection">
          <div className="showHide-content pl-0 pr-0">
            <div className="extraListLayout">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  {!state.showSlectedSeats && (
                    <Button
                      variant="primary"
                      disabled={props.disableseat}
                      onClick={handleShow}
                    >
                      Select Seat
                    </Button>
                  )}
                  {state.showSlectedSeats && (
                    <Button
                      variant="primary"
                      disabled={props.disable}
                      onClick={handleEditShow}
                    >
                      Edit Seat
                    </Button>
                  )}
                </div>
              </div>
              <br />

              <div className="seatDetails">
                {state.showSlectedSeats && state.segmentList !== undefined && (
                  <p className="m-0">
                    You have successfully added seats for your trip. Below is a
                    summary of your selection :
                  </p>
                )}
                <Row>
                  {state.showSlectedSeats &&
                    state.segmentList !== undefined &&
                    state.segmentList.map((seg, idx) => (
                      <React.Fragment key={idx}>
                        <Col xs={6} className="flyType wnr">
                          <div className="selecteddestination">
                            <strong>{seg.dept}</strong>
                            <span className="roots">
                              <span className=" ezyIcon icon-flight"></span>
                            </span>
                            <strong>{seg.arvl}</strong>
                          </div>
                          <ul>
                            {seg &&
                              seg.paxlist.map((pax, idx) => (
                                <li>
                                  <strong>{pax.pax_name}</strong>{" "}
                                  <span
                                    className={
                                      pax.rno !== undefined ? "noseat" : ""
                                    }
                                  >
                                    {pax.rno}
                                    {pax.seat}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </ShowHide>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="seatselectPopup"
        size="lg"
      >
        <Modal.Body>
          <Row>
            <Col xs={5}>
              <div className="selecttrip pr-2">
                <h3 className="selectseatss mb-3">
                  Select Seats for your trip
                </h3>
                {state.airline !== "" && (
                  <div className="airLine mb-3">
                    <img
                      src={
                        "https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/" +
                        state.airline +
                        ".png"
                      }
                      className="logo"
                      alt="AirLine"
                    />
                    <div className="airLineName">
                      <strong>
                        <AirlinesMemo code={state.airline} />
                      </strong>
                      <span>
                        {state.airline} {state.flt_no}
                      </span>
                    </div>
                  </div>
                )}
                {state.showSegments &&
                  state.segmentList !== undefined &&
                  state.segmentList.map((seg, idx) => (
                    <React.Fragment key={idx}>
                      <div className="seatradio own">
                        <div className="sectionname">
                          <Form.Group controlId="seat" className="m-0">
                            <div className="d-flex align-items-center">
                              <Form.Check
                                type="radio"
                                id={idx + 1 + "seat"}
                                onChange={handleChangeSegment(seg.seg_id)}
                                checked={seg.selected}
                                value={seg.seg_id}
                                label=""
                                name="longfare"
                                custom
                              />
                              <label>
                                {seg.dept}{" "}
                                <span className="ezyIcon icon-flight"></span>{" "}
                                {seg.arvl}
                              </label>
                            </div>
                          </Form.Group>
                          <span>{props.currency} {seg.price}</span>
                        </div>
                        {seg &&
                          seg.paxlist !== undefined &&
                          seg.paxlist.map((pax, idx) => (
                            <ul className="p-0">
                              <li className="passangername">
                                <strong>{pax.pax_name}</strong>{" "}
                                <strong className="d-flex">
                                  <span className="d-flex">
                                    {pax.seat !== "" ? "Seat :" : ""}
                                    {pax.rno}
                                    {pax.seat}{" "}
                                  </span>
                                  <span className="ml-4">
                                    {pax.price !== 0 ? props.currency : ""}{" "}
                                    {pax.price !== 0 ? +Number(pax.price) : 0}{" "}
                                  </span>
                                </strong>
                              </li>
                            </ul>
                          ))}
                      </div>
                    </React.Fragment>
                  ))}
                <div className="col border-0">
                  <span className="subTitl">Legend</span>
                  <div className="avaibility">
                    <div className="sSeat selected">Selected</div>
                    <div className="sSeat available">Available</div>
                    <div className="sSeat occupied">Occupied</div>
                    <div className="sSeat freeSeat">Free</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={7} className="flightShape">
              <div className="plane">
                <div className="cockpit">
                  <span>
                    {/* <img
                                            src={flighttop}
                                            title="EZY Trip"
                                            alt="EZY Trip"
                                            /> */}
                  </span>
                </div>

                <ol className="cabin fuselage">
                  <li className="SeatType">
                    <ol className="seats" type="A">
                      <li className="seat">
                        <span>A</span>
                      </li>
                      <li className="seat">
                        <span>B</span>
                      </li>
                      <li className="seat">
                        <span>C</span>
                      </li>
                      <li className="seat">
                        <span>D</span>
                      </li>
                      <li className="seat">
                        <span>E</span>
                      </li>
                      <li className="seat">
                        <span>F</span>
                      </li>
                    </ol>
                  </li>
                  {state.showSetMap &&
                    state.seatsList.length !== 0 &&
                    state.seatsList.map((seatmap, index) => (
                      <React.Fragment key={index}>
                        <li className="SeatRow">
                          <ol className="seats">
                            {seatmap.seats.map((obj, idx) => (
                              <React.Fragment key={idx}>
                                <li
                                  className={
                                    Number(obj.price) !== 0
                                      ? "toolTipInfo seat"
                                      : "toolTipInfo seat free"
                                  }
                                >
                                  {obj.infant !== undefined && obj.infant ? (
                                    <div className="toolTipCUstoom">
                                      <span>
                                        <span className="d-flex align-items-center">
                                          <span className="ezyIcon icon-infant1">
                                            Infant Associated{" "}
                                          </span>
                                        </span>
                                        <span className="d-flex align-items-center justify-content-between">
                                          <span>{props.currency} {obj.price!=null?obj.price:0}</span>
                                        </span>
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="toolTipCUstoom">
                                      <span>{props.currency} {obj.price!=null?obj.price:0}</span>
                                    </div>
                                  )}
                                  <input
                                    type="checkbox"
                                    value={obj.sn}
                                    id={seatmap.rno + obj.sn}
                                    onChange={() =>
                                      handleSelectSeat(obj, seatmap.rno)
                                    }
                                    disabled={obj.st === "Booked"}
                                    checked={obj.selected}
                                  />
                                  {obj.infant !== undefined && obj.infant ? (
                                    <label for={seatmap.rno + obj.sn}>
                                      <span className="listIcon ezyIcon icon-infant1"></span>
                                    </label>
                                  ) : (
                                    <label for={seatmap.rno + obj.sn}>
                                      {seatmap.rno + obj.sn}
                                    </label>
                                  )}
                                </li>
                              </React.Fragment>
                            ))}
                          </ol>

                          {seatmap.isExit && (
                            <li>
                              <div className="exit exit--back fuselage"></div>
                            </li>
                          )}
                        </li>
                      </React.Fragment>
                    ))}
                </ol>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={confirmSeat}>
              Confirm Seat Selection
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.confirmSeat}
        onHide={closeConfirm}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>
                Are you sure you want to confirm the selected Seats?
              </strong>
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button size="xs" variant="secondary" onClick={closeConfirm}>
            No
          </Button>
          <Button size="xs" onClick={continueSelection} variant="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectsSeats;
