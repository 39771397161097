import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Hold Bookings
 * @param {*} Service 
 */

class AirportConfigurationService {

      async searchAirport(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/att/common/fetch-airdata`,data)  
      }
    }

export default new AirportConfigurationService()