
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../App";
import TripCost from '../../components/bookingFlows/flight/review/TripCost';
import AirportMemo from '../common/airlines/airportNames';
import Button from '../common/buttons/Button';
import DateUtils from '../commonUtils/DateUtils';
import Utilities from '../commonUtils/Utilities';
import AttCommonSerivce from '../../services/commonService/AttCommonSerivce';
import { retrivePrice, saveSegDetails } from './operations';

/**
 * @description:This function will load the Import PNR details
 * @author: Lakshmi
 * @param {*}
 * @function DashBoardPNRImport
 * @date : 17-11-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  all: false, selSegment: false, notiMessage: '', notiMessageShow: false, notiVarient: '', priceResp: "", showSegments: [], adt: 0, chd: 0, inf: 0,grandTotal: 0,gst_obj:"",pax_gst:0
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function DashBoardPNRImport(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selSegments, setSelSegments] = useState({ segments: [] })
  const [response, setResponse] = useState(props.pnrResponse.res)
  const [mappings, setMappings] = useState(props.pnrResponse.res.mappings)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [context, setContext] = useContext(Context);
  const [enbalePrice, setEnablePrice] = useState(false);
  const [enableFare, setEnableFare] = useState(false);
  const [priceFareResp, setPriceFareResp] = useState([]);
  const [fareList, setFareList] = useState([]);
  const [showPrice, setShowPrice] = useState(false);

  useEffect(() => {
    if (response !== undefined) {
      for (let segment of response.origns[0].srcorigns[0].seg) {
        segment.selected = false;
        if (segment.st !== "Confirmed") {
          setEnablePrice(true);
        }
      }
    }
  }, [response]);

 
  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "all") {
      if (state.all) {
        dispatch({ type: 'all', payload: false })
        for (let segment of response.origns[0].srcorigns[0].seg) {
          if (segment.st !== "Confirmed") {
            segment.selected = false
          }
        }
      } else {
        dispatch({ type: 'all', payload: true })
        for (let segment of response.origns[0].srcorigns[0].seg) {
          if (segment.st !== "Confirmed") {
            segment.selected = true;
          }
        }
      }
    }
  }
  //Updating seleted segment
  const handleChangeSegment = (obj, idx) => (e) => {
    for (let segment of response.origns[0].srcorigns[0].seg) {
      if (!segment.selected) {
        if (segment.ref === obj.ref && segment.st !== "Confirmed") {
          segment.selected = true;
        }
      } else {
        if (segment.ref === obj.ref && segment.st !== "Confirmed") {
          segment.selected = false;
        }
      }
    }
    //Checking segement seleted or Not
    var selectedSeg = false;
    for (let segment of response.origns[0].srcorigns[0].seg) {
      if (segment.selected) {
        selectedSeg = true;
        dispatch({ type: 'selSegment', payload: true })
        break;
      }
    }
    if (!selectedSeg) {
      dispatch({ type: 'selSegment', payload: false })
    }
    dispatch({ type: 'all', payload: false })
  }

  //Updating seleted segment
  const handleChangeFareBasis = (obj) => {
      for(let fare of state.priceResp.fareList) {
         var code="";
        if(obj.length!==0){
          code=obj[0].defaultCode
         }else{
          code=obj.defaultCode;
         }
        if(!fare.selected && fare[0].defaultCode === code){
            let orignsList = state.priceResp.origns.filter((src) => src.srcorigns[0].fcb === code);
            fare.selected=true;
            setPriceFareResp(orignsList[0])
            setShowPrice(true)
            if(showPrice){
              setEnableFare(true)
              setShowPrice(false)
            }else{
              setEnableFare(false)
              setShowPrice(true)
            }
        }else{
          fare.selected=false;
        }
    }
    setFareList(state.priceResp.fareList)
  }

  //Calling Price service for seleted Segment(s)
  const showPriceItinarary = () => {
    if (validateImport()) {
      setIsLoadingMore(true);
      let segments = [];
      let selectedSegments = [];
      if (state.all) {
        for (let seg of response.origns[0].srcorigns[0].seg) {
          if (seg.st !== "Confirmed") {
            segments.push({ "da": seg.da, "ar": seg.ar });
            selectedSegments.push(seg);
          }
        }
        setSelSegments({ segments: selectedSegments })
        dispatch({ type: 'showSegments', payload: segments })
      } else {
        for (let seg of response.origns[0].srcorigns[0].seg) {
          if (seg.st !== "Confirmed" && seg.selected) {
            segments.push({ "da": seg.da, "ar": seg.ar });
            selectedSegments.push(seg);
          }
        }
        setSelSegments({ segments: selectedSegments })
        dispatch({ type: 'showSegments', payload: segments })
      }
      var priceReq = createPriceReq(selectedSegments);
      //console.log("PRICE REQ :"+JSON.stringify(priceReq));
      retrivePrice(priceReq).then((resp) => {
        //console.log("PRICE RESP :"+JSON.stringify(resp.res));
        if (resp.suc) {
          let count=1;
          if(resp.res.fareList!==undefined && resp.res.fareList.length!==0){
            for(let fare of resp.res.fareList) {
                 for(let org of resp.res.origns){
                  for(let obj of fare){
                    if(org.srcorigns[0].fcb===obj.defaultCode){
                    let taxes = org.srcorigns[0].paxpr.filter((pax) => pax.ptype === obj.ptype);
                    if(taxes!==undefined && taxes.length!==0){
                    if(obj.ptype==="ADT"){
                      let taxPrice1 = Utilities.dealMkpCalculation(taxes[0].tax)
                      obj.price=Number(obj.price)+ Number(taxPrice1);
                    }else if(obj.ptype==="CHD"){
                      let taxPrice2 = Utilities.dealMkpCalculation(taxes[0].tax)
                      obj.price=Number(obj.price)+ Number(taxPrice2)
                    }else if(obj.ptype==="INF"){
                      let taxPrice3 = Utilities.dealMkpCalculation(taxes[0].tax)
                      obj.price=Number(obj.price)+ Number(taxPrice3)
                    }
                  }
                  }
              }
              }
              if(count===1){
                fare.selected=true
                setEnableFare(true)
                setPriceFareResp(resp.res.origns[0])
              }else{
                fare.selected=false
              }
              count=count+1;
          }
            setFareList(resp.res.fareList)
          }
          dispatch({ type: 'priceResp', payload: resp.res })
          //Cal GST
          if(context.logindata.cid!==0 && context.logindata.cid!==undefined && resp.res.service_fee!==null && context.logindata.region_type==="India"){
            AttCommonSerivce.getFetchGSTDetails({cid:context.logindata.cid}).then(response => {
              if(response.data.suc && response.data.data.length!==0){
               let gst= calculateGST(resp.res,response.data.data[0]);
                if(resp.res.fareList.length!==0 && gst.total_gst!==0){
                 let paxGST=Number(gst.total_gst) / resp.res.fareList.length
                 dispatch({ type: 'pax_gst', payload: paxGST })
                }
                setShow(true)
              }else{
                setShow(true)
              }
            })
            }else{
                setShow(true)
            }
          setIsLoadingMore(false);
          setShowPrice(false)
        } else {
          if(resp.res.status == "failed" && resp.res.description !== undefined && resp.res.description !== null){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: resp.res.description })

          dispatch({ type: 'notiVarient', payload: 'danger' })
          setIsLoadingMore(false);
          hidemessage();
        }
        }
      })
    }

  }
 //Cal GST
  const calculateGST=(resp,obj)=>{
          setShow(false)
          let gst={}
          const req={
            "gst":{
              "type":obj.state_type,
              "sgst":obj.cgst,
              "igst":obj.sgst,
              "cgst":obj.igst,
              "gst_invoice":[],
             }
          }
          let total=0;
           gst = Utilities.applyGST(resp.service_fee,req.gst,[],resp,"Flight");
           if(gst.gst_invoice.length!==0){
            for(let val of gst.gst_invoice){
              if(gst.type==="same"){
              if(total===0){
                total=Number(val.cgst_val)+Number(val.sgst_val);
              }else{
                total=total+Number(val.cgst_val)+Number(val.sgst_val);
              }
             }else{
              if(total===0){
                total=Number(val.igst_val);
              }else{
                total=total+Number(val.igst_val);
              }
             }
          }
        }
        gst.total_gst=total;
        dispatch({type: "gst_obj", payload: gst})
        return gst;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const createPriceReq = (segments) => {
    let segms = [];
    if (segments && segments !== undefined) {
      segms = segments;
    } else {
      segms = selSegments.segments;
    }
    const payload = {
      "cha": "B2B",
      "adt": response.adt,
      "chd": response.chd,
      "inf": response.inf,
      "cur": response.cur,
      "cid": context.logindata.cid,
      "bid": context.logindata.bid,
      "sty": "normal",
      "tt": "3",
      "tid": "",
      "office_id": response.office_id,
      "agent_office_id":response.agent_office_id,
      "butype": context.logindata.butype,
      "region_type": context.logindata.region_type,
      "req_cur": context.logindata.cur,
      "origns": [{
        "srcorigns": [
          {
            "tid": response.origns[0].srcorigns[0].tid,
            "ref": response.origns[0].srcorigns[0].ref,
            "office_id": response.office_id,
            "sdur": response.origns[0].srcorigns[0].sdur,
            "re": response.origns[0].srcorigns[0].re,
            "stop": response.origns[0].srcorigns[0].stop,
            "sup": response.origns[0].srcorigns[0].sup,
            "cl": response.origns[0].srcorigns[0].cl,
            "ft": response.origns[0].srcorigns[0].ft,
            "tktair": response.origns[0].srcorigns[0].tktair,
            "tecst": response.origns[0].srcorigns[0].tecst,
            "pnr": response.pnr,
            "seg": segms
          }
        ]
      }]
    }
    return payload;
  }

  const createBookReq = (res) => {
    let bag=[];
    if(showPrice || enbalePrice){
      bag=priceFareResp.srcorigns[0].bgd;
      bag[0].type="Checkin";
      const req={
        "qun": "7",
        "wt": "kg",
        "type":"Cabin"
      }
      bag.push(req)
    }else{
      bag=state.priceResp.origns[0].srcorigns[0].bgd;
      bag[0].type="Checkin";
      const req={
        "qun": "7",
        "wt": "kg",
        "type":"Cabin"
      }
      bag.push(req)
    }
    const payload = {
      "adt": response.adt,
      "chd": response.chd,
      "inf": response.inf,
      "cur": response.cur,
      "sty": "normal",
      "tt": "3",
      "tid": res.tid!==""?res.tid:"",
      "flowType": "IMPORT",
      "trans_id": response.trans_id,
      "cid": context.logindata.cid,
      "bid": context.logindata.bid,
      "butype": context.logindata.butype,
      "bkref": (response.bkref !== undefined) ? response.bkref : "",
      "psngrs": response.psngrs,
      "doc":res.doc!==undefined?res.doc:"",
      "region_type": context.logindata.region_type,
      "req_cur": context.logindata.cur,
      "office_id": response.office_id,
      "agent_office_id":response.agent_office_id,
      "origns": [{
        "srcorigns": [
          {
            "tid": response.origns[0].srcorigns[0].tid,
            "ref": response.origns[0].srcorigns[0].ref,
            "ofcid": response.origns[0].srcorigns[0].ofcid,
            "sdur": response.origns[0].srcorigns[0].sdur,
            "re": response.origns[0].srcorigns[0].re,
            "stop": response.origns[0].srcorigns[0].stop,
            "sup": response.origns[0].srcorigns[0].sup,
            "cl": response.origns[0].srcorigns[0].cl,
            "ft": response.origns[0].srcorigns[0].ft,
            "tktair": response.origns[0].srcorigns[0].tktair,
            "tecst": response.origns[0].srcorigns[0].tecst,
            "pnr": response.pnr,
            "seg": selSegments.segments,
            "dealcode": priceFareResp.srcorigns[0].dealcode ? priceFareResp.srcorigns[0].dealcode : undefined,
            "bgd": bag,
            "pr": (showPrice || enbalePrice)?priceFareResp.srcorigns[0].pr:state.priceResp.origns[0].srcorigns[0].pr,
            "paxpr": (showPrice || enbalePrice)?priceFareResp.srcorigns[0].paxpr:state.priceResp.origns[0].srcorigns[0].paxpr,
            "serviceFee": state.priceResp.service_fee,
            "office_id": response.office_id,
          }
        ]
      }],
      "mappings": response.mappings,
      "serviceFee": state.priceResp.service_fee,
      "src_country":response.src_country,
      "dest_country":response.dest_country,
      "gst":state.gst_obj!==undefined?state.gst_obj:""
    }
    return payload;
  }

  const validateImport = () => {
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    if (!state.all && !state.selSegment) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one segment and proceed' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      setIsLoadingMore(false);
      hidemessage()
      return false;
    }
    return true;
  }

  const continueBooking = () => {
    var req = createBookReq("");
    saveSegDetails(req).then((resp) => {
      if (resp.suc) {
        var bookReq = createBookReq(resp.res);
        history.push({
          pathname: '/ezytrip/bookings/flight/itinerary',
          state: {
            req: bookReq
          }
        });
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Something went Wrong.Please try Once again' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
      }
    })
  }
  const cancel = () => {
    return window.location = "/ezytrip/dashboard";
  }
//set grand total getting from tripcost props
const setGrandTotal = (gt) => {
  if(gt > 0 && state.grandTotal === 0) {
    dispatch({ type: "grandTotal" , payload: gt}) //grand total
  }
}
  return (
    <>

      <div className="container confirmation mt-3">
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <div className="resultSection boxShadow bg-white summary mb-4 pb-2">
          <h5 className="title d-flex justify-content-between">PNR Details</h5>
          <div className='bookingListinfo'>
          <Row className="pb-2">
            <Col xs={3}>
              <Row>
                <Col xs={12}><span>Travel Agent :</span> {context.logindata.cnm}</Col>
              </Row>
            </Col>
            <Col xs={3}>
              <Row>
                <Col xs={12}><span>PNR :</span> {response.pnr}</Col>
              </Row>
            </Col>
            <Col xs={3}>
              <Row>
                <Col xs={12}><span>PCC Details :</span> {response.ofcId}</Col>
              </Row>
            </Col>
            {/* <Col xs={3}>
              <Row>
                <Col xs={12}><span>Booking Reference No :</span> {""}</Col>

              </Row>
            </Col> */}
            <Col xs={3}>
              <Row>
                <Col xs={12}><span>Supplier  :</span> {"Amedeus"}</Col>

              </Row>
            </Col>
          </Row>
          </div>
        </div>
        <div className="resultSection boxShadow bg-white summary mb-4 pb-2">
          <h5 className="title d-flex justify-content-between">Passenger Details </h5>
          <div className='bookingListinfo'>
          <Row className="pb-2">
            {response.psngrs.map((pasenger, idx) => (

              <Col xs={3}>
                <Row>
                  {pasenger.lead &&
                    <Col xs={12}>{pasenger.tl.toUpperCase()} {pasenger.fn.toUpperCase() + " " + pasenger.ln.toUpperCase()} ({pasenger.plbl.toUpperCase()}) Lead</Col>
                  }
                  {!pasenger.lead &&
                    <Col xs={12}>{pasenger.tl.toUpperCase()} {pasenger.fn.toUpperCase() + " " + pasenger.ln.toUpperCase()} ({pasenger.plbl.toUpperCase()})</Col>
                  }
                  {pasenger.inf !== "" &&
                    <Col xs={12}>{pasenger.inf.tl.toUpperCase()} {pasenger.inf.fn + " " + pasenger.inf.ln} ({pasenger.inf.plbl.toUpperCase()})</Col>
                  }
                </Row>
              </Col>

            ))}
          </Row>
                  </div>
        </div>

        <div className="cardMainlayout bg-white mb-4">
          <h5 className="title d-flex justify-content-between mb-3">Flight Details</h5>
          {/* {isLoadingMore && <InnerLoader />} */}
          <div className="selectedRout tableLayout">
            <div className='table-responsive'>
            <Table>
              <thead>
                <tr>
                  <th width="10"> <Form.Check type="checkbox" label="" id="all" custom checked={state.all} value={state.all} onChange={handleChange} />
                  </th>
                  <th>Flight No. </th>
                  <th>Departure Date/Time</th>
                  <th>Arrival Date/Time</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {response.origns[0].srcorigns[0].seg.map((sege, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr>
                        <td>
                          {sege.st !== "Confirmed" &&
                            <Form.Check type="checkbox" label="" custom id={index + 1} checked={sege.selected} onChange={handleChangeSegment(sege, index)} />
                          }
                        </td>
                        <td>
                          <span>{sege.ac} {sege.fn}</span>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                          </div>
                        </td>
                        {sege.st === "Confirmed" &&
                          <td className="text-center status">
                            <div className="bookings d-flex justify-content-center">
                            <span className="confirm ezyIcon icon-Confirmed">Confirmed</span>
                            </div>
                          </td>
                        }
                        {sege.st === "Pending" &&
                          <td className="text-center status">
                            <div className="bookings d-flex justify-content-center">
                            <span className="pending ezyIcon icon-exclamation">Pending</span>
                            </div>
                          </td>
                        }
                        {sege.st !== "Pending" && sege.st !== "Confirmed" &&
                          <td className="text-center status">
                            <span className="pending ezyIcon icon-pending"></span>
                            <div className="bookings d-flex justify-content-center">
                              <span>Failed </span>
                            </div>
                          </td>
                        }
                      </tr>
                    </>
                  </React.Fragment>
                ))
                }
              </tbody>

            </Table>
            </div>
          </div>
          {enbalePrice &&
            <div className="text-right buttonGrop mt-4 pb-0">
              <Button
                size="xs"
                variant="outline-secondary"
                type="submit"
                onClick={cancel}
              > Cancel</Button>
              <Button
                size="xs"
                variant="outline-primary"
                onClick={showPriceItinarary}
                type="submit"
              >Price Itinerary</Button>
            </div>
          }
        </div>
        <div>
          {isLoadingMore && <div class="loaderPageBackdrop">
            <div class="loaderPage">
              <div class="loaderIcon"></div>
            </div>
          </div>}
          {show &&
            <Modal
              show={show}
              onHide={handleClose}
              className="importPNRMdl importPnrPop"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <h5>Flight Details</h5>
                <div className="dstn">
                  {state.showSegments !== 0 && state.showSegments.map((seg, idx) => (
                    <span>{seg.da} <em className="ezyIcon icon-oneside"></em> {seg.ar}</span>
                  ))}
                </div>
                {fareList && fareList.length!==0 &&
                  <div className="selectedRout tableLayout">

                    <h5 className="title d-flex justify-content-between">Select Fare options</h5>
                    <Table className='dashBoardPNR'>
                        {fareList.map((fcode, index) => (
                          <React.Fragment key={index}>
                              <thead>
                              <tr>
                                <th className='d-flex pl-0 ml-0'>
                                <Form.Check type="radio" label="" custom id={"fb"+index+1} checked={fcode.selected} value={fcode.selected} onChange={()=>handleChangeFareBasis(fcode)} /><span>Fare Basis</span>
                                </th>
                                <>

                                <th>Passenger</th>
                                <th>Baggage</th>
                                <th className="text-center">Fare(QAR)</th>
                                </>
                              </tr>
                            </thead>

                            {fcode.map((fare, idx) => (
                           <React.Fragment key={idx}>

                            <>
                            <tbody>

                              <tr>
                                <td>
                                  <span>{fare.code}</span>
                                </td>
                                <td>
                                  <span>{fare.ptype}</span>
                                </td>
                                {fare.bgd!==undefined &&
                                  <td>
                                  <span>{fare.bgd[0].qun} {fare.bgd[0].wt}</span>
                                </td>
                                }
                               <td>
                                  <span>{fare.price + Number(state.pax_gst)}</span>
                                </td>
                              </tr>
                              </tbody>
                            </>
                            </React.Fragment>
                        ))
                        }
                          </React.Fragment>
                        ))
                        }


                    </Table>
                  </div>
                   }
                <h5 className="mt-3">Fare Details</h5>
                {state.priceResp !== undefined && !enableFare && !showPrice &&
                  <TripCost seatPrice={0} tripCost={state.priceResp.origns[0]} srequest={state.priceResp} cur={state.priceResp.cur} flowtype="import" serviceFee={state.priceResp.service_fee} grandTotal={setGrandTotal} gstObj={state.gst_obj}/>
                }
                {enableFare &&
                  <TripCost seatPrice={0} tripCost={priceFareResp} srequest={state.priceResp} cur={state.priceResp.cur} flowtype="import" serviceFee={state.priceResp.service_fee} grandTotal={setGrandTotal} gstObj={state.gst_obj}/>
                }
                 {showPrice &&
                  <TripCost seatPrice={0} tripCost={priceFareResp} srequest={state.priceResp} cur={state.priceResp.cur} flowtype="import" serviceFee={state.priceResp.service_fee} grandTotal={setGrandTotal} gstObj={state.gst_obj}/>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>
                  Cancel
          </Button>
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={continueBooking}
                  type="submit"
                >Continue</Button>
              </Modal.Footer>
            </Modal>
          }
        </div>
      </div>
    </>
  )
}
export default DashBoardPNRImport