import React, { useEffect, useState, useContext } from "react";
import { Alert, Col, Form ,Modal, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import VisaBookingService from "../../../../services/bookingService/visa/VisaBookingService";
import { Context } from "../../../../../App";
import log from "loglevel";
import { useHistory } from "react-router-dom";
import SelectVisaType from "./selectvisatype";

function VisaSearch() {
  const [state, setState] = useState({ na: [], trav_cnt: "", visa_type: "" });
  const [context, setContext] = useContext(Context);
  const history = useHistory();
  const [response,setResponse] = useState("")
  const [visaSearchResp,setVisaSearchResp] = useState("")
  const [show, setShow] = useState(false);
  const [adt, setAdult] = useState([{value:1, label:"1"}]);
  const [chd, setChd] = useState([{value:0, label:"0"}]);
  const handleClose = () => setShow(false);
  const [selectedFareDet, setSelectedFareDet] = useState("");
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [addTraveller, setAddTraveller] = useState(false);
  const [showError, setShowError] = useState(false);
  const [trvl_cnt_list,setTrvl_cnt_list] = useState([]);

  const adtList = Array.from({length:26 - chd[0]?.value }, (p, i)=> ({value:i + 0, label:(i+0).toString()}))
  const chdList = Array.from({length:26 - adt[0]?.value }, (p, i)=> ({value:i + 0, label:(i+0).toString()}))

  useEffect(() => {
    VisaBookingService.getVisaDetails({"region_type":context.logindata.region_type,"sales_chanel":"B2B"}).then((response) => {
        if (response.data.suc) {
          setState({...state, na:[]}) 
          setResponse(response.data.res);
        }
      }).catch(function (error){
        log.error("Exception occured in getVisaDetails function---" + error);
      });
  }, [context.logindata.region_type]);
useEffect(()=>{
  state.na=""
  state.trav_cnt=""
  state.visa_type=""
console.log("pappapapapapap",context.logindata.region_type)
debugger
},[context.logindata.region_type])
 const handleTravellersChange = (e) => {
  if (addTraveller) {
    setAddTraveller(false)
  } else {
    setAddTraveller(true)
  }
 }

 const coloseTravel = () => {
    setAddTraveller(false);
  }
  const disableNoti = () => {
    setShowError(false);
  }
  
  const handleAdtChange = (selected) => {
    setAdult(selected)
  }
  const handleChdChange = (selected) => {
      setChd(selected)
  }

  const hidemessage = () => {
    setTimeout(function () {
      setNotiVarient('');
      setNotiMessage("");
      setNotiMessageShow(false)
    }, 5000);
  }

  const validateSearch=()=>{
    if (context.logindata.utype === "att_user") {
      if (context.logindata.cid === 0) {
        setNotiMessageShow(true);
        setNotiMessage("Please select Company");
        setNotiVarient("danger");
        hidemessage()
        return false
      }
    }
    if(state.na===""){
      setNotiMessageShow(true);
      setNotiMessage("Please select Nationality");
      setNotiVarient("danger");
      hidemessage()
      return false
    }else if(state.trav_cnt===""){
      setNotiMessageShow(true);
      setNotiMessage("Please select Travelling Country");
      setNotiVarient("danger");
      hidemessage()
      return false
    }else if(state.visa_type===""){
      setNotiMessageShow(true);
      setNotiMessage("Please select Visa Type");
      setNotiVarient("danger");
      hidemessage()
      return false
    }
    else if(adt[0].value==0 && chd[0].value==0){
      setNotiMessageShow(true);
      setNotiMessage("Please select atleast one traveller");
      setNotiVarient("danger");
      hidemessage()
      return false
    }
    if(state.na!=="" && state.trav_cnt!=="" && state.na===state.trav_cnt){
      setNotiMessageShow(true);
      setNotiMessage("Nationality and Travelling Country should not be same");
      setNotiVarient("danger");
      hidemessage()
      return false
    }
    return true;
  }

const handleShow = () =>{
  if(validateSearch()){
  const request={
      "na":state.na[0].label,
      "trvel_cnt":state.trav_cnt[0].label,
      "visa_type":state.visa_type[0].label,
      "adt":adt[0].value,
      "chd":chd[0].value,
      "user_id":context.logindata.uid,
      "region_type":context.logindata.region_type,
      "sales_chanel":"B2B",
      "company_id":context.logindata.cid
  }
  VisaBookingService.getVisaSearch(request).then((response) => {
    if (response.data.suc) {
      setShow(true)
      setVisaSearchResp(response.data.res);
    }else{ 
      if (response.data.err.includes("No Data")){
      setNotiMessageShow(true);
      setNotiMessage("No data found for this scenario");
      setNotiVarient("danger");
      hidemessage()
      return false
    }
  }
  }).catch(function (error){
    log.error("Exception occured in getVisaDetails function---" + error);
  });
}
}

const handleFareSelect =(e)=>{
  setSelectedFareDet(e)
}
const hideModal =(e)=>{
  setTimeout(setNotiMessageShow(false), 5000);

}
const handleVisaform = () =>{
  if(selectedFareDet===""){
setShowError(true)
return
  }
  VisaBookingService.getViseaPriceDetails(selectedFareDet.id).then((response) => {
    if (response.data.suc){
      selectedFareDet.adt_cnt= adt[0]?.value;
      selectedFareDet.chd_cnt=chd[0]?.value
      response.data.res.adt_cnt= adt[0]?.value;
      response.data.res.chd_cnt=chd[0]?.value
      history.push({
        pathname: '/ezytrip/booking/visa/visaform',
        state: {
          res: response.data.res,
          req: selectedFareDet
        }
      });
    }
  }).catch(function (error){
    log.error("Exception occured in getVisaDetails function---" + error);
  });
}

const loadTravelCnts=(e) => {
  if(e.length!==0){
    setState({...state, na:e}) 
  VisaBookingService.getVisaTravCntsDetails({"region_type":context.logindata.region_type,"sales_chanel":"B2B","na":e[0].Value}).then((response) => {
    if (response.data.suc) {
      setTrvl_cnt_list(response.data.res.trvl_cnt_list);
      
    }
  }).catch(function (error){
    log.error("Exception occured in getVisaDetails function---" + error);
  });

}
}


  return (
    <>
    {response!=="" &&
    <div className="searchTab visaSearchPart">
      <Alert
              className="notification"
              variant={notiVarient}
              show={notiMessageShow}
              onClose={() =>
                setNotiMessageShow(false)
              }
              
              dismissible
            >
              {" "}
              {notiMessage}
            </Alert>
      <Form.Row>
      <div className="addInput natioNality">
      <Form.Group as={Col}>
      <Form.Label><span className="ezyIcon icon-Nationality"></span> Nationality</Form.Label>
      <Select
        //value={selectedOption}
        isSearchable={false}
        onChange={loadTravelCnts}
        options={response.na_cnt_list}
        values={state.na}
        className="dropCountry"
        placeholder="Select Nationality"
              
    />
        </Form.Group>
        </div>
        <div className="addInput apCountry">
      <Form.Group as={Col}>
      <Form.Label><span className="ezyIcon icon-Country"></span> Applicable Country</Form.Label>
      <Select
        //value={selectedOption}
        isSearchable={false}
        onChange={(e)=>(setState({...state, trav_cnt:e}))}
        options={trvl_cnt_list}
        value={state.trav_cnt}
        className="dropCountry"
        placeholder="Select Country"
    />
        </Form.Group>
        </div>
        <div className="addInput visaTyp">
      <Form.Group as={Col}>
      <Form.Label><span className="ezyIcon icon-Visa"></span> Visa Type</Form.Label>
      <Select
        //value={selectedOption}
        isSearchable={false}
        onChange={(e)=>(setState({...state, visa_type:e}))}
        options={response.visa_types}
        value={state.visa_type}
        className="dropCountry"
        placeholder="Select Visa"
    />
        </Form.Group>

        </div>

        <div className="addInput TrvTyp">
        <Form.Group as={Col} className="traveller" >
              <Form.Label><span className="ezyIcon icon-Add-Traveller"></span> Travellers</Form.Label>
              <Form.Control 
              onClick={handleTravellersChange} 
              type="text" 
              placeholder={adt[0]?.value + " Adult " +(chd[0]?.value>0?chd[0].value +" Children":"")} 
              autoComplete="off" 
              readOnly />

      { addTraveller &&
        <div className="dropDownPopUP travell">
        <h5>No. of Travellers <span onClick={coloseTravel} className="closeTravel ezyIcon icon-close"></span></h5>
        <div className="class d-flex  align-items-center justify-content-between">
          <label>Adult(18-70 yrs) </label>
          <div className="selectWidth">
            <Select
            isSearchable={false}
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={handleAdtChange}
            options={adtList}
            values={adt}
          /></div>
        </div>
        <div className="class d-flex  align-items-center justify-content-between">
          <label>Child(0-17 yrs)</label>
          <div className="selectWidth">
            <Select
            isSearchable={false}
            placeholder = "Select"
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={handleChdChange}
            options={chdList}
            values={chd}
          /></div>
        </div>
      </div>}
          </Form.Group>
      </div>

        
        <Form.Group className="searchHere m-0" as={Col}>
        <button type="button" class="btn-primary btn-lg d-flex btn" onClick={handleShow}><span class="d-flex">Get Price</span></button>
          </Form.Group>
      </Form.Row>
 
    </div>
    } 
    <Modal show={show} onHide={handleClose} className="VisaLandingpop"  size="lg">
        <Modal.Header closeButton>
          <Modal.Title>   <span className="d-flex"><span className="ezyIcon icon-Visa"></span>Select Your visa type</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectVisaType onFareSelect={handleFareSelect} cur={context.logindata.cur} searchRes={visaSearchResp} showError={showError} disableNoti={disableNoti}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleVisaform}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
     );
}
export default VisaSearch;