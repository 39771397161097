import React, { useEffect, useState } from "react";
import FormateCurrency from "../../../../commonUtils/FormateCurrency";
import Utilities from "../../../../commonUtils/Utilities";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AirlinesMemo from "../../../../common/airlines";

function ResultpageTopfilter(props) {
  const [airLinesList, setAirLinesList] = useState([]);
  const [isSlider, setIsSlider] = useState(false);
  const [sel, setSel] = useState([]);
  useEffect(() => {
    if (sessionStorage.getItem("ISRESET") === "true") {
      props.topFilter("")
      if(props.initResp.length>1 && props.initResp !== null) {
        props.handelChange(props.initResp, "clear");
      }
      sessionStorage.setItem("ISRESET", false);
      setSel([])
      sessionStorage.removeItem("ISRESET");
    }
  }, [sessionStorage.getItem("ISRESET")]);
  useEffect(() => {
    if (
      props.initResp &&
      props.initResp.filter &&
      props.initResp.filter.airl &&
      props.initResp.filter.airl.length > 0
    ) {
      setAirLinesList(props.initResp.filter.airl);
      if (props.initResp.filter.airl.length > 5) {
        setIsSlider(true);
      }
    }
  }, [props.initResp]);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    autoplay: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const onClickHandler = (obj) =>{

    props.topFilter(obj.airline)
    setSel(obj.airline);
  }
  useEffect(() => {
    setSel([])
  },[sessionStorage.getItem('FILTERCLICKED')])

  return (
    <>
      <div className={"filterLogoslider" + " checkSlider " + isSlider}>
        <Slider {...settings}>
          {airLinesList !== undefined &&
            airLinesList.length > 0 &&
            airLinesList
              .map((airline, index) => (
                <div
                  onClick={(e) => {


                    onClickHandler(airline);
                  }}
                >
                  <div
                    className={
                      sel.includes(airline.airline)
                        ? "airLogo active"
                        : "airLogo"
                    }
                  >
                    <img
                      src={
                        "https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/" +
                        airline.airline +
                        ".png"
                      }
                      className="logo"
                      alt="AirLine"
                    />
                    <div class="flightNo d-flex flex-column">
                      <span className="airLineName">
                        <span>
                          <span title={airline.airline}>
                            <AirlinesMemo code={airline.airline} />
                          </span>
                        </span>
                      </span>
                      <span className="priceQar">
                        {props.searchRes.cur}{" "}
                        {
                          <FormateCurrency
                            value={Utilities.addPrice(airline.prc, 0, "Add")}
                          />
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))
              .slice(0, props.searchRes.filter.airl.length)}
        </Slider>
      </div>
    </>
  );
}
export default ResultpageTopfilter;
