
import React , { useEffect, useReducer }from 'react';
import { Col, Row } from "react-bootstrap";
import FlightSegmentDetails from './FlightSegmentDetails';
import AirportMemo from '../../../common/airlines/airportNames';
import CityNameMemo from '../../../common/airlines/cityNames';
import DateUtils from "../../../commonUtils/DateUtils";
import { isNotNull } from '../../../commonUtils/validators';
import SegmentDetails from '../review/SegmentDetails';
import FlightImportSegmentDetails from './FlightSegmentImportDetails';
const _ = require('lodash')
/**
 * @description:This function will create the Sector detals
 * @author: Lakshmi
 * @param {*}
 * @function Flight sector details
 * @date : 01-09-2020
 */
/**
  * Initial State Declaration
  */
 const initialState = {
    import_segments:[],finalisd_import_seg:{},import_review_segment:[],import_tag_type : "",class:""
};

// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};
function FlightSectors(props) {
    //Origin json data
    //Origin json data
    const origin_data = props.origin !== undefined && props.origin[0] !== undefined && props.origin[0];
    const mappings = isNotNull(props.mapping) && props.mapping;
    //Page Type  Ex:Confirm or Review
    const type = props.origin !== undefined && props.origin[1] !== undefined && props.origin[1];


    const onwardsList = props.originsList !== undefined && props.originsList[0] !== undefined && props.originsList[0]

    //Page Type  Ex:Confirm or Review
    const types = props.originsList !== undefined && props.originsList[1] !== undefined && props.originsList[1];

    const [state, dispatch] = useReducer(reducer, initialState);
    /**
     * if import need to handle the specific layover scenarios
     * @author : Azamuddin
     * @date : 18-08-2021
     */
    useEffect(() => {
        if(props.origin!==undefined && props.origin.length!==0 && props.origin[0].fareop!==undefined){
            let ftypes = props.revieWFlt===undefined?props.origin[0].fareop.filter(obj=>obj.selected):[]     
            if(ftypes.length!==0){
            if(ftypes[0].pr.ftyp.includes("Regular")){
                dispatch({type:"class",payload:"Saver"})
            }else if(ftypes[0].pr.ftyp.includes("Flexi")){
                dispatch({type:"class",payload:"Flexi"})
            }else if(ftypes[0].pr.ftyp.includes("Corporate")){
                dispatch({type:"class",payload:"Corporate"})
            }else{
                dispatch({type:"class",payload:ftypes[0].pr.ftyp})
            }
        }else{
            if(origin_data!==undefined && origin_data.pr!==undefined && origin_data.pr.ftyp!==undefined){
                if(origin_data.pr.ftyp.includes("Regular")){
                    dispatch({type:"class",payload:"Saver"})
                }else if(origin_data.pr.ftyp.includes("Flexi")){
                    dispatch({type:"class",payload:"Flexi"})
                }else if(origin_data.pr.ftyp.includes("Corporate")){
                    dispatch({type:"class",payload:"Corporate"})
                }else{
                    dispatch({type:"class",payload:origin_data.pr.ftyp})
                }
            }
        }
      }
       
        if(props.bktype  && (props.bktype == "IMPORT" || props.bktype == "IMPORT/REISSUE") && ((props.originsList !== undefined && props.originsList[0] !== undefined && props.originsList[0]) || (props.origin !== undefined && props.origin[0] !== undefined && props.origin[0])) && state.import_segments.length === 0) {
        //******if import booking then handle accordingly *********/
            let initData = [] //segment details
            let con_page = ""
            if(props.originsList) {
                initData = props.originsList[0].srcorigns[0].seg
                con_page = props.originsList[1]
            } else {
                initData = props.origin[0].seg
                con_page = props.origin[1]
            }
            let count = 1 // count of seg order for further use
            for(let val of initData) { //iteration of seg details
                if(val) {
                val.trip_type = 1
                val.seg_order = count
                count = count + 1
                }
            }
            let  new_seg_details =  constructSegmentForImport(initData) //handle layover time or new departure

            if(new_seg_details !== null && new_seg_details.length > 0) {
                initData = new_seg_details //set new modified seg details
            }

           let arr =  _.groupBy(initData,"trip_type" )

           const propertyNames = Object.values(arr);
            let expected_details = {
                "seg":propertyNames
            }
            if(props.page) {
                dispatch({type:"import_review_segment",payload:propertyNames})
            } else {
                dispatch({type:"import_tag_type",payload:con_page})
                dispatch({type:"import_segments",payload:propertyNames})

            }
           dispatch({type:"finalisd_import_seg",payload:expected_details})
            }
     },[props.origin,props.bktype])
    /**
 * @Author : Azamuddin
 * @date : 11-08-2021
 * @description : construction of segments in placement of business with jira id 2313

 */
const constructSegmentForImport = (data) => {
    let seg_data = data //segment details

    // scenarios->

    if(seg_data.length > 0 && seg_data.length === 2) {
        //check if its like one way with connectivity
      let duration =  DateUtils.msToTime(Math.abs(new Date(seg_data[1].dd) - new Date(seg_data[0].ad))) //layover time
      let dayExceedTag = false
      if(duration.includes("day") || duration.includes("days") ) {
        dayExceedTag = true
      }
    //   if(dayExceedTag) {
        if(seg_data[0].ar == seg_data[1].da) { //checking arrival of first segment and departure of second segment
            if(seg_data[0].da == seg_data[1].ar) { //checking departure of first segment and arrival of first segment
                seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //no changes
            } else {
                if(dayExceedTag) {
                    seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                    seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //changes
                } else {
                    return null //no changes
                }
            }
        } else {
            if(seg_data[0].da == seg_data[1].ar) {
                seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                seg_data[1].seg_order = 1 // assigning inital seg order

                return seg_data //changes
            } else {
                return null //no changes
            }
        }

    } else {
        let count = 1 //intial count of trip type
        let segment_number = 1
        //iterate each of the seg to handle same condition as above
        for(let indexOf in seg_data) {
            let current_seg = seg_data[indexOf] //iterative current segment
            let next_seg = null
            if(seg_data[Number(indexOf) + 1]) {
            next_seg = seg_data[Number(indexOf) + 1] //next segment
            }
            //****condition to check whether next segment is available or not *****/
            if(next_seg) {
                //if current segment arrival === next segment destination
                // if(current_seg.ar == next_seg.da) {
                    let duration =  DateUtils.msToTime(Math.abs(new Date(next_seg.dd) - new Date(current_seg.ad))) //layover time
                    let dayExceedTag = false
                      if(duration.includes("day") || duration.includes("days") || duration.includes("d")) {
                         dayExceedTag = true
                        }
                        //this means its more than or equal to 24 hrs
                        if(dayExceedTag) {
                            count = count + 1
                            segment_number = 1
                        }
                        let next_seg_count = count
                        next_seg.trip_type = next_seg_count //assiginng new trip type(AKA segment header count) to show as new departure
                        if(dayExceedTag) {
                        next_seg.seg_order = 1 // assigning inital seg order
                        } else {
                            segment_number = segment_number + 1
                            next_seg.seg_order = segment_number
                        }
                }
            // }
        }
              return seg_data //changes depends on layover time
          }
}
    return (
        <>
            {props.tripType !== 3 && state.import_segments.length === 0 ? (
                (isNotNull(props.origin) && isNotNull(props.mapping)) && (
                    <>
                        {type === "Review" || type === "Confirm" ? (
                            <>
                                     {(isNotNull(type) && type !== 'Review') && <>{origin_data.sup!=="0" &&
                                            <div className="ltag lcc ezyIcon icon-nFlight">LCC </div>
                                            }
                                            {origin_data.sup==="0" &&
                                            <div className="ltag fcc ezyIcon icon-nFlight">FSC </div>
                                            }</>}
                                <div className="rs-headerBlock mt-3">

                                    <Row className="align-items-center rs-row">
                                        <Col xs={type === "Review" ? '4' : '3'} className="rs-rTypeDate">
                                            {origin_data.ref === 1 &&
                                                <>
                                                    <span className="departure ezyIcon icon-flight">

                                              Departure</span><span>{DateUtils.prettyDate(origin_data.seg[0].dd, "ddd, DD MMM, YYYY")}</span>
                                                </>
                                            }
                                            {origin_data.ref === 2 &&
                                                <>
                                                    <span className="departure arrival ezyIcon icon-flight">

                                                Arrival</span><span>{DateUtils.prettyDate(origin_data.seg[0].dd, "ddd, DD MMM, YYYY")}</span>
                                                </>
                                            }

                                        </Col>

                                        <Col xs={type === "Review" ? '8' : '9'} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={4} className="d-flex flex-column">
                                                    <AirportMemo code={origin_data.seg[0].da} mapping={mappings} />
                                                </Col>
                                                <Col xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col xs={type === "Review" ? '7' : '4'} className="d-flex flex-column">
                                                    <AirportMemo code={origin_data.seg.slice(-1)[0].ar} mapping={mappings} />
                                                </Col>
                                                {(isNotNull(type) && type === 'Confirm') && <Col><span className="tripDuration">Trip Duration: <span>{origin_data.sdur!==undefined && origin_data.sdur? origin_data.sdur.split(":")[0] + " hrs " + origin_data.sdur.split(":")[1] + " mins":"N/A"}</span></span></Col>}
                                            </Row>
                                            <span>
                                            </span>
                                        </Col>
                                    </Row>


                                </div>



                                {/* This function we are using common for Review and ConfirmationPage
                    based on condition we redirecting */}
                                {(isNotNull(type) && type === 'Confirm') && (<FlightSegmentDetails origindata={[origin_data, 'Confirm']} mapping={mappings} bktype={props.bktype} confirmation={props.confirmation ? true : false}/>)}
                                {(isNotNull(type) && type === 'Review') && (<SegmentDetails revieWFlt={props.revieWFlt} bktype={props.bktype} origin={origin_data} mapping={mappings} />)}

                            </>
                        ) : (props.bktype && props.bktype == "IMPORT" ? (
                            <>
                            {state.import_review_segment.length > 0 && state.import_review_segment.map((sec, idx) => (
                     <>
                                <Row className="align-items-center rs-row">
                                    <Col xs={8} className="flightRout">
                                        <p className="routDate m-0">
                                            <CityNameMemo code={sec[0].da} mapping={mappings} /> ({sec[0].da}) <spna className="ezyIcon icon-oneside"></spna> <CityNameMemo code={sec.slice(-1)[0].ar} mapping={mappings} /> ({sec.slice(-1)[0].ar}) </p>
                                        {/* {origin_data.ref === 1 ? <p className="m-0">&nbsp;{DateUtils.prettyDate(origin_data.seg[0].dd, "ddd, DD MMM  YYYY")}</p> : ''}
                                {origin_data.ref === 2 ? <p className="m-0">&nbsp;{DateUtils.prettyDate(origin_data.seg.slice(-1)[0].ad, "ddd, DD MMM  YYYY")}</p> : ''} */}
                                    </Col>
                                    <Col xs={4} className="timeClass d-flex justify-content-between">
                                        {/* <p className="m-0"><span className="time">{"N/A"}</span></p> */}
                                        <p className="m-0">{sec[0].cl} - {sec[0].rbd}</p>
                                        </Col>
                                </Row>
                                <FlightImportSegmentDetails  origindata={[{"seg":sec}, 'Review']} cl={sec[0].cl} bktype={props.bktype} pnr={origin_data.pnr} bgd={origin_data.bgd} mapping={mappings} confirmation={false}/>
                                {/* <FlightSegmentDetails origindata={[sec, 'Review']} mapping={mappings}  bktype={props.bktype} confirmation={props.confirmation ? true : false}/> */}
                                </>
                                ))}
                            </>
                    ) : (
                        <>
                                <Row className="align-items-center rs-row">


                                    <Col xs={12} className="timeClass d-flex justify-content-between flex-row align-items-end">
                                        <p className="m-0"><span className="d-flex time"><span className='ezyIcon icon-timenew mr-2'></span>{origin_data.sdur.split(":")[0] + " hrs " + origin_data.sdur.split(":")[1] + " mins"}</span></p>
                                        <p className="m-0"><span className="d-flex time">{origin_data.sup==="0"?origin_data.seg[0].cl:state.class}  {origin_data.seg[0].rbd !==''&& '-'} {origin_data.seg[0].rbd}</span></p>
                                    </Col>
                                </Row>
                                <FlightSegmentDetails origindata={[origin_data, 'Review']} mapping={mappings}  bktype={props.bktype} confirmation={props.confirmation ? true : false}/>
                        </>
                    ))}
                    </>
                )
            ) : (
                <>
                 {props.bktype && (props.bktype == "IMPORT" || props.bktype == "IMPORT/REISSUE") && state.import_segments.length > 0 ? (
                     <>
                     {state.import_segments.length > 0 && state.import_segments.map((sec, idx) => (
                         <>
                             <div className="rs-headerBlock mt-3">
                                 <Row className="align-items-center rs-row">
                                     <Col xs={(type === "Review") ? '4' : '3'} className="rs-rTypeDate">
                                         <>
                                             <span className="departure ezyIcon icon-flight">
                                          Departure </span><span>{DateUtils.prettyDate(sec[0].dd, "ddd, DD MMM YYYY")}</span>
                                         </>
                                     </Col>
                                     <Col xs={type === "Review" ? '8' : '9'} className="rs-FromTo">
                                         <Row className="align-items-center">
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[0].da} mapping={mappings} />
                                             </Col>
                                             <Col xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[sec.length - 1].ar} mapping={mappings} />
                                             </Col>

                                             {/* {(isNotNull(types) ) && <Col><span className="tripDuration">Trip Duration : {"N/A"}</span></Col>} */}
                                         </Row>
                                         <span>
                                         </span>
                                     </Col>
                                 </Row>
                             </div>
                             {state.import_tag_type && state.import_tag_type == "Confirm" && (<FlightImportSegmentDetails  origindata={[{"seg":sec}, 'Confirm']} cl={onwardsList.srcorigns[0].cl} pnr={onwardsList.srcorigns[0].pnr} bgd={onwardsList.srcorigns[0].bgd} mapping={mappings} confirmation={true}/>)}
                             {state.import_tag_type && state.import_tag_type != "Confirm" && (<FlightImportSegmentDetails  origindata={[{"seg":sec}, 'Confirm']} cl={origin_data.cl} pnr={origin_data.pnr} bgd={origin_data.bgd} mapping={mappings} confirmation={true}/>)}
                             {/* {(isNotNull(types) && types === 'Review') && (<SegmentDetails revieWFlt={props.revieWFlt} origin={sec} mapping={mappings} />)} */}
                         </>

                     ))}
                 </>
                 ) :(
<>
                        {onwardsList.srcorigns.length > 0 && onwardsList.srcorigns.map((sec, idx) => (
                            <>
                                <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={(type === "Review") ? '4' : '3'} className="rs-rTypeDate">
                                            <>
                                                <span className="departure ezyIcon icon-flight">
                                             Departure</span><span>{DateUtils.prettyDate(sec.seg[0].dd, "ddd, DD MMM YYYY")}</span>
                                            </>
                                        </Col>
                                        <Col xs={type === "Review" ? '8' : '9'} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={4} className="d-flex flex-column">
                                                    <AirportMemo code={sec.seg[0].da} mapping={mappings} />
                                                </Col>
                                                <Col xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col xs={4} className="d-flex flex-column">
                                                    <AirportMemo code={sec.seg.slice(-1)[0].ar} mapping={mappings} />
                                                </Col>

                                                {(isNotNull(types) && types === 'Confirm') && <Col><span className="tripDuration">Trip Duration : {sec.sdur.split(":")[0] + " hrs " + sec.sdur.split(":")[1] + " mins"}</span></Col>}
                                            </Row>
                                            <span>
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                {(isNotNull(types) && types === 'Confirm') && (<FlightSegmentDetails origindata={[sec, 'Confirm']} mapping={mappings} confirmation={props.confirmation ? true : false}/>)}
                                {(isNotNull(types) && types === 'Review') && (<SegmentDetails revieWFlt={props.revieWFlt} origin={sec} mapping={mappings} />)}
                            </>

                        ))}
                    </>
                 ) }

                    </>
                )}

        </>


    )
}
export default FlightSectors;
