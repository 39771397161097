
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form,Table,Modal} from "react-bootstrap";
import { Context } from "../../../../../App";
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import MainMenu from '../../../../components/common/MainMenu';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu'
import Select from "react-dropdown-select";
import { Typeahead } from 'react-bootstrap-typeahead';
import NoRecord from '../../../common/popUps/NoRecord';
import { charSpaceValidation} from '../../../commonUtils/FormValidations';
import {searchAirportCode,countries,cities,saveAirportData} from './operations';


/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Airport Code
 * @date : 22-09-2022
 */

/**
 * Initial State Declaration
 */
const initialState = {
    countryMenu:[], cityMenu:[],enableCreate:false,enableSearch:true,discObj:"",airport_code:"",airport_name:"",sel_cnt:[],sel_city:[],city_name:"",bclError:false,airportErr:false,airNameErr:false,
    res:"",showPopUp:false, enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, company_id:0};
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Category
 */
function AddAirport() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);

    const searchAirport=()=>{
        if(state.airport_code!==""){
            searchAirportCode({"searchCriteria": state.airport_code}).then(response => {
            if (response.suc && response.res.length!==0){
                dispatch({ type: 'res', payload: response.res[0] })
            }else{
                dispatch({ type: 'res', payload: "" })
            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }else{
            dispatch({ type: 'notiMessage', payload: "Please enter Airport Code" })
            dispatch({ type: 'notiVarient', payload: "danger" })
            dispatch({ type: 'notiMessageShow', payload: true })
            hidemessage()
    }
    }

    const reset=()=>{
        dispatch({ type: 'airport_code', payload: "" })
    }
    const back=()=>{
        dispatch({ type: 'enableSearch', payload: true })
        dispatch({ type: 'airport_code', payload: "" })
    }
    const close=()=>{
        dispatch({ type: 'showPopUp', payload: false })
    }
    const validateAirport=()=>{
      if(state.airport_code===""){
        dispatch({ type: 'notiMessage', payload: "Please enter Airport Code" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.airport_name===""){
        dispatch({ type: 'notiMessage', payload: "Please enter Airport Name" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.sel_cnt.length===0){
        dispatch({ type: 'notiMessage', payload: "Please select Country" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.sel_city.length===0){
        dispatch({ type: 'notiMessage', payload: "Please select City" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }
      return true;
    }

    const saveAirport=()=>{
        if(validateAirport()){
        dispatch({ type: 'showPopUp', payload: true })
        }
    }

    const addAirport=()=>{
            const req={
                "airport_code":state.airport_code,
                "airport_name":state.airport_name,
                "cnt_id":state.sel_cnt[0].value,
                "cnt_name":state.sel_cnt[0].label,
                "cnt_code":state.sel_cnt[0].code,
                "city_id":state.sel_city[0].value,
                "city_name":state.sel_city[0].label,
                "crby":context.logindata.uid,
             }
             saveAirportData(req).then(response => {
                if (response.suc){
                    if(response.is_duplicate){
                        dispatch({ type: 'notiMessage', payload: "Duplicate Airport Code" })
                        dispatch({ type: 'notiVarient', payload: "danger" })
                        dispatch({ type: 'notiMessageShow', payload: true })
                        hidemessage()
                    }else{
                        dispatch({ type: 'notiMessage', payload: "Airport Data saved successfully..." })
                        dispatch({ type: 'notiVarient', payload: "success" })
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'enableSearch', payload: true })
                        dispatch({ type: 'showPopUp', payload: false })
                        dispatch({ type: 'airport_code', payload: "" })
                        hidemessage()
                    }
                }else{
                    dispatch({ type: 'notiMessage', payload: "Airport data Not saved" })
                    dispatch({ type: 'notiVarient', payload: "danger" })
                    dispatch({ type: 'notiMessageShow', payload: true })
                    hidemessage()
                }
        })
    }

    // OnChange event
    const handleChangeAirport = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
        dispatch({type:"bclError",payload:false})
            if (state.airport_code.length!=="" && state.airport_code.length > 2){
                if (charSpaceValidation(state.airport_code)){
                        dispatch({type:"bclError",payload:false})
                    }else{
                        dispatch({type:"bclError",payload:true})
                    }
        }
    }

     // OnChange event
     const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
        dispatch({type:"airportErr",payload:false})
        dispatch({type:"airNameErr",payload:false})
        if(id==="airport_code"){
            if (state.airport_code.length!=="" && state.airport_code.length > 2){
                if (charSpaceValidation(state.airport_code)){
                        dispatch({type:"airportErr",payload:false})
                    }else{
                        dispatch({type:"airportErr",payload:true})
                    }
              }
        }else if(id==="airport_name"){
                if (state.airport_name.length!=="" && state.airport_name.length > 5){
                    if (charSpaceValidation(state.airport_name)){
                            dispatch({type:"airNameErr",payload:false})
                        }else{
                            dispatch({type:"airNameErr",payload:true})
                        }
                    }
        }
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const createAirport=()=>{
        dispatch({ type: "enableSearch", payload: false })
        dispatch({ type: "airport_code", payload: "" })
        dispatch({ type: "airport_name", payload: "" })
        dispatch({ type: "sel_cnt", payload: [] })
        dispatch({ type: "sel_city", payload: [] })
        countries()
      .then((response) => {
        if (response) {
          dispatch({ type: "countryMenu", payload: response });
        }
      });
    }
    function getUnique(arr, comp) {
        // store the comparison  values in array
        const unique = arr
          .map((e) => e[comp])

          // store the indexes of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the false indexes & return unique objects
          .filter((e) => arr[e])
          .map((e) => arr[e]);

        return unique;
      }

    const handleCountryChange = (e) => {
        if (e.length > 0) {
          dispatch({ type: "sel_cnt", payload: e });
          cities(e[0].value).then((res) => {
            let data = getUnique(res, "label");
            dispatch({ type: "cityMenu", payload: data });
          });
        }
      };

       //=====================================
  // This function will update the Airline
  //=====================================
  const handleAirChange = (obj) => {
    dispatch({ type: "sel_city", payload: obj })
    }

    const handlepfairChange = (obj)=>{
        if (obj.length > 0) {
          dispatch({ type: "sel_city", payload: [{value:"",label:obj}] })
        }
        }

    return (
        <>
            <MainMenu active='Air Services' />
            <Breadcrumb activePage="Add Airport" />
            <div className="container">
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
            <div className="cardMainlayout pt-0">
            <AirServiceConfigMenu active="Airport" />
            <br/>
            {!state.enableSearch &&
            <div>
            <Form.Row>
                <Form.Group as={Col} xs={3} controlId="airport_code" className="selectplugin">
                  <Form.Control type="text" placeholder="Airport Code" autoComplete="off"
                   value={state.airport_code}
                  onChange={handleChange} />
                  <Form.Label>
                    Airport Code <sup>*</sup>
                </Form.Label>
                {state.airportErr && (
                    <p className="vError">Please Enter valid Airport Code</p>
                )}
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="airport_name" className="selectplugin">
                  <Form.Control type="text" placeholder="Airport Code" autoComplete="off"
                  value={state.airport_name}
                  onChange={handleChange} />
                  <Form.Label> Airport Name <sup>*</sup> </Form.Label>
                  {state.airNameErr && (
                    <p className="vError">Please Enter valid Airport Name</p>
                )}
                </Form.Group>


                <Form.Group as={Col} xs={3} controlId="cnt" className="selectplugin">
                  <Select
                    options={state.countryMenu}
                    searchable={true}
                    values={state.sel_cnt}
                    onChange={(value) => handleCountryChange(value)}
                   />
                  <Form.Label>
                    Country <sup>*</sup>
                  </Form.Label>
                 </Form.Group>



            <Form.Group as={Col} className="preferred pl-0 mb-0 selectplugin">
         <div className="addInput">
              <Typeahead
                id="basic-typeahead-single"
                labelKey={air => `${air.label}`}
                readOnly
                onChange={air => handleAirChange(air)}
                onInputChange={air =>handlepfairChange(air)}
                options={state.cityMenu}
                placeholderText="Enter City Name"
                selected={state.sel_city}
              />
             </div>
             <Form.Label>
                    City <sup>*</sup>
                </Form.Label>
            </Form.Group >
                </Form.Row>
               </div>
                }
                {!state.enableSearch &&
                    <div className="text-right pb-0 buttonGrop  creat_absolute">
                    <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={back}
                    > Back</Button>
                    <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                    onClick={saveAirport}
                    > Add</Button>
                </div>
                }
            {state.enableSearch &&
            <div>
            <Form.Row>
            <Form.Group as={Col} xs={3} controlId="airport_code" className="selectplugin">
                  <Form.Control type="text" placeholder="Airport Code" autoComplete="off"
                  value={state.airport_code}
                  onChange={handleChangeAirport} />
                  <Form.Label>
                  Airport Code <sup>*</sup>
                </Form.Label>
                {state.bclError && (
                    <p className="vError">Please Enter valid Airport Code</p>
                )}
                </Form.Group>
                </Form.Row>
               </div>
                }
                {state.enableSearch &&
                     <div className="d-flex justify-content-between text-right pb-0 buttonGrop  creat_absolute">
                      <div className='d-flex'>
                      <Button
                      size="xs"
                      variant="primary"
                      className="addPlus ezyIcon icon-plus"
                      onClick={createAirport}
                      type="submit">
                    Add Airport
                  </Button>
                  </div>
                  <div className='d-flex'>
                     <Button
                       size="xs"
                       variant="outline-secondary"
                       type="submit"
                       onClick={reset}
                     > Reset</Button>
                     <Button
                       size="xs"
                       variant="outline-primary"
                       type="submit"
                       onClick={searchAirport}
                     > Search</Button>
                     </div>
                   </div>
                }
            {state.enableSearch &&
            <div className="d-flex justify-content-between buttonGrop pb-0 mt-3">

                </div>
              }
             </div>
             {state.enableSearch &&
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                                <Table className="m-0">
                                    <thead>
                                        <tr>
                                            <th>Airport Code</th>
                                            <th>Airport Name</th>
                                            <th>Country</th>
                                            <th>City</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.res!=="" &&
                                            <tr>
                                                <td>
                                                    <span>{state.res.airportCode}</span>
                                                </td>
                                                <td>
                                                    <span>{state.res.airportName}</span>
                                                </td>
                                                <td>
                                                    <span>{state.res.countryName}[{state.res.countryCode}]</span>
                                                </td>
                                                <td>
                                                    <span>{state.res.cityName}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            {state.res==="" &&
                                <NoRecord />
                            }
                    </>)}
                </div>
              }
            </div>
        <Modal show={state.showPopUp}
        className="popUplayout airPortmaster">
        <Modal.Header>
          <Modal.Title>Would you like to proceed with the selection below?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='IndigobdContent'>
          <Table className="m-0">
                                    <thead>
                                        <tr>
                                            <th>Airport Code</th>
                                            <th>Airport Name</th>
                                            <th>Country</th>
                                            <th>City</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr>
                                                <td>
                                                    <span>{state.airport_code}</span>
                                                </td>
                                                <td>
                                                    <span>{state.airport_name}</span>
                                                </td>
                                                <td>
                                                    <span>{state.sel_cnt.length!==0?state.sel_cnt[0].label:""}</span>
                                                </td>
                                                <td>
                                                    <span>{state.sel_city.length!==0?state.sel_city[0].label:""}</span>
                                                </td>
                                            </tr>
                                    </tbody>
                                </Table>
          </div>
          </Modal.Body>
          <Modal.Footer>
          <div className="text-right pb-0 buttonGrop  creat_absolute">
                    <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={close}
                    > No</Button>
                    <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                    onClick={addAirport}
                    > Yes</Button>
                </div>
        </Modal.Footer>

      </Modal>
            <Footer />
        </>
    )
}

export default AddAirport