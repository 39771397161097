import axios from 'axios'

const API_URL_DB = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Supplier
 * @param {*} Service 
 */

class SupplierConfiguration {
    async saveSupplier(data) {
        return await axios.post(`${API_URL_DB}/att/company/saveSupConfig`,data)  
      }
    async retrieveSupplier(obj) {
        return await axios.post(`${API_URL_DB}/att/company/retrieveSupConfig`,obj)
    }
    async updateSupConfig(data) {
        return await axios.post(`${API_URL_DB}/att/company/updateSupConfig`,data)
    }
    }
    
export default new SupplierConfiguration()