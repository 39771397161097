/**
 * @description: Flight Confirmation page Root Passgenger details component
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
 import React, { useEffect, useReducer, useState, useRef } from 'react';
 import { Col, Form, Row,Table, Alert} from "react-bootstrap";
 import { useHistory } from 'react-router-dom';
 import Button from '../../common/buttons/Button';
 import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
 import RefundType from '../refund/RefundType';
 import mp from '../../masterData/MealPreference.json'
 import sr from '../../masterData/SeatPreference.json'
 import sa from '../../masterData/SpecialAssistance.json'
 import Utilities from "../../commonUtils/Utilities";
 export const EDIT_TICKET_DATA = "editTicketData";
 let bookingStatus = require('../../masterData/BookingStatus..json');
 
 /**
   * @description: This function will show the PassengerDetails
   * @date:09-01-2020
   * @author:Rambabu
   *
   */
 /**
 * Initial State Declaration
 */
 const initialState = {
     notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false, disablereturned: false, disableonward: false, onward: false, return: false, checkRefundable: false,ticketStatusList:[],isCancelBooking:false,hideReturnIt:false,enableSplit:false,enableReissue:true,emdAvail:false, cheked: false, showSplit: false, onHoldSplit: true
 }

 // Reducer function for useReact Hook which will update the state
 const reducer = (state, action) => {
     switch (action.type) {
         case 'clear':
             return {
                 ...state, notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false
             };
         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 let ticketsArr = []
 let ticketReisueArr = []
 let ticketReisueArrReturn = []
 let onholdTicket = []
 let onHoldJSONarray = []

 function FlightSummaryPaxDetails(props) {
     const [state, dispatch] = useReducer(reducer, initialState);
     const [voidTickets, setVoidTickets] = useState([])
     const [enableCheck, setEnableCheck] = useState(false)
     const [enableCheckbox, setEnableCheckbox] = useState(true)
     const history = useHistory();
     const [test, setTest] = useState(false)
     const [status, setStatus] = useState("")
     const checkboxRef = useRef(null);
     const checkboxRRef = useRef(null);
     /**
      * @description : tickets to be voided are pushed in an array
      * @author : azamuddin
      * @date : 30-11-2020
      */
     //---------split------------


     useEffect(() => {
        if (props.enbaleReissueReturn) {
            ticketsArr = []
            setEnableCheck(true)
            dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
            props.voidTickets(ticketsArr)
        }
    }, [props.enbaleReissueReturn])
//      const ticketsTobeSplit = (e)=>{
//         var tkts_To_Hold = [];

//          if(e.target.checked == true){

//          tkts_To_Hold.push(e.target.id);
//          }
//         else{
//         tkts_To_Hold.pop(e.target.id);
//         }
//      }
const onHoldsplitSelection = (pax) => (e) => {
    const { id, value } = e.target;
    if (onholdTicket.indexOf(id)
> -1) {
        //available needs to remove
        onholdTicket = onholdTicket.filter(x => x !== id)
        onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== id)
        let chdTkt = ""
        //removal of inf if adt is selected
        if(pax.pax_type === "ADT") {
            for (let val of props.pax_det[0]) {
                if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                    const idx = onholdTicket.indexOf(val.pax_name)
                    // ticketsArr.push(val.tkt_no);
                    if(idx > -1) {
                        chdTkt = val.pax_name
                        onholdTicket = onholdTicket.filter(x => x !== val.pax_name)
                        onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== val.pax_name)
                    }
                }
            }
        }

       //removal of adt if inf is selected
        if(pax.pax_type === "INF") {
            for (let val of props.pax_det[0]) {
                if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                    const idx = onholdTicket.indexOf(val.pax_name)
                    // ticketsArr.push(val.tkt_no);
                    if(idx > -1) {
                        chdTkt = val.paxsno
                        onholdTicket = onholdTicket.filter(x => x !== val.pax_name)
                        onHoldJSONarray = onHoldJSONarray.filter(x => x.nm !== val.pax_name)
                    }
                }
            }
        }
        let array = onHoldJSONarray
            // props.onHoldsplitRequest(onHoldJSONarray)
        } else {
         //not available add
         onholdTicket = [...onholdTicket, id]
            if (pax.pax_type === "ADT") {
                for (let val of props.pax_det[0]) {
                    if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                        const payload = {
                            "nm": val.pax_name,
                            "tkt": "",
                            "ty": "INF",
                            "paxsno":val.paxsno,
                            "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                            "bktype": "ONHOLD",
                            "tid": val.tid,
                            "trv_id":val.trv_id
                        }
                        onHoldJSONarray.push(payload);
                        onholdTicket.push(val.pax_name);
                    }
                }
            }
            else if(pax.pax_type === "INF") {
                for (let val of props.pax_det[0]) {
                    if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                        const payload = {
                            "nm": val.pax_name,
                            "tkt": "",
                            "ty": "ADT",
                            "paxsno":val.paxsno,
                            "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                            "bktype": "ONHOLD",
                            "tid": val.tid,
                            "trv_id":val.trv_id
                        }
                        onHoldJSONarray.push(payload);
                        onholdTicket.push(val.pax_name);
                    }
                }
            }

            const payload = {
                "nm": pax.pax_name,
                "tkt": "",
                "ty": pax.pax_type,
                "paxsno":pax.paxsno,
                "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
                "bktype": "ONHOLD",
                "tid": pax.tid,
                "trv_id":pax.trv_id
            }
            onHoldJSONarray.push(payload);

         }
         if(props.pax_det[0].length === onHoldJSONarray.length ){
          dispatch({type: "onHoldSplit", payload: false})
         }
         else{
            dispatch({type: "onHoldSplit", payload: true})
         }
 }




     useEffect(() => {
         if (props.enableVoidBookingOnward) {
             // props.disableReturn(["1"])
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
             props.voidTickets(ticketsArr)
         }
     }, [props.enableVoidBookingOnward])

     useEffect(() => {
         if (props.enableVoidBookingReturn) {
             // props.disableOnward(["1"])
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
             props.voidTickets(ticketsArr)
         }
     }, [props.enableVoidBookingReturn])

     //---------split------------
     useEffect(() => {
         if (props.enableRefundOnward) {
             props.disableReturn(["1"])
             ticketsArr = []
             setEnableCheck(true)
             // dispatch({ type: 'returnVoid', payload: false })
             dispatch({ type: "enabelRefnd", payload: true })
             dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
             dispatch({ type: "rtype", payload: "ATC Refund" })
         }
     }, [props.enableRefundOnward])

     useEffect(() => {
         if (props.enableRefundReturn) {
             props.disableOnward(["1"])
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: "enabelRefnd", payload: true })
             dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
             dispatch({ type: "rtype", payload: "NON ATC Refund" })
         }
     }, [props.enableRefundReturn])


     //-----------normal-----------
     //voidbooking
     useEffect(() => {
         if (props.enableVoidBooking) {
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
             props.voidTickets(ticketsArr)
         }
     }, [props.enableVoidBooking])

     //voidcancellation
     useEffect(() => {
         if (props.enableVoidCancel) {
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: true })  //condition to check if refundable checking is necessary or not
             props.voidTickets(ticketsArr)
         }
     }, [props.enableVoidCancel])

     //disable void selection
     useEffect(() => {
         if (props.disableCheckbox) {
             setEnableCheck(false)
             ticketsArr = []
             dispatch({ type: 'disablereturned', payload: false })
             dispatch({ type: 'disableonward', payload: false })
             dispatch({ type: 'checkRefundable', payload: false })  //condition to check if refundable checking is necessary or not
             props.voidTickets(ticketsArr)
         }
     }, [props.disableCheckbox])


  useEffect(() => {
   const ticketsList = bookingStatus.map((obj) =>
   <option value={obj.value}>{obj.label}</option>
   );
   dispatch({ type: 'ticketStatusList', payload: ticketsList })
   if(props.pax_det!==undefined && props.pax_det.length!==0 && (props.isEdit || props.isEditReturn)){
        for(let val of props.pax_det){
           for(let pax of val){
             pax.stid="34";
             pax.sts="Ticket Failed";
           }
        }
   }
   }, [props.isEdit || props.isEditReturn])

     /**to diable if split  */
     useEffect(() => {
         if (props.enableVoidBookingOnward || props.enableVoidBookingReturn) {
             if (ticketsArr.length > 0) {
                 let stack = false
                 if (props.onTkts && props.onTkts.length > 0) {
                     for (let val of props.onTkts) {

                         if (ticketsArr.includes(val)) {

                             dispatch({ type: 'disablereturned', payload: true })
                             stack = true
                         }
                     }
                 }
                 if (props.retTkts && props.retTkts.length > 0 && !stack) {
                     for (let val of props.retTkts) {
                         if (ticketsArr.includes(val)) {
                             dispatch({ type: 'disableonward', payload: true })

                         }
                     }
                 }
             } else {
                 dispatch({ type: 'disablereturned', payload: false })
                 dispatch({ type: 'disableonward', payload: false })
             }
         }
         if(ticketsArr.length === 0) {
             dispatch({ type: 'disablereturned', payload: false })
             dispatch({ type: 'disableonward', payload: false })
         }
         if(props.pax_det.length!==0){
            for(let val of props.pax_det){
               for(let pax of val){
                   if(isNotNull(pax.emd_number)){
                    dispatch({ type: 'emdAvail', payload: true })
                   }
               }
            }
       }
     }, [props.onTkts, props.retTkts, ticketsArr])

     useEffect(() => {
        if(props.enablesptpnr) {
            onholdTicket = []
            onHoldJSONarray = []
        }
     },[props.enablesptpnr])
     useEffect(() => {
        checkboxRef.current && checkboxRef.current.focus();
        },[props.checkboxFocus])

     useEffect(() => {
        if(props.enableRsptpnr) {
            onholdTicket = []
            onHoldJSONarray = []
        }
     },[props.enableRsptpnr])
     useEffect(() => {
        checkboxRRef.current && checkboxRRef.current.focus();
     },[props.checkboxRState])


     const ticketsTobeVoid = (pax) => (e) => {
         const { id, value } = e.target;
         if (ticketsArr.indexOf(id) > -1) {
             const index = ticketsArr.indexOf(id);
             if (index > -1) {
                 // var array = ticketsArr.filter(x => x !== id);
                 ticketsArr = ticketsArr.filter(x => x !== id)
                 if(props.enableReisueTkt) {
                     ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== id)
                 }
                 if(props.ticketReisueArrReturn) {
                    ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== id)
                 }

                 if (ticketsArr.length === 0) {
                     dispatch({ type: 'disablereturned', payload: false })
                     dispatch({ type: 'disableonward', payload: false })
                 }
                 let chdTkt = ""
                 //removal of inf if adt is selected
                 if(props.enableReisueTkt || props.enbaleReissueReturn || props.enableRefundOnward || props.enableRefundTkt || props.enableRefundReturn || props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "ADT") {
                     for (let val of props.pax_det[0]) {
                         if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                             const idx = ticketsArr.indexOf(val.tkt_no)
                             // ticketsArr.push(val.tkt_no);
                             if(idx > -1) {
                                 chdTkt = val.tkt_no
                                 ticketsArr = ticketsArr.filter(x => x !== val.tkt_no)
                                 if(props.enableReisueTkt) {
                                     ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== val.tkt_no)
                                 }
                                 if(props.ticketReisueArrReturn) {
                                    ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== val.tkt_no)
                                 }
                                 if (ticketsArr.length === 0) {
                                     dispatch({ type: 'disablereturned', payload: false })
                                     dispatch({ type: 'disableonward', payload: false })
                                 }
                             }
                         }
                     }
                 }

                //removal of adt if inf is selected
                 if(props.enableReisueTkt || props.enbaleReissueReturn || props.enableRefundOnward || props.enableRefundTkt || props.enableRefundReturn || props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "INF") {
                     for (let val of props.pax_det[0]) {
                         if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                             const idx = ticketsArr.indexOf(val.tkt_no)
                             // ticketsArr.push(val.tkt_no);
                             if(idx > -1) {
                                 chdTkt = val.tkt_no
                                 ticketsArr = ticketsArr.filter(x => x !== val.tkt_no)
                                 if(props.enableReisueTkt) {
                                     ticketReisueArr = ticketReisueArr.filter(x => x.tkt !== val.tkt_no)
                                 }
                                 if(props.enbaleReissueReturn){
                                    ticketReisueArrReturn = ticketReisueArrReturn.filter(x => x.tkt !== val.tkt_no)
                                 }
                                 if (ticketsArr.length === 0) {
                                     dispatch({ type: 'disablereturned', payload: false })
                                     dispatch({ type: 'disableonward', payload: false })
                                 }
                             }
                         }
                     }
                 }
             }
                 let array = ticketsArr
                     props.voidTickets(array)
                     if (ticketsArr.length === 0) {
                         dispatch({ type: 'disablereturned', payload: false })
                         dispatch({ type: 'disableonward', payload: false })
                 // }
             }
         } else {
             // ticketsArr.push(id)
             ticketsArr = [...ticketsArr, id]
             if (props.enableRefundTkt && pax.pax_type === "ADT") {
                 for (let val of props.pax_det[0]) {
                     if (val.pax_type === "INF" && val.sts==="Confirmed" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                         ticketsArr.push(val.tkt_no);
                     }
                 }
             }

             // adding inf if void clicked
             if(props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "ADT") {
                 for (let val of props.pax_det[0]) {
                     if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                         ticketsArr.push(val.tkt_no);
                     }
                 }
             }

             if(props.enableVoidBooking || props.enableVoidBookingOnward || props.enableVoidBookingReturn || props.enableVoidCancel && pax.pax_type === "INF") {
                 for (let val of props.pax_det[0]) {
                     if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                         ticketsArr.push(val.tkt_no);
                     }
                 }
             }

             if(props.enableRefundTkt && pax.pax_type === "INF") {
                 for (let val of props.pax_det[0]) {
                     if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                         ticketsArr.push(val.tkt_no);
                     }
                 }
             }


             //For Reissue Onward
             if (props.enableReisueTkt) {
                 if (pax.pax_type === "ADT") {
                     for (let val of props.pax_det[0]) {
                         if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                             const payload = {
                                 "nm": val.pax_name,
                                 "tkt": val.tkt_no,
                                 "ty": "INF",
                                 "paxsno":val.paxsno,
                                 "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                                 "tid":val.tid,
                                 "tkt_time" : val.tkt_time ? val.tkt_time : null,
                                 "trv_id":val.trv_id
                             }
                             ticketReisueArr.push(payload);
                             ticketsArr.push(val.tkt_no);
                         }
                     }
                 }
                 else if(pax.pax_type === "INF") {
                     for (let val of props.pax_det[0]) {
                         if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                             const payload = {
                                 "nm": val.pax_name,
                                 "tkt": val.tkt_no,
                                 "ty": "ADT",
                                 "paxsno":val.paxsno,
                                 "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                                 "tid":val.tid,
                                 "tkt_time" : val.tkt_time ? val.tkt_time : null,
                                 "trv_id":val.trv_id
                             }
                             ticketReisueArr.push(payload);
                             ticketsArr.push(val.tkt_no);
                         }
                     }
                 }

                 const payload = {
                     "nm": pax.pax_name,
                     "tkt": id,
                     "ty": pax.pax_type,
                     "paxsno":pax.paxsno,
                     "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
                     "tid":pax.tid,
                     "tkt_time" : pax.tkt_time ? pax.tkt_time : null,
                     "trv_id":pax.trv_id
                 }
                 ticketReisueArr.push(payload);
             }

             //Reissue Return
             if (props.enbaleReissueReturn) {
             if (pax.pax_type === "ADT") {
                for (let val of props.pax_det[0]) {
                    if (val.pax_type === "INF" && val.infant_map_id !== null && pax.trv_id === val.infant_map_id) {
                        const payload = {
                            "nm": val.pax_name,
                            "tkt": val.tkt_no,
                            "ty": "INF",
                            "paxsno":val.paxsno,
                            "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                            "tid":val.tid,
                            "tkt_time" : val.tkt_time ? val.tkt_time : null,
                            "trv_id":val.trv_id
                        }
                        ticketReisueArrReturn.push(payload);
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }

            else if(pax.pax_type === "INF") {
                for (let val of props.pax_det[0]) {
                    if (val.pax_type === "ADT" && val.trv_id !== null && val.trv_id === pax.infant_map_id) {
                        const payload = {
                            "nm": val.pax_name,
                            "tkt": val.tkt_no,
                            "ptype": "ADT",
                            "paxno":val.paxsno,
                            "amd_ref_num" : val.amedeus_ref_num ? val.amedeus_ref_num : "",
                            "tid":val.tid,
                            "tkt_time" : val.tkt_time ? val.tkt_time : null,
                            "trv_id":val.trv_id
                        }
                        ticketReisueArrReturn.push(payload);
                        ticketsArr.push(val.tkt_no);
                    }
                }
            }

            const payload = {
                "nm": pax.pax_name,
                "tkt": id,
                "ty": pax.pax_type,
                "paxsno":pax.paxsno,
                "amd_ref_num" : pax.amedeus_ref_num ? pax.amedeus_ref_num : "",
                "tid":pax.tid,
                "tkt_time" : pax.tkt_time ? pax.tkt_time : null,
                "trv_id":pax.trv_id
            }
            ticketReisueArrReturn.push(payload);
        }

            if(!props.enbaleReissueReturn && props.onTkts && props.onTkts.includes(id)) {
                 dispatch({ type: 'disablereturned', payload: true })
             } else if (!props.enbaleReissueReturn && props.retTkts && props.retTkts.includes(id)) {
                 dispatch({ type: 'disableonward', payload: true })
             }
             props.voidTickets(ticketsArr)
            }
            if(ticketsArr!==undefined && ticketsArr.length!==0 && props.pax_det[0]!==undefined){
                let active_pax = props.pax_det[0].filter(s => (s.split_pnr == null || s.stsid === "23"))                
                let pax_details = props.pax_det[0].filter(s => (s.split_pnr == null || s.stsid === "31" || s.stsid === "39" || s.stsid === "42" || s.stsid === "44"))
                if(ticketsArr.length!==pax_details.length){
                    if(active_pax.length == 1){
                    dispatch({ type: 'enableSplit', payload: false })
                    dispatch({ type: 'enableReissue', payload: true })
                    }
                    else{
                    dispatch({ type: 'enableSplit', payload: true })
                    dispatch({ type: 'enableReissue', payload: false })
                    }
                }else{
                    dispatch({ type: 'enableSplit', payload: false })
                    dispatch({ type: 'enableReissue', payload: true })
                }
            }else{
                    dispatch({ type: 'enableReissue', payload: true })
                    dispatch({ type: 'enableSplit', payload: false })
            }
     }
     //split tickets

    const continueHoldSplitPnr = ()=>{
        props.sendOnHoldTkts(onHoldJSONarray)
        }

    const continueHoldRSplitPnr = ()=>{
        props.sendOnHoldTktsReturn(onHoldJSONarray)
    }



     //Refund Ticket
     useEffect(() => {
         if (props.enableRefundTkt) {
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: false }) //condition to check if refundable checking is necessary or not
             //props.refundTickets(ticketsArr)
         }
     }, [props.enableRefundTkt])

     //Refund Ticket
     useEffect(() => {
         if (props.enableReisueTkt) {
             ticketsArr = []
             setEnableCheck(true)
             dispatch({ type: 'checkRefundable', payload: false }) //condition to check if refundable checking is necessary or not
             //props.refundTickets(ticketsArr)
         }else{
            setEnableCheck(false)
         }
     }, [props.enableReisueTkt])


     const continueRefund = (data) => {
         props.sendRefund(data)
     }

     const continueEligibility = () => {
         props.sendReissueTickts(ticketReisueArr)
     }

     const continueSplitPnr = () => {
        props.sendReissueSplit(ticketReisueArr)
    }

    const continueSplitPnrreturn = () => {
        props.sendReissueSplitReturn(ticketReisueArrReturn)
    }
     const continueEligibilityReturn=()=>{
        props.sendReissueTicktsReturn(ticketReisueArrReturn)
     }


     const getStatus=(id)=>{
      let stary=bookingStatus.filter((obj) => obj.value === id);
      return stary[0].label;
     }

     const handleOnKeyup = (e)=>{
        Utilities.handleKeyUp(e)
     }
     const handlePaxChange = (data,type) => (e) => {
         const { id, value } = e.target
         dispatch({ type: id, payload: value });
         localStorage.removeItem(EDIT_TICKET_DATA);
         if(props.pax_det.length!==0){
             for(let val of props.pax_det){
                for(let pax of val){
                    if(pax.trv_op_id===data.trv_op_id){
                     pax.changed=true;
                     if(type==="status"){
                         pax.sts=getStatus(value);
                         pax.stid=value;
                        }
                     if(type==="ticket"){
                         pax.tkt_no=value;
                     }
                    }
                }
             }
             localStorage.setItem(EDIT_TICKET_DATA,JSON.stringify(props.pax_det));
        }
     }

     const cancelReissue = () => {
         return window.location = "/ezytrip/dashboard/bookingsummary/" + props.bookingId;
     }

     const mealPreference = (m) => {
         if(m) {
             for (let val of mp) {
                 if(val.code == m) {
                     return val.name
                 }
             }
         } else {
             return ""
         }
     }
     const specialRequest = (s) => {
         if(s) {
             for (let val of sr) {
                 if(val.value == s) {
                     return val.label
                 }
             }
         } else {
             return "N/A"
         }
     }

     const specialAssistance = (s) => {
         if(s) {
             for (let val of sa) {
                 if(val.code == s) {
                     return val.name
                 }
             }
         } else {
             return ""
         }
     }
     const handleChange = evt => {
         const { id, value } = evt.target;
         let isbook=false;
         if (state.isCancelBooking) {
             dispatch({ type: "isCancelBooking", payload: false })
             isbook=false;
         }else{
             dispatch({ type: "isCancelBooking", payload: true })
             isbook=true;
         }
         dispatch({ type: id, payload: value });
         props.sendCancelBook(isbook);
     }

     const bagLabelConstruction = (tag) => {
         let tagChanged = ""
         if(tag && tag.includes("total")) {
            let splitted_tag = tag.split("total")
            tagChanged = splitted_tag[0]
         } else if(tag) {
             let splitted_tag = tag.split("-")
             tagChanged = splitted_tag[0] +" - "+splitted_tag[1]
         }

         return tagChanged;
     }
     return (
         <>


             {/* <div className="header">
                 <Row>
                     <Col xs="2">Traveller</Col>
                     <Col>Pax Type</Col>
                     <Col xs="2">Sectors</Col>
                     <Col xs="2">Ticket Numbers</Col>
                     <Col xs="1">Baggage</Col>
                     <Col xs="2">Special Request</Col>
                     <Col>Segment Status</Col>
                     <Col >Status</Col>
                 </Row>

             </div> */}
             <div className='table-responsive mobileTable'>
             <div className="passengerList">
             <Table className="paxTable">
                 <tr>
                 <th width="20%">Traveller</th>
                     <th  width="10%">Pax Type</th>
                     {props.pax_det!==undefined && isArrayNotEmpty(props.pax_det) && props.pax_det[0][0]!==undefined && props.pax_det[0][0].supplier!==undefined && props.pax_det[0][0].supplier === "4" &&
                     <th  width="10%">PNR</th>
                     }
                     {/* <th  width="10%">Sectors</th> */}
                     <th  width="15%">Ticket Numbers</th>
                     {isArrayNotEmpty(props.pax_det) && (props.pax_det[0][0]) && props.pax_det[0][0].supplier != 0 && (
                        <th  width="15%">Baggage</th>
                     )}

                     {isArrayNotEmpty(props.pax_det) && (props.pax_det[0][0]) && props.pax_det[0][0].supplier != 0 && (
                     <th  width="15%">Seat</th>
                     )}

                     {state.emdAvail &&
                     <th  width="15%">EMD Number</th>
                     }

                     {/* <th  width="10%">Baggage</th> */}
                     {isArrayNotEmpty(props.pax_det) && (props.pax_det[0][0]) && props.pax_det[0][0].supplier == 0 ? (
                       <th  width="15%">Special Request</th>
                     ): (
                        <th  width="15%">Meals</th>
                     )}
                     <th  width="10%">Segment Status</th>
                     <th  width="15%">Ticketing status</th>
                 </tr>




                 {/*  passeneger details for split fare onward pax and return pax we are showing seperatly */}
                 {isArrayNotEmpty(props.pax_det) && props.pax_det!==undefined && props.pax_det.map((paxobj, ind) => (


                     paxobj.map((pax, index) => (
                         <React.Fragment key={index}>
                             <tr className={(props.tktsVoided.includes(pax.tkt_no) || props.voidedCancelled.includes(pax.tkt_no)) || pax.stsid === "31" || pax.stsid === "39" || pax.stsid === "42" || pax.stsid === "44" || (props.reIssuedOld !== undefined && props.reIssuedOld) ? 'list voided' : 'list '}>
                             {(pax.split_pnr === null ||  pax.stsid === "31" || pax.stsid === "39" || pax.stsid === "42" || pax.stsid === "44" || pax.stsid === "22" || pax.stsid === "37" || pax.stsid === "23") &&
                             <>
                             <td><div className="voidTkt">{
                             pax.sts == "Pending" &&
                             props.enablesptpnr &&
                             <Form.Check
                              ref = {checkboxRef}
                              type="checkbox" label=""
                             checked = {onholdTicket.includes(pax.pax_name)}
                             onChange={onHoldsplitSelection(pax)} custom id={pax.pax_name} />
                             }

                            {
                             pax.sts == "Pending" &&
                             props.enableRsptpnr &&
                             <Form.Check
                             ref = {checkboxRRef}
                              type="checkbox" label=""
                             checked = {onholdTicket.includes(pax.pax_name)}
                             onChange={onHoldsplitSelection(pax)} custom id={pax.pax_name} />
                             }

                            {pax.pax_name.toUpperCase()}{(isNotNull(pax.is_lead_pax) && pax.is_lead_pax !== 0) && '(lead)'}
                            </div>
                            </td>
                                     <td>{pax.pax_type}</td>
                                     {pax.supplier === "4" &&
                                     <td>{pax.split_pnr!==null?pax.split_pnr:pax.gds_pnr}</td>
                                      }
                                    { (pax.journey_type == "O" || pax.journey_type == "O/R") &&
                                         <td> <div className={pax.sts==="Refund/booking" || pax.sts==="Refund/cancel" ? "voidTkt text-danger": "voidTkt"}>{enableCheck && props.onward  && pax.supplier == 0  && (!props.tktsVoided.includes(pax.tkt_no) && !props.voidedCancelled.includes(pax.tkt_no)) && pax.tkt_no && pax.sts!=="Refund/booking" && pax.sts!=="Refund/cancel"  && pax.stsid !== "31" && pax.stsid !== "39" && pax.stsid !== "42" && pax.stsid !== "44" &&
                                             <Form.Check type="checkbox" label="" disabled={pax.sts==="Cancelled" || pax.sts==="Refund/booking" || pax.sts==="Refund/cancel"} checked={ticketsArr.includes(pax.tkt_no)} onChange={ticketsTobeVoid(pax)} custom id={pax.tkt_no} />

                                         }
                                         {!props.isEdit && !props.isEditReturn &&
                                             <>
                                              {pax.tkt_no ? pax.tkt_no : "N/A"} {(props.tktsVoided.includes(pax.tkt_no) || props.voidedCancelled.includes(pax.tkt_no) || pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {props.reIssuedOld !== undefined && props.reIssuedOld && pax.tkt_no ? "(E)" : ""}
                                             </>
                                         }



                                      {(props.isEdit || props.isEditReturn) &&
                                      <Form.Row>
                                            <Form.Group as={Col} controlId="tkt_no" className="edittime d-flex align-items-center justify-content-between m-0">
                                            <Form.Control type="text" placeholder="Enter Ticket number" value={pax.tkt_no} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"ticket")} />
                                          </Form.Group>
                                          </Form.Row>
                                         }

                                        {(props.refundAvailable || pax.stsid === "42" || pax.stsid === "44") &&
                                         <div className="status text-danger">
                                         {pax.sts==="Refund/booking" || pax.sts==="Refund/cancel" || pax.stsid === "42" || pax.stsid === "44"?("(R)") : ""}
                                         </div>
                                        }

                                      </div>
                                     </td>

                                   }

                                   { pax.journey_type == "R"  &&
                                         <td> <div className={pax.sts==="Refund/booking" || pax.sts==="Refund/cancel" ? "voidTkt text-danger": "voidTkt"}>{enableCheck && !props.onward  && pax.supplier == 0  && (!props.tktsVoided.includes(pax.tkt_no) && !props.voidedCancelled.includes(pax.tkt_no)) && pax.tkt_no && pax.sts!=="Refund/booking" && pax.sts!=="Refund/cancel"  && pax.stsid !== "31" && pax.stsid !== "39" && pax.stsid !== "42" && pax.stsid !== "44" &&
                                             <Form.Check type="checkbox" label="" disabled={pax.sts==="Cancelled" || pax.sts==="Refund/booking" || pax.sts==="Refund/cancel"} checked={ticketsArr.includes(pax.tkt_no)} onChange={ticketsTobeVoid(pax)} custom id={pax.tkt_no} />

                                         }
                                         {!props.isEdit && !props.isEditReturn &&
                                             <>
                                              {pax.tkt_no ? pax.tkt_no : "N/A"} {(props.tktsVoided.includes(pax.tkt_no) || props.voidedCancelled.includes(pax.tkt_no) || pax.stsid === "31" || pax.stsid === "39") ? ("(V)") : ""} {props.reIssuedOld !== undefined && props.reIssuedOld && pax.tkt_no ? "(E)" : ""}
                                             </>
                                         }



                                      {(props.isEdit || props.isEditReturn) &&
                                      <Form.Row>
                                      <Form.Group as={Col} controlId="tkt_no" className="edittime d-flex align-items-center justify-content-between m-0">
                                      <Form.Control type="text" placeholder="Enter Ticket number" value={pax.tkt_no} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"ticket")} />
                                    </Form.Group>
                                    </Form.Row>
                                         }

                                        {(props.refundAvailable || pax.stsid === "42" || pax.stsid === "44") &&
                                         <div className="status text-danger">
                                         {pax.sts==="Refund/booking" || pax.sts==="Refund/cancel" || pax.stsid === "42" || pax.stsid === "44"?("(R)") : ""}
                                         </div>
                                        }

                                      </div>
                                     </td>

                                   }
                                    {props.pax_det[0][0].supplier !== "0" ? pax.bag_lable !== undefined && pax.bag_lable !== "" ?
                                    <td>{bagLabelConstruction(pax.bag_lable)} </td> : <td>{"N/A"} </td>: null
                                    }

                                   {props.pax_det[0][0].supplier !== "0" ? pax.seg_seat_no !== undefined && pax.seg_seat_no !== "" ?
                                    <td>{pax.seg_seat_no} </td>:<td>{"N/A"} </td>: null
                                    }

                                     {state.emdAvail &&
                                         <td >
                                            {pax.emd_number}
                                          </td>
                                      }
                                     {/* <td>{pax.pax_type !== "INF" ? pax.bgd : "-"}</td> */}
                                     {pax.supplier == 0 ? (
                                     <td >
                                        <span className="special"> {mealPreference(pax.mp)}{specialRequest(pax.sp)}{specialAssistance(pax.sa)}</span>
                                     </td>) : (
                                         <td >
                                         {pax.meal_lable ? pax.meal_lable : "N/A"}
                                       </td>
                                     )}

                                     <td>
                                     <span className={"status paxstatus " + ((pax.segst == "Confirmed") && ('text-success')||(pax.segst == "Incomplete") && ('text-primery') ||(pax.segst == "On Hold") && ('text-primery')  || (pax.segst == "Discarded") && ('text-danger') || (pax.segst == "Ticketing failed") && ('text-danger')|| (pax.segst == "Cancelled") && ('text-danger'))}> {pax.segst}</span>
                                     </td>

                                     {props.voidedCancelled.includes(pax.tkt_no) ? (
                                         <td ><span className="status text-danger">Void/Cancel</span></td>
                                     ) : (

                                             <td >
                                                 {!props.isEdit && !props.isEditReturn &&
                                     <span className={"status paxstatus " + ((props.reIssuedOld) && ('text-primery') || (pax.sts == "Confirmed") && ('text-success')||(pax.sts == "Incomplete") && ('text-primery') ||(pax.sts == "On Hold") && ('text-primery')  || (pax.sts == "Discarded") && ('text-danger') || (pax.sts == "Failed") && ('text-danger')|| (pax.sts == "Cancelled") && ('text-danger'))}>{props.reIssuedOld ? "Exchanged" : pax.sts}</span>

                                                 }

                                              {(props.isEdit || props.isEditReturn) &&
                                             <Form.Row>
                                                 <Form.Group as={Col} controlId="stid" className="customSelect editOne m-0" >
                                                <Form.Control as="select" value={pax.stid} onKeyUp={(e)=>{handleOnKeyup(e)}} onChange={handlePaxChange(pax,"status")}>
                                                 {state.ticketStatusList}
                                                </Form.Control>
                                               </Form.Group>
                                               </Form.Row>
                                                 }

                                             </td>
                                         )}
                              </>
                            }
                             </tr>
                             {(pax.split_pnr === null ||  pax.stsid === "31" || pax.stsid === "39" || pax.stsid === "42" || pax.stsid === "44") &&

                             <tr>
                                 <td className="spaceNew" colSpan="8"></td>
                             </tr>
 }
                         </React.Fragment>
                     ))
                 ))}

             </Table>
             </div>
             </div>

             {
                props.enableRsptpnr && props.onhold_selected_split == "R" &&
                state.onHoldSplit &&
                 <div className="d-flex justify-content-end pb-4">
                     {/* <div className="reissueSMS">
                         <span className="ezyIcon icon-info"> Kindly select the tickets to be reissued & click on check eligibility button to continue</span>
                         <span> -  {props.reissueMsg}</span>
                     </div> */}

                         <Button
                         size="xs"
                         variant="outline-secondary"
                         className="mr-3"
                         onClick={cancelReissue}
                     >Cancel</Button>
                     {/* {state.enableReissue &&
                        <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueEligibilityReturn}
                         >Check Eligibility</Button>
                     } */}
                    {
                           <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueHoldRSplitPnr}
                         >Split PNR</Button>
                        }


                 </div>
             }
             {
                props.enablesptpnr && props.onhold_selected_split == "O" &&
                state.onHoldSplit &&
                 <div className="d-flex justify-content-end pb-4">
                     {/* <div className="reissueSMS">
                         <span className="ezyIcon icon-info"> Kindly select the tickets to be reissued & click on check eligibility button to continue</span>
                         <span> -  {props.reissueMsg}</span>
                     </div> */}

                         <Button
                         size="xs"
                         variant="outline-secondary"
                         className="mr-3"
                         onClick={cancelReissue}
                     >Cancel</Button>
                     {/* {state.enableReissue &&
                        <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueEligibilityReturn}
                         >Check Eligibility</Button>
                     } */}
                    {
                           <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueHoldSplitPnr}
                         >Split PNR</Button>
                        }


                 </div>
             }

             {props.enableReisueTkt && props.onward &&
                 <div className="d-flex justify-content-between pb-4 kindlySelect">
                     <div className="reissueSMS">
                         <span className="ezyIcon icon-info">Kindly select the tickets to be reissued & click on check eligibility button to continue</span>
                         <span> -  {props.reissueMsg}</span>
                     </div>
                     <div><Button
                         size="xs"
                         variant="outline-secondary"
                         className="mr-3"
                         onClick={cancelReissue}
                     >Cancel</Button>
                     {state.enableReissue &&
                        <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueEligibility}
                         >Check Eligibility</Button>
                     }
                    {state.enableSplit &&
                           <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueSplitPnr}
                         >Split PNR</Button>
                        }
                </div>

                 </div>
             }

             {props.enbaleReissueReturn && !props.onward &&
                 <div className="d-flex justify-content-between pb-4 mt align-items-center">
                     <div className="reissueSMS">
                         <span className="ezyIcon icon-info"> Kindly select the tickets to be reissued & click on check eligibility button to continue</span>
                         <span> -  {props.reissueMsg}</span>
                     </div>
                     <div><Button
                         size="xs"
                         variant="outline-secondary"
                         className="mr-3"
                         onClick={cancelReissue}
                     >Cancel</Button>
                     {state.enableReissue &&
                        <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueEligibilityReturn}
                         >Check Eligibility</Button>
                     }
                    {state.enableSplit &&
                           <Button
                             size="xs"
                             variant="outline-primary"
                             onClick={continueSplitPnrreturn}
                         >Split PNR</Button>
                        }
                        </div>

                 </div>
             }

             {(props.enableVoidCancel || props.enableVoidBookingReturn || props.enableVoidBooking || props.enableVoidBookingOnward) && !props.enableRefundTkt &&
                  <div className="d-flex justify-content-between pb-1 align-items-center">
                  <div className="reissueSMS mb-2">
                  <span className="ezyIcon icon-info"> Kindly select the tickets to be Voided & click on Continue button to continue</span>

                  </div>
                  </div>
             }

             <div>
             <Form.Row>
                     <Col xs={12} className="d-flex refundBotBlock">
                 {props.enableRefundTkt && props.flowType==="normal" &&
                     <RefundType type={"onward"} ticketsList={ticketsArr} bookDetails={props.bookDetails} sendRefund={continueRefund} />
                 }

                 {props.enableRefundTkt && props.flowType==="normal" &&
                     <Form.Check type="checkbox" label="Cancel Booking" custom id="bk" value={state.isCancelBooking} onChange={handleChange} />
                }
                </Col>
                 </Form.Row>
             </div>

         </>
     )
 }


 export default FlightSummaryPaxDetails;
