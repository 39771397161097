import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../../App";
import Button from '../../../../common/buttons/Button';
import DateUtils from '../../../../commonUtils/DateUtils';
import {  getCompaniesList, getUserDsrReportData } from "../../../airServices/dsrReport/operations";
import { getUserHotelDsrReportData,getUserInsuranceReportData} from "../../../hotelServices/dsrReport/operations";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../../../common/loader/InnerLoader';
import NoRecord from '../../../../common/popUps/NoRecord';
import Breadcrumb from '../../../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../../../common/ReportMenu';
import CoomonExportExcel from '../../../../common/CoomonExportExcel';
import MyreportMenu from "../../../../common/subMenu/MyReportMenu";
let airportsData = require('../../../../masterData/airlinesData.json');
/**
 * @description:This function will creaet all the search criteria
 * @author: pawan
 * @param {*}
 * @function Common Search component
 * @date : 25-01-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "", aplType: "", productData: [], companyData: [], branchData: [], applTypData: [], travelData: [], productReport: "",product:"", totals: ""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air dsrdata List and Add Markup
function DSRReports(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [dsrdata, setDsrData] = useState({ dsrReportList: [] });
  const [dsrExceldata, setDsrExcelData] = useState({ dsrReportExcelList: [] });
  const [products,setProducts] =useState([]);

  useEffect(() => {

    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      if(context.logindata.utype==="sta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }else if(context.logindata.utype==="ta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }
      dispatch({ type: 'userId', payload: context.logindata.uid })
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      getCompaniesList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'companies', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
      let productsList=[];
      if(context.logindata.roles!==undefined){
      if(context.logindata.roles.includes("F_R_P_T")){
        productsList.push({ label: "Flight", value: 'Flight' });
      }
      if(context.logindata.roles.includes("H_R_P_T")){
        productsList.push({ label: "Hotel", value: 'Hotel' });
      }
      if(context.logindata.roles.includes("I_D_R_P")){
        productsList.push({ label: "Insurance", value: 'Insurance' });
      }
      setProducts(productsList);
    }
    }
    //loadResponse();
  }, [context.logindata]);

  useEffect(()=>{
    if(dsrdata.dsrReportList && dsrdata.dsrReportList.length !== 0){
dsrExceldata.dsrReportExcelList = dsrdata.dsrReportList
let restotal = state.totals
if(state.productSelected == "Flight"){
let total = {
  region_type: restotal.region_type,
  currency: restotal.region_type=="Qatar"?"QAR":"INR",
  trstype: "Grand Total",
  publishfare: restotal.tot_publishfare.toFixed(2),
  basefare: restotal.tot_basefare.toFixed(2),
  tax: restotal.tot_tax.toFixed(2),
  servicefee: restotal.tot_servicefee.toFixed(2),
  cost : restotal.tot_cost.toFixed(2),
  markup: restotal.tot_markup.toFixed(2),
  bag_fare: restotal.tot_bag_fare.toFixed(2),
  meal_fare: restotal.tot_meal_fare.toFixed(2),
  seat_fare: restotal.tot_seat_fare.toFixed(2),
  penalty: restotal.tot_penalty.toFixed(2),
  disc: restotal.tot_disc.toFixed(2),
  cccharge : restotal.tot_cccharge !== null && restotal.tot_cccharge.toFixed(2),
  plb: restotal.tot_plb.toFixed(2),
  selprice : restotal.tot_selprice.toFixed(2),
  profit: restotal.tot_profit.toFixed(2),
  supfee: restotal.tot_supfee.toFixed(2),
  tds: restotal.tot_tds.toFixed(2),
  cgst: restotal.tot_cgst.toFixed(2),
  sgst: restotal.tot_sgst.toFixed(2),
  igst: restotal.tot_igst.toFixed(2)
  }
  let excelData = dsrExceldata.dsrReportExcelList.concat(total)
  setDsrExcelData({dsrReportExcelList: excelData})
}
else if(state.productSelected == "Hotel"){
  let total = {
      region_type: restotal.region_type,
      currency: restotal.region_type=="Qatar"?"QAR":"INR",
      star_rate: "Grand Total",
      pub_fare: restotal.tot_pub_fare.toFixed(2),
      mkt_fare: restotal.tot_mkt_fare.toFixed(2),
      tax: restotal.tot_tax.toFixed(2),
      svc_fee : restotal.tot_svc_fee.toFixed(2),
      cost: restotal.tot_cost.toFixed(2),
      markup: restotal.tot_markup.toFixed(2),
      disc : restotal.tot_disc.toFixed(2),
      cccharge: restotal.tot_cccharge.toFixed(2),
      tds: restotal.tot_tds.toFixed(2),
      cgst: restotal.tot_cgst.toFixed(2),
      sgst: restotal.tot_sgst.toFixed(2),
      igst: restotal.tot_igst.toFixed(2),
      selprice : restotal.tot_selprice !== null &&  restotal.tot_selprice.toFixed(2),
      profit: restotal.tot_profit !== null && restotal.tot_profit.toFixed(2)
    }
     let excelData = dsrExceldata.dsrReportExcelList.concat(total)
    setDsrExcelData({dsrReportExcelList: excelData})
  }
  else{
    let total = {
      region_type: restotal.region_type,
      currency: restotal.region_type=="Qatar"?"QAR":"INR",
      transaction_type: "Grand Total",
      fare: restotal.tot_fare.toFixed(2),
      covid_ext: restotal.tot_covid_ext.toFixed(2),
      tax: restotal.tot_tax.toFixed(2),
      service_fee : restotal.tot_servicefee.toFixed(2),
      cost: restotal.tot_cost.toFixed(2),
      markup: restotal.tot_markup.toFixed(2),
      win_spo_ext : restotal.tot_win_spo_ext.toFixed(2),
      disc: restotal.tot_disc.toFixed(2),
      cc_charges : restotal.tot_ccharge.toFixed(2),
      supplier_fee: restotal.tot_supplier_fee.toFixed(2),
      selling_price: restotal.tot_selling_price.toFixed(2),
      profit: restotal.tot_profit.toFixed(2),
    }
     let excelData = dsrExceldata.dsrReportExcelList.concat(total)
    setDsrExcelData({dsrReportExcelList: excelData})
  }
  }
  }, [dsrdata.dsrReportList] )
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateSearch=()=>{
    if(fromDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select From Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(toDate===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select To Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.product===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Product' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    return true;
  }

  //Handling the search creterai based pn the selection and dates
  const handleSearch = () => {
      if (validateSearch()){
        setDsrData({ dsrReportList: []})
        setDsrExcelData({dsrReportExcelList: []})
        dispatch({ type: 'totals', payload: "" })
        setIsLoadingMore(true)
        if(state.product == "Flight"){
        if(state.userType === "att_user"){
          const req = {
            "region_type":context.logindata.region_type,
            "vtype": "MTA",
            "uid": state.userId,
            "cid": "",
            "utype":"att_user",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
          }
          getUserDsrReportData(req).then(response => {
            if (response.res.success){
              dispatch({ type: 'productSelected', payload: "Flight" })
              dispatch({ type: 'totals', payload: response.res.totals })
              for(let val of response.res.data){
                if(val.ticket_status == "Void" || val.ticket_status == "Void/cancel" || val.ticket_status == "Refund/booking"	|| val.ticket_status == "Refund/cancel"){
                  val.region_type = val.region_type
                  val.currency = val.region_type=="Qatar"?"QAR":"INR"
                  val.publishfare = -val.publishfare
                  val.basefare = -val.basefare
                  val.tax = -val.tax
                  val.servicefee = -val.servicefee
                  val.cost = -val.cost
                  val.penalty = -val.penalty
                  val.markup = -val.markup
                  val.disc = -val.disc
                  val.cccharge = val.cccharge !== undefined ? -val.cccharge : 0
                  val.plb = -val.plb
                  val.tds_amt = -val.tds_amt
                  val.selprice = -val.selprice
                  val.profit = -val.profit
                  val.supfee = -val.supfee
                  val.cgst = -val.cgst
                  val.sgst = -val.sgst
                  val.igst = -val.igst
                }
                if(val.markting_airline.includes(",")){
                  let airAry=val.markting_airline.split(",")
                  let airlineName=getAirlineName(airAry[0])+","+getAirlineName(airAry[1])+","+getAirlineName(airAry[2]);
                  val.markting_airline=airlineName;
                }else{
                  val.markting_airline=getAirlineName(val.markting_airline);
                }
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.selprice = val.selprice?val.selprice.toFixed(2):null
                val.cost = val.cost?val.cost.toFixed(2):null
                val.publishfare = val.publishfare?val.publishfare.toFixed(2):null
                val.basefare = val.basefare?val.basefare.toFixed(2):null
                val.tax = val.tax?val.tax.toFixed(2):null
                val.servicefee = val.servicefee?val.servicefee.toFixed(2):null
                val.markup = val.markup?val.markup.toFixed(2):null
                val.bag_fare = val.bag_fare?val.bag_fare.toFixed(2):null
                val.meal_fare = val.meal_fare?val.meal_fare.toFixed(2):null
                val.seat_fare = val.seat_fare?val.seat_fare.toFixed(2):null
                val.profit = val.profit?val.profit.toFixed(2):null
                val.tds_amt = val.tds_amt
                val.cgst = val.cgst
                val.sgst = val.sgst
                val.igst = val.igst
                }
              setDsrData({ dsrReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
         if(state.cid!==0 && state.cid!==""){
          var userType = (state.userType === "ta_user") ? "TA" : "STA";
          const request = {
            "region_type":context.logindata.region_type,
            "vtype": userType,
            "uid": state.userId,
            "utype":state.userType,
            "cid": (state.cid !== 0) ? state.cid : "",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
         }
         getUserDsrReportData(request).then(response => {

          if (response.res.success){
            dispatch({ type: 'totals', payload: response.res.totals })
            for(let val of response.res.data){
              if(val.ticket_status == "Void" || val.ticket_status == "Void/cancel" || val.ticket_status == "Refund/booking"	|| val.ticket_status == "Refund/cancel"){
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.publishfare = -val.publishfare
                val.basefare = -val.basefare
                val.tax = -val.tax
                val.servicefee = -val.servicefee
                val.cost = -val.cost
                val.penalty = -val.penalty
                val.markup = -val.markup
                val.disc = -val.disc
                val.cccharge = val.cccharge !== undefined ? -val.cccharge : 0
                val.plb = -val.plb
                val.tds_amt = -val.tds_amt
                val.selprice = -val.selprice
                val.profit = -val.profit
                val.supfee = -val.supfee
                val.cgst = -val.cgst
                val.sgst = -val.sgst
                val.igst = -val.igst
              }
              if(val.markting_airline.includes(",")){
                let airAry=val.markting_airline.split(",")
                let airlineName=getAirlineName(airAry[0])+","+getAirlineName(airAry[1])+","+getAirlineName(airAry[2]);
                val.markting_airline=airlineName;
              }else{
                val.markting_airline=getAirlineName(val.markting_airline);
              }
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.selprice = val.selprice ? val.selprice.toFixed(2):null;
                val.cost = val.cost ? val.cost.toFixed(2) : null;
                val.publishfare = val.publishfare ? val.publishfare.toFixed(2) : null;
                val.basefare = val.basefare ? val.basefare.toFixed(2) : null;
                val.tax = val.tax ? val.tax.toFixed(2) : null;
                val.servicefee = val.service_fee ? val.servicefee.toFixed(2) : null;
                val.markup = val.markup ? val.markup.toFixed(2) : null;
                val.bag_fare = val.bag_fare ? val.bag_fare.toFixed(2) : null;
                val.meal_fare = val.meal_fare ? val.meal_fare.toFixed(2) : null;
                val.seat_fare = val.seat_fare ? val.seat_fare.toFixed(2) : null;
                val.profit = val.profit ? val.profit.toFixed(2) : null;
                val.tds_amt = val.tds_amt ? val.tds_amt.toFixed(2) : null;
                val.cgst = val.cgst ? Number(val.cgst).toFixed(2) : null;
                val.sgst = val.sgst ? Number(val.sgst).toFixed(2) : null;
                val.igst = val.igst ? Number(val.igst).toFixed(2) : null;
              }
            dispatch({ type: 'productSelected', payload: "Flight" })
            setDsrData({ dsrReportList: response.res.data })
            setIsLoadingMore(false)
          }else{
            setIsLoadingMore(false)
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
        }
        else{
        setIsLoadingMore(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        }
      }
    }
    else{
      if(state.product == "Hotel"){
        if(state.userType === "att_user" ){
          const req = {
            "region_type":context.logindata.region_type,
            "vtype": "MTA",
            "uid": state.userId,
            "cid": "",
            "utype":"att_user",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
          }
          getUserHotelDsrReportData(req).then(response => {
            if (response.suc){
              dispatch({ type: 'productSelected', payload: "Hotel" })
              dispatch({ type: 'totals', payload: response.res.totals })
              for(let val of response.res.data){
                  val.region_type = val.region_type
                  val.currency = val.region_type=="Qatar"?"QAR":"INR"
                  val.doc_date = DateUtils.prettyDate(val.doc_date, "ddd DD,MMM,YYYY, HH:mm:ss A")
                  val.tds = val.tds !== null && val.tds.toFixed(2)
                  val.selprice = val.selprice !== null && val.selprice.toFixed(2)
                  val.cost = val.cost.toFixed(2)
                  val.pub_fare = val.pub_fare.toFixed(2)
                  val.mkt_fare = val.mkt_fare.toFixed(2)
                  val.tax = val.tax.toFixed(2)
                  val.svc_fee = val.svc_fee.toFixed(2)
                  val.markup = val.markup.toFixed(2)
                  val.disc = val.disc.toFixed(2)
                  val.cccharge = val.cccharge.toFixed(2)
                  val.profit = val.profit !== null && val.profit.toFixed(2)
                }
              setDsrData({ dsrReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
         if(state.cid!==0 && state.cid!==""){
          var userType = (state.userType === "ta_user") ? "TA" : "STA";
          const request = {
            "region_type":context.logindata.region_type,
            "vtype": userType,
            "uid": state.userId,
            "utype":state.userType,
            "cid": (state.cid !== 0) ? state.cid : "",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : ""
         }
         getUserHotelDsrReportData(request).then(response => {
          if (response.suc){
            dispatch({ type: 'productSelected', payload: "Hotel" })
            dispatch({ type: 'totals', payload: response.res.totals })
            for(let val of response.res.data){
                val.region_type = val.region_type
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
                val.doc_date = DateUtils.prettyDate(val.doc_date, "ddd DD,MMM,YYYY, HH:mm:ss A")
                val.tds = val.tds !== null ? val.tds.toFixed(2) : null
                val.selprice = val.selprice !== null ? val.selprice.toFixed(2) : null
                val.cost = val.cost.toFixed(2)
                val.pub_fare = val.pub_fare.toFixed(2)
                val.mkt_fare = val.mkt_fare.toFixed(2)
                val.tax = val.tax.toFixed(2)
                val.svc_fee = val.svc_fee.toFixed(2)
                val.markup = val.markup.toFixed(2)
                val.disc = val.disc.toFixed(2)
                val.cccharge = val.cccharge.toFixed(2)
                val.profit = val.profit !== null ? val.profit.toFixed(2) : null
              }
            setDsrData({ dsrReportList: response.res.data })
            setIsLoadingMore(false)
          }else{
            setIsLoadingMore(false)
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
        }
        else{
        setIsLoadingMore(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        }
      }
      }else if(state.product == "Insurance"){
        if(state.userType === "att_user" ){
          const req = {
            "region_type":context.logindata.region_type,
            "vtype": "MTA",
            "uid": state.userId,
            "cid": "",
            "utype":"att_user",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
            "rtype":"myreport",
            "bound_type":null,
          }
          getUserInsuranceReportData(req).then(response => {
            if (response.suc){
              dispatch({ type: 'productSelected', payload: "Insurance" })
              dispatch({ type: 'totals', payload: response.res.totals })
              for(let val of response.res.data){
                val.currency = val.region_type=="Qatar"?"QAR":"INR"
              }
              setDsrData({ dsrReportList: response.res.data })
              setIsLoadingMore(false)
            }else{
              setIsLoadingMore(false)
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
         if(state.cid!==0 && state.cid!==""){
          var userType = (state.userType === "ta_user") ? "TA" : "STA";
          const request = {
            "region_type":context.logindata.region_type,
            "vtype": userType,
            "uid": state.userId,
            "utype":state.userType,
            "cid": (state.cid !== 0) ? state.cid : "",
            "fromdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
            "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
            "rtype":"myreport",
            "bound_type":null,
         }
         getUserInsuranceReportData(request).then(response => {
          if (response.suc){
            dispatch({ type: 'productSelected', payload: "Insurance" })
            dispatch({ type: 'totals', payload: response.res.totals })
            for(let val of response.res.data){
              val.currency = val.region_type=="Qatar"?"QAR":"INR"
            }
            setDsrData({ dsrReportList: response.res.data })
            setIsLoadingMore(false)
          }else{
            setIsLoadingMore(false)
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
        }
        else{
        setIsLoadingMore(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        }
      }
      }
    }
  }
  }


  //Get Airline Name
  const getAirlineName=(code)=>{
    let name = airportsData.filter((obj) => obj.code === code);
    if(name!==undefined && name[0]!==undefined && name[0].english!==undefined){
      return name!==undefined && name[0]!==undefined && name[0].english!==undefined? name[0].english:"";
    }

  }


  const resetSearch = () => {
    setFromDate("")
    setToDate("")
    dispatch({ type: 'productData', payload: [] })
    dispatch({ type: 'productSelected', payload: "" })
    setDsrData({dsrReportList: []})
    setDsrExcelData({dsrReportExcelList: []})
    dispatch({ type: 'total', payload: ""})
  }


  const setProduct = (obj) => {
    if (obj[0] !== undefined && obj[0].value !== undefined) {
      setDsrData({dsrReportList: []})
      dispatch({ type: 'product', payload: obj[0].value })
      dispatch({ type: 'productData', payload: obj})
      dispatch({ type: 'productSelected', payload: ""})
    }
  }
if(state.product == "Flight"){
  var columns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch',
      text: 'Branch',
     sort: false,
      filter: ""
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: ""
    },
    {
      dataField: 'doc_date',
      text: 'Document Date',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_id',
      text: 'BKR No',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_type',
      text: 'Booking Type',
     sort: false,
      filter: ""
    },
    {
    dataField: 'pnrdte',
    text: 'PNR Creation Date',
    sort: false,
    filter: ""
  },
  {
    dataField: 'tofcid',
    text: 'Ticketing Office Id',
   sort: false,
    filter: ""
  },
  {
    dataField: 'wofcid',
    text: 'Owner Office Id',
   sort: false,
    filter: ""
  },
  {
    dataField: 'branch',
    text: 'Cost Center',
   sort: false,
    filter: ""
  },
  {
    dataField: 'dcmntno',
    text: 'Document No',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier',
    text: 'Supplier',
   sort: false,
    filter: ""
  },
  {
    dataField: 'saleschnl',
    text: 'Sales Channel',
   sort: false,
    filter: ""
  },
  {
    dataField: 'servicetype',
    text: 'Type of Service',
   sort: false,
    filter: ""
  },
  {
    dataField: 'gds_pnr',
    text: 'GDS PNR',
   sort: false,
    filter: ""
  },
  {
    dataField: 'airlnpnr',
    text: 'Airline PNR',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custcode',
    text: 'Customer Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custnm',
    text: 'Customer Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'custgrp',
    text: 'Customer Group',
   sort: false,
    filter: ""
  },
  {
    dataField: 'agentnm',
    text: 'Agent Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ticketno',
    text: 'Ticket Number',
   sort: false,
    filter: ""
  },
  {
    dataField: 'lstcunjticketno',
    text: 'Last Conjuction Ticket Number',
   sort: false,
    filter: ""
  },
  {
    dataField: 'pname',
    text: 'Pax Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ptyp',
    text: 'Pax Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'nopax',
    text: 'No of Pax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'deptdate',
    text: 'Deparure Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'arvaldate',
    text: 'Arrival Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'route',
    text: 'Route',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ticket_status',
    text: 'Ticket Status',
   sort: false,
    filter: ""
  },
  {
    dataField: 'nosegments',
    text: 'No of Segments',
   sort: false,
    filter: ""
  },
  {
    dataField: 'dealcode',
    text: 'Deal Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'destcode',
    text: 'Destination Code',
    sort: false,
    filter: ""
  },
  {
    dataField: 'desttination',
    text: 'Destination',
   sort: false,
    filter: ""
  },
  {
    dataField: 'country',
    text: 'Country',
   sort: false,
    filter: ""
  },
  {
    dataField: 'farebasis',
    text: 'Fare Basis',
   sort: false,
    filter: ""
  },
  {
    dataField: 'seccodefltno',
    text: 'Sector and Flight No',
   sort: false,
    filter: ""
  },
  {
    dataField: 'rbd',
    text: 'RBD Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'ocls',
    text: 'Onward Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'rcls',
    text: 'Return Class',
   sort: false,
    filter: ""
  },
  {
    dataField: 'trstype',
    text: 'Transaction Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'publishfare',
    text: 'Published Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'basefare',
    text: 'Base Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'tax',
    text: 'Tax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'servicefee',
    text: 'Service Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cost',
    text: 'Cost',
   sort: false,
    filter: ""
  },
  {
    dataField: 'markup',
    text: 'Markup',
   sort: false,
    filter: ""
  },
  {
    dataField: 'bag_fare',
    text: 'Baggage Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'meal_fare',
    text: 'Meal Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'seat_fare',
    text: 'Seat Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'disc',
    text: 'Discount',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cccharge',
    text: 'CC Charges',
   sort: false,
    filter: ""
  },
  {
    dataField: 'plb',
    text: 'PLB',
   sort: false,
    filter: ""
  },
  {
    dataField: 'penalty',
    text: 'Penalty',
   sort: false,
    filter: ""
  },
];
if (context.logindata.region_type=="India"){
var columnData=[
{
  dataField: 'cgst',
  text: 'CGST',
 sort: false,
  filter: ""
},
{
  dataField: 'sgst',
  text: 'SGST',
 sort: false,
  filter: ""
},
{
  dataField: 'igst',
  text: 'IGST',
 sort: false,
  filter: ""
},
{
  dataField: 'tds_amt',
  text: 'TDS',
 sort: false,
  filter: ""
},
{
  dataField: 'selprice',
  text: 'Selling Price',
 sort: false,
  filter: ""
},
{
  dataField: 'profit',
  text: 'Profit',
 sort: false,
  filter: ""
},
{
  dataField: 'supfee',
  text: 'Supplier Fee',
 sort: false,
  filter: ""
},
{
  dataField: 'udid_fields',
  text: 'UDID',
 sort: false,
  filter: ""
},
{
  dataField: 'invoicenumber',
  text: 'Invoice Number',
 sort: false,
  filter: ""
},
{
  dataField: 'markting_airline',
  text: 'Airline Name',
 sort: false,
  filter: ""
},
{
  dataField: 'agentnm',
  text: 'Staff Name',
 sort: false,
  filter: ""
},
{
  dataField: 'emd_number',
  text: 'EMD Number',
 sort: false,
  filter: ""
},]
columns.push(...columnData)
}else{
  var columnData = [{
    dataField: 'selprice',
    text: 'Selling Price',
   sort: false,
    filter: ""
  },
  {
    dataField: 'profit',
    text: 'Profit',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supfee',
    text: 'Supplier Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'udid_fields',
    text: 'UDID',
   sort: false,
    filter: ""
  },
  {
    dataField: 'invoicenumber',
    text: 'Invoice Number',
   sort: false,
    filter: ""
  },
  {
    dataField: 'markting_airline',
    text: 'Airline Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'agentnm',
    text: 'Staff Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'emd_number',
    text: 'EMD Number',
   sort: false,
    filter: ""
  },]
  columns.push(...columnData)
}
  var defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  var csvHeaders = [
{ label: 'Region Type', key: 'region_type' },
{ label: 'Currency', key: 'currency' },
{ label: 'Document Date', key: 'doc_date' },
{ label: 'Booking Ref No', key: 'booking_id' },
{ label: 'Booking Type', key: 'booking_type' },
{ label: 'PNR Creation Date', key: 'pnrdte' },
{ label: 'Ticketing Office Id', key: 'tofcid' },
{ label: 'Owner Office Id', key: 'wofcid' },
{ label: 'Branch', key: 'branch' },
{ label: 'Document No', key: 'dcmntno' },
{ label: 'Supplier', key: 'supplier' },
{ label: 'Sales Channel', key: 'saleschnl' },
{ label: 'Type of Service', key: 'servicetype' },
{ label: 'GDS PNR', key: 'gds_pnr' },
{ label: 'Airline PNR', key: 'airlnpnr' },
{ label: 'Customer Code', key: 'custcode' },
{ label: 'Customer Name', key: 'custnm' },
{ label: 'Customer Group', key: 'custgrp' },
{ label: 'Ticket Number', key: 'ticketno' },
{ label: 'Last Conjuction Ticket No', key: 'lstcunjticketno' },
{ label: 'Pax Name', key: 'pname' },
{ label: 'Pax Type', key: 'ptyp' },
{ label: 'No of Pax', key: 'nopax' },
{ label: 'Departure Date', key: 'deptdate' },
{ label: 'Arrival Date', key: 'arvaldate' },
{ label: 'Route', key: 'route' },
{ label: 'Ticket Status', key: 'ticket_status' },
{ label: 'No of Segments', key: 'nosegments' },
{ label: 'Deal Code', key: 'dealcode' },
{ label: 'Destination', key: 'desttination' },
{ label: 'Destination Code', key: 'destcode' },
{ label: 'Country', key: 'country' },
{ label: 'Farebasis', key: 'farebasis' },
{ label: 'Sector and Flight No', key: 'seccodefltno' },
{ label: 'RBD', key: 'rbd' },
{ label: 'Onward Class', key: 'ocls' },
{ label: 'Return Class', key: 'rcls' },
{ label: 'Transaction Type', key: 'trstype' },
{ label: 'Published Fare', key: 'publishfare' },
{ label: 'Base Fare', key: 'basefare' },
{ label: 'Tax', key: 'tax' },
{ label: 'Service Fee', key: 'servicefee' },
{ label: 'Penalty', key: 'penalty' },
{ label: 'Cost', key: 'cost' },
{ label: 'Markup', key: 'markup' },
{ label: 'Baggage Fare', key: 'bag_fare' },
{ label: 'Meal Fare', key: 'meal_fare' },
{ label: 'Seat Fare', key: 'seat_fare' },
{ label: 'Disc', key: 'disc' },
{ label: 'CC Charge', key: 'cccharge' },
{ label: 'PLB', key: 'plb' },
];
if (context.logindata.region_type=="India"){
var exportData= [
  { label: 'CGST', key: 'cgst' },
  { label: 'SGST', key: 'sgst' },
  { label: 'IGST', key: 'igst' },
  { label: 'TDS', key: 'tds_amt' },
  { label: 'Selling Price', key: 'selprice' },
  { label: 'Profit', key: 'profit' },
  { label: 'Supplier Fee', key: 'supfee' },
  { label: 'UDID Fields', key: 'udid_fields' },
  { label: 'Invoice Number', key: 'invoicenumber' },
  { label: 'Airline Name', key: 'markting_airline' },
  { label: 'Staff Name', key: 'agentnm' },
  { label: 'EMD Number', key: 'emd_number' }]
} else{
  var exportData= [
  { label: 'Selling Price', key: 'selprice' },
  { label: 'Profit', key: 'profit' },
  { label: 'Supplier Fee', key: 'supfee' },
  { label: 'UDID Fields', key: 'udid_fields' },
  { label: 'Invoice Number', key: 'invoicenumber' },
  { label: 'Airline Name', key: 'markting_airline' },
  { label: 'Staff Name', key: 'agentnm' },
  { label: 'EMD Number', key: 'emd_number' }]
}
csvHeaders.push(...exportData)
}
if(state.product == "Hotel"){
if (state.userType == "att_user") {
  var hotelcolumns = [
      {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
      },
      {
          dataField: 'Branch',
          text: 'Branch',
          sort: false,
          filter: ""
      },
      {
        dataField: 'currency',
        text: 'Currency',
       sort: false,
        filter: ""
      },
      {
          dataField: 'doc_date',
          text: 'Document Date',
          sort: false,
          filter: ""
      },
      {
        dataField: 'hotel_nm',
        text: 'Hotel Name',
        sort: false,
        filter: ""
      },
      {
          dataField: 'supplier_no',
          text: 'Supplier Conf #',
          sort: false,
          filter: ""
      },
      {
          dataField: 'hcno',
          text: 'Hotel Conf #',
          sort: false,
          filter: ""
      },
      {
          dataField: 'c_center',
          text: 'Cost center',
          sort: false,
          filter: ""
      },
      {
          dataField: 'bkr_no',
          text: 'BKR No.',
          sort: false,
          filter: ""
      },
      {
          dataField: 'dcmntno',
          text: 'Document No.',
          sort: false,
          filter: ""
      },
      {
          dataField: 'supplier',
          text: 'Supplier',
          sort: false,
          filter: ""
      },
      {
          dataField: 'saleschnl',
          text: 'Sales Channel',
          sort: false,
          filter: ""
      },
      {
          dataField: 'servicetype',
          text: 'Type of Services',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custcode',
          text: 'Customer Code',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custnm',
          text: 'Customer Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custgrp',
          text: 'Customer Group',
          sort: false,
          filter: ""
      },
      {
          dataField: 'agent_name',
          text: 'Agent Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ticketno',
          text: 'Ticket Number',
          sort: false,
          filter: ""
      },
      {
          dataField: 'pass_name',
          text: 'Passenger Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ptype',
          text: 'Pax Type',
          sort: false,
          filter: ""
      },
      {
          dataField: 'nopax',
          text: 'No of PAX',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ticketst',
          text: 'Ticket Status',
          sort: false,
          filter: ""
      },
      {
          dataField: 'destination',
          text: 'Destination',
          sort: false,
          filter: ""
      },
      {
          dataField: 'country',
          text: 'Country',
          sort: false,
          filter: ""
      },
      {
          dataField: 'non',
          text: 'No. Of Nights',
          sort: false,
          filter: ""
      },
      {
          dataField: 'star_rate',
          text: 'Star Rating',
          sort: false,
          filter: ""
      },
      {
          dataField: 'pub_fare',
          text: 'Published Fare',
          sort: false,
          filter: ""
      },
      {
          dataField: 'mkt_fare',
          text: 'Market Fare(Base Fare)',
          sort: false,
          filter: ""
      },
      {
          dataField: 'tax',
          text: 'Tax',
          sort: false,
          filter: ""
      },
      {
          dataField: 'svc_fee',
          text: 'Service Fee',
          sort: false,
          filter: ""
      },
      {
          dataField: 'cost',
          text: 'Cost',
          sort: false,
          filter: ""
      },
      {
          dataField: 'markup',
          text: 'Mark Up',
          sort: false,
          filter: ""
      },
      {
          dataField: 'disc',
          text: 'Discount Given',
          sort: false,
          filter: ""
      },
      {
          dataField: 'cccharge',
          text: 'CC Charge',
          sort: false,
          filter: ""
      },
  ];
  if (context.logindata.region_type=="India"){
   var columData= [
    {
      dataField: 'cgst',
      text: 'CGST',
     sort: false,
      filter: ""
    },
    {
      dataField: 'sgst',
      text: 'SGST',
     sort: false,
      filter: ""
    },
    {
      dataField: 'igst',
      text: 'IGST',
     sort: false,
      filter: ""
    },
    {
        dataField: 'tds',
        text: 'TDS',
        sort: false,
        filter: ""
    },
    {
        dataField: 'selprice',
        text: 'Selling Price',
        sort: false,
        filter: ""
    },
    {
        dataField: 'profit',
        text: 'Profit',
        sort: false,
        filter: ""
    },
    {
        dataField: 'sp_req',
        text: 'Special Request',
        sort: false,
        filter: ""
    },
    {
        dataField: 'agent_name',
        text: 'Staff Name',
        sort: false,
        filter: ""
    }
   ]
   hotelcolumns.push(...columData)
  } else{
   var columData= [{
      dataField: 'selprice',
      text: 'Selling Price',
      sort: false,
      filter: ""
  },
  {
      dataField: 'profit',
      text: 'Profit',
      sort: false,
      filter: ""
  },
  {
      dataField: 'sp_req',
      text: 'Special Request',
      sort: false,
      filter: ""
  },
  {
      dataField: 'agent_name',
      text: 'Staff Name',
      sort: false,
      filter: ""
  }
 ]
 hotelcolumns.push(...columData)
  }
}
else {
  var hotelcolumns = [
      {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
        dataField: 'Branch',
        text: 'Branch',
        sort: false,
        filter: ""
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: ""
    },
    {
          dataField: 'doc_date',
          text: 'Document Date gg',
          sort: false,
          filter: ""
      },
      {
        dataField: 'hotel_nm',
        text: 'Hotel Name',
        sort: false,
        filter: ""
      },
      {
          dataField: 'supplier_no',
          text: 'Supplier Conf #',
          sort: false,
          filter: ""
      },
      {
          dataField: 'hcno',
          text: 'Hotel Conf #',
          sort: false,
          filter: ""
      },
      {
          dataField: 'c_center',
          text: 'Cost center',
          sort: false,
          filter: ""
      },
      {
          dataField: 'bkr_no',
          text: 'BKR No.',
          sort: false,
          filter: ""
      },
      {
          dataField: 'dcmntno',
          text: 'Document No.',
          sort: false,
          filter: ""
      },
      // {
      //     dataField: 'supplier',
      //     text: 'Supplier',
      //     sort: false,
      //     filter: ""
      // },
      {
          dataField: 'saleschnl',
          text: 'Sales Channel',
          sort: false,
          filter: ""
      },
      {
          dataField: 'servicetype',
          text: 'Type of Services',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custcode',
          text: 'Customer Code',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custnm',
          text: 'Customer Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'custgrp',
          text: 'Customer Group',
          sort: false,
          filter: ""
      },
      {
          dataField: 'agent_name',
          text: 'Agent Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ticketno',
          text: 'Ticket Number',
          sort: false,
          filter: ""
      },
      {
          dataField: 'pass_name',
          text: 'Passenger Name',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ptype',
          text: 'Pax Type',
          sort: false,
          filter: ""
      },
      {
          dataField: 'nopax',
          text: 'No of PAX',
          sort: false,
          filter: ""
      },
      {
          dataField: 'ticketst',
          text: 'Ticket Status',
          sort: false,
          filter: ""
      },
      {
          dataField: 'destination',
          text: 'Destination',
          sort: false,
          filter: ""
      },
      {
          dataField: 'country',
          text: 'Country',
          sort: false,
          filter: ""
      },
      {
          dataField: 'non',
          text: 'No. Of Nights',
          sort: false,
          filter: ""
      },
      {
          dataField: 'star_rate',
          text: 'Star Rating',
          sort: false,
          filter: ""
      },
      {
          dataField: 'pub_fare',
          text: 'Published Fare',
          sort: false,
          filter: ""
      },
      {
          dataField: 'mkt_fare',
          text: 'Market Fare(Base Fare)',
          sort: false,
          filter: ""
      },
      {
          dataField: 'tax',
          text: 'Tax',
          sort: false,
          filter: ""
      },
      {
          dataField: 'svc_fee',
          text: 'Service Fee',
          sort: false,
          filter: ""
      },
      {
          dataField: 'cost',
          text: 'Cost',
          sort: false,
          filter: ""
      },
      {
          dataField: 'markup',
          text: 'Mark Up',
          sort: false,
          filter: ""
      },
      {
          dataField: 'disc',
          text: 'Discount Given',
          sort: false,
          filter: ""
      },
      {
          dataField: 'cccharge',
          text: 'CC Charge',
          sort: false,
          filter: ""
      },
  ];
  if(context.logindata.region_type=="India"){
    var columData=[
      {
        dataField: 'cgst',
        text: 'CGST',
       sort: false,
        filter: ""
      },
      {
        dataField: 'sgst',
        text: 'SGST',
       sort: false,
        filter: ""
      },
      {
        dataField: 'igst',
        text: 'IGST',
       sort: false,
        filter: ""
      },
      {
        dataField: 'tds',
        text: 'TDS',
        sort: false,
        filter: ""
    },
    {
        dataField: 'selprice',
        text: 'Selling Price',
        sort: false,
        filter: ""
    },
    {
        dataField: 'profit',
        text: 'Profit',
        sort: false,
        filter: ""
    },
    {
        dataField: 'sp_req',
        text: 'Special Request',
        sort: false,
        filter: ""
    },
    {
        dataField: 'agent_name',
        text: 'Staff Name',
        sort: false,
        filter: ""
    }
    ]
    hotelcolumns.push(...columData)
  } else {
   var columData= [
    {
        dataField: 'selprice',
        text: 'Selling Price',
        sort: false,
        filter: ""
    },
    {
        dataField: 'profit',
        text: 'Profit',
        sort: false,
        filter: ""
    },
    {
        dataField: 'sp_req',
        text: 'Special Request',
        sort: false,
        filter: ""
    },
    {
        dataField: 'agent_name',
        text: 'Staff Name',
        sort: false,
        filter: ""
    }
    ]
    hotelcolumns.push(...columData)
  }

}

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];
if (state.userType == "att_user") {
  var hotelcsvHeaders = [
      { label: 'Region Type', key: 'region_type' },
      { label: 'Currency', key: 'currency' },
      { label: 'Document Date', key: 'doc_date' },
      { label: 'Hotel Name', key: 'hotel_nm' },
      { label: 'Supplier Conf #', key: 'supplier_no' },
      { label: 'Hotel Conf #', key: 'hcno' },
      { label: 'Branch', key: 'Branch' },
      { label: 'Cost center', key: 'c_center' },
      { label: 'BKR No.', key: 'bkr_no' },
      { label: 'Document No', key: 'dcmntno' },
      { label: 'Supplier', key: 'supplier' },
      { label: 'Sales Channel', key: 'saleschnl' },
      { label: 'Type of Service', key: 'servicetype' },
      { label: 'Customer Code', key: 'custcode' },
      { label: 'Customer Name', key: 'custnm' },
      { label: 'Customer Group', key: 'custgrp' },
      { label: 'Agent Name', key: 'agent_name' },
      { label: 'Ticket Number', key: 'ticketno' },
      { label: 'Passenger Name', key: 'pass_name' },
      { label: 'Pax Type', key: 'ptype' },
      { label: 'No of Pax', key: 'nopax' },
      { label: 'Ticket Status', key: 'ticketst' },
      { label: 'Destination', key: 'destination' },
      { label: 'Country', key: 'country' },
      { label: 'No. Of Nights', key: 'non' },
      { label: 'Star Rating', key: 'star_rate' },
      { label: 'Published Fare', key: 'pub_fare' },
      { label: 'Market Fare(Base Fare)', key: 'mkt_fare' },
      { label: 'Tax', key: 'tax' },
      { label: 'Service Fee', key: 'svc_fee' },
      { label: 'Cost', key: 'cost' },
      { label: 'Markup', key: 'markup' },
      { label: 'Discount Given', key: 'disc' },
      { label: 'CC Charge', key: 'cccharge' },
  ]
  if (context.logindata.region_type=="India"){
   var exprtData = [
      { label: 'CGST', key: 'cgst' },
      { label: 'SGST', key: 'sgst' },
      { label: 'IGST', key: 'igst' },
      { label: 'TDS', key: 'tds' },
      { label: 'Selling Price', key: 'selprice' },
      { label: 'Profit', key: 'profit' },
      { label: 'Special Request', key: 'sp_req' },
      { label: 'Staff Name', key: 'agent_name' }
    ]
    hotelcsvHeaders.push(...exprtData)
  } else{
   var exprtHotelData= [
      { label: 'Selling Price', key: 'selprice' },
      { label: 'Profit', key: 'profit' },
      { label: 'Special Request', key: 'sp_req' },
      { label: 'Staff Name', key: 'agent_name' }
    ]
    hotelcsvHeaders.push(...exprtHotelData)
  }
}
else {
  var hotelcsvHeaders = [
      { label: 'Region Type', key: 'region_type' },
      { label: 'Currency', key: 'currency' },
      { label: 'Document Date', key: 'doc_date' },
      { label: 'Hotel Name', key: 'hotel_nm' },
      { label: 'Supplier Conf #', key: 'supplier_no' },
      { label: 'Hotel Conf #', key: 'hcno' },
      { label: 'Branch', key: 'Branch' },
      { label: 'Cost center', key: 'c_center' },
      { label: 'BKR No.', key: 'bkr_no' },
      { label: 'Document No', key: 'dcmntno' },
      // { label: 'Supplier', key: 'supplier' },
      { label: 'Sales Channel', key: 'saleschnl' },
      { label: 'Type of Service', key: 'servicetype' },
      { label: 'Customer Code', key: 'custcode' },
      { label: 'Customer Name', key: 'custnm' },
      { label: 'Customer Group', key: 'custgrp' },
      { label: 'Agent Name', key: 'agent_name' },
      { label: 'Ticket Number', key: 'ticketno' },
      { label: 'Passenger Name', key: 'pass_name' },
      { label: 'Pax Type', key: 'ptype' },
      { label: 'No of Pax', key: 'nopax' },
      { label: 'Ticket Status', key: 'ticketst' },
      { label: 'Destination', key: 'destination' },
      { label: 'Country', key: 'country' },
      { label: 'No. Of Nights', key: 'non' },
      { label: 'Star Rating', key: 'star_rate' },
      { label: 'Published Fare', key: 'pub_fare' },
      { label: 'Market Fare(Base Fare)', key: 'mkt_fare' },
      { label: 'Tax', key: 'tax' },
      { label: 'Service Fee', key: 'svc_fee' },
      { label: 'Cost', key: 'cost' },
      { label: 'Markup', key: 'markup' },
      { label: 'Discount Given', key: 'disc' },
      { label: 'CC Charge', key: 'cccharge' },
  ];
  if (context.logindata.region_type=="India"){
    var exprtData = [
       { label: 'CGST', key: 'cgst' },
       { label: 'SGST', key: 'sgst' },
       { label: 'IGST', key: 'igst' },
       { label: 'TDS', key: 'tds' },
       { label: 'Selling Price', key: 'selprice' },
       { label: 'Profit', key: 'profit' },
       { label: 'Special Request', key: 'sp_req' },
       { label: 'Staff Name', key: 'agent_name' }
     ]
     hotelcsvHeaders.push(...exprtData)
   } else{
    var exprtHotelData= [
       { label: 'Selling Price', key: 'selprice' },
       { label: 'Profit', key: 'profit' },
       { label: 'Special Request', key: 'sp_req' },
       { label: 'Staff Name', key: 'agent_name' }
     ]
     hotelcsvHeaders.push(...exprtHotelData)
   }
  }
}else if(state.product == "Insurance"){
  var columnsIns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch_name',
      text: 'Branch',
     sort: false,
      filter: ""
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: ""
    },
    {
      dataField: 'document_date',
      text: 'Document Date',
     sort: false,
      filter: ""
    },
    {
      dataField: 'booking_refno',
      text: 'BKR No',
     sort: false,
      filter: ""
    },
    {
      dataField: 'trip_type',
      text: 'Trip Type',
     sort: false,
      filter: ""
    },
    {
    dataField: 'plan_type',
    text: 'Plan Type',
    sort: false,
    filter: ""
  },
  {
    dataField: 'route',
    text: 'Route',
   sort: false,
    filter: ""
  },
  {
    dataField: 'document_no',
    text: 'Document Number',
    sort: false,
    filter: ""
  },
  {
    dataField: 'staff_name',
    text: 'Staff Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier',
    text: 'Supplier',
   sort: false,
    filter: ""
  },
  {
    dataField: 'sales_channel',
    text: 'Sales Channel',
   sort: false,
    filter: ""
  },
  {
    dataField: 'type_of_service',
    text: 'Type of Service',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_code',
    text: 'Customer Code',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_name',
    text: 'Customer Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'customer_group',
    text: 'Customer Group',
   sort: false,
    filter: ""
  },
  {
    dataField: 'policy_number',
    text: 'Policy Number',
   sort: false,
    filter: ""
  },

  {
    dataField: 'pax_name',
    text: 'Pax Name',
   sort: false,
    filter: ""
  },
  {
    dataField: 'pax_type',
    text: 'Pax Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'noof_pax',
    text: 'No of Pax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'departure_date',
    text: 'Deparure Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'arrival_date',
    text: 'Arrival Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cover_start_date',
    text: 'Cover Start Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cover_end_date',
    text: 'Cover End Date',
   sort: false,
    filter: ""
  },
  {
    dataField: 'booking_status',
    text: 'Booking Status',
   sort: false,
    filter: ""
  },
  {
    dataField: 'transaction_type',
    text: 'Transaction Type',
   sort: false,
    filter: ""
  },
  {
    dataField: 'fare',
    text: 'Fare',
   sort: false,
    filter: ""
  },
  {
    dataField: 'tax',
    text: 'Tax',
   sort: false,
    filter: ""
  },
  {
    dataField: 'covid_ext',
    text: 'Covid Extension',
   sort: false,
    filter: ""
  },
  {
    dataField: 'win_spo_ext',
    text: 'Winter Sport Extension',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cost',
    text: 'Cost',
   sort: false,
    filter: ""
  },
  {
    dataField: 'markup',
    text: 'Markup',
   sort: false,
    filter: ""
  },
  {
    dataField: 'service_fee',
    text: 'Service Fee',
   sort: false,
    filter: ""
  },
  {
    dataField: 'disc',
    text: 'Discount',
   sort: false,
    filter: ""
  },
  {
    dataField: 'cc_charges',
    text: 'CC Charges',
   sort: false,
    filter: ""
  },
  {
    dataField: 'selling_price',
    text: 'Selling Price',
   sort: false,
    filter: ""
  },
  {
    dataField: 'profit',
    text: 'Profit',
   sort: false,
    filter: ""
  },
  {
    dataField: 'supplier_fee',
    text: 'Supplier Fee',
   sort: false,
    filter: ""
  }

];
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  var insureanceHeader = [
    { label: 'Region Type', key: 'region_type' },
    { label: 'Currency', key: 'currency' },
    { label: 'Document Date', key: 'document_date' },
    { label: 'Booking Ref No', key: 'booking_refno' },
    { label: 'Trip Type', key: 'trip_type' },
    { label: 'Plan Type', key: 'plan_type' },
    { label: 'Branch', key: 'branch_name' },
    { label: 'Document No', key: 'document_no' },
    { label: 'Supplier', key: 'supplier' },
    { label: 'Staff Naame', key: 'staff_name' },
    { label: 'Sales Channel', key: 'sales_channel' },
    { label: 'Type of Service', key: 'type_of_service' },
    { label: 'Customer Code', key: 'customer_code' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Customer Group', key: 'customer_group' },
    { label: 'Policy Number', key: 'policy_number' },
    { label: 'Pax Name', key: 'pax_name' },
    { label: 'Pax Type', key: 'pax_type' },
    { label: 'No of Pax', key: 'noof_pax' },
    { label: 'Departure Date', key: 'departure_date' },
    { label: 'Arrival Date', key: 'arrival_date' },
    { label: 'Cov Start Date', key: 'cover_start_date' },
    { label: 'Cov End Date', key: 'cover_end_date' },
    { label: 'Route', key: 'route' },
    { label: 'Booking Status', key: 'booking_status' },
    { label: 'Transaction Type', key: 'transaction_type' },
    { label: 'Fare', key: 'fare' },
    { label: 'Tax', key: 'tax' },
    { label: 'Covid Ext Fare', key: 'covid_ext' },
    { label: 'Winter Sport Fare', key: 'win_spo_ext' },
    { label: 'Service Fee', key: 'service_fee' },
    { label: 'Cost', key: 'cost' },
    { label: 'Markup', key: 'markup' },
    { label: 'Disc', key: 'disc' },
    { label: 'CC Charge', key: 'cc_charges' },
    { label: 'Selling Price', key: 'selling_price' },
    { label: 'Profit', key: 'profit' },
    { label: 'Supplier Fee', key: 'supplier_fee' }
    ];
}
const paxDate = (dt) => {
  // if(toDate){
    let oneYearDate= new Date(toDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() -1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
//   }else{
//   return new Date(dt.setFullYear(dt.getFullYear()));
//  }
  // return new Date(dt.setFullYear(dt.getFullYear()));
}
const returnDate = ()=>{
  // if(fromDate){
  //   let beforecurrentDate = paxDate(new Date()).setDate(new Date().getDate() - 30)
  //   let fromdateadded = paxDate(fromDate).setDate(fromDate.getDate())
  //   if(fromdateadded < beforecurrentDate ){
      let oneYearDate= new Date(fromDate);
      oneYearDate.setFullYear(oneYearDate.getFullYear() + 1);
      let today= new Date()
   let test= (today > oneYearDate ? oneYearDate : today) 
   return test
  //  let test= paxDate(fromDate).setDate(fromDate.getDate() + 30)
  //  return test
  //   }
  //  else{
  //    return new Date()
  //  }
  // }
  // else{
  //   return new Date()
  // }
}
  return (

    <>
     <ReportMenu active='My Reports' />
      <Breadcrumb activePage="My Reports" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <MyreportMenu active="MyReport DSR" />
      <div>
        <br/>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={products}
                searchable={false}
                values={state.productData}
                onChange={(value) => (setProduct(value))}
              />
              <Form.Label>Product<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={3}>
            <Form.Row>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}
                    fromDate={fromDate}
                    toDate={toDate}
                    maxDate={ toDate ? toDate : new Date()}
                    minDate={toDate && paxDate(toDate).setDate(toDate.getDate()) }
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>From Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate && fromDate}
                    maxDate= {returnDate()}
                    className="datefield"
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>To Date<sup>*</sup></Form.Label>
                </Form.Group>
            </Form.Row>
          </Form.Group>
        </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
        <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={handleSearch}
        > Search</Button>
      </div>

      <h4 class="subTitle plusHr mt-3 mb-3">My Report {state.productSelected && "  ("} {state.productSelected} { state.productSelected &&")"}</h4>
       {state.product!=="Insurance" && dsrExceldata.dsrReportExcelList!==undefined && dsrExceldata.dsrReportExcelList.length !== 0 &&
        <CoomonExportExcel data={dsrExceldata.dsrReportExcelList} headers={state.product == "Flight" ? csvHeaders : hotelcsvHeaders} show={true} name={"DSR_Report.csv"}/>
       }
       {state.product==="Insurance" && dsrExceldata.dsrReportExcelList!==undefined && dsrExceldata.dsrReportExcelList.length !== 0 &&
        <CoomonExportExcel data={dsrExceldata.dsrReportExcelList} headers={insureanceHeader} show={true} name={"DSR_Report.csv"}/>
       }
      <div className="cardMainlayout tableRes p-0">
      {isLoadingMore ? <InnerLoader /> : (<>
        {dsrdata.dsrReportList.length !== 0 &&
        <div className="tablescroll">
          <BootstrapTable
          bootstrap4
          keyField="id"
          classes="markup filterTbl myReports"
          data={dsrdata.dsrReportList}
          columns={(state.product == "Flight" && columns) || (state.product == "Hotel" && hotelcolumns) || (state.product == "Insurance" && columnsIns) }
          defaultSorted={defaultSorted }
          filter={filterFactory()}
          pagination={dsrdata.dsrReportList.length >10 && dsrdata.dsrReportList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
          dsrdata.dsrReportList.length >25 && dsrdata.dsrReportList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
          dsrdata.dsrReportList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
          />
          {state.totals !== "" && state.productSelected == "Flight" && <div className="cardMainlayout finalTotal">
   <div className="react-bootstrap-table">
   <table className="table table-bordered markup filterTbl myReports">

       <thead>
       <tr>
                <th>&nbsp;</th>
                <th>Published Fare</th>
                <th>Base Fare	</th>
                <th>Tax</th>
                <th>Service Fee	</th>
                <th>Cost</th>
                <th>Markup</th>
                <th>Baggage Fare</th>
                <th>Meal Fare</th>
                <th>Seat Fare</th>
                <th>Discount</th>
                <th>CC Charges</th>
                <th>PLB</th>
                <th>Penalty</th>
                {context.logindata.region_type=="India"&&
                <th>CGST	</th>}
                {context.logindata.region_type=="India"&&
                <th>SGST	</th>}
                {context.logindata.region_type=="India"&&
                <th>IGST	</th>}
                {context.logindata.region_type=="India"&&
                <th>TDS	</th>}
                <th>Selling Price	</th>
                <th>Profit</th>
                <th>Supplier Fee	</th>
                </tr>
       </thead>

            <tr>
            <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_publishfare.toFixed(2)}</td>
              <td>{state.totals.tot_basefare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_servicefee.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_bag_fare.toFixed(2)}</td>
              <td>{state.totals.tot_meal_fare.toFixed(2)}</td>
              <td>{state.totals.tot_seat_fare.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_cccharge !== null && state.totals.tot_cccharge.toFixed(2)}</td>
              <td>{state.totals.tot_plb.toFixed(2)}</td>
              <td>{state.totals.tot_penalty.toFixed(2)}</td>
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_cgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_sgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_igst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_tds.toFixed(2)}</td>}
              <td>{state.totals.tot_selprice.toFixed(2)}</td>
              <td>{state.totals.tot_profit.toFixed(2)}</td>
              <td>{state.totals.tot_supfee.toFixed(2)}</td>
            </tr>
            </table>
          </div></div>}
          {state.totals !== "" && state.productSelected == "Hotel" && <div className="cardMainlayout finalTotal"><div className="react-bootstrap-table">

          <table className="table table-bordered markup filterTbl">
          <thead>
                    <tr>
                  <th>&nbsp;</th>
                  <th>Published Fare</th>
                  <th>Market Fare(Base Fare)</th>
                  <th>Tax</th>
                  <th>Service Fee</th>
                  <th>Cost</th>
                  <th>Mark Up</th>
                  <th>Discount Given</th>
                  <th>CC Charge</th>
                  {context.logindata.region_type=="India"&&
                  <th>CGST	</th>}
                {context.logindata.region_type=="India"&&
                  <th>SGST	</th>}
                {context.logindata.region_type=="India"&&
                  <th>IGST	</th>}
                {context.logindata.region_type=="India"&&
                  <th>TDS	</th>}                  
                  <th>Selling Price</th>
                  <th>Profit</th>

              </tr>
              </thead>
            <tr>
            <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_pub_fare.toFixed(2)}</td>
              <td>{state.totals.tot_mkt_fare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_svc_fee.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_cccharge.toFixed(2)}</td>
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_cgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_sgst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_igst.toFixed(2)}</td>}
              {context.logindata.region_type=="India"&&
              <td>{state.totals.tot_tds.toFixed(2)}</td>}              
              <td>{state.totals.tot_selprice !== null && state.totals.tot_selprice.toFixed(2)}</td>
              <td>{ state.totals.tot_profit !== null && state.totals.tot_profit.toFixed(2)}</td>
            </tr></table>
          </div></div>}
          {state.totals !== "" && state.productSelected == "Insurance" && <div className="cardMainlayout finalTotal insurTotal"><div className="react-bootstrap-table">
          <table className="table table-bordered markup filterTbl">
          <thead>
              <tr>
                   <th>&nbsp;</th>
                <th>Fare	</th>
                <th>Tax</th>
                <th>Covid Extension	</th>
                <th>Winter Sport Extension</th>
                <th>Cost</th>
                <th>Markup</th>
                <th>Service Fee</th>
                <th>Discount</th>
                <th>CC Charges</th>
                <th>Selling Price	</th>
                <th>Profit</th>
                <th>Supplier Fee	</th>
                </tr>
                </thead>
            <tr>
            <td><span className="totalRec">Total Records:</span></td>
              <td>{state.totals.tot_fare.toFixed(2)}</td>
              <td>{state.totals.tot_tax.toFixed(2)}</td>
              <td>{state.totals.tot_covid_ext.toFixed(2)}</td>
              <td>{state.totals.tot_win_spo_ext.toFixed(2)}</td>
              <td>{state.totals.tot_cost.toFixed(2)}</td>
              <td>{state.totals.tot_markup.toFixed(2)}</td>
              <td>{state.totals.tot_servicefee.toFixed(2)}</td>
              <td>{state.totals.tot_disc.toFixed(2)}</td>
              <td>{state.totals.tot_ccharge.toFixed(2)}</td>
              <td>{state.totals.tot_selling_price.toFixed(2)}</td>
              <td>{state.totals.tot_profit.toFixed(2)}</td>
              <td>{state.totals.tot_supplier_fee.toFixed(2)}</td>

            </tr>
            </table>
          </div></div>}
        </div>
          || dsrdata.dsrReportList.length === 0
          && <NoRecord />}</>)}
      </div>

              </div>
              </div>
<Footer/>
    </>
  )
}
export default DSRReports