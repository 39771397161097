
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

// Create styles
const styles = StyleSheet.create({
  page: { marginTop: '0', height: '100%', padding: '30px', paddingTop: '20px' },
  main: {
    width: '100%',
    height: '140mm',
    marginBottom: '15px',
    fontFamily: 'Roboto',
    backgroundColor: '#fff',

    margin: '10 0 ',


  },

  table: { margin: '0 0 10px', border: '1 solid #ccc', borderRadius: '4px', padding: '5px 10px 10px' },
  mainSec: {

    fontFamily: 'Roboto',
    width: '100%',


  },
  img: {
    width: 60,
    height: '24',
    padding: '1',
  },
  hotelImages: {
    width: 80,
    height: 80,
    borderRadius: '10px',
  },
  sta: {
    width: '10px',
    height: '10px',
  },
  mapIcon: {
    width: '8px',
    marginRight: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10',
    fontFamily: 'Roboto',
  },
  heading: {
    fontSize: '20px',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  hedingunderline: {
    textDecoration: 'underline',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '14px',
    color: '#475F7B',
    textAlign: 'center',
    marginBottom: '10px',
  },
  tableIn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    display: 'flex',
    justifyContent: 'top',
    paddingRight: '15px',
  },
  companyName: {
    fontSize: '12px'
  },
  lablList: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    flexWrap:'wrap',
  },
  lablListPlus: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    width: '400px'
  },
  tid: {
    color: '#475F7B',
  },
  confStatus: {
    color: '#1DAD81',
  },
  Secheading: {
    fontSize: '12px',
    padding: '4px 0px',
    margin: '0 0 5px',
  },
  hotelPart: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px,'
  },
  hotelImage: {
    marginRight: '10px',
    width: '160px'
  },
  hotelName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px'
  },
  maneHtl: {
    fontSize: '12px',
    marginRight: '10px',
  },
  hotelAdres: {
    display: 'flex',
    flexDirection: 'row',
  },
  adress: {
    color: '#888888',
    fontSize: '10px',
  },
  infolist: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  time: {
    color: '#F26F22',
  },
  roomType: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: '6px',
  },
  tableHeading: {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
    color: '#00367A',
    fontSize: '11px',
  },
  paxtlbl: {
    width: '25%',
  },
  tableData: {
    padding: '6px 0 4px',
    display: 'flex',
    flexDirection: 'row',
    color: '#888',
    fontSize: '10px',
  },
  status: {
    color: '#1DAD81 ',
  },
  SpecialRequest: {
    border: '1px dashed #CACED5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
  },
  note: {
    border: '1px dashed #CED7E5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
    backgroundColor: '#F3F4F8',
  },
  spCont: {
    color: '#888',
    fontSize: '10px',
    marginTop: '4px',
    lineHeight: '1.5px',
  },
  noteTitl: {
    color: '#00367A',
    fontWeight: 'bold',
  },
  dueAtLay: {
    fontSize: '10px',
    fontWeight: 'normal',
    color: '#888',
    margin: '10px 0',
  },
  maplayout: {
    width: '1000px',
  },
  copy: {
    textAlign: 'right',
    fontSize: '8px',
    marginTop: '10px',
    fontStyle: 'italic',
    color: '#00367A',
  },
  strongText: {
    fontSize: '12px',
  },
  bookinglablList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: '1px solid #ccc',
    marginTop: '10px',
  },
  col3: {
    width: '100%',
    maxWidth: '33%',
  },
  iItineraryName:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'10px',
    backgroundColor:'#f3f3f3',
    padding:'6px',
    borderRadius:'6px'
  },
  iItineraryNameInfo:{
    display:'flex',
  },
  ref:{
    paddingLeft:'120px',
    display:'flex',
    color:'#475F7B',
  },
  refID:{
    color:'#888888',
  },
  flightLoop:{
    padding:'0 10px 10px',
    borderBottom:'1px solid #ccc',
  },
  flightSec:{
    border:'1px solid #ccc',
    borderRadius:'6px',
    overflow:'hidden',
    marginBottom:'10px',
  },
  flightLogo:{
    width:'20px',
  },
  flightSegPart:{
    display:'flex',
    flexDirection:'row',
  },
  flightSecOne:{
    display:'flex',
    flexDirection:'row',
    paddingRight:'20px',
  },
  flightNameNo:{
    display:'flex',
    flexDirection:'column',
    fontSize:'10px',
    paddingLeft:'5px',
  },
  flightName:{
    color:'#475F7B',
    paddingBottom:'4px',
  },
  flightNo:{
    color:'#888888',
  },
  flightSecTwo:{
    fontSize:'10px',
    color:'#475F7B',
  },
  time:{
    fontSize:'14px',
    color:'#475F7B',
    fontWeight:'bold',
  },
  date:{
    fontSize:'10px',
  },
  city:{
    paddingTop:'4px',
  },
  terminal:{
    fontSize:'8px',
  },
  layOvr:{
    display:'flex',
    flexDirection:'column',
    fontSize:'10px',
    alignItems:'center',
    color:'#475F7B',
    padding:'0 20px',
  },
  flightIcon:{
    padding:'4px',
  },
  dept:{
    width:'15px',
    marginBottom:'4px',
  },
  tableRow:{
    borderBottom:'1px solid #ccc'
  },
  paxlayout:{
    padding:'0 10px',
  },
});

function PaxDetails(props) {
  /**
     * @description:This function will Display the the Segment details
     * @author: Rambabu
     * @date : 02-10-2020
     * @param {*} code
     * @param {*} val
     */
  return (
           <View style={styles.paxlayout}>
            <View>
              <Text style={styles.Secheading}>{props.flowType} Details</Text>
            </View>
            <View style={styles.tableHeading}>
              <Text style={styles.paxtlbl}>Traveller </Text>
              <Text style={styles.paxtlbl}>Type </Text>
              {/* <Text style={styles.headingTaxt}>Sectors</Text> */}
              <Text style={styles.paxtlbl}>Ticket#</Text>
              <Text style={styles.paxtlbl}>Status</Text>
            </View>
            {props.paxList.map((pax, index) => (
              <View wrap={false} key={index} >
                <View style={styles.tableRow} >
                  <View style={styles.roomType}>
                    <View style={styles.tableData}>
                      <Text style={styles.paxtlbl}>{pax.pax_name} </Text>
                      <Text style={styles.paxtlbl}>
                        {pax.pax_type === "ADT" &&
                          <Text style={styles.spTitl}>Adult</Text>
                        }
                        {pax.pax_type === "CHD" &&
                          <Text style={styles.spTitl}>Child</Text>
                        }
                        {pax.pax_type === "INF" &&
                          <Text style={styles.spTitl}>Infant</Text>
                        }
                      </Text>
                      {/* <Text style={styles.headingTaxt}>
                        {pax.trip_type !== "2" &&
                          <Text style={styles.spTitl}>{pax.source}-{pax.destination}</Text>
                        }
                        {pax.trip_type === "2" &&
                          <Text style={styles.spTitl}>
                            {pax.source}-{pax.destination} :
                            {pax.destination}-{pax.source}</Text>
                        }
                      </Text> */}
                      <Text style={styles.paxtlbl}>{pax.tkt_no}</Text>
                      <Text style={styles.paxtlbl}><Text style={styles.status}>{pax.sts}</Text></Text>
                    </View>
                  </View>
                </View>
              </View>
            ))}
       </View>
);
}


export default PaxDetails;
