import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
//const API_URL = "http://localhost:3005"

/**
 * @description: Integrated the Credit Management services
 * @author: Rambabu
 * @date:09-09-2020
 * @param {*} req 
 */
class AirLineCommissionService {

    /**
     * @description: Save Commission services
     * @author: Rambabu
     * @date:16-09-2020
     * @param {*} req 
     */
    async saveCommission(req) {
        console.log('save airline commission data is----' + JSON.stringify(req))
        return await axios.post(`${API_URL}/att/commission/create`, req)
    }

    /**
     * @description: Fetch All commission Data
     * @author: Rambabu
     * @date:16-09-2020
     * @param {*} req 
     */
    async getCommissionData() {
        return await axios.get(`${API_URL}/att/commission/retrive`);
    }


    /**
     * @description: Fetch Resepective commission id commission Data
     * @author: Rambabu
     * @date:21-09-2020
     * @param {*} id 
     */
    async getComissionDetails(id) {

        return await axios.get(`${API_URL}/att/commission/edit/${id}`);

    }

    /**
     * @description: Delete Applicable Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id 
     */
    async deleteapplicableChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteApto/${id}`)
    }

    /**
     * @description: Delete Child Data(commission_route)
     * @author: Rambabu
     * @date:22-08-2020 
     * @param {*} req        
     */
    async deleteCommissionRoutesData(req) {
        console.log('deleteOfficeidRoutesData----' + JSON.stringify(req));
        return await axios({ method: "DELETE", url: `${API_URL}/att/commission/deleteRoutes`, data: req });
    }

    /**
     * @description: Delete Class(RBD) Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id 
     */
    async deleteCommissionClassChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteClass/${id}`)
    }

    /**
     * @description: Delete Office id Child data
     * @author: Rambabu
     * @date:22-09-2020
     * @param {*} id 
     */
    async deleteCommissionOfficeidChildData(id) {
        return await axios.delete(`${API_URL}/att/commission/deleteOffice/${id}`)
    }


    /**
     * @description: Update airline commission
     * @author: Rambabu
     * @date:2409-2020
     * @param {*} data 
     */
    async updateCommissionData(data) {
        console.log("updateCommissionData" + JSON.stringify(data));
        return await axios.post(`${API_URL}/att/commission/update`, data)
    }

    /**
     * @description: Update airline commission
     * @author: Rambabu
     * @date:2409-2020
     * @param {*} data 
     */
    async searchCommission(data) {
        return await axios.post(`${API_URL}/att/commission/search`, data)
    }


}

export default new AirLineCommissionService()