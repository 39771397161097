import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Paymentprocessingimg from '../../../assets/images/Search.gif'

/**
 * @description:This function will work for Result Not Found pop Up.
 * @author: Pravas
 * @param {*}
 * @function ResultsNotFound
 * @date : 01-09-2020
 */
function ResultsNotFound(props) {

  return (
    <>
          <div className="layover layout">
            <div className="popUpBox resultNotFound">
            <div className="backGroundgif">
              <span className='closebtnResultnot'>X</span>
<span className='backGroundbot'></span>
<img src={Paymentprocessingimg} />
            </div>

            {/* <div className={props.filterChange? 'icons notFound filter':'icons notFound'}></div> */}
             {props.product==="Flight" && <strong>Sorry No Results Found!</strong> }
             {props.product==="Hotel" && <strong>There are no rooms available within the date range you specified.</strong> }
              {(props.filterChange == undefined || !props.filterChange) &&
              <>
              {props.product==="Flight" && <p className="m-0">We couldn't find what you are looking for</p>}
              <button className="btn btn-primary btn-xs mt-2"><Link to="/ezytrip/dashboard">Back to Home</Link></button>
              </>
              }
              {props.filterChange &&
              <p> Please Reset the Filter</p>
              }
            </div>
          </div>

    </>
  )
}
export default ResultsNotFound