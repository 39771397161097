import React from 'react';
/**
 * @description:This component will work for processing popUp for void split Pnr .
 * @author: Pawan
 * @param {*}
 * @function Processing
 * @date : 14-09-2021
 */
function ProcessingVoidSPlitPNR() {

  return (
    <>
          <div className="layover zIndex999">
            <div className="popUpBox processing">
              <div className="icons process"></div>
              <strong>Please wait…</strong>
              {
                 
                  <p className="processInfo">Your request is being processed
                  </p>
                
              }

              {/* <p className="m-0">Do not refresh the page</p> */}

            </div>
          </div>

    </>
  )
}
export default ProcessingVoidSPlitPNR