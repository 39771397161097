import log from "loglevel";
import moment from "moment";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import { countriesWitCodeInsurance, mealPrefs, seatPrefs, specialPrefs, stdBookingCodes, titles } from '../../../adminstration/utils';
import ShowHide from '../../../common/showHide/ShowHide';
import DateUtils from '../../../commonUtils/DateUtils';

let nationalities = require('../../../masterData/InsNationality.json');

/**
 * @description:This function will creaet all the Passenger details and Passport info
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 26-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  chds: 0, infs: 0, doc: "Yes", doct: "P", passengers: [], stdCodeMenu: stdBookingCodes(), countriesMenu: [],genders:[], mealPrefs: mealPrefs(), spPrefs: specialPrefs(), seatPrefs: seatPrefs(), airlines: [], selTraveller: "", adtList: [], chdList: [], infList: [], enableImport: false, passportEnable: false, flowType: "", title: [], enbalePPInfo: false, docMandatory: "yes",cheked: false,
  passengerUniqueKey:[],enableFields:true,deptDate:'',depYear:0,childDeptDate:'',relationsList:[],relation:"",comp_id:"",address:"",pobox:"",city:"",nationalityList:[]
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//=====================================
// This function will create the List travellers beased on the selected Pax
//=====================================
function InsTravellers(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [resp, setResponse] = useState(props.response);
  const [adts, setAdt] = useState(props.srequest.adt)
  const [chds, setChd] = useState(props.srequest.chd)
  const [issueDate1, setIssueDate1] = useState()
  const [issueDate2, setIssueDate2] = useState()
  const [expDate1, setExpDate1] = useState()
  const [expDate2, setExpDate2] = useState()
  const [enableFiled, setEnabField] = useState(false)

  useEffect(() => {
    const genderList = [{ "value": "", "label": "Select" }, { "value": "Male", "label": "Male" }, { "value": "Female", "label": "Female" }]
    const list = genderList.map((title) =>
      <option value={title.value}>{title.label}</option>
    );
    dispatch({ type: 'genders', payload: list })
    if(props.passengerUniqueKey && props.passengerUniqueKey.length > 0) {
      state.passengerUniqueKey = props.passengerUniqueKey;
      dispatch({type:"passengerUniqueKey",payload:state.passengerUniqueKey})
    }
    createPaxList();
    const nalist = nationalities.res.map((na) =>
    <option value={na.code}>{na.country_name}</option>
     );
   dispatch({ type: 'nationalityList', payload: nalist })

    countriesWitCodeInsurance().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
    });

    const relationsList = [{ "value": "", "label": "Select" },{ "value": "001", "label": "BROTHER" }, { "value": "002", "label": "BUSINESS PARTNER" },{ "value": "003", "label": "DEPENDENT" },{ "value": "004", "label": "EMPLOYEE" },{ "value": "005", "label": "HUSBAND" },{ "value": "006", "label": "INDEPENDENT" },{ "value": "007", "label": "PARENTS" },
    { "value": "008", "label": "SELF" },{ "value": "009", "label": "SISTER" },{ "value": "010", "label": "SPOUSE" },{ "value": "011", "label": "SON" },{ "value": "012", "label": "DAUGHTER" },{ "value": "013", "label": "RELATIVE" },{ "value": "014", "label": "OTHERS" }]


    const relList = relationsList.map((com) =>
      <option value={com.value}>{com.label}</option>
    );
    dispatch({ type: 'relationsList', payload: relList })

  }, [!props.importFlow]);


  useEffect(() => {
    checkTravellerList();
  }, [props.travellersList.res]);

  useEffect(() => {
    let profilearray = props.prof;
    if (state.cheked == true && state.passengers.length > 0) {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      state.passengers[objindex].fn = profilearray.fn
      state.passengers[objindex].ln = profilearray.ln
      state.passengers[objindex].tl = profilearray.tl
      state.passengers[objindex].phc = profilearray.phc
      state.passengers[objindex].em = profilearray.em
      state.passengers[objindex].phn = profilearray.phn
      dispatch({ type: "passengers", payload: state.passengers })
    }
  }, [props.prof]);

  //=====================================
  // This function will create the ADT,CHD and INF List travellers beased on selected Profile.
  //=====================================
  const checkTravellerList = () => {
    let adultsList = []; let childsList = [];
    if (props.travellersList.res) {
      for (let p1 of props.travellersList.res) {
        if (p1.ty === "ADT" && adts > 0) {
          adultsList.push(p1);
        } else if (p1.ty === "CHD" && chds > 0) {
          childsList.push(p1);
        }
      }
      dispatch({ type: 'adtList', payload: adultsList })
      dispatch({ type: 'chdList', payload: childsList })
    }
  }

  const paxDate = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() - n));
  }
  const childMin = (dt, n)=>{
        return new Date(dt.setFullYear(dt.getFullYear() - n)).setDate(new Date().getDate()+1);
  }

  //=====================================
  // This function will create the Pax List based on selection
  // which will update the state
  //=====================================
  const createPaxList = () => {
    let paxList = [];
    var adtcount = 1;
    if (adts) {
      for (var i = 0; i < adts; i++) {
        let passKey = []
        if(state.passengerUniqueKey.length > 0) {
          passKey = state.passengerUniqueKey.filter(s => s.type == "ADT")
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : ""
        const payload = createPaxPayload("ADT", adtcount, "Adult", adtcount,uniqueKey);
        paxList.push(payload);
        adtcount = adtcount + 1;
      }
    }
    if (chds && chds > 0) {
      var chdcount = adtcount;
      var fesno = 1
      for (var i = 0; i < chds; i++) {
        let passKey = []
        if(state.passengerUniqueKey.length > 0) {
          passKey = state.passengerUniqueKey.filter(s => s.type == "CHD")
        }
        let uniqueKey = passKey && passKey.length > 0 ? passKey[i].key : ""
        const payload = createPaxPayload("CHD", chdcount, "Child", 0, fesno,uniqueKey);
        paxList.push(payload);
        chdcount = chdcount + 1;
        fesno = fesno + 1
      }
    }
    dispatch({ type: 'passengers', payload: paxList })
  }
  //=====================================
  // This is common function to create passengers
  //=====================================
  const createPaxPayload = (type, count, lable, sno,pUkey) => {
    var lead = false; var docPayload = ""; var prefPayload = ""; var ffPayload = "";
    (type === "ADT" && count === 1) ? lead = true : lead = false;
    docPayload = { ty: "P", num: "", na: "", isc: '', isd: null, exd: null };
    dispatch({ type: 'passportEnable', payload: true })
    if (type !== "INF") {
      prefPayload = { sa: "", sp: "", mp: "" };
      ffPayload = { ffnum: "", air: "" };
    }
    return {
      sno: count,passengerKey:pUkey ? pUkey : "", fesno: sno, gender: "", ty: type, plbl: lable, lead: lead, fn: "", ln: "", em: "", phc: "", phn: "", relation:lead?"SELF":"", dob: null, "doc": docPayload
    }
  }

  const showProfile = () => {
    dispatch({type: "cheked", payload: !state.cheked})
    let check = document.getElementById("pax").checked;
    let profilearray = props.prof;
    if (check == true && state.passengers.length > 0) {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      state.passengers[objindex].fn = props.fname;
      state.passengers[objindex].ln = props.lname===""?profilearray.ln:props.lname;
      state.passengers[objindex].tl = props.title===""?profilearray.tl:props.title;
      state.passengers[objindex].phc =props.code===""?profilearray.phc:props.code ;
      state.passengers[objindex].em = props.email===""?profilearray.em:props.email;
      state.passengers[objindex].phn = props.phone===""?profilearray.phn:props.phone;
      dispatch({ type: "passengers", payload: state.passengers })
    }
    else {
      let objindex = state.passengers.findIndex(obj => obj.sno == 1)
      state.passengers[objindex].fn = ""
      state.passengers[objindex].ln = ""
      state.passengers[objindex].tl = ""
      state.passengers[objindex].phc = ""
      state.passengers[objindex].em = ""
      state.passengers[objindex].phn = ""
      dispatch({ type: "passengers", payload: state.passengers })
    }
  }

  const handleOnInpChange = (txt, id, idx, type) => {
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else if (id !== "num" && id !== "na" && id !== "isc") {
        if (type !== "INF") {
          return { ...pax, [id]: txt };
        } else {
          return {
            ...pax, inf: {
              ...pax.inf, [id]: txt
            }
          }
        }
      } else {
        return {
          ...pax, doc: {
            ...pax.doc, [id]: txt
          }
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax);
  }

  // OnChange event we will call this and update the state
  const handleChange = (idx) => (e) => {
    try {
      if (e[0] === undefined) {
        let newpax = state.passengers.map((pax, sidx) => {
          const { id, value } = e.target;
          if (idx !== sidx) return pax;
          else if (id !== "num" && id !== "na" && id !== "isc") {
            return { ...pax, [id]: value };
          } else {
            return {
              ...pax, doc: {
                ...pax.doc, [id]: value
              }
            }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);
      } else {
        let newpax = state.passengers.map((pax, sidx) => {
          if (idx !== sidx) return pax;
          else if (e[0].doc !== undefined) {
            return {
              ...pax, tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", destnum: e[0].destnum,
              doc: {
                ...pax.doc, num: e[0].doc.num, na: e[0].doc.na, num: e[0].doc.num, ty: "P", isc: e[0].doc.isc, isd: e[0].doc.isd !== null ? DateUtils.convertStringToDate(e[0].doc.isd) : null, exd: e[0].doc.exd !== null ? DateUtils.convertStringToDate(e[0].doc.exd) : null
              }
            }
          } else {
            return { ...pax, tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", destnum: e[0].destnum }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);

      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }

  // OnChange event we will call this and update the state
  const handleDobChange = idx => date => {
    const convertDate = (date !== null ? DateUtils.convertStringToDate(date) : "");
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, dob: convertDate, dobOrg: date !== null ? date : null, doc: {
          ...pax.doc, isd: null, exd: null
        }
      };
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }

  // OnChange event we will call this and update the state
  const handleIssuDateChange = idx => date => {
    const convertDate = date ? DateUtils.convertStringToDate(date) : "";
    setIssueDate1(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, doc: {
          ...pax.doc, isd: convertDate
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
  // OnChange event we will call this and update the state
  const handleExpDateChange = idx => date => {
    const convertDate = date ? DateUtils.convertStringToDate(date) : "";
    setExpDate1(date);
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, doc: {
          ...pax.doc, exd: convertDate
        }
      }
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }

  useEffect(() => {
    if(props.deptDate) {
      let limit_year = moment(props.deptDate).subtract(2, 'years');
      let cops_date = new Date(limit_year)
      state.deptDate = cops_date
      dispatch({type:"deptDate",payload:state.deptDate})
    }
    if(props.deptDate) {
      let climit_year = moment(props.deptDate).subtract(12, 'years');
      let ccops_date = new Date(climit_year)
      state.childDeptDate = ccops_date
      dispatch({type:"childDeptDate",payload:state.childDeptDate})
    }
  },[props.deptDate])


  const handleChangeType = (obj) => (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    for(let pax of state.passengers){
       if(!pax.lead && pax.sno===obj.sno){
          pax.relation=value
       }
      if(pax.lead){
        pax.relation="008"
       }
    }
  };

  const handleChangeAdd = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    props.sendAddInfo(state)
}



  return (
    <>
      <div className="boxLayout">
        <ShowHide visible="true" icon="person" title="Travellers Details">
          <div className="showHide-content p-0">
          {((props.prof !== undefined && props.prof !== null && props.prof.length !== 0 )|| 
                  props.email!==""||props.fname!==""||props.lname!==""||props.phone!== ""
                  )&&          <div className="allPax flightpax"><Form.Check
              type="checkbox"
              onChange={showProfile}
              id="pax"
              label="Use Profile Details as Lead Pax"
              custom
            />
            </div>
            }
            <Alert variant="info mt-3">Note: Please ensure the name you enter is matching to your passport </Alert>
            {state.passengers.length > 0 &&
              <div className="travellerList">
                {state.passengers.map((pasenger, idx) => (
                  <>
                    <ShowHide visible={pasenger.lead ? "true" : ""} title={pasenger.plbl + " " + pasenger.sno + " " + (pasenger.lead ? "(Lead)" : "")}>
                      <div className="showHide-content pt-4">
                        <Form.Row>
                          <React.Fragment key={idx}>
                            <Form.Group as={Col} xs={3} controlId="gender" className="customSelect">
                                <Form.Control as="select" disabled={state.enableImport || props.disable} value={pasenger.gender} onChange={handleChange(idx)}>
                                  {state.genders}
                                </Form.Control>
                              <Form.Label>Gender<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} controlId="fn" className="selectplugin" xs={3}>
                              {pasenger.ty.toUpperCase() === "ADT" &&
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="fn"
                                  onChange={handleChange(idx)}
                                  onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, '', "ADT")}
                                  options={state.adtList}
                                  placeholder="Choose Country.."
                                  selected={[pasenger]}
                                  disabled={state.enableImport || props.disable}
                                />
                              }
                              {pasenger.ty.toUpperCase() === "CHD" &&
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="fn"
                                  onChange={handleChange(idx)}
                                  onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, "CHD")}
                                  options={state.chdList}
                                  placeholder="Choose Country.."
                                  selected={[pasenger]}
                                  disabled={state.enableImport || props.disable}
                                />
                              }
                              <Form.Label>First Name<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="ln">
                              <Form.Control type="text" disabled={state.enableImport || props.disable} placeholder="Last Name" value={pasenger.ln} onChange={handleChange(idx)} />
                              <Form.Label>Last Name<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs="3" className="selectplugin datePicker yearlist" controlId="dob">
                              {pasenger.ty === "ADT" &&
                                <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  //value={pasenger.dob}
                                  onChange={handleDobChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  maxDate={paxDate(new Date(), 18)}
                                  minDate={props.srequest.bound_type == "0"? childMin(new Date(), 71): childMin(new Date(), 66)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.disable}
                                />
                              }
                              {pasenger.ty === "CHD" &&
                                <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  // value={pasenger.dob}
                                  onChange={handleDobChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  minDate={childMin(new Date(),18)}
                                  maxDate= {new Date().setDate(new Date().getDate()-1)
                                  }
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.disable}
                                />
                              }
                               <Form.Label>Date of Birth<sup>*</sup></Form.Label>
                              <span className="calendar ezyIcon icon-newcalendar"></span>
                            </Form.Group>
                            {pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="em">
                                <Form.Control type="text" disabled={state.enableFields && props.disable} placeholder="Email" value={pasenger.em} onChange={handleChange(idx)} />
                                <Form.Label>Email<sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            {props.bookType!==undefined && props.bookType!=="IMPORT" &&   pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="phc" className="customSelect">
                                <Form.Control as="select" disabled={props.disable} value={pasenger.phc} onChange={handleChange(idx)}>
                                  {state.stdCodeMenu}
                                </Form.Control>
                                <Form.Label>Phone code <sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            { pasenger.lead &&
                              <Form.Group as={Col} xs={3} controlId="phn">
                                <Form.Control type="text" disabled={state.enableFields && props.disable} placeholder="Phone Number" value={pasenger.phn} onChange={handleChange(idx)} />
                                <Form.Label>Phone Number<sup>*</sup></Form.Label>
                              </Form.Group>
                            }
                            {pasenger.lead && <Form.Group as={Col} xs={3} controlId="destnum">
                              <Form.Control type="text" placeholder="Destination contact number" value={pasenger.destnum} onChange={handleChange(idx)} disabled={props.disabledcn ? true : false} />
                              <Form.Label>Destination Contact number</Form.Label>
                            </Form.Group>
                            }
                            {!pasenger.lead &&
                            <Form.Group as={Col} xs={3} controlId="relation" className="customSelect">
                            <Form.Control as="select" disabled={props.disable} value={pasenger.relation} onChange={handleChangeType(pasenger)}>
                              {state.relationsList}
                            </Form.Control>
                            <Form.Label>Relation<sup>*</sup></Form.Label>
                            </Form.Group>
                            }
                          </React.Fragment>
                        </Form.Row>
                        {pasenger.lead &&
                          <Form.Row>
                          <Form.Group as={Col} xs={3} controlId="address">
                              <Form.Control type="textarea" placeholder="Enter Address" disabled={props.disable} autoComplete="off" value={state.address} onChange={handleChangeAdd} />
                              <Form.Label>Address<sup>*</sup></Form.Label>
                          </Form.Group>
                          <Form.Group as={Col} xs={3} controlId="pobox">
                              <Form.Control type="text" placeholder="Enter Po Box" disabled={props.disable} autoComplete="off" value={state.pobox} onChange={handleChangeAdd} />
                              <Form.Label>PO Box<sup>*</sup></Form.Label>
                          </Form.Group>
                          <Form.Group as={Col} xs={3} controlId="city">
                              <Form.Control type="text" placeholder="Enter City Name" disabled={props.disable} autoComplete="off" value={state.city} onChange={handleChangeAdd} />
                              <Form.Label>City<sup>*</sup></Form.Label>
                          </Form.Group>
                          </Form.Row>
                        }
                        {state.passportEnable &&
                          <div>
                            <h6 className="subTitle">Passport Info</h6>
                            <Form.Row>
                              <Form.Group as={Col} xs={3} controlId="num">
                                <Form.Control type="text" disabled={state.enbalePPInfo || props.disable} placeholder="Passport Name" value={pasenger.doc.num} onChange={handleChange(idx)} />
                                <Form.Label>Passport Number {state.docMandatory === "yes" ? <sup>*</sup> : ""} </Form.Label>
                              </Form.Group>
                              <Form.Group as={Col} controlId="na" className="customSelect">
                                <Form.Control as="select" disabled={state.enbalePPInfo || props.disable} value={pasenger.doc.na} onChange={handleChange(idx)}>
                                  <option value="">Select</option>
                                  {state.nationalityList}
                                </Form.Control>
                                <Form.Label>Nationality {state.docMandatory === "yes" ? <sup>*</sup> : ""}</Form.Label>
                              </Form.Group>
                              <Form.Group as={Col} controlId="isc" className="customSelect">
                                <Form.Control as="select" disabled={state.enbalePPInfo || props.disable} value={pasenger.doc.isc} onChange={handleChange(idx)}>
                                  <option value="">Select</option>
                                  {state.countriesMenu}
                                </Form.Control>
                                <Form.Label>Issued Country {state.docMandatory === "yes" ? <sup>*</sup> : ""}</Form.Label>
                              </Form.Group>
                              <Form.Group as={Col} className="selectplugin datePicker" controlId="isd">
                                <DatePicker
                                  className="form-control"
                                  selected={pasenger.doc.isd !== null ? DateUtils.convertNewToDate(pasenger.doc.isd) : null}
                                  onChange={handleIssuDateChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  minDate={pasenger.dob ? pasenger.dobOrg : ""}
                                  maxDate={pasenger.ty === "CHD" ? new Date().setDate(new Date().getDate()-1) : new Date()}
                                  minDate={pasenger.ty === "CHD" ? childMin(new Date(),18) : props.srequest.bound_type == "0" ? childMin(new Date(), 71): childMin(new Date(), 66)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={state.enbalePPInfo || props.disable}
                                />
                                <Form.Label>Issued Date</Form.Label>
                                <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                              <Form.Group as={Col} className="selectplugin datePicker" controlId="exd">
                                <DatePicker
                                  className="form-control"
                                  selected={pasenger.doc.exd !== null ? DateUtils.convertNewToDate(pasenger.doc.exd) : null}
                                  onChange={handleExpDateChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  minDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={state.enbalePPInfo || props.disable}
                                />
                                <Form.Label>Expiry Date {state.docMandatory==="yes"?<sup>*</sup>:""}</Form.Label>
                                <span className="calendar ezyIcon icon-newcalendar"></span>
                              </Form.Group>
                            </Form.Row>
                            <div className="d-flex covidExtension">
                            {/* <div className="allPax flightpax"><Form.Check
              type="checkbox"

              id="pax"
              label="Covid 19 Extension"
              custom
            />
            </div>

            <div className="allPax flightpax"><Form.Check
              type="checkbox"

              id="pax"
              label="Winter Sport"
              custom
            />
            </div> */}
                              </div>

                          </div>
                        }
                      </div>
                    </ShowHide>
                  </>
                ))}
              </div>
            }
          </div>
        </ShowHide>
      </div>
    </>
  )
}
export default InsTravellers