
import React, { useEffect, useReducer,useState} from 'react';
import Select from 'react-select';

const initialState = {
  adultCount: 1, childCount: 0, adtmax: 30, chdmax: 30,selAdt:[{"label":1,"value":1}],selChd:[]
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'editBranch':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function InsuAddTravellers(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [totalPax,setTotalPax] = useState(1)
  const [paxCounts,setpaxCounts] = useState([])
  const [chdCounts,setChdPaxCounts] = useState([])
  const [paxCount,setpaxCount] = useState([])

  useEffect(() => {
    dispatch({ type: "adultCount", payload: props.sendCount[0] })
    dispatch({ type: "childCount", payload: props.sendCount[1] })
    if(props.sendCount[0]!==undefined){
      dispatch({ type: "selAdt", payload: {"label":props.sendCount[0],"value":props.sendCount[0]} })
    }
    if(props.sendCount[1]!==undefined){
      dispatch({ type: "selChd", payload: {"label":props.sendCount[1],"value":props.sendCount[1]} })
    }
  }, [props.sendCount]);

  useEffect(() => {
    let counts=[];
    for (var i = 1; i < 31; i++) {
      counts.push({"label":i,"value":i});
    }
    setpaxCounts(counts)
    let chdCounts=[];
    for (var i = 0; i < 31; i++) {
      chdCounts.push({"label":i,"value":i});
    }
    setChdPaxCounts(chdCounts)
  }, []);
  

  useEffect(() => {
    if(props.ismodify){
      dispatch({ type: "selAdt", payload: {"label":props.sendCount[0],"value":props.sendCount[0]} })
      dispatch({ type: "selChd", payload: {"label":props.sendCount[1],"value":props.sendCount[1]} })
    }
  }, [props.ismodify]);



  const handleAdtChange = (obj) => {
    dispatch({ type: "adultCount", payload: Number(obj.value)})
    setpaxCount(Number(obj.value));
    dispatch({ type: "selAdt", payload: obj })
    props.sendAdtCount(Number(obj.value))
  };

  const handleChdChange = (obj) => {
    dispatch({ type: "childCount", payload: Number(obj.value)})
    setpaxCount(state.adultCount+Number(obj.value));
    dispatch({ type: "selChd", payload: obj })
    props.sendChdCount(Number(obj.value))
  };

  const closeTrvl = () => {
    props.closetravaler("coseTrv");
  }
  return (
    <>
      <div className="dropDownPopUP travell">
        <h5>No. of Travellers <span onClick={closeTrvl} className="closeTravel ezyIcon icon-close"></span></h5>
        
        <div className="class d-flex  align-items-center justify-content-between">
          <label>{props.tripType===1?"Adult(18-70 yrs)":"Adult(18-65 yrs)"} </label>
          <div className="selectWidth">
            <Select
            isSearchable={false}
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={handleAdtChange}
            options={paxCounts}
            value={state.selAdt}
          /></div>
        </div>
        <div className="class d-flex  align-items-center justify-content-between">
          <label>Child(0-17 yrs)</label>
          <div className="selectWidth">
            <Select
            isSearchable={false}
            placeholder = "Select"
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={handleChdChange}
            options={chdCounts}
            value={state.selChd}
          /></div>
        </div>
      </div>
      
    </>
  )
}


export default InsuAddTravellers;
