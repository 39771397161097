
import React, { Component, useState } from 'react';
import HotelSearchPart from '../hotel/HotelSearchPart'
function HotelSearchFun(props) {
    const { state} = props || {}

    const [flightSearch, setFlightSearch] = useState({
        active:'FlightTab'
    });

    const content = {
        FlightTab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        OperationsTab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        };

    return(
        <>
          <div className="searchTab hotelSearchPart">
          <HotelSearchPart/>
          </div>


        </>
    )}

    class HotelSearch extends Component {
      constructor(props) {
        super(props);
        this.state = {


        };
      }

      render() {
        return <HotelSearchFun state={this.state} />
      }
    }

    export default HotelSearch;
