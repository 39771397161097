import axios from 'axios'

const REACT_APP_B2B = process.env.REACT_APP_B2B_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Exchange rate
 * @param {*} Service 
 */

class UserProfileService {
   
    async searchProfiles(data) {
        return await axios.post(`${REACT_APP_B2B}/att/user/fetchprofile`,data)
    }
    async getTravellers(data) {
        return await axios.post(`${REACT_APP_B2B}/att/userb2c/fetchtraveller`,data)
    }

    async updateProfile(data) {
        return await axios.post(`${REACT_APP_B2B}/att/user/updateprof`,data)
    }

    async updateTraveller(data) {
        return await axios.post(`${REACT_APP_B2B}/att/userb2c/updatetraveller`,data)
    }

    }
    
export default new UserProfileService()