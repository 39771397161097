import axios from 'axios'

const API_URL = process.env.REACT_APP_DB_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations service
 * @param {*} Service 
 */
class PredectiveSearch {
    
    async retreieveSearch(data) {
        const obj={
            searchCriteria:data
        }
        console.log(obj)
        return await axios.post(`${API_URL}/mapping/airports`,obj);
    };

    async retriveRecentSearch(id) {
        return await axios.get(`${API_URL}/common/fetchPredictive/${id}`);
    }

   
}
export default new PredectiveSearch()