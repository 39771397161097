import { apiPostHelper } from '../../../../components/commonUtils/helper';
import FlightService from '../../../../services/bookingService/flight/FlightService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH
const API_DEV_COMMON = process.env.REACT_APP_B2B_URL
const DB_DOMAIN = process.env.REACT_APP_DB_URL
/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @param {*}
 * @function Travel Itinarary
 * @date : 31-08-2020
 */

// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveBooking = async (saveObj) => {
    let finalRes = [];
    await FlightService.saveBooking(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// Load the Mini fare rules data
// ========================================================================
export const getMinifareRules = async (request) => {
    let fareData = [];
    await FlightService.getMinifareRules(request).then(response => {
        if (response) {
            fareData = response;
        }
    });
    return Promise.resolve(fareData);
}

// ========================================================================
// Load the Mini fare rules data http://localhost:3003/att/long-rules
// ========================================================================
export const getLongfareRules = async (request) => {
    let fareData = [];
    await FlightService.getLongfareRules(request).then(response => {
        if (response) {
            fareData = response;
        }
    });
    return Promise.resolve(fareData);
}

// ========================================================================
//  Get the Airport name based on the Airport code 
// ========================================================================
export const getCustomData = async (branchid, compid) => {
    let customData = [];
    await FlightService.getCustomDetails(branchid, compid).then(response => {
        if (response.data.suc) {
            customData = response.data;
        }
    });
    return Promise.resolve(customData);
}


// ========================================================================
//  Get the Airport name based on the Airport code 
// ========================================================================
export const fireFareRule = async (req) => {
    let fareRule = [];
    await FlightService.fireFareRuleReq(req).then(response => {
        fareRule = response;
    });
    return Promise.resolve(fareRule);
}

// ========================================================================
//  fireBookReq 
// ========================================================================
export const fireBookReq = async (bookreq, btype) => {
    let bookRes = [];
    if (btype !== "IMPORT" && btype !== "REISSUE" && btype !== "IMPORT/REISSUE") {
        await FlightService.fireBookReq(bookreq).then(response => {
            bookRes = response.data;
        });
    } else if (btype === "IMPORT") {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await apiPostHelper(`${API_URL_COMMON}/pnr/segment-ticketing`, bookreq, headers).then(response => {
            bookRes = response.data;
        });
    } else if (btype === "REISSUE" || btype==="IMPORT/REISSUE") {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await apiPostHelper(`${API_URL_COMMON}/postTicket/rebook`, bookreq, headers).then(response => {
            bookRes = response.data;
        });
    }
    return Promise.resolve(bookRes);
}

// ========================================================================
//  fire Ticket request  
// ========================================================================
export const fireTicket = async (tktReq, btype) => {
    let ticketRes = [];
    if (btype !== "IMPORT") {
        await FlightService.fireTicketReq(tktReq).then(response => {
            ticketRes = response.data;
        });
    } else {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await apiPostHelper(`${API_URL_COMMON}/segment-ticketing`, tktReq, headers).then(response => {
            ticketRes = response.data;
        });
    }
    return Promise.resolve(ticketRes);
}


// ========================================================================
//  Get Updated CL  
// ========================================================================
export const getCrediLimit = async (req) => {
    let clRes = [];
    await AttCommonSerivce.getUpdateCreditLimit(req).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}

// ========================================================================
//  Deduct Credi Limit 
// ========================================================================
export const deductCreditLimit = async (obj) => {
    let clRes = [];
    await AttCommonSerivce.deductCreditLimit(obj).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}

// ========================================================================
//  Revert the CL 
// ========================================================================
export const revertCreditLimit = async (obj) => {
    let clRes = [];
    await AttCommonSerivce.revertCreditLimit(obj).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}

// ========================================================================
//  Get Profiles List based on Company 
// ========================================================================
export const getProfilesList = async (type, id) => {
    let profList = [];
    await AttCommonSerivce.getProfilesData(type, id).then(response => {
        profList = response.data;
    });
    return Promise.resolve(profList);
}

// ========================================================================
//  Get Profiles List based on Company 
// ========================================================================
export const getTravellersList = async (id,type) => {
    let tralList = [];
    await AttCommonSerivce.getTravellersData(id,type).then(response => {
        tralList = response.data;
    });
    return Promise.resolve(tralList);
}

// ========================================================================
//  Calling the Flight search request for One way and Round trip
// ========================================================================
export const fireSearchReq = async (url) => {
    let finalRes = [];
    await FlightService.fireFlightSearch(url).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Calling the Flight search request for Multy
// ========================================================================
export const fireMultiCitySearch = async (req) => {
    let finalRes = [];
    await FlightService.fireMultyCityReq(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
// ========================================================================
//  Calling the send confirmation obj and email request in confirmation page
// ========================================================================
export const sendConfirmationObj = async (req) => {
    req.is_eticket = true
    req.is_invoice = false
    let finalRes = [];
    await FlightService.sendConfirmationObj(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Calling the Flight search request for Multy
// ========================================================================
export const fireMultiCityReissueSearch = async (req) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/flight/reissuesearch`, req, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const importTicketing = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`http://13.234.32.199:3000/att/segment-ticketing`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: Travel details page operations
 * @author: Rambabu
 * @date : 08-12-2020 
 * @param {*} obj 
 */
export const callDiscardBook = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${DB_DOMAIN}/postBook/discardStatus`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: Travel details page operations
 * @author: Rambabu
 * @date : 08-12-2020 
 * @param {*} obj 
 */
export const getReissueFareInfo = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/flight/availability-reissue`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


//Call OLD Itinarary details
export const getOldItnarary = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/common/flight-fetch`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Call OLD Itinarary details
export const priceCheckService = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/flight/pricecheck`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Call OLD Itinarary details
export const getSeatMapDetails = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/flight/getseatmap`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Call OLD Itinarary details
export const getPriceInfo = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/flight/aa-search-price`, obj, headers).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveManualBook = async (saveObj) => {
    let finalRes = [];
    await FlightService.saveManualBooking(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Update Pax Info
// ========================================================================
export const updatePaxInfo = async (obj) => {
    let finalRes = [];
    await FlightService.updatePax(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Update Pax Info
// ========================================================================
export const getSalamAirBaggage = async (obj) => {
    let finalRes = [];
    await FlightService.getBaggage(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Riya Travels Fare Fetch Operations
// ========================================================================
export const getRiyaAirBaggage = async (obj) => {
    let finalRes = [];
    await FlightService.getriyaBaggage(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}