
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import MainMenu from '../../../../components/common/MainMenu';
import AirServiceConfigMenu from '../../../common/subMenu/HotelServiceConfigMenu'
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import NoRecord from '../../../common/popUps/NoRecord';
import log from "loglevel";
import RegionType from '../../../adminstration/common/RegionType';
import { mobileNumberValidation } from '../../../commonUtils/FormValidations';
import { creation, getHoldBookingsData, update ,talist } from './operations';
import Select from 'react-dropdown-select';

/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Categeroy
 * @date : 13-06-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    company_id:0, maxHoldCount: "",selTa: [], showMessage: false, loadCategory: false, loadEdit: false, loadSubmit: false, id: 0,taMenu:[],comp_name:"",selBranch:[],branchList:[],region_type:"",region_type_edit:"",regionError:false,branchError:false,
    res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false,isItineraryLoading:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, company_id:0, maxHoldCount: ""
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Category
 */
function MaxOnhold() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState([]);

    useEffect(() => {
        dispatch({ type: 'isLoadingMore', payload: true });
        dispatch({ type: 'enbaleUpdate', payload: false })
        // talist().then((res) => {
        //     dispatch({ type: 'taMenu', payload: res })
        //   });
        const payload = {
            "userid": context.logindata.uid,
            "is_admin": context.logindata.is_admin,
            "region_type" : context.logindata.region_type
          }
          getHoldBookingsData(payload).then(response => {
            if (response && response.length !== 0) {
                convertHoldBookings(response);
            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }, [context.logindata]);

    //Converting all Hold bookings
    const convertHoldBookings = (response) => {
        let data = [];
        var enableWEdit = false;
        if (context.logindata !== "" && context.logindata.roles !== undefined) {
            if (context.logindata.roles.includes('C_T_U')) {
                enableWEdit = true;
            }
            if(response!==undefined){
                for (let val of response){
                    if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){

                    data.push({region_type:val.region_type, branch: val.branch_name, cname: val.company_name,hold_count: val.max_hold, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editHoldBooking(val, "view")}></a> {enableWEdit && <a className="ezyIcon icon-edit" onClick={editHoldBooking(val, "edit")}></a>}</span>  </> })
                    }
                }
                dispatch({ type: 'res', payload: data })
            }
        }
        dispatch({ type: 'res', payload: data })
        dispatch({ type: 'isLoadingMore', payload: false });
    }

    // OnChange event
    const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
    }

    //Adding Comapny
  const setTypeValue = (data) => {
    if (data && data.length > 0) {
        dispatch({ type: "company_id", payload: data[0].value });
        dispatch({ type: "comp_name", payload: data[0].label });
        dispatch({ type: "selTa", payload: data });
    }
  }

    //redirect to admin page
    const handleCancel = (e) => {
        return window.location=window.location.pathname;
        dispatch({ type: 'selTa', payload: [] })
        dispatch({ type: 'enbaleUpdate', payload: false });
        dispatch({ type: 'enableView', payload: false });
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "noError", payload: false })
        dispatch({ type: 'maxHoldCount', payload: "" })
        // talist().then((res) => {
        //     dispatch({ type: 'taMenu', payload: res })
        //   });
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

      const validateHoldBooking=()=>{
        let availCompany=state.res.filter((obj)=> obj.cname===state.comp_name);
        if(availCompany.length!==0){
            return false
        }else{
            return true;
        }
      }
    //Create Categeory
    const handleHoldCreation = () => {
        dispatch({ type: "companyError", payload: false })
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "noError", payload: false })
        dispatch({ type: "regionError", payload: false })
        dispatch({ type: "branchError", payload: false })
        if(state.maxHoldCount!=="" && state.maxHoldCount!=="0" && state.company_id!==0 && state.region_type!=="" && state.selBranch.length!==0){
                if (mobileNumberValidation(state.maxHoldCount)){
                    if(validateHoldBooking()){
                        

                    dispatch({ type: "nameError", payload: false })
                    const payload={
                        "company_id":state.company_id,
                        "max_hold":Number(state.maxHoldCount),
                        "crby":context.logindata.uid,
                        "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
                        "branch_id":state.selBranch[0].value,
                        "branch_name":state.selBranch[0].label
                    }
                    dispatch({ type: "isItineraryLoading", payload: true });
                    creation(payload).then((addRes) => {
                        if (addRes.suc){
                            getHoldBookingsData().then(response => {
                                if (response && response.res !== 0) {
                                    convertHoldBookings(response);
                                }
                                dispatch({ type: 'isLoadingMore', payload: false });
                            })
                            dispatch({ type: 'selTa', payload: [] })
                            talist().then((res) => {
                                dispatch({ type: 'taMenu', payload: res })
                              });
                            dispatch({ type: 'maxHoldCount', payload: "" });
                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Hold Bookings saved successfully' });
                            dispatch({ type: 'notiVarient', payload: 'success' });
                            hidemessage()
                            window.location="/ezytrip/administration/hotelServices/maxOnhold";
                        } else {
                            dispatch({ type: "isItineraryLoading", payload: false });

                            dispatch({ type: 'notiMessageShow', payload: true });
                            dispatch({ type: 'notiMessage', payload: 'Hold Bookings not saved..' });
                            dispatch({ type: 'notiVarient', payload: 'danger' });
                            hidemessage()
                        }
                    });
                }else{
                    dispatch({ type: "isItineraryLoading", payload: false });

                    dispatch({ type: 'notiMessageShow', payload: true });
                    dispatch({ type: 'notiMessage', payload: 'Already created Hold bookings with '+state.comp_name });
                    dispatch({ type: 'notiVarient', payload: 'danger' });
                    hidemessage()
                }
                } else {
                    dispatch({ type: "noError", payload: true })
                }
            }else{
            if(state.maxHoldCount==="0" || state.maxHoldCount===''){
                dispatch({ type: "nameError", payload: true })
            }else if(state.region_type===""){
                dispatch({ type: "regionError", payload: true })
            }else if(state.selBranch.length===0){
                dispatch({ type: "branchError", payload: true })
            }else if(state.company_id===0){
                dispatch({ type: "companyError", payload: true })
            }
        }
    }

    //Edit Categeory
    const editHoldBooking = (obj, type) => () => {
        dispatch({ type: 'enableView', payload: false });
        dispatch({ type: 'maxHoldCount', payload: obj.max_hold });
        dispatch({ type: 'region_type', payload: obj.region_type });
        dispatch({ type: 'region_type_edit', payload: obj.region_type });
        dispatch({ type: 'company_id', payload: obj.company_id });
        dispatch({ type: 'selTa', payload: [{ value: obj.company_id, label: obj.company_name }] })
        if (type === "view") {
            dispatch({ type: 'enableView', payload: true });
            dispatch({ type: 'enbaleUpdate', payload: false });
        } else {
            dispatch({ type: 'enableView', payload: false });
            dispatch({ type: 'enbaleUpdate', payload: true });
        }
        dispatch({ type: 'selBranch', payload: [{ value: obj.branch_id, label: obj.branch_name }] })
    }

    //Update Categeory
    const updateHoldBookings = (e) => {
        dispatch({ type: 'showMessage', payload: false });
        dispatch({ type: 'loadCategory', payload: true });
        dispatch({ type: "nameError", payload: false })
        dispatch({ type: "noError", payload: false })
        if(state.maxHoldCount!=="0" && state.maxHoldCount!==""){
        if (mobileNumberValidation(state.maxHoldCount)){
            dispatch({ type: "isItineraryLoading", payload: true });

        const payload={
            "company_id":state.company_id,
            "max_hold":Number(state.maxHoldCount),
            "upby":context.logindata.uid,
            "region_type":state.region_type,
            "branch_id":state.selBranch[0].value,
            "branch_name":state.selBranch[0].label
        }
        creation(payload).then(response => {
            if (response.suc) {
                dispatch({ type: 'loadCategory', payload: false });
                let updtedCat = state.res.map(
                    (obj, sidx) => {
                        if (obj.company_id !== state.company_id) return obj;
                        return {
                            ...obj,
                            maxHoldCount: state.maxHoldCount
                        };
                    }
                );
                dispatch({ type: 'selTa', payload: [] })
                dispatch({ type: 'maxHoldCount', payload: "" })
                talist().then((res) => {
                    dispatch({ type: 'taMenu', payload: res })
                });
                dispatch({ type: 'res', payload: updtedCat })
                dispatch({ type: 'enbaleUpdate', payload: false });
                dispatch({ type: 'maxHoldCount', payload: "" });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Hold Bookings updated successfully' })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                getHoldBookingsData().then(response => {
                    if (response && response.length !== 0) {
                        convertHoldBookings(response);
                    }
                    dispatch({ type: 'isLoadingMore', payload: false });
                })
                window.location="/ezytrip/administration/hotelServices/maxOnhold";
            } else {
                dispatch({ type: "isItineraryLoading", payload: false });

                dispatch({ type: 'loadCategory', payload: false });
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Hold Bookings has not updated..' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        })
        }else{
            dispatch({ type: "noError", payload: true })
            dispatch({ type: 'loadCategory', payload: false });
        }
        }else{
            dispatch({ type: 'loadCategory', payload: false });
            dispatch({ type: "nameError", payload: true })
        }
        }


    const columns = [
    {
        dataField: 'region_type',
        text: 'Region Type',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'branch',
        text: 'Branch name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'cname',
        text: 'Company Name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'hold_count',
        text: 'Hold Bookings',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
    }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const handleRegionType = (e) => {
        dispatch({ type: "branchList", payload: e });
        dispatch({ type: "regionError", payload: false });
        dispatch({ type: "selBranch", payload: [] });
        dispatch({ type: "selTa", payload: [] });
    }
    
    const updateRegion=(region)=>{
        dispatch({ type: "region_type", payload: region });
      }

    const handleBranchChange = (e)=> {
            dispatch({ type: 'selBranch', payload: e});
            dispatch({ type: 'selTa', payload: []});
            dispatch({ type: "branchError", payload: false });
            AttCommonSerivce.getCompanyList(e.length!==0?e[0].value:"")
              .then((response) => {
                if (response.data.suc){
                  dispatch({ type: 'taMenu', payload: response.data.res });
                }
              })
              .catch(function (error) {
                log.error("Exception occured in getCompanyList function---" + error);
              });
          };

    return (
        <>
            <MainMenu active='Hoel Services' />
            <Breadcrumb activePage="Hold Bookings" />
            <div className="container">
                <div className="cardMainlayout pt-0">
                    <AirServiceConfigMenu active="Hold Bookings" />
                    <div className="contentArea">
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>

{state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
                        <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                            <RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={state.region_type_edit} />
                               {state.regionError && (
                                 <p className="vError">Please Select Region Type</p>
                                )}
                                <Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                                <Select
                                options={state.branchList}
                                values={state.selBranch}
                                searchable={false}
                                disabled={state.enableView || state.enbaleUpdate}
                                onChange={(e)=>handleBranchChange(e)}
                                />
                                {state.branchError && (
                                 <p className="vError">Please Select branches</p>
                                )}
                                <Form.Label>Branch<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs="3" className="selectplugin" controlId="company_id">
                            <Select
                            disabled={state.enableView || state.enbaleUpdate}
                            options={state.taMenu}
                            id="class"
                            isSearchable={false}
                            values={state.selTa}
                            onChange={(value) => (setValue(value), setTypeValue(value))}
                            />
                            <Form.Label>Company<sup>*</sup></Form.Label>
                            {state.companyError && (
                                <p className="vError">Please Select a Company</p>
                            )}
                        </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={3} controlId="maxHoldCount">
                                    <Form.Control type="text" disabled={state.enableView} placeholder="Enter Max Hold Count" autoComplete="off" value={state.maxHoldCount} onChange={handleChange} />
                                    <Form.Label>No of Bookings Allow<sup>*</sup></Form.Label>
                                    {state.showMessage && (
                                        <div className="invalid-feedback">
                                            No of Bookings Allow
                                        </div>
                                    )}
                                    {state.nameError && (
                                        <p className="vError">Please Enter No of Bookings Allow</p>
                                    )}
                                    {state.noError && (
                                        <p className="vError">Enter Valid No of Bookings Allow</p>
                                    )}
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <div className="text-right buttonGrop pb-0">
                            {context.logindata.roles !== undefined && context.logindata.roles.includes('C_T_C') &&
                                <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={handleCancel}
                                    type="submit"
                                    isDisabled={state.enableView}
                                >Reset</Button>
                            }
                            {!state.enbaleUpdate && context.logindata.roles !== undefined && context.logindata.roles.includes('C_T_C') && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={handleHoldCreation}
                                    isDisabled={state.enableView}
                                    loading={state.loadCategory}
                                    type="submit"
                                    disabled={!context.logindata.roles.includes('C_T_C') ? true : false}
                                > Submit</Button>
                            )}
                            {state.enbaleUpdate && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateHoldBookings}
                                    loading={state.loadCategory}
                                    isDisabled={state.enableView}
                                    type="submit"
                                > Update</Button>
                            )}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>

                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MaxOnhold