/**
 * below call belong to check null
 * @author: Rambabu  
 * Date:20-07-2020
 */
const isNotNull = function (data) {
  if (data !== null
    && data !== undefined
    && data !== 'null'
    && data !== 'undefined'
    && data !== '') {
    return true;
  } else {
    return false;
  }
}

/**
 *this belongs to check success response
 * @author: Rambabu  
 * Date:20-07-2020
 */
const isSuccessResponse = function (status) {
  if (isNotNull(status) && status === 200) {
    return true;
  } else {
    return false;
  }

}

/**
 * this to check array is empty or not
 * @author: Rambabu  
 * Date:20-07-2020
 */
const isArrayNotEmpty = function (arr) {
  if (isNotNull(arr) && arr.length > 0) {
    return true;
  } else {
    return false;
  }

}

module.exports = {
  isNotNull: isNotNull,
  isSuccessResponse: isSuccessResponse,
  isArrayNotEmpty: isArrayNotEmpty
};
