import React from 'react';
import { Col, Row } from "react-bootstrap";
//This function will show the Air markups List and Add Markup
function Baggage(props) {
    const mappings = props.mapping;
    return (
        <>
<div className='baggeGecont'>
            <Row className="baggageinfo">
                <Col xs={4} className="rout">
                <p>Destination</p>
               </Col>

                <Col xs={2} className="cabin">
                    <p>Cabin</p>

                </Col>
                <Col className="checkIn">
                    <p>Check-In</p>

                </Col>
            </Row>

            {props.origin.bgd.length!==0 &&  props.origin.bgd.map((bag, index) => (
                <Row className="baggageinfo">


                    <>
                        <Col xs={4} className="rout d-flex">
                        <span> ({props.origin.seg[0].da}) To ({props.origin.seg.slice(-1)[0].ar})</span>
                            <span className="tag ml-2">Free</span>
                        </Col>

                        <Col xs={2} className="cabin">

                            <div>

                                <span>7 kg</span>
                            </div>

                        </Col>
                        <Col className="checkIn">

                            <div>
                               {bag.qun!==0 &&
                                <span>{bag.qun} {bag.wt}</span>
                               }
                               {bag.qun===0 &&
                                <span>N/A</span>
                               }
                            </div>

                        </Col>
                    </>
                </Row>

            ))
            }
                 </div>
        </>


    )
}
export default Baggage