
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../App";
import Tabs from '../../common/tabs/Tabs';
import IndRefundDetails from './IndRefundDetails';
let response = require("../../masterData/FareRule.json");


function IndigoSummaryDetails(props) {
  const [state, setState] = useState({})
  const [context, setContext] = useContext(Context);
  const [attPriceList, setAttPriceList] = useState([]);
  const [taPriceList, setTaPriceList] = useState([]);
  const [refundRes, setRefundRes] = useState(props.indigoRefundList);
  const [tablist, setTablist] = useState({
    active: 'ATT'
  });

  useEffect(() => {
    if(props.indigoRefundList!==0 && props.indigoRefundList!==undefined){
      let attList=[];
      let taList=[];
      for(let val of props.indigoRefundList){
        let att=val.price.filter((obj)=>obj.type==="ATT");
        let ta=val.price.filter((obj)=>obj.type==="TA");
        attList.push(att[0]);
        taList.push(ta[0]);
      }
      setAttPriceList(attList);
      setTaPriceList(taList);
    }
  }, [props.indigoRefundList])
  

  const content = {
    ATT: <IndRefundDetails refundData={attPriceList} type={"ATT"} />,
    TA: <IndRefundDetails refundData={taPriceList} type={"TA"} />
  };


  return (
    <>
      {attPriceList.length !== 0 && taPriceList.length !== 0 &&
        <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
          <h4 className="dashBordTitle mb-3">Refund Details</h4>
          <div className="tabLink tabList">
            {context.logindata.utype === "att_user" && attPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                <span key="TA">Traval Agent</span>
              </Tabs>
            }
            {context.logindata.utype === "ta_user" && taPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="TA">Traval Agent</span>
              </Tabs>
            }
            <div className="tabContent">
              {content[tablist.active]}
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default IndigoSummaryDetails;
