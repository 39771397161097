import React, { useEffect, useState, useRef, useReducer } from "react";
import { Col, Form, Button,Tab, Tabs, Modal } from "react-bootstrap";
import ShowHide from '../../../common/showHide/ShowHide';
import Payment from '../../flight/payment/Payment';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import DateUtils from "../../../commonUtils/DateUtils";
import log from "loglevel";
import moment from "moment";
import { cities, countries, countriesWitCode, stdBookingCodes } from "../../../adminstration/utils";


const initialState = {
passengers:[], chdPassengers:[], genderList:[], relationList:[], stdCodeMenu:stdBookingCodes(),
countriesMenu:[],cityMenu:[], na:"", 
adtfn:"", adtln:"", adtdob:"", adtgen:"", adtrn:"", adtphn:"", adtem:"",
adtna:"", adtcity:"",adtstate:"", adtpas_num:"", adtpas_exp:"", adtadrss:"",
chdfn:"", chdln:"", chddob:"", chdgen:"", chdrn:"", chdphn:"", chdem:"",
chdna:"", chdcity:"",chdstate:"", chdadrss:"", chdpas_num:"", chdpas_exp:"", field_type:"", is_mand:false, extraAdtFields:[], extraChdFields:[]
}

const reducer = (state, action) => {
  switch (action.type) {

    case 'test':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function VisaTravellers(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [startDate, setStartDate] = useState(new Date());
  const [fareRes, setFareRes] = useState(props.fareRes);
  const [adt_docs, setAdt_docs] = useState(props.fareRes?.res?.docs_info);



  useEffect(()=>{
    for (let fields of props.fareRes?.res?.pax_info){
      if (fields.type=="ADT"){
        for (let field of fields.pax){
          if (field.name.includes("First Name")){
            // dispatch({type:"adtfn", payload:field.name})
            // dispatch({type:"field_type", payload:field.type})
            // dispatch({type:"is_mand", payload:field.is_mand})
            state.adtfn=field
          }
          else if (field.name.includes("Last Name")){
            dispatch({type:"adtln", payload:field})
          }
          else if (field.name.includes("Date Of Birth")||field.name.includes("Date of Birth")){
            dispatch({type:"adtdob", payload:field})
          }
          else if (field.name.includes("Gender")){
            dispatch({type:"adtgen", payload:field})
          }
          else if (field.name.includes("Relation")){
            dispatch({type:"adtrn", payload:field})
          }
          else if (field.name.includes("Phone Number")||field.name.includes("Mobile Number")||field.name.includes("Contact Number")){
            dispatch({type:"adtphn", payload:field})
          }
          else if (field.name.includes("Email")||field.name.includes("E-Mail")){
            dispatch({type:"adtem", payload:field})
          }
          else if (field.name.includes("Nationality")){
            dispatch({type:"adtna", payload:field})
          }
          else if (field.name.includes("City")){
            dispatch({type:"adtcity", payload:field})
          }
          else if (field.name.includes("State")|| field.name.includes("Province")){
            dispatch({type:"adtstate", payload:field})
          }
          else if
           (field.name.includes("Passport Number")){
            dispatch({type:"adtpas_num", payload:field})
          }
          else if ((field.name.includes("Passport Expiry"))){
            dispatch({type:"adtpas_exp", payload:field})
          }
          else if (field.name.includes("Current Address")){
            dispatch({type:"adtadrss", payload:field})
          }else{
            if (field.name!="" && field.name!=undefined)
            state.extraAdtFields.push(field)
          }
        }
      }else {
        for (let field of fields.pax){
          if (field.name.includes("First Name")){
            dispatch({type:"chdfn", payload:field})
            // dispatch({type:"field_type", payload:field.type})
            // dispatch({type:"is_mand", payload:field.is_mand})
            // state.chdfn=field
          }
          else if (field.name.includes("Last Name")){
            dispatch({type:"chdln", payload:field})
          }
          else if (field.name.includes("Date Of Birth")||field.name.includes("Date of Birth")){
            dispatch({type:"chddob", payload:field})
          }
          else if (field.name.includes("Gender")){
            dispatch({type:"chdgen", payload:field})
          }
          else if (field.name.includes("Relation")){
            dispatch({type:"chdrn", payload:field})
          }
          else if (field.name.includes("Phone Number")||field.name.includes("Mobile Number")||field.name.includes("Contact Number")){
            dispatch({type:"chdphn", payload:field})
          }
          else if (field.name.includes("Email")||field.name.includes("E-Mail")){
            dispatch({type:"chdem", payload:field})
          }
          else if (field.name.includes("Nationality")){
            dispatch({type:"chdna", payload:field})
          }
          else if (field.name.includes("City")){
            dispatch({type:"chdcity", payload:field})
          }
          else if (field.name.includes("State") || field.name.includes("Province")){
            dispatch({type:"chdstate", payload:field})
          }
          else if (field.name.toUpperCase("Passport Number")){
            dispatch({type:"chdpas_num", payload:field})
          }
          else if (field.name.toUpperCase("Passport Expiry")){
            dispatch({type:"chdpas_exp", payload:field})
          }
          else if (field.name.toUpperCase("Address")){
            dispatch({type:"chdadrss", payload:field})
          } else{
            state.extraChdFields.push(field)
          }
        }        
       }
      }
      
    const gList =[{ "value": "", "label": "Select" },{ "value": "Male", "label": "Male" },{ "value": "Female", "label": "Female" },{ "value": "Other", "label": "Other" } ]
    const genderList=gList.map((title)=>
      <option value={title.value}>{title.label}</option>
    )
    dispatch({type:"genderList", payload:genderList})
    const rlist = [{ "value": "", "label": "Select" }, { "value": "father", "label": "father" }, { "value": "mother", "label": "mother" }, { "value": "brother", "label": "brother" }]
    const relationeList = rlist.map((title) =>
      <option value={title.value}>{title.label}</option>
    );
    dispatch({ type: 'relationList', payload: relationeList })
    countriesWitCode().then((res) => {
      dispatch({ type: 'countriesMenu', payload: res })
      createPaxList()        
      cities(res.value).then((res) => {
      let data = getUnique(res, "label");
      if( data.length>0){
        let cityNames=[]
        res.forEach(element => {
          cityNames.push({"city":element.label,"value":element.value})
        });
      dispatch({ type: "cityMenu", payload: cityNames })
    }
    })
    });
  },[props.fareRes.res.pax_info[0]?.pax])

  const createPaxList = () => {
    let paxList = [];
    var adtcount = 1;
    if (fareRes.res.adt_cnt) {
      for (var i = 0; i < fareRes.res.adt_cnt; i++) {
        const payload = createPaxPayload("ADT", adtcount, "Adult", adtcount);
        paxList.push(payload);
        adtcount = adtcount + 1;
      }
    }
    if (fareRes.res.chd_cnt && fareRes.res.chd_cnt > 0) {
      var chdcount = adtcount;
      var fesno = 1
      for (var i = 0; i < fareRes.res.chd_cnt; i++) {       
        const payload = createPaxPayload("CHD", chdcount, "Child", fesno);
        paxList.push(payload);
        chdcount = chdcount + 1;
        fesno = fesno + 1
      }
    }
    dispatch({ type: 'passengers', payload: paxList })
    console.log("Pax List :" + JSON.stringify(paxList));
  }

  //=====================================
  // This is common function to create passengers
  //=====================================
  const createPaxPayload = (type, count, lable, sno) => {

    if (type === "ADT"){
      var lead = false; var docPayload = "";
    (type === "ADT" && count === 1) ? lead = true : lead = false;
    docPayload = {type:"", docs:{front:"", back:""} };

    return {
     selFront:"",selBack:"",selBank:"",fError:"",ty: "P",application_id:fareRes.req.id, pas_num: "", na: state.na, city:"", pas_exp: null, sno: count, fesno: sno, tl: "", state:"",ty: type, plbl: lable, lead: lead, cur_adrs:"", gen:"", rn:"", fn: "", ln: "", em: "", phc: "", phn: "", dob: null, "docs_info": [],
    }
  } else if (type === "CHD"){
    var lead = false; var docPayload = "";
    (type === "ADT" && count === 1) ? lead = true : lead = false;
    docPayload = {type:"", docs:{front:"", back:""} };
    return {
     selFront:"",selBack:"",selBank:"",fError:"",ty: "P", application_id:fareRes.req.id, pas_num: "", na: state.na, city:"", pas_exp: null ,sno: count, fesno: sno, tl: "", state:"", ty: type, plbl: lable, lead: lead, cur_adrs:"", gen:"", rn:"", fn: "", ln: "", em: "", phc: "", phn: "", dob: null, "docs_info": [],
    }
  }
  }
  const handleLimitError = (data, type, pax)=>{
    if(type == "fn" ){
      if((data.length + pax.ln.length) > 29){
      if(data.length > pax.ln.length){
        return {
          showFirst: true,
          showLast: false
        }
      }
      else{
        return {
          showFirst: false,
          showLast: true
        }
      }
    }
    else{
      return {
        showFirst: false,
        showLast: false
      }
    }
    }
    else if(type == "ln" ){
      if((data.length + pax.fn.length) > 29){
     if(data.length > pax.fn.length ){
      return {
        showFirst: false,
        showLast: true
      }
    }
    else{
      return {
        showFirst: true,
        showLast: false
      }
    }
  }
  else{
    return {
      showFirst: false,
      showLast: false
    }
  }
  }
  else{
    return {
      showFirst: false,
      showLast: false
    }
  }
}

// OnChange event we will call this and update the state
const handleExpDateChange = idx => date => {
  console.log("gett the exp data:::::", date)
  const convertDate = date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "";
  console.log(idx);
  let newpax = state.passengers.map((pax, sidx) => {
    if (idx !== sidx) return pax;
    return {
        ...pax, pas_exp: convertDate
      , limitError: handleLimitError(date,"pas_exp" ,pax)
    }
  });
  dispatch({ type: 'passengers', payload: newpax })
  props.sendPsngersData(newpax)
}


// const hndextr = (idx, fild, id, value, type)=>{
// // const newFields=[{
// //   type:type, is_mand:fild.is_mand, value:value, id:idx, name:id
// // }]

// let newpax = state.passengers.map((pax, sidx) => {
//   if (idx !== sidx) return pax;
//   else if (type !== "INF") {
//       return { ...pax, [id]: value, limitError: handleLimitError(value, id, pax)};
//     } 
// });
// dispatch({ type: 'passengers', payload: newpax })
// props.sendPsngersData(newpax);
// }


const handleOnInpChange = (txt, id, idx, type) => {
  let newpax = state.passengers.map((pax, sidx) => {
    if (idx !== sidx) return pax;
    else if (id !== "pas_num" && id !== "na") {
      if (type !== "INF") {
        return { ...pax, [id]: txt, limitError: handleLimitError(txt, id, pax)};
      } else {
        return {
          
        }
      }
    } else {
      return {
        ...pax, docs_info: {
          ...pax.docs_info, [id]: txt
        },
        limitError: handleLimitError(txt, id, pax)
      }
    }
  });
  dispatch({ type: 'passengers', payload: newpax })
  props.sendPsngersData(newpax);
}

function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map((e) => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}
  const handleChange = (idx, type) => (e) => {
    const re = /^[0-9]*[0-9][0-9]*$/;
    try {
      if (e[0] === undefined) {
        let newpax = state.passengers.map((pax, sidx) => {
          const { id, value } = e.target;
          if (idx !== sidx) return pax;
          else if(type.toUpperCase()=="NUMBER"){
          if (e.target.value==="" || re.test(e.target.value)) {
           let  input_value = e.target.value;  
            if (id !== "pas_num" && id !== "na") {
              return { ...pax, [id]: input_value, limitError: handleLimitError(input_value, id, pax)};
            } else {
              return {
                ...pax, [id]: input_value
                , limitError: handleLimitError(input_value, id, pax)
              }
            }     
          }else{
            return pax}} else{
           if (id !== "pas_num" && id !== "na") {
            return { ...pax, [id]: value, limitError: handleLimitError(value, id, pax)};
          } else {
            return {
              ...pax, [id]: value
              , limitError: handleLimitError(value, id, pax)
            }
          }
        }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);
      } else {
        let newpax = state.passengers.map((pax, sidx) => {
          if (idx !== sidx) return pax;
          else if (e[0].docs_info !== undefined) {
            return {
              ...pax, pas_num: e[0].pas_num,city:e[0].city, na: e[0].na, pas_num: e[0].pas_num, ty: "P", pas_exp: e[0].pas_exp !== null ? DateUtils.convertStringToDate(e[0].pas_exp) : null, tl: e[0].tl, fn: e[0].fn, cur_adrs:e[0].value, state:e[0].state, gen:e[0].value,rn:e[0].rn, limitError: handleLimitError(e[0].fn, "fn", e[0]), ln: e[0].ln, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", 
              docs_info: {
                ...pax.docs_info, 
              }
            }
          }else if(e[0].city!=undefined){
            return {
            ...pax,city:e[0].city}
          } else {
            return { ...pax,pas_num: e[0].pas_num, na: e[0].na, pas_num: e[0].pas_num, ty: "P", pas_exp: e[0].pas_exp !== null ? DateUtils.convertStringToDate(e[0].pas_exp) : null, tl: e[0].tl, fn: e[0].fn, ln: e[0].ln, cur_adrs:e[0].value, city:e[0].city, state:e[0].state, gen:e[0].value, rn:e[0].rn, em: e[0].eml, phc: e[0].phc, phn: e[0].phn, dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "", limitError: handleLimitError(e[0].fn, "fn", e[0]) }
          }
        });
        dispatch({ type: 'passengers', payload: newpax })
        props.sendPsngersData(newpax);

      }
    } catch (err) {
      log.error('Exception occured in handleChange function---' + err);
    }
  }

  const paxDate = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() - n));
  }

  // min chd date
  const childMin = (dt, n)=>{
    return new Date(dt.setFullYear(dt.getFullYear() - n)).setDate(new Date().getDate()+1);
}

  // OnChange event we will call this and update the state
  const handleDobChange = idx => date => {
    console.log('old DOB', date)
    console.log('selected DOB', moment(date).format())
    const convertDate = (date !== null && date !== undefined ? DateUtils.convertStringToDate(date) : "");
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      return {
        ...pax, dob: convertDate, dobOrg: date !== null ? date : null, limitError: handleLimitError(convertDate, "dob", pax), pas_exp: null
        
      };
    });
    dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }



  // function for upload image n pdf 
 const handleFileChange  = (idx, type, name) => (e) => {
  let docx=[]
    // let newpax = state.passengers.map((pax, sidx) => {
    //   if (idx !== sidx) return pax;
    //   else if (e.target.files[0] !== undefined) {
        const file = e.target.files[0];
        if(file){
          if(file.type==='image/jpeg'||file.type==='image/png'|| file.type==='application/pdf'){
            if(file.size<= 2*1024*1024){
              if (name.includes("front")){
              
              docx = {
               type:type, docs:[{ front:file.name, back:""}]
              }
             } else if(name.includes("back")){
              docx= {
                type:type, docs:[{ front:file.name, back:""}]
              }
             }
             else {
              docx= {
                type:type, docs:[{ front:file.name}]
              }
             }
            } else {
              // return { ...pax, fError:"File size exceeds 2MB limit" }
            }
          } else{
            // return { ...pax, fError:"File size exceeds 2MB limit" }
                    }
        }
    //   }
    // });
    let newDocs = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else if (docx !=[]) {
          return { ...pax, [idx]: file, docs_info:docx, limitError: handleLimitError(file, idx, pax)};
        } 
    });
    dispatch({ type: 'passengers', payload: newDocs })
    props.sendPsngersData(newDocs)
  }

  const downloadFile=(file)=>{
    const url=URL.createObjectURL(file);
    const a=document.createElement('a');
    a.href=url;
    a.download=file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
  const removefile =(idx, type)=>{
    let newpax = state.passengers.map((pax, sidx) => {
      if (idx !== sidx) return pax;
      else{
        const fileInput = document.querySelector('.addfile');
        if(fileInput){
          fileInput.value= null
        } if (type=="front"){
          return {
            ...pax, selFront:[]
          }
         }else if(type=="back"){
          return {
            ...pax, selBack:[]
          }
         }else if(type=="bank"){
          return {
            ...pax, selBank:[]
          }
         }
      }
  })
  dispatch({ type: 'passengers', payload: newpax })
    props.sendPsngersData(newpax)
  }
    return (
        <>
        
        {state.passengers.map((pasenger, idx) => (

        <div className="boxLayout resultSection" key={idx}>
        <div className="tDetailsvis" >
            <ShowHide visible={pasenger.lead ? "true" : ""} icon="xxx" title={pasenger.plbl + " " + pasenger.fesno +" "+pasenger.fn}>
              <hr/>
            <span className="attChments"><h5>Attachments :</h5></span>
            
                <span className="docRquid"><span className="docRequidin">Document Required : </span>  Passport, Photo Copy, Bank Statement</span>
              {adt_docs!=undefined && adt_docs.map((docx, index)=>(
              <div className="secondSection">
              <h5>{docx.type}:</h5>
      <Form.Row>
        {docx.docs.map((docs, ids)=>(
          <div key={ids}>
          <Form.Group as={Col}>
        <Form.Label>{docs.name}</Form.Label>
        <div class="uploadButton">
          <label>
            <span className="addlogo ezyIcon icon-attach-ico"> Drag & Drop or 
            <input disabled={props.enable_pay} type="file" accept=".jpeg, .jpg, .png, .pdf" className="addfile" style={{display:'none'}} 
            onChange={handleFileChange(ids,docx.type, docs.name)}
            />
              <span className="browseE"> Browse</span>
            </span>
            </label>
            </div>
            {pasenger?.docs_info?.docs!==undefined && pasenger?.docs_info?.docs.map((docz, idd)=>(
              <div key={idd}>
            {docz==undefined || docz==""&&
            <span className="fileFormat">File Format should be JPEG, PNG, PDF. File Size 2MB</span>}
            {docz!=undefined && docz!=""&& 
              <div className="d-flex">
              <p className="d-flex mr-3" 
              onClick={()=>(downloadFile(idd, docz.docs_info))}
              >
                {docz.fornt?docz.front:docz.back}
                </p>
              <span
              className="iconBtn add ezyIcon icon-close"
              onClick={()=>removefile(idd, "front")}
              >
            </span>
          </div>
              }
              </div>))}
            {pasenger.fError!="" && 
              <Form.Text className="vError">
              {pasenger.fError}
             </Form.Text>}
        </Form.Group>
        </div>
         ))} 
         </Form.Row>
         </div>))}


            <Form.Row>
            
            { pasenger?.ty?.toUpperCase() === "ADT" && state.adtfn.name &&
               <Form.Group as={Col} controlId="fn" xs={4} className="selectplugin">
                 {state.adtfn.type.toUpperCase()!=("DROP DOWN") && 
                 <Form.Control disabled={props.enable_pay} type="text" placeholder="First Name" autoComplete="off"
                 value={pasenger.fn} onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, '', "ADT")}
                  onChange={handleChange(idx, state.adtfn.type)}/>}

                 {state.adtfn.type.toUpperCase()==("DROP DOWN") && 
                 <Select disabled={props.enable_pay} isSearchable={false}/>}
                  <Form.Label>{state.adtfn.name}{state.adtfn.is_mand?<sup>*</sup>:""}</Form.Label>
                  </Form.Group>
                  }
                 {pasenger.ty.toUpperCase() === "CHD" && state.chdfn.name && 
                 <Form.Group as={Col} controlId="fn" xs={4} className="selectpluginn">
                 {state.chdfn.type.toUpperCase()!=("DROP DOWN") &&
                 <Form.Control disabled={props.enable_pay} type="text" placeholder="First Name" autoComplete="off"
                 value={pasenger.fn}
                 onInputChange={(txt, e) => handleOnInpChange(txt, 'fn', idx, "CHD")}
                  onChange={handleChange(idx, state.chdfn.type)}/> }                 
                 {state.chdfn.type.toUpperCase()==("DROP DOWN") &&
                 <Select disabled={props.enable_pay} isSearchable={false}/> }                 
                 <Form.Label>{state.chdfn?.name}{state.chdfn?.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}

            { pasenger.ty.toUpperCase() === "ADT" && state.adtln.name &&
              <Form.Group as={Col} controlId="ln" xs={4} className="selectpluginn">
              {state.adtln.type.toUpperCase()!=("DROP DOWN") &&
                 <Form.Control disabled={props.enable_pay} type="text" placeholder="Last Name" autoComplete="off"
                 value={pasenger.ln}  onChange={handleChange(idx, state.adtln.type)} />}
                 {state.adtln.type.toUpperCase()==("DROP DOWN") &&
                 <Select disabled={props.enable_pay} isSearchable={false}/>}
                 <Form.Label>{state.adtln.name}{state.adtln.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}


            
            { pasenger.ty.toUpperCase() === "CHD" && state.chdln.name &&
            <Form.Group as={Col} controlId="ln" xs={4} className="selectpluginn">
                 {state.chdln.type.toUpperCase()!=("DROP DOWN") &&
                  <Form.Control disabled={props.enable_pay} type="text" placeholder="Last Name" autoComplete="off"
                 value={pasenger.ln}  onChange={handleChange(idx, state.chdln.type)} />}

                 {state.chdln.type.toUpperCase()==("DROP DOWN") &&
                 <Select disabled={props.enable_pay} isSearchable={false}/>}
                 <Form.Label>{state.chdln.name}{state.chdln.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}
            { pasenger.ty.toUpperCase() === "ADT" && state.adtdob.name &&
              <Form.Group as={Col} xs={4} className="selectplugin datePicker">
                { state.adtdob.type.toUpperCase()!=("DROP DOWN") &&
                                <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  //value={pasenger.dob}
                                  onCalendarOpen ={handleDobChange(idx)}
                                  onChange={handleDobChange(idx)}
                                  placeholder="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  maxDate={paxDate(new Date(), 17).setDate(new Date().getDate()-1)}
                                  minDate={new Date(1900,1,1) }
                                  preventOpenOnFocus={true}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.enable_pay}
                                  // disabled={props.disable || (state.enableImport && pasenger.disablePax!==undefined && pasenger.disablePax)}
                                />}{state.adtdob.type.toUpperCase()!=("DROP DOWN") &&
                                  <span className="calendar ezyIcon icon-newcalendar"></span>}
                                  {state.adtdob.type.toUpperCase()==("DROP DOWN") &&
                                 <Select isSearchable={false}/>}
                              <Form.Label>{state.adtdob.name}{state.adtdob.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group> }
            { pasenger.ty.toUpperCase() === "CHD" && state.chddob.name &&
              <Form.Group as={Col} xs={4} className="selectplugin datePicker">
                  
                               { state.chddob.type.toUpperCase()!=("DROP DOWN") &&
                               <DatePicker
                                  selected={pasenger.dob && DateUtils.convertNewToDate(pasenger.dob)}
                                  //value={pasenger.dob}
                                  onCalendarOpen ={handleDobChange(idx)}
                                  onChange={handleDobChange(idx)}
                                  placeholderText="DD-MM-YYYY"
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  maxDate={paxDate(new Date(),0).setDate(new Date().getDate()-1)}
                                  minDate={childMin(new Date(),17)}                                 
                                  preventOpenOnFocus={true}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  disabled={props.enable_pay}
                                  // disabled={props.disable || (state.enableImport && pasenger.disablePax!==undefined && pasenger.disablePax)}
                                />}
                               {state.adtdob.type.toUpperCase()!=("DROP DOWN") &&<span className="calendar ezyIcon icon-newcalendar"></span>}
                               {state.chddob.type.toUpperCase()==("DROP DOWN") &&
                                 <Select isSearchable={false}/>}
                                  <Form.Label>{state.chddob.name}{state.chddob.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group> }
        
              </Form.Row>
          
              <Form.Row>
              { pasenger.ty.toUpperCase() === "ADT" && state.adtgen.name &&
              //  <Form.Group className="selectplugin" as={Col} xs={3}>
                <Form.Group as={Col} xs={3} controlId="gen" className="customSelect">
           {state.adtgen.type.toUpperCase()==("DROP DOWN") &&
           <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.gen}
                onChange={handleChange(idx, state.adtgen.type)}>
                {state.genderList}
                </Form.Control>}
           {state.adtgen.type.toUpperCase()!=("DROP DOWN") &&
           <Form.Control disabled={props.enable_pay} type="text" placeholder="gender"  value={pasenger.gen}
                onChange={handleChange(idx, state.adtgen.type)} />}
                 <Form.Label>{state.adtgen.name}{state.adtgen.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}

              { pasenger.ty.toUpperCase() === "CHD" && state.chdgen.name &&
              //  <Form.Group className="selectplugin" as={Col} xs={3}>
                <Form.Group as={Col} xs={3} controlId="gen" className="customSelect">
           {state.chdgen.type.toUpperCase()==("DROP DOWN") &&
            <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.gen}
                onChange={handleChange(idx, state.chdgen.type)}>
                {state.genderList}
                </Form.Control>}
                {state.chdgen.type.toUpperCase()!=("DROP DOWN") &&
           <Form.Control disabled={props.enable_pay} type="text" placeholder="gender"  value={pasenger.gen}
                onChange={handleChange(idx, state.chdgen.type)} />}

                 <Form.Label>{state.chdgen.name}{state.chdgen.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}
             { pasenger.ty.toUpperCase() === "ADT" && state.adtrn.name &&
               <Form.Group as={Col} xs={3} controlId="rn" className="customSelect">
           {state.adtrn.type.toUpperCase()==("DROP DOWN") &&
            <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.rn}
                onChange={handleChange(idx, state.adtrn.type)}>
                {state.relationList}
                </Form.Control>}
            {state.adtrn.type.toUpperCase()!=("DROP DOWN") &&
           <Form.Control disabled={props.enable_pay} type="text" placeholder="relation"  value={pasenger.rn}
                onChange={handleChange(idx, state.adtrn.type)} />}
                <Form.Label>{state.adtrn.name}{state.adtrn.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}

             {pasenger.ty.toUpperCase() === "CHD" && state.chdrn.name &&
               <Form.Group as={Col} xs={3} controlId="rn" className="customSelect">
           {state.chdrn.type.toUpperCase()==("DROP DOWN") &&
              <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.rn}
                onChange={handleChange(idx, state.chdrn.type)}>
                {state.relationList}
                </Form.Control>}

                {state.chdrn.type.toUpperCase()!=("DROP DOWN") &&
           <Form.Control disabled={props.enable_pay} type="text" placeholder="relation"  value={pasenger.rn}
                onChange={handleChange(idx, state.chdrn.type)} />}

                <Form.Label>{state.chdrn.name}{state.chdrn.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}

                {pasenger.ty.toUpperCase() === "ADT" && state.adtphn.name &&
                   <Form.Group as={Col} xs={3} controlId="phc" className="customSelect">
                     <Form.Control disabled={props.enable_pay} as="select" value={pasenger.phc} onChange={handleChange(idx, state.adtphn.type)}>
                            {state.stdCodeMenu}
                       </Form.Control>
                       <Form.Label>Phone code {state.adtphn.is_mand?<sup>*</sup>:""}</Form.Label>                   
                       </Form.Group>}
                   
                  {pasenger.ty.toUpperCase() === "ADT" && state.adtphn.name &&
                    <Form.Group as={Col} xs={3} controlId="phn">
                { state.adtphn.type.toUpperCase()!=("DROP DOWN") &&
                   <Form.Control disabled={props.enable_pay} type="text" placeholder="Phone Number" value={pasenger.phn} 
                   onChange={handleChange(idx, state.adtphn.type)} />}
                { state.adtphn.type.toUpperCase()==("DROP DOWN") &&
                <Select isSearchable={false}/>
                }
                <Form.Label>{state.adtphn.name}{state.adtphn.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}

                {pasenger.ty.toUpperCase() === "CHD" && state.chdphn.name &&
                   <Form.Group as={Col} xs={3} controlId="phc" className="customSelect">
                     <Form.Control disabled={props.enable_pay} as="select" value={pasenger.phc} onChange={handleChange(idx, state.chdphn.type)}>
                            {state.stdCodeMenu}
                       </Form.Control>
                       <Form.Label>Phone code {state.chdphn.is_mand?<sup>*</sup>:""}</Form.Label> 
                   </Form.Group>}

                  {pasenger.ty.toUpperCase() === "CHD" && state.chdphn.name &&
                    <Form.Group as={Col} xs={3} controlId="phn">
                 {state.chdphn.type.toUpperCase()!=("DROP DOWN") &&
                    <Form.Control disabled={props.enable_pay} type="text" placeholder="Mobile Number" value={pasenger.phn} 
                   onChange={handleChange(idx, state.chdphn.type)} />}
                   { state.chdphn.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>
                }
                <Form.Label>{state.chdphn.name}{state.chdphn.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}
              </Form.Row>

              <Form.Row>
              {pasenger.ty.toUpperCase() === "ADT" && state.adtem.name &&
                <Form.Group as={Col} xs={4} controlId="em">
                 {state.adtem.type.toUpperCase()!=("DROP DOWN") &&
                  <Form.Control disabled={props.enable_pay} type="text" placeholder="Email" value={pasenger.em} 
                 onChange={handleChange(idx, state.adtem.type)} />}
                 {state.adtem.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>}
                 <Form.Label>{state.adtem.name}{state.adtem.is_mand?<sup>*</sup>:""}</Form.Label>
              </Form.Group>}

              {pasenger.ty.toUpperCase() === "CHD" && state.chdem.name &&
                <Form.Group as={Col} xs={4} controlId="em">
                 {state.chdem.type.toUpperCase()!=("DROP DOWN") &&
                  <Form.Control disabled={props.enable_pay} type="text" placeholder="Email" value={pasenger.em} 
                 onChange={handleChange(idx, state.chdem.type)} />}
                 {state.chdem.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>}
                 <Form.Label>{state.chdem.name}{state.chdem.is_mand?<sup>*</sup>:""}</Form.Label>
              </Form.Group>}

                { pasenger.ty.toUpperCase() === "ADT" && state.adtna.name &&
                <Form.Group as={Col} xs={4} controlId="na" className="customSelect">
                 {state.adtna.type.toUpperCase()==("DROP DOWN") &&
                    <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.na} onChange={handleChange(idx, state.adtna.type)}>
                       {/* <option value="">Select</option> */}
                        {state.countriesMenu}
                    </Form.Control>}
                 {state.adtna.type.toUpperCase()!=("DROP DOWN") &&
                    <Form.Control disabled={props.enable_pay} type="text" placeholder="Nationality"  value={pasenger.na} onChange={handleChange(idx, state.adtna.type)}
                    />}
                    <Form.Label>{state.adtna.name}{state.adtna.is_mand?<sup>*</sup>:""} </Form.Label>
                </Form.Group>}

                {pasenger.ty.toUpperCase()=== "CHD" && state.chdna.name &&
                <Form.Group as={Col} xs={4} controlId="na" className="customSelect">
                   {state.chdna.type.toUpperCase()==("DROP DOWN") &&
                    <Form.Control disabled={props.enable_pay} as="select"  value={pasenger.na} onChange={handleChange(idx, state.chdna.type)}>
                       {/* <option value="">Select</option> */}
                        {state.countriesMenu}
                    </Form.Control>}
                    {state.chdna.type.toUpperCase()!=("DROP DOWN") &&
                    <Form.Control disabled={props.enable_pay} type="text" placeholder="Nationality"  value={pasenger.na} onChange={handleChange(idx, state.chdna.type)}
                    />}
                    <Form.Label>{state.chdna.name}{state.chdna.is_mand?<sup>*</sup>:""} </Form.Label>
                </Form.Group>}

                {pasenger.ty.toUpperCase() === "ADT" && state.adtcity.name &&
                <Form.Group as={Col} xs={4} controlId="city" className="selectplugin customSelect">
                {state.adtcity.type.toUpperCase()==("DROP DOWN") &&
                  <Typeahead
              labelKey="city"
              value={pasenger.city}
              onInputChange={(txt, e) => handleOnInpChange(txt, 'city', idx, "ADT")}
              onChange={handleChange(idx, state.adtcity.type)}
              newSelectionPrefix="Add a new item: "
              options={state.cityMenu}
              disabled={props.enable_pay}
              placeholder="Enter City Name"
              />
              }
             {state.adtcity.type.toUpperCase()!=("DROP DOWN") &&
             <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter City Name"  value={pasenger.city} 
             onChange={handleChange(idx, state.adtcity.type)} />}
              <Form.Label>{state.adtcity.name}{state.adtcity.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}

                {pasenger.ty.toUpperCase() === "CHD" && state.chdcity.name &&
                <Form.Group as={Col} controlId="city" className="customSelect selectplugin ">
              
                {state.chdcity.type.toUpperCase()==("DROP DOWN") &&
                <Typeahead
              labelKey="city"
              value={pasenger.city}
              onChange={handleChange(idx, state.chdcity.type)}
              onInputChange={(txt, e) => handleOnInpChange(txt, 'city', idx, '', "CHD")}
              newSelectionPrefix="Add a new item: "
              options={state.cityMenu}
              disabled={props.enable_pay}
              placeholder="Enter City Name"
              />}
              {state.chdcity.type.toUpperCase()!=("DROP DOWN") &&
             <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter City Name"  value={pasenger.city} 
             onChange={handleChange(idx, state.chdcity.type)} />}
               <Form.Label>{state.chdcity.name}{state.chdcity.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>}
           </Form.Row>

           <Form.Row>
            {pasenger.ty.toUpperCase() === "ADT" && state.adtstate.name &&
            <Form.Group as={Col} xs={4} controlId="state" className="selectpluginn">
            {state.adtstate.type.toUpperCase()!=("DROP DOWN") &&
              <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter State/Provience"  value={pasenger.state}
             onChange={handleChange(idx, state.adtstate.type)} />}
             {state.adtstate.type.toUpperCase()==("DROP DOWN") &&
              <Select disabled={props.enable_pay} isSearchable={false}/>
             }
            <Form.Label>{state.adtstate.name}{state.adtstate.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}

            {pasenger.ty.toUpperCase() === "CHD" &&  state.chdstate.name &&
            <Form.Group as={Col} xs={4} controlId="state" className="selectpluginn">
        { state.chdstate.type.toUpperCase()!=("DROP DOWN") &&
            <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter State/Provience"  value={pasenger.state} 
            onChange={handleChange(idx, state.chdstate.type)} />}
            {state.chdstate.type.toUpperCase()==("DROP DOWN") &&
              <Select disabled={props.enable_pay} isSearchable={false}/>
             }
            <Form.Label>{ state.chdstate.name}{ state.chdstate.is_mand?<sup>*</sup>:""}</Form.Label>
                      {/* <Typeahead
              allowNew
              id="state"
              onChange={handleChange(idx)}
              multiple
              newSelectionPrefix="Add a new item: "
              options={[]}
              value={pasenger.state}
              placeholder="Enter State/Provience"
              /> */}
             
            </Form.Group>}
            { pasenger.ty.toUpperCase() === "ADT" && state.adtpas_num.name &&
            <Form.Group as={Col} xs={4} controlId="pas_num">
            {state.adtpas_num.type.toUpperCase()!=("DROP DOWN") &&
              <Form.Control disabled={props.enable_pay} type="text"
              placeholder="Enter Passport Number" value={pasenger.pas_num}
              onChange={handleChange(idx, state.adtpas_num.type)} />}
              {state.adtpas_num.type.toUpperCase()==("DROP DOWN") &&
              <Select disabled={props.enable_pay} isSearchable={false} />
              }
            <Form.Label>{state.adtpas_num.name}{state.adtpas_num.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}


            {pasenger.ty.toUpperCase() === "CHD" && state.chdpas_num.name &&
             <Form.Group as={Col} xs={4} controlId="pas_num" className="selectplugin">
            {state.chdpas_num.type.toUpperCase()!=("DROP DOWN") &&
              <Form.Control disabled={props.enable_pay} type="text"
              placeholder="Enter Passport Number" value={pasenger.pas_num}
              onChange={handleChange(idx, state.chdpas_num.type)} />}
              {state.chdpas_num.type.toUpperCase()==("DROP DOWN") &&
              <Select disabled={props.enable_pay} isSearchable={false} />
              }
            <Form.Label>{state.chdpas_num.name}{state.chdpas_num.is_mand?<sup>*</sup>:""}</Form.Label>
            </Form.Group>}

            { pasenger.ty.toUpperCase() === "ADT" && state.adtpas_exp.name &&
                <Form.Group as={Col} className="selectplugin datePicker" controlId="pas_exp">
                
               {state.adtpas_exp.type.toUpperCase()!=("DROP DOWN") &&
                <DatePicker
                  className="form-control"
                  selected={pasenger.pas_exp !== null ? DateUtils.convertNewToDate(pasenger.pas_exp) : null}
                  onCalendarOpen ={handleExpDateChange(idx)}
                  onChange={handleExpDateChange(idx)}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={props.enable_pay}
                  />}{state.adtpas_exp.type.toUpperCase()!=("DROP DOWN") &&
                <span className="calendar ezyIcon icon-newcalendar"></span>}
                {state.adtpas_exp.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>}
                <Form.Label>{state.adtpas_exp.name}{state.adtpas_exp.is_mand?<sup>*</sup>:""}</Form.Label>
               </Form.Group>}
            { pasenger.ty.toUpperCase() === "CHD" && state.chdpas_exp.name &&
                <Form.Group as={Col} className="selectplugin datePicker" controlId="pas_exp">
                
               {state.chdpas_exp.type.toUpperCase()!=("DROP DOWN") &&
                <DatePicker
                  className="form-control"
                  selected={pasenger.pas_exp !== null ? DateUtils.convertNewToDate(pasenger.pas_exp) : null}
                  onCalendarOpen ={handleExpDateChange(idx)}
                  onChange={handleExpDateChange(idx)}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={props.enable_pay}
                  />}{state.chdpas_exp.type.toUpperCase()!=("DROP DOWN") &&
                <span className="calendar ezyIcon icon-newcalendar"></span>}
                {state.chdpas_exp.type.toUpperCase()==("DROP DOWN") &&
                  <Select disabled={props.enable_pay} isSearchable={false}/>}
                  <Form.Label>{state.chdpas_exp.name}{state.chdpas_exp.is_mand?<sup>*</sup>:""}</Form.Label>
               </Form.Group>}
              </Form.Row>

              {pasenger.ty.toUpperCase() === "ADT" && state.adtadrss.name &&
               <Form.Row>
              <Form.Group as={Col} xs={4} className="selectpluginn" controlId="cur_adrs">
            <Form.Label>{state.adtadrss.name}{state.adtadrss.is_mand?<sup>*</sup>:""}</Form.Label>
            {state.adtadrss.type.toUpperCase()!=("DROP DOWN") &&
              <Form.Control disabled={props.enable_pay} as="textarea" rows="3" placeholder="Remarks" 
            value={pasenger.cur_adrs}
            onChange={handleChange(idx,state.adtadrss.type)}/>}
            {state.adtadrss.type.toUpperCase()==("DROP DOWN") &&
                  <Select isSearchable={false}/>}
            </Form.Group>
              </Form.Row>}
              {pasenger.ty.toUpperCase() === "CHD" && state.chdadrss.name &&
               <Form.Row>
              <Form.Group as={Col} xs={4} className="selectpluginn" controlId="cur_adrs">
            <Form.Label>{state.chdadrss.name}{state.chdadrss.is_mand?<sup>*</sup>:""}</Form.Label>
            {state.chdadrss.type.toUpperCase()!=("DROP DOWN") &&
            <Form.Control disabled={props.enable_pay} as="textarea" rows="3" placeholder="Remarks" 
            value={pasenger.cur_adrs}
            onChange={handleChange(idx, state.chdadrss.type)}/>}
            {state.chdadrss.type.toUpperCase()==("DROP DOWN") &&
            <Select disabled={props.enable_pay} isSearchable={false} />}
            </Form.Group>
              </Form.Row>}

              {/* extra Fields */}
              {pasenger.ty.toUpperCase() === "ADT" && 
              <Form.Row>
              {state.extraAdtFields.map((adtfild, idd)=>(
                
                <Form.Group as={Col} xs={4} controlId={adtfild.name} className="selectplugin customSelect">
                {adtfild.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>}
             {adtfild.type.toUpperCase()!=("DROP DOWN") &&
             <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter City Name"  
             onChange={handleChange(idx, adtfild.type)}
             onInputChange={(txt, e) => handleOnInpChange(txt, adtfild.name, idx, '', "ADT")} />}
              <Form.Label>{adtfild.name}{adtfild.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>
              ))
            }
            </Form.Row>
            }
            {pasenger.ty.toUpperCase() === "CHD" && 
              <Form.Row>
              {state.extraChdFields.map((chdfild, idd)=>(
                
                <Form.Group as={Col} xs={4} controlId={chdfild.name} className="selectplugin customSelect">
                {chdfild.type.toUpperCase()==("DROP DOWN") &&
                <Select disabled={props.enable_pay} isSearchable={false}/>}
             {chdfild.type.toUpperCase()!=("DROP DOWN") &&
             <Form.Control disabled={props.enable_pay} type="text" placeholder="Enter City Name" 
             onChange={handleChange(idx, chdfild.type)} 
             onInputChange={(txt, e) => handleOnInpChange(txt, chdfild.name, idx, '', "CHD")} />}
              <Form.Label>{chdfild.name}{chdfild.is_mand?<sup>*</sup>:""}</Form.Label>
                </Form.Group>
              ))
            }
            </Form.Row>
            }
                {/* </div>))} */}
              </ShowHide>
         </div>
        </div>
        ))}
       
     

        </>
   );
 }
export default VisaTravellers;