import React, { useState, useEffect, useContext, useReducer } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import DeletePopup from "./AdminDeletePopup";
import AddRemainder from "./AddRemainder";
import { Context } from "../../../../App";
import { getAllRemainders } from "../../dashBoard/operations";
import DateUtils from "../../commonUtils/DateUtils";

/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  rmainderId: 0,
  remType: "",
  remainderObj: "",
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        categoryName: "",
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function AdminRemainderAlertlist(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [remainder, setRemainder] = useState({ remainderData: [] });
  const [updateriminder, setUpdateriminder] = useState(false);
  const [viewRemainder, setViewremainder] = useState(false);
  const [showDelete, setShowDelete] = useState({
    deleteShow: false,
    statusShow: false,
    text: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [status, setStatus] = useState("");
  const [day, setDay] = useState("");
  const [customDate, setCustomDate] = useState(false);
  const [mainResponse, setMainResponse] = useState("");

  useEffect(() => {
    if (context.logindata !== undefined && context.logindata !== "") {
      let is_admin = context.logindata.utype === "att_user" ? 1 : 0;
      getAllRemainders(
        context.logindata.cid,
        is_admin,
        context.logindata.uid
      ).then((response) => {
        if (response.suc) {
          convertData(response.res);
        }
      });
    }
  }, [context.logindata]);

  const bookingReference = [
    { label: "Shyft", value: "Shyft" },
    { label: "Shyftinnovation", value: "Shyftinnovation" },
    { label: "Sample Company", value: "Sample Company" },
  ];

  const userName = [
    { label: "15 Mins", value: "15 Mins" },
    { label: "30 Mins", value: "30 Mins" },
    { label: "45 Mins", value: "45 Mins" },
    { label: "1 Hour", value: "1 Hour" },
  ];

  const branchName = [
    { label: "India", value: "India" },
    { label: "QTR", value: "QTR" },
    { label: "OMR", value: "OMR" },
  ];
  const days = [
    { label: "Today", value: new Date() },
    { label: "Tomorrow", value: "Tomorrow" },
    { label: "This Week", value: "This Week" },
    { label: "Custom Dates", value: "Custom Dates" },
  ];
  const statuses = [
    { label: "All Status", value: "All Status" },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
  ];

  const openRiminder = (obj, type) => {
    dispatch({ type: "remainderObj", payload: obj });
    if (type === "edit") {
      dispatch({ type: "remType", payload: "Edit" });
      setUpdateriminder(true);
    } else {
      dispatch({ type: "remType", payload: "View" });
      setViewremainder(true);
    }
  };

  const closeRiminder = () => {
    setUpdateriminder(false);
    setViewremainder(false);
  };
  const openDelete = (name, type, remId) => {
    dispatch({ type: "rmainderId", payload: remId });
    if (type === "delete") {
      setShowDelete({ deleteShow: true, statusShow: false, text: name });
    } else {
      setShowDelete({ deleteShow: false, statusShow: true, text: name });
    }
  };
  const closeDelete = () => {
    setShowDelete({ deleteShow: false, statusShow: false, text: "" });
  };

  const updateMessage = (type) => {
    let msg = "";
    if (type === "Delete") {
      msg = "Remainder Deleted Successfully...";
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: msg });
      dispatch({ type: "notiVarient", payload: "success" });
    } else if (type === "Close") {
      msg = "Remainder Closed Successfully...";
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: msg });
      dispatch({ type: "notiVarient", payload: "success" });
    } else {
      msg = "Something went wrong please try again later";
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: msg });
      dispatch({ type: "notiVarient", payload: "danger" });
    }
    setShowDelete({ deleteShow: false, statusShow: false, text: "" });
    setTimeout(() => {
      return (window.location = window.location.pathname);
    }, 2000);
  };

  const convertData = (response) => {
    let data1 = [];
    if (response !== undefined && response.length !== 0) {
      response.map((item) => {
        data1.push({
          remai_name: item.remai_name,
          dates: !item.date_time.includes("0000-00-00 00:00:00")
            ? new Date(item.date_time).toISOString()
            : "",
          // date: moment(Date(item.date_time)).format('ddd, DD MMM, YYYY'),
          date: DateUtils.prettyDate(item.date_time, "DD-MM-YYYY"),
          time: DateUtils.prettyDate(item.date_time, "hh:mm A"),
          assigne_name: item.assigne_name,
          remai_status: item.remai_status,
          priority: (
            <>
              <span
                className={
                  item.priority === "High"
                    ? "dot_span_high"
                    : item.priority === "Medium"
                    ? "dot_span_medium"
                    : "dot_span_low"
                }
              ></span>
              <span
                className={
                  item.priority === "High"
                    ? "priority_high"
                    : item.priority === "Medium"
                    ? "priority_medium"
                    : "priority_low"
                }
              >
                {item.priority}
              </span>
            </>
          ),
          prioritys: item.priority,
          notify: item.notify !== "  before" ? item.notify : "",
          options: (
            <>
              {" "}
              <span className="tbAction">
                <a
                  className="ezyIcon icon-view"
                  onClick={() => openRiminder(item, "view")}
                ></a>
                <a
                  className="ezyIcon icon-edit"
                  onClick={() => openRiminder(item, "edit")}
                ></a>
                <a
                  className="ezyIcon icon-delete"
                  onClick={() =>
                    openDelete(item.remai_name, "delete", item.remai_id)
                  }
                ></a>
                {item.remai_status === "Open" ? (
                  <a
                    className="ezyIcon icon-openAlt toolTipInfo"
                    onClick={() =>
                      openDelete(item.remai_name, "status", item.remai_id)
                    }
                  >
                    <span className="toolTipCUstoom">Open</span>
                  </a>
                ) : (
                  <a className="ezyIcon icon-Confirmed toolTipInfo">
                    <span className="toolTipCUstoom">Close</span>
                  </a>
                )}
              </span>
            </>
          ),
        });
      });
    }
    setRemainder({ remainderData: data1 });
    setMainResponse(data1);
  };

  const colStyle = (cell, row, rowIndex, colIndex) => {
    console.log("2020", cell);
    if (cell.priority === "High") {
      return { backgroundColor: "red" };
    }
  };

  const columns = [
    {
      dataField: "remai_name",
      text: "Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      filter: textFilter(),
    },

    {
      dataField: "assigne_name",
      text: "Assignee",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "prioritys",
      text: "Priority",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "notify",
      text: "Notify before",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "options",
      text: "Actions",
      class: "tes",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const validateSearch = () => {
    if (startDate === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Please select From Date" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    } else if (endDate === "") {
      dispatch({ type: "notiMessageShow", payload: true });
      dispatch({ type: "notiMessage", payload: "Please select To Date" });
      dispatch({ type: "notiVarient", payload: "danger" });
      hidemessage();
      return false;
    }
    return true;
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  }
  const handleSearch = () => {
    // loadResponse()
    checkDate(mainResponse, day.label, status);
  };
  const checkDate = (array, date, status) => {
    let todayDate = DateUtils.prettyDate(new Date(), "DD-MM-YYYY");
    let testDate = DateUtils.addDatePlusOne(new Date());
    let tomorrowDate = DateUtils.prettyDate(testDate, "DD-MM-YYYY");
    let testDate1 = DateUtils.addDatePlusSeven(new Date());
    let weekDate = new Date(testDate1).toISOString();
    let currentDate = new Date().toISOString();
    // for Today
    if (status == "" && date !== "") {
      if (date == "Today") {
        let result = array.filter((item) => {
          return item.date == todayDate;
        });
        setRemainder({ remainderData: result });
        return;
      } else if (date == "Tomorrow") {
        let result = array.filter((item) => {
          return item.date == tomorrowDate;
        });
        setRemainder({ remainderData: result });
        return;
      } else if (date == "This Week") {
        let result = array.filter((item) => {
          return item.dates >= currentDate && item.dates <= weekDate;
        });
        setRemainder({ remainderData: result });
        return;
      } else if (date == "Custom Dates") {
        if (validateSearch()) {
          let fromDate = new Date(startDate).toISOString();
          let toDate = new Date(endDate).toISOString();
          let result = array.filter((item) => {
            return item.dates >= fromDate && item.dates <= toDate;
          });
          setRemainder({ remainderData: result });
          return;
        }
      }
    } else if (date == undefined && status !== "") {
      if (status == "All Status") {
        setRemainder({ remainderData: array });
        return;
      } else {
        let result = array.filter((item) => {
          return item.remai_status == status;
        });
        setRemainder({ remainderData: result });
        return;
      }
    } else if (status !== "" && date !== "") {
      // for Today
      if (date == "Today") {
        if (status == "All Status") {
          let result = array.filter((item) => {
            return item.date == todayDate;
          });
          setRemainder({ remainderData: result });
          return;
        } else {
          let result = array.filter((item) => {
            return item.date == todayDate && item.remai_status == status;
          });
          setRemainder({ remainderData: result });
          return;
        }
      } else if (date == "Tomorrow") {
        if (status == "All Status") {
          let result = array.filter((item) => {
            return item.date == tomorrowDate;
          });
          setRemainder({ remainderData: result });
          return;
        } else {
          let result = array.filter((item) => {
            return item.date == tomorrowDate && item.remai_status == status;
          });
          setRemainder({ remainderData: result });
          return;
        }
      } else if (date == "This Week") {
        if (status == "All Status") {
          let result = array.filter((item) => {
            return item.dates >= currentDate && item.dates <= weekDate;
          });
          setRemainder({ remainderData: result });
          return;
        } else {
          let result = array.filter((item) => {
            return (
              item.dates >= currentDate &&
              item.dates <= weekDate &&
              item.remai_status == status
            );
          });
          setRemainder({ remainderData: result });
          return;
        }
      } else if (date == "Custom Dates") {
        if (validateSearch()) {
          let fromDate = new Date(startDate).toISOString();
          let toDate = new Date(endDate).toISOString();
          if (status == "All Status") {
            let result = array.filter((item) => {
              return item.dates >= fromDate && item.dates <= toDate;
            });
            setRemainder({ remainderData: result });
            return;
          } else {
            let result = array.filter((item) => {
              return (
                item.dates >= fromDate &&
                item.dates <= toDate &&
                item.remai_status == status
              );
            });
            setRemainder({ remainderData: result });
            return;
          }
        }
      }
    }
  };
  const showDate = (value) => {
    if (value.value === "Custom Dates") {
      setCustomDate(true);
    } else {
      setCustomDate(false);
    }
    setDay(value);
  };
  return (
    <>
      <Header />
      <div className="container dashBordLayout pt-3">
        <div className="Topfilterbutton">
          <Alert
            className="notification"
            variant={state.notiVarient}
            show={state.notiMessageShow}
            onClose={() =>
              dispatch({ type: "notiMessageShow", payload: false })
            }
            dismissible
          >
            {" "}
            {state.notiMessage}
          </Alert>
          <div class="left-button">
            <Button className="rmBtn active" variant="outline-secondary">
              <span className="ezyIcon icon-alarm remaindbotton">
                Reminders
              </span>
            </Button>

            {/* <Button className="rmBtn" variant="outline-secondary">
              <span className="ezyIcon icon-warning alertbotton">  <Link
                to="/ezytrip/dashboard/alerts/list"
                title="Reminder"> Alerts </Link></span>
            </Button> */}
          </div>
          <div class="right-filterbutton">
            <Form.Row>
              <Form.Group
                className="selectplugin"
                as={Col}
                xs={3}
                controlId="firstName"
              >
                <Select
                  options={days}
                  searchable={false}
                  value={day}
                  onChange={showDate}
                  disabled={true}
                />
              </Form.Group>
              {customDate && (
                <Form.Group
                  as={Col}
                  xs={2}
                  className="selectplugin datePicker"
                  controlId="fromDate"
                >
                  <DatePicker
                    selected={startDate}
                    showPreviousMonths
                    minDate={new Date()}
                    maxDate={endDate}
                    onChange={(date) => setStartDate(date)}
                    monthsShown={1}
                    disabled={viewRemainder}
                  />
                  <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>
                    From Date<sup>*</sup>
                  </Form.Label>
                </Form.Group>
              )}
              {customDate && (
                <Form.Group
                  as={Col}
                  xs={2}
                  className="selectplugin datePicker"
                  controlId="toDate"
                >
                  <DatePicker
                    selected={endDate}
                    showPreviousMonths
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    monthsShown={1}
                    disabled={viewRemainder}
                  />
                  <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>
                    To Date<sup>*</sup>
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group
                className="selectplugin"
                as={Col}
                xs={2}
                controlId="firstName"
              >
                <Select
                  options={statuses}
                  searchable={false}
                  values={status}
                  onChange={(value) => setStatus(value.value)}
                />
              </Form.Group>
              <Button
                size="sm"
                className="changeSearch ezyIcon icon-search"
                variant="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Form.Row>
          </div>
        </div>
        <div className="cardMainlayout responSive pb-0">
          <BootstrapTable
            bootstrap4
            keyField="id"
            classes="markup filterTbl"
            data={remainder.remainderData}
            columns={columns}
            defaultSorted={defaultSorted}
            colStyle={colStyle}
            filter={filterFactory()}
            pagination={
              remainder.remainderData.length > 10 &&
              remainder.remainderData.length <= 25
                ? paginationFactory({
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                    ],
                  })
                : remainder.remainderData.length > 25 &&
                  remainder.remainderData.length <= 30
                ? paginationFactory({
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                      { text: "30", value: 30 },
                    ],
                  })
                : remainder.remainderData.length > 30
                ? paginationFactory({
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                      { text: "30", value: 30 },
                      { text: "50", value: 50 },
                    ],
                  })
                : ""
            }
          />
        </div>
        {/* Delete popup component */}
        <DeletePopup
          show={showDelete}
          itemName={showDelete.text}
          close={closeDelete}
          remainder_Id={state.rmainderId}
          upby={context.logindata.uid}
          sendMessage={updateMessage}
        />

        {(updateriminder || viewRemainder) && (
          <AddRemainder
            remainObj={state.remainderObj}
            remType={state.remType}
            closeRemainder={closeRiminder}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default AdminRemainderAlertlist;
