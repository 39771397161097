import React from 'react';

function ShowHotelInfo(props) {

    return (
        <>
            <div className="definedServices">
            {props.response!==undefined &&
            <ul>
            {props.response.htl_markups!==undefined && props.response.htl_markups.length!==0 &&
            <li>
             <strong> Markup Name: </strong>
            {props.response.htl_markups.map((mark, index) => (
                <span>{mark.name}{props.response.htl_markups[index + 1] ? "," : ""}</span>
            ))}
          </li>
            }
            {props.response.htl_service!==undefined && props.response.htl_service.length!==0 &&
            <li>
             <strong> Service Fee: </strong>
            {props.response.htl_service.map((service, index) => (
                <span>{service.name}{props.response.htl_service[index + 1] ? "," : ""}</span>
            ))}
           </li>
            }
            </ul>
             }
             </div>
            {(props.response===undefined || props.response!==undefined && props.response.htl_markups!==undefined && props.response.htl_service!==undefined  && props.response.htl_markups.length===0 && props.response.htl_service.length===0) &&
           <div className="definedServices">
           <span> No Data</span>
           </div>
           }


        </>
    )
}


export default ShowHotelInfo
