
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../../App";
import AirlinesMemo from '../../../../common/airlines';
import Button from '../../../../common/buttons/Button';
import Utilities from '../../../../commonUtils/Utilities';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';

function SelectedPackage(props) {
    const history = useHistory();
    const [state, setState] = useState({})
    const [onward, setOnward] = useState(props.onward)
    const [cur, setCur] = useState(props.currency)
    const [arrival, setArrival] = useState(props.arrival)
    const [sortList, setSortList] = useState(false)
    const [travles, setTravles] = useState(props.trav);
    const [onwardPrice, setOnwardPrice] = useState(props.owPrice)
    const [arrivalPrice, setArrivalPrice] = useState(props.rtPrice);
    const [reqObj, setReqObj] = useState(props.srequest);
    const [enable, setEnable] = useState(false)
    const [owFinalObj, setOWFinalObj] = useState(props.owSelectObj);
    const [rtFinalObj, setRTFinalObj] = useState(props.rtSelectObj);
    const [owMappinng, serOWMappinng] = useState(props.owMapping);
    const [rtMapping, setRTMapping] = useState(props.rtMapping);
    const [onwardGST, setOnwardGst] = useState(0);
    const [returnGST, setReturnGST] = useState(0);
    const [flightSearch, setFlightSearch] = useState({
        active: 'FlightTab'
    });
    const [context, setContext] = useContext(Context);
    const [gccCodes , setGccCodes] = useState(["XNB","DWC","DXB","AZAZ","DZB","DOH","XSB","KWI","MCT","DMM","SHJ","IST","JED"]);

    useEffect(() => {
        setEnable(false);
        setOWFinalObj(props.owSelectObj);
        setRTFinalObj(props.rtSelectObj);
        serOWMappinng(props.owMapping);
        setRTMapping(props.rtMapping);
        setOnward(props.onward);
        setOnwardPrice(props.owPrice);
        setArrival(props.arrival);
        setArrivalPrice(props.rtPrice);
        setEnable(true);
        calculateGST(props.owSelectObj,"OW")
        calculateGST(props.rtSelectObj,"RT")
    }, [props])


    const calculateGST=(flight,type)=>{
      let total=0;
      let gst={};
      if(props.gst!==undefined && props.gst.type!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type==="India"){
        gst = Utilities.applyGST(flight.servicefee,props.gst,flight.pr.tax,props.srequest,"Flight");
        if(gst.gst_invoice.length!==0){
          for(let val of gst.gst_invoice){
            if(gst.type==="same"){
            if(total===0){
              total=Number(val.cgst_val)+Number(val.sgst_val);
            }else{
              total=total+Number(val.cgst_val)+Number(val.sgst_val);
            }
           }else{
            if(total===0){
              total=Number(val.igst_val);
            }else{
              total=total+Number(val.igst_val);
            }
           }
        }
      }
      gst.total_gst=total;
      if(type==="OW"){
        setOnwardGst(Number(total));
      }else if(type==="RT"){
        setReturnGST(Number(total))
      }
      }
    }

    const fireFareRuleReq = () => {
        rtMapping.forEach(element => {
            owMappinng.push(element);
        });
        var ofcid="";
        if(owFinalObj.srcorigns.length!==0 && rtFinalObj.srcorigns.length!==0 && owFinalObj.srcorigns[0].office_id!==undefined && rtFinalObj.srcorigns[0].office_id!==undefined){
          ofcid=owFinalObj.srcorigns[0].office_id+"/"+rtFinalObj.srcorigns[0].office_id;
        }
        const payload = {
            "echo": reqObj.echo,
            "cha": reqObj.cha,
            "cur": reqObj.cur,
            "adt": reqObj.adt,
            "chd": reqObj.chd,
            "inf": reqObj.inf,
            "cid": reqObj.cid,
            "sty": 'split',
            "tt": reqObj.tt,
            "tid": reqObj.tid,
            "bid": context.logindata.bid,
            "butype": context.logindata.butype,
            "pcid": context.logindata.cid,
            "mappings": owMappinng,
            "office_id": ofcid,
            "origns": [{
                "srcorigns": [owFinalObj.srcorigns[0].sup==="0"?convertFareReq(owFinalObj.srcorigns[0], owFinalObj, 1):convertTFFareReq(owFinalObj.srcorigns[0],1)],
                "destorigins": [rtFinalObj.srcorigns[0].sup==="0"?convertFareReq(rtFinalObj.srcorigns[0], rtFinalObj, 2):convertTFFareReq(rtFinalObj.srcorigns[0],2)]
            }],
            "psngrs": (!reqObj.paxChanged && reqObj !== undefined && reqObj.psngrs !== undefined) ? reqObj.psngrs : [],
            "flowType": "DIRECT",
            "src_country":reqObj.src!==undefined && reqObj.src.length!==0 && reqObj.src[0].countryCode,
            "dest_country":reqObj.dest!==undefined && reqObj.dest.length!==0 && reqObj.dest[0].countryCode,
            "src_code":reqObj.srcCode,
            "dest_code":reqObj.destCode,
            "region_type":props.regionType,
            "ow_gst":owFinalObj.gst!==undefined?owFinalObj.gst:"",
            "rt_gst":rtFinalObj.gst!==undefined?rtFinalObj.gst:"",
        }
        // if(state.srcorigns.sup==="5") {
        //     src_origns = flt.srcorigns.filter(s => s.tid == state.srcorigns.tid)
        //   }
           if(owFinalObj.sup && owFinalObj.sup == 3 && rtFinalObj.sup && rtFinalObj.sup == 3) {
            payload.ugk = owFinalObj.ugk
            payload.sup_currency = owFinalObj.sup_currency
            payload.return_ugk = rtFinalObj.ugk
            payload.return_sup_currency = rtFinalObj.sup_currency
        } else  if(owFinalObj.sup && owFinalObj.sup == 3) {
            payload.ugk = owFinalObj.ugk
            payload.sup_currency = owFinalObj.sup_currency
        } else if(rtFinalObj.sup && rtFinalObj.sup == 3) {
            payload.ugk = rtFinalObj.ugk
            payload.sup_currency = rtFinalObj.sup_currency
        }
        reqObj.sty="split";
        history.push({
            pathname: '/ezytrip/bookings/flight/itinerary',
            state: {
                req: payload,
                searchReq:reqObj
            }
        });
        //return payload;
    }

    const convertFareReq = (selectedFlt, price, ref) => {
        let bag=[];
        if(selectedFlt.bgd!==undefined && selectedFlt.bgd.length!==0){
          bag=selectedFlt.bgd;
          bag[0].type="Checkin";
          const req={
            "qun": "7",
            "wt": "kg",
            "type":"Cabin"
          }
          bag.push(req)
        }
        const payload = {
            "tid": selectedFlt.tid,
            "ref": ref,
            "office_id": selectedFlt.office_id!==undefined?selectedFlt.office_id:"",
            "sdur": selectedFlt.sdur,
            "re": selectedFlt.re,
            "stop": selectedFlt.stop,
            "sup": selectedFlt.sup,
            "cl": selectedFlt.cl,
            "ft": selectedFlt.ft,
            "tktair": selectedFlt.tktair,
            "tecst": selectedFlt.tecst,
            "seg": selectedFlt.seg,
            "bgd": bag,
            "pr": price.pr,
            "paxpr": price.paxpr,
            "serviceFee": price.servicefee
        }
        return payload;
    }

    const createBaggage=(qant1,type1,qant2,type2)=>{
        let bag=[];
        const req1={
          "qun": qant1,
          "wt": "kg",
          "type":type1
        }
        bag.push(req1);
        const req2={
          "qun": qant2,
          "wt": "kg",
          "type":type2
        }
        bag.push(req2);
        return bag;
      }

    //Convert Travel Fusion Object
  const convertTFFareReq = (selectedFlt,ref) => {
    let selFareObj = selectedFlt.fareop.filter((obj) => obj.selected);
    let bag=[];
    var check_qun="";
    var cabin_qun="";
    if(selectedFlt.sup==="4"){
      if(selFareObj[0].pr.ftyp==="Regular - R" || selFareObj[0].pr.ftyp==="Flexi - J"){
        if(props.regionType==="Domestic"){
          check_qun="15";
        }else if(props.regionType==="International"){
        if((reqObj.srcCode==="JED" && reqObj.dest.length!==0 && reqObj.dest[0].countryCode==="IN") || (reqObj.src.length!==0 && reqObj.src[0].countryCode==="IN" && reqObj.destCode==="JED")){
          check_qun="30";
        }else if(gccCodes.includes(reqObj.srcCode) || gccCodes.includes(reqObj.destCode)){
          check_qun="30";
        }else{
          check_qun="20";
        }
      }
        cabin_qun="7";
      }else if(selFareObj[0].pr.ftyp.includes("Corporate")){
        cabin_qun="10";
        if(props.regionType==="Domestic"){
          check_qun="15";
        }else{
          check_qun="30";
        }
      }else{
        check_qun="30";
        cabin_qun="7";
      }
      bag=createBaggage(check_qun,"Checkin",cabin_qun,"Cabin");
    }else{ // Setting Check in baggage
        if(selFareObj[0].pr.cabb!==undefined){
          if(selFareObj[0].pr.cabb.includes("Chargable") || selFareObj[0].pr.cabb.includes("Chargeable")){
           check_qun="0";
          }else{
           if(selFareObj[0].pr.cabb.includes("Free"||"free")){
             if(selFareObj[0].pr.bagage_info!==undefined){
             if(selFareObj[0].pr.bagage_info.includes("20kg")){
               check_qun="20";
             }else if(selFareObj[0].pr.bagage_info.includes("30kg")){
               check_qun="30";
             }else if(selFareObj[0].pr.bagage_info.includes("40kg")){
               check_qun="40";
             }
           }
           }else if(selFareObj[0].pr.cabb.includes("20 Kg")){
             check_qun="20";
           }else if(selFareObj[0].pr.cabb.includes("30 Kg")){
             check_qun="30";
           }else if(selFareObj[0].pr.cabb.includes("40 Kg")){
             check_qun="40";
           }
          }
        }
        if(selFareObj[0].pr.chekb !==undefined){// Setting Cabin baggage
         if(selFareObj[0].pr.chekb.includes("Chargable") || selFareObj[0].pr.chekb.includes("Chargeable")){
           cabin_qun="0";
         }else{
          if(selFareObj[0].pr.chekb.includes("7")){
           cabin_qun="7";
          }else if(selFareObj[0].pr.chekb.includes("10")){
           cabin_qun="10";
          }else if(selFareObj[0].pr.chekb.includes("14")){
            cabin_qun="14";
           }
         }
       }
       bag=createBaggage(check_qun,"Checkin",cabin_qun,"Cabin");
      }
    const payload = {
      "tid": selectedFlt.tid,
      "routeId":selFareObj[0].routeId,
      "prouteId":selectedFlt.prouteId,
      "ref": ref,
      "indigo_ugkey":selectedFlt && selectedFlt.indigo_ugkey?selectedFlt.indigo_ugkey:"",
      "sdur": selectedFlt.sdur,
      "re": selectedFlt.re,
      "stop": selectedFlt.stop,
      "sup": selectedFlt.sup,
      "cl": selectedFlt.cl,
      "ft": selectedFlt.ft,
      "tktair": selectedFlt.tktair,
      "tecst": selectedFlt.tecst,
      "seg": selectedFlt.seg,
      "bgd": bag,
      "pr": selFareObj[0].pr,
      "paxpr": selFareObj[0].paxpr,
      "serviceFee": selFareObj[0].servicefee,
      "dealcode":selectedFlt.dealcode,
      "transId":selectedFlt.transId,
      "jsessionId":selectedFlt.jsessionId,
      "fareKey":selFareObj[0].fareKey
    }
    if(selectedFlt.token) {
        payload.token = selectedFlt.token
        payload.extra = selectedFlt.extra ? selectedFlt.extra : ""
      }
    if(selectedFlt.journeyKey) {
        payload.journeyKey = selectedFlt.journeyKey
      }
      if(selFareObj[0].aj_fare_key) {
        payload.aj_fare_key = selFareObj[0].aj_fare_key
      }
      if(selFareObj[0].promotion_code) {
        payload.promotion_code = selFareObj[0].promotion_code
      }
    return payload;
  }


    const openSortList = (e) => {
        if (sortList) {
            setSortList(false)
        } else {
            setSortList(true)
        }
    }
    return (
        <>
            <div className="selectedRoutes mb-4">
                <p className="ezyIcon icon-flight">  Your Selection</p>
                <Row>
                    <Col lg={9} md={12}>
                        <Row>
                            <Col lg={6} md={12} className="d-flex owned justify-content-between align-items-center">
                                <div className="logopath">
                                    <img
                                        src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + onward.seg[0].ma + '.png'}
                                        className="logo"
                                        width="30"
                                        alt="AirLine" />
                                </div>
                                <div className="d-flex flex-column">
                                    <span><AirlinesMemo code={onward.seg[0].ma} /></span>
                                    <span>{onward.seg[0].ma} - {onward.seg[0].fn}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <strong> {onward.seg[0].dd.split('T')[1].substring(0, onward.seg[0].dd.split('T')[1].length - 3)}</strong>
                                    <strong>{onward.seg[0].da}</strong>
                                </div>
                                <div className="ezyIcon icon-flight"></div>
                                <div className="d-flex flex-column">
                                    <strong> {onward.seg[(onward.seg.length - 1)].ad.split('T')[1].substring(0, onward.seg[(onward.seg.length - 1)].ad.split('T')[1].length - 3)}</strong>
                                    <strong>{onward.seg[(onward.seg.length - 1)].ar}</strong>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{onward.sdur.split(":")[0] + " hrs " + onward.sdur.split(":")[1] + " mins"}</span>
                                    <span>{onward.seg.length - 1 === 0 ? 'Non ' : (onward.seg.length - 1)} Stop</span>
                                </div>

                            </Col>
                            <Col lg={6} md={12} className="return d-flex justify-content-between align-items-center">
                            <div className="logopath">
                                    <img
                                        src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + arrival.seg[0].ma + '.png'}
                                        className="logo"
                                        width="30"
                                        alt="AirLine" />
                                </div>
                                <div className="d-flex flex-column">
                                    <span><AirlinesMemo code={arrival.seg[0].ma} /></span>
                                    <span>{arrival.seg[0].ma} - {arrival.seg[0].fn}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <strong> {arrival.seg[0].dd.split('T')[1].substring(0, arrival.seg[0].dd.split('T')[1].length - 3)}</strong>
                                    <strong>{arrival.seg[0].da}</strong>
                                </div>
                                <div className="ezyIcon icon-flight"></div>
                                <div className="d-flex flex-column">
                                    <strong> {arrival.seg[(arrival.seg.length - 1)].ad.split('T')[1].substring(0, arrival.seg[(arrival.seg.length - 1)].ad.split('T')[1].length - 3)}</strong>
                                    <strong>{arrival.seg[(arrival.seg.length - 1)].ar}</strong>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{arrival.sdur.split(":")[0] + " hrs " + arrival.sdur.split(":")[1] + " mins"}</span>
                                    <span>{arrival.seg.length - 1 === 0 ? 'Non ' : (arrival.seg.length - 1)} Stop</span>
                                </div></Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={12} className=" price d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <strong><em className="priceTag">{cur}</em> {<FormateCurrency value={Utilities.addPrice(onwardPrice.tf + Number(props.onwardService) + Number(props.onMarkp), arrivalPrice.tf + Number(props.retrnService) + onwardGST + returnGST, "Add")}/>} </strong>
                            <span>(For {travles} Person)</span>
                        </div>
                        <Button
                            onClick={fireFareRuleReq}
                            size="md"
                            className="d-flex"
                            variant="primary"
                        >BOOK NOW</Button></Col>

                </Row>
            </div>
        </>
    )
}


export default SelectedPackage;
