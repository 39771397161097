
import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Alert, Col, Row } from "react-bootstrap";
import DateUtils from '../../../../commonUtils/DateUtils';
import { Context } from "../../../../../../App";

/**
 * Initial State Declaration
 */
const initialState = {
    price_data: '',
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

function TransactionSummary(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);

    useEffect(() => {
    }, [])


const openSummary = ()=>{
    window.open("/ezytrip/dashboard/hotel/bookingsummary/"+ props.bookDetails.booking_id) //to open new page
}
    return (
        <>


            <Row>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Booking Ref ID : </span> <span onClick =  {openSummary}>{props.bookDetails.booking_id}</span></Col>
                        {/*   <Col xs={12}><span>Booking Date:</span> 16 Jun, 2020   3:50</Col> */}
                        <Col xs={12}><span>Booking Date : </span> <span>{DateUtils.prettyDate(props.bookDetails.bd, "DD MMM, YYYY")}</span></Col>
                        <Col xs={12}><span>Email  : </span>{props.bookDetails.roompax[0].pax[0].em}</Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                    <Col xs={12}><span>Booking Status : </span> <span className={props.bookDetails.st ==="On hold" ? 'text-primary' : 'text-success' }>{props.bookDetails.st}</span></Col>
                        <Col xs={12}><span>Channel : </span> Online</Col>

                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Customer Name : </span> {(props.bookDetails.roompax[0].pax[0].fn) + ' ' + (props.bookDetails.roompax[0].pax[0].ln)}</Col>
                        <Col xs={12}><span>Phone  : </span>{props.bookDetails.roompax[0].pax[0].phc +" "+ props.bookDetails.roompax[0].pax[0].phn} </Col>

                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Agency : </span> {props.bookDetails.cname}</Col>
                        <Col xs={12}><span>Booked By : </span> {context.logindata.nm}</Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}


export default TransactionSummary;
