import React, { Component } from "react";
import PDFDemo from './PDFDemo';
const TestPage = props => {
  return (

    <div className="container confirmation">
      <PDFDemo/>
 </div>
    );
};

export default TestPage;