
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
//Main Function
function ReturnHeaderLoader(props) {
    const [state, setState] = useState({})



    return (

        <>
            <div className="rs-headerBlock mt-3">
                <Row className="align-items-center rs-row">
                    <Col xs={4} className="rs-rTypeDate">
                        <span className="departure arrival ezyIcon icon-flight">  Arrival</span>
                        <div class="fltDtLoader"></div>
                    </Col>
                    <Col xs={8} className="rs-FromTo">
                        <Row className="align-items-center">
                            <Col xs={5} className="d-flex flex-column">
                                <div class="depArvLoader"></div>
                            </Col>
                            <Col xs="1" className="iconFlight pl-0">
                                <span className="ezyIcon icon-flight"></span></Col>
                            <Col className="d-flex flex-column">
                                <div class="depArvLoader"></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

        </>
    )
}


export default ReturnHeaderLoader;
