
import React, { useEffect, useState } from 'react';
import { Col, Form, Table } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Header from '../../../common/Header';
import MainMenu from '../../../common/MainMenu';
import PickList from '../../../common/pickList/PickList';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
let airlineMaster = require('../../../masterData/AirlinesMasterData.json');

//This function will create the air markup defination and some other details.
function TASTAMarkupConfiguration() {
  const history = useHistory();
  const [routDetails, setRoutDetails,] = useState(false);
  const [passengerType, setPassengerType] = useState();
  const [addMarkUp, setAddMarkUp,] = useState(false);
  const [dataState, setData] = useState({ data: [] });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [state, setState] = useState({
    createMarkup: false,
    markupName: "",
    isActive: false,
    flowTypeB: true,
    flowTypeC: false,
    flowTypeE: false,
    flowTypeV: false,
    travelType: "All",
    applicableType: "",
    items: [],
    target: [],
    airlinesData: [],
    selectedValue: "",
    openRout: false,

  })

  const airline = [{ label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 }]

  const booking = [{ label: "Booking", value: "booking" },
  { label: "Travel", value: "Travel" }]

  const applicableOn = [{ label: "Per Ticket", value: "per-Ticket" },
  { label: "Per Booking", value: "per-Booking" }]

  const markUpOn = [{ label: "Base Fare", value: "base-Fare" },
  { label: "Total Fare", value: "total-Fare" }]

  const discountOn = [{ label: "Base Fare", value: "base-Fare" },
  { label: "Total Fare", value: "total-Fare" }]

  const discountType = [{ label: "% Percentage", value: "%-Percentage" },
  { label: "Value", value: "value" }]

  const applicableTo = [{ label: "Branch", value: "branch" },
  { label: "Category", value: "category" }]

  const passengertype = [{ label: "Adult", value: "Adult" },
  { label: "Child", value: "Child" },
  { label: "Infant", value: "Infant" }]

  const flowType = [{ label: "Booking", value: "Booking" },
  { label: "Cancellation", value: "Cancellation" },
  { label: "Amendment", value: "Amendment" }]

  const travelType = [{ label: "All", value: "all" },
  { label: "One Way", value: "one-Way" },
  { label: "Round Trip", value: "round-Trip" },
  { label: "Multi City", value: "multi-City" }]

  const [roles, setRoles] = useState([
    { value: 1, label: 'Generic Role' },
    { value: 2, label: 'Default Role' },
    { value: 3, label: 'Flight Booking Role' }
  ]);
  const MarkUp = [{ label: "Percentage", value: "Percentage" },
  { label: "Value", value: "Value" }]
  const pickldata = (data) => {
    setData({ data: data })
  }
  const openroutBtnClick = () => {
    setRoutDetails(true)
  }
  useEffect(() => {
    setState({ airlinesData: airlineMaster })
  }, []);

  const addedMarkup = () => {
    if (addMarkUp) {
      setAddMarkUp(false)
    } else {
      setAddMarkUp(true)
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value
    }))
    if (e.target.id === "icalInput") {
      if (state.isActive) {
        setState({ isActive: false })
      } else {
        setState({ isActive: true })
      }
    }
  }





  const handleCancel = (e) => {
    return history.push("/ezytrip/administration/markup");
  }

  const onSelect = (selectedList, selectedItem) => {
  }

  const onRemove = (selectedList, removedItem) => {
  }

  return (
    <>


      <Header />

      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Create Markup" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Air Markup Configuration" />
          <div className="contentArea">

            <Form.Row>

              <Form.Group as={Col} xs={3} controlId="mn" className="d-flex align-items-center">
                <Form.Control type="text" placeholder="Enter Markup Name" value={state.mn} onChange={handleChange} />
                <Form.Label>Mark-up name <sup>*</sup></Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} className="selectplugin" controlId="icalInput" >
                <Select
                  multi
                  options={flowType}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />

                <Form.Label>Flow Type <sup>*</sup></Form.Label>

              </Form.Group>
              <Form.Group as={Col} className="text-right">
                <Form.Check
                  type="switch"
                  id="act"
                  label="Status"
                  defaultChecked value={state.act} onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>


            <Form.Row>
              <Form.Group as={Col} xs={3} controlId="icalInput" className="d-flex align-items-center">
                <Form.Control type="text" placeholder="From Country" autoComplete="off" value={state.markupName} onChange={handleChange} />
                <Form.Label>From Country</Form.Label>
              </Form.Group >
              <Form.Group as={Col} xs={3}>
                <Form.Control type="text" placeholder="To Country" autoComplete="off" value={state.markupName} onChange={handleChange} />
                <Form.Label>To Country</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={3}>
                <Form.Control as="select" multiple>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
                <Form.Label>Source Airport</Form.Label>
              </Form.Group >

              <Form.Group as={Col} xs={3}>
                <Form.Control as="select" multiple>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
                <Form.Label>Destination Airport</Form.Label>
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={openroutBtnClick}
                > Add</Button>
              </Form.Group>
            </Form.Row>
            {routDetails && <Form.Row>
              <Form.Group as={Col} xs={6}>
                <div className="selectedRout tableLayout">
                  <Table>
                    <thead>
                      <tr>
                        <th>Source - Destination</th>
                        <th width="8%">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>India,IN ( AMD ) --  Pakistan,PK ( KHI )</span>
                        </td>
                        <td>
                          <Button
                            size="xs"
                           variant="link" className="ezyIcon icon-delete">
                          </Button>
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </Form.Group>
            </Form.Row>}
            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  multi
                  options={airline.airlinesData}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Airlines</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={travelType}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Travel Type</Form.Label>
              </Form.Group>

            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} xs={3} controlId="fm">
                <Form.Control as="select" xs={2} value={state.tty} onChange={handleChange}>
                  <option value="">Select </option>
                  <option value="Travel">Travel</option>
                  <option value="Booking">Booking</option>
                </Form.Control>
                <Form.Label>Applicable Date</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={2} controlId="fm">
                <DatePicker
                  selected={fromDate}
                  onChange={date => setFromDate(date)}
                  fromDate={fromDate}
                  toDate={toDate}
                  minDate={new Date()}
                />
                 <span className="calendar ezyIcon icon-newcalendar"></span>
              </Form.Group>

              <Form.Group as={Col} xs={2} controlId="to">
                <DatePicker
                  selected={toDate}
                  onChange={date => setToDate(date) + 1}
                  fromDate={fromDate}
                  toDate={toDate}
                  minDate={fromDate}
                  className="datefield"
                />
                 <span className="calendar ezyIcon icon-newcalendar"></span>
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  size="xs"
                  variant="outline-primary"
                  loading={state.loadBranch}
                  type="submit"
                > Add</Button>
              </Form.Group>
            </Form.Row>




            {/* <Form.Row>
  <Form.Group as={Col} xs={2} controlId="markupName">
      <Form.Label>Airlines</Form.Label>
    </Form.Group>

    <Form.Group as={Col} xs={3} controlId="selectedValue"> */}

            {/* <DropdownMultiselect
options={state.airlinesData[0].airCode}
name="countries"
/> */}
            {/* <Multiselect
        options={state.airlinesData} // Options to display in the dropdown
        selectedValues={state.selectedValue} // Preselected value to persist in dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onRemove} // Function will trigger on remove event
        displayValue="airName" // Property name to display in the dropdown options
        groupBy="cat"
        showCheckbox={true}
      />
    </Form.Group>
  </Form.Row> */}

            <h4 className="subTitle plusHr">Profiles MarkUp</h4>

            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={applicableOn}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Applicable On <sup>*</sup></Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  multi
                  options={passengertype}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Passenger type</Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={markUpOn}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Mark-up On <sup>*</sup></Form.Label>
              </Form.Group>

            </Form.Row>


            {/* <Form.Row>
    <Form.Group as={Col} xs={2} className="d-flex align-items-center" controlId="mark-up-Type">
      <Form.Label>Mark-up Type <sup>*</sup></Form.Label>
    </Form.Group>
    <Form.Group as={Col} controlId="icalInput" className="d-flex align-items-center">
      <Form.Check type="radio" label="% Percentage" className="ml-0" name="Mark-up-Type" id="percentage" custom onChange={valuePlus}/>
      <Form.Check type="radio" label="Value" className="ml-3" name="Mark-up-Type" id="value" custom onChange={valuePlus} />
      <Form.Check type="radio" label="Value/Percentage" className="ml-3" name="Mark-up-Type" id="valuePercentage" custom  onChange={valuePer}/>

   </Form.Group>
  </Form.Row> */}

            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="icalInput">
                <Select
                  options={MarkUp}
                  searchable={false}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Mark-Up <sup>*</sup></Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} controlId="icalInput" className="d-flex align-items-center">
                <Form.Control type="text" placeholder="To Country" autoComplete="off" value={state.markupName} onChange={handleChange} />
                <Form.Label>Enter Valu</Form.Label>
              </Form.Group>

              <Col>
                <Button
                  size="xs"
                  variant="link"
                  onClick={addedMarkup}
                  className="iconBtn add ezyIcon icon-plus align-self-end mb-4"
                ></Button>
              </Col>

            </Form.Row>

            {addMarkUp && <>
              <Form.Row>
                <Form.Group as={Col} xs={3} controlId="icalInput" className="selectplugin">
                  <Select
                    options={MarkUp}
                    searchable={false}
                    values={[]}
                    onChange={(value) => console.log(value)}
                  />
                  <Form.Label>Mark-Up <sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="icalInput" className="d-flex align-items-center">
                  <Form.Control type="text" placeholder="To Country" autoComplete="off" value={state.markupName} onChange={handleChange} />
                  <Form.Label>Enter Valu</Form.Label>
                </Form.Group>
              </Form.Row>
            </>}

            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={discountOn}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Discount On</Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={discountType}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Discount Type</Form.Label>
              </Form.Group>

              <Form.Group as={Col} xs={3} controlId="icalInput" className="d-flex align-items-center">
                <Form.Control type="text" placeholder="To Country" value={state.markupName} onChange={handleChange} />
                <Form.Label>Discount</Form.Label>
              </Form.Group>
            </Form.Row>


            <hr />
            <Form.Row>
              <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  options={applicableTo}
                  values={[]}
                  onChange={(value) => console.log(value)}
                />
                <Form.Label>Applicable To</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="picklist" xs={7} >
                <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" dataList={roles} sendData={pickldata} />
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" dataList={roles} sendData={pickldata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>
            </Form.Row>

            <div className="tableLayout">
              <Table>
                <thead>
                  <tr>
                    <th>Mark-Up On</th>
                    <th>Mark-Up Type</th>
                    <th>Applicable On</th>
                    <th>Markup</th>
                    <th>Currency</th>
                    <th>Profile Type</th>
                    <th>Profile Name</th>
                    <th>GDS</th>
                    <th>OfficeID</th>
                    <th className="text-right" width="10%">Actions</th>
                    <th className="text-right" width="8%">Enable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>Base Fare</span>
                    </td>
                    <td>
                      <span>Percentage</span>
                    </td>
                    <td>
                      <span>Per Ticket</span>
                    </td>
                    <td>
                      <span>100 / 0 / 0</span>
                    </td>
                    <td>
                      <span></span>
                    </td>
                    <td>
                      <span>CT Operation</span>
                    </td>
                    <td>
                      <span>CTOPT</span>
                    </td>
                    <td>
                      <span>AMADEUS</span>
                    </td>
                    <td>
                      <span>DXBDN3140</span>
                    </td>
                    <td className="text-right">
                      <Button
                        size="xs"
                        variant="link"
                        className="ezyIcon icon-edit"
                        type="submit">
                      </Button>
                      <Button
                        size="xs"
                        variant="link"
                        className="ezyIcon icon-delete"
                        type="submit">
                      </Button>
                    </td>
                    <td className="text-right">
                      <Form.Check type="checkbox" label="" custom className="ml-0" id="economy" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Base Fare</span>
                    </td>
                    <td>
                      <span>Percentage</span>
                    </td>
                    <td>
                      <span>Per Ticket</span>
                    </td>
                    <td>
                      <span>100 / 0 / 0</span>
                    </td>
                    <td>
                      <span></span>
                    </td>
                    <td>
                      <span>CT Operation</span>
                    </td>
                    <td>
                      <span>CTOPT</span>
                    </td>
                    <td>
                      <span>AMADEUS</span>
                    </td>
                    <td>
                      <span>DXBDN3140</span>
                    </td>
                    <td className="text-right">
                      <Button
                        size="xs"
                        variant="link"
                        className="ezyIcon icon-edit"
                        type="submit">
                      </Button>
                      <Button
                        size="xs"
                        variant="link"
                        className="ezyIcon icon-delete"
                        type="submit">
                      </Button>
                    </td>
                    <td className="text-right">
                      <Form.Check type="checkbox" label="" custom className="ml-0" id="economy" />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="text-right buttonGrop mt-4 pb-0">
              <Button
                size="xs"
                variant="outline-dark"
                type="submit"
              > Cancel</Button>

              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
              >Save</Button>


            </div>


          </div>
        </div>
      </div>

    </>
  )
}
export default TASTAMarkupConfiguration