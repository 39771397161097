
import React, { useContext, useEffect, useState } from 'react';
import Tabs from '../../components/common/tabs/Tabs';
import ManualPay from './ManualPay';

/**
 * @description:This function will fire the Book request and Process the Payment
 * @author: Lakshmi
 * @param {*}
 * @function Payment details
 * @date : 03-09-2020
 */

function ManualPaymentForm(props) {
    const [state, setState] = useState({})

    const [payment, setPayment] = useState({
        active: 'confirm'
    });

    const confirmBooking=(obj)=>{
     props.callBooking(obj);
    }

    const content = {
        confirm: <ManualPay payobj={props.payobj} callBooking={confirmBooking} />,
        // offline: <>{props.enableOffline && context.logindata.roles.includes('O_P_E') && <OfflinePay bdata={props.bookingdata} product={"Flight"} flowType={"Manual"} />}</>
    };

    return (
        <>
            <div className="paymentSec boxLayout" ref={props.refProp}>
                <h5 className="panelTitle ezyIcon icon-payment mb-4"> Payment Details</h5>
                <div className="tabLink d-flex">
                    <Tabs active={payment.active} onChange={active => setPayment({ active })}>
                        <span key="confirm" className="ezyIcon icon-confirm"> CONFIRM</span>
                        {/* {props.enableOffline && context.logindata.roles.includes('O_P_E') && <span key="offline" className="ezyIcon icon-offline"> OFFLINE PAY</span>} */}
                    </Tabs>
                    <div className="tabContent">
                        {content[payment.active]}
                    </div>
                </div>
            </div>
        </>
    )
}


export default ManualPaymentForm;
