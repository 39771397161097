import React, { Component, useState } from "react";
import InsHomeSearch from "./InsHomeSearch";
function InsuranceSearchFun(props) {
  const { state } = props || {};

  const [flightSearch, setFlightSearch] = useState({
    active: "FlightTab",
  });

  const content = {
    FlightTab:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    OperationsTab:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  };

  return (
    <>
      <div className="searchTab InsuranceSearchPart">
        <InsHomeSearch
          tabs={props.tabs}
          clickedTabs={(active) => props.clickedTabs(active)}
        />
      </div>
    </>
  );
}

class InsuranceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <InsuranceSearchFun
        state={this.state}
        tabs={this.props.tabs}
        clickedTabs={(active) => this.props.clickedTabs(active)}
      />
    );
  }
}

export default InsuranceSearch;
