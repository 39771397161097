import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button'
import { useHistory } from 'react-router-dom';
import Expire from '../../../assets/images/Session-Expired.gif'
/**
 * @description:This function will work for Session time out .
 * @author: Pravas
 * @param {*}
 * @function SessionTimeOut
 * @date : 01-09-2020
 */

function SessionTimeOut(props) {
  const history = useHistory();

  const gotoResults=()=>{
    props.sendToResult("Results");
  }

  const countinue=()=>{
    props.sendToReview("Review");
  }
  return (
    <>
          <div className="layover">
            <div className="popUpBox sessionTimeOut">

              <div className="backGroundgif">
              <span className='backGroundbot'></span>
              <img src={Expire} />
            </div>
              <strong>Session has expired !</strong>
              <p>Price or availability may have changed. Refresh the screen to check it again.</p>
              <div className="d-flex seccionBtn">
              <Button onClick={() => {
                gotoResults();
              }}
                size="md"
                variant="secondary"
              >Go Back</Button>
              <Button onClick={() => {
                countinue();
              }}
                size="md"
                variant="primary"
              >CONTINUE BOOKING</Button>
</div>
            </div>
          </div>

    </>
  )
}
export default SessionTimeOut