import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
// const API_URL = "http://localhost:3005"

class serviceFeeConf {
    async saveServiceFee(markpData) {
        return await axios.post(`${API_URL}/att/servicefee/save`,markpData)
    }

    async retrieveServiceFee(id) {
        return await axios.get(`${API_URL}/att/servicefee/retrieve`)
    }

    async editServiceFee(id) {
        return await axios.get(`${API_URL}/att/servicefee/edit/${id}`)
    }

    async deleteRoute(data) {
        console.log('routesData ', data)
        return await axios({ method: "DELETE", url: `${API_URL}/att/servicefee/delete_routes`, data: data });
        // return await axios.delete(`${API_URL}/att/servicefee/delete_routes`, data)
    }

    async deleteAppcableDate(id) {
        return await axios.delete(`${API_URL}/att/servicefee/delete_applicable/${id}`)
    }

    async retriveFMDetails(id) {
        return await axios.get(`${API_URL}/att/servicefee/edit_fare_map/${id}`)
    }

    async deleteFareMap(id) {
        return await axios.delete(`${API_URL}/att/servicefee/delete_fare_map/${id}`)
    }

    async deletePaxServiceFee(id) {
        let data = {}
        return await axios.delete(`${API_URL}/att/servicefee/delete_pax_map/${id}`, data)
    }

    async update(updata) {
        console.log('updata',updata)
        return await axios.put(`${API_URL}/att/servicefee/update/${updata.srvcreq.id}`, updata)
    }

    async updateFareMap(id,updata) {
        console.log('updata for FM',updata)
        return await axios.put(`${API_URL}/att/servicefee/update_fare_map/${id}`, updata)
    }

    async searchServiceFee(obj) {
        console.log('get service fee req:', obj)
        return await axios.post(`${API_URL}/att/servicefee/get_service_fees`, obj)
    }
}

export default new serviceFeeConf()