import SalesChannel from "../../../adminstration/common/SalesChannel";
import React, { useState, useEffect , useReducer , useContext } from 'react';
import Button from '../../../../components/common/buttons/Button';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';
import {talist,saveCommDisc,getCompanyList,updateDisc,delCompDisc} from './operations';
import PickList from '../../../../components/common/pickList/PickList';
import Select from 'react-dropdown-select';
import DatePicker from 'react-datepicker';
import log from "loglevel";
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import RegionType from '../../../adminstration/common/RegionType';
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import 'react-datepicker/dist/react-datepicker.css';

const initialState = {
mn: "",disc_name:"", disc_val:"", htlsuppliers:[{value:"0",label:"Expedia"}, {value:"1",label:"Otilla"}],htlSupNames:[],comp_list:[],enableUpdate:false,taMenu:[],
compData:[],selCompanyList:[],showCompList:[],sel_comp_id:0,selCompany:"",selBranch:[],branchList:[],region_type:"",region_type_edit:"",regionError:false,branchError:false,isItineraryLoading:false,sales_chanel:"",edit_salesChanel:""
}

const reducer = (state, action) => {

switch (action.type) {
    case "clear":
    return {...state,disc_name:"", htlsuppliers:[{value:"0",label:"Expedia"}, {value:"1",label:"Otilla"}]}
    default:
    return { ...state, [action.type]: action.payload };
}
};
//This function will create the air markup defination and some other details.
function CreateDisc(props) {
const [state, dispatch] = useReducer(reducer, initialState);
const history = useHistory();
const [fromDate, setFromDate] = useState(null);
const [toDate, setToDate] = useState(null);
const [data, setData] = useState([])
const [context, setContext] = useContext(Context);
const [act, setAct] = useState(true);


useEffect(() => {
    if(props.enableEidt){
    dispatch({ type: 'disc_name', payload: props.obj.disc_name });
    setFromDate(new Date(props.obj.from_dt));
    setToDate(new Date(props.obj.to_dt));
    dispatch({ type: 'enableUpdate', payload: true });
    setAct(props.obj.st)
    dispatch({ type: 'region_type', payload: props.obj.region_type });
    dispatch({ type: 'region_type_edit', payload: props.obj.region_type });
    dispatch({ type: 'edit_salesChanel', payload: props.obj.sales_channel });
    dispatch({ type: 'selBranch', payload: [{ "value": props.obj.branch_id, "label": props.obj.branch_name }] })
    if(props.obj.sup!=="" && props.obj.sup!==undefined){
       if(props.obj.sup.includes(",")){
            dispatch({ type: 'htlSupNames', payload: [{value:"0",label:"Expedia"}, {value:"1",label:"Otilla"}] });
        }else if(props.obj.sup==="0"){
            dispatch({ type: 'htlSupNames', payload: [{ "value": "0", "label": "Expedia" }] })
        }else if(props.obj.sup==="1"){
            dispatch({ type: 'htlSupNames', payload: [{ "value": "1", "label": "Otilla" }] })
        }
    }
    getCompanyList(props.obj.id).then((res) => {
      dispatch({ type: 'showCompList', payload: res })
    });

    }
}, [props.enableEidt]);

useEffect(()=>{
  if(state.enableDevices && state.sales_chanel==="B2C" && state.region_type.length>0){
    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
    AttCommonSerivce.att_companies(pyload).then(response => {
      let authData = []
      if (response.data.suc && response.data.res && response.data.res.data) {

        for (let val of response.data.res.data) {
          let auth = {
            "value": val.value,
            "label": val.label,
            "sales_channel":val.sales_channel
          }
          authData.push(auth)
        }
        // setCompanyPick(authData)
        let companyList = authData.filter((obj)=>obj.sales_channel===state.sales_chanel)
        dispatch({ type: "taMenu", payload: companyList });
        return
      } else {
        dispatch({ type: "taMenu", payload: [] });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });

  }
},[state.enableDevices , state.sales_chanel,state.region_type])
const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'act') {
        setAct(e.target.checked)
    }
    dispatch({ type: id, payload: value });
}

const updateData = (data) => {
    dispatch({ type: 'compData', payload: data })
  }

  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const validateDiscount=()=>{
      if(state.disc_name===""){
        dispatch({ type: 'notiMessage', payload: "Enter Discount Name" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(fromDate===null || fromDate===undefined){
        dispatch({ type: 'notiMessage', payload: "Select From Date" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(toDate===null || toDate===undefined){
        dispatch({ type: 'notiMessage', payload: "Select To Date" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.htlSupNames.length===0){
        dispatch({ type: 'notiMessage', payload: "Please select Supplier" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.region_type===""){
        dispatch({ type: 'notiMessage', payload: "Please select Region" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.selBranch.length===0 && state.sales_chanel=="B2B"){
        dispatch({ type: 'notiMessage', payload: "Please select Branch" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }else if(state.showCompList.length===0){
        dispatch({ type: 'notiMessage', payload: "Please select atleast one Company and proceed" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        return false;
      }
      return true;
  }

 const createDiscount=()=>{
     if(validateDiscount()){

        let supplier="";
        for(let sup of state.htlSupNames){
           if(supplier===""){
            supplier=sup.value;
           }else{
            supplier=supplier+","+sup.value;
           }
        }
        dispatch({ type: "isItineraryLoading", payload: true });
       const req={
        "disc_name": state.disc_name,
        "sup": supplier,
        "from_dt":DateUtils.convertStringToDate(fromDate),
        "to_dt": DateUtils.convertStringToDate(toDate),
        "st":act,
        "crby":context.logindata.uid,
        "comp_disc" :state.showCompList,
        "region_type":context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
        "branch_id":state.selBranch.length!=0?state.selBranch[0].value:"",
        "branch_name":state.selBranch.length!=0? state.selBranch[0].label:"",
        "isDeviceAll":state.isDeviceAll,
        "isB2cWeb":state.isDeviceAll?true:state.isB2cWeb,
        "isB2cApp":state.isDeviceAll?true:state.isB2cApp,
        "isMWeb":state.isDeviceAll?true:state.isMWeb,
        "sales_channel":state.sales_chanel
     }
     saveCommDisc(req).then(response => {
        if(response.suc){
        dispatch({ type: 'notiMessage', payload: "Created Commission Discount successfully..." })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        setTimeout(function () {
          return window.location="/ezytrip/administration/hotelServices/commdisc";
        }, 3000);
        }else if(!response.suc && response.status===404){
          dispatch({ type: "isItineraryLoading", payload: false });
        dispatch({ type: 'notiMessage', payload: "Something went wrong.Unable to create Commision Dicount" })
        dispatch({ type: 'notiVarient', payload: "danger" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
        }else if(!response.suc && response.status===406){
          dispatch({ type: "isItineraryLoading", payload: false });
          dispatch({ type: 'notiMessage', payload: "Commission Disount already Exist.Please check" })
          dispatch({ type: 'notiVarient', payload: "danger" })
          dispatch({ type: 'notiMessageShow', payload: true })
          hidemessage()
        }
        })
    }
 }

 const updateCommDisc=()=>{
  if(validateDiscount()){

    let supplier="";
    for(let sup of state.htlSupNames){
       if(supplier===""){
        supplier=sup.value;
       }else{
        supplier=supplier+","+sup.value;
       }
    }
    dispatch({ type: "isItineraryLoading", payload: true });
   const req={
    "id":props.obj.id,
    "disc_name": state.disc_name,
    "sup": supplier,
    "from_dt":DateUtils.convertStringToDate(fromDate),
    "to_dt": DateUtils.convertStringToDate(toDate),
    "st":act,
    "upby":context.logindata.uid,
    "comp_disc" :state.showCompList,
    "region_type":state.region_type,
    "branch_id":state.selBranch.length!=0?state.selBranch[0].value:"",
    "branch_name":state.selBranch.length!=0? state.selBranch[0].label:"",
    "sales_channel":state.sales_chanel
 }
 updateDisc(req).then(response => {
    if (response.suc) {
    dispatch({ type: 'notiMessage', payload: "Updated Commission Discount successfully..." })
    dispatch({ type: 'notiVarient', payload: "success" })
    dispatch({ type: 'notiMessageShow', payload: true })
    setTimeout(function () {
      return window.location="/ezytrip/administration/hotelServices/commdisc";
    }, 3000);
    }else if(!response.suc && response.status===404){
    dispatch({ type: 'notiMessage', payload: "Something went wrong.Unable to update Commision Dicount" })
    dispatch({ type: 'notiVarient', payload: "danger" })
    dispatch({ type: 'notiMessageShow', payload: true })
    hidemessage()
    }else if(!response.suc && response.status===406){
      dispatch({ type: "isItineraryLoading", payload: false });
      dispatch({ type: 'notiMessage', payload: "Commission Disount already Exist.Please check" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
    }
    })
}
}

const updateSalesChanel=(salesChanel)=>{
  dispatch({ type: "sales_chanel", payload: salesChanel });
  if(salesChanel==="B2C"){
    dispatch({ type: "enableDevices", payload: true });
  }else{
    dispatch({ type: "enableDevices", payload: false });
  }
  //dispatch({ type: 'officeIds', payload: [] })
  // setCompanyPick([])
  // props.sendChannel(salesChanel)
}
// const handleDeviceTypeChange = (e) => {
//   debugger
//   props.DeviceType(e)
// }
const handleDeviceTypeChange = (e) => {
  if (e.target.id === "deviceAll"){
      dispatch({ type: 'isB2cWeb', payload: false })
      dispatch({ type: 'isB2cApp', payload: false })
      dispatch({ type: 'isMWeb', payload: false })
    if(state.isDeviceAll){
      dispatch({ type: 'isDeviceAll', payload: false })
    }else{
      dispatch({ type: 'isDeviceAll', payload: true })
    }
  }else if(e.target.id === "web"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.web){
      dispatch({ type: 'isB2cWeb', payload: false })
    }else{
      dispatch({ type: 'isB2cWeb', payload: true })
      if(state.isB2cApp && state.isMWeb){
        updateStateVal();
      }
    }
  }else if(e.target.id === "app"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.app){
      dispatch({ type: 'isB2cApp', payload: false })
    }else{
      dispatch({ type: 'isB2cApp', payload: true })
      if(state.isB2cWeb && state.isMWeb){
        updateStateVal();
      }
    }
  }else if(e.target.id === "mweb"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.mweb){
      dispatch({ type: 'isMWeb', payload: false })
    }else{
      dispatch({ type: 'isMWeb', payload: true })
      if(state.isB2cWeb && state.isB2cApp){
        updateStateVal();
      }
    }
  }
// props.updateProps(state.isDeviceAll,state.isB2cWeb,state.isB2cApp,state.isMWeb)
}

const updateStateVal=()=>{
  dispatch({ type: 'isDeviceAll', payload: true })
  dispatch({ type: 'isB2cWeb', payload: false })
  dispatch({ type: 'isB2cApp', payload: false })
  dispatch({ type: 'isMWeb', payload: false })
}

 const validateDiscountVal=()=>{
    if(state.disc_val==="" || state.disc_val==="0"){
      dispatch({ type: 'notiMessage', payload: "Enter Discount Value" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
      return false;
    }else  if(state.disc_val!=="" && Number(state.disc_val)<0){
      dispatch({ type: 'notiMessage', payload: "Enter proper Discount Value" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
      return false;
    }else if(state.compData.length===0){
      dispatch({ type: 'notiMessage', payload: "Please select atleat one Company" })
      dispatch({ type: 'notiVarient', payload: "danger" })
      dispatch({ type: 'notiMessageShow', payload: true })
      hidemessage()
      return false;
    }else if(state.compData.length!==0){
      for(let comp of state.compData){
        if(state.showCompList.length!==0){
          let list = state.showCompList.filter((com)=>com.value===comp.value);
          if(list.length!==0){
            dispatch({ type: 'notiMessage', payload: "Already Exist " +comp.label +" Company. Please check" })
            dispatch({ type: 'notiVarient', payload: "danger" })
            dispatch({ type: 'notiMessageShow', payload: true })
            hidemessage()
            return false;
          }
        }
      }
    }
    return true;
}


 const createCompList=()=>{
     if(validateDiscountVal()){
         let compsList=[];
       for(let comp of state.compData){
            const obj={
                "disc_val":state.disc_val,
                "label" :comp.label,
                "value":comp.value
            }
            compsList.push(obj);
       }
       if(state.showCompList.length===0){
        dispatch({ type: 'showCompList', payload: compsList })
       }else{
        dispatch({ type: 'showCompList', payload: state.showCompList.concat(compsList) })
       }
       dispatch({ type: 'region_type_edit', payload: state.region_type })
       setData([])
       dispatch({ type: 'compData', payload: [] })
       dispatch({ type: 'disc_val', payload: "" })

     }
 }

 const editDisc=(obj) => () => {
  dispatch({ type: 'selCompany', payload: obj.label })
  dispatch({ type: 'sel_comp_id', payload: obj.value })
  dispatch({ type: 'disc_val', payload: Number(obj.disc_val) })

 }

 const deleteDisc = (obj, idx) => () => {
    if (obj.id !== "" && obj.id !== undefined) {
      confirmBranchDlg(obj, 'Discount', function () {
        delCompDisc(obj.id).then(response => {
          if (response.suc) {
            dispatch({ type: 'showCompList', payload: state.showCompList.filter((s, sidx) => idx !== sidx) })
            dispatch({ type: 'notiMessage', payload: "Discount deleted successfully..." })
            dispatch({ type: 'notiVarient', payload: "success" })
            dispatch({ type: 'notiMessageShow', payload: true })
            hidemessage()
            let list = state.showCompList.filter((s, sidx) => idx !== sidx);
            if(list.length===0){
              dispatch({ type: 'region_type_edit', payload: "" })
            }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
        dispatch({ type: 'showCompList', payload: state.showCompList.filter((s, sidx) => idx !== sidx) })
        dispatch({ type: 'showCompList', payload: state.showCompList.filter((s, sidx) => idx !== sidx) })
        dispatch({ type: 'notiMessage', payload: "Discount deleted successfully..." })
        dispatch({ type: 'notiVarient', payload: "success" })
        dispatch({ type: 'notiMessageShow', payload: true })
        hidemessage()
    }
  }


const handleCancel = (e) => {
return window.location="/ezytrip/administration/hotelServices/commdisc";
}

const cancelEdit=()=>{
  dispatch({ type: 'sel_comp_id', payload: 0 })
  dispatch({ type: 'disc_val', payload: ""})
  dispatch({ type: 'selCompany', payload: "" })
}

const validate=()=>{
  if(state.disc_val==="" || state.disc_val==="0"){
    dispatch({ type: 'notiMessage', payload: "Enter Discount Value" })
    dispatch({ type: 'notiVarient', payload: "danger" })
    dispatch({ type: 'notiMessageShow', payload: true })
    hidemessage()
    return false;
  }else  if(state.disc_val!=="" && Number(state.disc_val)<0){
    dispatch({ type: 'notiMessage', payload: "Enter proper Discount Value" })
    dispatch({ type: 'notiVarient', payload: "danger" })
    dispatch({ type: 'notiMessageShow', payload: true })
    hidemessage()
    return false;
  }
  return true
}


const updateCompany=()=>{
  if(validate()){
  if(state.showCompList.length!==0){
        for(let val of state.showCompList){
          if(val.value!==undefined){
            if(Number(val.value)===Number(state.sel_comp_id)){
              val.disc_val=state.disc_val;
            }
          }
        }
      cancelEdit();
  }
  }
}

const handleRegionType = (e) => {
  dispatch({ type: "selBranch", payload: [] });
  // dispatch({ type: "taMenu", payload: [] });
  setData([])
  dispatch({ type: "branchList", payload: e });
  dispatch({ type: "regionError", payload: false });
}

const updateRegion=(region)=>{
  dispatch({ type: "region_type", payload: region });
}

const handleBranchChange = (e)=> {
      dispatch({ type: 'selBranch', payload: e});
      dispatch({ type: "branchError", payload: false });
      AttCommonSerivce.getCompanyList(e.length!==0?e[0].value:"")
        .then((response) => {
          if (response.data.suc){
            let companyList = response.data.res.filter((obj)=>obj.sales_channel===state.sales_chanel)
            dispatch({ type: 'taMenu', payload: companyList });
          }
        })
        .catch(function (error) {
          log.error("Exception occured in getCompanyList function---" + error);
        });
    };


return (
<>

    <div className="cardMainlayout pt-0 commissDiscount">
    {state.notiMessageShow &&
    <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
    }
    {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <div className="contentArea">
                            <Form.Row>
                            <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={state.edit_salesChanel}/>

                            <RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={state.region_type_edit} />
                                                  {state.regionError && (
                                                    <p className="vError">Please Select Region Type</p>
                                                  )}
                            </Form.Row>
                            <Form.Row style={{padding:"10px"}}>
          {state.enableDevices &&
          <>
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2 mb-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
            </>
          }
            </Form.Row>
                            <Form.Row>
                            <Form.Group as={Col} xs={3} controlId="disc_name" className="d-flex align-items-center">
                                <Form.Control type="text" disabled={state.selCompany} value={state.disc_name} onChange={handleChange} placeholder="Enter Discount Name" />
                                <Form.Label>Discount Name<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group
                            as={Col}
                            xs={2}
                            className="selectplugin"
                            controlId="selectedValue"
                            >
                                <Select
                                disabled={state.selCompany}
                                multi
                                options={state.htlsuppliers}
                                values={state.htlSupNames}
                                onChange={(value) => {
                                    (dispatch({ type: 'htlSupNames', payload: value }))
                                }}
                                />
                                <Form.Label>Supplier<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={2} className="selectplugin datePicker" controlId="from">
                            <DatePicker
                            selected={fromDate}
                            onChange={date => setFromDate(date)}
                            fromDate={fromDate}
                            toDate={toDate}
                            minDate={new Date()}
                            disabled={state.selCompany}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <Form.Label>From Date<sup>*</sup></Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} xs={2} className="selectplugin datePicker" controlId="to">
                            <DatePicker
                            selected={toDate}
                            onChange={date => setToDate(date) + 1}
                            fromDate={fromDate}
                            toDate={toDate}
                            minDate={fromDate}
                            className="datefield"
                            disabled={state.selCompany}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <Form.Label>To Date<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={2} controlId="disc_val" className="d-flex align-items-top">
                            <Form.Control type="number" min="1" value={state.disc_val} onChange={handleChange} placeholder="Service Fee Name" />
                            <Form.Label>Discount(%)<sup>*</sup></Form.Label>
                           </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        {!state.enableDevices &&<Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                                <Select
                                options={state.branchList}
                                values={state.selBranch}
                                searchable={false}
                                disabled={state.enableView || state.enbaleUpdate || state.region_type_edit}
                                onChange={(e)=>handleBranchChange(e)}
                                />
                                {state.branchError && (
                                 <p className="vError">Please Select branches</p>
                                )}
                                <Form.Label>Branch<sup>*</sup></Form.Label>
                            </Form.Group>}
                        {state.taMenu && state.selCompany==="" &&
                        <Form.Group as={Col} className="picklist" xs={6}>
                            <PickList leftPaneLabel="Available Company" rightPaneLabel="Selected Company"
                            pickid="c_id" dataList={state.taMenu} type={true} selData={data} sendData={updateData} />
                            <Form.Label>Company<sup>*</sup></Form.Label>
                        </Form.Group>
                        }

                        {state.selCompany &&
                        <Form.Group as={Col} xs={2} controlId="selCompany" className="d-flex align-items-center">
                        <Form.Control type="number" disabled={true} placeholder="Service Fee Name" />
                        <Form.Label>{state.selCompany}</Form.Label>
                        </Form.Group>
                        }
                        <div className={"text-right ceditCancel buttonGrop"}>

                        {state.selCompany==="" &&

                        <Button
                                            size="xs"
                                            variant="outline-primary"
                                            type="submit"
                                            onClick={createCompList}
                                            >
                                            Add
                                            </Button>
                          }
                             {state.selCompany!=="" &&
                                            <Button
                                            size="xs"
                                            variant="outline-secondary"
                                            type="submit"
                                            onClick={cancelEdit}
                                            >
                                            Cancel
                                            </Button>
                               }
                             {state.selCompany!=="" &&
                                            <Button
                                            size="xs"
                                            variant="outline-primary"
                                            type="submit"
                                            onClick={updateCompany}
                                            >
                                            Update
                                            </Button>
                               }
                                            </div>


                        </Form.Row>

                      </div>

      <div className="tableLayout">
        <Table>
          <thead>
            <tr>
              {/* <th>Discount Name</th>
              <th>From Date</th>
              <th>To Date</th> */}
              <th>Company Name</th>
              <th>Discount</th>
              <th className="text-right" width="8%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.showCompList.length!==0 && state.showCompList.map((comp, index) => (
              <React.Fragment key={index}>
                <tr>
                  {/* <td>
                    <span>{state.disc_name}</span>
                  </td>
                  <td>
                    <span>{DateUtils.convertStringToDate(fromDate)}</span>
                  </td>
                  <td>
                    <span>{DateUtils.convertStringToDate(toDate)}</span>
                  </td> */}
                  <td>
                    <span>{comp.label}</span>
                  </td>
                  <td>
                    <span>{comp.disc_val}</span>
                  </td>
                  <td className="text-right">
                    {state.enableUpdate &&
                      <Button
                        size="xs"
                        variant="link"
                        onClick={editDisc(comp)}
                        className="ezyIcon icon-edit"
                        type="submit">
                      </Button>
                    }
                      <Button
                        size="xs"
                        variant="link"
                        onClick={deleteDisc(comp, index)}
                        className="ezyIcon icon-delete"
                        type="submit">
                      </Button>

                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>


      {state.showCompList.length===0 &&
        <span>No Records found</span>
      }
   <br/>
<div className={"text-right"}>
                        <Form.Group as={Col} className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="text-right pb-0 buttonGrop  creat_absolute">
                            <Button
                            size="xs"
                            variant="outline-secondary"
                            type="submit"
                            onClick={handleCancel}
                            >
                            Back
                            </Button>
                            {state.enableUpdate &&
                            <Button
                                size="xs"
                                onClick={updateCommDisc}
                                variant="outline-primary"
                                type="submit"
                            >
                                Update
                            </Button>
                            }
                            {!state.enableUpdate && (
                            <Button
                                size="xs"
                                onClick={createDiscount}
                                variant="outline-primary"
                                type="submit"
                            >
                            Save
                            </Button>
                            )}
                            </div>
                            </div>
      </div>

</>
)
}
export default CreateDisc