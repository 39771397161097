import { default as React, useReducer,useState } from 'react';
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { Link, useHistory } from 'react-router-dom';
import { fireHoldHotel, fireHotelBook , updateHoldPayment} from '../../../bookingFlows/hotel/payment/opearations';
import Button from '../../../common/buttons/Button';
import Processing from '../../../common/processingPopUp/Processing';
import { fireBookReq , fireTicket } from '../review/operations';

export const USER_AUTH_DATA = "userAuthData";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";

const initialState = {
  paymode: "", payref: "", remarks: "", id: 0, terms: false, tAc:'', showTerms: false, notiMessage: '', notiMessageShow: false, notiVarient: '', isLoading: false, pleasewait: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function OfflinePay(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [tac,setTAC] =useState(window.location.pathname.includes('flight') ? true : false)
  const paymodes = [{ label: "Cash", value: "Cash" },
  { label: "Bank Transfer", value: "Bank Transfer" }]

  const validateOffline = () => {
    if (state.paymode === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Payment mode' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else if (state.remarks === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Remarks' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else if (!state.terms) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: "Please accept terms and conditions and proceed" })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    return true;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const payOfflineBooking =()=>{
      window.sessionStorage.removeItem(CHECK_BOOKING);
      window.sessionStorage.removeItem(BOOKING_REF_NO);
    if(validateOffline()) {
    if(props.product==="Flight"){
      processOfflinePayFlight();
    }else if(props.product==="Hotel"){
      if(props.bdata.bookingType==="DIRECT"){
        processOfflinePayHotel();
      }else{
        processOnholdPay();
      }
    }
    }
  }
  //Process Onhold flow payment
  const processOnholdPay =()=>{
    props.bdata.request.pay=[{
      "ptype": "",
      "amt": 0,
      "ccode": "",
      "psta": "Pending",
      "crbal": 0,
      "pgauthid": "",
      "pgtid": "",
      "pgstc": "",
      "csur": "",
      "ctype": "",
      "cno": "",
      "cname": "",
      "cexp": "",
      "cvfr": ""
       },{
      "ptype": "Credit Limit",
      "amt": props.bdata.price,
      "ccode": props.bdata.cur,
      "crbal": props.bdata.creditBal,
      "psta": "Confirmed",
      "formofpay": "Offline",
      "paymode": state.paymode[0].value,
      "payref": state.payref,
      "remarks": state.remarks
    }];
    props.bdata.request.upby=props.bdata.userId;
    updateHoldPayment(props.bdata.request).then((bresp) => {
      history.push({
        pathname: '/ezytrip/bookings/hotel/confirmation',
        state: {
          req: bresp.res
        }
      });
    });
  }


  //=====================================
  // Calling to Booking Service to get the PNR
  //=====================================
  const processOfflinePayFlight = () => {
      dispatch({ type: 'isLoading', payload: true })
      dispatch({ type: 'pleasewait', payload: true })
      const payload = {
        "tid": props.bdata.tid,
        "bookId": props.bdata.bookingId,
        "onhold": "no",
        "cha": "B2C",
        "cur": props.bdata.cur,
        "sty": props.bdata.sty,
        "tt": props.bdata.tt,
        "bid": props.bdata.bid,
        "butype": props.bdata.butype,
        "bktype":props.bdata.bookingType,
        "pcid": props.bdata.pcid,
        "service_fee": props.bdata.service_fee,
        "ofcId": props.bdata.ofcId,
        "plb": props.bdata.plb,
        "ttl": props.bdata.ttl,
        "pnr":props.bdata.pnr,
        "uid" : props.bdata.userId,
        "tkt_user_type": props.bdata.utype,
       "tkt_user_id": props.bdata.tkt_user_id,
       "region_type":props.bdata.region_type,
       "req_cur":props.bdata.req_currency,
        "pay": [{
          "ptype": "",
          "amt": 0,
          "ccode": "",
          "psta": "Pending",
          "crbal": 0,
          "pgauthid": "",
          "pgtid": "",
          "pgstc": "",
          "csur": "",
          "ctype": "",
          "cno": "",
          "cname": "",
          "cexp": "",
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": props.bdata.price,
          "ccode": props.bdata.cur,
          "crbal": props.bdata.creditBal,
          "psta": "Confirmed",
          "formofpay": "Offline",
          "paymode": state.paymode[0].value,
          "payref": state.payref,
          "remarks": state.remarks
        }]
      }
      if(props.bdata.onhold_sty) {
        payload.onhold_sty = props.bdata.onhold_sty
      }
      if(props.bdata.splitted_pax) {
        payload.splitted_pax = props.bdata.splitted_pax
        payload.pr = props.bdata.pr
        payload.paxpr = props.bdata.paxpr
      }
      if(props.bdata.bookingType!=="ONHOLD" && (props.bdata.pnr===undefined ||  props.bdata.pnr==="")){
      fireBookReq(payload, "DIRECT").then((response) => {
        if (response.bookRs !== undefined && response.bookRs.status !== "bookfailed") {
          if(!props.bdata.isTravelFusion){
          const pay = {
            "response": response,
            "mappings": props.bdata.mappings
          }
          history.push({
            pathname: '/ezytrip/bookings/flight/confirmation',
            state: {
              req: pay
              }
          });
          dispatch({ type: 'isLoading', payload: false })
          dispatch({ type: 'pleasewait', payload: false })
        }else{
          callTicketRequest(payload);
        }
        } else {
          dispatch({ type: 'isLoading', payload: false })
          dispatch({ type: 'pleasewait', payload: false })
          return history.push({
            pathname: '/ezytrip/bookings/flight/error',
            state: {
              req: "Book"
            }
          });
        }
      });
      }else{
        callTicketRequest(payload);
      }
  }

  //=====================================
  // Calling to Booking Service to get the PNR
  //=====================================
  const processOfflinePayHotel = () => {
      dispatch({ type: 'isLoading', payload: true })
      dispatch({ type: 'pleasewait', payload: true })
      const payload = {
        "hid": props.bdata.hid,
        "bookId": props.bdata.bookingId,
        "cha": "B2C",
        "cur": props.bdata.cur,
        "butype": props.bdata.butype,
        "ofcId": props.bdata.ofcId,
        "region_type":props.bdata.region_type,
        "req_cur":props.bdata.req_cur,
        "pay": [{
          "ptype": "",
          "amt": 0,
          "ccode": "",
          "psta": "Pending",
          "crbal": 0,
          "pgauthid": "",
          "pgtid": "",
          "pgstc": "",
          "csur": "",
          "ctype": "",
          "cno": "",
          "cname": "",
          "cexp": "",
          "cvfr": ""
        }, {
          "ptype": "Credit Limit",
          "amt": props.bdata.price,
          "ccode": props.bdata.cur,
          "crbal": props.bdata.creditBal,
          "psta": "Confirmed",
          "formofpay": "Offline",
          "paymode": state.paymode[0].value,
          "payref": state.payref,
          "remarks": state.remarks
        }]
      }
      let holdReq = {"bookId": props.bdata.bookingId,"hid":props.bdata.hid}
      fireHoldHotel(holdReq).then((response) => {
        if (response.suc && response.data.st=="success"){
        fireHotelBook(payload).then((bresp) => {
            if(bresp.suc){
            }
            history.push({
              pathname: '/ezytrip/bookings/hotel/confirmation',
              state: {
                req: bresp
              }
            });
      });
    }else{
      dispatch({ type: 'isLoading', payload: false })
      dispatch({ type: 'pleasewait', payload: false })
      props.sendFailedMsg();
    }
    });
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if(id == "tc") {
    if (state.terms) {
      dispatch({ type: 'terms', payload: false })
    } else {
      dispatch({ type: 'terms', payload: true })
    }
  } else {
    dispatch({ type: id, payload: value });
  }
  }

  const showTermsConditions = () => {
    dispatch({ type: 'showTerms', payload: true })
  }

  const handleClose = () => {
    dispatch({ type: 'showTerms', payload: false })
  }


  //Calling ticketing
  const callTicketRequest=(payload)=>{
  fireTicket(payload,props.bdata.bookingType).then((response) => {
    if (response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed"){
      dispatch({ type: 'pleasewait', payload: false })
    const payload={
      "response":response,
      "mappings":props.bdata.mappings
      }
      history.push({
        pathname: '/ezytrip/bookings/flight/confirmation',
        state: {
        req: payload
        }
      });
    }
 });
}


  return (
    <>
      <>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <div className="offline">
          <Form id="formId">
            <Form.Row>
              <Form.Group as={Col} xs={4} className="selectplugin" controlId="paymode">
                <Select
                  options={paymodes}
                  searchable={false}
                  values={[]}
                  onChange={(value) => (dispatch({ type: 'paymode', payload: value }))}
                />
                <Form.Label>Payment mode<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={4} controlId="payref">
                <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.payref} onChange={handleChange} />
                <Form.Label>Ref#</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="m-0" as={Col} xs={8} controlId="remarks">
                <Form.Control as="textarea" placeholder="Enter Address" value={state.remarks} onChange={handleChange} />
                <Form.Label>Remarks<sup>*</sup></Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
          </div>
          <Row className="htlConfirm">
                  <Col xs="9">
                 {!tac ? <div className="d-flex mt-2 tc"><Form.Check
                type="checkbox"
                id="tc"
                label=""
                value={state.terms}
                onChange={handleChange}
                custom
              /><label> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip, Agent <a className="d-inline-flex font-weight-bold" href="/ezytrip/gent/termsandconditions" target="_blank">Terms & Conditions</a> & Supplier <a className="d-inline-flex font-weight-bold" href="/ezytrip/supplier/termsandconditions" target="_blank">Terms & Conditions</a> Hotel Package rates are only available when sold as part of a package combined with one or more transport component & sold as one piece to the customer.</label></div>
                :<div className="d-flex mt-2 tc"><Form.Check
                type="checkbox"
                id="tc"
                label=""
                value={state.terms}
                onChange={handleChange}
                custom
              /><label className="tAcCommen"> I understand and agree the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>}
                  </Col>
                  <Col xs="3" className="d-flex justify-content-end align-items-center">
                  <div className="d-flex justify-content-end">
                  <Button variant="success" size="lg"
              onClick={payOfflineBooking}
              className={"progress-bar continue d-flex  " + (state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
              disabled={state.isLoading && state.pleasewait ? true : false} >
              {state.isLoading ? "Processing..." : "CONFIRM"}
            </Button>
            {state.pleasewait ? <Processing /> : ""}
                  </div>
                  </Col>
                </Row>


      </>
      <Modal
        show={state.showTerms}
        onHide={handleClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <h5>Terms and Condtions</h5>
          <div className="dstn">
            Terms and Condtions
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default OfflinePay