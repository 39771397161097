/**
 * @description: Flight Confirmation page Root component
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
import log from "loglevel";
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { Alert, Form, Modal, Col, Row } from "react-bootstrap";
import { useHistory, Link } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import { isNotNull, isArrayNotEmpty } from '../../../commonUtils/validators';
import FlightSectors from '../../../bookingFlows/flight/common/FlightSectors';
import FareDetails from './FareDetails.jsx';
import PassengerDetails from './PassengerDetails.jsx';
import PaymentDetails from './PaymentDetails.jsx';
import TransactionSummary from './TransactionSummary.jsx';
import TFConfirmation from './TFConfirmation';
import { sendConfirmationObj } from '../review/operations';
import { emailValidation } from '../../../commonUtils/FormValidations';
import { useReactToPrint } from "react-to-print";
import FlightItinerarypdf from './FlightItinerarypdf.jsx';
import { getcompanyAddress } from '../../../postBooking/summary/operations';



/**
 * Initial State Declaration
 */
const initialState = {

  confirmationObj: '', notiMessage: '', notiMessageShow: false, notiVarient: '',
  // showPopup: false, email: ""
};


const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description: This function will show the Confirmation page
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
function ConfirmationPage(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [context, setContext] = useContext(Context);

  useEffect(() => {


    //if (ticketRes.bookrs) {
    if (props.location.state.req.response.bookRs.data) {
      //dispatch({ type: 'confirmationObj', payload: ticketRes.bookrs.data[0]})
      dispatch({ type: 'confirmationObj', payload: props.location.state.req.response.bookRs.data[0] })
      dispatch({ type: 'cobj', payload: props.location.state.req })
      //get compnay address  and branch address based on compnay id
      let confirmobj = props.location.state.req.response.bookRs.data[0]
      if (isNotNull(confirmobj.cid)) {
        const com_req = {
          cid: confirmobj.cid
        }
        getcompanyAddress(com_req).then((response) => {
          if (isArrayNotEmpty(response)) {
            let com_data = response[0];
            confirmobj.comp_add = com_data.company_add;
            confirmobj.comp_city = com_data.comp_city_name;
            confirmobj.comp_cnt = com_data.comp_cnt_name;
            confirmobj.comp_lmark = null;
            confirmobj.branch_name = com_data.branch_name;
            confirmobj.branch_add = com_data.branch_add;
            confirmobj.branch_city = com_data.branch_city_name;
            confirmobj.branch_cnt = com_data.branch_cnt_name;
            confirmobj.branch_lmark = com_data.branch_landmark;
            confirmobj.branch_phone = com_data.branch_phone_no;
            confirmobj.branch_email = com_data.branch_email;
            confirmobj.logo_comp_url = com_data.comp_logo_url;
            dispatch({ type: 'confirmationObj', payload: confirmobj })
          }

        });
      }
      if (props.location.state.req.response.bookRs.data[0].bktype === "REISSUE" && props.location.state.req.response.bookRs.data[0].bkd[0].st === "CONFIRMED") {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Your booking is Exchanged now!' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }

    } else if (props.location.state.req.response.tktrs.data) {
      dispatch({ type: 'confirmationObj', payload: props.location.state.req.response.bookRs.data[0] })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Your booking is Exchanged now!' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      hidemessage();
    }



  }, []);

  useEffect(() => {
    return () => {
      console.log(history.action)
      if (history.action === "POP") {
        history.push({ pathname: '/ezytrip/dashboard' })
      }
    };
  }, [history]);


   const handleChange = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
    dispatch({ type: 'email', payload: trimmedEmail })
     }
  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
   const handleClose = ()=>{
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
   }

  const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }
  const componentRef = useRef();
  const opendlevent =
    useReactToPrint({
      content: () => componentRef.current,
      documentTitle: state.confirmationObj !== undefined && state.confirmationObj.bkd !== undefined && state.confirmationObj.bkd[0].bookId !== undefined && ("FLIGHT_ITINERARY" + state.confirmationObj.bkd[0].bookId)
    }
    );
  const openshareevent = (e) => {
    e.preventDefault();
    dispatch({ type: 'showPopup', payload: true })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
   const validateEmail = ()=>{
    if(state.email == ""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     else if(!emailValidation(state.email)){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     return true
   }
   const shareItinerary = (data) =>{
    sendConfirmationObj(data).then(response=>{
      if(response.suc){
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response.res.data })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }
     }).catch(function (error) {
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'email', payload: "" })
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
   }
   const sendEmail = ()=>{
     if(validateEmail()){
       if(state.confirmationObj.bkd[0].st == "Confirmed" || state.confirmationObj.bkd[0].st == "Partially Ticketed"){
         if(state.confirmationObj.sty == "normal"){
           if(state.confirmationObj.sup == "0" || state.confirmationObj.bktype == "IMPORT"){
          const payload = {
              "booking_id": state.confirmationObj.bkd[0].bookId,
              "tid": state.confirmationObj.tid,
              "email_id":state.email,
              "sup": "0",
              "jtype": state.confirmationObj.tt == "1" ? "O" : state.confirmationObj.tt == "2" && "O/R",
              "ftype": state.confirmationObj.sty
             }
             shareItinerary(payload)
            }
            else{
              const payload1 = {
                "booking_id": state.confirmationObj.bkd[0].bookId,
                "tid": state.confirmationObj.tt == "1" ? state.confirmationObj.tid : state.confirmationObj.tid + "/" +state.confirmationObj.origns[0].srcorigns[0].tid,
                "email_id":state.email,
                "sup": state.confirmationObj.sup,
                "jtype": "O",
                "ftype": state.confirmationObj.sty
               }
               shareItinerary(payload1)
              if(state.confirmationObj.tt == "2"){
              setTimeout(() => {
              const payload2 = {
                "booking_id": state.confirmationObj.bkd[0].bookId,
                "tid": state.confirmationObj.tid+"/"+state.confirmationObj.origns[0].destorigins[0].tid,
                "email_id":state.email,
                "sup": state.confirmationObj.sup,
                "jtype": "R",
                "ftype": state.confirmationObj.sty
               }
               shareItinerary(payload2)
              }, 10000);
            }
            }
         }
         else{
          const payload = {
              "booking_id": state.confirmationObj.bkd[0].bookId,
              "tid":  state.confirmationObj.origns[0].srcorigns[0].tid,
              "email_id":state.email,
              "sup": state.confirmationObj.origns[0].srcorigns[0].sup,
              "jtype": "O",
              "ftype": state.confirmationObj.sty
             }
             shareItinerary(payload)
          setTimeout(() => {
            const payload = {
              "booking_id": state.confirmationObj.bkd[0].bookId,
              "tid":  state.confirmationObj.origns[0].destorigins[0].tid,
              "email_id":state.email,
              "sup": state.confirmationObj.origns[0].destorigins[0].sup,
              "jtype": "R",
              "ftype": state.confirmationObj.sty
             }
             shareItinerary(payload)
          }, 1000)
        }
      }
    }
   }
  return (

    <>
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => this.setState({
          notiMessageShow: false
        })}
        dismissible> {state.notiMessage}</Alert>

      <Header branchId={context.logindata.bid} companyId={context.logindata.cid} />

      {isNotNull(state.confirmationObj) && (
        <>
          <div className="container confirmation flightconFirmation mt-4">

          <div className={'confirmMessage d-flex align-items-center justify-content-between mb-0 ' + (state.confirmationObj.bkd[0].st === "Confirmed" || state.confirmationObj.bkd[0].st === "Partially Ticketed" ? 'confirmClass' : 'failedClass')}>
            <div className='resultMes'>
            <div className={"d-flex align-items-center resconfirMation " + (((state.confirmationObj.bkd[0].st === "Ticketing failed") || (state.confirmationObj.bkd[0].st === "Booking failed" || state.confirmationObj.bkd[0].st === "Failed")) ? 'fail' : '')}>
              <div className={"confirmStatus done ezyIcon icon-" + (((state.confirmationObj.bkd[0].st === "Ticketing failed") || (state.confirmationObj.bkd[0].st === "Booking failed" || state.confirmationObj.bkd[0].st === "Failed")) ? 'close' : 'tick')}></div>
              {state.confirmationObj.bkd[0].st === "On hold" &&
                <div className="message">
                  <h3 className="m-0">Thank you, Your flight booking is on hold.</h3>
                  <p className="m-0 pt-1">Your booking details have been sent to your email address..</p>
                </div>
              }
              {state.confirmationObj.bkd[0].st === "Confirmed" &&
                <div className="message">
                  <h3 className="m-0">Thank you for booking with us!</h3>
                  <p className="m-0 pt-1">The booking confirmation has been sent to your email address.</p>
                </div>
              }
              {state.confirmationObj.bkd[0].st === "Ticketing failed" &&
                <div className="message">
                  <h3 className="m-0">Sorry, Your ticketing got failed</h3>
              {/* {state.confirmationObj.sup == "0" && state.confirmationObj.bkd[0].msg !== "" && state.confirmationObj.bkd[0].msg !== undefined && <span>{state.confirmationObj.bkd[0].msg}</span>}                   */}
                  <p className="m-0 pt-1">For any quires please contact our support team on <span className="ezyIcon icon-phone"> +974-44798899</span><span className="ezyIcon icon-mail"> support@ezytrip.com</span></p>
                </div>
              }
              {state.confirmationObj.bkd[0].st === "Partially Ticketed" &&
                <div className="message">
                  <h3 className="m-0">Thank you, your booking is Partially Ticketed!</h3>
                </div>
              }
              {(state.confirmationObj.bkd[0].st === "Booking failed" || state.confirmationObj.bkd[0].st === "Failed") &&
                <div className="message">
                  <h3 className="m-0">Sorry, Your booking got failed</h3>
                  <p className="m-0 pt-1">For any quires please contact our support team on <span className="ezyIcon icon-phone"> +974-44798899</span><span className="ezyIcon icon-mail"> support@ezytrip.com</span></p>
                </div>
              }

            </div>
            {state.confirmationObj.failed_msg !== undefined && state.confirmationObj.failed_msg !== "" && state.confirmationObj.bkd[0].st === "Ticketing failed" &&
            <div className="errMessage">
              <p className="m-0"><span className="ezyIcon icon-info">{state.confirmationObj.failed_msg}</span></p>
            </div>
          }
              </div>

              <div className='socialIcons'>
              {(state.confirmationObj.bkd[0].st == "Confirmed" || state.confirmationObj.bkd[0].st === "Partially Ticketed")  &&<Button size="xs" className="prient ezyIcon icon-Pdf" to="#" onClick={opendlevent} variant="link">Download Itinerary
              </Button>}
              {(state.confirmationObj.bkd[0].st == "Confirmed" || state.confirmationObj.bkd[0].st === "Partially Ticketed") &&<Button size="xs" className="prient ezyIcon icon-print" icon="print" onClick={openprintevent} variant="link"> Print Confirmation</Button>}

              {(state.confirmationObj.bkd[0].st == "Confirmed" || state.confirmationObj.bkd[0].st === "Partially Ticketed")  &&
                <Button size="xs" className="prient ezyIcon icon-share" onClick={openshareevent} variant="link">Share Itinerary
                </Button>

              }


                  </div>

            </div>

            <div style={{ display: "none" }}>
              <FlightItinerarypdf ref={componentRef} confirmationObj={state.confirmationObj} phone_num={context.logindata.phno} emialId={context.logindata.unm} compLogo={context.logindata.comp_logo_url} cobj={state.cobj} customer={context.logindata.cnm} />
            </div>
            {/* Booking details section */}
            <div className="resultSection boxShadow bg-white summary mb-4">
              <h5 className="title">Transaction Summary</h5>
              <div className='table-responsive mobileTable newlayoutDesign'>
              <TransactionSummary bdata={state.confirmationObj.bkd} ttl={state.confirmationObj.ttl} customer={context.logindata.cnm} sup={props.location.state !== undefined && props.location.state.req !== undefined && props.location.state.req.response.bookRs !== undefined && props.location.state.req.response.bookRs.data !== undefined && props.location.state.req.response.bookRs.data[0].sup} leadpsngr={props.location.state.req !== undefined && props.location.state.req.response.bookRs !== undefined && props.location.state.req.response.bookRs.data !== undefined && props.location.state.req.response.bookRs.data[0].leadPsngr}
              />
              </div>
            </div>

            {/* Segment details section {IF NOT TF FLOW} */}
            {!state.confirmationObj.tf_flow &&
              <>
                <div className="resultSection bg-white boxShadow mb-4 pb-0">
                  <h5 className="title">Booking Details</h5>
                  {state.confirmationObj.origns.map((sector, idx) => (
                    <div className="newlayoutDesign">
                      {sector['srcorigns'] &&
                        <FlightSectors origin={[sector['srcorigns'][0], 'Confirm']}
                          mapping={props.location.state.req.mappings}
                          tripType={props.location.state.req.response.bookRs.data[0].tt}
                          bktype={props.location.state.req.response.bookRs.data[0].bktype}
                          originsList={[props.location.state.req.response.bookRs.data[0].origns[0], 'Confirm']} confirmation={true} />
                      }
                      {sector['destorigins'] &&
                        <FlightSectors origin={[sector['destorigins'][0], 'Confirm']}
                          mapping={props.location.state.req.mappings}
                          tripType={props.location.state.req.response.bookRs.data[0].tt} bktype={props.location.state.req.response.bookRs.data[0].bktype} confirmation={true} />
                      }
                    </div>
                  ))}
                  <br />
                </div>
                {/* Passenger details section */}
                {/* comfirmation page passenger details for split fare onward pax and return pax we are showing seperatly */}
                <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
                  <h5 className="title">Passenger Details</h5>
                  <PassengerDetails sector={props.location.state.req.response.bookRs.data[0].tt} sty={state.confirmationObj.sty} paxdata={state.confirmationObj} flowType={props.location.state !== undefined && props.location.state.req !== undefined && props.location.state.req.response.bookRs !== undefined && props.location.state.req.response.bookRs.data !== undefined && props.location.state.req.response.bookRs.data[0].bktype} tf_flow={state.confirmationObj.tf_flow} sup={props.location.state !== undefined && props.location.state.req !== undefined && props.location.state.req.response.bookRs !== undefined && props.location.state.req.response.bookRs.data !== undefined && props.location.state.req.response.bookRs.data[0].sup} />
                </div>

                {/* Fare  details section */}
                {/* confirmation page fare details onward fare and return fare adding and displaying. */}
                <div className="resultSection boxShadow bg-white PassengerDetails mb-4 mt-0">
                  <h5 className="title">Fare Details({context.logindata.cur})</h5>
                  <FareDetails sector={props.location.state.req.response.bookRs.data[0].tt} faredet={state.confirmationObj} tf_flow={state.confirmationObj.tf_flow} gst={props.location.state.req.response.bookRs.data[0].gst} ow_gst={props.location.state.req.response.bookRs.data[0].ow_gst} rt_gst={props.location.state.req.response.bookRs.data[0].rt_gst} tds={props.location.state.req.response.bookRs.data[0].tds} />
                </div>
              </>
            }

            {/* TRAVEL FUSION FLOW } */}
            {state.confirmationObj.tf_flow &&
              <TFConfirmation confirmationObj={state.confirmationObj} usrCurrency={context.logindata.cur} gst={props.location.state.req.response.bookRs.data[0].gst} ow_gst={props.location.state.req.response.bookRs.data[0].ow_gst} rt_gst={props.location.state.req.response.bookRs.data[0].rt_gst} />
            }

            {/* Payment details section */}
            {state.confirmationObj.bkd[0].st !== "On hold" &&
              <div className="resultSection boxShadow bg-white PassengerDetails paymentDetails mb-4">
                <h5 className="title">Payment Details({context.logindata.cur})</h5>
                <PaymentDetails paydata={state.confirmationObj.pay} st={state.confirmationObj.bkd[0].st} gst={props.location.state.req.response.bookRs.data[0].gst} ow_gst={props.location.state.req.response.bookRs.data[0].ow_gst} rt_gst={props.location.state.req.response.bookRs.data[0].rt_gst} sty={state.confirmationObj.sty}/>
              </div>
            }
          </div></>
      )}

      <Footer />
      <Modal
        show={state.showPopup}
        onHide={handleClose}
        className="importPNRMdl shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <h5>Share Itinerary</h5>
          <Row>
          <Col xs={10}>
          <Form.Group controlId="uem">
              <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChange} />
              <Form.Label>Email Id <sup>*</sup></Form.Label>
            </Form.Group>
            </Col>
            <Col xs={2}>
            <div className="d-flex justify-content-center">
            <Button variant="outline-dark" onClick={sendEmail}>Submit
          </Button>
            </div>
            </Col>
            </Row>
               </Modal.Body>
            </Modal>
    </>
  )
}
export default ConfirmationPage