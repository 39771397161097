/**
 * @description : Show SPlit PNR details
 * @author : <Azamuddin>
 * @date: 10-jan-2021
 */
 import React, { useReducer } from 'react';
 import { Table} from "react-bootstrap";
 import { isArrayNotEmpty,isNotNull } from '../../../commonUtils/validators';
 const JSON = require('circular-json');


 const initialState = {
     pnr:""
 }
 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

       default:
         return { ...state, [action.type]: action.payload };
     }
   };

   let pnr = ""
 function SplitReissuePNR(props) {
     const [state, dispatch] = useReducer(reducer, initialState);

     return (
         <div className="passengerList">
                 <Table className="paxTable table m-0">
                     <tr>
                     <th width="25%">Traveller</th>
                     <th width="25%">Pax Type</th>
                     <th width="25%">Ticket Number</th>
                     <th width="25%">GDS PNR</th>
                     </tr>

             {isArrayNotEmpty(props.paxList) && (props.paxList.map((obj, index) => (
                 <React.Fragment key={index}>

                        {isNotNull(obj.split_pnr) && (obj.stsid == "23" || obj.stsid == "37" || obj.stsid == "34") &&
                        <tr>
                         <td>{obj.pax_name.toUpperCase()}</td>
                         <td>{obj.pax_type}</td>
                         <td>{obj.tkt_no ? obj.tkt_no : "N/A"}</td>
                         <td>{obj.split_pnr}</td>
                         </tr>
                        }


                     <tr></tr>
                 </React.Fragment>
             )))}
             </Table>
             </div>
     )
 }

 export default SplitReissuePNR;
