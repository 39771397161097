import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Table, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import { deletePax} from "./operations";
function VisaPaxInfo(props) {
    const [adultRecords, setAdultRecords] = useState([{ fieldName: '', fileType: '', isMandatory: '',id:'' }]);
    const [childRecords, setChildRecords] = useState([{ fieldName: '', fileType: '', isMandatory: '',id:'' }]);
    const [notiMessage, setNotiMessage] = useState("");
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    // const [options, setOptions] = useState([
    //     { value: 'Alpha-Numeric', label: 'Alpha-Numeric' },
    //     { value: 'Number', label: 'Number' },
    //     { value: 'Text Area', label: 'Text Area' },
    //     { value: 'Drop down', label: 'Drop down' },
    //     { value: 'Date picker', label: 'Date picker' }
    // ]);
    const [fieldOptions, setFieldOptions] = useState([
        { value: 'Text Area', label: 'Title' },
        { value: 'Text Area', label: ' First name' },
        { value: 'Text Area', label: 'Last Name' },
        { value: 'Text Area', label: 'Sur Name' },
        { value: 'Date picker', label: 'DOB' },
        { value: 'Number', label: 'Mobile Number' },
        { value: 'Alpha-Numeric', label: 'Email Id' },
        { value: 'Text Area', label: 'Nationality' },
        { value: 'Text Area', label: 'Issued Country' },
        { value: 'Date picker', label: 'Expiry Date' },
        { value: 'Date picker', label: 'Issued Date' },
        { value: 'Alpha-Numeric', label: 'Passport Number' },

    ]);
    
    
    
    
    
    
    
    
    
   
    
  
    
    useEffect(()=>{
        if (props.pax_resp && props.pax_resp.length !=0){
            props.pax_resp.map((data)=>{
                if (data.type == "ADT") {
                    const adtRecords = data.pax.map(item => ({
                        fieldName: item.name,
                        fileType: item.type,
                        isMandatory: item.is_mand == 1 ? "YES" : "NO",
                        id: item.id
                    }));
                    setAdultRecords(adtRecords);
                }
                else if(data.type=="CHD"){
                    const chdRecords = data.pax.map(item => ({
                        fieldName: item.name,
                        fileType: item.type,
                        isMandatory: item.is_mand == 1 ? "YES" : "NO",
                        id: item.id
                    }));
                    setChildRecords(chdRecords);
                }
            })
        }
    },[props.pax_resp])

    const transformData = () => {
        const formatRecord = (record) => {
            return {
                id:record.id,
                name: record.fieldName,
                type: record.fileType,
                is_mand: record.isMandatory === 'Yes' // Adjust this based on your dropdown values
            };
        };
    
        const formattedData = [
            {
                type: "ADT",
                pax: adultRecords.map(formatRecord)
            },
            {
                type: "CHD",
                pax: childRecords.map(formatRecord)
            }
        ];
    
        return formattedData;
    };
    
    const handleInputChange = (recordSet, index, field, value) => {
        debugger
        const newRecords = [...recordSet];
        newRecords[index][field] = value;
        if (recordSet === adultRecords) {
            if(value){
            setAdultRecords(newRecords)
        } else{
                setAdultRecords(adultRecords)
            }
        } else {
            if (value){
                setChildRecords(newRecords)
            }
            else {
            setChildRecords(childRecords)}
        }
        props.onDataChangePax(transformData());

    };

    const handleAddClick = (recordSet) => {
        const newEntry = { fieldName: '', fileType: '', isMandatory: '' };
        if (recordSet === adultRecords) {
            setAdultRecords([...adultRecords, newEntry]);
        } else {
            setChildRecords([...childRecords, newEntry]);
        }
        props.onDataChangePax(transformData());

    };

    const handleDeleteClick = (recordSet, index) => {
        if (recordSet === adultRecords) {
            const newRecords = [...adultRecords];
            newRecords.splice(index, 1);
            setAdultRecords(newRecords);
        } else {
            const newRecords = [...childRecords];
            newRecords.splice(index, 1);
            setChildRecords(newRecords);
        }
        props.onDataChangePax(transformData());
        if(props.enableUpdate!==undefined && props.enableUpdate){
            const req={
              "ids": [recordSet[index].id]
             }
            deletePax(req).then((res) => {
              if(res.data.suc){
                setNotiMessageShow(true);
                setNotiMessage("Selected Pax Deleted successfully...");
                setNotiVarient("success");
                hidemessage()
              }else{
                setNotiMessageShow(true);
                setNotiMessage("Pax Not Deleted.Please try agan later");
                setNotiVarient("danger");
                hidemessage()
              }
            });
          }
    };

    // const options = [
    //     { value: 'Alpha-Numeric', label: 'Alpha-Numeric' },
    //     { value: 'Number', label: 'Number' },
    //     { value: 'Text Area', label: 'Text Area' },
    //     { value: 'Drop down', label: 'Drop down' }
    // ];



    const options2 = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ]

    function hidemessage() {
        setTimeout(function () {
          setNotiMessageShow(true);
          setNotiMessage("");
          setNotiVarient("");
        }, 5000);
      }

    return (
        <div>
             <Alert
              className="notification"
              variant={notiVarient}
              show={notiMessageShow}
              onClose={() =>
                setNotiMessageShow(false)
              }
              dismissible
            >
              {" "}
              {notiMessage}
            </Alert>
                   {["Adult", "Child"].map((type) => {
                const records = type === "Adult" ? adultRecords : childRecords;
                return (
                  <div key={type}>
                    <span className="visaChad">{type}</span>
                    {records.map((entry, index) => (
                      <Form.Row key={index}>
                        <Form.Group
                          as={Col}
                          xs={3}
                          className="selectplugin"
                          controlId="entry_type"
                        >
                          <Select
                            options={fieldOptions}
                            //disabled={props.enableUpdate}
                            values={
                              entry.fileType
                                ? [
                                    {
                                      value: entry.fileType,
                                      label: entry.fileType,
                                    },
                                  ]
                                : []
                            }
                            placeholder="Select"
                            onChange={(value) =>
                              handleInputChange(
                                records,
                                index,
                                "fileType",
                                value[0]?.value != undefined
                                  ? value[0].value
                                  : entry.fileType[0].value
                              )
                            }
                          />
                          <Form.Label>File Type</Form.Label>
                        </Form.Group>

                        {/* <Form.Group
                          as={Col}
                          xs={3}
                          className="selectplugin"
                          controlId="entry_type"
                        >
                          <Select
                            options={options}
                            //disabled={props.enableUpdate}
                            values={
                              entry.fileType
                                ? [
                                    {
                                      value: entry.fileType,
                                      label: entry.fileType,
                                    },
                                  ]
                                : []
                            }
                            placeholder="Select"
                            onChange={(value) =>
                              handleInputChange(
                                records,
                                index,
                                "fileType",
                                value[0]?.value != undefined
                                  ? value[0].value
                                  : entry.fileType[0].value
                              )
                            }
                          />
                          <Form.Label>File Type</Form.Label>
                        </Form.Group> */}

                        <Form.Group
                          as={Col}
                          xs={3}
                          className="selectplugin"
                          controlId="entry_type"
                        >
                          <Select
                            options={options2}
                            values={
                              entry.isMandatory
                                ? [
                                    {
                                      value: entry.isMandatory,
                                      label: entry.isMandatory,
                                    },
                                  ]
                                : []
                            }
                            placeholder="Select"
                            //disabled={props.enableUpdate}
                            onChange={(value) =>
                              handleInputChange(
                                records,
                                index,
                                "isMandatory",
                                value[0]?.value != undefined
                                  ? value[0].value
                                  : entry.isMandatory[0].value
                              )
                            }
                          />
                          <Form.Label>is Mandatory</Form.Label>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          xs={1}
                          className="selectplugin"
                          controlId="entry_type"
                        >
                          <div>
                            {index === records.length - 1 ? (
                              <Button
                                type="button" //disabled={props.enableUpdate}
                                size="xs"
                                variant="outline-secondary"
                                onClick={() => handleAddClick(records)}
                              >
                                +
                              </Button>
                            ) : (
                              <Button
                                type="button" //disabled={props.enableUpdate}
                                variant="outline-primary"
                                onClick={() =>
                                  handleDeleteClick(records, index)
                                }
                              >
                                -
                              </Button>
                            )}
                          </div>
                        </Form.Group>
                      </Form.Row>
                    ))}
                  </div>
                );
            })}
        </div>
    );
}

export default VisaPaxInfo;
