import React, {useState, useContext, useEffect, useReducer } from 'react';
import { Accordion, Card, Button,Form, Col, Row ,Alert} from 'react-bootstrap';
import { Context } from "../../../../../App";
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import classNames from 'classnames';
import log from "loglevel";

function MyCustomToggle({ eventKey, callback, name, headingIcon }) {
  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
  const toggleOnClick = useAccordionToggle(eventKey, () => {
    //callback(eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className={classNames({ 'show': isCurrentEventKey })}
      onClick={toggleOnClick}
    >
      <span className={headingIcon} />
      {name}
      <span className="icon-arrowDown" />
    </Card.Header>
  );
}

/**
 * Initial State Declaration
 */
 const initialState = {
  notiMessage: '', notiMessageShow: false, notiVarient: '',notif_code:""
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


const NotificationMaster = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [mastersList,setMasterList] = useState([])
  const [types,setTyes] = useState([])


  useEffect(() => {
    const types = [{ "value": "MIN", "label": "Minute(s)" }, { "value": "HOURS", "label": "Hour(s)" }]
    const list = types.map((type) =>
    <option value={type.value}>{type.label}</option>
    );
    setTyes(list)

    if(mastersList.length===0){
      AttCommonSerivce.getNotifiMasters().then(response => {
        if (response.data.suc) {
          setMasterList(response.data.res)
        }
    })
    }
    }, []);

    const updateNotification=(obj)=>{
      const payload={
        "id":obj.id,
        "code":obj.code,
        "trigger_type":obj.trigger_type,
        "trigger_time":obj.trigger_time,
        "email":obj.email,
        "upby":context.logindata.uid
      }
      AttCommonSerivce.updateNotiMaster(payload).then(response => {
        if (response.data.suc) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Notification Saved Successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage()
        return window.location = window.location.pathname;
        }
    })
    }

    const hidemessage = () => {
      setTimeout(function () {
        dispatch({ type: 'notiMessageShow', payload: false })
        dispatch({ type: 'notiMessage', payload: '' })
        dispatch({ type: 'notiVarient', payload: '' })
      }, 5000);
    }

    // OnChange event we will call this and update the state
    const handleChangeEmail = (idx,code) => (e) => {
    try {
      const { id, value } = e.target;
      let list = mastersList.map((notifi, sidx) => {
        if (idx !== sidx) return notifi;
        else {
          return {
             ...notifi,email:value==="0"?1:0,
            };
        }
      });
      setMasterList(list)
    } catch (err) {
      log.error('Exception occured in handleChangeEmail function---' + err);
    }
  }

  const handleChangeNotification = (idx,code) => (e) => {
    try {
      const { id, value } = e.target;
      let list = mastersList.map((notifi, sidx) => {
        if (idx !== sidx) return notifi;
        else {
          return {
             ...notifi, [id]: value
            };
        }
      });
      setMasterList(list)
    } catch (err) {
      log.error('Exception occured in handleChangeNotification function---' + err);
    }
  }



  return (
    <>
      <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>




          <Accordion defaultActiveKey="0" className="notification-accordian">
         {mastersList.length!==0 && mastersList.map((notification, idx) => (
         <React.Fragment key={idx}>
          <Card>
          <MyCustomToggle eventKey={idx ===0 ? '0':idx} pk-data={idx} name={notification.name} value={notification.code} headingIcon={notification.icon_name} />
          <Accordion.Collapse eventKey={idx ===0 ? '0':idx} pk-data={idx}>
            <Card.Body>
              <div className='row custom-control custom-switch'>
              <Form.Group controlId="email" as={Col} xs={1} className="d-flex align-items-center m-0">
              <Form.Check label="Email" className="p-0" custom checked={notification.email===1?true:false} value={notification.email} onChange={handleChangeEmail(idx,notification.code)} id={idx+1} />
              </Form.Group>
              </div>
              {(notification.code==="SCP" || notification.code==="SCD") &&
              <Form.Row>
                <Col className="d-flex align-items-center"  xs={2}>Triger if Change is more than</Col>
              <Form.Group controlId="trigger_time" as={Col} xs={1} className="d-flex align-items-center m-0">
              <Form.Control type="text" value={notification.trigger_time} onChange={handleChangeNotification(idx,notification.code)} placeholder="Addtime" />
              </Form.Group>
              <Form.Group className="selectplugin  m-0" as={Col} xs={2} controlId="trigger_type">
              <Form.Control as="select" value={notification.trigger_type} onChange={handleChangeNotification(idx,notification.code)} >
                  {types}
               </Form.Control>
             </Form.Group>
            </Form.Row>
              }
              <div className="switchPlus buttonGrop mt-3 pb-0">
          <Button
            size="xs"
            variant="outline-primary"
            type="submit"
            onClick={()=>updateNotification(notification)}
          >Save</Button>
        </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        </React.Fragment>
      ))}
      </Accordion>
    </>

  )
}

export default NotificationMaster;