
import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import Utilities from '../../../../commonUtils/Utilities';
import { isNotNull, isArrayNotEmpty } from '../../../../commonUtils/validators';
import FormateCurrency from '../../../../commonUtils/FormateCurrency';


/**
 * Initial State Declaration
 */
const initialState = {
    price_data: '',
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

function PaymentDetails(props) {

    const [state, dispatch] = useReducer(reducer, initialState);


    return (
        <>


            <div className="header">
                <Row>
                <Col xs="2">Payment Method</Col>
                        <Col >Sur Charge</Col>
                        <Col >Amount</Col>
                        <Col xs="2">Mode of Payment</Col>
                        <Col xs="2">Ref Number</Col>
                        <Col xs="1">Remarks</Col>
                </Row>

            </div>

            <div className="passengerList">
                 {props.payDetails.map((pay, index) => (
                          (isNotNull(pay.psta) && pay.psta.toUpperCase()!=='PENDING') && (
                            <React.Fragment key={index}>
                            {isNotNull(pay.ptype) &&(
                             <Row>
                             <Col xs="2">{pay.formofpay!=="" && pay.formofpay!==undefined && pay.formofpay.includes("Offline")?"Offline Payment":pay.ptype}</Col>
                             <Col >{pay.csur!==0 && pay.csur?pay.csur:"N/A"}</Col>
                             <Col>{pay.ccode} {<FormateCurrency value={Utilities.priceFormat(parseFloat(pay.amt))} />}</Col>
                             <Col xs="2">{pay.paymode}</Col>
                             <Col xs="2">{pay.payref}</Col>
                             <Col xs="1">{pay.remarks}</Col>
                         </Row>)}
                        </React.Fragment>
                          )
                    ))}
            </div>
<div className='mobileRoominfo'>
            <div className="passengerList">
                 {props.payDetails.map((pay, index) => (
                          (isNotNull(pay.psta) && pay.psta.toUpperCase()!=='PENDING') && (
                            <React.Fragment key={index}>
                            {isNotNull(pay.ptype) &&(
                             <Row>
                             <Col xs="2"><span> Payment Method :</span> {pay.formofpay!=="" && pay.formofpay!==undefined && pay.formofpay.includes("Offline")?"Offline Payment":pay.ptype}</Col>
                             <Col> <span> Sur Charge :</span> {pay.csur!==0 && pay.csur?pay.csur:"N/A"}</Col>
                             <Col> <span> Amount :</span>  {pay.ccode} {<FormateCurrency value={Utilities.priceFormat(parseFloat(pay.amt))} />}</Col>
                             <Col xs="2"> <span> Mode of Payment :</span> {pay.paymode}</Col>
                             <Col xs="2"> <span> Ref Number :</span> {pay.payref}</Col>
                             <Col xs="1"> <span> Remarks :</span> {pay.remarks}</Col>
                         </Row>)}
                        </React.Fragment>
                          )
                    ))}
            </div>
            </div>



        </>
    )
}


export default PaymentDetails;
