import react from 'react-dom';
import moment from 'moment';
import { isNotNull } from './validators';
export const STA_SERVICE_FEE = "staFee";
/**
 * @description:This function will Calculate the all Price related calls
 * @author: Lakshmi
 * @param {*}
 * @function Utilities
 * @date : 02-09-2020
 */
class Utilities {
    addPrice=(famt,tamt,type) =>{
        if(type==="Add"){
        const fare=Number(famt) + Number(tamt);
        const tfare=(Math.round((Number(fare) + Number.EPSILON) * 100) / 100).toFixed(2);
        return tfare;
        }else if(type==="Cnt"){
        const fare=Number(famt) * Number(tamt);
        const tfare=(Math.round((Number(fare) + Number.EPSILON) * 100) / 100).toFixed(2);
        return tfare;
        }else if(type==="Sub"){
        const fare=Number(famt) - Number(tamt);
        const tfare=(Math.round((Number(fare) + Number.EPSILON) * 100) / 100).toFixed(2);
        return tfare;
        }else if(type==="divide"){
        const fare=Number(famt) / Number(tamt);
        const tfare=(Math.round((Number(fare) + Number.EPSILON) * 100) / 100).toFixed(2);
        return tfare;
        }
    }
    handleKeyUp(event) {
      var key = event.keyCode || event.charCode;
      if( key === 8 || key === 46 ){
      }else{
        this.formatPNR(event);
      }
    }
    formatNumber(n) {
      // removes any special characters
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g,"")
    }
    formatPNR(input) {
      var input_val = input.target.value
      if (input_val === "") { return; }
      if( input_val.length===3){
        var changedValue = input_val+"-";
        input.target.value = changedValue
    }
    // max len 10
    
      if(input_val.length>3){
        input_val = this.formatNumber(input_val)
          var finalValue =input_val.slice(0,3) + "-" +input_val.slice(3)
          input.target.value = finalValue
      }
    
    }

    subPrice=(famt,tamt) =>{
        const tfare=Math.round(Number(famt)-Number(tamt)).toFixed(2);
        return tfare;
    }
    

    //price format with two decimals
    priceFormat=(num) =>{

        if(!isNotNull(num)){
            num=0;
        }
         return num.toFixed(2);
     }

     // This function will provide the Uniq JSON after dplication removed
     getUniqData(jsonarray){
        console.log('getUniqData Entered----')
        let myArrSerialized = jsonarray.map(e => JSON.stringify(e));
        const mySetSerialized = new Set(myArrSerialized);
        const myUniqueArrSerialized = [...mySetSerialized];
        const myUniqueArr = myUniqueArrSerialized.map(e => JSON.parse(e));
        return myUniqueArr;
      }

      taxCalculation(taxesObj){
        let arr =0;
        console.log('object values..ddd...dd.......',taxesObj.length);
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
        if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.attdisc && !mak.tadisc && !mak.stadisc && !mak.dealmkp){
            arr = arr + parseFloat(Object.values(taxesObj[taxIndex]));
        }
        }
        console.log('object values............',arr);
        return arr;
      }
      dicountCalculation(taxesObj){
        let arr =0;
        if(taxesObj!==undefined){
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
        if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.YQ && !mak.OT && !mak.YR && !mak.dealmkp){
            arr = this.addPrice(arr,parseFloat(Object.values(taxesObj[taxIndex])),"Add")
        }
        }
        }
        console.log('Total Discount :',arr)
        return arr;
      }
      dealMkpCalculation(taxesObj){
        let arr =0;
        if(taxesObj!==undefined){
        for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
        var mak=taxesObj[taxIndex];
        if(!mak.attmkp && !mak.tamkp && !mak.stamkp && !mak.YQ && !mak.OT && !mak.YR){
            arr = this.addPrice(arr,parseFloat(Object.values(taxesObj[taxIndex])),"Add")
        }
        }
        }
        console.log('Total Discount :',arr);
        return arr;
      }
//Calculating the Service fee for Normal flow
calculateServiceFee(serviceList,type,request){  
  if(serviceList!==undefined && serviceList.length!==0){
  let totalServicePbk=0;
  let totalServicePtkt=0;
  let totalServiceFee=0;
  let service_fee=serviceList.filter((obj) => obj.type === type);
  if(service_fee!==undefined && service_fee.length!==0){
   if(service_fee[0].apl_on!==undefined){
   if(service_fee[0].apl_on==="Per Booking"){
    totalServicePbk=this.addPrice(service_fee[0].sf,service_fee[0].sup_fee,"Add");
    if(type==="STA"){
      localStorage.setItem(STA_SERVICE_FEE,service_fee[0].sf)
    }
   }else{
    let totalsf=0;
    let staFee=0;
    if(service_fee[0].adt!==0){
     const sf=this.addPrice(service_fee[0].adt,request.adt,"Cnt");
     totalsf=sf;
     staFee=service_fee[0].adt;
    }
    if(service_fee[0].chd!==0){
     const sf=this.addPrice(service_fee[0].chd,request.chd,"Cnt");
     const total=this.addPrice(totalsf,sf,"Add");
     totalsf=total;
     staFee=this.addPrice(staFee,service_fee[0].chd,"Add");
    }
    if(service_fee[0].inf!==0){
     const sf=this.addPrice(service_fee[0].inf,request.inf,"Cnt");
     const total=this.addPrice(totalsf,sf,"Add");
     totalsf=total;
     staFee=this.addPrice(staFee,service_fee[0].inf,"Add");
    }
    totalServicePtkt=this.addPrice(totalsf,service_fee[0].sup_fee,"Add");
    if(type==="STA"){
      localStorage.setItem(STA_SERVICE_FEE,staFee);
    }
   }
  }
  }
if(totalServicePbk!==0 && totalServicePtkt!==0){
  if(totalServicePbk!=="NaN" && totalServicePtkt!=="NaN" && totalServicePbk!==undefined && totalServicePtkt!==undefined){
    totalServiceFee=this.addPrice(totalServicePbk,totalServicePtkt,"Add");
    return parseFloat(totalServiceFee);
  }else{
    return 0.00;
  }
}else if(totalServicePbk!==0){
  if(totalServicePbk!=="NaN"){
    return parseFloat(totalServicePbk);
  }else{
    return 0.00;
  }
}else if(totalServicePtkt!==0){
  if(totalServicePtkt!=="NaN"){
    return parseFloat(totalServicePtkt);
  }else{
    return 0.00;
  }
}else{
  return 0.00;
}
}else{
  return 0.00;
}
}

//Calculating the Service fee for Normal flow
calculateServiceFeePax(serviceList,type,pax,count,paxcnt){  
    let totalService=0;
    if(serviceList.length!==0){
    let service_fee=serviceList.filter((obj) => obj.type === type);
    if(service_fee!==undefined && service_fee.length!==0){
      if(service_fee[0].apl_on!==undefined && service_fee[0].apl_on==="Per Booking"){
        totalService=this.addPrice(service_fee[0].sf,service_fee[0].sup_fee,"Add");
        totalService=totalService/paxcnt;
      }else if(service_fee[0].apl_on!==undefined && service_fee[0].apl_on==="Per Ticket"){
     if(service_fee[0].adt!==0 && pax==="ADT"){
      if(type!=="ATT"){
        let supFee=service_fee[0].sup_fee/paxcnt;
        totalService=this.addPrice(supFee,service_fee[0].adt,"Add");
       }
     }
     if(service_fee[0].chd!==0 && pax==="CHD"){
      if(type!=="ATT"){
        let supFee=service_fee[0].sup_fee/paxcnt;
        totalService=this.addPrice(supFee,service_fee[0].chd,"Add");
       }
     }
     if(service_fee[0].inf!==0 && pax==="INF"){
      if(type!=="ATT"){
        let supFee=service_fee[0].sup_fee/paxcnt;
        totalService=this.addPrice(supFee,service_fee[0].inf,"Add");
       }
     }
    }else{
      totalService=service_fee[0].sup_fee/paxcnt;
    }
  if(totalService===undefined){
    totalService=0;
  }
  }
  return Number(totalService);
 }else{
  return 0;
 }
  }

  //Calculating the Service fee for Normal flow
calculateServiceFeeGST(serviceList,type,request){  
  if(serviceList!==undefined && serviceList.length!==0){
  let service_fee=serviceList.filter((obj) => obj.type === type);
  if(service_fee!==undefined && service_fee.length!==0){
   if(service_fee[0].apl_on!==undefined){
   if(service_fee[0].apl_on==="Per Booking"){
    return Number(service_fee[0].sf);
   }else{
    let totalsf=0;
    if(service_fee[0].adt!==0){
     const sf=this.addPrice(service_fee[0].adt,request.adt,"Cnt");
     totalsf=sf;
    }
    if(service_fee[0].chd!==0){
     const sf=this.addPrice(service_fee[0].chd,request.chd,"Cnt");
     const total=this.addPrice(totalsf,sf,"Add");
     totalsf=total;
    }
    if(service_fee[0].inf!==0){
     const sf=this.addPrice(service_fee[0].inf,request.inf,"Cnt");
     const total=this.addPrice(totalsf,sf,"Add");
     totalsf=total;
    }
    if(totalsf!==0 && totalsf!="NaN"){
      return Number(totalsf);
    }else{
      return 0;
    }
   }
  }
  }else{
  return 0;
}
} 
}

  
//Retrun the List based on the Star rating
getHotelStartRating=(count)=>{
  let startList=[];
  for (var i = 0; i < count; i++) {
    startList.push(i);
  }
  return startList;
}
// Return the Status based on user rating
getUserStatus=(count)=>{
  var status="";
  if(count<=2){
    status="Good";
  }else if(count>2 && count<=4){
    status="Very Good"
  }else{
    status="Excellent";
  }
  return status;
}

hotelTaxCalculation(taxesObj){
  let arr =0;
  for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
  var mak=taxesObj[taxIndex];
  if(mak.OT){
      console.log('Total taxs :',mak);
      arr = arr + parseFloat(Object.values(taxesObj[taxIndex]));
  }
  }
  return arr;
}

hotelDiscount(taxesObj,type){
  let arr =0;
  if(type==="ta_user"){
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(mak.tadisc && mak.tadisc!==undefined && mak.tadisc!==0){
          arr =  mak.tadisc;
      }
      }
  }else if(type==="sta_user"){
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(mak.stadisc && mak.stadisc!==undefined && mak.stadisc!==0){
          arr =  mak.stadisc;
      }
      }
  }else if(type==="att_user"){
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(mak.attdisc && mak.attdisc!==undefined && mak.attdisc!==0){
          arr =  mak.attdisc;
      }
      }
  }
  return arr;
}

getMarkup(taxesObj,type){
  let arr =0;
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(type==="ATT"){
        if(mak.attmkp && mak.attmkp!==undefined && mak.attmkp!==0){
          arr =  mak.attmkp;
        }
      }else if(type==="TA"){
        if(mak.tamkp && mak.tamkp!==undefined && mak.tamkp!==0){
          arr =  mak.tamkp;
        }
      }else{
        if(mak.stamkp && mak.stamkp!==undefined && mak.stamkp!==0){
          arr =  mak.stamkp;
      }
      }
      }
  return arr;
}

getDiscount(taxesObj,type){
  let arr =0;
    for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
      var mak=taxesObj[taxIndex];
      if(type==="ATT"){
        if(mak.attdisc && mak.attdisc!==undefined && mak.attdisc!==0){
          arr =  mak.attdisc;
        }
      }else if(type==="TA"){
        if(mak.tadisc && mak.tadisc!==undefined && mak.tadisc!==0){
          arr =  mak.tadisc;
        }
      }
      }
  return arr;
}

hotelSTaxCalculation(taxesObj){
  let arr =0;
  for(let taxIndex=0; taxIndex < taxesObj.length; taxIndex++){
  var mak=taxesObj[taxIndex];
  if(mak.ST && mak.ST!==undefined && mak.ST!==0){
      arr =  mak.ST;
  }
  }
  return arr;
}

getBoardBasis(ha){
  let bbase =[];
  for (var key in ha) {
    if (ha.hasOwnProperty(key)) {
        var val = ha[key];
        if (key !== "2109" && key !== "2195" && key !== "2192" && key !== "2191" &&
            key !== "1073742787" && key !== "1073742551" && key !== "1073743274"
            && key !== "1073743275" && key !== "1073742861") {
              if(bbase.length===0){
              bbase.push(val.name);
              }else{
              bbase.push(", "+val.name);
              }
        }
    }
   }
  if(bbase.length===0){
    bbase.push("Room Only")
  }
  return bbase;
}
getBoardBasisHotel(ha){
  let bbase =[];
  for(let taxIndex=0; taxIndex < ha.length; taxIndex++){
    var mak=ha[taxIndex];
    if(mak.id!=="2109" && mak.id!=="2195" && mak.id!=="2192" && mak.id!=="2191"){
      bbase.push(mak.name);
      
    }
    }
   if(bbase.length===0){
      bbase.push("Room Only")
    }
  return bbase.join(", ");
}

calHotelServiceFee(serviceList,type,flowType){  
  let totalServicePbk=0;
  var userType=(type==="ta_user")?"TA":"STA";
  if(serviceList!==undefined && serviceList.length!==0){
  let service_fee=serviceList.filter((obj) => obj.type === userType);
  if(service_fee!==undefined && service_fee.length!==0){
    if(flowType==="All"){
      totalServicePbk=this.addPrice(service_fee[0].sf,service_fee[0].sup_fee,"Add");
    }else{
      totalServicePbk=service_fee[0].sf;
    }
  }
 if(totalServicePbk!==undefined && totalServicePbk!==0){
  return totalServicePbk;
 }else{
  return 0;
 }
}else{
  return 0;
}
}

calHotelServicGst(serviceList,type){  
  let totalServicePbk=0;
  if(serviceList
    !==undefined && serviceList.length!==0){
  let service_fee=serviceList.filter((obj) => obj.type === type);
  if(service_fee!==undefined && service_fee.length!==0){
      totalServicePbk=service_fee[0].sf;
  }
 if(totalServicePbk!==undefined && totalServicePbk!==0){
  return totalServicePbk;
 }else{
  return 0;
 }
}else{
  return 0;
}
}


getSTAServiceFee(serviceList,userType){  
  let totalServicePbk=0;
  if(serviceList!==undefined && serviceList.length!==0){
  let service_fee=serviceList.filter((obj) => obj.type === userType);
  if(service_fee!==undefined && service_fee.length!==0){
      totalServicePbk=service_fee[0].sf;
    }
  }
 if(totalServicePbk!==undefined && totalServicePbk!==0){
  return totalServicePbk;
 }else{
  return 0;
 }
}



//Calculating the Service fee for Normal flow
getServiceFee(serviceList,type){
  let totalServicePbk=0;
  var userType=(type==="ta_user")?"TA":"STA";
  if(serviceList!==undefined && serviceList.length!==0){
    if(userType==="TA"){
      let service_fee=serviceList.filter((obj) => obj.type === "MTA");
      if(service_fee!==undefined && service_fee.length!==0){
        totalServicePbk=service_fee[0].sf;
      }
    }else{
      let service_fee=serviceList.filter((obj) => obj.type === "TA");
      if(service_fee!==undefined && service_fee.length!==0){
        totalServicePbk=service_fee[0].sf;
      }
    }
 if(totalServicePbk!==undefined && totalServicePbk!==0){
  return totalServicePbk;
 }else{
  return 0;
 }
}else{
  return 0;
}
}

/**
 * @description:get date time based on domain name
 * @author:Azamuddin
 * @date:29-06-2021
 * @param {*} date 
 * @param {*} domain_name 
 * @returns 
 */
 getTimeZoneDate(date, domain_name) {
  let timeZone = 'Asia/Qatar';
  if (domain_name == 'QA') {
    timeZone = 'Asia/Qatar';
  }
  let datetimestr = new Date(date.toUTCString()).toLocaleString("en-US", { timeZone: timeZone })
  return moment(datetimestr, "MM/DD/YYYY, HH:mm:ss a").format('YYYY-MM-DD HH:mm:ss');
}

createGstObj(c,s,i,type){
  const req={
    "cgst_val": c,
    "igst_val":i,
    "sgst_val" : s,
    "type" : type
  }
  return req;
}

applyTDS(tdsPer,plbVal){
  return ((Number(tdsPer)/ 100) * (Number(plbVal))).toFixed(2);
}

applyGST(serviceFeeList,gstObj,taxes,srequest,product){
  //ATT Service Fee
  let gst_Values=[];
  let attSfee = 0; let attMkp = 0; let attDisc=0;
  let taSfee = 0; let taMkp = 0;   let taDisc=0;
  if(product==="Flight"){
    attSfee =this.calculateServiceFeeGST(serviceFeeList, "ATT", srequest);
    taSfee =this.calculateServiceFeeGST(serviceFeeList, "TA", srequest);
  }else if(product==="Hotel"){
    attSfee =this.calHotelServicGst(serviceFeeList, "MTA");
    taSfee =this.calHotelServicGst(serviceFeeList, "TA");
  }
  if(taxes.length!==0){
    attMkp =this.getMarkup(taxes,"ATT");
    taMkp =this.getMarkup(taxes,"TA");
    attDisc =this.getDiscount(taxes,"ATT");
    taDisc =this.getDiscount(taxes,"TA");
  }
  let attTotal=0;
  let taTotal=0;
  if(attSfee!==undefined && attSfee!==0){
    attTotal=Number(attSfee);
  }
  if(attMkp!==undefined && attMkp!==0){
    attTotal=Number(attTotal)+Number(attMkp);
  }
  if(taSfee!==undefined && taSfee!==0){
    taTotal=Number(taSfee)
  }
  if(taMkp!==undefined && taMkp!==0){
    taTotal=Number(taTotal)+Number(taMkp);
  }
  if(Number(attTotal)!==0 && Number(attDisc)!==0 && attDisc!==undefined){
    attTotal=Number(attTotal) - Number(attDisc);
  }
  if(Number(taTotal)!==0 && Number(taDisc)!==0 && taDisc!==undefined){
    taTotal=Number(taTotal) - Number(taDisc);
  }
  //Applying Same State
  if(gstObj.type==="same"){
    //ATT
    const attCgst = ((gstObj.cgst/ 100) * (Number(attTotal))).toFixed(2)
    const attSgst = ((gstObj.sgst/ 100) * (Number(attTotal))).toFixed(2)
    if(attCgst!==0 || attSgst!==0){
      gst_Values.push(this.createGstObj(Number(attCgst),Number(attSgst),0,"ATT"));
    }
    //TA
    const taCgst = ((gstObj.cgst/ 100) * (Number(taTotal))).toFixed(2)
    const taSgst = ((gstObj.sgst/ 100) * (Number(taTotal))).toFixed(2)
    if(taCgst!==0 || taSgst!==0){
      gst_Values.push(this.createGstObj(Number(taCgst),Number(taSgst),0,"TA"));
    }
  }else{
    //ATT
    const attIgst = ((gstObj.igst/ 100) * (Number(attTotal))).toFixed(2)
    if(attIgst!==0){
      gst_Values.push(this.createGstObj(0,0,Number(attIgst),"ATT"));
    }
    //TA
    const taIgst = ((gstObj.igst/ 100) * (Number(taTotal))).toFixed(2)
    if(taIgst!==0){
      gst_Values.push(this.createGstObj(0,0,Number(taIgst),"TA"));
    }
  }
  const request={
     "type" : gstObj.type,
     "sgst" : gstObj.sgst,
     "igst" : gstObj.igst,
     "cgst" : gstObj.cgst,
     "gst_invoice":gst_Values
  }
  return request;
 }

// GST on void
applyGSTOnVoid(attsf,tasf,gstObj,product){
  let gst_Values=[];
  let attSfee = attsf; 
  let taSfee = tasf;
  if(product==="Flight"){
    attSfee = attsf;
    taSfee = tasf;
  }else if(product==="Hotel"){
    attSfee = attsf;
    taSfee = tasf;
  }  
  //Applying Same State
  if(gstObj.type==="same"){
    //ATT
    const attCgst = ((gstObj.cgst/ 100) * (Number(attSfee))).toFixed(2)
    const attSgst = ((gstObj.sgst/ 100) * (Number(attSfee))).toFixed(2)
    if(attCgst!==0 || attSgst!==0){
      gst_Values.push(this.createGstObj(Number(attCgst),Number(attSgst),0,"ATT"));
    }
    //TA
    const taCgst = ((gstObj.cgst/ 100) * (Number(taSfee))).toFixed(2)
    const taSgst = ((gstObj.sgst/ 100) * (Number(taSfee))).toFixed(2)
    if(taCgst!==0 || taSgst!==0){
      gst_Values.push(this.createGstObj(Number(taCgst),Number(taSgst),0,"TA"));
    }
  }else{
    //ATT
    const attIgst = ((gstObj.igst/ 100) * (Number(attSfee))).toFixed(2)
    if(attIgst!==0){
      gst_Values.push(this.createGstObj(0,0,Number(attIgst),"ATT"));
    }
    //TA
    const taIgst = ((gstObj.igst/ 100) * (Number(taSfee))).toFixed(2)
    if(taIgst!==0){
      gst_Values.push(this.createGstObj(0,0,Number(taIgst),"TA"));
    }
  }
  const request={
     "type" : gstObj.type,
     "sgst" : gstObj.sgst,
     "igst" : gstObj.igst,
     "cgst" : gstObj.cgst,
     "gst_invoice":gst_Values
  }
  return request;
 }
}

export default new Utilities()


