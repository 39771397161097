/**
 * @description : Retrieval of reissue data from summary and populating of the data in summary.
 * @author: Azamuddin
 * @date : 27-01-2021
 * @serviceBy : Vinod Kumar
 * @componentsUsed : FlightSummaryFareDetails,FlightsSummaryBookingDetails,FlightSummarySectors,FlightSummaryPaxDetails
 */

 import React, { useEffect, useReducer, useRef, useState } from 'react';
 import { Link, useHistory } from 'react-router-dom';
 import { isArrayNotEmpty, isNotNull } from '../../commonUtils/validators';
 import FlightSummarySectorHeader from '../summary/FlightSummarySectorHeader';
 import FlightSummaryBookingDetails from '../summary/FlightSummaryBookingDetails';
 import FlightSummaryFareDetails from '../summary/FlightSummaryFareDetails';
 import FlightSummaryPaxDetails from '../summary/FlightSummaryPaxDetails';
 import FlightSummarySegmentDetails from '../summary/FlightSummarySegmentDetails';
 import { getairportData } from '../summary/operations';
 const JSON = require('circular-json');

 /**
 * Initial State Declaration
 */
 const initialState = {

   reIssueResponse: [], bkng_det: [], flow_type: '', bktype: '', company_id: '', pax_det: [], seg_det: [], no_trip: false, fare_det: [], booking_id: "", ticketsVoided: [], ticketsVoidedCancelled: [], airport_data: [], pay_det: [],b_status:'',user_cur:""
 };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

   switch (action.type) {

     default:
       return { ...state, [action.type]: action.payload };
   }
 };

 function FlightReissue(props) {
   const [state, dispatch] = useReducer(reducer, initialState);
   const history = useHistory();
   const ref = useRef();
   const [editMore, setEditMore,] = useState(false);
   const [share, setShare,] = useState(false);
   const [editItinerary, setEditItinerary,] = useState(false);
   const [editPayment, setEditPayment,] = useState(false);


   /**
    * @description : the resiisue response after itereated response comes here to show as per UI design
    * @author: azamuddin
    * @servicesBy : Rambabu,Vinod
    * @date : 27-01-2021
    */
   useEffect(() => {
     if (props.res && isNotNull(props.res)) {
       dispatch({ type: 'reIssueResponse', payload: props.res });

       //making structure for each components
       let bookingData = []
       let booking_ref = '';
       let unique_airport_codes = [];
       let unique_airline_codes = [];
       let bid = ''
       if(props.bkngDetails && props.bkngDetails.length > 0) {
         bookingData = props.bkngDetails;
         dispatch({ type: 'bkng_det', payload: props.bkngDetails })
         dispatch({ type: 'flow_type', payload: props.bkngDetails[0].flow_type });
         dispatch({ type: 'bktype', payload: props.bkngDetails[0].bktype });
         dispatch({ type: 'b_status', payload: props.bkngDetails[0].booking_status })

         dispatch({ type: 'company_id', payload: props.bkngDetails[0].company_id })
         dispatch({ type: 'booking_id', payload: props.bkngDetails[0].booking_id })
         dispatch({ type: 'user_cur', payload: props.bkngDetails[0].usr_curr_code })
         
         bid = props.bkngDetails[0].booking_id
       }
       for (let r in props.res) {
         let data_ = props.res[r]

         //booking details construction for reIssue
         if (isArrayNotEmpty(data_.bkng_det)) {
           bookingData = data_.bkng_det;
           dispatch({ type: 'bkng_det', payload: data_.bkng_det })
           dispatch({ type: 'flow_type', payload: data_.bkng_det[0].flow_type });
           dispatch({ type: 'bktype', payload: data_.bkng_det[0].bktype });
           dispatch({ type: 'b_status', payload: data_.bkng_det[0].booking_status })

           dispatch({ type: 'company_id', payload: data_.bkng_det[0].company_id })
           dispatch({ type: 'booking_id', payload: data_.bkng_det[0].booking_id })
           dispatch({ type: 'user_cur', payload: data_.bkng_det[0].usr_curr_code })
           bid = data_.bkng_det[0].booking_id
         }
         // pax details reissued
          if (isArrayNotEmpty(data_.pax_det)) {
           dispatch({ type: 'pax_det', payload: data_.pax_det })
         }
         // segment details
          if (isArrayNotEmpty(data_.seg_det)) {
           //below code collecting all src and destination airport codes

           let values = [];
           let airCodes = [];
           for (var i = 0; i < data_.seg_det.length; i++) {
             airCodes.push(data_.seg_det[i]['tkt_airline']);
             if (data_.seg_det[i].hasOwnProperty('seg_source') || data_.seg_det[i].hasOwnProperty('seg_destination'))
               values.push(data_.seg_det[i]['seg_source']);
             values.push(data_.seg_det[i]['seg_destination']);
           };


           //Here find uniqu airport codes
           unique_airport_codes = uniquearray(values);
           unique_airline_codes = uniquearray(airCodes);
           var newArray = unique_airline_codes.toString();
           dispatch({ type: 'aircodes', payload: newArray })
           //Here load airport data based on airport codes
           if (isNotNull(unique_airport_codes)) {
             const req_code = {
               searchCriteria: unique_airport_codes
             }
             getairportData(req_code).then((res) => {

               dispatch({ type: 'airport_data', payload: res });
             });
           }
           dispatch({ type: 'seg_det', payload: data_.seg_det })

           // fare details are construcetd with tids fetched from fare details response of summary
           if(props.fareData!==undefined &&  props.fareData.length > 0) {
             let fare_resp = []
           for(let val of data_.seg_det) {
             if(val.tid) {
               fare_resp = props.fareData.filter((obj) =>
                 obj.tid == val.tid
               )
             }
           }
           dispatch({type:"fare_det",payload:fare_resp})
         }
         } else if (isNotNull(data_['pay_det'])) {
           dispatch({ type: 'pay_det', payload: data_.pay_det})
         }
         if (isArrayNotEmpty(data_.bkng_det) && isArrayNotEmpty(data_.pax_det) &&
           isArrayNotEmpty(data_.seg_det) && isArrayNotEmpty(data_.fare_det)) {
           dispatch({ type: 'no_trip', payload: true });

         }
       }

     }
   }, [props.res])


   function uniquearray(array) {
     var uniqueArray = [];

     // Loop through array values
     for (let i = 0; i < array.length; i++) {
       if (uniqueArray.indexOf(array[i]) === -1) {
         uniqueArray.push(array[i]);
       }
     }
     return uniqueArray;
   }



   const openMOre = () => {
     if (editMore) {
       setEditMore(false)
     } else {
       setEditMore(true)
     }
   }
   const openeditItinerary = () => {
     if (editItinerary) {
       setEditItinerary(false)
     } else {
       setEditItinerary(true)
     }
   }
   const openshare = () => {
     if (share) {
       setShare(false)
     } else {
       setShare(true)
     }
   }

   const openeeditPayment = () => {
     if (editPayment) {
       setEditPayment(false)
     } else {
       setEditPayment(true)
     }
   }
   return (
     <>
       {isArrayNotEmpty(state.bkng_det) && isArrayNotEmpty(state.seg_det) && isArrayNotEmpty(state.fare_det) && isArrayNotEmpty(state.pax_det) && isArrayNotEmpty(state.airport_data) ? (
         <>
           {/* booking details */}
           <div className="reissueHistory">
             <h6 className="ezyIcon icon-moneySettings"> Exchange Ticket Details</h6>
             <div className="resultSection boxShadow bg-white summary mb-4">
               <h5 className="title d-flex justify-content-between">Transaction Summary</h5>


               {/* Booking Details Section */}
               {isArrayNotEmpty(state.bkng_det) && (<FlightSummaryBookingDetails bk_det={state.bkng_det} />)}
               <br />
             </div>


             {/* Segment Details Section */}
             <div className="resultSection bg-white boxShadow pb-0">



               <h5 className="title d-flex justify-content-between">Flight Details</h5>
               {(isArrayNotEmpty(state.seg_det) &&  isNotNull(state.bktype)) && (
                   <>
               <FlightSummarySectorHeader supplier={props.supplier} data={state.seg_det} airport_data={state.airport_data} triptype={props.triptype} sectorTab={props.bktype} isEdit={false}/>
                  {props.triptype !== "3" &&
                 <>
                  <FlightSummarySegmentDetails supplier={props.supplier} data={state.seg_det} airport_data={state.airport_data} bktype={props.bktype} isEdit={false} />
                  </>
              }
              </>
                 )}

             {/* Passenger details for Reissue */}
               <h5 className="title mt-3 mb-2">Passenger Details</h5>
               {/* Pax Details Section */}
               {/*  passeneger details for split fare onward pax and return pax we are showing seperatly */}
               {isArrayNotEmpty(state.pax_det) && (<FlightSummaryPaxDetails pax_det={state.pax_det} enableVoidBooking={state.enableVoidBooking} enableVoidCancel={state.enableVoidCancel} tktsVoided={state.ticketsVoided} voidedCancelled={state.ticketsVoidedCancelled} disableCheckbox={state.disableCheckbox} bookDetails={state.bkng_det[0]} reIssuedOld={true} refundAvailable={state.refundAvail} />)}
             </div>

             {/* Fare details for Reissue */}

             <div className="resultSection boxShadow bg-white PassengerDetails pb-0">
             {state.fare_det.length !== 0 && state.fare_det !== undefined &&
               <h5 className="title mb-2 d-flex justify-content-between">Fare Details ({state.user_cur}) </h5>
             }
               {/* <span className="moreDetails bookingInfo onclickTag ezyIcon icon-more" ref={ref} > */}

                 {/* <div className={"showDetails " + (editPayment ? 'active' : 'inactive')}>
                   <ul className="d-flex flex-column">
                     <li>
                       <Link to="#">Edit Fare</Link>
                     </li>
                     <li>
                       <Link to="#">Update Fare</Link>
                     </li>

                   </ul>
                 </div>   */}
                 {/* </span> */}
                 
               {/*  <span className="itnry"><em>Itinerary Ref # :</em> EZ125198-1</span> */}
               {/* Fare details section */}
               {/* fare details onward fare and return fare we are showing seperaly */}
               {state.fare_det.length !== 0 && state.fare_det !== undefined &&
                 <FlightSummaryFareDetails faredata={state.fare_det} bookedTo={state.bkng_det[0].buser_type} bktype={state.b_status} userCurrency={props.userCurrency} bk_region_type={props.bk_region_type}/>
               }
             </div>
           </div>
         </>
       ) : ""}
     </>
   )
 }
 export default FlightReissue