import { apiGetHelper, apiPostHelper } from '../../components/commonUtils/helper';
import commonServices from '../../services/commonService/AttCommonSerivce';
import dashboardService from '../../services/dashboard/dashboardService';
import FlightService from '../../services/bookingService/flight/FlightService';
import HotelService  from '../../services/bookingService/hotel/HotelService';
/**
 * 
 * @description get dashboard related services 
 * @author: Azamuddin
 * @date: 12-09-2020
 */
const API_URL = process.env.REACT_APP_B2B_URL
const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH


/**
 * @author :Azamuddin
 * @description : get latest 7 bookings 
 * @date : 1-10-2020
 * ----id=>company id
 */
export const getRecentData = async (obj) => {
    let customList = [];
    await dashboardService.getrecentData(obj).then(response => {
        if (response.data.suc) {
            customList = response.data.res;
        }
    });
    return Promise.resolve(customList);
}
export const getMyReport = async (obj) => {
    let customList = [];
    await dashboardService.getMyReport(obj).then(response => {
        if (response.data.suc) {
            customList = response.data.res;
        }
    });
    return Promise.resolve(customList);
}

/**
 * @author :Azamuddin
 * @description : get quick Search Response 
 * @date : 1-10-2020
 * ----id=>company id
 */
export const getbookingData = async (obj) => {
    let customList = [];
    await dashboardService.getbookinglistData(obj).then(response => {
        if (response.data.suc) {
            customList = response.data.res;
        }
    });
    return Promise.resolve(customList);
}
/**
 * fetch branches for the user
 * getOfficeIdList
 * @param {} req 
 */
export const getOfficeIdList = async () => {
    let getOfficeIdList = [];
    await commonServices.getOfficeIdList().then(response => {
        getOfficeIdList = response;
    });
    return Promise.resolve(getOfficeIdList);
}
// fetchcompany
export const fetchcompany = async (obj) => {
    let fetchcompany = [];
    await commonServices.fetchcompany(obj).then(response => {
        fetchcompany = response;
    });
    return Promise.resolve(fetchcompany);
}
export const getBranchList = async (obj) => {
    let branchesList = [];
    await commonServices.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}
/**
 * @author :Azamuddin
 * @description : get quick Search Response 
 * @date : 1-10-2020
 * req--search criteria
 * 
 */
export const getBookingUsingCriteria = async (req) => {
    let resList = [];
    await dashboardService.getBooking(req).then(response => {
        if (response.data.suc) {
            resList = response.data.res;
        }
    });
    return Promise.resolve(resList);
}
/**
 * @author :Azamuddin
 * @description : get quick Search Response 
 * @date : 1-10-2020
 * 
 */
export const quickSearch = async (req) => {
    let resList = [];
    await dashboardService.quicksearch(req).then(response => {
        if (response.data.suc) {
            resList = response.data.res;
        }
    });
    return Promise.resolve(resList);
}

export const retrivePrice = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/pnr/segmentPrice`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);

}

export const saveSegDetails = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/pnr/itinerary-save`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);

}

//pnr search
export const pnrSearch = async (pnr) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            pnr: pnr
        }
    }
    await apiPostHelper(`${API_URL_COMMON}/pnr/retrieve`, pnr).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Save Remainder
export const saveRemainder = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/reminder/save`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//update Remainder
export const updateRemainder = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/reminder/update`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


//get Remainders
export const getUserList = async (type,id) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiGetHelper(`${API_URL}/att/reminder/user_list?utype=`+type+"&cid="+id, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//update Remainder
export const getAllRemainders = async (cid,type,uid) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiGetHelper(`${API_URL}/att/reminder/list?cid=`+cid+"&admin="+type+"&uid="+uid).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Delete Remainder
export const deleteRemainder = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/reminder/delete`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Close Remainder
export const closeStatus = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/reminder/status_update`,obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
//update Alert
export const getAllAlerts = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/notification/alert_list`,obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//update Alert
export const getAlertView = async (id) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiGetHelper(`${API_URL}/att/notification/alert_view?alertId=`+id).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//update Remainder
export const getAllPackages = async () => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    const obj={
        "type":"NOT_ALL"
    }
    await apiPostHelper(`${API_URL}/att/cms/retrievepkg`,obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Get Updated CL  
// ========================================================================
export const getCrediLimit = async (req) => {
    let clRes = [];
    await commonServices.getUpdateCreditLimit(req).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}

//Send Package Email
export const sendPackageEmail = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/dashboard/package_email`,obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveBooking = async (saveObj) => {
    let finalRes = [];
    await FlightService.saveManualBooking(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//pnr search
export const getPNRDetails = async (request) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL_COMMON}/pnr/retrieve`, request).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Saveing the Booking details
// ========================================================================
export const fetchEmails = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/booking/fetch_emails`,obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Call OLD Itinarary details
export const sendManualEmail = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/booking/share-itinerary`, obj, headers).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

/**
     * @description : fetch hotels to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
 export const fetchHotelList = async (id) => {
    let countriesList = [];
    await commonServices.fetchHotels(id).then(response => {
        countriesList = response
    })
    return Promise.resolve(countriesList);
   }


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveHotelBooking = async (saveObj) => {
    let finalRes = [];
    await HotelService.saveManualBooking(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const getAllLocations = async () => {
    let finalRes = {};
    const req = {
        type: "SA"
        }
    await apiPostHelper(`${API_URL}/att/cms/retrieveloc`,req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const getBookingsData = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/dashboard/loaddashboard`,obj, req).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

export const getCompanyBookingsData = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/dashboard/loaddashboard`,obj, req).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}




