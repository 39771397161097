import OfficeIdConfig from "../../../../services/administration/OfficieIdConfig"
/**
 * @description: CRUD operations for office ID Master
 * @author: shaikh Arshad
 * @param {*}
 * @function office_ID_Master
 * @date : 10-03-2023
 */

export const getOIDMasterData = async (id) => {
    let oidMaster = [];
    await OfficeIdConfig.getOIDMasterData().then(response => {
        if (response.data.suc) {
            oidMaster = response.data.res;
        }
    });
    return Promise.resolve(oidMaster);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    const saveObj = {
        office_id: obj.office_id,
        crby:obj.crby
        };
    await OfficeIdConfig.saveOIDMaster(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const update = async (obj) => {

    let finalRes = {};
    await OfficeIdConfig.updateOID(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
export const deletion = async (obj) => {
    const deleteObj = {
       id: obj.id
    }
    let finalRes = {};
    await OfficeIdConfig.deleteOID(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
