
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useState, useEffect, useReducer } from 'react';
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";
import DateUtils from '../../../commonUtils/DateUtils';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import SegmentDetails from './SegmentDetails';
import PaxDetails from './PaxDetails';
import PaxFareDetails from './PaxFareDetails';
import Utilities from '../../../commonUtils/Utilities';
const _ = require('lodash')

//import HotelDetailMap from '../../../bookingFlows/hotel/map/HotelDetailMap';
//import GoogleMapReact from 'google-map-react';
// Create styles
const styles = StyleSheet.create({
  page: { marginTop: '0', height: '100%', padding: '30px', paddingTop: '20px' },
  main: {
    width: '100%',
    height: '140mm',
    marginBottom: '15px',
    fontFamily: 'Roboto',
    backgroundColor: '#fff',

    margin: '10 0 ',


  },

  table: { margin: '0 0 10px', border: '1 solid #ccc', borderRadius: '4px', padding: '5px 10px 10px' },
  mainSec: {

    fontFamily: 'Roboto',
    width: '100%',


  },
  img: {
    width: 60,
    height: '24',
    padding: '1',
  },
  hotelImages: {
    width: 80,
    height: 80,
    borderRadius: '10px',
  },
  sta: {
    width: '10px',
    height: '10px',
  },
  mapIcon: {
    width: '8px',
    marginRight: '4px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10',
    fontFamily: 'Roboto',
  },
  heading: {
    fontSize: '20px',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  hedingunderline: {
    textDecoration: 'underline',
    marginBottom: '20px',
  },
  subheading: {
    fontSize: '14px',
    color: '#475F7B',
    textAlign: 'center',
    marginBottom: '10px',
  },
  tableIn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth:'60%',
    width:'60%',

  },
  adres:{
    display: 'flex',
    flexDirection: 'column',
    minWidth:'40%',
    width:'40%',
  },
  logo: {
    display: 'flex',
    justifyContent: 'top',
    paddingRight: '15px',
  },
  companyName: {
    fontSize: '12px'
  },
  lablList: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '6px',
    flexWrap:'wrap',
  },
  lablListPlus: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '8px',
    width: '400px'
  },
  tid: {
    color: '#475F7B',
  },
  confStatus: {
    color: '#1DAD81',
  },
  Secheading: {
    fontSize: '12px',
    backgroundColor: '#e7e7e7',
    padding: '4px 6px',
    borderRadius: '4px',
    margin: '0 0 15px',
  },
  hotelPart: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px,'
  },
  hotelImage: {
    marginRight: '10px',
    width: '160px'
  },
  hotelName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '6px'
  },
  maneHtl: {
    fontSize: '12px',
    marginRight: '10px',
  },
  hotelAdres: {
    display: 'flex',
    flexDirection: 'row',
  },
  adress: {
    color: '#888888',
    fontSize: '10px',
  },
  infolist: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  time: {
    color: '#F26F22',
  },
  roomType: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    marginBottom: '6px',
  },
  tableHeading: {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
    color: '#00367A',
    fontSize: '10px',
  },
  headingTaxt: {
    width: '12%',
    paddingRight:'4px',

  },
  headingType:{
    width: '9%',
    paddingRight:'4px',
  },
  headingTicket:{
    width: '20%',
    paddingRight:'4px',
  },
  headingTravel:{
    width: '22%',
    paddingRight:'4px',
  },
  headingTravelplus:{
    width: '15%',
    paddingRight:'4px',
  },
  headingTotal:{
    textAlign:'right',
    width:'15%',
  },
  tableData: {
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
    color: '#888',
    fontSize: '8px',
    borderBottom: '1px solid #ccc',
  },
  status: {
    color: '#1DAD81 ',
  },
  SpecialRequest: {
    border: '1px dashed #CACED5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
  },
  note: {
    border: '1px dashed #CED7E5',
    marginTop: '5px',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '11px',
    backgroundColor: '#F3F4F8',
  },
  spCont: {
    color: '#888',
    fontSize: '10px',
    marginTop: '4px',
    lineHeight: '1.5px',
  },
  noteTitl: {
    color: '#00367A',
    fontWeight: 'bold',
  },
  dueAtLay: {
    fontSize: '10px',
    fontWeight: 'normal',
    color: '#888',
    margin: '10px 0',
  },
  maplayout: {
    width: '1000px',
  },
  copy: {
    textAlign: 'right',
    fontSize: '8px',
    paddingTop: '10px',
    fontStyle: 'italic',
    color: '#00367A',
    borderTop:'1px solid #ccc'
  },
  strongText: {
    fontSize: '12px',
  },
  bookinglablList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: '1px solid #ccc',
    marginTop: '5px',
  },
  bAdres:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap:'wrap',
  },
  col3: {
    width: '100%',
    maxWidth: '33%',
  },
  iItineraryName:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    fontSize:'10px',
    backgroundColor:'#f3f3f3',
    padding:'6px',
    borderRadius:'6px'
  },
  iItineraryNameInfo:{
    display:'flex',
  },
  ref:{
    paddingLeft:'120px',
    display:'flex',
    color:'#475F7B',
  },
  refID:{
    color:'#888888',
  },

  tableRow:{
    borderBottom:'1px solid #ccc'
  },
  grandtotal:{
    display:'flex',
    fontSize:'10px',
    fontWeight:'bold',
    alignItems:'flex-end',
    justifyContent:'flex-end',

    maxWidth:'100%',
    padding:'10px 0',
  },
  grandtotal:{
    display:'flex',
    fontSize:'10px',
    fontWeight:'bold',
    alignItems:'flex-end',
    justifyContent:'flex-end',
    maxWidth:'100%',
    padding:'10px 0',
  },
  statusRefund:{
    color:'#FF0000',
  },
  branchName:{
    fontSize:'14px',
  },
  farepart:{
    padding:'0 10px',
    },
    subtitl:{
      fontSize:'10px',
      fontWeight:'bold',
      paddingBottom:'6px',
    },
});
/**
 * Initial State Declaration
 */
const initialState = {
  source: '', destination: '', duration: '', travel_type: '', departure_date: '', multi_city_arr: []
};

// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

/* @description:This is Main component for the PDF
     * @author: Lakshmi
     * @date : 18-05-2021
     * @param {*} code
     * @param {*} val
     */
function FlightInvoice(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cur, setCur] = useState("QAR")
  const [paxList, setPaxList] = useState([])
  const [fareList, setFareList] = useState([])
  const [refundList, setRefundList] = useState([])
  const [voidList, setVoidList] = useState([])
  const [onwardList, setOnwardList] = useState([])
  const [returnList, setReturnList] = useState([])
  const [refundPaxList, setRefundPaxList] = useState([])
  const [voidPaxList, setVoidPaxList] = useState([])

  useEffect(() => {
    if (props.response !== undefined) {
      if (props.response.data !== undefined) {
        for (let r in props.response.data) {
          let data = props.response.data[r];
          if (isNotNull(data['pax_det'])) {
            setPaxList(data.pax_det)
          }
          if (isNotNull(data['seg_det'])) {
            let onwards = data.seg_det.filter((obj) => obj.journey_type === "O");
            //seOnwardtLayoverAirport(onwards[0].seg_destination)
            setOnwardList(onwards);
            let returns = data.seg_det.filter((obj) => obj.journey_type === "R");
            if(returns!==undefined && returns[0]!==undefined){
              setReturnList(returns);
              //setReturnLayoverAirport(returns[0].seg_destination)
            }
          }
          if (isNotNull(data['fare_det'])) {
            setFareList(data.fare_det)
          }
          if (isNotNull(data['refund_det'])) {
            setRefundList(data.refund_det)
          }
          if (isNotNull(data['void_det'])) {
            setVoidList(data.void_det)
          }
          if (isNotNull(data['refund_pax_det'])) {
            setRefundPaxList(data.refund_pax_det)
          }
          if (isNotNull(data['void_pax_det'])) {
            setVoidPaxList(data.void_pax_det)
          }
        }
      }
    }

  }, [props.response])

  const calculateTotal = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.total, "Add");
    }, 0);
    return total;
  }

  return ((props.bookingData !== undefined && props.response !== undefined && onwardList.length !== 0) ? (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.heading}>
          <Text style={styles.hedingunderline}>Flight {props.response.invoicetype}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableIn}>
            <View style={styles.companyDetails}>
              <View style={styles.logo}>
                <Text >
                  <Image style={styles.img} src={logo} />
                </Text>
              </View>
              <View>
              <View style={styles.branchNamepart}>
              <Text style={styles.branchName}>{props.bookingData.branch_name},</Text>
                </View>
                <View style={styles.bAdres}>
                  <Text style={styles.lablList}>{props.bookingData.branch_add}, </Text>
                  <Text style={styles.lablList}>{props.bookingData.branch_lmark} </Text>
                  <Text style={styles.lablList}>{props.bookingData.branch_city} , {props.bookingData.branch_cnt}</Text>
                </View>

                <View style={styles.lablList}>
                  <Text style={styles.taskLabl}>Conatct No : </Text>
                  <Text style={styles.tid}>{props.bookingData.branch_phone}</Text>
                </View>
                <View style={styles.lablList}>
                  <Text style={styles.taskLabl}>Email Id : </Text>
                  <Text style={styles.tid}>{props.bookingData.branch_email}</Text>
                </View>

              </View>
            </View>
            {props.response.invoicetype === "INVOICE" &&
            <View style={styles.adres}>
              <View>
                <Text style={styles.strongText}>Billing To</Text>
              </View>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Agency Name: </Text>
                <Text style={styles.tid}>{props.bookingData.company_name}</Text>
              </View>
              <View style={styles.lablList}>
              <Text style={styles.taskLabl}>Corporate Office : </Text>
              </View>
              <View style={styles.lablList}>
                <Text style={styles.tid}>{props.bookingData.comp_add}</Text>
                <Text style={styles.tid}>{props.bookingData.comp_lmark}</Text>
                <Text style={styles.tid}>{props.bookingData.comp_city},{props.bookingData.comp_cnt} </Text>
              </View>
            </View>
                }
          </View>

          <View style={styles.bookinglablList}>
            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Booking ID : </Text>
                <Text style={styles.tid}>{props.bookingData.booking_id}</Text>
              </View>
            </View>
            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Booking Date : </Text>
                <Text style={styles.tid}>{DateUtils.prettyDate(props.bookingData.booking_date, "ddd, MMM DD, YYYY ")}</Text>
              </View>
            </View>
            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Booking Status: </Text>
                <Text style={styles.tid}>
                  <Text style={styles.confStatus}>{props.bookingData.booking_status_str}</Text>
                </Text>
              </View>
            </View>

            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Booked By : </Text>
                <Text style={styles.tid}>{props.bookingData.booked_by}</Text>
              </View>
            </View>
            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Conatct No : </Text>
                <Text style={styles.tid}>{props.bookingData.bookedby_contactno}</Text>
              </View>
            </View>
            <View style={styles.col3}>
              <View style={styles.lablList}>
                <Text style={styles.taskLabl}>Email : </Text>
                <Text style={styles.tid}>{props.bookingData.bookedby_email}</Text>
              </View>
            </View>
          </View>

        </View>
        <View style={styles.flightSec}>
        {(paxList.length!==0 || fareList.length!==0) &&
          <>
          <View style={styles.flightbookingInfo}>
            <View style={styles.iItineraryName}>
              <Text style={styles.iItineraryNameInfo}>Flight Details &nbsp;  </Text>
              <Text style={styles.statusinfo}> Status : <Text style={styles.status}>{"Confirmed"}</Text></Text>
            </View>
            {onwardList && onwardList[0]!==undefined &&
             <SegmentDetails  onwardList={onwardList} airport_data={props.airport_data} type={"Onward"}/>
            }
            {returnList && returnList[0]!==undefined &&
             <SegmentDetails returnList={returnList} airport_data={props.airport_data} type={"Return"}/>
             }
            </View>
          </>
         }
        {fareList.length!==0 && props.response.invoicetype !== "ITINERARY" &&
          <>
          <View style={styles.farepart}>
            <View>
              <Text style={styles.subtitl}>Fare Details ({cur})</Text>
            </View>
            <View style={styles.tableHeading}>
            {props.response.invoicetype==="INVOICE" ?
              <Text style={styles.headingTravelplus}>Traveller1 </Text>:<Text style={styles.headingTravel}>Traveller2 </Text>
            }
              <Text style={styles.headingType}>Type </Text>
              <Text style={styles.headingTicket}>Ticket#</Text>
              <Text style={styles.headingTaxt}>{props.bookingData.bktype!=="REISSUE"?"Base Fare":"Fare Diffrence"} </Text>
              <Text style={styles.headingTaxt}>{props.bookingData.bktype!=="REISSUE"?"Taxes":"Tax Diffrence"}</Text>
              {props.response.invoicetype==="INVOICE" &&
              <Text style={styles.headingTaxt}>Supplier Fee</Text>
              }
              <Text style={styles.headingType}>Discount</Text>
              {props.bookingData.bktype==="REISSUE" &&
              <Text style={styles.headingType}>Penalty</Text>
              }
              <Text style={styles.headingTotal}>Total Amount</Text>
            </View>
            {fareList.map((pax, index) => (
              <View style={styles.tableData} wrap={false} key={index} >
                    {props.response.invoicetype==="INVOICE" ?
              <Text style={styles.headingTravelplus}>{pax.paxname} </Text>:<Text style={styles.headingTravel}>{pax.paxname} </Text>
            }
                      <Text style={styles.headingType}>
                        {pax.ptype === "ADT" &&
                          <Text style={styles.spTitl}>Adult</Text>
                        }
                        {pax.ptype === "CHD" &&
                          <Text style={styles.spTitl}>Child</Text>
                        }
                        {pax.ptype === "INF" &&
                          <Text style={styles.spTitl}>Infant</Text>
                        }
                      </Text>
                      <Text style={styles.headingTicket}> {pax.tkt_no}</Text>
                      <Text style={styles.headingTaxt}> {pax.basefare}</Text>
                      <Text style={styles.headingTaxt}> {pax.taxes}</Text>
                      {props.response.invoicetype==="INVOICE" &&
                      <Text style={styles.headingTaxt}> {pax.supcharge}</Text>
                      }
                      <Text style={styles.headingType}> {pax.disc}</Text>
                      {props.bookingData.bktype==="REISSUE" &&
                      <Text style={styles.headingType}>{pax.penalty}</Text>
                      }
                      <Text style={styles.headingTotal}> {pax.total}</Text>
                    </View>
            ))}
            <View style={styles.grandtotal}>
            <Text>Grand Total {cur} {calculateTotal(fareList)}</Text>
            </View>
            </View>
          </>

        }

        {paxList.length!==0 && props.response.invoicetype === "ITINERARY" &&
          <>
          <PaxDetails paxList={paxList} flowType={"Passenger"}/>
          </>
        }
 </View>
        {(refundList.length!==0 || refundPaxList.length!==0) &&
          <>
            <View style={styles.flightSec}>
            <View style={styles.iItineraryName}>
              <Text style={styles.iItineraryNameInfo}>Flight Details &nbsp;  </Text>
              <Text style={styles.statusinfo}> Status : <Text style={styles.statusRefund}>{"Refund"}</Text></Text>
            </View>
            {onwardList && onwardList[0]!==undefined &&
             <SegmentDetails  onwardList={onwardList} airport_data={props.airport_data} type={"Onward"}/>
            }
            {returnList && returnList[0]!==undefined &&
             <SegmentDetails returnList={returnList} airport_data={props.airport_data} type={"Return"}/>
             }

            {refundList.length!==0  && props.response.invoicetype !== "ITINERARY" &&
            <PaxFareDetails flowType={"Refund"} type={props.response.type} fareList={refundList}/>
            }
          {refundPaxList.length!==0  && props.response.invoicetype === "ITINERARY" &&
          <>
          <PaxDetails paxList={refundPaxList} flowType={"Refund Pax"}/>
          </>
          }
           </View>
          </>
        }
        {(voidList.length!==0 || voidPaxList.length!==0) &&
          <>
            <View style={styles.flightSec}>
            <View style={styles.iItineraryName}>
              <Text style={styles.iItineraryNameInfo}>Flight Details &nbsp;  </Text>
              <Text style={styles.statusinfo}> Status : <Text style={styles.status}>{"Void"}</Text></Text>
            </View>
            {onwardList && onwardList[0]!==undefined &&
             <SegmentDetails  onwardList={onwardList} airport_data={props.airport_data} type={"Onward"}/>
            }
            {returnList && returnList[0]!==undefined &&
             <SegmentDetails returnList={returnList} airport_data={props.airport_data} type={"Return"}/>
             }

            {voidList.length!==0  && props.response.invoicetype !== "ITINERARY" &&
            <PaxFareDetails flowType={"Void"} type={props.response.type} fareList={voidList}/>
            }
            {voidPaxList.length!==0 && props.response.invoicetype === "ITINERARY" &&
            <PaxDetails paxList={voidPaxList} flowType={"Void Pax"}/>
            }
             </View>
          </>

        }
        <View>
          <Text style={styles.copy}>Copyright © 2021 ezytrip. All rights reserved.</Text>
        </View>
      </Page>
    </Document>
  ) : 'Loading...');
}


export default FlightInvoice;
