
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import AirportMemo from '../../common/airlines/airportNames';
import DateUtils from '../../commonUtils/DateUtils';
import { Context } from "../../../../App";
 import Utilities from "../../commonUtils/Utilities";
import AirlinesMemo from '../../common/airlines';
import {isNotNull } from '../../commonUtils/validators';
import LayoverDetails from '../../bookingFlows/flight/common/LayoverDetails';
import FlightSegmentImportDetails from '../../bookingFlows/flight/common/FlightSegmentImportDetails';
import ManualPaymentForm from "../../dashBoard/ManualPaymentForm";
import AttCommonSerivce from "../../../services/commonService/AttCommonSerivce";
import Button from '../../common/buttons/Button';
import {getCrediLimit,saveManualBook} from "../../bookingFlows/flight/review/operations";
const _ = require('lodash');
/**
 * @description:This function will load the Import PNR details
 * @author: Lakshmi
 * @param {*}
 * @function DashBoardPNRImport
 * @date : 17-11-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  all: false, selSegment: false, notiMessage: '', notiMessageShow: false, notiVarient: '', priceResp: "", showSegments: [], adt: 0, chd: 0, inf: 0,grandTotal: 0,finalisd_import_seg:[],
  fareobj: '', grand_total: 0, adts:1, chds:0, inf:0,adtbase: "", adttaxes: "", adtmarkup:"", adtsf: "", adttotal: 0, adtbaseta: "", adttaxesta: "", adtmarkupta:"", adtsfta: "",adtbagta: "",adtmealsta: "", adttotalta: 0, adttotalamt: 0, adttotalamtta: 0, adtbasesta: "", adttaxessta: "", adtmarkupsta:"", adtsfsta: "",adtbagsta: "",adtmealssta: "",  adttotalsta: 0, adttotalamtsta: 0, infbase: "", inftaxes: "", infmarkup:"", infsf: "", inftotal: 0, infbaseta: "", inftaxesta: "", infmarkupta:"", infsfta: "", infbagta: "",infmealsta: "", inftotalta: 0, inftotalamt: 0, infs: 0, inftotalamtta: 0, infbasesta: "", inftaxessta: "", infmarkupsta:"", infsfsta: "", infbagsta: "",infmealssta: "", inftotalsta: 0, inftotalamtsta: 0, chdbase: "", chdtaxes: "", chdmarkup:"", chdsf: "",chdtotal: 0, chdbaseta: "", chdtaxesta: "", chdmarkupta:"", chdsfta: "",chdbagta: "",chdmealsta: "", chdtotalta: 0, chdtotalamt: 0, chds: 0, chdtotalamtta: 0, chdbasesta: "", chdtaxessta: "", chdmarkupsta:"", chdsfsta: "", chdbagsta: "",chdmealssta: "", chdtotalsta: 0, chdtotalamtsta: 0, nat: [], sa: "", sp: "", mp: "", ffnum: "", air: "", jtype:"",supplier:"0",ttype:"", adtpax: [], infpax: [], chdpax: [],
  adtPenalty: "", adtResVal:0,chdPenalty: "", chdResVal:0,infPenalty:"",infResidualVal:0,payData:"",disable:false,enablePayment:false,isItineraryLoading:false,adtdis:0,adtdista:0,chddis:0,chddista:0,infdis:0,infdista:0,
  gst_type:"",cgst:0,sgst:0,igst:0,adt_attgst:0,chd_attgst:0,inf_attgst:0,adt_tagst:0,chd_tagst:0,inf_tagst:0,att_adtcgst:0,att_adtsgst:0,att_adtigst:0,att_adtRetcgst:0,att_adtRetsgst:0,att_adtRetigst:0,att_chdcgst:0,att_chdsgst:0,att_chdigst:0,ta_adtcgst:0,ta_adtsgst:0,ta_adtigst:0,ta_chdcgst:0,ta_chdsgst:0,ta_chdigst:0,ta_infcgst:0,ta_infsgst:0,ta_infigst:0,
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ShowManualPnrData(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [response, setResponse] = useState(props.pnrResponse.res)
  const [mappings, setMappings] = useState(props.pnrResponse.res.mappings)
  const  [type, setType] = useState("");
  const  [ptype, setPType] = useState("");
  const  [typeOne, setTypeOne] = useState("");
  const  [ptypeOne, setPTypeOne] = useState("");
  const  [typeTwo, setTypeTwo] = useState("");
  const  [ptypeTwo, setPTypeTwo] = useState("");
  const [enableAttPrice, setEnableAttPrice] = useState(false);
  const [enableTAPrice, setEnableTAPrice] = useState(false);
  const [enableSTAPrice, setEnableSTAPrice] = useState(false);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    //******if import booking then handle accordingly *********/
        let initData = [] //segment details
        if(props.pnrResponse.res.origns) {
            initData = props.pnrResponse.res.origns[0].srcorigns[0].seg;
        }
        let count = 1 // count of seg order for further use
        if(initData.length!==0){
          for(let val of initData) { //iteration of seg details
            if(val) {
            val.trip_type = 1
            val.seg_order = count
            count = count + 1
            }
        }
        }
        let  new_seg_details =  constructSegmentForImport(initData) //handle layover time or new departure
        if(new_seg_details !== null && new_seg_details.length > 0) {
            initData = new_seg_details //set new modified seg details
        }

       let arr = _.groupBy(initData,"trip_type" )
       const propertyNames = Object.values(arr);

       dispatch({type:"finalisd_import_seg",payload:propertyNames})
       if(context.logindata.cid!==0 && context.logindata.cid!==undefined && context.logindata.region_type==="India"){
        fetchGSTConfig()
      }

 },[props.pnrResponse.res])

 const fetchGSTConfig=()=>{
  AttCommonSerivce.getFetchGSTDetails({cid:context.logindata.cid}).then(response => {
    if (response.data.suc && response.data.data.length !== 0){
      dispatch({type: "gst_type", payload: response.data.data[0].state_type})
      dispatch({type: "cgst", payload: response.data.data[0].cgst})
      dispatch({type: "sgst", payload: response.data.data[0].sgst})
      dispatch({type: "igst", payload: response.data.data[0].igst})
    }
  })
 }

 const constructSegmentForImport = (data) => {
  let seg_data = data //segment details

  // scenarios->

  if(seg_data.length > 0 && seg_data.length === 2) {
      //check if its like one way with connectivity
    let duration =  DateUtils.msToTime(Math.abs(new Date(seg_data[1].dd) - new Date(seg_data[0].ad))) //layover time
    let dayExceedTag = false
    if(duration.includes("day") || duration.includes("days") ) {
      dayExceedTag = true
    }
  //   if(dayExceedTag) {
      if(seg_data[0].ar == seg_data[1].da) { //checking arrival of first segment and departure of second segment
          if(seg_data[0].da == seg_data[1].ar) { //checking departure of first segment and arrival of first segment
              seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
              seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //no changes
          } else {
              if(dayExceedTag) {
                  seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
                  seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //changes
              } else {
                  return null //no changes
              }
          }
      } else {
          if(seg_data[0].da == seg_data[1].ar) {
              seg_data[1].trip_type = 2 //assiginng new trip type(AKA segment header count) to show as new departure
              seg_data[1].seg_order = 1 // assigning inital seg order

              return seg_data //changes
          } else {
              return null //no changes
          }
      }

  } else {
      let count = 1 //intial count of trip type
      let segment_number = 1
      //iterate each of the seg to handle same condition as above
      for(let indexOf in seg_data) {
          let current_seg = seg_data[indexOf] //iterative current segment
          let next_seg = null
          if(seg_data[Number(indexOf) + 1]) {
          next_seg = seg_data[Number(indexOf) + 1] //next segment
          }
          //****condition to check whether next segment is available or not *****/
          if(next_seg) {
              //if current segment arrival === next segment destination
              // if(current_seg.ar == next_seg.da) {
                  let duration =  DateUtils.msToTime(Math.abs(new Date(next_seg.dd) - new Date(current_seg.ad))) //layover time
                  let dayExceedTag = false
                    if(duration.includes("day") || duration.includes("days") || duration.includes("d")) {
                       dayExceedTag = true
                      }
                      //this means its more than or equal to 24 hrs
                      if(dayExceedTag) {
                          count = count + 1
                          segment_number = 1
                      }
                      let next_seg_count = count
                      next_seg.trip_type = next_seg_count //assiginng new trip type(AKA segment header count) to show as new departure
                      if(dayExceedTag) {
                      next_seg.seg_order = 1 // assigning inital seg order
                      } else {
                          segment_number = segment_number + 1
                          next_seg.seg_order = segment_number
                      }
              }
          // }
          }
            return seg_data //changes depends on layover time
        }
      }

 function getAirportdata(code, val) {
    if (isNotNull(props.airport_data) && isNotNull(code)) {
        for (let a in props.airport_data) {
            let air_data = props.airport_data[a];
            if (isNotNull(air_data.airportCode)) {
                if (air_data.airportCode === code && val === 'c') {
                    return air_data.cityName;
                } else if (air_data.airportCode === code && val === 'a') {
                    return air_data.airportName;
                }
            }
        }
    }
}

const handlePax = (data) => (e) => {
  const { id, value } = e.target
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
         if(pax.sno===data.sno){
           if(!pax.selected){
            pax.selected=true;
           }else{
            pax.selected=false;
           }
         }
      }
 }
 dispatch({ type: id, payload: value });
}

const handlePaxEmd = (data) => (e) => {
  const { id, value } = e.target
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
         if(pax.sno===data.sno){
           if(!pax.isemd){
            pax.isemd=true;
           }else{
            pax.isemd=false;
           }
         }
      }
 }
 dispatch({ type: id, payload: value });
}

const handlePaxChange = (data,isInf) => (e) => {
  const { id, value } = e.target
  dispatch({ type: id, payload: value });
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
          if(isInf){
            if(pax.sno===data.sno){
              pax.inf.tkt=value;
           }
          }else{
            if(pax.sno===data.sno){
              pax.tkt=value;
           }
          }

      }
 }
 dispatch({ type: id, payload: value });
 props.sendPaxInfo(response.psngrs);
}

const handlePaxEmdChange = (data,isInf) => (e) => {
  const { id, value } = e.target
  dispatch({ type: id, payload: value });
  if(response.psngrs.length!==0){
      for(let pax of response.psngrs){
          if(isInf){
            if(pax.sno===data.sno){
              pax.inf.emd_number=value;
           }
          }else{
            if(pax.sno===data.sno){
              pax.emd_number=value;
           }
          }

      }
 }
 dispatch({ type: id, payload: value });
 props.sendPaxInfo(response.psngrs);
}

useEffect(() => {
    if(enableAttPrice){
      calculateADTPrice();
    }
   }, [enableAttPrice])

   useEffect(() => {
    if(enableTAPrice){
      calculateCHDPrice();
    }
   }, [enableTAPrice])

   useEffect(() => {
    if(enableSTAPrice){
      calculateSTAPrice();
    }
   }, [enableSTAPrice])

   useEffect(() => {
    dispatch({type: "adts", payload: response.adt})
    dispatch({type: "chds", payload: response.chd})
    dispatch({type: "infs", payload: response.inf})
    convertPaxpriceDetails();
    let adtList = []
    let chdList = []
    let infList = []
    for(let pax of response.psngrs){
        if(pax.ty == "ADT"){
            let obj1 = {
             pname : pax.fn,
             number : pax.tkt
            }
             adtList.push(obj1)
             dispatch({ type: 'adtpax', payload: state.adtpax.concat(adtList)})
             if(pax.inf!==undefined && pax.inf!==""){
                let obj3 = {
                    pname : pax.inf.fn,
                    number : pax.inf.tkt
                   }
                   infList.push(obj3)
                    dispatch({ type: 'infpax', payload: state.infpax.concat(infList)})
             }
          }
         else if(pax.ty == "CHD"){
            let obj2 = {
             pname : pax.fn,
             number : pax.tkt
            }
             chdList.push(obj2)
             dispatch({ type: 'chdpax', payload: state.chdpax.concat(chdList)})
          }
    }

   }, [response])

const convertPaxpriceDetails=()=>{
for(let price of response.origns[0].srcorigns[0].paxpr){
if(price.ptype == "ADT"){
  dispatch({type: "adtbase", payload: price.bf})
  dispatch({type: "adttaxes", payload:Utilities.taxCalculation(price.tax)})
  dispatch({type: "adtsupCharge", payload: Number(price.tf)})
  dispatch({type: "adttotal", payload: Number(price.tf)})
  dispatch({type: "adttotalamt", payload: Number(price.tf) * Number(response.adt)})
  dispatch({type: "adttotalta", payload: Number(price.tf)})
  dispatch({type: "adttotalamtta", payload: Number(price.tf) * Number(response.adt)})
  dispatch({type: "adttotalsta", payload: Number(price.tf)})
  dispatch({type: "adttotalamtsta", payload: Number(price.tf) * Number(response.adt)})
}else if(price.ptype == "CHD"){
  dispatch({type: "chdbase", payload: price.bf})
  dispatch({type: "chdtaxes", payload: Utilities.taxCalculation(price.tax)})
  dispatch({type: "chdsupCharge", payload: Number(price.tf)})
  dispatch({type: "chdtotal", payload: Number(price.tf)})
  dispatch({type: "chdtotalamt", payload: Number(price.tf) * Number(response.chd)})
  dispatch({type: "chdtotalta", payload: Number(price.tf)})
  dispatch({type: "chdtotalamtta", payload: Number(price.tf) * Number(response.chd)})
  dispatch({type: "chdtotalsta", payload: Number(price.tf)})
  dispatch({type: "chdtotalamtsta", payload: Number(price.tf) * Number(response.chd)})
}else if(price.ptype == "INF"){
  dispatch({type: "infbase", payload: price.bf})
  dispatch({type: "inftaxes", payload: Utilities.taxCalculation(price.tax)})
  dispatch({type: "infsupCharge", payload: Number(price.tf)})
  dispatch({type: "inftotal", payload: Number(price.tf)})
  dispatch({type: "inftotalamt", payload: Number(price.tf) * Number(response.inf)})
  dispatch({type: "inftotalta", payload: Number(price.tf)})
  dispatch({type: "inftotalamtta", payload: Number(price.tf) * Number(response.inf)})
  dispatch({type: "inftotalsta", payload: Number(price.tf)})
  dispatch({type: "inftotalamtsta", payload: Number(price.tf) * Number(response.inf)})
}
}
}


 //Calculation ADT Price for ATT , TA AND STA
const handleChangeADTPrice = (type,paxType) => (e) => {
const { id, value } = e.target;
dispatch({ type: id, payload: value });
setType(type);
setPType(paxType)
setEnableAttPrice(true);
}
//Calculation ADT Price for ATT , TA AND STA
const calculateADTPrice=()=>{
if(type==="ATT" && ptype==="ADT"){
let supprice=Number(state.adtbase) + Number(state.adttaxes);
dispatch({type: "sup_totalPrice", payload: supprice})
let price = Number(supprice) + Number(state.adtmarkup) + Number(state.adtsf) + Number(state.adtPenalty) - Number(state.adtResVal)
let taPrice= Number(price);
if(state.adtmarkup!=="" || state.adtsf!==""){
  let totalGst = Number(state.adtmarkup) + Number(state.adtsf) - Number(state.adtdis)
  if(Number(totalGst) > 0){
  if(state.gst_type==="same"){
    const attCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
    const attSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "att_adtcgst", payload: Number(attCgst)})
    dispatch({type: "att_adtsgst", payload: Number(attSgst)})
    dispatch({type: "adt_attgst", payload: Number(attSgst) + Number(attCgst) })
    price=price+Number(attSgst) + Number(attCgst);
    taPrice=taPrice+Number(attSgst) + Number(attCgst);
  }else{
    const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "att_adtigst", payload: Number(attIgst)})
    dispatch({type: "adt_attgst", payload: Number(attIgst) })
    price=price+Number(attIgst)
    taPrice=taPrice+Number(attIgst);
  }
}
}
dispatch({type: "adtsupCharge", payload: Number(supprice)})
dispatch({type: "adttotal", payload: price.toFixed(2)})
dispatch({type: "adttotalta", payload: taPrice.toFixed(2)})
dispatch({type: "adttotalsta", payload: taPrice.toFixed(2)})
dispatch({type: "adttotalamt", payload: Number(price) * state.adts})
dispatch({type: "adttotalamtta", payload: Number(taPrice) * state.adts})
dispatch({type: "adttotalamtsta", payload: Number(taPrice) * state.adts})
}else if(type==="TA" && ptype==="ADT"){
let price = Number(state.sup_totalPrice) + Number(state.adtmarkupta) + Number(state.adtmarkup) + Number(state.adtsf) + Number(state.adtsfta) + Number(state.adtPenalty) - Number(state.adtResVal);
let staPrice = Number(price);
if(state.adtmarkupta!=="" || state.adtsfta!==""){
  let totalGst = Number(state.adtmarkupta) + Number(state.adtsfta) - Number(state.adtdista)
  if(Number(totalGst) > 0){
  if(state.gst_type==="same"){
    const taCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
    const taSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_adtcgst", payload: Number(taCgst)})
    dispatch({type: "ta_adtsgst", payload: Number(taSgst)})
    dispatch({type: "adt_tagst", payload: Number(taCgst) + Number(taSgst) })
    price=price+Number(taCgst) + Number(taSgst);
  }else{
    const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_adtigst", payload: Number(attIgst)})
    dispatch({type: "adt_tagst", payload: Number(attIgst) })
    price=price+Number(attIgst)
  }
}
}
dispatch({type: "adttotalta", payload: price.toFixed(2)})
dispatch({type: "adttotalamtta", payload: price.toFixed(2) * state.adts})
dispatch({type: "adttotalsta", payload: staPrice})
dispatch({type: "adttotalamtsta", payload: staPrice * state.adts})
}else if(type==="STA" && ptype==="ADT"){
let totalsta = Number(state.sup_totalPrice) + Number(state.adtmarkup) + Number(state.adtmarkupta) + Number(state.adtsfta) + Number(state.adtsf) + Number(state.adtmarkupsta) + Number(state.adtsfsta) + Number(state.adtPenalty) - Number(state.adtResVal)
dispatch({type: "adttotalsta", payload: totalsta})
dispatch({type: "adttotalamtsta", payload: totalsta * state.adts})
}
setEnableAttPrice(false);
}

//Calculation CHD Price for ATT , TA AND STA
const handleChangeCHDPrice = (type,paxType) => (e) => {
const { id, value } = e.target;
dispatch({ type: id, payload: value });
setTypeOne(type);
setPTypeOne(paxType)
setEnableTAPrice(true);
}
const calculateCHDPrice=()=>{
if(typeOne==="ATT" && ptypeOne==="CHD"){
let suppChdrice=Number(state.chdbase) + Number(state.chdtaxes);
let price = Number(suppChdrice) + Number(state.chdmarkup) + Number(state.chdsf) + Number(state.chdPenalty) - Number(state.chdResVal)
let taPrice= Number(price);
if(state.chdmarkup!=="" || state.chdsf!==""){
  let totalGst = Number(state.chdmarkup) + Number(state.chdsf) - Number(state.chddis)
  if(Number(totalGst)>0){
  if(state.gst_type==="same"){
    const attCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
    const attSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "att_chdcgst", payload: Number(attCgst)})
    dispatch({type: "att_chdsgst", payload: Number(attSgst)})
    dispatch({type: "chd_attgst", payload: Number(attSgst) + Number(attCgst) })
    price=price+Number(attSgst) + Number(attCgst);
    taPrice=taPrice+Number(attSgst) + Number(attCgst);
  }else{
    const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "att_chdigst", payload: Number(attIgst)})
    dispatch({type: "chd_attgst", payload: Number(attIgst) })
    price=price+Number(attIgst)
    taPrice=taPrice+Number(attIgst);
  }
}
}
dispatch({type: "chdsupCharge", payload: Number(suppChdrice)})
dispatch({type: "chdtotal", payload: price.toFixed(2)})
dispatch({type: "chdtotalamt", payload: price.toFixed(2) * state.chds})
dispatch({type: "chdtotalta", payload: taPrice})
dispatch({type: "chdtotalamtta", payload: Number(taPrice) * state.chds})
dispatch({type: "chdtotalsta", payload: Number(taPrice)})
dispatch({type: "chdtotalamtsta", payload: Number(taPrice) * state.chds})
}else if(typeOne==="TA" && ptypeOne==="CHD"){
let price = Number(state.chdsupCharge) + Number(state.chdmarkup) + Number(state.chdsf) + Number(state.chdmarkupta) + Number(state.chdsfta) + Number(state.chdPenalty) - Number(state.chdResVal);
let staPrice=Number(price);
if(state.chdmarkupta!=="" || state.chdsfta!==""){
  let totalGst = Number(state.chdmarkupta) + Number(state.chdsfta) - Number(state.chddista)
  if(Number(totalGst)>0){
  if(state.gst_type==="same"){
    const taCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
    const taSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_chdcgst", payload: Number(taCgst)})
    dispatch({type: "ta_chdsgst", payload: Number(taSgst)})
    dispatch({type: "chd_tagst", payload: Number(taCgst) + Number(taSgst) })
    price=price+Number(taCgst) + Number(taSgst);
  }else{
    const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_chdigst", payload: Number(attIgst)})
    dispatch({type: "chd_tagst", payload: Number(attIgst) })
    price=price+Number(attIgst)
  }
}
}
dispatch({type: "chdtotalta", payload: price.toFixed(2)})
dispatch({type: "chdtotalamtta", payload: price.toFixed(2) * state.chds})
dispatch({type: "chdtotalsta", payload: staPrice})
dispatch({type: "chdtotalamtsta", payload: staPrice * state.chds})
}else if(typeOne==="STA" && ptypeOne==="CHD"){
let totalsta = Number(state.chdsupCharge) + Number(state.chdmarkup) + Number(state.chdmarkupta) + Number(state.chdsfta) + Number(state.chdsf) + Number(state.chdmarkupsta) + Number(state.chdsfsta) + Number(state.chdPenalty) - Number(state.chdResVal)
dispatch({type: "chdsupChargeSTA", payload: Number(state.chdsupCharge)})
dispatch({type: "chdtotalsta", payload: totalsta})
dispatch({type: "chdtotalamtsta", payload: totalsta * state.chds})
}
setEnableTAPrice(false);
}

const handleChangeINFPrice = (type,paxType) => (e) => {
const { id, value } = e.target;
dispatch({ type: id, payload: value });
setTypeTwo(type);
setPTypeTwo(paxType)
setEnableSTAPrice(true);
}
//Calculation INF Price for ATT , TA AND STA
const calculateSTAPrice=()=>{
if(typeTwo ==="ATT" && ptypeTwo==="INF"){
let supInfprice=Number(state.infbase) + Number(state.inftaxes);
let total = Number(supInfprice) + Number(state.infmarkup) + Number(state.infsf)
 if(state.infmarkup!=="" || state.infsf!==""){
      let totalGst = Number(state.infmarkup) + Number(state.infsf) - Number(state.infdis)
      if(Number(totalGst)>0){
      if(state.gst_type==="same"){
        const attCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
        const attSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
        dispatch({type: "att_infcgst", payload: Number(attCgst)})
        dispatch({type: "att_infsgst", payload: Number(attSgst)})
        dispatch({type: "inf_attgst", payload: Number(attSgst) + Number(attCgst) })
        total=total+Number(attSgst) + Number(attCgst);
      }else{
        const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
        dispatch({type: "att_infigst", payload: Number(attIgst)})
        dispatch({type: "inf_attgst", payload: Number(attIgst) })
        total=total+Number(attIgst)
      }
    }
    }
dispatch({type: "infsupCharge", payload: supInfprice})
dispatch({type: "inftotal", payload: total})
dispatch({type: "inftotalamt", payload: total * state.infs})
dispatch({type: "inftotalta", payload: total})
dispatch({type: "inftotalamtta", payload: total * state.infs})
dispatch({type: "inftotalsta", payload: Number(total)})
dispatch({type: "inftotalamtsta", payload: Number(total) * state.infs})
}else if(typeTwo==="TA" && ptypeTwo==="INF"){
let totalta = Number(state.infsupCharge) + Number(state.infmarkup) + Number(state.infsf) + Number(state.infmarkupta) + Number(state.infsfta);
let charge = Number(state.infsupCharge);
let staPrice=Number(state.infsupCharge) + Number(state.infmarkup) + Number(state.infmarkupta) + Number(state.infsf) + Number(state.infsfta);
if(state.infmarkupta!=="" || state.infsfta!==""){
  let totalGst = Number(state.infmarkupta) + Number(state.infsfta) - Number(state.infdista)
  if(Number(totalGst)>0){
  if(state.gst_type==="same"){
    const taCgst = ((state.cgst/ 100) * (totalGst)).toFixed(2)
    const taSgst = ((state.sgst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_infcgst", payload: Number(taCgst)})
    dispatch({type: "ta_infsgst", payload: Number(taSgst)})
    dispatch({type: "inf_tagst", payload: Number(taCgst) + Number(taSgst) })
    totalta=totalta+Number(taCgst) + Number(taSgst);
  }else{
    const attIgst = ((state.igst/ 100) * (totalGst)).toFixed(2)
    dispatch({type: "ta_infigst", payload: Number(attIgst)})
    dispatch({type: "inf_tagst", payload: Number(attIgst) })
    totalta=totalta+Number(attIgst)
  }
}
}
dispatch({type: "infsupChargeTA", payload: charge.toFixed(2)})
dispatch({type: "inftotalta", payload: totalta.toFixed(2)})
dispatch({type: "inftotalamtta", payload: totalta.toFixed(2) * state.infs})
dispatch({type: "inftotalsta", payload: Number(staPrice)})
dispatch({type: "inftotalamtsta", payload: Number(staPrice) * state.infs})
}else if(typeTwo==="STA" && ptypeTwo==="INF"){
let totalsta = Number(state.infsupCharge) + Number(state.infmarkup) + Number(state.infmarkupta) + Number(state.infmarkupsta) + Number(state.infsfsta) + Number(state.infsf) + Number(state.infsfta);
dispatch({type: "infsupChargeSTA", payload: Number(state.infsupCharge)})
dispatch({type: "inftotalsta", payload: totalsta})
dispatch({type: "inftotalamtsta", payload: totalsta * state.infs})
}
setEnableSTAPrice(false)
}

const createManualPayload=(clAvail,payType,clBal,overdieCL,total)=>{
     const payload={
      enableCl:clAvail,
      type:payType,
      creditBal:clBal,
      enableOvrwriteCL:overdieCL,
      totalPrice:total,
      cname: context.logindata.cnm
     }
     dispatch({ type: 'payData', payload: payload });
     dispatch({ type: 'disable', payload: true });
  }

  const hidemessage =() =>{
    setTimeout(function () {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
  }, 5000);
}


  const validatePrice=() =>{
    if(state.adtbase===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Base Fare for Adult' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if(state.adttaxes===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Texes for Adult' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if(state.chds > 0 && state.chdbase===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Base Fare for Child' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if(state.chds > 0 && state.chdtaxes===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Texes for Child' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if(state.infs > 0 && state.infbase===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Base Fare for Infant' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }else if(state.infs > 0 && state.inftaxes===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Taxes for Infant' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
    if(response.psngrs.length!==0){
      let tkts1 = response.psngrs.filter((obj) => obj.tkt === "");
      let tkts2 = response.psngrs.filter((obj) => obj.inf!==undefined && obj.inf.tkt==="");
         if(tkts1.length!==0 || tkts2.length!==0){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Enter Ticket Number'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
         }else{
          return true;
         }
  }else{
    return true;
  }
    return true;
  }

const continueBooking = ()=>{
    if(validatePrice()){
        //  dispatch({ type: 'enablePay', payload: true })
        let total=0;
        if(context.logindata.butype === "ta_user"){
          total=Number(state.adttotalamtta)  + Number(state.chdtotalamtta) + Number(state.inftotalamtta);
        }else if(context.logindata.butype === "sta_user"){
          total=Number(state.adttotalamtsta) + Number(state.chdtotalamtsta) + Number(state.inftotalamtsta);
        }
         let pcompId=0;
        if (Number(context.logindata.cid) === Number(context.logindata.pcid)) {
            pcompId = 0;
        }else{
            pcompId=context.logindata.pcid;
        }
      const req = {
          "comid": Number(context.logindata.cid),
          "pid": pcompId,
          "cuscode": context.logindata.cuscode
          }
         getCrediLimit(req).then((response) => {
          console.log("Manual CL Balance avial : " + JSON.stringify(response));
          if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
            dispatch({ type: 'creditBal', payload: response.res.data.cbal });
            dispatch({ type: 'enablePayment', payload: true })
            let clbal=Number(response.res.data.cbal);
            let val = Math.sign(clbal);
            let paytype="credit";
            if (Number(val) === 1) {
              if (context.logindata.butype === "sta_user") {
                var pclbal = 0;
                if (response.res.data.pcbal !== undefined) {
                   pclbal = response.res.data.pcbal;
                }
                console.log("CL Balance Parent : " + pclbal);
                if (clbal !== 0) {
                  if (paytype === "credit" && Number(clbal) >= Number(total) && pclbal >= Number(total)) {
                      createManualPayload(true, "credit", clbal,false,total);
                  } else if (paytype === "credit" && Number(total) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                      createManualPayload(false, "credit", clbal,true,total);
                  }
              }
              } else {
                if (clbal !== 0) {
                  if (paytype === "credit" && clbal >= Number(total)) {
                      createManualPayload(true, "credit", clbal,false,total);
                  } else if (paytype === "credit" && Number(total) > 0 && (clbal === 0 || clbal < 0)) {
                      createManualPayload(false, "credit", clbal,true,total);
                  }
                }
              }
          }else{
              createManualPayload(false, "credit", clbal,true,total);
          }
         }
        });
      }
  }

  const cancel=()=>{
      props.closePopup()
  }

  const createMainPr=()=>{
    let totalSf=0
    let baseFare=Number(state.adtbase * state.adts) + Number(state.chdbase * state.chds) + Number(state.infbase * state.infs);
    let taxes= Number(state.adttaxes * state.adts) + Number(state.chdtaxes * state.chds) + Number(state.inftaxes * state.infs);
    let total= Number(baseFare) + Number(taxes);
    //Adding ATT Service Fee to BF and Total
    if(context.logindata.butype === "sta_user"){
      totalSf = Number(state.adtsf * state.adts) + Number(state.chdsf * state.chds) + Number(state.infsf * state.infs);
      baseFare= Number(baseFare)+ Number(totalSf);
      total= Number(baseFare) + Number(taxes) + Number(totalSf);
    }
    const payload ={
        "bf": Number(baseFare),
        "tf": Number(total),
        "tax": [
          {
            "OT": Number(taxes)
          },
          {
            "attmkp": Number(state.adtmarkup * state.adts) + Number(state.chdmarkup * state.chds) + Number(state.infmarkup * state.infs)
          },
          {
            "tamkp":  Number(state.adtmarkupta * state.adts) + Number(state.chdmarkupta  * state.chds) + Number(state.infmarkupta * state.infs)
          },
          {
            "stamkp": Number(state.adtmarkupsta * state.adts) + Number(state.chdmarkupsta  * state.chds) + Number(state.infmarkupsta * state.infs)
          },
          {
            "attdisc": Number(state.adtdis * state.adts) + Number(state.chddis  * state.chds) + Number(state.infdis * state.infs)
          },
          {
            "tadisc": Number(state.adtdista * state.adts) + Number(state.chddista  * state.chds) + Number(state.infdista * state.infs)
          },
          {
            "stadisc": Number(state.adtdissta * state.adts) + Number(state.chddissta  * state.chds) + Number(state.infdissta * state.infs)
          },
          {
            "att_gst": Number(state.adt_attgst * state.adts) + Number(state.chd_attgst  * state.chds) + Number(state.inf_attgst * state.infs)
          },
          {
            "ta_gst": Number(state.adt_tagst * state.adts) + Number(state.chd_tagst  * state.chds) + Number(state.inf_tagst * state.infs)
          }
        ],

        "tfd": Number(total),
        "penlty": Number(state.adtPenalty * state.adts) + Number(state.chdPenalty * state.chds) + Number(state.infPenalty * state.infs),
        "resdval": 0,
        "gtd": Number(total),
        "bfd": Number(baseFare),
        "scur": context.logindata,
        "sup_fare": baseFare + "_" + total,
        "sup_cur_fare": baseFare + "_" + total,
        "markupnames": createMarkupname(),
        "gst_total":Number(state.adt_attgst * state.adts) + Number(state.chd_attgst  * state.chds) + Number(state.inf_attgst * state.infs) + Number(state.adt_tagst * state.adts) + Number(state.chd_tagst  * state.chds) + Number(state.inf_tagst * state.infs)
      }
      return payload;
    }

    const createMarkupname=()=>{
        let markNames=[];
        if(state.adtmarkup!==0){
          const att={
           "code":"MTA",
           "name" : "MTA Manual Markup"
          }
          markNames.push(att);
        }
        if(state.adtmarkupta!==0){
          const ta={
           "code":"TA",
           "name" : "TA Manual Markup"
          }
          markNames.push(ta);
        }
        if(state.adtmarkupsta!==0){
          const sta={
           "code":"STA",
           "name" : "STA Manual Markup"
          }
          markNames.push(sta);
        }
        return markNames;
      }

      const createPaxRequest=(type)=>{
        let baseFare=0;
        let total=0;
        let taxes=0;
        let att=0;
        let ta=0;
        let sta=0;
        let attdisc=0;
        let tadisc=0;
        let stadisc=0;
        let fbc=[];
        let penalty=0;
        let resVal=0;
        let att_cgst=0;
        let att_sgst=0;
        let att_igst=0;
        let ta_cgst=0;
        let ta_sgst=0;
        let ta_igst=0;
        if(type==="ADT"){
          baseFare=context.logindata.butype === "ta_user"?Number(state.adtbase):Number(state.adtbase)+Number(state.adtsf);
          let totalSup = Number(state.adtbase) + Number(state.adttaxes);
          total=context.logindata.butype === "ta_user"?Number(totalSup): Number(totalSup)+Number(state.adtsf);
          taxes=state.adttaxes;
          att=state.adtmarkup;
          ta=state.adtmarkupta;
          sta=state.adtmarkupsta;
          attdisc=state.adtdis;
          tadisc=state.adtdista;
          stadisc=state.adtdissta;
          fbc=response.origns[0].srcorigns[0].paxpr.filter((pax)=>pax.ptype==="ADT");
          penalty=state.adtPenalty;
          resVal=state.adtResVal;
          att_cgst=state.att_adtcgst;
          att_sgst=state.att_adtsgst;
          att_igst=state.att_adtigst;
          ta_cgst=state.ta_adtcgst;
          ta_sgst=state.ta_adtsgst;
          ta_igst=state.ta_adtigst;
        }else if(type==="CHD"){
          baseFare=context.logindata.butype === "ta_user"?Number(state.chdbase):Number(state.chdbase)+Number(state.chdsf);
          let totalSup = Number(state.chdbase) + Number(state.chdtaxes);
          total=context.logindata.butype === "ta_user"?Number(totalSup): Number(totalSup)+Number(state.chdsf);
          taxes=state.chdtaxes;
          att=state.chdmarkup;
          ta=state.chdmarkupta;
          sta=state.chdmarkupsta;
          attdisc=state.chddis;
          tadisc=state.chddista;
          stadisc=state.chddissta;
          fbc=response.origns[0].srcorigns[0].paxpr.filter((pax)=>pax.ptype==="CHD");
          penalty=state.chdPenalty;
          resVal=state.chdPenalty;
          att_cgst=state.att_chdcgst
          att_sgst=state.att_chdsgst;
          att_igst=state.att_chdigst;
          ta_cgst=state.ta_chdcgst;
          ta_sgst=state.ta_chdsgst;
          ta_igst=state.ta_chdigst;
        }else if(type==="INF"){
          baseFare=context.logindata.butype === "ta_user"?Number(state.infbase):Number(state.infbase)+Number(state.infsf);
          let totalSup = Number(state.infbase) + Number(state.inftaxes);
          total=context.logindata.butype === "ta_user"?Number(totalSup): Number(totalSup)+Number(state.infsf);
          taxes=state.inftaxes;
          att=state.infmarkup;
          ta=state.infmarkupta;
          sta=state.infmarkupsta;
          attdisc=state.infdis;
          tadisc=state.infdista;
          stadisc=state.infdissta;
          fbc=response.origns[0].srcorigns[0].paxpr.filter((pax)=>pax.ptype==="INF");
          penalty=state.infPenalty;
          resVal=state.infResidualVal;
          att_cgst=state.att_infcgst
          att_sgst=state.att_infsgst;
          att_igst=state.att_infigst;
          ta_cgst=state.ta_infcgst;
          ta_sgst=state.ta_infsgst;
          ta_igst=state.ta_infigst;
        }
        const payload ={
          "bf":Number(baseFare),
          "tf": Number(total),
          "ptype" : type,
          "tax": [
            {
              "OT": Number(taxes)
            },
            {
              "attmkp": Number(att)
            },
            {
              "tamkp": Number(ta)
            },
            {
              "stamkp": Number(sta)
            },
            {
              "attdisc": Number(attdisc)
            },
            {
              "tadisc": Number(tadisc)
            },
            {
              "stadisc": Number(stadisc)
            },
            {
              "att_cgst": Number(att_cgst)
            },
            {
              "att_sgst": Number(att_sgst)
            },
            {
              "att_igst": Number(att_igst)
            },
            {
              "ta_cgst": Number(ta_cgst)
            },
            {
              "ta_sgst": Number(ta_sgst)
            },
            {
              "ta_igst": Number(ta_igst)
            }
          ],
          "scur": context.logindata,
           "fbc": fbc[0].fbc,
           "tfd": Number(total),
           "penlty": Number(penalty),
           "resdval": 0,
           "gtd": Number(total),
           "bfd": Number(baseFare),
           "psup_fare": baseFare + "_" + total,
           "sup_cur_fare": baseFare + "_" + total
        }
        return payload;
      }

      const createPaxPr=()=>{
        let paxList=[];
          paxList.push(createPaxRequest("ADT"));
          if(state.chds > 0){
            paxList.push(createPaxRequest("CHD"))
          }
          if(state.infs > 0){
            paxList.push(createPaxRequest("INF"))
          }
          return paxList;
        }

  const convertSegReq = (ref) => {
      const payload = {
        "tid": response.origns[0].srcorigns[0].tid!==undefined?response.origns[0].srcorigns[0].tid:"",
        "ref": response.origns[0].srcorigns[0].ref,
        "ofcid":response.origns[0].srcorigns[0].ofcid,
        "sdur": response.origns[0].srcorigns[0].sdur,
        "re": response.origns[0].srcorigns[0].re,
        "stop": response.origns[0].srcorigns[0].stop,
        "sup": response.origns[0].srcorigns[0].sup,
        "cl": response.origns[0].srcorigns[0].cl,
        "ft": response.origns[0].srcorigns[0].ft,
        "tktair": response.origns[0].srcorigns[0].tktair,
        "tecst": response.origns[0].srcorigns[0].tecst,
        "seg": response.origns[0].srcorigns[0].seg,
        "bgd": response.origns[0].srcorigns[0].bag,
        "pr":createMainPr(),
        "paxpr":createPaxPr(),
        "serviceFee":createServiceFee()
      }
      return payload;
    }

    const request=(type,supFee)=>{
        let adt=0;
        let chd=0;
        let inf=0;
        let total=0;
        let nm="";
        if(type==="ATT"){
        adt=state.adtsf;
        chd=state.chdsf;
        inf=state.infsf;
        total=Number(state.adtsf) + Number(state.chdsf) + Number(state.infsf);
        nm="ATT Manual Service Fee";
        supFee=0;
        }else if(type==="TA"){
          adt=state.adtsfta;
          chd=state.chdsfta;
          inf=state.infsfta;
          total=Number(state.adtsfta) + Number(state.chdsfta) + Number(state.infsfta);
          nm="TA Manual Service Fee";
          supFee=supFee;
        }else if(type==="STA"){
          adt=state.adtsfsta;
          chd=state.chdsfsta;
          inf=state.infsfsta;
          total=Number(state.adtsfsta) + Number(state.chdsfsta) + Number(state.infsfsta);
          nm="STA Manual Service Fee";
          supFee=supFee;
        }
        const payload={
            "type": type,
            "adt": adt,
            "chd": chd,
            "inf": inf,
            "servicefee_name": nm,
            "apl_on": "Per Ticket",
            "sf": total,
            "sf_disc": 0,
            "adt_sf_disc": 0,
            "chd_sf_disc": 0,
            "inf_sf_disc": 0,
            "complete_sfvalue": total,
            "sup_fee": supFee
        }
        return payload;
      }

    const createServiceFee=()=>{
        let services=[];
        let att=request("ATT",0);
        services.push(att);
        let ta="";
        if(context.logindata.butype === "ta_user"){
          ta=request("TA",att.sf);
          services.push(ta);
        }else if(context.logindata.butype === "sta_user"){
          ta=request("TA",att.sf);
          services.push(ta);
          let sta=request("STA",ta.sf);
          services.push(sta);
        }
        return services;

      }

  const convertSrcorigns=()=>{
    if(Number(response.tt)===2){
       const payload={
          "srcorigns": [convertSegReq("O")],
          "destorigins": [convertSegReq("R")]
       }
    return payload;
    }else{
        const payload1={
            "srcorigns": [convertSegReq("O")],
      }
      return payload1;
    }
}

  const confirmManualBooking = (data)=>{
    dispatch({ type: 'isItineraryLoading', payload: true })
      for(let pax of response.psngrs){
        let paxinfo=props.paxList[0].filter((obj)=>Number(obj.paxsno)===Number(pax.sno));
        if(paxinfo.length!==0){
          pax.trv_op_id=paxinfo[0].trv_op_id;
        }
      }
       const payload={
               "pbreq":{
                  "echo": "7ekspsb1zj9",
                  "cha": "B2B",
                  "cur": context.logindata.cur,
                  "oldtid":props.oldtid,
                  "bkref":props.bookingId,
                  "pnr":  response.pnr,
                  "adt": response.adts,
                  "chd": response.chds,
                  "inf": response.infs,
                  "cid": context.logindata.cid,
                  "cname": context.logindata.cnm,
                  "sty": "normal",
                  "tt": Number(response.tt),
                  "utype" : context.logindata.utype,
                  "bid": context.logindata.bid,
                  "butype": context.logindata.butype,
                  "pcid": context.logindata.pcid,
                  "ofcId": response.origns[0].srcorigns[0].ofcid,
                  "tkt_user_type": context.logindata.utype,
                  "tkt_user_id": context.logindata.uid,
                  "sup": "0",
                  "bkby": context.logindata.nm,
                  "cuscode": context.logindata.cuscode,
                  "bktype" :"MANUAL/REISSUE",
                  "crby": context.logindata.uid,
                  "prof": [],
                  "psngrs": response.psngrs,
                  "origns": [convertSrcorigns()],
                  "pay" : [data],
                  "tfflow":false,
                  "region_type":context.logindata.region_type,
                  "type":state.gst_type,
                  "cgst":state.cgst,
                  "sgst":state.sgst,
                  "igst":state.igst
               }

      }
      saveManualBook(payload).then((response) => {
        if (response.suc !== undefined && response.res !== null && response.res.data!==undefined) {
          if (response.res.data.pbres.status !== undefined && response.res.data.pbres.status === "inserted") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Successfully Re-Issued your Booking' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              dispatch({ type: 'isItineraryLoading', payload: false })
              setTimeout(function () {
              return window.location = window.location.pathname
            }, 3000);
          }else{
            dispatch({ type: 'isItineraryLoading', payload: false })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Something went wrong..Please try again later'})
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
          }
        } else {
          dispatch({ type: 'isItineraryLoading', payload: false })
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Something went wrong..Please try again later'})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        }
      });
    }

  return (
    <>
      <div className="confirmation mt-3">
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
          {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                <div class="loaderPage">
                  <div class="loaderIcon"></div>
                </div>
              </div>
              ) : ""}
        <div className="cardMainlayout bg-white mb-4">
          <h5 className="title d-flex justify-content-between">Flight Details</h5>
          <div className="selectedRout resultSection tableLayout">
            <Table>
              {state.finalisd_import_seg.length===0 &&
                <thead>
                <tr>
                  <th>Flight No.</th>
                  <th>Departure Date/Time</th>
                  <th>Arrival Date/Time</th>
                  <th>Airline PNR</th>
                </tr>
              </thead>
              }
              <tbody>
                {state.finalisd_import_seg.length===0 && response.origns[0].srcorigns[0].seg.map((sege, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr>
                        <td>
                          <div className="d-flex">
                            <img
                            src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + sege.ma + '.png'}
                            className="logo"
                            alt="AirLine"
                                     />
                          <div className="ml-2"><strong><AirlinesMemo code={sege.ma} /></strong>
                          <span>{sege.ac} {sege.fn}</span>
                          <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                       </div></div> </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                            <span>{getAirportdata(sege.da, 'c')} ({sege.da}),</span>
                                         <span>{getAirportdata(sege.da, 'a')}</span>
                                         {sege.terminal &&
                                         <small className="trm">{"Terminal - " + sege.terminal.dep}</small>
                                         }
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, MMM DD, YYYY")}</small></strong>
                            <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                            <span>{getAirportdata(sege.ar, 'c')} ({sege.ar}),</span>
                                         <span>{getAirportdata(sege.ar, 'a')}</span>
                                         {sege.terminal &&
                                         <small className="trm">{"Terminal - " + sege.terminal.arr}</small>
                                         }
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                          <div className="oprtBy"><span>{sege.airpnr}</span></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="p-0 border-0">
                        {response.origns[0].srcorigns[0].bag!==undefined && response.origns[0].srcorigns[0].bag.length!==0 &&
                                <>
                                {response.origns[0].srcorigns[0].bag.map((bag, idx) => (
                                <React.Fragment key={idx}>
                                {bag.tatoo===sege.tatoo &&
                                <div className="sectBaggageinfo ezyIcon icon-baggage m-0"> Baggage Info : Cabin 7 kgs, Check-in {bag.qun}  {bag.wt} per Adult & Child </div>
                                }
                                </React.Fragment>
                                ))}
                                </>
                            }
                            </td>
                            </tr>
                            <tr>
                            {response.origns[0].srcorigns[0].seg.length > 1 &&
                             (isNotNull(response.origns[0].srcorigns[0].seg[(index + 1)]))
                             && <LayoverDetails layoverdata={[response.origns[0].srcorigns[0].seg[index].ad, response.origns[0].srcorigns[0].seg[(index + 1)].dd, sege.ar, mappings]} />}
                            </tr>
                    </>
                  </React.Fragment>
                ))
                }

                {state.finalisd_import_seg.length > 0 && state.finalisd_import_seg.map((sec, idx) => (
                         <>
                             <div className="rs-headerBlock mt-3">
                                 <Row className="align-items-center rs-row">
                                     <Col xs={3} className="rs-rTypeDate">
                                         <>
                                             <span className="departure ezyIcon icon-flight">
                                          Departure </span><span>{DateUtils.prettyDate(sec[0].dd, "ddd, DD MMM YYYY")}</span>
                                         </>
                                     </Col>
                                     <Col xs={9} className="rs-FromTo">
                                         <Row className="align-items-center">
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[0].da} mapping={mappings} />
                                             </Col>
                                             <Col xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                             <Col xs={4} className="d-flex flex-column">
                                                 <AirportMemo code={sec[sec.length - 1].ar} mapping={mappings} />
                                             </Col>
                                         </Row>
                                         <span>
                                         </span>
                                     </Col>
                                 </Row>
                             </div>
                             <FlightSegmentImportDetails  origindata={[{"seg":sec}, 'Confirm']} cl={response.origns[0].srcorigns[0].cl} pnr={response.pnr} bgd={response.origns[0].srcorigns[0].bag} mapping={mappings} confirmation={true}/>
                         </>
                     ))}
              </tbody>
            </Table>
          </div>
          <h5 className="title mt-4">Passenger Details </h5>
          <div className="selectedRout tableLayout manReissue">
            <Table>
              <thead>
                <tr>
                  <th>Traveller</th>
                  <th>Pax Type</th>
                  <th>Ticket Numbers</th>
                  <th>EMD Numbers</th>
                  <th>Segment Status</th>
                  <th>Ticketing Status</th>
                </tr>
              </thead>
              <tbody>
              {response.psngrs.map((pasenger, idx) => (
                <>
                <tr>
                  <td>{pasenger.tl.toUpperCase()} {pasenger.fn.toUpperCase() + " " + pasenger.ln.toUpperCase()} {pasenger.lead?"Lead":""}</td>
                  <td>{pasenger.plbl}</td>
                  <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.selected} onChange={handlePax(pasenger)} custom id={pasenger.tkt} />
                   {!pasenger.selected &&
                   <span>{pasenger.tkt} </span>
                   }
                   {pasenger.selected &&
                  <Form.Row>
                        <Form.Group xs={12} as={Col} controlId="tkt" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter Ticket number" maxlength="14" value={pasenger.tkt} onChange={handlePaxChange(pasenger,false)} onKeyUp={(e)=>Utilities.handleKeyUp(e)}/>
                      </Form.Group>
                      </Form.Row>
                    }
                    </div>
                   </td>
                   <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.isemd} onChange={handlePaxEmd(pasenger)} custom id={idx+1} />
                   {!pasenger.isemd &&
                   <span>{pasenger.emd_number!==""?pasenger.emd_number:"N/A"} </span>
                   }
                   {pasenger.isemd &&
                  <Form.Row>
                        <Form.Group xs={12} as={Col} controlId="emd_number" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter EMD number" value={pasenger.emd_number} onChange={handlePaxEmdChange(pasenger,false)} />
                      </Form.Group>
                      </Form.Row>
                    }
                    </div>
                   </td>
                   <td><span className="text-success">{pasenger.segst}</span></td>
                   <td><span className="text-success">{pasenger.sts}</span></td>
                  </tr>

                  {pasenger.inf !== "" &&
                  <tr>
                    <td width="332">{pasenger.inf.tl.toUpperCase()} {pasenger.inf.fn + " " + pasenger.inf.ln} ({pasenger.inf.plbl.toUpperCase()})</td>
                    <td>Infant</td>

                    <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.selected} onChange={handlePax(pasenger)} custom id={pasenger.inf.tkt} />
                   {!pasenger.selected &&
                   <span>{pasenger.inf.tkt} </span>
                   }
                   {pasenger.selected &&
                  <Form.Row>
                        <Form.Group xs={6} as={Col} controlId="tkt" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter Ticket number" maxlength="14" value={pasenger.inf.tkt} onChange={handlePaxChange(pasenger,true)} onKeyUp={(e)=>Utilities.handleKeyUp(e)}/>
                      </Form.Group>
                      </Form.Row>
                    }
                                    </div>
                   </td>
                   <td width="332">
                    <div className="d-flex align-item-center">
                  <Form.Check type="checkbox" label="" checked={pasenger.isemd} onChange={handlePaxEmd(pasenger)} custom id={idx+1} />
                   {!pasenger.isemd &&
                   <span>{pasenger.inf.emd_number!==""?pasenger.inf.emd_number:"N/A"} </span>
                   }
                   {pasenger.isemd &&
                  <Form.Row>
                        <Form.Group xs={6} as={Col} controlId="emd_number" className="edittime d-flex align-items-center justify-content-between m-0">
                        <Form.Control type="text" placeholder="Enter EMD number" value={pasenger.inf.emd_number} onChange={handlePaxEmdChange(pasenger,true)} />
                      </Form.Group>
                      </Form.Row>
                    }
                                    </div>
                   </td>
                   <td><span className="text-success">{pasenger.segst}</span></td>
                   <td><span className="text-success">{pasenger.sts}</span></td>
                  </tr>
                  }
               </>
            ))}
              </tbody>
              </Table>

            </div>

        <h5 className="title mt-4">Fare Details({props.userCurrency})</h5>
        <div className="confirmation confirmationMore mt-3">
              {state.adts > 0 &&
                <div className="cardMainlayout pb-0">

                    <div className="passengerName">
                        <Row>
                           <Col className="d-flex"><span className="d-flex">Passenger Details: Adult (<span class="panelTitle ezyIcon icon-person pl-2">* {state.adts}</span>)  </span>
                           {state.adtpax.length!==0 && state.adtpax.map((adt, idx) =>(
                               <React.Fragment key={idx}>
                           <span className="flex-column"><span className="passName">{adt.pname}</span> <span className="ticketNo"> (Tkt#: {adt.number}) </span> </span> </React.Fragment>
                           )) }
                           </Col>
                        </Row>
                    </div>
                    <h5 className="layoutTitle  d-flex justify-content-between mb-4"><span>Price Details ({props.userCurrency})</span></h5>
                    <table class="m-0 table passDetails">
                        <tr>
                            <th width="25%">Company Name</th>
                            <th width="15%">Fare Diffrence</th>
                            <th width="10%">Tax Diffrence</th>
                            <th width="10%">Markup</th>
                            <th width="10%">Service Fee</th>
                            <th width="10%">Penalty</th>
                            <th width="10%">Residual Value</th>
                            <th width="10%">Supplier Fee</th>
                            {context.logindata.region_type==="India" &&
                            <th width="10%">GST</th>
                            }
                            <th width="10%">Sub Total Charge</th>
                            <th width="10%">No. of Adults</th>
                            <th width="10%">Total Charge</th>
                        </tr>
                        <tr>
                            <td><span>ATT</span></td>
                            <td><Form.Group as={Col}  controlId="adtbase">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" disabled={state.disable} value={state.adtbase} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adttaxes">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" disabled={state.disable} value={state.adttaxes} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtmarkup">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" disabled={state.disable} value={state.adtmarkup} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="adtsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" disabled={state.disable} value={state.adtsf} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtPenalty">
                            <Form.Control  min={0} placeholder="Penalty" autoComplete="off" disabled={state.disable} value={state.adtPenalty} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="adtResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" disabled={true} value={state.adtResVal} onChange={handleChangeADTPrice("ATT", "ADT")}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={""} disabled={true}/>
                        </Form.Group></td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="adt_attgst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.adt_attgst} disabled={true}/>
                        </Form.Group></td>
                        }
                            <td><span>{state.adttotal}</span></td>
                            <td><span>{state.adts}</span></td>
                            <td>{state.adttotalamt}</td>
                        </tr>
                        <tr>
                            <td><span>TA</span></td>
                            <td><Form.Group as={Col}  controlId="adtbaseta">
                            <Form.Control  min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={Number(state.adtbase) + Number(state.adtmarkup)}  />
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adttaxesta">
                            <Form.Control  min={0} placeholder="Taxes"  autoComplete="off" value={Number(state.adttaxes) + Number(state.adt_attgst)} disabled={true}  />
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtmarkupta">
                            <Form.Control  min={0} placeholder="Markup" disabled={state.disable} autoComplete="off"  value={state.adtmarkupta} onChange={handleChangeADTPrice("TA", "ADT")} />
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtsfta">
                            <Form.Control  min={0} placeholder="Service Fee" disabled={state.disable} autoComplete="off" value={state.adtsfta} onChange={handleChangeADTPrice("TA", "ADT")} />
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtPenalty">
                            <Form.Control  min={0} placeholder="Penalty" autoComplete="off"  value={state.adtPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td>
                              <Form.Group as={Col}   controlId="adtResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.adtResVal} disabled={true}/>
                        </Form.Group>
                        </td>
                        <td>
                          <Form.Group as={Col}  controlId="adtsf">
                            <Form.Control  min={0} placeholder="Supplier Fee"  autoComplete="off" value={state.adtsf} disabled={true}/>
                        </Form.Group>
                        </td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="adt_tagst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.adt_tagst} disabled={true}/>
                        </Form.Group></td>
                        }
                            <td><span>{state.adttotalta}</span></td>
                            <td><span>{state.adts}</span></td>
                            <td><span>{state.adttotalamtta}</span></td>
                        </tr>
                        {context.logindata !== undefined && context.logindata.butype==="sta_user" &&
                        <tr>
                        <td><span>STA</span></td>
                            <td><Form.Group as={Col}  controlId="adtbasesta">
                            <Form.Control  min={0} placeholder="Base Fare" disabled={state.disable} autoComplete="off" value={Number(state.adtbase) + Number(state.adtmarkup) + Number(state.adtmarkupta) + Number(state.adtsf)} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adttaxessta">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={state.adttaxes} disabled={true} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtmarkupsta">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" disabled={state.disable} value={state.adtmarkupsta} onChange={handleChangeADTPrice("STA", "ADT")} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="adtsfsta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" disabled={state.disable} value={state.adtsfsta} onChange={handleChangeADTPrice("STA", "ADT")} />
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtPenalty">
                            <Form.Control  min={0} placeholder="Penalty" autoComplete="off"  value={state.adtPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="adtResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.adtResVal} disabled={true}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="adtsf">
                            <Form.Control  min={0} placeholder="Supplier Fee" autoComplete="off" value={state.adtsfta} disabled={true}/>

                        </Form.Group></td>
                            <td><span>{state.adttotalsta}</span></td>
                            <td><span>{state.adts}</span></td>
                            <td><span>{state.adttotalamtsta}</span></td>
                        </tr>
                  }
                    </table>
                </div>
                }

               {state.chds !== 0 &&<div className="cardMainlayout pb-0">
                    <div className="passengerName">
                        <Row>
                            <Col className="d-flex"><span className="d-flex">Child (<span class="panelTitle ezyIcon icon-person pl-2">* {state.chds}</span>)  </span>
                            { state.chdpax.map((chd, idx) =>(
                               <React.Fragment key={idx}>
                            <span className="flex-column"><span className="passName">{chd.pname}</span> <span className="ticketNo">(Tkt#: {chd.number})</span> </span> </React.Fragment>
                           )) }
                           </Col>
                        </Row>
                    </div>
                    <h5 className="layoutTitle  d-flex justify-content-between mb-4"><span>Price Details ({props.userCurrency})</span></h5>
                    <table class="m-0 table passDetails">
                        <tr>
                            <th width="25%">Company Name</th>
                            <th width="15%">Fare Diffrence</th>
                            <th width="10%">Tax Diffrence</th>
                            <th width="10%">Markup</th>
                            <th width="10%">Service Fee</th>
                            <th width="10%">Penalty</th>
                            <th width="10%">Residual Value</th>
                            <th width="10%">Supplier Fee</th>
                            {context.logindata.region_type==="India" &&
                            <th width="10%">GST</th>
                            }
                            <th width="10%">Sub Total Charge</th>
                            <th width="10%">No. of Infants</th>
                            <th width="10%">Total Charge</th>
                        </tr>
                        <tr>
                            <td><span>ATT</span></td>
                            <td><Form.Group as={Col}  controlId="chdbase">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" value={state.chdbase} disabled={state.disable} onChange={handleChangeCHDPrice("ATT", "CHD")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdtaxes">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={state.chdtaxes} disabled={state.disable} onChange={handleChangeCHDPrice("ATT", "CHD")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdmarkup">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.chdmarkup} disabled={state.disable} onChange={handleChangeCHDPrice("ATT", "CHD")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.chdsf} disabled={state.disable} onChange={handleChangeCHDPrice("ATT", "CHD")}/>

                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="chdPenalty">
                            <Form.Control  min={0} placeholder="Penalty" autoComplete="off" disabled={state.disable}  value={state.chdPenalty} onChange={handleChangeCHDPrice("ATT", "CHD")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="chdResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" disabled={true} value={state.chdResVal} onChange={handleChangeCHDPrice("ATT", "CHD")}/>
                        </Form.Group></td>

                        <td><Form.Group as={Col}  controlId="infsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={""} disabled={true}/>

                        </Form.Group></td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="chd_attgst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.chd_attgst} disabled={true}/>
                        </Form.Group></td>
                        }
                            <td><span>{state.chdtotal}</span></td>
                            <td><span>{state.chds}</span></td>
                            <td>{state.chdtotalamt}</td>
                        </tr>
                        <tr>
                            <td><span>TA</span></td>
                            <td><Form.Group as={Col}  controlId="chd_base_fare_ta">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" value={Number(state.chdbase) + Number(state.chdmarkup)}  disabled={true}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chd_taxes_ta">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={Number(state.chdtaxes) + Number(state.chd_attgst)} disabled={true} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdmarkupta">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.chdmarkupta} disabled={state.disable} onChange={handleChangeCHDPrice("TA", "CHD")} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdsfta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.chdsfta} disabled={state.disable} onChange={handleChangeCHDPrice("TA", "CHD")} />

                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="chdPenalty">
                            <Form.Control  min={0} placeholder="Penalty" autoComplete="off"  value={state.chdPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="chdResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.chdResVal} disabled={true}/>
                        </Form.Group></td>

                        <td><Form.Group as={Col}  controlId="chdsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.chdsf} disabled={true}/>

                        </Form.Group></td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="chd_tagst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.chd_tagst} disabled={true}/>

                        </Form.Group></td>
                        }
                            <td><span>{state.chdtotalta}</span></td>
                            <td><span>{state.chds}</span></td>
                            <td><span>{state.chdtotalamtta}</span></td>
                        </tr>
                        {context.logindata !== undefined && context.logindata.butype==="sta_user" &&
                        <tr>
                            <td><span>STA</span></td>
                            <td><Form.Group as={Col}  controlId="chd_base_fare_sta">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" value={Number(state.chdbase) + Number(state.chdmarkup) + Number(state.chdmarkupta) + Number(state.chdsf)}  disabled={true} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdtaxes">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={state.chdtaxes} disabled={true} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdmarkupsta">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.chdmarkupsta} disabled={state.disable} onChange={handleChangeCHDPrice("STA", "CHD")} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="chdsfsta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.chdsfsta} disabled={state.disable} onChange={handleChangeCHDPrice("STA", "CHD")} />

                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="chdPenaltyTa">
                            <Form.Control  min={0} placeholder="chdPenalty" autoComplete="off"  value={state.chdPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="chdResVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.chdResVal} disabled={true}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="chdsfta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.chdsfta} disabled={true} />
                        </Form.Group></td>
                            <td><span>{state.chdtotalsta}</span></td>
                            <td><span>{state.chds}</span></td>
                            <td><span>{state.chdtotalamtsta}</span></td>
                        </tr>}
                    </table>
                </div>
}

                {state.infs!== 0 &&
                <div className="cardMainlayout pb-0">

                    <div className="passengerName">
                        <Row>
                            <Col className="d-flex"><span className="d-flex">Infant (<span class="panelTitle ezyIcon icon-person pl-2">* {state.infs}</span>)  </span>
                            { state.infpax.map((inf, idx) =>(
                               <React.Fragment key={idx}>
                            <span className="flex-column"><span className="passName">{inf.pname}</span> <span className="ticketNo">(Tkt#: {inf.number}) </span> </span> </React.Fragment>

                           )) }
                           </Col>
                        </Row>

                    </div>


                    <h5 className="layoutTitle  d-flex justify-content-between mb-4"><span>Price Details ({props.userCurrency})</span></h5>
                    <table class="m-0 table passDetails">
                        <tr>
                            <th width="25%">Company Name</th>
                            <th width="15%">Fare Diffrence</th>
                            <th width="10%">Tax Diffrence</th>
                            <th width="10%">Markup</th>
                            <th width="10%">Service Fee</th>
                            <th width="10%">Penalty</th>
                            <th width="10%">Residual Value</th>
                            <th width="10%">Supplier Fee</th>
                            {context.logindata.region_type==="India" &&
                            <th width="10%">GST</th>
                            }
                            <th width="10%">Sub Total Charge</th>
                            <th width="10%">No. of Infants</th>
                            <th width="10%">Total Charge</th>
                        </tr>
                        <tr>
                            <td><span>ATT</span></td>
                            <td><Form.Group as={Col}  controlId="infbase">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" value={state.infbase} disabled={state.disable} onChange={handleChangeINFPrice("ATT", "INF")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="inftaxes">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={state.inftaxes} disabled={state.disable} onChange={handleChangeINFPrice("ATT", "INF")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infmarkup">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.infmarkup} disabled={state.disable}  onChange={handleChangeINFPrice("ATT", "INF")}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.infsf} disabled={state.disable} onChange={handleChangeINFPrice("ATT", "INF")}/>

                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="infPenalty">
                            <Form.Control  min={0} placeholder="chdPenaltySta" autoComplete="off" disabled={state.disable}  value={state.infPenalty} onChange={handleChangeINFPrice("ATT", "INF")}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="infResidualVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" disabled={true} value={state.infResidualVal} onChange={handleChangeINFPrice("ATT", "INF")}/>
                        </Form.Group></td>

                        <td><Form.Group as={Col}  controlId="infsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={0} disabled={true}/>

                        </Form.Group></td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="inf_attgst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.inf_attgst} disabled={true}/>
                        </Form.Group></td>
                        }
                            <td><span>{state.inftotal}</span></td>
                            <td><span>{state.infs}</span></td>
                            <td>{state.inftotalamt}</td>
                        </tr>
                        <tr>
                            <td><span>TA</span></td>
                            <td><Form.Group as={Col}  controlId="infbaseta">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" disabled={true} value={Number(state.infbase) + Number(state.infmarkup)} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="inftaxesta">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" disabled={true} value={Number(state.inftaxes)+Number(state.inf_attgst)}  />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infmarkupta">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.infmarkupta} disabled={state.disable} onChange={handleChangeINFPrice("TA", "INF")} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infsfta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.infsfta} disabled={state.disable} onChange={handleChangeINFPrice("TA", "INF")} />

                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="infPenalty">
                            <Form.Control  min={0} placeholder="chdPenaltySta" autoComplete="off"  value={state.infPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="infResidualVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.infResidualVal} disabled={true}/>
                        </Form.Group></td>
                        <td><Form.Group as={Col}  controlId="infsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.infsf} disabled={true}/>

                        </Form.Group></td>
                        {context.logindata.region_type==="India" &&
                        <td><Form.Group as={Col}  controlId="inf_tagst">
                            <Form.Control type="number" min={0} placeholder="GST" autoComplete="off" value={state.inf_tagst} disabled={true}/>
                        </Form.Group></td>
                        }
                            <td><span>{state.inftotalta}</span></td>
                            <td><span>{state.infs}</span></td>
                            <td><span>{state.inftotalamtta}</span></td>
                        </tr>
                        {context.logindata !== undefined && context.logindata.butype==="sta_user" &&
                        <tr>
                         <td><span>STA</span></td>
                            <td><Form.Group as={Col}  controlId="infbasesta">
                            <Form.Control  min={0} placeholder="Base Fare" autoComplete="off" disabled={true} value={Number(state.infbase) + Number(state.infmarkupta) + Number(state.infmarkup) + Number(state.infsf)}/>

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="inftaxessta">
                            <Form.Control  min={0} placeholder="Taxes" autoComplete="off" value={state.inftaxes} disabled={true}  />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infmarkupsta">
                            <Form.Control  min={0} placeholder="Markup" autoComplete="off" value={state.infmarkupsta} disabled={state.disable} onChange={handleChangeINFPrice("STA", "INF")} />

                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infsfsta">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.infsfsta} disabled={state.disable} onChange={handleChangeINFPrice("STA", "INF")} />

                            </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infPenalty">
                            <Form.Control  min={0} placeholder="chdPenaltySta" autoComplete="off"  value={state.infPenalty} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}   controlId="infResidualVal">
                            <Form.Control  min={0} placeholder="Residua Value" autoComplete="off" value={state.infResidualVal} disabled={true}/>
                        </Form.Group></td>
                            <td><Form.Group as={Col}  controlId="infsf">
                            <Form.Control  min={0} placeholder="Service Fee" autoComplete="off" value={state.infsfta} disabled={true}/>

                        </Form.Group></td>

                            <td><span>{state.inftotalsta}</span></td>
                            <td><span>{state.infs}</span></td>
                            <td><span>{state.inftotalamtsta}</span></td>
                        </tr>
                        }
                        </table>
                    </div>
                       }
                  </div>
                      <div className="ticketSummerynew d-flex">
                      {state.adts > 0 &&
                      <Col xs={6} className="cardMainlayout mr-2">
                      <h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Ticket Summary ATT</span></h5>
                      <div className="table-responsive">
                      <table class="m-0 table">
                    <tr>
                      <th width="25%">Passenger Type</th>
                      <th width="15%">Sub Total * Pax</th>
                      <th width="15%" align="right">Charge to TA</th>
                    </tr>
                    <tr>
                      <td><span>Adult</span></td>
                      <td><span>{Number(state.adttotal)} * {state.adts}</span></td>
                      <td align="right"><span>{Number(state.adttotal) * Number(state.adts)}</span></td>
                  </tr>
                  {state.chds > 0 &&
                    <tr>
                      <td><span>Child</span></td>
                      <td><span>{Number(state.chdtotal)} * {state.chds}</span></td>
                      <td align="right"><span>{Number(state.chdtotal) * Number(state.chds)}</span></td>
                  </tr>
                  }
                  {state.infs > 0 &&
                  <tr>
                      <td><span>Infant</span></td>
                      <td><span>{Number(state.inftotal)} * {state.infs}</span></td>
                      <td align="right"><span>{Number(state.inftotal) * Number(state.infs)}</span></td>
                  </tr>
                  }

                <tr>
                      <td></td>
                    <td colspan="2" class="text-right"><span><strong>Total Charge: {Number(state.adttotalamt) + Number(state.chdtotalamt) + Number(state.inftotalamt)}</strong></span></td>
                </tr>
              </table>
              </div>
                  </Col>
              }
              {state.adts > 0 &&
                      <Col   xs={6} className="cardMainlayout ml-2">

                      <h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Ticket Summary TA</span></h5>
                      <div className="table-responsive">
                      <table class="m-0 table">
                  <tr>
                      <th width="25%">Passenger Type</th>
                      <th width="15%">Sub Total * Pax</th>
                      {context.logindata.butype==="sta_user"?<th width="15%">Charge to STA</th>: <th width="15%">Charge to Customer</th>}
                  </tr>
                  <tr>
                      <td><span>Adult</span></td>
                      <td><span>{Number(state.adttotalta)} * {state.adts}</span></td>
                      <td align="right"><span>{Number(state.adttotalta) * Number(state.adts)}</span></td>
                  </tr>
                  {state.chds > 0 &&
                  <tr>
                      <td><span>Child</span></td>
                      <td><span>{Number(state.chdtotalta)} * {state.chds}</span></td>
                      <td align="right"><span>{Number(state.chdtotalta) * Number(state.chds)}</span></td>
                  </tr>
                  }
                  {state.infs > 0 &&
                  <tr>
                      <td><span>Infant</span></td>
                      <td><span>{Number(state.inftotalta)} * {state.infs}</span></td>
                      <td align="right"><span>{Number(state.inftotalta) * Number(state.infs)}</span></td>
                  </tr>
                  }
                <tr>
                    <td></td>
                    <td  colspan="2" class="text-right"><span><strong>Total Charge: {Number(state.adttotalamtta) + Number(state.chdtotalamtta) + Number(state.inftotalamtta)} </strong></span></td>
                  </tr>

              </table>
              </div>
                      </Col>
              }
                      {context.logindata.butype==="sta_user" && state.adts > 0 &&
                      <Col className="cardMainlayout ml-2">
                      <h5 className="layoutTitle  d-flex justify-content-between"><span>Ticket Summary STA</span></h5>
                      <table class="m-0 table">
                  <tr>
                      <th width="25%">Passenger Type</th>
                      <th width="15%">Sub Total * Pax</th>
                      <th width="15%">Charge to Customer</th>
                  </tr>
                  <tr>
                      <td><span>Adult</span></td>
                      <td><span>{Number(state.adttotalsta)} * {state.adts}</span></td>
                      <td align="right"><span>{Number(state.adttotalsta) * Number(state.adts)}</span></td>
                  </tr>
                  {state.chds > 0 &&
                  <tr>
                      <td><span>Child</span></td>
                      <td><span>{Number(state.chdtotalsta)} * {state.chds}</span></td>
                      <td align="right"><span>{Number(state.chdtotalsta) * Number(state.chds)}</span></td>
                  </tr>
                  }
                  {state.infs > 0 &&
                  <tr>
                      <td><span>Infant</span></td>
                      <td><span>{Number(state.inftotalsta)} * {state.infs}</span></td>
                      <td align="right"><span>{Number(state.inftotalsta) * Number(state.infs)}</span></td>
                  </tr>
                  }
                <tr>
                  <td colspan="2"></td>
                  <td><span><strong>Total Charge: {Number(state.adttotalamtsta) + Number(state.chdtotalamtsta) + Number(state.inftotalamtsta)} </strong></span></td>
                  </tr>
              </table>
                      </Col>
              }
                  </div>
            <div>
            {state.enablePayment &&
              <div className="cardMainlayout minHight0 mannualReissuet justify-content-between">
               <ManualPaymentForm payobj={state.payData} callBooking={confirmManualBooking}/>
             </div>
            }
           </div>
           <div className='d-flex justify-content-end'>
            <div className="text-right mr-2">
                <Button
                size="xs"
                variant="primary"
                onClick={() => cancel()}
                type="submit"
                >Cancel</Button>
                </div>
            {!state.enablePayment &&
                <div className="text-right">
                <Button
                size="xs"
                variant="primary"
                onClick={() => continueBooking()}
                type="submit"
                >Continue</Button>
                </div>
                 }
                 </div>
        </div>
      </div>
    </>
  )
}
export default ShowManualPnrData