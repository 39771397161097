import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import Utilities from '../../../commonUtils/Utilities';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
export const BOOKING_SERVICE = "bkServiceFee";
export const PLB_DISCOUNT = "PLB_DISCOUNT"
export const ONFLY_FLT_MKP = "onflyFltMkp";

/**
 * @description:This function will Calculate the Pax wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 01-09-2020
 */

/**
* Initial State Declaration
*/
const initialState = {
  tsfee: 0, tdisc: 0, totalfare: 0, discount: 0 ,gt : 0 , mealAdt:0,mealChd:0,mealInf:0,adtList:[],totalExtra:0,adtBagList:[],chdBagList:[],mealAdtlist:[],mealChdlist:[],otCharge:0,seatPrice:0
};

//====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function TripCost(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [adultPrice, setAdultPrice] = useState(true)
  const [childPrice, setCildPrice] = useState()
  const [infantPrice, setInfantPrice] = useState()
  const [price, setPrice] = useState({ prList: "" })
  const [destPrice, setDestPrice] = useState({ destPriceObj: "" })
  const [searchType, setSearchType] = useState(props.srequest.sty);
  const [request, setRequest] = useState(props.srequest);
  const [totalResdVal, setTotalResdVal] = useState(0)
  const [onflyMkp, setOnflyMkp] = useState(0)
  const [paxMkp, setPaxMkp] = useState(0)
  const [gst_val, setGstValue] = useState(0)
  const [adtTds, setAdtTds] = useState(0)
  const [chdTds, setChdTds] = useState(0)
  const [infTds, setInfTds] = useState(0)
  const [totalTds, setTotalTds] = useState(0)
  const [tds_percentage_plb, setTdsPrecentagePlb] = useState(0)
  const [adtPlb, setAdtPlb] = useState(0)
  const [chdPlb, setChdPlb] = useState(0)
  const [infPlb, setInfPlb] = useState(0)

  useEffect(() => {
    if (props.flowtype !== undefined && props.flowtype === "result") {
      if (request.tt === 3) {
        request.adt=request.search.adt;
        request.chd=request.search.chd;
        request.inf=request.search.inf;
        setRequest(request.search)
      }
      setPrice({ prList: props.tripCost })
      let type="normal";
      if(request.sty==="split"){
        type="normal";
        setSearchType("normal");
      }
      if(props.tripCost.gst!==undefined){
        setGstValue(props.tripCost.gst.total_gst)
      }
      var userType = (request.butype === "ta_user") ? "TA" : "STA";
      if (type === "normal") {
        if (props.tripCost.servicefee !== undefined && props.tripCost.servicefee !== null) {
          let servicefee = Utilities.calculateServiceFee(props.tripCost.servicefee, userType, request);
          if (servicefee !== undefined && servicefee !== 0) {
            dispatch({ type: 'tsfee', payload: servicefee })
            localStorage.setItem(BOOKING_SERVICE, servicefee);
            props.sendSrviceFee(servicefee);
          }
        }
      } else {
        if(request.flowType!=="REISSUE"){
          let onwardServiceFee = 0;
          let returnServiceFee = 0;
          let totalService = 0;
          if (request.origns!==undefined && request.origns[0]!==undefined && request.origns[0].srcorigns[0] !== undefined && request.origns[0].srcorigns[0].serviceFee !== undefined) {
            onwardServiceFee = Utilities.calculateServiceFee(request.origns[0].srcorigns[0].serviceFee, userType, request);
          }
          if (request.origns!==undefined && request.origns[0]!==undefined && request.origns[0].destorigins[0] !== undefined && request.origns[0].destorigins[0].serviceFee !== undefined) {
            returnServiceFee = Utilities.calculateServiceFee(request.origns[0].destorigins[0].serviceFee, userType, request);
          }
          if (onwardServiceFee !== undefined && returnServiceFee !== undefined && onwardServiceFee !== 0 && returnServiceFee !== 0) {
            totalService = Utilities.addPrice(onwardServiceFee, returnServiceFee, "Add");
            dispatch({ type: 'tsfee', payload: totalService })
            props.sendSrviceFee(totalService);
            localStorage.setItem(BOOKING_SERVICE, totalService);
          } else if (onwardServiceFee !== undefined && onwardServiceFee !== 0) {
            dispatch({ type: 'tsfee', payload: onwardServiceFee })
            props.sendSrviceFee(onwardServiceFee);
            localStorage.setItem(BOOKING_SERVICE, onwardServiceFee);
          } else if (returnServiceFee !== undefined && returnServiceFee !== 0) {
            dispatch({ type: 'tsfee', payload: returnServiceFee })
            props.sendSrviceFee(returnServiceFee);
            localStorage.setItem(BOOKING_SERVICE, returnServiceFee);
          }
        }else{
          if(props.tripCost!==undefined && props.tripCost.servicefee!==undefined){
            let serviceFee=Utilities.calculateServiceFee(props.tripCost.servicefee, userType, request);
            dispatch({ type: 'tsfee', payload: serviceFee });
            props.sendSrviceFee(serviceFee);
            localStorage.setItem(BOOKING_SERVICE, serviceFee);
          }
        }
      }
      //GST Applying Part
      //calculateGST(props.tripCost.servicefee,props.tripCost.gst,request.origns[0].srcorigns[0].pr.tax)

    } else if (props.flowtype !== undefined && props.flowtype === "review") {
      if(request.gst!==undefined && request.gst!=="" && props.srequest!==undefined && props.srequest.sty==="normal"){
        setGstValue(request.gst.total_gst)
      }else{
        if(props.srequest!==undefined && props.srequest.ow_gst!=="" && props.srequest.ow_gst!==undefined && props.srequest.rt_gst!=="" &&  props.srequest.rt_gst!==undefined){
          setGstValue(Number(props.srequest.ow_gst.total_gst) + Number(props.srequest.rt_gst.total_gst))
        }
      }
      if (request.sty !== undefined && request.sty === "normal"){
        if(request.tt==="2" && props.tripCost.srcorigns[0].sup!=="0" && props.tripCost.destorigins[0].sup!=="0"){
          setPrice({ prList: props.tripCost.srcorigns[0] })
          setDestPrice({ destPriceObj: props.tripCost.destorigins[0] })
          setSearchType("split");
          calFBFServiceFee();
        }else{
          setPrice({ prList: props.tripCost.srcorigns[0] })
        let serviceFee = localStorage.getItem(BOOKING_SERVICE);
        if (Number(serviceFee) !== 0 && serviceFee !== undefined && serviceFee!=="NaN") {
          dispatch({ type: 'tsfee', payload: Number(serviceFee) })
          props.sendSrviceFee(serviceFee);
          localStorage.removeItem(BOOKING_SERVICE);
        } else {
          var userType = (request.butype === "ta_user") ? "TA" : "STA";
          if (request.origns[0]!==undefined && request.origns[0].srcorigns[0] !== undefined && request.origns[0].srcorigns[0].serviceFee !== undefined && request.origns[0].srcorigns[0].serviceFee) {
            let servicefee = Utilities.calculateServiceFee(request.origns[0].srcorigns[0].serviceFee, userType, request);
            if (servicefee !== undefined && servicefee !== 0 && serviceFee!=="NaN") {
              props.sendSrviceFee(servicefee);
              dispatch({ type: 'tsfee', payload: servicefee })
            }
          }
        }
        }
      }else{
        setPrice({ prList: props.tripCost.srcorigns[0] })
        setDestPrice({ destPriceObj: props.tripCost.destorigins[0] })
        calFBFServiceFee();
      }
    } else if (props.flowtype !== undefined && props.flowtype === "import") {
      if(props.gstObj!==undefined && props.gstObj.total_gst!==undefined){
        setGstValue(props.gstObj.total_gst)
      }
      var userType = (request.butype === "ta_user") ? "TA" : "STA";
      if(props.tripCost!==undefined && props.tripCost.srcorigns!==undefined && props.tripCost.srcorigns.length!==0){
        setPrice({ prList: props.tripCost.srcorigns[0] })
      }
      if (props.serviceFee !== undefined && props.serviceFee !== null) {
        let servicefee = Utilities.calculateServiceFee(props.serviceFee, userType, request);
        if (servicefee !== undefined && servicefee !== 0) {
          dispatch({ type: 'tsfee', payload: servicefee })
          localStorage.setItem(BOOKING_SERVICE, servicefee);
        }
      }
    }
    //Flight OnFly Markup
    if(props.flowtype === "result" || props.flowtype === "review"){
    let onFlyMarkup=window.sessionStorage.getItem(ONFLY_FLT_MKP);
    if(onFlyMarkup===null && request.onFlyMkp!==undefined){
      onFlyMarkup=Number(request.onFlyMkp);
      window.sessionStorage.setItem(ONFLY_FLT_MKP,onFlyMarkup);
    }
    if(onFlyMarkup!==null){
    if(props.sty === "normal"){
        setOnflyMkp(Number(onFlyMarkup))
        let paxcnt=Number(request.adt)+Number(request.chd)+Number(request.inf);
        setPaxMkp(onFlyMarkup/paxcnt)
    }else{
        if(props.flowtype === "result"){
          let mkp=onFlyMarkup/2;
          setOnflyMkp(Number(mkp))
          let paxcnt=Number(request.adt)+Number(request.chd)+Number(request.inf);
          setPaxMkp(Number(mkp/paxcnt))
        }else{
          setOnflyMkp(Number(onFlyMarkup))
          let paxcnt=Number(request.adt)+Number(request.chd)+Number(request.inf);
          setPaxMkp(onFlyMarkup/paxcnt)
        }
      }
    }

    }

  }, []);

  useEffect(() => {
    if((props.flowtype !== undefined && props.flowtype === "review" && props.fareRuleResp!==undefined && props.fareRuleResp.data!==undefined && props.fareRuleResp.data.length!==0 && props.fareRuleResp.data[0].plb!==undefined) || props.summaryContiueClick !== undefined) {
      AttCommonSerivce.getTdsDetails({region_type:props.regionType,product:"Flight"}).then(response => {
        if (response.data.suc && response.data.res.length !== 0){
          setTdsPrecentagePlb(response.data.res[0].plb)
        }
      })
   }
  }, [props.fareRuleResp])

  useEffect(() => {
    AttCommonSerivce.getTdsDetails({region_type:props.regionType,product:"Flight"}).then(response => {
      if (response.data.suc && response.data.res.length !== 0){
        setTdsPrecentagePlb(response.data.res[0].plb)
      }
    })
  }, [])

  const calFBFServiceFee=()=>{
    var userType = (request.butype === "ta_user") ? "TA" : "STA";
    let onwardServiceFee = 0;
    let returnServiceFee = 0;
    let totalService = 0;
    if (request.origns[0].srcorigns[0] !== undefined && request.origns[0].srcorigns[0].serviceFee !== undefined && request.origns[0].srcorigns[0].serviceFee) {
      onwardServiceFee = Utilities.calculateServiceFee(request.origns[0].srcorigns[0].serviceFee, userType, request);
    }
    if (request.origns[0].srcorigns[0] !== undefined && request.origns[0].destorigins[0].serviceFee !== undefined && request.origns[0].srcorigns[0].serviceFee) {
      returnServiceFee = Utilities.calculateServiceFee(request.origns[0].destorigins[0].serviceFee, userType, request);
    }
    if (onwardServiceFee !== undefined && returnServiceFee !== undefined && onwardServiceFee !== 0 && returnServiceFee !== 0) {
      totalService = Utilities.addPrice(onwardServiceFee, returnServiceFee, "Add");
      dispatch({ type: 'tsfee', payload: totalService })
      props.sendSrviceFee(totalService);
      localStorage.setItem(BOOKING_SERVICE, totalService);
    } else if (onwardServiceFee !== undefined && onwardServiceFee !== 0) {
      dispatch({ type: 'tsfee', payload: onwardServiceFee })
      props.sendSrviceFee(onwardServiceFee);
      localStorage.setItem(BOOKING_SERVICE, onwardServiceFee);
    } else if (returnServiceFee !== undefined && returnServiceFee !== 0) {
      dispatch({ type: 'tsfee', payload: returnServiceFee })
      props.sendSrviceFee(returnServiceFee);
      localStorage.setItem(BOOKING_SERVICE, returnServiceFee);
    }
  }


  useEffect(() => {
    //PLB Discount
    if ((props.fareRuleResp !== undefined && props.fareRuleResp.data !== undefined && props.fareRuleResp.data[0] !== undefined) || props.summaryContiueClick !== undefined) {
      if ((props.fareRuleResp !== undefined && props.fareRuleResp.data !== undefined && props.fareRuleResp.data[0] !== undefined && props.fareRuleResp.data[0].plb !== undefined) || props.summaryContiueClick !== undefined) {
        let discObj = null;
        if (props.summaryContiueClick !== undefined) {
          discObj = props.summaryContiueClick;
        } else if (props.fareRuleResp !== undefined && props.fareRuleResp.data !== undefined && props.fareRuleResp.data[0] !== undefined && props.fareRuleResp.data[0].plb) {
          discObj = props.fareRuleResp.data[0].plb;
        }
        if (request!==undefined && request.sty === "normal" && discObj!==undefined && props.tripCost!==undefined && props.tripCost.srcorigns.length!==0 && props.tripCost.srcorigns[0].sup==="0") {
          let discount = 0;
          let adtDisc=discObj.adt*request.adt;
          let chdDisc=discObj.chd*request.chd;
          let infDisc=discObj.inf*request.inf;
          discount = Utilities.addPrice(adtDisc + chdDisc + infDisc, 0, "Add");
          dispatch({ type: 'discount', payload: parseFloat(discount) });
          setAdtPlb(discObj.adt);
          setChdPlb(discObj.chd);
          setInfPlb(discObj.inf);
          let adtTds=0; let chdTds=0; let inftds=0;
          if(discObj.adt!==0 && discObj.adt!==""){
            adtTds = Utilities.applyTDS(tds_percentage_plb,discObj.adt);
            setAdtTds(Number(adtTds));
          }
          if(discObj.chd!==0 && discObj.chd!==""){
            chdTds = Utilities.applyTDS(tds_percentage_plb,discObj.chd);
            setChdTds(Number(chdTds));
          }
          if(discObj.inf!==0 && discObj.inf!==""){
            inftds = Utilities.applyTDS(tds_percentage_plb,discObj.inf);
            setInfTds(Number(inftds));
          }
          setTotalTds((adtTds*request.adt)+(chdTds*request.chd)+(inftds*request.inf))
          const req={
            discount:discount,
            adtTds_val:adtTds,
            chdTds_val:chdTds,
            inftds_val:inftds,
            precentage:tds_percentage_plb,
            total_tds:(adtTds*request.adt)+(chdTds*request.chd)+(inftds*request.inf)
          }
          props.sendPLB(req);
          localStorage.setItem(PLB_DISCOUNT, discount);
        } else if ((props.tripCost!==undefined && props.tripCost.srcorigns.length!==0 && props.tripCost.destorigins.length!==0 &&  props.tripCost.srcorigns[0].sup==="0" || props.tripCost.destorigins[0].sup==="0") && discObj && discObj.adt!==undefined) {
          let adtDisc = 0;
          let chdDisc = 0;
          let infDisc = 0;
          let tdsAdt1=0; let tdsChd1=0; let tdsInf1=0;
          let tdsAdt2=0; let tdsChd2=0; let tdsInf2=0;
          if (discObj.adt !== undefined && discObj.adt !== null && discObj.adt!=="") {
            let adtPlb = discObj.adt.split("/");
            adtDisc = Utilities.addPrice(adtPlb[0], adtPlb[1]!==undefined?adtPlb[1]:0, "Add");
            tdsAdt1 = Utilities.applyTDS(tds_percentage_plb,adtPlb[0]);
            tdsAdt2 = Utilities.applyTDS(tds_percentage_plb,adtPlb[1]!==undefined?adtPlb[1]:0);
            adtDisc=adtDisc*request.adt;
            setAdtTds(Number(tdsAdt1)+Number(tdsAdt2));
          }
          if (discObj.chd !== undefined && discObj.chd !== null && discObj.chd!=="") {
            let chdPlb = discObj.chd.split("/");
            chdDisc = Utilities.addPrice(chdPlb[0], chdPlb[1]!==undefined?chdPlb[1]:0, "Add");
            tdsChd1 = Utilities.applyTDS(tds_percentage_plb,chdPlb[0]);
            tdsChd2 = Utilities.applyTDS(tds_percentage_plb,chdPlb[1]!==undefined?chdPlb[1]:0);
            chdDisc=chdDisc*request.chd;
            setChdTds(Number(tdsChd1)+Number(tdsChd2));
          }
          if (discObj.inf !== undefined && discObj.inf !== null && discObj.inf!=="") {
            let infPlb = discObj.inf.split("/");
            infDisc = Utilities.addPrice(infPlb[0], infPlb[1]!==undefined?infPlb[1]:0, "Add");
            tdsInf1 = Utilities.applyTDS(tds_percentage_plb,infPlb[0]);
            tdsInf2 = Utilities.applyTDS(tds_percentage_plb,infPlb[1]!==undefined?infPlb[1]:0);
            infDisc=infDisc*request.inf;
            setInfTds(Number(tdsInf1)+Number(tdsInf2));
          }
          let discount = 0;
          discount = Utilities.addPrice(parseFloat(adtDisc) + parseFloat(chdDisc) + parseFloat(infDisc), 0, "Add");
          dispatch({ type: 'discount', payload: discount })
          localStorage.setItem(PLB_DISCOUNT, discount);
          setTotalTds((tdsAdt1*request.adt)+(tdsAdt2*request.adt)+(tdsChd1*request.chd)+(tdsChd2*request.chd)+(tdsInf1*request.inf)+(tdsInf2*request.inf))
          const req1={
            discount:discount,
            adtTds_val:tdsAdt1+"/"+tdsAdt2,
            chdTds_val:tdsChd1+"/"+tdsChd2,
            inftds_val:tdsInf1+"/"+tdsInf2,
            precentage:tds_percentage_plb,
            total_tds:(tdsAdt1*request.adt)+(tdsAdt2*request.adt)+(tdsChd1*request.chd)+(tdsChd2*request.chd)+(tdsInf1*request.inf)+(tdsInf2*request.inf)
          }
          props.sendPLB(req1);
        }
        if(request.tds!==undefined && request.tds!=="" && props.srequest!==undefined && props.srequest.sty==="normal") {
          setTotalTds(request.tds.total_tds)
        }
      }
    }
  }, [props.fareRuleResp, props.summaryContiueClick ,tds_percentage_plb]);

  useEffect(()=>{
    if(props.baggagePrice!="" && props.baggagePrice!==undefined){
     if(props.baggagePrice[1]==="ADT"){
          const payload={
            "no":props.baggagePrice[2],
            "flow":props.baggagePrice[3],
            "price":props.baggagePrice[0].price
          }
          if(state.adtBagList.length===0){
            let list=[];
            list.push(payload);
            dispatch({ type: 'adtBagList', payload: list })
          }else{
            let match=true;
            for(let val of state.adtBagList){
              if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
                 val.price=props.baggagePrice[0].price;
                 match=false;
              }
            }
            if(match){
              let bagsList=[];
              bagsList=state.adtBagList;
              bagsList.push(payload);
              dispatch({ type: 'adtBagList', payload: bagsList })
            }else{
              dispatch({ type: 'adtBagList', payload: state.adtBagList })
            }
          }
     }else if(props.baggagePrice[1]==="CHD"){
      const payload={
        "no":props.baggagePrice[2],
        "flow":props.baggagePrice[3],
        "price":props.baggagePrice[0].price
      }
      if(state.chdBagList.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'chdBagList', payload: list })
      }else{
        let match=true;
        for(let val of state.chdBagList){
          if(val.no===props.baggagePrice[2] && val.flow===props.baggagePrice[3]){
             val.price=props.baggagePrice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.chdBagList;
          bagsList.push(payload);
          dispatch({ type: 'chdBagList', payload: bagsList })
        }else{
          dispatch({ type: 'chdBagList', payload: state.chdBagList })
        }
      }
     }else if(props.baggagePrice[1]==="INF"){
      if(props.baggagePrice[3]==="O"){
        dispatch({ type: 'oInfp', payload:Number(props.baggagePrice[0].price) })
        dispatch({ type: 'bagInf', payload:state.rInfp+Number(props.baggagePrice[0].price) })
       }else if(props.baggagePrice[3]==="R"){
        dispatch({ type: 'rInfp', payload:Number(props.baggagePrice[0].price) })
        dispatch({ type: 'bagInf', payload:state.oInfp+Number(props.baggagePrice[0].price) })
       }
     }

    }
  },[props.baggagePrice])

  useEffect(()=>{
    if(props.selMealprice!="" && props.selMealprice!==undefined){
     if(props.selMealprice[1]==="ADT"){
      const payload={
        "no":props.selMealprice[2],
        "flow":props.selMealprice[3],
        "price":props.selMealprice[0].price,
        "route":props.selMealprice[4]
      }
      if(state.mealAdtlist.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'mealAdtlist', payload: list })
      }else{
        let match=true;
        for(let val of state.mealAdtlist){
          if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
             val.price=props.selMealprice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.mealAdtlist;
          bagsList.push(payload);
          dispatch({ type: 'mealAdtlist', payload: bagsList })
        }else{
          dispatch({ type: 'mealAdtlist', payload: state.mealAdtlist })
        }
      }
     }else if(props.selMealprice[1]==="CHD"){
      const payload={
        "no":props.selMealprice[2],
        "flow":props.selMealprice[3],
        "price":props.selMealprice[0].price,
        "route":props.selMealprice[4]
      }
      if(state.mealChdlist.length===0){
        let list=[];
        list.push(payload);
        dispatch({ type: 'mealChdlist', payload: list })
      }else{
        let match=true;
        for(let val of state.mealChdlist){
          if(val.no===props.selMealprice[2] && val.flow===props.selMealprice[3] && val.route===props.selMealprice[4]){
             val.price=props.selMealprice[0].price;
             match=false;
          }
        }
        if(match){
          let bagsList=[];
          bagsList=state.mealChdlist;
          bagsList.push(payload);
          dispatch({ type: 'mealChdlist', payload: bagsList })
        }else{
          dispatch({ type: 'mealChdlist', payload: state.mealChdlist })
        }
      }
     }else if(props.selMealprice[1]==="INF"){
      dispatch({ type: 'mealInf', payload:state.mealInf + Number(props.selMealprice[0].price) })
     }
    }
  },[props.selMealprice])

  const calAdtBagPrice = () => {
    let totalPrice=0;
       if(state.adtBagList.length!==0){
         for(let val of state.adtBagList){
           if(totalPrice===0){
            totalPrice=Number(val.price);
           }else{
            totalPrice=Number(totalPrice+val.price);
           }
          }
          props.sendAdtPrice(totalPrice);
       }
    return totalPrice;
}

const calChdBagPrice = () => {
    let totalPrice=0;
    if(state.chdBagList.length!==0){
      for(let val of state.chdBagList){
        if(totalPrice===0){
         totalPrice=Number(val.price);
        }else{
         totalPrice=Number(totalPrice+val.price);
        }
       }
       props.sendChdPrice(totalPrice);
    }

   return totalPrice;
}

const calAdtMealPrice = () => {
  let totalPrice=0;
     if(state.mealAdtlist.length!==0){
       for(let val of state.mealAdtlist){
         if(totalPrice===0){
          totalPrice=Number(val.price);
         }else{
          totalPrice=Number(totalPrice+val.price);
         }
        }
        props.sendMealAdtPrice(totalPrice);
     }
  return totalPrice;
}

const calChdMealPrice = () => {
  let totalPrice=0;
     if(state.mealChdlist.length!==0){
       for(let val of state.mealChdlist){
         if(totalPrice===0){
          totalPrice=Number(val.price);
         }else{
          totalPrice=Number(totalPrice+val.price);
         }
        }
        props.sendMealChdPrice(totalPrice);
     }
  return totalPrice;
}

useEffect(()=>{
  if(props.otCharge!="" && props.otCharge!==undefined){
    if(request.sty==='normal'){
      dispatch({ type: 'otCharge', payload:Number(props.otCharge)})
    }else{
      if(props.otCharge!==undefined && props.onwardSupp!=="0" && props.returnSupp!=="0"){
       if(props.otCharge.includes("/")){
         let OtAry=props.otCharge.split("/");
         let total=Number(OtAry[0])+Number(OtAry[1]);
         dispatch({ type: 'otCharge', payload:Number(total)})
       }
      }else{
       dispatch({ type: 'otCharge', payload:Number(props.otCharge)})
      }
   }
   }
 },[props.otCharge])



  //Adult panel enable
  const openadultPrice = () => {
    if (adultPrice) {
      setAdultPrice(false)
    } else {
      setAdultPrice(true)
    }
  }
  //Child panel enable
  const openchildPrice = () => {
    if (childPrice) {
      setCildPrice(false)
    } else {
      setCildPrice(true)
    }
  }
  //Infant panel enable
  const openinfantPrice = () => {
    if (infantPrice) {
      setInfantPrice(false)
    } else {
      setInfantPrice(true)
    }
  }

  const totalPrice = (data) => {
    if(data && (state.gt === 0 || data !== state.gt)) {
      dispatch({type: "gt",payload: data})
      props.grandTotal(data)
    }
  }
const getTotalFare=(paxprList,total,serviceFee)=>{
  let totalFare=0;
  let totalValue=0;
  let adtVal=0;
  let chdVal=0;
  let infVal=0;
  for(let pax of paxprList){
  if(pax.ptype==="ADT" && pax.resdval!==0){
      adtVal=pax.resdval * request.adt;
    }else if(pax.ptype==="CHD"){
      chdVal=pax.resdval * request.chd;
    }else if(pax.ptype==="INF"){
      infVal=pax.resdval * request.inf;
    }
  }
  totalFare=adtVal+chdVal+infVal;
  totalValue=Utilities.addPrice(total + serviceFee, totalFare, "Sub");
  return totalValue;
}
  return (
    <>
      {price.prList !== "" && price.prList !== undefined && price.prList.paxpr.length!==0 && (props.srequest.flowType !== undefined && props.srequest.flowType !== "REISSUE" || props.flowtype === "import") &&
        <div className="costBox">
          {price.prList.paxpr.map((price, idx) => (
            <>
              {price.ptype === "ADT" &&
                <>
                  {searchType === "normal" ? (
                    <div className="costList">
                      <Link to='#' onClick={openadultPrice} className={"openPrice" + (adultPrice ? ' active' : '')}><span className="d-flex">Adult {request.adt} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                      <div className="pricetag"><em>{props.cur}</em> <strong> {props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(Utilities.addPrice(price.tf+Number(paxMkp)+Number(adtTds), request.adt, "Cnt"),gst_val,"Add" )} />:<FormateCurrency value={Utilities.addPrice(Utilities.addPrice(price.tf+Number(paxMkp)+Number(adtTds)-Number(adtPlb), request.adt, "Cnt"),gst_val,"Add" )} />}</strong></div></Link>
                      {adultPrice && props.regionType!=="India" && <div className="dropContent">
                        <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.bf+Number(paxMkp), request.adt, "Cnt")} />} </strong></div></div>
                        <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(Utilities.addPrice(Utilities.taxCalculation(price.tax)+Number(adtTds), request.adt, "Cnt"), gst_val, "Add")} />}  </strong></div></div>
                       {Utilities.dicountCalculation(price.tax)!=="0.00" &&
                        <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.adt, "Cnt")} />}</strong></div></div>
                       }
                      </div>}
                    </div>
                  ) : (
                      <>
                        <div className="costList">
                          <Link to='#' onClick={openadultPrice} className={"openPrice" + (adultPrice ? ' active' : '')}><span className="d-flex">Adult {request.adt} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                          <div className="pricetag"><em>{props.cur}</em> <strong>{props.regionType!=="India"? <FormateCurrency value={Utilities.addPrice(Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[0].tf + Number(adtTds), request.adt, "Cnt"),gst_val,"Add")} />: <FormateCurrency value={Utilities.addPrice(Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[0].tf + Number(adtTds) - Number(adtPlb), request.adt, "Cnt"),gst_val,"Add")} />}</strong></div></Link>
                          {adultPrice  && props.regionType!=="India" && <><div className="dropContent">
                            <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp) + destPrice.destPriceObj.paxpr[0].bf, request.adt, "Cnt")} />}  </strong></div></div>
                            <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(Utilities.addPrice(Utilities.taxCalculation(price.tax) + Utilities.taxCalculation(destPrice.destPriceObj.paxpr[0].tax) + Number(adtTds), request.adt, "Cnt"), gst_val,"Add")} />} </strong></div></div>
                            <div className="pList pb-2 discount"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={(parseFloat(Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.adt, "Cnt")) + parseFloat(Utilities.addPrice(Utilities.dicountCalculation(destPrice.destPriceObj.paxpr[0].tax), request.adt, "Cnt"))).toFixed(2)} />}  </strong></div></div>
                          </div></>}
                        </div>
                      </>)
                  }
                </>
              }
              {price !== undefined && price.ptype === "CHD" &&
                <>
                  {searchType === "normal" ? (
                    <div className="costList">
                      <Link to='#' onClick={openchildPrice} className={"openPrice" + (childPrice ? ' active' : '')}><span className="d-flex">Child {request.chd} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                      <div className="pricetag"><em>{props.cur}</em> <strong>{props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp)+Number(chdTds), request.chd, "Cnt")} />:<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp)+Number(chdTds)-Number(chdPlb), request.chd, "Cnt")} />} </strong></div></Link>
                      {childPrice && props.regionType!=="India" && <><div className="dropContent">
                        <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp), request.chd, "Cnt")} />} </strong></div></div>
                        <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(Utilities.taxCalculation(price.tax)+Number(chdTds), request.chd, "Cnt")} />} </strong></div></div>

                        {Utilities.dicountCalculation(price.tax)!=="0.00" &&
                         <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.chd, "Cnt")} />} </strong></div></div>
                         }

                      </div></>}
                    </div>
                  ) : (
                      <>
                        <div className="costList">
                          <Link to='#' onClick={openchildPrice} className={"openPrice" + (childPrice ? ' active' : '')}><span className="d-flex">Child {request.chd} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                          <div className="pricetag"><em>{props.cur}</em> <strong>{props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[1].tf+Number(chdTds), request.chd, "Cnt")} />:<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[1].tf+Number(chdTds)-Number(chdPlb), request.chd, "Cnt")} />} </strong></div></Link>
                          {childPrice &&  props.regionType!=="India" && <><div className="dropContent">
                            <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp) + destPrice.destPriceObj.paxpr[1].bf, request.chd, "Cnt")} />} </strong></div></div>
                            <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(Utilities.taxCalculation(price.tax) + Utilities.taxCalculation(destPrice.destPriceObj.paxpr[1].tax)+Number(chdTds), request.chd, "Cnt")} />} </strong></div></div>
                           <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={(parseFloat(Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.chd, "Cnt")) + parseFloat(Utilities.addPrice(Utilities.dicountCalculation(destPrice.destPriceObj.paxpr[1].tax), request.chd, "Cnt"))).toFixed(2)} />} </strong></div></div>
                         </div> </>}
                        </div>
                      </>)
                  }
                </>
              }
              {price !== undefined && price.ptype === "INF" &&
                <>
                  {searchType === "normal" ? (
                    <div className="costList">
                      <Link to='#' onClick={openinfantPrice} className={"openPrice" + (infantPrice ? ' active' : '')}><span className="d-flex">Infant {request.inf} {props.regionType!=="India" &&<span className="ezyIcon icon-arrowDown"></span>}</span>
                      <div className="pricetag"><em>{props.cur}</em> <strong>{props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) +Number(infTds), request.inf, "Cnt")} />:<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) +Number(infTds)-Number(infPlb), request.inf, "Cnt")} />} </strong></div></Link>
                      {infantPrice && props.regionType!=="India" &&<><div className="dropContent">
                        <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp), request.inf, "Cnt")} />} </strong></div></div>
                        <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(Utilities.taxCalculation(price.tax)+Number(infTds), request.inf, "Cnt")} />}  </strong></div></div>
                        {Utilities.dicountCalculation(price.tax)!=="0.00" &&
                         <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.inf, "Cnt")} />} </strong></div></div>
                        }
                      </div></>}
                    </div>
                  ) : (
                      <>
                        <div className="costList">
                          {destPrice.destPriceObj.paxpr!==undefined && destPrice.destPriceObj.paxpr[2]!==undefined && destPrice.destPriceObj.paxpr[2].tf !==undefined? (
                            <div className="costList">
                            <Link to='#' onClick={openinfantPrice} className={"openPrice" + (infantPrice ? ' active' : '')}><span className="d-flex">Infant {request.inf} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                            <div className="pricetag"><em>{props.cur}</em> <strong> {props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[2].tf+Number(infTds), request.inf, "Cnt")} />:<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + destPrice.destPriceObj.paxpr[2].tf+Number(infTds)-Number(infPlb), request.inf, "Cnt")} />} </strong></div></Link>
                             {infantPrice && props.regionType!=="India" && <><div className="dropContent">
                             <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp) + destPrice.destPriceObj.paxpr[2].bf, request.inf, "Cnt")} />} </strong></div></div>
                             <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(Utilities.taxCalculation(price.tax) + Utilities.taxCalculation(destPrice.destPriceObj.paxpr[2].tax)+Number(infTds), request.inf, "Cnt")} />} </strong></div></div>
                             <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={(parseFloat(Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.inf, "Cnt")) + parseFloat(Utilities.addPrice(Utilities.dicountCalculation(destPrice.destPriceObj.paxpr[2].tax), request.inf, "Cnt"))).toFixed(2)} />}</strong></div></div>
                            </div></>}
                          </div>
                           ):(
                            <div className="costList">
                            <Link to='#' onClick={openinfantPrice} className={"openPrice" + (infantPrice ? ' active' : '')}><span className="d-flex">Infant {request.inf} {props.regionType!=="India" && <span className="ezyIcon icon-arrowDown"></span>}</span>
                            <div className="pricetag"><em>{props.cur}</em> <strong>{props.regionType!=="India"?<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + Number(infTds) + destPrice.destPriceObj.paxpr[1].tf, request.inf, "Cnt")} />:<FormateCurrency value={Utilities.addPrice(price.tf + Number(paxMkp) + Number(infTds) + destPrice.destPriceObj.paxpr[1].tf-Number(infPlb), request.inf, "Cnt")} />} </strong></div></Link>
                             {infantPrice && props.regionType!=="India" && <><div className="dropContent">
                             <div className="pList pb-2"><span>Fare</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bf + Number(paxMkp) + destPrice.destPriceObj.paxpr[1].bf, request.inf, "Cnt")} />} </strong></div></div>
                             <div className="pList pb-2"><span>Taxes & Fees</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(Utilities.taxCalculation(price.tax) + Utilities.taxCalculation(destPrice.destPriceObj.paxpr[1].tax) + Number(infTds), request.inf, "Cnt")} />}  </strong></div></div>
                             <div className="pList pb-2"><span>Discount(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={(parseFloat(Utilities.addPrice(Utilities.dicountCalculation(price.tax), request.inf, "Cnt")) + parseFloat(Utilities.addPrice(Utilities.dicountCalculation(destPrice.destPriceObj.paxpr[1].tax), request.inf, "Cnt"))).toFixed(2)} />} </strong></div></div>
                            </div></>}
                          </div>
                          )
                           }
                      </div>
                      </>
                  )
                  }
                </>
              }
            </>
          ))}
          <>
             {searchType === "normal" ? (
              <>
                {state.tsfee!==0 &&
                 <div className="costList srvc">
                  <div className="pList"><span>Service Fee </span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(state.tsfee, 0, "Add")} />} </strong></div></div>
                  </div>
                }
              </>
            ) : (
              <>

                   {state.tsfee!==0 &&
                   <div className="costList srvc">
                  <div className="pList"><span>Service Fee </span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(state.tsfee, 0, "Add")} />}</strong></div></div>
                  </div>
                   }
                </>
              )
            }
          </>
            {props.onwardSupp!=="" && props.flowtype !== "result" && props.flowtype !== "import" && props.srequest.flowType == "DIRECT" &&  (props.onwardSupp!=="0" || props.returnSupp!=="0") && (calAdtBagPrice() !== 0 || calAdtMealPrice() !== 0 || props.seatPrice !== 0)  &&
               <div className="costList srvc">
                 <div className="pList mt-1"><span className="d-flex">Extras
                 <span className="toolTipInfo pricebrk ml-1"><span className="ezyIcon icon-info"></span>
                  <div className="toolTipCUstoom extrafare">
                      <div className="toolTipCont">
                        {/* <div className="list"><span>Base Fare</span> <span>QAR 450.00</span></div> */}
                      <div className="list"><span>Baggage Price </span> <span>{Utilities.addPrice(calAdtBagPrice() + calChdBagPrice(), 0, "Add")}</span></div>
                      <div className="list"><span>Meal Price </span> <span>{Utilities.addPrice(calAdtMealPrice() + calChdMealPrice(), 0, "Add")}</span></div>
                      <div className="list"><span>{props.seatPrice!==0?"Seat Price":""} </span> <span>{props.seatPrice!==0?props.seatPrice:""}</span></div>

                      </div>
                  </div>
                  </span>
                 </span>
                 <div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice, 0, "Add")} />} </strong></div>
                 </div>
                </div>
                }
                {state.otCharge!==0 &&
                  <div className="costList">
                    <div className="pList"><span>Other Charge</span><div className="pricetag"><em>{props.cur} </em><strong>{<FormateCurrency value={state.otCharge} />}</strong></div>
                  </div></div>
                }
          {props.regionType!=="India" && props.userType==="att_user" &&  state.discount !== 0 && state.discount !== "0.00" && state.discount !== "NaN" &&
            <div className="costList">
              <div className="pList"><span>PLB Discount(-)</span><div className="pricetag"><em>{props.cur} </em><strong>{<FormateCurrency value={state.discount} />}</strong></div>
            </div></div>
          }

          {/* {totalTds}
          <br/>
          {gst_val} */}
          {props.flowtype === "result" ? (
            <>
              {searchType === "normal" ? (
                <div className="costList total">
                  <div className="pList"><span>Total</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.prList.pr.tf + Number(onflyMkp) + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice  + state.otCharge + gst_val + Number(totalTds), state.tsfee, "Add")} />} </strong></div></div>
                  <p>Taxes and Fees Included</p>
                </div>
              ) : (
                  <div className="costList total">
                    <div className="pList"><span>Total</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.prList.pr.tf + Number(onflyMkp) + state.tsfee + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice + state.otCharge + gst_val + Number(totalTds), destPrice.destPriceObj.pr.tf, "Add")} />} </strong></div></div>
                    <p>Taxes and Fees Included</p>
                  </div>
                )
              }
            </>
          ) : (
              <>
               {searchType === "normal" ? (
                  <div className="costList total">
                    <div className="pList"><span>Grand Total</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.prList.pr.tf + Number(onflyMkp) + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice  + state.otCharge + gst_val + Number(totalTds) - state.discount, state.tsfee, "Add")} /> } {totalPrice(Utilities.addPrice(price.prList.pr.tf + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice  + gst_val + Number(totalTds) + state.otCharge - state.discount, state.tsfee, "Add"))}</strong></div></div>
                    <p>Taxes and Fees Included</p>

                  </div>
                ) : (
                    <div className="costList total">
                    <div className="pList"><span>Grand Total</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.prList.pr.tf + Number(onflyMkp) + destPrice.destPriceObj.pr.tf + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice()+ props.seatPrice + state.otCharge + gst_val + Number(totalTds) - state.discount, state.tsfee, "Add")} />}  {totalPrice(Utilities.addPrice(price.prList.pr.tf + destPrice.destPriceObj.pr.tf + calAdtBagPrice() + calChdBagPrice() + calAdtMealPrice() + calChdMealPrice() + props.seatPrice + gst_val + state.otCharge - state.discount, state.tsfee, "Add"))}</strong></div></div>
                      <p>Taxes and Fees Included</p>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      }

      {price.prList !== "" && props.srequest.flowType !== undefined && props.srequest.flowType === "REISSUE" &&
        <div className="costBox">
          {price.prList.paxpr.map((price, idx) => (
            <>
              {price.ptype === "ADT" &&
                <>
                  <div className="costList">
                    <Link to='#' onClick={openadultPrice} className={"openPrice" + (adultPrice ? ' active' : '')}><span className="d-flex">Adult {request.adt}<span className="ezyIcon icon-arrowDown"></span></span>
                    <div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.tf - price.resdval, request.adt, "Cnt")} />}</strong></div></Link>
                    {adultPrice && <div className="dropContent">
                      <div className="pList"><span>Base Fare Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bfd, request.adt, "Cnt")} />} </strong></div></div>
                      <div className="pList"><span>Tax Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.tfd, request.adt, "Cnt")} />} </strong></div></div>
                      <div className="pList"><span>Penalty</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.penlty, request.adt, "Cnt")} />}</strong></div></div>
                      <div className="pList"><span>Residual Value(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.resdval, request.adt, "Cnt")} />}</strong></div></div>
                    </div>}
                  </div>
                </>
              }
              {price !== undefined && price.ptype === "CHD" &&
                <>
                  <div className="costList">
                    <Link to='#' onClick={openchildPrice} className={"openPrice" + (childPrice ? ' active' : '')}><span className="d-flex">Child {request.chd} <span className="ezyIcon icon-arrowDown"></span></span>
                    <div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.tf - price.resdval, request.chd, "Cnt")} />}</strong></div></Link>
                    {childPrice && <><div className="dropContent">
                      <div className="pList"><span>Base Fare Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bfd, request.chd, "Cnt")} />} </strong></div></div>
                      <div className="pList"><span>Tax Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.tfd, request.chd, "Cnt")} />}</strong></div></div>
                      <div className="pList"><span>Penalty</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.penlty, request.chd, "Cnt")} />}</strong></div></div>
                      <div className="pList"><span>Residual Value(-)</span><div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.resdval, request.chd, "Cnt")} />} </strong></div></div>
                   </div> </>}
                  </div>
                </>
              }
              {price !== undefined && price.ptype === "INF" &&
                <>
                  <div className="costList">
                    <Link to='#' onClick={openinfantPrice} className={"openPrice" + (infantPrice ? ' active' : '')}><span className="d-flex">Infant {request.inf}<span className="ezyIcon icon-arrowDown"></span></span>
                    <div className="pricetag"><em>{props.cur}</em> <strong>{<FormateCurrency value={Utilities.addPrice(price.tf - price.resdval, request.inf, "Cnt")} />}</strong></div></Link>
                    {infantPrice && <><div className="dropContent">
                      <div className="pList"><span>Base Fare Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.bfd, request.inf, "Cnt")} />}</strong></div></div>
                      <div className="pList"><span>Tax Difference</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.tfd, request.inf, "Cnt")} />} </strong></div></div>
                      <div className="pList"><span>Penalty</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.penlty, request.inf, "Cnt")} />} </strong></div></div>
                      <div className="pList"><span>Residual Value(-)</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(price.resdval, request.inf, "Cnt")} />} </strong></div></div>
                   </div> </>}
                  </div>
                </>
              }
            </>
          ))}
          <>
            <div className="costList srvc">
            <div className="pList"><span>Grand Total with diff with Penalty</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={getTotalFare(price.prList.paxpr,price.prList.pr.gtd,0)} />   }</strong></div></div>
            <div className="pList"><span>Service Fee </span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={Utilities.addPrice(state.tsfee, 0, "Add")} />}</strong></div></div>
            </div>
            {price.prList.pr.resdval===undefined || price.prList.pr.resdval===0 &&
            <div className="costList total">
            <div className="pList"><span>Total Collection</span><div className="pricetag"><em>{props.cur}</em> <strong> {<FormateCurrency value={getTotalFare(price.prList.paxpr,price.prList.pr.gtd,state.tsfee)} />}</strong></div></div>
              <p>Taxes and Fees Included</p>
            </div>
            }
          </>
        </div>
      }
    </>

  )
}
export default TripCost