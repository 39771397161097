
import React, { useEffect, useReducer, useState ,useContext} from 'react';
import { Alert, Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import DateUtils from '../../components/commonUtils/DateUtils';
import Button from '../common/buttons/Button';
import { Context } from "../../../App";
import Footer from '../common/Footer';
import Header from '../common/Header';
import ListOfBooking from './ListOfBooking';
import { fetchcompany, getBookingUsingCriteria, getBranchList, getOfficeIdList } from './operations';
import { mobileNumberValidation, emailValidation } from "../commonUtils/FormValidations"
import { useHistory } from 'react-router-dom';
import AttCommonSerivce from '../../services/commonService/AttCommonSerivce';

const initialState = {
  pnr: '',
  product: 'Flight',
  bookingtype:"",
  contact_no: null,
  branchName: [],
  stat: '',
  tno: null,
  rfno: '',
  suppliers: [],
  bfrom: '',
  bto: '',
  tfrom: '',
  tto: '',
  cname: '',
  agencyname: '',
  email: '',
  fn: '',
  oid: null,
  ofcId: '',
  getsCompList: [],
  compId: '',
  emailError: false,
  contactError: false,
  loader: false,
  noRecords: false,
  notiMessage: "", notiMessageShow: false, notiVarient: "",
  prod: [],
  btype:[],
  insurance_trvl_type:'',
  mybookings:false,
  visa_type:[]
};

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function BookingList(props) {
  const history = useHistory();

  console.log('propssss', props)
  const [context, setContext] = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  //const [state, setState] = useState({})
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [cfromDate, setcFromDate] = useState();
  const [ctoDate, setcToDate] = useState();
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [getsBranchList, setgetBranchList] = useState([])
  const [officeData, setOfficeData] = useState([])
  const [showProduct, setShowProduct] = useState(true);
  const [visaTypes, setVisaTypes] = useState([{value:"Standard Visa",label:"Standard Visa"},{value:"Express Visa",label:"Express Visa"}]);
  const userName = [{ label: "Raghava", value: 'Raghava' },
  { label: "Anil", value: 'Anil' },
  { label: "Mubeen", value: "Mubeen" }]
  const branchName = [{ label: "India", value: 'India' },
  { label: "QTR", value: 'QTR' },
  { label: "OMR", value: "OMR" }]

  const   flightbookingStatus =
  [{ label: "Confirmed", value: '23' },
  { label: "Incomplete", value: '21' },
  { label: "Cancelled", value: "28" },
  { label: "Booking Failed", value: "25" },
  { label: "Ticketing Failed", value: "34" }, { label: "On Hold", value: "22" },
  { label: "Partially Ticketed", value: "36" }, { label: "Discarded", value: "35" },
  { label: "Voided", value: "31" },
  { label: "Void/Cancel", value: "39" }, { label: "Partially Confirmed", value: "41" },
  { label: "Offline", value: "47" }
  ]
  const hotelbookingStatus =
  [{ label: "Confirmed", value: '23' },
  { label: "Failed", value: "25" },
  { label: "Cancelled", value: "28" },
  { label: "On Hold", value: "22" },
  { label: "Partially Confirmed", value: "41" }
  ]

  const insuranceStatus = [
    {label: "Confirmed", value: '23'},
    { label: "Failed", value: "25" }
  ]
  const [supp, setSuppliers] = useState()
  const [res, setResponse] = useState([])
  const supplier = [{ label: "Amadeus", value: "0" }, { label: "Travel Fusion", value: "1" }, { label: "Air Arabia", value: "2" }, { label: "Jazeera Airways", value: "3" }, { label: "IndiGo Airlines", value: "4" }, { label: "Flydubai", value: "5" },{ label: "Salam Air", value: "6" }]
  const hotel_supplier = [{ label: "Expedia", value: '2' }, { label: "Otilla", value: '1' }]
  const insurance_supplier = [{label:"QIC",value:'1'}]
  const ezyproducts = [{ label: "Flight", value: 'Flight' }, { label: "Hotel", value: "Hotel" },{label: "Insurance", value: 'Insurance'},{label: "Visa", value: 'Visa'}]
  //  var BookingType = [{ label: "Direct", value: 'direct' }, { label: "Import PNR", value: "import" },{label: "Manual", value: 'manual'}]

   var BookingType
  //  = [{ label: "Direct", value: 'direct' }, { label: "Import PNR", value: "import" },{label: "Manual", value: 'manual'}]
   if(state.product === 'Flight'){
    BookingType = [{ label: "Direct", value: 'direct' }, { label: "Import PNR", value: "import" },{label: "Manual", value: 'manual'}]
  }else if(state.product === 'Hotel'){
    BookingType = [{ label: "Direct", value: 'direct' },{label: "Manual", value: 'manual'}]
  }else if(state.product === 'Visa'){
    BookingType = [{ label: "Direct", value: 'direct' }]
  }else(
    BookingType = [{ label: "Direct", value: 'direct' }]
  )
   const insurance_trvl_type =[{label: "Inbound", value: '1' }, { label: "OutBound", value: "0" }]
  const advanceSearchOpen = () => {
    if (advanceSearch) {
      setAdvanceSearch(false)
    } else {
      setAdvanceSearch(true)
    }
  }

  useEffect(() => {
   if(props.location!==undefined && props.location.search.includes("?booking_id")){
    setShowProduct(false);
   }
  }, [props.location])
  


  /**
   * @description : fetch branches for the respective user
   */
  useEffect(() => {
    let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
    if (localstorage) {
      getOfficeIdList().then((result) => {
        console.log('resultssss', result)
        if (result.data.suc && result.data.res.length > 0) {
          setOfficeData(result.data.res)
        }
      })
      if (localstorage.utype !== "ta_user") {
        if(context.logindata.is_admin){
          AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type}).then(response => {
            console.log('Brnaches List of Region : ', response)
            if (response.data.suc) {
              let brnchArr = []
              for (let val of response.data.res) {
                let obj = {
                  "value": val.value,
                  "label": val.label
                }
                brnchArr.push(obj)
              }
              setgetBranchList(brnchArr)
            }
          })
        }else{
          AttCommonSerivce.loadBranches({"region_type": context.logindata.region_type,"uid":context.logindata.uid}).then(response => {
            console.log('Brnaches List of Region : ', response)
            if (response.data.suc) {
              let brnchArr = []
              for (let val of response.data.res) {
                let obj = {
                  "value": val.value,
                  "label": val.label
                }
                brnchArr.push(obj)
              }
              setgetBranchList(brnchArr)
            }
          })
        }
      } else {
        let branchesList = []
        let obj = { "value": localstorage.bid, "label": localstorage.bnm }
        branchesList.push(obj)
        setgetBranchList(branchesList)
      }
    }
  }, [localStorage]);
  let emailvalidations = false;
  let mobilenumbervalidations = false;
  const handleReset = (e) => {
    console.log("calledadasdasdasd")
    history.go(0)
    // return history.push("/ezytrip/dashboard/bookingList");

  }
  const getonClkResponse = () => {
    setResponse("")
    dispatch({ type: "loader", payload: true })
    // contact Number validations
    if (state.contact_no) {
      if (mobileNumberValidation(state.contact_no)) {
        mobilenumbervalidations = true;
        dispatch({ type: "contactError", payload: false })
      } else {
        dispatch({ type: "contactError", payload: true })
      }
    }
    //email validations
    if (state.email) {
      if (emailValidation(state.email)) {
        emailvalidations = true
        dispatch({ type: "emailError", payload: false })
      } else {
        dispatch({ type: "emailError", payload: true })
      }
    }
    let localstorage = JSON.parse(localStorage.getItem('userAuthData'))
    console.log('state for adv search', state)
    if (state.contact_no || state.compId || state.stat || state.tno || state.rfno || fromDate || toDate || state.cname || state.agencyname ||
      state.email || state.fn || state.pnr || emailvalidations || mobilenumbervalidations
      || state.branchName ||
      state.suppliers
    ) {
      let rq_json = {
        "region_type":context.logindata.region_type,
        contact_no: state.contact_no,
        branchName: state.branchName && state.branchName.length > 0 ? state.branchName[0].value : '',
        compId: state.compId,
        status: state.stat && state.stat.length > 0 ? state.stat[0].value : '',
        tno: state.tno,
        rfno: state.rfno,
        suppliers: state.suppliers && state.suppliers.length > 0 ? state.suppliers[0].value : '',
        bfrom: fromDate ? DateUtils.prettyDate(fromDate, 'YYYY-MM-DD') : '',
        bto: toDate ? DateUtils.prettyDate(toDate, 'YYYY-MM-DD') : '',
        cfrom: cfromDate ? DateUtils.prettyDate(cfromDate, 'YYYY-MM-DD') : '',
        cto: ctoDate ? DateUtils.prettyDate(ctoDate, 'YYYY-MM-DD') : '',
        cname: state.cname,
        agencyname: state.agencyname,
        email: state.email,
        fn: state.fn,
        oid: state.ofcId,
        pnr: state.pnr,
        cid: localstorage.cid,
        uid: state.mybookings === true?localstorage.uid:null,
        utype: localstorage.utype,
        is_admin: localstorage.is_admin,
        product: state.product,
        insurance_trvl_type:state.insurance_trvl_type,
        bktype:state.bookingtype,
        mybookings:state.mybookings,
        visa_type:state.visa_type&& state.visa_type[0]?.value
      }
      console.log('request', rq_json)
      getBookingUsingCriteria(rq_json).then(response => {
        console.log("response", response)
        if (response && response.data!==undefined && response.data.length !== 0) {
          if (localstorage.utype == "ta_user") {
            if (localstorage.cnm == response.data[0].cname) {
              setResponse(response.data)
            }
            else {
              setResponse("")
            }
          }
          else{
            setResponse(response.data)
          }
        }
        dispatch({ type: "loader", payload: false })
      })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Atleast One InputField to Search' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      dispatch({
        type: "emailError", payload: false
      })
      dispatch({
        type: "contactError", payload: false
      })
    }
  }
  //set supplier value
  const gdsInvEvent = (e) => {
    console.log('hii supp', e)
    dispatch({ type: 'suppliers', payload: e })
    // setState.suppliers=JSON.stringify(e)
    console.log('supp', state)
  }
  //setBooking Status
  const setStatus = (e) => {
    console.log('stat', e)
    dispatch({ type: 'stat', payload: e })
  }
  const handleCheckboxChange = (e) => {
    if( e.target.checked){
      dispatch({ type: 'mybookings', payload: true })
    }else{
      dispatch({ type: 'mybookings', payload: false })
    }
  }
  const validation = (e) => {
    console.log('hi validations', e)
  }
  console.log("czzzzzzzzzzzzzzzzzzzzzzzzzzz",context.logindata)
  const branchChange = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: 'branchName', payload: e })
      fetchcompany(e[0].value).then(company => {

        if (company.data.suc) {
          console.log('company data', company)
          let arr = []
          for (let val of company.data.res) {
            let obj = {
              "label": val.label,
              "value": val.value
            }
            arr.push(obj)
          }
          dispatch({ type: 'getsCompList', payload: arr })
        }
      })
    }
  }
  const companyChange = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: 'compId', payload: e[0].value })
    }
  }
  console.log("czzzzzzzzzzzzzzzzzzzzzzzzzzz",context.logindata)


  /**
   * @description : the product selected or change function
   * @author  : Azamuddin
   * @date : 28-06-2021
   * @param {value} e
   */
  const productSelected = (e) => {
    console.log("zzzzzzzzzzzzzzzzzzz",e[0].value)
    dispatch({ type: "product", payload: e[0].value })
    dispatch({ type: "prod", payload: e })
    dispatch({ type: "suppliers", payload: [] })
  }
  const btypeSelected = (e) =>{
    dispatch({ type: "bookingtype", payload: e[0].value })
    dispatch({ type: "btype", payload: e })
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value })
  }
  const ofcIdChange = (e) => {
    if (e && e.length > 0) {
      dispatch({ type: 'ofcId', payload: e[0].value })
    }
  }
  const insurance_trvl_type_func = (e) => {
      if(e && e.length > 0) {
        dispatch({type:"insurance_trvl_type",payload:e[0].value})
      }
  }
  return (
    <>
      <Header />
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <div className="container dashBordLayout pt-3">
      {showProduct &&
        <Row>
          <Col className="bookingSearch">
            <div className="cardMainlayout pb-0">
              <h5 className="pageTitle d-flex justify-content-between justify-items-center">Search Booking
                <span onClick={advanceSearchOpen} className={"plusAdv ezyIcon icon-"+ (advanceSearch ? 'minus' : 'plus')}> Advance Search </span>
              </h5>
              <form>
                <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="rfno">
                    <Form.Control type="text" placeholder="User Name" value={state.rfno} autoComplete="off" onChange={handleChange} />
                    <Form.Label>Booking Reference</Form.Label>
                  </Form.Group>
                
                  <Form.Group as={Col} className="selectplugin" xs={3} controlId="product">
                    <Select
                      options={ezyproducts}
                      searchable={false}
                      values={state.prod}
                      onChange={(value) => (productSelected(value))}
                    />
                    <Form.Label>Product</Form.Label>
                  </Form.Group>
                  
                  {state.product!=="Visa" &&
                    <Form.Group as={Col} xs={3} controlId="tno">
                    <Form.Control type="text" value={state.tno} placeholder="Reporting To" onChange={handleChange} autoComplete="off" />
                    <Form.Label>{state.product == "" || state.product == "Flight" ? "Ticket Number" : state.product == "Hotel" ? "Hotel Confirmation Number" : "Policy Number"}</Form.Label>
                  </Form.Group>}
                  <Form.Group as={Col} className="selectplugin" xs={3} controlId="product">
                    <Select
                      options={BookingType}
                      searchable={false}
                      values={state.btype}
                      onChange={(value) => (btypeSelected(value))}
                    />
                   <Form.Label>Booking Type</Form.Label></Form.Group>
                  {state.product=="Visa" &&
                    <Form.Group as={Col} className="selectplugin" xs={3} controlId="visa_type">
                    <Select
                      options={visaTypes}
                      searchable={false}
                      values={state.visa_type}
                      onChange={(value) => (dispatch({type:"visa_type", payload:value}))}
                    />
                   <Form.Label>Visa Type</Form.Label></Form.Group>}
                  {/* <Form.Group as={Col} xs={2} controlId="lastNmae">
                  <Form.Control type="text" placeholder="Last Name" autoComplete="off"/>
                  <Form.Label>Agency Name</Form.Label>
                </Form.Group> */}
                {(context.logindata.utype == "att_user" || context.logindata.utype == "ta_user") && state.product !== "" && state.product == "Insurance" &&

                      <Form.Group as={Col} className="selectplugin" xs={3} controlId="supplier">
                        <Select
                          options={insurance_trvl_type}
                          searchable={false}
                          // value={state.suppliers}
                          onChange={(value) => (insurance_trvl_type_func(value))}
                        />
                        <Form.Label>Travel Type</Form.Label>
                      </Form.Group>
                    }
                </Form.Row>

                <div className={"advSerch " + (advanceSearch ? 'active' : 'inactive')}>

                  <Form.Row>
                  {state.product != "Insurance" &&
                    <Form.Group as={Col} xs={3} controlId="fn">
                      <Form.Control type="text" placeholder="User Role" onChange={handleChange} autoComplete="off" />
                      <Form.Label>Customer Name</Form.Label>
                    </Form.Group>
                  }
                    {(context.logindata.utype == "att_user" || context.logindata.utype == "ta_user") && state.product !== "" && state.product == "Flight" &&
                      <Form.Group as={Col} className="selectplugin" xs={3} controlId="supplier">
                        <Select
                          options={supplier}
                          searchable={false}
                          value={state.suppliers}
                          onChange={(value) => (gdsInvEvent(value))}
                        />
                        <Form.Label>Supplier Name</Form.Label>
                      </Form.Group>
                    }

                    {(context.logindata.utype == "att_user" || context.logindata.utype == "ta_user") && state.product !== "" && state.product == "Hotel" &&
                      <Form.Group as={Col} className="selectplugin" xs={3} controlId="supplier">
                        <Select
                          options={hotel_supplier}
                          searchable={false}
                          value={state.suppliers}
                          onChange={(value) => (gdsInvEvent(value))}
                        />
                        <Form.Label>Supplier Name</Form.Label>
                      </Form.Group>
                    }
                    {(context.logindata.utype == "att_user" || context.logindata.utype == "ta_user") && state.product !== "" && state.product == "Insurance" &&
                      <Form.Group as={Col} className="selectplugin" xs={3} controlId="supplier">
                        <Select
                          options={insurance_supplier}
                          searchable={false}
                          value={state.suppliers}
                          onChange={(value) => (gdsInvEvent(value))}
                        />
                        <Form.Label>Supplier Name</Form.Label>
                      </Form.Group>
                    }
                    <Form.Group as={Col} xs={3} controlId="contact_no">
                      <Form.Control type="text" value={state.contact_no} disabled={state.product == "Insurance"} placeholder="User Role" onChange={handleChange} autoComplete="off" />
                      <Form.Label>Contact Number</Form.Label>
                      {state.contactError && (
                        <small className="text-danger">Please Enter A Valid contact number</small>
                      )}
                    </Form.Group>
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                      <Select
                        options={getsBranchList}
                        searchable={true}
                        onChange={(value) => branchChange(value)}
                      />
                      <Form.Label>Branch Name</Form.Label>
                    </Form.Group>
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                      <Select
                        options={state.getsCompList}
                        searchable={true}
                        onChange={(value) => companyChange(value)}
                      />
                      <Form.Label>Agency Name</Form.Label>
                    </Form.Group>
                    {/* <Form.Group as={Col} className="selectplugin" xs={2} controlId="name">
                <Select
                      options={userName}
                      searchable={false}
                      onChange={(value)=>(console.log('supllier value'+value), gdsInvEvent(value))}
                    />
                  <Form.Label>User Name</Form.Label>
                </Form.Group> */}
                    <Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                      <Select
                        options={state.product == "Flight" ? flightbookingStatus : state.product == "Hotel" ? hotelbookingStatus : insuranceStatus}
                        searchable={true}
                        // value = {state.stat}
                        onChange={(value) => setStatus(value)}
                      />
                      <Form.Label>Booking Status</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} xs={3} controlId="email">
                      <Form.Control type="text" placeholder="Email" disabled={state.product == "Insurance"} value={state.email} onChange={handleChange} autoComplete="off" />
                      <Form.Label>Email</Form.Label>
                      {state.emailError && (
                        <small className="text-danger">Please Enter A Valid Email</small>
                      )}
                    </Form.Group>
                    {state.product === 'Flight'&&<Form.Group className="selectplugin" as={Col} xs={3} controlId="firstName">
                      <Select
                        options={officeData}
                        searchable={false}
                        value={state.oid}
                        onChange={(value) => ofcIdChange(value)}
                      />
                      <Form.Label>Office Id</Form.Label>
                    </Form.Group>}

                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} xs={6}>
                      <Form.Row>
                        <Form.Group as={Col} xs={6} className="selectplugin datePicker datepicleft mb-4" controlId="from">
                          <DatePicker
                            selected={fromDate}
                            onChange={date => setFromDate(date)}
                            fromDate={fromDate}
                            toDate={toDate}
                            maxDate={new Date()}
                            placeholderText="MM/DD/YYYY"
                            className="adcdt"
                          />
                           <span className="calendar ezyIcon icon-newcalendar"></span>
                          <Form.Label>Booking From</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={6} className="selectplugin datePicker datepicleft m-0" controlId="from">
                          <DatePicker
                            selected={toDate}
                            onChange={date => setToDate(date) + 1}
                            fromDate={fromDate}
                            toDate={toDate}
                            minDate={fromDate}
                            maxDate ={new Date()}
                            placeholderText="MM/DD/YYYY"
                            className="datefield adcdt"
                          />
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                          <Form.Label>Booking To</Form.Label>
                        </Form.Group>

                      </Form.Row>

                    </Form.Group>
                    <Form.Group as={Col} xs={6} >
                    My bookings{" "} <input type="checkbox" onChange={(e)=>handleCheckboxChange(e)}></input>
                    </Form.Group>
                    {state.product == "Hotel" &&
                      <>
                        <Form.Group as={Col} xs={3}>
                          <Form.Row>
                            <Form.Group as={Col} xs={6} className="selectplugin datePicker datepicleft m-0" controlId="from">
                              <DatePicker
                                selected={cfromDate}
                                onChange={date => setcFromDate(date)}
                                fromDate={cfromDate}
                                toDate={ctoDate}
                                placeholderText="MM/DD/YYYY"
                                className="adcdt"
                              />
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                              <Form.Label>Check-In Date</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={6} className="selectplugin datePicker datepicleft m-0" controlId="from">
                              <DatePicker
                                selected={ctoDate}
                                onChange={date => setcToDate(date) + 1}
                                fromDate={cfromDate}
                                toDate={ctoDate}
                                minDate={cfromDate}
                                placeholderText="MM/DD/YYYY"
                                className="datefield adcdt"
                              />
                           <span className="calendar ezyIcon icon-newcalendar"></span>
                              <Form.Label>Check-out Date</Form.Label>
                            </Form.Group>
                          </Form.Row>
                        </Form.Group>
                      </>
                    }
                  </Form.Row>
                </div>


              </form>

              <div class="buttonGrop text-right">
              <Button
              style={{"marginRight":"15px"}}
                size="xs"
                variant="outline-secondary mr-3"
                type="submit"
                onClick={handleReset}
              >
                {" "}
                Reset
              </Button>


                <Button
                size="xs"
                variant="primary m-0"
                type="submit"
                onClick={getonClkResponse}
              >Search</Button></div>


            </div>
          </Col>

        </Row>
        }
        <Row>
          <Col className="ListOfBooking">

            <ListOfBooking searchResponse={res} loader={state.loader} enableProduct={showProduct}/>

          </Col>

        </Row>



      </div>
      <Footer />
    </>
  )
}
export default BookingList