/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
 import React, { useState, useEffect } from 'react';
 import { Alert, Col, Form, Row } from "react-bootstrap";
 import DateUtils from "../../../commonUtils/DateUtils";
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";

 /**
  * @description: This function will show the Booking Details section
  * @date:18-09-2020
  * @author:Lakshmi
  */
 
 function ConfirmationBookingPdf(props) {
   const [state, setstate] = useState()
   const [bookid, setBookid] = useState({ selBooking: [] })
   const [compResp, setCompResp] = useState()

   return (
     <>
       <div className="flightbookinginfoPdf">
         <div className="flightheader">
           <Row>
             <Col xs={2}>
            
               <div className="img"> <img
               src={props.confirmationObj.logo_comp_url !== "" ? props.confirmationObj.logo_comp_url : logo }
                className="htlImg"
                /></div>
                {/* } */}
           
             </Col>
             <Col xs={10}>
             <Row>
               <Col xs={6}>
               <Col xs={12}><strong>{props.confirmationObj.cname}</strong></Col>
              <Col xs={12}>{props.confirmationObj.comp_add}</Col>
              <Col xs={12}>{props.confirmationObj.comp_city} , {props.confirmationObj.comp_cnt}</Col>
              <Col xs={12}><span>Contact Number: </span>{props.phone_num}</Col>
              <Col xs={12}><span>Email : </span>{props.emialId}</Col>
            </Col>
               <Col xs={6}>
                <Col xs={12}><strong>Billing To</strong></Col>
                <Col xs={12}><span>Agency Name: </span>{props.confirmationObj.cname}</Col>
                <Col xs={12}><strong>Corporate Office</strong> </Col>
                <Col xs={12}>{props.confirmationObj.comp_add}</Col>
                <Col xs={12}>{props.confirmationObj.comp_lmark}</Col>
                <Col xs={12}>{props.confirmationObj.comp_city} , {props.confirmationObj.comp_cnt}</Col>

              </Col>
           </Row>
 
             </Col>
           </Row>
         </div>
         <div className="flightDetails">
           <Row className="pb-2">
             <Col xs={4}><span>Booking Number :</span> {props.confirmationObj.bkd[0].bookId}
             </Col>
             <Col><span>Booking Status : </span>
             {props.confirmationObj.bkd[0].st}
             </Col>
             <Col xs={4}><span>Customer Name : </span>{props.confirmationObj.leadPsngr}</Col>
           </Row>
           <Row className="pb-2">
             <Col xs={4}><span>Company Name : </span>{props.confirmationObj.cname}</Col>
             <Col xs={4}><span>Booking Date : </span>{DateUtils.prettyDate(props.confirmationObj.bkd[0].bd, "ddd, DD MMM, YYYY")}</Col>
             {/* <Col xs={4}><span>Phone : </span>{props.bk_det[0].isd_code ? ("+" + props.bk_det[0].isd_code + " - ") : ""} {props.bk_det[0].comp_contact_num}</Col> */}
             {props.confirmationObj.sty !== "split" && (props.confirmationObj.sup !== undefined && props.confirmationObj.sup !== "0" ?  <Col xs={4}><span>Phone : </span> +{ props.confirmationObj.origns[0].srcorigns[0].psngrs[0].phc}-{props.confirmationObj.origns[0].srcorigns[0].psngrs[0].phn}</Col> : <Col xs={4}><span>Phone : </span> +{ props.confirmationObj.psngrs[0].phc}-{props.confirmationObj.psngrs[0].phn}</Col>)}
             {props.confirmationObj.sty == "split" && props.confirmationObj.flowType !== "REISSUE"&& (props.confirmationObj.origns[0].srcorigns[0].sup !== "0" || props.confirmationObj.origns[0].destorigins[0].sup !== "0" ?  <Col xs={4}><span>Phone : </span> +{ props.confirmationObj.origns[0].srcorigns[0].psngrs[0].phc}-{props.confirmationObj.origns[0].srcorigns[0].psngrs[0].phn}</Col> : <Col xs={4}><span>Phone : </span> +{ props.confirmationObj.psngrs[0].phc}-{props.confirmationObj.psngrs[0].phn}</Col> ) }
           </Row>
           <Row className="pb-1">
             <Col xs={4}><span>Email : </span> {props.confirmationObj.bkd[0].bby}</Col>
             <Col xs={4}><span>Booked By : </span> {props.confirmationObj.bkd[0].bby}</Col>
           </Row>
 
         </div>
       </div>
     </>
   )
 }
 
 
 export default ConfirmationBookingPdf;
 