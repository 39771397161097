import React, { useEffect, useState } from 'react';
import {Button, Col, Form, Row,} from 'react-bootstrap';
import Select from 'react-dropdown-select';
import { countries, stdCodes} from '../../adminstration/utils';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

const RegistrationForm = () => { 
    const [stdCods,setStdCods] = useState(stdCodes())
    const [countryList,setCountryList] = useState([])
    const [disable,setDisable] = useState(true)
    const [phone_code,setPhone_code] = useState([])
    const [country,setCountry] = useState([])
    const [state, setState] = useState({ name: "", email: "",mobile:"",agency_name:"",desc:"" })
    const [nameErr,setNameErr] = useState("")
    const [emailErr,setEmailErr] = useState("")
    const [codeErr,setCodeErr] = useState("")
    const [mobileErr,setMobileErr] = useState("")
    const [cntErr,setCntErr] = useState("")

   useEffect(() => {
    countries().then((response)=>{
        if (response) {
            setCountryList(response);
    }})
    },[]);

    const handleCountry =(e)=>{
        setCountry(e)
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        e.preventDefault();
        if (id=="name"){
            setState({...state, name: value})
            setNameErr("")
        }
        if (id=="email"){
            setState({...state, email: value})
            setEmailErr("")
        }
        if (id=="mobile"){
            setState({...state, mobile: value})
            setMobileErr("")
        }
        if (id=="agency_name"){
            setState({...state, agency_name: value})
        }
        if (id=="desc"){
            setState({...state, desc: value})
        }
    };

    const validateForm=()=>{
        setNameErr("")
        setEmailErr("")
        setCodeErr("")
        setMobileErr("")
        setCntErr("")
        if(state.name==""){
            setNameErr("Enter Name")
            return false;
        }else if(state.email==""){
            setEmailErr("Enter Email")
            return false;
        }else if(state.phone_code==""){
            setCodeErr("Enter Phone Code")
            return false;
        }else if(state.mobile==""){
            setMobileErr("Enter Phone Number")
            return false;
        }else if(state.country==""){
            setCntErr("Enter Country")
            return false;
        }
        return true;
    }

    const submitRegForm=()=>{
        if(validateForm()){
            const updatedState = {
                ...state, phone_code:phone_code[0].value, country:country[0].label
            }
        setDisable(false)
       AttCommonSerivce.submitRegForm(updatedState).then((response) => {
            if (response.data.suc){
              alert("Email Sent successfully...")
              setDisable(true)
              setState({ ...state, 
                name: "",
                email: "",
                mobile: "",
                agency_name: "",
                desc: ""
               })
               setCountry([])
               setPhone_code([])
              //Close Pop up
            }else{
              alert("Something went wrong Please try again later")
            }
        });
    }
    }
    return (
          <div >
            <Form.Row>
            <Form.Group as={Col} xs={6} controlId="name">
            <Form.Control type="text" className="username" onChange={handleChange} 
             placeholder="Enter Name" value={state.name}  autoComplete="off"  autoFocus >
              </Form.Control>
              <Form.Label>Name <sup>*</sup></Form.Label>
              {nameErr && 
              <Form.Text className="vError">
              {nameErr}
             </Form.Text>}
            </Form.Group>

            <Form.Group as={Col} xs={6} controlId="email">
            <Form.Control type="email" className="username" value={state.email} onChange={handleChange} 
             placeholder="Enter Email"  autoComplete="off"  autoFocus >
              </Form.Control>
              <Form.Label>Email <sup>*</sup></Form.Label>
              {emailErr &&
              <Form.Text className="vError">
              {emailErr}
             </Form.Text>}
            </Form.Group>
            </Form.Row>

            <Form.Row>
            <Form.Group 
            as={Col}
            xs={6}
            className={"selectplugin cCode" + (codeErr && "error")}
            controlId="phone_code"
            >
            <Select 
            options={stdCods}
            values={phone_code} 
            onChange={(e)=>(setPhone_code(e))}
            />
            <Form.Label>
                Phone code<sup>*</sup>
            </Form.Label>
            {codeErr && 
            <Form.Text className="vError">
              {codeErr}
             </Form.Text>}
            </Form.Group>
            <Form.Group as={Col} xs={6} controlId="mobile">
            <Form.Control type="text" className="username" value={state.mobile} onChange={handleChange} 
             placeholder="Enter Phone Number"  autoComplete="off"  autoFocus >
              </Form.Control>
            <Form.Label>Phone Number <sup>*</sup></Form.Label>
            {mobileErr && 
            <Form.Text className="vError">
              {mobileErr}
             </Form.Text>}
            </Form.Group>

            <Form.Group as={Col} xs={6} controlId="country" 
            className={"selectplugin cCode" + (cntErr && "error")}
            >
            <Select
            options={countryList}
            searchable={true}
            values={country}
            onChange={handleCountry}
            />
            <Form.Label>Country <sup>*</sup></Form.Label>
            {cntErr &&
            <Form.Text className="vError">
              {cntErr}
             </Form.Text>}
            </Form.Group>
            <Form.Group as={Col} xs={6} controlId="agency_name">
            <Form.Control type="text" className="username" onChange={handleChange} 
             placeholder="Agency Name" value={state.agency_name}  autoComplete="off"  autoFocus >
              </Form.Control>
              <Form.Label>Agency Name </Form.Label>
            </Form.Group>
            </Form.Row>

            <Form.Row>
         

            <Form.Group as={Col} xs={12} controlId="desc">
            <Form.Control type="textarea" className="username" value={state.desc} onChange={handleChange} 
             placeholder="Description"  autoComplete="off"  autoFocus >
              </Form.Control>
              <Form.Label>Description </Form.Label>
            </Form.Group>
            </Form.Row>

            {<Form.Row>
                <Form.Group as={Col} xs={12}>
                <Button disabled={!disable} type="submit" variant="primary" size="lg" block onClick={submitRegForm} >
          Submit
         </Button>
                </Form.Group>
            </Form.Row>}
                
        
         </div>
    )
}


export default RegistrationForm;


