import CompanyCreationService from '../../../../services/administration/CompanyCreationService';
/**
 * @description: CRUD operations for Branch
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */
//Loading company data
export const loadCompanies = async (id) => {
    let list = [];
    await CompanyCreationService.getCompanyData(id).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Comapany
export const saveCompany = async (obj) => {
    let list = [];
    await CompanyCreationService.saveCompanyDetails(obj).then(response => {
        list = response;
    });
    return Promise.resolve(list);
}

//Edit Comapany
export const editCompanyDetails = async (id) => {
    let list = [];
    await CompanyCreationService.editCompanyDetails(id).then(response => {
        list = response;
    });
    return Promise.resolve(list);
}

//Update Comapany
export const updateCompany = async (obj) => {
    let list = [];
    await CompanyCreationService.updateCompanyDetails(obj).then(response => {
        list = response;
    });
    return Promise.resolve(list);
}

//Update Comapany
export const searchCompany = async (obj) => {
    let list = [];
    await CompanyCreationService.companySearch(obj).then(response => {
        list = response;
    });
    return Promise.resolve(list);
}


