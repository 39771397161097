import axios from "axios";

const API_URL = process.env.REACT_APP_B2B_URL;
/**
 * @author: Ubedullah Mohammed
 * @description: Integration
 * @param {*} Service
 */

class TDSConfigurationService {
  // TDS Configuration calls
  async saveTdsInfo(data) {
    console.log("API_URL", API_URL);
    return await axios.post(`${API_URL}/att/common/tds_config/save`, data);
  }
  async updateTDS(data) {
    return await axios.put(`${API_URL}/att/common/tds_config/update/${data.id}`, data);
  }
  async getTdsInfo(obj) {
    return await axios.post(`${API_URL}/att/common/tds_config/retrieve`,obj);
  }
  async deleteTds(id) {
    return await axios.delete(`${API_URL}/att/common/tds_config/${id}`);
  }
}

export default new TDSConfigurationService();
