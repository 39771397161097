
import React, { useState , useEffect , useContext, useReducer } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { Alert, Button} from 'react-bootstrap';
import moment from 'moment';
import { Context } from "../../../../App";
import {getAllAlerts , getAlertView } from '../../dashBoard/operations';
import DateUtils from '../../commonUtils/DateUtils';
import AlertModal from './AlertModal'

/**
 * Initial State Declaration
 */
 const initialState = {
    notiMessage: '', notiMessageShow: false, notiVarient: '',
  }
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, categoryName: ''
        };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };
function DashBordAlert(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    // const [alertsList , setAlertsList] = useState([])
    const [context, setContext] = useContext(Context);
    const [showAlert, setShowAlert] = useState(false);
    const [ alertViewData, setAlertViewDate ] = useState("");
    const [ selEvntName, selEventName ] = useState("");

   //Getting all Flight ALERTS
    // useEffect(() => {
    //     if(context.logindata!==undefined){
    //         let is_admin=context.logindata.utype==="att_user"?1:0
    //         getAllAlerts({"userId":context.logindata.uid,"pcid":context.logindata.pcid,"utype":context.logindata.utype,"is_admin":is_admin}).then(response => {
    //         if(response.suc) {
    //         setAlertsList(response.res)
    //         props.sendAlertsSize(response.res.length)
    //         }
    //     })
    //     }
    //     },[context.logindata]);
        const openAlert = (obj) => {
            setShowAlert(false)
            selEventName(obj.alert_name)
            setAlertViewDate("")
              getAlertView(obj.alert_id).then(response => {
                if(response.suc){
                  setShowAlert(true)
                  setAlertViewDate(response.res)
                }else{
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Something went wrong.Please try again later'})
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage()
                }
          })
          }
          function hidemessage() {
            setTimeout(function () {
              dispatch({ type: 'notiMessageShow', payload: false })
              dispatch({ type: 'notiMessage', payload: '' })
              dispatch({ type: 'notiVarient', payload: '' })
            }, 5000);
          }
        const handleClose=()=>{
            setShowAlert(false)
           }

    return (
        <>
          <p className="reminderView"> <Button variant="link" ><Link
             to="/ezytrip/dashboard/alerts/list"
            title="Alerts">
            View All
          </Link></Button></p>
            <Scrollbars style={{ height: 500 }}>
                <div className="dashBordReminder pr-3">
                {props.Alerts.length!==0  &&  props.Alerts.map((item) => (
                    <div className="alertListDetails alertDate ezyIcon">
                        <Link onClick = {()=>openAlert(item)}><p className="alerttitle">{item.alert_name}</p></Link>
                        <div className="datePnr">
                        <p className="date ezyIcon icon-newcalendar"><span>{DateUtils.prettyDate(item.noti_date, 'DD-MM-YYYY')}</span></p>
                            <p className="m-0">PNR NO: {item.pnr}</p>
                            </div>
                    </div>))}
                </div>
            </Scrollbars>
            {showAlert && alertViewData!==undefined &&
            <AlertModal showAlert={showAlert} alertViewData={alertViewData} selEvntName= {selEvntName} handleClose={handleClose}/>           
            }
            
        </>
    )
}


export default DashBordAlert;
