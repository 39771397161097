import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import Select from 'react-select';
import { Context } from "../../../../../App";
import ShowHide from '../../../common/showHide/ShowHide';
import { characternameValidation } from "../../../commonUtils/FormValidations";
import { isNotNull } from '../../../commonUtils/validators';
import { getCustomData } from './operations';

/**
 * @description:This function will creaet the Docments
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 26-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  custList: [], enbaleDrop: false, dropList: [], enbaleUpload: false, data: []
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function CustomInformaion(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, setValue] = useState([]);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if(context.logindata !== "" && context.logindata!==undefined && props.compid!==undefined){
    getCustomData(context.logindata.bid, props.compid).then(response => {
      if (response.suc && response.res!==undefined && response.res.length!==0) {
        dispatch({ type: 'custList', payload: response.res })
        let dropdown = [];
        var payload = "";
        for (let value of response.res) {
          if (value.ftpe !== "" && value.ftpe === "D_D") {
            if (value.fval.includes(",")) {
              const valAry = value.fval.split(",");
              for (let val of valAry) {
                payload = {
                  "value": val,
                  "label": val
                }
                dropdown.push(payload)
              }
              dispatch({ type: 'dropList', payload: dropdown })
              dispatch({ type: "enbaleDrop", payload: true });
              value.fval="";
            }
          }
        }
        props.customs(response.res);
      }else{
        props.noCustoms();
      }
    })
  }

  }, [context.logindata !== "" && context.logindata !== undefined]);


  const setDropVal = idx => e => {
    try {
      let newCustoms = state.custList.map((obj, sidx) => {
        if (idx !== sidx) return obj;
        else {
          return { ...obj, fval: e.value };
        }
      });
      dispatch({ type: "custList", payload: newCustoms });
      props.customs(newCustoms);
    } catch (err) {
      log.error('Exception occured in handleChangeCustom function---' + err);
    }
  }
 const validateData = (val)=>{
   if(val !== ""){
  if(characternameValidation(val)){ 
    return true
    }
    else{
      return false
    }
  }
  else{
    return true
  }
}
  //This function will update the state Vlaue
  const handleChangeCustom = idx => evt => {
    try {
      let newCustoms = state.custList.map((obj, sidx) => {
        if (idx !== sidx) return obj;
        else {
          if(obj.ftpe == "T_A"){
          if(validateData( evt.target.value)){
          return { ...obj, [evt.target.id]: evt.target.value };
        }
        else{
          return obj
        }
      }
      else{
        if (idx !== sidx) return obj;
        else{
          return { ...obj, [evt.target.id]: evt.target.value };
        }
      }
      }
      });
      dispatch({ type: "custList", payload: newCustoms });
      props.customs(newCustoms);
    } catch (err) {
      log.error('Exception occured in handleChangeCustom function---' + err);
    }
  }

  return (
    <>
    {state.custList!==1 &&
      <div className="boxLayout additionalinfo pb-0 mt-3">
        <ShowHide visible="true" icon="document" title="Additional Information">

          <div className="showHide-content pl-0 pr-0">
            <Form.Row>
            {state.custList.map((cust, idx) => (
                <React.Fragment key={idx}>
                  {cust.ftpe !== "D_D" && cust.ftpe !== "D_U" &&
                    <Form.Group as={Col} xs={4} controlId="fval">
                      <Form.Control type="text" disabled={props.isOnhold} placeholder="Enter Label" value={cust.fval} onChange={handleChangeCustom(idx)} maxLength={cust.flen !== undefined && cust.flen !== null && cust.flen} />
                      <Form.Label>{cust.flbl}{isNotNull(cust.rm_type) && ((" - ") + (cust.rm_type))}
                        {cust.fmand !== undefined && cust.fmand !== null && cust.fmand == "Yes" ? <sup>*</sup> : ""}
                      </Form.Label>
                    </Form.Group>
                  }
                  {cust.ftpe === "D_D" &&
                    <Form.Group as={Col} xs="4" className="selectplugin" controlId="cn">
                      <Select
                        disabled={props.isOnhold}
                        options={state.dropList}
                        id="class"
                        isSearchable={false}
                        values={[]}
                        onChange={setDropVal(idx)}
                      />
                      <Form.Label>{cust.flbl}
                        {cust.fmand ? <sup>*</sup> : ""}</Form.Label>
                    </Form.Group>
                  }
                  {cust.ftpe === "D_U" &&
                    <Form.Group as={Col} xs={4} className="selectplugind" controlId="Logo">
                      <Form.File
                        disabled={props.isOnhold}
                        onChange={handleChangeCustom(idx)}
                        id="Logo"
                        custom
                      />
                      <Form.Label className="uploadLabel">{cust.flbl} {cust.fmand !== undefined && cust.fmand !== null && cust.fmand == "Yes" ? <sup>*</sup> : ""} </Form.Label>
                  </Form.Group>
                  }
                </React.Fragment>

                ))}
            </Form.Row>
          </div>
          </ShowHide></div>}
    </>
  )
}
export default CustomInformaion