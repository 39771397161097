import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Alert, Col, Form, Table, Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import Button from "../../../common/buttons/Button";
import RegionType from "../../common/RegionType";
import SalesChannel from "../../common/SalesChannel";
import ShowHide from "../../../common/showHide/ShowHide";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DatePicker from "react-datepicker";
import { createRevenue, deleteRoutes, fetch_company_list, getOfcIdList, updateRevenue } from "./operations";
import { Typeahead } from "react-bootstrap-typeahead";
import { Context } from "../../../../../App";
import PredectiveSearch from "../../../../services/predective";
import debounce from "lodash.debounce";
import log from "loglevel";
import ServiceType from "./ServiceType";
import DateUtils from "../../../commonUtils/DateUtils";
import { isArrayNotEmpty, isNotNull } from "../../../commonUtils/validators";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import { fetchBranches, loadBranchesList } from "../operations";
const _ = require("lodash");

let airlineMaster = require("../../../masterData/AirlinesMasterData.json");
let class_Code_List = require("../../../masterData/BookingClassCode.json");

/**
 * Initial State Declaration
 */

const initialState = {
  id:[],
  notiMessage: '',
  notiMessageShow: false,
  notiVarient: '',
  enablebtn:false,
  enableData:false,
  enableView:false,
  companyList: [],
  selCompany: [],
  bid:[],
  salesChanel: [],
  edit_salesChanel: "",
  region_type: "",
  officeIds: [],
  ofcs: [],
  ofcID:"",
  region_type_edit: "",
  resetChannel:false,
  resetRegion:false,
  selDates: [],
  setIHdate: new Date(),
  setHDate: new Date(),
  airlinesList:[],
  airlineName:[],
  flight_no:"",
  classCode:[],
  travelType:[],
  airportList: [],
  selAirport: [],
  airportsOW: [],
  airportsRT: [],
  src_code: "",
  scname: "",
  srcct: "",
  selOrigin: "",
  selDest: "",
  destct: "",
  dcname: "",
  dest_code:"",
  revenueType:[],
  sign:[],
  value:"",
  isActive:true,
  supplier: []
};

let routesArray = [];

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function RevenuMain(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [enableAirports, setEnableAirports] = useState(true);
  const [enableDelete, setEnableDelete] = useState(false);
  const [routDetails, setRoutDetails] = useState({rts:[]});

  const uref = useRef();
  const originref = useRef();
  const suppliers = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6},{label:"Riya Travel", value: 7}];
  const travel_Type = [{ label: "One Way", value: "1" },
  { label: "Round Trip", value: "2" }]

  const revenue_Type = [{ label: "Percentage", value: "Percentage" },
  { label: "Value", value: "Value" }]

  const sign = [{ label: "plus(+)", value: "plus(+)" },
  { label: "minus(-)", value: "minus(-)" }]

  const updateSalesChanel = (salesChanel) => {
    if(!state.enableData){
      dispatch({ type: "resetRegion", payload: true });
      dispatch({ type: "selCompany", payload: [] });
      dispatch({ type: "supplier", payload: [] });
      dispatch({ type: "bid", payload: [] });
    dispatch({ type: "ofcs", payload: [] })
    }
    dispatch({ type: "salesChanel", payload: salesChanel });
  };

  const handleRegionType = (e) => {
    dispatch({ type: "resetRegion", payload: false });
  };

  const updateRegion = (region) => {
    dispatch({ type: "resetRegion", payload: false });
    if(!state.enableData){
      dispatch({ type: "supplier", payload: [] });
      dispatch({ type: "ofcs", payload: [] });
      dispatch({ type: "bid", payload: [] })
      dispatch({ type: "selCompany", payload: [] })
    }
    dispatch({ type: "region_type", payload: region });
  };
  const updateSupplier=(sup)=>{
    if(!state.enableData){
      dispatch({ type: "ofcs", payload: [] });
      dispatch({ type: "bid", payload: [] })
      dispatch({ type: "selCompany", payload: [] })
    }
    if(sup!==undefined && sup.length!==0){
      //props.sendSup(sup);
      dispatch({type:"companyList", payload:[]})
      if(sup[0].label==="Amadeus"){
        dispatch({type:"enableOfcId", payload:true})
      }else{
        dispatch({type:"enableOfcId", payload:false})
      }
      dispatch({type:"branchMenu", payload:[]})
      dispatch({type:"bid", payload:0})
      // setCompanyPick([])
      if(sup[0].label!=="Amadeus"){
        if(state.salesChanel==="B2B"){
        if(context.logindata.utype == "att_user") {
       if(context.logindata.is_admin){
        fetchBranches({"region_type": state.region_type }).then(response => {
          if (response.data.suc) {
            let branchesList = response.data.res.map((branch) =>
              <option value={branch.value}>{branch.label}</option>
            );
            dispatch({ type: 'branchMenu', payload: branchesList })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
      }else{
        loadBranchesList({"region_type": state.region_type,"uid": context.logindata.uid}).then(response => {
          if (response.data.suc) {
            let branchesList = response.data.res.map((branch) =>
              <option value={branch.value}>{branch.label}</option>
            );
            dispatch({ type: 'branchMenu', payload: branchesList })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
      }
      }
      }else{
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
      AttCommonSerivce.att_companies(pyload).then(response => {
        let authData = []
        if (response.data.suc && response.data.res && response.data.res.data) {
          for (let val of response.data.res.data) {
            let auth = {
              "value": val.value,
              "label": val.label,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.salesChanel)
          // setCompanyPick(authData)
          dispatch({type:"companyList", payload:cmpanis})
        }
      }).catch(function (error) {
        console.log(
          "Exception occured in getCompanyList function---" + error
        );
      });
      }
      }
    }
  }

  /**
 * @author: Lakshmi
 * @description: This function will Load the Branches based on the selected OFC ID
 * @date:14-09-2020
 * @function : getBranchesWithOfficeId
 */
 useEffect(() => {
  if(state.ofcID && state.supplier &&state.supplier[0] &&state.supplier[0].label ==="Amadeus"){
    
    AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.ofcID,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.salesChanel}).then(response => {
      if (response.data.suc){
        let authData = []
        let BRANCHES = response.data.res.filter((val)=>{
                return val.fk_branch_id != null;
              })
              for (let val of BRANCHES){
                let auth = {
                  "value": val.fk_branch_id
                  ,
                  "label": val.office_name
                }
                authData.push(auth)
              }
        let branchesList = authData.map((branch) => (
          <option value={branch.value}>{branch.label}</option>
        ));
        state.branchMenu = branchesList;
        dispatch({ type: "branchMenu", payload: branchesList });
        dispatch({ type: "branches", payload: branchesList });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in Loadi branches based on Region function---" + error
      );
    });
  }
}, [state.ofcID,state.supplier])

  // response loading
  useEffect(()=>{
    if (props.response.length!==0){
      setRoutDetails({rts:props.response.rts})
      dispatch({type:"id", payload:props.response.id})
      dispatch({type:"region_type_edit", payload:props.response.region_type})
      dispatch({type:"region_type", payload:props.response.region_type})
      dispatch({type:"edit_salesChanel", payload:props.response.sales_channel})
      dispatch({type:"ofcs", payload:[{value:props.response.office_id, label:props.response.ofc_label}]})
      dispatch({type:"selCompany", payload:[{value:props.response.cmpid, label:props.response.cname}]})
     for (let data of airlineMaster){
       if(props.response.al==data.value){
      dispatch({type:"airlineName", payload:[{value:data.value, label:data.label}]})
    } else if(props.response.al == "All") {
      dispatch({type:"airlineName", payload:[{value:"All", label:"All"}]})
    }}
      dispatch({type:"travelType", payload:[{value:props.response.ttype, label:props.response.ttype==2?"Round Trip":"One Way"}]})
      dispatch({type:"classCode", payload:props.response.rbd==""?[]:[{value:props.response.rbd, label:props.response.rbd}]})
      dispatch({type:"revenueType", payload:[{value:props.response.rtype, label:props.response.rtype}]})
      dispatch({type:"sign", payload:[{value:props.response.sign, label:props.response.sign}]})
      dispatch({type:"value", payload:props.response.val})
      dispatch({type:"flight_no", payload:props.response.flt_no})
      dispatch({type:"isActive", payload:props.response.act==1?true:false})
      dispatch({type:"enablebtn", payload: true})
      dispatch({type:"enableData", payload: true})
      if(props.response.fm && props.response.to) {
         setFromDate(new Date(props.response.fm))
         setToDate(new Date(props.response.to))
      }
      
      let edit_supplier = props.response.supplier || props.response.supplier == 0 ? suppliers.filter(function (s) {
        if (s.value == props.response.supplier) {
          return s;
        }
      }) : []
      dispatch({ type: "supplier", payload: edit_supplier })
      setEnableAirports(false)
      if(props.response.rts.length>=1 ){
        setEnableDelete(true)
      }
    }
    if (props.viewResponse.length!==0){
      setRoutDetails({rts:props.viewResponse.rts})
      dispatch({type:"region_type_edit", payload:props.viewResponse.region_type})
      dispatch({type:"region_type", payload:props.viewResponse.region_type})
      dispatch({type:"edit_salesChanel", payload:props.viewResponse.sales_channel})
      dispatch({type:"ofcs", payload:[{value:props.viewResponse.office_id, label:props.viewResponse.ofc_label}]})
      dispatch({type:"selCompany", payload:[{value:props.viewResponse.cmpid, label:props.viewResponse.cname}]})
      for (let data of airlineMaster){
        if(props.viewResponse.al==data.value){
       dispatch({type:"airlineName", payload:[{value:data.value, label:data.label}]})
     } else if(props.viewResponse.al == "All") {
       dispatch({type:"airlineName", payload:[{value:"All", label:"All"}]})
     }}
      dispatch({type:"travelType", payload:[{value:props.viewResponse.ttype, label:props.viewResponse.ttype==2?"Round Trip":"One Way"}]})
      dispatch({type:"classCode", payload:props.viewResponse.rbd==""?[]:[{value:props.viewResponse.rbd, label:props.viewResponse.rbd}]})
      dispatch({type:"revenueType", payload:[{value:props.viewResponse.rtype, label:props.viewResponse.rtype}]})
      dispatch({type:"sign", payload:[{value:props.viewResponse.sign, label:props.viewResponse.sign}]})
      dispatch({type:"value", payload:props.viewResponse.val})
      dispatch({type:"flight_no", payload:props.viewResponse.flt_no})
      dispatch({type:"isActive", payload:props.viewResponse.act==0?false:true})
      dispatch({type:"enableData", payload: true})
      dispatch({type:"enableView", payload: true})
      if(props.viewResponse.fm && props.viewResponse.to) {
        setFromDate(new Date(props.viewResponse.fm))
        setToDate(new Date(props.viewResponse.to))
     }
      let edit_supplier = props.viewResponse.supplier || props.viewResponse.supplier == 0 ? suppliers.filter(function (s) {
        if (s.value == props.viewResponse.supplier) {
          return s;
        }
      }) : []
      dispatch({ type: "supplier", payload: edit_supplier })
      setEnableAirports(false)
    }

  },[props.response])
  useEffect(() => {
    getOfcIdList().then((res) => {
      let list = [];
      if (res.data.suc) {
        for (let val of res.data.res) {
          const req = {
            value: val.id,
            label: val.office_id,
          };
          list.push(req);
        }
        dispatch({ type: "officeIds", payload: list });
      }
    });
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" })
    let airlines = getUnique(airs, "value")
    dispatch({ type: 'airlinesList', payload: airlines })
  }, [state.salesChanel, state.region_type]);

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const updateOfcId = (ofc) => {
    if(!state.enableData){
      dispatch({ type: "bid", payload: [] })
      dispatch({ type: "selCompany", payload: [] })
    }
    dispatch({ type: "ofcs", payload: ofc });
    if (ofc.length !== 0) {
      if (state.salesChanel !== "") {
        if (state.salesChanel === "B2C") {
            const payload = {
              office_id: ofc[0].value,
              userid: context.logindata.uid,
              region_type: state.region_type,
              sales_channel: state.salesChanel,
            };
            fetch_company_list(payload).then((response) => {
              if (response.data.suc) {
                let authData = [];
                for (let val of response.data.res) {
                  let auth = {
                    label: val.company_name,
                    value: val.fk_company_id,
                    sales_channel: val.sales_channel
                  };
                  authData.push(auth);
                }
                let cmpanis = authData.filter((obj)=>obj.sales_channel===state.salesChanel)
                dispatch({ type: "companyList", payload: cmpanis });
              }
            });
        } else {
          let payload = "";
          if (context.logindata.is_admin) {
            payload = { region_type: state.region_type, ofc_id: ofc[0].value };
          } else {
            payload = {
              region_type: state.region_type,
              ofc_id: ofc[0].value,
              salesChanel: state.salesChanel,
              uid: context.logindata.uid,
            };
          }
        }
      }
    }
    dispatch({ type: "ofcID", payload: ofc.length != 0 ? ofc[0].value : "" });
  }

  /**
 * @author: Lakshmi
 * @description: This function will Load the Companies Based on the Branch for B2B
 * @date:14-09-2020
 * @function : fetch_company_list
 */
  const handleBranchChange = (e) => {
    if(!state.enableData){
      dispatch({ type: "selCompany", payload: [] })
    }
    let selbr = state.branches.filter((val)=>{
      if(val.value){
       if ( val.value == e.target.value){
        dispatch({ type: 'data', payload: [{"value":val.value, "label":val.children}]})
       }
      }
        else if(val.props.value){
        if(val.props.value == e.target.value){
          dispatch({ type: 'data', payload: [{"value":val.props.value, "label":val.props.children}] })
        }
      }
    })
    // dispatch({ type: 'selected_branch', payload: selbr });
    // state.selected_branch.push(selbr)
    // setCompanyPick("");
    dispatch({ type: "companyList", payload: [] });
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
    dispatch({ type: "bid", payload: value });
    const payload = {"office_id":state.ofcs[0]?.value?state.ofcs[0]?.value:"","userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.salesChanel,"branch_id":e.target.value}
      fetch_company_list(payload).then(response => {
        if (response.data.suc) {
          let authData = []
          for (let val of response.data.res){
            let auth = {
              "value": val.fk_company_id,
              "label": val.company_name,
              "sales_channel": val.sales_channel
            }
            authData.push(auth)
          }

          // setCompanyPick(authData)
          let cmpanis = authData.filter((obj)=>obj.sales_channel===state.salesChanel)
          dispatch({type:"companyList", payload:cmpanis})
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });

    state.branches.map((obj) => {
      let blist = [];
      if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
        blist.push({ "id": obj.value, "name": obj.label });
        dispatch({ type: 'selBranches', payload: blist })
      }
    });
  }

    const handleCompany = (e) => {
      dispatch({ type: "selCompany", payload: e });
    };

    const handleOrigin = (input, e) => {
      dispatch({ type: "src_code", payload: "" });
      dispatch({ type: "orignData", payload: [] });
      dispatch({ type: "selOrigin", payload: [] });
      if (input.length > 0) {
        dispatch({ type: "clearob", payload: true });
      } else {
        dispatch({ type: "clearob", payload: false });
      }
      if (input.length > 2) {
        state.selOrigin = [];
        // predectiveCall(input, 'airportsOW')
        let obj = {
          textValue: input,
          type: "airportsOW",
        };
        debouncedSave(obj);
      } else {
        state.selOrigin = [];
        dispatch({ type: "airportsOW", payload: [] });
      }
    };

    const handleOnOrigin = (obj) => {
      if (obj.length > 0) {
        dispatch({ type: "src_code", payload: obj[0].airportCode });
        dispatch({ type: "scname", payload: obj[0].countryName });
        dispatch({ type: "srcct", payload: obj[0].countryCode });
        dispatch({ type: "selOrigin", payload: obj });
        // uref.current.focus();
        // errorRemoveBlur();
        // props.sendSrc(obj);
      }
    };

    // highlight-starts
  const debouncedSave = useCallback(
    debounce((obj) => predectiveCall(obj.textValue, obj.type), 500),
    [] // will be created only once initially
  );
  // highlight-ends

   //=====================================
  // This function will call the service to get the Airport data
  //=====================================
  const predectiveCall = async (input, route) => {
    if (input.length > 1) {
      await PredectiveSearch.retreieveSearch(input).then((data) => {
        dispatch({ type: route, payload: data.data !== "" ? data.data : [] });
      });
    } else {
      dispatch({ type: route, payload: [] });
    }
  };

  //=====================================
  // This function will get the dest data
  //=====================================
  const handleDestination = (input, e) => {
    dispatch({ type: "dest_code", payload: "" });
    dispatch({ type: "destData", payload: [] });
    dispatch({ type: "selDest", payload: [] });
    if (input.length > 0) {
      dispatch({ type: "cleardb", payload: true });
    } else {
      dispatch({ type: "cleardb", payload: false });
    }
    if (input.length > 2) {
      state.selDest = [];
      //predectiveCall(input, 'airportsRT')
      let obj = {
        textValue: input,
        type: "airportsRT",
      };
      debouncedSave(obj);
    } else {
      state.selDest = [];
      dispatch({ type: "airportsRT", payload: [] });
    }
  };

  //=====================================
  // This function will get the Source data
  //=====================================
  const handleOnDest = (obj) => {
    if (obj.length > 0) {
      dispatch({ type: "dest_code", payload: obj[0].airportCode });
      dispatch({ type: "dcname", payload: obj[0].countryName });
      dispatch({ type: "destct", payload: obj[0].countryCode });
      dispatch({ type: "destData", payload: obj });
      dispatch({ type: "selDest", payload: obj });
      // openOnwardCal();
      // errorRemoveBlur();
      // props.sendDest(obj);
    }
  };

const handleRoutes = () =>{
           if (state.selOrigin.length!=0){
            var route = {
              "srcct": state.srcct,
              "scname": state.scname ,
              "destct": state.destct,
              "dcname": state.dcname,
              "dest_code": state.dest_code,
              "src_code": state.src_code
          }
          routesArray.push(route)
          routDetails.rts.push(route);
          setRoutDetails({rts:routDetails.rts})
          dispatch({type:"selOrigin", payload:[]})
          dispatch({type:"selDest", payload:[]})
          dispatch({type:"airportsOW", payload:[]})
          dispatch({type:"airportsRT", payload:[]})
        }
      }

      const handleRemoveRoute = (route, idx) => () => {
        if (route.id!==undefined) {
          deleteRoutes(route.id).then((response)=>{
            if (response.suc){
            }
          })}
          setRoutDetails({ rts: routDetails.rts.filter((s, sidx) => idx !== sidx) });
          let routesList = routesArray;
          routesArray = [];
          routesArray.push(routesList.filter(item => item.scnt !== undefined && item.scnt !== route.srcct && item.dcnt !== route.destct && route.srcair.includes(item.source)));
      };

      const changedate = (date) => {
        if (date) {
          let y = _.cloneDeep(date);
          setFromDate(y);
          let d = date;
          d.setDate(d.getDate() + 1);
          console.log("new date", d);
          dispatch({ type: "setHDate", payload: d });
        }
      };

      const handleValue = (e) => {
        const {value}= e.target
        const numericValue=value.replace(/\D/,'')
        dispatch({type:"value", payload:numericValue})

      }
      const handleChangeStatus = (e) => {
        if (state.isActive){
        dispatch({ type: 'isActive', payload: false })
      } else{
        dispatch({ type: 'isActive', payload: true })
      }
    }

      const hidemessage =() => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

      const validation = ()=> {
        if (state.salesChanel==""){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select sales channel' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.region_type==""){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select region' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.supplier[0].label === "Amadeus" && state.ofcs=="" || state.ofcs==undefined ){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select office id' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.selCompany=="" || state.selCompany==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.airlineName=="" || state.airlineName==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select airline' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (routDetails.rts=="" || routDetails.rts==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select airport' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.travelType=="" || state.travelType==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select travel type' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }

        if (fromDate=="" || fromDate==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select from date' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        return false;
        }
        if (toDate=="" || toDate==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select to date' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
        return false;
        }
        if (state.supplier[0].label === "Amadeus" && state.classCode=="" || state.supplier[0].label === "Amadeus" && state.classCode==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select rbd' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.revenueType=="" || state.revenueType==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select revenue type' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.sign=="" || state.sign==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select sign' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        if (state.value=="" || state.value==undefined){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter value' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
        return true;
      }

    const save = ()=> {
     if (validation()) {
      let code = '';
      if ( isArrayNotEmpty(state.classCode)) {
        for (let c in state.classCode) {
          let code_data = state.classCode[c];
          if (!isNotNull(code)) {
            code = code_data.value;
          } else {
            code = code + ',' + code_data.value;
          }
        }
      }
     const payload = {
    "sales_channel": state.salesChanel,
    "region_type": state.region_type,
    // "office_id": null,
    "office_id": state.ofcs.length!=0? state.ofcs[0].value:"",
    "supp": state.supplier.length > 0 ? state.supplier[0].value : null,
    "cmpid":state.selCompany.length!=0? state.selCompany[0].value:"",
    "bid":(state.bid.length && state.bid.length!=0)? state.bid[0].value:"",
    "al": state.airlineName.length!=0? state.airlineName[0].value:"",
    "flt_no":state.flight_no,
    "rbd": code,
    "ttype": state.travelType.length!=0? state.travelType[0].value:"",
    "act": state.isActive,
    "crby": context.logindata.uid,
    "fm": DateUtils.convertStringToDate(fromDate),
    "to": DateUtils.convertStringToDate(toDate),
    "rtype": state.revenueType.length!=0? state.revenueType[0].label:"",
    "val": state.value,
    "sign":state.sign.length!=0? state.sign[0].label:"",
    "rts": routDetails.rts
     }
     createRevenue(payload).then((response)=>{
      if (response.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Record saved succesfully' })
      dispatch({ type: 'notiVarient', payload: 'success' })
      return window.location = "/ezytrip/administration/revenue"
        }
      })}
    }


    const update = () => {
      if (validation()){
      let code = '';
      if ( isArrayNotEmpty(state.classCode)) {
        for (let c in state.classCode) {
          let code_data = state.classCode[c];
          if (!isNotNull(code)) {
            code = code_data.value;
          } else {
            code = code + ',' + code_data.value;
          }
        }
      }
      const payload = {
        "id":state.id,
        "sales_channel": state.salesChanel,
        "region_type": state.region_type,
         "office_id": state.ofcs.length!=0? state.ofcs[0].value:"",
        "supp": state.supplier.length > 0 ? state.supplier[0].value : null,
        "cmpid":state.selCompany.length!=0? state.selCompany[0].value:"",
        "bid":(state.bid.length && state.bid.length!=0)? state.bid[0].value:"",
        "al": state.airlineName.length!=0? state.airlineName[0].value:"",
        "flt_no":state.flight_no,
        "rbd": code,
        "ttype": state.travelType.length!=0? state.travelType[0].value:"",
        "act": state.isActive,
        "upby": context.logindata.uid,
        "fm": DateUtils.convertStringToDate(fromDate),
        "to": DateUtils.convertStringToDate(toDate),
        "rtype": state.revenueType.length!=0? state.revenueType[0].label:"",
        "val": state.value,
        "sign":state.sign.length!=0? state.sign[0].label:"",
        "rts": routDetails.rts
         }
         updateRevenue(payload).then((response)=>{
          if (response.suc){
            dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Record is updated' })
          dispatch({ type: 'notiVarient', payload: 'success' })
          return window.location = "/ezytrip/administration/revenue"
          }else{
            dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Record is not updated' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          return window.location = "/ezytrip/administration/revenue"
          }
         })
        }
      }

      const handleCancel = (e) => {
        return window.location = "/ezytrip/administration/revenue"
      }

    const handleReset = () => {
        state.salesChanel=""
        dispatch({type:"resetChannel", payload:true})
        dispatch({type:"resetRegion", payload:true})
        dispatch({type:"selOrigin", payload:""})
        dispatch({type:"selDest", payload:""})
        dispatch({type:"flight_no", payload:""})
        dispatch({type:"value", payload:""})
        dispatch({type:"airlineName", payload:[]})
        dispatch({type:"travelType", payload:[]})
        dispatch({type:"supplier", payload:[]})
        dispatch({type:"ofcs", payload:[]})
        dispatch({type:"selCompany", payload:[]})
        dispatch({type:"bid", payload:[]})
        dispatch({type:"selDates", payload:[]})
        dispatch({type:"classCode", payload:[]})
        dispatch({type:"revenueType", payload:[]})
        dispatch({type:"sign", payload:[]})
        setFromDate('')
        setToDate('')
        originref.current.clear()
        uref.current.clear()
   };
  return (
    <>
    {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
    <div className="boxLayout">
        <ShowHide visible={false}  title="Fetch Flight Services">
      <Tabs
        defaultActiveKey="markup"
        id="uncontrolled-tab-example"
        className="mb-3 fetchFservi"
      >
        <Tab eventKey="markup" title="Markup">
          <ServiceType type={"Markup"}/>
        </Tab>
        <Tab eventKey="service" title="Service Fee">
          <ServiceType type={"ServiceFee"}/>
        </Tab>
      </Tabs>
      </ShowHide>
      </div>
      <div className="boxLayout">
      <Form.Row>
      <SalesChannel
            sendSalesChanel={updateSalesChanel}
            edit_sales={state.edit_salesChanel}
            resetChannel={state.resetChannel}
          />
          <RegionType
            sendResponse={(e) => handleRegionType(e)}
            regionType={updateRegion}
            regionUpdate={state.region_type_edit}
            resetRegion={state.resetRegion}
          />
          <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="supplier"
          >
            <Select
              options={suppliers}
              values={state.supplier}
              // onChange={updateOfcId}
              onChange={(value) => (dispatch({ type: "supplier", payload: value }),updateSupplier(value))}
              disabled={state.enableData }
            />
            <Form.Label>
              Supplier <sup>*</sup>
            </Form.Label>
          </Form.Group>
          {state.supplier &&state.supplier[0] &&state.supplier[0].label === "Amadeus" && <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="supplier"
          >
            <Select
              options={state.officeIds}
              values={state.ofcs}
              onChange={updateOfcId}
              disabled={state.enableData }
            />
            <Form.Label>
              Office Id <sup>*</sup>
            </Form.Label>
          </Form.Group>}

          {state.salesChanel=="B2B" &&
              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value={state.data}>Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
            }
          
          <Form.Group
            as={Col}
            xs="3"
            className="selectplugin"
            controlId="company_id"
          >
            <Select
              disabled={state.enableData}
              options={state.companyList}
              values={state.selCompany}
              onChange={handleCompany}
            />
            <Form.Label>
              Company<sup>*</sup>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
            <Select
              disabled={state.enableView}
              options={state.airlinesList}
              values={state.airlineName}
              onChange={(value) => (dispatch({ type: 'airlineName', payload: value }))} />
            <Form.Label>Airlines <sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="flight_no">
        <Form.Control type="text" disabled={state.enableView} placeholder="User Role" autoComplete="off" onChange={(e)=>(dispatch({type:"flight_no", payload:e.target.value}))} value={state.flight_no}/>
        <Form.Label>Flight No</Form.Label>
        </Form.Group>
        <Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="code_id"
          >
            <Select
              disabled={state.enableView}
              multi
              options={class_Code_List}
              values={state.classCode}
              onChange={(value) => dispatch({ type: "classCode", payload: value })}
            />
             <Form.Label>RBD{state.supplier &&state.supplier[0] &&state.supplier[0].label === "Amadeus" &&<sup>*</sup>}</Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
            <Select
              disabled={state.enableView}
              options={travel_Type}
              values={state.travelType}
              onChange={(value) => (dispatch({ type: 'travelType', payload: value }))} />
            <Form.Label>Travel Type <sup>*</sup></Form.Label>
          </Form.Group>
      </Form.Row>
      </div>
      <Form.Row>
      <Form.Group as={Col} xs="3" className="selectplugin revMan">
            <Typeahead
              //defaultInputValue={state.selectedOrigin}
              id="originmaster"
              labelKey={(airport) =>
                `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`
              }
              disabled={state.enableView}
              onInputChange={handleOrigin}
              options={state.airportsOW}
              selected={state.selOrigin}
              onChange={(airport) => handleOnOrigin(airport)}
              filterBy={[
                "cityName",
                "airportName",
                "airportCode",
                "countryName",
              ]}
              //onFocus={originOnFocus} onBlur={originOnBlur}
              placeholder="From"
              readOnly
              autoFocus
              minLength={1}
              // onFocus={activeOrigin}
              renderMenuItemChildren={(airport) => (
                <>
                <div className="searchTabb">
                  <span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                    <strong> {airport.cityName}, </strong>
                    <span className="airportContname" title={airport.countryName}>
                      {airport.countryName}
                    </span>
                    <small>{airport.airportCode}</small>
                  </div>

                  <span className="airportName" title={airport.airportName}>{airport.airportName}</span>
                  </div>
                </>
              )}
              ref={originref}
            />
            <Form.Label>
              Source Airport<sup>*</sup>
            </Form.Label>
          </Form.Group>

          <Form.Group as={Col} xs="3" className="selectplugin revMan">
            <Typeahead
              //defaultInputValue={state.selectedOrigin}
              id="basic-typeahead-single"
              labelKey={(airport) =>
                `${airport.airportCode}, ${airport.cityName} - ${airport.countryName}`
              }
              onInputChange={handleDestination}
              options={state.airportsRT}
              //  onFocus={originOnFocus} onBlur={originOnBlur}
              placeholder="To"
              disabled={state.enableView}
              selected={state.selDest}
              onChange={(airport) => handleOnDest(airport)}
              filterBy={[
                "cityName",
                "airportName",
                "airportCode",
                "countryName",
              ]}
              readOnly
              minLength={1}
              // onFocus={activeDesti}
              renderMenuItemChildren={(airport) => (
                <>
              <div className="searchTabb">
                  <span className="ezyIcon icon-plane"></span>
                  <div className="d-flex originCitynamec">
                    <strong> {airport.cityName}, </strong>
                    <span
                      className="airportContname"
                      title={airport.countryName}
                    >
                      {airport.countryName}
                    </span>
                    <small>{airport.airportCode}</small>
                  </div>

                  <span className="airportName" title={airport.airportName}>
                    {airport.airportName}
                  </span>
                  </div>
                </>
              )}
              ref={uref}
            />
            <Form.Label>
              Destination Airport<sup>*</sup>
            </Form.Label>
          </Form.Group>
          { !state.enableView &&
          <Form.Group as={Col}>
              <Button
                size="xs"
                variant="outline-primary"
                onClick={handleRoutes}
                // isDisabled={(props.disabledBtn) ? true : false}
              > Add</Button>
          </Form.Group>}
          </Form.Row>
          {isArrayNotEmpty(routDetails.rts) &&
          <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Source - Destination</th>
                      <th width="8%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routDetails.rts.map((route, index) => (
                      <React.Fragment key={index}>
                        {route.scname !== undefined && route.dcname !== undefined &&
                          <tr>
                            <td>
                              <span>{route.scname } ({route.src_code}) {"--->"} {route.dcname} ({route.dest_code})</span>
                            </td>

                            <td>
                              { enableAirports&&
                                <Button
                                  size="xs"
                                  onClick={handleRemoveRoute(route, index)}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }
                              { enableDelete&& routDetails.rts.length>1&&
                                <Button
                                  size="xs"
                                  onClick={handleRemoveRoute(route, index)}
                                 variant="link" className="ezyIcon icon-delete">
                                </Button>
                              }

                            </td>
                          </tr>
                        }
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>}

          <Form.Row >
              <Form.Group
                as={Col}
                lg={3}
                md={3}
                className="selectplugin datePicker"
                controlId="fromDate"
              >
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => (setToDate(), changedate(date))}
                  fromDate={fromDate}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  toDate={toDate}
                  minDate={new Date()}
                />
                <span className="calendar ezyIcon icon-newcalendar"></span>
                <Form.Label>From Date<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={3}
                md={3}
                className="selectplugin datePicker"
                controlId="toDate"
              >
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date) + 1}
                  fromDate={fromDate}
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd-MM-yyyy"
                  toDate={toDate}
                  minDate={
                    fromDate
                      ? props.hotel !== undefined
                        ? state.setHDate
                        : fromDate
                      : props.hotel !== undefined
                      ? state.setIHdate
                      : new Date()
                  }
                  className="datefield"
                />
                <span className="calendar ezyIcon icon-newcalendar"></span>
                <Form.Label>To Date<sup>*</sup></Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
            <Select
             disabled={state.enableView}
              options={revenue_Type}
              values={state.revenueType}
              onChange={(value) => (dispatch({ type: 'revenueType', payload: value }))} />
            <Form.Label>Revenue Type <sup>*</sup></Form.Label>
          </Form.Group>
            <Form.Group as={Col} md={2} className="selectplugin signMiplus" controlId="selectedValue">
            <Select
              disabled={state.enableView}
              options={sign}
              values={state.sign}
              onChange={(value) => (dispatch({ type: 'sign', payload: value }))} />
            <Form.Label>+/-Sign<sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="value">
        <Form.Control  type="text" disabled={state.enableView} placeholder="User Role" autoComplete="off" onChange={handleValue} value={state.value}/>
        <Form.Label>Value <sup>*</sup></Form.Label>
        </Form.Group>
          </Form.Row>

          <div className="switchPlus buttonGrop pb-0">
            {/* Status  */}
            <Form.Group as={Col} className="topSwitch">
              <Form.Check type="switch" id="act" label="Status" checked={state.isActive} value={state.isActive}
                onChange={handleChangeStatus}
                custom className="ml-0" />
            </Form.Group>

            { !state.enableView && !state.enablebtn &&
              <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleReset}
            > Reset</Button>}
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleCancel}
            > Back</Button>

            {/* Save Button */}
            {context.logindata.roles.split(",").includes('C_R_V_M') &&
             !state.enableView &&!state.enablebtn && (
              <Button
                size="xs"
                variant="outline-primary"
                type="submit"
                onClick={save}
              >Save</Button>)}

            {/* Update Button */}
            {context.logindata.roles.split(",").includes('U_R_V_M') &&
            state.enablebtn&& !state.enableView && (
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={update}
            >Update</Button>)
            }

          </div>

    </>
  );
}
export default RevenuMain;
