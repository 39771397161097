
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import FilterLoader from './FilterLoader';
import ReturnHeaderLoader from './ReturnHeaderLoader';
//Main Function
function ResultPageLoader(props) {
    const [state, setState] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>

            <Row>
                <Col className="filterLayout">
                    <FilterLoader triptype={props.triptype} />
                </Col>
                <Col className="resultSection ">


                        <div className='sliderLoader'>

                        </div>


                    <div class="sort">
                        <div class="sortByValue">

                            <ul>
                                <li class="active"><a href=""><span class="sortLabel ezyIcon icon-cheapest"> Lowest</span><span class="sortPrice m-0"><strong><div class="priceLoader"></div></strong></span></a></li>
                                {/* <li><a href=""><span class="sortLabel"><Icon className="hotel" size={20} icon="fastest" /> Fastest</span><span class="sortPrice m-0"><strong><div class="priceLoader"></div></strong></span></a></li> */}
                                {/* <li><a href=""><span class="sortLabel"><Icon className="hotel" size={16} icon="bestValue" /> Recommended </span><span class="sortPrice m-0"><strong><div class="priceLoader"></div></strong></span></a></li> */}
                            </ul>

                            {/* <div class="sortRange">

                                <a href=""><span class="sortPrice">Sort</span>
                                   <span className="ezyIcon icon-fromTo"></span>
                                </a>

                                <div class="sortBy inactive">
                                    <ul class="b-flex">
                                        <li><a href="">Departure Time</a></li>
                                        <li><a href="">Arrival Time</a></li>
                                        <li><a href="">Trip Duration</a></li>
                                        <li><a href="">Price</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        {/* {props.triptype === 2 &&
                            <div class="gridStyle d-flex">
                                <div class="sortRange list"><a class="" href=""><span class="sortPrice"></span><span className="ezyIcon icon-list"></span></a></div>
                                <div class="sortRange box"><a class="active" href=""><span class="sortPrice"></span> <span className="ezyIcon icon-box"></span></a></div>
                            </div>
                        } */}

                    </div>
                    <div className="result listView  boxShadow d-flex flex-column mb-4 ">
                        <div className="rs-top d-flex justify-content-between align-items-center">
                            <div className="f-flex">
                                <div className="rs-cheapest ezyIcon icon-cheapest"> Lowest</div>
                            </div>
                            <div className="rs-price d-flex">
                                <div className="blockPrice d-flex flex-column align-items-end mr-3 width140">
                                    <div className="stopsLoader2"></div>
                                </div>
                                <Button
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    variant="primary">
                                    SELECT
                        {/* <span className="ezyIcon icon-rightArrow"></span> */}
                                </Button>
                            </div>
                        </div>

                      {props.triptype === 2 ? <Row>
                            <Col className="flightBlock">
                                <Row>
                                    <Col xs={6}>
                                    <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight">{props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                    <Col xs={6}>
                                    {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                </Row>



                            </Col>

                        </Row> :
                        <Row>
                            <Col className="flightBlock">
                                <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight"> {props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>}
                    </div>
                    <div className="result listView  boxShadow d-flex flex-column mb-4 ">
                        <div className="rs-top d-flex justify-content-between align-items-center">
                            <div className="f-flex">
                            </div>
                            <div className="rs-price d-flex">
                                <div className="blockPrice d-flex flex-column align-items-end mr-3 width140">
                                    <div className="stopsLoader2"></div>
                                </div>
                                <Button
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    variant="primary">
                                    SELECT
                                    <span className="ezyIcon icon-rightArrow"></span>
                                </Button>
                            </div>
                        </div>
                        {props.triptype === 2 ? <Row>
                            <Col className="flightBlock">
                                <Row>
                                    <Col xs={6}>
                                    <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight"> {props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"> <span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                    <Col xs={6}>
                                    {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                </Row>



                            </Col>

                        </Row> :
                        <Row>
                            <Col className="flightBlock">
                                <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight"> {props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>}
                    </div>
                    {props.triptype === 2 && <div className="result listView  boxShadow d-flex flex-column mb-4 ">
                        <div className="rs-top d-flex justify-content-between align-items-center">
                            <div className="f-flex">
                            </div>
                            <div className="rs-price d-flex">
                                <div className="blockPrice d-flex flex-column align-items-end mr-3 width140">
                                    <div className="stopsLoader2"></div>
                                </div>
                                <Button
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    variant="primary">
                                    SELECT
                                    <span className="ezyIcon icon-rightArrow"></span>
                                </Button>
                            </div>
                        </div>
                        {props.triptype === 2 ? <Row>
                            <Col className="flightBlock">
                                <Row>
                                    <Col xs={6}>
                                    <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight"> {props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                    <Col xs={6}>
                                    {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </Col>
                                </Row>



                            </Col>

                        </Row> :
                        <Row>
                            <Col className="flightBlock">
                                <div className="rs-headerBlock mt-3">
                                    <Row className="align-items-center rs-row">
                                        <Col xs={4} className="rs-rTypeDate">
                                            <span className="departure ezyIcon icon-flight"> {props.triptype === 3 ? <div className="priceLoader"></div>:'Departure'}</span>
                                            <div class="fltDtLoader"></div>
                                        </Col>
                                        <Col xs={8} className="rs-FromTo">
                                            <Row className="align-items-center">
                                                <Col xs={5} className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                                <Col xs="1" className="iconFlight pl-0"><span className="ezyIcon icon-flight"></span></Col>
                                                <Col className="d-flex flex-column">
                                                    <div class="depArvLoader"></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.triptype === 2 &&
                                    <ReturnHeaderLoader />}
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rBlocks">
                                    <div className="rs-block">
                                        <div class="fltLegLoad mt-2"></div>
                                        <div className="rsb-bottom d-flex justify-content-between align-items-center pl-3">
                                            <div className="flightAmenities d-flex">
                                                <div className="width140"><div className="flightAmenities"></div></div>
                                            </div>
                                            <div className="flightDetailsLink">
                                                Flight Details  <span className="ezyIcon icon-arrowDown"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                        </Row>}
                    </div>}

                </Col>

            </Row>



        </>
    )
}


export default ResultPageLoader;
