import React, { Component, useState, useContext } from 'react';


import Tabs from '../../../common/tabs/Tabs';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import MainMenu from '../../../common/MainMenu';
import Footer from '../../../common/Footer'
import { Context } from "../../../../../App";
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import NotificationMaster from './NotificationMaster';
import NotificationAccess from './NotificationAccess';

const Notification = (props) => {
    const content = {
        NotificationMasterTab: <NotificationMaster />,
        NotificationAccessTab: <NotificationAccess />,

      };
   const [tab, setTab] = useState({
    active: 'NotificationMasterTab'
  })
    const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
    return(
        <>
            <MainMenu active='Air Services' />
            <Breadcrumb activePage="Notification" />
            <div className="container">
                <div className="cardMainlayout pt-0">
                    <AirServiceConfigMenu active="Notification" />
                    <div className="submenu">
                        <div className="arrowTab tabLink notification-tablink">
                            <Tabs active={tab.active} onChange={active => setTab({ active })}>
                                <span key="NotificationMasterTab">Notification Master</span>
                                <span key="NotificationAccessTab">Notification Access</span>
                            </Tabs>
                            <div className="tabContent">
                                {content[tab.active]}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="cardMainlayout pt-0">
                    <div className="submenu">
                        <ul>
                            {context.logindata.utype == "att_user" && (
                                <li className={props.active === "Notification Master" ? 'active' : ''}>
                                <Link
                                    to="/ezytrip/administration/notificationMaster"
                                    title="Notification Master">
                                    Notification Master
                                </Link>
                                </li>)
                            }
                            {context.logindata.utype == "att_user" && (
                                <li className={props.active === "Notification Access" ? 'active' : ''}>
                                    <Link
                                        to="/ezytrip/administration/notificationAccess"
                                        title="Notification Access">
                                        Notification Access
                                    </Link>
                                </li>
                            )}

                        </ul>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default Notification;