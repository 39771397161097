import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { characterNumberValidation, characterValidation, emailValidation, mobileNumberValidation } from '../../../commonUtils/FormValidations';
import ContactInfo from '../../commonContactInfo/index';
import { cities, branchCountries, currencies, stdCodes } from '../../utils';
import { BranchContext } from './BranchMain';
import { creation, update } from './operations';
export const ContactContext = React.createContext();
export const COUNTRY_DATA = "COUNTRY_DATA";
let countryMaster = require("../../../masterData/CountryMaster.json")

/**
 * @description:This function will creaet Branches and this will provide the ADD,EDIT and Remove functionalities
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id: undefined, bc: '', bn: '', cur: '', beml: '', bphc: '', bph: '', cntnm: '', ctynm: '', messages: [], showMsg: false,
    bgeol: '', badd: '', lm: '', zc: '', cnt: '', cty: '', url: '', act: true, enbaleUpdate: false, enbaleView: false,
    loadOfice: false, bdes: '', countriesMenu: [], citiesMenu: [], contacts: [], res: [], clearChildData: false,
    currencyMenu: [], stdCodeMenu: stdCodes(), selPhone: [], selCountry: [], selCity: [], selCurrency: [], crby: '',
    cityError: '', countryError: '', countryCodeError: "", currencyError: "", upby: "", nameError: false, emailError: false, noError: false,
    bcError: false, contactValidation: {}, saveContacts: false, reset: false, notiMessageShow: false,bclError:false, isItineraryLoading:false
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, id: undefined, bc: '', bn: '', cur: '', beml: '', bphc: '', bph: '', cntnm: '', ctynm: '', messages: [], showMsg: false,
                bgeol: '', badd: '', lm: '', zc: '', cnt: '', cty: '', url: '', act: true, enbaleUpdate: false, enbaleView: false,
                loadOfice: false, bdes: '', contacts: [], res: [], clearChildData: false, selPhone: [], selCountry: [], selCity: [], selCurrency: [],
                crby: '', cityError: '', countryError: '', countryCodeError: "", currencyError: "", upby: "", nameError: false, emailError: false,
                noError: false, bcError: false, saveContacts: false, reset: true, notiMessageShow: false,bclError:false,isItineraryLoading:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//=====================================
// Main Function Started Here
//=====================================

function BranchCreation(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [validated, setValidated] = useState(false);
    const comapnyObj = useContext(BranchContext);
    const [disableButton, setDisableButton] = useState();
    const [errors, setErrors] = useState({});
    const countrySelect = useRef(null);
    const citySelect = useRef(null);
    const [resetChild, setresetChild] = useState(false)
    const [isActive, setisActive] = useState(true)

     const stdCodesMaster = require('../../../masterData/CountrySTDCodes.json');

    // When ever Function Called this hook will render.
    useEffect(() => {
        // branchCountries().then((res) => {
            let res = JSON.parse(localStorage.getItem(COUNTRY_DATA))
            // let res = [];
            // data.forEach((element)=>{
            //     res.push({"label":element.con_name, "value":element.con_id, "cur":element.cur})
            // })
            dispatch({ type: 'countriesMenu', payload: res })
            //For Edit Country
            if (comapnyObj !== undefined && comapnyObj.res !== undefined && comapnyObj.res.cnt !== undefined) {
                res.map((con) => {
                    if (con.value === comapnyObj.res.cnt) {
                        dispatch({ type: 'selCountry', payload: [con] })
                    }
                });
            }
        // });
    }, []);

    // When ever enbleUpdate prop Changed this hook will render.
    useEffect(() => {
        if (props.enbleUpdate) {
            setDisableButton(true);
        }
    }, [props.enbleUpdate]);

    // When ever comapnyObj prop Changed this hook will render.
    useEffect(() => {
        if (comapnyObj !== undefined && comapnyObj.res !== undefined && !props.create) {
            editBranch();
        } else if (props.create) {
            dispatch({ type: 'clear', payload: true })
            setValidated(false)
            setresetChild(true)
        }
    }, [comapnyObj !== undefined, props.create]);

    useEffect(() => {
        if (state.contacts.length > 0) {
            setresetChild(false)
        }
    })

     const onSearch = ({ props, state, methods }) => {
        //let codes = filterCountryCodes(state.search);

        let m = methods
            .sortBy()
            .filter((item) => {

                if (item.label.startsWith(state.search)) {
                    return item;
                }
            }
            );
      return m;

        // const regexp = new RegExp(methods.safeString(state.search), 'i');
      };
     /*  const onSearch = ({ props, state, methods }) => {
        console.log({ props, state, methods });

        const regexp = new RegExp(methods.safeString(state.search), 'i');
        return methods
          .sortBy()
          .filter((item) =>
            regexp.test(getByPath(item, props.searchBy) || getByPath(item, props.valueField))
          );
      };

       const getByPath = (object, path) => {
          console.log('path...........',path)
          console.log('object...........',object)
        if (!path) {
          return;
        }

        return object.label.startsWith(state.search);
      }; */


    //Edit Branch
    const editBranch = () => {
        dispatch({ type: 'editBranch', payload: comapnyObj.res })
        dispatch({ type: 'upby', payload: context.logindata.uid })
        dispatch({ type: 'act', payload: comapnyObj.res.act === 1 ? true : false })
        if (comapnyObj.res.act === 1) {
            setisActive(true)
        } else {
            setisActive(false)
        }
        Object.assign(state.contacts, comapnyObj.res.ccinfo);
        dispatch({ type: 'contacts', payload: comapnyObj.res.ccinfo })
        dispatch({ type: 'enbaleUpdate', payload: true })
        dispatch({ type: 'badd', payload: (comapnyObj.res.badd ? comapnyObj.res.badd : '') })
        for (const cur of currencies()) {
            if (cur.value === comapnyObj.res.cur) {
                dispatch({ type: 'selCurrency', payload: [cur] })
                break;
            }
        }
        if(comapnyObj.res.cnt===196){
            dispatch({ type: 'selCountry', payload: [{value:196,label:"India"}] })
        }else{
            dispatch({ type: 'selCountry', payload: [{value:comapnyObj.res.cnt,label:"Qatar"}] })
        }
        
        
        /*   currencies().map((cur) => {
              console.log('in map..........')
              if (cur.value === comapnyObj.res.cur) {
                  dispatch({ type: 'selCurrency', payload: [cur] })
              }
          }); */
        /*    stdCodes().map((code) => {
               if (code.value === comapnyObj.res.bphc) {
                   dispatch({ type: 'selPhone', payload: [code] })
               }
           }); */
        for (const code of stdCodesMaster) {
            if (code.code === comapnyObj.res.bphc) {
                let obj = {
                    "label": code.name,
                    "value": code.name
                }
                dispatch({ type: 'selPhone', payload: [obj] });
                break;
            }
        }
        cities(comapnyObj.res.cnt).then((resp) => {
            for (const city of resp) {
                if (city.value === comapnyObj.res.cty) {
                    dispatch({ type: 'selCity', payload: [city] })
                    break;
                }
            }
            dispatch({ type: 'citiesMenu', payload: resp });
            /*  resp.map((city) => {
                 if (city.value === comapnyObj.res.cty) {
                     dispatch({ type: 'selCity', payload: [city] })
                 }
             });
             dispatch({ type: 'citiesMenu', payload: resp }); */
        })
    }
    /**
     * @description: to remove all multiples in array of objects
     * @author : Azamuddin
     * @date : 03-11-2020
     */

    function getUnique(arr, comp) {

        // store the comparison  values in array
        const unique = arr.map(e => e[comp])

            // store the indexes of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the false indexes & return unique objects
            .filter((e) => arr[e]).map(e => arr[e]);

        return unique;
    }

    // OnChange event we will call this and update the state
    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === 'act') {
            if (isActive) {
                setisActive(false)
                dispatch({ type: 'act', payload: false })
            } else {
                setisActive(true)
                dispatch({ type: 'act', payload: true })
            }
        }
        if (id === 'bc') {
            dispatch({ type: 'clearChildData', payload: false });
            dispatch({ type: "bcError", payload: false })
            dispatch({ type: id, payload: value });
            if(value.length > 7 && value.length < 17) {
                dispatch({type:"bclError",payload: false})
            } else {
                dispatch({type:"bclError",payload: true})
            }
        }
        else {
            if (id === 'bn') {
                dispatch({ type: "nameError", payload: false })
            }
            if (id === 'beml') {
                dispatch({ type: "emailError", payload: false })
            }
            dispatch({ type: id, payload: value });
        }
    }

    const handleCountryChange = (e) => {
        dispatch({ type: 'selCity', payload: [] })
        if (e.length > 0 && !state.enbaleUpdate) {
            dispatch({ type: 'cntnm', payload: e[0].label })
            dispatch({ type: 'selCountry', payload: e[0].label })
            dispatch({ type: 'countryError', payload: '' })
            let list=countryMaster.filter(obj=>obj.label===e[0].label);
            dispatch({ type: 'currencyMenu', payload: [{"value":list[0].cur,"label":list[0].cur}]})
            dispatch({ type: 'selCurrency', payload:  [{"value":list[0].cur,"label":list[0].cur}]})
            dispatch({ type: 'cur', payload: list[0].cur })
            dispatch({ type: 'cnt', payload: list[0].value })
            citiesFetch(list[0].value);
        }
    }

    const handleCityChange = (e) => {
        if (e.length > 0) {
            dispatch({ type: 'cty', payload: e[0].value })
            dispatch({ type: 'ctynm', payload: e[0].label })
            dispatch({ type: 'cityError', payload: '' })
            dispatch({ type: 'selCity', payload: e })
        }
    }

    //On Cancel Click,redirects to Admin page
    const handleCancel = (e) => {
        return window.location = "/ezytrip/administration/branch"
    }
    //Contact Info Data
    const contactData = useCallback((data) => {
        dispatch({ type: 'contacts', payload: data })
    }, [state.contacts]);



    //reset the whole response

    const resetValues = (e) => {
        dispatch({ type: 'clear', payload: true })
        setValidated(false)
        setresetChild(true)
    }

    //contact child Validations
    const checkContactsValidations = (data) => {
        let email = false
        let nm = false
        let no = false
        let cph = false
        let emailEmpty = false
        let nameEmpty = false
        let noEmpty = false
        let design = false
        for (let each of data) {

            if (each.cem == "") {
                emailEmpty = true
            }
            if (each.cn == "") {
                nameEmpty = true
            }
            if (each.cph == "") {
                noEmpty = true
            }
            if (each.cdesig == "") {
                design = true
            }
            if (each.mcode == "") {
                cph = true
            }
            if (data.length > 0) {
                if (emailValidation(each.cem) == false) {
                    email = true
                }
                if (characterValidation(each.cn) == false) {
                    nm = true
                }
                if (mobileNumberValidation(each.cph) == false) {
                    no = true
                }

            }
        }
        if (data.length === 0) {
            cph = true
        }
        //send prop for child data
        let obj = {
            "email": email,
            "nm": nm,
            "no": no,
            "cph": cph
        }
        dispatch({ type: "contactValidation", payload: obj })
        if (!cph && !email && !no && !nm && !emailEmpty && !nameEmpty && !noEmpty && !design) {
            dispatch({ type: "saveContacts", payload: true })
            return true
        } else {
            return false
        }
    }
    //Add Branch
    const addBranch = (event) => {
        const form = document.getElementById('formId');
        let conditionCheck = false
        let branchnamevalidations = false
        let emailvalidations = false
        let numberValidations = false
        let bcValidations = false


        //name validations
        if (state.bn.length !== 0) {
            if (characterValidation(state.bn)) {
                branchnamevalidations = true
                dispatch({ type: "nameError", payload: false })
            } else {
                dispatch({ type: "nameError", payload: true })
            }
        }
        //email validations
        if (state.beml.length !== 0) {
            if (emailValidation(state.beml)) {
                emailvalidations = true
                dispatch({ type: "emailError", payload: false })
            } else {

                dispatch({ type: "emailError", payload: true })
            }
        }
        //number Validations
        if (state.bph.length !== 0) {
            if (mobileNumberValidation(state.bph)) {
                numberValidations = true
                dispatch({ type: "noError", payload: false })
            } else {
                dispatch({ type: "noError", payload: true })
            }
        }
        if (state.bc.length !== 0) {
            if (characterNumberValidation(state.bc)) {
                dispatch({ type: "bcError", payload: false })
                if(state.bc.length > 7 && state.bc.length < 17) {
                    bcValidations = true
                    dispatch({type:"bclError",payload:false})
                } else {
                    dispatch({type:"bclError",payload:true})
                }

            } else {
                dispatch({ type: "bcError", payload: true })
            }
        }
        //validate select country dropdown
        if (state.cnt === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'countryError', payload: 'Please Select Country' })
        }

        //validate select city dropdown
        if (state.cty === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'cityError', payload: 'Please Select City' })
        }

        //validate select countryCode dropdown
        if (state.bphc === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'countryCodeError', payload: 'Requireddd' })
        }

        //validate select currency dropdown
        if (state.cur === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'currencyError', payload: 'Requireddd' })
        }

        //checking form validation
        if (form.checkValidity() === false) {
            // if(state.contacts.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            // }
        }


        //check all the mandatory fields
        if (state.bn !== "" && state.bc !== "" && state.bph !== "" && state.beml !== "" && state.cur !== "" && state.bphc !== "" &&
            state.bgeol !== "" && state.cntnm !== "" && state.ctynm !== "" && state.contacts.length > 0 && !state.bclError) {
            conditionCheck = true
        }
        let contactsValidations = checkContactsValidations(state.contacts)
        if (branchnamevalidations && emailvalidations && numberValidations && bcValidations && conditionCheck && contactsValidations) {
            dispatch ({type:"isItineraryLoading",payload:true})
            creation(state, context.logindata.uid, isActive).then((addRes) => {
                if (addRes.suc) {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Branch saved Successfully' })
                    dispatch({ type: 'notiVarient', payload: 'success' });
                    hidemessage()
                    // dispatch({ type: 'clear', payload: true })
                    dispatch({ type: 'showMsg', payload: true })
                    dispatch({ type: 'messages', payload: ['succ'] })
                    dispatch({ type: 'clearChildData', payload: true })
                    return window.location = '/ezytrip/administration/branch'
                } else if (addRes.err) {
                    dispatch ({type:"isItineraryLoading",payload:false})
                    if (addRes.err.includes("Duplicate")) {
                        dispatch({ type: 'notiMessageShow', payload: true })
                        dispatch({ type: 'notiMessage', payload: 'Email Already Exists' })
                        dispatch({ type: 'notiVarient', payload: 'danger' });
                        hidemessage()
                    }
                }
            });
        }
    }
    //Update Branch
    const updateBranch = (event) => {

        const form = document.getElementById('formId');
        let conditionCheck = false
        let branchnamevalidations = false
        let emailvalidations = false
        let numberValidations = false
        let bcValidations = false


        //name validations
        if (state.bn.length !== 0) {
            if (characterValidation(state.bn)) {
                branchnamevalidations = true
                dispatch({ type: "nameError", payload: false })
            } else {
                dispatch({ type: "nameError", payload: true })
            }
        }
        //email validations
        if (state.beml.length !== 0) {
            if (emailValidation(state.beml)) {
                emailvalidations = true
                dispatch({ type: "emailError", payload: false })
            } else {
                dispatch({ type: "emailError", payload: true })
            }
        }
        //number Validations
        if (state.bph.length !== 0) {
            if (mobileNumberValidation(state.bph)) {
                numberValidations = true
                dispatch({ type: "noError", payload: false })
            } else {
                dispatch({ type: "noError", payload: true })
            }
        }
        if (state.bc.length !== 0) {
            if (characterNumberValidation(state.bc)) {
                if(state.bc.length > 7 && state.bc.length < 17) {
                    bcValidations = true
                    dispatch({type:"bclError",payload:false})
                } else {
                    dispatch({type:"bclError",payload:true})
                }
                dispatch({ type: "bcError", payload: false })
            } else {
                dispatch({ type: "bcError", payload: true })
            }
        }
        //validate select country dropdown
        if (state.cnt === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'countryError', payload: 'Please Select Country' })
        }

        //validate select city dropdown
        if (state.cty === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'cityError', payload: 'Please Select City' })
        }

        //validate select countryCode dropdown
        if (state.bphc === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'countryCodeError', payload: 'Requireddd' })
        }

        //validate select currency dropdown
        if (state.cur === "") {
            event.preventDefault();
            event.stopPropagation();
            dispatch({ type: 'currencyError', payload: 'Requireddd' })
        }

        //checking form validation
        if (form.checkValidity() === false) {
            // if(state.contacts.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            // }
        }


        //check all the mandatory fields
        if (state.bn !== "" && state.bc !== "" && state.bph !== "" && state.beml !== "" && state.cur !== "" && state.bphc !== "" &&
            state.bgeol !== "" && state.cntnm !== "" && state.ctynm !== "" && state.contacts.length > 0 && !state.bclError) {
            conditionCheck = true
        }
        let contactsValidations = checkContactsValidations(state.contacts)
        dispatch({ type: 'upby', payload: context.logindata.uid })
        if (branchnamevalidations && emailvalidations && numberValidations && bcValidations && conditionCheck && contactsValidations) {
            dispatch ({type:"isItineraryLoading",payload:true})
            update(state, isActive).then((updateRes) => {
                let updtedBranch = state.res.map(
                    (obj, sidx) => {
                        if (obj.id !== state.id) return obj;
                        return {
                            ...obj,
                            id: state.id,
                            bn: state.bn,
                            badd: state.badd,
                            lm: state.lm,
                            zc: state.zc,
                            cur: state.cur,
                            bdes: state.bdes,
                            cnt: state.cnt,
                            cty: state.cty,
                            act: state.act,
                            bc: state.bc,
                            beml: state.beml,
                            bph: state.bph,
                            bphc: state.bphc,
                            ccinfo: state.contacts,
                            upby: state.upby
                        };
                    }
                );
                dispatch({ type: 'res', payload: updtedBranch })
                if (updateRes.suc) {
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Branch updated successfully' })
                    dispatch({ type: 'notiVarient', payload: 'success' });
                    hidemessage()
                    // dispatch({ type: 'clear', payload: true })
                    dispatch({ type: 'showMsg', payload: true })
                    dispatch({ type: 'messages', payload: ['succ'] })
                    dispatch({ type: 'clearChildData', payload: true })
                    dispatch({ type: 'enbaleUpdate', payload: false })
                    dispatch({ type: 'clearChildData', payload: false })
                    return window.location = "/ezytrip/administration/branch";
                }
            });
            dispatch ({type:"isItineraryLoading",payload:false})
        }
    }

    //Cities fetch based on country id
    const citiesFetch = (id) => {
        cities(id).then((res) => {
            let data = getUnique(res, "label")
            dispatch({ type: 'citiesMenu', payload: data });
        })
    }

    const handleCurrency = (obj) => {
        if (obj.length > 0) {
            dispatch({ type: 'cur', payload: obj[0].value })
            dispatch({ type: 'currencyError', payload: "" })
            dispatch({ type: "selCurrency", payload: obj })
        }
    }
    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const handleCountryCode = (e) => {
        if (e.length > 0) {
            if(e[0].value !== "Select") {
            let bhpcSplit = e[0].value.split("(")
            let bhpc = bhpcSplit[1].split(")")
            dispatch({ type: 'bphc', payload: bhpc[0] })
            dispatch({ type: 'countryCodeError', payload: "" })
            dispatch({ type: "selPhone", payload: e })
            }
        }
    }
    //===================
    // Template Part
    //===================

    return (
        <>
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
               onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>
            <>
            
               {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
                <Form noValidate validated={validated} id="formId">

                    <Form.Row>

                        <Form.Group as={Col} xs={3} controlId="bc">
                            <Form.Control type="text" maxLength="16" required placeholder="Enter Office Name" autoComplete="off" value={state.bc} onChange={handleChange} />
                            <Form.Label>Branch Code <sup>*</sup></Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide Branch Code.
                                    </Form.Control.Feedback>
                            {state.bcError && (
                                <p className="vError">Please Enter A Valid Branch Code</p>
                            )}
                            {state.bclError && (
                                <p className="vError">Branch Code length should be in between 8 to 16</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="bn">
                            <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.bn} onChange={handleChange} />
                            <Form.Label>Branch Name <sup>*</sup></Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide Branch Name.
                                    </Form.Control.Feedback>
                            {state.nameError && (
                                <p className="vError">Please Enter Valid Name</p>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="beml">
                            <Form.Control type="text" required placeholder="Enter Address" value={state.beml} onChange={handleChange} />
                            <Form.Label>Email <sup>*</sup></Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide Email.
                                    </Form.Control.Feedback>
                            {state.emailError && (
                                <p className="vError">Please Enter Valid Email</p>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="bph">
                            <Form.Control type="text" required placeholder="Enter Landmark" value={state.bph} onChange={handleChange} />
                            <Form.Label>Contact Number <sup>*</sup></Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide Contact Number.
                                    </Form.Control.Feedback>
                            {state.noError && (
                                <p className="vError">Please provide valid Contact Number</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="bgeol">
                            <Form.Control type="text" required placeholder="Enter Zipcode" value={state.bgeol} onChange={handleChange} />
                            <Form.Label>GEO Location <sup>*</sup></Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide GEO Location.
                                    </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="badd">
                            <Form.Control type="text" placeholder="Enter Address" value={state.badd} onChange={handleChange} />
                            <Form.Label>Address</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="lm">
                            <Form.Control type="text" placeholder="Enter Landmark" value={state.lm} onChange={handleChange} />
                            <Form.Label>Landmark</Form.Label>
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="zc">
                            <Form.Control type="text" placeholder="Enter Zipcode" value={state.zc} onChange={handleChange} />
                            <Form.Label>Zip code</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.countryCodeError && "error")} controlId="bphc">
                        {/* <Typeahead
                          id="basic-typeahead-single"
                         labelKey="name"
                         onChange={handleCountryCode}
                         options={state.stdCodeMenu}
                         placeholder="Choose Country.."
                         selected={state.selPhone}
                      /> */}
                            <Select
                                options={state.stdCodeMenu}
                                values={state.selPhone}
                              //  searchFn={onSearch}
                              //  searchable
                            //   clearable
                                onChange={(value) => (handleCountryCode(value))}
                            />
                            <Form.Label>Country code <sup>*</sup></Form.Label>
                            {state.countryCodeError && (
                                <p className="vError">Please Select a Country Code</p>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.countryError && "error")} controlId="cnt">
                            <Select
                                options={state.countriesMenu}
                                searchable={true}
                                values={state.selCountry}
                                onChange={value => (handleCountryChange(value))}
                                ref={countrySelect}
                                disabled={state.enbaleUpdate}
                            />
                            <Form.Label>Country(Region) <sup>*</sup></Form.Label>
                            {state.countryError && (
                                <p className="vError">Please Select a Country</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.cityError && "error")} controlId="cty">
                            <Select
                                options={state.citiesMenu}
                                searchable={true}
                                values={state.selCity}
                                onChange={value => (handleCityChange(value))}
                                ref={citySelect}
                            />
                            <Form.Label>City <sup>*</sup></Form.Label>
                            {state.cityError && (
                                <p className="vError">Please Select a City</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} className={"selectplugin " + (state.currencyError && "error")} controlId="cur">
                            <Select
                                options={state.currencyMenu}
                                searchable={true}
                                values={state.selCurrency}
                                disabled={state.enbaleUpdate}
                                onChange={(value) => (handleCurrency(value))}
                            />
                            <Form.Label>Currency <sup>*</sup></Form.Label>
                            {state.currencyError && (
                                <p className="vError">Please Select a Currency</p>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} controlId="url">
                            <Form.Control type="text" placeholder="Enter URL" value={state.url} onChange={handleChange} />
                            <Form.Label>URL</Form.Label>
                        </Form.Group>

                        <Form.Group as={Col} xs={3} controlId="bdes">
                            <Form.Control as="textarea" rows="2" placeholder="Enter Description" value={state.bdes} onChange={handleChange} />
                            <Form.Label>Description</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <ContactInfo sendData={contactData} parentCall={state.clearChildData} contactsList={state.contacts} validation={validated} resetData={resetChild} intilaReset={state.reset} checkValidations={state.contactValidation} type='branch' />
                </Form>
                <div className="switchPlus buttonGrop pb-0">
                    <Form.Group as={Col} className="topSwitch" controlId="act">
                        <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={isActive} value={isActive} onChange={handleChange}
                        />
                    </Form.Group>

                    {!state.enbaleView &&
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={handleCancel}
                            type="submit"
                            loading={state.loadOfice}
                            isDisabled={state.enbaleView}
                        > Back</Button>
                    }
                    {!state.enbaleView &&
                        <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={resetValues}
                            type="submit"
                            // loading={state.loadOfice}
                            isDisabled={state.enbaleView}
                        > Reset</Button>
                    }
                    {!state.enbaleUpdate && (
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={addBranch}
                            loading={state.loadOfice}
                            type="submit"
                            isDisabled={context.logindata.roles !== undefined && !context.logindata.roles.includes('B_C_C') ? true : false}
                        > Add Branch</Button>
                    )}
                    {state.enbaleUpdate && !disableButton && (
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={updateBranch}
                            loading={state.loadOfice}
                            type="submit"
                        > Update Branch</Button>
                    )}
                    {state.enbaleUpdate && disableButton &&
                        <Button
                            size="xs"
                            variant="outline-primary"
                            loading={state.loadBranch}
                            isDisabled={true}
                            type="submit"
                        > Update Branch</Button>
                    }

                </div>

            </>

        </>
    )
}
export default BranchCreation