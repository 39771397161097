import axios from 'axios'

const REACT_APP_B2B = process.env.REACT_APP_B2B_SEARCH
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Exchange rate
 * @param {*} Service 
 */

class CustomerRequestService {
   
    async getAllCustRequest() {
        return await axios.get(`${REACT_APP_B2B}/postTicket/getRequests`)
    }
    }
    
export default new CustomerRequestService()