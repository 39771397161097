import React, { useState } from 'react';
import { Col, Row, Form, Modal, Button, Table } from "react-bootstrap";


//This function will show the Air markups List and Add Markup
function PolicyDetails(props) {


  return (
    <>
        <div className="resultSection boxShadow bg-white summary mb-4 policyMore">

<div className='table-responsive'>
        <Table>
<tr>
  <td> <span>No. Of pax:</span> <span className='bookNum'>{props.policy.no_ofpax}</span></td>
  <td><span>Email Id : </span> <span className='bookNum'>{props.policy.email}</span></td>
  <td><span>Phone#: </span> <span className='bookNum'>{props.policy.phone_no}</span></td>
  <td>  <span>Policy Number:</span> <span className='bookNum'>{props.policy.policy_no}</span></td>
</tr>
<tr>
  <td>  <span>Departure Date:</span> <span className='bookNum'>{props.policy.depart_date}</span></td>
  <td> <span>Return Date: </span> <span className='bookNum'>{props.policy.return_date}</span></td>
  <td><span>Coverage Starts:  </span><span className='bookNum'>{props.policy.cover_start_date}</span></td>
  <td>   <span>Coverage Ends:  </span><span className='bookNum'>{props.policy.cover_end_date}</span></td>
</tr>
<tr>
  <td> <span>Coverage Duration:</span> <span className='bookNum'>{props.policy.cover_dur}</span></td>
  <td>  <span>Travel Duration:</span> <span className='bookNum'>{props.policy.travel_dur}</span></td>
  <td>&nbsp;</td>
  <td>&nbsp;</td>
</tr>

</Table>
</div>
          {/* <Row className="pb-2 ">
       <Col>
       <span>No. Of pax:</span> <span>{props.policy.no_ofpax}</span>
       </Col>
       <Col>
       <span>Email Id : </span> <span>{props.policy.email}</span>
       </Col>
       <Col>
       <span>Phone#: </span> <span>{props.policy.phone_no}</span>
       </Col>

       <Col>
       <span>Policy Number:</span> <span>{props.policy.policy_no}</span>
       </Col>
          </Row>
        <Row className="pb-2 ">
       <Col>
       <span>Departure Date:</span> <span>{props.policy.depart_date}</span>
       </Col>
       <Col>
       <span>Return Date: </span> <span>{props.policy.return_date}</span>
       </Col>
       <Col>
       <span>Coverage Starts:  </span><span>{props.policy.cover_start_date}</span>
       </Col>
       <Col>
       <span>Coverage Ends:  </span><span>{props.policy.cover_end_date}</span>
       </Col>

        </Row>
        <Row className="pb-2 ">
       <Col>
       <span>Coverage Duration:</span> <span>{props.policy.cover_dur}</span>
       </Col>
       <Col>
       <span>Travel Duration:</span> <span>{props.policy.travel_dur}</span>
       </Col>
       <Col>

       </Col>
       <Col>

       </Col>
        </Row> */}
        </div>

    </>
  )
}
export default PolicyDetails