import axios from 'axios'

const API_URL = process.env.REACT_APP_PRE_BOOK_URL
/**
 * @author: Azamuddin
 * @description: Integrated the Markup CURD Operations for hotel service
 * @param {*} Service 
 */

class HotelConfigurationService {
    async saveServiceFee(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/servicefee/create`,data)  
      }

      async searchServiceFee(data) {
        return await axios.post(`${API_URL}/servicefee/get_service_fees`,data)  
      }
     async retrieveServiceFee() {
          return await axios.get(`${API_URL}/markup/retrieve`)
      }

     async editServiceFee(id) {
         return await axios.get(`${API_URL}/servicefee/edit/${id}`)
     } 

     async update(id,code) {
        return await axios({ method: "PUT", url: `${API_URL}/servicefee/update/${id}`, data: code }); 
     }
     
     async deleteapplicableConfig(id) {
         return await axios.delete(`${API_URL}/servicefee/deleteapplicableconfig/${id}`)
     }

     async deleteAppcableDate(id) {
        return await axios.delete(`${API_URL}/servicefee/deleteapplicabledates/${id}`)
    }

    async retriveFMDetails(id) {
    return await axios.get(`${API_URL}/servicefee/faremapedit/${id}`)
    }

    async deletePaxMarakup(id) {
        return await axios.delete(`${API_URL}/markup/paxmapdelete/${id}`)
    }
    
    async deleteLSid(id) {
        return await axios.delete(`${API_URL}/servicefee/longstaydelete/${id}`)
    }

    async updateFareMap(code) {
        console.log("code:::::",code)
        return await axios({ method: "PUT", url: `${API_URL}/servicefee/faremapupdate`, data: code.fmureq });
        // return await axios.put(`${API_URL}/servicefee/faremapupdate`,data.fmureq)
    }

    async deleteFareMap(id) {
        return await axios.delete(`${API_URL}/servicefee/faremapdelete/${id}`)
    }
}

export default new HotelConfigurationService()