

import React from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import { purchaseServicePostCall , checkPayment } from '../../services/payment/paymentService';
import { deductCreditLimit, fireBookReq, fireTicket } from '../bookingFlows/flight/review/operations';
import { confirmNomoreDlg } from '../common/sessionTimeOut/SessionNomore';
import Utilities from '../commonUtils/Utilities';
import Processing from '../common/processingPopUp/Processing';
import FormateCurrency from '../commonUtils/FormateCurrency';
import Paymentprocessing from '../../assets/images/Payment-Processing.gif'
import Naps from '../../assets/images/NAPSS.jpg'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from "./utils";

export const USER_AUTH_DATA = "userAuthData";
export const PAY_DATA = "payData";
export const PRODUCT_TYPE = "productType";
export const PAY_REQ = "payRequest";
export const PAY_RESP = "payResp";
export const PAY_DONE = "payDone";
export const GET_PAY_LINK= "PayLink";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";

let payment=false;
let isPaymentFailed=false;
class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //totalSum: this.props.location.state.res.cargoRes.prc,
      //currency: this.props.location.state.res.cargoRes.cur,
      errorMsgs: [],
      bookingStatus: 'payment-done',
     // email: this.props.location.state.res.senderEmail,
      errorExists: false,
      isLoading:false,
      pleasewait:false,
      name: '',
      number: '',
      expiry: '',
      cvc: '',
      focused: '',
      issuer: '',
      formData: '',
      surchrge:100,
      bookSuc:false,
      terms:false,notiMessage: '', notiMessageShow: false, notiVarient: '',
      showTerms:false,
      term:false,
      pnr:"",
      clBal:0,
      showCl:false,clCurrentBal:0,payAmount:0,overcl:false,showOverCl:false,showOver:false,
      cur:this.props.bdata.req_currency,
      isItineraryLoading:false,
      enablePayPopup:false,
      pay_type:"Credit Card",
    };

    var bookingData=JSON.parse(window.sessionStorage.getItem(PAY_DATA));
    var isPay=window.sessionStorage.getItem(PAY_DONE);
    if(isPay===null && bookingData!==undefined && bookingData!==null){
      payment=true;
      this.checkPaymentResp(bookingData);
    }else if(isPay!==null){
      isPaymentFailed=true;
      window.sessionStorage.removeItem(PAY_DATA);
    }
  }

  //Checking the Payment response and firing the Ticketing service
  checkPaymentResp=(bookingData)=>{
      var paylink=window.sessionStorage.getItem(GET_PAY_LINK);
      let pay_resp=window.sessionStorage.getItem(PAY_RESP);
      if(paylink!==null && paylink!=="" && pay_resp!==null){
      var payReq=window.sessionStorage.getItem(PAY_REQ);
      window.sessionStorage.removeItem(GET_PAY_LINK);
      checkPayment({"url":paylink}).then((resp) => {
      if(resp.data.suc){
        window.sessionStorage.removeItem(PAY_DATA);
        window.sessionStorage.removeItem(PAY_REQ);
        window.sessionStorage.removeItem(PAY_RESP);
        window.sessionStorage.removeItem(PAY_DONE);
        window.sessionStorage.removeItem(PRODUCT_TYPE);
        if(!bookingData.isTravelFusion){
          this.callTicketAfterPayment(bookingData,JSON.parse(payReq),resp.data);
        }else if(bookingData.isTravelFusion){
          this.callBookAfterPaymentLCC(bookingData,JSON.parse(payReq),resp.data);
        }
      }else{
        window.sessionStorage.removeItem(PAY_DATA)
        return window.location="/ezytrip/bookings/pay-fail";
      }
     });
    }
  }

 getUrlVars(url) {
    var hash;
    var myJson = {};
    var hashes = url.slice(url.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = hash[1];
        // If you want to get in native datatypes
        // myJson[hash[0]] = JSON.parse(hash[1]);
    }
    return myJson;
}


  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleChangeOcl = (e) => {
    const { id, value } = e.target;
    if(id == "cb"){
     if(this.state.term){
      this.setState({
        term: false
      });
     }else{
      this.setState({
        term: true
      });
     }
    }else{
      this.setState({ [e.target.name]: e.target.value });
    }
   }

   handleChange = (e) => {
    const { id, value } = e.target;
    if(id == "tc"){
     if(this.state.terms){
      this.setState({
        terms: false
      });
     }else{
      this.setState({
        terms: true
      });
     }
    }else{
      this.setState({ [e.target.name]: e.target.value });
    }
   }

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });

    // Crad type validation
    if (target.name === "number") {
      const x = target.value;
      const z = x.substring(0, 2);
      console.log(z);
      const numberFormated = target.value.replace(/\D/g, '')
      var patterns = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        AMEX: /^3[47][0-9]{13}$/,
        ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        DANKORT: /^(5019)\d+$/,
        INTERPAYMENT: /^(636)\d+$/,
        UNIONPAY: /^(62|88)\d+$/,
      }
      for (var key in patterns) {
        if (patterns[key].test(numberFormated)) {

          this.setState({
            issuer: key
          });
          return key
        }
      }
    }
  };

    confirmBooking=(e)=>{
     e.preventDefault();
     window.sessionStorage.removeItem(CHECK_BOOKING);
     window.sessionStorage.removeItem(BOOKING_REF_NO);
      if(this.state.terms){
      this.setState({
        isLoading : true,
        pleasewait:true
      })

    if(this.props.bdata.onhold==="yes"){
      //Calling Book and Ticketing both in below service if SUC the redirection to Confirmation other wise Booking filed
      this.callBookReqest(true,this.props.bdata.price,"yes","Confirmed",0);
     }else{
      if(this.props.bdata.butype==="sta_user"){
      this.convertCLObj(this.props.bdata,"deduct",false);
      var clBal=Math.round(Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub"));
      this.createCLPayload(clBal);
      }
      //Calling Book and Ticketing both in below service if SUC the redirection to Confirmation other wise Booking filed
      this.callBookReqest(true,this.props.bdata.price,"no","Confirmed",0);
     }
    }else{
      this.setState({
        notiMessageShow : true,
        notiMessage:"Please accept terms and conditions and proceed",
        notiVarient:'danger'
      })
      this.hidemessage()
      //setTimeout(this.setState.bind(this, {position:1}), 5000);
  }
  }

   convertCLObj=(data,type,partial)=>{
     var bal=null;
    if(data.butype==="sta_user"){
      var amt=Math.round(Utilities.addPrice(data.stamarkup,data.staservice,"Add"));
      let price=0
      if(partial){
       price=Utilities.addPrice(data.creditBal,amt,"Sub");
      }else{
       price=Utilities.addPrice(data.price,amt,"Sub");
      }
       //Deducting Parent CL
       const payload={
        "comid": data.cid,
        "method": type,
        "value":parseFloat(price),
        "pid":0,
        "pvalue":0,
        "cuscode":this.props.bdata.custCode
       }
       bal=this.deductCL(payload);
     }
     return bal;
  }

//=====================================
// Updating CL to Local storage
//=====================================
  createCLPayload=(price)=>{
    var userData = localStorage.getItem(USER_AUTH_DATA);
    var resObj=JSON.parse(userData);
    resObj.cbal=price;
    localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
  }

//=====================================
// Processing the Booking based on Payment
//=====================================
 confirmPayment= async (e)=>{
  e.preventDefault();
  window.sessionStorage.removeItem(CHECK_BOOKING);
  window.sessionStorage.removeItem(BOOKING_REF_NO);
  if(this.state.terms) {
  this.setState({
    isLoading : true,
    pleasewait:true,
    payAmount:0,
    clCurrentBal:0
  })
  var status="";
  //If it is Partial flow then deduction CL balance other wise it will go online
    var clPrice=0;
    var payPrice=0;
    var balance=null;
    var processBook=true;
    if(this.props.bdata.paytype==="credit" && this.props.bdata.diffamt>0){
      this.props.bdata.surchrge=this.state.surchrge;
      payPrice=Utilities.addPrice(this.props.bdata.diffamt,this.state.surchrge,"Add")
      var clBal=Math.round(Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub"));
      if(this.props.bdata.butype==="sta_user"){
      balance=await this.convertCLObj(this.props.bdata,"deduct",true);
      }
      if(balance!==null && balance!==undefined){
        var bal=Math.round(Utilities.addPrice(this.props.bdata.price,balance,"Sub"));
        var payAmt=Math.round(Utilities.addPrice(this.props.bdata.diffamt,balance,"Add"));
        this.setState({
          showCl : true,
          clCurrentBal:bal,
          payAmount:payAmt,
          isLoading : false,
          pleasewait:false
        })
        processBook=false;
      }else{
        this.createCLPayload(clBal);
        status="Confirmed"
        clPrice=this.props.bdata.creditBal;
      }
    }else{
      status="Pending"
      clPrice=0;
      payPrice=Utilities.addPrice(this.props.bdata.price,this.state.surchrge,"Add");
    }
    //Calling Book request
  if(this.state.pnr==="" && processBook){
   if(this.props.bdata.onhold==="yes"){
    this.callBookReqest(false,clPrice,"yes",status,payPrice);
   }else{
    this.paymentRetrieveOnholdNo(clPrice,payPrice,status);
   }
  }else if(this.state.pnr!=="" && processBook){
    this.paymentRetrieve(clPrice,payPrice,null,status);
  }
  }else{
    this.setState({
      notiMessageShow : true,
      notiMessage:"Please accept terms and conditions and proceed",
      notiVarient:'danger'
    })
    this.hidemessage()
    //setTimeout(this.setState.bind(this, {position:1}), 5000);
  }
}

//Creating Payload for Book and Ticket request
createPayPayload=(bookData,payreq,payresp)=>{
  const payload={
    "tid": bookData.tid,
    "bookId": bookData.bookingId,
    "onhold":bookData.onhold,
    "cha": "B2C",
    "cur": bookData.cur,
    "sty": bookData.sty,
    "tt": bookData.tt,
    "bid":bookData.bid,
    "butype":bookData.butype,
    "pcid":bookData.pcid,
    "office_id":bookData.ofcId,
    "service_fee":bookData.service_fee,
    "pnr":bookData.pnr,
    "bktype":bookData.bookingType,
    "plb":bookData.plb,
    "ttl":bookData.ttl,
    "oldSeg":bookData.oldseg,
    "tkts":bookData.tkts,
    "trans_id":bookData.trans_id,
    "tkt_user_type": bookData.utype,
    "tkt_user_id": bookData.tkt_user_id,
    "sup":bookData.supplier,
    "otCharge":bookData.otCharge,
    "region_type":bookData.region_type,
    "req_cur":bookData.req_currency,
    "pay": [{
      "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
      "amt": payreq.amount,
      "ccode": bookData.cur,
      "psta": "Confirmed",
      "pgauthid": payresp.res.id,
      "pgtid": payresp.res.organizationId,
      "pgstc": "000",
      "csur": this.state.surchrge,
      "ctype": "VISA",
      "cno": payreq.card,
      "cname": payreq.member,
      "cexp":payreq.expMonth+"/"+payreq.expYear,
      "cvfr": ""
      },{
      "ptype": "Credit Limit",
      "amt": bookData.clprice,
      "ccode": bookData.cur,
      "crbal": bookData.clStatus!==undefined && bookData.clStatus==="Pending"?bookData.creditBal:bookData.creditBal-bookData.price,
      "psta": bookData.clStatus,
      "formofpay":"",
      "paymode":"",
      "payref":"",
      "remarks":""
      }]
     }
   return payload;
}

//=====================================
// Calling to Ticket Service to get the ticket numbers
//=====================================
callTicketAfterPayment=(bookData,payreq,payresp)=>{
  let payload=this.createPayPayload(bookData,payreq,payresp);
  this.callTicketing(payload,bookData.mappings);
  }

//=====================================
// Calling to Book Service after payment is Done
//=====================================
callBookAfterPaymentLCC=(bookData,payreq,payresp)=>{
  let payload=this.createPayPayload(bookData,payreq,payresp);
  fireBookReq(payload,bookData.bookingType).then((response) => {
    if(response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed"){
      this.callTicketing(payload,bookData.mappings);
      }else{
        this.setState({
          isLoading : false,
          pleasewait:false
        })
        if(response && response.type == "TST_ISSUE") {
          this.props.sendBook("TST_fail")
        } else {
        this.props.sendBook(response.bookRs.err);
        }
    }
    this.setState({
      isItineraryLoading: false
    });
});
}


//=====================================
// Calling to Book Service after payment is Done
//=====================================
callBookAfterPayment=(bookData,payreq,payresp)=>{
  let payload=this.createPayPayload(bookData,payreq,payresp);
  fireBookReq(payload,bookData.bookingType).then((response) => {
    if (response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed"){
        const payload={
          "response":response,
          "mappings":bookData.mappings
        }
        this.props.history.push({
          pathname: '/ezytrip/bookings/flight/confirmation',
          state: {
            req: payload
          }
        });
      }else{
        this.setState({
          isLoading : false,
          pleasewait:false
        })
        if(response && response.type == "TST_ISSUE") {
          this.props.sendBook("TST_fail")
        } else {
        this.props.sendBook(response.bookRs.err);
      }
    }
    this.setState({
      isItineraryLoading: false
    });
});
}

//=====================================
// Calling to Ticket Service to get the ticket numbers
//=====================================
  callTicketReq=(bres,payresp,payreq,clprice,clStatus)=>{
    var pnr="";
    var onhold=this.props.bdata.onhold;
    if(this.props.bdata.bookingType==="DIRECT" || this.props.bdata.bookingType==="INCOMPLETE"){
    if(this.props.bdata.sty==="normal"){
    if(bres.data!==undefined && bres.data[0].origns!==undefined && bres.data[0].origns[0].srcorigns!=undefined && bres.data[0].origns[0].srcorigns[0].pnr!==undefined){
      pnr=bres.data[0].origns[0].srcorigns[0].pnr;
    }
    }else if(this.props.bdata.sty==="split"){
      pnr=bres.data[0].origns[0].srcorigns[0].pnr+"/"+bres.data[0].origns[0].destorigins[0].pnr;
    }
    }else if(this.props.bdata.sty==="normal" && this.props.bdata.bookingType==="IMPORT"){
     pnr=this.props.bdata.pnr;
     onhold="no"
    }else{
    pnr=this.state.pnr;
    }
    const payload={
      "tid": this.props.bdata.tid,
      "bookId": this.props.bdata.bookingId,
      "onhold":onhold,
      "cha": "B2C",
      "cur": this.props.bdata.req_currency,
      "sty": this.props.bdata.sty,
      "tt": this.props.bdata.tt,
      "bid":this.props.bdata.bid,
      "butype":this.props.bdata.butype,
      "pcid":this.props.bdata.pcid,
      "office_id":this.props.bdata.ofcId,
      "service_fee":this.props.bdata.service_fee,
      "pnr":pnr,
      "bktype":this.props.bdata.bookingType,
      "plb":this.props.bdata.plb,
      "ttl":this.props.bdata.ttl,
      "oldSeg":this.props.bdata.oldseg,
      "tkts":this.props.bdata.tkts,
      "trans_id":this.props.bdata.trans_id,
      "tkt_user_type": this.props.bdata.utype,
      "tkt_user_id": this.props.bdata.tkt_user_id,
      "sup":this.props.bdata.supplier,
      "otCharge":this.props.bdata.otCharge,
      "region_type":this.props.bdata.region_type,
      "req_cur":this.props.bdata.req_currency,
      "pay": [{
        "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
        "amt": payreq.amount,
        "ccode": this.props.bdata.req_currency,
        "psta": "Confirmed",
        "pgauthid": payresp.authcode,
        "pgtid": payresp.tranid,
        "pgstc": payresp.responsecode,
        "csur": this.state.surchrge,
        "ctype": "VISA",
        "cno": payreq.card,
        "cname": payreq.member,
        "cexp":payreq.expMonth+"/"+payreq.expYear,
        "cvfr": ""
        },{
        "ptype": "Credit Limit",
        "amt": clprice,
        "ccode": this.props.bdata.req_currency,
        "crbal": this.props.bdata.creditBal,
        "psta": clStatus,
        "formofpay":"",
        "paymode":"",
        "payref":"",
        "remarks":""
        }]
       }
     this.callTicketing(payload,"");
    }

  //=====================================
  // Calling to Booking Service to get the PNR
  //=====================================
    callBookReqest=(direct,clPrice,type,status,payPrice)=>{
    const payload={
      "tid": this.props.bdata.tid,
      "bookId": this.props.bdata.bookingId,
      "onhold":type,
      "cha": "B2C",
      "cur": this.props.bdata.req_currency,
      "sty": this.props.bdata.sty,
      "tt": this.props.bdata.tt,
      "bid":this.props.bdata.bid,
      "butype":this.props.bdata.butype,
      "pcid":this.props.bdata.pcid,
      "office_id":this.props.bdata.ofcId,
      "service_fee":this.props.bdata.service_fee,
      "pnr":this.props.bdata.pnr,
      "bktype":this.props.bdata.bookingType,
      "plb":this.props.bdata.plb,
      "ttl":this.props.bdata.ttl,
      "oldSeg":this.props.bdata.oldseg,
      "tkts":this.props.bdata.tkts,
      "trans_id":this.props.bdata.trans_id,
      "tkt_user_type": this.props.bdata.utype,
      "tkt_user_id": this.props.bdata.tkt_user_id,
      "segments":this.props.bdata.segments,
      "sup":this.props.bdata.supplier,
      "otCharge":this.props.bdata.otCharge,
      "region_type":this.props.bdata.region_type,
      "req_cur":this.props.bdata.req_currency,
      "pay": [{
        "ptype": "",
        "amt": 0,
        "ccode": "",
        "psta": "Pending",
        "crbal": 0,
        "pgauthid": "",
        "pgtid": "",
        "pgstc": "",
        "csur": "",
        "ctype": "",
        "cno": "",
        "cname": "",
        "cexp": "",
        "cvfr": ""
        },{
        "ptype": "Credit Limit",
        "amt": clPrice,
        "ccode": this.props.bdata.req_currency,
        "crbal": this.props.bdata.creditBal-this.props.bdata.price,
        "psta": status,
        "formofpay":"",
        "paymode":"",
        "payref":"",
        "remarks":""
        }]
        }
        if(this.props.bdata.onhold_sty) {
          payload.onhold_sty = this.props.bdata.onhold_sty
        }
        if(this.props.bdata.splitted_pax) {
          payload.splitted_pax = this.props.bdata.splitted_pax
          payload.pr = this.props.bdata.pr
          payload.paxpr = this.props.bdata.paxpr
        }
     if(this.props.bdata.bookingType!=="ONHOLD"){
     fireBookReq(payload,this.props.bdata.bookingType).then((response) => {
      if (response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed") {
        this.setState({
          pnr : response.bookRs.data[0].origns[0].srcorigns[0].pnr
        })
        if(direct){
          if(type==="yes" || this.props.bdata.isTravelFusion){
            this.convertCLObj(this.props.bdata,"deduct",false);
            var clBal=Math.round(Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub"));
            this.createCLPayload(clBal);
            if(this.props.bdata.sty==="normal"){
              payload.pnr=response.bookRs.data[0].origns[0].srcorigns[0].pnr;
            }else{
              let owpnr="";
              let rtpnr="";
              if(response.bookRs.data!==undefined && response.bookRs.data[0]!==undefined && response.bookRs.data[0].origns[0]!==undefined){
              if(response.bookRs.data[0].origns[0].srcorigns[0]!==undefined && response.bookRs.data[0].origns[0].srcorigns[0].pnr!==undefined){
                owpnr=response.bookRs.data[0].origns[0].srcorigns[0].pnr;
              }
              if(response.bookRs.data[0].origns[0].destorigins!==undefined && response.bookRs.data[0].origns[0].destorigins[0]!==undefined && response.bookRs.data[0].origns[0].destorigins[0].pnr!==undefined){
                rtpnr=response.bookRs.data[0].origns[0].destorigins[0].pnr;
              }
              payload.pnr=owpnr+"/"+rtpnr;
            }
            }
            fireTicket(payload).then((resp) => {
              if(resp.bookRs!==undefined && resp.bookRs.data[0]!==undefined && resp.bookRs.data[0].bkd[0]!==undefined && resp.bookRs.data[0].bkd[0].st==="Ticketing failed"){
                this.convertCLObj(this.props.bdata,"add",false);
              }
              const payload={
                "response":resp,
                "mappings":this.props.bdata.mappings
                }
                this.props.history.push({
                  pathname: '/ezytrip/bookings/flight/confirmation',
                  state: {
                  req: payload
                  }
                });
           });
          }else{
            const payload={
              "response":response,
              "mappings":this.props.bdata.mappings!==undefined?this.props.bdata.mappings:response.bookRs.data[0].mappings
            }
            this.props.history.push({
              pathname: '/ezytrip/bookings/flight/confirmation',
              state: {
                req: payload
              }
            });
          }
        }else{
          this.paymentRetrieve(clPrice,payPrice,response.bookRs,status);
        }
        }else{
          if(direct){
          this.convertCLObj(this.props.bdata,"add",false);
          }
          this.setState({
            isLoading : false,
            pleasewait:false
          })
          if(response && response.type == "TST_ISSUE") {
            this.props.sendBook("TST_fail")
          } else {
            this.props.sendBook("Book");
          }
      }
  });
}else{//onhold flow firing ticketing while Confrim
    this.callTicketing(payload,"");
}
}
//Calling ticketing
callTicketing=(resp,mappingsList)=>{
  sessionStorage.removeItem("COMPID");
  let bookType="";
  if(this.props.bdata!==undefined && this.props.bdata.bookingType!==undefined){
    bookType=this.props.bdata.bookingType;
  }else{
    bookType=resp.bktype!==undefined?resp.bktype:"DIRECT";
  }
  fireTicket(resp,bookType).then((response) => {
    if (response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed"){
    const payload={
      "response":response,
      "mappings":this.props.bdata!==undefined && this.props.bdata.mappings.length!==0?this.props.bdata.mappings:mappingsList
      }
      this.props.history.push({
        pathname: '/ezytrip/bookings/flight/confirmation',
        state: {
        req: payload
        }
      });
    }else{
      if(this.props.bdata.butype==="sta_user"){
        this.convertCLObj(this.props.bdata,"add",false);
        var clBal=Math.round(Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub"));
        this.createCLPayload(clBal);
        }
    }
    this.setState({
      isItineraryLoading: false
    });
 });
}

//=====================================
  // Calling to Booking Service to get the PNR
  //=====================================
  callBookReqestOnholdNo=(clPrice,status,payreq,payresp)=>{
    const payload={
      "tid": this.props.bdata.tid,
      "bookId": this.props.bdata.bookingId,
      "onhold":"no",
      "cha": "B2C",
      "cur": this.props.bdata.req_currency,
      "sty": this.props.bdata.sty,
      "tt": this.props.bdata.tt,
      "bid":this.props.bdata.bid,
      "butype":this.props.bdata.butype,
      "pcid":this.props.bdata.pcid,
      "office_id":this.props.bdata.ofcId,
      "service_fee":this.props.bdata.service_fee,
      "pnr":this.props.bdata.pnr,
      "bktype":this.props.bdata.bookingType,
      "plb":this.props.bdata.plb,
      "ttl":this.props.bdata.ttl,
      "oldSeg":this.props.bdata.oldseg,
      "tkts":this.props.bdata.tkts,
      "trans_id":this.props.bdata.trans_id,
      "tkt_user_type": this.props.bdata.utype,
      "tkt_user_id": this.props.bdata.tkt_user_id,
      "segments":this.props.bdata.segments,
      "sup":this.props.bdata.supplier,
      "otCharge":this.props.bdata.otCharge,
      "region_type":this.props.bdata.region_type,
      "req_cur":this.props.bdata.req_currency,
      "pay": [{
        "ptype": payreq.cardtype==="creditcard"?"Credit Card":"Debit Card",
        "amt": payreq.amount,
        "ccode": this.props.bdata.req_currency,
        "psta": "Confirmed",
        "pgauthid": payresp.authcode,
        "pgtid": payresp.tranid,
        "pgstc": payresp.responsecode,
        "csur": this.state.surchrge,
        "ctype": "VISA",
        "cno": payreq.card,
        "cname": payreq.member,
        "cexp":payreq.expMonth+"/"+payreq.expYear,
        "cvfr": ""
        },{
        "ptype": "Credit Limit",
        "amt": clPrice,
        "ccode": this.props.bdata.req_currency,
        "crbal": this.props.bdata.creditBal-this.props.bdata.price,
        "psta": status,
        "formofpay":"",
        "paymode":"",
        "payref":"",
        "remarks":""
        }]
  }
  if(this.props.bdata.onhold_sty) {
    payload.onhold_sty = this.props.bdata.onhold_sty
  }
  if(this.props.bdata.splitted_pax) {
    payload.splitted_pax = this.props.bdata.splitted_pax
    payload.pr = this.props.bdata.pr
    payload.paxpr = this.props.bdata.paxpr
  }
    if(this.props.bdata.bookingType!=="ONHOLD"){
     fireBookReq(payload,this.props.bdata.bookingType).then((response) => {
      if (response.bookRs!==undefined &&  response.bookRs.status!=="bookfailed"){
          const payload={
            "response":response,
            "mappings":this.props.bdata.mappings
          }
          this.props.history.push({
            pathname: '/ezytrip/bookings/flight/confirmation',
            state: {
              req: payload
            }
          });
        }else{
          this.setState({
            isLoading : false,
            pleasewait:false
          })
          if(response && response.type == "TST_ISSUE") {
            this.props.sendBook("TST_fail")
          } else {
            this.props.sendBook(response.bookRs.err);
          }
          
      }
  });
}else{//Call ticketing from Confirm payment
  this.callTicketing(payload,"");
}
}

  //=====================================
  // Calling service for Deduct the CL
  //=====================================
 deductCL=(payload)=>{
  deductCreditLimit(payload).then((response) => {
  if (response.data!==undefined && response.data.st!==undefined && response.data.st==="Insuficiant"){
      return response.data.bal;
  }else{
      return null;
  }
});
}


//=====================================
  // Calling Payment service
  //=====================================
  paymentRetrieve = (clprice,price,res,clStatus) => {
    this.setState({
      isLoading : true,
      pleasewait:true
    })
    var obj="";
    if(this.state.pay_type==="Credit Card"){
    obj={
        card: this.state.number.replace(/ +/g, ""),
        cvv2: this.state.cvc,
        expYear: this.state.expiry.split('/')[1],
        expMonth: this.state.expiry.split('/')[0],
        member: this.state.name,
        currencyCode: 'this.props.bdata.req_currency',
        address: '',
        city: '',
        statecode: '',
        zip: '',
        CountryCode: 'QA',
        email: "anil@test.com",
        amount: price,
        chanal:"ATT_B2B",
        bookingid:this.props.bdata.bookingId,
        product:"Flight",
        cardtype:"creditcard",
        flow_type:"B2B"
      }
    }else{
      obj =
      {
        currencyCode: 'this.props.bdata.req_currency',
        amount: "1.00",
        cardtype:"naps",
        booking_id:this.props.bdata.bookingId,
        flow_type:"B2B"
      };
    }
      purchaseServicePostCall(obj).then((response)=>{
        if(response.data.suc){
            var pnr="";
            var onhold=this.props.bdata.onhold;
            if(this.props.bdata.bookingType==="DIRECT" || this.props.bdata.bookingType==="INCOMPLETE"){
            if(this.props.bdata.sty==="normal"){
            if(res!==null && res.data!=="" && res.data!==undefined && res.data[0].origns!==undefined && res.data[0].origns[0].srcorigns!=undefined && res.data[0].origns[0].srcorigns[0].pnr!==undefined){
              pnr=res.data[0].origns[0].srcorigns[0].pnr;
            }
            }else if(res!==null &&  this.props.bdata.sty==="split"){
              pnr=res.data[0].origns[0].srcorigns[0].pnr+"/"+res.data[0].origns[0].destorigins[0].pnr;
            }
            }else if(this.props.bdata.sty==="normal" && this.props.bdata.bookingType==="IMPORT"){
            pnr=this.props.bdata.pnr;
            onhold="no"
            }else{
            pnr=this.state.pnr;
            }
           this.props.bdata.pnr=pnr;
           this.props.bdata.clprice=clprice;
           this.props.bdata.clStatus=clStatus;
           window.sessionStorage.setItem(PAY_DATA, JSON.stringify(this.props.bdata));
           window.sessionStorage.setItem(PRODUCT_TYPE, "Flight");
           window.sessionStorage.setItem(PAY_REQ, JSON.stringify(obj));
           window.sessionStorage.setItem(GET_PAY_LINK,response.data.res.self);
           return window.location=response.data.res.checkout;
        }else{
            this.setState({
              isLoading : false,
              pleasewait:false
            })
            this.confirmDlg();
          }
        })
  }

  //=====================================
  // Calling Payment service
  //=====================================
  paymentRetrieveOnholdNo = (clprice,price,clStatus) => {
    this.setState({
      isLoading : true
    })
    var obj="";
    if(this.state.pay_type==="Credit Card"){
    obj={
      card: this.state.number.replace(/ +/g, ""),
      cvv2: this.state.cvc,
      expYear: this.state.expiry.split('/')[1],
      expMonth: this.state.expiry.split('/')[0],
      member: this.state.name,
      currencyCode: 'this.props.bdata.req_currency',
      address: '',
      city: '',
      statecode: '',
      zip: '',
      CountryCode: 'QA',
      "email": "anil@test.com",
      amount: price,
      chanal:"ATT_B2B",
      bookingid:this.props.bdata.bookingId,
      product:"Flight",
      cardtype:"creditcard",
      flow_type:"B2B"
      }
    }else{
      obj =
      {
        currencyCode: 'this.props.bdata.req_currency',
        amount: "1.00",
        cardtype:"naps",
        booking_id:this.props.bdata.bookingId,
        flow_type:"B2B"
      };
    }
      purchaseServicePostCall(obj).then((response)=>{
        if(response.data.suc){
          this.props.bdata.clStatus=clStatus;
          this.props.bdata.clprice=clprice;
          window.sessionStorage.setItem(PAY_DATA, JSON.stringify(this.props.bdata));
          window.sessionStorage.setItem(PAY_REQ, JSON.stringify(obj));
          window.sessionStorage.setItem(PRODUCT_TYPE, "Flight");
          window.sessionStorage.setItem(GET_PAY_LINK,response.data.res.self);
          return window.location=response.data.res.checkout;
          //this.callBookReqestOnholdNo(clprice,clStatus,obj,response.data);
        }else{
          if(this.props.bdata.bookingType==="DIRECT" && this.props.bdata.paytype==="credit" && this.props.bdata.diffamt>0){
            this.convertCLObj(this.props.bdata,"add",false);
          }
          this.setState({
            isLoading : false,
            pleasewait:false
          })
          this.confirmDlg();
        }
        })
  }

  showTermsConditions=()=>{
    this.setState({
      showTerms : true
    })
  }

  handleClose=()=>{
    this.setState({
      showTerms : false
    })
  }
  handleCLClose=()=>{
    this.setState({
      showCl : false
    })
  }

  handleOverClose=()=>{
    this.setState({
      showOverCl : false
    })
  }

   handleChangeOver = (e) => {
    const { id, value } = e.target;
     if(this.state.overcl){
      this.setState({
        overcl: false,
        showOverCl:false,
        showOver:false
      });
     }else{
      this.setState({
        overcl: true,
        showOverCl:true
      });
     }
   }
   confirmChangeOver = (e) => {
    const { id, value } = e.target;
    this.setState({
      overcl: true,
      showOverCl:false,
      showOver:true
    });
   }
  //Confirmation dialog popup to delete the Branch
  confirmDlg = () =>{
    confirmNomoreDlg('Payment', function () {
      return window.location="/ezytrip/dashboard";
  });
  };

  gotoPayment=()=>{
    this.props.bdata.diffamt=this.state.payAmount;
    this.props.bdata.creditBal=this.state.clCurrentBal;
    this.props.bdata.price=this.state.payAmount;
    this.setState({
      name: "",
      number: "",
      expiry: '',
      cvc: '',
      focused: '',
      issuer: '',
      formData: '',
      showCl : false
    })
  }

  //=====================================
  // Calling Booking service to Confirm the ticketing
  //=====================================
  confirmOverCLBooking=()=>{
     window.sessionStorage.removeItem(CHECK_BOOKING);
     window.sessionStorage.removeItem(BOOKING_REF_NO);
    if(this.state.term && this.state.overcl){
    this.setState({
      isLoading : true,
      pleasewait:true
    })
  const payload={
    "tid": this.props.bdata.tid,
    "bookId": this.props.bdata.bookingId,
    "onhold":"no",
    "cha": "B2C",
    "cur": this.props.bdata.req_currency,
    "sty": this.props.bdata.sty,
    "tt": this.props.bdata.tt,
    "bid":this.props.bdata.bid,
    "butype":this.props.bdata.butype,
    "pcid":this.props.bdata.pcid,
    "service_fee":this.props.bdata.service_fee,
    "office_id":this.props.bdata.ofcId,
    "plb":this.props.bdata.plb,
    "ttl":this.props.bdata.ttl,
    "oldSeg":this.props.bdata.oldseg,
    "tkts":this.props.bdata.tkts,
    "trans_id":this.props.bdata.trans_id,
    "bktype":this.props.bdata.bookingType,
    "pnr" :this.props.bdata.pnr,
    "tkt_user_type": this.props.bdata.utype,
    "tkt_user_id": this.props.bdata.tkt_user_id,
    "segments":this.props.bdata.segments,
    "sup":this.props.bdata.supplier,
    "otCharge":this.props.bdata.otCharge,
    "region_type":this.props.bdata.region_type,
    "req_cur":this.props.bdata.req_currency,
    "pay": [{
      "ptype": "",
      "amt": 0,
      "ccode": "",
      "psta": "Pending",
      "crbal": 0,
      "pgauthid": "",
      "pgtid": "",
      "pgstc": "",
      "csur": "",
      "ctype": "",
      "cno": "",
      "cname": "",
      "cexp": "",
      "cvfr": ""
      },{
      "ptype": "Credit Limit",
      "amt": this.props.bdata.price,
      "ccode": this.props.bdata.req_currency,
      "crbal": this.props.bdata.creditBal-this.props.bdata.price,
      "psta": "Confirmed",
      "formofpay":"Override CL"
      }]
  }
  if(this.props.bdata.onhold_sty) {
    payload.onhold_sty = this.props.bdata.onhold_sty
  }
  if(this.props.bdata.splitted_pax) {
    payload.splitted_pax = this.props.bdata.splitted_pax
    payload.pr = this.props.bdata.pr
    payload.paxpr = this.props.bdata.paxpr
  }
  if(this.props.bdata.bookingType!=="ONHOLD"){
  fireBookReq(payload,this.props.bdata.bookingType).then((response) => {
    if (response.bookRs!==undefined && response.bookRs.status!=="bookfailed"){
      if(!this.props.bdata.isTravelFusion){
      this.setState({
        showOverCl : false
      })
      if(this.props.bdata.butype==="sta_user"){
      this.convertCLObj(this.props.bdata,"deduct",false);
      var clBal=Math.round(Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub"));
      this.createCLPayload(clBal);
      }
      const pay={
        "response":response,
        "mappings":this.props.bdata.mappings
      }
      this.props.history.push({
        pathname: '/ezytrip/bookings/flight/confirmation',
        state: {
        req: pay
        }
      });
      }else{
        if(this.props.bdata.sty==="split"){
          payload.pnr=response.bookRs.data[0].origns[0].srcorigns[0].pnr+"/"+response.bookRs.data[0].origns[0].destorigins[0].pnr;
        }
        this.callTicketing(payload,"");
      }
      }else{
        this.setState({
          isLoading : false,
          pleasewait:false
        })
        if(response && response.type == "TST_ISSUE") {
          this.props.sendBook("TST_fail")
        } else {
          this.props.sendBook(response.bookRs.err);
        }
        
      }
});
}else{
  this.callTicketing(payload,"");
}
}else if(!this.state.overcl){
  this.setState({
    notiMessageShow : true,
    notiMessage:"Please select Override credit balance and proceed",
    notiVarient:'danger'
  })
  this.hidemessage();
}else if(!this.state.term){
  this.setState({
    notiMessageShow : true,
    notiMessage:"Please accept terms and conditions and proceed",
    notiVarient:'danger'
  })
  this.hidemessage();
}
}

hidemessage = () => {
  setTimeout(()=>this.setState({
    notiMessageShow : false,
    notiMessage:"",
    notiVarient:''
  }), 5000);
}

backToHome=()=>{
  return window.location="/ezytrip/dashboard";
}

 handlePayChange=(type)=>{
  if(type == "credit"){
    this.setState({
      pay_type : "Credit Card",
    })
  }else if(type == "debit"){
    this.setState({
      pay_type : "Debit Card",
    })
  }else if(type == "apple"){

  }
 }

  render() {
    return (
      <>
 <Alert  className="notification" variant={this.state.notiVarient} show={this.state.notiMessageShow}
                onClose={() =>  this.setState({
                  notiMessageShow : false})}
                dismissible> {this.state.notiMessage}</Alert>
    <div>

    {payment ? <Processing/> : "" }

    {this.state.isItineraryLoading ?(<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
     </div>
      <div key="Payment">

        {this.props.bdata!==undefined && this.props.bdata.onhold==="no" &&  Math.sign(this.props.bdata.cbal)!==1 && this.props.bdata.utype !== "att_user" &&
          <Alert variant="info">
         <span>You don't have enough Balance to do the booking. Please contact Ezytrip Support Team for any queries!</span>
         </Alert>
         }
         {this.props.bdata!==undefined && this.props.bdata.onhold==="yes" &&  Math.sign(this.props.bdata.cbal)!==1 && this.props.bdata.utype !== "att_user" &&
          <Alert variant="info">
         <span>You don't have enough balance to confirm the booking, use the hold booking or contact ezytrip support team</span>
         </Alert>
         }
        {(this.props.bdata!==undefined && this.props.bdata.enableOvrwriteCL && this.props.bdata.utype==="att_user" && this.props.bdata.overwriteRole || this.props.bdata.enableOveride) &&
        <>
          <div className="overwrite mb-3">

        <div className="creditchk">
        <span>The amount going to be debited from your credit balance is : {/*<strong> {this.props.bdata.req_currency} {this.props.bdata.price}</strong>*/}</span><div className="priceupdates"><span></span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em> <strong> <FormateCurrency value={this.props.bdata.price} />  </strong></div></div>
        </div>
        <div className="creditchk">
          <Form.Check
            type="checkbox"
            id="ov"
            label="Override Credit Balance"
            checked={this.state.overcl}
            value={this.state.overcl}
            onChange={this.handleChangeOver}
            custom
        />
        {/* <span>Total: <strong> {props.bdata.cur} {props.bdata.price}</strong></span> */}
        </div>
        {this.state.showOver &&
          <div className="creditchk note">
          <div className="priceupdates"><span>Note : Your updated credit balance will be  : </span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.cbal,this.props.bdata.price,"Sub")} /> </strong></div></div>
          </div>
        }
        </div>
        {this.state.overcl &&
        <>
        <div className="d-flex mt-2 tc"><Form.Check
        type="checkbox"
        id="cb"
        label=""
        value={this.state.term}
        onChange={this.handleChangeOcl}
        custom
        />
      <label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of ezytrip</label></div>
      <div className="d-flex justify-content-end">
        <Button variant="success" size="lg"
        onClick={this.confirmOverCLBooking}
        className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
        disabled = {this.state.isLoading && this.state.pleasewait ? true : false } >
        {this.state.isLoading ? "Processing..." : "Confirm" }
        </Button>
        </div>
        {this.state.pleasewait ? <Processing/> : "" }

        </>
        }
        </>
        }
        {this.props.bdata!==undefined && !this.state.overcl && this.props.bdata.paytype==="credit" && !this.props.bdata.isclavail && this.props.bdata.diffamt>0 && this.props.bdata.enableOnlinePay &&
        <div>
        <div className="totalPay d-flex justify-content-between align-items-center"><span>Your available Credit Balance is : </span><span><strong> {this.props.bdata.req_currency} {this.props.bdata.creditBal}</strong></span></div>
        <div className="cardHilight">
        <p className="m-0">Your credit balance is too low to confirm this booking. You can pay remaining amount by credit card for instant confirmation.</p>
        <div className="tobePaid">
          <div className="priceupdates"><span>Balance : </span><div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={this.props.bdata.diffamt} /></strong></div></div>
          <div className="priceupdates"><span>Handling Fee : </span><div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={this.state.surchrge} /></strong></div></div>
        </div>
      </div>
      </div>
        }
      {this.props.bdata!==undefined && this.props.bdata.enableOnlinePay && !this.state.overcl && this.props.bdata.enablePayment &&
          <form ref={c => (this.form = c)} onSubmit={this.confirmPayment}>
          <div className="cardPayment">
            <div className='cardPaymentin'>
        <div className="cardPaymentDtld ssssd">
          <div>
            <div className='creditCardr'>
            <Form.Check
              type="radio"
              id="credit"
              label=" "
              checked={this.state.pay_type==="Credit Card"}
              value={this.state.pay_type}
              onChange={()=>this.handlePayChange("credit")}
              custom
             />
              <h6 className="subTitle m-0">Credit Card</h6>
              </div>
            <p>Enter your card details to continue payment process</p>

          </div>
          {this.props.bdata.diffamt>0 && <span>Pay : <strong>{this.props.bdata.req_currency} <FormateCurrency value={Utilities.addPrice(this.props.bdata.diffamt,this.state.surchrge,"Add")} /></strong></span>}
          {this.props.bdata.diffamt===0 && this.props.bdata.price>0 &&
          <div className="tobePaid">
          <div className="priceupdates"><span>Total :   </span > <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,0,"Add")} /></strong></div></div>
          <div className="priceupdates"> <span>Handling Fee :   </span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={this.state.surchrge} /> </strong></div></div>
          <div className="priceupdates"> <span>Pay :   </span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,this.state.surchrge,"Add")} /> </strong></div></div>
          </div>
          }
        </div>
          <>
            <Form.Row  autoComplete="off">

                {/* <Form.Group as={Col} className="priceLabel">
                  <span className="iconBg"><i className="icon price"></i></span>
                  <Form.Label>Total price in {this.state.currency} </Form.Label>
                  <h4>{this.state.totalSum} </h4>
                </Form.Group> */}

                <Form.Group as={Col} xs={8} className="cardnumber">
                    <input
                    type="tel"
                    name="number"
                    className="form-control"
                    placeholder="Enter Card Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    disabled={this.state.pay_type==="Debit Card"}
                  />
                  {/* <span className={"icon cardPhoto " + this.state.issuer}></span> */}
                  <Form.Label>Card number</Form.Label>
                  <span className="ezyIcon icon-creditCards"></span>
                </Form.Group>

                <Form.Group as={Col} xs={8} >
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    autoComplete="off"
                    disabled={this.state.pay_type==="Debit Card"}
                  />
                    <Form.Label>Holder Name</Form.Label>
                    <span className="ezyIcon icon-profile"></span>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} xs={4} >
                  <input
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder="MM/YY"
                    pattern="\d\d/\d\d"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    disabled={this.state.pay_type==="Debit Card"}
                  />
                  <Form.Label>Expiry date(MM/YY)</Form.Label>
                  <span className="ezyIcon icon-newcalendar"></span>
                </Form.Group>
                <Form.Group as={Col} xs={4}>
                  <input
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder="CVC"
                    pattern="\d{3,4}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    disabled={this.state.pay_type==="Debit Card"}
                  />
                  <Form.Label>CVV</Form.Label>
                  <span className="ezyIcon icon-lock"></span>
                  <small>The 3 digit number printed on the back of the card</small>
                </Form.Group>
            </Form.Row>
            </>

            <input type="hidden" name="issuer" value={this.state.issuer} />
            </div>
                <div className='brdLinee'></div>
                <div className='cardPaymentin'>
            <div className="d-flex tc dcardMain">
              <div className='d-flex align-items-center justify-content-start '>

             <Form.Check
              type="radio"
              id="debit"
              label=" "
              checked={this.state.pay_type==="Debit Card"}
              value={this.state.pay_type}
              onChange={()=>this.handlePayChange("debit")}
              custom
             />
              <h6 className="subTitle m-0">Debit Card</h6>
              </div>
                <div className='debitCardLg'>
                <img src={Naps} />
                </div>
             </div>
             <p> We are redirecting to QPay Page</p>

             <div className='dCardcontent'>
              {/* <span className='piconLogo'><img width={40}
                        src={""}
                        className="apaylogo"
                        title="ATT"
                        alt="Apay"
                    /></span> */}

          <span className='debitCardd'>
                         {this.props.bdata.diffamt===0 && this.props.bdata.price>0 &&
          <div className="tobePaid">
          <div className="priceupdates"><span>Total :  </span > <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,0,"Add")} /></strong></div></div>
          <div className="priceupdates"> <span>Handling Fee :  </span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={this.state.surchrge} /> </strong></div></div>
          <div className="priceupdates"> <span>Pay :  </span> <div className="pricetag"> <em>{this.props.bdata.req_currency}</em><strong> <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,this.state.surchrge,"Add")} /> </strong></div></div>
          </div>
          }

          </span>
           </div>
           </div>
            </div>
            <div className="d-flex mt-4 tc termCondp">
              <div className='d-flex'>
              <Form.Check
        type="checkbox"
        id="tc"
        label=" "
        value={this.state.terms}
        onChange={this.handleChange}
        custom
      /><label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label>
      </div>
                 <div className="conffirm d-flex justify-content-end">
              <Button variant="success" size="lg" className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')} disabled = {this.state.isLoading && this.state.pleasewait ? true : false } >{this.state.isLoading ? "Processing..." : "Confirm and Pay" } </Button>
            </div>
            </div>
            {this.state.pleasewait ? <Processing/> : "" }
          </form>
         }
      </div>

      {this.props.bdata!==undefined && this.props.bdata!==undefined && this.props.bdata.isclavail && this.props.bdata.creditBal!==0 &&
      <div className="conffirm crdt">
        <div className="totalPay mb-2">
          <span className="mr-1">The amount going to be debited from your credit balance is : </span> <strong> {this.props.bdata.req_currency} <FormateCurrency value={Utilities.addPrice(this.props.bdata.price,0,"Add")} /> </strong></div>
          <div className="totalPay">
          <span className="mr-1">The remaining credit will be  : </span><strong>{this.props.bdata.req_currency} <FormateCurrency value={Utilities.addPrice(this.props.bdata.creditBal,this.props.bdata.price,"Sub")} /> </strong></div>
        <div className="d-flex mt-2 tc"><Form.Check
        type="checkbox"
        id="tc"
        label=""
        value={this.state.terms}
        onChange={this.handleChange}
        custom
        /><label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>
        <div className="d-flex justify-content-end">
        <Button variant="success" size="lg"
        onClick={this.confirmBooking}
        className={"progress-bar continue d-flex  " + (this.state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
        disabled = {this.state.isLoading && this.state.pleasewait ? true : false } >
        {this.state.isLoading ? "Processing..." : "Confirm" }

       </Button>
       {this.state.pleasewait ? <Processing/> : "" }
       </div>
      </div>

      }
      <Modal
        show={this.state.showTerms}
        onHide={this.handleClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
        <h5>Terms and Condtions</h5>
        <div className="dstn">
        Terms and Condtions
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={this.handleClose}>
           Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.showCl}
        onHide={this.handleCLClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}>

        <Modal.Body>
        <div>
        <div className="custom-ui">
         <span className="confirmD"></span>
        <p className="m-0">Your credit balance is too low to confirm this booking.
         You can pay remaining amount by using credit card for booking confirmation</p>
        <div className="creditINfo d-flex flex-column align-items-end">
        <span>Credit Balance : <strong>{this.props.bdata.req_currency} {this.state.clCurrentBal}</strong></span>
        <span>Credit card Amount :<strong>{this.props.bdata.req_currency} {this.state.payAmount}</strong></span>
        </div>
        </div>
        <p className="m-0">Do you want to contine the payment?</p>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={this.handleCLClose}>
           Close
          </Button>
          <Button
              size="xs"
              variant="outline-primary"
              onClick={this.gotoPayment}
              type="submit"
            >Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.showOverCl}
        onHide={this.handleChangeOver}
        className="importPNRMdl creditPop"
        backdrop="static"
        keyboard={false}>
        <Modal.Body className="text-center clpopup" >

        {/* <div className="popIcon" >
          <span className="icons clIcon"></span>
        </div> */}
        <div className="backGroundgif">
<span className='backGroundbot'></span>
<span className='aarabQar'>{this.props.bdata.req_currency}</span>
<img src={Paymentprocessing} />
            </div>
        <h4>Credit balance alert!</h4>
        <div className="dstn">
        <p>{this.props.bdata!==undefined && this.props.bdata.cname} Doesn't have sufficient credit balance</p>

         <p>Would like to continue with the Override credit balance?</p>
        </div>
        <div className="buttonGrop pb-0 border-0">
        <Button size="xs" variant="secondary" onClick={this.handleChangeOver}>
           Cancel
          </Button>
          <Button variant="primary" size="xs" onClick={this.confirmChangeOver}>
           Yes
          </Button>
        </div>
        </Modal.Body>

      </Modal>
  
        <Modal
        show={this.state.enablePayPopup}
        onHide={this.backToHome}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}>

        <Modal.Body>
        <div>
        <div className="custom-ui">
         <span className="confirmD"></span>
        <div className="creditINfo d-flex flex-column align-items-end">
        <h3>Payment has failed.. </h3>
          <p>Sorry we are unable to process this transaction.
            Please check with adminstrator.
         </p>
        </div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
              size="xs"
              variant="outline-primary"
              onClick={this.backToHome}
              type="submit"
            >Back To Home</Button>
        </Modal.Footer>
      </Modal>
    

      </>
    );
  }
}

export default withRouter (PaymentDetails);
