import log from "loglevel";
import React, {
  useContext, useEffect,
  useReducer, useState
} from "react";
import { Alert, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../../App";
import Button from "../../../common/buttons/Button";
import {
  characterValidation
} from "../../../commonUtils/FormValidations";
import ApplicableDates from "../../common/ApplicableDates";
import Routes from "../../common/Routes";
import AirMarkupFareMapForm from "../markup/AirMarkupFareMapForm";

import PickList from '../../../common/pickList/PickList';
import {
  creation,

  deleteAppcableDate,
  deleteRoute, update
} from "../operations";
let airlineMaster = require("../../../masterData/AirlinesMasterData.json");

/**
 * Initial State Declaration
 */
{/* <Form.Group
          as={Col}
          xs={2}
          className="selectplugin"
          controlId="supplier"
        >
          <Select
            options={supplier}
            values={state.supplier}
            onChange={(value) => (dispatch({ type: "supplier", payload: value }), dispatch({type: "supplierError", payload: false}))}
          />
          <Form.Label>
            Supplier <sup>*</sup>
          </Form.Label>
        </Form.Group> */}
const initialState = {
  mn: "",
  act: true,
  ttype: "0",
  aplType: "",
  airlines: [],
  showCls: false,
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  rts: [],
  showMessage: false,
  fareList: [],
  markupId: 0,
  enableUpdate: false,
  enableTripAll: true,
  enableTripOne: false,
  enableTripTwo: false,
  enableTripThree: false,
  airlinesList: [],
  nameError: false,
  supplierError: false,
  supplier: [],
  reset: false,
  edit_applDates: [],
  flError: false,
  edit_region:"",
  isItineraryLoading:false,
  salesChanel:"",
  edit_salesChanel:"",
  ofc_id:"",
  edit_ofcid:"",
  edit_supplier:[]
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        mn: "",
        act: true,
        ttype: "0",
        aplType: "",
        airlines: [],
        showCls: false,
        notiMessage: "",
        notiMessageShow: false,
        notiVarient: "",
        rts: [],
        showMessage: false,
        fareList: [],
        markupId: 0,
        enableUpdate: false,
        enableTripAll: true,
        enableTripOne: false,
        enableTripTwo: false,
        enableTripThree: false,
        nameError: false,
        supplier: [],
        reset: true,
        edit_region:"",
        edit_applDates: [],
        isItineraryLoading:false,
        salesChanel:"",
        edit_salesChanel:"",
        ofc_id:"",
        disableOfc:false,
        edit_supplier:[]
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * @author: Lakshmi
 * @description: This function will create the Makrup
 * @date:14-09-2020
 * @function : AirMarkupForm
 */
function AirMarkupForm(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [routDetails, setRoutDetails] = useState({ rts: [] });
  const [dates, setDates] = useState({ datesList: [] });
  const [value, setValue] = useState([]);
  const [bType, setBType] = useState({ btyp: true });
  const [cType, setCType] = useState({ ctyp: false });
  const [aType, setAType] = useState({ atyp: false });
  const [vType, setVType] = useState({ vtyp: false });
  const [act, setAct] = useState(true);
  const [context, setContext] = useContext(Context);
  const [fareMap, setFareMaps] = useState({ fareMaps: [] });
  const supplier = [{ label: "Amadeus", value: 0 }, { label: "Travel Fusion", value: 1 }, { label: "Air Arabia", value: 2 },{ label: "Jazeera", value: 3 },{ label: "Indigo", value: 4 }, { label: "Flydubai", value: 5 },{label: "Salam Air", value: 6},{label:"Riya Travel", value: 7}];
  useEffect(() => {
    if (props.sendEditData && props.sendEditData !== undefined) {
      convertEditMarkup(props.sendEditData);
    }
  }, [props.sendEditData]);

  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" });
    let airlines = getUnique(airs, "value");
    dispatch({ type: "airlinesList", payload: airlines });
  }, []);

  const defaultAirlines = (e) => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" });
    let airlines = getUnique(airs, "value");
    dispatch({ type: "airlinesList", payload: airlines });
  };
  /**
   * @description: to remove all multiples in array of objects
   * @author : Azamuddin
   * @date : 03-11-2020
   */

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }
/**
 * @author: Lakshmi
 * @description: This function will Edit the markup Based on the Markup Id
 * @date:14-09-2020
 * @function : convertEditMarkup
 */
  const convertEditMarkup = (marobj) => {
    var obj = marobj.data.res;
    dispatch({ type: "mn", payload: obj.mn });
    dispatch({ type: "markupId", payload: obj.id });
    dispatch({ type: "edit_region", payload: obj.region_type });
    if(obj.office_id?.includes("All")){
      dispatch({ type: "edit_ofcid", payload: [{ value:"All", label: "All" }] });
    }else{
      dispatch({ type: "edit_ofcid", payload: [{ value:obj.office_id, label: obj.office_label }] });
      dispatch({ type: "ofc_id", payload: [{ value:obj.office_id, label: obj.office_label }] });
    }
   
    dispatch({ type: "edit_salesChanel", payload: obj.sales_channel });

    dispatch({ type: "enableUpdate", payload: true });
    if (obj.supp || obj.supp === 0) {
      let airList = [];
      for (let air of supplier) {
        if (obj.supp === air.value) {
          const pauload = {
            value: obj.supp,
            label: air.label,
          };
          airList.push(pauload);
        }
      }
      dispatch({ type: "supplier", payload: airList });
      dispatch({ type: "edit_supplier", payload: airList })
    }
    if (obj.ftype.includes("B")) {
      setBType({ btyp: true });
    }else{
      setBType({ btyp: false });
    }
    if (obj.ftype.includes("C")) {
      setCType({ ctyp: true });
    }
    if (obj.ftype.includes("A")) {
      setAType({ atyp: true });
    }
    if (obj.ftype.includes("V")) {
      setVType({ vtyp: true });
    }
    if (obj.act === 1) {
      setAct(true);
    } else {
      setAct(false);
    }
    if (obj.ttype === 1) {
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: true });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: false });
      dispatch({ type: "ttype", payload: obj.ttype });
    } else if (obj.ttype === 2) {
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: true });
      dispatch({ type: "enableTripThree", payload: false });
      dispatch({ type: "ttype", payload: obj.ttype });
    } else if (obj.ttype === 3) {
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: true });
      dispatch({ type: "ttype", payload: obj.ttype });
    } else if (obj.ttype === 0) {
      dispatch({ type: "enableTripAll", payload: true });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: false });
      dispatch({ type: "ttype", payload: obj.ttype });
    }

    if (obj.apdate) {
      //setApplicable({ applDates: obj.apdate });
      setDates({ datesList: obj.apdate });
      dispatch({type:"edit_applDates",payload: obj.apdate})
    }
    if (obj.rts && obj.rts !== undefined) {
      convertEditRoutes(obj);
    }
    if (obj.al) {
      let acarray = obj.al.split(",");
      let airList = [];
      for (let code of acarray) {
        for (let air of airlineMaster) {
          if (code === air.value) {
            const pauload = {
              value: code,
              label: air.label,
            };
            airList.push(pauload);
          }
        }
      }
      setValue(getUnique(airList, "value"));
      // dispatch({ type: "airlines", payload: getUnique(airList, "value") });
      dispatch({ type: "airlines", payload: airList });
    }

    //obj.fmap[0].region_type = obj.region_type
    dispatch({ type: "fareList", payload: obj.fmap });
    window.scrollTo(0, 0);
  };

  /**
 * @author: Lakshmi
 * @description: This function will Edit the Routes
 * @date:14-09-2020
 * @function : convertEditRoutes
 */
  const convertEditRoutes = (obj) => {
    let routes = [];
    for (let value of obj.rts) {
      let srcArray = [];
      let destArray = [];
      let airs = [];
      let dests = [];
      if (value.srcair && value.srcair.length !== 0) {
        srcArray = value.srcair.split(",");
        airs = srcArray.filter((q, idx) => srcArray.indexOf(q) === idx);
      }
      if (value.destair && value.destair.length !== 0) {
        destArray = value.destair.split(",");
        dests = destArray.filter((q, idx) => destArray.indexOf(q) === idx);
      }
      if (airs) {
        airs.map((obj) => {
          var route = "";
          if (obj && obj !== undefined) {
            route = {
              id: value.id,
              srcct: value.srcct,
              scname: value.scname + " " + "[" + obj + "]",
              destct: value.destct,
              dcname: value.dcname + " " + "[" + dests + "]",
              srcair: airs,
              destair: dests,
              is_db: value.is_db,
            };
          }
          routDetails.rts.push(route);
          setRoutDetails({ rts: routDetails.rts });
          let route_json = {
            srcct: value.srcct,
            destct: value.destct,
            srcair: airs,
            destair: dests,
            scname: value.scname,
            dcname: value.dcname,
            is_db: value.is_db
          };
          routes.push(route_json);
        });
        dispatch({ type: "rts", payload: routes });
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: "reset", payload: false });

    if (id === "mn") {
      dispatch({ type: "nameError", payload: false });
    }
    if (id === "act") {
      if (act) {
        setAct(false);
      } else {
        setAct(true);
      }
    }
    if (id === "all") {
      dispatch({ type: "ttype", payload: "0" });
      dispatch({ type: "enableTripAll", payload: true });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: false });
    } else if (id === "one") {
      dispatch({ type: "ttype", payload: "1" });
      dispatch({ type: "tripOne", payload: true });
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: true });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: false });
    } else if (id === "round") {
      dispatch({ type: "ttype", payload: "2" });
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: true });
      dispatch({ type: "enableTripThree", payload: false });
    } else if (id === "multi") {
      dispatch({ type: "ttype", payload: "3" });
      dispatch({ type: "enableTripAll", payload: false });
      dispatch({ type: "enableTripOne", payload: false });
      dispatch({ type: "enableTripTwo", payload: false });
      dispatch({ type: "enableTripThree", payload: true });
    }
    dispatch({ type: id, payload: value });
  };

  const setAir = (data) => {
    if (data.length > 0 && data[0].value === "All") {
      let finalAirs = [];
      data.map((obj) => {
        const al = {
          code: obj.value,
          name: obj.label,
        };
        finalAirs.push(al);
      });
      dispatch({ type: "airlines", payload: finalAirs });
      dispatch({ type: "airlinesList", payload: data });
      setValue(data);
    } else if (data.length > 0 && data[0].value !== "All") {
      let finalAirs = [];
      data.map((obj) => {
        const al = {
          code: obj.value,
          name: obj.label,
        };
        finalAirs.push(al);
      });
      dispatch({ type: "airlines", payload: finalAirs });
      setValue(data);
      removeAll();
    } else {
      dispatch({ type: "airlines", payload: [] });
      setValue(data);
      defaultAirlines();
    }
  };

  const removeAll = (e) => {
    let arr = [];
    for (let val of state.airlinesList) {
      arr.push(val.value);
    }
    if (arr.indexOf("All") > -1) {
      const index = arr.indexOf("All");

      state.airlinesList.splice(index, 1);
    }
  };
  const handleBookTypeChange = (e) => {
    if (e.target.id === "flb") {
      if (bType.btyp) {
        setBType({ btyp: false });
      } else {
        setBType({ btyp: true });
      }
    }
  };

  const setFlowType = (e) => {
    let flowtpes = [];
    flowtpes.push(bType);
    flowtpes.push(cType);
    flowtpes.push(aType);
    flowtpes.push(vType);
    var ftype = "";
    flowtpes.map((obj) => {
      if (ftype) {
        if (obj.btyp) {
          ftype = ftype + "|" + "B";
        } else if (obj.ctyp) {
          ftype = ftype + "|" + "C";
        } else if (obj.atyp) {
          ftype = ftype + "|" + "A";
        } else if (obj.vtyp) {
          ftype = ftype + "|" + "V";
        }
      } else {
        if (obj.btyp) {
          ftype = "B";
        } else if (obj.ctyp) {
          ftype = "C";
        } else if (obj.atyp) {
          ftype = "A";
        } else if (obj.vtyp) {
          ftype = "V";
        }
      }
    });
    return ftype;
  };

  useEffect(() => {
    if(setFlowType() == ""){
      dispatch({type: "flError", payload: true})
    }
    else{
      dispatch({type: "flError", payload: false})
    }
      }, [setFlowType()]);


  const handleCanTypeChange = (e) => {
    if (e.target.id === "flc") {
      if (cType.ctyp) {
        setCType({ ctyp: false });
      } else {
        setCType({ ctyp: true });
      }
    }
  };
  const handleAmdTypeChange = (e) => {
    if (aType.atyp) {
      setAType({ atyp: false });
    } else {
      setAType({ atyp: true });
    }
  };

  const handleVoidTypeChange = (e) => {
    if (vType.vtyp) {
      setVType({ vtyp: false });
    } else {
      setVType({ vtyp: true });
    }
  };
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateMarkupConfig = () => {
    if (state.mn === "") {
      dispatch({ type: "notiMessage", payload: "Please enter Markup name" });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    } else if (context.logindata.utype !== "ta_user" && fareMap.fareMaps.length === 0) {
      dispatch({
        type: "notiMessage",
        payload: "Please define atleast one Markup",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      return false;
    } else if (dates.datesList.length === 0) {
      dispatch({
        type: "notiMessage",
        payload: "Please Enter All The Mandatory Fields *",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage()
      return false;
    }
    return true;
  };

/**
 * @author: Lakshmi
 * @description: This function will save the Makrup
 * @date:14-09-2020
 * @function : handleSaveMarkup
 */
  const handleSaveMarkup = (e) => {
    e.preventDefault();
    let namevalidations = false;
    let flowValidations = false;
    if (validateMarkupConfig()) {
      if (characterValidation(state.mn)) {
        dispatch({ type: "isItineraryLoading", payload: true });
        namevalidations = true;
        dispatch({ type: "nameError", payload: false });
      } else {
        dispatch({ type: "nameError", payload: true });
      }
      if(setFlowType() !== ""){
        flowValidations = true
        dispatch({ type: "flError", payload: false });
      }
      else{
        dispatch({ type: "flError", payload: true });
      }
      if (namevalidations && flowValidations) {
        if (props.oldDataNames.indexOf(state.mn) === -1) {
          var ftype = setFlowType();
          var aircodes = "";
          if (state.airlines) {
            state.airlines.map((obj) => {
              if (aircodes) {
                aircodes = aircodes + "," + obj.code;
              } else {
                aircodes = obj.code;
              }
            });

          }
          const payload = {
            mrkreq: {
              mn: state.mn,
              ftype: ftype,
              act: act,
              ttype: state.ttype,
              crby: context.logindata.uid,
              utype: context.logindata.utype,
              ltype: context.logindata.utype,
              al: aircodes,
              apdate: dates.datesList,
              rts: state.rts,
              fmap: fareMap.fareMaps,
              supp: state.supplier.length > 0 ? state.supplier[0].value : null,
              region_type:context.logindata.utype==="att_user"?fareMap.fareMaps[0].region_type:context.logindata.region_type,
              sales_channel:context.logindata.utype==="att_user"?state.salesChanel:context.logindata.channel,
              // "office_id": null,
              office_id:context.logindata.utype==="att_user"?state.ofc_id[0]?.value.toString():"All"
            },
          };
          creation(payload)
            .then((response) => {
              if (response.suc) {
                dispatch({
                  type: "notiMessage",
                  payload: "Markup saved successfully",
                });
                dispatch({ type: "notiVarient", payload: "success" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
                return (window.location = "/ezytrip/administration/markup");
              } else {
                dispatch({ type: "isItineraryLoading", payload: false });
                dispatch({
                  type: "notiMessage",
                  payload: "Markup Not saved..",
                });
                dispatch({ type: "notiVarient", payload: "danger" });
                dispatch({ type: "notiMessageShow", payload: true });
                hidemessage()
              }
            })
            .catch(function (error) {
              log.error(
                "Exception occured in saveCompanyDetails function---" + error
              );
            });
          dispatch({ type: "showMessage", payload: false });
        } else {
          dispatch({ type: "notiMessage", payload: "Markup Already Exist" });
          dispatch({ type: "notiVarient", payload: "danger" });
          dispatch({ type: "notiMessageShow", payload: true });
          hidemessage()
        }
      }
    }
  };

  //FareMap  Data
  const fareMapData = (data) => {
    dispatch({ type: "reset", payload: false });
    setFareMaps({ fareMaps: [...fareMap.fareMaps, data] });
    if(state.salesChanel!==""){
      dispatch({ type: "edit_salesChanel", payload: state.salesChanel });
      dispatch({ type: "edit_region", payload: data.region_type });
    }
  };

  const applicableDates = (ad) => {
    //setApplicable({applDates: [...applicable.applDates, ad]});
    setDates({ datesList: [...dates.datesList, ad] });
    dispatch({ type: "reset", payload: false });
  };

  const updateApplDates = (data) => {
    dispatch({ type:"reset",payload:false})
    setDates({ datesList: data });
  };
  const rotesInfo = (route) => {
    state.rts.push(route);
    dispatch({ type: "rts", payload: state.rts });
    dispatch({ type: "reset", payload: false });
  };

  const updateRoutesData = (data) => {
    dispatch({ type: "rts", payload: data });
  };

  /**
 * @author: Lakshmi
 * @description: This function will delete the selected Applicable DATES
 * @date:14-09-2020
 * @function : callDeleteApp
 */
  const callDeleteApp = (id) => {

    deleteAppcableDate(id)
      .then((response) => {
        if (response.data.suc) {
          dispatch({
            type: "notiMessage",
            payload: "Applicable Deleted Successfully",
          });
          dispatch({ type: "notiVarient", payload: "success" });
          dispatch({ type: "notiMessageShow", payload: true })
          hidemessage()
        }
      })
      .catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
  };

/**
 * @author: Lakshmi
 * @description: This function will delete the selected route
 * @date:14-09-2020
 * @function : routeDelete
 */
  const routeDelete = (data) => {
    const payload = {
      src_air: data.srcair,
      desc_air: data.destair,
      markup_id: state.markupId,
    };
    deleteRoute(payload)
      .then((response) => {
        if (response.data.suc) {
          console.log("Delete Date Deleted SUC :");
        }
      })
      .catch(function (error) {
        log.error("Exception occured in deleteRoute function---" + error);
      });
  };
/**
 * @author: Lakshmi
 * @description: This function will update the Makrup based on Markup Id
 * @date:14-09-2020
 * @function : updateMarkup
 */
  const updateMarkup = (e) => {
    e.preventDefault();
    if (state.mn !== "" && dates.datesList.length > 0) {
      let namevalidations = false;
      let flowValidations = false;
      if (characterValidation(state.mn)) {
        dispatch({ type: "isItineraryLoading", payload: true });
        namevalidations = true;
        dispatch({ type: "nameError", payload: false });
      } else {
        dispatch({ type: "nameError", payload: true });
      }
      var ftype = setFlowType();
      if(ftype !== ""){
        flowValidations = true;
        dispatch({ type: "flError", payload: false })
        } else {
          dispatch({ type: "flError", payload: true })
        }
      if (namevalidations && flowValidations) {
        var aircodes = "";
        if (state.airlines) {
          state.airlines.map((obj) => {
            if (aircodes) {
              aircodes = aircodes + "," + obj.code !== undefined ? obj.code : obj.value;
            } else {
              aircodes = obj.code !== undefined ? obj.code : obj.value;
            }
          });
        }
        const payload = {
          mrkreq: {
            id: state.markupId,
            mn: state.mn,
            ftype: ftype,
            act: act,
            ttype: state.ttype,
            upby: context.logindata.uid,
            al: aircodes,
            apdate: dates.datesList,
            rts: state.rts,
            utype: context.logindata.utype,
            supp: state.supplier.length > 0 ? state.supplier[0].value : null,
            sales_channel:state.salesChanel,
            office_id:state.edit_ofcid[0].value
          },
        };
        update(payload)
          .then((response) => {
            if (response.suc) {
              dispatch({
                type: "notiMessage",
                payload: "Markup updated successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
              return (window.location = "/ezytrip/administration/markup");
            } else {
              dispatch({
                type: "notiMessage",
                payload: "Markup Not updated..",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({ type: "isItineraryLoading", payload: false });
              hidemessage()
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        dispatch({ type: "showMessage", payload: false });
      }
    } else {
      dispatch({
        type: "notiMessage",
        payload: "Please Enter All The Mandatory Fields *",
      });
      dispatch({ type: "notiVarient", payload: "danger" });
      dispatch({ type: "notiMessageShow", payload: true });
      hidemessage();
    }
  };

  const handleCancel = (e) => {
    return (window.location = "/ezytrip/administration/markup");
  };

  const handleReset = (e) => {
    dispatch({ type: "clear", payload: true });
    setCType({ ctyp: false });
    setAType({ atyp: false });
    setVType({ vtyp: false });
    setValue([]);
    setAir([]);
  };

const updateSalesChanel=(salesChanel)=>{
  dispatch({ type: "salesChanel", payload: salesChanel });
}

const updateOfcId=(ofc)=>{
  dispatch({ type: "ofc_id", payload: ofc });
}
const sendSup =(sup) =>{
  dispatch({type:"supplier",payload:sup})
}

  return (
    <>
      {state.notiMessageShow && (
        <Alert
          className="notification"
          variant={state.notiVarient}
          show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible
        >
          {" "}
          {state.notiMessage}
        </Alert>
      )}
      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <Form.Row>
        <Form.Group
          as={Col}
          xs={3}
          controlId="mn"
          className="d-flex align-items-center"
        >
          <Form.Control
            type="text"
            placeholder="Enter Markup Name"
            disabled={state.enableUpdate}
            value={state.mn}
            onChange={handleChange}
          />
          <Form.Label>
            Mark-up name <sup>*</sup>
          </Form.Label>
          <br />
          {state.showMessage && <span>Enater Markup Name</span>}
          {state.nameError && (
            <p className="vError">Please Enter Valid Markup Name</p>
          )}
        </Form.Group>
        </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={4}  className="inputCheck">
          <Form.Label>
            Flow Type <sup>*</sup>
          </Form.Label>
          <div className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              id="flb"
              label="Booking"
              custom
              className="ml-0"
              // defaultChecked
              checked = {bType.btyp}
              value={bType.btyp}
              onChange={handleBookTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="flc"
              label="Cancellation"
              custom
              className="ml-3"
              checked={cType.ctyp}
              value={cType.ctyp}
              onChange={handleCanTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="fla"
              label="Amendment"
              custom
              className="ml-3"
              checked={aType.atyp}
              value={aType.atyp}
              onChange={handleAmdTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="flv"
              label="Void"
              className="ml-3"
              custom
              checked={vType.vtyp}
              value={vType.vtyp}
              onChange={handleVoidTypeChange}
            />
          </div>
          {state.flError && (
            <p className="vError">Please Select FlowType</p>
          )}
        </Form.Group>
        <Form.Group as={Col} xs={4} controlId="ttype" className="inputCheck">
          <div className="d-flex align-items-center">
            <Form.Check
              type="radio"
              id="all"
              label="All"
              className="ml-0"
              name="ttype"
              custom
              checked={state.enableTripAll}
              value={state.ttype}
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="one"
              label="One Way"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripOne}
              value={state.ttype}
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="round"
              label="Round Trip"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripTwo}
              value={state.ttype}
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="multi"
              label="Multi City"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripThree}
              value={state.ttype}
              onChange={handleChange}
            />
          </div>
          <Form.Label>Travel Type</Form.Label>
        </Form.Group>
      </Form.Row>
      <Routes
        configType={"Markup"}
        updateData={updateRoutesData}
        sendRoutesData={rotesInfo}
        routesData={routDetails.rts}
        reset={state.reset}
        sendRouteDelData={routeDelete}
        disabledBtn={props.disableBtn}
      />
      <Form.Row>
      <Form.Group as={Col} className="picklist" xs={6} >
                {state.airlinesList && state.airlinesList.length !== 0 &&
                  <PickList leftPaneLabel="Available Airlines" rightPaneLabel="Selected Airlines" ofcsData={value} type={state.compType}  dataList={state.airlinesList} sendData={setAir} />
                }
                <Form.Label>Airlines</Form.Label>
              </Form.Group>
        {/* <Form.Group
          as={Col}
          xs={3}
          className="selectplugin"
          controlId="selectedValue"
        >
          <Select
            multi
            options={state.airlinesList}
            values={value}
            onChange={(value) => (setValue(value), setAir(value))}
          />
          <Form.Label>Airlines</Form.Label>
        </Form.Group> */}
        
      </Form.Row>

      <ApplicableDates
        configType={"Markup"}
        sendAppDates={applicableDates}
        updateData={updateApplDates}
        applDatesData={state.edit_applDates}
        reset={state.reset}
        sendApplicableId={callDeleteApp}
        disabledBtn={props.disableBtn}
      />
      <AirMarkupFareMapForm
        configType="Markup"
        sendFareData={fareMapData}
        fareData={state.fareList}
        markupId={state.markupId}
        disabledBtn={props.disableBtn}
        reset={state.reset}
        edit_region={state.edit_region}
        sendChannel={updateSalesChanel}
        sendOfcId={updateOfcId}
        edit_salesChanel={state.edit_salesChanel}
        edit_ofcid={state.edit_ofcid}
        edit_supplier={state.edit_supplier}
        sendSup ={sendSup}
        />

      <div className="buttonGrop mt-4 pb-0 switchPlus">
        {/* {!state.enableUpdate && (
          <Form.Group as={Col} className="topSwitch">
          <Form.Check
          type="switch"
              id="act"
              label="Status"
              checked={act}
              value={act}
              onChange={handleChange}
            />
          </Form.Group>
        )} */}
            <Form.Group as={Col} className="topSwitch">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              checked={act}
              value={act}
              onChange={handleChange}
              />
          </Form.Group>
              {state.enableUpdate && !props.disableBtn && (
                // <div className="text-right buttonGrop">

                  <Button
                    disabled = {!(context.logindata.roles && context.logindata.roles.split(",").includes('H_M_U'))}
                    size="xs"
                    onClick={updateMarkup}
                    variant="outline-primary"
                    type="submit"
                  >
                    Update
                  </Button>
                // </div>
              )}
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={handleCancel}
        >
          Back
        </Button>
        {!state.enableUpdate &&
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleReset}
          >
            Reset
        </Button>
        }
        {!state.enableUpdate && (
          <Button
            size="xs"
            onClick={handleSaveMarkup}
            variant="outline-primary"
            type="submit"
          >
            Save
          </Button>
        )}
      </div>
    </>
  );
}
export default AirMarkupForm;
