/**
 * @description: Flight Confirmation page Root Passgenger details component
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
 import React, { useState, useEffect, useReducer } from 'react';
 import { useHistory } from 'react-router-dom';
 import { Alert, Col, Container, Form, Row } from "react-bootstrap";
 import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
 let sPref = require('../../../masterData/SpecialAssistance.json');
 let mPref = require('../../../masterData/MealPreference.json');

 /**
  * Initial State Declaration
  */
 const initialState = {

     paxobj: '',bagAvail:false,mealAvail:false,seatAvail:false
 };


 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };

 /**
   * @description: This function will show the PassengerDetails
   * @date:09-01-2020
   * @author:Rambabu
   *
   */
 function PassengerDetails(props) {

     const [state, dispatch] = useReducer(reducer, initialState);
     const history = useHistory();
     const [stype , setSType] = useState(props.sty);

     useEffect(() => {

         //if props data avaliable
         if (props.paxdata) {
             let pax_array = [];
             let val=0;
             //If split fare need to display onward pax data and return pax data
             let value =0;
             if(props.sty!==undefined){
                 value= props.sty === 'normal' ? 0 : 1;
                 setSType(props.sty)
             }else{
                 value= props.paxdata.sty === 'normal' ? 0 : 1;
                 setSType(stype)
             }
             if(value===1 && props.flowType!=="" && props.flowType==="REISSUE"){
                 val=1;
             }
             if(props.tf_flow){
                 value=0;
             }

             //if split fare two times it will iterate
             for (let i = val; i <= value; i++) {

                 //iterating pax details
                 for (let p in props.paxdata.psngrs) {
                     let pax = props.paxdata.psngrs[p];
                     //here checking split fare or normal fare
                     let bgd_da =[];
                     let sector_da=[];
                     // if(props.flowType!=="" && props.flowType!=="REISSUE" && props.paxdata.sup==="0"){
                     //     bgd_da  = (i === 0 ? props.paxdata.origns[0]['srcorigns'][0].bgd : props.paxdata.origns[0]['destorigins'][0].bgd);
                     //     sector_da = (i === 0 ? props.paxdata.origns[0]['srcorigns'][0].seg : props.paxdata.origns[0]['destorigins'][0].seg);
                     // }else if(props.paxdata.sup==="0"){
                     //     sector_da = props.paxdata.origns[0]['srcorigns'][0].seg;
                     // }
                     //let status = (i === 0 ? props.paxdata.origns[0]['srcorigns'][0].st : props.paxdata.origns[0]['destorigins'][0].st);
                     var status=pax.tktst;
                     var segstatus=pax.segst;
                     var statusCls="";
                     if(pax.tktst==="Confirmed"){
                         status="Confirmed";
                         statusCls="status confirm";
                         segstatus="Confirmed";
                     }else if(pax.tktst==="On hold" || pax.tktst==="On Hold"){
                         status="On Hold";
                         segstatus="Confirmed"
                         statusCls="status confirm";
                     }else if(pax.tktst==="Ticketing failed" || pax.tktst==="Ticketing Failed"){
                         status="Ticketing Failed";
                         segstatus="Confirmed";
                         statusCls="status confirm";
                     }else if(pax.tktst==="Partially Ticketed"){
                         status="Partially Ticketed";
                         segstatus="Confirmed";
                         statusCls="status confirm";
                     }

                     let bag = ''
                     let sector = '';
                     let sectorDest = '';
                     //constructiong baggage data(sector wise)
                     if (isArrayNotEmpty(bgd_da) && bgd_da.length!==0) {
                         let qun = bgd_da[0].qun;
                         let wt = bgd_da[0].wt;
                         bag = qun + ' ' + wt
                     }

                     //constructing sector data
                     if (isArrayNotEmpty(sector_da) && sector_da.length!==0) {

                         let source = sector_da[0].da;
                         let destination = sector_da[sector_da.length - 1].ar;
                         if(props.paxdata.tt!=="2"){
                             sector = source + '-' + destination;
                         }else{
                             sector = source + '-' + destination;
                             sectorDest = destination + '-' + source;
                         }

                     }


                     //constructing special request data
                     let specialRequest = '';
                     if (isNotNull(pax.opref)) {
                         if(pax.opref.sa!==""){
                             let spref=sPref.filter((obj)=>obj.code === pax.opref.sa);
                             if(spref!==undefined && spref[0]!==undefined){
                                 specialRequest = spref[0].name;
                             }
                         }
                         if(pax.opref.sp!==""){
                             if(specialRequest!==""){
                                 specialRequest =specialRequest + '/' + pax.opref.sp;
                             }else{
                                 specialRequest = pax.opref.sp;
                             }
                         }
                         if(pax.opref.mp!==""){
                             let mpre=mPref.filter((obj)=>obj.code === pax.opref.mp);
                             if(specialRequest!==""){
                                 specialRequest =specialRequest + '/' +mpre[0].name;
                             }else{
                                 specialRequest = mpre[0].name;
                             }
                         }
                     }
                     //constructiong json object
                     let ticktNumber="";

                     if(stype!=="" && (stype === 'normal' || (props.flowType!==undefined && props.flowType === 'REISSUE'))){
                         ticktNumber=pax.tkt;
                     }else if(pax.tkt!==undefined){
                         let tktary=pax.tkt.split("/");
                         if(i===0){
                             ticktNumber=tktary[0];
                         }else{
                             ticktNumber=tktary[1];
                         }
                     }else{
                         ticktNumber=pax.tkt;
                     }
                     let bagLabel="";
                        if(pax.baggage!==undefined && pax.baggage.label!==undefined){
                              let bag_array = pax.baggage.label.replace("total", "")
                             bagLabel=bag_array;
                             dispatch({ type: 'bagAvail', payload: true })
                         }
                         let mealLabel="";
                         if(pax.meal!==undefined && pax.meal.label!==undefined){
                             let meal_array = pax.meal.label.split("-")
                             mealLabel=meal_array;
                             dispatch({ type: 'mealAvail', payload: true })
                         }
                         let seatLabel="";
                         if(pax.seats!==undefined && pax.seats.length!==0 && props.sector!==undefined){
                             var seatsList = pax.seats.filter((obj) => (obj.jtype === props.sector ) || (props.sector==="1" || props.sector==="2"));
                             if(seatsList.length!==0){
                                 for(let seat of seatsList){
                                   if(seatLabel===""){
                                     seatLabel=seat.sno;
                                   }else{
                                     seatLabel=seatLabel+","+seat.sno;
                                   }
                                 }
                             }
                             dispatch({ type: 'seatAvail', payload: true })
                         }

                     const obj = {
                         nm: pax.fn + ' ' + pax.ln,
                         lead: pax.lead,
                         ty: pax.ty,
                         sec: sector,
                         destsec:sectorDest,
                         tkt: ticktNumber,
                         bgd: bag,
                         spr: specialRequest,
                         st: status,
                         segst:segstatus,
                         stc:statusCls,
                         baglbl:bagLabel,
                         meallbl:mealLabel,
                         seatlbl:seatLabel
                     }
                     pax_array.push(obj);


                     //if infant, constructing data
                     if (isNotNull(pax.inf)) {
                         let ticktNumber="";
                         if(stype!=="" && (stype === 'normal' || (props.paxdata.flowType!==undefined && props.paxdata.flowType === 'REISSUE'))){
                             ticktNumber=pax.inf.tkt;
                         }else if(pax.inf.tkt!==undefined){
                             let tktary=pax.inf.tkt.split("/");
                             if(i===0){
                                 ticktNumber=tktary[0];
                             }else{
                                 ticktNumber=tktary[1];
                             }
                         }

                         const obj = {
                             nm: pax.inf.fn + ' ' + pax.inf.ln,
                             ty: 'INF',
                             sec: sector,
                             destsec:sectorDest,
                             tkt: ticktNumber,
                             st: status,
                             segst:segstatus,
                             stc:statusCls
                         }
                         pax_array.push(obj);
                     }
                 }
             }
             dispatch({ type: 'paxobj', payload: pax_array })

         }



     }, []);



     return (

         isArrayNotEmpty(state.paxobj) && (
             <>
             <div className='table-responsive mobileTable'>
                 <div className="header headerlist">

                     <Row>
                         <Col xs="2">Traveller</Col>
                         <Col>Pax Type</Col>
                       <Col xs="2">Ticket Numbers</Col>
                         {state.bagAvail &&
                             <Col>Baggage</Col>
                         }
                         {state.mealAvail &&
                             <Col>Meal</Col>
                         }
                         {state.seatAvail &&
                             <Col>Seat</Col>
                         }
                         {props.paxdata.sup == "0" && <Col xs="2">Special Request</Col>}
                         <Col >Segment Status </Col>
                         <Col>Ticketing status</Col>
                     </Row>

                 </div>
                 <div className="passengerList">
                 {/* comfirmation page passenger details for split fare onward pax and return pax we are showing seperatly */}
                         {state.paxobj.map((pax, index) => (
                             <div className="list">
                             <React.Fragment key={index}>
                                 <Row>
                                     <Col xs="2">{pax.nm.toUpperCase()}{pax.lead && '(Lead)'}</Col>
                                     <Col>{pax.ty}</Col>
                                     <Col xs="2">{pax.tkt ? pax.tkt : "N/A"}</Col>
                                     {state.bagAvail &&
                                     <Col>{pax.baglbl?pax.baglbl:"N/A"}</Col>
                                     }
                                     {state.mealAvail &&
                                     <Col>{pax.meallbl?pax.meallbl:"N/A"}</Col>
                                     }
                                     {state.seatAvail &&
                                     <Col>{pax.seatlbl?pax.seatlbl:"N/A"}</Col>
                                     }
                                     {props.paxdata.sup == "0" && <Col xs="2" className="special">
                                     <span>{isNotNull(pax.spr) ? pax.spr : "-"}</span></Col>}
                                     <Col className={pax.stc}>{pax.segst}</Col>
                                     {pax.st!=="Partially Ticketed" &&
                                          <Col className={"status paxstatus " + ((pax.st == "Confirmed") && ('text-success')||(pax.st == "Incomplete") && ('text-primery') ||(pax.st == "On Hold") && ('text-primery')  || (pax.st == "Discarded") && ('text-danger') || (pax.st == "Failed") && ('text-danger')|| (pax.st == "Cancelled") && ('text-danger'))}>{pax.st}</Col>
                                     }
                                     {pax.st==="Partially Ticketed" &&
                                          <Col className={pax.tkt?'text-success':'text-danger'}>{pax.tkt? "Confimed":pax.st}</Col>
                                     }

                                 </Row>
                             </React.Fragment>
                             </div>
                         ))}
                 </div>
                 </div>
             </>
         )
     )
 }


 export default PassengerDetails;

