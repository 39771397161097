import React, {useState,useEffect} from 'react'
import { useIdleTimer } from 'react-idle-timer'
import App from './App'
//import { SessionExpiryDlg } from './SessionExpiryDlg';
export const USER_AUTH_DATA = "userAuthData";
const USR_MIN = process.env.REACT_APP_USER_TIMED_OUT
export default function SessionExpiry (props) {
  

  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    let userData=localStorage.getItem(USER_AUTH_DATA);
    // if(userData!==null && userData!==undefined){
    //     SessionExpiryDlg(function () {
    //     });
    // }
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something', event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * USR_MIN,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <div>
     <App/>
    </div>
  )
}