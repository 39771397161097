
import React, { useState, useEffect}from 'react';
import DateUtils from '../commonUtils/DateUtils';
import Tabs from '../../components/common/tabs/Tabs';
import PackageInfo from './PackageInfo';
import { Alert, Col, Modal, Row,Button,Form } from "react-bootstrap";
import {sendPackageEmail} from './operations';
let stdCodesMaster = require('../masterData/CountrySTDCodes.json');

/**
 * @description:This Login pageLayout
 * @author: Pravas
 * @param {*}
 * @function Package details
 * @date : 21-09-2020
 */

function PackageDetails(props) {
  const [state, setState] = useState({fn:"",ln:"",phc:"",phn:"",em:"",desc:""})
  const [showEnqry,setShowEnqury] = useState(false)
  const [pacakge,setPackage] = useState({active: 'overview'});
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [stdCodeMenu, setStdCodeMenu] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [isItineraryLoading , setIsItineraryLoading] = useState(false);

const content = {
    overview: <>{props.selpackage!==undefined && <PackageInfo overView={props.selpackage.overview_en} isOver={true}/>}</>,
    inclusions: <>{props.selpackage!==undefined && <PackageInfo incluExclusion={props.selpackage.inclusive_en} isOnclu={true} />}</>,
    cancel: <>{props.selpackage!==undefined && <PackageInfo cancellation={props.selpackage.cancellation_en} isCancel={true} />}</>,
    terms: <>{props.selpackage!==undefined && <PackageInfo terms_en={props.selpackage.terms_en} isTerms={true} />}</>
};

useEffect(() => {
  const stdCodesList = stdCodesMaster.map((std) =>
        <option value={std.code}>{std.name}</option>
    );
    setStdCodeMenu(stdCodesList)
}, [])

function hidemessage() {
  setTimeout(function () {
    setNotiMessageShow(false);
    setNotiMessage("");
    setNotiVarient("");
  }, 5000);
}

const validate=()=>{
 if(state.fn===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter First name");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }else if(state.ln===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter Last name");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }else if(state.em===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter Email");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }else if(state.phn===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter Phone");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }else if(state.desc===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter Description");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }else if(state.phc===""){
  setNotiMessageShow(true);
  setNotiMessage("Enter Phone Code");
  setNotiVarient("danger");
  hidemessage()
  return false;
 }
 return true
}

const closeForm=()=>{
  setShowEnqury(false);
  setState({fn:"",ln:"",phc:"",phn:"",em:"",desc:""})
}

const sendEnquryMail=()=>{
  if(validate()){
    setIsItineraryLoading(true)
     state.pkg_nm=props.selpackage.package_name_en;
    sendPackageEmail(state).then(response => {
      if(response.suc){
        setNotiMessageShow(true);
        setNotiMessage("Mail sent successfully");
        setNotiVarient("success");
        setTimeout(function () {
          setShowEnqury(false)
        }, 5000);
      }else{
        setNotiMessageShow(true);
        setNotiMessage("Mail Not sent");
        setNotiVarient("danger");
        hidemessage()
        setShowEnqury(false)
      }
      setIsItineraryLoading(false)
      setState({fn:"",ln:"",phc:"",phn:"",em:"",desc:""})
     }).catch(function (error) {
       console.log('sendEnquryMail response error ', error)
     });
  }
}

const handleChange = (e) => {
    const { id, value } = e.target
    setState(prevState => ({
        ...prevState,
        [id]: value
      }))
  };

const openEnquryForm=()=>{
    setShowEnqury(true)
    setNotiMessageShow(false);
    setNotiMessage("");
    setNotiVarient("");
}

  return (
    <>
     {props.selpackage!==undefined &&
            <div className="costBox bookingSummary">
               <h4> {props.selpackage.package_name_en}</h4>
                <div className="hotelSummary">
                    <div className="img"> <img
                        src={props.selpackage.image}
                        className="htlImg"
                        title="Hotel"
                        alt="Hotel"
                    /></div>
                    <div className="sumryInfo">
                        <div className="extDetails">
                        {/* <span className="d-flex">QAR <strong>{props.selpackage.amt}</strong> </span> */}
                        {/* <div className="d-flex packagesName">
                        <span className="ezyIcon icon-time">  {props.selpackage.is_trending &&
                         <span> Trending Package </span>
                        }</span>
                        {props.selpackage.is_staynearby &&
                         <span> NearBy </span>
                        }
                        {props.selpackage.is_topdestination &&
                         <span> Top Destination </span>
                        }
                        </div> */}
                       <span className="ezyIcon icon-calendar">{DateUtils.convertToDateFormatewithoutTime(props.selpackage.from_date)} to {DateUtils.convertToDateFormatewithoutTime(props.selpackage.to_date)}</span>
                        </div>
                        <Button
                        size="xs"
                        variant="primary"
                        onClick={openEnquryForm}
                        type="submit"
                        > Submit Enquiry</Button>
                    </div>
                </div>
                <div className="tabLink">
                    <Tabs active={pacakge.active} onChange={active => setPackage({ active })}>
                        <span key="overview" >Overview</span>
                        <span key="inclusions" >Inclusions & Exclusions</span>
                        <span key="cancel" >Cancellation</span>
                        <span key="terms" >Terms & Conditions</span>
                    </Tabs>
                    <div className="tabContent">
                        {content[pacakge.active]}
                    </div>
                </div>
            </div>
           }

      <Modal
        show={showEnqry}
        onHide={closeForm}
        className="importPNRMdl secondPopup"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="text-center">
            <Alert className="notification" variant={notiVarient} show={notiMessageShow}
            onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
          {isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <Form.Row>
        <h4>{props.selpackage.package_name_en}</h4>
        <Form.Group as={Col} xs={12} controlId="fn">
          <Form.Control type="text" required placeholder="Enter First Name" value={state.fn} onChange={handleChange} />
            <Form.Label>First Name <sup>*</sup></Form.Label>
            <Form.Control.Feedback type="invalid">
                Please provide Name.
            </Form.Control.Feedback>
          </Form.Group>
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} xs={12} controlId="ln">
          <Form.Control type="text"  placeholder="Details" placeholder="Enter Last Name" value={state.ln} onChange={handleChange} className="d-flex align-items-center"/>
            <Form.Label>Last Name <sup>*</sup></Form.Label>
          </Form.Group>
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} xs={3} controlId="phc" className="customSelect">
              <Form.Control as="select" value={state.phc} onChange={handleChange}>
                {stdCodeMenu}
              </Form.Control>
            </Form.Group>
          <Form.Group as={Col} xs={9} controlId="phn">
          <Form.Control type="text"   placeholder="Enter Phone Number" value={state.phn} onChange={handleChange} className="d-flex align-items-center"/>
          <Form.Label>Contact Number <sup>*</sup></Form.Label>
          </Form.Group>
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} xs={12} controlId="em">
          <Form.Control type="text"   placeholder="Enter Email" value={state.em} onChange={handleChange} className="d-flex align-items-center"/>
            <Form.Label>Email <sup>*</sup></Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={12} controlId="desc">
          <Form.Control as="textarea" rows="3"   placeholder="Enter Description" value={state.desc} onChange={handleChange} className="d-flex align-items-center"/>
            <Form.Label>Description <sup>*</sup></Form.Label>
          </Form.Group>
        </Form.Row>
        </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
        <Button
            size="xs"
            variant="primary"
            onClick={sendEnquryMail}
            type="submit"
            > Submit Enquiry</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PackageDetails