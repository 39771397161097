
import React, { useState, useEffect } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";
import { getParentCompData } from '../BookingSummary/operations';

function InvoiceSummaryPdf(props) {
    const [state, setState] = useState({})
    const [bkdata, setBkData] = useState(props.bkngdetails);
    const [compResp, setCompResp] = useState(undefined)

    useEffect(() => {
        if (props.type === "STA" && props.parent_id !== undefined) {
            getParentCompData(props.parent_id).then((response) => {
                if (response.suc) {
                    setCompResp(response.res[0]);
                }
            });
        }
    }, [props.type])

    return (
        <>
             <div className="invoice">
            <Row>
                <Col xs="2">
              {props.bkngdetails!==undefined && (props.type === "TA" || (compResp!==undefined && compResp.comp_logo_url==null) ||  (props.companyLogo===null ||  props.companyLogo=="")) &&
              <div className="img"> <img
               src={logo}
               className="htlImg"
               /> </div>
              }
              {compResp!==undefined && props.type === "STA" && compResp.comp_logo_url!==undefined && compResp.comp_logo_url!==null &&
              <div className="img"> <img
               src={compResp.comp_logo_url}
               className="htlImg"
               /> </div>
              }
             {props.bkngdetails!==undefined && props.bkngdetails!==null && props.bkngdetails.company_logo!==null && props.type === "CUSTOMER" &&
              <div className="img"> <img
               src={props.bkngdetails.company_logo}
               className="htlImg"
               /> </div>
              }
                </Col>
                <Col xs={10}>
                <Row>
                {props.type === "TA" &&
                    <Col xs={6}>
                    <strong>Ezytrip</strong>
                    <p><span>Contact Number : </span> +974 44484848</p>
                    <p><span>Email Id  : </span> Support@ezytrip.com</p>


                    </Col>
                }
                {compResp !== undefined && props.type === "STA" &&
                    <Col xs={6}>

                            <span>{compResp.company_name}, </span>
                            <span>{compResp.company_add}, </span>
                            <span>{compResp.comp_city_name}, {compResp.comp_cnt_name}, </span>
                            <span>{compResp.contact_number}, </span>
                            <span>{compResp.email_id}</span>

                    </Col>
                }
                {props.type === "CUSTOMER" && props.bkngdetails!==undefined &&
                    <Col xs={6}>

                            <span>{props.bkngdetails.company_name}, </span><br/>
                            {props.bkngdetails.phone_no}, <br/>
                            <span>{props.bkngdetails.email}</span>

                    </Col>
                }

               {props.type === "TA" &&
                <Col xs="6" className="address">
                    <p><strong> Corporate Office :</strong></p>
                    <p>Wakrah Boulevard,
                    Abdul Rahman Bin Jasim Al-Thani Street,
                    Shop No.9 Wakra-Qatar,
                    P.O Box:9736.</p>
                </Col>
               }

                {props.type === "CUSTOMER" &&
                <Col xs="6" className="address">
                    <p><strong> Corporate Office :</strong></p>
                    <span>{props.bkngdetails.comp_add},</span><br/>
                    <span>{props.bkngdetails.comp_city},</span><br/>
                    <span>{props.bkngdetails.comp_cnt}</span>
                </Col>
               }
                </Row>
           </Col>
           </Row>
        </div>
            <div className="invoicebookingData">
                <div className="rowlist">
                <Row>
                    <Col xs={4}><span>Booking Id : </span> {props.bkngdetails.booking_id}</Col>
                    <Col xs={4}><span>Booking Date : </span> {DateUtils.prettyDate(props.bkngdetails.booking_date, "ddd, DD MMM, YYYY")}</Col>
                    <Col xs={4} className="d-flex align-items-center"><span className="mr-1"> Booking Status : </span>
                        {props.bkngdetails.booking_status_str === "Cancelled" &&
                            <span className="status paxstatus text-danger"> Cancelled</span>
                        }
                        {(props.bkngdetails.booking_status_str == "On hold" || props.bkngdetails.booking_status_str == "On Hold") &&
                            <span className="status paxstatus"> On hold</span>
                        }
                        {props.bkngdetails.booking_status_str === "Confirmed" &&
                            <span className="status paxstatus text-success"> Confirmed</span>
                        }
                        {props.bkngdetails.booking_status_str === "Partially Confirmed" &&
                            <span className="status paxstatus text-success"> Confirmed</span>
                        }
                        {props.bkngdetails.booking_status_str === "Incomplete" &&
                            <span className="status paxstatus text-danger"> {props.bkngdetails.booking_status_str}</span>
                        }
                    </Col>
                </Row>
                </div>
                <div className="rowlist">
                <Row>
                    <Col xs={4}><span>Booked By : </span> {props.bkngdetails.booked_by}</Col>
                    <Col xs={4}><span>Contact Number  : </span>{props.phone_num} </Col>
                    <Col xs={4}><span>Email  : </span>{props.emialId}</Col>
                </Row>
                </div>
            </div>

        </>
    )
}

export default InvoiceSummaryPdf;
