import UserProfileService from '../../../../services/administration/UserProfileService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */

export const searchProfile = async (req) => {
    let resp = [];
    await UserProfileService.searchProfiles(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const fetchTravellers = async (req) => {
    let resp = [];
    await UserProfileService.getTravellers(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const updateProf = async (req) => {
    let resp = [];
    await UserProfileService.updateProfile(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const updateTravellers = async (req) => {
    let resp = [];
    await UserProfileService.updateTraveller(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};


