import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { confirmAlert } from "react-confirm-alert";
import { Alert, Col, Form, Table } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from 'react-dropdown-select';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import InnerLoader from '../../../common/loader/InnerLoader';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import { countriesMenu } from '../../../../components/adminstration/utils';
import { getAirportData } from '../../airServices/operations';
import { getairlineData, airlineCreation, airlineUpdate, airlineDelete } from './operations';
let airlineMaster = require('../../../masterData/AirlinesMasterData.json');
let rbdMaster = require('../../../masterData/Rbd.json');


   /**
   * @description: Hold airline configuration
   * @date:25-05-2022
   * @author:Pawan
   * @param {*} e
   */


/**
 * Initial State Declaration
 */
const initialState = {
  airlinesList: [],countriesList: [], air: [], rbdMaster: [], source: [], destination: [], classCode: [], holdairlineDataList: [], notiMessageShow: '', notiMessage: '', notiVarient: '', enableView: false, enableUpdate: false, defaultRBDmaster: [], selected_id: "", fair: "", tair: "", type: "",isItineraryLoading:false
};



const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

function HoldAirline(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [enableAirline, setEnableAirline] = useState(true)

  useEffect(() => {
    let airs = [];
    airs = airlineMaster;
    airs.unshift({ value: "All", label: "All" });
    let airlines = getUnique(airs, "value");
    dispatch({ type: "airlinesList", payload: airlines });
    countriesMenu().then(response => {
      if (response.data.suc) {
        let initres = response.data.res
        let newResponse = initres.map(item => {
          return { label: item.name, value: item.code };
        });
        newResponse.unshift({ label: "All", id: 0, value: "All" });
        dispatch({ type: 'countriesList', payload: newResponse })
      }
    })
    .catch(function (error) {
      log.error(
        "Exception occured in getCountryList function---" + error
      );
    });
  }, [enableAirline]);
  
  useEffect(() => {
    getairlineData().then((res) => {
      res = res.filter((value, index, self) =>
  index === self.findIndex((t) => (
    t.ac === value.ac && t.fcnt === value.fcnt && t.tcnt === value.tcnt && t.rbd === value.rbd
  ))
)
      
      constructData(res)

      loadResponse(res);
      dispatch({ type: 'isLoadingMore', payload: false })
          }).catch(function (error) {
            log.error(
              "Exception occured in getAirportData function---" + error
            );
          });
  }, [state.airlinesList && state.countriesList]);

  const handleConstructData = (type, code)=>{
    if(type == "ac"){
      if(code == "All"){
        return "All"
      }
      else{      
      for(let val of state.airlinesList){
        if(val.value == code ){
          return val.label
        }
      }
    }
    }
    else if(type == "rbd"){
      if(code !== null){
      if(code == "All"){
        return [{label : "All", value: "All"}]
      }     
      else{
        let splittedArray = code.replaceAll(",", "")
        let data = []
        for(let val of splittedArray){
          val = {label : val, value: val}
          data.push(val)
        }
        return data
      }
    }
    }   
    else{
      if(code == "All"){
        return "All"
      }
      else{
      for(let val of state.countriesList){
        if(code == val.value ){
          return val.label
        }
      }
    }
    }
  }
  const constructData = (data)=>{
    for(let val of data){
      val.ac = [{"label" : handleConstructData("ac", val.ac), value: val.ac}]
      val.fcnt = [{"label" : handleConstructData("fcnt", val.fcnt), value: val.fcnt}]
      val.tcnt = [{"label" : handleConstructData("tcnt", val.tcnt), value: val.tcnt}]
      val.rbd = handleConstructData("rbd", val.rbd)
    }
    return data

  }
  const loadResponse = (response) => {
    let data = [];
    for (let val of response) {
      data.push({ type : val.type === "airline" ? "Airline" : "RBD", ac: val.ac, fcnt: val.fcnt, tcnt: val.tcnt, rbd : val.rbd,fair: val.fair, tair: val.tair, id: val.id,
        options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editholdairlineBtnClick(val, "view")}></a>  <a className="ezyIcon icon-edit ml-1 mr-1" onClick={editholdairlineBtnClick(val, "edit")}></a> <a className="ezyIcon icon-delete ml-1 mr-1" onClick={editholdairlineBtnClick(val, "delete")}></a></span></>
       })
    }
    for(let val of data){
      val.ac =  val.ac[0].label !== undefined ? val.ac[0].label : "N/A" 
      val.fcnt = val.fcnt[0].label !== undefined ? val.fcnt[0].label : "N/A"
      val.tcnt = val.tcnt[0].label !== undefined ? val.tcnt[0].label : "N/A"
      val.rbd = val.rbd !== undefined && val.rbd.length !== 0 ? joinclassCode(val.rbd) : "N/A"
    }
    dispatch({ type: 'holdairlineDataList', payload: data })
  }

  useEffect(() => {
    if (rbdMaster.length > 0) {
      dispatch({ type: 'rbdMaster', payload: rbdMaster })
      dispatch({ type: 'defaultRBDmaster', payload: rbdMaster })
    }
  }, [rbdMaster])

  function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const handleAirline = () => {
    setEnableAirline(true)
    dispatch({ type: 'air', payload: [] })
    dispatch({ type: 'classCode', payload: [] })
    dispatch({ type: 'selected_id', payload: "" })
  }
  const handleRBD = () => {
    setEnableAirline(false)
    dispatch({ type: 'air', payload: [] })
    dispatch({ type: 'source', payload: [] })
    dispatch({ type: 'destination', payload: [] })
    dispatch({ type: 'selected_id', payload: "" })
    dispatch({ type: 'fair', payload: "" })
    dispatch({ type: 'tair', payload: "" })
  }
  const handlechangeAir = (e) => {
    dispatch({ type: "air", payload: e });
  }

  const handleChangeCountry =(obj, type) =>{
    if (obj[0]) {
      if (type === "source") {
        if (obj[0].value !== "All") {
          getAirportData(obj[0].value).then(response => {
            if (response.data.suc) {
              dispatch({ type: 'source', payload: [{label : handleConstructData("fcnt", obj[0].value), value: obj[0].value }]})
              dispatch({ type: 'fair', payload: response.data.res[0].value})
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getAirportData function---" + error
            );
          });
        } else {
          dispatch({ type: 'source', payload: [{value: "All", label: "All" }] })
          dispatch({ type: 'fair', payload: ""})
        }
      } else {
        if (obj[0].value !== "All") {
          getAirportData(obj[0].value).then(response => {
            if (response.data.suc) {
              dispatch({ type: 'destination', payload:[{label : handleConstructData("tcnt", obj[0].value), value: obj[0].value }]})
              dispatch({ type: 'tair', payload: response.data.res[0].value})
            }
          }).catch(function (error) {
            log.error(
              "Exception occured in getAirportData function---" + error
            );
          });
        } else {
          dispatch({ type: 'destination', payload: [{ label: "All", value: "All" }] })
          dispatch({ type: 'tair', payload: ""})         
        }
      }
    } else {
      //If Source Country and Destination Country
      if (type === "source") {
        dispatch({ type: 'source', payload: [] })
        dispatch({ type: 'fair', payload: ""})
      } else {
        dispatch({ type: 'destination', payload: [] })
        dispatch({ type: 'tair', payload: ""})

      }
    }
  }
  const setCls = (data) => {
    if (data.length > 0 && data[0].value === "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      dispatch({ type: 'rbdMaster', payload: data })
      // setValue(data)
    } else if (data.length > 0 && data[0].value !== "All") {
      var finalrbd = "";
      let rbds = [];
      data.map((obj) => {
        if (finalrbd) {
          finalrbd = finalrbd + "|" + obj.value;
        } else {
          finalrbd = obj.value;
        }
      }
      );
      rbds.push(finalrbd);
      dispatch({ type: 'rbd', payload: rbds })
      dispatch({ type: 'classCode', payload: data })
      // setValue(data)
      removeAll()
    } else {
      let cls = getUnique(state.defaultRBDmaster, "value")
      dispatch({ type: 'rbdMaster', payload: cls })

      dispatch({ type: 'rbd', payload: [] })
      dispatch({ type: 'classCode', payload: data })
      // setValue(data)
    }
  }
  const removeAll = (e) => {
    let arr = []
    for (let val of state.rbdMaster) {
      arr.push(val.value)
    }
    if (arr.indexOf("All") > -1) {
      const index = arr.indexOf("All");

      state.rbdMaster.splice(index, 1);
    }
  }
  const validateholdAirline = (type)=>{
    if(state.air.length == 0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Airline' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    if(enableAirline){
 if(state.source.length == 0){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select From Country' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      else if(state.destination.length == 0){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select To Country' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    else{
      if(state.classCode.length == 0){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select RBD' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    if(state.air.length !== 0){
      for(let val of state.holdairlineDataList){
        if(type == "save"){
        if(state.air[0].label == val.ac){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Airline hold configuration already exist. Please select other Airline' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
            return false;
           } 
      }                
    }
    }
    return true
  }
  const handleholdAirline = ()=>{
    if (validateholdAirline("save")) {
    if(enableAirline){
    var payload = {
      "ac": state.air[0].value,
      "fcnt": state.source[0].value,
      "fair": state.fair,
      "tair": state.tair,
      "tcnt":state.destination[0].value,
      "type":"airline",
      "rbd": "",

    }
  }
  else{
    let clssCode = ""
    if(state.classCode[0].value == "All"){
      clssCode = state.classCode[0].value 
    }
    else{
      clssCode = joinclassCode(state.classCode)
    }
    dispatch({ type: "isItineraryLoading", payload: true });
    var payload = {
      "ac":state.air[0].value,
      "rbd": clssCode,
      "type": "rbd",
      "fair": state.fair,
      "tair": state.tair,
    }

  }
    airlineCreation(payload).then(response=>{      
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: response.res})
      dispatch({ type: 'notiVarient', payload: 'success' })
      handleReset()
      window.location = "/ezytrip/administration/holdairline"
    }).catch(function (error) {
      dispatch({ type: "isItineraryLoading", payload: false });

      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: "Something went wrong" })
      dispatch({ type: 'notiVarient', payload: 'success' })
      console.log(
        "Exception occured in handleRemovecompanyEvent function---" + error
      );
    });
    }
  }
const joinclassCode = (code)=>{
  if(code == "All"){
    return "All"
  }
  else{
let data = []
for(let val of code){
  data.push(val.value)
}
let joinedString = data.join(",")
return joinedString
  }
}
  const handleReset =()=>{
    window.location = "/ezytrip/administration/holdairline"    
  }
function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  // if(enableAirline){
  var columns = [
    {
    dataField: 'type',
    text: 'Hold Type',
    sort: true,
    filter: textFilter()
  },
    {
    dataField: 'ac',
    text: 'Airline',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'fcnt',
    text: 'From Country',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'tcnt',
    text: 'To Country',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'rbd',
    text: 'RBD',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

    const editholdairlineBtnClick = (data, type) => () => {
      window.scrollTo(0, 0);
      if(data.type == "airline" ){
        setEnableAirline(true)
      }
      else{
        setEnableAirline(false)
      } 
      if(type == "delete"){
        confirmDlg(data)
        return        
      }
          dispatch({ type: 'air', payload: data.ac });
          dispatch({ type: 'fair', payload: data.fair });
          dispatch({ type: 'tair', payload: data.tair });
          dispatch({ type: 'type', payload: data.type });
          dispatch({ type: 'source', payload: data.fcnt });
          dispatch({ type: 'destination', payload: data.tcnt });
          dispatch({ type: 'classCode', payload: data.rbd });
          dispatch({ type: 'selected_id', payload: data.id });
          if (type === "view"){
            dispatch({ type: 'enableView', payload: true });
            dispatch({ type: 'enableUpdate', payload: false });
        }else{
            dispatch({ type: 'enableView', payload: false });
            dispatch({ type: 'enableUpdate', payload: true });
        }
    }

    const updateholdairline = ()=>{
      if (validateholdAirline("update")) {

        if(enableAirline){
          var payload = {
            "ac": state.air[0].value,
            "fcnt": state.source[0].value,
            "fair": state.fair,
            "tair": state.tair,
            "tcnt":state.destination[0].value,
            "type":"airline",
            "rbd": "",
            "id": state.selected_id      
          }
        }
        else{
          let clssCode = ""
          if(state.classCode[0].value == "All"){
            clssCode = "All" 
          }
          else{
            clssCode = joinclassCode(state.classCode)
          }
          var payload = {
            "ac":state.air[0].value,
            "rbd": clssCode,
            "type": "rbd",
            "fair": state.fair,
            "tair": state.tair,
            "id": state.selected_id
          }
      
        }
        dispatch({ type: "isItineraryLoading", payload: true });
      airlineUpdate(payload).then(response=>{        
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: response.res })
      dispatch({ type: 'notiVarient', payload: 'success' })
        handleReset()
        setEnableAirline(true)
        window.location = "/ezytrip/administration/holdairline"
      }).catch(function (error) {
        dispatch({ type: "isItineraryLoading", payload: false });

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: "Something went wrong" })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        setEnableAirline(true)
        console.log(
          "Exception occured in handleRemovecompanyEvent function---" + error
        );
      });      
        }
    }
    const confirmDlg = (obj,cb) => {
      confirmAlert({
          customUI: () => {
              return (
                  <>
                      <div className="custom-ui">
                          <span className="confirmD"></span>
                          <h3>Are you sure?</h3>
                          <p className="t-color2 mb-1">You want to delete the configuration ?</p>
                      </div>
                      <div className="buttonGrop text-center border-0 pb-0">
                          <button
                              className="btn btn-xs btn-secondary pl-4 pr-4"
                              onClick={() => {
                                  handleDelete(obj);
                              }}>
                              Yes
                          </button>
                          <button className="btn btn-primary btn-xs pl-4 pr-4" onClick={handleClose}>
                              No
                          </button>
                      </div>
                  </>
              );
          }
      });
  };
    const handleDelete = (data)=>{
      const pyload = {
        "id": data.id
      }
      airlineDelete(pyload).then(response=>{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response})
        dispatch({ type: 'notiVarient', payload: 'success' })
        window.location = "/ezytrip/administration/holdairline"
        }).catch(function (error) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: "Something went wrong" })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          window.location = "/ezytrip/administration/holdairline"
          console.log(
            "Exception occured in handleRemovecompanyEvent function---" + error
          );
        });
    }
    const handleClose = ()=>{
      window.location = "/ezytrip/administration/holdairline"
    }
  return (

    <>

      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Hold Airline" />

      {
        <>
          <div className="container">

            <div className="cardMainlayout pt-0">
              <AirServiceConfigMenu active="Hold Airline" />

              <div className="contentArea">
              <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                            {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
                                  <Form.Row>
        <Form.Group as={Col} xs={3} controlId="appon" className="inputCheck">
          <div className="d-flex align-items-center">
            <Form.Check type="radio" 
             onChange={handleAirline} 
             checked={enableAirline}
             disabled={state.enableView || state.enableUpdate}
              value={state.airline_selected} label="Airlines" className="ml-0" name="ApplicableOn" id="airlines" custom />
            <Form.Check type="radio" 
             onChange={handleRBD} 
             disabled={state.enableView || state.enableUpdate}
             checked={!enableAirline}
              value={state.rbd_selected} label="RBD" className="ml-3" name="ApplicableOn" id="rbd" custom />
          </div>
          <Form.Label>Select On Hold Type<sup>*</sup></Form.Label>

        </Form.Group>
      </Form.Row>
                        <Form noValidate 
                        // validated={validated}
                         id="formId">
                 {enableAirline && <Form.Row>
                                <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="air"
                                >
                                    <Select
                                    // multi
                                    options={state.airlinesList}
                                    disabled={state.enableView || state.enableUpdate}
                                    values={state.air}
                                    onChange={(value)=>{handlechangeAir(value)}}
                                    />
                                    <Form.Label>Select Airline<sup>*</sup></Form.Label>
                                </Form.Group>
                                <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="air"
                                >
                                    <Select
                                    // multi
                                    options={state.countriesList}
                                    values={state.source}
                                    onChange={cnt => handleChangeCountry(cnt, "source")}
                                    disabled={state.enableView}
                                    />
                                    <Form.Label>From Country<sup>*</sup></Form.Label>
                                </Form.Group>

          <Form.Group
                                as={Col}
                                xs={3}
                                className="selectplugin"
                                controlId="air"
                                >
                                    <Select
                                    // multi
                                    options={state.countriesList}
                                    values={state.destination}
                                    onChange={cnt => handleChangeCountry(cnt, "destination")}
                                    disabled={state.enableView}
                                    />
                                    <Form.Label>To Country<sup>*</sup></Form.Label>
                                </Form.Group>
                            </Form.Row>}
                            {!enableAirline &&<Form.Row>
                            <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin"
                            controlId="rbdair"
                            >
                                <Select
                                options={state.airlinesList}
                                disabled={state.enableView || state.enableUpdate}
                                values={state.air}
                                onChange={(value)=>{handlechangeAir(value)}}
                                />
                                <Form.Label>Select Airline<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  multi
                  options={state.rbdMaster}
                  disabled={state.enableView}
                  values={state.classCode}
                  onChange={(value) => (setCls(value))}
                />
                <Form.Label>RBD<sup>*</sup></Form.Label>
              </Form.Group>
                            </Form.Row> }
                        </Form>
                        <div className="text-right buttonGrop pb-0">
                        <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    isDisabled={state.enableView}
                                    onClick={handleReset}
                                    type="submit"
                                >Reset</Button>
                            {(!state.enableUpdate && !state.enableView) &&
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={handleholdAirline}
                                    type="submit"
                                >Save</Button>
                            }
                            {state.enableUpdate &&
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateholdairline}
                                    isDisabled={state.enableView}
                                    type="submit"
                                > Update</Button>}
                          
                        </div>

              </div>
            </div>

            {/* hold airline Config DataTable */}
              <div className="cardMainlayout p-0 tableRes">
                {state.isLoadingMore ? <InnerLoader /> : (<>
                  {state.holdairlineDataList.length > 0 &&
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      classes="markup filterTbl"
                      data={state.holdairlineDataList}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={state.holdairlineDataList.length > 10 && state.holdairlineDataList.length <= 25 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }] }) :
                        state.holdairlineDataList.length > 25 && state.holdairlineDataList.length <= 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }] }) :
                          state.holdairlineDataList.length > 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }, { text: '50', value: 50 }] }) : ''}
                    />
                    || state.holdairlineDataList.length === 0 &&
                    <NoRecord />
                  }</>)
                }

              </div>
            {/* ) : ('') */}
            
          </div>

        </>
      }

      <Footer />
    </>
  )
}
export default HoldAirline