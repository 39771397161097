import React, { useState, useContext} from 'react';
import FormateCurrency from '../../commonUtils/FormateCurrency';
import "slick-carousel/slick/slick.css";
import {Row, Col, Table} from 'react-bootstrap';
import "slick-carousel/slick/slick-theme.css";
import { Context } from "../../../../App";
 function DashBoardBookings(props) {
  const [bookingData, setBookingsData] = useState(props.bookingsData);
  const [context, setContext] = useContext(Context);

    return (

          <div className='penal'>
        <>

        <Row>
          <Col xs="6">

            <Table>
<tr>
  <th><span className='ezyIcon icon-flight d-flex'><span className='pl-2'>FLIGHT BOOKINGS</span></span></th>
  <th align='center'>Ticket Count</th>
  <th align='right'>Amount ({context.logindata.cur})</th>
</tr>
{bookingData!==undefined && bookingData.flight!==undefined &&  bookingData.flight.length > 0 && bookingData.flight.map((flt)=>(
<tr>

    <>
  <td><span className={"status_"+flt.bk_st}>{flt.bk_st}</span></td>
  <td align='center'><span>{flt.bk_count}</span></td>
   <td align='right'>{<FormateCurrency value={flt.bk_price} />}</td>
  </>

</tr>
  ))}

  {bookingData!==undefined && bookingData.flight!==undefined && bookingData.flight.length === 0 &&
  <span className='nDatfound'>No Data Found</span>
  }


            </Table>


          </Col>

          <Col xs="6">
          <Row>
          <Col xs="12">

          <Table className='htlBoo'>
<tr className='hotelBookings'>
<th><span className='ezyIcon icon-newhotel d-flex'><span className='pl-2'>HOTEL BOOKINGS</span></span></th>
  <th align='center'>Room Count</th>
  <th align='right'>Amount ({context.logindata.cur})</th>
</tr>
{bookingData!==undefined && bookingData.hotel!==undefined && bookingData.hotel.length > 0 && bookingData.hotel.map((htl)=>(
<tr>

    <>
  <td><span className={"status_"+htl.bk_st}>{htl.bk_st}</span></td>
  <td align='center'><span>{htl.bk_count}</span></td>
   <td align='right'>{<FormateCurrency value={htl.bk_price} />}</td>
  </>

</tr>
  ))}

 {bookingData!==undefined && bookingData.hotel!==undefined && bookingData.hotel.length === 0 &&
  <span className='nDatfound'>No Data Found</span>
  }

            </Table>

          </Col>
          <Col xs="12">

          <Table className='insurdashBookings'>
<tr className='insurancesBookings'>
<th><span className='ezyIcon icon-plussheild d-flex'><span className='pl-2'>INSURANCE BOOKINGS</span></span></th>
  <th align='center'>Premium Count</th>
  <th align='right'>Amount ({context.logindata.cur})</th>
</tr>
{bookingData!==undefined &&  bookingData.insurance !==undefined && bookingData.insurance.length > 0 && bookingData.insurance.map((ins)=>(
<tr>

    <>
  <td><span className={"status_"+ins.bk_st}>{ins.bk_st} - {ins.bk_type}</span></td>
  <td  align='center'><span>{ins.bk_count}</span></td>
   <td align='right'>{<FormateCurrency value={ins.bk_price} />}</td>
  </>

</tr>
  ))}

{bookingData!==undefined && bookingData.insurance!==undefined && bookingData.insurance.length === 0 &&
  <span className='nDatfound'>No Data Found</span>
  }
            </Table>
          </Col>
          </Row>
          </Col>


        </Row>





         </>
         </div>



         )
    }

export default DashBoardBookings;