/**
 * @description : Dash Board Details Will diplay in this page
 * @author : Anil Kumar Ch
 * @date : 04-09-2020
 */
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Alert, Col, Row, Modal, Button, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Context } from "../../../App";
import DashBordHeader from "../common/dashBoard/DashBordHeader";
import PopularRoutes from "../common/dashBoard/PopularRoutes";
import DashBordNotifications from "./DashBordNotifications";
import TopAirlines from "../common/dashBoard/TopAirlines";
import TopSellingPackages from "../common/dashBoard/TopSellingPackages";
import EzytripUpdateandPromotion from "../common/dashBoard/EzytripUpdateandPromotion";
import SaudiActivities from "../common/dashBoard/SaudiActivities";
import TopHotels from "../common/dashBoard/TopHotels";
import PopularCities from "../common/dashBoard/PopularCities";
import Footer from "../common/Footer";
import DashBoardPNRImport from "./DashBoardPNRImport";
import FlightLatestBookings from "./FlightLatestBookings.jsx";
import Notificationimge from "../../assets/images/happynewyear.jpg";
import DashboardMyReports from "./DashboardMyReports.jsx";
import { pnrSearch } from "./operations";
import ShowHide from "../common/showHide/ShowHide";
export const SHOW_NOTIFY = "showNotify";
/**
 * Initial State Declaration
 */
const initialState = {
  pnr: "",
  notiMessage: "",
  notiMessageShow: false,
  notiVarient: "",
  pnrResponse: [],
  hotelClicked: false,
  showPop: false,
  callReport: false,
  BookingListRes:[]
};
// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        categoryName: "",
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//=================
// Function Implementaton which will recevie props
//=================
function DashBoardMain(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [enableImportPnr, setEnableImportPnr] = useState(false);
  const [hotelDashBordEnable, setHotelDashBordEnable] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const history = useHistory();

  const importPnrCall = (data) => {
    searchPNR(data);
  };

  const bookingRespDetail = (res) => {
    dispatch({type:"BookingListRes", payload:res})
  }

  const newBooking = (data) => {
    setEnableImportPnr(false);
    setHotelDashBordEnable(false);
    dispatch({ type: "pnrResponse", payload: [] });
  };
  const enableHotel = (data) => {
    setHotelDashBordEnable(true);
  };

  useEffect(() => {
    let showNotify = localStorage.getItem(SHOW_NOTIFY);
    if (showNotify !== null && showNotify) {
      setNotification(true);
      setTimeout(function () {
        setNotification(true);
        localStorage.removeItem(SHOW_NOTIFY);
      }, );
    }
  }, []);

  useEffect(() => {
    return () => {
      console.log(history.action);
      if (history.action === "POP") {
        history.push({ pathname: "/ezytrip/login" });
      }
    };
  }, [history]);

  const searchPNR = (pnr) => {
    setIsLoadingMore(true);
    setEnableImportPnr(false);
    pnrSearch(pnr).then((resp) => {
      console.log("PNR RESP :" + JSON.stringify(resp));
      if (resp.suc) {
        if (!resp.res.is_ezyrip) {
          dispatch({ type: "pnrResponse", payload: resp });
          setEnableImportPnr(true);
          setIsLoadingMore(false);
        } else {
          dispatch({ type: "showPop", payload: true });
          setIsLoadingMore(false);
        }
      } else {
        dispatch({ type: "notiMessageShow", payload: true });
        dispatch({
          type: "notiMessage",
          payload: "PNR details not available.",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        setIsLoadingMore(false);
        hidemessage();
      }
    });
  };

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: "notiMessageShow", payload: false });
      dispatch({ type: "notiMessage", payload: "" });
      dispatch({ type: "notiVarient", payload: "" });
    }, 5000);
  };
  const clickedOnhotel = (cond) => {
    console.log("conddd:::::", cond);
    if (cond) {
      dispatch({ type: "hotelClicked", payload: true });
      setEnableImportPnr(false);
    } else {
      dispatch({ type: "hotelClicked", payload: false });
    }
  };

  const handleClose = () => {
    dispatch({ type: "showPop", payload: false });
  };
  const callMyReports = () => {
    dispatch({ type: "callReport", payload: true });
  };

  const handleNotifiClose = () => {
    setNotification(false);
    localStorage.removeItem(SHOW_NOTIFY);
  };

  return (
    <>

        <Alert
        className="notification"
        variant={state.notiVarient}
        show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible
      >
        {" "}
        {state.notiMessage}
      </Alert>
      <DashBordHeader
        bookingListRes={bookingRespDetail}
        sendPnrVal={importPnrCall}
        clickedOnHotel={clickedOnhotel}
        newBooking={newBooking}
      />
      {enableImportPnr &&
        state.pnrResponse !== undefined &&
        state.pnrResponse.res.mappings !== undefined && (
          <DashBoardPNRImport pnrResponse={state.pnrResponse} />
        )}
      {!enableImportPnr && (
        <div className="container dashBordLayout pt-3">
          <div className="latestbookingNoty">
            <div className="showHide-content pl-0 pr-0 pb-0">
              <Tabs
                defaultActiveKey="latestbooking"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={callMyReports}
              >
                <Tab eventKey="latestbooking" title="Latest Bookings">
                  <div className="expandLink">
                    <Link
                      className="expnd ezyIcon icon-openNext"
                      title="Bookings List"
                      to={"/ezytrip/dashboard/bookingList"}
                    ></Link>
                  </div>
                  <FlightLatestBookings BookingListRes={state.BookingListRes} cid={context.logindata.cid} />
                </Tab>
                <Tab eventKey="notify" title="Reminders">
                  <DashBordNotifications />
                </Tab>
                {context.logindata !== undefined &&
                  context.logindata !== "" &&
                  context.logindata.roles.includes("M_Y_R") &&
                  context.logindata.roles.includes("R_P_T") && (
                    <Tab eventKey="My Bookings" title="My Bookings">
                      <div className="expandLink">
                        <Link
                          className="expnd ezyIcon icon-openNext"
                          title="Bookings List"
                          to={"/ezytrip/dashboard/bookingList"}
                        ></Link>
                      </div>
                      <DashboardMyReports callReport={state.callReport} />
                    </Tab>
                  )}
              </Tabs>
            </div>
          </div>
          {/* <Row>
            <Col className="latestList">
              <div className="cardMainlayout pb-0">
                <FlightLatestBookings cid={context.logindata.cid} />

              </div>
            </Col>
            <Col className="notiFicationLayout">
              <div className="cardMainlayout pb-0">
                <DashBordNotifications />
              </div>
            </Col>
          </Row> */}
          {/* <Row className="">
            <Col>
              <EzytripUpdateandPromotion />
            </Col>
          </Row> */}
          {state.hotelClicked ? (
            <>
              <Row className="mt-5 mb-5">
                <Col>
                  <PopularCities />
                </Col>
              </Row>
              <Row className="mt-5 mb-5">
                <Col>
                  <TopHotels />
                </Col>
              </Row>
            </>
          ) : (
            <>
              {/* <Row className="mt-5 mb-5">
            <Col>
              <PopularRoutes />
            </Col>
          </Row> */}
              <Row className="mt-3 mb-0">
                <Col>
                  <TopAirlines />
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
      {isLoadingMore ? (
        <div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
      ) : (
        ""
      )}

      {state.showPop && (
        <Modal
          show={state.showPop}
          onHide={handleClose}
          className="importPNRMdl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <h5>
              We are unable to process this PNR. This is belongs to OnHold PNR.
            </h5>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button size="xs" variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

       {/* {showNotification && (
        <Modal
          show={showNotification}
          onHide={handleNotifiClose}
          className="importPNRMdl newNotification"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div className="popUplayout center">
              <div className="text-center">
                <img src={Notificationimge} />
              </div>
            </div>
          </Modal.Header>
        </Modal>
      )} */}
      <Footer />
    </>
  );
}
export default DashBoardMain;
