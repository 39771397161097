
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../App";
import Tabs from '../../common/tabs/Tabs';
import Faredetails from './Faredetails';

function FlightSummaryFareDetails(props) {
  const [state, setState] = useState({})
  const [context, setContext] = useContext(Context);
  const [mtaList,setMtaList] = useState([]);
  const [taList,setTaList] = useState([]);
  const [staList,setStaList] = useState([]);
  const [tablist, setTablist] = useState({
    active: 'ATT'
  });

  useEffect(() => {
    if (context.logindata.utype === "att_user") {
      setTablist({ active: "ATT" });
    } else if (context.logindata.utype === "ta_user") {
      setTablist({ active: "TA" });
    } else if (context.logindata.utype === "sta_user") {
      setTablist({ active: "STA" });
    }
  }, [context.logindata]);

  useEffect(() => {

    if(props.faredata!==undefined && props.faredata.length!==0 && props.faredata[0]!==undefined && props.faredata[0].pasngrs!==undefined){
        setMtaList(props.faredata[0].pasngrs.filter((obj) => obj.type==="MTA"));
        setTaList(props.faredata[0].pasngrs.filter((obj) => obj.type==="TA"));
        setStaList(props.faredata[0].pasngrs.filter((obj) => obj.type==="STA"));
    }
}, []);

  const content = {
    ATT: <> {props.faredata!==undefined && props.faredata.length!==0 && props.faredata[0]!==undefined && <Faredetails bk_region_type={props.bk_region_type} fareData={mtaList[0]} payments={props.faredata[0].paydetails} type={"MTA"} bktype={props.bktype} bookingType={props.bookingType} supplier={props.supplier} userCurrency={props.userCurrency}/>}</>,
    TA: <> {props.faredata!==undefined && props.faredata.length!==0 && props.faredata[0]!==undefined && <Faredetails bk_region_type={props.bk_region_type} fareData={taList[0]} payments={props.faredata[0].paydetails} type={"TA"} bktype={props.bktype} bookingType={props.bookingType} supplier={props.supplier} userCurrency={props.userCurrency}/>}</>,
    STA: <> {props.faredata!==undefined && props.faredata.length!==0 && props.faredata[0]!==undefined && <Faredetails bk_region_type={props.bk_region_type} fareData={staList[0]} payments={props.faredata[0].paydetails} type={"STA"} bktype={props.bktype} bookingType={props.bookingType} supplier={props.supplier} userCurrency={props.userCurrency}/>}</>
  };

  return (
    <>
      <div className="tabLink tabList">
        {props.faredata!==undefined && props.faredata.length !== 0 && context.logindata.utype === "att_user" &&
          <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
            <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
            <span key="TA">Travel Agent</span>
            {props.bookedTo === "sta_user" &&
              <span key="STA">Sub Travel Agent</span>
            }
          </Tabs>
        }
        {props.faredata!==undefined && props.faredata.length !== 0 && context.logindata.utype === "ta_user" &&
          <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
            <span className="alertList alerts d-flex align-items-center" key="TA">Travel Agent</span>
            {props.bookedTo === "sta_user" &&
              <span key="STA">Sub Travel Agent</span>
            }
          </Tabs>
        }
        <div className="tabContent">
          {content[tablist.active]}
        </div>
      </div>
    </>
  )
}


export default FlightSummaryFareDetails;
