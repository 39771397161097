/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Rambabu
 *
 */
import React, { useState, useEffect } from 'react';
import { Alert, Col, Form, Row } from "react-bootstrap";
import DateUtils from "../../../commonUtils/DateUtils";
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";
import { getParentCompData } from '../../hotel/BookingSummary/operations';
let bookingStatus = require('../../../masterData/BookingStatus..json');
/**
 * @description: This function will show the Booking Details section
 * @date:18-09-2020
 * @author:Lakshmi
 *
 */
function BookingDetailsPdf(props) {
  const [state, setstate] = useState()
  const [bookid, setBookid] = useState({ selBooking: [] })
  const [compResp, setCompResp] = useState()

  //setBooking Status
  const setStatus = (data) => {
    props.sendBookStatus(data[0].label)
  }

  useEffect(() => {
    if (props.isEdit && props.bk_det !== undefined && props.bk_det.length !== 0) {
      let bookingList = bookingStatus;
      for (let val of bookingList) {
        if (val.value === props.bk_det[0].booking_status) {
          setBookid({ selBooking: [{ label: val.label, value: val.value }] })
        }
      }
    }
  }, [props.isEdit])


  useEffect(() => {
    if(props.bk_det!==undefined  && props.parent_id!== undefined){
      getParentCompData(props.parent_id).then((response) => {
      if(response.suc){
        setCompResp(response.res[0]);
        }
      });
    }
    }, [props.selectedTab])


  return (
    <>
      <div className="flightbookinginfoPdf">
        <div className="flightheader">
          <Row>
            <Col xs={2}>
           
              {props.selectedTab==="TA" && props.invoicetype === "INVOICE" &&
              <div className="img"> <img
               src={logo}
               className="htlImg"
               /></div>
              }
             {(props.selectedTab==="TA" || props.selectedTab==="STA") && props.invoicetype !== "INVOICE" &&
              <div className="img"> <img
              src={(isNotNull(props.bk_det) && isNotNull(props.bk_det[0]) && isNotNull(props.bk_det[0].logo_comp_url)) ? props.bk_det[0].logo_comp_url : logo}
               className="htlImg"
               /></div>
              }

            {props.selectedTab==="STA" && props.invoicetype === "INVOICE" &&
              <div className="img"> <img
               src={compResp!==undefined && compResp.comp_logo_url!==null?compResp.comp_logo_url:logo}
               className="htlImg"
               /></div>
              }
          
            </Col>
            <Col xs={10}>
            <Row>
            {props.selectedTab==="TA" && props.bk_det[0].buser_type==="ta_user" &&
              <Col xs={6}>
              <Col xs={12}><strong>{props.bk_det[0].company_name}</strong></Col>
              <Col xs={12}>{props.bk_det[0].comp_add}</Col>
              <Col xs={12}>{props.bk_det[0].comp_city} , {props.bk_det[0].comp_cnt}</Col>
              <Col xs={12}><span>Contact Number: </span>{props.bk_det[0].comp_contact_num}</Col>
              <Col xs={12}><span>Email : </span>{props.bk_det[0].comp_email_id}</Col>
            </Col>
              }
              {props.selectedTab==="STA" && props.bk_det[0].buser_type==="sta_user" &&
              <Col xs={6}>
              <Col xs={12}><strong>{props.bk_det[0].company_name}</strong></Col>
              <Col xs={12}>{props.bk_det[0].comp_add}</Col>
              <Col xs={12}>{props.bk_det[0].comp_city} , {props.bk_det[0].comp_cnt}</Col>
              <Col xs={12}><span>Contact Number: </span>{props.bk_det[0].comp_contact_num}</Col>
              <Col xs={12}><span>Email : </span>{props.bk_det[0].comp_email_id}</Col>
            </Col>
              }
              {props.selectedTab==="TA" && props.bk_det[0].buser_type==="sta_user" && compResp!==undefined &&
              <Col xs={6}>
              <Col xs={12}><strong>{compResp.company_name}</strong></Col>
              <Col xs={12}>{compResp.company_add}</Col>
              <Col xs={12}>{compResp.comp_city_name} , {compResp.comp_cnt_name}</Col>
              <Col xs={12}><span>Contact Number: </span>{compResp.contact_number}</Col>
              <Col xs={12}><span>Email : </span>{compResp.email_id}</Col>
            </Col>
              }
            {/* IF INVOICE */}

            {props.invoicetype === "INVOICE" &&
              <Col xs={6}>
                <Col xs={12}><strong>Billing To</strong></Col>
                <Col xs={12}><span>Agency Name: </span>{props.bk_det[0].company_name}</Col>
                <Col xs={12}><strong>Corporate Office</strong> </Col>
                <Col xs={12}>{props.bk_det[0].comp_add}</Col>
                <Col xs={12}>{props.bk_det[0].comp_lmark}</Col>
                <Col xs={12}>{props.bk_det[0].comp_city} , {props.bk_det[0].comp_cnt}</Col>

              </Col>
            }
          </Row>

            </Col>
          </Row>
        </div>
        <div className="flightDetails">
          <Row className="pb-2">
            <Col xs={4}><span>Booking Number :</span> {props.bk_det[0].booking_id}
            </Col>
            <Col><span>Booking Status : </span>
              {!props.isEdit &&
                <span className={props.changeStatus == "Confirmed" ? ('text-success') :props.changeStatus == "Cancelled" ? ('text-danger'): props.changeStatus == "Void" ? ('text-danger'):(props.bk_det[0].booking_status == "21") && ('text-primary') || (props.bk_det[0].booking_status == "23") && ('text-success') || (props.bk_det[0].booking_status == "25") && ('text-danger') || (props.bk_det[0].booking_status == "28") && ('text-danger') || (props.bk_det[0].booking_status == "30") && ('text-primary') || (props.bk_det[0].booking_status == "22") && ('text-primary') || (props.bk_det[0].booking_status == "21") && ('text-primary') || (props.bk_det[0].booking_status == "23") && ('Confirmed') || (props.bk_det[0].booking_status == "24") && ('text-primary') || (props.bk_det[0].booking_status == "31") && ('text-danger') || (props.bk_det[0].booking_status == "34") && ('text-danger') || (props.bk_det[0].booking_status == "35") && ('text-danger') || (props.bk_det[0].booking_status == "39") && ('text-danger') || (props.bk_det[0].booking_status == "25") && ('text-primary') || (props.bk_det[0].booking_status == "36") && ('text-success')}>{
                  // (props.bk_det[0].booking_status_str == "VoidCancel" ? "Void/Cancel" : props.bk_det[0].booking_status_str)}{ }
                   props.changeStatus !== "" ? props.changeStatus :(props.bk_det[0].booking_status_str == "VoidCancel" ? "Void/Cancel" : props.bk_det[0].booking_status_str)}{ }  
                  </span>
              }
            </Col>
            <Col xs={4}><span>Customer Name : </span>{props.bk_det[0].customer_name}</Col>
          </Row>
          <Row className="pb-2">
            <Col xs={4}><span>Company Name : </span>{props.bk_det[0].company_name}</Col>
            <Col xs={4}><span>Booking Date : </span>{DateUtils.prettyDate(props.bk_det[0].booking_date, "ddd, DD MMM, YYYY")}</Col>
            {/* <Col xs={4}><span>Phone : </span>{props.bk_det[0].isd_code ? ("+" + props.bk_det[0].isd_code + " - ") : ""} {props.bk_det[0].comp_contact_num}</Col> */}
            <Col xs={4}><span>Phone : </span> {props.bk_det[0].comp_contact_num}</Col>
          </Row>
          <Row className="pb-1">
            <Col xs={4}><span>Email : </span> {props.bk_det[0].comp_email_id}</Col>
            <Col xs={4}><span>Booked By : </span> {props.bk_det[0].booked_by}</Col>
          </Row>

        </div>
      </div>
    </>
  )
}


export default BookingDetailsPdf;
