import CommissionDiscountService from '../../../../services/administration/CommissionDiscountService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Hold Bookings
 * @date : 13-06-2021
 */

export const getAllDiscounts = async (obj) => {
    let discList = [];
    await CommissionDiscountService.loadAllDiscounts(obj).then(response => {
        if (response.data.suc) {
            discList = response.data.res;
        }
    });
    return Promise.resolve(discList);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const saveCommDisc = async (obj) => {
    let finalRes = {};
    await CommissionDiscountService.createComDisc(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const updateDisc = async (obj) => {
    let finalRes = {};
    await CommissionDiscountService.updateComDisc(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

//================================
// Fetch All Travel Agencies List
//================================
export const getCompanyList = async (id) => {
    let taList = [];
    await CommissionDiscountService.getDiscCompanyList(id).then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

//================================
// Fetch All Travel Agencies List
//================================
export const delCompDisc = async (id) => {
    let finalRes = {};
    await CommissionDiscountService.deleteDiscount(id).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
};

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const searchDisc = async (obj) => {
    let finalRes = {};
    await CommissionDiscountService.search(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}



