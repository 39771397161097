import axios from 'axios'

const API_URL = process.env.REACT_APP_PRE_BOOK_URL
/**
 * @author: Azamuddin
 * @description: Integrated the Markup CURD Operations for hotel service
 * @param {*} Service 
 */

class HotelConfigurationService {
    async saveMarkupDetails(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/markup/create`,data)  
      }

     async markupRetrieve() {
          return await axios.get(`${API_URL}/markup/retrieve`)
      }
     async editMarkup(id) {
         return await axios.get(`${API_URL}/markup/edit/${id}`)
     } 

     async updateMarkupDetails(data) {
        return await axios.post(`${API_URL}/markup/updatemarkup`,data)  
     }
     
     async deleteapplicableConfig(id) {
         return await axios.delete(`${API_URL}/markup/deleteapplicableconfig/${id}`)
     }

     async deleteAppcableDate(id) {
        return await axios.delete(`${API_URL}/markup/deleteapplicabledates/${id}`)
    }

    async retriveFMDetails(id) {
        return await axios.get(`${API_URL}/markup/faremapedit/${id}`)
    }

    async deletePaxMarakup(id) {
        return await axios.delete(`${API_URL}/markup/paxmapdelete/${id}`)
    }
    
    async deleteLSid(id) {
        return await axios.delete(`${API_URL}/markup/longestdelete/${id}`)
    }

    async updateFareMap(data) {
        return await axios.post(`${API_URL}/markup/updatemarkupfaremap`,data)
    }
    async deleteFareMap(id) {
        return await axios.delete(`${API_URL}/markup/faremapdelete/${id}`)
    }
    async getSearchMarkups(data) {
        return await axios.post(`${API_URL}/markup/get_markups`,data)
    }
    async getpromo(obj) {
        return await axios.post(`${API_URL}/promocode/retrieve`, obj);
    }

    async savePromoDetails(promoData) {
        return await axios.post(`${API_URL}/promocode/create`,promoData)
    }

    async updatePromoDetails(promoData) {
        return await axios.put(`${API_URL}/promocode/update/${promoData.id}`,promoData)
    }

    async editPromo(id) {
        return await axios.get(`${API_URL}/promocode/edit/${id}`)
    }
    async deletePromoAplDates(id) {
        return await axios.delete(`${API_URL}/promocode/deleteApplicable/${id}`)
    }
    async deletePromoFMap(id) {
        return await axios.delete(`${API_URL}/promocode/fm/delete/${id}`)
    }
}

export default new HotelConfigurationService()