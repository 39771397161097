import React, { useState, useEffect, useReducer } from 'react';
import { Col, Row} from "react-bootstrap";

/**
 * @description:This function will Calculate the Room wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 12-02-2021
 */

/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function RoomHeader(props) {


  return (
    <>
      <div className="costBox bookingSummary">
         <Row className="headerpart">
          <Col xs={6} className="perRoom"><strong>Select Your Room {props.rmno} : </strong> <span>Room Occupancy </span> <div className="selecPpaxtype"><span>{props.ocp.adt} Adult(s)</span>{props.ocp.chd!==0 && <span>{props.ocp.chd + " Child(s)"+props.ocp.chdage}</span>}</div></Col>
          <Col xs={2}>Board Basis</Col>
          <Col xs={3}>Price</Col>
          <Col xs={1}>Select</Col>
          </Row>
        </div>
    </>

  )
}
export default RoomHeader