import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import FlightSearch from '../../../landing/flight/FlightSearch';
export const EXIT_ITINARARY = "extItinarary";
export const EXIT_STORE = "is_store";
const initialState = {
  srcDate: "", destDate: ""
};
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


//This function will show the Air markups List and Add Markup
function ShowItinarary(props) {
 const [segmentList,setSegmentList] = useState([])

 useEffect(() => {
    if(props.segments!==undefined){
      setSegmentList(props.segments);
    }else{
      if(props.existItinarary!==undefined && props.existItinarary.seg!==undefined ){
        setSegmentList(props.existItinarary.seg);
      }
    }
  }, [])

  return (
    <>
            <div className="modifySearchInfo">
                <div className="rout d-flex flex-column b">
                  <div className="routsList">
                    <span className="rtInfo">{props.existItinarary.search.dcty1} ({props.existItinarary.search.dep1})</span>
                    <span className="ezyIcon icon-oneside"></span> 
                    <span className="rtInfo"> {props.existItinarary.search.acty1} ({props.existItinarary.search.arr1}) </span>  
                    <span className="multiDate">{ props.existItinarary.depDate1 !== undefined ? props.existItinarary.depDate1:props.existItinarary.search.dep1}</span>
                    
                    {segmentList.length!==0 &&
                    <div>
                    <span className="paxInfo"> Class : {segmentList[0].cabin_class!==undefined?segmentList[0].cabin_class: props.class?props.class:segmentList[0].cl}</span> 
                    </div>
                    }
                  </div>
                  {props.existItinarary.search.dcty2 !== undefined && props.existItinarary.search.dep2 !== "" && props.existItinarary.search.arr2 !== undefined && props.existItinarary.search.arr2 !== "" &&
                    <div className="routsList">
                      <span className="rtInfo"> {props.existItinarary.search.dcty2} ({props.existItinarary.search.dep2})</span> 
                      <span className="ezyIcon icon-oneside"></span> 
                      <span className="rtInfo"> {props.existItinarary.search.acty2} ({props.existItinarary.search.arr2}) </span>  
                      <span className="multiDate">{props.existItinarary.depDate2 !== undefined ? props.existItinarary.depDate2 : props.existItinarary.search.dep2}</span>
                    
                    {segmentList.length!==0 && segmentList.length > 1 &&
                    <div>
                    <span className="paxInfo">Class : {segmentList[1].cabin_class!==undefined?segmentList[1].cabin_class: props.class?props.class:segmentList[1].cl}</span> 
                    </div>
                    }
                    </div>
                  }
                  {props.existItinarary.search.dep3 !== undefined && props.existItinarary.search.dep3 !== "" && props.existItinarary.search.deptThree!=="" && props.existItinarary.search.arr3 !== undefined && props.existItinarary.search.arr3 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {props.existItinarary.search.dcty3} ({props.existItinarary.search.dep3}) </span> 
                      <span className="ezyIcon icon-oneside"></span> 
                      <span className="rtInfo"> {props.existItinarary.search.acty3} ({props.existItinarary.search.arr3}) </span>  
                      <span className="multiDate">{props.existItinarary.depDate3 !== undefined ? props.existItinarary.depDate3: props.existItinarary.search.dep3}</span>
                    {segmentList.length!==0 && segmentList.length > 2 &&
                    <div>
                    <span className="paxInfo">Class : {segmentList[2].cabin_class!==undefined?segmentList[2].cabin_class: props.class?props.class:segmentList[2].cl}</span> 
                    </div>
                    }
                    </div>
                  }
                  {props.existItinarary.search.dep4 !== undefined && props.existItinarary.search.dep4 !== "" && props.existItinarary.search.deptFour!==""  && props.existItinarary.search.arr4 !== undefined && props.existItinarary.search.arr4 !== "" &&
                    <div className="routsList">
                      <span className="rtInfo"> {props.existItinarary.search.dcty4} ({props.existItinarary.search.dep4}) </span> 
                      <span className="ezyIcon icon-oneside"></span> 
                      <span className="rtInfo"> {props.existItinarary.search.acty4} ({props.existItinarary.search.arr4}) </span>  
                      <span className="multiDate">{props.existItinarary.depDate4 !== undefined ? props.existItinarary.depDate4: props.existItinarary.search.dep4}</span>
                   
                    {segmentList.length!==0 && segmentList.length > 3 &&
                    <div>
                    <span className="paxInfo">Class : {segmentList[3].cabin_class!==undefined?segmentList[3].cabin_class: props.class?props.class:segmentList[3].cl}</span> 
                    </div>
                    }
                    </div>
                  }
                  {props.existItinarary.search.dep5 !== undefined && props.existItinarary.search.dep5 !== "" && props.existItinarary.search.deptFive!==""  &&props.existItinarary.search.arr5 !== undefined && props.existItinarary.search.arr5 !== "" &&
                    <div className="routsList">
                      <span className="rtInfo">{props.existItinarary.search.dcty5} ({props.existItinarary.search.dep5}) </span> 
                      <span className="ezyIcon icon-oneside"></span> 
                      <span className="rtInfo"> {props.existItinarary.search.acty5} ({props.existItinarary.search.arr5}) </span>  
                      <span className="multiDate">{props.existItinarary.depDate5 !== undefined ? props.existItinarary.depDate5 : props.existItinarary.search.dep5}</span>
                    </div>
                  }
                  {props.existItinarary.search.dep5 !== undefined && props.existItinarary.search.dep5 !== "" && props.existItinarary.search.deptSix!==""  && props.existItinarary.search.arr6 !== undefined && props.existItinarary.search.arr6 !== "" &&
                    <div className="routsList"><span className="rtInfo">
                      {props.existItinarary.search.dcty6} ({props.existItinarary.search.dep6}) </span> 
                      <span className="ezyIcon icon-oneside"></span> 
                      <span className="rtInfo"> {props.existItinarary.search.acty6} ({props.existItinarary.search.arr6}) </span>  
                      <span className="multiDate">{props.existItinarary.depDate6 !== undefined ? props.existItinarary.depDate6 :props.existItinarary.search.dep6}</span>
                    </div>
                  }
                </div>
                  <div>
                  <span>Travellers : {props.existItinarary.search.adt + props.existItinarary.search.chd + props.existItinarary.search.inf}</span>
                  </div>
            </div>


    </>
  )
}
export default ShowItinarary