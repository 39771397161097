import log from "loglevel";
import React, { useContext, useEffect, useState,useReducer } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Form,Alert} from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MainMenu from '../../../common/MainMenu';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import { Context } from "../../../../../App";
import InnerLoader from '../../../common/loader/InnerLoader';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import OfficeId from "./index";
import {getOfficeId , updateStatus} from './operations';
export const USER_AUTH_DATA = "userAuthData";


/**
 * Initial State Declaration
 */
const initialState = {
  notiMessage: '', notiMessageShow: false, notiVarient: ''
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
//This function will show the all the Office Id records and Create funcationality
function OfcIdMain() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ofcList, setOfcList] = useState([]);
  const [context, setContext] = useContext(Context);
  const [createOfc, setCreateOfc] = useState(false);
  const [editOfc, setEditofc] = useState(false);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [editOfcData, setEditOfcData,] = useState("");

  useEffect(() => {
    setIsLoadingMore(true)
    const payload = {
      "userid": context.logindata.uid,
      "is_admin": context.logindata.is_admin,
      "region_type" : context.logindata.region_type
    }
    getOfficeId(payload).then(response => {
        loadResponse(response);
    }).catch(function (error) {
      log.error(
        "Exception occured in getOfficeIdsList function---" + error
      );
    });
  }, [context.logindata.region_type])

  const handleChangeActive = (obj) => (e) => {
    //setIsLoadingMore(true)
    let active=1;
    if(e.target.value==="true"){
        active=0;
    }
   let req= {"id":obj.id,"upby":context.logindata.uid,"region":obj.reg,"act":active}
    updateStatus(req).then(response => {
        if(response.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Office Id Status updated Successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        //setIsLoadingMore(false)
        return window.location = window.location.pathname;
        }
    })
}


  const createOfcId = (e) => {
    setCreateOfc(true)
    setEditofc(false)
  }

  const editServiceBtnClick = (obj, type) => () => {
    setEditofc(true)
    setCreateOfc(false)
    obj.type=type;
    setEditOfcData(obj)
  }
  const loadResponse = (response) => {
    setOfcList([])
    let data = [];
    var enableEdit = true;
    if(context.logindata!==""){
      if(context.logindata.roles.includes('S_F_U')) {
        enableEdit = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      if(userData.roles.includes('S_F_U')) {
        enableEdit = true;
      }
    }
    for (let val of response) {
   
      if (context.logindata.is_admin==1 || context.logindata.region_type==val.reg){
      data.push({ ofc_id: val.onm,region_type:val.reg, sales_cha: val.cha, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editServiceBtnClick(val, "View")}></a> {enableEdit && <a className="ezyIcon icon-edit" onClick={editServiceBtnClick(val, "Edit")}></a>}  <div className='custom-switch'>
      <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
         <Form.Check  className="p-0 onFbtn"
         checked={val.act?true:false}
         type="checkbox"
         label="."
         value={val.act?true:false}
         onChange={handleChangeActive(val)}
         id={val.id}
         custom
          />
         </Form.Group>
         </div></span></> })}
    }
    setOfcList(data)
    setIsLoadingMore(false)
  }
  const columns = [
    {
      dataField: 'ofc_id',
      text: 'Office Id Name',
      sort: true,
      filter: textFilter()
    },
    {
    dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter()
    },
  {
    dataField: 'sales_cha',
    text: 'Sales Channel',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  return (
    <>
    <MainMenu active='Air Services' />
      <Breadcrumb activePage="Office Id" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Office Id" />
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
          <div className="contentArea cmnSearch">
            {(!createOfc && !editOfc) ? (
              <>
                {context.logindata.roles !== undefined && context.logindata.roles.includes('S_F_C') &&
                  <div className="d-flex justify-content-between buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn addPlus ezyIcon icon-plus"
                      onClick={createOfcId}
                      type="submit">
                    Create Office Id
                  </Button>
                  </div>
                }
              </>
            ) : (
                (createOfc) ? (
                  <>
                    <OfficeId sendEditData={undefined}/>
                  </>
                ) : (
                  <>
                    <OfficeId sendEditData={editOfcData} />
                  </>)
              )
            }
          </div>
        </div>
        {(!createOfc && !editOfc) ? (
          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore? <InnerLoader /> : (<>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={ofcList}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={ofcList.length >10 && ofcList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  ofcList.length >25 && ofcList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  ofcList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {ofcList.length===0 &&
                  <NoRecord />
                }
            </>)}
          </div>
          ) : ('')
          }
      </div>
      <Footer />
    </>
  )
}
export default OfcIdMain