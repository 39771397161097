import HotelService from '../../../../services/bookingService/hotel/HotelService';
import {apiPostHelper,apiGetHelper} from '../../../../components/commonUtils/helper';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce'
const API_HOTEL_URL = process.env.REACT_APP_SEARCH_URL
const HOTEL_BOOK_URL = process.env.REACT_APP_HOTEL_BOOK_URL
const HOTEL_PRE_BOOK_URL = process.env.REACT_APP_PRE_BOOK_URL
const HOTEL_CONT_BOOK_URL = process.env.REACT_APP_PREDICTIVE_URL
const HOTEL_BACKEND_ENGINE = process.env.REACT_APP_HOTEL_ENGINE_URL



/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @param {*}
 * @function Travel Itinarary
 * @date : 31-08-2020
 */

// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const searchRequest= async (req) => {
    let finalRes = [];
     await HotelService.fireHotelSearch(req).then(response => {
         finalRes = response;
     });
     return Promise.resolve(finalRes);
 }
 

// ========================================================================
//  send confirmation email 
// ========================================================================
export const sendHotelConfirmationObj = async (req) => {
    let finalRes = [];
    await HotelService.sendHotelConfirmationObj(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
 


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
 export const getHotelReviewContent= async (expHtlId,otHtlId) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiGetHelper(`${API_HOTEL_URL}/properties_review/?skip=0&limit=5&type=prid&expHtlId=`+expHtlId+"&otHtlId="+otHtlId,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const getHotelRooms= async (req) => {
    let finalRes = [];
     await HotelService.fireHotelSearch(req).then(response => {
         finalRes = response.data;
     });
     return Promise.resolve(finalRes);
 }

 /**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
export const getHotelSRPContent = async (request,type) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    
let req=type==="cityId"?"&expCityId="+request.expediaCityId+"&otCityId="+request.otillaCityId:"&expCityId="+request.expediaCityId+"&otCityId="+request.otilaCityId+"&expHtlId="+request.expediapid+"&otHtlId="+request.otilapid;
    await apiGetHelper(`${API_HOTEL_URL}/properties/?skip=0&limit=5&type=`+type+req+"&getExpediaMap=false",headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */
export const saveHotelBooking = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_PRE_BOOK_URL}/hotel/prebook`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const hotelPriceCheck= async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotelpricing/pricecheck`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Get Updated CL  
// ========================================================================
export const getCrediLimit= async (req) => {
    let clRes = [];
     await AttCommonSerivce.getUpdateCreditLimit(req).then(response => {
        clRes = response.data;
     });
     return Promise.resolve(clRes);
 }


 // ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const savePriceCheck= async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_HOTEL_URL}/common/hotelpricechecksave`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const getGuestReviews= async (pid) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiGetHelper(`${HOTEL_BOOK_URL}/hotelavailability/user_review?pid=`+pid,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveOtillaPriceCheck= async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotelpricing/hotelpricechecksave`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


 
