import log from "loglevel";
import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import InnerLoader from '../../../common/loader/InnerLoader';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import CommonSearch from '../../common/CommonSearch';
import CreateAirMarkup from '../markup/AirMarkupForm';
import { editMarkup, searchMarkups } from '../operations';
export const MarkupContext = React.createContext();
/**
 * @author: Lakshmi
 * @description: This function will Load the all the Markups
 * @date:14-09-2020
 */
function AirMarkupConfiguration() {
  const history = useHistory();
  const [markups, setMarkupList] = useState({ markupdata: [] });
  const [editData, setEditData] = useState();
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableView, setEnableView,] = useState(false);
  const [OldmarkupCreated, setOldNames] = useState({ oldData: [] })

  const [state, setState] = useState({
    createMarkup: false,
    loading: false,
    editMarkup: false
  })

/**
 * @author: Lakshmi
 * @description: Load all the data based search creteria
 * @date:14-09-2020
 * @function : searchMarkups
 */
  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "") {
      const payload = {
        "type": context.logindata.utype,
        "userid": context.logindata.uid,
        "is_admin": context.logindata.is_admin,
        "aplto": "",
        "bid": "",
        "cid": "",
        "apltyp": "",
        "ttype": "",
        "fmdate": "",
        "todate": "",
        "pcid" : context.logindata.cid,
        "agency_admin" : context.logindata.agency_admin,
        "region_type" : context.logindata.region_type
      }
      if(markups!==undefined && markups.markupdata.length==0){
        searchMarkups(payload).then(response => {
          if (response.data.suc) {
            loadResponse(response);
            setIsLoadingMore(false);
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getMarkupList function---" + error
          );
        });
      }
    }
  }, [context.logindata && markups.markupdata.length==0]);

  /**
 * @author: Lakshmi
 * @description:Create the Markup
 * @date:14-09-2020
 * @function : createMarkupBtnClick
 */
  const createMarkupBtnClick = (e) => {
    setState({ createMarkup: true, loadBranch: false })
  }
/**
 * @author: Lakshmi
 * @description: Edit the Markup based on Id
 * @date:14-09-2020
 * @function : editMarkupBtnClick
 */
  const editMarkupBtnClick = (obj, type) => () => {
    setState({ editMarkup: true })
    editMarkup(obj.id).then(response => {
      if (response.data.suc) {
        response.data.res.office_label = obj.office_label
        setEditData(response)
        if (type === "view") {
          setEnableView(true)
        } else {
          setEnableView(false)
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editMarkup function---" + error
      );
    });
  }

  const columns = [
    {
      dataField: 'sales_channel',
      text: 'Sales Channel',
      sort: true,
      filter: textFilter()
    },{
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },{
      dataField: 'office_label',
      text: 'Office Id',
      sort: true,
      filter: textFilter()
    },{
    dataField: 'mn',
    text: 'Markup Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'ft',
    text: 'Flow Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'tt',
    text: 'Travel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

/**
 * @author: Lakshmi
 * @description: Load all the data based search creteria
 * @date:14-09-2020
 * @function : searchMarkups
 */
  const searchMarkup = (data) => {
    searchMarkups(data).then(response => {
      if (response.data.suc) {
        loadResponse(response);
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in editMarkup function---" + error
      );
    });
  }

  /**
 * @author: Lakshmi
 * @description: Load all Markups based on User Id
 * @date:14-09-2020
 * @function : loadResponse
 */
  const loadResponse = (response) => {
    let data = [];
    let dataName = []
    var enableEdit = false;
    if (context.logindata.roles.includes('M_U_U')) {
      enableEdit = true;
    }
    for (let val of response.data.res) {
      if (context.logindata.is_admin==1|| context.logindata.region_type==val.region_type){
      var flowType = "";
      if (val.fftype.includes("B")) {
        flowType = "Booking";
      }
      if (val.fftype.includes("A")) {
        flowType = flowType + " | Amendment";
      }
      if (val.fftype.includes("C")) {
        flowType = flowType + " | Cancellation";
      }
      if (val.fftype.includes("V")) {
        flowType = flowType + " | Void";
      }
      let traveltype = ""
      if (val.ttype == "0") {
        traveltype = "All"
      }
      if (val.ttype == "1") {
        traveltype = "One Way"
      }
      if (val.ttype == "2") {
        traveltype = "Round Trip"
      }
      if (val.ttype == "3") {
        traveltype = "Multi City"
      }
      else if (val.ttype != "1" && val.ttype != "0" && val.ttype != "2" && val.ttype != "3") {
        traveltype = val.ttype
      }
      dataName.push(val.mn)
      data.push({ region_type:val.region_type,office_id:val.office_id,office_label:val.office_label!==null?val.office_label:"All",sales_channel:val.sales_channel,mn: val.mn, ft: flowType, tt: traveltype, status: (val.act === 1) ? "Active" : "In-Active", options: <> <span className="tbAction"><a className="ezyIcon icon-view" onClick={editMarkupBtnClick(val, "view")}></a> {enableEdit && <a className="ezyIcon icon-edit" onClick={editMarkupBtnClick(val, "edit")}></a>}</span></> })
    }}
    setOldNames({ oldData: dataName })
    setMarkupList({ markupdata: data })
  }


  return (
    <>
      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Air Markup" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Air Markup Configuration" />
          <div className="contentArea cmnSearch">
            {(!state.createMarkup && !state.editMarkup) ? (
              <>
                <CommonSearch configType={"Markup"} sendSearchCreteria={searchMarkup} />
                <div className="d-flex justify-content-between buttonGrop pb-0">
                  {context.logindata.roles !== undefined && context.logindata.roles.includes('M_U_C') &&
                    <Button
                      size="xs"
                      variant="primary"
                      className="btn addPlus ezyIcon icon-plus"
                      onClick={createMarkupBtnClick}
                      type="submit">
                    Create Markup
                  </Button>
                  }
                </div>
              </>
            ) : (
                (!state.editMarkup) ? (
                  <>
                    <CreateAirMarkup oldDataNames={OldmarkupCreated.oldData} />
                  </>
                ) : (
                    <>
                      <CreateAirMarkup sendEditData={editData} disableBtn={enableView} />
                    </>)
              )
            }
          </div>
        </div>

        {(!state.createMarkup && !state.editMarkup) ? (
          <div className="cardMainlayout p-0 tableRes">
            {isLoadingMore ? <InnerLoader /> : (<>
              {markups.markupdata.length !== 0 && context.logindata.roles && context.logindata.roles.split(",").includes('M_U')  &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={markups.markupdata}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={markups.markupdata.length >10 && markups.markupdata.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  markups.markupdata.length >25 && markups.markupdata.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  markups.markupdata.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || markups.markupdata.length === 0
                && <NoRecord />}</>)}
          </div>

        ) : ('')}

        {state.editMarkup ? (
          <>
            ''
      </>
        ) : ('')}

      </div>
      <Footer />
    </>
  )
}
export default AirMarkupConfiguration