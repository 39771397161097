import log from "loglevel";
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { characterValidation } from '../../../commonUtils/FormValidations';
import { fetchFunctionalities, saveRoleDetails, updateRoleDetails,fetchCrmFunctionalities } from './operations';
import { RoleContext } from './roles';

//This function will create the user details
function CreateRole(props) {
  const roleObj = useContext(RoleContext);
  const [state, setState] = useState({ id: 0, rn: '', rdesc: '', ctype: [], cmpid: "", enableBtn: false, fun: [] ,ini:{ response: [] }})
  const [type, setLoginType] = useState({ sta: false })
  const [company, setCompanyType] = useState({ companyTypes: [] });
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [context, setContext] = useContext(Context);
  const [functionalities, setFunctionalities] = useState({ response: [] })
  const [initial, setInitial] = useState({ response: [] })
  const [appliedfunctionalities, setAppliedfunctionalities] = useState([])
  const [handleChecked, setchecked] = useState(false)
  const [enableUpdate, setUpdate] = useState(false)
  const [comType, setCompType] = useState([])
  const [nameValid, setnameValid] = useState(false)
  const [applicableValid, setapplicableValid] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [roleId, setRoleId] = useState(0)
  const [roleName, setRoleName] = useState("")
  const [isItineraryLoading, setIsItineraryLoading] = useState(false)
  const [reset, setReset] = useState(false)

  useEffect(() => {
    let types = []
    if (context.logindata.utype === "att_admin" || context.logindata.utype === "att_user") {//{ value: "CRM", label: 'CRM Staff' }
      types = [{ value: "TA", label: 'TA Staff' }, { value: "ATT", label: 'ATT Staff' },{ value: "CRM", label: 'CRM Staff' }];
      setCompanyType({ companyTypes: types });
    }
    if (context.logindata.utype === "ta_user") {
      types = [{ value: "TA", label: 'TA Staff' }, { value: "STA", label: 'STA Staff' }];
      setCompanyType({ companyTypes: types });
    }
    if (context.logindata.utype === "sta_user") {
      types = [{ value: "STAS", label: 'STA Staff' }]
      setCompanyType({ companyTypes: types });
    }
    if (roleObj) {
      convertEditRole(types);

    }
  }, [context.logindata]);

  /**
   * @description : fetch all functionalities
   * @author : Azamuddin
   * @date: 30-10-2020
   */
  useEffect(() => {
    if (context.logindata !== "") {
      fetchFunctionalities(context.logindata.uid).then(response => {

        if (response.data.suc && response.data.res.menu) {

          setInitial({ response: response.data.res.menu })
          setState({ ini:{ response: response.data.res.menu } })
          setFunctionalities({ response: response.data.res.menu })

        }
      })
      if(reset){
        setCompType([])
        state.rdesc=""
        state.rn=""
        setReset(false)
      }
    }
  }, [context.logindata,reset])

  const updateRoles=(rolesList)=>{
    let res = rolesList;
    let finalres = rolesList;
    let ss = res.map((res,idx) => {
      if(res.code === "A_N" || res.name ==="Administration"){
        res.menuone.map((menuone) => {
          if(menuone.code === "O_R" || menuone.name ==="Organization/CM"){
            let arr =["B_C", "C_T", "C_F","C_O_M","C_C_M","C_M_O_H"]
           let rrr = []
           if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
            rrr= menuone.menutwo.filter((menutwo) => {
              return (!arr.includes(menutwo.code))
            })
           }
           if(finalres[idx].menuone[0].menutwo!==undefined){
            finalres[idx].menuone[0].menutwo = [];
            finalres[idx].menuone[0].menutwo = rrr;
           }
            
          }
          else if(menuone.code === 'A_S' || menuone.name ==='Air Services'){
            let arr =["D_C","C_M","O_I","A_C_O","R_V_M","F_P_C"]
            let rrr =  []
            if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
              rrr= menuone.menutwo.filter((menutwo) => {
               return (!arr.includes(menutwo.code))
             })
            }
            if(finalres[idx].menuone[2].menutwo!==undefined){
              finalres[idx].menuone[2].menutwo = [];
              finalres[idx].menuone[2].menutwo = rrr;
            }
          }else if(menuone.code === 'G_S' || menuone.name ==='Ground Services'){
            let arr =["H_P_C", "H_C_S"]
            let rrr =  []
            if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
              rrr= menuone.menutwo.filter((menutwo) => {
               return (!arr.includes(menutwo.code))
             })
            }
            if(finalres[idx].menuone[3].menutwo!==undefined){
              finalres[idx].menuone[3].menutwo = [];
              finalres[idx].menuone[3].menutwo = rrr;
            }
          }
          else if(menuone.code === 'P_B' || menuone.name ==='Payment & Billing'){
            let arr =["O_C_L","O_P_B","E_H_C","R_C_P"]
            let rrr =  []
            if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
             rrr= menuone.menutwo.filter((menutwo) => {
                return (!arr.includes(menutwo.code))
              })
            }
            if(finalres[idx].menuone[4].menutwo!==undefined){
              finalres[idx].menuone[4].menutwo = [];
              finalres[idx].menuone[4].menutwo = rrr;
            }
          }
        })
      }else if(res.code === "B_S" || res.name ==="Booking Summary"){
        let arr =["B_S_F"] ["F_E_B_S","B_S_R_T","B_S_T_R"]
        res.menuone.map((menuone) => {
          if(menuone.code === "B_S_F" || menuone.name ==="Flight"){
           let arr =["F_E_B_S","F_I_P_D"]
           let rrr = []
           if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
            rrr= menuone.menutwo.filter((menutwo) => {
              return (!arr.includes(menutwo.code))
            })
           }
            if(finalres[idx].menuone[0].menutwo!==undefined){
              finalres[idx].menuone[0].menutwo = [];
              finalres[idx].menuone[0].menutwo = rrr;
            }
          }else if(menuone.code ==="B_S_H" || menuone.name ==="Hotel"){
            let arr =["H_E_B_S","H_B_S_R"]
            let rrr = []
            if(menuone.menutwo!==undefined && menuone.menutwo.length!==0){
             rrr = menuone.menutwo.filter((menutwo) => {
               return (!arr.includes(menutwo.code))
             })
            }
            if(finalres[idx].menuone[1].menutwo!==undefined){
              finalres[idx].menuone[1].menutwo = [];
              finalres[idx].menuone[1].menutwo = rrr;
            }
          }
      })
        }
      })
             
      let finalRes = finalres.filter((obj)=>{
        return(obj.id !==3 && obj.id !==104)
      })
      setFunctionalities({ response: finalRes })
  }

  // console.log("asdasdasdas",comType)
  useEffect(()=>{
if(comType[0] && comType[0].value === "TA"){

  fetchFunctionalities(context.logindata.uid).then(response => {
    if (response.data.suc && response.data.res.menu) {
      setInitial({ response: response.data.res.menu })
      setState({ ini:{ response: response.data.res.menu } })
      setFunctionalities({ response: response.data.res.menu })
      updateRoles(response.data.res.menu);
    }
  })
}else{
  if (context.logindata !== "") {
    if(comType && comType[0] && comType[0].value === "ATT"){  
      fetchFunctionalities(context.logindata.uid).then(response => {
        if (response.data.suc && response.data.res.menu) {
          setFunctionalities({ response: response.data.res.menu })
        }
      })
    }else if(comType && comType[0] && comType[0].value === "CRM"){
      fetchCrmFunctionalities(context.logindata.uid).then(response => {
        if (response.data.suc && response.data.res.menu) {
          setInitial({ response: response.data.res.menu })
      setState({ ini:{ response: response.data.res.menu } })
      updateRoles(response.data.res.menu);
          setFunctionalities({ response: response.data.res.menu })
        }
      })
    }
  }
}
},[comType])
 
  const handleChange = (e) => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value
    }))
    if (id === "rn") {
      if (state.rn) {     
    setnameValid(false)
      }
    }
    if (id === "ctype") {
      if (state.rn) {     
    setapplicableValid(false)
      }
    }
  }


  const handlecChange = (e) => {
    setCompType(e)
    setapplicableValid(false)
  }
  const convertEditRole = (types) => {
    let roleFunctions = roleObj.fun;
    if (roleObj && roleObj.fun) {
      let arr = []
      if (types && types.length > 0) {
        for (let val of types) {
          if (val.value == roleObj.ctype) {
            let obj = {
              value: roleObj.ctype,
              label: val.label
            }
            arr.push(obj)
          }
        }
      }
      setAppliedfunctionalities([...appliedfunctionalities, ...roleFunctions])
      setState({ id: roleObj.rid, rn: roleObj.rn, rdesc: roleObj.rdesc, ctype: arr, enableBtn: true })
      setUpdate(true)
      setdisabled(true)
      setCompType(arr)
      setRoleId(roleObj.rid)
      setRoleName(roleObj.rn)
    }
  }
  /**
   * @description : pushing or removing element from array of functionalitites
   * @author: Azamuddin
   * @date: 30-10-2020
   * @param {*} e
   */
  const handlepermissionChange = (e) => {
    const { id, value } = e.target;
    if (appliedfunctionalities.indexOf(parseInt(id)) > -1) {
      setchecked(false);
      const index = appliedfunctionalities.indexOf(parseInt(id));
      if (index > -1) {
        // appliedfunctionalities.splice(index, 1);
        var array = appliedfunctionalities.filter(x => x !== parseInt(id));
        setAppliedfunctionalities(array);
      }

    } else {
      setchecked(true)
      setAppliedfunctionalities([...appliedfunctionalities, parseInt(id)])
    }

  }
  const pickldata = (data) => {
  }
  function hidemessage() {
    setTimeout(function () {
      setNotiMessageShow(true);
      setNotiMessage('');
      setNotiVarient('')
    }, 5000);
  }
  const handleRoleCreation = (e) => {
    e.preventDefault();
    state.crby = context.logindata.uid;
    state.fun = appliedfunctionalities; // functionalities
    if(state.ctype && state.ctype.length === 0){
      setapplicableValid(true);
    }
    if (comType && comType.length > 0) {
      state.ctype = comType && comType.length > 0 && comType[0].value ? comType[0].value : null
    }
    state.cid = context.logindata.cid
    state.userType = context.logindata.utype
    let rnvalidation = false  
      if (characterValidation(state.rn)) {
        rnvalidation = true
        setnameValid(false)
      } else {
        setnameValid(true)
      }
      
    if (state.ctype !== null && state.fun && state.fun.length > 0 && state.rn !== undefined) {
      const rolePayload = {
        rlreq: state
      }
      let rnvalidation = false
      if (characterValidation(state.rn)) {
        rnvalidation = true
        setnameValid(false)
      } else {
        setnameValid(true)
      }
      if (rnvalidation) {
        setIsItineraryLoading(true)
        saveRoleDetails(rolePayload).then(response => {
          if (response.suc) {
            setNotiMessageShow(true);
            setNotiMessage('Role saved successfully');
            setNotiVarient('success')
            hidemessage()
            handleCancel();
          } else if (response.res.message.includes("Duplicate")) {
            setIsItineraryLoading(false)
            setNotiMessageShow(true);
            setNotiMessage('Role Already Exists');
            setNotiVarient('danger')
            hidemessage()
          } else {
            setIsItineraryLoading(false)
            setNotiMessageShow(true);
            setNotiMessage('Role has not saved..');
            setNotiVarient('danger')
            hidemessage()
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getUserData function---" + error
          );
        });
      }
    } else {
      setNotiMessageShow(true);
      setNotiMessage('Please Enter the Mandatory Fields');
      setNotiVarient('danger')
      hidemessage()
    }
    // handleCancel()
  }
  const handleRoleUpdate = (e) => {
    e.preventDefault();
    state.upby = context.logindata.uid;
    state.fun = appliedfunctionalities; // functionalities
    if (comType && comType.length > 0) {
      state.ctype = comType.length > 0 && comType[0].value ? comType[0].value : null
    }
    state.cid = context.logindata.cid;
    state.id=roleId;
    state.rn=roleName;
    if (state.ctype !== null && state.fun && state.fun.length > 0 && state.rn!=="") {
      const rolePayload = {
        rlreq: state
      }
      setIsItineraryLoading(true)
      updateRoleDetails(rolePayload.rlreq).then(response => {
        if (response) {
          setNotiMessageShow(true);
          setNotiMessage('Role updated successfully');
          setNotiVarient('success')
          hidemessage()
          handleCancel();
        } else {
          setIsItineraryLoading(false)
          setNotiMessageShow(true);
          setNotiMessage('Role has not updated..');
          setNotiVarient('danger')
          hidemessage()
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in updateRoleDetails function---" + error
        );
      });
    } else {
      // setNotiMessageShow(true);
      // setNotiMessage('Please Enter the Mandatory Fields');
      // setNotiVarient('danger')
    }
    //handleCancel()
  }

  const handleCancel = (e) => {
    return window.location = "/ezytrip/administration/role";
  }

  const handleReset = (e) => {
    e.preventDefault();

    
    setAppliedfunctionalities([])
    setReset(true)
  }
  

  return (
    <>

          <Alert className="notification" variant={notiVarient} show={notiMessageShow}
        onClose={() => setNotiMessageShow(false)}
        dismissible> {notiMessage}</Alert>
        {isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
      <form>
        <Form.Row>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctype">
            <Select
              options={company.companyTypes}
              searchable={false}
              disabled={disabled}
              values={comType}
              onChange={handlecChange}
            //onChange={(value) => (setDates(value))}
            />
            {applicableValid && (<p className="vError">Please Enter A Valid Role </p>)}
            <Form.Label>Applicable To<sup>*</sup></Form.Label>
          </Form.Group>

          {/* <Form.Row>

          <Form.Group as={Col} xs={6} controlId="companyType">
            <PickList dataList={roles} sendData={pickldata} />
          </Form.Group>
        </Form.Row> */}

          <Form.Group as={Col} xs={3} controlId="rn">
            <Form.Control type="text" placeholder="User Role" value={state.rn} onChange={handleChange} />
            <Form.Label>Role Name<sup>*</sup></Form.Label>
            {nameValid && (
              <p className="vError">Please Enter A Valid Role </p>
            )}
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="rdesc">
            <Form.Control as="textarea" rows="2" value={state.rdesc} placeholder="Role Description" onChange={handleChange} />
            <Form.Label>Description</Form.Label>
          </Form.Group>
        </Form.Row>
        <h4 className="subTitle plusHr">Permissions<sup>*</sup></h4>
        <ul class="permissionsList">
          {functionalities.response.length > 0 && functionalities.response.map((func, index) => ( //first array of response

            <React.Fragment key={index}>
              <li>
                <Form.Check label={func.name} type="switch" checked={appliedfunctionalities.includes(func.id)} onChange={handlepermissionChange} id={func.id} />
                <ul>
                  {func.menuone && func.menuone.length > 0 && func.menuone.map((mone, i) => ( //inside first array
                    <React.Fragment key={i}>
                      <li>
                        <Form.Check label={mone.name} checked={appliedfunctionalities.includes(mone.id)} onChange={handlepermissionChange} type="switch" id={mone.id} />
                        <ul>
                          {mone.menutwo && mone.menutwo.length > 0 && mone.menutwo.map((mtwo, i) => ( //inside second array
                            <React.Fragment key={i}>
                              <li><Form.Check label={mtwo.name} checked={appliedfunctionalities.includes(mtwo.id)} onChange={handlepermissionChange} type="switch" onChange={handlepermissionChange} id={mtwo.id} />
                                <ul>
                                  {mtwo.menuthree && mtwo.menuthree.length > 0 && mtwo.menuthree.map((mthree, i) => ( //inside third array
                                    <React.Fragment key={i}>
                                      <li><Form.Check label={mthree.name} checked={appliedfunctionalities.includes(mthree.id)} type="switch" onChange={handlepermissionChange} id={mthree.id} /></li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </li>

            </React.Fragment>
          ))}
        </ul>

        <div className="text-right buttonGrop pb-0">
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleCancel}
          > Back</Button>
          {!enableUpdate && (
            <Button
              size="xs"
              variant="outline-secondary"
              type="submit"
              onClick={handleReset}
            > Reset</Button>
          )}
          {!enableUpdate && (
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={handleRoleCreation}
            > Add Role</Button>
          )}
          {enableUpdate == true && (
            <Button
              size="xs"
              variant="outline-primary"
              type="submit"
              onClick={handleRoleUpdate}
              isDisabled={props.disableBtn}
            > Update Role</Button>
          )}

        </div>

      </form>
    </>
  )
}
export default CreateRole