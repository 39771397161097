import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL

/**
 * @description: Integrated the Credit Management services
 * @author: Rambabu
 * @date:09-09-2020
 * @param {*} req 
 */
class CreditManagementService {

    /**
     * @description: Save Credit Management services
     * @author: Rambabu
     * @date:09-09-2020
     * @param {*} req 
     */
    async save(req) {
        console.log('save credit management data is----' + JSON.stringify(req))
        return await axios.post(`${API_URL}/att/credit/create`, req)
    }

    /**
     * @description: Retrieve Credit limit resepective company id
     * @author: Rambabu
     * @date:09-09-2020
     * @param {*} id 
     */
    async getCompanyCreditLimtData(id) {
        return await axios.get(`${API_URL}/att/credit/retrieve/${id}`);
    }

    /**
     * @description: update Credit limit resepective company id
     * @author: Rambabu
     * @date:09-09-2020
     * @param {*} data 
     */
    async updateCreditlimit(data) {
        return await axios.put(`${API_URL}/att/credit/update/${data.credit_id}`, data)
    }



}

export default new CreditManagementService()