
import React, { useState,useContext,useEffect } from 'react';
import { Table } from "react-bootstrap";
import Utilities from '../../commonUtils/Utilities';
import {Context} from '../../../../App'
import FormateCurrency from '../../commonUtils/FormateCurrency';


/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function VoidFareDetails(props) {
    const [context, setContext] = useContext(Context);
    const [ttlRefund,setTotalRefund] = useState("0.00")

    /**
     * @description : the total refund is added regarding the selection of user Type
     * @author : Azamuddin,<Lakshmi>
     * @serviceGivenBy : Rambabu
     * @date : 11-04-2021
     */
    useEffect(() => {
        if(props.voidData.length > 0 && props.voidData[0].pax.length > 0) {
            let totalRefundableAmount = 0
            //iterating each pax ttlrefundamount to show in UI
            for(let paxDetails of props.voidData[0].pax) {
                totalRefundableAmount = totalRefundableAmount + paxDetails.ttlrefund
            }
            /***---------if calculated totalrefund amount comes correctly then----------- */
            if(totalRefundableAmount > 0) {
            setTotalRefund(totalRefundableAmount.toFixed(2))
            }
        }
    },[props.voidData])




    return (
        <div className="voidFare">
            {props.voidData.length !== 0 && props.voidData !== undefined &&
                 <Table className="m-0">

                        <tr>
                            <th width="25%">Pax Name</th>
                            <th width="15%">Type</th>
                            <th width="15%">Ticket Number</th>
                            <th width="15%">Total Fare</th>
                            <th width="15%">Void Fee</th>
                            <th width="15%" className="text-right">Refund Amount</th>
                        </tr>

                        {props.voidData!==undefined && props.voidData[0].pax.map((pax, idx) => (
                            <tr>
                                <td>
                                    <span>{pax.paxname.toUpperCase()} {pax.is_lead_pax_no ? "(Lead)" : ""}</span>
                                </td>
                                <td>
                                    <span>{pax.ptype}</span>
                                </td>
                                <td>
                                    <span>{pax.tkt_no}</span>
                                </td>
                                <td>
                                    <span>{<FormateCurrency value={pax.totalfare.toFixed(2)} />}</span>
                                </td>
                                {props.type == "ATT" &&
                                <td>
                                    <span>{<FormateCurrency value={pax.attvoidservicefee.toFixed(2)} />}</span>
                                </td>
                                 }

                                 {props.type == "TA" &&
                                 <td>
                                    <span>{<FormateCurrency value={pax.tavoidservicefee.toFixed(2)} />}</span>
                                    </td>
                                 }
                                  { props.type == "STA" &&
                                  <td>
                                    <span>{<FormateCurrency value={pax.stavoidservicefee.toFixed(2)} />}</span>
                                    </td>
                                 }
                                <td className="text-right">
                                    <span>{<FormateCurrency value={pax.ttlrefund.toFixed(2)} />}</span>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="total text-right" colSpan="6">
                            <span className="mr-2">Grand Total  : </span> <strong > {<FormateCurrency value={ttlRefund} />}</strong>
                            </td>
                        </tr>
                </Table>

            }


            {/*  Grand total after void to be refunded to the Customer */}

            <div className="total d-flex justify-content-end mt-0">

            </div>
        </div>
    )
}
export default VoidFareDetails