
import React, {  useState, useEffect } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
// import ReturnHeaderLoader from './ReturnHeaderLoader'
//Main Function
function Amenities(props) {
  const [state, setState] = useState({})
 // const [showAmenities, setShowAmenities] = useState(false)
  const [showAmenities, setShowAmenities] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>


          <Row>
              <Col xs={12}>
               <strong>Most popular facilities</strong>
               {props.amenities!==undefined &&
               <>
               <ul>
               {Object.keys(props.amenities).map(key => (
              <li value={key.name}><strong className="ezyIcon icon-Confirmed" title={props.amenities[key].name}><span>{props.amenities[key].name}</span></strong></li>
               )).slice(0, showAmenities ? props.amenities.length :'12')}
               </ul>

               </>
                }
                  {Object.keys(props.amenities).length >=13 ?
                        <Button className="" variant="link" onClick={() => setShowAmenities(!showAmenities)}>
                            {showAmenities ? "- Show less" : "+ Show All"}
                        </Button> :''}

              </Col>
          </Row>

    </>
  )
}


export default Amenities;
