
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import ShowMoreText from 'react-show-more-text';
import DateUtils from '../../../commonUtils/DateUtils';
import {isNotNull } from '../../../commonUtils/validators';
let bookingStatus = require('../../../masterData/BookingStatus..json');

function RoomPaxDetails(props) {
    const [state, setState] = useState({})
    const [room, setRoom] = useState(props.roomObj);
    const [remarks, setRemarks] = useState("");
    const [cancel, setCancel] = useState("");
    const [brdbs, setBrbds] = useState([]);
    const [roomStatusList, setRoomStatusList] = useState([]);

    useEffect(() => {
        if (props.roomObj !== undefined) {
            if(props.bookingType!==undefined && props.bookingType==="DIRECT"){
                if(isNotNull(props.roomObj.remarks)){
                let remrk = JSON.parse(props.roomObj.remarks)
                setRemarks(remrk)
                }
                if (props.roomObj.rmpr.canpol.includes("non refundable")) {
                    setCancel("Non Refundable")
                } else {
                    let can = JSON.parse(props.roomObj.rmpr.canpol)
                    setCancel(can)
                }
            }else{
                setCancel(props.roomObj.rmpr.canpol);
                setRemarks(props.roomObj.remarks)
            }
            if(props.roomObj.brdbs!==undefined){
                let bbs = JSON.parse(props.roomObj.brdbs)
                setBrbds(bbs)
            }

        }
    }, [])
    const executeOnClick = (isExpanded) => {
    }
    const statusList = [
        { value: '23', label: 'Confirmed' },
        { value: '28', label: 'Cancelled' },
        { value: '24', label: 'Amended' },
        { value: '22', label: 'On hold' },
        { value: '41', label: 'Partially Confirmed' }]


    const getStatus = (id) => {
        let stary = statusList.filter((obj) => obj.value === id);
        return stary[0].label;
    }

    const handleRoomChange = (type) => (e) => {
        const { id, value } = e.target
        setState({ [e.target.name]: e.target.value });
        if (room !== undefined) {
            if (type === "status") {
                room.rst = getStatus(value);
                room.changed = true;
                room.stid = value;
            }
            if (type === "ref") {
                room.htlrefno = value;
                room.changed = true;
            }
        }
        props.sendSelRoom(room)
    }

    useEffect(() => {
        const ticketsList = statusList.map((obj) =>
            <option value={obj.value}>{obj.label}</option>
        );
        setRoomStatusList(ticketsList);
        for (let val of statusList) {
            if (val.label === room.rst) {
                room.stid = val.value;
            }
        }
    }, [props.enableEdit])

const handleComma = (bb, brbds, idx)=>{
    if(idx == brbds.length-1){
        return bb;
    }
    else{
        return bb+","
    }
}

    return (
        <>
            <div className="header mt-4">
                <Row>
                    <Col>Guest Name</Col>
                    <Col>Occupancy</Col>
                    <Col>Bed Type</Col>
                    <Col>Board Type</Col>
                    <Col>Hotel Conf No</Col>
                    <Col>Status</Col>
                    <Col>Special Request</Col>
                </Row>
            </div>
            <div className="passengerList">
                <Row>
                    {/* firstname & last name */}
                    <Col>{room.rmpax[0].tl} {room.rmpax[0].fn} {room.rmpax[0].ln}</Col>
                    {/*  <Col xs="2">{props.price.adt} Adult(s), {isNotNull(props.price.chd)&&(props.price.chd)+',Children'}</Col> */}

                    {/* occupancy */}
                    {room.chd > 0 ? <Col>{room.adt} Adult(s), {room.chd} Child(s)</Col> :
                        <Col>{room.adt} Adult(s)</Col>}
                    {/* bed type */}
                        <Col>{room.bed_type}</Col>
                    {/* board type */}
                    <Col>
                    <span className="d-flex">
                        {brdbs!==undefined && brdbs.length !== 0 && brdbs.map((bb, index) => (
                            <>{bb.name !== undefined? handleComma(bb.name, brdbs, index ): handleComma(bb, brdbs, index )} </>
                        ))}
                        </span>
                        {brdbs.length === 0 &&
                            <span> {"Room only"}</span>
                        }

                    </Col>
                    {/* Hotel reference number */}
                    <Col>
                        {!props.enableEdit && (room.htlrefno !== undefined && room.htlrefno ? room.htlrefno : "N/A")}
                        {props.enableEdit &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="htlrefno" className="d-flex align-items-center justify-content-between">
                                    <Form.Control type="text" placeholder="Enter Ticket number" value={room.htlrefno} onChange={handleRoomChange("ref")} />
                                </Form.Group>
                            </Form.Row>
                        }
                    </Col>
                    {/* Room level status */}
                    <Col className="status" >
                        {!props.enableEdit &&
                            <>
                                {room.rst === "Cancelled" &&
                                    <span className="status paxstatus text-danger"> Cancelled</span>
                                }
                                {room.rst === "On hold" &&
                                    <span className="status paxstatus"> On Hold</span>
                                }
                                {room.rst === "Confirmed" &&
                                    <span className="status paxstatus text-success"> Confirmed</span>
                                }
                                {room.rst === "Incomplete" &&
                                    <span className="status paxstatus text-danger">{room.rst}</span>
                                }
                                {room.rst.toUpperCase() === "FAILED" &&
                                    <span className="status paxstatus text-danger">Failed</span>
                                }
                                {/* <span className={room.rst==="Confirmed" ? 'status confirm' : 'text-danger'}>{room.rst}</span> */}
                            </>
                        }
                        {props.enableEdit &&
                            <Form.Row>
                                <Form.Group as={Col} className="customSelect editOne" controlId="stid" >
                                    <Form.Control as="select" value={room.stid} onChange={handleRoomChange("status")}>
                                        {roomStatusList}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        }
                    </Col>
                    {/* Special Request */}
                    <Col>{room.rmpax[0].spreq !== undefined && room.rmpax[0].spreq ? room.rmpax[0].spreq : "N/A"}</Col>
                </Row>
            </div>

            <div className="passInfo">
                <Row>

                         {/* Cancellation Remarks */}
                    {room.rst === "Cancelled"  && room.cancel_remarks && room.cancel_remarks!==" " && room.cancel_remarks!=="" &&
                     <Col xs={12}>
                        <div className="info">
                            <strong>Cancellation Remarks</strong>
                            {cancel !== undefined && cancel !== "Non Refundable" &&
                                <div className="plcyInfo">
                                    <p>{room.cancel_remarks}</p>
                                </div>
                            }
                        </div>
                        </Col>
                    }
                    {room.rst !== "On hold" && cancel !== undefined && cancel !== "Non Refundable" &&
                    <Col xs={6}>
                        <div className="info">
                        <strong>Cancellation Policy</strong>
                                <div className="plcyInfo">
                                    <p>{cancel}</p>
                                </div>

                        </div>
                    </Col>}
                    {room.rst === "On hold" &&
                    <Col xs={6}>
                        <div className="info">
                        <strong>Cancellation Policy (Hold Booking)</strong>
                        <div className="plcyInfo">
                                <p>Your booking is on hold till {DateUtils.prettyDate(room.rmpr.freeCancelDate, "ddd, MMM DD, YYYY HH:mm:ss")}. Please Pay before to avoid auto cancellation.</p>
                            </div>
                        </div>
                    </Col>}
                    {cancel !== undefined && cancel == "Non Refundable" && room.rst === "Confirmed" &&
                    <Col xs={6}>
                        <div className="info">
                        <strong>Cancellation Policy</strong>
                        <div className="plcyInfo">
                            <p>Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws.</p>
                        </div>
                        </div>
                    </Col>}

                    <Col xs={6}>
                    {remarks!==undefined && remarks!==null && 
                        <div className="info">
                    {/* Importinfo(Remarks) */}
                    <strong>Important Information </strong>
                    <div className="plcyInfo">
                        <ShowMoreText
                            /* Default options */
                            lines={2}
                            more='Show more'
                            less='Show less'
                            className='content-css'
                            anchorClass='my-anchor-css-class'
                            onClick={executeOnClick}
                            expanded={false}
                        >
                        {props.bookingType!==undefined && props.bookingType==="DIRECT" &&
                         <>
                        {remarks.instructions!==undefined && remarks!==null && remarks.instructions!==null &&
                          <div dangerouslySetInnerHTML={{__html: remarks.instructions}} ></div>
                        }
                        {remarks!==null && remarks.specialInstructions!==null &&
                         <div dangerouslySetInnerHTML={{__html: remarks.specialInstructions}} ></div>
                         }
                         </>
                        }
                        {props.bookingType!==undefined && props.bookingType==="MANUAL" &&
                         <>
                         <div dangerouslySetInnerHTML={{__html: remarks}} ></div>
                         </>
                         }
                        </ShowMoreText>
                    </div>
                </div>
                }
                    </Col>
                </Row>

                </div>




        </>
    )
}


export default RoomPaxDetails;
