import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import { Button, OverlayTrigger, Tooltip, Modal, Form, Alert,Row, Col } from "react-bootstrap";
import "@reach/combobox/styles.css";
import { isNotNull, isArrayNotEmpty } from '../../../commonUtils/validators';
export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";

/**
* @description:This function will the Room Occupancy
* @author: Lakshmi
* @param {*}
* @function Confirm details
* @date : 13-02-2021
*/
function ResultsMap(props) {
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selMark, setSelMark] = useState(null);
  const [resp, setResp] = useState(props.response);
  const [selHotel, setSelHotel] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [searchReq, setSearchReq] = useState(props.request);
  const [markupVal, setMarkup] = useState(props.markup);
  const [butype, setButype] = useState("");
  const mapRef = React.useRef();
  const [key, setKey] = useState("AIzaSyBPnDatU8GFmaTp3-rfJAKmjLS6bPMEjrY");
  const libraries = ["places"];


  useEffect(() => {
    if (searchReq !== undefined) {
      setButype(searchReq.butype);
    }
    if (resp !== undefined && resp.prpts.length !== 0) {
      let marks = [];
      for (let property of resp.prpts) {
        if (property.cont !== undefined && property.cont.location !== undefined && property.cont.location.coordinates !== undefined) {
          const payload = {
            lat: property.cont.location.coordinates.latitude,
            lng: property.cont.location.coordinates.longitude
          }
          marks.push(payload)
        }
      }
      setMarkers(marks)
    }
  }, [resp])


  //Set the filter response
   useEffect(() => {
    if (isNotNull(props.mapfilterRes)) {
          let marks = [];
          for (let property of props.mapfilterRes.prpts) {
            if (property.cont !== undefined && property.cont.location !== undefined && property.cont.location.coordinates !== undefined) {
              const payload = {
                lat: property.cont.location.coordinates.latitude,
                lng: property.cont.location.coordinates.longitude
              }
              marks.push(payload)
            }
          }
        setMarkers(marks);

    }
}, [props.mapfilterRes.prpts])




  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: key,
    libraries,
  });
  const mapContainerStyle = {
    height: "100vh",
    width: "100%",
  };
  const options = {
    styles: "",
    disableDefaultUI: true,
    zoomControl: true,
  };
  const center = {
    lat: markers.length !== 0 && markers[0].lat,
    lng: markers.length !== 0 && markers[0].lng,
  };

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(12);
  }, []);

  const onMapClick = React.useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const showHotelDetails = (marker) => {
    let hotlAry = resp.prpts.filter((obj) => obj.cont !== undefined && obj.cont.location !== undefined && obj.cont.location.coordinates !== undefined && obj.cont.location.coordinates.latitude === marker.lat &&
      obj.cont.location.coordinates.longitude === marker.lng);
    setSelHotel(hotlAry)
    setSelected(marker)
  }

  //Show Hotel Image
  const showHtlImage = (image) => {
    let imgs = image.links["350Px"]
    return imgs.href;
  }

  const handleMouseOver = (marker) => () => {
    setShowInfoWindow(true)
    let hotlAry = resp.prpts.filter((obj) => obj.cont !== undefined && obj.cont.location !== undefined && obj.cont.location.coordinates !== undefined && obj.cont.location.coordinates.latitude === marker.lat &&
      obj.cont.location.coordinates.longitude === marker.lng);
    setSelHotel(hotlAry)
    setSelMark(marker)
    panTo({ lat: marker.lat, lng: marker.lng });
  };
  const handleMouseExit = (marker) => () => {
    setShowInfoWindow(false)
  };

  const chooseRoom = (htlId,isPackage,sup) => () => {
  let selHtl = []
  if(sup===0){
      selHtl= resp.prpts.filter(obj => Number(obj.pid) === Number(htlId));
  }else{
      selHtl= resp.prpts.filter(obj => obj.pid === htlId);
  }
  selHtl[0].type=resp.type!==undefined?resp.type:"";
  selHtl[0].sgst=resp.sgst!==undefined?resp.sgst:0;
  selHtl[0].cgst=resp.cgst!==undefined?resp.cgst:0;
  selHtl[0].igst=resp.igst!==undefined?resp.igst:0;
  selHtl[0].tds_per=resp.tds_per!==undefined?resp.tds_per:0;
    var htl = JSON.stringify(selHtl[0]);
    localStorage.setItem(HTL_REQUEST, JSON.stringify(searchReq))
    var w = window.open("/ezytrip/bookings/hotel/review");
    w.htl = htl;
    w.isPackage = isPackage;
  }

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const showList=()=>{
    props.shwoListViewNew(true)
  }

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  return (
    // Important! Always set the container height explicitly
    <div className="resultMapView" style={{ height: '100vh', width: '100%' }}>

    <Button className="mapclose ezyIcon icon-close" onClick={showList}> Close</Button>
    <div>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={(selMark===null && center) || (selMark!==null && selMark!==undefined && { lat: selMark.lat, lng: selMark.lng })}
        options={options}
        onLoad={onMapLoad}
      >
        {markers.length !== 0 && markers.map((marker,id) => (
          <Marker
            key={id}
            position={{ lat: marker.lat, lng: marker.lng }}
            //onMouseOver={handleMouseOver(marker)}
            //onMouseOut={handleMouseExit(marker)}
            onClick={() => {
              showHotelDetails(marker);
            }}
          />
        ))}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            className="myvall"
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
            {selHotel!==0 && selHotel[0] !== null && selHotel[0].cont !== undefined && selHotel[0].cont!=="" && selHotel[0].cont!==null &&
                <div className="resultMap clickView d-flex">
                  <div className="htlImage">
                    <img
                      src={selHotel[0].cont.image}
                      className="htlImg"
                      title="Hotel"
                      alt="Hotel"
                    />
                    {selHotel[0].cont.catnm !== "Hotel" ? <sapn class="typeHotel">{selHotel[0].cont.catnm} </sapn> : ""}
                  </div>
                  <div className="hotelInfo">
                    <div className="propertyName">{selHotel[0].cont.name}
                    </div>
                        <div className="extDetails">
                        {selHotel[0].cont.ratings !== undefined && selHotel[0].cont.ratings !== 0 &&
                          <>
                              <span className="stars">
                              <span className={"star star"+Math.ceil(selHotel[0].cont.ratings)}></span>
                              </span>
                          </>
                            }
                        </div>
                    <p className="propertyAdress"><span className="ezyIcon icon-map"></span>{selHotel[0].cont.line1}, {selHotel[0].cont.city}, {selHotel[0].cont.countryCode}</p>
                    <p className="xpRating">
                        {selHotel[0].cont.urat !== "" &&
                          <>
                            <em>{selHotel[0].cont.urat + "/5"}</em> {selHotel[0].usrst}
                          </>
                        }
                        {selHotel[0].cont.expediareview !== 0 &&
                          <>
                            <span className="icons xpIcon"></span>
                           ({selHotel[0].cont.expediareview} Reviews)
                          </>
                        }
                      </p>
                  </div>

                  <div className="priceInfo">
                    <div className="review">
                      {/* Hot deal price */}
                        {selHotel[0].package !== undefined && selHotel[0].package &&
                        <div className="pkgRate ezyIcon icon-Package">
                          <span>Package Rate</span>
                          </div>
                        }
                      {selHotel[0].disc !== "0" && selHotel[0].disc !== 0 &&
                        <div className="deals">
                          <span>Hot Deal</span>
                        </div>
                      }

                    </div>
                    {/* Total price */}
                    <div className="price">
                      <div className="priceTag">
                      {selHotel[0].disc !== "0" && selHotel[0].disc !== 0 &&
                      <div>
                        {markupVal === 0 &&
                          <div className="blockPrice"> <strong className="offers"><em>{props.request.cur}</em>{selHotel[0].rdwtf}</strong></div>
                        }
                        {markupVal !== 0 &&
                          <div className="blockPrice"><strong className="offers"><em>{props.request.cur}</em>{parseFloat(selHotel[0].rdwtf) + parseFloat(markupVal)}</strong></div>
                        }</div>}
                      <div className="d-flex align-items-center">
                          {markupVal === 0 &&
                            <div className="blockPrice"><strong><em>{props.request.cur}</em>{selHotel[0].rtf}</strong></div>
                          }
                          {markupVal !== 0 &&
                            <div className="blockPrice"><strong><em>{props.request.cur}</em>{parseFloat(selHotel[0].rtf) + parseFloat(markupVal)}</strong></div>
                          }
                      </div>
                      </div>
                      <p className="nightPriceinfo">Price for all for {props.nights} nights</p>
                      <Button
                        size="xs"
                        onClick={chooseRoom(selHotel[0].pid,true,selHotel[0].hs)}
                        className="choose"
                        variant="primary">
                        CHOOSE ROOM
                        <span className="ezyIcon icon-rightArrow"></span>
                      </Button>
                    </div>
                  </div>

                </div>
              }
            </div>
          </InfoWindow>
        ) : null}
        {showInfoWindow && selMark !== null ? (
          <InfoWindow
            onPositionChanged={false}
            position={{ lat: selMark.lat, lng: selMark.lng }}
            onCloseClick={() => {
              setSelMark(null);
            }}
          >
            <div>
              {selHotel[0] !== null &&
                <div className="resultMap hoverMapLayout d-flex">
                  <div className="htlImage">
                    <img
                      src={showHtlImage(selHotel[0].cont.image[0])}
                      className="htlImg"
                      title="Hotel"
                      alt="Hotel"
                    />
                    {selHotel[0].cont.catnm !== "Hotel" ? <sapn class="typeHotel">{selHotel[0].cont.catnm} </sapn> : ""}
                  </div>
                  <div className="hotelInfo">
                  <div className="propertyName">{selHotel[0].cont.name}</div>
                  {selHotel[0].cont.ratings !== undefined && selHotel[0].cont.ratings !== 0 &&
                          <>
                              <span className="stars">
                              <span className={"star star"+Math.ceil(selHotel[0].cont.ratings)}></span>
                              </span>
                          </>
                            }

                  <p className="xpRating">
                        {selHotel[0].usrst !== "" &&
                          <>
                            <em>{selHotel[0].cont.urat + "/5"}</em> {selHotel[0].usrst}
                          </>
                        }
                        {selHotel[0].cont.expediareview !== 0 &&
                          <>
                            <span className="icons xpIcon"></span>
                           ({selHotel[0].cont.expediareview} Reviews)
                         </>
                        }
                      </p>
                  </div>
                  <div className="priceInfo">
                  <div className="review">
                      {/* Hot deal price */}
                        {selHotel[0].package !== undefined && selHotel[0].package &&
                        <div className="pkgRate ezyIcon icon-Package">
                          <span>Package Rate</span>
                          </div>
                        }
                      {selHotel[0].disc !== "0" && selHotel[0].disc !== 0 &&
                        <div className="deals">
                          <span>Hot Deal</span>
                        </div>
                      }
                    </div>
                    {/* Total price */}
                    <div className="price">
                    <div className="priceTag">
                      {selHotel[0].disc !== "0" && selHotel[0].disc !== 0 &&
                      <div>
                        {markupVal === 0 &&
                          <div className="blockPrice"> <strong className="offers"><em>{props.request.cur}</em>{selHotel[0].rdwtf}</strong></div>
                        }
                        {markupVal !== 0 &&
                          <div className="blockPrice"><strong className="offers"><em>{props.request.cur}</em>{selHotel[0].rdwtf + parseFloat(markupVal)}</strong></div>
                        }</div>}
                      <div className="d-flex align-items-center">
                          {markupVal === 0 &&
                            <div className="blockPrice"><strong><em>{props.request.cur}</em>{selHotel[0].rtf}</strong></div>
                          }
                          {markupVal !== 0 &&
                            <div className="blockPrice"><strong><em>{props.request.cur}</em>{selHotel[0].rtf + parseFloat(markupVal)}</strong></div>
                          }
                      </div>
                      </div>
                    <p className="nightPriceinfo">Price for all for {props.nights} nights</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>

  </div>
);
}


export default ResultsMap;
