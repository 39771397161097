
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Modal, Row, Table, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import MainMenu from '../../../../components/common/MainMenu';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import {searchProfile,fetchTravellers,updateProf,updateTravellers} from './operations';
import { stdCodes,countriesWitCode } from '../../../adminstration/utils';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
import DateUtils from '../../../commonUtils/DateUtils';
import DatePicker from "react-datepicker";
let stdCodesMaster = require('../../../masterData/CountrySTDCodes.json');
/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Exchange Rate Configuration
 * @date : 13-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    id:0,compList:[],companyNames:[],email:"",res:[],enbaleUpdate: false,title:"",stdCodeMenu:stdCodes(),fn:"",ln:"",phn:"",phc:"",countriesMenu: [],ufn:"",uln:"",uphn:"",uphc:"",utl:"",upnum:"",una:"",uisc:"",uisd:"",uexd:"",uemail:"",selUTitle:[],trv_id:0,selUPhc:[],
    noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false,showPoppup:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, id:0,enbaleUpdate: false,
                noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,fromError:false,toError:false
            };
        case 'editUser':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Editprofile Configuration
 */
function Editprofile(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [validated, setValidated] = useState(false);
    const [request, setRequest] = useState(props.location);
    const [context, setContext] = useContext(Context);
    const [issueDate,setIssueDate] = useState(null);
    const [expDate,setExpDate] = useState(null);
    const titles = [
        { label: "Mr", value: "Mr" },
        { label: "Miss", value: "Miss" },
        { label: "Mrs", value: "Mrs" },
        { label: "Ms", value: "Ms" },
        { label: "Shka", value: "Shka" },
        { label: "Shk", value: "Shk" },
      ];

    useEffect(() => {
        countriesWitCode().then((res) => {
            dispatch({ type: 'countriesMenu', payload: res })
          });
    }, []);

    useEffect(() => {
        if(request!==undefined && request.state.req!==undefined){
            dispatch({ type: 'title', payload: request.state.req.tl })
            dispatch({ type: 'fn', payload: request.state.req.fn })
            dispatch({ type: 'ln', payload: request.state.req.ln })
            dispatch({ type: 'email', payload: request.state.req.email })
            dispatch({ type: 'phn', payload: request.state.req.phn })
            dispatch({ type: 'phc', payload: request.state.req.phc })
            dispatch({ type: 'selTitle', payload: [{ "value": request.state.req.tl, "label": request.state.req.tl }] })
            stdCodesMaster.map((std, sidx) => {
                if (request.state.req.phc!==null && request.state.req.phc!==undefined && Number(std.code) === Number(request.state.req.phc)) {
                  dispatch({ type: 'selPhc', payload: [{ "value": std.name, "label": std.name }] })
                }
              });//request.state.req.id
            fetchTravellers({"user_id":10371}).then((res) => {
                if(res.data.suc){
                    convertData(res.data)
                }
              });
        }
    }, [request]);

    //Search User based on the company
    const updateProfile=()=>{
        const req={
            "id": request.state.req.id,
            "fn": state.fn,
            "ln": state.ln,
            "tl":state.title,
            "phn":state.phn,
            "phc":state.phc,
            "upby":context.logindata.uid
           }
        updateProf(req).then(response => {
                if (response.data.suc){
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Profile details updated successfully...' })
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    hidemessage();
                }else{
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Unable to update Profile details' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage();
                }
        })
    }

     //Search User based on the company
     const updateTraveller=()=>{
        const req=
        {
            "id": state.trv_id,
            "prof_id": request.state.req.id,
            "fn": state.ufn,
            "ln": state.uln,
            "tl":state.utl,
            "phn":state.uphn,
            "phc":state.uphc,
            "dob":state.udob,
            "email": state.uemail,
            "upby":context.logindata.uid,
            "doc_num":state.upnum,
            "doc_id":7896,
            "na":state.una,
            "isc":state.uisc,
            "exd":expDate!==null?DateUtils.convertStringToDate(expDate):null,
            "isd":issueDate!==null?DateUtils.convertStringToDate(issueDate):null
          }
          updateTravellers(req).then(response => {
                if (response.data.suc){
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Traveller details updated successfully...' })
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    hidemessage();
                }else{
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Unable to update Traveller details' })
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage();
                }
        })
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    const Back=()=>{
        window.location="/ezytrip/administration/userprofile";
    }

    //Converting all suppliers
    const convertData = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response.res){
                    data.push({name:val.tl+" "+val.ufn+" "+val.uln,pax_type:val.pax_type,email: val.email, phn:val.phc+"-"+val.phn,ptype:val.pftype,options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editprofile(val)}></a></span></>})
                }
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'isLoadingMore', payload: false });
    }

     //Edit Categeory
     const editprofile=(obj)=>{
            dispatch({ type: 'showPoppup', payload: true });
            dispatch({ type: 'trv_id', payload: obj.trv_id })
            dispatch({ type: 'ufn', payload: obj.ufn })
            dispatch({ type: 'uln', payload: obj.uln })
            dispatch({ type: 'uemail', payload: obj.email })
            dispatch({ type: 'uphn', payload: obj.phn })
            dispatch({ type: 'upnum', payload: obj.phc })
            dispatch({ type: 'una', payload: obj.nat })
            dispatch({ type: 'uisc', payload: obj.issued_cnt })
            dispatch({ type: 'selUTitle', payload: [{ "value": obj.tl, "label": obj.tl }] })
            stdCodesMaster.map((std, sidx) => {
                if (request.state.req.phc!==null && request.state.req.phc!==undefined && Number(std.code) === Number(request.state.req.phc)) {
                  dispatch({ type: 'selUPhc', payload: [{ "value": request.state.req.phc, "label": std.name }] })
                }
              });
            if(obj.issued_date!==null && obj.issued_date!=="Invalid date"){
                setIssueDate(new Date(obj.issued_date))
            }
            if(obj.exp_date!==null && obj.issued_date!=="Invalid date"){
                setExpDate(new Date(obj.exp_date))
            }
    }

     //Edit Categeory
     const cancelReq = () => {
        dispatch({ type: 'reqObj', payload: "" });
        dispatch({ type: 'showPoppup', payload: false });
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'pax_type',
            text: 'Pax Type',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'email',
            text: 'Email Id',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'phn',
            text: 'Phone Number',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'ptype',
            text: 'Traveller Type',
            sort: true,
            filter: textFilter()
        },
        {
            dataField: 'options',
            text: 'Actions',
            class: 'tes'
        }
        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }];

        const handleTitle=(e)=>{
            dispatch({ type: 'title', payload: e[0].value })
          }

          const handleUTitle=(e)=>{
            dispatch({ type: 'utl', payload: e[0].value })
          }

          const handlephnCode = (e) => {
            if(e && e.length > 0) {
              if(e[0].value !== "Select") {
                let bhpcSplit = e[0].value.split("(")
                let bhpc = bhpcSplit[1].split(")")
                dispatch({ type: 'phc', payload: bhpc[0] })
                }
            }
          }

          const handleUserphnCode = (e) => {
            if(e && e.length > 0) {
              if(e[0].value !== "Select") {
                let bhpcSplit = e[0].value.split("(")
                let bhpc = bhpcSplit[1].split(")")
                dispatch({ type: 'uphc', payload: bhpc[0] })
                }
            }
          }

          const handleChange = (evt) => {
              const { id, value } = evt.target;
              dispatch({ type: id, payload: value });
            }

        const cancel=()=>{
            dispatch({ type: 'showPoppup', payload: false })
        }

        const handleIssuDateChange = date => {
            setIssueDate(date);
          }
          const handleExpDateChange = date => {
            setExpDate(date);
          }

    return (
        <>
            <MainMenu active='Organization/CM' />
            <Breadcrumb activePage="User Profiles" />
            <div className="container">
              <div className="cardMainlayout pt-0">
                <OrganizationMenu active="User Profile" />
                <div className="contentArea">
                        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                            dismissible> {state.notiMessage}</Alert>
                        <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                            <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctid">
                            <Select
                            options={titles}
                            searchable={false}
                            values={state.selTitle}
                            onChange={handleTitle}
                            />
                            <Form.Label>Title</Form.Label>
                        </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="fn">
                            <Form.Control type="textarea" placeholder="Enter Name" autoComplete="off"
                            value={state.fn} onChange={handleChange} />
                            <Form.Label>First Name</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="ln">
                            <Form.Control type="textarea" placeholder="Enter Name" autoComplete="off"
                            value={state.ln} onChange={handleChange}/>
                            <Form.Label>Last Name</Form.Label>
                            </Form.Group>
                            </Form.Row>
                            <Form.Row>
                            <Form.Group as={Col} xs={3} className="selectplugin mb-0" controlId="phc">
                            <Select
                            options={state.stdCodeMenu}
                            searchable={true}
                            values={state.selPhc}
                            disabled={state.enableImport}
                            onChange={(value) => (handlephnCode(value))}
                            />
                            <Form.Label>Phone Code</Form.Label>
                        </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="email">
                            <Form.Control type="textarea" disabled={true} placeholder="Enter Desc" autoComplete="off"
                            value={state.email}/>
                            <Form.Label>Email Id</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} controlId="phn">
                            <Form.Control type="textarea"  placeholder="Enter Desc" autoComplete="off"
                            value={state.phn} onChange={handleChange} />
                            <Form.Label>Phone Number</Form.Label>
                            </Form.Group>
                            </Form.Row>
                            </Form>
                            <div className="text-right buttonGrop pb-0">
                            <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={Back}
                                    type="submit"
                                >Back</Button>
                            <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateProfile}
                                    loading={state.loadCategory}
                                    type="submit"
                                > Update</Button>
                        </div>
                            </div>
                <span><b>Travellers List({state.res.length})</b></span>
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
            </div>
      <Modal
        show={state.showPoppup}
        onHide={cancelReq}
        className="importPNRMdv editTravell shareIpop"
        backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
          <h5>Edit Traveller</h5>
        <Modal.Body>
        <Form.Row>
            <Form.Group as={Col} xs={4} className="selectplugin" controlId="utl">
            <Select
            options={titles}
            searchable={false}
            values={state.selUTitle}
            onChange={handleUTitle}
            />
            <Form.Label>Title</Form.Label>
        </Form.Group>
            <Form.Group as={Col} xs={4} controlId="ufn">
            <Form.Control type="textarea" placeholder="Enter Desc" autoComplete="off"
            value={state.ufn} onChange={handleChange} />
            <Form.Label>First Name</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={4} controlId="uln">
            <Form.Control type="textarea" placeholder="Enter Desc" autoComplete="off"
            value={state.uln} onChange={handleChange}/>
            <Form.Label>Last Name</Form.Label>
            </Form.Group>
            </Form.Row>
            <Form.Row>
            <Form.Group as={Col} xs={4} className="selectplugin mb-0" controlId="uphc">
            <Select
                options={state.stdCodeMenu}
                searchable={false}
                values={state.selUPhc}
                onChange={(value) => (handleUserphnCode(value))}
                />
            <Form.Label>Phone Code</Form.Label>
        </Form.Group>
            <Form.Group as={Col} xs={4} controlId="uemail">
            <Form.Control type="text" disabled={true} placeholder="Enter Desc" autoComplete="off"
            value={state.uemail}/>
            <Form.Label>Email Id</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={4} controlId="uphn">
            <Form.Control type="text"  placeholder="Enter Desc" autoComplete="off"
            value={state.uphn} onChange={handleChange} />
            <Form.Label>Phone Number</Form.Label>
            </Form.Group>
            </Form.Row>
            <div>
            <h6 className="subTitle">Passport Info</h6>
            <Form.Row>
                <Form.Group as={Col} xs={4} controlId="upnum">
                <Form.Control type="text"  placeholder="Passport Name" value={state.upnum} onChange={handleChange} />
                <Form.Label>Passport Number <sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={4} controlId="una" className="customSelect">
                <Form.Control as="select" value={state.una} onChange={handleChange}>
                    <option value="">Select</option>
                    {state.countriesMenu}
                </Form.Control>
                <Form.Label>Nationality</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={4} controlId="uisc" className="customSelect">
                <Form.Control as="select" value={state.uisc} onChange={handleChange}>
                    <option value="">Select</option>
                    {state.countriesMenu}
                </Form.Control>
                <Form.Label>Issued Country</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={4} className="selectplugin datePicker" controlId="uisd">
                <DatePicker
                    className="form-control"
                    onChange={handleIssuDateChange}
                    selected={issueDate}
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <Form.Label>Issued Date</Form.Label>
                <span className="calendar ezyIcon icon-newcalendar"></span>
                </Form.Group>
                <Form.Group as={Col} xs={4} className="selectplugin datePicker" controlId="uexd">
                <DatePicker
                    className="form-control"
                    onChange={handleExpDateChange}
                    selected={expDate}
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />

                <Form.Label>Expiry Date</Form.Label>
                <span className="calendar ezyIcon icon-newcalendar"></span>
                </Form.Group>
            </Form.Row>
            <div className="text-right buttonGrop pb-0">
                            <Button
                                    size="xs"
                                    variant="outline-secondary"
                                    onClick={cancel}
                                    type="submit"
                                >Cancel</Button>
                            <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateTraveller}
                                    loading={state.loadCategory}
                                    type="submit"
                                > Update</Button>
                        </div>
            </div>

        </Modal.Body>
        </Modal>
            </div>
            <Footer />
        </>
    )
}

export default Editprofile