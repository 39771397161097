
import React from 'react';
import FlightSectors from '../../../bookingFlows/flight/common/FlightSectors'
/**
 * @description:This function will Include the Origns and destinations
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 31-08-2020
 */
function FlightOldItinarary(props) {

    return (
           <>
                {Number(props.itinarary.tt)!==3 &&
                <>
                {props.itinarary.origns[0].srcorigns[0].ref===1 &&
                    <FlightSectors bktype = {props.bktype} revieWFlt={props.itinarary} origin={[props.itinarary.origns[0].srcorigns[0],'Review']} mapping={props.itinarary.mappings} tripType={Number(props.itinarary.tt)}/>
                 }
                 {props.itinarary.origns[0].destorigins!==undefined && props.itinarary.origns[0].destorigins[0]!==undefined && props.itinarary.origns[0].destorigins[0].ref===2 &&
                    <FlightSectors bktype = {props.bktype} revieWFlt={props.itinarary} origin={[props.itinarary.origns[0].destorigins[0],'Review']} mapping={props.itinarary.mappings} tripType={Number(props.itinarary.tt)}/>
                 }
                 </>
                }
                {Number(props.itinarary.tt)===3 &&
                <>
                {props.itinarary.origns[0].srcorigns &&
                    <FlightSectors bktype = {props.bktype} revieWFlt={props.itinarary} originsList={[props.itinarary.origns[0],'Review']} mapping={props.itinarary.mappings} tripType={3}/>
                 }
                 </>
                }

        </>
    )
}
export default FlightOldItinarary;
