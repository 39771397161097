import React from 'react';
import { confirmAlert } from "react-confirm-alert";
import Button from '../buttons/Button'
    //Confirmation dialog popup to delete the Branch
    export const confirmNomoreDlg = (name,cb) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <>
                        <div className="custom-ui">
                          <span className="confirmD"></span>
                          <h3>Payment has failed.. </h3><p>Sorry we are unable to process this transaction.
                              Please try again with another card or a different payment method.
                          </p>
                        </div>
                        <div className="buttonGrop popUpBox text-center pb-0">
                            <Button
                                 size="md"
                                 className="refresh"
                                 variant="primary"
                                onClick={() => {
                                    cb();
                                    onClose();
                                }}>
                                Back to Home
                            </Button>
                           {name==="Payment" &&
                            <Button size="md"
                            className="refresh"
                            variant="secondary" onClick={onClose}>
                            Retry
                           </Button>
                            }
                        </div>
                    </>
                );
            }
        });
    };
