import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
//const API_URL = 'http://localhost:3005'
/**
 * @description: Integrated the OfficeID CURD Operations service
 * @author: Rambabu
 * @date:09-08-2020
 * @param {*} Service 
 */
class OfficeIdConfig {

    /**
     * @description: Save Offcieid ConfigDetails
     * @author: Rambabu
     * @date:09-08-2020
     * @param {*} data 
     */
    async saveOffcieIdConfigDetails(data) {
        console.log("saveOffcieIdConfigDetails" + JSON.stringify(data));
        return await axios.post(`${API_URL}/att/officeid/create`, data)
    }

    /**
     * @description: Retrieve Offcieid ConfigDetails
     * @author: Rambabu
     * @date:09-08-2020     
     */
    async getOfficeIDList(obj) {
        console.log("getOfficeIDList---");
        return await axios.post(`${API_URL}/att/officeid/retrieve`,obj);
    }

    /**
     * @description: Retrieve Offcieid Specifc Data
     * @author: Rambabu
     * @date:09-08-2020 
     * @param {*} id     
     */
    async retrieveOfficeId(id) {
        console.log("retrieveOfficeID---");
        return await axios.get(`${API_URL}/att/officeid/edit/${id}`);
    }

    /**
     * @description: Update Offcieid Specifc Data
     * @author: Rambabu
     * @date:11-08-2020 
     * @param {*} payload     
     */
    async updateOffcieIdConfigDetails(payload) {
        console.log('payload is----' + payload.ofcreq.id);
        console.log("updateOffcieIdConfigDetails" + payload.ofcreq.id);
        return await axios.post(`${API_URL}/att/officeid/update`, payload)
    }

    /**
     * @description: Delete Offcieid Child Data(office_id_list,office_company_map)
     * @author: Rambabu
     * @date:11-08-2020 
     * @param {*} type 
     * @param {*} id    
     */
    async deleteOfficeidChildData(obj) {        
        return await axios.post(`${API_URL}/att/officeid/delete`,obj)
    }



    /**
     * @description: Delete Offcieid Child Data(office_id_route)
     * @author: Rambabu
     * @date:10-08-2020 
     * @param {*} req        
     */
    async deleteOfficeidRoutesData(req) {
        console.log('deleteOfficeidRoutesData----' + JSON.stringify(req));
        return await axios({ method: "DELETE", url: `${API_URL}/att/officeid/rtdelete`, data: req });
    }

     // Office ID Master calls
     async saveOIDMaster(officeID) {
        return await axios.post(`${API_URL}/att/common/officeid_master/save`, officeID)
    }
    async getOIDMasterData(id) {
        return await axios.get(`${API_URL}/att/common/officeid_master/retrieve`);
    }
    async updateOID (obj) {
        return await axios.put(`${API_URL}/att/common/officeid_master/update/${obj.id}`, obj)
    }
    async deleteOID (id) {
        return await axios.delete(`${API_URL}/att/common/officeid_master/${id}`)
    }
    
}
export default new OfficeIdConfig()