import MaxOnholdService from '../../../../services/administration/MaxOnholdService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Hold Bookings
 * @date : 13-06-2021
 */

export const getHoldBookingsData = async (obj) => {
    let holdBookigsList = [];
    await MaxOnholdService.retrieveMaxHoldBookings(obj).then(response => {
        if (response.data.suc) {
            holdBookigsList = response.data.res;
        }
    });
    return Promise.resolve(holdBookigsList);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    await MaxOnholdService.saveMaxHoldBooking(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};



