
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import CustomFields from './index';
import { edit, getCustomsData } from './operation';
export const CustomContext = React.createContext();
/**
 * @description:This function will create Custome details for Company
 * @author: Lakshmi
 * @param {*}
 * @function Custom details
 * @date : 07-09-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  res: [], updateCustoms: false, createCustoms: false, showCreateBtn: true, compType: [], type: [], name: "", searchComp: true
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state,
      };
    case 'editCustom':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};


/**
 * @description:This function will creaet Custom fields and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Custom fields
 */
function CustomMain() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [value, setValue] = useState([]);
  const [attDropDown, setAttDropDown] = useState(false)
  const [context, setContext] = useContext(Context);
  const [enableView, setEnableView] = useState(false)
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [data, setData] = useState();


  const agentTypes = [{ label: "Travel Agent", value: "TA" },
  { label: "Sub Travel Agent", value: "STA" }]

  const types = [{ label: "Company", value: "Company" },
  { label: "Branch", value: "Branch" }]


  useEffect(() => {
    setIsLoadingMore(true);
    if (context.logindata !== "" && context.logindata.roles !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      let obj = {
        type: context.logindata.utype,
        userid: context.logindata.uid,
        is_admin: context.logindata.is_admin,
        region_type: context.logindata.region_type
      }
      getCustomsData(obj).then(response => {
        if (response.data.suc) {
          loadAllCustoms(response.data.res);
          setIsLoadingMore(false);
        }
        setIsLoadingMore(false);
      })

    }
  }, [context.logindata]);

  const loadAllCustoms = (res) => {
    let data = [];

    var enableWEdit = false;
    if (context.logindata.roles.includes('C_F_U')) {
      enableWEdit = true;
    }
    for (let value of res) {
      if (context.logindata.is_admin==1 || context.logindata.region_type==value.region_type){
      data.push({region_type:value.region_type, ctype: value.ctyp, type: value.typ, name: value.name, city: value.ctynm, options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editCustomField(value, "view")}></a>{enableWEdit && <a className="ezyIcon icon-edit" onClick={editCustomField(value, "edit")}></a>} </span></> })
    }
  }
    dispatch({ type: 'res', payload: data })
    setIsLoadingMore(false);
  }

  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }

  const columns = [
  {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
  },
  {
    dataField: 'ctype',
    text: 'Company Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'type',
    text: 'Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  // Edit Custom
  const editCustomField = (obj, type) => () => {
    edit(obj.id).then((editObj) => {
      if (editObj.suc) {
        setData(editObj);
        dispatch({ type: 'updateCustoms', payload: true })
        dispatch({ type: 'searchComp', payload: false })
        dispatch({ type: 'createCustoms', payload: false })
        dispatch({ type: 'showCreateBtn', payload: false })
        if (type === "view") {
          setEnableView(true)
        } else {
          setEnableView(false)
        }
      }
    })
  }
  const createCompBtnClick = (e) => {
    dispatch({ type: 'createCustoms', payload: true })
    dispatch({ type: 'showCreateBtn', payload: false })
    dispatch({ type: 'searchComp', payload: false })

  }

  const handleSearch = () => {
    if (state.compType.length > 0 || state.type.length > 0 || state.name !== "") {
      const payload = {
        "type": state.userType,
        "userid": state.userId,
        "name": state.name,
        "ctype": state.compType && state.compType.length > 0 ? state.compType[0].value : "",
        "typ": state.type && state.type.length > 0 ? state.type[0].value : ""
      }
      getCustomsData(payload).then((resp) => {
        if (resp.data.suc) {
          loadAllCustoms(resp.data.res);
        }
      })
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select at least one search criteria' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const restValue = () => {
    dispatch({ type: 'compType', payload: [] })
    dispatch({ type: 'type', payload: [] })
    dispatch({ type: 'name', payload: "" })
  }
  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Organization/CM' />
      <Breadcrumb activePage="Custom Fields" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <OrganizationMenu active="Custom Fields" />
          <div className="contentArea cmnSearch">
            {state.searchComp ? (
              <>


                <Form.Row>

                  <Form.Group as={Col} xs={3} controlId="name">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.name} onChange={handleChange} />
                    <Form.Label>Name</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctyp">
                    <Select
                      options={agentTypes}
                      searchable={false}
                      values={state.compType}
                      onChange={(value) => (dispatch({ type: 'compType', payload: value }))}
                    />
                    <Form.Label>Company Type </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctyp">
                    <Select
                      options={types}
                      searchable={false}
                      values={state.type}
                      onChange={(value) => (dispatch({ type: 'type', payload: value }))}
                    />
                    <Form.Label>Type </Form.Label>
                  </Form.Group>
                </Form.Row>
                {!state.updateCustoms &&
                  <div className="text-right pb-0  buttonGrop creat_absolute">
                    <Button
                      size="xs"
                      variant="outline-secondary"
                      type="submit"
                      onClick={restValue}
                    > Reset</Button>
                    <Button
                      size="xs"
                      variant="outline-primary"
                      type="submit"
                      onClick={handleSearch}
                    > Search</Button>
                  </div>
                }
              </>) : ''}
            {state.showCreateBtn && context.logindata.roles !== undefined && context.logindata.roles.includes('C_F_C') && (
              <div className="buttonGrop pb-0">
                <Button
                  size="xs"
                  variant="primary"
                  onClick={createCompBtnClick}
                  loading={state.loadBranch}
                  className="addPlus ezyIcon icon-plus"
                  type="submit"
                >  Create Custom Fields</Button>
              </div>
            )}
            {state.createCustoms && (
              <CustomFields />
            )}
            {state.updateCustoms && (
              <CustomContext.Provider value={data}>
                <CustomFields enbleUpdate={enableView} />
              </CustomContext.Provider>
            )}
          </div>
        </div>
        {state.showCreateBtn ? (
          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
              {state.res.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || state.res.length === 0 &&
                <NoRecord />
              }</>)}
          </div>
        ) : ('')}

      </div>
      <Footer />
    </>
  )


}

export default CustomMain