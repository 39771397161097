import React, { useReducer, useState, useContext , useEffect} from 'react';
import { Alert, Col, Form, Dropdown, Row, Container, Table , Modal } from "react-bootstrap";
import Select from 'react-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import {talist,saveNotiAccess,updateNotiAccess,getNotifications,deleteNotifications,activeNotifications} from './operations';
import log from "loglevel";

/**
 * Initial State Declaration
 */
 const initialState = {
    company_id:0,company_name:"",enableUpdate:false,enableDelete:false,
    notiMessage: '', notiMessageShow: false, notiVarient: '',enableEdit:false,enableView:false,assigne_val:[],isItineraryLoading:false
  }
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, categoryName: ''
        };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };


const NotificationAccess = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [companyList , setCompanyList] = useState([])
    const [notificationList , setNotificationList] = useState([])
    const [update , setUpdate] = useState(false)
    const [notiTypes , setNotiTypes] = useState(
     [
        {
        "code": "SCP",
        "name":"Schedule Change prepond",
        "enable":0
        },
        {
        "code": "SCD",
        "name":"Schedule Change Delay",
        "enable":0
        },
        {
        "code": "TC",
        "name":"Terminal Change",
        "enable":0
        },
        {
        "code": "DGC",
        "name":"Departure Gate Change",
        "enable":0
        },
        {
        "code": "FDC",
        "name":"Flight Date Change",
        "enable":0
        },
        {
        "code": "AGC",
        "name":"Arrival Gate Change",
        "enable":0
        },
        {
        "code": "RC",
        "name":"Route Change",
        "enable":0
        },
        {
        "code": "AC",
        "name":"Airport change",
        "enable":0
        },
        {
        "code": "EC",
        "name":"Equipment Change",
        "enable":0
        },
        {
        "code": "BB",
        "name":"Baggage Belt",
        "enable":0
        },
        {
        "code": "ASC",
        "name":"Air Segment cancellation",
        "enable":0
        },
        {
        "code": "FC",
        "name":"Flight Cancellation",
        "enable":0
        }
    ])

    useEffect(() => {
        if(notificationList.length===0){
         getNotifications().then((response) => {
            if(response.suc){
             setNotificationList(response.res)
            }
          });
          }
          talist().then((res) => {
            setCompanyList(res)
          });
   }, []);

const saveNotifications=(type)=>()=>{
    if(state.company_id!==0){
    let newlist=notiTypes.filter((obj) => obj.enable===1);
    if(newlist.length!==0){
    const payload={
        "company_id":state.company_id,
        "company_name":state.company_name,
        "crby":context.logindata.uid,
        "noti_type":newlist
      }
      if(type==="Save"){
        dispatch({ type: "isItineraryLoading", payload: true });
        saveNotiAccess(payload).then(response => {
            if(response.suc) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Notification Saved Successfully...' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            setTimeout(function () {
                return window.location = window.location.pathname;
              }, 2000);
            }
        })
        }else{
          dispatch({ type: "isItineraryLoading", payload: true });
            updateNotiAccess(payload).then(response => {
                if(response.suc) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Notification Updated Successfully...' })
                dispatch({ type: 'notiVarient', payload: 'success' })
                setTimeout(function () {
                    return window.location = window.location.pathname;
                  }, 2000);
                }
            })
       }
      }else{
        dispatch({ type: "isItineraryLoading", payload: false });

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one service and proceed' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
      }
    }else{
      dispatch({ type: "isItineraryLoading", payload: false });

    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Assigne Name' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
}
}

const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

const handleChangeNotification = (idx) => (e) => {
            try {
              const { id, value } = e.target;
              let list = notiTypes.map((notifi, sidx) => {
                if (idx !== sidx) return notifi;
                else {
                  return {
                    ...notifi,enable:value==="false"?1:0,
                    };
                }
              });
              setNotiTypes(list)
              setUpdate(true)
            } catch (err) {
              log.error('Exception occured in handleChangeNotification function---' + err);
            }
          }

 //updating Assigne
  const setAsigneType = (obj) => {
    if (obj!== undefined) {
      dispatch({ type: 'company_id', payload: obj.value })
      dispatch({ type: 'company_name', payload: obj.label })
      dispatch({ type: 'assigne_val', payload: [{label:obj.label, value:obj.value}]})
    }
  }

  const handleEdit=(obj)=>{
    let list= [{
        "code": "SCP",
        "name":"Schedule Change prepond",
        "enable":0
        },
        {
        "code": "SCD",
        "name":"Schedule Change Delay",
        "enable":0
        },
        {
        "code": "TC",
        "name":"Terminal Change",
        "enable":0
        },
        {
        "code": "DGC",
        "name":"Departure Gate Change",
        "enable":0
        },
        {
        "code": "FDC",
        "name":"Flight Date Change",
        "enable":0
        },
        {
        "code": "AGC",
        "name":"Arrival Gate Change",
        "enable":0
        },
        {
        "code": "RC",
        "name":"Route Change",
        "enable":0
        },
        {
        "code": "AC",
        "name":"Airport change",
        "enable":0
        },
        {
        "code": "EC",
        "name":"Equipment Change",
        "enable":0
        },
        {
        "code": "BB",
        "name":"Baggage Belt",
        "enable":0
        },
        {
        "code": "ASC",
        "name":"Air Segment cancellation",
        "enable":0
        },
        {
        "code": "FC",
        "name":"Flight Cancellation",
        "enable":0
        }]
    dispatch({ type: 'enableUpdate', payload: true })
    dispatch({ type: 'company_id', payload: obj.comapny_id })
    dispatch({ type: 'company_name', payload: obj.company_name })
    dispatch({ type: 'assigne_val', payload: [{label:obj.company_name, value:obj.company_id}]})

    if(obj.noti_type.length!==0){
       for(let noti of obj.noti_type){
        for(let notification of list){
            if(noti.code===notification.code){
                notification.enable=1;
                break;
            }
        }
       }
       setNotiTypes(list)
    }
  }

  const handleClose=()=>{
    dispatch({ type: 'enableDelete', payload: false })
  }


  const deleteAccessNotification=()=>{
    deleteNotifications({company_id:state.company_id}).then(response => {
        if(response.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Notification Deleted Successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        setTimeout(function () {
            return window.location = window.location.pathname;
          }, 2000);
        }
    })
}

const handleDelete=(id)=>{
    dispatch({ type: 'enableDelete', payload: true })
    dispatch({ type: 'company_id', payload: id })
    }

const cancelNotifi=()=>{
    return window.location = window.location.pathname;
}

const handleChangeActive = (id) => (e) => {
    let active=1;
    if(e.target.value==="true"){
        active=0;
    }
    activeNotifications({company_id:id,is_active:active}).then(response => {
        if(response.suc){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Notification Status updated Successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        setTimeout(function () {
            return window.location = window.location.pathname;
          }, 2000);
        }
    })
}

    return (
        <>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
                                      {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
             <Form.Row>
              <Form.Group className="selectplugin  m-0" as={Col} xs={3} controlId="firstName">
              <Select
                  options={companyList}
                  searchable={false}
                  placeholder="Select Company"
                  value={state.assigne_val}
                  isDisabled={state.enableUpdate}
                  onChange={(value) => (setAsigneType(value))}
              />
               <Form.Label>Assignee</Form.Label>
            </Form.Group>
            </Form.Row>
            <Form.Row className="mt-3">
            {notiTypes.length!==0 && notiTypes.map((notification, idx) => (
                <React.Fragment key={idx}>
            <Col xs={6} className="mb-3">
             <div className='row custom-control custom-switch'>
              <Form.Group controlId="email" as={Col} xs={12} className="d-flex align-items-center m-0">
              <Form.Check label={notification.name} className="p-0" custom
              checked={notification.enable}
              type="checkbox"
              value={notification.enable===1?true:false}
              onChange={handleChangeNotification(idx)} id={idx+1} />
              </Form.Group>

                        </div>
                    </Col>
                </React.Fragment>
            ))}
            </Form.Row>
            <div className="switchPlus buttonGrop">
                  <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={cancelNotifi}
                >Cancel</Button>
                {!state.enableUpdate &&
                <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                onClick={saveNotifications("Save")}
                >Save</Button>
                }
               {state.enableUpdate &&
                  <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                onClick={saveNotifications("Update")}
                >Update</Button>
               }
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th width="15%">Company Name</th>
                        <th>Services Enabled</th>
                        <th  width="10%">Action</th>
                    </tr>
                </thead>
                <tbody>
                {notificationList.map((obj,index)=>(
                 <React.Fragment key={index}>
                 <tr>
                 <td>{obj.company_name}</td>
                 <td>
                 {obj.noti_type.map((noti,idx)=>(
                <React.Fragment key={noti}>
                     <span className="pr-2">{noti.name}, </span>
                </React.Fragment>
                 ))}
                </td>
                 <td>
                   <div className="d-flex justify-content-between">
                     <Button
                         size="xs"
                         onClick={()=>handleEdit(obj)}
                         className="ezyIcon icon-edit"
                         variant="link">
                     </Button>
                     <Button
                         size="xs"
                         onClick={()=>handleDelete(obj.comapny_id)}
                         className="ezyIcon icon-delete"
                         variant="link">
                     </Button>
                     <Form.Row>
                     <div className='custom-switch'>
                     <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
                        <Form.Check  className="p-0"
                        checked={obj.is_active===1?true:false}
                        type="checkbox"
                        label="."
                        value={obj.is_active===1?true:false}
                        onChange={handleChangeActive(obj.comapny_id)}
                        id={index + 1 +"-"+obj.comapny_id}
                        custom
                         />
                        </Form.Group>
                        </div>
                        </Form.Row>
                  </div>
                 </td>
             </tr>
             </React.Fragment>
                 ))

                }

                </tbody>
            </Table>

             <div>
             {state.enableDelete &&
             <Modal
               show={state.enableDelete}
               onHide={handleClose}
               onClick={handleClose}
               className="importPNRMdl requiredFare"
               backdrop="static"
               keyboard={false}
             >
               <Modal.Header>
                 <div className="popUplayout center">
                   <div className="icons warning"></div>
                   <h4 className="mt-3 mb-3">Are you sure?</h4>
                   <div className="message">
                     You want to Delete the selected Notification?
                   </div>
                 </div>
               </Modal.Header>
               <Modal.Footer className="justify-content-center">
                 <Button
                   size="xs"
                   variant="outline-primary"
                   onClick={()=>handleClose}
                   type="submit"
                 >No</Button>
                 <Button
                   size="xs"
                   variant="primary"
                   onClick={() => deleteAccessNotification()}
                   type="submit"
                 >Yes</Button>

               </Modal.Footer>
             </Modal>
           }
             </div>

        </>


    )
}
export default NotificationAccess;