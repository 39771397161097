
import React, {useEffect,useState } from 'react';
import { Alert, Col, Form, Row, } from "react-bootstrap";
import Select from 'react-select';

/**
 * @description:This function will creaet all the Add Baggage details
 * @author: Pravas
 * @param {*}
 * @function Baggagr details
 * @date : 30-06-2021
 */

function AddBaggage(props) {
    const [bagDefault, setBagDefault] = useState([]);
    const [bagOptions, setBagOptions] = useState([]);

    useEffect(() => {
        if(props.bagList!==undefined){
          if(props.bagList.includes("Select")){
            setBagDefault([])
          }else{
            if(props.bagList.length!==0 && props.bagList[0]!==undefined &&props.bagList[0].value!==undefined){
            setBagOptions(props.bagList)
            setBagDefault({"value":props.bagList[0].value,"label":props.bagList[0].label})
            props.sendBagprice(props.bagList[0])
            }else{
                for (let data of props.bagList) {
                    setBagOptions(data)
                    setBagDefault({"value":data[0].value,"label":data[0].label})
                    }
            }

          }
        }
        }, []);

    const setBaggagePrice=(obj)=>{
     props.sendBagprice(obj)
     if(!props.bagList.includes("Select")){
     setBagDefault({"value":obj.value,"label":obj.label})
     }
    }


    return (
        <>
            <div className="extraListLayout">
                <Row>
                    <Col className="d-flex">
                        <div className="extraIcon ezyIcon icon-tBaggage">
                        </div>
                        <Col xs={6}>
                            <Form.Row>
                                <Form.Group as={Col} xs="8" className="selectplugin" controlId="cn">
                                <Select
                                        isDisabled={props.disable}
                                        options={bagOptions}
                                        id="class"
                                        value={[bagDefault]}
                                        onChange={(value) => (setBaggagePrice(value))}
                                        className='react-select-container-extra addBaggage'
                                        classNamePrefix="react-select-extra"
                                        getOptionValue={(option) => option.code}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                              ...theme.colors,
                                              primary25: '#F0F0F0',
                                              primary: '#475F7B',
                                            },
                                          })}
                                    />
                                    <Form.Label>Select Baggage</Form.Label>
                                </Form.Group>
                            </Form.Row>
                        </Col>
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default AddBaggage;
