/**
  * @description: Flight Summary page Fare Fare details component
  * @date:19-09-2020
  * @author:Rambabu
  *
  */
 import React, { useReducer, useRef, useEffect } from 'react';
 import { Col, Row, Table } from "react-bootstrap";
 import { useHistory } from 'react-router-dom';
 import Utilities from '../../commonUtils/Utilities';
 import FormateCurrency from '../../commonUtils/FormateCurrency';

 /**
  * Initial State Declaration
  */
 const initialState = {
     user_cur: ''
 };

 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };


 /**
  * @description: This function will show the FareDetails
  * @date:19-09-2020
  * @author:Rambabu
  *
  */
 function Faredetails(props) {
     const [state, dispatch] = useReducer(reducer, initialState);
     const history = useHistory();

    //Calculating the Total Purchase Amount
     const totalPurchase = (data,type) => {
         let total=0;
         if(data!==undefined && data.pax!==undefined){
          for(let val of data.pax){
              if(total===0){
                total=Utilities.addPrice(val.purchase.total,0, "Add");
              }else{
                total=Utilities.addPrice(total, val.purchase.total, "Add");
              }
          }
        }
        return total;
     }
     //Calculating the Total Sales Amount
     const totalSales = (data) => {
        let total=0;
        if(data!==undefined && data.pax!==undefined){
         for(let val of data.pax){
             if(total===0){
               total=Utilities.addPrice(val.sales.total,0, "Add");
             }else{
               total=Utilities.addPrice(total, val.sales.total, "Add");
             }
         }
       }
       return total;
    }

     return (
         <>
             <Row>
                 <Col className="fareTableLayout">
                     <Table className="fareTable noHover">
                   <thead>
                             <tr>
                                 <th>Traveller</th>
                                 <th>Type</th>
                                 <th className="purcTitle">
                                     <h6 className="title"><span>Invoice</span></h6>
                                     {props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Base Fare":"Fare Diffrence"}</th>
                                 <th>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Taxes":"Tax Diffrence"}</th>
                                 {props.type!=="MTA" &&
                                  <>
                                  <th>Supplier Fee</th>
                                  <th>Discount</th>
                                  </>
                                 }
                                  {props.type==="TA" && Number(props.supplier) === 0 &&
                                  <>
                                   <th>PLB</th>
                                  </>
                                   }
                                   {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                   <th>Penalty</th>
                                    }
                                    {Number(props.supplier) !== 0 ? (
                                    <>
                                    <th>Baggage</th>
                                    <th>Meals</th>
                                    </>
                                    ):('')}
                                    {Number(props.supplier) !== 0 &&
                                    <>
                                    <th>Seat</th>
                                    </>
                                    }
                                 <th className="text-right">Payable</th>
                             </tr>
                         </thead>
                         <tbody>
                         <tr>
                            <td colSpan="8" className="space"> </td>
                            </tr>

                             {props.fareData !== undefined && props.fareData.pax.map((pr, idx) => (
                                 <tr>
                                     <td>
                                         <span className="textOverFlow150" title={pr.paxname.toUpperCase()}>{pr.paxname.toUpperCase()}</span>
                                     </td>
                                     <td>
                                         {pr.ptype === "ADT" &&
                                             <span>Adult</span>
                                         }
                                         {pr.ptype === "CHD" &&
                                             <span>Child</span>
                                         }
                                         {pr.ptype === "INF" &&
                                             <span>Infant</span>
                                         }
                                     </td>

                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.purchase.basefare} />}</span>

                                    </td>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                    </td>
                                    {props.type!=="MTA" &&
                                    <>
                                    <td className="tdborder">
                                    <span> {<FormateCurrency value={pr.purchase.supcharge} />}</span>
                                    </td>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.purchase.disc} />}</span>
                                    </td>
                                    {props.type==="TA" && Number(props.supplier) === 0 &&
                                    <td className="tdborder text-right">
                                    <span>{<FormateCurrency value={pr.purchase.plb} />}</span>
                                    </td>
                                    }
                                   </>
                                    }
                                     {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                      <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.penalty} />}</span>
                                     </td>
                                     }
                                     {/* {props.supplier && Number(props.supplier) === 1 ? (
                                    <>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.baggage_price} />}</span>
                                     </td>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.meal_price} />}</span>
                                     </td>
                                    </>) :('')} */}

                                    {Number(props.supplier) !== 0 ? (
                                    <>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.baggage_price} />}</span>
                                     </td>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.meal_price} />}</span>
                                     </td>
                                    </>) :('')
                                    }
                                    {Number(props.supplier) !== 0 ? (
                                    <>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.purchase.seat_price} />}</span>
                                     </td>
                                    </>) :('')
                                    }

                                    <td className="tdborder text-right">
                                    <span>{<FormateCurrency value={Utilities.addPrice(pr.purchase.total, 0, "Add")} />}</span>
                                    </td>

                                 </tr>
                             ))}
                             <tr className="alltotal">
                                 <td colSpan="2"></td>
                                     <td colSpan="9" className="text-right tdborder"><strong>Total Purchases :
                                          {<FormateCurrency value={ totalPurchase(props.fareData)} />}
                                     </strong></td>
                             </tr>
                         </tbody>
                     </Table>
                     <Table className="fareTable noHover">
                        <thead>
                            <tr>
                            <th className="purcTitle">
                                     <h6 className="title"><span>Sales</span></h6>

                                {props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Base Fare":"Fare Diffrence"}</th>
                            <th>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Taxes":"Tax Diffrence"}</th>
                                <th>Markup</th>
                                <th>Service Fee</th>
                                {props.type!=="MTA" &&
                                <>
                                <th>Supplier Fee</th>
                                </>
                                }
                                {props.bk_region_type!==undefined && props.bk_region_type==="India" &&
                                  <th>GST</th>
                                }

                                {props.type==="MTA" && props.bk_region_type!==undefined && props.bk_region_type==="India" &&
                                  <th>TDS</th>
                                }

                                <th>Discount</th>
                                {props.type==="MTA" && Number(props.supplier) === 0 &&
                                    <>
                                 <th>PLB</th>
                                    </>
                                }
                                {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                 <th>Penalty</th>
                                }
                                {Number(props.supplier) !== 0 ? (
                                    <>
                                    <th>Baggage</th>
                                    <th>Meals</th>
                                    </>
                                ):''}
                                {Number(props.supplier) !== 0 ? (
                                    <>
                                    <th>Seat</th>
                                    </>
                                ):''}
                                <th className="text-right">Receivable</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr><td colSpan="7" className="space">


                            </td></tr>
                            {props.fareData !== undefined && props.fareData.pax.map((pr, idx) => (
                                <tr>
                                     <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.sales.basefare} />}</span>
                                    </td>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                    </td>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.sales.markup} />}</span>
                                    </td>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.sales.servicefee} />}</span>
                                    </td>

                                    {props.type!=="MTA" &&
                                     <>
                                     <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.sales.supcharge} />}</span>
                                    </td>
                                    </>
                                    }
                                    {props.bk_region_type!==undefined && props.bk_region_type==="India" &&
                                    <td className="tdborder">
                                    <span> {<FormateCurrency value={pr.sales.gst} />}</span>
                                    </td>
                                    }
                                    {props.type==="MTA" && props.bk_region_type!==undefined && props.bk_region_type==="India" && pr.sales.tds_amt!==undefined &&
                                    <td className="tdborder">
                                    <span> {<FormateCurrency value={pr.sales.tds_amt} />}</span>
                                    </td>
                                    }
                                    <td className="tdborder">
                                    <span> {<FormateCurrency value={pr.sales.disc} />}</span>
                                    </td>
                                    {props.type==="MTA" && Number(props.supplier) === 0 &&
                                    <>
                                    <td className="tdborder">
                                    <span>{<FormateCurrency value={pr.sales.plb} />}</span>
                                    </td>
                                    </>
                                    }
                                    {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.sales.penalty} />}</span>
                                     </td>
                                     }
                                      {Number(props.supplier) !== 0 ? (
                                    <>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.sales.baggage_price} />}</span>
                                     </td>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.sales.meal_price} />}</span>
                                     </td>
                                    </>) :('')}

                                    {Number(props.supplier) !==0 ? (
                                    <>
                                     <td className="tdborder text-right">
                                     <span>{<FormateCurrency value={pr.sales.seat_price} />}</span>
                                     </td>
                                    </>) :('')
                                    }
                                    <td className="tdborder text-right">
                                    <span>{<FormateCurrency value={Utilities.addPrice(pr.sales.total, 0, "Add")} />}</span>
                                    </td>
                                </tr>
                            ))}
                            <tr className="alltotal">
                               <td colSpan="11" className="text-right tdborder"><strong>Total Sales : {<FormateCurrency value={totalSales(props.fareData)} />}</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                 </Col>
             </Row>

            {props.fareData !== undefined && props.fareData.pax_split !== undefined && props.fareData.pax_split.length > 0 &&

            <>
             <h4 className="title mt-3">Split PNR Fare Details</h4>
            <Row>
                 <Col className="fareTableLayout">
                     <Table className="fareTable noHover">
                         <thead>
                             <tr>
                                 <th>Traveller</th>
                                 <th>Type</th>
                                 <th className="purcTitle">
                                     <h6 className="title">Invoice</h6>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Base Fare":"Fare Diffrence"}</th>
                                 <th>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Taxes":"Tax Diffrence"}</th>
                                 {props.type!=="MTA" &&
                                  <>
                                  <th>Supplier Fee</th>
                                  <th>Discount</th>
                                  </>
                                 }
                                  {props.type==="TA" && Number(props.supplier) === 0 &&
                                  <>
                                   <th>PLB</th>
                                  </>
                                   }
                                   {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                   <th>Penalty</th>
                                    }
                                 <th className="text-right">Payable</th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr>
                            <td colSpan="8" className="space"> <span></span></td></tr>
                             {props.fareData !== undefined && props.fareData.pax_split.map((pr, idx) => (
                                 <tr>
                                     <td>
                                         <span className="textOverFlow150" title={pr.paxname.toUpperCase()}>{pr.paxname.toUpperCase()}</span>
                                     </td>
                                     <td>
                                         {pr.ptype === "ADT" &&
                                             <span>Adult</span>
                                         }
                                         {pr.ptype === "CHD" &&
                                             <span>Child</span>
                                         }
                                         {pr.ptype === "INF" &&
                                             <span>Infant</span>
                                         }
                                     </td>

                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.purchase.basefare} />}</span>

                                    </td>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                    </td>
                                    {props.type!=="MTA" &&
                                    <>
                                    <td className="tdborder borderBtm">
                                    <span> {<FormateCurrency value={pr.purchase.supcharge} />}</span>
                                    </td>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.purchase.disc} />}</span>
                                    </td>
                                    {props.type==="TA" && Number(props.supplier) === 0 &&
                                    <td className="tdborder text-right borderBtm">
                                    <span>{<FormateCurrency value={pr.purchase.plb} />}</span>
                                    </td>
                                    }
                                   </>
                                    }
                                     {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                      <td className="tdborder text-right borderBtm">
                                     <span>{<FormateCurrency value={pr.purchase.penalty} />}</span>
                                     </td>
                                     }
                                    <td className="tdborder text-right borderBtm">
                                    <span>{<FormateCurrency value={Utilities.addPrice(pr.purchase.total, 0, "Add")} />}</span>
                                    </td>

                                 </tr>
                             ))}
                             {/* <tr className="alltotal">
                                 <td colSpan="2"></td>
                                     <td colSpan="6" className="text-right tdborder">
                                         {/* <strong>Total Purchases :
                                          {<FormateCurrency value={ totalPurchase(props.fareData)} />}
                                     </strong>
                                     </td>
                             </tr> */}

                         </tbody>
                     </Table>
                     <Table className="fareTable noHover">
                        <thead>
                            <tr>
                            <th className="purcTitle">
                                     <h6 className="title">Sales</h6>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Base Fare":"Fare Diffrence"}</th>
                            <th>{props.bookingType!=="REISSUE" && props.bookingType!=="MANUAL/REISSUE" && props.bookingType!=="IMPORT/REISSUE"?"Taxes":"Tax Diffrence"}</th>
                                <th>Markup</th>
                                <th>Service Fee</th>
                                {props.type!=="MTA" &&
                                <>
                                <th>Supplier Fee</th>
                                </>
                                }
                                <th>Discount</th>
                                {props.type==="MTA" && Number(props.supplier) === 0 &&
                                    <>
                                 <th>PLB</th>
                                    </>
                                }
                                {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                 <th>Penalty</th>
                                }
                                <th className="text-right">Receivable</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr><td colSpan="7" className="space"></td></tr>
                            {props.fareData !== undefined && props.fareData.pax_split.map((pr, idx) => (
                                <tr>
                                     <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.sales.basefare} />}</span>
                                    </td>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.taxes} />}</span>
                                    </td>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.sales.markup} />}</span>
                                    </td>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.sales.servicefee} />}</span>
                                    </td>

                                    {props.type!=="MTA" &&
                                     <>
                                     <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.sales.supcharge} />}</span>
                                    </td>
                                    </>
                                    }
                                    <td className="tdborder borderBtm">
                                    <span> {<FormateCurrency value={pr.sales.disc} />}</span>
                                    </td>
                                    {props.type==="MTA" && Number(props.supplier) === 0 &&
                                    <>
                                    <td className="tdborder borderBtm">
                                    <span>{<FormateCurrency value={pr.sales.plb} />}</span>
                                    </td>
                                    </>
                                    }
                                    {(props.bookingType==="REISSUE" || props.bookingType==="MANUAL/REISSUE" || props.bookingType==="IMPORT/REISSUE") &&
                                     <td className="tdborder text-right borderBtm">
                                     <span>{<FormateCurrency value={pr.sales.penalty} />}</span>
                                     </td>
                                     }
                                    <td className="tdborder text-right borderBtm">
                                    <span>{<FormateCurrency value={Utilities.addPrice(pr.sales.total, 0, "Add")} />}</span>
                                    </td>
                                </tr>
                            ))}
                            {/* <tr className="alltotal">
                               <td colSpan="7" className="text-right tdborder"></td>
                            </tr> */}
                        </tbody>
                    </Table>
                 </Col>
                </Row>
                </>
                }
             {
             props.payments!==null &&
             props.payments.length > 0 &&
               props.bktype !== undefined && props.bktype !== "21" && props.bktype !== "22" &&
                <Row>
                    <Col className="farepaymentInfo">

                        <h4 className="title">Payment Method</h4>
                        <div class="table-responsive">

                        <Table >
                            <tr>
                                <th>Payment Type</th>
                                <th>Card Surcharge</th>
                                <th>Amount</th>
                                <th>Status</th>
                                {props.payments.length!==0 && props.payments[0].form_of_pay!=="" &&
                                <>
                                <th>Mode of Payment</th>
                                <th>Ref Number</th>
                                <th>Remarks</th>
                                </>
                                }
                            </tr>
                            {props.payments && props.payments.map((pay, idx) => (
                                <>
                                    <tr>
                                        <td>
                                       {pay.form_of_pay!==undefined && pay.form_of_pay!=="" &&
                                        <span>{pay.form_of_pay}</span>
                                        }
                                        {pay.form_of_pay===undefined || pay.form_of_pay === "" &&
                                        <span>{pay.payment_type}</span>
                                        }
                                        </td>
                                        <td>{pay.c_sur_charge} </td>
                                        <td>{props.userCurrency} {<FormateCurrency value={pay.purchase_amount} />}</td>
                                        {pay.payment_type.toUpperCase() !== "CREDIT CARD" &&
                                            <>
                                        {props.bktype!=="34" &&
                                        <td className={pay.status === "Confirmed" ? "status paxstatus confirm" : "status paxstatus "}>{pay.status} </td>
                                        }
                                        {props.bktype==="34" &&
                                        <td className="status paxstatus">Pending</td>
                                        }
                                        </>
                            }
                             {
                                pay.payment_type.toUpperCase() == "CREDIT CARD" &&
                                <>
                                <td className={pay.status === "Confirmed" ? "status paxstatus confirm" : "status paxstatus "}>{pay.status} </td>
                                </>
                            }
                                        {pay.form_of_pay!=="" &&
                                        <>
                                        <td>{pay.paymode} </td>

                                        <td>{pay.payref} </td>

                                        <td>{pay.payment_remarks} </td>
                                        </>
                                        }

                                    </tr>
                                </>
                            ))}
                        </Table>
                        </div>

                    </Col>
                </Row>
            }
         </>
     )
 }


 export default Faredetails;
