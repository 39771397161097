import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Footer from '../../../common/Footer'
import Select from 'react-dropdown-select';
import { Context } from "../../../../../App";
import Button from '../../../common/buttons/Button';
import { getallTAlist, creation, retrievebgdlist, update } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../../common/loader/InnerLoader';
import NoRecord from '../../../common/popUps/NoRecord';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import MainMenu from '../../../common/MainMenu';
import AirServiceConfigMenu from '../../../common/subMenu/AirServiceConfigMenu';
import { mobileNumberValidation } from '../../../commonUtils/FormValidations';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import RegionType from '../../common/RegionType'
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import log from "loglevel";
import SalesChannel from "../../common/SalesChannel";
let supp_json = require('../../../masterData/Suppliers.json');


/**
 * @description:This function will creaet Extra Services configuration
 * @author: Rambabu
 * @param {*}
 * @function ExtraServices
 * @date : 02-07-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
  validated: false, enbaleUpdate: false, enableView: false, taMenu: [], isLoadingMore: false, company_id: 0, comp_name: "", selTa: [],
  nameError: false, bgMarkup: '', mealMarkup: '', seatMarkup: '', selbgaptype: [], selbgapon: [], selmealaptype: [],
  selmealapon: [], selseataptype: [], selseatapon: [], res: [], loadCategory: false, companynameError: false,regionError:false, bgmarkuperror: false, mealmarkuperror: false,region_type_edit:"",branchError:false,
  bgaptypeerror: false, bgaponerror: false, mealaptypeerror: false, mealaponerror: false, suppliers:supp_json, supNames: [], suppliererror: false, showSubmit: true, region_type:"",RegionSelected:false,branchList:[],selBranch:[],  isItineraryLoading:false,sales_chanel:"",enableDevices:false,isDeviceAll:true,isB2cWeb:false,isB2cApp:false,isMWeb:false,reset:false,
};

//Static values for applicableOn Drop down
const applicableOn = [{ label: "Percentage", value: "Percentage" },
{ label: "Value", value: "Value" }]

//Static values for applicableType Drop down
const applicableType = [{ label: "Per Pax", value: "Per Pax" },
{ label: "Per Booking", value: "Per Booking" }]

// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, selTa: [], enbaleUpdate: false, enableView: false, selbgaptype: [], selbgapon: [], selmealaptype: [],
        selmealapon: [], selseataptype: [],
        selseatapon: [], bgMarkup: 0, mealMarkup: 0, seatMarkup: 0, suppliers:[],region_type:"",RegionSelected:false
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will be used for to create Baggae and MEal Markup Creation
function ExtraServices(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    dispatch({ type: 'isLoadingMore', payload: true });
    dispatch({ type: 'enbaleUpdate', payload: false })

    // //Load All Ta List for DropDown
    // getallTAlist().then((res) => {
    //   dispatch({ type: 'taMenu', payload: res })
    // });

    //Get All baggage and Meal markup list
    const payload = {
      "userid": context.logindata.uid,
      "is_admin": context.logindata.is_admin,
      "region_type" : context.logindata.region_type
    }
    retrievebgdlist(payload).then(response => {
      if (response.suc) {
        constructingDatatablejson(response.res);
      }
    })
    dispatch({ type: 'isLoadingMore', payload: false });
  }, [context.logindata]);
  
  useEffect(() => {
    if(context.logindata!==undefined && state.sales_chanel === "B2C" && state.region_type){
    let is_admin=context.logindata.utype==="att_user"?1:0
     let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type}
      AttCommonSerivce.att_companies(pyload).then(response => {
        if (response.data.suc && response.data.res.status === 200) {
          dispatch({ type: "taMenu", payload: response.data.res.data });

        }
      })
    }else if(state.sales_chanel === "B2B" && state.selBranch.length === 0){
      dispatch({ type: "taMenu", payload: []});
    }
  }, [context.logindata,state.sales_chanel,state.region_type]);
  const loginUserData = localStorage.getItem('userAuthData');
  let user_type = JSON.parse(loginUserData).utype;
  const getSupplierName=(code)=>{
    if(code!==undefined && code!==null){
    if(!code.includes(",")){
        if(code==="0"){
        return "Amadeus";
        }
        if(code==="1"){
        return "Travel Fusion";
        }else if(code==="2"){
        return "Air Arabia"
        }else if(code==="3"){
        return "Jazeera"
        }else if(code==="4"){
        return "Indigo"
        }else if(code==="5"){
        return "Flydubai"
        }else if(code==="6"){
        return "Salam Air"
        }else if(code==="7"){
        return "Riya Travel"
        }
       }else{
           let supary=code.split(",");
           var supname="";
           for(let val of supary){
            let supnames=state.suppliers.filter((obj)=>obj.value===val);
            if(supnames.length!==0){
                if(supname===""){
                    supname=supnames[0].label;
                }else{
                    supname=supname+", "+supnames[0].label;
                }
            }
           }
         return supname;
       }
}
}
  //Constructiong json for Data table Display
  const constructingDatatablejson = (response) => {
    let data = [];
    //var enableWEdit = false;
    var enableWEdit = true;
    //if (context.logindata !== "" && context.logindata.roles !== undefined) {
    /*  if (context.logindata.roles.includes('C_T_U')) {
         enableWEdit = true;
     } */
    if (response !== undefined) {
      for (let val of response) {
        if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){
        data.push({
          cname:<span className="commName" title={(val.company_name)}>{val.company_name}</span>, meal_markup: val.meal_markup,region_type:val.region_type,branch_name:val.branch_name, baggage_markup: val.baggage_markup, seat_markup: val.seat_markup,
          meal_markup_on: val.meal_appon, meal_markup_type: val.meal_apptype, bag_markup_on: val.baggage_appon, bag_markup_type: val.baggage_apptype, seat_markup_on: val.seat_appon, seat_markup_type: val.seat_apptype, supplier : getSupplierName(val.supplier),
          /* options: <><a onClick={editMarkupData(val, "view")}><Icon className="view" color="#475F7B" size={20} icon="view" /></a>
                  {enableWEdit && <a onClick={editMarkupData(val, "edit")}><Icon className="edit ml-1 mr-1" color="#475F7B" size={15} icon="edit" /></a>}
                  </> */
          options: <>
          <span className="tbAction"><a className="ezyIcon icon-view" onClick={editMarkupData(val, "view")}></a>
            {enableWEdit && <a className="ezyIcon icon-edit" onClick={editMarkupData(val, "edit")}></a>}</span>
          </>
        })}
      }
      dispatch({ type: 'res', payload: data })
    }
    //}
    dispatch({ type: 'res', payload: data })
    dispatch({ type: 'isLoadingMore', payload: false });
  }

  //Edit Markup Functionality
  const editMarkupData = (obj, type) => () => {
    dispatch({ type: 'enableView', payload: false });
    dispatch({type:"sales_chanel", payload: obj.sales_channel})
    dispatch({ type: 'region_type', payload: obj.region_type });
    dispatch({ type: 'region_type_edit', payload: obj.region_type });
    dispatch({ type: 'company_id', payload: obj.company_id });
    dispatch({ type: 'selbgaptype', payload: [{ "value": obj.baggage_apptype, "label": obj.baggage_apptype }] })
    dispatch({ type: 'selbgapon', payload: [{ "value": obj.baggage_appon, "label": obj.baggage_appon }] })
    dispatch({ type: 'selmealaptype', payload: [{ "value": obj.meal_apptype, "label": obj.meal_apptype }] })
    dispatch({ type: 'selmealapon', payload: [{ "value": obj.meal_appon, "label": obj.meal_appon }] })
    dispatch({ type: 'selseataptype', payload: [{ "value": obj.seat_apptype, "label": obj.seat_apptype }] })
    dispatch({ type: 'selseatapon', payload: [{ "value": obj.seat_appon, "label": obj.seat_appon }] })
    dispatch({ type: 'bgMarkup', payload: obj.baggage_markup })
    dispatch({ type: 'mealMarkup', payload: obj.meal_markup })
    dispatch({ type: 'seatMarkup', payload: obj.seat_markup })
    dispatch({ type: 'selTa', payload: [{ "value": obj.company_id, "label": obj.company_name }] })
    dispatch({ type: 'selBranch', payload: [{ "value": obj.branch_id, "label": obj.branch_name }] })
    if(obj.supplier !== null && obj.supplier.includes(",")){
            let sups=obj.supplier.split(",");
            var supname=[];
            for(let val of sups){
                let supnames=state.suppliers.filter((obj)=>obj.value===val);
                if(supnames.length!==0){
                    supname.push({ "value": supnames[0].value, "label": supnames[0].label });
                }
               }
               dispatch({ type: 'supNames', payload: supname });
        }else if(obj.supplier==="0"){
        dispatch({ type: 'supNames', payload: [{ "value": "0", "label": "Amadeus" }] });
        }else if(obj.supplier==="1"){
        dispatch({ type: 'supNames', payload: [{ "value": "1", "label": "Travel Fusion" }] });
        }else if(obj.supplier==="2"){
        dispatch({ type: 'supNames', payload: [{ "value": "2", "label": "Air Arabia" }] });
        }else if(obj.supplier==="3"){
        dispatch({ type: 'supNames', payload: [{ "value": "3", "label": "Jazeera" }] });
        }else if(obj.supplier==="4"){
        dispatch({ type: 'supNames', payload: [{ "value": "4", "label": "Indigo" }] });
        }else if(obj.supplier==="5"){
        dispatch({ type: 'supNames', payload: [{ "value": "5", "label": "Flydubai" }] });
        }else if(obj.supplier==="6"){
        dispatch({ type: 'supNames', payload: [{ "value": "6", "label": "Salam Air" }] });
        }else if(obj.supplier==="7"){
        dispatch({ type: 'supNames', payload: [{ "value": "7", "label": "Riya Travel" }] });
        }
        else{
          dispatch({ type: 'supNames', payload: [] });
        }
    if (type === "view") {
      dispatch({ type: 'enableView', payload: true });
      dispatch({ type: 'enbaleUpdate', payload: false });
      dispatch({ type: 'showSubmit', payload: false });
    } else if(type === "edit") {
      dispatch({ type: 'enableView', payload: false });
      dispatch({ type: 'enbaleUpdate', payload: true });
      dispatch({ type: 'showSubmit', payload: false });
    }
  }

  //When we select compnay drop down below one will be executed
  const setTypeValue = (data) => {
    if (isArrayNotEmpty(data)) {
      dispatch({ type: "company_id", payload: data[0].value });
      dispatch({ type: "comp_name", payload: data[0].label });
      dispatch({ type: "selTa", payload: data });
    }
    else{
      dispatch({ type: "company_id", payload: 0 });
      dispatch({ type: "comp_name", payload: "" });
      dispatch({ type: "selTa", payload: [] });
    }
  }




  // When we enter value in baggage markup and meal markup below one will be executed
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == 'bgMarkup') {
      if (isArrayNotEmpty(state.selbgapon) && state.selbgapon[0].value == "Percentage") {
        if(value <= 100){
      dispatch({ type: 'bgMarkup', payload: value });
    }
}
      else{
        dispatch({ type: 'bgMarkup', payload: value });
      }
  }
    if (id == 'mealMarkup') {
      if (isArrayNotEmpty(state.selmealapon) && state.selmealapon[0].value == "Percentage") {
        if(value <= 100){
          dispatch({ type: 'mealMarkup', payload: value });
        }
      }
      else{
        dispatch({ type: 'mealMarkup', payload: value });
      }
      }
    if (id == 'seatMarkup') {
      if (isArrayNotEmpty(state.selseatapon) && state.selseatapon[0].value == "Percentage") {
        if(value <= 100){
          dispatch({ type: 'seatMarkup', payload: value });
        }
      }
      else{
        dispatch({ type: 'seatMarkup', payload: value });
      }
      }
  }


  //Handle cancel Event when we click on Reset button
  const handleCancel = (e) => {
    state.sales_chanel=""
    dispatch({ type: 'reset', payload:true })
    dispatch({ type: 'selBranch', payload: [] })
    dispatch({ type: 'selTa', payload: [] })
    dispatch({ type: 'enbaleUpdate', payload: false });
    dispatch({ type: 'enableView', payload: false });
    dispatch({ type: 'selbgaptype', payload: [] })
    dispatch({ type: 'selbgapon', payload: [] })
    dispatch({ type: 'selmealaptype', payload: [] })
    dispatch({ type: 'selmealapon', payload: [] })
    dispatch({ type: 'selseataptype', payload: [] })
    dispatch({ type: 'selseatapon', payload: [] })
    dispatch({ type: 'supNames', payload: [] })
    dispatch({ type: 'bgMarkup', payload: "" })
    dispatch({ type: 'mealMarkup', payload: "" })
    dispatch({ type: 'seatMarkup', payload: "" })
    dispatch({ type: 'showSubmit', payload: true })
    dispatch({ type: 'saleChanelType', payload: [] })
    dispatch({ type: 'region_type', payload: [] })    
    dispatch({ type: 'RegionSelected', payload: false })    
    dispatch({ type: "sales_chanel", payload: "" });  
  }

  const handleRegionType = (e) => {
    dispatch({ type: "reset", payload: false });
    dispatch({ type: "branchList", payload: e });
    dispatch({type:"RegionSelected", payload: true })
    dispatch({ type: 'selBranch', payload: [] });
    dispatch({ type: 'selTa', payload: [] });
  }

  const updateRegion=(region)=>{
    dispatch({ type: "reset", payload: false });
    dispatch({ type: "region_type", payload: region });
  }

  //Constructing columns for data table display

  const columns = [{

    dataField: 'cname',
    text: 'Company Name'
    //sort: true,
    //filter: textFilter()
  },
  { dataField: 'region_type',
    text: 'Region Type',
    sort: true,
    filter: textFilter() },
    { dataField: 'branch_name',
    text: 'Branch Name',
    sort: true,
    filter: textFilter() },
  {
    dataField: 'meal_markup',
    text: 'Meal Markup'
    // sort: true,
    //filter: textFilter()
  },
  {
    dataField: 'baggage_markup',
    text: 'Baggage Markup'
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'seat_markup',
    text: 'Seat Markup'
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'supplier',
    text: 'Supplier'
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'meal_markup_on',
    text: 'Meal App On',
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'meal_markup_type',
    text: 'Meal App Type'
    //sort: true,
    //filter: textFilter()
  },
  {
    dataField: 'bag_markup_on',
    text: 'Baggage App On'
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'bag_markup_type',
    text: 'Baggage App Type'
    //sort: true,
    //filter: textFilter()
  },
  {
    dataField: 'seat_markup_on',
    text: 'Seat App On'
    //sort: true,
    // filter: textFilter()
  },
  {
    dataField: 'seat_markup_type',
    text: 'Seat App Type'
    //sort: true,
    //filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  //Default sorting order is descending order
  const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
  }];

  //validate markup availability
  const validatemarkupavailability = () => {
    let availCompany = state.res.filter((obj) => obj.cname === state.comp_name);
    if (availCompany.length !== 0) {
      return false
    } else {
      return true;
    }
  }

  //Hide the message after 5 sec
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }


  //Create Markup
  const handleCreationbaggagemkp = () => {
    dispatch({ type: 'loadCategory', payload: true });
    dispatch({ type: "companynameError", payload: false })
    dispatch({ type: "regionError", payload: false })
    dispatch({ type: "bgaptypeerror", payload: false })
    dispatch({ type: "bgaponerror", payload: false })
    dispatch({ type: "bgmarkuperror", payload: false })
    dispatch({ type: "mealaptypeerror", payload: false })
    dispatch({ type: "mealaponerror", payload: false })
    dispatch({ type: "mealmarkuperror", payload: false })
    dispatch({ type: "suppliererror", payload: false })
    dispatch({ type: "branchError", payload: false })
    if (isArrayNotEmpty(state.selbgaptype) && isArrayNotEmpty(state.selbgapon) &&
      isArrayNotEmpty(state.selmealaptype) && isArrayNotEmpty(state.selmealapon) &&
      isArrayNotEmpty(state.selTa) && isArrayNotEmpty(state.supNames) &&(state.enableDevices || (state.selBranch))
      ) {
      if (mobileNumberValidation(state.bgMarkup) && mobileNumberValidation(state.mealMarkup) && state.supNames.length !== 0) {
        if (validatemarkupavailability()) {

          let finalSup = [];
            state.supNames.map((obj) => {
              finalSup.push(obj.value);
              });
              dispatch({ type: "isItineraryLoading", payload: true });
          const payload = {
            "cid": state.company_id,
            "sales_channel": state.sales_chanel,
            "cname": state.comp_name,
            "sup": finalSup.toString(),
            "crby": context.logindata.uid,
            "branch_id":state.selBranch.length!==0?state.selBranch[0].value:"",
            "region_type" : state.region_type,
            "bagm": { "apty": state.selbgaptype[0].value, "apon": state.selbgapon[0].value, "value": state.bgMarkup },
            "melm": { "apty": state.selmealaptype[0].value, "apon": state.selmealapon[0].value, "value": state.mealMarkup },
            "seatm": {"apty": isArrayNotEmpty(state.selseataptype) && state.selseataptype[0].value,"apon": isArrayNotEmpty(state.selseatapon) && state.selseatapon[0].value,"value": state.seatMarkup},
            "isDeviceAll" : state.isDeviceAll,
            "isB2cWeb" : state.isB2cWeb,
            "isB2cApp" : state.isB2cApp,
            "isMWeb" : state.isMWeb
          }

          //Api call
          creation(payload).then((addRes) => {
            if (addRes.suc) {
              dispatch({ type: 'selTa', payload: [] })
              dispatch({ type: 'selbgaptype', payload: [] })
              dispatch({ type: 'selbgapon', payload: [] })
              dispatch({ type: 'selmealaptype', payload: [] })
              dispatch({ type: 'selseataptype', payload: [] })
              dispatch({ type: 'selmealapon', payload: [] })
              dispatch({ type: 'selseatapon', payload: [] })
              dispatch({ type: 'bgMarkup', payload: "" })
              dispatch({ type: 'mealMarkup', payload: "" })
              dispatch({ type: 'seatMarkup', payload: "" })
              dispatch({ type: 'supNames', payload: [] })
              dispatch({ type: 'notiMessageShow', payload: true });
              dispatch({ type: 'loadCategory', payload: false });
              dispatch({ type: 'notiMessage', payload: 'Markup saved successfully' });
              dispatch({ type: 'notiVarient', payload: 'success' });
              dispatch({ type: 'showSubmit', payload: true });
              dispatch({type:"region_type",payload: ""})
              dispatch({type:"RegionSelected", payload: false })
              hidemessage()
              window.location="/ezytrip/administration/extraservices";
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });

              dispatch({ type: 'notiMessageShow', payload: true });
              dispatch({ type: 'notiMessage', payload: 'Markup not saved..' });
              dispatch({ type: 'notiVarient', payload: 'danger' });
              dispatch({ type: 'loadCategory', payload: false });
              hidemessage()
            }
          });
        } else {
          dispatch({ type: 'notiMessageShow', payload: true });
          dispatch({ type: 'notiMessage', payload: 'Already created Markup with ' + state.comp_name });
          dispatch({ type: 'notiVarient', payload: 'danger' });
          dispatch({ type: 'loadCategory', payload: false });
          hidemessage()
        }
      } else {
        if (!mobileNumberValidation(state.bgMarkup)) {
          dispatch({ type: "bgmarkuperror", payload: true })
        }
        if (!mobileNumberValidation(state.mealMarkup)) {
          dispatch({ type: "mealmarkuperror", payload: true })
        }
        if (state.supNames.length == 0) {
          dispatch({ type: "suppliererror", payload: true })
        }
        dispatch({ type: 'loadCategory', payload: false });
      }
    } else {

      if(state.region_type===""){
        dispatch({ type: "regionError", payload: true })
      }
      if (!isArrayNotEmpty(state.selTa)) {
        dispatch({ type: "companynameError", payload: true })
      } if (!isArrayNotEmpty(state.selbgaptype)) {
        dispatch({ type: "bgaptypeerror", payload: true })
      } if (!isArrayNotEmpty(state.selbgapon)) {
        dispatch({ type: "bgaponerror", payload: true })
      } if (!isNotNull(state.bgMarkup) || state.bgMarkup == '0' || !mobileNumberValidation(state.bgMarkup)) {
        dispatch({ type: "bgmarkuperror", payload: true })
      }
      if (!isArrayNotEmpty(state.selmealaptype)) {
        dispatch({ type: "mealaptypeerror", payload: true })
      } if (!isArrayNotEmpty(state.selmealapon)) {
        dispatch({ type: "mealaponerror", payload: true })
      }
      if (!isArrayNotEmpty(state.supNames)) {
        dispatch({ type: "suppliererror", payload: true })
      }
       if (!isNotNull(state.mealMarkup) || state.mealMarkup == '0' || !mobileNumberValidation(state.mealMarkup)) {
        dispatch({ type: "mealmarkuperror", payload: true })
      }
      if(state.selBranch.length===0){
        dispatch({ type: "branchError", payload: true })
      }
      dispatch({ type: 'loadCategory', payload: false });



    }

  }
  const updateSalesChanel=(salesChanel)=>{
    dispatch({ type: "reset", payload: false });
    dispatch({ type: "sales_chanel", payload: salesChanel });
    if(salesChanel==="B2C"){
      dispatch({ type: "enableDevices", payload: true });
    }else{
      dispatch({ type: "enableDevices", payload: false });
    }
    //dispatch({ type: 'officeIds', payload: [] })
  }
  const handleDeviceTypeChange = (e) => {
    if (e.target.id === "deviceAll"){
        dispatch({ type: 'isB2cWeb', payload: false })
        dispatch({ type: 'isB2cApp', payload: false })
        dispatch({ type: 'isMWeb', payload: false })
      if(state.isDeviceAll){
        dispatch({ type: 'isDeviceAll', payload: false })
      }else{
        dispatch({ type: 'isDeviceAll', payload: true })
      }
    }else if(e.target.id === "web"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.web){
        dispatch({ type: 'isB2cWeb', payload: false })
      }else{
        dispatch({ type: 'isB2cWeb', payload: true })
        if(state.isB2cApp && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "app"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.app){
        dispatch({ type: 'isB2cApp', payload: false })
      }else{
        dispatch({ type: 'isB2cApp', payload: true })
        if(state.isB2cWeb && state.isMWeb){
          updateStateVal();
        }
      }
    }else if(e.target.id === "mweb"){
      dispatch({ type: 'isDeviceAll', payload: false })
      if(state.mweb){
        dispatch({ type: 'isMWeb', payload: false })
      }else{
        dispatch({ type: 'isMWeb', payload: true })
        if(state.isB2cWeb && state.isB2cApp){
          updateStateVal();
        }
      }
    }
  }
  const updateStateVal=()=>{
    dispatch({ type: 'isDeviceAll', payload: true })
    dispatch({ type: 'isB2cWeb', payload: false })
    dispatch({ type: 'isB2cApp', payload: false })
    dispatch({ type: 'isMWeb', payload: false })
  }
  //Update MArkup configuration Data
  const updateMarkupconfData = (e) => {
    dispatch({ type: 'loadCategory', payload: true });
    dispatch({ type: "companynameError", payload: false })
    dispatch({ type: "regionError", payload: false })
    dispatch({ type: "bgaptypeerror", payload: false })
    dispatch({ type: "bgaponerror", payload: false })
    dispatch({ type: "bgmarkuperror", payload: false })
    dispatch({ type: "mealaptypeerror", payload: false })
    dispatch({ type: "mealaponerror", payload: false })
    dispatch({ type: "mealmarkuperror", payload: false })
    dispatch({ type: "suppliererror", payload: false })
    if (isArrayNotEmpty(state.selbgaptype) && isArrayNotEmpty(state.selbgapon) &&
      isArrayNotEmpty(state.selmealaptype) && isArrayNotEmpty(state.selmealapon) &&
      isArrayNotEmpty(state.selTa) && isArrayNotEmpty(state.supNames)){
    if (mobileNumberValidation(state.bgMarkup) && mobileNumberValidation(state.mealMarkup) && state.supNames.length !== 0 && state.bgMarkup !== "0" && state.bgMarkup !== "" && state.mealMarkup !== "0" && state.mealMarkup !== "" && state.supNames.length !== 0) {
      let finalSup = [];
            state.supNames.map((obj) => {
              finalSup.push(obj.value);
              });
              dispatch({ type: "isItineraryLoading", payload: true });

      const payload = {
        "cid": state.company_id,
        "cname": state.comp_name,
        "sup": finalSup.toString(),
        "upby": context.logindata.uid,
        "branch_id":state.selBranch[0].value,
        "region_type" : state.region_type,
        "bagm": { "apty": state.selbgaptype[0].value, "apon": state.selbgapon[0].value, "value": state.bgMarkup },
        "melm": { "apty": state.selmealaptype[0].value, "apon": state.selmealapon[0].value, "value": state.mealMarkup },
        "seatm": {"apty": isArrayNotEmpty(state.selseataptype) && state.selseataptype[0].value,"apon": isArrayNotEmpty(state.selseatapon) && state.selseatapon[0].value,"value": state.seatMarkup}
      }

      //Update API Call
      update(payload).then((addRes) => {
        if (addRes.suc) {
          dispatch({ type: 'selTa', payload: [] })
          dispatch({ type: 'selbgaptype', payload: [] })
          dispatch({ type: 'selbgapon', payload: [] })
          dispatch({ type: 'selmealaptype', payload: [] })
          dispatch({ type: 'selmealapon', payload: [] })
          dispatch({ type: 'selseataptype', payload: [] })
          dispatch({ type: 'selseatapon', payload: [] })
          dispatch({ type: 'supNames', payload: [] })
          dispatch({ type: 'bgMarkup', payload: "" })
          dispatch({ type: 'mealMarkup', payload: "" })
          dispatch({ type: 'seatMarkup', payload: "" })
          dispatch({ type: 'notiMessageShow', payload: true });
          dispatch({ type: 'loadCategory', payload: false });
          dispatch({ type: 'notiMessage', payload: 'Markup Updated successfully' });
          dispatch({ type: 'notiVarient', payload: 'success' });
          dispatch({ type: 'enbaleUpdate', payload: false });
          dispatch({ type: 'showSubmit', payload: true });
          hidemessage()
          window.location="/ezytrip/administration/extraservices"
        } else {
          dispatch({ type: "isItineraryLoading", payload: false });

          dispatch({ type: 'notiMessageShow', payload: true });
          dispatch({ type: 'notiMessage', payload: 'Markup not updated..' });
          dispatch({ type: 'notiVarient', payload: 'danger' });
          dispatch({ type: 'loadCategory', payload: false });
          dispatch({ type: 'showSubmit', payload: true });
          hidemessage()
        }
      });

    } else {

      if (!isNotNull(state.bgMarkup) || state.bgMarkup == '0' || !mobileNumberValidation(state.bgMarkup)) {
        dispatch({ type: "bgmarkuperror", payload: true })
      }
      if (!isNotNull(state.mealMarkup) || state.mealMarkup == '0' || !mobileNumberValidation(state.mealMarkup)) {
        dispatch({ type: "mealmarkuperror", payload: true })
      }
      if (state.supNames.length == 0) {
        dispatch({ type: "suppliererror", payload: true })
      }
      dispatch({ type: 'loadCategory', payload: false });
      hidemessage()

    }
  }
  else{
    if(state.region_type===""){
      dispatch({ type: "regionError", payload: true })
    }    if (!isArrayNotEmpty(state.selTa)) {
      dispatch({ type: "companynameError", payload: true })
    } if (!isArrayNotEmpty(state.selbgaptype)) {
      dispatch({ type: "bgaptypeerror", payload: true })
    } if (!isArrayNotEmpty(state.selbgapon)) {
      dispatch({ type: "bgaponerror", payload: true })
    } if (!isNotNull(state.bgMarkup) || state.bgMarkup == '0' || !mobileNumberValidation(state.bgMarkup)) {
      dispatch({ type: "bgmarkuperror", payload: true })
    }
    if (!isArrayNotEmpty(state.selmealaptype)) {
      dispatch({ type: "mealaptypeerror", payload: true })
    } if (!isArrayNotEmpty(state.selmealapon)) {
      dispatch({ type: "mealaponerror", payload: true })
    }
    if (!isArrayNotEmpty(state.supNames)) {
      dispatch({ type: "suppliererror", payload: true })
    }
     if (!isNotNull(state.mealMarkup) || state.mealMarkup == '0' || !mobileNumberValidation(state.mealMarkup)) {
      dispatch({ type: "mealmarkuperror", payload: true })
    }
    dispatch({ type: 'loadCategory', payload: false });

  }
  }

  const handleBranchChange = (e)=> {
    if(e!==undefined && e.length!==0 && !state.enbaleUpdate){
        dispatch({ type: 'selBranch', payload: e});
        e && e[0] && e[0].value &&
        AttCommonSerivce.getCompanyList(e[0].value)
          .then((response) => {
            if (response.data.suc) {
              dispatch({ type: 'taMenu', payload: response.data.res });
            }
          })
          .catch(function (error) {
            log.error("Exception occured in getCompanyList function---" + error);
          });
        }
      };




  return (

    <>
      <MainMenu active='Air Services' />
      <Breadcrumb activePage="Extra Services" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <AirServiceConfigMenu active="Extra Services" />
          <div>
            <br />
            {/* Messages display Part  */}
            {state.notiMessageShow &&
              <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                dismissible> {state.notiMessage}</Alert>}
{state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}

            {/* Company id Dropdown  */}
            <Form noValidate validated={state.validated} id="extraformId">

              <Form.Row>
              <SalesChannel sendSalesChanel={updateSalesChanel} edit_sales={state.sales_chanel} reset={state.reset} />

              {/* {user_type.toUpperCase() === 'ATT_USER' || user_type.toUpperCase() === 'ATT_ADMIN' &&  */}
                <RegionType
                sendResponse={(e)=>handleRegionType(e)}
                regionUpdate={state.region_type_edit}
                disabled = {state.enabeleEdit}
                regionError = {state.regionError}
                regionType={updateRegion}
                reset={state.reset}
              />
              {/* } */}

              {!state.enableDevices && <Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                                <Select
                                options={state.branchList}
                                values={state.selBranch}
                                searchable={false}
                                disabled={state.enbaleUpdate}
                                onChange={handleBranchChange}
                                />
                                {state.branchError && (
                                 <p className="vError">Please Select Branch</p>
                                )}
                                <Form.Label>Branch<sup>*</sup></Form.Label>
                            </Form.Group>}


                  <Form.Group as={Col} xs="3" className="selectplugin" controlId="company_id">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={state.taMenu}
                    id="class"
                    searchable={true}
                    disabled={state.enableView}
                    values={state.selTa}
                    onChange={(value) => (setTypeValue(value))}
                  />
                  <Form.Label>Company<sup>*</sup></Form.Label>
                  {state.companynameError && (
                    <p className="vError">Please Select a Company</p>
                  )}
                </Form.Group>
                <Form.Group
                            as={Col}
                            xs={3}
                            className="selectplugin"
                            controlId="selectedValue"
                            >
                            <Select
                            multi
                            options={state.suppliers}
                            searchable={true}
                            values={state.supNames}
                            disabled={state.enableView}
                            onChange={(value) => (dispatch({ type: 'supNames', payload: value }))}
                            />
                            <Form.Label>Flight Supplier<sup>*</sup></Form.Label>
                            {state.suppliererror && (
                    <p className="vError">Please Select Atleast One Supplier</p>
                  )}
                            </Form.Group>
                            <Form.Group as={Col} className="inputCheck">
          <div className="d-flex align-items-center">
          {state.enableDevices &&
          <>
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="Desktop Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="M App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
            </>
          }
          </div>
        </Form.Group>
              </Form.Row>
             <p><label>Baggage Markup:</label></p>
              <Form.Row>
                <Form.Group as={Col} xs={3} controlId="selbgapon" className="selectplugin">
                  <Select
                    // disabled={state.enableView || state.enbaleUpdate}
                    options={applicableOn}
                    searchable={true}
                    values={state.selbgapon}
                    disabled={state.enableView}
                    onChange={(value) =>{
                      if(value !== state.selbgapon){
                    dispatch({ type: "selbgapon", payload: value })
                    dispatch({ type: 'bgMarkup', payload: "" })
                      }
                    }} />
                  <Form.Label>Applicable On<sup>*</sup></Form.Label>
                  {state.bgaponerror && (
                    <p className="vError">Please Select Applicable On</p>
                  )}
                </Form.Group>

                <Form.Group as={Col} xs={3} controlId="selbgaptype" className="selectplugin">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={applicableType}
                    searchable={true}
                    values={state.selbgaptype}
                    disabled={state.enableView}
                    onChange={(value) => (dispatch({ type: "selbgaptype", payload: value }))} />
                  <Form.Label>Applicable Type<sup>*</sup></Form.Label>
                  {state.bgaptypeerror && (
                    <p className="vError">Please Select Applicable Type</p>
                  )}
                </Form.Group>


                <Form.Group as={Col} xs={3} controlId="bgMarkup" className="selectplugin">
                  <Form.Control type="text" placeholder="Markup-value" autoComplete="off" disabled={state.enableView} value={state.bgMarkup} onChange={handleChange} maxlength={isArrayNotEmpty(state.selbgapon) && state.selbgapon && state.selbgapon[0].value == "Percentage" ? "3": "4"} />
                  <Form.Label>Markup-value<sup>*</sup></Form.Label>
                  {state.bgmarkuperror && (
                    <p className="vError"> Please Enter Markup Values </p>
                  )}

                </Form.Group>
              </Form.Row>



              <p><label>Meal Markup:</label></p>
              <Form.Row>
                <Form.Group as={Col} xs={3} controlId="selmealapon" className="selectplugin">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={applicableOn}
                    searchable={true}
                    disabled={state.enableView}
                    values={state.selmealapon}
                    onChange={(value)=>{
                      if(value !== state.selmealapon){
                    dispatch({ type: "selmealapon", payload: value });
                    dispatch({ type: 'mealMarkup', payload: "" })
                      }
                    }} />
                  <Form.Label>Applicable On<sup>*</sup></Form.Label>
                  {state.mealaponerror && (
                    <p className="vError">Please Select Applicable On</p>
                  )}
                </Form.Group>



                <Form.Group as={Col} xs={3} controlId="selmealaptype" className="selectplugin">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={applicableType}
                    searchable={true}
                    values={state.selmealaptype}
                    disabled={state.enableView}
                    onChange={(value) => (dispatch({ type: "selmealaptype", payload: value }))} />
                  <Form.Label>Applicable Type<sup>*</sup></Form.Label>
                  {state.mealaptypeerror && (
                    <p className="vError">Please Select Applicable Type</p>
                  )}
                </Form.Group>


                <Form.Group as={Col} xs={3} controlId="mealMarkup" className="selectplugin">
                  <Form.Control type="text" placeholder="Markup-value" autoComplete="off" disabled={state.enableView}
                    value={state.mealMarkup} onChange={handleChange} maxlength={isArrayNotEmpty(state.selmealapon) && state.selmealapon && state.selmealapon[0].value == "Percentage" ? "3": "4"}/>
                  <Form.Label>Markup-value<sup>*</sup></Form.Label>
                  {state.mealmarkuperror && (
                    <p className="vError"> Please Enter Markup Values </p>
                  )}
                </Form.Group>
              </Form.Row>
              <p><label>Seat Markup:</label></p>
              <Form.Row>
                <Form.Group as={Col} xs={3} controlId="selseatapon" className="selectplugin">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={applicableOn}
                    searchable={true}
                    disabled={state.enableView}
                    values={state.selseatapon}
                    onChange={(value)=>{
                      if(value !== state.selseatapon){
                    dispatch({ type: "selseatapon", payload: value });
                    dispatch({ type: 'seatMarkup', payload: "" })
                      }
                    }} />
                  <Form.Label>Applicable On</Form.Label>
                </Form.Group>



                <Form.Group as={Col} xs={3} controlId="selseataptype" className="selectplugin">
                  <Select
                    //disabled={state.enableView || state.enbaleUpdate}
                    options={applicableType}
                    searchable={true}
                    disabled={state.enableView}
                    values={state.selseataptype}
                    onChange={(value) => (dispatch({ type: "selseataptype", payload: value }))} />
                  <Form.Label>Applicable Type</Form.Label>
                </Form.Group>


                <Form.Group as={Col} xs={3} controlId="seatMarkup" className="selectplugin">
                  <Form.Control type="text" placeholder="Markup-value" autoComplete="off"disabled={state.enableView} value={state.seatMarkup} onChange={handleChange} maxlength={isArrayNotEmpty(state.selseatapon) && state.selseatapon && state.selseatapon[0].value == "Percentage" ? "3": "4"}/>
                  <Form.Label>Markup-value</Form.Label>
                </Form.Group>
              </Form.Row>




            </Form>

                    <div className="text-right pb-0 buttonGrop  creat_absolute">


            {/* Creation baggagemkp button  */}
              {/* {!state.enbaleUpdate && ( */}
                {state.showSubmit && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={handleCreationbaggagemkp}
                loading={state.loadCategory}
                isDisabled={state.enableView}
                type="submit"
              > Submit</Button>
            )}


            {/* Update baggagemkp button  */}
            {state.enbaleUpdate && (
              <Button
                size="xs"
                variant="outline-primary"
                onClick={updateMarkupconfData}
                loading={state.loadCategory}
                isDisabled={state.enableView}
                type="submit"
              > Update</Button>
            )}

            {/* Update handleCancel button  */}
            <Button
              size="xs"
              variant="outline-secondary"
              onClick={handleCancel}
              type="submit"
              isDisabled={state.enableView}>Reset
            </Button>
            </div>
          </div>


        </div>
                  {/* Data table Display  */}
                  <div className="cardMainlayout tableRes p-0">
            {state.isLoadingMore ? <InnerLoader /> : (<>
              {state.res.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl filterTabl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length > 10 && state.res.length <= 25 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }] }) :
                    state.res.length > 25 && state.res.length <= 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }] }) :
                      state.res.length > 30 ? paginationFactory({ sizePerPageList: [{ text: '10', value: 10 }, { text: '25', value: 25 }, { text: '30', value: 30 }, { text: '50', value: 50 }] }) : ''}
                />
                || state.res.length === 0
                && <NoRecord />}</>)}
          </div>
      </div>
<Footer/>
    </>
  )
}
export default ExtraServices