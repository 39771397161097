import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import BookingDetailsPdf from './BookingDetailsPdf';
import SegmentDetailsPdf from './SegmentDetailsPdf';
import { isArrayNotEmpty, isNotNull } from '../../../commonUtils/validators';
import ShowHide from '../../../common/showHide/ShowHide';
import logo from "../../../../assets/images/email/jpg/logoEzyTrip.jpg";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import Utilities from '../../../commonUtils/Utilities';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import PaxPdf from './PaxPdf';
import PaxFarePdf from './PaxFarePdf';

const initialState = { mtaList:[]};
// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

      default:
        return { ...state, [action.type]: action.payload };
    }
  };
const FlightInvoicePdf = React.forwardRef((props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cur, setCur] = useState(props.userCurrency)
  const [paxList, setPaxList] = useState([])
  const [fareList, setFareList] = useState([])
  const [refundList, setRefundList] = useState([])
  const [voidList, setVoidList] = useState([])
  const [onwardList, setOnwardList] = useState([])
  const [returnList, setReturnList] = useState([])
  const [refundPaxList, setRefundPaxList] = useState([])
  const [voidPaxList, setVoidPaxList] = useState([])
  const [noOfPax, setNoOfPax] = useState(props.noOfPax)
  const [triptype, setTriptype] = useState(props.response.data!=undefined && props.response.data[1].seg_det[0].trip_type)

  useEffect(() => {
    if (props.response !== undefined) {
      if (props.response.data !== undefined) {
        for (let r in props.response.data) {
          let data = props.response.data[r];
          if (isNotNull(data['pax_det'])) {
            setPaxList(data.pax_det)
          }
          if (isNotNull(data['seg_det'])) {
            let onwards = data.seg_det.filter((obj) => obj.journey_type === "O");
            //seOnwardtLayoverAirport(onwards[0].seg_destination)
            setOnwardList(onwards);
            let returns = data.seg_det.filter((obj) => obj.journey_type === "R");
            if(returns!==undefined && returns[0]!==undefined){
              setReturnList(returns);
              //setReturnLayoverAirport(returns[0].seg_destination)
            }
          }
          if (isNotNull(data['fare_det'])) {
            setFareList(data.fare_det)
          }
          if (isNotNull(data['refund_det'])) {
            setRefundList(data.refund_det)
          }
          if (isNotNull(data['void_det'])) {
            setVoidList(data.void_det)
          }
          if (isNotNull(data['refund_pax_det'])) {
            setRefundPaxList(data.refund_pax_det)
          }
          if (isNotNull(data['void_pax_det'])) {
            setVoidPaxList(data.void_pax_det)
          }
        }
      }
    }

  }, [props.response])
  const calculateTotal = (price) => {
    const total = price.reduce((prev, t) => {
        return Utilities.addPrice(prev, t.total, "Add");
    }, 0);
    return total;
  }

  return (
    <div className="bg-gray-200 p-6" title={"FLIGHT" + props.response.invoicetype} ref={ref}>
      <div className="a4-screen-sized">
      <div className="pdfbody">
            <div className="pdflayout">
            <div className="pdftitl">
            <h2 className="mb-4">
                 FLIGHT {props.response.invoicetype}
                </h2>
                </div>


               {props.bk_det !== undefined &&
               <BookingDetailsPdf bk_det={props.bk_det} invoicetype={props.response.invoicetype} company_logo={props.compLogo} parent_id={props.parent_id} selectedTab={props.selectedTab} changeStatus={props.changeStatus}/>
               }

              <div className="pdfflightDetails">
              {(paxList.length!==0 || fareList.length!==0) &&
                <div>
                <h3 className="pdfSubTitl">
                Flight Details
                {/* - Status :{"Confirmed"} */}
                </h3>
                {onwardList && onwardList[0]!==undefined &&
                <SegmentDetailsPdf  list={onwardList} supplier={props.supplier} airport_data={props.airport_data} type={"Onward"}/>
                }
                {returnList && returnList[0]!==undefined &&
                <SegmentDetailsPdf list={returnList} supplier={props.supplier} airport_data={props.airport_data} type={"Return"}/>
                }
                </div>
              }
              </div>
              {fareList.length!==0 && props.response.invoicetype !== "ITINERARY" &&
              <div className="pdffare">
               <h3 className="pdfSubTitl">
               Fare Details ({cur})
              </h3>
                 <div className="header">
                     <Row>
                     <Col xs="2">
                                 Traveller  </Col>
                         <Col xs='1'>Type</Col>
                         {props.supplier == "4" &&
                         <Col xs="1">PNR</Col>
                         }
                         {(props.supplier !== undefined && props.supplier == "0") &&
                            <Col xs="1">Ticket#</Col>
                         }

                         {props.bk_det[0].bktype!=="REISSUE" ? (
                         <Col>Base Fare</Col>
                         ):(
                         <Col >Fare Diffrence</Col>
                         )}
                         {props.bk_det[0].bktype!=="REISSUE" ? (
                         <Col >Taxes & Fee</Col>
                         ):(
                         <Col>Taxes Diffrence</Col>
                         )}
                         {props.supplier && props.supplier !== "0" && (
                          <>
                           <Col xs="1">Baggage</Col>
                           <Col xs="1">Meals</Col>
                           <Col >Seats</Col>
                          </>
                         )}
                         {/* {props.supplier && props.supplier == "2" && (
                          <>
                           <Col >Seats</Col>
                          </>
                         )} */}
                         {props.response.invoicetype==="INVOICE" &&
                         <Col xs="1">Supplier Fee</Col>
                         }
                         {props.bk_det[0].bktype==="REISSUE" &&
                         <Col xs="1">Penalty</Col>
                         }
                         {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="same" &&
                         <Col xs="1">CGST</Col>
                        }
                        {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="same"  &&
                         <Col xs="1">SGST</Col>
                        }
                        {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="other" &&
                        <Col xs="1">IGST</Col>
                       }
                        { cur==="INR" && props.response.invoicetype==="INVOICE" &&
                        <Col>TDS</Col>}
                         <Col xs="1">Discount</Col>
                         {props.response.invoicetype==="INVOICE" &&
                         <Col xs="1">PLB</Col>
                         }
                         <Col className="text-right" xs="1">Total</Col>
                     </Row>
                    </div>

                     {fareList.map((pax, index) => (
                         <React.Fragment key={index}>
                            <div className="passengerList">
                              <Row>
                                 <Col xs="2">{pax.paxname}</Col>
                                 <Col xs='1'>
                                    {pax.ptype === "ADT" &&
                                    "Adult"
                                    }
                                    {pax.ptype === "CHD" &&
                                   "Child"
                                    }
                                    {pax.ptype === "INF" &&
                                    "Infant"
                                    }
                                </Col>
                                {pax.supplier == "4" &&
                                <Col xs="1">{pax.pnr}</Col>
                                }
                                {(pax.supplier == undefined || pax.supplier == "0") && (
                                  <Col xs="1">{pax.tkt_no}</Col>
                                )}

                                 <Col > {<FormateCurrency value={pax.basefare} />}</Col>
                                 <Col > {<FormateCurrency value={pax.taxes} />}</Col>
                                 {(pax.supplier !== undefined && pax.supplier !== "0" &&
                                <>
                                <Col >{pax.baggage ? pax.baggage : "N/A"}</Col>
                                <Col >{pax.meals ? pax.meals : "N/A"}</Col>
                                <Col >{pax.seats ? pax.seats : "N/A"}</Col>
                                 </>
                                )}
                                {/* {props.supplier && props.supplier == "2" && (
                                  <>
                                  <Col >{pax.seats ? pax.seats : "N/A"}</Col>
                                  </>
                                )} */}
                                  {props.response.invoicetype=="INVOICE" &&
                                 <Col> {<FormateCurrency value={pax.supcharge} />}</Col>
                                  }
                                 {props.bk_det[0].bktype==="REISSUE" &&
                                 <Col xs="1"> {<FormateCurrency value={pax.penalty} />}</Col>
                                 }
                                {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="same" &&( props.response.invoicetype==="INVOICE"?
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[0].cgst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[0].cgst_val/noOfPax.length} />}</Col> :
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[1].cgst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[1].cgst_val/noOfPax.length} />}</Col>)
                                  }
                                {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="same" &&( props.response.invoicetype==="INVOICE"?
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[0].sgst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[0].sgst_val/noOfPax.length} />}</Col> :
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[1].sgst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[1].sgst_val/noOfPax.length} />}</Col>)
                                  }
                                {props.ow_gst!==undefined && props.ow_gst.type!=undefined&& props.ow_gst!="" &&  props.ow_gst.type=="other" &&( props.response.invoicetype==="INVOICE"?
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[0].igst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[0].igst_val/noOfPax.length} />}</Col> :
                                  <Col xs="1"> {<FormateCurrency value={triptype!="2"?props.ow_gst.gst_invoice[1].igst_val/noOfPax.length:props.rt_gst!=""&& props.rt_gst!=undefined&& props.rt_gst.gst_invoice[1].igst_val/noOfPax.length} />}</Col>)
                                  }
                                { cur==="INR" && props.response.invoicetype==="INVOICE" &&
                                 <Col > {<FormateCurrency value={pax.tds_amt} />}</Col>}
                                <Col xs="1"> {<FormateCurrency value={pax.disc} />}</Col>
                                {props.response.invoicetype==="INVOICE" &&
                                 <Col > {<FormateCurrency value={pax.plb} />}</Col>}
                                <Col  className="text-right" xs="1"> {<FormateCurrency value={pax.total} />}</Col>
                                </Row>
                                </div>
                         </React.Fragment>
                     ))}

                     <div className="gtotal">
                         <span>Grand Total : </span> <div className="pricetag"><em>{cur}</em> <strong>{<FormateCurrency value={calculateTotal(fareList)} />}</strong></div>
                     </div>


              </div>
              }
                {paxList.length!==0 && props.response.invoicetype === "ITINERARY" &&
                <>
                <PaxPdf paxList={paxList} flowType={"Passenger"}/>
                </>
                }

        {(refundList.length!==0 || refundPaxList.length!==0) &&
          <>
             <div className="pdfflightDetails">
             <h3 className="pdfSubTitl">
              Flight Details - Status :{"Refund"}
              </h3>
              {onwardList && onwardList[0]!==undefined &&
                <SegmentDetailsPdf  list={onwardList} supplier={props.supplier} airport_data={props.airport_data} type={"Onward"}/>
            }
            {returnList && returnList[0]!==undefined &&
                <SegmentDetailsPdf list={returnList} supplier={props.supplier} airport_data={props.airport_data} type={"Return"}/>
            }

          {refundList.length!==0  && props.response.invoicetype !== "ITINERARY" &&
            <PaxFarePdf flowType={"Refund"} type={props.response.type} fareList={refundList} sup={props.supplier} cur={cur} ow_gst={props.ow_gst} rt_gst={props.rt_gst} triptype={triptype} invoicetype={props.response.invoicetype}/>
          }
          {refundPaxList.length!==0  && props.response.invoicetype === "ITINERARY" &&
          <>
           <PaxPdf paxList={refundPaxList} flowType={"Refund Pax"}/>
          </>
          }</div>

          </>
        }
        {(voidList.length!==0 || voidPaxList.length!==0) &&
          <>
          <div className="pdfflightDetails">
            <h3 className="pdfSubTitl">
              Flight Details - Status : {"Void"}
              </h3>
              {onwardList && onwardList[0]!==undefined &&
                <SegmentDetailsPdf  list={onwardList} supplier={props.supplier} airport_data={props.airport_data} type={"Onward"}/>
                }
                {returnList && returnList[0]!==undefined &&
                <SegmentDetailsPdf list={returnList} supplier={props.supplier} airport_data={props.airport_data} type={"Return"}/>
                }

            {voidList.length!==0  && props.response.invoicetype !== "ITINERARY" &&
            <PaxFarePdf flowType={"Void"} type={props.response.type} fareList={voidList} sup={props.supplier} cur={cur} ow_gst={props.ow_gst} rt_gst={props.rt_gst} triptype={triptype} invoicetype={props.response.invoicetype}/>
            }
            {voidPaxList.length!==0 && props.response.invoicetype === "ITINERARY" &&
            <PaxPdf paxList={voidPaxList} flowType={"Void Pax"}/>
            }
            </div>
          </>
        }
          </div>
        </div>
        </div>
        <div className='a4-screen-sized'>
        <p className="copy mt-4 mb-4"> Copyright © 2021 ezytrip. All rights reserved.</p>
        </div>

      </div>
  );
});

export default FlightInvoicePdf;
