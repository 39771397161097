import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { Col, Form, Button,Tab, Tabs, Modal, Table, Alert } from "react-bootstrap";
import Select from "react-dropdown-select";
import Routes from "../../common/Routes";
import ApplicableDates from "../../common/ApplicableDates";
import PickList from "../../../common/pickList/PickList";
import Utilities from '../../../commonUtils/Utilities';
import SalesChannel from "../../common/SalesChannel";
import RegionType from "../../common/RegionType";
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import { fetchBranches, fetch_company_list, getCompaniesList, getCompanyList, getCompanyTAList, getOfficeIdListS, getSTACompanyList, loadBranchesList } from "../operations";
import { creation, delPromoClass, deletePromoAplDate, deletePromoFMap, deletePromoRoutes, update } from "./operations";
import { confirmBranchDlg } from '../../../common/confirmDialogue';
import { Context } from "../../../../../App";
import log from "loglevel";
import TextEditor from "../../common/TextEditor";

let supplier = require("../../../masterData/Suppliers.json");
let airlineMaster = require("../../../masterData/AirlinesMasterData.json");
let rbdMaster = require('../../../masterData/Rbd.json');


const initialState = {
offer_nm:"", offer_name_ar:"", pro_code:"", offer_desc_en: "", offer_desc_ar: "",ttype:"0", enableTripAll: true,enableTripOne: false,enableTripTwo: false,enableTripThree: false, 
rts:[{dcname: "All(All)", destair: ['All'],destct:"All",scname:"All(All)",srcair: ['All'],srcct:"All"}], 
type:"", act: true,utype:"", ctyp: "ATT", userId:"",userType:"",branches:[], branchMenu:[],regionSelected:false,max_bkng_allow:"",max_user_allow:"",budget_permit:"",
aptype: "Branch",airlines:[{"code":"All",  "label":"All"}], sales_channel:"", enableOfcs:false,officeIds:[], office_id:[{value:"All", label:"All"}],resetRegion:false, region_type:"",edit_region:"", supp:[{value:"All", label:"All"}], cpnfor:[], no_bkng_allow:"", min_purchase:"", offer_det_ar:"",offer_det_en:"", edit_offer_det_ar:"", edit_offer_det_en:"",
disc_type:[], disc_on:[], disc:"", rbdMaster:[],classType:[], enableDevices:false,reset:false, isDeviceAll:true, isB2cWeb:false,isB2cApp:false,isMWeb:false,bid:[], cmpid:[],ta_comp:"",data:[],pk_fare_map_id:"",
notiVarient:"",notiMessage:"",notiMessageShow:false, enableUpdate:false,edit_applDates:[],edit_salesChanel:"",update_id:"",selCompny:[],compType:true, selBrnch:[], enableOfcId:true,selected_branch:[],compData:[]
}
let classArray = [];
let markupFareArray = [];
  
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state,type:"", offer_nm:"", offer_name_ar:"", pro_code:"", offer_desc_en: "", max_bkng_allow:"", max_user_allow:"",budget_permit:"", offer_desc_ar: "",rts:[], ctyp: "ATT",disc_type:[], disc_on:[], disc:"", selCompny:[], selBrnch:[], enableOfcId:true,selected_branch:[],
          resetRegion:true, cpnfor:[], no_bkng_allow:"", min_purchase:"", offer_det_ar:"",offer_det_en:"", classType:[], bid:[], compType:true, cmpid:[],ta_comp:"",data:[],isDeviceAll:true, isB2cWeb:false,isB2cApp:false,isMWeb:false,
        }
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

const PromoCode = (props) => {
 const [state, dispatch] = useReducer(reducer, initialState);
 const [context, setContext] = useContext(Context);
 const [value, setValue] = useState([]);
 const [routDetails, setRoutDetails] = useState({ rts: [] });
 const [dates, setDates] = useState({ datesList: [] });
 const [classState, setRbdClass] = useState({ class: [{cls: 'Business', rbd: ["All"]}, {cls: 'Premium Economy', rbd: ["All"]}, {cls: 'First', rbd: ["All"]}, {cls: 'Economy', rbd: ["All"]}]});
 const [companyType, setCompanyType] = useState(true);
 const [companydata, setCompanyPick] = useState([]);
 const [data, setData] = useState([])
 const [fares, setShowFareList] = useState({ faresList: [] });
 const [enApplicable, setEnApplicable] = useState(true);
 const [disableButton, setDisableButton] = useState(false);
 const [sta, setSTA] = useState(false);



 const cpnUser = [{label:"New User", value:"New User"}, {label:"Registered User", value:"Registered User"}]
 const discount_typesList = [{ label: "% Percentage", value: "Percentage" },{ label: "Value", value: "Value" }]
 const disc_onList = [{ label: "Base Fare", value: "BF" },{ label: "Total Fare", value: "TF" },]
 const cls = [{ "label": "Economy", "value": "Economy" },{ "label": "Premium Economy", "value": "Premium Economy" }, { "label": "Business", "value": "Business" }, { "label": "First", "value": "First" }]


 useEffect(() => {
  if (props.sendEditData && props.sendEditData !== undefined) {
    convertEditPromo(props.sendEditData);
  }
}, [props.sendEditData]);

/**
 * @author: Lakshmi
 * @description: This function will Edit the markup Based on the Markup Id
 * @date:14-09-2020
 * @function : convertEditPromo
 */
const convertEditPromo = (marobj) => {
  var obj = marobj.data.res;
  dispatch({ type: "offer_nm", payload: obj.offer_nm });
  dispatch({ type: "offer_name_ar", payload: obj.offer_name_ar });
  dispatch({ type: "pro_code", payload: obj.pro_code });
  dispatch({ type: "offer_desc_en", payload: obj.offer_desc_en });
  dispatch({ type: "offer_desc_ar", payload: obj.offer_desc_ar });
  dispatch({ type: "edit_region", payload: obj.region_type });
  dispatch({ type: "region_type", payload: obj.region_type });
  dispatch({ type: "cpnfor", payload: [{value:obj.cpnfor, label:obj.cpnfor}] });
  dispatch({ type: "no_bkng_allow", payload: obj.no_bkng_allow });
  dispatch({ type: "max_bkng_allow", payload: obj.max_bkng_allow });
  dispatch({ type: "max_user_allow", payload: obj.max_user_allow });
  dispatch({ type: "budget_permit", payload: obj.budget_permit });
  dispatch({ type: "min_purchase", payload: obj.min_purchase });
  dispatch({ type: "offer_det_ar", payload: obj.offer_det_ar });
  dispatch({ type: "offer_det_en", payload: obj.offer_det_en });
  dispatch({ type: "edit_offer_det_ar", payload: obj.offer_det_ar });
  dispatch({ type: "edit_offer_det_en", payload: obj.offer_det_en });
  dispatch({ type: "aptype", payload: obj.aptype });
  dispatch({ type: "disc", payload: obj.disc });
  dispatch({ type: "update_id", payload: obj.id });
  dispatch({ type: "ctyp", payload: obj.ctyp });
  dispatch({ type: "pk_fare_map_id", payload: obj.pk_fare_map_id });
  if (obj.ttype === 1) {
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: true });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: false });
    dispatch({ type: "ttype", payload: obj.ttype });
  } else if (obj.ttype === 2) {
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: true });
    dispatch({ type: "enableTripThree", payload: false });
    dispatch({ type: "ttype", payload: obj.ttype });
  } else if (obj.ttype === 3) {
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: true });
    dispatch({ type: "ttype", payload: obj.ttype });
  } else if (obj.ttype === 0) {
    dispatch({ type: "enableTripAll", payload: true });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: false });
    dispatch({ type: "ttype", payload: obj.ttype });
  }
  for (let dtype of discount_typesList){
    if (dtype.value==obj.disc_type){
      dispatch({ type: "disc_type", payload: [{value:dtype.value, label:dtype.label}] });
    }
  }
  for (let discOn of disc_onList){
    if (discOn.label==obj.disc_on){
      dispatch({ type: "disc_on", payload: [{value:discOn.value, label:discOn.label}] });
    }
  }
//   for (let suplr of supplier){
//     if (suplr.value==obj.supp){
//   dispatch({ type: "supp", payload: [{value:suplr.value, label:suplr.label}] });
// }
dispatch({ type: "supp", payload: [{value:obj.supp, label:obj.supp}] });
// }
const clssnm= obj.class.map(cls=>({
  cls:cls.class_name,
  id:cls.id,
  rbd:cls.p_rbd
}))
classState.class=(clssnm)

  // setRbdClass({ class: obj.class });
  if(obj.office_id?.includes("All")){
    dispatch({ type: "office_id", payload: [{ value:"All", label: "All" }] });
  }else{
    if(obj.office_id!=null){
    dispatch({ type: "office_id", payload: [{ value:obj.office_id, label: obj.office_label }]})
  }else
    {
    dispatch({ type: "office_id", payload: ""})
  }
  }
 
  dispatch({ type: "edit_salesChanel", payload: obj.sales_channel });
  dispatch({ type: "sales_channel", payload: obj.sales_channel });
  dispatch({ type: "enableUpdate", payload: true });
  if (obj.supp || obj.supp === 0) {
    let airList = [];
    for (let air of supplier) {
      if (obj.supp === air.value) {
        const pauload = {
          value: obj.supp,
          label: air.label,
        };
        airList.push(pauload);
      }
    }
    dispatch({ type: "supplier", payload: airList });
  }
  if (obj.act == 1) {
    dispatch({ type: "act", payload: true });
  } else {
    dispatch({ type: "act", payload: false });  
  }
  if (obj.apdate) {
    //setApplicable({ applDates: obj.apdate });
    setDates({ datesList: obj.apdate });
    dispatch({type:"edit_applDates",payload: obj.apdate})
  }
  if (obj.rts && obj.rts !== undefined) {
    convertEditRoutes(obj);
  }
  if (obj.al) {
    let acarray = obj.al.split(",");
    let airList = [];
    for (let code of acarray) {
      for (let air of airlineMaster) {
        if (code === air.value) {
          const pauload = {
            value: code,
            label: air.label,
          };
          airList.push(pauload);
        }
      }
    }
    dispatch({ type: "airlines", payload: [{"code":obj.al,  "label":obj.al}] });
    setValue(getUnique(airList, "value"));
    dispatch({ type: "airlinesList", payload: getUnique(airList, "value")});
  }
  dispatch({ type: 'isDeviceAll', payload: obj.isDeviceAll })      
  dispatch({ type: 'isB2cWeb', payload: obj.isB2cWeb })      
  dispatch({ type: 'isB2cApp', payload: obj.isB2cApp })      
  dispatch({ type: 'isMWeb', payload: obj.isMWeb })    
  const cmpnyname= obj.cmpid.map(cid=>({
      name:cid.company_name,
      id:cid.company_id
    }))
    dispatch({ type: 'selCompny', payload: cmpnyname })  
  // dispatch({ type: 'cmpid', payload: obj.cmpid })
  dispatch({ type: 'bid', payload: obj.bid }) 
  if(obj.sales_channel=="B2B"){     
  dispatch({ type: 'selBrnch', payload: obj.bid })
} else{
    dispatch({ type: 'selBrnch', payload: [] })
  } 
  fares.faresList= obj.cmpid.map((cid, id)=>({
    company:cid.company_name,
    id:cid.company_id,
    borcname:obj.bid[0]?.name!=undefined?obj.bid[0]?.name:"N/A"
  }))
  window.scrollTo(0, 0);
};

/**
 * @author: Lakshmi
 * @description: This function will Edit the Routes
 * @date:14-09-2020
 * @function : convertEditRoutes
 */
const convertEditRoutes = (obj) => {
  let routes = [];
  for (let value of obj.rts) {
    let srcArray = [];
    let destArray = [];
    let airs = [];
    let dests = [];
    if (value.srcair && value.srcair.length !== 0) {
      srcArray = value.srcair.split(",");
      airs = srcArray.filter((q, idx) => srcArray.indexOf(q) === idx);
    }
    if (value.destair && value.destair.length !== 0) {
      destArray = value.destair.split(",");
      dests = destArray.filter((q, idx) => destArray.indexOf(q) === idx);
    }
    if (airs) {
      airs.map((obj) => {
        var route = "";
        if (obj && obj !== undefined) {
          route = {
            id: value.id,
            promo_id:value.promo_id,
            srcct: value.srcct,
            scname: value.scname + " " + "[" + obj + "]",
            destct: value.destct,
            dcname: value.dcname + " " + "[" + dests + "]",
            srcair: airs,
            destair: dests,
            is_db: value.is_db,
          };
        }
        routDetails.rts.push(route);
        setRoutDetails({ rts: routDetails.rts });
        let route_json = {
          srcct: value.srcct,
          destct: value.destct,
          srcair: airs,
          destair: dests,
          scname: value.scname,
          dcname: value.dcname,
          id: value.id,
          promo_id:value.promo_id,
          is_db: value.is_db
        };
        routes.push(route_json);
      });
      dispatch({ type: "rts", payload: routes });
    }
  }
};

 useEffect(() => {
  if (context.logindata !== undefined) {
    dispatch({ type: 'userType', payload: context.logindata.utype })
    dispatch({ type: 'userId', payload: context.logindata.uid })
    if (context.logindata.utype !== "ta_user") {
      dispatch({ type: 'enableOfcs', payload: true })
    } else {
      dispatch({ type: 'ctyp', payload: "TA" })
      dispatch({ type: 'utype', payload: "ta_user" })
    }
  }
}, [context.logindata]);

 useEffect(() => {
  if (rbdMaster.length > 0) {
    dispatch({ type: 'rbdMaster', payload: rbdMaster })
    dispatch({ type: 'defaultRBDmaster', payload: rbdMaster })
  }
}, [rbdMaster])

/**
 * @author: Lakshmi
 * @description: This function will Load the Branches based on the selected OFC ID
 * @date:14-09-2020
 * @function : getBranchesWithOfficeId
 */
useEffect(() => {
  if(state.office_id &&state.office_id[0]?.value && state.supp &&state.supp[0] &&state.supp[0].label ==="Amadeus"){
    
    AttCommonSerivce.getBranchesWithOfficeId({"office_id":state.office_id[0]?.value,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_channel}).then(response => {
      if (response.data.suc){
        let authData = []
        let BRANCHES = response.data.res.filter((val)=>{
                return val.fk_branch_id != null;
              })
              for (let val of BRANCHES){
                let auth = {
                  "value": val.fk_branch_id
                  ,
                  "label": val.office_name
                }
                authData.push(auth)
              }
        let branchesList = authData.map((branch) => (
          <option value={branch.value}>{branch.label}</option>
        ));
        state.branchMenu = branchesList;
        dispatch({ type: "branchMenu", payload: branchesList });
        dispatch({ type: "branches", payload: branchesList });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in Loadi branches based on Region function---" + error
      );
    });
  }
}, [state.office_id,state.supplier])

useEffect(()=>{
  if (state.sales_channel!="" && state.region_type!="")
  if(state.sales_channel==="B2B"){
    if(context.logindata.utype == "att_user") {
   if(context.logindata.is_admin){
    fetchBranches({"region_type": state.region_type }).then(response => {
      if (response.data.suc) {
        let branchesList = response.data.res.map((branch) =>
          <option value={branch.value}>{branch.label}</option>
        );
        dispatch({ type: 'branchMenu', payload: branchesList })
        dispatch({ type: 'branches', payload: branchesList })
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getBranchData function---" + error
      );
    });
  }else{
    loadBranchesList({"region_type": state.region_type,"uid": context.logindata.uid}).then(response => {
      if (response.data.suc) {
        let branchesList = response.data.res.map((branch) =>
          <option value={branch.value}>{branch.label}</option>
        );
        dispatch({ type: 'branchMenu', payload: branchesList })
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getBranchData function---" + error
      );
    });
   }
  }
  }else{
    let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type,sales_channel:state.sales_channel}
  fetch_company_list(pyload).then(response => {
    let authData = []
    if (response.data.suc) {
      for (let val of response.data.res){
        let auth = {
          "value": val.fk_company_id,
          "label": val.company_name,
          "sales_channel": val.sales_channel
        }
        authData.push(auth)
      }
      let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_channel)
      setCompanyPick(cmpanis)
    }
  }).catch(function (error) {
    log.error(
      "Exception occured in getCompanyList function---" + error
    );
  });
  }
},[state.sales_channel, state.region_type])

/**
 * @author: Lakshmi
 * @description: This function will Load the Companies based on the selected OFC ID
 * @date:14-09-2020
 * @function : fetch_company_list
 */
// useEffect(() => {
//   if(!state.enableDevices){
//    setCompanyPick([])
//   }
//   if(state.enableDevices && state.office_id && state.office_id[0]?.value){
//   const payload = {"office_id":state.office_id[0].value,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_channel}
//       fetch_company_list(payload).then(response => {
//         if (response.data.suc) {
//           let authData = []
//           for (let val of response.data.res){
//             let auth = {
//               "value": val.fk_company_id,
//               "label": val.company_name,
//               "sales_channel": val.sales_channel
//             }
//             authData.push(auth)
//           }
//           let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_channel)
//           setCompanyPick(cmpanis)
//         }
//       }).catch(function (error) {
//         log.error(
//           "Exception occured in getCompanyList function---" + error
//         );
//       });
//   }
// }, [!state.enableDevices,state.office_id]);

 function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map((e) => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

 const setAir = (data) => {
  if (data.length > 0 && data[0].value === "All") {
    let finalAirs = [];
    data.map((obj) => {
      const al = {
        code: obj.value,
        name: obj.label,
      };
      finalAirs.push(al);
    });
    dispatch({ type: "airlines", payload: finalAirs });
    dispatch({ type: "airlinesList", payload: data });
    setValue(data);
  } else if (data.length > 0 && data[0].value !== "All") {
    let finalAirs = [];
    data.map((obj) => {
      const al = {
        code: obj.value,
        name: obj.label,
      };
      finalAirs.push(al);
    });
    dispatch({ type: "airlines", payload: finalAirs });
    setValue(data);
    removeAll();
  } else {
    dispatch({ type: "airlines", payload: [] });
    setValue(data);
    defaultAirlines();
  }
};
useEffect(() => {
  let airs = [];
  airs = airlineMaster;
  airs.unshift({ value: "All", label: "All" });
  let airlines = getUnique(airs, "value");
  dispatch({ type: "airlinesList", payload: airlines });
}, []);

const defaultAirlines = (e) => {
  let airs = [];
  airs = airlineMaster;
  airs.unshift({ value: "All", label: "All" });
  let airlines = getUnique(airs, "value");
  dispatch({ type: "airlinesList", payload: airlines });
};

const removeAll = (e) => {
  let arr = [];
  for (let val of state.airlinesList) {
    arr.push(val.value);
  }
  if (arr.indexOf("All") > -1) {
    const index = arr.indexOf("All");

    state.airlinesList.splice(index, 1);
  }
};

const applicableDates = (ad) => {
    // setApplicable({applDates: [...applicable.applDates, ad]});
    setDates({ datesList: [...dates.datesList, ad] });
    dispatch({ type: "reset", payload: false });
  };

  const updateApplDates = (data) => {
    dispatch({ type:"reset",payload:false})
    setDates({ datesList: data });
  };

  /**
 * @author: arsh
 * @description: This function will delete the selected Applicable DATES
 * @date:20-11-2023
 * @function : callDeleteApp
 */
  const callDeleteApp = (id) => {

    deletePromoAplDate(id)
      .then((response) => {
        if (response.data.suc) {
          dispatch({
            type: "notiMessage",
            payload: "Applicable Deleted Successfully",
          });
          dispatch({ type: "notiVarient", payload: "success" });
          dispatch({ type: "notiMessageShow", payload: true })
          hidemessage()
        }
      })
      .catch(function (error) {
        log.error(
          "Exception occured in saveCompanyDetails function---" + error
        );
      });
  };

 const updateRoutesData = (data) => {
  dispatch({ type: "rts", payload: data });
};
const rotesInfo = (route) => {
  state.rts.push(route);
  dispatch({ type: "rts", payload: state.rts });
  dispatch({ type: "reset", payload: false });
};

/**
 * @author: Lakshmi
 * @description: This function will delete the selected route
 * @date:14-09-2020
 * @function : routeDelete
 */
const routeDelete = (data) => {
  const payload = {
    src_air: data.srcair,
    desc_air: data.destair,
    promoid: data.promo_id,
  };
  deletePromoRoutes(payload)
    .then((response) => {
      if (response.data.suc) {
        console.log("Delete Date Deleted SUC :");
      }
    })
    .catch(function (error) {
      log.error("Exception occured in deleteRoute function---" + error);
    });
};

const updateSalesChanel=(salesChanel)=>{
  if(salesChanel!==state.sales_channel){
  dispatch({type:"resetRegion", payload:true})
  dispatch({ type: 'region_type', payload:"" })
  dispatch({ type: 'branchMenu', payload:[] })
  dispatch({ type: 'bid', payload:[] })
  setCompanyPick([])}
  dispatch({ type: "sales_channel", payload: salesChanel });
  if(salesChanel==="B2C"){
    dispatch({ type: "enableDevices", payload: true });
  }else{
    dispatch({ type: "enableDevices", payload: false });
  }
  // dispatch({ type: 'office_id', payload:"" })
  // props.sendChannel(salesChanel)
}

const updateRegion=(region)=>{
  dispatch({ type: "resetRegion", payload: false });
  dispatch({ type: "region_type", payload: region });
  dispatch({type:"regionSelected", payload: true })
}
const handleRegionType = (e) => {
  dispatch({ type: "resetRegion", payload: false });
  if(e.length!==0){
    let branchesList = e.map((branch) =>
    <option value={branch.value}>{branch.label}</option>
    );
    // state.branchMenu = branchesList
    dispatch({ type: 'branchMenu', payload: branchesList})
    dispatch({ type: "branches", payload: e });
    // dispatch({ type: "regionError", payload: false });
    dispatch({type:"regionSelected", payload: true })
  }else{
    if(props.sendEditData==undefined){
    dispatch({type:"branchMenu", payload:[]})
    dispatch({type:"bid", payload:0})}
  }
}

const updateSupplier=(sup)=>{
  
  if(sup!==undefined && sup.length!==0){
    // props.sendSup(sup);
    if(sup[0].label==="Amadeus"){
      dispatch({type:"enableOfcId", payload:true})
    }else{
      dispatch({type:"enableOfcId", payload:false})
    }
    if(props.sendEditData==undefined){
    dispatch({type:"branchMenu", payload:[]})
    dispatch({type:"bid", payload:0})
    setCompanyPick([])
    }
    if(sup[0].label!=="Amadeus"){
      if(state.sales_channel==="B2B"){
      if(context.logindata.utype == "att_user") {
     if(context.logindata.is_admin){
      fetchBranches({"region_type": state.region_type }).then(response => {
        if (response.data.suc) {
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
          dispatch({ type: 'branches', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }else{
      loadBranchesList({"region_type": state.region_type,"uid": context.logindata.uid}).then(response => {
        if (response.data.suc) {
          let branchesList = response.data.res.map((branch) =>
            <option value={branch.value}>{branch.label}</option>
          );
          dispatch({ type: 'branchMenu', payload: branchesList })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
    }
    }
    }else{
      let pyload = { userId: context.logindata.uid, pcid: context.logindata.pcid, utype: context.logindata.utype, region_type: state.region_type,sales_channel:state.sales_channel}
    fetch_company_list(pyload).then(response => {
      let authData = []
      if (response.data.suc) {
        for (let val of response.data.res){
          let auth = {
            "value": val.fk_company_id,
            "label": val.company_name,
            "sales_channel": val.sales_channel
          }
          authData.push(auth)
        }
        let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_channel)
        setCompanyPick(cmpanis)
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
    }
    }
  }
}

/* @author: Lakshmi
 * @description: This function will Load the OFC IDS based on the selected Sales channel and Region
 * @date:14-09-2020
 * @function : getOfficeIdListS
 */
  useEffect(() => {
    if (state.sales_channel!== "" && state.region_type !== "") {
       const payload = {"sales_channel": state.sales_channel, "region_type": state.region_type, "userid": context.logindata.uid}
       getOfficeIdListS(payload).then(response => {
          if (response.data.suc) {
            if (response.data.res) {
              dispatch({ type: 'officeIds', payload: response.data.res })
              dispatch({ type: 'enableOfcs', payload: true })
            }
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
       }
  }, [state.sales_channel,state.region_type]);

const updateOfcId=(ofc)=>{
  dispatch({type:"office_id", payload:ofc})
  if(ofc.length!==0){
   if(state.sales_channel!==""){
      if(state.sales_channel==="B2C"){
        let payload="";
        if(context.logindata.is_admin){
          payload={ofc_id:ofc[0].value}
        }else{
          payload={ofc_id:ofc[0].value,uid:context.logindata.uid}
        }
        AttCommonSerivce.att_companies(payload).then(response => {
          if (response.data.suc && response.data.res.status === 200) {
            let authData = []
            for (let val of response.data.res.data){
              let auth = {
                "value": val.value,
                "label": val.label,
                "sales_channel": val.sales_channel,
              }
              authData.push(auth)
            }
            let cmpanis = authData.filter((obj)=>obj.sales_channel===state.sales_channel)
            setCompanyPick(cmpanis)
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getBranchData function---" + error
          );
        });
      }else{
        let payload="";
        if(context.logindata.is_admin){
          payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_channel:state.sales_channel}
        }else{
          payload={"region_type":state.region_type,ofc_id:ofc[0].value,sales_channel:state.sales_channel,uid:context.logindata.uid}
        }

      }
   }else if(state.sales_channel===""){

   }else if(state.region_type===""){
   }
  }
  // if(ofc.length>0){
  //   dispatch({ type: "office_id", payload: ofc[0].value });
  // }

  // props.sendOfcId(ofc)
}
const handleTravelType =(e)=>{
  const { id, value } = e.target;
    dispatch({ type: "reset", payload: false });
  if (id === "all") {
    dispatch({ type: "ttype", payload: "0" });
    dispatch({ type: "enableTripAll", payload: true });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: false });
  } else if (id === "one") {
    dispatch({ type: "ttype", payload: "1" });
    dispatch({ type: "tripOne", payload: true });
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: true });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: false });
  } else if (id === "round") {
    dispatch({ type: "ttype", payload: "2" });
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: true });
    dispatch({ type: "enableTripThree", payload: false });
  } else if (id === "multi") {
    dispatch({ type: "ttype", payload: "3" });
    dispatch({ type: "enableTripAll", payload: false });
    dispatch({ type: "enableTripOne", payload: false });
    dispatch({ type: "enableTripTwo", payload: false });
    dispatch({ type: "enableTripThree", payload: true });
  }
  dispatch({ type: id, payload: value });
}
const handleCoupon =(e)=>{
  if(!state.enableUpdate){
  (dispatch({type:"max_user_allow", payload:""}))
}
  dispatch({type:"cpnfor", payload:e}) 
}
const handleChange = (e)=>{
  const { id, value } = e.target;
  e.preventDefault();
  e.nativeEvent.stopImmediatePropagation();
  const re = /^[0-9\b]+$/;
  const en=/^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/i
  const ar=/^[\u0600-\u06FF0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/i

  if(id == "offer_nm"){
    if ( e.target.value === '' || en.test(e.target.value)) {
    dispatch({type: "offer_nm", payload : value})
    }
  }
  if(id == "offer_name_ar"){
    if ( e.target.value === '' || ar.test(e.target.value)) {
    dispatch({type: "offer_name_ar", payload : value})
    }
  }
  if(id == "offer_desc_en"){
    if ( e.target.value === '' || en.test(e.target.value)) {
    dispatch({type: "offer_desc_en", payload : value})
    }
  }
  if(id == "offer_desc_ar"){
    if ( e.target.value === '' || ar.test(e.target.value)) {
    dispatch({type: "offer_desc_ar", payload : value})
    }
  }
  if(id == "offer_det_en"){
    if ( e.target.value === '' || en.test(e.target.value)) {
    dispatch({type: "offer_det_en", payload : value})
    }
  }
  if(id == "offer_det_ar"){
    if ( e.target.value === '' || ar.test(e.target.value)) {
    dispatch({type: "offer_det_ar", payload : value})
    }
  }
  if (id =="disc" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"disc", payload: e.target.value})}
  }
  if (id =="no_bkng_allow" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"no_bkng_allow", payload: e.target.value})}
  }
  if (id =="max_bkng_allow" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"max_bkng_allow", payload: e.target.value})}
  }
  if (id =="max_user_allow" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"max_user_allow", payload: e.target.value})}
  }
  if (id =="min_purchase" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"min_purchase", payload: e.target.value})}
  }
  if (id =="budget_permit" ){
   if ( e.target.value === '' || re.test(e.target.value)) {
    dispatch({type:"budget_permit", payload: e.target.value})}
  }
  if (id === "act") {
    if (state.act) {
      dispatch({type:"act", payload: false})    
    } else {
      dispatch({type:"act", payload: true})  
    }
  }
}

const updateOverview = (data, type) => {
  if(type=="ar"){
  dispatch({ type: 'offer_det_ar', payload: data })
}else if(type=="en"){
  dispatch({ type: 'offer_det_en', payload: data })
 }
}

// Adding Class and rbds
const setCls = (data) => {
  if (data.length > 0 && data[0].value === "All") {
    var finalrbd = "";
    let rbds = [];
    data.map((obj) => {
      if (finalrbd) {
        finalrbd = finalrbd + "|" + obj.value;
      } else {
        finalrbd = obj.value;
      }
    }
    );
    rbds.push(finalrbd);
    dispatch({ type: 'rbd', payload: rbds })
    dispatch({ type: 'classCode', payload: data })
    dispatch({ type: 'rbdMaster', payload: data })
    // setValue(data)
  } else if (data.length > 0 && data[0].value !== "All") {
    var finalrbd = "";
    let rbds = [];
    data.map((obj) => {
      if (finalrbd) {
        finalrbd = finalrbd + "|" + obj.value;
      } else {
        finalrbd = obj.value;
      }
    }
    );
    rbds.push(finalrbd);
    dispatch({ type: 'rbd', payload: rbds })
    dispatch({ type: 'classCode', payload: data })
    // setValue(data)
    removeAll()
  } else {
    let cls = getUnique(state.defaultRBDmaster, "value")
    dispatch({ type: 'rbdMaster', payload: cls })

    dispatch({ type: 'rbd', payload: [] })
    dispatch({ type: 'classCode', payload: data })
    // setValue(data)

  }
}

 //checking the duplicates based on length(original json and unique json length)
 const validateCls = () => {
  if (state.type === "") {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Class' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false;
  } else if (state.type !== "") {
    const payload = {
      "cls": state.type,
      "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
    }
    classArray.push(payload);
    let data = Utilities.getUniqData(classArray);
    var int = 0;
    for (let val of data) {
      if (val.cls == "Economy") {
        int = int + 1;
      } else if (val.cls == "Premium Economy") {
        int = int + 1;
        if (int !== 0 && data.includes("All")) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
        int = int + 1;
      }
      if (classArray.length !== data.length) {
        classArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Class are already exist' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
  }
  return true;
}

//Adding to Classes list
const handleClses = () => {
  if (validateCls()) {
    const payload = {
      "cls": state.type,
      "rbd": state.rbd && state.rbd.length > 0 ? state.rbd : (["All"])
    }
    setRbdClass({ class: [...classState.class, payload] });
    dispatch({ type: 'classCode', payload: [] })
    dispatch({ type: 'type', payload: "" })
    dispatch({ type: 'classType', payload: [] })
    dispatch({ type: "rbd", payload: [] })
  }
}

const handleRemoveClass = (obj, idx) => () => {
  if (obj !== undefined && obj.id !== undefined) {
    delPromoClass(obj.id).then(response => {
      if (response.data.suc) {
        let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
        if (classArray && classArray.length > 0) {
          classArray = matchedArr
        }
        setRbdClass({ class: classState.class.filter((s, sidx) => s.id !== obj.id) });
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in saveCompanyDetails function---" + error
      );
    });
  } else {
    let matchedArr = classState.class.filter((s, sidx) => idx !== sidx)
    setRbdClass({ class: classState.class.filter((s, sidx) => idx !== sidx) });
    if (classArray && classArray.length > 0) {
      classArray = matchedArr
    }
  }
  if (classState.class.length === 0) {
    dispatch({ type: 'showCls', payload: false })
  }
}

const handleCompTypeChange = (e) => {
  if (e.target.id === "att") {
    dispatch({ type: 'ctyp', payload: "ATT" })
    dispatch({ type: 'compType', payload: true })
    setCompanyPick("");
    dispatch({ type: 'ofcs', payload: [] })
    setCompanyType(true);
    // markupFareArray = [];
  } else if (e.target.id === "ta") {
    dispatch({ type: 'bid', payload: 0 })
    dispatch({ type: 'enableOfcs', payload: false })
    setCompanyPick("");
    dispatch({ type: 'compType', payload: false })
    dispatch({ type: 'enbleType', payload: true })
    dispatch({ type: 'showCat', payload: false })
    dispatch({ type: 'ctyp', payload: "TA" })
    dispatch({ type: 'ofcs', payload: [] })
    dispatch({ type: 'enableOfcs', payload: true })
    dispatch({ type: 'aptype', payload: "Branch" })
    dispatch({ type: 'ta_comp', payload: [] })
    setEnApplicable(false)
    setCompanyType(false);
    setSTA(false)
    // markupFareArray = [];
  } else {
    dispatch({ type: 'bid', payload: 0 })
    dispatch({ type: 'enableOfcs', payload: false })
    setCompanyPick("");
    dispatch({ type: 'compType', payload: false })
    dispatch({ type: 'enbleType', payload: true })
    dispatch({ type: 'showCat', payload: false })
    dispatch({ type: 'ctyp', payload: "STA" })
    dispatch({ type: 'ofcs', payload: [] })
    dispatch({ type: 'enableOfcs', payload: true })
    dispatch({ type: 'aptype', payload: "Branch" })
    setCompanyType(true);
    setSTA(true)
    setEnApplicable(false)
    // markupFareArray = [];
  }
}

/**
 * @author: Arsh
 * @description: This function will update the Device type for B2C Sales channel
 * @date:19-11-2023
 * @function : handleDeviceTypeChange
 */
const handleDeviceTypeChange = (e) => {
  if (e.target.id === "deviceAll"){
      dispatch({ type: 'isB2cWeb', payload: false })
      dispatch({ type: 'isB2cApp', payload: false })
      dispatch({ type: 'isMWeb', payload: false })
    if(state.isDeviceAll){
      dispatch({ type: 'isDeviceAll', payload: false })
    }else{
      dispatch({ type: 'isDeviceAll', payload: true })
    }
  }else if(e.target.id === "web"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.isB2cWeb){
      dispatch({ type: 'isB2cWeb', payload: false })
    }else{
      dispatch({ type: 'isB2cWeb', payload: true })
      if(state.isB2cApp && state.isMWeb){
        updateStateVal();
      }
    }
  }else if(e.target.id === "app"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.isB2cApp){
      dispatch({ type: 'isB2cApp', payload: false })
    }else{
      dispatch({ type: 'isB2cApp', payload: true })
      if(state.isB2cWeb && state.isMWeb){
        updateStateVal();
      }
    }
  }else if(e.target.id === "mweb"){
    dispatch({ type: 'isDeviceAll', payload: false })
    if(state.isMWeb){
      dispatch({ type: 'isMWeb', payload: false })
    }else{
      dispatch({ type: 'isMWeb', payload: true })
      if(state.isB2cWeb && state.isB2cApp){
        updateStateVal();
      }
    }
  }
}

const updateStateVal=()=>{
  dispatch({ type: 'isDeviceAll', payload: true })
  dispatch({ type: 'isB2cWeb', payload: false })
  dispatch({ type: 'isB2cApp', payload: false })
  dispatch({ type: 'isMWeb', payload: false })
}

const handleTypeChange = (e) => {
  dispatch({ type: 'aptype', payload: "Branch" })
  if (e.target.id === "ta") {
    dispatch({ type: 'ctyp', payload: "TA" })
    setCompanyType(true);
    setCompanyPick([])
  } else {
    dispatch({ type: 'ctyp', payload: "STA" })
    setCompanyType(false);
    const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
    //Load all companies based Login type as TA
    if (companydata && companydata.length === 0) {
      getCompaniesList(pyload).then(response => {
        if (response.data.suc) {
          setCompanyPick(response.data.res)
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
  }
}

const pickldata = (data) => {
  dispatch({ type: 'data', payload: data })
}

/**
 * @author: Arshad
 * @description: This function will Load the Companies Based on the Branch for B2B
 * @date:19-11-2023
 * @function : fetch_company_list
 */
const handleBranchChange = (e) => {
  let selbr = state.branches.filter((val)=>{
    if(val.value){
     if ( val.value == e.target.value){
      dispatch({ type: 'data', payload: [{"value":val.value, "label":val.children?val.children:val.label}]})
     }
    }
      else if(val.props.value){
      if(val.props.value == e.target.value){
        dispatch({ type: 'data', payload: [{"value":val.props.value, "label":val.props.children}] })
      }
    }
  })
  dispatch({ type: 'selected_branch', payload: selbr });
  state.selected_branch.push(selbr)
  setCompanyPick("");
  const { id, value } = e.target
  dispatch({ type: id, payload: value });
  // const payload = {"office_id":state.office_id[0]?.value?state.office_id[0].value:state.office_id,"userid": context.logindata.uid,"region_type":state.region_type,"sales_channel":state.sales_channel,"branch_id":e.target.value}
  
  if (!sta) {
    getCompanyList(value).then(response => {
      if (response.data.suc) {
        let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_channel)
      setCompanyPick(cmpanis)
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });

  } else {
    dispatch({ type: 'ta_comp', payload: [] })
    getCompanyTAList(value, "TA").then(response => {
      if (response.data.suc) {
        let cmpanis = response.data.res.filter((obj)=>obj.sales_channel===state.sales_channel)
        setCompanyPick(cmpanis)
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });
  }
  state.branches.map((obj) => {
    let blist = [];
    if (obj.value !== undefined && Number(obj.value) === Number(state.bid)) {
      blist.push({ "id": obj.value, "name": obj.label });
      dispatch({ type: 'selBranches', payload: blist })
    }
  });
}

const handleStaChange = (e) => {
  dispatch({ type: 'staCompanies', payload: [] })
  setData([])
  if (e && e.length > 0) {
    // staCompanies
    dispatch({ type: 'ta_comp', payload: e });
    getSTACompanyList(e[0].value).then(response => {
      if (response.data.suc) {
        dispatch({ type: 'staCompanies', payload: response.data.res })
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getCompanySTAList function---" + error
      );
    });
  }
}

const compdata = (data) => {
  dispatch({ type: 'compData', payload: data })
}

const validateFareMap = () => {
  if (state.userType === "att_user") {
    if(!state.regionSelected && props.edit_region==undefined && state.region_type===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Region type ' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
      }else if((!state.office_id || state.office_id?.length === 0) && state.supp[0]?.label=="Amadeus") {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select OFC ID' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
    if (state.aptype === "Branch" && state.ctyp === "ATT") {

      if (state.data.length === 0 && !state.enableDevices) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one Branch' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      if (companydata.length===0 || state.compData.length===0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    } else if (!state.enableDevices && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) {
      if (state.bid === 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      if (state.ctyp === "STA") {
        if (state.ta_comp.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select TA Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          return false;
        }
        if (state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one STA Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      } else {
        if (state.compData.length === 0) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select atleast one TA Company' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage()
          return false;
        }
      }
    }
  } else {
    if (state.utype == "ta_user" && state.ctyp === "STA" && state.compData.length === 0) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select atleast one Company' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }
  }
  // //Validating profile markup
  // if (perBooking.perBookings.length === 0 && perTicket.perTickts.length === 0) {
  //   dispatch({ type: 'notiMessageShow', payload: true })
  //   dispatch({ type: 'notiMessage', payload: 'Please define Applicable markup' })
  //   dispatch({ type: 'notiVarient', payload: 'danger' })
  //   hidemessage()
  //   return false;
  // }

  if (state.aptype === "Branch") {
    if (state.ctyp === "TA") {
      markupFareArray = []
      if(fares.faresList && fares.faresList.length > 0) {

        for(let eachFare of fares.faresList) {

          if(eachFare.company !== state.selected_company) {

            let obj = {
              "name" : eachFare.company
            }
            markupFareArray.push(obj)
          }
        }
      }
      for (let val of state.compData) {
        const payload = {
          // "cid": val.value,
          "name": val.label
        }
        markupFareArray.push(payload);
      }
      let data = Utilities.getUniqData(markupFareArray);
      if (markupFareArray.length !== data.length) {
        // markupFareArray = data;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        // let correction = markupFareArray[markupFareArray.length - 1].name
        // markupFareArray = uniqueJson(markupFareArray)
        return false;
      }
    }
  } else if (state.aptype === "Category" && state.utype == "att_user") {
    for (let val of state.compData) {
      const payload = {
        // "cid": val.value,
        "name": val.label
      }
      markupFareArray.push(payload);
    }
    let data = Utilities.getUniqData(markupFareArray);
    if (markupFareArray.length !== data.length) {
      markupFareArray = data;
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      let correction = markupFareArray[markupFareArray.length - 1].name
      markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
      return false;
    }
  }
  if (state.ctyp === "STA" && state.utype == "ta_user") {
    markupFareArray = []
    if(fares.faresList && fares.faresList.length > 0) {

      for(let eachFare of fares.faresList) {

        if(eachFare.company !== state.selected_company) {

          let obj = {
            "name" : eachFare.company
          }
          markupFareArray.push(obj)
        }
      }
    }
    for (let val of state.compData) {
      const payload = {
        // "cid": val.value,
        "name": val.label
      }
      markupFareArray.push(payload);
    }
    let data = Utilities.getUniqData(markupFareArray);
    if (markupFareArray.length !== data.length) {
      // markupFareArray = data;
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      // let correction = markupFareArray[markupFareArray.length - 1].name
      // markupFareArray = markupFareArray.filter((s, idx) => (s.name !== correction))
      return false;
    }
  }
  let cmpanieslist=false
    state.compData.forEach(element => {
      const exist = state.selCompny.some(existingItem=> existingItem.id===element.value);
      if (exist){
       cmpanieslist=true
      }
    });
    if(cmpanieslist){
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Company Already Exists' })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage()
    return false
    }

  return true;
}

// ========================================================================
  // Creating Fare List baesd on Profiles selection
  // ========================================================================
  const showMarkupFares = () => {
    if (validateFareMap()) {
      let branches = [];
      let companys = [];
      let category = [];
      if (context.logindata.utype === "att_user" && state.aptype === "Branch") {
        if (state.compData) {
          if(!state.enableDevices){
            branches.push({ "id": state.data[0].value, "name": state.data[0].label });
            state.selBrnch.push({ "id": state.data[0].value, "name": state.data[0].label });
          }
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices? (state.data[0].props?state.data[0].props.children:state.data[0].label):"",
                //sup: state.ofcs[0].label
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              setData([])
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if ((context.logindata.utype === "att_user" && state.aptype === "Branch" && (state.ctyp === "TA" || state.ctyp === "STA")) || (state.aptype !== undefined && state.aptype === "Category")) {
        let fList = [];
        var branchcatName = "";
        if (state.branches && state.bid !== 0 && !state.enableDevices) {
          branches.push({ "id": state.data[0].value, "name": state.data[0].label });
          state.selBrnch.push({ "id": state.data[0].value, "name": state.data[0].label });
        } else if (state.categories && state.cat !== 0 && state.aptype === "Branch") {
          state.categories.map((obj) => {
            if (obj.label == state.cat) {
              branchcatName = obj.label;
              category.push({ "id": obj.value, "name": obj.label });
              branches = [];
              dispatch({ type: 'cat', payload: 0 })
            }
          });
        }
        if (state.compData && category.length === 0) {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                borcname:!state.enableDevices?state.data[0].label:"",
                //sup:state.ofcs[0].label
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
              if(!state.enableDevices){
              dispatch({ type: 'compData', payload: [] })
              setCompanyPick([])
              }
              setData([])
              // bid,ta_comp,companydata,staCompanies empting after adding one record
              dispatch({ type: 'staCompanies', payload: [] })
              dispatch({ type: 'bid', payload: 0 })
              dispatch({ type: 'ta_comp', payload: [] })
            }
          });
        }
      } else if (context.logindata.utype === "att_user" && category.length === 0) {
        let fList = [];
        state.compData.map((obj) => {
          if (obj.value) {
            const fare = {
              // applyon: applicable,
              borcname:!state.enableDevices? state.data[0].label:"",
              //sup: state.ofcs[0].label
            }
            state.selCompny.push({ "id": obj.value, "name": obj.label })
            companys.push({ "id": obj.value, "name": obj.label });
            fares.faresList.push(fare);
            setShowFareList({ faresList: fares.faresList });
          }
        });
      }
      if (category.length > 0) {
        const fare = {
          // applyon: applicable,
          borcname: branchcatName,
          //sup: state.ofcs[0].label
        }
        state.selCompny.push({ "id": context.logindata.cid, "name": context.logindata.cnm })
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }
      //Seeting Pax datat
      // let paxData = [];
      // if (applicable === "Per Booking") {
      //   paxData = perBooking.perBookings;
      // } else {
      //   paxData = perTicket.perTickts;
      // }
      var companyType = "";
      if (state.aptype === "Branch") {
        companyType = state.ctyp;
      } else {
        companyType = "";
      }
      //For User type as TA
      if (context.logindata.utype === "ta_user") {
        if (state.ctyp === "TA") {
          const fare = {
            // applyon: applicable,
            company: context.logindata.cnm,
            compType: "TA"
          }
          state.selCompny.push({ "id":context.logindata.cid, "name": context.logindata.cnm })
          companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
          fares.faresList.push(fare);
          fares.faresList = fares.faresList.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === value.place && t.name === value.name
              )
          );
          setShowFareList({ faresList: fares.faresList });
        } else {
          state.compData.map((obj) => {
            if (obj.value) {
              const fare = {
                // applyon: applicable,
                company: obj.label,
                compType: "STA"
              }
              state.selCompny.push({ "id": obj.value, "name": obj.label })
              companys.push({ "id": obj.value, "name": obj.label });
              fares.faresList.push(fare);
              setShowFareList({ faresList: fares.faresList });
            }
          });
        }
      } if (context.logindata.utype === "sta_user") {
        const fare = {
          // applyon: applicable,
          company: context.logindata.cnm,
          compType: "STA"
        }
        state.selCompny.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        companys.push({ "id": context.logindata.cid, "name": context.logindata.cnm });
        fares.faresList.push(fare);
        setShowFareList({ faresList: fares.faresList });
      }

      if (context.logindata.utype === "att_user") {
      //   dispatch({ type: 'disableOfc', payload: true })
        if (!state.enabeleEdit) {
          dispatch({ type: 'bid', payload: branches })      
          dispatch({ type: 'cmpid', payload: companys })      
          //     createFareMapRequest(paxData, companyType, branches, companys, category);
        } else {
          dispatch({ type: 'bid', payload: branches })      
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFMUpdatedPayload(paxData, companyType, branches, companys, category);
        }
      } else if (context.logindata.utype === "ta_user") {
        if (!state.enabeleEdit) {
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFareMapTARequest(paxData, companys);
        } else {
          dispatch({ type: 'cmpid', payload: companys })  
      //     createFMUpdatedTAPayload(paxData, companys);
        }

      } else {
        if (!state.enabeleEdit) {
          dispatch({ type: 'cmpid', payload: companys }) 
      //     createFareMapSTARequest(paxData, companys);
        } else {
          dispatch({ type: 'cmpid', payload: companys }) 
      //     createFMUpdatedSTAPayload(paxData, companys);
        }
      }
      dispatch({ type: 'branchAdded', payload: true })
    }
  }

  /**
 * @author: Lakshmi
 * @description: This function will Delete the Sel Fare Map 
 * @date:14-09-2020
 * @function : deleteFareMap
 */
  const deleteFareMap = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      confirmBranchDlg(obj, 'Fare Map', function () {
        deletePromoFMap(obj.id).then(response => {
          if (response.data.suc) {
            setShowFareList({ faresList: fares.faresList.filter((s, sidx) => s.id !== obj.id) });
            // let companyList = markupFareArray;
            dispatch({ type: 'selCompny', payload:state.selCompny.filter((item, i) => item.name !== obj.company)})
            // markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in saveCompanyDetails function---" + error
          );
        });
      });
    } else {
      setShowFareList({ faresList: fares.faresList.filter((s, sidx) => idx !== sidx) });
      dispatch({ type: 'selCompny', payload: state.selCompny.filter((item, i) => item.name !== obj.company) })
      let companyList = markupFareArray;
      markupFareArray = [];
      markupFareArray.push(companyList.filter(item => item.name !== undefined && item.name.trim() !== obj.company.trim()));
    }
  }

const handleCancel = () => {
  return (window.location = "/ezytrip/administration/promocode");
};
const handleReset = () => {
  dispatch({ type: 'clear', payload: true })};
function hidemessage() {
  setTimeout(function () {
    dispatch({ type: 'notiMessageShow', payload: false })
    dispatch({ type: 'notiMessage', payload: '' })
    dispatch({ type: 'notiVarient', payload: '' })
  }, 5000);
}

const validateMarkupConfig = () => {
  if (state.offer_nm === "") {
    dispatch({ type: "notiMessage", payload: "Please enter offer name english" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  } else if (state.offer_name_ar === ""){
    dispatch({ type: "notiMessage", payload: "Please enter offer name arabic" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  } else if (state.pro_code === ""){
    dispatch({ type: "notiMessage", payload: "Please enter promo code" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.offer_desc_en == ""){
    dispatch({ type: "notiMessage", payload: "Please enter offer description english" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.offer_desc_ar == ""){
    dispatch({ type: "notiMessage", payload: "Please enter offer description arabic" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.rts.length == 0 ){
    dispatch({ type: "notiMessage", payload: "Please please select routes" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.airlines.length==0){
    dispatch({ type: "notiMessage", payload: "Please select airlines" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  } else if (dates.datesList.length === 0) {
    dispatch({type: "notiMessage",payload: "Please select applicable dates"});
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (classState.class.length == 0){
    dispatch({ type: "notiMessage", payload: "Please select RBD" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.sales_channel == ""){
    dispatch({ type: "notiMessage", payload: "Please select sales channel" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.region_type == "" ){
    dispatch({ type: "notiMessage", payload: "Please select region type" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.supp.length == 0 ){
    dispatch({ type: "notiMessage", payload: "Please select supplier" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.cpnfor == "" ){
    dispatch({ type: "notiMessage", payload: "Please select coupon applicable to" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.max_user_allow == "" && state.cpnfor[0].label=="Registered User"){
    dispatch({ type: "notiMessage", payload: "Please enter utilization per user" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  // }else if (state.no_bkng_allow == "" ){
  //   dispatch({ type: "notiMessage", payload: "Please enter no of booking allowed" });
  //   dispatch({ type: "notiVarient", payload: "danger" });
  //   dispatch({ type: "notiMessageShow", payload: true });
  //   hidemessage()
  //   return false;
}else if (state.max_bkng_allow == ""){
  dispatch({ type: "notiMessage", payload: "Please enter max booking allowed" });
  dispatch({ type: "notiVarient", payload: "danger" });
  dispatch({ type: "notiMessageShow", payload: true });
  hidemessage()
  return false;
}else if (Number(state.max_bkng_allow)<Number(state.max_user_allow)){
  dispatch({ type: "notiMessage", payload: "Utilization per user should be less than or equal to max booking allowed" });
  dispatch({ type: "notiVarient", payload: "danger" });
  dispatch({ type: "notiMessageShow", payload: true });
  hidemessage()
  return false;
  }else if (state.min_purchase === "" ){
    dispatch({ type: "notiMessage", payload: "Please enter minimum purchase" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.budget_permit == ""){
    dispatch({ type: "notiMessage", payload: "Please enter offer budget" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (Number(state.min_purchase)>Number(state.budget_permit)){
    dispatch({ type: "notiMessage", payload: "offer budget should be greater than or equal to minimum purchase" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.offer_det_ar == "" ){
    dispatch({ type: "notiMessage", payload: "Please enter offer details AR" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.offer_det_en == "" ){
    dispatch({ type: "notiMessage", payload: "Please enter offer details EN" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.disc_type.length == 0 ){
    dispatch({ type: "notiMessage", payload: "Please select discount type" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.disc_on.length == 0 ){
    dispatch({ type: "notiMessage", payload: "Please select discount on" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.disc == "" ){
    dispatch({ type: "notiMessage", payload: "Please enter discount" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.sales_channel== "B2B" && state.selBrnch==""){
    dispatch({ type: "notiMessage", payload: "Please select branch" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (state.selCompny==""){
    dispatch({ type: "notiMessage", payload: "Please select company" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }else if (!state.isDeviceAll && !state.isB2cWeb && !state.isB2cApp &&!state.isMWeb){
    dispatch({ type: "notiMessage", payload: "Please select applicable type" });
    dispatch({ type: "notiVarient", payload: "danger" });
    dispatch({ type: "notiMessageShow", payload: true });
    hidemessage()
    return false;
  }
  return true;
}
  const handleSavePromo = (e) => {
  e.preventDefault();
  if (validateMarkupConfig()) {
        var aircodes = "";
        if (state.airlines) {
          state.airlines.map((obj) => {
            if (aircodes) {
              aircodes = aircodes + "," + obj.code;
            } else {
              aircodes = obj.code;
            }
          });

        }
        const payload = {
          offer_nm: state.offer_nm,
          offer_name_ar: state.offer_name_ar,
          pro_code:state.pro_code,
          offer_desc_en:state.offer_desc_en,
          offer_desc_ar:state.offer_desc_ar,
          max_bkng_allow:state.max_bkng_allow,
          max_user_allow:state.max_user_allow,
          budget_permit:state.budget_permit,
            ttype: state.ttype,
            utype: context.logindata.utype,
            // ltype: context.logindata.utype,
            al: aircodes,
            act: state.act,
            ctyp:state.ctyp,
            aptype:state.aptype,
            cmpid:state.selCompny,
            bid:state.selBrnch,
            class:classState.class,
            apdate: dates.datesList,
            rts: state.rts,
            region_type:context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
            sales_channel:context.logindata.utype==="att_user"?state.sales_channel:context.logindata.channel,
            office_id:context.logindata.utype==="att_user"?(state.office_id[0]!=undefined?state.office_id[0]?.value.toString():""):"All",
            crby: context.logindata.uid,
            supp:state.supp[0]?.value,
            cpnfor:state.cpnfor[0]?.value,
            no_bkng_allow:state.no_bkng_allow,
            min_purchase:state.min_purchase,
            offer_det_ar:state.offer_det_ar,
            offer_det_en:state.offer_det_en,
            disc_type:state.disc_type[0]?.value,
            disc_on:state.disc_on[0]?.label,
            disc:state.disc,
            isDeviceAll:state.isDeviceAll,
            isB2cWeb:state.isDeviceAll?true:state.isB2cWeb,
            isB2cApp:state.isDeviceAll?true:state.isB2cApp,
            isMWeb:state.isDeviceAll?true:state.isMWeb,
        };
        creation(payload)
          .then((response) => {
            if (response.suc) {
              dispatch({
                type: "notiMessage",
                payload: "Promo code saved successfully",
              });
              dispatch({ type: "notiVarient", payload: "success" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
              return (window.location = "/ezytrip/administration/promocode");
            } else {
              dispatch({ type: "isItineraryLoading", payload: false });
              dispatch({
                type: "notiMessage",
                payload: "Promo code not saved..",
              });
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "notiMessageShow", payload: true });
              hidemessage()
            }
          })
          .catch(function (error) {
            log.error(
              "Exception occured in saveCompanyDetails function---" + error
            );
          });
        dispatch({ type: "showMessage", payload: false });
      // } else {
      //   dispatch({ type: "notiMessage", payload: " Already Exist" });
      //   dispatch({ type: "notiVarient", payload: "danger" });
      //   dispatch({ type: "notiMessageShow", payload: true });
      //   hidemessage()
      // }
    // }
  }
};
const updatePromo = (e) => {
  e.preventDefault();
  if (validateMarkupConfig()) {
  var aircodes = "";
        if (state.airlines) {
          state.airlines.map((obj) => {
            if (aircodes) {
              aircodes = aircodes + "," + obj.code;
            } else {
              aircodes = obj.code;
            }
          });
        }
  const payload = {
    id:state.update_id,
    offer_nm: state.offer_nm,
    offer_name_ar: state.offer_name_ar,
    pro_code:state.pro_code,
    offer_desc_en:state.offer_desc_en,
    offer_desc_ar:state.offer_desc_ar,
    max_bkng_allow:state.max_bkng_allow,
    max_user_allow:state.max_user_allow,
    budget_permit:state.budget_permit,
      ttype: state.ttype,
      utype: context.logindata.utype,
      // ltype: context.logindata.utype,
      al: aircodes,
      act: state.act,
      ctyp:state.ctyp,
      aptype:state.aptype,
      cmpid:state.selCompny,
      bid:state.sales_channel=="B2B"?state.selBrnch:[],
      class:classState.class,
      apdate: dates.datesList,
      rts: state.rts,
      region_type:context.logindata.utype==="att_user"?state.region_type:context.logindata.region_type,
      sales_channel:context.logindata.utype==="att_user"?state.sales_channel:context.logindata.channel,
      office_id:context.logindata.utype==="att_user"?(state.office_id[0]!=undefined?state.office_id[0]?.value.toString():""):"All",
      upby: context.logindata.uid,
      pk_fare_map_id:state.pk_fare_map_id,
      supp:state.supp[0]?.value,
      cpnfor:state.cpnfor[0]?.value,
      no_bkng_allow:state.no_bkng_allow,
      min_purchase:state.min_purchase,
      offer_det_ar:state.offer_det_ar,
      offer_det_en:state.offer_det_en,
      disc_type:state.disc_type[0]?.value,
      disc_on:state.disc_on[0]?.label,
      disc:state.disc,
      isDeviceAll:state.isDeviceAll,
      isB2cWeb:state.isB2cWeb,
      isB2cApp:state.isB2cApp,
      isMWeb:state.isMWeb,
    };
    update(payload).then((response)=>{
      if (response.suc) {
        dispatch({
          type: "notiMessage",
          payload: "Promo code updated successfully",
        });
        dispatch({ type: "notiVarient", payload: "success" });
        dispatch({ type: "notiMessageShow", payload: true });
        hidemessage()
        return (window.location = "/ezytrip/administration/promocode");
      } else {
        dispatch({ type: "isItineraryLoading", payload: false });
        dispatch({
          type: "notiMessage",
          payload: "Promo code not updated..",
        });
        dispatch({ type: "notiVarient", payload: "danger" });
        dispatch({ type: "notiMessageShow", payload: true });
        hidemessage()
      }
    })
  }
}

    return (
        <div>
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
        onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
        dismissible> {state.notiMessage}</Alert>
         <div className="contentArea cmnSearch">

          <Form.Row>
            <Form.Group as={Col} xs={2} className="selectplugin" controlId="offer_nm">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Offer Name" value={state.offer_nm} 
                 onChange={handleChange}
                  />
                 <Form.Label>Offer Name EN<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={2} className="selectplugin" controlId="offer_name_ar">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Offer Name Arbic" value={state.offer_name_ar} 
                 onChange={handleChange}
                  />
                 <Form.Label>Offer Name AR<sup>*</sup></Form.Label>
            </Form.Group>

            <Form.Group as={Col} xs={2} className="selectplugin" controlId="pro_code">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Offer Name" value={state.pro_code} 
                onChange={(e)=>(dispatch({type:"pro_code", payload:e.target?.value}))}
                  />
                 <Form.Label>Promo Code<sup>*</sup></Form.Label>
            </Form.Group>
          
         </Form.Row>      
          <Form.Row>
            <Form.Group as={Col} xs={2} className="selectplugin" controlId="offer_desc_en">
                  <Form.Control type="text" placeholder="Offer Description EN" value={state.offer_desc_en} 
                 onChange={handleChange}
                  />
                 <Form.Label>Offer Description EN<sup>*</sup></Form.Label>
            </Form.Group>

            <Form.Group as={Col} xs={2} className="selectplugin" controlId="offer_desc_ar">
                  <Form.Control type="text" placeholder="Offer Name" value={state.offer_desc_ar} 
                 onChange={handleChange}
                  />
                 <Form.Label>Offer Description AR<sup>*</sup></Form.Label>
            </Form.Group>
            </Form.Row>

            <Form.Row>
            <Form.Group as={Col} xs={4} controlId="ttype" className="inputCheck">
          <div className="d-flex align-items-center">
            <Form.Check
              type="radio"
              id="all"
              label="All"
              className="ml-0"
              name="ttype"
              custom
              checked={state.enableTripAll}
              value={state.ttype}
              onChange={handleTravelType}
            />
            <Form.Check
              type="radio"
              id="one"
              label="One Way"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripOne}
              value={state.ttype}
              onChange={handleTravelType}
            />
            <Form.Check
              type="radio"
              id="round"
              label="Round Trip"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripTwo}
              value={state.ttype}
              onChange={handleTravelType}
            />
            <Form.Check
              type="radio"
              id="multi"
              label="Multi City"
              className="ml-3"
              name="ttype"
              custom
              checked={state.enableTripThree}
              value={state.ttype}
              onChange={handleTravelType}
            />
          </div>
          <Form.Label>Travel Type</Form.Label>
        </Form.Group>
          
         </Form.Row>      
         {/* <Routes
        configType={"Promo"}
        updateData={updateRoutesData}
        sendRoutesData={rotesInfo}
        routesData={routDetails.rts}
        reset={state.reset}
        sendRouteDelData={routeDelete}
        disabledBtn={props.disableBtn}
      />
      <Form.Row>
                {state.airlinesList && state.airlinesList.length !== 0 &&
         <Form.Group as={Col} className="picklist" xs={6} >
                  <PickList leftPaneLabel="Available Airlines" rightPaneLabel="Selected Airlines" 
                  ofcsData={value} 
                  type={state.compType}  
                  dataList={state.airlinesList} 
                  sendData={setAir}
                   />
                <Form.Label>Airlines</Form.Label>
              </Form.Group>
                } 
      </Form.Row> */}

      <ApplicableDates
        configType={"promoCode"}
        sendAppDates={applicableDates}
        updateData={updateApplDates}
        applDatesData={state.edit_applDates}
        reset={state.reset}
        sendApplicableId={callDeleteApp}
        disabledBtn={props.disableBtn}
      /> 
   
      <h4 className="subTitle plusHr">Promocode</h4>
      <div className="promoDetailscr">
      <Form.Row>
      <SalesChannel sendSalesChanel={updateSalesChanel} 
      edit_sales={state.edit_salesChanel}
      />
          <RegionType sendResponse={(e)=>handleRegionType(e)} 
          regionType={updateRegion} 
          regionUpdate={state.edit_region} 
          callBranch={state.enableDevices} flowType={"promoCode"}
          resetRegion={state.resetRegion} />

         {/* <Form.Group as={Col} xs={3} className="selectplugin" controlId="supp">
          <Select
          disabled= {state.enableUpdate}
            options={supplier}
            values={state.supp}
            // onChange={updateSupplier}
            onChange={(value) => (dispatch({ type: "supp", payload: value }), dispatch({type: "supplierError", payload: false}),updateSupplier(value))}
          />
            {state.supplierError && (
              <p className="vError">Please Select Supplier</p>
            )}
          <Form.Label>Supplier <sup>*</sup></Form.Label>
         </Form.Group>

          {state.enableOfcId &&<Form.Group
            as={Col}
            xs={3}
            className="selectplugin"
            controlId="office_id"
           >
          <Select
          disabled= {state.edit_region!=""?true:false}
            options={state.officeIds}
            values={state.office_id}
            onChange={updateOfcId}
          />
          <Form.Label>
            Office Id <sup>*</sup>
          </Form.Label>
        </Form.Group>} */}
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} xs={3} className="selectplugin" controlId="cpnUser">
            <Select 
            options={cpnUser}
            values={state.cpnfor}
            onChange={handleCoupon}
            disabled={state.enableUpdate}
            />
            <Form.Label>Coupon Applicable to <sup>*</sup></Form.Label>
           </Form.Group>
           {state.cpnfor[0]?.label && state.cpnfor[0]?.label=="Registered User"&&
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="max_user_allow">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Utilization per Booking" value={state.max_user_allow} 
                onChange={handleChange}
                  />
                 <Form.Label>Utilization per User<sup>*</sup></Form.Label>
            </Form.Group>}
           <Form.Group as={Col} xs={3} className="selectplugin" controlId="max_bkng_allow">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Max Booking Allowed" value={state.max_bkng_allow} 
                onChange={handleChange}
                  />
                 <Form.Label>Max Booking Allowed<sup>*</sup></Form.Label>
            </Form.Group>

           <Form.Group as={Col} xs={3} className="selectplugin" controlId="min_purchase">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Offer Name" value={state.min_purchase} 
                onChange={handleChange}
                  />
                 <Form.Label>Minimum Purchase<sup>*</sup></Form.Label>
            </Form.Group>
           <Form.Group as={Col} xs={3} className="selectplugin" controlId="budget_permit">
                  <Form.Control disabled={state.enableUpdate} type="text" placeholder="Offer Budget" value={state.budget_permit} 
                onChange={handleChange}
                  />
                 <Form.Label>Offer Budget<sup>*</sup></Form.Label>
            </Form.Group>

      </Form.Row>
      
      <Form.Row>
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
              <Select
                options={discount_typesList}
                values={state.disc_type}
                onChange={(value) => (dispatch({ type: 'disc_type', payload: value }), dispatch({ type: "disc_typeError", payload: false }))}
                disabled={state.enableUpdate}
              />
              {state.comissionTypeError && (
                <p className="vError">Please Select Discount Type</p>
              )}
              <Form.Label>Discount Type <sup>*</sup></Form.Label>
            </Form.Group>


            {/* Comission On */}
            <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
              <Select
                options={disc_onList}
                values={state.disc_on}
                onChange={(value) => (dispatch({ type: 'disc_on', payload: value }), dispatch({ type: "disc_onError", payload: false }))}
                disabled={state.enableUpdate}
              />
              {state.comissionOnError && (
                <p className="vError">Please Select Discount On</p>
              )}
              <Form.Label>Discount On <sup>*</sup></Form.Label>
            </Form.Group>

            {/* Comission value */}
            <Form.Group as={Col} xs={3} controlId="disc">
              <Form.Control type="number" disabled={state.enableUpdate} autoComplete="off" placeholder="Discount" value={state.disc} onChange={handleChange} />
              <Form.Label>Discount<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="offer_det_en">
                  {/* <Form.Control as="textarea" placeholder="Offer Name" value={state.offer_det_en} 
                onChange={handleChange}
                  /> */}
            <TextEditor textType={"Overview_EN"} sendOverview_EN={(e)=>updateOverview(e, "en")} enableUpdate={state.enableUpdate} updateOverEn={state.edit_offer_det_en} />
                 <Form.Label>Offer Details EN<sup>*</sup></Form.Label>
            </Form.Group>
           <Form.Group as={Col} xs={3} className="selectplugin" controlId="offer_det_ar">
                  {/* <Form.Control as="textarea" placeholder="Offer Name" value={state.offer_det_ar} 
                onChange={handleChange}
                  /> */}
            <TextEditor textType={"Overview_AR"} sendOverview_AR={(e)=>updateOverview(e, "ar")} enableUpdate={state.enableUpdate} updateOverAr={state.edit_offer_det_ar} />
                 <Form.Label>Offer Details AR<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>

        
          {/* <h4 className="subTitle plusHr">RBD</h4> */}
          
            {/* <Form.Row> */}
              {/* <Form.Group as={Col} xs={3} className="selectplugin" controlId="cls_id">
                <Select
                  options={cls}
                  values={state.classType}
                  onChange={(value) => (dispatch({ type: 'classType', payload: value }), dispatch({ type: 'type', payload: value.length > 0 && value[0].value }))}
                />
                <Form.Label>Class <sup>*</sup></Form.Label>
              </Form.Group> */}

              {/* <Form.Group as={Col} xs={3} className="selectplugin" controlId="selectedValue">
                <Select
                  multi
                  options={state.rbdMaster}
                  values={state.classCode}
                  onChange={(value) => ( setCls(value))}
                />
                <Form.Label>RBD</Form.Label>
              </Form.Group> */}
              {/* <Form.Group as={Col}>
                {/* {!disableButton && */}
                  {/* <Button
                    size="xs"
                    variant="outline-primary"
                    onClick={handleClses}
                  > Add</Button>  */}
                {/* } */}
                {/* {disableButton &&
                  <Button
                    size="xs"
                    variant="outline-primary"
                    isDisabled={true}
                  > Add</Button>
                } */}
              {/* </Form.Group> */}
            {/* </Form.Row> */}
          
          {/* {classState.class.length !== 0 && <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="selectedRout tableLayout">
                <Table>
                  <thead>
                    <tr>
                      <th>Class*</th>
                      <th>RBD</th>
                      <th width="8%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classState.class.map((cl, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <span>{cl.cls}</span>
                          </td>
                          <td>
                            <span>{cl.rbd}</span>
                          </td>
                          <td>
                            <Button
                              size="xs"
                              onClick={handleRemoveClass(cl, index)}
                              // isDisabled={(props.disabledBtn) ? true : false}
                             variant="link" className="ezyIcon icon-delete">
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Form.Row>} */}

          <Form.Row>
            {state.userType == "att_user" &&
              <Form.Group as={Col}  lg={3} md={6} controlId="classes" className="inputCheck ">

                <div className="d-flex align-items-center">
                  {/* <Form.Check type="radio" label="Branch" onChange={handleAppTypeChange} value={state.aptype} checked={enApplicable} custom name="applicableTo" className="ml-0" id="branch" /> */}
                  {/* <Form.Check type="radio" label="Category" onChange={handleAppTypeChange} value={state.aptype} checked={state.aptype !== "Branch"} custom name="applicableTo" id="category" /> */}
                  <Form.Check disabled={state.enableUpdate} type="radio" label="Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={!sta} custom name="ctype" id="ta" />
                  <Form.Check disabled={state.enableUpdate} type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleCompTypeChange} checked={sta} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Applicable Type</Form.Label>
              </Form.Group>
            }
            <br/>


          {state.enableDevices &&
          <>
 <Form.Group as={Col} lg={4} md={6} className="inputCheck">
<Form.Label className='applType mr-2'>Applicable Type <sup>*</sup></Form.Label>
          <div className="d-flex align-items-center">
          <Form.Check
              type="checkbox"
              id="deviceAll"
              label="All"
              custom
              className="ml-0 mr-2"
              checked = {state.isDeviceAll}
              value={state.isDeviceAll}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="web"
              label="B2C Web"
              custom
              className="ml-0"
              checked = {state.isB2cWeb}
              value={state.isB2cWeb}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="app"
              label="B2C App"
              custom
              className="ml-3"
              checked={state.isB2cApp}
              value={state.isB2cApp}
              onChange={handleDeviceTypeChange}
            />
            <Form.Check
              type="checkbox"
              id="mweb"
              label="M Web"
              custom
              className="ml-3"
              checked={state.isMWeb}
              value={state.isMWeb}
              onChange={handleDeviceTypeChange}
            />
             </div>
             </Form.Group>
            </>

          }




            {state.userType === "ta_user" &&
              <Form.Group as={Col} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} onChange={handleTypeChange} checked={companyType} custom name="ctype" id="ta" />
                  <Form.Check type="radio" label="Sub Travel Agent" value={state.ctyp} onChange={handleTypeChange} checked={!companyType} custom name="ctype" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
            {state.userType === "sta_user" &&
              <Form.Group as={Col} xs={2} controlId="ctyp" className="inputCheck">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label={context.logindata.cnm} value={state.ctyp} checked={companyType} custom name="ctype" className="ml-3" id="sta" />
                </div>
                <Form.Label>Company Type</Form.Label>
              </Form.Group>
            }
          </Form.Row>
          <Form.Row>
            {!state.enableDevices && state.enbleType && state.compType && state.userType === "att_user" && !sta && (state.ctyp !== "TA" && state.ctyp !== "STA") &&

              <Form.Group as={Col} className="picklist" xs={7} >
                {state.branches && state.branches.length !== 0 &&
                  <PickList leftPaneLabel="Available Branch Names" rightPaneLabel="Selected Branch Names" type={state.compType} selData={data} dataList={state.branches} sendData={pickldata} />
                }
                <Form.Label>Branch Names</Form.Label>
              </Form.Group>

            }
            {state.branchMenu.length!==0 && context.logindata.utype !== "ta_user"&& context.logindata.utype !== "sta_user" && !state.enableDevices &&
              <Form.Group as={Col} xs={3} controlId="bid">
                <Form.Control as="select" value={state.bid} onChange={handleBranchChange}>
                  <option value={state.data}>Select</option>
                  {state.branchMenu}
                </Form.Control>
                <Form.Label>Branch<sup>*</sup></Form.Label>
              </Form.Group>
             } 

            {!state.showCat && sta && companydata && companydata.length!==0 &&
              <Form.Group as={Col} xs="3" className="selectplugin" controlId="cn">
                <Select
                  options={companydata}
                  id="class"
                  isSearchable={false}
                  disabled={state.disabled}
                  values={state.ta_comp}
                  onChange={(value) => (handleStaChange(value))}
                />
                <Form.Label>Ta Company</Form.Label>
              </Form.Group>
            }
            {companydata && companydata.length !== 0 &&  !sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            {state.staCompanies && state.staCompanies.length !== 0 && state.userType === "att_user" && sta &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={state.staCompanies} sendData={compdata} />
                <Form.Label>STA Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }

            {!companyType && companydata && companydata.length !== 0 && state.userType === "ta_user" &&

              <Form.Group as={Col} className="picklist" xs={7}>
                <PickList leftPaneLabel="Available Company Names" rightPaneLabel="Selected Company Names" type={state.compType} selData={data} dataList={companydata} sendData={compdata} />
                <Form.Label>Company Name<sup>*</sup></Form.Label>
              </Form.Group>

            }
            <Form.Group as={Col} xs={2}>
              {/* {!state.enableUpdate && !disableButton && (state.userType === "ta_user" || state.officeIds?.length!==0) && */}
                <Button
                  size="xs"
                  variant="outline-primary"
                  onClick={showMarkupFares}
                > Add</Button>
              {/* } */}
              {disableButton &&
                <Button
                  size="xs"
                  variant="outline-primary"
                  isDisabled={true}
                > Add</Button>
              }
            </Form.Group>
          </Form.Row>

          </div>
          {fares.faresList.length !== 0 &&
        <div className="tableLayout">
          <Table>
            <thead>
              <tr>
                {/* <th>Applicable On</th> */}
                {state.userType === "att_user" &&
                  <th>Branch</th>
                }
                <th>Company Name</th>

                <th className="text-right" width="8%">Actions</th>
              </tr>
            </thead>
            <tbody>
              {fares.faresList.map((fare, index) => (
                <React.Fragment key={index}>
                  <tr>
                    {/* <td>
                      <span>{fare.applyon}</span>
                    </td> */}
                    {state.userType === "att_user" &&
                      <td>
                        <span>{fare.borcname!==""?fare.borcname:"N/A"}</span>
                      </td>
                    }
                    <td>
                      <span>{fare.company}</span>
                    </td>
                    <td className="text-right">
                      {/* {
                        <Button
                          size="xs"
                          variant="link"
                          onClick={editFareMap(fare)}
                          isDisabled={false}
                          className="ezyIcon icon-edit"
                          type="submit">
                        </Button>
                      } */}
                      {!props.disableBtn && !state.hideDelete && fares.faresList.length >1  &&
                        <Button
                          size="xs"
                          variant="link"
                          onClick={deleteFareMap(fare, index)}
                          // isDisabled={(props.disabledBtn) ? true : false}
                          className="ezyIcon icon-delete"
                          type="submit">
                        </Button>
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      }
          
      </div>
      <div className="buttonGrop mt-4 pb-0 switchPlus">
            <Form.Group as={Col} className="topSwitch">
            <Form.Check
              type="switch"
              id="act"
              label="Status"
              checked={state.act}
              value={state.act}
              onChange={handleChange}
              />
          </Form.Group>
              {state.enableUpdate && !props.disableBtn && (
                <div className="text-right">

                  <Button
                    // disabled = {!(context.logindata.roles && context.logindata.roles.split(",").includes('H_M_U'))}
                    size="xs"
                    className="mr-2"
                    onClick={updatePromo}
                    variant="outline-primary"
                    type="submit"
                  >
                    Update
                  </Button>
                 </div>
               )} 
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={handleCancel}
        >
          Back
        </Button>
        {!state.enableUpdate && !props.disableBtn &&
          <Button
            size="xs"
            variant="outline-secondary"
            type="submit"
            onClick={handleReset}
          >
            Reset
        </Button>
         }
        {!state.enableUpdate && !props.disableBtn && (
          <Button
            size="xs"
            onClick={handleSavePromo}
            variant="outline-primary"
            type="submit"
          >
            Save
          </Button>
        )} 
      </div>
         </div>
    );
};

export default PromoCode;