
import React, { useState, useEffect}from 'react';
import ItemsCarousel from 'react-items-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row,Carousel,CarouselItem , Modal} from "react-bootstrap";
import aboutBanner from "../../assets/images/adminBanner.jpg";
import whyBanner from "../../assets/images/adminBanner2.jpg";
import Trendingimage from "../../assets/images/discoverdubai.png"
import Trendingimageone from "../../assets/images/maldives.png"
import Leftimage from "../../assets/images/left.png";
import Rightimage from "../../assets/images/right.png";
import DashBordLoginHeader from '../common/dashBoard/DashBordLoginHeader';
import Footer from '../common/Footer';
import Aboutusdotimg from "../../assets/images/dootedimg.png"
import {getAllPackages} from './operations';
import PackageDetails from './PackageDetails';
import ScrollAnimation from 'react-animate-on-scroll';


/**
 * @description:This Login pageLayout
 * @author: Pravas
 * @param {*}
 * @function Login details
 * @date : 21-09-2020
 */

function DashBoardLogin(props) {
  //const [state, setState] = useState({})
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [response, setResponse] = useState([]);
  const  [showPackage , setShowPackage] = useState(false)
  const  [packageObj,setPackageObj] =useState("")
  const chevronWidth = 40;

  useEffect(() => {
    getAllPackages().then(response => {
      console.log('Package response', response)
      if (response.suc &&  response.res) {
        let resp=[];
        for(let val of response.res){
          if(val.cha_b2b && val.image!=="" && val.image!==null && val._id!==null){
            resp.push(val)
          }
        }
        setResponse(resp)
      }
    }).catch(function (error) {
      console.log('Package response error ', error)
    });
  }, [])

const openPackagePopup=(obj)=>{
  setShowPackage(true);
  setPackageObj(obj)
}

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const trendinsettings = {
    dots: true,
    infinite: response.length > 3,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <DashBordLoginHeader />
      <section className="bodyContent" id="aboutUs">
        <div className="container">
          <div className="dashBordLayout dashBoardAbout pt-4">
            <Row>
              <Col xs={4} className="aboutLeftcont">

                <h2 className="titleLine"><span>About Us</span></h2>

                <p>Ezytrip is the first Qatari online
B2B travel solution,specialized in facilitating unforgettable travel experiences.</p>


              </Col>
              <Col xs={8}>

                <div className="aboutContent pt-5">
                 <p>As a part of Tawfeeq Travel group, we leverage over a decade of experience in the travel and tourism industry to provide top-notch services.</p>

                 <p>We collaborate with travel partners worldwide to provide turnkey travel solutions to their customers. Ezytrip offers all the tools and support you need to manage and grow your travel business without hassles. Our goal is to build mutually beneficial business relationships based on professionalism, transparency, and quality service delivery</p>

                 <p>At Ezytrip, we work closely with stakeholders in our industry vertical to provide the best deals for our partners. We deliver all necessities and luxuries required to make traveling a pleasurable experience, from airport pickup to airline ticketing, hotel booking, chauffeur services, and much more.
</p>

<p>Whether your clients are travelling on vacation, honeymoon, a business trip, or attending an event, we have a perfect package that aligns with various fun and business travel objectives. Our team is well prepared to cater to your client’s unique needs ranging from accessibility, diet, and language, to provide an unforgettable travel experience.</p>
 </div>

              </Col>
            </Row>
          </div>

        </div>

        <div className="dashBordLayout dashBoardoffer pb-5" id="whatWeoffer">
           <div className="container">
           <h2 className="titleLine"><span>What We Offer For Your Business </span></h2>
           <span className="semiTitle">Our features and benefits are curated to suit the needs of every employee using Ezytrip.</span>
            <Row className="offerCardsec">
            <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-briefcase"></span>
                  <h3>Business Travel</h3>
                  <p>Our service takes care of all your client’s business travel needs, allowing them to forget about the hassles of travelling and focus on their business. We offer the best deals for single and group business travels. </p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-mountains"></span>
                  <h3>Tourism & Vacations</h3>
                  <p>We provide a wide range of tour and vacation packages ranging from adventurous to educational and many more. Even if you have a destination in mind, we’ll help your clients get the best vacation experience.</p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-today"></span>
                  <h3>Meeting & Events  </h3>
                  <p>Are your clients planning to attend meetings or organize events anywhere around the globe? We provide hassle-freetravel, from flight to event ticketing; we arrange the necessities that give your clients a pleasant travel experience.</p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-customer-service"></span>
                  <h3>Certified GSA Representatives</h3>
                  <p>Does your client’s need a unique travel product? We’ve got your back! Ezytrip is certified to represents and distributes world-class products like Hertz, Trafalgar, ClubMed, Rail Europe, Costa Cruises in the Qatar market.</p>
                </div>
              </Col>
            </Row>
             </div>
          </div>
      </section>


      <section className="whyEzytrip dashBordLayout pb-5" id="whyChoose">
        <div className="container">
        <h2 className="titleLine"><span>Why Choose Us?</span></h2>
        <span className="semiTitle">We offer the best deals for single and group business travels.</span>
        <Slider {...settings}>
          <div>
          <div className="list">
            <div className="circleOut">
                <span className="starOne ezyIcon icon-Transparent-Services"></span>
                </div>
                <div>
                <span>01</span>
                   <h3>Transparent Services</h3>
                  <p>Our partners retain the freedom to maintain their brand identity as we provide services for their clients transparently.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="dashboardOne ezyIcon icon-agentportal"></span>
                </div>
                <div>
                <span>02</span>
                  <h3>Agent Portal</h3>
                  <p>Get access to our agents online portal with leading-edge features for managing your travel and tourism business.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="priceTag ezyIcon icon-Competitive-Pricing"></span>
                </div>
                <div>
                <span>03</span>
                   <h3>Competitive Pricing </h3>
                  <p>All our products and services carry a reasonable price tag. We also give you the freedom to set the price for your customers.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="easy ezyIcon icon-Extensive-Experience"></span>
                </div>
                <div>
                <span>04</span>
                  <h3>Extensive Experience</h3>
                  <p>Our team has extensive experience and the industry-specific network required to deliver top-notch services.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="comprehensive ezyIcon icon-service"></span>
                </div>
                <div>
                <span>05</span>
                  <h3>Comprehensive Services</h3>
                  <p>Our comprehensive services cover all the travel and tourism services your clients need and much more.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="brand ezyIcon icon-easy"></span>
                </div>
                <div>
                <span>06</span>
                   <h3>Reliable Brand</h3>
                  <p>Ezytrip is a reliable brand with global partnerships and serving over 40 travel agencies in Qatar.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="support ezyIcon icon-supportus"></span>
                </div>
                <div>
                <span>07</span>
                   <h3>24/7 Support</h3>
                  <p>Travel business is hectic and time-sensitive. That is why our support staff are available to serve you 24/7.</p>
                </div>
              </div>
          </div>
        </Slider>



         </div>
        </section>
        {response.length!==0 &&
        <section className="trendingPackages dashBordLayout pb-5" id="trendingPackage">
        <div className="container">

        <div className="trendingItemscont">
        <h2 className="titleLine"><span>Riyadh Season</span></h2>

         <span className="semiTitle">Riyadh Season has become one of Saudi’s favourite festivals since its successful launch in 2019. This year it returns bigger than ever with a total of 7,500 days of activities and 14 unmatched zones. Last year, we asked you to imagine and this year we’re asking you to IMAGINE MORE and to travel beyond your imagination; because the exciting surprises, events and activities we have in store for you will make this season a citywide extravaganza curated for all age groups
</span>
         </div>

       <div className="sliderItem">
        {/* <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<button><img src={Rightimage} /></button>}
        rightChevron={<button><img src={Leftimage} /></button>}
        outsideChevron
        chevronWidth={chevronWidth}
      >
         {response.map((pack, sidx) => (
             <React.Fragment>
                  <div className="slideImg">
                    <div className="newpackageDetails">
                    <span  className="placeName">{pack.package_name_en}<br/> <span className="duration">{pack.noofDays}NIGHTS / {pack.noofDays+1}DAYS</span></span>
                     <span className="price"><em>QAR</em> {pack.amt} </span>
                     </div>
                    <img
                      src={pack.image}
                      title=""
                      alt=""
                    />
              </div>
              </React.Fragment>
        ))}
      </ItemsCarousel> */}

<Slider {...trendinsettings}>
         {response.map((pack, sidx) => (
             <React.Fragment>
                  <div className="slideImg">
                    <img
                      src={pack.image}
                      title=""
                      alt=""
                      onClick={()=>openPackagePopup(pack)}
                    />
              </div>
              <div className="newpackageDetails d-flex justify-content-between">
                    <span  className="placeName" title={pack.package_name_en}>{pack.package_name_en}</span>
                     {/* <span className="price"><span>QAR</span> {pack.amt} </span> */}
                     </div>
              </React.Fragment>
        ))}
      </Slider>
     </div>
         </div>
        </section>
     }

      <Modal
        show={showPackage}
        onHide={()=>setShowPackage(false)}
        className="importPNRMdl pacakgeDetailspop"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <PackageDetails selpackage={packageObj} />
          </div>
        </Modal.Header>
      </Modal>

      <Footer />
    </>
  )
}
export default DashBoardLogin