import { apiGetHelper } from '../../../../components/commonUtils/helper';
import BranchCreationService from '../../../../services/administration/BranchCreationService';
/**
 * @description: CRUD operations for Branch
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */


// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
const API_URL = process.env.REACT_APP_B2B_URL

export const creation = async (obj, uid, isact) => {
    obj.contacts.forEach(v => delete v.cid);
    let finalRes = {};
    const saveObj = {
        bc: obj.bc, bn: obj.bn, cur: obj.cur, beml: obj.beml, bphc: obj.bphc, bph: obj.bph,
        bgeol: obj.bgeol, badd: obj.badd, lm: obj.lm, zc: obj.zc, cnt: obj.cnt, cty: obj.cty, url: obj.url, act: isact,
        bdes: obj.bdes, crby: uid, cntnm: obj.cntnm, ctynm: obj.ctynm, ccinfo: obj.contacts
    };
    await BranchCreationService.saveBranch(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Delete Operation
//  This function will call the service to delete the selected branch
// ========================================================================
export const deletion = async (obj) => {
    let finalRes = {};
    if (obj !== "") {
        await BranchCreationService.deleteBranch(obj.id).then(response => {
            finalRes = response.data;
        })
    }
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Branch Update Operation
//  This function will call the service to delete the selected branch
// ========================================================================
export const update = async (obj, isact) => {
    const updateObj = {
        id: obj.id, bc: obj.bc, bn: obj.bn, cur: obj.cur, beml: obj.beml, bphc: obj.bphc, bph: obj.bph,
        bgeol: obj.bgeol, badd: obj.badd, lm: obj.lm, zc: obj.zc, cnt: obj.cnt, cty: obj.cty, url: obj.url, act: isact,
        bdes: obj.bdes, crby: '', cntnm: obj.cntnm, ctynm: obj.ctynm, ccinfo: obj.contacts, upby: obj.upby
    };
    let finalRes = {};
    await BranchCreationService.updateBranch(updateObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
// ========================================================================
//  Branch edit Operation
//  This function retrives the selected values while clicking on Edit
// ========================================================================
export const edit = async (obj) => {
    let finalRes = {};
    await BranchCreationService.editBranch(obj.id).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
// All Branch Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const branchDataList = async (id) => {
    let branchesList = [];
    await BranchCreationService.getBrnchData(id).then(response => {
        if (response.data.suc) {
            branchesList = response.data.res;
        }
    });
    return Promise.resolve(branchesList);
}


// ========================================================================
// search branch
//  This function retrives the selected values while clicking on Edit
// ========================================================================
export const searchBranch = async (obj) => {
    let finalRes = {};
    await BranchCreationService.searchBranch(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
// search branch
//  This function retrives the selected values while clicking on Edit
// ========================================================================
export const getAllBranches = async (userId, userType, admin, region_type) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            userId: userId,
            userType: userType,
            is_admin:admin,
            region_type:region_type
        }
    }
    await apiGetHelper(`${API_URL}/att/branch/getAllBranches`, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);

    /* await BranchCreationService.getAllBranches().then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes); */
}





