import React, { useState, useReducer, useContext, useEffect } from 'react';
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import ShowHideAccordion from '../../../common/showHide/ShowHideAccordion'
import InsModifySearch from "./InsModifySearch";
import DateUtils from "../../../commonUtils/DateUtils";
import {fireSearch,checkSelPlan} from '../operations';
import Paymentprocessingimg from '../../../../assets/images/Insurance.gif'
import { QA } from 'country-flag-icons/react/3x2'
import { BH } from 'country-flag-icons/react/3x2'
import { AE } from 'country-flag-icons/react/3x2'
import { SA } from 'country-flag-icons/react/3x2'
import { OM } from 'country-flag-icons/react/3x2'
import { KW } from 'country-flag-icons/react/3x2'
import { EG } from 'country-flag-icons/react/3x2'
import { SD } from 'country-flag-icons/react/3x2'
import { DZ } from 'country-flag-icons/react/3x2'
import { IR } from 'country-flag-icons/react/3x2'
import { MA } from 'country-flag-icons/react/3x2'
import { YE } from 'country-flag-icons/react/3x2'
import { SY } from 'country-flag-icons/react/3x2'
import { SO } from 'country-flag-icons/react/3x2'
import { TN } from 'country-flag-icons/react/3x2'
import { JO } from 'country-flag-icons/react/3x2'
import { LY } from 'country-flag-icons/react/3x2'
import { LB } from 'country-flag-icons/react/3x2'
import { PS } from 'country-flag-icons/react/3x2'
import { DJ } from 'country-flag-icons/react/3x2'
import { KM } from 'country-flag-icons/react/3x2'
import { MR } from 'country-flag-icons/react/3x2'
import { IN } from 'country-flag-icons/react/3x2'
import { PK } from 'country-flag-icons/react/3x2'
import { LK } from 'country-flag-icons/react/3x2'
import { BD } from 'country-flag-icons/react/3x2'
import { KR } from 'country-flag-icons/react/3x2'
import { PH } from 'country-flag-icons/react/3x2'
import { ID } from 'country-flag-icons/react/3x2'
import { NP } from 'country-flag-icons/react/3x2'
import { BT } from 'country-flag-icons/react/3x2'

import { AT } from 'country-flag-icons/react/3x2'
import { RS } from 'country-flag-icons/react/3x2'
import { BG } from 'country-flag-icons/react/3x2'
import { RU } from 'country-flag-icons/react/3x2'
import { DE } from 'country-flag-icons/react/3x2'
import { FR } from 'country-flag-icons/react/3x2'
import { IT } from 'country-flag-icons/react/3x2'
import { ES } from 'country-flag-icons/react/3x2'
import { UA } from 'country-flag-icons/react/3x2'
import { PL } from 'country-flag-icons/react/3x2'
import { RO } from 'country-flag-icons/react/3x2'
import { NL } from 'country-flag-icons/react/3x2'
import { BE } from 'country-flag-icons/react/3x2'
import { CZ } from 'country-flag-icons/react/3x2'
import { GR } from 'country-flag-icons/react/3x2'
import { PT } from 'country-flag-icons/react/3x2'
import { SE } from 'country-flag-icons/react/3x2'
import { HU } from 'country-flag-icons/react/3x2'
import { BY } from 'country-flag-icons/react/3x2'
import { CH } from 'country-flag-icons/react/3x2'
import { DK } from 'country-flag-icons/react/3x2'
import { FI } from 'country-flag-icons/react/3x2'
import { SK } from 'country-flag-icons/react/3x2'
import { NO } from 'country-flag-icons/react/3x2'
import { IE } from 'country-flag-icons/react/3x2'
import { HR } from 'country-flag-icons/react/3x2'
import { MD } from 'country-flag-icons/react/3x2'
import { BA } from 'country-flag-icons/react/3x2'
import { AL } from 'country-flag-icons/react/3x2'
import { LT } from 'country-flag-icons/react/3x2'
import { MK } from 'country-flag-icons/react/3x2'
import { SI } from 'country-flag-icons/react/3x2'
import { LV } from 'country-flag-icons/react/3x2'
import { EE } from 'country-flag-icons/react/3x2'
import { ME } from 'country-flag-icons/react/3x2'
import { LU } from 'country-flag-icons/react/3x2'
import { MT } from 'country-flag-icons/react/3x2'
import { IS } from 'country-flag-icons/react/3x2'
import { AD } from 'country-flag-icons/react/3x2'
import { MC } from 'country-flag-icons/react/3x2'
import { LI } from 'country-flag-icons/react/3x2'
import { SM } from 'country-flag-icons/react/3x2'
import { TR } from 'country-flag-icons/react/3x2'



let countryCodes = require('../../../masterData/InsCountryMapping.json');

const _ = require('lodash')



//Initial State
const initialState = {
    loading: false,notiMessage: '', notiMessageShow: false, notiVarient: '',noresults:false,isItineraryLoading: true
}

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================



const reducer = (state, action) => {
    switch (action.type) {

        case 'test':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};
//This function will show the Air markups List and Add Markup
function InsResults(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [request, setRequest] = useState("");
    const [initsrequest,setinitsRequest] =useState(_.cloneDeep(props.location))
    const [show, setShow] = useState(false);
    const [isSport, setIsSport] = useState(false);
    const [load, setLoad] = useState(false);
    const [isCovid, setIsCovid] = useState(false);
    const [price, setPrice] = useState(0);
    const [entryType, setEntryType] = useState("");
    const [insuResp, setInsuResp] = useState("");
    const handleClose = () => setShow(false);
    const handleClosee = () => setShoww(false);
    const handleCloseee = () => setShowww(false);

    const handleShow = () => setShow(true);
    const [modify, setModify] = useState(false);
    const [showw, setShoww] = useState(false);
    const [showww, setShowww] = useState(false);

    const [modalData, setModalData] = useState(false);


    const history = useHistory();

useEffect(() => {
    if(props.location!==undefined && props.location.state!==undefined && props.location.state.req!==undefined && !props.location.state.req.modify){
        insureanceSearch(props.location.state.req);
        setLoad(true)
        setRequest(props.location.state.req)
    }
}, [props.location])

useEffect(() => {
    setLoad(false)
    if(props.location!==undefined && props.location.state!==undefined && props.location.state.req!==undefined && props.location.state.req.modify){
        setRequest("")
        setModify(false)
        dispatch({ type: 'isItineraryLoading', payload: true })
        insureanceSearch(props.location.state.req);
        setLoad(true)
        setRequest(props.location.state.req)
    }
}, [props.location])

const executeOnClick = (isExpanded) => {
}
const insureanceSearch=(req)=>{
   let travelType="";
    if(req.days<=90){
        travelType="001";
        setEntryType("Single Entry");
    }else{
        travelType="002";
        setEntryType("Multiple Entry");
    }
    let cntryCode=[]
    if(Number(req.bound_type)===1){
        cntryCode=countryCodes.res.filter((obj)=>obj.country_code!=="" && obj.country_code===req.src[0].countryCode);
    }
    const payload={
	"cust_type":"0",
	"bound_type" :req.bound_type,
	"trvl_type" : travelType,
	"trvl_days":req.days,
	"adts":req.adt,
	"childs":req.chd,
	"from_dt":DateUtils.convertStringToDate(req.srcDate),
    "insured_name": "Testing",
	"trvl_dest":"001",
    "res_country" :cntryCode!==undefined && cntryCode.length!==0?cntryCode[0].code:""
    }
    setLoad(true)
    fireSearch(payload).then((response) => {
      if(response !== undefined && response.suc) {
          for(let plan of response.res.plans){
            if(plan.optional_covers.length!==0){
               for(let opt of plan.optional_covers){
                   if(opt.name==="Winter Sports Extension"){
                    opt.isWinter=false;
                   }
                   if(opt.name==="Covid 19 extension"){
                    opt.isCovid=false;
                   }
               }
            }
          }
        setInsuResp(response.res)
        if(props.location.state.req.modify){
            setModify(true)
        }
        dispatch({ type: 'isItineraryLoading', payload: false })
        }else{
         dispatch({ type: 'noresults', payload: true })
         dispatch({ type: 'isItineraryLoading', payload: false })
        }
      });
}
const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

const selectPlan=(plan)=>{
    dispatch({ type: 'isItineraryLoading', payload: true })
  let selPlan=plan;
  if(selPlan.optional_covers.length!==0){
    let winterPlan=selPlan.optional_covers.filter((obj)=>obj.isWinter);
    let covidPlan=selPlan.optional_covers.filter((obj)=>obj.isCovid);
    if(winterPlan.length!==0 && covidPlan.length!==0){
        request.optional_covers=selPlan.optional_covers;
    }else if(winterPlan.length!==0){
        request.optional_covers=winterPlan;
        selPlan.optional_covers=winterPlan;
    }else if(covidPlan.length!==0){
        request.optional_covers=covidPlan;
        selPlan.optional_covers=covidPlan;
    }else{
        delete selPlan.optional_covers;
    }
  }
  request.premium_price=plan.basic_covers[0].premium;
  request.plan_name=plan.scheme_name;
  request.quote_no=insuResp.quote_no;
  request.entry_type=entryType;
  selPlan.quote_no=insuResp.quote_no;
  checkSelPlan(selPlan).then((response) => {
  if(response !== undefined && response.suc){
    request.response=response.res;
    history.push({
        pathname: '/ezytrip/bookings/insurance/review',
        state: {
          req: request
        }
      });
    }else{
        dispatch({ type: 'isItineraryLoading', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Selected Plan is not avaialable.Please choose another Plan'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
    }
  });

}


const handleOptionalChange=(obj,code)=>{
    for(let sheme of insuResp.plans){
         if(sheme.optional_covers.length!==0){
            if(sheme.scheme_code===code){
            for(let plan of sheme.optional_covers){
                if(plan.name==="Winter Sports Extension"){
                    if(!plan.isWinter){
                        setIsSport(true)
                        sheme.net_premium=Number(sheme.net_premium)+Number(obj.premium);
                        plan.isWinter=true;
                        break;
                    }else{
                        sheme.net_premium=Number(sheme.net_premium)-Number(obj.premium);
                        plan.isWinter=false;
                        setIsSport(false)
                        break;
                    }
                }
            }
          }
         }
    }
}


const handleOptionalChangeCovid=(obj,code)=>{
    for(let sheme of insuResp.plans){
         if(sheme.optional_covers.length!==0){
            if(sheme.scheme_code===code){
            for(let plan of sheme.optional_covers){
                    if(!plan.isCovid){
                        sheme.net_premium=Number(sheme.net_premium)+Number(obj.premium);
                        plan.isCovid=true;
                        setIsCovid(true)
                        break;
                    }else{
                        sheme.net_premium=Number(sheme.net_premium)-Number(obj.premium);
                        plan.isCovid=false;
                        setIsCovid(false);
                        break;
                    }
          }
          }
         }
    }
}
const handleShoww = (p) =>{
    setShoww(true)
    setModalData(p)
  };
const handleShowww = ()=>{
    setShowww(true)

}

const back=()=>{
window.location="/ezytrip/dashboard";
}

    return (
        <>
            <Header />
            {!modify && request!=="" && <InsModifySearch initreq={request} load={load} modify={false} />}
            {modify && request!=="" && <InsModifySearch initreq={request} load={load} modify={true} />}
            {state.isItineraryLoading ? (<div class="loaderPageBackdrop newiconLoader">
            <div class="loaderPage">
            <div className="backGroundgif">
<span className='backGroundbot'></span>
<img src={Paymentprocessingimg} />
<span className="searchingText">{props.location.state.req.bound_type === 0?<span>Searching the best<span className="otBound"> Outbound</span> Plans for you! </span>:<span>Searching the best<span className="otBound"> Inbound</span> Plans for you! </span>}</span>
<span className="waitingText">Please wait...</span>
            </div>
            </div>
            </div>
            ) : ""}

<div className="topnewDesign">
     <h3 className="dashBordTitle">
         <span>Choose the Best Plan for you</span>
      </h3>
      </div>
      <div className="container resultLayout mb-4">
<div className="newinsuDesign">

       <div className="chooseBestinsu mt-5 p-3">
      <Row>
      {insuResp!="" && insuResp.plans.length!==0 && insuResp.plans.map((plan,index)=>(

         <Col  xs={6}>
         <div className="insuranceResult">
            <div className="innerInsurancesd">
            <div className="innerInsurancesdscroller">


               <div className="firstSectiontop">
                  <Row>
                     <Col  xs={12}>
                     <Row>
                        <Col xs={7} className="middleBrd commanName">
                        <div className="roomInfo d-flex align-items-center ">
                           <span className="ezyIcon icon-plussheild"></span>
                           <div className="d-flex flex-column">
                           { plan.scheme_code==="5014"
                            ?<span className="insuranceName d-flex regTitle" >
                                <div  className="d-flex flex-row extrCursorp" onClick={()=>handleShoww(plan)}>{plan.scheme_name}  <span className="ezyIcon icon-info"></span></div>


                            </span>

                                  :(plan.scheme_code==="5005"?
                                  <span className="insuranceName d-flex regTitle" >
                                <div  className="d-flex flex-row extrCursorp" onClick={()=>handleShowww(plan)}>{plan.scheme_name} <span className="ezyIcon icon-info"></span></div>


                            </span>
                                  :
                                  <span className="insuranceName d-flex regTitle">
                                  {plan.scheme_name}
                            </span>

                            )
                                  }
                              <span className="insuranceName d-flex regTitle">

                              </span>
                              <span className="d-flex">[{entryType}]</span>
                           </div>
                        </div>
                        </Col>
                        <Col xs={5} className="commanNameprice">
                        <div className="roomInfo">
                           <div class="rs-price d-flex flex-row">
                              <div class="blockPrice">
                                 <div class="blockPriceInfo pb-2 mr-2">
                                    <div class="pricetag"><em>QAR </em><strong><span>{plan.net_premium}.00</span></strong></div>
                                 </div>
                              </div>
                              <div className="blockPrice">
                              <button onClick={()=>selectPlan(plan)}  class="btn-primary btn-sm selectFlightBtn ezyIcon btn btn-">Choose Plan</button>
                              </div>

                           </div>
                        </div>
                        </Col>
                     </Row>
                     </Col>
                  </Row>
               </div>
               <div className="contentInsubody">
                  <div className="travellInsurance">
                  <Row>
                  <Col className="pr-0 pl-0">
                    <div className="travelIns">
                     <div className="travellInsurance">

                    <div className="firstBlock">

                   {plan.basic_covers.map((basic,index)=>(
                     <>

                     <Row>
                     <Col xs={8}>
                     <div className="allPax-list icolistTop">
                     <span className="insuTitle">{basic.name}</span>
                     {basic.help!==null &&
                     <p className="impotantinfo toolTipInfo">
                     <span className="ezyIcon icon-info"></span>
                     <div className="toolTipCUstoom cnclInfo">
                     <p>{basic.help}</p>
                     </div>
                     </p>
                     }
                     </div>
                     </Col>
                     <Col xs={4}>
                     {/* <div className="secomBlock">

                                                        <span className="pax-price"><span>QAR </span> <strong> {basic.premium}.00</strong></span>


                                                    </div> */}
                     </Col>

                     </Row>


                     {basic.benefits.length!==0 && basic.benefits!==null &&
                     <div className='newBenefits'>
                        <ShowHideAccordion className="newBenefitsdrop" title='Benefits' icon='arrowDown'>
                        {basic.benefits.map((benfit,index)=>(
                           <div className='newbenfitsCont'>
                              <div className="listOfterr d-flex">
                                 <Col xs={8}>
                                 <div className="allPax-list">
                                    <span className="insuTitlee">{benfit.benefitsDesc}</span>
                                    <p className="impotantinfo toolTipInfo">
                                       <span className="ezyIcon icon-info"></span>
                                    <div className="toolTipCUstoom cnclInfo">
                                       <p>{benfit.benefitsHelp}</p>
                                    </div>
                                    </p>
                                 </div>
                                 </Col>
                                 <Col xs={4}>
                                 <div class="secomBlock">
                                    <span class="pax-price"><span> </span>{benfit.benefitsInclusive}</span>
                                 </div>
                                 </Col>
                              </div>
                           </div>
                           ))}
                        </ShowHideAccordion>
                     </div>
                     }
                    </>


                  ))}
                 {plan.optional_covers!==undefined && plan.optional_covers.length!==0 && plan.optional_covers.map((opt,index)=>(
                        <div>
                            <div className="brdtopInus">
                            <Row>
                        <Col xs={8}>
                            <div className="allPax-list extenSion">
                            {opt.name==="Winter Sports Extension" &&
                            <Form.Check
                            type="checkbox"
                            id={opt.code+plan.scheme_code}
                            checked={opt.isWinter}
                            label={opt.name}
                            onChange={()=>handleOptionalChange(opt,plan.scheme_code)}
                            custom
                            />
                            }
                            {opt.name==="Covid 19 extension" &&
                            <Form.Check
                            type="checkbox"
                            id={opt.code+plan.scheme_code}
                            checked={opt.isCovid}
                            label={opt.name}
                            onChange={()=>handleOptionalChangeCovid(opt,plan.scheme_code)}
                            custom satya
                            />
                            }
                            {opt.help!==null &&
                            <p className="impotantinfo toolTipInfo">
                            <span className="ezyIcon icon-info"></span>
                            <div className="toolTipCUstoom cnclInfo">
                            <p>{opt.help}</p>
                            </div>
                            </p>
                            }
                        </div>
                        </Col>
                       <Col xs={4}>
                        <>
                        {opt.premium!=="0" &&
                                    <span className="pax-price"><span>QAR </span> <strong> {opt.premium}.00</strong></span>
                                    }
                                    {opt.premium==="0" &&
                                    <span className="pax-price">Inclusive</span>
                                    }
                                    </>
                                    </Col>
                            </Row>
                         </div>
                        {opt.benefits.length!==0 && opt.benefits!==null &&
                        <div className='newBenefits'>
                            <ShowHideAccordion className="newBenefitsdrop" title='Benefits' icon='arrowDown'>
                            {opt.benefits.map((benfit,index)=>(
                                <div className='newbenfitsCont'>
                                <div className="listOfterr d-flex">
                                    <Col xs={8}>
                                    <div className="allPax-list">
                                        <span className="insuTitlee">{benfit.benefitsDesc}</span>
                                        <p className="impotantinfo toolTipInfo">
                                            <span className="ezyIcon icon-info"></span>
                                        <div className="toolTipCUstoom cnclInfo">
                                            <p>{benfit.benefitsHelp}</p>
                                        </div>
                                        </p>
                                    </div>
                                    </Col>
                                    <Col xs={4}>
                                    <div class="secomBlock">
                                        <span class="pax-price"><span> </span> <strong> {benfit.benefitsInclusive}</strong></span>
                                    </div>
                                    </Col>
                                </div>
                                </div>
                                ))}
                            </ShowHideAccordion>
                        </div>
                        }
                        </div>
                ))}
                  {plan.inclusive_covers!==undefined && plan.inclusive_covers.length!==0 && plan.inclusive_covers.map((incl,index)=>(
                     <>


                     <div className="brdtopInus">
                          <Row>


                     <Col xs={8}>

                                                    <div className="allPax-list">
                                                        <Form.Check
                                                        type="checkbox"
                                                        id="paxxxx"
                                                        checked={true}
                                                        disabled={true}
                                                        label={incl.name}
                                                        custom
                                                       />
                                                       {incl.help!==null &&
                                                       <p className="impotantinfo toolTipInfo">
                                                        <span className="ezyIcon icon-info"></span>
                                                        <div className="toolTipCUstoom cnclInfo">
                                                        <p>{incl.help}</p>
                                                        </div>
                                                        </p>
                                                       }

                                                    </div>
                                                    </Col>
                                                    <Col xs={4}>

                                                            <>
                                                            {incl.premium!=="0" &&
                                                            <span className="pax-price"><span>QAR </span> <strong> {incl.premium}.00</strong></span>
                                                            }
                                                            {incl.premium==="0" &&
                                                            <span className="pax-price">Inclusive</span>
                                                            }
                                                            </>

                                                       </Col>
                                                    </Row>


                                                    </div>
                                                   {incl.benefits.length!==0 && incl.benefits!==null &&
                                                   <div className='newBenefits'>
                                                      <ShowHideAccordion className="newBenefitsdrop" title='Benefits' icon='arrowDown'>
                                                      {incl.benefits.map((benfit,index)=>(
                                                         <div className='newbenfitsCont'>
                                                            <div className="listOfterr d-flex">
                                                               <Col xs={8}>
                                                               <div className="allPax-list">
                                                                  <span className="insuTitlee">{benfit.benefitsDesc}</span>
                                                                  <p className="impotantinfo toolTipInfo">
                                                                     <span className="ezyIcon icon-info"></span>
                                                                  <div className="toolTipCUstoom cnclInfo">
                                                                     <p>{benfit.benefitsHelp}</p>
                                                                  </div>
                                                                  </p>
                                                               </div>
                                                               </Col>
                                                               <Col xs={4}>
                                                               <div class="secomBlock">
                                                                  <span class="pax-price"><span> </span>  {benfit.benefitsInclusive}</span>
                                                               </div>
                                                               </Col>
                                                            </div>
                                                         </div>
                                                         ))}
                                                      </ShowHideAccordion>
                                                   </div>
                                                   }

                                                    </>

                                                    ))}

                    {plan.discount_covers!==undefined && plan.discount_covers.length!==0 && plan.discount_covers.map((disc,index)=>(
                    <div>
                    <Row>
                     <Col xs={8}>
                     <div className="allPax-list">

                     <Form.Check
                     type="checkbox"
                     id="paxxxx"
                     checked={false}
                     label={disc.name}
                     custom
                    />
                    {disc.help!==null &&
                     <p className="impotantinfo toolTipInfo">
                     <span className="ezyIcon icon-info"></span>
                     <div className="toolTipCUstoom cnclInfo">
                     <p>{disc.help}</p>
                     </div>
                     </p>
                    }

                 </div>
                        </Col>
                        <Col xs={4}>

                        </Col>
                        </Row>

                                                    {disc.benefits.length!==0 && disc.benefits!==null &&
                                                   <div className='newBenefits'>
                                                      <ShowHideAccordion className="newBenefitsdrop" title='Benefits' icon='arrowDown'>
                                                      {disc.benefits.map((benfit,index)=>(
                                                         <div className='newbenfitsCont'>
                                                            <div className="listOfterr d-flex">
                                                               <Col xs={8}>
                                                               <div className="allPax-list">
                                                                  <span className="insuTitlee">{benfit.benefitsDesc}</span>
                                                                  <p className="impotantinfo toolTipInfo">
                                                                     <span className="ezyIcon icon-info"></span>
                                                                  <div className="toolTipCUstoom cnclInfo">
                                                                     <p>{benfit.benefitsHelp}</p>
                                                                  </div>
                                                                  </p>
                                                               </div>
                                                               </Col>
                                                               <Col xs={4}>
                                                               <div class="secomBlock">
                                                                  <span class="pax-price"><span> </span> <strong> {benfit.benefitsInclusive}</strong></span>
                                                               </div>
                                                               </Col>
                                                            </div>
                                                         </div>
                                                         ))}
                                                      </ShowHideAccordion>
                                                   </div>
                                                   }
                                                    </div>
                                                    ))}
                                                   </div>


                                                </div>
                                                </div>
                                                </Col>
                                                </Row>
                  </div>
               </div>
            </div>
            </div>
         </div>
         </Col>
       ))}

      </Row>

   </div>

</div>
</div>








            {/* <Modal show={show} onHide={handleClose} className="travelBaggageplans">
                <Modal.Header>
                    <Modal.Title>Travel Bagggage Plans for holidays makers</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Check type="checkbox" id="ab" label="Travel Baggage Assurances-Platinum"

                                    custom className="ml-0" />
                            </Form.Group>
                            <span>QAR 16.20</span>
                            <div className="flightDetailsLink">
                                More Details <span className="ezyIcon icon-arrowDown"></span>
                            </div>
                        </Col>
                        <Col>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Check type="checkbox" id="ad" label="Travel Baggage Assurances-Gold"

                                    custom className="ml-0" />
                            </Form.Group>
                            <span>QAR 11.64</span>
                            <div className="flightDetailsLink">
                                More Details <span className="ezyIcon icon-arrowDown"></span>
                            </div>

                        </Col>
                        <Col>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Check type="checkbox" id="ac" label=" Travel Baggage Assurances-Silver Plan"

                                    custom className="ml-0" />
                            </Form.Group>
                            <span>QAR 16.24</span>
                            <div className="flightDetailsLink">
                                More Details <span className="ezyIcon icon-arrowDown"></span>
                            </div>

                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Confirm Selection
                    </Button>
                </Modal.Footer>
            </Modal> */}
    <Modal show={showw} onHide={handleClosee} className="packagePop">
        <Modal.Header closeButton>
            <Modal.Title>List of Countries for {modalData.scheme_name}:</Modal.Title>
        </Modal.Header>
        <Modal.Body>


            <div className='popgalleryImage secondCntlist mb-3'>

              <Row>
                <Col xs={3}>
                <ul>
                <li>
                    <span className="d-flex"><span  className="flagIco"><RU title="Russia"/></span>
                    <span>Russia</span></span>
                </li>
                <li>
                <span className="d-flex"><span  className="flagIco"><DE title="Germany"/></span>
                    <span>Germany</span></span>


                </li>
                <li>
                    <span className="d-flex">

                    <span  className="flagIco"><AE title="GB"/></span>
                    <span>United Kingdom</span>
                    </span>
                </li>
                <li>
                    <span className="d-flex">
                    <span  className="flagIco"><FR title="Saudi Arabia"/></span>
                    <span>France</span>
                        </span>
                </li>
                <li>
                    <span className="d-flex"> <span  className="flagIco"><IT title="Italy"/></span>
                    <span>Italy</span></span>
                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><ES title="Spain"/></span>
                    <span>Spain</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><UA title="Ukraine"/></span>
                    <span>Ukraine</span></span>

                              </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><PL title="Poland"/></span>
                    <span>Poland</span></span>
                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><RO title="Romania"/></span>
                    <span>Romania</span></span>
                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><NL title="Netherlands"/></span>
                    <span>Netherlands</span></span>
                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><BE title="Belgium"/></span>
                    <span>Belgium</span></span>
                </li>
                </ul>
                </Col>
                <Col xs={3}>
                <ul>
                <li>

<span className="d-flex"> <span  className="flagIco"><CZ title="Czech Republic"/></span>
<span>Czech Republic (Czechia)</span></span>
</li>
<li>
<span className="d-flex"> <span  className="flagIco"><GR title="Greece"/></span>
<span>Greece</span></span>
</li>
<li>
<span className="d-flex"> <span  className="flagIco"><PT title="Portugal"/></span>
<span>Portugal</span></span>
</li>
<li>
<span className="d-flex"> <span  className="flagIco"><SE title="Sweden"/></span>
<span>Sweden</span></span>
</li>
<li>
                   <span className="d-flex"> <span  className="flagIco"><HU title="Hungary"/></span>
                    <span>Hungary</span></span>
                </li>
                <li>

                    <span className="d-flex"> <span  className="flagIco"><BY title="Belarus"/></span>
                    <span>Belarus</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><AT title="Austria"/></span>
                    <span>Austria</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><RS title="Serbia"/></span>
                    <span>Serbia</span></span>


                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><CH title="Switzerland"/></span>
                    <span>Switzerland</span></span>


                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><BG title="Bulgaria"/></span>
                    <span>Bulgaria</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><DK title="Denmark"/></span>
                    <span>Denmark</span></span>

                </li>
                </ul>
                </Col>
                <Col xs={3}>
                <ul>
                <li>
                <span className="d-flex"> <span  className="flagIco"><FI title="Finland"/></span>
                    <span>Finland</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><SK title="Slovakia"/></span>
                    <span>Slovakia</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><NO title="Norway"/></span>
                    <span>Norway</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><IE title="Ireland"/></span>
                    <span>Ireland</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><HR title="Croatia"/></span>
                    <span>Croatia</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><MD title="Moldova"/></span>
                    <span>Moldova</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><BA title="Bosnia and Herzegovina"/></span>
                    <span>Bosnia and Herzegovina</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><AL title="Albania"/></span>
                    <span>Albania</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><LT title="Lithuania"/></span>
                    <span>Lithuania</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><MK title="North Macedonia"/></span>
                    <span>North Macedonia</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><SI title="Slovenia"/></span>
                    <span>Slovenia</span></span>

                </li>

                </ul>
                </Col>
                <Col xs={3}>
                <ul>
                <li>
                <span className="d-flex"> <span  className="flagIco"><LV title="Latvia"/></span>
                    <span>Latvia</span></span>

                </li>

                <li>
                <span className="d-flex"> <span  className="flagIco"><EE title="Estonia"/></span>
                    <span>Estonia</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><ME title="Montenegro"/></span>
                    <span>Montenegro</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><LU title="Luxembourg"/></span>
                    <span>Luxembourg</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><MT title="Malta"/></span>
                    <span>Malta</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><IS title="Iceland"/></span>
                    <span>Iceland</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><AD title="Andorra"/></span>
                    <span>Andorra</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><MC title="Monaco"/></span>
                    <span>Monaco</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><LI title="Liechtenstein"/></span>
                    <span>Liechtenstein</span></span>

                </li>
                <li>
                <span className="d-flex"> <span  className="flagIco"><SM title="San Marino"/></span>
                    <span>San Marino</span></span>

                </li>

                <li>
                <span className="d-flex"> <span  className="flagIco"><TR title="TURKEY"/></span>
                    <span>TURKEY</span></span>

                </li>

               </ul>
                </Col>
              </Row>

            </div>
        </Modal.Body>
    </Modal>
    <Modal show={showww} onHide={handleCloseee} className="packagePop">
    <Modal.Header closeButton>
            <Modal.Title>List of Countries for Regional:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='popgalleryImage mb-3'>
                <div className="titleContry">
              <h3>Middle East</h3>
              </div>
              <Row>
                <Col xs={3}>
                <ul>
                <li>
                    <span className="qatR d-flex"><span  className="flagIco"><QA title="Qatar"/></span>
                    <span>Qatar</span></span>
                </li>
                <li>
                    <span className="bahRa d-flex">
                    <span  className="flagIco"><BH title="Bahrain"/></span>
                    <span>Bahrain</span>
                        </span>
                </li>
                <li>
                    <span className="uniTed d-flex">

                    <span  className="flagIco"><AE title="UAE"/></span>
                    <span>UAE</span>
                    </span>
                </li>
                <li>
                    <span className="saudiArab d-flex">
                    <span  className="flagIco"><SA title="Saudi Arabia"/></span>
                    <span>Saudi Arabia</span>
                        </span>
                </li>
                <li>
                    <span className="omAn d-flex"> <span  className="flagIco"><OM title="Oman"/></span>
                    <span>Oman</span></span>
                </li>
                </ul>
                </Col>
                <Col xs={3}>
                <ul>
                <li>
                <span className="kuWait d-flex"> <span  className="flagIco"><KW title="Kuwait"/></span>
                    <span>Kuwait</span></span>

                </li>
                <li>
                <span className="egyPt d-flex"> <span  className="flagIco"><EG title="Egypt"/></span>
                    <span>Egypt</span></span>

                              </li>
                <li>
                <span className="suDan d-flex"> <span  className="flagIco"><SD title="Sudan"/></span>
                    <span>Sudan</span></span>
                </li>
                <li>
                <span className="algerIa d-flex"> <span  className="flagIco"><DZ title="Algeria"/></span>
                    <span>Algeria</span></span>
                </li>
                <li>
                <span className="irAq d-flex"> <span  className="flagIco"><IR title="Iraq"/></span>
                    <span>Iraq</span></span>
                </li>
                </ul>
                </Col>
                <Col xs={3}>
                <ul>
                <li>
                <span className="morOcc d-flex"> <span  className="flagIco"><MA title="Morocco"/></span>
                    <span>Morocco</span></span>
                </li>
                <li>

                    <span className="yeMen d-flex"> <span  className="flagIco"><YE title="Yemen"/></span>
                    <span>Yemen</span></span>
                </li>
                <li>
                    <span className="syRia d-flex"> <span  className="flagIco"><SY title="Syria"/></span>
                    <span>Syria</span></span>
                </li>
                <li>
                 <span className="somAlia d-flex"> <span  className="flagIco"><SO title="Somalia"/></span>
                    <span>Somalia</span></span>
                </li>
                <li>
                   <span className="tunIsia d-flex"> <span  className="flagIco"><TN title="Tunisia"/></span>
                    <span>Tunisia</span></span>
                </li>
                </ul>
                </Col>
                <Col xs={3}>
                <ul>

                <li>
                   <span className="jorDan d-flex"> <span  className="flagIco"><JO title="Jordan"/></span>
                    <span>Jordan</span></span>
                </li>
                <li>

                    <span className="liBar d-flex"> <span  className="flagIco"><LY title="Libta"/></span>
                    <span>Libta</span></span>

                </li>
                <li>
                <span className="leBanon d-flex"> <span  className="flagIco"><LB title="Lebanon"/></span>
                    <span>Lebanon</span></span>

                </li>
                <li>
                <span className="palEstine d-flex"> <span  className="flagIco"><PS title="Palestine"/></span>
                    <span>Palestine</span></span>


                </li>
                <li>
                <span className="maurTania d-flex"> <span  className="flagIco"><MR title="Mauritania"/></span>
                    <span>Mauritania</span></span>


                </li>
                <li>
                <span className="djiBouti d-flex"> <span  className="flagIco"><DJ title="Djibouti"/></span>
                    <span>Djibouti</span></span>

                </li>
                <li>
                <span className="comoRos d-flex"> <span  className="flagIco"><KM title="Comoros"/></span>
                    <span>Comoros</span></span>

                </li>
               </ul>
                </Col>
              </Row>




              <div className="contryList">
<Row>
    <Col xs={6}>
    <div className="titleContry">
              <h3>ISC Contries</h3>
              </div>

              <Row>
                <Col xs={6}>

                <ul>
                <li>
                    <span className="inDia d-flex"><span  className="flagIco"><IN title="India"/></span>
                    <span>India</span></span>
                </li>
                <li>
                    <span className="paKis d-flex"><span  className="flagIco"><PK title="Pakistan"/></span>
                    <span>Pakistan</span></span>
                </li>
                </ul>
                </Col>

                <Col xs={6}>
                                <ul>
                <li>
                    <span className="sriLanka d-flex"><span  className="flagIco"><LK title="Srilanka"/></span>
                    <span>Sri Lanka</span></span>
                </li>

                <li>
                    <span className="desh d-flex"><span  className="flagIco"><BD title="Bangladesh"/></span>
                    <span>Bangladesh</span></span>
                </li>
                </ul>
                </Col>
              </Row>
    </Col>

    <Col xs={6}>
    <div className="titleContry">
              <h3>Sea Countries</h3>
              </div>

              <Row>
                <Col xs="6">
                <ul>
                <li>
                    <span className="sothKorea d-flex"><span  className="flagIco"><KR title="South Korea"/></span>
                    <span>South Korea</span></span>
                </li>

                <li>
                    <span className="philipians d-flex"><span  className="flagIco"><PH title="Phillippines"/></span>
                    <span>Phillippines</span></span>
                </li>

                <li>
                    <span className="indoAsia d-flex"><span  className="flagIco"><ID title="Indonesia"/></span>
                    <span>Indonesia</span></span>
                </li>

                </ul>
                </Col>

                <Col xs="6">
                <ul>
                <li>
                    <span className="nepAl d-flex"><span  className="flagIco"><NP title="Nepal"/></span>
                    <span>Nepal</span></span>
                </li>

                <li>
                    <span className="bhuTan d-flex"><span  className="flagIco"><BT title="Bhutan"/></span>
                    <span>Bhutan</span></span>
                </li>
                </ul>
                </Col>

              </Row>

    </Col>

</Row>




               </div>
            </div>
        </Modal.Body>
    </Modal>

  <Modal
        show={state.noresults}
        //onHide={handlePriceClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>No Results Found!</strong><br/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              variant="secondary"
              onClick={back}
            >Back to Home</Button>
        </Modal.Footer>
      </Modal>
            <Footer />
        </>
    )
}
export default InsResults