import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form , Modal} from "react-bootstrap";
import Footer from '../../common/Footer'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../App";
import Button from '../../common/buttons/Button';
import DateUtils from '../../commonUtils/DateUtils';
import {getBranchList, companyList, saveReceipt, updateReceipt,getReceipts,saveReceiptReversal  } from './operations';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InnerLoader from '../../common/loader/InnerLoader';
import NoRecord from '../../common/popUps/NoRecord';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import ReportMenu from '../../common/ReportMenu';
import DailyReportMenu from '../../common/subMenu/DailyReportMenu';
import RegionType from "../common/RegionType";
let currencyMaster = require('../../masterData/Currencies.json');


/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  regionUpdate:"", region_type:"",region_type_edit:"", branchList:[], branches: [],sel_branch:[], companies: [], userType: "", companyData: [], branchData: [],bound_type:"",
  pay_type:[], sel_currency:[] ,receipt_no:"", receipt_amount:"",remarks:"",id:0,showpopup:false,trcs_reversal_no:"",trcs_reversal_remarks:""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state,  sel_branch:[], companies: [], userType: "", companyData: [], branchData: [],bound_type:"",
        pay_type:[], sel_currency:[] ,receipt_no:"", receipt_amount:"",remarks:"",id:0
      };
      case 'edit':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air dsrdata List and Add Markup
function ReceiptMain(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [doc_date, setDocDate] = useState("");
  const [issue_date, setIssueDate] = useState("");
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [receiptList, setReceiptList] = useState([]);
  const [enableBtn, setEnableBtn] = useState(false);
  const [selObj, setSelObj] = useState("");

  const formOfPay = [{ label: "Cash", value: 'Cash' }, { label: "Bank Transfer", value: 'Bank Transfer' },{ label: "Cheque", value: 'Cheque' }]

  useEffect(() => {
    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      if(context.logindata.utype==="sta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }else if(context.logindata.utype==="ta_user"){
        dispatch({ type: 'cid', payload: context.logindata.cid })
      }
      dispatch({ type: 'userId', payload: context.logindata.uid })
      //Load all branches based on for ATT
      const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid }
      getBranchList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'branches', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
       //Load all Receipts
       if(receiptList.length===0){
        getReceipts().then(response => {
           if (response.res.suc) {
            loadResponse(response.res.res)
           }
         }).catch(function (error) {
           log.error(
             "Exception occured in Receipts function---" + error
           );
         });
       }
  }
  }, [context.logindata]);

  const loadResponse = (response) => {
    let data = [];
    for (let val of response) {
      data.push({region_type:val.region_type, currency: val.region_type=="Qatar"?"QAR":"INR", doc_date: val.doc_date, issue_date: val.issue_date,booked_by:val.bkby,branch_name: val.branch_name,company_name: val.company_name,fop: val.fop,receipt_no: val.receipt_no,receipt_amount:val.cur+" "+val.receipt_amount, creation_date:val.creation_date, options: <><span className="tbAction">{context.logindata.roles.includes('E_R_C_P') && val.is_reversal===0 && <a className="ezyIcon icon-edit ml-1 mr-1" onClick={()=>editReceipt(val)}></a>} {context.logindata.roles.includes('R_R_C_P') &&  val.is_reversal===0 && <a className="ezyIcon icon-reversal" onClick={()=>updateReversal(val)}></a>}</span></> })
    }
    setReceiptList(data)
  }

  function hidemessage(){
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const validateReceipt=()=>{
    if(state.region_type==""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Region type' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(doc_date===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Document Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(issue_date===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Issue Date' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.sel_branch.length===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Branch' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.companyData.length===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Compnay' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.sel_currency.length===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Currency' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.pay_type.length===0){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Form of Payment' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.receipt_no===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Trcs Ref Number' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.receipt_amount==="" || state.receipt_amount==="0"){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Receipt Amount' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(state.remarks===""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Remarks' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false;
    }else if(!enableBtn && state.receipt_no!=="" && state.receipt_no!==undefined && receiptList.length!==0){
       let list=receiptList.filter(obj=>obj.receipt_no===state.receipt_no);
       if(list.length!==0){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Trcs Ref Number should be uniq' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
       }
    }
    return true;
  }

  const editReceipt=(obj)=>{
    dispatch({ type: 'edit', payload: obj })
    dispatch({ type: 'region_type_edit', payload: obj.region_type })
    dispatch({ type: 'sel_branch', payload: [{label:obj.branch_name,value:obj.branch_id}] })
    dispatch({ type: 'companyData', payload: [{label:obj.company_name,value:obj.company_id}] })
    dispatch({ type: 'companyData', payload: [{label:obj.company_name,value:obj.company_id}] })
    dispatch({ type: 'pay_type', payload: [{label:obj.fop,value:obj.fop}] })
    dispatch({ type: 'sel_currency', payload: [{label:obj.cur,value:obj.cur}] })
    setDocDate(new Date(obj.doc_date));
    setIssueDate(new Date(obj.issue_date));
    setEnableBtn(true)
  }

  const updateReversal=(obj)=>{
    dispatch({ type: 'showpopup', payload: true })
    setSelObj(obj)
  }

  //Handling the search creterai based pn the selection and dates
  const saveReceipts = (type) => {
    if (validateReceipt()){
      setIsLoadingMore(true)
        if(type==="save"){
          const req = {
            "region_type":state.regionUpdate,
            "branch_id": state.sel_branch.length!==0?state.sel_branch[0].value:0,
            "branch_name": state.sel_branch.length!==0?state.sel_branch[0].label:"",
            "doc_date": doc_date!==""?DateUtils.convertStringToDate(doc_date):"",
            "issue_date": issue_date!==""?DateUtils.convertStringToDate(issue_date):"",
            "receipt_no": state.receipt_no,
            "receipt_amount": state.receipt_amount,
            "company_id": state.companyData.length!==0?state.companyData[0].value:0,
            "crby" : context.logindata.uid,                //0
            "company_name": state.companyData.length!==0?state.companyData[0].label:"",
            "fop": state.pay_type.length!==0?state.pay_type[0].label:"",
            "cur": state.sel_currency.length!==0?state.sel_currency[0].label:"",
            "remarks":state.remarks,
            "bk_by":context.logindata.nm
            }
          saveReceipt(req).then(response => {
            if(response.suc){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Receipt saved sucessfully...' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              hidemessage()
              return window.location=window.location.pathname;
            }else{
              setIsLoadingMore(false)
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Receipt Not saved' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }else{
          const request = {
            "region_type":state.regionUpdate,
            "id":state.id,
            "doc_date": doc_date!==""?DateUtils.convertStringToDate(doc_date):"",
            "issue_date": issue_date!==""?DateUtils.convertStringToDate(issue_date):"",
            "receipt_no": state.receipt_no,
            "receipt_amount": state.receipt_amount,
            "crby" : context.logindata.uid,
            "fop": state.pay_type.length!==0?state.pay_type[0].label:"",
            "cur": state.sel_currency.length!==0?state.sel_currency[0].label:"",
            "remarks":state.remarks,
            "bk_by":context.logindata.nm
            }
          updateReceipt(request).then(response => {
            if(response.suc){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Receipt updated sucessfully...' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              hidemessage()
              return window.location=window.location.pathname;
            }else{
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Receipt Not updated' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              setIsLoadingMore(false)
            }
          }).catch(function (error) {
            setIsLoadingMore(false)
            log.error(
              "Exception occured in getCompanyList function---" + error
            );
          });
        }

    }
    }
    const validateReversal=()=>{
      if(state.trcs_reversal_no===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Trcs Ref Number' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }else if(state.trcs_reversal_no!==""){
        let list=receiptList.filter(obj=>obj.receipt_no===state.trcs_reversal_no);
        if(list.length!==0){
         dispatch({ type: 'notiMessageShow', payload: true })
         dispatch({ type: 'notiMessage', payload: 'Trcs Reversal Ref Number should be uniq' })
         dispatch({ type: 'notiVarient', payload: 'danger' })
         hidemessage()
         return false;
      }
      return true;
    }
  }

    const saveTrcsReverNumber=()=>{
      if(validateReversal()){
        const req = {
          "region_type":state.regionUpdate,
          "id":selObj.id,
          "doc_date": selObj.doc_date,
          "issue_date": selObj.issue_date,
          "branch_id": selObj.branch_id,
          "branch_name": selObj.branch_name,
          "receipt_no": state.trcs_reversal_no,
          "receipt_amount": selObj.receipt_amount,
          "company_id": selObj.company_id,
          "crby" : context.logindata.uid,
          "company_name": selObj.company_name,
          "fop": selObj.fop+" "+"Reversal",
          "cur": selObj.cur,
          "remarks":selObj.remarks,
          "bk_by":context.logindata.nm,
          "rev_remarks":state.trcs_reversal_remarks
          }
          saveReceiptReversal(req).then(response => {
          if(response.suc){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Trcs Reversal saved sucessfully...' })
            dispatch({ type: 'notiVarient', payload: 'success' })
            hidemessage()
            return window.location=window.location.pathname;
          }else{
            setIsLoadingMore(false)
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Receipt Not saved' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage()
          }
        }).catch(function (error) {
          setIsLoadingMore(false)
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      }
    }

  const setCompany = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'cid', payload: obj[0].value })
      dispatch({ type: 'companyData', payload: obj })
    }
  }

  const resetSearch = () => {
    return window.location=window.location.pathname;
    setDocDate("")
    setIssueDate("")
    dispatch({ type: 'region_type', payload: [] })
    dispatch({ type: 'sel_branch', payload: [] })
    dispatch({ type: 'companyData', payload: [] })
    dispatch({ type: 'sel_currency', payload: [] })
    dispatch({ type: 'pay_type', payload: [] })
    dispatch({ type: 'clear', payload: true })
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }

  const columns = [
    {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'branch_name',
      text: 'Branch',
      sort: false,
      filter: textFilter()
    },
    {
      dataField: 'company_name',
      text: 'Company',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'currency',
      text: 'Currency',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'doc_date',
      text: 'Document Date',
      sort: false,
      filter: textFilter()
    },
    {
      dataField: 'issue_date',
      text: 'Issue Date',
     sort: false,
      filter: textFilter()
    },
    {
      dataField: 'booked_by',
      text: 'Booked By',
     sort: false,
      filter: textFilter()
    },
  {
    dataField: 'fop',
    text: 'Form of Payment',
   sort: false,
    filter: textFilter()
  },
  {
    dataField: 'receipt_no',
    text: 'Trcs Ref Number',
    sort: false,
    filter: textFilter()
  },
  {
    dataField: 'receipt_amount',
    text: 'Receipt Amount',
    sort: false,
    filter: textFilter()
  },
  {
    dataField: 'creation_date',
    text: 'creation date',
   sort: false,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }
];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const handleRegionType = (e) => {
    dispatch({ type: 'branchList', payload: e })
    dispatch({ type: 'sel_branch', payload: [] })
  }
  const updateRegion=(region)=>{
    dispatch({ type: "region_type", payload: region });
    dispatch({ type: "regionUpdate", payload: region });
  }
const setSelbranch = (obj) => {
  dispatch({ type: 'companyData', payload: [] })
  if(obj.length!==0){
    companyList(obj[0].value).then((res) => {
      let arr = []
      for (let val of res) {
        if (val.type == "TA") {
          let obj = {
            "label": val.label,
            "value": val.value
          }
          arr.push(obj)
        }
      }
      dispatch({ type: 'companies', payload: arr })
      dispatch({ type: 'sel_branch', payload: obj })
    });
}
  }


const selPayment=(obj)=>{
  if (obj[0] !== undefined) {
    dispatch({ type: 'pay_type', payload: obj })
  }
}

const selCurrency=(obj)=>{
  if (obj[0] !== undefined) {
    dispatch({ type: 'sel_currency', payload: obj })
  }
}

const closeReceipt=()=>{
  dispatch({ type: 'showpopup', payload: false })
}

const cancel=()=>{
  return window.location=window.location.pathname;
}

  return (
    <>
     <ReportMenu active='Receipt' />
      <Breadcrumb activePage="Receipt" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <DailyReportMenu active="Receipt" />
      <div>
        <br/>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
          <Form.Row>
            <RegionType sendResponse={(e)=>handleRegionType(e)} regionType={updateRegion} regionUpdate={state.region_type_edit}/>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={state.branchList}
                searchable={true}
                values={state.sel_branch}
                onChange={(value) => (setSelbranch(value))}
                disabled={enableBtn}
              />
              <Form.Label>Branch<sup>*</sup></Form.Label>
            </Form.Group>
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.companies}
                  searchable={true}
                  values={state.companyData}
                  onChange={(value) => (setCompany(value))}
                  disabled={enableBtn}
                />
                <Form.Label>Company<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} className="selectplugin datePicker mb-0" controlId="doc_date">
                  <DatePicker
                    selected={doc_date}
                    onChange={date => setDocDate(date)}
                    maxDate={ issue_date ? issue_date : new Date()}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>Document Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={3} className="selectplugin datePicker mb-0" controlId="issue_date">
                  <DatePicker
                    selected={issue_date}
                    onChange={date => setIssueDate(date)}
                    maxDate={ issue_date ? issue_date : new Date()}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>Issue Date<sup>*</sup></Form.Label>
                </Form.Group>
          </Form.Row>
        <Form.Row className="pt-4">
        <Form.Group as={Col} xs={3} controlId="receipt_no">
              <Form.Control type="text"  placeholder="Ticket" value={state.receipt_no} onChange={handleChange} />
              <Form.Label>Tracs Ref Number<sup>*</sup></Form.Label>
              </Form.Group>
             <Form.Group className="selectplugin" as={Col} xs={3} controlId="bound_type">
              <Select
                options={formOfPay}
                searchable={true}
                values={state.pay_type}
                onChange={(value) => (selPayment(value))}
              />
              <Form.Label>Form of pay<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="bound_type">
              <Select
                options={currencyMaster}
                searchable={true}
                values={state.sel_currency}
                onChange={(value) => (selCurrency(value))}
              />
              <Form.Label>Currency<sup>*</sup></Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={3} controlId="receipt_amount">
              <Form.Control type="text"  placeholder="Ticket" value={state.receipt_amount} onChange={handleChange} />
              <Form.Label>Receipt Amount<sup>*</sup></Form.Label>
              </Form.Group>

        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={3} controlId="receipt_no">
              <Form.Control type="text" disabled={true}  placeholder="Ticket" value={context.logindata.nm} onChange={handleChange} />
              <Form.Label>Booked By<sup>*</sup></Form.Label>
              </Form.Group>
            <Form.Group as={Col} xs={3} controlId="remarks">
            <Form.Control as="textarea" rows="3" value={state.remarks} placeholder="Remarks" onChange={handleChange} />
            <Form.Label>Remarks<sup>*</sup></Form.Label>
          </Form.Group>

        </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
       {!enableBtn &&
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
       }
        {context.logindata!=="" && context.logindata.roles.includes('A_R_C_P') && !enableBtn &&
          <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={()=>saveReceipts("save")}
        > Add</Button>
        }
        {enableBtn &&
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={cancel}
        > Cancel</Button>
       }
        {enableBtn &&
          <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={()=>saveReceipts("update")}
        > Update</Button>
        }
      </div>

      <h4 class="subTitle plusHr mt-3 mb-3">Receipts</h4>
      <div className="cardMainlayout tableRes p-0">
      {isLoadingMore ? <InnerLoader /> : (<>
        {receiptList.length !== 0 &&
        <div className="tablescroll">
          <BootstrapTable
          bootstrap4
          keyField="id"
          classes="markup filterTbl"
          data={receiptList}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={receiptList.length >10 && receiptList.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
          receiptList.length >25 && receiptList.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
          receiptList.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
          />
        </div>
          || receiptList.length === 0
          && <NoRecord />}</>)}
      </div>

              </div>
              </div>
      <Modal
        show={state.showpopup}
        onHide={closeReceipt}
        className="importPNRMdl reverSal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        Receipt Reversal
        </Modal.Header>
        <Modal.Body>

        <div className="popUplayout center">

            <div className="text-center">
            <Form.Row>

              <Form.Group as={Col} xs={4} controlId="receipt_no" className="selectplugin">
              <Form.Label>Tracs Ref</Form.Label>
              <Form.Control type="text" disabled={true}  placeholder="Ticket" value={selObj.receipt_no} />

              </Form.Group>
              <Form.Group as={Col} xs={4} controlId="receipt_no"  className="selectplugin">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" disabled={true} value={selObj.receipt_amount}  placeholder="Ticket" />

              </Form.Group>
            <Form.Group as={Col} xs={4} controlId="receipt_no" className="selectplugin">
            <Form.Label>Form of Payment</Form.Label>
              <Form.Control type="text" disabled={true}  placeholder="Ticket" value={selObj.fop} />

              </Form.Group>

        </Form.Row>

        <Form.Row>
        <Form.Group as={Col} xs={6} controlId="trcs_reversal_no" className="selectplugin">
            <Form.Label>Tracs Reversal Number<sup>*</sup></Form.Label>
            <Form.Control type="text"  value={state.trcs_reversal_no} placeholder="Remarks" onChange={handleChange} />

          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="trcs_reversal_remarks" className="selectplugin">
          <Form.Label>Reversal Remarks</Form.Label>
            <Form.Control as="textarea" value={state.trcs_reversal_remarks} placeholder="Remarks" onChange={handleChange} />

          </Form.Group>
        </Form.Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              variant="secondary"
              onClick={closeReceipt}
            >Cancel</Button>
            <Button
              size="xs"
              onClick={saveTrcsReverNumber}
              variant="primary"
            >Reverse</Button>
        </Modal.Footer>
      </Modal>
<Footer/>
    </>

  )
}
export default ReceiptMain