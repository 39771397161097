import CustomerRequestService from '../../../../services/administration/CustomerRequestService';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */

export const getCustomerRequests = async () => {
    let resp = [];
    await CustomerRequestService.getAllCustRequest().then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}


