/**
  * @description: Flight Confirmation page Root Fare details component
  * @date:09-01-2020
  * @author:Rambabu
  *
  */
 import React, { useState, useEffect, useReducer } from 'react';
 import { Alert, Col, Container, Form, Row } from "react-bootstrap";
 import { isNotNull } from '../../../commonUtils/validators';
 import Utilities from '../../../commonUtils/Utilities';
 import FormateCurrency from '../../../commonUtils/FormateCurrency';
 export const ONFLY_FLT_MKP = "onflyFltMkp";

 /**
  * Initial State Declaration
  */
 const initialState = {

     fareobj: '', grand_total: 0 , plbdisc:0 ,otCharge:0,bagAvail:false,mealAvail:false,seatAvail:false
 };


 const reducer = (state, action) => {

     switch (action.type) {

         default:
             return { ...state, [action.type]: action.payload };
     }
 };

 /**
  * @description: This function will show the FareDetails
  * @date:29-06-2021
  * @author:Lakshmi
  *
  */
 function TFFareDetails(props) {
     const [state, dispatch] = useReducer(reducer, initialState);
     useEffect(() => {
         //if props data avaliable
         if (isNotNull(props.faredet)) {
            var userType=(props.butype==="ta_user")?"TA":"STA";
            let adts=props.faredet.psngrs.filter((obj) => obj.ty === "ADT");
            let inf=0;
            for(let val of adts){
               if(val.inf!==undefined && val.inf!=="" && val.inf.ty==="INF"){
                   inf=inf+1;
               }
            }
             let chds=props.faredet.psngrs.filter((obj) => obj.ty === "CHD");
             let paxCount=adts.length+chds.length+inf;
             let pax_array = [];
             let grandtotal = 0;
             let adtService=0;
             let chdService=0;
             let infService=0;
             let adtReturnService=0;
             let chdReturnService=0;
             let infReturnService=0;
             let plbdisc=0;
             let adtDisc=0;
             let chdDisc=0;
             let infDisc=0;
             let bagAdtPrice=0;
             let mealAdtPrice=0;
             let bagChdPrice=0;
             let mealChdPrice=0;
             let totalOtCharge=0;
             let paxOt=0;
             let seatAdtPrice=0;
             let seatChdPrice=0;
             let onFlyMarkup=0
             let onFlyPaxMkp=0
             let total_gst=0
             let attGst_total=0;
             let taGst_total=0;
             let attGst=0
             let taGst=0;
             //Cal GST :
             if (props.sty==="normal"){
             if(props.gst!==undefined && props.gst!==""){
                total_gst=props.gst.total_gst/2;
                let att = props.gst.gst_invoice.filter(obj => obj.type==="ATT");
                let ta =  props.gst.gst_invoice.filter(obj => obj.type==="TA");
                if(att.length!==0){
                    if(Number(att[0].cgst_val)!==0 && Number(att[0].sgst_val)!==0){
                        attGst_total= Number(att[0].cgst_val) + Number(att[0].sgst_val)
                    }else{
                        attGst_total= Number(att[0].igst_val)
                    }
                        attGst=Number(attGst_total/paxCount)/2;
                }
                if(ta.length!==0){
                    if(Number(ta[0].cgst_val)!==0 && Number(ta[0].sgst_val)!==0){
                        taGst_total= Number(ta[0].cgst_val) + Number(ta[0].sgst_val)
                    }else{
                        taGst_total= Number(ta[0].igst_val)
                    }
                        taGst=Number(taGst_total/paxCount)/2;
                }
            }
            }else{
                if(props.ow_gst!==undefined && props.ow_gst!=="" && props.rt_gst!==undefined && props.rt_gst!==""){
                if(props.sector==="O"){
                    total_gst=props.ow_gst.total_gst;
                    let att = props.ow_gst.gst_invoice.filter(obj => obj.type==="ATT");
                    let ta =  props.ow_gst.gst_invoice.filter(obj => obj.type==="TA");
                    if(att.length!==0){
                        if(Number(att[0].cgst_val)!==0 && Number(att[0].sgst_val)!==0){
                            attGst_total= Number(att[0].cgst_val) + Number(att[0].sgst_val)
                        }else{
                            attGst_total= Number(att[0].igst_val)
                        }
                            attGst=Number(attGst_total/paxCount);
                    }
                    if(ta.length!==0){
                        if(Number(ta[0].cgst_val)!==0 && Number(ta[0].sgst_val)!==0){
                            taGst_total= Number(ta[0].cgst_val) + Number(ta[0].sgst_val)
                        }else{
                            taGst_total= Number(ta[0].igst_val)
                        }
                            taGst=Number(taGst_total/paxCount);
                    }
                }else if(props.sector==="R"){
                    total_gst=props.rt_gst.total_gst;
                    let att = props.rt_gst.gst_invoice.filter(obj => obj.type==="ATT");
                    let ta =  props.rt_gst.gst_invoice.filter(obj => obj.type==="TA");
                    if(att.length!==0){
                        if(Number(att[0].cgst_val)!==0 && Number(att[0].sgst_val)!==0){
                            attGst_total= Number(att[0].cgst_val) + Number(att[0].sgst_val)
                        }else{
                            attGst_total= Number(att[0].igst_val)
                        }
                            attGst=Number(attGst_total/paxCount);
                    }
                    if(ta.length!==0){
                        if(Number(ta[0].cgst_val)!==0 && Number(ta[0].sgst_val)!==0){
                            taGst_total= Number(ta[0].cgst_val) + Number(ta[0].sgst_val)
                        }else{
                            taGst_total= Number(ta[0].igst_val)
                        }
                            taGst=Number(taGst_total/paxCount);
                    }
                }
            }
            }
            //Other charge for all pax onwardSup={state.onwardSup} returnSup={state.returnSup}
            let markup=window.sessionStorage.getItem(ONFLY_FLT_MKP);
            if(markup!==null){
                onFlyMarkup=Number(markup);
                onFlyPaxMkp=Number(markup/paxCount);
                window.sessionStorage.removeItem(ONFLY_FLT_MKP);
            }
             if(props.otherCharge!==undefined && props.otherCharge!==null){
                 if(props.sty==="normal"){
                     if(props.faredet.tt==="1"){
                        totalOtCharge=Number(props.otherCharge);
                        paxOt=props.otherCharge/paxCount;
                        dispatch({ type: 'otCharge', payload:Number(props.otherCharge/paxCount)})
                     }else{
                        let totalPax=paxCount*2;
                        totalOtCharge=Number(props.otherCharge/totalPax);
                        paxOt=props.otherCharge/totalPax;
                        dispatch({ type: 'otCharge', payload:Number(props.otherCharge/totalPax)})
                     }
                 }else{
                 if(props.otherCharge!==undefined && props.otherCharge!==0){
                   if(props.onwardSup!=="" && props.returnSup!=="" && props.onwardSup!=="0" && props.returnSup!=="0"){
                    let ot_ary=props.otherCharge.split("/");
                    if(props.faredet.ref===1 && ot_ary[0]!==undefined){
                        paxOt=ot_ary[0]/paxCount;
                        totalOtCharge=Number(ot_ary[0]);
                      dispatch({ type: 'otCharge', payload:Number(ot_ary[0]/paxCount)})
                    }else if(props.faredet.ref===2 && ot_ary[1]!==undefined){
                        paxOt=ot_ary[1]/paxCount;
                        totalOtCharge=Number(ot_ary[1]);
                      dispatch({ type: 'otCharge', payload:Number(ot_ary[1]/paxCount)})
                    }
                   }else{
                     if(props.onwardSup!=="" && props.onwardSup!=="0" && props.faredet.ref===1){
                        totalOtCharge=Number(props.otherCharge);
                        paxOt=props.otherCharge/paxCount;
                        dispatch({ type: 'otCharge', payload:Number(props.otherCharge/paxCount)})
                     }else if(props.returnSup!=="" && props.returnSup!=="0" && props.faredet.ref===2){
                        totalOtCharge=Number(props.otherCharge);
                        paxOt=props.otherCharge/paxCount;
                        dispatch({ type: 'otCharge', payload:Number(props.otherCharge/paxCount)})
                     }
                   }
                   }
                 }
             }
             if(props.butype!=="REISSUE"){
             if(props.faredet.plb!==undefined && props.faredet.plb!==null && Object.keys(props.faredet.plb).length !== 0){
                     if(props.faredet.plb.adt!==undefined){
                        adtDisc=Utilities.addPrice(props.faredet.plb.adt,adts.length,"Cnt");
                     }
                     if(props.faredet.plb.chd){
                        chdDisc=Utilities.addPrice(props.faredet.plb.chd,chds.length,"Cnt");
                     }
                     if(props.faredet.plb.inf!==undefined){
                        infDisc=Utilities.addPrice(props.faredet.plb.inf,inf,"Cnt");
                     }
                     if(chdDisc==="NaN"){
                        chdDisc=0;
                     }
                     if(infDisc==="NaN"){
                        infDisc=0;
                     }
                    plbdisc=Utilities.addPrice(parseFloat(adtDisc)+parseFloat(chdDisc)+parseFloat(infDisc),0,"Add");
                    dispatch({ type: 'plbdisc', payload: parseFloat(plbdisc)})
             }
         }

             //iterating pax data
             for (let p in props.faredet.psngrs) {
                 let pax = props.faredet.psngrs[p];
                 let onward_sector = '';
                 //below code if normal flow we are taking srcorigns data
                     console.log('Entered normal flow');
                     //get teh total fare amount from pr tag
                     grandtotal = props.faredet.pr.tf;
                     //if normal flow we are taking srcorigns tag data only
                     onward_sector = props.faredet;
                     //adult fare data from srcorigns paxpr tag
                     let adult_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'ADT'
                     });
                     //child fare data from srcorigns paxpr tag
                     let chd_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'CHD'
                     });
                     //infant fare data from srcorigns paxpr tag
                     let inf_fare_data = onward_sector.paxpr.filter(function (obj) {
                         return obj.ptype.toUpperCase() === 'INF'
                     });

                     //if adult, constructing fare data
                     if (pax.ty.toUpperCase() === 'ADT') {
                         let totalfare = adult_fare_data[0].tf;//total_fare
                         let basefare = adult_fare_data[0].bf;//base_fare
                        // let tax = adult_fare_data[0].tax;//tax
                         let tax=0;
                         let penalty=0;
                         let resVal=0;
                         if(props.butype!=="REISSUE"){
                          tax=getTaxValue(adult_fare_data[0].tax);
                         }else{
                          tax=adult_fare_data[0].tfd;
                          penalty = adult_fare_data[0].penlty;
                          resVal = adult_fare_data[0].resdval;
                         }
                         let disc=Utilities.dicountCalculation(adult_fare_data[0].tax);
                         let serviceFee=[];
                         if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                             serviceFee=props.faredet.service_fee;
                         }else{
                             serviceFee=props.faredet.serviceFee;
                         }
                         let adtFee=0;
                         if(serviceFee!==null && serviceFee!==undefined && serviceFee.length!==0){
                             adtService = Utilities.calculateServiceFeePax(serviceFee,userType,"ADT",props.faredet.adt,paxCount);
                             if(adtService!==undefined && adtService!==0){
                               adtFee=adtService;
                               if(props.faredet.adt!==undefined){
                                adtService=adtService*props.faredet.adt;
                               }else{
                                adtService=adtService*adts.length;
                               }
                             }else{
                                adtService=0;
                             }
                         }

                         let adtplb=0;
                         if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.adt!==undefined){
                             adtplb=props.faredet.plb.adt;
                         }
                         let bagPrice=0;
                         if(pax.baggage!==undefined && pax.baggage.price!==undefined){
                            bagPrice=Number(pax.baggage.price);
                            if(bagAdtPrice===0){
                                bagAdtPrice=bagPrice;
                            }else{
                                bagAdtPrice=bagAdtPrice+bagPrice;
                            }
                            if(bagPrice!==0){
                              dispatch({ type: 'bagAvail', payload: true });
                            }
                         }
                         let mealPrice=0;
                         if(pax.meal!==undefined && pax.meal.price!==undefined){
                            mealPrice=Number(pax.meal.price);
                            if(mealAdtPrice===0){
                                mealAdtPrice=mealPrice;
                            }else{
                                mealAdtPrice=mealAdtPrice+mealPrice;
                            }
                            if(mealAdtPrice!==undefined){
                                dispatch({ type: 'mealAvail', payload: true });
                            }
                         }
                         let price=0;
                         if(pax.seats!==undefined && pax.seats.length!==0){
                            var seatsList = pax.seats.filter((obj) => obj.jtype === props.sector);
                            if(seatsList.length!==0){
                                for(let val of seatsList){
                                   if(seatAdtPrice===0){
                                    seatAdtPrice=val.price;
                                    price=val.price;
                                   }else{
                                    seatAdtPrice=seatAdtPrice+val.price;
                                    price=price+val.price;
                                   }
                                }
                               if(seatAdtPrice!==0){
                                   dispatch({ type: 'seatAvail', payload: true });
                                 }
                            }
                         }

                         let vat = 0;
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Adult';
                         let lead = pax.lead;
                         const obj = {
                             nm: pax_name,
                             lead: lead,
                             ty: paxtype,
                             bf: basefare + Number(onFlyPaxMkp),
                             tax: parseFloat(tax)+Number(attGst),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(adtplb),"Add"),
                             sf: parseFloat(adtFee),
                             gst:taGst,
                             penalty:penalty,
                             resVal:resVal,
                             vat: vat,
                             bagprice:bagPrice,
                             mealprice:mealPrice,

                             seatprice:price,
                             tf: totalfare+adtFee+bagPrice+mealPrice+Number(price)+paxOt+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)-parseFloat(adtplb),
                         }
                         pax_array.push(obj);

                         //if infant, constructing fare data
                         if (isNotNull(pax.inf) && inf_fare_data!==undefined && inf_fare_data.length!==0) {
                             let totalfare = inf_fare_data[0].tf;//total_fare
                             let basefare = inf_fare_data[0].bf;//base_fare
                             //let tax = inf_fare_data[0].tax;//tax
                             let tax=0;
                             let penalty=0;
                             let resVal=0;
                             if(props.butype!=="REISSUE"){
                                 tax=getTaxValue(inf_fare_data[0].tax);
                                }else{
                                 tax=inf_fare_data[0].tfd;
                                 penalty = inf_fare_data[0].penlty;
                                 resVal = inf_fare_data[0].resdval;
                                }
                             let disc=Utilities.dicountCalculation(inf_fare_data[0].tax);
                             let serviceFee=[];
                             if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                                 serviceFee=props.faredet.service_fee;
                             }else{
                                 serviceFee=props.faredet.serviceFee;
                             }
                             let infFee=0;
                             if(isNotNull(serviceFee) && serviceFee!==undefined && serviceFee.length!==0){
                                 infService = Utilities.calculateServiceFeePax(serviceFee,userType,"INF",props.faredet.inf,paxCount);
                                 if(infService!==undefined && infService!==0){
                                     infFee=infService;
                                     if(props.faredet.inf!==undefined){
                                        infService=infService*props.faredet.inf;
                                     }else{
                                        infService=infService*inf;
                                     }
                                 }else{
                                    infService=0;
                                 }
                             }
                             let infplb = 0;
                             if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.inf!==undefined){
                                 infplb=props.faredet.plb.inf;
                             //Per pax PLB
                            //  if(infplb!==undefined && infplb!==0){
                            //      infplb=infplb/props.faredet.inf;
                            //  }
                             }
                             let vat = 0;
                             let pax_name = pax.inf.fn + ' ' + pax.inf.ln;
                             let paxtype = 'Infant';

                             const obj = {
                                 nm: pax_name,
                                 ty: paxtype,
                                 bf: basefare+Number(onFlyPaxMkp),
                                 tax: parseFloat(tax)+Number(attGst),
                                 disc:Utilities.addPrice(parseFloat(disc),parseFloat(infplb),"Add"),
                                 sf: parseFloat(infFee),
                                 gst:taGst,
                                 penalty:penalty,
                                 resVal:resVal,
                                 vat: vat,
                                 seatprice:0.00,
                                 tf: totalfare+infFee+paxOt+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)-parseFloat(infplb),
                                 bagprice:"N/A",
                                 mealprice:"N/A"
                             }
                             pax_array.push(obj);
                         }

                     }//if child, constructing fare data
                     else if (pax.ty.toUpperCase() === 'CHD') {

                         let totalfare = chd_fare_data[0].tf;//total_fare
                         let basefare = chd_fare_data[0].bf;//base_fare
                         //let tax = chd_fare_data[0].tax;//tax
                         let tax=0;
                         let penalty=0;
                         let resVal=0;
                         if(props.butype!=="REISSUE"){
                             tax=getTaxValue(chd_fare_data[0].tax);
                            }else{
                             tax=chd_fare_data[0].tfd;
                             penalty = chd_fare_data[0].penlty;
                             resVal = chd_fare_data[0].resdval;
                            }
                         let disc=Utilities.dicountCalculation(chd_fare_data[0].tax);
                         let serviceFee=[];
                             if(props.faredet.service_fee!==null && props.faredet.service_fee!==undefined && props.faredet.service_fee.length!==0){
                                 serviceFee=props.faredet.service_fee;
                             }else{
                                 serviceFee=props.faredet.serviceFee;
                             }
                             let chdFee=0;
                         if(isNotNull(serviceFee) && serviceFee!==undefined && serviceFee.length!==0){
                             chdService = Utilities.calculateServiceFeePax(serviceFee,userType,"CHD",props.faredet.chd,paxCount);
                             if(chdService!==undefined && chdService!==0){
                                 chdFee=chdService;
                                 if(props.faredet.chd!==undefined){
                                    chdService=chdService*props.faredet.chd;
                                 }else{
                                    chdService=chdService*chds.length;
                                 }
                             }else{
                                chdService=0;
                             }
                         }

                         let chdplb = 0;
                         if(props.faredet.plb!==undefined && Object.keys(props.faredet.plb).length !== 0 && props.faredet.plb!==null && props.faredet.plb.chd!==undefined){
                             chdplb=props.faredet.plb.chd;
                             //Per pax PLB
                            //  if(chdplb!==undefined && chdplb!==0){
                            //      chdplb=chdplb/props.faredet.chd;
                            //  }
                         }
                         let vat = 0;
                         let pax_name = pax.fn + ' ' + pax.ln;
                         let paxtype = 'Child';
                         let bagPrice=0;
                         if(pax.baggage!==undefined && pax.baggage.price!==undefined){
                            bagPrice=Number(pax.baggage.price);
                            if(bagChdPrice===0){
                                bagChdPrice=bagPrice;
                            }else{
                                bagChdPrice=bagChdPrice+bagPrice;
                            }
                            if(bagPrice!==0){
                                dispatch({ type: 'bagAvail', payload: true });
                              }
                         }
                         let mealPrice=0;
                         if(pax.meal!==undefined && pax.meal.price!==undefined){
                            mealPrice=Number(pax.meal.price);
                            if(mealChdPrice===0){
                                mealChdPrice=mealPrice;
                            }else{
                                mealChdPrice=mealChdPrice+mealPrice;
                            }
                            if(mealChdPrice!==undefined){
                                dispatch({ type: 'mealAvail', payload: true });
                             }
                         }
                         let price=0;
                         if(pax.seats!==undefined && pax.seats.length!==0){
                            var seatsList = pax.seats.filter((obj) => obj.jtype === props.sector);
                            if(seatsList.length!==0){
                                for(let val of seatsList){
                                   if(seatChdPrice===0){
                                    seatChdPrice=val.price;
                                    price=val.price;;
                                   }else{
                                    seatChdPrice=seatChdPrice+val.price;
                                    price=price+val.price;;
                                   }
                                }
                               if(seatChdPrice!==0){
                                   dispatch({ type: 'seatAvail', payload: true });
                                 }
                            }
                         }
                         const obj = {
                             nm: pax_name,
                             ty: paxtype,
                             bf: basefare+Number(onFlyPaxMkp),
                             tax: parseFloat(tax)+Number(attGst),
                             disc:Utilities.addPrice(parseFloat(disc),parseFloat(chdplb),"Add"),
                             sf: parseFloat(chdFee),
                             gst:Number(taGst),
                             penalty:penalty,
                             resVal:resVal,
                             vat: vat,
                             bagprice:bagPrice,
                             mealprice:mealPrice,
                             seatprice:price,
                             tf: totalfare+chdFee+bagPrice+mealPrice+Number(price)+paxOt+Number(onFlyPaxMkp)+Number(attGst)+Number(taGst)-parseFloat(chdplb)
                         }
                         pax_array.push(obj);

                     } else {
                         const obj = { nm: '', ty: '', bf: 0, tax: 0, sf: 0, vat: 0, tf: 0 }
                         pax_array.push(obj);
                     }


                }

             dispatch({ type: 'fareobj', payload: pax_array });
             dispatch({ type: 'grand_total', payload: grandtotal+parseFloat(adtService)+parseFloat(adtReturnService)+parseFloat(chdService)+parseFloat(chdReturnService)+parseFloat(infService)+parseFloat(infReturnService)+parseFloat(bagAdtPrice)+parseFloat(bagChdPrice)+parseFloat(mealAdtPrice)+parseFloat(mealChdPrice)+parseFloat(seatAdtPrice)+parseFloat(seatChdPrice)+parseFloat(totalOtCharge)+parseFloat(onFlyMarkup)+parseFloat(total_gst)-parseFloat(plbdisc) });
         }

     }, []);

     /**
      * @description:gettaxvalue
      * @date:11-12-2020
      * @author:Rambabu
      */
     function getTaxValue(taxarry) {
         let tax = 0
         for (let t in taxarry) {
             let tax_d = taxarry[t];
             if (isNotNull(tax_d.YQ)) {
                 tax = tax+tax_d.YQ;
             }
             if (isNotNull(tax_d.YR)) {
                 tax = tax+tax_d.YR;
             }
             if (isNotNull(tax_d.OT)) {
                 tax = tax+tax_d.OT;
             }
         }
         return tax;
     }


     return (

         isNotNull(state.fareobj) && (
             <>
             <div className='table-responsive mobileTable'>
                 <div className="header headerlist">

                     <Row>
                         <Col xs="2">Traveller </Col>
                         <Col>Pax Type</Col>
                         {props.butype!=="REISSUE" ? (
                         <Col>Fare</Col>
                         ):(
                         <Col>Fare Diffrence</Col>
                         )}
                         {props.butype!=="REISSUE" ? (
                         <Col>Taxes & Fee</Col>
                         ):(
                         <Col>Taxes Diffrence</Col>
                         )}
                         {props.butype!=="REISSUE" &&
                         <Col>Discount</Col>
                         }
                         <Col>Service Fee</Col>
                         {props.currency!==undefined && props.currency==="INR" &&
                         <Col>GST</Col>
                         }
                         {
                         state.bagAvail &&
                         <Col>Baggage</Col>
                         }
                         {
                         state.mealAvail &&
                         <Col>Meal</Col>
                         }
                        {state.seatAvail &&
                         <Col>Seat</Col>
                         }
                         {state.otCharge!==0 &&
                         <Col>OT Charge</Col>
                         }
                         {props.butype==="REISSUE" &&
                         <>
                         <Col>Penalty</Col>
                         </>
                         }
                          {props.butype==="REISSUE" &&
                         <>
                         <Col>Res.Value</Col>
                         </>
                         }

                         {props.butype!=="REISSUE" ? (
                         <Col className="text-right">Total</Col>
                         ):(
                         <Col  className="text-right">Total Collection</Col>
                         )}
                     </Row>

                 </div>
                 <div className="passengerList">


                 {/* confirmation page fare details onward fare and return fare adding and displaying. */}
                     {state.fareobj.map((fdet, index) => (
                        <div className='list'>
                         <React.Fragment key={index}>
                             <Row>
                                 <Col xs="2">{fdet.nm.toUpperCase()}{(isNotNull(fdet.lead) && fdet.lead) && '(Lead)'}</Col>
                                 <Col>{fdet.ty}</Col>
                                 <Col>{props.currency} {<FormateCurrency value={fdet.bf} />}</Col>
                                 <Col>{props.currency} {<FormateCurrency value={fdet.tax} />}</Col>
                                 {props.butype!=="REISSUE" &&
                                  <Col>{props.currency} {<FormateCurrency value={fdet.disc} />}</Col>
                                 }
                                 <Col>{props.currency} {<FormateCurrency value={fdet.sf} />}</Col>
                                 {props.currency!==undefined && props.currency==="INR" &&
                                 <Col>{props.currency} {<FormateCurrency value={fdet.gst} />}</Col>
                                 }
                                 {state.bagAvail && (fdet.bagprice == "N/A" ? <Col> QAR 0.00</Col>:
                                    (<Col>  {props.currency} {<FormateCurrency value={fdet.bagprice }  />}</Col>))
                                 }
                                 {state.mealAvail && (fdet.mealprice == "N/A" ? <Col>QAR 0.00</Col>:<Col>{props.currency} {<FormateCurrency value={fdet.mealprice} />}</Col>)
                                 }
                                 {state.seatAvail &&
                                  <Col>{props.currency} {<FormateCurrency value={fdet.seatprice} />}</Col>
                                 }
                                 {state.otCharge!==0 &&
                                  <Col>{props.currency} {<FormateCurrency value={state.otCharge} />}</Col>
                                 }
                                 {props.butype==="REISSUE" &&
                                 <Col>{props.currency} {<FormateCurrency value={fdet.penalty} />}</Col>
                                 }
                                 {props.butype==="REISSUE" &&
                                 <Col>{props.currency} {<FormateCurrency value={fdet.resVal} />}</Col>
                                 }
                                 <Col  className="text-right">{props.currency} {<FormateCurrency value={fdet.tf} />}</Col>
                                 </Row>
                         </React.Fragment>
                         </div>
                     ))}

                     <div className="total d-flex justify-content-end">
                         <span>Grand Total : </span> <div className="pricetag"><em>{props.currency}</em> <strong>{<FormateCurrency value={state.grand_total} />}</strong></div>
                     </div>

                 </div>
             </div>
             </>))
 }


 export default TFFareDetails;
