import React, { useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getAllLocations} from '../../dashBoard/operations';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

 function SaudiActivities() {
  const [locationsList, setLocationsList] = useState([]);

  useEffect(() => {
    getAllLocations().then(response => {
      if (response.suc &&  response.res) {
        setLocationsList(response.res)
      }
    }).catch(function (error) {
      console.log('LocationsList response error ', error)
    });
  },[])

  const trendinsettings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 3,
        autoplay: false,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

    return (
        <>
       <Slider {...trendinsettings}>
         {locationsList.length > 0 && locationsList.map((locations)=>(
           <>
           {locations.active &&
           <div className='slideImgsec'>
            <img src={locations.url} title="Abha" alt="Abha" width={250}/>
            <div className='breatTitle'>

              </div>
              </div>
           }
           </>
         ))}
         {locationsList.length === 0 &&
            <span>No data found</span>
          }
      </Slider>
        </>
          )
    }


export default SaudiActivities;