import React, { useContext, useEffect, useReducer, useState} from "react";
import { Alert, Col, Form, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../App";
import Breadcrumb from "../../../components/common/breadcrumb/Breadcrumb";
import Button from "../../../components/common/buttons/Button";
import Footer from "../../../components/common/Footer";
import InnerLoader from "../../../components/common/loader/InnerLoader";
import MainMenu from "../../../components/common/MainMenu";
import FileUpload from "../../commonUtils/FileUpload";
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';
import log from "loglevel";
import { saveVisaConfig , updateVisaConfig,getVisConfiguration} from "./operations";
import Select from "react-dropdown-select";
import RegionType from "../common/RegionType";
import SalesChannel from "../common/SalesChannel";
import VisaMenu from "../../common/subMenu/VisaMenu";
import VisaDocuments from "../../adminstration/visaServices/VisaDocuments"
import VisaPaxInfo from "../../adminstration/visaServices/VisaPaxInfo"


/**
 * Initial State Declaration
 */
const initialState = {
  "region_type":"",
  "sales_chanel":"",
  "na" :"",
  "trvel_cnt" :"",
  "na_code": "",
  "trvel_cnt_code": "",
  "visa" :"",
  "visa_type" :"",
  "entry_type" :"",
  "category" :"",
  "dur_type":"",
  "dur":"",
  "process_time":"",
  "visa_validity":"",
  "stay_validity":"",
  "description":"",
  "adt_fee":0,
  "adt_tax":0,
  "adt_serviceFee":0,
  "chd_fee":0,
  "chd_serviceFee":0,
  "chd_tax":0,
  "docs_info":[],
  "pax_info":[],
  "pax_resp":[]
};

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case "clear":
      return {
        ...state,
        "region_type":"",
        "sales_chanel":"",
        "na" :"",
        "trvel_cnt" :"",
        "na_code": "",
        "trvel_cnt_code": "",
        "visa" :"",
        "visa_type" :"",
        "entry_type" :"",
        "category" :"",
        "dur_type":"",
        "dur":"",
        "process_time":"",
        "visa_validity":"",
        "stay_validity":"",
        "description":"",
        "adt_fee":0,
        "adt_tax":0,
        "adt_serviceFee":0,
        "chd_fee":0,
        "chd_serviceFee":0,
        "chd_tax":0,
        "docs_info":[],
        "pax_info":[]
      };
    case "editVisa":
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function VisaConfiguration(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [validated, setValidated] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [enableView, setEnableView] = useState(false);
  const [countries, setCountries] = useState([]);
  const [valuess, setValuess] = useState([]);
  const [visaTypes, setVisaTypes] = useState([{value:"Standard Visa",label:"Standard Visa"},{value:"Express Visa",label:"Express Visa"}]);
  const [entryTypes, setEntryTypes] = useState([{value:"Single Entry",label:"Single Entry"},{value:"Multiple Entry",label:"Multiple Entry"}]);
  const [visaList, setVisaList] = useState([{value:"E- Visa",label:"E- Visa"},{value:"Normal Visa",label:"Normal Visa"}]);
  const [categoryList, setCategoryList] = useState([{value:"Tourist",label:"Tourist"},{value:"Student",label:"Student"},{value:"Transit",label:"Transit"},{value:"Medical",label:"Medical"},{value:"Business",label:"Business"}]);
  const [durationTypes, setDurationTypes] = useState([{value:"Days",label:"Days"},{value:"Hours",label:"Hours"}]);
  const [notiMessage, setNotiMessage] = useState("");
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [request, setRequest] = useState(props.location);
  const [urlsList,setUrlsList] = useState([]);
  const [formData, setFormData] = useState([]);



  useEffect(() => {
    AttCommonSerivce.getCountryList().then((response) => {
      if (response.data.suc) {
        let transformedCountries = response.data.res.map(country => ({
          value: country.name,
          label: country.name,
          code: country.code
      }));          
      setCountries(transformedCountries);
      }
    })
    .catch(function (error) {
      log.error("Exception occured in getCountryList function---" + error);
    });
  }, []);

  useEffect(() => {
    if(request!==undefined && request.state &&request.state.req&& request.state.req!==undefined){
        getVisConfiguration(request.state.req.id).then((res) => {
            if(res.data.suc){
              setEnableUpdate(true)
                if(request.state.req.flow_type==="view"){
                  setEnableView(true)
                }
                dispatch({ type: "editVisa", payload: res.data.res }); 
                setValuess(res.data.res)               
                dispatch({ type: "adt_fee", payload: res.data.res.adt_charge.fee});
                dispatch({ type: "adt_tax", payload: res.data.res.adt_charge.tax});
                dispatch({ type: "adt_serviceFee", payload: res.data.res.adt_charge.service_fee});
                dispatch({ type: "chd_fee", payload: res.data.res.chd_charge.fee});
                dispatch({ type: "chd_tax", payload: res.data.res.chd_charge.tax});
                dispatch({ type: "chd_serviceFee", payload: res.data.res.chd_charge.service_fee});
                dispatch({ type: "pax_resp", payload: res.data.res.pax_info});
                updateFileName(res.data.res.uploads[0].url,res.data.res.uploads[0].name) 
                
            }
          });
    }
}, [request]);
 
  const handleSalesChanel=(chnel)=>{
    dispatch({ type: "sales_chanel", payload: chnel });
  }
  const handleRegionChange=(region)=>{
    dispatch({ type: "region_type", payload: region });
  }

  const handleNaChange = (e) => {
    if (e[0]) {
        dispatch({ type: "na", payload: e[0].label });
        dispatch({ type: "na_code", payload: e[0].code });

    }
  };
  const handleDurationTypeChange = (e) => {
    if (e[0]) {
        dispatch({ type: "dur_type", payload: e[0].label });
    }
  };
  const handleTravelChange = (e) => {
    if (e[0]) {
        dispatch({ type: "trvel_cnt", payload: e[0].label });
        dispatch({ type: "trvel_cnt_code", payload: e[0].code });

    }
  };

  const validateVisa=()=>{
      if(state.region_type===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Region type");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.sales_chanel===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Sales channal");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.na===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Nationality");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.trvel_cnt===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Travelling Country");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.visa===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Visa");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.visa_type===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Visa Type");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }else if(state.entry_type===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Entry Type");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.dur===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Duration");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.process_time===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Processing Time");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.visa_validity===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Visa validity");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.stay_validity===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Stay Validity");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.adt_fee===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Visa Fee");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.adt_tax===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Tax");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.adt_serviceFee===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Service Fee");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.chd_serviceFee===""){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Child Service Fee");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.docs_info.length ===0){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Visa Documents info");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.pax_info.length ===0){
        setNotiMessageShow(true);
        setNotiMessage("Please enter Visa Passengers details");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      else if(state.category===""){
        setNotiMessageShow(true);
        setNotiMessage("Please select Category");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
      if(state.na===state.trvel_cnt){
        setNotiMessageShow(true);
        setNotiMessage("Nationality and Traveling Country should not be same");
        setNotiVarient("danger");
        hidemessage()
        return false;
      }
    return true;
  }

  const handleSaveVisa=(type)=>{
    if(validateVisa()){
        const adt={
             "fee":state.adt_fee,
             "service_fee":state.adt_serviceFee,
             "tax" :state.adt_tax
        }
        const chd={
            "fee":state.chd_fee,
            "service_fee":state.chd_serviceFee,
            "tax" :state.chd_tax
       }
       state.adt_charge=adt;
       state.chd_charge=chd;
       state.visa_uploads=urlsList;
       state.crby=context.logindata.uid;
       if(type==="Save"){
        // let payload={
        //   "adt_charge":adt,
        // "category": state.category[0].label,
        // "chd_charge": chd,
        // "crby": state.crby,
        // "description": state.description,
        // "docs_info": state.docs_info,
        // "dur": state.dur,
        // "dur_type": state.dur_type,
        // "entry_type": state.entry_type[0].label,
        // "na": state.na,
        // "pax_info":state.pax_info,
        // "process_time": state.process_time,
        // "region_type": state.region_type,
        // "sales_chanel": state.sales_chanel,
        // "stay_validity": state.stay_validity,
        // "trvel_cnt": state.trvel_cnt,
        // "visa": state.visa[0].label,
        // "visa_type": state.visa_type[0].label,
        // "visa_uploads": [
        //     {
        //         "name": "test",
        //         "url": "First"
        //     }
        // ],
        // "visa_validity": state.visa_validity
        // }
        setIsLoadingMore(true)
        saveVisaConfig(state).then((response) => {
          if(response.data.suc) {
            setNotiMessageShow(true);
            setNotiMessage("Visa saved successfully");
            setNotiVarient("success");
            hidemessage()
            setIsLoadingMore(false)
            return window.location="/ezytrip/administration/visa";
            }else{
                setNotiMessageShow(true);
                setNotiMessage("Visa Not saved...");
                setNotiVarient("danger");
                hidemessage()
            }
          });
       }else{
        state.upby=context.logindata.uid;
        state.visa_uploads=urlsList;
        state.id=request.state.req.id;
        setIsLoadingMore(true)
        updateVisaConfig(state).then((response) => {
          if(response.data.suc) {
            setNotiMessageShow(true);
            setNotiMessage("Visa updated successfully");
            setNotiVarient("success");
            hidemessage()
            setIsLoadingMore(false)
            return window.location="/ezytrip/administration/visa";
          }else{
               setIsLoadingMore(false)
                setNotiMessageShow(true);
                setNotiMessage("Visa Not updated...");
                setNotiVarient("danger");
                hidemessage()
            }
          });
       }
       }
   
  }

  function hidemessage() {
    setTimeout(function () {
      setNotiMessageShow(true);
      setNotiMessage("");
      setNotiVarient("");
    }, 5000);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }
  const handleNumberChange = (e) => {
    const { id, value } = e.target;
    if(parseInt(value)>-1){
      dispatch({ type: id, payload: value });

    }
  }
  const handleDataChange = (newData) => {
    setFormData(newData);
    
    dispatch({ type: "docs_info", payload: newData });

};
const handleDataChangePax = (formattedData) => {
  console.log(formattedData); // Or do whatever you want with the data
  
  dispatch({ type: "pax_info", payload: formattedData });

};
  const cancelVisa=()=>{
    window.location="/ezytrip/administration/visa";
  }

  const updateFileName = (url,name) => {
    if(urlsList.length===0){
      setUrlsList([{"name":name,"url":url}])
    }else{
      let list=[...urlsList]
      list.push({"name":name,"url":url});
      setUrlsList(list)
    }
  };


  return (
    <>
      <MainMenu active="Visa" />
      <Breadcrumb activePage="Visa Configuration" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <VisaMenu active="visa" />
          <div className="contentArea">
          {isLoadingMore ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
            <Alert
              className="notification"
              variant={notiVarient}
              show={notiMessageShow}
              onClose={() =>
                setNotiMessageShow(false)
              }
              dismissible
            >
              {" "}
              {notiMessage}
            </Alert>
            <Form noValidate validated={validated} id="formId">
              <Form.Row>
                 <SalesChannel sendSalesChanel={handleSalesChanel} 
                  edit_sales={enableUpdate?state.sales_chanel:""}/>
                  <>
                    <RegionType
                      regionType={handleRegionChange}
                      callBranch={true}
                      regionUpdate={enableUpdate?state.region_type:""}
                    />
                  </>
              </Form.Row>
              <h4 className="subTitle plusHr">Visa Details</h4>
              <Form.Row>
                 <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="na"
                  >
                  <Select
                    options={countries}
                    values={valuess.nationality?[{value:valuess.nationality, label:valuess.nationality}]:[]}
                    onChange={handleNaChange}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Nationality<sup>*</sup>
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="trvel_cnt"
                >
                  <Select
                    options={countries}
                    values={valuess.travel_country?[{value:valuess.travel_country, label:valuess.travel_country}]:[]}
                    onChange={handleTravelChange}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Travelling Country<sup>*</sup>
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="visa_type"
                  >
                  <Select
                    options={visaTypes}
                    values={valuess.visa_type?[{value:valuess.visa_type, label:valuess.visa_type}]:[]}
                    onChange={(value) =>  dispatch({ type: "visa_type", payload: value[0].label })}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Visa Type<sup>*</sup>
                  </Form.Label>
             </Form.Group>
              </Form.Row>
              <Form.Row>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="entry_type"
                  >
                  <Select
                    options={entryTypes}
                    values={valuess.entry_type?[{value:valuess.entry_type, label:valuess.entry_type}]:[]}
                    onChange={(value) =>  dispatch({ type: "entry_type", payload: value[0].label })}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Entry Type<sup>*</sup>
                  </Form.Label>
             </Form.Group>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="category"
                  >
                  <Select
                    options={categoryList}
                    values={valuess.category?[{value:valuess.category, label:valuess.category}]:[]}
                    onChange={(value) =>  dispatch({ type: "category", payload: value[0].label })}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Category<sup>*</sup>
                  </Form.Label>
             </Form.Group>
             <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="visa"
                  >
                  <Select
                    options={visaList}
                    values={valuess.visa?[{value:valuess.visa, label:valuess.visa}]:[]}
                    onChange={(value) =>  dispatch({ type: "visa", payload: value[0].label })}
                    disabled={enableUpdate}
                  />
                  <Form.Label>
                    Visa<sup>*</sup>
                  </Form.Label>
             </Form.Group>
              </Form.Row>
              <h4 className="subTitle plusHr">Visa Duration</h4>
              <Form.Row>
                 <Form.Group
                  as={Col}
                  xs={3}
                  className="selectplugin"
                  controlId="dur_type"
                  >
                  <Select
                    options={durationTypes}
                    values={valuess.dur_type?[{value:valuess.dur_type, label:valuess.dur_type}]:[]}
                    onChange={handleDurationTypeChange}
                    disabled={enableView}
                  />
                  <Form.Label>
                    Duration Type<sup>*</sup>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={3} controlId="dur">
                 <Form.Control type="number" placeholder="Duration" autoComplete="off"
                 value={state.dur} onChange={handleNumberChange} 
                 disabled={enableView}/>
                 <Form.Label>Duration<sup>*</sup></Form.Label>
              </Form.Group>
              </Form.Row>
              <Form.Row>
              <Form.Group as={Col} xs={3} controlId="process_time">
                 <Form.Control type="number" placeholder="Processing Time" autoComplete="off"
                 value={state.process_time} onChange={handleNumberChange} disabled={enableView} />
                 <Form.Label>Processing Time<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="visa_validity">
                 <Form.Control type="number" placeholder="Visa Validity" autoComplete="off"
                 value={state.visa_validity} onChange={handleNumberChange} disabled={enableView} />
                 <Form.Label>Visa Validity<sup>*</sup></Form.Label>
              </Form.Group>
              </Form.Row>
              <Form.Row>
              <Form.Group as={Col} xs={3} controlId="stay_validity">
                 <Form.Control type="number" placeholder="Stay Validity" autoComplete="off"
                 value={state.stay_validity} onChange={handleNumberChange} disabled={enableView} />
                 <Form.Label>Stay Validity<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="description">
                 <Form.Control type="textarea" placeholder="Description" autoComplete="off"
                 value={state.description} onChange={handleChange} disabled={enableView} />
                 <Form.Label>Description</Form.Label>
              </Form.Group>
              </Form.Row>
              <h4 className="subTitle plusHr">Visa Charges</h4>
              <span className="visaChad">Adult</span>
              <Form.Row>
               
              <Form.Group as={Col} xs={3} controlId="adt_fee">
                 <Form.Control type="number" placeholder="Visa Fee" autoComplete="off"
                 value={state.adt_fee} onChange={handleNumberChange} disabled={enableView} />
                 <Form.Label>Visa Fee<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="adt_tax">
                 <Form.Control type="number" placeholder="Enter Tax" autoComplete="off"
                 value={state.adt_tax} onChange={handleNumberChange} disabled={enableView}/>
                 <Form.Label>Tax <sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="adt_serviceFee">
                 <Form.Control type="number" placeholder="Enter Service Fee" autoComplete="off"
                 value={state.adt_serviceFee} onChange={handleNumberChange} disabled={enableView}/>
                 <Form.Label>Service Fee <sup>*</sup></Form.Label>
              </Form.Group>
              </Form.Row>
              <span className="visaChad">Child</span>
              <Form.Row>
              <Form.Group as={Col} xs={3} controlId="chd_fee">
                 <Form.Control type="number" placeholder="Visa Fee" autoComplete="off"
                 value={state.chd_fee} onChange={handleNumberChange} disabled={enableView}/>
                 <Form.Label>Visa Fee<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="chd_tax">
                 <Form.Control type="number" placeholder="Enter Tax" autoComplete="off"
                 value={state.chd_tax} onChange={handleNumberChange} disabled={enableView}/>
                 <Form.Label>Tax<sup>*</sup></Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="chd_serviceFee">
                 <Form.Control type="number" placeholder="Service Fee" autoComplete="off"
                 value={state.chd_serviceFee} onChange={handleNumberChange} disabled={enableView} />
                 <Form.Label>Service Fee<sup>*</sup></Form.Label>
              </Form.Group>
              </Form.Row>
                        <h4 className="subTitle plusHr">Visa Documents</h4>
             
             <Form.Row>
              <Form.Group as={Col}>
              <VisaDocuments docs_info={state.docs_info} onDataChange={handleDataChange} enableUpdate ={enableUpdate} enableView={enableView} />
              </Form.Group>
             
             </Form.Row>
            <h4 className="subTitle plusHr">Visa Passengers</h4>
            
             <Form.Row>
             <Form.Group as={Col}>
             <VisaPaxInfo pax_resp={state.pax_resp} onDataChangePax={handleDataChangePax} enableUpdate ={enableUpdate} enableView={enableView}/>
             </Form.Group>
             </Form.Row>
             <h4 className="subTitle plusHr">Upload Visa</h4>
             <Form.Row>
             <FileUpload
                reportUrls={""}
                type={"Visa"}
                sendFileUrl={updateFileName}
                urlsList={urlsList}
                />
             </Form.Row>
            </Form>
            <div className="text-right buttonGrop pb-0">
                    <Button
                  size="xs"
                  variant="outline-secondary"
                  onClick={cancelVisa}
                  type="submit"
                >
                  {" "}
                  Cancel
                </Button>
                {!enableUpdate && !enableView &&
                <Button
                  size="xs"
                  variant="outline-primary" 
                  onClick={()=>handleSaveVisa("Save")}
                  type="submit"
                >
                  {" "}
                  Save
                </Button>
                 }
                 {enableUpdate && !enableView &&
                <Button
                  size="xs"
                  variant="outline-secondary"
                  onClick={()=>handleSaveVisa("Update")}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
                 }
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VisaConfiguration;
