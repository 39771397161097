import axios from 'axios'

const API_URL = process.env.REACT_APP_PRE_BOOK_URL
/**
 * @author: Lakshmi
 * @description: Integrated the Markup CURD Operations for Hold Bookings
 * @param {*} Service 
 */

class MaxOnholdService {
    async saveMaxHoldBooking(data) {
        console.log("API_URL",API_URL)
        return await axios.post(`${API_URL}/hotelonholdconfig/onholdconfig`,data)  
      }
      async retrieveMaxHoldBookings(obj) {
        return await axios.post(`${API_URL}/hotelonholdconfig/retriveonholdconfig`,obj)
    }
    }

export default new MaxOnholdService()