import React, { useState, useEffect, useReducer } from 'react';
/**
 * @description:This function will Calculate the Room wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 12-02-2021
 */

/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function CancellationPolicy(props) {


  return (
    <>
      {props.isRefund && props.canPolicy!==undefined && props.canPolicy.length!==0 &&
       <p className="plcy toolTipInfo">Cancellation Policy
              <span className="rcmd ezyIcon icon-info"></span>
          <div className="toolTipCUstoom cnclInfo">
            <div className="toolTipCont">
              <strong className="ezyIcon icon-cancellation"> Cancellation Policy</strong>
              {props.canPolicy.map((canp, index) => (
                <React.Fragment key={index}>
                <p>{canp}</p>
                </React.Fragment>
              ))}
            </div>
          </div>
        </p>
    }
    {!props.isRefund &&
       <p className="plcy toolTipInfo">Cancellation Policy
              <span className="rcmd ezyIcon icon-info"></span>
          <div className="toolTipCUstoom cnclInfo">
            <div className="toolTipCont">
            <strong className="ezyIcon icon-cancellation"> Cancellation Policy</strong>
             <p>Non Refundable : If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws.</p>
            </div>
          </div>
        </p>
    }
    </>

  )
}
export default CancellationPolicy