
import React, { useState , useEffect} from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';

function TransactionSummaryPdf(props) {
    const [state, setState] = useState({})
    const [flightSearch, setFlightSearch] = useState();
    const [bkdata , setBkData] = useState(props.bkngdetails);
    const [bookid, setBookid]=  useState({selBooking:[]})
    const [bookingList, setBookingList]=  useState([])

    return (
        <>
            <Row>
            <Col xs={4}>
                    <Row>
                        <Col xs={12}><span>Customer Name : </span>{bkdata.customer_name}</Col>
                        <Col xs={12}><span>Phone  : </span>{bkdata.isd_code} {bkdata.phone_no} </Col>
                        <Col xs={12}><span>Email  : </span>{bkdata.email}</Col>
                    </Row>
                </Col>
                <Col xs={4}>
                    <Row>
                        <Col xs={12}><span>Booking Ref Number : </span> {props.bkngdetails.booking_id}</Col>
                        {/*   <Col xs={12}><span>Booking Date:</span> 16 Jun, 2020   3:50</Col> */}
                        <Col xs={12}><span>Booking Date : </span> {DateUtils.prettyDate(bkdata.booking_date, "ddd, DD MMM, YYYY")}</Col>
                        <Col xs={12} className="d-flex align-items-center"><span> Booking Status : </span>
                        {!props.enableEdit && <>

                            {bkdata.booking_status_str==="Cancelled" &&
                            <span className="status paxstatus text-danger"> Cancelled</span>
                            }
                            {(bkdata.booking_status_str == "On hold" || bkdata.booking_status_str == "On Hold") &&
                            <span className="status paxstatus"> On hold</span>
                            }
                            {bkdata.booking_status_str === "Confirmed" &&
                            <span className="status paxstatus text-success"> Confirmed</span>
                            }
                             {bkdata.booking_status_str === "Partially Confirmed" &&
                            <span className="status paxstatus text-success"> Confirmed</span>
                            }
                             {bkdata.booking_status_str === "Incomplete" &&
                            <span className="status paxstatus text-danger"> {bkdata.booking_status_str}</span>
                            }
                        </>
                        }
                        </Col>
                    </Row>
                </Col>


                <Col xs={4}>
                    <Row>
                    <Col xs={12}><span>Channel : </span> Online</Col>
                        <Col xs={12}><span>Agency : </span>{bkdata.company_name}</Col>
                        <Col xs={12}><span>Booked By : </span> {bkdata.booked_by}</Col>
                    </Row>
                </Col>
            </Row>







        </>
    )
}


export default TransactionSummaryPdf;
