import React, { useEffect, useReducer, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputRange from "react-input-range";
import { Link, useHistory } from "react-router-dom";
import AirlinesMemo from "../../../../common/airlines";
import AirportMemo from "../../../../common/airlines/airportNames";
import DateUtils from "../../../../commonUtils/DateUtils";
import Utilities from "../../../../commonUtils/Utilities";
import FormateCurrency from "../../../../commonUtils/FormateCurrency";
import StickyBox from "react-sticky-box/dist/esnext";
import moment from "moment";

//Initial State
const initialState = {
  /* response: {}, finalResponse: {}, price: { min: 0, max: 0 }, onwardDepTime: { min: 0, max: 24 },
    onwardArrivalTime: { min: 0, max: 24 }, returnDeptTime: { min: 0, max: 24 },
    returnArrivalTime: { min: 0, max: 24 },  stopsList: [], airlines: [],
    onwardDep: true, onwardArrrival: false, returnDep: true, returnArrrival: false,
    airports:[],mapping:[],
    resType: '' */
  owPrice: { min: 0, max: 0 },
  arPrice: { min: 0, max: 0 },
  onwardDep: true,
  onwardArrrival: false,
  returnDep: true,
  returnArrrival: false,
  onwardArrivalTime: { min: 0, max: 24 },
  returnDeptTime: { min: 0, max: 24 },
  stopsList: [],
  onwardDepTime: { min: 0, max: 24 },
  returnArrivalTime: { min: 0, max: 24 },
  onwardLayoversList: "",
  returnLayoversList: "",
  airlinesOW: [],
  airlinesAR: [],
  refundable: false,
  priceOW: "",
  priceRT: "",
  airportOW: [],
  airportRT: [],
  supplierDEP: [],
  supplierAR: [],
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case "min":
      return { ...state, price: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//Main Function
function FilterSplit(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [owSearchRes, setOwSearchRes] = useState(props.owSearchRes);
  const [rtSearchRes, setRtSearchRes] = useState(props.rtSearchRes);
  const [owSearchResFinal, setOwSearchResFinal] = useState(
    Object.assign({}, props.initialowSearchRes)
  );
  const [rtSearchResFinal, setRtSearchResFinal] = useState(
    Object.assign({}, props.initialrtSearchRes)
  );

  const [owMapping, setOwMapping] = useState(props.owSearchRes.mappings);
  const [arMapping, setArMapping] = useState(props.rtSearchRes.mappings);
  const [owFilters, setOwFilters] = useState(props.owSearchRes.filter);
  const [arFilters, setArFilters] = useState(props.rtSearchRes.filter);
  const [showArrivalAirlines, setShowArrivalAirlines] = useState(false);
  const [showNearbyAirports, setshowNearbyArrAirports] = useState(false);
  const [showDepartureAirlines, setShowDepartureAirlines] = useState(false);

  useEffect(() => {
    dispatch({
      type: "owPrice",
      payload: {
        min: props.initialowSearchRes.filter.minprc,
        max: props.initialowSearchRes.filter.maxprc,
      },
    });
    dispatch({
      type: "arPrice",
      payload: {
        min: props.initialrtSearchRes.filter.minprc,
        max: props.initialrtSearchRes.filter.maxprc,
      },
    });
    /*  dispatch({ type: 'rtSearchRes', payload: props.rtSearchRes});
         dispatch({ type: 'owMapping', payload: props.owSearchRes.mapping });
         dispatch({ type: 'arMapping', payload: props.rtSearchRes.mapping });
         dispatch({ type: 'owFilters', payload: props.owSearchRes.filter });
         dispatch({ type: 'arFilters', payload: props.rtSearchRes.filter }); */
  }, []);

  // Price Filter Logic
  const onPriceChange = (value, type) => {
    if (type === "ow") {
      dispatch({ type: "owPrice", payload: value });
      let res = owSearchResFinal.origns.filter((flight) => {
        return flight.pr.tf >= value.min && flight.pr.tf <= value.max;
      });
      owSearchRes.origns = res;
      props.owHandelChange(owSearchRes, "");
    } else if (type === "ar") {
      dispatch({ type: "arPrice", payload: value });
      let res = rtSearchResFinal.origns.filter((flight) => {
        return flight.pr.tf >= value.min && flight.pr.tf <= value.max;
      });
      rtSearchRes.origns = res;
      props.arHandelChange(rtSearchRes, "");
    }
  };

  // Stops Filter Logic
  const onStopsChange = (e) => {
    if (e.target.checked) {
      state.stopsList.push(parseInt(e.target.value));
    } else {
      for (var i = 0; i < state.stopsList.length; i++) {
        if (state.stopsList[i] === parseInt(e.target.value)) {
          state.stopsList.splice(i, 1);
          i--;
        }
      }
    }


    let resow = [];
    if (state.stopsList.length > 0) {
      let owClone = Object.assign({}, owSearchResFinal);
      owSearchResFinal.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((src) => {
          return state.stopsList.includes(parseInt(src.stop));
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = srcFiltered;
          resow.push(flights);
        }
      });
      owSearchRes.origns = resow;
      owSearchResFinal.origns = owClone.origns;
      props.owHandelChange(owSearchRes, "");

      let resar = [];
      let rtClone = Object.assign({}, rtSearchResFinal);
      rtSearchResFinal.origns.forEach((flts) => {
        let desFiltered = flts.srcorigns.filter((des) => {
          return state.stopsList.includes(parseInt(des.stop));
        });
        if (desFiltered.length > 0) {
          flts.srcorigns = desFiltered;
          resar.push(flts);
        }
      });
      rtSearchRes.origns = resar;
      rtSearchResFinal.origns = rtClone.origns;
      props.arHandelChange(rtSearchRes, "");
    } else {
      owSearchRes.origns = owSearchResFinal.origns;
      props.owHandelChange(owSearchRes, "");
      rtSearchRes.origns = rtSearchResFinal.origns;
      props.arHandelChange(rtSearchRes, "");
    }
  };
  // OnwardlayoversFiltersClear
  const OnwardlayoversFiltersClear = () => {
    dispatch({ type: "onwardLayoversList", payload: "" });
    state.onwardLayoversList = "";

    //Layover clear
    var layoverChecks = document.getElementsByName("onwardlayover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });
    filterApply("", "");
  };
  // clear ReturnlayoversFilters
  const ReturnlayoversFiltersClear = () => {
    dispatch({ type: "returnLayoversList", payload: "" });
    state.returnLayoversList = "";
    var layoverChecks = document.getElementsByName("returnlayover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });

    filterApply("", "");
  };

  const layOverTimeCal = (one, two) => {
    // let totTime = Math.abs(new Date(one) - new Date(two));
    var from = moment(new Date(one));
    var to = moment(new Date(two));
    //     var m = moment(new Date(totTime));
    // var minutes = (m.hour()*60) + m.minute();
    return to.diff(from, "minutes");
  };
  // Airlines Filter
  const airlinesFilterow = (e) => {
    let res = [];
    if (e.target.checked) {
      state.airlinesOW.push(e.target.value);
    } else {
      for (var i = 0; i < state.airlinesOW.length; i++) {
        if (state.airlinesOW[i] === e.target.value) {
          state.airlinesOW.splice(i, 1);
          i--;
        }
      }
    }
    if (state.airlinesOW.length > 0) {
      owSearchResFinal.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return flt.seg && state.airlinesOW.includes(flt.seg[0].ac);
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = srcFiltered;
          res.push(flights);
        }
      });
      owSearchRes.origns = res;
    } else {
      owSearchRes.origns = owSearchResFinal.origns;
    }

    props.owHandelChange(owSearchRes, "");
  };

  // Airlines Filter
  const airlinesFilterar = (e) => {
    let res = [];
    if (e.target.checked) {
      state.airlinesAR.push(e.target.value);
    } else {
      for (var i = 0; i < state.airlinesAR.length; i++) {
        if (state.airlinesAR[i] === e.target.value) {
          state.airlinesAR.splice(i, 1);
          i--;
        }
      }
    }
    if (state.airlinesAR.length > 0) {
      rtSearchResFinal.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return flt.seg && state.airlinesAR.includes(flt.seg[0].ac);
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = srcFiltered;
          res.push(flights);
        }
      });
      rtSearchRes.origns = res;
    } else {
      rtSearchRes.origns = rtSearchResFinal.origns;
    }

    props.arHandelChange(rtSearchRes, "");
  };

  const airportFilter = (e) => {
    let rtClone = Object.assign({}, state.finalResponse);
    let res = [];
    if (e.target.checked) {
      state.airports.push(e.target.value);
    } else {
      for (var i = 0; i < state.airports.length; i++) {
        if (state.airports[i] === e.target.value) {
          state.airports.splice(i, 1);
          i--;
        }
      }
    }
    if (state.airports.length > 0) {
      state.finalResponse.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return (
            flt.seg &&
            (state.airports.includes(flt.seg[0].da) ||
              state.airports.includes(flt.seg[flt.seg.length - 1].ar))
          );
        });

        if (srcFiltered.length > 0) {
          if (state.response.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airports.includes(flt.seg[0].da) ||
                  state.airports.includes(flt.seg[flt.seg.length - 1].ar))
              );
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (state.response.tt === 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);

            if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
        }
      });
    } else {
      state.response.origns = state.finalResponse.origns;
    }
    props.handelChange(state.response, "");
    state.finalResponse = {};
    state.finalResponse = rtClone;
  };
  const layoverFilter = (flt) => {
    if (state.onwardLayoversList === 2000) {
      return (
        1440 < layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
      );
    } else {
      return (
        state.onwardLayoversList >
        layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
      );
    }
  };

  const openOnwardDEP = () => {
    dispatch({ type: "onwardDep", payload: true });
    dispatch({ type: "onwardArrrival", payload: false });
  };
  const openOnwardARI = () => {
    dispatch({ type: "onwardDep", payload: false });
    dispatch({ type: "onwardArrrival", payload: true });
  };

  const openReturndDEP = () => {
    dispatch({ type: "returnDep", payload: true });
    dispatch({ type: "returnArrrival", payload: false });
  };
  const openReturnARI = () => {
    dispatch({ type: "returnDep", payload: false });
    dispatch({ type: "returnArrrival", payload: true });
  };

  // Onward Departure Time Filter
  const onChangeOnwardDepTime = (data) => {
    dispatch({ type: "onwardDepTime", payload: data.value });
    let res = [];
    owSearchResFinal.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let depDate = DateUtils.convertToHours(flt.seg[0].dd);
        return depDate >= data.value.min && depDate <= data.value.max;
      });

      /*  const lists = remainsRef.filter(refs => (refs.ref === 1)); */
      if (remainsRef.length > 0) {
        flights.srcorigns = [];
        flights.srcorigns = remainsRef;
        res.push(flights);
      }
    });
    owSearchRes.origns = res;
    props.owHandelChange(owSearchRes, "");
  };

  const onChangeOnwardArrTime = (data) => {
    dispatch({ type: "onwardArrivalTime", payload: data.value });
    let res = [];
    owSearchResFinal.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let arrDate = DateUtils.convertToHours(flt.seg[flt.seg.length - 1].ad);
        return arrDate >= data.value.min && arrDate <= data.value.max;
      });

      if (remainsRef.length > 0) {
        flights.srcorigns = [];
        flights.srcorigns = remainsRef;
        res.push(flights);
      }
    });
    owSearchRes.origns = res;
    props.owHandelChange(owSearchRes, "");
  };

  const onChangeReturnDepTime = (data) => {
    dispatch({ type: "returnDeptTime", payload: data.value });
    let res = [];
    rtSearchResFinal.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let depDate = DateUtils.convertToHours(flt.seg[0].dd);
        return depDate >= data.value.min && depDate <= data.value.max;
      });
      if (remainsRef.length > 0) {
        flights.srcorigns = [];
        flights.srcorigns = remainsRef;
        res.push(flights);
      }
    });
    rtSearchRes.origns = res;
    props.arHandelChange(rtSearchRes, "");
  };

  const onChangeReturnArrTime = (data) => {
    dispatch({ type: "returnArrivalTime", payload: data.value });
    let res = [];
    rtSearchResFinal.origns.forEach((flights) => {
      let remainsRef = flights.srcorigns.filter((flt) => {
        let arrDate = DateUtils.convertToHours(flt.seg[flt.seg.length - 1].ad);
        return arrDate >= data.value.min && arrDate <= data.value.max;
      });
      if (remainsRef.length > 0) {
        flights.srcorigns = [];
        flights.srcorigns = remainsRef;
        res.push(flights);
      }
    });
    rtSearchRes.origns = res;
    props.arHandelChange(rtSearchRes, "");
  };

  const refundable = (e) => {
    let owClone = Object.assign({}, owSearchResFinal);
    if (e.target.checked) {
      let refundsList = [];
      let refundListAR = [];
      owSearchResFinal.origns.forEach((flights) => {
        const refundsSRC = flights.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (refundsSRC.length > 0) {
          flights.srcorigns = refundsSRC;
          refundsList.push(flights);
        }
      });
      if (refundsList.length > 0) owSearchRes.origns = refundsList;
      props.owHandelChange(owSearchRes, "");
      owSearchResFinal.origns = owClone.origns;

      //Arrival
      let rtClone = Object.assign({}, rtSearchResFinal);
      rtSearchResFinal.origns.forEach((flts) => {
        const refundsDes = flts.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (refundsDes.length > 0) {
          flts.srcorigns = refundsDes;
          refundListAR.push(flts);
        }
      });
      if (refundListAR.length > 0) rtSearchRes.origns = refundListAR;
      props.arHandelChange(rtSearchRes, "");
      rtSearchResFinal.origns = rtClone.origns;
    } else {
      owSearchRes.origns = owSearchResFinal.origns;
      props.owHandelChange(owSearchRes, "");
      rtSearchRes.origns = rtSearchResFinal.origns;
      props.arHandelChange(rtSearchRes, "");
    }
  };
  /*   const refundableAR = (e) => {
          let rtClone = Object.assign({}, rtSearchResFinal);
          if (e.target.checked) {
              let refundsList = []
              rtSearchResFinal.origns.forEach(flights => {
                  const refundsSRC = flights.srcorigns.filter(flt => { return (flt.re === 'Refundable Before Departure') })
                      if (refundsSRC.length > 0) {
                          flights.srcorigns = refundsSRC;
                          refundsList.push(flights);
                      }
              })
              if (refundsList.length > 0)
              rtSearchRes.origns = refundsList;
              props.owHandelChange(rtSearchRes, '')
              rtSearchResFinal.origns = rtClone.origns;
          }
          else {

              rtSearchRes.origns = rtSearchResFinal.origns;
              props.owHandelChange(rtSearchRes, '')
          }

      } */

  const nonRefundable = () => {
    let refundsList = [];
    state.finalResponse.origns.forEach((flights) => {
      const refundsSRC = flights.srcorigns.filter((flt) => {
        return flt.re !== "Refundable Before Departure";
      });
      if (state.finalResponse.tt === 2 && refundsSRC.length > 0) {
        const refundsARR = flights.destorigins.filter((flt) => {
          return flt.re !== "Refundable Before Departure";
        });
        if (refundsSRC.length > 0 && refundsARR.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = refundsSRC;
          flights.destorigins = [];
          flights.destorigins = refundsARR;
          refundsList.push(flights);
        }
      } else {
        flights.srcorigns = [];
        flights.srcorigns = refundsSRC;
        refundsList.push(flights);
      }
    });

    state.response.origns = refundsList;
    props.handelChange(state.response, "");
  };

  const flightListView = (e) => {
    if (props.srequest !== undefined) {
      history.push({
        pathname: "/ezytrip/bookings/flight/results",
        state: {
          req: props.srequest,
        },
      });
    }
  };

  const allFiltersClear = () => {
    dispatch({
      type: "owPrice",
      payload: {
        min: owSearchResFinal.filter.minprc,
        max: owSearchResFinal.filter.maxprc,
      },
    });
    dispatch({
      type: "arPrice",
      payload: {
        min: rtSearchResFinal.filter.minprc,
        max: rtSearchResFinal.filter.maxprc,
      },
    });
    dispatch({ type: "onwardDepTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "onwardArrivalTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "returnDeptTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "returnArrivalTime", payload: { min: 0, max: 24 } });
    dispatch({ type: "stopsList", payload: [] });
    dispatch({ type: "onwardLayoversList", payload: "" });
    dispatch({ type: "returnLayoversList", payload: "" });
    dispatch({ type: "airlines", payload: [] });
    dispatch({ type: "airportOW", payload: [] });
    dispatch({ type: "airportRT", payload: [] });
    dispatch({ type: "refundable", payload: false });
    dispatch({ type: "airlinesOW", payload: [] });
    dispatch({ type: "airlinesAR", payload: [] });
    dispatch({ type: "priceOW", payload: "" });
    dispatch({ type: "priceRT", payload: "" });
    dispatch({ type: "supplierDEP", payload: [] });
    dispatch({ type: "supplierAR", payload: [] });
// Depature carrier type
    var DepCarrierType = document.getElementsByName("suppDep");
    for (var i = 0, n = DepCarrierType.length; i < n; i++) {
      DepCarrierType[i].checked = false;
    }
// Arrival carrier type
    var ArrCarrierType = document.getElementsByName("suppArr");
      for (var i = 0, n = ArrCarrierType.length; i < n; i++) {
        ArrCarrierType[i].checked = false;
    }
    //Stops clear
    var stopChecks = document.getElementsByName("stops");
    for (var i = 0, n = stopChecks.length; i < n; i++) {
      stopChecks[i].checked = false;
    }
    var layoverChecks = document.getElementsByName("onwardlayover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });
    var layoverChecks = document.getElementsByName("returnlayover");
    Object.entries(layoverChecks).map((item) => {
      item[1].checked = false;
    });
    //refunds clear
    var refundChecks = document.getElementsByName("refund");
    for (var i = 0, n = refundChecks.length; i < n; i++) {
      refundChecks[i].checked = false;
    }
    //airlines clear
    var airlineChecks = document.getElementsByName("airlinesow");
    for (var i = 0, n = airlineChecks.length; i < n; i++) {
      airlineChecks[i].checked = false;
    }

    var airlineChecksrt = document.getElementsByName("airlinesar");
    for (var i = 0, n = airlineChecksrt.length; i < n; i++) {
      airlineChecksrt[i].checked = false;
    }
    //near by clears
    var nearbyChecks = document.getElementsByName("nearbyow");
    for (var i = 0, n = nearbyChecks.length; i < n; i++) {
      nearbyChecks[i].checked = false;
    }

    var nearbyChecksrt = document.getElementsByName("nearbyrt");
    for (var i = 0, n = nearbyChecksrt.length; i < n; i++) {
      nearbyChecksrt[i].checked = false;
    }
    props.owHandelChange(owSearchResFinal, "clear");
    props.arHandelChange(rtSearchResFinal, "clear");
  };
  const priceFilterClear = (type) => {
    if (type === "ow") {
      state.owPrice = {
        min: owSearchResFinal.filter.minprc,
        max: owSearchResFinal.filter.maxprc,
      };
      state.priceOW = "";
      // dispatch({ type: 'owPrice', payload: { min: owSearchRes.filter.minprc, max: owSearchRes.filter.maxprc } })
      // props.owHandelChange(owSearchRes, '')
    } else if (type === "ar") {
      state.arPrice = {
        min: rtSearchResFinal.filter.minprc,
        max: rtSearchResFinal.filter.maxprc,
      };
      state.priceRT = "";
      // dispatch({ type: 'arPrice', payload: { min: rtSearchResFinal.filter.minprc, max: rtSearchRes.filter.maxprc } })
      // props.arHandelChange(rtSearchRes, '')
    }
    filterApply("", "");
  };
  const stopsFiltersClear = () => {
    state.stopsList = [];
    //Stops clear
    var stopChecks = document.getElementsByName("stops");
    for (var i = 0, n = stopChecks.length; i < n; i++) {
      stopChecks[i].checked = false;
    }
    filterApply("", "");
  };

  const owTimingsFiltersClear = () => {
    state.onwardDepTime = { min: 0, max: 24 };
    state.onwardArrivalTime = { min: 0, max: 24 };
    // dispatch({ type: 'onwardDepTime', payload: { min: 0, max: 24 } });
    // dispatch({ type: 'onwardArrivalTime', payload: { min: 0, max: 24 } });
    filterApply("", "");
  };
  const rtTimingsFiltersClear = () => {
    state.returnDeptTime = { min: 0, max: 24 };
    state.returnArrivalTime = { min: 0, max: 24 };
    // dispatch({ type: 'returnDeptTime', payload: { min: 0, max: 24 } });
    // dispatch({ type: 'returnArrivalTime', payload: { min: 0, max: 24 } });
    filterApply("", "");
  };
  const refundFiltersClear = () => {
    state.refundable = false;
    var checkboxes = document.getElementsByName("refund");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }
    filterApply("", "");

    /*  else if(type === 'ar'){
             var checkboxes = document.getElementsByName("refundar");
             for (var i = 0, n = checkboxes.length; i < n; i++) {
                 checkboxes[i].checked = false;
             }
             props.arHandelChange(rtSearchResFinal, '');
         } */
  };
  const airlinesFiltersClear = (type) => {
    if (type === "ow") {
      state.airlinesOW = [];
      // dispatch({ type: 'airlinesOW', payload: [] });
      var checkboxes = document.getElementsByName("airlinesow");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    } else if (type === "ar") {
      state.airlinesAR = [];
      // dispatch({ type: 'airlinesAR', payload: [] });
      var checkboxes = document.getElementsByName("airlinesar");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    }
    filterApply("", "");
  };

  const supplierFiltersClear = (type) => {
    if (type === "dep") {
      state.supplierDEP = [];
      // dispatch({ type: 'airlinesOW', payload: [] });
      var checkboxes = document.getElementsByName("suppDep");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    } else if (type === "arrival") {
      state.supplierAR = [];
      // dispatch({ type: 'airlinesAR', payload: [] });
      var checkboxes = document.getElementsByName("suppArr");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    }
    filterApply("", "");
  };
  const nearbyFiltersClear = (type) => {
    state.airports = [];
    // dispatch({ type: 'airports', payload: [] });
    if (type === "ow") {
      state.airportOW = [];
      var checkboxes = document.getElementsByName("nearbyow");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    } else if (type === "rt") {
      state.airportRT = [];
      var checkboxes = document.getElementsByName("nearbyrt");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    }

    filterApply("", "");
  };

  const calcuateServiceFee = (serviceFeeList) => {
    var userType = props.srequest.butype === "ta_user" ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(
        serviceFeeList,
        userType,
        props.srequest
      );
      if (servicefee === "NaN") {
        return 0.0;
      } else {
        return servicefee;
      }
    } else {
      return 0.0;
    }
  };

  //Calculation the DISC
  const calculateDisc = (taxes) => {
    if (taxes !== undefined) {
      for (let taxIndex = 0; taxIndex < taxes.length; taxIndex++) {
        var mak = taxes[taxIndex];
        if (
          mak !== undefined &&
          mak.dealmkp !== undefined &&
          mak.dealmkp !== 0
        ) {
          return mak.dealmkp;
        } else {
          return 0.0;
        }
      }
    } else {
      return 0.0;
    }
  };
  /*  const nonRefundable = () => {
         let refundsList = []
         state.finalResponse.origns.forEach(origin => {
             const refunds = origin.orign.filter(jur => { return ((jur.re && jur.re === 'no') || jur.pr != undefined) })
             if (state.finalResponse.tt === 2 && refunds.length > 0) {
                 const onwards = refunds.filter(jur => (jur.ref && jur.ref === 1))
                 const arrivals = refunds.filter(jur => (jur.ref && jur.ref === 2))
                 if (onwards.length > 0 && arrivals.length > 0) {
                     origin.orign = [];
                     origin.orign = refunds;
                     refundsList.push(origin)
                 }
             }
             else {
                 origin.orign = [];
                 origin.orign = refunds;
                 refundsList.push(origin);
             }

         })

         state.response.origns = refundsList;
         props.handelChange(state.response,'')
     } */
  /**
   * @description : spilt filter functionality
   * @author : Azamuddin
   * @date : 13-03-2021
   *
   * @param {name} it has the selected filter name
   * @param {e} consist the data of the name need to be filetered
   */
  const filterApply = (name, e) => {
    let res = JSON.parse(localStorage.getItem("RESP"));
    let rtClone = Object.assign({}, res[1].data[0]);
    let owClone = Object.assign({}, res[0].data[0]);
    // let owClone = Object.assign({}, owSearchResFinal);
    // let rtClone = Object.assign({}, rtSearchResFinal);

    window.scrollTo(0, 0);
    if (name == "refundable") {
      //when refundable is clicked
      if (e.target.checked) {
        state.refundable = true;
      } else {
        state.refundable = false;
      }
    }

    //onward price filter selected
    if (name == "priceOW") {
      state.priceOW = e;
      dispatch({ type: "owPrice", payload: e });
    }

    //return price selection in filter
    if (name == "priceRT") {
      state.priceRT = e;
      dispatch({ type: "arPrice", payload: e });
    }

    //stops selection
    if (name == "stops") {
      if (e.target.checked) {
        state.stopsList.push(parseInt(e.target.value));
      } else {
        for (var i = 0; i < state.stopsList.length; i++) {
          if (state.stopsList[i] === parseInt(e.target.value)) {
            state.stopsList.splice(i, 1);
            i--;
          }
        }
      }

    }
    if (name == "onwardlayover") {
      state.onwardLayoversList = parseInt(e.target.value);
    }
    if (name == "returnlayover") {
      state.returnLayoversList = parseInt(e.target.value);
    }
    //onward dep time
    if (name == "onwardDep") {
      state.onwardDepTime = e.value;
    }

    //onward arr time
    if (name == "onwardArr") {
      state.onwardArrivalTime = e.value;
    }

    //return arr time
    if (name == "returnArr") {
      state.returnArrivalTime = e.value;
    }

    //return dep timr
    if (name == "returnDep") {
      state.returnDeptTime = e.value;
    }

    //airlines onward selection
    if (name == "airlinesOW") {
      if (e.target.checked) {
        state.airlinesOW.push(e.target.value);
      } else {
        for (var i = 0; i < state.airlinesOW.length; i++) {
          if (state.airlinesOW[i] === e.target.value) {
            state.airlinesOW.splice(i, 1);
            i--;
          }
        }
      }
    }

    //airlines dep selection
    if (name == "airlinesDEP") {
      if (e.target.checked) {
        state.airlinesAR.push(e.target.value);
      } else {
        for (var i = 0; i < state.airlinesAR.length; i++) {
          if (state.airlinesAR[i] === e.target.value) {
            state.airlinesAR.splice(i, 1);
            i--;
          }
        }
      }
    }

    //airports onward
    if (name == "airportOW") {
      if (e.target.checked) {
        state.airportOW.push(e.target.value);
      } else {
        for (var i = 0; i < state.airportOW.length; i++) {
          if (state.airportOW[i] === e.target.value) {
            state.airportOW.splice(i, 1);
            i--;
          }
        }
      }
    }

    //airport return selection
    if (name == "airportRT") {
      if (e.target.checked) {
        state.airportRT.push(e.target.value);
      } else {
        for (var i = 0; i < state.airportRT.length; i++) {
          if (state.airportRT[i] === e.target.value) {
            state.airportRT.splice(i, 1);
            i--;
          }
        }
      }
    }
    //refundable check in split state

    if (state.refundable) {
      let refundsList = [];
      let refundListAR = [];
      owClone.origns.forEach((flights) => {
        const refundsSRC = flights.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (refundsSRC.length > 0) {
          flights.srcorigns = refundsSRC;
          refundsList.push(flights);
        }
      });
      if (refundsList.length > 0) {
        owClone.origns = refundsList;
      }
      // owSearchResFinal.origns = owClone.origns;

      //Arrival
      // let rtClone = Object.assign({}, rtSearchResFinal);
      rtClone.origns.forEach((flts) => {
        const refundsDes = flts.srcorigns.filter((flt) => {
          return flt.re === "Refundable Before Departure";
        });
        if (refundsDes.length > 0) {
          flts.srcorigns = refundsDes;
          refundListAR.push(flts);
        }
      });
      if (refundListAR.length > 0) {
        rtClone.origns = refundListAR;
      }
      // rtSearchResFinal.origns = rtClone.origns;
    }

    //price onward changes
    if (state.priceOW) {
      let res = owClone.origns.filter((flights) => {
        let price = flights.pr.tf;
        if (flights.pr.tax) {
          price = Utilities.addPrice(
            flights.pr.tf + calcuateServiceFee(flights.servicefee),
            calculateDisc(flights.pr.tax),
            "Add"
          );
        } else if (
          props.srequest.flowType == undefined ||
          props.srequest.flowType !== "REISSUE"
        ) {
          price = Utilities.addPrice(
            flights.pr.tf + calcuateServiceFee(flights.servicefee),
            calculateDisc(flights.pr.tax),
            "Sub"
          );
        }
        return (
          Number(price) >= Number(state.priceOW.min) &&
          Number(price) <= Number(state.priceOW.max)
        );
      });
      owClone.origns = res;
    }

    //if price of return changes
    if (state.priceRT) {
      let res = rtClone.origns.filter((flights) => {
        let price = flights.pr.tf;
        if (flights.pr.tax) {
          price = Utilities.addPrice(
            flights.pr.tf + calcuateServiceFee(flights.servicefee),
            calculateDisc(flights.pr.tax),
            "Add"
          );
        } else if (
          props.srequest.flowType == undefined ||
          props.srequest.flowType !== "REISSUE"
        ) {
          price = Utilities.addPrice(
            flights.pr.tf + calcuateServiceFee(flights.servicefee),
            calculateDisc(flights.pr.tax),
            "Sub"
          );
        }
        return (
          Number(price) >= Number(state.priceRT.min) &&
          Number(price) <= Number(state.priceRT.max)
        );
      });
      rtClone.origns = res;
    }

    //if stops clicked or selected
    if (state.stopsList.length > 0) {
      let resow = [];
      owClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((src) => {
          return state.stopsList.includes(parseInt(src.stop));
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = srcFiltered;
          resow.push(flights);
        }
      });
      owClone.origns = resow;
      // owSearchResFinal.origns = owClone.origns;

      let resar = [];
      // let rtClone = Object.assign({}, rtSearchResFinal);
      rtClone.origns.forEach((flts) => {
        let desFiltered = flts.srcorigns.filter((des) => {
          return state.stopsList.includes(parseInt(des.stop));
        });
        if (desFiltered.length > 0) {
          flts.srcorigns = desFiltered;
          resar.push(flts);
        }
      });
      rtClone.origns = resar;
      // rtSearchResFinal.origns = rtClone.origns;
    }
    // if layovers are selected in filters destorigins
    if (state.onwardLayoversList && state.onwardLayoversList !== "") {
      let res = [];
      owClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter(layoverFilter);
        if (srcFiltered) {
          flights.srcorigns = srcFiltered;
        }
        res.push(flights);
      });
      owClone.origns = res;
    }
    if (state.returnLayoversList && state.returnLayoversList !== "") {
      let res = [];
      if (state.returnLayoversList === 2000) {
        rtClone.origns.forEach((flights) => {
          let srcFiltered = flights.srcorigns.filter((flt) => {
            return (
              "1440" <
              layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
            );
          });
          flights.srcorigns = srcFiltered;
          res.push(flights);
        });
        rtClone.origns = res;
      } else {
        rtClone.origns.forEach((flights) => {
          let srcFiltered = flights.srcorigns.filter((flt) => {
            return (
              state.returnLayoversList >
              layOverTimeCal(flt.seg[0].ad, flt.seg[flt.seg.length - 1].dd)
            );
          });
          flights.srcorigns = srcFiltered;
          flights.srcorigns = srcFiltered;
          res.push(flights);
        });
        rtClone.origns = res;
      }
    }

    //if onwardDep is there
    if (state.onwardDep) {
      if (state.onwardDepTime) {
        let res = [];
        owClone.origns.forEach((flights) => {
          let remainsRef = flights.srcorigns.filter((flt) => {
            let depDate = DateUtils.convertToHours(flt.seg[0].dd);
            return (
              depDate >= state.onwardDepTime.min &&
              depDate <= state.onwardDepTime.max
            );
          });

          /*  const lists = remainsRef.filter(refs => (refs.ref === 1)); */
          if (remainsRef.length > 0) {
            flights.srcorigns = [];
            flights.srcorigns = remainsRef;
            res.push(flights);
          }
        });
        owClone.origns = res;
      }
    }

    //if onwards Arrival is changed
    if (state.onwardArrivalTime) {
      let res = [];
      owClone.origns.forEach((flights) => {
        let remainsRef = flights.srcorigns.filter((flt) => {
          let arrDate = DateUtils.convertToHours(
            flt.seg[flt.seg.length - 1].ad
          );
          return (
            arrDate >= state.onwardArrivalTime.min &&
            arrDate <= state.onwardArrivalTime.max
          );
        });

        if (remainsRef.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = remainsRef;
          res.push(flights);
        }
      });
      owClone.origns = res;
    }

    //if return dept time si changed
    if (state.returnDeptTime) {
      let res = [];
      rtClone.origns.forEach((flights) => {
        let remainsRef = flights.srcorigns.filter((flt) => {
          let depDate = DateUtils.convertToHours(flt.seg[0].dd);
          return (
            depDate >= state.returnDeptTime.min &&
            depDate <= state.returnDeptTime.max
          );
        });
        if (remainsRef.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = remainsRef;
          res.push(flights);
        }
      });
      rtClone.origns = res;
    }

    //if return arrival is changed
    if (state.returnArrivalTime) {
      let res = [];
      rtClone.origns.forEach((flights) => {
        let remainsRef = flights.srcorigns.filter((flt) => {
          let arrDate = DateUtils.convertToHours(
            flt.seg[flt.seg.length - 1].ad
          );
          return (
            arrDate >= state.returnArrivalTime.min &&
            arrDate <= state.returnArrivalTime.max
          );
        });
        if (remainsRef.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = remainsRef;
          res.push(flights);
        }
      });
      rtClone.origns = res;
    }

    //airlines onward selected
    if (state.airlinesOW.length > 0) {
      let res = [];
      owClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return flt.seg && state.airlinesOW.includes(flt.seg[0].ma);
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = srcFiltered;
          res.push(flights);
        }
      });
      owClone.origns = res;
    }

    //airlines arrival selected
    if (state.airlinesAR.length > 0) {
      let res = [];
      rtClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return flt.seg && state.airlinesAR.includes(flt.seg[0].ma);
        });
        if (srcFiltered.length > 0) {
          flights.srcorigns = [];
          flights.srcorigns = srcFiltered;
          res.push(flights);
        }
      });
      rtClone.origns = res;
    }

    //airports oneway sleected
    if (state.airportOW.length > 0) {
      let res = [];
      owClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return (
            flt.seg &&
            (state.airportOW.includes(flt.seg[0].da) ||
              state.airportOW.includes(flt.seg[flt.seg.length - 1].ar))
          );
        });

        if (srcFiltered.length > 0) {
          if (owSearchRes.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airportOW.includes(flt.seg[0].da) ||
                  state.airportOW.includes(flt.seg[flt.seg.length - 1].ar))
              );
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }

          owClone.origns = res;
        }
      });
    }
    //airport return selected
    if (state.airportRT.length > 0) {
      let res = [];
      rtClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          return (
            flt.seg &&
            (state.airportRT.includes(flt.seg[0].da) ||
              state.airportRT.includes(flt.seg[flt.seg.length - 1].ar))
          );
        });

        if (srcFiltered.length > 0) {
          if (rtSearchRes.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              return (
                flt.seg &&
                (state.airportRT.includes(flt.seg[0].da) ||
                  state.airportRT.includes(flt.seg[flt.seg.length - 1].ar))
              );
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else if (rtSearchRes.tt === 3) {
            const refOne = srcFiltered.filter((jour) => jour.ref === 1);
            const refTwo = srcFiltered.filter((jour) => jour.ref === 2);
            const refThree = srcFiltered.filter((jour) => jour.ref === 3);

            if (refOne.length > 0 && refTwo.length > 0 && refThree.length > 0) {
              flights.srcorigns = srcFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }

          rtClone.origns = res;
        }
      });
    }

    //Supplier DEPARTURE
    if (name == "suppliersDEP") {
      if (e.target.checked) {
        state.supplierDEP.push(e.target.value);
      } else {
        for (var i = 0; i < state.supplierDEP.length; i++) {
          if (state.supplierDEP[i] === e.target.value) {
            state.supplierDEP.splice(i, 1);
            i--;
          }
        }
      }
    }
    if (state.supplierDEP.length > 0) {
      let res = [];
      owClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          if (
            state.supplierDEP.includes("LCC") &&
            state.supplierDEP.includes("Full Service")
          ) {
            return true;
          } else if (state.supplierDEP.includes("LCC")) {
            return flt.sup !== "0";
          } else if (state.supplierDEP.includes("Full Service")) {
            return flt.sup === "0";
          }
        });
        if (srcFiltered.length > 0) {
          if (owSearchRes.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              if (
                state.supplierDEP.includes("LCC") &&
                state.supplierDEP.includes("Full Service")
              ) {
                return true;
              } else if (state.supplierDEP.includes("LCC")) {
                return flt.sup !== "0";
              } else if (state.supplierDEP.includes("Full Service")) {
                return flt.sup === "0";
              }
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
          owClone.origns = res;
        }
      });
    }
    //Supplier ARRIVAL
    if (name == "suppliersARR") {
      if (e.target.checked) {
        state.supplierAR.push(e.target.value);
      } else {
        for (var i = 0; i < state.supplierAR.length; i++) {
          if (state.supplierAR[i] === e.target.value) {
            state.supplierAR.splice(i, 1);
            i--;
          }
        }
      }
    }
    if (state.supplierAR.length > 0) {
      let res = [];
      rtClone.origns.forEach((flights) => {
        let srcFiltered = flights.srcorigns.filter((flt) => {
          if (
            state.supplierAR.includes("LCC") &&
            state.supplierAR.includes("Full Service")
          ) {
            return true;
          } else if (state.supplierAR.includes("LCC")) {
            return flt.sup !== "0";
          } else if (state.supplierAR.includes("Full Service")) {
            return flt.sup === "0";
          }
        });
        if (srcFiltered.length > 0) {
          if (owSearchRes.tt === 2) {
            let desFiltered = flights.destorigins.filter((flt) => {
              if (
                state.supplierAR.includes("LCC") &&
                state.supplierAR.includes("Full Service")
              ) {
                return true;
              } else if (state.supplierAR.includes("LCC")) {
                return flt.sup !== "0";
              } else if (state.supplierAR.includes("Full Service")) {
                return flt.sup === "0";
              }
            });
            if (srcFiltered.length > 0 && desFiltered.length > 0) {
              flights.srcorigns = [];
              flights.srcorigns = srcFiltered;
              flights.destorigins = [];
              flights.destorigins = desFiltered;
              res.push(flights);
            }
          } else {
            flights.srcorigns = [];
            flights.srcorigns = srcFiltered;
            res.push(flights);
          }
          rtClone.origns = res;
        }
      });
    }

    //*******************final filtered result is proped************************* */
    props.owHandelChange(owClone, "");
    props.arHandelChange(rtClone, "");
  };

  return (
    <>
      <StickyBox offsetTop={20} offsetBottom={20}>
        <Button
          size="lg"
          block
          variant="primary"
          className="fbyf backCombained"
          onClick={flightListView}
        >
          <span className="ezyIcon icon-flight mr-3"></span>Back to combined flights
        </Button>
        <div className="filter">
          <div className="title">
            <h5>Result Filter</h5>
            <Link
              className="ezyIcon icon-reset"
              onClick={allFiltersClear}
            ></Link>
          </div>
          <div className="filterSec price">
            <div className="filterLabel d-flex justify-content-between">
              <p>Price Departure</p>
              <Link
                onClick={() => {
                  priceFilterClear("ow");
                }}
              >
                Clear
              </Link>
            </div>
            <div className="ranges">
              <InputRange
                formatLabel={(value) => `${owSearchRes.cur} ${value}`}
                allowSameValues={true}
                maxValue={owFilters && owFilters.maxprc}
                minValue={owFilters && owFilters.minprc}
                value={state.owPrice}
                // onChange={value => onPriceChange(value, 'ow')}
                onChange={(value) => filterApply("priceOW", value)}
              />
            </div>
          </div>
          <div className="filterSec price">
            <div className="filterLabel d-flex justify-content-between">
              <p>Price Arrival</p>
              <Link
                onClick={() => {
                  priceFilterClear("ar");
                }}
              >
                Clear
              </Link>
            </div>
            <div className="ranges">
              <InputRange
                formatLabel={(value) => `${rtSearchRes.cur} ${value}`}
                allowSameValues={true}
                maxValue={arFilters && arFilters.maxprc}
                minValue={arFilters && arFilters.minprc}
                value={state.arPrice}
                onChange={(value) => filterApply("priceRT", value)}
              />
            </div>
          </div>
          <div className="filterSec stops">
            <div className="filterLabel d-flex justify-content-between">
              <p>Stops</p>
              <Link
                onClick={() => {
                  stopsFiltersClear();
                }}
              >
                Clear
              </Link>
            </div>
            <div className="stops">
              <ul>
                <li>
                  <Form.Check
                    name="stops"
                    type="checkbox"
                    id={1}
                    // onChange={onStopsChange}
                    onChange={(value) => filterApply("stops", value)}
                    value={0}
                    label={"Non Stop"}
                  />
                </li>
                <li>
                  <Form.Check
                    name="stops"
                    type="checkbox"
                    id={2}
                    // onChange={onStopsChange}
                    onChange={(value) => filterApply("stops", value)}
                    value={1}
                    label={"1 Stop"}
                  />
                </li>
                <li>
                  <Form.Check
                    name="stops"
                    type="checkbox"
                    id={3}
                    // onChange={onStopsChange}
                    onChange={(value) => filterApply("stops", value)}
                    value={2}
                    label={"2 Stop"}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="filterSec layOver">
            <div className="filterLabel d-flex justify-content-between">
              <p>Onward Layover</p>
              <Link onClick={OnwardlayoversFiltersClear}>Clear</Link>
            </div>
            <div>
              <ul className="m-0 p-0">
                {/* 6hours/12hours/24 hours and more then 24 hours */}

                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="onwardlayover"
                    type="radio"
                    id="6hours"
                    label={<span>6 hours</span>}
                    title="6hours"
                    value="360"
                    custom
                    onChange={(e) => filterApply("onwardlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="onwardlayover"
                    type="radio"
                    id="12hours"
                    label={<span>12 hours</span>}
                    title="12hours"
                    value="720"
                    custom
                    onChange={(e) => filterApply("onwardlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="onwardlayover"
                    type="radio"
                    id="24hours"
                    label={<span>24 hours</span>}
                    title="24hours"
                    value="1440"
                    custom
                    onChange={(e) => filterApply("onwardlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="onwardlayover"
                    type="radio"
                    id="moreThan24"
                    label={<span>More than 24 hours</span>}
                    title="moreThan24"
                    value="2000"
                    custom
                    onChange={(e) => filterApply("onwardlayover", e)}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="filterSec layOver">
            <div className="filterLabel d-flex justify-content-between">
              <p>Return Layover</p>
              <Link onClick={ReturnlayoversFiltersClear}>Clear</Link>
            </div>
            <div>
              <ul className="m-0 p-0">
                {/* 6hours/12hours/24 hours and more then 24 hours */}

                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="returnlayover"
                    type="radio"
                    id="6hrs"
                    label={<span>6 hours</span>}
                    title="6hours"
                    value="360"
                    custom
                    onChange={(e) => filterApply("returnlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="returnlayover"
                    type="radio"
                    id="12hrs"
                    label={<span>12 hours</span>}
                    title="12hours"
                    value="720"
                    custom
                    onChange={(e) => filterApply("returnlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="returnlayover"
                    type="radio"
                    id="24hrs"
                    label={<span>24 hours</span>}
                    title="24hours"
                    value="1440"
                    custom
                    onChange={(e) => filterApply("returnlayover", e)}
                  />
                </li>
                <li className="d-flex justify-content-between">
                  <Form.Check
                    name="returnlayover"
                    type="radio"
                    id="morethan24"
                    label={<span>More than 24 hours</span>}
                    title="moreThan24"
                    value="2000"
                    custom
                    onChange={(e) => filterApply("returnlayover", e)}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="filterSec times">
            <div className="filterLabel d-flex justify-content-between">
              <p>Onward Timings</p>
              <Link
                onClick={() => {
                  owTimingsFiltersClear();
                }}
              >
                Clear
              </Link>
            </div>
            <ul>
              <li className={state.onwardDep ? "active" : ""}>
                <Link onClick={openOnwardDEP}>Departure</Link>
              </li>
              <li className={state.onwardArrrival ? "active" : ""}>
                <Link onClick={openOnwardARI}>Arrival</Link>
              </li>
            </ul>

            {state.onwardDep && (
              <div className="ranges">
                <InputRange
                  maxValue={24}
                  minValue={0}
                  value={state.onwardDepTime}
                  // onChange={value => onChangeOnwardDepTime({ value })}
                  onChange={(value) => filterApply("onwardDep", { value })}
                />
              </div>
            )}
            {state.onwardArrrival && (
              <div className="ranges">
                <InputRange
                  maxValue={24}
                  minValue={0}
                  value={state.onwardArrivalTime}
                  // onChange={value => onChangeOnwardArrTime({ value })}
                  onChange={(value) => filterApply("onwardArr", { value })}
                />
              </div>
            )}
          </div>
          <div className="filterSec times return">
            <div className="filterLabel d-flex justify-content-between">
              <p>Return Timings</p>
              <Link
                onClick={() => {
                  rtTimingsFiltersClear();
                }}
              >
                Clear
              </Link>
            </div>
            <ul>
              <li className={state.returnDep ? "active" : ""}>
                <Link onClick={openReturndDEP}>Departure</Link>
              </li>
              <li className={state.returnArrrival ? "active" : ""}>
                <Link onClick={openReturnARI}>Arrival</Link>
              </li>
            </ul>

            {state.returnDep && (
              <div className="ranges">
                <InputRange
                  maxValue={24}
                  minValue={0}
                  value={state.returnDeptTime}
                  // onChange={value => onChangeReturnDepTime({ value })}
                  onChange={(value) => filterApply("returnDep", { value })}
                />
              </div>
            )}
            {state.returnArrrival && (
              <div className="ranges">
                <InputRange
                  maxValue={24}
                  minValue={0}
                  value={state.returnArrivalTime}
                  // onChange={value => onChangeReturnArrTime({ value })}
                  onChange={(value) => filterApply("returnArr", { value })}
                />
              </div>
            )}
          </div>
          <div className="filterSec farePolicy">
            <div className="filterLabel d-flex justify-content-between">
              <p>Fare Policy onward</p>
              <Link
                onClick={() => {
                  refundFiltersClear();
                }}
              >
                Clear
              </Link>
            </div>
            <ul>
              <li>
                <Form.Check
                  name="refund"
                  type="checkbox"
                  id="refund"
                  label="Penalty Applies"
                  onChange={(e) => filterApply("refundable", e)}
                />
              </li>
              {/*  <li>
                            <Form.Check
                                type="checkbox"
                                id="noneRefund"
                                label="Non-Refundable"
                                onChange={nonRefundable}
                            />
                        </li> */}
            </ul>
          </div>

          <div className="filterSec airlines">
            <div className="filterLabel d-flex justify-content-between">
              <p>Departure Airlines Fare</p>
              <Link
                onClick={() => {
                  airlinesFiltersClear("ow");
                }}
              >
                Clear
              </Link>
            </div>
            <ul className="m-0 p-0">
              {owFilters &&
                owFilters.airl !== undefined &&
                owFilters.airl
                  .map((airline, index) => (
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="airlinesow"
                        type="checkbox"
                        id={"airlinesow" + index}
                        label={<AirlinesMemo code={airline.airline} />}
                        value={airline.airline}
                        // onChange={airlinesFilterow}
                        onChange={(e) => filterApply("airlinesOW", e)}
                        custom
                      />
                      <span className="priceLbel">
                        <em>{owSearchRes.cur} </em>{" "}
                        {
                          <FormateCurrency
                            value={Utilities.addPrice(airline.prc, 0, "Add")}
                          />
                        }
                      </span>
                    </li>
                  ))
                  .slice(
                    0,
                    showDepartureAirlines ? owFilters.airl.length : "5"
                  )}
            </ul>
            {arFilters.airl !== undefined && arFilters.airl.length >= 6 ? (
              <Button
                className="showmorelink"
                variant="link"
                onClick={() => setShowDepartureAirlines(!showDepartureAirlines)}
              >
                {showDepartureAirlines ? "- Show less" : "+ Show All"}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="filterSec airlines">
            <div className="filterLabel d-flex justify-content-between">
              <p>Arrival Airlines Fare</p>
              <Link
                onClick={() => {
                  airlinesFiltersClear("ar");
                }}
              >
                Clear
              </Link>
            </div>
            <ul className="m-0 p-0">
              {arFilters &&
                arFilters.airl !== undefined &&
                arFilters.airl
                  .map((airline, index) => (
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="airlinesar"
                        type="checkbox"
                        id={"airlinesar" + index}
                        label={<AirlinesMemo code={airline.airline} />}
                        value={airline.airline}
                        // onChange={airlinesFilterar}
                        onChange={(e) => filterApply("airlinesDEP", e)}
                        custom
                      />
                      <span className="priceLbel">
                        <em>{owSearchRes.cur} </em>
                        {
                          <FormateCurrency
                            value={Utilities.addPrice(airline.prc, 0, "Add")}
                          />
                        }
                      </span>
                    </li>
                  ))
                  .slice(0, showArrivalAirlines ? arFilters.airl.length : "5")}
            </ul>
            {arFilters.airl !== undefined && arFilters.airl.length >= 6 ? (
              <Button
                className="showmorelink"
                variant="link"
                onClick={() => setShowArrivalAirlines(!showArrivalAirlines)}
              >
                {showArrivalAirlines ? "- Show less" : "+ Show All"}
              </Button>
            ) : (
              ""
            )}
          </div>
          {owFilters &&
            owFilters.suplrs !== undefined &&
            owFilters.suplrs.length > 0 && (
              <div className="filterSec airlines">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Departure Carrier Type</p>
                  <Link onClick={() => supplierFiltersClear("dep")}>Clear</Link>
                </div>
                <ul className="m-0 p-0">
                  {owFilters.suplrs.map((sup, index) => (
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="suppDep"
                        type="checkbox"
                        id={"suppDep" + index}
                        label={sup.sup_name}
                        value={sup.sup_name}
                        onChange={(e) => filterApply("suppliersDEP", e)}
                        custom
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

          {arFilters &&
            arFilters.suplrs !== undefined &&
            arFilters.suplrs.length > 0 && (
              <div className="filterSec airlines">
                <div className="filterLabel d-flex justify-content-between">
                  <p>Arrival Carrier Type</p>
                  <Link onClick={() => supplierFiltersClear("arrival")}>
                    Clear
                  </Link>
                </div>
                <ul className="m-0 p-0">
                  {arFilters.suplrs.map((sup, index) => (
                    <li className="d-flex justify-content-between">
                      <Form.Check
                        name="suppArr"
                        type="checkbox"
                        id={"suppArr" + index}
                        label={sup.sup_name}
                        value={sup.sup_name}
                        onChange={(e) => filterApply("suppliersARR", e)}
                        custom
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

          <div className="filterSec nearbyAirports">
            <div className="filterLabel d-flex justify-content-between">
              <p>Nearby Airports Departure</p>
              <Link
                onClick={() => {
                  nearbyFiltersClear("ow");
                }}
              >
                Clear
              </Link>
            </div>
            <ul>
              {owFilters &&
                owFilters.nerby
                  .map((airport, index) => (
                    <li>
                      <Form.Check
                        name="nearbyow"
                        type="checkbox"
                        id={airport}
                        label={
                          <AirportMemo
                            code={airport}
                            mapping={owMapping}
                            type={"filters"}
                          />
                        }
                        value={airport}
                        // onChange={airportFilter}
                        onChange={(e) => filterApply("airportOW", e)}
                        custom
                      />
                    </li>
                  ))
                  .slice(0, showNearbyAirports ? owFilters.nerby.length : "5")}
            </ul>
            {owFilters.nerby.length >= 6 ? (
              <Button
                className="showmorelink"
                variant="link"
                onClick={() => setshowNearbyArrAirports(!showNearbyAirports)}
              >
                {showNearbyAirports ? "- Show less" : "+ Show All"}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="filterSec nearbyAirports">
            <div className="filterLabel d-flex justify-content-between">
              <p>Nearby Airports Arrival</p>
              <Link
                onClick={() => {
                  nearbyFiltersClear("rt");
                }}
              >
                Clear
              </Link>
            </div>
            <ul>
              {arFilters &&
                arFilters.nerby.map((airport, index) => (
                  <li>
                    <Form.Check
                      name="nearbyrt"
                      type="checkbox"
                      id={airport}
                      label={
                        <AirportMemo
                          code={airport}
                          mapping={arMapping}
                          type={"filters"}
                        />
                      }
                      value={airport}
                      // onChange={airportFilter}
                      onChange={(e) => filterApply("airportRT", e)}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </StickyBox>
    </>
  );
}

export default FilterSplit;
