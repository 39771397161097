import React, { useState, useContext } from 'react';
import { Col, Form, Table,Alert } from "react-bootstrap";
import Utilities from '../../../commonUtils/Utilities';
import { Context } from '../../../../../App'
import FormateCurrency from '../../../commonUtils/FormateCurrency';

/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Pravas
 *
 */
function RefundPriceInfo(props) {
  const [context, setContext] = useContext(Context);
  const [cur, setCur] = useState(props.currency)

  const calculateRefundPrice = (price) => {
    const total = price.reduce((prev, t) => {
      return Utilities.addPrice(prev, t.refundprice, "Add");
    }, 0);
    return total;
  }
  return (
    <div className="HotelrecordInfo">
      {props.supplier==0 && props.priceData.length !== 0 && props.priceData !== undefined &&
      <>
      <div className='table-responsive'>
        <Table className="header">
          <tr>
            <th width="25%">Room</th>
            <th >CONF#</th>
            <th>Room Price</th>
            <th>Cancellation Fee</th>
            <th>Cancellation Charges</th>
            <th className="text-right">Refund Amount</th>
          </tr>
          {props.priceData !== undefined && props.priceData[0].rooms.map((obj, index) => (
            obj.st && (
            <React.Fragment key={index}>
              <tr className="pList">
                <td><span>{obj.rno}</span> <span>{obj.rnm}</span></td>
                <td><span>{obj.htlconfno}</span></td>
                <td><span>{cur} {<FormateCurrency value={obj.roomprice} />}</span></td>
                <td><span>{cur} {<FormateCurrency value={obj.canservice} />}</span></td>
                <td><span>{cur} {<FormateCurrency value={obj.cancharges} />}</span></td>
                <td className="text-right"><div className="d-flex cnclCurrencyInfo"><span>{cur}  {<FormateCurrency value={obj.refundprice} />}</span>
                  <div className="plcy toolTipInfo">
                   <span className="ezyIcon icon-info"></span>
                    <div className="toolTipCUstoom cnclInfo">
                      <div className="toolTipCont">
                        {obj.supcancharges}</div>
                    </div>
                  </div>

                </div>
                </td>
              </tr>
            </React.Fragment>
          )))}
          {props.priceData !== undefined &&
            <tr>
              <td colSpan="6" className="text-right total" > <span className="mr-2">Refund Total : </span> <strong > {cur} {<FormateCurrency value={calculateRefundPrice(props.priceData[0].rooms)} />}</strong>
              </td>
            </tr>
          }
        </Table>
        </div>
          <Alert variant="info"> <strong > {cur} {<FormateCurrency value={calculateRefundPrice(props.priceData[0].rooms)} />}</strong> will be credited on successful cancellation
          </Alert>
                 </>
      }
      {/* For Otilla */}
      {props.supplier==1 && props.priceData.length !== 0 && props.priceData !== undefined &&
      <>
      <div className='confirmation'>

      <h6 class="title">Rooms Info:</h6>
      </div>

      <div className='table-responsive'>

        <Table className="header OtilTable">
                 <tr>
            <th>Room</th>
            <th >CONF# : {props.priceData[0].rooms[0].htlconfno}</th>
          </tr>
          {props.priceData !== undefined && props.priceData[0].rooms.map((obj, index) => (
            obj.st && (
            <React.Fragment key={index}>
              <tr className="pList">
                <td><span>{obj.rno}</span> <span>{obj.rnm}</span></td>
              </tr>
            </React.Fragment>
          )))}
        </Table>
        <br/>
        <div className='confirmation'>

<h5 class="title">Price Info:</h5>
</div>
        <Table className="header">
                <tr>
            <th>Room Price</th>
            <th>Cancellation Fee</th>
            <th>Cancellation Charges</th>
            <th className="text-right">Refund Amount</th>
          </tr>
          {props.priceData !== undefined && props.priceData[0].otillaRefundPrice.map((obj, index) => ((
            <React.Fragment key={index}>
              <tr className="pList">
                <td><span>{cur} {<FormateCurrency value={obj.roomprice} />}</span></td>
                <td><span>{cur} {<FormateCurrency value={obj.canservice} />}</span></td>
                <td><span>{cur} {<FormateCurrency value={obj.cancharges} />}</span></td>
                <td className="text-right"><div className="d-flex cnclCurrencyInfo"><span>{cur}  {<FormateCurrency value={obj.refundprice} />}</span>
                {obj.supcancharges!==0 &&
                  <div className="plcy toolTipInfo">
                   <span className="ezyIcon icon-info"></span>
                    <div className="toolTipCUstoom cnclInfo">
                      <div className="toolTipCont">
                        {obj.supcancharges}</div>
                    </div>
                  </div>
                 }
                </div>
                </td>
              </tr>
            </React.Fragment>
          )))}
          {props.priceData !== undefined &&
            <tr>
              <td colSpan="6" className="text-right total" > <span className="mr-2">Refund Total : </span> <strong > {cur} {<FormateCurrency value={calculateRefundPrice(props.priceData[0].otillaRefundPrice)} />}</strong>
              </td>
            </tr>
          }
        </Table>
        </div>
          <Alert variant="info"> <strong > {cur} {<FormateCurrency value={calculateRefundPrice(props.priceData[0].otillaRefundPrice)} />}</strong> will be credited on successful cancellation
          </Alert>
       </>

      }
    </div>
  )
}
export default RefundPriceInfo