
import React, { useState, useEffect, useRef } from 'react';
function Sticky(props) {
    const [isSticky, setSticky] = useState(false);
    const [className] = useState(props.className);
    const ref = useRef(null);
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0);
      }
    };
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', () => handleScroll);
      };
    }, []);


    return (
        <>
            <div className={`${isSticky ? 'sticky' : ''} ${className}`} ref={ref}>
            {props.children}
            </div>
        </>
    )
}

Sticky.defaultProps = {
className:"stickyLayout",

  }
export default Sticky;
