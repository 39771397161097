import React, { useState,useRef } from 'react';
import { Col, Row,Button,Modal,Form,Navbar,Nav, Container,NavDropdown,NavLink } from 'react-bootstrap';
import logogray from "../../assets/images/logo.svg";
import ScrollToTop from "react-scroll-to-top";
import { Link, useHistory } from 'react-router-dom';
import zigzag from "../../assets/images/productimage.png";
import aboutusimg from "../../assets/images/aboutusimg.png";
import Clickopenpackage from "../../assets/images/ezytrippack.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../common/Footer';
import RegistrationForm from '../common/dashBoard/RegistrationForm';
export const REGION_COUNTRY = "REGION_COUNTRY";
const API_PACKAGES_URL = process.env.REACT_APP_PACKAGE_URL

function LandingPage(props) {
  const [enablePop,setEnbalePop] = useState(false);
  const [onmarkup,setOnmarkup] = useState(true);
  const ref = useRef();

   const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const redirectPkg=()=>{
    return window.open(API_PACKAGES_URL);
  }
  const redirect=()=>{
    window.location="/ezytrip/india/login";
  }

  const handleCLClose=()=>{
    setEnbalePop(false)
  }
  

  const openMarkUp = () => {
    if(onmarkup) {
      setOnmarkup(false)
    } else {
      setOnmarkup(true)
    }
  }

    return (

<>
<ScrollToTop smooth />
<section className="dashBordHeader inddashboardHead" id="stcickyHeader">
<Container>
<Col lg={12}>

<Navbar expand="lg">
  <Container>
    <Navbar.Brand>
    <Link className="app-header-logo mr-4">
                <img
                  src={logogray}
                  title="EZY Trip"
                  alt="logo"
                />

              </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav defaultActiveKey="#home" className="ml-auto">
   
    <Nav.Link href="#home" data-hover="Home">Home</Nav.Link>
        <Nav.Link href="#aboutUs" data-hover="About Us">About Us</Nav.Link>
        <Nav.Link href="#whatWeoffer" data-hover="What we offer">What we offer</Nav.Link>
        <Nav.Link href="#whyChoose" data-hover="Why Ezytrip">Why Ezytrip</Nav.Link>
        {window.sessionStorage.getItem(REGION_COUNTRY)==="IN" &&
        <Nav.Link onClick={redirectPkg} data-hover="Packages">Packages</Nav.Link>
        }
        <NavLink  onClick={redirect}><span className='indLogin'>Login</span></NavLink>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

</Col>

</Container>
</section>

<section className="headerBanner" id='home'>
    <Container>
    <div className='indheadingCont d-flex'>
    <h2>Want to become a travel agent?</h2>
    <h6>Start your profitable business today with best B2B travel portal.</h6>

  <div className="zigZagimg">
  <img
                  src={zigzag}
                  title=""
                  alt="img"
                  width={620}
                  />
  </div>
</div>

    </Container>

</section>
{/* <div className={"mrkup " + (onmarkup ? 'open':'') } ref={ref} >
      <span className="arrBg tt" onClick={openMarkUp}><span className="ezyIcon markup icon-arrowDown"></span></span>
      {onmarkup &&
            <div className={"admarkup " + (onmarkup ? 'active':'') }>
    <a href="https://dev-package.ezytrip.com/home" target="_blank"><img src={Clickopenpackage} /></a>
            </div>
            }

        </div> */}
<div id="aboutUs"></div>
<section className="bodyContent indbodyCont">
      <Container>
          <div className="dashBordLayout dashBoardAbout pt-4">
            <Row>
              <Col lg={6} md={6} className="aboutLeftcont">

              <img
                  src={aboutusimg}
                  title="aboutus"
                  alt="img"

                  />


              </Col>
              <Col lg={6} md={6}>

                <div className="aboutContent pt-5">
                <h2 className="titleLine"><span>About Us</span></h2>

<h6>Ezytrip is the best B2B travel solution, specialized in facilitating unforgettable travel experiences.</h6>

                 <p>As a part of Tawfeeq Travel group, we leverage over a decade of experience in the travel and tourism industry to provide top-notch services.</p>

                 <p>We collaborate with travel partners worldwide to provide turnkey travel solutions to their customers. Ezytrip offers all the tools and support you need to manage and grow your travel business without hassles. Our goal is to build mutually beneficial business relationships based on professionalism, transparency, and quality service delivery.</p>

                 <p>Whether your clients are travelling on vacation, honeymoon, a business trip, or attending an event, we have a perfect package that aligns with various fun and business travel objectives. Our team is well prepared to cater to your client’s unique needs ranging from accessibility, diet, and language, to provide an unforgettable travel experience.</p>


 </div>

              </Col>
            </Row>
          </div>

          </Container>


      </section>
      <section>

        <div className="dashBordLayout dashBoardoffer indWhatweoffer pb-5" id="whatWeoffer">
        <Container>

           <h2 className="titleLine"><span>What We Offer For Your Business </span></h2>
           <span className="semiTitle">Our features and benefits are curated to suit the needs of every employee using Ezytrip.</span>
            <Row className="offerCardsec">
            <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-briefcase"></span>
                  <h3>Business Travel</h3>
                  <p>Our service takes care of all your client’s business travel needs, allowing them to forget about the hassles of travelling and focus on their business. We offer the best deals for single and group business travels. </p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-mountains"></span>
                  <h3>Tourism & Vacations</h3>
                  <p>We provide a wide range of tour and vacation packages ranging from adventurous to educational and many more. Even if you have a destination in mind, we’ll help your clients get the best vacation experience.</p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-today"></span>
                  <h3>Meeting & Events  </h3>
                  <p>Are your clients planning to attend meetings or organize events anywhere around the globe? We provide hassle-freetravel, from flight to event ticketing; we arrange the necessities that give your clients a pleasant travel experience.</p>

                </div>
              </Col>
              <Col xs={3}>
                <div className="offerCard">
                  <span className="travelerOne ezyIcon icon-visa"></span>
                  <h3>Visa Services</h3>
                  <p>Does your client’s need a unique travel product? We’ve got your back! Ezytrip is certified to represents and distributes world-class products like Hertz, Trafalgar, ClubMed, Rail Europe, Costa Cruises in the Qatar market.</p>
                </div>
              </Col>
            </Row>
            </Container>
             </div>


      </section>

      <section className="whyEzytrip dashBordLayout indWhychoose pb-5" id="whyChoose">
        <div className="container">
        <h2 className="titleLine"><span>Why Choose Us?</span></h2>
        <span className="semiTitle">We offer the best deals for single and group business travels.</span>
        <Slider {...settings}>
          <div>
          <div className="list">
            <div className="circleOut">
                <span className="starOne ezyIcon icon-Transparent-Services"></span>
                </div>
                <div>
                <span>01</span>
                   <h3>Transparent Services</h3>
                  <p>Our partners retain the freedom to maintain their brand identity as we provide services for their clients transparently.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="dashboardOne ezyIcon icon-agentportal"></span>
                </div>
                <div>
                <span>02</span>
                  <h3>Agent Portal</h3>
                  <p>Get access to our agents online portal with leading-edge features for managing your travel and tourism business.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="priceTag ezyIcon icon-Competitive-Pricing"></span>
                </div>
                <div>
                <span>03</span>
                   <h3>Competitive Pricing </h3>
                  <p>All our products and services carry a reasonable price tag. We also give you the freedom to set the price for your customers.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="easy ezyIcon icon-Extensive-Experience"></span>
                </div>
                <div>
                <span>04</span>
                  <h3>Extensive Experience</h3>
                  <p>Our team has extensive experience and the industry-specific network required to deliver top-notch services.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="comprehensive ezyIcon icon-service"></span>
                </div>
                <div>
                <span>05</span>
                  <h3>Comprehensive Services</h3>
                  <p>Our comprehensive services cover all the travel and tourism services your clients need and much more.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="brand ezyIcon icon-easy"></span>
                </div>
                <div>
                <span>06</span>
                   <h3>Reliable Brand</h3>
                  <p>Ezytrip is a reliable brand with global partnerships and serving over 40 travel agencies in Qatar.</p>
                </div>
              </div>
          </div>
          <div>
          <div className="list">
          <div className="circleOut">
                <span className="support ezyIcon icon-supportus"></span>
                </div>
                <div>
                <span>07</span>
                   <h3>24/7 Support</h3>
                  <p>Travel business is hectic and time-sensitive. That is why our support staff are available to serve you 24/7.</p>
                </div>
              </div>
          </div>
        </Slider>



         </div>
        <Modal
        show={enablePop}
        onHide={handleCLClose}
        className="importPNRMdl registratPop"
        backdrop="static"
        keyboard={false}
        >
       <Modal.Header closeButton>
          <Modal.Title>Register with Us!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
        <RegistrationForm />

          </Modal.Body>
         </Modal>
         
        </section>
     
        <Footer />
</>

            )
  }
  export default LandingPage