import React, { useState,useEffect,useReducer } from 'react';
import log from "loglevel";
import { Col, Row, Form, Modal, Button,Alert } from "react-bootstrap";
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import InsTransactionDetails from './InsTransactionDetails';
import InsPolicyDetails from './InsPolicyDetails';
import InsPlanDetails from './InsPlanDetails';
import InsPassengers from './InsPassengers';
import InsFareDetails from './InsFareDetails';
import InsPayment from './InsPayment';
import { useHistory } from 'react-router-dom';
import { emailValidation } from "../../../commonUtils/FormValidations";
import { sendConfirmationObj } from '../../../bookingFlows/insurance/review/operation';


//This function will show the Air markups List and Add Markup
const initialState = { notiMessageShow: false, showPopup: false, email: ""
,notiMessage:"",notiVarient:"" };

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

function ConfirmationPage(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [response, setResponse] = useState(props.location.state.req);

  const openprintevent = (e) => {
    e.preventDefault();
    window.print();
  }
  const openshareevent = () => {
    dispatch({ type: 'showPopup', payload: true })
  }

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push({ pathname: '/ezytrip/dashboard' })
      }
    };

  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleChangeemail = (e) => {
    e.preventDefault();
    let trimmedEmail = e.target.value.trim();
       dispatch({ type: 'email', payload: trimmedEmail })
   }
   const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
   const handleemailClose = ()=>{
    dispatch({ type: 'showPopup', payload: false })
    dispatch({ type: 'email', payload: "" })
    // dispatch({ type: 'splittid', payload: "" })
    // dispatch({ type: 'fbfreturn', payload: false })
   }
   const validateEmail = ()=>{
    if(state.email == ""){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     else if(!emailValidation(state.email)){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid Email' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false
     }
     return true
   }
   const sendEmail = ()=>{
    if(validateEmail()){
      const payload = {
        "email": state.email,
        "tid": response && response.plan.tid,
        "booking_id": response && response.booking_id
             }
      sendConfirmationObj(payload).then(response=>{
      if(response.suc){
        dispatch({ type: 'email', payload: "" })
        dispatch({ type: 'showPopup', payload: false })
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: response.res })
        dispatch({ type: 'notiVarient', payload: 'success' })
        hidemessage();
      }
      }).catch(function (error) {
      dispatch({ type: 'showPopup', payload: false })
      dispatch({ type: 'email', payload: "" })
      log.error(
        "Exception occured in getCompanyList function---" + error
      );
    });

      }
    }
  return (
    <>
      <Header />
      {state.notiMessageShow &&
           <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
         }
      {response!==undefined &&
      <div className="container confirmation insuranceConfirm">
        <div className="confirmMessage d-flex align-items-center justify-content-between mt-4 mb-4">
        <div className='resultMes'>
        <div className="d-flex align-items-center resconfirMation ">
          <div className="confirmStatus done ezyIcon icon-tick"></div>
          <div className="message">
            <h3 className="m-0">Thank you for booking with us</h3>
            <p className="m-0 pt-1">The booking confirmation has been sent to your email address.</p>
          </div>
          </div>
          </div>
          <div className='socialIcons'>
          <Button size="xs" className="prient ezyIcon icon-print" onClick={openprintevent} variant="link"> Print Confirmation</Button>
          <Button className="prient ezyIcon icon-share" onClick={()=>{openshareevent()}} variant="link">Share Itinerary</Button>
        </div>
        </div>
        <div className="resultSection boxShadow bg-white summary transactionSummeryinsu mb-4">
          <h5 className="title">Transaction Summary</h5>
          <InsTransactionDetails resp={response} />

        </div>
        <div className="resultSection boxShadow bg-white transactionSummeryinsu mb-4">
          <h5 className="title">Policy Details</h5>
         <InsPolicyDetails policy={response.policy} paxInfo={response.psngrs[0]}/>

         <div className='schInsu'>
         <h5 className="title"><span  className="prient ezyIcon icon-plussheild"></span>{response.plan.scheme_name} Plan</h5>
         <InsPlanDetails plans={response.plan} />
         </div>
         <p>&nbsp;</p>
        </div>
        {/* <div className="resultSection boxShadow bg-white schemeDetails mb-4">
        <h5 className="title">{response.plan.scheme_name} Plan</h5>
         <InsPlanDetails plans={response.plan} />
        </div> */}
        <div className="resultSection boxShadow bg-white PassengerDetails mb-4">
          <h5 className="title">Passenger Details</h5>
         <InsPassengers paxList={response.psngrs}/>
        </div>
        <div className="resultSection bg-white boxShadow PassengerfareDetails mb-4">
          <h5 className="title">Fare Details (QAR)</h5>
        <InsFareDetails plans={response.plan}/>
        </div>
        <div className="resultSection bg-white boxShadow PassengerpaymentDetails mb-4">
          <h5 className="title">Payment Details</h5>
          <InsPayment payments={response.pay}/>
       </div>
        </div>
        }
        <Modal
              show={state.showPopup}
              onHide={handleemailClose}
              className="importPNRMdl shareIpop"
              backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h5>Share Itinerary</h5>
                <Row>
                <Col xs={10}>
                <Form.Group controlId="uem">
                    <Form.Control type="text" placeholder="Enter Email" value= {state.email} onChange={handleChangeemail} />
                    <Form.Label>Email Id <sup>*</sup></Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={2}>
                <div className="d-flex justify-content-center">
                  <Button variant="outline-dark" onClick={sendEmail}>Submit
                </Button>
                  </div>
                </Col>
                </Row>
              </Modal.Body>
            </Modal>
      <Footer />
    </>
  )
}
export default ConfirmationPage