import React, { useContext, useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { Context } from "../../../App";
import AirMarkup from '../../components/adminstration/airServices/markup/AirMarkupConfiguration';
import Confirmation from '../../components/bookingFlows/flight/confirmation';
import AirlineCommission from '../adminstration/airServices/comission/CommissionMain';
import PromoCode from'../adminstration/airServices/promoCode/PromoCodeMain.jsx'
import DealCode from '../adminstration/airServices/dealcode';
import TASTAMarkup from '../adminstration/airServices/markup/TASTAMarkupConfiguration';
import Officeid from '../adminstration/airServices/officeid/OfcIdMain';
import ServiceFee from '../adminstration/airServices/serviceFee/main';
import HoldAirline from '../adminstration/airServices/holdairline';
import RoleManagement from '../adminstration/masterServices/roleManagement/roles';
import UserManagement from '../adminstration/masterServices/userManagement/UserManagement';
import Branch from '../adminstration/organization/branch/BranchMain';
import CountryMast from '../adminstration/organization/countryMaster';
import Category from '../adminstration/organization/category';
import CompanyManagement from '../adminstration/organization/company/CompanyMain';
import CustomFields from '../adminstration/organization/customFields/CustomMain';
import MaxHits from '../adminstration/organization/oidMaxHits/index';
import Creditmanagement from '../adminstration/paymentAndBilling/creditmanagement';
import Visa from '../adminstration/visaServices/index';
import VisaConfiguration from '../adminstration/visaServices/VisaConfiguration'; 
import VisaDocumentation from '../adminstration/visaServices/VisaDocuments';
import BookingError from '../bookingFlows/flight/common/BookingError';
import HotelPage from '../bookingFlows/hotel/pages/HotelResultsPage';
import HotelReview from '../bookingFlows/hotel/pages/HotelReview';
import HotelGuestDetails from '../bookingFlows/hotel/pages/HotelGuestDetails';
import ConfirmationPage from '../bookingFlows/hotel/pages/ConfirmationPage';
import FlightPage from '../bookingFlows/flight/search/normal/FlightResultsPage';
import Itinerary from '../bookingFlows/flight/review/FlightItinerary';
import RoundTrip from '../bookingFlows/flight/search/flightByFlight/FBFResultsPage';
import loader from '../bookingFlows/flight/searchLoaders/FBFResultsPageLoader';
import loader1 from '../bookingFlows/flight/searchLoaders/ResultPageLoader';
import MainMenu from '../common/MainMenu';
import OrganizationMenu from '../common/subMenu/OrganizationMenu';
import DashBoardBookingList from '../dashBoard/BookingList.jsx';
import Indialandingpage from '../india/LandingPage';
import Indialogin from '../india/Login';
import { default as DashBoardLogin, default as LoginPage } from '../dashBoard/DashBoardLogin';
import DashBoardMain from '../dashBoard/DashBoardMain';
import DashBoardPNRImport from '../dashBoard/DashBoardPNRImport';
import ManualBooking from '../dashBoard/ManualBooking.jsx';
import HotelManualBooking from '../dashBoard/HotelManualBooking.jsx';
import Demo from '../Demo';
import Agenttermsandconditions from '../bookingFlows/hotel/common/policy/AgentTaC'
import Suppliertermsandconditions from '../bookingFlows/hotel/common/policy/SupplierTaC'
import TermsAndCondtions from '../bookingFlows/hotel/common/policy/TaC'
import PDFTestPage from '../Demo/PDFTestPage';
import SupplierConfig from '../adminstration/organization/supplierConfig/index';
import GSTConfiguration from '../adminstration/organization/gstConfig/index';
import TDSConfiguration from '../adminstration/organization/tdsConfig/index';
import AdminDashboard from '../adminDashBoard/AdminEzyDashBoard'
import InsurancesResult from '../../components/bookingFlows/insurance/results/InsResults'
import InsurancesPostBooking from '../../components/postBooking/insurances'
import InsurancesReview from '../../components/bookingFlows/insurance/review/InsReview'
import InsurancesConfirmation from '../../components/bookingFlows/insurance/confirmation/ConfirmationPage';
import OIDMaster from '../adminstration/organization/oidMaster';


import VisaForm from '../bookingFlows/visa/review/VisaForm';
import Visasummary from '../bookingFlows/visa/summary/VisaSummary';
import VisaSummaryEdit from '../bookingFlows/visa/summary/VisaSummaryEdit';
import CrossSellMain from '../adminstration/hotelServices/corssSell/CrossSellMain'



//import DashBoardBookingsummay from '../dashBoard/BookingSummary';
//import {confirmNomoreTimeDlg} from '../common/sessionTimeOut/SessionIdleTimeOut';
import HotelBookingSummary from '../../components/postBooking/hotel/BookingSummary/Index';
import HotelMarkup from '../adminstration/hotelMarkup/main';
import HotelServiceFee from '../adminstration/hotelServiceFee/main';
import HotelDealCode from '../adminstration/hotelServices/DealCode';
import HotelCommission from '../adminstration/hotelServices/Commission';
import HotelPromoCode from '../adminstration/hotelServices/promoCode/CreateHotelPromo.jsx';
import HotelOfficeid from '../adminstration/hotelServices/Officeid';
import RefundRecord from '../postBooking/refund/RefundRecord.js';
import ManualRefundRecord from '../postBooking/refund/ManualRefundRecord';
import FlightReissueDetails from '../postBooking/reIssue/FlightReissueDetails';
import FlightBookingSummary from '../postBooking/summary';
import ExtraServices from '../adminstration/airServices/extraservices';
import DSRReport from '../adminstration/hotelServices/dsrReport/main'
import MaxHoldBooking from '../adminstration/hotelServices/maxOnhold/index';
import ExchangeRate from '../adminstration/organization/exchangeRate/index';
import CustomerRequests from '../adminstration/organization/customerRequest/index';
import ProtectedRoute from './protected.route';
import PaymentDetails from '../../components/paymentUnique/index';
import HotelPayment from '../../components/paymentUnique/payHotel';
import PaymentFail from '../../components/paymentUnique/PaymentFail';
import AdminRemainderAlertlist from '../common/dashBoard/AdminRemainderAlertlist';
import AdminAlertlist from '../common/dashBoard/AdminAlertList';
import Notification from '../adminstration/airServices/notification/Notification';
import CommissionDisc from '../adminstration/hotelServices/commissionDisc/index';
import NotificationAccess from '../adminstration/airServices/notification/NotificationAccess';
import NotificationMaster from '../adminstration/airServices/notification/NotificationMaster';
import HotelReport from '../adminstration/hotelServices/dsrReport/main';
import MyReport from '../adminstration/myReport/dsr/flight/main';
import FlightReport from '../adminstration/airServices/dsrReport/main';
import InsuranceSummary from '../postBooking/insurances/index';
import InsuraneReport from '../adminstration/InsuranceReport/main';
import UserProfile from '../adminstration/organization/userProfile/index';
import EditProfile from '../adminstration/organization/userProfile/Editprofile';
import ManualRefund from '../../components/postBooking/hotel/BookingSummary/ManualRefund';
import FlightIndigoRefund from '../postBooking/refund/FlightIndigoRefund';
import AuthenticationService from '../../services/authService/AuthenticationService';
import TransactionMain from '../adminstration/dailyReports/TransactionMain';
import ReceiptMain from '../adminstration/dailyReports/ReceiptMain';
import AddAirport from '../adminstration/airServices/airportConfig/index';
import Revenumanagement from '../adminstration/airServices/revenumanagement/RevenuMain';
import LandingPage from '../india/LandingPage';
import LoginInd from '../india/Login';
import OfficeidAdd from '../adminstration/airServices/officeid/index';
import TDSConfig from '../adminstration/organization/tdsConfig';
export const USER_AUTH_DATA = "userAuthData";
export const PAY_RESP = "payResp";
export const PRODUCT_TYPE = "productType";
export const USER_SESSION = "usr_session";
export const ATT_BRANCHES = "attBranches";
export const ATT_COMPANIES = "attCompanys";
export const BOOKING_SERVICE = "bkServiceFee";
export const HTL_REQUEST = "htlRequest";
export const HTL_MARKUP = "htlMarkup";
export const HTL_CHD_AGES = "chdages";
export const COMP_ID = "COMP_ID";
export const PLB_DISCOUNT = "PLB_DISCOUNT";
export const STA_SERVICE_FEE = "staFee";
export const REISUE_RESP = "reisueResp";
export const CLIENT_IP = "clientIp";
export const PAY_DATA = "payData";
export const PAY_REQ = "payRequest";
export const ONFLY_FLT_MKP = "onflyFltMkp";
export const SEL_AIR_PRICE = "selAirPrice";
export const USERSTATE = "userState";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";
export const REGION_COUNTRY = "REGION_COUNTRY";
export const SUMMARY_COMP_ID = "SUMMARY_COMP_ID";
export const SUMMARY_COMP_REGION = "SUMMARY_COMP_REGION";
export const COUNTRY_DATA = "COUNTRY_DATA"

function RouterApp() {
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [enableInd,setEnableInd] = useState(false)
    const [enableQr,setEnableQr] = useState(false)

    useEffect(() => {
        if(window.sessionStorage.getItem(REGION_COUNTRY)!==null){
            if(!enableInd && window.sessionStorage.getItem(REGION_COUNTRY)==="IN"){
                setEnableInd(true);
                //setEnableQr(true);
            }else if(!enableQr){
                setEnableQr(true);
            }
            }else{
                setEnableQr(true);
            }
    }, [window.sessionStorage.getItem(REGION_COUNTRY)!==null])


    useEffect(() => {
            var filename = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            if(filename===""){
              window.location="/ezytrip/login";
             }
             var userSession = localStorage.getItem(USER_SESSION);
             var userState = localStorage.getItem(USERSTATE);
            if (userSession==="true"){
                if(userState !== "active"){
                var userSession = localStorage.setItem(USER_SESSION,false);
                localStorage.removeItem(ATT_BRANCHES);
                localStorage.removeItem(ATT_COMPANIES);
                localStorage.removeItem(BOOKING_SERVICE);
                localStorage.removeItem(HTL_REQUEST);
                localStorage.removeItem(HTL_MARKUP);
                localStorage.removeItem(HTL_CHD_AGES);
                localStorage.removeItem(COMP_ID);
                localStorage.removeItem(STA_SERVICE_FEE);
                localStorage.removeItem(PLB_DISCOUNT);
                localStorage.removeItem(REISUE_RESP);
                localStorage.removeItem(CLIENT_IP);
                localStorage.removeItem(PAY_DATA);
                localStorage.removeItem(PAY_REQ);
                localStorage.removeItem(PAY_RESP);
                window.sessionStorage.removeItem("CMPID")
                window.sessionStorage.removeItem(ONFLY_FLT_MKP)
                window.sessionStorage.removeItem(SEL_AIR_PRICE);
                localStorage.removeItem(SUMMARY_COMP_ID)
                window.sessionStorage.removeItem(SUMMARY_COMP_REGION)
                localStorage.removeItem(COUNTRY_DATA)
                sendInCompleteEmail();
                window.location="/ezytrip/login";
            }
        }
        }, []);

    useEffect(() => {
        if (context.logindata === "") {
            var userData = localStorage.getItem(USER_AUTH_DATA);
            if (userData) {
                let data=JSON.parse(userData);
                if(!data.is_admin){
                    if(data.cid!==0 || data.uid!==0 ){
                    checkCompUserActive(data.cid,data.uid,data.utype)
                 }
                }else{
                    setContext({ logindata: JSON.parse(userData) });
                }
            }
        }
    }, [context.logindata === ""]);

    useEffect(() => {
        console.log("ROUTE :" + JSON.stringify(context.logindata.roles));
        if (context.logindata.roles === undefined) {
            var userData = localStorage.getItem(USER_AUTH_DATA);
            if (userData) {
                let data=JSON.parse(userData);
                if(!data.is_admin){
                    if(data.cid!==0 || data.uid!==0 ){
                    checkCompUserActive(data.cid,data.uid,data.utype)
                 }
                }else{
                    setContext({ logindata: JSON.parse(userData) });
                }
            }
        }
    }, [context.logindata.roles]);

    const checkCompUserActive=(compId,userId,utype)=>{
        let request="";
        if(utype==="att_user"){
            request={
                usr_type:utype,
                usr_id:userId
            }
        }else{
            request={
                usr_type:utype,
                company_id:compId,
                usr_id:userId
            }
        }
    AuthenticationService.checkCompActive(request).then(response => {
        if(response.data.suc){
            var userData = localStorage.getItem(USER_AUTH_DATA);
            setContext({ logindata: JSON.parse(userData) });
        }else{
            localStorage.removeItem(USER_AUTH_DATA)        
        }
       
    })
    }

    useEffect(() => {
        sendInCompleteEmail();
    }, [sessionStorage.getItem(CHECK_BOOKING)]);

    const sendInCompleteEmail=()=>{
        let check=sessionStorage.getItem(CHECK_BOOKING);
        let bookingId=sessionStorage.getItem(BOOKING_REF_NO);
        if (check!==null && check && bookingId!==null && bookingId!==undefined){
            AuthenticationService.updateStatus({"booking_id":bookingId}).then(response => {
                console.log("updated Incomplete Status : ",response)
                sessionStorage.removeItem(CHECK_BOOKING);
                sessionStorage.removeItem(BOOKING_REF_NO);
            })
        }
    }



        useEffect(() => {
            if (window.location.href!==undefined){
                  if(window.location.href.includes("payment-verifying")){
                    let product=window.sessionStorage.getItem(PRODUCT_TYPE)
                    window.sessionStorage.setItem(PAY_RESP, window.location.href);
                    if(product!==null && product!==undefined){
                        if(product==="Flight"){
                            return window.location = "/ezytrip/bookings/fltpay"
                          }else{
                            return window.location = "/ezytrip/bookings/htlpay"
                          }
                    }
                  }
              }
          }, [window.location.href]);

        return (
            <>
                <Router>
                    <>
                        <Switch>
                            <ProtectedRoute path="/demo" exact component={Demo} />
                            <ProtectedRoute path="/samplePDFPage" exact component={PDFTestPage} />
                            <Route path="/" exact component={LoginPage} />
                            {enableInd && <Route path="/ezytrip/login" exact component={LandingPage} />}
                            <Route path="/ezytrip/ind/login" exact component={LoginInd} />
                            <Route path="/ezytrip/dashboard/loader" exact component={loader} />
                            <Route path="/ezytrip/dashboard/loader2" exact component={loader1} />
                            <Route path="/ezytrip/admindashboard" exact component={AdminDashboard}/>
                            {/* <Route path="/ezytrip/dashboard/adminDashBoard" exact component={adminDashBoard} /> */}
                            {enableQr && <Route path="/ezytrip/login" exact component={LoginPage} /> }
                            {/* <Route path="/accessdenied" exact component={AccessDeniedPage} /> */}
                            <ProtectedRoute path="/ezytrip/dashboard" exact component={DashBoardMain} />
                            <ProtectedRoute path="/ezytrip/pnrimport" exact component={DashBoardPNRImport} />
                            <ProtectedRoute path="/ezytrip/dashboard/remainders/list" exact component={AdminRemainderAlertlist} />
                            <ProtectedRoute path="/ezytrip/dashboard/alerts/list" exact component={AdminAlertlist} />
                            <Route path="/ezytrip/dashboard/agentLogin" exact component={DashBoardLogin} />
                            <Route path="/ezytrip/india/login" exact component={Indialogin} />
                            <ProtectedRoute path="/ezytrip/dashboard/bookingList" exact component={DashBoardBookingList} />
                            <Route path="/ezytrip/india/landingpage" exact component={Indialandingpage} />
                            <ProtectedRoute path="/ezytrip/dashboard/hotelmanual" exact component={HotelManualBooking} />
                            <ProtectedRoute path="/ezytrip/dashboard/manualbooking" exact component={ManualBooking} />
                            <ProtectedRoute path="/ezytrip/dashboard/bookingsummary"  component={FlightBookingSummary} />
                            <ProtectedRoute path="/ezytrip/bookings/flight/results" exact component={FlightPage} />
                            <ProtectedRoute path="/ezytrip/bookings/flight/flightbyflight" exact component={RoundTrip} />
                            <ProtectedRoute path="/ezytrip/bookings/flight/itinerary" exact component={Itinerary} />
                            <ProtectedRoute path="/ezytrip/bookings/flight/confirmation" exact component={Confirmation} />

                            <ProtectedRoute path="/ezytrip/bookings/hotel/results" exact component={HotelPage} />
                            <ProtectedRoute path="/ezytrip/bookings/hotel/review" exact component={HotelReview} />
                            <ProtectedRoute path="/ezytrip/bookings/hotel/guestDetails" exact component={HotelGuestDetails} />
                            <ProtectedRoute path="/ezytrip/bookings/hotel/confirmation" exact component={ConfirmationPage} />

                            <ProtectedRoute path="/ezytrip/bookings/insurance/results" exact component={InsurancesResult} />
                            <ProtectedRoute path="/ezytrip/bookings/insurance/review" exact component={InsurancesReview} />
                            <ProtectedRoute path="/ezytrip/bookings/insurance/confirmation" exact component={InsurancesConfirmation} />
                            {/* <Route path="/ezytrip/administration" exact component={CommonAdminPage} /> */}

                            <ProtectedRoute path="/ezytrip/administration/countryMaster" exact component={CountryMast} />
                            <ProtectedRoute path="/ezytrip/administration/branch" exact component={Branch} />
                            <ProtectedRoute path="/ezytrip/administration/admin" exact component={MainMenu} />
                            <ProtectedRoute path="/ezytrip/administration/organization" exact component={OrganizationMenu} />
                            <ProtectedRoute path="/ezytrip/administration/category" exact component={Category} />
                            <ProtectedRoute path="/ezytrip/administration/markup" exact component={AirMarkup} />
                            <ProtectedRoute path="/ezytrip/administration/taSTAmarkup" exact component={TASTAMarkup} />
                            <ProtectedRoute path="/ezytrip/administration/commission" exact component={AirlineCommission} />
                            <ProtectedRoute path="/ezytrip/administration/promocode" exact component={PromoCode} />
                            <ProtectedRoute path="/ezytrip/administration/dealCode" exact component={DealCode} />
                            <ProtectedRoute path="/ezytrip/administration/serviceFee" exact component={ServiceFee} />
                            {/*  <Route path="/ezytrip/administration/officeid" exact component={CreateOfficeId} /> */}
                            <ProtectedRoute path="/ezytrip/administration/holdairline" exact component={HoldAirline} />
                            <ProtectedRoute path="/ezytrip/administration/oidMaster" exact component={OIDMaster}/>
                           <ProtectedRoute path="/ezytrip/administration/officeid" exact component={Officeid} />
                           <ProtectedRoute path="/ezytrip/administration/ofc/create" exact component={OfficeidAdd} />
                            <ProtectedRoute path="/ezytrip/administration/user" exact component={UserManagement} />
                            <ProtectedRoute path="/ezytrip/administration/role" exact component={RoleManagement} />
                            <ProtectedRoute path="/ezytrip/administration/company" exact component={CompanyManagement} />
                            <ProtectedRoute path="/ezytrip/administration/creditmanagement" exact component={Creditmanagement} />
                            <ProtectedRoute path="/ezytrip/administration/customFields" exact component={CustomFields} />
                            <ProtectedRoute path="/ezytrip/administration/oidMaxHits" exact component={MaxHits} />
                            <ProtectedRoute path="/ezytrip/bookings/flight/error" exact component={BookingError} />
                            <ProtectedRoute path="/ezytrip/dashboard/refund" exact component={RefundRecord} />
                            <ProtectedRoute path="/ezytrip/dashboard/manual-refund" exact component={ManualRefundRecord} />
                            <ProtectedRoute path="/ezytrip/dashboard/reissue" exact component={FlightReissueDetails} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/markup" exact component={HotelMarkup} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/serviceFee" exact component={HotelServiceFee} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/dealCode" exact component={HotelDealCode} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/commission" exact component={HotelCommission} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/promocode" exact component={HotelPromoCode} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/officeid" exact component={HotelOfficeid} />
                            <ProtectedRoute path="/ezytrip/dashboard/hotel/bookingsummary"  component={HotelBookingSummary} />

                            <ProtectedRoute path="/ezytrip/administration/visa" exact component={Visa} />
                            <ProtectedRoute path="/ezytrip/booking/visa/visaform"  component={VisaForm} />
                            <ProtectedRoute path="/ezytrip/dashboard/visa/summary"  component={Visasummary} />
                            <ProtectedRoute path="/ezytrip/dashboard/visa/summarydit"  component={VisaSummaryEdit} />
                            <ProtectedRoute path="/ezytrip/administration/visa-config"  component={VisaConfiguration} />

                            <ProtectedRoute path="/ezytrip/administration/extraservices"  component={ExtraServices} />
                            <ProtectedRoute path="/ezytrip/administration/notification"  component={Notification}/>
                            <ProtectedRoute path="/ezytrip/administration/notificationMaster"  component={NotificationMaster}/>
                            <ProtectedRoute path="/ezytrip/administration/notificationAccess"  component={NotificationAccess}/>
                            {/* Static page */}
                            <ProtectedRoute path="/ezytrip/gent/termsandconditions" exact component={Agenttermsandconditions} />
                            <ProtectedRoute path="/ezytrip/supplier/termsandconditions" exact component={Suppliertermsandconditions} />
                            <ProtectedRoute path="/ezytrip/termsandconditions" exact component={TermsAndCondtions} />
                            <ProtectedRoute path="/ezytrip/administration/hotelServices/maxOnhold" exact component={MaxHoldBooking} />
                            <ProtectedRoute path="/ezytrip/administration/hotelServices/dsrreport" component={DSRReport} />
                            <ProtectedRoute path="/ezytrip/administration/supplierConfig" exact component={SupplierConfig} />
                            <ProtectedRoute path="/ezytrip/administration/gst" exact component={GSTConfiguration} />
                            <ProtectedRoute path="/ezytrip/administration/tds" exact component={TDSConfig} />
                            <ProtectedRoute path="/ezytrip/administration/ExchangeRate" exact component={ExchangeRate} />
                            <ProtectedRoute path="/ezytrip/administration/custRequests" exact component={CustomerRequests} />
                            <ProtectedRoute path="/ezytrip/bookings/fltpay" exact component={PaymentDetails} />
                            <ProtectedRoute path="/ezytrip/bookings/htlpay" exact component={HotelPayment} />
                            <ProtectedRoute path="/ezytrip/bookings/pay-fail" exact component={PaymentFail} />

                            <ProtectedRoute path="/ezytrip/administration/hotelreport" exact component={HotelReport} />
                            <ProtectedRoute path="/ezytrip/administration/myreport" exact component={MyReport} />
                            <ProtectedRoute path="/ezytrip/administration/flightreport" exact component={FlightReport} />
                            <ProtectedRoute path="/ezytrip/administration/insurances" exact component={InsurancesPostBooking} />
                            <ProtectedRoute path="/ezytrip/dashboard/insurance/bookingsummary"  component={InsuranceSummary} />
                            <ProtectedRoute path="/ezytrip/administration/insreport" exact component={InsuraneReport} />
                            <ProtectedRoute path="/ezytrip/administration/hotelServices/commdisc" exact component={CommissionDisc} />
                            <ProtectedRoute path="/ezytrip/administration/userprofile" exact component={UserProfile} />
                            <ProtectedRoute path="/ezytrip/administration/editprofile" exact component={EditProfile} />
                            <ProtectedRoute path="/ezytrip/hotel/bookingsummary/manual-refund" exact component={ManualRefund} />
                            <ProtectedRoute path="/ezytrip/bookingsummary/flight/lcc-refund" exact component={FlightIndigoRefund} />
                            <ProtectedRoute path="/ezytrip/administration/transaction" exact component={TransactionMain} />
                            <ProtectedRoute path="/ezytrip/administration/receipt" exact component={ReceiptMain} />
                            <ProtectedRoute path="/ezytrip/administration/airport" exact component={AddAirport} />
                            <ProtectedRoute path="/ezytrip/administration/revenue" exact component={Revenumanagement} />
                            <ProtectedRoute path="/ezytrip/administration/hotel/crossell"  component={CrossSellMain} />
                                  </Switch>
                    </>
                </Router>
            </>
        )
}


export default RouterApp