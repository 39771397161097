import axios from "axios";

const API_URL = process.env.REACT_APP_B2B_URL;

class bookingService {
  /**
   * @author :Azamuddin
   * @description : get latest 7 bookings
   * @date : 1-10-2020
   * ----id=>company id
   */
  async getrecentData(obj) {
    return await axios.get(
      `${API_URL}/att/dashboard/booking?companyid=${obj.id}&landing=true&utype=${obj.utype}&uid=${obj.uid}&admin=${obj.is_admin}&region_type=${obj.region_type}`
    );
  }
  async getMyReport(obj) {
    return await axios.post(
      `${API_URL}/att/dashboard/myreports`, obj
    );
  }

  /**
   * @author :Azamuddin
   * @description : get bookings for booking list
   * @date : 1-10-2020
   * ----id=>company id
   */
  async getbookinglistData(obj) {
    return await axios.get(`${API_URL}/att/dashboard/booking?companyid=${obj.id}&utype=${obj.utype}&uid=${obj.uid}&admin=${obj.is_admin}&region_type=${obj.region_type}`);
  }

  /**
   * @author :Azamuddin
   * @description : get bookings for search Criteria
   * @date : 1-10-2020
   */
  async getBooking(criteria) {
    return await axios.post(`${API_URL}/att/dashboard/getbooking`, criteria);
  }

  /**
   * @author :Azamuddin
   * @description : get response for quick search
   * @date : 1-10-2020
   */
  async quicksearch(req) {
    return await axios.get(
      `${API_URL}/att/dashboard/quicksearch?id=${req.id}&cid=${req.cid}&uid=${req.uid},&utype=${req.utype}&admin=${req.is_admin}&tag=${req.tag}`
    );
  }
}

export default new bookingService();
