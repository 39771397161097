import React, { useState, useEffect, useCallback, useReducer, useContext, useRef, } from "react";
import { Alert, Col, Form, Row, Tab, Accordion, Card, Button } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import ShowHide from "../../../../common/showHide/ShowHide";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { stdBookingCodes, countriesWitCode } from "../../../../adminstration/utils";
import DateUtils from "../../../../commonUtils/DateUtils";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-dropdown-select";
import log from "loglevel";
import CancellationPolicy from "../../pages/CancellationPolicy";
import ImportantInfo from "../../pages/ImportantInfo";

/**
 * @description:This function will creaet all the Gust details
 * @author: Pravas
 * @param {*}
 * @function Traveller details
 * @date : 24-01-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
  roomDetails: [],
  roomConfig: false,
  initialResponse: false,
  initalRoomResponse: [],
  adtList: [],
  chdList: [],
  stdCodeMenu: stdBookingCodes(),
  countriesMenu: [],
  isOnhold: false,
  cheked: false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//=====================================
//  function
//=====================================
function GustDetails(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [specialRequest, setSpecialRequest] = useState(false);
  const [paxDetails, setPaxDetails] = useState(false);
  const [adts, setAdt] = useState();
  const [chds, setChd] = useState();
  const [request, setRequest] = useState(props.srequest);

  const title = [
    { label: "Mr", value: "Mr" },
    { label: "Miss", value: "Miss" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Shka", value: "Shka" },
    { label: "Shk", value: "Shk" },
  ];
  const titleJr = [
    { value: "Miss", label: "Miss" },
    { value: "Mstr", label: "Mstr" },
  ];

  const showSpecialRequest = () => {
    if (specialRequest) {
      setSpecialRequest(false);
    } else {
      setSpecialRequest(true);
    }
  };
  useEffect(() => {
    if (request) {
      // create roomList Response for each room
      if (request.bktype === "DIRECT") {
        createRoomList();
      } else {
        dispatch({ type: "isOnhold", payload: true });
        if (request.isallpax) {
          setPaxDetails(true);
          convertAllPaxList(request.roompax)
          props.allpaxcheckbox(true);
        } else {
          convertRoomPax(request.roompax)
        }
      }
      countriesWitCode(request).then((res) => {
        dispatch({ type: "countriesMenu", payload: res });
      });
    }
    window.scrollTo(0, 0);
  }, [request]);

  useEffect(() => {
    checkTravellerList();
  }, [props.travellersList.res]);

  useEffect(()=>{
    if (props.enableCHDOtila){
      setPaxDetails(true);
      allpakShow()
    }
      },[props.enableCHDOtila])

  const convertRoomPax = (roomsPax) => {
    let roomInitRequest = [];
    for (let eachRoom of roomsPax) {
      let arr = [];
      let obj = "";
      for (let pax of eachRoom.pax) {
        if (pax.lead) {
          obj = {
            sno: pax.sno,
            tl: pax.tl,
            ty: pax.ty,
            plbl: pax.plbl,
            lead: true,
            fn: pax.fn,
            ln: pax.ln,
            em: pax.em,
            phc: pax.phc,
            phn: pax.phn
          };
        } else {
          obj = {
            sno: pax.sno,
            tl: pax.tl,
            ty: pax.ty,
            plbl: pax.plbl,
            lead: false,
            fn: pax.fn,
            ln: pax.ln,
          };
        }
        arr.push(obj);
      }
      let payload = {
        rmindex: eachRoom.rmindex,
        rmid: eachRoom.rmid,
        adt: eachRoom.adt,
        chd: eachRoom.chd ? eachRoom.chd : 0,
        spr: eachRoom.spr,
        pax: arr,
        canpolicy: eachRoom.canpol,
      };
      roomInitRequest.push(payload);
      setAdt(eachRoom.adt);
      let chd = 0;
      if (eachRoom.chd && eachRoom.chd > 0) {
        chd = eachRoom.chd;
        setChd(chd);
      }
      dispatch({ type: "roomDetails", payload: roomInitRequest });
    }
  }
  const useProfile = () => {
    dispatch({ type: "cheked", payload: !state.cheked });
    let check = document.getElementById("profile").checked;
    let profilearray = props.prof;
    if (check == true && state.roomDetails[0].pax.length > 0) {
      let objindex = state.roomDetails[0].pax.findIndex(obj => obj.sno == 1)
      if(props.fname && props.fname!==""){
        state.roomDetails[0].pax[objindex].fn = props.fname===""?profilearray.fn:props.fname;

      }

      state.roomDetails[0].pax[objindex].ln = props.lname===""?profilearray.ln:props.lname;
      state.roomDetails[0].pax[objindex].tl = props.title===""?profilearray.tl:props.title;
      state.roomDetails[0].pax[objindex].phc = props.code===""?profilearray.phc:props.code ;
      state.roomDetails[0].pax[objindex].em = props.email===""?profilearray.em:props.email;
      state.roomDetails[0].pax[objindex].phn = props.phone===""?profilearray.phn:props.phone;
      dispatch({ type: "roomDetails", payload: state.roomDetails })
    }
    else {
      let objindex = state.roomDetails[0].pax.findIndex(obj => obj.sno == 1)
      state.roomDetails[0].pax[objindex].fn = ""
      state.roomDetails[0].pax[objindex].ln = ""
      state.roomDetails[0].pax[objindex].tl = []
      state.roomDetails[0].pax[objindex].phc = ""
      state.roomDetails[0].pax[objindex].em = ""
      state.roomDetails[0].pax[objindex].phn = ""
      dispatch({ type: "roomDetails", payload: state.roomDetails })
    }
  }
  const convertAllPaxList = (roomsPax) => {
    let roomInitRequest = [];
    for (let eachRoom of roomsPax) {
      let arr = [];
      for (let pax of eachRoom.pax) {
        const obj = {
          sno: pax.sno,
          tl: pax.tl,
          ty: pax.ty,
          plbl: pax.plbl,
          lead: pax.lead,
          fn: pax.fn,
          ln: pax.ln,
          em: pax.em,
          phc: pax.phc,
          phn: pax.phn,
          dob: pax.dob,
          doc: { ty: pax.doc.ty, num: pax.doc.num, na: pax.doc.na, isc: pax.doc.isc, isd: pax.doc.isd, exd: pax.doc.exd },
        };
        arr.push(obj)
      }
      let payload = {
        rmindex: eachRoom.rmindex,
        rmid: eachRoom.rmid,
        adt: eachRoom.adt,
        chd: eachRoom.chd ? eachRoom.chd : 0,
        spr: eachRoom.spr,
        pax: arr,
        canpolicy: eachRoom.canpol,
      };
      roomInitRequest.push(payload);
      setAdt(eachRoom.adt);
      let chd = 0;
      if (eachRoom.chd && eachRoom.chd > 0) {
        chd = eachRoom.chd;
        setChd(chd);
      }
    }

    dispatch({ type: "roomDetails", payload: roomInitRequest });
  }

  //=====================================
  // This function will create the ADT,CHD and INF List travellers beased on selected Profile.
  //=====================================
  const checkTravellerList = () => {
    let adultsList = [];
    let childsList = [];
    let infantsList = [];
    if (props.travellersList.res) {
      for (let p1 of props.travellersList.res) {
        if (p1.ty === "ADT" && adts > 0) {
          adultsList.push(p1);
        } else if (p1.ty === "CHD" && chds > 0) {
          childsList.push(p1);
        }
      }
      dispatch({ type: "adtList", payload: adultsList });
      dispatch({ type: "chdList", payload: childsList });
      dispatch({ type: "infList", payload: infantsList });
    }
  };
  function createRoomList() {
    if (request && request !== undefined) {
      let roomInitRequest = [];
      for (let eachRoom of request.rooms) {
        let arr = [];
        let obj = "";
        if (eachRoom.rmindex === 1) {
          obj = {
            sno: 1,
            tl: "",
            ty: "ADT",
            plbl: "Adult",
            lead: true,
            fn: "",
            ln: "",
            em: "",
            phc: "",
            phn: "",
          };
        } else {
          obj = {
            sno: 1,
            tl: "",
            ty: "ADT",
            plbl: "Adult",
            lead: true,
            fn: "",
            ln: "",
          };
        }
        arr.push(obj);

        let payload = {
          rmindex: eachRoom.rmindex,
          rmid: eachRoom.rmid,
          adt: eachRoom.adt,
          chd: eachRoom.chd ? eachRoom.chd : 0,
          spr: "",
          pax: arr,
          canpolicy: eachRoom.rmpr[0].canpol,
        };
        roomInitRequest.push(payload);
        setAdt(eachRoom.adt);
        let chd = 0;
        if (eachRoom.chd && eachRoom.chd > 0) {
          chd = eachRoom.chd;
          setChd(chd);
        }
      }
      dispatch({ type: "roomDetails", payload: roomInitRequest });
      dispatch({ type: "initialResponse", payload: true });
      props.roomsresponse(roomInitRequest);
      if (state.roomDetails.length > 0) {
        for (let val in state.roomDetails) {
          let oldData = state.roomDetails[val]
          oldData.pax[0].doc = { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" }
          roomInitRequest[val].pax[0] = oldData.pax[0]
        }
      }
    }
  }

  const handleOnInpChange = (txt, id, ridx, type, idx) => {
    // a fn 0 ADT 0
    let newHotel = state.roomDetails.map((room, roomIdx) => {
      if (roomIdx == ridx) {
        let paxData = room;
        let newpax = paxData.pax.map((paxx, sidx) => {
          if (idx !== sidx) {
            return paxx;
          } else {
            let leadpax = false;
            if (idx === 0 && type == "ADT") {
              leadpax = true;
            }
            return { ...paxx, [id]: txt };
          }
        });
        return { ...room, pax: newpax };
      } else {
        return room;
      }
    });
    dispatch({ type: "roomDetails", payload: newHotel });
    props.roomsresponse(newHotel);
  };

  // OnChange event we will call this and update the state
  const handleChange = (idr, ridx, type, idx) => (e) => {
    // "ln",idx,adt.ty,adtCount
    try {
      if (e[0] === undefined) {
        let newHotel = state.roomDetails.map((room, roomIdx) => {
          if (roomIdx == ridx) {
            let paxData = room;
            let newpax = paxData.pax.map((paxx, sidx) => {
              const { id, value } = e.target;
              if (idx !== sidx) {
                return paxx;
              } else if (id !== "num" && id !== "na" && id !== "isc") {
                return { ...paxx, [id]: value };
              } else {
                return {
                  ...paxx,
                  doc: {
                    ...paxx.doc,
                    [id]: value,
                  },
                };
              }
            });
            return { ...room, pax: newpax };
          } else {
            return room;
          }
        });
        dispatch({ type: "roomDetails", payload: newHotel });
        props.roomsresponse(newHotel);
        //  props.sendPsngersData(newpax);
      } else {
        let newHotel = state.roomDetails.map((room, roomIdx) => {
          if (roomIdx == ridx) {
            let paxData = room;
            let newpax = paxData.pax.map((paxx, sidx) => {
              // const { id, value } = e.target;
              if (idx !== sidx) {
                return paxx;
              } else if (e[0].doc !== undefined && paxDetails) {
                // ty: "P", num: "", na: "", isc: "", isd: "", exd: ""
                return {
                  ...paxx,
                  tl: e[0].tl,
                  fn: e[0].fn,
                  ln: e[0].ln,
                  em: e[0].eml,
                  phc: e[0].phc,
                  phn: e[0].phn,
                  dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "",
                  doc: {
                    ...paxx.doc,
                    num: e[0].doc.num,
                    na: e[0].doc.na,
                    ty: "P",
                    isc: e[0].doc.isc,
                    isd:
                      e[0].doc.isd !== undefined &&
                        e[0].doc.isd !== "0000-00-00 00:00:00"
                        ? DateUtils.convertStringToDate(e[0].doc.isd)
                        : "",
                    exd:
                      e[0].doc.exd && e[0].doc.exd !== "0000-00-00 00:00:00"
                        ? DateUtils.convertStringToDate(e[0].doc.exd)
                        : "",
                  },
                };
              } else {
                return {
                  ...paxx,
                  tl: e[0].tl,
                  fn: e[0].fn,
                  ln: e[0].ln,
                  em: e[0].eml,
                  phc: e[0].phc,
                  phn: e[0].phn,
                  dob: e[0].dob ? DateUtils.convertStringToDate(e[0].dob) : "",
                };
              }
            });
            return { ...room, pax: newpax };
          } else {
            return room;
          }
        });
        dispatch({ type: "roomDetails", payload: newHotel });
        props.roomsresponse(newHotel);
      }
    } catch (err) {
      log.error("Exception occured in handleChange function---" + err);
    }
  };

  function createAllRoomPaxList() {
    if (request && request !== undefined) {
      let roomInitRequest = [];
      for (let eachRoom of request.rooms) {
        let arr = [];
        let adtcount = 1;
        for (let i = 0; i < eachRoom.adt; i++) {
          if (eachRoom.rmindex == 1 && i === 0) {
            let obj = {
              sno: 1,
              tl: "",
              ty: "ADT",
              plbl: "Adult",
              lead: true,
              fn: "",
              ln: "",
              em: "",
              phc: "",
              phn: "",
              dob: "",
              doc: { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" },
            };
            arr.push(obj);
          } else if (eachRoom.rmindex !== 1 && i === 0) {
            let obj = {
              sno: 1,
              tl: "",
              ty: "ADT",
              plbl: "Adult",
              lead: true,
              fn: "",
              ln: "",
              dob: "",
              doc: { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" },
            };
            arr.push(obj);
          } else {
            let obj = {
              sno: adtcount,
              tl: "",
              ty: "ADT",
              plbl: "Adult",
              lead: false,
              fn: "",
              ln: "",
              dob: "",
              doc: { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" },
            };
            arr.push(obj);
          }
          adtcount = adtcount + 1;
        }
        if (eachRoom.chd && eachRoom.chd > 0) {
          let chdcount = 1;
          for (let i = 0; i < eachRoom.chd; i++) {
            let obj = {
              sno: chdcount,
              tl: "",
              ty: "CHD",
              plbl: "Child",
              lead: false,
              fn: "",
              ln: "",
              dob: "",
              doc: { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" },
            };
            arr.push(obj);
            chdcount = chdcount + 1;
          }
        }
        let obj = {
          rmindex: eachRoom.rmindex,
          rmid: eachRoom.rmid,
          adt: eachRoom.adt,
          chd: eachRoom.chd ? eachRoom.chd : 0,
          spr: "",
          pax: arr,
          canpolicy: eachRoom.rmpr[0].canpol,
        };
        roomInitRequest.push(obj);
        setAdt(eachRoom.adt);
        let chd = 0;
        if (eachRoom.chd && eachRoom.chd > 0) {
          chd = eachRoom.chd;
          setChd(chd);
        }
      }
      dispatch({ type: "roomDetails", payload: roomInitRequest });
      dispatch({ type: "initialResponse", payload: true });
      props.roomsresponse(roomInitRequest);
      if (state.roomDetails.length > 0) {
        for (let val in state.roomDetails) {
          let oldData = state.roomDetails[val]
          oldData.pax[0].doc = { ty: "P", num: "", na: "", isc: "", isd: "", exd: "" }
          roomInitRequest[val].pax[0] = oldData.pax[0]
        }
      }
    }
  }
  //=====================================
  //  function for add all pax details
  //=====================================
  const allpakShow = (e) => {
    if (paxDetails) {
      setPaxDetails(false);
      createRoomList();
      props.allpaxcheckbox(false);
    } else {
      setPaxDetails(true);
      createAllRoomPaxList();
      props.allpaxcheckbox(true);
    }
  };

  const handleSpecialRquest = (idx) => (e) => {
    const { id, value } = e.target;
    let newHotel = state.roomDetails.map((each, ridx) => {
      if (ridx === idx) {
        return { ...each, spr: value };
      } else {
        return each;
      }
    });
    dispatch({ type: "roomDetails", payload: newHotel });
    props.roomsresponse(newHotel);
  };

  const paxDate = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() - n));
  };

  // OnChange event we will call this and update the state
  const handleDobChange = (ridx, idx) => (date) => {
    const convertDate = DateUtils.convertStringToDate(date);
    let newHotel = state.roomDetails.map((room, roomIdx) => {
      if (roomIdx == ridx) {
        let paxData = room;
        let newpax = paxData.pax.map((paxx, sidx) => {
          // const { id, value } = e.target;
          if (idx !== sidx) {
            return paxx;
          } else {
            return {
              ...paxx,
              dob: convertDate,
            };
          }
        });
        return { ...room, pax: newpax };
      } else {
        return room;
      }
    });
    dispatch({ type: "roomDetails", payload: newHotel });
    props.roomsresponse(newHotel);
  };

  // OnChange event we will call this and update the state
  const handleExpDateChange = (ridx, idx) => (date) => {
    const convertDate = DateUtils.convertStringToDate(date);
    let newHotel = state.roomDetails.map((room, roomIdx) => {
      if (roomIdx == ridx) {
        let paxData = room;
        let newpax = paxData.pax.map((paxx, sidx) => {
          // const { id, value } = e.target;
          if (idx !== sidx) {
            return paxx;
          } else {
            return {
              ...paxx,
              doc: {
                ...paxx.doc,
                exd: convertDate,
              },
            };
          }
        });
        return { ...room, pax: newpax };
      } else {
        return room;
      }
    });
    dispatch({ type: "roomDetails", payload: newHotel });
    props.roomsresponse(newHotel);
  };

  // OnChange event we will call this and update the state
  const handleIssuDateChange = (ridx, idx) => (date) => {
    const convertDate = DateUtils.convertStringToDate(date);
    let newHotel = state.roomDetails.map((room, roomIdx) => {
      if (roomIdx == ridx) {
        let paxData = room;
        let newpax = paxData.pax.map((paxx, sidx) => {
          // const { id, value } = e.target;
          if (idx !== sidx) {
            return paxx;
          } else {
            return {
              ...paxx,
              doc: {
                ...paxx.doc,
                isd: convertDate,
              },
            };
          }
        });
        return { ...room, pax: newpax };
      } else {
        return room;
      }
    });
    dispatch({ type: "roomDetails", payload: newHotel });
    props.roomsresponse(newHotel);
  };

  return (
    (
      <>
        {state.roomDetails.length > 0 && (
          <div className="boxLayout">
            <ShowHide visible="true" icon="person" title="Guest Details">
              <div className="showHide-content htlGust pl-0 pr-0">
                <div className="allPax">
                {((props.prof !== undefined && props.prof !== null && props.prof.length !== 0 )|| 
                  props.email!==""||props.fname!==""||props.lname!==""||props.phone!== ""
                  )&&
                  <Form.Check
                    type="checkbox"
                    disabled={state.isOnhold}
                    onChange={useProfile}
                    checked={state.cheked}
                    id="profile"
                    label="Use Profile details as Lead Guest"
                    custom
                    className="mr-4"
                  />
                    }
                 {!props.enableCHDOtila && <Form.Check
                    type="checkbox"
                    disabled={state.isOnhold}
                    onChange={allpakShow}
                    checked={paxDetails}
                    id="pax"
                    label="Enter All Guest Details"
                    custom
                  />}
                </div>
                {state.roomDetails.map((room_data, idx) => (
                  <div className="travellerList">
                    <React.Fragment key={idx}>
                      <ShowHide
                        visible="true"
                        title={
                          "Room " +
                          (room_data.rmindex) +
                          " (" +
                          room_data.adt +
                          " Adult(s)" +
                          (room_data.chd && room_data.chd > 0
                            ? " & " + room_data.chd + " Child(s)"
                            : "") +
                          ")"
                        }
                      >
                        <div className="showHide-content">
                          {!paxDetails && (
                            <Form.Row>
                              <Form.Group
                                className="selectplugin"
                                as={Col}
                                xs={3}
                                controlId="tl"
                              >
                                <Select
                                  options={title}
                                  searchable={true}
                                  disabled={state.isOnhold}
                                  values={
                                    room_data.pax[0] && room_data.pax[0].tl
                                      ? [
                                        {
                                          label: room_data.pax[0].tl,
                                          value: room_data.pax[0].tl,
                                        },
                                      ]
                                      : []
                                  }
                                  onChange={(value) =>
                                    handleOnInpChange(value!==undefined && value.length!==0 &&
                                      value[0].value,
                                      "tl",
                                      idx,
                                      "ADT",
                                      0
                                    )
                                  }
                                />
                                <Form.Label>
                                  Title <sup>*</sup>
                                </Form.Label>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                controlId="fn"
                                className="selectplugin"
                                xs={3}
                              >
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="fn"
                                  onChange={handleChange("fn", idx, "ADT", 0)}
                                  onInputChange={(txt, e) =>
                                    handleOnInpChange(txt, "fn", idx, "ADT", 0)
                                  }
                                  options={state.adtList}
                                  selected={[room_data.pax[0]]}
                                  disabled={state.isOnhold}
                                  placeholder="First Name"
                                />
                                <Form.Label>
                                  First Name<sup>*</sup>
                                </Form.Label>
                              </Form.Group>

                              <Form.Group as={Col} xs={3} controlId="ln">
                                <Form.Control
                                  disabled={state.isOnhold}
                                  type="text"
                                  placeholder="Last Name"
                                  autoComplete="off"
                                  value={room_data.pax[0].ln}
                                  onChange={handleChange("ln", idx, "ADT", 0)}
                                />
                                <Form.Label>
                                  Last Name <sup>*</sup>
                                </Form.Label>
                              </Form.Group>

                              {idx === 0 && (
                                <Form.Group
                                  as={Col}
                                  xs={3}
                                  controlId="em"
                                  className="mb-2"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    value={room_data.pax[0].em}
                                    onChange={handleChange("em", idx, "ADT", 0)}
                                    disabled={state.isOnhold}
                                  />
                                  <Form.Label>
                                    Email<sup>*</sup>
                                  </Form.Label>
                                </Form.Group>
                              )}
                              {idx === 0 && (
                                <Form.Group
                                  as={Col}
                                  xs={3}
                                  controlId="phc"
                                  className="mb-2 customSelect"
                                >
                                  <Form.Control
                                    disabled={state.isOnhold}
                                    as="select"
                                    value={room_data.pax[0].phc}
                                    onChange={handleChange(
                                      "phc",
                                      idx,
                                      "ADT",
                                      0
                                    )}
                                  >
                                    {state.stdCodeMenu}
                                  </Form.Control>
                                  <Form.Label>
                                    Phone code <sup>*</sup>
                                  </Form.Label>
                                </Form.Group>
                              )}
                              {idx === 0 && (
                                <Form.Group
                                  as={Col}
                                  xs={3}
                                  controlId="phn"
                                  className="mb-2"
                                >
                                  <Form.Control
                                    disabled={state.isOnhold}
                                    type="text"
                                    placeholder="Phone Number"
                                    value={room_data.pax[0].phn}
                                    onChange={handleChange(
                                      "phn",
                                      idx,
                                      "ADT",
                                      0
                                    )}
                                  />
                                  <Form.Label>
                                    Phone Number<sup>*</sup>
                                  </Form.Label>
                                </Form.Group>
                              )}
                            </Form.Row>
                          )}
                          {/* //=====================================
                  //  add all pax details
                  //===================================== */}
                          {paxDetails && (
                            <div className="allpax">
                              {room_data.pax.map((pax, adtCount) => (
                                <div className="eachList">
                                  <React.Fragment key={adtCount}>
                                    <ShowHide
                                      visible={
                                        pax.sno === 1 && pax.ty == "ADT"
                                          ? "true"
                                          : ""
                                      }
                                      title={
                                        (pax.ty == "ADT" ? "Adult" : "Child") +
                                        pax.sno
                                      }
                                    >
                                      <div className="showHide-content">
                                        <Form.Row>
                                          <Form.Group
                                            className="selectplugin"
                                            as={Col}
                                            xs={3}
                                            controlId="tl"
                                          >
                                            <Select
                                              disabled={state.isOnhold}
                                              options={
                                                pax.ty == "ADT"
                                                  ? title
                                                  : titleJr
                                              }
                                              searchable={false}
                                              values={
                                                pax && pax.tl
                                                  ? [
                                                    {
                                                      label: pax.tl,
                                                      value: pax.tl,
                                                    },
                                                  ]
                                                  : []
                                              }
                                              onChange={(value) =>
                                                handleOnInpChange(
                                                  value[0].value,
                                                  "tl",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )
                                              }
                                            />
                                            <Form.Label>
                                              Title<sup>*</sup>
                                            </Form.Label>
                                          </Form.Group>

                                          <Form.Group
                                            className="selectplugin"
                                            as={Col}
                                            xs={3}
                                            controlId="fn"
                                          >
                                            {pax.ty == "ADT" && (
                                              <Typeahead
                                                selected={[pax]}
                                                disabled={state.isOnhold}
                                                id="basic-typeahead-single"
                                                labelKey="fn"
                                                onChange={handleChange(
                                                  "fn",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )}
                                                onInputChange={(txt, e) =>
                                                  handleOnInpChange(
                                                    txt,
                                                    "fn",
                                                    idx,
                                                    pax.ty,
                                                    adtCount
                                                  )
                                                }
                                                options={state.adtList}
                                                placeholder="First name"
                                              />
                                            )}
                                            {pax.ty == "CHD" && (
                                              <Typeahead
                                                selected={[pax]}
                                                disabled={state.isOnhold}
                                                id="basic-typeahead-single"
                                                labelKey="fn"
                                                onChange={handleChange(
                                                  "fn",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )}
                                                onInputChange={(txt, e) =>
                                                  handleOnInpChange(
                                                    txt,
                                                    "fn",
                                                    idx,
                                                    pax.ty,
                                                    adtCount
                                                  )
                                                }
                                                options={state.chdList}
                                                placeholder="First Name"
                                              // selected={[pasenger]}
                                              />
                                            )}
                                            <Form.Label>
                                              First Name<sup>*</sup>
                                            </Form.Label>
                                          </Form.Group>

                                          <Form.Group
                                            as={Col}
                                            xs={3}
                                            controlId="ln"
                                          >
                                            <Form.Control
                                              type="text"
                                              disabled={state.isOnhold}
                                              placeholder="Last Name"
                                              autoComplete="off"
                                              value={pax.ln}
                                              onChange={handleChange(
                                                "ln",
                                                idx,
                                                pax.ty,
                                                adtCount
                                              )}
                                            />
                                            <Form.Label>
                                              Last Name <sup>*</sup>
                                            </Form.Label>
                                          </Form.Group>

                                          {idx === 0 && adtCount === 0 && (
                                            <Form.Group
                                              as={Col}
                                              xs={3}
                                              controlId="em"
                                            >
                                              <Form.Control
                                                disabled={state.isOnhold}
                                                type="text"
                                                placeholder="Email"
                                                value={pax.em}
                                                onChange={handleChange(
                                                  "em",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )}
                                              />
                                              <Form.Label>
                                                Email<sup>*</sup>
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {idx === 0 && adtCount === 0 && (
                                            <Form.Group
                                              as={Col}
                                              xs={3}
                                              controlId="phc"
                                            >
                                              <Form.Control
                                                disabled={state.isOnhold}
                                                as="select"
                                                value={pax.phc}
                                                onChange={handleChange(
                                                  "phc",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )}
                                              >
                                                {state.stdCodeMenu}
                                              </Form.Control>
                                              <Form.Label>
                                                Phone code <sup>*</sup>
                                              </Form.Label>
                                            </Form.Group>
                                          )}
                                          {idx === 0 && adtCount === 0 && (
                                            <Form.Group
                                              as={Col}
                                              xs={3}
                                              controlId="phn"
                                            >
                                              <Form.Control
                                                disabled={state.isOnhold}
                                                type="text"
                                                placeholder="Phone Number"
                                                value={pax.phn}
                                                onChange={handleChange(
                                                  "phn",
                                                  idx,
                                                  pax.ty,
                                                  adtCount
                                                )}
                                              />
                                              <Form.Label>
                                                Phone Number<sup>*</sup>
                                              </Form.Label>
                                            </Form.Group>
                                          )}

                                          <Form.Group
                                            as={Col}
                                            xs="3"
                                            className="selectplugin datePicker yearlist"
                                            controlId="dob"
                                          >
                                            {pax.ty === "ADT" && (
                                              <DatePicker
                                                selected={DateUtils.convertNewToDate(
                                                  pax.dob
                                                )}
                                                //value={pasenger.dob}
                                                onChange={handleDobChange(
                                                  idx,
                                                  adtCount
                                                )}
                                                placeholderText="DD-MM-YYYY"
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                disabled={state.isOnhold}
                                                maxDate={paxDate(
                                                  new Date(),
                                                  12
                                                )}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                              />
                                            )}
                                            {pax.ty === "CHD" && (
                                              <DatePicker
                                                selected={DateUtils.convertNewToDate(
                                                  pax.dob
                                                )}
                                                // value={pasenger.dob}
                                                onChange={handleDobChange(
                                                  idx,
                                                  adtCount
                                                )}
                                                placeholderText="DD-MM-YYYY"
                                                disabled={state.isOnhold}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                maxDate={paxDate(new Date(), 2)}
                                                minDate={paxDate(
                                                  new Date(),
                                                  12
                                                )}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                              />
                                            )}
                                            <Form.Label>
                                              Date of Birth
                                            </Form.Label>
                                            <span className="calendar ezyIcon icon-newcalendar"></span>

                                          </Form.Group>
                                        </Form.Row>

                                        <div>
                                          <Tabs
                                            defaultActiveKey="passportInfo"
                                            id="uncontrolled-tab-example"
                                          >
                                            <Tab
                                              eventKey="passportInfo"
                                              title="Passport Info"
                                            >
                                              <Form.Row>
                                                <Form.Group
                                                  as={Col}
                                                  xs={3}
                                                  controlId="num"
                                                  className="mb-0"
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    disabled={state.isOnhold}
                                                    placeholder="Passport Name"
                                                    value={pax.doc.num}
                                                    onChange={handleChange(
                                                      "num",
                                                      idx,
                                                      pax.ty,
                                                      adtCount
                                                    )}
                                                  />
                                                  <Form.Label>
                                                    Passport Number
                                                  </Form.Label>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="na"
                                                  className="mb-0"
                                                >
                                                  <Form.Control
                                                    as="select"
                                                    disabled={state.isOnhold}
                                                    value={pax.doc.na}
                                                    onChange={handleChange(
                                                      "na",
                                                      idx,
                                                      pax.ty,
                                                      adtCount
                                                    )}
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    {state.countriesMenu}
                                                  </Form.Control>
                                                  <Form.Label>
                                                    Nationality
                                                  </Form.Label>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  controlId="isc"
                                                  className="mb-0"
                                                >
                                                  <Form.Control
                                                    as="select"
                                                    value={pax.doc.isc}
                                                    disabled={state.isOnhold}
                                                    onChange={handleChange(
                                                      "isc",
                                                      idx,
                                                      pax.ty,
                                                      adtCount
                                                    )}
                                                  >
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    {state.countriesMenu}
                                                  </Form.Control>
                                                  <Form.Label>
                                                    Issued Country
                                                  </Form.Label>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  className="selectplugin datePicker mb-0"
                                                  controlId="isd"
                                                >
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={DateUtils.convertNewToDate(
                                                      pax.doc.isd
                                                    )}
                                                    onChange={handleIssuDateChange(
                                                      idx,
                                                      adtCount
                                                    )}
                                                    placeholderText="DD-MM-YYYY"
                                                    dateFormat="dd-MM-yyyy"
                                                    maxDate={new Date()}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    disabled={
                                                      state.isOnhold
                                                    }
                                                  />
                                                  <Form.Label>
                                                    Issued Date
                                                  </Form.Label>
                                                  <span className="calendar ezyIcon icon-newcalendar"></span>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  className="selectplugin datePicker mb-0"
                                                  controlId="exd"
                                                >
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={DateUtils.convertNewToDate(
                                                      pax.doc.exd
                                                    )}
                                                    onChange={handleExpDateChange(
                                                      idx,
                                                      adtCount
                                                    )}
                                                    placeholderText="DD-MM-YYYY"
                                                    dateFormat="dd-MM-yyyy"
                                                    minDate={new Date()}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    disabled={state.isOnhold}
                                                  />
                                                  <Form.Label>
                                                    Expiry Date
                                                  </Form.Label>
                                                  <span className="calendar ezyIcon icon-newcalendar"></span>
                                                </Form.Group>
                                              </Form.Row>
                                            </Tab>
                                            {/* <Tab
                                              eventKey="idProof"
                                              title="Id Proof"
                                            >
                                              <p>Id Proof Comes</p>
                                            </Tab> */}
                                          </Tabs>
                                        </div>
                                      </div>
                                    </ShowHide>
                                  </React.Fragment>
                                </div>
                              ))}
                            </div>
                          )}

                          <div className="otherInfo specialRequest">
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <Accordion.Toggle as={Button} variant="link" className="ezyIcon icon-plus" eventKey="0">
                                     Special Request
                              </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                    <div className="extradetails">
                                      <Form.Row>
                                        <Form.Group as={Col} controlId="bdes">
                                          <Form.Control
                                            disabled={state.isOnhold}
                                            as="textarea"
                                            rows="2"
                                            onChange={handleSpecialRquest(idx)}
                                            placeholder="Please enter any special request that you may have. (Eg: Late Check-In, VIP Pax)"
                                          />
                                          <Form.Label>
                                            Please enter any special request that you
                                            may have. (Eg: Late Check-In, VIP Pax)
                                  </Form.Label>
                                        </Form.Group>
                                      </Form.Row>
                                      <div className="splQInfo">
                                        <span className="ezyIcon icon-info">
                                        </span>
                                        <p>
                                          Special requests are not guaranteed and are at
                                          the hotel’s discretion. Additional charges may
                                          apply depending on the hotel’s policies.
                                </p>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>


                            <div className="policyInfo">
                              <span className="extLink remark">
                                <ImportantInfo
                                  checkin={request.htldtls.remarks !== undefined && request.htldtls.remarks}
                                />
                              </span>
                              {!state.isOnhold && request.hs===0 &&
                                room_data.canpolicy.length !== 0 && (
                                  <CancellationPolicy
                                    canPolicy={room_data !== undefined && room_data.canpolicy !== undefined ? room_data.canpolicy : ""}
                                    isRefund={true}
                                  />
                                )}
                              {request.hs===0 && !state.isOnhold && room_data !== undefined && room_data.canpolicy.length === 0 && (
                                <>
                                  <CancellationPolicy
                                    canPolicy={undefined}
                                    isRefund={false}
                                  />
                                  <p className="m-0 ml-2">
                                    <span className="refundable nonRefund">
                                      {"Non Refundable"}
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>
                          </div>

                        </div>
                      </ShowHide>
                    </React.Fragment>
                  </div>
                  // </React.Fragment>
                ))}
              </div>
            </ShowHide>
          </div>
        )}
      </>
    )
  );
}
export default GustDetails;
