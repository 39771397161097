
import { Button, Col, Form,Alert  } from 'react-bootstrap';
import React, { useState , useReducer , useContext,useEffect} from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import DateUtils from "../../commonUtils/DateUtils";
import { Context } from "../../../../App";
import { saveRemainder,updateRemainder,getUserList} from '../../dashBoard/operations';
/**
 * Initial State Declaration
 */
 const initialState = {
  name:"",desc:"",rem_id:0,asigne_name:"",notify_type:"",hrsBefore:"",asigne_id:0,asigne_email:"",userNamesList:[],pre_type:"High",activeMe:false,
  notiMessage: '', notiMessageShow: false, notiVarient: '',remainders_List:[],enableEdit:false,enableView:false,assigne_val:[],notify_aray:[]
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function AddRemainder(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [startDate, setStartDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [remainTime, setRemainTime] = useState("");
  const [addriminder, setAddriminder,] = useState(false);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if(context.logindata!==undefined){
      getUserList(context.logindata.utype,context.logindata.cid).then(response => {
        if (response.suc) {
          dispatch({ type: 'userNamesList', payload: response.res })
        }
    })
    }
    }, [context.logindata]);

    //Edit Remainder
    useEffect(() => {
        if(props.remainObj!=="" && props.remType!==undefined){
            getUserList(context.logindata.utype,context.logindata.cid).then(response => {
                if (response.suc) {
                  dispatch({ type: 'userNamesList', payload: response.res })
                }
            })
            if(props.remType==="Edit"){
                dispatch({ type: 'enableEdit', payload: true })
                dispatch({ type: 'enableView', payload: false })
            }else if(props.remType==="View"){
                dispatch({ type: 'enableEdit', payload: false })
                dispatch({ type: 'enableView', payload: true })
            }
            dispatch({ type: 'rem_id', payload: props.remainObj.remai_id })
            dispatch({ type: 'name', payload: props.remainObj.remai_name })
            dispatch({ type: 'desc', payload: props.remainObj.reminder_details })
            dispatch({ type: 'notify_aray', payload:[{label:props.remainObj.notify_type, value:props.remainObj.notify_type}]  })
            dispatch({ type: 'notify_type', payload: props.remainObj.notify_type})
            dispatch({ type: 'hrsBefore', payload: props.remainObj.notify_time })
            dispatch({ type: 'pre_type', payload: props.remainObj.priority })
            if(props.remainObj.date_time!==undefined){
                setStartDate(DateUtils.prettyDate(props.remainObj.date_time, 'DD-MM-YYYY'))
                setFromDate(new Date(props.remainObj.date_time))
            }
            if(props.remainObj.date_time!==undefined){
                setRemainTime(DateUtils.prettyDate(props.remainObj.date_time, 'hh:mm A'))
                setStartTime(new Date(props.remainObj.date_time))
            }
          dispatch({ type: 'assigne_val', payload: [{label:props.remainObj.assigne_name, value:props.remainObj.assigne_id}]})
        dispatch({ type: 'asigne_id', payload: props.remainObj.assigne_id })
        dispatch({ type: 'asigne_name', payload: props.remainObj.assigne_name })
        }
        }, [props.remainObj]);


  const notifyList = [{ label: "Minutes before", value: 'Minutes' },
  { label: "Hours before", value: 'Hours' }]

  const closeRiminder = () =>{
    props.closeRemainder();
  }
  //Validate Remainder
  const validateRemainder=()=>{
      if(state.name===""){
        dispatch({ type: 'notiMessageShow', pyload: true })
        dispatch({ type: 'notiMessage', payload: 'Reminder Name is required' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }else if(startDate===undefined || startDate===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Reminder Date is required' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      else if(startTime=== undefined || startTime===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Reminder Time is required' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()

        return false;
      }
      else if(state.asigne_name == "" || state.asigne_name == undefined){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Assignee name is required' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
      return true;
  }

  //Add/Update Remainder
  const handleAddRemainder=(type)=>{
    if(validateRemainder()){
     const payload={
      "rem_name":state.name,
      "rem_desc":state.desc,
      "date":DateUtils.prettyDate(fromDate, 'DD-MM-YYYY'),
     "time":DateUtils.prettyDate(startTime, "hh:mm A"),
      "assigne_id":state.asigne_id,
      "assigne_name" :  state.asigne_name,
      "assigne_email" : state.asigne_email,
      "priority":state.pre_type,
      "notify_time": state.hrsBefore !== "" && state.notify_type !== "" ? state.hrsBefore : "",
      "notify_type" : state.hrsBefore !== "" && state.notify_type !== "" ? state.notify_type : ""
     }
     if(type==="Save"){
        payload.crby=context.logindata.uid;
        saveRemainder(payload).then((resp) => {
            if (resp.suc){
              setAddriminder(false)
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Reminder Saved Successfully...' })
              dispatch({ type: 'notiVarient', payload: 'success' })
              setTimeout(() => {
                return window.location = window.location.pathname;
              }, 2000)
            } else {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Reminder Not  saved...' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
            }
          })
        }else{
            payload.upby=context.logindata.uid;
            payload.rem_id=state.rem_id;
            updateRemainder(payload).then((resp) => {
                if (resp.suc){
                  setAddriminder(false)
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Reminder Updated Successfully...' })
                  dispatch({ type: 'notiVarient', payload: 'success' })
                  setTimeout(() => {
                    return window.location = window.location.pathname;
                  }, 2000)
                } else {
                  dispatch({ type: 'notiMessageShow', payload: true })
                  dispatch({ type: 'notiMessage', payload: 'Reminder Not  updated...' })
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                }
              })
        }

      }
     }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handleChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  };

  const updatePreority=(type)=>{
    dispatch({ type: "pre_type", payload: type });
    dispatch({ type: 'activeMe', payload: true })
  }

   //updating Assigne
   const setNotifyType = (obj) => {
    if (obj!== undefined) {
      dispatch({ type: "notify_type", payload: obj.value })
      dispatch({ type: 'notify_aray', payload: [{label:obj.label, value:obj.value}]})
    }
  }

  //updating Assigne
  const setAsigneType = (obj) => {
    if (obj!== undefined) {
      dispatch({ type: 'asigne_id', payload: obj.value })
      dispatch({ type: 'asigne_name', payload: obj.label })
      dispatch({ type: 'asigne_email', payload: obj.email_id })
      dispatch({ type: 'assigne_val', payload: [{label:obj.label, value:obj.value}]})
    }
  }
  const setFrom1Date = (date) => {
    setFromDate(date)
    setStartDate(DateUtils.prettyDate(date, 'DD-MM-YYYY'))
    }
  const setStartTime1 = (time) => {
    setStartTime(time)
    setRemainTime(DateUtils.prettyDate(time, 'hh:mm A'))
    }

  return (
    <>
    <div className="addRmPopUp">
        <div className="addRmPopUpInner">
        {state.enableEdit ? <h3 className="d-flex justify-content-between"><span>Update Reminder</span>
        <span className="colose ezyIcon icon-close" onClick={closeRiminder}></span></h3> :
        <h3 className="d-flex justify-content-between"><span>Add Reminder</span>
        <span className="colose ezyIcon icon-close" onClick={closeRiminder}></span></h3>}
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        <Form.Row>
        <Form.Group as={Col} xs={12} controlId="name">
          <Form.Control type="text" disabled={state.enableView} required placeholder="Enter Name" value={state.name}
             onChange={handleChange} />
            <Form.Label>Name <sup>*</sup></Form.Label>
            <Form.Control.Feedback type="invalid">
                Please provide Name.
            </Form.Control.Feedback>
        </Form.Group>
          <Form.Group as={Col} xs={12} controlId="desc">
          <Form.Control type="text" disabled={state.enableView} placeholder="Details" placeholder="Enter Details" value={state.desc} onChange={handleChange} className="d-flex align-items-center"/>
            <Form.Label>Details</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker" controlId="startDate">
                <DatePicker
                  selected={fromDate}
                  showPreviousMonths
                  minDate={new Date()}
                  value={startDate}
                  onChange={(date) => setFrom1Date(date)}
                  monthsShown={1}
                  disabled={state.enableView}
                />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>Date<sup>*</sup></Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="selectplugin datePicker" controlId="startTime">
                <DatePicker
                selected={startTime}
                value={remainTime}
                onChange={(date) => setStartTime1(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                disabled={state.enableView}
              />
                   <span className="calendar ezyIcon icon-time"></span>
                  <Form.Label>Time<sup>*</sup></Form.Label>
                </Form.Group>
            </Form.Row>
            <Form.Row>
              <h3></h3>
            <Form.Group className="selectplugin" as={Col} xs={12} controlId="asigne_name">
              <Select
                  isDisabled={state.enableView}
                  options={state.userNamesList}
                  searchable={false}
                  value={state.assigne_val}
                  onChange={(value) => (setAsigneType(value))}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#F0F0F0',
                      primary: '#00367A',
                    },
                  })}
              />
              <Form.Label>Assignee<sup>*</sup></Form.Label>
          </Form.Group>
            </Form.Row>
            <h3>Set Priority</h3>
            <div className="priorityType"><Button
                  size="xs"
                  variant="outline-dark"
                  className={state.pre_type==='High' ? "typeOf active":"typeOf"}
                  value={state.pre_type}
                  isDisabled={state.enableView}
                  onClick={()=>updatePreority("High")}
                  type="submit"
                >  High</Button>
                <Button
                  size="xs"
                  isDisabled={state.enableView}
                  variant="outline-dark"
                  className={state.pre_type==='Medium' ? "typeOf active":"typeOf"}
                  value={state.pre_type}
                  onClick={()=>updatePreority("Medium")}
                  type="submit"
                >  Medium</Button>
                <Button
                  size="xs"
                  isDisabled={state.enableView}
                  variant="outline-dark"
                  value={state.pre_type}
                  className={state.pre_type==='Low' ? "typeOf active":"typeOf"}
                  onClick={()=>updatePreority("Low")}
                  type="submit"
                >  Low</Button>

                </div>
                <h3 className="mt-3">Notify before?</h3>
                <Form.Row>
                <Form.Group as={Col} xs={4} controlId="hrsBefore">
                <Form.Control type="text" placeholder="Notify before?" disabled={state.enableView}  placeholder="Enter Details" value={state.hrsBefore} onChange={handleChange}    className="d-flex align-items-center" />
          </Form.Group>
          <Form.Group className="selectplugin"  as={Col} xs={8} controlId="firstName">
              <Select
                  isDisabled={state.enableView}
                  options={notifyList}
                  searchable={false}
                  value={state.notify_aray}
                  onChange={(value) => (setNotifyType(value))}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#F0F0F0',
                      primary: '#00367A',
                    },
                  })}
              />
              <Form.Label>Minutes before</Form.Label>
          </Form.Group>
        </Form.Row>
        <div className="d-flex justify-content-end border-top pt-3">
        {!state.enableEdit && !state.enableView &&

       <Button
        size="xs"
        variant="outline-primary"
        onClick={()=>handleAddRemainder("Save")}
        //loading={state.loadOfice}
        type="submit"
        > Add Reminder</Button>
        }
        {state.enableEdit &&
        <Button
        size="xs"
        variant="outline-primary"
        onClick={()=>handleAddRemainder("Update")}
        //loading={state.loadOfice}
        type="submit"
        > Update Reminder</Button>
        }
    </div>
        </div>
      </div>
    </>
  )
}


export default AddRemainder;
