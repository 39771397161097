
import VisaService from '../../../services/administration/VisaService';
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Shaik Fayaz
 * @param {*}
 * @function Supplier
 * @date : 07/09/2023
 */

export const getVisaSearchData = async (req) => {
    let resp = [];
    await VisaService.retriveVisaDetails(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const saveVisaConfig = async (req) => {
    let resp = [];
    await VisaService.saveConfiguration(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const updateVisaConfig = async (req) => {
    let resp = [];
    await VisaService.updateConfiguration(req).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const getVisConfiguration = async (id) => {
    let resp = [];
    await VisaService.getVisaConfiguration(id).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const deleteDocument = async (obj) => {
    let resp = [];
    await VisaService.deleteDocs(obj).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}

export const deletePax = async (obj) => {
    let resp = [];
    await VisaService.deletePax(obj).then(response => {
        if (response) {
            resp = response;
        }
    });
    return Promise.resolve(resp);
}