
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import Breadcrumb from '../../../../components/common/breadcrumb/Breadcrumb';
import Button from '../../../../components/common/buttons/Button';
import Footer from '../../../../components/common/Footer';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import MainMenu from '../../../../components/common/MainMenu';
import AirServiceConfigMenu from '../../../common/subMenu/HotelServiceConfigMenu'
import NoRecord from '../../../common/popUps/NoRecord';
import CreateDisc from '../commissionDisc/CreateDisc';
import DatePicker from 'react-datepicker';
import DateUtils from '../../../commonUtils/DateUtils';
import {getAllDiscounts,searchDisc} from './operations';


/**
 * @description:This function will create Categeroy and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Categeroy
 * @date : 13-06-2021
 */

/**
 * Initial State Declaration
 */
const initialState = {
    company_id:0, enableCreate:false,enableEdit:false,discObj:"",
    res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, company_id:0, enableCreate:false,enableEdit:false,discObj:"",
                res: [], enbaleUpdate: false, noRecords: false, notiMessage: '', notiMessageShow: false, notiVarient: '', enableView: false, isLoadingMore: false, nameError: false,companyError:false,noError:false
            };
        case 'editBranch':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};


/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Lakshmi
 * @param {*}
 * @function Category
 */
function CommssionDiscount() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [validated, setValidated] = useState(false);
    const [value, setValue] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        dispatch({ type: 'isLoadingMore', payload: true });
        dispatch({ type: 'enbaleUpdate', payload: false })
        const payload = {
            "userid": context.logindata.uid,
            "is_admin": context.logindata.is_admin,
            "region_type" : context.logindata.region_type
          }
        getAllDiscounts(payload).then(response => {
            if (response && response.length !== 0) {
                convertDisc(response);
            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }, [context.logindata]);

    //Converting all Hold bookings
    const convertDisc = (response) => {
        let data = [];
            if(response!==undefined){
                for (let val of response){
                    let supplier="";
                    if(val.sup!=="" && val.sup!==null){
                        if(val.sup.includes(",")){
                            supplier="Expedia-Otilla";
                        }else if(val.sup==="0"){
                            supplier="Expedia";
                        }else if(val.sup==="1"){
                            supplier="Otilla";
                        }
                        if (context.logindata.is_admin==1 || context.logindata.region_type==val.region_type){

                        data.push({region_type:val.region_type,sales_channel:val.sales_channel, branch: val.branch_name, discname: val.disc_name,sup: supplier,from: val.from_dt,to: val.to_dt,st: val.st===1?"Active":"InActive", options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={editDisc(val)}></a></span>  </> })
                        }
                    }
                    }
                dispatch({ type: 'res', payload: data })
            }
        dispatch({ type: 'res', payload: data })
        dispatch({ type: 'isLoadingMore', payload: false });
    }
    const searchDiscount=()=>{
        if(fromDate!==null && toDate!==null){
        const req={
            "from_dt": DateUtils.convertStringToDate(fromDate),
            "to_dt": DateUtils.convertStringToDate(toDate)
        }
        searchDisc(req).then(response => {
            if (response.suc){
                dispatch({ type: 'res', payload: [] })
                convertDisc(response.res);
            }else{
                dispatch({ type: 'res', payload: [] })
            }
            dispatch({ type: 'isLoadingMore', payload: false });
        })
    }else{
        if(fromDate===null){
            dispatch({ type: 'notiMessage', payload: "Select From date" })
            dispatch({ type: 'notiVarient', payload: "danger" })
            dispatch({ type: 'notiMessageShow', payload: true })
            hidemessage()
        }else if(toDate===null){
            dispatch({ type: 'notiMessage', payload: "Select To date" })
            dispatch({ type: 'notiVarient', payload: "danger" })
            dispatch({ type: 'notiMessageShow', payload: true })
            hidemessage()
        }
    }
    }

    const resetDisc=()=>{
        setFromDate(null);
        setToDate(null);
    }

    // OnChange event
    const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
    }

    function hidemessage() {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const createCommission=()=>{
        dispatch({ type: "enableCreate", payload: true })
        dispatch({ type: "enableEdit", payload: false })
    }
    //Edit Categeory
    const editDisc = (obj) => () => {
        dispatch({ type: "enableEdit", payload: true })
        dispatch({ type: "enableCreate", payload: false })
        dispatch({ type: "discObj", payload: obj })
    }
    const columns = [
        {
            dataField: 'sales_channel',
            text: 'Sales Channel',
            sort: true,
            filter: textFilter()
        },
    {
        dataField: 'region_type',
        text: 'Region Type',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'branch',
        text: 'Branch name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'discname',
        text: 'Discount Name',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'sup',
        text: 'Supplier',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'from',
        text: 'From Date',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'to',
        text: 'To Date',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'st',
        text: 'Status',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
    }];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];



    return (
        <>
            <MainMenu active='Hoel Services' />
            <Breadcrumb activePage="Commission Discount" />
            <div className="container">
            <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
             onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
             dismissible> {state.notiMessage}</Alert>
            <div className="cardMainlayout pt-0">
            <AirServiceConfigMenu active="Commission" />
            <br/>
            {!state.enableCreate && !state.enableEdit &&
            <div>
            <Form.Row>
                            <Form.Group as={Col} xs={2} className="selectplugin datePicker" controlId="from">
                            <DatePicker
                            selected={fromDate}
                            onChange={date => setFromDate(date)}
                            fromDate={fromDate}
                            toDate={toDate}
                            minDate={new Date()}
                            disabled={state.selCompany}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <Form.Label>From Date<sup>*</sup></Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} xs={2} className="selectplugin datePicker" controlId="to">
                            <DatePicker
                            selected={toDate}
                            onChange={date => setToDate(date) + 1}
                            fromDate={fromDate}
                            toDate={toDate}
                            minDate={fromDate}
                            className="datefield"
                            disabled={state.selCompany}
                            />
                            <span className="calendar ezyIcon icon-newcalendar"></span>
                            <Form.Label>To Date<sup>*</sup></Form.Label>
                            </Form.Group>
                </Form.Row>
               </div>

                }
                {!state.enableCreate && !state.enableEdit &&
                     <div className="text-right pb-0 buttonGrop  creat_absolute">
                     <Button
                       size="xs"
                       variant="outline-secondary"
                       type="submit"
                       onClick={resetDisc}
                     > Reset</Button>
                     <Button
                       size="xs"
                       variant="outline-primary"
                       type="submit"
                       onClick={searchDiscount}
                     > Search</Button>
                   </div>
                }
            {!state.enableCreate && !state.enableEdit &&
            <div className="d-flex justify-content-between buttonGrop pb-0 mt-3">
                    <Button
                      size="xs"
                      variant="primary"
                      className="addPlus ezyIcon icon-plus"
                      onClick={createCommission}
                      type="submit">
                    Create Discount
                  </Button>
                </div>
              }
             </div>
             {!state.enableCreate && !state.enableEdit &&
                <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader /> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}
                </div>
              }
              {state.enableCreate &&
               <CreateDisc obj={state.discObj} enableEidt={false}/>
               }
               {state.enableEdit &&
               <CreateDisc obj={state.discObj} enableEidt={true}/>
               }
            </div>
            <Footer />
        </>
    )
}

export default CommssionDiscount