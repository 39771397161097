
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { apiGetHelper, apiPostHelper } from '../../../../components/commonUtils/helper';
const API_URL = process.env.REACT_APP_B2B_URL
const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH

/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Supplier
 * @date : 13-07-2021
 */


//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

//Save Remainder
export const saveNotiAccess = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/notification/savecompanyconfig`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Save Remainder
export const updateNotiAccess = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/notification/updatecompanyconfig`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Save Remainder
export const getNotifications = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiGetHelper(`${API_URL}/att/notification/getcompanyconfig`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Save Remainder
export const deleteNotifications = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/notification/deletecompanyconfig`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Save Remainder
export const activeNotifications = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/att/notification/isactive`, obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}