/**
 * @description: Flight Confirmation page Root Transaction details component
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
import React from 'react';
import { Col, Row } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';
import { isNotNull } from '../../../commonUtils/validators';

/**
 * @description: This function will show the TransactionSummary
 * @date:09-01-2020
 * @author:Rambabu
 *
 */
function TransactionSummary(props) {


    const openSummary = (data, idx)=>{
        if(props.bdata !== undefined && props.bdata[0] !== undefined){
            window.open("/ezytrip/dashboard/bookingsummary/"+props.bdata[0].bookId);
        }
    }

    return (

        isNotNull(props.bdata) && (

            <>

                {props.bdata.map((bdet, index) => (
                    <React.Fragment key={index}>
                        <Row className="pb-2">
                            <Col><span>Booking Number :</span> <span onClick = {openSummary}
                            >{bdet.bookId}</span></Col>
                        { props.sup !== "0" ? <Col><span>Request Date : </span> {DateUtils.prettyDate(bdet.bd, "DD MMM, YYYY hh:mm a")}</Col> :
                        <Col><span>Booking Date : </span> <span>{DateUtils.prettyDate(bdet.bd, "ddd, DD MMM, YYYY")}</span></Col>
                        }
                        { props.sup !== "0" ? <Col><span>Request By : </span> {props.leadpsngr !== undefined && props.leadpsngr !== null && props.leadpsngr}</Col> :
                        <Col xs={4}><span>Booked By : </span> <span>{bdet.bby}</span></Col>}
                            <Col><span>Booking Status : </span>
                            <span>
                            {bdet.st === "Confirmed" &&
                            <span className="status paxstatus confirm">Confirmed</span>
                            }
                            {bdet.st === "Success" &&
                            <span className="status paxstatus confirm">Confirmed</span>
                            }
                            {bdet.st === "On hold" &&
                            <span className="status paxstatus">On Hold</span>
                            }
                            {bdet.st === "Ticketing failed" &&
                            <span className="status paxstatus text-danger">Ticketing Failed</span>
                            }
                            {bdet.st === "Partially Ticketed" &&
                            <span className="status paxstatus confirm">Partially Ticketed</span>
                            }
                            {bdet.st === "Booking failed" &&
                            <span className="status paxstatus text-danger">Booking Failed</span>
                            }
                            {bdet.st === "Failed" &&
                            <span className="status paxstatus text-danger">Failed</span>
                            }
                            </span>
                            </Col>

                        </Row>
                        <Row className="pb-2">
                        {bdet.st === "On hold" &&
                                <Col><span>Ticketing Time Limit : </span> <span>{DateUtils.prettyDate(props.ttl, "ddd, MMM DD, YYYY HH:mm:ss")}</span></Col>
                            }
                        </Row>

                        { props.sup !== "0" &&<Row className="pb-2">
                        <Col xs={3}><span>Customer Name :</span> {props.customer}</Col>
                            <Col xs={3}><span>Lead Passenger : </span> {props.leadpsngr !== undefined && props.leadpsngr !== null && props.leadpsngr}</Col>
                       </Row>
}
                    </React.Fragment>
                ))}
            </>
        )
    )
}


export default TransactionSummary;
