
import React, { useState } from 'react';
import Select from 'react-select';

function ReactDropdownSelect() {
    const [state, setState] = useState({
        selectedOption: null,
    });
    const SelectOptions = [
        { value: 'Argentina', label: 'Argentina' },
        { value: 'Austria', label: 'Austria' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Sweden', label: 'Sweden' },
        { value: 'Venezuela', label: 'Venezuela' },
      ];

      const handleChange = selectedOption => {
        setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
      };

    return (
        <><Select
        //value={selectedOption}
        isSearchable={false}
        onChange={handleChange}
        options={SelectOptions}
    /><br/>
    <Select
        isMulti={true}
        isSearchable={true}
        onChange={handleChange}
        options={SelectOptions}
    />

        </>
    )
}

export default ReactDropdownSelect
