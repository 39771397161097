
import React, { useEffect, useState } from 'react';
import ResultBlock from '../common/ResultBlock';
import SortowSplit from '../filtersFBF/SortOnward';
function OnwardPackages(props) {
    const [onwardFlights, setOnwardFlights] = useState(props.searchRes);
    const [srcorigns, setSrcorigns] = useState([]);
    const [selPrice, setSelPrice] = useState();
    const [update, setUpdate] = useState(false);


    const tripType = props.trip;
    const secDet = props.sectors;
    const currency = props.cur;
    const mappings = props.mapping;

    useEffect(() => {
        setOnwardFlights(props.searchRes)
    }, [props.searchRes]);


    const handleChangeCallBack = (res, type) => {
        setOnwardFlights(res);
        props.owHandelChange(res)
    }
    const handleOnwardSelectedCallBack = (res, selected) => {
        let responseFiltered = Object.assign({}, res);
        responseFiltered.srcorigns = undefined;
        responseFiltered.srcorigns = [JSON.parse(selected)];
        props.owHandelChange(responseFiltered, 'split');
    }

    const selOnwardFlt = (data, price) => {
        setSrcorigns({ srcorigns: data });
        setSelPrice(price)
    }

    const onwardServiceFee = (fee) => {
        props.onwardService(fee)
    }

    const updateOwPrice=(price)=>{
        for(let val of onwardFlights.origns){
            for(let src of val.srcorigns){
                if(src.tid===price[1]){
                    val.pr=price[0].pr;
                    val.paxpr=price[0].paxpr;
                    break;
                }
            }
        }
        setUpdate(true);
        props.sendOwPrice(price)
    }

    const updateOwAirPrice=(orign)=>{
        let priceOrgn=orign[0];
        for(let val of onwardFlights.origns){
        if(val.srcorigns[0].tid===priceOrgn[0].srcorigns[0].tid){
            val.pr=priceOrgn[0].pr;
            val.paxpr=priceOrgn[0].paxpr;
            val.servicefee=priceOrgn[0].servicefee;
            val.show=true;
            props.sendOwAirPrice(priceOrgn)
            break;
        }  
        }
    }

    return (
        <>
            <div className="resultSection p-0 mb-3">
                <SortowSplit searchRes={onwardFlights} handelChange={handleChangeCallBack} sortClear={props.sortClear} srequest={props.srequest} sectors={secDet} mapping={mappings} />
            </div>
            <div class="resultLegsOnward">
            {onwardFlights && onwardFlights.origns.map((flights, index) => (
                <ResultBlock sect={secDet} sty={'split'} secType={'onward'} mapping={mappings} fltIndex={index} searchRes={flights.srcorigns} trip={tripType} sectors={flights} sect={secDet} currency={currency} price={flights.pr} onwardSelectedCallBack={handleOnwardSelectedCallBack} onwardFlt={selOnwardFlt} srequest={props.srequest} owsplitFlights={props.owsplitFlights} onwardService={onwardServiceFee} sendTfPrice={updateOwPrice} onFlyMkp={props.onSecMkp} sendPriceResp={updateOwAirPrice} regionType={props.regionType} gst={props.gst}/>
            ))}
            </div>
        </>
    )
}


export default OnwardPackages;
