import React, { useCallback, useEffect, useState, useRef } from "react";
import Tabs from "../../../common/tabs/Tabs";
import InsuSearch from "./InsuSearch";

function InsHomeSearch(props) {
  const [source, setSource] = useState([]);
  const [destination, setDestination] = useState([]);
  const [isModify, setIModify] = useState(false);
  const [tablist, setTablist] = useState({
    active: "Inbound",
  });
  useEffect(() => {
    if (props.srequest !== undefined) {
      if (props.srequest.tt === 1) {
        setTablist({ active: "Outbound" });
      } else if (props.srequest.tt === 2) {
        setTablist({ active: "Inbound" });
      }
    }
  }, [props.srequest !== undefined]);
  useEffect(() => {
    setTablist({ active: props.tabs });
  }, [props.tabs]);
  useEffect(() => {
    if (props.bound_type == 1) {
      setTablist({ active: "Inbound" });
    } else {
      setTablist({ active: "Outbound" });
    }
  }, [props.isModify]);

  const retValue = useCallback((type) => {
    if (type === "Round") {
      setTablist({ active: "Inbound" });
    }
  }, []);

  const srcObj = (obj) => {
    setSource(obj);
  };

  const destObj = (obj) => {
    setDestination(obj);
  };

  const srcMultiObj = (obj) => {
    setSource(obj);
  };

  const destMultiObj = (obj) => {
    setDestination(obj);
  };

  const content = {
    Inbound: (
      <InsuSearch
        stopButtonLoad={props.stopButtonLoad}
        sendSrc={srcObj}
        sendDest={destObj}
        srcMultiData={source}
        destMultiData={destination}
        srequest={props.srequest}
        sendReturn={retValue}
        tt={2}
        load={props.load}
        srequest={props.srequest}
        ismodify={props.ismodify}
      />
    ),
    Outbound: (
      <InsuSearch
        stopButtonLoad={props.stopButtonLoad}
        sendSrc={srcObj}
        sendDest={destObj}
        srcMultiData={source}
        destMultiData={destination}
        srequest={props.srequest}
        sendReturn={retValue}
        tt={1}
        load={props.load}
        srequest={props.srequest}
        ismodify={props.ismodify}
      />
    ),
  };

  return (
    <>
      <div className="searchTab InsuranceSearchFrom">
        <div className="tabLink">
          <Tabs
            type="ins"
            active={tablist.active}
            onChange={(active) => {
              setTablist({ active });
              if (props.clickedTabs) {
                props.clickedTabs(active);
              }
            }}
          >
            <input
              type="radio"
              checked={tablist.active === "Inbound"}
              key="Inbound"
            />
            <span key="Inbound">Inbound</span>
            <input
              type="radio"
              checked={tablist.active === "Outbound"}
              key="Outbound"
            />
            <span key="Outbound">Outbound</span>
          </Tabs>
          <div className="tabContent">{content[tablist.active]}</div>
        </div>
      </div>
    </>
  );
}

export default InsHomeSearch;
