
import log from "loglevel";
import React, { useEffect, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { confirmBranchDlg } from '../../common/confirmDialogue';
import { stdBookingCodes } from '../utils';
import { deleteBranchcontact, deleteCompanycontact } from './operations';



/**
 * @description:This function will List of Contacts Infos
 * @author: Lakshmi
 * @param {*}
 * @function Branch
 * @modifiedBy : Anil Kumar Ch
 */
function ContactInfo(props) {
    const [codes, setCodes] = useState({ stdCodeMenu: stdBookingCodes() });
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState(false)
    const [dataInfo, setDataInfo] = useState({ contacts: [], loadEdit: false });
    const [reset, resetData] = useState([])
    const [nameValid, setnameValid] = useState(false)
    const [emailValid, setemailValid] = useState(false)
    const [noValid, setnoValid] = useState(false)
    const [currencyCode, setCurrenyCode] = useState([])
    const [cdelete, setDelete] = useState(false)
    // alert(props.validation)
    useEffect(() => {
        setDataInfo({ contacts: [{ cid: 0, cn: "", cem: "", cph: "", cdesig: "", mcode: "", bcc: true }] })
    }, []);

    useEffect(() => {
        setDataInfo({ contacts: [{ cid: 0, cn: "", cem: "", cph: "", cdesig: "", mcode: "", bcc: true }] })
    }, [props.parentCall]);

    useEffect(() => {
        if (props.contactsList !== null && props.contactsList !== undefined && props.contactsList.length > 0 && !cdelete) {
            let data = []
            var obj = "";
            for (let echContact of props.contactsList) {
                let mainObj = {
                    bcc: echContact.bcc,
                    bid: echContact.bid,
                    cdesig: echContact.cdesig,
                    cem: echContact.cem,
                    cid: echContact.cid,
                    cn: echContact.cn,
                    cph: echContact.cph,
                    mcode: echContact.mcode
                }
                data.push(mainObj)
            }
            setDataInfo({ contacts: data })
        } else {
            setDataInfo({ contacts: [{ cid: 0, cn: "", cem: "", cph: "", cdesig: "", mcode: "", bcc: true }] });
        }
    }, [props.contactsList]);

    //reset the contact info
    useEffect(() => {
        if (props.resetData || props.intilaReset) {
            setemailValid(false)
            setnameValid(false)
            setnoValid(false)
            setState(false)
            setDelete(false)
            setDataInfo({
                contacts: [
                    {
                        "cid": 0,
                        "cn": "",
                        "cem": "",
                        "cph": "",
                        "cdesig": "",
                        "mcode": "",
                        "bcc": true
                    }
                ]
            })
            resetData([])
        }
    }, [props.resetData, props.intilaReset])

    //check for type Validations
    useEffect(() => {
        if (props.checkValidations) {
            // "email": email,
            // "nm": nm,
            // "no": no,
            // "cph": cph
            if (props.checkValidations.email) {
                setemailValid(true)
            }
            if (props.checkValidations.nm) {
                setnameValid(true)
            }
            if (props.checkValidations.no) {
                setnoValid(true)
            }
            if (props.checkValidations.cph) {
                setState(true)
            }
        }
    }, [props.checkValidations])

    const handleChange = idx => evt => {
        setnameValid(false)
        setemailValid(false)
        setnoValid(false)
        try {
            let newContacts = dataInfo.contacts.map((obj, sidx) => {
                if (idx !== sidx) return obj;
                else if (evt.target.id === "bcc") {
                    if (obj.bcc) {
                        return { ...obj, bcc: false };
                    } else {
                        return { ...obj, bcc: true };
                    }
                }
                else {
                    return { ...obj, [evt.target.id]: evt.target.value };
                }
            });
            setDataInfo({ contacts: newContacts })
            props.sendData(newContacts)
        } catch (err) {
            log.error('Exception occured in CompanyComponent function---' + err);
        }
    }

    const handleChangeBcc = idx => evt => {
        try {
            let newContacts = dataInfo.contacts.map((obj, sidx) => {
                if (idx !== sidx) return obj;
                else
                    if (obj.bcc) {
                        return { ...obj, bcc: false };
                    } else {
                        return { ...obj, bcc: true };
                    }
            });
            setDataInfo({ contacts: newContacts })
            props.sendData(newContacts)
        } catch (err) {
            log.error('Exception occured in CompanyComponent function---' + err);
        }
    }

    const handleCodeChange = (idx, evt) => {
        if (evt && evt.length > 0) {
            setCurrenyCode(evt)
            try {
                let newContacts = dataInfo.contacts.map((obj, sidx) => {
                    if (idx !== sidx) return obj;
                    else {
                        return { ...obj, ["mcode"]: evt[0].value };
                    }
                });
                setDataInfo({ contacts: newContacts })
                props.sendData(newContacts)
            } catch (err) {
                log.error('Exception occured in CompanyComponent function---' + err);
            }
        }
    }
    /**
     * @description:This function will List of Contacts Infos
     * @author: Lakshmi
     * @date:19-08-2020
     * @param {*}
     * @function Branch
     * @modifiedBy : Anil Kumar Ch
     * @param {*} contact
     * @param {*} idx
     */
    const handleRemovePerson = (contact, idx) => {
        setDelete(true)
        if (contact !== null && contact !== undefined && contact !== '' && contact.cid !== null
            && contact.cid !== undefined && contact.cid !== '') {

            if (props.type !== null && props.type !== undefined && props.type !== '' && props.type === 'company') {
                deleteCompanycontact(contact.cid).then(response => {
                    if (response.suc) {
                        setDataInfo({ contacts: dataInfo.contacts.filter((s, sidx) => contact.cid !== s.cid) });
                        let arr = dataInfo.contacts.filter((s, sidx) => contact.cid !== s.cid)
                        props.sendData(arr)
                    } else {
                        console.log("Company contact details Not delete successfull");
                    }
                }).catch(function (error) {
                    log.error(
                        "Exception occured in handleRemovePerson function---" + error
                    );
                });
            } else if (props.type !== null && props.type !== undefined && props.type !== '' && props.type === 'branch') {

                deleteBranchcontact(contact.cid).then(response => {
                    if (response.suc) {
                        setDataInfo({ contacts: dataInfo.contacts.filter((s, sidx) => contact.cid !== s.cid) });
                        let arr = dataInfo.contacts.filter((s, sidx) => contact.cid !== s.cid)
                        props.sendData(arr)
                    } else {
                        console.log("Branch contact details Not delete successfull");
                    }
                }).catch(function (error) {
                    log.error(
                        "Exception occured in handleRemovePerson function---" + error
                    );
                });


            }

        } else {
            setDataInfo({
                contacts: dataInfo.contacts.filter((s, sidx) => idx !== sidx)
            });

        }

    };

    const handleAddPerson = () => {
        setDataInfo({
            contacts: dataInfo.contacts.concat([{ cid: 0, cn: "", cem: "", cph: "", cdesig: "", mcode: "", bcc: true }])
        });
    };

    /**
     * @description:Confirmation dialog popup to delete the Contact
     * @author: Lakshmi
     * @param {*}
     * @function Branch
     * @modifiedBy : Anil Kumar Ch
     * @param {*} obj
     * @param {*} index
     */
    const confirmBranchDlg1 = (obj, index) => () => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <>
                        <div className="custom-ui">
                            <h3>Are you sure?</h3>
                            <p className="t-color2 mb-15">You want to delete the contact ?</p>
                        </div>
                        <div className="buttonGrop text-right pb-0">
                            <button
                                className="btn btn-xs outline-dark"
                                onClick={() => {
                                    handleRemovePerson(obj, index);
                                    onClose();
                                }}
                            >
                                Yes
                </button>
                            <button className="btn btn-primary btn-xs" onClick={onClose}>
                                No
                </button>
                        </div>
                    </>
                );
            }
        });
    };

    /**
     * @description:Confirmation dialog popup to delete the Contact
     * @author: Rambabu
     * @date : 20-08-2020
     * @param {*} obj
     * @param {*} index
     */
    const confirmDlg = (obj, index) => () => {
        if (obj.cid !== 0) {
            confirmBranchDlg(obj, 'Contact', function () {
                handleRemovePerson(obj, index)
            });
        } else {
            setDataInfo({
                contacts: dataInfo.contacts.filter((s, sidx) => index !== sidx)
            });
        }
    };

    return (
        <>
            <h4 className="subTitle plusHr">Contact Person Info</h4>

            <div className="contact d-flex">
                <div className>
                    {dataInfo.contacts.map((contact, idx) => (
                        <Form noValidate validated={props.validation} id="formId">
                            <Form.Row>

                                <React.Fragment key={idx}>
                                    <Form.Group as={Col} xs={2} controlId="cn">
                                        <Form.Control type="text" required placeholder="Enter Name" value={contact.cn} onChange={handleChange(idx)} />
                                        <Form.Label>Name <sup>*</sup></Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Name
                                    </Form.Control.Feedback>
                                        {nameValid && (
                                            <p className="vError">Please Enter a Valid Name</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} xs={2} controlId="cem">
                                        <Form.Control type="text" required placeholder="Enter Email" value={contact.cem} onChange={handleChange(idx)} />
                                        <Form.Label>Email <sup>*</sup></Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Email
                                    </Form.Control.Feedback>
                                        {emailValid && (
                                            <p className="vError">Please Enter a Valid Email</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} xs={2} controlId="cdesig">
                                        <Form.Control type="text" required placeholder="Enter Designation" value={contact.cdesig} onChange={handleChange(idx)} />
                                        <Form.Label>Designation <sup>*</sup></Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Designation
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={2} controlId="mcode">
                                        <Form.Control required as="select" value={contact.mcode} onChange={handleChange(idx)}>
                                            {codes.stdCodeMenu}
                                        </Form.Control>
                                        <Form.Label>Country code<sup>*</sup></Form.Label>
                                        {state && (
                                            <p className="vError">Please Select a Country Code</p>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs={2} controlId="cph">
                                        <Form.Control type="text" required placeholder="Enter Number" value={contact.cph} onChange={handleChange(idx)} />
                                        <Form.Label>Contact Number <sup>*</sup></Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Contact Number
                                    </Form.Control.Feedback>
                                        {noValid && (
                                            <p className="vError">Please Enter a Valid Number</p>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="bcc" as={Col} className="d-flex width140 ">
                                        <Form.Check type="checkbox" label="BCC Email" id={idx} className="d-flex align-items-center" custom checked={contact.bcc} value={contact.bcc} onChange={handleChangeBcc(idx)} />
                                    </Form.Group>
                                    {(idx > 0) ? (
                                        <Col className="d-flex justify-content-center">
                                            <div className="d-flex"><span
                                                className="iconBtn add ezyIcon icon-delete align-self-end mb-4-3"
                                                onClick={confirmDlg(contact, idx)}
                                            ></span></div>
                                        </Col>
                                    ) : ''}

                                </React.Fragment>
                            </Form.Row>
                        </Form>
                    ))}
                </div>
                <div className="d-flex"><span
                    className="iconBtn add ezyIcon icon-plus align-self-end mb-4-3"
                    onClick={handleAddPerson}
                ></span></div>
            </div>
        </>
    )
}
export default React.memo(ContactInfo)