import BranchCreationService from '../../../../services/administration/BranchCreationService';
/**
 * @description: CRUD operations for Branch
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */

export const getCategoryData = async (id) => {
    let categoryList = [];
    await BranchCreationService.getCategoryData().then(response => {
        if (response.data.suc) {
            categoryList = response.data.res;
        }
    });
    return Promise.resolve(categoryList);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const creation = async (obj) => {
    let finalRes = {};
    const saveObj = {
        cn: obj.categoryName,
        crby: obj.crby
    };
    await BranchCreationService.saveCategory(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const update = async (obj) => {
    const updateObj = {
        id: obj.id,
        cn: obj.categoryName,
        upby: obj.upby
    }
    let finalRes = {};
    await BranchCreationService.updateCategory(updateObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const deletion = async (obj) => {

    const req = {
        delby: 'attuser@gmail.com',
        id: obj.id
    }
    let finalRes = {};
    if (obj !== "") {
        await BranchCreationService.deleteCategory(req).then(response => {
            finalRes = response.data;
        })
    }
    return Promise.resolve(finalRes);
}



