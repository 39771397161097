import React, { useState, useEffect, useContext } from 'react';
import Footer from '../../common/Footer'
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';
import MainMenu from '../../common/MainMenu'
import HotelServiceConfigMenu from '../../common/subMenu/HotelServiceConfigMenu'
import { useHistory } from 'react-router-dom';

import { Context } from "../../../../App";
export const MarkupContext = React.createContext();
//This function will show the Air markups List and Add Markup
function DealCode() {
  const history = useHistory();
  const [data, setData] = useState()
  const [markups, setMarkupList] = useState({ markupdata: [] });
  const [editData, setEditData] = useState();
  const [context, setContext] = useContext(Context);
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableView, setEnableView,] = useState(false);
  const [OldmarkupCreated, setOldNames] = useState({oldData: []})

  const [state, setState] = useState({
    createMarkup: false,
    loading: false,
    editMarkup: false
  })


  // ========================================================================
  //  retrieveMarkups
  // Load all the data based on Login type and user id
  // ========================================================================




  return (
    <>
      <MainMenu active='Hoel Services'/>
      <Breadcrumb activePage="Deals"/>
      <div className="container">
        <div className="cardMainlayout pt-0">
          <HotelServiceConfigMenu active="Hotel Deals" />
          <div className="contentArea cmnSearch">
<p>gggggggggggggggggggg</p>
          </div>
        </div>



      </div>
      <Footer />
    </>
  )
}
export default DealCode