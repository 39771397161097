import React, { useState, useReducer, useEffect, useContext} from 'react';
import { Alert, Col, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from '../../../common/buttons/Button'
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import MainMenu from '../../../common/MainMenu';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import { Context } from '../../../../../App';
import { creation, deletion, getOIDMasterData, update } from './operations';
import InnerLoader from '../../../common/loader/InnerLoader';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecord from '../../../common/popUps/NoRecord';
import { confirmAlert } from 'react-confirm-alert';
export const USER_AUTH_DATA = "userAuthData";
const initialState ={ oidMaster:"", res:[], enableView:false, id:[], enbaleUpdate:false, enableUpdate:false, notiMessageShow:false,notiVarient:"",notiMessage:"", isLoadingMore:false, isItineraryLoading:false}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
        };
      case 'editoidmaster':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };
/**
 * @description:This function will creaet Categories and this will provide the ADD,EDIT and Remove functionalities
 * @author: Arshad
 * @param {*}
 * @function Category
 */

function OIDMaster() {

const [state, dispatch] = useReducer (reducer, initialState);
const [context, setcontext] = useContext(Context);
const [validated, setValidated] = useState(false);

useEffect(()=>{
    dispatch({ type: 'isLoadingMore', payload: true });
    dispatch({ type: 'enbaleUpdate', payload: false })
    getOIDMasterData().then((response)=>{
        if (response.length!==0){
        loadOIDresponse(response)
    }
    dispatch({ type: 'isLoadingMore', payload: false });

    })
},[])
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
   const handleChange = (e) => {
    const { id, value } = e.target;
    const re = /^[A-Za-z0-9]+/;
    if ( e.target.value === '' || re.test(e.target.value)) {
      dispatch({type:"oidMaster", payload: e.target.value})}
   }
   const handleCancel = () => {
    window.location='/ezytrip/administration/oidMaster'
   }
   const resetValues = () => {
    dispatch({type:"oidMaster", payload:""})
   }
   const addOfficeId = () => {
    if (!state.oidMaster==""){
        let suc=true
      for(let val of state.res){
        if((val.officeID).toLowerCase()==(state.oidMaster).toLowerCase()){
          suc=false
        }
      }
      if (suc){
        dispatch({ type: 'isItineraryLoading', payload: true });
        const req = {
            "office_id":state.oidMaster,
            "crby":context.logindata.uid
        }
        creation(req).then((response)=>{
            if (response.suc){
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: "Office ID Saved Successfully" })
                dispatch({ type: 'notiVarient', payload: 'success' });
                hidemessage()
                dispatch({ type: 'isItineraryLoading', payload: false });
                window.location = '/ezytrip/administration/oidMaster'
            }
        })
       } else{
            dispatch({ type: 'isItineraryLoading', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Office id already exists' });
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
       }
     }else{
            dispatch({ type: 'isItineraryLoading', payload: false });
            dispatch({ type: 'notiMessageShow', payload: true });
            dispatch({ type: 'notiMessage', payload: 'Office id is required' });
            dispatch({ type: 'notiVarient', payload: 'danger' });
            hidemessage()
    }
   }
   const updateOID = () =>{
    if (state.oidMaster!=""){
        let suc=true
      for(let val of state.res){
        if((val.officeID).toLowerCase()==(state.oidMaster).toLowerCase()){
          suc=false
        }
      }
      if (suc){
        dispatch({ type: 'isItineraryLoading', payload: true });
        const req = {
        "id":state.id,
        "office_id":state.oidMaster,
        "upby":context.logindata.uid
    }
    update(req).then((response)=>{
        if (response.suc){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: response.res })
            dispatch({ type: 'notiVarient', payload: 'success' });
            hidemessage()
            window.location = '/ezytrip/administration/oidMaster'
        }
      })
      } else{
        dispatch({ type: 'isItineraryLoading', payload: false });
        dispatch({ type: 'notiMessageShow', payload: true });
        dispatch({ type: 'notiMessage', payload: 'Office id already exists' });
        dispatch({ type: 'notiVarient', payload: 'danger' });
        hidemessage()
      }
    } else{
        dispatch({ type: 'isItineraryLoading', payload: false });
        dispatch({ type: 'notiMessageShow', payload: true });
        dispatch({ type: 'notiMessage', payload: 'Office id is required' });
        dispatch({ type: 'notiVarient', payload: 'danger' });
        hidemessage()
    }
   }
   const editOfficeID = (obj, type) => ()=> {
    // dispatch({ type: 'oidMaster', payload: obj.office_id });
    if (type === "delete") {
        confirmDlg(obj)
    }else {
            dispatch({ type: 'enbaleUpdate', payload: true });
            dispatch({ type: 'enableView', payload: false });
            dispatch({ type: 'oidMaster', payload: obj.office_id });
            dispatch({ type: 'id', payload: obj.id });
        }
   }
   const confirmDlg = (obj,cb) => {
    confirmAlert({
        customUI: () => {
            return (
                <>
                    <div className="custom-ui">
                        <span className="confirmD"></span>
                        <h3>Are you sure?</h3>
                        <p className="t-color2 mb-1">You want to delete the office id ?</p>
                    </div>
                    <div className="buttonGrop text-center border-0 pb-0">
                        <button
                            className="btn btn-xs btn-secondary pl-4 pr-4"
                            onClick={() => {handleDelete(obj)}}
                           > Yes </button>
                        <button className="btn btn-primary btn-xs pl-4 pr-4" onClick={handleCancel}>
                            No </button>
                    </div>
                </>
            );
        }
    });
};
  const handleDelete = (obj)=>{
        dispatch({ type: "isItineraryLoading", payload: true });
        deletion(obj.id).then((response)=>{
            if (response.suc) {
                dispatch({ type: "notiMessageShow", payload: true });
                dispatch({ type: "notiMessage", payload: response.res});
                dispatch({ type: "notiVarient", payload: "success" });
                window.location = "/ezytrip/administration/oidMaster";
        return
              }
            else{
              dispatch({ type: "notiMessageShow", payload: true });
              dispatch({type: "notiMessage", payload: "Not deleted successfully"});
              dispatch({ type: "notiVarient", payload: "danger" });
              dispatch({ type: "showSave", payload: true });
              dispatch({ type: "isItineraryLoading", payload: false });
              window.location = "/ezytrip/administration/oidMaster";
        return
            }
        })
  }
   const loadOIDresponse = (res)=> {
    let data = [];
    var enableWEdit = false;
    var enableWDel = false;
    if(context.logindata!==""){
      if(context.logindata.roles.includes('C_O_M_E')) {
        enableWEdit = true;
      }
      if(context.logindata.roles.includes('C_O_M_D')) {
        enableWDel = true;
      }
    }else{
      var userData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
      if(userData.roles.includes('C_O_M_E')) {
        enableWEdit = true;
      }
      if(userData.roles.includes('C_O_M_D')) {
        enableWDel = true;
      }
    }
    for (let value of res) {
        data.push({ officeID: value.office_id, options: <><span className="tbAction">{ enableWEdit && <a className="ezyIcon icon-edit" onClick={editOfficeID(value, "edit")}></a>}  </span> </> })
    }
    if (res.length!==0) {
        dispatch({ type: 'res', payload: data })
    }
    dispatch({ type: 'isLoadingMore', payload: false });}

   const columns = [{
    dataField: 'officeID',
    text: 'OfficeID',
    sort: true,
    filter: textFilter()
},
{
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
}];
const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];
    return (
        <>
         {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
              onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
              dismissible> {state.notiMessage}</Alert>
            }
           {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
                 <div class="loaderPage">
                 <div class="loaderIcon"></div>
                </div>
                 </div>
                ) : ""}
        <MainMenu active='Organization/CM'/>
        <Breadcrumb activePage="OID Master"/>
        <div className="container">
            <div className="cardMainlayout pt-0">
            <OrganizationMenu active="OIDMaster" />
            <div className="contentArea">
            <Form noValidate validated={validated} id="formId">
                            <Form.Row>
                                <Form.Group as={Col} xs={3} controlId="oidMaster">
                                    <Form.Control type="text" placeholder="OID Master" autoComplete="off" value={state.oidMaster} onChange={handleChange} />
                                    <Form.Label>OID Master<sup>*</sup></Form.Label>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <div className="text-right pb-0 buttonGrop  creat_absolute">
                       {state.enbaleUpdate &&
                          <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={handleCancel}
                            type="submit"
                        > Back</Button>}
                       {!state.enbaleUpdate &&
                          <Button
                            size="xs"
                            variant="outline-secondary"
                            onClick={resetValues}
                            type="submit"
                            isDisabled={state.enableView}
                        > Reset</Button>}
                      {!state.enbaleUpdate && context.logindata.roles!==undefined && context.logindata.roles.includes('C_O_M_C') &&
                        <Button
                            size="xs"
                            variant="outline-primary"
                            onClick={addOfficeId}
                            type="submit"
                        > Add OID</Button>
                        }
                        {state.enbaleUpdate && (
                                <Button
                                    size="xs"
                                    variant="outline-primary"
                                    onClick={updateOID}
                                    isDisabled={state.enableView}
                                    type="submit"
                                > Update</Button>
                            )}
                   </div>
            </div>
            <div className="cardMainlayout tableRes p-0">
                    {state.isLoadingMore ? <InnerLoader/> : (<>
                        {state.res.length !== 0 &&
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                classes="markup filterTbl oidMasterr"
                                data={state.res}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination= {state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                                state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                                state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                            />
                            || state.res.length === 0
                            && <NoRecord />}</>)}

                 </div>
           </div>
        </div>
        </>
    );
}

export default OIDMaster;