import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { Alert, Col, Form ,Modal, Button,Row, Container} from "react-bootstrap";
import {Link } from 'react-router-dom';
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import VisaPayment from '../payment/VisaPayment';
import Travellerdetailvisa from './VisaTravellers'
import Transactionssummaryvisa from './VisaTransactionsSummary'
import Visaconformation from '../confirmation/VisaConfirmation';
import VisaBookingService from "../../../../services/bookingService/visa/VisaBookingService";
import { Context } from "../../../../../App";
// D:\FE\att_front_end\src\client\components\bookingFlows\insurance\operations.js
import {getCrediLimit} from "../../../bookingFlows/insurance/operations.js"
import Utilities from '../../../commonUtils/Utilities';
import { useHistory } from "react-router-dom";




const initialState= {
    travelsList:[], trvelr_detels:[], notiMessageShow: false, notiMessage: '', notiVarient: '',prepersist:false,enable_pay:false,payment_data:"",enable_payment:false,pay_response:""
}

const reducer = (state, action) => {
    switch (action.type) {
  
      case 'test':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

function VisaForm(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [ fareRes, setSearchRes]= useState(props.location.state)
    const [show, setShow] = useState(false);
    const [context, setContext] = useContext(Context);
    const history = useHistory();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendPsngersData =(e) =>{
        console.log("cxkckdad", e)
       dispatch({type:"trvelr_detels", payload:e})
    }

    const handleHomebtn =()=>{
      window.location='/ezytrip/dashboard'
    }

    const handleVisaform = () =>{
                    history.push({
                    pathname: '/ezytrip/dashboard/visa/summary',
                    state: {
                    fareRes:fareRes,
                    booking_id:state.pay_response.res.booking_id
                        }
                     });       }
       const hidemessage = () => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }
    const Validation = () =>{
        let pType = {
            "ADT": "Adult",
            "CHD": "Child"
          }
        if (state.trvelr_detels!="" || state.trvelr_detels.length>0){
            for(let val of state.trvelr_detels){
              for (let fields of fareRes?.res?.pax_info){
                for(let field of fields.pax ){
        // if ((val.selFront==""|| val.selFront.length==0) && fareRes.res.docs_info[0].docs[0].is_mand){
        // dispatch({ type: 'notiMessageShow', payload: true })
        // dispatch({ type: 'notiMessage', payload: `Please upload front picture of passport for ${pType[val.ty] + "" + val.fesno}`})
        // dispatch({ type: 'notiVarient', payload: 'danger' })
        // hidemessage();
        // return false;
        // }else if ((val.selBack==""|| val.selBack.length==0) && fareRes.res.docs_info[0].docs[1].is_mand){
        // dispatch({ type: 'notiMessageShow', payload: true })
        // dispatch({ type: 'notiMessage', payload: `Please upload back picture of passport for ${pType[val.ty] + "" + val.fesno}`})
        // dispatch({ type: 'notiVarient', payload: 'danger' })
        // hidemessage();
        // return false;
        // }
        // else if ((val.selBank==""|| val.selBank.length==0) && fareRes.res.docs_info[1].docs[0].is_mand){
        //     dispatch({ type: 'notiMessageShow', payload: true })
        //     dispatch({ type: 'notiMessage', payload: `Please upload picture of bank statement for ${pType[val.ty] + "" + val.fesno}`})
        //     dispatch({ type: 'notiVarient', payload: 'danger' })
        //     hidemessage();
        //     return false;
        // }
        // else 
        if ((val.fn=="")&& (field.name.includes("First Name")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter first name for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.ln=="")&& (field.name.includes("Last Name")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter last name for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.dob==null ||val.dob=="")&& (field.name.includes("Date Of Birth")||field.name.includes("Date of Birth")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select date of birth for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.gen=="")&&(field.name.includes("Gender")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select gender for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.rn=="")&&(field.name.includes("Relation")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select relation for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.phn=="")&&(field.name.includes("Phone Number")||field.name.includes("Mobile Number")||field.name.includes("Contact Number")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter mobile number for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.phc=="")){
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: `Please enter Phone code for ${pType[val.ty] + "" + val.fesno}`})
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
  }
    else if ((val.em=="")&&((field.name.includes("Email")||field.name.includes("E-Mail"))?field.is_mand:false) ){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter email for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.na=="")&&(field.name.includes("Nationality")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select nationality for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.city=="")&&(field.name.includes("City")?field.is_mand:false) ){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select city for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.state=="")&&((field.name.includes("State")||field.name.includes("Provience"))?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select state for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.pas_num=="")&& field.name.includes("Passport Number")?field.is_mand:false){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter passport number for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.pas_exd=="") &&(field.name.includes("Passport Expiry")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please select expiry date for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
    else if ((val.cur_adrs=="") &&(field.name.includes("Current Address")?field.is_mand:false)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: `Please enter current address for ${pType[val.ty] + "" + val.fesno}`})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
     }
    }}}
   }else{
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please fill mandatory fields '})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
    }
        return true
       }
    const submitForm = () =>{
        if (Validation()){
            const prebook = {
                  "cur": context.logindata.cur,
                  "cid": (context.logindata.cid !== 0) ? context.logindata.cid : 0,
                  "cname": context.logindata.cnm,
                  "crby": context.logindata.uid,
                  "pax_info": state.trvelr_detels,
                  "bid": context.logindata.bid,
                  "butype": context.logindata.butype,
                  "utype": context.logindata.utype,
                  "bktype": "DIRECT",
                  "bkby": context.logindata.nm,
                  "region_type":context.logindata.region_type,
                  "sales_channel":context.logindata.channel,
                  "trvl_to": fareRes.req.trvel_cnt,
                  "na": fareRes.req.na,
                  "entry_type": fareRes.req.visa_type,
                  "visa": fareRes.req.visa,
                  "dur_type": fareRes.req.dur_type,
                  "dur": fareRes.req.dur,
                  "visa_validity": fareRes.req.visa_validity,
                  "visa_type": fareRes.req.visa_type,
                  "application_id": fareRes.req.id,
                  "category": fareRes.req.category,
                  "adt_pr":fareRes.req.adt_pr,
                  "chd_pr": fareRes.req.chd_pr,
                  "adt_charge":fareRes.res.adt_charge,
                  "chd_charge": fareRes.res.chd_charge,
              }
              VisaBookingService.saveVisaBooking(prebook).then((response) => {
                console.log("Prebook response : " + JSON.stringify(response));
                if (response.data.suc !== undefined && response.data.res !== null) {
                  if (response.data.res.pbres.status !== undefined && response.data.res.pbres.status === "inserted") {
                    console.log("Created TRIP ID: " + response.data.res.pbres.bookingId);
                    sessionStorage.setItem("BOOKING_REF_NO",response.data.res.pbres.bookingId)
                    checkCreditLimit(context.logindata.cid, "credit", response.data.res.pbres, context.logindata.pcid);//Call service to validate CL
                    dispatch({ type: 'enable_pay', payload: true })
                    dispatch({ type: 'notiMessageShow', payload: true })
                    dispatch({ type: 'notiMessage', payload: 'Form submited successfully'})
                    dispatch({ type: 'notiVarient', payload: 'success' })
                    hidemessage();
                  } else {
                    dispatch({ type: 'prepersist', payload: true })
                  }
                } else {
                  dispatch({ type: 'prepersist', payload: true })
                }
              });
       
        return false;
    }
    }
    const checkCreditLimit = async (companyid, paytype, resp, pcompId) => {
        if (companyid === undefined) {
          companyid = context.logindata.cid;
        }
        if (pcompId === undefined || pcompId === null) {
          pcompId = 0;
        }
        if (Number(companyid) === Number(pcompId)) {
          pcompId = 0;
        }
        const req = {
          "comid": Number(companyid),
          "pid": pcompId,
          "cuscode": context.logindata.cuscode
        }
        await getCrediLimit(req).then((response) => {
          console.log("CL Balance avial : " + JSON.stringify(response));
          if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
            const clbal = response.res.data.cbal;
            dispatch({ type: 'creditBal', payload: clbal });
            let total = (fareRes.adt_pr*fareRes.adt_cnt) + (fareRes.chd_pr*fareRes.chd_cnt)
            let totalFae = Number(total);
            console.log("CL Balance  : " + clbal);
            let val = Math.sign(clbal);
            if (Number(val) === 1) {
              if (context.logindata.butype === "sta_user") {
                var pclbal = 0;
                if (response.res.data.pcbal !== undefined) {
                  pclbal = response.res.data.pcbal;
                }
                console.log("CL Balance Parent : " + pclbal);
                if (clbal !== 0) {
                  if (paytype === "credit" && clbal >= Number(totalFae) && pclbal >= Number(totalFae)) {
                    Utilities.subPrice(clbal, Number(totalFae));
                    createBookPayload(true, paytype, resp, 0, clbal, false, false, clbal);
                  } else if (paytype === "credit" && clbal < Number(totalFae)) {
                    var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                    dispatch({ type: 'diffamt', payload: diffAmnt });
                    createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true, clbal);
                  } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                    createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
                  } else if (paytype === "online") {
                    createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
                  } else if (paytype === "credit" && clbal >= Number(totalFae) && pclbal < Number(totalFae)) {
                    dispatch({ type: 'pcomName', payload: response.res.data.pcname });
                    dispatch({ type: 'showNote', payload: true });
                    if (context.logindata.utype === "att_user") {
                      dispatch({ type: 'showOvercl', payload: true });
                    }
                    createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
                  }
                } else {
                  createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
                }
              } else {
                if (clbal !== 0) {
                  if (paytype === "credit" && clbal >= Number(totalFae)) {
                    Utilities.subPrice(state.creditBal, Number(totalFae));
                    createBookPayload(true, paytype, resp, 0, clbal, false, false , clbal);
                  } else if (paytype === "credit" && clbal < Number(totalFae)) {
                    var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                    dispatch({ type: 'diffamt', payload: diffAmnt });
                    createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true,clbal);
                  } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0)) {
                    createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
                  } else if (paytype === "online") {
                    createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
                  } else {
                    createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
                  }
                } else {
                  createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
                }
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
            }
          } else {
            createBookPayload(false, paytype, resp, 0, 0, true, true);
          }
        });
      }

      const createBookPayload = (clavail, paytype, response, amt, clbal, offline, ovewriteCL , balance) => {
        //If CL is available then call book request
        var enableOnline = false;
        if (!clavail && paytype === "credit") {
          enableOnline = true;
        }
        console.log("Total Price+++++++++3 "+state.totalPrice);
        const payload = {
          tid: response.tid,
          bookingId: response.bookingId,
          price: (fareRes.req.adt_pr*fareRes.req.adt_cnt) + (fareRes.req.chd_pr*fareRes.req.chd_cnt),
          cur: "QAR",
          diffamt: amt,
          isclavail: clavail,
          enableOnlinePay: false,
          paytype: paytype,
          cid: context.logindata.cid,
          creditBal: clbal,
          enableOvrwriteCL: ovewriteCL,
          bid: context.logindata.bid,
          butype: context.logindata.butype,
          pcid: context.logindata.pcid,
          utype: context.logindata.utype,
          cname: context.logindata.cnm,
          region_type:context.logindata.region_type,
          overwriteRole: (context.logindata.roles !== undefined && context.logindata.roles.includes('O_C_L_E')) ? true : false,
          cbal:parseFloat(balance),
        }
        console.log("Created Payload :+", payload);
        dispatch({ type: 'payment_data', payload: payload });
        dispatch({ type: 'enable_payment', payload: true });
      }

    const backToHome=()=>{
     window.location="/ezytrip/dashboard";
    }   

    const updateResponse=(data)=>{
        dispatch({ type: 'pay_response', payload: data });
        setShow(true)
    }
      
    return (
        <>
        <Header/>
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
       <div className="headIamge">
        <Container>
        <div className="d-flex applyVisat">
               <h2>Apply For A Visa</h2>
        <span>Choose the right visa for your trip</span>

        </div>
        <div className="backgroundPoly"></div>
        <div className="backgroundPoly"></div>
        </Container>
       </div>
        <Container>
       <Row>
        <Col xs={8}>
          <div className="breadcrumb hderSec">

                <Link><span onClick={handleHomebtn}>Home {">"}</span><span>Traveller Details</span></Link>
        </div>
            <div className="TravellerDetailstitle">
                <h3>Traveller Details</h3>
            </div>
        <Travellerdetailvisa enable_pay={state.enable_pay} sendPsngersData={sendPsngersData} fareRes={fareRes} travellersList={state.travelsList!==undefined && state.travelsList}/>
       
            {!state.enable_pay && <div className="d-flex submitvisForm">
              <button type="button" onClick={submitForm} class="btn-primary btn-lg d-flex btn"><span class="d-flex"> Continue To Payment</span></button>
      </div>}
      <hr />
        {/* PAYMENT FLOW */}
        {state.enable_payment && 
        <VisaPayment bookingdata={state.payment_data} userType={context.logindata.butype}  showOverCL={state.showOvercl} finalResponse={updateResponse} />
        }

        </Col>
       
        <Col xs={4}>
            <div className="TravellerDetailstitle">
                <h3>Transactions Summary</h3>
            </div>
        <Transactionssummaryvisa trvelr_detels={state.trvelr_detels} fareRes={fareRes}/>
        </Col>

       </Row>
       </Container>
       
          

        <Modal show={show} onHide={handleClose} className="sunmitApplicationpop">
        <Modal.Header>
          <Modal.Title>   <span className="d-flex"><span className="ezyIcon icon-tick-fare"></span>Confirmed</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Visaconformation finalResp={state.pay_response}/>
        </Modal.Body>
              <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleVisaform}>Summary</Button>
          <Button variant="outline-primary">Back to Home</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={state.prepersist}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
                <strong>Pre Persistance is Failed</strong>
                <p>Please check with Administrator</p>
              </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              onClick={backToHome}
              variant="primary"
            >Back to Home</Button>
        </Modal.Footer>
      </Modal>
        <Footer/>
        </>
     );
}
export default VisaForm;