import CompanyCreationService from '../../../services/administration/CompanyCreationService';
import BranchCreationService from '../../../services/administration/BranchCreationService';
/**
 * @description: CRUD operations for Branch
 * @author: Anil Kumar Ch
 * @param {*}
 * @function Branch
 * @date : 13-08-2020
 */

/**
  * @description:Delete Company Contact details(Hard Delete)
  * @author:Rambabu
  * @date:19-08-2020
  * @param {*} id 
  */
export const deleteCompanycontact = async (id) => {
    let finalRes = {};
    if (id !== "") {
        await CompanyCreationService.deleteContactDetails(id).then(response => {
            finalRes = response.data;
        })
    }
    return Promise.resolve(finalRes);
}

/**
 * @description:Delete Branch Contact details(Hard Delete)
 * @author:Rambabu
 * @date:20-08-2020
 * @param {*} id 
 */
export const deleteBranchcontact = async (id) => {
    let finalRes = {};
    if (id !== "") {
        await BranchCreationService.deleteContactDetails(id).then(response => {
            finalRes = response.data;
        })
    }
    return Promise.resolve(finalRes);
}








