
import { Button, Col, Form,Alert  } from 'react-bootstrap';
import React, { useState , useReducer , useContext,useEffect} from 'react';
import { Link } from 'react-router-dom';
import ReminderCall from '../dashBoard/ReminderCall';
import { Context } from "../../../../App";
import { useHistory } from 'react-router-dom';
import AddRemainder from './AddRemainder';
import {getAllRemainders,getAllAlerts } from '../../dashBoard/operations';
/**
 * Initial State Declaration
 */
 const initialState = {
  name:"",desc:"",rem_id:0,asigne_name:"",notify_type:"MIN",hrsBefore:"",asigne_id:0,asigne_email:"",userNamesList:[],pre_type:"High",
  notiMessage: '', notiMessageShow: false, notiVarient: '',remainders_List:[]
}
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};


function DashBordReminder(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addriminder, setAddriminder,] = useState(false);
  const [context, setContext] = useContext(Context);
  const history = useHistory();

  const userName = [{ label: "Raghava", value: 'Raghava' },
  { label: "Anil", value: 'Anil' },
  { label: "Mubeen", value: "Mubeen" }]

  const notifyList = [{ label: "Minutes", value: 'MIN' },
  { label: "Hours", value: 'HR' }]


  useEffect(() => {
    if(context.logindata!==undefined && context.logindata!==""){
      let is_admin=context.logindata.utype==="att_user"?1:0
    getAllRemainders(context.logindata.cid,is_admin,context.logindata.uid).then(response => {
      if (response.suc) {
        dispatch({ type: 'remainders_List', payload: response.res })
      }
     })
     
    }
    if(context.logindata!==undefined){
      let is_admin=context.logindata.utype==="att_user"?1:0
      getAllAlerts({"userId":context.logindata.uid,"pcid":context.logindata.pcid,"utype":context.logindata.utype,"is_admin":is_admin}).then(response => {
      if(response.suc) {
        props.sendAlerts(response.res)
      props.sendAlertsSize(response.res.length)
      }
  })
  }
    }, [context.logindata.uid]);


  const openRiminder = () => {
    if (addriminder) {
      setAddriminder(false)
    } else {
      setAddriminder(true)
    }
  }
  const closeRiminder = () =>{
    setAddriminder(false)
  }


  return (
    <>
       {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
       }
      {/* <div className="dashBordReminder">
        <Button  size="md" className="viewallqu" onClick={openRiminder}  variant="primary">Add Reminder</Button>
      </div> */}
      <p className="reminderView"><Button variant="link" ><Link
            to="/ezytrip/dashboard/remainders/list"
            title="Reminder">
            View All
          </Link></Button></p>

       {addriminder &&
        <AddRemainder closeRemainder={closeRiminder}/>
        }
        {state.remainders_List!==undefined &&
        <ReminderCall remaiList={state.remainders_List}/>
        }
    </>
  )
}


export default DashBordReminder;
