
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../App";
import Tabs from '../../common/tabs/Tabs';
import Utilities from '../../commonUtils/Utilities';
import VoidFareDetails from './VoidFareDetails';

function VoidSummaryDetails(props) {
  const [state, setState] = useState({})
  const [context, setContext] = useContext(Context);
  const [attPriceList, setAttPriceList] = useState([]);
  const [taPriceList, setTaPriceList] = useState([]);
  const [staPriceList, setStaPriceList] = useState([]);
  const [tablist, setTablist] = useState({
    active: 'ATT'
  });

  
  useEffect(() => {
    if(props.voidFareResp!==undefined && props.voidFareResp.length!==0){
            setAttPriceList(props.voidFareResp.passenger.filter((obj) => obj.type==="MTA"));
            setTaPriceList(props.voidFareResp.passenger.filter((obj) => obj.type==="TA"));
            setStaPriceList(props.voidFareResp.passenger.filter((obj) => obj.type==="STA"));
    }
  }, [props.voidFareResp])


  const content = {
    ATT: <VoidFareDetails voidData={attPriceList} type={"ATT"} />,
    TA: <VoidFareDetails voidData={taPriceList} type={"TA"} />,
    STA: <VoidFareDetails voidData={staPriceList} type={"STA"} />
  };


  return (
    <>
      {attPriceList.length !== 0 && attPriceList[0].pax!==undefined && attPriceList[0].pax.length!==0 &&  taPriceList.length !== 0 &&
        <div className="refundBookingInfo resultSection boxShadow bg-white mb-4 pb-0">
          <h4 className="dashBordTitle mb-3">Void Fare Details ({props.userCurrency})</h4>
          <div className="tabLink tabList">
            {context.logindata.utype === "att_user" && attPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="ATT">Ezytrip</span>
                <span key="TA">Traval Agent</span>
                {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                  <span key="STA">Sub Travel Agent</span>
                }
              </Tabs>
            }
            {context.logindata.utype === "ta_user" && taPriceList.length !== 0 &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="TA">Traval Agent</span>
                {staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
                  <span key="STA">Sub Travel Agent</span>
                }
              </Tabs>
            }
            {context.logindata.utype === "sta_user" && staPriceList.length !== 0 && props.bookedTo === "sta_user" &&
              <Tabs active={tablist.active} onChange={active => setTablist({ active })}>
                <span className="alertList alerts d-flex align-items-center" key="STA">Sub Traval Agent</span>
              </Tabs>
            }
            <div className="tabContent">
              {content[tablist.active]}
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default VoidSummaryDetails;
