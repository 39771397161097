import React from 'react';

/**
 * @description:This function will Calculate the Room wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 12-02-2021
 */

/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function Remarks(props) {


  return (
    <>
      {props.attractions!==undefined &&
      <div className="costBox bookingSummary nearby">
        <div className="nearbylist" dangerouslySetInnerHTML={{ __html: props.attractions }} />
     </div>
      }
    </>

  )
}
export default Remarks