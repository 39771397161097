import AirportConfigurationService from '../../../../services/administration/AirportConfigurationService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import { apiPostHelper } from '../../../../components/commonUtils/helper';
const API_URL = process.env.REACT_APP_B2B_URL;
/**
 * @description: CRUD operations for Hold Bookings
 * @author: Lakshmi
 * @param {*}
 * @function Hold Bookings
 * @date : 22-09-2022
 */

 export const saveAirportData = async (obj) => {     
    let finalRes = {};
    const headers = { headers: { 'Content-Type': 'application/json' } };
    await apiPostHelper(`${API_URL}/att/common/save-airdata`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}



// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const searchAirportCode = async (obj) => {
    let finalRes = {};
    await AirportConfigurationService.searchAirport(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//================================
// Fetch All Master Countries List
//================================
export const countries = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            response.data.res.map((cnt) =>
                countriesList.push({ "value": cnt.id, "label": cnt.name+"["+cnt.code+"]","code": cnt.code })
            );
        }
    });
    return Promise.resolve(countriesList);
};

//================================
// Fetch All Master Cities List Based on Country
//================================
export const cities = async (countryId) => {
    let citiesList = [];
    await AttCommonSerivce.getCityList(countryId).then(response => {
        if (response.data.suc) {
            response.data.res.map((cty) =>
                citiesList.push({ "value": cty.id, "label": cty.name })
            );
        }
    });
    return Promise.resolve(citiesList);
};



