
import commonService from '../../../services/commonService/AttCommonSerivce';
import FlightSummaryService from '../../../services/postBooking/flight/FlightSummaryService';
import PostBookingService from '../../../services/postBooking/flight/PostBookingService';
import { apiGetHelper, apiPostHelper } from '../../commonUtils/helper';

//import response from './response.json';
/**
 * @author: Rambabu
 * @description: Fetch Flight booking summary details resepective booking id
 * @param {*} req 
 * @date:16-09-2020 
 */

const API_URL = process.env.REACT_APP_B2B_SEARCH
const POST_BOOK_URL = process.env.REACT_APP_DB_URL
const POST_URL = process.env.REACT_APP_B2B_URL

export const getFltSummaryData = async (req) => {
    let summary_res = '';
    await FlightSummaryService.getFlightSummaryDetails(req.booking_id).then(response => {
        if (response.data.suc) {
            summary_res = response.data.res;
        }
    })
    return Promise.resolve(summary_res);
}

// /retrievereissuesplit
export const retrievereissuesplit = async (req) => {
    let summary_res = '';
    await FlightSummaryService.getretrievereissuesplit(req).then(response => {
        if (response.data.suc) {
            summary_res = response.data.res;
        }
    })
    return Promise.resolve(summary_res);
}
// fetchHistoryData
export const fetchHistoryData = async (req) => {
    let summary_res = '';
    await FlightSummaryService.getHistory(req).then(response => {
        if (response.data.suc) {
            summary_res = response.data.res;
        }
    })
    return Promise.resolve(summary_res);
}
/**
 * @author: Rambabu
 * @description: Fetch Flight Airport data based on airport codes
 * @param {*} req 
 * @date:01-10-2020 
 */
export const getairportData = async (req) => {

    let air_res = '';
    await FlightSummaryService.getAirportData(req).then(response => {
        if (response.data.suc) {
            air_res = response.data.res;
        }
    })
    return Promise.resolve(air_res);
}



export const callTicketing = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/pnr/validatePNR`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const callDiscardBook = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${POST_BOOK_URL}/postBook/discardStatus`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}



/**
 * @author: Rambabu
 * @description: Fire incomplete flow request
 * @param {*} req 
 * @date:28-11-2020 
 */
export const fireIncompleteService = async (req) => {
    let incomplete_res = '';
    await PostBookingService.getIncompletebooking(req).then(response => {
        incomplete_res = response.data;
    })
    return Promise.resolve(incomplete_res);
    //return Promise.resolve(response);


}
/**
 * @description : to fetch country codes by passing city codes 
 * @author: Azamuddin
 * @serviceFrom : Chandra
 * @date: 03-12-2020
 */

export const getCountryCodes = async (req) => {

    let air_res = '';
    await commonService.fetch_country_codes(req).then(response => {
        if (response.data.suc) {
            air_res = response.data.res;
        }
    })
    return Promise.resolve(air_res);
}

// voidFareFetch

/**
 * @description : to fetch fares for the requested ticket numbers 
 * @author: Azamuddin
 * @serviceFrom : Rambabu
 * @date: 03-12-2020
 */

export const voidFareFetch = async (req) => {

    let air_res = '';
    await FlightSummaryService.voidFareFetch(req).then(response => {
        if (response.data.success) {
            air_res = response.data;
        }
    })
    return Promise.resolve(air_res);
}

/**
 * @description : to save void for the tickets 
 * @author: Azamuddin
 * @serviceFrom : Chandra
 * @date: 03-12-2020
 */

export const saveVoid = async (req) => {

    let air_res = '';
    await FlightSummaryService.saveVoid(req).then(response => {
            air_res = response;
    })
    return Promise.resolve(air_res);
}

/**
 * @description : to cancel void for the tickets 
 * @author: Azamuddin
 * @serviceFrom : Chandra
 * @date: 03-12-2020
 */

export const cancelVoid = async (req) => {

    let air_res = '';
    await FlightSummaryService.cancelVoid(req).then(response => {
            air_res = response;
    })
    return Promise.resolve(air_res);
}


/**
 * @author: Rambabu
 * @description: Fire Fetch PNR request
 * @param {*} pnr 
 * @date:03-12-2020 
 */
export const pnrSearch_bkp = async (pnr) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            pnr: pnr
        }
    }
    await apiGetHelper(`${API_URL}/pnr/retrieve`, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const pnrSearch = async (body) => {
    let finalRes = {};
    const headers = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/pnr/retrievePnrs`, body,headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @author: Rambabu
 * @description: Fetch retrive segment pricing
 * @param {*} obj 
 * @date:03-12-2020 
 */
export const retrivePrice = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/pnr/segmentPrice`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);

}

/**
 * @author: Rambabu
 * @description: Save Segmnet details for Import PNR
 * @param {*} obj 
 * @date:03-12-2020 
 */
export const saveSegDetails = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/pnr/itinerary-save`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);

}

//Get Refund details
export const getRefundDetails = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/refundPricing`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


//Get Reissue details
export const getReissueDetails = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/ticketEligibility`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const processRefund = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/refundProcess`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const showRefunds = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${POST_BOOK_URL}/postTicket/refund-retrive`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//pnr search
export const retriveFareDetails = async (bookingId) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            bookingId: bookingId
        }
    }
    await apiGetHelper(`${POST_URL}/att/fltsummary/retrieve-fare-details`, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description : to fetch faredetails for reissued tickets
 * @date: 20-01-2021
 * @author : Azamuddin
 */

export const fetchfareDetailsReissued = async (data) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        },
        params: {
            bookingId: data.bookingId,
            isReIssue: "yes"
        }
    }
    await apiGetHelper(`${POST_URL}/att/fltsummary/retrieve-fare-details`, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


export const getATCAirline = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${POST_URL}/att/flight/get-atc-airlines`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const processEditBooking = async (obj) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${POST_URL}/att/fltsummary/flight_edit`, obj, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

export const cancelledSplit = async (data) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/postTicket/cancelPnr`, data, req).then(response => {
        finalRes = response;
    });
    return Promise.resolve(finalRes);
}

export const atc_refundairlines = async (airports) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${POST_URL}/att/flight/get_atc_refund_airlines`, airports, req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

/**
 * @description:Get compnay and branch address based on compnay id
 * @date:19-05-2021
 * @author:Rambabu
 * @param {*} req 
 * @returns 
 */
export const getcompanyAddress = async (req) => {    
    let address_res = '';
    await FlightSummaryService.getCompnayAddress(req.cid).then(response => {        
        if (response.data.suc) {
            address_res = response.data.res;
        }
    })
    return Promise.resolve(address_res);
}

/**
 * @description : validate summary 
 * @author : Azamuddin,
 * @date : 27-05-2021
 */
export const validateBookingSummary = async(req) => {
    let response = '';
    await commonService.validateBookingSummary(req).then(resp => {
        if(resp) {
            response = resp
        }
    })
    return Promise.resolve(response)
}

/**
 * @description : cancel booking flow service call
 * @author : Azamuddin
 * @date : 28-05-2021
 */

export const cancelBookingFlow = async(req) => {
let response = '';
await FlightSummaryService.cancelBookingFlow(req).then(resp => {
    if(resp.data.suc) {
        response = resp
    }
})
  return Promise.resolve(response)
}

//Get Reissue details
export const getReissueSplitResp = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/pnr/split_pnr`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Get Reissue details
export const getReissueSplitItinarary = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/att/pnr/split_pnr`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
//Get Long fare rules for Flight
export const getSummaryLongfareRules = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/flight/long-rules`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Get Long fare rules for Flight
export const checkReissuePrice = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/flight/reissuePricing`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


//Call OLD Itinarary details
export const getItnarary = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/common/flight-fetch`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//pnr search
export const getPNRDetails = async (request) => {
    let finalRes = {};
    const req = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    await apiPostHelper(`${API_URL}/pnr/retrieve`, request).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


//Get Reissue details
export const insertPNRData = async (pnr) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/insert_itinerary`, pnr, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const processManualRefund = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${POST_URL}/att/fltsummary/refund-save`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const sendRequest = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${POST_BOOK_URL}/postbook/sendRequest`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Refund
export const getIndigoRefundPricing = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/refundPricing-indigo`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Show Indigo Refund details
export const showIndigoRefunds = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/refundretrieve-indigo`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Process Indigo Refund
export const processIndigoRefund = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL}/postTicket/refundProcess-indigo`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}