
import React, { useEffect, useReducer } from 'react';
import { Col, Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { isNotNull } from '../../../commonUtils/validators';
import DealCodeConfig from './DealCodeConfig';
import PLBConfig from './PLB';

/**
 * Initial State Declaration
 */
const initialState = {
  dealCode: true,
};

const reducer = (state, action) => {

  switch (action.type) {

    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will create the air markup defination and some other details.
function Deal(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {

    //if PLB Deal code
    if (isNotNull(props.edit_res) && parseInt(props.edit_res.dt) === 2) {
      dispatch({ type: 'dealCode', payload: false });

    }//if normal Deal code
    else if (isNotNull(props.edit_res) && parseInt(props.edit_res.dt) === 1) {
      dispatch({ type: 'dealCode', payload: true });

    } else {
      //deafaulte Normal deal code should be select
      dispatch({ type: 'dealCode', payload: true });

    }

  }, []);





  const dealtype = () => {
    dispatch({ type: 'dealCode', payload: true })
  }
  const plbtype = () => {

    dispatch({ type: 'dealCode', payload: false })
  }

  return (

    <>


      <Form.Row>
        <Form.Group as={Col} xs={3} controlId="ttype" className="inputCheck pt-0 mb-0">
          <div className="d-flex align-items-center">
            <Form.Check type="radio" id="perTicket" label="Deal Code" className="ml-0" name="applicableOn" checked={state.dealCode} custom onChange={dealtype} />
            <Form.Check type="radio" id="perBooking" label="PLB" className="ml-3" name="applicableOn" checked={!state.dealCode} custom onChange={plbtype} />
          </div>
        </Form.Group>
      </Form.Row>
      {state.dealCode && <DealCodeConfig oldDataNames={props.oldDataNames} edit_result={props.edit_res} disabledBtn={props.disableBtn} />}
      {!state.dealCode && <PLBConfig edit_result={props.edit_res} disabledBtn={props.disableBtn} />}


    </>
  )
}
export default Deal