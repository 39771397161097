
import {apiPostHelper} from '../../../../components/commonUtils/helper';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce'
const HOTEL_BOOK_URL = process.env.REACT_APP_HOTEL_BOOK_URL
const HOTEL_HOLD_BOOK_URL = process.env.REACT_APP_PRE_BOOK_URL
 /**
 * @description: HotelPay page operations
 * @author: Lakshmi
 * @date : 08-12-2020 
 * @param {*} obj 
 */

// ========================================================================
//  Deduct Credi Limit 
// ========================================================================
export const deductCreditLimit= async (obj) => {
    let clRes = [];
     await AttCommonSerivce.deductCreditLimit(obj).then(response => {
        clRes = response.data;
     });
     return Promise.resolve(clRes);
 }

// ========================================================================
//  Calling for Final Book
// ========================================================================
export const fireHotelBook = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotel/confirm`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Calling for Final Book
// ========================================================================
export const fireHotelOtillaBook = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotel/otilla/confirm`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Calling for HOLD THE BOOK
// ========================================================================
export const fireHoldHotel = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_BOOK_URL}/hotel/onholdbook `,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
//  Calling for HOLD THE BOOK
// ========================================================================
export const updateHoldPayment = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_HOLD_BOOK_URL}/hotel/onholdpostbook`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
 
// ========================================================================
//  Calling for Final Book
// ========================================================================
export const checkHoldBookings = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${HOTEL_HOLD_BOOK_URL}/hotelonholdconfig/checkonholdconfig`,obj,headers).then(response => {       
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
