import React, { useState, useEffect, useReducer } from 'react';
import Utilities from '../../../../commonUtils/Utilities';
import DateUtils from '../../../../commonUtils/DateUtils';
import {Col, Row,ProgressBar,Button,Alert} from "react-bootstrap";
import {getGuestReviews } from '../../../../bookingFlows/hotel/pages/operations';
/**
 * @description:This function will load the Guest reviews
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 01-04-2021
 */

 const initialState = {
   guestReviews:[],reviewObj:"",expRating:0,usrRating:0,response:"",isLoading:false,notiMessage:"", notiMessageShow:false, notiVarient:"danger"
  };
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
            ...state,      };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };


/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function GuestReviews(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [cleanless , setCleanless] = useState(0)
    const [service , setService] = useState(0)
    const [amenities , setAmenites] = useState(0)
    const [fecilities , setFacitliies] = useState(0)
    const [showReview, setShowReview] = useState(false);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(5);
    const [cleanlessRate , setCleanlessRate] = useState(0)
    const [serviceRate , setServiceRate] = useState(0)
    const [amenitiesRate , setAmenitesRate] = useState(0)
    const [fecilitiesRate , setFacitliiesRate] = useState(0)

    useEffect(() => {
        if(props.reviewObj!==undefined){
            dispatch({type: "isLoading",payload: true})
            let reviewCont="";
            let pid="";
            if(props.page==="Results"){
            reviewCont=props.reviewObj.cont.guestreview;
            pid=props.reviewObj.pid;
            }else{
            reviewCont=props.reviewObj;
            pid=props.pid;
            }
            getGuestReviews(pid).then((response) => {
                if (response.suc) {
                 dispatch({ type: 'guestReviews', payload: response.data })
                 dispatch({type: "isLoading",payload: false})
                if(reviewCont.cleaniness!==undefined){
                    let val=checProgresBar(reviewCont.cleaniness);
                    setCleanless(val)
                    setCleanlessRate(reviewCont.cleaniness)
                   }
                    if(reviewCont.staff_service!==undefined){
                        let val=checProgresBar(reviewCont.staff_service);
                        setService(val)
                        setServiceRate(reviewCont.staff_service)
                    }
                    if(reviewCont.amenities!==undefined){
                        let val=checProgresBar(reviewCont.amenities);
                        setAmenites(val)
                        setAmenitesRate(reviewCont.amenities)
                    }
                    if(reviewCont.conditions_facilities!==undefined){
                        let val=checProgresBar(reviewCont.conditions_facilities);
                        setFacitliies(val)
                        setFacitliiesRate(reviewCont.conditions_facilities)
                    }
                } else {
                dispatch({ type: "notiMessage", payload: "Guest reviews not avaialable" })
                dispatch({ type: "notiVarient", payload: "danger" })
                dispatch({ type: "notiMessageShow", payload: true })
                dispatch({type: "isLoading",payload: false})
                }
            });
        }
        }, [props.reviewObj])

        const checProgresBar=(val)=>{
            if(val!==undefined && val!==0){
                let valAray=val.split(".");
                let finalVal=valAray[0]+valAray[1];
                return finalVal*2;
            }else{
                return 0;
            }

        }
        const showtag = () =>{
            if(state.guestReviews.length > to){

                if(state.guestReviews.length <= (to + 5)) {
                    setShowReview(true)
                } else {
                    setShowReview(false)
                }
                setTo(to + 5)

            }else{
                if(showReview) {
                    setShowReview(false)
                } else {
                    setShowReview(true)
                }
                setFrom(0)
                setTo(5)
            }
        }
  return (
    <>
          <div className="reviewHeader">
          {state.isLoading && <div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>}
        {state.notiMessageShow &&
         <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
         }
          <Row>
              <Col className="reviewCount">
                  <h3><span>{props.urat +"/5"}</span>{props.urat!==undefined && Utilities.getUserStatus(props.urat)}</h3>
                  {/* <p>{props.reviewCount} verified reviews </p> */}
                  <p className="plcy d-flex">{props.reviewCount} verified reviews
                  <span className="toolTipInfo ml-1 rcmd ezyIcon icon-info">
          <div className="toolTipCUstoom cnclInfo">
            <div className="toolTipCont">
            Expedia Group verifies all reviews to ensure that the customer has indeed booked on our site. To encourage customers to submit their reviews, we send an email containing a link to evaluate the hotel to which we sometimes attach a reduction or Expedia Rewards voucher to use on a future trip. We post all reviews whether they are positive or negative, to the extent that they respect our <a href="https://www.expedia.com/p/info-other/reviewandphotosubmissionrules">content directives</a>. This monitoring is automated. Any rejection is manually checked and, if maintained, notified to the reviewer by email with reasons. We do not otherwise process changes, nor reviewer contact. We de-list reviews after 4 years, other than for properties having a non-significant number of reviews
            </div>
          </div></span>
        </p>

              </Col>
              <Col xs={4}>
                  <div className="reviewType">
                      <p><span>Cleanliness</span><span>{cleanlessRate}/5</span></p>
                  <ProgressBar variant="success" now={cleanless}  />
                  </div>
                  <div className="reviewType">
                      <p><span>Staff & service</span><span>{serviceRate}/5</span></p>
                  <ProgressBar variant="success" now={service} />
                  </div>
              </Col>
              <Col xs={4}>
              <div className="reviewType">
                      <p><span>Amenities</span><span>{amenitiesRate}/5</span></p>
                  <ProgressBar variant="success" now={amenities} />
                  </div>
                  <div className="reviewType">
                      <p><span>Property conditions & facilities</span><span>{fecilitiesRate}/5</span></p>
                  <ProgressBar variant="success" now={fecilities} />
                  </div>
              </Col>
          </Row>
          </div>
          <div className="reviewList">
          {state.guestReviews.length > 0 && state.guestReviews.map((review, index) => (
                <React.Fragment key={index}>
               <div className="list">
                  <h4 className="gustName"><strong>{review.reviewer_name} |
                  {review.verification_source.includes("Hotels.com") &&
                  <><span className="icons hotelcom"></span>Traveler</>
                  }
                  {review.verification_source.includes("Expedia Group") &&
                  <><span className="icons expedia"></span> Traveler</>
                  }
                   {review.verification_source.includes("vrvo") &&
                  <><span className="icons vrvo"></span> Traveler</>
                  }

                  </strong><span>{DateUtils.prettyDate(review.date_submitted,'ddd, DD MMM, YYYY')}</span></h4>
                  <h5 className="reviewtype"><em>{review.rating}</em>{Utilities.getUserStatus(review.rating)}</h5>
                  {review.text!==undefined && review.text!=="" &&
                  <p>{review.text}</p>
                  }
                  {/* {review.verification_source.includes("Hotels.com") &&
                  <p>Source : Hotel</p>
                  }
                  {review.verification_source.includes("Expedia Group") &&
                  <p>Source : Expedia</p>
                  } */}
              </div>
              </React.Fragment>
          )).slice(from, to )}


          </div>
          {state.guestReviews.length >=6 ?
            <Button className="showmorelink" variant="outline-drak" onClick={() => showtag()}>
                {showReview ? <span className="less">Show less <span className="ezyIcon icon-arrowDown"></span></span> : <span className="more">Show more <span className="ezyIcon icon-arrowDown"></span></span>}
            </Button> :''}

    </>

  )
}
export default GuestReviews