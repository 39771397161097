
import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import AirlinesMemo from '../../../common/airlines';
import DateUtils from "../../../commonUtils/DateUtils";
import FlightDetails from '../common/FlightDetails';

/**
 * @description:This function will create the Segement details
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 01-09-2020
 */
function SegmentDetails(props) {
    const [flightDetails, setFlightDetails] = useState(false);
    const mappings = props.mapping;
    const showFlightInfo = (event) => {
        if (event.target.parentNode.classList.contains("fdnextOpen")) {
            event.target.parentNode.classList.remove("fdnextOpen");
            event.target.classList.remove("active");
        } else {
            event.target.parentNode.classList.add("fdnextOpen");
            event.target.classList.add("active");
        }
    }
    return (
        <>
            <div className={"rs-block " + (props.origin)}>
                <Row>
                    <Col xs={4} className="itineraryDetailpage  d-flex justify-content-between">
                        <div className="airLogo d-flex align-items-center ll">
                            <img
                                src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + props.origin.seg[0].ma + '.png'}
                                className="logo"
                                alt="AirLine"
                            />
                            <div className="flightNo d-flex flex-column">
                                <strong><AirlinesMemo code={props.origin.seg[0].ma} /></strong>
                                <span>{props.origin.seg[0].ma} - {props.origin.seg[0].fn}</span>
                            </div>
                        </div>
                        {props.origin.sup!=="0" &&
                            <div className="ltag lcc ezyIcon icon-nFlight"> LCC </div>
                            }
                            {props.origin.sup==="0" &&
                            <div className="ltag fcc ezyIcon icon-nFlight"> FSC </div>
                            }
                    </Col>
                    <Col xs={8}>
                        <Row className="align-items-center pt-2 pb-2">
                            <Col xs={7} className="short-timeStops pr-5 d-flex justify-content-between align-items-center">
                                <div className="time d-flex flex-column">
                                    <span>{DateUtils.prettyDate(props.origin.seg[0].dd, "HH:mm")}</span>
                                    <strong>{props.origin.seg[0].da}</strong>
                                </div>

                                {props.bktype && props.bktype == "IMPORT" ? (
                                   <>
                                   <div className="stop d-flex flex-column align-items-center 1">
                                   <span className="icons"><span className=" ezyIcon icon-flight"></span></span>
                                   </div>
                                   </>
                                ) : (
                                    <>
                                    <div className="stop d-flex flex-column align-items-center 1">
                                   <span>{props.origin.seg.length - 1 === 0 ? 'Non ' : (props.origin.seg.length - 1)} Stop</span>
                                    <span className="icons"><span className=" ezyIcon icon-flight"></span></span>
                                    <div className="layoverStp"> {props.origin.seg.length !==1 && <span className="space"> </span>}<span>{props.origin.seg.length > 1 ? props.origin.seg.slice(1, props.origin.seg.length).map(segs => segs.da) : ''}</span></div>
                                    </div>
                                    </>
                                )}
                                <div className="time d-flex flex-column">
                                    <span className="flightTime">{DateUtils.prettyDate(props.origin.seg.slice(-1)[0].ad, "HH:mm")} </span>
                                    <strong>{props.origin.seg.slice(-1)[0].ar}</strong>
                                </div>
                            </Col>
                            <Col xs={5} className="timeSeat pl-0 d-flex justify-content-between">
                                <div className="duration  d-flex flex-column">
                                    {props.origin.sdur!=="" && props.origin.sdur !== undefined && <span className="time">{props.origin.sdur.split(":")[0] + " hrs " + props.origin.sdur.split(":")[1] + " mins"}</span>}
                                    {props.origin.bgd!==undefined && props.origin.bgd.length!==0 && <span className="baggage ezyIcon icon-baggage"> {props.origin.bgd[0].qun} {props.origin.bgd[0].wt} </span>}
                                </div>
                                <div className="seatsLeft  d-flex flex-column mr-3 align-items-end">
                                    {window.location.href.includes("/results")? (
                                    <span className="ezyIcon icon-running">{props.origin.seg[0].seat} Seats Left</span>
                                    ) : (
                                     <>
                                      {props.revieWFlt!==undefined && props.revieWFlt.flowType!=="IMPORT" &&
                                      <span className="ezyIcon icon-running">{props.origin.seg[0].seat} Seats Left</span>
                                      }
                                     </>
                                     )
                                    }
                                    {props.origin.re === "yes" ? <span className="refundType refund">Penalty Applies</span> : ''}
                                </div>
                            </Col>
                        </Row>

                    </Col>

                </Row>
                <div className="rsb-bottom d-flex justify-content-between align-items-center">
                    <div className="flightAmenities d-flex">
                        {props.origin.seg[0].fa.includes("USB") ? <span className="usb ezyIcon icon-usb">
                           USB
                        </span> : ''}
                        {props.origin.seg[0].fa.includes("WIFI") ? <span className="wi-fi ezyIcon icon-wifi">
                            Wi-Fi
                        </span> : ''}
                        {props.origin.seg[0].fa.includes("MEAL") ? <span className="fresh-meals ezyIcon icon-meal">
                           Fresh Meals
                        </span> : ''}
                        &nbsp;
                    </div>
                    <div onClick={showFlightInfo} className={"flightDetailsLink" + (flightDetails ? " active" : "")}>
                        Flight Details <span className="ezyIcon icon-arrowDown"></span>
                    </div>

                </div>
                <FlightDetails origin={[props.origin, 'Confirm']} mapping={mappings} bktype={props.bktype} reviewFlight={props.revieWFlt} selFlt={props.origin.ref}/>
            </div>
        </>
    )
}


export default SegmentDetails;
