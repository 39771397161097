
import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import Utilities from '../../../../commonUtils/Utilities';

/**
 * Initial State Declaration
 */
const initialState = {
    price_data: '',
};


// Reducer function for userReact Hook which will update the state
const reducer = (state, action) => {

    switch (action.type) {

        default:
            return { ...state, [action.type]: action.payload };
    }
};

function BookingDetails(props) {
    const [state, dispatch] = useReducer(reducer, initialState);


    useEffect(() => {



    }, [])



    return (
        <>
            <div className="confirmHotel">
                <div className="img"> <img
                    src={props.bkres.htldtls.htimg}
                    className="htlImg"
                    title="Hotel"
                    alt="Hotel"
                /></div>
                <div className="sumryInfo"> <h5>{props.bkres.htldtls.htnm}
                    <span className="stars ml-2">
                        {Utilities.getHotelStartRating(props.bkres.htldtls.rating).map((star, idx) => (
                            <React.Fragment key={idx}>
                                <span className="ezyIcon icon-star"></span>
                            </React.Fragment>
                        ))}
                    </span>
                </h5>
                    <p className="place ezyIcon icon-map"> {props.bkres.htldtls.add}, {props.bkres.htldtls.city}, {props.bkres.htldtls.country}</p>
                    {/* <p className="place">{props.bkres.htldtls.phone_no}, {props.bkres.htldtls.email}</p> */}
                   {props.bkres.htldtls.phone_no !=='' && <p className="place contact"> <span className=" ezyIcon icon-phone"> {props.bkres.htldtls.phone_no}</span> {props.bkres.htldtls.email !==undefined && props.bkres.htldtls.email.length!==0 && <span className="ezyIcon icon-mail"> {props.bkres.htldtls.email}</span>} </p>}
                    <div className="adderss">
                        <p>Nights : <span>{props.bkres.nonights}</span></p>
                        <p>Rooms : <span>{props.bkres.rooms.length}</span></p>
                        <p>Check-In : <span>{props.bkres.htldtls.cin} </span><strong></strong></p>
                        <p>Check-Out : <span>{props.bkres.htldtls.cout} </span><strong></strong></p>
                    </div>
                </div>
            </div>
        </>
    )
}


export default BookingDetails;
