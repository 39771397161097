import UserRoleManagementService from '../../../../services/administration/UserRoleManagementService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';

/**
 * @description: CRUD operations for Markup
 * @author: Lakshmi
 * @param {*}
 * @function User
 * @date : 27-10-20
 */


// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const saveRoleDetails = async (obj) => {
    let finalRes = [];
    await UserRoleManagementService.saveRoleDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}
export const fetchCrmFunctionalities = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.crmFunctionalities(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
//  Branch Create Operation
//  This will create Branch and save the details 
// ========================================================================
export const updateRoleDetails = async (obj) => {
    let finalRes = [];
    await UserRoleManagementService.updateRoleDetails(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


// ========================================================================
// All Air port Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const fetchFunctionalities = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.fetchFunctionalities(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

/**
 * @description : fetch users from DB w.r.t userId
 */

export const getRolesSearch = async (code) => {
    let ofcList = [];
    await UserRoleManagementService.getRolesSearch(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}





