import React, {useState,useEffect,useContext} from 'react';
import Select from 'react-dropdown-select';
import {Col, Form } from "react-bootstrap";
import { Context } from "../../../../App";

/**
 * @description: This function is common for All Sales Chanel Drop down
 * @date:27-02-2023
 * @author:Lakshmi
 *
 */
function SalesChannel(props) {
    const [saleChanelType,setSaleChanelType] = useState([])
    const [saleChanelList,setSaleChanelList] = useState([])
    const [disable,setDisable] = useState(false)
    const [context, setContext] = useContext(Context);
    const [visaDisable,setVisaDisable] = useState(true)


    useEffect(() => {
      if(props.edit_sales!=="" && props.edit_sales!==undefined){
        setSaleChanelType([{value:props.edit_sales,label:props.edit_sales}])
        setDisable(true)
      }
      if (props.flowType && props.flowType=="visaConfig"){
        setVisaDisable(false)
      }
    }, [props.edit_sales, props.flowType])

    useEffect(()=>{
      if(props.resetChannel){
        setSaleChanelType([])
      }
    },[props.resetChannel])

    useEffect(() => {
      if(context.logindata!==undefined && context.logindata.utype=="att_user" && context.logindata.bothRegions){
        setSaleChanelList([{ label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }])
      }else{
        setSaleChanelList([{ label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }])
        // setSaleChanelList([{value:context.logindata.region_type,label:context.logindata.region_type}])
      }
    }, [])


   const updateSalaeChanel = (e) => {
    setSaleChanelType(e)
    props.sendSalesChanel(e.length!=0?e[0].value:"")
   }

    return (
        <>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="region_type">
        <Select
        options={saleChanelList}
        values={saleChanelType}
        searchable={false}
        disabled={disable}
        onChange={updateSalaeChanel}
        className="selectDis"
        />
        <Form.Label>Sales Channel {visaDisable && <sup>*</sup>}</Form.Label>
       </Form.Group>
        </>
    )
}
export default SalesChannel