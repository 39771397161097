import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from 'react-dropdown-select';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import { categoryDataList, getBranchList } from '../../../../components/adminstration/utils';
import InnerLoader from '../../../../components/common/loader/InnerLoader';
import Breadcrumb from '../../../common/breadcrumb/Breadcrumb';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import MainMenu from '../../../common/MainMenu';
import NoRecord from '../../../common/popUps/NoRecord';
import OrganizationMenu from '../../../common/subMenu/OrganizationMenu';
import { characterValidation, emailValidation } from '../../../commonUtils/FormValidations';
import CompanyCreation from './index';
import { editCompanyDetails, searchCompany } from "./operations";
export const CompanyContext = React.createContext();


/**
 * Initial State Declaration
 */
const initialState = {
  res: [], updateCompany: false, createCompany: false, showCreateBtn: true, companyName: "", compType: [], userType: "", userId: "",
  branchId: [], catId: [], branchList: [], catMenu: [], agencyEmail: "", nameError: false, emailError: false, create: false,
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editCompany':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will Load the all companies from service
 * @author: Lakshmi
 * @param {*}
 * @function CompanyManagement
 */
function CompanyManagement() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [data, setData] = useState()
  const [context, setContext] = useContext(Context);
  const [enableView, setEnableView] = useState(false)
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [enableNew, setEnableNew] = useState(false);

  //This fnction will call when component will load
  useEffect(() => {
    setIsLoadingMore(true)
    if (context.logindata !== "" && context.logindata.roles !== undefined) {
      const pyload = { "type": context.logindata.utype, "userId": context.logindata.uid, fetch: 'mapping' }
      getBranchList(pyload).then(response => {
        if (response.data.res && !response.data.res.includes("Unknown User Type....!")) {
          dispatch({ type: 'branchList', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBranchData function---" + error
        );
      });
      categoryDataList(pyload).then(response => {
        if (response.data.suc) {
          dispatch({ type: 'catMenu', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCategoryData function---" + error
        );
      });

      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      let obj = {
        "type": context.logindata.utype, "region_type":context.logindata.region_type, "userid": context.logindata.uid, "is_admin": context.logindata.is_admin, "comp_id": context.logindata.cid
      }
      searchCompany(obj).then(response => {
        if (response.data.suc) {
          loadAllCompanies(response.data.res);
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getBrnchData function---" + error
        );
      });
    }
  }, [context.logindata]);



  const loadAllCompanies = (res) => {
    let data = [];
    var enableWEdit = false;
    if (context.logindata.roles.includes('C_C_U')) {
      enableWEdit = true;
    }
    for (let value of res) {
      if (context.logindata.is_admin==1 || context.logindata.region_type==value.region_type){
      data.push({sales_channel:value.sales_channel, region_type:value.region_type, cname: value.cn, branch : value.branch, category: value.category, type: value.ctype, status: (value.act) ? "Active" : "In-Active", options: <><span className="tbAction"><a className="ezyIcon icon-view" onClick={editCompany(value, "view")}></a> {enableWEdit && <a className="ezyIcon icon-edit" onClick={editCompany(value, "edit")}></a>}</span> </> })
    }
  }
    setIsLoadingMore(false)
    if (data && data.length !== 0) {
      dispatch({ type: 'res', payload: data })
      setIsLoadingMore(false)
    }
  }

  const columns = [
  {
      dataField: 'region_type',
      text: 'Region Type',
      sort: true,
      filter: textFilter()
    },
  {
      dataField: 'sales_channel',
      text: 'Sales Channel',
      sort: true,
      filter: textFilter()
    },
  {
    dataField: 'cname',
    text: 'Company Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'branch',
    text: 'Branch',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'category',
    text: 'Category',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'type',
    text: 'Company Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const resetSearch = () => {
    dispatch({ type: 'companyName', payload: "" })
    dispatch({ type: 'agencyEmail', payload: "" })
    dispatch({ type: 'compType', payload: [] })
    dispatch({ type: 'branchId', payload: [] })
    dispatch({ type: 'catId', payload: [] })
  }

  const editCompany = (obj, type) => () => {
    editCompanyDetails(obj.id).then(response => {
      if (response.data.suc) {
        setData(response)
        dispatch({ type: 'updateCompany', payload: true })
        dispatch({ type: 'createCompany', payload: false })
        dispatch({ type: 'showCreateBtn', payload: false })
        if (type === "view") {
          setEnableView(true);
        } else {
          setEnableView(false);
        }
      }
    }).catch(function (error) {
      log.error(
        "Exception occured in getBrnchData function---" + error
      );
    });
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handleSearch = () => {
    let namevalidations = false
    let emailvalidations = false
    if (state.companyName.length !== 0) {
      if (characterValidation(state.companyName)) {
        namevalidations = true
        dispatch({ type: "nameError", payload: false })
      } else {
        dispatch({ type: "nameError", payload: true })
      }
    }
    //email validations
    if (state.agencyEmail.length !== 0) {
      if (emailValidation(state.agencyEmail)) {
        emailvalidations = true
        dispatch({ type: "emailError", payload: false })
      } else {
        dispatch({ type: "emailError", payload: true })
      }
    }
    if (state.companyName !== "" || state.agencyEmail !== "" || emailvalidations || namevalidations || state.compType.length > 0 || state.branchId.length > 0 || state.catId.length > 0) {
      const payload = {
        "type": state.userType,
        "userid": state.userId,
        "company_name": state.companyName,
        "company_type": state.compType && state.compType.length > 0 ? state.compType[0].value : "",
        "branch": state.branchId && state.branchId.length > 0 ? state.branchId[0].value : "",
        "category": state.catId && state.catId.length > 0 ? state.catId[0].value : "",
        "email": state.agencyEmail,
        "is_admin": context.logindata.is_admin,
        "comp_id": context.logindata.cid,
        "region_type": context.logindata.region_type
      }
      searchCompany(payload).then((resp) => {
        if (resp.data.suc) {
          loadAllCompanies(resp.data.res);
        }
      })
    } else {
      dispatch({ type: "emailError", payload: false })
      dispatch({ type: "nameError", payload: false })
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select at least one search criteria' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }

  // OnChange event we will call this and update the state
  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
  }

  const agentTypes = [{ label: "Travel Agent", value: "TA" },
  { label: "Sub Travel Agent", value: "STA" }]

  const createCompBtnClick = (e) => {
    dispatch({ type: 'createCompany', payload: true })
    dispatch({ type: 'showCreateBtn', payload: false })
    setEnableNew(true);
  }

  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <MainMenu active='Organization/CM' />
      <Breadcrumb activePage="Company Creation" />
      <div className="container">
        <div className="cardMainlayout pt-0">
          <OrganizationMenu active="Company Creation" />
          <div className="contentArea cmnSearch">
            {state.showCreateBtn &&
              <>

                <Form.Row>
                  <Form.Group as={Col} xs={3} controlId="companyName">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.companyName} onChange={handleChange} />
                    <Form.Label>Company Name</Form.Label>
                    {state.nameError && (
                      <p className="vError">Please Enter Valid Name</p>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={3} controlId="agencyEmail">
                    <Form.Control type="text" required placeholder="Enter Office Name" autoComplete="off" value={state.agencyEmail} onChange={handleChange} />
                    <Form.Label>Agency Email</Form.Label>
                    {state.emailError && (
                      <p className="vError">Please Enter Valid Email</p>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="ctyp">
                    <Select
                      options={agentTypes}
                      searchable={false}
                      values={state.compType}
                      onChange={(value) => (dispatch({ type: 'compType', payload: value }))}
                    />
                    <Form.Label>Company Type </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="bid">
                    <Select
                      options={state.branchList}
                      searchable={false}
                      values={state.branchId}
                      onChange={(value) => (dispatch({ type: 'branchId', payload: value }))}
                    />
                    <Form.Label>Branch</Form.Label>
                  </Form.Group>

                  <Form.Group as={Col} xs={3} className="selectplugin" controlId="cid">
                    <Select
                      options={state.catMenu}
                      searchable={false}
                      values={state.catId}
                      onChange={(value) => (dispatch({ type: 'catId', payload: value }))}
                    />
                    <Form.Label>Category</Form.Label>
                  </Form.Group>
                </Form.Row>

                <div className="text-right pb-0  buttonGrop creat_absolute">
                  <Button
                    size="xs"
                    variant="outline-secondary"
                    type="submit"
                    onClick={resetSearch}
                  > Reset</Button>
                  <Button
                    size="xs"
                    variant="outline-primary"
                    type="submit"
                    onClick={handleSearch}
                  > Search</Button>
                </div>
                {state.showCreateBtn && context.logindata.roles !== undefined && context.logindata.roles.includes('C_C_C') && (
                  <div className="buttonGrop pb-0">
                    <Button
                      size="xs"
                      variant="primary"
                      onClick={createCompBtnClick}
                      loading={state.loadBranch}
                      className="addPlus ezyIcon icon-plus"
                      type="submit"
                    >  Create Company</Button>
                  </div>
                )}
              </>
            }
            {/* {state.createCompany && (
              <CompanyCreation create={state.create}/>
            )} */}
            {(state.updateCompany || state.createCompany) && (
              <CompanyContext.Provider value={data}>
                <CompanyCreation enbleUpdate={enableView} create={enableNew} res={state.res}/>
              </CompanyContext.Provider>
            )}
          </div>
        </div>
        {state.showCreateBtn ? (
          <div className="cardMainlayout tableRes p-0">
            {isLoadingMore ? <InnerLoader /> : (<>
              {state.res.length !== 0 &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                || state.res.length === 0
                && <NoRecord />}</>)}
          </div>

        ) : ('')}
      </div>
      <Footer />

    </>
  )
}
export default CompanyManagement