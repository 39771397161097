import React, { Component, useState,useContext } from 'react';
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";

function DailyReportMenuFun(props) {
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);


return (

        <div className="submenu">
            <ul>
            {context.logindata.roles && context.logindata.roles.includes('T_R_P') &&
              <li  className={state.active==="Transaction" ? 'active':''}>
              <Link
                to="/ezytrip/administration/transaction"
                title="Transaction Report"
              >
               Transaction Report
              </Link>
            </li>
           }
           {context.logindata.roles && context.logindata.roles.includes('R_C_P') &&
            <li  className={state.active==="Receipt" ? 'active':''}>
              <Link
                to="/ezytrip/administration/receipt"
                title="Add Receipt"
              >
               Add Receipt
              </Link>
            </li>
            }
            </ul>
          </div>

  );
};



class DailyReportMenu extends Component {
  constructor(props) {
  super(props);
  console.log(props)
  this.state = {
         active:props.active

      };
  }

  render() {
  return <DailyReportMenuFun state={this.state} />
}
}

export default DailyReportMenu;

