
import React, { useState , useEffect} from 'react';
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import DateUtils from '../../../commonUtils/DateUtils';
import Select from 'react-dropdown-select';
let bookingStatus = require('../../../masterData/BookingStatus..json');

function TransactionSummary(props) {
    const [state, setState] = useState({})
    const [flightSearch, setFlightSearch] = useState();
    const [bkdata , setBkData] = useState(props.bkngdetails);
    const [bookid, setBookid]=  useState({selBooking:[]})
    const [bookingList, setBookingList]=  useState([])

    //setBooking Status
    const setStatus = (data) => {
        props.sendBookStatus(data[0].label)
      }

      useEffect(() => {
        if(props.enableEdit && props.bkngdetails!==undefined){
        let bookingList= bookingStatus.filter((obj) => obj.value!=="34" && obj.value!=="36" && obj.value!=="21" && obj.value!=="31" && obj.value!=="39" && obj.value!=="35" && obj.value!=="25" );
        setBookingList(bookingList)
        for(let val of bookingList){
            if(val.value===bkdata.booking_status){
              setBookid({selBooking : [{ label: val.label, value: val.value }]})
            }
        }
        }
        }, [props.enableEdit])

    return (
        <>
        <div className="table-responsive mobileTable newlayoutDesign">
            <Row>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Booking Ref Number : </span> {props.bkngdetails.booking_id}</Col>
                        {/*   <Col xs={12}><span>Booking Date:</span> 16 Jun, 2020   3:50</Col> */}
                        <Col xs={12}><span>Booking Date : </span> {DateUtils.prettyDate(bkdata.booking_date, "ddd, DD MMM, YYYY")}</Col>
                        <Col xs={12}><span>Email  : </span>{bkdata.email}</Col>
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={12} className="d-flex align-items-center"><span> Booking Status : </span>
                        {!props.enableEdit && <>

                            {bkdata.booking_status_str==="Cancelled" &&
                            <span className="status paxstatus text-danger"> Cancelled</span>
                            }
                            {(bkdata.booking_status_str == "On hold" || bkdata.booking_status_str == "On Hold") &&
                            <span className="status paxstatus"> On hold</span>
                            }
                            {bkdata.booking_status_str === "Confirmed" &&
                            <span className="status paxstatus text-success"> Confirmed</span>
                            }
                             {(bkdata.booking_status_str === "Partially Confirmed") &&
                            <span className="status paxstatus text-success"> Partially Confirmed</span>
                            }
                             {bkdata.booking_status_str === "Incomplete" &&
                            <span className="status paxstatus text-danger"> {bkdata.booking_status_str}</span>
                            }
                             {bkdata.booking_status_str.toUpperCase() === "FAILED" &&
                            <span className="status paxstatus text-danger"> Failed</span>
                            }
                        {/* <span className={bkdata.booking_status_str==="Cancelled"? "text-danger": "text-success"}>&nbsp;{bkdata.booking_status_str} </span> */}
                        {/* {bkdata.booking_status_str} */}
                        </>
                        }
                      {props.enableEdit &&
                      <Form.Group className="selectplugin editOne m-0" as={Col} controlId="firstName">
                       <Select
                         options={bookingList}
                         searchable={false}
                         values={bookid.selBooking}
                         onChange={(value) => (setStatus(value))}
                       />
                      </Form.Group>
                      }
                        </Col>
                        <Col xs={12}><span>Channel : </span> Online</Col>
                        {props.utype!==undefined && props.utype==="att_user" &&
                        <Col xs={12}><span>Supplier  : </span>{bkdata.supplier_name}</Col>
                        }
                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Customer Name : </span>{bkdata.customer_name}</Col>
                        <Col xs={12}><span>Phone  : </span>{bkdata.isd_code} {bkdata.phone_no} </Col>

                    </Row>
                </Col>
                <Col xs={3}>
                    <Row>
                        <Col xs={12}><span>Agency : </span>{bkdata.company_name}</Col>
                        <Col xs={12}><span>Booked By : </span> {bkdata.booked_by}</Col>
                    </Row>
                </Col>
            </Row>

            </div>





        </>
    )
}


export default TransactionSummary;
