import React from 'react';
import AttCommonSerivce from '../../../services/commonService/AttCommonSerivce';

let currencyMaster = require('../../masterData/Currencies.json');
let stdCodesMaster = require('../../masterData/CountrySTDCodes.json');
let titleMaster = require('../../masterData/Title.json');
let mealMaster = require('../../masterData/MealPreference.json');
let specialMaster = require('../../masterData/SpecialAssistance.json');
let seatMaster = require('../../masterData/SeatPreference.json');

/**
 * @description : All Common Utills we are loading here
 * @author : Anil Kumar Ch
 * @date : 13-08-2020
 */

//================================
// Fetch All Master Countries List
//================================
export const countries = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            response.data.res.map((cnt) =>
                countriesList.push({ "value": cnt.id, "label": cnt.name })
            );
        }
    });
    return Promise.resolve(countriesList);
};

//================================
// Fetch All Master Countries List
//================================
export const countriesWitCode = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            countriesList = response.data.res.map((cnt) =>
                <option value={cnt.code}>{cnt.name}</option>
            );
        }

    });
    return Promise.resolve(countriesList);
};

//================================
// Fetch All Master Countries List
//================================
export const countriesWitCodeInsurance = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            countriesList = response.data.res.map((cnt) =>
                <option value={cnt.name}>{cnt.name}</option>
            );
        }

    });
    return Promise.resolve(countriesList);
};

//================================
// Fetch All Master Cities List Based on Country
//================================
export const cities = async (countryId) => {
    let citiesList = [];
    await AttCommonSerivce.getCityList(countryId).then(response => {
        if (response.data.suc) {
            response.data.res.map((cty) =>
                citiesList.push({ "value": cty.id, "label": cty.name })
            );
        }
    });
    return Promise.resolve(citiesList);
};

//================================
// Fetch All Master Currencies
//================================
export const currencies = () => {
    const currenciesList = currencyMaster;
    return currenciesList;
};

//================================
// Fetch All Master STD Codes
//================================
export const stdCodes = () => {
    let stdCodesList = [];
    stdCodesMaster.map((std) =>
        stdCodesList.push({ "value": std.name, "label": std.name })
    );
    return stdCodesList;
};

//================================
// Label fetch after selecting from any dropdown
//================================
export const labelFetch = (e) => {
    return e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
}

//================================
// Fetch All Master Title
//================================
export const titles = () => {
    const titleList = titleMaster.map((title) =>
        <option value={title.value}>{title.label}</option>
    );
    return titleList;
};

//================================
// Fetch All Master Title
//================================
export const titlesBooking = () => {

    return titleMaster;
};

//================================
// Fetch All Master Meal Preferences
//================================
export const mealPrefs = () => {
    const mealList = mealMaster.map((meal) =>
        <option value={meal.code}>{meal.name}</option>
    );
    return mealList;
};

//================================
// Fetch All Master Speacial Preferences
//================================
export const specialPrefs = () => {
    const specialList = specialMaster.map((special) =>
        <option value={special.code}>{special.name}</option>
    );
    return specialList;
};

//================================
// Fetch All Master Seat Preferences
//================================
export const seatPrefs = () => {
    const seatList = seatMaster.map((seat) =>
        <option value={seat.value}>{seat.label}</option>
    );
    return seatList;
};

//================================
// Fetch All Master Seat Preferences
//================================
export const preBookReq = () => {
    const seatList = seatMaster.map((seat) =>
        <option value={seat.value}>{seat.label}</option>
    );
    return seatList;
};


//================================
// Fetch All Branches List
//================================
export const branches = async (obj) => {
    let branchList = [];
    await AttCommonSerivce.getBranchData(obj).then(response => {
        if (response.data.suc) {
            branchList = response.data.res;
        }
    });
    return Promise.resolve(branchList);
};

//================================
// Fetch All Travel Agencies List
//================================
export const talist = async () => {
    let taList = [];
    await AttCommonSerivce.getAllTaList().then(response => {
        if (response.data.suc) {
            taList = response.data.res;
        }
    });
    return Promise.resolve(taList);
};

//================================
// Fetch All Travel Agencies List
//================================
export const stalist = async (id) => {
    let staList = [];
    await AttCommonSerivce.getAllStaList(id).then(response => {
        if (response.data.suc) {
            staList = response.data.res;
        }
    });
    return Promise.resolve(staList);
};

//================================
// Fetch All Master Countries List
//================================
export const countriesMenu = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            countriesList = response;
        }
    });
    return Promise.resolve(countriesList);
};


// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const categoryDataList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getCategoryList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}

//getCustId

export const getCustId = async (id) => {
    let list = [];
    await AttCommonSerivce.getCustId(id).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}
// ========================================================================
// All Branch Data List basedon user type and User id
//  This function update the state values while clicking on Edit
// ========================================================================
export const rolesList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getRolesList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}
export const CRMrolesList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getCRMRolesList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}
export const getSTACompanyList = async (data) => {
    let cateList = [];
    await AttCommonSerivce.getSTACompanyList(data).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}
// ========================================================================
// compnay Data List
//  This function update the state values while clicking on Edit
// ========================================================================
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

export const getCompanyTAList = async (code, obj) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyTAList(code, obj).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}

// ========================================================================
// Get Roles based on company Id
//  This function update the state values while clicking on Edit
// ========================================================================
export const companyRoles = async (id) => {
    let cateList = [];
    await AttCommonSerivce.getCompanyRoles(id).then(response => {
        cateList = response;
    });
    return Promise.resolve(cateList);
}

//================================
// Fetch All Master STD Codes
//================================
export const stdBookingCodes = () => {
    const stdCodesList = stdCodesMaster.map((std) =>
        <option value={std.code}>{std.name}</option>
    );
    return stdCodesList;
};


//================================
// Fetch All Master Countries List
//================================
export const branchCountries = async () => {
    let countriesList = [];
    await AttCommonSerivce.getCountryList().then(response => {
        if (response.data.suc) {
            response.data.res.map((cnt) => (cnt.name==="Qatar" || cnt.name==="India") &&
                countriesList.push({ "value": cnt.id, "label": cnt.name })
            );
        }
    });
    return Promise.resolve(countriesList);
};
