
/**
 * @description: CRUD operations for Deal code
 * @author: Rambabu
 * @param {*}
 * @function Commission
 * @date : 15-10-2020
 */


import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
import DealcodeServices from '../../../../services/administration/DealcodeServices';

/**
 * @description: Fetch compane details based on branch id
 * @author: Rambabu
 * @param {*}
 * @function companyList
 * @date : 15-10-2020
 */
export const getOfficeIdListS = async (payload) => {
    let ofcList = [];
    await AttCommonSerivce.getOfficeIdListS(payload).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
export const fetch_company_list = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.fetch_company_list(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}
export const companyList = async (id) => {
    let company_res = '';
    await AttCommonSerivce.fetchcompany(id).then(response => {
        if (response.data.suc) {
            company_res = response.data.res;
        }
    })
    return Promise.resolve(company_res);
}

export const getBranchList = async (obj) => {
    let branchesList = [];
    await AttCommonSerivce.getBranches(obj).then(response => {
        branchesList = response;
    });
    return Promise.resolve(branchesList);
}

/**
 * @description: Fetch Office id master List 
 * @author: Rambabu
 * @param {*} id
 * @function companyList
 * @date : 15-10-2020
 */
export const officeidList = async (id) => {
    let oofice_res = '';
    await AttCommonSerivce.getOfficeIdList().then(response => {
        if (response.data.suc) {
            oofice_res = response.data.res;
        }
    })
    return Promise.resolve(oofice_res);
}


/**
 * @description: Creation Deal code
 * @author: Rambabu
 * @param {*} obj
 * @function creation
 * @date : 15-10-2020
 */
export const creation = async (obj) => {
    let finalRes = '';
    await DealcodeServices.saveDealcode(obj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}


/**
 * @description: Fetch all Deal codes list
 * @author: Rambabu
 * @param {*}
 * @function getDealcodeList
 * @date : 16-10-2020
 */
export const getDealcodeList = async (data) => {
    let deal_code_res = '';
    await DealcodeServices.fetchDealCodeData(data).then(response => {
        if (response.data.suc) {
            deal_code_res = response.data.res;
        }
    })
    return Promise.resolve(deal_code_res);
}

/**
 * @description: Edit Deal code
 * @author: Rambabu
 * @param {*}
 * @function getDealcodeList
 * @date : 16-10-2020
 */
export const editDealcode = async (id) => {
    //console.log('editDealcode id-----' + id);
    let edit_deal_code_res = '';
    await DealcodeServices.editDealCode(id).then(response => {
        //console.log('editDealcode response-----' + JSON.stringify(response));
        //console.log('response.data.suc-----' + response.data.suc);
        if (response.data.suc) {
            //console.log('response.data.suc-----' + response.data.suc);
            edit_deal_code_res = response.data.res;
        }
    })
    return Promise.resolve(edit_deal_code_res);
}


/**
 * @description: Update Deal code
 * @author: Rambabu
 * @param {*}
 * @function update
 * @date : 19-10-2020
 */
export const update = async (obj) => {
    let updateRes = '';
    await DealcodeServices.updateDealCodeData(obj).then(response => {
        updateRes = response.data;
    });
    return Promise.resolve(updateRes);
}


/**
 * @description: Delete Office id and (company or branch) data 
 * @author: Rambabu
 * @param {*}
 * @function deleteCommissionOfficeidData
 * @date : 19-10-2020
 */
export const deleteDealcodeinventoryData = async (obj) => {
    let delete_res = [];
    await DealcodeServices.deleteDealcodeinventoryChildData(obj).then(response => {
        console.log("Delete Dealcode inventory ChildData res is--- : " + JSON.stringify(response.data.res));
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}


/**
 * @description: Delete RBD data 
 * @author: Rambabu
 * @param {*}
 * @function deleteRBDData
 * @date : 20-10-2020
 */
export const deleteDealcodeRBDData = async (id) => {
    let delete_res = [];
    await DealcodeServices.deleteDealcodeRBDChildData(id).then(response => {
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}


/**
 * @description: Delete Routes data 
 * @author: Rambabu
 * @param {*}
 * @function deleteDealcodeRoutesData
 * @date : 20-10-2020
 */
export const deleteDealcodeRoutesData = async (id) => {
    let delete_res = [];
    await DealcodeServices.deleteDealcodeRoutesChildData(id).then(response => {
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}


/**
 * @description: Delete Applicable data 
 * @author: Rambabu
 * @param {*}
 * @function deleteDealcodeRoutesData
 * @date : 20-10-2020
 */
export const deleteDealcodeApplicableData = async (id) => {
    let delete_res = [];
    await DealcodeServices.deleteDealcodeApplicableChildData(id).then(response => {
        if (response.data.suc) {
            delete_res = response.data.res;
        }
    });
    return Promise.resolve(delete_res);
}


/**
 * @description: Fetch Branch data based on user type and user id
 * @author: Rambabu
 * @param {*}
 * @function companyList
 * @date : 23-10-2020
 */
export const getBranches = async (obj) => {
    let branch_res = '';
    await AttCommonSerivce.getBranches(obj).then(response => {
        if (response.data.suc) {
            branch_res = response.data.res;
        }
    })
    return Promise.resolve(branch_res);
}
export const getCompanyList = async (code) => {
    let ofcList = [];
    await AttCommonSerivce.getCompanyList(code).then(response => {
        ofcList = response;
    });
    return Promise.resolve(ofcList);
}




