import { apiPostHelper } from '../../components/commonUtils/helper'

const DOMAIN =  process.env.REACT_APP_PAYMENT_G_W;

export const purchaseServicePostCall = payload => {
    console.log('DOMAIN----------',DOMAIN)
    console.log("in login:::::retive",JSON.stringify(payload));
    var URL="";
    if(payload.cardtype==="creditcard"){
        URL = `${DOMAIN}/dibsy/create-payment`;
    }else if(payload.cardtype==="naps"){
        URL = `${DOMAIN}/dibsy/naps-payment-create`;
    }
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const response = apiPostHelper(URL, payload, headers);
    return response;
}

export const checkPayment = payload => {
    console.log('DOMAIN----------',DOMAIN)
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const response = apiPostHelper(`${DOMAIN}/dibsy/get-payment`, payload, headers);
    return response;
}