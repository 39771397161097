/**
 * @description: Flight Confirmation page Flight details section
 * @date:09-02-2020
 * @author:Rambabu
 *
 */
 import React from 'react';
 import { Col, Row } from "react-bootstrap";
 import AirlinesMemo from '../../../common/airlines';
 import AirportMemo from '../../../common/airlines/airportNames';
 import DateUtils from '../../../commonUtils/DateUtils';
 import { isNotNull } from '../../../commonUtils/validators';
 import LayoverDetails from './LayoverDetails.jsx';
 function FlightSegmentImportDetails(props) {
     const origin_data = props.origindata[0];
     const type = props.origindata[1];
     const mappings = props.mapping;

     return (
         <>
             <div className="flightRoutInfo mt-3">
                 {origin_data.seg.map((sege, index) => (
                     <>
                         <Row className="align-items-center rs-row mt-3">
                             <Col xs={3} className="rs-rTypeDate">
                                 <div className="airLogo p-0">
                                     <img
                                         src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + sege.ma + '.png'}
                                         className="logo"
                                         alt="AirLine"
                                     />
                                     <div className="flightNo d-flex flex-column">
                                         <strong><AirlinesMemo code={sege.ma} /></strong>
                                         <span>{sege.ma}-{sege.fn}</span>
                                         {/* <span className={"ezyIcon icon-"+ (sege.eq === 'BUS' ? 'bus':'Aircraft')}> Boeing {sege.eq}</span> */}
                                         <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                                     </div>
                                 </div>
                             </Col>
                             <Col xs={(isNotNull(type) && type === 'Confirm') ? '7' : '9'} className="rs-FromTo">
                                 <Row className="align-items-center">
                                     <Col className="d-flex flex-column">
                                         <strong>{DateUtils.prettyDate(sege.dd, "HH:mm")} <small>{DateUtils.prettyDate(sege.dd, "ddd, DD MMM, YYYY")}</small></strong>
                                         <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                                         {sege.terminal !== undefined &&
                                             <small className="trm">Terminal: {sege.terminal.dep}</small>
                                         }
                                     </Col>
                                     <Col className="p-0" xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                     <Col className="d-flex flex-column">
                                         <strong>{DateUtils.prettyDate(sege.ad, "HH:mm")} <small>{DateUtils.prettyDate(sege.ad, "ddd, DD MMM, YYYY")}</small></strong>
                                         <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                                         {sege.terminal !== undefined &&
                                             <small className="trm">Terminal: {sege.terminal.arr}</small>
                                         }
                                     </Col>
                                 </Row>

                                 <span>

                                 </span>
                             </Col>
                             {(isNotNull(type) && type === 'Confirm') &&
                                 <Col xs={2}>
                                     <p><span>Class : </span> {sege.cl!==undefined && sege.cl!=="" && sege.cl ? ( sege.rbd ? sege.cl + "-" + sege.rbd : sege.cl ):(sege.rbd ? sege.rbd : "N/A")}</p>
                                     <p><span>Airline PNR : </span> {sege.airpnr ? sege.airpnr : "N/A"}</p>
                                     <p><span>GDS PNR : </span> {props.pnr!==undefined?props.pnr:"N/A"}</p>
                                 </Col>}

                         </Row>
                         {/* Showing baggage based on the segment level {origin_data} */}
                            {props.confirmation && props.bgd && props.bgd.length > 0 &&
                                <>
                                     <Row>
                                {props.bgd.map((bag, index) => (
                                    <React.Fragment key={index}>
                                {bag.tatoo===sege.tatoo &&
                                <div className="sectBaggageinfo mannuBpnr ezyIcon icon-baggage"> Baggage Info : Cabin 7 kgs, Check-in {bag.qun}  {bag.wt} per Adult & Child </div>
                                }
                                </React.Fragment>
                                ))}
                                </Row>
                                </>
                            }
                         {/* LayoverDetails */}
                         {origin_data.seg.length > 1 &&
                             (isNotNull(origin_data.seg[(index + 1)]))
                             && <LayoverDetails layoverdata={[origin_data.seg[index].ad, origin_data.seg[(index + 1)].dd, sege.ar, mappings]} />}
                     </>
                 ))
                 }


             </div>
         </>
     )
 }

 export default FlightSegmentImportDetails;

