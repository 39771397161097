import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form, Table } from "react-bootstrap";
import Select from 'react-dropdown-select';
import Button from '../../common/buttons/Button';
import Utilities from '../../commonUtils/Utilities';

/**
 * @description:This function will creaet all the routes
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 09-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  appon: "Per Ticket", mrkon: "", mvalue: "", discVal: "", passengerType: true, percentage: "", value: "", paxtype: "", mtyp: [], markupOn: [], showmsg: false
};

let markupArray = [];

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'upprof':
      return Object.assign(state, action.payload);
    case 'clear':
      return {
        ...state, appon: "Per Ticket", mrkon: "", mvalue: "", discVal: "", passengerType: true, percentage: "", value: "", paxtype: "", mtyp: [], markupOn: [], showmsg: false
      }
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ApplicableDates(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [markups, setMarkups] = useState({ showMarkups: [] })
  const [enablePerTickt, setEnablePerTicket] = useState(true)
  const [disableButton, setDisableButton] = useState(false);

  const paxTypes = [{ label: "All", value: "All" },
  { label: "Adult", value: "ADT" }, { label: "Child", value: "CHD" }, { label: "Infant", value: "INF" }]

  const markupOnTypes = [{ label: "Base Fare", value: "BF" },
  { label: "Total Fare", value: "TF" }, { label: "Base Fare+YQ", value: "YQ" }, { label: "Base Fare+YR", value: "YR" }]

  const markupTypes = [{ label: "Percentage", value: "Percentage" },
  { label: "Value", value: "Value" }]


  useEffect(() => {
    if (props.reset) {
      dispatch({ type: 'clear', payload: true })
      markupArray = []
      setMarkups({ showMarkups: [] })
      setEnablePerTicket(true)
      setDisableButton(false)
    }
  }, [props.reset])
  useEffect(() => {
    if (props.disabledBtn) {
      setDisableButton(true);
    }
  }, [props.disabledBtn]);

  useEffect(() => {
    if (props.configType !== "") {
      markupArray = []
    }
  }, [props.configType]);


  useEffect(() => {
    if (props.editPaxData !== undefined) {
      if (props.editPaxData.applon) {
        if (props.editPaxData.applon === "Per Ticket") {
          dispatch({ type: 'appon', payload: "Per Ticket" })
          setEnablePerTicket(true);
        } else {
          setEnablePerTicket(false);
          dispatch({ type: 'appon', payload: "Per Booking" })
        }
      }
      let pax = [];
      for (let val of props.editPaxData.applist) {
        const ap = {
          "id": val.paxid,
          "paxtyp": val.paxtyp,
          "appon": val.appon,
          "mtype": val.mtype,
          "val": val.val,
          "disc": val.disc
        }
        pax.push(ap);
        props.sendPaxData([props.editPaxData.applon, ap]);
      }
      setMarkups({ showMarkups: pax });
      // props.sendPaxData([props.editPaxData.applon,pax]);
    }
  }, [props.editPaxData]);


  const handlePaxMarkup = () => {
    if (validateMarkup()) {
      var paxType = "";
      if (state.paxtype !== undefined && state.paxtype[0] !== undefined && state.paxtype[0].value !== undefined) {
        paxType = state.paxtype[0].value;
      }
      const markup = {
        "paxtyp": paxType,
        "appon": state.markupOn[0].value,
        "mtype": state.mtyp[0].value,
        "val": state.mvalue,
        "disc": state.discVal
      }
      setMarkups({ showMarkups: [...markups.showMarkups, markup] });
      props.sendPaxData([state.appon, markup]);
      dispatch({ type: 'markupOn', payload: [] })
      dispatch({ type: 'mtyp', payload: [] })
      dispatch({ type: 'mvalue', payload: "" })
      dispatch({ type: 'discVal', payload: "" })

    }
  }
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const validateMarkup = () => {
    if (state.mvalue === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(props.configType === "Markup") {
      dispatch({ type: 'notiMessage', payload: 'Please enter Markup Value' })
      } else {
        dispatch({ type: 'notiMessage', payload: 'Please enter ServiceFee Value' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    } else if (state.mtyp === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(props.configType === "Markup") {
      dispatch({ type: 'notiMessage', payload: 'Please select Markup Type' })
      } else {
        dispatch({ type: 'notiMessage', payload: 'Please select ServiceFee Type' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    } else if (state.markupOn === "") {
      dispatch({ type: 'notiMessageShow', payload: true })
      if(props.configType === "Markup") {
      dispatch({ type: 'notiMessage', payload: 'Please select Markup On' })
      } else {
        dispatch({ type: 'notiMessage', payload: 'Please select ServiceFee On' })
      }
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
      return false
    } else if (state.appon === "Per Ticket") {
      if (state.paxtype === "") {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please select Passenger Type' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false
      }
    }
    if (state.appon === "Per Booking") {
      if (markups.showMarkups.length != 0) {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Applicable on Already Defined' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false
      }
    }
    if (state.mtyp[0].value) {
      var json = "";
      if (state.appon === "Per Ticket") {
        json = {
          "paxtyp": state.paxtype[0].value,
          // "appon": state.markupOn[0].value,
        }
      } else {
        json = {
          "appon": state.markupOn[0].value,
          // "mtype": state.mtyp[0].value,
        }
      }
      markupArray.push(json);
      if(props.editPaxData !== undefined) {
        if(props.editPaxData.applon === "Per Ticket") {
          if(props.editPaxData.applist.length > 0 &&  markups.showMarkups.length > 0) {
            for (let val of  markups.showMarkups) {
              let jsontick = {
                "paxtyp": val.paxtyp,
                // "appon": val.appon,
              }
              markupArray.push(jsontick);
            }

          }
        } else {
          if(props.editPaxData.applist.length > 0 &&  markups.showMarkups.length > 0) {
            for (let val of  markups.showMarkups) {
             let jsontick = {
                "appon": val.appon,
                // "mtype": val.mtype,
              }
              markupArray.push(jsontick);

            }
          }
        }
      }

      let data = Utilities.getUniqData(markupArray);
      if (data && state.appon === "Per Ticket") {
        var pax = data.map(q => q.paxtyp !== "" && q.paxtyp !== "All");
        if (pax.length >= 2) {
          for (let val of pax) {
            if (!val) {
              let arr = [];
              markupArray = arr
              if(markups.showMarkups.length > 0) {
              for(let val of markups.showMarkups) {
                if(state.appon === "Per Ticket") {
                  json = {
                    "paxtyp": val.paxtyp,
                    // "appon": val.appon,
                  }
                } else {
                  json = {
                    "appon": val.appon,
                    // "mtype": val.mtype,
                  }
                }
                arr.push(json)
              }
            }
            markupArray = arr;
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Passenger Type Already Exists' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage()
              return false;
            }
          }
        }

      if (markupArray.length !== data.length) {
        let arr = [];
        markupArray = arr
        if(markups.showMarkups.length > 0) {
        for(let val of markups.showMarkups) {
          if(state.appon === "Per Ticket") {
            json = {
              "paxtyp": val.paxtyp,
              // "appon": val.appon,
            }
          } else {
            json = {
              "appon": val.appon,
              // "mtype": val.mtype,
            }
          }
          arr.push(json)
        }
      }

        markupArray = arr;
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Passenger Type Already Exists' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage()
        return false;
      }
    }
    }

    return true;
  }



  const handleRemoveMarkup = (obj, idx) => () => {
    if (obj !== undefined && obj.id !== undefined) {
      props.sendAppMarkupId(obj.id);
      if (state.appon === "Per Ticket") {
      markupArray = markupArray.filter(item => item.paxtyp !== undefined && item.paxtyp !== obj.paxtyp);
      } else {
        markupArray = markupArray.filter(item => item.appon !== undefined && item.appon !== obj.appon);
      }
      let afterdeleteArr = markups.showMarkups.filter((s, sidx) => idx !== sidx)
      setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => s.id !== obj.id) });
      props.updateData([state.appon, afterdeleteArr]);
    } else {
      setMarkups({ showMarkups: markups.showMarkups.filter((s, sidx) => idx !== sidx) });
      if (state.appon === "Per Ticket") {
        markupArray = markupArray.filter(item => item.paxtyp !== undefined && item.paxtyp !== obj.paxtyp);
        props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => idx !== sidx)]);
      } else {
        markupArray = markupArray.filter(item => item.appon !== undefined && item.appon !== obj.appon);
        props.updateData([state.appon, markups.showMarkups.filter((s, sidx) => idx !== sidx)]);
      }
    }
  };


  const handlePerChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }


  const handleOnChange = (e) => {
    const { id, value } = e.target
    dispatch({ type: id, payload: value });
  }
  const handleMarPType = () => {
    dispatch({ type: 'percentage', payload: "" })
    dispatch({ type: 'enablePercen', payload: false })
  };

  const perTicket = (e) => {
    dispatch({ type: 'passengerType', payload: true })
    dispatch({ type: 'appon', payload: "Per Ticket" })
    dispatch({ type: 'mvalue', payload: "" })
    dispatch({ type: 'discVal', payload: "" })
    dispatch({ type: 'markupOn', payload: [] })
    dispatch({ type: 'mtyp', payload: [] })
    setMarkups({ showMarkups: [] })
    setEnablePerTicket(true);
    markupArray = [];
    props.updateData(["Per Booking", []]);
  }
  const perBooking = (e) => {
    setEnablePerTicket(false);
    dispatch({ type: 'passengerType', payload: false })
    dispatch({ type: 'appon', payload: "Per Booking" })
    dispatch({ type: 'markupOn', payload: [] })
    dispatch({ type: 'mtyp', payload: [] })
    dispatch({ type: 'mvalue', payload: "" })
    dispatch({ type: 'discVal', payload: "" })
    dispatch({ type: 'paxtype', payload: "" })
    setMarkups({ showMarkups: [] })
    markupArray = [];
    props.updateData(["Per Ticket", []]);
  }


  return (
    <>
      {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }
      <Form.Row>
        <Form.Group as={Col} xs={3} controlId="appon" className="inputCheck">
          <div className="d-flex align-items-center">
            <Form.Check type="radio" disabled={props.editPaxData && markups.showMarkups.length > 0} onChange={perTicket} checked={enablePerTickt} value={state.appon} label="Per Ticket" className="ml-0" name="ApplicableOn" id="perTicket" custom />
            <Form.Check type="radio" disabled={props.editPaxData && markups.showMarkups.length > 0} onChange={perBooking} checked={!enablePerTickt} value={state.appon} label="Per Booking" className="ml-3" name="ApplicableOn" id="perBooking" custom />
          </div>
          <Form.Label>Applicable On <sup>*</sup></Form.Label>

        </Form.Group>
      </Form.Row>
      <Form.Row>
        {state.passengerType && state.appon === "Per Ticket" &&
          <Form.Group as={Col} xs={2} className="selectplugin" controlId="paxtype">
            <Select
              options={paxTypes}
              searchable={false}
              values={[]}
              onChange={(value) => (dispatch({ type: 'paxtype', payload: value }))}
            />
            <Form.Label>Passenger Type<sup>*</sup></Form.Label>
          </Form.Group>
        }

        <Form.Group as={Col} xs={2} className="selectplugin" controlId="markupOn">
          <Select
            options={markupOnTypes}
            searchable={false}
            values={state.markupOn}
            onChange={(value) => (dispatch({ type: 'markupOn', payload: value }))}
          />
          {props.configType == "Markup" ? (<Form.Label>Mark-up On<sup>*</sup></Form.Label>) : (<Form.Label>Service Fee On<sup>*</sup></Form.Label>)}

        </Form.Group>

        <Form.Group as={Col} xs={2} className="selectplugin" controlId="mtyp">
          <Select
            options={markupTypes}
            searchable={false}
            values={state.mtyp}
            onChange={(value) => (dispatch({ type: 'mtyp', payload: value }))}
          />
          {props.configType == "Markup" ? (<Form.Label>Markup Type<sup>*</sup></Form.Label>) : (<Form.Label>ServiceFee Type<sup>*</sup></Form.Label>)}

        </Form.Group>

        <Form.Group as={Col} xs={2} controlId="mvalue" className="inputCheck" className="d-flex align-items-center">
          <Form.Control type="number" min="0" placeholder= {props.configType == "Markup" ? "Markup":"Value"} value={state.mvalue} onChange={handleOnChange} />
          {props.configType == "Markup" ? (<Form.Label>Markup<sup>*</sup></Form.Label>) : (<Form.Label>Value<sup>*</sup></Form.Label>)}

        </Form.Group>

        {props.configType == "Markup" &&
          <Form.Group as={Col} xs={2} controlId="discVal" className="inputCheck" className="d-flex align-items-center">
            <Form.Control type="text" placeholder="Discount" value={state.discVal} onChange={handleOnChange} />
            <Form.Label>Discount </Form.Label>
          </Form.Group>}
        <Col>
          {!disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              className="mb-4"
              onClick={handlePaxMarkup}
            > Add</Button>
          }
          {disableButton &&
            <Button
              size="xs"
              variant="outline-primary"
              isDisabled={true}
            > Add</Button>
          }
        </Col>
      </Form.Row>
      {markups.showMarkups && markups.showMarkups.length !== 0 &&
        <div className="selectedRout tableLayout mb-4">
          <Table>
            <thead>
              <tr>
                {state.appon === "Per Ticket" &&
                  <th>Pax Type</th>
                }
                {props.configType == "Markup" ? (<th>Markup On</th>) : (<th>ServiceFee On</th>)}
                {props.configType == "Markup" ? (<th>Markup Type</th>) : (<th>ServiceFee type</th>)}
                {props.configType == "Markup" ? (<th>Markup Value</th>) : (<th>ServiceFee Value</th>)}
                {state.disc !== "" && props.configType == "Markup" &&
                  <th>Discount Value</th>
                }
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {markups.showMarkups.map((markup, index) => (
                <React.Fragment key={index}>
                  <tr>
                    {state.appon === "Per Ticket" &&
                      <td>
                        {markup.paxtyp === "All" &&
                          <span>All</span>
                        }
                        {markup.paxtyp === "ADT" &&
                          <span>Adult</span>
                        }
                        {markup.paxtyp === "CHD" &&
                          <span>Child</span>
                        }
                        {markup.paxtyp === "INF" &&
                          <span>Infant</span>
                        }
                      </td>
                    }
                    <td>
                      {markup.appon === "BF" &&
                        <span>Base Fare</span>
                      }
                      {markup.appon === "TF" &&
                        <span>TotalFare</span>
                      }
                      {markup.appon !== "TF" && markup.appon !== "BF" &&
                        <span>{markup.appon  === "YQ" ? "Base Fare + YQ" : markup.appon  === "YR" ? "Base Fare + YR" : ""}</span>
                      }
                    </td>
                    <td>
                      <span>{markup.mtype}</span>
                    </td>
                    <td>
                      <span>{markup.val}</span>
                    </td>
                    {state.disc !== "" && props.configType == "Markup" &&
                      <td>
                        <span>{markup.disc}</span>
                      </td>
                    }
                      <td>
                        <Button
                          size="xs"
                          isDisabled={(props.disabledBtn) ? true : false}
                          onClick={handleRemoveMarkup(markup, index)}
                        variant="link" className="ezyIcon icon-delete">
                        </Button>
                      </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      }





      {state.enablePercen &&
        <Form.Row>
          <Form.Group as={Col} xs={3} controlId="mtyp">
            <Form.Control as="select">
              <option value="Percentage">Percentage</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs={3} controlId="per" className="d-flex align-items-center">
            <Form.Control type="text" placeholder="Enter Percentage" value={state.percentage} onChange={handlePerChange} />
            <Form.Label>Enter Value</Form.Label>
          </Form.Group>
          <Col>
            <Button
              size="xs"
              variant="link"
              onClick={handleMarPType}
              className="iconBtn add ezyIcon icon-delete align-self-end mb-4"
            ></Button>
          </Col>
        </Form.Row>
      }

    </>
  )
}
export default ApplicableDates