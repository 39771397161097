
import React, { useState } from 'react';
import Footer from '../../../../common/Footer';
import Header from '../../../../common/Header';
function AgentTaC(props) {
    const [state, setState] = useState({})

    return (
        <>
<Header />
<div className="container">
            <div className="boxShadow bg-white Policies agentTaC mb-4">
                <h2 className="title">Agent terms of use</h2>
                <p>These Agent Terms of Use (these Terms) govern the terms upon which you market Hotel Information and undertake Bookings for End Customers through the Approved Platforms.</p>
                <p>Please read these Terms. Defined terms are indicated by capitalization, and where these terms are not defined in the text, they are defined at the end of these Terms. By offering Hotel Information and undertaking Bookings for End Customers through the Approved Platform you agree, acknowledge and represent to us that:</p>
                <ol>
                    <li>Your representative has read and understood these Terms on your behalf;</li>
                    <li>You agree to be bound by these Terms without modification in relation to your access to the Hotel Information through the Approved Platform and the offer to undertake Bookings for End Customers</li>
                    <li>Your representative entering into the agreement created by acceptance of these Terms has the power, capacity and authority to enter into these Terms and to bind you.</li>
                </ol>
                <h5>Agent Obligations</h5>
                <ol>
                    <li>Agent Marketing. During the Term, you shall actively market and promote the Hotel Information and the undertaking of Bookings to End Customers.</li>
                    <li>Content. You will not change, amend or misrepresent any of the information, content or data relating to or in connection with the Hotel Information that is provided to you through the Approved Platforms (Content).</li>
                    <li>Access to the Hotel Information and Approved Platform. You must:
                        <ol type="I">
                            <li>
                                only: (i) access the Hotel Information through the Approved Platform; and (ii) procure Bookings; in a manner consistent with the Code of Conduct in Section 2 of these Terms, EPS’s policies and rules and our or EPS’s instructions as notified to you from time to time;
                            </li>
                            <li>only input the End Customer’s data in the Approved Platform and solely for the purpose of procuring Bookings; </li>
                            <li>
                                ensure that the End Customer’s data and any other information submitted to the Approved Platform is complete and accurate.
                            </li>

                        </ol>
                    </li>
                    <li>
                        Customer Contracts. Prior to the completion of a Booking, you must:
                         <ol type="I">
                            <li>
                                make clear to End Customers that Travelscape, LLC or Vacationspot, SL (or any other Group Member of EPS) shall be treated by any tax authority in any pertinent jurisdiction as the supplier to the End Customer of E-Collect Bookings and accommodation component of a Package for VAT (Value Added Tax) purposes as applied by Directive 2006/112/EC;
                             </li>
                            <li>
                                not display or make any statement which expressly or by implication contradicts Section 1.4(a) of these Terms;
                            </li>
                            <li>
                                ensure that the End Customer has read and agrees to be bound by the End Customer Terms and Conditions;
                            </li>
                            <li>
                                ensure that the End Customer is aware of any limitations or restrictions that apply to their Booking, including whether the End Customer is allowed to make any cancellations or changes to their Booking and the consequences of the same;
                            </li>
                            <li>
                                not make any verbal or written assurances to an End Customer which are additional to or are contrary to: (i) these Terms; or (ii) the End Customer Terms and Conditions; which includes promising an End Customer that their special request will be met; and
                            </li>
                            <li><p>not cancel or amend any Bookings made by Agents except on the express instruction of the End Customer.</p>
                                <p>You shall be solely responsible and liable to use in respect of any representations or special requests made or confirmed to the Customer without our prior written authorisation and approval.</p>
                            </li>

                        </ol>
                    </li>
                    <li>Customer Communications and Complaints.
                        <ol type="I">
                            <li>You shall within 24 hours of receipt: (i) provide to the End Customer, without amendment or modification, all information related to a Booking; and (ii) provide to us, without amendment or modification: (1) all communications received related to a Booking; (2) any complaints received from End Customers; and (3) any communications received from Trading Standards or any other regulatory trade body which relate to an End Customer and/or any Booking.</li>
                            <li>
                                You shall notify us within 24 hours if you receive any End Customer complaint, claim or action regarding a Booking (each an End Customer Issue). You acknowledge and agree that we shall have the right to attempt to settle any End Customer Issue directly with the End Customer. If we attempt such a settlement, you must, at your expense, provide us with all information and reasonable assistance we require to reach settlement and you shall refrain from taking any other action in respect of the End Customer Issue; unless required to do so by Applicable Law.
                            </li>
                            <li>
                                In order for us to support End Customers in the event that the accommodation supplier comprising the Hotel Information is not able to honour any Booking, you: (a) will continue to provide us with your up to date contact details for us to notify you of any relocations; (b) will cooperate with us to facilitate a relocation, including promptly liaising with End Customers; (c) agree that relocations will be in accordance with EPS’s standard process from time to time.
                            </li>
                        </ol>
                    </li>
                    <li> Compliance with Laws. You shall comply with all applicable laws in force from time to time relevant to the performance of your obligations under these Terms including, without limitation, any legislation relating to the licensing of travel agents (Applicable Laws).</li>
                    <li>
                        Compliance with Laws. You shall comply with all applicable laws in force from time to time relevant to the performance of your obligations under these Terms including, without limitation, any legislation relating to the licensing of travel agents (Applicable Laws).
                    </li>
                    <li>
                        Package Bookings. Provided we give our written consent to you (directly or indirectly), certain Hotel Information that we specify from time to time (Package Rates) may be made available to you for booking as part of a Package. We may cease to offer Package Rates at any time by providing you (directly or indirectly) with no less than seven (7) days’ written notice.
                        <ol type="I">
                            <li>
                                <p>Booking of Package Rates. You shall: (a) not book, nor make available or offer Package Rates except as part of a Package; (b) ensure that the final booking price for a Package Rate is equal to the rate we provide for such Package Rate (and you acknowledge that you remain responsible for the final price of the Package); (c) not display or otherwise communicate separate pricing of Package Rates to End Customers  at any time during the booking or confirmation processes; (d) ensure the Package Rate can only be applied in relation to the same trip to which the Approved Transport Component within the Package relates (including having no more rooms booked than the number of people to which the Approved Transport Component relates); and (e) retain proof of the inclusion of the Approved Transport Component within the Package for at least 30 days after the Booking is made. </p>
                                <p>
                                    We may from time to time require you at your own cost to demonstrate your ongoing compliance with this Section 1.7(a). You shall upon our written request, send copies of booking confirmations, booking details and give access to such other information, systems and/or documentation as is reasonably necessary to demonstrate your compliance with this Section 1.7(a).
                                </p>
                                <p>
                                    Without prejudice to any other rights or remedies available to us or our Group Members, if you are in breach of this Section 1.7(a), we may restrict access to Package Rates with immediate effect and may terminate these Terms.
                                </p>
                            </li>
                            <li>
                                <p>Compliance with Law. You undertake and warrant that you shall be responsible for obtaining and maintaining all licences, consents and other permissions (each, if any and whether regulatory or otherwise) and all financial security arrangements necessary for the performance of obligations under these Terms in respect of bookings of Packages and/or linked travel arrangements provided and/or arranged by you. You will comply with all applicable laws (including European Council Directive 90/314/EEC European Directive (EU) 2015/2302 and all relevant local implementations of it) and any other current or future laws relating to package travel or linked travel arrangements in any jurisdiction.. You shall co-operate as necessary to provide the necessary information allowing us each to discharge our obligations with regard to any packaged travel product or linked travel arrangement. </p>
                                <p>You agree to provide all necessary assistance and support in the event of an enquiry, request for information or investigation into the making available of Packages and/or linked travel arrangements by any national authority, consumer body or other interested party.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Agent Booking Fee.  If you add a booking fee to the total amount charged to the End Customer, you shall ensure:
                        <ol type="I">
                            <li>
                                the booking fee is not aggregated into the room rates, taxes, or service fees provided in the Approved Platform,
                            </li>
                            <li>
                                you make it clear to the End Customer that the booking fee is applied by you only;
                            </li>
                            <li>
                                you include the following text in your booking terms and conditions in respect of the Booking: “The total price may include an additional booking fee from [INSERT YOUR NAME]”,
                             </li>
                            <li>
                                you do not include the booking fee in any supplier selection algorithm or sort order logic.
                         </li>
                        </ol>
                    </li>
                </ol>
                <h5>CODE OF CONDUCT</h5>
                <ol>
                    <li>
                        You shall not send unsolicited bulk email, “spam” or otherwise engage in any other unethical or illegal marketing activities (as determined by us or EPS, acting reasonably) concerning End Customers in any jurisdiction.
                     </li>
                    <li>
                        You shall not:
                        <ol type="I">
                            <li>
                                place any material on any customer facing website or associate Hotel Information with any material or opinions that are illegal in any jurisdiction or are otherwise discriminatory, promote or incite violence, hatred or an illegal activity, are capable of interpretation as discriminatory or of promoting such views, or are inappropriate for general or family viewing (e.g. sexually explicit materials);
                             </li>
                            <li>
                                mislead or misrepresent to consumers as to the origin, affiliation or nature of your websites, products or services;
                            </li>
                            <li>
                                sell, redistribute, display or use in any context or manner (directly or indirectly), any data or materials from EPS or its Group Members’ websites (including any Content);
                            </li>
                            <li>
                                sell, redistribute, display or use any data, materials or other content from any website owned or operated by EPS or our Group Members other than in accordance with the terms of this Agreement.
                            </li>
                            <li>
                                use EPS trademarks or those of its Group Members (including any misspelling or substantially similar or confusingly similar version thereof), in any manner whatsoever (including without limitation, in any domain or sub-domain name, any other online/offline marketing or advertising, press releases, etc.) without first obtaining EPS's prior written approval;
                            </li>
                        </ol>
                    </li>
                    <li>
                        Predatory Advertising. You will not use any predatory advertising methods. Predatory advertising means any method that creates or overlays links or banners on websites, mobile devices, social media or any other channel which allows access to Hotel Information and Content (each a channel), spawns browser windows, or any method invented to generate traffic from a channel without that channel owner’s knowledge, permission, and participation.
                     </li>
                    <li>
                        Keyword Advertising. With respect to any “pay for sponsored advertising placement” keyword purchase program offered by an internet search engine: neither you, nor your Group Members, shall bid on any of our marks that are listed in Annex 1 (or any misspellings of such trademarks). (or any regional variants), for preferential placement in any cost per click search engine or other search engine in which search result page listing order is determined by payment to the search engine or other third party, and will not use any such names in keyword meta tags on any pages of the approved websites or any other websites or channels you own or operate.  If requested, you shall promptly cease bidding on or otherwise using any name or trademark of any accommodation supplier comprising Hotel Information, or to register a negative keyword of such supplier, then you will, at our request promptly cease the use or bidding in question upon such name or names, or register such negative keyword.
                    </li>
                    <li>
                        You shall not represent yourself as acting on behalf of EPS or any of its Group Members.
                    </li>
                    <li>
                        You shall not misrepresent who you are acting for when contacting End Customers including (as an example only) leading End Customers to believe that you are directly connected to any of the accommodation suppliers comprising Hotel Information.
                    </li>
                    <li>
                        You acknowledge and agree that Travelscape, LLC or Vacationspot, SL (or any other Group Member EPS designates) shall be treated by any Tax authority in any pertinent jurisdiction as the supplier to the End Customer of E-Collect Bookings and the accommodation component of a Package for VAT (Value Added Tax) purposes as applied by Directive 2006/112/EC
                     </li>
                    <li>
                        You shall not directly contact any of the accommodation suppliers comprising Hotel Information in relation to Hotel Information or Bookings. Any direct communications from such accommodation suppliers which you receive should be referred immediately to us.
                    </li>
                    <li>
                        Any advertising and promotional materials used in connection with the Hotel Information shall be provided by or expressly approved in writing by us.
                    </li>
                    <li>
                        You shall notify us of any and all customer complaints made to you relating to the VAT treatment of E-Collect Bookings, and must not attempt to deal with such complaints yourself. You shall also refer to us any enquiries from tax authorities or any other government agencies in relation to these Terms or the E-Collect Bookings, and shall provide us with the opportunity to review and/or contribute to any response to such enquiries.
                    </li>
                    <li>
                        You shall not misrepresent any of the terms of the contract relevant to services being provided under these Terms, including product descriptions.
                    </li>
                    <li>
                        You acknowledge that Bookings of more than 8 rooms with the same accommodation supplier for the same stay dates (a Group Booking) may not be made by you or End Customers. In the event you, your Agent or an End Customer requires a Group Booking, you will notify us and follow our relevant process. Any Group Booking made in breach of this clause may be cancelled by us and any applicable cancellation fees will be applied.
                    </li>
                </ol>
                <h5>Term and termination</h5>
                <ol>
                    <li>
                    These Terms shall commence on the earlier of: (i) your acceptance of these Terms; or (ii) your first Booking; and subject to earlier termination in accordance with Section 3.2, shall continue until expiry or termination of our relationship with you or EPS’s relationship with us (Term).
                    </li>
                    <li>
                    We in our sole discretion and without cause may terminate these Terms immediately upon written notice for any reason. You may terminate these Terms on thirty (30) days’ written notice to us. The rights and remedies provided in this Section 3.2 are not exclusive and are in addition to any other rights and remedies provided by law or these Terms.
                    </li>
                    <li>
                    Upon termination or expiration of these Terms for any reason: (i) you shall immediately cease procuring Bookings; and (ii) we may immediately shut down your account. Sections 3.3, 4, 5, and 6 shall survive any expiration or termination of these Terms.
                    </li>
                </ol>
                <h5>Confidentiality; media communications, data protection</h5>
                <ol>
                    <li>
                    Confidentiality
                        <ol type="I">
                            <li>
                            For the purpose of this clause, Confidential Information means any and all know-how, documentation and information, whether commercial, financial, technical, operational or otherwise, relating to the business, finances, affairs, tools (including those provided on a technology), products, services, personnel, customers, suppliers (including precincts, future and potential personnel, customers and suppliers), prices, commissions, rates, vendors, processes, or methods of one party or its Group Members (and EPS or its Group Members), which is disclosed to or otherwise obtained by the other party in connection with these Terms and the existence, terms and subject matter of these Terms.
                            </li>
                            <li>
                            Both you and we  (each a Party and together the Parties) shall keep the other's Confidential Information confidential and shall not divulge the same to any third party, except EPS or EPS’s Group Members, unless divulging for the purposes of these Terms, or use it itself for any other purpose without the prior written consent of the other Party.
                            </li>
                            <li>
                            Section 4.1(b) shall not apply to any Confidential Information that the receiving Party can show: (i) is in the public domain in substantially the same combination as that in which it was disclosed to the receiving Party other than as a result of a breach of these Terms or any other obligations of confidentiality; (ii) is or was lawfully received from a third party not under an obligation of confidentiality with respect thereto; (iii) is required to be disclosed under operation of law, by court order or by any regulatory body of competent jurisdiction (but then only to the extent and for the purpose required); (iv) is approved for disclosure in writing; or (v) was developed independently of and without reference to Confidential Information disclosed by the other Party; provided that a particular disclosed or discovered use, combination, analysis, form or collection of information will not be in the public domain simply because it could be re-created using information in the public domain.
                            </li>
                            <li>
                            Each Party shall give the other as much notice of any disclosure required under Section 4.1(c)as is reasonable and lawful in the circumstances (if any) and shall provide the other with reasonable assistance in avoiding or limiting the required disclosure.
                            </li>
                            <li>
                            Each Party shall be entitled to divulge the other Party's Confidential Information to its employees agents, directors, officers, authorised sub-contractors, professional advisors and consultants who have a need to know the same in connection with these Terms provided that the receiving Party shall ensure that such persons are aware of and, shall procure that such persons comply with, these obligations as to confidentiality.
                            </li>
                        </ol>
                    </li>
                    <li>
                    Media Communications. You shall not release to the public any press release or other communication to the press and/or public regarding these Terms without our prior written consent.
                    </li>
                    <li>
                        <p>Data Protection. For the purposes of Section 4.3, the following terms will have the following meanings:</p>
                        <p>
                            Applicable Data Protection Law(s) means all data protection and privacy that apply to End Customer Data processed under this Agreement (including, where applicable, EU Data Protection Laws);
                        </p>
                        <p>
                        End Customer Data means any personal data relating to an End Customer who views, enquires about, books or has stayed at a hotel in accordance with their Booking that is processed pursuant to these Terms.
                        </p>
                        <p>
                        EU Data Protection Laws means: (i) prior to 25 May 2018, EU Directives 95/46/EC and 2002/58/EC (each as amended), and any applicable national implementations of them; and (ii) on and after 25 May 2018, EU Regulation 2016/679 (the General Data Protection Regulation) and any applicable national legislation made under or in connection with the General Data Protection Regulation; and
                        </p>
                        <p>
                        Security Incident means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.
                        </p>
                        <ol type="I">
                            <li>
                            You warrant that you have complied with and will comply with all Applicable Data Protection Law(s).You will ensure that you display a compliant and accurate privacy policy and cookie notice (if required) on your customer facing websites.
                            </li>
                            <li>
                            You shall:
                            <ol type="a">
                                <li>
                                maintain appropriate technical and organizational measures to protect the End Customer Data against any Security Incident;
                                </li>
                                <li>
                                in the event of a confirmed Security Incident, promptly notify us if the Security Incident affects End Customer Data, providing full details of the same. In such event, both parties shall cooperate reasonably and in good faith to remedy or mitigate the effects of the Security Incident, and the reasonable costs of such cooperation shall be borne by the party that suffered the Security Incident.
                                </li>
                            </ol>
                            </li>
                            <li>
                            You warrant and represent that you will:
                            <ol type="a">
                                <li>
                                only obtain, use, transmit and store End Customer cardholder data to the extent required to comply with your obligations under this Agreement;
                                </li>
                                <li>
                                provide us with a copy of your annual certification of compliance;
                                </li>
                                <li>
                                promptly notify us of any breach of the PCI DSS or any other Security Incident affecting End Customer cardholder data.
                                </li>
                            </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
                <h5>Compliance</h5>
                <p>
                We or EPS shall be entitled to verify your performance under these Terms at any time upon reasonable notice (save where we or EPS (acting reasonably) suspect a material breach of these Terms, fraud and/or a breach of law in which case there shall be no requirement to give prior notice). You shall permit and/or shall procure that we or EPS are permitted access to such of your and your sub-contractors' and agents' records, systems and information as we or EPS may require for the purpose of such verification.
                </p>
                <p>
                Without prejudice to the foregoing, upon request you will promptly provide us or EPS with copies of such records, accounts and information as we or EPS require to verify your performance or compliance under these Terms.
                </p>
                <h5>Disclaimer</h5>
                <p>
                You agree that eps makes no representations or warranties with regard to the hotel information. You agree that eps expressly disclaims all implied warranties, obligations and liabilities arising by law or otherwise, with respect to the hotel information, including without limitation any:
                </p>
                <ol type="I">
                    <li>
                    implied warranty of merchantability, satisfactory quality or fitness for a particular purpose;
                    </li>
                    <li>
                    implied warranty arising from course of performance, course of dealing or usage of trade;
                    </li>
                    <li>
                    implied warranty of non-infringement.
                    </li>
                </ol>
                <p>
                You agree that eps will have no liability to you or any end customers relating to:
                </p>
                <ol type="a">
                    <li>
                    Any failure of the systems of us or any third party that results in the failure or inability to process a booking;
                    </li>
                    <li>
                    The quality of the hotel information provided by travel suppliers to end customers.
                    </li>
                </ol>
                <h5>General</h5>
                <ol>
                    <li>
                    Governing Law and Dispute Resolution. These Terms and all rights and obligations hereunder, including matters of construction, validity, performance and dispute resolution, shall be governed by and construed in accordance with the substantive laws of England and Wales without regard to any conflict of laws, rules or principles that may require the application of any other law or where these Terms or any amendment to it is actually executed. Any dispute, controversy or claim arising out of or in relation to these Terms or at law, or the breach, termination or invalidity thereof, that cannot be settled amicably by agreement between the Parties, shall be finally settled by the Courts of England and Wales, and the Parties submit to the exclusive jurisdiction of those courts.
                    </li>
                    <li>
                    Economic Sanctions. You warrants and represents on a continuing basis that it and its Group Members are not incorporated in or resident in a country, or listed as a "Specially Designated National," a "Specially Designated Global Terrorist," a "Blocked Person," or similar designation under the economic or trade sanctions imposed by the by the U.S. Treasury Department's Office of Foreign Assets Control (OFAC), the United Nations Security Council, the European Union and her Majesty’s Treasury (the Economic Sanctions, and any relevant individual being a Restricted Person). You will not act in such a way so as to bring us or EAN into breach of any Economic Sanctions (including marketing to Restricted Persons), and will notify us immediately in respect of any breach of this clause. You warrant that you will be liable for any act made by you or your Agent that would otherwise threaten to bring us into breach of any Economic Sanctions, and agree that any breach of this clause shall be deemed a material breach not capable of remedy and we may immediately terminate this Agreement in such an event.
                    </li>
                    <li>
                    Modification. We may make changes to this Agreement from time to time.  We will notify you of any changes and provide you with a copy of the revised terms by email or such other means as may have been agreed between the parties.  If you do not agree with the changes, you must notify us by email within 30 days of the date of (i) successful dispatch of our email or (ii) receipt of our notice if sent by any other method.  If you fail to do so within this time period you shall be deemed to have accepted the changes and the changes will come into effect on the date set out in the revised terms.  If you notify us that you object, either Party may terminate this Agreement in accordance with Section 3.2.
                    </li>
                    <li>
                    Force Majeure. Neither Party shall be liable to the other for any failure or delay in the performance of its obligations under these Terms (save in respect of any obligation to pay any monies due) to the extent that such failure or delay arises due to reasons beyond such Party's reasonable control which that Party is unable to reasonably avoid or provide against provided always that the affected Party promptly notifies the other of the cause and likely duration of the failure or delay and takes all reasonable steps, including implementation of its business continuity and disaster recovery plan to overcome the failure or delay as soon as possible.
                    </li>
                    <li>
                    Non-waiver. No waiver of any term, condition or obligation of these Terms will be valid. No failure or delay by any Party at any time to enforce one or more of the terms, conditions or obligations of these Terms will: (a) constitute waiver of such term, condition or obligation; (b) preclude such Party from requiring performance by the other Party at any later time; or (c) be deemed to be a waiver of any other subsequent term, condition or obligation, whether of like or different nature.
                    </li>
                    <li>
                    Assignment. These Terms may not be assigned by either Party without the prior written consent of the other Party not to be unreasonably withheld.
                    </li>
                    <li>
                    Severability. These Terms will be enforced to the fullest extent permitted by Applicable Law. If any provision of these Terms is held to be invalid or unenforceable, then such provision will be interpreted, construed or reformed to the extent reasonably required to render the same valid, enforceable and consistent with the original intent underlying such provision.
                    </li>
                    <li>
                        <p>Entire Agreement. These Terms constitute the entire and exclusive agreement between the Parties regarding the subject matter hereof, and supersede all prior or contemporaneous oral or written agreements and understandings; provided nothing in this Section 7.8 shall limit a Party’s liability for any representations made fraudulently.</p>
                        <p>
                        Third Party Rights. You agree and acknowledge that EAN, Travelscape LLC, and VacationSpot SL and each of its Group Members shall have the benefit of these Terms. Notwithstanding this, the Parties agree that any claims in respect of losses suffered by EAN, Travelscape LLP, Vacation Spot SL and/or any of their Group Members shall where possible be brought by us for and on behalf of the affected Group Member and the Parties agree that, for this purpose, we shall be agent for and conduct any claims on behalf of such Group Member. Nothing in this clause shall enable EAN, Travelscape LLC, and VacationSpot SL and each of their Group Members to be entitled to recover damages or obtain any other form of payment or compensation more than once in respect of the same loss or breach.
                        </p>
                    </li>
                    <li>
                    Notice.
                    <ol type="a">
                        <li>
                        Notices must be given in English by email by a Party's authorised sender to the addresses notified to the other Party from time to time.
                        </li>
                        <li>
                        An email notice shall take effect: (i) if sent before 4.30pm on a Working Day, on the day of transmission; or (ii) when receipt is acknowledged by any of the receiving party's authorised recipients, or in the absence of such acknowledgment, 3 hours after the time of transmission (as recorded by the sending party's email application) save where such time of receipt would fall after 4.30pm on a Working Day, in which case it shall be deemed received at 10am on the next Working Day; and where (iii) no error (including any delivery error message) or valid out-of-office email message is received by the sending party in response to such email notice in respect of all of the receiving party's authorised recipients.
                        </li>
                    </ol>
                    </li>

                </ol>
                <h5>
                Definitions and interpretation
                </h5>
                <p>Definitions. In these Terms, the following definitions apply:</p>
                <p>
                <strong>Applicable Laws:</strong> as defined at Section 1.6 of these Terms;
                </p>
                <p>
                <strong> Approved Platform:     </strong> the websites and/or platforms you use to view Hotel Information and Content and undertake Bookings
                </p>
                <p>
                <strong>Approved Transport Component:</strong> one of the following travel services, separately provided by you or your third-party supplier: (i) air travel, (ii) rail travel which constitutes a substantial portion of the Package, (iii) car rental for at least the full duration of the hotel stay period of the Booking, or (iv) an overnight cruise;
                </p>
                <p><strong>Booking:</strong> a reservation made through the Approved Platform on the basis of the Hotel Information;
                </p>
                <p>
                <strong>EPS:</strong> means EAN.com LP;
                </p>
                <p>
                <strong>E-Collect Booking: </strong>A Booking for which you or we collect the payment from the End Custome
                </p>
                <p>
                <strong>End Customer:</strong> the individual or corporate customer undertaking the Booking;</p>

                <p>
                <strong>End Customer Issue:</strong> as defined at Section 1.5(b) of these Terms;</p>

                <p>
                <strong>End Customer Terms and Conditions:</strong> the terms and conditions applicable to Bookings, including any applicable privacy policy, as updated and notified to you from time to time;
                </p>
                <p>
                <strong>Group Member:</strong> an entity that, directly or indirectly, through one or more intermediaries, controls or is controlled by, or is under common control with an entity;
                </p>
                <p>
                <strong> Hotel Information:</strong> the rate and availability information that EAN and its Group Members make available (at its sole discretion) from time to time in the Approved Platform;
                </p>
                <p>
                <strong>Package:</strong> the booking of accommodation together with an Approved Travel Component;
                </p>
                <p>
                <strong>Package Rates:</strong> as defined at Section 1.7 of these terms;</p>

                <p>
                <strong>Term:</strong> as defined at Section 3.1 of these terms;</p>

                <p>
                <strong>Working Day:</strong> Monday to Friday excluding bank holidays in England.
                </p>
                <p>Interpretation.</p>
                <h5>
                In these Terms, except where the context requires otherwise:
                </h5>
                <ol type="a">
                    <li>
                    References to sections or schedules shall be to sections of and schedules to these terms. Headings are inserted for ease of reference and shall not affect interpretation;
                    </li>
                    <li>
                    Persons includes natural persons, firms, partnerships, companies, corporations, and words suggesting a gender shall include all other genders, words denoting the singular shall include the plural and vice versa;
                    </li>
                    <li>
                    References to statutes, statutory instruments and government regulations shall be deemed to include any modification, amendment, extension or re-enactment thereof from time to time, unless otherwise specified, the provisions of any statute, regulation or rule which amends, supplements or supersedes any such statute, regulation or rule
                    </li>
                    <li>
                    The expressions including, include, in particular, for example and any similar expression are meant to be inclusive and shall not limit the preceding words, and the word “or” is disjunctive, but not necessarily exclusive;
                    </li>
                    <li>
                    References to writing and written include communication by email including all related attachments;
                    </li>
                    <li>
                    A reference to any agreement or document means that agreement or document as amended or varied by written agreement between the parties from time to time.
                    </li>

                </ol>
            </div>

            </div>

            <Footer />

        </>
    )
}


export default AgentTaC;
