
import React, { useEffect, useReducer, useContext , useState } from 'react';
import { Button, Col, Row, Modal } from "react-bootstrap";
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import 'react-input-range/lib/css/index.css';
import Hotel from '../common/result/Hotel';
import Sort from '../common/result/Sort';
import Filter from '../common/result/Filter';
import FilterStatic from '../common/result/FilterStatic';
import ChangeHotelSearch from '../common/reviewpage/ChangeHotelSearch';
import log from "loglevel";
import { getHotelSRPContent } from '../../../bookingFlows/hotel/pages/operations';
import InnerLoader from '../../../common/loader/InnerLoader';
import ResultsMap from '../map/ResultsMap';
import ResultsNotFound from '../../../common/popUps/ResultsNotFound';
import { isNotNull } from '../../../commonUtils/validators';

//let bookresponse = require('../pages/htlResponse.json');

//Main Function
const initialState = {
  searchResp: "", loading: false, results: true, enable: true, load: true, hotelContResp: "", contentAvail: false, respAvail: false, showPop: false,
  hotelsearchRes: '', filtersearchRes: '', sortsearchRes: '', update: false, showMapView: false, hideSort: false, noofnights: 0, markupVal: 0, applToData: [], offers:[],clickedMap:false,noresults:false
  , reset: false, markupValue : 0 };

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 * @description:This function will load Hotel contant data
 * @author: Lakshmi
 * @param {*}
 * @function Results details
 * @date : 12-02-2021
 */
function HotelResultsPage(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [request , setRequest] =  useState(props.location);
  const [isLoading, setIsloading] =  useState(false);
  const [enableModify, setEnbleModify] =  useState(false);
  let offers_data = [{ label: "Price - Low to High", value: "LH" },
  { label: "Price - High to Low", value: "HL" },{ label: "Recommended", value: "RC" }]

  useEffect(() => {
    if(request!==undefined && request.state !==undefined && request.state.req!==undefined && !request.state.req.ismodify){
      callHotelContent(request);
    }else if(request.state===undefined && props.location!==undefined){
      dispatch({ type: 'showPop', payload: true })
    }
    },[request])

    useEffect(() => {
      setEnbleModify(false)
      if(props.location!==undefined && props.location.state !==undefined && props.location.state.req!==undefined && props.location.state.req.ismodify){
        setRequest(props.location)
        callHotelContent(props.location);
        setEnbleModify(true)
        dispatch({type:"markupValue",payload:0})
        dispatch({type:"applToData",payload:[]})
      }
      },[props.location])

  const callHotelContent = (request) => {
    state.htlsSize = 0;
    state.hotelsearchRes = "";
    state.filtersearchRes="";
    state.sortsearchRes="";
    setIsloading(true);
    var ptype="cityId";
    if(request.state.req.htlData[0].type==="city"){
      ptype="cityId"
    }else if(request.state.req.htlData[0].type==="property"){
      ptype="prid"
    }
    try {
      getHotelSRPContent(request.state.req.htlData[0],ptype).then((response) => {
        if (response.data!==undefined && response.data.list.length!==0 && response.status==="success") {
          if(response.data.list.length === 0){
            dispatch({type: 'showPop', payload: true})
          }
          dispatch({ type: 'contentAvail', payload: false })
          dispatch({ type: 'hotelContResp', payload: response.data})
          dispatch({ type: 'contentAvail', payload: true })
          dispatch({ type: 'noofnights', payload: request.state.req.nights })
          dispatch({ type: 'showPop', payload: false })
          dispatch({ type: 'clickedMap', payload: false });
          dispatch({ type: 'noresults', payload: false });
          setIsloading(false);
        } else {
          dispatch({ type: 'showPop', payload: true })
        }
      });
    } catch (e) {
      log.error('Exception occured in handleChange function---' + e);
    }
  }

  const handleCLClose = () => {
    dispatch({ type: 'showPop', payload: false });
    return window.location="/ezytrip/dashboard"
  }

  /**
   * @description:Props the response from Hotels component
   * @param {*} res
   * @@author:Rambabu
   * @date:01-03-2021
   */
  const hotelResponseCallBack = (res) => {
    if(res.filters.ishotdeal){
      let obj={ label: "Hot Deal", value: "Hot Deal" };
      state.applToData=[];
      state.applToData.push(obj);
      offers_data.push(obj);
    }
    state.offers=offers_data;
    //dispatch({ type: 'offers', payload: offers_data });
    state.hotelsearchRes=res;
    state.sortsearchRes=res;
    //dispatch({ type: 'hotelsearchRes', payload: state.hotelsearchRes });
    //dispatch({ type: 'sortsearchRes', payload: res });
    state.htlsSize = res.prpts.length
    //dispatch({ type: 'htlsSize', payload: state.htlsSize})
    dispatch({ type: 'showPop', payload: false });
    dispatch({ type: 'contentAvail', payload: true })

  }
  const setMarkupValue = (data) => {
    dispatch({ type: 'markupValue', payload: Number(data)})
  }
  /**
     * @description:Props the response from Filter component
     * @param {*} res
     * @@author:Rambabu
     * @date:01-03-2021
     */
  const filterResponseCallBack = (res,objec,type,reset) => {
    if(type==='Filter' && res.filters.ishotdeal){
      if(res.filters.ishotdeal){
        let obj=[{ label: "Hot Deal", value: "Hot Deal" }];
        state.applToData=obj;
        //dispatch({ type: 'applToData', payload: obj });
        if (isNotNull(reset) && reset == 'allclear') {
          dispatch({ type: 'reset', payload: true });
        }
      }else{
        state.applToData = isNotNull(objec) ? objec : [];
      }
    }else{
      state.applToData = isNotNull(objec) ? objec : [];
      dispatch({ type: 'reset', payload: false });
      //dispatch({ type: 'applToData', payload: objec });
    }
    state.filtersearchRes=res;
    state.sortsearchRes=res;
    //dispatch({ type: 'filtersearchRes', payload: res });
    //dispatch({ type: 'sortsearchRes', payload: res });
    dispatch({ type: 'htlsSize', payload: res.prpts.length });
    if(res.prpts.length===0){
      dispatch({ type: 'noresults', payload: true });
    }else{
      dispatch({ type: 'noresults', payload: false });
    }

    //window.scrollTo(0, 0)
  }

  const checkresetval = (data) => {
    if (isNotNull(data) && data == 'sort') {
      dispatch({ type: 'reset', payload: false });
    }
  }

  //Show List View
  const showList = (res) => {
    dispatch({ type: 'showMapView', payload: false });
    dispatch({ type: 'clickedMap', payload: true });
  }
  const listview = (res) => {
    dispatch({ type: 'showMapView', payload: false });
    dispatch({ type: 'clickedMap', payload: true });
    dispatch({ type: 'hideSort', payload: false });

  }
  //Show Map View
  const showMap = (res) => {
    dispatch({ type: 'showMapView', payload: true });
    dispatch({ type: 'clickedMap', payload: true });
    dispatch({ type: 'hideSort', payload: true });
  }

  //Show Map View
  const updateMarkup = (markup) => {
    dispatch({ type: 'markupVal', payload: markup });
  }

  //Show Map View
  const showResults = () => {
    dispatch({ type: 'showPop', payload: true });
  }


  return (

    <>
    <Header/>

    {request.state!==undefined && request.state.req!==undefined && !enableModify &&
      <ChangeHotelSearch request={request.state.req}  searchEnable={true} hotels={state.htlsSize} flow={"Results"} enable={state.contentAvail}/>
    }
    {enableModify &&
      <ChangeHotelSearch request={request.state.req}  searchEnable={true} hotels={state.htlsSize} flow={"Results"} enable={state.contentAvail}/>
    }
      {!state.showPop &&
      <div className="container resultLayout hotelContainer mt-4 mb-4">
        {isLoading ? <InnerLoader/>:
        <Row>
          <Col className="filterLayout">
            {state.hotelsearchRes ? <Filter hotelresponse={state.hotelsearchRes} filterresponse={filterResponseCallBack} />:<FilterStatic/>}
          </Col>

          <Col className="resultSection ">
          {state.sortsearchRes && !state.hideSort  && <Sort hotelresponse={state.sortsearchRes}  sortresponse={filterResponseCallBack} shwoMapView={showMap} shwoListView={showList}
          applToData={state.applToData} offers={state.offers} ismapview={state.showMapView} isreset={state.reset} resetvalue={checkresetval}/>}

           {(state.hotelsearchRes || state.filtersearchRes)   &&  state.showMapView &&
           <ResultsMap response={state.hotelsearchRes} nights={state.noofnights} request={request.state.req} markup={state.markupVal} shwoListViewNew={listview} mapfilterRes={state.filtersearchRes}/>
           }
            {state.noresults &&
           <>

         <div className="filterNoResultFound">
          <ResultsNotFound  filterChange={true} product={"Hotel"}/>
          </div>

            </>
            }
            {!state.showMapView && (state.contentAvail || state.filtersearchRes) &&
              <Hotel contentResp={state.hotelContResp} request={request.state.req} hotelresponse={hotelResponseCallBack} markupValue={setMarkupValue} appliedMarkupValue={state.markupValue}
              filterResponse={state.filtersearchRes} sendMarkup={updateMarkup} norRsponse={showResults} mapView={state.clickedMap} searchResp={state.hotelsearchRes}/>
            }
             {state.showPop &&
      <>
       <ResultsNotFound product={"Hotel"}/>
       </>
        }
          </Col>

        </Row>}

      </div>
          }
        {state.showPop &&
        <div className="htlovrl">
            <ResultsNotFound product={"Hotel"}/>
        </div>

        }
      <Footer />
    </>
  )
}


export default HotelResultsPage;
