import React, { useEffect, useState, useRef, useReducer } from "react";
import { Alert, Col, Form ,Modal, Button} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function SelectVisaType(props) {
    const [selectedFareIndex, setSelectedFareIndex] = useState(0);
    const [enbleMorDet, setEnbleMoreDet] = useState(false);
    const [response, setResponse] = useState(0);
    const [notiMessage, setNotiMessage] = useState("");
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();


    useEffect(()=>{
        if (props.searchRes){
            setResponse(props.searchRes)
            handleFareSelect(props.searchRes[0], 0)
                }
    },[props.searchRes])
    useEffect(() => {
    if(props.showError){
      setNotiVarient('danger');
      setNotiMessage("Select a package");
      setNotiMessageShow(true)
      hidemessage()
    }
    }, [props.showError])
    const hidemessage = () => {
      setTimeout(function () {
        setNotiVarient('');
        setNotiMessage("");
        setNotiMessageShow(false)
        props.disableNoti()
      }, 5000);
    }

    const handleMoreDet =()=> {
        if (enbleMorDet){
            setEnbleMoreDet(false)
                }else{
                    setEnbleMoreDet(true)
                }
    }
    const handleFareSelect =(item, index) => {
        setSelectedFareIndex(index)
        item.cur=props.cur
        props.onFareSelect(item)
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <>
    
    <Alert
              className="notification"
              variant={notiVarient}
              show={notiMessageShow}
              onClose={() =>
                {setNotiMessageShow(false)
                props.disableNoti()}
              }
              
              dismissible
            >
              {" "}
              {notiMessage}
            </Alert>
       <Slider {...settings}>
      
            { response && response.map((item, index) => (
               
        <div className={index===selectedFareIndex?"selectVisatyp active":"selectVisatyp"} key={item.id}>
                     <div className="visaTypeone">
                <div className="headerVt">
                    <span>{item.visa_validity} {item.dur_type} {item.visa}</span>
                </div>
                <div className="paxPrice d-flex">
                    <span className="firstPaxad">Per Adult: {props.cur}<span className="pasRat"> {Number(item.adt_pr)}</span></span>

                    <span className="secondPaxad">Per Child: {props.cur}<span className="pasRat"> {Number(item.chd_pr)}</span></span>

                </div>
                <div className="varTourtyp">
                    <div className="typeTourism d-flex">
                    {/* <span>Tourist</span> <span>Standard</span> <span>Single Entry</span> <span>30 Days</span> */}
                    <span>{item.category}</span> <span>{item.visa_type}</span> <span>{item.entry_type}</span> <span>{item.dur} {item.dur_type}</span>
                    </div>
                    <div className="visListt">
                        <ul>
                            <li>Visa Validity: {item.visa_validity} {item.dur_type} from date of issue</li>
                            <li>Stay Validity: {item.stay_validity} {item.dur_type} from date of entry</li>
                            <li>Processing Time: {item.process_time} working days</li>
                        </ul>
                        {item.desc!=undefined &&item.desc!="" &&<Button onClick={handleMoreDet} className="showmorelink" variant="link">
                          More Details
                        </Button>}
                        {enbleMorDet &&item.desc!=undefined && item.desc!="" && <ul><li>{item.desc}</li></ul>}

                        
                    </div>
                </div>

                <span onClick={()=> handleFareSelect(item, index)} className={index===selectedFareIndex?"selected":"select"}>{index===selectedFareIndex?"SELECTED":"SELECT"}</span>
            </div>
        </div>
        
        ))}
      </Slider>
       

{/*             
        <div class="selectVisatyp active"><div class="visaTypeone"><div class="headerVt"><span>60 Days E- Visa</span></div><div class="paxPrice d-flex"><span class="firstPaxad">Per Adult: QAR<span class="pasRat"> 1699</span></span><span class="secondPaxad">Per Child: QAR<span class="pasRat"> 750</span></span></div><div class="varTourtyp"><div class="typeTourism d-flex"><span>Tourist</span> <span>Standard Visa</span> <span>Single Entry</span> <span>60 Days</span></div><div class="visListt"><ul><li>Visa Validity: 60 Days from date of issue</li><li>Stay Validity: 60 Days from date of entry</li><li>Processing Time: 20 working days</li></ul><button class="showmorelink btn btn-link">More Details</button></div></div><span class="selected">SELECTED</span></div></div>
        <div class="selectVisatyp "><div class="visaTypeone"><div class="headerVt"><span>60 Days E- Visa</span></div><div class="paxPrice d-flex"><span class="firstPaxad">Per Adult: QAR<span class="pasRat"> 1699</span></span><span class="secondPaxad">Per Child: QAR<span class="pasRat"> 750</span></span></div><div class="varTourtyp"><div class="typeTourism d-flex"><span>Tourist</span> <span>Standard Visa</span> <span>Single Entry</span> <span>60 Days</span></div><div class="visListt"><ul><li>Visa Validity: 60 Days from date of issue</li><li>Stay Validity: 60 Days from date of entry</li><li>Processing Time: 20 working days</li></ul><button class="showmorelink btn btn-link">More Details</button></div></div><span class="select">SELECTED</span></div></div>
        <div class="selectVisatyp "><div class="visaTypeone"><div class="headerVt"><span>60 Days E- Visa</span></div><div class="paxPrice d-flex"><span class="firstPaxad">Per Adult: QAR<span class="pasRat"> 1699</span></span><span class="secondPaxad">Per Child: QAR<span class="pasRat"> 750</span></span></div><div class="varTourtyp"><div class="typeTourism d-flex"><span>Tourist</span> <span>Standard Visa</span> <span>Single Entry</span> <span>60 Days</span></div><div class="visListt"><ul><li>Visa Validity: 60 Days from date of issue</li><li>Stay Validity: 60 Days from date of entry</li><li>Processing Time: 20 working days</li></ul><button class="showmorelink btn btn-link">More Details</button></div></div><span class="select">SELECTED</span></div></div>
        <div class="selectVisatyp "><div class="visaTypeone"><div class="headerVt"><span>60 Days E- Visa</span></div><div class="paxPrice d-flex"><span class="firstPaxad">Per Adult: QAR<span class="pasRat"> 1699</span></span><span class="secondPaxad">Per Child: QAR<span class="pasRat"> 750</span></span></div><div class="varTourtyp"><div class="typeTourism d-flex"><span>Tourist</span> <span>Standard Visa</span> <span>Single Entry</span> <span>60 Days</span></div><div class="visListt"><ul><li>Visa Validity: 60 Days from date of issue</li><li>Stay Validity: 60 Days from date of entry</li><li>Processing Time: 20 working days</li></ul><button class="showmorelink btn btn-link">More Details</button></div></div><span class="select">SELECTED</span></div></div> */}

            
        </>
     );




}

export default SelectVisaType;