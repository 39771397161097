import log from "loglevel";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Context } from "../../../../App";
import Button from '../../common/buttons/Button';
import { Typeahead } from 'react-bootstrap-typeahead'
import DateUtils from '../../commonUtils/DateUtils';
import { getCompanyTAList,getBranchList, getCompaniesList } from '../airServices/operations';
import {fetchCountries,fetchCities} from '../hotelMarkup/operations'
/**
 * @description:This function will creaet all the search criteria
 * @author: Lakshmi
 * @param {*}
 * @function Common Search component
 * @date : 21-10-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "", aplType: "", applToData: [], companyData: [], branchData: [], applTypData: [], travelData: [],countries:[],countrySelected: [], citySelected: []
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, branches: [], companies: [], userType: "", aplTo: "", bid: "", cid: "", userId: 0, travelType: "",
      };
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function ApplicableDates(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enabelATT, setEnableATT] = useState(false);
  const [enabelTA, setEnableTA] = useState(false);
  const [enabelType, setEnableType] = useState(false);
  const [city,setCity] = useState([])

  const applicabelTo = [{ label: "TA", value: 'TA' }, { label: "STA", value: 'STA' }]
  const applicabelType = [{ label: "Booking Period", value: "Booking Period" }, { label: "Check-In Period", value: "CheckIn Period" }]
  const travelType = [{ label: "All", value: "0" }, { label: "One way", value: "1" }, { label: "Round Trip", value: "2" }, { label: "Multi City", value: "3" }]

  const StarRating = [{ label: "Five Star", value: "5" },
  { label: "Four Star", value: "4" },
  { label: "Three Star", value: "3" },
  { label: "Two Star", value: "2" },
  { label: "One Star", value: "1" },
  { label: "No Star", value: "0" }]

  useEffect(() => {

    if (context.logindata !== undefined) {
      dispatch({ type: 'userType', payload: context.logindata.utype })
      dispatch({ type: 'userId', payload: context.logindata.uid })
      const pyload = { "type": "ta_user", "userId": context.logindata.uid, "cid": context.logindata.cid }
      //Load all companies based Login type as TA
      getCompaniesList(pyload).then(response => {
        if (response.data.suc) {
          let data = response.data.res
          if(context.logindata.utype == "ta_user") {
            let arr = []
            let obj={
              "label": context.logindata.cnm,
              "value": context.logindata.cid
            }
             arr.push(obj)
             data = arr.concat(data)
          }
          dispatch({ type: 'companies', payload: data })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    }
  }, [context.logindata]);

    /**
     * @description : fetch countries to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
     useEffect(() => {
      if(state.countries.length === 0) {
          fetchCountries().then(response => {
              if(response.data.status == 'success') {
                  let country = []
                  let data = response.data.data
                  for(let val in response.data.data) {
                      let country_data = data[val]
                      let obj = {
                          "value":country_data.countryCode,
                          "label":country_data.name.en
                      }
                      country.push(obj)
                  }
                  dispatch({type:"countries",payload:country})
              }
          })
      }
  },[state.countries])
  
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const handleSearch = () => {
    if (state.cid !== "") {
        const payload = {
          "type": state.userType,
          "userid": state.userId,
          "aplto": state.aplTo,
          "bid": state.bid,
          "cid": (state.cid !== 0) ? state.cid : "",
          "apltyp": state.aplType,
          "str": state.travelType,
          "cnt": state.countrySelected.length > 0 ? state.countrySelected[0].value : "",
          "cty": state.citySelected.length > 0 ? state.citySelected[0].value : "",
          "fmdate": (fromDate !== "") ? DateUtils.convertStringToDate(fromDate) : "",
          "todate": (toDate !== "") ? DateUtils.convertStringToDate(toDate) : "",
          "pcid" : context.logindata.cid,
          "agency_admin" : context.logindata.agency_admin
        }
        props.sendSearchCreteria(payload);
    }else{
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please select Company' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }

  }

  // Adding Class and rbds
  const setAplType = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'aplType', payload: obj[0].value })
      setEnableType(true);
      dispatch({ type: 'applTypData', payload: obj })
    }
  }

  const setCompany = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'cid', payload: obj[0].value })
      dispatch({ type: 'companyData', payload: obj })
    }
  }

  const setTravelType = (obj) => {
    if (obj[0] !== undefined) {
      dispatch({ type: 'travelType', payload: obj[0].value })
      dispatch({ type: 'travelData', payload: obj })
    }
  }

  const resetSearch = () => {
    setEnableType(false);
    setEnableATT(false)
    setEnableTA(false)
    dispatch({ type: 'applTypData', payload: [] })
    dispatch({ type: 'travelData', payload: [] })
    dispatch({ type: 'companyData', payload: [] })
    dispatch({ type: 'branchData', payload: [] })
    dispatch({ type: 'applToData', payload: [] })
    setCity([])
    dispatch({type:"countrySelected",payload:[]})
    dispatch({type:"citySelected",payload:[]})
  }


  const setAplTo = (obj) => {
    if (obj[0] !== undefined && obj[0].value !== undefined) {
      dispatch({ type: 'aplTo', payload: obj[0].value })
    }
    dispatch({ type: 'applToData', payload: obj })
    dispatch({ type: 'cid', payload: 0 })
    if (obj[0] !== undefined && obj[0].value === "TA") {
      const pyload = { "type": state.userType, "userId": state.userId }
      //Load all branches based on for ATT
      getBranchList(pyload).then(response => {
        if (response.data.suc) {
          setEnableATT(true);
          setEnableTA(false)
          dispatch({ type: 'branches', payload: response.data.res })
        }
      }).catch(function (error) {
        log.error(
          "Exception occured in getCompanyList function---" + error
        );
      });
    } else if (obj[0] !== undefined && obj[0].value === "STA") {
      // if (state.userType === "att_admin") {
        const pyload = { "type": state.userType, "userId": state.userId }
        getBranchList(pyload).then(response => {
          if (response.data.suc) {
            setEnableATT(true);
            setEnableTA(false)
            dispatch({ type: 'branches', payload: response.data.res })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      // }
    }
  }

  const selectBranch = (obj) => {
    var branchId = obj[0].value;
    dispatch({ type: 'bid', payload: branchId })
    dispatch({ type: 'branchData', payload: obj })
    if (enabelATT) {
        getCompanyTAList(branchId,state.aplTo).then(response => {
          if (response.data.suc) {
            setEnableTA(true)
            dispatch({ type: 'companies', payload: response.data.res })
            dispatch({ type: 'aplTo', payload: "TA" })
          }
        }).catch(function (error) {
          log.error(
            "Exception occured in getCompanyList function---" + error
          );
        });
      // } else {
      //   setEnableTA(false)
      //   console.log("Branch  : ");
      // }
    } else {
      setEnableTA(false);
    }
  }
  const fetchCity =  (input,e) => {
    if(input.length > 2 && state.countrySelected.length > 0) {
   fetchCities(state.countrySelected[0].value,input).then(response => {
        if(response.data.status == 'success') {
            setCity([])
            let city = []
            let data = response.data.data
            dispatch({type:"cities",payload:data})
            dispatch({type:"selectedCityPredictive",payload:data})
        }
    })
}
}

    /**
     * @description : fetch hotel to show in predective list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
     const handleCityChange = (e) => {
      if (e && e.length > 0) {
          let citySelectedJson = {
              "label": e[0].name.en,
              "value" : e[0]._id
          }
          setCity()
          dispatch({ type: "citySelected", payload: [citySelectedJson] })
      }
  }

      /**
     * @description : fetch cities to show in drop down list
     * @author : Azamuddin
     * @date : 27-04-2021
     */
       const handleCountryChange = (e) => {
        if (e && e.length > 0) {
            dispatch({ type: "countrySelected", payload: e })
            dispatch({ type: "citySelected", payload: [] })
            dispatch({type:"cities",payload:[]})
            setCity([])
        }
    }
  return (
    <>
      <div>
        {state.notiMessageShow &&
          <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
            onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
            dismissible> {state.notiMessage}</Alert>
        }
        {state.userType !== "ta_user" && state.userType !== "sta_user" &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={applicabelTo}
                searchable={false}
                values={state.applToData}
                onChange={(value) => (setAplTo(value))}
              />
              <Form.Label>Applicable To <sup>*</sup></Form.Label>
            </Form.Group>
            {enabelATT &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.branches}
                  searchable={false}
                  values={state.branchData}
                  onChange={(value) => (selectBranch(value))}
                />
                <Form.Label>Branch</Form.Label>
              </Form.Group>
            }
            {enabelTA && (state.aplTo === "TA" || state.userType === "att_user") &&
              <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
                <Select
                  options={state.companies}
                  searchable={false}
                  values={state.companyData}
                  onChange={(value) => (setCompany(value))}
                />
                <Form.Label>Company</Form.Label>
              </Form.Group>
            }
          </Form.Row>
        }
        {state.userType === "ta_user" && state.companies.length !== 0 &&
          <Form.Row>
            <Form.Group className="selectplugin" as={Col} xs={3} controlId="userRole">
              <Select
                options={state.companies}
                searchable={false}
                values={state.companyData}
                onChange={(value) => (setCompany(value))}
              />
              <Form.Label>Company<sup>*</sup></Form.Label>
            </Form.Group>
          </Form.Row>
        }
        <Form.Row>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
            <Select
              options={StarRating}
              searchable={false}
              values={state.travelData}
              onChange={(value) => (setTravelType(value))}
            />
            <Form.Label>Star Rating</Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3} className="selectplugin" controlId="aplType">
            <Select
              options={applicabelType}
              searchable={false}
              values={state.applTypData}
              onChange={(value) => (setAplType(value))}
            />
            <Form.Label>Applicable Type</Form.Label>
          </Form.Group>
          <Form.Group as={Col} xs={3}>
            <Form.Row>
              {enabelType &&
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="fromDate">
                  <DatePicker
                    selected={fromDate}
                    onChange={date => setFromDate(date)}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={new Date()}
                    popperModifiers={{
                      flip: {
                          behavior: ['bottom'] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>Start Date</Form.Label>
                </Form.Group>
              }
              {enabelType &&
                <Form.Group as={Col} xs={6} className="selectplugin datePicker mb-0" controlId="toDate">
                  <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date) + 1}
                    fromDate={fromDate}
                    toDate={toDate}
                    minDate={fromDate ? fromDate : new Date()}
                    className="datefield"
                    popperModifiers={{
                      flip: {
                          behavior: ['bottom'] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                  />
                   <span className="calendar ezyIcon icon-newcalendar"></span>
                  <Form.Label>End Date</Form.Label>
                </Form.Group>
              }
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={3} className="selectplugin" controlId="icalInput">
                    <Select
                        options={state.countries}
                        values={state.countrySelected}
                        disabled={state.enableEdit}
                        onChange={(value) => (handleCountryChange(value))}
                    />
                    <Form.Label>Country</Form.Label>
                </Form.Group >
                <Form.Group as={Col} xs={3} className="selectplugin">

                     <Typeahead
                                        //defaultInputValue={state.selectedOrigin}
                                        id="originmaster"
                                        labelKey={ta => `${ta.name.en}`}
                                        onInputChange={fetchCity}
                                        options={state.cities}
                                        selected={city}
                                        onChange={ta => (handleCityChange(ta))}
                                        disabled={state.enableEdit || state.countrySelected.length===0}
                                        //onFocus={originOnFocus} onBlur={originOnBlur}
                                        placeholder="Select City"
                                        readOnly
                                        minLength={3}
                                        // onFocus={activeOrigin}
                                    />
                    <Form.Label>City <sup>*</sup></Form.Label>

                </Form.Group>
          </Form.Row>
      </div>
      <div className="text-right pb-0 buttonGrop  creat_absolute">
        <Button
          size="xs"
          variant="outline-secondary"
          type="submit"
          onClick={resetSearch}
        > Reset</Button>
        <Button
          size="xs"
          variant="outline-primary"
          type="submit"
          onClick={handleSearch}
        > Search</Button>
      </div>


    </>
  )
}
export default ApplicableDates