/**
 * @description: Flight Confirmation page Flight details section
 * @date:09-02-2020
 * @author:Rambabu
 *
 */
 import React from 'react';
 import { Col, Row } from "react-bootstrap";
 import AirlinesMemo from '../../../common/airlines';
 import AirportMemo from '../../../common/airlines/airportNames';
 import DateUtils from '../../../commonUtils/DateUtils';
 import { isNotNull } from '../../../commonUtils/validators';
 import LayoverDetails from './LayoverDetails.jsx';
 function FlightSegmentDetails(props) {
     const origin_data = props.origindata[0];
     const type = props.origindata[1];
     const mappings = props.mapping;
     return (
         <>
             <div className="flightRoutInfo mt-3">
                 {origin_data.seg.map((sege, index) => (
                     <>
                         <Row className="align-items-center rs-row mt-1">
                             <Col xs={3} className="rs-rTypeDate">
                                 <div className="airLogo p-0">
                                     <img
                                         src={'https://d386hvlqraus9s.cloudfront.net/airlines/1x/airline-logos/' + sege.ma + '.png'}
                                         className="logo"
                                         title="ATT"
                                         alt="AirLine"
                                     />
                                     <div className="flightNo d-flex flex-column">
                                         <strong><AirlinesMemo code={sege.ma} /></strong>
                                         <span>{sege.ma}-{sege.fn}</span>
                                         {/* <span className={"ezyIcon icon-"+ (sege.eq === 'BUS' ? 'bus':'Aircraft')}> Boeing {sege.eq}</span> */}
                                         <div className="oprtBy"><span>Operated By : </span> <span><AirlinesMemo code={sege.oa} /></span></div>
                                     </div>
                                 </div>
                             </Col>
                             <Col xs={(isNotNull(type) && type === 'Confirm') ? '7' : '9'} className="rs-FromTo">
                                 <Row className="align-items-center">
                                     <Col className="d-flex flex-column">
                                         <span className='flightArri'>{DateUtils.prettyDate(sege.dd, "HH:mm")} </span>
                                         <span className='flightYeartime'>{DateUtils.prettyDate(sege.dd, "ddd, DD MMM, YYYY")}</span>
                                         <span className="d-flex flex-column"><AirportMemo code={sege.da} mapping={mappings} /></span>
                                         {sege.terminal !== undefined &&
                                             <small className="trm">Terminal: {sege.terminal.dep}</small>
                                         }
                                     </Col>
                                     <Col className="p-0" xs="1"><span className="ezyIcon icon-flight"></span></Col>
                                     <Col className="d-flex flex-column">
                                         <span className='flightDesti'>{DateUtils.prettyDate(sege.ad, "HH:mm")} </span>

                                         <span className='flightYeartime'>{DateUtils.prettyDate(sege.ad, "ddd, DD MMM, YYYY")}</span>
                                         <span className="d-flex flex-column"><AirportMemo code={sege.ar} mapping={mappings} /></span>
                                         {sege.terminal !== undefined &&
                                             <small className="trm">Terminal: {sege.terminal.arr}</small>
                                         }
                                     </Col>
                                 </Row>

                                 <span>

                                 </span>
                             </Col>
                             {(isNotNull(type) && type === 'Confirm') &&
                                 <Col xs={2}>
                                    {origin_data.sup == 0? <p className='ecoNomy'><span>Class : </span> {sege.cl!==undefined && sege.cl!=="" && sege.cl ? ( sege.rbd ? sege.cl + "-" + sege.rbd : sege.cl ):(sege.rbd ? sege.rbd : "N/A")}</p>:<p className='ecoNomy'><span>Fare Type : </span> {props.origindata[0].pr.ftyp==="Regular - R"? "SAVER":props.origindata[0].pr.ftyp==="Flexi - J"?"FLEXI":props.origindata[0].pr.ftyp==="Corporate - F"?"CORPORATE":props.origindata[0].pr.ftyp}</p>}
                                     <p className='airLinepnr'><span>Airline PNR : </span> <span>{sege.airpnr}</span></p>
                                  {origin_data.sup == 0 && <p className='airLinepnr'><span>GDS PNR : </span> {origin_data.pnr!==undefined?origin_data.pnr:"N/A"}</p>}
                                 </Col>}

                         </Row>
                         {/* {origin_data} */}
                                         {(origin_data.sup == undefined || origin_data.sup == 0) && props.confirmation && origin_data.bgd && origin_data.bgd.length > 0 &&
                                         // ("Baggage Info : " + "Cabin "+origin_data.bgd[0].qun +" in "+origin_data.bgd[0].wt + "  per Adult & Child")
                                         <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {origin_data.bgd[1]!==undefined && origin_data.bgd[1].qun} {origin_data.bgd[1]!==undefined && origin_data.bgd[1].wt}, Check-in {origin_data.bgd[0]!==undefined && origin_data.bgd[0].qun}  {origin_data.bgd[0]!==undefined && origin_data.bgd[0].wt} per Adult & Child </div>
                                         }

                                        {(origin_data.sup != 0) && props.confirmation && origin_data.bgd && origin_data.bgd.length > 0 &&
                                         // ("Baggage Info : " + "Cabin "+origin_data.bgd[0].qun +" in "+origin_data.bgd[0].wt + "  per Adult & Child")
                                         <div className="sectBaggageinfo ezyIcon icon-baggage"> Baggage Info : Cabin {origin_data.bgd[1]!==undefined && origin_data.bgd[1].qun} {origin_data.bgd[1]!==undefined &&  origin_data.bgd[1].wt}, Check-in {origin_data.bgd[0]!==undefined && origin_data.bgd[0].qun}  {origin_data.bgd[0]!==undefined && origin_data.bgd[0].wt} per Adult & Child </div>
                                         }
                         {/* LayoverDetails */}
                         {origin_data.seg.length > 1 &&
                             (isNotNull(origin_data.seg[(index + 1)]))
                             && <LayoverDetails layoverdata={[origin_data.seg[index].ad, origin_data.seg[(index + 1)].dd, sege.ar, mappings]} />}
                     </>
                 ))
                 }


             </div>
         </>
     )
 }

 export default FlightSegmentDetails;

