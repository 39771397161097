/**
 * @description : custom fields data component for summary
 * @aurhor : Azamuddin
 * @date : 26-05-2021
 */
import React, { useContext, useEffect, useReducer, useRef, useState }  from 'react';
import { Col, Row } from "react-bootstrap";
import { isArrayNotEmpty } from '../../commonUtils/validators';
const JSON = require('circular-json');


/**
 * Initial State Declaration
 */
 const initialState = { customFieldsData:[],customFieldsString:"" };


 // Reducer function for userReact Hook which will update the state
 const reducer = (state, action) => {

   switch (action.type) {

     default:
       return { ...state, [action.type]: action.payload };
   }
 };


function CustomFields(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        //set data to init state for declaration and showing in ui
        if(isArrayNotEmpty(props.res)) {
            dispatch({type:"customFieldsData",payload:props.res}); //declaration of data
            let string = ""
            for(let val of props.res) {
                if(string == "") {
                    string = val.clbl +" - " + val.cval
                } else {
                    string = string + "," + val.clbl +" - " + val.cval
                }
            }
            dispatch({type:"customFieldsString",payload:string})
        }
     },[props.res])

    return (
        <>
        {isArrayNotEmpty(state.customFieldsData) && (state.customFieldsData.map((data,index) => (
            <React.Fragment key={index}>
                <div className='cstfieldData'>
                <Row>
                <Col>{data.clbl} : {data.cval}</Col>
                </Row>
                </div>
            </React.Fragment>
        )))}
        {/* {state.customFieldsString} */}
        </>
    )
}


export default CustomFields;
