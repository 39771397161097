import React, { useState, useEffect, useReducer } from 'react';
import ShowHideAccordion from '../../../common/showHide/ShowHideAccordion'
import Utilities from '../../../commonUtils/Utilities';
import {Col, Row,} from "react-bootstrap";
import FormateCurrency from '../../../commonUtils/FormateCurrency';
/**
 * @description:This function will Calculate the Room wise Fare
 * @author: Lakshmi
 * @param {*}
 * @function Room Price details
 * @date : 12-02-2021
 */

/**
* Initial State Declaration
*/
//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================

function RoomPrice(props) {
  const [state, dispatch] = useReducer();
  const [request, setRequest] = useState(props.roomsData);
  const [adultPrice, setAdultPrice] = useState(true)
  const [taxeinfo, setTaxeinfo] = useState(false)
  const [tsfee, setTsfee] = useState(0)
  const [disc, setDisc] = useState(0)
  const [markup, setMarkup] = useState(0)
  const [roomMarkup, setRoomMarkup] = useState(0)

  useEffect(()=>{
  if(request!==undefined){
    if(request.onflymarkup!==undefined && request.onflymarkup!==0){
      let markup=request.onflymarkup/request.rooms.length;
      setMarkup(request.onflymarkup);
      setRoomMarkup(markup);
    }
  }
  },[])

  //Adult panel enable
  const openadultPrice = () => {
    if (adultPrice) {
      setAdultPrice(false)
    } else {
      setAdultPrice(true)
    }
  }

  const openTaxeinfo = () => {
    if (taxeinfo) {
      setTaxeinfo(false)
    } else {
      setTaxeinfo(true)
    }
  }
  //Calculating the TAX
  const calTotalPrice = (rooms) => {
    let totalFare=0;
    for(let room of rooms){
    if(totalFare===0){
      let service=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,request.butype,"All");
      totalFare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,service,"Add");
      totalFare=parseFloat(totalFare) + parseFloat(room.rmpr[0].pr[0].gst_total) + parseFloat(room.rmpr[0].pr[0].tds_val)
    }else{
      let service=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,request.butype,"All");
      let fare=Utilities.addPrice(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc,service,"Add");
      totalFare=parseFloat(totalFare) + parseFloat(fare) + parseFloat(room.rmpr[0].pr[0].gst_total) + parseFloat(room.rmpr[0].pr[0].tds_val);
    }
    }
    totalFare=Utilities.addPrice(totalFare,request.onflymarkup,"Add");
    return totalFare;
  }

 //Calculating the TAX
 const calServiceFee = (rooms) => {
  let totalFare=0;
  for(let room of rooms){
  if(totalFare===0){
    let service=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,request.butype,"Not");
    totalFare=service;
  }else{
    let service=Utilities.calHotelServiceFee(room.rmpr[0].servicefee,request.butype,"Not");
    totalFare=parseFloat(totalFare) + parseFloat(service);
  }
  }
  return totalFare;
}
const showAmenities =(roomData)=>{
  let brdbase=[];
  if (roomData && roomData.rmpr && roomData.rmpr[0].ra &&
    props.roomsData.rooms[0].rmpr[0].ra.length !== 0) {
      for (let taxIndex = 0; taxIndex < roomData.rmpr[0].ra.length; taxIndex++) {
        var mak = roomData.rmpr[0].ra[taxIndex];
        if (mak.id !== "2109" && mak.id !== "2195" && mak.id !== "2192" && mak.id !== "2191" ) {
          brdbase.push(mak.name);
        }
      }
  }
  if (brdbase.length === 0) {
      brdbase.push("Room Only");
    }
  const strdata = brdbase.join(', ');
  return strdata
}


  return (
    <>
      <div className="costBox bookingSummary">
        {request.rooms !== undefined &&
          <div className="gustSUmmary">
            {request.rooms.map((room, idx) => (
              <>
              <div className="costList">
              <Row>
                <Col>
                <span className="roomConts">Room {idx + 1} : <span>{room.adt} Adult(s)  {room.chd!==undefined && room.chd!==0?" & "+room.chd+" Child(s)":""}</span></span>
                </Col>
                <Col>
                <div className='newBoardbasis'>
                <ShowHideAccordion className="boardBasis" title='Board Basis' icon='arrowDown'>
                    <div className='ABCD'>{showAmenities(room)}</div>
              </ShowHideAccordion>
              </div>
                </Col>
              </Row>



              <ShowHideAccordion title={room.rnm} icon='arrowDown' cur={request.cur} subtitle={<FormateCurrency value={parseFloat(room.rmpr[0].pr[0].tf - room.rmpr[0].pr[0].com_disc) + Number(room.rmpr[0].pr[0].gst_total) + Number(room.rmpr[0].pr[0].tds_val) + parseFloat(roomMarkup) + Utilities.getServiceFee(room.rmpr[0].servicefee,request.butype)} />}>
              <div className="showHide-content">
              {adultPrice &&
              <div className="dropContent">
                    <p><span >Base Fare</span><span>{request.cur} <FormateCurrency value={parseFloat(room.rmpr[0].pr[0].bf) + parseFloat(roomMarkup) + Utilities.getServiceFee(room.rmpr[0].servicefee,request.butype)} /> </span>

                    </p>
                    <p><span className="toolTipInfo taxpop">Taxes & Fees <span className="ezyIcon icon-info"></span>

                    <div className="toolTipCUstoom cnclInfo">
                          <p>This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax).  This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked.  For more details, please see the Terms and Conditions.</p>
                          </div>

                    </span><span>{request.cur} <FormateCurrency value={Utilities.hotelTaxCalculation(room.rmpr[0].pr[0].tax) + Number(room.rmpr[0].pr[0].gst_total) + Number(room.rmpr[0].pr[0].tds_val)} /> </span>

                    </p>
                    <p><span>Discount</span><span>{request.cur} <FormateCurrency value={Utilities.hotelDiscount(room.rmpr[0].pr[0].tax,request.butype)} /></span></p>
                    {room.rmpr[0].pr[0].com_disc!==0 &&
                      <p><span>Agent Discount</span><span>{request.cur} <FormateCurrency value={room.rmpr[0].pr[0].com_disc} /></span></p>
                    }
                    {Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax)!==0 &&
                    <p><span>Sales Tax</span><span>{request.cur} <FormateCurrency value={Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax)} /></span></p>
                    }
                  </div>}
              </div>
              </ShowHideAccordion>
              </div>





                {/* <div className="costList">
                  <span className="roomConts">Room {idx + 1} : <span>{room.adt} Adult(s)  {room.chd!==undefined && room.chd!==0?" & "+room.chd+" Child(s)":""}</span></span>
                  <Link to='#' onClick={openadultPrice} className={"openPrice" + (adultPrice ? ' active' : '')}><span className="roomName" title={room.rnm}><em >{room.rnm}</em><Icon size={12} icon="arrowDown" /></span>
                    <span className="cost">{request.cur} <FormateCurrency value={parseFloat(room.rmpr[0].pr[0].tf) + parseFloat(roomMarkup) + Utilities.getServiceFee(room.rmpr[0].servicefee,request.butype)} /></span></Link>
                  {adultPrice && <div className="dropContent">
                    <p><span >Base Fare</span><span>{request.cur} <FormateCurrency value={parseFloat(room.rmpr[0].pr[0].bf) + parseFloat(roomMarkup) + Utilities.getServiceFee(room.rmpr[0].servicefee,request.butype)} /> </span>

                    </p>
                    <p><span className="toolTipInfo taxpop">Taxes & Fees <span><Icon className="ml-1" color="#888888" size={12} icon="info" /></span>

                    <div className="toolTipCUstoom cnclInfo">
                          <p>This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax).  This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked.  For more details, please see the Terms and Conditions.</p>
                          </div>

                    </span><span>{request.cur} <FormateCurrency value={Utilities.hotelTaxCalculation(room.rmpr[0].pr[0].tax)} /> </span>

                    </p>

                    <p><span>Discount</span><span>{request.cur} <FormateCurrency value={Utilities.hotelDiscount(room.rmpr[0].pr[0].tax,request.butype)} /></span></p>

                    {Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax)!==0 &&
                    <p><span>Sales Tax</span><span>{request.cur} <FormateCurrency value={Utilities.hotelSTaxCalculation(room.rmpr[0].pr[0].tax)} /></span></p>
                    }
                  </div>}
                </div> */}
              </>
            ))}
            <>
              <div className="costList otherCust">
                <p><span>Affiliate Service Charge </span><span>{request.cur} <FormateCurrency value={calServiceFee(request.rooms)} /> </span></p>
              </div>
              <div className="costList otherCust totalpernight">
                <p><span>Total Price ({request.nonights} Nights) </span><span>{request.cur} <FormateCurrency value={calTotalPrice(request.rooms)} /> </span></p>
                {/* <p className="discount"><span>Discount</span><span>{request.cur} {disc}</span></p> */}
              </div>
              <div className="costList total otherCust">
                <div className="gTPrice"><span>Grand Total</span><div className="blockPrice"><strong><em>{request.cur}</em> <FormateCurrency value={calTotalPrice(request.rooms)} /> </strong></div></div>
                {request.priceNote!==undefined && request.priceNote!=="" &&
                <span className="priceNote"><span>Note :</span> {request.priceNote} </span>
                }
              </div>
            </>
          </div>
        }
      </div>
    </>

  )
}
export default RoomPrice