import React, { useEffect, useReducer, useState } from 'react';
import { Col, Modal} from "react-bootstrap";
import FormateCurrency from '../../../../commonUtils/FormateCurrency';
import Utilities from '../../../../commonUtils/Utilities';
import DomesticContent from './DomesticContent';
import InternationalContent from './InternationalContent';
import IndigoFareopContent from './IndigoFareopContent';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//This function will show the Air markups List and Add Markup
//Initial State
const initialState = {
    response: {}
  }

  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {

      case 'test':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

function TravelFusion(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [fareResp, setFareResponse] = useState(props.selFlight.fareop);
    const [isSlider, setIsSlider] = useState(props && props.selFlight && props.selFlight.fareop && props.selFlight.fareop.length>=3?true:false);
    const [regionType, setRegionType] = useState(props.regionType);
    const [baggage, setBaggage] = useState("");
    const [isCorporate, setIsCorporate] = useState(false);
    const [showJeddah, setShowJeddah] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [gccCodes , setGccCodes] = useState(["XNB","DWC","DXB","AZAZ","DZB","DOH","XSB","KWI","MCT","DMM","SHJ","IST","JED"]);
    const [show, setShow] = useState(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const [enableVandeBharath, setEnableVandeBharath] = useState(false);
    const [vandeList1 , setVandeList1] = useState(["TRV","COK","CNN","HYD","BLR","BOM","CCJ","MAA","LKO"]);
    const [vandeList2 , setVandeList2] = useState(["TRV","COK","CNN","HYD","BLR","BOM","CCJ","MAA","LKO","AMD","BBI"]);
    const handleClosee = () => setShow(false);
    const handleShoww = () => setShow(false);
    const [showBaggage, setShowBaggae] = useState("");
    
      useEffect(() => {
      if(props.selFlight.sup === "4"){
      if((props.srequest.srcCode==="JED" && props.srequest.dest[0].countryCode==="IN") || (props.srequest.src[0].countryCode==="IN" && props.srequest.destCode==="JED")){
        setShowBaggae("30KG")
      }else if(gccCodes.includes(props.srequest.srcCode) || gccCodes.includes(props.srequest.destCode)){
        setShowBaggae("30KG")
      }else{
        setShowBaggae("20KG")
      }
    }
    }, [])


    //Checking Indigo sectors
    const checkIndigoContent=(ftyp)=>{
      setIsCorporate(false)
      setShowDiscount(false);
      setEnableVandeBharath(false);
      if(regionType!=="" && regionType!==undefined){
        if(regionType==="International"){//For International
          if(props.srequest.srcCode==="JED" && props.srequest.dest[0].countryCode==="IN"){//JED - IND showing 5L Zamzam water
          setBaggage("30")
          setShow(true)
          setShowJeddah(true);
          setShowDiscount(true);
          }else{
          if(props.srequest.src[0].countryCode==="IN" && props.srequest.destCode==="JED"){//IND - JED showing 30KG with same jeddah content
            setBaggage("30")
            setShow(true)
            setShowJeddah(true);
            setShowContent(true);
          }else{
            if(gccCodes.includes(props.srequest.srcCode) || gccCodes.includes(props.srequest.destCode)){//GCC Cities checking
              setBaggage("30")
              setShow(true)
             }else{//NON GCC Cities
              setBaggage("20")
              setShow(true)
             }
          }
          }
          //Cheking Vande Bharath Codes
          if(props.srequest.src[0].countryCode==="QA" && props.srequest.dest[0].countryCode==="IN"){
            if(vandeList1.includes(props.srequest.destCode)){
              setEnableVandeBharath(true);
            }
          }else if(props.srequest.src[0].countryCode==="KW" && props.srequest.dest[0].countryCode==="IN"){
            if(vandeList2.includes(props.srequest.destCode)){
              setEnableVandeBharath(true);
            }
          }
        }else{//Domestic
          setShow(true)
        }
        //Checking Corp Deal
        if(ftyp.includes("Corporate")){
          setIsCorporate(true)
        }
      }
    }



    const updateFarePrice=(flt, index) => (event) => {
    if(index===0){
      flt.selectTf='selectedOne'
    }else if(index===1){
      flt.selectTf='selectedTwo'
    }else if(index===2){
      flt.selectTf='selectedThree'
    }
    //props.selectedTf(myselect)
        props.sendFarePrice(flt)
        if(fareResp){
          for(let val of fareResp){
            if(val.routeId===flt.routeId){
               val.selected=true;
            }else{
               val.selected=false;
            }
        }
        }
        //setFareResponse(fareResp);
        event.stopPropagation();
      }
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      autoplay: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  const calcuateServiceFee = (serviceFeeList) => {
    var userType = (props.srequest!==undefined && props.srequest.butype === "ta_user") ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
      if(servicefee==="NaN"){
        return 0.00;
      }else{
        return servicefee;
      }
    } else {
      return 0.00;
    }
  }

  const calculateGST=(flight)=>{
    let total=0;
    if(props.sectors!==undefined && props.srequest!==undefined && props.sectors.gst!==undefined && props.sectors.gst.type!==undefined &&  props.srequest.region_type==="India"){
    let gst={};
      gst = Utilities.applyGST(flight.servicefee,props.sectors.gst,flight.pr.tax,props.srequest,"Flight");
      if(gst.gst_invoice.length!==0){
        for(let val of gst.gst_invoice){
          if(gst.type==="same"){
          if(total===0){
            total=Number(val.cgst_val)+Number(val.sgst_val);
          }else{
            total=total+Number(val.cgst_val)+Number(val.sgst_val);
          }
         }else{
          if(total===0){
            total=Number(val.igst_val);
          }else{
            total=total+Number(val.igst_val);
          }
         }
      }
    }
    }
    return total;
  }

    return (
        <>
        <div className="travelFusionresponceList">
             <p className="selectfusioninfo">Select below fare options :</p>
                <div className={"fusionlistGrid "+" checkSlider "+isSlider}>
                    {props.selFlight.fareop!==undefined &&
                    <>

                    <Slider {...settings}>
                    {props.selFlight.fareop.map((flt, index) => (


                        <Col xs={12}>
                        <div className={flt.selected?`fusionList fusionOne ${flt.pr.ftyp.toLowerCase()} active`:`fusionList ${flt.pr.ftyp.toLowerCase()} fusionOne`}  >
                        <div className="cardtop">
                            <span className="tfType"

                        >
                          {flt.pr.ftyp==="Regular - R" &&
                          <span>SAVER </span>
                          }
                          {flt.pr.ftyp==="Flexi - J" &&
                          <span>FLEXI</span>
                          }
                          {flt.pr.ftyp.toUpperCase()==="CORPORATE - F" &&
                           <span>CORPORATE </span>
                          }
                          {flt.pr.ftyp!=="Regular - R" && flt.pr.ftyp!=="Flexi - J" && flt.pr.ftyp.toUpperCase()!=="CORPORATE - F" &&
                          <span>{flt.pr.ftyp.toUpperCase()}</span>
                          }
                          {props.selFlight.sup==="4" &&
                            <span variant="primary" onClick={()=>checkIndigoContent(flt.pr.ftyp)} className='ezyIcon icon-info ml-2'></span>
                           }

                        </span>
                        {props.selFlight.sup === "2" && flt.pr.ftyp!=="Basic" &&
                        <div className="pricetag"><em>{props.srequest.cur} +</em><strong>{<FormateCurrency value={Utilities.addPrice(flt.pr.tf-props.selFlight.fareop[0].pr.tf, calcuateServiceFee(flt.servicefee) + calculateGST(flt),"Add")} />}</strong></div>
                        }
                        {props.selFlight.sup !== "2" &&
                        <div className="pricetag"><em>{props.srequest.cur}</em><strong>{<FormateCurrency value={Utilities.addPrice(flt.pr.tf, calcuateServiceFee(flt.servicefee) + calculateGST(flt),"Add")} />}</strong></div>
                        }
                        </div>

                        <div className="cardbody">
                        {props.selFlight.sup === "2" &&
                         <>
                                    <p><span className="ezyIcon icon-covidglobal"> Global Covid-19 Cover</span></p>
                                    <p><span className="ezyIcon icon-newseatselection"> Generous 32" seat pitch</span></p>
                                    <p><span className="ezyIcon icon-tenkg"> 10 Kg carry-on baggage</span></p>
                                 </>
                          }
                            {flt.pr.cabb!="" &&
                             <p><span className="ezyIcon icon-checkinbag">Checkin baggage
                             {flt.pr.bagage_info!=="" && flt.pr.bagage_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.bagage_info}</span>
                                              </div>
                                              </span>
                             }
                            </span><span>{flt.pr.cabb}</span></p>
                           }
                           {
                             flt.pr.chekb &&
                             <p><span className="ezyIcon icon-cabinbaggage">Cabin baggage
                             {props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" ||flt.uniqueId ==="Business" || flt.uniqueId ==="FLEX")?<span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.chekb}</span>
                                              </div>
                                              </span>:""}
                            </span><span>{props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" ||flt.uniqueId ==="Business"|| flt.uniqueId ==="FLEX")?"free":flt.pr.chekb}</span></p>
                           }
                           {
                             props.selFlight.sup === "6" &&
                             <p><span className="ezyIcon icon-cabinbaggage">Cabin baggage
                              </span><span>7KG</span></p>
                           }
                           {flt.pr.puBag &&
                           <p><span className="ezyIcon icon-tBaggage">Purchase baggage
                           {flt.pr.puBag_info!=="" && flt.pr.puBag_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.puBag_info}</span>
                                              </div>
                                              </span>
                             }
                           </span><span>{flt.pr.puBag}</span></p>
                           }
                           {flt.pr.meals!="" && props.selFlight.sup !== "6" &&
                             <p><span className="ezyIcon icon-mealsselection"> Meal Selection
                             {flt.pr.meals_info!=="" && flt.pr.meals_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.meals_info}</span>
                                              </div>
                                              </span>
                             }
                             {props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" || flt.uniqueId ==="FLEX")?<span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>INCLUDED IN FARE</span>
                                              </div>
                                              </span>:""}
                            </span><span>{props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" || flt.uniqueId ==="FLEX")?"free":flt.pr.meals}</span></p>
                           }
                           {flt.pr.seats &&
                            <p><span className="ezyIcon icon-newseatselection"> Seat Selection
                            {flt.pr.seat_info!=="" && flt.pr.seat_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.seat_info}</span>
                                              </div>
                                              </span>
                             }
                             {props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" || flt.uniqueId ==="Business" || flt.uniqueId ==="FLEX")?<span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>INCLUDED IN FARE</span>
                                              </div>
                                              </span>:""}
                            </span><span>{props.selFlight.sup === "5" && (flt.uniqueId ==="VALUE" || flt.uniqueId ==="Business" || flt.uniqueId ==="FLEX")?"free":flt.pr.meals}</span></p>
                           }
                           {flt.pr.chanb!=="" &&
                            <p><span className="ezyIcon icon-chnagebooking"> Change Booking
                            {flt.pr.chanb_info!=="" && flt.pr.chanb_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.chanb_info}</span>
                                              </div>
                                              </span>
                             }
                            </span><span title={(flt.pr.chanb)}>{flt.pr.chanb}</span></p>
                           }
                           {flt.pr.canb!=="" && props.selFlight.sup !== "4" && props.selFlight.sup !== "6" &&
                            <p><span className="ezyIcon icon-cancelbooking"> Cancel Booking
                            {flt.pr.cabb_info!=="" && flt.pr.cabb_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{flt.pr.cabb_info}</span>
                                              </div>
                                              </span>
                             }
                            </span><span title={(flt.pr.canb)}>{flt.pr.canb}</span></p>
                           }

                           {props.selFlight.sup === "4" &&
                            <IndigoFareopContent regionType={regionType} ftype={flt.pr.ftyp} baggage={showBaggage}/>
                           }

                         {flt.selected?  (props.srequest.tt=="1"?<span className="select">SELECTED</span>:<span onClick={updateFarePrice(flt, index)} className="select">SELECTED</span>) :  <span onClick={updateFarePrice(flt, index)}  className="selecttt">SELECT</span>}

                        </div>


                    </div>
                      </Col>

                    ))}
                    </Slider>
                    </>
                    }
                    </div>
                </div>

        <Modal show={show} onHide={handleClosee} className="fareCategorydescrip">
        <Modal.Header closeButton>
          <Modal.Title>Fare Category Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='IndigobdContent'>
            {regionType!=="" && regionType==="International" &&
            <InternationalContent regionType={regionType} baggage={baggage} isCorporate={isCorporate} showJeddah={showJeddah} showContent={showContent} showDiscLabel={showDiscount} showVandeBharath={enableVandeBharath}/>
            }
            {regionType!=="" && regionType==="Domestic" &&
            <DomesticContent regionType={regionType} baggage={baggage} isCorporate={isCorporate} />
            }
          </div>
          </Modal.Body>
      </Modal>
        </>


    )
}
export default TravelFusion