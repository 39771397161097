import React, { useEffect, useReducer, useState , useContext} from 'react';
import { Alert, Col, Modal,Form,Row, Button} from "react-bootstrap";
import InnerLoader from '../../../common/loader/InnerLoader';
import { getLongfareRules,getMinifareRules} from '../review/operations';
import ShowRules from './ShowRules';
import { Context } from "../../../../../App";
const _ = require('lodash');

/**
 * @description:This function will call the Mini fare rules
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 6-09-2020
 */

/**
* Initial State Declaration
*/
const initialState = { response: ""};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
    switch (action.type) {
        case 'upprof':
            return Object.assign(state, action.payload);
        default:
            return { ...state, [action.type]: action.payload };
    }
};

//This function will show the Air markups List and Add Markup
function FareRules(props) {
    const [modifySearch, setModifySearch,] = useState(false);
    const [isLoadingMore, setIsLoadingMore,] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [selFlight, setSelFlight] = useState(props.selectedFlight)
    const [reviewFlight, setReviewFlight] = useState(props.reviewFlight)
    const [multiFlights, setMultiFlights] = useState(props.mulflights)
    const [splitFlights, setSplitFlights] = useState(props.splitFlights)
    const [minirules, setMinirules] = useState([])
    const [longrules, setLongRules] = useState([])
    const [showMsg, setMessage] = useState()
    const [showLong, setShowLong] = useState(false)
    const [lgShow, setLgShow] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [applicables, setApplicables] = useState([])
    const [tripType, setTripType] = useState("")
    const [context, setContext] = useContext(Context);
    const [serviceFee, setServiceFee] = useState("")
    const [showData, setShowData] = useState("")

    const [tablist, setTablist] = useState({
        active: "Onward",
      });

    const openSearch = () => {
        if (modifySearch) {
            setModifySearch(false)
        } else {
            setModifySearch(true)
        }
    }

    //=====================================
    // Calling Mini AND lONG FARE RULES Service
    //=====================================
    useEffect(() => {
        setIsLoadingMore(true);
        if(props.srequest!==undefined && props.srequest.tt!==undefined){
            setTripType(props.srequest.tt);
        }else{
            setTripType(props.reviewFlight.tt)
        }

        var sty = props.sty;
        if (sty === undefined && reviewFlight) {
            sty = "normal"
        }
        if (sty !== undefined && sty === "normal") {
            if (selFlight !== undefined) {
                var request = "";
                if (props.srequest.tt === 1) {
                    request = createOneWayMiniRequest(false,"");
                } else if (props.srequest.tt === 2) {
                    request = createRoundFareRuleReq(false,"");
                } else if (props.srequest.tt === 3) {
                    request = createMultiFareRuleReq(false,"");
                }
                request.pcid=context.logindata.pcid;
                request.butype=context.logindata.butype;
                request.bid=context.logindata.bid;
                if(context.logindata.cid===228){
                getMinifareRules(request).then(response => {
                    if (response.data !== undefined && response.data.miniRulesRs !== undefined && response.data.miniRulesRs.status === "success") {
                        if (!response.data.miniRulesRs.data[0].err) {
                            setMinirules(response.data.miniRulesRs.data);
                            setIsLoadingMore(false);
                        } else {
                            setMessage(true);
                        }
                    } else {
                        setMessage(true);
                        setIsLoadingMore(false);
                    }
                })
                }
                getLongfareRules(request).then(response => {
                    if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                        let cnt=1;
                        for(let val of response.data.longRuleRes.data[0].sectors){
                            if(cnt===1){
                                val.selected=true;
                                 setShowData(val);
                            }else{
                                val.selected=false;
                            }
                            cnt=cnt+1;
                        }
                        setLongRules(response.data.longRuleRes.data);
                        setApplicables(response.data.longRuleRes.data[0].apcbleRules);
                        setShowLong(true);
                        setIsLoadingMore(false);
                        if(response.data.longRuleRes.data[0]!==undefined && response.data.longRuleRes.data[0].sf!==undefined){
                        setServiceFee(response.data.longRuleRes.data[0].sf)
                        }
                    } else {
                        setIsLoadingMore(false);
                    }
                })
            } else if (reviewFlight !== undefined) {
                //For Import PNR
                if (reviewFlight.flowType !== undefined && reviewFlight.flowType === "Import") {
                    request = convertImpFareReq(reviewFlight,false,"");
                } else {
                    request = _.cloneDeep(reviewFlight,false,"");
                    request.ruleType="ALL";
                    request.pcid=context.logindata.pcid;
                    request.butype=context.logindata.butype;
                    request.bid=context.logindata.bid;
                    delete request.mappings;
                    if(reviewFlight.sty==="split"){
                        setTripType("1")
                            if(props.selFlt===1){
                                delete request.origns[0].destorigins;
                            }else{
                                let dest=request.origns[0].destorigins;
                                delete request.origns[0].srcorigns;
                                delete request.origns[0].destorigins;
                                request.origns[0].srcorigns=dest;
                            }
                    }
                }
                // getMinifareRules(request).then(response => {
                //     if (response.data !== undefined && response.data.miniRulesRs !== undefined && response.data.miniRulesRs.status === "success") {
                //         if (!response.data.miniRulesRs.data[0].err) {
                //             setMinirules(response.data.miniRulesRs.data);
                //             setIsLoadingMore(false);
                //         } else {
                //             setMessage(true);
                //         }
                //     } else {
                //         setMessage(true);
                //         setIsLoadingMore(false);
                //     }
                // })
                getLongfareRules(request).then(response => {
                    if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                        let cnt=1;
                        for(let val of response.data.longRuleRes.data[0].sectors){
                            if(cnt===1){
                                val.selected=true;
                                setShowData(val);
                            }else{
                                val.selected=false;
                            }
                            cnt=cnt+1;
                        }
                        setLongRules(response.data.longRuleRes.data);
                        setApplicables(response.data.longRuleRes.data[0].apcbleRules)
                        setShowLong(true);
                        setIsLoadingMore(false);
                        if(response.data.longRuleRes.data[0]!==undefined && response.data.longRuleRes.data[0].sf!==undefined){
                        setServiceFee(response.data.longRuleRes.data[0].sf)
                        }
                    } else {
                        setIsLoadingMore(false);
                    }
                })
            }
        } else {
            if (splitFlights) {
                setTripType("1")
                if (splitFlights[0]) {
                    fireSplitOnwardMini(splitFlights[0],false,"");
                } else if (splitFlights[1]) {
                    fireSplitReturnMini(splitFlights[1],false,"");
                }
            }
        }

    }, []);
    //=====================================
    // Creating payload for One way
    //=====================================
    const createOneWayMiniRequest = (selected,code) => {
        const payload = {
            "echo": props.srequest.echo,
            "cha": props.srequest.cha,
            "cur": props.srequest.cur,
            "adt": props.srequest.adt,
            "chd": props.srequest.chd,
            "inf": props.srequest.inf,
            "cid": props.srequest.cid,
            "sty": props.srequest.sty,
            "tt": props.srequest.tt,
            "tid": selFlight.srcorigns[0].tid,
            "ruleType":!selected?"ALL":code,
            "origns": [{
                "srcorigns": [convertFareReq(props.flights[0])]
            }]
        }
        return payload;
    }

    //=====================================
    // Creating payload for Split Onward
    //=====================================
    const fireSplitOnwardMini = (selectedObj,selected,code) => {
        var request = createSplitMiniReq(selectedObj,selected,code);
        request.pcid=context.logindata.pcid;
        request.butype=context.logindata.butype;
        request.bid=context.logindata.bid;
        // getMinifareRules(request).then(response => {
        //     if (response.data !== undefined && response.data.miniRulesRs !== undefined && response.data.miniRulesRs.status === "success") {
        //         if (!response.data.miniRulesRs.data[0].err) {
        //             setMinirules(response.data.miniRulesRs.data);
        //             setIsLoadingMore(false);
        //         } else {
        //             setMessage(true);
        //         }
        //     } else {
        //         setMessage(true);
        //         setIsLoadingMore(false);
        //     }
        // })
        getLongfareRules(request).then(response => {
            if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                let cnt=1;
                        for(let val of response.data.longRuleRes.data[0].sectors){
                            if(cnt===1){
                                val.selected=true;
                                setShowData(val);
                            }else{
                                val.selected=false;
                            }
                            cnt=cnt+1;
                        }
                setLongRules(response.data.longRuleRes.data);
                if(!selected){
                 setApplicables(response.data.longRuleRes.data[0].apcbleRules)
                 if(response.data.longRuleRes.data[0]!==undefined && response.data.longRuleRes.data[0].sf!==undefined){
                    setServiceFee(response.data.longRuleRes.data[0].sf)
                    }
                }
                setShowLong(true);
                setIsLoadingMore(false);
            } else {
                setIsLoadingMore(false);
            }
        })
    }

    //=====================================
    // fireSplit Return Mini servie
    //=====================================
    const fireSplitReturnMini = (selectedObj,selected,code) => {
        var request = createSplitMiniReq(selectedObj,selected,code);
        request.pcid=context.logindata.pcid;
        request.butype=context.logindata.butype;
        request.bid=context.logindata.bid;
        // getMinifareRules(request).then(response => {
        //     if (response.data !== undefined && response.data.miniRulesRs !== undefined && response.data.miniRulesRs.status === "success") {
        //         if (!response.data.miniRulesRs.data[0].err) {
        //             setMinirules(response.data.miniRulesRs.data);
        //             setIsLoadingMore(false);
        //         } else {
        //             setMessage(true);
        //         }
        //     } else {
        //         setMessage(true);
        //     }
        // })
        getLongfareRules(request).then(response => {
            if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                let cnt=1;
                        for(let val of response.data.longRuleRes.data[0].sectors){
                            if(cnt===1){
                                val.selected=true;
                                setShowData(val);
                            }else{
                                val.selected=false;
                            }
                            cnt=cnt+1;
                        }
                setLongRules(response.data.longRuleRes.data);
                if(!selected){
                setApplicables(response.data.longRuleRes.data[0].apcbleRules);
                setServiceFee(response.data.longRuleRes.data[0].sf)
                }
                setShowLong(true);
                setIsLoadingMore(false);
            }
        })
    }

    //=====================================
    // create Round FareRule Req
    //=====================================
    const createRoundFareRuleReq = (selected,code) => {
        const payload = {
            "echo": props.srequest.echo,
            "cha": props.srequest.cha,
            "cur": props.srequest.cur,
            "adt": props.srequest.adt,
            "chd": props.srequest.chd,
            "inf": props.srequest.inf,
            "cid": props.srequest.cid,
            "sty": props.srequest.sty,
            "tt": props.srequest.tt,
            "tid": selFlight.srcorigns[0].tid,
            "ruleType":!selected?"ALL":code,
            "origns": [{
                "srcorigns": [convertFareReq(props.flights[0])],
                "destorigins": [convertFareReq(props.flights[1])]
            }]
        }
        return payload;
    }
    //=====================================
    // create Round FareRule Req
    //=====================================
    const convertFareReq = (selectedFlt) => {
        const payload = {
            "tid": selectedFlt.tid,
            "ref": selectedFlt.ref,
            "ofcid": "DOHQR28IH",
            "sdur": selectedFlt.sdur,
            "re": selectedFlt.re,
            "stop": selectedFlt.stop,
            "sup": selectedFlt.sup,
            "cl": selectedFlt.cl,
            "ft": selectedFlt.ft,
            "tktair": selectedFlt.tktair,
            "tecst": selectedFlt.tecst,
            "seg": selectedFlt.seg,
            "bgd": selectedFlt.bgd,
            "pr": selFlight.pr,
            "paxpr": selFlight.paxpr
        }
        return payload;
    }

    //=====================================
    // create Round FareRule Req
    //=====================================
    const convertImpFareReq = (selectedFlt,selected,code) => {
        const payload = {
            "echo": selectedFlt.echo,
            "cha": selectedFlt.cha,
            "cur": selectedFlt.cur,
            "adt": selectedFlt.adt,
            "chd": selectedFlt.chd,
            "inf": selectedFlt.inf,
            "cid": selectedFlt.cid,
            "sty": selectedFlt.sty,
            "tt": selectedFlt.tt,
            "tid": selectedFlt.origns[0].srcorigns[0].tid,
            "ruleType":!selected?"ALL":code,
            "origns": selectedFlt.origns
        }
        return payload;
    }
    //=====================================
    // create convertSplit FareReq
    //=====================================
    const convertSplitFareReq = (selectedFlt, price) => {
        const payload = {
            "tid": selectedFlt.tid,
            "ref": 1,
            "ofcid": "DOHQR28IH",
            "sdur": selectedFlt.sdur,
            "re": selectedFlt.re,
            "stop": selectedFlt.stop,
            "sup": selectedFlt.sup,
            "cl": selectedFlt.cl,
            "ft": selectedFlt.ft,
            "tktair": selectedFlt.tktair,
            "tecst": selectedFlt.tecst,
            "seg": selectedFlt.seg,
            "bgd": selectedFlt.bgd,
            "pr": price.pr,
            "paxpr": price.paxpr
        }
        return payload;
    }
    //=====================================
    // create create MultiFare RuleReq FareReq
    //=====================================
    const createMultiFareRuleReq = (selected,code) => {
        let selectedFlts = [];
        if (multiFlights[0]) {
            selectedFlts.push(convertFareReq(multiFlights[0]));
        }
        if (multiFlights[1]) {
            selectedFlts.push(convertFareReq(multiFlights[1]));
        }
        if (multiFlights[2] !== "") {
            selectedFlts.push(convertFareReq(multiFlights[2]));
        }
        if (multiFlights[3] !== "") {
            selectedFlts.push(convertFareReq(multiFlights[3]));
        }
        if (multiFlights[4] !== "") {
            selectedFlts.push(convertFareReq(multiFlights[4]));
        }
        if (multiFlights[5] !== "") {
            selectedFlts.push(convertFareReq(multiFlights[5]));
        }
        const payload = {
            "echo": props.srequest.echo,
            "cha": props.srequest.cha,
            "cur": props.srequest.cur,
            "adt": props.srequest.adt,
            "chd": props.srequest.chd,
            "inf": props.srequest.inf,
            "cid": props.srequest.cid,
            "sty": props.srequest.sty,
            "tt": props.srequest.tt,
            "tid": selFlight.srcorigns[0].tid,
            "ruleType":!selected?"ALL":code,
            "origns": [{
                "srcorigns": selectedFlts
            }]
        }
        return payload;
    }

    const createSplitMiniReq = (selectedObj,selected,code) => {
        const payload = {
            "echo": props.srequest.echo,
            "cha": props.srequest.cha,
            "cur": props.srequest.cur,
            "adt": props.srequest.adt,
            "chd": props.srequest.chd,
            "inf": props.srequest.inf,
            "cid": props.srequest.cid,
            "sty": "split",
            "tt": props.srequest.tt,
            "tid": selectedObj.tid,
            "ruleType":!selected?"ALL":code,
            "origns": [{
                "srcorigns": [convertSplitFareReq(selectedObj.srcorigns[0], selectedObj)]
            }]
        }
        return payload;
    }

    //Showing the Rules for Mini fares
    const openRules = obj => evt => {
        setLgShow(true);
        setTitle(obj.type)
        setContent(obj.notes)

    };



    //Updating seleted segment
  const handleChangeRule = (val) => (e) => {
    if(longrules.length && applicables.length!==0){
        let secs = longrules[0].sectors.filter((q, idx) => q.selected);
        for(let rule of applicables){
            if (!rule.selected && rule.value===val){
                rule.selected = true;
                if(props.sty!==undefined && props.sty==="normal"){
                if (selFlight !== undefined) {
                var request = "";
                if (props.srequest.tt === 1) {
                    request = createOneWayMiniRequest(true,val);
                } else if (props.srequest.tt === 2) {
                    request = createRoundFareRuleReq(true,val);
                } else if (props.srequest.tt === 3) {
                    request = createMultiFareRuleReq(true,val);
                }
                setTripType(props.srequest.tt);
                getLongfareRules(request).then(response => {
                    if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                        setShowLong(false);
                        for(let sec of response.data.longRuleRes.data[0].sectors){
                            if(secs[0].id===sec.id){
                                sec.selected=true;
                                setShowData(sec);
                            }else{
                                sec.selected=false;
                            }
                        }
                        setLongRules(response.data.longRuleRes.data);
                        setShowLong(true);
                        setIsLoadingMore(false);
                    } else {
                        setIsLoadingMore(false);
                    }
                })
               }else if(reviewFlight !== undefined){
                //For Import PNR
                if (reviewFlight.flowType !== undefined && reviewFlight.flowType === "Import") {
                    request = convertImpFareReq(reviewFlight,true,val);
                } else {
                    request = _.cloneDeep(reviewFlight);
                    request.ruleType=val;
                    delete request.mappings;
                    if(reviewFlight.sty==="split"){
                            if(props.selFlt===1){
                                delete request.origns[0].destorigins;
                            }else{
                                let dest=request.origns[0].destorigins;
                                delete request.origns[0].srcorigns;
                                delete request.origns[0].destorigins;
                                request.origns[0].srcorigns=dest;
                            }
                    }
                }
                setTripType(reviewFlight.tt);
                getLongfareRules(request).then(response => {
                    if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                        setShowLong(false);
                        for(let sec of response.data.longRuleRes.data[0].sectors){
                            if(secs[0].id===sec.id){
                                sec.selected=true;
                                setShowData(sec);
                            }else{
                                sec.selected=false;
                            }
                        }
                        setLongRules(response.data.longRuleRes.data);
                        setShowLong(true);
                        setIsLoadingMore(false);
                    } else {
                        setIsLoadingMore(false);
                    }
                })
              }
            }else{
                if (splitFlights!==undefined){
                    setTripType("1")
                    if (splitFlights[0]) {
                        fireSplitOnwardMini(splitFlights[0],true,val);
                    } else if (splitFlights[1]) {
                        fireSplitReturnMini(splitFlights[1],true,val);
                    }
                }else{
                    var request = _.cloneDeep(reviewFlight);
                    request.ruleType=val;
                    delete request.mappings;
                    if(reviewFlight.sty==="split"){
                        setTripType("1");
                            if(props.selFlt===1){
                                delete request.origns[0].destorigins;
                            }else{
                                let dest=request.origns[0].destorigins;
                                delete request.origns[0].srcorigns;
                                delete request.origns[0].destorigins;
                                request.origns[0].srcorigns=dest;
                            }
                    }else{
                        setTripType(reviewFlight.tt);
                    }
                    getLongfareRules(request).then(response => {
                            if (response.data !== undefined && response.data.longRuleRes !== undefined && response.data.longRuleRes.status === "success") {
                                setShowLong(false);
                            let secs = longrules[0].sectors.filter((q, idx) => q.selected);
                            for(let sec of response.data.longRuleRes.data[0].sectors){
                                if(secs[0].id===sec.id){
                                    sec.selected=true;
                                    setShowData(sec);
                                }else{
                                    sec.selected=false;
                                }
                            }
                            setLongRules(response.data.longRuleRes.data);
                            setShowLong(true);
                            setIsLoadingMore(false);
                        } else {
                            setIsLoadingMore(false);
                        }
                    })
                }
            }
            }else{
                rule.selected = false;
              }
            }
    }
  }

    const handleTabChange=(obj)=>{
        for(let sec of longrules[0].sectors){
         if(sec.id===obj.id){
            sec.selected=true;
            setShowData(obj);
         }else{
            sec.selected=false;
         }
        }
    }

    return (
        <>
            {isLoadingMore ? <InnerLoader /> : ""}



                {minirules && minirules.map((mini, idx) => (
                    <ul className="fareRules">
                        {mini.cbd !== undefined &&
                            <li className="d-flex align-items-center">
                                <Col xs={5} className="rules">
                                    <p>Cancellation before departure</p>
                                </Col>
                                <Col xs={3} className="status text-center">
                                    {mini.cbd.alow === 1 ? <p className="allow">Allowed <sup> *</sup></p> : <p className="notAllow">Not Allowed</p>}
                                </Col>
                                <Col xs={4} className="price d-flex flex-column align-items-end">
                                <div className="pricetag"><em>QAR </em> <strong>{mini.cbd.prc}</strong></div>
                                    <span>Per Ticket</span>
                                </Col>
                            </li>
                        }
                        {mini.cad !== undefined &&
                            <li className="d-flex align-items-center">
                                <Col xs={5} className="rules">
                                    <p>Cancellation after departure</p>
                                </Col>
                                <Col xs={3} className="status text-center">
                                    {mini.cad.alow === 1 ? <p className="allow">Allowed <sup> *</sup></p> : <p className="notAllow">Not Allowed</p>}
                                </Col>
                                <Col xs={4} className="price d-flex flex-column align-items-end">
                                <div className="pricetag"><em>QAR </em> <strong> {mini.cad.prc}</strong></div>
                                    <span>Per Ticket</span>
                                </Col>
                            </li>
                        }
                        {mini.nos !== undefined &&
                            <li className="d-flex align-items-center">
                                <Col xs={5} className="rules">
                                    <p>No show</p>
                                </Col>
                                <Col xs={3} className="status text-center">
                                    {mini.nos.alow === 1 ? <p className="allow">Allowed <sup> *</sup></p> : <p className="notAllow">Not Allowed</p>}
                                </Col>
                                <Col xs={4} className="price d-flex flex-column align-items-end">
                                <div className="pricetag"><em>QAR </em> <strong> {mini.nos.prc}</strong></div>
                                    <span>Per Ticket</span>
                                </Col>
                            </li>
                        }
                    </ul>
                ))}

                            <div className="rulsInfo">
                        {showMsg &&
                            <span className="noMin ezyIcon icon-failed"> Mini rules not available</span>
                        }
                        {showLong &&
                            <>
                                {longrules!==undefined && longrules[0]!==null && longrules[0]!==undefined &&
                                <span className="linkStyle" onClick={openRules(longrules[0])}>Rules for cancellation and charges</span>
                                }
                            </>
                        }
                        </div>

            <Modal
                size="lg"
                show={lgShow}
                className="rulesancharges longfare"
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className="mb-0">
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Flight Long Fare Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                {serviceFee!==undefined &&  serviceFee!=="" && serviceFee.service_fee_value!==undefined &&
                <Alert variant="info" className="ezyIcon icon-info">
                <p className="ml-1">Travel Agency service of QAR {serviceFee.service_fee_value} applies {serviceFee.applicable_on} for reissue and refund.</p>
                </Alert>
                }
                <strong>Please select the menu and confirm the content of each rule :</strong>
          <Row>
              <Col xs={4}>
            <div className="selectoption">
            {applicables.length!==0 &&  applicables.map((app, index) => (
            <React.Fragment key={index}>
            <Form.Check type="radio" id={index + 1 +"-"+app.value} onChange={handleChangeRule(app.value)} checked={app.selected} value={app.value} label={app.label} name="longfare" custom />
            </React.Fragment>
            ))}
            </div>
              </Col>
                <Col xs={8}>
                <div className="fareLink">
                   {longrules.length!==0 && longrules[0].sectors.map((sec, index) => (

                    <Button
                    size="xs"
                    variant="link"
                    type="submit"
                    className={"selectFare " + (sec.selected ? "active" : " ")}
                    onClick={()=>handleTabChange(sec)}
                    >{sec.dep} {"-"} {sec.arr}</Button>

                   ))}
                   </div>
                   {showData!=="" &&
                   <ShowRules data={showData} mappings={props.mapping} type={"O"}/>
                   }
                   </Col>

              </Row>
              </Modal.Body>
            </Modal>

        </>
    )
}
export default FareRules