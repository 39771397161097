
import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
//Main Function
function FilterStatic(props) {
    const [state, setState] = useState({})

    const [price, setPrice] = useState({
        value: { min: 0, max: 1110 },
    });
    const [rating, setRating] = useState({
        value: 5,
    });
    return (

        <>
            <div className="filter hotelFilter staticss">
                <div className="title d-flex justify-content-between">
                    <h5>Result Filter</h5>
                    <Link to="#" className="ezyIcon icon-reset"></Link>
                </div>
                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Hotel Name</p>
                    </div>
                    <div className="search filterSearch d-flex align-items-center">
                        <Form.Control type="text" id="ariaid" placeholder="Search by Hotel" />
                        <button className="searchIcon ezyIcon icon-search"></button>
                    </div>
                </div>
                <div className="filterSec price">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Price</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <div className="ranges price">
                        <InputRange
                            maxValue={1110}
                            minValue={0}
                            value={price.value}
                            onChange={value => setPrice({ value })} />
                    </div>
                </div>
                <div className="filterSec expediaRating">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>User Reviews</p>

                        <Link>Clear</Link>
                    </div>
                    <div className="ranges">
                        <InputRange
                            maxValue={20}
                            minValue={0}
                            value={rating.value}
                            onChange={value => setRating({ value })} />
                    </div>
                </div>
                <div className="filterSec">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Star Rating</p>
                        <Link to="#">Clear</Link>
                    </div>
                    <div className="w-50"><div className="flightAmenities"></div></div>
                </div>
                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Board Basis</p>
                        <Link>Clear</Link>
                    </div>


                    <ul class="loadList w-75">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>
                </div>


                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Type of Accommodation</p>
                        <Link>Clear</Link>
                    </div>
                    <ul class="loadList w-75">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>
                </div>

                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Area</p>
                        <Link>Clear</Link>
                    </div>
                    <div className="search filterSearch d-flex align-items-center mb-2">
                        <Form.Control type="text" id="ariaid" placeholder="Search by Area" />
                        <button className="searchIcon ezyIcon icon-search"></button>
                    </div>

                    <ul class="loadList w-75">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>
                </div>
                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Landmarks</p>
                        <Link>Clear</Link>
                    </div>
                    <div className="search filterSearch d-flex align-items-center mb-2">
                        <Form.Control type="text" id="ariaid" placeholder="Search by Area" />
                        <button className="searchIcon ezyIcon icon-search"></button>
                    </div>

                    <ul class="loadList w-75">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>
                </div>
                <div className="filterSec accommodation">
                    <div className="filterLabel d-flex justify-content-between">
                        <p>Amenities</p>
                        <Link>Clear</Link>
                    </div>
                    <ul class="loadList w-75">
                        <li class="airLgolist"></li>
                        <li class="airLgolist"></li>

                    </ul>
                </div>


            </div>

        </>
    )
}


export default FilterStatic;
