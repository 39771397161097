import log from "loglevel";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import moment from "moment";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import { isNotNull } from '../../../../components/commonUtils/validators';
import Payment from '../../../bookingFlows/flight/payment/Payment';
import Button from '../../../common/buttons/Button';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import { confirmNomoreDlg } from '../../../common/sessionTimeOut/SessionNomore';
import ShowHide from '../../../common/showHide/ShowHide';
import Sticky from '../../../common/sticky/Sticky';
import DateUtils from '../../../commonUtils/DateUtils';
import Expire from '../../../../assets/images/nofare.gif'
import { characterNumberValidation, characterValidation, emailValidation, mobileNumberValidation } from '../../../commonUtils/FormValidations';
import Utilities from '../../../commonUtils/Utilities';
import Countdowns from '../review/Countdowns';
import { callDiscardBook, fireFareRule, getCrediLimit, getOldItnarary, getReissueFareInfo ,priceCheckService} from '../review/operations';
import ProfileDetails from '../review/ProfileDetails';
import TravellersDetails from '../review/TravellersDetails';
import SelectsSeats from './SelectsSeats';
import TripCost from '../review/TripCost';
import CustomInformaion from './CustomInformaion';
import FlightInfoDetails from './FlightInfoDetails';
import FlightOldItinarary from './FlightOldItinarary';
import { saveBooking,updatePaxInfo } from './operations';
import ReissueFareDetails from './ReissueFareDetails';
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
export const USER_AUTH_DATA = "userAuthData";
export const PLB_DISCOUNT = "PLB_DISCOUNT";
export const STA_SERVICE_FEE = "staFee";
export const BOOKING_SERVICE = "bkServiceFee";
export const ONHOLD_STY = "ONHOLD_STY"
export const ONFLY_FLT_MKP = "onflyFltMkp";
export const PAY_DATA = "payData";
export const PAY_REQ = "payRequest";
export const PAY_RESP = "payResp";
export const PRODUCT_TYPE = "productType";
export const GET_PAY_LINK= "PayLink";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
/**
 * @description:This function will create the review page
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 31-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  psngers: [], prof: "", custms: [], remarks: [], preBkresponse: "", enabletimer: false, totalprice: 0, bdata: "", diffamt: 0, creditBal: 0, travelsList: [], traveller: "", fareruleResp: [], prebookSuc: false, isClAvailable: false, fareEnable: false, isItineraryLoading: true, notiMessage: '', notiMessageShow: false, notiVarient: '', showNote: false, pcomName: "", serviceFee: 0, showPop: false, fareRule: false, prepersist: false, bookfail: false, showOvercl: false, plbDiscount: 0, summaryContiueClick: "", reissueRes: "", olditnararyResp: "", enableOldItnarary: false,showCustoms:true,timer:900000,grandTotal: 0,disable:false,seatPrice:0
  ,onwardRoute:"",returnRoute:"",onwardSupp:"",returnSupp:"",baggagePrice:"",selMealprice:"",showPriceCheck:false,tfFlow:false,otherCharge:0,adtBagPrice:0,chdBagPrice:0,adtMealPrice:0,chdMealPrice:0,importTag:"", disabledcn: false,selSeatsList:[],passengerUniqueKey:[],departure_date:'', disableseat: false,inf_dept_date:'',check_price:null,firstName:"",lastName:"",pcode:"",phoneNum:"",email:"",title:"",document:"",indigo_passport:false,showssr_popup:false,cnfEmail:"",gstObj:[],tds_obj:{},total_gst_val:0,total_tds_val:0,enableFlynas:false
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function FlightItinerary(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [payment, setPayment] = useState({ doPayment: false, showContinueButton: true });
  const [request, setRequest] = useState(props.location);
  const [isConinueLoading, setIsConinueLoading] = useState(false);
  const [context, setContext] = useContext(Context);
  const paySec = useRef(null)
  const executeScroll = () => paySec.current.scrollIntoView()
  const [summaryPLB, setsummaryPLB] = useState()


  useEffect(() => {
    window.sessionStorage.removeItem(PAY_DATA);
    window.sessionStorage.removeItem(PAY_REQ);
    window.sessionStorage.removeItem(PAY_RESP);
    window.sessionStorage.removeItem(PRODUCT_TYPE);
    window.sessionStorage.removeItem(SEL_SALAM_ONE)
    window.sessionStorage.removeItem(SEL_SALAM_TWO)
    if (request !== undefined && request.state !== undefined && request.state.req !== undefined) {
      try {
        if (request.state.req !== undefined && request.state.req.flowType !== undefined && (request.state.req.flowType === "IMPORT" || request.state.req.flowType === "ONHOLD"
          || request.state.req.flowType === "INCOMPLETE") || request.state.req.flowType === "REISSUE") {
          if(request.state.req.plb!==undefined && Object.keys(request.state.req.plb).length !== 0) {
            dispatch({ type: 'summaryContiueClick', payload: request.state.req.plb })
            setsummaryPLB(request.state.req.plb)
          }
          //Total fare
          if (request.state.req.sty === "normal") {
            if(request.state.req.flowType === "REISSUE"){
              var userType = (request.state.req.butype === "ta_user") ? "TA" : "STA";
              let servicefee=0;
              if(request.state.req.origns[0].srcorigns[0]!==undefined && request.state.req.origns[0].srcorigns[0].serviceFee!==undefined){
              servicefee = Utilities.calculateServiceFee(request.state.req.origns[0].srcorigns[0].serviceFee, userType, request.state.req);
              }
              dispatch({ type: 'totalprice', payload: request.state.req.origns[0].srcorigns[0].pr.tf })
              if(servicefee!==undefined && servicefee!==0){
                let totalPrice=request.state.req.origns[0].srcorigns[0].pr.tf+servicefee;
                dispatch({ type: 'totalprice', payload: totalPrice })
              }
              if(request.state.req.origns[0].srcorigns[0].tktair==="XY" && request.state.req.origns[0].srcorigns[0].sup === "0"){
                dispatch({ type: 'enableFlynas', payload: true})
              }
            }else{
              if(request.state.req.origns[0].srcorigns[0].tktair==="XY"&& request.state.req.origns[0].srcorigns[0].sup === "0"){
                dispatch({ type: 'enableFlynas', payload: true})
              }
              dispatch({ type: 'totalprice', payload: request.state.req.origns[0].srcorigns[0].pr.tf })
            }
            dispatch({ type: 'onwardSupp', payload: request.state.req.origns[0].srcorigns[0].sup })
            dispatch({type:'departure_date',payload: request.state.req.origns[0].srcorigns[0].seg[0].dd})
          } else {
            if(request.state.req.origns[0]!==undefined && request.state.req.origns[0].srcorigns!==undefined && request.state.req.origns[0].destorigins!==undefined){
              const price = Utilities.addPrice(request.state.req.origns[0].srcorigns[0].pr.tf, request.state.req.origns[0].destorigins[0].pr.tf, "Add");
              dispatch({ type: 'totalprice', payload: Number(price) })
              dispatch({ type: 'returnSupp', payload: request.state.req.origns[0].destorigins[0].sup })
            }
            dispatch({ type: 'onwardSupp', payload: request.state.req.origns[0].srcorigns[0].sup })
            dispatch({type:'departure_date',payload: request.state.req.origns[0].srcorigns[0].seg[0].dd})
            if(request.state.req.origns[0].srcorigns[0].tktair==="XY"&& request.state.req.origns[0].srcorigns[0].sup === "0"){
              dispatch({ type: 'enableFlynas', payload: true})
            }
          }
          if (request.state.req.farechange) {
            dispatch({ type: 'farechange', payload: true })
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({
              type: 'notiMessage', payload: `The earliest fare is not avaliable.The next avaliable best fare is below
          ,kindly review the booking before continue.If you wish to have some other option please`})
            dispatch({ type: 'notiVarient', payload: 'danger' })
          }
          dispatch({ type: 'isItineraryLoading', payload: false })
          if (request.state.req.flowType !== "REISSUE") {
            //let pax=checkPaxList(request.state.req.psngrs)
            dispatch({ type: 'psngers', payload: request.state.req.psngrs })
            dispatch({ type: 'leadPaxObj', payload: request.state.req.psngrs[0] })
          }
          if (request.state.req.bkref !== undefined && request.state.req.bkref !== "") {
            dispatch({ type: 'bkRefNum', payload: request.state.req.bkref })
          }
          dispatch({ type: 'bookingType', payload: request.state.req.flowType })
          if (request.state.req.flowType !== "INCOMPLETE") {
            dispatch({ type: 'enableImprtFlow', payload: true });
            dispatch({ type: 'fareEnable', payload: false })
            dispatch({ type: 'pnr', payload: request.state.req.origns[0].srcorigns[0].pnr })
          } else {
            dispatch({ type: 'fareEnable', payload: true })
          }
          // if (request.state.req.flowType === "ONHOLD") {
          //   if(context.logindata!==undefined && context.logindata.cid!==undefined){
          //     checkCreditLimit(context.logindata.cid, "credit", request.state.req, context.logindata.pcid,false);
          //   }else{
          //     checkCreditLimit(request.state.req.cid, "credit", request.state.req, request.state.req.pcid,false);
          //   }
          //   window.scrollTo(0, 0)
          // }

          //Calling Reissue Service
          if (request.state.req.flowType === "REISSUE") {
            let fareReq = Object.assign({}, request.state.req);
            if(request.state.req && request.state.req.bktype == "IMPORT/REISSUE") {
              dispatch({type:"importTag",payload:"IMPORT/REISSUE"})
            }
            delete fareReq.mappings;
            delete fareReq.oldseg;
            fareReq.region_type=request.state.searchReq.region_type;
            fareReq.req_cur=request.state.searchReq.req_cur;
            getReissueFareInfo(fareReq).then(response => {
              if (response.suc && response.data !== undefined) {
                dispatch({ type: 'reissueRes', payload: response.data })
                dispatch({ type: 'document', payload: response.data.doc })

                //let pax=checkPaxList(response.data.psngrs)
                dispatch({ type: 'psngers', payload: response.data.psngrs })
                dispatch({ type: 'leadPaxObj', payload: response.data.psngrs[0] })
              } else {
                dispatch({ type: 'showPop', payload: true })
                dispatch({ type: 'fareRule', payload: true })
              }
            })
            // call gst with company id
            AttCommonSerivce.getFetchGSTDetails({cid:request.state.req.cid}).then(response => {
              if(response.data.suc && response.data.data.length !== 0) {
                dispatch({ type: 'gstObj', payload: response.data.data[0] })
              }
            })
            //Call OLD Itinarary service:
            const payload = {
              "tid": request.state.req.oldtid
            }
            getOldItnarary(payload).then(response => {
              if (response.success && response.data !== undefined) {
                dispatch({ type: 'olditnararyResp', payload: response.data[0] })
                dispatch({ type: 'enableOldItnarary', payload: true })
              }
            })
          }
        } else {
          //let fareReq = request.state.req;
          if(request.state.req.origns[0].srcorigns[0].tktair==="XY" && request.state.req.origns[0].srcorigns[0].sup === "0"){
            dispatch({ type: 'enableFlynas', payload: true})
          }
          let fareReq = Object.assign({}, request.state.req);
          delete fareReq.mappings;
          var onholdEnable = false;
          if (request.state.req.psngrs !== undefined && request.state.req.psngrs !== "") {
            dispatch({ type: 'psngers', payload: request.state.req.psngrs })
            dispatch({ type: 'leadPaxObj', payload: request.state.req.psngrs[0] })

          }
          if (request.state.req.flowType !== undefined && request.state.req.flowType !== "DIRECT") {
            dispatch({ type: 'bkRefNum', payload: request.state.req.bkref })
            dispatch({ type: 'bookingType', payload: request.state.req.flowType })
            onholdEnable = true;
          } else {
            if(request.state.req.origns[0].destorigins) {
              let segDetails = request.state.req.origns[0].destorigins[0].seg
              let ddeptDate = segDetails[segDetails.length - 1].dd
              let limit_year = moment(ddeptDate).subtract(2, 'years');
              let cops_date = new Date(limit_year)
              state.inf_dept_date = cops_date
              dispatch({type:"inf_dept_date",payload:state.inf_dept_date})
            } else {
              let segDetails = request.state.req.origns[0].srcorigns[0].seg
              let ddeptDate = segDetails[0].dd
              let limit_year = moment(ddeptDate).subtract(2, 'years');
              let cops_date = new Date(limit_year)
              state.inf_dept_date = cops_date
              dispatch({type:"inf_dept_date",payload:state.inf_dept_date})
            }
            dispatch({ type: 'bookingType', payload: "DIRECT" })
          }
          delete fareReq.psngrs;
          delete fareReq.flowType;
          if(request.state.req.flowType==="DIRECT"){
          if(context.logindata.region_type!==undefined){
          fareReq.region_type=context.logindata.region_type;
          fareReq.req_cur=context.logindata.cur;
          }else{
          var userData = localStorage.getItem(USER_AUTH_DATA);
          var resObj = JSON.parse(userData);
          fareReq.region_type=resObj.region_type;
          fareReq.req_cur=resObj.cur;
          }
          }
          fireFareRule(fareReq).then(response => {
            if (response.data !== "" && response.data !== undefined) {
              if (response.data.fareRuleRes !== undefined && response.data.fareRuleRes.status === "success") {
                dispatch({ type: 'fareruleResp', payload: response.data.fareRuleRes })
                let pUkey = [];
                if(response.data.fareRuleRes.data[0] && response.data.fareRuleRes.data[0].passengerUniqueKey) {
                  pUkey = response.data.fareRuleRes.data[0].passengerUniqueKey
                }
                dispatch({ type:"passengerUniqueKey", payload:pUkey})
                dispatch({ type: 'fareEnable', payload: true })
                dispatch({ type: 'isItineraryLoading', payload: false })
                dispatch({ type: 'enableImprtFlow', payload: false })
                if (request.state.req.sty === "normal") {
                  dispatch({ type: 'totalprice', payload: request.state.req.origns[0].srcorigns[0].pr.tf })
                } else {
                  const price = Utilities.addPrice(request.state.req.origns[0].srcorigns[0].pr.tf, request.state.req.origns[0].destorigins[0].pr.tf, "Add");
                  dispatch({ type: 'totalprice', payload: Number(price) })
                }
                let onward=request.state.req.origns[0].srcorigns[0].seg[0].da+"-"+request.state.req.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
                let arrval="";
                if(request.state.req.tt==="2" || request.state.req.sty === "split"){
                  arrval=request.state.req.origns[0].destorigins[0].seg[0].da+"-"+request.state.req.origns[0].destorigins[0].seg.slice(-1)[0].ar;
                  dispatch({ type: 'returnSupp', payload: request.state.req.origns[0].destorigins[0].sup })
                  dispatch({ type: 'returnRoute', payload: arrval })
                }
                 dispatch({ type: 'onwardRoute', payload: onward })
                 dispatch({ type: 'onwardSupp', payload: request.state.req.origns[0].srcorigns[0].sup })
                 dispatch({type:'departure_date',payload: request.state.req.origns[0].srcorigns[0].seg[0].dd})
                if(request.state.req.sty === "normal" && request.state.req.tt==="2" && request.state.req.origns[0].srcorigns[0].sup!=="0" && request.state.req.origns[0].destorigins!==undefined &&  request.state.req.origns[0].destorigins[0].sup!=="0"){
                  dispatch({ type: 'tfFlow', payload: true })
                }
                if((request.state.req.origns.length > 0 && request.state.req.origns[0].srcorigns[0].sup==="4") || (request.state.req.origns.length > 0 && request.state.req.origns[0].destorigins!==undefined && request.state.req.origns[0].destorigins.length > 0 && request.state.req.origns[0].destorigins[0].sup==="4")){
                  if(request.state.req.src_country!=="IN" && request.state.req.dest_country==="IN"){
                    dispatch({ type: 'indigo_passport', payload: true })
                  }
                }
                window.scrollTo(0, 0)
              } else {
                dispatch({ type: 'showPop', payload: true })
                dispatch({ type: 'fareRule', payload: true })
                dispatch({ type: 'isItineraryLoading', payload: false })
              }
            } else {
              dispatch({ type: 'showPop', payload: true })
              dispatch({ type: 'fareRule', payload: true })
              dispatch({ type: 'isItineraryLoading', payload: false })
            }
          })
        }
      } catch (err) {
        log.error('Exception occured in createFareRuleReq function---' + err);
      }
    } else {
      dispatch({ type: 'showPop', payload: true })
      dispatch({ type: 'fareRule', payload: true })
      dispatch({ type: 'isItineraryLoading', payload: false })
    }
  }, [request]);

//   useEffect(() => {
//     let check=sessionStorage.getItem(CHECK_BOOKING);
//     let bookingId=sessionStorage.getItem(BOOKING_REF_NO);
//     if (check!==null && check && bookingId!==null && bookingId!==undefined){
//         AuthenticationService.updateStatus({"booking_id":bookingId}).then(response => {
//             sessionStorage.removeItem(CHECK_BOOKING);
//             sessionStorage.removeItem(BOOKING_REF_NO);
//         })
//     }
// }, []);



  const checkMandatoryFlagDOB = () => {
    if(state.onwardSupp==="2"||props.returnSupp ==="2"){ // if any air arabia check condition
      if(state.fareruleResp && state.fareruleResp.data &&state.fareruleResp.data[0] && state.fareruleResp.data[0].adtdob && state.fareruleResp.data[0].adtdob === "yes" ){
      return true
      }else{
        return false
    }
    }else if(state.onwardSupp ==="0" || state.returnSupp ==="0"){ //if any one is gds then mandatory
      return true
    }
    else if(state.onwardSupp!=="0" && state.returnSupp!=="0"){ //if both lcc checking tag
      if(state.fareruleResp && state.fareruleResp.data &&state.fareruleResp.data[0] && state.fareruleResp.data[0].adtdob && state.fareruleResp.data[0].adtdob === "yes" ){
        return true
      }else{
        return false
      }
    }

    else{
      return false
    }
  }
  //Passengers  Data
  // LCC -state.onwardSupp!=="0"
  // GDS - props.origin.sup==="0"
  // Air arabia state.onwardSupp==="2"
  // if any one is Air arabia
   // if both lcc NAT not manditory
  const checkMandatoryFlagNat =() =>{
    if(state.onwardSupp==="2" || state.returnSupp ==="2"  || state.onwardSupp ==="3" || state.returnSupp ==="3"){
      return true
    }
    else{
      return false
    }
  }
  const passengrsData = (data) => {
    dispatch({ type: 'psngers', payload: data })
  }

  //Profile  Data
  const profileData = (data) => {
    dispatch({ type: 'prof', payload: data })
  }

  const travelsData = (data) => {
    dispatch({ type: 'travelsList', payload: data })
  }


  const resultsCall = useCallback((data) => {
    createSearchRequest();
  })

  const updateServiceFee = useCallback((data) => {
    dispatch({ type: 'serviceFee', payload: data })
  })

  const updateDiscount = useCallback((data) => {
    dispatch({ type: 'plbDiscount', payload: data.discount })
    dispatch({ type: 'tds_obj', payload: data })
    
  })

  const sendBookDlg = useCallback((data) => {
    dispatch({ type: 'showPop', payload: true })
    dispatch({ type: 'bookfail', payload: true })
    dispatch({type:'showErrorMsg',payload: data })

  })


  //Calloing fare request and checking the fare available or not
  const countinueBook = useCallback((data) => {
    if (data === "Review") {
      return window.location="/ezytrip/bookings/flight/itinerary";
      // try {
      //   //const request=createFareRuleReq();
      //   dispatch({ type: 'showPop', payload: false })
      //   dispatch({ type: 'fareRule', payload: false })
      //   let fareReq = Object.assign({}, request.state.req);
      //   delete fareReq.mappings;
      //   fireFareRule(fareReq).then(response => {
      //     if (response.data.fareRuleRes !== undefined && response.data.fareRuleRes.status === "success") {
      //       if (!state.enabletimer) {
      //         dispatch({ type: 'enabletimer', payload: true })
      //       } else {
      //         dispatch({ type: 'enabletimer', payload: false })
      //       }
      //        return window.location="/ezytrip/bookings/flight/itinerary";
      //       // return history.push({
      //       //   pathname: '/ezytrip/bookings/flight/itinerary',
      //       //   state: {
      //       //     req: request.state.req
      //       //   }
      //       // });
      //     } else {
      //       dispatch({ type: 'fareRule', payload: true })
      //     }
      //   })
      // } catch (err) {
      //   log.error('Exception occured in countinueBook function---' + err);
      // }
    }
  }, []);

  //Confirmation dialog popup to delete the Branch
  const confirmDlg = () => {
    confirmNomoreDlg('Booking', function () {
      gotoResults();


    });
  };
  const gotoResults = () => {
    return window.location = "/ezytrip/bookings/flight/results";
  }
  //Docs  Data
  const customData = useCallback((data) => {
    let customList = [];
    let remarkList = [];
    if (data) {
      for (let val of data) {
        const payload = {
          "clbl": val.flbl,
          "cval": val.fval,
          "cmand": val.fmand
        }
        customList.push(payload);

        if (isNotNull(val.rm_type)) {
          const remarkpayload = {
            "key": val.rm_type,
            "value": val.fval,
          }
          remarkList.push(remarkpayload);
        }
      }
    }
    dispatch({ type: 'custms', payload: customList })
    dispatch({ type: 'remarks', payload: remarkList })
  }, [state.custms]);

  const validateTitle = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select title for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
  const validateNationality =(count, pax)=>{
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Nationality for ' + pax +" "+count})
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
  const validateName = (name, type, count, pax) => {
    if (!name) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter ' + type + ' for passenger ' + pax + " " + count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid ' + type + ' for passenger ' + pax + " " + count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
  }

  const validatePassport = (name, count, type) => {
    if (!name) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter passport number for passenger ' + type + " " + +count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid passport number for passenger ' + type + " " + +count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }

  }


  const validateNational = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select nationality for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }

  const validateIssuCntry = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select issued country for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }

  // const validateIssuDate = (count, type) => {
  //   dispatch({ type: 'notiMessageShow', payload: true })
  //   dispatch({ type: 'notiMessage', payload: 'Please select issued Date for passenger ' + type + " " + count })
  //   dispatch({ type: 'notiVarient', payload: 'danger' })
  //   hidemessage();
  //   return false;
  // }

  const validateExpiry = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Expiry Date for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }


  const validateProfile = () => {
    if (state.bookingType === "DIRECT") {
      if (state.prof !== "") {
        if (state.prof.fn !== "" && !characterValidation(state.prof.fn)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid profile first name  ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.ln !== "" && !characterValidation(state.prof.ln)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid  profile last name ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.mn !== "" && !characterValidation(state.prof.mn)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid profile middle name ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.em!==null && state.prof.em !== "") {
          if(!emailValidation(state.prof.em)){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid profile email id' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
          // }else if(state.prof.cem!=="" && !emailValidation(state.prof.cem)){
          //   dispatch({ type: 'notiMessageShow', payload: true })
          //   dispatch({ type: 'notiMessage', payload: 'Please enter valid profile confirm email id '})
          //   dispatch({ type: 'notiVarient', payload: 'danger' })
          //   hidemessage();
          //   return false;
          // }else if(state.prof.em!=="" && state.prof.cem!=="" && state.prof.em!==state.prof.cem){
          //   dispatch({ type: 'notiMessageShow', payload: true })
          //   dispatch({ type: 'notiMessage', payload: 'Email id and confirm email id should same for profile'})
          //   dispatch({ type: 'notiVarient', payload: 'danger' })
          //   hidemessage();
          //   return false;
          // }else if(state.prof.em!=="" && state.prof.cem==""){
          //   dispatch({ type: 'notiMessageShow', payload: true })
          //   dispatch({ type: 'notiMessage', payload: 'Please enter confirm email id'})
          //   dispatch({ type: 'notiVarient', payload: 'danger' })
          //   hidemessage();
          //   return false;
          // }else if(state.prof.phn!=="" && !mobileNumberValidation(state.prof.phn)){
          //   dispatch({ type: 'notiMessageShow', payload: true })
          //   dispatch({ type: 'notiMessage', payload: 'Please enter valid phone number'})
          //   dispatch({ type: 'notiVarient', payload: 'danger' })
          //   hidemessage();
          //   return false;
        }
      }
    }
    return true;
  }


  const validatePax = (check) => {
    var count = 1;
    let pType = {
      "ADT": "Adult",
      "INF": "Infant",
      "CHD": "Child"
    }
    if (state.bookingType === "DIRECT" || state.bookingType === "IMPORT") {
      if (state.psngers && state.psngers.length !== 0) {
        for (let val of state.psngers) {
          if(state.bookingType === "DIRECT"){
          if (val.tl === "") {
            return validateTitle(val.fesno, pType[val.ty]);
          }
          if(state.onwardSupp !== "0"){
          if (val.fn === "") {
            return validateName(false, "first name", val.fesno, pType[val.ty])
          } else {
            if (!characterValidation(val.fn)) {
              return validateName(true, "first name", val.fesno,  pType[val.ty])
            }
          }
        }
          if (val.ln === "") {
            return validateName(false, "last name", val.fesno,  pType[val.ty])
          } else {
            if (!characterValidation(val.ln)) {
              return validateName(true, "last name", val.fesno,  pType[val.ty])
            }
          }
        }
        if(state.bookingType === "DIRECT"){
          //if(state.onwardSupp==="0" || state.returnSupp==="0"){
          if (val.ty==="ADT" && ((val.dob === null || val.dob === "") && (state.fareruleResp.data[0].doc === "yes" || (state.onwardSupp ==="3" || state.returnSupp ==="3") || (state.onwardSupp ==="5" || state.returnSupp ==="5") || (state.onwardSupp ==="6" || state.returnSupp ==="6")))){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.fesno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }else if(val.ty==="ADT" && (val.dob === null || val.dob === "") && (state.onwardSupp ==="4" || state.returnSupp ==="4")){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.fesno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
          // }else{
          //   if ((val.ty==="ADT" && state.fareruleResp.data[0].adtdob === "yes" && state.fareruleResp.data[0].doc === "no" && (val.dob === null || val.dob === "") ) ||( (val.dob === null || val.dob === "") && checkMandatoryFlagDOB())){
          //     dispatch({ type: 'notiMessageShow', payload: true })
          //     dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.fesno })
          //     dispatch({ type: 'notiVarient', payload: 'danger' })
          //     hidemessage();
          //     return false;
          //   }
          // }
          if(checkMandatoryFlagNat() === true && val.doc.na === ""){
            return validateNationality(val.fesno,pType[val.ty])
          }
        }else{
          if ((val.ty==="ADT" && request.state.req!==undefined && request.state.req.doc!==undefined && request.state.req.doc === "yes" && val.dob === null)){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.fesno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
        }

        if (val.ty==="CHD" && val.dob === null){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.fesno })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }


          //Call CHD DOB
          // if( pType[val.ty]==="CHD" && val.dob!==undefined){
          //   var date="2020-01-20";
          //   let newDate=DateUtils.convertStringToDate(new Date());
          //   var dates = new Date().getFullYear()-12+'-'+(new Date().getMonth()-1)+'-'+new Date().getDate();
          //   alert(dates);//2019-01-22
          //   alert(date <= newDate && date <= dates);
          //   if(val.inf.dob <= newDate && val.inf.dob <= dates){
          //    dispatch({ type: 'notiMessageShow', payload: true })
          //    dispatch({ type: 'notiMessage', payload: 'Invalid date of Birth for CHD '+count})
          //    dispatch({ type: 'notiVarient', payload: 'danger' })
          //    hidemessage();
          //    return false;
          //   }
          //  }
          if(state.bookingType === "DIRECT"){
          if (state.onwardSupp !== "0" &&  val.fn !== "" && val.fn !== undefined && val.fn.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'First name should be minimum two characters ' +  pType[val.ty] + "" + val.fesno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
          if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Last name should be minimum two characters ' +  pType[val.ty] + "" + val.fesno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
        }
          //Infant validations
          if (val.inf !== undefined && request.state.req.inf > 0) {
            if(state.bookingType === "DIRECT"){
            if (val.inf.tl === "") {
              return validateTitle(val.fesno, "Infant");
            }
            if (state.onwardSupp !== "0" && val.inf.fn === "") {
              return validateName(false, "first name", val.fesno, "Infant")
            } else {
              if (state.onwardSupp !== "0" && !characterValidation(val.inf.fn)) {
                return validateName(true, "first name", val.fesno, "Infant")
              }
            }
            if (val.inf.ln === "") {
              return validateName(false, "last name", val.fesno, "Infant")
            } else {
              if (!characterValidation(val.inf.ln)) {
                return validateName(true, "last name", val.fesno, "Infant")
              }
            }

            if (state.onwardSupp !== "0" && val.inf !== "" && val.inf.fn !== undefined && val.inf.fn !== "" && val.inf.fn.length === 1) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'First name should be minimum two characters for Infant ' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.inf !== "" && val.inf.ln !== undefined && val.inf.ln !== "" && val.inf.ln.length === 1) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Last name should be minimum two characters for Infant ' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.inf !== "" && val.inf !== undefined &&  (val.inf.dob === "" || val.inf.dob === null)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Pelase select Date of Birth for INF' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }

            //alJazeera conditions handle for inf dob > 14 days from flight date
            if(val.inf !== "" && val.inf !== undefined && val.inf.dob !== "" && val.inf.dob !== null && (state.onwardSupp == "3" || state.returnSupp == "3") && state.departure_date) {
              let departureTime = new Date(state.departure_date).getTime()
              let dobTime = new Date(val.inf.dob).getTime()
              let hourDiff = departureTime - dobTime
              var hDiff = hourDiff / 3600 / 1000; //in hours
              let hours = Math.floor(hDiff);
              if(hours < 336) {
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Date of Birth for INF cannot be less than 14 Days - for Infant ' + val.fesno })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage();
                return false;
              }
            }

            // inf_dept_date
            if (val.inf !== "" && val.inf !== undefined &&  val.inf.dob  !== "" &&  val.inf.dob !== null && state.inf_dept_date) {
              let dobinNum = Number(moment(val.inf.dob).format('YYYYMMDD'))
              let deptinNum = Number(moment(state.inf_dept_date).format('YYYYMMDD'))
              if(dobinNum < deptinNum) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Infant Date of Birth is more than 2 years of Travelling for INF ' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
              }
            }
            if (val.inf !== "" && val.inf !== undefined && state.fareruleResp.data[0] !== undefined && state.fareruleResp.data[0].doc === "yes" && val.inf.doc.exd === null){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Pelase select Expiry Date for INF' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
          }else{
            if (val.inf !== "" && val.inf !== undefined && request.state.req!==undefined && request.state.req.doc!==undefined && request.state.req.doc === "yes" && val.inf.doc.exd === null){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Pelase select Expiry Date for INF' + val.fesno })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
          }
            //Check INF DOB
            // if(val.inf!=="" && val.inf.dob!==undefined){
            //    var date="2020-01-20";
            //    let newDate=DateUtils.convertStringToDate(new Date());
            //    var dates = new Date().getFullYear()-2+'-'+(new Date().getMonth()-1)+'-'+new Date().getDate();
            //    alert(dates);//2019-01-22
            //    alert(date <= newDate && date <= dates);
            //    if(val.inf.dob <= newDate && val.inf.dob <= dates){
            //     dispatch({ type: 'notiMessageShow', payload: true })
            //     dispatch({ type: 'notiMessage', payload: 'Invalid date of Birth for INF '+count})
            //     dispatch({ type: 'notiVarient', payload: 'danger' })
            //     hidemessage();
            //     return false;
            //    }
            //   }
            //Passport validations
            if(state.bookingType === "DIRECT"){
            if (state.fareruleResp.data[0] !== undefined && state.fareruleResp.data[0].doc === "yes") {
              if (val.inf !== undefined && val.inf.doc !== undefined) {
                if (val.inf.doc.na === undefined) {
                  return validateNational(val.fesno, "Infant");
                } else if (val.inf.doc !== undefined && val.inf.doc.isc === undefined) {
                  return validateIssuCntry(val.fesno, "Infant");
                } else if (val.inf.doc !== undefined && val.inf.doc.num === "") {
                  return validatePassport(false, val.fesno, "Infant");
                } else {
                  if (!characterNumberValidation(val.inf.doc.num)) {
                    return validatePassport(true, val.fesno, "Infant");
                  }
                }
              }
            }
          }else if(request.state.req!==undefined && request.state.req.doc!==undefined && request.state.req.doc === "yes"){
            if (val.inf !== undefined && val.inf.doc !== undefined) {
              if (val.inf.doc.na === undefined) {
                return validateNational(val.fesno, "Infant");
              } else if (val.inf.doc !== undefined && val.inf.doc.isc === undefined) {
                return validateIssuCntry(val.fesno, "Infant");
              } else if (val.inf.doc !== undefined && val.inf.doc.num === "") {
                return validatePassport(false, val.fesno, "Infant");
              } else {
                if (!characterNumberValidation(val.inf.doc.num)) {
                  return validatePassport(true, val.fesno, "Infant");
                }
              }
            }
          }
          //Validating Passport and Expiry date for Indigo
        if((request.state.req.origns.length > 0 && request.state.req.origns[0].srcorigns[0].sup==="4") || (request.state.req.origns.length > 0 && request.state.req.origns[0].destorigins!==undefined && request.state.req.origns[0].destorigins.length > 0 && request.state.req.origns[0].destorigins[0].sup==="4")){
            if(state.indigo_passport){
              if (val.inf.doc!==undefined && val.inf.doc.num!==undefined && val.inf.doc.num === ""){
                return validatePassport(false,val.fesno,"Infant");
              }else if(val.inf.doc!==undefined && val.inf.doc.exd!==undefined && val.inf.doc.exd===null){
                return validateExpiry(val.fesno,"Infant");
              }
            }
          }
          //   if(state.bookingType === "DIRECT"){
          //     if (state.fareruleResp.data !== undefined && state.fareruleResp.data[0].doc === "yes" && val.ty === "ADT" && state.psngers[0].dob === "") {
          //     dispatch({ type: 'notiMessageShow', payload: true })
          //     dispatch({ type: 'notiMessage', payload: 'Please select Date of birth for Lead pax' })
          //     dispatch({ type: 'notiVarient', payload: 'danger' })
          //     hidemessage();
          //     return false;

          //   }
          // }
          }
          //Lead pax validations

          if (val.ty === "ADT" && val.lead) {
            if (state.bookingType === "DIRECT" && (val.phc === "" || val.phc === null)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please select phone code for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.phn === "") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter phone number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            } else if (!mobileNumberValidation(val.phn)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid phone number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (state.bookingType === "DIRECT" && val.destnum !== undefined && val.destnum !== "" && !mobileNumberValidation(val.destnum)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid destination contact number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.em === "") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter email id for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            } else if (!emailValidation(val.em)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid email id for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
          }

          //Passport validations
          if(state.bookingType === "DIRECT"){
          if (!check && state.fareruleResp.data[0] !== undefined && state.fareruleResp.data[0].doc === "yes" || state.enableFlynas) {
            if (val.doc.na === "") {
              return validateNational(val.fesno,  pType[val.ty]);
            } else if (val.doc.isc === "") {
              return validateIssuCntry(val.fesno,  pType[val.ty]);
            } else if (val.doc.num === "") {
              return validatePassport(false, val.fesno,  pType[val.ty]);
            }
            // else if(val.doc.isd===""){
            //   return validateIssuDate(val.fesno,  pType[val.ty]);
            // }
            else if(val.doc.exd=== null){
              return validateExpiry(val.fesno,  pType[val.ty]);
            } else {
              if (!characterNumberValidation(val.doc.num)) {
                return validatePassport(true, val.fesno,  pType[val.ty]);
              }
            }
          }
        }else if(request.state.req!==undefined && request.state.req.doc!==undefined && request.state.req.doc === "yes"){
          if (val.doc.na === "") {
            return validateNational(val.fesno,  pType[val.ty]);
          } else if (val.doc.isc === "") {
            return validateIssuCntry(val.fesno,  pType[val.ty]);
          } else if (val.doc.num === "") {
            return validatePassport(false, val.fesno,  pType[val.ty]);
          }
          // else if(val.doc.isd===""){
          //   return validateIssuDate(val.fesno,  pType[val.ty]);
          // }
          else if(val.doc.exd===null){
            return validateExpiry(val.fesno,  pType[val.ty]);
          } else {
            if (!characterNumberValidation(val.doc.num)) {
              return validatePassport(true, val.fesno,  pType[val.ty]);
            }
          }
        }
        //Validating Passport Number for Indigo
        if((request.state.req.origns.length > 0 && request.state.req.origns[0].srcorigns[0].sup==="4") || (request.state.req.origns.length > 0 && request.state.req.origns[0].destorigins!==undefined && request.state.req.origns[0].destorigins.length > 0 && request.state.req.origns[0].destorigins[0].sup==="4")){
          if(state.indigo_passport){
            if (val.doc.num === "") {
              return validatePassport(false,val.fesno,  pType[val.ty]);
            }else if(val.doc.exd===null){
              return validateExpiry(val.fesno,  pType[val.ty]);
            }
          }
        }
        //Validating the Seat
          if(state.bookingType === "DIRECT"){
          var seat="";
          if(val.ty==="ADT" || val.ty==="CHD"){
          if(request.state.req.sty==="normal"){
           if(request.state.req.tt==="1"){
            seat=request.state.req.origns[0].srcorigns[0].pr.seats;
            if(seat!==undefined && seat==="Free" && val.seats.length===0){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please select Seat for ' +val.ty+val.fesno})
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
           }else if(request.state.req.tt==="2"){
            seat=request.state.req.origns[0].srcorigns[0].pr.seats;
            var retSeat=request.state.req.origns[0].destorigins[0].pr.seats;
            if(seat!==undefined && seat!=="" && retSeat!=="" && val.seats.length===0 && (seat==="Free" || retSeat==="Free")){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please select Seat for ' +val.ty+val.fesno})
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
           }
           //Validating the Meal
           if(request.state.req.origns[0].srcorigns[0].sup==="4" && request.state.req.region_type==="International"){
            if(request.state.req.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") || request.state.req.origns[0].srcorigns[0].pr.ftyp.includes("Corporate")){
              let count = 1;
              for(let meal of val.meals){
                 if(meal.label==="Select"){
                  dispatch({ type: 'notiMessageShow', payload: true })
                  if(count===1){
                    dispatch({ type: 'notiMessage', payload: 'Please select Onward Meal for ' +val.ty+val.fesno})
                  }else{
                    dispatch({ type: 'notiMessage', payload: 'Please select Return Meal for ' +val.ty+val.fesno})
                  }
                  dispatch({ type: 'notiVarient', payload: 'danger' })
                  hidemessage();
                  return false;
                 }
                 count= count+1;
              }
              
            }
           }
          }else{
            var retSeat="";
            if(state.onwardSupp==="2"){
              seat=request.state.req.origns[0].srcorigns[0].pr.seats;
            }else if(state.returnSupp==="2" && request.state.req.origns[0].destorigins!==undefined){
              retSeat=request.state.req.origns[0].destorigins[0].pr.seats;
            }
            if(seat!==undefined && retSeat!=="" && val.seats.length===0 && (seat==="Free" || retSeat==="Free")){
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please select Seat for ' +val.ty+val.fesno})
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            //Validating the Meal
            if(request.state.req.region_type==="International" && (request.state.req.origns[0].srcorigns[0].sup==="4" ||  request.state.req.origns[0].destorigins[0].sup==="4")){
              let onwardftype=false;
              let returnftype=false;
              if(request.state.req.origns[0].srcorigns[0].sup==="4" && (request.state.req.origns[0].srcorigns[0].pr.ftyp.includes("Flexi") || request.state.req.origns[0].srcorigns[0].pr.ftyp.includes("Corporate"))){
                onwardftype=true;
              }
              if(request.state.req.origns[0].destorigins[0].sup==="4" && (request.state.req.origns[0].destorigins[0].pr.ftyp.includes("Flexi") || request.state.req.origns[0].destorigins[0].pr.ftyp.includes("Corporate"))){
                returnftype=true;
              }
              if(onwardftype || returnftype){
                let count = 1;
                for(let meal of val.meals){
                  if(meal.label==="Select"){
                    dispatch({ type: 'notiMessageShow', payload: true })
                    if(count===1){
                      dispatch({ type: 'notiMessage', payload: 'Please select Onward Meal for ' +val.ty+val.fesno})
                    }else{
                      dispatch({ type: 'notiMessage', payload: 'Please select Return Meal for ' +val.ty+val.fesno})
                    }
                    dispatch({ type: 'notiVarient', payload: 'danger' })
                    hidemessage();
                    return false;
                  }
                  count= count+1;
                }
              }
            }
          }
        }
        }
        }
        count = count + 1;
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Passenger details' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }
      if(state.custms!==undefined){
      for (let val of state.custms) {
        if(val.cmand == "Yes" && val.cval == "" ){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter Additional info mandatory fileds '})
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
      }
      }
    }
    return true;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }

  const handleMandatory = ()=>{
    for(let pax of state.psngers){
    if(pax.fn == "" ){
      pax.fn = pax.tl
    }
    if(pax.inf !== "" && pax.inf.fn == "" ){
    pax.inf.fn = pax.inf.tl
  }
  }
}

const validatePPInfo=()=>{
  let pType = {
    "ADT": "Adult",
    "INF": "Infant",
    "CHD": "Child"
  }
  for (let val of state.psngers) {
      if (val.doc.na === "") {
        return validateNational(val.fesno,  pType[val.ty]);
      } else if (val.doc.isc === "") {
        return validateIssuCntry(val.fesno,  pType[val.ty]);
      } else if (val.doc.num === "") {
        return validatePassport(false, val.fesno,  pType[val.ty]);
      }else if(val.doc.exd===null){
        return validateExpiry(val.fesno,  pType[val.ty]);
      } else {
        if (!characterNumberValidation(val.doc.num)) {
          return validatePassport(true, val.fesno,  pType[val.ty]);
        }
      }
      //For Infant
      if (val.inf !== undefined && val.inf.doc !== undefined) {
        if (val.inf.doc.na === undefined) {
          return validateNational(val.fesno, "Infant");
        } else if (val.inf.doc !== undefined && val.inf.doc.isc === undefined) {
          return validateIssuCntry(val.fesno, "Infant");
        } else if (val.inf.doc !== undefined && val.inf.doc.num === "") {
          return validatePassport(false, val.fesno, "Infant");
        } else {
          if (!characterNumberValidation(val.inf.doc.num)) {
            return validatePassport(true, val.fesno, "Infant");
          }
        }
      }
}
return true;
}

//For Onhold Summary flow
const countnuePaxUpdate=()=>{
  if(request.state.req!==undefined && request.state.req.doc!==undefined && request.state.req.doc === "yes"){
    if(validatePPInfo()){
      const req1={
        "booking_id": request.state.req.bkref,
        "tid":request.state.req.tid,
        "psngrs": state.psngers
      }
      updatePaxInfo(req1).then(response => {
        if(response.suc) {
          checkCreditLimit(request.state.req.cid, "credit", request.state.req, request.state.req.pcid,false)
        }
      })
    }
  }else{
    const req2={
      "booking_id": request.state.req.bkref,
      "tid":request.state.req.tid,
      "psngrs": state.psngers
    }
    updatePaxInfo(req2).then(response => {
      if (response.suc) {
        checkCreditLimit(request.state.req.cid, "credit", request.state.req, request.state.req.pcid,false)
      }
    })
  }
}

const checkPassportInfo=()=>{
  let checkPassport=true;
  for(let pax of state.psngers){
   if(pax.doc.num!=="" || pax.doc.na!=="" || pax.doc.isc !=="" || pax.doc.isd!==null || (pax.inf!=="" && pax.inf!==undefined && (pax.inf.doc.num!=="" || pax.inf.doc.na!=="" || pax.inf.doc.isc !=="" || pax.inf.doc.isd!==null))){
     checkPassport = false;
     break;
     }
 }
 return checkPassport;
}

const countnueSSRDocument=()=>{
  if(state.bookingType === "DIRECT" &&  checkPassportInfo() && (state.fareruleResp.data[0].doc === "yes" || state.indigo_passport) && (state.onwardSupp==="0" || state.returnSupp==="0")){
    dispatch({ type: 'showssr_popup', payload: true })
  }else{
    dispatch({ type: 'showssr_popup', payload: false })
    continueBooking(false);
  }
}

const closeSSRPopup=()=>{
  dispatch({ type: 'showssr_popup', payload: false })
}

  //=====================================
  // This functiona will Create the Prebook request Json and Call Pre presistance service
  //=====================================
  //Create Categeory
  const continueBooking = async (check) => {
    dispatch({type:"showssr_popup", payload: false})
    //validating branch selection for incomplete flow
    for(let pax of state.psngers){
       if(pax.fn!=="" && !pax.fn.includes(" ")){
       let fn=pax.fn;
       pax.fn=fn.trim();
       }else{
        var str =pax.fn;
        let fn = str.replace(/ +/g, " ");
        pax.fn=fn.trim();
       }
       if(pax.ln!=="" && !pax.ln.includes(" ")){
        let ln=pax.ln;
        pax.ln=ln.trim();
        }else{
        var str =pax.ln;
        let ln = str.replace(/ +/g, " ");
        pax.ln=ln.trim();
        }
      if(pax.dob===""){
        pax.dob=null;
        }
       if(pax.doc.isd===""){
         pax.doc.isd=null;
       }
       if(pax.doc.exd===""){
         pax.doc.exd=null;
       }
     if(pax.inf!=="" && pax.inf!==undefined){
      if(pax.inf.fn!=="" && pax.inf.fn!==undefined){
        let fn=pax.inf.fn;
        pax.inf.fn=fn.trim();
        }
        if(pax.inf.ln!=="" && pax.inf.ln!==undefined){
         let ln=pax.inf.ln;
         pax.inf.ln=ln.trim();
         }
       if(pax.inf.dob===""){
       pax.inf.dob=null;
       }
       if(pax.inf.doc!==undefined && pax.inf.doc.isd===""){
       pax.inf.doc.isd=null;
       }
       if(pax.inf.doc!==undefined && pax.inf.doc.exd===""){
       pax.inf.doc.exd=null;
       }
     }
    }
    if (validateProfile()) {
      if (validatePax(check)) {
        dispatch({type:"disabledcn", payload: true})
        setIsConinueLoading(true)
        dispatch({type:"disableseat",payload:true})
        if(!state.showPriceCheck && ((request.state.req.sty==="normal" && state.onwardSupp==="0") || request.state.req.flowType === "REISSUE" || (request.state.req.sty==="split" && state.onwardSupp==="0" && state.returnSupp==="0"))){
        continuePersistance(check);
      }else{
        dispatch({type:"disable",payload:true})

        let tid="";
        let type="";
        let supplier="";
        let fareinfo = null
        if(request.state.req.sty=="normal"){
          tid=state.fareruleResp.data[0].tid;
          type="normal";
          supplier=state.onwardSupp;

        }else{
          let tidAry=state.fareruleResp.data[0].tid.split("/");
          if(state.onwardSupp!=="0" && state.returnSupp!=="0"){
            tid=state.fareruleResp.data[0].tid;
            type="split";
            supplier=state.onwardSupp+"/"+state.returnSupp;
          }else if(state.onwardSupp!=="0"){
            tid=tidAry[0];
            type="normal";
            supplier=state.onwardSupp;
          }else if(state.returnSupp!=="0"){
            tid=tidAry[1];
            type="normal";
            supplier=state.returnSupp;
          }

        }
        if(state.fareruleResp.data[0].fareinfo) {
          fareinfo = state.fareruleResp.data[0].fareinfo
        }
        for(let pax of state.psngers){
          if(pax.baggage!==undefined && pax.baggage.length!==0){
            pax.baggage = pax.baggage.filter(s=> s.label !== "Select")
          }
          if(pax.meals!==undefined && pax.meals.length!==0){
            pax.meals = pax.meals.filter(s=> s.label !== "Select")
          }
        }
        
        const payload={
            tid: tid,
            sty: type,
            psngrs: state.psngers,
            sup:supplier,
            fareinfo: fareinfo,
            region_type :request.state.req.flowType==="DIRECT"?context.logindata.region_type:request.state.req.region_type
        }
        priceCheckService(payload).then(response => {
          if(response.suc){
            if(response.data && response.data.retail_price) {
            state.check_price = response.data.retail_price
            dispatch({ type: 'check_price', payload: state.check_price })
            }
          if (response.data !== undefined && response.data.isFareChanged){
            dispatch({ type: 'otherCharge', payload: response.data.otcharge })
            dispatch({ type: 'showPriceCheck', payload: true })
          }else{
            continuePersistance(false);
          }
        }else{
          dispatch({ type: 'showPop', payload: true })
          dispatch({ type: 'bookfail', payload: true })
          setIsConinueLoading(false)
          dispatch({type:"disableseat",payload:false})
        }
        })
       }
      }
    }
  }

  const createSeatsList=(pax,segList)=>{
    let selSeats=[];
     for(let seg of segList){
        let paxList=seg.paxlist.filter((obj) => obj.pax_id===pax.sno);
        if(paxList!==undefined){
          for(let pax of paxList){
            if(pax.seat!=="" && pax.st==="Avail"){
              const payload={
                "sno":pax.rno+pax.seat,
                "jtype": seg.jtype,
                "route":seg.dept+"-"+seg.arvl,
                "price": Number(pax.price),
                "sup_price": Number(pax.price),
                "sup_cur_price":pax.sup_cur_price,
                "tid" : seg.tid
                }
                if(pax.seatKey) {
                  payload.seatKey = pax.seatKey
                  payload.segKey = pax.segKey
                }
                  if(pax.seatId) {
                  payload.seatId = pax.seatId
                }
                selSeats.push(payload)
            }
          }
        }
     }
     pax.seats=selSeats;
  }
//CAL GST
const calcualteGST=()=>{
let gst=0;
if(state.bookingType === "DIRECT" && request.state.req.gst!=="" && request.state.req.gst!==undefined){
  if(request.state.req.sty==="normal"){
     gst = parseFloat(request.state.req.gst.total_gst);
  }else{
    if(request.ow_gst!=="" && request.ow_gst!==undefined && request.rt_gst!=="" && request.rt_gst!==undefined ){
      gst = parseFloat(request.ow_gst.total_gst) + parseFloat(request.rt_gst.total_gst);
    }else{
      if(request.state.req.ow_gst!==undefined && request.state.req.rt_gst!==undefined){
        gst = parseFloat(request.state.req.ow_gst.total_gst) + parseFloat(request.state.req.rt_gst.total_gst);
      }
    }
  }
} else if(request.state.req.flowType === "INCOMPLETE" || request.state.req.flowType === "ONHOLD") {
  if(request.state.req.sty==="normal") {
    gst = parseFloat(request.state.req.gst?.total_gst!==undefined?request.state.req.gst?.total_gst:0);
  } else {
    gst = parseFloat(request.ow_gst.total_gst) + parseFloat(request.rt_gst.total_gst);
  }
}
dispatch({type:"total_gst_val",payload:parseFloat(gst)})
return gst;
}
//Cal TDS 
  const calcualteTDS=()=>{
    let tds=0;
    if(state.tds_obj!==undefined && state.tds_obj!=="" && state.tds_obj.total_tds!==0 && state.tds_obj.total_tds !== undefined){
      tds= parseFloat(state.tds_obj.total_tds);
    }
    if(request.state.req.flowType === "INCOMPLETE" || request.state.req.flowType === "ONHOLD") {
      tds= parseFloat(request.state.req.tds.total_tds);
    }
    dispatch({type:"total_tds_val",payload: parseFloat(tds)})
    return tds;
    }

const continuePersistance=(ssrDoc)=>{
  dispatch({ type: 'showPriceCheck', payload: false })
  dispatch({type:"disable",payload:true})
  var tid = "";
  let serviceFee = [];
  var onhold = "";
  if (!state.enableImprtFlow) {
    if (state.fareruleResp !== undefined && state.fareruleResp.data !== undefined) {
      tid = state.fareruleResp.data[0].tid;
      onhold = state.fareruleResp.data[0].hold;
    } else {
      tid = request.state.req.tid;
    }
  } else {
    tid = request.state.req.tid;
  }
  if (!state.enableImprtFlow && request.state.req.origns[0].srcorigns[0] !== undefined && request.state.req.origns[0].srcorigns[0].serviceFee !== undefined) {
    serviceFee = request.state.req.origns[0].srcorigns[0].serviceFee;
  } else {
    if (request.state !== undefined && request.state.req !== undefined && request.state.req.serviceFee !== undefined && request.state.req.serviceFee !== null) {
      serviceFee = request.state.req.serviceFee;
    }
  }
  if (request.state.req.flowType === "REISSUE") {
    onhold = "no";
    tid = state.reissueRes.tid;
  }
  //Flight On fly Markup
  let onFlyMarkup=window.sessionStorage.getItem(ONFLY_FLT_MKP);
  if(state.onwardSupp =="0"){
    handleMandatory()
    }
    //Region and Currency 
    let regionType="";
    let currency="";
    if(request.state.req.flowType==="DIRECT"){
      if(context.logindata!==undefined && context.logindata.region_type!==undefined && context.logindata.region_type!==""){
        regionType=context.logindata.region_type;
      }else{
        regionType=context.logindata.cur==="QAR"?"Qatar":"India";
      }
      currency=context.logindata.cur;
    }else if(request.state.req.region_type!==undefined){
      regionType=request.state.req.region_type;
      currency=request.state.req.req_cur!==undefined?request.state.req.req_cur:request.state.req.cur;
    }else{
      regionType=request.state.searchReq.region_type;
      currency=request.state.searchReq.req_cur!==undefined?request.state.searchReq.req_cur:request.state.searchReq.cur;
    }
    let totalPrice=0;
    if(regionType==="India"){
    //Call GST
    let gstVal= calcualteGST();
    //Call TDS
    let tdsVal= calcualteTDS();
    totalPrice=state.grandTotal;
    if(gstVal!==0 && gstVal!==""){
      totalPrice = parseFloat(totalPrice) + parseFloat(gstVal);
    }
    if(tdsVal!==0 && tdsVal!==""){
      totalPrice = parseFloat(totalPrice) + parseFloat(tdsVal);
    }
   }
    let proReq="";
      if(state.prof===""){
        proReq = {
          "tl": state.title,
          "fn": state.firstName,
          "ln": state.lastName,
          "phc": state.pcode,
          "phn": state.phoneNum,
          "em": state.email,
          "cem": state.cnfEmail,
          "profid": ""
        }
      }
  const prebook = {
    "pbreq": {
      tid: tid,
      echo: request.state.req.echo,
      cur: currency,
      sty: request.state.req.sty,
      office_id: request.state.req.office_id,
      cid: (context.logindata.cid !== 0) ? context.logindata.cid : 0,
      cname: context.logindata.cnm,
      crby: context.logindata.uid,
      "prof": state.prof!==""?state.prof:proReq,
      psngrs: state.psngers,
      docs: state.custms,
      remarks: state.remarks,
      bid: context.logindata.bid,
      butype: context.logindata.butype,
      utype: context.logindata.utype,
      usr_cur_total_amt : totalPrice,
      service_fee: serviceFee,
      bkref: (state.bkRefNum !== undefined) ? state.bkRefNum : "",
      bktype: state.importTag ? state.importTag : state.bookingType,
      pnr: (state.pnr !== undefined) ? state.pnr : request.state.req.pnr,
      trans_id: (request.state.req.trans_id !== undefined) ? request.state.req.trans_id : "",
      bkby: context.logindata.nm,
      cuscode: context.logindata.cuscode,
      plb: (state.fareruleResp !== undefined && state.fareruleResp.data !== undefined && state.fareruleResp.data[0] !== undefined) ? state.fareruleResp.data[0].plb : (request.state.req !== undefined ? summaryPLB : "") ,
      hold: onhold,
      tfflow:state.tfFlow,
      otcharge:state.otherCharge,
      onFlyMkp:onFlyMarkup!==null?Number(onFlyMarkup):0,
      region_type:regionType,
      sales_channel:context.logindata.channel,
      tds:state.tds_obj
    }
  }

   saveBooking(prebook).then((response) => {
    if (response.suc !== undefined && response.res !== null) {
      if (response.res.pbres.status !== undefined && response.res.pbres.status === "inserted") {
        dispatch({ type: 'preBkresponse', payload: response });
        sessionStorage.setItem(CHECK_BOOKING,true)
        sessionStorage.setItem(BOOKING_REF_NO,response.res.pbres.bookingId)
        var paytype = "";
        if (state.bookingType !== undefined && (state.bookingType === "DIRECT" || state.bookingType === "ONHOLD REBOOK"
          || state.bookingType === "INCOMPLETE REBOOK")) {
          if (state.fareruleResp !== undefined && state.fareruleResp.data !== undefined) {
            paytype = state.fareruleResp.data[0].paytpe;
          }
        } else if (state.bookingType !== undefined && (state.bookingType === "IMPORT" || state.bookingType === "INCOMPLETE" || state.bookingType === "ONHOLD" || state.bookingType === "REISSUE")) {
          paytype = "credit";
        }
        //createBookPayload(false, paytype, response.res.pbres, 0, 0, false,ssrDoc);
        if (paytype !== "") {
          if (paytype === "credit") {
            checkCreditLimit(request.state.req.cid, paytype, response.res.pbres, request.state.req.pcid,ssrDoc);//Call service to validate CL
          } else if (paytype === "online") {
            createBookPayload(false, paytype, response.res.pbres, 0, 0, false,ssrDoc);
          }
        } else {
          createBookPayload(false, paytype, response.res.pbres, 0, 0, false,ssrDoc);
        }
      } else {
        setIsConinueLoading(false)
        dispatch({ type: 'showPop', payload: true })
        dispatch({ type: 'prepersist', payload: true })
      }
    } else {
      dispatch({ type: 'showPop', payload: true })
      dispatch({ type: 'prepersist', payload: true })
    }
  });
}



  const checkCreditLimit = async (companyid, paytype, resp, pcompId,ssr) => {
    if (companyid === undefined) {
      companyid = context.logindata.cid;
    }
    if (pcompId === undefined || pcompId === null) {
      pcompId = 0;
    }
    if (Number(companyid) === Number(pcompId)) {
      pcompId = 0;
    }
    const req = {
      "comid": Number(companyid),
      "pid": context.logindata.butype === "ta_user"?0: pcompId,
      "cuscode": resp.flowType == "ONHOLD" ? resp.cuscode : context.logindata.cuscode
    }
    await getCrediLimit(req).then((response) => {
      if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
        const clbal = response.res.data.cbal;
        dispatch({ type: 'creditBal', payload: clbal });
        let totalFae = state.totalprice;
        if (state.serviceFee !== 0 && state.serviceFee !== undefined) {
          totalFae = Utilities.addPrice(state.totalprice, state.serviceFee, "Add");
        }
        if (Number(totalFae) === 0) {
          if (resp.sty === "normal") {
            totalFae = Utilities.addPrice(resp.origns[0].srcorigns[0].pr.tf, state.serviceFee, "Add");
          } else {
            if(resp.origns!==undefined && resp.origns.length!==0 && resp.origns[0].srcorigns!==undefined && resp.origns[0].destorigins!==undefined){
            const price = Utilities.addPrice(resp.origns[0].srcorigns[0].pr.tf + state.serviceFee, resp.origns[0].destorigins[0].pr.tf, "Add");
            totalFae = Utilities.addPrice(price, state.serviceFee, "Add");
            }
          }
        }
        let val = Math.sign(clbal);
        if (Number(val) === 1) {
          if (context.logindata.butype === "sta_user") {
            var pclbal = 0;
            if (response.res.data.pcbal !== undefined) {
              pclbal = response.res.data.pcbal;
            }
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae) && pclbal >= Number(totalFae)) {
                Utilities.subPrice(clbal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false, clbal,ssr);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true, clbal,ssr);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal,ssr);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal,ssr);
              } else if (paytype === "credit" && clbal >= Number(totalFae) && pclbal < Number(totalFae)) {
                dispatch({ type: 'pcomName', payload: response.res.data.pcname });
                dispatch({ type: 'showNote', payload: true });
                if (context.logindata.utype === "att_user") {
                  dispatch({ type: 'showOvercl', payload: true });
                }
                createBookPayload(false, paytype, resp, 0, 0, true, true, clbal,ssr);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, clbal,ssr);
            }
          } else {
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae)) {
                Utilities.subPrice(state.creditBal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false , clbal,ssr);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true,clbal,ssr);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal,ssr);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal,ssr);
              } else {
                createBookPayload(false, paytype, resp, 0, 0, true, true, clbal,ssr);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, clbal,ssr);
            }
          }
        } else {
          createBookPayload(false, paytype, resp, 0, 0, true, true, clbal,ssr);
        }
      } else {
        createBookPayload(false, paytype, resp, 0, 0, true, true,ssr);
      }
    });
  }


  const createBookPayload = (clavail, paytype, response, amt, clbal, offline, ovewriteCL , balance,ssr) => {
    //If CL is available then call book request
    var enableOnline = false;
    if (!clavail && paytype === "credit") {
      enableOnline = true;
    }
    var onhold = "";
    if (state.bookingType !== undefined) {
      if (state.bookingType === "DIRECT") {
        onhold = state.fareruleResp.data[0].hold;
      } else if (state.bookingType === "INCOMPLETE") {
        onhold = "yes";
      } else {
        onhold = "no";
      }
    } else {
      onhold = "no";
    }
    if(ssr && onhold==="yes"){
      onhold="yes";
    }
    //STA MARKUP
    var staMarkup=0;
    if(request.state.req.origns[0]!==undefined && request.state.req.origns[0].srcorigns[0].paxpr!==undefined ){
      let taxes=request.state.req.origns[0].srcorigns[0].pr.tax;
      for(let taxIndex=0; taxIndex < taxes.length; taxIndex++){
        var mak=taxes[taxIndex];
        if(mak!==undefined && mak.stamkp!==undefined){
          staMarkup=mak.stamkp;
        }
      }
    }

    //Service Fee
    var totalPrice = 0;
    if (Number(state.totalprice) !== 0) {
      if(request.state.req.tt=="2" && state.onwardSupp!=="0" && state.returnSupp!=="0"){
        let price=request.state.req.origns[0].srcorigns[0].pr.tf + request.state.req.origns[0].destorigins[0].pr.tf;
        totalPrice=Math.round(Utilities.addPrice(price, Number(state.serviceFee), "Add"));
      }else{
        totalPrice = Math.round(Utilities.addPrice(state.totalprice, Number(state.serviceFee), "Add"));
      }
    } else {
      if (request.state.req !== undefined) {
        if (request.state.req.sty === "normal") {
            if(request.state.req.tt=="2" && state.onwardSupp!=="0" && state.returnSupp!=="0"  && state.onwardSupp){
              let price=request.state.req.origns[0].srcorigns[0].pr.tf + request.state.req.origns[0].destorigins[0].pr.tf;
              totalPrice=Math.round(Utilities.addPrice(price, Number(state.serviceFee), "Add"));
            }else{
              totalPrice=request.state.req.origns[0].srcorigns[0].pr.tf;
            }
          if (state.serviceFee !== 0 && state.serviceFee !== undefined) {
            totalPrice = Math.round(Utilities.addPrice(totalPrice, Number(state.serviceFee), "Add"));
          } else {
            let servicefee = 0;
            var userType = (request.state.req.butype === "ta_user") ? "TA" : "STA";
            servicefee = Utilities.calculateServiceFee(request.state.req.origns[0].srcorigns[0].serviceFee, userType, request.state.req);
            dispatch({ type: 'serviceFee', payload: servicefee });
            if (servicefee !== undefined && servicefee !== 0) {
              totalPrice = Utilities.addPrice(request.state.req.origns[0].srcorigns[0].pr.tf, parseFloat(servicefee), "Add");
            }
          }
        } else {
          if(request.state.req.origns!==undefined && request.state.req.origns[0]!==undefined && request.state.req.origns[0].srcorigns!==undefined && request.state.req.origns[0].destorigins!==undefined){
          const price = Utilities.addPrice(request.state.req.origns[0].srcorigns[0].pr.tf, request.state.req.origns[0].destorigins[0].pr.tf, "Add");
          if (state.serviceFee !== 0 && state.serviceFee !== undefined) {
            totalPrice = Utilities.addPrice(price, Number(state.serviceFee), "Add");
          } else {
            let onwardServiceFee = 0;
            let returnServiceFee = 0;
            onwardServiceFee = Utilities.calculateServiceFee(request.state.req.origns[0].srcorigns[0].serviceFee, userType, request.state.req);
            returnServiceFee = Utilities.calculateServiceFee(request.state.req.origns[0].destorigins[0].serviceFee, userType, request.state.req);
            totalPrice = Utilities.addPrice(price, parseFloat(onwardServiceFee) + parseFloat(returnServiceFee), "Add");
            dispatch({ type: 'serviceFee', payload: Utilities.addPrice(parseFloat(onwardServiceFee), parseFloat(returnServiceFee), "Add") });
          }
        }else{
           totalPrice=request.state.req.origns[0].srcorigns[0].pr.tf;
          if (state.serviceFee !== 0 && state.serviceFee !== undefined) {
            //totalPrice = Utilities.addPrice(totalPrice, Number(state.serviceFee), "Add");
          }else{
            let fee = localStorage.getItem(BOOKING_SERVICE);
            if(fee!==undefined && fee!==0 && fee!==null){
              //totalPrice=totalPrice+parseFloat(fee);
              localStorage.removeItem(BOOKING_SERVICE);
            }else{
              let servicefee = 0;
              var userType = (request.state.req.butype === "ta_user") ? "TA" : "STA";
              servicefee = Utilities.calculateServiceFee(request.state.req.origns[0].srcorigns[0].serviceFee, userType, request.state.req);
              //totalPrice=totalPrice+parseFloat(servicefee);
            }
          }
        }
      }
      }
    }
    //STA ServiceFee
    let staServiceFee=0;
    if(context.logindata.utype==="sta_user" || context.logindata.butype==="sta_user"){
      let fee = localStorage.getItem(STA_SERVICE_FEE);
      if(fee!==undefined && fee!==0 && fee!==null){
        staServiceFee=fee;
      }
      localStorage.removeItem(STA_SERVICE_FEE);
      }
    //PLB Dicount
    if (state.plbDiscount !== undefined && state.plbDiscount !== 0) {
      totalPrice = Utilities.addPrice(totalPrice, state.plbDiscount, "Sub");
      amt = amt - state.plbDiscount;
      localStorage.removeItem(PLB_DISCOUNT);
    } else {
      let discount = localStorage.getItem(PLB_DISCOUNT);
      totalPrice = Utilities.addPrice(totalPrice, discount, "Sub");
      amt = amt - discount;
      localStorage.removeItem(PLB_DISCOUNT);

    }
    let isTfFlow=false;
    if(request.state.req.sty==="normal" && state.onwardSupp!==""  && state.onwardSupp!=="0"){
      isTfFlow=true;
    }else if(request.state.req.sty==="split" && state.onwardSupp!=="" && state.returnSupp!=="" && state.returnSupp!==undefined && (state.onwardSupp!=="0" || state.returnSupp!=="0")){
      isTfFlow=true;
    }
    //FOr TF Flow Baggage and Meal
    if(state.adtBagPrice!==0 || state.chdBagPrice!==0 || state.adtMealPrice!==0 || state.chdMealPrice!==0){
      totalPrice=Number(totalPrice) + Number(state.adtBagPrice) + Number(state.chdBagPrice) + Number(state.adtMealPrice) + + Number(state.chdMealPrice)
    }
    //Fpr TF OT
    if(request.state.req.sty==="normal" && state.otherCharge!==0){
      totalPrice=Number(totalPrice)+Number(state.otherCharge);
    }else if(request.state.req.sty==="split"){
      if(state.otherCharge!==undefined && state.otherCharge!==0 && state.onwardSupp!=="0" && state.returnSupp!=="0"){
        let OtAry=state.otherCharge.split("/");
        let total=Number(OtAry[0])+Number(OtAry[1]);
        totalPrice=Number(totalPrice)+Number(total);
      }else{
        totalPrice=Number(totalPrice)+Number(state.otherCharge);
      }
    }

    //Seat Price
   if(state.seatPrice!==0){
    totalPrice=Number(totalPrice)+Number(state.seatPrice);
   }

   let onFlyMarkup=window.sessionStorage.getItem(ONFLY_FLT_MKP);
   if(onFlyMarkup!==null){
    totalPrice=Number(totalPrice)+Number(onFlyMarkup);
   }

   //GST
    if(state.total_gst_val!==0 && state.total_gst_val!==""){
      totalPrice = parseFloat(totalPrice) + state.total_gst_val;
    }else{
    let gstVal= calcualteGST();
    totalPrice = parseFloat(totalPrice) + parseFloat(gstVal);
    }
    //TDS
    if(state.total_tds_val!==0 && state.total_tds_val!==""){
      totalPrice = parseFloat(totalPrice) + state.total_tds_val;
    }else{
    let tdsVal= calcualteTDS();
    totalPrice = parseFloat(totalPrice) + parseFloat(tdsVal);
    }
    var bookingType="";
    if(state.importTag!==undefined && state.importTag!==""){
      bookingType=state.importTag;
    }else{
      bookingType=(state.bookingType !== undefined) ? state.bookingType : request.state.req !== undefined && request.state.req.flowType;
    }
    if(request.state.req.onhold_sty) {
      localStorage.setItem(ONHOLD_STY,request.state.req.onhold_sty)
    }
    var region="";
    var currency="";
    if(request.state.req.flowType === "DIRECT"){
      region=context.logindata.region_type;
      currency=context.logindata.cur;
    }else if(request.state.req.region_type!==undefined){
      region=request.state.req.region_type;
      currency=request.state.req.req_cur;
    }else{
      region=request.state.searchReq.region_type;
      currency=request.state.searchReq.req_cur;
    }
    

    const payload = {
      tid: response.tid,
      bookingId: response.bookingId,
      price: totalPrice,
      cur: currency,
      diffamt: amt,
      isclavail: clavail,
      enableOnlinePay: false,
      enablePayment : (context.logindata.roles !== undefined && context.logindata.roles.includes('C_P_M')) ? true : false,
      tt: request.state.req.tt,
      sty: request.state.req.sty,
      paytype: paytype,
      cid: context.logindata.cid,
      onhold: onhold,
      mappings: request.state.req.mappings,
      creditBal: clbal,
      bid: context.logindata.bid,
      butype: context.logindata.butype,
      pcid: context.logindata.pcid,
      ofcId: request.state.req.office_id,
      utype: context.logindata.utype,
      service_fee: state.serviceFee,
      bookingType: bookingType,
      pnr: (request.state.req !== undefined && request.state.req.pnr) ? request.state.req.pnr : request.state.req !== undefined && request.state.req.origns[0] !== undefined && request.state.req.origns[0].srcorigns[0] !== undefined && request.state.req.origns[0].srcorigns[0].pnr,
      enableOffline: offline,
      enableOvrwriteCL: ovewriteCL,
      cname: context.logindata.cnm,
      overwriteRole: (context.logindata.roles !== undefined && context.logindata.roles.includes('O_C_L_E')) ? true : false,
      plb: (state.fareruleResp !== undefined && state.fareruleResp.data !== undefined && state.fareruleResp.data[0] !== undefined) ? state.fareruleResp.data[0].plb : "",
      ttl: response.tkt_time_limit,
      oldseg: (request.state.req !== undefined && request.state.req.oldseg !== undefined) ? request.state.req.oldseg : [],
      tkts: (request.state.req !== undefined && request.state.req.tkts !== undefined) ? request.state.req.tkts : [],
      trans_id: response.trans_id,
      cbal:parseFloat(balance),
      stamarkup:staMarkup,
      staservice:staServiceFee,
      custCode:context.logindata.cuscode,
      tkt_user_id: context.logindata.uid,
      segments:request.state!==undefined && request.state.req!==undefined && request.state.req.segments!==undefined?request.state.req.segments:[],
      isTravelFusion:isTfFlow,
      supplier:state.onwardSupp+"/"+state.returnSupp,
      otCharge:state.check_price,
      isSSR:ssr,
      region_type :region,
      req_currency:currency,
      enableOveride:(Number(context.logindata.cid)===231 || Number(context.logindata.cid)===232)?true:false
    }
    if(request.state.req.onhold_sty) {
      payload.onhold_sty = "split"
    }
    if(request.state.req.splitted_pax && request.state.req.splitted_pax.length > 0) {
      payload.splitted_pax = request.state.req.splitted_pax
      payload.pr = request.state.req.origns[0].srcorigns[0].pr
      payload.paxpr = request.state.req.origns[0].srcorigns[0].paxpr
    }
    dispatch({ type: 'bdata', payload: payload });
    setPayment({ doPayment: true, showContinueButton: false })
    executeScroll()

  }

  /**
   * @description:For in-complete flow when we click on clickhere(for fare change it will come) below code will execute
   * @author: Rambabu
   * @param {*}
   * @date : 08-12-2020
   */
  const discrdBooking = () => {
    const request_obj = {
      booking_id: request.state.req.bookingId
    }
    //Calling Discard booking API
    callDiscardBook(request_obj).then((resp) => {

      if (resp.success) {

        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Booking Discarded successfully' })
        dispatch({ type: 'notiVarient', payload: 'success' });
        hidemessage();
        var response = request.state.req;
        response.flowType = "INCOMPLETE REBOOK";
        response.bktype = "INCOMPLETE REBOOK";

        if (response.tt !== 3) {
          response = convertOnholdObj(response);
        } else {
          response = convertMultiCityObj(response);
        }
        response.clstyp = request.state.req.origns[0].srcorigns[0].cl;
        response.ns = false;
        var userData = localStorage.getItem(USER_AUTH_DATA);
        var resObj = JSON.parse(userData);
        resObj.cid = request.state.req.cid;
        resObj.butype = "ta_user";
        updateContext(resObj);
        if (request.state.req.sty === "normal") {
          history.push({
            pathname: '/ezytrip/bookings/flight/results',
            state: {
              req: response
            }
          });
        } else {
          history.push({
            pathname: '/ezytrip/bookings/flight/flightbyflight',
            state: {
              req: response
            }
          });
        }

      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Booking Discard failed.Please try again later' })
        dispatch({ type: 'notiVarient', payload: 'danger' });
        hidemessage();
      }
    })
  }
  // Creating the Search request
  const createSearchRequest = () => {
    if (request.state.searchReq !== undefined) {
      if (request.state.searchReq.flowType === "DIRECT" || request.state.searchReq.flowType === "REISSUE") {
        var userData = localStorage.getItem(USER_AUTH_DATA);
        var resObj = JSON.parse(userData);
        resObj.cid = request.state.searchReq.cid;
        resObj.butype = request.state.searchReq.butype;
        updateContext(resObj);
        if (request.state.searchReq.sty === "normal"){
          history.push({
            pathname: '/ezytrip/bookings/flight/results',
            state: {
              req: request.state.searchReq,
              flow:request.state.req.bktype
            }
          });
        } else if(request.state.searchReq.flowType!=="REISSUE") {
          history.push({
            pathname: '/ezytrip/bookings/flight/flightbyflight',
            state: {
              req: request.state.searchReq
            }
          });
        }else if(request.state.searchReq.sty==="split" && request.state.searchReq.flowType==="REISSUE"){
          history.push({
            pathname: '/ezytrip/bookings/flight/results',
            state: {
              req: request.state.searchReq
            }
          });
        }
      }
    }else{
      if(request.state.req.flowType === "IMPORT"){
        return window.location = "/ezytrip/dashboard";
      } else if (request.state.req.flowType  === "INCOMPLETE" || request.state.req.flowType  === "ONHOLD") {
        return window.location = "/ezytrip/dashboard/bookingsummary/" + request.state.req.bkref;
      }
  }
}

  //updating context
  const updateContext = (resObj) => {
    localStorage.setItem(USER_AUTH_DATA, JSON.stringify(resObj));
    if (resObj) {
      setContext({ logindata: resObj });
    }
  }

  //Converting Onward and Rountrip obj
  const convertOnholdObj = (response) => {
    var src = request.state.req.origns[0].srcorigns[0].seg[0].da;
    var dest = request.state.req.origns[0].srcorigns[0].seg.slice(-1)[0].ar;
    if (response.mappings) {
      for (let val of response.mappings) {
        if (val.airportCode === src) {
          response.srcCity = val.cityName;
          response.src = [val];
        }
        if (val.airportCode === dest) {
          response.destCity = val.cityName;
          response.dest = [val];
        }
      }
    }
    response.srcCode=src;
    response.destCode=dest;
    response.dd=DateUtils.prettyDate(request.state.req.origns[0].srcorigns[0].seg[0].dd, "YYYY-MM-DD");
    response.srcDate=DateUtils.prettyDate(request.state.req.origns[0].srcorigns[0].seg[0].dd, "ddd, DD MMM YYYY");
    if(response.tt==="2"){
      response.ad=DateUtils.prettyDate(request.state.req.origns[0].destorigins[0].seg[0].ad, "YYYY-MM-DD");
      response.destDate=DateUtils.prettyDate(request.state.req.origns[0].destorigins[0].seg[0].ad, "ddd, DD MMM YYYY");
    }
   return response;
}

  //Converting Multicity
  const convertMultiCityObj = (response) => {
    var search = new Object();
    if (request.state.req.origns[0].srcorigns) {
      for (let src of request.state.req.origns[0].srcorigns) {
        if (src.ref !== undefined) {
          if (src.ref === 1) {
            search.dep1 = src.seg[0].da;
            search.arr1 = src.seg.slice(-1)[0].ar;
            search.dd1 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptOne = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty1 = getCityName(src.seg[0].da);
            search.acty1 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataOne = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataOne = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          } else if (src.ref === 2) {
            search.dep2 = src.seg[0].da;
            search.arr2 = src.seg.slice(-1)[0].ar;
            search.dd2 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptTwo = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty2 = getCityName(src.seg[0].da);
            search.acty2 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataTwo = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataTwo = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          } else if (src.ref === 3) {
            search.dep3 = src.seg[0].da;
            search.arr3 = src.seg.slice(-1)[0].ar;
            search.dd3 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptThree = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty3 = getCityName(src.seg[0].da);
            search.acty3 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataThree = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataThree = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          } else if (src.ref === 4) {
            search.dep4 = src.seg[0].da;
            search.arr4 = src.seg.slice(-1)[0].ar;
            search.dd4 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptFour = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty4 = getCityName(src.seg[0].da);
            search.acty4 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataFour = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataFour = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          } else if (src.ref === 5) {
            search.dep5 = src.seg[0].da;
            search.arr5 = src.seg.slice(-1)[0].ar;
            search.dd5 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptFive = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty5 = getCityName(src.seg[0].da);
            search.acty5 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataFive = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataFive = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          } else if (src.ref === 6) {
            search.dep6 = src.seg[0].da;
            search.arr6 = src.seg.slice(-1)[0].ar;
            search.dd6 = DateUtils.prettyDate(src.seg[0].dd, "YYYY-MM-DD");
            search.deptSix = DateUtils.prettyDate(src.seg[0].dd, "ddd, DD MMM YYYY");
            search.dcty6 = getCityName(src.seg[0].da);
            search.acty6 = getCityName(src.seg.slice(-1)[0].ar);
            search.orignDataSix = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg[0].da);
            search.destDataSix = request.state.req.mappings.filter((obj) => obj.airportCode === src.seg.slice(-1)[0].ar);
          }
        }
      }
    }
    search.cls = request.state.req.origns[0].srcorigns[0].cl;
    search.adt = request.state.req.adt;
    search.chd = request.state.req.chd;
    search.inf = request.state.req.inf;
    response.search = search
    return response;
  }


  const getCityName = (code) => {
    if (request.state.req.mappings) {
      for (let val of request.state.req.mappings) {
        if (val.airportCode === code) {
          return val.cityName;
        }
      }
    }
  }



//set grand total getting from tripcost props
  const setGrandTotal = (gt) => {
    if(gt > 0 && (state.grandTotal === 0 || gt !== state.grandTotal)) {
      dispatch({ type: "grandTotal" , payload: gt}) //grand total
    }
  }


  const handleCLClose = () => {
    dispatch({ type: 'showNote', payload: false });
  }

  const handlePriceClose = () => {
    dispatch({ type: 'showPriceCheck', payload: false });
  }

  const handleClose = () => {
    dispatch({ type: 'showPop', payload: false });
  }

  const backTohome = () => {
    return window.location = "/ezytrip/dashboard";
  }

  const hideCustoms = () => {
    dispatch({ type: 'showCustoms', payload: false })
  }

  const updatePrice=(obj)=>{
    dispatch({ type: 'baggagePrice', payload: obj })
  }

  const updateMealprice=(obj)=>{
    dispatch({ type: 'selMealprice', payload: obj })
  }

  const updateAdtPrice=(obj)=>{
    state.adtBagPrice=obj;
  }

  const updateChdPrice=(obj)=>{
    state.chdBagPrice=obj;
  }

  const updateAdtMealPrice=(obj)=>{
    state.adtMealPrice=obj;
  }

  const updateChdMealPrice=(obj)=>{
    state.chdMealPrice=obj;
  }

  const updateSeatPrice=(price)=>{
    dispatch({ type: 'seatPrice', payload: price })
  }

  const updateSelectedSeats=(segList)=>{
    for(let pax of state.psngers){
      createSeatsList(pax,segList);
    }
  }

  const updateFName=(nm)=>{
    dispatch({ type: 'firstName', payload: nm })
   }

   const updateLName=(nm)=>{
     dispatch({ type: 'lastName', payload: nm })
    }

    const updatePhoneNum=(num)=>{
     dispatch({ type: 'phoneNum', payload: num })
    }

    const updateCode=(code)=>{
     dispatch({ type: 'pcode', payload: code })
    }

    const updateEmail=(em)=>{
     dispatch({ type: 'email', payload: em })
    }

    const updateTitle=(title)=>{
      dispatch({ type: 'title', payload: title })
    }

    const updateCnfEmail=(mail)=>{
      dispatch({ type: 'cnfEmail', payload: mail })
    }

  return (
    <>
      <Header branchId={context.logindata.bid} companyId={context.logindata.cid} />
      {/* <ModifySearch /> */}
      {/* <ItineraryStep /> */}
      <div className="container itineraryPage mt-4">
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
        {state.farechange &&
          <Link to='#' onClick={discrdBooking}><span>ClickHere</span></Link>}


        {state.isItineraryLoading ? (<div class="loaderPageBackdrop">
          <div class="loaderPage">
            <div class="loaderIcon"></div>
          </div>
        </div>
        ) : ""}
        <Row className="itinearyPagetab">
          <Col lg={request.state.req.flowType === "REISSUE" && state.enableOldItnarary ? '12' : '9' } md={12}>
            {/* //old Itinerary Details  */}
            {request.state.req.flowType === "REISSUE" && state.enableOldItnarary &&
              <div className="boxLayout resultSection">
                <ShowHide icon="xxx" title="Your Previous Itinerary">
                  <div className="showHide-content pl-0 pr-0 pb-0">
                    <FlightOldItinarary bktype = {request.state.req.bktype == "IMPORT/REISSUE" ? "IMPORT" : request.state.req.bktype} itinarary={state.olditnararyResp} />
                  </div></ShowHide></div>
            }
            {/* //Itinerary Details  */}
            {request.state.req.flowType &&
            <div className="boxLayout resultSection">
              <ShowHide visible="true" icon="travellingItinerary" title="Your Itinerary">
                <div className="showHide-content pl-0 pr-0 pb-0">
                  {request !== undefined &&
                    <FlightInfoDetails bktype = {request.state.req.bktype == "IMPORT/REISSUE" ? "IMPORT" : request.state.req.flowType} response={request.state.req} />
                  }
                </div></ShowHide></div>
             }
            {/* //Profile Details  */}
            {request.state.req !== undefined &&
              <ProfileDetails sendProfile={profileData} sendName={updateFName} sendLastname={updateLName} sendPhcode={updateCode} sendPhone={updatePhoneNum} sendEmail={updateEmail} sendTitle={updateTitle} travelres={travelsData} importFlow={state.enableImprtFlow} importLeadPax={state.leadPaxObj} type={"Flight"} flowType={state.bookingType} sendCEmail={updateCnfEmail} />
            }
            {/* //Travellers Details  request.state.req.origns[0].srcorigns[0].seg[0].ma*/}
            {request.state.req !== undefined && state.bookingType !== "REISSUE" && (state.enableImprtFlow || state.fareEnable) &&
              <TravellersDetails enableFlynas={state.enableFlynas} checkMandatoryFlagNat={checkMandatoryFlagNat()} checkMandatoryFlagDOB={checkMandatoryFlagDOB()} deptDate={request.state.req.origns[0].destorigins ? request.state.req.origns[0].destorigins[0].seg[request.state.req.origns[0].destorigins[0].seg.length-1].dd : request.state.req.origns[0].srcorigns[0].seg[0].dd} srequest={request.state.req} sendPsngersData={passengrsData} travellersList={state.travelsList!==undefined && state.travelsList} fareresp={state.fareruleResp} importFlow={state.enableImprtFlow} paxList={state.psngers!==undefined && state.psngers}
                bookType={state.bookingType} enablePay={payment.doPayment} disable={state.disable} onwardRoute={state.onwardRoute} returnRoute={state.returnRoute} onwardSupp={state.onwardSupp} returnSupp={state.returnSupp} selBagprice={updatePrice} selMealPrice={updateMealprice} prof = {state.prof} passengerUniqueKey={state.passengerUniqueKey} disabledcn={state.disabledcn}
                fname={state.firstName} lname={state.lastName} phone={state.phoneNum} code={state.pcode} email={state.email} title={state.title} indPassportEnable={state.indigo_passport}/>
            }
            {request.state.req !== undefined && state.psngers!==undefined && state.psngers.length !== 0 && state.bookingType === "REISSUE" &&
              <TravellersDetails enableFlynas={state.enableFlynas} document={state.document} checkMandatoryFlagNat={checkMandatoryFlagNat()} checkMandatoryFlagDOB={checkMandatoryFlagDOB()}  deptDate={request.state.req.origns[0].destorigins ? request.state.req.origns[0].destorigins[0].seg[request.state.req.origns[0].destorigins[0].seg.length-1].dd : request.state.req.origns[0].srcorigns[0].seg[0].dd} srequest={request.state.req} sendPsngersData={passengrsData} travellersList={state.travelsList} fareresp={state.fareruleResp} importFlow={state.enableImprtFlow} passengerUniqueKey={state.passengerUniqueKey} paxList={state.psngers}
                bookType={state.bookingType} onwardRoute={state.onwardRoute} returnRoute={state.returnRoute} onwardSupp={state.onwardSupp} returnSupp={state.returnSupp} selBagprice={updatePrice} selMealPrice={updateMealprice}
                fname={state.firstName} lname={state.lastName} phone={state.phoneNum} code={state.pcode} email={state.email} title={state.title}/>
            }
            {request.state.req.flowType === "REISSUE" && state.reissueRes !== undefined && state.reissueRes !== "" &&
              <ReissueFareDetails srequest={request.state.req} reissueData={state.reissueRes} currency={request.state.req.cur} gstObj = {state.gstObj} />
            }
            {/* //Seat Details  */}
            {(state.onwardSupp==="2" || state.returnSupp==="2" || state.onwardSupp==="3" || state.returnSupp==="3" || state.onwardSupp==="4" || state.returnSupp==="4"|| state.onwardSupp==="7" || state.returnSupp==="7") &&
            <SelectsSeats disable={state.disable} paxList={state.psngers} srequest={request.state.req} sendSeatPrice ={updateSeatPrice} sendSelectedSeats={updateSelectedSeats} onwardSupp={state.onwardSupp} returnSupp={state.returnSupp} disableseat = {state.disableseat} currency={context.logindata.cur} />
            }

            {/* //Docment Details  */}
            {state.showCustoms &&
              <CustomInformaion customs={customData} compid={request.state.req.cid} noCustoms={hideCustoms}/>
            }

            {(payment.showContinueButton && !isConinueLoading) && (<div className="d-flex justify-content-end contiNuebtn mt-4 mb-4">
            <Button
              size="lg"
              onClick={createSearchRequest}
              className="continue back ezyIcon icon-rightArrow"
              variant="dark"
            > Back</Button>
              {request.state.req.flowType !== "ONHOLD" &&
              <Button
              size="lg"
              onClick={countnueSSRDocument}
              className={"continue d-flex "}
              variant="primary"
            >Continue <span className="ezyIcon icon-rightArrow"></span></Button>
              }
            {request.state.req.flowType === "ONHOLD" &&
            <Button
              size="lg"
              onClick={countnuePaxUpdate}
              className={"continue d-flex "}
              variant="primary"
            >Continue <span className="ezyIcon icon-rightArrow"></span></Button>
            }
            </div>)}
            {(payment.showContinueButton && isConinueLoading) && (<div className="d-flex justify-content-end mt-4 mb-4"> <Button
              size="lg"
              className={"continue d-flex " + ('progress-bar-striped progress-bar-animated')}
              variant="primary"
            >Continue <span className="ezyIcon icon-rightArrow"></span></Button></div>)}

            {payment.doPayment && (<Payment refProp={paySec} sendToBook={sendBookDlg} bookingdata={state.bdata} userType={context.logindata.butype} bookingType={state.bookingType} showNote={state.showNote} showOverCL={state.showOvercl} />)}
          </Col>
          {request.state.req.flowType !== "REISSUE" &&
            <Col lg={3} md={12}>
              <Sticky>
                <div className="boxLayout costLayout times">
                  <h3 className="ezyIcon icon-timer">
                    {!state.enabletimer &&
                      <span className="d-flex flex-column"><Countdowns sendToResult={resultsCall} sendToReview={countinueBook} time={state.timer} /></span>
                    }
                    {state.enabletimer &&
                      <span className="d-flex flex-column"><Countdowns sendToResult={resultsCall} sendToReview={countinueBook} time={state.timer} /></span>
                    }
                  </h3>
                  <p className="m-0 text-center">Time left to complete your booking</p>
                </div>
                <div className="boxLayout costLayout">
                  <h5 className="panelTitle">Your Trip Cost</h5>
                  {request.state !== undefined && request.state.req !== undefined &&
                    <TripCost tripCost={request.state.req.origns[0]} srequest={request.state.req} cur={context.logindata.cur} flowtype="review" fareRuleResp={state.fareruleResp} summaryContiueClick={summaryPLB} importFlow={state.enableImprtFlow} sendSrviceFee={updateServiceFee} sendPLB={updateDiscount} grandTotal={setGrandTotal} baggagePrice={state.baggagePrice} selMealprice={state.selMealprice} onwardSupp={state.onwardSupp} returnSupp={state.returnSupp} otCharge={state.otherCharge} sendAdtPrice={updateAdtPrice} sendChdPrice={updateChdPrice} sendMealAdtPrice={updateAdtMealPrice} sendMealChdPrice={updateChdMealPrice} seatPrice={state.seatPrice} regionType={request.state.req.flowType==="DIRECT"?context.logindata.region_type:request.state.req.region_type} userType={context.logindata.butype}/>
                  }
                </div>
              </Sticky>
            </Col>
          }
        </Row>
      </div>

      <Modal
        show={state.showNote}
        onHide={handleCLClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>We are Unable to process this booking due to insufficient balance</strong>
              <p>Please check with {state.pcomName} Travel Agent</p>
              {context.logindata.utype === "att_user" &&
                <p>otherwise please proceed with the Override Credit Limit Option</p>
              }
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-dark" onClick={handleCLClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.showPriceCheck}
        onHide={handlePriceClose}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>Fare change Alert!</strong><br/>
              <strong>Do you want to proceed with updated price?</strong>
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              variant="secondary"
              onClick={createSearchRequest}
            >Back</Button>
            <Button
              size="xs"
              onClick={()=>continuePersistance(false)}
              variant="primary"
            >Continue</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.showssr_popup}
        className="importPNRMdl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="popUplayout center">
            <div className="icons warning"></div>
            <div className="text-center">
              <strong>Do you want to Hold the Booking?</strong><br/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
            <Button
              size="xs"
              variant="secondary"
              onClick={closeSSRPopup}
            >No</Button>
            <Button
              size="xs"
              onClick={()=>continueBooking(true)}
              variant="primary"
            >Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.showPop}
        onHide={handleClose}
        className="importPNRMdl requiredFare"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="popUplayout fareNomoreavail center">
          <div className="backGroundgif">
<span className='backGroundbot'></span>
<img src={Expire} />
            </div>
            {state.fareRule &&
              <div className="text-center">
                <strong>Fare no more available</strong>
                <p>Please select another flight</p>
              </div>
            }
            {state.prepersist &&
              <div className="text-center">
                <strong>Pre Persistance is Failed</strong>
                <p>Please check with Administrator</p>
              </div>
            }
            {state.showErrorMsg && state.showErrorMsg !== "TST_fail" &&
              <div className="text-center">
              <strong>{state.showErrorMsg}</strong>
            </div>
             }
             {state.showErrorMsg && state.showErrorMsg == "TST_fail" &&
              <div className="text-center">
              <strong>Booking got failed due to multiple TST</strong>
              <p> Please delete the TST offline and try again</p>
            </div>
             }
            {state.bookfail && state.showErrorMsg !== "TST_fail" &&
              <div className="text-center">
                <strong>Flight availability is gone..</strong>
                <p>Please select another flight</p>
              </div>
            }
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          {!state.enableImprtFlow &&
            <Button
              size="xs"
              variant="primary"
              onClick={createSearchRequest}
            >Back</Button>
          }
          {state.enableImprtFlow &&
            <Button
              size="xs"
              variant="secondary"
              onClick={backTohome}
            >Back to Home</Button>
          }
        </Modal.Footer>
      </Modal>

      <Footer />
    </>
  )
}
export default FlightItinerary