/**
 * @description: Flight Summary page Booking  details component
 * @date:18-09-2020
 * @author:Lakshmi
 *
 */
 import React, { useState, useEffect } from 'react';

 /**
  * @description: This function will show the Booking Details section
  * @date:18-09-2020
  * @author:Lakshmi
  *
  */
 function InsuracnePlanPdf(props) {
   const [state, setstate] = useState()
 
   
   return (
     <>
     {props.response!==undefined && props.response.plan!==undefined &&
       <div className="flightbookinginfoPdf">
       <h5 className="mb-4"> {props.response.plan.scheme_name} Plan Details  </h5>
         <div className="flightheader">
         {props.response.plan!==undefined && props.response.plan.basic_covers.length!==0 && props.response.plan.basic_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span>  {"Inclusive"}
                         </div>
                     </React.Fragment>
          ))}
          {props.response.plan!==undefined && props.response.plan.inclusive_covers.length!==0 && props.response.plan.inclusive_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span>  {"Inclusive"}
                         </div>
                     </React.Fragment>
          ))}
          {props.response.plan!==undefined && props.response.plan.optional_covers.length!==0 && props.response.plan.optional_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span>  {"Inclusive"}
                         </div>
                     </React.Fragment>
          ))}
          {props.response.plan!==undefined && props.response.plan.discount_covers.length!==0 && props.response.plan.discount_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span>  {"Inclusive"}
                         </div>
                     </React.Fragment>
          ))}
           {props.response.plan!==undefined && props.response.plan.excess_covers.length!==0 && props.response.plan.excess_covers.map((plan, index) => (
                     <React.Fragment key={index}>
                         <div className="passengerList">
                         <span>{plan.name}</span>  {"Inclusive"}
                         </div>
                     </React.Fragment>
          ))}
         </div>
       </div>
      }
     </>
   )
 }
 
 
 export default InsuracnePlanPdf;
 