
import React, { useState , useReducer , useContext} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Col, Form, Alert , Modal } from 'react-bootstrap';
import Utilities from '../commonUtils/Utilities';
import Processing from '../common/processingPopUp/Processing';
import FormateCurrency from '../commonUtils/FormateCurrency';
import { Context } from "../../../App";
import Paymentprocessimg from '../../assets/images/Payment-Processing.gif'
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
  } from "../paymentUnique/utils";

/**
 * Initial State Declaration
 */
 const initialState = {
    isclavail:false,terms:false,showTerms: false, notiMessage: '', notiMessageShow: false, notiVarient: '', isLoading: false, pleasewait: false,overcl:false,showOver:false,onlinePay:false,enablePay:false,
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focused: '',
    issuer: '',
    formData: '',
    surchrge:100,
  }
  //=====================================
  // Reducer function for userReact Hook
  // which will update the state
  //=====================================
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, categoryName: ''
        };
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

function ManualPay(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [addriminder, setAddriminder,] = useState(false);
    const [context, setContext] = useContext(Context);

    const handleChange = (e) => {
        const { id, value } = e.target;
        if(id == "tc") {
        if (state.terms) {
          dispatch({ type: 'terms', payload: false })
        } else {
          dispatch({ type: 'terms', payload: true })
        }
      } else {
        dispatch({ type: id, payload: value });
      }
      }

      const hidemessage = () => {
          setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

      const confirmBooking=()=>{
            if (state.terms) {
                const payload={
                        "ptype": "Credit Limit",
                        "amt": props.payobj.totalPrice,
                        "ccode": context.logindata.cur,
                        "crbal": Number(props.payobj.creditBal)-Number(props.payobj.totalPrice),
                        "psta": "Confirmed",
                        "formofpay":""
                }
                props.callBooking(payload);
              } else{
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: "Please accept terms and conditions and proceed" })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage();
              }
      }

      const handleChangeOcl = (e) => {
        const { id, value } = e.target;
        if(id == "cb") {
        if (state.terms) {
          dispatch({ type: 'terms', payload: false })
        } else {
          dispatch({ type: 'terms', payload: true })
        }
      } else {
        dispatch({ type: id, payload: value });
      }
       }

       const closepop=()=>{
        dispatch({ type: 'showOver', payload: false })
        dispatch({ type: 'overcl', payload: false })
       }

      const handleChangeOver = (e) => {
        const { id, value } = e.target;
        if(id == "ov"){
         if(state.overcl){
            dispatch({ type: 'overcl', payload: false })
            dispatch({ type: 'showOver', payload: false })
         }else{
            dispatch({ type: 'overcl', payload: true })
            dispatch({ type: 'showOver', payload: true })
         }
        }
         dispatch({ type: id, payload: value });
       }

       const confirmOverCLBooking=()=>{
        if (state.terms){
          dispatch({ type: 'pleasewait', payload: true })
          dispatch({ type: 'isLoading', payload: true })
            const payload={
                    "ptype": "Credit Limit",
                    "amt": props.payobj.totalPrice,
                    "ccode": context.logindata.cur,
                    "crbal": Number(props.payobj.creditBal)-Number(props.payobj.totalPrice),
                    "psta": "Confirmed",
                    "formofpay":"Override CL"
            }
            props.callBooking(payload);
          } else{
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: "Please accept terms and conditions and proceed" })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
          }
       }

       const confirmPayment=()=>{

       }

       const handleInputChange = ({ target }) => {
        if (target.name === "number") {
          target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
          target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
          target.value = formatCVC(target.value);
        }
        dispatch({ type: target.id, payload: target.value });

        // Crad type validation
        if (target.name === "number") {
          const x = target.value;
          const z = x.substring(0, 2);
          console.log(z);
          const numberFormated = target.value.replace(/\D/g, '')
          var patterns = {
            VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
            MASTER: /^5[1-5][0-9]{14}$/,
            AMEX: /^3[47][0-9]{13}$/,
            ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
            AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
            JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
            DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
            ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
            MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
            DANKORT: /^(5019)\d+$/,
            INTERPAYMENT: /^(636)\d+$/,
            UNIONPAY: /^(62|88)\d+$/,
          }
          for (var key in patterns) {
            if (patterns[key].test(numberFormated)) {

              this.setState({
                issuer: key
              });
              return key
            }
          }
        }
      };

      const handleInputFocus = ({ target }) => {
        dispatch({ type: "focused", payload: target.name });
      };

      const confirmChangeOver = () => {
        dispatch({ type: "overcl", payload: true });
        dispatch({ type: "enablePay", payload: true });
        dispatch({ type: "showOver", payload: false });
       }

    return (
        <>
     <div className="reminderCall">
     <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
          {/* CL PAYMENT */}
      {props.payobj.enableCl &&
      <div className="conffirm crdt">
        <div className="totalPay mb-2">
          <span className="mr-1">The amount going to be debited from your credit balance is : </span> <strong> {context.logindata.cur} <FormateCurrency value={Utilities.addPrice(props.payobj.totalPrice,0,"Add")} /> </strong></div>
          <div className="totalPay">
          <span className="mr-1">The remaining credit will be  : </span><strong>{context.logindata.cur} <FormateCurrency value={Utilities.addPrice(props.payobj.creditBal,props.payobj.totalPrice,"Sub")} /> </strong></div>
        <div className="d-flex mt-2 tc"><Form.Check
        type="checkbox"
        id="tc"
        label=""
        value={state.terms}
        onChange={handleChange}
        custom
        /><label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>
        <div className="d-flex justify-content-end">
        <Button variant="success" size="lg"
        onClick={confirmBooking}
        className={"progress-bar continue d-flex  " + (state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
        disabled = {state.isLoading && state.pleasewait ? true : false } >
        {state.isLoading ? "Processing..." : "Confirm" }
       </Button>
       {state.pleasewait ? <Processing/> : "" }
       </div>
      </div>
      }
      <br/>
      {/* OVERRIDE CL PAY */}
      {props.payobj.enableOvrwriteCL &&  context.logindata.roles.includes('O_C_L_E') &&
        <>
        <div className="overwrite mb-3">
        <div className="creditchk">
        <span>The amount going to be debited from your credit balance is : </span><div className="priceupdates"><span></span> <div className="pricetag"> <em>{context.logindata.cur}</em> <strong> <FormateCurrency value={props.payobj.totalPrice} />  </strong></div></div>
        </div>
        <br/>
        <div className="creditchk">
          <Form.Check
            type="checkbox"
            id="ov"
            label="Override Credit Balance"
            checked={state.overcl}
            value={state.overcl}
            onChange={handleChangeOver}
            custom
        />
        </div>
        {state.overcl &&
          <div className="creditchk note">
          <div className="priceupdates"><span>Note : Your updated credit balance will be  : </span> <div className="pricetag"> <em>{context.logindata.cur}</em><strong> <FormateCurrency value={Utilities.addPrice(props.payobj.creditBal,props.payobj.totalPrice,"Sub")} /> </strong></div></div>
          </div>
        }
        </div>
        {state.overcl && state.enablePay &&
        <>
        <div className="d-flex mt-2 tc"><Form.Check
        type="checkbox"
        id="cb"
        label=""
        value={state.term}
        onChange={handleChangeOcl}
        custom
        />
      <label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of ezytrip</label></div>
      <div className="d-flex justify-content-end">
        <Button variant="success" size="lg"
        onClick={confirmOverCLBooking}
        className={"progress-bar continue d-flex  " + (state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')}
        disabled = {state.isLoading && state.pleasewait ? true : false } >
        {state.isLoading ? "Processing..." : "Confirm" }
        </Button>
        </div>
        {state.pleasewait ? <Processing/> : "" }
        </>
        }
        </>
        }
       {/* ONLINE PAY */}
       {state.onlinePay &&
          <form  onSubmit={confirmPayment}>
          <div className="cardPayment">
        <div className="cardPaymentDtld">
          <div>
            <h6 className="subTitle m-0">Card Payment</h6>
            <p>Enter your card details to continue payment process</p>
          </div>
        </div>
          <>
            <Form.Row  autoComplete="off">
                <Form.Group as={Col} xs={8} className="cardnumber">
                    <input
                    type="tel"
                    name="number"
                    className="form-control"
                    placeholder="Enter Card Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  {/* <span className={"icon cardPhoto " + this.state.issuer}></span> */}
                  <Form.Label>Card number</Form.Label>
                  <span className="ezyIcon icon-creditCards"></span>
                </Form.Group>

                <Form.Group as={Col} xs={8} >
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    autoComplete="off"
                  />
                    <Form.Label>Holder Name</Form.Label>
                    <span className="ezyIcon icon-profile"></span>
                </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} xs={4} >
                  <input
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder="MM/YYYY"
                    pattern="\d\d/\d\d\d\d"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  <Form.Label>Expiry date(MM/YYYY)</Form.Label>
                  <span className="ezyIcon icon-newcalendar"></span>
                </Form.Group>
                <Form.Group as={Col} xs={4}>
                  <input
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder="CVC"
                    pattern="\d{3,4}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  <Form.Label>CVV</Form.Label>
                  <span className="ezyIcon icon-lock"></span>
                  <small>The 3 digit number printed on the back of the card</small>
                </Form.Group>
            </Form.Row>
            </>
            <input type="hidden" name="issuer" value={state.issuer} />
            </div>
            <div className="d-flex mt-2 tc"><Form.Check
        type="checkbox"
        id="tc"
        label=" "
        value={state.terms}
        onChange={handleChange}
        custom
      /><label>I understand and agree with the <a href="/ezytrip/termsandconditions" target="_blank" className="d-inline-flex font-weight-bold">Terms & Conditions</a> of Ezytrip</label></div>
            <div className="conffirm d-flex justify-content-end">
              <Button variant="success" size="lg" className={"progress-bar continue d-flex  " + (state.isLoading ? ' progress-bar-striped progress-bar-animated' : '')} disabled = {state.isLoading && state.pleasewait ? true : false } >{state.isLoading ? "Processing..." : "Confirm and Pay" } </Button>
            </div>
            {state.pleasewait ? <Processing/> : "" }
          </form>
         }



    </div>
    <Modal
        show={state.showOver}
        onHide={closepop}
        className="importPNRMdl creditPop"
        backdrop="static"
        keyboard={false}>

        <Modal.Body className="text-center clpopup" >

        {/* <div className="popIcon" >
          <span className="icons clIcon"></span>
        </div> */}

<div className="backGroundgif">
<span className='backGroundbot'></span>
<span className='aarabQar'>{context.logindata.cur}</span>
<img src={Paymentprocessimg} />
            </div>

        <h4>Credit balance alert!</h4>
        <div className="dstn">
        <p>{props.payobj!==undefined && props.payobj.cname} Doesn't have sufficient credit balance</p>

         <p>Would like to continue with the Override credit balance?</p>
        </div>
        <div className="buttonGrop pb-0 border-0">
        <Button size="xs" variant="secondary" onClick={closepop}>
           Cancel
          </Button>
          <Button variant="primary" size="xs" onClick={confirmChangeOver}>
           Yes
          </Button>
        </div>
        </Modal.Body>

      </Modal>
    </>
    )
}


export default ManualPay;
