
import React, { useState, useEffect } from 'react';
import { Alert, Col, Container, Form, Row,Button,Modal} from "react-bootstrap";
import Utilities from '../../../commonUtils/Utilities';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import { useHistory } from 'react-router-dom';
import {processHotelRefund} from './operations';

function ManualRefund(props) {
    const [state, setState] = useState({})
    const [roomsList, setRoomsList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [enableLoader, setEnableLoader] = useState(false);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const history = useHistory();

    useEffect(() => {
        if (props.location.state!==undefined && props.location.state.req!==undefined) {
            if(props.location.state.req.refundprice.length!==0){
              let index=1;
              let roomsList=props.location.state.req.refundprice;
               for(let room of roomsList){
                room.rno=index;
                index=index+1;
               }
               setRoomsList(roomsList);
            }
        }
      }, [props.location.state]);

    const handleChangePrice = (type,idx) => (e) => {
        const { id, value } = e.target;
        let newRoomList = roomsList.map((room, sidx) => {
          if (idx !== sidx) return room;
          else if(type==="ATT"){
            return {
                ...room, mtaprice: {
                ...room.mtaprice, [id]: value,
                }
            }
          }else{
            return {
              ...room, taprice: {
              ...room.taprice, [id]: value,
              }
          }
          }
        });
        for(let room of newRoomList){
          if(Number(room.rno)===Number(idx+1)){
            if(type==="ATT"){
              room.mtaprice.roomprice=Number(room.mtaprice.bf) + Number(room.mtaprice.taxes) + Number(room.mtaprice.bkmarkup) + Number(room.mtaprice.bkservice) - Number(room.mtaprice.canservice) - Number(room.mtaprice.expediacharges) - Number(room.mtaprice.canmarkup) - Number(room.mtaprice.mtadisc) - Number(room.mtaprice.com_disc);
              room.taprice.roomprice=Number(room.taprice.bf) + Number(room.taprice.taxes) + Number(room.taprice.bkmarkup) + Number(room.taprice.bkservice) + Number(room.taprice.onflymkp) - Number(room.taprice.supfee) - Number(room.taprice.tadisc) - Number(room.mtaprice.canservice) - Number(room.mtaprice.canmarkup) - Number(room.mtaprice.expediacharges);
              room.taprice.expediacharges=Number(room.mtaprice.canmarkup) + Number(room.mtaprice.canservice) + Number(room.mtaprice.expediacharges);
              if(id==="expediacharges" && room.mtaprice.expediacharges!=="" && room.mtaprice.expediacharges!==0){
                room.mtaprice.reffrmsup=Number(room.mtaprice.bf) + Number(room.mtaprice.taxes) - Number(room.mtaprice.expediacharges);
              }
            }else if(type==="TA"){
             room.taprice.roomprice=Number(room.taprice.bf) + Number(room.taprice.taxes) + Number(room.taprice.bkmarkup) + Number(room.taprice.bkservice) + Number(room.taprice.onflymkp) - Number(room.taprice.supfee) - Number(room.taprice.expediacharges) - Number(room.taprice.tadisc) - Number(room.taprice.canmarkup) - Number(room.taprice.canservice);
            }
            }
        }
        setRoomsList(newRoomList)
      }

      const cancelRefund = () => {
        return window.location = "/ezytrip/dashboard/hotel/bookingsummary/" + props.location.state.req.booking_id;
    }

    const totalPrice = (data,type) => {
        let total=0;
        if(data.length!==0){
        if(type==="ATT"){
            for(let val of data){
              if(val.mtaprice.roomprice!=="" && val.mtaprice.roomprice!==undefined){
                if(total===0){
                  total=Utilities.addPrice(Number(val.mtaprice.roomprice),0, "Add");
                }else{
                  total=Utilities.addPrice(total, Number(val.mtaprice.roomprice), "Add");
                }
              }
            }
        }else{
           let tprice=0;
            for(let val of data){
              if(val.taprice.roomprice!=="" && val.taprice.roomprice!==undefined){
                if(total===0){
                  total=Utilities.addPrice(Number(val.taprice.roomprice),0, "Add");
                  tprice=total;
                }else{
                  total=Utilities.addPrice(total, Number(val.taprice.roomprice), "Add");
                  tprice=total;
                }
              }
            }

        }
      }
       return total;
    }

    const totalRefundPrice = (data,type) => {
        let total=0;
        if(data.length!==0){
        if(type==="ATT"){
            for(let val of data){
              if(val.mtaprice.reffrmsup!=="" && val.mtaprice.reffrmsup!==undefined){
                if(total===0){
                  total=Utilities.addPrice(Number(val.mtaprice.reffrmsup),0, "Add");
                }else{
                  total=Utilities.addPrice(total, Number(val.mtaprice.reffrmsup), "Add");
                }
              }
            }
        }
      }
       return total;
    }

    //Proceess Refund flow
    const procesRefundFlow = () => {
        const request={
            "st": props.location.state.req.st,
            "isRoomCancel": false,
            "upby": 1,
            "butype":props.location.state.req.butype,
            "cid":props.location.state.req.cid,
            "pcid":props.location.state.req.pcid,
            "booking_id":props.location.state.req.booking_id,
            "hid": props.location.state.req.hid,
            "rooms":roomsList
          }
        setEnableLoader(true)
        processHotelRefund(request).then((resp) => {
            if (resp.suc){
                setNotiMessageShow(true);
                setNotiMessage('Refund processed successfully...');
                setNotiVarient('success')
                setTimeout(() => {
                    history.push({
                        pathname: '/ezytrip/dashboard/hotel/bookingsummary/' + props.location.state.req.booking_id,
                    });
                }, 2000)
            }else{
                setEnableLoader(false)
                setNotiMessageShow(true);
                setNotiMessage('Something went wrong please try again later');
                setNotiVarient('danger')
            }
        })
}


    const handlePopup=()=>{
        setShowPopup(true);
    }

    const handleComma = (bb, brbds, idx)=>{
        if(idx == brbds.length-1){
            return bb;
        }
        else{
            return bb+","
        }
    }

    return (
        <>
         <div className="container confirmation refundRecord">
             {notiMessageShow &&
                <Alert className="notification" variant={notiVarient} show={notiMessageShow}
                onClose={() => setNotiMessageShow(false)}
                dismissible> {notiMessage}</Alert>
             }
                <div className="topSec summarytopSec">
                    <Button
                        variant="li"
                        className="backbtn ezyIcon icon-rightArrow"
                        onClick={cancelRefund}
                    >Back</Button>
                </div>


        {roomsList.length!==0 && roomsList.map((room, idx) => (
                  <React.Fragment key={idx}>
                <div className="mannualRefundH recordInfo  pb-0">
                <h5 className="title d-flex align-items-center mt-0">Refund Details({props.location.state.req.cur})</h5>

                    <h5 className="layoutTitle title d-flex justify-content-between mt-3"><span className='d-flex'><span className="ezyIcon icon-refund mr-1"></span>Room {idx+1} : {room.rnm}</span> <span>Supplier Ref No : {room.htlconfno}</span>
                    </h5>
                    <div className="cardMainlayout pb-0">
                            <div className="prDetails">
                            <h5 className="layoutTitle  d-flex justify-content-between"><span>Price Details({props.location.state.req.cur})</span></h5>
                            <div className="table-responsive">
                            <table class="m-0 table passDetails">
                            <tr>
                            <th>Company Type</th>
                            <th>Base Fare</th>
                            <th>Taxes</th>
                            <th>Markup</th>
                            <th >Service Fee</th>
                            <th >Supplier Fee</th>
                            <th>Onfly Markup</th>
                            <th className="canclInfo">
                            <h5>Cancellation</h5>Discount</th>
                            <th className="canclInfo">Supplier Charges</th>
                            <th className="canclInfo">Refund Markup</th>
                            <th className="canclInfo">Refund Fee</th>
                            <th className="canclInfo">Commission Discount</th>
                            <th width="10%">Total Amount</th>
                        </tr>
                        <tr>
                        <td><span>ATT  </span></td>
                        <td>
                        <span>{room.mtaprice.bf.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.taxes.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.bkmarkup.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.bkservice.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.supfee.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.onflymkp.toFixed(2)}</span>
                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="mtadisc">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Discount" autoComplete="off"  value={room.mtaprice.mtadisc} />
                        </Form.Group>
                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="expediacharges">
                            <Form.Control type="number"  min={0}  placeholder="Supplier charges" autoComplete="off"  value={room.mtaprice.expediacharges} onChange={handleChangePrice("ATT",idx)}/>
                        </Form.Group>

                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="canmarkup">
                            <Form.Control type="number"  min={0}  placeholder="Refund Markup" autoComplete="off"  value={room.mtaprice.canmarkup} onChange={handleChangePrice("ATT",idx)} />
                        </Form.Group>

                        </td>

                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="canservice">
                            <Form.Control type="number"  min={0}  placeholder="Refund Service" autoComplete="off"  value={room.mtaprice.canservice} onChange={handleChangePrice("ATT",idx)} />
                        </Form.Group>

                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="com_disc">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Commission Disc" autoComplete="off"  value={room.mtaprice.com_disc} />
                        </Form.Group>

                        </td>
                        <td>
                        <span>{room.mtaprice.roomprice.toFixed(2)}</span>
                         </td>
                          </tr>
                          <tr>
                        <td><span>TA  </span></td>
                        <td>
                        <span>{room.taprice.bf.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.taprice.taxes.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.taprice.bkmarkup.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.taprice.bkservice.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.mtaprice.bkservice.toFixed(2)}</span>
                        </td>
                        <td>
                        <span>{room.taprice.onflymkp.toFixed(2)}</span>
                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="taDisc">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Base Fare" autoComplete="off"  value={room.taprice.tadisc} />
                        </Form.Group>

                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="expediacharges">
                            <Form.Control type="number"  min={0} disabled={true} value={room.taprice.expediacharges}  placeholder="Base Fare" autoComplete="off" />
                        </Form.Group>

                        </td>
                        <td className="canclInfo noBrd">
                          <Form.Group as={Col}  controlId="canmarkup">
                            <Form.Control type="number"  min={0}  placeholder="Refund Markup" autoComplete="off"  value={room.taprice.canmarkup} onChange={handleChangePrice("TA",idx)} />
                        </Form.Group>

                        </td>

                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="canservice">
                            <Form.Control type="number"  min={0}  placeholder="Refund Service" autoComplete="off"  value={room.taprice.canservice} onChange={handleChangePrice("TA",idx)} />
                        </Form.Group>

                        </td>
                        <td className="canclInfo">
                          <Form.Group as={Col}  controlId="com_disc">
                            <Form.Control type="number" disabled={true} min={0}  placeholder="Commission Disc" autoComplete="off"  value={0}/>
                        </Form.Group>

                        </td>
                        <td>
                        <span>{room.taprice.roomprice.toFixed(2)}</span>
                         </td>
                          </tr>
                    </table>
                    </div>
                    </div>
                    </div>
                </div>
                </React.Fragment>
                ))}

        <div className="ticketSummerynew newTicketal  d-flex">
            <Col xs={6} className="cardMainlayout mr-2">
            <h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Refund Summary ATT</span></h5>
            <div className="table-responsive">
            <table class="m-0 table">
                <tr>
                    <th width="50%" align="left">Room Type</th>
                    <th width="50%" align="left">Refund from Supplier</th>
                    <th width="50%" align="right" class="text-right">Refund to TA</th>
                </tr>

            {roomsList.length!==0 && roomsList.map((room, idx) => (
                <tr>
                    <td width="50%" align="left">{room.rnm}</td>
                    <td width="50%" align="left">{props.location.state.req.cur} {room.mtaprice.reffrmsup.toFixed(2)}</td>
                    <td width="50%" align="right" class="text-right">{props.location.state.req.cur} {room.mtaprice.roomprice.toFixed(2)}</td>
                </tr>
            ))}
                <tr>

                  <td colSpan="3">
                  <div className='d-flex'>
                    <div className='leftBox'>
                    <span><strong>Total : {props.location.state.req.cur} {<FormateCurrency value={totalRefundPrice(roomsList,"ATT")} />}</strong></span>
                    </div>
                    <div className='rightBox'>
                    <span><strong>{props.location.state.req.cur} {<FormateCurrency value={totalPrice(roomsList,"ATT")} />}</strong></span>
                      </div>
                  </div>
                  </td>


                </tr>
                </table>
            </div>
            </Col>
            <Col xs={6} className="cardMainlayout mr-2">
            <h5 className="layoutTitle  d-flex justify-content-between"><span className="panelTitle ezyIcon icon-document">Refund Summary TA</span></h5>
            <div className="table-responsive">
            <table class="m-0 table">
            <tr>
                    <th width="50%" align="left">Room Type</th>
                    <th width="50%" align="left">Refund from Supplier</th>
                    <th width="50%" align="right" class="text-right">Refund to Customer</th>
                </tr>

            {roomsList.length!==0 && roomsList.map((room, idx) => (
                <tr>
                    <td width="50%" align="left">{room.rnm}</td>
                    <td width="50%" align="left">{props.location.state.req.cur} {room.mtaprice.roomprice.toFixed(2)}</td>
                    <td width="50%" align="right" class="text-right">{props.location.state.req.cur} {room.taprice.roomprice.toFixed(2)}</td>
                </tr>
            ))}
                <tr>
 <td colSpan='3'>
 <div className='d-flex'>
                    <div className='leftBox'>
                    <span><strong>Total : {props.location.state.req.cur}  {<FormateCurrency value={totalPrice(roomsList,"ATT")} />}</strong></span>
                    </div>
                    <div className='rightBox'>
                    <span><strong>{props.location.state.req.cur} {<FormateCurrency value={totalPrice(roomsList,"TA")} />}</strong></span>
                      </div>
                  </div>

 </td>




                </tr>
                </table>
            </div>
            </Col>
            </div>
            <div className="text-right buttonGrop mt-4">
                    <Button
                        size="xs"
                        variant="outline-dark"
                        type="submit"
                        onClick={cancelRefund}
                    > Cancel Refund</Button>
                    <Button
                        size="xs"
                        variant="outline-primary"
                        type="submit"
                        onClick={handlePopup}
                    > Process Refund</Button>
                </div>

                <Modal
                    show={showPopup}
                    onHide={()=>setShowPopup(false)}
                    className="importPNRMdl requiredFare"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <div className="popUplayout center">
                            <div className="icons warning"></div>
                            <div>
                                <strong>Are you sure, you want to process the refund?</strong>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Footer className="justify-content-center">
                        {enableLoader ? '' : <Button variant="outline-dark" onClick={()=>setShowPopup(false)}>
                            Close
                       </Button>}
                        <Button
                            size="xs"
                            variant="outline-primary"
                            type="submit"
                            onClick={procesRefundFlow}
                        > {!enableLoader ? 'Yes' : 'Processing...'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}


export default ManualRefund;
