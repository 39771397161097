import log from "loglevel";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import moment from "moment";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import { Context } from "../../../../../App";
import InsPayment from '../../../bookingFlows/insurance/payment/InsPayment';
import Button from '../../../common/buttons/Button';
import Sticky from '../../../common/sticky/Sticky';
import Footer from '../../../common/Footer';
import Header from '../../../common/Header';
import { characterNumberValidation, characterValidation, emailValidation, mobileNumberValidation } from '../../../commonUtils/FormValidations';
import Utilities from '../../../commonUtils/Utilities';
import {getCrediLimit,saveBooking} from '../operations';
import ProfileDetails from "../../flight/review/ProfileDetails";
import InsTravellers from "./InsTravellers";
import DateUtils from "../../../commonUtils/DateUtils";
export const USER_AUTH_DATA = "userAuthData";
export const CLIENT_IP = "clientIp";
export const CHECK_BOOKING = "checkBooking";
export const BOOKING_REF_NO = "booking_ref";

/**
 * @description:This function will create the review page
 * @author: Lakshmi
 * @param {*}
 * @function Traveller details
 * @date : 31-08-2020
 */

/**
 * Initial State Declaration
 */
const initialState = {
  psngers: [], prof: "",bdata:"",totalPrice:0,bookingType:"DIRECT",cov_end_date:"",cov_duration:0,days:0,covidPrice:0,winterSport:0,notiMessage: '', notiMessageShow: false, notiVarient: '',preBkresponse:"",
  address:"",pobox:"",city:"",title:"",firstName:"",lastName:"",pcode:"",phoneNum:"",email:""
};

//=====================================
// Reducer function for userReact Hook
// which will update the state
//=====================================
const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
};

//This function will show the Air markups List and Add Markup
function InsReview(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [payment, setPayment] = useState({ doPayment: false, showContinueButton: true });
  const [request, setRequest] = useState(props.location);
  const [isConinueLoading, setIsConinueLoading] = useState(false);
  const [context, setContext] = useContext(Context);
  const paySec = useRef(null)
  const executeScroll = () => paySec.current.scrollIntoView()
  const [summaryPLB, setsummaryPLB] = useState()
  const [departureDate, setDepartureDate] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");


  useEffect(() => {
    if (request !==undefined) {
      try {
        //calculateCovrage();
        setDepartureDate(request.state.req.dd)
        setArrivalDate(request.state.req.ad)
    if(request.state!==undefined && request.state.req!==undefined && request.state.req.optional_covers.length!==0){
        for(let val of request.state.req.optional_covers){
             if(val.name.includes("Covid")){
              dispatch({ type: 'covidPrice', payload: Number(val.premium) })
             }else if(val.name.includes("Winter")){
              dispatch({ type: 'winterSport', payload: Number(val.premium) })
             }
        }
       }
      } catch (err) {
        log.error('Exception occured in createFareRuleReq function---' + err);
      }
    }
    window.scrollTo(0, 0)
  }, [request]);


//   useEffect(() => {
//     let check=sessionStorage.getItem(CHECK_BOOKING);
//     let bookingId=sessionStorage.getItem(BOOKING_REF_NO);
//     if (check!==null && check && bookingId!==null && bookingId!==undefined){
//         AuthenticationService.updateStatus({"booking_id":bookingId}).then(response => {
//             sessionStorage.removeItem(CHECK_BOOKING);
//             sessionStorage.removeItem(BOOKING_REF_NO);
//         })
//     }
// }, []);
const handleClose = () => {
  dispatch({ type: 'showPop', payload: false });
}
  const calculateCovrage=()=>{
    let day=request.state.req.days;
    if(day<=7){
      dispatch({ type: 'cov_duration', payload: 7 })
      covarageEndDate(7)
    }else if(day<=14){
      dispatch({ type: 'cov_duration', payload: 14 })
      covarageEndDate(14)
    }else if(day<=21){
      dispatch({ type: 'cov_duration', payload: 21 })
      covarageEndDate(21)
    }else if(day<=31){
      dispatch({ type: 'cov_duration', payload: 31 })
      covarageEndDate(31)
    }else if(day<=45){
      dispatch({ type: 'cov_duration', payload: 45 })
      covarageEndDate(45)
    }else if(day<=60){
      dispatch({ type: 'cov_duration', payload: 60 })
      covarageEndDate(60)
    }else if(day<=90){
      dispatch({ type: 'cov_duration', payload: 90 })
      covarageEndDate(90)
    }else{
      dispatch({ type: 'cov_duration', payload: 365 })
      covarageEndDate(365)
    }
  }

  const covarageEndDate=(days)=>{
    const dateObj = moment(request.state.req.dd).add(days, 'days');
    dispatch({ type: 'cov_end_date', payload: moment(dateObj).toLocaleString() })
  }

  //Passengers  Data
  const passengrsData = (data) => {
    dispatch({ type: 'psngers', payload: data })
  }

  const updateFName=(nm)=>{
   dispatch({ type: 'firstName', payload: nm })
  }

  const updateLName=(nm)=>{
    dispatch({ type: 'lastName', payload: nm })
   }

   const updatePhoneNum=(num)=>{
    dispatch({ type: 'phoneNum', payload: num })
   }

   const updateCode=(code)=>{
    dispatch({ type: 'pcode', payload: code })
   }

   const updateEmail=(em)=>{
    dispatch({ type: 'email', payload: em })
   }

   const updateTitle=(title)=>{
   }

   
  //Profile  Data
  const profileData = (data) => {
    dispatch({ type: 'prof', payload: data })
  }

  const travelsData = (data) => {
    dispatch({ type: 'travelsList', payload: data })
  }


  const validateTitle = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select title for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }
  const validateName = (name, type, count, pax) => {
    if (!name) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter ' + type + ' for passenger ' + pax + " " + count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid ' + type + ' for passenger ' + pax + " " + count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }
  }

  const validatePassport = (name, count, type) => {
    if (!name) {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter passport number for passenger ' + type + " " + +count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Please enter valid passport number for passenger ' + type + " " + +count })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage();
      return false;
    }

  }


  const validateNational = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select nationality for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }

  const validateIssuCntry = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select issued country for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }

  const validateExpiry = (count, type) => {
    dispatch({ type: 'notiMessageShow', payload: true })
    dispatch({ type: 'notiMessage', payload: 'Please select Expiry Date for passenger ' + type + " " + count })
    dispatch({ type: 'notiVarient', payload: 'danger' })
    hidemessage();
    return false;
  }


  const validateProfile = () => {
    if (state.bookingType === "DIRECT") {
      if (state.prof !== "") {
        if (state.prof.fn !== "" && !characterValidation(state.prof.fn)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid profile first name  ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.ln !== "" && !characterValidation(state.prof.ln)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid  profile last name ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.mn !== "" && !characterValidation(state.prof.mn)) {
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please enter valid profile middle name ' })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        } else if (state.prof.em!==null && state.prof.em !== ""){
          if(!emailValidation(state.prof.em)){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please enter valid profile email id' })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
        }
      }
    }
    return true;
  }

  const validatePax = () => {
    var count = 1;
    let pType = {
      "ADT": "Adult",
      "CHD": "Child"
    }
    if (state.bookingType === "DIRECT") {
      if(state.address===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Address'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }else if(state.city===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter City name'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }else if(state.pobox===""){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Postbox number'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }else if(state.pobox!=="" && !mobileNumberValidation(state.pobox)){
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Valid PostBox number'})
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }
      if (state.psngers && state.psngers.length !== 0) {
        for (let val of state.psngers) {
          if (val.gender === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Gender for ' +  val.ty + "" + val.sno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
           }
          if (val.fn === "") {
            return validateName(false, "first name", val.sno, pType[val.ty])
          } else {
            if (!characterValidation(val.fn)) {
              return validateName(true, "first name", val.sno,  pType[val.ty])
            }
          }
          if (val.ln === "") {
            return validateName(false, "last name", val.sno,  pType[val.ty])
          } else {
            if (!characterValidation(val.ln)) {
              return validateName(true, "last name", val.sno,  pType[val.ty])
            }
          }
           if (!val.lead && val.relation === "") {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Relation for ' +  val.ty + "" + val.sno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
           }
        
        if(state.bookingType === "DIRECT"){
          if (val.ty==="ADT" && val.dob === null){
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.sno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
        }
        if (val.ty==="CHD" && val.dob === null){
          dispatch({ type: 'notiMessageShow', payload: true })
          dispatch({ type: 'notiMessage', payload: 'Please select Date of Birth for ' +  pType[val.ty] + "" + val.sno })
          dispatch({ type: 'notiVarient', payload: 'danger' })
          hidemessage();
          return false;
        }
          if(state.bookingType === "DIRECT"){
          if (val.fn !== "" && val.fn !== undefined && val.fn.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'First name should be minimum two characters ' +  pType[val.ty] + "" + val.sno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
          if (val.ln !== "" && val.ln !== undefined && val.ln.length === 1) {
            dispatch({ type: 'notiMessageShow', payload: true })
            dispatch({ type: 'notiMessage', payload: 'Last name should be minimum two characters ' +  pType[val.ty] + "" + val.sno })
            dispatch({ type: 'notiVarient', payload: 'danger' })
            hidemessage();
            return false;
          }
        }
          //Lead pax validations
          if (val.ty === "ADT" && val.lead) {
            if (state.bookingType === "DIRECT" && (val.phc === "" || val.phc === null)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please select phone code for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.phn === "") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter phone number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            } else if (!mobileNumberValidation(val.phn)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid phone number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }else if (val.phn!=="" && val.phn.length!==8) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Phone Number should be 8 digit for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (state.bookingType === "DIRECT" && val.destnum !== undefined && val.destnum !== "" && !mobileNumberValidation(val.destnum)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid destination contact number for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
            if (val.em === "") {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter email id for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            } else if (!emailValidation(val.em)) {
              dispatch({ type: 'notiMessageShow', payload: true })
              dispatch({ type: 'notiMessage', payload: 'Please enter valid email id for Lead pax' })
              dispatch({ type: 'notiVarient', payload: 'danger' })
              hidemessage();
              return false;
            }
          }

          //Passport validations
          if(state.bookingType === "DIRECT"){
            if (val.doc.na === "") {
              return validateNational(val.sno,  pType[val.ty]);
            } else if (val.doc.isc === "") {
              return validateIssuCntry(val.sno,  pType[val.ty]);
            } else if (val.doc.num === "") {
              return validatePassport(false, val.sno,  pType[val.ty]);
            }
            // else if(val.doc.isd===""){
            //   return validateIssuDate(val.sno,  pType[val.ty]);
            // }
            else if(val.doc.exd=== null){
              return validateExpiry(val.sno,  pType[val.ty]);
            } else {
              if (!characterNumberValidation(val.doc.num)) {
                return validatePassport(true, val.sno,  pType[val.ty]);
              }
          }
        }
        }
      } else {
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Please enter Passenger details' })
        dispatch({ type: 'notiVarient', payload: 'danger' })
        hidemessage();
        return false;
      }
    }
    return true;
  }

  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }


  //=====================================
  // This functiona will Create the Prebook request Json and Call Pre presistance service
  //=====================================
  //Create Categeory
  const continueBooking = async () => {
    //validating branch selection for incomplete flow
    for(let pax of state.psngers){
      if(pax.dob===""){
        pax.dob=null;
        }
       if(pax.doc.isd===""){
         pax.doc.isd=null;
       }
       if(pax.doc.exd===""){
         pax.doc.exd=null;
       }
    }
    if (validateProfile()) {
      if (validatePax()){
        dispatch({type:"disabledcn", payload: true})
        setIsConinueLoading(true)
        continuePersistance();
        }
       }
  }

 
const continuePersistance=()=>{
  dispatch({ type: 'showPriceCheck', payload: false })
  dispatch({type:"disable",payload:true})
  let object=request.state.req;
  const policy={
    "adt": object.adt,
    "chd": object.chd,
    "dep_date":object.dd,
    "retu_date": object.ad,
    "cov_stdate": object.dd,
    "cov_endate": object.ad,
    "cov_dur": request.state.req.days,
    "trvl_dur": request.state.req.days,
    "bound_type": object.bound_type,
    "entry_type": object.entry_type,
    "trvl_type": object.entry_type==="Single Entry"?"001":"002",
    "res_country": object.bound_type==="1"?"001":"",
    "insured_name": state.psngers[0].fn+" "+state.psngers[0].ln,
    "cust_type": "Individual",
    "src_country_name":request.state.req.bound_type=="0"?"Qatar":request.state.req.srcCity,
    "src_country_code":request.state.req.bound_type=="0"?"QAT":request.state.req.srcCode
  }

  const addInfo={
    "add":state.address,
    "city":state.city,
    "pobox":state.pobox,
  }
  const prebook = {
    "pbreq": {
      tid: object.response.tid,
      premium_price:Number(object.premium_price),
      covid_price:state.covidPrice,
      winsport_price:state.winterSport,
      client_ip:localStorage.getItem(CLIENT_IP),
      echo: object.echo,
      cur: "QAR",
      cid: (context.logindata.cid !== 0) ? context.logindata.cid : 0,
      cname: context.logindata.cnm,
      crby: context.logindata.uid,
      prof: state.prof,
      psngrs: state.psngers,
      docs: [],
      remarks: "",
      bid: context.logindata.bid,
      butype: context.logindata.butype,
      utype: context.logindata.utype,
      ofcId: "DOHQR28IH",
      usr_cur_total_amt : Number(request.premium_price),
      service_fee: [],
      bktype: "DIRECT",
      bkby: context.logindata.nm,
      cuscode: context.logindata.cuscode,
      policy:policy,
      tkt_user_id: context.logindata.uid,
      addinfo:addInfo,
      plan_name:request.state.req.plan_name,
      region_type: context.logindata.region_type
    }
  }
   saveBooking(prebook).then((response) => {
    if (response.suc !== undefined && response.res && response.res.pbres !== undefined) {
      if (response!== undefined && response.suc) {
        dispatch({ type: 'preBkresponse', payload: response.res });
        sessionStorage.setItem(CHECK_BOOKING,true)
        sessionStorage.setItem(BOOKING_REF_NO,response.res.pbres.bookingId)
        checkCreditLimit(context.logindata.cid, "credit", response.res.pbres, context.logindata.pcid);//Call service to validate CL
      } else {
        setIsConinueLoading(false)
        dispatch({ type: 'showPop', payload: true })
        dispatch({ type: 'prepersist', payload: true })
      }
    } else {
      dispatch({ type: 'showPop', payload: true })
      dispatch({ type: 'prepersist', payload: true })
    }
  });
}

  const checkCreditLimit = async (companyid, paytype, resp, pcompId) => {
    if (companyid === undefined) {
      companyid = context.logindata.cid;
    }
    if (pcompId === undefined || pcompId === null) {
      pcompId = 0;
    }
    if (Number(companyid) === Number(pcompId)) {
      pcompId = 0;
    }
    const req = {
      "comid": Number(companyid),
      "pid": pcompId,
      "cuscode": context.logindata.cuscode
    }
    await getCrediLimit(req).then((response) => {
      if (response !== undefined && response.res !== undefined && response.suc && response.res.data !== undefined) {
        const clbal = response.res.data.cbal;
        dispatch({ type: 'creditBal', payload: clbal });
        let totalFae = Number(request.state.req.response.net_premium);
        let val = Math.sign(clbal);
        if (Number(val) === 1) {
          if (context.logindata.butype === "sta_user") {
            var pclbal = 0;
            if (response.res.data.pcbal !== undefined) {
              pclbal = response.res.data.pcbal;
            }
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae) && pclbal >= Number(totalFae)) {
                Utilities.subPrice(clbal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false, clbal);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true, clbal);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0) && (pclbal === 0 || pclbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
              } else if (paytype === "credit" && clbal >= Number(totalFae) && pclbal < Number(totalFae)) {
                dispatch({ type: 'pcomName', payload: response.res.data.pcname });
                dispatch({ type: 'showNote', payload: true });
                if (context.logindata.utype === "att_user") {
                  dispatch({ type: 'showOvercl', payload: true });
                }
                createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
            }
          } else {
            if (clbal !== 0) {
              if (paytype === "credit" && clbal >= Number(totalFae)) {
                Utilities.subPrice(state.creditBal, Number(totalFae));
                createBookPayload(true, paytype, resp, 0, clbal, false, false , clbal);
              } else if (paytype === "credit" && clbal < Number(totalFae)) {
                var diffAmnt = Utilities.subPrice(Number(totalFae), clbal);
                dispatch({ type: 'diffamt', payload: diffAmnt });
                createBookPayload(false, paytype, resp, diffAmnt, clbal, false, true,clbal);
              } else if (paytype === "credit" && Number(totalFae) > 0 && (clbal === 0 || clbal < 0)) {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
              } else if (paytype === "online") {
                createBookPayload(false, paytype, resp, 0, clbal, true, true, clbal);
              } else {
                createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
              }
            } else {
              createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
            }
          }
        } else {
          createBookPayload(false, paytype, resp, 0, 0, true, true, clbal);
        }
      } else {
        createBookPayload(false, paytype, resp, 0, 0, true, true);
      }
    });
  }


  const createBookPayload = (clavail, paytype, response, amt, clbal, offline, ovewriteCL , balance) => {
    //If CL is available then call book request
    var enableOnline = false;
    if (!clavail && paytype === "credit") {
      enableOnline = true;
    }
    const payload = {
      tid: response.tid,
      bookingId: response.bookingId,
      price: Number(request.state.req.response.net_premium),
      cur: "QAR",
      diffamt: amt,
      isclavail: clavail,
      enableOnlinePay: enableOnline,
      tt: request.state.req.tt,
      sty: request.state.req.sty,
      paytype: paytype,
      cid: context.logindata.cid,
      creditBal: clbal,
      enableOvrwriteCL: ovewriteCL,
      bid: context.logindata.bid,
      butype: context.logindata.butype,
      pcid: context.logindata.pcid,
      ofcId: "DOHQR28IH",
      utype: context.logindata.utype,
      service_fee: [],
      bookingType: "DIRECT",
      cname: context.logindata.cnm,
      overwriteRole: (context.logindata.roles !== undefined && context.logindata.roles.includes('O_C_L_E')) ? true : false,
      cbal:parseFloat(balance),
      custCode:context.logindata.cuscode,
      tkt_user_id: context.logindata.uid,
      enableOnlinePay:false,
      paxList:state.psngers,
      quote_number:request.state.req.quote_no,
      tid:request.state.req.response.tid,
      address:state.address,
      pobox:state.pobox,
      city:state.city
    }
    dispatch({ type: 'bdata', payload: payload });
    setPayment({ doPayment: true, showContinueButton: false })
    //executeScroll()
  }

  const backToResults=()=>{
    history.push({
      pathname: '/ezytrip/bookings/insurance/results',
      state: {
        req: request.state.req
      }
    });
  }

  const updateAddtionalInfo=(obj)=>{
    dispatch({ type: 'address', payload: obj.address });
    dispatch({ type: 'pobox', payload: obj.pobox });
    dispatch({ type: 'city', payload: obj.city });
  }

  return (
    <>
      <Header branchId={context.logindata.bid} companyId={context.logindata.cid} />
      <div className="container insuraanceReviewpage mt-4">
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>

        <Row>
          <Col xs="9">
            {/* //Profile Details  */}
            {request.state.req !== undefined &&
            <ProfileDetails sendProfile={profileData} sendName={updateFName} sendLastname={updateLName} sendPhcode={updateCode} sendPhone={updatePhoneNum} sendEmail={updateEmail} sendTitle={updateTitle} travelres={travelsData} importFlow={false} type={"Insurance"} flowType={state.bookingType} />
            }
            {/* //Travellers Details  */}
            {request.state.req !== undefined &&
              <InsTravellers srequest={request.state.req} sendPsngersData={passengrsData} travellersList={state.travelsList!==undefined && state.travelsList} importFlow={false} paxList={state.psngers!==undefined && state.psngers}
                bookType={state.bookingType} enablePay={payment.doPayment} disable={state.disable} prof={state.prof} passengerUniqueKey={state.passengerUniqueKey} disabledcn={state.disabledcn} sendAddInfo={updateAddtionalInfo} 
                fname={state.firstName} lname={state.lastName} phone={state.phoneNum} code={state.pcode} email={state.email} />
            }

            {(payment.showContinueButton && !isConinueLoading) && (<div className="d-flex justify-content-end mt-4 mb-4">
            <Button
              size="lg"
              onClick={backToResults}
              className="continue back ezyIcon icon-rightArrow"
              variant="dark"
            > Back</Button>
             <Button
              size="lg"
              onClick={continueBooking}
              className={"continue d-flex "}
              variant="primary"
            >Continue <span className="ezyIcon icon-rightArrow"></span></Button>
            </div>)}
            {payment.doPayment && (<InsPayment refProp={paySec} sendToBook={""} bookingdata={state.bdata} userType={context.logindata.butype} bookingType={state.bookingType} showNote={state.showNote} showOverCL={state.showOvercl} backResults={backToResults}/>)}
          </Col>
          <Col xs={3}>
              <Sticky>

                <div className="boxLayout costLayout">
                  <h5 className="panelTitle">Your Trip Details</h5>
                  <div className="costList">
                  <div class="pList pb-2">
    <span className="d-flex">Adults: (<span className="ezyIcon icon-person"> X {request.state.req!==undefined && request.state.req.adt}</span>)</span>
   {request.state.req.chd!==0 &&
    <div class="pricetagg">
   <span className="d-flex">Child: (<span className="ezyIcon icon-child"> X {request.state.req!==undefined && request.state.req.chd}</span>)</span>
   </div>
   }
    
  </div>
  </div>
                  <div class="dropContent">
  <div class="pList pb-2">
    <span className="depDate">Departure Date</span>
    <span className="depDate">Return Date </span>


  </div>
  <div class="pList pb-2">
    <span>{DateUtils.convertToDate(departureDate)}</span>
    <span>{DateUtils.convertToDate(arrivalDate)}</span>


  </div>

</div>

<div class="dropContent">
  <div class="pList pb-2">

    <span className="coverageDate">Coverage Starts:</span>
    <span className="covDate ezyIcon icon-newcalendar">{DateUtils.convertToDate(departureDate)} </span>


 </div>

 <div class="pList pb-2">

<span className="coverageDate">Coverage Ends:</span>
<span className="covDate ezyIcon icon-newcalendar">{DateUtils.convertToDate(arrivalDate)}</span>


</div>


<div class="pList pb-2">

<span className="coverageDate">Coverage Duration:</span>
<span className="covDate">{request.state.req.days}</span>


</div>

<div class="pList pb-2">

<span className="coverageDate">Travel Duration:</span>
<span className="covDate">{request.state.req.days}</span>


</div>

</div>

<div class="dropContent yourSelection">
<h5 className="panelTitle pt-4 ezyIcon icon-confirmedOne">Your Selection</h5>
<div class="pList pb-2">
  <span>{request.state.req!==undefined && request.state.req.plan_name}</span>
  <div class="pricetag">
    <em>QAR</em>

      <span>{request.state.req!==undefined && request.state.req.premium_price}.00</span>

  </div>
</div>

{request.state.req !==undefined && request.state.req.optional_covers!==undefined && request.state.req.optional_covers.length!==0?<h5 className="panelTitle pt-4">Optional Covers</h5>:""}
{request.state.req !==undefined && request.state.req.optional_covers!==undefined && request.state.req.optional_covers.length!==0 && request.state.req.optional_covers.map((optin,index)=>(
  <div class="pList pb-2">
    <span>{optin.name}</span>
  <div class="pricetag">
    <em>QAR</em>
    <span>{optin.premium}.00</span>
  </div>
</div>
))
}
</div>
<div class="dropContent">

<div class="pList pb-2">
  <span>Grand Total</span>
  <div class="pricetag">
    <em>QAR</em>
    <span>{request.state.req!==undefined && (Number(request.state.req.premium_price) + Number(state.covidPrice) + Number(state.winterSport))}.00</span>
  </div>
</div>
</div>


                </div>
              </Sticky>
            </Col>

        </Row>
      </div>
      <Modal
        show={state.showPop}
        onHide={handleClose}
        className="importPNRMdl requiredFare"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="popUplayout fareNomoreavail center">
          <div className="backGroundgif">
            </div>
            {state.prepersist &&
              <div className="text-center">
                <strong>Pre Persistance is Failed</strong>
                <p>Please check with Administrator</p>
                
              </div>
            } 
          </div>
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          {state.prepersist &&
            <Button
              size="xs"
              variant="secondary"
              onClick={backToResults}
            >Back to Result</Button>
          }
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  )
}
export default InsReview