
import React, { useReducer, useState,useContext,useEffect } from 'react';
import { Alert, Col, Form } from "react-bootstrap";
import Button from '../../../common/buttons/Button';
import AttCommonSerivce from "../../../../services/commonService/AttCommonSerivce";
import { Context } from "../../../../../App";
import log from "loglevel";
import Select from 'react-dropdown-select';

/**
 * Initial State Declaration
 */
const initialState = {
    pnr: "", notiMessage: '', notiMessageShow: false, notiVarient: '', enableImportPnr: false, pnrResponse: [],selOfcId:[]
}
// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
        case 'clear':
            return {
                ...state, categoryName: ''
            };
        default:
            return { ...state, [action.type]: action.payload };
    }
};
function PNRImport(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [context, setContext] = useContext(Context);
    const [officeIdList, setOfficeIdList] = useState([]);


    useEffect(() => {
        if(context.logindata.cid!==undefined){
            AttCommonSerivce.loadOfficeIdList({"region":context.logindata.region_type,"cid":context.logindata.cid,"uid":context.logindata.uid,"utype":context.logindata.utype}).then(response => {
                console.log('Offce id List', response)
                if (response.data.suc) {
                    setOfficeIdList(response.data.res)
                  }
              }).catch(function (error) {
                log.error(
                  "Exception occured in loadOfficeIdList function---" + error
                );
              })
        }
      }, [context.logindata.cid])


    const searchPNR = () => {
        if (state.pnr !== "" && state.selOfcId.length!==0){
            const req={
                "pnr":state.pnr,
                "office_id":state.selOfcId[0].label,
                "region":context.logindata.region_type,
                "req_cur":context.logindata.cur,
            }
            props.sendImport(req);
        } else {
            if(state.selOfcId.length===0){
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Please select Office Id' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }else if(state.pnr===""){
                dispatch({ type: 'notiMessageShow', payload: true })
                dispatch({ type: 'notiMessage', payload: 'Please enter PNR Number' })
                dispatch({ type: 'notiVarient', payload: 'danger' })
                hidemessage()
            }
        }
    }

    const handleofcid=(e)=>{
        dispatch({ type: 'selOfcId', payload: e});
    }
    const hidemessage = () => {
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 5000);
      }

    const handleChange = (e) => {
        const { id, value } = e.target
        dispatch({ type: id, payload: value });
    }
    return (
        <>
              <div>
              <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
                    onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
                    dismissible> {state.notiMessage}</Alert>
                    <div className='flightSearchFrom'>
              <Form.Row>

                    <Form.Group as={Col} xs={3} className="selectplugin" controlId="branch_id">
                                <Select
                                options={officeIdList}
                                values={state.selOfcId}
                                searchable={false}
                                onChange={(e)=>handleofcid(e)}
                                />
                            <Form.Label>Office Id<sup>*</sup></Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} xs={3} className="mb-0" controlId="pnr">
                        <Form.Control type="text" value={state.pnr} onChange={handleChange} placeholder="Enter PNR Number..." autoComplete="off" />
                        <Form.Label> Enter PNR Number</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} xs={5} className="clickMe mb-0" controlId="userRole">
                        <Button
                            size="md"
                            onClick={searchPNR}
                            className="importButton"
                            variant="primary" block>
                            Import PNR <span className='ezyIcon icon-import'></span></Button>
                    </Form.Group>
                    </Form.Row>
                    </div>
              </div>
            {/* <div className="flightSearchFrom ">
                  <Form.Row>


                    <Form.Group as={Col} xs={5} className="clickMe mb-0" controlId="userRole">
                        <Button
                            size="md"
                            onClick={searchPNR}
                            className="importButton"
                            variant="primary" block>
                            Import PNR <span className='ezyIcon icon-import'></span></Button>
                    </Form.Group>
                </Form.Row>

            </div> */}

        </>
    )
}


export default PNRImport;
