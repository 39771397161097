import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL
const API_URL_SEARCH = process.env.REACT_APP_B2B_SEARCH

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

/**
 * @author: Lakshmi
 * @description: Integrated the Login and Roles service
 * @param {*} Service 
 */
class AuthenticationService {
    async checkCompActive(data) {
        return await axios.post(`${API_URL}/att/user/validateUser`,data);
    }
    async userLogin(userData) {
        return await axios.post(`${API_URL}/att/user/signin`,userData)
    }
    async confirmOTP(obj) {
        return await axios.post(`${API_URL}/att/user/confirmotp`,obj); 
    }
    async resendOTP(data) {
        return await axios.post(`${API_URL}/att/user/signin`,data); 
    }

    async handleForgotPWD(data) {
        return await axios.post(`${API_URL}/att/user/forgot-password`,data)
    }
    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }
    
    async updateStatus(data) {
        return await axios.post(`${API_URL}/att/booking/send-incomplete-email`,data)
    }
    
    async getCountryMasterData(data) {
        return await axios.post(`${API_URL}/att/common/region_config/retrieve`,data); 
    }
}

export default new AuthenticationService()