import React, { Component, useState,useContext } from 'react'; 
import { Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom';
import { Context } from "../../../../App";
import Header from '../Header';
import MainMenu from '../../../components/common/MainMenu';
import Breadcrumb from '../../common/breadcrumb/Breadcrumb';


function VisaMenuFun(props) {   
  const { state} = props || {}
  const history = useHistory();
  const [context, setContext] = useContext(Context);
   /* const redirect = (event) => {
      event.preventDefault();
      if (event.target.id === "branch") {
        history.push("/ezytrip/administration/branch");
      }else if(event.target.id === "category"){
        history.push("/ezytrip/administration/category");
      }else if(event.target.id === "markup"){
        history.push("/ezytrip/administration/markup");
      }else if(event.target.id === "user"){
        history.push("/ezytrip/administration/user");
      }else if(event.target.id === "role"){
        history.push("/ezytrip/administration/role");
      }
     
    }*/
  //const [mainNav, setmainNav,] = useState(false);

return (
  <>

        <div className="submenu">
          
            <ul>
            {<li  className={state.active==="Branch Creation" ? 'active':''}>
              <Link
                to="/ezytrip/administration/visa-config" 
                title="Visa Configuration"
              >                
                Visa Configuration
              </Link>
              </li>}              
            </ul>
          </div>
   </>
  );
};



class VisaMenu extends Component {
  constructor(props) {
  super(props);
  console.log("propspropsprops",props)
  this.state = {
         active:props.active
          
      }; 
  }

  render() {
  return <VisaMenuFun state={this.state} />
}
}

export default VisaMenu;

