import React, { useReducer,useContext } from 'react';
import { Col, Form } from "react-bootstrap";
import { Context } from "../../../../App";
import Button from '../../common/buttons/Button';


/**
 * @description: This function will show the Flight Refund Record Summary page
 * @date:07-12-2020
 * @author:Lakshmi
 *
 */

const initialState = {
  rtype: "ATC Refund", notiMessageShow: false, notiMessage: '', notiVarient: '', enabelRefnd: false,isManual:false
}

// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, rtype: "ATC Refund"
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
function RefundTypeReturn(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [context, setContext] = useContext(Context);
  // This function will update the State
  const handleChange = evt => {
    const { id, value } = evt.target;
    if (id === "atcr") {
      dispatch({ type: "rtype", payload: "ATC Refund" })
      dispatch({type:'isManual',payload:false})
      //props.refundType("ATC Refund");
    } else if (id === "natcr") {
      dispatch({ type: "rtype", payload: "NON ATC Refund" });
      dispatch({type:'isManual',payload:false})
      //props.refundType("ATC NON Refund");
    }else if (id === "retman") {
      dispatch({ type: "rtype", payload: "Manual Refund" });
      dispatch({type:'isManual',payload:true})
      //props.refundType("ATC NON Refund");
    }
    dispatch({ type: id, payload: value });
  }

  const cancelRefund = () => {
    return window.location = "/ezytrip/dashboard/bookingsummary/" + props.bookDetails.booking_id;
  }
  const hidemessage = () => {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 5000);
  }
  const continueRefund = () => {
    let tickets = [];
    tickets.push(props.ticketsList);
    if (props.ticketsList !== undefined) {
      props.sendRefund([props.ticketsList, state.rtype, "Return"]);
    } else {
      dispatch({ type: 'notiMessageShow', payload: true })
      dispatch({ type: 'notiMessage', payload: 'Select atleast One ticket and proceed to Refund' })
      dispatch({ type: 'notiVarient', payload: 'danger' })
      hidemessage()
    }
  }




  return (
    <>



          <Form.Group controlId="icalInput" className="d-flex align-items-center">
          {context.logindata.roles.includes('A_T_R') &&
            <Form.Check type="radio" label="ATC Refund" id="atcr" defaultChecked custom value={state.rtype} onChange={handleChange} custom name="classes" className="ml-0" />
  }
           {context.logindata.roles.includes('N_A_T_R') &&
            <Form.Check type="radio" label="NON ATC Refund" id="natcr" value={state.rtype} onChange={handleChange} custom name="classes" className="ml-3" />
}
          {context.logindata.roles.includes('M_R') &&
           <Form.Check type="radio" label="Manual Refund" id="retman" checked={state.isManual} value={state.rtype} onChange={handleChange} custom name="classes" className="ml-3" />
          }
           </Form.Group>

          <div className={state.rtype == "NON ATC Refund" ? "passengerList pb-5": 'passengerList'}>
            <Button
              size="xs"
              variant="outline-secondary"
              className="mr-3"
              onClick={cancelRefund}
            >Cancel</Button>
            <Button
              size="xs"
              variant="outline-primary"
              className="mr-3"
              onClick={continueRefund}
            >Continue</Button>
          </div>

          {state.rtype == "NON ATC Refund" &&
            <div className="reissueSMS">
            <span className="ezyIcon icon-info"> Non-ATC refunds are not guaranteed and Ezytrip is not responsible for any ADM’s</span>

        </div>

}

    </>
  )
}
export default RefundTypeReturn