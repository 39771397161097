
import React from 'react';
import CurrencyFormat from 'react-currency-format';

/**
 * @description:This function will fire the Book request and Process the Payment
 * @author: Lakshmi
 * @param {*}
 * @function Payment details
 * @date : 03-09-2020
 */

function FormateCurrency(props) {
   
    return (
        <>
                {props.value!==undefined &&
                    <CurrencyFormat value={parseFloat(props.value)} displayType={'text'} fixedDecimalScale={true} decimalScale={'2'} thousandSeparator={true} prefix={''} />
                }
        </>
    )
}


export default FormateCurrency;
