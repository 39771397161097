
import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InputRange from 'react-input-range';
import logo from "../../assets/images/logo.svg";
import Button from '../common/buttons/Button'; /* Button Import*/
import Notification from '../common/notification/Notification'; /* Notification Import*/
import PickList from '../common/pickList/PickList'; /* PickList Import*/
import PaymentFailed from '../common/popUps/PaymentFailed';
import ResultsNotFound from '../common/popUps/ResultsNotFound';
import Processing from '../common/processingPopUp/Processing';
import SessionTimeOut from '../common/sessionTimeOut/SessionTimeOut';
import Sticky from '../common/sticky/Sticky';
import Tabs from '../common/tabs/Tabs';
import ReactDropdownSelect from './ReactDropdownSelect'; /* React Dropdown Select Import*/
import ReactSelect from './ReactSelect'; /* React Select Import*/
import Slider from './Slider';



export const LoginContext = React.createContext();

function LoginPage(props) {
    const [state, setState] = useState({
        active:'RCTab',
    });
    const [dataState, setData] = useState({data:[]});

    const [roles, setRoles] = useState([
        { value: 1, label: 'Generic Role' },
        { value: 2, label: 'Default Role' },
        { value: 3, label: 'Flight Booking Role' }
    ]);




    const pickldata = (data) => {
        console.log('pickldata data---' + JSON.stringify(data));
        setData({data:data})
      }
      const [price, setPrice] = useState({
        value: { min: 112, max: 1110 },
    });

      const content = {
        RCTab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        RATab: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        };

    const demoPage = {
        backgroundColor: '#F3F4F5',
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'

    };
    const logoStyle = {
        padding: '20px',
        marginBottom: '30px',
    }
    const dpLeft = {
        minWidth: '200px',
        background: '#ccc',
        overflow: 'auto'
    }
    const dpRight = {
        width: '100%',
        padding: '15px'
    }
    const compCont = {
        background: '#ffffff',
        borderRadius: '5px',
        padding: '15px',
        minHeight: '300px',
    }
    const bodyBg = {
        background: '#F3F4F8',
    }
    const h3 = {
        fontSize: '18px'
    }
    const componetList = {
        display: 'flex',
        flexDirection: 'column',
    }

    const links = {
        borderRadius: "0",
        textAlign: 'right',

    }
    const title = {
        marginBottom: '60px',
    }
    const iconList = {
        display:'flex',
        fontSize: '20px',
        flexWrap: 'wrap'
    }

    /* react-bootstrap-table-next Starts here */
    const edit = (data) => {
      }
      const del = (data) => {
      }
    const products = [
        { id: 1, name: 'George', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 2, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 3, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 4, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 5, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 6, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 7, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 8, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 9, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 10, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 11, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 12, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 13, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 14, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 15, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 16, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 17, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 18, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 19, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 20, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 15, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 16, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 17, name: 'Jeffrey', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 18, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 19, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> },
        { id: 20, name: 'Alice', options: <><a onClick={edit}>Edit</a> <a onClick={del}>Delete</a></> }


    ];

    const columns = [{
        dataField: 'id',
        text: 'Product ID',
        sort: true
      }, {
        dataField: 'name',
        text: 'Product Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'options',
        text: 'Options'
      }];

      const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
      }];



    return (
        <><Tab.Container id="left-tabs-example" defaultActiveKey="reactDropdown">
            <div style={demoPage}>
                <div style={dpLeft}>
                    <img
                        src={logo}
                        style={logoStyle}
                        alt="EZY Trip"
                        title="EZY Trip"
                    />
                    <div style={componetList}>
                    </div>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="reactDropdown">React Dropdown Select</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="button">Button</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="pickList">c2-react-picklist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="icon">Icon</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="notification">Notification</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="processing">Processing popUp
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="sessionTimeOut">Session TimeOut
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="resultsNotFound">Results Not Found
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="paymentFailed">Payment Failed
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="tab">Tab</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="table">Table</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="rangeSlider">React-input-range</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="sticky">Sticky</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="slideshow">React Slideshow
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={links} eventKey="scssInfo">Scss Info
                            </Nav.Link>
                        </Nav.Item>

                    </Nav>
                </div>
                <div style={dpRight}>
                    <h3 style={title}>FInd all component list here click on left menu</h3>

                    <Tab.Content>
                        <Tab.Pane eventKey="reactDropdown">
                            <div style={compCont}>
                                <h3 className="mb-3" style={h3}>React Dropdown Select</h3>


                                <ReactDropdownSelect />
                        <br/>
                        <h3 className="mb-3" style={h3}>React Select</h3>
                                <ReactSelect/>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="button">
                            <div style={compCont}>
                            <h3 style={h3} className="mb-3">Button</h3>
                                <Button
                                    size="xs"
                                    variant="primary"
                                    type="submit">
                                    Button Name
                                </Button>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="pickList">
                        <div style={compCont}>
                            <Row>
                                <Col xs={6}>
                                <h3 style={h3} className="mb-3">c2-react-picklist</h3>
                                    <PickList leftPaneLabel="Choose Label left" rightPaneLabel="Choose Label Right" dataList={roles} sendData={pickldata} />

                                </Col>
                            </Row>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="icon">
                        <div style={compCont}>
                            <Row>
                                <Col xs={12}>
                                <h3 style={h3} className="mb-3">Icons</h3>
                                <p>List of Icons: </p>
                                <div style={iconList}>
                                <span className="mb-4 mr-4 ezyIcon icon-delet"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-delete"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-view"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-user"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-flight"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-time"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-alarm"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-rightArrow"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-attachment"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-baggage"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-newcalendar"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-cheapest"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-amended"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-cancelled"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-close"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-confirm"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-contact"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-creditCards"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-document"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-exclamation"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-hotel"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-hotel1"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-list"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-lock"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-map"></span>

                                <span className="mb-4 mr-4 ezyIcon icon-menu"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-meal"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-payment"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-person"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-profile"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-recent"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-reset"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-travellingItinerary"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-usb"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-swap1"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-failed"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-bestValue"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-fastest"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-box"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-plus"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-cash"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-moneySettings"></span>
                                <span className="mb-4 mr-4 ezyIcon icon-policy"></span>
                                    </div>
                                </Col>
                            </Row>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="notification">
                        <div style={compCont}>
                            <Row>
                                <Col xs={6}>
                                <h3 style={h3} className="mb-3">Notification</h3>

                                <Notification messageText='Add your message here' variant="success" show={true}/>

                                </Col>
                            </Row>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="processing">
                        <div style={compCont}>
                            <Processing/>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sessionTimeOut">
                        <div style={compCont}>
                            <SessionTimeOut/>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="resultsNotFound">
                        <div style={compCont, bodyBg}>
                            <ResultsNotFound/>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="paymentFailed">
                        <div style={compCont, bodyBg}>
                            <PaymentFailed/>
                       </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tab">
                        <div style={compCont}>
                            <Row>
                                <Col xs={6}>
                                <h3 style={h3} className="mb-3">Tab</h3>
                                <div className="tabLink">
                                <Tabs active={state.active} onChange={active => setState({active})}>
                                <span key="RCTab">Sample Data</span>
                                <span key="RATab">Sample Data2</span>
                                </Tabs>
                                <div className="tabContent">
                                {content[state.active]}
                                </div>
                                </div>

                                </Col>
                            </Row>
                       </div>
                        </Tab.Pane>
                         <Tab.Pane eventKey="table">
                        <div style={compCont}>
                            <Row>
                                <Col xs={6}>
                                <h3 style={h3} className="mb-3">Table</h3>

                                <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    data={ products }
                                    columns={ columns }
                                    defaultSorted={ defaultSorted }
                                    filter={ filterFactory()}
                                    pagination={ paginationFactory() }
                                    />

                                </Col>
                            </Row>
                       </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="rangeSlider">
                            <div style={compCont}>
                                <h3 className="mb-3" style={h3}>Range Slide</h3>
                              <Col xs={3} className=" ml-3 mr-3 mt-5">
                              <InputRange
                        formatLabel={value => `QAR ${value}`}
                        maxValue={1110}
                        minValue={112}
                        value={price.value}
                        onChange={value => setPrice({ value })} />
                              </Col>

                            </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="sticky">
                            <div style={compCont}>
                                <Sticky className="sample">
                                <h3 className="mb-3" style={h3}>Sticky Example</h3>
                                </Sticky>

                                <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    data={ products }
                                    columns={ columns }
                                    defaultSorted={ defaultSorted }
                                    filter={ filterFactory()}
                                    pagination={ paginationFactory() }
                                    />


                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="slideshow">
                            <div style={compCont}>
                            <Slider/>

                            </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="scssInfo">
                            <div style={compCont}>
                            <Slider/>

                            </div>
                        </Tab.Pane>

                    </Tab.Content>

                </div>
            </div>
        </Tab.Container>
        </>
    )
}

export default LoginPage
