import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "../../../App";
import { USER_AUTH_DATA } from "../common/headerInfo/HeaderInfo";

/* Currently we are checking user is locked in or not from store */
const ProtectedRoute = ({ component: Component, path,login, ...rest }) => {
 const [context, setContext] = useContext(Context);
 const [valid, setValid] = useState(false);
 const userData = localStorage.getItem(USER_AUTH_DATA);
//  useEffect(() => {
//         console.log(userData)
//         if (userData) {
//             setContext({ logindataone: JSON.parse(userData) });
//             setValid(true);
//         }
     
        
// }, [userData]);


  console.log(`isValid :::: `, userData?.length)
    return (
      <Route
        path={path}
        {...rest}

        render={props => userData?.length > 0 ? <Component {...props} /> : <Redirect to="/ezytrip/login" />}
      />
    );
  }
  

export default ProtectedRoute;