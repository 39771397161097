import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Col, Modal, Row } from "react-bootstrap";
import InnerLoader from '../../../common/loader/InnerLoader';
import FormateCurrency from '../../../commonUtils/FormateCurrency';
import Utilities from '../../../commonUtils/Utilities';
import {getSalamAirBaggage} from '../../flight/review/operations';
export const CLIENT_IP = "clientIp";
export const SEL_SALAM_ONE = "sel_salam1";
export const SEL_SALAM_TWO = "sel_salam2";
/**
 * @description:This function will call the Mini props.fare rules
 * @author: Lakshmi
 * @param {*}
 * @function TravellerFusionFare details
 * @date : 28-06-2021
 */

//This function will show the Air markups List and Add Markup
function ShowFareOption(props) {
    //Calculation the DISC
    const [gccCodes , setGccCodes] = useState(["XNB","DWC","DXB","AZAZ","DZB","DOH","XSB","KWI","MCT","DMM","SHJ","IST","JED"]);
    const [showBaggage, setShowBaggae] = useState("");
    const [regionType, setRegionType] = useState("");
    const [bagggaeInfo, setBaggageInfo] = useState("");
    const [bagggaeInfo1, setBaggageInfo1] = useState("");
    const [currency, setCurrency] = useState("");
    

    useEffect(() => {
      if(props.flights.sup==="4" && props.reviewFlt!==undefined){
        setRegionType(props.reviewFlt.region_type);
        if((props.reviewFlt.src_code==="JED" && props.reviewFlt.dest_country==="IN") || (props.reviewFlt.src_country==="IN" && props.reviewFlt.dest_code==="JED")){
          setShowBaggae("30KG")
        }else if(gccCodes.includes(props.reviewFlt.src_code) || gccCodes.includes(props.reviewFlt.dest_code)){
          setShowBaggae("30KG")
        }else{
          setShowBaggae("20KG")
        }
      }else{
        if(props.flights.sup==="4" && props.srequest!==undefined){
          setRegionType(props.regionType);
          if((props.srequest.srcCode==="JED" && props.srequest.dest[0].countryCode==="IN") || (props.srequest.src[0].countryCode==="IN" && props.srequest.destCode==="JED")){
            setShowBaggae("30KG")
          }else if(gccCodes.includes(props.srequest.srcCode) || gccCodes.includes(props.srequest.destCode)){
            setShowBaggae("30KG")
          }else{
            setShowBaggae("20KG")
          }
        }
       
      }
      //Checking the Salam Air Baggage
      if(props.flights.sup==="6" || props.flights.sup===6){
        if(props.srequest.tt===1 || props.srequest.tt==="1"){
          checkBaggageInfo(props.flights);
        }else if(props.srequest.tt===2 || props.srequest.tt==="2"){
        if(props.flights.ref==="1" || props.flights.ref===1){
          checkBaggageInfo(props.flights);
        }else{
          checkBaggageInfo(props.flights);
        }
       } 
      }
      if(props.srequest!==undefined){
        setCurrency(props.srequest.cur)
      }
    }, [])

  const checkBaggageInfo =(flt)=>{
    if((props.reviewFlt===undefined &&  flt.fare===undefined) ||  (props.reviewFlt!==undefined && flt.pr===undefined)){
      callBaggage(flt);
    }else if(flt.fare!==undefined && flt.fare.pr!==undefined && flt.fare.pr.bagage_info!==""){
      setBaggageInfo(flt.fare.pr.bagage_info);
      setBaggageInfo1(flt.fare.pr.cabb)
    }else if(flt.pr!==undefined &&  flt.pr.bagage_info!==""){
      setBaggageInfo(flt.pr.bagage_info);
      setBaggageInfo1(flt.pr.cabb)
    }else{
      callBaggage(flt);
    }
    }

    const callBaggage=(flt)=>{
      const req={
        "token":flt.token,
        "ip_address":localStorage.getItem(CLIENT_IP),
        "fareop":flt.fareop
    }
    getSalamAirBaggage(req).then(response => {
    if(response.status){
      let selFareObj = response.fareop.filter((obj) => obj.selected);
      setBaggageInfo(selFareObj[0].pr.bagage_info);
      setBaggageInfo1(selFareObj[0].pr.cabb)
    }
    })
    }


  const calcuateServiceFee = (serviceFeeList) => {
    var userType = (props.srequest!==undefined && props.srequest.butype === "ta_user") ? "TA" : "STA";
    let servicefee = 0;
    if (serviceFeeList !== undefined) {
      servicefee = Utilities.calculateServiceFee(serviceFeeList, userType, props.srequest);
      if(servicefee==="NaN"){
        return 0.00;
      }else{
        return servicefee;
      }
    } else {
      return 0.00;
    }
  }

  const calculateGST=()=>{
    let total=0;
    if(props.reviewFlt!==undefined && props.reviewFlt.gst!==undefined && props.reviewFlt.cur==="INR"){
    let gst={};
      gst = Utilities.applyGST(props.serviceFee,props.reviewFlt.gst,props.fare.pr.tax,props.srequest,"Flight");
      if(gst.gst_invoice.length!==0){
        for(let val of gst.gst_invoice){
          if(gst.type==="same"){
          if(total===0){
            total=Number(val.cgst_val)+Number(val.sgst_val);
          }else{
            total=total+Number(val.cgst_val)+Number(val.sgst_val);
          }
         }else{
          if(total===0){
            total=Number(val.igst_val);
          }else{
            total=total+Number(val.igst_val);
          }
         }
      }
    }
    }
    return total;
  }


    return (
        <>
            <div className="travelFusionresponceList faretf">

                            <div className={props.fare.pr.ftyp==="STANDARD"?"fusionList fusionOne active":"fusionList fusionOne active" + " "+(props.fare.pr.ftyp).toLowerCase()+" " +(props.fare.pr.ftyp === 'Extra' && 'selectedTwo' || props.fare.pr.ftyp === 'Value' && 'selectedThree')} >
                                <div className={ "cardtop " + (props.fare.pr.ftyp === 'Extra' && 'selectedTwo' || props.fare.pr.ftyp === 'Value' && 'selectedThree') }>
                                   <span className="tfType">
                                       {props.fare.pr.ftyp==="Regular - R" &&
                                        <span>SAVER </span>
                                        }
                                        {props.fare.pr.ftyp==="Flexi - J" &&
                                        <span>FLEXI </span>
                                        }
                                        {props.fare.pr.ftyp.toUpperCase()==="CORPORATE - F" &&
                                        <span>CORPORATE </span>
                                        }
                                        {props.fare.pr.ftyp!=="Regular - R" && props.fare.pr.ftyp!=="Flexi - J" && props.fare.pr.ftyp.toUpperCase()!=="CORPORATE - F" &&
                                        <span>{props.fare.pr.ftyp.toUpperCase()}</span>
                                        }
                                     </span>
                                    <div className="pricetag"><em>{currency}</em><strong>{<FormateCurrency value={Utilities.addPrice(props.fare.pr.tf, calcuateServiceFee(props.serviceFee) + calculateGST(),"Add")} />}</strong></div>
                                </div>
                                <div className="cardbody">
                                  {props.flights!==undefined && (props.flights.sup==="2"|| props.flights.sup===2) &&
                                    <>
                                    <p><span className="ezyIcon icon-globalcovid"> Global Covid-19 Cover</span></p>
                                    <p><span className="ezyIcon icon-newseatselection"> Generous 32" seat pitch</span></p>
                                    <p><span className="ezyIcon icon-tenkg"> 10 Kg carry-on baggage</span></p>
                                    </>
                                  }
                                   {props.fare.pr.cabb && props.flights.sup!=="6" && props.flights.sup!==6 &&
                                    <p><span className="ezyIcon icon-checkinbag"> Checkin baggage
                                   {props.fare.pr.bagage_info!==undefined && props.fare.pr.bagage_info!=="" &&
                                     <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{props.fare.pr.bagage_info}</span>
                                              </div>
                                              </span>
                                     }
                                    </span><span>{props.fare.pr.cabb}</span></p>
                                    }
                                    {bagggaeInfo!=="" && (props.flights.sup==="6" || props.flights.sup===6) &&
                                    <p><span className="ezyIcon icon-checkinbag"> Checkin baggage
                                    <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{bagggaeInfo}</span>
                                              </div>
                                              </span>
                                    </span><span>{bagggaeInfo1}</span></p>
                                    }
                                    {(props.flights.sup==="6" || props.flights.sup===6) &&
                                    <p><span className="ezyIcon icon-checkinbag"> Cabin baggage
                                    </span><span>7KG</span></p>
                                    }
                                     {props.fare.pr.chekb ? props.flights.sup ==="5"?
                                     <p><span className="ezyIcon icon-tenkg">Cabin baggage
                                     <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom"
                                               style={{"width":"200px"}}>
                                              <span>{props.fare.pr.chekb}</span>
                                              </div>
                                              </span>
                                       </span><span>free</span></p>
                                     :
                                     <p>
                                       <span className="ezyIcon icon-cabinbaggage">Cabin baggage</span>
                                      <span>{props.fare.pr.chekb}</span>
                                  </p>:<></>
                           }
                                  {props.fare.pr.puBag &&
                           <p><span className="ezyIcon icon-tBaggage">Purchase baggage
                           {props.fare.pr.puBag_info!=="" && props.fare.pr.puBag_info!==undefined &&
                             <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{props.fare.pr.puBag_info}</span>
                                              </div>
                                              </span>
                             }
                           </span><span>{props.fare.pr.puBag}</span></p>
                           }
                                {props.fare.pr.meals!="" && props.flights.sup !== "6" &&
                                      <p><span className="ezyIcon icon-mealsselection"> Meal Selection
                                      {props.fare.pr.meals_info!=="" && props.fare.pr.meals_info!==undefined &&
                                      <span className="ezyIcon icon-info toolTipInfo">
                                                        <div className="toolTipCUstoom">
                                                        <span>{props.fare.pr.meals_info}</span>
                                                        </div>
                                                        </span>
                                      }
                                      {props.flights.sup === "5" && (props.fare.uniqueId ==="VALUE" || props.fare.uniqueId ==="FLEX")?<span className="ezyIcon icon-info toolTipInfo">
                                                        <div className="toolTipCUstoom">
                                                        <span>INCLUDED IN FARE</span>
                                                        </div>
                                                        </span>:""}
                                      </span><span>{props.flights.sup === "5" && (props.fare.uniqueId ==="VALUE" || props.fare.uniqueId ==="FLEX")?"free":props.fare.pr.meals}</span></p>
                                    }

                                    {props.fare.pr.seats &&
                                    <p><span className="ezyIcon icon-newseatselection"> Seat Selection
                                    {props.fare.pr.seat_info!==undefined && props.fare.pr.seat_info!=="" &&
                                     <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{props.fare.pr.seat_info}</span>
                                              </div>
                                              </span>
                                     }
                                    </span><span>{props.fare.pr.seats}</span></p>
                                    }
                                    {props.fare.pr.chanb &&
                                    <p><span className="ezyIcon icon-chnagebooking"> Change Booking
                                   {props.fare.pr.chanb_info!==undefined && props.fare.pr.chanb_info!=="" &&
                                     <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{props.fare.pr.chanb_info}</span>
                                              </div>
                                              </span>
                                     }
                                    </span><span title={(props.fare.pr.chanb)}>{props.fare.pr.chanb}</span></p>
                                    }

                                    {props.fare.pr.canb &&
                                    <p><span className="ezyIcon icon-cancelbooking"> Cancel Booking
                                   {props.fare.pr.cabb_info!==undefined && props.fare.pr.cabb_info!=="" &&
                                     <span className="ezyIcon icon-info toolTipInfo">
                                              <div className="toolTipCUstoom">
                                              <span>{props.fare.pr.cabb_info}</span>
                                              </div>
                                              </span>
                                     }
                                    </span><span title={(props.fare.pr.canb)}>{props.fare.pr.canb}</span></p>
                                    }
                                    {props.flights.sup ==="4" && regionType!==undefined &&
                                    <div>
                                    {props.fare.pr.ftyp==="Regular - R" &&
                                      <>
                                      <p><span className="ezyIcon icon-cabinbaggage">Hand Baggage </span>
                                       <span>7KG</span></p>
                                      <p><span className="ezyIcon icon-checkinbag">Checkin Baggage </span>
                                       <span>{regionType === "Domestic"?"15KG":showBaggage}</span>
                                       </p>
                                       <p><span className="ezyIcon icon-mealsselection">Meal Selection </span>
                                       <span>Chargeable</span>
                                       </p>
                                       <p><span className="ezyIcon icon-newseatselection">Seat Selection</span>
                                       <span>Chargeable</span>
                                       </p>
                                       </>
                                      }
                                      {props.fare.pr.ftyp.includes("Flexi") &&
                                      <>
                                      <p><span className="ezyIcon icon-cabinbaggage">Hand Baggage </span>
                                       <span>7KG</span></p>
                                      <p><span className="ezyIcon icon-checkinbag">Checkin Baggage </span>
                                       <span>{regionType === "Domestic"?"15KG":showBaggage}</span>
                                       </p>
                                       <p><span className="ezyIcon icon-mealsselection">Meal Selection </span>
                                       <span>{regionType === "Domestic"?"Complimentary":"Included"}</span>
                                       </p>
                                       <p><span className="ezyIcon icon-newseatselection">Seat Selection</span>
                                       <span>Included</span>
                                       </p>
                                       </>
                                      }
                                      {props.fare.pr.ftyp.includes("Corporate") &&
                                      <>
                                      <p><span className="ezyIcon icon-cabinbaggage">Hand Baggage </span>
                                       <span>10KG</span></p>
                                      <p><span className="ezyIcon icon-checkinbag">Checkin Baggage </span>
                                      {regionType === "Domestic" &&
                                      <span>15KG</span>
                                      }
                                      {regionType === "International" && showBaggage==="30KG" &&
                                      <span>30KG</span>
                                      }
                                      {regionType === "International" && showBaggage==="20KG" &&
                                      <span>30KG</span>
                                      }
                                       </p>
                                       <p><span className="ezyIcon icon-mealsselection">Meal Selection </span>
                                       <span>{regionType === "Domestic"?"Complimentary":"Included"}</span>
                                       </p>
                                       <p><span className="ezyIcon icon-newseatselection">Seat Selection</span>
                                       <span>Included</span>
                                       </p>
                                       </>
                                      }
                                    </div>
                                    }


                                </div>
                            </div>
                        <div className="tffare">
                            {props.flights!==undefined &&
                            <ul>
                            <li>{props.flights.seg[0].fareRule}</li>
                            </ul>
                            }
                        </div>
                </div>
        </>
    )
}
export default ShowFareOption