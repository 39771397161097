import { apiPostHelper } from '../../../commonUtils/helper';
import InsuranceService from '../../../../services/bookingService/insurance/InsuranceService';
import AttCommonSerivce from '../../../../services/commonService/AttCommonSerivce';
const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH
const DB_DOMAIN = process.env.REACT_APP_DB_URL
/**
 * @description: Travel details page operations
 * @author: Lakshmi
 * @param {*}
 * @function Travel Itinarary
 * @date : 31-08-2020
 */


// ========================================================================
//  Saveing the Booking details 
// ========================================================================
export const saveBooking = async (saveObj) => {
    let finalRes = [];
    await InsuranceService.saveBooking(saveObj).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

//Call OLD Itinarary details
export const fireApprovalRequest = async (obj) => {
    let finalRes = {};
    const headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await apiPostHelper(`${API_URL_COMMON}/insurance/approval`, obj, headers).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}

// ========================================================================
//  Deduct Credi Limit 
// ========================================================================
export const deductCreditLimit = async (obj) => {
    let clRes = [];
    await AttCommonSerivce.deductCreditLimit(obj).then(response => {
        clRes = response.data;
    });
    return Promise.resolve(clRes);
}
export const sendConfirmationObj = async (req) => {
    let finalRes = [];
    await InsuranceService.sendConfirmationObj(req).then(response => {
        finalRes = response.data;
    });
    return Promise.resolve(finalRes);
}